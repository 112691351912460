import { debounce } from 'lodash';
import { useCallback } from 'react';

import { SEARCH_DEBOUNCE_MS } from '~/constants/debounces';
import { Filter } from '~/evifields';
import { api } from '~/redux';
import { DepartmentV2, Filters, PilotId } from '~/types';

export const getDepartmentsFilterRendererAndSerializer = ({
  clientId,
}: {
  clientId: PilotId;
}) => {
  const [
    searchDepartments,
    result,
  ] = api.endpoints.findDepartmentsByName.useLazyQuery();
  const { data, error, isFetching } = result;

  const AsyncDepartmentOptionsContainer: Filters.FieldRendererProps = ({
    Component,
    innerProps,
  }) => {
    const debouncedSearchDepartments = useCallback(
      debounce(searchDepartments, SEARCH_DEBOUNCE_MS),
      [searchDepartments],
    );
    const fetchedDepartments = data?.results ?? [];
    const options = fetchedDepartments.map((dep: DepartmentV2) => ({
      value: `${dep.id}`,
      label: dep.name,
    }));
    const asyncOptions = { data: options, error, isFetching };
    const fetchOptions = (query = '') => {
      debouncedSearchDepartments({ query, clientId }, true);
    };

    const asyncProps = {
      asyncOptions,
      onMount: fetchOptions,
      onQuery: fetchOptions,
    };

    return <Component asyncProps={asyncProps} innerProps={innerProps} />;
  };

  return {
    render: AsyncDepartmentOptionsContainer,
    serialize: (filter: Filter) => {
      const department = data?.results.find(
        (dep) => `${dep.id}` === filter.values[0],
      );
      return department?.name ?? '';
    },
  };
};
