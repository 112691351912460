/*eslint import/namespace: ['error', { allowComputed: true }]*/
import { DataFieldType } from '~/enums';
import { SearchableFieldId } from '~/redux/api/methods';
import * as apiServices from '~/services';
import { DropdownFieldValue, PilotId } from '~/types';

const { pilot, pilotV3 } = apiServices;

interface DataFieldsResponse {
  data: DataFieldEntry[];
}

interface DataFieldEntry {
  attributes: DataFieldAttributes;
}

interface DataFieldAttributes {
  id: PilotId;
  label: string;
  type: DataFieldType;
}

interface FieldValuesResponse {
  selectAllCount: string;
  values: DropdownFieldValue[];
}

export enum SearchableFields {
  DocumentGroup = 'document_group_id',
  Folder = 'folder_id',
}

export interface GetFieldValuesParam {
  fieldId: SearchableFieldId;
  searchSuggestions?: boolean;
  substringQuery?: string;
}

export const getFieldValues = ({
  fieldId,
  searchSuggestions = false,
  substringQuery = '',
}: GetFieldValuesParam): Promise<FieldValuesResponse> => {
  if (fieldId === SearchableFields.DocumentGroup) {
    const url = `search/searchable/field-suggestions/${fieldId}/`;

    const params = {
      query: substringQuery || undefined,
    };

    return pilot.get(url, { params });
  }

  const params = {
    search_suggestions: !!searchSuggestions,
    substring_query: substringQuery || undefined,
  };

  const url = `document-information-fields/${fieldId}/values`;

  return pilot.get(url, { params });
};

export const getV3DataFields = (): Promise<DataFieldsResponse> => {
  return pilotV3.get('/search/filters');
};
