import React, { useMemo } from 'react';

import { Select } from '~/eds';
import { Nullable } from '~/types';

import { LabeledOperator, OperatorId } from './types';

interface Props {
  isEmbedded?: boolean;
  operators: LabeledOperator[];
  value: Nullable<OperatorId>;
  onChange: (updatedValue: Nullable<OperatorId>) => void;
}

export const OperatorSelect = ({
  isEmbedded,
  operators,
  value,
  onChange,
}: Props) => {
  const options = useMemo(
    () =>
      operators.map((operator) => ({
        label: operator.label,
        value: operator.id,
      })),
    [operators],
  );

  return (
    <Select
      enableControl={!isEmbedded}
      enablePortal={true}
      isClearable={false}
      isEmbedded={isEmbedded}
      isSearchable={false}
      isMulti={false}
      name="operator"
      options={options}
      placeholder="Choose an operator"
      value={value}
      onChange={onChange}
    />
  );
};
