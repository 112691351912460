import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { builderSetShouldEditSetup } from '~/actions';
import { DocumentEditor } from '~/components/Shared/DocumentEditor';
import DocumentViewer from '~/components/Shared/DocumentViewer_DEPRECATED';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { useToast } from '~/eds';
import { WorkflowIntakeFormType } from '~/enums';
import { api } from '~/redux';
import { Alert, Box, FlexLayout, Icon, Text } from '~/ui';

const {
  Company,
  CompanyAndCounterparty,
  Counterparty,
} = WorkflowIntakeFormType;

function Source({
  // connected
  builderSetShouldEditSetup,
  disabled,
  workflow,
}) {
  const { id, type, file: uploadedFile } = workflow;
  const canRenderFile = type !== Counterparty && !uploadedFile; // we cannot render a file if it doesn't exist (counterparty) or if users have only uploaded it locally
  const {
    data: templateFile,
    isError,
    isFetching: isLoadingFile,
  } = api.endpoints.getWorkflowFile.useQuery(
    { id, type: 'sfdt' },
    { skip: !id || !canRenderFile },
  );
  const { toast } = useToast();

  useEffect(() => {
    if (isError) {
      toast({
        message: 'An error occurred while fetching file. Please try again.',
        status: 'danger',
      });
    }
  }, [isError]);

  let permissionsText;
  if (type === CompanyAndCounterparty) {
    permissionsText =
      'Users can upload an existing document and generate contracts from a template.';
  } else if (type === Company) {
    permissionsText = 'Users can generate contracts from a template.';
  } else if (type === Counterparty) {
    permissionsText = 'Users can upload an existing document.';
  }

  function renderDocViewer() {
    if (templateFile) {
      return <DocumentEditor file={templateFile} readonly />;
    } else {
      return (
        <Box sx={{ flexGrow: 1 }}>
          <DocumentViewer
            file={templateFile}
            maxWidth="100%"
            noFileMessage="Unable to find file."
          />
        </Box>
      );
    }
  }

  return (
    <FlexLayout
      disabled={disabled}
      flexDirection="column"
      space={6}
      sx={{ height: '100%' }}
    >
      <FlexLayout space={2} flexDirection="column">
        <Alert
          variant="info"
          noActionBorder
          actions={[
            { text: 'Edit', onClick: () => builderSetShouldEditSetup(true) },
          ]}
        >
          <Text color="gray-700" variant="xs-dense-medium">
            {uploadedFile
              ? 'You have uploaded a new template for this workflow. To preview the workflow with the new template, please save the workflow.'
              : permissionsText}
          </Text>
        </Alert>
      </FlexLayout>
      {isLoadingFile ? (
        <FlexLayout alignItems="center" justifyContent="center">
          <LoadingSpinner />
        </FlexLayout>
      ) : !canRenderFile ? (
        <Box sx={{ flexGrow: 1 }}>
          <Icon icon="uploadPaper" sx={{ height: '100%', width: '100%' }} />
        </Box>
      ) : (
        renderDocViewer()
      )}
    </FlexLayout>
  );
}

const mapStateToProps = ({ builder, workflow }) => ({
  disabled: !builder.shouldEnableAllComponents,
  workflow,
});

export default connect(mapStateToProps, { builderSetShouldEditSetup })(Source);
