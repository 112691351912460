import React, { Component } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import injectSheet from 'react-jss';
// explicit indicate use of legacy Route (lives outside of the Router)
import { Route as LegacyRoute } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { PageHeader } from '~/components/PageLayout';
import { ChatBotPanel } from '~/features/ask-anything/chat-bot-panel';
import { withFlags } from '~/flags';
import { MODAL_AUTH_REQUIRE_POPUP } from '~/types/modal.types';

import AuthenticationStore from '../../../auth';
import Router from '../../Router';
import EcModalNestedOverlay from '../../Shared/EcModalNestedOverlay';
import withSplashScreen from '../../Shared/HOCs/withSplashScreen';
import UploadInfoPanel from '../../Shared/UploadInfoPanel';
import { AppData } from '../AppData';
import { AppTracingInitializer } from '../AppTracingInitializer';
import { componentConfig, djsConfig } from './AppContents.config';
import styles from './AppContents.styles';
import { MAIN_CONTENT_ID } from './constants';
import { SkipToMainLink } from './SkipToMainLink';

class AppContents extends Component {
  renderUploadLogsPopup() {
    const { user } = this.props;
    const isUserLoggedIn = AuthenticationStore().isLoggedIn();
    if (!user || !isUserLoggedIn) return null;
    return (
      <UploadInfoPanel
        user={user}
        onRef={(ref) => (this.uploadLogsPanel = ref)}
      />
    );
  }

  renderAuthRequirePopup() {
    const { user } = this.props;
    const AS = AuthenticationStore();
    if (!user || !AS.isLoggedIn()) return null;

    return (
      <EcModalNestedOverlay
        width="580px"
        modalType={MODAL_AUTH_REQUIRE_POPUP}
      />
    );
  }

  render() {
    return (
      <div className="main-layout">
        <SkipToMainLink />
        {this.renderUploadLogsPopup()}
        {this.renderAuthRequirePopup()}
        <ToastContainer
          position="bottom-center"
          transition={Slide}
          hideProgressBar={true}
          autoClose={5000}
          closeButton={false}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          draggable={false}
          className="toast-container"
          toastClassName="toast-message"
          closeOnClick={false}
        />
        <DropzoneComponent
          className="app-dropzone"
          config={componentConfig}
          djsConfig={djsConfig}
        />
        {AuthenticationStore().isLoggedIn() ? (
          <LegacyRoute path="*" component={PageHeader} />
        ) : null}
        <main id={MAIN_CONTENT_ID}>
          <AppData />
          <Router />
          <AppTracingInitializer />
          <ChatBotPanel />
        </main>
      </div>
    );
  }
}

AppContents = withSplashScreen(AppContents);

export default injectSheet(styles)(withFlags(AppContents));
