import { getUserName, types } from '~/eds';
import { Department, PilotId } from '~/types';

import { sortByNumericValue } from './array';

export const transformUserToMention = (
  user: types.User,
  hasAccess: boolean,
) => {
  const userMention: types.MentionedEntity<'user'> = {
    id: String(user.id),
    type: 'user',
    label: getUserName(user, { placeholderName: 'Deactivated User' }),
    isInvalid: !hasAccess,
    tooltip: hasAccess ? user.email : 'No access to file',
    data: {
      user,
    },
  };
  return userMention;
};

export function createDepartmentMention(department: Department) {
  const { id, name, memberCount } = department;
  const departmentMention: types.MentionedEntity<'department'> = {
    id: String(id),
    isInvalid: false,
    label: name,
    tooltip: '',
    type: 'department',
    data: {
      department: {
        count: memberCount,
        id,
        name,
      },
    },
  };
  return departmentMention;
}

export function getDepartmentMentions(departmentTree: Department[]) {
  const normalizedDepartments = normalizeDepartmentTree(departmentTree);
  return Object.values(normalizedDepartments)
    .map((department) => createDepartmentMention(department))
    .sort(sortByNumericValue('position'));
}

export function normalizeDepartmentTree(departmentTree: Department[]) {
  const normalizedDepartments: Record<PilotId, Department> = {};

  function normalizeDepartmentTreeR(deptTree: Department[]) {
    deptTree.forEach((department: Department) => {
      const { children, id } = department;

      // add current department
      normalizedDepartments[id] = department;

      // recursively normalize and add departments
      normalizeDepartmentTreeR(children);
    });
  }

  normalizeDepartmentTreeR(departmentTree);

  return normalizedDepartments;
}
