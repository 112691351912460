import React from 'react';
import { connect } from 'react-redux';

import { builderSetShouldEditParties } from '~/actions/builder';
import { getPartyIds } from '~/reducers/workflow';
import { FlexLayout, Link, PageLayout } from '~/ui';

import EmptySectionItem from '../EmptySectionItem';
import Party from './Party';

function PartySignerFieldSection({
  // connected
  disabled,
  partyIds,
  builderSetShouldEditParties,
}) {
  const Content =
    partyIds.length === 0 ? (
      <EmptySectionItem message="No parties yet" />
    ) : (
      <FlexLayout flexDirection="column" space={3}>
        {partyIds.map((partyId) => (
          <Party key={partyId} partyId={partyId} />
        ))}
      </FlexLayout>
    );

  return (
    <PageLayout.SidebarSection
      headerRightContent={
        disabled ? null : (
          <Link
            variant="xs-dense-bold"
            onClick={() => builderSetShouldEditParties(true)}
          >
            Edit
          </Link>
        )
      }
      title="Parties"
    >
      {Content}
    </PageLayout.SidebarSection>
  );
}

function mapStateToProps({ builder, workflow }) {
  return {
    disabled: !builder.shouldEnableAllComponents,
    partyIds: getPartyIds(workflow),
  };
}

export default connect(mapStateToProps, { builderSetShouldEditParties })(
  PartySignerFieldSection,
);
