import {
  EDS_ACTION_ACTIVE_COLOR,
  EDS_ACTION_ACTIVE_HOVER_COLOR,
  EDS_ACTION_COLOR,
  EDS_ACTION_HOVER_COLOR,
} from '~/ui';

import {
  evisortGreenDark,
  evisortOrange,
  evisortRed,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  evisortButton: {
    // TODO: button should not hardcode this
    alignSelf: (props) => props.alignSelf || 'flex-start',
    display: 'flex',
    flexShrink: '0',
    justifyContent: 'center',
    alignItems: 'center',
    width: (props) => props.width,
    height: (props) => props.height,
    border: (props) =>
      props.borderless ? 'none' : 'solid 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    padding: '7px 12px',
    backgroundColor: (props) => {
      const { inverse, yellow, red, highlighted, green, clicked } = props;

      if (clicked) {
        if (yellow) {
          return EDS_ACTION_ACTIVE_COLOR;
        } else if (red) {
          return '#df3535';
        } else if (green) {
          return '#0B9D49';
        }

        return '#e8e8e8';
      }

      if (yellow) {
        return EDS_ACTION_COLOR;
      } else if (red) {
        return evisortRed;
      } else if (highlighted) {
        return evisortOrange;
      } else if (green) {
        return evisortGreenDark;
      } else if (inverse) {
        return '#404040';
      }

      return '#fff';
    },
    color: (props) =>
      props.yellow || props.red || props.green || props.inverse
        ? '#fff'
        : 'rgba(0, 0, 0, 0.75)',
    fontSize: (props) => props.fontSize,
    fontWeight: semibold,
    cursor: 'pointer',
    opacity: (props) => (props.disabled ? '0.4' : '1'),
    pointerEvents: (props) => (props.disabled ? 'none' : 'auto'),
    '&:hover': {
      backgroundColor: (props) => {
        const { inverse, yellow, red, green, clicked } = props;

        if (clicked) {
          if (yellow) {
            return EDS_ACTION_ACTIVE_HOVER_COLOR;
          } else if (red) {
            return '#df3535';
          } else if (green) {
            return '#0B9D49';
          }

          return '#e8e8e8';
        }

        if (yellow) {
          return EDS_ACTION_HOVER_COLOR;
        } else if (red) {
          return '#f44b4b';
        } else if (green) {
          return '#0B9D49';
        } else if (inverse) {
          return '#404040';
        }

        return '#f4f4f4';
      },
    },
    '& svg': {
      flexShrink: '0',
      // this is hacky but EcButton will be refactored away favoring eds.Button
      // The goal is to override any icon svg path fill with white for the primary (i.e. yellow) button.
      '& path[fill]': {
        fill: (props) => (props.yellow ? 'white' : undefined),
      },
    },
  },
  evisortButtonText: {
    flexGrow: 1,
    marginLeft: (props) => (props.iconLeft ? '12px' : 0),
    marginRight: (props) => (props.iconRight ? '4px' : 0),
    textAlign: (props) => props.textAlign || 'start',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default styles;
