import {
  black1,
  black2,
  black3,
  black4,
  bold,
  evisortBlue,
  regular,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  analyzerFilters: {
    flexShrink: 0,
  },
  filtersHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'OatmealPro',
    marginBottom: '24px',
    color: black2,
    fontSize: '21px',
    fontWeight: bold,
  },
  filterEntities: {
    position: 'relative',
    maxWidth: '262px',
  },
  filterEntityTitle: {
    marginBottom: '16px',
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
  },
  filterEntitySubtitle: {
    marginTop: '4px',
    color: black3,
    fontSize: '13px',
    fontWeight: regular,
  },
  filterSearch: {
    alignItems: 'center',
    borderRadius: '4px',
    marginBottom: '12px',
  },
  entitySeparator: {
    margin: '24px 0',
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  selectedFoldersEmpty: {
    marginBottom: '16px',
    color: black4,
    fontSize: '15px',
  },
  foldersAddLink: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& svg': {
      marginRight: '4px',
    },

    '& span': {
      color: evisortBlue,
      fontSize: '13px',
      fontWeight: bold,
    },
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(249, 251, 252, 0.85)',
    zIndex: 1,
  },
  filtersErrorContainer: {
    textAlign: 'center',
  },
  filtersErrorMessage: {
    marginBottom: '8px',
    color: black2,
    fontSize: '17px',
  },
  filtersErrorAction: {
    display: 'inline-block',
    color: evisortBlue,
    cursor: 'pointer',
  },
};

export default styles;
