import PT from 'prop-types';
import React, { useEffect, useState } from 'react';

import ExpressionContext from './ExpressionContext';
import Tree from './Tree';
import {
  createRootNode,
  fromExpression,
  toExpression,
  TreeManager,
} from './utils';

function ConditionExpressionBuilder({
  expression = [],
  fields = {},
  fieldDefinitions,
  maxNestLevel = 3,
  operatorDefinitions,
  onChange,
}) {
  const [tree, setTree] = useState(createRootNode());

  useEffect(() => setTree(fromExpression(expression)), [expression]);

  const treeManager = new TreeManager(tree);

  function onAddNode(id, newNode) {
    const updatedTree = treeManager.addNode(id, newNode);
    setTree(updatedTree);
    onChange(toExpression(updatedTree), updatedTree);
  }

  function onRemoveNode(id) {
    const updatedTree = treeManager.removeNode(id);
    setTree(updatedTree);
    onChange(toExpression(updatedTree), updatedTree);
  }

  function onUpdateNode(id, data) {
    const updatedTree = treeManager.updateNode(id, data);
    setTree(updatedTree);
    onChange(toExpression(updatedTree), updatedTree);
  }

  // only use fields that have valid and specified data type mapping
  const validFields = Object.values(fields).reduce((valid, field) => {
    if (fieldDefinitions[field.type]) {
      return { ...valid, [field.id]: field };
    }
    return valid;
  }, {});

  return (
    <ExpressionContext.Provider
      value={{ fields: validFields, fieldDefinitions, operatorDefinitions }}
    >
      <Tree
        maxNestLevel={maxNestLevel}
        node={tree}
        onAddNode={onAddNode}
        onRemoveNode={onRemoveNode}
        onUpdateNode={onUpdateNode}
      />
    </ExpressionContext.Provider>
  );
}

ConditionExpressionBuilder.propTypes = {
  /** Array of expression string terms */
  expression: PT.arrayOf(
    PT.oneOfType([PT.string.isRequired, PT.arrayOf(PT.string.isRequired)])
      .isRequired,
  ),
  /** Record<fieldId, Field> */
  fields: PT.objectOf(
    PT.shape({
      id: PT.string.isRequired,
      name: PT.string.isRequired,
    }),
  ),
  /** TODO: document this more explicitly.  Map field.type to fieldDefinitions as required by the system */
  fieldDefinitions: PT.objectOf(PT.object.isRequired),
  /** Maximum nest level for nested condition expressions */
  maxNestLevel: PT.number,
  /** TODO: document this more explicitly.  Map operator values to operatorDefinitions as required by the system */
  operatorDefinitions: PT.objectOf(PT.object.isRequired),
  /** Captures updated expression */
  onChange: PT.func.isRequired,
};

export default ConditionExpressionBuilder;
