import PT from 'prop-types';
import React from 'react';

import { FlexLayout } from '~/ui';
import { toSnakeTrimmed } from '~/utils/strings';

import Radio from './Radio';

function RadioGroup({
  disabled = false,
  id,
  inline = false,
  options,
  value,
  onChange,
}) {
  return (
    <FlexLayout flexDirection={inline ? 'row' : 'column'} space={3}>
      {options.map((option) => {
        const optionValue = toSnakeTrimmed(option.value.toString());
        return (
          <Radio
            disabled={disabled}
            id={`${id}-${optionValue}`}
            key={option.value}
            option={option}
            selected={option.value === value}
            onChange={onChange}
          />
        );
      })}
    </FlexLayout>
  );
}

RadioGroup.propTypes = {
  /** Disable selection */
  disabled: PT.bool,
  /** Render option ID */
  id: PT.string,
  /** Render options horizontally */
  inline: PT.bool,
  /** Array of options: { value, label } */
  options: PT.array.isRequired,
  /** Currently selected value */
  value: PT.oneOfType([PT.string, PT.bool]),
  /** Callback that returns new selected value */
  onChange: PT.func.isRequired,
};

export default RadioGroup;
