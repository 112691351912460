import { noop } from 'lodash';
import React from 'react';

import {
  Chips,
  CollapsibleText,
  DateText,
  DescriptionList,
  FileSystem,
  LayoutDivider,
  Link,
  LoadingShimmer,
  NumberText,
  Progress,
  StatusText,
  TextArea,
  UserActivity,
} from '../../components';
import { typedMemo } from '../../utils';
import * as types from './types';

export const InfoContent = typedMemo(
  <T extends types.InfoType>({ isLoading, props, type }: types.Info<T>) => {
    if (isLoading) {
      return <LoadingShimmer height="height.m" width="100%" />;
    }
    switch (type) {
      case 'chips':
        return <Chips {...(props as types.InfoProps['chips'])} />;
      case 'content':
        return (props as types.InfoProps['content']).children;
      case 'descriptions':
        return (
          <DescriptionList
            limit={200}
            mode="condensed"
            terms={props as types.InfoProps['descriptions']}
          />
        );
      case 'filesystem':
        return <FileSystem {...(props as types.InfoProps['filesystem'])} />;
      case 'number':
        return <NumberText {...(props as types.InfoProps['number'])} />;
      case 'progress':
        return <Progress {...(props as types.InfoProps['progress'])} />;
      case 'status-texts':
        return (
          <LayoutDivider align="center" spacing={2}>
            {/* @ts-ignore */}
            {props.statusTexts.map(
              (statusText: types.StatusTextProps, i: number) => (
                <StatusText
                  key={i}
                  {...statusText}
                  shouldTruncate
                  icon={`status-${statusText.status}`}
                  textColor="text.primary"
                />
              ),
            )}
          </LayoutDivider>
        );
      case 'text':
        return <CollapsibleText {...(props as types.InfoProps['text'])} />;
      case 'textarea':
        return (
          <TextArea
            {...(props as types.InfoProps['textarea'])}
            onChange={noop}
          />
        );
      case 'user-activity':
        return (
          <UserActivity {...(props as types.InfoProps['user-activity'])} />
        );
      case 'date':
        return <DateText {...(props as types.InfoProps['date'])} />;
      case 'link':
        return <Link {...(props as types.InfoProps['link'])} />;
      default:
        return null;
    }
  },
);
