import { pilot } from '~/services';
import { Dashboard } from '~/types';

export async function getDashboards(): Promise<Dashboard[]> {
  const response = await pilot.get('dashboards/');
  return response.data;
}

export async function getDashboard({
  dashboardId,
}: {
  dashboardId: string;
}): Promise<Dashboard> {
  return pilot.get(`dashboards/${dashboardId}/`);
}
