import React, { memo } from 'react';

import { SharedButtonProps } from '../../types';
import { Box } from '../Box';
import { Button } from '../Button';
import { Image } from '../Image';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { presets } from './presets';

interface Props {
  /** Empty page preset */
  preset: keyof typeof presets;
  /** CTA action for resolving the empty state */
  resolveAction?: SharedButtonProps;
}

export const EmptyPage = memo(({ preset, resolveAction }: Props) => {
  const { description, image, imageAlt, title } = presets[preset];

  return (
    <Layout
      align="center"
      direction="column"
      h="100%"
      justify="center"
      minW="min-content"
      p={12}
      spacing={4}
      w="100%"
    >
      {image && <Image alt={imageAlt} image={image} />}
      <Text textAlign="center" variant="subtitle">
        {title}
      </Text>
      <Text color="text.secondary" textAlign="center">
        {description}
      </Text>
      {resolveAction && (
        <Box pt={8}>
          <Button {...resolveAction} variant="primary" />
        </Box>
      )}
    </Layout>
  );
});
