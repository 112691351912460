import {
  black1,
  black2,
  black3,
  bold,
  evisortBlue,
  evisortRed,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '204px',
  },
  emptyContainer: {
    padding: '80px 0',
    textAlign: 'center',
  },
  primaryEmptyMessage: {
    marginBottom: '8px',
    color: black2,
    fontSize: '17px',
  },
  emptyMessageAction: {
    display: 'inline-block',
    color: evisortBlue,
    cursor: 'pointer',
  },
  alertsList: {
    flexGrow: 1,
  },
  firstCellInRowWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxWrapper: {
    marginRight: '8px',
  },
  chevronIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
  },
  alertsTable: {
    width: '100%',
    borderCollapse: 'collapse',
    color: black2,
    textAlign: 'left',
    '& tr': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      '&:last-child': {
        border: 0,
      },
    },
    '& th': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '16px 0',
      color: black3,
      fontSize: '13px',
      fontWeight: semibold,
    },
    '& td': {
      padding: '12px 0',
      color: black1,
      fontSize: '16px',
    },
  },
  headerCheckbox: {
    width: '40px',
  },
  headerName: {
    width: '358px',
  },
  headerRecipients: {
    width: '110px',
  },
  headerActive: {
    width: '80px',
    textAlign: 'center',
  },
  headerMuted: {
    width: '80px',
    textAlign: 'center',
  },
  headerAlertActions: {
    width: '32px',
  },
  alertNameText: {
    maxWidth: '340px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  createdByText: {
    maxWidth: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cellName: {
    width: '358px',
  },
  cellRecipients: {
    width: '114px',
  },
  cellCreatedBy: {
    width: '144px',
  },
  cellActive: {
    width: '80px',
    textAlign: 'center',
  },
  cellMuted: {
    width: '80px',
    textAlign: 'center',
  },
  cellActions: {
    width: '32px',
  },
  dateCellWrapper: {
    marginRight: '24px',
  },
  verticalSeparator: {
    display: 'block',
    height: '16px',
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  deleteAction: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '24px',
    color: evisortRed,
    fontSize: '13px',
    fontWeight: bold,
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'white',
    '& svg': {
      marginRight: '8px',
    },
  },
  simpleModalText: {
    color: '#404040',
    lineHeight: '1.47',
    '& span': {
      fontWeight: bold,
    },
  },
};

export default styles;
