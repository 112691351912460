import { PreBuiltRoleAdminType } from './types';

export const adminPreBuiltRoles: PreBuiltRoleAdminType[] = [
  'Admin',
  'Admin - Workflow',
  'Admin - Automation',
  'Admin - Workflow - Automation',
  'Administrator',
  'Owner',
];
