export default `
<svg
  aria-hidden="true"
  aria-label="collapse-left"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M6.53125 17.25L6.53125 6.75C6.53125 6.3125 6.21875 6 5.78125 6C5.34375 6 5 6.3125 5 6.75L5 17.25C5 17.6562 5.34375 18 5.78125 18C6.1875 18 6.53125 17.6562 6.53125 17.25ZM13.5313 15.4375L10.6563 12.7188H18.25C18.6875 12.75 19 12.4062 19 12C19 11.5625 18.6875 11.25 18.25 11.25H10.6563L13.5313 8.53125C13.6875 8.375 13.75 8.1875 13.75 8C13.75 7.8125 13.6875 7.625 13.5625 7.46875C13.2813 7.15625 12.8125 7.15625 12.5 7.4375L8.25 11.4375C7.9375 11.7187 7.9375 12.25 8.25 12.5312L12.5 16.5312C12.8125 16.8125 13.2813 16.8125 13.5625 16.5C13.8438 16.1875 13.8438 15.7188 13.5313 15.4375Z"
    fill="currentcolor"
  />
</svg>
`;
