import React, { memo, useEffect, useMemo, useState } from 'react';

import { useEscape } from '../../hooks';
import { DEPRECATED_SharedPanelProps, UserAction } from '../../types';
import { Backdrop } from '../Backdrop';
import { Box } from '../Box';
import { ContentContainer } from '../ContentContainer';
import { Header } from '../Header';
import { Layout } from '../Layout';
import { Tabs } from '../Tabs';

const DEFAULT_PADDING = 6;
const RIGHT_SPACE = '48px';

export const Panel_DEPRECATED = memo(
  ({
    actions = [],
    children,
    chip,
    footer,
    isEmbedded = false,
    isLoading = false,
    isPadded,
    isVisible = false,
    selectedTab: overrideSelectedTab,
    mode,
    tabs,
    title,
    width = 'l',
    onBack,
    onHide,
    onSelectTab,
  }: DEPRECATED_SharedPanelProps) => {
    const initialSelectedTab = useMemo(
      () => overrideSelectedTab ?? tabs?.[0]?.value ?? null,
      [overrideSelectedTab, tabs],
    );

    const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
    useEscape(onHide);

    useEffect(() => setSelectedTab(initialSelectedTab), [initialSelectedTab]);

    if (!isVisible) {
      return null;
    }

    const headerActions: UserAction[] = actions.map((action) => ({
      ...action,
      mode: 'icon',
      text: action.label,
      onClick: () => action.onClick?.(action.value as any),
    }));

    if (!isEmbedded) {
      headerActions.push({
        mode: 'icon',
        icon: 'x',
        text: 'Close',
        tooltip: 'Close',
        onClick: onHide,
      });
    }

    const header = (
      <Header
        actions={headerActions}
        chips={chip ? [chip] : undefined}
        title={title}
        backAction={
          onBack
            ? {
                text: 'Back',
                tooltip: 'Back to previous',
                icon: 'arrow-left',
                onClick: onBack,
              }
            : undefined
        }
      />
    );

    const panel = (
      <Layout
        as="aside"
        direction="column"
        styles={componentStyles.panel({ isEmbedded, width })}
      >
        {header}
        <ContentContainer loadingContent={{ isLoading }}>
          <>
            {selectedTab && tabs && (
              <Tabs
                enableBorder
                enableContentPadding={false}
                panelHeight="100%"
                selectedTab={selectedTab}
                tabs={tabs}
                onSelectTab={(updatedTab) => {
                  onSelectTab?.(updatedTab);
                  setSelectedTab(updatedTab);
                }}
              />
            )}
            {children && (
              <Box
                p={isPadded && !tabs ? DEFAULT_PADDING : 0}
                styles={componentStyles.contents}
              >
                {children}
              </Box>
            )}
            {footer}
          </>
        </ContentContainer>
      </Layout>
    );

    switch (mode) {
      case 'blocking':
        return <Backdrop disableBodyScroll={isVisible}>{panel}</Backdrop>;
      default:
        return panel;
    }
  },
);

const componentStyles = {
  contents: {
    flex: 'auto',
    overflowY: 'auto',
  },
  // * isEmbedded and width are used here because of `fixed` position in combination with `Sidebar`.
  panel: ({
    isEmbedded,
    width,
  }: {
    isEmbedded: boolean;
    width: 'm' | 'l' | 'fill';
  }) => ({
    backgroundColor: 'background',
    border: 'none',
    boxShadow: 'raised',
    bottom: 0,
    height: 'auto',
    position: 'fixed',
    right: 0,
    top: 'DEPRECATED_navbar.height',
    width: getWidth(width),
    zIndex: 'panel',
    ...(isEmbedded
      ? {
          border: 'border.divider',
          height: '100%',
          position: 'static',
          width: getEmbeddedWidth(width),
        }
      : {}),
  }),
};

const getEmbeddedWidth = (width: string) => {
  switch (width) {
    case 'fill':
      return '100%';
    case 'm':
      return 'panel.width.m';
    default:
      return 'panel.width.l';
  }
};

const getWidth = (width: string) => {
  switch (width) {
    case 'fill':
      return `calc(100% - ${RIGHT_SPACE})`;
    case 'm':
      return 'panel.width.m';
    default:
      return 'panel.width.l';
  }
};
