import { last } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import injectSheet from 'react-jss';

import SearchFolderPanel from '~/components/Shared/FolderPanel';
import PageTitle from '~/components/Shared/PageTitle';
import { Box, Icon, Layout, Menu, Popover } from '~/eds';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, withFlags } from '~/flags';
import { withPermissions, withUseEscape } from '~/hocs';
import { withRouting } from '~/routing';
import { filterValidFiles } from '~/utils/helper.utils';

import {
  MODAL_ACCESS_SETTINGS,
  MODAL_DELETE,
  MODAL_FOLDER_TREE,
  MODAL_NAME,
  MODAL_UPLOAD,
} from '../../../types/modal.types';
import { featureFlagIncluded } from '../../../utils/user';
import EcBreadcrumbs from '../../Shared/EcBreadcrumbs';
import EcButton from '../../Shared/EcButton';
import EcFolderPanel from '../../Shared/EcFolderPanel';
import withHover from '../../Shared/HOCs/withHover';
import FolderLookUpIcon from '../../Shared/Icons/FolderLookUpIcon';
import FolderUpIcon from '../../Shared/Icons/FolderUpIcon';
import styles from './DocumentsPageHeader.styles';

const FolderUpIconWithHover = withHover(FolderUpIcon);
const FolderLookUpIconWithHover = withHover(FolderLookUpIcon);
const FolderPanel = withUseEscape(EcFolderPanel);

const folderAccessPermission = {
  resourceId: 'folder_access',
  resourceType: 'edit',
};

const permissionsToCheck = [folderAccessPermission];

class DocumentsPageHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      folderPanelShown: false,
    };

    this.folderPanel = React.createRef();

    this.navigate = this.navigate.bind(this);
    this.handleOnBackClick = this.handleOnBackClick.bind(this);
    this.showFolderPanel = this.showFolderPanel.bind(this);
    this.hideFolderPanel = this.hideFolderPanel.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleMouseDown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouseDown);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.folderData !== this.props.folderData) {
      this.setState({ folderPanelShown: false });
    }
  }

  navigate(path) {
    this.props.navigate(path);
  }

  handleMouseDown(e) {
    if (!this.folderPanel || !this.folderPanel.current.contains(e.target)) {
      this.hideFolderPanel();
    }
  }

  handleOnBackClick(parentId) {
    this.props.navigate(`/documents/${parentId}`);
  }

  showFolderPanel() {
    this.setState({ folderPanelShown: true });
  }

  hideFolderPanel() {
    this.setState({ folderPanelShown: false });
  }

  handleNavigateToFolder = (folder) => {
    if (folder) {
      this.props.navigate(`/documents/${folder.id}`);
    }
  };

  renderFolderPanel() {
    const { folderData, folderPathId } = this.props;

    return (
      <FolderPanel
        folderPathId={folderPathId}
        isRootFolder={!folderData.path || !folderData.path.length}
        hidePanel={() => this.setState({ folderPanelShown: false })}
        onEsc={this.hideFolderPanel}
      />
    );
  }

  renderSearchFolderPanel = () => {
    const { folderData } = this.props;
    return (
      <Box h={400}>
        <SearchFolderPanel
          onSelect={this.handleNavigateToFolder}
          isRootFolder={!folderData.path || !folderData.path.length}
          folderPathId={folderData?.id}
          w={600}
        />
      </Box>
    );
  };

  handleContextMenuOptionSelect = (menuOption) => {
    const { handleShowModal, folderData } = this.props;
    switch (menuOption.value) {
      case 'rename':
        handleShowModal(MODAL_NAME, {
          type: 'folder',
          id: folderData.id,
          name: folderData.name,
          selfUpdate: true,
          parentId: last(folderData.path ?? [])?.id,
        });
        break;
      case 'move':
        handleShowModal(MODAL_FOLDER_TREE, {
          type: 'folder',
          id: folderData.id,
          name: folderData.name,
          action: 'Move',
          selfUpdate: true,
          parentId: last(folderData.path ?? [])?.id,
          contains_sync_pair: folderData.contains_sync_pair,
        });
        break;
      case 'accessSettings':
        handleShowModal(MODAL_ACCESS_SETTINGS, {
          type: 'folder',
          id: folderData.id,
          name: folderData.name,
          parentId: last(folderData.path ?? [])?.id,
          folder_access_list: folderData.folder_access_list,
          content_visibility_level: folderData.content_visibility_level,
          selfUpdate: true,
        });
        break;
      case 'delete':
        handleShowModal(MODAL_DELETE, {
          type: 'folder',
          id: folderData.id,
          name: folderData.name,
          parentId: last(folderData.path ?? [])?.id,
          selfUpdate: true,
        });
        break;
      default:
        break;
    }
  };

  renderHeaderActions() {
    const {
      handleShowModal,
      handleAddUploadFiles,
      user,
      canModifyFolder,
      folderData,
    } = this.props;

    const componentConfig = { postUrl: 'no-url' };
    const djsConfig = {
      autoQueue: false,
      autoProcessQueue: false,
      clickable: '#upload_documents',
      dictDefaultMessage: '',
      previewTemplate: '<div class="ignore"></div>',
    };
    const eventHandlers = {
      init: (dz) => (this.dropzone = dz),
      addedfiles: async (files) => {
        const validFiles = await filterValidFiles(files);
        setTimeout(() => {
          handleAddUploadFiles(validFiles);
          handleShowModal(MODAL_UPLOAD);
        }, 0);
      },
    };

    const hasFolderAccessPermission = this.props.checkPermission(
      folderAccessPermission.resourceId,
      folderAccessPermission.resourceType,
    );
    const menuOptions = [
      {
        label: 'Rename',
        value: 'rename',
      },
      {
        label: 'Move',
        value: 'move',
      },
      {
        label: 'Access Settings',
        value: 'accessSettings',
        hidden: !hasFolderAccessPermission,
      },
      {
        label: 'Delete',
        value: 'delete',
      },
    ];

    return (
      <Layout preset="buttons">
        {this.renderFolderSearchAction(<EcButton text="Search Folders" />)}
        {canModifyFolder ? (
          <EcButton
            ariahaspopup={true}
            id="documents_header_newFolderButton"
            onClick={() => handleShowModal(MODAL_ACCESS_SETTINGS)}
            text="New Folder"
          />
        ) : null}
        {canModifyFolder && featureFlagIncluded(user, 'UPLOAD') ? (
          <DropzoneComponent
            className="document-page-header-dropzone"
            config={componentConfig}
            djsConfig={djsConfig}
            eventHandlers={eventHandlers}
          >
            <div id="upload_documents">
              <EcButton
                ariahaspopup={true}
                id={OnboardingIdType.DocumentUpload}
                yellow
                text="Upload"
              />
            </div>
          </DropzoneComponent>
        ) : null}
        {folderData.path?.length > 0 && (
          <Menu
            trigger={<Icon icon="more" tooltip="More" />}
            options={menuOptions.filter((option) => !option.hidden)}
            onSelectOption={this.handleContextMenuOptionSelect}
          />
        )}
      </Layout>
    );
  }

  renderFolderSearchAction(overrideTrigger) {
    const { classes, flags } = this.props;
    const trigger = overrideTrigger ?? (
      <button
        aria-haspopup="true"
        title="Go to a folder"
        className={classes.folderIcon}
      >
        {<FolderLookUpIconWithHover size="32" />}
      </button>
    );

    return flags[FlagType.SearchFolderPanel] ? (
      <Popover preset="dropdown" trigger={trigger}>
        {this.renderSearchFolderPanel()}
      </Popover>
    ) : (
      <button
        aria-haspopup="true"
        title="Go to a folder"
        className={classes.folderIcon}
        onClick={this.showFolderPanel}
      >
        <FolderLookUpIconWithHover size="32" />
      </button>
    );
  }

  renderFullTitle() {
    const { classes, folderData } = this.props;
    const { folderPanelShown } = this.state;
    return (
      <div className={classes.documentFullTitle}>
        {folderData.name && folderData.path.length ? (
          <EcBreadcrumbs path={folderData.path} />
        ) : null}
        <Layout align="center" spacing={2}>
          {folderData.name ? (
            <PageTitle title={folderData.name} />
          ) : (
            <div className={classes.emptyfolderName} />
          )}
        </Layout>
        <div ref={this.folderPanel}>
          {folderPanelShown ? this.renderFolderPanel() : null}
        </div>
      </div>
    );
  }

  renderBackIcon() {
    const { classes, folderData } = this.props;

    if (folderData.path) {
      if (folderData.path.length > 0) {
        const parentId = folderData.path[folderData.path.length - 1].id;
        return (
          <button
            className={classes.documentBackAction}
            onClick={() => {
              this.handleOnBackClick(parentId);
            }}
            title={`go to ${folderData.name} parent folder`}
          >
            <FolderUpIconWithHover />
          </button>
        );
      } else {
        return null;
      }
    }
  }

  render() {
    const { classes, loading, user } = this.props;

    return (
      <div className={classes.documentHeaderContainer}>
        <div className={classes.documentHeader}>
          {this.renderBackIcon()}
          {this.renderFullTitle()}
          {!loading && Object.keys(user).length
            ? this.renderHeaderActions()
            : null}
        </div>
      </div>
    );
  }
}

DocumentsPageHeader.propTypes = {
  folderData: PropTypes.object.isRequired,
  handleShowModal: PropTypes.func,
  user: PropTypes.object,
};

export default injectSheet(styles)(
  withFlags(
    withRouting(withPermissions(DocumentsPageHeader, permissionsToCheck)),
  ),
);
