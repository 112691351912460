import { camelizeKeys } from 'humps';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { importDocumentMetadataListExcelFile } from '~/api/documentMetadata';
import { showToast } from '~/components/Shared/EcToast';
import { ContentContainer, FileInput } from '~/eds';
import {
  BulkImportDocumentMetadataType,
  CodedErrorCodeType,
  FileExtensionType,
  FileMimeType,
} from '~/enums';
import { SUCCESS } from '~/types/toast.types';
import { Alert, FlexLayout, Text, WizardPageLayout } from '~/ui';
import { parseApiError } from '~/utils/parseApiError';

const DEFAULT_ACCEPTED_FILES = {
  [FileMimeType.Xlsx]: [FileExtensionType.Xlsx],
  [FileMimeType.Xls]: [FileExtensionType.Xls],
};

function UploadStep(props) {
  const { dataTestid, clientId, moveToStep, stepCommonWizardProps } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [
    importFailedWithCurrentFile,
    setImportFailedWithCurrentFile,
  ] = useState(false);

  const isBulkDocumentMetadataImportFileAvailable = Boolean(selectedFile);

  const uploadDocumentMetadataList = () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('client_id', clientId);

    importDocumentMetadataListExcelFile(formData)
      .then((response) => {
        showToast(
          SUCCESS,
          'Document Metadata List file has been imported successfully.',
        );
        response = camelizeKeys(response);
        const { headers, items } = response.dataPreview;
        const summaryData = {
          previewHeaders: headers.map((header) => header.title),
          previewItems: items,
          importId: response.importId,
        };
        moveToStep(BulkImportDocumentMetadataType.Summary, summaryData);
      })
      .catch((errorResponse) => {
        handleImportSummaryApiError(
          errorResponse,
          CodedErrorCodeType.BulkImportDocumentMetadataErrorSubmittingFile,
        );
        setImportFailedWithCurrentFile(true);
        setIsLoading(false);
      });
  };

  const shouldActionButtonDisabled =
    importFailedWithCurrentFile ||
    !isBulkDocumentMetadataImportFileAvailable ||
    isLoading;

  const actionButton = {
    promise: uploadDocumentMetadataList,
    text: 'Preview document metadata',
    disabled: shouldActionButtonDisabled,
  };

  const handleImportSummaryApiError = (errorResponse, customCodeError) => {
    const error = parseApiError(errorResponse, customCodeError);
    setAlertMessage(error.message);
    setShowAlert(true);
  };

  const selectedFileRendered = selectedFile && (
    <Text p={4} body1>
      <Text body2 isBold>
        Uploaded file:{' '}
      </Text>
      {selectedFile.name}
    </Text>
  );

  const handleSelectedFile = (files) => {
    setSelectedFile(files[0]);
    setShowAlert(false);
    setImportFailedWithCurrentFile(false);
  };

  const renderBody = () => {
    return (
      <FlexLayout
        space={4}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        {isLoading ? (
          <h4>Uploading Documents Metadata List</h4>
        ) : (
          <>
            <Text variant="subtitle">Submit Document File</Text>
            {isBulkDocumentMetadataImportFileAvailable && selectedFileRendered}
          </>
        )}

        {showAlert && (
          <Alert
            enableIcon
            variant="danger"
            onDismiss={() => setShowAlert(false)}
          >
            <div>{alertMessage}</div>
          </Alert>
        )}

        <ContentContainer loadingContent={{ isLoading }}>
          <FileInput
            accept={DEFAULT_ACCEPTED_FILES}
            onChange={handleSelectedFile}
            name="Upload Document"
          />
        </ContentContainer>
      </FlexLayout>
    );
  };

  return (
    <WizardPageLayout
      {...stepCommonWizardProps}
      currentStep={BulkImportDocumentMetadataType.Upload}
      actionButton={actionButton}
      children={<div data-testid={dataTestid}>{renderBody()}</div>}
    />
  );
}

UploadStep.propTypes = {
  clientId: PropTypes.string.isRequired,
  moveToStep: PropTypes.func.isRequired,
  stepCommonWizardProps: PropTypes.object.isRequired,
  dataTestid: PropTypes.string.isRequired,
};

export default UploadStep;
