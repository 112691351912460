import { everest } from '~/services';

import { ShareSettings } from './types';

export const updateWorkflowShareSettings = ({
  workflowId,
  shareSettings,
  hasSettings,
}: {
  workflowId: string;
  shareSettings: ShareSettings;
  hasSettings: boolean;
}) => {
  return hasSettings
    ? everest.put(`/workflows/${workflowId}/visibility-settings/`, {
        data: {
          type: 'workflow-visibility',
          attributes: {
            user_ids: shareSettings.userIds,
            department_ids: shareSettings.departmentIds,
          },
        },
      })
    : everest.post(`/workflows/${workflowId}/visibility-settings/`, {
        data: {
          type: 'workflow-visibility',
          attributes: {
            user_ids: shareSettings.userIds,
            department_ids: shareSettings.departmentIds,
          },
        },
      });
};
