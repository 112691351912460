import React from 'react';

import { redirectToDomainLogin } from '~/services/redirects';
import { Box, Button, FlexLayout, Text } from '~/ui';

function ErrorBoundary() {
  return (
    <FlexLayout
      alignItems="center"
      flexDirection="column"
      p={6}
      sx={{ textAlign: 'center' }}
    >
      <Box>
        <Box mb={6}>
          <img
            src="/evisort.png"
            alt="Evisort"
            title="Evisort"
            style={{ width: '180px' }}
          />
        </Box>
        <Box py={6}>
          <Text variant="xl-dense-bold">Sorry! Something went wrong.</Text>
        </Box>
        <Box py={4} sx={{ width: '470px' }}>
          <Text color="gray-800" variant="s-dense">
            Looks like we’ve encountered an issue. It has been reported to our
            team and we will get around to fixing it soon. Sorry for the
            inconvenience.
          </Text>
        </Box>
        <FlexLayout
          alignItems="center"
          flexDirection="column"
          p={6}
          sx={{ textAlign: 'center' }}
        >
          <Button
            text="Go to Home"
            variant="outlined"
            onClick={() => redirectToDomainLogin()}
          />
        </FlexLayout>
      </Box>
    </FlexLayout>
  );
}

export default ErrorBoundary;
