import PT from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box, FlexLayout, Icon } from '~/ui';

function Card({
  children,
  collapseIconPosition = 'left',
  isBordered = true,
  isCollapsed: initialIsCollapsed = false,
  isFullHeight,
  footer,
  header,
  onToggleCollapse,
}) {
  const [isCollapsed, setIsCollapsed] = useState(initialIsCollapsed);
  const getCardStyle = (fullBordered) => {
    if (fullBordered) {
      return {
        cardBorder: 'border',
        cardBorderBottom: null,
        cardBorderRadius: 'm',
        headerContainerSpacing: 6,
      };
    }
    return {
      cardBorder: null,
      cardBorderBottom: 'border',
      cardBorderRadius: null,
      headerContainerSpacing: 0,
    };
  };
  const cardStyle = getCardStyle(isBordered);

  useEffect(() => {
    setIsCollapsed(initialIsCollapsed);
  }, [initialIsCollapsed]);

  function renderIcon() {
    return (
      <>
        {onToggleCollapse && (
          <Icon
            className="ui/card__collapsible-icon"
            color="black-alpha-50"
            icon={isCollapsed ? 'chevronRight' : 'chevronDown'}
            onClick={onToggleCollapse}
            title={isCollapsed ? 'expand' : 'collapse'}
          />
        )}
      </>
    );
  }
  function renderHeaderAndControl() {
    if (collapseIconPosition === 'left') {
      return (
        <>
          {renderIcon()}
          {header}
        </>
      );
    }
    return (
      <>
        {header}
        {renderIcon()}
      </>
    );
  }

  function handleToggleCollapse() {
    const updatedIsCollapsed = !isCollapsed;
    onToggleCollapse && onToggleCollapse(updatedIsCollapsed);
    setIsCollapsed(updatedIsCollapsed);
  }

  return (
    <Box
      bg="white"
      sx={{
        border: cardStyle.cardBorder,
        borderBottom: cardStyle.cardBorderBottom,
        borderRadius: cardStyle.cardBorderRadius,
        height: isFullHeight ? '100%' : undefined,
      }}
    >
      {header && (
        <FlexLayout
          alignItems="center"
          px={cardStyle.headerContainerSpacing}
          space={2}
          sx={{
            borderBottom: 'border',
            borderBottomWidth: isCollapsed ? 0 : '1px',
          }}
          onClick={onToggleCollapse ? handleToggleCollapse : undefined}
        >
          {renderHeaderAndControl()}
        </FlexLayout>
      )}
      {!isCollapsed && (
        <>
          <Box px={cardStyle.headerContainerSpacing} py={6}>
            {children}
          </Box>
          {footer}
        </>
      )}
    </Box>
  );
}

Card.propTypes = {
  /** React children */
  children: PT.node,
  /** Collapse icon on left or right */
  collapseIconPosition: PT.oneOf(['left', 'right']),
  /** has full border or just border bottom */
  isBordered: PT.bool,
  /** is card initially isCollapsed */
  isCollapsed: PT.bool,
  /** If card should take the full height of the container */
  isFullHeight: PT.bool,
  /** Card.Footer component */
  footer: PT.node,
  /** Card.Header component */
  header: PT.node,
  /** Optional callback when collapse changes */
  onToggleCollapse: PT.func,
};

export default Card;
