import { types, visit } from '~/eds';

/**
 * Serializes the AST to a simple string with user email.
 */
export const serializeContentAstWithEmail = (
  ast: types.ContentAst,
  userResolver: Record<number, types.User>,
): string => {
  let string = '';
  const options = {
    testNode: (node: types.AnyNode) =>
      !(
        (node as types.MentionNode).type &&
        (node as types.MentionNode).type === 'mention'
      ),
  };
  visit(
    ast,
    (node) => {
      const mentionNode = node as types.MentionNode;
      if (mentionNode.type && mentionNode.type === 'mention') {
        const user = userResolver[Number(mentionNode.entity.id)];
        if (user) {
          string += user.email;
        }
      } else if ('children' in node) {
        string += ' ';
      } else {
        string += node.text || '';
      }
    },
    options,
  );

  return string.trim();
};
