import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDisabledSaveSearchActionButtonTooltip,
  shouldSaveSearchActionButtonDisabled,
} from '~/components/SearchV2/SearchV2.utils';
import { showToast } from '~/components/Shared/EcToast';
import { api, selectors } from '~/redux';
import search from '~/redux/slices/search';
import { MODAL_DUPLICATE_SAVED_SEARCH } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Modal } from '~/ui/';

import SaveSearchForm from '../SaveSearchForm';

const DuplicateSavedSearchModal = () => {
  const modal = useSelector(selectors.selectSearchResultModal);
  const dispatch = useDispatch();
  const resetModal = () => dispatch(search.actions.setModal());
  const { modalType, context } = modal || {};

  const [saveSearch, saveSearchResult] = api.endpoints.saveSearch.useMutation();
  const {
    isError: duplicateSavedSearchError,
    isSuccess: duplicateSavedSearchSuccess,
  } = saveSearchResult;

  const [formData, setFormData] = useState();

  const actionButton = {
    disabled: shouldSaveSearchActionButtonDisabled(formData),
    text: 'Duplicate',
    tooltip: getDisabledSaveSearchActionButtonTooltip(formData),
    promise: () => saveSearch(formData),
  };

  useEffect(() => {
    if (duplicateSavedSearchError) {
      showToast(ERROR, 'Duplicate saved search failed. Please try again.');
    }
  }, [duplicateSavedSearchError]);

  useEffect(() => {
    if (duplicateSavedSearchSuccess) {
      showToast(
        SUCCESS,
        `${context?.name} search has been successfully duplicated.`,
      );
    }
  }, [duplicateSavedSearchSuccess]);

  return (
    <Modal
      actionButton={actionButton}
      overflowDisplay="visible"
      title="Duplicate Saved Search"
      visible={modalType === MODAL_DUPLICATE_SAVED_SEARCH}
      onCancel={resetModal}
      onHide={resetModal}
    >
      <SaveSearchForm isDuplicate={true} handleFormDataChanges={setFormData} />
    </Modal>
  );
};

export default DuplicateSavedSearchModal;
