import { Bar } from '@nivo/bar';
import { truncate } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { ChartProps } from '~/types';

import { BarChartLabel } from './BarChartLabel';
import { ChartTooltip } from './ChartTooltip';
import { BARCHART_COLOR, UNACTIVE_CHART_COLOR } from './constants';
import defaultTheme from './theme';

interface BarChartProps extends ChartProps {
  orientation?: 'horizontal' | 'vertical';
}

type Datum = {
  label: string;
  value: number;
  bucketValue?: string;
};
type TooltipProps = {
  data: Datum;
};

const CustomTooltip = ({ data }: TooltipProps) => {
  const tooltipContent = `${data.label}: ${data.value}`;
  return <ChartTooltip tooltipContent={tooltipContent} />;
};

export const BarChart = ({
  buckets,
  orientation = 'horizontal',
  width,
  selectedBucket,
  onBucketSelected,
}: BarChartProps) => {
  const reorderedBuckets = useMemo(
    () =>
      orientation === 'horizontal' ? [...buckets].reverse() : [...buckets],
    [orientation, buckets],
  );

  const nivoData: Datum[] = reorderedBuckets.map(
    ({ label, value, bucketValue }) => ({
      label,
      value,
      bucketValue,
    }),
  );

  const horizontalProps = {
    axisLeft: {
      format: (value: string) =>
        truncate(value, { omission: '...', length: 25 }),
    },
    axisBottom: null,
  };

  const verticalProps = {
    axisBottom: {
      format: (value: string) =>
        truncate(value, { omission: '...', length: 18 }),
      tickRotation: buckets.length >= 4 ? -30 : 0,
    },
    axisLeft: null,
  };

  const axis = orientation === 'horizontal' ? horizontalProps : verticalProps;

  const getMargin = () => {
    return {
      bottom: orientation === 'horizontal' ? 10 : 65,
      left: orientation === 'horizontal' ? 158 : 60,
      top: orientation === 'horizontal' ? 15 : 35,
      right: orientation === 'horizontal' ? 60 : 20,
    };
  };

  const CustomLabel = useCallback(
    (props: any) => {
      return (
        <BarChartLabel
          {...props}
          orientation={orientation}
          onClick={(data) => {
            if (
              selectedBucket &&
              (data.label === `${selectedBucket}` ||
                data.bucketValue === selectedBucket)
            ) {
              onBucketSelected?.(null);
            } else {
              onBucketSelected?.(`${data?.bucketValue || data?.label}`);
            }
          }}
        />
      );
    },
    [orientation, selectedBucket],
  );
  // Make bars blue  if there is no range bucket matching.
  const noMatch =
    selectedBucket &&
    !nivoData.find(
      (bucket) =>
        bucket.bucketValue === selectedBucket ||
        bucket.label === selectedBucket,
    );

  return (
    <Bar
      height={300}
      width={width}
      animate={false}
      enableGridY={false}
      data={nivoData}
      layout={orientation}
      keys={['value']}
      indexBy="label"
      axisTop={null}
      axisRight={null}
      tooltip={CustomTooltip}
      labelSkipWidth={12}
      labelSkipHeight={12}
      margin={getMargin()}
      padding={0.2}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      theme={defaultTheme}
      onClick={(datum) => {
        if (
          selectedBucket &&
          (datum.data.label === `${selectedBucket}` ||
            datum.data.bucketValue === selectedBucket)
        ) {
          onBucketSelected?.(null);
        } else {
          onBucketSelected?.(`${datum.data?.bucketValue || datum.data?.label}`);
        }
      }}
      colors={(bar) => {
        if (!selectedBucket || noMatch) return BARCHART_COLOR;

        if (
          bar.data.label === `${selectedBucket}` ||
          bar.data.bucketValue === selectedBucket
        )
          return BARCHART_COLOR;

        return UNACTIVE_CHART_COLOR;
      }}
      labelTextColor="#fff"
      enableLabel={false}
      layers={['grid', 'axes', 'bars', 'markers', 'legends', CustomLabel]}
      {...axis}
    />
  );
};
