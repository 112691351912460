import { createTheme } from 'uinix-ui';

import { animations } from './animations';
import { borders } from './borders';
import { borderWidths } from './borderWidths';
import { colors } from './colors';
import { fontFamilies } from './fontFamilies';
import { fontSizes } from './fontSizes';
import { fontWeights } from './fontWeights';
import { keyframes } from './keyframes';
import { lineHeights } from './lineHeights';
import { opacities } from './opacities';
import { radii } from './radii';
import { shadows } from './shadows';
import { sizes } from './sizes';
import { spacings } from './spacings';
import { zIndices } from './zIndices';

export const theme = createTheme({
  animations,
  borders,
  borderWidths,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  keyframes,
  lineHeights,
  opacities,
  radii,
  sizes,
  shadows,
  spacings,
  zIndices,
});
