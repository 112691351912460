import React, { useMemo } from 'react';

import { Select, types, useDebouncedState } from '~/eds';
import { api } from '~/redux';

interface Props<M> extends types.SharedSelectProps<string, M> {
  extraOptions?: types.Option<string>[];
}
export const ClausesSelect = <M extends boolean>({
  value,
  onChange,
  name,
  extraOptions = [],
  ...rest
}: Props<M>) => {
  const [debouncedSearch, _search, setSearch] = useDebouncedState<string>('');
  const { data } = api.endpoints.getFilterSuggestions.useQuery({
    filterId: 'clause',
    query: debouncedSearch,
  });
  const options: types.Option<string>[] = useMemo(() => {
    if (data) {
      const optionsList = data.data.map(({ attributes }) => ({
        label: attributes.label,
        value: attributes.value,
      }));

      return [...extraOptions, ...optionsList];
    }
    return [];
  }, [data, extraOptions]);

  return (
    <Select
      {...rest}
      value={value}
      onChange={onChange}
      name={name}
      options={options}
      onSearch={setSearch}
    />
  );
};
