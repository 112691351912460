import {
  CrmType,
  EsignatureType,
  ProcurementIntegrationType,
  StorageProviderType,
} from '~/enums';

interface Integration<ProviderType> {
  inactiveMessage?: string;
  name: string;
  provider: ProviderType;
  title?: string;
}

export const esignatureIntegrations: Record<
  EsignatureType,
  Integration<EsignatureType>
> = {
  [EsignatureType.Adobesign]: {
    name: 'Adobe Sign',
    provider: EsignatureType.Adobesign,
  },
  [EsignatureType.Docusign]: {
    name: 'Docusign',
    provider: EsignatureType.Docusign,
  },
};

export const crmIntegrations: Record<CrmType, Integration<CrmType>> = {
  [CrmType.Salesforce]: {
    name: 'Salesforce',
    provider: CrmType.Salesforce,
    title: 'Evisort Contract Management for Salesforce',
  },
};

export const procurementIntegrations: Record<
  ProcurementIntegrationType,
  Integration<ProcurementIntegrationType>
> = {
  [ProcurementIntegrationType.Ariba]: {
    name: 'Ariba',
    provider: ProcurementIntegrationType.Ariba,
    title: 'Evisort Procurement Management for Ariba',
  },
};

export const storageIntegrations: Record<
  StorageProviderType,
  Integration<StorageProviderType>
> = {
  [StorageProviderType.Dropbox]: {
    name: 'Dropbox',
    provider: StorageProviderType.Dropbox,
  },
  [StorageProviderType.GoogleDrive]: {
    name: 'Google Drive',
    provider: StorageProviderType.GoogleDrive,
  },
  [StorageProviderType.AmazonS3]: {
    name: 'Amazon S3',
    provider: StorageProviderType.AmazonS3,
  },
  [StorageProviderType.Box]: {
    name: 'Box',
    provider: StorageProviderType.Box,
  },
  [StorageProviderType.OneDrive]: {
    name: 'OneDrive',
    provider: StorageProviderType.OneDrive,
  },
  [StorageProviderType.SharePoint]: {
    name: 'SharePoint',
    provider: StorageProviderType.SharePoint,
  },
};
