type JsonApiErrorFormat = {
  code: string;
  detail: string;
  status: string;
  title: string;
};

const defaultUnknownError = {
  code: 'UnknwonError',
  detail: 'Unknown Error',
  status: '500',
  title: 'Unknown Error',
};

class JsonApiError extends Error {
  errors: JsonApiErrorFormat[];
  constructor(errors: JsonApiErrorFormat[]) {
    super('JsonApiError');
    // if for some reason `errors` is undefined, set it to a default unknown value
    this.errors = errors ?? [defaultUnknownError];
  }
}

export { JsonApiError };
export type { JsonApiErrorFormat };
