import React, { useEffect, useState } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import {
  AsyncSelectComponent,
  LabeledOperator,
} from '~/components/Shared/Filters_DEPRECATED';
import {
  Box,
  Button,
  Divider,
  Label,
  Layout,
  Select,
  TextArea,
  useToggle,
} from '~/eds';
import {
  ClauseValue,
  Field,
  Filter,
  TextSearch,
  TextSearchContains,
  TextSearchScope,
} from '~/evifields';
import { useCurrentUser } from '~/hooks';
import { Nullable } from '~/types';

interface Props {
  field: Field<'clause_v2'>;
  filter: Filter<ClauseValue>;
  onChange: (updatedValues: ClauseValue[]) => void;
}

const ClauseValues = ({ field, filter, onChange }: Props) => {
  const { render, settings } = field;
  const { textSearchFilter } = settings;
  const { id, values, operatorId } = filter;
  const [clauseValue] = values;
  const textSearch = clauseValue?.text_search[0] || null;
  const provisionValue = clauseValue?.provision || null;
  const textSearchOperatorValue = textSearch?.scope?.value || null;
  const textSearchValue = textSearch?.text || null;
  const hasProvision = Boolean(provisionValue);
  const isContainsAny = operatorId === 'contains_any';
  const shouldEnableSearchWithClause = hasProvision && isContainsAny;

  const [draftClauseOperatorId, setDraftClauseOperatorId] = useState<
    Nullable<string>
  >(textSearchOperatorValue);
  const [draftClauseText, setDraftClauseText] = useState<Nullable<string>>(
    textSearchValue,
  );
  const user = useCurrentUser();
  const [
    shouldSearchWithinClause,
    _toggleSearchWithinClause,
    enableSearchWithinClause,
    disableSearchWithinClause,
  ] = useToggle(false);

  useEffect(() => {
    if (!isContainsAny) {
      handleCancelClauseSearch();
    }
  }, [isContainsAny]);

  const handleClauseProvisionChange = (
    updatedClauseProvision: Nullable<string>,
  ) => {
    if (updatedClauseProvision !== null) {
      const updatedClause = {
        provision: updatedClauseProvision,
        text_search: [],
      };
      onChange([updatedClause]);
    }
  };

  const handleCancelClauseSearch = () => {
    resetDraft(textSearch);
    disableSearchWithinClause();
    trackSegment('Clause Type - Cancel', {
      user_id: user.id,
      client_id: user.client,
    });
  };

  const handleConfirmClauseSearch = () => {
    trackSegment('Clause Type - Confirm', {
      user_id: user.id,
      client_id: user.client,
    });

    let updatedTextSearch: TextSearch[] = [];
    if (draftClauseOperatorId) {
      const contains = isContainsAny
        ? { value: 'contains', label: 'contains' }
        : { value: 'does_not_contain', label: 'does not contain' };
      const textOperator = textSearchFilter?.operators.find(
        (op: LabeledOperator) => op.id === draftClauseOperatorId,
      );
      const scope = {
        value: textOperator?.id || '',
        label: textOperator?.label || '',
      };

      updatedTextSearch = [
        {
          contains: contains as TextSearchContains,
          scope: scope as TextSearchScope,
          text: draftClauseText || '',
        },
      ];
    }
    const updatedClauseValues: ClauseValue[] = provisionValue
      ? [
          {
            ...clauseValue,
            provision: provisionValue,
            text_search: updatedTextSearch,
          },
        ]
      : [];

    onChange(updatedClauseValues);
    disableSearchWithinClause();
  };

  const resetDraft = (textSearch: Nullable<TextSearch>) => {
    let updatedDraftClauseOperatorId = null;
    let updatedDraftClauseText = null;
    if (textSearch) {
      updatedDraftClauseOperatorId = textSearch.scope.value;
      updatedDraftClauseText = textSearch.text;
    }

    setDraftClauseOperatorId(updatedDraftClauseOperatorId);
    setDraftClauseText(updatedDraftClauseText);
  };

  const innerProps = {
    enableOptionIndicator: true,
    enableSearchIcon: true,
    enableControl: true,
    isEmbedded: true,
    isClearable: false,
    isMulti: false,
    name: id,
    placeholder: 'Search for a clause...',
    value: provisionValue,
    onChange: handleClauseProvisionChange,
  };

  const clauseContent = (
    <>
      {render?.({
        Component: !shouldSearchWithinClause
          ? AsyncSelectComponent
          : () => <></>,
        innerProps,
      })}
      {shouldSearchWithinClause && (
        <Layout direction="column" px={px} spacing={2}>
          <Label>{provisionValue}</Label>
          <ClauseOperatorSelect
            value={draftClauseOperatorId}
            onChange={setDraftClauseOperatorId}
            clauseOptions={textSearchFilter?.operators || []}
          />
          <TextArea
            name="clause-text"
            value={draftClauseText}
            onChange={setDraftClauseText}
          />
        </Layout>
      )}
    </>
  );

  const actions = shouldSearchWithinClause ? (
    <Layout preset="buttons">
      <Button preset="cancel" onClick={handleCancelClauseSearch} />
      <Button
        disabled={!(draftClauseOperatorId && draftClauseText)}
        text="Confirm"
        variant="action"
        onClick={handleConfirmClauseSearch}
      />
    </Layout>
  ) : (
    shouldEnableSearchWithClause && (
      <Layout direction="column" spacing={2}>
        <Divider />
        <Box px={px}>
          <Button
            text="Search within clause text"
            variant="action"
            onClick={enableSearchWithinClause}
          />
        </Box>
      </Layout>
    )
  );

  return (
    <Box mx={-px}>
      {clauseContent}
      {actions}
    </Box>
  );
};

interface ClauseOperatorSelectProps {
  value: Nullable<string>;
  onChange: (updatedClauseOperatorId: Nullable<string>) => void;
  clauseOptions: LabeledOperator[];
}

const ClauseOperatorSelect = ({
  value,
  onChange,
  clauseOptions,
}: ClauseOperatorSelectProps) => {
  return (
    <Select
      isClearable={false}
      isMulti={false}
      isSearchable={false}
      enablePortal
      name="clause-operator"
      options={clauseOptions.map((textOperator) => ({
        value: textOperator.id,
        label: textOperator.label,
      }))}
      placeholder="Choose an operator"
      value={value}
      onChange={onChange}
    />
  );
};

const px = 4;

export default ClauseValues;
