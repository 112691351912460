import { EntityType, TicketStatusType } from '~/enums';
import { everest } from '~/services';
import { bytesToBlob, coerceFileType } from '~/utils/files';

import { judgmentResultActivity } from './mocks/tickets';
import { getAvailableWorkflows } from './workflows';

export async function getTicketsPageData() {
  const tickets = await getTickets();
  const workflowTemplates = await getAvailableWorkflows();

  return { tickets, workflowTemplates };
}

export async function getNewTickets({ page, pageSize, sort, filter }) {
  return await everest.get('/tickets/list', {
    params: { page, pageSize, sort, filter },
  });
}

export function getTickets() {
  return everest.get('/tickets/');
}

export async function createTicket({
  intakeForm,
  counterpartyFile,
  turnTracking,
}) {
  const formData = new FormData();
  formData.append('intakeForm', JSON.stringify(intakeForm));
  if (counterpartyFile) {
    formData.append('file', counterpartyFile);
  }
  if (!!turnTracking?.partyId) {
    formData.append('turnTracking', JSON.stringify(turnTracking));
  }
  return await everest.post('/tickets/', formData);
}

export async function getTicketComments({ ticketId }) {
  return await everest.get(`/tickets/${ticketId}/comments`);
}

export async function getTicketSigners({ ticketId }) {
  return await everest.get(`/tickets/${ticketId}/signers`);
}

export async function uploadSignedDocument({
  ticketId,
  text = '',
  file,
  signerIds,
  isKeepingUploadedFilename = false,
}) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('text', text);
  formData.append('isKeepingUploadedFilename', isKeepingUploadedFilename);
  signerIds.forEach((signerId) => formData.append('signerIds', signerId));

  return await everest.post(`/tickets/${ticketId}/upload-signed`, formData);
}

export async function getTicketIntakeForm(ticketId) {
  return await everest.get(`/tickets/${ticketId}/intake-form`);
}

export async function partialUpdateTicket(ticketId, payload) {
  return await everest.patch(`/tickets/${ticketId}`, payload);
}

export async function updateTicket(ticketId, payload) {
  return await everest.put(`/tickets/${ticketId}`, payload);
}

// TODO - remove `disableRedirect` when opinionated redirection is handled in `createService`
export async function deleteTicket({ ticketId }, disableRedirect = false) {
  return await everest.remove(`/tickets/${ticketId}`, {
    data: { disableRedirect },
  });
}

export async function completeTicket({ ticketId }) {
  return await everest.put(`/tickets/${ticketId}/complete/`);
}

export async function patchTicketStage({ ticketId }) {
  return await everest.post(`/tickets/${ticketId}/next-stage`);
}

export async function backToReview({ ticketId, shouldResetApprovals = true }) {
  return await everest.post(`/tickets/${ticketId}/back-to-review/`, {
    resetApprovals: shouldResetApprovals,
  });
}

export async function addNewReminder({ ticketId, approvalId }) {
  return await everest.post(
    `/tickets/${ticketId}/judgment-results/${approvalId}/remind/`,
  );
}

export async function shareTicketDocument({ data, documentId, versionId }) {
  return await everest.post(
    `/documents/${documentId}/versions/${versionId}/share`,
    {
      emailsTo: data.to,
      emailsCc: data.cc,
      emailsBcc: data.bcc,
      subject: data.subject,
      body: data.body,
    },
  );
}

export async function shareTicketDocumentAttachments({ data, ticketId }) {
  const {
    to,
    cc,
    bcc,
    subject,
    body,
    attachments,
    uploadedAttachments,
    turnTracking,
  } = data;
  const formData = new FormData();
  to && to.forEach((toEmail) => formData.append('emailsTo', toEmail));
  cc && cc.forEach((ccEmail) => formData.append('emailsCc', ccEmail));
  bcc && bcc.forEach((bccEmail) => formData.append('emailsBcc', bccEmail));
  formData.append('body', body);
  formData.append('subject', subject);
  formData.append('onPlatformAttachments', JSON.stringify(attachments));
  if (!!turnTracking?.partyId) {
    formData.append('turnTracking', JSON.stringify(turnTracking));
  }
  uploadedAttachments &&
    uploadedAttachments.forEach((uploadAttachment) =>
      formData.append('uploadedAttachments', uploadAttachment),
    );
  return await everest.post(`/tickets/${ticketId}/share`, formData);
}

export async function uploadNewDocumentVersion({
  documentId,
  file,
  text,
  isCounterparty = false,
  isKeepingUploadedFilename = true,
  isGenerated = false,
  referenceVersionId = null,
  targetVersionId = null,
  userIds = null,
  turnTracking,
}) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('text', text);
  formData.append('isCounterparty', isCounterparty);
  formData.append('isKeepingUploadedFilename', isKeepingUploadedFilename);
  formData.append('isGenerated', isGenerated);
  referenceVersionId &&
    formData.append('referenceVersionId', referenceVersionId);
  targetVersionId && formData.append('targetVersionId', targetVersionId);
  userIds &&
    userIds.length > 0 &&
    userIds.forEach((userId) => formData.append('userIds', userId));
  if (!!turnTracking?.partyId) {
    formData.append('turnTracking', JSON.stringify(turnTracking));
  }

  return await everest.post(`/documents/${documentId}/versions`, formData);
}

export async function patchTicketApproval({
  ticketId,
  approvalId,
  status,
  note,
}) {
  const data = { status, comment: note };
  return await everest.patch(
    `/tickets/${ticketId}/judgment-results/${approvalId}/`,
    data,
  );
}

export async function reassignApproval({
  ticketId,
  issueId,
  oldAssigneeId,
  newAssigneeId,
  entityType,
}) {
  return await everest.patch(
    `/tickets/${ticketId}/issues/${issueId}/reassign`,
    {
      oldUserId: oldAssigneeId,
      assigneeUserId: newAssigneeId,
      entityType,
    },
  );
}

export async function reassignCoordinator({
  ticketId,
  stageId,
  coordinatorId,
}) {
  return await everest.patch(`/tickets/${ticketId}/stages/${stageId}`, {
    userId: coordinatorId,
  });
}

export async function getActivities({ entityId, entityType, status }) {
  const params = { entityId, entityType };

  switch (entityType) {
    case EntityType.Ticket:
      return everest.get('/activities', { params });
    default:
      return judgmentResultActivity(status);
  }
}

export async function getTicketDocumentVersions(documentId) {
  const documentVersions = await everest.get(
    `/documents/${documentId}/versions`,
  );
  return documentVersions.map((documentVersion) => ({
    ...documentVersion,
    fileType: coerceFileType(documentVersion.fileType),
  }));
}

export async function getTicketDocumentFiles({ documentId, versionId }) {
  const config = { responseType: 'blob' };

  let original;
  try {
    original = await everest.get(
      `/documents/${documentId}/versions/${versionId}/download/original`,
      config,
    );
  } catch {
    original = null;
  }

  return {
    orig: bytesToBlob(original, original.type),
    html: null, // deprecated. Tickets no longer maintain an html preview.
  };
}

export async function downloadTicketDocument({
  documentId,
  versionId,
  isWorkingDraft = false,
}) {
  let original;
  try {
    original = await everest.get(
      `/documents/${documentId}/versions/${versionId}/download-file`,
      {
        params: {
          isWorkingDraft,
        },
        responseType: 'blob',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      },
    );
  } catch {
    original = null;
  }

  return bytesToBlob(original, original.type);
}

export async function downloadTicketDocumentWithoutActivityLog({
  documentId,
  versionId,
  isWorkingDraft = false,
}) {
  const docFile = await everest.get(
    `/documents/${documentId}/versions/${versionId}/download/${
      isWorkingDraft ? 'working_draft' : 'original'
    }`,
    {
      responseType: 'blob',
    },
  );

  return bytesToBlob(docFile, docFile.type);
}

export async function patchTicketStatus({
  entityId,
  ticketId,
  status,
  ...params
}) {
  let statusAction;
  switch (status) {
    case TicketStatusType.Approved:
      statusAction = 'approve';
      break;
    case TicketStatusType.Rejected:
      statusAction = 'reject';
      break;
    default:
      statusAction = 'unsupported action';
  }

  const url = `/tickets/${ticketId}/issues/${entityId}/${statusAction}`;
  const resp = await everest.patch(url, params);
  const { activities, approvals } = resp;

  return {
    id: entityId,
    status: resp.status,
    activities,
    approvals,
    comment: params.comment,
  };
}

export async function checkESignatureLogin({
  ticketId,
  esignatureProvider,
  ticketUrl,
}) {
  const data = { ticketId, esignatureProvider, ticketUrl };
  return await everest.post('esignatures/user', data);
}

export async function signDocument({ ticketId, ticketUrl }) {
  return await everest.post(`tickets/${ticketId}/sign-envelope`, { ticketUrl });
}

export function updateEnvelope(ticketId) {
  return everest.patch(`tickets/${ticketId}/update-envelope`, null, {
    fullResponse: true,
  });
}

export async function resetApproval(
  ticketId,
  issueId,
  comment,
  entityType,
  assignees,
) {
  return await everest.patch(`/tickets/${ticketId}/issues/${issueId}/reset/`, {
    comment,
    entityType,
    assignees,
  });
}

export async function resetApprovals(ticketId, stageId, comment) {
  return await everest.patch(
    `/tickets/${ticketId}/stages/${stageId}/reset-approvals/`,
    { comment },
  );
}

export async function getTicketParticipants({ ticketId }) {
  return await everest.get(`/tickets/${ticketId}/participants/`);
}

export async function removeTicketParticipant(ticketId, participantId) {
  return await everest.remove(
    `/tickets/${ticketId}/participants/${participantId}/`,
  );
}

export function addTicketParticipants({ ticketId, userIds }) {
  return everest.post(`/tickets/${ticketId}/participants`, { userIds });
}

export async function downloadTicketAttachment(attachmentId) {
  const config = { responseType: 'blob' };

  let original;
  try {
    original = await everest.get(`/attachments/${attachmentId}/`, config);
  } catch {
    original = null;
  }

  return bytesToBlob(original, original.type);
}

export async function uploadAtttachment(file, fieldId) {
  const formData = new FormData();
  formData.append('file', file);
  if (fieldId) {
    formData.append('intake_form_field_id', fieldId);
  }
  return await everest.post('/attachments/', formData); // subject to location change if Pilot not everest
}

export const uploadAtttachments = async (
  files,
  callback,
  errorHandler,
  fieldId,
) => {
  const promises = files.map((file) => {
    return uploadAtttachment(file, fieldId).catch((error) => {
      errorHandler(error, file?.name);
    });
  });

  return (
    everest
      .concurrentRequests(promises)
      // rejected promises will return undefined, so we filter them out
      .then((data) => callback(data.filter((item) => item !== undefined)))
  );
};

export async function checkEnvelope(ticketId) {
  return everest.get(`/tickets/${ticketId}/check-envelope/`);
}

export function deleteTicketDocumentVersion(documentId, versionId) {
  return everest.remove(`/documents/${documentId}/versions/${versionId}`);
}

export function bumpVersionForCounterpartyVersion({ documentId, versionId }) {
  return everest.post(
    `/documents/${documentId}/versions/${versionId}/bump-version/`,
  );
}

export function notifyMentionedUsers(
  documentId,
  versionId,
  commentId,
  payload,
) {
  return everest.post(
    `/documents/${documentId}/versions/${versionId}/comments/${commentId}`,
    payload,
  );
}

export function getTicketAttachments(ticketId) {
  return everest.get(`/tickets/${ticketId}/attachments/`);
}

export function updateSignersOrder({ id, signers }) {
  var payload = [];
  for (var i = 0; i < signers.length; i++) {
    const item = signers[i];
    payload.push({ position: i, judgmentResultId: item.approvalIds[0] });
  }
  return everest.patch(`/tickets/${id}/signers`, { data: payload });
}

export function updateRecipients({ ticketId, updatedRecipients }) {
  const payload = updatedRecipients.map((item, index) => {
    return {
      newCC: false,
      judgmentResultId: item.approvalIds[0],
      position: index,
      deleteCC: item.deleteCC,
    };
  });
  return everest.patch(`/tickets/${ticketId}/signers`, { data: payload });
}
