export function getPinnedFilters(categories) {
  return categories
    .map((f) => f.fields)
    .flat()
    .filter((f) => f.is_pinned)
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export function updateFilterPinnedProperty(filterId, filterOptions) {
  return filterOptions.map((category) => {
    const categoryFields = category.fields;
    const fieldIndex = categoryFields.findIndex(
      (field) => field.id === filterId,
    );

    if (fieldIndex === -1) return category;

    categoryFields[fieldIndex] = {
      ...categoryFields[fieldIndex],
      is_pinned: !categoryFields[fieldIndex].is_pinned,
    };
    return category;
  });
}
