import React from 'react';

import { Layout, Text } from '~/eds';

type tooltipContent = { tooltipContent: string };

export const ChartTooltip = ({ tooltipContent }: tooltipContent) => {
  return (
    <Layout
      p={2}
      bg="#000"
      borderRadius={4}
      direction="column"
      maxW={350}
      justify="center"
      align="center"
    >
      <Text
        fontSize={12}
        color="inverse.text.primary"
        textAlign="center"
        minW={100}
        maxW={350}
        whiteSpace="nowrap"
      >
        {tooltipContent}
      </Text>
    </Layout>
  );
};
