import PT from 'prop-types';
import React from 'react';

import { Box, Icon, Text } from '~/ui';

function ButtonAddSection({ disabled, id, text = 'Add a section', onClick }) {
  return (
    <Box
      as="button"
      disabled={disabled}
      id={id}
      p={4}
      sx={{
        alignItems: 'center',
        bg: 'black-alpha-04',
        border: 'none',
        borderRadius: 'm',
        display: 'flex',
        justifyContent: 'center',
        outline: 'none',
        width: '100%',
        '&:hover': {
          backgroundColor: 'black-alpha-02',
        },
        '&:active': {
          backgroundColor: 'black-alpha-10',
        },
      }}
      type="button"
      onClick={onClick}
    >
      <Icon color="black-alpha-50" icon="plus" size="xs" />
      <Text color="black-alpha-50" ml={2} variant="xs-dense-bold">
        {text}
      </Text>
    </Box>
  );
}

ButtonAddSection.propTypes = {
  /** Disable the button */
  disabled: PT.bool,
  /** Button ID */
  id: PT.string,
  /** Button text */
  text: PT.string,
  /** Click handler */
  onClick: PT.func,
};

export default ButtonAddSection;
