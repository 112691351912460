import { bold } from '../../../assets/shared-styles/general';

const styles = {
  modalBodyContent: {
    '& span': {
      fontWeight: bold,
    },
  },
  infoComponent: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    marginTop: '16px',
    padding: '8px 16px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontSize: '14px',
    lineHeight: '21px',
    '& svg': {
      marginRight: '12px',
    },
    '& span': {
      fontWeight: bold,
    },
  },
  modalInput: {
    width: '100%',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    marginTop: '12px',
    padding: '12px 16px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    fontSize: '15px',
    '&:focus': {
      outline: 'none',
    },
  },
  loadingContainer: {
    padding: '24px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
};

export default styles;
