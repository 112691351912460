import { ActionsMenu, Button } from '~/eds';

type SaveAction = {
  tooltip?: string;
  disabled?: boolean;
  onClick: () => void;
};

interface Props {
  entity: 'Search' | 'Dashboard';
  overwriteAction?: SaveAction;
  saveAsNewAction: SaveAction;
}

export const SaveActionButton = ({
  entity,
  overwriteAction,
  saveAsNewAction,
}: Props) => {
  const btnText = `Save ${entity}`;
  const { tooltip, disabled, onClick } = overwriteAction ?? {};
  const {
    tooltip: saveAsNewTooltip,
    disabled: saveAsNewDisabled,
    onClick: onSaveAsNew,
  } = saveAsNewAction;

  return overwriteAction ? (
    <ActionsMenu
      trigger={
        <Button
          variant="secondary"
          icon="save"
          text={btnText}
          preset="dropdown"
        />
      }
      actions={[
        {
          label: `Save as a New ${entity}`,
          onClick: onSaveAsNew,
          tooltip: saveAsNewTooltip,
          disabled: saveAsNewDisabled,
          value: 'new',
        },
        {
          label: `Overwrite Saved ${entity}`,
          value: 'overwrite',
          onClick,
          tooltip,
          disabled,
        },
      ]}
      name="save-search-menu"
      icon="chevron-down"
    />
  ) : (
    <Button
      variant="secondary"
      icon="save"
      iconPosition="left"
      text={btnText}
      disabled={saveAsNewDisabled}
      onClick={onSaveAsNew}
    />
  );
};
