import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { Button } from '~/eds';

import { evisortBlue } from '../../../assets/shared-styles/general';
import {
  MODAL_DELETE,
  MODAL_DEPARTMENT_MEMBERS,
  MODAL_DEPARTMENT_TREE,
} from '../../../types/modal.types';
import EcMemberItem from '../EcMemberItem';
import PlusIcon from '../Icons/PlusIcon';
import styles from './EcMembersTable.styles';

class EcMembersTable extends Component {
  constructor(props) {
    super(props);

    this.handleMemberCheck = this.handleMemberCheck.bind(this);

    this.state = {
      membersChecked: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.listItems !== prevState.listItems) {
      return { listItems: nextProps.listItems, membersChecked: [] };
    } else {
      return null;
    }
  }

  handleMemberCheck(member, memberChecked) {
    const { membersChecked } = this.state;

    if (memberChecked) {
      this.setState({ membersChecked: [...membersChecked, member] });
    } else {
      const usersSelected = membersChecked.filter((item) => item !== member);

      this.setState({ membersChecked: usersSelected });
    }
  }

  renderMembersItems() {
    const {
      classes,
      clientId,
      listItems,
      header,
      handleShowModal,
    } = this.props;
    const isForUnassignedMembers = header === 'Unassigned Users';

    if (!listItems.length)
      return (
        <div className={classes.noDataMessage}>
          No users found in this department.
        </div>
      );
    return listItems.map((item, index) => {
      return (
        <EcMemberItem
          clientId={clientId}
          item={item}
          isForUnassignedMembers={isForUnassignedMembers}
          index={index}
          key={`department-member-${item.id}`}
          handleShowModal={handleShowModal}
          handleMemberCheck={this.handleMemberCheck}
        />
      );
    });
  }

  handleAddUsersToDepartment() {
    const { handleShowModal, handleAddUnassignedUsers } = this.props;
    const { membersChecked } = this.state;

    handleShowModal(MODAL_DEPARTMENT_TREE);
    handleAddUnassignedUsers(membersChecked);
  }

  handleRemoveMembers() {
    const { handleShowModal, handleRemoveUsers } = this.props;
    const { membersChecked } = this.state;

    handleRemoveUsers(membersChecked);
    handleShowModal(MODAL_DELETE);
  }

  render() {
    const { classes, listItems, header, handleShowModal } = this.props;
    const { membersChecked } = this.state;
    const isForUnassignedMembers = header === 'Unassigned Users';

    return (
      <div
        className={classes.departmentMembersTable}
        data-testid="department-members-table"
      >
        <div className={classes.tableHeaderWrapper}>
          <div className={classes.tableHeaderText}>
            {header ? header : 'Users'}
          </div>
          <div className={classes.headerButtonWrapper}>
            {isForUnassignedMembers && membersChecked.length ? (
              <button
                className={classes.headerButton}
                onClick={() => this.handleAddUsersToDepartment()}
              >
                <PlusIcon size="16" color={evisortBlue} />
                <span>Add Users to Department</span>
              </button>
            ) : !isForUnassignedMembers &&
              membersChecked.length &&
              listItems.length ? (
              <Button
                variant="action"
                text="Remove from Department"
                className={classes.headerButton}
                onClick={() => this.handleRemoveMembers()}
              />
            ) : !isForUnassignedMembers ? (
              <button
                className={classes.headerButton}
                onClick={() => handleShowModal(MODAL_DEPARTMENT_MEMBERS)}
              >
                <PlusIcon size="16" color={evisortBlue} />
                <span>Add Users</span>
              </button>
            ) : (
              <div className={classes.noHeaderAction} />
            )}
          </div>
        </div>
        <div>{this.renderMembersItems()}</div>
      </div>
    );
  }
}

EcMembersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  listItems: PropTypes.array.isRequired,
  handleShowModal: PropTypes.func,
};

export default injectSheet(styles)(EcMembersTable);
