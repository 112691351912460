import {
  fromInternalState,
  fromSelectedRowIdsMap,
  toSelectedRowIdsMap,
} from './utils';

/**
 * Create a react-table stateReducer closed over derived variables to override handling of actions in controlled mode.
 *
 * @typedef {import('./utils').State} State
 *
 * @param {object} params
 *    derived data computed in `Table` component
 * @param {string[]} params.columnOrder
 * @param {boolean} params.isAllRowsSelected
 * @param {boolean} params.isControlled
 * @param {string} params.primaryColumnKey
 * @param {string[]} params.rowIds
 * @param {number} params.totalCount
 * @param {(state: State) => void} params.onUpdate
 * @returns {function}
 *    react-table's stateReducer (untyped because type identifier is not explicitly exported)
 */
export const createStateReducer = ({
  columnOrder,
  isAllRowsSelected,
  isControlled,
  isMultiSelectRows,
  name,
  primaryColumnKey,
  rowIds,
  totalCount,
  onUpdate,
}) => {
  return (newState, action) => {
    let updatedState = newState;

    switch (action.type) {
      case 'init': {
        updatedState = {
          ...newState,
          // track additional state
          name,
          columnOrder,
          isAllRowsSelected: isAllRowsSelected || false,
          isMultiSelectRows,
          primaryColumnKey,
          trackedSelectedRowIndex: null,
          totalCount,
        };
        break;
      }
      case 'resetHiddenColumns': {
        updatedState = {
          ...newState,
          columnOrder,
        };
        break;
      }
      case 'toggleRowSelected':
      case 'toggleAllPageRowsSelected': {
        let selectedRowIds = {};
        if (newState.selectedRowIds[action.id]) {
          selectedRowIds = {
            [action.id]: true,
          };
        }
        updatedState = {
          ...newState,
          selectedRowIds: isMultiSelectRows
            ? newState.selectedRowIds
            : selectedRowIds,
          isAllRowsSelected: getIsAllRowsSelectedFromSelectedRowIds({
            isControlled,
            selectedRowIds,
            totalCount,
          }),
          trackedSelectedRowIndex: null,
        };
        break;
      }
      case 'toggleAllRowsSelected': {
        const updatedIsAllRowsSelected = !newState.isAllRowsSelected;
        updatedState = {
          ...newState,
          isAllRowsSelected: updatedIsAllRowsSelected,
          selectedRowIds: updatedIsAllRowsSelected
            ? toSelectedRowIdsMap(rowIds)
            : {},
          trackedSelectedRowIndex: null,
        };
        break;
      }
      case 'toggleSortBy': {
        updatedState = {
          ...newState,
          pageIndex: 0,
          trackedSelectedRowIndex: null,
        };
        break;
      }
      case 'trackSelectedRow': {
        updatedState = {
          ...newState,
          trackedSelectedRowIndex: action.payload,
        };
        break;
      }
      // merge the updated selected row IDs and re-update `isAllRowsSelected`.
      case 'toggleMultipleRowsSelected': {
        const updatedSelectedRowIds = {
          ...newState.selectedRowIds,
          ...toSelectedRowIdsMap(action.payload),
        };
        updatedState = {
          ...newState,
          selectedRowIds: updatedSelectedRowIds,
          isAllRowsSelected: getIsAllRowsSelectedFromSelectedRowIds({
            isControlled,
            selectedRowIds: updatedSelectedRowIds,
            totalCount,
          }),
        };
        break;
      }
      default:
        break;
    }

    if (onUpdate) {
      onUpdate(fromInternalState(updatedState), action);
    }

    return updatedState;
  };
};

const getIsAllRowsSelectedFromSelectedRowIds = ({
  isControlled,
  selectedRowIds,
  totalCount,
}) =>
  isControlled
    ? false
    : totalCount === fromSelectedRowIdsMap(selectedRowIds).length;
