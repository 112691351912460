import {
  black1,
  black2,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  settingsRow: {
    margin: '24px 0',
  },
  inputLabel: {
    display: 'block',
    marginBottom: '12px',
    color: black1,
    fontSize: '15px',
    fontWeight: semibold,
  },
  inputContainer: {
    display: 'inline-block',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '240px',
    height: '44px',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    marginRight: '24px',
    padding: '12px 16px',
    paddingRight: '0',
    '& input': {
      flexGrow: 1,
      border: 'none',
      borderRadius: '4px',
      color: black2,
      fontSize: '15px',
      outline: 'none',
      caretColor: evisortBlue,
    },
  },
  helpPasswordMessage: {
    width: '581px',
    marginTop: '8px',
    color: black2,
    fontSize: '13px',
    lineHeight: '1.47',
  },
};

export default styles;
