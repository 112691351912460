import { Layout } from '~/eds';
import { PilotId, SearchFilter } from '~/types';

import { PinnedFilterChip } from './PinnedFilterChip';

type Props = {
  searchFilters: Record<string, SearchFilter>;
  filterIds: Array<PilotId | string>;
  onClick: (fieldId: PilotId | string) => void;
};

export const PinnedFilters = ({ filterIds, searchFilters, onClick }: Props) => {
  return (
    <Layout spacing="1" minW={0} wrap role="list" aria-label="pinned">
      {filterIds
        .filter((id) => searchFilters[id])
        .map((id) => (
          <PinnedFilterChip
            key={`pinned_filters_${id}`}
            onClick={onClick}
            searchFilter={searchFilters[id]}
          />
        ))}
    </Layout>
  );
};
