import PT from 'prop-types';
import React from 'react';

import downloadSrc from '~/assets/images/cta/download.svg';
import { FileMimeType } from '~/enums';
import { HtmlDocumentViewer } from '~/features/document-viewer';
import { Box, CtaSection } from '~/ui';
import { downloadFile } from '~/utils/files';

import DocumentImageViewer from './DocumentImageViewer';
import DocumentPdfViewer from './DocumentPdfViewer';

function DocumentViewer({
  file,
  htmlProps = {},
  maxWidth = 'document-max-width',
  noFileMessage = 'No file provided',
  width = '100%',
  onDownloadFile = downloadFile,
}) {
  function Container({ children }) {
    return (
      <Box
        p={16}
        sx={{
          background: 'white',
          boxShadow: 'depth-2',
          maxWidth,
          width,
        }}
      >
        {children}
      </Box>
    );
  }

  if (!file) {
    return <Container>{noFileMessage}</Container>;
  }

  let viewer;
  switch (file.type) {
    case FileMimeType.Html:
      viewer = <HtmlDocumentViewer file={file} {...htmlProps} />;
      break;
    case FileMimeType.Pdf:
      viewer = <DocumentPdfViewer file={file} />;
      break;
    case FileMimeType.Jpeg:
    case FileMimeType.Jpg:
    case FileMimeType.Png:
    case FileMimeType.Webp:
      viewer = <DocumentImageViewer file={file} />;
      break;
    default: {
      viewer = (
        <CtaSection
          description="Download the contract for use offline."
          imageSrc={downloadSrc}
          actionButton={{
            text: 'Download',
            onClick: () => onDownloadFile(file),
          }}
          title="File preview is unavailable for this file type"
        />
      );
    }
  }

  return <Container>{viewer}</Container>;
}

DocumentViewer.propTypes = {
  // JS File/Blob object
  file: PT.oneOfType([PT.instanceOf(File), PT.instanceOf(Blob)]),
  htmlProps: PT.shape({
    activeElementId: PT.string,
    highlights: PT.arrayOf(
      PT.shape({
        id: PT.string.isRequired,
        className: PT.string,
      }),
    ),
  }),
  maxWidth: PT.string,
  noFileMessage: PT.string,
  width: PT.string,
  onDownloadFile: PT.func,
};

export default DocumentViewer;
