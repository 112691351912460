import React, { memo } from 'react';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { StepState } from './types';
import { getStepStateConfig } from './utils';

interface Props {
  isJoined: boolean;
  label: string;
  state: StepState;
}

export const Pill = memo(({ isJoined, label, state }: Props) => {
  const { bg, color, icon, iconColor } = getStepStateConfig(state);
  return (
    <Layout align="center" flex="none">
      {isJoined && (
        <Box bg={bg} h="step.separator.height" w="step.separator.width" />
      )}
      <Layout
        align="center"
        bg={bg}
        borderRadius="pill"
        color={color}
        pl={3}
        pr={4}
        py={1}
        spacing={2}
      >
        <Icon color={iconColor} icon={icon} />
        <Text color={color} variant="body-bold">
          {label}
        </Text>
      </Layout>
    </Layout>
  );
});
