export enum TrainingStage {
  Error = -2,
  Waiting = -1,
  Empty = 0,
  FirstRoundInProgress = 1,
  FirstRoundLabeling = 2,
  FirstRoundF1Low = 3,
  FirstRoundF1Mid = 4,
  FirstRoundF1Ideal = 5,
  SecondRoundInProgress = 6,
  SecondRoundLabeling = 7,
  SecondRoundF1GoUp = 8,
  SecondRoundF1GoDown = 9,
  SecondRoundF1Ideal = 10,
  ThirdRoundInProgress = 11,
  ThirdRoundLabeling = 12,
  ThirdRoundF1GoUp = 13,
  ThirdRoundF1GoDown = 14,
  ThirdRoundF1Ideal = 15,
  FourthRoundInProgress = 16,
  FourthRoundLabeling = 17,
  FourthRoundF1GoUp = 18,
  FourthRoundF1GoDown = 19,
  FourthRoundF1Ideal = 20,
  Publishing = 21,
  Published = 22,
  Predicting = 23,
  Predicted = 24,
}
