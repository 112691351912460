import React, { ComponentProps } from 'react';

import {
  BasePlaceholderContentProps,
  BaseWelcomeContentProps,
} from '../../types';
import { LoadingContent } from '../LoadingContent';
import { PlaceholderContent } from '../PlaceholderContent';
import { WelcomeContent } from '../WelcomeContent';

interface Props {
  /** Main content */
  children?: React.ReactElement;
  /** Loading content */
  loadingContent?: ComponentProps<typeof LoadingContent>;
  /** Placeholder content */
  placeholderContent?: BasePlaceholderContentProps;
  /** Welcome content */
  welcomeContent?: BaseWelcomeContentProps;
}

export const ContentContainer = ({
  children,
  loadingContent,
  placeholderContent,
  welcomeContent,
}: Props) => {
  if (loadingContent?.isLoading) {
    return <LoadingContent {...loadingContent} />;
  }

  if (placeholderContent) {
    return <PlaceholderContent {...placeholderContent} />;
  }

  if (welcomeContent) {
    return <WelcomeContent {...welcomeContent} />;
  }

  return children ?? null;
};
