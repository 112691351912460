import isHotkey from 'is-hotkey';

import { useToggle } from '../../../hooks';
import { CustomEditor } from '../types';
import { FORMAT_CONFIGS, FORMAT_HOTKEYS } from './constants';
import { Format } from './types';
import { toggleFormat } from './utils';

interface Props {
  editor: CustomEditor;
  formats: Format[];
}

export const useFormat = ({ editor, formats }: Props) => {
  const [enableFormat, toggleEnableFormat] = useToggle(formats.length > 0);

  const handleFormatHotkey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    Object.entries(FORMAT_HOTKEYS).forEach(([hotkey, format]) => {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        toggleFormat(editor, format, FORMAT_CONFIGS[format].type);
      }
    });
  };

  return { enableFormat, handleFormatHotkey, toggleEnableFormat };
};
