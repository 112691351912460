import PT from 'prop-types';
import React from 'react';

import DisabledFeatureAlert from '~/components/Shared/DisabledFeatureAlert';
import UnpurchasedMsg from '~/components/Shared/UnpurchasedMsg';
import { FlexLayout, ListItems, Text } from '~/ui';

function ContactIntegrationMessage({
  description,
  disabledFeatureDescription = 'This integration is not currently enabled for your account.',
  enableIcon = false,
  isUnpurchased = true,
}) {
  return (
    <ListItems alignItems="center" columns={2} space={12}>
      <Text color="gray-800" variant="s-dense">
        {description}
      </Text>
      <FlexLayout alignItems="center" space={4}>
        <DisabledFeatureAlert
          description={disabledFeatureDescription}
          enableIcon={enableIcon}
          isUnpurchased={isUnpurchased}
          renderUnpurchasedMsg={UnpurchasedMsg}
        />
      </FlexLayout>
    </ListItems>
  );
}

ContactIntegrationMessage.propTypes = {
  description: PT.string.isRequired,
  disabledFeatureDescription: PT.string,
  enableIcon: PT.bool,
  isUnpurchased: PT.bool,
  renderUnpurchasedMsg: PT.func,
};

export default ContactIntegrationMessage;
