import { isEqual, trimEnd } from 'lodash';

import {
  AribaFieldSubType,
  DataFieldType,
  ProcurementIntegrationType,
  StorageProviderType,
  SyncPairStatusType,
} from '~/enums';

import { DEFAULT_FILE_TYPES } from './constants';

export const testIsFileTypesEqual = (fileTypes) => {
  const sortedFileTypes = [...fileTypes].sort();
  const sortedDefaultFileTypes = [...DEFAULT_FILE_TYPES].sort();
  return isEqual(sortedFileTypes, sortedDefaultFileTypes);
};

export const testIsValidSyncPair = (syncPair) => {
  if (!syncPair) {
    return false;
  }

  const { allowedSyncFileTypes, folderId, providerFolderPath } = syncPair;
  return allowedSyncFileTypes.length > 0 && folderId && providerFolderPath;
};

export const resetSyncPairAuthCode = (syncPair) => ({
  ...syncPair,
  code: null,
});

export const testIsAmzS3SyncPair = (syncPair) => {
  if (!syncPair) {
    return false;
  }

  return syncPair.provider === StorageProviderType.AmazonS3;
};

export const testIsAribaSyncPair = (syncPair) => {
  if (!syncPair) {
    return false;
  }

  return syncPair.provider === ProcurementIntegrationType.Ariba;
};

export const resetSyncPairAuthData = (syncPair) => ({
  ...syncPair,
  code: null,
  state: null,
  tokenId: null,
});

export const hasErrorStatus = (statuses) => {
  const errorStatues = [
    SyncPairStatusType.AuthError,
    SyncPairStatusType.DataAccessError,
    SyncPairStatusType.OutOfSpaceError,
    SyncPairStatusType.OtherError,
    SyncPairStatusType.ConfigError,
  ];

  return statuses.some((status) => errorStatues.includes(status));
};

export const getSyncPairCardStatus = (provider, syncPairs) => {
  if (!syncPairs) {
    return { color: 'text.secondary', icon: 'plus-circle', count: 0 };
  } else {
    const statuses = syncPairs.reduce(
      (statuses, sp) =>
        sp.provider === provider ? statuses.concat(sp.status) : statuses,
      [],
    );
    const count = statuses.length;

    let color = 'status.success';
    let icon = 'status-success';

    if (statuses.length <= 0) {
      color = 'text.secondary';
      icon = syncPairs.length <= 0 ? 'plus-circle' : 'info';
    }

    if (statuses.includes(SyncPairStatusType.Initial)) {
      icon = 'info';
      color = 'text.secondary';
    }

    if (hasErrorStatus(statuses)) {
      icon = 'status-warning';
      color = 'text.redline';
    }

    return { color, icon, count };
  }
};

export const buildProviderPath = (path, prefix = '') => {
  const fullPath = `${prefix || ''}${path}`;

  return fullPath === '/' ? fullPath : trimEnd(fullPath, '/');
};

export const isInvalidMapping = (evisortFieldType, aribaFieldType) => {
  switch (evisortFieldType) {
    case DataFieldType.BOOLEAN:
      return aribaFieldType !== AribaFieldSubType.BooleanValue;
    case DataFieldType.DATE:
      return aribaFieldType !== AribaFieldSubType.DateValue;
    case DataFieldType.ARRAY_SINGLE:
      return aribaFieldType !== AribaFieldSubType.TextValue;
    case DataFieldType.ARRAY_MULTIPLE:
      return aribaFieldType !== AribaFieldSubType.TextMultiSelectValue;
    case DataFieldType.NUMBER:
      return aribaFieldType !== AribaFieldSubType.NumberValue;
    case DataFieldType.STRING:
      return aribaFieldType !== AribaFieldSubType.TextValue;
    case DataFieldType.TEXT_AREA:
      return aribaFieldType !== AribaFieldSubType.TextMultiSelectValue;
    case DataFieldType.ATTACHMENT:
      return true;
    default:
      return false;
  }
};
