import { operators } from '../operators';
import { Nullable, Operator, OperatorId } from '../types';

export const getOperator = (
  operatorId: Nullable<OperatorId>,
): Nullable<Operator> => {
  if (operatorId === null) {
    return null;
  }

  const operator = operators[operatorId];
  return operator || null;
};
