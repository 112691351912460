import { identity } from 'lodash';
import React, { useMemo } from 'react';

import { Select, types, useThrottledState } from '~/eds';
import { api } from '~/redux';
import { DataField, Nullable, PilotId } from '~/types';
import { sortByStringValue } from '~/utils/array';

import { getFieldTypeLabel, getIconFromFieldType } from '../utils';

type Props = {
  value: Nullable<PilotId>;
  onChange: (field: Nullable<DataField>) => void;
  disabled?: boolean;
  footerAction?: types.UserAction;
  filterOption?: (option: types.Option<PilotId>) => boolean;
  isLoading?: boolean;
  mapOption?: (
    option: types.Option<PilotId, DataField>,
  ) => types.Option<PilotId, DataField>;
  sortOption?: (
    a: types.Option<PilotId, DataField>,
    b: types.Option<PilotId, DataField>,
  ) => number;
};

export const FieldSelect = ({
  disabled,
  filterOption,
  footerAction,
  isLoading: overrideIsLoading,
  mapOption = identity,
  onChange,
  sortOption = sortByStringValue('label'),
  value,
}: Props) => {
  const [throttledSearch, _search, setSearch] = useThrottledState('');

  const {
    data: searchableFields,
    error: searchableFieldsError,
    isFetching: isFetchingSearchableFields,
  } = api.endpoints.getFields.useQuery({
    limit: 100,
    search: throttledSearch,
  });

  const {
    data: resolvedFields,
    error: resolvedFieldsError,
    isFetching: isFetchingResolvedFields,
  } = api.endpoints.getFields.useQuery(
    {
      limit: 1,
      ids: value ? [value] : undefined,
    },
    {
      skip: !value,
    },
  );

  const error =
    resolvedFieldsError || searchableFieldsError
      ? 'Unable to load fields'
      : undefined;
  const isLoading =
    overrideIsLoading || isFetchingSearchableFields || isFetchingResolvedFields;

  const options = useMemo(() => {
    const fields = [
      ...(resolvedFields?.results ?? []),
      ...(searchableFields?.results ?? []),
    ];
    return fields
      .map((field) => ({
        data: field,
        label: field.label,
        leftIcon: getIconFromFieldType(field.type),
        value: field.id,
        description: getFieldTypeLabel(field.type),
      }))
      .map(mapOption)
      .sort(sortOption);
  }, [resolvedFields, searchableFields, mapOption, sortOption]);

  const optionsMap: Record<PilotId, DataField> = useMemo(
    () =>
      Object.fromEntries(options.map((option) => [option.value, option.data!])),
    [options],
  );

  const handleChange = (value: Nullable<PilotId>) => {
    onChange(value === null ? null : optionsMap[value]);
  };

  return (
    <Select
      disabled={disabled}
      error={error}
      filterOption={filterOption}
      footerAction={footerAction}
      isClearable={false}
      isLoading={isLoading}
      isMulti={false}
      name="field-select"
      options={options}
      placeholder="Search for a field…"
      value={value}
      onChange={handleChange}
      onSearch={setSearch}
    />
  );
};
