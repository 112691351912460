import React, { useMemo } from 'react';

import { isInvalidMapping } from '~/components/Admin/IntegrationPage/util';
import { Select, types } from '~/eds';
import { AribaFieldSubType, DataFieldType } from '~/enums';
import { api } from '~/redux';
import { AribaDataField, Nullable } from '~/types';
import { sortByStringValue } from '~/utils/array';

type Props = {
  value: string;
  syncpair: string;
  evisortFieldType: DataFieldType;
  onChange: (field: Nullable<AribaDataField>) => void;
  disabled?: boolean;
  footerAction?: types.UserAction;
  filterOption?: (option: types.Option<string>) => boolean;
  isLoading?: boolean;
  name?: string;
  mapOption?: (
    option: types.Option<string, AribaDataField>,
  ) => types.Option<string, AribaDataField>;
  sortOption?: (
    a: types.Option<string, AribaDataField>,
    b: types.Option<string, AribaDataField>,
  ) => number;
};

export const AribaFieldSelect = ({
  disabled,
  filterOption,
  footerAction,
  onChange,
  name = 'field-select',
  sortOption = sortByStringValue('label'),
  value,
  syncpair,
  evisortFieldType,
}: Props) => {
  const {
    data: aribaFields,
    isLoading,
    error: aribaFieldsError,
  } = api.endpoints.getAribaFields.useQuery(syncpair);
  const error = aribaFieldsError ? 'Unable to load fields' : undefined;

  const getIsDisabled = (aribaFieldType: string) => {
    return isInvalidMapping(evisortFieldType, aribaFieldType);
  };

  const options = useMemo(() => {
    const wsFields = [...(aribaFields?.workspace ?? [])];
    return [
      ...wsFields
        .map((field) => {
          return {
            ...field,
            primitiveType: getAribaPrimitiveType(field),
          };
        })
        .map((field) => {
          return {
            data: field,
            value: field.fieldId,
            label: field.fieldName,
            leftIcon: getIconFromFieldType(field.primitiveType),
            disabled: getIsDisabled(field.primitiveType),
          };
        }),
    ].sort(sortOption);
  }, [aribaFields, sortOption]);

  const optionsMap: Record<string, AribaDataField> = useMemo(
    () =>
      Object.fromEntries(options.map((option) => [option.value, option.data!])),
    [options],
  );

  const handleChange = (value: Nullable<string>) => {
    onChange(value === null ? null : optionsMap[value]);
  };

  return (
    <Select
      disabled={disabled}
      error={error}
      filterOption={filterOption}
      footerAction={footerAction}
      isClearable={false}
      isLoading={isLoading}
      isMulti={false}
      name={name}
      options={options}
      placeholder="Search for a field…"
      value={value}
      onChange={handleChange}
    />
  );
};

const getIconFromFieldType = (type: string): types.IconType => {
  switch (type) {
    case AribaFieldSubType.BooleanValue:
      return 'field-boolean';
    case AribaFieldSubType.DateValue:
      return 'field-date';
    case AribaFieldSubType.TextValue:
      return 'field-short-response';
    case AribaFieldSubType.TextMultiSelectValue:
      return 'field-multiple-choice';
    case AribaFieldSubType.BigDecimalValue:
    case AribaFieldSubType.IntValue:
    case AribaFieldSubType.NumberValue:
      return 'field-number';
    case AribaFieldSubType.MultiLineTextValue:
      return 'field-paragraph';
    default:
      return 'field';
  }
};

const getAribaPrimitiveType = (field: AribaDataField): string => {
  if (field.fieldDataSubType === AribaFieldSubType.MoneyValue) {
    switch (field.fieldDataType) {
      case 'number':
        return AribaFieldSubType.NumberValue;
      case 'string':
        return AribaFieldSubType.TextValue;
      case 'date':
        return AribaFieldSubType.DateValue;
      default:
        return field.fieldDataSubType;
    }
  }
  return field.fieldDataSubType;
};
