import React, { memo } from 'react';

import { Popover } from '../../components/Popover';
import { CommentDraft } from '../Comments/CommentDraft';
import { CommentData, Entity, Mentions } from './types';

interface Props {
  /** The entity the comments belong to */
  entity: Entity;
  /** Flag to check if mentions is loading */
  isMentionsLoading: boolean;
  /** Creating a new Comment to a ThreadId */
  onCreateComment: (commentData: CommentData) => void;
  isVisible?: boolean;
  mentions?: Mentions;
  resolvedMentions?: Mentions;
  /** Popover trigger */
  trigger?: React.ReactElement;
  onMentionSearch?: (updatedSearch: string) => void;
}

export const CommentBubble = memo(
  ({
    isMentionsLoading,
    isVisible,
    trigger,
    entity,
    mentions = {},
    resolvedMentions = {},
    onCreateComment,
    onMentionSearch,
  }: Props) => {
    return (
      <Popover isVisible={isVisible} trigger={trigger} position="absolute">
        {isVisible ? (
          <CommentDraft
            entity={entity}
            isFocused={true}
            isMentionsLoading={isMentionsLoading}
            mentions={mentions}
            resolvedMentions={resolvedMentions}
            onMentionSearch={onMentionSearch}
            onSubmit={onCreateComment}
          />
        ) : undefined}
      </Popover>
    );
  },
);
