import Footer from './Footer';
import Header from './Header';
import PageLayout from './PageLayout';
import Sidebar from './Sidebar';
import SidebarSection from './SidebarSection';
import SidebarSectionItem from './SidebarSectionItem';
import SidebarTab from './SidebarTab';
import SidebarTabs from './SidebarTabs';

PageLayout.Footer = Footer;
PageLayout.Header = Header;
PageLayout.Sidebar = Sidebar;
PageLayout.SidebarSection = SidebarSection;
PageLayout.SidebarSectionItem = SidebarSectionItem;
PageLayout.SidebarTab = SidebarTab;
PageLayout.SidebarTabs = SidebarTabs;

export default PageLayout;
