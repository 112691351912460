import PT from 'prop-types';
import React from 'react';

import { Icon } from '~/eds';
import { theme } from '~/ui';

const variantStyles = {
  red: theme.colors['red-600'],
  yellow: theme.colors['yellow-500'],
};

export default function ErrorIcon({
  errors,
  shouldRender = false,
  variant = 'red',
}) {
  if (!shouldRender || errors.count === 0) {
    return null;
  }
  return <Icon icon="status-warning" color={variantStyles[variant]} />;
}

ErrorIcon.propTypes = {
  errors: PT.shape({
    count: PT.number.isRequired,
  }),
  shouldRender: PT.bool,
  variant: PT.string,
};
