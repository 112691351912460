import { ConfigurationType, Endpoint } from '~/features/webhooks';
import { courier } from '~/services';

import { EndpointUpdateRequestDto } from '../../types/courier/endpoints';

interface Request {
  endpoint: Endpoint;
  configuration: ConfigurationType;
}

export const updateEndpoint = async ({
  endpoint,
  configuration,
}: Request): Promise<boolean> => {
  const { url, description, events = {} } = configuration;
  const allEventValues = Object.values(events).flat();

  const params: EndpointUpdateRequestDto = {
    description,
    events: allEventValues,
    name: url,
    url,
    type: 'HTTP',
  };

  const response = await courier.patch(`/endpoints/${endpoint.id}`, params);

  return Boolean(response);
};
