export default `
<svg
  aria-hidden="true"
  aria-label="format-heading-1"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M17.3115 5.0332H7.31152C6.18652 5.0332 5.31152 5.93945 5.31152 7.0332V17.0332C5.31152 18.1582 6.18652 19.0332 7.31152 19.0332H17.3115C18.4053 19.0332 19.3115 18.1582 19.3115 17.0332V7.0332C19.3115 5.93945 18.4053 5.0332 17.3115 5.0332ZM17.8115 17.0332C17.8115 17.3145 17.5615 17.5332 17.3115 17.5332H7.31152C7.03027 17.5332 6.81152 17.3145 6.81152 17.0332V7.0332C6.81152 6.7832 7.03027 6.5332 7.31152 6.5332H17.3115C17.5615 6.5332 17.8115 6.7832 17.8115 7.0332V17.0332ZM14.3115 14.5332H13.0615V8.7832C13.0615 8.5332 12.9053 8.2832 12.6553 8.12695C12.4053 8.00195 12.124 8.0332 11.874 8.18945L10.249 9.25195C9.90527 9.4707 9.81152 9.93945 10.0615 10.2832C10.2803 10.627 10.749 10.7207 11.0928 10.4707L11.5615 10.1895V14.5332H10.3115C9.87402 14.5332 9.56152 14.877 9.56152 15.2832C9.56152 15.7207 9.87402 16.0332 10.3115 16.0332H14.3115C14.7178 16.0332 15.0615 15.7207 15.0615 15.2832C15.0615 14.877 14.7178 14.5332 14.3115 14.5332Z"
    fill="currentcolor"
  />
</svg>
`;
