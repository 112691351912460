import { createActions } from '../utils/redux';

export const {
  ticketReviewerSetSelectedDocumentHighlights,
  ticketReviewerSetViewType,
  ticketReviewerUpdateEntity,
} = createActions('ticketReviewer', [
  'setSelectedDocumentHighlights',
  'setViewType',
  'updateEntity',
]);
