import dataTypes from '~/components/Shared/ConditionExpressionBuilder/dataTypes';
import { WorkflowFieldType, WorkflowOperatorType } from '~/enums';

const {
  AllOfTheseWords,
  AnyOfTheseWords,
  Between,
  Contains,
  ContainsAll,
  ContainsAnyOf,
  DoesNotContain,
  ExactPhrase,
  Equal,
  GreaterThan,
  GreaterThanEqual,
  IsBlank,
  IsNotBlank,
  LessThan,
  LessThanEqual,
  NotEqual,
} = WorkflowOperatorType;

export default {
  [WorkflowFieldType.Boolean]: {
    dataType: dataTypes.BOOLEAN,
    operators: [Equal, IsBlank, IsNotBlank],
  },
  [WorkflowFieldType.Checkbox]: {
    dataType: dataTypes.SINGLE_SELECT,
    operators: [Equal, NotEqual],
  },
  [WorkflowFieldType.Date]: {
    dataType: dataTypes.DATE,
    operators: [LessThan, Equal, GreaterThan, Between, IsBlank, IsNotBlank],
  },
  [WorkflowFieldType.Email]: {
    dataType: dataTypes.TEXT,
    operators: [
      Contains,
      DoesNotContain,
      AllOfTheseWords,
      AnyOfTheseWords,
      IsBlank,
      IsNotBlank,
    ],
  },
  [WorkflowFieldType.File]: {
    dataType: dataTypes.TEXT,
    operators: [IsBlank, IsNotBlank],
  },
  [WorkflowFieldType.LongResponse]: {
    dataType: dataTypes.TEXT,
    operators: [
      Contains,
      DoesNotContain,
      AllOfTheseWords,
      AnyOfTheseWords,
      ExactPhrase,
      IsBlank,
      IsNotBlank,
    ],
  },
  // TODO: remove temporarily until we support it formally
  // [WorkflowFieldType.MonetaryValue]: {
  // dataType: dataTypes.NUMBER,
  // operators: [GreaterThan, GreaterThanEqual, LessThan, LessThanEqual, Equal, Between, IsBlank, IsNotBlank]
  // },
  [WorkflowFieldType.MultiSelect]: {
    dataType: dataTypes.MULTI_SELECT,
    operators: [
      ContainsAnyOf,
      ContainsAll,
      DoesNotContain,
      IsBlank,
      IsNotBlank,
    ],
  },
  [WorkflowFieldType.Number]: {
    dataType: dataTypes.NUMBER,
    operators: [
      GreaterThan,
      GreaterThanEqual,
      LessThan,
      LessThanEqual,
      Equal,
      Between,
      IsBlank,
      IsNotBlank,
    ],
  },
  [WorkflowFieldType.Percentage]: {
    dataType: dataTypes.NUMBER,
    operators: [
      GreaterThan,
      GreaterThanEqual,
      LessThan,
      LessThanEqual,
      Equal,
      Between,
      IsBlank,
      IsNotBlank,
    ],
  },
  [WorkflowFieldType.Radio]: {
    dataType: dataTypes.SINGLE_SELECT,
    operators: [Equal, NotEqual, IsBlank, IsNotBlank],
  },
  [WorkflowFieldType.ShortResponse]: {
    dataType: dataTypes.TEXT,
    operators: [
      Contains,
      DoesNotContain,
      AllOfTheseWords,
      AnyOfTheseWords,
      ExactPhrase,
      IsBlank,
      IsNotBlank,
    ],
  },
  [WorkflowFieldType.SingleSelect]: {
    dataType: dataTypes.SINGLE_SELECT,
    operators: [Equal, NotEqual, ContainsAnyOf, IsBlank, IsNotBlank],
  },
  // TODO: remove temporarily until we support it formally
  // [WorkflowFieldType.TimePeriod]: {
  // dataType: dataTypes.NUMBER,
  // operators: [GreaterThan, GreaterThanEqual, LessThan, LessThanEqual, Between]
  // },
  [WorkflowFieldType.User]: {
    dataType: dataTypes.USER,
    operators: [
      Equal,
      NotEqual,
      ContainsAnyOf,
      ContainsAll,
      DoesNotContain,
      IsBlank,
      IsNotBlank,
    ],
  },
  [WorkflowFieldType.Department]: {
    dataType: dataTypes.DEPARTMENT,
    operators: [
      Equal,
      NotEqual,
      ContainsAnyOf,
      ContainsAll,
      DoesNotContain,
      IsBlank,
      IsNotBlank,
    ],
  },
};
