import React from 'react';

import { Table } from '~/eds';

import { ClauseType } from './types';

interface Props {
  clauseTypes: ClauseType[];
  isLoading?: boolean;
}

export const Top3ClauseTypes = ({ clauseTypes = [], isLoading }: Props) => {
  if (!clauseTypes.length) return null;

  const top3ClauseTypes = sortByCount(clauseTypes).slice(0, 3);
  const columns = [
    {
      key: 'name',
      title: 'Clause Type',
      cellType: 'text',
      mapCellProps: (clauseType: ClauseType) => ({ text: clauseType.name }),
    },
    {
      key: 'count',
      title: 'Count',
      cellType: 'text',
      mapCellProps: (clauseType: ClauseType) => ({
        text: `${clauseType.count} matches`,
      }),
    },
  ];

  return (
    <Table
      name="top 3 clause types"
      isLoading={isLoading}
      mode="summary"
      header={{
        title: 'Clause Types Found in Your Contracts',
        chips: [{ text: 'Powered by Evisort AI', preset: 'ai' }],
      }}
      columns={columns}
      data={top3ClauseTypes}
    />
  );
};

export const sortByCount = (clauseTypes: ClauseType[]): ClauseType[] => {
  return clauseTypes.sort((a, b) => (b?.count ?? 0) - (a?.count ?? 0));
};
