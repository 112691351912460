import PropTypes from 'prop-types';
import React from 'react';

const NotificationsOffIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.color}
          fillOpacity={props.opacity}
          d="M12 22c1.1 0 2-.9 2-2h-4a2 2 0 0 0 2 2zm6-11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-.24.06-.47.15-.69.23L18 13.1V11zM5.41 3.35L4 4.76l2.81 2.81C6.29 8.57 6 9.73 6 11v5l-1.29 1.29c-.63.63-.19 1.71.7 1.71h12.83l1.74 1.74 1.41-1.41L5.41 3.35z"
        />
      </g>
    </svg>
  );
};

NotificationsOffIcon.defaultProps = {
  size: '24',
  opacity: '1',
  color: '#000',
};

NotificationsOffIcon.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.string,
  size: PropTypes.string,
};

export default NotificationsOffIcon;
