export default `
<svg
  aria-hidden="true"
  aria-label="field-percentage"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.4043 6.24219C18.0918 5.92969 17.623 5.92969 17.3418 6.24219L6.8418 16.7422C6.5293 17.0234 6.5293 17.4922 6.8418 17.7734C6.99805 17.9297 7.18555 17.9922 7.4043 17.9922C7.5918 17.9922 7.7793 17.9297 7.9043 17.7734L18.4043 7.27344C18.7168 6.99219 18.7168 6.52344 18.4043 6.24219ZM8.6543 9.49219C9.4668 9.49219 10.1543 8.83594 10.1543 7.99219C10.1543 7.17969 9.4668 6.49219 8.6543 6.49219C7.81055 6.49219 7.1543 7.17969 7.1543 7.99219C7.1543 8.83594 7.81055 9.49219 8.6543 9.49219ZM16.6543 14.4922C15.8105 14.4922 15.1543 15.1797 15.1543 15.9922C15.1543 16.8359 15.8105 17.4922 16.6543 17.4922C17.4668 17.4922 18.1543 16.8359 18.1543 15.9922C18.1543 15.1797 17.4668 14.4922 16.6543 14.4922Z"
    fill="currentcolor"
  />
</svg>
`;
