/*eslint import/namespace: ['error', { allowComputed: true }]*/
import { types as Types } from '~/eds';
import { EntityType } from '~/enums';
import * as apiServices from '~/services';
import { Comment, CommentData, ContextEntityType, Uuid } from '~/types';

/**
 * Data for comments received from the api
 */
interface CommentResponse {
  id: Uuid;
  content: Types.ContentAst;
  createdBy: Types.PilotId;
  createdDate: Date;
  context: Types.Entity<ContextEntityType>[];
  entityId: string;
  entityType: EntityType.Document;
  isResolved: boolean;
  mentions: Types.Comments.MentionEntity[];
  modifiedDate: Date;
  threadId?: Uuid;
}

type CommentImportStatusResponse = {
  status: 'SUCCESSFUL' | 'FAILED' | 'IN_PROGRESS';
};

const { weaver } = apiServices;

export const getComments = async ({
  id,
  type,
}: Types.Entity): Promise<Comment[]> => {
  const url = `${type}/${id}/comments`;
  const response = (await weaver.get(url)) as CommentResponse[];
  return response.map(transformResponse);
};

export const createComment = async (comment: CommentData): Promise<Comment> => {
  const url = `${comment.entity.type}/${comment.entity.id}/comments`;
  const commentPayload = {
    content: comment.content,
    context: comment.context,
    entityId: comment.entity.id,
    entityType: comment.entity.type,
    mentions: comment.mentions,
    threadId: comment.threadId,
  };
  const response = (await weaver.post(url, {
    ...commentPayload,
  })) as CommentResponse;
  return transformResponse(response);
};

export const updateComment = async (comment: Comment): Promise<Comment> => {
  const url = `${comment.entity.type}/${comment.entity.id}/comments/${comment.id}`;
  const commentPayload = {
    content: comment.content,
    mentions: comment.mentions,
  };
  const response = (await weaver.patch(url, {
    ...commentPayload,
  })) as CommentResponse;
  return transformResponse(response);
};

export const resolveComment = async (comment: Comment): Promise<Comment> => {
  const url = `${comment.entity.type}/${comment.entity.id}/comments/${comment.id}/resolve`;
  const response = (await weaver.post(url)) as CommentResponse;
  return transformResponse(response);
};

export const unresolveComment = async (comment: Comment): Promise<Comment> => {
  const url = `${comment.entity.type}/${comment.entity.id}/comments/${comment.id}/unresolve`;
  const response = (await weaver.post(url)) as CommentResponse;
  return transformResponse(response);
};

export const deleteComment = async (comment: Comment): Promise<Comment> => {
  const url = `${comment.entity.type}/${comment.entity.id}/comments/${comment.id}`;
  const response = (await weaver.remove(url)) as CommentResponse;
  return transformResponse(response);
};

const transformResponse = (payload: CommentResponse): Comment => ({
  id: payload.id,
  content: payload.content,
  context: payload.context,
  createdBy: payload.createdBy,
  createdDate: new Date(payload.createdDate),
  entity: {
    type: payload.entityType,
    id: payload.entityId,
  },
  isResolved: payload.isResolved,
  modifiedDate: new Date(payload.modifiedDate),
  mentions: payload.mentions,
  replies: 0,
  threadId: payload.threadId,
});

export const getCommentImportStatus = ({
  documentId,
  versionId,
}: {
  documentId: Uuid;
  versionId: Uuid;
}): Promise<CommentImportStatusResponse> => {
  return weaver.get(
    `document/${documentId}/version/${versionId}/comment-import-status`,
  );
};
