export default `
<svg
  aria-hidden="true"
  aria-label="format-align-justify"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.5615 5.2666C18.9678 5.2666 19.3115 5.61035 19.3115 6.0166C19.3115 6.4541 18.9678 6.7666 18.5615 6.7666H6.06152C5.62402 6.7666 5.31152 6.4541 5.31152 6.0166C5.31152 5.61035 5.62402 5.2666 6.06152 5.2666H18.5615ZM18.5615 9.2666C18.9678 9.2666 19.3115 9.61035 19.3115 10.0166C19.3115 10.4541 18.9678 10.7666 18.5615 10.7666H6.06152C5.62402 10.7666 5.31152 10.4541 5.31152 10.0166C5.31152 9.61035 5.62402 9.2666 6.06152 9.2666H18.5615ZM5.31152 14.0166C5.31152 13.6104 5.62402 13.2666 6.06152 13.2666H18.5615C18.9678 13.2666 19.3115 13.6104 19.3115 14.0166C19.3115 14.4541 18.9678 14.7666 18.5615 14.7666H6.06152C5.62402 14.7666 5.31152 14.4541 5.31152 14.0166ZM18.5615 17.2666C18.9678 17.2666 19.3115 17.6104 19.3115 18.0166C19.3115 18.4541 18.9678 18.7666 18.5615 18.7666H6.06152C5.62402 18.7666 5.31152 18.4541 5.31152 18.0166C5.31152 17.6104 5.62402 17.2666 6.06152 17.2666H18.5615Z"
    fill="currentcolor"
  />
</svg>
`;
