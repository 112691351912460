import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Text } from '~/ui';

function SidebarTab({ isSelected, tab, onClick }) {
  const { disabled, iconLeft, iconRight, label, value } = tab;

  return (
    <FlexLayout
      alignItems="center"
      bg={isSelected ? 'transparent' : 'gray-300'}
      disabled={disabled}
      justifyContent="center"
      sx={{ height: 'tab-height' }}
      onClick={() => onClick(value)}
    >
      <FlexLayout alignItems="center" space={2}>
        {iconLeft}
        <Text color="gray-700" variant="xs-dense-bold">
          {label}
        </Text>
        {iconRight}
      </FlexLayout>
    </FlexLayout>
  );
}

export const tabPropType = PT.shape({
  disabled: PT.bool,
  iconLeft: PT.node,
  iconRight: PT.node,
  label: PT.string.isRequired,
  tooltip: PT.string,
  value: PT.any.isRequired,
});

SidebarTab.propTypes = {
  /** Indicates if tab is active */
  isSelected: PT.bool,
  /** Tab Object */
  tab: tabPropType,
  /** Callback function to trigger change of tabs */
  onClick: PT.func,
};

export default SidebarTab;
