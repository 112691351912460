import { getDate, getYear, set } from 'date-fns';
import { DateRange, Matcher } from 'react-day-picker';

import { DateValue, View } from '../types';

export const MIN_YEAR = 1;
export const MIN_DATE = set(new Date(), {
  year: MIN_YEAR,
  month: 0,
  date: 1,
});
export const YEAR_PAGE_SIZE = 20;

export const floorYear = (year: number) =>
  Math.floor(year / YEAR_PAGE_SIZE) * YEAR_PAGE_SIZE + 1;

export const getYearRange = (displayDate: Date) => {
  const floor = floorYear(getYear(displayDate));
  return [floor, floor + YEAR_PAGE_SIZE - 1];
};

export const testDisablePreviousYear = (displayDate: Date, view: View) => {
  if (view === 'year') {
    return floorYear(getYear(displayDate)) <= MIN_YEAR;
  }

  return displayDate <= MIN_DATE;
};

export const getDisabledMatcher = ({
  disabled,
  disabledDates = [],
  disabledDays = [],
  max,
  min,
}: {
  disabled?: boolean;
  disabledDates?: Date[];
  disabledDays?: number[];
  max?: Date;
  min?: Date;
}): Matcher | Matcher[] => {
  const matcher = [
    ...disabledDates,
    {
      dayOfWeek: disabledDays,
    },
    (date: Date) => {
      if (disabled || (max && date > max) || (min && date < min)) {
        return true;
      }
      return false;
    },
  ];

  return matcher;
};

export const getMonth = (value: DateValue, isRange?: boolean): Date => {
  const monthValue = isRange ? (value as DateRange)?.from : (value as Date);
  return monthValue || new Date();
};

export const getSelectedDate = (
  dateValue: Matcher | Matcher[] | undefined,
  isRange?: boolean,
): number => {
  const date = isRange ? (dateValue as DateRange)?.from : (dateValue as Date);
  return getDate(date || new Date());
};

export const getNavigateUnit = (view: View) => {
  switch (view) {
    case 'day':
      return 'month';
    case 'month':
      return 'year';
    case 'year':
      return 'years';
  }
};
