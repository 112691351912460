import { highlighter } from './highlighter';
import { reactDatePicker } from './react-datepicker';
import { reactDayPicker } from './react-day-picker';
import { reactMarkdown } from './react-markdown';
import { reactMenu } from './react-menu';
import { reactPdf } from './react-pdf';
import { reactToastify } from './react-toastify';
import { tippy } from './tippy';

export const globalStyles = {
  ...highlighter,
  ...reactDatePicker,
  ...reactDayPicker,
  ...reactMarkdown,
  ...reactMenu,
  ...reactPdf,
  ...reactToastify,
  ...tippy,
  '*': {
    boxSizing: 'border-box',
  },
  body: {
    fontFamily: 'IBMPlexSans',
  },
  del: {
    color: 'text.redline',
    textDecoration: 'line-through',
  },
  ins: {
    color: 'text.redline',
    textDecoration: 'none',
  },
};
