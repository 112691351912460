import { theme } from '../../theme';

const defaultProps: {
  align?: string;
  columns: number;
  columnSpacing?: number;
  columnWidths?: string[];
  justify?: string;
  rows?: number;
  rowSpacing?: number;
} = {
  columns: 0,
};

export const gridColumns = ({
  align,
  columns,
  columnSpacing,
  columnWidths = [],
  justify,
  rows,
  rowSpacing,
} = defaultProps) =>
  columns > 0
    ? {
        alignItems: align,
        columnGap: columnSpacing
          ? `${theme.spacings[columnSpacing]}px`
          : undefined, // columnGap CSS property is not registered in theme spec AND also needs to be expressed in px-units to be valid CSS.  This issue is fixed in uinix-ui@1+
        display: 'grid',
        justifyItems: justify,
        gridTemplateColumns:
          columnWidths.join(' ') || `repeat(${columns}, 1fr)`,
        gridTemplateRows: rows && rows > 0 ? `repeat(${rows}, 1fr)` : undefined,
        rowGap: rowSpacing,
      }
    : null;
