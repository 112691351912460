import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import { workflowSetCondition } from '~/actions/workflow';
import ConditionExpressionBuilder, {
  getInvalidFieldNodes,
  hasLoops,
  testEmptyExpression,
  testHasEmptySections,
} from '~/components/Shared/ConditionExpressionBuilder';
import Links from '~/components/Workflow/shared/Links';
import { getAssignedFields } from '~/reducers/workflow';
import {
  FlexLayout,
  InputLabel,
  Modal,
  Text,
  TextInput,
  useToggle,
} from '~/ui';

import fieldDefinitions from './fieldDefinitions';
import operatorDefinitions from './operatorDefinitions';

// TODO: find a unified way to validate input errors for UI input components.
function validate(formData, fields, conditions) {
  const errors = [];
  const { hasInvalidFields, expression, name } = formData;

  const loops = hasLoops(fields, conditions);
  if (Object.values(loops).some((hasLoop) => hasLoop)) {
    errors.push('Expression contains infinite loops');
  }
  if (!name) {
    errors.push('Name must be provided');
  }
  if (testEmptyExpression(expression)) {
    errors.push('Expression must be non-empty');
  } else if (hasInvalidFields) {
    errors.push('All fields and operators must be specified');
  } else if (testHasEmptySections(expression)) {
    errors.push('Expression must not contain empty sections');
  }

  return errors;
}

// format fields compatible for ExpressionBuilder
function formatFields(fields) {
  return Object.values(fields).reduce((acc, field) => {
    acc[field.id] = {
      id: field.id,
      name: field.name,
      type: field.type,
      options: field?.customSettings?.options || [],
    };
    return acc;
  }, {});
}

function ConditionModal({
  condition,
  visible,
  onHide,
  // connected
  conditions,
  fields,
  workflowSetCondition,
}) {
  const shouldCreate = !condition;
  const id = condition?.id || uuid();
  const initialName = condition?.name || '';
  const initialExpression = condition?.expression || [];

  const [name, setName] = useState(initialName);
  const [expression, setExpression] = useState(initialExpression);
  const [hasInvalidFields, setHasInvalidFields] = useState(initialExpression);
  const [showLinks, toggleShowLinks] = useToggle(false);

  function clear() {
    setExpression(initialExpression);
    setHasInvalidFields(false);
    setName(initialName);
  }

  useEffect(
    () => {
      clear();
      if (condition) {
        setName(condition.name);
        setExpression(condition.expression);
      }
    },
    [condition], // eslint-disable-line react-hooks/exhaustive-deps
  );

  function handleHide() {
    clear();
    onHide();
  }

  function handleNameChange(updatedName) {
    setName(updatedName);
  }

  function handleExpressionChange(updatedExpression, updatedTree) {
    setExpression(updatedExpression);
    setHasInvalidFields(getInvalidFieldNodes(updatedTree).length > 0);
  }

  const formData = { hasInvalidFields, name, expression };
  const errors = validate(formData, fields, conditions);
  const hasErrors = errors.length > 0;

  let LinksWarning;
  if (condition?.links) {
    const length = condition?.links.length;
    LinksWarning = length > 0 && (
      <FlexLayout bg="yellow-100" flexDirection="column" p={6} space={6}>
        <FlexLayout alignItems="center" justifyContent="space-between">
          <Text variant="xs-dense-bold">
            This condition is linked to {pluralize('object', length, true)}.
            Modifying it will affect the workflow.
          </Text>
          <Text
            color="peach-800"
            variant="xs-dense-bold"
            onClick={toggleShowLinks}
          >
            {showLinks ? 'Hide' : 'Show'} affected objects
          </Text>
        </FlexLayout>
        {showLinks && <Links bg="yellow-200" links={condition.links} />}
      </FlexLayout>
    );
  }

  return (
    <Modal
      disableAutoHide
      actionButton={{
        disabled: hasErrors,
        errorHandler: () => {},
        text: shouldCreate ? 'Add Condition' : 'Save',
        tooltip: hasErrors
          ? errors.map((error) => (
              <React.Fragment key={error}>
                {error}
                <br />
              </React.Fragment>
            ))
          : undefined,
        promise: async function () {
          workflowSetCondition({ id, name, expression });
        },
      }}
      title={`${shouldCreate ? 'Add' : 'Edit'} Condition`}
      visible={visible}
      width="l"
      onHide={handleHide}
    >
      <FlexLayout flexDirection="column" pb={60} space={6}>
        <InputLabel
          isRequired
          id="condition-name"
          placeholder="Enter condition name"
          label="Name"
        >
          <TextInput
            autoFocus
            value={name}
            width="fullWidth"
            onChange={handleNameChange}
          />
        </InputLabel>
        <FlexLayout flexDirection="column" space={1}>
          <Text color="gray-700" variant="s-dense-bold">
            Expression
          </Text>
          {expression.length === 0 && (
            <Text color="gray-600" variant="xs-dense-medium">
              You have not added any rules
            </Text>
          )}
        </FlexLayout>
        <ConditionExpressionBuilder
          expression={expression}
          fields={formatFields(fields)}
          fieldDefinitions={fieldDefinitions}
          operatorDefinitions={operatorDefinitions}
          onChange={handleExpressionChange}
        />
        {LinksWarning}
      </FlexLayout>
    </Modal>
  );
}

const mapStateToProps = ({ workflow }) => ({
  conditions: workflow.conditions,
  fields: getAssignedFields(workflow, true),
});

export default connect(mapStateToProps, { workflowSetCondition })(
  ConditionModal,
);
