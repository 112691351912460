export const presets = {
  'clear-all': {
    icon: 'x',
    size: 's',
    tooltip: 'Clear all',
  },
  hide: {
    icon: 'chevron-up',
    size: 's',
    tooltip: 'Hide',
  },
  maximize: {
    icon: 'maximize',
    tooltip: 'Maximize',
  },
  minimize: {
    icon: 'minimize',
    tooltip: 'Minimize',
  },
  show: {
    icon: 'chevron-down',
    size: 's',
    tooltip: 'Show',
  },
  close: {
    icon: 'x',
    tooltip: 'Close',
  },
  pin: {
    icon: 'push-pin',
    size: 's',
    tooltip: 'Pin',
  },
  unpin: {
    icon: 'push-pin',
    size: 's',
    status: 'active',
    tooltip: 'Unpin',
  },
};
