import React, { useEffect, useMemo } from 'react';

import { ClausesSelect } from '~/components/Shared/ClausesSelect';
import { FormField, Layout, RadioGroup, Section, TextArea, types } from '~/eds';
import { FlagType, useFlag } from '~/flags';

type RadioGroupType = types.Nullable<'add' | 'delete'>;
export type EditClausesData = {
  actionType: RadioGroupType;
  isAllClausesSelected: boolean;
  clausesNames?: string[];
  clausesNewValues?: Record<string, string>;
};
interface Props {
  onChange: (params: EditClausesData) => void;
}
const selectAllOption = {
  label: 'Select all documents clauses',
  value: 'select_all',
};
const getRadioGroupOptions = (
  isAllClausesSelected: boolean,
  shouldIncludeAddOption: boolean,
): types.Option<RadioGroupType>[] => [
  ...((shouldIncludeAddOption
    ? [
        {
          label: 'Add to existing values',
          info:
            'This will add to the existing clause value. This cannot be undone.',
          tooltip: isAllClausesSelected
            ? 'This function is not available when you select “All document clauses”. Select specific clauses to add additional values to.'
            : undefined,
          value: 'add',
          disabled: isAllClausesSelected,
        },
      ]
    : []) as types.Option<RadioGroupType>[]),
  {
    label: 'Delete existing values',
    info: 'This will remove the current clause value. This cannot be undone.',
    value: 'delete',
  },
];
export const EditClausesAction = ({ onChange }: Props) => {
  const isAdvancedBulkClauseEditingEnabled = useFlag(
    FlagType.AdvancedBulkClauseEditing,
  );

  const [clausesNames, setClauseNames] = React.useState<string[]>([]);
  const [radioGroupValue, setRadioGroupValue] = React.useState<
    types.Nullable<RadioGroupType>
  >(null);
  const [clauseValuesRecord, setClauseValuesRecord] = React.useState<
    Record<string, string> | undefined
  >();

  const isAllClausesSelected = useMemo(
    () => clausesNames?.some((value) => value === 'select_all'),
    [clausesNames],
  );
  const onSelectChange = (updatedValue: types.Nullable<string[]>) => {
    if (updatedValue) {
      setClauseNames(updatedValue);
      if (radioGroupValue === 'add') {
        updateClauseValuesRecord(updatedValue);
      }
    }
  };
  const updateClauseValuesRecord = (selectedClauses: string[]) => {
    const filteredClauses = selectedClauses.filter(
      (clause) => clause !== 'select_all',
    );
    if (filteredClauses.length) {
      const updatedRecords: typeof clauseValuesRecord = selectedClauses.reduce(
        (acc, clauseName) => ({
          ...acc,
          [clauseName]: clauseValuesRecord?.[clauseName] ?? '',
        }),
        {},
      );
      setClauseValuesRecord(updatedRecords);
    }
  };

  useEffect(() => {
    if (radioGroupValue === 'add') {
      setRadioGroupValue(null);
    }
  }, [isAllClausesSelected]);

  useEffect(() => {
    onChange({
      isAllClausesSelected,
      clausesNames: clausesNames.filter((value) => value !== 'select_all'),
      actionType: radioGroupValue,
      clausesNewValues: clauseValuesRecord,
    });
  }, [clausesNames, radioGroupValue, isAllClausesSelected, clauseValuesRecord]);

  const onRadioGroupChange = (updatedValue: types.Nullable<RadioGroupType>) => {
    setRadioGroupValue(updatedValue);
    if (updatedValue === 'add') {
      updateClauseValuesRecord(clausesNames);
    }
  };

  const handleClauseValue = (clauseName: string, clauseValue: string) => {
    setClauseValuesRecord({ ...clauseValuesRecord, [clauseName]: clauseValue });
  };

  const onTextAreaChange = (
    clauseName: string,
    updatedValue: string | null | undefined,
  ) => {
    if (updatedValue !== null) {
      // Remove leading spaces only but allow spaces elsewhere
      const trimmedLeadingSpaces = updatedValue?.replace(/^\s+/, '') ?? '';
      handleClauseValue(clauseName, trimmedLeadingSpaces);
    } else {
      // If updatedValue is null (which shouldn't typically happen in a textarea), treat as empty string
      handleClauseValue(clauseName, '');
    }
  };

  return (
    <Layout direction="column" spacing={4}>
      <FormField<string, true>
        description="Select the clause(s) you want to edit"
        input={ClausesSelect}
        inputProps={{
          enableSorting: false,
          enableSelectAll: false,
          enableValueContainer: true,
          isClearable: false,
          isMulti: true,
          isSearchable: true,
          extraOptions:
            isAdvancedBulkClauseEditingEnabled && radioGroupValue !== 'add'
              ? [selectAllOption]
              : undefined,
        }}
        name="clause-select"
        label="Clauses"
        onChange={onSelectChange}
        value={clausesNames}
      />

      <RadioGroup
        name="deleteReason"
        options={getRadioGroupOptions(
          isAllClausesSelected,
          isAdvancedBulkClauseEditingEnabled,
        )}
        value={radioGroupValue}
        onChange={onRadioGroupChange}
      />

      {clausesNames.length > 0 && radioGroupValue === 'add' && (
        <Layout direction="column">
          <Section heading="h4" title="Additional clause values">
            <Layout direction="column" spacing={4}>
              {clausesNames.map((clause) => (
                <FormField<string, false>
                  key={clause}
                  input={TextArea}
                  inputProps={{
                    rows: 5,
                  }}
                  name={`${clause}-textarea`}
                  label={`${clause}`}
                  onChange={(updatedValue) =>
                    onTextAreaChange(clause, updatedValue)
                  }
                  placeholder="Enter new wording for this clause"
                  value={clauseValuesRecord?.[clause] ?? ''}
                />
              ))}
            </Layout>
          </Section>
        </Layout>
      )}
    </Layout>
  );
};
