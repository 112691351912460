/**
 * Returns the date format pattern (date-fns compatible) for the given locale.
 *
 * Reference: https://stackoverflow.com/a/69095836
 *
 * @example
 *    getDateTimeFormat('en-AU'); // dd/mm/yyyy
 *    getDateTimeFormat('en-US'); // m/d/yyyy
 */
export const getDateTimeFormatPattern = (locale?: string) =>
  new Intl.DateTimeFormat(locale)
    .formatToParts(new Date())
    .map(mapPartToPattern)
    .join('');

const mapPartToPattern = (part: Intl.DateTimeFormatPart) => {
  const { type, value } = part;
  const { length } = value;

  switch (type) {
    case 'day':
      return 'd'.repeat(length);
    case 'month':
      return 'M'.repeat(length);
    case 'year':
      return 'y'.repeat(length);
    case 'literal':
      return value;
    default:
      return '';
  }
};
