import React, { memo } from 'react';

import { copy } from '../../../utils';
import { useToast } from '../../hooks/useToast';
import { IconButton } from '../../IconButton';
import { Button } from '../Button';

interface Props {
  copyText: string;
  size?: 's' | 'm';
  copyTooltip?: string;
  copiedToastMessage?: string;
  disabled?: boolean;
  disableText?: boolean;
}

export const CopyButton = memo(
  ({
    copyText,
    size = 'm',
    copyTooltip = 'Copy',
    copiedToastMessage = 'Copied',
    disabled,
    disableText,
  }: Props) => {
    const { toast } = useToast();

    const handleCopy = async () => {
      copy(copyText);
      toast({
        status: 'success',
        message: copiedToastMessage,
      });
    };

    const ButtonComponent = disableText ? IconButton : Button;

    return (
      <ButtonComponent
        disabled={disabled}
        icon="clipboard"
        text="Copy"
        size={size}
        tooltip={copyTooltip}
        onClick={handleCopy}
      />
    );
  },
);
