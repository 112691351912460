import { noop } from 'lodash';
import React from 'react';
import { useStyles } from 'uinix-ui';

import {
  Box,
  Button,
  Checkbox,
  Layout,
  Radio,
  TruncateText,
} from '../../../components';
import { Cell } from '../Cell';

const getSortTooltipRange = (cellType) => {
  switch (cellType) {
    case 'link':
    case 'text':
      return ['A', 'Z'];
    case 'datetime':
      return ['oldest', 'newest'];
    case 'number':
      return ['smallest', 'largest'];
    default:
      return ['ascending', 'descending'];
  }
};

function render(
  {
    disableSelectColumn,
    isMultiSelectColumns,
    isSelected,
    isSorted,
    isSortedDesc,
    resizerProps,
    cellLayoutProps = {},
    title,
    sortProps,
    onSelectColumn,
    styles,
  },
  type,
) {
  const [sortLow, sortHigh] = getSortTooltipRange(type);
  const { onClick: onSortClick } = sortProps;
  const sortTooltip = isSorted
    ? `Sorted ${isSortedDesc ? sortHigh : sortLow} to ${
        isSortedDesc ? sortLow : sortHigh
      }`
    : `Sort ${sortLow} to ${sortHigh}`;
  const sortIcon = isSorted
    ? isSortedDesc
      ? 'arrow-down'
      : 'arrow-up'
    : undefined;

  const selectColumnsName = 'table-column-select';
  const selectColumnsOption = {
    value: title,
    label: title,
  };

  let content;
  if (onSelectColumn && !disableSelectColumn) {
    if (isMultiSelectColumns) {
      content = (
        <Checkbox
          labelVariant="body-medium"
          name={selectColumnsName}
          option={selectColumnsOption}
          value={isSelected}
          onChange={onSelectColumn}
        />
      );
    } else {
      content = (
        <Radio
          checked={isSelected}
          labelVariant="body-medium"
          name={selectColumnsName}
          option={selectColumnsOption}
          onChange={onSelectColumn}
          value={title}
        />
      );
    }
  } else if (onSortClick) {
    content = (
      <Box
        styles={type === 'loading' ? styles.disabledVisual : null}
        flex="auto"
      >
        <Button
          icon={sortIcon}
          mode="area"
          text={title}
          tooltip={sortTooltip}
          onClick={type !== 'loading' ? onSortClick : noop}
        />
      </Box>
    );
  } else {
    content = (
      <TruncateText textAlign="left" variant="body-medium">
        {title}
      </TruncateText>
    );
  }

  return (
    <Layout
      align="center"
      flex="none"
      justify="inherit"
      spacing={2}
      styles={{
        ':hover .resizer': {
          opacity: 1,
        },
      }}
      {...cellLayoutProps}
    >
      {content}
      {resizerProps && (
        <Box
          styles={{
            display: 'inline-block',
            height: '100%',
            position: 'absolute',
            paddingLeft: 1,
            paddingRight: 1,
            right: 0,
            top: 0,
            touchAction: 'none',
            transform: 'translateX(50%)',
            zIndex: 'forward',
          }}
          {...resizerProps}
        >
          <Box
            className="resizer"
            styles={{
              backgroundColor: 'border.divider',
              height: '100%',
              opacity: 0,
              width: '1px',
            }}
          />
        </Box>
      )}
    </Layout>
  );
}

function HeaderCell({ column }) {
  const {
    canResize,
    cellLayoutProps,
    cellType,
    disableSelectColumn,
    isPrimary,
    isMultiSelectColumns,
    isSelected,
    isSorted,
    isSortedDesc,
    title,
    tooltip,
    totalLeft,
    getHeaderProps,
    getResizerProps,
    getSortByToggleProps,
    onSelectColumn,
    width,
  } = column;

  const ariaSort = isSorted
    ? isSortedDesc
      ? 'descending'
      : 'ascending'
    : undefined;

  const styles = useStyles();

  return (
    <Cell
      align="left"
      aria-sort={ariaSort}
      as="th"
      cellProps={{
        cellLayoutProps,
        disableSelectColumn,
        isMultiSelectColumns,
        isSelected,
        isSorted,
        isSortedDesc,
        resizerProps: canResize ? getResizerProps() : null,
        sortProps: getSortByToggleProps(),
        title,
        tooltip,
        onSelectColumn,
        styles,
      }}
      flex={width === 'auto' ? 'auto' : 'none'}
      isActive={isSelected}
      isPrimary={isPrimary}
      stickyLeft={isPrimary ? totalLeft : undefined}
      style={getHeaderProps().style}
      type={cellType}
      width={width}
      render={render}
      onClick={onSelectColumn}
    />
  );
}

export default HeaderCell;
