import React from 'react';

import { PAGE_HEIGHT_WITHOUT_NAV } from '~/constants/page';
import { PageLayout } from '~/eds';

import { Contents } from './Contents';
import { Header } from './Header';

export const ClauseLibraryPage = () => {
  return (
    <PageLayout
      header={<Header />}
      height={PAGE_HEIGHT_WITHOUT_NAV}
      title="Clause Library"
    >
      <Contents />
    </PageLayout>
  );
};
