export default `
<svg
  aria-hidden="true"
  aria-label="filter"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M4 6.3125C4 5.59375 4.5625 5 5.28125 5H18.6875C19.4062 5 20 5.59375 20 6.3125C20 6.625 19.875 6.90625 19.6875 7.125L14.5 13.5312V18C14.5 18.5625 14.0312 19 13.4688 19C13.25 19 13.0312 18.9375 12.8438 18.7812L9.96875 16.5C9.65625 16.25 9.5 15.9062 9.5 15.5312V13.5312L4.28125 7.125C4.09375 6.90625 4 6.625 4 6.3125ZM5.6875 6.5L10.8125 12.7812C10.9375 12.9375 11 13.0938 11 13.25V15.4062L13 17V13.25C13 13.0938 13.0312 12.9375 13.1562 12.7812L18.2812 6.5H5.6875Z"
    fill="currentcolor"
  />
</svg>
`;
