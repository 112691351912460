import PT from 'prop-types';
import React from 'react';

import { ContentContainer } from '~/eds';

// TODO: deprecate this indirection and simply use `eds.ContentContainer` directly
function LoadingContainer({ children = null, isLoading = false, message }) {
  return (
    <ContentContainer
      loadingContent={{
        isLoading,
        message,
      }}
    >
      {children}
    </ContentContainer>
  );
}

LoadingContainer.propTypes = {
  /** Contents to render if loading is false */
  children: PT.node,
  /** Determines if loading state is occurring */
  isLoading: PT.bool,
  /** Optional loading message */
  message: PT.string,
  /** With FF on (serving EDS LoadingContent), optional mode ('quote' mode
   * or default 'message' mode) to be passed to EDS LoadingContent */
  mode: PT.oneOf(['message', 'quote']),
};

export default LoadingContainer;
