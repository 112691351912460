import React from 'react';

import { SharedPanelProps, SharedStepsProps, UserAction } from '../../../types';
import { Box } from '../../Box';
import { Layout } from '../../Layout';
import { StepsPanel } from '../../Panel';
import { Modal } from '../Modal';

interface Props {
  /** Modal contents */
  children: React.ReactNode;
  /** Visibility of the modal */
  isVisible: boolean;
  /** Props to manage steps */
  steps: SharedStepsProps & {
    title: string;
  };
  /** Modal title */
  title: string;
  /** Callback when hiding the modal */
  onHide: () => void;
  /** Optional back action */
  backAction?: UserAction;
  /** Optional panel */
  panel?: SharedPanelProps;
  /** Primary action button (should only accept `primary` and `danger` as button variants) */
  primaryAction?: UserAction;
  /** Secondary action buttons */
  secondaryActions?: UserAction[];
}

export const WizardModal = ({
  backAction,
  children,
  isVisible,
  panel,
  primaryAction,
  secondaryActions,
  steps,
  title,
  onHide,
}: Props) => {
  return (
    <Modal
      isFullPage
      backAction={backAction}
      cancelText={null}
      enableContentPadding={false}
      isVisible={isVisible}
      panel={panel}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      title={title}
      onHide={onHide}
    >
      <Layout h="100%" spacing={8} overflowY="hidden">
        <StepsPanel {...steps} />
        <Box h="100%" flex="auto" overflowX="none" overflowY="auto" p={4}>
          {children}
        </Box>
      </Layout>
    </Modal>
  );
};
