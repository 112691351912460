export default `
<svg
  aria-hidden="true"
  aria-label="status-danger"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12.8193 5C13.2568 5 13.6631 5.25 13.8818 5.625L20.6318 17.125C20.8506 17.5312 20.8506 18 20.6318 18.375C20.4131 18.7812 20.0068 19 19.5693 19H6.06934C5.60059 19 5.19434 18.7812 4.97559 18.375C4.75684 18 4.75684 17.5312 4.97559 17.125L11.7256 5.625C11.9443 5.25 12.3506 5 12.8193 5ZM12.8193 9C12.3818 9 12.0693 9.34375 12.0693 9.75V13.25C12.0693 13.6875 12.3818 14 12.8193 14C13.2256 14 13.5693 13.6875 13.5693 13.25V9.75C13.5693 9.34375 13.2256 9 12.8193 9ZM13.8193 16C13.8193 15.4688 13.3506 15 12.8193 15C12.2568 15 11.8193 15.4688 11.8193 16C11.8193 16.5625 12.2568 17 12.8193 17C13.3506 17 13.8193 16.5625 13.8193 16Z"
    fill="currentcolor"
  />
</svg>
`;
