import PropTypes from 'prop-types';
import { useState } from 'react';

const EcRadioContainer = ({
  children,
  defaultValue = null,
  groupName,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const onOptionChange = (selectedValue) => {
    setSelectedValue(selectedValue);
    onChange({ selectedValue });
  };

  return children(groupName, selectedValue, onOptionChange);
};

EcRadioContainer.propTypes = {
  defaultValue: PropTypes.any,
  groupName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EcRadioContainer;
