import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { black5 } from '../../../assets/shared-styles/general';
import { ERROR, SUCCESS } from '../../../types/toast.types';
import EcButton from '../../Shared/EcButton';
import EcCard from '../../Shared/EcCard';
import EcMultipleSelectValue from '../../Shared/EcMultipleSelectValue';
import EcMultiSelect from '../../Shared/EcMultiSelect';
import EcRadioButton from '../../Shared/EcRadioButton';
import EcRadioContainer from '../../Shared/EcRadioContainer';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import SubArrowIcon from '../../Shared/Icons/SubArrowIcon';
import { saveReviewerSettings } from '../ReviewerAdminPage.data';
import styles from './ReviewerAdminClients.styles';

const ecCardContentStyles = { padding: '16px' };

class ReviewerAdminClients extends Component {
  constructor(props) {
    super(props);

    this.handleOnSaveChangesClick = this.handleOnSaveChangesClick.bind(this);
    this.state = {
      selectedClients: [],
      reviewAllClients: true,
      submitting: false,
    };
  }

  transformOptionValue(options) {
    return options.map((item) => {
      return {
        label: item.display_value,
        value: item.value,
      };
    });
  }

  componentDidMount() {
    const { selectedClients, reviewAllClients } = this.props;

    this.setState({
      selectedClients: this.transformOptionValue(selectedClients),
      reviewAllClients,
    });
  }

  handleOnSaveChangesClick() {
    const { showToast } = this.props;
    const review_all_clients = this.state.reviewAllClients;
    const selected_clients = this.state.selectedClients.map(
      (party) => party.value,
    );

    this.setState({ submitting: true });
    saveReviewerSettings({ review_all_clients, selected_clients })
      .then(() => showToast(SUCCESS, 'Client settings saved.'))
      .catch(() => showToast(ERROR, 'Could not save client settings.'))
      .finally(() => this.setState({ submitting: false }));
  }

  renderSelectedClientsContainer() {
    const { classes } = this.props;
    const { selectedClients } = this.state;

    return (
      <div className={classes.multipleValuesContainer}>
        <SubArrowIcon color={black5} />
        <div className={classes.multipleValues}>
          {selectedClients.length ? (
            selectedClients.map((value, index) => (
              <EcMultipleSelectValue
                label={value.label}
                key={index}
                closeHandler={() => {
                  const newSelectedClients = selectedClients.filter(
                    (multiValue) => multiValue.value !== value.value,
                  );
                  this.setState({ selectedClients: newSelectedClients });
                }}
              />
            ))
          ) : (
            <span className={classes.multipleValuesEmpty}>
              No values selected...
            </span>
          )}
        </div>
      </div>
    );
  }

  renderClientsRadio() {
    const { classes } = this.props;

    return (
      <EcRadioContainer
        groupName="review_clients"
        onChange={({ selectedValue }) =>
          this.setState({ reviewAllClients: selectedValue })
        }
        defaultValue={this.state.reviewAllClients}
      >
        {(groupName, selectedValue, onOptionChange) => (
          <div className={classes.radioContainerContent}>
            <EcRadioButton
              id="all_clients"
              groupName={groupName}
              value={true}
              label="All clients"
              onChange={onOptionChange}
              selectedValue={selectedValue}
            />
            <EcRadioButton
              id="specific_clients"
              groupName={groupName}
              value={false}
              label="Specific clients"
              onChange={onOptionChange}
              selectedValue={selectedValue}
            />
          </div>
        )}
      </EcRadioContainer>
    );
  }

  render() {
    const { classes, allClients } = this.props;
    const { selectedClients, submitting } = this.state;

    const clientList = this.transformOptionValue(allClients);

    return (
      <div className={classes.cardWrapper}>
        <EcCard title="Clients" contentStyles={ecCardContentStyles}>
          {this.renderClientsRadio()}
          <div className={classes.excludeClients}>
            <div className={classes.sectionSubtitle}>
              {this.state.reviewAllClients ? 'EXCLUDE' : 'SELECT'} CLIENTS
            </div>
            <div className={classes.sectionContent}>
              <div className={classes.excludeClientsInfo}>
                Any clients selected here will be{' '}
                {this.state.reviewAllClients ? 'excluded from' : 'included for'}{' '}
                the reviewers.
              </div>
              <div className={classes.dataFieldContainer}>
                <EcMultiSelect
                  value={selectedClients}
                  options={clientList}
                  onChange={(selectedClients) =>
                    this.setState({ selectedClients })
                  }
                  getOptionLabel={(option) => option.label}
                  placeholder="Search for clients..."
                  noOptionsMessage="No clients found"
                />
                {this.renderSelectedClientsContainer()}
              </div>
            </div>
          </div>
          <div className={classes.sectionActions}>
            <EcButton
              yellow
              text="Save Changes"
              disabled={submitting}
              iconLeft={<CheckmarkIcon size="20" />}
              onClick={this.handleOnSaveChangesClick}
            />
          </div>
        </EcCard>
      </div>
    );
  }
}

ReviewerAdminClients.propTypes = {
  reviewAllClients: PropTypes.bool.isRequired,
  selectedClients: PropTypes.array.isRequired,
  allClients: PropTypes.array.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default injectSheet(styles)(ReviewerAdminClients);
