import { FieldModelConfigState } from '~/features/x-ray/fields';
import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { PromptModelConfigSuggestResponse } from '../../types/sylvanus/model';
import {
  mapFieldClassificationConfigAttributes,
  mapModelConfigAttributes,
} from './transformers';

interface Request {
  config: FieldModelConfigState;
  modelId: Uuid;
}

type Response = PromptModelConfigSuggestResponse;

export const suggestPromptModelInstructions = async ({
  config,
  modelId,
}: Request): Promise<string | undefined> => {
  if (modelId !== 'new') {
    const response: Response = await sylvanus.get(
      `/model/${modelId}/config-suggest`,
    );
    return response.config.instructions;
  }

  const { field } = config;

  if (field) {
    const response: Response = await sylvanus.post('/model/config-suggest', {
      field_name: field.label,
      instructions: '', // required by API but unimportant
      ...mapFieldClassificationConfigAttributes(config),
      ...mapModelConfigAttributes(config),
    });

    return response.config.instructions;
  }
};
