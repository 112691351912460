import React, { memo } from 'react';

import { Theme } from '../../types';
import { Image } from '../Image';

type LogoType =
  | 'logo-dark'
  | 'logo-dark-icon'
  | 'logo-light'
  | 'logo-light-icon';

interface Props {
  mode?: 'full' | 'icon';
  theme?: Theme;
}

export const Logo = memo(({ mode = 'full', theme = 'light' }: Props) => {
  const isDarkTheme = theme === 'dark';
  const isIcon = mode === 'icon';
  const image = `logo${isDarkTheme ? '-light' : '-dark'}${
    isIcon ? '-icon' : ''
  }`;

  return (
    <Image
      alt="Evisort logo"
      flex="none"
      h="height.s"
      image={image as LogoType}
      title="Evisort"
      w="auto"
    />
  );
});
