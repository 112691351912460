import type { Element } from 'hast';
import React, { ReactNode } from 'react';

import { Link, Text } from '~/eds';

import { Citation, UserType } from '../types';
import { Citation as CitationComponent } from './components/Sources/Citation';

export const createMarkdownComponents = (
  {
    citations,
    onCitationClick,
    isReplying,
  }: {
    citations: Citation[];
    onCitationClick: (citation: Citation) => void;
    isReplying: boolean;
  },
  options: {
    shouldSkipLinks: boolean;
    externalLinkProps: {
      disabledTooltip: string;
      regexp: RegExp;
    };
  },
) => {
  if (options.shouldSkipLinks) {
    const renderCustomLink = (props: {
      children: ReactNode[];
      node: Element;
      href?: string;
    }) => {
      const linkIndex = citations.findIndex(
        (citation) => citation.key === props.node.children[0].value,
      );
      const citation = citations[linkIndex];
      const text = `${linkIndex + 1}`;

      if (!citation) {
        const { disabledTooltip, regexp } = options.externalLinkProps;
        const linkProps: React.ComponentProps<typeof Link> = {
          ...props,
          display: 'inline-flex' as const,
        };
        if (props.href) {
          const disabled = !regexp.test(props.href);
          if (disabled) {
            linkProps.disabled = disabled;
            linkProps.tooltip = disabledTooltip;
          }
        }
        return <Link {...linkProps} />;
      }
      if (isReplying) return <Text>{text}</Text>;
      return (
        <CitationComponent
          mode="inline"
          citation={citation}
          text={text}
          onClick={onCitationClick}
        />
      );
    };
    return {
      a: renderCustomLink,
    };
  }
};

export const testIsTicketCitation = (citation: Citation) =>
  citation.entity.type === 'ticket';

export const getUserTheme = (userType: UserType) => ({
  bg: userType === 'other' ? 'brand.ai.light' : 'background',
});
