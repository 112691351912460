import PropTypes from 'prop-types';
import React from 'react';

const DashIcon = (props) => {
  const { white, color } = props;

  let iconColor = color;
  if (white) {
    iconColor = '#fff';
  }

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <rect width="14" height="2" x="5" y="11" fill={iconColor} rx="1" />
      </g>
    </svg>
  );
};

DashIcon.defaultProps = {
  size: '24',
  color: '#000',
};

DashIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default DashIcon;
