import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.DashboardV2, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.DashboardV2 as TagType],
  endpoints: {
    getDashboardsV2: {
      providesTags: [TAG_BY_LIST],
    },
    updateDashboard: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deleteDashboard: {
      invalidatesTags: [TAG_BY_LIST],
    },
  },
});
