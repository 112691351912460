import {
  black1,
  black2,
  black3,
  bold,
} from '../../../assets/shared-styles/general';

const styles = {
  fieldTable: {
    width: '100%',
    borderCollapse: 'collapse',
    color: black1,
    textAlign: 'left',
    '& th': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '0 16px 16px 0',
      color: black2,
      fontSize: '15px',
      fontWeight: bold,
      '&:nth-child(1)': {
        width: '30%',
      },
      '&:nth-child(2)': {
        width: '24%',
      },
      '&:nth-child(3)': {
        width: '33%',
      },
      '&:nth-child(4)': {
        width: '24%',
      },
    },
    '& td': {
      padding: '12px 0',
      color: black2,
      fontSize: '15px',
      wordBreak: 'break-all',
    },
  },
  tableHeaderContainer: {
    paddingBottom: '15px',
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    fontSize: '14px',
    fontWeight: bold,
  },

  fieldNameContainer: {
    width: '41%',
    display: 'flex',
  },
  fieldTypeContainer: {
    width: '21%',
    display: 'flex',
  },
  fieldDocTypeContainer: {
    width: '32%',
    display: 'flex',
  },
  emptyMessage: {
    marginTop: '20px',
    color: black3,
    fontSize: '15px',
    textAlign: 'center',
  },
  sortableHelper: {
    zIndex: 10,
  },
};

export default styles;
