import PropTypes from 'prop-types';
import React from 'react';

import { TicketStatusType } from '~/enums';
import { Icon } from '~/ui';

const { Approved, Rejected, Pending } = TicketStatusType;

function StatusIcon({ status }) {
  let statusIcon;
  switch (status) {
    case Approved:
      statusIcon = (
        <Icon icon="checkmarkCircledFilled" color="green-500" size="s" />
      );
      break;
    case Rejected:
      statusIcon = <Icon icon="stop" color="red-600" size="s" />;
      break;
    case Pending:
    default:
      statusIcon = <Icon icon="donut" color="yellow-500" size="s" />;
      break;
  }

  return statusIcon;
}

StatusIcon.propTypes = {
  status: PropTypes.oneOf([Approved, Rejected, Pending]),
};

export default StatusIcon;
