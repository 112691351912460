import { capitalize } from 'lodash';

import { EventList } from '~/features/webhooks/types';
import { courier } from '~/services';

import { GetEventTypeNestedResponseDto } from '../../types/courier/eventTypes';

type Response = GetEventTypeNestedResponseDto;

export const getConfigurationEvents = async (): Promise<EventList[]> => {
  const response: Response = await courier.get(`/event-types/available/nested`);
  return mapResponseToEventsConfiguration(response);
};

const mapResponseToEventsConfiguration = (
  data: Record<string, string[]>,
): EventList[] => {
  const response = Object.keys(data).map((key) => {
    return {
      name: key,
      options: data[key].map((value) => ({
        label: value,
        value,
        description:
          //@ts-ignore - this is a mock
          value.description ?? 'this',
      })),
      label: capitalize(key),
    };
  });

  return response;
};
