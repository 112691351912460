export const ADMIN_PERMISSION_IDS = [
  '5fcdf68d-9f6a-4137-8c67-7e31222735c5',
  '1f98ae2b-0c4f-4c4a-ae9b-21582a5e1622',
  'c978e2d8-7032-4e4e-9c20-98a79fc43f7d',
  '607e2151-b480-4f19-918f-9e57ab9e7d39',
  'e68f77b6-3e08-4e6f-9ed2-1ad6b1c8f927',
  '050e16f8-725e-422e-a1f9-16f6af78b09c',
  'a19a7f5c-028e-4e4b-a87e-30c2e78309bc',
  '5c0a8528-7e43-4a86-8c6d-2e4e9e6df5f9',
  '7c72e1c6-8d0a-4e22-9c9e-10bb3ce6f4e6',
  '77d80dd0-f22c-45f9-a51b-7eed54369586',
];

export const CLAUSE_SAMPLES_PERMISSION = '66b31814-5ef7-4e4c-9f68-647475f6f869';
export const FIELD_AI_PERMISSION = '70468eca-8cb0-4893-bbd5-2483e906b26e';
export const AUTOMATION_ADMIN_ROLE_IDS = [
  CLAUSE_SAMPLES_PERMISSION,
  FIELD_AI_PERMISSION,
];

export const CLAUSE_LIBRARY_PERMISSION_IDS = [
  '72917b19-c2fa-4d63-97eb-9c6d04f6c7bc',
  '6b7d0b8f-14c5-482a-9f7e-b8ba2cc5c5e1',
];

export const WORKFLOW_TICKETS_VIEW_ID = 'a33e34d4-8cb3-4a13-8193-1d6de3e54f7c';
export const WORKFLOW_TICKETS_EDIT_ID = '1f2e9eb9-30fb-4b4c-b4b4-4d4d4a8f4c4a';
export const WORKFLOW_BUILDER_ID = '16fbec88-790c-4226-a172-3c8138cbcf20';

export const WORKFLOW_PERMISSION_IDS = [
  WORKFLOW_TICKETS_VIEW_ID,
  WORKFLOW_TICKETS_EDIT_ID,
  WORKFLOW_BUILDER_ID,
];

export const DOCUMENT_PERMISSION_IDS = ['03656c72-5368-4d51-9e0d-008d54b1381e'];

export const DOCUMENT_GROUPS_PERMSSION_IDS = [
  'b7fbf44c-e2a3-4af8-b3f1-7c7d02abca6f',
  'f3a6c2f2-0a76-42b0-942a-8540eeb434f9',
];

export const WORKFLOW_ADMIN_ROLE_ID = '16fbec88-790c-4226-a172-3c8138cbcf20';
export const WORKFLOW_ONLY_ROLE_IDS = [
  '1f2e9eb9-30fb-4b4c-b4b4-4d4d4a8f4c4a',
  'a33e34d4-8cb3-4a13-8193-1d6de3e54f7c',
];

export const DASHBOARDS_PERMISSION_IDS = [
  'b5bd361c-5c60-4c5e-9ef5-3b73a086e002',
  '3be632ad-3ba6-48ea-a6fb-033b14d071e5',
];

// TODO: Remove as per new permissions arrive
export const NOT_IMPLEMENTED_PERMISSIONS = [WORKFLOW_TICKETS_VIEW_ID];
