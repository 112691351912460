import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import {
  BulkImportUserSummaryType,
  BulkImportUserToogleActionName,
} from '~/enums';
import { Link, Switch } from '~/ui';

import styles from './SummaryTable.styles';
import { buildImportSummaryContent } from './SummaryTable.utils';

// This is not relevant after using ui.Table
const SWITCH_COLUMN_WIDTH = '100px';

class SummaryTable extends Component {
  state = {
    importActions: this.props.importActions,
  };

  handleToggleImportAction = (importAction) => {
    const { importActions } = this.state;
    const updatedImportActions = {
      ...importActions,
      [importAction]: !importActions[importAction],
    };
    this.props.updateToogleData(updatedImportActions);
    this.setState({ importActions: updatedImportActions });
  };

  renderErrorItem = () => {
    const {
      classes,
      handleImportResultsErrorFileDownloadClick,
      importSummaryData,
    } = this.props;
    const errorKey = BulkImportUserSummaryType.Errors;
    const resultValue = importSummaryData[errorKey];

    const downloadedLink = !!resultValue && (
      <Link
        variant="xs-dense"
        onClick={handleImportResultsErrorFileDownloadClick}
      >
        Export error sheet
      </Link>
    );

    return (
      <tr key={`summary_type_${errorKey}`}>
        <td className={!!resultValue && classes.titleContentError}>
          Errors found
        </td>
        <td className={!!resultValue && classes.resultContentError}>
          {resultValue}
        </td>
        <td colspan="2">{downloadedLink}</td>
      </tr>
    );
  };

  renderSummaryItems = () => {
    const { importActions } = this.state;
    const { classes, importSummaryData } = this.props;
    const importSummaryContent = buildImportSummaryContent(
      importSummaryData,
      importActions,
    );

    return Object.keys(importSummaryContent).map((key) => {
      const {
        resultTypeAction,
        resultTypeActionName,
        resultTypeName,
        resultTypeDescription,
        resultTypeValue,
        isDangerous,
      } = importSummaryContent[key];
      return (
        <tr key={`summary_type_${key}`}>
          <td>
            <div className={classes.titleContent}>{resultTypeName}</div>
            <div className={classes.titleDescription}>
              {resultTypeDescription}
            </div>
          </td>
          <td>{resultTypeValue}</td>
          <td>{resultTypeActionName}</td>
          <td>
            {resultTypeAction in importActions && (
              <Switch
                id={resultTypeAction}
                size="s"
                value={importActions[resultTypeAction]}
                onChange={() => this.handleToggleImportAction(resultTypeAction)}
                variant={isDangerous ? 'danger' : 'default'}
              />
            )}
          </td>
        </tr>
      );
    });
  };

  render = () => {
    const { classes, importSummaryData } = this.props;

    return (
      <table className={classes.summaryTable}>
        <thead>
          <tr className={classes.headerColumn}>
            <th>Total users found in upload</th>
            <th className={classes.resultContent}>
              {importSummaryData[BulkImportUserSummaryType.Total]}
            </th>
            <th />
            <th style={{ width: SWITCH_COLUMN_WIDTH }} />
          </tr>
        </thead>
        <tbody>
          {this.renderSummaryItems()}
          {this.renderErrorItem()}
        </tbody>
      </table>
    );
  };
}

SummaryTable.propTypes = {
  classes: PropTypes.object.isRequired,
  handleImportResultsErrorFileDownloadClick: PropTypes.func.isRequired,
  importSummaryData: PropTypes.object.isRequired,
  updateToogleData: PropTypes.func.isRequired,
  importActions: PropTypes.shape({
    [BulkImportUserToogleActionName.EmailNewUsers]: PropTypes.bool.isRequired,
    [BulkImportUserToogleActionName.UpdateExistingUsers]:
      PropTypes.bool.isRequired,
    [BulkImportUserToogleActionName.DeleteMissingUsers]:
      PropTypes.bool.isRequired,
  }).isRequired,
};

export default injectSheet(styles)(SummaryTable);
