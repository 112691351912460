import PropTypes from 'prop-types';
import React from 'react';

import { BooleanSelect, FlexLayout, Icon, Text } from '~/ui';
import { createConditionRule } from '~/utils/workflow';

import ConditionSelect from './ConditionSelect';

function ConditionRuleInput({
  conditionRule,
  id,
  label = 'Show when',
  onChange,
}) {
  const hasConditionRule = !!conditionRule;

  function onSpecifyCondition() {
    hasConditionRule ? onChange(null) : onChange(createConditionRule());
  }

  return (
    <FlexLayout flexDirection="column" space={3}>
      <FlexLayout
        alignItems="center"
        id={id}
        space={2}
        onClick={hasConditionRule ? null : onSpecifyCondition}
      >
        <Icon
          color={hasConditionRule ? 'gray-700' : 'blue-500'}
          icon="condition"
          size="s"
        />
        <Text
          color={hasConditionRule ? 'gray-700' : 'blue-500'}
          variant="xs-dense-bold"
        >
          {hasConditionRule ? 'Condition' : 'Specify Condition'}
        </Text>
      </FlexLayout>
      {hasConditionRule && (
        <FlexLayout
          alignItems="center"
          justifyContent="space-between"
          space={4}
        >
          <FlexLayout alignItems="center" space={4}>
            <Text color="black" variant="xs-dense">
              {label}
            </Text>
            <ConditionSelect
              width="l"
              value={conditionRule.id}
              onChange={(id) => onChange({ ...conditionRule, id })}
            />
            <BooleanSelect
              width="input.s.width"
              placeholder="is true / is false"
              value={conditionRule.satisfiedWhenCondMet}
              onChange={(value) =>
                onChange({ ...conditionRule, satisfiedWhenCondMet: value })
              }
            />
          </FlexLayout>
          <Icon
            color="black-alpha-75"
            icon="trash"
            size="s"
            title="remove condition"
            onClick={onSpecifyCondition}
          />
        </FlexLayout>
      )}
    </FlexLayout>
  );
}

export const conditionRulePropType = PropTypes.shape({
  id: PropTypes.string,
  satisfiedWhenCondMet: PropTypes.bool,
  enabledWhenSatisfied: PropTypes.bool,
});
ConditionRuleInput.propTypes = {
  /** instance of ConditionRule */
  conditionRule: conditionRulePropType,
  /** Optional ID */
  id: PropTypes.string,
  /** Optional text label */
  label: PropTypes.string,
  /** callback function returning an instance of ConditionRule */
  onChange: PropTypes.func.isRequired,
};

export default ConditionRuleInput;
