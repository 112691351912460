import React from 'react';
import { Day as DayPickerDay, DayProps } from 'react-day-picker';

import { Tooltip } from '../../Tooltip';
import { useView } from './ViewContext';

interface DayPickerCustomProps extends DayProps {
  getDayTooltip?: (date: Date) => string;
}

export const Day = (props: DayPickerCustomProps) => {
  const { view } = useView();
  const tooltip = props?.getDayTooltip ? props.getDayTooltip(props.date) : '';

  if (view !== 'day') {
    return null;
  }

  return (
    <Tooltip tooltip={tooltip}>
      <DayPickerDay {...props} />
    </Tooltip>
  );
};
