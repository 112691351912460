import React, { memo, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Box, ContentContainer } from '~/eds';
import { removeBreaklinesFromText } from '~/utils/strings';

import { loadingMessage } from '../constants';
import { BaseDocumentViewerProps, InteractionPosition } from '../types';
import { useHighlightsMap } from '../useHighlightsMap';
import { useInteractionHighlight } from '../useInteractionHighlight';
import { getInteractionPosition } from '../utils';
import {
  createHighlightTransform,
  defaultPdfHighlighterProps,
  mapPdfHighlights,
  PdfHighlighter,
  Toolbar,
  usePdfDocument,
} from './bridge';
import { PdfHighlight } from './types';
import { useScrollToHighlight } from './useScrollToHighlight';

export const PdfDocumentViewer = memo(
  ({
    activeHighlightId,
    file,
    highlights = [],
    invalidateActiveHighlightId,
    onHighlightClick,
    onHighlightCreate,
  }: BaseDocumentViewerProps) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [pdfDocument, isReady] = usePdfDocument(file);

    const [pdfScaleValue, setPdfScaleValue] = useState<number | 'page-width'>();

    const pdfHighlights = useMemo(() => mapPdfHighlights(highlights), [
      highlights,
    ]);

    const highlightsMap = useHighlightsMap(highlights);

    const {
      clearInteractionHighlight,
      renderInteractionHighlightActionsMenu,
      setInteractionHighlight,
    } = useInteractionHighlight({
      containerRef,
      onHighlightClick,
      onHighlightCreate,
    });

    useScrollToHighlight({
      activeHighlightId,
      pdfHighlights,
      deps: [highlights, invalidateActiveHighlightId],
    });

    const handleHighlightClick = onHighlightClick
      ? (pdfHighlight: PdfHighlight, position: InteractionPosition) => {
          const highlight = highlightsMap[pdfHighlight.id];
          if (highlight) {
            onHighlightClick.callback?.(highlight);
            setInteractionHighlight({
              highlight,
              interaction: 'click',
              position,
            });
          }
        }
      : undefined;

    const loadingContent = {
      isLoading: !isReady,
      message: loadingMessage,
    };

    return (
      <div ref={containerRef} style={componentStyles.container}>
        <Toolbar
          searchListenOn={containerRef}
          zoom={{
            scale: pdfScaleValue,
            onChange: setPdfScaleValue,
          }}
        />
        <Box styles={componentStyles.inner}>
          <ContentContainer loadingContent={loadingContent}>
            {pdfDocument && (
              <PdfHighlighter
                {...defaultPdfHighlighterProps}
                pdfDocument={pdfDocument}
                pdfScaleValue={
                  pdfScaleValue ? String(pdfScaleValue) : undefined
                }
                highlights={pdfHighlights}
                highlightTransform={createHighlightTransform({
                  containerRef,
                  onClick: handleHighlightClick,
                })}
                // @ts-ignore -- onScroll is added onto PdfHighlighter
                onScroll={clearInteractionHighlight}
                onSelectionFinished={({
                  pageNumber,
                  rects,
                }: {
                  // vendor is untyped
                  pageNumber: any;
                  rects: any;
                }) => {
                  if (onHighlightCreate) {
                    const selection = window.getSelection();
                    if (selection && selection.rangeCount > 0) {
                      const range = selection.getRangeAt(0);
                      const rect = range.getBoundingClientRect();
                      const newHighlight = {
                        id: uuid(),
                        text: removeBreaklinesFromText(
                          selection.toString().trim(),
                        ),
                        pdfCoordinates: {
                          pageNumber,
                          rects,
                        },
                      };
                      setInteractionHighlight({
                        highlight: newHighlight,
                        interaction: 'create',
                        position: getInteractionPosition({
                          clientX: rect.left,
                          clientY: rect.top,
                          containerRef,
                        }),
                      });
                      onHighlightCreate.callback?.(newHighlight);
                    }
                  }
                  return null;
                }}
              />
            )}
          </ContentContainer>
          {renderInteractionHighlightActionsMenu()}
        </Box>
      </div>
    );
  },
);

const componentStyles = {
  container: {
    height: '100%',
    position: 'relative' as const,
  },
  inner: {
    position: 'relative',
    '& span.highlight': {
      backgroundColor: 'highlight.search',
    },
    '& span.highlight.selected': {
      backgroundColor: 'highlight.search',
      backgroundImage: 'linear-gradient(rgba( 0, 0, 0, 0.05) 0 0)',
    },
    '& .textLayer': { zIndex: 'auto' },
    ':hover': {
      backgroundImage: 'none',
    },
    ':active': {
      backgroundImage: 'none',
    },
    height: '100%',
    marginTop: '16px',
  },
};
