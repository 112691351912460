import {
  black4,
  evisortRed,
  semibold,
} from '../../assets/shared-styles/general';

const styles = {
  reviewersPage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  reviewerHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '21px',
  },
  reviewerBackAction: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    cursor: 'pointer',
  },
  reviewerPageTitle: {
    flexGrow: 1,
  },
  pageName: {
    margin: 0,
  },
  subTitle: {
    color: black4,
    fontSize: '13px',
    fontWeight: semibold,
    letterSpacing: '1px',
  },
  contentContainer: {
    '& > div:not(:last-child)': {
      marginBottom: '24px',
    },
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '268px',
  },
  errorMessage: {
    margin: '20px',
    color: evisortRed,
    fontSize: '15px',
    textAlign: 'center',
  },
  reviewedDocumentsContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

export default styles;
