import { noop } from 'lodash';
import React from 'react';

import { Filter } from '~/evifields';
import { SearchFilters } from '~/features/filters';

interface Props {
  scope: Filter[];
  readOnly?: boolean;
  onFiltersChange?: (updatedFilters: Filter[]) => void;
}

export const ModelScope = ({
  scope,
  readOnly,
  onFiltersChange = noop,
}: Props) => (
  <SearchFilters
    readOnly={readOnly}
    placeholder="–"
    filters={scope}
    onFilterChange={onFiltersChange}
  />
);
