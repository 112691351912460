import { sylvanus } from '~/services';
import { Nullable, Uuid } from '~/types';

import { PostUserMessageParams } from '../transformers';
import {
  Conversation,
  ConversationMessage,
  ExternalEntity,
  ListConversationsApiV1ConversationsGetApiArg,
  PageConversation,
  UserRating,
} from '../types/sylvanus/conversation';

interface PostUserRatingParams {
  conversationId: Uuid;
  messageId: Uuid;
  userRating: Nullable<UserRating>;
}

export const getAllConversations = async (
  params: ListConversationsApiV1ConversationsGetApiArg,
): Promise<PageConversation> => {
  return sylvanus.get('/conversations', {
    params: { entity_id: params.entityId, entity_type: params.entityType },
  });
};

export const createNewConversation = async (
  entity: ExternalEntity,
): Promise<Conversation> => {
  return sylvanus.post('/conversation', { entities: [entity] });
};

export const getExistingConversation = async (
  conversationId: Uuid,
): Promise<Conversation> => {
  return sylvanus.get(`/conversation/${conversationId}`);
};

export const postNewUserMessage = async ({
  conversationId,
  message,
  context,
  metadata,
}: PostUserMessageParams): Promise<ConversationMessage> => {
  return sylvanus.post(`/conversation/${conversationId}/message`, {
    message,
    context,
    metadata: metadata
      ? {
          question_id: metadata?.questionId,
          question_group_id: metadata?.questionGroupId,
        }
      : undefined,
  });
};

export const postUserRating = async ({
  conversationId,
  messageId,
  userRating,
}: PostUserRatingParams): Promise<ConversationMessage> => {
  return sylvanus.patch(
    `/conversation/${conversationId}/message/${messageId}`,
    {
      user_rating: userRating,
    },
  );
};

export const acceptLegalDisclaimer = async () => {
  return sylvanus.post('/user/accept-disclaimer');
};

export const interruptAiMessage = async (conversationId: Uuid) => {
  return sylvanus.post(`/conversation/${conversationId}/interrupt`);
};
