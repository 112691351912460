/**
 * UserRoleSingleSelect renders a user selector similar to UserSingleSelect but uses the composite UserRole interface instead of user ID in its `value` and `onChange` props, allowing support for composite/typed user interfaces.
 *
 * It renders the provided `rolesOptions` prop into the menu options.
 *
 * The implementation uses the fact that user IDs (whether PilotID or uuid) are unique, and that the provided `roles` are also unique in relation to user IDs.  This allows the implementation to assume uniqueness of option values to perform lookups and subsequently infer how to create a UserRole object.
 */
import React, { useMemo } from 'react';

import { types } from '~/eds';
import { PilotId } from '~/types';

import UserSelect from '../Shared/UserSelect';

// semantic local type aliases
type Role = string;
type RoleSet = Set<Role>;
type Value = Role | PilotId | null;
type RoleOption = types.Option<Role>;

interface UserValue {
  id: PilotId;
  role: null;
  type: 'user';
}

interface RoleValue {
  id: null;
  role: Role;
  type: 'user';
}

type UserRoleValue = UserValue | RoleValue | null;

interface Props {
  value: UserRoleValue;
  onChange: (updatedValue: UserRoleValue) => void;
  disabled?: boolean;
  id?: string;
  error?: string;
  placeholder?: string;
  roleOptions?: RoleOption[];
}

const UserRoleSingleSelect = ({
  disabled,
  id,
  error,
  placeholder,
  roleOptions = [],
  value,
  onChange,
}: Props) => {
  const roleSet: RoleSet = useMemo(
    () => new Set(roleOptions.map((role) => role.value)),
    [roleOptions],
  ); // for O(1) lookup.

  const handleChange = (updatedValue: Value) =>
    onChange(
      toUserRole({
        roleSet,
        value: updatedValue,
      }),
    );

  return (
    <UserSelect
      name="select-user-role"
      isMulti={false}
      id={id}
      disabled={disabled}
      error={error}
      placeholder={placeholder}
      value={fromUserRole(value)}
      roles={roleOptions}
      onChange={handleChange}
    />
  );
};

export const toUserRole = ({
  value,
  roleSet,
}: {
  value: Value;
  roleSet: RoleSet;
}): UserRoleValue => {
  if (value === null) {
    return null;
  } else if (typeof value === 'string' && roleSet.has(value)) {
    return {
      id: null,
      type: 'user',
      role: value,
    } as RoleValue;
    // Note: There are unhandled conditional branches based on the runtime data provided.  However, the behavior of this method requests for the right data spec to be honored for it to be well-behaved.
  } else {
    return {
      id: value,
      type: 'user',
      role: null,
    } as UserValue;
  }
};

export const fromUserRole = (userRole: UserRoleValue): Value => {
  if (userRole === null) {
    return null;
  }
  const { id, role } = userRole;
  return role || id;
};

export default UserRoleSingleSelect;
