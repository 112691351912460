// numbers
export const BOOST_CYCLE = 10;
export const CORRECT_VALUE_MAX_LENGTH = 10240;
export const FEEDBACK_MAX_LENGTH = 1000;
export const HIGHLIGHT_SOURCE_LIMIT = 10;
export const MAX_TESTCASE_NUMBER = 100;
export const PROMPT_WORD_LIMIT = 1000;
export const RECOMMENDED_PUBLISH_TEST_CASE_COUNT = 10;

// Descriptions
export const VALUE_DOES_NOT_EXIST_TEXT =
  'Value does not exist in the document.';

// DOM IDs
export const MODEL_INSTRUCTIONS_ELEMENT_ID = 'field-ai-model-instructions';
export const PUBLISH_ELEMENT_ID = 'field-ai-publish';

// TODO: deprecate hardcoded design UI
export const REVIEW_TEST_CASE_LABEL_WIDTH = '100px';
export const INFO_LABEL_FLEX = `0 0 ${REVIEW_TEST_CASE_LABEL_WIDTH}`;
export const INFO_SPACING = 2;
export const INFO_WIDTH = '75%';
