import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

import { getCurrentUser } from '~/api';
import userProfileEmpty from '~/assets/images/user-profile-empty.svg';
import {
  Button,
  Grid,
  IconButton,
  Image,
  Layout,
  Text,
  useDeleteModal,
} from '~/eds';
import { api } from '~/redux';

import { ERROR, SUCCESS } from '../../../../types/toast.types';
import { showToast } from '../../../Shared/EcToast';
import { removePic, updatePic } from './AvatarPanel.data';
import { avatarSize, border, layoutSize, styles } from './AvatarPanel.styles';

export const AvatarPanel = ({ user, setCurrentUser }) => {
  const [image, setImage] = useState('');
  const dropzoneRef = useRef(null);
  const editorRef = useRef(null);
  const [scale, setScale] = useState(1.2);

  const handleDrop = (dropped) => {
    setImage(dropped[0]);
  };
  const [getAllUsers] = api.endpoints.getAllUsers.useLazyQuery();

  const handleClickSavePic = () => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      canvasScaled.toBlob((blob) => {
        updatePic(user, blob)
          .then(() => {
            showToast(SUCCESS, 'User profile picture has been updated.');
            getCurrentUser().then((currentUser) => setCurrentUser(currentUser));
            getAllUsers(user.client);
          })
          .catch(() => {
            showToast(ERROR, 'User Profile picture could not be updated.');
          });
      });
    }
  };

  const handleScaling = (type) => {
    if (type === 'Up') {
      setScale(scale + 0.1);
    } else {
      setScale(scale <= 0 ? 0 : scale - 0.1);
    }
  };

  const removeAvatar = () => {
    removePic()
      .then(() => {
        showToast(SUCCESS, 'User profile picture has been removed.');
        getCurrentUser().then((currentUser) => setCurrentUser(currentUser));
        getAllUsers(user.client);
        hideRemoveAvatarModal();
      })
      .catch(() => {
        showToast(ERROR, 'User Profile picture could not be removed.');
      });
  };

  const [
    removeAvatarModal,
    showRemoveAvatarModal,
    hideRemoveAvatarModal,
  ] = useDeleteModal({
    children: <Text>Removing profile picture is not reversible</Text>,
    title: 'Remove profile picture?',
    primaryActionText: 'Remove',
    onDelete: removeAvatar,
  });

  const openUploadDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleCancelPic = () => {
    setImage('');
  };
  return (
    <Dropzone
      ref={dropzoneRef}
      noClick
      noKyboard
      onDrop={handleDrop}
      style={styles.Dropzone}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <div {...getRootProps()}>
            {image ? (
              <Layout w={layoutSize} direction="column">
                <AvatarEditor
                  ref={editorRef}
                  crossOrigin="anonymous"
                  width={avatarSize}
                  height={avatarSize}
                  border={border}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={scale}
                  rotate={0}
                  image={image}
                />
                <Layout justify="center">
                  <Layout preset="buttons">
                    <IconButton
                      icon="plus-circle"
                      text=""
                      tooltip="Zooming in the picture"
                      onClick={() => handleScaling('Up')}
                    />
                    <IconButton
                      icon="remove"
                      text=""
                      tooltip="Zooming out the picture"
                      onClick={() => handleScaling('Down')}
                    />
                  </Layout>
                </Layout>
                <Layout justify="center">
                  <Grid columns={2} columnSpacing={4} rowSpacing={4}>
                    <Button text="Save Picture" onClick={handleClickSavePic} />
                    <Button text="Cancel" onClick={handleCancelPic} />
                  </Grid>
                </Layout>
              </Layout>
            ) : (
              <Layout w={avatarSize} direction="column" spacing={1}>
                <Image
                  src={!Boolean(user.avatar) ? userProfileEmpty : user.avatar}
                  style={styles.avatartImg}
                  alt="avatar image"
                />
                <Button
                  onClick={openUploadDialog}
                  text="Upload Picture or Drag and Drop"
                />
                {Boolean(user.avatar) ? (
                  <Button
                    onClick={showRemoveAvatarModal}
                    text="Remove"
                    variant="tertiary"
                  />
                ) : null}
                {removeAvatarModal}
              </Layout>
            )}
            <input {...getInputProps()} />
          </div>
        );
      }}
    </Dropzone>
  );
};
