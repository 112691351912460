import { FieldType, OperatorId } from './types';

/**
 * This object organizes operators based by field type.
 *
 * The operator order matters and affects how they are shown in the UI.
 */
export const operatorIdsByFieldType: Record<FieldType, OperatorId[]> = {
  age: [
    'equals',
    'greater_than',
    'greater_than_equals',
    'less_than',
    'less_than_equals',
    'between',
  ],
  boolean: ['is_true', 'is_false', 'is_blank', 'is_not_blank'],
  clause: ['contains_any', 'not_contains_any'],
  date: [
    'date_before',
    'date_on',
    'date_after',
    'date_between',
    'date_in_the_last',
    'date_in_the_next',
    'is_blank',
    'is_not_blank',
  ],
  enum: ['equals', 'not_equals', 'is_blank', 'is_not_blank'],
  enum_set: [
    'contains_any',
    'contains_all',
    'not_contains_any',
    'is_blank',
    'is_not_blank',
  ],
  file: ['is_blank', 'is_not_blank'],
  folder: ['in_folder'],
  number: [
    'equals',
    // 'not_equals',
    'greater_than',
    'greater_than_equals',
    'less_than',
    'less_than_equals',
    'between',
    'is_blank',
    'is_not_blank',
  ],
  text: [
    'text_contains_any',
    'text_not_contains_any',
    'is_blank',
    'is_not_blank',
  ],
  text_search: [
    'text_contains_all_of_these_words',
    'text_contains_any_of_these_words',
    'text_contains_exact_phrase',
    'text_not_contains_all_of_these_words',
    'text_not_contains_any_of_these_words',
    'text_not_contains_exact_phrase',
  ],
  document_group_id: [],
  clause_v2: [],
};
