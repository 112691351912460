import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout, Icon, PageLayout, Text } from '~/ui';

const { SidebarSectionItem } = PageLayout;

function Item({ disabled = false, hasError = false, item, onDelete, onEdit }) {
  let ItemContent;
  if (item) {
    const { name, links } = item;
    ItemContent = (
      <FlexLayout
        alignItems="center"
        flex="1 1 auto"
        justifyContent="space-between"
        space={2}
      >
        <FlexLayout alignItems="center" space={4}>
          <Icon
            color={hasError ? 'red-600' : 'plum-500'}
            icon={hasError ? 'warning' : 'condition'}
            size="xl"
          />
          <Text
            color={hasError ? 'red-600' : 'gray-900'}
            variant="xs-dense-medium"
          >
            {name}
          </Text>
        </FlexLayout>
        {links.length === 0 ? (
          <Text
            color="gray-600"
            sx={{ flex: '0 0 auto' }}
            variant="2xs-dense-caps"
          >
            Not used
          </Text>
        ) : (
          <FlexLayout alignItems="center" flex="0 0 auto" space={1}>
            <Box color="plum-500" sx={{ borderRadius: 'm' }}>
              <Icon color="gray-600" icon="link" size="xs" />
            </Box>
            <Text color="gray-600" variant="2xs-dense-caps">
              {links.length}
            </Text>
          </FlexLayout>
        )}
      </FlexLayout>
    );
  } else {
    ItemContent = (
      <Text color="gray-600" variant="xs-dense-medium">
        No conditions yet
      </Text>
    );
  }

  return (
    <SidebarSectionItem
      borderLeftIcon="trash"
      disabled={disabled}
      onClick={onEdit}
      onClickBorderLeftIcon={onDelete}
    >
      {ItemContent}
    </SidebarSectionItem>
  );
}

Item.propTypes = {
  disabled: PT.bool,
  hasError: PT.bool,
  item: PT.object.isRequired,
  onDelete: PT.func.isRequired,
  onEdit: PT.func.isRequired,
};

export default Item;
