import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { noop } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';

import {
  dropzoneEnqueueUploadingFile,
  dropzoneRemoveUploadedFiles,
  dropzoneUpdateUploadingProgress,
} from '~/actions';
import { getUploadLogs, uploadFile } from '~/api';
import EcToast from '~/components/Shared/EcToast';
import { UploadStatusType } from '~/enums';
import { ERROR, SUCCESS } from '~/types/toast.types';

const sliceName = 'fileUpload';

export const initialState = {
  isUploadInfoPanelOpen: false,
  uploadLogs: [],
};

/*************
 * Reducers
 *************/
const openUploadInfoPanelReducer = (state) => {
  state.isUploadInfoPanelOpen = true;
};
const closeUploadInfoPanelReducer = (state) => {
  state.isUploadInfoPanelOpen = false;
};
const setUploadLogsReducer = (state, { payload }) => {
  state.uploadLogs = payload.results;
};

/*************
 * Thunks
 *************/
export const getUserUploads = createAsyncThunk(
  'fileUpload/getUserUploads',
  getUploadLogs,
);

export const openUploadInfoPanelAndGetLogs = createAsyncThunk(
  'fileUpload/openUploadInfoPanelAndGetLogs',
  async (__unused__, thunkAPI) => {
    // open before fetching not after, in case the user closes before the get request resolves
    thunkAPI.dispatch(openUploadInfoPanel());

    await thunkAPI.dispatch(getUserUploads());
  },
);

function getFileUploadRequestPayload(file) {
  const payload = new FormData();
  const form = {
    id: file.upload.uuid,
    file,
    folder: file.folderId,
    filename: file.name,
    upload_form_data: JSON.stringify(file.upload_form_data),
    size_in_bytes: file.size,
  };

  for (const [k, v] of Object.entries(form)) {
    payload.set(k, v);
  }

  return payload;
}

async function uploadDocument(
  file,
  {
    apiConfig,
    onUploadStart,
    onUploadProgress,
    onUploadError,
    onUploadComplete,
  },
) {
  const payload = getFileUploadRequestPayload(file);

  const config = {
    onUploadProgress: (event) => onUploadProgress(file, event),
  };

  onUploadStart(file);

  let resp;

  try {
    resp = await uploadFile({
      ...apiConfig,
      payload,
      config,
    });
    onUploadComplete(file, resp);
  } catch (error) {
    onUploadError(file, error);
  }

  return resp;
}

const uploadDocs = (files, options) => {
  return Promise.all(files.map((file) => uploadDocument(file, options)));
};

export const uploadDocuments = createAsyncThunk(
  'fileUpload/uploadDocuments',
  async ({ files, apiConfig, uploadFormFields }, thunkAPI) => {
    let toastId;
    const hasUploadLogs = ({ service, url }) =>
      service === 'pilot' && url === '/document/upload/';

    const onUploadStart = (file) => {
      const processingFile = {
        id: file.upload.uuid,
        document_name: file.name,
        size: file.size,
        date_added: new Date(),
        progress: 0,
        processing_status: UploadStatusType.Uploading,
      };

      thunkAPI.dispatch(dropzoneEnqueueUploadingFile(processingFile));
    };

    const onUploadProgress = (file, event) => {
      const { total, loaded } = event;
      const progress = (loaded / total) * 100;
      const bytesSent = loaded;
      if (progress === 100 && file.size - bytesSent > 0) return;

      thunkAPI.dispatch(
        dropzoneUpdateUploadingProgress({ id: file.upload.uuid, progress }),
      );
    };

    const onUploadError = (file, error) => {
      const href = hasUploadLogs(apiConfig) ? '/upload' : '#';

      let detail = error?.detail || error;
      let processingStatus = UploadStatusType.UploadFailed;
      if (error?.response?.data?.detail) {
        detail = error.response.data.detail;
        if (detail?.includes('File type not supported')) {
          processingStatus = UploadStatusType.UploadFileTypeFailure;
        }
      }

      if (detail?.includes('DOCTYPE')) {
        detail = 'Service error. Please try again';
      }

      if (!toast.isActive(toastId)) {
        toastId = toast(<EcToast type={ERROR} text={detail} href={href} />);
      } else {
        toast(<EcToast type={ERROR} text={detail} href={href} />);
      }

      thunkAPI.dispatch(
        dropzoneUpdateUploadingProgress({
          id: file.upload.uuid,
          processing_status: processingStatus,
        }),
      );
    };

    const onUploadComplete = (file) => {
      const href = file.uploadCb ? '#' : '/upload';
      toast(
        <EcToast
          type={SUCCESS}
          text={`"${file.name}" has been uploaded successfully.`}
          href={href}
        />,
      );

      thunkAPI.dispatch(dropzoneRemoveUploadedFiles([file.upload.uuid]));
    };

    const options = {
      apiConfig,
      onUploadStart,
      onUploadProgress,
      onUploadError,
      onUploadComplete,
    };

    files.forEach((file) => {
      file['upload_form_data'] = uploadFormFields;
    });

    await uploadDocs(files, options);

    if (hasUploadLogs(apiConfig)) {
      thunkAPI.dispatch(openUploadInfoPanelAndGetLogs());
    }
  },
);

/*************
 * Slice Definition
 *************/
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    openUploadInfoPanel: openUploadInfoPanelReducer,
    closeUploadInfoPanel: closeUploadInfoPanelReducer,
    setUploadLogs: setUploadLogsReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserUploads.fulfilled, setUploadLogsReducer);
    builder.addCase(getUserUploads.rejected, noop);
  },
});

/*************
 * Actions
 *************/
export const {
  openUploadInfoPanel,
  closeUploadInfoPanel,
  setUploadLogs,
} = slice.actions;

export default slice.reducer;
