import { fromExpression } from '~/components/Shared/ConditionExpressionBuilder/utils';
import { WorkflowFieldType, WorkflowOperatorType } from '~/enums';

/**
 * Evaluates if a field is valid within a condition
 *
 * E.g if a condition has a expression
 *
 *  [
 *   "f1-multiple-select",
 *   "CONTAINS_ALL",
 *   ["A"],
 *   "AND",
 *   "f2-single-select",
 *   "CONTAINS_ALL",
 *   "1"
 * ]
 *
 * it will evaluate if the field has the operator `CONTAINS_ALL` and if it is of type `SingleSelect`
 * if those are true, then this condition is invalid.
 *
 * @param condition A condition that is used by fields
 * @param field A field to be evaluated within the condition
 * @returns true if the condition/field
 */
export const isConditionFieldValid = (condition, field) => {
  let isValid = true;
  if (field?.type === WorkflowFieldType.SingleSelect) {
    const { children: conditionRules } = fromExpression(condition.expression);
    const isContainsAllType = conditionRules.some(
      (condition) =>
        condition.fieldId === field.id &&
        condition.operator === WorkflowOperatorType.ContainsAll,
    );
    isValid = !isContainsAllType;
  }
  return isValid;
};
