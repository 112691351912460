import React from 'react';

import {
  DateInput,
  FormField,
  Layout,
  NumberInput,
  Select,
  TextArea,
  TextInput,
} from '~/eds';
import { DataFieldType } from '~/enums';
import { FieldValueSelect } from '~/features/fields';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';

import { FormFieldConfig, UploadField } from './types';
import { formatLabel } from './utils';

interface FormFieldsProps {
  formFields: FormFieldConfig[];
  fields: UploadField[];
  onChange: (formData: UploadField) => void;
}

export const FormFields = ({
  formFields,
  fields,
  onChange,
}: FormFieldsProps) => {
  const user = useCurrentUser();

  const {
    data: dataFields = { results: [] },
  } = api.endpoints.getFields.useQuery(
    {
      ids: formFields.map((field) => field.id),
    },
    { skip: !user.client_config },
  );

  return (
    <Layout preset="form-fields">
      {formFields.map((field) => {
        const isAllowNewOptions = field.allowNewOptions;
        const label = formatLabel(field.label);
        const helpText = dataFields.results.find((item) => item.id === field.id)
          ?.helpText;
        const value = fields.find((formData) => formData.fieldId === field.id)
          ?.value;

        const commonProps = {
          id: `form-field-${field.id}`,
          key: field.id,
          label: label,
          name: `data field ${label}`,
          info: helpText ?? '',
          placeholder: `Search for ${label}…`,
          value: value ?? null,
        };

        let formField;
        switch (field.type) {
          case DataFieldType.ARRAY_SINGLE:
            formField = (
              <FormField
                {...commonProps}
                input={FieldValueSelect}
                inputProps={{
                  fieldId: field.id,
                  enableSearchIcon: true,
                  isMulti: false,
                  isClearable: true,
                  enableOptionIndicator: true,
                }}
                onChange={(value) => {
                  onChange({ fieldId: field.id, value });
                }}
              />
            );
            break;
          case DataFieldType.ARRAY_MULTIPLE:
            formField = (
              <FormField
                {...commonProps}
                input={FieldValueSelect}
                inputProps={{
                  onAddOption: isAllowNewOptions ? () => {} : undefined,
                  fieldId: field.id,
                  enableSearchIcon: true,
                  enableSelectAll: true,
                  isMulti: true,
                }}
                onChange={(value) => {
                  onChange({ fieldId: field.id, value });
                }}
              />
            );
            break;
          case DataFieldType.TEXT_AREA:
            formField = (
              <FormField
                {...commonProps}
                input={TextArea}
                onChange={(value) => {
                  onChange({ fieldId: field.id, value });
                }}
              />
            );
            break;
          case DataFieldType.STRING:
            formField = (
              <FormField
                {...commonProps}
                input={TextInput}
                onChange={(value) => {
                  onChange({ fieldId: field.id, value });
                }}
              />
            );
            break;
          case DataFieldType.DATE:
            formField = (
              <FormField
                {...commonProps}
                input={DateInput}
                onChange={(value) => {
                  onChange({ fieldId: field.id, value });
                }}
              />
            );
            break;
          case DataFieldType.NUMBER:
            formField = (
              <FormField
                {...commonProps}
                input={NumberInput}
                onChange={(value) => {
                  onChange({ fieldId: field.id, value });
                }}
              />
            );
            break;
          case DataFieldType.BOOLEAN:
            formField = (
              <FormField
                {...commonProps}
                input={Select}
                inputProps={{
                  isMulti: false,
                  isClearable: true,
                  options: [
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ],
                }}
                onChange={(value) => {
                  onChange({ fieldId: field.id, value });
                }}
              />
            );
            break;
        }
        return formField;
      })}
    </Layout>
  );
};

// ?How to test these types: BOOLEAN or CLAUSE  when DATA FIELDS do not give you the option to create them.
