export default `
<svg
  aria-hidden="true"
  aria-label="status-complete"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.7656 7.33203C19.0781 7.64453 19.0781 8.11328 18.7656 8.39453L10.5156 16.6445C10.2344 16.957 9.76562 16.957 9.48438 16.6445L5.23438 12.3945C4.92188 12.1133 4.92188 11.6445 5.23438 11.332C5.51562 11.0508 5.98438 11.0508 6.26562 11.332L10.0156 15.082L17.7344 7.33203C18.0156 7.05078 18.4844 7.05078 18.7656 7.33203Z"
    fill="currentcolor"
  />
</svg>
`;
