import { useRef, useState } from 'react';

/**
 *
 * @param initial initial state
 * @returns an array where the first and second positions are the usual "useState", and the third is the ref.
 */
export function useStateAndRef<T>(initial: T) {
  const [value, setValue] = useState<T>(initial);
  const valueRef = useRef(value);
  valueRef.current = value;
  return [value, setValue, valueRef] as const;
}
