import React, { memo } from 'react';

import { SharedChipProps } from '../../types';
import { Chips } from '../Chips';
import { Layout } from '../Layout';
import { Section } from '../Section';

interface Group {
  chips: SharedChipProps[];
  title: string;
  limit?: number;
  step?: number;
}

interface Props {
  groups: Group[];
}

export const ChipsGroups = memo(({ groups }: Props) => {
  return (
    <Layout direction="column" spacing={2}>
      {groups.map(({ chips, limit, step, title }) => (
        <Section key={title} heading="h4" title={title}>
          <Chips chips={chips} limit={limit} step={step} />
        </Section>
      ))}
    </Layout>
  );
});
