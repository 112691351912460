import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { EviProgress } from '~/eds';

const ProgressBar = ({ progress }) => {
  if (isEmpty(progress)) return null;

  return (
    <EviProgress
      activeStage={progress.active}
      stages={[
        {
          title: 'Step 1',
          description: 'Train',
          steps: progress.stages[0],
        },
        {
          title: 'Step 2',
          steps: [],
          ...progress.stages[1],
        },
        {
          title: 'Step 3',
          steps: [],
          ...progress.stages[2],
        },
      ]}
    />
  );
};

const mapStateToProps = ({ automation }) => ({
  progress: automation.model?.progress,
});

export default connect(mapStateToProps, {})(ProgressBar);
