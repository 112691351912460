import React, { Component } from 'react';

function withFocus(WrappedComponent) {
  class WithFocus extends Component {
    constructor(props) {
      super(props);

      this.handleOnFocus = this.handleOnFocus.bind(this);
      this.handleOnBlur = this.handleOnBlur.bind(this);

      this.state = { isFocused: false };
    }

    handleOnFocus() {
      this.setState({ isFocused: true });
    }

    handleOnBlur() {
      this.setState({ isFocused: false });
    }

    render() {
      return (
        <div onFocus={this.handleOnFocus} onBlur={this.handleOnBlur}>
          <WrappedComponent isFocused={this.state.isFocused} {...this.props} />
        </div>
      );
    }
  }

  return WithFocus;
}

export default withFocus;
