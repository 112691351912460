import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import EcFilterInput from '~/components/Shared/EcFilterInput';
import { RIGHT } from '~/constants/keyCodes';
import { testHasTextMatch } from '~/eds';

import { evisortBlue } from '../../../assets/shared-styles/general';
import {
  searchAreaOptions,
  searchWordsContainOptions,
  searchWordsScopeOptions,
} from '../../AnalyzerPage/TextSearchForm/TextSearchForm.utils';
import EcModalCard from '../../Shared/EcModalCard';
import EcTooltip from '../../Shared/EcTooltip';
import ChevronRightIcon from '../../Shared/Icons/ChevronRightIcon';
import PinIcon from '../../Shared/Icons/PinIcon';
import styles from './AddFilterModal.styles';
import { getPinnedFilters } from './AddFilterModal.utils';

class AddFilterModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnCategoryClick = this.handleOnCategoryClick.bind(this);
    this.handleOnSearchChange = this.handleOnSearchChange.bind(this);

    this.state = { query: '', activeCategoryIndex: 0 };
  }

  handleOnSearchChange(query) {
    this.setState({ query, activeCategoryIndex: 0 });
  }

  handleOnPinClick(filterId) {
    this.props.handleFilterOptionsChange(filterId);
  }

  handleOnCategoryClick(activeCategoryIndex) {
    this.setState({ activeCategoryIndex });
  }

  handleOnCategoryKepress(e, activeCategoryIndex) {
    if (e.keyCode === RIGHT) {
      this.setState({ activeCategoryIndex });
    }
  }

  handleOnFilterClick(filter) {
    const { handleAddFilter, hideModal, replacingFilterIndex } = this.props;

    handleAddFilter(filter, replacingFilterIndex);
    hideModal();
  }

  handleOnProvisionClick(provisionName) {
    const { handleAddProvision, hideModal, replacingFilterIndex } = this.props;

    const provision = {
      entity: 'provision',
      provision: provisionName,
      contains: searchWordsContainOptions[0],
      text_search: [],
    };

    handleAddProvision(provision, replacingFilterIndex);
    hideModal();
  }

  handleOnTextSearchClick(textSearch) {
    const { handleAddTextSearch, hideModal, replacingFilterIndex } = this.props;

    const textSearchObject = {
      entity: 'text_search',
      area: textSearch,
      contains: searchWordsContainOptions[0],
      scope: searchWordsScopeOptions[0],
      text: '',
    };

    handleAddTextSearch(textSearchObject, replacingFilterIndex);
    hideModal();
  }

  handleOnBooleanSearchClick() {
    const {
      handleAddBooleanTextSearch,
      hideModal,
      replacingFilterIndex,
    } = this.props;

    const boolTextSearchObject = {
      entity: 'bool_text_search',
      query: '',
    };

    handleAddBooleanTextSearch(boolTextSearchObject, replacingFilterIndex);
    hideModal();
  }

  getFilteredCategories(categories) {
    const { query } = this.state;

    if (!query) return categories;

    return categories.reduce((arr, category) => {
      const [categoryFields, fieldsType] = category.provisions
        ? [category.provisions, 'provisions']
        : category.textSearches
        ? [category.textSearches, 'textSearches']
        : [category.fields, 'fields'];

      const matchingFields = categoryFields.filter((f) => {
        const categoryName = f.hasOwnProperty('label') ? f.label : f;
        return testHasTextMatch(categoryName, query);
      });

      if (matchingFields.length) {
        return [...arr, { ...category, [fieldsType]: matchingFields }];
      }

      return arr;
    }, []);
  }

  renderTooltip(tooltipId, pinned) {
    return <EcTooltip id={tooltipId}>{pinned ? 'Unpin' : 'Pin'}</EcTooltip>;
  }

  renderFilterList(categories) {
    const { classes, enablePinning } = this.props;
    const { activeCategoryIndex } = this.state;

    const activeCategory = categories[activeCategoryIndex];

    if (!activeCategory) {
      return <div className={classes.filterList} />;
    }

    if (activeCategory.textSearches) {
      return (
        <div className={classes.filterList}>
          {activeCategory.textSearches.map((textSearch, index) => (
            <button
              className={classes.filterListItem}
              key={`text-search-${index}`}
              onClick={() => this.handleOnTextSearchClick(textSearch)}
            >
              {textSearch.label}
            </button>
          ))}
        </div>
      );
    }

    if (activeCategory.provisions) {
      return (
        <div className={classes.filterList}>
          {activeCategory.provisions.map((provisionName, index) => (
            <button
              className={classes.filterListItem}
              key={`provision-${index}`}
              onClick={() => this.handleOnProvisionClick(provisionName)}
            >
              {provisionName}
            </button>
          ))}
        </div>
      );
    }

    return (
      <div className={classes.filterList}>
        {activeCategory.fields.map((filter) => {
          const [color, opacity] = filter.is_pinned
            ? [evisortBlue, '1']
            : ['#000', '0.25'];
          const dataForValue = `pin-icon_${filter.id}`;

          return (
            <div className={classes.filterListItem} key={filter.id}>
              <button
                className={classes.filterListButton}
                onClick={() => this.handleOnFilterClick(filter)}
              >
                {filter.label}
              </button>
              {enablePinning && (
                <span data-tip data-for={dataForValue}>
                  <button
                    title={`pin ${filter.label}`}
                    className={classes.filterListButton}
                    onClick={() => this.handleOnPinClick(filter.id)}
                  >
                    <PinIcon size="20" color={color} opacity={opacity} />
                  </button>
                </span>
              )}
              {this.renderTooltip(dataForValue, filter.is_pinned)}
            </div>
          );
        })}
      </div>
    );
  }

  renderFilterCategories(filterCategories) {
    const { classes } = this.props;
    const { activeCategoryIndex } = this.state;

    return (
      <div className={classes.filterCategories}>
        {filterCategories.map((filterCategory, categoryIndex) => {
          const filterCategoryClass =
            activeCategoryIndex === categoryIndex
              ? classes.activeFilterCategory
              : classes.filterCategory;

          if (filterCategory.title === 'Boolean Text Search') {
            return (
              <button
                className={filterCategoryClass}
                onClick={() => this.handleOnBooleanSearchClick()}
                key={filterCategory.title}
              >
                <span>{filterCategory.title}</span>
              </button>
            );
          }

          return (
            <button
              aria-expanded={activeCategoryIndex === categoryIndex}
              className={filterCategoryClass}
              onClick={() => this.handleOnCategoryClick(categoryIndex)}
              onKeyDown={(e) => this.handleOnCategoryKepress(e, categoryIndex)}
              key={filterCategory.title}
            >
              <span>{filterCategory.title}</span>
              <ChevronRightIcon size="20" opacity="0.5" />
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      classes,
      enablePinning,
      enableTextSearch,
      filterOptions,
      hideModal,
      provisionOptions,
    } = this.props;
    const { query } = this.state;

    let totalFilterCategories = [];
    if (enablePinning) {
      totalFilterCategories.push({
        title: 'Pinned',
        fields: getPinnedFilters(filterOptions),
      });
    }
    totalFilterCategories = [...totalFilterCategories, ...filterOptions];
    if (provisionOptions.length) {
      totalFilterCategories.push({
        title: 'Provisions',
        provisions: provisionOptions,
      });
    }
    if (enableTextSearch) {
      totalFilterCategories.push({
        title: 'Text Search',
        textSearches: searchAreaOptions,
      });
      totalFilterCategories.push({ title: 'Boolean Text Search', fields: [] });
    }

    const filteredCategories = this.getFilteredCategories(
      totalFilterCategories,
    );

    return (
      <EcModalCard
        title="Add a Filter"
        content={
          <div className={classes.modalContent}>
            <div className={classes.filterCategoriesWrapper}>
              <div className={classes.filterSearchWrapper}>
                <EcFilterInput
                  value={query}
                  onChange={(query) => this.handleOnSearchChange(query)}
                  onClearSearch={() => this.handleOnSearchChange('')}
                  placeholder="Search for filters..."
                />
              </div>
              {this.renderFilterCategories(filteredCategories)}
            </div>
            {this.renderFilterList(filteredCategories)}
          </div>
        }
        hideModal={hideModal}
      />
    );
  }
}

AddFilterModal.propTypes = {
  enablePinning: PropTypes.bool,
  enableTextSearch: PropTypes.bool,
  filterOptions: PropTypes.array.isRequired,
  provisionOptions: PropTypes.array.isRequired,
  handleFilterOptionsChange: PropTypes.func.isRequired,
  handleAddFilter: PropTypes.func.isRequired,
  handleAddProvision: PropTypes.func.isRequired,
  handleAddTextSearch: PropTypes.func.isRequired,
  handleAddBooleanTextSearch: PropTypes.func.isRequired,
  replacingFilterIndex: PropTypes.number,
};

AddFilterModal.defaultProps = {
  enablePinning: true,
  enableTextSearch: true,
};

export default injectSheet(styles)(AddFilterModal);
