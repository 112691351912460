import { noop } from 'lodash';

import { Layout, Text, TextArea } from '~/eds';

import { INFO_SPACING } from '../../constants';
import { PromptModelTestCase } from '../../types';

export const ReviewLlmOutput = ({
  testCase,
}: {
  testCase: PromptModelTestCase;
}) => {
  if (!testCase.llmOutput) {
    return null;
  }

  return (
    <Layout align="flex-start" spacing={INFO_SPACING}>
      <Text flex={infoLabelFlex} variant="body-bold">
        LLM Output
      </Text>
      <TextArea
        readOnly
        enableAutoResize={false}
        name="llm-output"
        rows={5}
        value={JSON.stringify(JSON.parse(testCase.llmOutput), null, 2)}
        onChange={noop}
      />
    </Layout>
  );
};

const infoLabelFlex = '0 0 100px';
