import { black3 } from '../../../assets/shared-styles/general';

const styles = {
  selectedOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 5px',
    borderBottom: '1px solid #ccc',
    color: black3,
    fontSize: '14px',
  },
  accountUserNameItem: {
    display: 'flex',
    alignItems: 'center',
  },
  allowDownloadWrapper: {
    display: 'flex',
    marginRight: '10px',
  },
  allowDownloadContainer: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '15px',
    '& label': {
      color: black3,
    },
  },
  removeUserIconWrapper: {
    padding: 0,
    cursor: 'pointer',
    '& svg': {
      flexShrink: 0,
      marginLeft: '8px',
    },
  },
};

export default styles;
