import { pilot } from '../../../../services';

export const uploadImage = (avatar) => {
  return pilot.post('/user/avatar/', {
    file: avatar,
  });
};

export const updatePic = (user, imageFile) => {
  const formData = new FormData();
  formData.append('file', imageFile, `${user.email}.png`);
  formData.append('user_id', user.id);
  return pilot.post('/user/avatar/', formData);
};

export const removePic = () => {
  return pilot.remove('/user/avatar/remove/');
};
