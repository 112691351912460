import AuthenticationStore from '~/auth';
import { types } from '~/eds';
import { RoleType } from '~/enums';
import { pilot, pilotV3, rolodex } from '~/services';
import { Nullable, RbacPermission } from '~/types';

import { getResources } from '../ApiTypes';
import permissionsResponse from './permissionsResponse.json';

export type RoleAttributes = {
  id: string;
  name: string;
  role_type: RoleType;
  description: string;
  users: number;
  permissions: string[];
  created_by: Nullable<number>;
  created_at: string | Date;
};

export type RolesParams = {
  client_id?: types.PilotId;
  name?: string;
  role_type?: string;
  page: number;
  pageSize: number;
};

type RolesMeta = {
  count: number;
  saml_default_role_uuid: Nullable<string>;
  page: {
    size: number;
    current: number;
    next: Nullable<string>;
  };
};

const AS = AuthenticationStore();

export const fetchRoles: getResources<
  'role',
  RoleAttributes,
  RolesParams,
  RolesMeta
> = ({ name, role_type, page, pageSize, client_id }) => {
  return pilotV3.get('/roles/', {
    params: {
      client_id,
      name,
      role_type,
      'page[number]': page,
      'page[size]': pageSize,
    },
  });
};

export const fetchRolesSimple: getResources<
  'role',
  RoleAttributes,
  RolesParams,
  RolesMeta
> = ({ name, role_type, page, pageSize, client_id }) => {
  return pilotV3.get('/roles/simple/', {
    params: {
      client_id,
      name,
      role_type,
      'page[number]': page,
      'page[size]': pageSize,
    },
  });
};

interface RoleParams {
  name: string;
  description: string;
  permissions: string[];
  client_id: string;
}

export const createRole = (params: RoleParams) => {
  return pilotV3.post('/roles/', {
    data: {
      attributes: params,
    },
  });
};

export const updateRole = ({
  roleId,
  params,
}: {
  roleId: string;
  params: RoleParams;
}) => {
  return pilotV3.put(`/roles/${roleId}/`, {
    data: {
      attributes: params,
    },
  });
};

interface ValidateRoleNameParams {
  name: string;
}

interface ValidateRoleNameAttributeResponse {
  attributes: {
    isUnique: boolean;
  };
}

export const validateRoleName = async ({
  name,
}: ValidateRoleNameParams): Promise<ValidateRoleNameAttributeResponse> => {
  const response = await pilotV3.get('/roles/validate/', {
    params: {
      name,
    },
  });
  return response?.data;
};

export const deleteRole = ({
  roleId,
  newRoleId,
  defaultRoleId,
}: {
  roleId: string;
  newRoleId?: string;
  defaultRoleId?: string;
}) => {
  return pilotV3.remove(`/roles/${roleId}/`, {
    params: {
      replacement_uuid: newRoleId,
      saml_default_role_uuid: defaultRoleId,
    },
  });
};

export type Permission = {
  id: string;
  name: string;
  description: string;
  feature: string;
  premium?: boolean;
  relations: string[];
};

export type PermissionGroup = {
  type: string;
  id: string;
  attributes: PermissionGroupAttributes;
};

export type PermissionGroupAttributes = {
  id: string;
  name: string;
  permissions: Permission[];
};

export const fetchPermissions = async ({
  hasAskAnything,
  hasFolderAccessSettings,
}: {
  hasAskAnything?: boolean;
  hasFolderAccessSettings?: boolean;
}): Promise<{ data: PermissionGroup[] }> => {
  const AI_TOOLS_GROUP_ID = 'c2b75206-3c03-4293-a0a6-0e8afdd1fda1';
  const CONVERSATIONAL_AI_PERMISSION_ID =
    '583c61a8-02a4-4d21-9cb8-792f1c5bd7e5';

  const DOCUMENT_PERMSISION_GROUP_ID = '0e2a99d1-03a6-4f10-8df2-9a28a30e1c16';
  const FOLDER_ACESS_ID = 'f049f264-d14a-4ef3-9958-fc1932e43f12';

  const updatedPermissions = (permissionsResponse.data as PermissionGroup[]).map(
    (group) => {
      if (group.id === AI_TOOLS_GROUP_ID) {
        return {
          ...group,
          attributes: {
            ...group.attributes,
            permissions: group.attributes.permissions.map((permission) => {
              if (
                permission.id === CONVERSATIONAL_AI_PERMISSION_ID &&
                hasAskAnything
              ) {
                return { ...permission, name: 'Ask AI: Access' };
              }
              return permission;
            }),
          },
        };
      }
      if (group.id === DOCUMENT_PERMSISION_GROUP_ID) {
        const { permissions } = group.attributes;
        return {
          ...group,
          attributes: {
            ...group.attributes,
            permissions: hasFolderAccessSettings
              ? permissions
              : permissions.filter(
                  (permission) => permission.id !== FOLDER_ACESS_ID,
                ),
          },
        };
      }
      return group;
    },
  );

  return Promise.resolve({ data: updatedPermissions });
};

export type UserParams = {
  searchValue?: string;
  page: number;
  pageSize: number;
  clientId?: types.PilotId;
  roles?: types.PilotId[];
  departments?: types.PilotId[];
  status?: UserAttributes['status'][];
  useRolodex?: boolean;
};

export interface UserAttributes {
  id: string;
  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  role_id: string;
  role_type: RoleType;
  sso_user: string;
  custom_attribute_values: Record<string, string>;
  departments: {
    department_name: string;
    department_id: number;
  }[];
  job_title: string;
  status: 'Active' | 'Deactivated' | 'Pending';
  last_login: string;
}
interface UsersMeta {
  count: number;
  columns: {
    key: string;
    label: string;
  }[];
  page: {
    size: number;
    current: number;
    next: null | number;
  };
}

export const fetchUsers: getResources<
  'user',
  UserAttributes,
  UserParams,
  UsersMeta
> = ({
  searchValue,
  page,
  pageSize,
  clientId,
  departments,
  roles,
  status,
  useRolodex,
}) => {
  if (useRolodex && AS.isRolodexAuthed()) {
    return rolodex.get('/user', {
      params: {
        client_id: clientId,
        searchValue,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        roles: roles,
        departments: departments,
        status: status,
      },
    });
  } else {
    return pilotV3.get('/users/', {
      params: {
        client_id: clientId,
        searchValue,
        'page[number]': page,
        'page[size]': pageSize,
        roles: roles,
        departments: departments,
        status: status,
      },
    });
  }
};

export interface CreateUserParams {
  email: string;
  first_name: string;
  last_name: string;
  job_title: Nullable<string>;
  client: number;
  role_uuid: string;
  departments: number[];
  useRolodex?: boolean;
}

export interface CreateUserResponse {
  data: {
    id: string;
    type: 'user';
    attributes: CreateUserParams;
  };
}

export const createUser = async ({
  body,
  useRolodex,
}: {
  body: CreateUserParams;
  useRolodex?: boolean;
}): Promise<CreateUserResponse> => {
  if (useRolodex && AS.isRolodexAuthed()) {
    const response = await rolodex.post('/user/create', {
      data: { attributes: body },
    });
    return {
      data: {
        id: response.data.attributes.user_id,
        type: 'user',
        attributes: body,
      },
    };
  } else {
    return pilotV3.post('/users/', { data: { attributes: body } });
  }
};

export const sendInvitationEmail = ({
  userId,
  useRolodex,
}: {
  userId: string;
  useRolodex: boolean;
}) => {
  if (useRolodex && AS.isRolodexAuthed()) {
    return rolodex.patch(`/user/send-invite/${userId}`);
  } else {
    return pilot.post('login/admin-reset-password/', { userId });
  }
};

export const updateUser = async ({
  userId,
  body,
  useRolodex,
}: {
  userId: string;
  body: CreateUserParams;
  useRolodex?: boolean;
}) => {
  if (useRolodex && AS.isRolodexAuthed()) {
    const response = await rolodex.put(`/user/${userId}`, {
      data: { attributes: body },
    });
    return {
      data: {
        id: response.data.attributes.user_id,
        type: 'user' as const,
        attributes: body,
      },
    };
  } else {
    return pilotV3.put(`/users/${userId}/`, { data: { attributes: body } });
  }
};

export const deactivateUser = ({
  userId,
  useRolodex = false,
}: {
  userId: string;
  useRolodex?: boolean;
}) => {
  if (useRolodex && AS.isRolodexAuthed()) {
    return rolodex.remove(`/user/delete/${userId}`);
  } else {
    return pilotV3.remove(`/users/${userId}/`);
  }
};

export const reactivateUser = ({
  userId,
  useRolodex = false,
}: {
  userId: string;
  useRolodex?: boolean;
}) => {
  if (useRolodex && AS.isRolodexAuthed()) {
    return rolodex.patch(`/user/restore/${userId}`);
  } else {
    return pilotV3.patch(`/users/restore/${userId}/`);
  }
};

export const cancelUserInvite = ({
  userId,
  useRolodex = false,
}: {
  userId: string;
  useRolodex?: boolean;
}) => {
  if (useRolodex && AS.isRolodexAuthed()) {
    return rolodex.remove(`/user/delete/${userId}`);
  } else {
    return pilotV3.patch(`/users/cancel-invite/${userId}/`);
  }
};

export const resendReactivatedUserInvite = ({
  userId,
  useRolodex,
}: {
  userId: string;
  useRolodex: boolean;
}) => {
  if (useRolodex && AS.isRolodexAuthed()) {
    return rolodex.patch(`/user/send-invite/${userId}`);
  } else {
    return pilotV3.patch(`/users/resend-invite/${userId}/`);
  }
};

export const checkPermission: (
  params: RbacPermission,
) => Promise<{
  data: {
    type: 'permission_check';
    attributes: {
      permission: boolean;
    };
  };
}> = ({ resourceId, resourceType }) => {
  return pilotV3.get('/permission/', {
    params: {
      resource_id: resourceId,
      resource_type: resourceType,
    },
  });
};
