import { ClauseType } from '~/features/clause-library';

import { fetchClauseTypes } from '../methods';
import { ListExtractedClausesApiResponse } from '../types/clouseau/clauses';

export const getClauseTypes = async (): Promise<ClauseType[]> => {
  const response: ListExtractedClausesApiResponse = await fetchClauseTypes();
  return response.data.map((extractedClause) => ({
    id: extractedClause.id,
    name: extractedClause.attributes.name,
    count: extractedClause.attributes.contracts_count,
  }));
};
