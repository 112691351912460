import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { find, get, isEmpty, isNull, noop, omitBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import uuid from 'uuid';

import { Filters_DEPRECATED } from '~/components/Shared/Filters_DEPRECATED';
import { PAGE_START } from '~/constants/page';
import { EnumValue, Field, FieldId, Filter, OperatorId } from '~/evifields';
import { api } from '~/redux';
import {
  DeletedDocumentDeletedByUserOption,
  DeletedDocumentOriginalFolderOption,
} from '~/redux/api/methods';
import { TableQuery } from '~/types';
import * as componentTypes from '~/types';
import { debounce } from '~/utils/debounce';

type DocumentsRestoreFiltersProps = {
  query: TableQuery;
  onChange: any;
  isAdminConsole: boolean;
};

type DocumentRestoreFiltersDefaultField = {
  id: string;
  label: string;
  type: string;
  settings: {
    __testEnableSelectAll?: (_: Filter<EnumValue>) => boolean;
    options?:
      | DeletedDocumentOriginalFolderOption[]
      | DeletedDocumentDeletedByUserOption[];
  };
  whitelistOperatorIds?: OperatorId[];
  render?: componentTypes.Filters.FieldRendererProps;
};

type DocumentRestoreFiltersDefaultFieldNames =
  | 'doc_name'
  | 'folder'
  | 'deleted_date'
  | 'deleted_by';

type DocumentRestoreFiltersDefaultFields = {
  [key in DocumentRestoreFiltersDefaultFieldNames]?: DocumentRestoreFiltersDefaultField;
};

export const DocumentsRestoreFilters = ({
  query,
  onChange = noop,
  isAdminConsole = false,
}: DocumentsRestoreFiltersProps) => {
  const getDeletedDocumentsOriginalFolderEndpoint = isAdminConsole
    ? api.endpoints.getDeletedDocumentsOriginalFolder
    : api.endpoints.getDeletedDocumentsOriginalFolderForCurrentUser;

  const {
    data: folders,
    isFetching: isFetchingFolders,
  } = getDeletedDocumentsOriginalFolderEndpoint.useQuery({
    substringQuery: '',
  });

  const {
    data: users,
    isFetching: isFetchingUsers,
  } = api.endpoints.getDeletedDocumentsDeletedByUsers.useQuery(undefined);

  const AsyncFolderOptionsRenderer: componentTypes.Filters.FieldRendererProps = ({
    Component,
    innerProps,
  }) => {
    const [
      getFieldValues,
      result,
    ] = getDeletedDocumentsOriginalFolderEndpoint.useLazyQuery();
    const debouncedGetFieldValues = useCallback(debounce(getFieldValues, 500), [
      getFieldValues,
    ]);
    const { data, error, isFetching } = result;
    const options = (data || []).map((value) => ({
      value: value.value,
      label: value.display_value,
      description: value.path,
      leftIcon: 'folder',
    }));
    const asyncOptions = { data: options, error, isFetching };
    const fetchOptions = (substringQuery = '') => {
      debouncedGetFieldValues({ substringQuery }, true);
    };

    const asyncProps = {
      asyncOptions,
      onMount: fetchOptions,
      onQuery: fetchOptions,
    };

    return <Component asyncProps={asyncProps} innerProps={innerProps} />;
  };

  const DEFAULT_FIELDS: DocumentRestoreFiltersDefaultFields = {
    doc_name: {
      id: 'doc_name',
      label: 'Name',
      type: 'text',
      whitelistOperatorIds: ['text_contains_any'],
      settings: {},
    },
    folder: {
      id: 'folder',
      label: 'Original Folder',
      type: 'folder',
      settings: {
        __testEnableSelectAll: (_: Filter<EnumValue>) => false,
        options: isFetchingFolders ? [] : folders,
      },
      render: AsyncFolderOptionsRenderer,
    },
    deleted_date: {
      id: 'deleted_date',
      label: 'Deleted date',
      type: 'date',
      whitelistOperatorIds: [
        'date_on',
        'date_before',
        'date_after',
        'date_between',
      ],
      settings: {},
    },
  };
  if (isAdminConsole) {
    DEFAULT_FIELDS.deleted_by = {
      id: 'deleted_by',
      label: 'Deleted by',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        __testEnableSelectAll: (_: Filter<EnumValue>) => false,
        options: isFetchingUsers ? [] : users,
      },
    };
  }

  const DEFAULT_FILTERS = [
    {
      id: uuid.v4(),
      fieldId: 'doc_name',
      operatorId: null,
      values: [],
    },
    {
      fieldId: 'folder',
      id: uuid.v4(),
      operatorId: null,
      values: [],
      asyncValue: {
        isAllSelected: false,
        length: 0,
        search: '',
        selectedValuesMap: {},
      },
    },
    {
      fieldId: 'deleted_date',
      id: uuid.v4(),
      operatorId: null,
      values: [],
    },
  ];
  if (isAdminConsole) {
    DEFAULT_FILTERS.push({
      fieldId: 'deleted_by',
      id: uuid.v4(),
      operatorId: null,
      values: [],
    });
  }

  const [filters, setFilters] = useState(DEFAULT_FILTERS as Filter[]);

  useEffect(() => {
    if (isEmpty(query.filters)) setFilters(DEFAULT_FILTERS as Filter[]);
  }, [query]);

  const parseDateFilter = (ft: Filter | undefined) => {
    if (!ft) return {};

    switch (ft.operatorId) {
      case 'date_on':
        return {
          deleted_date__gte: formatISO(startOfDay(ft.values[0] as Date)),
          deleted_date__lte: formatISO(endOfDay(ft.values[0] as Date)),
        };
      case 'date_before':
        return {
          deleted_date__lte: formatISO(endOfDay(ft.values[0] as Date)),
        };
      case 'date_after':
        return {
          deleted_date__gte: formatISO(startOfDay(ft.values[0] as Date)),
        };
      case 'date_between':
        return {
          deleted_date__gte: formatISO(startOfDay(ft.values[0] as Date)),
          deleted_date__lte: formatISO(endOfDay(ft.values[1] as Date)),
        };
    }
  };

  const onChangeFilter = (fts: Filter[]) => {
    setFilters(fts);

    const filters = {
      ...parseDateFilter(find(fts, (f) => f.fieldId === 'deleted_date')),
      name: get(
        find(fts, (f) => f.fieldId === 'doc_name'),
        'values',
      ),
      originalFolder: get(
        find(fts, (f) => f.fieldId === 'folder'),
        'values',
      ),
      deletedBy: get(
        find(fts, (f) => f.fieldId === 'deleted_by'),
        'values',
      ),
    };

    onChange({ ...query, page: PAGE_START, filters: omitBy(filters, isNull) });
  };

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Filters_DEPRECATED
      fields={DEFAULT_FIELDS as Record<FieldId, Field>}
      filters={filters}
      groups={[]}
      options={{ disableManageFilters: true, disableRemoveFilter: true }}
      onChange={onChangeFilter}
    />
  );
};
