import { formatDate } from '~/eds';
import { DataFieldType } from '~/enums';

export const convertFieldValueData = (type: DataFieldType, value: any) => {
  if (type === DataFieldType.DATE) {
    return value ? formatDate(value, 'iso') : null;
  }
  return value;
};

export const validateFieldValue = (type: DataFieldType, value: any) => {
  if (value === undefined || value === null) return false;
  switch (type) {
    case DataFieldType.ARRAY_MULTIPLE:
      return Array.isArray(value) && value.length > 0;
    case DataFieldType.ARRAY_SINGLE:
      return value?.length > 0;
    case DataFieldType.ATTACHMENT:
      return true;
    case DataFieldType.BOOLEAN:
      return value === true || value === false;
    case DataFieldType.DATE:
    case DataFieldType.NUMBER:
    case DataFieldType.TEXT_AREA:
    case DataFieldType.STRING:
      return !!value;
    default:
      return !!value;
  }
};
