import { pilot } from '~/services';
import { Client } from '~/types';

type GetClientsParams = {
  page?: number;
  searchText?: string;
};

export const getClients = async ({
  page = 1,
  searchText = '',
}: GetClientsParams): Promise<Client[]> => {
  const resp = (await pilot.get(
    `client/list/?page=${page}&name=${searchText}`,
  )) as any;
  return resp.results as Client[];
};
