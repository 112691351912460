import { useEffect, useState } from 'react';

export const useCountdown = (
  start: number,
  tick?: (second: number) => void,
) => {
  const [countdown, setCountdown] = useState(start);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextCountdown = countdown - 1;
      setCountdown(nextCountdown);
      tick?.(nextCountdown);
      if (nextCountdown <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown, tick]);

  return countdown;
};
