import {
  black1,
  black2,
  black3,
  bold,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  modalInfo: {
    marginBottom: '12px',
    color: black2,
    fontSize: '15px',
    lineHeight: '1.47',
    '& span': {
      fontWeight: bold,
    },
  },
  documentItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    '& svg': {
      flexShrink: 0,
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  documentName: {
    wordBreak: 'break-word',
  },
  documentItemSeparator: {
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    margin: 0,
  },
  documentInfo: {
    flexGrow: 1,
    margin: '0 8px',
  },
  documentItemName: {
    marginBottom: '2px',
    color: black1,
    fontSize: '15px',
    fontWeight: semibold,
    wordBreak: 'break-word',
  },
  documentPath: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: black3,
    fontSize: '13px',
  },
  linkButton: {
    padding: '6px 12px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    color: black2,
    fontSize: '14px',
  },
};

export default styles;
