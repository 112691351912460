import PT from 'prop-types';
import React from 'react';

import { Box, Icon, styles, Text, Tooltip } from '~/ui';
import { warn } from '~/utils/development';

import { EDS_ACTION_COLOR } from '../../constants';
import { iconKeyPropType } from '../Icon';

export const variantStyles = {
  primary: {
    bg: EDS_ACTION_COLOR,
    color: 'white',
  },
  secondary: {
    bg: 'white',
    color: 'black-alpha-75',
  },
  red: {
    bg: 'red-600',
    color: 'white',
  },
  green: {
    bg: 'green-500',
    color: 'white',
  },
  outlined: {
    bg: 'white',
    color: 'black-alpha-75',
  },
  dark: {
    bg: 'gray-800',
    color: 'white',
  },
  ghost: {
    bg: 'white',
    color: 'black-alpha-75',
  },
  'ghost-accent': {
    bg: 'white',
    color: 'blue-500',
  },
  link: {
    bg: 'transparent',
    color: EDS_ACTION_COLOR,
  },
};

function Button({
  disabled = false,
  iconLeft,
  iconRight,
  id,
  isFullWidth = false,
  isLoading = false,
  text,
  title,
  tooltip,
  variant = 'primary',
  onClick,
  ...rest
}) {
  const { bg, color } = variantStyles[variant];

  const IconLoading = (
    <Box
      sx={{
        animation: `${styles.keyframes.spin} 1s linear infinite`,
      }}
    >
      <Icon
        className="ui/button__icon-spinner"
        color={color}
        icon="spinner"
        size="xs"
      />
    </Box>
  );

  const hasIconLeft = iconLeft;
  const hasIconRight = iconRight || (!iconLeft && isLoading);
  let IconLeft;
  let IconRight;

  if (hasIconLeft) {
    IconLeft = isLoading ? (
      IconLoading
    ) : (
      <Icon
        className="ui/button__icon-left"
        color={color}
        icon={iconLeft}
        size="xs"
      />
    );
  } else if (hasIconRight) {
    IconRight = isLoading ? (
      IconLoading
    ) : (
      <Icon
        className="ui/button__icon-right"
        color={color}
        icon={iconRight}
        size="xs"
      />
    );
  }

  const ButtonContent = text && (
    <Text
      color={color}
      ml={hasIconLeft ? 2 : 0}
      mr={hasIconRight ? 2 : 0}
      variant="xs-dense-bold"
    >
      {text}
    </Text>
  );

  const isLinkVariant = variant === 'link';

  title = title || text;

  if (!title) {
    warn('<Button> missing title and text - a11y');
  }

  return (
    <Tooltip content={tooltip}>
      <Box
        as="button"
        disabled={disabled || isLoading}
        id={id}
        px={isLinkVariant ? undefined : 4}
        sx={{
          ...styles.interactions.clickable,
          alignItems: 'center',
          bg,
          borderColor: ['ghost', 'ghost-accent', 'link'].includes(variant)
            ? 'transparent'
            : 'black-alpha-25',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderRadius: 'm',
          display: 'flex',
          flex: 'none',
          height: 'input-height',
          justifyContent: 'center',
          outline: 'none',
          '&:hover': {
            background: (theme) =>
              isLinkVariant
                ? undefined
                : `linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), ${theme.colors[bg]}`,
          },
          '&:active': {
            background: (theme) =>
              isLinkVariant
                ? undefined
                : `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${theme.colors[bg]}`,
          },
          width: isFullWidth ? '100%' : 'fit-content',
        }}
        type="button"
        onClick={onClick}
        title={title}
        {...rest}
      >
        {IconLeft}
        {ButtonContent}
        {IconRight}
      </Box>
    </Tooltip>
  );
}

Button.propTypes = {
  /** Disable the button */
  disabled: PT.bool,
  /** Provide a valid icon key to define the left icon */
  iconLeft: iconKeyPropType,
  /** Provide a valid icon key to define the right icon */
  iconRight: iconKeyPropType,
  /** Button ID */
  id: PT.string,
  /** Full width */
  isFullWidth: PT.bool,
  /** Indicates if the button is loading. This will auto-disable the button. */
  isLoading: PT.bool,
  /** Button text */
  text: PT.string,
  /** Button title is expected if text is missing - ie. an icon only button, for a11y purposes */
  title: PT.string,
  /** Button tooltip content */
  tooltip: PT.string,
  /** Button variants */
  variant: PT.oneOf([
    'primary',
    'secondary',
    'red',
    'green',
    'outlined',
    'dark',
    'ghost',
    'ghost-accent',
    'link',
  ]),
  /** Click handler */
  onClick: PT.func,
};

export default Button;
