import PropTypes from 'prop-types';
import React from 'react';

const ChevronDownIcon = (props) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      onClick={props.onClick}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.color}
          fillOpacity={props.opacity}
          fillRule="nonzero"
          d="M7.839 9.31l4.166 4.125 4.167-4.124a1.077 1.077 0 0 1 1.514 0 1.051 1.051 0 0 1 0 1.499l-4.929 4.88a1.077 1.077 0 0 1-1.514 0l-4.929-4.88a1.051 1.051 0 0 1 0-1.5 1.1 1.1 0 0 1 1.525 0z"
        />
      </g>
    </svg>
  );
};

ChevronDownIcon.defaultProps = {
  size: '24',
  color: 'currentColor',
  opacity: '.75',
  onClick: () => {},
};

ChevronDownIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
  onClick: PropTypes.func,
};

export default ChevronDownIcon;
