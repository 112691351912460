import { noop } from 'lodash';
import { useMemo, useState } from 'react';

import { Icon, Layout, Link, Table, Text, types } from '~/eds';
import { DocumentViewerModal } from '~/features/document-viewer';
import { Nullable, PilotId } from '~/types';

import { DEFAULT_FIELD_VALUE } from '../../../../fields/constants';
import { FieldValueDisplay } from '../../../../fields/shared/FieldValue/FieldValueDisplay';
import {
  OptimizeResult,
  OptimizeResultTag,
  OptimizeTableRow,
} from '../../../../types';
import {
  OPTIMIZE_OPTION_PADDING,
  OPTIMIZE_OPTION_WIDTH,
  PAGE_INDEX,
} from '../../constants';
import {
  mapResultsToTableRows,
  mapTagToLabel,
  mapTestCaseOutcomeToStatus,
} from '../../utils';

const TABLE_OPTIONS = {
  enableExportXlsx: false,
  enableSelectRows: false,
  enableManageColumns: false,
  enableControls: false,
  enableSelectColumns: true,
};

interface Props {
  results: OptimizeResult[];
  selectedOption: OptimizeResultTag;
}
export const BoostResultsDetails = ({ results, selectedOption }: Props) => {
  const [activeDocumentVersionId, setActiveDocumentVersionId] = useState<
    Nullable<PilotId>
  >(null);

  const data: OptimizeTableRow[] = mapResultsToTableRows(results);

  const columns = useMemo(() => {
    const linkColumn = {
      key: 'entity',
      cellType: 'link',
      disableSelectColumn: true,
      disableSortBy: true,
      title: '',
      renderCell: (row: OptimizeTableRow) => {
        return (
          <Link
            onClick={() => setActiveDocumentVersionId(Number(row.entityId))}
          >
            {row.id}
          </Link>
        );
      },
      width: 48,
    };

    const resultTags = results.map((result) => result.tag);

    const optionColumns: any[] = [];
    resultTags.forEach((tag) => {
      optionColumns.push({
        key: tag,
        cellType: 'statusText',
        title: mapTagToLabel(tag),
        align: 'left',
        disableResizing: true,
        disableSelectColumn: true,
        disableSortBy: true,
        shouldTruncate: true,
        getDisabledCell: (row: OptimizeTableRow) => row[tag]?.isNotChanged,
        renderCell: (row: OptimizeTableRow) => {
          const testCase = row[tag];
          if (testCase) {
            const { color, icon, tooltip } = mapTestCaseOutcomeToStatus(
              testCase,
            );
            return (
              <Layout align="center" spacing={3}>
                <Icon
                  icon={icon as types.IconType}
                  color={color as types.IconColor}
                  tooltip={tooltip}
                />
                <FieldValueDisplay
                  fieldValue={testCase.modelValue ?? DEFAULT_FIELD_VALUE}
                />
              </Layout>
            );
          } else {
            return (
              <Layout align="center" spacing={3}>
                <Text>-</Text>
              </Layout>
            );
          }
        },
        width:
          results.length === 1
            ? OPTIMIZE_OPTION_WIDTH + OPTIMIZE_OPTION_PADDING * 2
            : OPTIMIZE_OPTION_WIDTH + OPTIMIZE_OPTION_PADDING / 2,
      });
    });

    return [linkColumn, ...optionColumns];
  }, [results]);

  const { total } = results[0].stats;

  return (
    <Layout>
      <Table
        name="Model Performance Details"
        header={{
          title: `Model Performance Details (${total})`,
        }}
        data={data}
        columns={columns}
        options={TABLE_OPTIONS}
        selectedColumnKeys={[selectedOption]}
        onSelectColumns={noop}
        state={{
          pageIndex: PAGE_INDEX,
          pageSize: total,
        }}
      />
      <DocumentViewerModal
        documentViewer={{
          documentVersionId: activeDocumentVersionId,
        }}
        isVisible={Boolean(activeDocumentVersionId)}
        onHide={() => setActiveDocumentVersionId(null)}
      />
    </Layout>
  );
};
