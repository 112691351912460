import { compact } from 'lodash';
import React, { Dispatch, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { Filters_DEPRECATED } from '~/components/Shared/Filters_DEPRECATED';
import { INPUT_DEBOUNCE_MS, Layout, TextInput, useDebounce } from '~/eds';
import { Field, FieldId, Filter } from '~/evifields';
import { actions, api } from '~/redux';
import * as componentTypes from '~/types';
import { ClauseLibraryFilters, Nullable } from '~/types';
import { debounce } from '~/utils/debounce';

const CLAUSE_FILTER_TYPE_ID = 'CLAUSE_FILTER';
const POSITION_FILTER_TYPE_ID = 'POSITION_FILTER';

const ClauseFilter = ({
  filters,
  setClauseLibraryFilter,
  setClauseLibraryIsLoadingSummaries,
}: {
  filters: ClauseLibraryFilters;
  setClauseLibraryFilter: Dispatch<ClauseLibraryFilters>;
  setClauseLibraryIsLoadingSummaries: Dispatch<boolean>;
}) => {
  const [internalQuery, setInternalQuery] = useState<Nullable<string>>(null);

  const AsyncClauseOptions: componentTypes.Filters.FieldRendererProps = ({
    Component,
    innerProps,
  }) => {
    const [
      getFieldValues,
      result,
    ] = api.endpoints.clauseLibrarySearchClauses.useLazyQuery();
    const debouncedGetClauses = useCallback(debounce(getFieldValues, 300), [
      getFieldValues,
    ]);
    const { data, error, isFetching } = result;
    const options = (data?.data || []).map((node) => ({
      value: node.id,
      label: node.attributes.name,
    }));
    const asyncOptions = { data: options, error, isFetching };
    const fetchOptions = (name = '') => {
      debouncedGetClauses({ name }, true);
    };

    const asyncProps = {
      asyncOptions,
      onMount: fetchOptions,
      onQuery: fetchOptions,
    };

    return <Component asyncProps={asyncProps} innerProps={innerProps} />;
  };

  const FIELDS = {
    [CLAUSE_FILTER_TYPE_ID]: {
      id: CLAUSE_FILTER_TYPE_ID,
      label: 'Clause',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      overrideOperatorLabels: {
        contains_any: 'contains',
      },
      settings: {
        __testEnableSelectAll: () => false,
        options: [],
      },
      render: AsyncClauseOptions,
      serialize: () => '1 value',
    },
    [POSITION_FILTER_TYPE_ID]: {
      id: POSITION_FILTER_TYPE_ID,
      label: 'Position',
      type: 'enum',
      whitelistOperatorIds: ['equals'],
      overrideOperatorLabels: {
        equals: 'equals',
      },
      settings: {
        __testEnableSelectAll: () => false,
        options: [
          { label: 'Preferred', value: 'Preferred' },
          { label: 'Fallback', value: 'Fallback' },
          { label: 'Walk away', value: 'Walk away' },
        ],
      },
    },
  };

  const DEFAULT_FILTERS = compact([
    {
      id: CLAUSE_FILTER_TYPE_ID,
      fieldId: CLAUSE_FILTER_TYPE_ID,
      operatorId: 'contains_any',
      values: [],
    },
    {
      id: POSITION_FILTER_TYPE_ID,
      fieldId: POSITION_FILTER_TYPE_ID,
      operatorId: 'equals',
      values: [],
    },
  ]);

  const onSearchTextChange = useDebounce(
    (
      query: Nullable<string>,
      initialFilters: Nullable<ClauseLibraryFilters>,
    ) => {
      setClauseLibraryIsLoadingSummaries(true);
      setClauseLibraryFilter({ ...initialFilters, query });
    },
    INPUT_DEBOUNCE_MS,
    [filters?.query],
  );

  const onFilterChange = (chips: Filter[]) => {
    setClauseLibraryIsLoadingSummaries(true);
    setClauseLibraryFilter({ ...filters, chips });
  };

  return (
    <Layout direction="row" spacing={1}>
      <Layout justify="end" align="center" flex={1}>
        <TextInput
          type="search"
          name="clause-lib-search-language"
          placeholder="Search Clauses and Language"
          value={internalQuery ?? filters?.query ?? ''}
          onChange={(q) => {
            setInternalQuery(q);
            onSearchTextChange(q, filters);
          }}
        />
      </Layout>
      <Layout justify="end" align="center">
        {/* eslint-disable-next-line react/jsx-pascal-case -- deprecating */}
        <Filters_DEPRECATED
          fields={FIELDS as Record<FieldId, Field>}
          filters={(filters?.chips ?? DEFAULT_FILTERS) as Filter[]}
          groups={[]}
          onChange={onFilterChange}
          options={{ disableManageFilters: true, disableRemoveFilter: true }}
        />
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({ clauseLibrary }: any) => ({
  filters: clauseLibrary.filters,
});

export default connect(mapStateToProps, {
  setClauseLibraryIsLoadingSummaries:
    actions.setClauseLibraryIsLoadingSummaries,
  setClauseLibraryFilter: actions.setClauseLibraryFilter,
})(ClauseFilter);
