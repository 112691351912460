import React, { useState } from 'react';

import {
  BaseStatusMessageProps,
  BaseStatusTextProps,
  Input,
  Nullable,
  Theme,
  UserAction,
} from '../../types';
import { typedMemo } from '../../utils';
import { Actions } from '../Actions';
import { Button, CopyButton } from '../Button';
import { IconButton } from '../IconButton';
import { Label } from '../Label';
import { Layout } from '../Layout';
import { Link } from '../Link';
import { Markdown } from '../Markdown';
import { StatusMessage } from '../StatusMessage';
import { StatusText } from '../StatusText';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

type Props<V, M extends boolean> = {
  /** A valid Input component (e.g. `TextInput`, `NumberInput`, `Select`, `Checkbox`) */
  input: Nullable<React.ElementType>;
  /** Input label */
  label: string;
  /** FormField actions */
  actions?: UserAction[];
  /** Custom child content (when `input` is explicitly set to `null`) */
  children?: React.ReactNode;
  /** Optional description */
  description?: string;
  /** Optional description link */
  descriptionLink?: {
    linkText: string;
    pathname: string;
    additionalText?: string;
    tooltip?: string;
    search?: string;
  };
  /** Toggle to activate the copy button for the input field.*/
  enableCopy?: boolean;
  /** Preview an example */
  example?: {
    content: React.ReactNode;
    label?: string;
  };
  /** Footer text */
  footer?: string;
  /** Information that will be displayed in info icon tooltip */
  info?: string;
  /** Input props */
  inputProps?: Object;
  /** Optional setting to toggle form field content collapsed/expanded */
  isCollapsible?: boolean;
  /** Explicitly indicate an input is optional */
  optional?: boolean;
  /** Explicitly indicate an input is required */
  required?: boolean;
  /** Optional status message */
  statusMessage?: BaseStatusMessageProps;
  /** Optinoal status text */
  statusText?: BaseStatusTextProps;
  /** Light / Dark Theme */
  theme?: Theme;
  /** Tooltip for the input (e.g. if it is disabled) */
  tooltip?: string;
  /** Reserves an interactive Explain action button */
  onExplain?: () => void;
} & Input<V, M>;

export const FormField = typedMemo(
  <V extends unknown, M extends boolean>({
    actions = [],
    children,
    description,
    descriptionLink,
    enableCopy,
    example,
    footer,
    info,
    input: Input,
    inputProps: overrideInputProps = {},
    isCollapsible,
    label,
    optional,
    readOnly,
    required,
    // input props
    name,
    value,
    onChange,
    onExplain,
    disabled,
    id,
    error,
    success,
    placeholder,
    statusMessage,
    statusText,
    theme,
    tooltip,
  }: Props<V, M>) => {
    const [isCollapsed, toggleCollapsible] = useState(false);

    const footerText = error || success || footer;
    const footerTextColor = error
      ? 'status.danger'
      : success
      ? 'status.success'
      : 'text.quiet';

    const inputProps = {
      name,
      value,
      onChange,
      disabled,
      id,
      error,
      success,
      placeholder,
      readOnly,
      ...overrideInputProps,
    };

    const input = Input && <Input {...inputProps} />;
    const inputContent = enableCopy ? (
      <Layout spacing={1}>
        {input}
        <CopyButton copyText={String(value)} />
      </Layout>
    ) : (
      input
    );

    const linkDescription = descriptionLink && (
      <Layout preset="xs">
        <Text preset="description">{descriptionLink.additionalText}</Text>
        <Link
          text={descriptionLink.linkText}
          tooltip={descriptionLink.tooltip}
          pathname={descriptionLink.pathname}
          search={descriptionLink.search}
        />
      </Layout>
    );

    const descriptionText = description && (
      <Text preset="description">{description}</Text>
    );

    return (
      <Layout
        direction="column"
        minW="input.min-width"
        spacing={1}
        w="100%"
        aria-labelledby={name}
      >
        <Layout direction="column">
          <Layout align="center" spacing={2}>
            <Label htmlFor={name} id={name} info={info} theme={theme}>
              {label}
              {required && <Text preset="help"> (Required)</Text>}
              {optional && <Text preset="help"> (Optional)</Text>}
            </Label>
            {actions && <Actions actions={actions} />}
            {statusText && <StatusText {...statusText} />}
            {onExplain && (
              <Button
                text="Explain this"
                variant="action"
                onClick={onExplain}
              />
            )}
            {isCollapsible && (
              <IconButton
                preset={isCollapsed ? 'show' : 'hide'}
                size="s"
                onClick={() => toggleCollapsible(!isCollapsed)}
              />
            )}
          </Layout>
          {!isCollapsed && (
            <>
              {descriptionText}
              {linkDescription}
              {example && (
                <Tooltip
                  isInverse
                  isInteractive
                  tooltip={
                    typeof example.content === 'string' ? (
                      <Markdown text={example.content} />
                    ) : (
                      example.content
                    )
                  }
                >
                  <Text color="action.link">
                    {example.label ?? 'Show example'}
                  </Text>
                </Tooltip>
              )}
            </>
          )}
        </Layout>
        {!isCollapsed && (
          <>
            <Tooltip tooltip={tooltip} width="100%">
              <>{children ?? inputContent}</>
            </Tooltip>
            {footerText && (
              <Text color={footerTextColor} variant="tiny" role="note">
                {footerText}
              </Text>
            )}
            {statusMessage && <StatusMessage {...statusMessage} />}
          </>
        )}
      </Layout>
    );
  },
);
