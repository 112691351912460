export default `
<svg
  aria-hidden="true"
  aria-label="loading-dashed"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path opacity="0.4"  d="M10 3C9.51602 3 9.125 3.39102 9.125 3.875V5.625C9.125 6.10898 9.51602 6.5 10 6.5C10.484 6.5 10.875 6.10898 10.875 5.625V3.875C10.875 3.39102 10.484 3 10 3ZM3 10C3 10.484 3.39102 10.875 3.875 10.875H5.625C6.10898 10.875 6.5 10.484 6.5 10C6.5 9.51602 6.10898 9.125 5.625 9.125H3.875C3.39102 9.125 3 9.51602 3 10ZM6.28672 5.05078C5.94492 4.70898 5.39258 4.70898 5.05078 5.05078C4.70898 5.39258 4.70898 5.94766 5.05078 6.28945L6.28945 7.52813C6.63125 7.86992 7.18633 7.86992 7.52813 7.52813C7.86992 7.18633 7.86992 6.63125 7.52813 6.28945L6.28672 5.05078Z" fill="currentcolor" />
  <path opacity="0.4" d="M12.4746 6.28701C12.1328 6.62881 12.1328 7.18389 12.4746 7.52568C12.8164 7.86748 13.3715 7.86748 13.7133 7.52568L14.9492 6.28701C15.291 5.94521 15.291 5.39014 14.9492 5.04834C14.6074 4.70654 14.0523 4.70654 13.7105 5.04834L12.4719 6.28701H12.4746ZM13.5 10.0003C13.5 10.4843 13.891 10.8753 14.375 10.8753H16.125C16.609 10.8753 17 10.4843 17 10.0003C17 9.51631 16.609 9.12529 16.125 9.12529H14.375C13.891 9.12529 13.5 9.51631 13.5 10.0003ZM10 13.5003C9.51602 13.5003 9.125 13.8913 9.125 14.3753V16.1253C9.125 16.6093 9.51602 17.0003 10 17.0003C10.484 17.0003 10.875 16.6093 10.875 16.1253V14.3753C10.875 13.8913 10.484 13.5003 10 13.5003ZM13.7133 12.4749C13.3715 12.1331 12.8164 12.1331 12.4746 12.4749C12.1328 12.8167 12.1328 13.3718 12.4746 13.7136L13.7133 14.9495C14.0551 15.2913 14.6102 15.2913 14.952 14.9495C15.2937 14.6077 15.2937 14.0526 14.952 13.7108L13.7133 12.4722V12.4749ZM5.05078 14.9495C5.39258 15.2913 5.94766 15.2913 6.28945 14.9495L7.52813 13.7108C7.86992 13.369 7.86992 12.814 7.52813 12.4722C7.18633 12.1304 6.63125 12.1304 6.28945 12.4722L5.05078 13.7136C4.70898 14.0554 4.70898 14.6104 5.05078 14.9522V14.9495Z" fill="currentcolor" />
  <path d="M10 3C9.51602 3 9.125 3.39102 9.125 3.875V5.625C9.125 6.10898 9.51602 6.5 10 6.5C10.484 6.5 10.875 6.10898 10.875 5.625V3.875C10.875 3.39102 10.484 3 10 3ZM3 10C3 10.484 3.39102 10.875 3.875 10.875H5.625C6.10898 10.875 6.5 10.484 6.5 10C6.5 9.51602 6.10898 9.125 5.625 9.125H3.875C3.39102 9.125 3 9.51602 3 10ZM6.28672 5.05078C5.94492 4.70898 5.39258 4.70898 5.05078 5.05078C4.70898 5.39258 4.70898 5.94766 5.05078 6.28945L6.28945 7.52813C6.63125 7.86992 7.18633 7.86992 7.52813 7.52813C7.86992 7.18633 7.86992 6.63125 7.52813 6.28945L6.28672 5.05078Z" fill="currentcolor">
    <animateTransform
      id="op0"
      xmlns="http://www.w3.org/2000/svg"
      attributeName="transform"
      type="rotate"
      values="0 10 10;45 10 10;90 10 10;135 10 10;180 10 10;225 10 10;270 10 10;315 10 10;360 10 10"
      dur="1s"
      fill="freeze"
      additive="sum"
      calcMode="discrete"
      repeatCount="indefinite"
      keyTimes="0;0.125;0.25;0.375;0.5;0.625;0.75;0.875;1"
    />
  </path>
</svg>
`;
