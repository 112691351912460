import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { UI_USER_IMPORT_WIZARD_TESTID } from '~/constants/testids';
import {
  BulkImportUserResultType,
  BulkImportUserSummaryType,
  BulkImportUserToogleActionName,
  BulkImportUserType,
  FileExtensionType,
} from '~/enums';
import {
  FileInput,
  FlexLayout,
  Link,
  LoadingSpinner,
  Text,
  WizardPageLayout,
} from '~/ui';

import styles from './BulkImportUserWizard.styles';
import BulkUserImportWarningModal from './BulkUserImportWarningModal';
import { getBaseWarningModalProps } from './BulkUserImportWarningModal/Pressets';
import SummaryTable from './SummaryTable';

const steps = [BulkImportUserType.Upload, BulkImportUserType.Summary];

class BulkImportUserWizard extends Component {
  constructor(props) {
    super(props);

    this.toogleData = {
      [BulkImportUserToogleActionName.EmailNewUsers]: false,
      [BulkImportUserToogleActionName.UpdateExistingUsers]: false,
      [BulkImportUserToogleActionName.DeleteMissingUsers]: false,
    };

    this.state = {
      selectedFile: undefined,
      showWarningModal: false,
      warningModalProps: {},
      shouldSubtractMissingUsersFromTotal: !this.toogleData[
        BulkImportUserToogleActionName.DeleteMissingUsers
      ],
      shouldSubtractUsersToUpdateFromTotal: !this.toogleData[
        BulkImportUserToogleActionName.UpdateExistingUsers
      ],
    };
  }

  handleStartImportUserBulkProcessing = () => {
    this.props.onStartImportUserBulkProcessing(
      this.toogleData,
      this.handleHideWarningModal,
    );
  };

  handleShowWarningModal = () => {
    const baseProps = getBaseWarningModalProps(this.toogleData);
    const warningModalProps = {
      ...baseProps,
      cancelAction: this.handleHideWarningModal,
      confirmAction: this.handleStartImportUserBulkProcessing,
    };
    this.setState({ showWarningModal: true, warningModalProps });
  };

  handleHideWarningModal = () => {
    this.setState({ showWarningModal: false });
  };

  handleUserListUploadActionClick = () => {
    this.props.onUploadActionClick(this.state.selectedFile);
  };

  handleDownloadUserTemplateClick = () => {
    const { handleDownloadUserTemplateFile } = this.props;

    handleDownloadUserTemplateFile(true);
  };

  handleSelectedFile = (files) => {
    this.setState({ selectedFile: files[0] });
  };

  handleImportResultsErrorFileDownloadClick = () => {
    const { handleImportResultsFileDownload } = this.props;
    const resultType = BulkImportUserResultType.SummaryErrors;

    handleImportResultsFileDownload(resultType);
  };

  isBulkUserImportFileAvailable = () => {
    return this.state.selectedFile;
  };

  updateToogleData = (importActions) => {
    this.toogleData = importActions;
    if (this.toogleData[BulkImportUserToogleActionName.UpdateExistingUsers]) {
      this.setState({ shouldSubtractUsersToUpdateFromTotal: false });
    } else {
      this.setState({ shouldSubtractUsersToUpdateFromTotal: true });
    }
    if (this.toogleData[BulkImportUserToogleActionName.DeleteMissingUsers]) {
      this.setState({ shouldSubtractMissingUsersFromTotal: false });
    } else {
      this.setState({ shouldSubtractMissingUsersFromTotal: true });
    }
  };

  renderSelectedFile = () => {
    const { selectedFile } = this.state;

    return (
      selectedFile && (
        <Text p={4} variant="body-1">
          <Text variant="body-2" isBold={true}>
            Selected file:
          </Text>
          &nbsp;{this.state.selectedFile.name}
        </Text>
      )
    );
  };

  handleBackToUploadStep = () => {
    this.props.handleSelectedStep(BulkImportUserType.Upload);
  };

  renderUploadContent = () => {
    const defaultAcceptedTypes = [
      FileExtensionType.Xlsx,
      FileExtensionType.Xls,
    ].join(', ');
    const { stepLoading, file, classes } = this.props;

    return (
      <FlexLayout
        space={4}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        {stepLoading ? (
          <h4>Uploading Users List</h4>
        ) : (
          <>
            <Text variant="subtitle">Submit Your Users List</Text>
            <Text variant="body-1">
              <Link
                variant="body-2"
                color="red-700"
                onClick={this.handleDownloadUserTemplateClick}
              >
                Download the user template
              </Link>{' '}
              to organize your users list for import to Evisort.{' '}
            </Text>
            {this.isBulkUserImportFileAvailable() && this.renderSelectedFile()}
          </>
        )}

        {stepLoading ? (
          <LoadingSpinner size="m" />
        ) : (
          <FileInput
            file={file}
            acceptedFiles={defaultAcceptedTypes}
            onChange={this.handleSelectedFile}
            className={classes.fileInput}
          />
        )}
      </FlexLayout>
    );
  };

  renderSummaryContent = () => {
    const { importSummaryData, stepLoading } = this.props;
    let totalUsers = importSummaryData.results[BulkImportUserSummaryType.Total];
    if (this.state.shouldSubtractMissingUsersFromTotal) {
      totalUsers -=
        importSummaryData.results[BulkImportUserSummaryType.ToBeDeleted];
    }
    if (this.state.shouldSubtractUsersToUpdateFromTotal) {
      totalUsers -=
        importSummaryData.results[BulkImportUserSummaryType.Existing];
    }

    return (
      <FlexLayout
        space={4}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        {stepLoading ? (
          <>
            <h4>Importing Users List</h4>
            <LoadingSpinner size="m" />
          </>
        ) : (
          <SummaryTable
            handleImportResultsErrorFileDownloadClick={
              this.handleImportResultsErrorFileDownloadClick
            }
            importSummaryData={{
              ...importSummaryData.results,
              [BulkImportUserSummaryType.Total]: totalUsers,
            }}
            updateToogleData={this.updateToogleData}
            importActions={this.toogleData}
          />
        )}
      </FlexLayout>
    );
  };

  renderStepActionButton = () => {
    const { step, stepLoading } = this.props;

    return step === BulkImportUserType.Upload
      ? {
          promise: this.handleUserListUploadActionClick,
          text: 'Upload Users List',
          disabled: !this.isBulkUserImportFileAvailable() || stepLoading,
        }
      : {
          promise: this.handleShowWarningModal,
          text: 'Import Users List',
          disabled: stepLoading,
        };
  };

  renderStep = () => {
    const { step } = this.props;

    return step === BulkImportUserType.Upload
      ? this.renderUploadContent()
      : this.renderSummaryContent();
  };

  render = () => {
    const { onClose, step, stepLoading } = this.props;
    const { showWarningModal, warningModalProps } = this.state;
    const backButton = step === BulkImportUserType.Summary && {
      promise: this.handleBackToUploadStep,
      text: 'Back To Upload',
      disabled: stepLoading,
    };

    return (
      <>
        <WizardPageLayout
          title={{ text: 'Import Users' }}
          isContainer
          steps={steps}
          currentStep={step}
          onClose={onClose}
          actionButton={this.renderStepActionButton()}
          backButton={backButton}
          children={
            <div data-testid={UI_USER_IMPORT_WIZARD_TESTID}>
              {this.renderStep()}
            </div>
          }
        />

        {showWarningModal && (
          <BulkUserImportWarningModal {...warningModalProps} />
        )}
      </>
    );
  };
}

BulkImportUserWizard.propTypes = {
  handleDownloadUserTemplateFile: PropTypes.func.isRequired,
  handleImportResultsFileDownload: PropTypes.func.isRequired,
  handleSelectedStep: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  importSummaryData: PropTypes.object,
  onStartImportUserBulkProcessing: PropTypes.func.isRequired,
  onUploadActionClick: PropTypes.func.isRequired,
  step: PropTypes.string,
  stepLoading: PropTypes.bool,
};

export default injectSheet(styles)(BulkImportUserWizard);
