export default `
<svg
  aria-hidden="true"
  aria-label="automation"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.25 4.15234C11.2812 4.05859 11.375 3.99609 11.5 3.99609C11.5938 3.99609 11.6875 4.05859 11.7188 4.15234L12.1562 5.33984L13.3438 5.77734C13.4375 5.80859 13.5 5.90234 13.5 5.99609C13.5 6.12109 13.4375 6.21484 13.3438 6.24609L12.1562 6.68359L11.7188 7.83984C11.6875 7.93359 11.5938 7.99609 11.5 7.99609C11.375 7.99609 11.2812 7.93359 11.25 7.83984L10.8125 6.68359L9.625 6.24609C9.53125 6.21484 9.5 6.12109 9.5 5.99609C9.5 5.90234 9.53125 5.80859 9.625 5.77734L10.8125 5.33984L11.25 4.15234ZM18.9688 4.46484L20.0312 5.52734C20.625 6.12109 20.625 7.05859 20.0312 7.65234L8.125 19.5586C7.53125 20.1523 6.59375 20.1523 6 19.5586L4.9375 18.4961C4.34375 17.9023 4.34375 16.9648 4.9375 16.3711L16.8438 4.46484C17.4375 3.87109 18.375 3.87109 18.9688 4.46484ZM17.9062 5.49609L14.4688 8.93359L15.5625 10.0273L18.9688 6.58984L17.9062 5.49609ZM7.0625 18.4961L14.5 11.0898L13.4062 9.99609L6 17.4336L7.0625 18.4961ZM3.71875 7.68359L5.5 6.99609L6.15625 5.24609C6.1875 5.08984 6.34375 4.99609 6.5 4.99609C6.625 4.99609 6.78125 5.08984 6.8125 5.24609L7.5 6.99609L9.25 7.68359C9.40625 7.71484 9.5 7.87109 9.5 7.99609C9.5 8.15234 9.40625 8.30859 9.25 8.33984L7.5 8.99609L6.8125 10.7773C6.78125 10.9023 6.625 10.9961 6.5 10.9961C6.34375 10.9961 6.1875 10.9023 6.15625 10.7773L5.5 8.99609L3.71875 8.33984C3.5625 8.30859 3.5 8.15234 3.5 7.99609C3.5 7.87109 3.5625 7.71484 3.71875 7.68359ZM14.7188 15.6836L16.5 14.9961L17.1562 13.2461C17.1875 13.0898 17.3438 12.9961 17.5 12.9961C17.625 12.9961 17.7812 13.0898 17.8125 13.2461L18.5 14.9961L20.25 15.6836C20.4062 15.7148 20.5 15.8711 20.5 15.9961C20.5 16.1523 20.4062 16.3086 20.25 16.3398L18.5 16.9961L17.8125 18.7773C17.7812 18.9023 17.625 18.9961 17.5 18.9961C17.3438 18.9961 17.1875 18.9023 17.1562 18.7773L16.5 16.9961L14.7188 16.3398C14.5625 16.3086 14.5 16.1523 14.5 15.9961C14.5 15.8711 14.5625 15.7148 14.7188 15.6836Z"
    fill="currentcolor"
  />
</svg>
`;
