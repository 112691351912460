import React, { memo, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { SharedLinkProps } from '../../types';
import { Link } from '../Link';
import { Text } from '../Text';

const remarkPlugins = [remarkGfm];

interface Props extends React.ComponentProps<typeof Text> {
  /** Simple markdown string (e.g. 'hello', '# hello') */
  text: string;
  /** This prop can be used to render custom components for markdown elements. */
  components?: React.ComponentProps<typeof ReactMarkdown>['components'];
  options?: {
    allowLinks?: {
      disabledTooltip: string;
      regexp: RegExp;
    };
  };
}

export const Markdown = memo(
  ({
    text,
    components: overrideComponents = {},
    options = {},
    ...rest
  }: Props) => {
    const components = useMemo(
      () => ({
        a: (props: { children: React.ReactNode; href?: string }) => {
          const linkProps: SharedLinkProps = {
            ...props,
            display: 'inline-flex' as const,
          };
          const { allowLinks } = options;
          if (allowLinks && props.href) {
            const disabled = !allowLinks.regexp.test(props.href);
            if (disabled) {
              linkProps.disabled = disabled;
              linkProps.tooltip = allowLinks.disabledTooltip;
            }
          }

          return <Link {...linkProps} />;
        },
        ...overrideComponents, // order matters (always allow props.components to override)
      }),
      [options, overrideComponents],
    );

    return (
      <Text {...rest}>
        <ReactMarkdown
          className="eds-markdown"
          components={components}
          linkTarget="_blank"
          remarkPlugins={remarkPlugins}
          skipHtml={true}
        >
          {text}
        </ReactMarkdown>
      </Text>
    );
  },
);
