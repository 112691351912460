import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from 'react-table';
import uuid from 'uuid';

import { toFilter } from '~/components/SearchV2';
import { getSearchableFields } from '~/components/SearchV2/utils';
import { trackSegment } from '~/components/SegmentAnalytics';
import BaseLoadView from '~/components/Shared/BaseLoadView';
import BaseSaveView from '~/components/Shared/BaseSaveView';
import { EntityVisibilityType } from '~/components/Shared/EntityVisibilitySelect';
import SearchV3BooleanSearchBar from '~/components/Shared/SearchV3BooleanSearchBar';
import {
  Box,
  EmptyPage,
  Layout,
  LoadingShimmer,
  PageLayout,
  useTableSelection,
  useToast,
} from '~/eds';
import { TableContextType, TableViewContextType } from '~/enums';
import { FieldId, Filter } from '~/evifields';
import { Filters } from '~/features/advanced-search/filters';
import { QueryBuilder as QueryBuilderEnhancedUx } from '~/features/advanced-search/query-builder';
import { DEFAULT_CHAT_CONTEXT } from '~/features/ask-anything';
import { DEFAULT_SEARCH_ID } from '~/features/ask-anything/ask-anything-button/utils';
import { SearchFilters, useFieldsWithLazyLoad } from '~/features/filters';
import { SaveActionButton, SaveEditEntityModal } from '~/features/search';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser, usePinnedFilters, useTableSettings } from '~/hooks';
import { api, selectors } from '~/redux';
import { buildQuery, QueryGroup } from '~/redux/api/methods/searchV3';
import { chatbotSlice } from '~/redux/slices/chatbot';
import searchV3 from '~/redux/slices/searchV3';
import { RoutePathType, useRouting } from '~/routing';
import { PilotId, QueryOperatorEntity } from '~/types';
import {
  MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
  MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
} from '~/types/modal.types';
import { DEFAULT_TABLE_SETTINGS } from '~/utils/table';
import { testIsAdmin } from '~/utils/user';

import { AddSearchResultsToGroup } from '../AddSearchResultsToGroup';
import { QueryBuilder, ReadOnlyComplexQuery } from '../QueryBuilder';
import { useDocumentsBulkActionsPanel } from '../useDocumentsBulkActionsPanel';
import {
  getActiveFilterIds,
  testHasMixedOperatorInSection,
  testHasNestedSections,
  testIsOperator,
  testIsSection,
} from '../utils/queryUtils';
import { SearchV3Results } from './SearchV3Results';

export const SearchV3 = () => {
  const { navigate, params } = useRouting();
  const { searchId = '' } = params;
  const currentUser = useCurrentUser();

  const {
    data: searchFilters,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFilters.useQuery(undefined);

  const {
    data: savedSearchResponse,
    isLoading: isLoadingSavedSearch,
    error: savedSearchError,
  } = api.endpoints.getSavedSearch.useQuery(searchId, { skip: !searchId });

  const { toast } = useToast();
  const dispatch = useDispatch();

  const [
    fetchTableView,
    tableViewResult,
  ] = api.endpoints.getCurrentTableView.useLazyQuery();
  const {
    data: currentTableView,
    isFetching: isFetchingCurrentTableViewSet,
  } = tableViewResult;

  const [updateTableView] = api.endpoints.updateTableView.useMutation();

  const activeFilters = useSelector(selectors.selectSearchV3ActiveFilters);
  const page = useSelector(selectors.selectSearchV3Page);
  const pageSize = useSelector(selectors.selectSearchV3PageSize);
  const sortBy = useSelector(selectors.selectSearchV3SortBy);
  const searchText = useSelector(selectors.selectSearchV3SearchText);
  const columnOrder = useSelector(selectors.selectSearchV3ColumnOrder);
  const columnWidths = useSelector(selectors.selectSearchV3ColumnWidths);
  const selectedFilters = useSelector(selectors.selectSearchV3SelectedFilters);
  const isSearchInitialized = useSelector(
    selectors.selectSearchV3IsInitialized,
  );
  const featuredClauses = useSelector(selectors.selectSearchV3FeaturedClauses);
  const queryBuilder = useSelector(selectors.selectSearchV3QueryBuilder);
  const queryBuilderPanelVisible = useSelector(
    selectors.selectSearchV3QueryBuilderPanelVisible,
  );
  const resultsCache = useSelector(selectors.selectSearchV3ResultsCache);
  const savedSearch = useSelector(selectors.selectSearchV3SavedSearch);
  const isDirty = useSelector(selectors.selectIsSearchV3Dirty);
  const [isSaveAsVisible, setIsSaveAsVisible] = useState(false);
  const shouldPerformSearch =
    isSearchInitialized || activeFilters.length > 0 || queryBuilder;

  const hasSavePermission =
    testIsAdmin(currentUser) || savedSearch?.userId === currentUser.id;
  const isSaveDisabled = !isDirty || !hasSavePermission;

  const hasTextDelimitedFilterView = useFlag(
    FlagType.TextDelimitedMultiselectFilter,
  );
  const hasNewSearchUI = useFlag(FlagType.SearchNewUI);
  const hasQueryBuilderEnhancedUx = useFlag(FlagType.QueryBuilderEnhancedUx);

  // local state for optimistic update
  const [localPinnedFilterIds, setLocalPinnedFilterIds] = useState<
    Array<PilotId | string>
  >([]);
  const [isAddToGroupVisible, setIsAddToGroupVisible] = useState(false);

  const [isLoadViewVisible, setIsLoadViewVisible] = useState(false);
  const [isSaveViewVisible, setIsSaveViewVisible] = useState(false);

  const {
    getPinnedFilters,
    result: {
      data: userPreferencesDefaultFilters,
      isFetching: isFetchingPinnedFilters,
    },
  } = usePinnedFilters(searchFilters);
  const [updatePinnedFilters] = api.endpoints.updatePinnedFilters.useMutation();
  const [postSavedSearch] = api.endpoints.postSavedSearch.useMutation();
  const [putSavedSearch] = api.endpoints.putSavedSearch.useMutation();

  useEffect(() => {
    if (savedSearchResponse) {
      dispatch(searchV3.actions.setSavedSearch(savedSearchResponse));
    }
    return () => {
      dispatch(searchV3.actions.setSavedSearch(undefined));
    };
  }, [savedSearchResponse]);

  useEffect(() => {
    if (searchFilters) {
      if (savedSearch) {
        dispatch(
          searchV3.actions.initSavedSearchData({
            searchFilters,
            pageSize: tableSettings.pageSize,
            sortBy: tableSettings.sortBy,
          }),
        );
      } else {
        dispatch(
          searchV3.actions.init({
            columnOrder: (tableSettings.columnOrder as string[]) ?? [],
            pageSize: tableSettings.pageSize,
            sortBy: tableSettings.sortBy,
          }),
        );
      }
    }
  }, [savedSearch, searchFilters]);

  const query = useMemo(() => {
    if (queryBuilder && searchFilters) return queryBuilder;
    if (activeFilters?.length || searchFilters) {
      return buildQuery({
        booleanQuery: searchText ?? '',
        filters: activeFilters,
        searchFilters,
      });
    } else {
      return [];
    }
  }, [activeFilters, searchFilters, searchText, queryBuilder]);

  const {
    data: searchV3Data,
    isFetching: isFetchingSearchResults,
    isError: isErrorSearchDocuments,
    refetch: refetchSearchV3Documents,
  } = api.endpoints.searchV3Documents.useQuery(
    {
      query,
      page,
      pageSize,
      sortBy,
      filters: columnOrder?.filter((column) => column !== 'name'),
      store_as_recent_search: false,
    },
    { skip: !shouldPerformSearch },
  );

  const handleSaveAs = async (
    data: { name: string } & EntityVisibilityType,
  ) => {
    try {
      const activeFilters = getActiveFilterIds(query);
      const response = await postSavedSearch({
        name: data.name,
        visibility: data.visibility,
        userIds: data.userIds,
        departmentIds: data.departmentIds,
        query: query,
        filters: selectedFilters
          .filter((filter) => !activeFilters.includes(filter.fieldId as string))
          .map((filter) => filter.fieldId),
        resultTable: columnOrder
          ?.filter((column) => column !== 'name')
          .map((column, index) => ({
            filterId: column,
            columnIndex: index,
          })),
      }).unwrap();
      if (response) {
        toast({
          status: 'success',
          message: `Search **${response.name}** has been saved.`,
        });
        navigate(`/search/${response.id}`);
      }
    } catch (error: any) {
      toast({
        status: 'danger',
        message: error.message,
      });
    }
  };

  const handleSave = async () => {
    if (savedSearch) {
      try {
        const activeFilters = getActiveFilterIds(query);
        const response = await putSavedSearch({
          id: savedSearch.id,
          savedSearch: {
            name: savedSearch.name,
            visibility: savedSearch.visibility,
            userIds: savedSearch.userIds,
            departmentIds: savedSearch.departmentIds,
            query: query,
            filters: selectedFilters
              .filter((filter) => !activeFilters.includes(filter.fieldId))
              .map((filter) => filter.fieldId),
            resultTable: columnOrder
              ?.filter((column) => column !== 'name')
              .map((column, index) => ({
                filterId: column,
                columnIndex: index,
              })),
          },
        }).unwrap();
        if (response) {
          toast({
            status: 'success',
            message: `Search **${response.name}** has been saved.`,
          });
        }
      } catch (error: any) {
        toast({
          status: 'danger',
          message: error.message,
        });
      }
    }
  };

  const {
    tableSettings = {
      ...DEFAULT_TABLE_SETTINGS,
      columnOrder: (savedSearch?.resultTable ?? []).map((column) =>
        String(column.filterId),
      ),
    },
    updateTableSettings,
  } = useTableSettings(TableContextType.SearchV3);

  const pinnedFiltersIds = useMemo(() => {
    return userPreferencesDefaultFilters?.map((filter) =>
      isNaN(Number(filter.fieldId)) ? filter.fieldId : Number(filter.fieldId),
    );
  }, [userPreferencesDefaultFilters]);

  const refreshSearchResult = useCallback(() => {
    resetSearchResultSelection();
    refetchSearchV3Documents();
  }, []);

  const resetSearchResultSelection = () => {
    tableSelection.clear();
  };

  const tableSelection = useTableSelection();
  const {
    panel,
    limitExceededModal,
    setActivePanel,
  } = useDocumentsBulkActionsPanel({
    selectedDocumentIds: tableSelection?.selectedIds,
    isAllSelected: tableSelection?.isAllSelected,
    totalCount: searchV3Data?.meta?.total ?? 0,
    query: query,
    searchAPI: 'v3',
    onActionCompleted: refreshSearchResult,
    resultsCache: resultsCache,
  });

  const onSearch = (search: string) => {
    dispatch(searchV3.actions.setSearchText(search));
    dispatch(searchV3.actions.setPage(1));
    if (search) {
      trackSegment('searchKeywords', {
        name: search,
      });
    }
  };

  const onSetColumnOrder = useCallback(
    (updatedColumnOrder: Array<string>) => {
      dispatch(searchV3.actions.setColumnOrder(updatedColumnOrder));
      updateTableSettings({
        ...tableSettings,
        columnOrder: updatedColumnOrder,
      });
      if (!savedSearch) {
        updateTableView({
          context: TableViewContextType.SEARCH,
          fields: getSearchableFields(updatedColumnOrder),
        });
      }

      const queryFields = updatedColumnOrder
        .map((columnId) => (searchFilters ?? {})[columnId])
        .filter((field) => field);
      dispatch(searchV3.actions.setQueryFields(queryFields));
    },
    [searchFilters, tableSettings, savedSearch],
  );

  const onPaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      if (pageIndex !== page) {
        dispatch(searchV3.actions.setPage(pageIndex));
      }
    },
    [page],
  );

  const onPageSizeChange = useCallback((pageSize: number) => {
    dispatch(searchV3.actions.setPageSize(pageSize));
  }, []);

  const handleUpdate = (state: any, action?: ActionType) => {
    tableSelection.onTableUpdate(state, action);
    if (action?.type === 'columnDoneResizing') {
      dispatch(
        searchV3.actions.patchColumnWidths(state.columnResizing.columnWidths),
      );
    }
    if (action?.type === 'toggleSortBy') {
      dispatch(searchV3.actions.setSortBy(state.sortBy[0]));
    }
  };
  const handleUpdatePinnedFilters = async (
    updatedPinnedFilters: Array<PilotId | string>,
  ) => {
    setLocalPinnedFilterIds(updatedPinnedFilters);
    await updatePinnedFilters({
      context: 'SEARCH',
      pinnedFilters: updatedPinnedFilters as string[],
    });
    getPinnedFilters('SEARCH');
  };

  const openQueryBuilderPanel = () => {
    dispatch(searchV3.actions.setQueryBuilderPanelVisible(true));
  };
  const hideQueryBuilderPanel = () => {
    dispatch(searchV3.actions.setQueryBuilderPanelVisible(false));
  };

  const onFiltersReset = () => {
    if (searchFilters) {
      dispatch(
        searchV3.actions.initSavedSearchData({
          searchFilters,
          pageSize: tableSettings.pageSize,
          sortBy: tableSettings.sortBy,
        }),
      );
      dispatch(searchV3.actions.setPage(1));
    }
  };

  const handleSearchQueryBuilder = (
    groupsEntity: (QueryGroup | QueryOperatorEntity)[],
  ) => {
    const queryBuilderPayload = buildQuery({
      booleanQuery: '',
      filters: [],
      searchFilters,
      queryBuilder: groupsEntity,
    });
    dispatch(searchV3.actions.setQueryBuilder(queryBuilderPayload));
    dispatch(searchV3.actions.setPage(1));
    dispatch(searchV3.actions.setQueryBuilderPanelVisible(false));
    dispatch(searchV3.actions.setSearchText(''));
  };

  const handleAddPinFilter = (fieldId: PilotId | string) => {
    dispatch(
      searchV3.actions.setSelectedFilters([
        ...selectedFilters,
        toFilter(fieldId as string),
      ]),
    );
  };

  // Viewset
  const showSaveView = useCallback(() => {
    setIsSaveViewVisible(true);
  }, []);

  const showLoadView = useCallback(() => {
    setIsLoadViewVisible(true);
  }, []);

  // Bulk Actions
  const handleMove = useCallback(() => {
    setActivePanel('move');
  }, []);

  const handleCopy = useCallback(() => {
    setActivePanel('copy');
  }, []);

  const handleEdit = useCallback(() => {
    setActivePanel('edit');
  }, []);

  const handleEditClauses = useCallback(() => {
    setActivePanel('edit-clauses');
  }, []);

  const handleDelete = useCallback(() => {
    setActivePanel('delete');
  }, []);

  const handleSuccessAddToGroup = useCallback(() => {
    refreshSearchResult();
    setIsAddToGroupVisible(false);
  }, []);

  useEffect(() => {
    if (hasNewSearchUI && !searchId) {
      getPinnedFilters('SEARCH')
        .unwrap()
        .then((response) => {
          if (!response) {
            return;
          }
          const { data } = response;
          const pinnedFilters = data?.map((fieldId) => ({
            id: uuid.v4(),
            fieldId: (Number(fieldId) || fieldId) as FieldId,
            operatorId: null,
            values: [],
          }));
          if (selectedFilters.length === 0) {
            dispatch(searchV3.actions.setSelectedFilters(pinnedFilters ?? []));
          }
        });
    } else {
      getPinnedFilters('SEARCH');
    }

    dispatch(
      chatbotSlice.actions.setContext([
        { ...DEFAULT_CHAT_CONTEXT, primary: true },
      ]),
    );
  }, []);

  useEffect(() => {
    if (pinnedFiltersIds && pinnedFiltersIds.length) {
      setLocalPinnedFilterIds(pinnedFiltersIds ?? []);
    }
  }, [pinnedFiltersIds]);

  useEffect(() => {
    if (!!isErrorSearchDocuments) {
      toast({
        status: 'danger',
        message: 'An error occurred while searching for documents.',
      });
    }
  }, [isErrorSearchDocuments]);

  useEffect(() => {
    if (searchV3Data?.results) {
      dispatch(searchV3.actions.patchSearchResultsCache(searchV3Data.results));
      if (searchV3Data.results.length > 0) {
        dispatch(
          chatbotSlice.actions.setContext([
            DEFAULT_CHAT_CONTEXT,
            {
              label: 'All Results',
              query,
              title: `All Results (${searchV3Data.meta.total})`,
              icon: 'search',
              primary: true,
              searchEntity: 'document',
              selectedIds: [],
              id: DEFAULT_SEARCH_ID,
            },
          ]),
        );
      }
    }
  }, [searchV3Data]);

  // Fetch Columns
  useEffect(() => {
    if (!searchId) {
      fetchTableView({ context: TableViewContextType.SEARCH });
    } else if (savedSearch) {
      const savedSearchColumns = savedSearch.resultTable.map((column) =>
        String(column.filterId),
      );
      onSetColumnOrder(savedSearchColumns);
    }
  }, [searchId, savedSearch]);

  const isQueryBuilderSupported = useMemo(() => {
    if (!queryBuilder || savedSearch?.isComplexQuery === false) return true;
    return (
      !testHasMixedOperatorInSection(queryBuilder) &&
      !testHasNestedSections(queryBuilder) &&
      queryBuilder?.every(
        (queryEntity) =>
          testIsSection(queryEntity) || testIsOperator(queryEntity),
      )
    );
  }, [savedSearch, queryBuilder]);

  const isLoading =
    isFetchingFilters ||
    isFetchingSearchResults ||
    isFetchingCurrentTableViewSet ||
    isFetchingPinnedFilters;

  const onFilterChange = (filters: Filter[]) => {
    dispatch(searchV3.actions.setSelectedFilters(filters));
    dispatch(searchV3.actions.setPage(1));
  };

  const loadingContent = {
    isLoading: isLoadingSavedSearch,
  };

  const getTitle = () => {
    if (!searchId) return 'Search';
    return savedSearch ? savedSearch.name : '';
  };

  const placeholderContent = savedSearchError
    ? {
        title: 'No Access to Page',
        message:
          'The saved search does not exist or you do not have permission to access this saved search. To request permission, contact the user who created the saved search.',
        image: 'error-page-access',
        action: {
          text: 'Take me Home',
          level: 'primary' as const,
          onClick: () => navigate(RoutePathType.Home),
        },
      }
    : undefined;

  const searchFiltersList = useFieldsWithLazyLoad({
    filters: Object.values(searchFilters ?? {}),
    fieldValues: {},
  });

  return (
    <>
      <PageLayout
        loadingContent={loadingContent}
        placeholderContent={placeholderContent}
        title={getTitle()}
      >
        <Layout align="flex-start" w="100%">
          <Layout direction="column" flex={1} mr={2}>
            <SearchV3BooleanSearchBar
              value={searchText ?? ''}
              onSubmit={onSearch}
              isSubmitting={isLoading}
            />
          </Layout>
          <Box>
            <SaveActionButton
              entity="Search"
              saveAsNewAction={{
                disabled: !searchId && !isDirty,
                onClick: () => setIsSaveAsVisible(true),
              }}
              overwriteAction={
                searchId
                  ? {
                      onClick: handleSave,
                      tooltip: hasSavePermission
                        ? ''
                        : 'You do not have privileges to save this search.',
                      disabled: isSaveDisabled,
                    }
                  : undefined
              }
            />
          </Box>
        </Layout>
        <Box mt={4}>
          {queryBuilder ? (
            <>
              {!isFetchingFilters && (
                <ReadOnlyComplexQuery
                  queryBuilder={queryBuilder}
                  isQueryBuilderSupported={isQueryBuilderSupported}
                  searchFilters={searchFilters || {}}
                  onClear={() => {
                    dispatch(searchV3.actions.setQueryBuilder(undefined));
                    dispatch(
                      searchV3.actions.setQueryBuilderPanelVisible(false),
                    );
                  }}
                  onEdit={() => {
                    dispatch(
                      searchV3.actions.setQueryBuilderPanelVisible(true),
                    );
                  }}
                />
              )}
            </>
          ) : (
            <Layout direction="column" spacing={4}>
              {hasNewSearchUI ? (
                <>
                  {isFetchingFilters ? (
                    <LoadingShimmer height="filter.height" />
                  ) : (
                    <Filters
                      searchFilters={searchFiltersList}
                      selectedFilters={selectedFilters}
                      pinnedFiltersIds={localPinnedFilterIds}
                      onChange={onFilterChange}
                      onFiltersReset={savedSearch ? onFiltersReset : undefined}
                      onQueryButtonClick={openQueryBuilderPanel}
                      onUpdatePinnedFilters={handleUpdatePinnedFilters}
                    />
                  )}
                </>
              ) : (
                <SearchFilters
                  enableFilterViews={hasTextDelimitedFilterView}
                  pinnedFiltersIds={localPinnedFilterIds}
                  isLoading={isFetchingFilters}
                  filterCount={activeFilters.length}
                  filters={selectedFilters}
                  onFilterChange={onFilterChange}
                  onQueryButtonClick={openQueryBuilderPanel}
                  onUpdatePinnedFilters={handleUpdatePinnedFilters}
                  onClickPinnedFilter={handleAddPinFilter}
                />
              )}
            </Layout>
          )}
        </Box>

        <Box mt={4}>
          {shouldPerformSearch || isFetchingSearchResults ? (
            <SearchV3Results
              currentTableView={currentTableView}
              state={{
                page,
                pageSize,
                sortBy,
                columnOrder,
                columnWidths,
                featuredClauses,
              }}
              searchFilters={searchFilters || {}}
              isLoading={isFetchingSearchResults}
              data={searchV3Data}
              query={query}
              onSetColumnOrder={onSetColumnOrder}
              onPaginate={onPaginate}
              onPageSizeChange={onPageSizeChange}
              onMove={handleMove}
              onCopy={handleCopy}
              onEdit={handleEdit}
              onEditClauses={handleEditClauses}
              onDelete={handleDelete}
              onAddToGroup={() => setIsAddToGroupVisible(true)}
              resultsCache={resultsCache}
              tableSelection={tableSelection}
              handleUpdate={handleUpdate}
              onSaveView={showSaveView}
              onLoadView={showLoadView}
            />
          ) : (
            <EmptyPage preset="no-search" />
          )}
        </Box>
      </PageLayout>
      {!!searchFilters &&
        isQueryBuilderSupported &&
        queryBuilderPanelVisible && (
          <>
            {hasQueryBuilderEnhancedUx ? (
              <QueryBuilderEnhancedUx
                pinnedFiltersIds={localPinnedFilterIds}
                value={queryBuilder}
                searchFilters={searchFiltersList}
                onSubmit={handleSearchQueryBuilder}
                onHide={hideQueryBuilderPanel}
              />
            ) : (
              <QueryBuilder
                searchFilters={searchFilters}
                value={queryBuilder}
                visible={queryBuilderPanelVisible}
                onSearch={handleSearchQueryBuilder}
                onHide={hideQueryBuilderPanel}
              />
            )}
          </>
        )}
      {limitExceededModal}
      {panel}
      <SaveEditEntityModal
        placeholder="Saved Search Name"
        onSubmit={handleSaveAs}
        onHide={() => setIsSaveAsVisible(false)}
        isVisible={isSaveAsVisible}
        title="Save as New Search"
      />
      {isAddToGroupVisible && (
        <AddSearchResultsToGroup
          query={query}
          tableSelection={tableSelection}
          onHide={() => setIsAddToGroupVisible(false)}
          onCompleted={handleSuccessAddToGroup}
        />
      )}

      {isSaveViewVisible && (
        <BaseSaveView
          context={TableViewContextType.SEARCH}
          fields={getSearchableFields(columnOrder)}
          hideModal={() => setIsSaveViewVisible(false)}
          modalType={MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE}
        />
      )}

      {isLoadViewVisible && (
        <BaseLoadView
          context={TableViewContextType.SEARCH}
          modalType={MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH}
          hideModal={() => setIsLoadViewVisible(false)}
          onViewSelected={() =>
            fetchTableView({ context: TableViewContextType.SEARCH })
          }
        />
      )}
    </>
  );
};
