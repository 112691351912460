import adobesignLogoSrc from '~/assets/images/logo-adobesign.svg';
import amazonS3LogoSrc from '~/assets/images/logo-amazon-s3.svg';
import aribaLogoSrc from '~/assets/images/logo-ariba.svg';
import boxLogoSrc from '~/assets/images/logo-box.svg';
import docusignLogoSrc from '~/assets/images/logo-docusign.svg';
import dropboxLogoSrc from '~/assets/images/logo-dropbox.svg';
import googleDriveLogoSrc from '~/assets/images/logo-google-drive.svg';
import oneDriveLogoSrc from '~/assets/images/logo-one-drive.svg';
import salesforceLogoSrc from '~/assets/images/logo-salesforce.svg';
import sharepointLogoSrc from '~/assets/images/logo-sharepoint.svg';
import {
  CrmType,
  EsignatureType,
  ProcurementIntegrationType,
  StorageProviderType,
} from '~/enums';
import evisortLogoSrc from '~/ui/assets/images/logo-evisort.svg';

export default {
  evisort: evisortLogoSrc,
  [ProcurementIntegrationType.Ariba]: aribaLogoSrc,
  [CrmType.Salesforce]: salesforceLogoSrc,
  [EsignatureType.Adobesign]: adobesignLogoSrc,
  [EsignatureType.Docusign]: docusignLogoSrc,
  [StorageProviderType.AmazonS3]: amazonS3LogoSrc,
  [StorageProviderType.Box]: boxLogoSrc,
  [StorageProviderType.Dropbox]: dropboxLogoSrc,
  [StorageProviderType.GoogleDrive]: googleDriveLogoSrc,
  [StorageProviderType.OneDrive]: oneDriveLogoSrc,
  [StorageProviderType.SharePoint]: sharepointLogoSrc,
};
