import React from 'react';

import { esignatureIntegrations } from '~/constants/integrations';
import { useSearchParams } from '~/hooks';

import IntegrationCardList from './IntegrationCardList';

function EsignatureIntegrationCardList() {
  const [_, setTabValue] = useSearchParams('tab'); // eslint-disable-line no-unused-vars

  const integrationCards = Object.values(esignatureIntegrations).map(
    (integration) => ({
      ...integration,
      enableStatusDetails: false,
      action: {
        // TODO: we will need to integrate the E-Signature to card design but
        //       for now we just need it functional and show the 3rd party tab
        onClick: () => setTabValue('third-party'),
        text: 'See integration',
      },
    }),
  );

  return <IntegrationCardList integrationCards={integrationCards} />;
}

export default EsignatureIntegrationCardList;
