import PropTypes from 'prop-types';
import React from 'react';

import { evisortBlue } from '../../../assets/shared-styles/general';

const TimelapseIcon = (props) => {
  let { color } = props;

  if (props.blue) {
    color = evisortBlue;
  }

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={color}
          fillRule="nonzero"
          d="M16.24 7.76A5.974 5.974 0 0 0 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0a5.99 5.99 0 0 0-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        />
      </g>
    </svg>
  );
};

TimelapseIcon.defaultProps = {
  size: '24',
  color: '#000',
};

TimelapseIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  blue: PropTypes.bool,
};

export default TimelapseIcon;
