import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  EntityVisibilitySelect,
  validateShareField,
} from '~/components/Shared/EntityVisibilitySelect';
import {
  Form,
  formatDate,
  Icon,
  Layout,
  requiredValidator,
  Text,
  TextArea,
  TextInput,
  types,
  useModal,
  User,
  useToast,
} from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { useResolveUsers } from '~/hooks';
import { api } from '~/redux';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { Nullable, QuestionGroup, Uuid } from '~/types';

import { SideContentTabs } from '../useChatBotSideContent';
import { QuestionSelect } from './QuestionSelect';

interface EditQuestionFormProps {
  questionGroup: Nullable<QuestionGroup>;
  onClose?: () => void;
}

const getFields = (isShareQuestionGroupEnabled: boolean, groupId?: Uuid) => ({
  name: {
    order: 1,
    key: 'name',
    type: 'field' as const,
    name: 'name',
    label: 'Name',
    input: TextInput,
    placeholder: 'e.g. Contract Law Compliance',
    required: true,
  },
  description: {
    order: 2,
    key: 'description',
    type: 'field' as const,
    name: 'description',
    label: 'Description',
    optional: true,
    input: TextArea,
  },
  ...(isShareQuestionGroupEnabled
    ? {
        shareField: {
          order: 3,
          key: 'shareField',
          type: 'field' as const,
          name: 'shareField',
          label: 'Visibility',
          description:
            'If visibility is set to anyone, changes to the question group will impact all users and groups.',
          input: EntityVisibilitySelect,
          inputProps: {
            enablePortal: false,
          },
        },
      }
    : {}),
  questions: {
    order: 4,
    key: 'questions',
    type: 'field' as const,
    name: 'questions',
    label: 'Questions',
    description:
      'Search and select the question(s) to add to the question group. You can add a maximum number of N items in the question group',
    input: QuestionSelect,
    inputProps: {
      groupId,
    },
    placeholder: 'Search and select',
    required: true,
  },
});

type QuestionGroupDraft = Omit<
  QuestionGroup,
  'id' | 'createdBy' | 'updatedBy' | 'createdAt' | 'updatedAt' | 'pinnedAt'
>;

type QuestionGroupForm = {
  name: string;
  description: string;
  questions: string[];
  shareField: {
    visibility: 'OPEN' | 'PRIVATE' | 'SHARED';
    userIds: number[];
    departmentIds: number[];
  };
};

const getQuestionGroup = (questionGroup: Nullable<QuestionGroup>) => {
  return questionGroup
    ? questionGroup
    : {
        name: '',
        description: '',
        questionIds: [],
        visibility: 'PRIVATE' as const,
        userIds: [],
        departmentIds: [],
      };
};

const getUserIds = (questionGroup: Nullable<QuestionGroup>) => {
  return questionGroup
    ? [questionGroup.createdBy, questionGroup.updatedBy]
    : [];
};

export const useQuestionGroupFormModal = ({
  questionGroup,
  onClose,
}: EditQuestionFormProps) => {
  const isCreatingQuestionGroup = !questionGroup;
  const isShareQuestionGroupEnabled = useFlag(FlagType.ShareQuestionGroup);

  const handleClose = () => {
    setQuestionGroupDraft(getQuestionGroup(null));
    onClose?.();
  };

  useEffect(() => {
    setQuestionGroupDraft(getQuestionGroup(questionGroup));
  }, [questionGroup]);

  const [
    questionGroupDraft,
    setQuestionGroupDraft,
  ] = useState<QuestionGroupDraft>(getQuestionGroup(questionGroup));

  const [hasErrors, setHasErrors] = useState(false);
  const { users } = useResolveUsers({
    userIds: getUserIds(questionGroup),
  });

  const { toast } = useToast();

  const [
    updateQuestionGroup,
    { isLoading: isUpdating },
  ] = api.endpoints.updateQuestionGroup.useMutation();

  const [
    createQuestionGroup,
    { isLoading: isCreating },
  ] = api.endpoints.createQuestionGroup.useMutation();

  const questionInitialState = {
    name: { value: questionGroupDraft.name },
    description: { value: questionGroupDraft.description },
    questions: { value: questionGroupDraft.questionIds },
    shareField: {
      value: {
        visibility: questionGroupDraft.visibility,
        userIds: questionGroupDraft.userIds,
        departmentIds: questionGroupDraft.departmentIds,
      },
    },
  };

  const dispatch = useDispatch();

  const openSideContent = (tab?: SideContentTabs) => {
    dispatch(chatbotSlice.actions.openSideContent(tab));
  };

  const handleOnSubmit = async () => {
    const operation = isCreatingQuestionGroup
      ? createQuestionGroup
      : updateQuestionGroup;
    try {
      await operation({
        id: questionGroup?.id ?? '',
        name: questionGroupDraft.name,
        description: questionGroupDraft.description,
        questionIds: questionGroupDraft.questionIds,
        visibility: questionGroupDraft.visibility,
        userIds: questionGroupDraft.userIds,
        departmentIds: questionGroupDraft.departmentIds,
      }).unwrap();
      toast({
        message: `The question group has been successfully ${
          isCreatingQuestionGroup ? 'created' : 'updated'
        }.`,
        status: 'success',
        action: {
          text: 'View',
          level: 'action',
          onClick: () => {
            openSideContent('questionGroups');
          },
        },
      });
      isCreatingQuestionGroup && close();
    } catch (apiError: any) {
      const message = `The question group ${
        isCreatingQuestionGroup ? 'creation' : 'update'
      } failed due to ${apiError.errors[0].title}. ${
        apiError.errors[0].detail
      }`;

      toast({
        status: 'danger',
        message,
      });
    }
  };

  const handleOnChange = ({
    state,
    errors,
  }: types.Form.StateUpdater<QuestionGroupForm>) => {
    setHasErrors(Object.values(errors || {}).some((error) => error.length > 0));
    setQuestionGroupDraft({
      name: state.name.value,
      description: state.description.value,
      questionIds: state.questions.value,
      visibility: state.shareField.value.visibility,
      userIds: state.shareField.value.userIds,
      departmentIds: state.shareField.value.departmentIds,
    });
  };

  const handleOnValidate = ({
    errors,
  }: types.Form.StateUpdater<QuestionGroupForm>) => {
    setHasErrors(Object.values(errors || {}).some((error) => error.length > 0));
  };

  const groupId = questionGroup?.id;

  const renderForm = () => {
    return (
      <Layout spacing={4} direction="column">
        <Text>
          Input a name and description for the question group and select
          questions you would like added to the Question Group.
        </Text>
        <Form<QuestionGroupForm>
          fields={getFields(isShareQuestionGroupEnabled, groupId)}
          onChange={handleOnChange}
          onValidate={handleOnValidate}
          state={questionInitialState}
          validators={[
            requiredValidator(['name', 'questions']),
            ...(isShareQuestionGroupEnabled ? [validateShareField] : []),
          ]}
        />
        {questionGroup && (
          <Layout flex={1} my={4} spacing={4}>
            <Layout direction="column" flex={1}>
              <Text variant="tiny">Created</Text>
              <Layout spacing={1} direction="column">
                <Layout spacing={2}>
                  <Icon icon="field-date" size="s" />
                  <Text>{formatDate(questionGroup.createdAt, 'long')}</Text>
                </Layout>
                <Layout spacing={2}>
                  <Icon icon="user" size="s" />
                  <User user={users?.[questionGroup.createdBy]} mode="name" />
                </Layout>
              </Layout>
            </Layout>
            <Layout direction="column" flex={1}>
              <Text variant="tiny">Modified</Text>
              <Layout spacing={1} direction="column">
                <Layout spacing={2}>
                  <Icon icon="field-date" size="s" />
                  <Text>{formatDate(questionGroup.updatedAt, 'long')}</Text>
                </Layout>
                <Layout spacing={2}>
                  <Icon icon="user" size="s" />
                  <User user={users?.[questionGroup.createdBy]} mode="name" />
                </Layout>
              </Layout>
            </Layout>
          </Layout>
        )}
      </Layout>
    );
  };

  const [modal, open, close] = useModal({
    title: isCreatingQuestionGroup ? 'Create a Question Group' : 'Edit',
    children: renderForm(),
    onHide: handleClose,
    primaryAction: {
      text: isCreatingQuestionGroup ? 'Create' : 'Save',
      onClick: handleOnSubmit,
      isLoading: isCreating || isUpdating,
      disabled: hasErrors,
      tooltip: hasErrors ? 'Please fill in all required fields' : undefined,
    },
  });

  return [modal, open, close] as const;
};
