import { atlas } from '~/services';

import { QuestionResponsePayload } from './types';
import { mapQuestionResponse } from './utils';

export const getQuestion = async ({ id }: { id: string }) => {
  const response: QuestionResponsePayload = await atlas.get(`questions/${id}`);

  return mapQuestionResponse(response.data);
};
