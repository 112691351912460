import { black2, bold } from '../../../assets/shared-styles/general';

const border = 'solid 1px rgba(0, 0, 0, 0.2)';

const ecCardTitleWrapper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderLeft: border,
  borderRight: border,
};

const styles = {
  collaspedEcCardWrapper: {
    maxHeight: '65px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderTop: border,
    borderBottom: border,
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  ecCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderTop: border,
    borderRadius: '4px',
    backgroundColor: '#fff',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  ecCardTitleWrapper: {
    ...ecCardTitleWrapper,
  },
  ecCardTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px',
    color: black2,
    fontSize: '15px',
    fontWeight: bold,
  },
  expandCollapseBtn: {
    padding: 0,
    marginRight: '10px',
  },
  ecCardTitleRightComponent: {
    padding: '12px 16px',
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '15px',
  },
  ecCardContent: {
    flexGrow: '1',
    border,
    borderTop: 0,
    borderRadius: '0 0 4px 4px',
  },
};

export default styles;
