import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Box, Button, Layout, Popover, TruncateText, useToggle } from '~/eds';
import { api, coerceRtkqError } from '~/redux';
import { getApplicationRoute, matchPath, RoutePathType } from '~/routing';

import { Citation as CitationType } from '../../../types';
import { CitationDetails } from './CitationDetails';

interface Props {
  mode?: 'inline';
  citation: CitationType;
  text: string;
  onClick: (citation: CitationType) => void;
}

export const Citation = ({ citation, mode, text, onClick }: Props) => {
  const [isHovered, _toggleHover, hover, unhover] = useToggle(false);
  const [
    resolveCitation,
    { requestId, error, isLoading },
  ] = api.endpoints.resolveCitation.useLazyQuery();
  const citationEntity = getCitationEntity(citation);

  const selector = useMemo(
    () => api.endpoints.resolveCitation.select(citationEntity),
    [requestId],
  );
  const handleClick = () => {
    onClick(citation);
  };
  const isInline = mode === 'inline';
  const hasDescription = Boolean(citation.entityName) && !isInline;

  const cachedData = useSelector(selector);
  const resolvedCitation = cachedData.data;

  const onPopoverShow = () => {
    hover();
    if (!resolvedCitation) {
      resolveCitation(getCitationEntity(citation));
    }
  };

  const citationBtn = (
    <Popover
      preset="popover-hover"
      placement="bottom-start"
      onShow={onPopoverShow}
      onHide={unhover}
      offset={[0, 5]}
      maxWidth={500}
      trigger={
        <Button
          variant={isHovered ? 'secondary-active' : undefined}
          text={text}
          onClick={handleClick}
          size="xs"
        />
      }
    >
      <CitationDetails
        text={citation.tooltip ?? ''}
        isLoading={isLoading}
        resolvedCitation={resolvedCitation}
        error={coerceRtkqError(error)}
      />
    </Popover>
  );

  return isInline ? (
    <Box display="inline-block">{citationBtn}</Box>
  ) : (
    <Layout w="100%" spacing={2} align="center">
      {citationBtn}
      <Layout w="100%" direction="column">
        {hasDescription && (
          <TruncateText w="90%" variant="tiny">
            {citation.entityName}
          </TruncateText>
        )}
      </Layout>
    </Layout>
  );
};

const getCitationEntity = (citation: CitationType): CitationType['entity'] => {
  switch (citation.entity.type) {
    case 'ticket':
      return citation.entity;
    default:
    case 'document_id':
      const matchedPath = matchPath(
        RoutePathType.Document,
        getApplicationRoute(citation.link ?? ''),
      );
      return {
        type: 'document_handler',
        id: `${matchedPath?.params.documentId}`,
      };
  }
};
