import React, { useState } from 'react';

import { FlexLayout } from '~/ui';
import workflowAddConditionalTextStep1 from '~/ui/assets/images/workflow-add-conditional-text-step-1.svg';
import workflowAddConditionalTextStep2 from '~/ui/assets/images/workflow-add-conditional-text-step-2.svg';
import workflowAddConditionalTextStep3 from '~/ui/assets/images/workflow-add-conditional-text-step-3.svg';
import workflowAddConditionalText from '~/ui/assets/images/workflow-add-conditional-text.svg';
import workflowAddDocumentFieldsStep1 from '~/ui/assets/images/workflow-add-document-fields-step-1.svg';
import workflowAddDocumentFieldsStep2 from '~/ui/assets/images/workflow-add-document-fields-step-2.svg';
import workflowAddDocumentFieldsStep3 from '~/ui/assets/images/workflow-add-document-fields-step-3.svg';
import workflowAddDocumentFields from '~/ui/assets/images/workflow-add-document-fields.svg';

import FieldCard from './FieldCard';
import FieldCardDetail from './FieldCardDetail';

const steps = {
  DEFAULT: 'DEFAULT',
  DOCUMENT_FIELDS: 'DOCUMENT_FIELDS',
  CONDITIONAL_TEXT: 'DOCUMENT_CONDITIONAL_TEXTFIELDS',
};

function TemplateSteps() {
  const [step, setStep] = useState(steps.DEFAULT);

  function backToDefault() {
    setStep(steps.DEFAULT);
  }

  switch (step) {
    case steps.DOCUMENT_FIELDS:
      return (
        <FieldCardDetail
          bg="#DBF4F0"
          title="Adding Document Fields"
          steps={[
            {
              title: 'Prepare document',
              description:
                'Mark each variable directly in the document with {{}}.',
              imgSrc: workflowAddDocumentFieldsStep1,
            },
            {
              title: 'Upload document',
              description:
                'Evisort saves the position and name for each marked variable.',
              imgSrc: workflowAddDocumentFieldsStep2,
            },
            {
              title: 'Set up fields',
              description:
                'Specify the type of information expected and its structure.',
              imgSrc: workflowAddDocumentFieldsStep3,
            },
          ]}
          onClose={backToDefault}
        />
      );
    case steps.CONDITIONAL_TEXT:
      return (
        <FieldCardDetail
          bg="#D7CDFF"
          title="Adding Conditional Text"
          steps={[
            {
              title: 'Prepare document',
              description:
                'Mark each block of conditional text with {{name}}.[[conditional content]].',
              imgSrc: workflowAddConditionalTextStep1,
            },
            {
              title: 'Upload document',
              description:
                'Evisort saves the position and name of each marked block of text.',
              imgSrc: workflowAddConditionalTextStep2,
            },
            {
              title: 'Set up conditional text',
              description:
                'Specify the rules for when the conditional content is included in the generated document.',
              imgSrc: workflowAddConditionalTextStep3,
            },
          ]}
          onClose={backToDefault}
        />
      );
    case steps.DEFAULT:
    default:
      return (
        <FlexLayout space={6}>
          <FieldCard
            description="Designate placeholders in the document for the insertion of custom text or variable values based on information provided by the submitter in the intake form. You’ll choose which intake form answer to tie the placeholders to in a later step."
            id="workflow--adding-document-fields-image"
            imgSrc={workflowAddDocumentFields}
            title="Adding Document Fields"
            onClick={() => setStep(steps.DOCUMENT_FIELDS)}
          />
          <FieldCard
            description="Designate areas of the uploaded document such as clauses and exhibits that may be but will not always be included in the generated document. You will specify the logic for when the text should be included at a later step."
            id="workflow--adding-condition-text-image"
            imgSrc={workflowAddConditionalText}
            title="Adding Conditional Text"
            onClick={() => setStep(steps.CONDITIONAL_TEXT)}
          />
        </FlexLayout>
      );
  }
}

export default TemplateSteps;
