import { Party } from '~/features/manage-parties';
import { pilot } from '~/services';
import { Nullable, PilotId } from '~/types';

interface Request {
  clientId: PilotId;
  isInternal: boolean;
  page?: number;
}

interface Response {
  count: number;
  next: string;
  previous: Nullable<string>;
  results?: Party[];
}

export const getParties = async ({
  clientId,
  isInternal,
  page,
}: Request): Promise<{ count: number; results: Party[] }> => {
  const params = { page, internal: isInternal };
  const response = (await pilot.get(`client/${clientId}/party-lists/`, {
    params,
  })) as Response;

  return {
    results:
      response.results?.map((party) => ({
        id: party.id,
        name: party.name,
        isInternal,
      })) ?? [],
    count: response.count,
  };
};
