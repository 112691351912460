import { noop } from 'lodash';
import React from 'react';
import { useStyles } from 'uinix-ui';

import { useToggle } from '../../hooks';
import { Input, Option } from '../../types';
import { stopPropagation } from '../../utils';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { MarkedText } from '../MarkedText';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

interface Props extends Input<boolean> {
  /** Radio options */
  option: Option;
  /** Boolean to determine if radio is checked */
  checked: boolean;
  /** Supported label modes */
  labelMode?: 'hidden';
  /** Label typography variant */
  labelVariant?: 'body' | 'body-medium';
  /** Used to highlight radio label if it matches search */
  search?: string;
}

export const Radio = ({
  checked,
  disabled,
  labelMode,
  labelVariant,
  name,
  option,
  search,
  onChange,
}: Props) => {
  const styles = useStyles();

  const [focused, _toggle, focus, blur] = useToggle();

  const { info, label, value, tooltip } = option;
  const radioValue = String(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.checked);

  const labelText = (
    <Text
      styles={labelMode === 'hidden' ? styles.hidden.visual : null}
      variant={labelVariant}
    >
      <MarkedText search={search} text={label} />
    </Text>
  );

  return (
    <Tooltip tooltip={tooltip}>
      <Layout
        data-eds-styles-disable-interactable
        as="label"
        disabled={disabled}
        spacing={1}
        styles={componentStyles.base}
        onClick={stopPropagation}
      >
        <input
          checked={checked}
          disabled={disabled}
          name={name}
          type="radio"
          value={radioValue}
          onBlur={blur}
          onChange={handleChange}
          onFocus={focus}
        />
        <Box
          styles={
            focused
              ? {
                  ...styles.interactions[':focus'],
                  ...styles.focus.ring,
                }
              : null
          }
          onClick={noop}
        >
          <Icon icon={checked ? 'radio-checked' : 'radio-empty'} />
        </Box>
        {labelText}
        {info && <Icon icon="info" tooltip={info} />}
      </Layout>
    </Tooltip>
  );
};

export const componentStyles = {
  base: {
    userSelect: 'none',
    '> input': {
      opacity: 0,
      position: 'absolute' as const,
      width: 0,
    },
  },
};
