import { sub } from 'date-fns';

import { Filter } from '~/evifields';

import { EntityType, InitiatorType, Operation } from './types';

const operationOptionLabels: Record<Operation, string> = {
  CREATE: 'CREATE',
  DEACTIVATE: 'DEACTIVATE',
  DELETE: 'DELETE',
  DOWNLOAD: 'DOWNLOAD',
  EDIT: 'EDIT',
  FIELD_CHANGE: 'FIELD_CHANGE',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SECURITY: 'SECURITY',
  VIEW: 'VIEW',
};
const entityTypeOptionLabels: Record<EntityType, string> = {
  DOCUMENT: 'DOCUMENT',
  FOLDER: 'FOLDER',
  USER: 'USER',
};

const initiatorTypeOptionLabels: Record<InitiatorType, string> = {
  API: 'API',
  USER: 'USER',
};

const getInitialDateValues = () => {
  const today = new Date();
  const sevenDaysAgo = sub(new Date(), { days: 7 });

  return [sevenDaysAgo, today];
};

export const MAX_AUDIT_LOGS_COUNT = 50000;

export const initialFilters: Filter[] = [
  {
    id: 'activity',
    fieldId: 'activity',
    operatorId: 'equals', //TODO: rework to 'contains_any' once BE is ready
    values: ['VIEW'],
    fieldType: 'text',
  },
  {
    id: 'entityType',
    fieldId: 'entityType',
    operatorId: 'equals',
    values: ['DOCUMENT'],
    fieldType: 'text',
  },
  {
    id: 'datetime',
    fieldId: 'datetime',
    operatorId: 'date_between',
    values: getInitialDateValues(),
    fieldType: 'date',
  },
];

export const operationOptions = Object.entries(
  operationOptionLabels,
).map(([value, label]) => ({ value, label }));

export const entityTypeOptions = Object.entries(
  entityTypeOptionLabels,
).map(([value, label]) => ({ value, label }));

export const initiatorTypeOptions = Object.entries(
  initiatorTypeOptionLabels,
).map(([value, label]) => ({ value, label }));
