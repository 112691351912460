import PT from 'prop-types';
import React from 'react';

import { Text } from '~/ui';

function FieldPlaceholder({ value }) {
  if (!value) {
    return null;
  }

  return (
    <Text color="teal-500" variant="2xs-dense">
      {`{{${value}}}`}
    </Text>
  );
}

FieldPlaceholder.propTypes = {
  value: PT.string,
};

export default FieldPlaceholder;
