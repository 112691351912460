import { addDays, endOfDay, isToday, setHours, subDays } from 'date-fns';
import uuid from 'uuid';

import { formatDate } from '~/eds';
import { Filter, OperatorId } from '~/evifields';
import { AuditLogRecord } from '~/features/audit-logs';
import { earlyAccess } from '~/services';
import { Nullable } from '~/types';
import { sortStrings } from '~/utils';

import {
  AuditlogRecordsPayload,
  SchemasAuditlogRecordsResponse,
} from '../../types/eviaudit/records';

interface Request {
  filters: Filter[];
  pageIndex: number;
  pageSize: number;
  sortBy?: {
    id: keyof AuditLogRecord;
    desc: boolean;
  };
  //** The next token to be used to retrieve the next batch of auditlog entries */
  next?: null | string;
}

type Response = SchemasAuditlogRecordsResponse;

export const getAuditLogs = async (
  request: Request,
): Promise<{
  results: AuditLogRecord[];
  totalCount: number;
}> => {
  const postData: AuditlogRecordsPayload = mapRequestToPostData(request);

  const responses: Response[] = [];
  let totalCount = 0;
  let shouldEnd = false;

  while (!shouldEnd) {
    const response: Response = await earlyAccess.post(
      '/auditlog/records',
      postData,
    );

    const { data, has_more, next } = response;
    postData.next = next;

    totalCount += data.length;
    shouldEnd = totalCount > 50000 || has_more === false;
    responses.push(response);
  }

  return prepareResponse(request, responses.flat());
};

const prepareResponse = (
  request: Request,
  responses: Response[],
): {
  results: AuditLogRecord[];
  totalCount: number;
} => {
  const response = responses.reduce(
    (acc, res) => {
      acc.data.push(...res.data);
      return acc;
    },
    { data: [] as Response['data'] },
  );

  const { pageIndex, pageSize, sortBy = '' } = request;
  const startIndex = (pageIndex - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  let results = response.data.map((result) => {
    const { operation, timestamp, details, initiator, entity } = result;

    return {
      id: uuid.v4(),
      activity: operation,
      datetime: timestamp,
      details: stringifyData(details ?? {}),
      initiatorType: initiator?.type,
      initiatorId: initiator?.id,
      initiatorName: initiator?.name,
      recordType: entity?.type,
      recordId: entity?.id,
      recordName: entity?.name,
    };
  });

  if (sortBy) {
    const { id: sortColumn, desc: isSortDesc } = sortBy;

    results = results.sort((a, b) => {
      return isSortDesc
        ? //TODO: only works if text is in lowercase, need to fix
          // use platform-recommended sortStrings util
          sortStrings(String(b[sortColumn]), String(a[sortColumn]))
        : sortStrings(String(a[sortColumn]), String(b[sortColumn]));
    });
  }

  return {
    results: results.slice(startIndex, endIndex),
    totalCount: results.length,
  };
};

const mapRequestToPostData = (request: Request): AuditlogRecordsPayload => {
  const postData = request.filters.reduce(
    (
      params,
      filter: {
        fieldId: string;
        values: any[];
        operatorId: Nullable<OperatorId>;
      },
    ) => {
      const { fieldId, values, operatorId } = filter;
      switch (fieldId) {
        case 'datetime':
          const [date1, date2] = values;
          const today = new Date();

          const minEndDate = (date: Date) => {
            return isToday(date) ? today : endOfDay(date);
          };
          let startDate;
          let endDate;
          switch (operatorId) {
            case 'date_after':
              startDate = addDays(date1, 1);
              endDate = today;
              break;
            case 'date_before':
              startDate = subDays(today, 179);
              endDate = date1;
              break;
            case 'date_on':
              startDate = setHours(date1, 0);
              endDate = minEndDate(date1);
              break;
            case 'date_between':
            default:
              startDate = date1;
              endDate = minEndDate(date2);
              break;
          }

          params.start_time = formatDate(startDate, 'utc');
          params.end_time = formatDate(endDate, 'utc');
          break;
        case 'activity':
          params.operation = values[0];
          break;
        case 'entityType':
          params.entity = values[0];
          break;
      }

      return params;
    },
    {
      operation: 'VIEW',
      entity: 'DOCUMENT',
      start_time: formatDate(new Date(), 'utc'),
      end_time: null,
      next: null,
    } as AuditlogRecordsPayload,
  );

  return postData;
};

const stringifyData = (item: object) => {
  return Object.entries(item)
    .map(([key, value]) => `${key}: ${JSON.stringify(value, null, 2)}`)
    .join(', ');
};
