import { pilot } from '../../services';

export const getNextDocument = (id = '') => pilot.get(`reviewer/next/${id}`);

export const getPreviousDocument = (id) => pilot.get(`reviewer/previous/${id}`);

export const getNextFlaggedDocument = (id = '') =>
  pilot.get(`reviewer/flagged/next/${id}`);

export const getPreviousFlaggedDocument = (id) =>
  pilot.get(`reviewer/flagged/previous/${id}`);

export const reviewDocument = (data) => pilot.post('reviewer/', data);
