import axios from 'axios';

import AuthenticationStore from '~/auth';
import { getRBACPermissions } from '~/permissions';
import { pilot } from '~/services';
import Configuration from '~/services/configuration';

export async function getCurrentUser() {
  const user = await pilot.get('user/my-profile/');
  if (user.role_uuid?.role_type) {
    const rbacPermissions = getRBACPermissions(
      user.role_uuid?.permissions || [],
    );
    return { ...user, ...rbacPermissions };
  } else {
    return user;
  }
}

export async function fetchAegisCsrfToken() {
  const AS = AuthenticationStore();
  const refreshToken = AS.getRefreshToken();

  const config = refreshToken
    ? { headers: { Authorization: `Bearer ${refreshToken}` } }
    : { withCredentials: true };

  const response = await axios.get(
    `${Configuration.authEndpoint}/v1/auth/csrf-token`,
    config,
  );
  if (response.status === 200) {
    AS.setCsrf(response.data.csrf_token);
  }
}

export async function fetchRolodexCsrfToken() {
  const AS = AuthenticationStore();

  const response = await axios.get(
    `${Configuration.rolodexEndpoint}/api/v1/token/csrf`,
    { withCredentials: true },
  );
  if (response.status === 200) {
    AS.setCsrf(response.data.csrf_token);
  }
}

export async function fetchCsrfToken() {
  const AS = AuthenticationStore();

  if (AS.isRolodexAuthed()) {
    await fetchRolodexCsrfToken();
  } else {
    await fetchAegisCsrfToken();
  }
}

export async function setUserFoldersPermission() {
  return pilot.get('user/status/onboarding/');
}
