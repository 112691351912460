import PropTypes from 'prop-types';
import React from 'react';

const ExpandIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" opacity=".9">
        <path d="M0 0h20v20H0z" />
        <path
          fill={props.color}
          d="M6.075 5.833c.375 0 .558-.45.292-.708L4.458 3.208a.413.413 0 0 0-.591 0L1.958 5.125a.417.417 0 0 0 .3.708h1.075v8.334H2.258a.412.412 0 0 0-.291.708l1.908 1.908a.413.413 0 0 0 .592 0l1.908-1.908a.417.417 0 0 0-.3-.708H5V5.833h1.075zm3.092 0H17.5A.836.836 0 0 0 18.333 5a.836.836 0 0 0-.833-.833H9.167A.836.836 0 0 0 8.333 5c0 .458.375.833.834.833zm8.333 8.334H9.167a.836.836 0 0 0-.834.833c0 .458.375.833.834.833H17.5a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.833zm0-5H9.167a.836.836 0 0 0-.834.833c0 .458.375.833.834.833H17.5a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.833z"
        />
      </g>
    </svg>
  );
};

ExpandIcon.defaultProps = {
  size: '20',
  color: '#007FBA',
};

ExpandIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default ExpandIcon;
