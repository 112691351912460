import { truncate } from 'lodash';
import React, { useEffect, useState } from 'react';

import { showToast } from '~/components/Shared/EcToast';
import {
  Box,
  Button,
  Chip,
  scrollElementIntoView,
  Table,
  Text,
  types,
} from '~/eds';
import { ClauseType } from '~/enums';
import { api } from '~/redux';
import { DocumentClause } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { copyToClipboard } from '~/utils/helper.utils';

import styles from './ClausesTable.styles';
import useEditClauseModal from './useEditClauseModal';
import useShowMoreClauseModal from './useShowMoreClauseModal/useShowMoreClauseModal';

type Props = {
  handlerId: number;
  clauseIdToScroll?: number;
};

type PaginationParams = {
  pageIndex: number;
};

const defaultClause: DocumentClause = {
  id: 0,
  name: '',
  content: '',
  title: '',
  version: '',
  provisionType: '',
  provisionVersion: '',
  htmlTokens: [],
  isLast: false,
  isEdited: false,
};

const PAGE_SIZE = 10;

const ClausesTable = ({ handlerId, clauseIdToScroll }: Props) => {
  const [clause, setClause] = useState<DocumentClause>(defaultClause);
  const [hasShownClauseToScroll, setHasShownClauseToScroll] = useState(false);
  const [page, setPage] = useState(1);

  const { data, isFetching } = api.endpoints.getAllProvisions.useQuery({
    docId: handlerId,
    page,
  });

  const [
    deleteProvision,
    deleteProvisionResult,
  ] = api.endpoints.deleteProvision.useMutation();
  const { isError, isSuccess, isLoading } = deleteProvisionResult;

  useEffect(() => {
    if (isSuccess) {
      showToast(SUCCESS, `${clause.name} Clause is permanently deleted`);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(ERROR, 'Something went wrong, please try again later.');
    }
  }, [isError]);

  useEffect(() => {
    if (data && clauseIdToScroll && !hasShownClauseToScroll) {
      const isInCurrentPage = data.results.find(
        (el) => el.id === clauseIdToScroll,
      );
      if (isInCurrentPage) {
        scrollElementIntoView(clauseIdToScroll.toString(), { block: 'center' });
        setHasShownClauseToScroll(true);
      } else {
        const hasMorePages = page * PAGE_SIZE < data.count;
        if (hasMorePages) {
          setPage((page) => page + 1);
        }
      }
    }
  }, [data, clauseIdToScroll]);

  const clearClause = () => {
    setClause(defaultClause);
  };

  const [clauseModal, showClauseModal] = useShowMoreClauseModal({
    content: clause.content,
    title: clause.title,
    onClose: clearClause,
  });
  const [editModal, showEditModal] = useEditClauseModal(
    clause,
    handlerId,
    clearClause,
  );

  const renderClauseContent = (cell: DocumentClause) => {
    return (
      <Box styles={styles.tableCell}>
        <Text id={`${cell.id}`} variant="body">
          {truncate(cell.content, { length: 500, omission: '...' })}
        </Text>
        {cell.content.length > 500 ? (
          <Button
            onClick={() => {
              setClause(cell);
              showClauseModal();
            }}
            variant="action"
            text="Read More"
          />
        ) : null}
      </Box>
    );
  };

  const columns = [
    {
      key: 'name',
      title: 'Clause',
      width: 'm',
      disableSortBy: true,
      disableResizing: true,
      renderCell: (cell: DocumentClause) => {
        return (
          <Text variant="body-bold" styles={styles.provisionTitle}>
            {cell.name}
          </Text>
        );
      },
    },
    {
      key: 'content',
      title: 'Content',
      disableSortBy: true,
      disableResizing: true,
      width: 'auto',
      renderCell: renderClauseContent,
    },
    {
      key: 'version',
      title: 'Document Version',
      width: 'm',
      disableSortBy: true,
      disableResizing: true,
      cellType: 'text',
      mapCellProps: ({ version }: DocumentClause) => ({ text: version }),
    },
    {
      key: 'provisionVersion',
      title: 'Clause Version',
      width: 'm',
      disableSortBy: true,
      disableResizing: true,
      renderCell: (cell: DocumentClause) => {
        return (
          <Box styles={styles.versionContainer}>
            <Text textAlign="right" variant="body">
              {cell.provisionVersion}
            </Text>
            {cell.isEdited && (
              <Text variant="tiny" color="text.secondary">
                Edited
              </Text>
            )}
          </Box>
        );
      },
    },
    {
      key: 'source',
      title: 'Source',
      width: 'm',
      disableSortBy: true,
      disableResizing: true,
      renderCell: (cell: DocumentClause) => {
        let status: types.StatusType | undefined = undefined;
        switch (cell.provisionType) {
          case ClauseType.EvisortAI:
            status = 'info';
            break;
          case ClauseType.QuickAI:
            status = 'inactive';
            break;
          case ClauseType.Migrated:
            status = 'warning';
            break;
        }
        return <Chip status={status} text={cell.provisionType} />;
      },
    },
  ];

  const rowActions = [
    {
      label: 'Copy to clipboard',
      onClick: (row: DocumentClause) => {
        copyToClipboard(
          row.content,
          'Provision content copied to clipboard',
          SUCCESS,
          null,
          true,
        );
      },
    },
    {
      label: 'Edit clause',
      onClick: (row: DocumentClause) => {
        setClause(row);
        showEditModal();
      },
    },
    {
      label: 'Delete clause',
      onClick: (row: DocumentClause) => {
        deleteProvision({ provisionId: row.id, documentHandlerId: handlerId });
      },
    },
  ];

  return (
    <>
      {/* @ts-ignore: Disable the table component props shape verification*/}
      <Table
        name="Clauses"
        data={data?.results || []}
        columns={columns}
        totalCount={data?.count}
        rowActions={rowActions}
        isBordered={false}
        state={{ pageIndex: page, pageSize: PAGE_SIZE }}
        isLoading={isFetching || isLoading}
        onPaginate={({ pageIndex }: PaginationParams) => {
          setPage(pageIndex);
        }}
        options={{
          enableExportXlsx: false,
          enableSelectRows: false,
          enableManageColumns: false,
        }}
      />
      {clauseModal}
      {editModal}
    </>
  );
};

export default ClausesTable;
