import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Icon, Text } from '~/ui';

// TODO: document and formally spec
function Collapsible({
  headerRightContent,
  id,
  isCollapsed = false,
  sx = {},
  title,
  onToggleCollapse,
}) {
  return (
    <FlexLayout
      alignItems="center"
      flex="1 1 auto"
      flexShrink="0"
      id={id}
      space={5}
      sx={sx}
      onClick={onToggleCollapse}
    >
      <Icon
        color="gray-900"
        icon={isCollapsed ? 'chevronRight' : 'chevronDown'}
        size="m"
      />
      <FlexLayout
        alignItems="center"
        flex="1 1 auto"
        flexShrink="0"
        justifyContent="space-between"
      >
        <Text color="gray-900" variant="m-dense-bold">
          {title}
        </Text>
        {headerRightContent}
      </FlexLayout>
    </FlexLayout>
  );
}

Collapsible.propTypes = {
  headerRightContent: PT.node,
  isCollapsed: PT.bool,
  sx: PT.object,
  title: PT.string,
  onToggleCollapse: PT.func,
};

export default Collapsible;
