const spacings = {
  xs: {
    spacing: 1,
  },
  s: {
    spacing: 2,
  },
  m: {
    spacing: 3,
  },
  l: {
    spacing: 6,
  },
  xl: {
    spacing: 12,
  },
};

export const presets = {
  ...spacings,
  buttons: {
    align: 'center',
    role: 'toolbar',
    spacing: spacings.xs.spacing,
  },
  cards: {
    spacing: spacings.l.spacing,
  },
  chips: {
    spacing: spacings.xs.spacing,
    wrap: true,
    wrapSpacing: spacings.xs.spacing,
  },
  'form-fields': {
    direction: 'column',
    spacing: spacings.m.spacing,
  },
  icons: {
    align: 'center',
    direction: 'row',
    spacing: spacings.s.spacing,
  },
  sections: {
    direction: 'column',
    spacing: spacings.l.spacing,
  },
  subsections: {
    direction: 'column',
    spacing: spacings.m.spacing,
  },
  users: {
    align: 'center',
    spacing: -spacings.m.spacing,
    wrap: true,
    wrapSpacing: spacings.xs.spacing,
  },
};
