import { pilot } from '~/services';
import { PilotId } from '~/types';

interface Request {
  clientId: PilotId;
}

interface Response {
  suggestions: string[];
}

export const getClientContractTypes = async ({
  clientId,
}: Request): Promise<string[]> => {
  const response: Response = await pilot.get(
    `client/${clientId}/contract-types/`,
  );

  return response.suggestions;
};
