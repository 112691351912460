/**
 * Organized namespaces for creating namespaced BEM test IDs.
 *
 * - Avoid updating existing namespaces and add new namespaces in alphabetical order without confirming consuming code and reporting.
 * - Enum values should be lower-snake-cased.
 */
export enum TestNamespaceType {
  DocumentsViewPage = 'documents-view-page',
  EmailModal = 'email-modal',
  SearchPage = 'search-page',
  TicketReviewerPage = 'ticket-reviewer-page',
  HighlightNavigator = 'highlight-navigator',
  Ui = 'ui',
}
