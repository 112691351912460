import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { black2, evisortBlue } from '../../../assets/shared-styles/general';
import { MODAL_LIST } from '../../../types/modal.types';
import { SUCCESS, WARNING } from '../../../types/toast.types';
import {
  editDocumentRelationshipGroup,
  linkParentChildDocumentsInGroup,
} from '../../DocumentGroupsPage/DocumentGroupsPage.data';
import { documentTypeIcon } from '../../DocumentsPage/Document.utils';
import EcButton from '../../Shared/EcButton';
import EcModal from '../../Shared/EcModal';
import EcModalCard from '../../Shared/EcModalCard';
import EcRadioButton from '../../Shared/EcRadioButton';
import EcRadioContainer from '../../Shared/EcRadioContainer';
import { showToast } from '../../Shared/EcToast';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import ChevronRightIcon from '../../Shared/Icons/ChevronRightIcon';
import styles from './DocumentGroupRelationModal.styles';

class DocumentGroupRelationModal extends Component {
  constructor(props) {
    super(props);

    this.handleAddListItem = this.handleAddListItem.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);

    this.state = {
      selectedOption: this.props.selectedDocument.node.relationship,
      documentSelected: null,
      selectedDocumentPCRelationOption: 'linkAsChild',
    };
  }

  handleOptionSelection(selectedOption) {
    this.setState({ selectedOption });
  }

  handleDocumentPCRelationSelection(selectedDocumentPCRelationOption) {
    this.setState({ selectedDocumentPCRelationOption });
  }

  handleShowModal(modal) {
    this.setState({
      primaryModal: modal,
    });
  }

  handleHideModal() {
    this.setState({ primaryModal: null });
  }

  handleAddListItem(documentSelected) {
    this.setState({ documentSelected });
  }

  handleActionClick() {
    const {
      selectedDocument,
      groupId,
      hideModal,
      reloadDocumentTreeData,
    } = this.props;
    const {
      selectedOption,
      documentSelected,
      selectedDocumentPCRelationOption,
    } = this.state;

    if (selectedOption === 'uncategorized' || selectedOption === 'supporting') {
      const data = { relationship: selectedOption };
      editDocumentRelationshipGroup(selectedDocument.node.docGroupId, data)
        .then(() => {
          hideModal();
          showToast(SUCCESS, 'Document has been updated.');
          reloadDocumentTreeData();
        })
        .catch(() =>
          showToast(
            WARNING,
            'An error occurred while updating the document relationship.',
          ),
        );
    } else {
      const data =
        selectedDocumentPCRelationOption === 'linkAsChild'
          ? {
              parent: documentSelected.documentId,
              child: selectedDocument.node.documentId,
            }
          : {
              child: documentSelected.documentId,
              parent: selectedDocument.node.documentId,
            };
      linkParentChildDocumentsInGroup(groupId, data)
        .then(() => {
          hideModal();
          showToast(SUCCESS, 'Documents have been linked.');
          reloadDocumentTreeData();
        })
        .catch(() => {
          hideModal();
          showToast(
            WARNING,
            'An error occurred while updating the document relationship.',
          );
        });
    }
  }

  renderDocumentRelationOptions() {
    const { classes } = this.props;
    const { selectedOption } = this.state;

    return (
      <EcRadioContainer
        groupName="documentRelationOptions"
        onChange={({ selectedValue }) =>
          this.handleOptionSelection(selectedValue)
        }
        defaultValue={selectedOption}
      >
        {(groupName, selectedValue, onOptionChange) => (
          <div className={classes.radioContainerContent}>
            <EcRadioButton
              id="uncategorized"
              groupName="documentRelationOptions"
              value="uncategorized"
              label="Uncategorized"
              onChange={onOptionChange}
              selectedValue={selectedOption}
              labelColor={black2}
            />

            <EcRadioButton
              id="linkToDocument"
              groupName="documentRelationOptions"
              value="linked"
              label="Link to Document"
              onChange={onOptionChange}
              selectedValue={selectedOption}
              labelColor={black2}
            />

            {selectedOption === 'linked' && this.renderPickADocumentLink()}

            <EcRadioButton
              id="supportingDocument"
              groupName="documentRelationOptions"
              value="supporting"
              label="Supporting Document"
              onChange={onOptionChange}
              selectedValue={selectedOption}
              labelColor={black2}
            />
          </div>
        )}
      </EcRadioContainer>
    );
  }

  renderDocumentPCRelationOptions() {
    const { classes } = this.props;
    const { selectedDocumentPCRelationOption, documentSelected } = this.state;

    if (!documentSelected) return null;
    return (
      <>
        <div className={classes.relationshipLabel}>RELATIONSHIP</div>
        <EcRadioContainer
          groupName="documentPCRelationOptions"
          onChange={({ selectedValue }) =>
            this.handleDocumentPCRelationSelection(selectedValue)
          }
          defaultValue={selectedDocumentPCRelationOption}
        >
          {(groupName, selectedValue, onOptionChange) => (
            <div className={classes.radioContainerContent}>
              <EcRadioButton
                id="linkAsChild"
                isInline={true}
                groupName="documentPCRelationOptions"
                value="linkAsChild"
                label="Link as Child"
                onChange={onOptionChange}
                selectedValue={selectedDocumentPCRelationOption}
                fontSize="13px"
                labelColor={black2}
              />

              <EcRadioButton
                id="linkAsParent"
                isInline={true}
                groupName="documentPCRelationOptions"
                value="linkAsParent"
                label="Link as Parent"
                onChange={onOptionChange}
                selectedValue={selectedDocumentPCRelationOption}
                fontSize="13px"
                labelColor={black2}
              />
            </div>
          )}
        </EcRadioContainer>
      </>
    );
  }

  renderDoumentListModal() {
    const { groupData } = this.props;

    return (
      <EcModal
        modalType={MODAL_LIST}
        width="560px"
        title="Pick a Document"
        listItems={groupData}
        selectedItemIds={[]}
        returnListItem={this.handleAddListItem}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderPickADocumentLink() {
    const { classes } = this.props;
    const { documentSelected } = this.state;

    return (
      <div className={classes.pickADocument}>
        {documentSelected ? (
          <div className={classes.documentSelectedDisplay}>
            <span>{documentTypeIcon(documentSelected.fileType)}</span>
            {documentSelected.label}
          </div>
        ) : (
          <div className={classes.lightText}>
            You haven’t selected a document to link to.
          </div>
        )}
        <div>
          <button
            className={classes.selectDocumentCTA}
            onClick={() => this.handleShowModal(MODAL_LIST)}
          >
            <ChevronRightIcon size="15" color={evisortBlue} />
            Pick a document
          </button>
        </div>
        {this.renderDocumentPCRelationOptions()}
      </div>
    );
  }

  render() {
    const { classes, title, hideModal } = this.props;
    const { selectedOption, documentSelected, primaryModal } = this.state;
    const disableSaveButton = selectedOption === 'linked' && !documentSelected;

    return (
      <>
        <EcModalCard
          title={title}
          content={
            <div className={classes.modalBody}>
              <div className={classes.modalBodyContent}>
                <div className={classes.headerText}>
                  Specify the relationship between this document and other
                  documents in the group.
                </div>
                {this.renderDocumentRelationOptions()}
              </div>
            </div>
          }
          footer={
            <>
              {selectedOption === 'existingGroup' ? (
                <EcButton white text="Cancel" onClick={hideModal} />
              ) : (
                <>
                  <EcButton
                    white
                    borderless
                    text="Cancel"
                    onClick={hideModal}
                  />
                  <EcButton
                    disabled={disableSaveButton}
                    yellow
                    text="Save"
                    onClick={this.handleActionClick}
                    iconLeft={<CheckmarkIcon size="20" color={black2} />}
                  />
                </>
              )}
            </>
          }
          hideModal={hideModal}
        />
        {primaryModal === MODAL_LIST && this.renderDoumentListModal()}
      </>
    );
  }
}

DocumentGroupRelationModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  labelText: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(DocumentGroupRelationModal);
