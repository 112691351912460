import { semibold } from '../../../assets/shared-styles/general';

const styles = {
  sectionsContainer: {
    marginTop: '20px',
  },
  sectionRow: {
    margin: '10px 0',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'row-resize',
    '& svg': {
      position: 'relative',
      top: '5px',
      left: '3px',
    },
  },
  sectionName: {
    marginLeft: '10px',
    fontSize: '15px',
    fontWeight: semibold,
    lineHeight: '22px',
  },
  sortableHelper: {
    zIndex: 10,
  },
};

export default styles;
