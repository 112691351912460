import { theme } from '../../theme';

const { colors } = theme;

// TODO: uinix-ui will expose a fully customizable theme spec in the near future.  This can be moved formally into the `theme` construct instead of managed in style rules in the future.
export const background = {
  darkChrome: {
    backgroundColor: 'inverse.background',
    color: 'inverse.text.primary',
    height: '100vh',
    width: '100vw',
  },
  gradient: {
    linear: {
      ai: `linear-gradient(90deg, ${colors.brand} 0%, ${colors['brand.ai']} 100%)`,
    },
    radial: {
      brand: `radial-gradient(125.75% 12307.02% at -8.33% -6.1%, ${colors.background} 0%, ${colors['brand.light']} 21.57%, ${colors.background} 52.17%, ${colors['brand.light']} 71.96%)`,
    },
    complete: {
      background:
        'radial-gradient(105.68% 45.69% at 92.95% 50%, rgba(105, 244, 253, 0.5) 0%, rgba(160, 255, 244, 0.095) 53.91%, rgba(254, 216, 255, 0) 100%), radial-gradient(103.18% 103.18% at 90.11% 102.39%, #C9FFF2 0%, rgba(230, 255, 250, 0) 100%), radial-gradient(90.45% 90.45% at 87.84% 9.55%, #FFD2F5 0%, rgba(254, 219, 246, 0) 100%), linear-gradient(135.66deg, rgba(203, 185, 255, 0.8) 14.89%, rgba(216, 202, 254, 0) 74.33%)',
    },
  },
  image: (url: string) => ({
    backgroundImage: `url(${url})`,
    backgroundOrigin: 'content-box',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }),
};
