import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { PromptModelWorkflow } from '../../types/sylvanus/model';

interface Request {
  modelId: Uuid;
}

type Response = boolean;

export const suspendPromptModel = async ({
  modelId,
}: Request): Promise<Response> => {
  const response = (await sylvanus.patch(
    `/model/${modelId}/suspend`,
  )) as PromptModelWorkflow;

  return Boolean(response);
};
