import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import EcUserItem from '../EcUserItem';
import styles from './EcUserTable.styles';

class EcUserTable extends Component {
  renderUserListItems() {
    const { listItems, handleShowModal } = this.props;
    if (!listItems.length)
      return (
        <tr>
          <td>No current users</td>
        </tr>
      );
    return listItems.map((item, index) => {
      return (
        <EcUserItem
          item={item}
          key={`item-${index}`}
          handleShowModal={handleShowModal}
        />
      );
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <table className={classes.userTable}>
        <thead>
          <tr>
            <th className={classes.headerColumn}>Users</th>
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
          </tr>
          <tr>
            <th className={classes.headerColumn}>Email</th>
            <th className={classes.headerColumn}>Name</th>
            <th className={classes.headerColumn}>Last Access</th>
            <th className={classes.headerColumn}>Access Level</th>
            <th className={classes.headerColumn}>Department</th>
            <th className={classes.headerColumn}>Job Title</th>
            <th className={classes.headerColumn} />
          </tr>
        </thead>
        <tbody>{this.renderUserListItems()}</tbody>
      </table>
    );
  }
}

EcUserTable.propTypes = {
  classes: PropTypes.object.isRequired,
  listItems: PropTypes.array.isRequired,
  handleShowModal: PropTypes.func,
};

export default injectSheet(styles)(EcUserTable);
