import React from 'react';

import withHover from '~/components/Shared/HOCs/withHover';
import { FlexLayout, Icon, useHover } from '~/ui';
import { Radio } from '~/ui/components/RadioGroup';

function RadioItem({
  disabled = false,
  indent = 1,
  option,
  selected,
  onClick,
  onShowMore = null,
  alignItems = 'center',
}) {
  const [hoverRef, isHovered] = useHover();

  return (
    <FlexLayout
      alignItems={alignItems}
      bg={isHovered ? 'gray-200' : 'transparent'}
      disabled={disabled}
      flexGrow="1"
      justifyContent="space-between"
      px={indent * 6}
      py={3}
      ref={hoverRef}
      onClick={onClick}
    >
      <Radio option={option} selected={selected} alignItems={alignItems} />
      {isHovered && onShowMore && (
        <Icon color="gray-900" icon="arrowRight" size="s" />
      )}
    </FlexLayout>
  );
}

export default withHover(RadioItem);
