import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.DocumentRestore, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.DocumentRestore as TagType],
  endpoints: {
    getDeletedDocuments: {
      providesTags: [TAG_BY_LIST],
    },
    getDeletedDocumentsForCurrentUser: {
      providesTags: [TAG_BY_LIST],
    },
    permanentlyDeleteDocuments: {
      invalidatesTags: [TAG_BY_LIST],
    },
    permanentlyDeleteDocumentsForCurrentUser: {
      invalidatesTags: [TAG_BY_LIST],
    },
    restoreDeletedDocuments: {
      invalidatesTags: [TAG_BY_LIST],
    },
    restoreDeletedDocumentsForCurrentUser: {
      invalidatesTags: [TAG_BY_LIST],
    },
  },
});
