import React from 'react';

import { withGetFieldValuesOnFocus } from '~/hocs';

import EcSelect from '../EcSelect';

const LazyEcSelect = withGetFieldValuesOnFocus(EcSelect);

const FieldValuesSingleSelect = (props) => {
  const { fieldId, searchSuggestions } = props;

  return (
    <LazyEcSelect
      {...props}
      fieldId={fieldId}
      searchSuggestions={searchSuggestions}
    />
  );
};

export default FieldValuesSingleSelect;
