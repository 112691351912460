import React from 'react';

import { IconButton } from '../../../components';
import { exportXlsx } from '../../../utils';
import { UI_TABLE_EXPORT_EXCEL_ICON_TESTID } from '../constants';

function ExportXlsxAction({ state }) {
  const handleClick = () => {
    exportXlsx({
      data: state.data,
      name: state.name,
    });
  };

  const title = 'Export Excel';

  return (
    <div className={UI_TABLE_EXPORT_EXCEL_ICON_TESTID}>
      <IconButton
        icon="download"
        size="s"
        tooltip={title}
        onClick={handleClick}
      />
    </div>
  );
}

export default ExportXlsxAction;
