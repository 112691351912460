import React from 'react';

import { fromValuesMap, HtmlEntityType } from '~/eds';

import { TextContainer } from '../TextContainer';
import { EnumValue, Field, Filter } from '../types';

interface Props {
  field: Field<'enum' | 'enum_set'>;
  filter: Filter<EnumValue>;
}

export const EnumValuesPreview = ({ field, filter }: Props) => {
  const { asyncValue, values } = filter;
  const { serialize, settings } = field;
  const { options } = settings;
  const value = (asyncValue
    ? fromValuesMap(asyncValue.selectedValuesMap)
    : values)[0];

  /**
   * The preview text value is determined in the following priority:
   * - Run the serialize method provided by the field.
   * - Find the matching value in the provided option.
   * - Render the value as-is.
   */
  let text = '';
  if (serialize) {
    const serializedValue = serialize(filter);
    text =
      serializedValue === undefined ? HtmlEntityType.Ellipsis : serializedValue;
  } else if (options.length > 0) {
    text = options.find((option) => option.value === value)?.label || '';
  } else {
    text = String(value);
  }

  return <TextContainer text={text} />;
};
