import { createSelector } from '@reduxjs/toolkit';

import { DashboardType, SliceType } from '~/enums';
import { Dashboard } from '~/types';

import { RootState, DashboardsState as State } from '../types';

const selectState = (rootState: Partial<RootState>): State =>
  rootState[SliceType.Dashboards];

const selectDashboards = createSelector(
  selectState,
  (state: State): Dashboard[] => Object.values(state.entities),
);

export const selectDashboardByType = (type: DashboardType) =>
  createSelector(
    selectDashboards,
    (dashboards: Dashboard[]): Dashboard | void =>
      dashboards.find((dashboard) => dashboard.type === type),
  );
