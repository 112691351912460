import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { startImportDocumentMetadataListProcessing } from '~/api/documentMetadata';
import { showToast } from '~/components/Shared/EcToast';
import { Table } from '~/eds';
import { BulkImportDocumentMetadataType } from '~/enums';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { FlexLayout, LoadingSpinner, Text, WizardPageLayout } from '~/ui';
import { parseApiError } from '~/utils/parseApiError';

function SummaryStep(props) {
  const {
    dataTestid,
    stepCommonWizardProps,
    data,
    clientId,
    moveToStep,
    handleImportedJob = () => {},
  } = props;
  const { previewHeaders, previewItems, importId } = data;

  const [isLoading, setIsLoading] = useState(false);

  const renderBody = () => {
    const columns = previewHeaders.map((header, index) => {
      const key = `col${index}`;
      return {
        key,
        title: header,
        mapCellProps: (d) => ({ text: d[key] }),
        minWidth: 's',
        maxWidth: 'l',
        width: 's',
      };
    });

    const addColumnKeyValuePairsToDataItem = (dataItem, valuesOfRow) => {
      const addColumnKeyValuePairToDataItem = (
        dataItem,
        value,
        valueIndexInRow,
      ) => {
        const columnKey = columns[valueIndexInRow].key;
        return { ...dataItem, [columnKey]: value };
      };

      return valuesOfRow.reduce(addColumnKeyValuePairToDataItem, dataItem);
    };

    const data = previewItems.map((valuesOfRow, rowIndex) => {
      const dataItem = { id: `${rowIndex}` };
      return addColumnKeyValuePairsToDataItem(dataItem, valuesOfRow);
    });

    return (
      <FlexLayout
        space={2}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <FlexLayout space={3} flexDirection="column" alignItems="center">
          {isLoading ? (
            <>
              <h4>Importing Document Metadata List</h4>
            </>
          ) : (
            <>
              <Text variant="subtitle">Review Columns</Text>
              <Text color="gray-600">
                Below are sample rows from the file you have uploaded.
              </Text>
            </>
          )}
        </FlexLayout>
        {isLoading ? (
          <LoadingSpinner size="m" />
        ) : (
          <Table
            name="Preview Items Table"
            columns={columns}
            data={data}
            options={{
              enableControls: false,
              enableExportXlsx: false,
              enableManageColumns: false,
              enableSearch: false,
              enableSelectRows: false,
            }}
          />
        )}
      </FlexLayout>
    );
  };

  const backButton = {
    promise: () => moveToStep(BulkImportDocumentMetadataType.Upload),
    text: 'Back To Upload',
    disabled: false,
  };

  const handleApiError = (errorResponse, customErrorMessage = '') => {
    const error = parseApiError(errorResponse, customErrorMessage);
    showToast(ERROR, error.message);
  };

  const importDocument = async () => {
    setIsLoading(true);
    try {
      await startImportDocumentMetadataListProcessing({ clientId, importId });
      showToast(SUCCESS, 'Document Metadata List is being processed.');
      stepCommonWizardProps.onClose();
      handleImportedJob(importId);
    } catch (errorResponse) {
      const customErrorMessage =
        'Something went wrong when importing Document Metadata List.';
      handleApiError(errorResponse, customErrorMessage);
      setIsLoading(false);
    }
  };

  const actionButton = {
    promise: importDocument,
    text: 'Import Document',
    disabled: isLoading,
  };

  return (
    <WizardPageLayout
      {...stepCommonWizardProps}
      isContentFill={true}
      isContainer={false}
      currentStep={BulkImportDocumentMetadataType.Summary}
      actionButton={actionButton}
      backButton={backButton}
      children={<div data-testid={dataTestid}>{renderBody()}</div>}
    />
  );
}

SummaryStep.propTypes = {
  dataTestid: PropTypes.string.isRequired,
  stepCommonWizardProps: PropTypes.object.isRequired,
  moveToStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  clientId: PropTypes.string.isRequired,
};

export default SummaryStep;
