import { HttpStatusCodeType } from '~/enums';

export const handle403 = (error) => {
  if (error?.response?.status === HttpStatusCodeType.Forbidden) {
    // we can show an error toast base on the error.request.url
    // silencing the error until we get Product spec
  } else {
    throw error;
  }
};
