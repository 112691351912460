import React, { useEffect, useState } from 'react';

import { Layout, LoadingShimmer, Text, useToast } from '~/eds';
import { Filter } from '~/evifields';
import { api } from '~/redux';
import { buildQuery } from '~/redux/api/methods/searchV3';

interface Props {
  filters: Filter[];
  isVisible?: boolean;
}

export const SearchFiltersScope = ({ filters, isVisible = false }: Props) => {
  const { toast } = useToast();
  const [defaultTotal, setDefaultTotal] = useState(0);

  const filtersWithValues = filters.filter(
    (filter) => Array.isArray(filter.values) && filter.values.length > 0,
  );

  const { data: searchFilters } = api.endpoints.getFilters.useQuery(undefined);
  const {
    data: docsInScope,
    isLoading: isLoadingDocsInScope,
    error: errorDocsInScope,
  } = api.endpoints.searchV3Documents.useQuery(
    {
      page: 1,
      pageSize: 20,
      query: buildQuery({
        booleanQuery: '',
        filters: filtersWithValues,
        searchFilters,
      }),
      store_as_recent_search: false,
    },
    {
      skip: !isVisible || filtersWithValues.length <= 0,
    },
  );

  useEffect(() => {
    if (filtersWithValues.length <= 0 || !docsInScope) {
      setDefaultTotal(0);
    } else if (docsInScope) {
      setDefaultTotal(docsInScope.meta.total);
    }
  }, [filtersWithValues, docsInScope]);

  if (errorDocsInScope) {
    toast({
      status: 'danger',
      message: 'Failed to get documents in scope',
    });
  }

  return (
    <Layout preset="xs">
      <Text>Documents Found: </Text>
      {isLoadingDocsInScope ? (
        <LoadingShimmer width="25" />
      ) : (
        <Text color="status.active">{defaultTotal}</Text>
      )}
    </Layout>
  );
};
