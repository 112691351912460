import { SavedSearchesList } from '~/features/search';
import { FlagType, useFlag } from '~/flags';

import DeprecatedSavedSearch from './DEPRECATED_saved-search';

function Page() {
  const hasSearchV3 = useFlag(FlagType.SearchV3);

  return hasSearchV3 ? <SavedSearchesList /> : <DeprecatedSavedSearch />;
}

export default Page;
