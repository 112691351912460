import { isEqual, noop } from 'lodash';
import React from 'react';

import {
  ActionsMenu,
  DropdownBody,
  DropdownFooter,
  FormField,
  IconButton,
  Layout,
  Select,
  useDropdown,
} from '~/eds';

import { RevisionProps } from './types';

const CHANGE_TYPE_OPTIONS = [
  { label: 'All Changes', value: 'all' },
  { label: 'Inserted', value: 'insertion' },
  { label: 'Deleted', value: 'deletion' },
];

export const DEFAULT_FILTER = {
  author: 'all',
  changeType: 'all',
};

const MENU_ACTIONS = [
  { label: 'Accept all changes', value: 'accept_all' },
  { label: 'Reject all changes', value: 'reject_all' },
];

interface Props {
  filters: Filters;
  onUpdateFilters: (filter: Filters) => void;
  authors: string[];
  revisions: RevisionProps;
  disabled: boolean;
}

export interface Filters {
  author: string;
  changeType: string;
}

export function RevisionsFilter({
  authors,
  filters,
  onUpdateFilters,
  revisions,
  disabled,
}: Props) {
  const isFiltersOnDefaultState = isEqual(filters, DEFAULT_FILTER);

  const authorOptions = [
    { label: 'All', value: 'all' },
    ...authors.map((author) => ({ label: author, value: author })),
  ];

  function onMoreActionClick(actionValue: string) {
    switch (actionValue) {
      case 'accept_all':
        revisions.acceptAll(revisions.changes);
        break;
      case 'reject_all':
        revisions.rejectAll(revisions.changes);
        break;
      default:
        noop();
        break;
    }
  }

  const selectInputProps = {
    enablePortal: true,
    enableSorting: false,
    enableValueContainer: true,
    isClearable: false,
    isMulti: false,
    isSearchable: false,
  };

  const [wrap, _show, _hide, toggle] = useDropdown({
    children: (
      <DropdownBody>
        <Layout direction="column" spacing={4}>
          <FormField
            label="Changes from"
            input={Select}
            inputProps={{
              ...selectInputProps,
              options: authorOptions,
            }}
            name="changeType"
            value={filters.author}
            onChange={(updatedValue) => {
              if (updatedValue) {
                onUpdateFilters({ ...filters, author: updatedValue as string });
              }
            }}
          />
          <FormField
            label="Show"
            input={Select}
            inputProps={{
              ...selectInputProps,
              options: CHANGE_TYPE_OPTIONS,
            }}
            name="changeType"
            value={filters.changeType}
            onChange={(updatedValue) => {
              if (updatedValue) {
                onUpdateFilters({
                  ...filters,
                  changeType: updatedValue as string,
                });
              }
            }}
          />
        </Layout>
      </DropdownBody>
    ),
    footer: (
      <DropdownFooter
        leftAction={{
          disabled: isFiltersOnDefaultState,
          text: 'Reset Filters',
          onClick: () => onUpdateFilters(DEFAULT_FILTER),
        }}
      />
    ),
  });

  return (
    <Layout>
      {wrap(
        <IconButton
          icon="filter"
          status={!isFiltersOnDefaultState ? 'active' : undefined}
          tooltip="Set filters"
          onClick={toggle}
        />,
      )}
      <ActionsMenu
        disabled={disabled}
        icon="more"
        actions={MENU_ACTIONS}
        name="acceptRejectAll"
        onActionClick={onMoreActionClick}
      />
    </Layout>
  );
}
