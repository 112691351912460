import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import ChevronDownIcon from '../Icons/ChevronDownIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import styles from './EcCard.styles';

class EcCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCollapsed: this.props.allCollapsed,
      collapsed: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allCollapsed !== prevState.allCollapsed) {
      return {
        allCollapsed: nextProps.allCollapsed,
        collapsed: nextProps.allCollapsed,
      };
    } else {
      return null;
    }
  }

  collapseSection(collapsed) {
    this.setState({ collapsed });
  }

  render() {
    const {
      classes,
      title,
      includeCollapseExpand,
      titleRightComponent,
      children,
      contentStyles,
      containerRef,
      testId,
    } = this.props;
    const { allCollapsed, collapsed } = this.state;

    return (
      <div
        ref={containerRef}
        className={
          collapsed ? classes.collaspedEcCardWrapper : classes.ecCardWrapper
        }
        data-testid={testId}
      >
        <div className={classes.ecCardTitleWrapper}>
          {title ? (
            <div className={classes.ecCardTitle}>
              {includeCollapseExpand && (
                <>
                  {collapsed || allCollapsed ? (
                    <button
                      className={classes.expandCollapseBtn}
                      onClick={() => this.collapseSection(false)}
                    >
                      <ChevronRightIcon />
                    </button>
                  ) : !collapsed || !allCollapsed ? (
                    <button
                      className={classes.expandCollapseBtn}
                      onClick={() => this.collapseSection(true)}
                    >
                      <ChevronDownIcon />
                    </button>
                  ) : null}
                </>
              )}
              {title}
            </div>
          ) : null}
          {titleRightComponent && (
            <div className={classes.ecCardTitleRightComponent}>
              {titleRightComponent}
            </div>
          )}
        </div>
        {!collapsed && (
          <div className={classes.ecCardContent} style={contentStyles}>
            {children}
          </div>
        )}
      </div>
    );
  }
}

EcCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleRightComponent: PropTypes.element,
  testId: PropTypes.string,
  contentStyles: PropTypes.object,
};
const StyledEcCard = injectSheet(styles)(EcCard);
export default React.forwardRef((props, ref, children) => (
  <StyledEcCard containerRef={ref} children={children} {...props} />
));
