export interface CancellablePromise<T> {
  cancel: () => void;
  promise: Promise<T>;
}

/**
 * Creates a cancellable promise object containing the wrapped promise and cancel method.  When cancelled, the promise will always reject with an object that identifies that 'cancelled === true'
 */
export const createCancellablePromise = <T>(
  promise: Promise<T>,
): CancellablePromise<T> => {
  let _cancelled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise
      .then((val) => {
        if (_cancelled) {
          reject({ cancelled: true });
        } else {
          resolve(val);
        }
      })
      .catch((error) => {
        if (_cancelled) {
          reject({ cancelled: true });
        } else {
          reject(error);
        }
      });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      _cancelled = true;
    },
  };
};
