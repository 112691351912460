import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { ticketActivitiesSet, ticketParticipantsSet } from '~/actions';
import { getActivities, getTicketParticipants } from '~/api';
import EcButton from '~/components/Shared/EcButton';
import { showToast } from '~/components/Shared/EcToast';
import { CommentPostType, EntityType } from '~/enums';
import { withUsers } from '~/hocs';
import { useAsync } from '~/hooks';
import ticket from '~/reducers/ticket';
import * as toastTypes from '~/types/toast.types';
import { Box, FlexLayout, Text, useModal, useToggle } from '~/ui';
import { sortByDateValue } from '~/utils/array';
import { getMentions } from '~/utils/comments';
import { getUserName } from '~/utils/user';

import Comment, { commentPropTypes } from './Comment';
import CommentPost from './CommentPost';

function CommentThread({
  comments: initialComments = [],
  ticketId,
  participantIds,
  displayCount = 5,
  events = [],
  isLocked = false,
  commentDataRenderer,
  eventRenderer,
  promises,
  //injected
  ticketActivitiesSet,
  ticketParticipantsSet,
  users,
}) {
  const allItemsCount = initialComments.length + events.length;

  const [comments, setComments] = useState(initialComments);
  const [postContent, setPostContent] = useState('');
  const [updatedContent, setUpdatedContent] = useState(null);
  const [isPosting, setIsPosting] = useState(false);
  const [isCollapsed, toggleIsCollapsed] = useToggle(true);
  const [usersToAddParticipants, setUsersToAddParticipants] = useState([]);

  useEffect(() => setComments(initialComments), [initialComments]);

  function getUserNameText(userIds) {
    const userNames = userIds.map((id) => getUserName(users[id]));
    if (userNames.length === 1) return userNames[0];
    else if (userNames.length === 2) return userNames.join(' and ');
    else return `${userNames[0]} and ${userNames.length - 1} other people`;
  }

  const allItems = useMemo(() => {
    const startIndex =
      !isCollapsed || allItemsCount <= displayCount
        ? 0
        : allItemsCount - displayCount;
    const endIndex = allItemsCount;
    return [
      ...comments.map((comment) => ({
        id: comment.id,
        type: 'comment',
        createdDate: comment.createdDate,
        comment,
      })),
      ...events.map((event) => ({
        action: event.action,
        id: event.id,
        type: 'event',
        createdDate: event.createdDate,
        event,
      })),
    ]
      .sort(sortByDateValue('createdDate', 'asc'))
      .slice(startIndex, endIndex);
  }, [allItemsCount, comments, displayCount, events, isCollapsed]);

  function clearPostContent() {
    setPostContent('');
  }

  const { executor: handleGetParticipants } = useAsync(
    getTicketParticipants,
    { ticketId },
    {
      successHandler: ({ data }) => ticketParticipantsSet(data),
    },
  );

  function handleUpdatedContent(updatedContent, addParticipants) {
    promises
      .postComment(updatedContent, addParticipants)
      .then((newComment) => {
        setComments([...comments, newComment]);
        handleGetParticipants(ticket);
      })
      .catch(() =>
        showToast(
          toastTypes.ERROR,
          'Unable to post comment, please try again.',
        ),
      )
      .finally(() => {
        if (addParticipants) {
          setUpdatedContent(null);
        }
        clearPostContent();
        setIsPosting(false);
      });
  }

  function handleSubmitPost(updatedContent) {
    setIsPosting(true);
    const mentionedUsersNotParticipants = getMentions(updatedContent).filter(
      (participant) => !participantIds.includes(participant),
    );
    if (mentionedUsersNotParticipants.length) {
      setUsersToAddParticipants(mentionedUsersNotParticipants);
      setUpdatedContent(updatedContent);
      showModal();
    } else {
      handleUpdatedContent(updatedContent, false);
    }
  }

  const [modal, showModal] = useModal({
    title: `Add as ${pluralize('Participant', usersToAddParticipants.length)}?`,
    actionButton: {
      text: 'Add to Ticket',
      errorHandler: () =>
        showToast(toastTypes.ERROR, 'Add participants failed.'),
      promise: async () => {
        handleUpdatedContent(updatedContent, true);
        const participants = await getTicketParticipants({ ticketId });
        ticketParticipantsSet(participants.data);
        const activities = await getActivities({
          entityId: ticketId,
          entityType: EntityType.Ticket,
        });
        ticketActivitiesSet(activities);
        showToast(
          toastTypes.SUCCESS,
          `${getUserNameText(usersToAddParticipants)}
          ${
            usersToAddParticipants.length > 1 ? 'have' : 'has'
          } been added to the list of participants.`,
        );
      },
    },
    onCancel: () => {
      setIsPosting(false);
    },
    content: (
      <FlexLayout flexDirection="column" space={8}>
        <FlexLayout flexDirection="column" space={2}>
          <Text variant="xs-dense">
            <Box mb={4}>
              {`The ${pluralize('user', usersToAddParticipants.length)} below
              ${
                usersToAddParticipants.length > 1 ? 'are' : 'is'
              } not in the list of participants for and must be added to the ticket in order to see this comment.`}
            </Box>
            {usersToAddParticipants
              ? usersToAddParticipants.map((user) => {
                  return (
                    <Box>
                      <Text variant="xs-dense-medium">
                        {getUserName(users[user])}
                      </Text>
                    </Box>
                  );
                })
              : null}
          </Text>
          <Box>
            <Text variant="xs-dense">Want to invite them?</Text>
          </Box>
        </FlexLayout>
      </FlexLayout>
    ),
  });

  return (
    <Box>
      {modal}
      {allItemsCount > displayCount && (
        <Box mb={8}>
          <EcButton
            height="32px"
            text={
              <FlexLayout alignItems="center" justifyContent="center">
                Show {isCollapsed ? `all ${allItemsCount}` : 'fewer'} items
              </FlexLayout>
            }
            textAlign="center"
            width="100%"
            onClick={toggleIsCollapsed}
          />
        </Box>
      )}
      <FlexLayout flexDirection="column" space={6}>
        {allItems.map((item) => {
          const { id, comment, event, type } = item;
          switch (type) {
            case 'event':
              return <div key={id}>{eventRenderer(event)}</div>;
            case 'comment':
            default:
              return (
                <Comment
                  key={id}
                  comment={comment}
                  commentDataRenderer={commentDataRenderer}
                  isLocked={isLocked}
                  promises={promises}
                  handleGetParticipants={handleGetParticipants}
                />
              );
          }
        })}
      </FlexLayout>
      {!isLocked && (
        <Box mt={7}>
          <CommentPost
            content={postContent}
            isLoading={isPosting}
            postType={CommentPostType.Post}
            onCancel={clearPostContent}
            onSubmit={handleSubmitPost}
            onUpdateContent={setPostContent}
          />
        </Box>
      )}
    </Box>
  );
}

const eventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
});

CommentThread.propTypes = {
  comments: PropTypes.arrayOf(commentPropTypes.isRequired).isRequired,
  displayCount: PropTypes.number,
  events: PropTypes.arrayOf(eventPropTypes.isRequired),
  isLocked: PropTypes.bool,
  promises: PropTypes.shape({
    /** (comment: Comment) => void */
    deleteComment: PropTypes.func.isRequired,
    /** (message: string) => void */
    postComment: PropTypes.func.isRequired,
    /** (comment: Comment) => void */
    updateComment: PropTypes.func.isRequired,
  }).isRequired,
  commentDataRenderer: PropTypes.func,
  eventRenderer: PropTypes.func,
};

export default withUsers(
  connect(null, { ticketActivitiesSet, ticketParticipantsSet })(CommentThread),
);
