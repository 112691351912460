export const BULK_COPY_LIMIT = 500;
export const BULK_DELETE_LIMIT = 500;
export const BULK_EDIT_LIMIT = 10000;
export const BULK_EDIT_CLAUSE_LIMIT = 10000;
export const BULK_EXPORT_LIMIT = 250000;
export const BULK_MOVE_LIMIT = 2000;
export const BULK_RESTORE_LIMIT = 500;
export const DEFAULT_MAX_LIMIT = 50000;
export const MAX_DASHBOARD_NAME_LENGTH = 128;
export const MAX_SAVED_SEARCH_NAME_LENGTH = 128;
export const MAX_DOCUMENT_NAME_LENGTH = 256;
export const QUICK_SEARCH_DOCUMENT_COUNT = 10;
export const SEARCH_RESULTS_LIMIT = 50000;
export const EXCEL_EXPORT_SINGLE_FILE_DOC_COUNT_LIMIT = 750000;
export const FOLDER_SEARCH_VISIBLE_RESULTS = 30;
export const DASHBOARD_CHARTS_LIMIT = 15;
export const TEXT_DELIMITED_MULTISELECT_FILTER_LIMIT = 10000;
