import React from 'react';
import { components } from 'react-select';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { UI_SELECT_VALUE_CLOSE_ICON_TESTID } from '~/constants/testids';
import { FlexLayout, Icon, Text, Tooltip } from '~/ui';

import simpleSelectComponents from '../SingleSelect/components';

const SortableControl = SortableContainer(simpleSelectComponents.Control);
const SortableMultiValue = SortableElement(components.MultiValue);

const Control = (props) => {
  const { sortProps } = props.selectProps;
  return <SortableControl {...props} {...sortProps} />;
};

const MultiValue = (props) => {
  // Based on formal recommendation in: https://react-select.com/advanced#sortable-multiselect
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <SortableMultiValue {...props} innerProps={innerProps} />;
};

function MultiValueContainer(props) {
  const { errorLabel, tooltip } = props.data;
  return (
    <components.MultiValueContainer {...props}>
      <Tooltip arrow={false} content={tooltip} placement="bottom">
        <FlexLayout
          alignItems="center"
          bg={errorLabel ? 'red-100' : 'blue-100'}
          px={2}
          space={2}
          sx={{
            borderColor: errorLabel ? 'red-alpha-25' : 'blue-alpha-25',
            borderRadius: 'm',
            borderStyle: 'solid',
            borderWidth: 1,
            height: 'multi-select-chip-height',
          }}
        >
          {props.children}
        </FlexLayout>
      </Tooltip>
    </components.MultiValueContainer>
  );
}

function MultiValueLabel(props) {
  const { errorLabel, valueLabelOverride } = props.data;
  return (
    <components.MultiValueLabel {...props}>
      <FlexLayout alignItems="center" space={2}>
        {props.data.icon}
        <Text
          sx={{ color: errorLabel ? 'red-400' : 'blue-500' }}
          variant="xs-dense-medium"
          shouldTruncate
        >
          {errorLabel || valueLabelOverride || props.children}
        </Text>
      </FlexLayout>
    </components.MultiValueLabel>
  );
}

function MultiValueRemove(props) {
  const { errorLabel } = props.data;
  return (
    <components.MultiValueRemove {...props}>
      <Icon
        className={UI_SELECT_VALUE_CLOSE_ICON_TESTID}
        color={errorLabel ? 'red-400' : 'blue-500'}
        icon="close"
        size="xs"
      />
    </components.MultiValueRemove>
  );
}

export default {
  ...simpleSelectComponents,
  Control,
  MultiValue,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
};
