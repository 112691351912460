import { createActions } from '~/utils/redux';

export const {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
  integrationsSetManageSyncPairPageIndex,
  integrationsSetManageSyncPairPageSize,
  integrationsSetSyncPairs,
  integrationsAddSyncPair,
  integrationsRemoveSyncPair,
} = createActions('integrations', [
  'setActiveSyncPair',
  'setManageSyncPairStage',
  'setManageSyncPairPageIndex',
  'setManageSyncPairPageSize',
  'setSyncPairs',
  'addSyncPair',
  'removeSyncPair',
]);
