import { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'uinix-ui';

import { TEXT_DELIMITED_MULTISELECT_FILTER_LIMIT } from '~/constants/max_lengths';
import {
  Actions,
  getDistanceToWindow,
  Layout,
  Text,
  TextArea,
  types,
  useDebouncedState,
} from '~/eds';
import { useTrackSegment } from '~/hooks';

import { EnumValue } from '../types';

type Props = {
  fieldId: types.PilotId;
  values: EnumValue[];
  onBackClick?: () => void;
  onChange: (values: EnumValue[]) => void;
};
export const TextDelimitedEnumSetValue = ({
  fieldId,
  values: initialValues,
  onBackClick,
  onChange,
}: Props) => {
  const trackSegment = useTrackSegment();
  const theme = useTheme();
  const maxHUpperLimit = theme.sizes['textarea.max-height'] as number;

  const [debouncedState, values, setValues] = useDebouncedState<
    types.Nullable<string>
  >(initialValues.join('\n'));
  const [maxH, setMaxH] = useState<number | undefined>();
  const textAreaContainerRef = useRef<HTMLDivElement>(null);

  const isLimitExceeded = useMemo(() => {
    if (!debouncedState) return false;
    return (
      debouncedState.split('\n').length >
      TEXT_DELIMITED_MULTISELECT_FILTER_LIMIT
    );
  }, [debouncedState]);

  useEffect(() => {
    if (textAreaContainerRef && textAreaContainerRef.current) {
      setTimeout(() => {
        //Timeout is necessary because there is a delay to get the correct positioning, duo to how Tippy(Popover) works
        const { bottom } = getDistanceToWindow(textAreaContainerRef.current!);
        setMaxH(bottom < maxHUpperLimit ? bottom : maxHUpperLimit);
      }, 500);
    }
  }, [textAreaContainerRef]);

  const clear = () => {
    setValues('');
    onChange([]);
  };

  const onApply = () => {
    if (values) {
      const valuesArray = values
        .split('\n')
        .map((value) => value.trim())
        .filter((value) => !!value);

      onChange(valuesArray);
      trackSegment('textDelimitedFilterApplied', { fieldId });
    }
  };
  const actions: types.UserAction[] = [
    ...(onBackClick
      ? [{ text: 'Back', level: 'tertiary' as const, onClick: onBackClick }]
      : []),
    { text: 'Clear', level: 'tertiary', onClick: clear },
    {
      text: 'Apply',
      level: 'tertiary',
      onClick: onApply,
      disabled: isLimitExceeded || !values?.length,
    },
  ];

  return (
    <Layout variant="menu.container" w={250} direction="column">
      <div ref={textAreaContainerRef}>
        <TextArea
          name="filter-values"
          value={values}
          onChange={setValues}
          placeholder="Enter values"
          rows={6}
          maxH={maxH}
        />
      </div>
      {isLimitExceeded ? (
        <Text
          preset="error"
          variant="tiny-bold"
        >{`Maximum ${TEXT_DELIMITED_MULTISELECT_FILTER_LIMIT} values. Remove additional values to search`}</Text>
      ) : (
        <Text preset="description">
          {`Paste up to 10,000 line-separated values. Press Shift + Return to add a new line.`}
        </Text>
      )}
      <Layout alignSelf="flex-end">
        <Actions actions={actions} />
      </Layout>
    </Layout>
  );
};
