import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import EcFilterInput from '~/components/Shared/EcFilterInput';

import { getPinnedFilters } from '../../Modals/AddFilterModal/AddFilterModal.utils';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import AnalyzerFilterCategory from '../AnalyzerFilterCategory';
import { searchAreaOptions } from '../TextSearchForm/TextSearchForm.utils';
import styles from './AnalyzerFilters.styles';

class AnalyzerFilters extends Component {
  constructor(props) {
    super(props);

    this.handleOnSearchChange = this.handleOnSearchChange.bind(this);
    this.handleOnClearSearchClick = this.handleOnClearSearchClick.bind(this);
    this.handleFoldersChange = this.handleFoldersChange.bind(this);
    this.handleFilterOptionsChange = this.handleFilterOptionsChange.bind(this);
    this.handleAddNewEntity = this.handleAddNewEntity.bind(this);
    this.state = { query: '', currentModal: null, childCategories: {} };
  }

  handleOnSearchChange(searchValue) {
    this.setState({ query: searchValue, activeCategoryIndex: 0 }, () =>
      this.toggleChildCategories(!!searchValue),
    );
  }

  handleOnClearSearchClick() {
    this.setState({ query: '', activeCategoryIndex: 0 }, () =>
      this.toggleChildCategories(false),
    );
  }

  handleAddNewEntity(entityType, entity) {
    const { filters, updateFilters } = this.props;

    const newEntity =
      entityType === 'section'
        ? { entity: 'section', section: [] }
        : { ...entity, entity: entityType };
    const newFilters = [...filters];

    if (filters.length) {
      const operator = { entity: 'operator', operator: 'and' };
      newFilters.push(operator);
    }

    newFilters.push(newEntity);
    updateFilters(newFilters);
  }

  handleFoldersChange(locations) {
    const newFolders = [...this.state.folders];
    newFolders.push(locations);

    this.setState({ folders: newFolders.flat(Infinity) });
  }

  handleFilterOptionsChange(filterId) {
    this.props.updateFilterOptions(filterId);
  }

  toggleChildCategories(value) {
    Object.values(this.state.childCategories).forEach((child) =>
      child.toggleCategory(value),
    );
  }

  getFilteredCategories(categories) {
    const { query } = this.state;

    return categories.reduce((arr, category) => {
      const matchingFields = category.fields.filter((f) => {
        return f.label.toLowerCase().includes(query.toLowerCase());
      });

      if (matchingFields.length) {
        return [...arr, { ...category, fields: matchingFields }];
      }

      return arr;
    }, []);
  }

  renderTextSearchEntity() {
    const { classes } = this.props;
    const basicSearchCategory = {
      title: 'Basic Text Search',
      fields: searchAreaOptions,
      entityType: 'text_search',
    };
    const booleanSearchCategory = {
      title: 'Boolean Text Search',
      fields: [],
      entityType: 'bool_text_search',
    };

    return (
      <div className={classes.filterEntity}>
        <div className={classes.filterEntityTitle}>TEXT SEARCH</div>
        <AnalyzerFilterCategory
          category={basicSearchCategory}
          handleAddNewEntity={this.handleAddNewEntity}
          noPin
        />
        <AnalyzerFilterCategory
          category={booleanSearchCategory}
          handleAddNewEntity={this.handleAddNewEntity}
          noPin
        />
      </div>
    );
  }

  renderFoldersEntity() {
    const { classes } = this.props;

    const folderSearchCategory = {
      title: 'Filter by Folders',
      fields: [],
      entityType: 'folder',
    };

    return (
      <div className={classes.filterEntity}>
        <div className={classes.filterEntityTitle}>
          FOLDERS
          <div className={classes.filterEntitySubtitle}>
            Specify folders in which to run your search.
          </div>
        </div>
        <AnalyzerFilterCategory
          category={folderSearchCategory}
          handleAddNewEntity={this.handleAddNewEntity}
          noPin
        />
      </div>
    );
  }

  renderProvisionEntity() {
    const { classes, provisionOptions } = this.props;

    const category = {
      title: 'Provision Types',
      fields: provisionOptions,
      entityType: 'provision',
    };

    return (
      <div className={classes.filterEntity}>
        <div className={classes.filterEntityTitle}>CLAUSE LIBRARY</div>
        <AnalyzerFilterCategory
          category={category}
          key={category.title}
          handleAddNewEntity={this.handleAddNewEntity}
          noPin
        />
      </div>
    );
  }

  renderDataFieldsEntity() {
    const { classes, filterOptions } = this.props;
    const { query, childCategories } = this.state;

    const totalFilteredCategories = [
      {
        title: 'Pinned',
        fields: getPinnedFilters(filterOptions),
        expanded: true,
      },
      ...filterOptions,
    ];
    const filteredCategories = this.getFilteredCategories(
      totalFilteredCategories,
    );
    const fieldResultsCount = filteredCategories.reduce(
      (prev, curr) => [...prev, ...curr.fields],
      [],
    ).length;

    return (
      <div className={classes.filterEntity}>
        <div className={classes.filterEntityTitle}>DATA FIELDS</div>
        <div className={classes.filterSearch}>
          <EcFilterInput
            ariaDescribedby="analyzer-filters-found-status"
            value={query}
            onChange={(query) => this.handleOnSearchChange(query)}
            onClearSearch={this.handleOnClearSearchClick}
            placeholder="Search for data field..."
          />
        </div>
        <div
          className="screenReaderText"
          aria-live="assertive"
          id="analyzer-filters-found-status"
          role="status"
        >
          {!query.length
            ? null
            : fieldResultsCount
            ? `${fieldResultsCount} item(s) found`
            : 'No items found'}
        </div>
        {filteredCategories.map((category) => (
          <AnalyzerFilterCategory
            category={category}
            key={category.title}
            handleFilterOptionsChange={this.handleFilterOptionsChange}
            handleAddNewEntity={this.handleAddNewEntity}
            onRef={(ref, key) => {
              if (ref) {
                childCategories[key] = ref;
              } else {
                delete childCategories[key];
              }
            }}
          />
        ))}
      </div>
    );
  }

  renderLoadingContainer() {
    const { classes, loadingError, loadAnalyzerFiltersData } = this.props;

    let containerContent = <LoadingSpinner size="medium" />;

    if (loadingError) {
      containerContent = (
        <div className={classes.filtersErrorContainer}>
          <div className={classes.filtersErrorMessage}>
            There's been an error with loading filters data.
          </div>
          <button
            className={classes.filtersErrorAction}
            onClick={() => loadAnalyzerFiltersData()}
          >
            Retry
          </button>
        </div>
      );
    }

    return <div className={classes.loadingContainer}>{containerContent}</div>;
  }

  render() {
    const { classes, loading, loadingError } = this.props;

    return (
      <div id="analyzer-filters-container" className={classes.analyzerFilters}>
        <div className={classes.filtersHeader}>Filters</div>
        <div className={classes.filterEntities}>
          {(loading || loadingError) && this.renderLoadingContainer()}
          {this.renderDataFieldsEntity()}
          <hr className={classes.entitySeparator} />
          {this.renderFoldersEntity()}
          <hr className={classes.entitySeparator} />
          {this.renderProvisionEntity()}
          <hr className={classes.entitySeparator} />
          {this.renderTextSearchEntity()}
        </div>
      </div>
    );
  }
}

AnalyzerFilters.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingError: PropTypes.bool.isRequired,
  filters: PropTypes.array.isRequired,
  filterOptions: PropTypes.array.isRequired,
  provisionOptions: PropTypes.array.isRequired,
  loadAnalyzerFiltersData: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  updateFilterOptions: PropTypes.func.isRequired,
};

export default injectSheet(styles)(AnalyzerFilters);
