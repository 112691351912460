export default `
<svg
  aria-hidden="true"
  aria-label="push-pin"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.2656 15H7.01562C6.70312 15 6.39062 14.875 6.20312 14.625C6.01562 14.375 5.95312 14.0625 6.01562 13.7812L6.23438 13C6.54688 11.6875 7.32812 10.625 8.35938 9.90625L8.70312 5.5H7.76562C7.60938 5.5 7.48438 5.46875 7.35938 5.40625C7.14062 5.28125 7.01562 5.03125 7.01562 4.75C7.01562 4.34375 7.32812 4 7.76562 4H16.2656C16.6719 4 17.0156 4.34375 17.0156 4.75C17.0156 5.03125 16.8594 5.28125 16.6406 5.40625C16.5156 5.46875 16.3906 5.5 16.2656 5.5H15.2969L15.6406 9.90625C16.6719 10.625 17.4531 11.6875 17.7656 13L17.9844 13.7812C18.0469 14.0625 17.9844 14.375 17.7969 14.625C17.6094 14.875 17.2969 15 17.0156 15H12.7656V19.25C12.7656 19.6875 12.4219 20 11.9844 20C11.5781 20 11.2344 19.6875 11.2344 19.25L11.2656 15ZM9.82812 10.75L9.20312 11.1562C8.48438 11.6562 7.92188 12.4375 7.67188 13.3438L7.64062 13.5H11.2656V10.75C11.2656 10.3438 11.5781 10 12.0156 10C12.4219 10 12.7656 10.3438 12.7656 10.75V13.5H16.3594L16.3281 13.3438C16.0781 12.4375 15.5156 11.6562 14.7969 11.1562L14.1719 10.75L13.7969 5.5H10.2031L9.82812 10.75Z"
    fill="currentcolor"
  />
</svg>
`;
