const styles = {
  loadingContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  modalHelpText: {
    marginTop: '8px',
    width: '300px',
    height: '20px',
    fontSize: '14px',
    color: '#9a9a9a',
  },
  folderTree: {
    maxHeight: '63vh',
    overflowY: 'scroll',
  },
};

export default styles;
