export function updateSectionField(sections, sectionId, field) {
  const newSections = [...sections];

  const section = newSections.find((s) => s.id === sectionId);

  if (!section) return sections;

  const fieldIndex = section.doc_info_fields
    ?.map((f) => f.id)
    .indexOf(field.id);

  if (fieldIndex < 0) return sections;

  section.doc_info_fields.splice(fieldIndex, 1, field);

  return newSections;
}
