import React from 'react';
import { connect } from 'react-redux';

import { ticketReviewerSetViewType } from '~/actions/ticketReviewer';
import { TicketReviewerViewType } from '~/enums';
import { FlexLayout, Icon, Text } from '~/ui';

const viewTypeOptions = [
  {
    value: TicketReviewerViewType.AllIssues,
    label: 'All Issues',
  },
  {
    value: TicketReviewerViewType.AssignedToMe,
    label: 'Assigned to me',
  },
];

// TODO: this should be a core UI component called ButtonGroup and uses Button components with button props.
function ViewTypeToggle({
  // connected
  ticketReviewerSetViewType,
  viewType,
}) {
  return (
    <FlexLayout p={6} sx={{ borderBottom: 'border' }}>
      {viewTypeOptions.map(({ value, label }, i) => {
        const isSelected = value === viewType;
        const optionStyle =
          i === 0
            ? {
                borderBottomLeftRadius: 'm',
                borderTopLeftRadius: 'm',
              }
            : {
                borderBottomRightRadius: 'm',
                borderTopRightRadius: 'm',
              };

        return (
          <FlexLayout
            key={value}
            alignItems="center"
            flex="0 0 50%"
            justifyContent="center"
            mr="-1px"
            p={2}
            space={3}
            sx={{
              backgroundColor: isSelected ? 'gray-100' : 'white',
              border: 'border',
              cursor: 'pointer',
              ...optionStyle,
            }}
            onClick={() => ticketReviewerSetViewType(value)}
          >
            {isSelected && <Icon icon="checkmark" size="xs" />}
            <Text
              color={isSelected ? 'gray-800' : 'gray-700'}
              variant="xs-dense-bold"
            >
              {label}
            </Text>
          </FlexLayout>
        );
      })}
    </FlexLayout>
  );
}

const mapStateToProps = ({ ticketReviewer }) => ({
  viewType: ticketReviewer.viewType,
});

export default connect(mapStateToProps, { ticketReviewerSetViewType })(
  ViewTypeToggle,
);
