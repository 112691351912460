import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import { TicketStatusType } from '~/enums';
import { FlexLayout } from '~/ui';

import StatusIcon from './StatusIcon';

const { Approved, Pending, Rejected } = TicketStatusType;

const styles = {
  button: {
    cursor: 'pointer',
    '&:hover': {
      opacity: (props) => (props.disabled ? undefined : '0.8'),
    },
  },
  disabled: {
    cursor: 'auto',
    opacity: '0.3',
    userSelect: 'none',
  },
};

const colors = {
  default: 'gray-900',
  approved: 'green-500',
  inactive: 'gray-900',
  rejected: 'red-600',
};

function ApproveReject({
  disabled = false,
  status = Pending,
  onUpdateStatus,
  // injected
  classes,
}) {
  function StatusButton({ color, status, statusValue }) {
    const selected = statusValue === status;

    const className = classnames({
      [classes.button]: true,
      [classes.disabled]: disabled || selected,
    });

    function handleUpdateStatus() {
      if (!disabled && !selected) {
        onUpdateStatus(statusValue);
      }
    }

    return (
      <FlexLayout
        alignItems="center"
        className={className}
        space={3}
        sx={{
          width: '100%',
          justifyContent: 'center',
          border: '1px solid #ccc',
          borderRadius: '3px',
        }}
        onClick={handleUpdateStatus}
      >
        <FlexLayout space={2} pt={2} pb={2} pl={10} pr={10}>
          <StatusIcon color={color} status={statusValue} />
        </FlexLayout>
      </FlexLayout>
    );
  }

  let approveColor = colors.default;
  let rejectColor = colors.default;

  switch (status) {
    case Approved:
      approveColor = colors.approved;
      rejectColor = colors.rejected;
      break;
    case Rejected:
      approveColor = colors.inactive;
      rejectColor = colors.rejected;
      break;
    default:
      break;
  }

  return (
    <FlexLayout space={8} justifyContent="space-between">
      <StatusButton
        color={approveColor}
        statusValue={Approved}
        status={status}
      />
      <StatusButton
        color={rejectColor}
        statusValue={Rejected}
        status={status}
      />
    </FlexLayout>
  );
}

ApproveReject.propTypes = {
  disabled: PropTypes.bool,
  status: PropTypes.oneOf([Approved, Pending, Rejected]),
  onUpdateStatus: PropTypes.func.isRequired,
};

export default injectSheet(styles)(ApproveReject);
