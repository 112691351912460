import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { Card, Icon } from '~/eds';

import EcInput from '../../../Shared/EcInput/EcInput';
import EcMultipleSelectValue from '../../../Shared/EcMultipleSelectValue/EcMultipleSelectValue';
import EcMultiSelect from '../../../Shared/EcMultiSelect/EcMultiSelect';
import styles from './PermissionSettings.styles';

const mappedPlaceholders = {
  whitelistedUsers: 'whitelisted users',
  whitelistedEmailList: 'whitelisted email list',
  whitelistedDomainList: 'whitelisted domain list',
};

class PermissionSettings extends Component {
  constructor(props) {
    super(props);

    this.handleRadioChange = this.handleRadioChange.bind(this);

    this.state = {
      usersNameDropdownList: [],
      usersEmailList: [],
      domainList: [],
      selectedOption: undefined,
      showUserMultiSelect: false,
      editData: {
        whitelistedUsers: [],
        whitelistedEmailList: [],
        whitelistedDomainList: [],
      },
    };
  }

  componentDidMount() {
    const {
      whitelistedUsers,
      whitelistedEmailList,
      whitelistedDomainList,
      userNamesDropdownList,
    } = this.props;

    this.setState({
      whitelistedUsers,
      whitelistedEmailList,
      whitelistedDomainList,
      userNamesDropdownList,
      selectedOption: whitelistedUsers.length > 0 ? 'specific' : 'all',
      editData: {
        whitelistedUsers,
        whitelistedEmailList,
        whitelistedDomainList,
      },
    });
  }

  handleRadioChange(selectedOption) {
    const { handlePermissionChange } = this.props;

    this.setState({ selectedOption });
    handlePermissionChange(
      { ...this.state.editData },
      selectedOption === 'specific',
    );
  }

  renderItem(item, name) {
    const { handlePermissionChange } = this.props;
    const { selectedOption } = this.state;

    const presentValue = this.state.editData[name] || [];
    const options = item
      ? item.map((val) => ({
          label: val.display_value,
          value: val.value,
          key: val.item,
        }))
      : [];
    const placeholderName = mappedPlaceholders[name];
    const isCreatable = name !== 'whitelistedUsers';
    const isDomainList = name === 'whitelistedDomainList';

    return (
      <div>
        <EcMultiSelect
          value={presentValue}
          options={options}
          onChange={(newItems) => {
            const newState = {};
            newState[name] = isDomainList
              ? newItems.map((item) => ({
                  label: item.label.replace('@', ''),
                  value: item.value.replace('@', ''),
                }))
              : newItems;
            this.setState({
              editData: { ...this.state.editData, ...newState },
            });
            handlePermissionChange(
              { ...this.state.editData, ...newState },
              selectedOption === 'specific',
            );
          }}
          width="30%"
          placeholder={`Type in a ${placeholderName}...`}
          noOptionsMessage={`No ${placeholderName} found`}
          isCreatable={isCreatable}
        />
        {this.renderItemValues(name)}
      </div>
    );
  }

  renderItemValues(name) {
    const { classes, handlePermissionChange } = this.props;
    const { selectedOption } = this.state;
    let presentValues = this.state.editData;
    presentValues = presentValues[name];

    return (
      <div className={classes.multipleValuesContainer}>
        <Icon icon="arrow-forward" />
        <div className={classes.multipleValues}>
          {presentValues.length ? (
            presentValues.map((value) => (
              <EcMultipleSelectValue
                label={value.label}
                key={value.value}
                closeHandler={() => {
                  const newState = {};
                  const newMultiState = [...this.state.editData[name]];
                  newState[name] = newMultiState.filter(
                    (multiValue) => multiValue.value !== value.value,
                  );
                  this.setState({
                    editData: { ...this.state.editData, ...newState },
                  });
                  handlePermissionChange(
                    { ...this.state.editData, ...newState },
                    selectedOption === 'specific',
                  );
                }}
              />
            ))
          ) : (
            <span className={classes.multipleValuesEmpty}>
              No values selected...
            </span>
          )}
        </div>
      </div>
    );
  }

  renderMultiSelect(item, name) {
    const { classes } = this.props;

    const multiSelectMenu = this.renderItem(item, name);
    return <div className={classes.overviewInformation}>{multiSelectMenu}</div>;
  }

  render() {
    const { classes } = this.props;
    const {
      selectedOption,
      usersEmailList,
      userNamesDropdownList,
      domainList,
      whitelistedEmailList,
    } = this.state;

    if (!whitelistedEmailList) {
      return null;
    }
    return (
      <Card
        header={{
          title: 'Permissions and Whitelisting',
        }}
        mode="bordered"
      >
        <Fragment>
          <div className={classes.headerUsers}>WHITELISTED USERS</div>
          <div className={classes.allEmailDocChoice}>
            <EcInput
              type="radio"
              input={{
                value: 'all',
                onChange: (e) => {
                  this.handleRadioChange(e.target.value);
                },
                checked: selectedOption === 'all',
              }}
              width="25px"
              height="17px"
            />
            Allow all organization users to email documents to Evisort
          </div>
          <div className={classes.selectEmailDocChoice}>
            <EcInput
              type="radio"
              input={{
                value: 'specific',
                onChange: (e) => {
                  this.handleRadioChange(e.target.value);
                },
                checked: selectedOption === 'specific',
              }}
              width="25px"
              height="17px"
            />
            Allow only specific organization users to email documents to Evisort
          </div>
          {selectedOption === 'specific'
            ? this.renderMultiSelect(userNamesDropdownList, 'whitelistedUsers')
            : null}

          <div className={classes.headerEmails}>WHITELISTED EMAILS</div>
          {this.renderMultiSelect(usersEmailList, 'whitelistedEmailList')}

          <div className={classes.domainsContainer}>
            <div className={classes.headerEmails}>WHITELISTED DOMAINS</div>
            <div className={classes.domainInfo}>
              Any email address from these domains will be able to email
              documents to Evisort.
            </div>
            {this.renderMultiSelect(domainList, 'whitelistedDomainList')}
          </div>
        </Fragment>
      </Card>
    );
  }
}

export default injectSheet(styles)(PermissionSettings);
