import Configuration from './configuration';
import createService from './createService';
import { JsonApiError } from './errors';

export const clouseau = createService({
  shouldRetryOn401: true,
  url: `${Configuration.clouseauEndpoint}/api/v1`,
});

export const clouseauV2 = createService({
  shouldRetryOn401: true,
  url: `${Configuration.clouseauEndpoint}/api/v2`,
});

export const pilot = createService({
  shouldRetryOn401: true,
  url: `${Configuration.pilotEndpoint}/api/v2`,
});

export const pilotV3 = createService({
  shouldRetryOn401: true,
  url: `${Configuration.pilotEndpoint}/api/v3`,
});

export const publicApi = createService({
  shouldRetryOn401: true,
  url: `${Configuration.publicApiEndpoint}/v1`,
});

export const everest = createService({
  shouldRetryOn401: true,
  url: `${Configuration.everestEndpoint}/`,
});

export const auth = createService({
  url: `${Configuration.authEndpoint}/v1`,
  refresh_token: true,
});

export const authExt = createService({
  shouldRetryOn401: true,
  url: `${Configuration.authEndpoint}/ext`,
});

export const aegis = createService({
  shouldRetryOn401: true,
  url: `${Configuration.authEndpoint}/v1`,
});

export const weaver = createService({
  shouldRetryOn401: true,
  url: `${Configuration.weaverEndpoint}/`,
});

export const genAi = createService({
  shouldRetryOn401: true,
  url: `${Configuration.genAiEndpoint}/`,
});

export const muse = createService({
  shouldRetryOn401: true,
  url: `${Configuration.museEndpoint}/`,
});

export const sylvanus = createService({
  shouldRetryOn401: true,
  url: `${Configuration.sylvanusEndpoint}/api/v1`,
});

export const eviSearch = createService({
  shouldRetryOn401: true,
  url: `${Configuration.evisearchEndpoint}/`,
});

export const wopi = createService({
  shouldRetryOn401: true,
  url: Configuration.wopiEndpoint,
});

export const codeCash = createService({
  shouldRetryOn401: true,
  url: `${Configuration.codeCash}/api/v1`,
});

export const earlyAccess = createService({
  shouldRetryOn401: true,
  url: `${Configuration.earlyAccessEndpoint}/v1`,
});

export const atlas = createService({
  shouldRetryOn401: true,
  url: `${Configuration.atlas}/api/v1`,
  errorHandler: (error) => {
    throw new JsonApiError(error.response?.data?.errors);
  },
});

export const rolodex = createService({
  shouldRetryOn401: true,
  url: `${Configuration.rolodexEndpoint}/api/v1`,
});

export const courier = createService({
  shouldRetryOn401: true,
  url: `${Configuration.courier}/api/v1`,
});

export const managedEntities = createService({
  shouldRetryOn401: true,
  url: `${Configuration.managedEntities}/api/v1`,
});
