export default `
<svg
  aria-hidden="true"
  aria-label="plus-circle"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.25 14.75V12.75H9.25C8.8125 12.75 8.5 12.4375 8.5 12C8.5 11.5938 8.8125 11.25 9.25 11.25H11.25V9.25C11.25 8.84375 11.5625 8.5 12 8.5C12.4062 8.5 12.75 8.84375 12.75 9.25V11.25H14.75C15.1562 11.25 15.5 11.5938 15.5 12C15.5 12.4375 15.1562 12.75 14.75 12.75H12.75V14.75C12.75 15.1875 12.4062 15.5 12 15.5C11.5625 15.5 11.25 15.1875 11.25 14.75ZM20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4C16.4062 4 20 7.59375 20 12ZM12 5.5C8.40625 5.5 5.5 8.4375 5.5 12C5.5 15.5938 8.40625 18.5 12 18.5C15.5625 18.5 18.5 15.5938 18.5 12C18.5 8.4375 15.5625 5.5 12 5.5Z"
    fill="currentcolor"
  />
</svg>
`;
