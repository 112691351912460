import {
  DataFieldType,
  DateFormatType,
  WorkflowFieldType,
  WorkflowTicketRoleType,
} from '~/enums';
import { capitalize } from '~/utils/strings';

export const fieldTypeLabels = {
  [WorkflowFieldType.Address]: 'Address',
  [WorkflowFieldType.Boolean]: 'Boolean',
  [WorkflowFieldType.Checkbox]: 'Checkbox',
  [WorkflowFieldType.Date]: 'Date',
  [WorkflowFieldType.DateSigned]: 'Date Signed',
  [WorkflowFieldType.Department]: 'Department',
  [WorkflowFieldType.Email]: 'Email',
  [WorkflowFieldType.File]: 'Attachments',
  [WorkflowFieldType.Initials]: 'Initials',
  [WorkflowFieldType.LongResponse]: 'Text (Paragraph)',
  [WorkflowFieldType.MonetaryValue]: 'Monetary Value',
  [WorkflowFieldType.MultiSelect]: 'Dropdown (Choose multiple)',
  [WorkflowFieldType.Number]: 'Number',
  [WorkflowFieldType.Percentage]: 'Percentage',
  [WorkflowFieldType.Radio]: 'Radio',
  [WorkflowFieldType.ShortResponse]: 'Text (Single line)',
  [WorkflowFieldType.Signature]: 'Signature',
  [WorkflowFieldType.SingleSelect]: 'Dropdown (Choose one)',
  [WorkflowFieldType.TimePeriod]: 'Time Period',
  [WorkflowFieldType.User]: 'User',
};

export const fieldTypesToDataFieldTypes = {
  [WorkflowFieldType.Address]: [],
  [WorkflowFieldType.Boolean]: [],
  [WorkflowFieldType.Checkbox]: [],
  [WorkflowFieldType.Date]: [DataFieldType.DATE],
  [WorkflowFieldType.Department]: [
    DataFieldType.STRING,
    DataFieldType.ARRAY_SINGLE,
    DataFieldType.ARRAY_MULTIPLE,
  ],
  [WorkflowFieldType.Email]: [DataFieldType.STRING],
  [WorkflowFieldType.File]: [DataFieldType.ATTACHMENT],
  [WorkflowFieldType.Initials]: [],
  [WorkflowFieldType.LongResponse]: [DataFieldType.TEXT_AREA],
  [WorkflowFieldType.MonetaryValue]: [],
  [WorkflowFieldType.MultiSelect]: [
    DataFieldType.BOOLEAN,
    DataFieldType.ARRAY_SINGLE,
    DataFieldType.ARRAY_MULTIPLE,
  ],
  [WorkflowFieldType.Number]: [DataFieldType.NUMBER],
  [WorkflowFieldType.Percentage]: [DataFieldType.NUMBER],
  [WorkflowFieldType.Radio]: [
    DataFieldType.BOOLEAN,
    DataFieldType.ARRAY_SINGLE,
    DataFieldType.ARRAY_MULTIPLE,
  ],
  [WorkflowFieldType.ShortResponse]: [
    DataFieldType.STRING,
    DataFieldType.ARRAY_MULTIPLE,
  ],
  [WorkflowFieldType.Signature]: [],
  [WorkflowFieldType.SingleSelect]: [
    DataFieldType.BOOLEAN,
    DataFieldType.ARRAY_SINGLE,
    DataFieldType.ARRAY_MULTIPLE,
  ],
  [WorkflowFieldType.TimePeriod]: [],
  [WorkflowFieldType.User]: [],
};

export const contractFieldDefinitions = [
  {
    key: 'governingLaw',
    entity: 'contract',
    fieldType: WorkflowFieldType.SingleSelect,
    label: 'Governing Law',
  },
  {
    key: 'agreementDate',
    entity: 'contract',
    fieldType: WorkflowFieldType.Date,
    label: 'Agreement Date',
  },
  {
    key: 'masterAgreementDate',
    entity: 'contract',
    fieldType: WorkflowFieldType.Date,
    label: 'Master Agreement Date',
  },
];

export const partyFieldDefinitions = [
  {
    key: 'name',
    entity: 'party',
    fieldType: WorkflowFieldType.SingleSelect,
    label: 'Name',
    isEsignatureTag: false,
    isEsignatureTagOptional: false,
  },
  {
    key: 'address',
    entity: 'party',
    fieldType: WorkflowFieldType.Address,
    label: 'Address',
    isEsignatureTag: false,
    isEsignatureTagOptional: false,
  },
  {
    key: 'phone',
    entity: 'party',
    fieldType: WorkflowFieldType.ShortResponse,
    label: 'Contact Phone',
    isEsignatureTag: false,
    isEsignatureTagOptional: false,
  },
  {
    key: 'contactName',
    entity: 'party',
    fieldType: WorkflowFieldType.ShortResponse,
    label: 'Contact Name',
    isEsignatureTag: false,
    isEsignatureTagOptional: false,
  },
  {
    key: 'text',
    entity: 'party',
    fieldType: WorkflowFieldType.LongResponse,
    label: 'Text',
    isEsignatureTag: false,
    isEsignatureTagOptional: false,
  },
];

export const signerFieldDefinitions = [
  {
    key: 'email',
    entity: 'signer',
    fieldType: WorkflowFieldType.Email,
    isRequired: true,
    label: 'Email',
    isEsignatureTag: false,
    isEsignatureTagOptional: false,
  },
  {
    key: 'initials',
    entity: 'signer',
    fieldType: WorkflowFieldType.Initials,
    label: 'Signer Initials',
    isEsignatureTag: true,
    isEsignatureTagOptional: false,
  },
  {
    key: 'name',
    entity: 'signer',
    fieldType: WorkflowFieldType.ShortResponse,
    isRequired: true,
    label: 'Name',
    isEsignatureTag: false,
    isEsignatureTagOptional: false,
  },
  {
    key: 'signature',
    entity: 'signer',
    fieldType: WorkflowFieldType.Signature,
    isRequired: true,
    label: 'Signature',
    isEsignatureTag: true,
    isEsignatureTagOptional: false,
  },
  {
    key: 'signDate',
    entity: 'signer',
    fieldType: WorkflowFieldType.DateSigned,
    label: 'Date Signed',
    isEsignatureTag: true,
    isEsignatureTagOptional: false,
  },
  {
    key: 'title',
    entity: 'signer',
    fieldType: WorkflowFieldType.ShortResponse,
    label: 'Title',
    isEsignatureTag: false,
    isEsignatureTagOptional: false,
  },
  {
    key: 'text',
    entity: 'signer',
    fieldType: WorkflowFieldType.LongResponse,
    label: 'Text',
    isEsignatureTag: false,
    isEsignatureTagOptional: false,
  },
];

export const valueKeys = {
  [WorkflowFieldType.Address]: {
    COUNTRY: 'country',
    STREET_1: 'street_1',
    STREET_2: 'street_2',
    CITY: 'city',
    STATE: 'state',
    ZIP_CODE: 'zip_code',
  },
  [WorkflowFieldType.Boolean]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.Checkbox]: {
    VALUE: 'value',
    OTHER_VALUE: 'other_value',
  },
  [WorkflowFieldType.Date]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.Department]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.Email]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.File]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.LongResponse]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.MonetaryValue]: {
    AMOUNT: 'amount',
    CURRENCY: 'currency',
  },
  [WorkflowFieldType.MultiSelect]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.Number]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.Percentage]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.Radio]: {
    VALUE: 'value',
    OTHER_VALUE: 'other_value',
  },
  [WorkflowFieldType.ShortResponse]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.SingleSelect]: {
    VALUE: 'value',
  },
  [WorkflowFieldType.TimePeriod]: {
    PERIOD: 'period',
    AMOUNT: 'amount',
  },
  [WorkflowFieldType.User]: {
    VALUE: 'value',
  },
};

export const customSettingsKeys = {
  [WorkflowFieldType.Address]: {
    DOCUMENT_PLACEMENT: 'document_placement',
  },
  [WorkflowFieldType.Checkbox]: {
    ENABLE_OTHER_OPTION: 'enable_other_option',
    EXCLUDE_OTHER_OPTION_IN_DATA_MAPPING:
      'exclude_other_option_in_data_mapping',
  },
  [WorkflowFieldType.Date]: {
    FORMAT: 'format',
  },
  [WorkflowFieldType.Department]: {
    RULE: 'rule',
    DEPARTMENTS: 'departments',
    ALLOW_MULTIPLE: 'allow_multiple',
  },
  [WorkflowFieldType.Email]: {
    EMAIL_TYPE: 'email_type',
  },
  [WorkflowFieldType.File]: {
    ALLOWED_FILE_TYPE_RULE: 'allowed_file_type_rule',
    ALLOWED_FILE_TYPES: 'allowed_file_types',
    FILE_LIMIT: 'file_limit',
    MIN_FILE_COUNT: 'min_file_count',
  },
  [WorkflowFieldType.MonetaryValue]: {
    CURRENCY_RULE: 'currency_rule',
    CURRENCY_LIST: 'currency_list',
    INCLUDE_SYMBOL_IN_DOCUMENT: 'include_symbol_in_document',
  },
  [WorkflowFieldType.MultiSelect]: {
    ENABLE_CREATE_OPTIONS: 'enable_create_options',
  },
  [WorkflowFieldType.Number]: {
    ALLOW_DECIMALS: 'allow_decimals',
  },
  [WorkflowFieldType.Percentage]: {
    ALLOW_DECIMALS: 'allow_decimals',
    INCLUDE_SYMBOL_IN_DOCUMENT: 'include_symbol_in_document',
    MAX: 'max',
    MIN: 'min',
    OPERATOR: 'operator',
  },
  [WorkflowFieldType.Radio]: {
    ENABLE_OTHER_OPTION: 'enable_other_option',
    EXCLUDE_OTHER_OPTION_IN_DATA_MAPPING:
      'exclude_other_option_in_data_mapping',
  },
  [WorkflowFieldType.SingleSelect]: {
    ENABLE_CREATE_OPTIONS: 'enable_create_options',
  },
  [WorkflowFieldType.User]: {
    RULE: 'rule',
    USERS: 'users',
    ALLOW_MULTIPLE: 'allow_multiple',
  },
};

export const customSettingsOptionValues = {
  [WorkflowFieldType.Address]: {
    DOCUMENT_PLACEMENT: {
      MULTIPLE_LINES: 'multiple_lines',
      SINGLE_LINE: 'single_line',
    },
  },
  [WorkflowFieldType.Date]: {
    FORMAT: DateFormatType,
  },
  [WorkflowFieldType.Department]: {
    RULE: {
      ALL: 'all',
      ALL_EXCEPT: 'all_except',
      SPECIFIC: 'specific',
    },
  },
  [WorkflowFieldType.Email]: {
    EMAIL_TYPE: {
      // ANOTHER_FIELD: 'another_field', // to be supported in the future
      MANUAL: 'manual',
      NONE: 'none',
      SUBMITTER: 'submitter',
    },
  },
  [WorkflowFieldType.File]: {
    FILE_LIMIT: {
      SINGLE: 'single',
      MULTIPLE: 'multiple',
      UNLIMITED: 'unlimited',
    },
    FILE_TYPE_RULE: {
      ANY: 'any',
      ANY_EXCEPT: 'any_except',
      SPECIFIC: 'specific',
    },
  },
  [WorkflowFieldType.MonetaryValue]: {
    CURRENCY_RULE: {
      ANY: 'any',
      ANY_EXCEPT: 'any_except',
      SPECIFIC: 'specific',
    },
  },
  [WorkflowFieldType.Percentage]: {
    OPERATOR: {
      BETWEEN: 'between',
      GREATER: 'greater',
      GREATER_OR_EQUAL: 'greater_or_equal',
      LESS: 'less',
      LESS_OR_EQUAL: 'less_or_equal',
    },
  },
  [WorkflowFieldType.User]: {
    RULE: {
      ALL: 'all',
      ALL_EXCEPT: 'all_except',
      SPECIFIC: 'specific',
    },
  },
};

export const timePeriodFieldOptions = [
  'second',
  'minute',
  'hour',
  'day',
  'week',
  'month',
  'year',
].map((value) => {
  return { value, label: `${capitalize(value)}(s)` };
});

export const timePeriodFieldDefaultOptions = timePeriodFieldOptions.filter(
  (opt) => ['hour', 'day', 'week', 'month', 'year'].includes(opt.value),
);

export const workflowTicketRoleOptions = [
  {
    value: WorkflowTicketRoleType.Creator,
    label: 'Ticket Submitter',
    leftIcon: 'signature',
  },
];
