import { Option, Workflow } from '~/types';

export const mapVersionCell = (
  workflow: Workflow,
  onClick: (option: Option<string>) => void,
) => ({
  name: 'versions',
  options: [...workflow.versions]
    // the versions need to be sorted by newest first
    .sort((a, b) => b.number - a.number)
    .map((version) => ({
      label:
        version.number === 0
          ? 'Original'
          : `Version ${version.number}` +
            (version.number === workflow.versionNumber ? ' (Current)' : ''),
      value: version.id,
      description: `${version.totalTickets} tickets (${version.activeTickets} active)`,
    })),
  onClick,
  text:
    workflow.versions.length > 1
      ? `${workflow.versions.length} versions`
      : 'Current',
});
