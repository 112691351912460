export const OCCURRENCE_PERIOD_OPTIONS = [
  { value: 'Hour', label: 'Hour(s)' },
  { value: 'Day', label: 'Day(s)' },
  { value: 'Week', label: 'Week(s)' },
];

export const OCCURRENCE_END_TIMESPAN_OPTIONS = [
  { value: 'never', label: 'Never' },
  { value: 'on_a_specific_date', label: 'On a specific date' },
  { value: 'number_of_occurrences', label: '# of occurrences' },
];

export const DATE_PERIOD_OPTIONS = [
  { value: 'Day', label: 'Day(s)' },
  { value: 'Week', label: 'Week(s)' },
  { value: 'Month', label: 'Month(s)' },
  { value: 'Year', label: 'Year(s)' },
];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
