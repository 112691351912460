export const UploadStatusTooltip = {
  COMPLETED: 'Your document was uploaded and processed successfully',
  QUEUED:
    'Your document was uploaded successfully and has been placed in line for processing, which may take several minutes',
  PROCESSING:
    'Your document is being processed and should be available shortly',
  UPLOAD_FAILED:
    'Your document was not processed due to a system error. Please try uploading again later or contact support.',
  UPLOAD_FILETYPE_FAILURE:
    'Your document was not uploaded because its file type is not supported.',
  UPLOADING: 'Your document is uploading',
  OCR_FAILURE:
    'Your document was not processed due to a system error. Please try uploading again later or contact support.',
  ALGORITHM_FAILURE:
    'Your document was not processed due to a system error. Please try uploading again later or contact support.',
};
