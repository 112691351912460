import React, { memo } from 'react';

import { ContentContainer } from '~/eds';

export const UnsupportedDocumentViewer = memo(() => {
  return (
    <ContentContainer
      placeholderContent={{
        icon: 'status-warning',
        title: 'Unsupported document type',
        message: 'This document type is not supported.',
      }}
    />
  );
});
