import { groupBy } from 'lodash';
import React, { useMemo, useState } from 'react';

import { Box, Layout, RadioGroups, SearchInput, Text } from '~/eds';
import { api } from '~/redux';
import { GroupOrderFieldIds } from '~/redux/api/methods/searchV3';
import { Nullable, SearchFilter } from '~/types';

type FilterOption = {
  label: string;
  value: string;
};

type GroupOrderFilter = {
  title: string;
  options: FilterOption[];
};

type Props = {
  onFilterSelect: (filterId: string) => void;
};

const SelectAddFilter = ({ onFilterSelect }: Props) => {
  const [filterId, setFilterId] = useState<string>('');
  const [search, setSearch] = useState<Nullable<string>>();

  const { data: searchFilters } = api.endpoints.getFilters.useQuery(undefined);
  const { data: sections } = api.endpoints.getFilterSections.useQuery(
    undefined,
  );

  const groupedFilters = groupBy(searchFilters, (filter) => filter.section);
  const groupsOrder = useMemo(
    () => sections?.fieldGroups.map((group: GroupOrderFieldIds) => group.id),
    [sections?.fieldGroups],
  );
  const titlecaseGroupsOrder = useMemo(
    () =>
      groupsOrder?.map((name: string) =>
        name === 'More filters' ? 'More Filters' : name,
      ),
    [groupsOrder],
  );

  const mapOptionItems = (field: SearchFilter) => {
    return {
      label: field.label,
      value: field.id,
    };
  };

  const orderedGroups = titlecaseGroupsOrder?.map((groupName: string) => {
    return {
      title: groupName,
      options: groupedFilters[groupName]?.map(mapOptionItems) || [],
    };
  });

  const handleFilterSelect = (value: any) => {
    setFilterId(value);
    onFilterSelect(value);
  };

  const searchResults = orderedGroups
    ? orderedGroups
        .map((o: GroupOrderFilter) => {
          return {
            title: o.title,
            options: o.options.filter((o: FilterOption) =>
              o.label.toLowerCase().includes(search?.toLowerCase() as string),
            ),
          };
        })
        .filter((option: GroupOrderFilter) => option.options.length)
    : [];

  const filterOptions = search ? searchResults : orderedGroups;

  return (
    <Box p={15}>
      {filterOptions ? (
        <>
          <Box mt={10} mb={25}>
            <SearchInput
              name="search"
              placeholder="Search filters"
              value={search || ''}
              onChange={setSearch}
            />
          </Box>
          {search && !searchResults.length ? (
            <Layout minH="100%" w="100%" align="center" justify="center">
              <Text>No matching filters found.</Text>
            </Layout>
          ) : null}
          <RadioGroups
            columns={2}
            groups={filterOptions}
            name="Select a filter"
            value={filterId}
            onChange={handleFilterSelect}
          />
        </>
      ) : null}
    </Box>
  );
};

export default SelectAddFilter;
