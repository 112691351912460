import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';

import ActionsMenu from '~/components/Shared/ActionsMenu';
import { showToast } from '~/components/Shared/EcToast';
import { Box, HtmlEntityType, IconButton, Layout, Link, Text } from '~/eds';
import { api } from '~/redux';
import { DepartmentTree } from '~/types';
import { MODAL_DELETE_DEPARTMENT, MODAL_NAME } from '~/types/modal.types';
import { ERROR } from '~/types/toast.types';

type Props = {
  clientId: number;
  item: DepartmentTree;
  handleShowModal: (
    modalName: string,
    item: DepartmentTree,
    isChildren?: boolean,
  ) => void;
  onClick: (item: DepartmentTree) => void;
};

const DepartmentItem = ({
  clientId,
  item,
  handleShowModal,
  onClick,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [
    getDepartmentChildren,
    { data, isFetching, isError, isSuccess },
  ] = api.endpoints.getDepartmentChildren.useLazyQuery();

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setExpanded(true);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isError && !isFetching) {
      showToast(ERROR, 'Something went wrong, please try again later.');
    }
  }, [isError, isFetching]);

  useEffect(() => {
    if (expanded) {
      getDepartmentChildren({ departmentId: item.department_id, clientId });
    }
  }, [item.children_count]);

  const getIcon = () => {
    if (isFetching) return 'loading';

    if (expanded) return 'chevron-down';

    return 'chevron-right';
  };

  const toggleChildren = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      if (!isFetching) {
        getDepartmentChildren({ departmentId: item.department_id, clientId });
      }
    }
  };

  const getMenuItems = () => {
    const items = [];

    items.push({
      id: '1',
      content: 'Add Subdepartment',
      onClick: () => handleShowModal(MODAL_NAME, item, true),
    });

    items.push({
      id: '2',
      content: 'Rename',
      onClick: () => handleShowModal(MODAL_NAME, item),
    });

    items.push({
      id: '3',
      content: 'Delete',
      onClick: () => handleShowModal(MODAL_DELETE_DEPARTMENT, item),
    });

    return items;
  };

  const renderChildren = () => {
    return (
      <Box>
        {data?.results.map((child) => (
          <DepartmentItem
            onClick={onClick}
            key={`child_department_${child.department_id}`}
            clientId={clientId}
            item={child}
            handleShowModal={handleShowModal}
          />
        ))}
      </Box>
    );
  };

  return (
    <Box pt={3} pl={2}>
      <Layout
        direction="row"
        justify="space-between"
        align="center"
        borderBottom="border"
        pb={3}
      >
        <Box>
          <Layout direction="row" spacing={2}>
            {item.children_count ? (
              <IconButton
                onClick={toggleChildren}
                icon={getIcon()}
                text={`sub-departments of department ${item.department_name}`}
              />
            ) : null}
            <Link
              variant="subtitle"
              onClick={() => onClick(item)}
              color="text.primary"
            >
              {item.department_name}
            </Link>
          </Layout>
          <Box>
            {item.members_count ? (
              <>
                <Text variant="tiny" color="text.quiet" mr={2}>
                  {item.members_count} {pluralize('user', item.members_count)}
                </Text>
                <Text>{HtmlEntityType.Bullet}</Text>
              </>
            ) : null}
            {item.children_count ? (
              <Text variant="tiny" color="text.quiet" ml={2}>
                {item.children_count}{' '}
                {pluralize('subdepartment', item.children_count)}
              </Text>
            ) : null}
          </Box>
        </Box>
        <Box>
          <ActionsMenu
            id={`client_department_list_actions_menu?id=${item.department_id}`}
            items={getMenuItems()}
            align="end"
            icon={undefined}
            smallIcon={undefined}
            title="edit department menu"
          />
        </Box>
      </Layout>
      {expanded && renderChildren()}
    </Box>
  );
};

export default DepartmentItem;
