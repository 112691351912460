import { HelperMethods } from '@syncfusion/ej2-react-documenteditor';
import { uniq } from 'lodash';

import {
  extractMentionEntities,
  testHasTextMatch,
  types,
  types as Types,
} from '~/eds';
import { EntityType } from '~/enums';
import {
  Comment,
  CommentFilters,
  ContextEntityType,
  Nullable,
  User,
  Uuid,
} from '~/types';
import { getUserNameFromFullname } from '~/utils/user';

interface TransformOptions {
  users: Record<Types.PilotId, Types.User>;
  serverComments: Comment[];
}

const getCommentCreatedBy = (
  comment: Comment,
  users: Record<Types.PilotId, Types.User>,
): Types.User => {
  if (comment.createdBy) {
    const creator = users[comment.createdBy];

    if (creator) return creator;
    return { id: comment.createdBy, firstName: '', lastName: '', email: '' };
  }

  if (!comment.createdBy && comment.creatorName) {
    const { firstName, lastName } = getUserNameFromFullname(
      comment.creatorName,
    );
    return {
      id: '',
      firstName,
      lastName: `${lastName} (External User)`,
      email: 'External user',
    };
  }

  return { id: comment.createdBy!, firstName: '', lastName: '', email: '' };
};

const getCommentCreatedDate = (comment: Comment) => {
  // this will only be applied to comments that are embedded in the document and imported from the uploaded document
  if (comment.isCommentEmbedded && comment.creatorName && !comment.createdBy) {
    return new Date(
      HelperMethods.getModifiedDate(comment.createdDate.toISOString()),
    );
  }
  return comment.createdDate;
};

export const transformServerComments = ({
  users,
  serverComments,
}: TransformOptions) => (comment: Comment) => ({
  ...comment,
  createdBy: getCommentCreatedBy(comment, users),
  createdDate: getCommentCreatedDate(comment),
  //TODO: not sure if this data will be on BE, so let's do it here for now.
  replies: serverComments.filter(
    (filteredComment) => filteredComment.threadId === comment.id,
  ).length,
});

export const resolveCommentAuthorName = (
  commentAuthorNameResolver?: (comment: Comment) => string,
) => (comment: Comment) => {
  if (!comment.createdBy && !comment.creatorName && commentAuthorNameResolver) {
    return {
      ...comment,
      creatorName: commentAuthorNameResolver(comment),
    };
  }
  return comment;
};

export const resolveIsCommentEmbedded = (
  isCommentEmbeddedResolver?: (comment: Comment) => boolean,
) => (comment: Comment) => {
  if (isCommentEmbeddedResolver) {
    return {
      ...comment,
      isCommentEmbedded: isCommentEmbeddedResolver(comment),
    };
  }
  return comment;
};

export const filterByParentComment = (
  parentComment: Nullable<Types.Comments.Comment>,
) => (comment: Comment) => comment.threadId === (parentComment?.id || null);

export const filterHighlightsOfDifferentVersion = (
  comment: Types.Comments.Comment,
  entity: Types.Entity,
) => {
  const hasHighlight = comment.context.some((commentContext) =>
    ([
      EntityType.Highlight,
      EntityType.EditorHighlight,
    ] as ContextEntityType[]).includes(commentContext.type),
  );
  if (hasHighlight) {
    return comment.context.some(
      (commentContext) =>
        commentContext.type === entity.type && commentContext.id === entity.id,
    );
  }
  return true;
};

export const transformEdsCommentToComment = (
  edsComment: Types.Comments.Comment,
): Comment => {
  return {
    ...edsComment,
    createdBy: edsComment.createdBy.id,
  };
};

interface FilterCommentOptions {
  filters: CommentFilters;
  currentUser: User;
  parentComment?: Nullable<Types.Comments.Comment>;
}

export const getEnabledFilters = ({
  enabledFilters,
  filters,
}: {
  enabledFilters: Types.Comments.EnabledFilters;
  filters: CommentFilters;
}) => {
  const initialFilters = { ...filters };
  if (!enabledFilters.flags) {
    initialFilters.flags = [];
  }
  if (!enabledFilters.source) {
    initialFilters.source = 'all';
  }
  if (!enabledFilters.status) {
    initialFilters.status = 'open';
  }
  return initialFilters;
};

const filterWithFlags = ({
  filters,
  currentUser,
  comment,
}: {
  currentUser: User;
  filters: CommentFilters;
  comment: Comment;
}) => {
  if (filters.flags.length !== 0) {
    for (const flag of filters.flags) {
      if (flag === 'byMe' && comment.createdBy === currentUser.id) {
        return true;
      }
      if (flag === 'mentionMe') {
        const userId: Uuid = String(currentUser.id);
        const entities = extractMentionEntities(comment.content, {
          type: 'user',
          id: userId,
        });
        if (entities.has(userId)) {
          return true;
        }
      }
    }
    return false;
  } else {
    return true;
  }
};

const filterWithStatus = ({
  filters,
  comment,
}: {
  filters: CommentFilters;
  comment: Comment;
}) => {
  if (
    filters.status === 'all' ||
    (filters.status === 'open' && !comment.isResolved) ||
    (filters.status === 'isResolved' && comment.isResolved)
  ) {
    return true;
  }
  return false;
};

const filterWithSource = ({
  filters,
  comment,
}: {
  filters: CommentFilters;
  comment: Comment;
}) => {
  if (filters.source === 'all') return true;
  const commentSource = comment.context.some((context) =>
    [EntityType.EditorHighlight, EntityType.Highlight].includes(
      context.type as EntityType,
    ),
  )
    ? 'document'
    : 'ticket';
  if (filters.source && commentSource === filters.source) {
    return true;
  }
  return false;
};

export const filterComments = ({
  filters,
  currentUser,
  parentComment,
}: FilterCommentOptions) => (comment: Comment) => {
  if (!parentComment && comment.threadId !== null) return true;

  const matchesAFilter = filterWithFlags({ filters, currentUser, comment });
  const matchesStatus = filterWithStatus({ filters, comment });
  const matchesSource = filters.source
    ? filterWithSource({ filters, comment })
    : true;

  return matchesAFilter && matchesStatus && matchesSource;
};

export const searchComments = (search: string) => (comment: Comment) =>
  search
    ? testHasTextMatch(serializeContentAst(comment.content), search)
    : comment;

const serializeContentAst = (contentArr: types.ContentAst) =>
  contentArr.reduce((text, content) => {
    if ('text' in content) {
      return text + content.text;
    }

    if ('children' in content) {
      const childText: string = serializeContentAst(content.children);
      return text + childText;
    }

    return text;
  }, '');

export const extractUserMentionsFromComments = (comments: Comment[]) => {
  const userIds: types.PilotId[] = [];
  for (const comment of comments) {
    const userMentions = extractMentionEntities(comment.content, {
      type: 'user',
      id: '',
    });

    userIds.push(...Array.from(userMentions));
  }

  return uniq(userIds);
};
