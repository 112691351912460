import { useEffect } from 'react';

import { FlagType, useFlag } from '~/flags';
import { User } from '~/types';

import { initCandu, loadCanduScript } from './setupCandu';

export const useCandu = (user: User) => {
  const enableCandu = useFlag(FlagType.CanduScript);

  useEffect(() => {
    if (enableCandu && user) {
      loadCanduScript().then(() => {
        initCandu(user);
      });
    } else {
      return;
    }
  }, [user, enableCandu]);
};
