const styles = {
  unsupportedBrowserContainer: {
    margin: '100px auto',
    width: '100%',
    backgroundColor: '#f5f5f6',
  },
  unsupportedBrowserMessage: {
    position: 'absolute',
    top: '40%',
    width: '100%',
    color: '#043440',
    fontSize: '20px',
    fontWeight: 300,
    textAlign: 'center',
  },
  unsupportedBrowserImageContainer: {
    marginBottom: '30px',
    width: '100%',
    textAlign: 'center',
  },
  unsupportedBrowserContainerLogo: {
    width: '300px',
    height: 'auto',
  },
};

export default styles;
