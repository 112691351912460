import { pilot } from '../services';

export const getExcelExportSettings = (query) =>
  pilot.post('/search/export-setting-options/', { ...query });

export const saveExcelExportSettings = (formData) =>
  pilot.patch('/search/export-setting/', formData);

// XHR property `responseType: 'arraybuffer'` required to properly fetch a document.
export const exportExcel = (formData) =>
  pilot.post('search/export-excel/', formData, {
    responseType: 'arraybuffer',
    fullResponse: true,
  });

export const getSavedSearchById = (queryId) =>
  pilot.get(`saved-search/query/singular/${queryId}`);

export const validateBooleanQuery = (query) =>
  pilot.get(`search/validate-bool-query/?query=${encodeURIComponent(query)}`);
