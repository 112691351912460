import { groupBy } from 'lodash';

import { testHasTextMatch } from '~/eds';

import { OptionsType } from './types';

export const groupFilterOptions = ({
  searchFilters,
  search,
  groupsOrder,
}: {
  searchFilters: OptionsType[];
  search: string;
  groupsOrder: string[];
}) => {
  const groups = groupBy(searchFilters, (filter) => filter.section);

  const mainGroups = groupsOrder.map((section) =>
    asCheckboxGroup(section, groups[section], search),
  );
  const additionalGroups = Object.keys(groups)
    .filter((section) => groupsOrder.indexOf(section) === -1)
    .map((section) => asCheckboxGroup(section, groups[section], search));

  return [...mainGroups, ...additionalGroups];
};

export const asCheckboxGroup = (
  section: string,
  group: OptionsType[],
  search: string,
) => {
  return {
    title: section,
    options: (group || []).map(asGroupItem).filter((groupOption) => {
      if (!search) return true;
      return testHasTextMatch(groupOption.label, search);
    }),
  };
};

export const asGroupItem = (searchFilter: OptionsType) => ({
  label: searchFilter.label,
  value: searchFilter.id,
  description: searchFilter.section,
});
