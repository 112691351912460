export default `
<svg
  aria-hidden="true"
  aria-label="arrow-forward"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M14.7188 18.1484C14.4062 17.8672 14.4062 17.3984 14.7188 17.0859L17.4375 14.3672H4.75C4.3125 14.3672 4 14.0547 4 13.6172V6.36719C4 5.96094 4.3125 5.61719 4.75 5.61719C5.15625 5.61719 5.5 5.96094 5.5 6.36719V12.8672H17.4375L14.7188 10.1484C14.4062 9.86719 14.4062 9.39844 14.7188 9.08594C15 8.80469 15.4688 8.80469 15.75 9.08594L19.75 13.0859C19.9062 13.2422 20 13.4297 20 13.6172C20 13.8359 19.9062 14.0234 19.75 14.1484L15.75 18.1484C15.4688 18.4609 15 18.4609 14.7188 18.1484Z"
    fill="currentcolor"
  />
</svg>
`;
