import React from 'react';

import { crmIntegrations } from '~/constants/integrations';
import { useSearchParams } from '~/hooks';

import IntegrationCardList from './IntegrationCardList';

function CrmIntegrationCardList() {
  const [_, setTabValue] = useSearchParams('tab'); // eslint-disable-line no-unused-vars

  const integrationCards = Object.values(crmIntegrations).map(
    (integration) => ({
      ...integration,
      inactiveMessage: false,
      enableStatusDetails: false,
      action: {
        onClick: () => setTabValue('third-party'),
        text: 'See integration',
      },
    }),
  );

  return <IntegrationCardList integrationCards={integrationCards} />;
}

export default CrmIntegrationCardList;
