import PropTypes from 'prop-types';
import React from 'react';

const FolderUpIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 40 40">
      <g fill="none" fillRule="evenodd">
        <circle
          cx="20"
          cy="20"
          r="20"
          fill="#000"
          fillOpacity={props.isHovered ? '.15' : '.1'}
          fillRule="nonzero"
        />
        <path d="M8 8h24v24H8z" />
        <path
          fill="#000"
          fillOpacity=".5"
          d="M17.289 12.32a1.086 1.086 0 0 1 1.541 0l4.972 5.026c.423.428.423 1.13 0 1.558a1.086 1.086 0 0 1-1.541 0l-3.116-3.16v8.062h7.77c.597 0 1.085.493 1.085 1.097 0 .603-.488 1.097-1.085 1.097H18.06a1.095 1.095 0 0 1-1.086-1.097v-9.159l-3.115 3.16a1.086 1.086 0 0 1-1.541 0 1.114 1.114 0 0 1 0-1.558l4.971-5.025z"
        />
      </g>
    </svg>
  );
};

FolderUpIcon.propTypes = {
  size: PropTypes.string,
  isHovered: PropTypes.bool,
};

FolderUpIcon.defaultProps = {
  size: '40',
  isHovered: false,
};

export default FolderUpIcon;
