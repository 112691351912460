import React from 'react';
import { connect } from 'react-redux';

import { getUnassignedFields } from '~/reducers/workflow';
import { PageLayout } from '~/ui';

import FieldSectionItem from './FieldSectionItem';

function UnassignedFieldSection({
  // connected
  fields,
}) {
  if (fields.length === 0) {
    return null;
  }

  return (
    <PageLayout.SidebarSection title="Unassigned">
      {fields.map((field) => (
        <FieldSectionItem key={field.id} field={field} />
      ))}
    </PageLayout.SidebarSection>
  );
}

function mapStateToProps({ workflow }) {
  return {
    fields: getUnassignedFields(workflow),
  };
}

export default connect(mapStateToProps)(UnassignedFieldSection);
