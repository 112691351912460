import { TestNamespaceType } from '~/enums';

import { createTestId } from './createTestId';

export const UI_ALERT_ICON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'alert',
  element: 'icon',
});
export const UI_ALERT_ACTION_ICON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'alert',
  element: 'action-icon',
});

export const UI_BUTTON_GROUP_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'button-group',
});

export const UI_CHECKBOX_ICON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'checkbox',
});

export const UI_ICON_TEST_ID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'icon',
});

export const UI_PAGE_LAYOUT_HEADER_CLOSE_BUTTON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'page-layout',
  element: 'header-close-icon',
});

export const UI_MODAL_CANCEL_BUTTON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'modal',
  element: 'cancel-button',
});
export const UI_MODAL_CLOSE_BUTTON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'modal',
  element: 'close-button',
});
export const UI_MODAL_SUBMIT_BUTTON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'modal',
  element: 'submit-button',
});
export const UI_MODAL_SECONDARY_BUTTON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'modal',
  element: 'secondary-button',
});

// note: we intend to merge MultiSelect and Select, so assigning 'select' as the block value
export const UI_SELECT_VALUE_CLOSE_ICON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'select',
  element: 'value-close-icon',
});
export const UI_SELECT_CONTROL_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'select',
  element: 'control',
});

export const UI_STEPS_STEP_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'steps',
  element: 'step',
});

export const UI_TABLE_EXPORT_EXCEL_ICON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'table',
  element: 'export-excel-icon',
});

export const UI_TABLE_ROW_ACTION_ICON_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'table',
  element: 'row-action-icon',
});

export const UI_TABLE_SELECT_CELL_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'table',
  element: 'select-cell',
});

export const UI_LOADING_SPINNER_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'loading-spinner',
});

export const UI_USER_IMPORT_WIZARD_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'wizard',
  element: 'step',
});

export const UI_DOCUMENT_METADATA_IMPORT_WIZARD_TESTID = createTestId({
  namespace: TestNamespaceType.Ui,
  block: 'wizard',
  element: 'step',
});
