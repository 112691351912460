export default `
<svg
  aria-hidden="true"
  aria-label="status-warning"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12.0156 5C12.4531 5 12.8594 5.25 13.0781 5.625L19.8281 17.125C20.0469 17.5312 20.0469 18 19.8281 18.375C19.6094 18.7812 19.2031 19 18.7656 19H5.26562C4.79688 19 4.39062 18.7812 4.17188 18.375C3.95312 18 3.95312 17.5312 4.17188 17.125L10.9219 5.625C11.1406 5.25 11.5469 5 12.0156 5ZM12.0156 9C11.5781 9 11.2656 9.34375 11.2656 9.75V13.25C11.2656 13.6875 11.5781 14 12.0156 14C12.4219 14 12.7656 13.6875 12.7656 13.25V9.75C12.7656 9.34375 12.4219 9 12.0156 9ZM13.0156 16C13.0156 15.4688 12.5469 15 12.0156 15C11.4531 15 11.0156 15.4688 11.0156 16C11.0156 16.5625 11.4531 17 12.0156 17C12.5469 17 13.0156 16.5625 13.0156 16Z"
    fill="currentcolor"
  />
</svg>
`;
