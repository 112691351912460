import React from 'react';

import {
  BooleanValuesPreview,
  DateValuesPreview,
  EnumSetValuesPreview,
  EnumValuesPreview,
  FileValuesPreview,
  NumberValuesPreview,
  TextValuesPreview,
} from '~/components/Shared/Filters_DEPRECATED';
import {
  ClauseValue,
  DateValue,
  Field,
  Filter,
  NumberValue,
  TextValue,
} from '~/evifields';

import ClauseValuesPreview from './ClauseValuesPreview';

interface Props {
  field: Field;
  filter: Filter;
}

const ValuesPreview = ({ field, filter }: Props) => {
  switch (field.type) {
    case 'boolean':
      return <BooleanValuesPreview />;
    case 'clause':
      return <ClauseValuesPreview filter={filter as Filter<ClauseValue>} />;
    case 'date':
      return <DateValuesPreview filter={filter as Filter<DateValue>} />;
    case 'enum':
      return (
        <EnumValuesPreview field={field as Field<'enum'>} filter={filter} />
      );
    case 'enum_set':
    case 'folder':
      return (
        <EnumSetValuesPreview
          field={field as Field<'enum_set'>}
          filter={filter}
        />
      );
    case 'file':
      return <FileValuesPreview />;
    case 'number':
      return <NumberValuesPreview filter={filter as Filter<NumberValue>} />;
    case 'text':
    case 'text_search':
      return (
        <TextValuesPreview filter={filter as Filter<TextValue>} field={field} />
      );
    default:
      return null;
  }
};

export default ValuesPreview;
