import React from 'react';

import { useToggle } from '../../hooks';
import { SharedDropdownProps } from '../../types';
import { Dropdown } from '../Dropdown';

type Callback = () => void;

type Wrapper = (trigger: React.ReactElement) => React.ReactElement;

export const useDropdown = (
  props: SharedDropdownProps,
): [Wrapper, Callback, Callback, Callback] => {
  const [isVisible, _toggle, show, hide] = useToggle();

  const handleShow = () => {
    if (!isVisible) {
      props.onShow?.();
      show();
    }
  };

  const handleHide = () => {
    if (isVisible) {
      props.onHide?.();
      hide();
    }
  };

  const handleToggle = () => {
    if (isVisible) {
      handleHide();
    } else {
      handleShow();
    }
  };

  const wrap = (trigger: React.ReactElement) => (
    <Dropdown
      {...props}
      isVisible={isVisible}
      trigger={trigger}
      onClickOutside={props.onClickOutside || hide}
      onHide={handleHide}
      onShow={handleShow}
    />
  );

  return [wrap, handleShow, handleHide, handleToggle];
};
