import { Endpoint } from '~/features/webhooks';
import { SuspendApiResponse } from '~/redux/api/types/courier/endpoints';
import { courier } from '~/services';

interface Request {
  endpoint: Endpoint;
}

export const suspendEndpoint = async ({
  endpoint,
}: Request): Promise<boolean> => {
  const response: SuspendApiResponse = await courier.patch(
    `/endpoints/${endpoint.id}/suspend`,
  );

  return Boolean(response);
};
