import {
  black2,
  black3,
  black5,
  bold,
  evisortBlue,
  evisortRed,
  evisortYellow,
  semibold,
} from '../../../assets/shared-styles/general';

const border = '1px solid rgba(0, 0, 0, 0.2)';

const checkboxUnchecked = {
  position: 'absolute',
  top: '2px',
  display: 'flex',
  alignItems: 'center',
  width: '16px',
  height: '16px',
  backgroundColor: 'white',
  border: `solid 1px ${black5}`,
  borderRadius: '2px',
  cursor: 'pointer',
};

const headerActionStyles = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '24px',
  fontSize: '13px',
  fontWeight: bold,
  cursor: 'pointer',
  '& svg': {
    marginRight: '4px',
  },
};

const styles = {
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  moveAction: {
    ...headerActionStyles,
    color: evisortBlue,
  },
  moveActionForbidden: {
    ...headerActionStyles,
    color: evisortBlue,
    opacity: '0.4',
  },
  copyAction: {
    ...headerActionStyles,
    color: evisortBlue,
  },
  copyActionForbidden: {
    ...headerActionStyles,
    color: evisortBlue,
    opacity: '0.4',
  },
  editAction: {
    ...headerActionStyles,
    color: evisortBlue,
  },
  editActionForbidden: {
    ...headerActionStyles,
    padding: '0 6px',
    color: evisortBlue,
    opacity: '0.4',
  },
  deleteAction: {
    ...headerActionStyles,
    color: evisortRed,
  },
  deleteActionForbidden: {
    ...headerActionStyles,
    color: evisortRed,
    opacity: '0.4',
  },
  firstCellInRowWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxWrapper: {
    marginRight: '8px',
  },
  chevronIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
  },
  selectAllMessage: {
    margin: '4px 20px',
    display: 'table-cell',
    color: black2,
    fontSize: '14px',
    fontWeight: semibold,
    whiteSpace: 'nowrap',
  },
  selectAllContent: {
    marginLeft: '16px',
    color: evisortBlue,
    fontWeight: bold,
    cursor: 'pointer',
  },
  theadBulkActionMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > button': {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  tableWrap: {
    border,
    display: 'block',
    maxWidth: '100%',
    overflowX: 'auto',
  },
  documentTable: {
    display: 'inline-block',
    width: '100%',
    minHeight: '400px',
    borderSpacing: 0,
  },
  documentTableHeader: {
    minWidth: '100%',
    height: '40px',
    borderBottom: border,
  },
  documentTableThead: {
    position: 'relative',
    '&:first-child': {
      padding: '10px 10px 5px 9px',
      borderRight: 'none',
    },

    '&:last-child': {
      padding: '0 6px',
      position: 'absolute !important',
      right: '80px',
      backgroundColor: 'white',
      borderRight: 'none',
      overflow: 'visible',
      textTransform: 'none',
    },
  },
  documentTableTheadButton: {
    margin: 0,
    padding: '10px 20px 5px 16px',
    position: 'relative',
    overflow: 'hidden',
    color: black3,
    backgroundColor: 'transparent',
    border: 'none',
    borderRight: border,
    fontSize: '13px',
    lineHeight: '19px',
    textAlign: 'left',
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  actionMenuHeader: {
    width: '50px',
  },
  documentTableGearWrapper: {
    position: 'absolute',
    top: '2px',
    right: '-30px',
    height: '36px',
    backgroundColor: 'white',
    borderRight: 'none',
    zIndex: 1,
  },
  documentTableTd: {
    margin: 0,
    padding: '9px 7px 4px 16px',
    position: 'relative',
    fontSize: '14px',
    lineHeight: '23px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:first-child': {
      paddingLeft: '10px',
      borderRight: 'none',
    },

    '&:nth-child(2)': {
      overflow: 'visible',
    },

    '&:nth-child(3)': {
      paddingRight: '50px',
    },

    '& a': {
      display: 'inline',
      color: '#111',
      textDecoration: 'none',
      '&:hover': {
        color: '#007fba',
        textDecoration: 'none',
      },
      '&:visited': {
        color: '#111',
        textDecoration: 'none',
        '&:hover': {
          color: '#007fba',
        },
      },
    },
  },
  documentTableRow: {
    display: 'inline-block',
    minWidth: '100%',
    '& > div:not(:last-child)': {
      borderBottom: border,
    },

    '& > div:hover': {
      backgroundColor: '#f7f5f8',
    },
  },
  documentTableSortIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  },
  documentTableCheckboxContainer: {
    position: 'relative',
  },
  documentTableCheckboxChevronDown: {
    marginLeft: '5px',
    position: 'absolute',
    top: 0,
    left: '13px',
  },
  documentTableCheckboxInput: {
    width: '16px',
    height: '16px',
    opacity: 0,
  },
  documentTableCheckboxUnchecked: {
    ...checkboxUnchecked,
  },
  documentTableCheckboxChecked: {
    ...checkboxUnchecked,
    border: 'none',
    backgroundColor: evisortBlue,
  },
  headerText: {
    width: '95%',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  tooltipContentHeader: {
    marginBottom: '3px',
    padding: '2px',
    display: 'inline-block',
    color: evisortYellow,
    fontSize: '10px',
    fontWeight: bold,
    textTransform: 'uppercase',
  },
  documentName: {
    marginLeft: '5px',
    position: 'relative',
    top: '-6px',
  },
  groupIcon: {
    position: 'absolute',
    top: '14px',
    right: '9px',
  },
  documentTableColumnResizer: {
    marginLeft: '-10px',
    position: 'absolute',
    width: '20px',
    height: '100%',
    zIndex: 2,
  },
};

export default styles;
