import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import injectSheet from 'react-jss';

import { validateBooleanQuery } from '~/api/search';
import { evisortBlue } from '~/assets/shared-styles/general';
import EcBooleanTextSearchPopup from '~/components/Shared/EcBooleanTextSearchPopup';
import EcButton from '~/components/Shared/EcButton';
import EcCard from '~/components/Shared/EcCard';
import EcFiltersSection from '~/components/Shared/EcFiltersSection';
import ReloadIcon from '~/components/Shared/Icons/ReloadIcon';
import SearchIcon from '~/components/Shared/Icons/SearchIcon';
import { ENTER, SPACE } from '~/constants/keyCodes';
import { Button } from '~/eds';
import { FlexLayout, Text } from '~/ui';

import AnalyzerAddFilter from '../AnalyzerAddFilter';
import styles from './AnalyzerForm.styles';

const AnalyzerForm = ({
  classes,
  filters,
  filterOptions,
  updateFilters,
  updateFilterOptions,
  booleanQuery,
  updateBooleanQuery,
  provisionOptions,
  handleSubmit,
}) => {
  const [showSyntaxPopup, setShowSyntaxPopup] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [booleanQueryError, setBooleanQueryError] = useState(null);

  const handleFilterOptionsChange = (filterId) => {
    updateFilterOptions(filterId);
  };

  const handleBooleanInputChange = (e) => {
    const query = e.target.value;

    updateBooleanQuery(query);
    validateBooleanQuery(query)
      .then(() => setBooleanQueryError(null))
      .catch((err) => setBooleanQueryError(err?.response?.data?.detail));
  };

  const handleOnAddSectionKeypress = (e) => {
    if (e.keyCode === ENTER || e.keyCode === SPACE) setShowSection(true);
  };

  const handleOnAddSectionClick = () => {
    setShowSection(true);
  };

  const renderSearchInputAppendix = () => {
    let appendixContent = null;
    if (booleanQueryError) {
      appendixContent = (
        <Text color="red-400" variant="xs-dense-medium">
          {booleanQueryError}
        </Text>
      );
    } else if (booleanQuery.length) {
      appendixContent = (
        <FlexLayout justifyContent="flex-end">
          <Text color="gray-600" variant="xs-dense">
            Press <strong>Return</strong> to run search.
          </Text>
        </FlexLayout>
      );
    }

    return <div className={classes.searchInputAppendix}>{appendixContent}</div>;
  };

  const renderAnalyzerBooleanSearch = () => {
    return (
      <FlexLayout flexDirection="column" space={4}>
        <FlexLayout flexDirection="column" space={2}>
          <form className={classes.analyzerForm} onSubmit={handleSubmit}>
            <FlexLayout
              alignItems="center"
              className={
                booleanQueryError
                  ? classes.errorSearchInputWrapper
                  : classes.searchInputWrapper
              }
              space={2}
            >
              <SearchIcon color={evisortBlue} opacity="1" />
              <DebounceInput
                debounceTimeout={300}
                placeholder="Search filenames and content"
                className={classes.searchInput}
                value={booleanQuery}
                onChange={handleBooleanInputChange}
              />
              <Button
                icon="help"
                iconPosition="left"
                text="Help"
                variant="action"
                onClick={() => setShowSyntaxPopup(true)}
              />
            </FlexLayout>
          </form>
          {renderSearchInputAppendix()}
        </FlexLayout>
        <FlexLayout
          alignItems="center"
          flexDirection="column"
          className={classes.booleanSearchPlaceholder}
          space={2}
        >
          <Text color="gray-700" variant="l-dense-bold">
            or add filters to run a new Analyzer search.
          </Text>
          <Text color="gray-700" variant="m-dense-bold">
            <span role="img" aria-label=":point_left:">
              👈
            </span>
            &nbsp;Use the sidebar on the left-hand side to&nbsp;
            <AnalyzerAddFilter
              render={({ onClick, onKeyDown }) => (
                <span
                  role="button"
                  tabIndex="0"
                  id="analyzer-add-a-filter-link"
                  className={classes.addSectionButton}
                  onClick={onClick}
                  onKeyDown={onKeyDown}
                >
                  add a filter
                </span>
              )}
              filters={filters}
              filterOptions={filterOptions}
              updateFilters={updateFilters}
              updateFilterOptions={updateFilterOptions}
              provisionOptions={provisionOptions}
            />
            &nbsp;or&nbsp;
            <span
              role="button"
              tabIndex="0"
              id="analyzer-add-a-section-link"
              className={classes.addSectionButton}
              onClick={handleOnAddSectionClick}
              onKeyDown={handleOnAddSectionKeypress}
            >
              add a section
            </span>
            .
          </Text>
        </FlexLayout>
      </FlexLayout>
    );
  };

  const renderAnalyzerFormContainer = () => {
    return (
      <FlexLayout flexDirection="column" space={4}>
        <FlexLayout
          alignItems="center"
          className={classes.sectionHeader}
          space={3}
        >
          <SearchIcon size="32" color={evisortBlue} opacity="1" />
          <FlexLayout flexDirection="column" space={1}>
            <Text color="gray-700" variant="l-dense-bold">
              Run a New Search
            </Text>
            <Text color="gray-700" variant="m-spaced">
              Add filters and run a custom search.
            </Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout flexDirection="column" space={4}>
          <EcFiltersSection
            filters={filters}
            filterOptions={filterOptions}
            provisionOptions={provisionOptions}
            onChange={(selectedFilters) => updateFilters(selectedFilters)}
            handleFilterOptionsChange={handleFilterOptionsChange}
            openEmptySection={showSection}
            rootSection
          />
          <FlexLayout
            id="analyzerFormActions"
            alignItems="center"
            justifyContent="flex-end"
            space={4}
          >
            <EcButton
              id="analyzer_buttonActions_resetSearchButton"
              text="Reset"
              iconLeft={<ReloadIcon size="20" color="rgba(0, 0, 0, 0.75)" />}
              onClick={() => updateFilters([])}
            />
            <EcButton
              disabled={filters[0]?.error}
              id="analyzer_buttonActions_runSearchButton"
              yellow
              text="Run Search"
              iconLeft={
                <SearchIcon opacity="1" size="20" color="rgba(0, 0, 0, 0.75)" />
              }
              onClick={handleSubmit}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    );
  };

  return (
    <>
      <EcCard contentStyles={{ padding: '16px' }}>
        {filters.length || showSection
          ? renderAnalyzerFormContainer()
          : renderAnalyzerBooleanSearch()}
      </EcCard>
      {showSyntaxPopup && (
        <EcBooleanTextSearchPopup
          handleOnCloseClick={() => setShowSyntaxPopup(false)}
        />
      )}
    </>
  );
};

AnalyzerForm.propTypes = {
  classes: PropTypes.object.isRequired,
  booleanQuery: PropTypes.string.isRequired,
  updateBooleanQuery: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  filterOptions: PropTypes.array.isRequired,
  provisionOptions: PropTypes.array.isRequired,
  updateFilters: PropTypes.func.isRequired,
  updateFilterOptions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default injectSheet(styles)(AnalyzerForm);
