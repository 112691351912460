import { union } from 'lodash';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { ActionType, Row } from 'react-table';

import {
  displayReason,
  DocumentsRestoreFilters,
  getExportDataFromColumns,
  useLimitExceededModal,
} from '~/components/DocumentsPage/DocumentsRestore';
import DeleteDocumentPermanentlyModal from '~/components/Modals/DeleteDocumentPermanentlyModal';
import { showToast } from '~/components/Shared/EcToast';
import FolderEmptyIcon from '~/components/Shared/Icons/FolderEmptyIcon';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User as UserComponent } from '~/components/Shared/User';
import {
  BULK_DELETE_LIMIT,
  BULK_EXPORT_LIMIT,
  BULK_RESTORE_LIMIT,
} from '~/constants/max_lengths';
import { PAGE_SIZE, PAGE_START } from '~/constants/page';
import { ContentContainer, exportXlsx, Layout, PageLayout, Text } from '~/eds';
import { TableContextType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { withCurrentUser } from '~/hocs';
import { useTableSettings } from '~/hooks';
import { api, selectors } from '~/redux';
import {
  DeletedDocumentResult,
  downloadDeletedDocument,
  downloadDeletedDocumentForCurrentUser,
  getDeletedDocuments,
  getDeletedDocumentsForCurrentUser,
} from '~/redux/api/methods';
import myDeletedItems from '~/redux/slices/myDeletedItems';
import restorationHub from '~/redux/slices/restorationHub';
import { generateNav, RoutePathType } from '~/routing';
import { TableQuery, User } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Alert, WIP_FolderModal as FolderModal } from '~/ui';
import { sortStrings } from '~/utils';
import { downloadFile } from '~/utils/files';

const PAGE_SIZE_LIST = [10, 20, 30, 50, 100]; // TODO: source from EDS

const DEFAULT_SORT = { id: 'deleted_date', desc: true };

const Page = ({ currentUser }: { currentUser: User }) => {
  const isAdminConsole = !!useRouteMatch(
    RoutePathType.AdminConsoleClientRestorationHub,
  );

  const isRestorationHub500PageSizeEnabled = useFlag(
    FlagType.RestorationHub500PageSize,
  );
  const RESTORATION_HUB_PAGE_SIZE_LIST = isRestorationHub500PageSizeEnabled
    ? [10, 20, 30, 50, 100, 500]
    : PAGE_SIZE_LIST;

  const dispatch = useDispatch();

  const columnOrderSelector = isAdminConsole
    ? selectors.selectRestorationHubColumnOrder
    : selectors.selectMyDeletedItemsColumnOrder;
  const columnOrder = useSelector(columnOrderSelector);

  const [query, setQuery] = useState<TableQuery>({
    page: PAGE_START,
    pageSize: PAGE_SIZE,
    sort: DEFAULT_SORT,
    filters: {},
  });

  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showPermanentDeleteModal, setShowPermanentDeleteModal] = useState(
    false,
  );
  const [selectedDocuments, setSelectedDocuments] = useState<
    DeletedDocumentResult[]
  >([]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [selectedFolder, setSelectedFolder] = useState(0);
  const [allSelected, setAllSelected] = useState(false);

  const getDeletedDocumentsEndpoint = isAdminConsole
    ? api.endpoints.getDeletedDocuments
    : api.endpoints.getDeletedDocumentsForCurrentUser;
  const {
    data: tableData,
    error,
    isFetching: isLoading,
  } = getDeletedDocumentsEndpoint.useQuery(query);
  const { data: folderData, error: folderError } = api.useGetFolderTreeQuery(
    null,
  );

  const deletePermanentlyEndpoint = isAdminConsole
    ? api.endpoints.permanentlyDeleteDocuments
    : api.endpoints.permanentlyDeleteDocumentsForCurrentUser;
  const [
    deletePermanently,
    deletePermanentlyResult,
  ] = deletePermanentlyEndpoint.useMutation();
  const {
    isError: isDeletePermanentlyError,
    isSuccess: isDeletePermanentlySuccess,
  } = deletePermanentlyResult;

  const restoreDocumentsEndpoint = isAdminConsole
    ? api.endpoints.restoreDeletedDocuments
    : api.endpoints.restoreDeletedDocumentsForCurrentUser;
  const [
    restoreDocuments,
    restoreDocumentsResult,
  ] = restoreDocumentsEndpoint.useMutation();
  const {
    isError: isRestoreDocumentsError,
    isSuccess: isRestoreDocumentsSuccess,
  } = restoreDocumentsResult;

  const tableContext = isAdminConsole
    ? TableContextType.RestorationHub
    : TableContextType.MyDeletedItems;
  const { tableSettings } = useTableSettings(tableContext);

  useEffect(() => {
    if (tableSettings) {
      setQuery({
        ...query,
        pageSize: tableSettings.pageSize,
        sort: tableSettings.sortBy,
      });
      setColumnOrder(tableSettings.columnOrder);
    }
  }, [tableSettings]);

  const onRowRestoreClick = (data: DeletedDocumentResult) => {
    setSelectedDocuments([data]);
    setShowRestoreModal(true);
  };

  const onBulkRestoreClick = async () => {
    if (selectedRowIds.length && !allSelected) {
      const allData = await getAllData();
      if (allData) {
        const selectedData = allData.filter((item) =>
          selectedRowIds.includes(`${item.id}`),
        );
        setSelectedDocuments(selectedData);
      }
    }
    setShowRestoreModal(true);
    showRestoreExceedsLimitModal();
  };

  const onRowDeleteClick = (data: DeletedDocumentResult) => {
    setSelectedDocuments([data]);
    setShowPermanentDeleteModal(true);
  };

  const onBulkDeleteClick = async () => {
    if (selectedRowIds.length && !allSelected) {
      const allData = await getAllData();
      if (allData) {
        const selectedData = allData.filter((item) =>
          selectedRowIds.includes(`${item.id}`),
        );
        setSelectedDocuments(selectedData);
      }
    }
    setShowPermanentDeleteModal(true);
    showDeleteExceedsLimitModal();
  };

  const onRowDownloadClick = (data: DeletedDocumentResult) => {
    const downloadDeletedDocumentEndpoint = isAdminConsole
      ? downloadDeletedDocument
      : downloadDeletedDocumentForCurrentUser;
    const displayError = () =>
      showToast(ERROR, `Failed to download "${data.name}"`);

    downloadDeletedDocumentEndpoint(data.id)
      .then((response: any) =>
        downloadFile(response, `${data.name}${data.fileType}`),
      )
      .catch(displayError);
  };

  const actions = [
    {
      key: 'restore',
      icon: 'restore',
      label: 'Restore',
      onClick: () => {},
    },
    {
      key: 'download',
      icon: 'download',
      label: 'Download',
      onClick: () => {},
    },
    {
      key: 'delete',
      icon: 'trash-permanent',
      label: 'Delete permanently',
      onClick: () => {},
    },
  ];

  const rowActions = actions.map((action) => {
    switch (action.key) {
      case 'restore':
        return { ...action, onClick: onRowRestoreClick };
      case 'delete':
        return { ...action, onClick: onRowDeleteClick };
      case 'download':
        return { ...action, onClick: onRowDownloadClick };
      default:
        return action;
    }
  });

  const bulkActions = actions
    .filter((action) => action.key !== 'download')
    .map((action) => {
      switch (action.key) {
        case 'restore':
          return { ...action, onClick: onBulkRestoreClick };
        case 'delete':
          return { ...action, onClick: onBulkDeleteClick };
        default:
          return action;
      }
    });

  const columns = [
    {
      key: 'name',
      cellType: 'filesystem',
      title: 'Name',
      sortType: (rowA: Row, rowB: Row, columnId: string) =>
        sortStrings(rowA.values[columnId], rowB.values[columnId]),
      mapCellProps: (rowData: DeletedDocumentResult) => {
        return {
          file: {
            name: `${rowData.name}${rowData.fileType}`,
            includeExtension: false,
          },
        };
      },
    },
    {
      key: 'original_folder',
      cellType: 'filesystem',
      title: 'Original Folder',
      mapCellProps: (rowData: DeletedDocumentResult) => {
        return {
          folder: {
            name: rowData.originalFolder,
            path: rowData.originalFolder,
          },
        };
      },
    },
    {
      key: 'deleted_date',
      cellType: 'datetime',
      title: 'Date Deleted',
      minWidth: 'm',
      width: 'm',
      mapCellProps: ({ deletedDate }: DeletedDocumentResult) => {
        return {
          datetime: deletedDate ? new Date(deletedDate) : undefined,
          format: 'iso',
        };
      },
    },
    {
      key: 'reason',
      cellType: 'text',
      title: 'Reason Deleted',
      mapCellProps: (rowData: DeletedDocumentResult) => {
        return {
          text: displayReason(rowData.reason),
          description: rowData.extraDetails,
          shouldTruncate: true,
        };
      },
    },
    {
      key: 'deleted_by',
      cellType: 'user',
      title: 'Deleted By',
      mapCellProps: (rowData: DeletedDocumentResult) => ({
        asyncUser: {
          id: rowData.deletedById,
          render: UserComponent,
        },
        mode: 'avatar-name',
      }),
    },
  ];

  const handleCancelRestore = () => {
    setSelectedFolder(0);
    setShowRestoreModal(false);
    hideRestoreExceedsLimitModal();
  };

  const handleCancelPermanentDelete = () => {
    setSelectedDocuments([]);
    setShowPermanentDeleteModal(false);
    hideDeleteExceedsLimitModal();
  };

  const count = allSelected
    ? tableData!.count
    : selectedRowIds.length || selectedDocuments.length || 0;
  const documentIds = selectedDocuments.map((document) => document.id);

  const handlePermanentlyDeleteDocuments = () => {
    deletePermanently({ documentIds, allSelected, filters: query.filters });

    setShowPermanentDeleteModal(false);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setQuery({ ...query, page: PAGE_START, pageSize: newPageSize });
  };

  const handlePaginate = ({ pageIndex }: { pageIndex: number }) => {
    setQuery({ ...query, page: pageIndex });
  };

  const handleTableUpdate = (
    {
      isAllRowsSelected,
      selectedRowIds: updatedSelectedRowIds,
      sortBy,
    }: {
      isAllRowsSelected: boolean;
      selectedRowIds: string[];
      sortBy: { id: string; desc: boolean }[];
    },
    action?: ActionType,
  ) => {
    if (action?.type === 'toggleRowSelected') {
      setSelectedRowIds(updatedSelectedRowIds);
    }

    if (action?.type === 'toggleAllPageRowsSelected') {
      if (updatedSelectedRowIds.length > selectedRowIds.length) {
        setSelectedRowIds(union(selectedRowIds, updatedSelectedRowIds));
      } else {
        setSelectedRowIds(updatedSelectedRowIds);
      }
    }

    if (action?.type === 'toggleAllRowsSelected') {
      setAllSelected(isAllRowsSelected);
      if (!isAllRowsSelected) {
        setSelectedRowIds([]);
      }
    }

    if (action?.type === 'toggleMultipleRowsSelected') {
      setSelectedRowIds(union(selectedRowIds, updatedSelectedRowIds));
    }

    if (action?.type === 'toggleSortBy') {
      setQuery({ ...query, sort: sortBy[0] });
    }
  };

  const handleSetQuery = (query: TableQuery) => {
    if (isAdminConsole) {
      setQuery(query);
    } else {
      setQuery({
        ...query,
        filters: { ...query.filters, deletedBy: [currentUser.email] },
      });
    }
  };

  const store = isAdminConsole ? restorationHub : myDeletedItems;
  const setColumnOrder = (columnOrder: string[]) => {
    dispatch(store.actions.setColumnOrder(columnOrder));
  };

  const resetPageIfEmpty = () => {
    const oldCount = tableData?.count ?? 0;
    if (count === oldCount) {
      // If all documents were removed, reset the filters & page index
      setQuery({ ...query, page: PAGE_START, filters: {} });
    } else {
      const newCount = oldCount > count ? oldCount - count : 0;
      const newLastPage =
        newCount > 0 ? Math.ceil(newCount / query.pageSize) : PAGE_START;
      if (query.page > newLastPage) {
        // If the current page is now empty, go to the new last page
        setQuery({ ...query, page: newLastPage });
      }
    }
  };

  useEffect(() => {
    if (isDeletePermanentlySuccess) {
      showToast(
        SUCCESS,
        `${count} ${pluralize('document', count)} permanently deleted.`,
      );
      setSelectedRowIds([]);
      setSelectedDocuments([]);
      setShowPermanentDeleteModal(false);
      resetPageIfEmpty();
    }

    if (isDeletePermanentlyError) {
      showToast(
        ERROR,
        `An error occurred: ${count} document${
          count === 1 ? ' was' : 's were'
        } not deleted.`,
      );
      setShowPermanentDeleteModal(false);
    }
  }, [isDeletePermanentlySuccess, isDeletePermanentlyError]);

  useEffect(() => {
    if (isRestoreDocumentsSuccess) {
      showToast(SUCCESS, `${count} ${pluralize('document', count)} restored.`);
      setSelectedRowIds([]);
      setSelectedDocuments([]);
      resetPageIfEmpty();
    }

    if (isRestoreDocumentsError) {
      showToast(
        ERROR,
        `An error occurred: ${count} document${
          count === 1 ? ' was' : 's were'
        } not restored.`,
      );
    }
  }, [isRestoreDocumentsSuccess, isRestoreDocumentsError]);

  const restoreDocumentToFolder = () => {
    restoreDocuments({
      documentIds,
      allSelected,
      folderId: selectedFolder,
      filters: query.filters,
    });
    setShowRestoreModal(false);
  };

  const renderFolderTreeRestoreModal = () => {
    return (
      <>
        {!folderError && !!folderData && (
          <FolderModal
            title="Select the folder to restore to"
            onSelectFolder={restoreDocumentToFolder}
            onHide={handleCancelRestore}
            visible={showRestoreModal}
            folderTreeProps={{
              label: '',
              folders: [folderData],
              onChange: setSelectedFolder,
              value: selectedFolder,
            }}
            disabled={!selectedFolder || (count || 0) > BULK_RESTORE_LIMIT}
            modalWidth="m"
            count={count}
          />
        )}
      </>
    );
  };

  const [
    restoreExceedsLimitModal,
    showRestoreExceedsLimitModal,
    hideRestoreExceedsLimitModal,
  ] = useLimitExceededModal({
    limit: BULK_RESTORE_LIMIT,
    count,
    actionType: 'restore',
    primaryActionHide: handleCancelRestore,
  });

  const [
    deleteExceedsLimitModal,
    showDeleteExceedsLimitModal,
    hideDeleteExceedsLimitModal,
  ] = useLimitExceededModal({
    limit: BULK_DELETE_LIMIT,
    count,
    actionType: 'permanentlyDelete',
    primaryActionHide: handleCancelPermanentDelete,
  });

  const renderConfirmPermanentDeleteModal = () => {
    return (
      <DeleteDocumentPermanentlyModal
        selectedDocuments={selectedDocuments}
        isVisible={showPermanentDeleteModal}
        totalCount={count}
        onConfirm={handlePermanentlyDeleteDocuments}
        onCancel={handleCancelPermanentDelete}
      />
    );
  };

  const nav = generateNav({
    current: {
      text: 'Restoration Hub',
      pathname: RoutePathType.AdminConsoleClientRestorationHub,
    },
    from: RoutePathType.AdminConsoleClient,
    params: {
      clientId: currentUser.client,
    },
  });

  const getAllData = async (isExport?: boolean) => {
    const endpoint = isAdminConsole
      ? getDeletedDocuments
      : getDeletedDocumentsForCurrentUser;
    const allQuery = { ...query, page: 1, pageSize: BULK_EXPORT_LIMIT };
    try {
      const response = await endpoint(allQuery);
      return response.results;
    } catch {
      let message =
        'An error occurred. Please try again later or contact support.';
      if (isExport) {
        message = 'Export failed. Please try again later or contact support.';
      }
      showToast(ERROR, message);
    }
  };

  const handleExport = async ({ columnOrder }: { columnOrder: string[] }) => {
    const allData = await getAllData(true);
    if (allData) {
      if (selectedRowIds.length && !allSelected) {
        const selectedData = allData.filter((item) =>
          selectedRowIds.includes(`${item.id}`),
        );
        if (selectedData.length) {
          exportXlsx({
            data: getExportDataFromColumns(selectedData, columnOrder),
            name: 'Deleted Documents',
          });
        }
      } else {
        exportXlsx({
          data: getExportDataFromColumns(allData, columnOrder),
          name: 'Deleted Documents',
        });
      }
    }
  };

  const pageTitle = isAdminConsole ? 'Restoration Hub' : 'My Deleted Items';
  const deletedBy = isAdminConsole ? 'any user' : 'you';
  const message =
    `Here you can find files that were deleted recently by ${deletedBy}. ` +
    'Files may be permanently deleted from here 90 days after their deletion ' +
    'from the platform. Once a file is permanently deleted it cannot be restored.';

  return (
    <Layout direction="column" spacing={8}>
      <PageLayout title={pageTitle} nav={nav} />
      <Alert enableIcon variant="info" key="info-message">
        {message}
      </Alert>
      <DocumentsRestoreFilters
        query={query}
        onChange={handleSetQuery}
        isAdminConsole={isAdminConsole}
      />
      <>
        <ContentContainer
          loadingContent={{
            isLoading: isLoading && !tableData,
            message: `Loading ${pageTitle}`,
          }}
        />
        {!error && !!tableData && !!tableData.results.length && (
          <PersistedTable
            context={tableContext}
            actions={[
              {
                key: 'export',
                icon: 'download',
                label: 'Export Excel',
                onClick: handleExport,
              },
            ]}
            columns={columns}
            data={tableData.results}
            isLoading={isLoading}
            name={pageTitle}
            options={{
              enableExportXlsx: false,
              enablePageSizeSelect: true,
              pageSizes: RESTORATION_HUB_PAGE_SIZE_LIST,
            }}
            rowActions={rowActions}
            bulkActions={bulkActions}
            state={{
              columnOrder,
              pageIndex: query.page,
              pageSize: query.pageSize,
              sortBy: [query.sort],
              selectedRowIds,
              isAllRowsSelected: allSelected,
            }}
            reactTableOptions={{
              autoResetSortBy: false,
              disableSortRemove: true,
              manualSortBy: true,
            }}
            totalCount={tableData.count}
            onPageSizeChange={handlePageSizeChange}
            onUpdate={handleTableUpdate}
            onPaginate={handlePaginate}
            onSetColumnOrder={setColumnOrder}
          />
        )}
        {!error && !!tableData && !tableData.results.length && (
          <Layout
            direction="column"
            spacing={8}
            maxW="516px"
            align="center"
            m="auto"
          >
            <FolderEmptyIcon />
            <Text textAlign="center">{message}</Text>
          </Layout>
        )}
      </>
      {count > BULK_RESTORE_LIMIT
        ? restoreExceedsLimitModal
        : renderFolderTreeRestoreModal()}
      {count > BULK_DELETE_LIMIT
        ? deleteExceedsLimitModal
        : renderConfirmPermanentDeleteModal()}
    </Layout>
  );
};

export default withCurrentUser(Page);
