import React from 'react';
import { GroupProps } from 'react-select';

import { Option } from '../../../types';
import { Text } from '../../Text';

export const GroupHeading = (props: GroupProps<Option, boolean>) => {
  const { children } = props;

  return (
    // this group heading is hidden to SRs, as it is associated in Group.tsx
    <Text aria-hidden display="inline-block" pb={1} preset="help" px={3}>
      {children}
    </Text>
  );
};
