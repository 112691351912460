import { useEffect, useState } from 'react';

import { DEFAULT_POLL_INTERVAL_MS, types } from '~/eds';
import { api } from '~/redux';
import { Nullable, Uuid } from '~/types';

import { ModelProgress } from '../types';
import { getModelProgressStatusChip, testIsTerminateProgress } from '../utils';

interface Props {
  modelId?: Uuid;
}

export const usePollModelProgress = ({
  modelId,
}: Props): {
  isLoading: boolean;
  progress: Nullable<ModelProgress>;
  progressChip: types.SharedChipProps;
} => {
  const [skipPoll, setSkipPoll] = useState(false);

  const {
    data: progress = null,
    isLoading,
    isUninitialized,
  } = api.endpoints.getPromptModelProgress.useQuery(
    {
      modelId: modelId!,
    },
    {
      pollingInterval: skipPoll ? undefined : DEFAULT_POLL_INTERVAL_MS,
      skip: !modelId,
    },
  );

  // this state management is a workaround for RTKQ not supporting keyed queries for `skip` API
  useEffect(() => {
    if (isUninitialized) {
      setSkipPoll(false);
    } else {
      setSkipPoll(testIsTerminateProgress(progress));
    }
  }, [modelId, progress]);

  const progressChip =
    isLoading || !progress
      ? { isLoading: true, text: 'Loading…', width: '100px' }
      : getModelProgressStatusChip(progress);

  return {
    isLoading,
    progress,
    progressChip,
  };
};
