export default `
<svg
  aria-hidden="true"
  aria-label="groups"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M13 5C13.8125 5 14.5 5.6875 14.5 6.5V8.5C14.5 9.34375 13.8125 10 13 10H12.75V11.25H17.5C18.4375 11.25 19.25 12.0625 19.25 13V14H19.5C20.3125 14 21 14.6875 21 15.5V17.5C21 18.3438 20.3125 19 19.5 19H17.5C16.6562 19 16 18.3438 16 17.5V15.5C16 14.6875 16.6562 14 17.5 14H17.75V13C17.75 12.875 17.625 12.75 17.5 12.75H12.75V14H13C13.8125 14 14.5 14.6875 14.5 15.5V17.5C14.5 18.3438 13.8125 19 13 19H11C10.1562 19 9.5 18.3438 9.5 17.5V15.5C9.5 14.6875 10.1562 14 11 14H11.25V12.75H6.5C6.34375 12.75 6.25 12.875 6.25 13V14H6.5C7.3125 14 8 14.6875 8 15.5V17.5C8 18.3438 7.3125 19 6.5 19H4.5C3.65625 19 3 18.3438 3 17.5V15.5C3 14.6875 3.65625 14 4.5 14H4.75V13C4.75 12.0625 5.53125 11.25 6.5 11.25H11.25V10H11C10.1562 10 9.5 9.34375 9.5 8.5V6.5C9.5 5.6875 10.1562 5 11 5H13ZM13 6.5H11V8.5H13V6.5ZM4.5 17.5H6.5V15.5H4.5V17.5ZM11 17.5H13V15.5H11V17.5ZM17.5 17.5H19.5V15.5H17.5V17.5Z"
    fill="currentcolor"
  />
</svg>
`;
