export default `
<svg
  aria-hidden="true"
  aria-label="lightbulb"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M9.5 18.2188C9.5 18.4062 9.53125 18.5938 9.65625 18.75L10.1875 19.5625C10.3438 19.8125 10.7188 20 11.0312 20H12.9375C13.25 20 13.625 19.8125 13.7812 19.5625L14.3125 18.75C14.4062 18.625 14.4688 18.375 14.4688 18.2188L14.5 17H9.5V18.2188ZM12 4C8.8125 4.03125 6.5 6.59375 6.5 9.5C6.5 10.875 7 12.125 7.84375 13.0938C8.375 13.6875 9.15625 14.9375 9.46875 15.9688C9.46875 15.9688 9.46875 15.9688 9.46875 16H14.5C14.5 15.9688 14.5 15.9688 14.5 15.9688C14.8125 14.9375 15.5938 13.6875 16.125 13.0938C16.9688 12.1562 17.5 10.9062 17.5 9.5C17.5 6.46875 15.0312 4.03125 12 4ZM15 12.1562C14.5 12.6875 13.9062 13.5938 13.4688 14.5H10.5C10.0625 13.5938 9.46875 12.6875 8.96875 12.1562C8.34375 11.4062 8 10.4688 8 9.5C8 7.5625 9.5 5.53125 11.9688 5.5C14.1875 5.5 16 7.3125 16 9.5C16 10.4688 15.625 11.4062 15 12.1562ZM11.5 6.5C10.0938 6.5 9 7.625 9 9C9 9.28125 9.21875 9.5 9.5 9.5C9.75 9.5 10 9.28125 10 9C10 8.1875 10.6562 7.5 11.5 7.5C11.75 7.5 12 7.28125 12 7.03125C12 6.75 11.75 6.5 11.5 6.5Z"
    fill="currentcolor"
  />
</svg>
`;
