import { useEffect } from 'react';

import AuthenticationStore from '~/auth';
import { LoginViewType, QueryParamType } from '~/enums';
import { getPageSearchQuery } from '~/utils/searchQuery';

const AS = AuthenticationStore();

export const useRolodexLoginProps = ({
  setStage,
  location,
  navigate,
}: {
  setStage: (stage: LoginViewType) => void;
  location: Location;
  navigate: (path: string) => void;
}) => {
  const searchParamKey = [...getPageSearchQuery().keys()][0];

  useEffect(
    () => {
      const handleRolodexResetPasswordCallback = (showWelcome = false) => {
        AS.removeInactiveTimer();
        AS.saveJwtTokenCreationTime();

        setLogIn(showWelcome);
      };

      switch (searchParamKey) {
        case QueryParamType.RolodexFirstLoginSuccess:
          return handleRolodexResetPasswordCallback(true);
        case QueryParamType.RolodexResetSuccess:
          return handleRolodexResetPasswordCallback();
        default:
          return undefined;
      }
    },
    [location.search, navigate], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const setLogIn = (showWelcome = false) => {
    setTimeout(() => {
      if (AS.isLoggedIn()) {
        showWelcome
          ? setStage(LoginViewType.LoggedInWithWelcome)
          : setStage(LoginViewType.LoggedIn);
      }
    }, 250);
  };

  const onRolodexLoginComplete = () => {
    setTimeout(() => {
      if (AS.isLoggedIn()) {
        AS.saveJwtTokenCreationTime();
        setStage(LoginViewType.LoggedIn);
      }
    }, 100);
  };
  return { onRolodexLoginComplete };
};
