import PT from 'prop-types';
import React from 'react';

import { getUserName } from '~/utils/user';

import { useShowMoreItems } from '../../hooks';
import FlexLayout from '../FlexLayout';
import UserAvatar, { userPropType } from '../UserAvatar';

function UserAvatarList({ limit = 3, users }) {
  const [visibleUsers, showMore] = useShowMoreItems({
    items: users,
    limit,
    mapItemText: getUserName,
  });

  return (
    <FlexLayout alignItems="center" flexWrap="wrap" flexWrapSpace={1} space={1}>
      {visibleUsers.map((user) => (
        <UserAvatar key={user.id} size="m" user={user} />
      ))}
      {showMore}
    </FlexLayout>
  );
}

UserAvatarList.propTypes = {
  /** Number of users to display */
  limit: PT.number,
  /** Array of User objects */
  users: PT.arrayOf(userPropType),
};

export default UserAvatarList;
