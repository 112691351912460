import { Layout, Text } from '~/eds';
import { Nullable } from '~/types';

import { OptimizeResult, OptimizeResultTag } from '../../../../types';
import { BoostResultsDetails } from './BoostResultsDetails';
import { BoostResultsOptions } from './BoostResultsOptions';

interface Props {
  results: OptimizeResult[];
  selectedOptimizeTag: Nullable<OptimizeResultTag>;
  onSelectOptimizeTag: (tag: OptimizeResultTag) => void;
}

export const BoostResults = ({
  results: initialResults,
  selectedOptimizeTag,
  onSelectOptimizeTag,
}: Props) => {
  const results = preprareResults(initialResults);

  if (!selectedOptimizeTag) {
    const selectedTag = results.find((result) => result.isRecommended)?.tag;
    if (selectedTag) {
      onSelectOptimizeTag(selectedTag);
    }
  }

  return (
    <Layout direction="column" preset="xl" mx="auto">
      <Layout direction="column" preset="xs">
        <Text variant="section" textAlign="center">
          AI Boost has found model variations with improved accuracy.
        </Text>
        <Text textAlign="center">
          Review the updates in the table below and select the variation you
          prefer.
        </Text>
      </Layout>
      <BoostResultsOptions
        results={results}
        selectedOption={selectedOptimizeTag!}
        onSelectOption={onSelectOptimizeTag}
      />
      <BoostResultsDetails
        results={results}
        selectedOption={selectedOptimizeTag!}
      />
    </Layout>
  );
};

/** If a result is `best_overall` but has `noUserConfigChanges`,
 * overwrite the `best_no_user_config_changes` result with that result */
const preprareResults = (results: OptimizeResult[]) => {
  const initialNoUserChanges = results.find(
    (result) => result.tag === 'best_no_user_config_changes',
  );
  const bestOverallNoUserChanges = results.find(
    (result) => result.noUserConfigChanges && result.tag === 'best_overall',
  );
  if (initialNoUserChanges && bestOverallNoUserChanges) {
    const filteredResults = results.filter(
      (result) => result.tag !== 'best_overall',
    );
    filteredResults.splice(results.indexOf(initialNoUserChanges), 1, {
      ...bestOverallNoUserChanges,
      tag: 'best_overall_no_user_config_changes',
    });
    return filteredResults;
  }
  return results;
};
