import { Filter } from '~/evifields';
import { toSearchV3Filter } from '~/redux/api/methods/searchV3';
import {
  BooleanQueryEntity,
  Query,
  QueryOperatorEntity,
  SearchFilter,
  SectionEntity,
} from '~/types';

import {
  testIsBooleanTextSearch,
  testIsFilter,
  testIsOperator,
  testIsSection,
} from '../../utils/queryUtils';

export type SectionFilters = {
  type: 'section';
  values: QueryFilters[];
};

export type QueryFilters =
  | SectionFilters
  | QueryOperatorEntity
  | Filter
  | BooleanQueryEntity;

export const mapQuerytoSectionFiltersOperators = (
  query: Query,
  searchFilters: Record<string, SearchFilter>,
): QueryFilters[] => {
  return query.map((item) => {
    if (testIsSection(item)) {
      return {
        type: 'section',
        values: item.value.map((a) =>
          mapQuerySectionEntityItemToSectionFilterItem(a, searchFilters),
        ),
      };
    } else {
      return mapQuerySectionEntityItemToSectionFilterItem(item, searchFilters);
    }
  });
};

const mapQuerySectionEntityItemToSectionFilterItem = (
  item: SectionEntity['value'][number],
  searchFilters: Record<string, SearchFilter>,
): QueryFilters => {
  if (testIsOperator(item) || testIsBooleanTextSearch(item)) {
    return item;
  } else if (testIsFilter(item)) {
    return toSearchV3Filter(item, searchFilters);
  } else {
    return {
      type: 'section',
      values: mapQuerytoSectionFiltersOperators(item.value, searchFilters),
    };
  }
};
