import {
  black2,
  bold,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  dataField: {
    marginBottom: '24px',
  },
  fieldTitle: {
    marginBottom: '8px',
    color: black2,
    fontSize: '12px',
    fontWeight: semibold,
    textTransform: 'uppercase',
  },
  fieldFooter: {
    display: 'inline-block',
    margin: '12px 0 0 8px',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
  },
  clickable: {
    cursor: 'pointer',
  },
  fieldFooterContent: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: '4px',
    },
  },
  multipleValuesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '12px',
  },
  multipleValuesEmpty: {
    color: black2,
    fontSize: '14px',
  },
};

export default styles;
