import { pilotV3 } from '~/services';

import { CreateUserParams, CreateUserResponse } from './roles';

interface SuperAdminParams extends Omit<CreateUserParams, 'role_uuid'> {}

export const createSuperAdmin: (
  body: SuperAdminParams,
) => Promise<CreateUserResponse> = async (body) => {
  return pilotV3.post('/users/platform-admin', { data: { attributes: body } });
};

export const deactivateSuperAdmin = ({
  superAdminId,
}: {
  superAdminId: string;
}) => {
  return pilotV3.remove(`/users/platform-admin/${superAdminId}`);
};
