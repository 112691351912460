import PropTypes from 'prop-types';
import React from 'react';

const AddIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h19.608v19.608H0z" opacity={props.opacity} />
        <path
          fill={props.color}
          d="M15.58 4.027a8.162 8.162 0 0 0-11.553 0 8.162 8.162 0 0 0 0 11.554 8.162 8.162 0 0 0 11.554 0 8.162 8.162 0 0 0 0-11.554zM9.805 14.772a.814.814 0 0 1-.815-.814v-3.34H5.65a.814.814 0 1 1 0-1.629h3.34V5.65a.814.814 0 1 1 1.628 0v3.34h3.34a.814.814 0 1 1 0 1.628h-3.34v3.34c0 .439-.37.808-.814.814z"
        />
      </g>
    </svg>
  );
};

AddIcon.defaultProps = {
  size: '20',
  color: '#0DA64E',
  opacity: '0.87',
};

AddIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
};

export default AddIcon;
