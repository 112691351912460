import React from 'react';

import EcTextarea from '~/components/Shared/EcTextarea';
import UserSelect from '~/components/Shared/UserSelect';
import { IconButton } from '~/eds';
import { Box, FlexLayout, Text } from '~/ui';
import { CHARACTER_INPUT_LIMIT_LONG } from '~/ui/enums/input';

function NotificationItem({ notification, onRemove, onUpdate }) {
  const { id, message, userIds } = notification;

  let error;

  if (message.length > CHARACTER_INPUT_LIMIT_LONG) {
    error = true;
  }

  function handleUserMultiSelectOnChange(selectedUserIds) {
    onUpdate(id, selectedUserIds, message);
  }

  function handleTextAreaChange(value) {
    onUpdate(id, userIds, value);
  }

  return (
    <FlexLayout flexDirection="column" space={3} bg="gray-200" px={6} py={4}>
      <FlexLayout alignItems="center" justifyContent="space-between" space={2}>
        <Box sx={{ flexGrow: 1 }}>
          <UserSelect
            isMulti={true}
            name="notification-user-select"
            value={userIds}
            onChange={handleUserMultiSelectOnChange}
            width="100%"
          />
        </Box>
        <IconButton icon="trash" tooltip="remove" onClick={onRemove} />
      </FlexLayout>
      <FlexLayout flexDirection="column" space={2}>
        <Text variant="xs-dense-caps">Custom Message</Text>
        <EcTextarea
          limit={CHARACTER_INPUT_LIMIT_LONG}
          error={error}
          value={message}
          onChange={(event) => {
            handleTextAreaChange(event.target.value);
          }}
        />
      </FlexLayout>
    </FlexLayout>
  );
}

export default NotificationItem;
