export default `
<svg
  aria-hidden="true"
  aria-label="analyzer"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M17.4062 6.9375L15.0625 4.59375C14.6875 4.21875 14.1875 4 13.6562 4H8C6.875 4 6 4.90625 6 6V18C6 19.125 6.875 20 8 20H16C17.0938 20 18 19.125 18 18V8.34375C18 7.8125 17.7812 7.3125 17.4062 6.9375ZM16.5 18C16.5 18.2812 16.25 18.5 16 18.5H8C7.71875 18.5 7.5 18.2812 7.5 18V6.03125C7.5 5.75 7.71875 5.53125 8 5.53125H13V8C13 8.5625 13.4375 9 14 9H16.4688V18H16.5ZM11.5 10.5C9.8125 10.5 8.5 11.8438 8.5 13.5C8.5 15.1875 9.8125 16.5 11.5 16.5C12.0312 16.5 12.5625 16.3438 13 16.0938L14.4688 17.5312C14.5938 17.6875 14.7812 17.75 15 17.75C15.1875 17.75 15.375 17.6875 15.5 17.5312C15.8125 17.25 15.8125 16.7812 15.5 16.5L14.0625 15.0312C14.3125 14.5938 14.5 14.0625 14.5 13.5C14.5 11.8438 13.1562 10.5 11.5 10.5ZM11.5 15C10.6562 15 10 14.3438 10 13.5C10 12.6875 10.6562 12 11.5 12C12.3125 12 13 12.6875 13 13.5C13 14.3438 12.3125 15 11.5 15Z"
    fill="currentcolor"
  />
</svg>
`;
