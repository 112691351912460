import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import FolderPanel from '~/components/Shared/FolderPanel';
import { BULK_COPY_LIMIT, BULK_MOVE_LIMIT } from '~/constants/max_lengths';
import { withIsSelectedHash, withTreeNodeKeyDownHandler } from '~/hocs';
import { Alert } from '~/ui';
import { TreeNode } from '~/utils/TreeNode';

import { ERROR } from '../../../types/toast.types';
import EcButton from '../../Shared/EcButton';
import EcFolderTreeItem from '../../Shared/EcFolderTreeItem';
import EcModalCard from '../../Shared/EcModalCard';
import { showToast } from '../../Shared/EcToast';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import { getFolderTree } from '../../UploadPage/UploadPage.data';
import styles from './FolderTreeModal.styles';

const FolderTreeItem = withTreeNodeKeyDownHandler(EcFolderTreeItem);

class FolderTreeModal extends Component {
  state = {
    loading: true,
    folderTree: null,
    uploadLocation: null,
    uploadLocations: [],
    documentCount: null,
    overServerLimit: false,
  };

  async componentDidMount() {
    const {
      fetchDocumentsCount,
      uploadLocation,
      overLimit,
      bulkAction,
      enableSearch,
    } = this.props;
    const defaultFetchDocuments = () => Promise.resolve({});
    const fetchCount =
      fetchDocumentsCount && !overLimit
        ? fetchDocumentsCount
        : defaultFetchDocuments;
    const limitCountPerActionType =
      bulkAction === 'Copy' ? BULK_COPY_LIMIT : BULK_MOVE_LIMIT;

    try {
      const [folderTree, { document_count }] = await Promise.all([
        getFolderTree(),
        fetchCount(),
      ]);
      const newUploadLocation = uploadLocation || folderTree;
      this.setState({
        folderTree: new TreeNode(folderTree),
        uploadLocation: !enableSearch ? newUploadLocation : null,
        loading: false,
        overServerLimit: document_count > limitCountPerActionType,
      });
    } catch (e) {
      showToast(ERROR, 'An error occurred while fetching a folder tree.');
    }
  }

  handleUploadSelect = (folder) => {
    if (folder.editable) {
      this.props.setIsSelected(this.state.uploadLocation.id, false);
      this.props.setIsSelected(folder.id, true);
      this.setState({ uploadLocation: folder.treeNode });
    }
  };

  handleUploadLocationMultiSelect = (location, checked) => {
    const { uploadLocations } = this.state;

    if (checked) {
      this.setState({
        uploadLocations: [...uploadLocations, location.treeNode],
      });
    } else {
      let newFolders = uploadLocations.filter((f) => f.id !== location.id);
      this.setState({ uploadLocations: newFolders });
    }
  };

  handleUploadMultiSelect = (folder) => {
    if (!folder.path?.length) return;

    if (!this.props.folderIdsSelected?.includes(folder.id)) {
      const isSelected = this.props.getIsSelected(folder.id);

      this.handleUploadLocationMultiSelect(folder, !isSelected);
      this.props.setIsSelected(folder.id, !isSelected);
    }
  };

  handleOnChooseLocationClick = () => {
    const { handleUploadLocationChange, hideModal } = this.props;
    const { uploadLocation } = this.state;

    handleUploadLocationChange(uploadLocation);
    hideModal();
  };

  handleChooseMultipleLocationsClick = () => {
    const { handleChooseMultipleLocationsClick, hideModal } = this.props;
    const { uploadLocations } = this.state;

    handleChooseMultipleLocationsClick(uploadLocations);
    hideModal();
  };

  handleSelectFolder = (folder) => {
    if (this.props.isMultiSelect) {
      this.handleUploadMultiSelect(folder);
    } else {
      this.handleUploadSelect(folder);
    }
  };

  handleSelectFolderSearch = (folder) => {
    this.setState({ uploadLocation: folder });
  };

  renderLimitWarning() {
    const { bulkAction, overLimit } = this.props;
    const { overServerLimit } = this.state;
    const limitCountPerActionType =
      bulkAction === 'Copy' ? BULK_COPY_LIMIT : BULK_MOVE_LIMIT;

    if (overLimit || overServerLimit) {
      return (
        <Alert enableIcon variant="warning">
          Only {limitCountPerActionType} documents can be{' '}
          {bulkAction === 'Copy' ? 'copied' : 'moved'} at a time. Please select
          fewer documents to complete this action.
        </Alert>
      );
    } else {
      return null;
    }
  }

  renderFolderTree() {
    const {
      classes,
      folderIdsSelected,
      isMultiSelect,
      getIsSelected,
      setIsSelected,
      disableSyncPaired,
      enableSearch,
    } = this.props;
    const { folderTree, uploadLocation, uploadLocations, loading } = this.state;

    return (
      <div className={classes.folderTree}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <LoadingSpinner size="medium" />
          </div>
        ) : (
          <>
            {this.renderLimitWarning()}

            {enableSearch ? (
              <FolderPanel
                onSelect={this.handleSelectFolderSearch}
                folderPathId={uploadLocation?.id}
                selectedFolder={uploadLocation}
                disableSyncPaired={disableSyncPaired}
                shouldDisable={(folder) => {
                  return (
                    !folder.editable ||
                    !!(disableSyncPaired && folder.sync_paired)
                  );
                }}
              />
            ) : (
              <FolderTreeItem
                firstToOpenId={folderTree.id}
                folder={folderTree}
                folderIdsSelected={folderIdsSelected}
                getIsSelected={getIsSelected}
                isMultiSelect={isMultiSelect}
                onSelect={this.handleSelectFolder}
                setIsSelected={setIsSelected}
                uploadLocation={uploadLocation}
                uploadLocations={uploadLocations}
                disableSyncPaired={disableSyncPaired}
              />
            )}
          </>
        )}
      </div>
    );
  }

  render() {
    const {
      classes,
      title,
      confirmButtonIcon,
      confirmButtonText,
      hideModal,
      isMultiSelect,
      folderIdsSelected,
      overLimit,
    } = this.props;
    const { uploadLocation, uploadLocations, overServerLimit } = this.state;

    const handleOnClick = isMultiSelect
      ? this.handleChooseMultipleLocationsClick
      : this.handleOnChooseLocationClick;

    return (
      <EcModalCard
        title={title}
        content={<>{this.renderFolderTree()}</>}
        footer={
          <>
            {isMultiSelect && (
              <Fragment>
                <div className={classes.modalHelpText}>
                  {folderIdsSelected.length + uploadLocations.length} folder(s)
                  selected
                </div>
              </Fragment>
            )}
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              yellow
              disabled={!uploadLocation?.id || overLimit || overServerLimit}
              iconLeft={confirmButtonIcon}
              text={confirmButtonText}
              onClick={handleOnClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

export default injectSheet(styles)(withIsSelectedHash(FolderTreeModal));
