import uuid from 'uuid';

import { formatDate, formatNumber, pluralize } from '~/eds';
import { Nullable } from '~/types';

import {
  getFilterValuesLength,
  getOperator,
  operatorIdsByFieldType,
  operatorLabelsByFieldType,
  toTextSearchOption,
} from '../../../evifields';
import {
  AsyncValue,
  ClauseValue,
  DatePeriod,
  DateValue,
  EmptyFilter,
  Field,
  FieldId,
  Filter,
  Group,
  GroupedFieldOption,
  GroupId,
  LabeledOperator,
  NumberValue,
  Operator,
  OperatorId,
  Value,
} from './types';

export const addFilters = (
  filters: Filter[],
  selectedFieldIds: Record<GroupId, FieldId[]>,
): Filter[] =>
  filters.concat(Object.values(selectedFieldIds).flat().map(createFilter));

export const clearFilter = (filter: Filter): EmptyFilter => ({
  ...filter,
  asyncValue: undefined,
  operatorId: null,
  hasError: false,
  values: [],
});

export const clearFilterValues = (filter: Filter): Filter => ({
  ...filter,
  asyncValue: undefined,
  hasError: false,
  values: [],
});

export const createFilter = (fieldId: FieldId): EmptyFilter => ({
  id: uuid.v4(),
  fieldId,
  operatorId: null,
  values: [],
});

export const groupFieldOptions = ({
  fields,
  groups,
}: {
  fields: Record<FieldId, Field>;
  groups: Group[];
}): GroupedFieldOption[] =>
  groups.map(({ id, fieldIds, label, isPinnable }) => ({
    isPinnable,
    label,
    name: id,
    options: fieldIds.map((fieldId) => {
      const field = fields[fieldId];
      return {
        label: field.label,
        value: field.id,
        description: label,
      };
    }),
  }));

export const removeFilter = (
  filters: Filter[],
  filterToRemove: Filter,
): Filter[] => filters.filter((filter) => filter.id !== filterToRemove.id);

// A filter has empty values if it has a non-zero cardinality operator assigned but values have not been specified.
export const testIsEmptyValueFilter = (filter: Filter): boolean => {
  const { operatorId } = filter;
  const filterValuesLength = getFilterValuesLength(filter);
  const operator = getOperator(operatorId);
  const operatorCardinality = operator?.cardinality || 0;

  return operatorCardinality > 0 && filterValuesLength === 0;
};

export const updateFilter = (
  filters: Filter[],
  updatedFilter: Filter,
): Filter[] =>
  filters.map((filter) =>
    filter.id === updatedFilter.id ? updatedFilter : filter,
  );

export const updateFilterOperator = (
  filter: Filter,
  operatorId: Nullable<OperatorId>,
) => ({
  ...clearFilter(filter),
  operatorId,
});

export const updateFilterValues = (
  filter: Filter,
  values: Value[],
  asyncValue?: AsyncValue<Value>,
) => ({
  ...filter,
  asyncValue,
  hasError: false,
  values,
});

export const getLabeledOperator = (
  field: Field,
  operatorId: Nullable<OperatorId>,
): Nullable<LabeledOperator> => {
  if (operatorId === null) {
    return null;
  }

  const operator = getOperator(operatorId) as Operator;
  if (!field) {
    return null;
  }

  const { overrideOperatorLabels, type } = field;

  const overrideOperatorLabel = (overrideOperatorLabels || {})[operatorId];

  // label priority: override > official > 'Unknown'
  const label =
    overrideOperatorLabel ||
    operatorLabelsByFieldType[type][operatorId] ||
    'Unknown';

  return {
    ...operator,
    label,
  };
};

export const getOperatorsByFieldType = (field: Field): LabeledOperator[] => {
  const whitelistOperatorIds = field?.whitelistOperatorIds || [];
  const { type } = field;

  const operatorIds =
    whitelistOperatorIds.length > 0
      ? whitelistOperatorIds
      : operatorIdsByFieldType[type];

  return operatorIds.map((operatorId) => {
    return getLabeledOperator(field, operatorId) as LabeledOperator;
  });
};

// always enable searching of options if in async mode or if optionsLength exceeds threshold (5).
export const testEnableSearch = (isAsync: boolean, optionsLength: number) =>
  isAsync || optionsLength > 5;

export const coerceFormatDate = (dateValue: DateValue) => {
  const isDate = dateValue instanceof Date;
  if (dateValue === null) {
    return '';
  } else if (isDate) {
    return dateValue === null ? '' : formatDate(dateValue as Date);
  } else {
    const { value, unit } = dateValue as DatePeriod;
    return pluralize(value, unit || '');
  }
};

export const coerceFormatNumber = (numberValue: NumberValue) =>
  numberValue === null ? '' : formatNumber(numberValue);

export const serializeClauseFilter = (
  filter: Filter<ClauseValue>,
): {
  clauseSearchOperatorLabel: string;
  clauseSearchText: string;
  provisionText: string;
} => {
  const { values } = filter;
  const [clauseValue] = values;

  let clauseSearchOperatorLabel = '';
  let clauseSearchText = '';
  let provisionText = '';

  if (clauseValue) {
    const { provision, text_search: textSearches } = clauseValue;
    provisionText = provision;
    if (textSearches.length > 0) {
      const [textSearch] = textSearches;
      const option = toTextSearchOption(textSearch);
      clauseSearchOperatorLabel = ` and ${option.label}`;
      clauseSearchText = textSearch.text || '';
    }
  }

  return {
    clauseSearchOperatorLabel,
    clauseSearchText,
    provisionText,
  };
};
