import PT from 'prop-types';
import React from 'react';

import { Icon } from '~/eds';
import { FlexLayout, Text } from '~/ui';
import { getUserName } from '~/utils/user';

import Avatar from './Avatar';

function UserAvatar({ shouldRenderName, size = 'xl', user }) {
  if (!user) return <Icon color="status.info" icon="user" size={size} />;

  return (
    <FlexLayout alignItems="center" justifyContent="center" space={2}>
      <Avatar showTooltip={!shouldRenderName} size={size} user={user} />
      {shouldRenderName && (
        <Text shouldTruncate color="gray-900" variant="s-spaced">
          {getUserName(user)}
        </Text>
      )}
    </FlexLayout>
  );
}

export const userPropType = PT.shape({
  /** Avatar source */
  avatar: PT.string,
  /** First name */
  firstName: PT.string,
  /** Last name */
  lastName: PT.string,
});

UserAvatar.propTypes = {
  /** Indicates if name should be rendered */
  shouldRenderName: PT.bool,
  /** Avatar size */
  size: PT.oneOf(['xs', 's', 'm', 'l', 'xl']),
  /** User object: { avatarSrc, firstName, lastName }  */
  user: userPropType,
};

export default UserAvatar;
