export default `
<svg
  aria-hidden="true"
  aria-label="checkbox-indeterminate"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M7 5C5.875 5 5 5.90625 5 7V17C5 18.125 5.875 19 7 19H17C18.0938 19 19 18.125 19 17V7C19 5.90625 18.0938 5 17 5H7ZM9.75 11.25H14.25C14.6562 11.25 15 11.5938 15 12C15 12.4375 14.6562 12.75 14.25 12.75H9.75C9.3125 12.75 9 12.4375 9 12C9 11.5938 9.3125 11.25 9.75 11.25Z"
    fill="currentcolor"
  />
</svg>
`;
