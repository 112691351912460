import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions';
import binocularsSrc from '~/assets/images/cta/binoculars.svg';
import rocketSrc from '~/assets/images/cta/rocket.svg';
import { showToast } from '~/components/Shared/EcToast';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { Button } from '~/eds';
import { ManageSyncPairStageType } from '~/enums';
import { api } from '~/redux';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { CtaSection, FlexLayout, InputLabel, TextInput } from '~/ui';

import AmazonS3Instruction from './AmazonS3Instruction';

function AmazonS3Auth({
  enableGuide,
  showInstruction,
  onToggleEnableGuide,
  onToggleShowInstruction,
  activeSyncPair,
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const [roleArn, setRoleArn] = useState('');
  const isReauthenticate = !!activeSyncPair?.isReauthenticate;

  const [
    createS3TokenId,
    createTokenResult,
  ] = api.endpoints.createS3TokenId.useMutation();
  const {
    data: tokenData,
    isError: isTokenError,
    isLoading: isTokenLoading,
  } = createTokenResult;

  const [
    updateSyncPair,
    updateSyncPairResult,
  ] = api.endpoints.updateSyncPair.useMutation();
  const {
    data: updatedPair,
    isError: isUpdateError,
    isLoading: isUpdateLoading,
  } = updateSyncPairResult;

  useEffect(() => {
    if (isTokenError) {
      showToast(ERROR, 'Failed to exchange the S3 token ID. Please try again.');
    }
    if (isUpdateError) {
      showToast(ERROR, 'Failed to update Sync Pair. Please try again.');
    }
  }, [isTokenError, isUpdateError]);

  useEffect(() => {
    if (updatedPair) {
      showToast(SUCCESS, `${activeSyncPair.name} sync pair has been updated.`);

      integrationsAddSyncPair(updatedPair);
      integrationsSetManageSyncPairStage(ManageSyncPairStageType.None);
    }
  }, [updatedPair]);

  useEffect(() => {
    if (!isTokenLoading && tokenData) {
      if (isReauthenticate) {
        updateSyncPair({ ...activeSyncPair, tokenId: tokenData.id });
      } else {
        integrationsSetActiveSyncPair({
          ...activeSyncPair,
          tokenId: tokenData.id,
        });
        integrationsSetManageSyncPairStage(ManageSyncPairStageType.Folders);
      }
    }
  }, [tokenData]);

  if (showInstruction && !isReauthenticate) {
    return <AmazonS3Instruction />;
  }

  if (enableGuide && !isReauthenticate) {
    return (
      <FlexLayout space={4}>
        <CtaSection
          actionButton={{
            text: 'Go to help guide',
            onClick: () => onToggleShowInstruction(true),
          }}
          description="AWS Admin: Login into your AWS Console to access bucket"
          imageSrc={binocularsSrc}
        />
        <CtaSection
          actionButton={{
            text: 'Create sync pairs',
            onClick: () => onToggleEnableGuide(false),
          }}
          description="Evisort Admin: Set up Sync pair on Evisort platform with bucket access"
          imageSrc={rocketSrc}
        />
      </FlexLayout>
    );
  }

  return (
    <FlexLayout
      alignItems="center"
      flex="auto"
      flexDirection="column"
      space={12}
    >
      <ProviderLogo provider="AMAZON_S3" />
      <InputLabel
        id="amazon-s3-auth-input-label"
        isRequired={true}
        label="Add IAM Role ARN"
      >
        <FlexLayout flexDirection="column" space={8}>
          <FlexLayout flexDirection="column">
            <TextInput
              placeholder="Example: arn:aws:iam::123456..."
              value={roleArn}
              width="l"
              onChange={setRoleArn}
              isTokenError={
                isTokenError
                  ? 'Please make sure you have entered a valid IAM Role ARN.'
                  : undefined
              }
            />
          </FlexLayout>
          <Button
            isLoading={isTokenLoading || isUpdateLoading}
            isFullWidth={true}
            text={isReauthenticate ? 'Reauthenticate' : 'Check Value'}
            onClick={() => createS3TokenId({ roleArn })}
            variant="primary"
          />
        </FlexLayout>
      </InputLabel>
    </FlexLayout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
});

export default connect(mapStateToProps, {
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(AmazonS3Auth);
