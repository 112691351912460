import React from 'react';
import { connect } from 'react-redux';

import { partyFieldDefinitions } from '~/constants/workflow';
import { getFieldDefinitionStatus } from '~/utils/workflow';

import RadioItem from './RadioItem';

function RadioParties({
  selectedFieldDefinition,
  onClick,
  onShowMore,
  // connected
  fieldLinks,
}) {
  return (
    <>
      {partyFieldDefinitions.map((fieldDefinition) => {
        const { isSelected } = getFieldDefinitionStatus({
          fieldDefinition,
          fieldLinks,
          selectedFieldDefinition,
        });
        return (
          <RadioItem
            key={fieldDefinition.key}
            option={{
              label: fieldDefinition.label,
              value: fieldDefinition.key,
            }}
            selected={isSelected}
            onClick={() => {
              onClick(fieldDefinition);
              onShowMore();
            }}
            onShowMore={onShowMore}
          />
        );
      })}
    </>
  );
}

const mapStateToProps = ({ workflow }) => ({
  fieldLinks: workflow.fieldLinks,
});

export default connect(mapStateToProps)(RadioParties);
