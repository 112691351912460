/**
 * Calculates percentage
 * @param x number you are getting percentage for
 * @param y number you are getting percentage of
 * @param fixed number of decimal places
 * @returns number | null
 */
export const calcPercentage = (x: number, y: number, fixed = 2) => {
  const percent = (x / y) * 100;
  if (!Number.isNaN(percent)) {
    return Number(percent.toFixed(fixed));
  } else {
    return null;
  }
};
