import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ContentContainer } from '~/eds';

// TODO: deprecate this indirection and simply use `eds.ContentContainer` directly
class LoadingSpinner extends Component {
  render() {
    const { message } = this.props;
    return <ContentContainer loadingContent={{ isLoading: true, message }} />;
  }
}

LoadingSpinner.propTypes = {
  /** Optional loading message */
  message: PropTypes.string,
  /** With FF on (serving EDS LoadingContent), optional mode ('quote' mode
   * or default 'message' mode) to be passed to EDS LoadingContent */
  mode: PropTypes.oneOf(['message', 'quote']),
};

export default LoadingSpinner;
