import PT from 'prop-types';
import React, { useEffect, useState } from 'react';

import { IconButton, Link, Text, TruncateText } from '~/eds';

import Box from '../Box';
import FlexLayout from '../FlexLayout';
import NumberInput from '../NumberInput';

const MIN = 1;

const labelBox = {
  maxWidth: '200px',
};

const customErrorStyles = {
  width: 'max-content',
  top: -7,
  right: 0,
  left: 'auto',
  height: '24px',
};

export default function Navigator({
  debug = false,
  label,
  onClick,
  onChange,
  onNext,
  onPrev,
  total,
  tooltipNext,
  tooltipPrev,
  value,
}) {
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => setInputValue(value), [value]);

  const hasPrev = value > MIN;
  const hasNext = value < total;

  const isOutOfRange = (val) => val < MIN || val > total;

  const handleConfirm = () => {
    if (!isOutOfRange(inputValue)) onChange(inputValue);
  };

  return (
    <FlexLayout alignItems="center" space={2}>
      {
        <IconButton
          disabled={!hasPrev}
          icon="chevron-left"
          tooltip={tooltipPrev}
          text="previous"
          onClick={onPrev}
        />
      }
      <Box sx={labelBox}>
        <Link onClick={onClick}>
          <TruncateText color="action.link" textTransform="uppercase">
            {label}
          </TruncateText>
        </Link>
      </Box>
      <NumberInput
        ariaDescribedby="documentNavigatorRange"
        ariaLabel="Document navigation"
        value={inputValue}
        onChange={setInputValue}
        onBlur={handleConfirm}
        onEnter={handleConfirm}
        min={MIN}
        max={total}
        width="fitContent"
        disableErrorMessage={false}
        customErrorStyles={customErrorStyles}
      />
      <Text>/</Text>
      <Text>{total}</Text>
      {
        <IconButton
          disabled={!hasNext}
          icon="chevron-right"
          tooltip={tooltipNext}
          text="next"
          onClick={onNext}
        />
      }

      {debug && <Text>value: {value}</Text>}
    </FlexLayout>
  );
}

Navigator.propTypes = {
  /** Toggles debug mode.  */
  debug: PT.bool,
  label: PT.string,
  onChange: PT.func.isRequired,
  onNext: PT.func.isRequired,
  onPrev: PT.func.isRequired,
  total: PT.number.isRequired,
  value: PT.number.isRequired,
};
