import React from 'react';

import TextSnippets from '~/components/Shared/TextSnippet/TextSnippets';
import { Layout } from '~/eds';
import { SnippetPart } from '~/redux/api/methods';

interface Props {
  matches: {
    name: string;
    content: SnippetPart[][];
  }[];
}

function SearchMatches({ matches = [] }: Props) {
  if (!matches.length) {
    return null;
  }

  return (
    <Layout preset="sections">
      {matches.map((match, index) => {
        return (
          <TextSnippets
            key={index}
            title={match.name}
            snippets={match.content}
          ></TextSnippets>
        );
      })}
    </Layout>
  );
}

export default SearchMatches;
