import React from 'react';

import { Option as BaseOption, Nullable, RecordKey } from '../../types';
import { typedMemo } from '../../utils';
import { Layout } from '../Layout';
import { RadioGroup } from '../RadioGroup';

type GroupValue<V> = Nullable<V>;

type Option<V> = BaseOption<V, { group: string }>;

type Value<V> = Nullable<V>;

interface Group<V> {
  /** RadioGroup options */
  options: Option<V>[];
  /** RadioGroup title */
  title: string;
  /** Group info tooltip */
  info?: string;
}

interface Props<V> {
  /** Radio groups */
  groups: Group<V>[];
  /** RadioGroups' name */
  name: string;
  /** Value tracking selected values keyed on group names */
  value: Value<V>;
  /** Value change handler */
  onChange: (updatedValue: Value<V>) => void;
  /** Group columns */
  columns?: number;
  /** When option.label matches search, render a text match */
  search?: string;
}

export const RadioGroups = typedMemo(
  <V extends RecordKey>({
    columns = 1,
    groups,
    name,
    search,
    value,
    onChange,
  }: Props<V>) => {
    const handleChange = (updatedGroupValue: GroupValue<V>) => {
      const updatedValue = updatedGroupValue;
      onChange(updatedValue);
    };

    return (
      <Layout direction="column" spacing={6}>
        {groups.map(({ info, options, title }) => (
          <RadioGroup
            key={`${name}_${title}`}
            columns={columns}
            info={info}
            title={title}
            name={name}
            options={options}
            search={search}
            value={value}
            onChange={handleChange}
          />
        ))}
      </Layout>
    );
  },
);
