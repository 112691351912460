import borders from '~/ui/theme/borders';
import colors from '~/ui/theme/colors';
import radii from '~/ui/theme/radii';
import shadows from '~/ui/theme/shadows';

const { palette } = colors;

const styles = {
  popover: {
    backgroundColor: palette['white'],
    border: borders['border-dark'],
    borderRadius: radii['m'],
    boxShadow: shadows['depth-3'],
    color: palette['gray-900'],
    maxWidth: 'unset !important', // override tippy behavior

    '& .tippy-content': {
      padding: 0,
    },
  },
};

export default styles;
