import isHotkey from 'is-hotkey';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Editable, Slate } from 'slate-react';
import { useStyles } from 'uinix-ui';

import {
  Box,
  CharacterLimit,
  Icon,
  IconButton,
  Layout,
  Text,
} from '../../components';
import { useEscape, useToggle } from '../../hooks';
import { spacings } from '../../tokens';
import {
  BaseLoadingContentProps,
  BasePaginateProps,
  StatusType,
  UserAction,
} from '../../types';
import {
  coerceAst,
  resetAst,
  serializeContentAst,
  testIsEmptyAst,
  wordCount,
} from '../../utils';
import { MentionEntityType } from '../Comments/types';
import { ActionsBar } from './actions';
import { createEditor, Element, Leaf } from './editor';
import { FormatBar, useFormat } from './format';
import {
  MentionSuggestions,
  triggerMentions,
  useMentions,
  withEnhanceElement,
} from './mentions';
import { ContentAst, Format, MentionedEntity, Mentions } from './types';
import { blurEditor, clearEditor, deselectEditor, focusEditor } from './utils';

interface Props {
  /** Submit and post the content */
  onPost: (updatedContent: ContentAst) => void;
  /** Additional user actions */
  actions?: UserAction[];
  /** Content */
  content?: ContentAst;
  /** Disables the editor (post and actions are disabled, but editing/drafting is still supported) */
  disabled?: boolean;
  /** Disables the visual post button and submit keyboard */
  disablePostButton?: boolean;
  /** Enables mentions */
  enableMention?: boolean;
  /** Enable resizing the editor */
  enableResize?: boolean;
  /** Formatting options */
  formats?: Format[];
  /** Custom header */
  header?: React.ReactNode;
  /** Focuses texteditor once rendered */
  isFocused?: boolean;
  /** Flag to check if mentions is loading */
  isMentionsLoading?: boolean;
  /** Posting state */
  isPosting?: boolean;
  /** Is replaying */
  isReplying?: boolean;
  /** Character limit, or word limit if `limitMode="words"` */
  limit?: number;
  /** Indicates if the limit should be calculated by character or word count */
  limitMode?: 'characters' | 'words';
  /** Loading content (differentiated from `isPosting` as editor can be loading without posting) */
  loadingContent?: BaseLoadingContentProps;
  /**
   * Mentions
   * TODO: rework this API as domain logic has creeped in
   */
  mentions?: Mentions;
  /** Supported UI modes */
  mode?: 'preview' | 'text';
  /** Pagination support (useful for supporting versions of input values) */
  paginate?: BasePaginateProps;
  /** Placeholder */
  placeholder?: string;
  /** Editor is read-only (cannot be edited) */
  readOnly?: boolean;
  /** Resolved mentions */
  resolvedMentions?: Mentions;
  /** Status of editor */
  status?: StatusType;
  /** Callback when editor is blurred (used with `isFocused` for controlled situations) */
  onBlur?: () => void;
  /** Callback when cancel (ESC) is triggered */
  onCancel?: () => void;
  /** Callback when editor is focused (used with `isFocused` for controlled situations) */
  onFocus?: () => void;
  /** Callback when AI is interrupted */
  onInterrupt?: () => void;
  /** Updating the content */
  onChange?: (updatedContent: ContentAst) => void;
  /** Callback for searching async mentions */
  onMentionSearch?: (updatedSearch: string) => void;
  /** callback when selecting a mentionee without permissions on the Entity */
  onMention?: (mention: MentionedEntity<MentionEntityType>) => void;
  /** Intentional API to deprecate improved post behavior (ENTER on post) */
  DEPRECATED_enableImprovedPost?: boolean;
}

const defaultContent: ContentAst = [];

export const TextEditor = memo(
  ({
    actions = [],
    content: initialContent = defaultContent,
    disabled,
    disablePostButton,
    enableMention,
    enableResize,
    formats = [],
    header,
    isFocused: overrideIsFocused = false,
    isMentionsLoading = false,
    isPosting = false,
    isReplying = false,
    limit,
    limitMode = 'characters',
    loadingContent,
    mentions = {},
    mode,
    paginate,
    placeholder,
    readOnly,
    resolvedMentions = {},
    status,
    onBlur,
    onCancel,
    onChange,
    onFocus,
    onInterrupt,
    onMention,
    onMentionSearch,
    onPost,
    DEPRECATED_enableImprovedPost = false,
  }: Props) => {
    // content
    const [content, setContent] = useState(initialContent);

    // this state is used only for determining text.length character limit.  We perform the serialization (costly) logic only when the limit is specified
    const text = useMemo(() => {
      return limit ? serializeContentAst(content) : '';
    }, [limit, content]);

    useEffect(() => setContent(initialContent), [initialContent]);

    // editor
    const styles = useStyles();
    const editor = useMemo(createEditor, []);
    const renderElement = useCallback(
      withEnhanceElement(Element, { mentions: resolvedMentions }),
      [resolvedMentions],
    );
    const renderLeaf = useCallback(Leaf, []);
    const [isFocused, _toggleIsFocused, focus, blur] = useToggle();
    const [
      isEditing,
      _toggleIsEditing,
      enableIsEditing,
      disableIsEditing,
    ] = useToggle();

    // format
    const { enableFormat, handleFormatHotkey, toggleEnableFormat } = useFormat({
      editor,
      formats,
    });

    // mentions
    const {
      handleInsertMention,
      handleNavigateMention,
      handleSelectMention,
      index,
      hasSuggestions,
      search: mentionSearch,
      suggestions,
      target: mentionTarget,
      onSetIndex,
    } = useMentions({
      editor,
      mentions,
      onMention,
      onMentionSearch,
    });

    const hasMentionTarget = Boolean(mentionTarget);
    const isTextMode = mode === 'text';
    const isPreviewMode = mode === 'preview';
    const isLoading = loadingContent?.isLoading;
    const shouldShowUserActions =
      !isPreviewMode && !isTextMode && isFocused && !isLoading;
    const shouldShowFormat = shouldShowUserActions && enableFormat;
    const shouldShowActions = shouldShowUserActions || actions.length > 0;
    const shouldShowSuggestions =
      !isTextMode && hasMentionTarget && hasSuggestions;
    const isEmpty = testIsEmptyAst(content);
    const isExceedsCharacterLimit =
      limit && limitMode === 'characters' && text.length >= limit;
    const isExceedsWordLimit =
      limit && limitMode === 'words' && wordCount(text) > limit;
    const isExceedsLimit = isExceedsCharacterLimit || isExceedsWordLimit;
    const count = limitMode === 'characters' ? text.length : wordCount(text);
    const inputError = count > (limit ?? 0);
    // posting is disabled if the editor is disabled, or there is not user value, or if it is currently posting, or if we are currently searching for mentions
    const disablePost =
      disablePostButton ||
      disabled ||
      isEmpty ||
      isLoading ||
      isPosting ||
      isExceedsLimit ||
      hasMentionTarget;

    const submitButtonTooltip = useMemo(() => {
      if (isReplying) return 'Stop Generating';
      if (isPosting) return 'Sending…';
      return 'Send';
    }, [isReplying, isPosting]);

    const handleFocus = () => {
      enableIsEditing();
      focusEditor(editor);
      focus();
      onFocus?.();
    };

    const handleChange = (updatedContent: ContentAst) => {
      handleInsertMention();
      setContent(updatedContent);
      onChange?.(updatedContent);
    };

    const handleBlur = () => {
      disableIsEditing();
      deselectEditor(editor);
      blurEditor(editor);
      blur();
      onBlur?.();
    };

    const handleCancel = () => {
      handleBlur();
      onCancel?.();
    };

    /**
     * Should only listen to overriseIsFocused.
     * TODO - clowntown: refactor `props.isFocused` and stateful `isFocused`.
     */
    useEffect(() => {
      if (overrideIsFocused) {
        if (isFocused) {
          if (!isEditing) {
            enableIsEditing();
          }
        } else if (!isFocused) {
          focus();
          focusEditor(editor, 'end');
          if (!isEditing) {
            enableIsEditing();
          }
        }
      } else if (!overrideIsFocused && isFocused) {
        handleCancel();
      }
    }, [overrideIsFocused]);

    useEffect(() => {
      if (isEditing && isFocused) {
        focusEditor(editor);
      }
    }, [isEditing, isFocused]);

    /**
     * This is a hotfix and the root cause of the problem is still unknown. (Update: issue is caused by Popover component when we blur activeElement.)
     *
     * Slate.Editor loses focus when focus is gained on other mouse events (e.g. Tooltip triggers).
     *
     * Ensure that the editor is always focused if the editor has state.isEditing set to true.
     *
     * Set state.isEditing to false when the TextEditor is not being interacted (onMouseLeave);
     */
    const handleMouseMove = () => {
      if (isEditing) {
        focusEditor(editor);
      }
    };

    const handleMouseLeave = disableIsEditing;

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      handleFormatHotkey(event);
      handleNavigateMention(event);

      // editor-opinionated hotkeys
      const postHotkey = DEPRECATED_enableImprovedPost ? 'enter' : 'mod+enter';
      if (isHotkey(postHotkey, event)) {
        event.preventDefault();
        if (!disablePost) {
          handlePost();
        }
      }
    };

    const handlePost = () => {
      if (isReplying) {
        onInterrupt?.();
      } else {
        onPost(content);
        handleChange(resetAst());
        clearEditor(editor);
        handleBlur();
      }
    };

    const handleHoverMention = (updatedIndex: number) => {
      onSetIndex(updatedIndex);
      focusEditor(editor);
    };

    // side effects
    useEscape(handleCancel);

    const value = coerceAst(content);

    const textEditor = (
      <Slate editor={editor} value={value} onChange={handleChange}>
        <Layout direction="column" w="100%">
          <Layout
            direction="column"
            disabled={disabled}
            maxH={isPreviewMode ? undefined : 'textarea.max-height'}
            p={0}
            styles={[
              componentStyles.editor,
              isPreviewMode ? componentStyles.preview : styles.input.container,
              styles.colors.status,
              {
                borderRadius: 'ml',
              },
            ]}
            styleProps={{
              enableReadOnly: false,
              enableResize,
              status,
              error: inputError,
            }}
            w="100%"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            {header && (
              <Layout
                borderBottom="border.divider"
                h="header.height.s"
                w="100%"
              >
                {header}
              </Layout>
            )}
            {shouldShowFormat && (
              <Box p={1}>
                <FormatBar formats={formats} />
              </Box>
            )}
            <Editable
              style={{ paddingRight: spacings[3], paddingLeft: spacings[3] }}
              spellCheck
              className="text-editor"
              placeholder={placeholder}
              readOnly={readOnly || isPreviewMode || isReplying}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              // handling character limit on paste
              onPaste={(event) => {
                const clipboardData = event.clipboardData;
                const pastedText = clipboardData.getData('text/plain');
                if (
                  limit &&
                  limitMode === 'characters' &&
                  pastedText.length + text.length > limit
                ) {
                  event.preventDefault();
                }
              }}
            />
            {loadingContent?.isLoading && (
              <Layout align="center" p={1} spacing={2}>
                <Icon icon="loading-content" />
                <Text color="text.quiet" variant="body-medium">
                  {loadingContent.message}
                </Text>
              </Layout>
            )}
            {shouldShowActions && (
              <Box p={1}>
                <ActionsBar
                  actions={actions}
                  enableFormat={enableFormat}
                  enableMention={enableMention}
                  formats={formats}
                  paginate={paginate}
                  onMention={triggerMentions(editor)}
                  onToggleEnableFormat={toggleEnableFormat}
                />
              </Box>
            )}
            {!isPreviewMode && !disablePostButton && (
              <>
                {!shouldShowActions && <Box p={4}></Box>}
                <Layout
                  align="center"
                  spacing={4}
                  styles={componentStyles.postAction}
                >
                  <Box>
                    {limit && !isEmpty && (
                      <CharacterLimit
                        limit={limit}
                        mode={limitMode}
                        text={text}
                      />
                    )}
                  </Box>
                  <IconButton
                    disabled={disablePost && !isReplying}
                    icon={isReplying ? 'circle-stop' : 'send'}
                    size="s"
                    tooltip={submitButtonTooltip}
                    onClick={handlePost}
                    PRIVATE_variant={isFocused ? 'action' : undefined}
                  />
                </Layout>
              </>
            )}
          </Layout>
        </Layout>
      </Slate>
    );

    return (
      <MentionSuggestions
        index={index}
        isMentionsLoading={isMentionsLoading}
        search={mentionSearch}
        isVisible={shouldShowSuggestions}
        suggestions={suggestions}
        trigger={textEditor}
        onFocusEditor={handleFocus}
        onHoverMention={handleHoverMention}
        onSelectMention={handleSelectMention}
      />
    );
  },
);

const componentStyles = {
  editor: ({ enableResize }: { enableResize: boolean }) => ({
    position: 'relative',
    '> .text-editor': {
      cursor: 'text',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: '4px 8px',
      resize: enableResize ? 'vertical' : undefined,
      width: 'calc(100% - 8px)',
      '> ol': {
        margin: 0,
      },
      '> p': {
        margin: 0,
      },
      '> ul': {
        margin: 0,
      },
    },
  }),
  postAction: {
    bottom: 1,
    position: 'absolute',
    right: 1,
    paddingRight: 1,
  },
  preview: {
    '> .text-editor': {
      overflowY: 'hidden',
    },
  },
};
