import React, { memo } from 'react';

import { Theme } from '../../types';
import { Box } from '../Box';
import { Text } from '../Text';

interface Props {
  /** Adjust the flex property in flex layouts */
  flex?: 'auto' | 'none';
  /** Direction of divider */
  isHorizontal?: boolean;
  /** Optional display label for the divider */
  label?: string;
  /** Supported heights */
  height?: 's';
  /** Supported widths */
  width?: 's';
  /** Theme */
  theme?: Theme;
}

export const Divider = memo(
  ({
    height = 's',
    isHorizontal = true,
    label,
    theme,
    width,
    flex = !isHorizontal || width ? 'none' : 'auto',
  }: Props) => {
    const isDarkTheme = theme === 'dark';
    return (
      <Box
        role="separator"
        flex={flex}
        styles={componentStyles.divider}
        styleProps={{
          height,
          isDarkTheme,
          isHorizontal,
          width,
        }}
      >
        {label && isHorizontal && (
          <Text
            p={1}
            color={theme === 'dark' ? 'inverse.text.primary' : 'text.primary'}
            styles={componentStyles.label}
            styleProps={{
              isDarkTheme,
            }}
            variant="tiny-bold"
          >
            {label}
          </Text>
        )}
      </Box>
    );
  },
);

const componentStyles = {
  divider: ({
    height,
    isDarkTheme,
    isHorizontal,
    width,
  }: {
    height?: 's';
    isDarkTheme?: boolean;
    isHorizontal?: boolean;
    width?: 's';
  }) => ({
    borderColor: isDarkTheme ? 'inverse.border' : 'border',
    position: 'relative',
    ...(isHorizontal
      ? {
          borderBottom: 'border',
          width: width ? `divider.width.${width}` : '100%',
        }
      : {
          borderLeft: 'border',
          height: height ? `divider.height.${height}` : '100%',
          width: '1px',
        }),
  }),
  label: ({ isDarkTheme }: { isDarkTheme?: boolean }) => ({
    backgroundColor: isDarkTheme
      ? 'inverse.background.secondary'
      : 'background.secondary',
    borderRadius: 'm',
    color: isDarkTheme ? 'inverse.text.primary' : 'text.primary',
    left: '50%',
    position: 'absolute',
    top: '50%',
    textTransform: 'uppercase',
    transform: 'translate(-50%, -50%)',
  }),
};
