import React, { useState } from 'react';

import Text from '../components/Text';
import Tooltip from '../components/Tooltip';

export function useShowMoreItems({
  items,
  limit: initialLimit,
  step = items.length,
  mapItemText,
}) {
  const [limit, setLimit] = useState(initialLimit);
  const visibleItems = items.slice(0, limit);
  const hiddenItems = items.slice(limit);
  const hiddenItemsTooltip = hiddenItems.map(mapItemText).join('\n');

  const handleShowMore = () => {
    setLimit(limit + step);
  };

  const showMore = hiddenItems.length > 0 && (
    <Tooltip content={hiddenItemsTooltip}>
      <Text variant="2xs-dense" onClick={handleShowMore}>
        +{hiddenItems.length} more
      </Text>
    </Tooltip>
  );

  return [visibleItems, showMore];
}
