import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Text, useHover } from '~/ui';

function FieldCard({ description, id, imgSrc, title, onClick }) {
  const [ref, isHovered] = useHover();

  return (
    <FlexLayout
      id={id}
      bg={isHovered ? 'white' : 'gray-100'}
      flexDirection="column"
      ref={ref}
      onClick={onClick}
    >
      <img alt={title} src={imgSrc} />
      <FlexLayout flexDirection="column" p={6} space={2}>
        <Text color="gray-900" variant="s-dense-bold">
          {title}
        </Text>
        <Text color="gray-600" variant="s-spaced">
          {description}
        </Text>
      </FlexLayout>
    </FlexLayout>
  );
}

FieldCard.propTypes = {
  description: PT.string.isRequired,
  imgSrc: PT.string.isRequired,
  title: PT.string.isRequired,
  onClick: PT.func.isRequired,
};

export default FieldCard;
