import { CrmType } from '~/enums';
import { everest } from '~/services';
import { Nullable } from '~/types';

interface Error {
  message?: string;
}

interface CrmIntegrationConfig {}

interface CrmIntegration {
  config: CrmIntegrationConfig;
  disabled?: boolean;
  id: Nullable<string>;
  error: Nullable<Error>;
  isDefault: boolean;
  provider: CrmType;
}

interface GetCrmsResponse {
  data: CrmIntegration[];
}

interface GetLoginRequest {
  integrationUrl: string;
}

interface GetLoginResponse {
  redirectUrl: string;
}

// if crm does not exist from the server, create an empty crm without an ID
export const getCrmIntegrations = async (): Promise<CrmIntegration[]> => {
  function createEmptyCrmIntegration(provider: CrmType): CrmIntegration {
    return {
      id: null,
      config: {},
      error: null,
      isDefault: false,
      provider,
    };
  }

  const response = (await everest.get('/crms')) as GetCrmsResponse;

  const crmIntegrations = Object.values(CrmType).map((crmType) => {
    const crmIntegration = response.data.find(
      (record) => record.provider === crmType.toUpperCase(),
    );
    return crmIntegration
      ? { ...crmIntegration, provider: crmType }
      : createEmptyCrmIntegration(crmType);
  });

  return crmIntegrations;
};

export const deleteCrmIntegration = async (
  integration: CrmIntegration,
): Promise<string> => {
  return await everest.remove(`/crms/${integration.id}`);
};

export const getCrmIntegrationLoginUrl = async ({
  integrationUrl,
}: GetLoginRequest): Promise<GetLoginResponse> => {
  return await everest.post('/salesforce/login', { integrationUrl });
};
