export default `
<svg
  aria-hidden="true"
  aria-label="collapse-right"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M17.4688 6.75V17.25C17.4688 17.6875 17.7812 18 18.2188 18C18.6562 18 19 17.6875 19 17.25V6.75C19 6.34375 18.6562 6 18.2188 6C17.8125 6 17.4688 6.34375 17.4688 6.75ZM10.4688 8.5625L13.3438 11.2812H5.75C5.3125 11.25 5 11.5938 5 12C5 12.4375 5.3125 12.75 5.75 12.75H13.3438L10.4688 15.4688C10.3125 15.625 10.25 15.8125 10.25 16C10.25 16.1875 10.3125 16.375 10.4375 16.5312C10.7188 16.8438 11.1875 16.8438 11.5 16.5625L15.75 12.5625C16.0625 12.2812 16.0625 11.75 15.75 11.4688L11.5 7.46875C11.1875 7.1875 10.7188 7.1875 10.4375 7.5C10.1562 7.8125 10.1562 8.28125 10.4688 8.5625Z"
    fill="currentcolor"
  />
</svg>
`;
