import React, { useState } from 'react';

import { RETRY_ERROR } from '~/constants/errors';
import {
  Accordion,
  Checkbox,
  ConditionalSelect,
  types as edsTypes,
  FormField,
  Layout,
  Panel,
  Section,
  TextInput,
  useToast,
} from '~/eds';
import { ClientContractTypeSelect } from '~/features/client';
import { FieldSectionSelect } from '~/features/fields';
import { api } from '~/redux';
import { PilotId } from '~/types';

import { DataFieldType } from '../enums';
import { FieldTypeSelect } from '../FieldTypeSelect';
import { DataField } from '../types';
import { initialFieldData } from './constants';
import { DefaultFieldValue } from './DefaultFieldValue';
import { FieldConfiguration } from './FieldConfiguration';
import { FieldData } from './types';
import { validateFieldData } from './utils';

interface Props {
  onCreate: (newField: DataField, fieldData: FieldData) => void;
  onHide: () => void;
  acceptFieldTypes?: DataFieldType[];
}

export const CreateFieldPanel = ({
  acceptFieldTypes,
  onCreate,
  onHide,
}: Props) => {
  const { toast } = useToast();

  const [createField] = api.endpoints.createField.useMutation();

  const [fieldData, setFieldData] = useState<FieldData>(initialFieldData);

  const {
    defaultValue,
    docRestriction,
    docTypes,
    fieldConfig,
    fieldName,
    fieldSectionId,
    fieldType,
    helpText,
    showOnUploadForm,
  } = fieldData;

  const disableTooltip = validateFieldData(fieldData);

  const handleHide = () => {
    setFieldData(initialFieldData);
    onHide();
  };

  const footer = {
    actions: [
      {
        text: 'Cancel',
        onClick: handleHide,
      },
      {
        disabled: Boolean(disableTooltip),
        level: 'primary' as const,
        text: 'Save',
        tooltip: disableTooltip,
        onClick: () => {
          createField({ fieldData })
            .unwrap()
            .then((newField) => {
              toast({
                message: `Field **${fieldName}** has been created.`,
                status: 'success',
              });
              onCreate(newField!, fieldData);
              handleHide();
            })
            .catch((error) => {
              const message = error?.response?.data.detail ?? RETRY_ERROR;
              toast({
                message: `Field **${fieldName}** could not be created.  ${message}`,
                status: 'danger',
              });
            });
        },
      },
    ],
  };

  return (
    <Panel
      animate
      backAction={{
        text: 'Back',
        tooltip: 'Back',
        onClick: handleHide,
      }}
      footer={footer}
      position="right"
      title="Create Data Field"
      width="m"
    >
      <Layout preset="sections">
        <Layout preset="form-fields">
          <FormField<string, false>
            label="Field Name"
            description="Pick a name to help you identify this field."
            input={TextInput}
            name="field name"
            placeholder="e.g. Indemnification, Warranties"
            value={fieldName ?? ''}
            onChange={(updatedFieldName) =>
              setFieldData({
                ...fieldData,
                fieldName: updatedFieldName,
              })
            }
          />
          <FormField<PilotId, false>
            description="Group new field with other document data."
            input={FieldSectionSelect}
            label="Field Section"
            name="field section"
            value={fieldSectionId}
            onChange={(updatedFieldSectionId) => {
              setFieldData({
                ...fieldData,
                fieldSectionId: updatedFieldSectionId,
              });
            }}
          />
          <FormField<DataFieldType, false>
            label="Field Type"
            name="field type"
            input={FieldTypeSelect}
            inputProps={{
              config: {
                acceptFieldTypes,
                shouldMergeFieldTypes: true,
              },
            }}
            value={fieldType}
            onChange={(updatedFieldType) =>
              setFieldData({
                ...fieldData,
                defaultValue: null,
                fieldConfig: initialFieldData.fieldConfig,
                fieldType: updatedFieldType,
              })
            }
          />
        </Layout>
        {fieldType && (
          <FieldConfiguration
            config={fieldConfig}
            fieldType={fieldType}
            onChange={(updatedFieldConfig) =>
              setFieldData({
                ...fieldData,
                fieldConfig: updatedFieldConfig,
                defaultValue: null, // reset any value affected by the field config
              })
            }
          />
        )}
        <Accordion
          border="none"
          items={[
            {
              content: (
                <Layout preset="form-fields">
                  {fieldType && (
                    <DefaultFieldValue
                      fieldData={fieldData}
                      value={defaultValue}
                      onChange={(updatedDefaultValue) =>
                        setFieldData({
                          ...fieldData,
                          defaultValue: updatedDefaultValue,
                        })
                      }
                    />
                  )}
                  <FormField<string, false>
                    optional
                    description="Describe the field's purpose and offer usage guidance."
                    label="Help Text"
                    name="help text"
                    input={TextInput}
                    value={helpText}
                    onChange={(updatedHelpText) =>
                      setFieldData({
                        ...fieldData,
                        helpText: updatedHelpText ?? '',
                      })
                    }
                  />
                </Layout>
              ),
              title: 'Optional Configurations',
            },
          ]}
          padding="none"
        />
        <Section
          description="Customize which document types will display this field."
          title="Display options"
        >
          <Layout preset="form-fields">
            <FormField<
              edsTypes.ConditionalSelectValue<
                'NONE' | 'EXCLUDE' | 'INCLUDE',
                string,
                true
              >,
              false
            >
              input={ConditionalSelect}
              inputProps={{
                sourceInput: {
                  name: 'document types',
                  placeholder: 'Specify document type configuration',
                  options: [
                    { label: 'All document types', value: 'NONE' },
                    { label: 'All document types, except…', value: 'EXCLUDE' },
                    {
                      label: 'Only specific document types…',
                      value: 'INCLUDE',
                    },
                  ],
                },
                conditionalInputs: {
                  EXCLUDE: {
                    input: ClientContractTypeSelect,
                    label: 'Exclude',
                  },
                  INCLUDE: {
                    input: ClientContractTypeSelect,
                    label: 'Only',
                  },
                },
              }}
              label="Document Type(s)"
              name="document types"
              value={{
                sourceValue: docRestriction,
                conditionalValues: {
                  EXCLUDE: docTypes,
                  INCLUDE: docTypes,
                },
              }}
              onChange={(updatedConditionalValue) => {
                if (updatedConditionalValue?.sourceValue) {
                  const updatedDocRestriction =
                    updatedConditionalValue.sourceValue;
                  const excludeDocTypes =
                    updatedConditionalValue.conditionalValues.EXCLUDE ?? [];
                  const includeDocTypes =
                    updatedConditionalValue.conditionalValues.INCLUDE ?? [];

                  setFieldData({
                    ...fieldData,
                    docRestriction: updatedDocRestriction,
                    docTypes:
                      updatedDocRestriction === 'EXCLUDE'
                        ? excludeDocTypes
                        : includeDocTypes,
                  });
                }
              }}
            />
            <Checkbox
              name="display field on upload"
              option={{
                info:
                  'Checking this box will cause this field to appear every time a user uploads documents. It’s important to keep this limited to your most important 2-3 fields.',
                label: 'Display this field on upload',
                value: true,
              }}
              value={showOnUploadForm}
              onChange={(updatedShowOnUploadForm) =>
                setFieldData({
                  ...fieldData,
                  showOnUploadForm: updatedShowOnUploadForm ?? false,
                })
              }
            />
          </Layout>
        </Section>
      </Layout>
    </Panel>
  );
};
