import { BulkImportStatusType } from '~/enums';
import { IBulkImportJobList } from '~/types';

import { IBulkImportJob } from '../types/BulkImport';

const initialStatuses = [
  BulkImportStatusType.New,
  BulkImportStatusType.Running,
];

const finalStatuses = [
  BulkImportStatusType.Finished,
  BulkImportStatusType.Cancelled,
  BulkImportStatusType.Failed,
];

export const filterInitialStatusImportJobs = (
  importJobs: IBulkImportJobList,
): IBulkImportJobList =>
  importJobs.filter(
    (importData: IBulkImportJob) =>
      initialStatuses.indexOf(importData.status) > -1,
  );

export const filterFinalStatusImportJobs = (
  importStatusData: IBulkImportJobList,
): IBulkImportJobList =>
  importStatusData.filter(
    (importData: IBulkImportJob) =>
      finalStatuses.indexOf(importData.status) > -1,
  );

export const parseInitialAndFinalImportJobs = (
  importJobsData: IBulkImportJobList,
) => {
  const initialStatusJobs = filterInitialStatusImportJobs(importJobsData);
  const finalStatusJobs = filterFinalStatusImportJobs(importJobsData);

  return { initialStatusJobs, finalStatusJobs };
};

const mergeFinalStatusJobs = (
  currentFinalImportJobs: IBulkImportJobList,
  previousFinalImportJobs: IBulkImportJobList,
): IBulkImportJobList => [
  ...currentFinalImportJobs,
  ...previousFinalImportJobs,
];

export const parseCurrentAndPreviousFinalImportJobs = (
  initialImportJobs: IBulkImportJobList,
  finalImportJobs: IBulkImportJobList,
  previousFinalImportJobs: IBulkImportJobList,
): IBulkImportJobList => {
  if (!initialImportJobs.length) {
    return mergeFinalStatusJobs(finalImportJobs, previousFinalImportJobs);
  } else {
    return previousFinalImportJobs;
  }
};
