import React, { useState } from 'react';

import { Button, InfoCard, Layout, PageLayout, types } from '~/eds';

import { ClauseTypeSelect } from './ClauseTypeSelect';
import { Top3ClauseTypes } from './Top3ClauseTypes';
import { ClauseType, Stage } from './types';

interface Props {
  onContinue: () => void;
  onSkip: () => void;
}

// TODO: REMOVE MOCK DATA
const mockClauseTypes: ClauseType[] = [
  {
    name: 'Acceptance',
    id: 'acceptance',
    count: 363,
  },
  {
    name: 'Assignment',
    id: 'assignment',
    count: 421,
  },
  {
    name: 'Confidentiality',
    id: 'confidentiality',
    count: 1045,
  },
  {
    name: 'Force Majeure',
    id: 'force-majeure',
    count: 29,
  },
  {
    name: 'Governing Law',
    id: 'governing-law',
    count: 882,
  },
  {
    name: 'Indemnification',
    id: 'indemnification',
    count: 96,
  },
];

const mockQueryResponse = {
  data: mockClauseTypes,
  isFetching: false,
  isSuccess: true,
  error: null,
};

export const WelcomePage = ({ onContinue, onSkip }: Props) => {
  const [stage, setStage] = useState<Stage>('welcome');

  const { data: clauseTypes = [], isFetching } = mockQueryResponse; // api.endpoints.getClauseTypes.useQuery({});

  const initiateAiSuggest = (clauseType: ClauseType) => {
    console.log('TODO: initiateAiSuggest for', clauseType.name);
    onContinue();
  };

  let title = '';
  let description = '';
  let navActions: types.UserAction[] = [];
  let content;

  switch (stage) {
    case 'welcome':
      title = 'Welcome to Clause Library';
      description =
        'Let Evisort AI suggest clauses for you or skip and enter the clause library to add clauses manually.';
      content = (
        <Layout direction="column" spacing={8} align="center">
          <Top3ClauseTypes clauseTypes={clauseTypes} isLoading={isFetching} />
          <Layout preset="cards">
            <InfoCard
              action={{
                text: 'Get Started',
                level: 'secondary',
                onClick: () => setStage('ai-suggest-start'),
              }}
              description="Evisort AI makes suggestions based on your past agreements."
              icon="ai"
              title="Let Evisort AI Suggest"
            />
          </Layout>
          <Button
            variant="action"
            text="Skip and enter clause library"
            onClick={onSkip}
          />
        </Layout>
      );
      break;
    case 'ai-suggest-start':
      title = 'Let Evisort AI Suggest';
      description =
        'Evisort AI will suggest clauses after looking at your executed agreements from the past 2 years. Select a clause type to generate clauses for.';
      navActions = [
        {
          text: 'Clause Library',
          onClick: () => setStage('welcome'),
        },
        {
          text: title,
          disabled: true,
        },
      ];
      content = (
        <ClauseTypeSelect
          clauseTypes={clauseTypes}
          isLoading={isFetching}
          onSubmit={initiateAiSuggest}
        />
      );
      break;
  }

  return (
    <PageLayout
      welcome={{ content: content }}
      title={title}
      description={description}
      nav={{ actions: navActions }}
    />
  );
};
