import { Uuid } from '~/types';

import { DEFAULT_TAB } from './constants';
import { TabValue } from './types';

export const formatUrl = (
  tabValue: TabValue = DEFAULT_TAB,
  clauseId?: Uuid,
  variationId?: Uuid,
) => {
  let url = '/clause-library';
  if (clauseId) {
    url += `/${clauseId}`;
  }
  url += `?tab=${tabValue}`;
  if (variationId) {
    url += `#${variationId}`;
  }
  return url;
};
