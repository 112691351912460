import { add, sub } from 'date-fns';
import { noop } from 'lodash';
import React from 'react';

import {
  DateInput,
  DatePeriodInput,
  DateRangeInput,
  Layout,
  types,
} from '~/eds';
import { Nullable } from '~/types';

import { DateValue, Field, Filter, OperatorId } from '../types';

interface Props {
  field?: Field<'date'>;
  filter: Filter<DateValue>;
  onChange: (updatedValues: DateValue[]) => void;
}

export const DateValues = ({ field, filter, onChange }: Props) => {
  const { id, operatorId, values } = filter;

  const settings = field?.settings || undefined;
  const [firstValue = null] = values;

  const handleValueChange = (updatedValue: Nullable<DateValue>) => {
    onChange(updatedValue ? [updatedValue] : []);
  };

  const handleDateRangeChange = (
    updatedDateRange: Nullable<types.DateRange>,
  ) => {
    if (updatedDateRange) {
      const { from, to } = updatedDateRange;
      onChange([from || null, to || null]);
    } else {
      onChange([null, null]);
    }
  };

  const handleDatePeriodChange = (
    updatedDatePeriod: Nullable<types.DatePeriod>,
  ) => {
    onChange([updatedDatePeriod]);
  };

  const dateProps = {
    format: 'iso_date' as const,
    placeholder: 'yyyy-mm-dd',
  };

  switch (operatorId) {
    case 'date_in_the_last':
    case 'date_in_the_next': {
      // Note: this maybe abstracted into DateInput and supported as a feature but we will wait and decide when EDS becomes more mature
      const datePeriod = (values[0] || null) as Nullable<types.DatePeriod>;
      return (
        <Layout direction="column" spacing={4}>
          <DatePeriodInput
            name={`${id}-period`}
            value={datePeriod}
            onChange={handleDatePeriodChange}
          />
          <DateRangeInput
            isEmbedded
            readOnly
            enableControl={false}
            name={id}
            width="100%"
            onChange={noop}
            {...inferDateProps(datePeriod, operatorId)}
            min={settings?.min}
            max={settings?.max}
            getDayTooltip={settings?.getDayTooltip}
          />
        </Layout>
      );
    }
    case 'date_between': {
      return (
        <DateRangeInput
          isEmbedded
          name={id}
          value={toDateRange(values)}
          width="100%"
          onChange={handleDateRangeChange}
          min={settings?.min}
          max={settings?.max}
          getDayTooltip={settings?.getDayTooltip}
          {...dateProps}
        />
      );
    }
    default:
      return (
        <DateInput
          isEmbedded
          name={id}
          value={firstValue as Date}
          width="100%"
          onChange={handleValueChange}
          min={settings?.min}
          max={settings?.max}
          getDayTooltip={settings?.getDayTooltip}
          {...dateProps}
        />
      );
  }
};

const toDateRange = (values: DateValue[]): types.DateRange => {
  const [from, to] = values;
  return { from, to } as types.DateRange;
};

const inferDateProps = (
  datePeriod: Nullable<types.DatePeriod>,
  operatorId: OperatorId,
): {
  max?: Date;
  month?: Date;
  min?: Date;
  value: types.DateRange;
} => {
  const value: types.DateRange = {
    from: undefined,
    to: undefined,
  };

  let month;
  if (datePeriod !== null && datePeriod.unit !== null) {
    const today = new Date();
    switch (operatorId) {
      case 'date_in_the_last': {
        value.to = today;
        value.from = sub(today, {
          [datePeriod.unit]: datePeriod.value,
        });
        month = value.from;
        break;
      }
      case 'date_in_the_next': {
        value.from = today;
        value.to = add(today, {
          [datePeriod.unit]: datePeriod.value,
        });
        month = value.to;
        break;
      }
      default:
        break;
    }
  }

  return { month, value };
};
