import pluralize from 'pluralize';
import React from 'react';

import { Popover } from '~/eds';
import { FlexLayout, Link, Text } from '~/ui';

function VersionsMenuItem({ isLatest, version }) {
  const { totalTickets, activeTickets, id, number } = version;
  return (
    <Link to={`/workflow/builder/${id}`}>
      <FlexLayout
        flexDirection="column"
        key={id}
        p="10px 36px 10px 24px"
        space={1}
        sx={{ '&:hover': { backgroundColor: 'blue-100' } }}
      >
        <Text color="blue-500" variant="s-dense-bold">
          {number === 0 ? 'Original' : `Version ${number}`}
          {isLatest && ' (current)'}
        </Text>
        <Text color="gray-600" variant="2xs-dense">
          {`${totalTickets} ${pluralize(
            'ticket',
            totalTickets,
          )} (${activeTickets} active)`}
        </Text>
      </FlexLayout>
    </Link>
  );
}

function VersionsMenu({ trigger, versions }) {
  if (!versions.length) {
    return trigger;
  }

  return (
    <Popover trigger={trigger} preset="dropdown">
      {versions
        .sort((a, b) => b.number - a.number)
        .map((version, index) => (
          <VersionsMenuItem
            isLatest={index === 0}
            key={version.id}
            version={version}
          />
        ))}
    </Popover>
  );
}

export default VersionsMenu;
