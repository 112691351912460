import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import TicketApprovalItem, {
  propTypes as TicketApprovalItemPropTypes,
} from '../TicketApprovalItem';
import styles from './TicketApprovalList.styles';

const TicketApprovalList = ({
  approvalList,
  approvalName,
  issueId,
  entityType,
  // injected
  classes,
}) => {
  return (
    <div className={classes.ticketApprovalList}>
      {approvalList.map((approvalId, index) => (
        <TicketApprovalItem
          issueId={issueId}
          entityType={entityType}
          approvalId={approvalId}
          approvalList={approvalList}
          approvalName={approvalName}
          key={index}
        />
      ))}
    </div>
  );
};

TicketApprovalList.propTypes = {
  approvalList: PropTypes.arrayOf(TicketApprovalItemPropTypes.approvalId)
    .isRequired,
};

export default injectSheet(styles)(TicketApprovalList);
