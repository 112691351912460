import React, { ComponentProps, useEffect, useMemo } from 'react';

import { Accordion, ContentContainer, scrollElementIntoView } from '~/eds';
import { BaseClause, DocumentSimplifiedClauses } from '~/types';

import { Clause } from './Clause';

type AccordionProps = ComponentProps<typeof Accordion>;

interface Props {
  clauses: DocumentSimplifiedClauses[];
  search?: string;
  onToggle?: AccordionProps['onToggle'];
  /** if true, all accordion items will be expanded */
  isAllExpanded?: boolean;
  /** when provided, the clause will be expanded and will the screen will scroll to the highlight. */
  featuredClauses?: string[];
}

export function ClausesAccordion({
  clauses,
  featuredClauses,
  search = '',
  onToggle,
  isAllExpanded = false,
}: Props) {
  const getClauseContent = (clauses: BaseClause[]) => (
    <Clause clauses={clauses} />
  );

  const clausesAccordionItems = useMemo(() => {
    return clauses.map((clause) => ({
      content: getClauseContent(clause.content),
      data: clause,
      title: `${clause.name} (${clause.content.length})`,
      isExpanded: isAllExpanded || featuredClauses?.includes(clause.name),
    }));
  }, [clauses]);

  useEffect(() => {
    if (featuredClauses) {
      const clause = clauses.find((clause) =>
        featuredClauses?.includes(clause.name),
      );
      const title = `${clause?.name} (${clause?.content.length})-header`;
      scrollElementIntoView(title, { block: 'center' });
    }
  }, [featuredClauses, clauses]);

  const placeholderContent = !clauses.length
    ? {
        icon: 'field-clause' as const,
        message: 'No clauses exists for this document.',
      }
    : undefined;

  return (
    <ContentContainer placeholderContent={placeholderContent}>
      <Accordion
        items={clausesAccordionItems}
        search={search}
        onToggle={onToggle}
      />
    </ContentContainer>
  );
}
