import PT from 'prop-types';
import React from 'react';

import { Button } from '~/eds';

import FlexLayout from '../FlexLayout';
import Icon from '../Icon';

function ActionList({ actions }) {
  return (
    <FlexLayout alignItems="center" space={5}>
      {actions.map(
        ({
          icon,
          className,
          color,
          tooltip,
          title,
          onClick,
          disabled = false,
          textOnly = false,
        }) =>
          textOnly ? (
            <Button
              variant="action"
              text={title}
              tooltip={tooltip}
              disabled={disabled}
              className={className}
              onClick={onClick}
            />
          ) : (
            <Icon
              key={icon}
              className={className}
              color={color}
              disabled={disabled}
              icon={icon}
              size="s"
              title={title || icon}
              tooltip={tooltip}
              onClick={onClick}
            />
          ),
      )}
    </FlexLayout>
  );
}

const actionPropType = PT.shape({
  /** Valid icon key for accessing icon svg asset */
  icon: PT.string.isRequired,
  /** Icon title required for a11y.  If unspecified, the "icon" prop value will be used by default */
  title: PT.string,
  /** Handler on action click */
  onClick: PT.func.isRequired,
  /** Function that takes data to determine visibility of an action */
  condition: PT.func,
  /** Classname */
  className: PT.string,
  /** Tooltip */
  tooltip: PT.string,
  /** This flag will show text only or icon */
  textOnly: PT.bool,
});

ActionList.propTypes = {
  /** An array of action objects specifying an icon, tooltip, and click handler */
  actions: PT.arrayOf(actionPropType),
};

export default ActionList;
