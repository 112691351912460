import { decamelizeKeys } from 'humps';

import { pilot } from '~/services';

export const addNewClient = (props) => {
  const { subdomain, ...restProps } = props;
  const data = { ...decamelizeKeys(restProps), sub_domain: subdomain };

  return pilot.post('/client/', data);
};

export const updateClient = (props) => {
  const { clientId, subdomain, ...restProps } = props;
  const data = { ...decamelizeKeys(restProps), sub_domain: subdomain };

  return pilot.put(`client/${clientId}/`, data);
};

export const getClients = (page, searchText) => {
  return pilot.get(`client/list/?page=${page}&name=${searchText}`);
};

export const deleteClient = (clientId) => pilot.remove(`client/${clientId}/`);

export const getCustomAlgorithmChoices = () => {
  return pilot.get('client/custom-algorithm-suggestions/');
};

export const getBucketInfo = (bucket) => {
  return pilot.get(`client/get-bucket-info/${bucket}/`);
};
