import { bold } from '../../../assets/shared-styles/general';

const styles = {
  modalBody: {
    padding: '16px',
    fontSize: '15px',
    lineHeight: '1.47',
  },
  modalBodyContent: {
    '& span': {
      fontWeight: bold,
    },
  },
  modalBodyTitle: {
    marginBottom: '24px',
    fontSize: '20px',
    fontWeight: bold,
  },
};

export default styles;
