export default `
<svg
  aria-hidden="true"
  aria-label="sort"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M5 7.00439C5 6.59814 5.3125 6.25439 5.75 6.25439H18.25C18.6562 6.25439 19 6.59814 19 7.00439C19 7.44189 18.6562 7.75439 18.25 7.75439H5.75C5.3125 7.75439 5 7.44189 5 7.00439ZM5 12.0044C5 11.5981 5.3125 11.2544 5.75 11.2544H14.25C14.6562 11.2544 15 11.5981 15 12.0044C15 12.4419 14.6562 12.7544 14.25 12.7544H5.75C5.3125 12.7544 5 12.4419 5 12.0044ZM10.25 17.7544H5.75C5.3125 17.7544 5 17.4419 5 17.0044C5 16.5981 5.3125 16.2544 5.75 16.2544H10.25C10.6562 16.2544 11 16.5981 11 17.0044C11 17.4419 10.6562 17.7544 10.25 17.7544Z"
    fill="currentcolor"
  />
</svg>
`;
