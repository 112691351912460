import React from 'react';
import { connect } from 'react-redux';

import {
  workflowCreateFormSection,
  workflowRemoveFormSection,
  workflowSetFormDescription,
  workflowSetFormSectionName,
  workflowSortFormSections,
} from '~/actions';
import DescriptionTextAreaForm from '~/components/Workflow/shared/DescriptionTextAreaForm';
import StageItems from '~/components/Workflow/shared/StageItems';
import { getFormErrors } from '~/reducers/workflow';
import { FlexLayout, Text } from '~/ui';
import { coerceName } from '~/utils/workflow';

import Section from './Section';

function Form({
  // connected
  disabled,
  errors,
  form,
  workflowCreateFormSection,
  workflowRemoveFormSection,
  workflowSetFormDescription,
  workflowSetFormSectionName,
  workflowSortFormSections,
}) {
  const sectionItems = form.sections.map((section, index) => ({
    ...section,
    title: coerceName(section.name, 'Form Section', index),
    content: <Section position={index} sectionId={section.id} />,
  }));

  return (
    <>
      <FlexLayout flexDirection="column" space={4}>
        <FlexLayout disabled={disabled} flexDirection="column" space={2}>
          <Text variant="xs-dense-caps">Form Description</Text>
          <DescriptionTextAreaForm
            description={form.description}
            id="workflow--form-add-description"
            onUpdate={(description) =>
              workflowSetFormDescription({ description })
            }
          />
        </FlexLayout>
        {errors.questionCount === 0 && (
          <Text
            bg="red-100"
            color="red-600"
            p={4}
            sx={{ borderRadius: 'm' }}
            variant="xs-dense"
          >
            At least one question should be created in a form.
          </Text>
        )}
        {errors.circularDependencies && (
          <Text
            bg="red-100"
            color="red-600"
            p={4}
            sx={{ borderRadius: 'm' }}
            variant="xs-dense"
          >
            Circular logic detected in at least one Field and/or Condition.
          </Text>
        )}
        {errors.lackCounterpartySignerQuestion && (
          <Text
            bg="red-100"
            color="red-600"
            p={4}
            sx={{ borderRadius: 'm' }}
            variant="xs-dense"
          >
            Questions for the counterparty name field and the counterparty email
            field are required for each counterparty signer.
          </Text>
        )}
        <StageItems
          disabled={disabled}
          items={sectionItems}
          invalidPhaseIds={errors.invalidPhaseIds}
          removeModalTitle="Remove Form Section"
          sortModalTitle="Reorder Form Sections"
          onRemove={workflowRemoveFormSection}
          onSort={workflowSortFormSections}
          onEditTitle={workflowSetFormSectionName}
          newItemAction={{
            id: 'workflow--form-add-section',
            text: 'Add a section',
            handler: workflowCreateFormSection,
          }}
        />
      </FlexLayout>
    </>
  );
}

const mapStateToProps = ({ builder, workflow }) => ({
  disabled: !builder.shouldEnableAllComponents,
  errors: getFormErrors(workflow),
  form: workflow.form,
});

export default connect(mapStateToProps, {
  workflowCreateFormSection,
  workflowRemoveFormSection,
  workflowSetFormDescription,
  workflowSetFormSectionName,
  workflowSortFormSections,
})(Form);
