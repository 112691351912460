import { alphas } from '../../../tokens/colors/system/alphas';

const light = alphas['darken-3'];
const dark = alphas['darken-10'];

export const shimmer = ({
  height = '100%',
  width = '100%',
}: {
  height?: string;
  width?: string;
}) => {
  return {
    animation: 'shimmer',
    animationName: 'shimmer',
    backgroundColor: light,
    backgroundImage: `linear-gradient(to right, ${light} 0%, ${dark} 20%, ${light} 40%, ${light} 100%)`,
    backgroundSize: '800',
    height,
    width,
  };
};
