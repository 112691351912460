import { decamelizeKeys } from 'humps';

import { AutomationDocsApplyTypes } from '~/enums';
import { Filter } from '~/evifields';
import { buildFilterQuery } from '~/redux/api/methods/searchV3';
import { pilot } from '~/services';
import { Uuid } from '~/types';

interface Request {
  name: string;
  content: string;
  sampleName: string;
  applyTo: AutomationDocsApplyTypes[];
  searchQuery: Filter[];
}

interface Response {
  source_id: Uuid;
}

export const createSample = async ({
  name,
  sampleName,
  content,
  applyTo,
  searchQuery,
}: Request): Promise<Response> => {
  const query = searchQuery
    ? buildFilterQuery({
        booleanQuery: '',
        filters: searchQuery,
      })
    : undefined;

  const response: Response = await pilot.post(
    '/ai-command-center/highlights/',
    decamelizeKeys({
      clause: name,
      applyTo,
      highlight: sampleName.trim(),
      content: content.trim(),
      search_query: query,
    }),
  );

  return response;
};
