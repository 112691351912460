import PT from 'prop-types';
import React from 'react';

import { Box } from '~/ui';

import logos from './logos';

const sizes = {
  s: '16px',
  m: '64px',
};

// TODO: ideally spec a Logo UI component to make rendering any brand logo consistent
function ProviderLogo({ provider, size = 'm' }) {
  const sizeValue = sizes[size];
  return (
    <Box
      as="img"
      src={logos[provider]}
      sx={{
        height: sizeValue,
        width: sizeValue,
      }}
    />
  );
}

ProviderLogo.propTypes = {
  provider: PT.oneOf(Object.keys(logos)),
  size: PT.oneOf(['s', 'm']),
};

export default ProviderLogo;
