import React from 'react';

import { HtmlEntityType } from '../../../enums';
import { Nullable } from '../../../types';
import { formatNumber } from '../../../utils';
import { Chip } from '../Chip';

interface Props {
  count: Nullable<number>;
}

export const CountChip = ({ count }: Props) => {
  return (
    <Chip mode="tag" text={count ? formatNumber(count) : HtmlEntityType.Dash} />
  );
};
