import React from 'react';

import { LayoutType } from '~/enums';
import { ButtonGroup } from '~/ui';

const { ThreeColumn, TwoColumn } = LayoutType;

const options = [
  { icon: 'mosaic', title: '2 columns', value: TwoColumn },
  { icon: 'panel', title: '3 columns', value: ThreeColumn },
];

export const ColumnLayoutButtonGroup = ({ className, onChange, layout }) => {
  return (
    <ButtonGroup
      className={className}
      title="Layout"
      onChange={onChange}
      options={options}
      value={layout}
    />
  );
};
