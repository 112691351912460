import { black2, black4, evisortBlue } from '~/assets/shared-styles/general';

const ecFilterInputStyles = {
  flexGrow: 1,
  borderRadius: '4px',
  padding: '12px 16px',
  color: black2,
  fontSize: '15px',
  outline: 'none',
  caretColor: evisortBlue,

  '&::placeholder': {
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: black4,
  },
};

const styles = {
  ecFilterFieldHeader: {
    paddingLeft: '28px',
  },
  booleanInputHelper: {
    display: 'flex',
    alignItems: 'center',
  },
  removeFieldContainer: {
    marginTop: '12px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  ecFilterInputContainer: {
    flexGrow: 1,
  },
  ecFilterInput: {
    ...ecFilterInputStyles,
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
  },
  ecFilterInputError: {
    ...ecFilterInputStyles,
    border: 'solid 1px #f33737',
  },
};

export default styles;
