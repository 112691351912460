import { RoleType } from '~/enums';

import {
  fetchPermissions,
  fetchRoles,
  fetchRolesSimple,
  fetchUsers,
  RolesParams,
  UserParams,
} from '../methods';
import { extractResourceAttributes } from './utils';

const PRE_BUILT_ROLES_ORDER = ['owner', 'admin', 'power user', 'workflow-only'];

export const getRoles = async (params: RolesParams) => {
  const rolesResponse = await fetchRoles(params);

  const results = (rolesResponse?.data || []).map((role) => ({
    ...{
      ...role.attributes,
      name: transformRoleName(role.attributes.name, role.attributes.role_type),
    },
    id: role.id,
    created_at: new Date(role.attributes.created_at),
  }));

  return {
    results,
    total: rolesResponse.meta?.count,
    samlDefaultRoleUuid: rolesResponse.meta?.saml_default_role_uuid,
  };
};

const findRoleIndex = (roleName: string) =>
  PRE_BUILT_ROLES_ORDER.findIndex((order) =>
    roleName.toLowerCase().includes(order),
  );

const sortRoles = (a: any, b: any) => {
  if (a.order !== b.order) {
    return a.order - b.order;
  }
  return a.name.localeCompare(b.name);
};
export const getRolesSimple = async (params: RolesParams) => {
  const rolesResponse = await fetchRolesSimple(params);
  const parsedResponse = extractResourceAttributes(rolesResponse);
  const roles = parsedResponse.results.map((role) => ({
    ...role,
    order:
      role.roleType === RoleType.PreBuiltRole
        ? findRoleIndex(role.name)
        : Number.POSITIVE_INFINITY,
  }));

  const sortedRoles = roles.sort(sortRoles).map((role) => ({
    ...role,
    name: transformRoleName(role.name, role.roleType),
  }));

  parsedResponse.results = sortedRoles;
  return parsedResponse;
};

type PermissionsParams = {
  hasAskAnything?: boolean;
  hasFolderAccessSettings?: boolean;
};

export const getPermissions = async ({
  hasAskAnything,
  hasFolderAccessSettings,
}: PermissionsParams) => {
  const permissionsResponse = await fetchPermissions({
    hasAskAnything,
    hasFolderAccessSettings,
  });

  return permissionsResponse.data.map((group) => ({
    ...group.attributes,
    id: group.id,
  }));
};

export const getUsers = async ({
  searchValue,
  page,
  pageSize,
  clientId,
  useRolodex,
  ...filterParams
}: UserParams) => {
  const response = await fetchUsers({
    searchValue,
    page,
    pageSize,
    clientId,
    useRolodex,
    ...filterParams,
  });

  const extractedUsers = extractResourceAttributes(response, [
    'custom_attribute_values',
  ]);

  if (useRolodex) {
    return {
      ...extractedUsers,
      results: extractedUsers.results.map((user) => ({
        ...user,
        id: user.userId,
        role: user.role
          ? transformRoleName(user.role, user.roleType)
          : user.role,
      })),
    };
  } else {
    return {
      ...extractedUsers,
      results: extractedUsers.results.map((user) => ({
        ...user,
        role: user.role
          ? transformRoleName(user.role, user.roleType)
          : user.role,
      })),
    };
  }
};

/** we are hardcoding those changes to the role name because we don't want to change
 * the roles file on the BE. (see EKP-21399 for more info)
 */
function transformRoleName(name: string, roleType: RoleType): string {
  const nameSplitted = name.split(' - ');
  const isLegacyAdmin = nameSplitted[0] === 'Admin';
  const isPowerUserWithSubroles =
    nameSplitted[0] === 'Power user' && nameSplitted.length > 1;
  if (
    roleType === RoleType.PreBuiltRole &&
    (isLegacyAdmin || isPowerUserWithSubroles)
  ) {
    const newName = name
      .replace('Workflow', 'Workflow Admin')
      .replace('Automation', 'Automation Admin');
    return `${newName} (legacy)`;
  }

  return name;
}
