const styles = {
  container: {
    height: '100%',
    width: '100%',
  },
  paginate: {
    paddingBottom: '24px',
  },
};

export default styles;
