import { bold, evisortBlue } from '../../../assets/shared-styles/general';

const styles = {
  searchboxContainer: {
    marginBottom: '20px',
    width: '100%',
  },
  filterSearch: {
    marginBottom: '20px',
  },
  otherFieldsContainer: {
    marginTop: '30px',
  },
  headerContainer: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    fontSize: '12px',
    fontWeight: bold,
  },
  titleActionContainer: {
    display: 'flex',
    color: evisortBlue,
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '12px',
    fontWeight: bold,
    cursor: 'pointer',
  },
  listContainer: {
    maxHeight: '130px',
    overflowY: 'scroll',
  },
  itemRowActionButton: {
    marginRight: '7px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  itemFieldContainer: {
    padding: '10px 0',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  fieldName: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default styles;
