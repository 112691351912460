import React, { useMemo } from 'react';

import { SortableList } from '../../../components';
import { Column } from '../types';
import { testIsNonPrimaryColumn } from '../utils';

interface Props {
  columns: Column[];
  columnOrder: string[];
  onUpdateColumnOrder: (updatedColumnOrder: string[]) => void;
  primaryColumnKey?: string;
}

export const ColumnOrder = ({
  columns,
  columnOrder,
  primaryColumnKey,
  onUpdateColumnOrder,
}: Props) => {
  const columnsMap = useMemo(
    () => Object.fromEntries(columns.map((column) => [column.key, column])),
    [columns],
  );

  const filteredColumns = useMemo(() => {
    return columnOrder
      .map((columnKey) => columnsMap[columnKey])
      .filter((column) => !!column)
      .filter(testIsNonPrimaryColumn(primaryColumnKey))
      .map((item) => ({ item }));
  }, [columnOrder, columnsMap]);

  const handleChange = (updatedColumns: Column[]) => {
    const updatedColumnOrder = updatedColumns.map((column) => column.key);
    onUpdateColumnOrder(updatedColumnOrder);
  };

  return (
    <SortableList
      isRemovable
      items={filteredColumns}
      renderTitle={(item: { title: string }) => item.title}
      onChange={handleChange}
    />
  );
};
