/**
 * The following utils implements sortable values based on the formal recommendation: https://react-select.com/advanced#sortable-multiselect
 *
 * Related components:
 * - Integration: ./Select.tsx
 * - SortableContainer: ./components/Control
 * - SortableElement: ./components/MultiValue
 */

/**
 * Based on formal recommendation in: https://react-select.com/advanced#sortable-multiselect
 * this prevents the menu from being opened/closed when the user clicks
 * on a value to begin dragging it. ideally, detecting a click (instead of
 * a drag) would still focus the control and toggle the menu, but that
 * requires some magic with refs that are out of scope for this example
 */
export const onSortableElementMouseDown = (event: MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

/**
 * Should be used and passed down the main `Select` component.
 */
export const getSortProps = (
  onSortEnd: (props: { oldIndex: number; newIndex: number }) => void,
) => ({
  axis: 'xy',
  distance: 4,
  helperClass: 'overrideReactSortableHoc',
  onSortEnd,
  getHelperDimensions: ({ node }: { node: HTMLElement }) =>
    node.getBoundingClientRect(),
});

export const arrayMove = <T extends unknown>(
  array: T[],
  from: number,
  to: number,
) => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
};
