import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout, Text } from '~/ui';

function Radio({
  disabled,
  id,
  option,
  selected,
  onChange = () => {},
  alignItems = 'center',
}) {
  const { label, value, sublabel } = option;

  return (
    <FlexLayout
      alignItems={alignItems}
      disabled={disabled}
      id={id}
      space={2}
      onClick={() => onChange(value)}
    >
      <Box
        p="2px"
        sx={{
          border: 'border-dark',
          borderRadius: 'round',
          flex: 'none',
          height: '16px',
          position: 'relative',
          width: '16px',
          minWidth: '16px',
          '&:after': {
            backgroundColor: selected ? 'blue-500' : 'white',
            borderRadius: 'round',
            content: '""',
            height: '10px',
            position: 'absolute',
            width: '10px',
          },
        }}
      />
      <FlexLayout alignItems="left" flexDirection="column">
        <Text color="gray-900" variant="s-dense">
          {label}
        </Text>
        {sublabel && (
          <Text color="gray-700" variant="2xs-dense" sx={{ pt: 3 }}>
            {sublabel}
          </Text>
        )}
      </FlexLayout>
    </FlexLayout>
  );
}

Radio.propTypes = {
  /** Disable selection */
  disabled: PT.bool,
  /** Option object: { value, label, sublabel } */
  option: PT.object,
  /** Indicates if this option is selected */
  selected: PT.bool,
  /** Callback that returns new selected value */
  onChange: PT.func,
};

export default Radio;
