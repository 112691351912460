import React from 'react';

import { MenuOption, User } from '../../../components';
import { MentionedEntity } from '../types';

interface Props {
  isFocused: boolean;
  search: string;
  mention: MentionedEntity<'user'>;
  onClick: (mention: MentionedEntity<'user'>) => void;
}

export const UserSuggestion = ({
  isFocused,
  mention,
  search,
  onClick,
}: Props) => {
  const { user } = (mention as MentionedEntity<'user'>).data;
  return (
    <MenuOption
      isFocused={isFocused}
      search={search}
      option={{
        label: mention.label,
        value: mention.id,
        description: user.email || '',
        leftContent: (
          <User mode="avatar" options={{ disableTooltip: true }} user={user} />
        ),
        rightIcon: mention.isInvalid ? 'block' : undefined,
      }}
      onClick={() => onClick(mention)}
    />
  );
};
