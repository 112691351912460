import React from 'react';

import {
  AgeValues,
  BooleanValues,
  DateValues,
  EnumSetValues,
  EnumValues,
  FileValues,
  NumberValues,
  TextValues,
} from '~/components/Shared/Filters_DEPRECATED';
import { types } from '~/eds';
import {
  AsyncValue,
  ClauseValue,
  DateValue,
  Field,
  Filter,
  FilterViewType,
  NumberValue,
  TextValue,
  Value,
} from '~/evifields';

import ClauseValues from './ClauseValues';

interface Props {
  field: Field;
  filter: Filter;
  onChange: (
    updatedValues: Value[],
    updatedAsyncValue?: AsyncValue<Value>,
  ) => void;
  onViewChange?: (filterView: types.Nullable<FilterViewType>) => void;
}

const Values = ({ field, filter, onChange, onViewChange }: Props) => {
  switch (field.type) {
    case 'age':
      return (
        <AgeValues filter={filter as Filter<NumberValue>} onChange={onChange} />
      );
    case 'boolean':
      return <BooleanValues />;
    case 'clause':
      return (
        <ClauseValues
          field={field as Field<'clause_v2'>}
          filter={filter as Filter<ClauseValue>}
          onChange={onChange}
        />
      );
    case 'date':
      return (
        <DateValues filter={filter as Filter<DateValue>} onChange={onChange} />
      );
    case 'enum': {
      return (
        <EnumValues
          field={field as Field<'enum'>}
          filter={filter}
          onChange={onChange}
        />
      );
    }
    case 'enum_set':
    case 'folder': {
      return (
        <EnumSetValues
          field={field as Field<'enum_set'>}
          filter={filter}
          onChange={onChange}
          onViewChange={onViewChange}
        />
      );
    }
    case 'file':
      return <FileValues />;
    case 'number':
      return (
        <NumberValues
          filter={filter as Filter<NumberValue>}
          onChange={onChange}
        />
      );
    case 'text':
    case 'text_search':
      return (
        <TextValues
          field={field}
          filter={filter as Filter<TextValue>}
          onChange={onChange}
        />
      );
    default:
      return null;
  }
};

export default Values;
