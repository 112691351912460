/**
 * NOTE: please avoid changes in this file as it has security implications.  Have #team-security review any changes to sanitize configs in this file
 */
import { Config } from 'dompurify';

/**
 * Extending dompurify's default/safe config
 * - support <head /> tag: https://github.com/cure53/DOMPurify/issues/501
 */
export const defaultConfig: Config = {
  WHOLE_DOCUMENT: true,
  ADD_TAGS: ['head'],
};
