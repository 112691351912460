import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { getClientDepartmentsTree } from '~/api';
import { withIsSelectedHash, withTreeNodeKeyDownHandler } from '~/hocs';
import { TreeNode } from '~/utils/TreeNode';

import EcButton from '../../Shared/EcButton';
import EcDepartmentTreeItem from '../../Shared/EcDepartmentTreeItem';
import EcModalCard from '../../Shared/EcModalCard';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import styles from './DepartmentTreeModal.styles';

const DepartmentTreeItem = withTreeNodeKeyDownHandler(EcDepartmentTreeItem);

class DepartmentTreeModal extends Component {
  state = {
    loadingDepartments: false,
    departmentTree: null,
    departmentsSelected: this.props.departmentsSelected || [],
  };

  UNSAFE_componentWillMount() {
    const { departmentTree } = this.state;

    if (!departmentTree) this.loadClientDepartments();
  }

  loadClientDepartments() {
    const { client } = this.props;
    this.setState({ loadingDepartments: true });
    getClientDepartmentsTree(client)
      .then((res) => {
        if (res) {
          this.setState({
            departmentTree: TreeNode.fromArray(res, undefined, {
              id: 'department_id',
              name: 'department_name',
            }),
            loadingDepartments: false,
            errorLoadingDepartments: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loadingDepartments: false,
          errorLoadingDepartments: true,
        });
      });
  }

  handleDepartmentSelect = (dept, checked) => {
    const { departmentsSelected } = this.state;

    if (checked) {
      this.setState({ departmentsSelected: [...departmentsSelected, dept] });
    } else {
      const newItems = departmentsSelected.filter(
        (f) => f.department_id !== dept.department_id,
      );

      this.setState({ departmentsSelected: newItems });
    }
  };

  handleSelect = (dept) => {
    const isSelected = this.props.getIsSelected(dept.id);

    this.handleDepartmentSelect(dept.treeNode, !isSelected);
    this.props.setIsSelected(dept.id, !isSelected);
  };

  handleOnChooseDepartmentClick = () => {
    const { handleDepartmentChange, hideModal } = this.props;
    const { departmentsSelected } = this.state;

    handleDepartmentChange(departmentsSelected);
    hideModal();
  };

  renderDepartmentTree() {
    const { classes, getIsSelected, setIsSelected } = this.props;
    const {
      departmentTree,
      departmentsSelected,
      loadingDepartments,
      errorLoadingDepartments,
    } = this.state;

    if (loadingDepartments) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    } else if (errorLoadingDepartments) {
      return <div>An error occurred while loading the departments.</div>;
    } else if (!errorLoadingDepartments && !departmentTree?.children.length) {
      return (
        <div className={classes.noDeptMessage}>
          No departments currently exist for your organization.
          <br />
          Create new departments from the Admin Console.
        </div>
      );
    } else {
      return (
        <div className={classes.departmentTree}>
          <DepartmentTreeItem
            department={departmentTree}
            departmentsSelected={departmentsSelected}
            getIsSelected={getIsSelected}
            onSelect={this.handleSelect}
            setIsSelected={setIsSelected}
          />
        </div>
      );
    }
  }

  render() {
    const {
      title,
      confirmButtonIcon,
      confirmButtonText,
      hideModal,
    } = this.props;
    const { departmentTree } = this.state;

    return (
      <EcModalCard
        title={title}
        content={this.renderDepartmentTree()}
        footer={
          departmentTree?.children.length > 0 && (
            <>
              <EcButton white borderless text="Cancel" onClick={hideModal} />
              <EcButton
                yellow
                iconLeft={confirmButtonIcon}
                text={confirmButtonText}
                onClick={this.handleOnChooseDepartmentClick}
              />
            </>
          )
        }
        hideModal={hideModal}
      />
    );
  }
}

export default injectSheet(styles)(withIsSelectedHash(DepartmentTreeModal));
