import { decamelizeKeys } from 'humps';

import { pilot } from '~/services';

type UpdateClientAdminProps = {
  clientId: string;
  inactiveTimeoutInSeconds: number;
  sessionTimeoutInSeconds: number;
};

export const updateClientAdmin = ({
  clientId,
  inactiveTimeoutInSeconds,
  sessionTimeoutInSeconds,
}: UpdateClientAdminProps) =>
  pilot.put(
    `client/${clientId}/user-admin/`,
    decamelizeKeys({
      inactiveTimeoutInSeconds,
      sessionTimeoutInSeconds,
    }),
  );
