import React from 'react';

import { Icon, Layout, LoadingShimmer } from '~/eds';
import { Link } from '~/ui';

export const SeeMoreFiles = ({ indent, isLoading, onClick }) => {
  return (
    <>
      {isLoading ? (
        <LoadingShimmer />
      ) : (
        <Layout ml={indent * 40} align="center" spacing={1}>
          <Icon icon="more" />
          <Link
            shouldTruncate={true}
            shouldWrap={false}
            onClick={onClick}
            variant="m-spaced"
          >
            See more files
          </Link>
        </Layout>
      )}
    </>
  );
};
