import { codeCash } from '~/services';
import { Uuid } from '~/types';

export interface Request {
  clientId: number;
  credits: number;
  effectiveDate: Date;
  idempotencyKey: Uuid;
  notes?: string;
}

export const updateRecurringCredits = async ({
  clientId,
  credits,
  effectiveDate,
  idempotencyKey,
  notes,
}: Request) =>
  await codeCash.put(`clients/${clientId}/credits/recurring`, {
    data: {
      type: 'recurringCredits',
      attributes: { credits, effectiveDate, idempotencyKey, notes },
    },
  });
