import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { Button, Text } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { withFlags } from '~/flags';
import { testHasFlag } from '~/permissions';
import { getProvisionTypes } from '~/redux/api/methods/document';
import { FlexLayout, InputLabel, MultiSelect, SingleSelect } from '~/ui';

import EcModalCard from '../../Shared/EcModalCard';
import styles from './TagClauseModal.styles';

const MAX_SELECTED_PROVISION_TYPES = 5;

class TagClauseModal extends Component {
  constructor(props) {
    super(props);

    // we model selectedProvisionTypes as an array of objects (it can be used in singleton or multi mode, based on this.props.isMulti
    this.state = {
      provisionTypes: [],
      selectedProvisionTypes: [],
      selectedApplyTo: false,
    };

    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleOnChangeClauses = this.handleOnChangeClauses.bind(this);
    this.handleOnChangeApplyTo = this.handleOnChangeApplyTo.bind(this);
    this.handleOnAddProvision = this.handleOnAddProvision.bind(this);
    this.handleOnUpdateClause = this.handleOnUpdateClause.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectedProvisionTypes: this.props.preselectedProvisionType
        ? [this.props.preselectedProvisionType]
        : [],
    });

    getProvisionTypes().then((provisionTypes) =>
      this.setState({
        provisionTypes: provisionTypes.suggestions.map((key) => ({
          value: key,
          label: key,
        })),
      }),
    );
  }

  handleActionClick() {
    const { createProvision, hideModal } = this.props;
    const { selectedProvisionTypes, selectedApplyTo } = this.state;

    selectedProvisionTypes.forEach((selectedProvisionType) =>
      createProvision(selectedProvisionType, selectedApplyTo),
    );
    hideModal();
  }

  handleOnChangeClauses(provisions) {
    const updatedSelectedProvisionTypes =
      provisions.length > MAX_SELECTED_PROVISION_TYPES
        ? this.state.selectedProvisionTypes
        : provisions;
    this.setState({ selectedProvisionTypes: updatedSelectedProvisionTypes });
  }

  handleOnUpdateClause(updatedProvision) {
    this.setState({ selectedProvisionTypes: [updatedProvision] });
  }

  handleOnChangeApplyTo(applyTo) {
    this.setState({ selectedApplyTo: applyTo });
  }

  handleOnAddProvision(provision) {
    this.setState({
      provisionTypes: [...this.state.provisionTypes, provision],
    });
  }

  renderClauseInput() {
    const { provisionTypes, selectedProvisionTypes } = this.state;

    const sortedProvisionTypes = provisionTypes?.length
      ? provisionTypes.sort((a, b) => (a.label > b.label ? 1 : -1))
      : [];

    const commonSelectProps = {
      width: 'fullWidth',
      enableDocumentBodyMenuPortal: true,
      isClearable: true,
      isSearchable: true,
      shouldHideSelectedOptions: false,
      options: sortedProvisionTypes,
      onAddOption: this.handleOnAddProvision,
    };

    return (
      <FlexLayout flexDirection="column" space={2}>
        <InputLabel
          id="select-clause-tagging-names"
          isRequired
          label="Clause"
          description="The Clause(s) that this language will be associated with when identified"
        >
          {this.props.isMulti ? (
            <FlexLayout flexDirection="column">
              <MultiSelect
                {...commonSelectProps}
                values={selectedProvisionTypes}
                onChange={this.handleOnChangeClauses}
                placeholder="Select Clauses"
              />
              <Text
                color={
                  selectedProvisionTypes.length >= MAX_SELECTED_PROVISION_TYPES
                    ? 'status.danger'
                    : 'text.quiet'
                }
                variant="tiny"
              >
                Maximum of {MAX_SELECTED_PROVISION_TYPES} Clause selections
              </Text>
            </FlexLayout>
          ) : (
            <SingleSelect
              {...commonSelectProps}
              value={selectedProvisionTypes[0] ?? null}
              onChange={this.handleOnUpdateClause}
              placeholder="Select clause"
            />
          )}
        </InputLabel>
      </FlexLayout>
    );
  }

  render() {
    const { hideModal, currentUser, bulkTaggingInsufficientWords } = this.props;
    const { selectedProvisionTypes, selectedApplyTo } = this.state;

    const isClauseTaggingDisabled = testHasFlag(
      FeatureFlagType.DisableDocTagging,
    )(currentUser);

    const shouldDisableClauseTagging =
      isClauseTaggingDisabled || bulkTaggingInsufficientWords;

    const saveDisabled = selectedProvisionTypes.length === 0;

    return (
      <EcModalCard
        title="Quick AI"
        content={
          <FlexLayout flexDirection="column" space={4}>
            {this.renderClauseInput()}
            <FlexLayout
              flexDirection="column"
              space={2}
              sx={{ height: '100px' }}
            >
              <InputLabel
                id="select-clause-tagging-type"
                isRequired
                label="Apply Sample to"
              >
                <SingleSelect
                  disabled={shouldDisableClauseTagging}
                  width="fullWidth"
                  enableDocumentBodyMenuPortal={true}
                  isClearable={true}
                  isSearchable={true}
                  shouldHideSelectedOptions={false}
                  options={[
                    { label: 'Only this document', value: false },
                    { label: 'This and other documents', value: true },
                  ]}
                  value={selectedApplyTo}
                  onChange={this.handleOnChangeApplyTo}
                />
              </InputLabel>
            </FlexLayout>
          </FlexLayout>
        }
        footer={
          <>
            <Button variant="tertiary" text="Cancel" onClick={hideModal} />
            <Button
              variant="primary"
              text="Save"
              onClick={this.handleActionClick}
              disabled={saveDisabled}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

TagClauseModal = connect(mapStateToProps)(TagClauseModal);

export default injectSheet(styles)(withFlags(TagClauseModal));
