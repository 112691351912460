import React, { memo, ReactNode } from 'react';

import { Text, TruncateText } from '../Text';

interface Props {
  children: ReactNode;
  wrap?: boolean;
}

export const Quote = memo(({ children, wrap }: Props) => {
  const TextElement = wrap ? Text : TruncateText;
  return (
    <TextElement
      as="blockquote"
      color="text.secondary"
      borderLeft="quote"
      fontStyle="italic"
      pl={2}
      w="100%"
      m={0}
    >
      {children}
    </TextElement>
  );
});
