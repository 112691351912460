import React, { Component } from 'react';
import injectSheet from 'react-jss';

import withFocus from '../../../Shared/HOCs/withFocus';
import withHover from '../../../Shared/HOCs/withHover';
import ChevronDownIcon from '../../../Shared/Icons/ChevronDownIcon';
import { DocumentsViewPageConsumer } from '../../DocumentsViewPage.context';
import InformationField from '../InformationField';
import styles from './KeyInformationSection.styles';

const InformationFieldWithHover = withFocus(withHover(InformationField));

class KeyInformationSection extends Component {
  constructor(props) {
    super(props);

    this.handleOnTitleClick = this.handleOnTitleClick.bind(this);
    this.state = { expanded: true };
  }

  componentDidMount() {
    this.setState({ section: this.props.section });
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  handleOnTitleClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  expandSection() {
    this.setState({ expanded: true });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.section !== prevState.section) {
      return { section: nextProps.section };
    } else {
      return null;
    }
  }

  renderSectionFields() {
    const {
      showOCR,
      showFlaggedOnly,
      userVisibilityLevel,
      onFieldTokenClick,
      isPdfHighlighter,
    } = this.props;
    const { section } = this.state;
    return section.fields
      .filter((field) => {
        if (showFlaggedOnly) return field.is_flagged;
        return field;
      })
      .map((field) => (
        <DocumentsViewPageConsumer key={field.id}>
          {(context) => (
            <InformationFieldWithHover
              field={field}
              showOCR={showOCR}
              userVisibilityLevel={userVisibilityLevel}
              documentId={context.document.id}
              refreshField={context.refreshField}
              allFields={section}
              onFieldTokenClick={onFieldTokenClick}
              isPdfHighlighter={isPdfHighlighter}
            />
          )}
        </DocumentsViewPageConsumer>
      ));
  }

  render() {
    const { classes, section } = this.props;
    const { expanded } = this.state;

    const sectionTitleClass = expanded
      ? classes.sectionTitleOpen
      : classes.sectionTitleClosed;

    return (
      <div className={classes.sectionWrapper}>
        <button
          aria-expanded={expanded}
          title={section.title}
          className={sectionTitleClass}
          onClick={this.handleOnTitleClick}
        >
          <ChevronDownIcon size="16" opacity="1" />
          <span>{section.title}</span>
          <span className="screenReaderText">
            Use Tab key to navigate fields
          </span>
        </button>
        {expanded ? (
          <div className={classes.sectionContent} role="menu">
            {this.renderSectionFields()}
          </div>
        ) : null}
      </div>
    );
  }
}

export default injectSheet(styles)(KeyInformationSection);
