export default `
<svg
  aria-hidden="true"
  aria-label="link"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M2 12C2 9.25 4.21875 7 7 7H10.25C10.6562 7 11 7.34375 11 7.75C11 8.1875 10.6562 8.5 10.25 8.5H7C5.0625 8.5 3.5 10.0938 3.5 12C3.5 13.9375 5.0625 15.5 7 15.5H10.25C10.6562 15.5 11 15.8438 11 16.25C11 16.6875 10.6562 17 10.25 17H7C4.21875 17 2 14.7812 2 12ZM17 17H13.75C13.3125 17 13 16.6875 13 16.25C13 15.8438 13.3125 15.5 13.75 15.5H17C18.9062 15.5 20.5 13.9375 20.5 12C20.5 10.0938 18.9062 8.5 17 8.5H13.75C13.3125 8.5 13 8.1875 13 7.75C13 7.34375 13.3125 7 13.75 7H17C19.75 7 22 9.25 22 12C22 14.7812 19.75 17 17 17ZM15.25 11.25C15.6562 11.25 16 11.5938 16 12C16 12.4375 15.6562 12.75 15.25 12.75H8.75C8.3125 12.75 8 12.4375 8 12C8 11.5938 8.3125 11.25 8.75 11.25H15.25Z"
    fill="currentcolor"
  />
</svg>
`;
