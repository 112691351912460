import React from 'react';
import { useStyles } from 'uinix-ui';

import { SharedStepsProps } from '../../../../types';
import { Icon } from '../../../Icon';
import { Layout } from '../../../Layout';
import { Markdown } from '../../../Markdown';
import { Text } from '../../../Text';

interface Props {
  instruction: SharedStepsProps['steps'][0]['instructions'][0];
  isActive: boolean;
}

export const Instruction = ({ instruction, isActive }: Props) => {
  const styles = useStyles();

  const { description, id, info, title } = instruction;

  return (
    <Layout
      borderRadius="m"
      direction="column"
      id={id}
      px={6}
      py={3}
      styles={styles.colors.status}
      styleProps={{
        status: isActive ? 'active' : undefined,
      }}
    >
      <Layout align="center" spacing={1}>
        <Text variant="body-bold">{title}</Text>
        {/* TODO: abstract InfoTooltip component/pattern */}
        {info && <Icon icon="info" tooltip={info} />}
      </Layout>
      <Markdown text={description} />
    </Layout>
  );
};
