import React, { useEffect } from 'react';

import {
  ContentContainer,
  Divider,
  Layout,
  MenuOption,
  Popover,
  Text,
} from '../../../components';
import { EntityType, MentionedEntity, Mentions } from '../types';
import { DepartmentSuggestion } from './DepartmentSuggestion';
import { UserSuggestion } from './UserSuggestion';

interface Props {
  index: number;
  isMentionsLoading: boolean;
  isVisible: boolean;
  search: string;
  suggestions: Mentions;
  trigger: React.ReactElement;
  onFocusEditor: () => void;
  onHoverMention: (updatedIndex: number) => void;
  onSelectMention: (mention: MentionedEntity<EntityType>) => void;
}

const MENTION_ENTITY_LABEL: Record<string, string> = {
  user: 'People',
  department: 'Departments',
};

export const MentionSuggestions = ({
  index,
  isMentionsLoading,
  isVisible,
  search,
  suggestions,
  trigger,
  onFocusEditor,
  onHoverMention,
  onSelectMention,
}: Props) => {
  useEffect(() => {
    if (isVisible) {
      onFocusEditor();
    }
    return () => {
      onFocusEditor();
    };
  }, [isVisible]);

  const renderSuggestion = (
    mention: MentionedEntity<EntityType>,
    isFocused: boolean,
  ) => {
    switch (mention.type) {
      case 'user':
        return (
          <UserSuggestion
            search={search}
            isFocused={isFocused}
            mention={mention}
            onClick={onSelectMention}
          />
        );
      case 'department':
        return (
          <DepartmentSuggestion
            search={search}
            isFocused={isFocused}
            mention={mention}
            onClick={onSelectMention}
          />
        );
      default:
        return (
          <MenuOption
            search={search}
            isFocused={isFocused}
            option={{
              label: mention.label,
              value: mention.id,
            }}
            onClick={() => onSelectMention(mention)}
          />
        );
    }
  };

  let count = 0;
  const suggestedMentions: (JSX.Element | null)[] = Object.entries(
    suggestions,
  ).map(([entityType, mentions]) => {
    if (!mentions || mentions.length === 0) {
      return null;
    }
    return (
      <Layout key={entityType} direction="column" pt={2} w="dropdown.min-width">
        <Text
          preset="description"
          pl={4}
          pr={3}
          pb={2}
          textTransform="capitalize"
          w="100%"
        >
          {MENTION_ENTITY_LABEL[entityType]}
        </Text>
        {mentions.map((mention) => {
          const isFocused = count === index;
          const mentionIndex = count;
          count++;
          return (
            <div
              key={mention.id}
              onMouseEnter={() => onHoverMention(mentionIndex)}
            >
              {renderSuggestion(mention, isFocused)}
            </div>
          );
        })}
        <Divider />
      </Layout>
    );
  });

  return (
    <Popover
      isVisible={isVisible}
      preset="dropdown"
      trigger={trigger}
      triggerStyle={{
        flex: 'auto',
        width: '100%',
      }}
    >
      <Layout direction="column" w="dropdown.min-width">
        <ContentContainer
          loadingContent={{
            isLoading: isMentionsLoading,
            message: 'Loading mentions...',
          }}
        >
          <>{suggestedMentions}</>
        </ContentContainer>
      </Layout>
    </Popover>
  );
};
