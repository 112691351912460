import { wordCount } from '~/eds';
import { Nullable } from '~/types';

import { PROMPT_WORD_LIMIT } from '../constants';
import { OptimizeState, PromptModelPublishStatus } from '../types';
import { testIsOptimizingState } from './status';

/**
 * Determine the appropriate model progress status to display:
 * - if optimizing (i.e. 'initializing' | 'running' ), show the optimize state
 * - else if not optimizing (i.e. null | 'done' | 'error')
 *   - if publish state is `null`, show optimize state
 *   - else show the publish state
 * - show publish state in any other case
 */
export const getModelProgressStatusChip = ({
  optimizeState,
  publishStatus,
}: {
  optimizeState: Nullable<OptimizeState>;
  publishStatus: Nullable<PromptModelPublishStatus>;
}) => {
  if (optimizeState) {
    return testIsOptimizingState(optimizeState) || publishStatus === null
      ? getModelOptimizeStateChip(optimizeState)
      : getModelPublishStatusChip(publishStatus);
  } else {
    return getModelPublishStatusChip(publishStatus);
  }
};

export const getModelOptimizeStateChip = (optimizeState: OptimizeState) => {
  switch (optimizeState) {
    case 'error':
      return {
        icon: 'ai' as const,
        status: 'danger' as const,
        text: 'Boost Error',
      };
    case 'done':
      return {
        icon: 'ai' as const,
        status: 'ai' as const,
        text: 'Boost Ready',
      };
    case 'initializing':
    case 'running':
    default:
      return {
        icon: 'ai' as const,
        status: 'warning' as const,
        text: 'Boost in Progress',
      };
  }
};

export const getModelPublishStatusChip = (
  publishStatus: Nullable<PromptModelPublishStatus>,
) => {
  switch (publishStatus) {
    case 'in_progress':
    case 'resumed':
      return {
        status: 'info' as const,
        text: 'In Progress',
      };
    case 'completed':
      return {
        status: 'success' as const,
        text: 'Published',
      };
    case 'failed':
      return {
        status: 'danger' as const,
        text: 'Failed',
      };
    case 'suspended':
      return {
        status: 'danger' as const,
        text: 'Suspended',
      };
    default:
      return {
        status: 'warning' as const,
        text: 'Draft',
      };
  }
};

export const testIsPromptOverLimit = (prompt: string) =>
  wordCount(prompt) > PROMPT_WORD_LIMIT;
