import React, { useState } from 'react';
import injectSheet from 'react-jss';

import { EM_DASH } from '~/constants/unicode';
import { UploadStatusType } from '~/enums';
import { MODAL_SIMPLE } from '~/types/modal.types';

import {
  black2,
  evisortBlue,
  evisortOrange,
  evisortRed,
  evisortYellow,
} from '../../../assets/shared-styles/general';
import { algorithmStatusHelper } from '../../../utils/helper.utils';
import { featureFlagIncluded, testIsSuperAdmin } from '../../../utils/user';
import EcButton from '../../Shared/EcButton';
import EcModal from '../../Shared/EcModal';
import EcTooltip from '../../Shared/EcTooltip';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import CloseIcon from '../../Shared/Icons/CloseIcon';
import InfoIcon from '../../Shared/Icons/InfoIcon';
import QueueIcon from '../../Shared/Icons/QueueIcon';
import SyncIcon from '../../Shared/Icons/SyncIcon';
import UploadArrowUpIcon from '../../Shared/Icons/UploadArrowUpIcon';
import WarningIcon from '../../Shared/Icons/WarningIcon';
import styles from './UploadStatus.styles';
import { UploadStatusTooltip } from './UploadStatusTooltip.utils';

const UploadStatuses = {
  COMPLETED: 'Completed',
  QUEUED: 'Queued',
  PROCESSING: 'Processing',
  PROCESSING_ERROR: 'Processing Error',
  UPLOAD_FAILED: 'Upload Failed',
  UPLOAD_FILETYPE_FAILURE: 'Upload Failed',
  UPLOADING: 'Uploading',
  OCR_FAILURE: 'Completed',
  ALGORITHM_FAILURE: 'Completed',
  DUPLICATE: 'Duplicate',
};

function UploadStatus({ classes, status, progress, algoStatus, user, logId }) {
  const [modal, showModal] = useState();
  function renderAlgoStatusModal() {
    if (modal) {
      return (
        <EcModal
          modalType={MODAL_SIMPLE}
          title="Algorithm Status"
          width="560px"
          hideModal={() => showModal(false)}
          includeCancelButton={false}
          hideCloseIcon={true}
          modalButtons={
            <EcButton yellow text="Close" onClick={() => showModal(false)} />
          }
        >
          {renderAlgoStatus()}
        </EcModal>
      );
    }
    return null;
  }

  function renderStatusIcon() {
    switch (status) {
      case UploadStatusType.Completed:
        return <CheckmarkIcon size="20" green />;
      case UploadStatusType.Queued:
        return <QueueIcon color={black2} />;
      case UploadStatusType.Processing:
        return <SyncIcon size="20" color={evisortBlue} />;
      case UploadStatusType.ProcessingError:
      case UploadStatusType.UploadFailed:
      case UploadStatusType.UploadFileTypeFailure:
        return <CloseIcon size="20" color={evisortRed} />;
      case UploadStatusType.Uploading:
        return <UploadArrowUpIcon size="20" color={evisortYellow} />;
      case UploadStatusType.Duplicate:
        return featureFlagIncluded(user, 'DUPLICATE_STATUS') ? (
          <WarningIcon size="20" color={evisortOrange} />
        ) : (
          <CheckmarkIcon size="20" green />
        );
      case UploadStatusType.OcrFailure:
      case UploadStatusType.AlgorithmFailure:
        return <WarningIcon size="20" yellow />;
      default:
        break;
    }
  }

  function renderAlgoStatus() {
    const algoList = algorithmStatusHelper(algoStatus);

    return algoList
      ? algoList.map((item) => {
          let itemMessageLabel = EM_DASH;
          if (item.message.length) itemMessageLabel = item.message;

          return (
            <div key={item.name} className={classes.algoContainer}>
              <span className={classes.statusContainer}>
                <span
                  className={
                    item.status === 1
                      ? classes.algoStatusGreenDot
                      : item.status === 0
                      ? classes.algoStatusYellowDot
                      : item.status === -2
                      ? classes.algoStatusBlueDot
                      : classes.algoStatusRedDot
                  }
                />
                <span className={classes.algoName}>{item.name}</span>
              </span>
              {item.status === 1 ? (
                <div className={classes.statusMessage}>
                  Message {itemMessageLabel}
                </div>
              ) : item.status === 0 ? (
                <div className={classes.statusMessage}>In Progress</div>
              ) : item.status === -2 ? (
                <div className={classes.statusMessage}>Partially Complete</div>
              ) : (
                <div className={classes.statusErrorMessage}>Failed</div>
              )}
            </div>
          );
        })
      : null;
  }

  const isSuperAdmin = testIsSuperAdmin(user);

  return (
    <div className={classes.statusContainer}>
      <div
        className={classes.uploadStatusTooltip}
        data-tip
        data-for={`uploadStatusTooltip-${logId}`}
      >
        {renderStatusIcon()}
        <span className={classes.statusName}>
          {featureFlagIncluded(user, 'DUPLICATE_STATUS')
            ? UploadStatuses[status]
            : status === UploadStatusType.Duplicate
            ? UploadStatuses[UploadStatusType.Completed]
            : UploadStatuses[status]}
          &nbsp;
        </span>
        {status !== UploadStatusType.Duplicate && (
          <EcTooltip
            id={`uploadStatusTooltip-${logId}`}
            width="224px"
            place="left"
          >
            {UploadStatusTooltip[status]}
          </EcTooltip>
        )}
      </div>
      {isSuperAdmin && algoStatus && (
        <button
          className={classes.algoStatusButton}
          onClick={() => showModal(true)}
        >
          <InfoIcon color={black2} size="18" />
        </button>
      )}
      {renderAlgoStatusModal()}
      {progress ? (
        <span className={classes.statusProgress}>{`\u2013 ${Math.floor(
          progress,
        )}%`}</span>
      ) : null}
    </div>
  );
}

export default injectSheet(styles)(UploadStatus);
