export const searchAreaOptions = [
  { value: 'filename_content', label: 'Filename and Content' },
  { value: 'filename', label: 'Filename' },
  { value: 'content', label: 'Content' },
];

export const searchWordsContainOptions = [
  { value: 'contains', label: 'contains' },
  { value: 'does_not_contain', label: 'does not contain' },
];

export const searchWordsScopeOptions = [
  { value: 'all_of_these_words', label: 'all of these words' },
  { value: 'any_of_these_words', label: 'any of these words' },
  { value: 'exact_phrase', label: 'exact phrase' },
];
