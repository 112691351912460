const styles = {
  userNameSearchContainer: {
    marginTop: '20px',
    paddingBottom: '50px',
    '& input[type=text]': {
      width: '240px',
    },
  },
  inputFieldHeader: {
    margin: '20px 0 10px',
    display: 'block',
    fontSize: '12px',
  },
};

export default styles;
