import PropTypes from 'prop-types';
import React from 'react';

import {
  evisortRed,
  evisortYellow,
} from '../../../assets/shared-styles/general';

const WarningIcon = (props) => {
  const { color, red, yellow } = props;

  let fillColor = color;
  if (red) {
    fillColor = evisortRed;
  } else if (yellow) {
    fillColor = evisortYellow;
  }

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={fillColor}
          fillRule="nonzero"
          d="M3.468 21.6h17.064c1.745 0 2.833-1.885 1.96-3.387L13.96 3.526c-.872-1.501-3.048-1.501-3.92 0L1.507 18.213c-.872 1.502.216 3.387 1.96 3.387zM12 13.698a1.134 1.134 0 0 1-1.133-1.13V9.112a1.134 1.134 0 0 1 2.266 0v3.458c0 .62-.51 1.129-1.133 1.129zm1.133 4.515h-2.266v-2.258h2.266v2.258z"
        />
      </g>
    </svg>
  );
};

WarningIcon.defaultProps = {
  color: '#000',
  size: '24',
};

WarningIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string.isRequired,
};

export default WarningIcon;
