import {
  black1,
  black2,
  bold,
  evisortBlue,
} from '../../../assets/shared-styles/general';

const styles = {
  subheadWrapper: {
    display: 'flex',
  },
  subheader: {
    marginBottom: '24px',
    color: black2,
    fontSize: '19px',
    fontWeight: bold,
    lineHeight: '33px',
  },
  sectionsActionWrapper: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  separator: {
    height: '24px',
    display: 'block',
  },
  reorderBtnWrapper: {
    marginRight: '15px',
    '& svg': {
      marginTop: '2px',
    },
  },
  addDataField: {
    marginRight: '10px',
    display: 'inline-flex',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
    cursor: 'pointer',
    '& svg': {
      marginRight: '4px',
    },
  },
  modalLabel: {
    marginBottom: '12px',
    fontSize: '12px',
    color: black2,
  },
  dropdownWrapper: {
    display: 'inline-flex',
    position: 'relative',
    top: '3px',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  dataAddedEditedMessage: {
    margin: '20px 0',
    padding: '12px 18px 18px',
    color: black1,
    backgroundColor: 'rgba(255, 196, 73, 0.25)',
    fontSize: '13px',
  },
  dataAddedEditedMessageIcon: {
    marginRight: '10px',
    position: 'relative',
    top: '5px',
  },
};

export default styles;
