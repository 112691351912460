export default `
<svg
  aria-hidden="true"
  aria-label="loading-content-deprecated"
  width="128"
  height="64"
  viewBox="0 0 128 64"
  fill="none"
>
  <style>
    .Bar_5_786 {
      animation: 0.9s linear 0.9s infinite normal forwards running Bar_5_786;
      transform: translateX(112px) translateY(24px);
      height: 16px;
      fill: rgba(184, 227, 255, 1);
    }
    @keyframes Bar_5_786 {
      0% {
        transform: translateX(112px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      55.56% {
        transform: translateX(112px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      66.67% {
        transform: translateX(112px) translateY(16px);
        height: 32px;
      }
      77.78% {
        transform: translateX(112px) translateY(0px);
        height: 64px;
        fill: rgba(22, 132, 208, 1);
      }
      88.89% {
        transform: translateX(112px) translateY(16px);
        height: 32px;
      }
      100% {
        transform: translateX(112px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
    }
    .Bar_6_852 {
      animation: 0.9s linear 0.9s infinite normal forwards running Bar_6_852;
      transform: translateX(84px) translateY(24px);
      height: 16px;
      fill: rgba(184, 227, 255, 1);
    }
    @keyframes Bar_6_852 {
      0% {
        transform: translateX(84px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      44.44% {
        transform: translateX(84px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      100% {
        transform: translateX(84px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      55.56% {
        transform: translateX(84px) translateY(16px);
        height: 32px;
      }
      66.67% {
        transform: translateX(84px) translateY(0px);
        height: 64px;
        fill: rgba(22, 132, 208, 1);
      }
      77.78% {
        transform: translateX(84px) translateY(16px);
        height: 32px;
      }
      88.89% {
        transform: translateX(84px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
    }
    .Bar_7_492 {
      animation: 0.9s linear 0.9s infinite normal forwards running Bar_7_492;
      transform: translateX(56px) translateY(24px);
      height: 16px;
      fill: rgba(184, 227, 255, 1);
    }
    @keyframes Bar_7_492 {
      0% {
        transform: translateX(56px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      33.33% {
        transform: translateX(56px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      100% {
        transform: translateX(56px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      44.44% {
        transform: translateX(56px) translateY(16px);
        height: 32px;
      }
      55.56% {
        transform: translateX(56px) translateY(0px);
        height: 64px;
        fill: rgba(22, 132, 208, 1);
      }
      66.67% {
        transform: translateX(56px) translateY(16px);
        height: 32px;
      }
      77.78% {
        transform: translateX(56px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
    }
    .Bar_8_325 {
      animation: 0.9s linear 0.9s infinite normal forwards running Bar_8_325;
      transform: translateX(28px) translateY(24px);
      height: 16px;
      fill: rgba(184, 227, 255, 1);
    }
    @keyframes Bar_8_325 {
      0% {
        transform: translateX(28px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      22.22% {
        transform: translateX(28px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      100% {
        transform: translateX(28px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      33.33% {
        transform: translateX(28px) translateY(16px);
        height: 32px;
      }
      44.44% {
        transform: translateX(28px) translateY(0px);
        height: 64px;
        fill: rgba(22, 132, 208, 1);
      }
      55.56% {
        transform: translateX(28px) translateY(16px);
        height: 32px;
      }
      66.67% {
        transform: translateX(28px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
    }
    .Bar_9_677 {
      animation: 0.9s linear 0.9s infinite normal forwards running Bar_9_677;
      transform: translateX(0px) translateY(24px);
      height: 16px;
      fill: rgba(184, 227, 255, 1);
    }
    @keyframes Bar_9_677 {
      0% {
        transform: translateX(0px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      11.11% {
        transform: translateX(0px) translateY(16px);
        height: 32px;
      }
      22.22% {
        transform: translateX(0px) translateY(0px);
        height: 64px;
        fill: rgba(22, 132, 208, 1);
      }
      33.33% {
        transform: translateX(0px) translateY(16px);
        height: 32px;
      }
      44.44% {
        transform: translateX(0px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
      100% {
        transform: translateX(0px) translateY(24px);
        height: 16px;
        fill: rgba(184, 227, 255, 1);
      }
    }
  </style>
  <g width="128" height="64">
    <rect
      width="16"
      height="16"
      rx="8"
      fill="#B8E3FF"
      data-name="Bar 5"
      class="Bar_5_786"
    />
    <rect
      width="16"
      height="16"
      rx="8"
      fill="#B8E3FF"
      data-name="Bar 6"
      class="Bar_6_852"
    />
    <rect
      width="16"
      height="16"
      rx="8"
      fill="#B8E3FF"
      data-name="Bar 7"
      class="Bar_7_492"
    />
    <rect
      width="16"
      height="22.5"
      rx="8"
      fill="#A4D7F9"
      data-name="Bar 8"
      class="Bar_8_325"
    />
    <rect
      width="16"
      height="63"
      rx="8"
      fill="#2A90D6"
      data-name="Bar 9"
      class="Bar_9_677"
    />
  </g>
</svg>
`;
