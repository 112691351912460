import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import { IconButton } from '~/eds';

import styles from './EcModalCard.styles';

function EcModalCard(props) {
  const {
    classes,
    content,
    footer,
    hideModal,
    id,
    title,
    renderTitleControls,
  } = props;

  return (
    <div className={classes.ecModalCardWrapper} aria-label={title} id={id}>
      <div className={classes.ecModalCardTitleWrapper}>
        <div className={classes.ecModalCardTitle}>{title}</div>
        <div className={classes.ecModalCardTitleControl}>
          {renderTitleControls?.()}
          <IconButton
            icon="x"
            text={`${title} heading level 2 dialog close`}
            onClick={hideModal}
            name="close"
            tooltip="Close"
          />
        </div>
      </div>
      <div className={classes.ecModalCardContent}>{content}</div>
      {footer && <div className={classes.ecModalCardFooter}>{footer}</div>}
    </div>
  );
}

EcModalCard.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  renderTitleControls: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(EcModalCard);
