import { useEffect, useState } from 'react';

import { UploadStatusType } from '~/enums';
import { api } from '~/redux';
import { Entity, Nullable } from '~/types';

export const useDocumentProcessingStatus = (
  entity: Nullable<Entity<'document_handler' | 'ticket'>>,
): {
  isLoading: boolean;
  isProcessing: boolean;
} => {
  const { id, type } = entity ?? {};

  const [isProcessing, setIsProcessing] = useState<boolean | undefined>();

  useEffect(() => {
    if (!entity) {
      setIsProcessing(undefined);
    }

    return () => {
      setIsProcessing(undefined);
    };
  }, [entity]);

  const shouldStopFetching = isProcessing === false;

  const endpoint =
    type === 'document_handler' ? 'getDocumentAlgoStatus' : 'getDocumentByTag';

  const {
    isLoading: isDocumentAlgoStatusLoading,
    data: algoStatusData,
  } = api.endpoints[endpoint].useQuery(
    { id: Number(id), tag: id as string },
    {
      skip: !entity || shouldStopFetching,
      pollingInterval: 5000,
    },
  );

  useEffect(() => {
    if (algoStatusData) {
      setIsProcessing(
        [UploadStatusType.Processing, UploadStatusType.Queued].includes(
          algoStatusData.processingStatus as UploadStatusType,
        ),
      );
    }
  }, [algoStatusData]);

  return {
    isLoading: isDocumentAlgoStatusLoading,
    isProcessing: Boolean(isProcessing),
  };
};
