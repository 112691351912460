import React, { useState } from 'react';

import FolderTree from '~/components/Shared/FolderTree';
import { Button, Icon } from '~/eds';
import { FlexLayout, Text, useModalSimple } from '~/ui';

function ArchiveLocationPath({
  folderId,
  onChange,
  errorMessage,
  // connected
  folderPath,
}) {
  const [selectedItemId, setSelectedItemId] = useState(folderId);

  const [modal, showModal] = useModalSimple({
    disableAutoHide: true,
    title: 'Choose Folder for Archive Location',
    text: 'Choose Folder',
    confirm: () => onChange(selectedItemId),
    content: (
      <FlexLayout sx={{ height: '320px' }}>
        <FolderTree
          selectedItemId={selectedItemId}
          setSelectedItemId={setSelectedItemId}
        />
      </FlexLayout>
    ),
  });

  const placeholder = (
    <Text color="gray-600" sx={{ fontStyle: 'italic' }}>
      Select a folder
    </Text>
  );

  return (
    <FlexLayout flexDirection="column" space={2}>
      <Text variant="xs-dense-caps">Destination</Text>
      <FlexLayout
        id="workflow--settings-naming-format"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={1}
        space={4}
        sx={{
          bg: 'gray-100',
          border: 'border',
          borderRadius: 'm',
          height: 'input-height',
          borderColor: errorMessage ? 'red-400' : 'gray-200',
        }}
        onClick={showModal}
      >
        <FlexLayout space={2}>
          <Icon icon="folder" />
          <Text variant="s-spaced">
            {(folderPath && folderPath[folderId]) || placeholder}
          </Text>
        </FlexLayout>
        <Button
          id="workflow--settings-pick-folder"
          text="Click to pick folder"
          variant="action"
          onClick={showModal}
        />
      </FlexLayout>
      {errorMessage && (
        <Text mt={2} color="red-600" variant="2xs-dense">
          {errorMessage}
        </Text>
      )}
      {modal}
    </FlexLayout>
  );
}

export default ArchiveLocationPath;
