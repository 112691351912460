import React from 'react';

import { Icon, Layout, Text, types, useModal } from '~/eds';
import { BulkActionType } from '~/enums';
import { actionTypeContent, actionTypeTitle } from '~/utils';

type Callback = () => void;

interface Props {
  limit: number;
  count: number | undefined;
  actionType: BulkActionType;
  primaryActionHide: Callback;
}

export const useLimitExceededModal = ({
  limit,
  count = 0,
  actionType,
  primaryActionHide,
}: Props): [React.ReactNode, Callback, Callback] => {
  const children = (
    <Layout spacing={3}>
      <Icon icon="status-warning" color="status.warning" size="l" />
      <Text>
        Only {limit} documents can be {actionTypeContent(actionType)} at a time.
        Please select fewer documents to complete this action.
      </Text>
    </Layout>
  );

  const props = {
    children,
    primaryAction: {
      text: 'Got it',
      variant: 'primary' as types.ButtonVariant,
      onClick: primaryActionHide,
    },
    onCancel: null,
    title: `${actionTypeTitle(actionType)} ${count} Documents: Limit Exceeded`,
  };

  return useModal(props);
};
