import singleSelectStylesFactory from '../SingleSelect/stylesFactory';

export default function stylesFactory(error) {
  return {
    ...singleSelectStylesFactory(error),
    multiValueRemove: (styles) => ({
      ...styles,
      padding: 0,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
      },
    }),
  };
}
