import React from 'react';

import { ConditionalSelectValue, Option } from '../../types';
import { typedMemo } from '../../utils';
import { Label } from '../Label';
import { Layout } from '../Layout';
import { Select } from '../Select';

interface ConditionalInput {
  /** Should be a Select-based component */
  input: React.ElementType;
  /** Input props */
  inputProps: Object;
  /** Input label */
  label: string;
}

interface Props<SV extends string, CV, CM extends boolean> {
  // conditional inputs determined by the primary input.
  conditionalInputs: Record<SV, ConditionalInput>;
  // The primary input (Select) setting the condition.
  sourceInput: {
    name: string;
    options: Option<SV>[];
    placeholder?: string;
  };
  /** Value interface tracking relationship of a source value (V1) affecting multiple conditional values (V2) */
  value: ConditionalSelectValue<SV, CV, CM>;
  /** Callback when value is updated */
  onChange: (
    updatedConditionalValue: ConditionalSelectValue<SV, CV, CM>,
  ) => void;
  /** Disables the conditional input */
  disabled?: boolean;
}

/**
 * This component is a composed pattern that allows a primary `sourceInput` to conditionally render `conditionalInputs`.  It is recommended that all inputs should implement the `Select-*` interface.
 *
 * The value signature tracks the primary source value and conditional values selected.
 *
 * This component also automates label/input association, layout, and state management.
 */
export const ConditionalSelect = typedMemo(
  <SV extends string, CV, CM extends boolean>({
    conditionalInputs,
    disabled,
    sourceInput,
    value,
    onChange,
  }: Props<SV, CV, CM>) => {
    const { options, name, placeholder } = sourceInput;
    const { conditionalValues, sourceValue } = value;

    const conditionalInput = sourceValue
      ? conditionalInputs[sourceValue]
      : null;

    return (
      <Layout preset="form-fields">
        <Select<SV, false>
          disabled={disabled}
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          name={name}
          options={options}
          placeholder={placeholder}
          value={sourceValue}
          onChange={(updatedSourceValue) =>
            onChange({
              sourceValue: updatedSourceValue,
              conditionalValues: {},
            })
          }
        />
        {conditionalInput && sourceValue && (
          <Layout align="center" preset="m">
            <Label htmlFor={conditionalInput.label} variant="body">
              {conditionalInput.label}
            </Label>
            <conditionalInput.input
              {...conditionalInput.inputProps}
              // prop overrides (always ensure the `Select` isMulti and is associated with the `Label` via the same ID)
              id={conditionalInput.label}
              name={conditionalInput.label}
              value={conditionalValues[sourceValue]}
              onChange={(updatedConditionalValues: CV[]) =>
                onChange({
                  ...value,
                  conditionalValues: {
                    ...conditionalValues,
                    [sourceValue]: updatedConditionalValues,
                  },
                })
              }
            />
          </Layout>
        )}
      </Layout>
    );
  },
);
