import React, { useState } from 'react';

export function withIsSelectedHash(Base) {
  return function WrappedwithIsSelectedHash(props) {
    const [isSelectedHash, setIsSelectedHash] = useState({});

    const setIsSelected = (id, isSelected) => {
      if (
        (isSelected && !isSelectedHash[id]) ||
        (!isSelected && isSelectedHash[id])
      ) {
        isSelectedHash[id] = isSelected;

        setIsSelectedHash({ ...isSelectedHash });
      }
    };

    return (
      <Base
        {...props}
        setIsSelected={setIsSelected}
        getIsSelected={(id) => isSelectedHash[id]}
      />
    );
  };
}
