/* trunk-ignore-all(prettier) */
import { camelize, camelizeKeys as camelizeKeysHumps } from 'humps';

import { JsonApiListResponse } from '../ApiTypes';

export type ParsedType<T> = {
  [K in Extract<keyof T, string> as CamelCase<K>]: T[K] extends Array<infer U>
    ? Array<ParsedValue<U>>
    : ParsedValue<T[K]>;
};

type CamelCase<S extends string> = S extends `${infer H}_${infer T}`
  ? `${Lowercase<H>}${Capitalize<CamelCase<T>>}`
  : Lowercase<S>;

type ParsedValue<T> = T extends object ? ParsedType<T> : T;

export function camelizeKeys<T extends object>(
  object: T,
  keysToExclude: string[] = [],
): ParsedType<T> {
  const camelizedResponse: Record<string, any> = camelizeKeysHumps(object);
  const camelizedKeysToExlude = keysToExclude.map((key) => camelize(key));

  // Iterate through the keys to exclude and revert them back to snake_case if they exist in the response.
  camelizedKeysToExlude.forEach((keyToExclude, i) => {
    if (keyToExclude in camelizedResponse) {
      camelizedResponse[keyToExclude] = (object as Record<string, any>)[
        keysToExclude[i]
      ];
    }
  });

  return camelizedResponse as ParsedType<T>;
}

type ResourceAttributes<T> = {
  results: ParsedType<T>[];
  total: number;
  columns?: { key: string; label: string }[];
};

export function extractResourceAttributes<T extends object>(
  serverResponse: JsonApiListResponse<any, T, any>,
  keysToExcludeFromCamelization: string[] = [],
): ResourceAttributes<T> {
  const results = serverResponse.data.map((user) => {
    const parsedAttributes: ParsedType<T> = camelizeKeys<T>(
      user.attributes,
      keysToExcludeFromCamelization,
    );
    return { ...parsedAttributes, id: user.id };
  });

  return {
    results,
    total: serverResponse.meta?.count ?? 0,
    columns: serverResponse.meta?.columns ?? [],
  };
}
