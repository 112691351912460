import React from 'react';

import { Box, Layout } from '~/eds';
import { OnboardingIdType } from '~/features/onboarding';

import IntegrationCard from './IntegrationCard';

function IntegrationCardList({ integrationCards }) {
  return (
    <Layout wrap={true} role="list" id={OnboardingIdType.SelectProvider}>
      {integrationCards.map((integrationCard) => {
        return (
          <Box key={integrationCard.provider} pb={6} pr={3} role="listitem">
            <IntegrationCard {...integrationCard} />
          </Box>
        );
      })}
    </Layout>
  );
}

export default IntegrationCardList;
