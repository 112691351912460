import { DEFAULT_POLL_INTERVAL_MS } from '~/eds';
import { api } from '~/redux';
import { Nullable } from '~/types';

/**
 * This hook polls the model progress, and merges the progress.publishStatus onto the model version
 */
export const usePollModelVersionProgress = (
  props: Nullable<{
    modelId: string;
    version: number;
  }>,
) => {
  // these default values are never reached as RTKQ query is skipped, but are authored for type safety
  const modelId = props?.modelId ?? '';
  const version = props?.version ?? 1;
  const skip = props === null;

  const {
    data: modelVersion,
    isError: isErrorModelVersion,
    isFetching: isLoadingModelVersion,
  } = api.endpoints.getPromptModelVersion.useQuery(
    {
      modelId,
      version,
    },
    {
      skip,
    },
  );

  const {
    data: modelProgress = {
      docsProcessed: 0,
      docsInScope: 0,
      publishStatus: null,
      valuesFound: 0,
      docsInScopeProcessing: false,
    },
    isError: isErrorModelProgress,
    isLoading: isLoadingModelProgress,
  } = api.endpoints.getPromptModelProgress.useQuery(
    {
      modelId,
    },
    {
      pollingInterval: DEFAULT_POLL_INTERVAL_MS,
      skip,
    },
  );

  return {
    isErrorModelProgress,
    isErrorModelVersion,
    isLoadingModelProgress,
    isLoadingModelVersion,
    modelProgress,
    modelVersion: modelVersion // merge the modelProgress pubishStatus
      ? {
          ...modelVersion,
          publishStatus: modelProgress.publishStatus,
          // optimize: TODO BE should support /progress for optimize so it can be polled together.
        }
      : undefined,
  };
};
