import { keyBy, pick } from 'lodash';

import { SearchableFields } from '~/api';
import { DataFieldType } from '~/enums';
import { Field, FieldId, Filter } from '~/evifields';
import * as componentTypes from '~/types';
import { capitalizeWords } from '~/utils/strings';

import {
  AlertSectionsResponse,
  getAlertSections,
  PilotField,
  PilotFieldSection,
  SearchableFieldId,
} from '../methods';

type FilterSectionsResponse = {
  dataFields: PilotField[];
  searchableFields: PilotField[];
  fields: Record<FieldId, Field>;
  fieldGroups: componentTypes.Filters.Group[];
  defaultFilters: componentTypes.Filters.Filter[];
};

const addGroupId = (section: PilotFieldSection) => ({
  ...section,
  id: section.title,
});

export const toEviFieldType = (type: DataFieldType) => {
  switch (type) {
    case DataFieldType.ARRAY_MULTIPLE:
      return 'enum_set';
    case DataFieldType.ARRAY_SINGLE:
      return 'enum';
    case DataFieldType.ATTACHMENT:
      return 'file';
    case DataFieldType.BOOLEAN:
      return 'boolean';
    case DataFieldType.CLAUSE:
      return 'clause';
    case DataFieldType.DATE:
      return 'date';
    case DataFieldType.NUMBER:
      return 'number';
    case DataFieldType.STRING:
    case DataFieldType.TEXT_AREA:
      return 'text';
    case DataFieldType.AGE:
      return 'age';
  }
};

const SearchableFieldToEviField = (pilotField: PilotField): Field => ({
  id: String(pilotField.id),
  type: toEviFieldType(pilotField.type),
  label: pilotField.label,
  settings: {
    options: [],
  },
});

const mapSectionsToNormalizedFields = (sections: PilotFieldSection[] = []) => {
  const fields = sections
    .map(addGroupId)
    .flatMap((section) => section.fields)
    .map(SearchableFieldToEviField);

  return keyBy(fields, 'id');
};

const sectionToGroup = (
  section: PilotFieldSection,
): componentTypes.Filters.Group => ({
  id: section.title,
  label: section.title,
  fieldIds: section.fields.map((f) => String(f.id)),
});

const mapSectionsToNormalizedGroups = (sections: PilotFieldSection[] = []) =>
  sections.map(sectionToGroup);

const isPinned = (field: PilotField): boolean => !!field.is_pinned;
const toDefaultFilter = (field: PilotField): Filter => ({
  id: String(field.id),
  fieldId: String(field.id),
  operatorId: null,
  values: [],
});
const getDefaultFilters = (sections: PilotFieldSection[] = []) => {
  const defaultFilters = sections
    .flatMap((section) => section.fields)
    .filter(isPinned)
    .map(toDefaultFilter);

  return defaultFilters;
};

const pilotFieldFactory = ({
  id,
  type,
  name,
  label,
}: {
  id: SearchableFieldId;
  type: DataFieldType;
  name: string;
  label: string;
}): PilotField => {
  return {
    id,
    type,
    name,
    label,
    chained_fields: [],
    is_pinned: false,
  };
};

const DocumentGroupField = pilotFieldFactory({
  id: 'document_group_id' as SearchableFields,
  type: 'ARRAY_MULTIPLE' as DataFieldType,
  name: 'Document Group',
  label: 'Document Group',
});

const FolderField = pilotFieldFactory({
  id: 'folder_id' as SearchableFields,
  type: 'ARRAY_MULTIPLE' as DataFieldType,
  name: 'Folder',
  label: 'Folder',
});

const DEFAULT_MORE_FILTERS = {
  document_group_id: SearchableFieldToEviField(DocumentGroupField),
  folder: {
    label: 'Folders',
    id: 'folder',
    type: 'folder',
    settings: {},
  },
  clause: {
    label: 'Clause Type',
    id: 'clause',
    type: 'clause',
    settings: {},
  },
  filename_content: {
    label: 'Text Search: Filename and Content',
    id: 'filename_content',
    type: 'text_search',
    settings: {},
  },
  filename: {
    label: 'Text Search: Filename',
    id: 'filename',
    type: 'text_search',
    settings: {},
  },
  content: {
    label: 'Text Search: Content',
    id: 'content',
    type: 'text_search',
    settings: {},
  },
};

export const MORE_FILTERS_SECTION_ID = 'More filters';

export const mapFolderFilterIdToFieldId = (id: string) =>
  id === 'folder' ? SearchableFields.Folder : id;

const getMoreFiltersSection = (moreFiltersFieldIds: FieldId[]) => ({
  id: MORE_FILTERS_SECTION_ID,
  label: capitalizeWords(MORE_FILTERS_SECTION_ID),
  fieldIds: moreFiltersFieldIds,
});

export const DEFAULT_MORE_FILTER_FIELD_IDS = Object.keys(DEFAULT_MORE_FILTERS);

export const transformFieldSections = (
  sectionsResponse: AlertSectionsResponse,
  moreFiltersFieldIds: FieldId[] = DEFAULT_MORE_FILTER_FIELD_IDS,
) => {
  const fields = mapSectionsToNormalizedFields(sectionsResponse.sections);
  const fieldsMap = Object.assign(
    fields,
    pick(DEFAULT_MORE_FILTERS, moreFiltersFieldIds),
  );

  return fieldsMap;
};

export const getFilterSections = async (
  moreFiltersFieldIds: FieldId[] = DEFAULT_MORE_FILTER_FIELD_IDS,
): Promise<FilterSectionsResponse> => {
  const sectionsResponse = await getAlertSections();
  const dataFields = sectionsResponse.sections.flatMap(
    (section) => section.fields,
  );
  const searchableFields = sectionsResponse.sections.flatMap(
    (section) => section.fields,
  );
  searchableFields.push(DocumentGroupField);
  searchableFields.push(FolderField);

  const fields = mapSectionsToNormalizedFields(sectionsResponse.sections);

  const fieldGroups = mapSectionsToNormalizedGroups(sectionsResponse.sections);
  const defaultFilters = getDefaultFilters(sectionsResponse.sections);
  const fieldsMap = Object.assign(
    fields,
    pick(DEFAULT_MORE_FILTERS, moreFiltersFieldIds),
  );
  const groupset = fieldGroups.concat(
    getMoreFiltersSection(moreFiltersFieldIds),
  );

  return {
    dataFields,
    searchableFields, //superset of dataFields
    fields: fieldsMap,
    fieldGroups: groupset,
    defaultFilters,
  };
};
