import React from 'react';

import { Box, Chips, DateText, Text } from '~/eds';

import { INFO_SPACING, INFO_WIDTH } from '../../../constants';
import { FieldValue } from '../../types';

interface Props {
  fieldValue: FieldValue;
}

export const FieldValueDisplay = ({ fieldValue }: Props) => {
  const { type, value } = fieldValue;

  if (value === null) {
    return <Text>No value found</Text>;
  }

  let display;
  switch (type) {
    case 'classification':
      display = (
        <Box minW={INFO_WIDTH} py={INFO_SPACING}>
          <Chips
            limit={3}
            direction="column"
            chips={(value as string[]).map((v) => ({ text: v }))}
          />
        </Box>
      );
      break;
    case 'datetime':
      display = <DateText date={new Date((value ?? '') as string)} />;
      break;
    case 'bool':
    case 'int':
    case 'float':
    case 'string':
    case 'text_area':
    default:
      display = <Text color="unset">{value}</Text>;
  }

  return display;
};
