import React from 'react';

import { TicketActivityType, TicketStatusType } from '~/enums';
import { Box, Icon } from '~/ui';

function ActivityLogEventIcon({ action, data }) {
  let icon;
  switch (action) {
    case TicketActivityType.DeleteVersion:
      icon = 'trash';
      break;
    case TicketActivityType.DocumentSigned:
      icon = 'signature';
      break;
    case TicketActivityType.DocumentSave:
      icon =
        data.comment === TicketActivityType.ConvertedDocument
          ? 'arrowRight'
          : 'checklist';
      break;
    case TicketActivityType.CreateTicket:
    case TicketActivityType.VersionBumpForEditing:
      icon = 'documentOutline';
      break;
    case TicketActivityType.IntakeFormEdit:
      icon = 'field';
      break;
    case TicketActivityType.JudgmentResultStatusUpdate:
      const { status } = data;

      if (status === TicketStatusType.Approved) {
        icon = 'checkmark';
      } else if (status === TicketStatusType.Rejected) {
        icon = 'close';
      } else {
        icon = 'checklist';
      }
      break;
    case TicketActivityType.JudgmentResultReassignment:
      icon = 'sync';
      break;
    case TicketActivityType.SENT_FOR_SIGNATURES:
      icon = 'email';
      break;
    case TicketActivityType.NextStage:
      icon = 'arrowRight';
      break;
    case TicketActivityType.PreviousStage:
      icon = 'arrowLeft';
      break;
    case TicketActivityType.StageChangeArchive:
    case TicketActivityType.StageChangeArchiveUser:
      icon = 'folder';
      break;
    case TicketActivityType.DownloadDocument:
      icon = 'download';
      break;
    case TicketActivityType.DocumentUpload:
    case TicketActivityType.SignatureUpload:
      icon = 'upload';
      break;
    case TicketActivityType.ShareDocument:
      icon = 'move';
      break;
    case TicketActivityType.CancelTicket:
    case TicketActivityType.InboundAttachmentRejected:
    case TicketActivityType.DeclineEnvelope:
      icon = 'close';
      break;
    case TicketActivityType.InboundAttachmentAccepted:
    default:
      icon = 'checklist';
  }

  return (
    <Box
      sx={{ bg: 'yellow-500', borderRadius: 'm', flexShrink: 0 }}
      px={2}
      py={2}
    >
      <Icon icon={icon} size="m" color="black-alpha-50" />
    </Box>
  );
}

export default ActivityLogEventIcon;
