import PT from 'prop-types';
import React, { useState } from 'react';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';

import { Box, Link, Text } from '~/ui';
import { validateEmail } from '~/utils/strings';

import customStyles from './EmailSelect.Styles';

const MultiValue = (props) => {
  const {
    isInDisplayAllChipsMode,
    setIsInDisplayAllChipsMode,
    maxVisibleChipsCount,
  } = props.selectProps.customMultivalueProps;
  const currentIndex = props.selectProps.value.findIndex(
    (o) => o.value === props.data.value,
  );

  const shouldChipBeVisible = currentIndex < maxVisibleChipsCount;
  const isSeeAllChip = currentIndex === maxVisibleChipsCount;

  if (isInDisplayAllChipsMode || shouldChipBeVisible) {
    return (
      <components.MultiValue {...props}>
        {props.data.chipLabel ? props.data.chipLabel : props.data.label}
      </components.MultiValue>
    );
  } else if (isSeeAllChip) {
    const hiddenChipsCount =
      props.selectProps.value.length - maxVisibleChipsCount;
    return (
      <Link onClick={() => setIsInDisplayAllChipsMode(true)}>
        See All {hiddenChipsCount > 1 ? hiddenChipsCount : null}
      </Link>
    );
  } else {
    return null;
  }
};

const Option = (props) => {
  const addBoldToSearchText = (content, query) => {
    if (!query) return content;

    let splitedArray = content.split(new RegExp(`(${query})`, 'i'));

    return splitedArray.map((element, index) => {
      return index % 2 === 1 ? <b key={index}>{element}</b> : element;
    });
  };

  return (
    <components.Option {...props}>
      {!props.data.__isNew__
        ? addBoldToSearchText(props.data.label, props.selectProps.inputValue)
        : props.data.label}
    </components.Option>
  );
};

function EmailSelect({
  onChange,
  values,
  options,
  inputId,
  onBlurResetsInput = false,
  hasSeeAllChip = true,
  maxVisibleChipsCount = 10,
  errorMessage = '',
  name,
}) {
  const [inputValue, setInputValue] = useState('');
  const [isInDisplayAllChipsMode, setIsInDisplayAllChipsMode] = useState(
    !hasSeeAllChip,
  );

  const handleInputChange = (inputValue, action) => {
    if (
      onBlurResetsInput ||
      (action.action !== 'input-blur' && action.action !== 'menu-close')
    ) {
      setInputValue(inputValue);
    }
  };

  const onCreateOption = (newItem) => {
    const isNewItemValid = validateEmail(newItem);
    onChange([
      ...values,
      {
        label: newItem,
        value: newItem,
        isAddedOption: true,
        isValid: isNewItemValid,
      },
    ]);
  };

  return (
    <Box
      sx={{ flexShrink: 0, width: '520px' }}
      role="combobox"
      aria-label={name}
    >
      <Creatable
        styles={customStyles}
        customMultivalueProps={{
          isInDisplayAllChipsMode,
          setIsInDisplayAllChipsMode,
          maxVisibleChipsCount,
        }}
        components={{ MultiValue, Option }}
        inputId={inputId}
        isMulti
        options={options}
        onChange={onChange}
        value={values}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onCreateOption={onCreateOption}
        hasError={errorMessage}
      />
      {errorMessage && (
        <Text mt={2} color="red-600" variant="2xs-dense">
          {errorMessage}
        </Text>
      )}
    </Box>
  );
}

EmailSelect.propTypes = {
  /** Function to handle when new file type is added */
  onChange: PT.func,
  /** Array of currently selected values */
  values: PT.arrayOf(PT.any),
  /** inputId of react-select's internal input element.
   * Can be used to get the element when wirting tests
   */
  inputId: PT.string,
  /** react-select's options list */
  options: PT.arrayOf(
    PT.shape({
      value: PT.string.isRequired,
      label: PT.string.isRequired,
      chipLabel: PT.string,
    }),
  ),
  /** flag to Reset input text on Blur */
  onBlurResetsInput: PT.bool,
  /** number to set the maximum number of Chips displayed before
   * adding 'See all' chip.
   */
  maxVisibleChipsCount: PT.number,
  /** flag to enable a Chip 'See all' when maxVisibleChipsCount is exceeded by the
   * number of selected values.
   */
  hasSeeAllChip: PT.bool,
  /** Optional name for accesibility */
  name: PT.string,
};

export default EmailSelect;
