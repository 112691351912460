import { createSelector } from '@reduxjs/toolkit';

import { name } from '../slices/myDeletedItems';
import { DocumentRestoreSlice } from '../slices/restorationHub';

const selectSlice = (rootState: any): DocumentRestoreSlice => rootState[name];

export const selectColumnOrder = createSelector(
  selectSlice,
  (state) => state.columnOrder,
);
