import { noop } from 'lodash';
import PT from 'prop-types';
import React from 'react';

import { BULK_RESTORE_LIMIT } from '~/constants/max_lengths';
import { Box } from '~/eds';

import Alert from '../Alert';
import FolderTree, { folderTreePropType } from '../FolderTree';
import Modal from '../Modal';

function FolderModal({
  disabled,
  folderTreeProps,
  title,
  visible,
  onCancel,
  onHide,
  onSelectFolder,
  modalWidth = 'l',
  count,
}) {
  const actionButton = {
    disabled,
    errorHandler: noop,
    text: 'Select Folder',
    promise: async () => onSelectFolder(),
  };

  const renderLimitWarning = () => {
    if (count > BULK_RESTORE_LIMIT) {
      return (
        <Box mb={5}>
          <Alert enableIcon variant="warning">
            Only {BULK_RESTORE_LIMIT} documents can be restored at a time.
            Please select fewer documents to complete this action.
          </Alert>
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <Modal
      actionButton={actionButton}
      disableAutoHide={true}
      disableCancelButton={true}
      title={title}
      visible={visible}
      width={modalWidth}
      onCancel={onCancel}
      onHide={onHide}
    >
      {renderLimitWarning()}
      <FolderTree {...folderTreeProps} />
    </Modal>
  );
}

FolderModal.propTypes = {
  /** Disables selection of folder */
  disabled: PT.bool,
  /** FolderTree props */
  folderTreeProps: PT.shape(folderTreePropType).isRequired,
  /** Modal title text */
  title: PT.string.isRequired,
  /** Controls modal visibility */
  visible: PT.bool,
  /** Callback function when modal is cancelled (close icon or autohide) */
  onCancel: PT.func,
  /** Callback function to trigger logic for hiding modal */
  onHide: PT.func,
  /** Callback when folder is selected */
  onSelectFolder: PT.func,
  /** Set Modal width. Default to large */
  modalWidth: PT.oneOf(['m', 'l']),
};

export default FolderModal;
