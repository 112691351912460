import React from 'react';

export const Header = () => {
  return (
    <div>
      <div>Search input</div>
      <div>Filters</div>
    </div>
  );
};
