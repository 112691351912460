import { black2, black3, bold } from '../../../assets/shared-styles/general';

const styles = {
  loadingContainer: {
    padding: '24px',
    textAlign: 'center',
  },
  fieldSectionWrapper: {
    padding: '0 16px',
    '&:last-child > div': {
      border: 0,
      transition: 'border 5s ease',
    },
  },
  fieldSection: {
    padding: '16px 8px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  contentContainer: {
    minHeight: '300px',
  },
  addFieldSection: {
    padding: '16px 24px',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
  fieldLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    color: black2,
    fontSize: '15px',
    fontWeight: bold,
    '& svg': {
      cursor: 'pointer',
    },
  },
  fieldDescription: {
    color: black2,
    fontSize: '15px',
  },
  fieldMeta: {
    color: black3,
    fontSize: '13px',
  },
  fieldContainer: {
    width: '100%',
    marginTop: '16px',
  },
  textAreaFieldContainer: {
    width: '50%',
    marginTop: '16px',
  },
  contentDivider: {
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    margin: 0,
  },
  modalMultiEditActions: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '16px',
    backgroundColor: '#fff',
    '& div': {
      marginTop: 0,
    },
  },
  fieldActions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '18px',
    '& > div': {
      marginRight: '32px',
    },
  },
  iconButton: {
    backgroundColor: 'transparent',
    border: 'none',
  },
};

export default styles;
