import React from 'react';
import { useStyles } from 'uinix-ui';

import { Box, Button, Layout } from '../../../components';
import {
  CELL_ALIGNMENTS,
  CELL_PX,
  CELL_PY,
  CELL_STICKY_ZINDEX,
  ROW_DETAILS_CLASSNAME,
} from '../constants';
import { CellType, ColumnAlign } from '../types';
import { renderers } from './renderers';

interface Props {
  align?: ColumnAlign;
  as?: 'td' | 'th';
  cellProps?: any; // TODO [EKP-10675]: strongly type based on props.type
  className?: string; // TODO: research and deprecate API
  disabled?: boolean;
  flex?: 'none' | 'auto';
  isActive?: boolean;
  isPrimary?: boolean;
  stickyLeft?: number;
  stickyRight?: number;
  style?: object; // TODO: research and deprecate API
  type?: CellType;
  width?: string; // override width
  render?: (cell: object, type: CellType) => React.ReactElement; // TODO [EKP-10675]: strongly type based on props.type
  onClick?: () => void;
  onShowDetails?: () => void; // the primary cell supports a special show details action.
}

export const Cell = ({
  align = 'left',
  as = 'td',
  className,
  cellProps,
  disabled,
  flex,
  isActive,
  isPrimary,
  stickyLeft,
  stickyRight,
  style = {},
  type = 'text',
  width,
  render,
  onShowDetails,
  onClick,
  ...rest
}: Props) => {
  const styles = useStyles();

  const enableShowDetails = isPrimary && onShowDetails;

  const renderer = render || renderers[type];
  const justify = enableShowDetails ? 'space-between' : CELL_ALIGNMENTS[align]; // always override justify if details are enabled

  const isSticky =
    Number.isInteger(stickyLeft) || Number.isInteger(stickyRight);

  const stickyStyles = {
    position: 'sticky',
    left: stickyLeft,
    right: stickyRight,
    zIndex: CELL_STICKY_ZINDEX,
  };

  return (
    <Layout
      {...rest}
      align="center"
      borderRight={isPrimary ? 'border.divider' : undefined}
      as={as}
      className={className}
      flex={flex}
      justify={justify}
      px={CELL_PX}
      py={CELL_PY}
      styles={{
        ...style,
        ...(isSticky ? stickyStyles : {}),
        ...componentStyles.cell,
        ...(disabled ? styles.disabledVisual : {}),
        ...(isActive
          ? styles.colors.status({
              status: 'active',
            })
          : {}),
        width,
      }}
      onClick={onClick}
    >
      {renderer(cellProps, type)}
      {enableShowDetails && (
        <Box
          className={ROW_DETAILS_CLASSNAME}
          styles={componentStyles.detailsAction}
        >
          <Button
            icon="chevron-right"
            text="Details"
            variant="tertiary"
            onClick={onShowDetails}
          />
        </Box>
      )}
    </Layout>
  );
};

const componentStyles = {
  cell: {
    background: 'inherit',
    boxShadow: 'inherit',
    display: 'inline-flex',
  },
  detailsAction: {
    visibility: 'hidden',
  },
};
