import { useState } from 'react';
import { generatePath } from 'react-router-dom';

import { PageLayout } from '~/eds';
import { CreditBalance } from '~/features/billing';
import { useClientId } from '~/hooks';
import { api } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';
import { Nullable, Uuid } from '~/types';

import { CreditsLedger } from './CreditsLedger';
import { OneTimeCreditPanel } from './OneTimeCreditPanel';
import { RecurringCreditsPanel } from './RecurringCreditsPanel';

const Page = () => {
  const { location, params } = useRouting();
  const {
    data: creditSummaryData,
    isFetching: isCreditSummaryFetching,
    isError: isCreditSummaryError,
  } = api.endpoints.getCreditSummary.useQueryState(Number(params.clientId));
  // TODO: M1.5 We will only display active balances but M2 will use a separate endpoint.
  // const {
  //   data = { transactions: [], totalCount: 0 },
  //   isError,
  //   isFetching,
  // } = api.endpoints.getCreditLedger.useQuery(clientId);

  const [panelName, setPanelName] = useState<Nullable<string>>(null);
  const [balanceId, setBalanceId] = useState<Nullable<Uuid>>(null);
  const clientId = useClientId();
  const { getSearchParam } = useRouting();
  const pageIndex = Number(getSearchParam('pageIndex') ?? 1);

  const handlePanelClose = () => setPanelName(null);

  const placeholderContent = isCreditSummaryError
    ? {
        message:
          'There was an error loading credit transaction. Refresh the page to try again.',
      }
    : creditSummaryData?.balances === undefined ||
      (creditSummaryData.balances && creditSummaryData.balances.length === 0)
    ? {
        title: 'No Transactions',
        message: 'No credit transactions have not been made in this client.',
      }
    : undefined;

  const setPanel = (panelName: string, id: Nullable<Uuid> = null) => {
    setPanelName(panelName);
    setBalanceId(id);
  };

  const activeBalance = creditSummaryData?.balances?.find(
    (balance) => balance.id === balanceId,
  );

  return (
    <>
      <PageLayout
        actions={[
          {
            text: 'Add One-Time Credits',
            onClick: () => setPanel('one-time-credits'),
          },
          {
            text: 'Manage Recurring Credits',
            onClick: () => setPanel('recurring-credits'),
          },
        ]}
        headerCalloutContent={
          <CreditBalance clientId={Number(params.clientId)} />
        }
        loadingContent={{
          isLoading: isCreditSummaryFetching,
          message: 'Loading transactions...',
        }}
        nav={{
          actions: [
            {
              text: 'Admin Console',
              pathname: generatePath(RoutePathType.AdminConsoleClient, {
                clientId,
              }),
            },
            {
              text: 'Admin',
              pathname: RoutePathType.Admin,
            },
            {
              text:
                (location.state as { clientName: string })?.clientName ??
                'Client',
              pathname: RoutePathType.AdminClientCredits,
            },
          ],
        }}
        placeholderContent={placeholderContent}
        title="View Credits"
      >
        {creditSummaryData?.balances &&
          creditSummaryData.balances.length > 0 && (
            <CreditsLedger
              pageIndex={pageIndex}
              totalCount={creditSummaryData?.balances?.length ?? 0}
              transactions={creditSummaryData?.balances ?? []}
              setPanel={setPanel}
            />
          )}
      </PageLayout>
      {panelName === 'one-time-credits' && (
        <OneTimeCreditPanel
          balance={activeBalance}
          onClose={handlePanelClose}
        />
      )}
      {panelName === 'recurring-credits' && creditSummaryData && (
        <RecurringCreditsPanel
          creditSummary={creditSummaryData}
          onClose={handlePanelClose}
        />
      )}
    </>
  );
};

export default Page;
