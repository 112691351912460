import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from '~/eds';
import { TicketStatusType } from '~/enums';

const { Approved, Rejected, Pending } = TicketStatusType;

function StatusIcon({ color, status }) {
  let icon;
  let tooltip;
  switch (status) {
    case Approved: {
      icon = 'status-complete';
      tooltip = 'Approved';
      break;
    }
    case Rejected:
      icon = 'status-reject';
      tooltip = 'Rejected';
      break;
    case Pending:
    default:
      icon = 'status-pending';
      tooltip = 'Pending';
  }
  return <Icon icon={icon} color={color} tooltip={tooltip} />;
}

StatusIcon.propTypes = {
  status: PropTypes.oneOf([Approved, Rejected, Pending]),
};

export default StatusIcon;
