import { EsignatureType } from '~/enums';
import { everest } from '~/services';

// if esignature does not exist from the server, create an empty esignature without an ID
export async function getEsignatureIntegrations() {
  function createEmptyEsignatureIntegration(type) {
    return {
      id: null,
      config: {},
      error: null,
      isDefault: false,
      shouldUseCompanyAccount: null,
      type,
    };
  }

  const response = await everest.get('/esignatures');

  const esignatureIntegrations = [];
  Object.values(EsignatureType).forEach((esignatureType) => {
    const esignatureIntegration = response.data.find(
      (record) => record.type === esignatureType,
    );
    if (esignatureIntegration) {
      esignatureIntegrations.push(esignatureIntegration);
    } else {
      esignatureIntegrations.push(
        createEmptyEsignatureIntegration(esignatureType),
      );
    }
  });

  return esignatureIntegrations;
}

export async function createEsignatureIntegration(data) {
  return await everest.post('/esignatures', data);
}

export async function deleteEsignatureIntegration(data) {
  return await everest.remove(`/esignatures/${data.id}`);
}

export async function updateEsignatureIntegration(data) {
  return await everest.patch(`/esignatures/${data.id}`, data);
}

export async function getEsignatureIntegrationLoginUrl({
  integrationId,
  integrationUrl,
}) {
  const data = { integrationUrl };
  return await everest.post(`/esignatures/${integrationId}/login`, data);
}

export async function checkEsignatureIntegration() {
  const { hasEsignatureIntegrations } = await everest.get('/esignatures/check');
  return hasEsignatureIntegrations;
}
