import { PageLayout, SearchInput, Table } from '~/eds';

const Page = () => {
  const mockData = [
    { id: 1, party: 'Internal', documentCount: 67 },
    { id: 2, party: 'External', documentCount: 89 },
  ];

  // TODO: remove client_id param, filter by "parties" entity type
  // or better, getEntityInstancesByEntityType
  // const { data, isLoading } = api.endpoints.getEntityInstanceByClient.useQuery({
  // clientId: 173471,
  // });

  const columns = [
    {
      key: 'party',
      cellType: 'link',
      title: 'Party',
      // TODO: update data type
      mapCellProps: (data: any) => ({
        text: data.party,
        pathname: `/parties/${data.party}`,
      }),
    },
    {
      key: 'document-count',
      cellType: 'number',
      title: 'Documents',
      // TODO: update data type
      mapCellProps: (data: any) => ({
        number: data.documentCount,
      }),
    },
  ];

  return (
    <PageLayout
      title="Parties"
      actions={[
        {
          text: 'New Party',
          level: 'primary',
          onClick: () => console.log('click'),
        },
      ]}
    >
      {/* TODO: wrap in Layout and set width */}
      <SearchInput
        name="party"
        value="call api to filter table"
        onChange={() => console.log('type')}
      />
      <Table name="parties" columns={columns} data={mockData} />
    </PageLayout>
  );
};

export default Page;
