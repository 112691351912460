export default `
<svg
  aria-hidden="true"
  aria-label="bookmark"
  width="10"
  height="16"
  viewBox="0 0 10 16"
  fill="none"
>
  <path
    d="M0 1.5C0 0.671875 0.559896 0 1.25 0H8.75C9.4401 0 10 0.671875 10 1.5V15.3094C10 15.6906 9.74219 16 9.42448 16C9.3099 16 9.20052 15.9594 9.10417 15.8844L5 12.6L0.895833 15.8844C0.802083 15.9594 0.690104 16 0.575521 16C0.257812 16 0 15.6906 0 15.3094V1.5ZM1.25 1C1.02083 1 0.833333 1.225 0.833333 1.5V14.7312L4.76823 11.5844C4.90885 11.4719 5.09115 11.4719 5.23177 11.5844L9.16667 14.7312V1.5C9.16667 1.225 8.97917 1 8.75 1H1.25Z"
    fill="currentcolor"
  />
</svg>
`;
