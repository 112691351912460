import { useMemo } from 'react';

import { Actions } from '~/eds';
import { useRouting } from '~/routing';

import { testIsInADocViewerPage } from '../../../ask-anything-button/utils';
import { Message } from '../../../types';

interface Props {
  message: Message;
  onSelectSource?: (message: Message, sourceIndex: number) => void;
}
export const Sources = ({ message, onSelectSource }: Props) => {
  const { location } = useRouting();

  const isDocViewerPage = useMemo(
    () => testIsInADocViewerPage(location.pathname),
    [location.pathname],
  );

  const sources = useMemo(() => {
    return (message.sources ?? []).map((_source, index) => ({
      level: 'secondary' as const,
      text: `${index + 1}`,
      tooltip: !isDocViewerPage
        ? 'Source highlights are accessible when viewing the document version.'
        : `View source ${index + 1}`,
      onClick: () => {
        onSelectSource?.(message, index);
      },
      disabled: !isDocViewerPage,
    }));
  }, [message, isDocViewerPage]);

  if (!message.sources?.length) return null;

  return <Actions wrap actions={sources} size="xs" />;
};
