import { noop } from 'lodash';
import React from 'react';

import EcDatepicker from '~/components/Shared/EcDatepicker';
import {
  FormField,
  Label,
  Layout,
  NumberInput,
  RadioGroup,
  Text,
  TextArea,
  TextInput,
  types,
} from '~/eds';
import { DataFieldType } from '~/enums';
import { DataFieldValue, FieldValueSelect } from '~/features/fields';
import { DocumentInformationField } from '~/features/search';

interface Props<T extends DataFieldType> {
  field: DocumentInformationField;
  onChange: (updatedValue: types.Nullable<DataFieldValue<T>>) => void;
  value: types.Nullable<DataFieldValue<T>>;
}
export const FieldEdit = <T extends DataFieldType>({
  field,
  onChange,
  value,
}: Props<T>) => {
  //TODO: remove when EKP-23791 is implemented.
  if (field.type === DataFieldType.DATE) {
    return (
      <Layout preset="form-fields">
        <Layout direction="column">
          <Label>{field.label}</Label>
          <Text preset="description">Select the value(s) you want to use</Text>
        </Layout>
        <EcDatepicker
          onDateChange={onChange}
          date={value as Date}
          title={field.label}
          placeholder="Select field value"
        />
      </Layout>
    );
  }

  const { input, inputProps } = getInput(field);
  const description = getDescription(field.type);
  return (
    <FormField<DataFieldValue<T>, false>
      label={field.label}
      description={description}
      name={field.label}
      input={input}
      inputProps={inputProps}
      value={value}
      onChange={onChange}
    />
  );
};
const getDescription = (type: DataFieldType) => {
  switch (type) {
    case DataFieldType.ARRAY_MULTIPLE:
    case DataFieldType.ARRAY_SINGLE:
      return 'Select the value(s) you want to use';
    case DataFieldType.ATTACHMENT:
      return 'Select the file you want to use';
    case DataFieldType.BOOLEAN:
      return 'Select the value you want to use';
    case DataFieldType.DATE:
      return 'Select the date you want to use';
    case DataFieldType.NUMBER:
      return 'Enter the number you want to use';
    case DataFieldType.TEXT_AREA:
      return 'Enter the text you want to use';
    case DataFieldType.STRING:
    default:
      return 'Enter the text you want to use';
  }
};
const getInput = (field: DocumentInformationField) => {
  const { type, id } = field;

  let input;
  let inputProps;

  switch (type) {
    case DataFieldType.ARRAY_MULTIPLE:
    case DataFieldType.ARRAY_SINGLE: {
      input = FieldValueSelect;
      inputProps = {
        enableSelectAll: false,
        isMulti: type === DataFieldType.ARRAY_MULTIPLE,
        placeholder: 'Select field value',
        fieldId: id,
        onAddOption: field.allowNewOptions ? noop : undefined,
      };
      break;
    }
    case DataFieldType.ATTACHMENT:
      input = null;
      break;
    case DataFieldType.BOOLEAN:
      input = RadioGroup;
      inputProps = {
        options: [
          {
            label: 'True',
            value: true,
          },
          {
            label: 'False',
            value: false,
          },
        ],
      };
      break;
    // TODO: DataFieldType.CLAUSE to be supported
    case DataFieldType.NUMBER:
      input = NumberInput;
      break;
    case DataFieldType.TEXT_AREA:
      input = TextArea;
      break;
    case DataFieldType.STRING:
    default:
      input = TextInput;
      break;
  }

  return {
    input,
    inputProps,
  };
};
