import React from 'react';

import { IconType } from '../../types';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Text } from '../Text';

interface Item {
  title: string;
  icon?: IconType;
  description?: string;
}

interface ListItemsProps {
  as: 'ol' | 'ul';
  items: Item[];
}

export const ListItems = ({ as, items }: ListItemsProps) => {
  return (
    <Layout as={as} spacing="4" direction="column" pl="0" pr="0">
      {items.map((item, index) => (
        <Layout key={`list-item-${index}`} direction="column">
          <Layout>
            {item.icon && (
              <Box mr="2">
                <Icon color="status.ai" icon={item.icon} />
              </Box>
            )}
            <Text variant="body-medium">{item.title}</Text>
          </Layout>
          <Text>{item.description}</Text>
        </Layout>
      ))}
    </Layout>
  );
};
