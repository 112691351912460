export default `
<svg
  aria-hidden="true"
  aria-label="chevron-down"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.5158 9.68173L12.547 15.4005C12.3595 15.5567 12.172 15.6192 12.0158 15.6192C11.8283 15.6192 11.6408 15.5567 11.4845 15.4317L5.48453 9.68173C5.17203 9.40048 5.17203 8.90048 5.45328 8.61923C5.73453 8.30673 6.23453 8.30673 6.51578 8.58798L12.0158 13.838L17.4845 8.58798C17.7658 8.30673 18.2658 8.30673 18.547 8.61923C18.8283 8.90048 18.8283 9.40048 18.5158 9.68173Z"
    fill="currentcolor"
  />
</svg>
`;
