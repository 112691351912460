import PT from 'prop-types';
import React from 'react';

import FlexLayout from '../FlexLayout';
import Text from '../Text';

const Preview = ({ children }) => {
  return (
    <FlexLayout
      bg="gray-300"
      flex="1"
      flexDirection="column"
      sx={{
        borderRadius: 'm',
        flex: 'auto',
      }}
    >
      <Text
        color="gray-700"
        px="x6"
        py="x4"
        sx={{ borderBottom: 'border' }}
        variant="body2"
      >
        Preview
      </Text>
      <FlexLayout flexDirection="column" alignItems="center" p="x6">
        {children}
      </FlexLayout>
    </FlexLayout>
  );
};

Preview.propTypes = {
  /** Children */
  children: PT.node,
};

export default Preview;
