import React from 'react';

import {
  AsyncValue,
  ClauseValue,
  DateValue,
  EnumValue,
  Field,
  Filter,
  NumberValue,
  onFilterViewChange,
  TextValue,
  Value,
} from '../types';
import { BooleanValues } from './BooleanValues';
import { ClauseValues } from './ClauseValues';
import { DateValues } from './DateValues';
import { EnumSetValues } from './EnumSetValues';
import { EnumValues } from './EnumValues';
import { FileValues } from './FileValues';
import { NumberValues } from './NumberValues';
import { TextValues } from './TextValues';

interface Props {
  field: Field;
  filter: Filter;
  onChange: (
    updatedValues: Value[],
    updatedAsyncValue?: AsyncValue<Value>,
  ) => void;
  onViewChange?: onFilterViewChange;
}

export const Values = ({ field, filter, onChange, onViewChange }: Props) => {
  switch (field.type) {
    case 'boolean':
      return <BooleanValues />;
    case 'clause':
      return (
        <ClauseValues
          field={field as Field<'clause'>}
          filter={filter as Filter<ClauseValue>}
          onChange={onChange}
        />
      );
    case 'date':
      return (
        <DateValues
          field={field as Field<'date'>}
          filter={filter as Filter<DateValue>}
          onChange={onChange}
        />
      );
    case 'enum': {
      return (
        <EnumValues
          field={field as Field<'enum'>}
          filter={filter as Filter<EnumValue>}
          onChange={onChange}
        />
      );
    }
    case 'enum_set':
    case 'folder': {
      return (
        <EnumSetValues
          field={field as Field<'enum_set'>}
          filter={filter as Filter<EnumValue>}
          onChange={onChange}
          onViewChange={onViewChange}
        />
      );
    }
    case 'file':
      return <FileValues />;
    case 'number':
      return (
        <NumberValues
          filter={filter as Filter<NumberValue>}
          onChange={onChange}
        />
      );
    case 'text':
    case 'text_search':
      return (
        <TextValues
          field={field}
          filter={filter as Filter<TextValue>}
          onChange={onChange}
        />
      );
    default:
      return null;
  }
};
