import React, { memo } from 'react';

import { User } from '../../types';
import { getUserName } from '../../utils';
import { Text, TruncateText } from '../Text';
import { Tooltip } from '../Tooltip';
import { UserNameOptions } from './types';

interface Props {
  user?: User;
  mode?: 'info' | 'name' | 'full';
  options?: UserNameOptions;
  placeholder?: string;
}

export const UserName = memo(
  ({
    mode = 'info',
    options = {},
    user,
    placeholder = 'Unknown User',
  }: Props) => {
    const {
      isCurrentUser = false,
      isDeleted = false,
      shouldAbbreviateLastName,
      shouldTruncate = true,
    } = options;

    const TextComponent = shouldTruncate ? TruncateText : Text;
    return (
      <Tooltip tooltip={user?.email}>
        <TextComponent
          whiteSpace="nowrap"
          variant={mode !== 'full' ? 'body-bold' : 'body'}
        >
          {user
            ? getUserName(user, {
                shouldAbbreviateLastName,
                isDeleted,
                isCurrentUser,
              })
            : placeholder}
        </TextComponent>
      </Tooltip>
    );
  },
);
