import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
  builderSetShouldEditParties,
  workflowSetPartiesSigners,
} from '~/actions';
import { Button } from '~/eds';
import { WorkflowIntakeFormType, WorkflowPartyType } from '~/enums';
import { getPartiesWithSigners } from '~/reducers/workflow';
import { FlexLayout, Text } from '~/ui';
import { coerceName, getPartyName } from '~/utils/workflow';

import PartySignerCard from './PartySignerCard';

function getMaxSignerIndex(parties) {
  return Math.max(
    ...parties
      .map((party) => party.signers.map((signer) => signer.index))
      .flat(),
  );
}

function CreatePartySigner({
  // connected
  parties,
  builderSetShouldEditParties,
  workflowSetPartiesSigners,
}) {
  const [internalParties, setInternalParties] = useState(parties.internal);
  const [counterparties, setCounterparties] = useState(parties.counter);

  useEffect(() => {
    setInternalParties(parties.internal);
    setCounterparties(parties.counter);
  }, [parties]);

  if (!parties) {
    return null;
  }

  function handleAddParty({ type }) {
    const parties =
      type === WorkflowPartyType.Counterparty
        ? counterparties
        : internalParties;
    const setParties =
      type === WorkflowPartyType.Counterparty
        ? setCounterparties
        : setInternalParties;

    const maxIndex = Math.max(...parties.map((party) => party.index));
    const newPartyIndex = Math.max(-1, maxIndex) + 1;
    const newParty = {
      id: uuid(),
      index: newPartyIndex,
      name: getPartyName(type, newPartyIndex),
      signers: [],
      type,
    };
    const updatedParties = [...parties, newParty];
    setParties(updatedParties);
  }

  function handleAddSigner({ partyId, type }) {
    const parties =
      type === WorkflowPartyType.Counterparty
        ? counterparties
        : internalParties;
    const setParties =
      type === WorkflowPartyType.Counterparty
        ? setCounterparties
        : setInternalParties;

    const signerMaxIndex = Math.max(
      getMaxSignerIndex(internalParties),
      getMaxSignerIndex(counterparties),
    );
    const newSignerIndex = Math.max(signerMaxIndex, -1) + 1;
    const newSigner = {
      id: uuid(),
      index: newSignerIndex,
      name: coerceName(null, 'Signer', newSignerIndex),
      partyId,
    };
    const updatedParties = parties.map((party) => {
      if (party.id === partyId) {
        return {
          ...party,
          signers: [...party.signers, newSigner],
        };
      } else {
        return party;
      }
    });
    setParties(updatedParties);
  }

  function handleRemoveParty({ partyId, type }) {
    const parties =
      type === WorkflowPartyType.Counterparty
        ? counterparties
        : internalParties;
    const setParties =
      type === WorkflowPartyType.Counterparty
        ? setCounterparties
        : setInternalParties;

    const updatedParties = parties.filter((party) => party.id !== partyId);
    setParties(updatedParties);
  }

  function handleRemoveSigner({ signerId, type }) {
    const parties =
      type === WorkflowPartyType.Counterparty
        ? counterparties
        : internalParties;
    const setParties =
      type === WorkflowPartyType.Counterparty
        ? setCounterparties
        : setInternalParties;

    const updatedParties = parties.map((party) => ({
      ...party,
      signers: party.signers.filter((signer) => signer.id !== signerId),
    }));
    setParties(updatedParties);
  }

  return (
    <FlexLayout alignItems="center" bg="white" flexDirection="column" pb={12}>
      <FlexLayout justifyContent="center">
        <Text variant="xl-dense-bold" sx={{ justifySelf: 'center' }}>
          Edit Parties and Signers
        </Text>
      </FlexLayout>
      <FlexLayout mt={8} space={2}>
        <PartySignerCard
          parties={internalParties}
          title="Internal parties"
          type={WorkflowIntakeFormType.Company}
          onAddParty={handleAddParty}
          onAddSigner={handleAddSigner}
          onRemoveParty={handleRemoveParty}
          onRemoveSigner={handleRemoveSigner}
        />
        <PartySignerCard
          parties={counterparties}
          title="Counterparties"
          type={WorkflowIntakeFormType.Counterparty}
          onAddParty={handleAddParty}
          onAddSigner={handleAddSigner}
          onRemoveParty={handleRemoveParty}
          onRemoveSigner={handleRemoveSigner}
        />
      </FlexLayout>
      <FlexLayout
        alignItems="center"
        justifyContent="center"
        sx={{
          bg: 'white',
          bottom: 0,
          boxShadow: 'page-footer-top',
          height: 'modal-footer-height',
          left: 0,
          position: 'fixed',
          right: 0,
        }}
      >
        <FlexLayout justifyContent="flex-end" space={4} sx={{ width: '808px' }}>
          <Button
            text="Cancel"
            variant="ghost"
            onClick={() => builderSetShouldEditParties(false)}
          />
          <Button
            text="Save"
            variant="primary"
            onClick={() => {
              workflowSetPartiesSigners([
                ...internalParties,
                ...counterparties,
              ]);
              builderSetShouldEditParties(false);
            }}
          />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
}

function mapStateToProps({ workflow }) {
  return {
    parties: getPartiesWithSigners(workflow),
  };
}

export default connect(mapStateToProps, {
  builderSetShouldEditParties,
  workflowSetPartiesSigners,
})(CreatePartySigner);
