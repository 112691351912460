import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { showToast } from '~/components/Shared/EcToast';
import { CommentPostType } from '~/enums';
import { withCurrentUser } from '~/hocs';
import * as toastTypes from '~/types/toast.types';
import { FlexLayout, Link } from '~/ui';

import CommentContent from './CommentContent';
import CommentHeader from './CommentHeader';
import CommentPost from './CommentPost';

function defaultDataRenderer() {
  return null;
}

function Comment({
  comment: initialComment,
  commentDataRenderer = defaultDataRenderer,
  isLocked = false,
  promises,
  handleGetParticipants,
  // injected,
  currentUser,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [comment, setComment] = useState(initialComment);
  const [updatedContent, setUpdatedContent] = useState(comment.content);

  const {
    content,
    createdDate,
    data,
    modifiedDate,
    userId,
    customRenderer,
  } = comment;

  if (customRenderer) {
    return customRenderer(comment);
  }

  function handleDeleteComment() {
    setIsDeleting(true);
    promises
      .deleteComment(comment)
      .then(() => handleGetParticipants())
      .catch(() =>
        showToast(
          toastTypes.ERROR,
          'Unable to delete comment, please try again.',
        ),
      )
      .finally(() => setIsDeleting(false));
  }

  function handleUpdateComment(updatedContent) {
    setIsUpdating(true);
    promises
      .updateComment({ ...comment, content: updatedContent })
      .then(setComment)
      .catch(() =>
        showToast(
          toastTypes.ERROR,
          'Unable to edit comment, please try again.',
        ),
      )
      .finally(() => {
        setIsEditing(false);
        setIsUpdating(false);
      });
  }

  function handleCancelUpdateComment() {
    setUpdatedContent(content);
    setIsEditing(false);
  }

  const canEditComment = !isLocked && !isEditing && userId === currentUser.id;

  const commentStatus = createdDate === modifiedDate ? '' : 'edited';

  const deletingStyle = isDeleting
    ? {
        filter: 'grayscale(100%)',
        opacity: 0.3,
        pointerEvents: 'none',
        userSelect: 'none',
      }
    : {};

  return (
    <FlexLayout flexDirection="column" space={2} sx={{ ...deletingStyle }}>
      <CommentHeader
        datetime={modifiedDate}
        metadata={commentDataRenderer(data)}
        status={commentStatus}
        userId={userId}
      />
      {isEditing ? (
        <CommentPost
          autoFocus={true}
          content={updatedContent}
          isLoading={isUpdating}
          postType={CommentPostType.Edit}
          onCancel={handleCancelUpdateComment}
          onSubmit={handleUpdateComment}
          onUpdateContent={setUpdatedContent}
        />
      ) : (
        <CommentContent content={content} />
      )}
      {canEditComment && !isEditing && (
        <FlexLayout space={4}>
          <Link variant="2xs-dense" onClick={() => setIsEditing(true)}>
            {isUpdating ? 'Editing…' : 'Edit'}
          </Link>
          <Link variant="2xs-dense" onClick={handleDeleteComment}>
            {isDeleting ? 'Deleting…' : 'Delete'}
          </Link>
        </FlexLayout>
      )}
    </FlexLayout>
  );
}

export const commentPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  content: PropTypes.string,
  data: PropTypes.object,
  modifiedDate: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
});

Comment.propTypes = {
  comment: commentPropTypes.isRequired,
  isLocked: PropTypes.bool,
  promises: PropTypes.shape({
    /** (comment: Comment) => void */
    deleteComment: PropTypes.func.isRequired,
    /** (comment: Comment) => void */
    updateComment: PropTypes.func.isRequired,
  }).isRequired,
  commentDataRenderer: PropTypes.func,
};

export default withCurrentUser(Comment);
