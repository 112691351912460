import { useMemo } from 'react';

export const useGetChatBotStatusMessage = ({
  isProcessing,
  isLatestVersion,
  isEdited,
}: {
  isProcessing: boolean;
  isLatestVersion: boolean;
  isEdited?: boolean;
}):
  | {
      message: string;
      status: 'danger' | 'warning' | 'info' | 'success' | 'active';
      title: string;
      autoDismiss?: boolean;
    }
  | undefined => {
  const statusMessage = useMemo(() => {
    if (!isLatestVersion) {
      return {
        message:
          'Ask AI only supports responses for the latest document version in Workflow.',
        title: 'Select the latest document version for Workflow',
        status: 'warning' as const,
      };
    }
    if (isProcessing) {
      return {
        message:
          'Please wait until the process is complete or try another document in the meantime.',
        title: 'Document is being processed by Ask AI',
        status: 'warning' as const,
      };
    }
    if (isEdited) {
      return {
        message:
          'This document has been edited. Save a new version to include the edits in your answers.',
        title: 'Document has been edited',
        status: 'active' as const,
      };
    }
  }, [isLatestVersion, isProcessing, isEdited]);

  return statusMessage;
};
