import { Layout, TextInput } from '~/eds';

interface SearchAndFiltersProps {
  search: string;
  onSearch: (updatedValue: string) => void;
}

export const SearchAndFilters = ({
  search,
  onSearch,
}: SearchAndFiltersProps) => {
  return (
    <Layout>
      <TextInput
        name="search question input"
        placeholder="Search"
        value={search}
        onChange={(value) => onSearch(value || '')}
      />
    </Layout>
  );
};
