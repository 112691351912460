import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom-v5-compat';
import SortableTree from 'react-sortable-tree';

import ActionsMenu from '~/components/Shared/ActionsMenu';

import { black4 } from '../../../assets/shared-styles/general';
import {
  MODAL_DELETE,
  MODAL_DOCUMENT_GROUP_RELATION,
} from '../../../types/modal.types';
import { SUCCESS, WARNING } from '../../../types/toast.types';
import {
  deleteDocumentFromGroup,
  editDocumentRelationshipGroup,
} from '../../DocumentGroupsPage/DocumentGroupsPage.data';
import { documentTypeIcon } from '../../DocumentsPage/Document.utils';
import EcModal from '../../Shared/EcModal';
import { showToast } from '../../Shared/EcToast';
import DocumentFilledIcon from '../../Shared/Icons/DocumentFilledIcon';
import LinkIcon from '../../Shared/Icons/LinkIcon';
import EcMultipleLocationsLink from '../EcMultipleLocationsLink';
import styles from './DocumentTree.styles';

const maxDepth = 5;

const customSearchMethod = ({ node, searchQuery }) =>
  searchQuery &&
  node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

class DocumentTree extends Component {
  constructor(props) {
    super(props);

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.onDocumentRemoveFromGroup = this.onDocumentRemoveFromGroup.bind(this);

    this.state = {
      primaryModal: null,
      searchString: this.props.searchString,
      treeData: this.props.treeData,
      preparing: true,
    };
  }

  componentDidMount() {
    this.setUpWidth();
  }

  componentDidUpdate() {
    this.setUpWidth();
  }

  setUpWidth() {
    const elements = Array.from(
      document.getElementsByClassName('rst__nodeContent'),
    );

    elements.forEach((e) => {
      e.style = `left: ${e.style.left} ; width: calc(95% - ${e.style.left})`;
    });

    setTimeout(() => {
      this.setState({ preparing: false });
    }, 200);
  }

  handleTreeOnChange = (treeData) => {
    this.setState({ treeData });
  };

  handleSearchOnChange = (searchString) => {
    this.setState({ searchString });
  };

  handleMasterDocumentChange(node, isMaster) {
    const { reloadDocumentTreeData } = this.props;
    editDocumentRelationshipGroup(node.docGroupId, {
      relationship: 'linked',
      isMaster,
    })
      .then(() => {
        showToast(
          SUCCESS,
          `The document has been successfully ${
            isMaster ? 'marked' : 'unmarked'
          } as Master.`,
        );
        reloadDocumentTreeData();
      })
      .catch(() =>
        showToast(
          WARNING,
          `An error occurred while ${
            isMaster ? 'marking' : 'unmarking'
          } the document in this group.`,
        ),
      );
  }

  onDocumentRemoveFromGroup() {
    const { reloadDocumentTreeData } = this.props;
    const { selectedItem } = this.state;
    deleteDocumentFromGroup(selectedItem.node.docGroupId)
      .then(() => {
        showToast(SUCCESS, 'Document has been removed from this group.');
        reloadDocumentTreeData();
      })
      .catch(() =>
        showToast(
          WARNING,
          'An error occurred while removing the document from this group.',
        ),
      );
  }

  handleShowModal(modal, selectedItem = null) {
    this.setState({
      primaryModal: modal,
      selectedItem,
    });
  }

  handleHideModal() {
    this.setState({ primaryModal: null, selectedItem: null });
  }

  getMenuItems = (item) => {
    const items = [];

    items.push(
      {
        content: item.node.isMaster ? 'Unmark as "Master"' : 'Mark as "Master"',
        onClick: () =>
          this.handleMasterDocumentChange(
            item.node,
            item.node.isMaster ? false : true,
          ),
      },
      {
        content: 'Remove from Group',
        onClick: () => this.handleShowModal(MODAL_DELETE, item),
      },
    );

    return items;
  };

  renderExtraOptions(item) {
    const { classes } = this.props;

    return (
      <div className={classes.actionsWrapper}>
        <ActionsMenu
          id={`document_tree_item_actions_menu?item=${item.node.documentId}`}
          items={this.getMenuItems(item)}
          align="end"
          smallIcon
          title="edit document group document menu"
        />
      </div>
    );
  }

  renderRemoveDocumentFromGroupModalText() {
    return (
      <Fragment>
        Are you sure you want to remove
        <span> {this.state.selectedItem.node.title}</span> from this group? You
        won’t be able to undo this action.
      </Fragment>
    );
  }

  renderDeleteModal() {
    const modalText = this.renderRemoveDocumentFromGroupModalText();

    return (
      <EcModal
        modalType={MODAL_DELETE}
        width="560px"
        title="Remove Document from Group?"
        text={modalText}
        confirmButtonText="Delete"
        deleteItem={this.onDocumentRemoveFromGroup}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderDocumentTitle(rowInfo) {
    const { classes } = this.props;
    return rowInfo.node.handlers.length ? (
      rowInfo.node.handlers.length > 1 ? (
        <EcMultipleLocationsLink
          wrapperStyles={classes.groupDocMultipleLocation}
          documentName={rowInfo.node.title}
          documentHandlers={rowInfo.node.handlers}
        >
          {documentTypeIcon(rowInfo.node.fileType)}
          <span className={classes.groupDocumentName}>
            {rowInfo.node.title}
          </span>
        </EcMultipleLocationsLink>
      ) : (
        <Link to={`/document/${rowInfo.node.handlerIds[0]}`}>
          {documentTypeIcon(rowInfo.node.fileType)}
          <span className={classes.groupDocumentName}>
            {rowInfo.node.title}
          </span>
        </Link>
      )
    ) : (
      <div className={classes.groupDocMultipleLocation}>
        {documentTypeIcon(rowInfo.node.fileType)}
        <span className={classes.groupDocumentName}>{rowInfo.node.title}</span>
      </div>
    );
  }

  render() {
    const {
      classes,
      handleShowModal,
      searchString,
      treeId,
      allowEdit,
    } = this.props;
    const { preparing, treeData, primaryModal } = this.state;

    if (preparing) {
      return null;
    }
    return (
      <div>
        <div className={classes.treeWrapper} id={treeId}>
          {treeData ? (
            <SortableTree
              treeData={treeData}
              onChange={this.handleTreeOnChange}
              canDrag={false}
              canDrop={() => false}
              maxDepth={maxDepth}
              searchMethod={customSearchMethod}
              searchQuery={searchString}
              isVirtualized={false}
              generateNodeProps={(rowInfo) => ({
                title: rowInfo.node.isVisible ? (
                  <span className={classes.groupDocumentItem}>
                    <span className={classes.groupDocumentDot} />
                    {this.renderDocumentTitle(rowInfo)}
                    {rowInfo.node.isMaster ? (
                      <span className={classes.masterTag}>Master</span>
                    ) : null}
                  </span>
                ) : (
                  <span className={classes.groupDocumentItem}>
                    <span className={classes.groupDocumentDot} />
                    <span>
                      <DocumentFilledIcon color={black4} size="20" />
                    </span>
                    <span className={classes.groupDocumentNameInvisible}>
                      You don’t have the necessary permissions to view this
                      document.
                    </span>
                  </span>
                ),
                buttons: [
                  allowEdit && rowInfo.node.isVisible ? (
                    <Fragment>
                      <button
                        title="Edit document relationship"
                        className={classes.treeIcon}
                        onClick={() =>
                          handleShowModal(
                            MODAL_DOCUMENT_GROUP_RELATION,
                            rowInfo,
                          )
                        }
                      >
                        <LinkIcon />
                      </button>
                      <span className={classes.treeIcon}>
                        {this.renderExtraOptions(rowInfo)}
                      </span>
                    </Fragment>
                  ) : null,
                ],
              })}
            />
          ) : null}
          {primaryModal === MODAL_DELETE && this.renderDeleteModal()}
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(DocumentTree);
