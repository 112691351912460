import { createActions } from '../utils/redux';

export const {
  builderReset,
  builderSetFieldSections,
  builderSetShouldEditSetup,
  builderSetShouldEditParties,
  builderSetShouldEnableAllComponents,
  builderSetShouldValidate,
  builderSetTab,
  builderSetInitialWorkflow,
} = createActions('builder', [
  'reset',
  'setFieldSections',
  'setShouldEditSetup',
  'setShouldEditParties',
  'setShouldEnableAllComponents',
  'setShouldValidate',
  'setTab',
  'setInitialWorkflow',
]);
