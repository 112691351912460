import borders from './borders';
import colors from './colors';
import radii from './radii';
import shadows from './shadows';
import sizes from './sizes';
import space from './space';
import typography from './typography';
import vendorOverrides from './vendorOverrides';
import zIndices from './zIndices';

// base colors required by theme UI
const themeUiColors = {
  background: colors.palette.white,
  text: colors.palette['gray-900'],
  primary: colors.palette['blue-500'],
};

const styles = {
  root: {
    bg: 'white',
    fontFamily: 'body',
    minHeight: '100vh',
    ...vendorOverrides,
  },
};

export default {
  // spec
  borders,
  colors: {
    // flatten colors
    ...colors.alphas,
    ...colors.palette,
    ...themeUiColors,
  },
  fonts: typography.fonts,
  fontWeights: typography.fontWeights,
  radii,
  shadows,
  sizes,
  space,
  zIndices,

  // variants
  text: typography.variants,

  // root styles
  styles,
};
