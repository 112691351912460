import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Layout,
  SearchInput,
  Text,
  types,
  useModal,
  useThrottledValue,
} from '~/eds';
import { FieldId } from '~/evifields';
import { Nullable, PilotId } from '~/types';

import GroupedCheckboxSelect from '../../GroupedCheckboxSelect';
import { presets } from './presets';
import { OptionsType, SelectedFilters } from './types';
import { groupFilterOptions } from './utils';

const resolvePresetProps = (props: Props) => {
  const { preset } = props;
  const presetProps = preset ? presets[preset] : presets['default'] ?? {};
  return {
    ...presetProps,
    ...props,
  };
};

type Props = {
  groupsOrder: string[];
  searchFilters: OptionsType[];
  getActionButtonTooltip: (state: { selectedFiltersCount: number }) => string;
  onApply: (ids: string[]) => void;
  buttonText?: string;
  buttonIcon?: types.IconType;
  buttonSize?: 'xs' | 's' | 'm' | 'l';
  buttonVariant?: 'action' | 'secondary';
  ctaText?: string;
  limit?: number;
  preset?: keyof typeof presets;
  pinnedFiltersIds?: Array<PilotId | string>;
  selectedFilters?: SelectedFilters;
  onUpdatePinnedFilters?: (
    updatedPinnedFilters: Array<PilotId | string>,
  ) => void;
  renderHelpText?: (state: {
    selectedFiltersCount: number;
  }) => JSX.Element | null;
  renderLimitHeader?: () => JSX.Element;
};

const ManageFilters = (props: Props) => {
  const {
    buttonText,
    buttonIcon,
    buttonSize,
    buttonVariant,
    ctaText,
    getActionButtonTooltip,
    groupsOrder,
    limit,
    pinnedFiltersIds,
    renderHelpText,
    renderLimitHeader,
    searchFilters,
    selectedFilters = {},
    onApply,
    onUpdatePinnedFilters,
  } = resolvePresetProps(props);
  const [search, setSearch] = useState('');
  const [selectedFilterIds, setSelectedFilterIds] = useState<
    Nullable<SelectedFilters>
  >(selectedFilters);

  const selectedFiltersCount = Object.values(selectedFilterIds || {}).flat()
    .length;
  const hasNoFiltersSelected = selectedFiltersCount === 0;
  const hasOverLimitSelected = Boolean(limit && selectedFiltersCount > limit);

  const throttleMs = useMemo(() => searchFilters.length * 2, [searchFilters]);
  const throttledSearch = useThrottledValue(search, throttleMs);

  const handleShowModal = () => {
    showModal();
    setSelectedFilterIds(selectedFilters);
  };

  const handleHide = () => {
    setSearch('');
  };

  const handleSearch = (updatedSearch: Nullable<string>) => {
    setSearch(updatedSearch || '');
  };

  const generateFilterIdsInNewOrder = () => {
    const existingIDs = Object.values(selectedFilters).flat();
    const allIDs = Object.values(selectedFilterIds || {}).flat();
    const newlyAddedIDs = allIDs.filter((item) => !existingIDs.includes(item));
    const remainingIDs = allIDs.filter((item) => !newlyAddedIDs.includes(item));
    return [...newlyAddedIDs, ...remainingIDs];
  };

  const handleSubmit = () => {
    const ids = generateFilterIdsInNewOrder();
    onApply(ids);
    hideModal();
  };

  const checkboxGroups = React.useMemo(() => {
    const groupedFieldOptions = groupFilterOptions({
      searchFilters,
      search,
      groupsOrder,
    });
    const optionsCount = groupedFieldOptions.reduce(
      (acc, group) => acc + group.options.length,
      0,
    );

    if (optionsCount === 0) {
      return <Text preset="description">No matching fields.</Text>;
    }

    return (
      <GroupedCheckboxSelect<FieldId>
        pins={pinnedFiltersIds as string[]}
        search={throttledSearch}
        optionGroups={groupedFieldOptions}
        value={selectedFilterIds}
        onChange={setSelectedFilterIds}
        onUpdatePinnedFilters={onUpdatePinnedFilters}
      />
    );
  }, [searchFilters, selectedFilterIds, throttledSearch, groupsOrder]);

  const [modal, showModal, hideModal] = useModal({
    children: (
      <Layout direction="column" h="100%" spacing={6}>
        {renderLimitHeader?.()}
        <Box flex="none">
          <SearchInput
            autoFocus
            name="fields"
            placeholder="Search fields"
            value={search}
            onChange={handleSearch}
          />
        </Box>
        {renderHelpText?.({ selectedFiltersCount })}
        <Box flex="auto" overflowY="auto">
          {checkboxGroups}
        </Box>
      </Layout>
    ),
    disableHideOnEscape: true,
    isStretched: true,
    primaryAction: {
      disabled: hasNoFiltersSelected || hasOverLimitSelected,
      text: ctaText,
      tooltip: getActionButtonTooltip({ selectedFiltersCount }),
      onClick: handleSubmit,
    },
    onHide: handleHide,
    title: buttonText,
    width: 'l',
  });

  return (
    <>
      <Button
        iconPosition="left"
        size={buttonSize}
        icon={buttonIcon}
        text={buttonText}
        variant={buttonVariant}
        onClick={handleShowModal}
      />
      {modal}
    </>
  );
};

export default ManageFilters;
