import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';

export const useRouting = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<any>();

  const getSearchParam = <V extends string>(
    param: string,
    defaultValue?: V,
  ) => {
    const searchParams = new URLSearchParams(location.search);
    const value = searchParams.get(param) as V;
    return value ?? defaultValue;
  };

  const getSearchParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  };

  const setSearchParam = (param: string, value: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(param, value);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const setSearchParams = (params: Record<string, string>) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return {
    location,
    navigate,
    params,
    getSearchParam,
    getSearchParams,
    setSearchParam,
    setSearchParams,
  };
};
