import { decamelizeKeys } from 'humps';
import { head } from 'lodash';

import { types } from '~/eds';
import { clouseau } from '~/services';
import { ClauseLibraryFilters, Nullable, Uuid } from '~/types';

// REQUEST //

type Position = 'Preferred' | 'Fallback' | 'Walk away';

interface CreateClauseRequest {
  name: string;
  guidance?: string;
}

export interface FilterClauseParams {
  searchText?: string;
  clauseId?: Uuid[];
  position?: Position;
}

interface ListClausesRequest {
  filters?: Nullable<ClauseLibraryFilters>;
}

interface SearchClausesRequest {
  name?: string;
}

interface GetClauseRequest {
  clauseId: Uuid;
  filters?: Nullable<ClauseLibraryFilters>;
}

interface EditClauseRequest {
  clauseId: Uuid;
  name: string;
  guidance?: string;
}

interface DeleteClauseRequest {
  clauseId: Uuid;
}

interface CreateVariationRequest {
  clauseId: Uuid;
  nickname: string;
  position: Position;
  guidance?: string;
  requiresApproval: boolean;
  text: string;
}

interface EditVariationRequest {
  variationId: Uuid;
  nickname: string;
  position: Position;
  guidance?: string;
  requiresApproval: boolean;
  text: string;
}

interface DeleteVariationRequest {
  variationId: Uuid;
}

// RESPONSE //

// TODO: move this to a different file for generic use
interface OpenAPIResponseEntry {
  id: Uuid;
  type: string;
}

interface ClauseSummary {
  name: string;
}

interface ClauseSummaryRelationships {
  variations: {
    data: OpenAPIResponseEntry[];
  };
}

interface ClauseVariationSummary {
  nickname: string;
  position: Position;
}

export interface ListClausesResponseData extends OpenAPIResponseEntry {
  attributes: ClauseSummary;
  relationships?: ClauseSummaryRelationships;
}

interface ListClauseResponseIncluded extends OpenAPIResponseEntry {
  attributes: ClauseVariationSummary;
}

export interface ListClausesResponse {
  data: ListClausesResponseData[];
  included?: ListClauseResponseIncluded[];
}

export interface GetClauseResponseData extends OpenAPIResponseEntry {
  attributes: {
    name: string;
    createDate: string; // date-time
    editDate: string; // date-time
    guidance: string;
  };
  relationships: {
    variations: {
      data: OpenAPIResponseEntry[];
    };
  };
}

interface GetClauseResponseIncluded extends OpenAPIResponseEntry {
  attributes: {
    nickname: string;
    position: Position;
    createDate: string; // date-time
    editDate: string; // date-time
    guidance: string;
    requiresApproval: boolean;
    text: string;
  };
}

interface GetClauseResponse {
  data: GetClauseResponseData;
  included?: GetClauseResponseIncluded[];
}

interface SearchClauseResponse {
  data: GetClauseResponseData[];
}

export interface VariationData {
  key: Uuid;
  name: string;
  text: string;
  guidance: string;
  position: Position;
  status: types.StatusType;
}

export interface ClauseData {
  key: Uuid;
  name: string;
  guidance: string;
  variations?: VariationData[];
}

// HELPER //

const mapPositionToColor = (position: Position): types.IconColor => {
  switch (position) {
    case 'Preferred':
      return 'status.success';
    case 'Fallback':
      return 'status.info';
    case 'Walk away':
      return 'status.danger';
    default:
      return 'status.info';
  }
};

const mapPositionToStatus = (position: Position): types.StatusType => {
  switch (position) {
    case 'Preferred':
      return 'success';
    case 'Fallback':
      return 'info';
    case 'Walk away':
      return 'danger';
    default:
      return 'info';
  }
};

const listClauseSummaryParser = (
  response: ListClausesResponse,
): types.TreeNode[] => {
  const clauseMapHelper = (clause: ListClausesResponseData): types.TreeNode => {
    const result: types.TreeNode = {
      key: clause.id,
      name: clause.attributes.name,
      pathname: `/clause-library/${clause.id}`,
      children: [],
    };
    if (clause?.relationships?.variations?.data?.length) {
      const variationMapHelper = (
        variationReference: OpenAPIResponseEntry,
      ): types.TreeNode => {
        const variation = response?.included?.find(
          (variation) => variation.id === variationReference?.id,
        ) as ListClauseResponseIncluded;
        return {
          key: variation.id,
          name: variation.attributes.nickname,
          parentKey: clause.id,
          pathname: `/clause-library/${clause.id}`,
          hash: variation.id,
          children: [],
          status: {
            icon: 'status-current',
            label: variation.attributes.position,
            tooltip: variation.attributes.position,
            color: mapPositionToColor(variation.attributes.position),
          },
        };
      };
      result.children = clause.relationships.variations.data.map(
        variationMapHelper,
      );
    }
    return result;
  };

  return response.data.map(clauseMapHelper);
};

const getClauseDataParser = (response: GetClauseResponse): ClauseData => {
  const variations = response?.included?.map(
    (variation: GetClauseResponseIncluded): VariationData => {
      return {
        key: variation.id,
        name: variation.attributes.nickname,
        text: variation.attributes.text,
        guidance: variation.attributes.guidance,
        position: variation.attributes.position,
        status: mapPositionToStatus(variation.attributes.position),
      };
    },
  );
  return {
    key: response.data.id,
    name: response.data.attributes.name,
    guidance: response.data.attributes.guidance,
    variations,
  };
};

// ENDPOINT DEFINITION //

export const clauseLibraryCreateClause = async ({
  name,
  guidance,
}: CreateClauseRequest) => {
  return clouseau.post('/clauses', { name, guidance }, {});
};

export const clauseLibraryListClauses = async ({
  filters,
}: ListClausesRequest): Promise<types.TreeNode[]> => {
  const clauseIds =
    filters?.chips?.find((c) => c.id === 'CLAUSE_FILTER')?.values ?? [];
  const position = head(
    filters?.chips?.find((c) => c.id === 'POSITION_FILTER')?.values,
  );

  const params = new URLSearchParams();
  for (const id of clauseIds) params.append('filter[clause_id]', id as string);

  const queryString = !!filters?.query
    ? `&filter[search_text]=${encodeURIComponent(filters?.query)}`
    : '';
  const positionString = !!position
    ? `&filter[position]=${encodeURIComponent(position as string)}`
    : '';
  const paramsString = params.toString() + queryString + positionString;

  const response = await clouseau.get(`/clauses?${paramsString}`, {});
  return listClauseSummaryParser(response);
};

export const clauseLibrarySearchClauses = async ({
  name,
}: SearchClausesRequest): Promise<SearchClauseResponse> => {
  return clouseau.get(
    '/clauses/search',
    !!name ? { params: { name } } : undefined,
  );
};

export const clauseLibraryGetClause = async ({
  clauseId,
  filters,
}: GetClauseRequest): Promise<ClauseData> => {
  const position = head(
    filters?.chips?.find((c) => c.id === 'POSITION_FILTER')?.values,
  );

  const params = new URLSearchParams();

  const queryString = !!filters?.query
    ? `&filter[search_text]=${encodeURIComponent(filters?.query)}`
    : '';
  const positionString = !!position
    ? `&filter[position]=${encodeURIComponent(position as string)}`
    : '';
  const paramsString = params.toString() + queryString + positionString;

  const response = await clouseau.get(
    `/clauses/${clauseId}?${paramsString}`,
    {},
  );
  return getClauseDataParser(response);
};

export const clauseLibraryEditClause = async ({
  clauseId,
  name,
  guidance,
}: EditClauseRequest) => {
  return clouseau.put(`/clauses/${clauseId}`, { name, guidance }, {});
};

export const clauseLibraryDeleteClause = async ({
  clauseId,
}: DeleteClauseRequest) => {
  return clouseau.remove(`/clauses/${clauseId}`, {});
};

export const clauseLibraryCreateVariation = async ({
  clauseId,
  nickname,
  position,
  guidance,
  requiresApproval,
  text,
}: CreateVariationRequest) => {
  return clouseau.post(
    '/variations',
    decamelizeKeys({
      clauseId,
      nickname,
      position,
      guidance,
      requiresApproval,
      text,
    }),
    {},
  );
};

export const clauseLibraryEditVariation = async ({
  variationId,
  nickname,
  position,
  guidance,
  requiresApproval,
  text,
}: EditVariationRequest) => {
  return clouseau.put(
    `/variations/${variationId}`,
    decamelizeKeys({ nickname, position, guidance, requiresApproval, text }),
    {},
  );
};

export const clauseLibraryDeleteVariation = async ({
  variationId,
}: DeleteVariationRequest) => {
  return clouseau.remove(`/variations/${variationId}`, {});
};
