import React from 'react';

import { Steps } from '../../../components';

interface Props<V = unknown> {
  currentStepValue: V;
  defaultStepLabel?: string;
  isCompleted?: boolean;
  isCancelled?: boolean;
  steps: { label: string; value: V }[];
}

export const StepsCell = ({
  currentStepValue,
  isCompleted,
  isCancelled,
  defaultStepLabel,
  steps,
}: Props) => {
  return (
    <Steps
      currentStepValue={currentStepValue}
      isCompleted={isCompleted}
      isCancelled={isCancelled}
      defaultStepLabel={defaultStepLabel}
      mode="concise"
      steps={steps}
    />
  );
};
