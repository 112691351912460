import React from 'react';
import injectSheet from 'react-jss';

import { FlexLayout, Text } from '~/ui';

import LoadingSpinner from '../Icons/LoadingSpinner';
import styles from './LoadingPage.styles';

function LoadingPage({ classes, message }) {
  return (
    <FlexLayout className={classes.root} flexDirection="column" space={2}>
      <LoadingSpinner size="medium" />
      {message && (
        <Text color="gray-600" variant="m-spaced">
          {message}
        </Text>
      )}
    </FlexLayout>
  );
}

export default injectSheet(styles)(LoadingPage);
