export default `
<svg
  aria-hidden="true"
  aria-label="attachment"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M7.5 7.375C7.5 5.53125 9 4 10.875 4C12.7188 4 14.25 5.53125 14.25 7.375V14.75C14.25 16 13.2188 17 12 17C10.75 17 9.75 16 9.75 14.75V8.75C9.75 8.34375 10.0625 8 10.5 8C10.9062 8 11.25 8.34375 11.25 8.75V14.75C11.25 15.1875 11.5625 15.5 12 15.5C12.4062 15.5 12.75 15.1875 12.75 14.75V7.375C12.75 6.34375 11.9062 5.5 10.875 5.5C9.8125 5.5 9 6.34375 9 7.375V15.5C9 17.1562 10.3125 18.5 12 18.5C13.6562 18.5 15 17.1562 15 15.5V8.75C15 8.34375 15.3125 8 15.75 8C16.1562 8 16.5 8.34375 16.5 8.75V15.5C16.5 18 14.4688 20 12 20C9.5 20 7.5 18 7.5 15.5V7.375Z"
    fill="currentcolor"
  />
</svg>
`;
