import React, { Component } from 'react';

import { Card, CopyButton, EmailInput, FormField, Layout, Text } from '~/eds';

class EmailSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inboundEmail: '',
      copied: false,
    };
  }

  componentDidMount() {
    const { inboundEmail } = this.props;

    this.setState({ inboundEmail });
  }

  render() {
    const { inboundEmail } = this.state;

    return (
      <Card
        header={{
          title: 'Email Settings',
        }}
        mode="bordered"
      >
        <Layout preset="form-fields">
          <Text preset="description">
            Email Intake allows you to set up a way to import documents into
            Evisort by emailing them to a special address.
          </Text>
          <FormField
            disabled
            label="Intake Email"
            input={EmailInput}
            inputProps={{
              width: 'input.l.width',
            }}
            value={inboundEmail}
          />
          <CopyButton
            copyText={inboundEmail}
            copiedToastMessage="Email address copied to clipboard"
          />
        </Layout>
      </Card>
    );
  }
}

export default EmailSettings;
