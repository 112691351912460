export default `
<svg
  aria-hidden="true"
  aria-label="double-arrow-left"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M7.28125 11L12.2812 5.78122C12.5625 5.49997 12.5625 4.99997 12.25 4.71872C11.9375 4.43747 11.4688 4.43747 11.1875 4.74997L5.6875 10.5C5.40625 10.7812 5.40625 11.25 5.6875 11.5312L11.1875 17.2812C11.4688 17.5937 11.9688 17.5937 12.25 17.3125C12.4062 17.1562 12.5 16.9687 12.5 16.75C12.5 16.5937 12.4062 16.4062 12.2812 16.25L7.28125 11ZM18.2812 16.25L13.2812 11L18.2812 5.78122C18.5625 5.49997 18.5625 4.99997 18.25 4.71872C17.9375 4.43747 17.4688 4.43747 17.1875 4.74997L11.6875 10.5C11.4062 10.7812 11.4062 11.25 11.6875 11.5312L17.1875 17.2812C17.4688 17.5937 17.9375 17.5937 18.25 17.3125C18.4062 17.1562 18.5 16.9687 18.5 16.75C18.5 16.5625 18.4062 16.4062 18.2812 16.25Z"
    fill="currentcolor"
  />
</svg>
`;
