import PropTypes from 'prop-types';
import React from 'react';

import { evisortBlue } from '../../../assets/shared-styles/general';

const DocumentFilledIcon = (props) => {
  let color = props.color;
  let opacity = props.opacity;

  if (props.isActive) {
    color = evisortBlue;
    opacity = '1';
  }

  return (
    <svg
      aria-hidden="true"
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <path
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
          d="M4 1.333c-.733 0-1.327.6-1.327 1.334l-.006 10.666c0 .734.593 1.334 1.326 1.334H12c.733 0 1.333-.6 1.333-1.334v-8l-4-4H4zM8.667 6V2.333L12.333 6H8.667z"
        />
      </g>
    </svg>
  );
};

DocumentFilledIcon.defaultProps = {
  size: '16',
  color: '#fff',
  opacity: '1',
};

DocumentFilledIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
};

export default DocumentFilledIcon;
