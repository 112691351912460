import React from 'react';

import { Layout, Text } from '~/eds';

import { FilterChip } from './FilterChip';
import { Field, FieldId, Filter } from './types';

interface Props {
  enableFilterViews?: boolean;
  disableRemove: boolean;
  fields: Record<FieldId, Field>;
  filters: Filter[];
  onRemoveFilter: (_filterToRemove: Filter) => void;
  onUpdateFilter: (_updatedFilter: Filter) => void;
}

export const FilterChips = ({
  enableFilterViews = false,
  disableRemove,
  fields,
  filters,
  onRemoveFilter,
  onUpdateFilter,
}: Props) => {
  if (filters.length === 0) {
    return <Text preset="description">No filters selected</Text>;
  }

  return (
    <Layout role="list" wrap preset="chips">
      {filters.map(
        (filter) =>
          filter.fieldId && (
            <FilterChip
              key={filter.id}
              disableRemove={disableRemove}
              enableFilterViews={enableFilterViews}
              field={fields[filter.fieldId]}
              filter={filter}
              hasError={filter.hasError}
              onChange={onUpdateFilter}
              onRemove={onRemoveFilter}
            />
          ),
      )}
    </Layout>
  );
};
