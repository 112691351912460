import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BaseBooleanSearchBar from '~/components/Shared/BaseBooleanSearchBar';
import { selectors } from '~/redux';

const BooleanSearchBar = ({
  classes = {},
  isDisabled = false,
  isQuickSearch,
  isSubmitting,
  onSubmit,
}) => {
  const booleanQuery = useSelector(selectors.selectBooleanQuery);
  const [booleanSearch, setBooleanSearch] = useState('');

  useEffect(() => {
    setBooleanSearch(booleanQuery);
  }, [booleanQuery]);

  const handleSubmit = () => {
    onSubmit(booleanSearch);
  };

  return (
    <BaseBooleanSearchBar
      classes={classes}
      isDisabled={isDisabled}
      isQuickSearch={isQuickSearch}
      isSubmitting={isSubmitting}
      value={booleanSearch}
      onChange={setBooleanSearch}
      onSubmit={handleSubmit}
    />
  );
};

export default BooleanSearchBar;
