export const reactMarkdown = {
  '.eds-markdown': {
    wordWrap: 'break-word',
  },
  '.eds-markdown > p': {
    margin: 0,
    padding: 0,
    marginBottom: '8px',
    whiteSpace: 'pre-wrap',
  },
  '.eds-markdown > p:last-child': {
    margin: 0,
    padding: 0,
  },
  '.eds-markdown > ul, .eds-markdown > ol': {
    marginTop: 0,
    marginBottom: 0,
  },
  '.eds-markdown > ul > li, .eds-markdown > ol > li': {
    margin: 0,
    padding: 0,
    paddingBottom: '4px',
    paddingTop: '4px',
  },
  '.eds-markdown > ul > li > p, .eds-markdown > ol > li > p': {
    margin: 0,
    padding: 0,
  },
  '.eds-markdown table': {
    borderCollapse: 'collapse',
  },
  '.eds-markdown td, .eds-markdown th': {
    border: 'border',
  },
};
