import React from 'react';
import { useStyles } from 'uinix-ui';

import { Layout } from '../../../components';
import {
  ROW_DEFAULT_HEIGHT,
  ROW_DETAILS_CLASSNAME,
  UI_TABLE_SELECT_CELL_TESTID,
} from '../constants';

// TODO: should remove testIds completely in favor of role-based selectors.  Check with E2E tests
export const testIdToClassSelector = (testId) => {
  return '.' + testId.replace(/\//g, '\\/');
};

const TABLE_SELECT_CELL_CLASS_SELECTOR = testIdToClassSelector(
  UI_TABLE_SELECT_CELL_TESTID,
);

function Row({
  children,
  disabled,
  isHeaderRow = false,
  isActive = false,
  isSelected = false,
  justify,
  styles: rowStyles = {},
  isBordered = true,
  onClick,
}) {
  const styles = useStyles();

  return (
    <Layout
      align="stretch"
      as="tr"
      justify={justify}
      onClick={onClick}
      styles={{
        // style order matters
        backgroundColor: 'background',
        ...styles.colors.status({
          status: isSelected ? 'active' : isActive ? 'info' : undefined,
        }),
        ...(disabled ? styles.disabledVisual : {}),
        ...rowStyles,
        borderBottom: isBordered ? 'border.divider' : undefined,
        minHeight: ROW_DEFAULT_HEIGHT,
        minWidth: '100%',
        width: 'fit-content',
        // TODO: this is not great as the general Row component is coupled and has to decide what to do with a specific cell (i.e. SelectCell).  This mental model should be inverted and fixed appropriately.
        [`& ${TABLE_SELECT_CELL_CLASS_SELECTOR} svg`]: {
          visibility: isHeaderRow || isSelected ? 'visible' : 'hidden',
        },
        [`:hover ${TABLE_SELECT_CELL_CLASS_SELECTOR} svg`]: {
          visibility: 'visible',
        },
        [`:hover .${ROW_DETAILS_CLASSNAME}`]: {
          visibility: 'visible',
        },
      }}
    >
      {children}
    </Layout>
  );
}

export default Row;
