import { Bar } from '@nivo/bar';
import { useMemo } from 'react';

import { ChartTooltip } from '~/components/Shared/Charts/ChartTooltip';
import { Box, Layout, Text } from '~/eds';
import { TurnTrackingHistoryType, TurnTrackingPartyType } from '~/types';

import { calculateDuration, partyNameFromPartyIdAndParties } from './utils';

interface FillType {
  match: {
    id: string;
  };
  id: string;
}

interface ProgressBarObjectType {
  durationObject: {
    [turn: string]: number;
  };
  turns: string[];
  fill: FillType[];
}

export const ProgressBar = ({
  history,
  parties,
}: {
  history: TurnTrackingHistoryType[];
  parties: TurnTrackingPartyType[];
}) => {
  const allPartyNames = parties.map((entry: TurnTrackingPartyType) => {
    return partyNameFromPartyIdAndParties(entry.id, parties);
  });
  const colors = {
    [allPartyNames[0]]: '#ffbc04',
    [allPartyNames[1]]: '#78ac44',
    [`${allPartyNames[0]}-current`]: '#ffeebf',
    [`${allPartyNames[1]}-current`]: '#dbebd1',
  };
  const currentTurn = history[0];
  const currentParty = partyNameFromPartyIdAndParties(
    currentTurn.partyId,
    parties,
  );
  const legend = allPartyNames.concat(`${currentParty}-current`);
  const firstTurn = history[history.length - 1];
  const totalDuration = calculateDuration(firstTurn.startTime, new Date());

  const reversedHistory = history.slice().reverse();

  const progressBarObject = useMemo(() => {
    return reversedHistory.reduce(
      (
        previousValue: ProgressBarObjectType,
        entry: TurnTrackingHistoryType,
        index: number,
      ) => {
        const turn = `turn-${index + 1}`;
        const endTime = entry.endTime ?? new Date();
        const duration = calculateDuration(entry.startTime, endTime);
        previousValue.durationObject[turn] = duration;
        previousValue.turns.push(turn);
        const partyName = partyNameFromPartyIdAndParties(
          entry.partyId,
          parties,
        );
        previousValue.fill.push({
          match: {
            id: turn,
          },
          id:
            index === reversedHistory.length - 1
              ? `${currentParty}-current`
              : partyName,
        });
        return previousValue;
      },
      {
        durationObject: {},
        turns: [],
        fill: [],
      },
    );
  }, [reversedHistory]);

  const { durationObject, turns, fill } = progressBarObject;

  return (
    <Layout direction="column">
      <Layout justify="space-between">
        <Text variant="tiny" color="text.secondary">
          Days in review ({totalDuration})
        </Text>
        <Layout spacing={2}>
          {legend.map((partyName: string, index: number) => {
            return (
              <Layout spacing={2}>
                <Layout align="center" justify="center">
                  <Box h={12} w={12} bg={colors[partyName]} />
                </Layout>
                <Text variant="tiny" color="text.secondary">
                  {index === allPartyNames.length ? 'Current' : partyName}
                </Text>
              </Layout>
            );
          })}
        </Layout>
      </Layout>
      <Layout h={20}>
        <Bar
          data={[durationObject]}
          layout="horizontal"
          keys={turns}
          height={30}
          width={450}
          margin={{ top: 10 }}
          innerPadding={2}
          fill={fill}
          axisBottom={null}
          enableLabel={false}
          enableGridY={false}
          defs={[
            {
              id: allPartyNames[0],
              type: 'patternLines',
              color: colors[allPartyNames[0]],
              lineWidth: 20,
            },
            {
              id: allPartyNames[1],
              type: 'patternLines',
              color: colors[allPartyNames[1]],
              lineWidth: 20,
            },
            {
              id: `${currentParty}-current`,
              type: 'patternLines',
              color: colors[`${currentParty}-current`],
              lineWidth: 20,
            },
          ]}
          tooltip={(data) => {
            const tooltipContent = `${data.value} days`;
            return <ChartTooltip tooltipContent={tooltipContent} />;
          }}
        />
      </Layout>
    </Layout>
  );
};
