import {
  black1,
  black2,
  black3,
  bold,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const sectionLabel = {
  color: black2,
  fontSize: '15px',
  fontWeight: bold,
};

const sectionEmptyState = {
  marginBottom: '16px',
  color: black3,
  fontSize: '15px',
};

const sectionAddElement = {
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',

  '& svg': {
    marginRight: '4px',
  },

  '& span': {
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
  },
};

const styles = {
  loadingContainer: {
    margin: '120px 0',
    textAlign: 'center',
  },
  optionLabel: {
    marginBottom: '8px',
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
  },
  optionTextArea: {
    display: 'block',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    padding: '11px 15px',
    color: black1,
    fontSize: '15px',
    outline: 'none',
    caretColor: evisortBlue,
    resize: 'none',
  },
  optionDatepicker: {
    '& input': {
      width: '145px',
    },
  },
  optionInlineFields: {
    display: 'flex',
  },
  optionFieldsSeparator: {
    width: '8px',
  },
  optionsInfo: {
    width: '528px',
    marginBottom: '16px',
    color: black2,
    fontSize: '14px',
    lineHeight: '1.5',
  },
  nameSection: {
    marginBottom: '24px',
  },
  messageSection: {
    marginBottom: '32px',
  },
  schedulingSection: {
    marginBottom: '32px',
  },
  schedulingLabel: {
    ...sectionLabel,
    marginBottom: '16px',
  },
  schedulingSubSections: {
    display: 'flex',

    '& > div:not(:last-child)': {
      marginRight: '24px',
    },
  },
  foldersLabel: {
    ...sectionLabel,
    marginBottom: '8px',
  },

  selectedFoldersEmpty: {
    ...sectionEmptyState,
  },
  selectedFolders: {
    marginBottom: '6px',
  },
  foldersAddLink: {
    ...sectionAddElement,
    backgroundColor: 'transparent',
    border: 'none',
  },
  filtersSection: {
    minHeight: '350px',
  },
  filtersLabel: {
    ...sectionLabel,
    marginBottom: '16px',
  },
  filtersEmptyState: {
    ...sectionEmptyState,
  },
  filtersAddLink: {
    ...sectionAddElement,
  },
  recipientsLabel: {
    ...sectionLabel,
    marginBottom: '16px',
  },
  recipientsSection: {
    marginBottom: '32px',
  },
  recipientsSubsections: {
    display: 'flex',
  },
  recipientsSectionContainer: {
    marginRight: '30px',
    flexBasis: '32%',
  },
  departmentsEmptyState: {
    ...sectionEmptyState,
  },
  departmentsAddLink: {
    ...sectionAddElement,
    backgroundColor: 'transparent',
    border: 'none',
  },
  usersMultipleValuesContainer: {
    maxWidth: '280px',
  },
  multipleValuesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '7px',

    '& svg': {
      flexShrink: 0,
    },
  },
  multipleValuesEmpty: {
    margin: '5px 0',
    color: black3,
    fontSize: '15px',
  },
  multipleValues: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '8px',
  },
  excludeUsersSection: {
    marginBottom: '32px',
  },
  excludeUsersLabel: {
    ...sectionLabel,
    marginBottom: '8px',
  },
  permissionsLabel: {
    ...sectionLabel,
    marginBottom: '8px',
  },
  permissionsCheckbox: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    color: black2,
    fontSize: '15px',
    lineHeight: '1.4',
  },
  filterSectionWrapper: {
    borderRadius: '4px',
    padding: '16px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  modalErrorMessage: {
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    marginTop: '24px',
    padding: '12px 16px',
    backgroundColor: 'rgba(243, 55, 55, 0.1)',
    color: black2,
    fontSize: '15px',
  },
};

export default styles;
