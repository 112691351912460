import React, { memo, useEffect, useState } from 'react';
import { useStyles } from 'uinix-ui';

import { Layout } from '../Layout';
import { Progress } from '../Progress';
import { Text } from '../Text';
import { StepperSection } from './StepperSection';
import { Section } from './types';
import { findLastCompletedIndex, getCompletedCount } from './utils';

interface Props {
  /** Unique name to identify the stepper  */
  name: string;
  /** List of sections */
  sections: Section[];
  /** Current section ID */
  currentSectionId?: string;
  /** Enables navigation of each step via hash link */
  enableNavigation?: boolean;
  /** Enables the progress bar */
  enableProgress?: boolean;
  /** Section's container id which is used for auto setting active section when scrolling. */
  containerId?: string;
  /** If sections are ordered (not skippable) or unordered (skippable) */
  isOrdered?: boolean;
  /** Sections classnames which are needed for auto setting active section when scrolling.  */
  sectionClassName?: string;
}

export const Stepper = memo(
  ({
    containerId,
    currentSectionId: overrideSectionId,
    enableNavigation = true,
    enableProgress,
    isOrdered = false,
    name,
    sections,
    sectionClassName,
  }: Props) => {
    const [currentSectionId, setCurrentSectionId] = useState(overrideSectionId);
    const styles = useStyles();

    useEffect(() => {
      setCurrentSectionId(overrideSectionId);
    }, [overrideSectionId]);

    useEffect(() => {
      if (containerId) {
        const container = document.querySelector(`#${containerId}`);
        container?.addEventListener('scroll', () => onScroll());
        return () => container?.removeEventListener('scroll', () => onScroll());
      }
    }, [containerId]);

    const onScroll = () => {
      const container = document.querySelector(`#${containerId}`);
      const sections = document.querySelectorAll(`.${sectionClassName}`);
      if (!container || !sections.length) {
        return;
      }
      if (sections.length === 1) {
        setCurrentSectionId(sections[0].id);
      }
      const containerRect = container.getBoundingClientRect();
      const visibleElements: string[] = [];
      sections.forEach((el: Element) => {
        const rectangle = el.getBoundingClientRect();
        const scrollingOffset = rectangle.height / 2;
        if (
          rectangle.top + scrollingOffset >= containerRect.top &&
          rectangle.top - scrollingOffset <= containerRect.bottom
        ) {
          visibleElements.push(el.id);
        }
      });

      const secondToLastSection = sections[sections.length - 2];
      const lastSection = sections[sections.length - 1];

      setCurrentSectionId((state) => {
        if (
          lastSection?.id === visibleElements[visibleElements.length - 1] &&
          visibleElements.length >= 2 &&
          secondToLastSection.getBoundingClientRect().bottom <=
            containerRect.bottom &&
          lastSection.getBoundingClientRect().bottom >= containerRect.bottom
        ) {
          return sections[sections.length - 2].id;
        } else if (
          (state === secondToLastSection?.id && visibleElements.length === 0) ||
          lastSection.getBoundingClientRect().bottom <= containerRect.bottom
        ) {
          return sections[sections.length - 1].id;
        } else if (state !== visibleElements[0]) {
          return visibleElements[0];
        }

        return state;
      });
    };

    const totalCount = sections.length;
    const lastCompletedIndex = findLastCompletedIndex(sections);
    const completedCount = getCompletedCount(sections);

    const progress = enableProgress && (
      <Layout align="center" spacing={1}>
        <Progress max={totalCount} name={name} value={completedCount} />
        <Text preset="help">
          {completedCount}/{totalCount}
        </Text>
      </Layout>
    );

    return (
      <Layout
        as="nav"
        aria-label={name}
        direction="column"
        flex="auto"
        h="100%"
        spacing={4}
      >
        {progress}
        <Layout
          as={isOrdered ? 'ol' : 'ul'}
          direction="column"
          styles={[styles.list.unset, styles.scroll.hidden]}
          overflowY="auto"
        >
          {sections.map((section, i) => (
            <StepperSection
              key={section.id}
              isOrdered={isOrdered}
              isCurrent={currentSectionId === section.id}
              isLast={i === totalCount - 1}
              isUnstarted={i > lastCompletedIndex + 1}
              section={section}
              onClick={enableNavigation ? setCurrentSectionId : undefined}
            />
          ))}
        </Layout>
      </Layout>
    );
  },
);
