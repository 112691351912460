import React from 'react';

import { Layout, Text } from '~/eds';
import { Filter } from '~/evifields';
import { PilotId, SearchFilter } from '~/types';

import FilterOperator from '../../FilterOperator';
import FilterChip from '../FilterChip';

interface Props {
  enableFilterViews?: boolean;
  disableEdit?: boolean;
  disableRemove?: boolean;
  filters: Filter[];
  name?: string;
  operatorValue?: string;
  placeholder?: string;
  pinnedFiltersIds?: Array<PilotId | string>;
  readOnly?: boolean;
  searchFilters: SearchFilter[];
  onOperatorChange?: (operatorValue: string) => void;
  onRemoveFilter: (_filterToRemove: Filter) => void;
  onUpdateFilter: (_updatedFilter: Filter) => void;
}

export const FilterChips = ({
  enableFilterViews = false,
  disableEdit,
  disableRemove,
  filters,
  operatorValue,
  placeholder = 'No filters selected',
  pinnedFiltersIds = [],
  readOnly,
  searchFilters,
  onOperatorChange,
  onRemoveFilter,
  onUpdateFilter,
}: Props) => {
  const _hasOperators = !!onOperatorChange;

  if (filters.length === 0) {
    return <Text preset="description">{placeholder}</Text>;
  }

  const handleOperatorValueChange = (value: string) => {
    onOperatorChange?.(value);
  };

  return (
    <>
      {filters.map((filter: Filter, index: number) => {
        const isFirst = index === 0;
        const isLast = index === filters.length - 1;
        const hasOperators = _hasOperators && (isFirst || !isLast);
        const searchFilter = searchFilters.find(
          (searchFilter) => String(searchFilter.id) === String(filter.fieldId),
        );
        if (filter.fieldId && searchFilter) {
          return (
            <Layout
              key={filter.id}
              align="center"
              direction="row"
              mr={1}
              mb={hasOperators ? 2 : 1}
              spacing={hasOperators ? 3 : 0}
              minW={0}
              role="listitem"
            >
              <FilterChip
                isPinned={pinnedFiltersIds.includes(
                  Number(filter.fieldId) || filter.fieldId,
                )}
                enableFilterViews={enableFilterViews}
                disableEdit={disableEdit}
                disableRemove={!!disableRemove}
                readOnly={readOnly}
                searchFilter={searchFilter}
                filter={filter}
                hasError={filter.hasError}
                onChange={onUpdateFilter}
                onRemove={onRemoveFilter}
              />
              {hasOperators && (
                <FilterOperator
                  index={index}
                  operatorValue={operatorValue}
                  onToggleClick={handleOperatorValueChange}
                />
              )}
            </Layout>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};
