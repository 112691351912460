import { TicketStageType } from '~/enums';

export const StageConfig = {
  [TicketStageType.Edit]: {
    label: 'Edit',
    value: TicketStageType.Edit,
    order: 0,
  },
  [TicketStageType.Review]: {
    label: 'Review',
    value: TicketStageType.Review,
    order: 1,
  },
  [TicketStageType.Sign]: {
    label: 'Sign',
    value: TicketStageType.Sign,
    order: 2,
  },
  [TicketStageType.Finalize]: {
    label: 'Finalize',
    value: TicketStageType.Finalize,
    order: 3,
  },
  [TicketStageType.Ticket]: {
    label: 'Ticket',
    value: TicketStageType.Ticket,
    order: 100,
  },
};

export const TICKET_DOCUMENT_VERSION_EDITOR_KEY_POLLING_INTERVAL = 10000;
export const TICKET_DOCUMENT_VERSION_POLLING_MAX_COUNTER = 3;

export const WORKFLOW_TAB_INDEX = 0;
export const FORM_INFO_TAB_INDEX = 1;

export const TICKET_NAMING_CONVENTION_ALLOWED_TYPES = [
  'DEPARTMENT',
  'USER',
  'DATE',
  'SHORT_RESPONSE',
  'SINGLE_SELECT',
];
export const TICKET_NAMING_CONVENTION_ALLOWED_IMPLICIT_TYPES = [
  'Paper Type',
  'Submitter Department',
  'Submitter Name',
  'Ticket Created Date',
  'Workflow Name',
];
