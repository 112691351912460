import { InputWidth } from '../../../types';
import { typography } from '../typography';
import { disabled as baseDisabled } from './disabled';
import { readOnly } from './readOnly';

const text = typography.variants;

const unset = {
  textarea: {
    backgroundColor: 'background',
    border: 'none',
    fontFamily: 'body',
    fontSize: 'm',
    maxHeight: 'textarea.max-height',
    outline: 'none',
    overflowX: 'hidden',
    overflowY: 'auto',
    resize: 'vertical',
    '::placeholder': {
      color: 'text.quiet',
      opacity: 'unset',
    },
  },
};

const disabled = {
  ...baseDisabled,
  backgroundColor: 'background.quiet',
  color: 'text.quiet',
  opacity: 'unset',
};

export const input = {
  container: ({
    enableReadOnly = true,
    error,
    width = '100%',
  }: {
    enableReadOnly?: boolean;
    error?: string;
    width?: InputWidth;
  }) => ({
    ...text.body,
    backgroundColor: 'background',
    border: 'border',
    borderColor: error ? 'status.danger' : 'border',
    borderRadius: 'm',
    color: 'text.primary',
    fontFamily: 'body',
    fontSize: 'm',
    lineHeight: 'm',
    minHeight: 'height.m',
    minWidth: width === '100%' ? 'input.min-width' : undefined,
    outline: 'none',
    paddingLeft: 4,
    paddingRight: 4,
    width,
    ':disabled': disabled,
    ':read-only': enableReadOnly ? readOnly : undefined,
    '::placeholder': {
      color: 'text.quiet',
      opacity: 'unset',
    },
    ':focus-within': {
      borderColor: error ? 'status.danger' : 'status.active',
    },
    ':hover': {
      borderColor: error ? 'status.danger' : 'border.hover',
    },
    ':invalid': {
      borderColor: 'status.danger',
      ':focus-within': {
        borderColor: 'status.danger',
      },
      ':hover': {
        borderColor: 'status.danger',
      },
    },
  }),
  disabled,
  unset,
};
