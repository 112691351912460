import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors } from '~/redux';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { Uuid } from '~/types';

interface UseConversationPipelineProps {
  processHandler?: (
    message: string,
    metadata: {
      questionId: Uuid;
      questionGroupId?: Uuid;
    },
  ) => void;
  onFinishQueue?: () => void;
  enableAutoClearQueue?: boolean;
}

type MessagePayload = { id: Uuid; message: string; parentId?: string };

/**
 * This hook is made to be possible to run questions through Chatbot without
 * the need of invoking the endpoint which needs more setup. It will be consumed
 * by the ChatBot component.
 *
 * @param processHandler - A function that will be called to process the message
 *
 * @returns {postMessage, onFinish, isProcessing}
 */
export const useConversationPipeline = ({
  processHandler,
  onFinishQueue,
  enableAutoClearQueue,
}: UseConversationPipelineProps = {}) => {
  const queue = useSelector(selectors.selectMessageQueue);
  const index = useSelector(selectors.selectMessageQueueIndex);
  const processingMessage = useSelector(selectors.selectProcessingMessage);
  const isProcessing = useSelector(selectors.selectIsMessageQueueProcessing);
  const isRunningMessage = useSelector(selectors.selectIsMessageQueueRunning);
  const parentId = processingMessage?.parentId;
  const dispatch = useDispatch();

  useEffect(() => {
    if (queue.length && processHandler && !isRunningMessage && isProcessing) {
      processHandler(queue[index].message, {
        questionId: queue[index].id,
        questionGroupId: queue[index].parentId,
      });
      dispatch(
        chatbotSlice.actions.updateMessageStatus({
          status: 'running',
          id: queue[index].id,
        }),
      );
    }
  }, [queue, index]);

  const postMessage = (item: MessagePayload | MessagePayload[]) => {
    dispatch(chatbotSlice.actions.postMessageToQueue(item));
  };

  useEffect(() => {
    if (queue.length && !isProcessing && onFinishQueue) {
      onFinishQueue();
      if (enableAutoClearQueue) {
        clearQueue();
      }
    }
  }, [queue, isProcessing]);

  const onFinishMessage = () => {
    dispatch(chatbotSlice.actions.processNextMessage());
  };

  const clearQueue = () => {
    dispatch(chatbotSlice.actions.clearMessageQueue());
  };

  return {
    postMessage,
    onFinishMessage,
    clearQueue,
    parentId,
    processingMessage,
    isProcessing: isProcessing,
    queue,
    index,
  };
};
