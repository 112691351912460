import React, { Fragment } from 'react';

import { Box, CollapsibleText, Divider, Layout, Section } from '~/eds';

interface Props {
  clauses: {
    name: string;
    content: string[];
  }[];
}

type SnippetsProps = {
  title: string;
  snippets: string[];
  limit?: number;
};
function TextSnippets({
  title = '',
  snippets = [],
  limit = 300,
}: SnippetsProps) {
  return (
    <Section title={title}>
      {snippets.map((text, index) => {
        return (
          <Fragment key={index}>
            <Box>
              <CollapsibleText
                text={text}
                limit={limit}
                whiteSpace="pre-wrap"
              />
            </Box>
            {index < snippets.length - 1 ? <Divider /> : null}
          </Fragment>
        );
      })}
    </Section>
  );
}

function ClauseMatches({ clauses = [] }: Props) {
  if (!clauses.length) {
    return null;
  }

  return (
    <Layout preset="sections">
      {clauses.map((match, index) => {
        return (
          <TextSnippets
            key={index}
            title={match.name}
            snippets={match.content}
          ></TextSnippets>
        );
      })}
    </Layout>
  );
}

export default ClauseMatches;
