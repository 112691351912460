import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import injectSheet from 'react-jss';

import { ESCAPE } from '~/constants/keyCodes';

import {
  MODAL_ACCESS_SETTINGS,
  MODAL_ADD_FILTER,
  MODAL_ADMIN_USER,
  MODAL_ALERT,
  MODAL_AUTOCOMPLETE,
  MODAL_CKEDITOR,
  MODAL_CLIENT_PARTY,
  MODAL_CUSTOM_FIELD,
  MODAL_CUSTOM_FIELD_MOVE,
  MODAL_DELETE,
  MODAL_DELETE_DEPARTMENT,
  MODAL_DEPARTMENT_MEMBERS,
  MODAL_DEPARTMENT_TREE,
  MODAL_DOCUMENT_GROUP,
  MODAL_DOCUMENT_GROUP_RELATION,
  MODAL_DOCUMENTS_COLUMN_FIELDS,
  MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
  MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
  MODAL_EXCEL_EXPORT,
  MODAL_FOLDER_TREE,
  MODAL_LEAVE_REVIEW,
  MODAL_LIST,
  MODAL_MULTI_EDIT,
  MODAL_MULTIPLE_LOCATIONS,
  MODAL_NAME,
  MODAL_REORDER_SECTIONS,
  MODAL_SAVE_AND_FLAG,
  MODAL_SEARCH_WITHIN_PROVISION,
  MODAL_SEND_EMAIL,
  MODAL_SIMPLE,
  MODAL_TAG_CLAUSE,
  MODAL_UPLOAD,
  MODAL_VERSION_DOCUMENT_VIEWER,
} from '../../../types/modal.types';
import AccessSettingsModal from '../../Modals/AccessSettingsModal';
import AddFilterModal from '../../Modals/AddFilterModal';
import AdminUserModal from '../../Modals/AdminUserModal';
import AlertModal from '../../Modals/AlertModal';
import AutocompleteModal from '../../Modals/AutocompleteModal';
import CKEditorModal from '../../Modals/CKEditorModal';
import ClientPartyModal from '../../Modals/ClientPartyModal';
import CustomFieldModal from '../../Modals/CustomFieldModal';
import DeleteDepartmentModal from '../../Modals/DeleteDepartmentModal';
import DeleteModal from '../../Modals/DeleteModal';
import DepartmentMembersModal from '../../Modals/DepartmentMembersModal';
import DepartmentTreeModal from '../../Modals/DepartmentTreeModal';
import DocumentGroupModal from '../../Modals/DocumentGroupModal';
import DocumentGroupRelationModal from '../../Modals/DocumentGroupRelationModal';
import DocumentsColumnFieldsModal from '../../Modals/DocumentsColumnFieldsModal';
import DocumentsColumnFieldsSaveModal from '../../Modals/DocumentsColumnFieldsSaveModal';
import DocumentsColumnFieldsViewSwitchModal from '../../Modals/DocumentsColumnFieldsViewSwitchModal';
import ExportSettingModal from '../../Modals/ExportSettingModal';
import FolderTreeModal from '../../Modals/FolderTreeModal';
import LeaveReviewModal from '../../Modals/LeaveReviewModal';
import ListModal from '../../Modals/ListModal';
import MoveFieldToSectionModal from '../../Modals/MoveFieldToSectionModal';
import MultiEditModal from '../../Modals/MultiEditModal';
import MultipleLocationsModal from '../../Modals/MultipleLocationsModal';
import NameModal from '../../Modals/NameModal';
import ReorderSectionsModal from '../../Modals/ReorderSectionsModal';
import SaveNFlagModal from '../../Modals/SaveNFlagModal';
import SearchWithinProvisionModal from '../../Modals/SearchWithinProvisionModal';
import SendPasswordEmailModal from '../../Modals/SendPasswordEmailModal';
import SimpleModal from '../../Modals/SimpleModal';
import TagClauseModal from '../../Modals/TagClauseModal';
import UploadModal from '../../Modals/UploadModal';
import VersionDocumentViewerModal from '../../Modals/VersionDocumentViewerModal';
import styles from './EcModal.styles';

class EcModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnEscPress = this.handleOnEscPress.bind(this);

    this.modalRoot = document.getElementById('modal-root');

    this.state = {
      disableEscapeClose: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.focusOnTheNextOpenModal();
  }

  focusOnTheNextOpenModal = () => {
    const modals = document.querySelectorAll(
      '#modal-root [role="presentation"]',
    );

    // the last modal is this instance itself, and we want to focus on the one before it
    if (modals.length > 1) {
      modals[modals.length - 2].focus();
    }
  };

  disableEscapeCloseModal = (disableEscapeClose) => {
    this.setState({ disableEscapeClose });
  };

  handleOnEscPress(event) {
    if (
      event.keyCode === ESCAPE &&
      !this.props.disableEscapeKey &&
      !this.state.disableEscapeClose
    ) {
      this.props.hideModal();
      event.stopPropagation();
    }
  }

  renderModal() {
    switch (this.props.modalType) {
      case MODAL_CLIENT_PARTY:
        return <ClientPartyModal {...this.props} />;
      case MODAL_DELETE:
        return <DeleteModal {...this.props} />;
      case MODAL_NAME:
        return <NameModal {...this.props} />;
      case MODAL_LIST:
        return <ListModal {...this.props} />;
      case MODAL_ADMIN_USER:
        return <AdminUserModal {...this.props} />;
      case MODAL_ACCESS_SETTINGS:
        return <AccessSettingsModal {...this.props} />;
      case MODAL_SEND_EMAIL:
        return <SendPasswordEmailModal {...this.props} />;
      case MODAL_UPLOAD:
        return (
          <UploadModal
            {...this.props}
            disableEscapeKey={this.disableEscapeCloseModal}
          />
        );
      case MODAL_FOLDER_TREE:
        return <FolderTreeModal {...this.props} />;
      case MODAL_AUTOCOMPLETE:
        return <AutocompleteModal {...this.props} />;
      case MODAL_TAG_CLAUSE:
        return <TagClauseModal {...this.props} />;
      case MODAL_SEARCH_WITHIN_PROVISION:
        return <SearchWithinProvisionModal {...this.props} />;
      case MODAL_SAVE_AND_FLAG:
        return <SaveNFlagModal {...this.props} />;
      case MODAL_LEAVE_REVIEW:
        return <LeaveReviewModal {...this.props} />;
      case MODAL_CKEDITOR:
        return <CKEditorModal {...this.props} />;
      case MODAL_CUSTOM_FIELD:
        return <CustomFieldModal {...this.props} />;
      case MODAL_CUSTOM_FIELD_MOVE:
        return <MoveFieldToSectionModal {...this.props} />;
      case MODAL_REORDER_SECTIONS:
        return <ReorderSectionsModal {...this.props} />;
      case MODAL_MULTIPLE_LOCATIONS:
        return <MultipleLocationsModal {...this.props} />;
      case MODAL_DEPARTMENT_MEMBERS:
        return <DepartmentMembersModal {...this.props} />;
      case MODAL_DEPARTMENT_TREE:
        return <DepartmentTreeModal {...this.props} />;
      case MODAL_DELETE_DEPARTMENT:
        return <DeleteDepartmentModal {...this.props} />;
      case MODAL_MULTI_EDIT:
        return (
          <MultiEditModal
            {...this.props}
            disableEscapeKey={this.disableEscapeCloseModal}
          />
        );
      case MODAL_ALERT:
        return (
          <AlertModal
            {...this.props}
            disableEscapeKey={this.disableEscapeCloseModal}
          />
        );
      case MODAL_SIMPLE:
        return <SimpleModal {...this.props} />;
      case MODAL_VERSION_DOCUMENT_VIEWER:
        return <VersionDocumentViewerModal {...this.props} />;
      case MODAL_ADD_FILTER:
        return <AddFilterModal {...this.props} />;
      case MODAL_DOCUMENTS_COLUMN_FIELDS:
        return <DocumentsColumnFieldsModal {...this.props} />;
      case MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE:
        return <DocumentsColumnFieldsSaveModal {...this.props} />;
      case MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH:
        return <DocumentsColumnFieldsViewSwitchModal {...this.props} />;
      case MODAL_DOCUMENT_GROUP:
        return <DocumentGroupModal {...this.props} />;
      case MODAL_DOCUMENT_GROUP_RELATION:
        return <DocumentGroupRelationModal {...this.props} />;
      case MODAL_EXCEL_EXPORT:
        return <ExportSettingModal {...this.props} />;
      default:
        return null;
    }
  }

  render() {
    const { classes } = this.props;

    const modalUI = (
      <div
        role="presentation"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={classes.modalOverlay}
        onKeyDown={this.handleOnEscPress}
      >
        <div className={classes.modalWrapper} role="dialog">
          {this.renderModal()}
        </div>
      </div>
    );

    return ReactDOM.createPortal(modalUI, this.modalRoot);
  }
}

EcModal.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disableEscapeKey: PropTypes.bool,
  width: PropTypes.string.isRequired,
  verticalCenter: PropTypes.bool,
  modalType: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  disableOverlayClose: PropTypes.bool,
};

export default injectSheet(styles)(EcModal);
