import { black1, black3, bold } from '../../../assets/shared-styles/general';

const styles = {
  sizeAmount: {
    color: black1,
    fontWeight: bold,
  },
  sizeUnit: {
    color: black3,
  },
};

export default styles;
