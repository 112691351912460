import { Radio, types } from '~/eds';

interface Props<V = unknown> {
  disabled?: boolean;
  groupName: string;
  id: string;
  label: types.Option['label'];
  selectedValue: V;
  sublabel?: types.Option['info'];
  value: V;
  onChange: (value: V) => void;
}

const EcRadioButton = ({
  disabled = false,
  groupName,
  id,
  label,
  selectedValue,
  sublabel,
  value,
  onChange,
}: Props) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <Radio
      value
      id={id}
      checked={selectedValue === value}
      name={groupName}
      disabled={disabled}
      option={{
        label: label,
        info: sublabel,
        value: value,
      }}
      onChange={handleChange}
    />
  );
};

export default EcRadioButton;
