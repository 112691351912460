import { useState } from 'react';

import { TableSettings } from '~/types';
import { getStorageItem, setStorageItem } from '~/utils/browser';
import { getStorageKey } from '~/utils/table';

import { useCurrentUser } from './useCurrentUser';

export function useTableSettings(context: string) {
  const user = useCurrentUser();
  const key = getStorageKey(user, context);
  const [tableSettings, setTableSettings] = useState<TableSettings | undefined>(
    getStorageItem(key) ?? undefined,
  );

  const updateTableSettings = (tableSettings: TableSettings) => {
    setStorageItem(key, tableSettings);
    setTableSettings(tableSettings);
  };

  return { tableSettings, updateTableSettings };
}
