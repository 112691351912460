import { useEffect, useMemo, useState } from 'react';

import { useLimitExceededModal } from '~/components/DocumentsPage/DocumentsRestore';
import {
  BULK_COPY_LIMIT,
  BULK_DELETE_LIMIT,
  BULK_EDIT_CLAUSE_LIMIT,
  BULK_EDIT_LIMIT,
  BULK_MOVE_LIMIT,
} from '~/constants/max_lengths';
import { types } from '~/eds';
import { BulkActionType } from '~/enums';
import { DocumentHandler } from '~/redux/api/methods/searchV3';

import {
  DeleteActionPanel,
  EditClausesActionPanel,
  EditFieldsActionPanel,
  MoveCopyActionPanel,
} from './BulkActionPanels';
import { BaseBulkActionProps } from './types';

type BaseDocumentSearchItem = { document_handlers: DocumentHandler[] };
interface Props extends BaseBulkActionProps {
  resultsCache: Record<types.PilotId, BaseDocumentSearchItem>;
  totalCount: number;
  /** this prop will be passed to MoveCopy panel to disable folders containing sync pair. only applicable when
   * moving folders.
   */
  shouldDisableSyncPairedFolders?: boolean;
}

export const useDocumentsBulkActionsPanel = ({
  isAllSelected,
  resultsCache,
  searchAPI,
  selectedDocumentIds = [],
  shouldDisableSyncPairedFolders = false,
  totalCount,
  query,
  onActionCompleted: onActionCompletedParam,
}: Props) => {
  const [activePanel, setActivePanel] = useState<BulkActionType | undefined>();
  const [hasCheckedLimit, setHasCheckedLimit] = useState(false);
  const closePanel = () => {
    setActivePanel(undefined);
    setHasCheckedLimit(false);
  };

  const documentHandlerIds = useMemo(() => {
    //only edit clauses action receives a document id list. The rest of the actions receive a doc handler id list.
    return selectedDocumentIds.reduce((acc, current) => {
      const document = resultsCache[current];
      if (document) {
        return acc.concat(
          document.document_handlers.map(
            (handler) => handler.id as types.PilotId,
          ),
        );
      }
      return acc;
    }, [] as types.PilotId[]);
  }, [selectedDocumentIds, resultsCache]);

  const handleHideLimitExceededModal = () => {
    closeLimitExceededModal();
    closePanel();
  };

  const numberOfAffectedDocuments = isAllSelected
    ? totalCount
    : selectedDocumentIds.length;

  const [
    limitExceededModal,
    showLimitExceededModal,
    closeLimitExceededModal,
  ] = useLimitExceededModal({
    limit: getBulkActionDocumentsLimit(activePanel),
    actionType: activePanel ?? 'copy',
    count: numberOfAffectedDocuments,
    primaryActionHide: handleHideLimitExceededModal,
  });

  const onActionCompleted = () => {
    onActionCompletedParam?.();
    closePanel();
  };

  useEffect(() => {
    if (activePanel) {
      if (
        numberOfAffectedDocuments > getBulkActionDocumentsLimit(activePanel)
      ) {
        showLimitExceededModal();
      } else {
        setHasCheckedLimit(true);
      }
    }
  }, [activePanel, numberOfAffectedDocuments]);

  const baseBulkActionProps: BaseBulkActionProps = {
    isAllSelected,
    onActionCompleted,
    query,
    searchAPI: searchAPI,
    selectedDocumentIds:
      activePanel !== 'edit-clauses' && activePanel !== 'edit'
        ? documentHandlerIds
        : selectedDocumentIds,
  };

  const panelComponent = useMemo(() => {
    switch (activePanel) {
      case 'copy':
      case 'move':
        return (
          <MoveCopyActionPanel
            {...baseBulkActionProps}
            selectedCount={numberOfAffectedDocuments}
            shouldDisableSyncPairedFolders={shouldDisableSyncPairedFolders}
            onHide={closePanel}
            activePanel={activePanel}
          />
        );
      case 'delete':
        return (
          <DeleteActionPanel
            {...baseBulkActionProps}
            onHide={closePanel}
            selectedCount={numberOfAffectedDocuments}
          />
        );
      case 'edit':
        return (
          <EditFieldsActionPanel
            selectedCount={numberOfAffectedDocuments}
            {...baseBulkActionProps}
            onHide={closePanel}
            selectedDocumentHandlerIds={documentHandlerIds}
          />
        );

      case 'edit-clauses':
        return (
          <EditClausesActionPanel
            onHide={closePanel}
            selectedCount={numberOfAffectedDocuments}
            {...baseBulkActionProps}
          />
        );
    }
  }, [activePanel]);

  return {
    panel: activePanel && hasCheckedLimit ? panelComponent : null,
    limitExceededModal,
    setActivePanel,
  };
};

const getBulkActionDocumentsLimit = (bulkAction?: BulkActionType) => {
  switch (bulkAction) {
    case 'copy':
      return BULK_COPY_LIMIT;
    case 'delete':
      return BULK_DELETE_LIMIT;
    case 'edit':
      return BULK_EDIT_LIMIT;
    case 'edit-clauses':
      return BULK_EDIT_CLAUSE_LIMIT;
    case 'move':
      return BULK_MOVE_LIMIT;
    default:
      return Number.POSITIVE_INFINITY;
  }
};
