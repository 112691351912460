import { evisortBlue } from '../../../assets/shared-styles/general';

const backgroundWash = '#F0F0F0';
const border = 'solid 1px rgba(0, 0, 0, 0.1)';
const fontSize = '14px';

export default {
  mentionsInput: {
    highlighter: {
      fontSize,
    },
    input: {
      border: 0,
      fontSize,
      outline: 0,
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
        border,
        maxHeight: '240px',
        overflowY: 'auto',
      },
      item: {
        borderBottom: border,
        fontSize,
        padding: '8px 16px',
        '&focused': {
          background: backgroundWash,
          color: evisortBlue,
        },
      },
    },
  },
  mention: {
    background: backgroundWash,
  },
};
