/**
 * Utility methods in this file bridge internal APIs of react-table with Table's public APIs.
 */

/**
 * @typedef SortBy
 *    Indicates which column is sorted in which direction.
 * @property {string} id
 * @property {boolean} [asc]
 * @property {boolean} [desc]
 *
 * @typedef State
 *    This refers to the state interface exposed by the Table (not react-table), capturing Table state updates useful for managing controlled updates (e.g. server pagination).
 *
 *    It is largely similar to react-table's state defined in InternalState, but is reshaped with an improved public API.
 * @property {string[]} columnOrder array of column keys
 * @property {boolean} isAllRowsSelected
 * @property {number} pageIndex 1-based
 * @property {number} pageSize
 * @property {string[]} selectedRowIds
 * @property {SortBy[]} sortBy
 * @property {number} totalCount
 *
 * @typedef {import('react-table').TableState} InternalState
 *    A type alias referencing react-table's `initialState` interface.
 */

/**
 * Returns the current table data after filtering and applying mappers.  Used in table actions (e.g. export excel, custom actions).
 */
export const getCurrentData = ({ columns, rows, selectedRowIds }) => {
  const testSelectedRow = (row) => row.isSelected;

  const selectedRows = selectedRowIds.length
    ? rows.filter(testSelectedRow)
    : rows;

  return selectedRows.map((row) => {
    const data = row.original;
    return columns.reduce((d, column) => {
      const { key, mapCellValue } = column;
      if (data.hasOwnProperty(key)) {
        const value = mapCellValue ? mapCellValue(data) : data[key];
        d[key] = value;
      }
      return d;
    }, {});
  });
};

/** ID accessor */
export const getRowId = (row) => row.id;

/**
 * Returns the internal react-table props required for controlled state updates.  This method should only be used if Table is used in controlled mode.
 *
 * Please refer to the official react-table documentation for details.
 *
 * @param {object} params
 * @param {string[]} params.rowIds
 * @param {State} params.state
 * @param {number} params.totalCount
 * @returns {InternalState}
 */
export const getControlledStateProps = ({ rowIds, state, totalCount }) => {
  const internalState = toInternalState(state);
  const { isAllRowsSelected, pageSize, selectedRowIds } = internalState;

  // if isAllRowsSelected is true, we prefill and sync the client selectedRowIds (the server has no knowledge of this).
  const prefilledSelectedRowIds = isAllRowsSelected
    ? toSelectedRowIdsMap(rowIds)
    : selectedRowIds;

  return {
    initialState: {
      ...internalState,
      selectedRowIds: prefilledSelectedRowIds,
    },
    manualPagination: true,
    pageCount: Math.ceil(totalCount / pageSize),
  };
};

/**
 * Returns react-table's `InternalState` (i.e. initialState) from the public Table `State`.
 * @param {State} state
 * @returns {InternalState}
 */
export const toInternalState = (state) => {
  const { pageIndex, selectedRowIds = [] } = state;

  return {
    ...state,
    pageIndex: pageIndex - 1, // react-table uses 0-based pageIndex
    selectedRowIds: toSelectedRowIdsMap(selectedRowIds), // react-table uses a boolean object map
  };
};

/**
 * Returns the public Table `State` from react-table's `InternalState`.
 *
 * @param {InternalState} internalState
 * @returns {State}
 */
export const fromInternalState = (internalState) => {
  const {
    columnOrder,
    columnResizing,
    isAllRowsSelected,
    pageIndex,
    pageSize,
    selectedRowIds,
    sortBy,
    totalCount,
  } = internalState;

  return {
    columnOrder,
    columnResizing,
    isAllRowsSelected,
    pageIndex: pageIndex + 1, // Table uses 1-based pageIndex
    pageSize,
    selectedRowIds: fromSelectedRowIdsMap(selectedRowIds), // Table uses an array of strings
    sortBy,
    totalCount,
  };
};

/**
 * Returns an array of `selectedRowIds` from react-table's `selectedRowIdsMap`
 *
 * @param {Object<string, boolean>} selectedRowIdsMap
 * @returns {string[]}
 */
export const fromSelectedRowIdsMap = (selectedRowIdsMap) =>
  Object.keys(selectedRowIdsMap);

/**
 * Returns react-table's `selectedRowIdsMap` from an array of selectedRowIds
 *
 * @param {string[]} selectedRowIds
 * @returns {Object<string, boolean>}
 */
export const toSelectedRowIdsMap = (selectedRowIds) =>
  selectedRowIds.reduce((acc, selectedRowId) => {
    acc[selectedRowId] = true;
    return acc;
  }, {});

/**
 * Returns the primary (first) column key.  Returns undefined if columns is an empty array.
 * @param {object[]} columns
 * @returns {string | undefined}
 */
export const getPrimaryColumnKey = (columns) => columns[0]?.key;
