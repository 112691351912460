export enum WorkflowFieldType {
  Address = 'ADDRESS',
  Boolean = 'BOOLEAN',
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  DateSigned = 'DATE_SIGNED',
  Department = 'DEPARTMENT',
  Email = 'EMAIL',
  File = 'FILE',
  Initials = 'INITIALS',
  LongResponse = 'LONG_RESPONSE',
  MonetaryValue = 'MONETARY_VALUE',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Radio = 'RADIO',
  ShortResponse = 'SHORT_RESPONSE',
  Signature = 'SIGNATURE',
  SingleSelect = 'SINGLE_SELECT',
  TimePeriod = 'TIME_PERIOD',
  User = 'USER',
  ConditionalText = 'CONDITIONAL_TEXT',
}
