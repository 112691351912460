import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { usePollingRequest } from '~/hooks';
import { api } from '~/redux';
import { COMMENT_TAG } from '~/redux/api/endpoints/comments';
import { Uuid } from '~/types';
import { ERROR } from '~/types/toast.types';

interface Props {
  versionId: Uuid;
  documentId: Uuid;
  skip: boolean;
}

export function useDocumentCommentImportStatus({
  versionId,
  documentId,
  skip,
}: Props) {
  const dispatch = useDispatch();

  const {
    data: commentImportStatus,
    isPollingFinishedByMaxRetries: isCommentImportStatusPollingFinished,
    stopPolling,
  } = usePollingRequest(
    api.endpoints.getCommentImportStatus.useQuery,
    { documentId, versionId },
    {
      skip: skip || !versionId || !documentId,
      maxRetries: 12,
      pollingInterval: 10000,
    },
  );

  useEffect(() => {
    if (commentImportStatus) {
      if (commentImportStatus.status === 'SUCCESSFUL') {
        dispatch(api.util.invalidateTags([COMMENT_TAG]));
        stopPolling();
      } else if (commentImportStatus.status === 'FAILED') {
        stopPolling();
        showToast(
          ERROR,
          'Document comments extraction failed, please refresh the page to retry. If the error persists, please contact support.',
        );
      }
    }
  }, [commentImportStatus]);

  useEffect(() => {
    if (isCommentImportStatusPollingFinished) {
      showToast(
        ERROR,
        'Document comments are not ready yet, please refresh the page. If the error persists, please contact support.',
      );
    }
  }, [isCommentImportStatusPollingFinished]);
}
