import React from 'react';

import { Fields } from '~/components/SearchV2/DetailsPanel/Fields';
import { Panel_DEPRECATED, types } from '~/eds';
import { api } from '~/redux';
import { SearchDocumentItem } from '~/redux/api/methods/searchV3';

type Props = {
  onHide: () => void;
  document?: SearchDocumentItem;
  actions?: types.Action<unknown>[];
};

export const DetailsPanel = ({ onHide, document, actions }: Props) => {
  if (!document) {
    return null;
  }
  const handler = document.document_handlers[0];
  api.endpoints.getDocumentInformation.useQuery(handler.id);

  const tabs = [
    {
      label: 'Fields',
      value: 'fields',
      panel: <Fields handlerId={handler.id} documentId={document.id} />,
    },
  ];

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      title={handler.document_name}
      actions={actions}
      isVisible={true}
      onHide={onHide}
      tabs={tabs}
    />
  );
};
