import {
  black1,
  black2,
  black3,
  black4,
  bold,
  evisortYellow,
  semibold,
} from '../../assets/shared-styles/general';

const fieldTokenControl = {
  cursor: 'pointer',
  '&:last-child': {
    marginLeft: '5px',
  },
};

const styles = {
  reviewerViewPage: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  reviewerHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '36px',
  },
  reviewerBackAction: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    cursor: 'pointer',
  },
  reviewerPageTitle: {
    flexGrow: 1,
  },
  title: {
    margin: 0,
    color: black2,
    fontSize: '32px',
  },
  subTitle: {
    color: black4,
    fontSize: '13px',
    fontWeight: semibold,
    letterSpacing: '1px',
  },
  headerMetaInfo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    '& svg': {
      marginRight: '8px',
    },
    '& div': {
      color: black2,
    },
    '& span': {
      color: black1,
      fontWeight: bold,
    },
  },
  reviewerContentsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '32px',
  },
  documentNameWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingRight: '32px',
    '& svg': {
      flexShrink: 0,
    },
  },
  documentNameDetails: {
    margin: '0 0 0 8px',
    '& h4': {
      margin: 0,
      fontSize: '21px',
      fontWeight: bold,
    },
    '& div': {
      marginTop: '4px',
      color: black2,
      fontSize: '15px',
    },
    '& span': {
      fontWeight: bold,
    },
  },
  reviewerActions: {
    display: 'flex',
    alignItems: 'center',
  },
  verticalSeparator: {
    width: '16px',
  },
  reviewerContentsWrapper: {
    display: 'flex',
  },
  documentViewerWrapper: {
    flexGrow: 1,
    minWidth: 0,
    position: 'relative',
  },
  loadingContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  reviewerContentSideInfo: {
    flexShrink: 0,
    width: '288px',
    marginLeft: '40px',
    paddingRight: '20px',
    position: 'relative',
  },
  flagWrapper: {
    textAlign: 'center',
  },
  metaAbout: {
    color: black3,
    fontSize: '14px',
    textAlign: 'center',
    '& span': {
      color: black2,
      fontWeight: bold,
    },
  },
  metadataSection: {
    marginTop: '30px',
  },
  metadataSectionHeader: {
    marginBottom: '16px',
    color: black2,
    fontSize: '12px',
    fontWeight: semibold,
  },
  metadataReason: {
    margin: '16px 0',
    color: black1,
    fontSize: '14px',
    fontWeight: bold,
  },
  metadataAdditional: {
    color: black2,
    fontSize: '14px',
    overflowX: 'scroll',
  },
  horizontalSeparator: {
    margin: '24px 0',
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  fieldTokenWrapper: {
    padding: '0 10px 0 20px',
    display: 'flex',
    alignItems: 'center',
    height: '34px',
    top: '80px',
    right: '-4%',
    backgroundColor: evisortYellow,
    borderRadius: '26px',
    boxShadow: '0 2px 4px 0 rgba(17, 17, 17, 0.15)',
    position: 'absolute',
    transform: 'translate(-50%)',
    zIndex: '5',
  },
  fieldTokenName: {
    marginRight: '10px',
    display: 'inline-flex',
    fontSize: '12px',
    fontWeight: semibold,
    textTransform: 'uppercase',
  },
  fieldTokenControlButtons: {
    display: 'inline-flex',
  },
  fieldTokenControlEnabled: {
    ...fieldTokenControl,
  },
  fieldTokenControlDisabled: {
    ...fieldTokenControl,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    opacity: 0.3,
  },
  closeFieldTokenHightlightButtons: {
    position: 'absolute',
    top: '-9px',
    right: '-9px',
    width: '19px',
    height: '19px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#000',
    borderRadius: '30px',
    border: '1px solid #fff',
    cursor: 'pointer',
  },
};

export default styles;
