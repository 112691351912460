import React, { useMemo, useState } from 'react';

import { HtmlEntityType } from '../../enums';
import { Nullable } from '../../types';
import { Button } from '../Button';
import { IconButton } from '../IconButton';

interface Props {
  /** Limit the number of visible items */
  limit: number;
  /** Source text */
  text: string;
  /** Indicates if Show More / Show Less should display as text or an icon button */
  mode?: 'text' | 'icon';
  /** Override the label for "Show less" action */
  showLessText?: string;
  /** Override the label for "Show more" action */
  showMoreText?: string;
  /** Controls the number of items to reveal (reveals all items by default) */
  step?: number;
}

/**
 *
 * @param params receives a `limit` and a `text` and will show it when `showMore` is triggered.
 * The amount of initial text shown is decided by `limit` and the amount of text shown after trigger
 * `showMore` is decided by `step`. If step is not assigned, it will use the `text.length`
 * @returns [`visibleText`, `showMore/showLess`]
 */
export const useShowMoreText = ({
  text,
  limit: initialLimit,
  mode = 'text',
  step = text.length,
  showLessText = 'Show less',
  showMoreText = 'Show more',
}: Props): {
  showMore: Nullable<JSX.Element>;
  shouldShowMore: boolean;
  visibleText: string;
  handleShowMore: () => void;
  handleShowLess: () => void;
} => {
  const [limit, setLimit] = useState(initialLimit);
  const hasHiddenText = text.length > initialLimit;
  const hiddenText = text.slice(limit);
  const shouldShowMore = hiddenText.length > 0;
  const visibleText =
    text.slice(0, limit) +
    (shouldShowMore ? ` [${HtmlEntityType.Ellipsis}]` : '');
  const handleShowMore = () => setLimit((prevLimit) => prevLimit + step);
  const handleShowLess = () => setLimit(initialLimit);

  const showMore = useMemo(() => {
    if (hasHiddenText) {
      return mode === 'icon' ? (
        <IconButton
          preset={shouldShowMore ? 'show' : 'hide'}
          onClick={shouldShowMore ? handleShowMore : handleShowLess}
        />
      ) : (
        <Button
          isInline
          text={shouldShowMore ? showMoreText : showLessText}
          variant="action"
          onClick={shouldShowMore ? handleShowMore : handleShowLess}
        />
      );
    }
    return null;
  }, [hasHiddenText, shouldShowMore]);

  return {
    showMore,
    shouldShowMore,
    visibleText,
    handleShowMore,
    handleShowLess,
  };
};
