import PT from 'prop-types';
import React from 'react';
import InlineSvg from 'react-inlinesvg';

import { Box } from '~/ui';
import loadingSpinner from '~/ui/assets/icons/loading-spinner.svg';

const sizes = {
  s: '24px',
  m: '48px',
  l: '72px',
};

function LoadingSpinner({ size = 'm' }) {
  const spinnerSize = sizes[size] || sizes.m;

  return (
    <Box color="blue-500" sx={{ height: spinnerSize, width: spinnerSize }}>
      <InlineSvg height="100%" src={loadingSpinner} width="100%" />
    </Box>
  );
}

LoadingSpinner.propTypes = {
  /** Spinner size */
  size: PT.oneOf(['s', 'm', 'l']),
};

export default LoadingSpinner;
