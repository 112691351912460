import get from 'lodash/get';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddToGroupStepType, HttpStatusCodeType } from '~/enums';
import { api, selectors } from '~/redux';
import search from '~/redux/slices/search';
import { MODAL_DOCUMENT_GROUP } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { LoadingContainer, WizardPageLayout } from '~/ui';

import { showToast } from '../../Shared/EcToast';
import SelectDocumentInGroup from './SelectDocumentInGroup';
import SelectGroup from './SelectGroup';

const MAX_TITLE_WIDTH = 355;

const AddSearchResultsToGroup = () => {
  const modal = useSelector(selectors.selectSearchResultModal);
  const step = useSelector(selectors.selectSearchCurrentAddToGroupStep);

  const selectedGroup = useSelector(selectors.selectSearchSelectedGroup);
  const selectedDocument = useSelector(selectors.selectSearchSelectedDocument);

  const dispatch = useDispatch();
  const pilotSearchQuery = useSelector(selectors.selectPilotSearchQuery);
  const { modalType, context } = modal || { context: {} };

  const { selectedRowIds, isAllRowsSelected: isAllSelected } = context;

  const [
    addDocumentsToGroup,
    addDocumentsToGroupResult,
  ] = api.endpoints.addDocumentsToGroup.useMutation();
  const {
    isError: addDocumentsToGroupError,
    isSuccess: addDocumentsToGroupSuccess,
    isLoading: addDocumentsToGroupLoading,
    error: addDocumentsToGroupResponseError,
  } = addDocumentsToGroupResult;

  const [
    addDocumentsToGroupLinkParent,
    addDocumentsToGroupLinkParentResult,
  ] = api.endpoints.addDocumentsToGroupLinkParent.useMutation();
  const {
    isError: addDocumentsToGroupLinkParentError,
    isSuccess: addDocumentsToGroupLinkParentSuccess,
    isLoading: addDocumentsToGroupLinkParentLoading,
    error: addDocumentsToGroupLinkParentResponseError,
  } = addDocumentsToGroupLinkParentResult;

  const [
    createDocumentGroup,
    createDocumentGroupResult,
  ] = api.endpoints.createDocumentGroup.useMutation();
  const {
    isError: createDocumentGroupError,
    isSuccess: createDocumentGroupSuccess,
    isLoading: createDocumentGroupLoading,
    error: createDocumentGroupResponseError,
  } = createDocumentGroupResult;

  const resetModal = () => {
    dispatch(search.actions.setModal());
    dispatch(search.actions.setCurrentAddToGroupStep(AddToGroupStepType.GROUP));
    dispatch(search.actions.setSelectedDocument(undefined));
  };

  useEffect(() => {
    return () => {
      resetModal();
    };
  }, []);

  useEffect(() => {
    if (
      addDocumentsToGroupSuccess ||
      addDocumentsToGroupLinkParentSuccess ||
      createDocumentGroupSuccess
    ) {
      showToast(SUCCESS, 'Documents have been added to the group successfully');
      resetModal();
    }
  }, [
    addDocumentsToGroupSuccess,
    addDocumentsToGroupLinkParentSuccess,
    createDocumentGroupSuccess,
  ]);

  useEffect(() => {
    if (
      addDocumentsToGroupError ||
      addDocumentsToGroupLinkParentError ||
      createDocumentGroupError
    ) {
      const error =
        addDocumentsToGroupResponseError ||
        addDocumentsToGroupLinkParentResponseError ||
        createDocumentGroupResponseError;
      const { response } = error;
      const defaultErrorMessage =
        'An error occurred while adding documents to the group.';
      if (response?.status === HttpStatusCodeType.BadRequest) {
        showToast(ERROR, get(response, 'data.message', defaultErrorMessage));
      } else {
        showToast(ERROR, defaultErrorMessage);
      }
      resetModal();
    }
  }, [
    addDocumentsToGroupError,
    addDocumentsToGroupLinkParentError,
    createDocumentGroupError,
  ]);

  const handleSelectGroup = useCallback((groupId) => {
    dispatch(
      search.actions.setCurrentAddToGroupStep(AddToGroupStepType.STRUCTURE),
    );
    dispatch(search.actions.setSelectedGroup(groupId));
  }, []);

  const handleNewGroupCreated = ({ name, note }) => {
    const documentIds = isAllSelected ? undefined : selectedRowIds;
    const query = isAllSelected ? pilotSearchQuery : undefined;
    createDocumentGroup({ name, note, documents: documentIds, query });
  };

  const handleSelectedDocument = useCallback((documentId) => {
    dispatch(search.actions.setSelectedDocument(documentId));
  }, []);

  const handleAddDocumentsToGroup = () => {
    const documentIds = isAllSelected ? undefined : selectedRowIds;
    const query = isAllSelected ? pilotSearchQuery : undefined;
    if (selectedDocument) {
      addDocumentsToGroupLinkParent({
        groupId: selectedGroup,
        parentId: selectedDocument,
        documentIds,
        query,
      });
    } else {
      addDocumentsToGroup({ groupId: selectedGroup, documentIds, query });
    }
    dispatch(search.actions.setSelectedDocument(undefined));
    dispatch(search.actions.setSelectedGroup(undefined));
  };

  const steps = useMemo(() => {
    return {
      [AddToGroupStepType.GROUP]: {
        title: 'Adding document to group',
        component: (
          <SelectGroup
            onSelectGroup={handleSelectGroup}
            onNewGroupCreated={handleNewGroupCreated}
          />
        ),
        action: {
          promise: () => resetModal(),
          text: 'Cancel',
          variant: 'ghost',
        },
      },
      [AddToGroupStepType.STRUCTURE]: {
        title: 'Add to group',
        component: (
          <SelectDocumentInGroup
            group={selectedGroup}
            onDocumentSelected={handleSelectedDocument}
          />
        ),
        action: {
          text: 'Add to group',
        },
      },
    };
  }, [selectedGroup, isAllSelected]);

  const isLoading =
    addDocumentsToGroupLoading ||
    addDocumentsToGroupLinkParentLoading ||
    createDocumentGroupLoading;

  if (modalType !== MODAL_DOCUMENT_GROUP) return null;

  const buttonTitle = selectedDocument
    ? 'Add to group as linked documents'
    : steps[step].action.text;
  const handleActionPromise =
    step === AddToGroupStepType.STRUCTURE
      ? handleAddDocumentsToGroup
      : steps[step].action.promise;
  return (
    <WizardPageLayout
      currentStep={step}
      steps={[AddToGroupStepType.GROUP, AddToGroupStepType.STRUCTURE]}
      title={{ text: steps[step].title, maxWidth: MAX_TITLE_WIDTH }}
      onClose={resetModal}
      actionButton={{
        ...steps[step].action,
        text: buttonTitle,
        promise: handleActionPromise,
      }}
      isContentFill={true}
    >
      <LoadingContainer isLoading={isLoading}>
        {steps[step].component}
      </LoadingContainer>
    </WizardPageLayout>
  );
};

export default AddSearchResultsToGroup;
