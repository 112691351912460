import { noop, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { PAGE_SIZE } from '~/constants/page';
import { UserDepartmentSelect as EdsUserDepartmentSelect, types } from '~/eds';
import { EntityType, WorkflowTicketRoleType } from '~/enums';
import { withClient } from '~/hocs';
import { useDebouncedCallback } from '~/hooks';
import { api, coerceRtkqError } from '~/redux';
import {
  DepartmentV2,
  DEPRECATED_Client,
  NormalizedDepartment,
  NormalizedDepartments,
  PilotId,
} from '~/types';
import { normalizeDepartmentTree } from '~/utils/departments';

type DepartmentValue = {
  id: number;
  type: EntityType.Department;
  role: null;
};

type UserValue = {
  id: number;
  type: EntityType.User;
  role: null;
};

type RoleValue = {
  id: null;
  type: EntityType.User;
  role: WorkflowTicketRoleType;
};

type UserDepartmentSelectValue = DepartmentValue | RoleValue | UserValue;

type Role = WorkflowTicketRoleType;

type OptionsEntityTypes =
  | EntityType.User
  | EntityType.Department
  | EntityType.Role;

interface Props {
  value: Array<UserDepartmentSelectValue>;
  roles?: types.Option<Role>[];
  enablePortal?: boolean;
  error?: string;
  onChange: (values: Array<UserDepartmentSelectValue> | null) => void;
  // connected
  client: DEPRECATED_Client;
}

const initialUserSearchParams = {
  query: '',
  pageSize: PAGE_SIZE,
};

const UserDepartmentSelect = ({
  client,
  enablePortal,
  error,
  roles = [],
  value,
  onChange = noop,
}: Props) => {
  const departments: NormalizedDepartments = useMemo(
    () =>
      normalizeDepartmentTree(client.departmentTree) as NormalizedDepartments,
    [client.departmentTree],
  );

  const [
    findUsers,
    usersResult,
  ] = api.endpoints.findUsersByNameOrEmail.useLazyQuery();
  const { error: fetchUsersError, isFetching: isFetchingUsers } = usersResult;
  const [
    findDepartments,
    departmentsResult,
  ] = api.endpoints.findDepartmentsByName.useLazyQuery();
  const {
    error: fetchDepartmentsError,
    isFetching: isFetchingDepartments,
  } = departmentsResult;
  const [
    resolveUsers,
    { data: resolvedUsersData, isFetching: isFetchingResolvedUsers },
  ] = api.endpoints.resolveUsers.useLazyQuery();
  const initialDepartmentSearchParams = useMemo(
    () =>
      client.id
        ? { clientId: client.id, pageSize: PAGE_SIZE, query: '' }
        : null,
    [client],
  );

  const debouncedFindUsers = useDebouncedCallback(
    findUsers,
    initialUserSearchParams,
  );
  const debouncedFindDepartments = useDebouncedCallback(
    findDepartments,
    initialDepartmentSearchParams,
  );

  const fetchedUsers = usersResult?.data?.results || [];
  const fetchedDepartments = departmentsResult?.data?.results || [];

  const onChangeValues = (values: Array<types.PilotId> | null) => {
    const parsedUserDepartments = (values || []).map(transfromValuesToData);
    onChange(parsedUserDepartments);
  };

  // Remove deleted items from values and transform into MultiSelect ids
  const preloadedValues = useMemo(
    () =>
      value
        .filter((item) => {
          if (item.role) {
            return roles.some((role) => role.value === item.role);
          }

          if (item.type === EntityType.Department) {
            return item.id && !!departments[item.id];
          }

          return true;
        })
        .map(transformDataToValues),
    [value],
  );
  const [preloadedDepartments, setPreloadedDepartments] = useState<
    Array<DepartmentV2>
  >([]);
  const [preloadedUsers, setPreloadedUsers] = useState<Array<types.User>>([]);

  useEffect(() => {
    const departmentOptions: Array<DepartmentV2> = [];
    const usersToResolve: Array<number> = [];
    value.forEach((val: UserDepartmentSelectValue) => {
      if (val.type === EntityType.User) {
        if (val.id) {
          usersToResolve.push(val.id);
        }
      } else {
        if (val.id && departments[val.id]) {
          departmentOptions.push(
            transfromDepartmentsToOptions(departments[val.id], departments),
          );
        }
      }
    });
    usersToResolve.length &&
      resolveUsers({
        ids: usersToResolve,
        params: { includeDeleted: true, clientId: client.id },
      });
    setPreloadedDepartments(departmentOptions);
  }, [value, departments]);

  useEffect(() => {
    resolvedUsersData && setPreloadedUsers(Object.values(resolvedUsersData));
  }, [resolvedUsersData]);

  const allUsers = useMemo(
    () =>
      uniqBy([...fetchedUsers, ...preloadedUsers], 'id').map((val) =>
        formatID(val, EntityType.User),
      ) as Array<types.User>,
    [fetchedUsers, preloadedUsers],
  );
  const allDepartments = useMemo(
    () =>
      uniqBy(
        [...fetchedDepartments, ...preloadedDepartments],
        'id',
      ).map((val) =>
        formatID(val, EntityType.Department),
      ) as Array<DepartmentV2>,
    [fetchedDepartments, preloadedDepartments],
  );

  const parsedRoles = useMemo(
    () =>
      roles.map((role) => ({
        ...role,
        value: `${EntityType.Role}-${role.value}`,
      })) as types.Option<string>[],
    [roles],
  );

  return (
    <EdsUserDepartmentSelect
      name="User Department Multi Select"
      placeholder="Specify one or more departments or users..."
      isMulti
      isLoading={
        isFetchingUsers ||
        isFetchingDepartments ||
        isFetchingResolvedUsers ||
        !client.id
      }
      users={allUsers}
      roles={parsedRoles}
      departments={allDepartments}
      value={preloadedValues}
      onChange={onChangeValues}
      enablePortal={enablePortal}
      error={
        coerceRtkqError(fetchUsersError) ||
        coerceRtkqError(fetchDepartmentsError) ||
        error
      }
      width="100%"
      onSearch={(query: string) => {
        debouncedFindUsers({ query, pageSize: PAGE_SIZE });
        debouncedFindDepartments({
          query,
          pageSize: PAGE_SIZE,
          clientId: client.id,
        });
      }}
    />
  );
};

export const formatID = (
  value: DepartmentV2 | types.User,
  type: EntityType,
) => {
  return {
    ...value,
    id: `${type}-${value.id}`,
  };
};

export const transformDataToValues = (
  data: UserDepartmentSelectValue,
): string => {
  if (data.role) {
    return `${EntityType.Role}-${data.role}`;
  }
  return `${data.type}-${data.id}`;
};

export const transfromValuesToData = (
  combinedValue: types.PilotId,
): UserDepartmentSelectValue => {
  const [type, value] = String(combinedValue).split('-', 2) as [
    OptionsEntityTypes,
    WorkflowTicketRoleType | string,
  ];
  switch (type) {
    case EntityType.Role:
      return {
        id: null,
        type: EntityType.User,
        role: value as WorkflowTicketRoleType,
      };
    case EntityType.Department:
    case EntityType.User:
      return {
        id: Number(value),
        type: type,
        role: null,
      };
    default:
      return { id: null, role: null, type: null } as any;
  }
};

export const transfromDepartmentsToOptions = (
  value: NormalizedDepartment,
  departments: NormalizedDepartments,
): DepartmentV2 => {
  return {
    id: value.id,
    name: value.name,
    parent: value.position,
    path: value.parentIds.map((id) => departments[id].name),
    members: [],
    members_count: 0,
  };
};

export const transformDeletedDepartmentToOption = (
  id: PilotId,
): DepartmentV2 => {
  return {
    id: id,
    name: 'Removed department',
    parent: null,
    path: [],
    members: [],
    members_count: 0,
  };
};

export default withClient(UserDepartmentSelect);
