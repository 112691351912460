import { black4, bold, semibold } from '../../../assets/shared-styles/general';

const styles = {
  provisionsWrapper: {
    marginTop: '16px',
  },
  provisionsEmptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '117px',
    border: 'solid 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    backgroundColor: '#fafafa',
    color: black4,
    fontSize: '16px',
    boxShadow: '0 2px 4px 0 rgba(17, 17, 17, 0.05)',
  },
  provisionsTable: {
    width: '100%',
    border: 'solid 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    borderSpacing: 0,
    backgroundColor: '#fafafa',
    textAlign: 'left',
    boxShadow: '0 2px 4px 0 rgba(17, 17, 17, 0.05)',
    '& thead': {
      display: 'block',
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
      '& th': {
        padding: '8px 12px',
        fontSize: '13px',
        fontWeight: bold,
      },
    },
    '& tbody': {
      display: 'block',
      maxHeight: '560px',
      fontSize: '14px',
      overflowY: 'scroll',
      '& td': {
        display: 'table-cell',
        padding: '8px 12px',
      },
      '& tr:not(:last-child) td': {
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
      },
    },
  },
  provisionsHeaderTitle: {
    width: '150px',
  },
  provisionsBodyTitle: {
    width: '150px',
    verticalAlign: 'top',
    fontWeight: semibold,
    wordBreak: 'break-word',
  },
  provisionsBodyText: {
    '& p': {
      marginBlockStart: 0,
      marginBottom: '20px',
      paddingBottom: '20px',
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    '& p:last-child': {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: 0,
    },
  },
};

export default styles;
