import { Field, FieldGroup, FieldGroupConfig, FieldKey } from './types';

const sortFields = (
  a: Field | FieldGroup | FieldGroupConfig,
  b: Field | FieldGroup | FieldGroupConfig,
) => {
  if (a.order && b.order) {
    return a.order - b.order;
  } else if (a.order) {
    return -1;
  } else if (b.order) {
    return 1;
  }
  return 0;
};

export const prepareFields = (fields: Record<FieldKey, Field | FieldGroup>) => {
  return Object.entries(fields).reduce((prev, [key, field]) => {
    if (field.type === 'group') {
      return {
        ...prev,
        ...(field as FieldGroup).fields,
      };
    }
    return {
      ...prev,
      [key]: field,
    };
  }, {});
};

export const orderFields = (
  fields: Record<FieldKey, Field | FieldGroup>,
): (Field | FieldGroupConfig)[] => {
  return (
    Object.entries(fields)
      // transform the group fields into an array of fields
      .map(([key, field]) => {
        if (field.type === 'group') {
          return {
            key,
            ...field,
            fields: Object.entries(field.fields)
              .map(([key, field]) => ({ key, ...field }))
              .sort(sortFields),
          };
        }
        return {
          key,
          ...field,
        };
      })
      .sort((a, b) => {
        return sortFields(a, b);
      })
  );
};
