export const CELL_PX = 3;
export const CELL_PY = 1;
export const CELL_SELECT_WIDTH = 48;
export const CELL_STICKY_ZINDEX = 1;
export const CELL_ALIGNMENTS = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};
export const CELL_WIDTHS = {
  xs: 40,
  s: 120,
  m: 200,
  l: 300,
  xl: 600,
};

export const ROW_DEFAULT_HEIGHT = 48;
export const ROW_DETAILS_CLASSNAME = 'eds__table__row__details';
export const ROW_STICKY_ZINDEX = CELL_STICKY_ZINDEX + 1;

export const PAGE_SIZE_LIST = [10, 20, 30, 50, 100];

// TODO: these are generated via ~/constants/testIds.  EDS should avoid them entirely in favor of role-based selectors.  Confirm we no longer use testIds in E2E tests and remove them
export const UI_TABLE_EXPORT_EXCEL_ICON_TESTID = 'ui/table__export-excel-icon';
export const UI_TABLE_ROW_ACTION_ICON_TESTID = 'ui/table__row-action-icon';
export const UI_TABLE_SELECT_CELL_TESTID = 'ui/table__select-cell';

export const DEFAULT_OPTIONS = {
  enableColumnHeaders: true,
  enableControls: true,
  enableExportXlsx: true,
  enablePageSizeSelect: false,
  enablePagination: true,
  enableManageColumns: true,
  enableSearch: false,
  enableSelectRows: true,
  enableEmptyState: false,
  isMultiSelectRows: true,
  pageSizes: PAGE_SIZE_LIST,
};

export const LOADING_ROWS_LENGTH = 10;
