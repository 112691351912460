import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.SmartClause, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.SmartClause as TagType],
  endpoints: {
    getFolderTree: {
      providesTags: [TAG_BY_LIST],
    },
    getTrainingStatus: {
      providesTags: [TAG_BY_LIST],
    },
    getFiltersData: {
      providesTags: [TAG_BY_LIST],
    },
    updateTrainingLabels: {
      invalidatesTags: [TAG_BY_LIST],
    },
    createRetraining: {
      invalidatesTags: [TAG_BY_LIST],
    },
    publishTrainedModel: {
      invalidatesTags: [TAG_BY_LIST],
    },
    updatePredictionFilters: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deleteTrainingModel: {
      invalidatesTags: [TAG_BY_LIST],
    },
  },
});
