import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import injectSheet from 'react-jss';

import { setUserFoldersPermission } from '~/api';
import { black4 } from '~/assets/shared-styles/general';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import WarningIcon from '~/components/Shared/Icons/WarningIcon';

function PermissionContentContainer({ classes, children, title }) {
  return (
    <div className={classes.folderPermissionContainer}>
      {title}
      <div className={classes.folderPermissionIcon}>{children}</div>
    </div>
  );
}

function Page(props) {
  const { classes } = props;

  const [error, setError] = useState(false);

  useEffect(() => {
    checkFoldersPermission();
  });

  const checkFoldersPermission = () => {
    setUserFoldersPermission()
      .then(() => {
        window.location.href = '/';
      })
      .catch(() => setError(true));
  };

  return (
    <div className={classes.folderPermissionCheckPage}>
      {error ? (
        <PermissionContentContainer
          classes={classes}
          title="Failed to recover permissions. Please contact your administrator."
        >
          <WarningIcon size="50" color={black4} />
        </PermissionContentContainer>
      ) : (
        <PermissionContentContainer
          classes={classes}
          title="Setting permissions, this might take a minute."
        >
          <LoadingSpinner size="medium" />
        </PermissionContentContainer>
      )}
    </div>
  );
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  folderPermissionCheckPage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, .4)',
    zIndex: 5,
  },
  folderPermissionContainer: {
    position: 'absolute',
    top: '45%',
    width: '100%',
    textAlign: 'center',
    color: black4,
  },
  folderPermissionIcon: {
    marginTop: '20px',
  },
};

export default injectSheet(styles)(Page);
