import { ERROR, WARNING } from '../../../types/toast.types';

export const errorTooltip = {
  message: 'Invalid value for date field.',
  type: ERROR,
};

export const warningTooltip = {
  message: 'This value seems to be too far out.',
  type: WARNING,
};
