export const preprocessFieldValue = (fieldValue) => {
  if (fieldValue === null) return null;

  if (fieldValue instanceof Array) {
    return fieldValue.map((values) => values.value);
  } else if (fieldValue.value === '') {
    return null;
  }

  return fieldValue.value;
};
