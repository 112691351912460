import { useDataFieldSection } from '~/hooks';
import { AllDataFields } from '~/types';

interface Options {
  allDataFields: AllDataFields;
  isLoading: boolean;
}

export function withGetDataFieldSections(
  WrappedComponent: React.ComponentType<Options>,
) {
  function FieldSetionsDataContainer({ ...rest }) {
    const { isFetching, allDataFields } = useDataFieldSection();
    return (
      <WrappedComponent
        {...rest}
        isLoading={isFetching}
        allDataFields={allDataFields}
      />
    );
  }

  return FieldSetionsDataContainer;
}
