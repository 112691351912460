import { createSelector } from '@reduxjs/toolkit';

import { DocumentGroupSlice, name } from '../slices/documentGroup';

const selectSlice = (rootState: any): DocumentGroupSlice => rootState[name];

export const selectColumnOrder = createSelector(selectSlice, (state) =>
  state.columnOrder.map(String),
);
export const selectColumnWidths = createSelector(
  selectSlice,
  (state) => state.columnWidths,
);
export const selectQueryFields = createSelector(
  selectSlice,
  (state) => state.queryFields,
);
export const selectModal = createSelector(selectSlice, (state) => state.modal);
export const selectSelectedDocumentIds = createSelector(
  selectSlice,
  (state) => state.selectedDocumentIds,
);
export const selectSelectedDocumentId = createSelector(
  selectSlice,
  (state) => state.selectedDocumentId,
);
export const selectAddToGroupStep = createSelector(
  selectSlice,
  (state) => state.currentAddToGroupStep,
);
export const selectCurrentTableView = createSelector(
  selectSlice,
  (state) => state.currentTableView,
);
