import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import styles from './EcInput.styles';

const EcInput = ({
  ariaLabel,
  classes,
  error = false,
  id,
  input,
  isDisabled = false,
  max,
  min,
  placeholder,
  step,
  type = 'text',
}) => {
  return (
    <input
      {...(input && input)}
      aria-label={ariaLabel}
      className={classnames(classes.ecInput, { [classes.ecInputError]: error })}
      id={id}
      type={type}
      placeholder={placeholder}
      {...(min && { min })}
      {...(max && { max })}
      {...(step && { step })}
      disabled={isDisabled}
    />
  );
};

EcInput.propTypes = {
  ariaLabel: PropTypes.string,
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  isDisabled: PropTypes.bool,
  max: PropTypes.string,
  min: PropTypes.string,
  placeholder: PropTypes.string,
  step: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
};

export default injectSheet(styles)(EcInput);
