import { Attempt } from '~/features/webhooks';
import { courier } from '~/services';

interface Request {
  attempt: Attempt;
}

export const resendAttempt = async ({ attempt }: Request): Promise<boolean> => {
  const response = await courier.post(
    //TODO: BE API TBD
    `/delivery-attempts/${attempt.id}/resend`,
  );
  return Boolean(response);
};
