import React, { useMemo, useState } from 'react';

import { WizardModal } from '~/eds';
import { FormSection, isFieldValid } from '~/features/sso-settings';
import { FormSection as FormSectionType } from '~/types';

type Props = {
  clientId: string;
  sections: FormSectionType[];
  onHide: () => void;
  onSubmit: () => void;
};

export const SAMLConfigWizard = ({
  clientId,
  sections,
  onHide,
  onSubmit,
}: Props) => {
  const [activeStepId, setActiveStepId] = useState(sections[0].id);
  const [activeInstructionId, setActiveInstructionId] = useState<string>();
  const [lastCompletedStepId, setLastCompletedStepId] = useState<string>();
  const activeIndex = useMemo(
    () => sections.findIndex((step) => step.id === activeStepId),
    [activeStepId],
  );
  const currentSection = useMemo(() => sections[activeIndex], [
    activeIndex,
    sections,
  ]);

  const isSectionValid = useMemo(
    () => currentSection.configs.every((formField) => isFieldValid(formField)),
    [currentSection],
  );

  const handleNext = () => {
    if (activeIndex === sections.length - 1) {
      onSubmit();
      return;
    }

    if (isSectionValid) {
      setLastCompletedStepId(activeStepId);
      setActiveStepId(sections[activeIndex + 1].id);
    }
  };

  const handleBack = () => {
    if (activeIndex === 0) return;
    setActiveStepId(sections[activeIndex - 1].id);
  };

  const handleSelectStep = (stepId: string) => {
    const stepIndex = sections.findIndex((step) => step.id === stepId);
    const lastCompletedStepIdIndex = sections.findIndex(
      (step) => step.id === lastCompletedStepId,
    );
    if (stepIndex < activeIndex || stepIndex <= lastCompletedStepIdIndex) {
      setActiveStepId(stepId);
    }
  };

  return (
    <WizardModal
      title="Configure SAML SSO"
      isVisible={true}
      backAction={{ text: 'Back', onClick: handleBack }}
      steps={{
        activeInstructionId,
        activeStepId,
        lastCompletedStepId,
        steps: sections,
        onSelectStep: handleSelectStep,
        title: 'Steps',
      }}
      primaryAction={{
        text: activeIndex === sections.length - 1 ? 'Submit' : 'Continue',
        onClick: handleNext,
        disabled: !isSectionValid,
      }}
      onHide={onHide}
    >
      <FormSection
        clientId={clientId}
        section={currentSection}
        onExplain={setActiveInstructionId}
      />
    </WizardModal>
  );
};
