import PropTypes from 'prop-types';
import React from 'react';

const FolderLookUpIcon = (props) => {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon points="0 0 32 0 32 32 0 32" />
          <path
            d="M13.4,6 L5.6,6 C4.17,6 3.013,7.18125 3.013,8.625 L3,24.375 C3,25.81875 4.17,27 5.6,27 L26.4,27 C27.83,27 29,25.81875 29,24.375 L29,11.25 C29,9.80625 27.83,8.625 26.4,8.625 L16,8.625 L13.4,6 Z"
            fill={props.isHovered ? '#ffb51a' : '#ffc44a'}
            fillRule="nonzero"
          />
        </g>
        <path
          d="M26.7320821,23.3243027 L25.9906517,23.3243027 L25.7278662,23.0709024 C26.8540897,21.756975 27.4359718,19.9644026 27.1168752,18.0592078 C26.6757709,15.4501233 24.4984055,13.3666098 21.8705506,13.0475131 C17.9006127,12.5594829 14.5594829,15.9006127 15.0475131,19.8705506 C15.3666098,22.4984055 17.4501233,24.6757709 20.0592078,25.1168752 C21.9644026,25.4359718 23.756975,24.8540897 25.0709024,23.7278662 L25.3243027,23.9906517 L25.3243027,24.7320821 L29.3130111,28.7207905 C29.6978041,29.1055835 30.3266122,29.1055835 30.7114052,28.7207905 C31.0961983,28.3359974 31.0961983,27.7071893 30.7114052,27.3223962 L26.7320821,23.3243027 Z M21.1009645,23.3243027 C18.7640507,23.3243027 16.8776263,21.4378783 16.8776263,19.1009645 C16.8776263,16.7640507 18.7640507,14.8776263 21.1009645,14.8776263 C23.4378783,14.8776263 25.3243027,16.7640507 25.3243027,19.1009645 C25.3243027,21.4378783 23.4378783,23.3243027 21.1009645,23.3243027 Z"
          fillOpacity="1"
          fill={props.isHovered ? '#000' : '#333'}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

FolderLookUpIcon.propTypes = {
  size: PropTypes.string,
  isHovered: PropTypes.bool,
};

FolderLookUpIcon.defaultProps = {
  size: '32',
  isHovered: false,
};

export default FolderLookUpIcon;
