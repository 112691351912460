import React, { useState } from 'react';

import { FormField, Modal, TextInput, useToast } from '~/eds';
import { api } from '~/redux';

import { ExtendedPartyModalProps } from '../types';

export const UpdatePartyModal = ({
  onHide,
  party,
  clientId,
}: ExtendedPartyModalProps) => {
  const { toast } = useToast();
  const [partyName, setPartyName] = useState<string>(party.name);
  const [editParty, { isLoading }] = api.endpoints.updateParty.useMutation();

  const children = (
    <FormField
      label="Party Name"
      input={TextInput}
      name="Edit Party Name"
      value={partyName}
      onChange={(updatedPartyName) => setPartyName(updatedPartyName as string)}
    />
  );

  return (
    <Modal
      children={children}
      enableHideOnPrimaryAction={true}
      isVisible={true}
      primaryAction={{
        isLoading,
        text: 'Save',
        disabled: !partyName,
        onClick: () => {
          const updatedParty = { ...party, name: partyName };
          editParty({
            isInternal: party.isInternal,
            oldName: party.name,
            party: updatedParty,
            clientId,
          })
            .unwrap()
            .then(() => {
              toast({
                message: `Party "${party.name}" has been renamed to "${updatedParty.name}".`,
                status: 'success',
              });
            })
            .catch((error) => {
              toast({
                message: `${error.response.data.detail}`,
                status: 'danger',
              });
            });
          onHide();
        },
        variant: 'primary',
      }}
      onHide={onHide}
      title="Edit Party"
    />
  );
};
