import { createSelector } from '@reduxjs/toolkit';

import { buildQuery } from '../api/methods';
import { isActiveFilter, name, SearchSlice } from '../slices/search';

const selectSlice = (rootState: any): SearchSlice => rootState[name];

export const selectBooleanQuery = createSelector(
  selectSlice,
  (state) => state.booleanQuery,
);

export const selectModal = createSelector(selectSlice, (state) => state.modal);

export const selectPage = createSelector(selectSlice, (state) => state.page);
export const selectPageSize = createSelector(
  selectSlice,
  (state) => state.pageSize,
);
export const selectSortBy = createSelector(
  selectSlice,
  (state) => state.sortBy,
);

export const selectFieldValues = createSelector(
  selectSlice,
  (state) => state.fieldValues,
);
export const selectClauseSuggestions = createSelector(
  selectSlice,
  (state) => state.clause,
);
export const selectFilters = createSelector(
  selectSlice,
  (state) => state.filters,
);
export const selectActiveFilters = createSelector(selectFilters, (filters) =>
  filters.filter(isActiveFilter),
);
export const selectFiltersAreDirty = createSelector(
  selectSlice,
  (state) => state.filtersAreDirty,
);
export const selectFolderTree = createSelector(
  selectSlice,
  (state) => state.folderTree,
);

const _selectColumnOrder = createSelector(
  selectSlice,
  (state) => state.columnOrder,
);
export const selectColumnOrder = createSelector(
  _selectColumnOrder,
  (columnOrder) => columnOrder.map(String),
);
export const selectColumnWidths = createSelector(
  selectSlice,
  (state) => state.columnWidths,
);

export const selectQuery = createSelector(selectSlice, (state) => state.query);
export const selectQueryFields = createSelector(
  selectSlice,
  (state) => state.queryFields,
);

export const selectUnsupportedSavedSearchQuery = createSelector(
  selectSlice,
  (state) => state.unsupportedSavedSearchQuery,
);

export const selectPilotSearchQuery = createSelector(selectSlice, (state) => {
  if (state.query && !state.unsupportedSavedSearchQuery) {
    return buildQuery(state.query);
  } else if (!state.query && state.unsupportedSavedSearchQuery) {
    return state.unsupportedSavedSearchQuery;
  } else if (state.query && state.unsupportedSavedSearchQuery) {
    return buildQuery(state.query);
  } else {
    return [];
  }
});

export const selectCurrentTableView = createSelector(
  selectSlice,
  (state) => state.currentTableView,
);

export const selectCurrentAddToGroupStep = createSelector(
  selectSlice,
  (state) => state.currentAddToGroupStep,
);

export const selectSelectedDocument = createSelector(
  selectSlice,
  (state) => state.selectedDocument,
);

export const selectSelectedGroup = createSelector(
  selectSlice,
  (state) => state.selectedGroup,
);

export const selectSearchResultsCache = createSelector(
  selectSlice,
  (state) => state.searchResultsCache,
);

export const selectPinnedFieldIds = createSelector(
  selectSlice,
  (state) => state.pinnedFieldIds,
);
