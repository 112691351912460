import { fetchEventSource } from '@microsoft/fetch-event-source';

import AuthenticationStore from '~/auth';
import { refreshAccessToken } from '~/auth/refreshAccessToken';
import {
  REFRESH_AND_RETRY_ERROR,
  RETRY_LATER_ERROR,
  SYSTEM_ERROR,
} from '~/constants/errors';
import { useToast } from '~/eds';
import { LOGIN_CONSTANTS } from '~/features/login';
import { camelizeKeys } from '~/redux/api/transformers/utils';

const authStore = AuthenticationStore();

interface sseCallbacks<
  Event = unknown,
  EventError = unknown,
  EventResponse = Response
> {
  /**
   * useSse will thry refreshing access token if it fails this callback gets invoked.
   * @param EventError - reason for error.
   * @returns void
   */
  onAuthenticationError: (err: EventError) => void;
  /**
   * Pass callback to handle SSE connection close.
   * @returns void
   */
  onClose: () => void;
  /**
   * Pass callback to handle SSE retryable errors.
   * @param EventError - reason for error.
   * @returns void
   */
  onRateLimitError: () => void;
  /**
   * Pass callback to handle SSE errors that close a connection.
   * @param EventError - reason for error.
   * @returns void
   */
  onError: (err: EventError) => void;
  /**
   * Pass callback to handle SSE message events.
   * @param Event - is unknown until type is specified by the consumer.
   * @returns void
   */
  onMessage: (event: Event) => void;
  /**
   * Pass callback to handle SSE opening a connection.
   * Place where you can handle logic based on response statuses.
   * @param Response - fetch response object.
   * @returns void
   */
  onOpen: (response: EventResponse) => void;
}

export interface SseRequestProps {
  //* Request method.
  method: 'POST' | 'GET';
  //* Request headers.
  headers?: {
    'Content-Type': string;
  };
  //* `fetch-event-source` uses the browsers Page Visibility API so the connection closes if document is hidden.(Like when you switch tabs)
  //* By default this is true but we can pass false to disable this.
  //* https://github.com/Azure/fetch-event-source#fetch-event-source
  openWhenHidden?: boolean;
}

export class RateLimitError extends Error {}
export class AuthenticationError extends Error {}

export const useSse = () => {
  const { toast } = useToast();

  const createSseConnection = (
    url: string,
    callbacks: sseCallbacks,
    requestProps: SseRequestProps = { method: 'GET', openWhenHidden: false },
    hasRetried = false,
  ) =>
    //* Adding a catch here for authentication errors. This is so we retry until we get updated refresh token.
    //* Github issue: https://github.com/Azure/fetch-event-source/issues/33
    setupConnection(url, callbacks, requestProps).catch(async (err) => {
      if (hasRetried) {
        toast({
          message: err?.detail ?? REFRESH_AND_RETRY_ERROR,
          status: 'danger',
        });
        return callbacks.onAuthenticationError?.(err);
      } else if (err instanceof AuthenticationError) {
        await refreshAccessToken();
        createSseConnection(url, callbacks, requestProps, true);
      }
    });

  const setupConnection = (
    url: string,
    callbacks: sseCallbacks,
    requestProps: SseRequestProps,
  ) => {
    //* This has to be async because onopen return `Promise<void>.`
    const handleOpen = async (res: Response) => {
      switch (res.status) {
        case 200:
          callbacks.onOpen?.(res);
          break;
        case 429:
          toast({ message: RETRY_LATER_ERROR, status: 'danger' });
          callbacks.onRateLimitError?.();
          throw new RateLimitError();
        case 401:
          throw new AuthenticationError();
        default:
          throw new Error();
      }
    };

    return fetchEventSource(url, {
      credentials: 'include',
      headers: {
        ...(requestProps.headers || { Accept: 'text/event-stream' }),
        ...(authStore.getCsrf()
          ? { [LOGIN_CONSTANTS.HEADERS.CSRF_TOKEN]: authStore.getCsrf() }
          : {}),
        ...(authStore.getAccessToken()
          ? {
              Authorization: `Bearer ${authStore.getAccessToken()}`,
            }
          : {}),
      },
      method: requestProps.method,
      openWhenHidden: requestProps.openWhenHidden,
      onclose: () => {
        callbacks.onClose?.();
      },
      onerror: (err) => {
        if (
          err instanceof AuthenticationError ||
          err instanceof RateLimitError
        ) {
          throw err;
        } else {
          toast({ message: err?.detail ?? SYSTEM_ERROR, status: 'danger' });
          //* Calls callback here to catch errors that may happen mid connection.
          callbacks.onError?.(err);
          throw err; // rethrow to stop the connection else it will auto retry.
        }
      },
      onmessage: (event) => {
        /*
         * FE needs to ignore empty events.
         * Reason: SSE has a ping event in order to make sure the connection stays open and the server is still alive (in case there’s a big gap between events).
         */
        if (event.data === '') {
          return;
        }

        try {
          const eventData = JSON.parse(event.data);
          const parsedEventData = camelizeKeys(eventData);
          callbacks.onMessage?.(parsedEventData);
        } catch {
          console.error('Failed to parse server response.');
          throw new Error();
        }
      },
      onopen: handleOpen,
    });
  };

  return {
    createSseConnection,
  };
};
