import React from 'react';
import { components, MultiValueProps } from 'react-select';
import { SortableElement } from 'react-sortable-hoc';

import { Option } from '../../../types';
import { Chip } from '../../Chip';
import { onSortableElementMouseDown } from '../sortable-utils';
import { resetGetStyles } from './resetGetStyles';

// SortableContainer is implemented in the sister ./Control component
const SortableMultiValue = SortableElement(components.MultiValue);

export const MultiValue = (props: MultiValueProps<Option>) => {
  const { children, removeProps, selectProps } = props;
  const { enableValueContainer, isDisabled } = selectProps;

  if (!enableValueContainer) {
    return null;
  }

  const innerProps = {
    ...props.innerProps,
    onMouseDown: onSortableElementMouseDown,
  };

  return (
    // @ts-ignore `react-select`'s official suggestion explicitly ignores this error since it does not pass `index` to `react-sortable-hoc` (see https://react-select.com/advanced#sortable-multiselect)
    <SortableMultiValue
      {...props}
      innerProps={innerProps}
      getStyles={resetGetStyles}
    >
      <Chip
        preset={isDisabled ? undefined : 'removable'}
        text={children as string}
        onRemove={() => removeProps.onClick(null)}
      />
    </SortableMultiValue>
  );
};
