export default `
<svg
  aria-hidden="true"
  aria-label="status-new"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M14.2188 6.6875L16 6L16.6562 4.25C16.6875 4.09375 16.8438 4 17 4C17.125 4 17.2812 4.09375 17.3125 4.25L18 6L19.75 6.6875C19.9062 6.71875 20 6.875 20 7C20 7.15625 19.9062 7.3125 19.75 7.34375L18 8L17.3125 9.78125C17.2812 9.90625 17.125 10 17 10C16.8438 10 16.6875 9.90625 16.6562 9.78125L16 8L14.2188 7.34375C14.0625 7.3125 14 7.15625 14 7C14 6.875 14.0625 6.71875 14.2188 6.6875ZM9.5 6.3125C9.5625 6.125 9.75 6 9.9375 6C10.125 6 10.3125 6.125 10.4062 6.3125L12.0312 9.875L15.5938 11.5C15.7812 11.5938 15.9062 11.7812 15.9062 11.9688C15.9062 12.1562 15.7812 12.3438 15.5938 12.4375L12.0312 14.0625L10.4062 17.625C10.3125 17.8125 10.125 17.9375 9.9375 17.9375C9.75 17.9375 9.5625 17.8125 9.5 17.625L7.84375 14.0625L4.28125 12.4375C4.09375 12.3438 4 12.1562 4 11.9688C4 11.7812 4.09375 11.5938 4.28125 11.5L7.84375 9.875L9.5 6.3125ZM9.1875 10.5C9.0625 10.8125 8.78125 11.0938 8.46875 11.2188L6.875 11.9688L8.46875 12.7188C8.78125 12.8438 9.0625 13.125 9.1875 13.4375L9.9375 15.0312L10.6875 13.4375C10.8125 13.125 11.0938 12.8438 11.4062 12.7188L13 11.9688L11.4062 11.2188C11.0938 11.0938 10.8125 10.8125 10.6875 10.5L9.9375 8.90625L9.1875 10.5ZM16.6562 14.25C16.6875 14.0938 16.8438 14 17 14C17.125 14 17.2812 14.0938 17.3125 14.25L18 16L19.75 16.6875C19.9062 16.7188 20 16.875 20 17C20 17.1562 19.9062 17.3125 19.75 17.3438L18 18L17.3125 19.7812C17.2812 19.9062 17.125 20 17 20C16.8438 20 16.6875 19.9062 16.6562 19.7812L16 18L14.2188 17.3438C14.0625 17.3125 14 17.1562 14 17C14 16.875 14.0625 16.7188 14.2188 16.6875L16 16L16.6562 14.25Z"
    fill="currentcolor"
  />
</svg>
`;
