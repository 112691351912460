import { EnumValue, Filter } from '~/evifields';
import { DashboardEntityType } from '~/redux/api/methods';
import { DropdownFieldValue, SearchFilter } from '~/types';

import getAsyncOptionsContainer from './AsyncOptionsContainer';

export const FIELDS_WITH_COMPLEX_VALUES: string[] = [
  'document_group_id',
  'folder',
];

export const updateSettings = (searchFilter: SearchFilter): SearchFilter => {
  const newSearchFilter = {
    ...searchFilter,
  };
  switch (searchFilter.type) {
    case 'enum':
    case 'enum_set':
    case 'folder':
      break;

    default:
      return newSearchFilter;
  }

  const shouldEnableSelectAll = (filter: Filter<EnumValue>) => {
    if (searchFilter.type === 'folder') {
      return false;
    }

    return (
      searchFilter.type === 'enum_set' &&
      (filter.operatorId === 'contains_any' ||
        filter.operatorId === 'not_contains_any')
    );
  };

  newSearchFilter.settings = {
    ...newSearchFilter.settings,
    __testEnableSelectAll: shouldEnableSelectAll,
    options: [],
  };

  return newSearchFilter;
};

export const attachRenderer = (
  searchFilter: SearchFilter,
  type?: DashboardEntityType,
): SearchFilter => {
  switch (searchFilter.type) {
    case 'enum':
    case 'enum_set':
    case 'clause':
    case 'folder':
      break;
    default:
      return searchFilter;
  }

  searchFilter.render = getAsyncOptionsContainer(searchFilter, type);

  return searchFilter;
};

export const attachSerializer = ({
  searchFilter,
  values,
}: {
  searchFilter: SearchFilter;
  values?: DropdownFieldValue[];
}): SearchFilter => {
  if (!FIELDS_WITH_COMPLEX_VALUES.includes(searchFilter.id)) {
    return searchFilter;
  }

  const filterSerializer = (filter: Filter) => {
    return values?.find((value) => value.value === filter.values[0])
      ?.display_value;
  };

  searchFilter.serialize = filterSerializer;

  return searchFilter;
};

export const attachRenderersAndSerializers = ({
  filters,
  fieldValues,
  type,
}: {
  filters: SearchFilter[];
  fieldValues: {
    [fieldId: string]: DropdownFieldValue[];
  };
  type: DashboardEntityType | undefined;
}) => {
  const entries = filters
    .map((filter: SearchFilter) => updateSettings(filter))
    .map((filter) => attachRenderer(filter, type))
    .map((filter) =>
      attachSerializer({
        searchFilter: filter,
        values: fieldValues[filter.id],
      }),
    );

  return entries;
};
