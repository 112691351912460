import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { black5 } from '../../../assets/shared-styles/general';
// eslint-disable-line import/default
import RemoveIcon from '../../Shared/Icons/RemoveIcon';
import ReorderIcon from '../../Shared/Icons/ReorderIcon';
import styles from './EcDocumentsVisibleField.styles';

class EcDocumentsVisibleField extends Component {
  constructor(props) {
    super(props);

    this.onSortEnd = this.onSortEnd.bind(this);

    this.state = { editingMode: false, items: [] };
  }

  componentDidMount() {
    this.setState({ items: this.props.itemsData });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ items: nextProps.itemsData });
  }

  toggleEditingMode = (editingMode) => {
    this.setState({ editingMode: !editingMode });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { handleItemReorder } = this.props;
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
    handleItemReorder(this.state.items);
  };

  renderDocumentsVisibleFields() {
    const { classes, handleRemoveRow } = this.props;
    const { items } = this.state;

    const DragHandle = SortableHandle(() => <ReorderIcon color={black5} />);

    const Row = ({ item }) => {
      return (
        <div
          className={classes.itemFieldContainer}
          role="menuitem"
          tabIndex={0}
        >
          <span className={classes.dragHandleContainer}>
            <DragHandle />
          </span>
          <span className={classes.fieldName}>{item.field_name}</span>
        </div>
      );
    };

    const SortableItem = SortableElement(({ value }) => (
      <Row
        item={value}
        dragHandle={<DragHandle />}
        handleRemoveRow={(value) => handleRemoveRow(value)}
      />
    ));

    if (items && items.length) {
      const SortableList = SortableContainer(({ items }) => (
        <div className={classes.listContainer}>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </div>
      ));

      return (
        <SortableList
          items={items}
          onSortEnd={this.onSortEnd}
          helperClass={classes.sortableHelper}
        />
      );
    }
  }

  renderDocumentsVisibleFieldsEditing() {
    const { classes, handleRemoveRow } = this.props;
    const { items } = this.state;

    return items.map((item) => {
      return (
        <div className={classes.itemFieldEditingContainer}>
          <span className={classes.fieldName}>{item.field_name}</span>
          <button
            className={classes.itemRowActionButton}
            title={`Remove ${item.field_name} field}`}
            onClick={() => handleRemoveRow(item)}
          >
            <RemoveIcon color={black5} size="20" />
          </button>
        </div>
      );
    });
  }

  render() {
    const { classes } = this.props;
    const { editingMode } = this.state;

    return (
      <div>
        <div className={classes.headerContainer}>
          <span className={classes.titleContainer}>VISIBLE COLUMNS</span>
          <button
            className={classes.titleActionContainer}
            onClick={() => this.toggleEditingMode(editingMode)}
          >
            {editingMode ? 'Reorder' : 'Remove...'}
          </button>
        </div>
        {editingMode
          ? this.renderDocumentsVisibleFieldsEditing()
          : this.renderDocumentsVisibleFields()}
      </div>
    );
  }
}

EcDocumentsVisibleField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(EcDocumentsVisibleField);
