import { Line, Point, PointTooltipProps } from '@nivo/line';
import { truncate } from 'lodash';
import React from 'react';

import { ChartProps } from '~/types';

import { ChartTooltip } from './ChartTooltip';
import { BARCHART_COLOR } from './constants';

interface CustomPoint extends Point {
  data: {
    x: string | number;
    xFormatted: string | number;
    y: string | number;
    yFormatted: string | number;
    yStacked?: number;
    bucketValue?: string;
  };
}

const CustomTooltip = ({ point }: PointTooltipProps) => {
  const tooltipContent = `${point.data.xFormatted}: ${point.data.yFormatted}`;
  return <ChartTooltip tooltipContent={tooltipContent} />;
};

export const LineChart = ({
  buckets,
  width,
  selectedBucket,
  onBucketSelected,
}: ChartProps) => {
  const nivoData = buckets.map(({ label, value, bucketValue }) => ({
    x: label,
    y: value,
    bucketValue,
  }));

  const data = [
    {
      id: 'Sample Data',
      data: nivoData,
    },
  ];

  const handlePointClick = (point: CustomPoint) => {
    onBucketSelected?.(`${point.data?.bucketValue ?? point.data?.x}`);
  };

  return (
    <div style={{ height: '300px' }}>
      <Line
        data={data}
        height={300}
        colors={[BARCHART_COLOR]}
        width={width}
        margin={{ top: 30, right: 60, bottom: 80, left: 100 }}
        xScale={{ type: 'point' }}
        enableGridX={false}
        tooltip={CustomTooltip}
        onClick={(point) => handlePointClick(point as CustomPoint)}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'slices',
          'mesh',
          'legends',
          ({ points }) => {
            const selectedPoint = points.find(
              (point) =>
                (point as CustomPoint).data.bucketValue === selectedBucket,
            ) as CustomPoint | undefined;

            return (
              <g key="custom-points">
                {points.map((point) => (
                  <circle
                    onClick={() => handlePointClick(point as CustomPoint)}
                    key={point.id}
                    cx={point.x}
                    cy={point.y}
                    r={4}
                    fill="#333333"
                    strokeWidth={1}
                    stroke="#fff"
                  />
                ))}
                {selectedPoint && (
                  <circle
                    onClick={() => onBucketSelected?.(null)}
                    cx={selectedPoint.x}
                    cy={selectedPoint.y}
                    r={8}
                    fill="#ED7D31"
                    strokeWidth={2}
                    stroke="#FFF"
                  />
                )}
              </g>
            );
          },
        ]}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: (value: string) =>
            truncate(value, { omission: '...', length: 18 }),
          tickRotation: buckets.length >= 4 ? -30 : 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        pointSize={3}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </div>
  );
};
