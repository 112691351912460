import React from 'react';
import {
  components,
  InputProps,
  PlaceholderProps,
  Props as SelectProps,
} from 'react-select';

import { Option } from '../../../types';
import { Layout } from '../../Layout';
import { Placeholder } from './Placeholder';
import { resetGetStyles } from './resetGetStyles';

type Props = InputProps & SelectProps & PlaceholderProps<Option, boolean>;

export const Input = (props: Props) => {
  const { selectProps } = props;
  const { inputValue, enableValueContainer, placeholder, value } = selectProps;

  const input = <components.Input {...props} getStyles={resetGetStyles} />;

  if (!enableValueContainer) {
    const hasValue =
      value !== null && Array.isArray(value)
        ? value.length > 0
        : Boolean(value);
    return (
      <Layout align="center" spacing={1}>
        {/* react-select opionatedly hides the Placeholder when the select hasValue.  We want to continue showing this placeholder */}
        {hasValue && !inputValue && (
          <Placeholder {...props}>{placeholder}</Placeholder>
        )}
        {input}
      </Layout>
    );
  }

  return input;
};
