import { Actions, DescriptionText, Layout, Stepper } from '~/eds';
import { RoutePathType, useRouting } from '~/routing';

import { OptimizeState } from '../../../types';

interface Props {
  state: OptimizeState;
  progressComplete: number;
}

export const BoostProgress = ({ state, progressComplete }: Props) => {
  const { navigate } = useRouting();

  const sections = [
    {
      id: 'rewiewing',
      isLoading: state === 'initializing',
      title: 'Reviewing your feedback',
      description:
        'Our AI is considering your ratings and reviewing the feedback you provided on incorrect values.',
      isCompleted: state === 'running',
    },
    {
      id: 'testing',
      isLoading:
        state === 'running' && progressComplete >= 0 && progressComplete < 0.33,
      title: 'Testing model improvements',
      description:
        'Our AI is checking for optimizations to your model configurations.',
      isCompleted: progressComplete >= 0.33,
    },
    {
      id: 'writing',
      isLoading: progressComplete >= 0.33 && progressComplete < 0.67,
      title: 'Updating instructions',
      description:
        'Our AI is drafting new instructions that incorporate your ratings and feedback.',
      isCompleted: progressComplete >= 0.67,
    },
    {
      id: 'analyzing',
      isLoading: progressComplete >= 0.67,
      title: 'Analyzing model accuracy',
      description:
        'Our AI is reviewing multiple optimized versions of the model to recommend the best ones to you.',
      isCompleted: state === 'done' && progressComplete === 1,
    },
  ];

  return (
    <Layout
      align="center"
      justify="center"
      w="540"
      preset="xl"
      direction="column"
      m="auto"
    >
      <DescriptionText text="Our AI is reviewing your ratings and testing improvements to increase your accuracy. This could take several minutes. AI Boost will continue to run if you leave this page." />
      <Stepper isOrdered={true} name="boost progression" sections={sections} />
      <Actions
        direction="row"
        actions={[
          {
            text: 'Return to Automation Hub',
            level: 'secondary',
            onClick: () => navigate(RoutePathType.AutomationHubFields),
          },
        ]}
      />
    </Layout>
  );
};
