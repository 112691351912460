import React, { memo } from 'react';
import { Layout as UinixLayout } from 'uinix-ui';
import { LayoutProps } from 'uinix-ui/lib/components/layout';

import { SharedBoxProps } from '../../types';
import { resolvePresetProps } from '../../utils';
import { presets } from './presets';

interface Props extends LayoutProps, SharedBoxProps {
  /** Layout preset */
  preset?: keyof typeof presets;
}

export const Layout = memo((props: Props) => (
  <UinixLayout {...resolvePresetProps(props, presets)} />
));
