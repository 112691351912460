import { minBy } from 'lodash';

import { EMAIL_MENTION_REGEXP } from '~/constants/regexp';

export const EDIT_VIEW_SIDEBAR_WIDTH = 64;
// Should match with 'page-sidebar-width': '400px' on theme sizes file
export const SIDEBAR_WIDTH = 400;

export const getHeaderTitleWidth = () =>
  EDIT_VIEW_SIDEBAR_WIDTH + SIDEBAR_WIDTH;

/**
 * Gets all emails starting with a + from a given string
 * @param {String} text
 * @returns {String[]} An array of emails without the + sign and trimmed
 */
export const getEmailsFromString = (text) => {
  const emails = text.match(EMAIL_MENTION_REGEXP) || [];
  // removes any white space and the "+" at the begining of the email.
  return emails.map((email) => email.trim().replace(/^\+/, ''));
};

export const extractClausesIds = (clauses) => {
  return clauses
    .map((clause) => clause.content.map((content) => content.id))
    .flat();
};

export function getUserEditingDocument(channelMembers, users) {
  const memberEditing = minBy(
    Object.entries(channelMembers.members),
    ([_key, value]) => {
      return new Date(value.joinDate).getTime();
    },
  );
  const user = users[memberEditing[0]];
  return user;
}

export function isCurrentUserEditing(channelMembers, currentUser, users) {
  const userEditing = getUserEditingDocument(channelMembers, users);
  return userEditing?.id === currentUser?.id;
}
/**
 * Find the clause by its id or return null otherwise
 *
 * @param {*} clauses clauses array
 * @param {number} clauseId the clause id to be found
 * @returns {*} clause or null
 */
export const getClauseById = (clauses, clauseId) => {
  return clauses.reduce((composedClause, clause) => {
    const findableClause = clause.content.find(
      (clauseContent) => clauseContent.id === clauseId,
    );
    if (findableClause) {
      return {
        ...findableClause,
        name: clause.name,
      };
    }
    return composedClause;
  }, null);
};

export function getCompareToVersionId(ticket, versionNumber) {
  return ticket.documentVersions?.find(
    (version) => version.versionNumber === versionNumber,
  );
}

export function isOnLatestVersion(ticket, currentVersion) {
  return ticket.documentVersions?.length === currentVersion;
}
