import { Party } from '~/features/manage-parties';
import { PilotId } from '~/types';

import { deleteParty } from './deleteParty';

interface Request {
  clientId: PilotId;
  isInternal: boolean;
  parties: Party[];
}

interface DeletedParty {
  status: 'deleted' | 'failed';
  party: Party;
}

export const deleteParties = async ({
  parties,
  clientId,
  isInternal,
}: Request): Promise<any> => {
  const deletedParties = await Promise.all(
    parties.map(async (party) => {
      return deleteParty({ clientId, party, isInternal })
        .then(() => ({ status: 'deleted' as const, party }))
        .catch(() => ({ status: 'failed' as const, party }));
    }),
  );

  return (deletedParties as unknown) as DeletedParty[];
};
