import PT from 'prop-types';
import { connect } from 'react-redux';

import { Select } from '~/eds';
import { getSignerOptions } from '~/reducers/workflow';

function SignerSelect({
  blacklistValues = [],
  value,
  onChange,
  // connected
  options,
  shouldValidate,
}) {
  let error;
  if (shouldValidate) {
    if (value == null) {
      error = 'Missing Signer';
    } else if (!options.find((option) => option.value === value)) {
      error = 'Invalid Signer';
    }
  }

  return (
    <Select
      isClearable={false}
      error={error}
      options={options}
      placeholder={error || 'Specify a signer'}
      value={value}
      width="input.l.width"
      onChange={onChange}
      filterOption={(option) => {
        return !blacklistValues.includes(option.value);
      }}
      name="Missing Signer"
    />
  );
}

SignerSelect.propTypes = {
  blacklistValues: PT.arrayOf(PT.string.isRequired),
  value: PT.string,
  onChange: PT.func.isRequired,
};

function mapStateToProps({ builder, workflow }) {
  return {
    options: getSignerOptions(workflow),
    shouldValidate: builder.shouldValidate,
  };
}

export default connect(mapStateToProps)(SignerSelect);
