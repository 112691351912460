import { sylvanus } from '~/services';
import { Uuid } from '~/types';

interface Request {
  modelId: Uuid;
  version: number;
}

export const executePromptModelTestCases = async ({
  modelId,
  version,
}: Request): Promise<void> => {
  await sylvanus.post(`/model/${modelId}/version/${version}/execute`);
  return;
};
