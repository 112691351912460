import React from 'react';

import { Box, ErrorPage } from '~/eds';
import { useRouting } from '~/routing';

const Page = () => {
  const { navigate } = useRouting();

  const resolveAction = {
    text: 'Take me home',
    onClick: () => navigate('/'),
  };

  return (
    <Box py={16}>
      <ErrorPage preset="page-not-found" resolveAction={resolveAction} />
    </Box>
  );
};

export default Page;
