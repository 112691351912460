import { isEqual } from 'lodash';

import {
  Nullable,
  Period,
  TurnTrackingHistoryType,
  TurnTrackingPartyType,
  Uuid,
} from '~/types';

export const partyNameFromPartyIdAndParties = (
  partyId: Uuid,
  parties: TurnTrackingPartyType[],
) => {
  const filteredParty = parties?.filter((party: TurnTrackingPartyType) => {
    return party?.id === partyId;
  });
  const currentPartyName = filteredParty?.[0]?.name;
  return currentPartyName;
};

export const isEntryUpdated = (
  entry1: Nullable<TurnTrackingHistoryType>,
  entry2: Nullable<TurnTrackingHistoryType>,
): boolean => {
  if (!entry1 || !entry2) return false;
  // only compare time up to minutes, not seconds nor milliseconds
  const entry1StartTime = entry1.startTime.toISOString().substring(0, 16);
  const entry2StartTime = entry2.startTime.toISOString().substring(0, 16);
  if (!entry1?.endTime || !entry2?.endTime) {
    return !isEqual(entry1StartTime, entry2StartTime);
  }
  const entry1EndTime = entry1.endTime.toISOString().substring(0, 16);
  const entry2EndTime = entry2.endTime.toISOString().substring(0, 16);
  return (
    !isEqual(entry1StartTime, entry2StartTime) ||
    !isEqual(entry1EndTime, entry2EndTime)
  );
};

export const isValidTime = (value: Nullable<string>) => {
  if (!value) return false;
  if (value.split(':').length !== 2) {
    return false;
  }
  const [hours, minutes] = value.split(':');
  if (hours.length !== 2 || minutes.length !== 2) {
    return false;
  }
  if (!Number(hours) || (!Number(minutes) && Number(minutes) !== 0)) {
    return false;
  }
  if (Number(hours) < 0 || Number(hours) > 12) {
    return false;
  }
  if (Number(minutes) < 0 || Number(minutes) > 59) {
    return false;
  }
  return true;
};

export const formatDateTimeUpdate = (
  initial: Date,
  value: Date | string,
  period?: Period,
): Date => {
  if (typeof value === 'string') {
    const [hours, minutes] = value.split(':');
    if (Number(hours) === 12) {
      const periodShift = period === 'pm' ? 0 : 12;
      initial.setHours((Number(hours) + periodShift) % 24);
      initial.setMinutes(Number(minutes));
      return initial;
    }
    const periodShift = period === 'am' ? 0 : 12;
    initial.setHours(Number(hours) + periodShift);
    initial.setMinutes(Number(minutes));
    return initial;
  } else {
    initial.setDate(value.getDate());
    initial.setMonth(value.getMonth());
    initial.setFullYear(value.getFullYear());
    return initial;
  }
};

const formatTimeToString = (hours: number, minutes: number) => {
  const hourString = (hours: number) => {
    if (hours === 0) {
      return '12';
    } else if (hours.toString().length === 1) {
      return `0${hours.toString()}`;
    } else {
      return hours.toString();
    }
  };
  const minutesString =
    minutes.toString().length === 1
      ? `0${minutes.toString()}`
      : minutes.toString();
  return `${hourString(hours)}:${minutesString}`;
};

export const getInitialTimeValues = (
  entry: TurnTrackingHistoryType,
): {
  initialStartPeriod: Period;
  initialEndPeriod: Period | undefined;
  initialStartTime: string;
  initialEndTime: string | undefined;
} => {
  let initialStartPeriod, initialEndPeriod, initialStartTime, initialEndTime;
  const startHours = entry.startTime.getHours();
  const startMinutes = entry.startTime.getMinutes();
  if (startHours < 12) {
    initialStartPeriod = 'am' as Period;
    initialStartTime = formatTimeToString(startHours, startMinutes);
  } else {
    initialStartPeriod = 'pm' as Period;
    initialStartTime = formatTimeToString(startHours % 12, startMinutes);
  }
  if (entry?.endTime) {
    const endHours = entry.endTime.getHours();
    const endMinutes = entry.endTime.getMinutes();
    if (endHours < 12) {
      initialEndPeriod = 'am' as Period;
      initialEndTime = formatTimeToString(endHours, endMinutes);
    } else {
      initialEndPeriod = 'pm' as Period;
      initialEndTime = formatTimeToString(endHours % 12, endMinutes);
    }
  }
  return {
    initialStartPeriod,
    initialEndPeriod,
    initialStartTime,
    initialEndTime,
  };
};

export const calculateDuration = (startTime: Date, endTime: Date) => {
  const milliseconds = endTime.valueOf() - startTime.valueOf();
  const days = milliseconds / 8.64e7;
  return Math.round(days * 10) / 10;
};

export const timeFieldErrors = (
  turn: Nullable<TurnTrackingHistoryType>,
  createdTime?: Date,
) => {
  const errors: string[] = [];
  if (!turn) return errors;
  if (createdTime) {
    const created = new Date(createdTime);
    created.setSeconds(0);
    created.setMilliseconds(0);
    if (created > turn.startTime) {
      errors.push('Start time should be after the ticket was created.');
    }
  }
  if (turn?.endTime && turn.startTime > turn.endTime) {
    errors.push('Start time should be before the end time for this turn.');
  }
  if (turn.startTime > new Date()) {
    errors.push('Start time should be in the past.');
  }
  return errors;
};

const turnTrackingColumns = [
  'ticket_age_Ticket',
  'internal_turns_age_Ticket',
  'external_turns_age_Ticket',
];

export const testIsTurnTrackingColumn = (column: string) =>
  !turnTrackingColumns.includes(column);
