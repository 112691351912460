import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';

import { BannerState, PagesState as State } from '../types';

const name = SliceType.Pages;

const initialState = {} as State;

export default createSlice({
  name,
  initialState,
  reducers: {
    clearPageBanner: (
      state: State,
      action: PayloadAction<{ pathname: string }>,
    ) => {
      const { pathname } = action.payload;
      delete state[pathname]?.banner;
    },
    setPageBanner: (
      state: State,
      action: PayloadAction<{ banner: BannerState; pathname: string }>,
    ) => {
      const { banner, pathname } = action.payload;
      if (!state[pathname]) {
        state[pathname] = {};
      }
      state[pathname].banner = banner;
    },
  },
});
