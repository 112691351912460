export const presets = {
  'new-clause': {
    description:
      'Tag a clause in your contract and let our AI find similar ones, making them searchable, trackable, and analyzable.',
    image: 'empty-no-groups' as const,
    imageAlt: 'no clauses',
    title: 'Automate clause identification',
  },
  'no-alerts': {
    description:
      'You have not added any alerts and no alerts have been shared with you.',
    image: 'empty-no-alerts' as const,
    imageAlt: 'no alerts',
    title: 'You don’t have any alerts.',
  },
  'no-clause-selected': {
    description: 'Select a Clause on the left to view content.',
    image: undefined,
    imageAlt: '',
    title: 'No Clause Selected.',
  },
  'no-clause-variations-admin': {
    description:
      'Continue building your library using the + button to add Clause Variations.',
    image: undefined,
    imageAlt: '',
    title: 'Selected Clause has no variations.',
  },
  'no-clause-variations-user': {
    description:
      'Please contact your Administrator to continue building your library of standardized language.',
    image: undefined,
    imageAlt: '',
    title: 'Selected Clause has no variations.',
  },
  'no-clauses-admin': {
    description:
      'Start building your library by adding Clauses and Clause Variations.',
    image: undefined,
    imageAlt: '',
    title: 'No Clauses to display.',
  },
  'no-clauses-user': {
    description:
      'Please contact your Administrator to start building a library of standardized language.',
    image: undefined,
    imageAlt: '',
    title: 'No Clauses to display.',
  },
  'no-dashboard': {
    description: 'Explore custom dashboards or build your own.',
    image: 'empty-no-search' as const,
    imageAlt: 'no dashboard',
    title: 'Dashboard not found.',
  },
  'no-documents': {
    description: 'Once documents are uploaded, they will be displayed here.',
    image: 'empty-no-documents' as const,
    imageAlt: 'no documents',
    title: 'This folder is empty.',
  },
  'no-groups': {
    description:
      'Groups are an easy way to keep track of related documents. Link documents to each other, define relationships and keep track of supporting documents.',
    image: 'empty-no-groups' as const,
    imageAlt: 'no groups',
    title: 'There are no groups yet.',
  },
  'no-matches': {
    description: 'Try adjusting your search term or filters.',
    image: undefined,
    imageAlt: '',
    title: 'No matches.',
  },
  'no-search': {
    description: 'Please enter your search criteria to see results.',
    image: 'empty-no-search' as const,
    imageAlt: 'no search',
    title: 'Please enter search criteria.',
  },
  'no-saved-dashboards': {
    description: '',
    image: 'empty-saved-dashboards' as const,
    imageAlt: 'no saved dashboards',
    title: 'No Saved Dashboards',
  },
  'no-version-match': {
    description: 'Select another version or document to view',
    image: 'empty-version' as const,
    imageAlt: 'version not found',
    title: "This version doesn't exist",
  },
  'no-webhooks': {
    description:
      'Create webhook endpoints to listen for Evisort events, allowing your integration to receive notifications when asynchronous actions take place.',
    image: undefined,
    imageAlt: '',
    title: 'Create Endpoints for Evisort Events',
  },
  'no-uploaded-files': {
    description: 'You haven’t uploaded any documents yet…',
    image: undefined,
    imageAlt: '',
    title: 'No documents uploaded yet.',
  },
};
