export const quotes = [
  {
    text:
      'The universe never did make sense; I suspect it was built on government contract.',
    cite: 'Robert A. Heinlein',
  },
  {
    text:
      "Always read your contract. Know what you're getting yourself into. Know your worth.",
    cite: 'Lil Durk',
  },
  {
    text:
      'I wish to be cremated. One tenth of my ashes shall be given to my agent, as written in our contract.',
    cite: 'Groucho Marx',
  },
  {
    text: 'Early on, they were timing my contract with an egg timer.',
    cite: "Conan O'Brien",
  },
  {
    text: "A verbal contract isn't worth the paper it's written on.",
    cite: 'Samuel Goldwyn',
  },
  {
    text:
      'The first principle of a civilized state is that the power is legitimate only when it is under contract.',
    cite: 'Walter Lippmann',
  },
];
