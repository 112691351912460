import { camelizeKeys, decamelizeKeys } from 'humps';

import { types } from '~/eds';
import { BulkImportUserResultType } from '~/enums';
import { pilot } from '~/services';
import { IBulkImportJob, PilotId, Uuid } from '~/types';
import { fromArray } from '~/utils/object';

import { BatchBaseResolver, SingleBaseResolver } from './types';

type FindUsersByNameOrEmailParams = {
  query: string;
  page?: number;
  pageSize?: number;
};

type FindUsersByNameOrEmailPayload = {
  results: types.User[];
  count: number;
};

export const findUsersByNameOrEmail = async ({
  query,
  page = 1,
  pageSize = 100,
}: FindUsersByNameOrEmailParams): Promise<FindUsersByNameOrEmailPayload> => {
  const params = decamelizeKeys({ query, page, pageSize });
  const result = await pilot.get('user/search/', { params });
  return camelizeKeys(result) as FindUsersByNameOrEmailPayload;
};

type UserResolverParams = {
  includeDeleted?: boolean;
  clientId: number;
};

type UserResolvers = BatchBaseResolver<
  types.User,
  types.PilotId,
  UserResolverParams
>;

export const resolveUsers: UserResolvers = async ({ ids: userIds, params }) => {
  const result = await pilot.post(
    `user/${params?.clientId}/list_all_limited/`,
    {
      userIds,
      includeDeleted: params?.includeDeleted ?? true,
    },
  );

  return fromArray(camelizeKeys(result), 'id');
};

type UserResolver = SingleBaseResolver<types.User, PilotId, UserResolverParams>;

export const resolveUser: UserResolver = async ({ id: userId, params }) => {
  const result = (await pilot.post(
    `user/${params?.clientId}/list_all_limited/`,
    {
      userIds: [userId],
      includeDeleted: params?.includeDeleted ?? true,
    },
  )) as types.User[];
  return camelizeKeys(result[0]) as types.User;
};

type ExportUserParams = {
  clientId: PilotId;
  onlyHeaders?: boolean;
};

export const exportUsersExcelFile = ({
  clientId,
  onlyHeaders,
}: ExportUserParams) => {
  const params = { client_id: clientId, only_headers: onlyHeaders };
  return pilot.get('user/export/', { params, responseType: 'blob' });
};

type ImportResultsFileParams = {
  clientId: PilotId;
  importId: Uuid;
  resultType: BulkImportUserResultType;
};

export const downloadImportResultsExcelFile = ({
  clientId,
  importId,
  resultType,
}: ImportResultsFileParams) => {
  const params = {
    client_id: clientId,
    import_id: importId,
    result_type: resultType,
  };
  return pilot.get('user/import/results/', { params, responseType: 'blob' });
};

type ImportUsersListParams = {
  client_id: PilotId;
  import_id: Uuid;
  email_new_users: boolean;
  update_existing_users: boolean;
  delete_missing_users: boolean;
};
export const startImportUsersListProcessing = ({
  client_id,
  import_id,
  email_new_users,
  update_existing_users,
  delete_missing_users,
}: ImportUsersListParams) =>
  pilot.post('user/import/', {
    client_id,
    import_id,
    email_new_users,
    update_existing_users,
    delete_missing_users,
  });

type ImportUsersListExcelFileParams = {
  file: Blob;
  clientId: PilotId;
};

export type SummaryResults = {
  errors: number;
  existing: number;
  new_users: number;
  to_be_deleted: number;
  total: number;
};
export type ImportUsersSummaryType = {
  client_id: PilotId;
  import_id: Uuid;
  results: SummaryResults;
};

export const importUsersListExcelFile = ({
  file,
  clientId,
}: ImportUsersListExcelFileParams): Promise<ImportUsersSummaryType> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('client_id', clientId.toString());
  return pilot.post('user/import/summary/', formData);
};

interface ImportStatusParams {
  clientId: PilotId;
  importId?: Uuid;
  cancelToken?: Uuid;
}

type ImportStatusType = 'Finished' | 'Failed' | 'Cancelled' | 'Running' | 'New';
export interface UserImportStatus {
  acknowledged: boolean;
  client_id: PilotId;
  counters: {
    total: number;
    processed: number;
    users_created: number;
    users_updated: number;
    users_deleted: number;
    errors: number;
  };
  id: PilotId;
  import_id: Uuid;
  status: ImportStatusType;
  task_id: Uuid;
  timestamps: {
    start: string;
    end: string;
  };
  user_id: PilotId;
}

export const getUsersImportStatus = async ({
  clientId,
  importId,
}: ImportStatusParams): Promise<IBulkImportJob[]> => {
  const params = decamelizeKeys({ clientId, importId });
  const result = await pilot.get('user/import/status/', {
    params,
  });

  return camelizeKeys(result) as IBulkImportJob[];
};

interface AcknowledgeUserImport {
  clientId: PilotId;
  importId?: Uuid;
}

export const acknowledgeUserImport = async ({
  clientId,
  importId,
}: AcknowledgeUserImport) => {
  const params = decamelizeKeys({ clientId, importId });
  return pilot.patch('user/import/acknowledged/', params);
};

export const cancelImportJob = async ({
  clientId,
  importId,
}: ImportStatusParams) => {
  const params = decamelizeKeys({ clientId, importId });
  return pilot.patch('user/import/cancel-request/', params);
};
