import React, { useEffect, useMemo, useState } from 'react';

import { Nullable } from '~/types';

import { InteractionHighlightActionsMenu } from './InteractionHighlightActionsMenu';
import { HighlightCallback, InteractionHighlight } from './types';

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
  onHighlightClick?: HighlightCallback;
  onHighlightCreate?: HighlightCallback;
}

/**
 * This hook returns the interaction highlight state, actions, position, and a helper render function for the actions menu.
 */
export const useInteractionHighlight = ({
  containerRef,
  onHighlightClick,
  onHighlightCreate,
}: Props) => {
  const [interactionHighlight, setInteractionHighlight] = useState<
    Nullable<InteractionHighlight>
  >(null);

  const clearInteractionHighlight = () => setInteractionHighlight(null);

  useEffect(() => {
    const container = containerRef?.current;
    if (container) {
      container.addEventListener('scroll', clearInteractionHighlight);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', clearInteractionHighlight);
      }
    };
  }, []);

  const {
    interactionHighlightActions,
    interactionHighlightPosition,
  } = useMemo(() => {
    const interactionHighlightPosition = interactionHighlight?.position;
    switch (interactionHighlight?.interaction) {
      case 'click':
        return {
          interactionHighlightActions:
            onHighlightClick?.getActions?.(interactionHighlight.highlight) ??
            [],
          interactionHighlightPosition,
        };
      case 'create':
        return {
          interactionHighlightActions:
            onHighlightCreate?.getActions?.(interactionHighlight.highlight) ??
            [],
          interactionHighlightPosition,
        };
      default:
        return {
          interactionHighlightActions: [],
          interactionHighlightPosition,
        };
    }
  }, [interactionHighlight, onHighlightClick, onHighlightCreate]);

  const renderInteractionHighlightActionsMenu = () => {
    return (
      interactionHighlightPosition &&
      interactionHighlightActions.length > 0 && (
        <InteractionHighlightActionsMenu
          actions={interactionHighlightActions}
          position={interactionHighlightPosition}
          onCloseMenu={clearInteractionHighlight}
        />
      )
    );
  };

  return {
    interactionHighlight,
    interactionHighlightActions,
    interactionHighlightPosition,
    clearInteractionHighlight,
    renderInteractionHighlightActionsMenu,
    setInteractionHighlight,
  };
};
