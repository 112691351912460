import { createActions } from '~/utils/redux';

export const {
  workflowLoad,
  workflowReset,
  workflowClearFile,
  workflowSetReviewPhaseName,
  workflowReviewAddApprover,
  workflowRemoveReviewApproval,
  workflowRemoveReviewPhase,
  workflowSetReviewCoordinator,
  workflowToggleReviewConditionRule,
  workflowToggleReviewApprovalType,
  workflowSetReviewApprovalCount,
  workflowSetReviewApprovalOrder,
  workflowRemoveReviewApprovalCount,
  workflowSetReviewPhaseDescription,
  workflowSortReviewPhases,
  workflowSetReviewApprovalTitle,
  workflowSetReviewApprovalDescription,
  workflowSetReviewPhaseApprovers,
  workflowAddReviewPhase,
  workflowAddSigner,
  workflowAddSigningPhase,
  workflowDeleteCondition,
  workflowRemoveSigner,
  workflowRemoveSigningPhase,
  workflowSetCondition,
  workflowSetPartiesSigners,
  workflowSetSignCoordinator,
  workflowSetSigner,
  workflowSetSignerConditionRule,
  workflowSetSignPhaseName,
  workflowSortSignPhases,
  workflowUpdateSetup,
  workflowSortFormSections,
  workflowCreateFormSection,
  workflowRemoveFormSection,
  workflowSetFormDescription,
  workflowSetFormSectionName,
  workflowSortFormQuestions,
  workflowRemoveQuestion,
  workflowSetSectionDescription,
  workflowSetSectionQuestion,
  workflowCreateSectionQuestion,
  workflowSetFinalizeTaskName,
  workflowAddFinalizeTask,
  workflowRemoveFinalizeTask,
  workflowSetFinalizeTaskApprovalTitle,
  workflowSetFinalizeTaskApprovalDescription,
  workflowToggleFinalizeConditionRule,
  workflowToggleFinalizeApprovalType,
  workflowAddFinalizeApproval,
  workflowRemoveFinalizeApproval,
  workflowSetFinalizeCoordinator,
  workflowSetFinalizeApprovalCount,
  workflowSetFinalizeApprovalOrder,
  workflowRemoveFinalizeApprovalCount,
  workflowToggleFinalizeCondition,
  workflowSetFinalizeTaskDescription,
  workflowSortFinalizeTasks,
  workflowSetFinalizeTaskApproval,
  workflowSetArchiveLocation,
  workflowSetArchiveLocationFilePath,
  workflowSetDefaultTicketName,
  workflowSetTicketNameConventions,
  workflowAddDataFieldsMapping,
  workflowRemoveDataFieldsMapping,
  workflowSetDataFieldsMapping,
  workflowSetSettings,
  workflowUpdatePrefillableIntakeFormLink,
  workflowSetNotifications,
  workflowSetCreatorPermissions,
  workflowSetFullAccessPermissions,
  workflowSetCreatorFullAccess,
  workflowSetIsTurnTrackingEnabled,
  // fields
  workflowSetConditionalTextField,
  workflowCreateCustomField,
  workflowFieldAssign,
  workflowFieldUnassign,
  // stage
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
} = createActions('workflow', [
  'load',
  'reset',
  'clearFile',
  'setReviewPhaseName',
  'reviewAddApprover',
  'removeReviewApproval',
  'removeReviewPhase',
  'setReviewCoordinator',
  'toggleReviewConditionRule',
  'toggleReviewApprovalType',
  'setReviewApprovalCount',
  'setReviewApprovalOrder',
  'removeReviewApprovalCount',
  'setReviewPhaseDescription',
  'sortReviewPhases',
  'setReviewApprovalTitle',
  'setReviewApprovalDescription',
  'setReviewPhaseApprovers',
  'addReviewPhase',
  'addSigner',
  'addSigningPhase',
  'deleteCondition',
  'removeSigner',
  'removeSigningPhase',
  'setCondition',
  'setPartiesSigners',
  'setSignCoordinator',
  'setSigner',
  'setSignerConditionRule',
  'setSignPhaseName',
  'sortSignPhases',
  'updateSetup',
  'sortFormSections',
  'createFormSection',
  'removeFormSection',
  'setFormDescription',
  'setFormSectionName',
  'sortFormQuestions',
  'removeQuestion',
  'setSectionDescription',
  'setSectionQuestion',
  'createSectionQuestion',
  'setFinalizeTaskName',
  'addFinalizeTask',
  'removeFinalizeTask',
  'setFinalizeTaskApprovalTitle',
  'setFinalizeTaskApprovalDescription',
  'toggleFinalizeConditionRule',
  'toggleFinalizeApprovalType',
  'addFinalizeApproval',
  'removeFinalizeApproval',
  'setFinalizeCoordinator',
  'setFinalizeApprovalCount',
  'setFinalizeApprovalOrder',
  'removeFinalizeApprovalCount',
  'toggleFinalizeCondition',
  'setFinalizeTaskDescription',
  'sortFinalizeTasks',
  'setFinalizeTaskApproval',
  'setArchiveLocation',
  'setArchiveLocationFilePath',
  'setDefaultTicketName',
  'setTicketNameConventions',
  'addDataFieldsMapping',
  'removeDataFieldsMapping',
  'setDataFieldsMapping',
  'setSettings',
  'updatePrefillableIntakeFormLink',
  'setNotifications',
  'setCreatorPermissions',
  'setFullAccessPermissions',
  'setCreatorFullAccess',
  'setIsTurnTrackingEnabled',
  // fields
  'setConditionalTextField',
  'setFieldSettings',
  'createCustomField',
  'fieldAssign',
  'fieldUnassign',
  // stage
  'setStageConditionRule',
  'toggleSkipStage',
]);
