import React, { memo } from 'react';

import { UserAction } from '../../../types';
import { Text } from '../../Text';
import { Card } from '../Card';

export interface Props {
  /** Card contents */
  children: React.ReactNode;
  /** Card title */
  title: string;
  /** Card description */
  description?: string;
  /** Optional header actions */
  actions?: UserAction[];
  /** Optional more actions */
  moreActions?: UserAction[];
}

export const SummaryCard = memo(
  ({ actions, children, moreActions, title, description }: Props) => {
    return (
      <Card
        header={{
          actions,
          moreActions,
          title,
        }}
        mode="bordered"
      >
        {description && (
          <Text preset="description" pb={4}>
            {description}
          </Text>
        )}
        {children}
      </Card>
    );
  },
);
