import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '~/ui';

export const statusStyles = {
  text: {
    borderRadius: '4px',
    fontSize: '14px',
    padding: '6px 12px',
  },
  success: {
    background: 'green-100',
    color: 'green-500',
  },
  warn: {
    background: 'yellow-100',
    color: 'peach-800',
  },
  danger: {
    background: 'red-100',
    color: 'red-600',
  },
  muted: {
    background: 'gray-100',
    color: 'gray-900',
  },
  noBackground: {
    background: 'none',
    padding: 0,
  },
};

function StatusText({
  children,
  noBackground = false,
  status,
  variant = '2xs-dense-bold',
}) {
  const statusStyle = statusStyles[status] || statusStyles.muted;
  return (
    <Text
      bg={noBackground ? undefined : statusStyle.background}
      color={statusStyle.color}
      px={3}
      py="6px"
      variant={variant}
      sx={{ borderRadius: 'm' }}
    >
      {children}
    </Text>
  );
}

StatusText.propTypes = {
  children: PropTypes.node,
  noBackground: PropTypes.bool,
  status: PropTypes.oneOf(['muted', 'success', 'warn', 'danger']).isRequired,
};

export default StatusText;
