import { UploadStatusType } from '~/enums';
import { pilot } from '~/services';
import { DocumentField, Nullable, PilotId, VisibilityLevel } from '~/types';

import { List } from '../ApiTypes';
import { camelizeKeys, ParsedType } from '../transformers';

type ViewSetFieldApi = {
  field_id: PilotId | string;
  field_name: string;
};

const OTHER_DOCUMENT_FIELDS: ViewSetFieldApi[] = [
  { field_id: 'document_group_id', field_name: 'Document Group' },
];

export type ViewSetField = ParsedType<ViewSetFieldApi>;

type FolderDataItem = {
  id: PilotId;
  name: string;
  file_type: string;
  date_added: string;
  date_modified: string;
  document_id: null;
  user_added: string;
  type: 'document' | 'folder';
  document_fields: DocumentField[];
  processing_status: Nullable<UploadStatusType>;
  user_visibility_level: VisibilityLevel;
  document_types: string[];
  children_count_top_level: { folder: number; document: number };
};

export const getDocumentsViewsetFields = async ({
  hasDocumentGroupPermission,
}: {
  hasDocumentGroupPermission: boolean;
}): Promise<ViewSetField[]> => {
  const viewSetField = (await pilot.get(
    'viewset/fields/',
  )) as ViewSetFieldApi[];

  const documentFields = hasDocumentGroupPermission
    ? [...viewSetField, ...OTHER_DOCUMENT_FIELDS]
    : viewSetField;

  return documentFields.map((value) => camelizeKeys(value));
};

export const getFolderData = ({
  folderId,
  order,
  orderBy,
  page,
  pageSize,
}: {
  folderId: Nullable<PilotId>;
  orderBy: string;
  order: string;
  page: number;
  pageSize: number;
}): Promise<List<FolderDataItem>> => {
  const contentUrl = folderId
    ? `folder/content/${folderId}/?order_by_field=${orderBy}&order=${order}&page=${page}&page_size=${pageSize}`
    : `folder/content/?order_by_field=${orderBy}&order=${order}&page=${page}&page_size=${pageSize}`;
  return pilot.get(contentUrl);
};
