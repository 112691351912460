import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { scrollElementIntoView } from '~/eds';

import styles from './EcProvisionsTable.styles';

class EcProvisionsTable extends Component {
  componentDidMount() {
    const { clauseIdToScroll } = this.props;
    if (clauseIdToScroll) {
      setTimeout(() => {
        scrollElementIntoView(clauseIdToScroll);
      }, 300);
    }
  }

  renderProvisionsTableBody(classes, provisions) {
    const sortedProvisions = provisions
      ? provisions.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
        )
      : [];
    if (sortedProvisions.length) {
      return sortedProvisions.map((provision, index) => (
        <tr key={index}>
          <td className={classes.provisionsBodyTitle}>{provision.name}</td>
          <td className={classes.provisionsBodyText}>
            {provision.content.map((content, index) => (
              <div key={index}>{content}</div>
            ))}
          </td>
        </tr>
      ));
    } else {
      return null;
    }
  }

  render() {
    const { classes, provisions } = this.props;

    return (
      <table className={classes.provisionsTable}>
        <thead>
          <tr>
            <th className={classes.provisionsHeaderTitle}>Provision Title</th>
            <th className={classes.provisionsHeaderText}>Provision Text</th>
          </tr>
        </thead>
        <tbody>{this.renderProvisionsTableBody(classes, provisions)}</tbody>
      </table>
    );
  }
}

EcProvisionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  provisions: PropTypes.array.isRequired,
};

export default injectSheet(styles)(EcProvisionsTable);
