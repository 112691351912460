import { SearchableFieldId } from '~/redux/api/methods';
import { PilotId } from '~/types';

import { pilot } from '../services';

interface GetGroupDocumentsParams {
  groupId: PilotId;
  parentId?: PilotId | SearchableFieldId;
  page: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  fields: any[];
  documentGroupIds?: number[];
}

interface DocumentPath {
  id: number;
  name: string;
}
interface DocumentHandler {
  document_name: string;
  file_type: string;
  id: number;
  path: DocumentPath[];
}
interface DocumentFieldValues {
  [fieldId: string]: {
    value: unknown[];
  } | null;
}

export interface DocumentGroupRelation {
  document_id: number | null;
  group_id: number;
  handlers: DocumentHandler[] | null;
  id: number;
  number_of_children: number;
  parent_id: null | number;
  selected_fields: DocumentFieldValues;
}

export interface GetGroupDocumentsResult {
  count: number;
  next: null | string;
  previous: null | string;
  results: DocumentGroupRelation[];
}

export const getGroupDocuments = ({
  groupId,
  parentId,
  page = 1,
  pageSize = 20,
  sortBy,
  sortOrder,
  fields = [],
  documentGroupIds,
}: GetGroupDocumentsParams): Promise<GetGroupDocumentsResult> => {
  const url = `/group/${groupId}/lookup-children/`;
  const params = {
    parent_id: parentId,
    page,
    page_size: pageSize,
    order_by_field: sortBy,
    order: sortOrder,
  };
  const payload = {
    selected_fields: fields,
    excluded_trees: documentGroupIds,
  };

  return pilot.post(url, payload, { params });
};
