import { useStyles } from 'uinix-ui';

import {
  ActionsMenu,
  Icon,
  IconButton,
  Layout,
  TruncateText,
  types,
} from '~/eds';

interface BaseFilterProps<V = string> {
  filterText: string;
  name: string;
  options: types.Option<V>[];
  value: V;
  onChange: (value: V) => void;
}

export const BaseFilter = <V,>({
  filterText,
  name,
  options,
  value,
  onChange,
}: BaseFilterProps<V>) => {
  const styles = useStyles();

  const trigger = (
    <Layout
      align="center"
      styles={[componentStyles.base, styles.colors.status]}
      styleProps={{
        status: !!value ? 'active' : undefined,
      }}
    >
      <Layout
        align="center"
        as="button"
        borderRadius="m"
        color="inherit"
        h="100%"
        minW={0}
        px={2}
        spacing={2}
        styles={[styles.button.unset, componentStyles.leftContent]}
      >
        <Icon
          icon="field-dropdown"
          color={!!value ? 'status.active' : undefined}
        />
        <TruncateText color="text.primary" variant="tiny" whiteSpace="nowrap">
          {filterText}
        </TruncateText>
        {value && (
          <IconButton
            icon="x"
            size="s"
            preset="clear-all"
            onClick={() => onChange(null as V)}
          />
        )}
      </Layout>
    </Layout>
  );

  return (
    <ActionsMenu
      onActionClick={onChange}
      name={name}
      trigger={trigger}
      actions={options}
    />
  );
};

const componentStyles = {
  base: {
    border: 'border',
    borderRadius: 'm',
    height: 'filter.height',
    outline: 'none',
    maxWidth: '100%',
    width: 'min-content',
  },
  leftContent: {
    borderTopLeftRadius: 'm',
    borderBottomLeftRadius: 'm',
    flex: '0 1 auto',
  },
  disabled: {
    opacity: '50%',
  },
};
