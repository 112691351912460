import React, { useEffect, useMemo } from 'react';

import { INPUT_DEBOUNCE_MS, Select, types, useDebounce } from '~/eds';
import { RoleType } from '~/enums';
import { api } from '~/redux';
import { Nullable } from '~/types';

type Props = {
  name: string;
  clientId?: string;
  endpoint: keyof typeof api.endpoints;
  value: Nullable<string>;
  onChange: (value: Nullable<string>) => void;
};

export const RolesSelect = ({
  name,
  clientId,
  value,
  onChange,
  ...rest
}: Props) => {
  const [
    getRoles,
    { isFetching, data },
  ] = api.endpoints.getRolesSimple.useLazyQuery();

  const options = useMemo(() => {
    if (!data?.results) return [];
    return data.results.map((item) => ({
      label: item.name,
      value: item.id,
      description: item.roleType,
    }));
  }, [data?.results]);

  const groupedItems: Array<types.Option<RoleType, unknown>> = useMemo(
    () =>
      Object.values(
        options.reduce((acc: Record<RoleType, types.Option>, item) => {
          const { description, label, value } = item;
          if (!acc[description]) {
            acc[description] = {
              label: description,
              options: [],
              value: `${description}-${value}`,
            };
          }
          acc[description].options!.push({ label, value });
          return acc;
        }, {} as Record<RoleType, types.Option>),
      ) as Array<types.Option<RoleType, unknown>>,
    [options],
  );

  const handleSearch = useDebounce((search: string) => {
    getRoles({
      page: 1,
      pageSize: 100,
      name: search,
      client_id: clientId,
    });
  }, INPUT_DEBOUNCE_MS);

  useEffect(() => {
    getRoles({
      page: 1,
      pageSize: 100,
      name: '',
      client_id: clientId,
    });
  }, [clientId]);

  return (
    <Select
      isLoading={isFetching}
      options={groupedItems}
      name={name}
      value={value}
      onChange={onChange}
      onSearch={handleSearch}
      isMulti={false}
      isSearchable={true}
      {...rest}
    />
  );
};
