import { chunk } from 'lodash';

import { PermissionResponse } from '~/redux/types';
import * as apiServices from '~/services';
import { Entity, PilotId } from '~/types';

const { everest } = apiServices;
const CHUNK_SIZE = 1000;

type GetEntityPermissionsParams = {
  userIds: PilotId[];
  entity: Entity<'ticket'>;
  enableChunkSpliting?: boolean;
};

/**
 * This method will work for entities of type 'ticket'
 */
export const checkUsersPermissions = async ({
  userIds,
  entity,
  enableChunkSpliting = false,
}: GetEntityPermissionsParams): Promise<PermissionResponse[]> => {
  const userIdsChunks = enableChunkSpliting
    ? chunk(userIds, CHUNK_SIZE)
    : [userIds];
  const permissionPromises = userIdsChunks.map((userIdsChunk) => {
    const data = {
      entityType: entity.type,
      entityId: entity.id,
      userIds: userIdsChunk,
    };
    return everest.post(
      '/permissions/check-user-accessibility/',
      data,
    ) as Promise<PermissionResponse>;
  });

  return ((await Promise.all(
    permissionPromises,
  )) as unknown) as PermissionResponse[];
};
