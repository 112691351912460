export default `
<svg
  aria-hidden="true"
  aria-label="arrow-up"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M6.20304 10.7344L11.453 5.23438C11.6093 5.07812 11.7968 5.01562 12.0155 5.01562C12.203 5.01562 12.3905 5.07812 12.5468 5.23438L17.7968 10.7344C18.078 11.0469 18.078 11.5156 17.7655 11.7969C17.4843 12.0781 16.9843 12.0781 16.703 11.7656L12.7655 7.60938V18.2344C12.7655 18.6719 12.4218 18.9844 12.0155 18.9844C11.6405 18.9844 11.2655 18.6719 11.2655 18.2344V7.60938L7.29679 11.7656C7.01554 12.0781 6.51554 12.0781 6.23429 11.7969C5.92179 11.5156 5.92179 11.0156 6.20304 10.7344Z"
    fill="currentcolor"
  />
</svg>
`;
