import { useEffect, useState } from 'react';

import { passwordsValidation } from '~/utils/password';

const emptyValidationResult = {
  validations: {},
};

export function usePasswordsWithValidation() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationResult, setValidationResult] = useState(
    emptyValidationResult,
  );

  useEffect(() => {
    const validation = passwordsValidation(password, confirmPassword);
    setValidationResult(validation);
  }, [password, confirmPassword]);

  return [
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    validationResult,
  ];
}
