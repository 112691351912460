import {
  black1,
  black2,
  semibold,
} from '../../../assets/shared-styles/general';

export const styles = {
  modalInputLabel: {
    display: 'block',
    marginBottom: '10px',
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
  },
  modalErrorMessage: {
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    marginTop: '24px',
    padding: '12px 16px',
    backgroundColor: 'rgba(243, 55, 55, 0.1)',
    color: black2,
    fontSize: '15px',
  },
};
