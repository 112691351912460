import React from 'react';
import { useStyles } from 'uinix-ui';

import { ActionsMenu, Box, Layout, Link } from '../../components';
import { SubNavLink } from '../../types';
import { testIsActiveLink } from './utils';

interface Props {
  activePathname: string;
  links: SubNavLink[];
}

export const SubNav = ({ activePathname, links }: Props) => {
  const styles = useStyles();

  return (
    <Box as="nav" aria-label="sub navigation" bg="background" flex="auto">
      <Layout as="ul" variant="unset.list">
        {links.map((link) => {
          const { label, pathname, onClick } = link;
          const isActiveLink = testIsActiveLink(activePathname)(link);
          const { children } = link;

          const isMenu = Boolean(children);

          const content = children ? (
            <ActionsMenu
              icon="chevron-down"
              name={`${label} menu`}
              text={label}
              tooltip=""
              actions={children.map((childLink) => ({
                ...childLink,
                mode: childLink.isEmphasized ? 'link' : undefined,
                value: childLink.pathname,
              }))}
            />
          ) : (
            <Box maxW="navlink.max-width">
              <Link
                shouldTruncate
                color="text.primary"
                isActive={isActiveLink}
                pathname={pathname}
                variant="body-medium"
                onClick={onClick}
              >
                {label}
              </Link>
            </Box>
          );

          return (
            <Layout
              key={pathname}
              align="center"
              as="li"
              flex="none"
              styles={isMenu ? null : styles.tabs.tab}
              styleProps={{ isSelected: isActiveLink }}
            >
              {content}
            </Layout>
          );
        })}
      </Layout>
    </Box>
  );
};
