import uuid from 'uuid';

import { FieldId, Filter } from '~/evifields';

export const addFilters = (
  filters: Filter[],
  selectedIds: string[],
): Filter[] => {
  return filters.concat(Object.values(selectedIds).map(createFilter));
};

export const clearFilter = (filter: Filter) => ({
  ...filter,
  asyncValue: undefined,
  operatorId: null,
  hasError: false,
  values: [],
});

export const clearFilterValues = (filter: Filter): Filter => ({
  ...filter,
  asyncValue: undefined,
  hasError: false,
  values: [],
});

export const createFilter = (fieldId: FieldId) => ({
  id: uuid.v4(),
  fieldId,
  operatorId: null,
  values: [],
});

export const removeFilter = (
  filters: Filter[],
  filterToRemove: Filter,
): Filter[] => filters.filter((filter) => filter.id !== filterToRemove.id);

export const updateFilter = (
  filters: Filter[],
  updatedFilter: Filter,
): Filter[] =>
  filters.map((filter) =>
    filter.id === updatedFilter.id ? updatedFilter : filter,
  );
