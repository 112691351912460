import React, { useMemo } from 'react';

import { Select } from '~/eds';
import { api } from '~/redux';
import { Nullable, PilotId } from '~/types';

interface Props {
  value: Nullable<PilotId>;
  onChange: (updatedValue: Nullable<PilotId>) => void;
}

export const FieldSectionSelect = ({ value, onChange }: Props) => {
  const {
    data = { results: [] },
    isFetching: isLoading,
  } = api.endpoints.getFieldSections.useQuery({
    limit: 1000,
  });

  const options = useMemo(
    () =>
      data.results.map((section) => ({
        label: section.label,
        value: section.id,
      })),
    [data],
  );

  return (
    <Select
      isLoading={isLoading}
      isMulti={false}
      name="select field section"
      options={options}
      placeholder="Select field section"
      value={value}
      onChange={onChange}
    />
  );
};
