import React from 'react';

import { Box, CollapsibleText, Layout } from '~/eds';
import { BaseClause } from '~/types';

interface Props {
  clauses: BaseClause[];
}

export const Clause = ({ clauses }: Props) => {
  return (
    <Layout direction="column">
      {clauses.map((clause) => (
        <Box key={clause.id} px={6} py={4} role="listitem">
          <CollapsibleText key={clause.id} text={clause.content} limit={180} />
        </Box>
      ))}
    </Layout>
  );
};
