import PropTypes from 'prop-types';
import React from 'react';

import { evisortBlue } from '../../../assets/shared-styles/general';

const EditIcon = (props) => {
  const { blue, color, onClick } = props;

  let iconColor = color;
  if (blue) {
    iconColor = evisortBlue;
  }

  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      {...(onClick && { onClick })}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          fill={iconColor}
          fillOpacity={props.opacity}
          fillRule="nonzero"
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm14.758-8.508a.83.83 0 0 0 0-1.175l-1.95-1.95a.83.83 0 0 0-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
        />
      </g>
    </svg>
  );
};

EditIcon.defaultProps = {
  size: '20',
  opacity: '1',
  color: 'currentColor',
};

EditIcon.propTypes = {
  white: PropTypes.bool,
  size: PropTypes.string,
  opacity: PropTypes.string,
};

export default EditIcon;
