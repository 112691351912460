import {
  Form,
  Layout,
  PageLayout,
  requiredValidator,
  TextInput,
  types,
  useToast,
  uuidValidator,
} from '~/eds';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { generateNav, RoutePathType } from '~/routing';

import { ClientSelect } from './ClientSelect';

const current = {
  text: 'Copy Workflow',
  pathname: RoutePathType.AdminConsoleClientCopyWorkflow,
};

const fields = {
  sourceWorkflowId: {
    order: 1,
    key: 'sourceWorkflowId',
    type: 'field' as const,
    name: 'sourceWorkflowId',
    label: 'Source Workflow ID',
    input: TextInput,
    placeholder: 'e.g 00000000-0000-0000-0000-000000000000',
    required: true,
  },
  destinationEnvironment: {
    order: 2,
    key: 'destinationEnvironment',
    type: 'field' as const,
    name: 'destinationEnvironment',
    label: 'Destination Environment',
    input: ClientSelect,
    required: true,
  },
  destinationWorkflowId: {
    order: 3,
    key: 'destinationWorkflowId',
    type: 'field' as const,
    name: 'destinationWorkflowId',
    label: 'Destination Workflow ID',
    input: TextInput,
    placeholder: 'e.g 00000000-0000-0000-0000-000000000000',
    required: false,
  },
};

type CopyWorkflowFormState = {
  sourceWorkflowId: string;
  destinationEnvironment: string;
  destinationWorkflowId: string;
};

const initialState = {
  sourceWorkflowId: { value: '' },
  destinationEnvironment: { value: '' },
  destinationWorkflowId: { value: '' },
};

const CopyWorkflowPage = () => {
  const currentUser = useCurrentUser();
  const nav = generateNav({
    current,
    from: RoutePathType.AdminConsoleClient,
    params: {
      clientId: currentUser.client,
    },
  });
  const { toast } = useToast();

  const [
    copyWorkflow,
    { isLoading: isCopying },
  ] = api.endpoints.copyWorkflow.useMutation();

  const handleOnSubmit = async (
    formState: types.Form.StateUpdater<CopyWorkflowFormState>,
  ) => {
    const {
      sourceWorkflowId,
      destinationEnvironment,
      destinationWorkflowId,
    } = formState.state;

    try {
      await copyWorkflow({
        sourceWorkflowId: sourceWorkflowId.value,
        destinationClientId: Number(destinationEnvironment.value),
        destinationWorkflowId: destinationWorkflowId.value,
      }).unwrap();
      toast({
        message: 'Workflow copied successfully.',
        status: 'success',
      });
    } catch (error: any) {
      const detail =
        error?.response?.data?.errors?.[0]?.detail ??
        error?.response?.data?.data ??
        '';
      toast({
        message: `There was an error when copying the workflow. ${detail}`,
        status: 'danger',
      });
    }
  };

  return (
    <PageLayout
      nav={nav}
      title="Copy Workflow"
      description="Copy a workflow from one client to another."
    >
      <Layout spacing={4} direction="column" w="500px">
        <Form<CopyWorkflowFormState>
          fields={fields}
          state={initialState}
          onSubmit={handleOnSubmit}
          submitButtonProps={{
            text: 'Copy Workflow',
            variant: 'primary',
            isLoading: isCopying,
          }}
          validators={[
            requiredValidator(['sourceWorkflowId', 'destinationEnvironment']),
            uuidValidator(['sourceWorkflowId', 'destinationWorkflowId']),
          ]}
        />
      </Layout>
    </PageLayout>
  );
};

export default CopyWorkflowPage;
