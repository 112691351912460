import { wopi } from '~/services';

export interface WopiStatic {
  urlPreloadView: string;
  urlPreloadEdit: string;
}

export interface WopiStaticResponse {
  url_preload_view: string;
  url_preload_edit: string;
}

export const getWopiStaticFiles = async (): Promise<WopiStatic> => {
  const result: WopiStaticResponse = await wopi.get(`/static_content`);

  return {
    urlPreloadView: result.url_preload_view,
    urlPreloadEdit: result.url_preload_edit,
  };
};
