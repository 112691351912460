import React, { memo } from 'react';

import { formatFileSize } from '../../utils/formatFileSize';
import { Divider } from '../Divider';
import { Filename } from '../Filename';
import { IconButton } from '../IconButton';
import { Layout } from '../Layout';
import { Text } from '../Text';

interface Props {
  /** List of added files */
  files: File[];
  /** Remove file callback */
  onRemoveFile: (removedFile: File, removedFileIndex?: number) => void;
  /** List title */
  title: string;
}

export const Files = memo(({ files, onRemoveFile, title }: Props) => {
  if (files.length === 0) return null;
  return (
    <Layout preset="subsections">
      <Divider label={`${title} (${files.length})`} />
      <Layout direction="column" role="list">
        {files.map((file, index) => {
          return (
            <Layout key={index} justify="space-between" role="listitem">
              <Filename name={file.name} includeExtension={true} />
              <Layout align="center" preset="xs">
                <Text>{formatFileSize(file.size)}</Text>
                <IconButton
                  icon="x"
                  onClick={() => onRemoveFile(file, index)}
                  tooltip={`Remove ${file.name}`}
                />
              </Layout>
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  );
});
