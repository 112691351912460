import { useMemo } from 'react';

import { DropdownFieldValue, SearchFilter } from '~/types';

import { attachRenderersAndSerializers } from './utils';

type Props = {
  filters: SearchFilter[];
  fieldValues: {
    [fieldId: string]: DropdownFieldValue[];
  };
};

export const useFieldsWithLazyLoad = ({ filters, fieldValues }: Props) => {
  const searchFilterItems = useMemo(
    () => attachRenderersAndSerializers({ filters, fieldValues }),
    [filters, fieldValues],
  );

  return searchFilterItems;
};
