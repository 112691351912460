import React from 'react';

import { Box, FileSystem } from '../../../components';
import { SharedFileSystemProps } from '../../../types';

export const FileSystemCell = ({
  onClick,
  ...props
}: SharedFileSystemProps) => {
  return (
    <Box onClick={onClick} minW={0}>
      <FileSystem {...props} />
    </Box>
  );
};
