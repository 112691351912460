import React, { memo } from 'react';
import { Placement } from 'tippy.js';

import { TooltipType } from '../../types';
import { Popover } from '../Popover';
import { Text } from '../Text';

type Props = {
  /** The tooltip is triggered by wrapping its children */
  children: React.ReactElement;
  /** If the tooltip can be interacted (i.e. supports pointer events) */
  isInteractive?: boolean;
  /** Renders an inverted (white) tooltip */
  isInverse?: boolean;
  /** Control the visibility explicitly */
  isVisible?: boolean;
  /** Tooltip content (prefer simple strings) */
  tooltip?: TooltipType;
  /** Optional styles assigned on the trigger node */
  triggerStyle?: React.CSSProperties;
  /** Trigger width */
  width?: number | string;
  /** placement of the tooltip */
  placement?: Placement;
};

export const Tooltip = memo(
  ({
    children,
    isInteractive = false,
    isInverse,
    isVisible,
    tooltip,
    triggerStyle = {},
    placement,
    width,
  }: Props) => {
    if (!tooltip) {
      return children;
    }

    const tooltipContent =
      typeof tooltip === 'string' ? (
        <Text color="inherit" variant="tiny" whiteSpace="pre-wrap">
          {tooltip}
        </Text>
      ) : (
        tooltip
      );

    return (
      <Popover
        appendTo={document.body}
        containerStyle={{
          width,
        }}
        isInteractive={isInteractive}
        isVisible={isVisible}
        preset={isInverse ? 'tooltip.inverse' : 'tooltip'}
        trigger={children}
        triggerStyle={{
          ...triggerStyle,
          width,
        }}
        placement={placement}
      >
        {tooltipContent}
      </Popover>
    );
  },
);
