import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';

import EcFilterInput from '~/components/Shared/EcFilterInput';
import { Modal, Text } from '~/eds';
import { FlagType, withFlags } from '~/flags';
import { getHighlights } from '~/redux/api/methods';
import { uploadDocuments } from '~/slices/fileUpload';

import {
  MODAL_DELETE,
  MODAL_NAME,
  MODAL_VERSION_DOCUMENT_VIEWER,
} from '../../../types/modal.types';
import { ERROR, SUCCESS } from '../../../types/toast.types';
import { createVersionListFromVersionData } from '../../Modals/VersionDocumentViewerModal/VersionDocumentViewerModal.utils';
import EcCard from '../../Shared/EcCard';
import EcModal from '../../Shared/EcModal';
import EcPaginate from '../../Shared/EcPaginate';
import { showToast } from '../../Shared/EcToast';
import EcVersionsTable from '../../Shared/EcVersionsTable';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import {
  deleteDocumentVersion,
  editDocumentVersion,
  getDocumentVersions,
  replaceDocumentVersion,
  restoreDocumentVersion,
  updateDocumentVersionOrder,
} from './DocumentVersions.data';
import styles from './DocumentVersions.styles';
import { DocumentVersionViewer } from './DocumentVersionViewer';

class DocumentVersions extends Component {
  constructor(props) {
    super(props);

    this.loadDocumentVersions = this.loadDocumentVersions.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.onVersionReorder = this.onVersionReorder.bind(this);
    this.onVersionEdit = this.onVersionEdit.bind(this);
    this.onVersionReplace = this.onVersionReplace.bind(this);
    this.onVersionRestore = this.onVersionRestore.bind(this);
    this.onVersionDelete = this.onVersionDelete.bind(this);
    this.handleFileAttachment = this.handleFileAttachment.bind(this);

    this.state = {
      page: 1,
      pageCount: 1,
      pageSize: 10,
      filterValue: '',
      selectedItem: null,
      versionsData: [],
      warningModal: {
        isVisible: false,
        item: null,
        file: null,
      },
    };
  }

  componentDidMount() {
    this.loadDocumentVersions();
  }

  loadDocumentVersions(searchText = null) {
    const { documentId } = this.props;
    const { page } = this.state;

    getDocumentVersions(documentId, page, searchText)
      .then((data) => {
        this.setState({
          versionsData: data.results,
          pageCount: data.count,
          loading: false,
          errorLoading: false,
        });
      })
      .catch(() => this.setState({ loading: false, errorLoading: true }));
  }

  handlePageClick(clickedPage) {
    const selectedPage = clickedPage.selected + 1;
    this.setState({ page: selectedPage }, this.loadDocumentVersions);
  }

  handleShowToast(type, text) {
    showToast(type, text);
  }

  handleShowModal(modal, selectedItem) {
    this.setState({
      modal,
      selectedItem,
    });
  }

  handleHideModal() {
    this.setState({
      modal: null,
      selectedItem: null,
    });
  }

  handleVersionSearch = (filterValue) => {
    this.setState({ filterValue });
    this.loadDocumentVersions(filterValue);
  };

  renderDocumentViewerModal() {
    const {
      selectedItem,
      pdfFile,
      versionsData,
      page,
      pageCount,
      pageSize,
    } = this.state;

    const { flags } = this.props;

    const versionList = createVersionListFromVersionData(versionsData);

    if (flags[FlagType.UnifyDocumentViewer]) {
      return (
        <DocumentVersionViewer
          onHide={this.handleHideModal}
          selectedVersion={selectedItem}
          versions={versionList}
        />
      );
    }
    return (
      <EcModal
        modalType={MODAL_VERSION_DOCUMENT_VIEWER}
        width="660px"
        title="Document Viewer"
        versionList={versionList}
        documentObject={selectedItem}
        pdfFile={pdfFile}
        loadDocument={this.loadDocument}
        hideModal={this.handleHideModal}
        handleShowModal={this.handleShowModal}
        page={page}
        totalPageCount={Math.ceil(pageCount / pageSize)}
        pageSize={pageSize}
        documentId={this.props.documentId}
      />
    );
  }

  onVersionReorder(data) {
    const { documentId, reloadDocumentContent } = this.props;

    updateDocumentVersionOrder(data, documentId)
      .then(() => {
        this.handleShowToast(
          SUCCESS,
          'The document version has been reordered.',
        );
        this.loadDocumentVersions();
        reloadDocumentContent();
      })
      .catch((error) => {
        const message =
          error.response?.data?.Error ??
          'An error occurred while reordering the document version.';
        this.handleShowToast(ERROR, message);
        this.loadDocumentVersions();
      });
  }

  onVersionEdit(file_name, description) {
    const { reloadDocumentContent, documentId } = this.props;
    const { id } = this.state.selectedItem;

    editDocumentVersion(id, file_name, description, documentId)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Document version has been updated.');
        this.loadDocumentVersions();
        reloadDocumentContent();
      })
      .catch(() =>
        this.handleShowToast(
          ERROR,
          'An error occurred while editing document version.',
        ),
      );
  }

  handleFileAttachment() {
    this.setState({ fileAttached: this.refs.fileContainer.files[0] }, () => {
      const blob = new Blob([this.state.fileAttached], {
        type: 'application/json',
      });
      const fileData = new FormData();
      fileData.append('file', new File([blob], this.state.fileAttached.name));
      this.onVersionReplace(this.refs.fileContainer.files[0]);
    });
  }

  onVersionReplace(item, file) {
    const { documentId } = this.props;
    const blob = new Blob([file], {
      type: 'application/json',
    });
    const fileData = new FormData();
    fileData.append('file', new File([blob], file.name));

    replaceDocumentVersion(item.id, fileData, documentId)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Document version has been replaced.');
        this.closeWarningModal();
        this.loadDocumentVersions();
        this.reloadDocumentContent();
      })
      .catch(() =>
        this.handleShowToast(
          ERROR,
          'An error occurred while replacing the document version.',
        ),
      );
  }

  onVersionReplaceClick = async (item, file) => {
    const highlights = await getHighlights({ version_id: item.id });
    if (highlights?.length) {
      this.setState({
        ...this.state,
        warningModal: { isVisible: true, item, file },
      });
    } else {
      this.onVersionReplace(item, file);
    }
  };

  closeWarningModal = () => {
    this.setState({
      ...this.state,
      warningModal: { isVisible: false, item: null, file: null },
    });
  };

  onVersionRestore(item) {
    const { reloadDocumentContent, documentId } = this.props;

    restoreDocumentVersion(item.id, documentId)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Document version has been restored.');
        this.loadDocumentVersions();
        reloadDocumentContent();
      })
      .catch(() =>
        this.handleShowToast(
          ERROR,
          'An error occurred while restoring the document version.',
        ),
      );
  }

  onVersionDelete() {
    const { documentId, reloadDocumentContent } = this.props;
    const { id } = this.state.selectedItem;

    deleteDocumentVersion(id, documentId)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Document version has been deleted.');
        this.loadDocumentVersions();
        reloadDocumentContent();
      })
      .catch(() =>
        this.handleShowToast(
          ERROR,
          `An error occurred while deleting document version #${id}.`,
        ),
      );
  }

  renderEditVersionModal() {
    const { selectedItem } = this.state;
    const title = `Edit ${selectedItem.version_tag}`;

    return (
      <EcModal
        modalType={MODAL_NAME}
        width="560px"
        title={title}
        labelText="FILE NAME"
        includeTextArea={true}
        labelTextArea="DESCRIPTION"
        hideModal={this.handleHideModal}
        handleShowModal={this.handleShowModal}
        confirmButtonText="Save"
        itemsCurrentName={selectedItem ? selectedItem.file_name : ''}
        itemsCurrentDescription={selectedItem ? selectedItem.description : ''}
        handleNameChange={this.onVersionEdit}
      />
    );
  }

  renderDeleteVersionModalText() {
    const { selectedItem } = this.state;

    return (
      <div>
        Are you sure you want to delete <b>version {selectedItem.version}</b> of
        the document? You won’t be able to undo this action.
      </div>
    );
  }

  renderDeleteVersionModal() {
    return (
      <EcModal
        modalType={MODAL_DELETE}
        width="560px"
        title="Delete Version?"
        text={this.renderDeleteVersionModalText()}
        confirmButtonText="Delete"
        deleteItem={this.onVersionDelete}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderSearchBox() {
    const { classes } = this.props;
    const { filterValue, versionsData } = this.state;

    return (
      <div className={classes.searchboxContainer}>
        <EcFilterInput
          aria-describedby="doc-versions-found-status"
          debounceTimeout={300}
          id="search-version-input"
          value={filterValue}
          onChange={(filterValue) => this.handleVersionSearch(filterValue)}
          onClearSearch={() => this.handleVersionSearch('')}
          placeholder="Search versions..."
        />
        <div
          aria-live="assertive"
          className="screenReaderText"
          id="doc-versions-found-status"
          role="status"
        >
          {!filterValue.length
            ? null
            : versionsData.length
            ? `${versionsData.length} item(s) found`
            : 'No items found'}
        </div>
      </div>
    );
  }

  renderVersionTable() {
    const { classes, userVisibilityLevel } = this.props;
    const { versionsData, loading, errorLoading, filterValue } = this.state;

    if (loading && !errorLoading) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    } else if (errorLoading && !loading) {
      return <div>An error occurred while loading the version data.</div>;
    }
    return (
      <EcCard>
        {this.renderSearchBox()}
        <EcVersionsTable
          versionsData={versionsData}
          userVisibilityLevel={userVisibilityLevel}
          filterValue={filterValue}
          handleShowModal={this.handleShowModal}
          handleVersionReorder={this.onVersionReorder}
          handleVersionReplace={this.onVersionReplaceClick}
          handleVersionRestore={this.onVersionRestore}
        />
      </EcCard>
    );
  }

  uploadNewVersion = async () => {
    const { warningModal } = this.state;
    const { uploadDocuments, documentId } = this.props;
    const { file } = warningModal;

    const fileToUpload = new File([file], file.name, { type: file.type });
    fileToUpload.upload = { uuid: uuidV4() };

    await uploadDocuments({
      files: [fileToUpload],
      apiConfig: {
        service: 'pilot',
        method: 'post',
        url: `/version/document/${documentId}/`,
      },
    }).unwrap();
    this.closeWarningModal();
    this.loadDocumentVersions();
    this.reloadDocumentContent();
  };

  reloadDocumentContent = () => {
    const { reloadDocumentContent } = this.props;
    setTimeout(() => {
      reloadDocumentContent();
    }, 5000);
  };

  renderPagination() {
    const { page, pageCount, pageSize } = this.state;

    if (pageCount <= pageSize) return null;

    return (
      <EcPaginate
        onPageChange={this.handlePageClick}
        pageCount={Math.ceil(pageCount / pageSize)}
        forcePage={page - 1}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { modal } = this.state;

    return (
      <Fragment>
        {this.renderVersionTable()}
        <span className={classes.horizontalSeparator} />
        {this.renderPagination()}
        {modal === MODAL_NAME ? this.renderEditVersionModal() : null}
        {modal === MODAL_DELETE ? this.renderDeleteVersionModal() : null}
        {modal === MODAL_VERSION_DOCUMENT_VIEWER
          ? this.renderDocumentViewerModal()
          : null}
        <Modal
          title="Warning"
          onHide={this.closeWarningModal}
          isVisible={this.state.warningModal.isVisible}
          primaryAction={{
            onClick: () =>
              this.onVersionReplace(
                this.state.warningModal.item,
                this.state.warningModal.file,
              ),
            text: 'Replace',
            variant: 'danger',
          }}
          secondaryActions={[
            {
              text: 'Upload new version',
              onClick: this.uploadNewVersion,
            },
          ]}
          onCancel={this.closeWarningModal}
        >
          <Text>
            Replacing this document version will overwrite comments in the
            current version. If you need to retain comments in the current
            versions, upload a new version instead.
          </Text>
          <Text>Do you want to continue ?</Text>
        </Modal>
      </Fragment>
    );
  }
}

DocumentVersions.propTypes = {
  classes: PropTypes.object.isRequired,
};

DocumentVersions = connect(null, { uploadDocuments })(DocumentVersions);

export default injectSheet(styles)(withFlags(DocumentVersions));
