import { keyframes } from '@emotion/core';
import React, { useEffect } from 'react';

import UserName from '~/components/Client/UserName';
import { showToast } from '~/components/Shared/EcToast';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { DateText, IconButton } from '~/eds';
import { api } from '~/redux';
import { ERROR, SUCCESS } from '~/types/toast.types';
import {
  Box,
  EditableText,
  FlexLayout,
  Switch,
  Text,
  theme,
  Tooltip,
  useHover,
} from '~/ui';

const fadeKeyframe = keyframes({
  from: {
    backgroundColor: theme.colors['blue-100'],
  },
  to: {
    backgroundColor: 'white',
  },
});

function ApiKeyRow({ activeKey, disabled, item, shouldAnimate, removeItem }) {
  const [ref, isHovered] = useHover();

  const [updateApiKey, updateResult] = api.endpoints.updateApiKey.useMutation();
  const { isLoading, isError, isSuccess } = updateResult;

  useEffect(() => {
    if (isSuccess) {
      showToast(SUCCESS, 'API Key updated.');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(ERROR, 'Update API Key failed. Please try again.');
    }
  }, [isError]);

  function editKey(id, data) {
    updateApiKey({ id, data });
  }

  function editName(id, name) {
    editKey(id, { name });
  }

  function toggleStatus(id, is_enabled) {
    editKey(id, { is_enabled });
  }

  return (
    <FlexLayout
      role="row"
      bg={isHovered ? 'gray-200' : 'white'}
      px={6}
      py={4}
      ref={ref}
      sx={{
        borderTop: 'border',
        height: '68px',
        animation: shouldAnimate ? `${fadeKeyframe} 2s` : 'none',
      }}
    >
      <FlexLayout
        alignItems="center"
        flexGrow="1"
        pr={4}
        sx={{ flexBasis: '300px' }}
      >
        <Box sx={{ display: 'inline-block' }}>
          <EditableText
            disabled={disabled || isLoading}
            isOutsideHover={isHovered}
            placeholder="Untitled API Key"
            value={item.name}
            onChange={(value) => editName(item.id, value)}
          />
        </Box>
      </FlexLayout>
      <FlexLayout
        alignItems="flex-start"
        flexDirection="column"
        justifyContent="center"
        sx={{ width: '300px' }}
      >
        <>
          <DateText
            date={item.date_created ? new Date(item.date_created) : undefined}
            format="full_datetime"
            variant="tiny"
          />
          <Text color="gray-600" variant="2xs-dense">
            &nbsp;by&nbsp;
            <UserName userId={item.user_id} />
          </Text>
        </>
      </FlexLayout>
      <FlexLayout
        alignItems="flex-start"
        flexDirection="column"
        justifyContent="center"
        sx={{ width: '300px' }}
      >
        <DateText
          date={
            item.date_last_accessed
              ? new Date(item.date_last_accessed)
              : undefined
          }
          format="full_datetime"
          variant="tiny"
        />
      </FlexLayout>
      <FlexLayout
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '220px' }}
      >
        <FlexLayout space={4}>
          <Tooltip
            content={
              !disabled && !item.is_enabled && activeKey
                ? `Disable currently active API Key (${
                    activeKey?.name || 'Untitled API Key'
                  }) to enable this item.`
                : null
            }
          >
            <Switch
              disabled={
                disabled || isLoading || (activeKey && activeKey.id !== item.id)
              }
              size="s"
              value={item.is_enabled}
              onChange={() => toggleStatus(item.id, !item.is_enabled)}
            />
          </Tooltip>
          <Text color="gray-900" variant="s-dense">
            {item.is_enabled ? 'Enabled' : 'Disabled'}
          </Text>
        </FlexLayout>
        <FlexLayout
          alignItems="center"
          justifyContent="center"
          sx={{ width: '45px' }}
        >
          {isLoading ? (
            <LoadingSpinner size="small" />
          ) : (
            isHovered &&
            !disabled && (
              <IconButton
                icon="trash"
                text="remove"
                tooltip="Remove"
                onClick={() => removeItem(item)}
              />
            )
          )}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
}

export default ApiKeyRow;
