import { checkPermission } from '~/redux/api/methods';
import { RbacPermission } from '~/types';

export const checkPermissions = async (permissions: RbacPermission[]) => {
  const permissionPromises = permissions.map((permission) => {
    return checkPermission(permission).catch(() => {
      return null;
    });
  });

  const permissionResponses = await Promise.all(permissionPromises);
  const result: Record<string, Record<string, boolean>> = {};
  for (let i = 0; i < permissionResponses.length; i++) {
    const permissionResponse = permissionResponses[i];
    const permission = permissions[i];
    result[permission.resourceId] = result[permission.resourceId] || {};
    result[permission.resourceId][
      permission.resourceType
      // @ts-ignore false positive of resolved data attribute
    ] = !!permissionResponse?.data?.attributes?.permission;
  }
  return result;
};
