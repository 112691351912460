import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BaseLoadView from '~/components/Shared/BaseLoadView';
import { TableViewContextType } from '~/enums';
import { selectors } from '~/redux';
import search from '~/redux/slices/search';

const LoadView = ({ onViewSelected }) => {
  const modal = useSelector(selectors.selectSearchResultModal);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(search.actions.setModal());
  const { modalType } = modal || {};

  return (
    <BaseLoadView
      context={TableViewContextType.SEARCH}
      modalType={modalType}
      hideModal={hideModal}
      onViewSelected={onViewSelected}
    />
  );
};

export default LoadView;
