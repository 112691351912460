import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  ActionsMenu,
  Button,
  Layout,
  LoadingShimmer,
  Panel,
  Text,
  useToast,
} from '~/eds';
import { api } from '~/redux';
import {
  CompletedTurnTrackingHistoryType,
  Nullable,
  TurnTrackingHistoryType,
  Uuid,
} from '~/types';

import { EditCyclePanel } from './EditCyclePanel';
import { PartySelectField } from './PartySelectField';
import { ProgressBar } from './ProgressBar';
import { TurnTrackingHistory } from './TurnTrackingHistory';
import {
  isEntryUpdated,
  partyNameFromPartyIdAndParties,
  timeFieldErrors,
} from './utils';

interface Props {
  ticketId: Uuid;
  workflowId: Uuid;
  position?: 'right';
  width?: 'm';
  isHidden?: boolean;
  onHide?: () => void;
}

export const TurnTrackingPanel = ({
  ticketId,
  workflowId,
  position,
  width,
  isHidden,
  onHide,
}: Props) => {
  const { toast } = useToast();
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [isCurrentTurn, setIsCurrentTurn] = useState(false);
  const [entryDraft, setEntryDraft] = useState<
    Nullable<TurnTrackingHistoryType>
  >(null);
  const [entryReference, setEntryReference] = useState<
    Nullable<TurnTrackingHistoryType>
  >(null);
  const [
    showInitiateResponsibleParty,
    setShowInitiateResponsibleParty,
  ] = useState(false);
  const [isCreatingNewEntry, setIsCreatingNewEntry] = useState(false);
  const [partyId, setPartyId] = useState<Nullable<Uuid>>(null);
  const isTicketList = !!useRouteMatch('/workflow/tickets')?.isExact;

  const [
    createEntry,
    createEntryResult,
  ] = api.endpoints.createTurnTrackingEntry.useMutation();
  const { isLoading: isCreateEntryLoading } = createEntryResult;
  const [
    updateEntry,
    updateEntryResult,
  ] = api.endpoints.updateTurnTrackingEntry.useMutation();
  const { isLoading: isUpdateEntryLoading } = updateEntryResult;

  const isLoadingActionButton = isCreateEntryLoading || isUpdateEntryLoading;

  const {
    data: historyData = [],
    isError: historyError,
    isLoading: isLoadingHistory,
  } = api.endpoints.getTurnTrackingHistory.useQuery({
    ticketId,
  });

  const {
    data: partiesData = [],
    isError: partiesError,
    isLoading: isLoadingParties,
  } = api.endpoints.getTurnTrackingParties.useQuery({ workflowId });

  // ticket name needed on TicketListV2, may move call there after workflow parties discussion
  const { data: ticketData } = api.endpoints.getTicketSummary.useQuery({
    ticketId,
  });

  useEffect(() => {
    if (historyError) {
      toast({
        message: 'Try again in a few minutes.',
        title: 'We’re experiencing difficulty loading History.',
        status: 'danger',
      });
    }
  }, [historyError]);

  useEffect(() => {
    if (partiesError) {
      toast({
        message: 'Try again in a few minutes.',
        title: 'We’re experiencing difficulty loading Responsible Parties.',
        status: 'danger',
      });
    }
  }, [partiesError]);

  const onBack = () => {
    setShowEditPanel(false);
    setEntryDraft(null);
    setEntryReference(null);
    setShowInitiateResponsibleParty(false);
    setIsCreatingNewEntry(false);
  };

  const handleOnSubmit = async () => {
    try {
      if (!isCreatingNewEntry && !showInitiateResponsibleParty) {
        await updateEntry({ ticketId, entry: entryDraft! }).unwrap();
        onBack();
        toast({
          message: 'Cycle time updated',
          status: 'success',
        });
      } else {
        await createEntry({
          ticketId,
          partyId: showInitiateResponsibleParty
            ? partyId!
            : entryDraft!.partyId,
        }).unwrap();
        onBack();
        const message =
          'Current responsible party updated' +
          (isTicketList
            ? ` for "${ticketData?.name}". It will take a few seconds for changes to be reflected on Tickets list.`
            : '');
        toast({
          message,
          status: 'success',
        });
      }
    } catch (error: any) {
      toast({
        title: 'Sorry, something has gone wrong',
        message: error?.response?.data?.data,
        status: 'danger',
      });
    }
  };

  const currentPartyId = historyData[0]?.partyId;
  const currentPartyName = partyNameFromPartyIdAndParties(
    currentPartyId,
    partiesData,
  );

  const errorMessages = timeFieldErrors(entryDraft, ticketData?.createdDate);
  const disableSave =
    !isEntryUpdated(entryDraft, entryReference) ||
    !entryDraft?.partyId ||
    errorMessages.length > 0;

  const previousEntry = historyData.filter((turn, index) => {
    if (!entryDraft) return null;
    const nextEntry = historyData[index - 1];
    return nextEntry?.turnId === entryDraft.turnId;
  })[0];
  const nextEntry = historyData.filter((turn, index) => {
    if (!entryDraft) return null;
    const previousEntry = historyData[index + 1];
    return previousEntry?.turnId === entryDraft.turnId;
  })[0];

  const previousEndTime = previousEntry?.endTime || null;
  previousEndTime?.setSeconds(0);
  previousEndTime?.setMilliseconds(0);
  const subsequentStartTime = nextEntry?.startTime || null;
  subsequentStartTime?.setSeconds(0);
  subsequentStartTime?.setMilliseconds(0);

  entryDraft?.startTime?.setSeconds(0);
  entryDraft?.startTime?.setMilliseconds(0);
  entryDraft?.endTime?.setSeconds(0);
  entryDraft?.endTime?.setMilliseconds(0);

  const timesOverlap =
    (previousEndTime &&
      entryDraft?.startTime &&
      entryDraft.startTime < previousEndTime) ||
    (subsequentStartTime &&
      entryDraft?.endTime &&
      entryDraft.endTime > subsequentStartTime);
  if (timesOverlap) {
    errorMessages.push(
      'Start and end times should not overlap with other turns for this ticket.',
    );
  }

  const filterCompleted = (
    turnTracking: TurnTrackingHistoryType,
  ): turnTracking is CompletedTurnTrackingHistoryType => !!turnTracking.endTime;

  const renderLoadingState = () => {
    return (
      <Layout direction="column" spacing={4}>
        <Layout h="40px" mt={4}>
          <LoadingShimmer />
        </Layout>
        <Layout h="40px">
          <LoadingShimmer />
        </Layout>
        <Layout h="40px">
          <LoadingShimmer />
        </Layout>
      </Layout>
    );
  };

  const renderInitiateResponsibleParty = () => {
    return (
      <PartySelectField
        onChange={(partyId) => setPartyId(partyId)}
        parties={partiesData}
      />
    );
  };

  const renderEmptyState = () => {
    return (
      <Layout
        direction="column"
        justify="center"
        align="center"
        h="100%"
        spacing={4}
      >
        <Layout direction="column">
          <Text variant="body-bold">
            There are no cycle time entries for this document.
          </Text>
          <Text>Set a currently responsible party to create an entry.</Text>
        </Layout>
        <Button
          variant="primary"
          text="Set Responsible Party"
          onClick={() => {
            setShowInitiateResponsibleParty(true);
          }}
        />
      </Layout>
    );
  };

  const renderPanelContent = () => {
    return (
      <>
        {isTicketList && (
          <Layout direction="column" m={2}>
            <Text variant="tiny" color="text.secondary">
              Ticket name
            </Text>
            <Text variant="body-bold">{ticketData?.name}</Text>
          </Layout>
        )}
        <Layout>
          <Layout direction="column" m={2}>
            <Text variant="tiny" color="text.secondary">
              Currently with
            </Text>
            <Text variant="body-bold">{currentPartyName ?? 'Not Set'}</Text>
          </Layout>
          <ActionsMenu
            actions={[{ label: 'Edit', value: 'Edit' }]}
            name="more actions"
            onActionClick={() => {
              setIsCurrentTurn(true);
              setShowEditPanel(true);
              const latestEntry = cloneDeep(historyData[0]);
              setEntryDraft(latestEntry);
              // clone entry to avoid editing comparison reference
              setEntryReference(cloneDeep(latestEntry));
            }}
          />
        </Layout>
        <Layout direction="column" m={3}>
          <ProgressBar history={historyData} parties={partiesData} />
        </Layout>
        {historyData.length >= 2 ? (
          <Layout direction="column" m={2}>
            <Layout mb={'8px'} mt={'8px'}>
              <Text variant="section">History</Text>
            </Layout>
            <Layout direction="column" spacing={2}>
              {historyData
                .filter(filterCompleted)
                .map((historyEntry: CompletedTurnTrackingHistoryType) => {
                  return (
                    <TurnTrackingHistory
                      parties={partiesData}
                      historyEntry={historyEntry}
                      onShowEditPanel={() => {
                        setIsCurrentTurn(false);
                        setShowEditPanel(true);
                      }}
                      onSetEditingEntry={(entry) => {
                        setEntryDraft(entry);
                        // clone entry to avoid editing comparison reference
                        setEntryReference(cloneDeep(entry));
                      }}
                    />
                  );
                })}
            </Layout>
          </Layout>
        ) : null}
      </>
    );
  };

  const renderingLogic = () => {
    if (isLoadingHistory || isLoadingParties) {
      return renderLoadingState();
    } else if (showInitiateResponsibleParty) {
      return renderInitiateResponsibleParty();
    } else if (historyData.length === 0) {
      return renderEmptyState();
    } else {
      return renderPanelContent();
    }
  };

  if (showEditPanel && entryDraft && entryReference) {
    return (
      <EditCyclePanel
        history={historyData}
        parties={partiesData}
        disableSave={disableSave}
        entry={entryDraft!}
        entryReference={entryReference!}
        previousEntry={previousEntry}
        nextEntry={nextEntry}
        errorMessages={errorMessages}
        isCurrentTurn={isCurrentTurn}
        isLoadingActionButton={isLoadingActionButton}
        isCreatingNewEntry={isCreatingNewEntry}
        {...(position && { position })}
        {...(width && { width })}
        {...(isHidden && { isHidden })}
        {...(!!onHide && { onHide })}
        onBack={onBack}
        onSubmit={handleOnSubmit}
        onEditEntry={setEntryDraft}
        onToggleCreatingNewEntry={(bool) => setIsCreatingNewEntry(bool)}
      />
    );
  }

  return (
    <Panel
      title="Cycle Time Tracker"
      {...(position && { position })}
      {...(width && { width })}
      {...(!!onHide && { hidden: { isHidden, onHide } })}
      {...(showInitiateResponsibleParty && {
        footer: {
          actions: [
            {
              text: 'Set Responsible Party',
              level: 'primary',
              onClick: handleOnSubmit,
              isLoading: isLoadingActionButton,
              disabled: !partyId,
            },
          ],
        },
      })}
    >
      {renderingLogic()}
    </Panel>
  );
};
