import { createSelector } from '@reduxjs/toolkit';

import { name, State } from '../slices/turnTracking';

const selectSlice = (rootState: any) => rootState[name] as State;

export const selectTurnTrackingState = createSelector(
  selectSlice,
  (state) => state,
);

export const selectTurnTrackingParties = createSelector(
  selectSlice,
  (state) => state.parties,
);

export const selectTurnTrackingHistory = createSelector(
  selectSlice,
  (state) => state.history,
);
