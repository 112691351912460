import {
  black2,
  bold,
  evisortBlue,
  evisortRed,
  evisortYellow,
  semibold,
} from '../../../assets/shared-styles/general';

const algoStatusDot = {
  margin: '4px 7px',
  width: '8px',
  height: '8px',
  display: 'flex',
  borderRadius: '8px',
};

const styles = {
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'normal',
  },
  statusName: {
    marginLeft: '4px',
    color: black2,
    fontSize: '15px',
  },
  statusProgress: {
    fontStyle: 'italic',
  },
  uploadStatusTooltip: {
    display: 'flex',
    whiteSpace: 'normal',
    lineHeight: '26px',
    '& svg': {
      marginTop: '3px',
    },
  },
  smartTooltip: {
    marginLeft: '5px',
    padding: '0 7px',
    height: '22px',
    borderRadius: '2px',
    backgroundColor: black2,
    color: '#fff',
    fontSize: '11px',
    wordBreak: 'break-word',
    cursor: 'pointer',
  },
  algoName: {
    fontSize: '14px',
    fontWeight: bold,
  },
  statusMessage: {
    marginLeft: '21px',
    fontSize: '10px',
  },
  statusErrorMessage: {
    marginLeft: '21px',
    color: evisortRed,
    fontSize: '10px',
    fontWeight: semibold,
  },
  algoContainer: {
    marginBottom: '10px',
  },
  algoStatusButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '& svg': {
      marginTop: '4px',
    },
  },
  algoStatusGreenDot: {
    ...algoStatusDot,
    backgroundColor: '#519811',
  },
  algoStatusRedDot: {
    ...algoStatusDot,
    backgroundColor: evisortRed,
  },
  algoStatusYellowDot: {
    ...algoStatusDot,
    backgroundColor: evisortYellow,
  },
  algoStatusBlueDot: {
    ...algoStatusDot,
    backgroundColor: evisortBlue,
  },
};

export default styles;
