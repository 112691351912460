export default `
<svg
  aria-hidden="true"
  aria-label="more"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M15.5 12C15.5 11.1875 16.1562 10.5 17 10.5C17.8125 10.5 18.5 11.1875 18.5 12C18.5 12.8438 17.8125 13.5 17 13.5C16.1562 13.5 15.5 12.8438 15.5 12ZM10.5 12C10.5 11.1875 11.1562 10.5 12 10.5C12.8125 10.5 13.5 11.1875 13.5 12C13.5 12.8438 12.8125 13.5 12 13.5C11.1562 13.5 10.5 12.8438 10.5 12ZM8.5 12C8.5 12.8438 7.8125 13.5 7 13.5C6.15625 13.5 5.5 12.8438 5.5 12C5.5 11.1875 6.15625 10.5 7 10.5C7.8125 10.5 8.5 11.1875 8.5 12Z"
    fill="currentcolor"
  />
</svg>
`;
