// pilot api
export enum DataFieldOperators {
  IsBlank = 'is_blank',
  IsNotBlank = 'is_not_blank',

  LessThan = 'less_than',
  LessThanOrEqualsTo = 'less_than_or_equals_to',
  Exactly = 'exactly',
  GreaterThanOrEqualsTo = 'greater_than_or_equal_to',
  GreaterThan = 'greater_than',
  Between = 'between',

  ContainsText = 'contains_text',
  DoesNotContainText = 'does_not_contain_text',

  DateBefore = 'before_date',
  DateEquals = 'on_date',
  DateAfter = 'after_date',
  DateBetween = 'between_dates',
  Next = 'next',
  Last = 'last',

  Equals = 'equals',
  DoesNotEqual = 'does_not_equal',

  ContainsAll = 'contains_all',
  ContainsAny = 'contains_any',
  Contains = 'contains',
  DoesNotContain = 'does_not_contain',
}
