import React from 'react';

import { UserActivity } from '~/components/Shared/UserActivity';
import {
  Checkbox,
  CollapsibleText,
  Footer,
  formatNumber,
  Layout,
  Link,
  StatusMessage,
  Text,
} from '~/eds';

import { Variation } from '../../../../types';

interface Props {
  isSelected?: boolean;
  variation: Variation;
}

const p = 4;

export const ClauseVariation = ({ isSelected = false, variation }: Props) => {
  const {
    content,
    contractsCount,
    id,
    name,
    generatedBy,
    generatedOn,
    variationsCount,
  } = variation;

  const actions = [
    { level: 'tertiary' as const, text: 'Delete', onClick: console.log },
    {
      level: 'secondary' as const,
      text: 'Move to Review',
      onClick: console.log,
    },
  ];

  const contractsCountText = formatNumber(contractsCount);
  const variationsCountText = formatNumber(variationsCount);

  const checkbox = (
    <Checkbox
      name={name}
      value={isSelected}
      option={{
        label: '',
        value: id,
      }}
      onChange={console.log}
    />
  );

  const moreVariations = variationsCount > 0 && (
    <StatusMessage
      action={{
        text: `Show All ${variationsCountText} Variations`,
        onClick: console.log,
      }}
      message={`${variationsCountText} Variations across ${contractsCountText} Contracts`}
    />
  );

  const footer = <Footer actions={actions} enableBorder={false} />;

  return (
    <Layout border="border.divider" direction="column" pt={p} spacing={p}>
      <Layout flex="auto" px={p} spacing={p}>
        {checkbox}
        <Layout direction="column" flex="auto" spacing={p}>
          <Layout direction="column" spacing={2}>
            <Text variant="body-bold">{name}</Text>
            <Link pathname="/">Appears in {contractsCountText} contracts</Link>
            <UserActivity
              activity="Generated"
              id={generatedBy}
              date={generatedOn}
            />
            <CollapsibleText text={content} />
          </Layout>
          {moreVariations}
        </Layout>
      </Layout>
      {footer}
    </Layout>
  );
};
