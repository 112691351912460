import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContentContainer, EmptyPage, Tabs } from '~/eds';
import { api, slices } from '~/redux';

import { AttemptModal } from './Modals/AttemptModal';
import { EndpointModal } from './Modals/EndpointModal';
import { AttemptConfiguration } from './Panels/AttemptConfiguration';
import { EndpointConfiguration } from './Panels/EndpointConfiguration';
import { Attempts } from './Tabs/Attempts';
import { Endpoints } from './Tabs/Endpoints';
import { TabType } from './types';

export const Webhooks = () => {
  const dispatch = useDispatch();
  const tab = useSelector(slices.webhooks.selectors.selectTab);

  const {
    data = { results: [], total: 0 },
    isLoading: isLoadingEndpoints,
    error: isErrorEndpoints,
  } = api.endpoints.getEndpoints.useQuery({
    page: 1,
    pageSize: 50,
  });

  useEffect(() => {
    if (data.total) {
      dispatch(slices.webhooks.actions.setEndpointsCount(data.total));
    }
  }, [data.total]);

  return (
    <>
      <ContentContainer
        loadingContent={{ isLoading: isLoadingEndpoints }}
        placeholderContent={
          isErrorEndpoints
            ? {
                message: 'Error loading webhooks, please try again',
                image: 'error-page-error',
              }
            : undefined
        }
      >
        {data.total > 0 ? (
          <Tabs<TabType>
            actions={
              tab === 'endpoints'
                ? [
                    {
                      text: 'Add Endpoint',
                      level: 'primary',
                      onClick: () => {
                        dispatch(
                          slices.webhooks.actions.setActiveEndpointType('add'),
                        );
                      },
                    },
                  ]
                : []
            }
            tabs={[
              {
                label: 'Endpoints',
                value: 'endpoints',
                panel: <Endpoints />,
              },
              {
                label: 'Attempts',
                value: 'attempts',
                panel: <Attempts />,
              },
            ]}
            selectedTab={tab}
            onSelectTab={(tab) => dispatch(slices.webhooks.actions.setTab(tab))}
          />
        ) : (
          <EmptyPage
            preset="no-webhooks"
            resolveAction={{
              level: 'primary',
              onClick: () =>
                dispatch(slices.webhooks.actions.setActiveEndpointType('add')),
              text: 'Add Endpoint',
            }}
          />
        )}
      </ContentContainer>
      <EndpointConfiguration />
      <AttemptConfiguration />
      <EndpointModal />
      <AttemptModal />
    </>
  );
};
