import { everest } from '~/services';
import { Workflow } from '~/types';

export const getWorkflows = async () => {
  const workflows = (await everest.get('/workflows/')) as any;
  return workflows.map((workflow: any) => ({
    ...workflow,
    isDraft: workflow.status === 'draft',
    isPublished: workflow.published,
    canPublish: workflow.publishable,
    lastModifiedBy: workflow.lastModifiedById,
  })) as Workflow[];
};
