import { black5, evisortGreen } from '../../../assets/shared-styles/general';

const toggleStyles = {
  display: 'flex',
  alignItems: 'center',
  width: (props) => (props.size === 's' ? '28px' : '56px'),
  height: (props) => (props.size === 's' ? '16px' : '32px'),
  border: 'solid 1px rgba(0, 0, 0, 0.1)',
  borderRadius: '16px',
  padding: '3px',
  cursor: 'pointer',
  transition: '.2s',
};

const styles = {
  toggleOn: {
    ...toggleStyles,
    backgroundColor: evisortGreen,
    '& div': {
      transform: (props) =>
        props.size === 's' ? 'translateX(12px)' : 'translateX(24px)',
    },
  },
  toggleOff: {
    ...toggleStyles,
    backgroundColor: black5,
    '& div': {
      transform: 'translateX(0px)',
    },
  },
  toggleButton: {
    width: (props) => (props.size === 's' ? '12px' : '24px'),
    height: (props) => (props.size === 's' ? '12px' : '24px'),
    backgroundColor: '#FFF',
    borderRadius: '50%',
    boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.5)',
    transition: '.2s',
  },
};

export default styles;
