import React from 'react';
import { useDispatch } from 'react-redux';

import {
  CreditBalance as BillingCreditBalance,
  CreditSummary,
} from '~/features/billing';
import { FlagType, useFlag } from '~/flags';
import { useClientId } from '~/hooks';
import { actions } from '~/redux';

import { getCreditsTooltip } from './utils';

export const CreditBalance = () => {
  const clientId = useClientId();

  const dispatch = useDispatch();

  const enableDocumentXRayBilling = useFlag(FlagType.DocumentXRayBilling);

  // TODO: remove this API and need for redux and reference credit value from RTKQ endpiont instead
  const onCreditSummary = (credit: CreditSummary) =>
    dispatch(actions.setFieldAiCreditsSummary(credit));

  if (!enableDocumentXRayBilling) {
    return null;
  }

  return (
    <BillingCreditBalance
      clientId={clientId}
      getCreditsTooltip={getCreditsTooltip}
      onCreditsSummaryAvailable={onCreditSummary}
    />
  );
};
