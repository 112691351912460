export default `
<svg
  aria-hidden="true"
  aria-label="expand"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M14.25 16.25C14.0312 16.25 13.8438 16.3125 13.7188 16.4688L12.75 17.4688V14.7812C12.75 14.3438 12.4062 14.0312 12 14.0312C11.5625 14.0312 11.25 14.3438 11.25 14.7812V17.4688L10.25 16.5C10.125 16.3438 9.9375 16.25 9.75 16.25C9.34375 16.25 9 16.5625 9 17C9 17.1875 9.0625 17.375 9.21875 17.5312L11.4688 19.7812C11.5312 19.875 11.7188 20 12 20C12.25 20 12.4375 19.875 12.5 19.7812L14.75 17.5312C14.9062 17.4062 15 17.2188 15 17C15 16.5938 14.625 16.25 14.25 16.25ZM9.75 7.75C9.9375 7.75 10.125 7.65625 10.25 7.53125L11.25 6.5625V9.25C11.25 9.6875 11.5625 10 12 10C12.4062 10 12.75 9.6875 12.75 9.25V6.5625L13.7188 7.53125C13.8438 7.6875 14.0312 7.75 14.25 7.75C14.8125 7.75 15 7.1875 15 7C15 6.8125 14.9062 6.625 14.75 6.5L12.5 4.25C12.3125 4.03125 12.0938 4 11.9688 4C11.875 4 11.6562 4.03125 11.4688 4.21875L9.21875 6.5C9.0625 6.625 9 6.8125 9 7C9 7.4375 9.34375 7.75 9.75 7.75ZM18.25 11.25H5.75C5.3125 11.25 5 11.5938 5 12C5 12.375 5.3125 12.75 5.75 12.75H18.25C18.6562 12.75 19 12.4375 19 12C19 11.5938 18.6562 11.25 18.25 11.25Z"
    fill="currentcolor"
  />
</svg>
`;
