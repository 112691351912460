import { BulkActionType } from '~/enums';

export const actionTypeTitle = (actionType: BulkActionType) => {
  switch (actionType) {
    case 'restore':
      return 'Restoring';
    case 'delete':
      return 'Deleting';
    case 'permanentlyDelete':
      return 'Permanently Deleting';
    case 'edit-clauses':
    case 'edit':
      return 'Editing';
    case 'move':
      return 'Moving';
    case 'copy':
      return 'Copying';
    case 'export':
      return 'Exporting';
  }
};

export const actionTypeContent = (actionType: BulkActionType) => {
  switch (actionType) {
    case 'restore':
      return 'restored';
    case 'delete':
      return 'deleted';
    case 'permanentlyDelete':
      return 'permanently deleted';
    case 'edit-clauses':
    case 'edit':
      return 'edited';
    case 'move':
      return 'moved';
    case 'export':
      return 'exported';
    case 'copy':
      return 'copied';
  }
};
