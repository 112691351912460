import {
  black2,
  black3,
  bold,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  tableEmptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '80px 0',
  },
  tableEmptyStateTitle: {
    margin: '30px 0 10px',
    color: black2,
    fontSize: '17px',
    fontWeight: semibold,
  },
  tableEmptyStateMessage: {
    marginBottom: '20px',
    width: '560px',
    color: black3,
    fontSize: '15px',
    textAlign: 'center',
  },
  documentTreeContent: {
    '& a': {
      textDecoration: 'none',
    },
  },
  footerButtonContainer: {
    marginTop: '30px',
  },
  groupText: {
    color: black3,
    fontSize: '13px',
    textTransform: 'uppercase',
  },
  groupName: {
    marginTop: '10px',
    color: black2,
    fontSize: '23px',
    fontWeight: bold,
  },
  groupCategory: {
    marginTop: '30px',
    color: black2,
    fontSize: '17px',
    fontWeight: bold,
  },
  groupCategoryNoMasterDocs: {
    marginTop: '20px',
    padding: '10px',
    width: '94%',
    border: '1px dashed #ffc449',
    backgroundColor: '#fffdf6',
  },
  groupCategoryEmptyDocs: {
    marginTop: '20px',
    color: black3,
  },
  viewGroupLink: {
    padding: '7px 12px',
    display: 'inline-flex',
    alignItems: 'center',
    color: black2,
    backgroundColor: 'transparent',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '14px',
    textDecoration: 'none',
    '& svg': {
      marginRight: '5px',
    },
    '&:visited': {
      color: black2,
    },
  },
};

export default styles;
