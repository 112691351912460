export const absolute = {
  bottom: 0,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
};

export const container = {
  mx: 'auto',
  maxWidth: 'container-width',
  width: '100%',
};

export const fullPage = {
  backgroundColor: 'white',
  bottom: 0,
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 'page',
};

export const ulReset = {
  ul: {
    listStyleType: 'none',
    p: 0,
  },
};

export const stretch = {
  height: '100%',
  width: '100%',
};
