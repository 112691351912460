import axios from 'axios';

import { fetchCsrfToken } from '~/api';
import AuthenticationStore from '~/auth';
import {
  refreshAccessToken,
  shouldRefreshAccessToken,
} from '~/auth/refreshAccessToken';
import { with401Retry } from '~/auth/with401Retry';
import { getEnvVariable } from '~/dev';
import { LOGIN_CONSTANTS } from '~/features/login';

/**
 * Creates a service abstraction around the AxiosInstance.  It currently only exposes common axios HTTP methods, but this can be expanded in the future.
 * @param {*} configuration: simple object containing just the URL.  Can be made more configurable in the future.
 */
export default function createService(configuration) {
  const instance = axios.create({
    baseURL: configuration.url,
    timeout: 120000,
    withCredentials: getEnvVariable('REACT_APP_CORS_REQUESTS') === 'true',
  });

  const AS = AuthenticationStore();
  const wasLoggedIn = AS.isLoggedIn();

  instance.interceptors.request.use(async (config) => {
    // do something before request is sent
    config.headers = {
      ...config.headers,
      'User-Timezone':
        new Intl.DateTimeFormat().resolvedOptions().timeZone ||
        'America/Vancouver',
    };

    // Fetch and set up CSRF token
    if (wasLoggedIn && !AS.getCsrf()) {
      await fetchCsrfToken();
    }

    if (AS.getCsrf()) {
      config.headers[LOGIN_CONSTANTS.HEADERS.CSRF_TOKEN] = AS.getCsrf();
    }

    if (wasLoggedIn && shouldRefreshAccessToken()) {
      await refreshAccessToken();
    }

    if (!AS.getAccessToken() || !AS.getRefreshToken()) {
      // for httponly support. No longer need access token or refresh token
      return config;
    }

    config.headers.Authorization = configuration.refresh_token
      ? `Bearer ${AS.getRefreshToken()}`
      : `Bearer ${AS.getAccessToken()}`;

    return config;
  });

  instance.interceptors.response.use((response) => {
    const { config, data } = response;
    return config.fullResponse ? response : data;
  }, configuration.errorHandler);

  if (configuration.shouldRetryOn401) {
    return {
      get: with401Retry(instance.get.bind(instance)),
      post: with401Retry(instance.post.bind(instance)),
      remove: with401Retry(instance.delete.bind(instance)),
      put: with401Retry(instance.put.bind(instance)),
      patch: with401Retry(instance.patch.bind(instance)),
      concurrentRequests: with401Retry(axios.all.bind(axios)),
    };
  }

  return {
    get: instance.get,
    post: instance.post,
    remove: instance.delete,
    put: instance.put,
    patch: instance.patch,
    concurrentRequests: axios.all,
  };
}
