import { noop, upperCase } from 'lodash';
import React from 'react';

import { Icon, Layout, Text, types } from '~/eds';

type Props = {
  value: 'and' | 'or';
  icon?: types.IconType;
};
export const Operator = ({ value, icon }: Props) => {
  return (
    <Layout
      onClick={noop}
      border="border"
      borderRadius="m"
      px={2}
      align="center"
      styles={{ height: 'filter.height', userSelect: 'none' }}
    >
      <Text>{upperCase(value)}</Text>
      {icon && <Icon icon={icon} />}
    </Layout>
  );
};
