import React, { useState } from 'react';
import { connect } from 'react-redux';

import { samlFormValueUpdate } from '~/actions';
import EcInput from '~/components/Shared/EcInput/EcInput';
import EcMultipleSelectValue from '~/components/Shared/EcMultipleSelectValue';
import EcMultiSelect from '~/components/Shared/EcMultiSelect';
import EcSelect from '~/components/Shared/EcSelect';
import EcTextarea from '~/components/Shared/EcTextarea';
import EcToggle from '~/components/Shared/EcToggle/EcToggle';
import { Button, Icon } from '~/eds';
import { SsoFieldType } from '~/enums';
import { SUCCESS } from '~/types/toast.types';
import { Box, FlexLayout, Text } from '~/ui';
import { copyToClipboard } from '~/utils/helper.utils';

import { testEmptyValue, testInvalidUrl, testValueError } from './util';

function SAMLConfigForm({
  saml,
  //actions
  samlFormValueUpdate,
}) {
  const MARGIN_OFFSET = 20;

  const [activeSection, setActiveSection] = useState(saml.sections[0].id);
  const sectionsContainer = React.createRef();

  const renderConfigs = ({ field, disabled }, onUpdate, error) => {
    const { type, value, options, description } = field;
    switch (type) {
      case SsoFieldType.SingleSelect: {
        return (
          <FlexLayout flexDirection="column" space={2}>
            <EcSelect
              width="300px"
              value={value}
              options={options}
              isDisabled={disabled}
              onChange={(selected) => onUpdate({ value: selected })}
              error={error}
            />
            {value?.descriptions.map((description) => (
              <Text color="gray-500" variant="xs-dense">
                {description}
              </Text>
            ))}
          </FlexLayout>
        );
      }
      case SsoFieldType.MultiSelect: {
        return (
          <FlexLayout flexDirection="column" space={2}>
            <EcMultiSelect
              width="300px"
              value={value || []}
              options={options}
              onChange={(values) => onUpdate({ value: values })}
              noOptionsMessage="No options present."
              placeholder="Pick an option..."
              getOptionValue={(data) => data.label}
              openMenuOnClick
              isDisabled={disabled}
              isCreatable={true}
              error={error}
            />
            <FlexLayout alignItems="center" space={2}>
              <Icon
                color="status.info"
                icon="arrow-forward"
                label="arrow forward"
              />
              <Box>
                {value && value.length ? (
                  value.map((cur) => (
                    <EcMultipleSelectValue
                      key={cur.value}
                      label={cur.label}
                      closeHandler={() => {
                        let updates = value.filter((val) => val !== cur);
                        onUpdate({ value: updates });
                      }}
                      isDisabled={disabled}
                    />
                  ))
                ) : (
                  <Text color="gray-500" variant="xs-dense">
                    No items selected...
                  </Text>
                )}
              </Box>
            </FlexLayout>
          </FlexLayout>
        );
      }
      case SsoFieldType.ShortResponse: {
        return (
          <EcInput
            width="100%"
            isDisabled={disabled}
            input={{
              value: value || '',
              onChange: (e) => onUpdate({ value: e.target.value }),
            }}
            error={error}
          />
        );
      }
      case SsoFieldType.LongResponse: {
        return (
          <EcTextarea
            disabled={disabled}
            width="100%"
            value={value || ''}
            onChange={(e) => onUpdate({ value: e.target.value })}
            error={error}
          />
        );
      }
      case SsoFieldType.Static: {
        return (
          <FlexLayout flexDirection="column" space={4}>
            <FlexLayout flexDirection="column" space={2}>
              {field.value.map((v) => (
                <>
                  <Text color="gray-700" variant="m-spaced-bold">
                    {v.title}
                  </Text>
                  <Text color="gray-600" variant="xs-dense">
                    {v.value}
                  </Text>
                </>
              ))}
            </FlexLayout>
          </FlexLayout>
        );
      }
      case SsoFieldType.Attributes: {
        return (
          <FlexLayout flexDirection="column" space={4}>
            <FlexLayout flexDirection="column" space={2}>
              <FlexLayout alignItems="center" space={1}>
                <Text color="gray-700" variant="m-spaced-bold">
                  Email
                </Text>
                <Text color="gray-500" variant="xs-dense">
                  (Attribute Name)
                </Text>
              </FlexLayout>
              <EcInput
                width="100%"
                isDisabled={disabled}
                input={{
                  value: value.email || '',
                  onChange: (e) => {
                    onUpdate({ value: { ...value, email: e.target.value } });
                  },
                }}
                error={error}
              />
            </FlexLayout>
            <FlexLayout flexDirection="column" space={2}>
              <FlexLayout alignItems="center" space={1}>
                <Text color="gray-700" variant="m-spaced-bold">
                  First Name
                </Text>
                <Text color="gray-500" variant="xs-dense">
                  (Attribute Name)
                </Text>
              </FlexLayout>
              <EcInput
                width="100%"
                isDisabled={disabled}
                input={{
                  value: value.firstName || '',
                  onChange: (e) => {
                    onUpdate({
                      value: { ...value, firstName: e.target.value },
                    });
                  },
                }}
                error={error}
              />
            </FlexLayout>
            <FlexLayout flexDirection="column" space={2}>
              <FlexLayout alignItems="center" space={1}>
                <Text color="gray-700" variant="m-spaced-bold">
                  Last Name
                </Text>
                <Text color="gray-500" variant="xs-dense">
                  (Attribute Name)
                </Text>
              </FlexLayout>
              <EcInput
                width="100%"
                isDisabled={disabled}
                input={{
                  value: value.lastName || '',
                  onChange: (e) => {
                    onUpdate({ value: { ...value, lastName: e.target.value } });
                  },
                }}
                error={error}
              />
            </FlexLayout>
          </FlexLayout>
        );
      }
      case SsoFieldType.Toggle: {
        return (
          <FlexLayout alignItems="center" space={4}>
            <FlexLayout sx={{ minWidth: '50px' }}>
              <EcToggle
                active={!!value}
                id="SAMLPage/ssoFieldTypeToggle"
                onClick={() => onUpdate({ value: !value })}
              />
            </FlexLayout>
            <Text
              color="gray-500"
              variant="xs-dense"
              id="SAMLPage/ssoFieldTypeToggle"
            >
              {description}
            </Text>
          </FlexLayout>
        );
      }
      default:
        return null;
    }
  };

  const onWindowScroll = () => {
    if (!sectionsContainer.current) return;

    const visibleElements = [];
    const sections = Array.from(sectionsContainer.current.firstChild.children);
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight,
    );

    sections.forEach((el) => {
      const rectangle = el.getBoundingClientRect();
      const isVisible = !(
        rectangle.bottom < MARGIN_OFFSET ||
        rectangle.top - viewHeight >= MARGIN_OFFSET
      );
      if (isVisible) visibleElements.push(el.id);
    });

    if (visibleElements.includes(sections[2].id)) {
      setActiveSection(sections[2].id);
    } else {
      if (activeSection !== visibleElements[0]) {
        setActiveSection(visibleElements[0]);
      }
    }
  };

  return (
    <Box
      bg="white"
      sx={{
        height: '80vh',
        overflowY: 'scroll',
      }}
      onScroll={onWindowScroll}
    >
      <FlexLayout
        mt={MARGIN_OFFSET}
        px={6}
        space={6}
        sx={{ minWidth: '1200px' }}
      >
        <Box sx={{ flex: '0 0 300px' }}>
          <FlexLayout
            flexDirection="column"
            space={6}
            sx={{
              position: 'sticky',
              top: MARGIN_OFFSET,
            }}
          >
            <FlexLayout flexDirection="column" space={4}>
              <Text color="gray-600" variant="xs-dense-caps">
                SECTIONS
              </Text>
              <Box>
                {saml.sections.map((section) => {
                  const indicatorColor =
                    activeSection === section.id ? 'blue-500' : 'blue-100';
                  return (
                    <FlexLayout
                      alignItems="center"
                      key={`sidebar-section-${section.id}`}
                      space={4}
                    >
                      <Box bg={indicatorColor} p="20px 2px" />
                      <Text variant="xs-dense-medium">{section.name}</Text>
                    </FlexLayout>
                  );
                })}
              </Box>
            </FlexLayout>
          </FlexLayout>
        </Box>
        <Box sx={{ flexGrow: 1 }} ref={sectionsContainer}>
          <FlexLayout
            flexDirection="column"
            space={8}
            px={10}
            sx={{ paddingBottom: '100px' }}
          >
            {saml.sections.map((section) => (
              <Box
                id={section.id}
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  borderRadius: 'm',
                }}
              >
                <FlexLayout
                  justifyContent="space-between"
                  p={4}
                  sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}
                >
                  <FlexLayout alignItems="center" space={2}>
                    <Text variant="l-dense-bold">{section.name}</Text>
                  </FlexLayout>
                </FlexLayout>
                <FlexLayout
                  alignItems="center"
                  flexDirection="column"
                  py={4}
                  space={8}
                  justifyContent="space-between"
                >
                  {section.configs
                    .filter(
                      (formField) =>
                        formField.field.type !== 'ROLE_SELECT' &&
                        formField.field.type !== 'CUSTOM_ATTRIBUTES',
                    )
                    .map((config) => (
                      <>
                        {config.visible ? (
                          <FlexLayout
                            flexDirection="row"
                            justifyContent="space-between"
                            sx={{ width: '100%' }}
                          >
                            <FlexLayout
                              flexDirection="column"
                              space={2}
                              px={4}
                              sx={{ width: '400px' }}
                            >
                              <Text color="gray-700" variant="m-spaced-bold">
                                {config.title}
                                {config.required && (
                                  <Text
                                    color="red-600"
                                    px={1}
                                    sx={{ verticalAlign: 'top' }}
                                  >
                                    *
                                  </Text>
                                )}
                              </Text>
                              {config.description && (
                                <Text color="gray-600" variant="xs-dense">
                                  {config.description}
                                </Text>
                              )}
                            </FlexLayout>
                            <FlexLayout
                              flexDirection="column"
                              alignItems="unset"
                              space={2}
                              px={25}
                              sx={{ width: '700px' }}
                            >
                              {renderConfigs(
                                config,
                                (opts) =>
                                  samlFormValueUpdate({
                                    sectionId: section.id,
                                    configId: config.id,
                                    value: opts.value,
                                  }),
                                testValueError(config),
                              )}
                              {testValueError(config) && (
                                <>
                                  {testEmptyValue(config) && (
                                    <Text color="red-600">
                                      {config.fieldName === 'attributes'
                                        ? 'All 3 attribute fields require a value'
                                        : "This field can't be empty"}
                                    </Text>
                                  )}
                                  {testInvalidUrl(config) && (
                                    <Text color="red-600">
                                      Please enter a valid value
                                    </Text>
                                  )}
                                </>
                              )}
                              {config.copiable && (
                                <Box>
                                  <Button
                                    text="Copy"
                                    onClick={() =>
                                      copyToClipboard(
                                        config.field.value,
                                        'Data copied to clipboard.',
                                        SUCCESS,
                                      )
                                    }
                                    variant="action"
                                  />
                                </Box>
                              )}
                            </FlexLayout>
                          </FlexLayout>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                </FlexLayout>
              </Box>
            ))}
          </FlexLayout>
        </Box>
      </FlexLayout>
    </Box>
  );
}

const mapStateToProps = ({ saml }) => ({ saml });

export default connect(mapStateToProps, { samlFormValueUpdate })(
  SAMLConfigForm,
);
