export default {
  page: '2',
  highlight: {
    normal: 1,
    'normal:active': 2,
    high: 3,
    'high:active': 4,
  },
  modal: '10',
  select: '11',
  sortableList: '11',
  tooltip: '11',
};
