import get from 'lodash/get';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectDocumentInGroup from '~/components/SearchV2/AddSearchResultsToGroupV2/SelectDocumentInGroup';
import { showToast } from '~/components/Shared/EcToast';
import { AddToGroupStepType, HttpStatusCodeType } from '~/enums';
import { api, selectors } from '~/redux';
import documentGroup from '~/redux/slices/documentGroup';
import { MODAL_ADD_DOCUMENTS_TO_GROUP } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { FlexLayout, LoadingContainer, Text, WizardPageLayout } from '~/ui';

import SearchDocumentsToAddToGroup from '../SearchDocumentsToAddToGroup';

const MAX_TITLE_WIDTH = 355;

const AddDocuments = ({ groupId, groupName }) => {
  const modal = useSelector(selectors.selectDocGroupModal);
  const { modalType } = modal || {};
  const step = useSelector(selectors.selectGroupPageAddToGroupStep);
  const selectedDocumentId = useSelector(
    selectors.selectDocGroupSelectedDocumentId,
  );
  const documentIds = useSelector(selectors.selectDocGroupSelectedDocumentIds);
  const shouldNextDisabled = !documentIds || documentIds?.length === 0;

  const dispatch = useDispatch();

  const [
    addDocumentsToGroup,
    addDocumentsToGroupResult,
  ] = api.endpoints.addDocumentsToGroup.useMutation();
  const { isLoading: addDocumentsToGroupLoading } = addDocumentsToGroupResult;

  const [
    addDocumentsToGroupLinkParent,
    addDocumentsToGroupLinkParentResult,
  ] = api.endpoints.addDocumentsToGroupLinkParent.useMutation();
  const {
    isLoading: addDocumentsToGroupLinkParentLoading,
  } = addDocumentsToGroupLinkParentResult;

  const resetModal = () => {
    dispatch(documentGroup.actions.setSelectedDocumentIds(undefined));
    dispatch(documentGroup.actions.setSelectedDocumentId(undefined));
    dispatch(
      documentGroup.actions.setCurrentAddToGroupStep(
        AddToGroupStepType.DOCUMENTS,
      ),
    );
  };

  const handleHideModal = () => {
    dispatch(documentGroup.actions.setModal());
    resetModal();
  };

  const handleSelectDocumentIds = useCallback((docIds) => {
    dispatch(documentGroup.actions.setSelectedDocumentIds(docIds));
  }, []);

  const handleLinkDocumentsInGroup = useCallback((documentId) => {
    dispatch(documentGroup.actions.setSelectedDocumentId(documentId));
  }, []);

  const handleAddDocumentsToGroup = () => {
    (selectedDocumentId
      ? addDocumentsToGroupLinkParent({
          groupId,
          parentId: selectedDocumentId,
          documentIds,
        })
      : addDocumentsToGroup({ groupId, documentIds })
    )
      .unwrap()
      .then(() => {
        showToast(SUCCESS, 'Documents added to the group successfully.');
      })
      .catch((error) => {
        const { response } = error;
        const defaultErrorMessage =
          'An error occurred while adding documents to the group, please try again later.';
        if (response.status === HttpStatusCodeType.BadRequest) {
          showToast(ERROR, get(response, 'data.message', defaultErrorMessage));
        } else {
          showToast(ERROR, defaultErrorMessage);
        }
      })
      .finally(() => {
        dispatch(documentGroup.actions.setSelectedDocumentIds(undefined));
        dispatch(documentGroup.actions.setSelectedDocumentId(undefined));
        handleHideModal();
      });
  };

  const goToStructureStep = () => {
    dispatch(
      documentGroup.actions.setCurrentAddToGroupStep(
        AddToGroupStepType.STRUCTURE,
      ),
    );
  };

  const steps = useMemo(() => {
    return {
      [AddToGroupStepType.DOCUMENTS]: {
        title: 'Add to group',
        component: (
          <>
            <FlexLayout justifyContent="center">
              <Text variant="subtitle">{`Select documents you want to add to "${groupName}" group`}</Text>
            </FlexLayout>
            <SearchDocumentsToAddToGroup
              onDocumentsSelect={handleSelectDocumentIds}
            />
          </>
        ),
        action: {
          disabled: shouldNextDisabled,
          promise: () => goToStructureStep(),
          text: 'Next',
        },
      },
      [AddToGroupStepType.STRUCTURE]: {
        title: 'Add to group',
        component: (
          <SelectDocumentInGroup
            group={groupId}
            onDocumentSelected={handleLinkDocumentsInGroup}
          />
        ),
        action: {
          text: 'Add to group',
        },
      },
    };
  }, [groupId, shouldNextDisabled]);

  const isLoading =
    addDocumentsToGroupLoading || addDocumentsToGroupLinkParentLoading;

  const buttonTitle = selectedDocumentId
    ? 'Add to group as linked documents'
    : steps[step]?.action.text;
  const handleActionPromise =
    step === AddToGroupStepType.STRUCTURE
      ? handleAddDocumentsToGroup
      : steps[step]?.action.promise;

  if (modalType === MODAL_ADD_DOCUMENTS_TO_GROUP) {
    return (
      <WizardPageLayout
        currentStep={step}
        steps={[AddToGroupStepType.DOCUMENTS, AddToGroupStepType.STRUCTURE]}
        title={{ text: steps[step]?.title, maxWidth: MAX_TITLE_WIDTH }}
        onClose={handleHideModal}
        actionButton={{
          ...steps[step]?.action,
          text: buttonTitle,
          promise: handleActionPromise,
        }}
        backButton={{
          text: 'Cancel',
          variant: 'tertiary',
          promise: () => handleHideModal(),
        }}
        isContentFill={true}
      >
        <LoadingContainer isLoading={isLoading}>
          {steps[step]?.component}
        </LoadingContainer>
      </WizardPageLayout>
    );
  } else {
    return null;
  }
};

export default AddDocuments;
