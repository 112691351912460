import { ClauseData, VariationData } from '~/redux/api/methods';
import { Nullable } from '~/types';
import { htmlToText } from '~/utils/strings';

export const getInstructions = (
  clause?: Nullable<ClauseData>,
  variation?: Nullable<VariationData>,
): string => {
  if (variation?.guidance) {
    // first, try to set instructions from variation guidance
    return htmlToText(variation.guidance);
  }
  if (clause?.guidance) {
    // then, fall back to clause guidance
    return htmlToText(clause.guidance);
  }
  return '';
};
