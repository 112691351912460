import {
  black1,
  black2,
  black3,
  bold,
  evisortYellow,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  treeWrapper: {
    marginTop: '10px',
  },
  treeIcon: {
    margin: '0 5px',
    display: 'inline-flex',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  loadingContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  emptyPageWrapper: {
    display: 'flex',
    flexGrow: '1',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70%',
  },
  emptyPageContent: {
    textAlign: 'center',
  },
  folderEmptyLabel: {
    margin: '72px 0 8px 0',
    color: black2,
    fontSize: '17px',
    fontWeight: semibold,
    textAlign: 'center',
  },
  pageName: {
    margin: '0 0 0 8px',
    color: black2,
    fontSize: '32px',
    fontWeight: bold,
  },
  groupDocumentItem: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      display: 'inline-flex',
      alignItems: 'center',
      color: black2,
      textDecoration: 'none',
    },
  },
  groupDocumentDot: {
    margin: '0 10px 0 5px',
    display: 'inline-flex',
    width: '5px',
    height: '5px',
    borderRadius: '10px',
    backgroundColor: '#ccc',
  },
  groupDocumentName: {
    marginLeft: '7px',
    fontSize: '14px',
    fontWeight: 'normal',
  },
  groupDocumentNameInvisible: {
    marginLeft: '7px',
    color: black3,
    fontSize: '14px',
    fontWeight: 'normal',
  },
  masterTag: {
    marginLeft: '10px',
    padding: '3px 5px',
    color: black1,
    backgroundColor: evisortYellow,
    borderRadius: '2px',
    fontSize: '12px',
    fontWeight: semibold,
  },
  groupDocMultipleLocation: {
    padding: 0,
    margin: 0,
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
};

export default styles;
