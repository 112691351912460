import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.AlgorithmMetrics, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.AlgorithmMetrics as TagType],
  endpoints: {
    getAlgorithmMetrics: {
      providesTags: [TAG_BY_LIST],
    },
    reprocessAlgorithms: {
      invalidatesTags: [TAG_BY_LIST],
    },
    stopReprocessAlgorithms: {
      invalidatesTags: [TAG_BY_LIST],
    },
  },
});
