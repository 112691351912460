import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import withHover from '../../Shared/HOCs/withHover';
import BackIcon from '../../Shared/Icons/BackIcon';
import styles from './AdminConsolePageNavHeader.styles';

const BackIconWithHover = withHover(BackIcon);

const AdminConsolePageNavHeader = (props) => {
  const { classes, title, subTitle, navigateBack } = props;

  return (
    <div className={classes.pageHeader}>
      <button className={classes.pageBackAction} onClick={navigateBack}>
        <BackIconWithHover />
      </button>
      <div className={classes.pageTitle}>
        <span className={classes.subTitle}>
          {subTitle ? subTitle : 'ADMIN CONSOLE'}
        </span>
        <h2 className={classes.pageName}>{title}</h2>
      </div>
    </div>
  );
};

AdminConsolePageNavHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  subTitle: PropTypes.string,
  navigateBack: PropTypes.func.isRequired,
};

export default injectSheet(styles)(AdminConsolePageNavHeader);
