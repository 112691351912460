import PT from 'prop-types';
import React from 'react';

import { Icon } from '~/eds';
import { FlexLayout, Text } from '~/ui';

function DisabledFeatureAlert({
  description = 'This feature is not currently enabled for your account.',
  enableIcon = false,
  isUnpurchased = true,
  renderUnpurchasedMsg = () => null,
}) {
  return (
    <FlexLayout flexDirection="column">
      <FlexLayout space={2} pb={4} alignItems="center">
        {enableIcon && <Icon color="status.info" icon="status-warning" />}
        <FlexLayout flexDirection="column">
          <Text color="gray-600" variant="s-dense">
            {description}
          </Text>
          {isUnpurchased && renderUnpurchasedMsg()}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
}

DisabledFeatureAlert.propTypes = {
  description: PT.string,
  enableIcon: PT.bool,
  isUnpurchased: PT.bool,
  renderUnpurchasedMsg: PT.func.isRequired,
};

export default DisabledFeatureAlert;
