import { CKEditorEventAction, useCKEditor } from 'ckeditor4-react';
import React from 'react';

import { Nullable } from '~/types';

type Editor = any;

export interface CKEditor4Props {
  data: Nullable<string>;
  config: Record<string, any>;
  onChange: (editor: Editor) => void;
}

export const CKEditor = ({ data = '', config, onChange }: CKEditor4Props) => {
  const [
    editorElement,
    setEditorElement,
  ] = React.useState<HTMLDivElement | null>(null);

  useCKEditor({
    editorUrl: 'https://cdn.ckeditor.com/4.20.1/full-all/ckeditor.js',
    config: {
      ...config,
      allowedContent: true,
      // set language to english
      language: 'en',
      // remove element path and resize from footer
      removePlugins: 'elementspath',
      resize_enabled: false,
      versionCheck: false, // TODO: use !isProd as a formal fix
    },
    debug: false,
    initContent: data,
    dispatchEvent: ({ type, payload }) => {
      if (type === CKEditorEventAction.change) {
        onChange(payload.editor);
      }
      if (type === CKEditorEventAction.instanceReady) {
        payload.editor?.document
          ?.find('figure')
          ?.getItem(0)
          ?.setStyle('width', '90%');
        payload.editor?.document
          ?.find('table')
          ?.getItem(0)
          ?.setStyle('width', '100%');
      }
      // could support more events
    },
    element: editorElement,
    type: 'classic',
    subscribeTo: ['instanceReady', 'change'],
  });

  return <div ref={setEditorElement} />;
};
