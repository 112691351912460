import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSegment } from '~/components/SegmentAnalytics';
import { api, selectors } from '~/redux';
import search from '~/redux/slices/search';
import { Divider, FlexLayout } from '~/ui';

import BooleanSearchBar from '../BooleanSearchBar';
import SaveSearch from '../SaveSearch';

const SearchBarControls = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectors.selectActiveSearchFilters);
  const unsupportedSavedSearchQuery = useSelector(
    selectors.selectUnsupportedSavedSearchQuery,
  );

  const {
    data: getFiltersResponse,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFilterSections.useQuery(undefined);
  const fields = getFiltersResponse?.fields;

  const handleSearchBarSubmit = (booleanQuery) => {
    dispatch(search.actions.setPage(1));
    dispatch(search.actions.clearUnsupportedQuery());
    dispatch(search.actions.setBooleanQuery(booleanQuery));
    dispatch(search.actions.setQuery({ booleanQuery, filters, fields }));

    if (booleanQuery) {
      trackSegment('searchKeywords', {
        name: booleanQuery,
      });
    }
  };

  return (
    <FlexLayout space={3}>
      <BooleanSearchBar
        isDisabled={Boolean(unsupportedSavedSearchQuery?.length)}
        isSubmitting={isFetchingFilters}
        onSubmit={handleSearchBarSubmit}
      />
      <FlexLayout mt={2} sx={{ height: '25px' }}>
        <Divider orientation="vertical" width="m" />
      </FlexLayout>
      <SaveSearch />
    </FlexLayout>
  );
};

export default SearchBarControls;
