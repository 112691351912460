import React from 'react';
import { connect } from 'react-redux';

import ReviewPhase from '~/components/Workflow/TicketReviewerPage/Sidebar/ReviewPhase';
import ViewTypeToggle from '~/components/Workflow/TicketReviewerPage/Sidebar/ViewTypeToggle';
import { Layout } from '~/eds';
import { getPhases } from '~/reducers/ticketReviewer';
import { Uuid } from '~/types';

interface Props {
  onRiskClick?: (riskId: string) => void;
  //Connected
  phases: any;
}

// ! We are moving this phases into the `Sidebar` for milestone 1. Avoiding to update this component into typescript or use EDS because there is no final decision to keep this component.
const Tasks = ({
  onRiskClick,
  // connected
  phases,
}: Props) => {
  return (
    <Layout direction="column" h="100%" w="100%">
      <ViewTypeToggle />
      {phases.map(
        ({
          id,
          isCollapsed,
          isDisabled,
        }: {
          id: Uuid;
          isCollapsed: boolean;
          isDisabled: boolean;
        }) => (
          <ReviewPhase
            key={id}
            isCollapsed={isCollapsed}
            isDisabled={isDisabled}
            phaseId={id}
            onRiskClick={onRiskClick}
          />
        ),
      )}
    </Layout>
  );
};

// TODO: Update this when we properly type TicketReviewer.
const mapStateToProps = ({ ticketReviewer }: { ticketReviewer: unknown }) => ({
  phases: getPhases(ticketReviewer),
});

export default connect(mapStateToProps)(Tasks);
