import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import AndOrToggle from '~/components/Shared/AndOrToggle';

import { evisortBlue } from '../../../assets/shared-styles/general';
import { MODAL_ADD_FILTER } from '../../../types/modal.types';
import EcFilterBooleanTextSearch from '../EcFilterBooleanTextSearch';
import EcFilterField from '../EcFilterField';
import EcFilterFolderField from '../EcFilterFolderField';
import EcFilterProvision from '../EcFilterProvision';
import EcFilterTextSearch from '../EcFilterTextSearch';
import EcModal from '../EcModal';
import PlusIcon from '../Icons/PlusIcon';
import RemoveIcon from '../Icons/RemoveIcon';
import styles from './EcFiltersSection.styles';

class EcFiltersSection extends Component {
  constructor(props) {
    super(props);

    this.handleOnRemoveClick = this.handleOnRemoveClick.bind(this);
    this.removeSubSection = this.removeSubSection.bind(this);
    this.handleOnAddSectionClick = this.handleOnAddSectionClick.bind(this);
    this.handleOnAddFilterClick = this.handleOnAddFilterClick.bind(this);
    this.handleAddFilter = this.handleAddFilter.bind(this);
    this.handleAddProvision = this.handleAddProvision.bind(this);
    this.handleAddTextSearch = this.handleAddTextSearch.bind(this);
    this.handleAddBooleanTextSearch = this.handleAddBooleanTextSearch.bind(
      this,
    );
    this.handleHideModal = this.handleHideModal.bind(this);

    this.state = { modal: null };
  }

  componentDidMount() {
    if (this.props.openEmptySection) this.handleOnAddSectionClick();
  }

  handleOnAddSectionClick() {
    this.addNewEntity('section');
  }

  handleOnAddFilterClick(replacingFilterIndex) {
    this.setState({ modal: { type: MODAL_ADD_FILTER, replacingFilterIndex } });
  }

  handleAddFilter(filter, replaceFilterIndex) {
    this.addNewEntity('field', filter, replaceFilterIndex);
  }

  handleAddProvision(provision, replaceFilterIndex) {
    this.addNewEntity('provision', provision, replaceFilterIndex);
  }

  handleAddTextSearch(textSearch, replaceFilterIndex) {
    this.addNewEntity('text_search', textSearch, replaceFilterIndex);
  }

  handleAddBooleanTextSearch(boolTextSearch, replaceFilterIndex) {
    this.addNewEntity('bool_text_search', boolTextSearch, replaceFilterIndex);
  }

  addNewEntity(entityType, entity, replaceFilterIndex) {
    const { filters, onChange, sectionIndex } = this.props;

    const newEntity =
      entityType === 'section'
        ? { entity: 'section', section: [] }
        : { ...entity, entity: entityType };
    const newFilters = [...filters];

    if (Number.isInteger(replaceFilterIndex)) {
      newFilters[replaceFilterIndex] = newEntity;
    } else {
      if (filters.length) {
        const operator = { entity: 'operator', operator: 'and' };
        newFilters.push(operator);
      }

      newFilters.push(newEntity);
    }

    onChange(newFilters, sectionIndex);
  }

  handleHideModal() {
    this.setState({ modal: null });
  }

  handleFilterChange(filterIndex, value) {
    const { filters, onChange, sectionIndex } = this.props;

    const newFilters = [...filters];
    newFilters[filterIndex] = value;

    onChange(newFilters, sectionIndex);
  }

  handleSubSectionFiltersChange(subSectionId, subSectionFilters) {
    const { filters, onChange, sectionIndex } = this.props;

    filters[subSectionId] = { entity: 'section', section: subSectionFilters };
    onChange(filters, sectionIndex);
  }

  removeSubItem(index) {
    const { filters, onChange, sectionIndex } = this.props;

    let newFilters = [...filters];
    if (index === filters.length - 1) {
      newFilters.splice(index - 1, 2);
    } else {
      newFilters.splice(index, 2);
    }

    onChange(newFilters, sectionIndex);
  }

  handleOnRemoveClick(index) {
    this.removeSubItem(index);
  }

  removeSubSection(sectionIndex) {
    this.removeSubItem(sectionIndex);
  }

  generateUIFilters(filters) {
    const {
      classes,
      enablePinning,
      enableTextSearch,
      filterOptions,
      provisionOptions,
      handleFilterOptionsChange,
    } = this.props;

    return filters.map((filter, filterIndex) => {
      let filterItem;

      if (filter.entity === 'section') {
        return (
          <EcFiltersSectionStyled
            enablePinning={enablePinning}
            enableTextSearch={enableTextSearch}
            filters={filter.section}
            filterOptions={filterOptions}
            provisionOptions={provisionOptions}
            onChange={(subSectionFilters, subSectionId) =>
              this.handleSubSectionFiltersChange(
                subSectionId,
                subSectionFilters,
              )
            }
            handleOnRemoveSectionClick={this.removeSubSection}
            handleFilterOptionsChange={handleFilterOptionsChange}
            sectionIndex={filterIndex}
            key={`section-${filterIndex}`}
          />
        );
      }

      if (filter.entity === 'operator') {
        return (
          <div
            className={classes.operatorWrapper}
            key={`operator-${filterIndex}`}
          >
            <AndOrToggle
              value={filter.operator}
              onChange={(operator) => {
                this.handleFilterChange(filterIndex, {
                  entity: 'operator',
                  operator,
                });
              }}
            />
          </div>
        );
      }

      if (filter.entity === 'field') {
        filterItem = (
          <EcFilterField
            {...filter}
            onChange={(filterProperty) =>
              this.handleFilterChange(filterIndex, {
                ...filter,
                ...filterProperty,
              })
            }
            onRemoveClick={() => this.handleOnRemoveClick(filterIndex)}
            onFilterNameClick={() => this.handleOnAddFilterClick(filterIndex)}
          />
        );
      }

      if (filter.entity === 'provision') {
        filterItem = (
          <EcFilterProvision
            {...filter}
            onChange={(filterProperty) =>
              this.handleFilterChange(filterIndex, {
                ...filter,
                ...filterProperty,
              })
            }
            onRemoveClick={() => this.handleOnRemoveClick(filterIndex)}
            onFilterNameClick={() => this.handleOnAddFilterClick(filterIndex)}
          />
        );
      }

      if (filter.entity === 'text_search') {
        filterItem = (
          <EcFilterTextSearch
            {...filter}
            onChange={(filterProperty) =>
              this.handleFilterChange(filterIndex, {
                ...filter,
                ...filterProperty,
              })
            }
            onRemoveClick={() => this.handleOnRemoveClick(filterIndex)}
            onFilterNameClick={() => this.handleOnAddFilterClick(filterIndex)}
          />
        );
      }

      if (filter.entity === 'bool_text_search') {
        filterItem = (
          <EcFilterBooleanTextSearch
            {...filter}
            onChange={(filterProperty) =>
              this.handleFilterChange(filterIndex, {
                ...filter,
                ...filterProperty,
              })
            }
            onRemoveClick={() => this.handleOnRemoveClick(filterIndex)}
          />
        );
      }

      if (filter.entity === 'folder') {
        filterItem = (
          <EcFilterFolderField
            {...filter}
            onChange={(filterProperty) =>
              this.handleFilterChange(filterIndex, {
                ...filter,
                ...filterProperty,
              })
            }
            onRemoveClick={() => this.handleOnRemoveClick(filterIndex)}
            onFilterNameClick={() => this.handleOnAddFilterClick(filterIndex)}
          />
        );
      }

      return (
        <div
          className={classes.filterItemWrapper}
          key={`${filter.id}-${filterIndex}`}
        >
          {filterItem}
        </div>
      );
    });
  }

  renderAddFilterModal() {
    const {
      enablePinning,
      enableTextSearch,
      filterOptions,
      provisionOptions,
      handleFilterOptionsChange,
    } = this.props;
    const { modal } = this.state;

    return (
      <EcModal
        modalType={MODAL_ADD_FILTER}
        hideModal={this.handleHideModal}
        width="720px"
        enablePinning={enablePinning}
        enableTextSearch={enableTextSearch}
        filterOptions={filterOptions}
        provisionOptions={provisionOptions}
        handleFilterOptionsChange={handleFilterOptionsChange}
        handleAddFilter={this.handleAddFilter}
        handleAddProvision={this.handleAddProvision}
        handleAddTextSearch={this.handleAddTextSearch}
        handleAddBooleanTextSearch={this.handleAddBooleanTextSearch}
        replacingFilterIndex={modal.replacingFilterIndex}
      />
    );
  }

  render() {
    const {
      classes,
      filters,
      rootSection,
      handleOnRemoveSectionClick,
      sectionIndex,
      hideAddFilterAction,
    } = this.props;
    const { modal } = this.state;

    return (
      <div className={classes.filterSectionWrapper}>
        {this.generateUIFilters(filters)}
        {!filters.length && (
          <div className={classes.emptySectionMessage}>
            {rootSection
              ? "You haven't added any filters."
              : 'This section does not contain any filters.'}
          </div>
        )}
        <div className={classes.sectionActions}>
          {!hideAddFilterAction && (
            <button
              type="button"
              className={classes.filterAction}
              onClick={() => this.handleOnAddFilterClick(null)}
            >
              <PlusIcon size="20" color={evisortBlue} />
              <span id="analyzer-add-a-filter-link">Add a Filter</span>
            </button>
          )}
          <button
            className={classes.filterAction}
            onClick={this.handleOnAddSectionClick}
          >
            <PlusIcon size="20" color={evisortBlue} />
            <span id="analyzer-add-a-section-link">Add a Section</span>
          </button>
          {!rootSection && (
            <>
              <span className={classes.actionSeparator} />
              <button
                className={classes.filterAction}
                onClick={() => handleOnRemoveSectionClick(sectionIndex)}
              >
                <RemoveIcon size="20" color={evisortBlue} />
                <span>Remove Section</span>
              </button>
            </>
          )}
        </div>
        {modal &&
          modal.type === MODAL_ADD_FILTER &&
          this.renderAddFilterModal()}
      </div>
    );
  }
}

EcFiltersSection.propTypes = {
  enableTextSearch: PropTypes.bool,
  enablePinning: PropTypes.bool,
  filters: PropTypes.array.isRequired,
  filterOptions: PropTypes.array.isRequired,
  provisionOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  handleFilterOptionsChange: PropTypes.func,
  rootSection: PropTypes.bool,
  hideAddFilterAction: PropTypes.bool,
};

EcFiltersSection.defaultProps = {
  enableTextSearch: true,
  enablePinning: true,
};

const EcFiltersSectionStyled = injectSheet(styles)(EcFiltersSection);

export default EcFiltersSectionStyled;
