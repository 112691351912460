import {
  black2,
  bold,
  evisortBlue,
} from '../../../assets/shared-styles/general';

const styles = {
  label: {
    display: 'flex',
    alignItems: 'center',
    color: black2,
    fontSize: '15px',
    wordBreak: 'break-word',
  },
  action: {
    marginLeft: '10px',
    color: evisortBlue,
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '13px',
    fontWeight: bold,
    cursor: 'pointer',
  },
  summaryInnerWrapper: {
    padding: '16px',
    overflowY: 'auto',
  },
  summaryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:first-child)': {
      marginTop: '16px',
    },
  },
  noContentMessage: {
    padding: '9px',
    backgroundColor: '#ebf0f3',
    fontSize: '13px',
    lineHeight: '18px',
  },
  expandButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    color: evisortBlue,
    fontWeight: 600,
    fontSize: '15px',
    cursor: 'pointer',
  },
};

export default styles;
