import { useEffect } from 'react';

import { Box, Button, Icon, Layout, Text } from '../../components';
import { useToggle } from '../../hooks';
import { Action, MentionedEntity, Nullable, Uuid } from '../../types';
import { scrollElementIntoView } from '../../utils';
import { CommentPost } from './CommentPost';
import {
  Comment,
  CommentId,
  EntityResolvers,
  MentionEntityType,
  Mentions,
  User,
} from './types';
import { testIsTargetComment } from './utils';

interface Props {
  activeCommentId?: Nullable<CommentId>;
  comments: Comment[];
  entityResolvers: EntityResolvers;
  isMentionsLoading: boolean;
  mentions: Mentions;
  resolvedMentions: Mentions;
  viewer: User;
  /** Copy link action is called */
  onCopyLink: (comment: Comment) => void;
  /** Delete a given comment */
  onDelete: (comment: Comment) => void;
  onFilterAction?: (action: Action, comment: Comment) => Action;
  onHighlightClick?: (highlight: Uuid) => void;
  /** Select a comment to reply to */
  onResolveComment: (commentData: Comment) => void;
  onReply: (comment: Comment) => void;
  onUnresolveComment: (commentData: Comment) => void;
  /** update a comment */
  onUpdate: (comment: Comment) => void;
  /* Message to display when there are not comments */
  emptyCommentsMessage?: string;
  loadingCommentId?: Nullable<CommentId>;
  parentComment?: Nullable<Comment>;
  /** Collapse all comments that has collapisible text */
  shouldCollapseAllComments?: boolean;
  /** This callback is fired when mentioning.
   * @param mention the mentioned entity
   */
  onMention?: (mention: MentionedEntity<MentionEntityType>) => void;
  /** Callback for searching async mentions */
  onMentionSearch?: (updatedSearch: string) => void;
}

const COMMENTS_INITIAL_DISPLAY_COUNT = 5;

const filterDisplayComments = (comments: Comment[]): Comment[] => {
  const initialRange =
    comments.length < COMMENTS_INITIAL_DISPLAY_COUNT
      ? 0
      : comments.length - COMMENTS_INITIAL_DISPLAY_COUNT;
  return comments.slice(initialRange, comments.length);
};

export const CommentsThread = ({
  activeCommentId,
  comments,
  emptyCommentsMessage,
  entityResolvers,
  isMentionsLoading,
  loadingCommentId,
  mentions,
  parentComment,
  resolvedMentions,
  shouldCollapseAllComments,
  viewer,
  onCopyLink,
  onDelete,
  onFilterAction,
  onHighlightClick,
  onMention,
  onMentionSearch,
  onReply,
  onUpdate,
  onResolveComment,
  onUnresolveComment,
}: Props) => {
  const [shouldDisplayAll, toggleDisplayAll, displayAll] = useToggle(false);

  useEffect(() => {
    const scrollToActiveComment = async () => {
      if (activeCommentId) {
        const activeCommentIndex = comments.findIndex((comment) =>
          testIsTargetComment(comment.id, activeCommentId),
        );

        if (
          activeCommentIndex <
          comments.length - COMMENTS_INITIAL_DISPLAY_COUNT
        ) {
          await displayAll();
        }

        scrollElementIntoView(activeCommentId);
      }
    };
    scrollToActiveComment();
  }, [activeCommentId]);

  const displayComments = shouldDisplayAll
    ? comments
    : filterDisplayComments(comments);

  if (comments.length === 0 && !parentComment) {
    return (
      <Layout direction="column" align="center" justify="center" h="100%">
        <Icon icon="message" />
        <Text preset="description" textAlign="center" whiteSpace="pre-wrap">
          {emptyCommentsMessage}
        </Text>{' '}
      </Layout>
    );
  }

  return (
    <>
      <Layout direction="column" flex="auto" minH="0">
        <Box overflowY="auto">
          {parentComment && (
            <CommentPost
              comment={parentComment}
              enableReply={false}
              entityResolvers={entityResolvers}
              isActive={testIsTargetComment(parentComment.id, activeCommentId)}
              isLoading={testIsTargetComment(
                parentComment.id,
                loadingCommentId,
              )}
              isMentionsLoading={isMentionsLoading}
              mentions={mentions}
              resolvedMentions={resolvedMentions}
              shouldCollapseComment={shouldCollapseAllComments}
              viewer={viewer}
              onCopyLink={onCopyLink}
              onDelete={onDelete}
              onFilterAction={onFilterAction}
              onHighlightClick={onHighlightClick}
              onMention={onMention}
              onMentionSearch={onMentionSearch}
              onReply={onReply}
              onResolveComment={onResolveComment}
              onUpdate={onUpdate}
              onUnresolveComment={onUnresolveComment}
            />
          )}
          {comments.length > COMMENTS_INITIAL_DISPLAY_COUNT && (
            <Layout
              align="center"
              justify="center"
              styles={styles.toggleButtonContainer}
            >
              <Button
                text={shouldDisplayAll ? 'Hide comments' : 'Show all comments'}
                variant="action"
                onClick={toggleDisplayAll}
              />
            </Layout>
          )}
          {displayComments.map((comment) => {
            const { id } = comment;
            const enableReply = !Boolean(parentComment);
            return (
              <CommentPost
                key={id}
                comment={comment}
                enableReply={enableReply}
                entityResolvers={entityResolvers}
                isActive={testIsTargetComment(id, activeCommentId)}
                isLoading={testIsTargetComment(id, loadingCommentId)}
                isMentionsLoading={isMentionsLoading}
                mentions={mentions}
                resolvedMentions={resolvedMentions}
                shouldCollapseComment={shouldCollapseAllComments}
                viewer={viewer}
                onCopyLink={onCopyLink}
                onDelete={onDelete}
                onFilterAction={onFilterAction}
                onHighlightClick={onHighlightClick}
                onMention={onMention}
                onMentionSearch={onMentionSearch}
                onReply={onReply}
                onResolveComment={onResolveComment}
                onUpdate={onUpdate}
                onUnresolveComment={onUnresolveComment}
              />
            );
          })}
        </Box>
      </Layout>
    </>
  );
};

const styles = {
  toggleButtonContainer: {
    backgroundColor: 'background',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
};
