import React, { memo } from 'react';

import { Box } from '../../Box';

interface Props {
  /** Children */
  children: React.ReactNode;
}

export const DropdownBody = memo(({ children }: Props) => {
  return (
    <Box px={4} py={3} variant="menu.container">
      {children}
    </Box>
  );
});
