import React, { useState } from 'react';
import { BasePoint, Editor, Range, Transforms } from 'slate';

import { MentionTriggerType } from '../../../enums';
import { Nullable } from '../../../types';
import { testHasTextMatch } from '../../../utils';
import { CustomEditor, EntityType, MentionedEntity, Mentions } from '../types';
import { insertMention } from './utils';

interface Props {
  editor: CustomEditor;
  mentions: Mentions;
  onMentionSearch?: (updatedSearch: string) => void;
  onMention?: (mention: MentionedEntity<EntityType>) => void;
}

const SUGGESTION_LIMIT = 4;

/**
 * A hook exposing state variables and handlers relating to managing mentions.
 *
 * Based on https://github.com/ianstormtaylor/slate/blob/9892cf0ffbd741cc2880d1f0bd0d7c1b36145bbd/site/examples/mentions.tsx
 **/
export const useMentions = ({
  editor,
  mentions,
  onMentionSearch,
  onMention,
}: Props) => {
  const [target, setTarget] = useState<Nullable<Range>>(null);
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');

  const handleInsertMention = () => {
    const { selection } = editor;
    // TODO: make this configurable for different MentionTriggerType.
    const beforeMatchRegExp = new RegExp(
      `^${MentionTriggerType.User}(\\w+(\\s\\w*)?)$`,
    );
    const afterMatchRegExp = /^(\s|$)/;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);

      let wordBefore = Editor.before(editor, start, { unit: 'word' });
      const beforeOneWordText = getBeforeText(wordBefore, start);
      if (beforeOneWordText && beforeOneWordText.startsWith(' ')) {
        /*
         * if the text of "one word before" starts with white space, it means the cursor is on the second
         *  word of the mention. So we need to set the distance of wordBefore to 2 to get the entire mention.
         */
        wordBefore = Editor.before(editor, start, {
          unit: 'word',
          distance: 2,
        });
      }
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(beforeMatchRegExp);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(afterMatchRegExp);

      if (beforeMatch && afterMatch) {
        onMentionSearch?.(beforeMatch[1]);
        setTarget(beforeRange as Range);
        setSearch(beforeMatch[1]);
        setIndex(0);
        return;
      }
    }

    setTarget(null);
  };
  const suggestionsArr: MentionedEntity<EntityType>[] = [];
  const suggestions = Object.keys(mentions).reduce((acc: Mentions, curr) => {
    const suggestedMentions = mentions[curr as EntityType];
    if (suggestedMentions) {
      const filteredSuggestions = onMentionSearch
        ? suggestedMentions.slice(0, SUGGESTION_LIMIT)
        : suggestedMentions
            .filter((mention) => testHasTextMatch(mention.label, search))
            .slice(0, SUGGESTION_LIMIT);

      suggestionsArr.push(...filteredSuggestions);
      acc[curr as EntityType] = filteredSuggestions;
    }

    return acc;
  }, {});

  const hasSuggestions = suggestionsArr.length > 0;

  const handleSelectMention = (mention: MentionedEntity<EntityType>) => {
    if (target) {
      Transforms.select(editor, target);
    }
    onMention?.(mention);
    insertMention(editor, mention);
    Editor.insertText(editor, ' '); // append space after inserting mention
    setTarget(null);
  };

  function getBeforeText(wordBefore: BasePoint | undefined, start: BasePoint) {
    const before = wordBefore && Editor.before(editor, wordBefore);
    const beforeRange = before && Editor.range(editor, before, start);
    const beforeText = beforeRange && Editor.string(editor, beforeRange);
    return beforeText;
  }

  const handleNavigateMention = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (target) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          const prevIndex = index >= suggestionsArr.length - 1 ? 0 : index + 1;
          setIndex(prevIndex);
          break;
        case 'ArrowUp':
          event.preventDefault();
          const nextIndex = index <= 0 ? suggestionsArr.length - 1 : index - 1;
          setIndex(nextIndex);
          break;
        case 'Tab':
        case 'Enter':
          event.preventDefault();
          suggestionsArr[index] && handleSelectMention(suggestionsArr[index]);
          break;
        case 'Escape':
          event.preventDefault();
          event.stopPropagation();
          setTarget(null);
          break;
      }
    }
  };

  return {
    handleInsertMention,
    handleNavigateMention,
    handleSelectMention,
    index,
    hasSuggestions,
    search,
    suggestions,
    target,
    onSetIndex: setIndex,
  };
};
