import { noop } from 'lodash';
import { useEffect, useState } from 'react';

interface Options {
  callback: () => void;
  disabled: boolean;
}

/**
 *
 * @param ms time that should be awaited
 * @returns an array where [0] is the boolean value and [1] is the function to stop awaiting.
 */
export const useElapse = (ms: number, options: Partial<Options> = {}) => {
  const { disabled = false, callback = noop } = options;

  const [hasElapsed, setHasElapsed] = useState(false);

  const elapse = () => setHasElapsed(true);

  let timeout: NodeJS.Timeout;
  useEffect(() => {
    if (!disabled && !hasElapsed) {
      timeout = setTimeout(() => {
        callback();
        elapse();
      }, ms);
    }
    return () => clearTimeout(timeout);
  }, [ms]);

  return [hasElapsed, elapse];
};
