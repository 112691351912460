export default `<svg
  aria-hidden="true"
  aria-label="folder"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M4.75 16.25H15.25C16.2152 16.25 17 15.4652 17 14.5V7.5C17 6.53477 16.2152 5.75 15.25 5.75H10.875C10.5988 5.75 10.3391 5.62148 10.175 5.4L9.65 4.7C9.31914 4.25977 8.79961 4 8.25 4H4.75C3.78477 4 3 4.78477 3 5.75V14.5C3 15.4652 3.78477 16.25 4.75 16.25Z"
    fill="#FFC400"
  />
</svg>`;
