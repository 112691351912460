import { api } from '~/redux';
import { Nullable } from '~/types';

import { TargetEntity, TargetEntityDetails } from '../types';

export const useResolveTargetEntityDetails = (
  targetEntity: Nullable<TargetEntity>,
): [TargetEntityDetails, boolean] => {
  const {
    data: targetEntityDetails = {
      ...targetEntity!,
      label: '–',
    },
    isFetching: isResolvingTargetEntity,
  } = api.endpoints.resolvePromptModelTargetEntityDetails.useQuery(
    {
      entity: targetEntity!,
    },
    {
      skip: targetEntity === null,
    },
  );

  return [targetEntityDetails, isResolvingTargetEntity];
};
