import PT from 'prop-types';

import { Select } from '~/eds';
import { Box } from '~/ui';

import DepartmentSelect from '../DepartmentSelect';
import UserSelect from '../UserSelect';
import dataTypes from './dataTypes';

function MultiValueInput({
  dataType,
  disabled,
  error,
  options = [],
  values,
  onChange,
  name,
}) {
  let Input;
  switch (dataType) {
    case dataTypes.DEPARTMENT:
      Input = (
        <DepartmentSelect
          enablePortal
          isMulti
          disabled={disabled}
          enableErrorMessage
          error={error}
          placeholder="..."
          value={values ?? []}
          width="fullWidth"
          onChange={onChange}
          name={name}
        />
      );
      break;
    case dataTypes.MULTI_SELECT:
    case dataTypes.SINGLE_SELECT:
      Input = (
        <Select
          enablePortal
          isMulti
          disabled={disabled}
          error={error}
          options={options}
          placeholder="..."
          value={values}
          width="fullWidth"
          onChange={onChange}
          shouldAllowInvalidValue={false}
          name={name}
        />
      );
      break;
    case dataTypes.USER:
      Input = (
        <UserSelect
          enablePortal
          isMulti
          name={name}
          disabled={disabled}
          placeholder="..."
          value={values}
          onChange={onChange}
          error={error}
          specificUsers={options}
        />
      );
      break;
    default:
      Input = null;
  }

  return <Box sx={{ flex: '1 1 auto' }}>{Input}</Box>;
}

MultiValueInput.propTypes = {
  dataType: PT.oneOf(Object.keys(dataTypes)).isRequired,
  disabled: PT.bool,
  error: PT.string,
  options: PT.arrayOf(
    PT.shape({ label: PT.string.isRequired, value: PT.string.isRequired })
      .isRequired,
  ),
  values: PT.arrayOf(PT.any.isRequired),
  onChange: PT.func.isRequired,
};

export default MultiValueInput;
