import React from 'react';

import Tooltip from '../components/Tooltip';

export const withTooltip = (WrappedComponent) => ({
  tooltip = null,
  ...restProps
}) => (
  <Tooltip content={tooltip}>
    <WrappedComponent {...restProps} />
  </Tooltip>
);
