const styles = {
  '@global': {
    '.toast-container': {
      width: 'min-content',
      padding: 0,
      bottom: '16px',
      right: '16px',
    },
    '.toast-message': {
      minHeight: 'auto',
      margin: 0,
      marginTop: '16px',
      padding: 0,
      borderRadius: '4px',
      fontFamily: 'unset',
    },
  },
};

export default styles;
