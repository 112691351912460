import React, { memo } from 'react';

import { FILE_ICONS } from '../../constants';
import { SharedFilenameProps } from '../../types';
import { getFileBasename, getFileExtension } from '../../utils';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Link } from '../Link';
import { TruncateText } from '../Text';

export const Filename = memo(
  ({ includeExtension = true, link, name }: SharedFilenameProps) => {
    const extension = getFileExtension(name);
    const icon = FILE_ICONS[extension] || 'file';

    const filename = (
      <Layout align="center" spacing={1}>
        <Icon label={extension} icon={icon} />
        <TruncateText color="inherit" variant="body">
          {includeExtension ? name : getFileBasename(name)}
        </TruncateText>
      </Layout>
    );

    return link ? (
      <Link shouldTruncate {...link}>
        {filename}
      </Link>
    ) : (
      filename
    );
  },
);
