import { getDocument } from 'pdfjs-dist/legacy/build/pdf';
import {
  PDFFindController,
  SimpleLinkService,
} from 'pdfjs-dist/legacy/web/pdf_viewer';
import { EventBus } from 'pdfjs-dist/types/web/interfaces';
import { PDFViewer } from 'pdfjs-dist/types/web/pdf_viewer';

type DestinationArray = [number, number];

/**
 * Setups a findController for the PDFViewer. This is done after initialization of PDFViewer but following the same principles as here https://github.com/mozilla/pdf.js/blob/master/examples/components/simpleviewer.js
 * @param pdfFile loaded PDF
 */
export const setupFindController = (
  viewer: PDFViewer,
  linkService: SimpleLinkService,
  eventBus: EventBus,
) => {
  if (viewer) {
    const findController = new PDFFindController({
      linkService: linkService,
      eventBus: eventBus,
    });
    viewer.findController = findController;
    viewer.update();
  }
};

export const onEventBusEvent = (
  eventBus: EventBus | undefined,
  eventName: string,
  callback: (event: any) => void,
) => {
  eventBus?.on?.(eventName, callback);
};

/**
 * @param pageNumber page to scroll to
 * @param destArray coordinates [x,y] inside the page to scroll to. default is the beginning of the page [0,0].
 */
export const scrollToPage = (
  viewer: PDFViewer | undefined,
  pageNumber: number,
  destArray: DestinationArray = [0, 0],
) => {
  /** for reference, the scrollPageIntoView's destArray format:
   * [<page-reference>, <coordinates_system>, LEFT_X_IN_PDF_COORDINATE_SYSTEM, TOP_Y_IN_PDF_COORDINATE_SYSTEM] */
  viewer?.scrollPageIntoView({
    pageNumber,
    destArray: [null, { name: 'XYZ' }, ...destArray],
  });
};

export const getViewport = (viewer: PDFViewer | undefined, pageNumber = 1) => {
  return viewer?.getPageView(pageNumber - 1)?.viewport;
};

export const getPdfDocumentFromBlob = async (
  pdfBlob: Blob,
  isEvalSupported = false,
) => {
  const buffer = await pdfBlob.arrayBuffer();
  return getDocument({ data: new Uint8Array(buffer), isEvalSupported }).promise;
};
