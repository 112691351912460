import { findIndex } from 'lodash';
import React, { useMemo, useState } from 'react';

import { Button, HtmlEntityType, Mark, Text } from '~/eds';
import { SnippetPart } from '~/redux/api/methods';

type Props = {
  snippets: SnippetPart[];
  limit?: number;
};

const findAndTruncateLast = ({ snippets, limit = 0 }: Props) => {
  let lastIndex = 0;
  let count = 0;
  let last = snippets[lastIndex];
  for (let i = 0; i < snippets.length; i++) {
    lastIndex = i;
    last = snippets[lastIndex];

    count += last.text.length;

    if (count > limit) {
      last = {
        ...last,
        text: last.text.substring(0, last.text.length - (count - limit)),
      };
      break;
    }
  }
  return { lastIndex, last };
};

/**
 *
 * @param {SnippetPart[]} snippets a list of SnippetPart to truncate
 * @param {number} limit the character size limit for the concatinated text fromthe list of @snippets
 * @returns {SnippetPart[]} a list of SnippetPart whose total text size is no more than the @limit
 */
const truncateSnippets = ({ snippets, limit = 0 }: Props) => {
  const { lastIndex, last } = findAndTruncateLast({ snippets, limit });
  return snippets.slice(0, lastIndex).concat([last]);
};

const getTotalLen = (snippets: SnippetPart[]) => {
  let len = 0;
  for (const s of snippets) {
    len += s.text.length;
  }
  return len;
};

const shouldHighlight = (part: SnippetPart) => part.type === 'match';

export const getTruncateSnippets = ({ snippets, limit = 0 }: Props) => {
  const targetIndex = findIndex(snippets, shouldHighlight);

  if (targetIndex <= 0) {
    if (targetIndex < 0) {
      //exception but handles the same way
    }

    return truncateSnippets({ snippets, limit });
  } else {
    if (targetIndex > 1) {
      // exception
      // TODO: merge all snippets before the first highlighted snippet into one
      // Note: once we do this, targetIndex can only be 0 or 1, or -1 which is handled above
    }

    const firstSnippet = { ...snippets[targetIndex - 1] }; //copy it since it will be potentially truncated
    const restOfSnippets = snippets.slice(targetIndex);
    const firstLen = firstSnippet.text.length;
    const restLen = getTotalLen(restOfSnippets);

    const halfLimit = Math.floor(limit / 2); // arbitrarily set how many chars to show before the first highlighted snippet

    const needToTruncateTheFirst =
      firstLen > halfLimit && firstLen + restLen > limit;

    if (needToTruncateTheFirst) {
      let start = firstLen - halfLimit;

      // try to be smart about how much we truncate the first snippet
      // if it has more room for the first snippet, move cursor to the left
      if (restLen < halfLimit) {
        start = start - (halfLimit - restLen);
      }

      firstSnippet.text = firstSnippet.text.substring(start);
    }

    // rebuild the list
    snippets = [firstSnippet].concat(restOfSnippets);

    return truncateSnippets({ snippets, limit });
  }
};

const showLessText = 'Show less';
const showMoreText = 'Show more';

export function CollapsibleSnippets({ snippets = [], limit }: Props) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const needTruncation = useMemo(() => {
    return limit && getTotalLen(snippets) > limit;
  }, [snippets, limit]);

  const truncatedSnippets = useMemo(() => {
    if (needTruncation && isCollapsed) {
      return getTruncateSnippets({ snippets, limit });
    } else {
      return snippets;
    }
  }, [snippets, needTruncation, isCollapsed]);

  return (
    <>
      {needTruncation && isCollapsed ? `[${HtmlEntityType.Ellipsis}] ` : ''}
      {truncatedSnippets.map((s, index) =>
        shouldHighlight(s) ? (
          <Mark key={index}>{s.text}</Mark>
        ) : (
          <Text key={index}>{s.text}</Text>
        ),
      )}
      {needTruncation && isCollapsed ? ` [${HtmlEntityType.Ellipsis}] ` : ''}
      {needTruncation ? (
        <Button
          isInline
          text={isCollapsed ? showMoreText : showLessText}
          variant="action"
          onClick={
            isCollapsed
              ? () => setIsCollapsed(false)
              : () => setIsCollapsed(true)
          }
        />
      ) : null}
    </>
  );
}

export default CollapsibleSnippets;
