import { UserRoleType } from '~/enums';

import {
  ADMIN_PERMISSION_IDS,
  AUTOMATION_ADMIN_ROLE_IDS,
  WORKFLOW_ADMIN_ROLE_ID,
  WORKFLOW_ONLY_ROLE_IDS,
} from './constants';

export const getRBACPermissions = (userPermissions: string[]) => {
  // if no permissions skip any rbac mapping, legacy fallback
  if (!userPermissions.length) return {};

  const isAdmin = includePermissions(userPermissions, ADMIN_PERMISSION_IDS);
  const isWorkflowOnly = hasOnlyPermissions(
    userPermissions,
    WORKFLOW_ONLY_ROLE_IDS,
  );
  return {
    role: getRole(isAdmin, isWorkflowOnly),
    is_rbac_admin: isAdmin,
    is_workflow_admin: userPermissions?.includes(WORKFLOW_ADMIN_ROLE_ID),
    is_automation_admin: includePermissions(
      userPermissions,
      AUTOMATION_ADMIN_ROLE_IDS,
    ),
  };
};

export const includePermissions = (
  userPermissions: string[],
  permissions: string[],
) => {
  return permissions.some((permission) => userPermissions.includes(permission));
};

export const hasOnlyPermissions = (
  userPermissions: string[],
  adminPermissions: string[],
) => {
  const hasAdminPermission = userPermissions.some((permission) =>
    adminPermissions.includes(permission),
  );
  const hasOtherPermissions = userPermissions.some(
    (permission) => !adminPermissions.includes(permission),
  );

  return hasAdminPermission && !hasOtherPermissions;
};

export const getRole = (
  isAdmin: boolean,
  isWorkflowOnly: boolean,
): UserRoleType => {
  if (isAdmin) return UserRoleType.Admin;
  if (isWorkflowOnly) return UserRoleType.WorkflowOnly;
  return UserRoleType.PowerUserWithEdit;
};
