export default `
<svg
  aria-hidden="true"
  aria-label="field-dropdown"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M15.5049 10.4688L12.5361 13.4688L9.53613 10.4688C9.22363 10.1875 8.75488 10.1875 8.47363 10.4688C8.16113 10.7812 8.16113 11.25 8.47363 11.5312L11.9736 15.0312C12.1299 15.1875 12.3174 15.25 12.5361 15.25C12.7236 15.25 12.9111 15.1875 13.0361 15.0312L16.5361 11.5312C16.8486 11.25 16.8486 10.7812 16.5361 10.5C16.2549 10.1875 15.7861 10.1875 15.5049 10.4688ZM17.5361 5H7.53613C6.41113 5 5.53613 5.90625 5.53613 7V17C5.53613 18.125 6.41113 19 7.53613 19H17.5361C18.6299 19 19.5361 18.125 19.5361 17V7C19.5361 5.90625 18.6299 5 17.5361 5ZM18.0361 17C18.0361 17.2812 17.7861 17.5 17.5361 17.5H7.53613C7.25488 17.5 7.03613 17.2812 7.03613 17V7C7.03613 6.75 7.25488 6.5 7.53613 6.5H17.5361C17.7861 6.5 18.0361 6.75 18.0361 7V17Z"
    fill="currentcolor"
  />
</svg>
`;
