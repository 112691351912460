import { connect } from 'react-redux';
import uuid from 'uuid';

import { workflowSetNotifications } from '~/actions';
import { Box, Button } from '~/eds';

import Section from '../Section';
import NotificationItem from './NotificationItem';

function Notifications({
  notifications,
  // connected
  workflowSetNotifications,
}) {
  function handleOnAddNotificationClick() {
    const newNotification = { id: uuid(), userIds: [], message: '' };
    workflowSetNotifications([...notifications, newNotification]);
  }

  function handleOnRemoveNotificationClick(notificationId) {
    const updatedNotifications = notifications.filter(
      ({ id }) => id !== notificationId,
    );
    workflowSetNotifications(updatedNotifications);
  }

  function handleOnUpdateNotification(notificationId, userIds, message) {
    const updatedNotifications = notifications.map((notification) => {
      if (notification.id !== notificationId) return notification;
      return { ...notification, userIds, message };
    });
    workflowSetNotifications(updatedNotifications);
  }

  return (
    <Section
      description="Specify users who should be notified after the workflow is completed."
      icon="alert"
      id="workflow--settings-notification-header"
      title="Notifications"
    >
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onRemove={() => handleOnRemoveNotificationClick(notification.id)}
          onUpdate={handleOnUpdateNotification}
        />
      ))}
      <Box>
        <Button
          text="Add Notification"
          onClick={handleOnAddNotificationClick}
        />
      </Box>
    </Section>
  );
}

const mapStateToProps = ({ currentUser, workflow }) => ({
  currentUser,
  notifications: workflow.settings.notifications,
});

export default connect(mapStateToProps, { workflowSetNotifications })(
  Notifications,
);
