import {
  QueryArgFrom,
  QueryDefinition,
  QuerySubState,
  SubscriptionOptions,
} from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';

const DEFAULT_POLLING_REQUEST_INTERVAL = 3000;

interface Options {
  maxRetries?: number;
  skip?: boolean;
}

// TODO use RTKQ poll API directly as it is not possible to be typesafe (vendor no longer exports types)
export const usePollingRequest = <
  Q extends QueryDefinition<any, any, any, any, any>
>(
  request: any,
  params: QueryArgFrom<Q>,
  options: SubscriptionOptions & Options = {},
): QuerySubState<Q> & {
  isError: boolean;
  isPollingFinished: boolean;
  isPollingFinishedByMaxRetries: boolean;
  isUninitialized: boolean;
  reset: () => void;
  stopPolling: () => void;
} => {
  const { maxRetries = 5, skip, ...queryOptions } = options;
  const [retryCount, setRetryCount] = useState(0);
  const [shouldStopPolling, setShouldStopPolling] = useState(false);

  const result = request(params, {
    pollingInterval: DEFAULT_POLLING_REQUEST_INTERVAL,
    skip: shouldStopPolling || skip,
    ...queryOptions,
  });
  const { requestId } = result;

  useEffect(() => {
    requestId && setRetryCount((prevRetryCount) => prevRetryCount + 1);
  }, [requestId]);

  useEffect(() => {
    retryCount === maxRetries && setShouldStopPolling(true);
  }, [retryCount]);

  const reset = () => {
    setRetryCount(0);
    setShouldStopPolling(false);
  };

  const stopPolling = () => setShouldStopPolling(true);

  return {
    ...result,
    stopPolling,
    isPollingFinishedByMaxRetries:
      shouldStopPolling && retryCount >= maxRetries,
    isPollingFinished: shouldStopPolling || skip,
    reset,
  };
};
