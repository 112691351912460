import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom-v5-compat';

import { Button, Icon, Layout } from '~/eds';
import { FlexLayout } from '~/ui';

import { black4, black5 } from '../../../assets/shared-styles/general';
import EcTooltip from '../../Shared/EcTooltip';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import LockIcon from '../Icons/LockIcon';
import styles from './EcFolderTreeItem.styles';

const PAGE_SIZE = 100;

class EcFolderTreeItem extends Component {
  treeItem = createRef();

  state = {
    currentPage: 1,
  };

  focus = () => {
    this.treeItem.current.focus();
  };

  componentDidUpdate() {
    if (
      this.props.focusId === this.props.folder.id ||
      (this.props.autoFocus && !this._autoFocused)
    ) {
      this.focus();
      this._autoFocused = true;
    }
  }

  componentDidMount() {
    const {
      uploadLocation,
      folder,
      firstToOpenId,
      folderIdsSelected,
      isRootFolder,
    } = this.props;

    folder.isExpanded =
      (uploadLocation &&
        uploadLocation.path &&
        uploadLocation.path.find((f) => f.id === folder.id)) ||
      isRootFolder === true ||
      folder.children.find((f) => f.id === firstToOpenId) ||
      (folderIdsSelected &&
        folderIdsSelected.length &&
        folder.children
          .map((f) => f.id)
          .some((f) => folderIdsSelected.includes(f)));

    const isSelected =
      firstToOpenId === folder.id ||
      (folderIdsSelected && folderIdsSelected.includes(folder.id)
        ? true
        : false);
    this.props.setIsSelected(folder.id, isSelected);
  }

  handleOnExpandClick = () => {
    this.props.folder.isExpanded = !this.props.folder.isExpanded;
    this.forceUpdate(); //TODO: remove forceUpdate, requires making TreeNode a component/redux state so React is aware of its state change
  };

  handleKeyDown = (event) => {
    this.props?.onTreeNodeKeyDown(event, this.props.folder); //eslint-disable-line no-unused-expressions

    this.forceUpdate();
  };

  handleItemClick = () => this.props.onSelect(this.props.folder);

  showLoadMore = () => {
    const { folder } = this.props;
    const { currentPage } = this.state;
    return folder.children?.length > currentPage * PAGE_SIZE;
  };

  renderSubtree() {
    const { classes, folder } = this.props;
    const { currentPage } = this.state;

    const { children } = folder;
    folder.path = folder.path || [];

    return (
      <>
        {children.slice(0, currentPage * PAGE_SIZE).map((treeItem) => {
          const path = [...folder.path, { id: folder.id, name: folder.name }];
          treeItem.path = path;
          treeItem.treeNode.path = path;

          const { autoFocus: _autoFocus, ...rest } = this.props;

          return (
            <EcFolderTreeItem
              {...rest}
              folder={treeItem}
              highLight={classes.selectedFolder}
              isRootFolder={false}
              key={treeItem.id}
            />
          );
        })}
      </>
    );
  }

  render() {
    const {
      classes,
      folder,
      folderIdsSelected,
      firstToOpenId,
      getIsSelected,
      uploadLocation,
      isMultiSelect,
      forLinkOnly,
      disableSyncPaired,
    } = this.props;
    const isSelected = getIsSelected(folder.id);
    const { isExpanded } = folder;
    const { currentPage } = this.state;

    const selectedFolderClass =
      (folder &&
        folder.id &&
        uploadLocation &&
        uploadLocation.id &&
        uploadLocation.id === folder.id) ||
      firstToOpenId === folder.id
        ? classes.selectedItemName
        : classes.itemName;
    const itemHeaderClass = folder.children.length
      ? classes.itemHeader
      : classes.emptyItemHeader;
    const checkboxSelected = isSelected
      ? classes.selectedFolder
      : classes.itemName;
    const folderSelected = folderIdsSelected?.includes(folder.id);

    const ariaExpanded = folder.children.length
      ? isExpanded
        ? 'true'
        : 'false'
      : ''; // otherwise the SR would think its expandable and read the state out loud

    // following the best practice at:
    // https://www.w3.org/TR/wai-aria-practices-1.1/examples/treeview/treeview-2/treeview-2a.html
    const listRole = folder.parent ? 'group' : 'tree';

    // a temporary solution to allow SR to read the selected state when the items are selectable
    const ariaLabel = forLinkOnly
      ? folder.name
      : isSelected
      ? `${folder.name} selected`
      : `${folder.name} unselected`;

    const disabledDueToSyncPair =
      disableSyncPaired && folder.treeNode.sync_paired;

    return (
      <ul
        className={classes.ecFolderTreeItem}
        aria-hidden="false"
        role={listRole}
      >
        <li
          aria-expanded={ariaExpanded}
          aria-label={ariaLabel}
          className={itemHeaderClass}
          data-testid="folder-tree-item-header"
          onKeyDown={this.handleKeyDown}
          ref={this.treeItem}
          role="treeitem"
          tabIndex={0}
          data-tip={disabledDueToSyncPair ? '' : null}
          data-for="helpSyncPairedDisabled"
        >
          {folder.children.length ? (
            isExpanded ? (
              <ChevronDownIcon
                size="20"
                onClick={this.handleOnExpandClick}
                color={black4}
              />
            ) : disabledDueToSyncPair ? (
              <ChevronRightIcon size="20" color={black5} />
            ) : (
              <ChevronRightIcon
                size="20"
                onClick={this.handleOnExpandClick}
                color={black4}
              />
            )
          ) : null}

          {isMultiSelect ? (
            <button
              tabIndex={-1}
              className={
                folderSelected ? classes.folderSelected : checkboxSelected
              }
              onClick={this.handleItemClick}
            >
              {folder.name} {folder.editable ? null : <LockIcon size="11" />}
            </button>
          ) : forLinkOnly ? (
            <Link
              tabIndex={-1}
              to={`/documents/${folder.id}`}
              className={
                folder.editable && !(disableSyncPaired && folder.sync_paired)
                  ? selectedFolderClass
                  : classes.greyout
              }
            >
              {folder.name} {folder.editable ? null : <LockIcon size="11" />}
            </Link>
          ) : (
            <button
              tabIndex={-1}
              className={
                folder.editable && !disabledDueToSyncPair
                  ? selectedFolderClass
                  : classes.greyout
              }
              onClick={this.handleItemClick}
            >
              <Layout>
                <Icon icon="folder" />
                {folder.name} {folder.editable ? null : <LockIcon size="11" />}
              </Layout>
            </button>
          )}
        </li>
        {isExpanded ? this.renderSubtree() : null}

        {this.showLoadMore() && isExpanded ? (
          <FlexLayout alignItems="center" justifyContent="center">
            <Button
              variant="action"
              onClick={() => this.setState({ currentPage: currentPage + 1 })}
              text="Load More"
            />
          </FlexLayout>
        ) : null}

        <EcTooltip id="helpSyncPairedDisabled" width="240px" place="left">
          Cannot move to this location, as both this location and the selected
          folder contain synchronizations with external providers. Please
          contact your Evisort admin to stop synchronization before moving the
          selected folder to this location.
        </EcTooltip>
      </ul>
    );
  }
}

EcFolderTreeItem.propTypes = {
  classes: PropTypes.object.isRequired,
  folder: PropTypes.object,
  uploadLocation: PropTypes.object,
};

export default injectSheet(styles)(EcFolderTreeItem);
