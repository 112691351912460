import React, { useState } from 'react';
import { connect } from 'react-redux';

import { workflowRemoveQuestion } from '~/actions';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import ConditionRulePill from '~/components/Workflow/shared/ConditionRulePill';
import FieldPill from '~/components/Workflow/shared/FieldPill';
import { Button } from '~/eds';
import { getFieldById } from '~/reducers/workflow';
import { FlexLayout, Text } from '~/ui';

import QuestionEditable from './QuestionEditable';

function Question({
  question,
  sectionId,
  onReorder,
  // connected,
  field,
  workflowRemoveQuestion,
}) {
  const [editMode, setEditMode] = useState(!field?.id);

  function handleOnClose() {
    setEditMode(false);
  }

  if (editMode) {
    return (
      <QuestionEditable
        position={question.position}
        question={question}
        sectionId={sectionId}
        onClose={handleOnClose}
        onReorder={onReorder}
      />
    );
  }

  const { conditionRule, description, id, isRequired, name } = question;

  const getMenuItems = () => {
    const items = [];

    items.push({
      content: 'Reorder Questions',
      onClick: () => onReorder(),
    });

    items.push({
      content: 'Delete Question',
      onClick: () => workflowRemoveQuestion({ sectionId, questionId: id }),
    });

    return items;
  };

  return (
    <FlexLayout flexDirection="column" key={id}>
      <FlexLayout alignItems="center" space={2}>
        <Text variant="m-dense-bold" sx={{ flexGrow: 1 }}>
          {name}
          {isRequired && ' *'}
        </Text>
        <FlexLayout alignItems="center" flexShrink="0" space={2}>
          <Button
            text="Edit"
            variant="action"
            onClick={() => setEditMode(true)}
          />
          <ActionsMenu
            id={`workflow_builder_form_question_actions_menu_id=${id}`}
            items={getMenuItems()}
            align="end"
          />
        </FlexLayout>
      </FlexLayout>
      <FlexLayout flexDirection="column" space={4}>
        {description && (
          <Text variant="xs-dense-medium" color="gray-600">
            {description}
          </Text>
        )}
        <FlexLayout space={2}>
          <FieldPill fieldId={question.fieldId} />
          <ConditionRulePill conditionRule={conditionRule} />
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
}

const mapStateToProps = ({ workflow }, { question }) => ({
  // TODO: @zixu to figure out how to write a selector that checks a question.
  //       field and returns if field/condition is valid without having to remap
  //       conditions/fields to props.
  conditions: workflow.conditions,
  fields: workflow.fields,
  field: question ? getFieldById(workflow, question.fieldId) : {},
});

export default connect(mapStateToProps, { workflowRemoveQuestion })(Question);
