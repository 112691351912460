import React from 'react';

import { MentionElement } from '../mentions';
import { BlockNode, ElementProps, MentionNode } from '../types';

export const Element = ({
  attributes,
  children,
  element,
}: ElementProps<BlockNode | MentionNode>) => {
  switch (element.type) {
    case 'mention': {
      return (
        <MentionElement
          attributes={attributes}
          children={children}
          element={element}
        />
      );
    }
    // BlockNodeType
    case 'blockquote':
    case 'h1':
    case 'h2':
    case 'li':
    case 'ol':
    case 'p':
    case 'pre':
    case 'ul':
    default: {
      const style = { textAlign: element.align };
      const ComponentType = element.type || 'p';

      return (
        <ComponentType {...attributes} element={element} style={style}>
          {children}
        </ComponentType>
      );
    }
  }
};
