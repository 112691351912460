import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import { debounce } from '../../../utils/helper.utils';
import CloseIcon from '../Icons/CloseIcon';
import SearchIcon from '../Icons/SearchIcon';
import styles from './EcFilterInput.styles';

function EcFilterInput(props) {
  const {
    classes,
    ariaDescribedby,
    autoFocus,
    debounceTimeout,
    id,
    onClearSearch,
    onChange,
    placeholder,
    value,
    disabled,
  } = props;
  const debouncedOnChange = debounceTimeout
    ? debounce(onChange, debounceTimeout)
    : onChange;

  return (
    <div className={classes.filterSearch}>
      <SearchIcon size="20" opacity="0.5" color="#000" />
      <input
        id={id}
        type="text"
        aria-describedby={ariaDescribedby}
        autoFocus={autoFocus}
        value={value}
        onChange={(event) => debouncedOnChange(event.target.value)}
        placeholder={placeholder}
        disabled={disabled}
      />
      {value && onClearSearch && (
        <button title="clear search" onClick={onClearSearch}>
          <CloseIcon size="20" opacity="0.5" />
        </button>
      )}
    </div>
  );
}

EcFilterInput.propTypes = {
  classes: PropTypes.object.isRequired,
  debounceTimeout: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default injectSheet(styles)(EcFilterInput);
