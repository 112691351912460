import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LayoutType } from '~/enums';

interface State {
  contentTabLayout: LayoutType;
}

const initialState = { contentTabLayout: LayoutType.TwoColumn };

const setContentTabLayoutReducer = (
  state: State,
  action: PayloadAction<LayoutType>,
) => {
  state.contentTabLayout = action.payload;
};

const slice = createSlice({
  name: 'documentsViewPage',
  initialState,
  reducers: {
    setContentTabLayout: setContentTabLayoutReducer,
  },
});

export const { setContentTabLayout } = slice.actions;
export default slice.reducer;
