import React from 'react';
import { connect } from 'react-redux';

import {
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
} from '~/actions';
import { Box } from '~/eds';
import { TicketStageType } from '~/enums';

import SkipStageAccordion from '../../shared/SkipStageAccordion';

const Edit = ({
  // connected
  conditionRule,
  disabled,
  isSkipped,
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
}) => {
  const handleUpdateStageConditionRule = (updatedConditionRule) => {
    workflowSetStageConditionRule({
      conditionRule: updatedConditionRule,
      stage: TicketStageType.Edit,
    });
  };

  const handleToggleEnableStage = (updatedIsSkipped) => {
    workflowToggleSkipStage({
      isSkipped: updatedIsSkipped,
      stage: TicketStageType.Edit,
    });
  };

  return (
    <Box disabled={disabled}>
      <SkipStageAccordion
        conditionRule={conditionRule}
        isSkipped={isSkipped}
        onChange={handleUpdateStageConditionRule}
        onToggle={handleToggleEnableStage}
      />
    </Box>
  );
};

const mapStateToProps = ({ builder, workflow }) => {
  const stage = workflow.stages[TicketStageType.Edit];
  return {
    disabled: !builder.shouldEnableAllComponents,
    conditionRule: stage.conditionRule,
    isSkipped: !stage.isEnabled,
  };
};

export default connect(mapStateToProps, {
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
})(Edit);
