import {
  black1,
  black2,
  black4,
  bold,
  evisortBlue,
  evisortRed,
  evisortYellow,
  heavy,
  semibold,
} from '../../assets/shared-styles/general';

const bulkActionStyles = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '24px',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '13px',
  fontWeight: bold,
  cursor: 'pointer',

  '& svg': {
    marginRight: '8px',
  },
};

const styles = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '32px',
  },
  headerBackAction: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  pageName: {
    flexGrow: 1,
    margin: '4px 0',
  },
  headerActions: {
    display: 'flex',

    '& button:not(:last-child)': {
      marginRight: '16px',
    },
  },
  loadingContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  contentContainer: {
    display: 'flex',
  },
  sidebar: {
    flexShrink: 0,
    width: '280px',
    marginTop: '68px',
    marginRight: '24px',
  },
  content: {
    flexGrow: 1,
  },
  contentActions: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '42px',
    marginBottom: '24px',
    paddingLeft: '16px',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  checkboxContainer: {
    display: 'flex',
    marginRight: '4px',
  },
  documentCount: {
    display: 'inline-flex',
    marginLeft: '8px',
    color: black2,
    fontSize: '17px',
    fontWeight: heavy,
  },
  selectAllContent: {
    marginLeft: '16px',
    color: evisortBlue,
    fontWeight: bold,
    cursor: 'pointer',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  viewFilter: {
    display: 'flex',
    height: '45px',
    marginRight: '16px',
    border: 'solid 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    color: black1,
    fontSize: '15px',
    fontWeight: semibold,
    cursor: 'pointer',

    '& button': {
      padding: '12px 16px',
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  viewUnSelected: {
    color: evisortBlue,
    cursor: 'pointer',
  },
  viewSelected: {
    color: black2,
    cursor: 'default',
  },
  viewFilterSeparator: {
    width: '1px',
    margin: '8px 0',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  bulkActionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  bulkActionAddToGroup: {
    ...bulkActionStyles,
    color: evisortBlue,
  },
  bulkActionAddToGroupForbidden: {
    ...bulkActionStyles,
    color: evisortBlue,
    opacity: 0.4,
  },
  bulkActionMove: {
    ...bulkActionStyles,
    color: evisortBlue,
  },
  bulkActionMoveForbidden: {
    ...bulkActionStyles,
    color: evisortBlue,
    opacity: 0.4,
  },
  bulkActionCopy: {
    ...bulkActionStyles,
    color: evisortBlue,
  },
  bulkActionCopyForbidden: {
    ...bulkActionStyles,
    color: evisortBlue,
    opacity: 0.4,
  },
  bulkActionEdit: {
    ...bulkActionStyles,
    color: evisortBlue,
  },
  bulkActionEditForbidden: {
    ...bulkActionStyles,
    color: evisortBlue,
    opacity: 0.4,
  },
  bulkActionDelete: {
    ...bulkActionStyles,
    color: evisortRed,
  },
  bulkActionDeleteForbidden: {
    ...bulkActionStyles,
    color: evisortRed,
    opacity: 0.4,
  },
  resultsContent: {
    display: 'flex',
  },
  resultsList: {
    flexGrow: '1',
    marginRight: '24px',
  },
  paginationWrapper: {
    marginTop: '24px',
  },
  emptyResultsList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '91px',
  },
  emptyResultsListTitle: {
    margin: '24px 0 8px 0',
    fontSize: '17px',
    fontWeight: semibold,
  },
  emptyResultsListSubtitle: {
    color: black4,
    fontSize: '15px',
  },
  errorLoadingMessage: {
    color: evisortRed,
    fontSize: '17px',
    textAlign: 'center',
  },
  exportSettingButton: {
    backgroundColor: evisortYellow,
    padding: '7px 7px 3px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ffca5b',
    },
    '&:disabled': {
      opacity: 0.4,
    },
  },
  exportButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 12px',
    height: '36px',
    fontSize: '14px',
    fontWeight: semibold,
    backgroundColor: evisortYellow,
    borderRight: 'solid 1px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    '& svg': {
      flexShrink: '0',
    },
    '&:hover': {
      backgroundColor: '#ffca5b',
    },
    '&:disabled': {
      opacity: 0.4,
    },
  },
  exportButtonGroup: {
    backgroundColor: evisortYellow,
    border: 'solid 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    display: 'flex',
    marginLeft: '20px',
  },
};

export default styles;
