import PT from 'prop-types';
import React, { useRef, useState } from 'react';

import {
  UI_MODAL_CANCEL_BUTTON_TESTID,
  UI_MODAL_CLOSE_BUTTON_TESTID,
  UI_MODAL_SECONDARY_BUTTON_TESTID,
  UI_MODAL_SUBMIT_BUTTON_TESTID,
} from '~/constants/testids';
import { IconButton } from '~/eds';
import {
  Box,
  Button,
  FlexLayout,
  Text,
  Tooltip,
  useClickOutside,
  useEscape,
  useLockBodyScroll,
} from '~/ui';

const modalMarginTop = '10vh';
const modalMarginBottom = '30vh';

const widths = {
  m: 'modal-width',
  l: 'modal-width-l',
};

function Modal({
  actionButton,
  backButton,
  children,
  disableAutoHide = false,
  disableCancelButton = false,
  disableCloseButton = false,
  disableHideOnSubmit = false,
  overflowDisplay = 'auto',
  secondaryButton,
  title,
  visible = false,
  width = 'm',
  onCancel,
  onHide,
}) {
  const ref = useRef();
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [isSecondaryActionLoading, setIsSecondaryActionLoading] = useState(
    false,
  );

  function handleCancel() {
    onCancel && onCancel();
    onHide();
  }

  function autoHide() {
    !isActionLoading && !disableAutoHide && handleCancel();
  }

  useClickOutside(ref, autoHide);
  useEscape(autoHide);
  useLockBodyScroll(visible);

  if (!visible) {
    return null;
  }

  const submitAction = async () => {
    setIsActionLoading(true);
    try {
      await actionButton.promise();
      !disableHideOnSubmit && onHide();
    } catch (error) {
      if (actionButton.errorHandler) {
        actionButton.errorHandler(error);
      }
    } finally {
      setIsActionLoading(false);
    }
  };

  const submitSecondaryAction = async () => {
    setIsSecondaryActionLoading(true);
    try {
      await secondaryButton.promise();
      !disableHideOnSubmit && onHide();
    } catch (error) {
      if (secondaryButton.errorHandler) {
        secondaryButton.errorHandler(error);
      }
    } finally {
      setIsSecondaryActionLoading(false);
    }
  };

  return (
    <FlexLayout
      bg="black-alpha-75"
      justifyContent="center"
      sx={{
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 'modal',
      }}
      aria-label={title}
      role="dialog"
    >
      <Box
        bg="white"
        ref={ref}
        sx={{
          borderRadius: 'm',
          boxShadow: 'depth-3',
          height: 'fit-content',
          transform: `translateY(${modalMarginTop})`,
          width: widths[width],
        }}
      >
        <FlexLayout
          alignItems="center"
          bg="gray-100"
          justifyContent="space-between"
          px={6}
          sx={{
            borderBottom: 'border',
            borderTopLeftRadius: 'm',
            borderTopRightRadius: 'm',
            height: 'modal-header-height',
          }}
        >
          <Text color="gray-900" variant="m-spaced-bold">
            {title}
          </Text>
          {!disableCloseButton && (
            <IconButton
              className={UI_MODAL_CLOSE_BUTTON_TESTID}
              icon="x"
              tooltip="close"
              onClick={handleCancel}
            />
          )}
        </FlexLayout>
        <Box
          p={6}
          sx={{
            maxHeight: `calc(100vh - ${modalMarginBottom})`,
            overflow: overflowDisplay,
          }}
        >
          {children}
        </Box>
        <FlexLayout
          alignItems="center"
          justifyContent="space-between"
          px={6}
          space={4}
          sx={{ borderTop: 'border', height: 'modal-footer-height' }}
        >
          {backButton ? <Button {...backButton} variant="outlined" /> : <div />}
          <FlexLayout alignItems="center" space={4}>
            {!disableCancelButton && (
              <Button
                className={UI_MODAL_CANCEL_BUTTON_TESTID}
                text="Cancel"
                variant="ghost"
                onClick={handleCancel}
              />
            )}
            {secondaryButton && (
              <Tooltip content={secondaryButton.tooltip}>
                <Button
                  className={UI_MODAL_SECONDARY_BUTTON_TESTID}
                  disabled={secondaryButton.disabled}
                  isLoading={isSecondaryActionLoading}
                  text={secondaryButton.text}
                  variant={secondaryButton.variant}
                  onClick={submitSecondaryAction}
                />
              </Tooltip>
            )}
            <Tooltip content={actionButton.tooltip}>
              <Button
                className={UI_MODAL_SUBMIT_BUTTON_TESTID}
                disabled={actionButton.disabled}
                isLoading={isActionLoading}
                text={actionButton.text}
                variant={actionButton.variant}
                onClick={submitAction}
              />
            </Tooltip>
          </FlexLayout>
        </FlexLayout>
      </Box>
    </FlexLayout>
  );
}

export const actionButtonPT = PT.shape({
  /** Disables action button */
  disabled: PT.bool,
  /** Handler function for action's promise failure */
  errorHandler: PT.func,
  /** Handler function for main action */
  promise: PT.func.isRequired,
  /** Action text */
  text: PT.string.isRequired,
  /** Tooltip */
  tooltip: PT.node,
  /** Action variant */
  variant: PT.oneOf(['primary', 'red', 'green', 'outlined']),
});

Modal.propTypes = {
  /** Action button object */
  actionButton: actionButtonPT.isRequired,
  /** Renders a Back button in the footer left.  Accepts Button props */
  backButton: PT.object,
  /** React children */
  children: PT.node,
  /** Disables autohide (outside click / Esc press) */
  disableAutoHide: PT.bool,
  /** Hides Cancel button */
  disableCancelButton: PT.bool,
  /** Hides top right Close button */
  disableCloseButton: PT.bool,
  /** Hides modal when submit action is clicked */
  disableHideOnSubmit: PT.bool,
  /** overflow for content outside of modal when menu expanded **/
  overflowDisplay: PT.oneOf(['auto', 'hidden', 'scroll', 'visible']),
  /** Secondary button object*/
  secondaryButton: actionButtonPT,
  /** Modal title text */
  title: PT.string.isRequired,
  /** Controls modal visibility */
  visible: PT.bool,
  /** Width of modal */
  width: PT.oneOf(['m', 'l']),
  /** Callback function when modal is cancelled (close icon or autohide) */
  onCancel: PT.func,
  /** Callback function to trigger logic for hiding modal */
  onHide: PT.func,
};

export default Modal;
