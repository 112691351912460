import { Filter } from '~/evifields';
import { ClauseIdentificationResponse } from '~/features/automation';
import {
  getFilters,
  SearchFilterRecord,
  toSearchV3Filter,
} from '~/redux/api/methods/searchV3';
import { pilot } from '~/services';
import { EntityQuery, FilterQueryEntity, Nullable } from '~/types';

interface Request {
  name: string;
}

interface Response {
  bulk_tagging_enabled_status: 'active' | 'suspended';
  bulk_tagging_last_identification: Nullable<string>;
  bulk_tagging_total_identifications: number;
  smart_clause_last_identification: Nullable<string>;
  smart_clause_total_identifications: number;
  bulk_tagging_filters: Filter[];
}

export const getIdentifications = async ({
  name,
}: Request): Promise<ClauseIdentificationResponse> => {
  const response: Response = await pilot.get(
    '/ai-command-center/identification/',
    {
      params: { clause_name: name },
    },
  );
  const searchFilters = await getFilters();
  return {
    bulkTaggingEnabledStatus: response.bulk_tagging_enabled_status,
    bulkTaggingLastIdentification: response.bulk_tagging_last_identification,
    bulkTaggingTotalIdentifications:
      response.bulk_tagging_total_identifications,
    smartClauseLastIdentification: response.smart_clause_last_identification,
    smartClauseTotalIdentifications:
      response.smart_clause_total_identifications,
    filters: response.bulk_tagging_filters
      ? mapScopeToFilters(response.bulk_tagging_filters, searchFilters)
      : [],
  };
};

const mapScopeToFilters = (
  scope: object[],
  searchFilters: SearchFilterRecord,
): Filter[] => {
  return (scope as EntityQuery[])
    .filter(
      (filter: EntityQuery): filter is FilterQueryEntity =>
        filter.type === 'filter',
    )
    .map((filter: FilterQueryEntity) =>
      toSearchV3Filter(filter, searchFilters),
    );
};
