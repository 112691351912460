import PT from 'prop-types';
import React from 'react';

import { UI_PAGE_LAYOUT_HEADER_CLOSE_BUTTON_TESTID } from '~/constants/testids';
import { Box, FlexLayout, Icon, Text } from '~/ui';

import { PX } from './constants';
import { getTitleMaxWidth } from './Header.utils';

function Header({
  leftIcon,
  centerContent,
  title,
  leftContent,
  rightContent,
  onClose,
}) {
  return (
    <FlexLayout
      alignItems="center"
      bg="white"
      flex="0 0 auto"
      justifyContent="space-between"
      px={PX}
      space={5}
      sx={{
        borderBottom: 'border',
        height: 'page-header-height',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <FlexLayout alignItems="center" flex="auto" space={5}>
        {leftIcon && (
          <Icon
            color="gray-500"
            icon={leftIcon.icon}
            size="m"
            title="go back to previous page"
            onClick={leftIcon.onClick}
          />
        )}
        <Box sx={{ maxWidth: getTitleMaxWidth(title.maxWidth) }}>
          <Text
            shouldTruncate
            color={title.color || 'gray-700'}
            variant="l-dense-bold"
          >
            {title.text}
          </Text>
        </Box>
        {leftContent}
      </FlexLayout>
      {centerContent && (
        <FlexLayout
          alignItems="center"
          justifyContent="center"
          my="auto"
          sx={{
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%)',
          }}
        >
          {centerContent}
        </FlexLayout>
      )}
      <FlexLayout alignItems="center" flex="none" space={5}>
        {rightContent}
        {onClose && (
          <Box pl={3} sx={{ borderLeft: 'border' }}>
            <Icon
              className={UI_PAGE_LAYOUT_HEADER_CLOSE_BUTTON_TESTID}
              color="black-alpha-50"
              icon="close"
              size="m"
              title="Close"
              onClick={onClose}
            />
          </Box>
        )}
      </FlexLayout>
    </FlexLayout>
  );
}

Header.propTypes = {
  /** Optional right header content */
  centerContent: PT.node,
  /** optional left icon */
  leftIcon: PT.shape({
    icon: PT.string.isRequired,
    onClick: PT.func,
  }),
  /** Title for the header */
  title: PT.shape({
    color: PT.string,
    text: PT.string.isRequired,
    maxWidth: PT.number,
  }).isRequired,
  /** Optional left header content*/
  leftContent: PT.node,
  /** Optional right header content */
  rightContent: PT.node,
  /** Renders an optional close icon on the right */
  onClose: PT.func,
};

export default Header;
