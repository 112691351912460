import { memo } from 'react';

import { FormatNumberOptions } from '../../types';
import { formatNumber } from '../../utils';
import { Text } from '../Text';

interface Props {
  /** JS `number` */
  number: number;
  /** status to use for text color. */
  status?: 'active' | 'danger' | 'success';
  /** Formatting options */
  options?: FormatNumberOptions;
}

export const NumberText = memo(({ number, status, options }: Props) => {
  return (
    <Text color={status ? `status.${status}` : 'text.primary'}>
      {formatNumber(number, options)}
    </Text>
  );
});
