import React from 'react';
import { useStyles } from 'uinix-ui';

import { Box } from '../Box';

interface Props {
  width?: number;
  height?: number;
  children: React.ReactNode;
}

export function GridItem({ width, height, children }: Props) {
  const styles = useStyles();
  return (
    <Box
      children={children}
      styles={styles.gridItem}
      styleProps={{ width, height }}
      role="gridcell"
    />
  );
}
