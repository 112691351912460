export default `
<svg
  aria-hidden="true"
  aria-label="panel"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M2.24609 7C2.24609 5.90625 3.12109 5 4.24609 5H20.2461C21.3398 5 22.2461 5.90625 22.2461 7V17C22.2461 18.125 21.3398 19 20.2461 19H4.24609C3.12109 19 2.24609 18.125 2.24609 17V7ZM16.2461 17.5H20.2461C20.4961 17.5 20.7461 17.2812 20.7461 17V7C20.7461 6.75 20.4961 6.5 20.2461 6.5H16.2461V17.5ZM14.7461 6.5H9.74609V17.5H14.7461V6.5ZM8.24609 17.5V6.5H4.24609C3.96484 6.5 3.74609 6.75 3.74609 7V17C3.74609 17.2812 3.96484 17.5 4.24609 17.5H8.24609Z"
    fill="currentcolor"
  />
</svg>
`;
