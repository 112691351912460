import React, { Children, ComponentProps, Fragment } from 'react';

import { Divider } from '../../Divider';
import { Layout } from '../Layout';

export const LayoutDivider = ({
  children,
  direction,
  ...rest
}: ComponentProps<typeof Layout>) => {
  const childrenCount = Children.count(children);
  return (
    <Layout direction={direction} {...rest}>
      {Children.map(children, (child, index) => (
        <Fragment key={index}>
          {child}
          {index < childrenCount - 1 && (
            <Divider isHorizontal={direction === 'column'} />
          )}
        </Fragment>
      ))}
    </Layout>
  );
};
