import { pilot } from '../../../services';

export const getClientDepartments = (clientId, page) =>
  pilot.get(`client/${clientId}/departments/?page=${page}`);

export const addDepartment = (name, members, parent, client) => {
  const data = { name, members, parent, client };
  return pilot.post('department/', data);
};

export const editDepartment = (client, department_id, name, members) => {
  const data = { client, name, members };
  return pilot.put(`department/update/${department_id}/`, data);
};

export const addDepartmentMembers = (client, department_id, name, members) => {
  const data = { client, name, members };
  return pilot.post(`department/${department_id}/members/`, data);
};

export const removeDepartmentMembers = (
  client,
  department_id,
  name,
  members,
) => {
  const data = { client, name, members };
  return pilot.put(`department/${department_id}/members/bulk-delete/`, data);
};

export const listDepartmentMembers = (department_id, page, page_size) => {
  return pilot.get(
    `department/${department_id}/members/?page=${page}&page_size=${page_size}`,
  );
};

export const renameDepartment = (client, department_id, name) => {
  const data = { client, name };
  return pilot.patch(`department/update/${department_id}/`, data);
};

export const deleteDepartment = (department_id) =>
  pilot.remove(`department/${department_id}/`);

export const getClientDepartment = (department_id) =>
  pilot.get(`department/detail/${department_id}/`);

export const getUnassignedMembersInDepartment = (clietnt_id, department_id) =>
  pilot.get(`user/${clietnt_id}/list_unassigned/${department_id}/`);

export async function getClientDepartmentsInfo(clientId) {
  const unassignedUsers = await pilot.get(`user/${clientId}/list_unassigned/`);

  return {
    unassignedUsers,
  };
}
