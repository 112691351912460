export default `
<svg
  aria-hidden="true"
  aria-label="arrow-down"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M17.7968 13.2656L12.5468 18.7656C12.3905 18.9219 12.203 18.9844 12.0155 18.9844C11.7968 18.9844 11.6093 18.9219 11.453 18.7656L6.20304 13.2656C5.92179 12.9531 5.92179 12.4844 6.23429 12.2031C6.51554 11.9219 7.01554 11.9219 7.29679 12.2344L11.2655 16.3906V5.76562C11.2655 5.32812 11.578 5.01562 11.9843 5.01562C12.3593 5.01562 12.7655 5.32812 12.7655 5.76562V16.3906L16.703 12.2344C16.9843 11.9219 17.4843 11.9219 17.7655 12.2031C18.078 12.4844 18.078 12.9531 17.7968 13.2656Z"
    fill="currentcolor"
  />
</svg>
`;
