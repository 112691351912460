import { saveAs } from 'file-saver';
import React from 'react';

import {
  cancelDocumentMetadataImportJob,
  downloadDocumentsMetadataImportResultsExcelFile,
  setDocumentMetadataImportJobAcknowledge,
} from '~/api';
import { showToast } from '~/components/Shared/EcToast';
import { formatDate } from '~/eds';
import {
  BulkImportDocumentMetadataResultType,
  BulkImportStatusType,
  CodedErrorCodeType,
} from '~/enums';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Link } from '~/ui';
import { parseApiError } from '~/utils/parseApiError';

const handleImportErrorsFileDownloadClick = async (importId, clientId) => {
  const resultType = BulkImportDocumentMetadataResultType.ProcessErrors;

  try {
    const result = await downloadDocumentsMetadataImportResultsExcelFile({
      clientId,
      importId,
      resultType,
    });

    showToast(SUCCESS, `${resultType} file downloaded successfully.`);
    const timestamp = formatDate(new Date(), 'iso_datetime');
    saveAs(
      result,
      `evisort-documents-metadata-${resultType}-${timestamp}.xlsx`,
    );
  } catch (errorResponse) {
    handleApiError(
      errorResponse,
      CodedErrorCodeType.BulkImportErrorDownloadingJobResult,
      { resultType },
    );
  }
};

const handleImportProcessCancelAction = async (clientId, importData) => {
  const { importId } = importData;
  try {
    await cancelDocumentMetadataImportJob({ clientId, importId });
    showToast(SUCCESS, 'Cancelled import job successfully.');
  } catch (errorResponse) {
    handleApiError(
      errorResponse,
      CodedErrorCodeType.BulkImportErrorCancellingJob,
    );
  }
};

const handleImportJobAcknowledgeClick = async ({
  clientId,
  importData,
  handleAcknowledgeImportJobUpdate,
}) => {
  const { importId } = importData;
  try {
    await setDocumentMetadataImportJobAcknowledge({ clientId, importId });

    showToast(SUCCESS, 'Acknowledged import job successfully.');
    handleAcknowledgeImportJobUpdate(importId);
  } catch (errorResponse) {
    handleApiError(
      errorResponse,
      CodedErrorCodeType.BulkImportErrorAcknowledgingJob,
    );
  }
};

const handleApiError = (
  errorResponse,
  customCodeError,
  pairsKeyValueToFillTemplate = {},
) => {
  const error = parseApiError(
    errorResponse,
    customCodeError,
    pairsKeyValueToFillTemplate,
  );
  showToast(ERROR, error.message);
};

const getFinishedStatusMessage = ({ importId, counters }, clientId) => {
  const { processed, total, errors } = counters;
  const finishedImportMessage = `Processed ${processed} documents metadata of ${total}.`;
  if (errors === 0) return finishedImportMessage;

  return getErrorDownloadMessage({
    importId,
    clientId,
    errorMessagePrefix: finishedImportMessage,
    errors,
  });
};

const getCancelledStatusMessage = ({ counters }) => {
  const { processed, total, errors } = counters;
  const cancelledImportMessage = `Cancelled - Processed ${processed} documents metadata of ${total}.`;
  if (errors === 0) return cancelledImportMessage;

  return getErrorMessage(cancelledImportMessage, errors);
};

const getErrorMessage = (errorMessagePrefix, errors) =>
  `${errorMessagePrefix} ${errors} documents metadata failed to import.`;

const getErrorDownloadMessage = ({
  importId,
  clientId,
  errorMessagePrefix,
  errors,
}) => {
  return (
    <>
      {getErrorMessage(errorMessagePrefix, errors)}
      <Link
        variant="body2"
        onClick={() => handleImportErrorsFileDownloadClick(importId, clientId)}
      >
        Download the error list
      </Link>{' '}
      to fix errors.
    </>
  );
};

export const barItemBaseProps = {
  [BulkImportStatusType.New]: {
    getIcon: () => 'loading',
    getBarItemText: () => <>Documents metadata import in queue.</>,
    getActions: ({ clientId, importData }) => [
      {
        onClick: () => handleImportProcessCancelAction(clientId, importData),
        text: 'Cancel Import',
      },
    ],
  },
  [BulkImportStatusType.Failed]: {
    getIcon: () => 'danger',
    getBarItemText: () => <>Documents metadata import failed.</>,
    getActions: () => [],
    handleImportJobAcknowledgeClick,
  },
  [BulkImportStatusType.Running]: {
    getIcon: () => 'loading',
    getBarItemText: ({ importData: { counters } }) => (
      <>
        Processed {counters.processed} documents metadata of {counters.total}.
      </>
    ),
    getActions: ({ clientId, importData }) => [
      {
        onClick: () => handleImportProcessCancelAction(clientId, importData),
        text: 'Cancel Import',
      },
    ],
  },
  [BulkImportStatusType.Finished]: {
    getIcon: ({ errors }) => (errors === 0 ? 'success' : 'warning'),
    getBarItemText: ({ clientId, importData }) =>
      getFinishedStatusMessage(importData, clientId),
    getActions: () => [],
    handleImportJobAcknowledgeClick,
  },
  [BulkImportStatusType.Cancelled]: {
    getIcon: () => 'warning',
    getBarItemText: ({ clientId, importData }) =>
      getCancelledStatusMessage(importData, clientId),
    getActions: () => [],
    handleImportJobAcknowledgeClick,
  },
};
