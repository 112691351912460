import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';

import { formatDate, Layout, useToast } from '~/eds';
import { BulkImportStatusType, BulkImportUserResultType } from '~/enums';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { Nullable, PilotId, Uuid } from '~/types';

import { ImportStatus } from './ImportStatus';

interface Props {
  /** should be provided if used by super admin */
  clientId?: PilotId;
  /** active import id to get status */
  activeImportId: Nullable<Uuid>;
}

export const ImportUserStatus = ({
  clientId: clientIdParam,
  activeImportId,
}: Props) => {
  const currentUser = useCurrentUser();
  const [hasActiveImportFinished, setHasActiveImportFinished] = useState(false);
  const clientId = clientIdParam ?? currentUser.client;
  const shouldDoPolling = activeImportId && !hasActiveImportFinished;
  const { data } = api.endpoints.getUsersImportStatus.useQuery(
    {
      clientId,
      importId: activeImportId ? activeImportId : undefined,
    },
    { pollingInterval: shouldDoPolling ? 2000 : undefined },
  );

  const [
    downloadErrorList,
  ] = api.endpoints.downloadImportResultsExcelFile.useLazyQuery();

  const [cancelImportJob] = api.endpoints.cancelImportJob.useMutation();

  const { toast } = useToast();

  const [
    acknowledgeUserImport,
  ] = api.endpoints.acknowledgeUserImport.useMutation();

  useEffect(() => {
    if (activeImportId && data) {
      const activeImportStatus = data.find(
        (el) => el.importId === activeImportId,
      );
      if (activeImportStatus) {
        setHasActiveImportFinished(
          [
            BulkImportStatusType.Finished,
            BulkImportStatusType.Cancelled,
            BulkImportStatusType.Failed,
          ].includes(activeImportStatus.status),
        );
      } else {
        setHasActiveImportFinished(true);
      }
    }
  }, [data]);

  const onAcknowledge = async (importId: Uuid) => {
    try {
      await acknowledgeUserImport({ clientId, importId }).unwrap();
      toast({
        message: 'Acknowledged import job successfully.',
        status: 'success',
      });
    } catch {
      toast({
        message: 'Error acknowledging import job',
        status: 'danger',
      });
    }
  };

  const onDownloadErrorList = async (importId: Uuid) => {
    const filename =
      'evisort-users-' + formatDate(new Date(), 'iso_datetime') + '.xlsx';

    const response = await downloadErrorList({
      clientId,
      importId,
      resultType: BulkImportUserResultType.ProcessErrors,
    }).unwrap();
    saveAs((response as unknown) as Blob, filename);
  };

  const onCancelImportJob = async (importId: Uuid) => {
    try {
      await cancelImportJob({
        clientId,
        importId,
      });
      toast({
        message: 'Import job cancelled.',
        status: 'success',
      });
    } catch {
      toast({
        message: 'An error occured while cancelling import job.',
        status: 'danger',
      });
    }
  };

  return (
    <Layout direction="column" spacing={2}>
      {data?.map((status) => (
        <ImportStatus
          key={status.id}
          status={status}
          onAcknowledge={() => onAcknowledge(status.importId)}
          onDownloadErrorList={() => onDownloadErrorList(status.importId)}
          onCancelImportJob={() => onCancelImportJob(status.importId)}
        />
      ))}
    </Layout>
  );
};
