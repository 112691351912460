import { pilot } from '~/services';

interface Response {
  suggestions: string[];
}

export const getProvisions = async () => {
  const response: Response = await pilot.get(
    '/search/suggestion/provision-type/',
  );

  return response;
};
