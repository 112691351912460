import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FlexLayout, Modal, Text, TextInput } from '~/ui';

const BulkUserImportWarningModal = (props) => {
  const {
    cancelAction,
    confirmAction,
    actionButtonText,
    actionButtonVariant,
    warningMessage,
    warningTitle,
    requireConfirm,
    warningMessageToConfirm,
    textToConfirm,
  } = props;

  const [inputValueToConfirm, setInputValueToConfirm] = useState('');
  const [disabledConfirmAction, setDisabledConfirmAction] = useState(
    requireConfirm,
  );

  const handleConfirmInputChange = (value) => {
    setInputValueToConfirm(value);

    if (value === textToConfirm) {
      setDisabledConfirmAction(false);
    } else {
      setDisabledConfirmAction(true);
    }
  };

  const actionButton = {
    disabled: disabledConfirmAction,
    text: actionButtonText,
    variant: actionButtonVariant,
    promise: confirmAction,
    errorHandler: () => {},
  };

  return (
    <Modal
      actionButton={actionButton}
      disableAutoHide={true}
      title={warningTitle}
      visible={true}
      onCancel={cancelAction}
      onHide={() => {}}
    >
      <FlexLayout flexDirection="column" space={6}>
        <Text variant="xs-dense">{warningMessage}</Text>

        {requireConfirm && (
          <>
            <Text variant="xs-dense">{warningMessageToConfirm}</Text>
            <TextInput
              value={inputValueToConfirm}
              width="fullWidth"
              placeholder={textToConfirm}
              onChange={handleConfirmInputChange}
            />
          </>
        )}
      </FlexLayout>
    </Modal>
  );
};

BulkUserImportWarningModal.propTypes = {
  actionButtonText: PropTypes.string.isRequired,
  actionButtonVariant: PropTypes.string.isRequired,
  cancelAction: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  warningMessage: PropTypes.string.isRequired,
  warningTitle: PropTypes.string.isRequired,
  requireConfirm: PropTypes.bool.isRequired,
  warningMessageToConfirm: PropTypes.string,
  textToConfirm: PropTypes.string,
};

export default BulkUserImportWarningModal;
