import React from 'react';

import { SharedHeaderProps } from '../../types';
import { Actions } from '../Actions';
import { Chips } from '../Chips';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Layout } from '../Layout';
import { Link } from '../Link';
import { TruncateText } from '../Text';

interface Props extends SharedHeaderProps {
  /** Supported DOM elements */
  as?: 'header' | 'summary';
  /** Enables padding */
  enablePadding?: boolean;
  /** Click handler */
  onClick?: (event?: MouseEvent) => void;
}

export const Header = ({
  as = 'header',
  actions,
  backAction,
  calloutContent,
  chips,
  enablePadding = true,
  icon,
  iconPosition = 'left',
  id,
  moreActions,
  moreActionsPosition = 'right',
  size,
  title,
  titleLink,
  toggleAction,
  onClick,
}: Props) => {
  const titleContent = (
    <TruncateText role="heading" variant="section">
      {title}
    </TruncateText>
  );

  const iconComponent = icon ? <Icon {...icon} /> : null;

  return (
    <Layout
      align="center"
      as={as}
      borderBottom="border.divider"
      flex="none"
      id={id}
      justify="space-between"
      minH={size === 'l' ? 'header.height.l' : 'header.height'}
      pl={enablePadding ? 4 : 0}
      pr={enablePadding ? 3 : 0}
      py={1}
      role={as === 'summary' ? 'button' : undefined}
      spacing={2}
      onClick={onClick}
    >
      <Layout align="center" minW={0} spacing={2}>
        {backAction && <IconButton {...backAction} icon="arrow-left" />}
        {toggleAction && <IconButton {...toggleAction} />}
        {iconPosition === 'left' && iconComponent}
        {titleLink ? (
          <Link {...titleLink} enableNewTab shouldTruncate variant="section">
            {titleContent}
          </Link>
        ) : (
          titleContent
        )}
        {iconPosition === 'right' && iconComponent}
        {chips && <Chips chips={chips} />}
      </Layout>
      <Layout align="center" spacing={2}>
        {calloutContent}
        {actions && (
          <Actions
            actions={actions}
            moreActions={moreActions}
            moreActionsPosition={moreActionsPosition}
          />
        )}
      </Layout>
    </Layout>
  );
};
