import PT from 'prop-types';
import React from 'react';

import { Box, Card, FlexLayout, Text } from '~/ui';

function IntegrationDetailCard({
  headerRightContent,
  logoSrc,
  title,
  ...rest
}) {
  const header = (
    <Card.Header
      rightContent={headerRightContent}
      title={
        <FlexLayout alignItems="center" ml={3} space={6}>
          <Box
            as="img"
            src={logoSrc}
            sx={{
              height: 'auto',
              width: 40,
            }}
          />
          <Text variant="s-dense-bold">{title}</Text>
        </FlexLayout>
      }
    />
  );

  return <Card header={header} {...rest} />;
}

IntegrationDetailCard.propTypes = {
  headerRightContent: PT.node,
  logoSrc: PT.string.isRequired,
  title: PT.string.isRequired,
};

export default IntegrationDetailCard;
