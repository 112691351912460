import React, { memo } from 'react';

import { User } from '../../types';
import { Layout } from '../Layout';
import { UserNameOptions } from './types';
import { UserAvatar } from './UserAvatar';
import { UserName } from './UserName';

interface Props {
  user?: User;
  options?: UserNameOptions;
  placeholder?: string;
}

export const UserAvatarName = memo(
  ({ options = {}, user, placeholder = 'Unknown User' }: Props) => {
    const { date } = options;

    return (
      <Layout align="center" spacing={2} maxW="100%">
        <UserAvatar
          options={{ date, size: 'm' }}
          user={user}
          placeholder={placeholder}
        />
        <UserName
          mode="full"
          user={user}
          options={options}
          placeholder={placeholder}
        />
      </Layout>
    );
  },
);
