import React, { memo } from 'react';

import { SharedFooterProps } from '../../types';
import { Actions } from '../Actions';
import { Layout } from '../Layout';

interface Props extends SharedFooterProps {
  /** Enables top border of footer */
  enableBorder?: boolean;
  /** Embedded footer */
  isEmbedded?: boolean;
  /** Footer size */
  size?: 's' | 'm';
}

export const Footer = memo(
  ({
    actions = [],
    children,
    enableBorder = true,
    isEmbedded = false,
    justify = 'flex-end',
    leftActions = [],
    size = 'm',
  }: Props) => {
    const enableCustomContent = Boolean(children);

    const content = enableCustomContent ? (
      children
    ) : (
      <Layout align="center" justify="space-between">
        {leftActions.length > 0 && (
          <Actions actions={leftActions} justify="flex-start" />
        )}
        <Actions actions={actions} flex="auto" justify={justify} />
      </Layout>
    );

    return (
      <Layout
        as="footer"
        borderTop={enableBorder ? 'border.divider' : undefined}
        styles={componentStyles.container({
          enableCustomContent,
          isEmbedded,
          size,
        })}
      >
        {content}
      </Layout>
    );
  },
);

const componentStyles = {
  container: ({
    enableCustomContent,
    isEmbedded,
    size,
  }: {
    enableCustomContent: boolean;
    isEmbedded: boolean;
    size: 's' | 'm';
  }) => {
    const isSmall = size === 's';
    const height = enableCustomContent
      ? 'fit-content'
      : isSmall
      ? 'footer.height.s'
      : 'footer.height';

    const px = isEmbedded ? 0 : isSmall ? 3 : 6;
    const py = enableCustomContent ? 3 : 0;

    return {
      flex: 'none',
      height,
      paddingBottom: py,
      paddingLeft: px,
      paddingRight: px,
      paddingTop: py,
      '> *': {
        width: '100%',
      },
    };
  },
};
