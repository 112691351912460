/**
 * The bridge utilities in this file deal with the unfortunate fact that react-accessible-treeview overwrites the input data's "id" property with a generated numeric id.  These utilities track the original folder ID so that they can be resolved accordingly.
 */
import { cloneDeep } from 'lodash';
import { flattenTree } from 'react-accessible-treeview';

import {
  Folder,
  FolderNode,
  Id,
  NodeRendererProps,
  ResolvedNodeProps,
  TreeViewNode,
  TreeViewState,
  Value,
} from './types';

const FOLDER_ID_KEY: 'folderId' = 'folderId';

const trackFolderIds = (tree: FolderNode): void => {
  tree[FOLDER_ID_KEY] = tree.id;
  tree.children.forEach((child) => {
    trackFolderIds(child);
  });
};

export const getFolderFromNode = (node: TreeViewNode): Folder => {
  // do not include the following generated properties: id, folderId, children
  const { id: _id, children: _children, parent: _parent, ...rest } = node;
  return {
    id: node[FOLDER_ID_KEY] as Id,
    ...rest,
  };
};

export const getDefaultGeneratedIds = ({
  data,
  value,
}: {
  data: TreeViewNode[];
  value: Value;
}): {
  defaultDisabledIds: Id[];
  defaultExpandedIds: Id[];
  defaultSelectedIds: Id[];
} => {
  const selectedIdSet = new Set(Array.isArray(value) ? value : [value]);
  const defaultDisabledIds: Id[] = [];
  const defaultExpandedIds: Id[] = [];
  const defaultSelectedIds: Id[] = [];

  data.forEach((d) => {
    const { id } = d;
    if (selectedIdSet.has(d[FOLDER_ID_KEY])) {
      defaultSelectedIds.push(id);
    }
    if (d.disabled) {
      defaultDisabledIds.push(id);
    }
    if (d.isExpanded) {
      defaultExpandedIds.push(id);
    }
  });

  return { defaultDisabledIds, defaultExpandedIds, defaultSelectedIds };
};

export const prepareData = (folders: FolderNode[]): TreeViewNode[] => {
  const tree = cloneDeep({ id: 'root', name: 'root', children: folders });
  trackFolderIds(tree);
  return flattenTree(tree);
};

export const getSelectedFolderData = (
  treeState: TreeViewState,
  data: TreeViewNode[],
): {
  lastSelectedFolder: Folder | null;
  selectedIds: Id[];
} => {
  let lastSelectedFolder = null;
  const selectedIds: Id[] = [];
  data.forEach((node) => {
    const folderId = node[FOLDER_ID_KEY];
    if (treeState.selectedIds.has(node.id)) {
      selectedIds.push(folderId);
    }
    if (node.id === treeState.lastUserSelect) {
      lastSelectedFolder = getFolderFromNode(node);
    }
  });
  return { lastSelectedFolder, selectedIds };
};

// remap react-accessible-treeview's nodeRenderer internal props to our public props
export const resolveNodeProps = (
  nodeRendererProps: NodeRendererProps,
): ResolvedNodeProps => {
  const {
    dispatch,
    element,
    getNodeProps,
    handleSelect,
    isBranch,
    isExpanded,
    isHalfSelected,
    isSelected,
    level,
  } = nodeRendererProps;
  const {
    className: _className,
    disabled: _disabled,
    onClick: _onClick,
    ...ariaProps
  } = getNodeProps();

  const onExpand = (updatedExpand: boolean): void => {
    dispatch({
      type: updatedExpand ? 'EXPAND' : 'COLLAPSE',
      id: element.id,
    });
  };

  const onSelect = handleSelect;

  return {
    ariaProps,
    folder: {
      ...element,
      id: element[FOLDER_ID_KEY],
    },
    isBranch,
    isExpanded,
    isIndeterminate: isHalfSelected,
    isSelected,
    level,
    onExpand,
    onSelect,
  };
};
