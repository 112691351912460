export const tabs = {
  tab: ({
    isFocused = false,
    isSelected = false,
  }: {
    isFocused?: boolean;
    isSelected?: boolean;
  }) => {
    const activeBorderColor = 'currentcolor';

    let borderColor;
    if (isSelected) {
      borderColor = activeBorderColor;
    } else if (isFocused) {
      borderColor = 'border.divider';
    } else {
      borderColor = 'transparent';
    }

    return {
      alignItems: 'center',
      display: 'flex',
      padding: 4,
      position: 'relative',
      '::after': {
        borderBottom: 'border.divider',
        borderColor,
        borderWidth: 'l',
        bottom: 0,
        content: '""',
        left: 0,
        position: 'absolute',
        right: 0,
      },
      ':hover': {
        '::after': {
          borderColor: isSelected ? activeBorderColor : 'border.divider',
          filter: 'brightness(0.9)',
        },
      },
      ':focus': {
        '::after': {
          borderColor: isSelected ? activeBorderColor : 'border.divider',
          filter: 'brightness(0.8)',
        },
      },
    };
  },
};
