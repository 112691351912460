import { AlgorithmMetric } from '~/features/process-documents';
import { pilot } from '~/services';
import { PilotId } from '~/types';

interface Request {
  clientId: PilotId;
}

interface Response {
  data: Record<
    string,
    {
      failed: number;
      not_run: number;
      successful: number;
      active: boolean;
      last_run: string;
    }
  >;
}

export const getAlgorithmMetrics = async ({
  clientId,
}: Request): Promise<AlgorithmMetric[]> => {
  const response: Response = await pilot.get(
    `client/${clientId}/document-processing-status/`,
  );

  return Object.entries(response.data).map(([key, value]) => {
    return {
      id: key,
      title: key,
      counts: {
        failed: value.failed,
        notRun: value.not_run,
        successful: value.successful,
      },
      status: value.active ? 'active' : 'inactive',
      lastRunDate: value.last_run ? new Date(value.last_run) : undefined,
    };
  });
};
