import React, { useEffect } from 'react';

import { documentTypeIcon } from '~/components/DocumentsPage/Document.utils';
import { useRouting } from '~/routing';
import { Box, FlexLayout, Navigator } from '~/ui';

const getNavigatorTooltip = (item) => {
  if (!item) return null;

  const { fileType, name } = item;
  const iconSize = '20';
  const icon = documentTypeIcon(fileType, iconSize);

  return (
    <FlexLayout alignItems="center" space={3}>
      <FlexLayout flex="none">{icon}</FlexLayout>
      <Box>{name}</Box>
    </FlexLayout>
  );
};

const DocumentIdWatcher = ({ documentId, setIndexById, verifyId }) => {
  useEffect(() => {
    verifyId(documentId);
    setIndexById({ value: documentId });
  }, [documentId]);

  return null;
};

const DocumentsNavigation = ({
  context,
  documentId,
  fillCurrentIndexList,
  verifyHandlerId,
  location,
  selectItem,
  setIndexById,
}) => {
  const { navigate } = useRouting();

  const navigateToDocument = (docId) => {
    const { search } = location;
    const newUrl = `/document/${docId}${search}`;
    navigate(newUrl);
  };

  const tryNavigateWithLocalList = (index, list) => {
    const item = list[index];
    if (item) {
      navigateToDocument(item.id);
    }
  };

  const tryNavigateTo = (offset) => {
    const item = selectItem(context, offset);
    if (item) {
      navigateToDocument(item.id);
    }
  };

  const handleChange = async (value) => {
    const { currentIndex, total } = context;

    if (value > total) return;

    const index = value - 1;

    if (index === currentIndex) return;

    await fillCurrentIndexList({
      context,
      index,
      callback: (list) => {
        tryNavigateWithLocalList(index, list);
      },
    });
  };

  const handlePrev = async () => {
    const index = context.currentIndex - 1;
    await fillCurrentIndexList({
      context,
      index,
    });
    tryNavigateTo(-1);
  };

  const handleNext = async () => {
    const index = context.currentIndex + 1;
    await fillCurrentIndexList({
      context,
      index,
    });
    tryNavigateTo(+1);
  };

  const handleVerifyId = async (handlerId) => {
    await verifyHandlerId({
      context,
      handlerId,
    });
  };

  const handleClick = () => {
    navigate(context.fromLocation);
    context.onClick?.();
  };

  return (
    <>
      {context.currentIndex >= 0 && !context.fetching && (
        <Navigator
          label={context.name}
          onClick={handleClick}
          onChange={handleChange}
          onNext={handleNext}
          onPrev={handlePrev}
          tooltipPrev={getNavigatorTooltip(selectItem(context, -1))}
          tooltipNext={getNavigatorTooltip(selectItem(context, +1))}
          total={context.total}
          value={1 + context.currentIndex}
        />
      )}
      <DocumentIdWatcher
        documentId={documentId}
        setIndexById={setIndexById}
        verifyId={handleVerifyId}
      />
    </>
  );
};

export default DocumentsNavigation;
