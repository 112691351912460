import React from 'react';

import FilterChips from '~/components/Shared/Filters/FilterChips';
import {
  removeFilter,
  updateFilter,
} from '~/components/Shared/Filters/Filters.utils';
import { Filter } from '~/evifields';
import { SearchFilter } from '~/types';

type Props = {
  searchFilters: SearchFilter[];
  filters: Filter[];
  onChange: (filters: Filter[]) => void;
};

// TODO: Once we fully integrate filters with query builder, we should move this as well and it can be removed from here.
export const CrossFilters = ({ searchFilters, filters, onChange }: Props) => {
  const handleRemoveFilter = (filterToRemove: Filter) => {
    onChange(removeFilter(filters, filterToRemove));
  };

  const handleUpdateFilter = (updatedFilter: Filter) => {
    onChange(updateFilter(filters, updatedFilter));
  };

  return (
    <FilterChips
      searchFilters={searchFilters}
      filters={filters}
      onRemoveFilter={handleRemoveFilter}
      onUpdateFilter={handleUpdateFilter}
      disableEdit={true}
      disableRemove={false}
      placeholder=""
    />
  );
};
