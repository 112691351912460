export enum ClientType {
  Onboarding = 'ONBOARDING',
  Normal = 'NORMAL',
  Pilot = 'PILOT',
  Poc = 'POC',
  Internal = 'INTERNAL',
  QA = 'QA',
  LiveClient = 'LIVE',
  ChurnedClient = 'CHURNED',
}
