export const position = ({
  position,
  bottom,
  left,
  right,
  top,
}: {
  position: 'fixed' | 'absolute';
  bottom?: number;
  left?: number;
  right?: number;
  top?: number;
}) => ({
  position,
  bottom,
  left,
  right,
  top,
});
