export default `
<svg
  aria-hidden="true"
  aria-label="field-number"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM7.64 15H6.49V10.5L5.59 11.16L5.01 10.27L6.77 9H7.64V15ZM13.5 15H9.61V13.98C10.68 12.91 11.38 12.21 11.74 11.83C12.14 11.41 12.28 11.14 12.28 10.77C12.28 10.37 11.97 10.05 11.47 10.05C10.95 10.05 10.67 10.44 10.57 10.77L9.56 10.35C9.57 10.33 9.74 9.59 10.56 9.2C11.25 8.87 12.04 9 12.51 9.23C13.37 9.67 13.42 10.47 13.42 10.71C13.42 11.35 13.11 11.97 12.5 12.57C12.25 12.82 11.78 13.28 11.1 13.96L11.13 14.01H13.5V15ZM18.75 14.15C18.67 14.28 18.19 15 16.99 15C16.95 15 15.39 15.08 14.94 13.49L15.97 13.08C16 13.18 16.16 13.94 16.99 13.94C17.4 13.94 17.88 13.66 17.88 13.17C17.88 12.62 17.4 12.38 16.84 12.38H16.34V11.38H16.8C17.13 11.38 17.68 11.24 17.68 10.66C17.68 10.27 17.37 10.01 16.93 10.01C16.43 10.01 16.19 10.33 16.08 10.65L15.09 10.24C15.2 9.9 15.68 9 16.94 9C18.03 9 18.48 9.64 18.56 9.75C18.89 10.25 18.84 10.91 18.58 11.32C18.43 11.54 18.26 11.7 18.06 11.8V11.87C18.34 11.98 18.57 12.15 18.74 12.39C19.11 12.91 19.07 13.66 18.75 14.15Z"
    fill="currentColor"
  />
</svg>
`;
