import { CodedErrorCodeType, CodedErrorNamespaceType } from '../enums';

export interface ICodedError {
  code: CodedErrorCodeType;
  name: string;
  message: string;
  namespace: CodedErrorNamespaceType;
}

const codedErrors: Record<number, ICodedError> = {
  [CodedErrorCodeType.BulkImportExistingNewOrRunningJob]: {
    code: CodedErrorCodeType.BulkImportExistingNewOrRunningJob,
    message:
      'Another import is in progress. You must wait until it is completed before starting another import.',
    name: 'New or running job found',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportJobStatusCannotBeCancelled]: {
    code: CodedErrorCodeType.BulkImportJobStatusCannotBeCancelled,
    message: 'Only running or new jobs can be cancelled.',
    name: 'Job can not be cancelled',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportJobStatusCannotBeAcknowledged]: {
    code: CodedErrorCodeType.BulkImportJobStatusCannotBeAcknowledged,
    message: 'New or running jobs can not be acknowledged.',
    name: 'Job can not be acknowledged',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportInvalidOrMissingClientId]: {
    code: CodedErrorCodeType.BulkImportInvalidOrMissingClientId,
    message: 'Invalid or missing client_id.',
    name: 'Invalid or missing client_id',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportInvalidOrMissingImportId]: {
    code: CodedErrorCodeType.BulkImportInvalidOrMissingImportId,
    message: 'Invalid or missing import_id.',
    name: 'Invalid or missing import_id',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportFileNotProvided]: {
    code: CodedErrorCodeType.BulkImportFileNotProvided,
    message: 'File not provided.',
    name: 'File not provided',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportFileProvidedNotValidExcel]: {
    code: CodedErrorCodeType.BulkImportFileProvidedNotValidExcel,
    message: 'The provided file is not a valid Excel file.',
    name: 'Invalid Excel file',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportFileNameNotFound]: {
    code: CodedErrorCodeType.BulkImportFileNameNotFound,
    message: 'The file was not found.',
    name: 'File not found',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportResultFileTypeNotValid]: {
    code: CodedErrorCodeType.BulkImportResultFileTypeNotValid,
    message: 'The file to download is not a result valid type.',
    name: 'Invalid result type file',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportFileInfected]: {
    code: CodedErrorCodeType.BulkImportFileInfected,
    message:
      'Your file cannot be processed as it has been flagged by our anti-virus scanning. If you believe this is an error, please contact support.',
    name: 'File with virus',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkExportDocumentMetadataErrorDownloading]: {
    code: CodedErrorCodeType.BulkExportDocumentMetadataErrorDownloading,
    message: 'Something went wrong with downloading document metadata.',
    name: 'Error Downloading Document Metadata',
    namespace: CodedErrorNamespaceType.BulkExport,
  },
  [CodedErrorCodeType.BulkImportDocumentMetadataErrorSubmittingFile]: {
    code: CodedErrorCodeType.BulkImportDocumentMetadataErrorSubmittingFile,
    message: 'An error occurred while submitting the file for import',
    name: 'Error submitting document metadata file for import',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportUserErrorSubmittingFile]: {
    code: CodedErrorCodeType.BulkImportUserErrorSubmittingFile,
    message: 'Something went wrong when uploading Users List file.',
    name: 'Error submitting user file for import',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportErrorFetchingJobs]: {
    code: CodedErrorCodeType.BulkImportErrorFetchingJobs,
    message: 'Failed when trying to fetch the import jobs.',
    name: 'Error fetching import jobs',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportErrorDownloadingJobResult]: {
    code: CodedErrorCodeType.BulkImportErrorDownloadingJobResult,
    message:
      'Something went wrong when downloading {{resultType:resultType}} file.',
    name: 'Error downloading import jobs result',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportErrorCancellingJob]: {
    code: CodedErrorCodeType.BulkImportErrorCancellingJob,
    message: 'Failed when trying to cancel an import job.',
    name: 'Error cancelling import job',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportErrorAcknowledgingJob]: {
    code: CodedErrorCodeType.BulkImportErrorAcknowledgingJob,
    message: 'Failed when trying to acknowledge an import job.',
    name: 'Error acknowledging import job',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkExportUserErrorDownloading]: {
    code: CodedErrorCodeType.BulkExportUserErrorDownloading,
    message: 'Something went wrong with downloading system users',
    name: 'Error downloading users',
    namespace: CodedErrorNamespaceType.BulkExport,
  },
  [CodedErrorCodeType.BulkExportUserTemplateErrorDownloading]: {
    code: CodedErrorCodeType.BulkExportUserTemplateErrorDownloading,
    message: 'Something went wrong with downloading users template',
    name: 'Error downloading users template',
    namespace: CodedErrorNamespaceType.BulkExport,
  },
  [CodedErrorCodeType.BulkImportUserErrorQueuingJob]: {
    code: CodedErrorCodeType.BulkImportUserErrorQueuingJob,
    message:
      'Something went wrong when queuing Import Users List for processing.',
    name: 'Error queuing user job',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportDuplicatedColumns]: {
    code: CodedErrorCodeType.BulkImportDuplicatedColumns,
    message:
      'Remove the duplicated columns in your file to continue: {{detail:duplicatedColumns}}',
    name: 'Duplicated columns',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportEmptyColumns]: {
    code: CodedErrorCodeType.BulkImportEmptyColumns,
    message:
      'The uploaded spreadsheet contains columns with data but has empty field names: {{detail:empty_columns}}. Please review and re-upload to continue.',
    name: 'Empty columns',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
  [CodedErrorCodeType.BulkImportMissingColumns]: {
    code: CodedErrorCodeType.BulkImportMissingColumns,
    message:
      'There are missing mandatory columns in your uploaded file: {{detail:missingColumns}}. Add columns in and upload again.',
    name: 'Missing columns',
    namespace: CodedErrorNamespaceType.BulkImport,
  },
};

export default codedErrors;

export type IPairsKeyValueToFillTemplate = Record<string, string>;
