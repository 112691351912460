import React, { memo } from 'react';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { TruncateText } from '../Text';
import { Step } from './types';
import {
  findCurrentStepIndex,
  testHasStarted,
  testIsAllStepsCompleted,
  testIsCompleted,
} from './utils';

interface Props {
  steps: Step[];
}

export const Steps = memo(({ steps }: Props) => {
  const currentStepIndex = findCurrentStepIndex(steps);
  const isAllStepsCompleted = testIsAllStepsCompleted(steps);

  return (
    <Layout align="center" h="100%" px={2}>
      {steps.map((step, index) => {
        const { description } = step;

        const hasStarted = testHasStarted(step);
        const isCompleted = testIsCompleted(step);
        const isFutureStep = index > currentStepIndex;

        const icon = isCompleted
          ? 'status-success'
          : hasStarted
          ? 'status-current'
          : 'status-not-started';
        const color =
          !isAllStepsCompleted && isFutureStep
            ? 'status.active'
            : 'inverse.icon';

        return (
          <Box key={index} styles={componentStyles.indicator.wrapper}>
            <Box styles={componentStyles.indicator.description}>
              <TruncateText variant="tiny">{description}</TruncateText>
            </Box>
            <Icon color={color} icon={icon} />
          </Box>
        );
      })}
    </Layout>
  );
});

const componentStyles = {
  indicator: {
    wrapper: {
      flex: 'auto',
      position: 'relative',
    },
    description: {
      bottom: 'calc(100% + 12px)',
      left: 0,
      position: 'absolute',
      right: 0,
    },
  },
};
