import uuid from 'uuid';

import { pilot } from '~/services';
import { PilotId } from '~/types';

interface Request {
  isInternal: boolean;
  clientId: PilotId;
  files: File[];
}

export const uploadParty = async (request: Request): Promise<boolean> => {
  const formData = await mapRequestToFormData(request);

  await pilot.post(`client/${request.clientId}/upload-party-list/`, formData);

  return true;
};

const mapRequestToFormData = async (request: Request) => {
  const { files, isInternal } = request;

  const id = uuid.v4();
  const contents = Promise.all(files.map(async (file) => file.text()));
  const mergedContent = (await contents).join(',');
  const file = new File([mergedContent], `${id}.csv`);

  const formData = new FormData();
  formData.append('id', id);
  formData.append('file', file);
  formData.append('filename', file.name);
  formData.append(
    'upload_form_data',
    JSON.stringify([
      {
        isInternal,
      },
    ]),
  );
  formData.append('size_in_bytes', String(file.size));
  return formData;
};
