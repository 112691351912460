import React from 'react';

import { FormField, Select } from '~/eds';
import {
  FormField as FormFieldType,
  SingleSelectField as SingleSelectType,
} from '~/types';

type Option = { label: string; value: string };

type Props = {
  formField: FormFieldType<SingleSelectType>;
  errors?: string[];
  onExplain?: (fieldId: string) => void;
  onChange: (value: Option) => void;
};

export const SingleSelectField = ({
  formField,
  errors = [],
  onExplain,
  onChange,
}: Props) => {
  return (
    <FormField
      onExplain={onExplain ? () => onExplain(formField.fieldName) : undefined}
      input={Select}
      inputProps={{
        options: formField.field?.options ?? [],
        isClearable: false,
        isSearchable: false,
      }}
      name={formField.fieldName}
      value={formField?.field?.value?.value}
      disabled={formField.disabled}
      onChange={(value) => {
        onChange({ ...formField.field.value, value: value as string });
      }}
      error={errors.join(', ')}
      label={formField.title}
      description={formField.description}
      required={formField.required}
    />
  );
};
