import {
  black1,
  black2,
  black3,
  bold,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const stateContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
};

const styles = {
  emptyState: {
    ...stateContainerStyles,
    height: '230px',
  },
  emptyStateTitle: {
    marginBottom: '8px',
    color: black2,
    fontSize: '18px',
    fontWeight: bold,
  },
  emptyStateText: {
    width: '640px',
    color: black2,
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: 1.5,
  },
  searchesSection: {
    marginTop: '40px',
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',

    '& svg': {
      flexShrink: 0,
      marginRight: '12px',
    },
  },
  sectionTitle: {
    marginBottom: '4px',
    color: black2,
    fontSize: '21px',
    fontWeight: bold,
  },
  sectionSubtitle: {
    color: black2,
    fontSize: '14px',
  },
  searchTable: {
    padding: '0 16px',
    '& table': {
      width: '100%',
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
      textAlign: 'left',
      '& th': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        paddingTop: '16px',
        paddingBottom: '16px',
        color: black3,
        fontSize: '13px',
        fontWeight: semibold,
        textAlign: 'left',
      },
      '& td': {
        color: black2,
        fontSize: '15px',
        wordBreak: 'break-word',
      },
      '& tr': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        '&:last-child': {
          border: 0,
        },
      },
    },
  },
  headerApplyFilters: { width: '134px' },
  headerSearchFilters: { width: '94px' },
  headerActions: { width: '34px' },
  cellName: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '14px 8px 14px 0',
  },
  tableAction: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    wordBreak: 'normal',
    '& svg': {
      flexShrink: 0,
      marginRight: '4px',
    },
    '& span': {
      color: evisortBlue,
      fontSize: '13px',
      fontWeight: bold,
    },
    '&:disabled': {
      opacity: 0.5,
    },
  },
  cellCreatedBy: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cellDate: {
    padding: '14px 8px 14px 0',
  },
  recentSearchFilter: {
    borderRadius: '4px',
    margin: '4px 0',
    padding: '4px 8px',
    color: black1,
    fontSize: '13px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
  recentSearchOperator: {
    margin: '0 8px',
    color: black2,
    fontSize: '13px',
    fontWeight: bold,
    textTransform: 'uppercase',
  },
  recentSearchSection: {
    display: 'flex',
    alignItems: 'center',
  },
  recentSearchSectionSeparator: {
    margin: '0 8px',
    color: black2,
    fontSize: '13px',
    fontWeight: bold,
  },
  tooltip: {
    color: '#fff',
    fontSize: '12px',

    '& span': {
      fontWeight: bold,
    },
  },
  pagination: {
    margin: '20px',
  },
};

export default styles;
