import React, { memo, useMemo } from 'react';

import { BasePaginateProps, Nullable } from '../../types';
import { ActionsMenu } from '../ActionsMenu';
import { IconButton } from '../IconButton';
import { Layout } from '../Layout';
import { LoadingSpinner } from '../LoadingSpinner';
import { NumberInput } from '../NumberInput';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import {
  bounded,
  MIN_PAGE_INDEX,
  renderPageDisplay,
  testHasValidProps,
} from './utils';

interface Props extends BasePaginateProps {
  /** Paginator name */
  name: string;
  /** Disables going to a specific page index but still supports back/next navigation */
  disableGoTo?: boolean;
  /** Customize tooltips */
  getTooltips?: ({
    pageIndex,
    totalCount,
  }: {
    pageIndex: number;
    totalCount: number;
  }) => Partial<{
    first: string;
    last: string;
    next: string;
    previous: string;
  }>;
  /** If Paginate is loading, the tooltip to display */
  loadingTooltip?: string;
  /** Configures supported paginate modes */
  mode?: 'count';
}

const PAGE_INDEX_START = 1; // Paginate uses 1-based indexing

export const Paginate = memo(
  ({
    disabled,
    disableGoTo,
    isLoading,
    getTooltips,
    loadingTooltip,
    mode,
    name,
    pageIndex,
    pageSize = 10,
    totalCount,
    onUpdatePageIndex,
  }: Props) => {
    if (pageIndex < MIN_PAGE_INDEX) {
      throw new Error(`Page index is ${MIN_PAGE_INDEX}-based`);
    }

    const pageCount = Math.ceil(totalCount / pageSize);

    const isCountMode = mode === 'count';
    const lastPageIndex = isCountMode ? totalCount : pageCount;
    const disableFirst = disabled || pageIndex <= MIN_PAGE_INDEX;
    const disableLast = disabled || pageIndex >= lastPageIndex;

    const tooltips = getTooltips?.({ pageIndex, totalCount }) ?? {};

    const handleFirstPage = () => onUpdatePageIndex(PAGE_INDEX_START);

    const handleLastPage = () => onUpdatePageIndex(lastPageIndex);

    const handlePreviousPage = () => {
      const updatedPageIndex = pageIndex - 1;
      onUpdatePageIndex(
        isCountMode && updatedPageIndex === 0 ? totalCount : updatedPageIndex,
      );
    };

    const handleNextPage = () => {
      const updatedPageIndex = pageIndex + 1;
      onUpdatePageIndex(
        isCountMode && updatedPageIndex > totalCount
          ? PAGE_INDEX_START
          : updatedPageIndex,
      );
    };

    const handleSetPage = (updatedPageIndex: Nullable<number>) => {
      onUpdatePageIndex(updatedPageIndex ?? PAGE_INDEX_START);
    };

    const hasValidProps = testHasValidProps({
      pageIndex,
      pageSize,
      totalCount,
    });
    const canPaginate = isCountMode || (hasValidProps && totalCount > pageSize);

    const pageOptions = useMemo(
      () =>
        Array.from(
          { length: Number.isInteger(pageCount) ? pageCount : 0 },
          (_, i) => {
            const pageIndex: number = i + 1;
            return {
              value: pageIndex,
              label: renderPageDisplay({ pageIndex, pageSize, totalCount }),
            };
          },
        ),
      [pageCount],
    );

    let summary;
    if (disableGoTo) {
      summary = (
        <Text flex="none" variant="body-medium">
          {pageIndex} of {bounded(totalCount)}
        </Text>
      );
    } else {
      summary = isCountMode ? (
        <Layout align="center" spacing={2}>
          <NumberInput
            disabled={disabled}
            max={totalCount}
            min={PAGE_INDEX_START}
            mode="digits"
            name={`set ${name} page`}
            value={pageIndex}
            onChange={handleSetPage}
          />
          <Text flex="none">{` of ${bounded(totalCount)}`}</Text>
        </Layout>
      ) : (
        <ActionsMenu
          enablePortal
          disabled={disabled}
          actions={pageOptions}
          icon="chevron-down"
          name={name}
          size="s"
          text={`${renderPageDisplay({
            pageIndex,
            pageSize,
            totalCount,
          })} of ${bounded(totalCount)}`}
          tooltip="Go to page"
          onActionClick={onUpdatePageIndex}
        />
      );
    }

    return (
      <Layout align="center" flex="none">
        {summary}
        {canPaginate && (
          <Layout preset="icons">
            <IconButton
              disabled={disableFirst}
              icon="double-arrow-left"
              size="s"
              tooltip={tooltips.first ?? 'First'}
              onClick={handleFirstPage}
            />
            <IconButton
              disabled={disableFirst}
              icon="chevron-left"
              size="s"
              tooltip={tooltips.previous ?? 'Previous'}
              onClick={handlePreviousPage}
            />
            <IconButton
              disabled={disableLast}
              icon="chevron-right"
              size="s"
              tooltip={tooltips.next ?? 'Next'}
              onClick={handleNextPage}
            />
            <IconButton
              disabled={disableLast}
              icon="double-arrow-right"
              size="s"
              tooltip={tooltips.last ?? 'Last'}
              onClick={handleLastPage}
            />
            {isLoading && (
              <Tooltip tooltip={loadingTooltip}>
                <LoadingSpinner />
              </Tooltip>
            )}
          </Layout>
        )}
      </Layout>
    );
  },
);
