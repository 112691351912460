import { formatDate } from '~/eds';
import { CreateFieldData, DataField, DataFieldType } from '~/features/fields';
import { pilot } from '~/services';
import { Nullable, PilotId } from '~/types';

interface Request {
  fieldData: CreateFieldData;
}

interface Params {
  allow_multiple: boolean;
  allow_new_options: boolean;
  default: Nullable<string>;
  doc_restriction: 'INCLUDE' | 'EXCLUDE';
  doc_types: string[];
  field_type: DataFieldType;
  help_text: string;
  name: string;
  options: string[];
  section_id: PilotId;
  show_on_upload_form: boolean;
  sort_options: boolean;
}

interface Response {
  id: PilotId;
  field_type: DataFieldType;
  label: string;
  is_smart: boolean;
}

export const createField = async (request: Request): Promise<DataField> => {
  const params = mapRequestToParams(request);

  const response: Response = await pilot.post(
    '/document-information-fields/',
    params,
  );

  return {
    id: response.id,
    label: response.label,
    type: response.field_type,
    isSmart: response.is_smart,
  };
};

const mapRequestToParams = (request: Request): Params => {
  const {
    defaultValue = null,
    docRestriction,
    docTypes,
    fieldConfig,
    fieldName,
    fieldSectionId,
    fieldType,
    helpText = '',
    showOnUploadForm,
  } = request.fieldData;

  const {
    enableMultiple = false,
    optionsConfig,
    optionValues = [],
  } = fieldConfig;

  return {
    allow_multiple: enableMultiple,
    allow_new_options: optionsConfig.includes('enable_add'),
    default: serializeDefaultValue(defaultValue),
    doc_restriction: docRestriction === 'INCLUDE' ? 'INCLUDE' : 'EXCLUDE',
    doc_types: docTypes,
    help_text: helpText,
    options: optionValues,
    show_on_upload_form: showOnUploadForm,
    sort_options: optionsConfig.includes('enable_sort'),
    // never nullable from upstream
    field_type: fieldType!,
    name: fieldName!,
    section_id: fieldSectionId!,
  };
};

const serializeDefaultValue = (
  defaultValue: Nullable<CreateFieldData['defaultValue']>,
): Nullable<string> => {
  if (defaultValue === null) {
    return null;
  } else if (defaultValue instanceof Date) {
    return formatDate(defaultValue);
  } else if (Array.isArray(defaultValue)) {
    return defaultValue[0];
  } else {
    return defaultValue.toString();
  }
};
