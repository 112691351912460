export default `
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" aria-hidden="true" aria-label="evisort-ai">
<g clip-path="url(#clip0_1728_27960)">
<path d="M2.65643 17.6874C4.94465 19.9493 7.23288 22.2112 9.5226 24.4745C10.2635 25.2069 11.003 25.9378 11.7439 26.6702C12.9654 27.8776 14.7373 28.0806 16.0629 26.8732C17.7159 25.3701 19.2528 23.7201 20.8402 22.1509C22.8398 20.1743 24.8394 18.1977 26.8405 16.2197C26.9253 16.1358 27.0101 16.052 27.0934 15.9697C27.1276 15.9388 27.1619 15.9064 27.1946 15.8696C27.2497 15.8152 27.2958 15.7579 27.3359 15.7005C28.4116 14.3519 28.1156 12.7239 26.9149 11.5385C26.1368 10.7694 25.3601 10.0017 24.582 9.23254C22.3012 6.97801 20.019 4.72201 17.7382 2.46748C17.1862 1.92186 16.6491 1.35124 16.0778 0.826217C14.7879 -0.357669 13.0249 -0.229721 11.8019 0.957106C9.94365 2.7572 8.12109 4.59553 6.28069 6.41474C4.49087 8.18395 2.66833 9.92522 0.909761 11.7238C-0.283449 12.943 -0.310229 14.6858 0.872566 15.9255C1.44983 16.53 2.06429 17.1035 2.65792 17.6903L2.65643 17.6874ZM14.0053 5.54411C14.5677 5.53823 15.1316 5.89266 15.1212 6.60887C15.1688 9.98846 17.9033 12.6915 21.3223 12.7386C22.761 12.7577 22.758 14.9255 21.3223 14.9446C17.9033 14.9917 15.1688 17.6947 15.1212 21.0743C15.1018 22.4965 12.9088 22.4935 12.8895 21.0743C12.8419 17.6947 10.1073 14.9917 6.68836 14.9446C5.24967 14.924 5.25264 12.7577 6.68836 12.7386C10.1073 12.6915 12.8419 9.98846 12.8895 6.60887C12.8791 5.89119 13.4414 5.53676 14.0053 5.54411Z" fill="url(#paint0_linear_1728_27960)"/>
</g>
<defs>
<linearGradient id="paint0_linear_1728_27960" x1="-0.000446081" y1="13.8416" x2="28.0004" y2="13.8416" gradientUnits="userSpaceOnUse">
<stop stop-color="#4481FF"/>
<stop offset="1" stop-color="#6E44FF"/>
</linearGradient>
<clipPath id="clip0_1728_27960">
<rect width="28" height="28" rx="14" fill="white"/>
</clipPath>
</defs>
</svg>
`;
