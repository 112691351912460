import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { types as edsTypes, Markdown, Modal, useToast } from '~/eds';
import { api, slices } from '~/redux';

import { useResolveTargetEntityDetails } from '../hooks';
import { resolveModalProps } from '../utils';
import { ConfirmResumePublishModal } from './ConfirmResumePublishModal';

export const ModelModal = () => {
  const dispatch = useDispatch();

  const { toast } = useToast();

  const [
    deleteFieldModel,
    { isLoading: isDeletingModel },
  ] = api.endpoints.deletePromptModel.useMutation();

  const [
    suspendFieldModel,
    { isLoading: isSuspendingModel },
  ] = api.endpoints.suspendPromptModel.useMutation();

  const modelAction = useSelector(slices.xRay.selectors.selectModelAction);

  const isLoading = isDeletingModel || isSuspendingModel;

  const [
    targetEntityDetails,
    isResolvingTargetEntity,
  ] = useResolveTargetEntityDetails(modelAction?.model.entity ?? null);

  const [modalActionProps, setModalActionProps] = useState<{
    description: string;
    toastMessage: string;
    variant: edsTypes.ButtonVariant;
  }>({ description: '', toastMessage: '', variant: 'primary' });

  useEffect(() => {
    if (isResolvingTargetEntity || !modelAction) {
      return;
    }
    if (targetEntityDetails.label && modelAction.action !== 'resume') {
      const resolvedModalProps = resolveModalProps(
        modelAction.action,
        targetEntityDetails,
      );
      setModalActionProps(resolvedModalProps);
    }
  }, [targetEntityDetails, modelAction]);

  if (modelAction === null) {
    return null;
  }

  const { model, action } = modelAction;
  const isResumeAction = action === 'resume';

  const handleHide = () => dispatch(slices.xRay.actions.setModelAction(null));

  const handleModelActionConfirm = () => {
    if (isResumeAction) return;

    const queries = {
      delete: deleteFieldModel,
      suspend: suspendFieldModel,
    };

    const query = queries[action];

    query({
      modelId: model.id,
      version: model.version,
    })
      ?.unwrap()
      .then(() => {
        toast({
          message: modalActionProps.toastMessage,
          status: 'success',
        });
      })
      .catch(() =>
        toast({
          message: `Failed to ${action} **${targetEntityDetails.label}**. Please try again.`,
          status: 'danger',
        }),
      )
      .finally(handleHide);
  };

  const modalProps = {
    children: <Markdown text={modalActionProps.description} />,
    disableHideOnEscape: true,
    isVisible: true,
    primaryAction: {
      isLoading,
      text: 'Confirm',
      variant: modalActionProps.variant,
      onClick: handleModelActionConfirm,
    },
    title: `${capitalize(action)} ${capitalize(
      targetEntityDetails.type,
    )} Model`,
    onHide: handleHide,
  };

  return isResumeAction ? (
    <ConfirmResumePublishModal
      modelId={model.id}
      version={model.version}
      targetEntityDetails={targetEntityDetails}
    />
  ) : (
    <Modal {...modalProps} />
  );
};
