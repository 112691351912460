import { useSelector } from 'react-redux';

import { selectors } from '~/redux';
import { calcPercentage } from '~/utils';

export const useCreditStatusMessage = () => {
  const creditsSummary = useSelector(selectors.selectFieldAiCreditsSummary);

  const creditsUsed = creditsSummary
    ? calcPercentage(
        creditsSummary.totalCredits - creditsSummary.credits,
        creditsSummary.totalCredits,
      )
    : 0;

  if (creditsSummary) {
    return creditsSummary.credits <= 0
      ? {
          message:
            'Contact Evisort to purchase additional credits. You may be invoiced for the negative credit balance at the Credit overage rate.',
          status: 'danger' as const,
          title: 'You have used up all of your available credits.',
        }
      : creditsUsed && creditsUsed >= 90
      ? {
          message: 'Contact Evisort to purchase additional credits.',
          status: 'danger' as const,
          title: 'You used more than 90% of your available credits.',
        }
      : undefined;
  }
};
