import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Icon, Text } from '~/ui';

function Pill({ bg, icon, text, color = 'white' }) {
  return (
    <FlexLayout
      alignItems="center"
      bg={bg}
      px={2}
      py={1}
      space={2}
      sx={{ borderRadius: '80px', width: 'fit-content' }}
    >
      <Icon color={color} icon={icon} size="s" />
      <Text color={color} variant="2xs-dense-bold">
        {text}
      </Text>
    </FlexLayout>
  );
}

Pill.propTypes = {
  bg: PT.string.isRequired,
  icon: PT.string.isRequired,
  text: PT.string.isRequired,
  color: PT.string,
};

export default Pill;
