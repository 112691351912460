import React, { memo } from 'react';

import { ActionsMenu, Box, Layout } from '../../../components';
import { Cell } from '../Cell';

export const RowActionCell = memo(({ row }) => {
  const isHeader = !Boolean(row);

  return (
    <Cell
      align="right"
      as={isHeader ? 'th' : 'td'}
      flex="none"
      isPadded={false}
      stickyRight={0}
      style={{
        marginLeft: 'auto', // https://www.w3.org/TR/css-flexbox-1/#auto-margins
      }}
      render={() => {
        return isHeader ? (
          <Box px={4} />
        ) : (
          <Layout align="center">
            <ActionsMenu
              actions={row.actions}
              enablePortal
              id={row.actionsMenuId}
              name={`More actions for row ${row.index + 1}.`}
              size="s"
            />
          </Layout>
        );
      }}
    />
  );
});
