import { camelizeKeys, decamelizeKeys } from 'humps';

import { pilot } from '~/services';

const documentMetadataImportBaseUrl = 'document/metadata/import';

export const importDocumentMetadataListExcelFile = (formData) =>
  pilot.post(`${documentMetadataImportBaseUrl}/summary/`, formData);

export const getDocumentMetadataImportJobsData = async ({
  clientId,
  importId,
}) => {
  const params = decamelizeKeys({ clientId, importId });
  const result = await pilot.get(`${documentMetadataImportBaseUrl}/status/`, {
    params,
  });

  return camelizeKeys(result);
};

export const cancelDocumentMetadataImportJob = async ({
  clientId,
  importId,
}) => {
  const params = decamelizeKeys({ clientId });
  return pilot.patch(
    `${documentMetadataImportBaseUrl}/${importId}/cancel-request/`,
    params,
  );
};

export const downloadDocumentsMetadataImportResultsExcelFile = async ({
  clientId,
  importId,
  resultType,
}) => {
  const params = decamelizeKeys({ clientId, resultType });
  return pilot.get(`${documentMetadataImportBaseUrl}/${importId}/results/`, {
    params,
    responseType: 'blob',
  });
};

export const setDocumentMetadataImportJobAcknowledge = async ({
  clientId,
  importId,
}) => {
  const params = decamelizeKeys({ clientId });
  return pilot.patch(
    `${documentMetadataImportBaseUrl}/${importId}/acknowledge/`,
    params,
  );
};

export const startImportDocumentMetadataListProcessing = (params) => {
  params = decamelizeKeys(params);
  return pilot.post(`${documentMetadataImportBaseUrl}/`, params);
};
