import React, { memo } from 'react';

import { DateText, Text } from '~/eds';
import { PilotId } from '~/types';

import { User } from '../User';

interface Props {
  /** User activity string */
  activity: string;
  /** JS Date */
  date: Date;
  /** User ID */
  id: PilotId;
}

export const UserActivity = memo(({ activity, id, date }: Props) => {
  return (
    <Text>
      {activity} at <DateText date={date} format="full" /> by{' '}
      <User id={id} mode="name" />
    </Text>
  );
});
