import React from 'react';
import { CountryRegionData } from 'react-country-region-selector';

import { SharedSelectProps } from '../../../types';
import { Select } from '../Select';

type CountryName = string;

interface Props<M> extends SharedSelectProps<CountryName, M> {
  /** Countries that options should appear first in the select */
  priorityCountries?: CountryName[];
}

const defaultPriorityCountries = ['United States', 'Canada', 'United Kingdom'];

export const CountrySelect = <M extends boolean>({
  placeholder = 'Select Country...',
  priorityCountries = defaultPriorityCountries,
  value,
  onChange,
  ...rest
}: Props<M>) => {
  /* the CountryRegionData is an array of arrays with [['country_name', 'country_short_name', 'regions'], ...]
  the regions format is 'region_name~region_short_name|region_name~region_short_name' e.g 'Alabama~AL|Alaska~AK'
  */
  const allCountries = CountryRegionData.map((item) => item[0]);
  const filteredList = allCountries.filter(
    (country) => !priorityCountries.includes(country),
  );
  const priorityCountriesOptions = priorityCountries.map((item) => {
    return {
      label: item,
      value: item,
    };
  });
  const filteredCountryList = filteredList.map((country) => {
    return {
      label: country,
      value: country,
    };
  });
  const countryOptions = [...priorityCountriesOptions, ...filteredCountryList];
  return (
    <Select
      options={countryOptions}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};
