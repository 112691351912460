import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { Icon } from '~/eds';

import { evisortBlue } from '../../../assets/shared-styles/general';
import EcTooltip from '../../Shared/EcTooltip';
import PinIcon from '../../Shared/Icons/PinIcon';
import {
  searchWordsContainOptions,
  searchWordsScopeOptions,
} from '../TextSearchForm/TextSearchForm.utils';
import styles from './AnalyzerFilterCategory.styles';

class AnalyzerFilterCategory extends Component {
  constructor(props) {
    super(props);

    this.handleOnCategoryClick = this.handleOnCategoryClick.bind(this);

    this.state = { expanded: props.category.expanded || false };
  }

  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) {
      onRef(this, this.props.category.title);
    }
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) {
      onRef(undefined, this.props.category.title);
    }
  }

  toggleCategory(expand) {
    this.setState({ expanded: expand });
  }

  handleOnCategoryClick() {
    const {
      category: { entityType },
      handleAddNewEntity,
    } = this.props;

    if (entityType !== 'bool_text_search' && entityType !== 'folder') {
      this.setState((prevState) => ({ expanded: !prevState.expanded }));
    } else {
      const newEntity = this.generateEntityObject(entityType);
      handleAddNewEntity(entityType, newEntity);
    }
  }

  handleOnPinClick(fieldId) {
    this.props.handleFilterOptionsChange(fieldId);
  }

  generateEntityObject(type, filter) {
    switch (type) {
      case 'provision':
        return {
          entity: 'provision',
          provision: filter,
          contains: searchWordsContainOptions[0],
          text_search: [],
        };
      case 'text_search':
        return {
          entity: 'text_search',
          area: filter,
          contains: searchWordsContainOptions[0],
          scope: searchWordsScopeOptions[0],
          text: '',
        };
      case 'bool_text_search':
        return {
          entity: 'bool_text_search',
          query: '',
        };
      case 'folder':
        return {
          entity: 'folder',
          folder_ids: [],
        };
      default:
        return filter;
    }
  }

  handleOnFilterClick(filter) {
    const {
      category: { entityType },
      handleAddNewEntity,
    } = this.props;

    const newEntity = this.generateEntityObject(entityType, filter);
    handleAddNewEntity(entityType || 'field', newEntity);
  }

  renderTooltip(tooltipId, pinned) {
    return <EcTooltip id={tooltipId}>{pinned ? 'Unpin' : 'Pin'}</EcTooltip>;
  }

  renderCategoryFields() {
    const { classes, category, noPin } = this.props;

    return (
      <div className={classes.categoryFields}>
        {category.fields.map((field) => {
          const [color, opacity] = field.is_pinned
            ? [evisortBlue, '1']
            : ['#000', '0.25'];
          const dataForValue = `pin-icon_${field.id}`;

          const filterName = field.label || field;

          return (
            <div
              className={classes.categoryField}
              key={`category-field-${filterName}`}
            >
              <button onClick={() => this.handleOnFilterClick(field)}>
                {filterName}
              </button>
              {!noPin && (
                <button
                  className={classes.categoryFieldPinButton}
                  onClick={() => this.handleOnPinClick(field.id)}
                >
                  <span data-tip data-for={dataForValue}>
                    <PinIcon size="20" color={color} opacity={opacity} />
                  </span>
                  {this.renderTooltip(dataForValue, field.is_pinned)}
                  <span className="screenReaderText">pin {filterName}</span>
                </button>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { classes, category } = this.props;
    const { expanded } = this.state;

    return (
      <div
        id={`analyzer-filter-${category.title.replace(/\s/gi, '-')}`}
        className={classes.filterCategory}
      >
        <button
          aria-expanded={expanded}
          className={
            expanded
              ? classes.filterCategoryTitleWrapperExpanded
              : classes.filterCategoryTitleWrapper
          }
          onClick={this.handleOnCategoryClick}
        >
          <span>{category.title}</span>
          {!!category.fields.length &&
            (expanded ? (
              <Icon icon="chevron-up" />
            ) : (
              <Icon icon="chevron-down" />
            ))}
        </button>
        {expanded && this.renderCategoryFields()}
      </div>
    );
  }
}

AnalyzerFilterCategory.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string,
    fields: PropTypes.array,
    entityType: PropTypes.string,
  }),
  onRef: PropTypes.func,
  handleFilterOptionsChange: PropTypes.func,
};

export default injectSheet(styles)(AnalyzerFilterCategory);
