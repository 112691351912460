import PropTypes from 'prop-types';
import React from 'react';

const DocumentTypePdfIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path fill="#C50707" d="M4 2h12l4 4v16H4z" />
        <text
          fill="#FFF"
          fontFamily="SFMono-Heavy, SF Mono, Arial"
          fontSize="6"
          fontWeight="600"
        >
          <tspan x="6.437" y="19">
            PDF
          </tspan>
        </text>
        <path fill="#FFF" d="M7 6h9v1H7zM7 8h9v1H7zM7 10h6v1H7z" />
      </g>
    </svg>
  );
};

DocumentTypePdfIcon.defaultProps = {
  size: '24',
};

DocumentTypePdfIcon.propTypes = {
  size: PropTypes.string,
};

export default DocumentTypePdfIcon;
