/**
 * Returns a JS file from a full Axios response
 * @param {Axios.Response} response axios full response (including headers)
 * @returns {File} JS file
 */
export function toFile(response, options = {}) {
  const { defaultFilename = 'file', defaultMimeType = 'text/html' } = options;
  const { data, headers } = response;
  let filename = defaultFilename;

  // TODO: use a more robust library (e.g. `content-disposition`) to parse key-value pair and content type.  For now, we just have to softly agree with backend that the format of content-type and content-disposition is kept to a consistent interface
  const contentType = headers['content-type'];
  const contentDisposition = headers['content-disposition'];

  const type = contentType ? contentType.split(';')[0] : defaultMimeType;

  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename=(.*)/);
    if (filenameMatch) {
      filename = filenameMatch[1].replace(/^"+|"+$/g, '');
    }
  }

  return new File([data], filename, { type });
}
