import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSegment } from '~/components/SegmentAnalytics';
import EcModal from '~/components/Shared/EcModal';
import { DEFAULT_ACCEPTED_FILES } from '~/constants/files';
import {
  ContentContainer,
  FileInput,
  Files,
  FolderInput,
  Layout,
  Panel,
  useToast,
} from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { api, coerceRtkqError } from '~/redux';
import { slices } from '~/redux/slices';
import { Nullable } from '~/types';
import { MODAL_FOLDER_TREE } from '~/types/modal.types';
import { getClientInfo, getUserClientInfo } from '~/utils/user';

import { FormFields } from './FormFields';
import { FormFieldConfig, Location, UploadField } from './types';

export const UploadDocumentsPanel = ({
  onRemoveFile,
}: {
  onRemoveFile: (removedFile: File, removedFileIndex?: number) => void;
}) => {
  const dispatch = useDispatch();
  const { selectedFiles, configuration } = useSelector(
    slices.upload.selectors.selectUploadConfiguration,
  );

  const { enableSettings, fields } = configuration;

  const { toast } = useToast();
  const user = useCurrentUser();

  //TODO: deprecare SearchFolderPanel flag if set to true by default in LD
  const hasSearchFolderPanel = useFlag(FlagType.SearchFolderPanel);

  const [locationModal, setLocationModal] = useState<string | null>(null);

  const [_hasUploadPermission, setHasUploadPermission] = useState(null);

  const [uploadLocation, setUploadLocation] = useState<Nullable<Location>>(
    null,
  );

  const formFields: FormFieldConfig[] = user.client_config.upload_form_fields.map(
    ({ allow_new_options, ...field }) => ({
      ...field,
      allowNewOptions: allow_new_options,
    }),
  );

  const {
    data: folderTree,
    isFetching: isLoadingFolderTree,
    error: folderTreeError,
  } = api.endpoints.getFolderTree.useQuery(undefined);

  const [accessFolder] = api.endpoints.getFolderVisibility.useLazyQuery();
  const [uploadFiles] = api.endpoints.addUploadFiles.useMutation();

  const handleOnHide = () => {
    setUploadLocation(null);
    dispatch(slices.upload.actions.resetFileConfiguration());
  };

  const handleLocationChange = (location: Location) => {
    const folderId = location.id;
    accessFolder({ folderId }).then((res: any) => {
      if (res.error) {
        toast({
          message: res.error.message,
          status: 'danger',
        });
      } else {
        setUploadLocation(location);
        setHasUploadPermission(res.data.canModifyFolder);
      }
    });
  };

  const handleOnChange = (formData: UploadField) => {
    const { fieldId, value } = formData;

    const fieldIndex = fields.findIndex((field) => field.fieldId === fieldId);

    if ((Array.isArray(value) && value.length === 0) || !value) {
      dispatch(
        slices.upload.actions.setFields(
          fields.filter((_, index) => index !== fieldIndex),
        ),
      );
    } else if (fieldIndex === -1) {
      dispatch(
        slices.upload.actions.setFields([...fields, { fieldId, value }]),
      );
    } else {
      dispatch(
        slices.upload.actions.setFields(
          fields.map((item, index) =>
            index === fieldIndex ? { fieldId, value } : item,
          ),
        ),
      );
    }
  };

  const locationName = uploadLocation
    ? uploadLocation.name
    : folderTree?.name ?? '';

  const folderId = uploadLocation
    ? uploadLocation.id
    : folderTree?.id
    ? folderTree.id
    : '2';

  const extendedFiles =
    selectedFiles && selectedFiles.length > 0
      ? selectedFiles.map((file) => {
          //Remove this 2, but confirm how to prevent foldertree from undefined?
          // @ts-ignore folderId is not a property of File
          file.folderId = uploadLocation
            ? uploadLocation.id
            : folderTree?.id
            ? folderTree.id
            : '2';
          return file;
        })
      : [];

  const children = (
    <>
      {enableSettings ? (
        <Layout preset="sections">
          {!folderTreeError && (
            <FolderInput
              folder={{
                name: locationName,
                path: uploadLocation?.path ?? '',
                isConcise: true,
              }}
              onChange={() => setLocationModal(MODAL_FOLDER_TREE)}
              isLoading={isLoadingFolderTree}
            />
          )}
          <ContentContainer
            placeholderContent={
              !user.client_config.upload_form_fields
                ? { message: 'FormFields are not available' }
                : undefined
            }
          >
            <FormFields
              formFields={formFields}
              fields={fields}
              onChange={handleOnChange}
            />
          </ContentContainer>
        </Layout>
      ) : (
        <Layout preset="sections">
          <FileInput
            accept={DEFAULT_ACCEPTED_FILES}
            isMulti={true}
            name="Upload Files Dropzone"
            onChange={(uploadedFiles) => {
              dispatch(
                slices.upload.actions.setSelectedFiles(
                  uploadedFiles
                    ? [
                        ...uploadedFiles,
                        ...uploadedFiles.filter(
                          (file) =>
                            !selectedFiles.some((f) => f.name === file.name),
                        ),
                      ]
                    : [],
                ),
              );
            }}
            placeholder="Drag document(s) here to upload them."
            value={null}
          />
          <Files
            files={selectedFiles}
            onRemoveFile={onRemoveFile}
            title="Upload Files"
          />
        </Layout>
      )}
      {locationModal === MODAL_FOLDER_TREE && (
        <EcModal
          modalType={MODAL_FOLDER_TREE}
          width="540px"
          title="Choose Location"
          uploadLocation={uploadLocation}
          uploadLocationPath={uploadLocation?.path ? uploadLocation.path : []}
          folderIdsSelected={[]}
          handleUploadLocationChange={handleLocationChange}
          confirmButtonText="Choose Location"
          hideModal={() => {
            setLocationModal(null);
          }}
          enableSearch={hasSearchFolderPanel}
        />
      )}
    </>
  );
  const hasPermissionToUpload = false;

  const disableTooltip = !enableSettings
    ? 'Please select next to add your folder location'
    : !hasPermissionToUpload
    ? ' Please select a folder you have access to.'
    : undefined;

  return (
    <Panel
      width="m"
      children={children}
      position="right"
      title="Upload"
      hidden={{
        placement: 'left',
        onHide: handleOnHide,
      }}
      footer={{
        actions: [
          {
            text: 'Cancel',
            onClick: handleOnHide,
          },
          {
            text: 'Next',
            onClick: () => {
              dispatch(
                slices.upload.actions.setConfiguration({
                  ...configuration,
                  enableSettings: true,
                }),
              );
            },
            disabled: enableSettings,
          },
          {
            text: 'Upload',
            disabled: Boolean(disableTooltip),
            tooltip: disableTooltip,
            onClick: () => {
              trackSegment('User Uploads Document', {
                fileNames: selectedFiles.map((file) => file.name),
                groupId: getClientInfo(user),
                userId: getUserClientInfo(user),
              });
              uploadFiles({
                files: extendedFiles,
                folderId: String(folderId),
                uploadFormFields: fields,
              }).then((res) => {
                if ('error' in res) {
                  toast({
                    message: coerceRtkqError(res.error)?.message,
                    status: 'danger',
                  });
                } else {
                  toast({
                    message: 'Files uploaded successfully',
                    status: 'success',
                  });
                }
              });
              handleOnHide();
            },
            level: 'primary',
          },
        ],
      }}
    />
  );
};
