export enum TicketActivityType {
  AddParticipant = 'add participant',
  AttachmentUpload = 'attachment upload',
  CreateEnvelope = 'create envelope',
  CancelEnvelope = 'cancel envelope',
  CancelTicket = 'cancel ticket',
  CollectSignatures = 'collect signatures',
  CollectSignaturesCancelled = 'collect signatures cancelled',
  Comment = 'comment',
  ConvertedDocument = 'converted from pdf file',
  CoordinatorReassignment = 'coordinator reassignment',
  CreateTicket = 'create ticket',
  DeclineEnvelope = 'decline envelope',
  DeleteVersion = 'delete version',
  DocumentEdit = 'document edit',
  DocumentSave = 'document save',
  DocumentSigned = 'document signed',
  DocumentUpload = 'document upload',
  DownloadDocument = 'download document',
  Esignature = 'esignature',
  IntakeFormEdit = 'intake form edit',
  JudgmentResultAssignment = 'judgment result assignment',
  JudgmentReminderSent = 'judgment reminder sent',
  JudgmentResult = 'judgment result',
  JudgmentResultReassignment = 'judgment result reassignment',
  JudgmentResultStatusUpdate = 'judgment result status update',
  SignerReassignment = 'signer reassignment',
  NextStage = 'next stage',
  DownloadRedline = 'oodocs download redline',
  DownloadVersion = 'oodocs download version',
  PreviousStage = 'previous stage',
  RemoveParticipant = 'remove participant',
  RenameTicket = 'rename ticket',
  ResetApprovals = 'reset approvals',
  ShareDocument = 'share document',
  SignatureUpload = 'signature upload',
  StageChangeArchive = 'stage change archive',
  StageChangeArchiveUser = 'stage change archive user',
  TicketCompletion = 'ticket completion',
  VersionBumpForEditing = 'version bump for editing',
  InboundAttachmentAccepted = 'inbound attachment accepted',
  InboundAttachmentRejected = 'inbound attachment rejected',
  TurnTrackEdit = 'edit turn',
}
