import PT from 'prop-types';
import React from 'react';

import { Button, Layout, Popover } from '~/eds';
import { Box, Text } from '~/ui';

export default function AddParticipantToTicketPopover({
  isVisible,
  participant,
  onClose,
  onSubmit,
}) {
  const onAddParticipantClick = async () => {
    await onSubmit(participant);
  };

  return (
    <Popover trigger="" isVisible={isVisible}>
      <Layout
        w={250}
        p={2}
        data-testid="addParticipantPopover"
        direction="column"
        spacing={4}
      >
        {participant && (
          <Box>
            <Text isBold>{participant.name}</Text>
            <Text> is not currently a participant in this ticket.</Text>
          </Box>
        )}
        <Button
          isFullWidth
          text="Add as participant"
          onClick={onAddParticipantClick}
          variant="primary"
          label="Add as participant"
        />
        <Button
          data-testid="addParticipantPopover_cancel"
          isFullWidth
          text="Cancel"
          label="Cancel"
          variant="ghost"
          onClick={onClose}
        />
      </Layout>
    </Popover>
  );
}

AddParticipantToTicketPopover.propTypes = {
  isVisible: PT.bool,
  participant: PT.shape({
    id: PT.number,
    name: PT.string,
  }),
  onClose: PT.func,
  onSubmit: PT.func,
  reference: PT.object,
  referenceOffset: PT.arrayOf(PT.number),
};
