import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import injectSheet from 'react-jss';

import styles from './EcFab.styles';

const EcFab = forwardRef((props, ref) => {
  const { classes, icon, id, onClick, onKeyDown, tabIndex, ...rest } = props;

  return (
    <button
      {...rest}
      className={classes.ecFabButton}
      id={id}
      ref={ref}
      {...(onClick && { onClick })}
      {...(onKeyDown && { onKeyDown })}
      tabIndex={tabIndex}
    >
      {icon}
    </button>
  );
});

EcFab.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.element,
  id: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.number,
};

export default injectSheet(styles)(EcFab);
