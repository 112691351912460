import React from 'react';

import { Cell as TableCell } from '../Cell';

function Cell({ cell }) {
  const { column, getCellProps, row } = cell;
  const {
    align,
    cellType,
    getDisabledCell = () => false,
    isPrimary,
    isSelected,
    mapCellProps = (x) => x,
    renderCell,
    totalLeft,
    width,
  } = column;

  const handleShowDetails = row.onShowDetails
    ? () => row.onShowDetails(row.original)
    : undefined;

  return (
    <TableCell
      align={align}
      cellProps={mapCellProps(row.original)}
      disabled={getDisabledCell(row.original)}
      flex={width === 'auto' ? 'auto' : 'none'}
      isActive={isSelected}
      isPrimary={isPrimary}
      stickyLeft={isPrimary ? totalLeft : undefined}
      style={getCellProps().style}
      type={cellType}
      render={renderCell}
      width={width}
      onShowDetails={handleShowDetails}
    />
  );
}

export default Cell;
