import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { workflowFieldUnassign } from '~/actions/workflow';
import Field from '~/components/Workflow/shared/Field';
import Links from '~/components/Workflow/shared/Links';
import { getFieldLinksByFieldId } from '~/reducers/workflow';
import { FlexLayout, PageLayout, Text, useModal } from '~/ui';
import { testDocumentField, testUnassignedField } from '~/utils/workflow';

import FieldAssignmentMenu from '../FieldAssignmentMenu';

const { SidebarSectionItem } = PageLayout;

function FieldSectionItem({
  field,
  indent = 0,
  isAssigned = false,
  // connected,
  disabled,
  links,
  workflowFieldUnassign,
  errorMessage,
}) {
  const fieldId = field.id;

  const isDocumentField = testDocumentField(field);

  const [deleteCustomFieldModal, showDeleteCustomFieldModal] = useModal({
    actionButton: {
      text: 'Delete',
      variant: 'red',
      errorHandler: () => {},
      promise: async () => workflowFieldUnassign({ fieldId }),
    },
    content: (
      <FlexLayout flexDirection="column" space={6}>
        {links.length > 0 && (
          <FlexLayout
            bg="red-600"
            color="white"
            flexDirection="column"
            p={6}
            space={2}
          >
            <Text variant="m-dense-bold">
              This field is linked to an object
            </Text>
            <Text variant="s-spaced">
              Deleting the field will have consequences for the objects listed
              below.
            </Text>
          </FlexLayout>
        )}
        <Links links={links} />
      </FlexLayout>
    ),
    disableAutoHide: true,
    title: `Delete ${field.name}`,
  });

  function handleUnassign() {
    if (!isDocumentField && links.length > 0) {
      showDeleteCustomFieldModal();
    } else {
      workflowFieldUnassign({ fieldId });
    }
  }

  return (
    <SidebarSectionItem
      borderLeftIcon={disabled ? null : isDocumentField ? 'unlink' : 'trash'}
      indent={indent}
      onClickBorderLeftIcon={
        testUnassignedField(field) ? undefined : handleUnassign
      }
    >
      {disabled ? (
        <Field
          disabled={disabled}
          errorMessage={errorMessage}
          field={field}
          isAssigned={isAssigned}
        />
      ) : (
        <FieldAssignmentMenu
          fieldId={fieldId}
          trigger={
            <Field
              disabled={disabled}
              errorMessage={errorMessage}
              field={field}
              isAssigned={isAssigned}
            />
          }
        />
      )}
      {deleteCustomFieldModal}
    </SidebarSectionItem>
  );
}

FieldSectionItem.propTypes = {
  field: PT.object.isRequired,
  indent: PT.number,
  isAssigned: PT.bool,
  errorMessage: PT.string,
};

function mapStateToProps({ builder, workflow }, { field }) {
  return {
    disabled: !builder.shouldEnableAllComponents,
    links: getFieldLinksByFieldId(workflow, field.id),
  };
}

export default connect(mapStateToProps, { workflowFieldUnassign })(
  FieldSectionItem,
);
