import React from 'react';

import { Chip, CountChip } from '../Chip';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { MarkedText } from '../MarkedText';
import { Text } from '../Text';
import { Item, PaddingMode } from './types';

interface Props<D> {
  bg?: string;
  borderRadius?: 'm';
  isVisible: boolean;
  onClick: (event: MouseEvent) => void;
  item: Item<D>;
  minH?: string;
  togglePosition?: 'left' | 'right';
  padding?: PaddingMode;
  search?: string;
}

export const AccordionItemHeader = <D,>({
  bg,
  borderRadius,
  isVisible,
  item,
  minH = 'header.height',
  togglePosition = 'left',
  padding,
  onClick,
  search,
}: Props<D>) => {
  const {
    chip,
    count,
    hash,
    headerBorder = 'none',
    headerClass,
    headerContentAlignment = 'center',
    isLoading,
    summary,
    title,
  } = item;

  return (
    <Layout
      as="summary"
      align="center"
      aria-controls={`${title}-panel`}
      aria-expanded={isVisible}
      className={headerClass}
      disabled={isLoading}
      minH={minH}
      id={hash || `${title}-header`}
      justify="space-between"
      px={padding === 'all' ? 4 : 0}
      py={headerContentAlignment === 'start' ? 2 : 0}
      role="button"
      spacing={2}
      styles={componentStyles.summary({
        enableBottomBorder: isVisible && headerBorder === 'bottom',
      })}
      onClick={onClick}
      bg={bg}
      borderRadius={borderRadius}
    >
      <Layout align={headerContentAlignment} flex="auto" spacing={2}>
        {togglePosition === 'left' && (
          <Icon icon={isVisible ? 'chevron-down' : 'chevron-right'} />
        )}
        {summary || (
          <Text variant="body-bold">
            <MarkedText search={search} text={title} />
          </Text>
        )}
        {chip && <Chip {...chip} />}
      </Layout>
      <Layout align={headerContentAlignment} flex="none" spacing={2}>
        {isLoading ? (
          <Icon icon="loading" />
        ) : (
          count && <CountChip count={count} />
        )}
      </Layout>
      {togglePosition === 'right' && (
        <Icon icon={isVisible ? 'chevron-up' : 'chevron-down'} />
      )}
    </Layout>
  );
};

const componentStyles = {
  summary: ({ enableBottomBorder }: { enableBottomBorder: boolean }) => ({
    '::-webkit-details-marker': {
      display: 'none',
    },
    borderBottom: enableBottomBorder ? 'border.divider' : 'none',
  }),
};
