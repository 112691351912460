import React from 'react';

import { toSnakeTrimmed } from '~/utils/strings';

import ActionsMenu from '../ActionsMenu';

export default function SelectDeselectAllMenu({ name, onChange }) {
  const menuName = name + '_select menu';
  const id = toSnakeTrimmed(menuName);
  function getSelectAllMenuItems() {
    const items = [];

    items.push(
      {
        content: 'Select All',
        onClick: () => onChange(true),
      },
      {
        content: 'Deselect All',
        onClick: () => onChange(false),
      },
    );

    return items;
  }

  return (
    <ActionsMenu
      aria-label={`${name} select menu`}
      title={`${name} select menu`}
      id={id}
      smallIcon
      icon="chevronDown"
      items={getSelectAllMenuItems()}
    />
  );
}
