const styles = {
  ecFabButton: (props) => ({
    width: '40px',
    height: '40px',
    boxShadow: props.clicked
      ? 'inset 0 0 4px 0 rgba(0, 0, 0, 0.2)'
      : '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: props.clicked ? '#e8e8e8' : '#ffffff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  }),
};

export default styles;
