/** Preferred method for sorting strings in the platform, as it is
 * case-insensitive, and takes into account special characters and punctuation.
 * For full list of sorting rules, see 'src/utils/sortStrings.test.ts'.
 *
 * Usage:
 *
 * `mySortedArray = myUnsortedArrayOfStrings.sort(sortStrings)`
 *
 * OR, pass into `sortType` prop of EDS Table `column`, e.g.
 *
 * `myTextColumn = { ...myColumnProps, sortType: (rowA, rowB, columnId) => sortStrings(rowA.values[columnId], rowB.values[columnId])` }; */
export const sortStrings = (a: string, b: string) => {
  return new Intl.Collator('en-US').compare(a, b);
};
