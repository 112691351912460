import { Action, UserAction } from '../types';

// util to transform the deprecated `Action[]` interface to `UserAction[]`
export const DEPRECATED_actionsToUserActions = (
  actions: Action[],
  mode?: 'icon',
): UserAction[] =>
  actions.map((action) => ({
    ...action,
    mode,
    text: action.label,
    onClick: action.onClick
      ? () => action.onClick?.(action.value as any)
      : undefined,
  }));
