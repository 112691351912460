import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Pill from '~/components/Workflow/shared/Pill';
import {
  getFieldColor,
  getFieldTypeIcon,
} from '~/components/Workflow/Workflow.utils';

function FieldPill({
  // connected
  field,
  isUnassigned,
}) {
  let bg = 'red-600';
  let icon = 'warning';
  let color = 'white';
  let text;

  if (field) {
    if (isUnassigned) {
      text = 'Unassigned';
    } else {
      bg = getFieldColor(field);
      color = field.placeholder ? 'white' : 'black';
      text = field.name;
      icon = getFieldTypeIcon(field);
    }
  } else {
    text = 'Field is Missing';
  }

  return <Pill bg={bg} color={color} icon={icon} text={text} />;
}

const fieldPropTypes = PT.shape({
  description: PT.string,
  id: PT.string,
  isAssigned: PT.bool,
  isDocumentRelated: PT.bool,
  name: PT.string,
  type: PT.string,
});

FieldPill.propTypes = {
  field: fieldPropTypes,
};

const mapStateToProps = ({ workflow }, { fieldId }) => ({
  field: workflow.fields[fieldId],
  isUnassigned: !workflow.fieldLinks[fieldId],
});

export default connect(mapStateToProps)(FieldPill);
