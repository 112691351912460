import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';

import { FormField, Label, Layout, Select, Text, TextInput } from '~/eds';
import { actions } from '~/redux';
import { Alert } from '~/ui';
import { htmlToText } from '~/utils/strings';

import { CKEditor } from '../../Shared/CKEditor4';
import {
  CLAUSE_LIBRARY_NAME_CHAR_LIMIT,
  GUIDANCE_NOTES_CHAR_LIMIT,
  VARIATION_TEXT_CHAR_LIMIT,
} from '../constants';
import {
  State as ClauseLibraryState,
  VariationForm as VariationFormInterface,
} from '../types';

const editorConfig = {
  editorplaceholder: 'Clause Text or Guidance is required.',
  toolbar: [
    { name: 'editing', items: ['Undo', 'Redo'] },
    {
      name: 'text',
      items: ['Format', 'Bold', 'Italic', 'Underline', 'Strike'],
    },
    { name: 'link', items: ['Link', 'Unlink'] },
    {
      name: 'format',
      items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
    },
    {
      name: 'paragraph',
      items: ['BulletedList', 'NumberedList', 'BidiLtr', 'BidiRtl'],
    },
  ],
  toolbarGroups: [
    { name: 'group1', groups: ['editing'] },
    { name: 'group2', groups: ['text'] },
    { name: 'group3', groups: ['link'] },
    { name: 'group4', groups: ['format'] },
    { name: 'group5', groups: ['paragraph'] },
  ],
};

const VariationForm = ({
  variationForm,
  setClauseVariationForm,
  setClauseLibraryVariationGuidanceNotes,
  setClauseLibraryVariationClauseText,
  resetClauseLibraryVariationEditorsValidationErrors,
  resetClauseLibraryVariationGuidanceError,
  resetClauseLibraryVariationTextError,
}: {
  variationForm: VariationFormInterface;
  setClauseVariationForm: Dispatch<VariationFormInterface>;
  setClauseLibraryVariationGuidanceNotes: Dispatch<string>;
  setClauseLibraryVariationClauseText: Dispatch<string>;
  resetClauseLibraryVariationEditorsValidationErrors: Dispatch<void>;
  resetClauseLibraryVariationGuidanceError: Dispatch<void>;
  resetClauseLibraryVariationTextError: Dispatch<void>;
}) => {
  const positionOptions = [
    {
      label: 'Preferred',
      value: 'Preferred',
    },
    {
      label: 'Fallback',
      value: 'Fallback',
    },
    {
      label: 'Walk away',
      value: 'Walk away',
    },
  ];

  const [variationName, setVariationName] = useState(variationForm.name || '');
  const [guidanceNotes, setGuidanceNotes] = useState(
    variationForm.guidanceNotes || '',
  );
  const [clauseText, setClauseText] = useState(variationForm.clauseText || '');
  const [position, setPosition] = useState(variationForm.position || undefined);

  const variationNameFooter = `${
    variationName.trim().length
  }/${CLAUSE_LIBRARY_NAME_CHAR_LIMIT}`;
  const variationNameOverLimit =
    variationName.trim().length > CLAUSE_LIBRARY_NAME_CHAR_LIMIT;
  const variationNameAPIErrors = variationForm.errors?.filter(
    (error) => error.source?.pointer === '/nickname',
  );
  const getVariationNameError = () => {
    if (variationNameOverLimit)
      return `${variationNameFooter} (Name cannot be longer than ${CLAUSE_LIBRARY_NAME_CHAR_LIMIT} characters)`;
    if (variationNameAPIErrors?.length)
      return variationNameAPIErrors.map((error) => error.detail).join(' ');
    return undefined;
  };

  // must come from validation errors because they shouldn't display until submission
  const positionMissing = variationForm.validationErrors?.find(
    (message) => message === 'position missing',
  );
  const positionAPIErrors = variationForm.errors?.filter(
    (error) => error.source?.pointer === '/position',
  );
  const getPositionError = () => {
    if (positionMissing) return 'This is a required field ';
    if (positionAPIErrors?.length)
      return positionAPIErrors.map((error) => error.detail).join(' ');
    return undefined;
  };

  const guidanceNotesText = htmlToText(guidanceNotes).trim();
  const guidanceNotesFooter = `${guidanceNotesText.length}/${GUIDANCE_NOTES_CHAR_LIMIT}`;
  const guidanceNotesOverLimit =
    guidanceNotesText.length > GUIDANCE_NOTES_CHAR_LIMIT;
  // must come from validation errors because they shouldn't display until submission
  const guidanceNotesMissing = variationForm.validationErrors?.find(
    (message) => message === 'text missing',
  );
  const guidanceNotesAPIErrors = variationForm.errors?.filter(
    (error) => error.source?.pointer === '/guidance',
  );
  const getGuidanceNotesError = () => {
    if (guidanceNotesOverLimit)
      return `${guidanceNotesFooter} (Guidance notes cannot be longer than ${GUIDANCE_NOTES_CHAR_LIMIT} characters)`;
    if (guidanceNotesMissing)
      return 'Clause Text or Guidance must be filled in. Both cannot be empty.';
    if (guidanceNotesAPIErrors?.length)
      return guidanceNotesAPIErrors.map((error) => error.detail).join(' ');
    return undefined;
  };

  const clauseRawText = htmlToText(clauseText).trim();
  const clauseTextFooter = `${clauseRawText.length}/${VARIATION_TEXT_CHAR_LIMIT}`;
  const clauseTextOverLimit = clauseRawText.length > VARIATION_TEXT_CHAR_LIMIT;
  // must come from validation errors because they shouldn't display until submission
  const clauseTextMissing = variationForm.validationErrors?.find(
    (message) => message === 'text missing',
  );
  const clauseTextAPIErrors = variationForm.errors?.filter(
    (error) => error.source?.pointer === '/text',
  );
  const getClauseTextError = () => {
    if (clauseTextOverLimit)
      return `${clauseTextFooter} (Text cannot be longer than ${VARIATION_TEXT_CHAR_LIMIT} characters)`;
    if (clauseTextMissing)
      return 'Clause Text or Guidance must be filled in. Both cannot be empty.';
    if (clauseTextAPIErrors?.length)
      return clauseTextAPIErrors.map((error) => error.detail).join(' ');
    return undefined;
  };

  function handleVariationNameUpdate(name: string) {
    setVariationName(name);
    const errors = variationForm.errors?.filter(
      (error) => error.source?.pointer && error.source.pointer !== '/nickname',
    );
    setClauseVariationForm({ ...variationForm, name, errors });
  }

  // TODO: update type
  function handlePositionUpdate(position: any) {
    setPosition(position);
    const errors = variationForm.errors?.filter(
      (error) => error.source?.pointer && error.source.pointer !== '/position',
    );
    const validationErrors = variationForm.validationErrors?.filter(
      (error) => error !== 'position missing',
    );
    setClauseVariationForm({
      ...variationForm,
      position,
      validationErrors,
      errors,
    });
  }

  function handleGuidanceNotesUpdate(editorInput: any) {
    const guidanceNotes = editorInput.getData();
    setGuidanceNotes(guidanceNotes);
    setClauseLibraryVariationGuidanceNotes(guidanceNotes);
    resetClauseLibraryVariationGuidanceError();
    resetClauseLibraryVariationEditorsValidationErrors();
  }

  function handleClauseTextUpdate(editorInput: any) {
    const clauseText = editorInput.getData();
    setClauseText(clauseText);
    setClauseLibraryVariationClauseText(clauseText);
    resetClauseLibraryVariationEditorsValidationErrors();
    resetClauseLibraryVariationTextError();
  }

  function handleDismissErrorAlert() {
    setClauseVariationForm({ ...variationForm, errors: undefined });
  }

  const VARIATION_FORM_POINTERS = [
    '/nickname',
    '/guidance',
    '/text',
    '/position',
  ];

  return (
    <Layout direction="column" spacing={8} w="100%">
      {!!variationForm?.errors &&
        variationForm.errors.map((error) => {
          if (
            error.source?.pointer &&
            VARIATION_FORM_POINTERS.includes(error.source.pointer)
          )
            return <></>;
          return (
            <Alert
              key="warning"
              enableIcon
              variant="danger"
              onDismiss={handleDismissErrorAlert}
            >
              <Layout direction="column" spacing={2}>
                <Text variant="body">{error.detail}</Text>
              </Layout>
            </Alert>
          );
        })}
      <Layout w="100%">
        <Layout w="80%" direction="column" pr={4}>
          <FormField
            name="clause-library-variation-name"
            label="Clause variation Name"
            input={TextInput}
            value={variationName}
            placeholder={variationForm.namePlaceholder}
            // @ts-ignore
            onChange={handleVariationNameUpdate}
            footer={variationNameFooter}
            error={getVariationNameError()}
          />
        </Layout>
        <Layout direction="column" spacing={2}>
          <Label>
            Position<Text color="status.danger">*</Text>
          </Label>
          <Layout direction="column" spacing={1}>
            <Select
              isMulti={false}
              options={positionOptions}
              onChange={handlePositionUpdate}
              name="position"
              value={position}
              error={getPositionError()}
              enableErrorMessage
            />
          </Layout>
        </Layout>
      </Layout>
      <Layout direction="column" spacing={4} aria-labelledby="guidance-notes">
        <Label id="guidance-notes">Guidance Notes</Label>
        <Layout direction="column" spacing={2}>
          <CKEditor
            data={guidanceNotes}
            config={editorConfig}
            onChange={handleGuidanceNotesUpdate}
          />
          {getGuidanceNotesError() ? (
            <Text color="status.danger" variant="tiny" role="note">
              {getGuidanceNotesError()}
            </Text>
          ) : (
            <Text color="text.secondary" variant="tiny" role="note">
              {guidanceNotesFooter}
            </Text>
          )}
        </Layout>
      </Layout>
      <Layout direction="column" spacing={4} aria-labelledby="clause-text">
        <Label id="clause-text">Clause text</Label>
        <Layout direction="column" spacing={2}>
          <CKEditor
            data={clauseText}
            config={editorConfig}
            onChange={handleClauseTextUpdate}
          />
          {getClauseTextError() ? (
            <Text color="status.danger" variant="tiny" role="note">
              {getClauseTextError()}
            </Text>
          ) : (
            <Text color="text.secondary" variant="tiny" role="note">
              {clauseTextFooter}
            </Text>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({
  clauseLibrary,
}: {
  clauseLibrary: ClauseLibraryState;
}) => ({
  variationForm: clauseLibrary.variationForm,
});

export default connect(mapStateToProps, {
  setClauseVariationForm: actions.setClauseLibraryVariationForm,
  setClauseLibraryVariationGuidanceNotes:
    actions.setClauseLibraryVariationGuidanceNotes,
  setClauseLibraryVariationClauseText:
    actions.setClauseLibraryVariationClauseText,
  resetClauseLibraryVariationEditorsValidationErrors:
    actions.resetClauseLibraryVariationEditorsValidationErrors,
  resetClauseLibraryVariationGuidanceError:
    actions.resetClauseLibraryVariationGuidanceError,
  resetClauseLibraryVariationTextError:
    actions.resetClauseLibraryVariationTextError,
})(VariationForm);
