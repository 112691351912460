import React, { Component, Fragment } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { trackSegment } from '~/components/SegmentAnalytics';
import DocumentStatusEditButton from '~/components/Shared/DocumentStatusEditButton';
import { EM_DASH } from '~/constants/unicode';
import { Button, formatDate, Layout, LoadingShimmer, parseDate } from '~/eds';
import { HttpStatusCodeType } from '~/enums';
import { FlagType, withFlags } from '~/flags';
import { withPermissions } from '~/hocs';
import documentGroup from '~/redux/slices/documentGroup';
import { withRouting } from '~/routing';
import { uploadDocuments } from '~/slices/fileUpload';
import { FlexLayout, Text } from '~/ui';
import {
  featureFlagIncluded,
  getClientInfo,
  getUserClientInfo,
  testIsSuperAdmin,
} from '~/utils/user';

import { black3 } from '../../../assets/shared-styles/general';
import { MODAL_SIMPLE } from '../../../types/modal.types';
import { ERROR, SUCCESS, WARNING } from '../../../types/toast.types';
import {
  algorithmStatusHelper,
  copyToClipboard,
} from '../../../utils/helper.utils';
import EcBreadcrumbs from '../../Shared/EcBreadcrumbs';
import EcModal from '../../Shared/EcModal';
import { showToast } from '../../Shared/EcToast';
import withHover from '../../Shared/HOCs/withHover';
import BackIcon from '../../Shared/Icons/BackIcon';
import CopyToClipboardIcon from '../../Shared/Icons/CopyToClipboardIcon';
import ProjectIcon from '../../Shared/Icons/ProjectIcon';
import {
  getDocumentPath,
  getDocumentStatus,
  getGroupForDocument,
  updateDocumentStatus,
} from '../Document.data';
import styles from './DocumentViewHeader.styles';
import DownloadMenuButton from './DownloadMenuButton';
import EditDocumentMenu from './EditDocumentMenu';
import { testHasNonEmptyItems } from './utils';

const BackIconWithHover = withHover(BackIcon);
const docGroupViewPermission = {
  resourceId: 'document_groups',
  resourceType: 'view',
};
const conversationalAiPermission = {
  resourceId: 'conversational_ai',
  resourceType: 'edit',
};
const permissionsToCheck = [docGroupViewPermission, conversationalAiPermission];

class DocumentViewHeader extends Component {
  constructor(props) {
    super(props);

    this.handleDocStatusChange = this.handleDocStatusChange.bind(this);
    this.handleOnPartiesShowAllClick = this.handleOnPartiesShowAllClick.bind(
      this,
    );
    this.navigateBack = this.navigateBack.bind(this);
    this.getDocStatus = this.getDocStatus.bind(this);

    this.state = {
      currentModal: null,
      fullDocumentPath: null,
      showAllParties: false,
    };
  }

  handleShowModal = (modal) => {
    this.setState({ currentModal: modal });
  };

  handleHideModal = () => {
    this.setState({ currentModal: null });
  };

  componentDidMount() {
    this.getDocStatus();
  }

  componentDidUpdate(prevProps) {
    const { hasCheckedGroupPermission } = this.state;
    const hasDocGroupViewPermission = this.props.checkPermission(
      docGroupViewPermission.resourceId,
      docGroupViewPermission.resourceType,
    );
    if (this.props.documentData !== prevProps.documentData) {
      getDocumentPath(this.props.documentData.id).then((path) =>
        this.setState({ fullDocumentPath: path }),
      );
    } else if (
      !hasCheckedGroupPermission &&
      hasDocGroupViewPermission &&
      this.props.documentData &&
      this.props.documentData.id
    ) {
      this.setState({ hasCheckedGroupPermission: true });
      this.loadDocumentGroup();
    }
  }
  loadDocumentGroup = async () => {
    const { documentData, setGroup } = this.props;
    try {
      this.setState({ isLoadingDocumentGroup: true });
      const documentGroupRes = await getGroupForDocument(
        documentData.id,
        false,
      );

      const [documentGroup] = documentGroupRes.results;
      if (documentGroup) {
        setGroup(documentGroup);
      }

      this.setState({
        groups: documentGroupRes.results ? documentGroupRes.results : [],
      });
    } catch (error) {
      this.handleShowToast(ERROR, 'Error fetching document group information.');
    } finally {
      this.setState({ isLoadingDocumentGroup: false });
    }
  };

  getDocStatus() {
    const { documentId } = this.props;

    getDocumentStatus(documentId)
      .then((res) => {
        this.setState({ documentStatus: res.doc_status });
      })
      .catch((error) => {
        // No need to handle 404 since it's handled upstream.
        if (error.response.status !== HttpStatusCodeType.NotFound) {
          this.handleShowToast(
            ERROR,
            'An error occurred while fetching document status.',
          );
        }
      });
  }

  handleDocStatusChange(status) {
    const { documentId } = this.props;

    updateDocumentStatus(documentId, status)
      .then((res) => {
        this.setState({ doc_status: res.doc_status });
        this.handleShowToast(SUCCESS, 'Document status has been updated.');
        this.getDocStatus();
      })
      .catch(() => {
        this.handleShowToast(
          WARNING,
          'An error occurred while updating the document status.',
        );
        this.getDocStatus();
      });
  }

  handleShowToast(type, text) {
    showToast(type, text);
  }

  handleOnPartiesShowAllClick() {
    this.setState({ showAllParties: true });
  }

  navigateBack() {
    const fromLocation = this.props.documentsNavigation?.context.fromLocation;
    if (fromLocation) {
      this.props.navigate(fromLocation.pathname + fromLocation.search);
    } else {
      this.props.navigate(-1);
    }
  }

  handleAddUploadFiles(files, uploadCb) {
    const { currentUser, uploadDocuments } = this.props;
    const {
      fullDocumentPath: { id },
    } = this.state;
    const uploadFiles = Object.keys(files).map((key) => {
      const file = files[key];
      file.uploadCb = uploadCb;
      return file;
    });

    trackSegment('User Uploads Document', {
      fileNames: uploadFiles.map((file) => file.name),
      groupId: getClientInfo(currentUser),
      userId: getUserClientInfo(currentUser),
    });
    uploadDocuments({
      files: uploadFiles,
      apiConfig: {
        service: 'pilot',
        method: 'post',
        url: `/version/document/${id}/`,
      },
    });
    setTimeout(() => {
      if (uploadFiles.length && uploadFiles[0].status === 'success') {
        this.props.refreshDocumentVersions();
      }
    }, 5000);
  }

  renderProject() {
    const { classes, documentData } = this.props;

    return documentData.project ? (
      <div className={classes.documentMetadataItem}>
        <div className={classes.metadataHeader}>PROJECT</div>
        <div className={classes.metadataContent}>
          <ProjectIcon />
          <span>{documentData.project}</span>
        </div>
      </div>
    ) : null;
  }

  renderGroup = () => {
    const { classes } = this.props;
    const { groups, isLoadingDocumentGroup } = this.state;
    if (isLoadingDocumentGroup) return <LoadingShimmer />;
    return groups && groups.length ? (
      <div className={classes.documentGroupContainer}>
        <span>{groups[0].name}</span>
      </div>
    ) : (
      EM_DASH
    );
  };

  renderContractTypes(items) {
    const { classes } = this.props;
    if (testHasNonEmptyItems(items)) {
      return items
        .map((party) => <span key={party}>{party}</span>)
        .reduce((prev, curr) => [
          prev,
          <span className={classes.metadataSeparator} key={curr} />,
          curr,
        ]);
    } else {
      return EM_DASH;
    }
  }

  renderParties(items) {
    const { classes } = this.props;
    const { showAllParties } = this.state;

    if (testHasNonEmptyItems(items)) {
      if (!showAllParties && items.length > 2) {
        return (
          <Fragment>
            <span>{items[0]}</span>
            <span className={classes.metadataSeparator} />
            <span>{items[1]}</span>
            <span className={classes.metadataSeparator} />
            <button
              className={classes.partiesShowAll}
              onClick={this.handleOnPartiesShowAllClick}
            >
              Show All Parties
            </button>
          </Fragment>
        );
      } else {
        return items
          .map((party) => <span key={party}>{party}</span>)
          .reduce((prev, curr) => [
            prev,
            <span className={classes.metadataSeparator} key={curr} />,
            curr,
          ]);
      }
    } else {
      return EM_DASH;
    }
  }

  renderAlgoStatus() {
    const {
      classes,
      documentData: { algo_status },
    } = this.props;
    const algoList = algorithmStatusHelper(algo_status);

    return algoList
      ? algoList.map((item) => {
          let itemMessageLabel = EM_DASH;
          if (item.message.length) itemMessageLabel = item.message;

          return (
            <div key={item.name} className={classes.statusWrapper}>
              <div className={classes.statusContainer}>
                <span
                  className={
                    item.status === 1
                      ? classes.algoStatusGreenDot
                      : item.status === 0
                      ? classes.algoStatusYellowDot
                      : item.status === -2
                      ? classes.algoStatusBlueDot
                      : classes.algoStatusRedDot
                  }
                />
                <span className={classes.algoName}>{item.name}</span>
              </div>
              {item.status === 1 ? (
                <div className={classes.statusMessage}>
                  Message {itemMessageLabel}
                </div>
              ) : item.status === 0 ? (
                <div className={classes.statusMessage}>In Progress</div>
              ) : item.status === -2 ? (
                <div className={classes.statusMessage}>Partially Complete</div>
              ) : (
                <div className={classes.statusErrorMessage}>Failed</div>
              )}
            </div>
          );
        })
      : null;
  }

  renderHeaderActions() {
    const {
      classes,
      documentData,
      userVisibilityLevel,
      askAiButtonProps,
      currentUser,
      flags,
    } = this.props;
    const { documentStatus } = this.state;
    const {
      onClick: onAskAiClick,
      tooltip: askAiButtonTooltip,
      disabled: isAskAiButtonDisabled,
    } = askAiButtonProps;

    const documentDeleteReasonRequired = !!currentUser?.client_config
      ?.document_delete_reason_required;
    const isHideDocumentStatusEnabled = flags[FlagType.HideDocumentStatus];
    const canEdit = userVisibilityLevel === 'OPEN';
    const canDownload = !!canEdit || userVisibilityLevel === 'OPEN_NO_EDIT';
    const algoList = algorithmStatusHelper(documentData.algo_status);
    const indicatorPercentageGenerator = (condition) => {
      return algoList
        ? (algoList.filter((item) => item.status === condition).length /
            algoList.length) *
            100
        : 0;
    };

    const greenIndicatorWidth = indicatorPercentageGenerator(1);
    const yellowIndicatorWidth = indicatorPercentageGenerator(0);
    const redIndicatorWidth = indicatorPercentageGenerator(-1);
    const blueIndicatorWidth = indicatorPercentageGenerator(-2);

    const componentConfig = { postUrl: 'no-url' };
    const djsConfig = {
      autoQueue: false,
      autoProcessQueue: false,
      clickable: '#documents_header_newVersionButton',
      dictDefaultMessage: '',
      maxFiles: 1,
      previewTemplate: '<div class="ignore"></div>',
    };
    const eventHandlers = {
      addedfiles: (files) =>
        setTimeout(() => {
          this.handleAddUploadFiles(files);
        }, 0),
    };

    const isSuperAdmin = testIsSuperAdmin(currentUser);

    const enableConversationalAI =
      flags[FlagType.ConversationalAIButtonOnDocPageHeader];
    const hasConversationalAIPermission = this.props.checkPermission(
      conversationalAiPermission.resourceId,
      conversationalAiPermission.resourceType,
    );

    const hasAskAnything = flags[FlagType.AskAnything];

    const shouldShowConversationalAiButton =
      enableConversationalAI &&
      hasConversationalAIPermission &&
      !hasAskAnything;

    return (
      <div className={classes.documentHeaderActions}>
        {isSuperAdmin && documentData.algo_status && (
          <>
            <button
              className={classes.algoStatusButton}
              onClick={() => this.handleShowModal(MODAL_SIMPLE)}
            >
              <span className={classes.algoStatusHeader}>Algorithm Status</span>
              <span className={classes.algoStatusIndicatorBarWrapper}>
                <span
                  className={classes.algoStatusIndicatorBarGreen}
                  style={{ width: greenIndicatorWidth + '%' }}
                />
                <span
                  className={classes.algoStatusIndicatorBarYellow}
                  style={{ width: yellowIndicatorWidth + '%' }}
                />
                <span
                  className={classes.algoStatusIndicatorBarBlue}
                  style={{ width: blueIndicatorWidth + '%' }}
                />
                <span
                  className={classes.algoStatusIndicatorBarRed}
                  style={{ width: redIndicatorWidth + '%' }}
                />
              </span>
            </button>
          </>
        )}
        <Layout spacing={2}>
          {canEdit && !isHideDocumentStatusEnabled ? (
            <DocumentStatusEditButton
              id={`document_header_editStatusField?document=${documentData.id}`}
              status={documentStatus}
              handleDocStatusChange={this.handleDocStatusChange}
            />
          ) : null}
          {shouldShowConversationalAiButton && (
            <Button
              icon="chatbot"
              iconPosition="left"
              variant="ai"
              text="Ask AI"
              tooltip={askAiButtonTooltip}
              onClick={onAskAiClick}
              disabled={isAskAiButtonDisabled}
            />
          )}
          {canDownload ? (
            <DownloadMenuButton documentData={documentData} />
          ) : null}

          {canEdit && featureFlagIncluded(currentUser, 'UPLOAD') ? (
            <DropzoneComponent
              className="new-document-version-dropzone"
              config={componentConfig}
              djsConfig={djsConfig}
              eventHandlers={eventHandlers}
            >
              <div>
                <Button
                  variant="primary"
                  id="documents_header_newVersionButton"
                  data-testid="documents_header_newVersionButton"
                  text="New Version"
                  icon="plus"
                  iconPosition="left"
                />
              </div>
            </DropzoneComponent>
          ) : null}

          {canEdit ? (
            <EditDocumentMenu
              documentData={documentData}
              hasDeleteWithReasonEnabled={documentDeleteReasonRequired}
              handleDocumentRename={this.props.handleDocumentRename}
              handleDocumentDelete={this.props.handleDocumentDelete}
            />
          ) : null}
        </Layout>
      </div>
    );
  }

  renderDocumentTitle() {
    const { classes } = this.props;
    const { fullDocumentPath } = this.state;

    return fullDocumentPath ? (
      <h2 className={classes.documentName}>{fullDocumentPath.document_name}</h2>
    ) : (
      <div className={classes.emptyDocumentName} />
    );
  }

  renderEmptyMetadata() {
    const { classes } = this.props;

    return (
      <div className={classes.documentHeaderMetadata}>
        {Array.from(Array(5).keys()).map((index) => (
          <div className={classes.documentMetadataItem} key={index}>
            <div className={classes.emptyMetadataHeader} />
            <div className={classes.emptyMetadataContent} />
          </div>
        ))}
      </div>
    );
  }

  renderMetadata() {
    const { classes, documentData, checkPermission } = this.props;
    const docId = documentData.id;

    return (
      <div className={classes.documentHeaderMetadata}>
        <div className={classes.documentMetadataItem}>
          <div className={classes.metadataHeader}>DOC ID</div>
          <FlexLayout
            alignItems="center"
            justifyContent="space-between"
            space={2}
          >
            <FlexLayout>
              <Text color="gray-900" variant="s-spaced">
                {docId}
              </Text>
            </FlexLayout>
            <button
              className={classes.borderlessBtn}
              title="copy document ID"
              onClick={() =>
                copyToClipboard(
                  docId,
                  'Document ID has been copied to your clipboard.',
                )
              }
            >
              <CopyToClipboardIcon size="20" color={black3} />
            </button>
          </FlexLayout>
        </div>
        {checkPermission(
          docGroupViewPermission.resourceId,
          docGroupViewPermission.resourceType,
        ) && (
          <div className={classes.documentMetadataItem}>
            <div className={classes.metadataHeader}>GROUP</div>
            <div className={classes.metadataContent}>
              {this.renderGroup(documentData.groups)}
            </div>
          </div>
        )}

        {documentData.hasOwnProperty('document_types') && (
          <div className={classes.documentMetadataItem}>
            <div className={classes.metadataHeader}>CONTRACT TYPE</div>
            <div className={classes.metadataContent}>
              {this.renderContractTypes(documentData.document_types)}
            </div>
          </div>
        )}
        {this.renderProject()}
        <div className={classes.documentMetadataItem}>
          <div className={classes.metadataHeader}>PARTIES</div>
          <div className={classes.metadataContent}>
            {this.renderParties(documentData.party_list)}
          </div>
        </div>
        <div className={classes.documentMetadataItem}>
          <div className={classes.metadataHeader}>UPLOAD DATE</div>
          <div className={classes.metadataContent}>
            <span>
              {formatDate(parseDate(documentData.date_added), 'full_datetime')}
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderAlgoStatusModal() {
    return (
      <EcModal
        modalType={MODAL_SIMPLE}
        title="Algorithm Status"
        width="560px"
        hideModal={this.handleHideModal}
        includeCancelButton={false}
        hideCloseIcon={true}
        modalButtons={<Button text="Close" onClick={this.handleHideModal} />}
      >
        {this.renderAlgoStatus()}
      </EcModal>
    );
  }

  componentWillUnmount() {
    const { setGroup } = this.props;
    setGroup(undefined);
  }

  render() {
    const { classes, loading } = this.props;
    const { currentModal } = this.state;

    const path =
      loading || !this.state.fullDocumentPath
        ? []
        : this.state.fullDocumentPath.path;

    return (
      <Fragment>
        <div className={classes.documentHeader}>
          <button
            className={classes.documentBackAction}
            onClick={this.navigateBack}
            title="Go back to previous page"
          >
            <BackIconWithHover />
          </button>
          <div className={classes.documentFullTitle}>
            <EcBreadcrumbs path={path} />
            {this.renderDocumentTitle()}
          </div>
          {loading ? null : this.renderHeaderActions()}
        </div>
        {loading ? this.renderEmptyMetadata() : this.renderMetadata()}
        {currentModal === MODAL_SIMPLE ? this.renderAlgoStatusModal() : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ currentUser, documentsNavigation }) => ({
  currentUser,
  documentsNavigation,
});

DocumentViewHeader = connect(mapStateToProps, {
  uploadDocuments,
  setGroup: documentGroup.actions.setGroup,
})(withRouting(DocumentViewHeader));

export default withPermissions(
  injectSheet(styles)(withFlags(DocumentViewHeader)),
  permissionsToCheck,
);
