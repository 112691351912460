import { Permission } from '~/types';

import { canUseWorkflow } from './canUseWorkflow';
import { checkIsWorkflowAdmin } from './checkIsWorkflowAdmin';
import { and } from './utils';

export const canUseWorkflowAdmin: Permission = and([
  canUseWorkflow, //
  checkIsWorkflowAdmin,
]);
