import React from 'react';

import { User } from '~/components/Shared/User';
import { Chip, Layout, types } from '~/eds';
import { TicketStageType } from '~/enums';
import { PilotId } from '~/types';

export const getUnassignedUserDisplay = (stage: TicketStageType) => {
  switch (stage) {
    case TicketStageType.Review:
      return 'Unassigned Reviewer';
    case TicketStageType.Sign:
      return 'Unassigned Signer';
    case TicketStageType.Finalize:
      return 'Unassigned Finalizer';
    default:
      return 'Unassigned User';
  }
};

export const unassignedCoordinator = 'Unassigned Coordinator';

interface Props {
  userId?: PilotId;
  externalUser: Pick<types.User, 'email' | 'firstName' | 'lastName' | 'id'>;
  currentStage: TicketStageType;
  isCoordinator?: boolean;
  isCounterpartySigner?: boolean;
}

export function UserInfo({
  isCounterpartySigner = false,
  userId,
  externalUser,
  currentStage,
  isCoordinator = false,
}: Props) {
  return (
    <Layout align="center" spacing={4}>
      <Layout align="center" spacing={2}>
        <User
          mode="info"
          id={userId}
          user={externalUser}
          placeholder={
            isCoordinator
              ? unassignedCoordinator
              : getUnassignedUserDisplay(currentStage)
          }
        />
        {isCounterpartySigner && <Chip text="Counterparty Signer" />}
      </Layout>
    </Layout>
  );
}
