export default `
<svg
  aria-hidden="true"
  aria-label="key"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.8828 12.1797L8.78906 15.2422L10.2578 16.7109C10.5703 17.0234 10.5703 17.4922 10.2578 17.7734C9.97656 18.0859 9.50781 18.0859 9.22656 17.7734L7.75781 16.3047L6.78906 17.2422L8.25781 18.7109C8.57031 19.0234 8.57031 19.4922 8.25781 19.7734C7.97656 20.0859 7.50781 20.0859 7.22656 19.7734L5.22656 17.7734C4.91406 17.4922 4.91406 17.0234 5.22656 16.7109L10.8203 11.1172C10.2891 10.3672 10.0078 9.49219 10.0078 8.49219C10.0078 6.02344 12.0078 3.99219 14.5078 3.99219C16.9766 3.99219 19.0078 6.02344 19.0078 8.49219C19.0078 10.9922 16.9766 12.9922 14.5078 12.9922C13.5078 12.9922 12.6328 12.7109 11.8828 12.1797ZM14.5078 11.4922C16.1641 11.4922 17.5078 10.1484 17.5078 8.49219C17.5078 6.83594 16.1641 5.49219 14.5078 5.49219C12.8203 5.49219 11.5078 6.83594 11.5078 8.49219C11.5078 10.1484 12.8203 11.4922 14.5078 11.4922Z"
    fill="currentcolor"
  />
</svg>
`;
