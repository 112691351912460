import React from 'react';

import { Text } from '~/eds';

interface Props {
  title: string;
}

// Note: this will be refactored and absorbed into eds.PageLayout when it is ready.
const PageTitle = ({ title }: Props) => {
  return (
    <Text as="h2" m={0} variant="title">
      {title}
    </Text>
  );
};

export default PageTitle;
