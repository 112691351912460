import React from 'react';

import { DateText } from '../../../components';
import { DateFormat } from '../../../types';

interface Props {
  /** JS Date */
  datetime?: Date;
  /** Date format */
  format?: DateFormat;
}

export const DatetimeCell = ({ datetime, format }: Props) => {
  return <DateText date={datetime} format={format} />;
};
