export function setDocumentTitle(title) {
  document.title = title ? `Evisort | ${title}` : 'Evisort';
}

/** A valid window.opener is that exists and is of SAME origin */
export function testIsValidWindowOpener() {
  try {
    return Boolean(window.opener?.location.href);
  } catch (e) {
    return false; // i.e. cross-origin error
  }
}
