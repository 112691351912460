import { jwtDecode } from 'jwt-decode';

export const getDecodedUserInfoFromLoginToken = (urlWithToken) => {
  const tokenChunk = urlWithToken.substr(urlWithToken.indexOf('=') + 1);
  const decodedToken = jwtDecode(tokenChunk);

  return {
    client: decodedToken?.identity?.client ?? '',
    client_id: decodedToken?.identity?.client_id ?? '',
    client_name: decodedToken?.identity?.client_name ?? '',
    email: decodedToken?.identity?.email ?? '',
    user_id: decodedToken?.identity?.user_id ?? '',
  };
};

export const getDecodedUserInfoFromToken = (accessToken) => {
  const decodedToken = jwtDecode(accessToken);

  return {
    client_id: decodedToken?.identity?.client_id ?? '',
    client_name: decodedToken?.identity?.client_name ?? '',
    user_id: decodedToken?.identity?.user_id ?? '',
  };
};

export const testIsValidBase64String = (string) => {
  if (!string) return false;
  return /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/.test(
    string,
  );
};
