import { createReducer } from '@reduxjs/toolkit';

import * as actions from '~/actions';

export default createReducer({}, (builder) => {
  builder.addCase(actions.commentsLoad, (state, action) => {
    const commentThreads = action.payload;
    commentThreads.forEach((commentThread) => {
      const { threadId, comments } = commentThread;
      comments.forEach((comment) => {
        state[comment.id] = {
          ...comment,
          threadId,
        };
      });
    });
  });
  builder.addCase(actions.commentsDelete, (state, action) => {
    const deletedComment = action.payload;
    delete state[deletedComment.id];
  });
  builder.addCase(actions.commentsCreate, (state, action) => {
    const createdComment = action.payload;
    state[createdComment.id] = createdComment;
  });
  builder.addCase(actions.commentsUpdate, (state, action) => {
    const updatedComment = action.payload;
    state[updatedComment.id] = updatedComment;
  });
});

export function getCommentsByThreadId(state, threadId) {
  return Object.values(state).filter(
    (comment) => comment.threadId === threadId,
  );
}
