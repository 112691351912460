import React, { useEffect, useState } from 'react';

import { Info, Layout, Text } from '~/eds';

interface Props {
  isExactTextMatch: boolean;
  onToggleIsExactTextMatch: (updatedIsExactTextMatch: boolean) => void;
}

export const EvaluateSettings = ({
  isExactTextMatch,
  onToggleIsExactTextMatch,
}: Props) => {
  const [isExactTextMatchEnabled, setIsExactTextMatchEnabled] = useState(
    isExactTextMatch,
  );

  const handleToggleIsExactTextMatch = (updatedIsExactTextMatch: boolean) => {
    setIsExactTextMatchEnabled(updatedIsExactTextMatch);
    onToggleIsExactTextMatch(updatedIsExactTextMatch);
  };

  // Keep state in sync with prop changes
  useEffect(() => {
    setIsExactTextMatchEnabled(isExactTextMatch);
  }, [isExactTextMatch]);

  const sections = [
    {
      title: '',
      infos: [
        {
          title: 'Answers match exactly',
          description:
            'Turning this on will cause the AI to only grade outputs as Correct if it is exactly the same as the Correct Answer you entered. By default, it checks if the meaning is the same as the Correct Answer.',
          type: 'content' as const,
          props: {
            children: null,
          },
          toggle: {
            enabled: isExactTextMatchEnabled,
            name: 'answers match exactly',
            onToggle: handleToggleIsExactTextMatch,
          },
        },
        {
          title: 'Exact Match',
          description: 'Checks for capitalization and punctuation',
          type: 'status-texts' as const,
          props: {
            statusTexts: [
              {
                status: 'success' as const,
                text: 'ABC Inc',
              },
              {
                status: 'danger' as const,
                text: 'abc inc',
              },
            ],
          },
        },
        {
          title: 'Meaning Match',
          description: 'Checks for meaning',
          type: 'status-texts' as const,
          props: {
            statusTexts: [
              {
                status: 'success' as const,
                text: 'This is an NDA.',
              },
              {
                status: 'success' as const,
                text: 'This is an Non-Disclosure Agreement ',
              },
            ],
          },
        },
      ],
    },
  ];

  return (
    <Layout direction="column" spacing={2}>
      <Text preset="help">Evaluation Settings</Text>
      <Info sections={sections} />
    </Layout>
  );
};
