import { useEffect } from 'react';

import { Nullable } from '~/types';

import { scrollToHighlight, usePdfHighlighterContext } from './bridge';
import { PDF_HIGHLIGHT_SCROLL_TOP_OFFSET } from './constants';
import { PdfHighlight } from './types';

export const useScrollToHighlight = ({
  activeHighlightId,
  pdfHighlights,
  deps = [],
}: {
  activeHighlightId?: string;
  pdfHighlights: Nullable<PdfHighlight>[];
  deps?: any[];
}) => {
  const { viewer } = usePdfHighlighterContext();

  useEffect(() => {
    const activePdfHighlight = pdfHighlights.find(
      (pdfHighlight) => pdfHighlight?.id === activeHighlightId,
    );

    if (activePdfHighlight) {
      scrollToHighlight(
        viewer,
        activePdfHighlight.position,
        PDF_HIGHLIGHT_SCROLL_TOP_OFFSET,
      );
    }
  }, [activeHighlightId, ...deps]);
};
