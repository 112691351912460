import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

// eslint-disable-line import/default
import { black4 } from '../../../assets/shared-styles/general';
import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import ReorderIcon from '../../Shared/Icons/ReorderIcon';
import styles from './ReorderSectionsModal.styles';

class ReorderSectionsModal extends Component {
  constructor(props) {
    super(props);

    this.handleActionClick = this.handleActionClick.bind(this);

    this.state = { sectionOrder: this.props.sectionOrder };
  }

  handleActionClick() {
    const { sectionOrder } = this.state;
    const { handleSaveChange, hideModal } = this.props;

    handleSaveChange(sectionOrder);
    hideModal();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ sectionOrder }) => ({
      sectionOrder: arrayMove(sectionOrder, oldIndex, newIndex),
    }));
  };

  renderSectionList() {
    const { classes } = this.props;
    const { sectionOrder } = this.state;

    const SortableItem = SortableElement(({ value }) => (
      <div
        className={classes.sectionRow}
        key={value.id}
        role="menuitem"
        tabIndex={0}
      >
        <ReorderIcon color={black4} />
        <span className={classes.sectionName}>{value.label}</span>
      </div>
    ));

    const SortableList = SortableContainer(() => {
      return (
        <div className={classes.sectionsContainer}>
          {sectionOrder.map((section, index) => (
            <SortableItem key={`item-${index}`} index={index} value={section} />
          ))}
        </div>
      );
    });

    return (
      <SortableList
        items={sectionOrder}
        onSortEnd={this.onSortEnd}
        helperClass={classes.sortableHelper}
      />
    );
  }

  render() {
    const { hideModal } = this.props;

    return (
      <EcModalCard
        title="Reorder Sections"
        content={
          <div>
            <div>Use the drag handles to reorder the list of sections.</div>
            {this.renderSectionList()}
          </div>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              yellow
              text="Save Order"
              onClick={this.handleActionClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

ReorderSectionsModal.propTypes = {
  handleSaveChange: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(ReorderSectionsModal);
