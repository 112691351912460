import React from 'react';

import { Icon, Layout, Link } from '../../../components';
import { DEFAULT_PLACEHOLDER_LABEL } from '../../../constants';
import { IconType } from '../../../types/';

interface Props {
  enableNewTab?: boolean;
  icon?: IconType;
  pathname: string;
  search?: string;
  shouldTruncate?: boolean;
  state?: object;
  text: string;
  onClick?: () => void;
}

export const LinkCell = ({
  shouldTruncate = true,
  icon,
  pathname,
  enableNewTab,
  text,
  state,
  search,
  onClick,
}: Props) => {
  return icon ? (
    <Layout spacing={2} direction="row" w="100%" minW="0">
      {text ? (
        <>
          <Icon icon={icon} />
          <Link
            shouldTruncate={shouldTruncate}
            enableNewTab={enableNewTab}
            pathname={pathname}
            onClick={onClick}
            search={search}
            state={state}
          >
            {text}
          </Link>
        </>
      ) : (
        DEFAULT_PLACEHOLDER_LABEL
      )}
    </Layout>
  ) : (
    <Link
      shouldTruncate={shouldTruncate}
      enableNewTab={enableNewTab}
      pathname={pathname}
      onClick={onClick}
      search={search}
      state={state}
    >
      {text}
    </Link>
  );
};
