// check if a document has valid OCR/Text content
import { isEqual, uniqWith } from 'lodash';
import pluralize from 'pluralize';

import { getHandlersKeyFromSearchResult } from '~/components/SearchV2/SearchResult/SearchResult.utils';
import { EN_DASH } from '~/constants/unicode';

export function hasOcrContent(doc) {
  return doc.file_type === '.pdf';
}

export function getLabelForDocumentCount(fileCount, docCount) {
  let label = EN_DASH;

  if (fileCount && docCount && docCount >= fileCount) {
    let duplicates = docCount - fileCount;

    label = `${fileCount} ${pluralize('document', fileCount)}`;
    if (duplicates > 0) {
      label += ` (${duplicates} ${pluralize('duplicate', duplicates)})`;
    }
  }

  return label;
}

export const testIsAnyDocumentNotMovable = (documents = []) => {
  return documents.some((doc) => !doc.is_movable);
};

export const testIsGhostSearchResultDocument = (doc = {}) => {
  return !getHandlersKeyFromSearchResult(doc).length;
};

export const testContainsGhostSearchResult = (documents = []) => {
  return documents.some(testIsGhostSearchResultDocument);
};

export const getUniqueFieldValues = (values = []) => {
  return values && values.length > 0 ? uniqWith(values, isEqual) : [];
};
