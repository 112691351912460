import React, { Dispatch } from 'react';
import { connect } from 'react-redux';

import { Box, Card, CollapsibleText, copy, Text, types } from '~/eds';
import { ClauseLibraryViewType } from '~/enums';
import { actions } from '~/redux';
import { VariationData as VariationDataType } from '~/redux/api/methods';
import { Uuid } from '~/types';
import { HtmlContent } from '~/ui';
import { htmlToText } from '~/utils/strings';

import { CLAUSE_LIBRARY_CHAR_DISPLAY_LIMIT } from '../constants';
import { VariationForm as VariationFormInterface } from '../types';

export const ClauseVariation = ({
  canEdit,
  variation,
  clauseId,
  handleDelete,
  setClauseLibraryView,
  setClauseLibraryEditMode,
  setClauseLibraryVariationForm,
}: {
  canEdit: boolean;
  variation: VariationDataType;
  clauseId: Uuid;
  handleDelete: (id: Uuid, name: string, clauseId: Uuid) => void;
  setClauseLibraryView: Dispatch<ClauseLibraryViewType>;
  setClauseLibraryEditMode: Dispatch<boolean>;
  setClauseLibraryVariationForm: Dispatch<VariationFormInterface>;
}) => {
  if (!variation) return <></>;

  const editClauseVariation = () => {
    setClauseLibraryVariationForm({
      clauseId,
      variationId: variation.key,
      name: variation.name,
      title: variation.name,
      position: variation.position,
      guidanceNotes: variation.guidance,
      clauseText: variation.text,
    });
    setClauseLibraryEditMode(true);
    setClauseLibraryView(ClauseLibraryViewType.VariationForm);
  };

  const cardActions: types.UserAction[] = [
    {
      mode: 'icon',
      level: 'tertiary',
      icon: 'clipboard',
      text: 'Copy',
      tooltip: 'Copy to Clipboard',
      onClick: () => copy(variation.text),
    },
  ];

  const moreCardActions = [
    {
      text: 'Edit',
      onClick: editClauseVariation,
    },
    {
      text: 'Delete',
      onClick: () => handleDelete(variation.key, variation.name, clauseId),
    },
  ];

  return (
    <Card
      key={variation.key}
      header={{
        actions: cardActions,
        chips: [
          {
            text: variation.position,
            status: variation.status,
          },
        ],
        id: variation.key,
        moreActions: canEdit ? moreCardActions : undefined,
        title: variation.name,
      }}
      mode="embedded"
      role="listitem"
    >
      {Boolean(variation.guidance?.length) && (
        <Box
          styles={componentStyles.wrap}
          bg="background.quiet"
          borderRadius="m"
          py={2}
          px={3}
          my={3}
          mx={6}
        >
          <CollapsibleText
            text={htmlToText(variation.guidance)}
            fullContent={
              <Text>
                <HtmlContent html={variation.guidance} />
              </Text>
            }
            limit={CLAUSE_LIBRARY_CHAR_DISPLAY_LIMIT}
            mode="icon"
          />
        </Box>
      )}
      {Boolean(variation.text?.length) && (
        <Box styles={componentStyles.wrap} mb={3} px={6}>
          <CollapsibleText
            text={htmlToText(variation.text)}
            fullContent={
              <Text>
                <HtmlContent html={variation.text} />
              </Text>
            }
            limit={CLAUSE_LIBRARY_CHAR_DISPLAY_LIMIT}
            mode="icon"
          />
        </Box>
      )}
    </Card>
  );
};

const componentStyles = {
  header: {
    borderTop: 'border',
    scrollMarginTop: '64px',
  },
  wrap: {
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  setClauseLibraryVariationForm: actions.setClauseLibraryVariationForm,
  setClauseLibraryEditMode: actions.setClauseLibraryEditMode,
  setClauseLibraryView: actions.setClauseLibraryView,
})(ClauseVariation);
