import React, { useState } from 'react';

import { Box, Modal } from '~/eds';
import { Nullable } from '~/types';

import { DocumentViewer, Props as DocumentViewerProps } from './DocumentViewer';
import type { ResolvedDocument } from './types';

interface Props {
  documentViewer: DocumentViewerProps;
  isVisible: boolean;
  onHide: () => void;
}

export const DocumentViewerModal = ({
  documentViewer,
  isVisible,
  onHide,
}: Props) => {
  const [resolvedDoc, setResolvedDoc] = useState<Nullable<ResolvedDocument>>(
    null,
  );

  return (
    <Modal
      isVisible={isVisible}
      title={resolvedDoc?.file.name ?? 'Document Viewer'}
      titleLink={
        resolvedDoc
          ? { pathname: `/document/${resolvedDoc.documentId}/` }
          : undefined
      }
      onHide={onHide}
    >
      <Box styles={{ height: '60vh' }}>
        <DocumentViewer
          {...documentViewer}
          onResolveDocument={setResolvedDoc}
        />
      </Box>
    </Modal>
  );
};
