import { createReducer } from '@reduxjs/toolkit';

import * as actions from '~/actions';
import { ManageSyncPairStageType } from '~/enums';
import { fromArray } from '~/utils/object';

export const getInitialState = () => ({
  activeSyncPair: null,
  manageSyncPairStage: ManageSyncPairStageType.None,
  tableState: { pageIndex: 1, pageSize: 10 },
  syncPairs: [],
});

export default createReducer(getInitialState(), (builder) => {
  builder.addCase(actions.integrationsSetActiveSyncPair, (state, action) => {
    state.activeSyncPair = action.payload;
  });
  builder.addCase(
    actions.integrationsSetManageSyncPairStage,
    (state, action) => {
      state.manageSyncPairStage = action.payload;
    },
  );
  builder.addCase(actions.integrationsAddSyncPair, (state, action) => {
    const newSyncPair = action.payload;
    state.syncPairs[newSyncPair.id] = newSyncPair;
  });
  builder.addCase(actions.integrationsRemoveSyncPair, (state, action) => {
    delete state.syncPairs[action.payload.id];
  });
  builder.addCase(actions.integrationsSetSyncPairs, (state, action) => {
    state.syncPairs = fromArray(action.payload, 'id');
  });
  builder.addCase(
    actions.integrationsSetManageSyncPairPageIndex,
    (state, action) => {
      const { pageIndex = 1 } = action.payload;
      state.tableState.pageIndex = pageIndex;
    },
  );
  builder.addCase(
    actions.integrationsSetManageSyncPairPageSize,
    (state, action) => {
      const { pageSize = 10 } = action.payload;
      state.tableState.pageSize = pageSize;
    },
  );
});
