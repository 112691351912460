import PropTypes from 'prop-types';
import React from 'react';

import { evisortYellow } from '../../../assets/shared-styles/general';

const FolderViewIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 16">
      <path
        fill={evisortYellow}
        d="M8.05.5H2.2a2,2,0,0,0-1.94,2V14.28a2,2,0,0,0,2,2H17.8a2,2,0,0,0,1.95-2V4.44a2,2,0,0,0-1.95-2H10Z"
      />
      <path
        fill="#333"
        d="M13.79,8.5a5,5,0,0,0-4.67,3.18,5,5,0,0,0,9.33,0A5,5,0,0,0,13.79,8.5Zm0,5.3a2.12,2.12,0,1,1,2.12-2.12A2.12,2.12,0,0,1,13.79,13.8Zm0-3.39a1.27,1.27,0,1,0,1.27,1.27A1.27,1.27,0,0,0,13.79,10.41Z"
      />
    </svg>
  );
};

FolderViewIcon.defaultProps = {
  size: '24',
};

FolderViewIcon.propTypes = {
  size: PropTypes.string,
};

export default FolderViewIcon;
