import { capitalize } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { User } from '~/components/Shared/User';
import { Layout, Table } from '~/eds';
import { api, slices } from '~/redux';

import { Endpoint, EndpointStatus, EndpointType } from '../types';
import { getStatusChip, testIsActiveStatus } from '../utils';

export const Endpoints = () => {
  const dispatch = useDispatch();

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [sortBy, setSortBy] = useState<
    { id: keyof Endpoint; desc: boolean } | undefined
  >({
    id: 'dateModified',
    desc: true,
  });

  const {
    data = { results: [], total: 0 },
    isLoading: isLoadingEndpoints,
  } = api.endpoints.getEndpoints.useQuery({
    page: pageIndex,
    pageSize,
    sortBy,
  });

  const columns = [
    {
      key: 'endpointUrl',
      title: 'Endpoint URL',
      cellType: 'link',
      mapCellProps: (endpoint: Endpoint) => ({
        pathname: endpoint.endpointUrl,
        text: endpoint.endpointUrl,
        onClick: () => {
          dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
          dispatch(slices.webhooks.actions.setActiveEndpointType('view'));
        },
      }),
    },
    {
      key: 'status',
      title: 'Status',
      cellType: 'chips',
      mapCellProps: (endpoint: Endpoint) => {
        const { text, status } = getStatusChip(
          endpoint.status as EndpointStatus,
        );
        return {
          chips: [
            {
              text,
              status,
            },
          ],
        };
      },
    },
    {
      key: 'description',
      title: 'Description',
      cellType: 'text',
      mapCellProps: (endpoint: Endpoint) => ({
        text: endpoint.description,
        shouldTruncate: true,
      }),
    },
    {
      key: 'events',
      title: 'Selected Events',
      cellType: 'text',
      mapCellProps: (endpoint: Endpoint) => ({
        text: `${endpoint.events.length}`,
        isNumeric: true,
      }),
      width: 'm',
    },
    {
      key: 'dateCreated',
      title: 'Created',
      cellType: 'datetime',
      mapCellProps: (endpoint: Endpoint) => ({
        datetime: endpoint.dateCreated,
        format: 'full',
      }),
      width: 'm',
    },
    {
      key: 'dateModified',
      title: 'Modified',
      cellType: 'datetime',
      mapCellProps: (endpoint: Endpoint) => ({
        datetime: endpoint.dateModified,
        format: 'full',
      }),
      width: 'm',
    },
    {
      key: 'createdBy',
      title: 'Created by',
      minWidth: 'm',
      cellType: 'user',
      disableSortBy: true,
      mapCellProps: (endpoint: Endpoint) => ({
        asyncUser: {
          id: endpoint.createdBy,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
    {
      key: 'modifiedBy',
      title: 'Modified by',
      minWidth: 'm',
      cellType: 'user',
      disableSortBy: true,
      mapCellProps: (endpoint: Endpoint) => ({
        asyncUser: {
          id: endpoint.modifiedBy,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
  ];

  const handleRowAction = (endpoint: Endpoint, action: EndpointType) => {
    dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
    dispatch(slices.webhooks.actions.setActiveEndpointType(action));
  };

  const handlePaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      setPageIndex(pageIndex + 1);
    },
    [pageIndex],
  );

  const handlePageSizeChange = useCallback(
    ({ pageSize }: { pageSize: number }) => {
      setPageIndex(1);
      setPageSize(pageSize);
    },
    [],
  );

  const handleUpdate = useCallback(
    (state: any, action?: any) => {
      if (action?.type === 'toggleSortBy') {
        const updatedSortBy = state.sortBy[0];

        setSortBy(updatedSortBy);
      }
    },
    [sortBy],
  );

  const getRowActions = useCallback(
    (endpoint: Endpoint) => {
      const { status } = endpoint;

      const modelActions = [
        'view details',
        'view attempts',
        'edit',
        testIsActiveStatus(status as EndpointStatus) ? 'suspend' : 'activate',
        'delete',
      ];

      const disabled = isLoadingEndpoints;

      return modelActions.map((action) => {
        return {
          key: action,
          disabled,
          label: capitalize(action),
          tooltip: disabled ? 'Loading endpoint…' : undefined,
          onClick: () => {
            if (action === 'view details') {
              handleRowAction(endpoint, 'view');
            } else if (action === 'view attempts') {
              dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
              dispatch(slices.webhooks.actions.setTab('attempts'));
            } else {
              handleRowAction(endpoint, action as EndpointType);
            }
          },
        };
      });
    },
    [isLoadingEndpoints, data],
  );

  return (
    <Layout preset="sections">
      <Table
        data={data.results}
        isLoading={isLoadingEndpoints}
        columns={columns}
        options={{
          enableManageColumns: false,
          enableSelectRows: false,
          enableSearch: true,
          enablePageSizeSelect: true,
          enablePagination: true,
        }}
        getRowActions={getRowActions}
        totalCount={data.total}
        state={{
          pageIndex,
          pageSize,
          sortBy: [sortBy],
        }}
        onPaginate={handlePaginate}
        onPageSizeChange={handlePageSizeChange}
        onUpdate={handleUpdate}
        reactTableOptions={{
          manualSortBy: true,
        }}
      />
    </Layout>
  );
};
