export const presets = {
  del: {
    as: 'del',
    color: 'text.redline',
  },
  description: {
    color: 'text.secondary',
    variant: 'body',
  },
  error: {
    color: 'status.danger',
  },
  help: {
    color: 'text.quiet',
    variant: 'tiny',
  },
  ins: {
    as: 'ins',
    color: 'text.redline',
  },
};
