import React from 'react';
import { connect } from 'react-redux';

import {
  workflowSetCreatorFullAccess,
  workflowSetCreatorPermissions,
  workflowSetFullAccessPermissions,
} from '~/actions';
import UserDepartmentSelect from '~/components/Shared/UserDepartmentSelect';
import { Layout, Select } from '~/eds';
import {
  TicketStageType,
  WorkflowCreatorPermissionType,
  WorkflowUserDepartmentInclusionType,
} from '~/enums';
import { Box, Card, FlexLayout, RadioGroup, Text } from '~/ui';
import {
  creatorPermissionEnums,
  editOptions,
  finalizeOptions,
  reviewOptions,
  signOptions,
  ticketOptions,
} from '~/utils/workflow';

import Section from '../Section';

const accessOptions = [
  { label: 'Full Access', value: true },
  { label: 'Limited Access', value: false },
];
const userDeptOptions = [
  { label: 'All users', value: WorkflowUserDepartmentInclusionType.All },
  {
    label: 'All users except',
    value: WorkflowUserDepartmentInclusionType.Exclude,
  },
  { label: 'Only', value: WorkflowUserDepartmentInclusionType.Include },
];

const testIsStageEnabled = (stage) =>
  stage.isEnabled || Boolean(stage.conditionRule);

export const testIsTicketPermissionsEnabled = ({ stages }) =>
  !Object.values(stages).some(testIsStageEnabled);

export const testIsStagePermissionsEnabled = ({ stage, stages }) =>
  testIsStageEnabled(stages[stage]);

function Permissions({
  // connected
  creatorFullAccess,
  creatorPermissions,
  fullAccessPermissions,
  stages,
  workflowSetCreatorFullAccess,
  workflowSetCreatorPermissions,
  workflowSetFullAccessPermissions,
}) {
  function handleUpdateOptions(stage, values) {
    workflowSetCreatorPermissions({ stage, values });
  }

  function toggleViewApproval(current, updated) {
    const basicEnum = creatorPermissionEnums.VIEW_APPROVAL_BASIC;
    const detailedEnum = creatorPermissionEnums.VIEW_APPROVAL_DETAILED;

    if (current.includes(detailedEnum) && updated.includes(basicEnum)) {
      return updated.filter((v) => v !== detailedEnum);
    }

    if (current.includes(basicEnum) && updated.includes(detailedEnum)) {
      return updated.filter((v) => v !== basicEnum);
    }

    return updated;
  }

  function renderEditPermissionsSelect() {
    const permissionKey = WorkflowCreatorPermissionType.Edit;
    const currentPermissions = creatorPermissions[permissionKey];
    return (
      <Select
        isMulti={true}
        options={editOptions}
        placeholder="Specify Edit permissions"
        value={currentPermissions}
        width="100%"
        onChange={(updatedValues) => {
          return handleUpdateOptions(permissionKey, updatedValues);
        }}
      />
    );
  }

  function renderReviewPermissionsSelect() {
    const permissionKey = WorkflowCreatorPermissionType.Review;
    const currentPermissions = creatorPermissions[permissionKey];
    return (
      <Select
        isMulti={true}
        options={reviewOptions}
        placeholder="Specify Review permissions"
        value={currentPermissions}
        width="100%"
        onChange={(updatedValues) => {
          updatedValues = toggleViewApproval(currentPermissions, updatedValues);
          return handleUpdateOptions(permissionKey, updatedValues);
        }}
      />
    );
  }

  function renderSignPermissionsSelect() {
    const permissionKey = WorkflowCreatorPermissionType.Sign;
    const currentPermissions = creatorPermissions[permissionKey];
    return (
      <Select
        isMulti={true}
        options={signOptions}
        placeholder="Specify Sign permissions"
        value={currentPermissions}
        width="100%"
        onChange={(updatedValues) => {
          updatedValues = toggleViewApproval(currentPermissions, updatedValues);
          return handleUpdateOptions(permissionKey, updatedValues);
        }}
      />
    );
  }

  function renderFinalizePermissionsSelect() {
    const permissionKey = WorkflowCreatorPermissionType.Finalize;
    const currentPermissions = creatorPermissions[permissionKey];
    return (
      <Select
        isMulti={true}
        options={finalizeOptions}
        placeholder="Specify Finalize permissions"
        value={currentPermissions}
        width="100%"
        onChange={(updatedValues) => {
          updatedValues = toggleViewApproval(currentPermissions, updatedValues);
          return handleUpdateOptions(permissionKey, updatedValues);
        }}
      />
    );
  }

  // This and many other helper functions are repetitive.  In the lack of test coverage and formalized UI components, favoring duplication over abstraction.
  function renderTicketPermissionSelect() {
    const permissionKey = WorkflowCreatorPermissionType.Ticket;
    const currentPermissions = creatorPermissions[permissionKey];
    return (
      <Select
        isMulti={true}
        options={ticketOptions}
        placeholder="Specify Ticket permissions"
        value={currentPermissions}
        width="100%"
        onChange={(updatedValues) => {
          updatedValues = toggleViewApproval(currentPermissions, updatedValues);
          return handleUpdateOptions('ticket', updatedValues);
        }}
      />
    );
  }

  return (
    <Section
      description="Manage workflow access and limit what ticket submitters can see on a created ticket."
      icon="briefcase"
      id="workflow--settings-permissions-header"
      title="Permissions"
    >
      <FlexLayout space={6} py={2} flexDirection="column">
        <Text variant="xs-dense-caps">General Access</Text>
        <Card
          id="workflow--settings-see-submitted-tickets-header"
          header={<Card.Header title="See Submitted Tickets" />}
          onToggleCollapse={() => {}}
        >
          <Text color="gray-600">
            Specify users and departments who will be able to see tickets
            created with this workflow.
          </Text>
          <Layout spacing={4} py={4}>
            <Select
              isClearable={false}
              id="workflow--settings-see-submitted-tickets-select"
              onChange={(rule) => {
                workflowSetFullAccessPermissions({
                  ...fullAccessPermissions,
                  rule,
                });
              }}
              options={userDeptOptions}
              value={fullAccessPermissions.rule}
              width="input.s.width"
            />
            {!(
              fullAccessPermissions.rule ===
              WorkflowUserDepartmentInclusionType.All
            ) && (
              <UserDepartmentSelect
                value={fullAccessPermissions.userDepartments}
                onChange={(userDepartments) => {
                  workflowSetFullAccessPermissions({
                    ...fullAccessPermissions,
                    userDepartments,
                  });
                }}
              />
            )}
          </Layout>
        </Card>
      </FlexLayout>
      <FlexLayout flexDirection="column" space={4} py={2}>
        <Box id="workflow--settings-submitter-access-header">
          <Text variant="xs-dense-caps">Submitter Access</Text>
        </Box>
        <Text variant="xs-dense" color="gray-600">
          Control the details of what a ticket submitter is able to see and do
          after they submit a request based on this workflow.
        </Text>
        <RadioGroup
          id="workflow--settings-submitter-access-full-access"
          onChange={(value) => workflowSetCreatorFullAccess(value)}
          options={accessOptions}
          value={creatorFullAccess}
        />
        {!creatorFullAccess && (
          <>
            <Card
              header={
                <Card.Header
                  title="Submitter Access"
                  onToggleCollapse={() => {}}
                />
              }
            >
              <FlexLayout flexDirection="column" space={6}>
                {testIsStagePermissionsEnabled({
                  stage: TicketStageType.Edit,
                  stages,
                }) && (
                  <Box sx={{ width: '100%' }}>
                    <Text variant="xs-dense-bold">Edit</Text>
                    <Box mt={2}>{renderEditPermissionsSelect()}</Box>
                  </Box>
                )}
                {testIsStagePermissionsEnabled({
                  stage: TicketStageType.Review,
                  stages,
                }) && (
                  <Box sx={{ width: '100%' }}>
                    <Text variant="xs-dense-bold">Review</Text>
                    <Box mt={2}>{renderReviewPermissionsSelect()}</Box>
                  </Box>
                )}
                {testIsStagePermissionsEnabled({
                  stage: TicketStageType.Sign,
                  stages,
                }) && (
                  <Box sx={{ width: '100%' }}>
                    <Text variant="xs-dense-bold">Sign</Text>
                    <Box mt={2}>{renderSignPermissionsSelect()}</Box>
                  </Box>
                )}
                {testIsStagePermissionsEnabled({
                  stage: TicketStageType.Finalize,
                  stages,
                }) && (
                  <Box sx={{ width: '100%' }}>
                    <Text variant="xs-dense-bold">Finalize</Text>
                    <Box mt={2}>{renderFinalizePermissionsSelect()}</Box>
                  </Box>
                )}
                {testIsTicketPermissionsEnabled({ stages }) && (
                  <Box sx={{ width: '100%' }}>
                    <Text variant="xs-dense-bold">Ticket</Text>
                    <Box mt={2}>{renderTicketPermissionSelect()}</Box>
                  </Box>
                )}
              </FlexLayout>
            </Card>
          </>
        )}
      </FlexLayout>
    </Section>
  );
}

const mapStateToProps = ({ workflow }) => ({
  creatorFullAccess: !!workflow.settings.permissions.isCreatorFullAccess,
  creatorPermissions: workflow.settings.permissions.creator,
  fullAccessPermissions: workflow.settings.permissions.fullAccess,
  stages: workflow.stages,
});

export default connect(mapStateToProps, {
  workflowSetCreatorFullAccess,
  workflowSetCreatorPermissions,
  workflowSetFullAccessPermissions,
})(Permissions);
