import { EntityVisibilityQueryParam, SavedSearch } from '~/features/search';
import { pilotV3 } from '~/services';
import { ColumnSortOrder } from '~/types';

import { JsonApiListResponse } from '../../ApiTypes';
import { toSortParam } from '../../utils';
import { ListMeta, SavedSearchAttributes, SavedSearchMeta } from './types';
import { mapSavedSearchResponseToSavedSearch } from './utils';

type Request = {
  page: number;
  pageSize: number;
  types: EntityVisibilityQueryParam[];
  sort?: ColumnSortOrder;
};

type Response = JsonApiListResponse<
  'saved-search',
  SavedSearchAttributes,
  ListMeta,
  SavedSearchMeta
>;

type ReturnType = {
  results: SavedSearch[];
  meta: ListMeta;
};

export const getSavedSearches = async ({
  page,
  pageSize,
  types,
  sort,
}: Request): Promise<ReturnType> => {
  const response: Response = await pilotV3.get('/saved-search/searches/', {
    params: {
      'page[number]': page,
      'page[size]': pageSize,
      sort: sort ? toSortParam(sort) : undefined,
      types,
    },
  });
  return mapResponseToType(response);
};

const mapResponseToType = (response: Response): ReturnType => {
  return {
    results: response.data.map((savedSearch) =>
      mapSavedSearchResponseToSavedSearch({ data: savedSearch }),
    ),
    meta: response.meta ?? { total: 0 },
  };
};
