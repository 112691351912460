import React from 'react';

import { Header, Layout } from '../../components';
import { SharedHeaderProps } from '../../types';
import { typedMemo } from '../../utils';
import { InfoSection } from './InfoSection';
import * as types from './types';

interface Props<T extends types.InfoType> {
  /** Sections of info */
  sections: types.InfoSection<T>[];
  /** Optional header */
  header?: SharedHeaderProps;
}

export const Info = typedMemo(
  <T extends types.InfoType>({ header, sections }: Props<T>) => {
    return (
      <Layout direction="column" spacing={2}>
        {header && <Header {...header} enablePadding={false} />}
        <Layout preset="sections">
          {sections.map((section) => (
            <InfoSection key={section.title} {...section} />
          ))}
        </Layout>
      </Layout>
    );
  },
);
