import PT from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getExcelExportSettings, saveExcelExportSettings } from '~/api';
import EcFilterInput from '~/components/Shared/EcFilterInput';
import EcModalCard from '~/components/Shared/EcModalCard';
import { showToast } from '~/components/Shared/EcToast';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { EXCEL_EXPORT_SINGLE_FILE_DOC_COUNT_LIMIT } from '~/constants/max_lengths';
import { Checkbox, Tabs } from '~/eds';
import { ExcelExportTabType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Alert, Box, Button, FlexLayout, Text } from '~/ui';
import { formatNumeral } from '~/utils';
import { getItemValue } from '~/utils/array';
import {
  combineValueInObject,
  excludeValueInObject,
  getObjectFlattenedValue,
} from '~/utils/object';
import { capitalizeWords, searchByString } from '~/utils/strings';

import FieldsCard from './FieldsCard';
import { getSelectAllCheckboxStatus, isOneBooleanTrue } from './utils';

function ExportSettingModal({
  analyzerSearchQuery,
  title,
  totalDocs,
  totalDups,
  onExport,
  hideModal,
}) {
  const enableClauseValueExportSettings = useFlag(
    FlagType.ClauseValueExportSettings,
  );
  const [excelExportOptionTabs, setExcelExportOptionTabs] = useState([]);
  const [excelExportSettingData, setExcelExportSettingData] = useState(null);
  const [
    filteredExcelExportSettingData,
    setFilteredExcelExportSettingData,
  ] = useState({});
  const [formData, setFormData] = useState({
    [ExcelExportTabType.Fields]: {},
    [ExcelExportTabType.Provisions]: [],
    [ExcelExportTabType.Tables]: [],
  });

  const [query, setQuery] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [isAllSelected, setAllSelected] = useState();
  const [enableDupDocs, setEnableDupDocs] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ExcelExportTabType.All);
  const [isExporting, setIsExporting] = useState(false);
  const [isExportingUpdating, setIsExportingUpdating] = useState(false);

  const selectedItems = [
    ...getObjectFlattenedValue(formData[ExcelExportTabType.Fields]),
    ...formData[ExcelExportTabType.Provisions],
    ...formData[ExcelExportTabType.Tables],
  ];

  function getQueryPhrase() {
    return query ? ` for ${query}.` : '.';
  }

  function getConditionalSelectAllStatus() {
    return noResults ? false : isAllSelected;
  }

  function getSelectedItemsValue(array) {
    return array.filter((item) => item.selected).map((itm) => itm.value);
  }

  useEffect(() => {
    if (
      query &&
      !getObjectFlattenedValue(filteredExcelExportSettingData).length
    ) {
      setNoResults(true);
      setAllSelected(false);
    } else {
      setNoResults(false);
    }
  }, [selectedTab, query, filteredExcelExportSettingData]);

  useEffect(() => {
    getSelectAllCheckboxStatus(
      selectedTab,
      filteredExcelExportSettingData,
      formData,
      setAllSelected,
    );
  }, [filteredExcelExportSettingData, formData, selectedTab]);

  const { executor: saveExcelExportSetting } = useAsync(
    saveExcelExportSettings,
    {
      ...formData,
      [ExcelExportTabType.Fields]: getObjectFlattenedValue(
        formData[ExcelExportTabType.Fields],
      ),
      includeDupDocs: enableDupDocs,
    },
    {
      successHandler: () => {
        showToast(
          SUCCESS,
          'Your settings have been saved for your next export.',
        );
      },
      errorHandler: () =>
        showToast(
          ERROR,
          'An error occurred while saving excel export settings',
        ),
    },
  );

  const { isLoading } = useAsync(
    getExcelExportSettings,
    { query: analyzerSearchQuery },
    {
      condition: true,
      successHandler: (response) => processExcelSettings(response),
      errorHandler: () =>
        showToast(
          ERROR,
          'An error occurred while loading excel export settings',
        ),
    },
  );

  function processExcelSettings(response) {
    const fields = Object.entries(response.fields).map(([key, value]) => {
      return {
        sectionName: key,
        fields: value,
      };
    });
    const fieldsSelected = Object.fromEntries(
      Object.entries(response.fields).map(([key, val]) => [
        key,
        val.filter((itm) => itm.selected).map((d) => d.value),
      ]),
    );

    const data = {
      [ExcelExportTabType.Fields]: fields,
      [ExcelExportTabType.Provisions]: response.provisions,
      [ExcelExportTabType.Tables]: response.tables,
    };

    setExcelExportSettingData(data);
    setFilteredExcelExportSettingData(data);

    let options = [];

    if (Object.values(response.fields).length) {
      const fieldsTab = ExcelExportTabType.Fields;
      options.push({ label: capitalizeWords(fieldsTab), value: fieldsTab });
      setSelectedTab(fieldsTab);
    }

    if (response.provisions.length) {
      const provisionsTab = ExcelExportTabType.Provisions;
      const label = enableClauseValueExportSettings
        ? 'Clauses'
        : capitalizeWords(provisionsTab);
      options.push({ label, value: provisionsTab });
    }

    if (!enableClauseValueExportSettings && response.tables.length) {
      const tablesTab = ExcelExportTabType.Tables;
      options.push({ label: capitalizeWords(tablesTab), value: tablesTab });
    }

    if (options.length > 1) {
      const allTab = ExcelExportTabType.All;
      options.unshift({ label: capitalizeWords(allTab), value: allTab });
      setSelectedTab(allTab);
    }

    setExcelExportOptionTabs(options);
    setEnableDupDocs(response.includeDupDocs || false);
    setFormData({
      [ExcelExportTabType.Fields]: fieldsSelected,
      [ExcelExportTabType.Provisions]: getSelectedItemsValue(
        response.provisions,
      ),
      [ExcelExportTabType.Tables]: getSelectedItemsValue(response.tables),
    });
  }

  function handleCheckboxClick(type, itemValue) {
    let newSelectedItems = formData?.[type] || [];

    if (newSelectedItems.includes(itemValue)) {
      newSelectedItems = newSelectedItems.filter((item) => item !== itemValue);
    } else {
      newSelectedItems.push(itemValue);
    }
    setFormData({ ...formData, [type]: newSelectedItems });
  }

  function onFieldSelect(sectionName, fieldIds) {
    const newFields = {};
    newFields[sectionName] = fieldIds;
    const newFormData = { ...formData.fields, ...newFields };
    setFormData({ ...formData, [ExcelExportTabType.Fields]: newFormData });
  }

  function handleSearch(searchString, tab) {
    setSelectedTab(tab);
    setQuery(searchString);

    if (!searchString) {
      setFilteredExcelExportSettingData(excelExportSettingData);
    } else {
      const filteredFields = excelExportSettingData.fields
        .map((item) => {
          let results = [];
          item.fields.forEach((itm) => {
            if (searchByString(itm.label, searchString)) {
              results.push(itm);
            }
            return results;
          });
          if (results.length) {
            return {
              sectionName: item.sectionName,
              fields: results,
            };
          } else if (searchByString(item.sectionName, searchString)) {
            return item;
          }
          return null;
        })
        .filter((item) => !!item);
      const filterProvisions = excelExportSettingData.provisions.filter(
        (item) => searchByString(item.label, searchString),
      );
      const filteredTables = excelExportSettingData.tables.filter((item) =>
        searchByString(item.label, searchString),
      );
      const dataShowWithTab = {};
      if (
        isOneBooleanTrue(
          tab === ExcelExportTabType.All,
          tab === ExcelExportTabType.Fields,
        )
      ) {
        dataShowWithTab[ExcelExportTabType.Fields] = filteredFields;
      }
      if (
        isOneBooleanTrue(
          tab === ExcelExportTabType.All,
          tab === ExcelExportTabType.Provisions,
        )
      ) {
        dataShowWithTab[ExcelExportTabType.Provisions] = filterProvisions;
      }
      if (
        !enableClauseValueExportSettings &&
        isOneBooleanTrue(
          tab === ExcelExportTabType.All,
          tab === ExcelExportTabType.Tables,
        )
      ) {
        dataShowWithTab[ExcelExportTabType.Tables] = filteredTables;
      }
      setFilteredExcelExportSettingData(dataShowWithTab);
    }
  }

  function handleSelectAllClick() {
    setAllSelected(!isAllSelected);
    const newFields =
      selectedTab === ExcelExportTabType.All ||
      selectedTab === ExcelExportTabType.Fields
        ? filteredExcelExportSettingData.fields.reduce(
            (obj, item) => ({
              ...obj,
              [item.sectionName]: item.fields.map((itm) => itm.value),
            }),
            {},
          )
        : {};

    const provisionValues =
      selectedTab === ExcelExportTabType.All ||
      selectedTab === ExcelExportTabType.Provisions
        ? getItemValue(filteredExcelExportSettingData.provisions)
        : [];
    const tableValues =
      !enableClauseValueExportSettings &&
      (selectedTab === ExcelExportTabType.All ||
        selectedTab === ExcelExportTabType.Tables)
        ? getItemValue(filteredExcelExportSettingData.tables)
        : [];

    if (isAllSelected) {
      setFormData({
        [ExcelExportTabType.Fields]: excludeValueInObject(
          { ...formData.fields },
          newFields,
        ),
        [ExcelExportTabType.Provisions]: formData.provisions.filter(
          (itm) => !provisionValues?.includes(itm),
        ),
        [ExcelExportTabType.Tables]: formData.tables.filter(
          (itm) => !tableValues.includes(itm),
        ),
      });
    } else {
      setFormData({
        [ExcelExportTabType.Fields]: combineValueInObject(
          { ...formData.fields },
          newFields,
        ),
        [ExcelExportTabType.Provisions]: [
          ...new Set([...formData.provisions, ...provisionValues]),
        ],
        [ExcelExportTabType.Tables]: [
          ...new Set([...formData.tables, ...tableValues]),
        ],
      });
    }
  }

  function preventDefaultBubbling(e) {
    e.preventDefault();
  }

  function renderFields() {
    const shouldShowFields = isOneBooleanTrue(
      selectedTab === ExcelExportTabType.All,
      selectedTab === ExcelExportTabType.Fields,
    );
    const dataDisplay = filteredExcelExportSettingData.fields;
    if (!dataDisplay?.length || !shouldShowFields) {
      return null;
    }
    const fieldsData = dataDisplay.map((field) => {
      return (
        <div role="presentation" onClick={preventDefaultBubbling}>
          <FieldsCard
            key={`field_card_${field.sectionName}`}
            shouldCollapse={query.length === 0}
            field={field}
            fieldsChosen={formData.fields[field.sectionName]}
            onFieldSelect={onFieldSelect}
          />
        </div>
      );
    });

    return (
      <Box mt={3}>
        <Text color="black-alpha-75" variant="2xs-dense-caps">
          Fields
        </Text>
        <Box>{fieldsData}</Box>
      </Box>
    );
  }

  function renderList(name, data) {
    const shouldShowList = isOneBooleanTrue(
      selectedTab === ExcelExportTabType.All,
      selectedTab === name,
    );
    if (!data?.length || !shouldShowList) {
      return null;
    }
    const dataList = data.map((item, index) => {
      return (
        <FlexLayout
          key={`excel_export_${name}_list_${index}`}
          alignItems="center"
          px={2}
          py={2}
          space={2}
          onClick={preventDefaultBubbling}
        >
          <Checkbox
            label={item.label}
            option={{
              label: item.label,
              value: formData[name].includes(item.value),
            }}
            value={formData[name].includes(item.value)}
            onChange={() => handleCheckboxClick(name, item.value)}
          />
        </FlexLayout>
      );
    });

    return (
      <Box mt={3}>
        <Text color="black-alpha-75" variant="2xs-dense-caps">
          {enableClauseValueExportSettings ? 'Clauses' : capitalizeWords(name)}
        </Text>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
          {dataList}
        </Box>
      </Box>
    );
  }

  function renderExcelExportMeta() {
    return (
      <FlexLayout
        pt={4}
        mt={4}
        justifyContent="space-between"
        sx={{ borderTop: 'border' }}
      >
        <FlexLayout flexDirection="column">
          <Text color="gray-700" variant="xs-dense">
            Selected Items:
          </Text>{' '}
          <Text color="gray-700" variant="xs-dense-bold">
            {formatNumeral()(selectedItems.length)}
          </Text>
        </FlexLayout>
        <FlexLayout flexDirection="column">
          <Text color="gray-700" variant="xs-dense">
            Total Documents:
          </Text>{' '}
          <Text color="gray-700" variant="xs-dense-bold">
            {formatNumeral()(totalDocs)}
          </Text>
        </FlexLayout>
        <FlexLayout flexDirection="column">
          <Text color="gray-700" variant="xs-dense">
            Total Duplicates:
          </Text>
          <Text color="gray-700" variant="xs-dense-bold">
            {formatNumeral()(totalDups - totalDocs)}
          </Text>
        </FlexLayout>
        <FlexLayout flexDirection="column">
          <Text color="gray-700" variant="xs-dense">
            Including Duplicates:
          </Text>{' '}
          <Text color="gray-700" variant="xs-dense-bold">
            {formatNumeral()(totalDups)}
          </Text>
        </FlexLayout>
      </FlexLayout>
    );
  }

  function renderSearchBarSelectAll() {
    return (
      <FlexLayout
        pb={4}
        mt={4}
        space={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderBottom: 'border' }}
      >
        <EcFilterInput
          value={query}
          onChange={(str) => handleSearch(str, selectedTab)}
          onClearSearch={() => handleSearch('', selectedTab)}
          placeholder="Search"
        />

        <FlexLayout space={2} justifyContent="end" inline alignItems="center">
          <Checkbox
            option={{ label: 'Select All', value: isAllSelected }}
            value={getConditionalSelectAllStatus()}
            onChange={handleSelectAllClick}
          />
        </FlexLayout>
      </FlexLayout>
    );
  }

  return (
    <EcModalCard
      title={title}
      content={
        <>
          {isLoading ? (
            <FlexLayout justifyContent="center">
              <LoadingSpinner size="medium" />
            </FlexLayout>
          ) : (
            <>
              {excelExportSettingData && excelExportOptionTabs.length && (
                <>
                  <>
                    <Box>
                      <Tabs
                        enableContentPadding={false}
                        selectedTab={selectedTab}
                        tabs={excelExportOptionTabs}
                        onSelectTab={setSelectedTab}
                      />
                    </Box>
                    {renderSearchBarSelectAll()}
                  </>

                  <Box sx={{ maxHeight: '30vh', overflowY: 'scroll' }}>
                    {renderFields()}
                    {renderList(
                      ExcelExportTabType.Provisions,
                      filteredExcelExportSettingData.provisions,
                    )}
                    {!enableClauseValueExportSettings &&
                      renderList(
                        ExcelExportTabType.Tables,
                        filteredExcelExportSettingData.tables,
                      )}
                    {noResults && (
                      <FlexLayout
                        sx={{ height: '30vh' }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text color="gray-600" variant="m-dense-bold">
                          No results found{getQueryPhrase()}
                        </Text>
                      </FlexLayout>
                    )}
                  </Box>
                </>
              )}
              {renderExcelExportMeta()}

              {totalDocs > EXCEL_EXPORT_SINGLE_FILE_DOC_COUNT_LIMIT && (
                <Alert enableIcon variant="warning">
                  <Text color="gray-800" variant="xs-dense-medium">
                    Exports files are limited to 5GB. If your export exceeds
                    this size, it will be split into multiple files.
                  </Text>
                </Alert>
              )}
            </>
          )}
        </>
      }
      footer={
        <>
          <FlexLayout
            space={2}
            justifyContent="space-between"
            inline
            alignItems="center"
          >
            <label htmlFor="ExcelExportSetttings/IncludeDuplicatesCheckbox">
              <Text variant="xs-dense"></Text>
            </label>
            <Checkbox
              label="Include Duplicates"
              option={{ label: 'Include Duplicates', value: enableDupDocs }}
              value={enableDupDocs}
              onChange={() => setEnableDupDocs(!enableDupDocs)}
            />
          </FlexLayout>
          <Button
            isLoading={isExportingUpdating}
            text="Export & Update Settings"
            variant="outlined"
            onClick={() => {
              setIsExportingUpdating(true);
              onExport({
                ...formData,
                includeDupDocs: enableDupDocs,
                [ExcelExportTabType.Fields]: getObjectFlattenedValue(
                  formData[ExcelExportTabType.Fields],
                ),
              });
              saveExcelExportSetting();
            }}
          />
          <Button
            isLoading={isExporting}
            text="Export"
            variant="primary"
            onClick={() => {
              setIsExporting(true);
              onExport({
                ...formData,
                includeDupDocs: enableDupDocs,
                [ExcelExportTabType.Fields]: getObjectFlattenedValue(
                  formData[ExcelExportTabType.Fields],
                ),
              });
            }}
          />
        </>
      }
      hideModal={hideModal}
    />
  );
}

ExportSettingModal.propTypes = {
  title: PT.string.isRequired,
  totalDocs: PT.number,
  totalDups: PT.number,
  onExport: PT.func.isRequired,
  hideModal: PT.func.isRequired,
};

export default ExportSettingModal;
