export enum SyncPairStatusType {
  Initial = 'ACTIVE_INIT_REQUIRED',
  Active = 'ACTIVE',
  AuthError = 'INACTIVE_AUTH_ERROR',
  DataAccessError = 'INACTIVE_DATA_ACCESS_ERROR',
  OutOfSpaceError = 'INACTIVE_OUT_OF_SPACE_ERROR',
  OtherError = 'INACTIVE_OTHER_ERROR',
  ConfigError = 'INACTIVE_CONFIG_ERROR',
  Syncing = 'SYNCING',
  ActiveSync = 'ACTIVE_SYNC',
  Inactive = 'INACTIVE',
}
