import React, { useEffect, useState } from 'react';

import { MODAL_MULTIPLE_LOCATIONS } from '~/types/modal.types';

import EcModal from './EcModal';

export function useDocumentMultipleLocationsModal(
  getHandlers,
  openDocumentInNewTab = false,
  onNavigate,
) {
  const [documentSelected, setDocumentSelected] = useState(null);
  // using null as default value due the conflict of types, TODO: fix on the ts refactor
  const [localOpenDocumentInNewTab, setOpenDocumentInNewTab] = useState(null);

  useEffect(() => {
    setOpenDocumentInNewTab(openDocumentInNewTab);
  }, [openDocumentInNewTab]);

  function renderMultipleLocationModal() {
    if (documentSelected) {
      const handlers = getHandlers(documentSelected);
      if (handlers?.length) {
        return (
          <EcModal
            hideModal={() => setDocumentSelected(null)}
            modalType={MODAL_MULTIPLE_LOCATIONS}
            openDocumentInNewTab={localOpenDocumentInNewTab}
            multipleLocationsCallback={(document) => onNavigate?.(document.id)}
            width="560px"
            documentName={handlers[0].document_name}
            documents={handlers}
          />
        );
      }
    } else {
      return null;
    }
  }

  return [
    setDocumentSelected,
    renderMultipleLocationModal,
    setOpenDocumentInNewTab,
  ];
}
