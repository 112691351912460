import React, { memo } from 'react';

import * as types from '../../types';
import { getUserName, stopPropagation } from '../../utils';
import { Box } from '../Box';
import { useShowMoreItems } from '../hooks/useShowMoreItems';
import { Layout } from '../Layout';
import { User } from '../User';

interface Props {
  /** List of users */
  users: types.User[];
  /** Limit the number of visible users */
  limit?: number;
  /** Number of hidden users to display */
  step?: number;
}

export const Users = memo(({ users, limit = users.length, step }: Props) => {
  const [visibleUsers, showMore] = useShowMoreItems<types.User>({
    items: users,
    limit,
    serializeItem: getUserName,
    step,
  });

  return (
    <Layout preset="users">
      {visibleUsers.map((user) => (
        <Box border="avatar" borderRadius="round" key={user.id}>
          <User mode="avatar" user={user} />
        </Box>
      ))}
      <Box pl={3} onClick={stopPropagation}>
        {showMore}
      </Box>
    </Layout>
  );
});
