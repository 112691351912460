import React from 'react';

import { HtmlEntityType, Layout, TruncateText } from '~/eds';

interface Props {
  isBold?: boolean;
  text: string;
}

// Shareable text container to apply consistent styles and truncation rules
export const TextContainer = ({ isBold = true, text }: Props) => (
  <TruncateText
    color="inherit"
    maxW="100%"
    variant={isBold ? 'tiny-bold' : 'tiny'}
  >
    {text}
  </TruncateText>
);

interface PairProps {
  delimiter: string;
  texts: [string, string];
  placeholder?: string;
}

const Pair = ({
  delimiter,
  placeholder = HtmlEntityType.Dash,
  texts,
}: PairProps) => {
  const [text1, text2] = texts;

  if (text1) {
    return text2 ? (
      <Layout align="center" spacing={1}>
        <TextContainer text={text1} />
        <span>{delimiter}</span>
        <TextContainer text={text2} />
      </Layout>
    ) : (
      <TextContainer text={[text1, delimiter, placeholder].join(' ')} />
    );
  } else {
    return text2 ? (
      <TextContainer text={[placeholder, delimiter, text2].join(' ')} />
    ) : null;
  }
};

TextContainer.Pair = Pair;
