import React from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import { Layout, Text } from '~/eds';
import { useCurrentUser } from '~/hooks';

import { AddFilters } from './AddFilters';
import { ClearFilters } from './ClearFilters';
import { FilterChips } from './FilterChips';
import { Field, FieldId, Filter, Group, GroupId } from './types';
import { addFilters, clearFilter, removeFilter, updateFilter } from './utils';

interface Options {
  /** Disable adding and managing filters */
  disableManageFilters: boolean;
  /** Disable remove icon on filter chips */
  disableRemoveFilter: boolean;
  /** Disable Clear Filters button to clear the filter values */
  disableClearFilters: boolean;
  /** Disable or enable inline filters UI */
  showInlineManageFilters?: boolean;
}

interface Props {
  enableFilterViews?: boolean;
  /** Fields spec (normalized) */
  fields: Record<FieldId, Field>;
  /** Applied filters */
  filters: Filter[];
  /** Groups spec (normalized) */
  groups: Group[];
  /** Filters change handler */
  onChange: (filters: Filter[]) => void;
  /** Options */
  options?: Partial<Options>;
  /** Pinned fields when managing fields */
  pinnedFieldIds?: FieldId[];
  /** Updating pinned fields */

  onUpdatePinnedFieldIds?: (
    updatedFieldIds: FieldId[],
    action: { value: FieldId; type: 'pin' | 'unpin' },
  ) => void;
}

export const Filters_DEPRECATED = ({
  enableFilterViews = false,
  fields,
  filters,
  groups,
  options = {},
  pinnedFieldIds,
  onChange,
  onUpdatePinnedFieldIds,
}: Props) => {
  const title = 'Filters';
  const {
    disableManageFilters = false,
    disableRemoveFilter = false,
    disableClearFilters = false,
    showInlineManageFilters = true,
  } = options;
  const user = useCurrentUser();
  const handleAddFilters = (selectedFieldIds: Record<GroupId, FieldId[]>) =>
    onChange(addFilters(filters, selectedFieldIds));

  const handleClearFilters = () => {
    trackSegment('clearFilters', {
      user_id: user.id,
      client_id: user.client,
    });
    onChange(filters.map(clearFilter));
  };

  const handleRemoveFilter = (filterToRemove: Filter) => {
    const field = Object.values(fields).find(
      (field) => field.id === filterToRemove.fieldId,
    );
    trackSegment('removeFilter', {
      fieldId: filterToRemove.fieldId,
      name: field?.label,
    });
    onChange(removeFilter(filters, filterToRemove));
  };

  const handleUpdateFilter = (updatedFilter: Filter) => {
    const field = Object.values(fields).find(
      (field) => field.id === updatedFilter.fieldId,
    );
    if (updatedFilter.operatorId) {
      trackSegment('updateFilter', {
        fieldId: updatedFilter.fieldId,
        values: updatedFilter.values,
        name: field?.label,
        operatorId: updatedFilter.operatorId,
        type: field?.type,
        isAllSelected: updatedFilter.asyncValue?.isAllSelected,
      });
    } else {
      trackSegment('clearFilter', {
        fieldId: updatedFilter.fieldId,
        name: field?.label,
      });
    }

    onChange(updateFilter(filters, updatedFilter));
  };

  const inlineManageFilters = (
    <Layout align="center" ml={2} spacing={4}>
      {filters.length > 0 && !disableClearFilters && (
        <ClearFilters onClear={handleClearFilters} />
      )}
      {!disableManageFilters && (
        <AddFilters
          onAdd={handleAddFilters}
          fields={fields}
          groups={groups}
          pinnedFieldIds={pinnedFieldIds}
          onUpdatePinnedFieldIds={onUpdatePinnedFieldIds}
        />
      )}
    </Layout>
  );

  const manageFilters = (
    <Layout align="center" mb={4}>
      <Text variant="body-bold" whiteSpace="nowrap" mr={4}>
        {title}
      </Text>
      {filters.length > 0 && !disableClearFilters && (
        <ClearFilters onClear={handleClearFilters} />
      )}
      {!disableManageFilters && (
        <AddFilters
          onAdd={handleAddFilters}
          fields={fields}
          groups={groups}
          pinnedFieldIds={pinnedFieldIds}
          onUpdatePinnedFieldIds={onUpdatePinnedFieldIds}
        />
      )}
    </Layout>
  );

  return (
    <Layout direction="column">
      {!showInlineManageFilters && manageFilters}
      <Layout
        wrap
        spacing={1}
        wrapSpacing={1}
        role="group"
        aria-label="Filters"
      >
        <FilterChips
          enableFilterViews={enableFilterViews}
          disableRemove={disableRemoveFilter}
          fields={fields}
          filters={filters}
          onRemoveFilter={handleRemoveFilter}
          onUpdateFilter={handleUpdateFilter}
        />
        {showInlineManageFilters && inlineManageFilters}
      </Layout>
    </Layout>
  );
};
