import React, { useEffect, useState } from 'react';

// TODO: Move or deprecate
import QueryBuilderGroup from '~/components/DashboardV2/QueryBuilderPanel/QueryBuilderGroup';
import { toSectionFiltersOperators } from '~/components/DashboardV2/QueryBuilderPanel/QueryBuilderPanel.utils';
import { toFilter } from '~/components/SearchV2/SearchV2.utils';
import AndOrToggle from '~/components/Shared/AndOrToggle';
import SelectAddFilter from '~/components/Shared/Filters/SelectAddFilter';
import { AND as AndOperator } from '~/constants/openSearch';
import { Box, Button, Layout, Panel_DEPRECATED } from '~/eds';
import { Filter } from '~/evifields';
import { QueryGroup, SearchFilterRecord } from '~/redux/api/methods/searchV3';
import { Nullable, Query, QueryOperatorEntity, SectionEntity } from '~/types';

type Props = {
  visible: boolean;
  value?: Query;
  searchFilters: SearchFilterRecord;
  onHide: () => void;
  onSearch: (groupsEntity: (QueryGroup | QueryOperatorEntity)[]) => void;
};

const newOperator = { type: 'operator', value: 'and' } as QueryOperatorEntity;
const newGroup = {
  type: 'section',
  value: { filters: [], operator: newOperator },
};

export const QueryBuilder = ({
  value,
  visible,
  searchFilters,
  onHide,
  onSearch,
}: Props) => {
  const [filterSelected, setFilterSelected] = useState<string>();
  const [groups, setGroups] = useState<QueryGroup[]>([newGroup]);
  const [operators, setOperators] = useState<QueryOperatorEntity[]>([]);
  const [selectFilterScreen, setSelectFilterScreen] = useState(false);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<
    Nullable<number>
  >(0);

  const addQueryGroup = () => {
    const newGroups = [...groups, newGroup];
    setGroups(newGroups);

    if (newGroups.length > 1) {
      setOperators([...operators, AndOperator]);
    }
  };

  const handleRemoveGroup = (index: number) => {
    const newGroups = groups;
    const newOperators = [...operators];
    const operatorIndex = index === 0 ? 0 : index - 1;
    newGroups.splice(index, 1);
    newOperators.splice(operatorIndex, 1);
    setGroups(newGroups);
    setOperators([...newOperators]);
  };

  const handleGroupOperatorSelect = (index: number, value: string) => {
    const newOperators = operators;
    const operator = { type: 'operator', value } as QueryOperatorEntity;
    newOperators[index] = operator;
    setOperators(newOperators);
  };

  const handleFiltersInGroup = (index: number, filters: Filter[]) => {
    const newGroups = [...groups];
    const filterOperator = newGroups[index].value.operator;
    const valueObj = { filters, operator: filterOperator };
    const grpObj = { type: 'section', value: valueObj } as QueryGroup;
    newGroups[index] = grpObj;
    setGroups(newGroups);
  };

  const handleFiltersOperator = (index: number, operatorValue: string) => {
    const newGroups = [...groups];
    const filters = newGroups[index].value.filters;
    const valueObj = {
      filters,
      operator: { type: 'operator', value: operatorValue },
    };
    const grpObj = { type: 'section', value: valueObj } as QueryGroup;
    newGroups[index] = grpObj;
    setGroups(newGroups);
  };

  useEffect(() => {
    if (value && searchFilters) {
      const querySections = (value as SectionEntity[]).filter(
        (item: SectionEntity) => item.type === 'section',
      );
      const queryGroupFilters = toSectionFiltersOperators(
        querySections,
        searchFilters,
      );
      const operatorsToSet = (value as QueryOperatorEntity[]).filter(
        (item: QueryOperatorEntity) => item.type === 'operator',
      );
      setGroups(queryGroupFilters as QueryGroup[]);
      setOperators(operatorsToSet);
    } else {
      setGroups([newGroup]);
    }
  }, [value, searchFilters]);

  const runSearch = () => {
    const groupsEntity = groups.reduce(
      (acc: (QueryGroup | QueryOperatorEntity)[], val: QueryGroup, i) => {
        return operators[i] ? acc.concat(val, operators[i]) : acc.concat(val);
      },
      [],
    );
    onSearch(groupsEntity);
  };

  const handleAddFilter = () => {
    setSelectFilterScreen(false);
    setSelectedGroupIndex(null);

    if (filterSelected) {
      const newGroups = groups;
      if (selectedGroupIndex !== null) {
        const theGroup = newGroups[selectedGroupIndex];
        let filterValue = theGroup.value.filters;
        const operator = theGroup.value.operator;
        filterValue = filterValue.concat(toFilter(filterSelected));
        newGroups[selectedGroupIndex || 0] = {
          type: 'section',
          value: {
            filters: filterValue,
            operator: operator || { type: 'operator', value: 'and' },
          },
        };
        setGroups(newGroups);
      }
    }
  };
  const areGroupsEmpty =
    !groups.flat().length ||
    !!groups.flat().some((item: QueryGroup) => !item.value.filters.length);
  const areFiltersEmpty = groups
    .flatMap((group) => group.value.filters)
    .some(
      (filter) =>
        !isBooleanFilter(filter.operatorId) && filter.values.length === 0,
    );

  const PanelFooter = (
    <Layout borderTop="border.divider" justify="end" p={20} spacing={20}>
      {selectFilterScreen ? (
        <Button
          disabled={!filterSelected}
          text="Add Filter"
          variant="primary"
          onClick={handleAddFilter}
        />
      ) : (
        <>
          <Button text="Cancel" variant="tertiary" onClick={onHide} />
          <Button
            disabled={areGroupsEmpty || areFiltersEmpty}
            text="Run Search"
            tooltip={
              areGroupsEmpty || areFiltersEmpty
                ? 'Please make sure there is a valid filter.'
                : ''
            }
            variant="primary"
            onClick={runSearch}
          />
        </>
      )}
    </Layout>
  );

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      title="Query Builder"
      footer={PanelFooter}
      isVisible={visible}
      onHide={() => {
        onHide();
        setSelectFilterScreen(false);
      }}
    >
      {selectFilterScreen ? (
        <SelectAddFilter onFilterSelect={setFilterSelected} />
      ) : (
        <Box p={20}>
          {groups.map((group, index) => {
            return (
              <>
                <QueryBuilderGroup
                  filters={group.value.filters}
                  index={index}
                  operatorValue={group.value.operator?.value}
                  searchFilters={searchFilters}
                  goSelectFilter={() => {
                    setSelectFilterScreen(true);
                    setSelectedGroupIndex(index);
                  }}
                  setFiltersInGroup={handleFiltersInGroup}
                  setFiltersOperator={handleFiltersOperator}
                  onRemove={handleRemoveGroup}
                />
                {index < groups.length - 1 ? (
                  <Box mt={15} mb={15}>
                    <AndOrToggle
                      value={operators[index]?.value}
                      onChange={(value) =>
                        handleGroupOperatorSelect(index, value)
                      }
                    />
                  </Box>
                ) : null}
              </>
            );
          })}
          <Box mt={20}>
            <Button variant="action" text="Add group" onClick={addQueryGroup} />
          </Box>
        </Box>
      )}
    </Panel_DEPRECATED>
  );
};

const isBooleanFilter = (operatorId: Nullable<string>) => {
  return (
    operatorId === 'is_true' ||
    operatorId === 'is_false' ||
    operatorId === 'is_blank' ||
    operatorId === 'is_not_blank'
  );
};
