import { decamelizeKeys } from 'humps';

import { pilot } from '../../services';

// DocumentProcessingPage endpoints
export const getClientDocumentProcessingStatus = (clientId) =>
  pilot.get(`client/${clientId}/document-processing-status/`);

export const reprocessDocuments = (clientId, { algorithms, condition }) =>
  pilot.patch(`client/${clientId}/reprocess-documents/`, {
    algorithms,
    condition,
  });

export const stopReprocessingTask = (clientId, { algorithms }) =>
  pilot.remove(`client/${clientId}/reprocessing-task/`, {
    data: { algorithms },
  });

export const updateClientAdmin = (clientId, data) =>
  pilot.put(`client/${clientId}/user-admin/`, decamelizeKeys(data));
