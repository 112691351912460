const styles = {
  collapseWrapper: {
    display: 'flex',
    position: 'relative',
  },
  showComponent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    borderRadius: '4px',
    margin: (props) => (props.collapseToRight ? '0 0 0 16px' : '0 16px 0 0'),
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  hideComponent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 8px',
    cursor: 'pointer',
    '&:before': {
      content: '""',
      display: 'block',
      width: '2px',
      position: 'absolute',
      top: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 0,
    },
    '&:hover:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.14)',
    },
  },
  chevronIconWrapper: {
    padding: '8px 0',
    backgroundColor: '#f9fbfc',
    zIndex: 0,
  },
};

export default styles;
