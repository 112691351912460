export enum PanelType {
  ActivityLogs = 'activityLogs',
  AskAI = 'askAI',
  ClauseLibrary = 'clauseLibrary',
  Comments = 'comments',
  CycleTimeTracker = 'cycleTimeTracker',
  DocumentDetails = 'documentDetails',
  GenAi = 'genAi',
  IntakeForm = 'intakeForm',
  TrackChanges = 'trackChanges',
}
