import chartEmpty from './chart-empty.svg';
import chartError from './chart-error.svg';
import chartTooMuchData from './chart-too-much-data.svg';
import documentFaq from './document-faq.svg';
import emptyNoAlerts from './empty-no-alerts.svg';
import emptyNoDocuments from './empty-no-documents.svg';
import emptyNoGroups from './empty-no-groups.svg';
import emptyNoSearch from './empty-no-search.svg';
import emptySavedDashboards from './empty-saved-dashboards.svg';
import emptyVersion from './empty-version.svg';
import errorPageAccess from './error-page-access.svg';
import errorPageError from './error-page-error.svg';
import errorPageNotFound from './error-page-not-found.svg';
import errorServiceUnavailable from './error-service-unavailable.svg';
import errorSsoAccess from './error-sso-access.svg';
import errorSystemAccess from './error-system-access.svg';
import evibotHappy from './evibot-happy.svg';
import evibotLit from './evibot-lit.svg';
import evibotMeh from './evibot-meh.svg';
import evibotSad from './evibot-sad.svg';
import evibotTrainee from './evibot-trainee.svg';
import loginForgotPassowrd from './login-forgot-password.png';
import loginSignIn from './login-sign-in.png';
import loginWorkspace from './login-workspace.png';
import logoDarkIcon from './logo-dark-icon.png';
import logoDark from './logo-dark.png';
import logoLightIcon from './logo-light-icon.png';
import logoLight from './logo-light.png';
import previewFieldMultipleSelect from './preview-field-multiple-select.svg';
import previewFieldSingleSelect from './preview-field-single-select.svg';

export const images = {
  'chart-empty': chartEmpty,
  'chart-error': chartError,
  'chart-too-much-data': chartTooMuchData,
  'document-faq': documentFaq,
  'empty-no-alerts': emptyNoAlerts,
  'empty-no-documents': emptyNoDocuments,
  'empty-no-groups': emptyNoGroups,
  'empty-no-search': emptyNoSearch,
  'empty-saved-dashboards': emptySavedDashboards,
  'empty-version': emptyVersion,
  'error-page-access': errorPageAccess,
  'error-page-error': errorPageError,
  'error-page-not-found': errorPageNotFound,
  'error-service-unavailable': errorServiceUnavailable,
  'error-sso-access': errorSsoAccess,
  'error-system-access': errorSystemAccess,
  'evibot-happy': evibotHappy,
  'evibot-lit': evibotLit,
  'evibot-meh': evibotMeh,
  'evibot-sad': evibotSad,
  'evibot-trainee': evibotTrainee,
  'login-forgot-password': loginForgotPassowrd,
  'login-sign-in': loginSignIn,
  'login-workspace': loginWorkspace,
  'logo-dark': logoDark,
  'logo-dark-icon': logoDarkIcon,
  'logo-light': logoLight,
  'logo-light-icon': logoLightIcon,
  'preview-field-multiple-select': previewFieldMultipleSelect,
  'preview-field-single-select': previewFieldSingleSelect,
};
