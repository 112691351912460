import React, { memo } from 'react';

import { BaseStepsProps } from '../../types';
import { Divider } from '../Divider';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { Bar } from './Bar';
import { Pill } from './Pill';
import { getStepState } from './utils';

interface Props<V = unknown> extends BaseStepsProps<V> {
  /** The step label to adopt if steps doesn't provide one. */
  defaultStepLabel?: string;
  /** Indicate if all steps are completed */
  isCompleted?: boolean;
  /** Indicate if the entity was cancelled */
  isCancelled?: boolean;
  /** UI mode */
  mode?: 'concise' | 'full' | 'text';
}

export const Steps = memo(
  ({
    currentStepValue,
    defaultStepLabel,
    isCompleted = false,
    isCancelled = false,
    mode = 'full',
    steps,
  }: Props) => {
    const currentStepIndex = steps.findIndex(
      (step) => step.value === currentStepValue,
    );
    const currentStepLabel =
      isCancelled || isCompleted
        ? defaultStepLabel
        : steps[currentStepIndex]?.label || defaultStepLabel;

    switch (mode) {
      case 'concise':
        return (
          <Layout direction="column" spacing={1}>
            <Layout spacing={0.5}>
              {steps.map(({ label }, index) => (
                <Bar
                  key={index}
                  label={label}
                  state={getStepState({ currentStepIndex, index, isCompleted })}
                />
              ))}
            </Layout>
            {currentStepLabel && <Text variant="tiny">{currentStepLabel}</Text>}
          </Layout>
        );
      case 'text':
        return (
          <Layout align="center" spacing={3}>
            {steps.map(({ label }, index) => (
              <React.Fragment key={index}>
                <Text
                  variant={currentStepIndex === index ? 'body-bold' : 'body'}
                >
                  {label}
                </Text>
                {index < steps.length - 1 && <Divider width="s" />}
              </React.Fragment>
            ))}
          </Layout>
        );
      case 'full':
      default:
        return (
          <Layout align="center">
            {steps.map(({ label }, index) => (
              <Pill
                key={index}
                isJoined={index > 0}
                label={label}
                state={getStepState({ currentStepIndex, index, isCompleted })}
              />
            ))}
          </Layout>
        );
    }
  },
);
