import PT from 'prop-types';

import WarningIcon from '~/components/Shared/Icons/WarningIcon';
import {
  getFieldColor,
  getFieldTypeIcon,
} from '~/components/Workflow/Workflow.utils';
import { fieldTypeLabels } from '~/constants/workflow';
import { Box, FlexLayout, Icon, Text, Tooltip } from '~/ui';

function getIconColorLabelValues(field, fieldState) {
  switch (fieldState) {
    case 'WARNING': {
      return {
        icon: 'warning',
        iconBg: 'yellow-500',
        iconColor: 'white',
        label: null,
        labelColor: 'gray-600',
        nameColor: 'peach-800',
      };
    }
    case 'ASSIGNED': {
      const fieldColor = getFieldColor(field);
      const fieldIcon = getFieldTypeIcon(field);
      return {
        icon: fieldIcon,
        iconBg: fieldColor,
        iconColor: 'white',
        label: fieldTypeLabels[field.type],
        labelColor: fieldColor,
      };
    }
    default: {
      return {
        icon: 'help',
        iconBg: 'gray-500',
        iconColor: 'gray-200',
        label: 'unassigned',
        labelColor: 'gray-600',
      };
    }
  }
}

function Field({
  assignedLabel,
  disabled = false,
  field,
  isAssigned,
  shouldHideLabel = false,
  warningTooltip,
  errorMessage,
}) {
  let fieldState;
  if (warningTooltip) {
    fieldState = 'WARNING';
  } else if (isAssigned) {
    fieldState = 'ASSIGNED';
  }

  const {
    icon,
    iconBg,
    iconColor,
    label,
    labelColor,
    nameColor,
  } = getIconColorLabelValues(field, fieldState);

  return (
    <Tooltip content={warningTooltip}>
      <FlexLayout justifyContent="space-between">
        <FlexLayout disabled={disabled} alignItems="center" space={4}>
          <Icon
            bg={iconBg}
            color={iconColor}
            icon={icon}
            p={1}
            size="l"
            sx={{ borderRadius: 'm' }}
          />
          <Box>
            <Text color={nameColor} variant="xs-dense-bold">
              {field.name}
            </Text>
            {!shouldHideLabel && (
              <FlexLayout alignItems="center" space={1}>
                <Text color={labelColor} variant="2xs-dense-caps">
                  {assignedLabel || label}
                </Text>
                <Icon color={labelColor} icon="chevronRight" size="2xs" />
              </FlexLayout>
            )}
          </Box>
        </FlexLayout>
        {errorMessage && (
          <Tooltip content={errorMessage}>
            <WarningIcon yellow />
          </Tooltip>
        )}
      </FlexLayout>
    </Tooltip>
  );
}

const fieldPropTypes = PT.shape({
  id: PT.string,
  description: PT.string,
  name: PT.string,
  type: PT.string,
});

Field.propTypes = {
  assignedLabel: PT.string,
  field: fieldPropTypes.isRequired,
  isAssigned: PT.bool,
  shouldHideLabel: PT.bool,
  warningTooltip: PT.string,
};

export default Field;
