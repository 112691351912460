import { saveAs } from 'file-saver';
import React, { useState } from 'react';

import { formatDate, useToast } from '~/eds';
import { BulkImportUserType } from '~/enums';
import { api } from '~/redux';
import { ImportUsersSummaryType } from '~/redux/api/methods';
import { Uuid } from '~/types';

import { BulkImportUserWizard } from '../UsersPage';

type Props = {
  clientId: number;
  onClose: () => void;
  onImport: (importId: Uuid) => void;
};

export const ImportUsersWizard = ({ clientId, onClose, onImport }: Props) => {
  const { toast } = useToast();
  const [importSummaryData, setImportSummaryData] = useState<
    ImportUsersSummaryType | undefined
  >();
  const [importStep, setImportStep] = useState<BulkImportUserType>(
    BulkImportUserType.Upload,
  );
  const [importStepLoading, setImportStepLoading] = useState(false);
  const [
    exportUsersExcelFile,
  ] = api.endpoints.exportUsersExcelFile.useLazyQuery();
  const [
    downloadImportResultsExcelFile,
  ] = api.endpoints.downloadImportResultsExcelFile.useLazyQuery();

  const [
    startImportUsersListProcessing,
  ] = api.endpoints.startImportUsersListProcessing.useMutation();

  const [
    importUsersListExcelFile,
  ] = api.endpoints.importUsersListExcelFile.useMutation();

  const handleUserDownloadClick = async (onlyHeaders: any) => {
    let filename = 'evisort-users-import-template.xlsx';
    if (!onlyHeaders) {
      filename = 'evisort-users-' + formatDate(new Date()) + '.xlsx';
    }

    try {
      const res = await exportUsersExcelFile({
        clientId,
        onlyHeaders,
      }).unwrap();
      toast({
        status: 'success',
        message: 'File downloaded successfully.',
      });
      saveAs((res as unknown) as Blob, filename);
    } catch (e) {
      toast({
        status: 'danger',
        message: 'Error downloading file.',
      });
    }
  };

  const handleImportResultsFileDownloadClick = async (resultType: any) => {
    const importId = importSummaryData?.import_id;
    if (!importId) {
      return;
    }
    try {
      const res = await downloadImportResultsExcelFile({
        clientId,
        importId,
        resultType,
      }).unwrap();
      toast({
        status: 'success',
        message: `${resultType} file downloaded successfully.`,
      });
      const timestamp = formatDate(new Date(), 'iso_datetime');
      saveAs(
        (res as unknown) as Blob,
        `evisort-users-${resultType}-${timestamp}.xlsx`,
      );
    } catch (e) {
      toast({
        status: 'danger',
        message: 'Error downloading file.',
      });
    }
  };

  const handleSelectedImportStep = (importStep: any) => {
    setImportStep(importStep);
  };

  const handleHideImportWizard = () => {
    onClose();
    setImportStep(BulkImportUserType.Upload);
    setImportStepLoading(false);
  };

  const handleUserListUploadClick = async (file: Blob) => {
    setImportStepLoading(true);

    try {
      const response = await importUsersListExcelFile({
        file,
        clientId,
      }).unwrap();
      toast({
        status: 'success',
        message: 'Users List file has been imported successfully.',
      });
      setImportSummaryData(response);
      setImportStep(BulkImportUserType.Summary);
      setImportStepLoading(false);
    } catch (e) {
      toast({
        status: 'danger',
        message: 'Error importing file.',
      });
      setImportStep(BulkImportUserType.Upload);
      setImportStepLoading(false);
    }
  };
  const handleStartImportUserBulkProcessing = async (
    importActions: any,
    hideModalFn: () => void,
  ) => {
    setImportStepLoading(true);
    try {
      if (importSummaryData) {
        await startImportUsersListProcessing({
          client_id: clientId,
          import_id: importSummaryData?.import_id ?? '',
          email_new_users: importActions.emailNewUsers,
          update_existing_users: importActions.updateExistingUsers,
          delete_missing_users: importActions.deleteMissingUsers,
        }).unwrap();

        onImport(importSummaryData.import_id);
        toast({
          status: 'success',
          message:
            'Request to start Import Users List processing successfully queued.',
        });
        handleHideImportWizard();
        hideModalFn();
      }
    } catch (e) {
      toast({
        status: 'danger',
        message: 'Error starting import processing.',
      });
      setImportStepLoading(false);
    }
  };

  return (
    <BulkImportUserWizard
      handleDownloadUserTemplateFile={handleUserDownloadClick}
      handleImportResultsFileDownload={handleImportResultsFileDownloadClick}
      handleSelectedStep={handleSelectedImportStep}
      onClose={handleHideImportWizard}
      importSummaryData={importSummaryData}
      onUploadActionClick={handleUserListUploadClick}
      step={importStep}
      stepLoading={importStepLoading}
      onStartImportUserBulkProcessing={handleStartImportUserBulkProcessing}
    />
  );
};
