import { useEffect } from 'react';

import { Layout } from '~/eds';
import { api } from '~/redux';
import { calcPercentage } from '~/utils';

import { CreditBalanceDisplay } from './CreditBalanceDisplay';
import { CreditSummary, EstimateUnit } from './types';

interface Props {
  // The client id.
  clientId: number;
  // Estimation objected used to get the estimation.
  estimateUnit?: EstimateUnit;
  // CB to get the tooltip for available credits.
  getCreditsTooltip?: (creditSummary: CreditSummary) => string;
  // CB to get the tooltip for estimated credits.
  getEstimatedCreditsTooltip?: (
    credits: number,
    estimatedCredits: number,
  ) => string;
  // CB that runs after we get available credits.
  onCreditsSummaryAvailable?: (creditsSummary: CreditSummary) => void;
  /** Optionally hide visibility of credits available */
  enableCreditsAvailable?: boolean;
}

export const CreditBalance = ({
  clientId,
  estimateUnit,
  enableCreditsAvailable = true,
  getCreditsTooltip,
  getEstimatedCreditsTooltip,
  onCreditsSummaryAvailable,
}: Props) => {
  const {
    data: creditSummary = {
      credits: 0,
      recurringCredits: { credits: 0, renewalDate: new Date(), notes: '' },
      totalCredits: 0,
    },
    isLoading: isCreditSummaryLoading,
    isError: isCreditSummaryError,
  } = api.endpoints.getCreditSummary.useQuery(clientId);

  const {
    data: creditEstimation = 0,
    isFetching: isCreditEstimationFetching,
    isError: isCreditEstimationError,
  } = api.endpoints.getCreditEstimation.useQuery(
    { clientId, estimateUnit: estimateUnit! },
    {
      skip: !estimateUnit || estimateUnit.value === 0,
    },
  );

  useEffect(() => {
    // * We don't want to call the callback if we are still loading.
    if (!isCreditSummaryLoading && !isCreditSummaryError) {
      onCreditsSummaryAvailable?.(creditSummary);
    }
  }, [creditSummary]);

  const isEstimationLoading =
    isCreditEstimationFetching ||
    isCreditSummaryLoading ||
    Boolean(estimateUnit?.isLoadingUnitValue);

  const creditsUsed = creditSummary?.recurringCredits
    ? calcPercentage(
        creditSummary.recurringCredits.credits - creditSummary.credits,
        creditSummary.recurringCredits.credits,
      )
    : 0;

  const displayEstimation = Boolean(estimateUnit);
  const displayCreditsAvailable = enableCreditsAvailable;

  const estimation = estimateUnit?.value === 0 ? 0 : creditEstimation;

  const estimationStatus =
    estimation > creditSummary.credits ? 'danger' : 'active';

  return (
    <Layout align="center" spacing={6}>
      {displayEstimation && (
        <CreditBalanceDisplay
          credits={estimateUnit?.value === 0 ? 0 : estimation}
          isError={isCreditEstimationError}
          isLoading={isEstimationLoading}
          status={estimateUnit?.isUninitialized ? 'active' : estimationStatus}
          title="Estimated Credits"
          tooltip={
            isEstimationLoading
              ? ''
              : getEstimatedCreditsTooltip?.(
                  creditSummary.credits,
                  estimation,
                ) ?? ''
          }
        />
      )}
      {displayCreditsAvailable && (
        <CreditBalanceDisplay
          credits={creditSummary.credits}
          isError={isCreditSummaryError}
          isLoading={isCreditSummaryLoading}
          status={
            creditSummary.credits <= 0 || (creditsUsed && creditsUsed >= 90)
              ? 'danger'
              : 'success'
          }
          title="Credits Available"
          tooltip={
            isCreditSummaryLoading
              ? ''
              : getCreditsTooltip?.(creditSummary) ?? ''
          }
        />
      )}
    </Layout>
  );
};
