import {
  black2,
  bold,
  evisortBlue,
} from '../../../assets/shared-styles/general';

const styles = {
  filterSectionWrapper: {
    borderRadius: '4px',
    padding: (props) => (props.rootSection ? null : '16px'),
    backgroundColor: (props) =>
      props.rootSection ? null : 'rgba(0, 0, 0, 0.04)',
  },
  sectionActions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '24px',
  },
  filterAction: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginRight: '24px',
    },
    '& svg': {
      marginRight: '4px',
    },
    '& span': {
      color: evisortBlue,
      fontSize: '13px',
      fontWeight: bold,
    },
  },
  actionSeparator: {
    flexGrow: 1,
  },
  operatorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '8px auto',
  },
  emptySectionMessage: {
    fontSize: '15px',
    color: black2,
  },
};

export default styles;
