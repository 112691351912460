import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Layout, Link, LoadingShimmer, Text } from '~/eds';
import { FieldInfo } from '~/features/fields';
import { actions, api, selectors } from '~/redux';

import { INFO_LABEL_FLEX, INFO_SPACING, INFO_WIDTH } from '../../constants';
import { DEFAULT_FIELD_VALUE } from '../../fields/constants';
import { FieldValueDisplay } from '../../fields/shared/FieldValue/FieldValueDisplay';
import { testIsTestCaseEvaluating } from '../../fields/utils';
import { PromptModelTestCase } from '../../types';
import { ReviewHighlightSources } from './ReviewHighlightSources';
import { ReviewLlmOutput } from './ReviewLlmOutput';

interface Props {
  testCase: PromptModelTestCase;
}

export const ReviewTestCase = ({ testCase }: Props) => {
  const isLoading = testIsTestCaseEvaluating(testCase);

  const dispatch = useDispatch();

  const documentVersionId = testCase?.entity.id
    ? Number(testCase.entity.id)
    : null;

  // always read source highlights from gold value if populated,
  // otherwise fall back on model value highlights
  const documentHighlights =
    testCase.goldValue?.highlights ?? testCase.modelValue?.highlights;

  const {
    data: document,
    isFetching: isLoadingActiveDocument,
  } = api.endpoints.getDocumentFromVersion.useQuery(documentVersionId!, {
    skip: !documentVersionId,
  });

  const documentViewer = useSelector(selectors.selectDocumentViewer);

  useEffect(() => {
    // only update active highlight if it's not already set, or when the document version changes.
    // this is to prevent the active highlight from changing when tagging and deleting sources.
    if (
      !documentViewer.activeHighlightId ||
      documentVersionId !== documentViewer.documentVersionId
    ) {
      dispatch(
        actions.setFieldAiDocumentViewer({
          activeHighlightId: documentHighlights?.[0]?.id,
          documentVersionId,
          highlights: documentHighlights?.[0] ? [documentHighlights[0]] : [],
        }),
      );
    }
  }, [testCase, documentVersionId, documentHighlights]);

  const fieldValue =
    testCase?.state === 'error'
      ? { value: 'Error, please Retry Evaluation.' }
      : testCase?.modelValue;

  const config = useSelector(selectors.selectFieldAiConfig);
  const isClassificationModel = Boolean(config.fieldClassification);

  return (
    <Layout direction="column" spacing={1}>
      <Layout align="center" spacing={INFO_SPACING}>
        <Text flex={INFO_LABEL_FLEX} variant="body-bold">
          Document
        </Text>
        {document && !isLoadingActiveDocument ? (
          <Link
            enableNewTab
            shouldTruncate
            pathname={`/document/${document.id}`}
          >
            {document.name}
          </Link>
        ) : (
          <LoadingShimmer height="14px" />
        )}
      </Layout>
      <Layout align="center" spacing={INFO_SPACING}>
        <Text flex={INFO_LABEL_FLEX} variant="body-bold">
          Field
        </Text>
        <Box minW={INFO_WIDTH} py={INFO_SPACING}>
          {config?.field && <FieldInfo id={config.field.id} />}
        </Box>
      </Layout>
      <Layout align="flex-start" spacing={INFO_SPACING}>
        <Text flex={INFO_LABEL_FLEX} variant="body-bold">
          {isClassificationModel ? 'Identified Value(s)' : 'Value'}
        </Text>
        {isLoading || typeof fieldValue === 'undefined' ? (
          <LoadingShimmer height="14px" />
        ) : (
          <FieldValueDisplay fieldValue={fieldValue ?? DEFAULT_FIELD_VALUE} />
        )}
      </Layout>
      <ReviewHighlightSources
        documentVersionId={documentVersionId}
        highlights={documentHighlights}
        isLoading={isLoading}
      />
      <ReviewLlmOutput testCase={testCase} />
    </Layout>
  );
};
