import React from 'react';

const UploadFilesIcon = () => {
  return (
    <svg role="img" width="120" height="131" viewBox="0 0 120 131">
      <title>documents graphic</title>
      <defs>
        <linearGradient id="a" x1="49.997%" x2="49.997%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="#B3B3B3" stopOpacity=".25" />
          <stop offset="54%" stopColor="#B3B3B3" stopOpacity=".1" />
          <stop offset="100%" stopColor="#B3B3B3" stopOpacity=".05" />
        </linearGradient>
        <linearGradient id="b" x1="50.006%" x2="50.006%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="#B3B3B3" stopOpacity=".25" />
          <stop offset="54%" stopColor="#B3B3B3" stopOpacity=".1" />
          <stop offset="100%" stopColor="#B3B3B3" stopOpacity=".05" />
        </linearGradient>
        <linearGradient id="c" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="d" x1="49.997%" x2="49.997%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="#B3B3B3" stopOpacity=".4" />
          <stop offset="55.245%" stopColor="#B3B3B3" stopOpacity=".2" />
          <stop offset="100%" stopColor="#B3B3B3" stopOpacity=".05" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="nonzero">
        <path
          fill="#E0E0E0"
          d="M58.943 9.63H82.47v1.79H58.943zM58.943 16.205H82.47v1.79H58.943zM58.943 22.78H82.47v1.79H58.943z"
        />
        <path
          fill="#6C63FF"
          d="M58.943 29.354H82.47v1.79H58.943z"
          opacity=".5"
        />
        <path
          fill="#E0E0E0"
          d="M58.943 35.929H82.47v1.79H58.943zM58.943 42.504H82.47v1.79H58.943z"
        />
        <path
          fill="url(#a)"
          d="M68.242 105.688c0-8.508 8.424-16.352 16.956-16.352 1.307 0 2.575-.328 3.792-.026a173.2 173.2 0 0 0 .026-2.929V0H15.165L0 15.533v100.51h69.725c-1.5-2.374-1.483-7.34-1.483-10.355z"
        />
        <path
          fill="#FCFCFC"
          d="M66.923 107.331c0-10.62 8.635-19.23 19.286-19.23.582 0 1.154.027 1.727.077v-44.59H1.398v70.482H68.14a19.14 19.14 0 0 1-1.218-6.739z"
        />
        <path
          fill="#FCFCFC"
          d="M87.872 44.246H1.139V15.533L15.165.904h72.707z"
        />
        <path
          fill="#007FBA"
          d="M13.965 56.475h23.527v1.79H13.965z"
          opacity=".5"
        />
        <path fill="#E0E0E0" d="M13.965 63.631h23.527v1.79H13.965z" />
        <path
          fill="#1DDB6E"
          d="M13.965 60.053h61.41v1.79h-61.41z"
          opacity=".5"
        />
        <path
          fill="#007FBA"
          d="M13.965 73.77h23.527v1.79H13.965z"
          opacity=".5"
        />
        <path
          fill="#E0E0E0"
          d="M13.965 80.93h23.527v1.789H13.965zM13.965 77.35h61.41v1.79h-61.41z"
        />
        <path
          fill="#007FBA"
          d="M13.965 91.069h23.527v1.79H13.965z"
          opacity=".5"
        />
        <path
          fill="#E0E0E0"
          d="M13.965 98.225h23.527v1.79H13.965zM13.965 94.647h50.342v1.79H13.965z"
        />
        <path fill="#D4D4D4" d="M51.099 46.187H8.242V8.876l1.483-1.808H51.1z" />
        <path fill="url(#b)" d="M.81 15.861h14.685V.575z" />
        <path fill="#F5F5F5" d="M1.139 15.533h14.026V.904z" />
        <ellipse
          cx="16.627"
          cy="16.478"
          fill="url(#c)"
          opacity=".5"
          rx="16.505"
          ry="16.458"
          transform="translate(86.868 97.962)"
        />
        <ellipse
          cx="103.495"
          cy="114.44"
          fill="#D4D4D4"
          rx="15.448"
          ry="15.404"
        />
        <path
          fill="url(#c)"
          d="M16.72 6.566h-6.474V.112H6.524v6.454H.05v3.713h6.475v6.457h3.722v-6.457h6.475z"
          opacity=".5"
          transform="translate(95.11 106.016)"
        />
        <path fill="#FFF" d="M101.913 107.374h3.164v14.13h-3.164z" />
        <path fill="#FFF" d="M110.561 112.858v3.165h-14.13v-3.165z" />
        <path
          fill="url(#d)"
          d="M85.55 113.084c0-8.508 8.424-16.351 16.956-16.351 1.307 0 2.575-.329 3.791-.026.017-.372.027-2.555.027-2.93V7.398H32.473L17.308 22.928v100.51h69.725c-1.5-2.373-1.484-7.34-1.484-10.355z"
        />
        <path
          fill="#FFF"
          d="M84.23 114.728c0-10.621 8.635-19.231 19.286-19.231.582 0 1.154.028 1.728.077v-44.59H18.705v70.483H85.45a19.14 19.14 0 0 1-1.218-6.74z"
        />
        <path
          fill="#FFF"
          d="M105.18 51.642H18.447V22.93L32.473 8.301h72.707z"
        />
        <path
          fill="#E0E0E0"
          d="M76.251 17.027h23.527v1.79H76.251zM76.251 23.601h23.527v1.79H76.251zM76.251 30.176h23.527v1.79H76.251z"
        />
        <path
          fill="#007FBA"
          d="M76.251 36.75h23.527v1.79H76.251z"
          opacity=".5"
        />
        <path
          fill="#E0E0E0"
          d="M76.251 43.325h23.527v1.79H76.251zM76.251 49.9h23.527v1.79H76.251z"
        />
        <path
          fill="#007FBA"
          d="M31.273 63.871h23.526v1.79H31.273z"
          opacity=".5"
        />
        <path fill="#E0E0E0" d="M31.273 71.028h23.526v1.79H31.273z" />
        <path
          fill="#1DDB6E"
          d="M31.273 67.45h61.41v1.79h-61.41z"
          opacity=".5"
        />
        <path
          fill="#007FBA"
          d="M31.273 81.167h23.526v1.79H31.273z"
          opacity=".5"
        />
        <path
          fill="#E0E0E0"
          d="M31.273 88.326h23.526v1.79H31.273zM31.273 84.746h61.41v1.79h-61.41z"
        />
        <path
          fill="#007FBA"
          d="M31.273 98.465h23.526v1.79H31.273z"
          opacity=".5"
        />
        <path
          fill="#E0E0E0"
          d="M31.273 105.622h23.526v1.79H31.273zM31.273 102.044h50.342v1.79H31.273z"
        />
        <path
          fill="#D4D4D4"
          d="M68.407 53.583H25.549v-37.31l1.484-1.809h41.374z"
        />
        <path fill="url(#b)" d="M18.117 23.258h14.685V7.972z" />
        <path fill="#FCFCFC" d="M18.447 22.93h14.026V8.3z" />
      </g>
    </svg>
  );
};

export default UploadFilesIcon;
