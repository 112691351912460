import {
  black1,
  black2,
  black3,
  bold,
  semibold,
} from '~/assets/shared-styles/general';

const border = '1px solid rgba(0, 0, 0, 0.2)';

const styles = {
  searchInput: {
    width: '480px',
    marginBottom: '24px',
  },
  tableHeaderCell: {
    padding: '8px 16px',
    backgroundColor: 'transparent',
    border: 'none',
    boxSizing: 'border-box',
    minWidth: '50px',

    '&:not(:last-child)': {
      borderRight: border,
    },
  },
  headerCellContent: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  headerCellLabel: {
    marginRight: '4px',
    color: black3,
    fontSize: '12px',
    fontWeight: semibold,
    textTransform: 'uppercase',
    lineHeight: '19px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerCellIcon: {
    flexShrink: 0,
  },
  headerCellResizer: {
    width: '10px',
    position: 'relative',
  },
  tableCell: {
    padding: '0 16px',
    fontSize: '15px',
    color: black1,
  },
  cellContent: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    lineHeight: '24px',
    whiteSpace: 'pre-line',
  },
  tableRow: {
    minWidth: '100%',
    padding: '12px 0',
    borderTop: border,
  },
  tableEmptyState: {
    borderTop: border,
    padding: '32px 0',
    color: black2,
    fontSize: '14px',
    fontWeight: bold,
    textAlign: 'center',
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '24px',
  },
};

export default styles;
