import { FieldData } from './types';

export const validateFieldData = (fieldData: FieldData): string | undefined => {
  const { fieldName, fieldSectionId, fieldType } = fieldData;
  if (!fieldName) {
    return 'Field name is required.';
  }
  if (!fieldSectionId) {
    return 'Field section is required.';
  }
  if (!fieldType) {
    return 'Field type is required.';
  }
};

export const testEnableSort = (
  optionsConfig: FieldData['fieldConfig']['optionsConfig'],
): boolean => {
  return optionsConfig.includes('enable_sort');
};
