export default `
<svg
  aria-hidden="true"
  aria-label="bookmark-filled"
  width="10"
  height="16"
  viewBox="0 0 10 16"
  fill="none"
>
  <path
    d="M0 1.49951V15.2401C0 15.6589 0.283854 15.9995 0.632812 15.9995C0.763021 15.9995 0.890625 15.9526 0.997396 15.862L5 12.4995L9.0026 15.862C9.10938 15.9526 9.23698 15.9995 9.36719 15.9995C9.71615 15.9995 10 15.6589 10 15.2401V1.49951C10 0.671387 9.4401 -0.000488281 8.75 -0.000488281H1.25C0.559896 -0.000488281 0 0.671387 0 1.49951Z"
    fill="currentcolor"
  />
</svg>
`;
