export default `
<svg
  aria-hidden="true"
  aria-label="status-new-gradient"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M9.37496 3.46029C9.401 3.38216 9.47913 3.33008 9.58329 3.33008C9.66142 3.33008 9.73954 3.38216 9.76558 3.46029L10.1302 4.44987L11.1198 4.81445C11.1979 4.84049 11.25 4.91862 11.25 4.99674C11.25 5.10091 11.1979 5.17904 11.1198 5.20508L10.1302 5.56966L9.76558 6.5332C9.73954 6.61133 9.66142 6.66341 9.58329 6.66341C9.47913 6.66341 9.401 6.61133 9.37496 6.5332L9.01038 5.56966L8.02079 5.20508C7.94267 5.17904 7.91663 5.10091 7.91663 4.99674C7.91663 4.91862 7.94267 4.84049 8.02079 4.81445L9.01038 4.44987L9.37496 3.46029ZM15.8073 3.7207L16.6927 4.60612C17.1875 5.10091 17.1875 5.88216 16.6927 6.37695L6.77079 16.2988C6.276 16.7936 5.49475 16.7936 4.99996 16.2988L4.11454 15.4134C3.61975 14.9186 3.61975 14.1374 4.11454 13.6426L14.0364 3.7207C14.5312 3.22591 15.3125 3.22591 15.8073 3.7207ZM14.9218 4.58008L12.0573 7.44466L12.9687 8.35612L15.8073 5.49154L14.9218 4.58008ZM5.88538 15.4134L12.0833 9.24154L11.1718 8.33008L4.99996 14.528L5.88538 15.4134ZM3.09892 6.40299L4.58329 5.83008L5.13017 4.37174C5.15621 4.24154 5.28642 4.16341 5.41663 4.16341C5.52079 4.16341 5.651 4.24154 5.67704 4.37174L6.24996 5.83008L7.70829 6.40299C7.8385 6.42904 7.91663 6.55924 7.91663 6.66341C7.91663 6.79362 7.8385 6.92383 7.70829 6.94987L6.24996 7.49674L5.67704 8.98112C5.651 9.08529 5.52079 9.16341 5.41663 9.16341C5.28642 9.16341 5.15621 9.08529 5.13017 8.98112L4.58329 7.49674L3.09892 6.94987C2.96871 6.92383 2.91663 6.79362 2.91663 6.66341C2.91663 6.55924 2.96871 6.42904 3.09892 6.40299ZM12.2656 13.0697L13.75 12.4967L14.2968 11.0384C14.3229 10.9082 14.4531 10.8301 14.5833 10.8301C14.6875 10.8301 14.8177 10.9082 14.8437 11.0384L15.4166 12.4967L16.875 13.0697C17.0052 13.0957 17.0833 13.2259 17.0833 13.3301C17.0833 13.4603 17.0052 13.5905 16.875 13.6165L15.4166 14.1634L14.8437 15.6478C14.8177 15.752 14.6875 15.8301 14.5833 15.8301C14.4531 15.8301 14.3229 15.752 14.2968 15.6478L13.75 14.1634L12.2656 13.6165C12.1354 13.5905 12.0833 13.4603 12.0833 13.3301C12.0833 13.2259 12.1354 13.0957 12.2656 13.0697Z"
    fill="url(#paint0_linear_3982_305)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_3982_305"
      x1="9.99996"
      y1="3.33008"
      x2="9.99996"
      y2="16.6699"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#7413FF" />
      <stop offset="1" stop-color="#4136FF" />
    </linearGradient>
  </defs>
</svg>

`;
