import React, { memo } from 'react';

import { TextVariant, Theme } from '../../types';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Text } from '../Text';

interface Props {
  /** Children */
  children?: React.ReactNode;
  /** Input ID for labeled control */
  htmlFor?: string;
  /** Information that will be displayed in info icon tooltip */
  info?: string;
  /** HTML element ID */
  id?: string;
  /** Dark / Light theme */
  theme?: Theme;
  /** Variant */
  variant?: TextVariant;
}

export const Label = memo(
  ({ children, htmlFor, id, info, theme, variant = 'body-bold' }: Props) => {
    const label = (
      <Text
        as="label"
        color={theme === 'dark' ? 'inverse.text.primary' : 'text.primary'}
        htmlFor={htmlFor}
        id={id}
        variant={variant}
      >
        {children}
      </Text>
    );

    if (info) {
      return (
        <Layout align="center">
          {label}
          {info && <Icon icon="info" tooltip={info} />}
        </Layout>
      );
    }

    return label;
  },
);
