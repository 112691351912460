import React, { useState } from 'react';

import { Box, Popover, styles } from '~/ui';

import Content from './Content';

function FieldAssignmentMenu({
  fieldId,
  isVisible: initialIsVisible = false,
  trigger,
  onFieldAssign,
  onHide,
}) {
  const [isActive, setIsActive] = useState(initialIsVisible);
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  function handleMouseEnter() {
    setIsActive(true);
  }
  function handleHide() {
    setIsActive(false);
    setIsVisible(false);
    onHide && onHide();
  }
  function handleShow() {
    setIsVisible(true);
  }
  return (
    <Box
      sx={{ flexGrow: 1 }}
      onMouseEnter={handleMouseEnter}
      onClick={handleShow}
    >
      {isActive && isVisible ? (
        <Popover
          content={
            <Content
              fieldId={fieldId}
              onFieldAssign={onFieldAssign}
              onHide={handleHide}
            />
          }
          isVisible={isVisible}
          trigger={trigger}
          triggerStyle={styles.layout.stretch}
          onHide={handleHide}
          onShow={handleShow}
        />
      ) : (
        trigger
      )}
    </Box>
  );
}
export default FieldAssignmentMenu;
