const styles = {
  signingPhase: {
    margin: '24px 0',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  phaseSubsection: {
    '&:not(:last-child)': {
      marginBottom: '24px',
    },
  },
};

export default styles;
