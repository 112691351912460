import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { FeatureFlagType } from '~/enums';
import { testHasFlag } from '~/permissions';
import { HtmlContent } from '~/ui';

import EcCard from '../../Shared/EcCard';
import EcProvisionsTable from '../../Shared/EcProvisionsTable';
import EcTable from '../../Shared/EcTable/index';
import ClausesTable from './ClausesTable';
import styles from './DocumentKeyProvisions.styles';
import MigratedProvisions from './MigratedProvisions';

const keyProvisionsCardContentStyles = { display: 'flex', padding: '16px' };
const tableCardContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
};

class DocumentKeyProvisions extends Component {
  renderRebateTables() {
    const { rebateTables, handleTableUpdate, handleTableDelete } = this.props;

    if (!rebateTables || !rebateTables.length) return null;

    return (
      <Fragment>
        {rebateTables.map((table, index) => (
          <EcTable
            key={`documentPanelItem_${index}`}
            tableId={table.id}
            tableName={table.name}
            tableContent={table.html}
            handleTableUpdate={handleTableUpdate}
            handleTableDelete={handleTableDelete}
            allowRowChanges
            allowColumnChanges
            allowCellChanges
          />
        ))}
      </Fragment>
    );
  }

  renderPaymentTables() {
    const { classes, paymentTables, keyProvisions } = this.props;

    if (!paymentTables || !paymentTables.length) return null;

    return (
      <div className={classes.paymentTablesContent}>
        <EcCard title="Payment Tables" contentStyles={tableCardContentStyles}>
          <HtmlContent className={classes.tablesItem} html={paymentTables} />
        </EcCard>
        {keyProvisions && keyProvisions.length ? (
          <div className={classes.horizontalSeparator} />
        ) : null}
      </div>
    );
  }

  renderMigratedProvisions = () => {
    const { documentId } = this.props;
    return <MigratedProvisions documentId={Number(documentId)} />;
  };

  renderKeyProvisions() {
    const { classes, keyProvisions, clauseIdToScroll } = this.props;

    if (!keyProvisions || !keyProvisions.length) {
      return (
        <EcCard title="Key Provisions">
          <div className={classes.tableEmptyState}>
            <div className={classes.tableEmptyStateTitle}>
              No key provisions found.
            </div>
            <div className={classes.tableEmptyStateMessage}>
              Looks like we couldn’t find any key provisions for this document.
            </div>
          </div>
        </EcCard>
      );
    }

    return (
      <EcCard
        title="Key Provisions"
        contentStyles={keyProvisionsCardContentStyles}
      >
        <EcProvisionsTable
          clauseIdToScroll={clauseIdToScroll}
          provisions={keyProvisions}
        />
      </EcCard>
    );
  }

  renderClausesTable = () => {
    const { documentId, clauseIdToScroll } = this.props;
    return (
      <ClausesTable
        handlerId={documentId}
        clauseIdToScroll={clauseIdToScroll}
      />
    );
  };

  render() {
    const { user } = this.props;
    const hasEditClauseFlag = testHasFlag(FeatureFlagType.EditClause)(user);
    return (
      <Fragment>
        {this.renderRebateTables()}
        {this.renderPaymentTables()}
        {hasEditClauseFlag ? (
          this.renderClausesTable()
        ) : (
          <>
            {this.renderMigratedProvisions()}
            {this.renderKeyProvisions()}
          </>
        )}
      </Fragment>
    );
  }
}

DocumentKeyProvisions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(DocumentKeyProvisions);
