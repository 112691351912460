import { TestNamespaceType } from '~/enums';

import { createTestId } from './createTestId';

export const EMAIL_MODAL_TO_INPUT_TESTID = createTestId({
  namespace: TestNamespaceType.EmailModal,
  block: 'email-to-input-field',
});

export const EMAIL_MODAL_CC_INPUT_TESTID = createTestId({
  namespace: TestNamespaceType.EmailModal,
  block: 'email-cc-input-field',
});

export const EMAIL_MODAL_SUBJECT_INPUT_TESTID = createTestId({
  namespace: TestNamespaceType.EmailModal,
  block: 'subject-input-field',
});

export const EMAIL_MODAL_BODY_INPUT_TESTID = createTestId({
  namespace: TestNamespaceType.EmailModal,
  block: 'body-input-field',
});

export const EMAIL_MODAL_ATTACHMENT_INPUT_TESTID = createTestId({
  namespace: TestNamespaceType.EmailModal,
  block: 'attachment-input-field',
});
