import React from 'react';
import { connect } from 'react-redux';

import { AccountInformation, ChangePassword } from '~/components/SettingsPage';
import { Layout, PageLayout } from '~/eds';
import { actions } from '~/redux';

function Page({ currentUser, setCurrentUser }) {
  return (
    <PageLayout
      title="Settings"
      loadingContent={{ isLoading: !Object.keys(currentUser).length }}
      children={
        <Layout preset="sections">
          <AccountInformation
            user={currentUser}
            setCurrentUser={setCurrentUser}
          />
          <ChangePassword />
        </Layout>
      }
    />
  );
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

export default connect(mapStateToProps, {
  setCurrentUser: actions.setCurrentUser,
})(Page);
