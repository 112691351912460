import React from 'react';

import { getSections } from '~/reducers/intakeForm';
import { Box, FlexLayout, Text } from '~/ui';
import { hasEnabledFields } from '~/utils/intakeForm';

const SIDEBAR_WIDTH = '280px';

const TicketFormSidebar = ({
  activeSectionId,
  onSidebarSectionClick,
  intakeForm,
}) => {
  const sections = getSections(intakeForm).filter((section) =>
    hasEnabledFields(intakeForm, section),
  );

  return (
    <>
      <FlexLayout
        flexDirection="column"
        space={6}
        sx={{ position: 'absolute', width: SIDEBAR_WIDTH }}
      >
        <FlexLayout flexDirection="column" space={4}>
          <Text color="gray-600" variant="body2">
            SECTIONS
          </Text>
          <Box>
            {sections.map((section) => {
              const { id: sectionId, name: sectionName } = section;
              const indicatorColor =
                activeSectionId === sectionId ? 'blue-500' : 'blue-100';
              return (
                <FlexLayout
                  alignItems="center"
                  key={sectionId}
                  space={4}
                  onClick={() => onSidebarSectionClick(sectionId)}
                >
                  <Box bg={indicatorColor} py={5} px="2px" />
                  <Text variant="body1" sx={{ wordBreak: 'break-word' }}>
                    {sectionName}
                  </Text>
                </FlexLayout>
              );
            })}
          </Box>
        </FlexLayout>
      </FlexLayout>
      <Box sx={{ width: SIDEBAR_WIDTH, flexShrink: 0 }} />
    </>
  );
};

export { TicketFormSidebar };
