import React from 'react';

import { PageLayout } from '~/eds';
import { OnboardingIdType } from '~/features/onboarding';

const Page = () => {
  const onboardingIds = [OnboardingIdType.OnboardingGuide];
  return <PageLayout title="Onboarding" welcome={{ onboardingIds }} />;
};

export default Page;
