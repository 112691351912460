import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';

import { showToast } from '~/components/Shared/EcToast';
import { BULK_DELETE_LIMIT } from '~/constants/max_lengths';
import { ContentContainer, Layout, Modal, Text, TextArea } from '~/eds';
import { HttpStatusCodeType } from '~/enums';
import { DocumentsCountPayload } from '~/redux/api/methods';
import { ApiError } from '~/types';
import { ERROR } from '~/types/toast.types';
import { RadioGroup } from '~/ui';

import LimitExceededModal from '../LimitExceededModal/LimitExceededModal';

const CHAR_LIMIT = 100;

interface DeleteDocumentWithReasonModalProps {
  type: string;
  isVisible: boolean;
  count: number;
  selectedItemName?: string;
  fetchDocumentsCount?: () => Promise<DocumentsCountPayload>;
  hideModal: () => void;
  onConfirmDelete: (...args: any[]) => void;
}

const DeleteDocumentWithReasonModal = ({
  type,
  isVisible,
  count,
  selectedItemName = '',
  fetchDocumentsCount,
  hideModal,
  onConfirmDelete,
}: DeleteDocumentWithReasonModalProps) => {
  const options = [
    {
      label: 'Duplicate',
      value: 'duplicate',
      selected: false,
    },
    {
      label: 'Added by mistake',
      value: 'mistake',
      selected: false,
    },
    {
      label: 'Other',
      value: 'other',
      selected: false,
    },
  ];

  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteComment, setDeleteComment] = useState('');
  const [documentCount, setDocumentCount] = useState(count);
  const [overLimit, setOverLimit] = useState(
    (documentCount ?? count) > BULK_DELETE_LIMIT,
  );
  const [isLoaded, setIsLoaded] = useState(
    !fetchDocumentsCount || count > BULK_DELETE_LIMIT,
  );

  const onFetchDocumentCountError = (error: ApiError) => {
    if (error?.response?.status === HttpStatusCodeType.Forbidden) {
      showToast(
        ERROR,
        'Sorry, the action cannot be performed because you do not have edit access to one of the selected folders.',
      );
      handleHide();
    } else if (!overLimit) {
      setIsLoaded(true);
    } else {
      showToast(ERROR, 'An error occurred. Please try again.');
      handleHide();
    }
  };

  useEffect(() => {
    const fetchCount = async () => {
      let document_count = 0;
      if (!!fetchDocumentsCount) {
        ({ document_count } = await fetchDocumentsCount());
      }
      if (document_count) {
        setDocumentCount(document_count);
        setOverLimit(document_count > BULK_DELETE_LIMIT);
      }
      setIsLoaded(true);
    };
    if (!isLoaded) {
      fetchCount().catch(onFetchDocumentCountError);
    }
  }, [count]);

  const handleCommentChange = (comment: string | null): void => {
    if (!comment) setDeleteComment('');
    if (comment && comment?.length <= CHAR_LIMIT) {
      setDeleteComment(comment.slice(0, CHAR_LIMIT));
    }
  };

  const handleHide = () => {
    setDocumentCount(0);
    setOverLimit(false);
    setIsLoaded(false);
    setDeleteReason(null);
    setDeleteComment('');
    hideModal();
  };

  const handleOnConfirm = () => {
    onConfirmDelete(deleteReason, deleteComment.trim());
    handleHide();
  };

  // 1. must choose a reason, 2. if reason is 'other', must provide details
  const deleteDisabled =
    !deleteReason || (deleteReason === 'other' && !deleteComment.trim());

  const label =
    selectedItemName || `${count} ${pluralize(type || 'item', count)}`;
  const multiple = count > 1;

  let content = (
    <Modal
      title={`Delete ${label}?`}
      primaryAction={{
        variant: 'danger',
        icon: 'trash',
        onClick: handleOnConfirm,
        text: 'Delete',
        disabled: deleteDisabled,
      }}
      isVisible={isVisible}
      onHide={handleHide}
      onCancel={handleHide}
    >
      <Layout direction="column" spacing={4}>
        <Text styles={{ 'overflow-wrap': 'break-word' }}>
          <span>{label}</span> will be removed from your document page. Are you
          sure you want to remove the
          {` ${type}${multiple ? 's' : ''}`}
          {type !== 'document'
            ? ` and all of ${multiple ? 'their' : 'its'} contents`
            : ''}
          ?
        </Text>
        <Layout direction="column" spacing={2}>
          <Text variant="body-medium">
            Please put in a reason why you want to remove
            {multiple ? ' these ' : ' this '} {type}
            {multiple ? 's' : ''}:
          </Text>
          <RadioGroup
            options={options}
            value={deleteReason}
            onChange={setDeleteReason}
          />
        </Layout>
        <div>
          <Layout direction="column" spacing={2}>
            <Text variant="body-medium">
              Tell us more{' '}
              {deleteReason === 'other' ? '(Required)' : '(Optional)'}
            </Text>
            <TextArea
              placeholder="Your reason"
              value={deleteComment}
              onChange={handleCommentChange}
              name="reason"
            />
          </Layout>
          <Text variant="tiny" color="text.secondary">
            {deleteComment.length} / {CHAR_LIMIT} characters
          </Text>
        </div>
      </Layout>
    </Modal>
  );
  if (!isLoaded) {
    content = (
      <Modal title="" isVisible={isVisible} onCancel={null}>
        <ContentContainer
          loadingContent={{
            isLoading: true,
          }}
        />
      </Modal>
    );
  } else if (overLimit) {
    content = (
      <LimitExceededModal
        actionType="delete"
        count={documentCount}
        limit={BULK_DELETE_LIMIT}
        isVisible={isVisible}
        hideModal={handleHide}
      />
    );
  }

  return content;
};

export default DeleteDocumentWithReasonModal;
