import { createActions } from '../utils/redux';

export const {
  dropzoneEnqueueUploadingFile,
  dropzoneUpdateUploadingProgress,
  dropzoneRemoveUploadedFiles,
} = createActions('dropzone', [
  'enqueueUploadingFile',
  'updateUploadingProgress',
  'removeUploadedFiles',
]);
