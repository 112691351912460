import { FieldModel } from '~/features/x-ray/fields';
import { sylvanus } from '~/services';
import { PilotId, Uuid } from '~/types';

import { PromptModelVersion } from '../../types/sylvanus/model';
import { getFilters } from '../searchV3';
import { mapModelVersionToFieldModel } from './transformers';

export const DEPRECATED_getPromptModelVersion = async ({
  modelId,
  version,
  fieldId, // TODO: remove this dependency (sylvanus doesn't need it and neither should our transform)
}: {
  modelId: Uuid;
  version: number;
  fieldId?: PilotId;
}): Promise<FieldModel> => {
  const response: PromptModelVersion = await sylvanus.get(
    `/model/${modelId}/version/${version}`,
  );

  const searchFilters = await getFilters();
  return mapModelVersionToFieldModel({
    modelId,
    version: response,
    fieldId,
    searchFilters,
    optimizeState:
      response.optimize?.version_number === response.version_number
        ? response.optimize?.state
        : null,
    publishStatus: null,
  });
};
