import React from 'react';

import { Layout } from '~/eds';

import { LeftPane } from './LeftPane';
import { RightPane } from './RightPane';

export const Contents = () => {
  return (
    <Layout flex="auto" minH={0} mx={-8} mt={-4}>
      <LeftPane />
      <RightPane />
    </Layout>
  );
};
