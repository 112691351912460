import PropTypes from 'prop-types';
import React from 'react';

const ChevronLeftIcon = (props) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      onClick={props.onClick}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 24V0h24v24z" />
        <path
          fill={props.color}
          fillOpacity={props.opacity}
          fillRule="nonzero"
          d="M14.69 16.161a1.1 1.1 0 0 1 0 1.525 1.051 1.051 0 0 1-1.5 0l-4.88-4.929a1.077 1.077 0 0 1 0-1.514l4.88-4.929a1.051 1.051 0 0 1 1.5 0 1.077 1.077 0 0 1 0 1.514l-4.125 4.167 4.124 4.166z"
        />
      </g>
    </svg>
  );
};

ChevronLeftIcon.defaultProps = {
  size: '24',
  color: 'currentColor',
  opacity: '.75',
};

ChevronLeftIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
  onClick: PropTypes.func,
};

export default ChevronLeftIcon;
