import React from 'react';

import { IconButton } from '~/eds';
import { FlexLayout, Text, useHover } from '~/ui';

function ItemPartySigner({ name, onRemove }) {
  const [hoverRef, isHovered] = useHover();

  return (
    <FlexLayout
      alignItems="center"
      bg={isHovered ? 'gray-200' : 'gray-300'}
      justifyContent="space-between"
      px={6}
      py={2}
      ref={hoverRef}
      sx={{ borderRadius: 'm' }}
    >
      <Text color="gray-900" variant="m-spaced-bold">
        {name}
      </Text>
      {isHovered && (
        <IconButton icon="trash" tooltip="remove" onClick={onRemove} />
      )}
    </FlexLayout>
  );
}

export default ItemPartySigner;
