import PropTypes from 'prop-types';
import { Component } from 'react';
import injectSheet from 'react-jss';

import { BULK_EDIT_LIMIT } from '~/constants/max_lengths';
import { formatDate, parseDate } from '~/eds';
import { DataFieldType } from '~/enums';
import { withFlags } from '~/flags';
import { Alert, Box, MultiSelect, Tooltip } from '~/ui';

import { ERROR, SUCCESS } from '../../../types/toast.types';
import EcButton from '../../Shared/EcButton';
import EcDataField from '../../Shared/EcDataField';
import EcDatepicker from '../../Shared/EcDatepicker';
import EcModalCard from '../../Shared/EcModalCard';
import EcRadioButton from '../../Shared/EcRadioButton';
import EcRadioContainer from '../../Shared/EcRadioContainer';
import { showToast } from '../../Shared/EcToast';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import StopIcon from '../../Shared/Icons/StopIcon';
import styles from './MultiEditModal.styles';

class MultiEditModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnAddFieldChange = this.handleOnAddFieldChange.bind(this);
    this.handleOnFieldChange = this.handleOnFieldChange.bind(this);
    this.handleApplyChangesClick = this.handleApplyChangesClick.bind(this);

    this.state = {
      fieldOptions: [],
      fields: [],
      loading: true,
      overServerLimit: false,
    };
  }

  async componentDidMount() {
    const {
      getModalData,
      hideModal,
      fetchDocumentsCount,
      overLimit,
    } = this.props;
    const defaultFetchDocuments = () => Promise.resolve({});
    const fetchCount =
      fetchDocumentsCount && !overLimit
        ? fetchDocumentsCount
        : defaultFetchDocuments;
    try {
      const [{ fields }, { document_count }] = await Promise.all([
        getModalData(),
        fetchCount(),
      ]);
      const editableFields = fields.filter((field) => field.is_editable);
      this.setState({
        loading: false,
        fieldOptions: editableFields,
        overServerLimit: document_count > BULK_EDIT_LIMIT,
      });
    } catch (e) {
      showToast(ERROR, 'Something went wrong with editing fields.');
      hideModal();
    }
  }

  static processBulkData(data) {
    return data.map((f) => {
      let value;
      if (Array.isArray(f.value)) {
        value = f.value.map((v) => v.value);
      } else if (f.value && f.value.hasOwnProperty('value')) {
        value = f.value.value;
      } else {
        value = f.value;
      }

      return {
        id: f.id,
        document_ids: f.document_ids,
        keep_old_values: f.keep_old_values,
        value,
      };
    });
  }

  generateNewFields(fieldId, valueObject) {
    const fieldsCopy = [...this.state.fields];
    const currentIndex = fieldsCopy.findIndex((f) => f.id === Number(fieldId));
    fieldsCopy[currentIndex] = { ...fieldsCopy[currentIndex], ...valueObject };

    return fieldsCopy;
  }

  handleOnAddFieldChange(labels) {
    const fields = labels.map((label) => {
      const { fields, fieldOptions } = this.state;
      return [...fields, ...fieldOptions].find((opt) => opt.label === label);
    });

    this.setState({ fields: fields }, () => {
      if (this.state.fields.length > 1) {
        document
          .getElementById([...this.state.fields].pop().id)
          .scrollIntoView();
      }
    });
  }

  handleOnFieldChange(newState) {
    const editedFieldId = Object.keys(newState)[0];
    const fields = this.generateNewFields(editedFieldId, {
      value: newState[editedFieldId],
    });

    this.setState({ fields });
  }

  handleOnRemoveClick({ id }) {
    const newFields = this.state.fields.filter((field) => field.id !== id);
    this.setState({ fields: newFields });
  }

  handleDateChange(date, fieldId) {
    const fields = this.generateNewFields(fieldId, { value: date });
    this.setState({ fields });
  }

  handleApplyChangesClick() {
    const { update, onUpdateComplete, hideModal } = this.props;
    const { fields } = this.state;

    const bulkData = MultiEditModal.processBulkData(fields);

    this.setState({ loading: true });
    update(bulkData)
      .then((response) => {
        onUpdateComplete();
        showToast(
          SUCCESS,
          (response && response.detail) ?? 'Documents successfully edited.',
        );
        hideModal();
      })
      .catch((error) => {
        const genericMessage = 'Something went wrong with editing fields.';
        const errorMessage = error?.response?.data?.error || genericMessage;
        showToast(ERROR, errorMessage);
        hideModal();
      });
  }

  onCalendarOpenClose = (open) => {
    this.props.disableEscapeKey(open);
  };

  renderExtraFieldOptions(field) {
    const { classes } = this.props;

    return (
      <EcRadioContainer
        groupName={`${field.id}_multiple_radio_options`}
        defaultValue={field.keep_old_values}
        onChange={({ selectedValue }) => {
          const fields = this.generateNewFields(field.id, {
            keep_old_values: selectedValue,
          });
          this.setState({ fields });
        }}
      >
        {(groupName, selectedValue, onOptionChange) => (
          <div className={classes.fieldActions}>
            <EcRadioButton
              id={`${field.id}-replace_existing_values`}
              isInline={true}
              groupName={groupName}
              value={false}
              label="Replace existing values"
              onChange={onOptionChange}
              selectedValue={selectedValue}
            />
            <EcRadioButton
              id={`${field.id}-add_existing_values`}
              isInline={true}
              groupName={groupName}
              value={true}
              label="Add to existing values"
              onChange={onOptionChange}
              selectedValue={selectedValue}
            />
          </div>
        )}
      </EcRadioContainer>
    );
  }

  renderField(field) {
    const { classes } = this.props;

    if (field.type === DataFieldType.TEXT_AREA) {
      return (
        <div className={classes.textAreaFieldContainer}>
          <EcDataField
            field={field}
            fieldValue={field.value}
            onFieldChange={this.handleOnFieldChange}
            fieldPlaceholder={`Enter ${field.label}`}
          />
        </div>
      );
    }

    return (
      <div className={classes.fieldContainer}>
        {field.type === DataFieldType.DATE ? (
          <EcDatepicker
            date={field.value ? parseDate(field.value) : null}
            isInModal={true}
            onDateChange={(date) => {
              const newDate = date ? formatDate(date, 'iso_date') : null;
              this.handleDateChange(newDate, field.id);
            }}
            onCalendarOpenClose={this.onCalendarOpenClose}
            placeholder={`Enter ${field.label}`}
            isClearable={field.is_nullable}
          />
        ) : (
          <EcDataField
            field={field}
            fieldValue={field.value}
            onFieldChange={this.handleOnFieldChange}
            fieldPlaceholder={`Enter ${field.label}`}
          />
        )}
      </div>
    );
  }

  renderFieldSection(field) {
    const { classes } = this.props;

    return (
      <div className={classes.fieldSectionWrapper} key={field.id} id={field.id}>
        <div className={classes.fieldSection}>
          <div className={classes.fieldLabel}>
            <span>{field.label}</span>
            <button
              title="Remove field"
              className={classes.iconButton}
              onClick={() => this.handleOnRemoveClick(field)}
            >
              <StopIcon color="rgba(0, 0, 0, 0.5)" />
            </button>
          </div>
          {this.renderField(field)}
          {field.type === DataFieldType.ARRAY_MULTIPLE &&
            this.renderExtraFieldOptions(field)}
        </div>
      </div>
    );
  }

  renderLimitWarning() {
    const { overLimit } = this.props;
    const { overServerLimit } = this.state;

    if (overLimit || overServerLimit) {
      return (
        <Box mb={5}>
          <Alert enableIcon variant="warning">
            Only {BULK_EDIT_LIMIT} documents can be edited at a time. Please
            select fewer documents to complete this action.
          </Alert>
        </Box>
      );
    } else {
      return null;
    }
  }

  render() {
    const { classes, hideModal, overLimit } = this.props;
    const { fields, loading, fieldOptions, overServerLimit } = this.state;
    const fieldOptionsMapped = fieldOptions.map((opt) => ({
      value: opt.label,
      ...opt,
    }));

    const modalTitle = 'Multi Edit';

    const invalidFields = fields
      .filter((f) => !f.value || (Array.isArray(f.value) && !f.value.length))
      .map((f) => f.label);

    return (
      <EcModalCard
        title={modalTitle}
        content={
          <>
            {loading ? (
              <div className={classes.loadingContainer}>
                <LoadingSpinner size="medium" />
              </div>
            ) : (
              <div className={classes.contentContainer}>
                {this.renderLimitWarning()}
                <div className={classes.addFieldSection}>
                  <div className={classes.fieldLabel}>Add Data Fields</div>
                  <div className={classes.fieldDescription}>
                    You can specify which data fields you want to update across
                    the selected document(s).
                  </div>
                  <div className={classes.fieldContainer}>
                    <MultiSelect
                      values={fields.map((f) => f.label)}
                      options={fieldOptionsMapped}
                      width="fullWidth"
                      onChange={this.handleOnAddFieldChange}
                      placeholder="Search for values..."
                      isClearable={false}
                    />
                  </div>
                </div>
                <div className={classes.contentDivider} />
                {fields.map((field) => this.renderFieldSection(field))}
              </div>
            )}
          </>
        }
        footer={
          <>
            <EcButton borderless text="Cancel" onClick={hideModal} />
            <Tooltip
              content={
                invalidFields.length ? (
                  <div>
                    Following fields must be provided:
                    <ul>
                      {invalidFields.map((f) => (
                        <li key={f}>{f}</li>
                      ))}
                    </ul>
                  </div>
                ) : null
              }
            >
              <EcButton
                yellow
                text="Apply Changes"
                onClick={this.handleApplyChangesClick}
                disabled={
                  overLimit ||
                  overServerLimit ||
                  !fields.length ||
                  !!invalidFields.length ||
                  loading
                }
              />
            </Tooltip>
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

MultiEditModal.propTypes = {
  fetchDocumentsCount: PropTypes.func,
  getModalData: PropTypes.func,
  update: PropTypes.func,
  onUpdateComplete: PropTypes.func,
  overLimit: PropTypes.bool,
};

export default injectSheet(styles)(withFlags(MultiEditModal));
