import PropTypes from 'prop-types';
import React from 'react';

const UploadArrowUpIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.color}
          fillRule="nonzero"
          d="M13 19V7.83l4.88 4.88c.39.39 1.03.39 1.42 0a.996.996 0 0 0 0-1.41l-6.59-6.59a.996.996 0 0 0-1.41 0l-6.6 6.58a.996.996 0 1 0 1.41 1.41L11 7.83V19c0 .55.45 1 1 1s1-.45 1-1z"
        />
      </g>
    </svg>
  );
};

UploadArrowUpIcon.defaultProps = {
  size: '24',
  color: '#000',
};

UploadArrowUpIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default UploadArrowUpIcon;
