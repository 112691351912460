import React from 'react';

import { NumberText } from '../../../components';
import { FormatNumberOptions } from '../../../types';

interface Props {
  /** JS `number` */
  number: number;
  /** Formatting options */
  options?: FormatNumberOptions;
}

export const NumberCell = ({ number, options }: Props) => {
  return <NumberText number={number} options={options} />;
};
