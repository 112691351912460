import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BaseSaveView from '~/components/Shared/BaseSaveView';
import { TableViewContextType } from '~/enums';
import { selectors } from '~/redux';
import search from '~/redux/slices/search';

import { getSearchableFields } from '../utils';

const SaveView = () => {
  const modal = useSelector(selectors.selectSearchResultModal);
  const columnOrder = useSelector(selectors.selectSearchColumnOrder);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(search.actions.setModal());
  const { modalType } = modal || {};

  const fields = getSearchableFields(columnOrder);

  return (
    <BaseSaveView
      context={TableViewContextType.SEARCH}
      fields={fields}
      hideModal={hideModal}
      modalType={modalType}
    />
  );
};

export default SaveView;
