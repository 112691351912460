import React from 'react';

import { formatNumber, wordCount } from '../../utils';
import { Text } from '../Text';

interface Props {
  limit: number;
  text: string;
  mode?: 'characters' | 'words';
}

export const CharacterLimit = ({ limit, mode = 'characters', text }: Props) => {
  const count = mode === 'characters' ? text.length : wordCount(text);
  const preset = count > limit ? 'error' : 'description';
  return (
    <Text preset={preset}>
      {formatNumber(count)}/{formatNumber(limit)} {mode}
    </Text>
  );
};
