export default `
<svg
  aria-hidden="true"
  aria-label="format-underline"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M7.11914 5.5332H7.86914V11.5332C7.86914 14.3145 10.0879 16.5332 12.8691 16.5332C15.6191 16.5332 17.8691 14.3145 17.8691 11.5332V5.5332H18.6191C19.0254 5.5332 19.3691 5.2207 19.3691 4.7832C19.3691 4.37695 19.0254 4.0332 18.6191 4.0332H15.6191C15.1816 4.0332 14.8691 4.37695 14.8691 4.7832C14.8691 5.2207 15.1816 5.5332 15.6191 5.5332H16.3691V11.5332C16.3691 13.4707 14.7754 15.0332 12.8691 15.0332C10.9316 15.0332 9.36914 13.4707 9.36914 11.5332V5.5332H10.1191C10.5254 5.5332 10.8691 5.2207 10.8691 4.7832C10.8691 4.37695 10.5254 4.0332 10.1191 4.0332H7.11914C6.68164 4.0332 6.36914 4.37695 6.36914 4.7832C6.36914 5.2207 6.68164 5.5332 7.11914 5.5332ZM19.1191 18.5332H6.61914C6.18164 18.5332 5.86914 18.877 5.86914 19.2832C5.86914 19.7207 6.18164 20.0332 6.61914 20.0332H19.1191C19.5254 20.0332 19.8691 19.7207 19.8691 19.2832C19.8691 18.877 19.5254 18.5332 19.1191 18.5332Z"
    fill="currentcolor"
  />
</svg>
`;
