import { Attempt } from '~/features/webhooks';
import { courier } from '~/services';

interface Request {
  attempt: Attempt;
}

export const archiveAttempt = async ({
  attempt,
}: Request): Promise<boolean> => {
  //TODO: BE - Implement archive attempt API
  const response = await courier.post(`/delivery-attempts/${attempt.id}`);
  return Boolean(response);
};
