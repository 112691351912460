import React, { useEffect, useState } from 'react';

import { useEnter, useEscape, useToggle } from '../../hooks';
import { Input } from '../../types';
import { Button } from '../Button';
import { Label } from '../Label';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

type Props<V, M extends boolean> = {
  /** A valid Input component (e.g. `TextInput`, `NumberInput`, `Select`, `Checkbox`) */
  input: React.ElementType;
  /** Input props */
  inputProps?: Object;
  /** Input label */
  label: string;
  /** Placeholder when field is empty */
  placeholder: string;
  /** Footer text */
  footer?: string;
  /** Callback to check input value and compute footer message */
  getValueMessage: (value: V) => { message: string; status?: 'info' | 'error' };
  /** The value to be displayed */
  value: V;
  /** Callback to save/commit updated value */
  onSave: (updatedValue: V) => void;
} & Input<V, M>;

export const EditableField = <V extends unknown, M extends boolean>({
  input: Input,
  inputProps: overrideInputProps = {},
  label,
  placeholder,
  value,
  onSave,
  getValueMessage,
  error,
}: Props<V, M>) => {
  const [isEditing, _, edit, cancelEdit] = useToggle(false);
  const [draftValue, setDraftValue] = useState(value);

  const { message, status } = getValueMessage(draftValue) || {
    message: error,
    status: '',
  };
  const hasError = !!error || status === 'error';
  const footerColor = hasError ? 'status.danger' : 'text.secondary';

  useEffect(() => setDraftValue(value), [value]);

  const handleCancel = () => {
    cancelEdit();
    setDraftValue(value);
  };

  useEscape(handleCancel, isEditing);

  const handleSave = () => {
    if (!hasError) {
      cancelEdit();
      onSave(draftValue);
    }
  };

  useEnter(handleSave, isEditing);

  const inputProps = {
    error: hasError,
    placeholder,
    value: draftValue,
    onBlur: handleCancel,
    onChange: setDraftValue,
    ...overrideInputProps,
  };

  return (
    <Layout direction="column" spacing={1}>
      <Layout justify="space-between" spacing={4}>
        <Label>{label}</Label>
        {!isEditing && (
          <Button
            variant="action"
            onClick={edit}
            text={draftValue ? 'Edit' : 'Add'}
          ></Button>
        )}
      </Layout>
      {isEditing ? (
        <Tooltip tooltip="Press ESC to Cancel. Press ENTER to Save">
          <Input {...inputProps} />
        </Tooltip>
      ) : (
        <Text preset="description" whiteSpace="pre-wrap">
          {String(draftValue) || placeholder}
        </Text>
      )}
      {message && (
        <Text color={footerColor} variant="tiny">
          {message}
        </Text>
      )}
    </Layout>
  );
};
