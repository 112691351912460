import React from 'react';

import { SharedStepsProps } from '../../../../types';
import { Layout } from '../../../Layout';
import { Step } from './Step';

interface Props extends SharedStepsProps {
  mode?: 'summary';
}

export const Steps = ({
  activeInstructionId,
  activeStepId,
  lastCompletedStepId,
  mode,
  steps,
  onSelectStep,
}: Props) => {
  const lastCompletedStepIndex = steps.findIndex(
    (step) => step.id === lastCompletedStepId,
  );

  return (
    <Layout direction="column" spacing={3}>
      {steps.map((step, index) => (
        <Step
          key={step.id}
          activeInstructionId={activeInstructionId}
          isActive={activeStepId === step.id}
          isCompleted={
            lastCompletedStepIndex === undefined
              ? false
              : index <= lastCompletedStepIndex
          }
          index={index}
          mode={mode}
          step={step}
          onSelectStep={onSelectStep}
        />
      ))}
    </Layout>
  );
};
