import React from 'react';

import { default as CardJS } from './Card';
import Footer from './Footer';
import Header from './Header';

interface HeaderProps {
  rightContent?: string | React.ReactNode;
  title?: string | React.ReactNode;
}

interface FooterProps {
  actionButtons?: React.ReactNode;
}

interface CardProps {
  children: React.ReactElement;
  collapseIconPosition?: 'left' | 'right';
  isBordered?: boolean;
  isCollapsed?: boolean;
  isFullHeight?: boolean;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  onToggleCollapse?: () => void;
}

const Card = (CardJS as unknown) as React.FC<CardProps> & {
  Footer: React.FC<FooterProps>;
  Header: React.FC<HeaderProps>;
};

Card.Footer = Footer as React.FC<FooterProps>;
Card.Header = Header as React.FC<HeaderProps>;

export default Card;
