import { api } from '../createApi';
import { TagType } from '../TagType';
import { filterById, patchById } from '../utils';

const TAG_BY_LIST = { type: TagType.ApiKey, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.ApiKey as TagType],
  endpoints: {
    getApiKeys: {
      providesTags: [TAG_BY_LIST],
    },
    createApiKey: {
      invalidatesTags: [TAG_BY_LIST],
    },
    updateApiKey: {
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getApiKeys', undefined, (drafts) =>
              patchById(drafts, id, data),
            ),
          );
        } catch {}
      },
    },
    deleteApiKey: {
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getApiKeys', undefined, (drafts) =>
              filterById(drafts, id),
            ),
          );
        } catch {}
      },
    },
  },
});
