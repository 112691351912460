import React from 'react';
import { connect } from 'react-redux';

import { Layout, Text } from '~/eds';

import { calculateF1Score } from '../../util';

const AccuracyMetrics = ({ model }) => {
  const metrics = model?.current?.accuracyMetrics || {};
  const f1Score = calculateF1Score(metrics) ?? 0;

  return (
    <Layout
      direction="column"
      borderRadius="m"
      border="border.divider"
      mb={4}
      p={4}
    >
      <Text variant="body-bold">Details</Text>
      <Layout justify="space-between" pt={2}>
        <Text>In-Sample Precision</Text>
        <Text variant="body-bold">{metrics?.inSample?.precision}</Text>
      </Layout>
      <Layout justify="space-between" pt={2}>
        <Text>In-Sample Recall</Text>
        <Text variant="body-bold">{metrics?.inSample?.recall}</Text>
      </Layout>
      <Layout justify="space-between" pt={2}>
        <Text>Out-of-Sample Precision</Text>
        <Text variant="body-bold">{metrics?.outOfSample?.precision}</Text>
      </Layout>
      <Layout justify="space-between" pt={2}>
        <Text>Out-of-Sample Recall</Text>
        <Text variant="body-bold">{metrics?.outOfSample?.recall}</Text>
      </Layout>
      <Layout justify="space-between" pt={2}>
        <Text>F1 Score</Text>
        <Text variant="body-bold">{f1Score}</Text>
      </Layout>
      <Layout justify="space-between" pt={2}>
        <Text>Threshold</Text>
        <Text variant="body-bold">{model?.current?.threshold}</Text>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({ automation }) => ({
  model: automation.model,
});

export default connect(mapStateToProps, {})(AccuracyMetrics);
