import React from 'react';
import { useSlate } from 'slate-react';

import { Actions } from '../../../components';
import { UserAction } from '../../../types';
import { FORMAT_CONFIGS } from './constants';
import { Format } from './types';
import { testIsFormatActive, toggleFormat } from './utils';

interface Props {
  formats: Format[];
}

export const FormatBar = ({ formats }: Props) => {
  const editor = useSlate();

  const actions: UserAction[] = formats.map((format) => {
    const { format: text, icon, tooltip, type } = FORMAT_CONFIGS[format];

    const isActive = testIsFormatActive(editor, format, type);

    return {
      icon,
      mode: 'icon',
      status: isActive ? 'active' : undefined,
      text,
      tooltip,
      onClick: () => toggleFormat(editor, format, type),
    };
  });

  return <Actions actions={actions} size="s" />;
};
