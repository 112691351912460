import { DataFieldOperators, DataFieldType } from '~/enums';

const BLANK_OPTIONS = [
  { value: DataFieldOperators.IsBlank, label: 'is blank' },
  { value: DataFieldOperators.IsNotBlank, label: 'is not blank' },
];

const NUMBER_OPTIONS = [
  { value: DataFieldOperators.LessThan, label: 'less than' },
  {
    value: DataFieldOperators.LessThanOrEqualsTo,
    label: 'less than or equals to',
  },
  { value: DataFieldOperators.Exactly, label: 'exactly' },
  {
    value: DataFieldOperators.GreaterThanOrEqualsTo,
    label: 'greater than or equal to',
  },
  { value: DataFieldOperators.GreaterThan, label: 'greater than' },
  { value: DataFieldOperators.Between, label: 'between' },
  ...BLANK_OPTIONS,
];

const STRING_OPTIONS = [
  { value: DataFieldOperators.ContainsText, label: 'contains text' },
  {
    value: DataFieldOperators.DoesNotContainText,
    label: 'does not contain text',
  },
  ...BLANK_OPTIONS,
];

const DATE_OPTIONS = [
  { value: DataFieldOperators.DateBefore, label: 'before date' },
  { value: DataFieldOperators.DateEquals, label: 'on date' },
  { value: DataFieldOperators.DateAfter, label: 'after date' },
  { value: DataFieldOperators.DateBetween, label: 'between dates' },
  { value: DataFieldOperators.Next, label: 'in the next' },
  { value: DataFieldOperators.Last, label: 'in the last' },
  ...BLANK_OPTIONS,
];

const ARRAY_SINGLE_OPTIONS = [
  { value: DataFieldOperators.Equals, label: 'equals' },
  { value: DataFieldOperators.DoesNotEqual, label: 'does not equal' },
  ...BLANK_OPTIONS,
];

const ARRAY_MULTIPLE_OPTIONS = [
  { value: DataFieldOperators.ContainsAll, label: 'contains all' },
  { value: DataFieldOperators.ContainsAny, label: 'contains any' },
  { value: DataFieldOperators.DoesNotContain, label: 'does not contain' },
  ...BLANK_OPTIONS,
];

export const ATTACHMENT_OPTIONS = [
  { value: DataFieldOperators.IsNotBlank, label: 'Has Files Attached' },
  { value: DataFieldOperators.IsBlank, label: 'No Files Attached' },
];

export const DOCUMENT_ID_OPTIONS = [
  { value: DataFieldOperators.ContainsAny, label: 'contains any' },
  { value: DataFieldOperators.DoesNotContain, label: 'does not contain' },
];

export const BOOLEAN_OPTIONS = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
  ...BLANK_OPTIONS,
];

export function getRelationOptions(type) {
  switch (type) {
    case DataFieldType.NUMBER:
      return NUMBER_OPTIONS;
    case DataFieldType.STRING:
    case DataFieldType.TEXT_AREA:
      return STRING_OPTIONS;
    case DataFieldType.DATE:
      return DATE_OPTIONS;
    case DataFieldType.ARRAY_SINGLE:
      return ARRAY_SINGLE_OPTIONS;
    case DataFieldType.ARRAY_MULTIPLE:
      return ARRAY_MULTIPLE_OPTIONS;
    default:
      return null;
  }
}
