export default `
<svg
  aria-hidden="true"
  aria-label="view"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M8.01562 12C8.01562 9.8125 9.79688 8 12.0156 8C14.2031 8 16.0156 9.8125 16.0156 12C16.0156 14.2188 14.2031 16 12.0156 16C9.79688 16 8.01562 14.2188 8.01562 12ZM12.0156 14.5C13.3906 14.5 14.5156 13.4062 14.5156 12C14.5156 10.625 13.3906 9.5 12.0156 9.5C11.9844 9.5 11.9531 9.5 11.9219 9.5C11.9844 9.6875 12.0156 9.84375 12.0156 10C12.0156 11.125 11.1094 12 10.0156 12C9.82812 12 9.67188 12 9.51562 11.9375C9.51562 11.9688 9.51562 12 9.51562 12C9.51562 13.4062 10.6094 14.5 12.0156 14.5ZM5.98438 7.53125C7.45312 6.15625 9.48438 5 12.0156 5C14.5156 5 16.5469 6.15625 18.0156 7.53125C19.4844 8.875 20.4531 10.5 20.9219 11.625C21.0156 11.875 21.0156 12.1562 20.9219 12.4062C20.4531 13.5 19.4844 15.125 18.0156 16.5C16.5469 17.875 14.5156 19 12.0156 19C9.48438 19 7.45312 17.875 5.98438 16.5C4.51562 15.125 3.54688 13.5 3.07812 12.4062C2.98438 12.1562 2.98438 11.875 3.07812 11.625C3.54688 10.5 4.51562 8.875 5.98438 7.53125ZM12.0156 6.5C9.95312 6.5 8.29688 7.4375 7.01562 8.625C5.79688 9.75 4.98438 11.0625 4.54688 12C4.98438 12.9375 5.79688 14.2812 7.01562 15.4062C8.29688 16.5938 9.95312 17.5 12.0156 17.5C14.0469 17.5 15.7031 16.5938 16.9844 15.4062C18.2031 14.2812 19.0156 12.9375 19.4531 12C19.0156 11.0625 18.2031 9.75 16.9844 8.625C15.7031 7.4375 14.0469 6.5 12.0156 6.5Z"
    fill="currentcolor"
  />
</svg>
`;
