import PT from 'prop-types';
import React, { useState } from 'react';
import injectSheet from 'react-jss';
import { Mention, MentionsInput } from 'react-mentions';

import EcTextarea from '~/components/Shared/EcTextarea';
import { Box, CharacterLimit, FlexLayout, Icon, Text } from '~/ui';
import { CHARACTER_INPUT_LIMIT_SHORT } from '~/ui/enums/input';
import { sortByStringValue } from '~/utils/array';
import { capitalize } from '~/utils/strings';
import { getUserName } from '~/utils/user';

import mentionStyles from './DescriptionTextAreaForm.Mentions.style';

function DescriptionTextAreaForm({
  description: initialDescription,
  disabled = false,
  id,
  limit = CHARACTER_INPUT_LIMIT_SHORT,
  title = 'description',
  onUpdate,
  userMentionsData,
  onMentionUser,
  mentionInputRef,
  classes,
}) {
  const description = initialDescription || '';
  const [editMode, setEditMode] = useState(description !== '');

  function handleTextChange(e) {
    onUpdate(e.target.value);
  }

  function renderUserSuggestion({ id }) {
    const user = userMentionsData[id];
    return getUserName(user);
  }

  function displayTransform(id) {
    const user = userMentionsData[id];
    return getUserName(user);
  }

  function handleRemoveDescription() {
    onUpdate('');
    setEditMode(false);
  }

  function mapUsersToMentionsData() {
    return Object.values(userMentionsData)
      .map((user) => ({
        id: user.id,
        display: getUserName(user),
      }))
      .sort(sortByStringValue('display'));
  }

  let error;
  if (description.length > limit) {
    error = true;
  }

  function renderDescription() {
    return (
      <Box py={2}>
        <FlexLayout space={4} alignItems="center">
          <Icon color="gray-700" icon="fieldParagraph" size="s" />
          <Text color="gray-700" variant="xs-dense-bold">
            {capitalize(title)}
          </Text>
        </FlexLayout>
        <FlexLayout
          py={2}
          alignItems="top"
          justifyContent="space-between"
          space={2}
        >
          <FlexLayout flexDirection="column" flexGrow="1">
            {renderInputComponent()}
          </FlexLayout>
          <FlexLayout space={4}>
            <Icon
              icon="trash"
              title="Clear text"
              onClick={handleRemoveDescription}
            />
          </FlexLayout>
        </FlexLayout>
      </Box>
    );
  }

  function renderInputComponent() {
    if (userMentionsData) {
      return (
        <Box sx={{ width: '100%' }}>
          <MentionsInput
            data-testid="textArea"
            value={description}
            onChange={(_, newValue) => onUpdate(newValue)}
            className={classes.mentionsInput}
            inputRef={mentionInputRef}
          >
            <Mention
              data={mapUsersToMentionsData()}
              markup="{{user:__id__}}"
              trigger="@"
              displayTransform={displayTransform}
              renderSuggestion={renderUserSuggestion}
              className={classes.mention}
              onAdd={(id) => onMentionUser(id)}
            />
          </MentionsInput>
          <FlexLayout justifyContent="flex-end">
            {limit && <CharacterLimit limit={limit} value={description} />}
          </FlexLayout>
        </Box>
      );
    } else {
      return (
        <EcTextarea
          height="100px"
          error={error}
          limit={limit}
          value={description}
          onChange={handleTextChange}
          data-testid="textArea"
        />
      );
    }
  }

  return disabled ? (
    renderDescription()
  ) : description === '' && !editMode ? (
    <FlexLayout id={id} space={4} py={2} onClick={() => setEditMode(true)}>
      <Icon color="blue-500" icon="fieldParagraph" size="s" />
      <Text color="blue-500" variant="xs-dense-bold">
        Add {title}
      </Text>
    </FlexLayout>
  ) : (
    renderDescription()
  );
}

DescriptionTextAreaForm.propTypes = {
  description: PT.string,
  disabled: PT.bool,
  onUpdate: PT.func,
  userMentionsData: PT.shape({
    id: PT.number,
    firstname: PT.string,
    lastName: PT.string,
    email: PT.string,
  }),
  onMentionUser: PT.func,
  mentionInputRef: PT.object,
};

export default injectSheet(mentionStyles)(DescriptionTextAreaForm);
