import { TargetEntity, TargetEntityDetails } from '~/features/x-ray';

import { getFields } from '../fields';

interface Request {
  entity: TargetEntity;
}

export const resolvePromptModelTargetEntityDetails = async ({
  entity,
}: Request): Promise<TargetEntityDetails> => {
  const { id, type } = entity;
  switch (type) {
    case 'field':
      const { results } = await getFields({ ids: [Number(id)] });
      const field = results[0];
      return {
        id: String(field.id),
        label: field.label,
        type,
      };
    default:
      throw new Error('Entity type not supported');
  }
};
