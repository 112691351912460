import { useEffect } from 'react';
import { Link } from 'react-router-dom-v5-compat';

import wordLogoSrc from '~/assets/images/logo-microsoft-word.svg';
import { Box, Button, Layout, Menu, Text } from '~/eds';
import { api } from '~/redux';

interface DocumentOnlineEditorButtonProps {
  id?: string;
  documentId: string;
  documentProvider: string;
  documentVersionId: string;
  onClick?: () => void;
  disabled?: boolean;
  tooltipMessage?: string;
}

export const DocumentOnlineEditorButton = ({
  id,
  documentId,
  documentProvider,
  documentVersionId,
  onClick,
  disabled,
  tooltipMessage,
}: DocumentOnlineEditorButtonProps) => {
  const { data: wopiStatic } = api.endpoints.getWopiStaticFile.useQuery({});

  useEffect(() => {
    if (wopiStatic) {
      cacheStaticWopiFiles();
    }
  }, [wopiStatic]);

  const cacheStaticWopiFiles = () => {
    const form = document.getElementById('office_form') as HTMLFormElement;
    if (!form || !wopiStatic?.urlPreloadEdit) {
      return;
    }

    form.action = wopiStatic.urlPreloadEdit;
    form.submit();
  };

  const getMenuOptions = () => {
    return [
      {
        label: '',
        value: `edit-online-${documentId}`,
        leftContent: (
          <Link
            target="_blank"
            to={`/word-online/${documentProvider}_${documentId}_${documentVersionId}`}
            onClick={onClick}
            style={{
              maxWidth: 300,
            }}
          >
            <Layout align="center" spacing={3}>
              <Box
                as="img"
                src={wordLogoSrc}
                styles={{
                  height: 'auto',
                  width: 40,
                  marginLeft: 0,
                }}
              />
              <Layout direction="column">
                <Text variant="tiny-bold">Microsoft Word for the web</Text>
                <Text>
                  Open this file in your browser to edit in real-time with
                  others
                </Text>
              </Layout>
            </Layout>
          </Link>
        ),
      },
    ];
  };

  return (
    <div id={id}>
      <Menu
        enablePortal
        disabled={disabled}
        name="Edit online"
        options={getMenuOptions()}
        trigger={
          <Button
            text="Edit online"
            icon="chevron-down"
            disabled={disabled}
            tooltip={tooltipMessage}
          />
        }
      />

      <form
        id="office_form"
        name="office_form"
        target="office_frame"
        method="post"
      />

      <span id="frameholder">
        <iframe
          style={{ display: 'none' }}
          id="office_frame"
          name="office_frame"
          title="Word Online Static Files"
          sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
        />
      </span>
    </div>
  );
};
