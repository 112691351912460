import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { evisortBlue } from '../../../assets/shared-styles/general';
import EcDataField from '../../Shared/EcDataField';
import SearchIcon from '../../Shared/Icons/SearchIcon';
import styles from './ReviewerDataFields.styles';

class ReviewerDataFields extends Component {
  constructor(props) {
    super(props);

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onFieldFocusChange = this.onFieldFocusChange.bind(this);
    this.state = {
      editData: {},
      editedFieldsIds: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.initializeFieldValues();
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  initializeFieldValues() {
    const editData = this.props.dataFields.reduce(
      (fieldsObj, field) => ({ ...fieldsObj, [field.id]: field.value }),
      {},
    );

    this.setState({ editData, loading: false });
  }

  onFieldChange(newState) {
    const { editedFieldsIds } = this.state;
    const { handleSubmitButtonStatus } = this.props;

    const editedId = Object.keys(newState)[0];
    const newEditedIds =
      editedFieldsIds.indexOf(editedId) > -1
        ? editedFieldsIds
        : [...editedFieldsIds, editedId];
    const newEditedData = { ...this.state.editData, ...newState };
    const enableSaveButton =
      Object.values(newEditedData)
        .reduce((acc, value) => acc.concat(value), [])
        .filter((item) => item && (item.error || item.disableForm)).length ===
      0;

    this.setState({
      editData: newEditedData,
      editedFieldsIds: newEditedIds,
    });

    handleSubmitButtonStatus(enableSaveButton);
  }

  onFieldFocusChange(newState) {
    this.setState(newState);
  }

  renderFieldFooter(field) {
    const { classes, onFieldTokenClick } = this.props;

    return (
      <div className={classes.fieldFooter}>
        <button
          className={`${
            field.html_tokens.length > 0 ? classes.clickable : ''
          } ${classes.fieldFooterContent}`}
          onClick={() => onFieldTokenClick(field)}
          data-testid="find-in-document-button"
        >
          {field.html_tokens.length > 0 ? (
            <SearchIcon opacity="1" size="24" color={evisortBlue} />
          ) : null}
        </button>
      </div>
    );
  }

  renderDataField(field) {
    const { classes } = this.props;
    const fieldValue = this.state.editData[field.id];

    return (
      <div className={classes.dataField} key={field.id}>
        {field.type === 'DATE' ? (
          <EcDataField
            field={field}
            fieldValue={fieldValue}
            onFieldChange={this.onFieldChange}
            fieldFocused={this.state[`focused-${field.id}`]}
            onFieldFocusChange={this.onFieldFocusChange}
            anchorDirection="right"
          >
            {this.renderFieldFooter(field)}
          </EcDataField>
        ) : (
          <EcDataField
            field={field}
            fieldValue={fieldValue}
            onFieldChange={this.onFieldChange}
          >
            {this.renderFieldFooter(field)}
          </EcDataField>
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        {!this.state.loading &&
          this.props.dataFields.map((field) => this.renderDataField(field))}
      </div>
    );
  }
}

ReviewerDataFields.propTypes = {
  dataFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.any,
    }),
  ).isRequired,
  onRef: PropTypes.func.isRequired,
};

export default injectSheet(styles)(ReviewerDataFields);
