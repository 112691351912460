import { black1, semibold } from '../../../assets/shared-styles/general';

const filterCategoryTitleWrapper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '44px',
  border: 'none',
  borderRadius: '4px',
  padding: '12px 8px 12px 16px',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  textAlign: 'left',
  cursor: 'pointer',
  '& span': {
    color: black1,
    fontSize: '15px',
    fontWeight: semibold,
  },
};

const styles = {
  filterCategory: {
    margin: '8px 0',
  },
  filterCategoryTitleWrapper: {
    ...filterCategoryTitleWrapper,
  },
  filterCategoryTitleWrapperExpanded: {
    ...filterCategoryTitleWrapper,
    border: '1px solid rgba(255, 255, 255, 0.25)',
  },
  categoryField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 8px 10px 0',
    width: '100%',
    paddingLeft: '15px',
    color: black1,
    fontSize: '15px',
    '& button': {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      textAlign: 'left',
      wordBreak: 'break-word',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
  categoryFieldPinButton: {
    backgroundColor: 'transparent',
    border: 'none',
  },
};

export default styles;
