import React from 'react';

import { SharedHeaderProps } from '../../../types';
import { Card, Props as CardProps } from '../Card';

interface Props extends CardProps {
  /** Header must be provided, will serve as the `summary` of a `details` card */
  header: SharedHeaderProps;
  /** The collapsed state of the card */
  isCollapsed: boolean;
  /** Controlled callback to update collapse */
  onToggle: (updatedIsCollapsed: boolean) => void;
  /** Convenient passthrough configuration to transform back to a regular card */
  disableCollapse?: boolean;
}

export const CollapsibleCard = ({
  children,
  disableCollapse,
  header,
  isCollapsed,
  onToggle,
  ...rest
}: Props) => {
  const cardProps = disableCollapse
    ? {
        ...rest,
        children,
        header,
      }
    : {
        ...rest,
        as: 'details' as const,
        children,
        domProps: {
          open: !isCollapsed,
        },
        header: {
          ...header,
          actions: [
            ...(header.actions ?? []),
            {
              icon: isCollapsed
                ? ('chevron-down' as const)
                : ('chevron-up' as const),
              mode: 'icon' as const,
              text: isCollapsed ? 'Show' : 'Hide',
            },
          ],
          as: 'summary',
          onClick: (event?: MouseEvent) => {
            event?.preventDefault();
            onToggle(!isCollapsed);
          },
        },
      };

  return <Card {...cardProps} />;
};
