import type { ModelVersion } from '~/features/x-ray';
import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { PromptModelVersion } from '../../types/sylvanus/model';
import { getFilters } from '../searchV3';
import { mapScopeToFilters } from './transformers';

export const getPromptModelVersion = async ({
  modelId,
  version,
}: {
  modelId: Uuid;
  version: number;
}): Promise<ModelVersion> => {
  const response: PromptModelVersion = await sylvanus.get(
    `/model/${modelId}/version/${version}`,
  );

  const searchFilters = await getFilters();

  const { scope } = response.config;

  return {
    config: response.config,
    createdBy: Number(response.created_by_user_id),
    createdDate: new Date(response.datetime_created),
    modelId: response.prompt_model_id,
    optimize: response.optimize
      ? {
          state: response.optimize.state,
          version: response.optimize.version_number,
        }
      : null,
    publishStatus: response.publish?.status ?? null,
    scope: scope ? mapScopeToFilters(scope, searchFilters) : [],
    testCases: Object.values(response.test_cases ?? []),
    version: response.version_number,
  };
};
