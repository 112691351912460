import PT from 'prop-types';
import React from 'react';

import { UI_STEPS_STEP_TESTID } from '~/constants/testids';

import Box from '../Box';
import FlexLayout from '../FlexLayout';
import Icon from '../Icon';
import Text from '../Text';
import Tooltip from '../Tooltip';

const StateType = {
  Completed: 'completed',
  Current: 'current',
  Inactive: 'inactive',
};

// TODO: these can be simplified once Design consolidates and shares UIUX for concise and non-concise steps.
const stateConfigs = {
  [StateType.Completed]: {
    bg: 'green-500',
    bgCondensed: 'green-400',
    color: 'white',
    icon: 'checkmark',
    iconColor: 'white',
    iconSize: 'm',
  },
  [StateType.Current]: {
    bg: 'yellow-200',
    bgCondensed: 'green-400',
    color: 'gray-900',
    icon: 'donut',
    iconColor: 'peach-800',
    iconSize: 'm',
  },
  [StateType.Inactive]: {
    bg: 'gray-400',
    bgCondensed: 'gray-500',
    color: 'gray-600',
    icon: 'actions',
    iconColor: 'gray-600',
    iconSize: 'xs',
  },
};

const getStepState = ({ currentStepIndex, index, isCompleted }) => {
  const isCurrentStep = index === currentStepIndex;
  const isPreviousStep = index < currentStepIndex;
  if (isPreviousStep) {
    return StateType.Completed;
  } else if (isCurrentStep) {
    return isCompleted ? StateType.Completed : StateType.Current;
  } else {
    return StateType.Inactive;
  }
};

const Bar = ({ label, state }) => {
  const { bgCondensed } = stateConfigs[state];
  return (
    <Tooltip content={label}>
      <Box
        className={UI_STEPS_STEP_TESTID}
        sx={{
          bg: bgCondensed,
          flex: 'none',
          height: 'step-bar-height',
          width: 'step-bar-width',
        }}
      />
    </Tooltip>
  );
};

const Step = ({ isJoined, label, state, hideIcon = false }) => {
  const { bg, color, icon, iconColor, iconSize } = stateConfigs[state];
  return (
    <FlexLayout alignItems="center" flex="none">
      {isJoined && (
        <Box
          sx={{
            bg,
            height: 'step-separator-height',
            width: 'step-separator-width',
          }}
        />
      )}
      <FlexLayout
        alignItems="center"
        bg={bg}
        color={color}
        className={UI_STEPS_STEP_TESTID}
        sx={{ borderRadius: 'pill' }}
        pl={3}
        pr={4}
        py={1}
        space={2}
      >
        {!hideIcon && <Icon color={iconColor} icon={icon} size={iconSize} />}
        <Text color={color} variant="xs-dense-bold">
          {label}
        </Text>
      </FlexLayout>
    </FlexLayout>
  );
};

const Steps = ({
  currentStepValue,
  isCancelled = false,
  isCompleted = false,
  isConcise = false,
  defaultStepLabel = null,
  steps,
}) => {
  const currentStepIndex = steps.findIndex(
    (step) => step.value === currentStepValue,
  );
  const currentStepLabel = steps[currentStepIndex]?.label || defaultStepLabel;

  if (isCancelled) {
    return (
      <Step
        hideIcon
        isCompleted={isCompleted}
        label="Cancelled"
        state={StateType.Inactive}
      />
    );
  }

  if (isConcise) {
    return (
      <FlexLayout flexDirection="column" space={1}>
        <FlexLayout space="2px">
          {steps.map(({ label, value }, index) => (
            <Bar
              key={value}
              isCompleted={isCompleted}
              label={label}
              state={getStepState({ currentStepIndex, index, isCompleted })}
            />
          ))}
        </FlexLayout>
        {currentStepLabel && (
          <Text color="gray-700" variant="2xs-dense">
            {currentStepLabel}
          </Text>
        )}
      </FlexLayout>
    );
  }

  return (
    <FlexLayout alignItems="center">
      {steps.map(({ label, value }, index) => (
        <Step
          key={value}
          isCompleted={isCompleted}
          isJoined={index > 0}
          label={label}
          state={getStepState({ currentStepIndex, index, isCompleted })}
        />
      ))}
    </FlexLayout>
  );
};

Steps.propTypes = {
  /** The current step.value */
  currentStepValue: PT.any,
  /** Indicate if all steps are completed */
  isCompleted: PT.bool,
  /** Whether to render a concise view of the component  */
  isConcise: PT.bool,
  /** An array of steps (options-based interface) */
  steps: PT.arrayOf(
    PT.shape({
      label: PT.string.isRequired,
      value: PT.any,
    }),
  ).isRequired,
};

export default Steps;
