import { Party } from '~/features/manage-parties';
import { pilot } from '~/services';
import { PilotId } from '~/types';

import { getParties } from './getParties';

interface Request {
  party: Party;
  clientId: PilotId;
}

export const addParty = async ({
  party,
  clientId,
}: Request): Promise<boolean> => {
  const partiesResponse = await getParties({
    clientId,
    isInternal: party.isInternal,
  });

  const params = {
    internal: party.isInternal,
    party: { name: party.name, id: partiesResponse.count },
  };

  await pilot.post(`client/${clientId}/update-party-list/`, params);

  return true;
};
