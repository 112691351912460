import pluralize from 'pluralize';
import React, { useState } from 'react';

import { Alert, Box, Text } from '~/ui';

const SearchReIndexInProgressWarning = ({ fullyIndexed, docCountIndexing }) => {
  const [showAlert, setShowAlert] = useState(true);

  return showAlert && !fullyIndexed ? (
    <Box mb={4}>
      <Alert
        onDismiss={() => setShowAlert(false)}
        ariaLive="assertive"
        enableIcon
        variant="warning"
      >
        <Text>
          {docCountIndexing} document {pluralize('record', docCountIndexing)}{' '}
          {pluralize('is', docCountIndexing)}
        </Text>
        <Text>
          {' '}
          currently being updated and may not be accurately represented in
          Search{' '}
        </Text>
        <Text>
          results while this update is in progress. Please check again later to{' '}
        </Text>
        <Text>
          confirm that your search query has returned a full set of results.{' '}
        </Text>
      </Alert>
    </Box>
  ) : null;
};

export default SearchReIndexInProgressWarning;
