import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = ({ currentUser }) => ({ currentUser });

export function withCurrentUser(WrappedComponent) {
  function CurrentUserContainer({ currentUser, ...rest }) {
    return <WrappedComponent {...rest} currentUser={currentUser} />;
  }
  return connect(mapStateToProps)(CurrentUserContainer);
}
