import React, { useEffect, useState } from 'react';

import { domainCheck } from '~/api';
import AuthenticationStore from '~/auth';
import { Box, ContentContainer, LoginPage as EdsLoginPage } from '~/eds';
import { LoginViewType, QueryParamType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { withRouting } from '~/routing';
import { getAbsoluteRedirectUrl } from '~/services/redirects';

import { useAegisLoginProps } from './useAegisLoginProps';
import { useRolodexLoginProps } from './useRolodexLoginProps';

const AS = AuthenticationStore();

const componentStyles = {
  page: {
    alignItems: 'flex-start',
    backgroundColor: 'inverse.background.secondary',
    display: 'flex',
    height: '100vh',
    margin: 'auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'fixed',
    width: '100vw',
  },
  container: {
    flex: 'auto',
    padding: 4,
    margin: 'auto',
    maxWidth: '1280px',
  },
};

function Page(props) {
  const [isCheckingDomain, setIsCheckingDomain] = useState(false);

  const [stage, setStage] = useState(LoginViewType.Domain);
  const [error, setError] = useState();
  const [returnTo, setRedirectTo] = useState(null);

  const [ssoConfigs, setSsoConfigs] = useState(null);
  const [workspace, setWorkspace] = useState('');

  const [useRolodex, setUseRolodex] = useState(false);

  const isEmbed = AS.isEmbed();
  const isSsoOnly = isEmbed && ssoConfigs; // enable auto-login if in embedded login mode and ssoConfigs exists

  if (stage === LoginViewType.LoggedIn) {
    window.location.href = returnTo
      ? `/${workspace.toLowerCase()}${returnTo}`
      : `/${workspace.toLowerCase()}/`;
  }

  const shouldUseSearchAsHomePage = useFlag(FlagType.SearchAsHomePage);
  if (stage === LoginViewType.LoggedInWithWelcome) {
    if (shouldUseSearchAsHomePage) {
      window.location.href = `/${workspace.toLowerCase()}/search?welcomeMessage=true`;
    } else {
      window.location.href = `/${workspace.toLowerCase()}/?welcomeMessage=true`;
    }
  }

  const checkClient = (ws) => {
    const fromLocation = props.location.state?.from;
    const formData = fromLocation
      ? {
          client: ws,
          return_to: getAbsoluteRedirectUrl(
            window.location.origin,
            ws.toLowerCase(),
            fromLocation,
          ),
        }
      : { client: ws };

    setIsCheckingDomain(true);
    return domainCheck(formData)
      .then((response) => {
        if (response) {
          if (!props.location.search.includes(QueryParamType.SamlError)) {
            setStage(LoginViewType.Email);
          }
          if (response.sso.error) {
            setError(`Invalid SSO Configuration: ${response.sso.error}`);
          } else {
            setError(null);
          }
          if (response.sso.enabled) {
            setSsoConfigs(response.sso);
          }

          setUseRolodex(response.useRolodex);
        }
        setIsCheckingDomain(false);
      })
      .catch(() => {
        setError('An error occurred. Please check again.');
      })
      .finally(() => {
        setIsCheckingDomain(false);
      });
  };

  /**
   * Mapping of old/DEPRECATED LoginViewType to new LoginPage.Stage
   */
  const handleChangeWorkspace = (ws) => {
    setWorkspace(ws);
    checkClient(ws);
  };

  const queryChangeWorkspace = [
    handleChangeWorkspace,
    ssoConfigs ? { error: ssoConfigs.error, isSuccess: true } : {},
  ];

  useEffect(() => {
    const isLoggedIn = AS.isLoggedIn();
    const hasReturnTo =
      props.location && props.location.state && props.location.state.from;

    const windowPathname = window.location.pathname;
    const subdomain = windowPathname.split('/')[1];

    if (isLoggedIn && !isEmbed) {
      return props.navigate('/');
    }
    if (hasReturnTo) {
      const { pathname, search } = props.location.state.from;
      if (search) {
        setRedirectTo(`${pathname}/${search}`);
      } else {
        setRedirectTo(`${pathname}`);
      }
    }

    if (subdomain) handleChangeWorkspace(subdomain);
  }, [props.location.search, props.navigate]);

  // Move aegis related props to useAegisLoginProps and remove them at once
  // when migration to rolodex is done
  const aegisLoginPageProps = useAegisLoginProps({
    ...props,
    workspace,
    ssoConfigs,
    stage,
    setStage,
    error,
    setError,
  });

  const rolodexLoginPageProps = useRolodexLoginProps({
    ...props,
    stage,
    setStage,
    error,
    setError,
  });

  if (isSsoOnly) return null;

  return (
    <Box styles={componentStyles.page}>
      <Box styles={componentStyles.container}>
        <ContentContainer loadingContent={{ isLoading: isCheckingDomain }}>
          <EdsLoginPage
            {...aegisLoginPageProps}
            {...rolodexLoginPageProps}
            workspace={workspace}
            isSsoOnly={isSsoOnly}
            useRolodex={useRolodex}
            queryChangeWorkspace={queryChangeWorkspace}
          />
        </ContentContainer>
      </Box>
    </Box>
  );
}

export default withRouting(Page);
