import { Predicate } from '~/types';

export const and = <T>(predicates: Predicate<T>[]) => (x: T): boolean => {
  if (predicates.length < 1) {
    return false;
  }

  const firstValue = predicates[0](x);

  return predicates.reduce((bool: boolean, predicate: Predicate<T>) => {
    return bool && predicate(x);
  }, firstValue);
};
