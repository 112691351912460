import React, { memo } from 'react';

import { SharedIconButtonProps } from '../../../types';
import { IconButton } from '../../IconButton';
import { Layout } from '../../Layout';
import { Text } from '../../Text';
import { BaseHeader } from './BaseHeader';

interface Props {
  /** Header title */
  title: string;
  /** Left icon */
  leftIcon?: SharedIconButtonProps;
  /** Right icon */
  rightIcon?: SharedIconButtonProps;
}

export const DropdownHeader = memo(({ leftIcon, title, rightIcon }: Props) => {
  return (
    <BaseHeader>
      <Layout align="center" flex="auto" justify="space-between" spacing={1}>
        <Layout align="center" flex="auto" spacing={1}>
          {leftIcon && <IconButton {...leftIcon} />}
          <Text variant="body-medium">{title}</Text>
        </Layout>
        {rightIcon && <IconButton {...rightIcon} />}
      </Layout>
    </BaseHeader>
  );
});
