import { black1, bold } from '../../../assets/shared-styles/general';

const styles = {
  tagClauseLabel: {
    fontSize: '14px',
    fontWeight: bold,
    color: black1,
    marginBottom: '12px',
  },
  tagClauseSelect: {
    position: 'absolute !important',
  },
};

export default styles;
