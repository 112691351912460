export default `
<svg
  aria-hidden="true"
  aria-label="push-pin-filled"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M7.01492 5C7.01492 4.46875 7.45242 4 8.01492 4H16.0149C16.5462 4 17.0149 4.46875 17.0149 5C17.0149 5.5625 16.5462 6 16.0149 6H15.0774L15.4212 10.6562C16.5774 11.2812 17.4837 12.3125 17.9212 13.5938L17.9524 13.6875C18.0462 14 18.0149 14.3438 17.8274 14.5938C17.6399 14.8438 17.3274 15 17.0149 15H7.01492C6.67117 15 6.38992 14.875 6.20242 14.5938C5.98367 14.3438 5.95242 14 6.04617 13.6875L6.07742 13.5938C6.51492 12.3125 7.42117 11.2812 8.57742 10.6562L8.92117 6H8.01492C7.45242 6 7.01492 5.5625 7.01492 5ZM11.0149 16H13.0149V19C13.0149 19.5625 12.5462 20 12.0149 20C11.4524 20 11.0149 19.5625 11.0149 19V16Z"
    fill="currentcolor"
  />
</svg>
`;
