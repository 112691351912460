import { connect } from 'react-redux';

import { ticketParticipantsSet, ticketUpdate } from '~/actions';
import { completeTicket, getTicketParticipants } from '~/api';
import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import { Button } from '~/eds';
import { useCurrentUser } from '~/hooks';
import * as toastTypes from '~/types/toast.types';
import { FlexLayout, Text, useModal } from '~/ui';
import { getClientInfo, getUserClientInfo } from '~/utils/user';

function MarkCompleteAction({
  disabled,
  // connected
  ticketId,
  ticketUpdate,
  ticketParticipantsSet,
}) {
  const currentUser = useCurrentUser();

  const [modal, showModal] = useModal({
    title: 'Complete ticket',
    actionButton: {
      text: 'Submit',
      variant: 'green',
      errorHandler: () =>
        showToast(
          toastTypes.ERROR,
          'Ticket completion failed. Please refresh the page and try again.',
        ),
      promise: async () => {
        const res = await completeTicket({ ticketId });
        trackSegment('Workflow is completed', {
          groupId: getClientInfo(currentUser),
          userId: getUserClientInfo(currentUser),
          ticketId,
        });
        const { data: participants } = await getTicketParticipants({
          ticketId,
        });
        const updatedTicket = {
          document: res.document,
          isCompleted: res.isCompleted,
          modifiedDate: res.modifiedDate,
          stages: res.stages,
          status: res.status,
        };
        ticketUpdate(updatedTicket);
        ticketParticipantsSet(participants);
      },
    },
    content: (
      <FlexLayout flexDirection="column" space={8}>
        <FlexLayout flexDirection="column" space={2}>
          <Text variant="xs-dense">
            Are you sure you want to complete this ticket?
          </Text>
          <Text variant="xs-dense-bold">
            You won’t be able to undo this action.
          </Text>
        </FlexLayout>
      </FlexLayout>
    ),
  });

  return (
    <>
      <Button
        disabled={disabled}
        text="Mark as Complete"
        onClick={showModal}
        variant="primary"
      />
      {modal}
    </>
  );
}

function mapStateToProps({ ticket }) {
  return { ticketId: ticket.id };
}

export default connect(mapStateToProps, {
  ticketUpdate,
  ticketParticipantsSet,
})(MarkCompleteAction);
