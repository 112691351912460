import { HttpStatusCode } from 'axios';

import type { Citation, ResolvedCitationEntity } from '~/features/ask-anything';
import { RoutePathType } from '~/routing';

import { testIsAxiosError } from '../../utils';
import { getDocumentHandler } from '../document/getDocumentHandler';
import { getTicketDocument } from '../ticket';

export const resolveCitation = async (
  entity: Citation['entity'],
): Promise<ResolvedCitationEntity | undefined> => {
  switch (entity.type) {
    case 'ticket':
      const ticketDocument = await getTicketDocument({ ticketId: entity.id });

      return {
        lastModified: new Date(ticketDocument.modifiedDate),
        fileName: `${ticketDocument.name}${ticketDocument.fileType}`,
        link: RoutePathType.WorkflowTicketsTicketReviewerVersionNumber.replace(
          ':ticketId',
          entity.id,
        ).replace(':versionNumber', `${ticketDocument.version}`),
      };
    case 'document_handler':
      try {
        const documentHandler = await getDocumentHandler(entity.id);
        return {
          lastModified: documentHandler.dateModified,
          link: RoutePathType.Document.replace(':documentId', entity.id),
          fileName: `${documentHandler.documentName}${documentHandler.fileType}`,
          path: documentHandler.path.map((path) => path.name).join('/'),
        };
      } catch (error: unknown) {
        if (
          testIsAxiosError(error) &&
          error.response?.status === HttpStatusCode.NotFound
        ) {
          throw new Error('This file has been deleted.', {
            cause: HttpStatusCode.NotFound,
          });
        }
      }
  }
};
