import axios from 'axios';

import { getProvisionTypes } from '~/redux/api/methods/document';

import { pilot } from '../../services';
import { getFiltersFields } from '../AlertsPage/AlertsPage.data';

export const getAnalyzerFiltersData = (callback, errorHandler) => {
  const requestsArray = [getFiltersFields(), getProvisionTypes()];

  return pilot
    .concurrentRequests(requestsArray)
    .then(
      axios.spread((sectionsRes, provisionsRes) =>
        callback(sectionsRes, provisionsRes),
      ),
    )
    .catch((err) => errorHandler(err));
};

export const getAnalyzerSearchQueries = (callback, errorHandler) => {
  const requestsArray = [getRecentSearches(), getSavedQueries()];

  return pilot
    .concurrentRequests(requestsArray)
    .then(
      axios.spread((recentRes, savedRes) =>
        callback(recentRes.results, savedRes.results, savedRes.count),
      ),
    )
    .catch((err) => errorHandler(err));
};

export const getRecentSearches = () => pilot.get('saved-search/recent/');

export const getSavedQueries = (page = 1) =>
  pilot.get(`saved-search/query/?page=${page}`);

export const renameSavedQuery = (queryId, newName) => {
  return pilot.patch(`saved-search/query/${queryId}`, {
    name: newName,
  });
};

export const deleteSavedQuery = (queryId) =>
  pilot.remove(`saved-search/query/${queryId}`);
