import { HelperMethods } from '@syncfusion/ej2-react-documenteditor';
import { useStyles } from 'uinix-ui';

import type { Revision } from '~/components/Shared/DocumentEditor';
import { Button, formatDate, Layout, Text, User } from '~/eds';
import { Uuid } from '~/types';

export interface Props {
  id: Uuid;
  onClick: () => void;
  revision: Revision;
  index: string;
  disabled: boolean;
  isActive: boolean;
  text: string;
  onAction?: (action: { id: string; type: 'accept' | 'reject' }) => void;
}
export function RevisionItem({
  id,
  revision,
  index,
  disabled,
  text,
  onClick,
  isActive,
  onAction,
}: Props) {
  const styles = useStyles();

  function onAccept() {
    revision.accept();
    onAction?.({ id, type: 'accept' });
  }
  function onReject() {
    revision.reject();
    onAction?.({ id, type: 'reject' });
  }

  const convertDate = (date: string) => {
    return new Date(HelperMethods.getModifiedDate(date));
  };

  return (
    <Layout
      id={id}
      px={6}
      py={2}
      onClick={onClick}
      spacing={4}
      styles={styles.colors.status}
      styleProps={{
        status: isActive ? 'active' : undefined,
      }}
      w="100%"
      direction="column"
    >
      <Layout justify="space-between" align="center">
        <User
          mode="info"
          user={{ firstName: revision.author, lastName: '', id: 0, email: '' }}
          options={{ info: formatDate(convertDate(revision.date), 'full') }}
        />
        {revision.revisionType === 'Insertion' ? (
          <Text color="status.success">INSERTED</Text>
        ) : (
          <Text color="status.danger">DELETED</Text>
        )}
      </Layout>
      <Text wordBreak="break-word" whiteSpace="pre-wrap">
        {text}
      </Text>
      <Layout justify="space-between">
        <Layout>
          <Button
            tooltip="Accept"
            disabled={disabled}
            mode="icon"
            icon="check"
            onClick={onAccept}
          />
          <Button
            tooltip="Reject"
            disabled={disabled}
            mode="icon"
            icon="x"
            onClick={onReject}
          />
        </Layout>
        <Text preset="help">{index}</Text>
      </Layout>
    </Layout>
  );
}
