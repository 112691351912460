import React, { memo } from 'react';

import { Box } from '../Box';
import { Tooltip } from '../Tooltip';
import { StepState } from './types';
import { getStepStateConfig } from './utils';

interface Props {
  label: string;
  state: StepState;
}

export const Bar = memo(({ label, state }: Props) => {
  const { bgCondensed } = getStepStateConfig(state);
  return (
    <Tooltip tooltip={label}>
      <Box
        bg={bgCondensed}
        flex="none"
        h="step.bar.height"
        w="step.bar.width"
      />
    </Tooltip>
  );
});
