import { saveAs } from 'file-saver';
import { get, groupBy, uniq } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from 'react-table';

import { canDownloadSearchResultItem } from '~/components/AnalyzerResultsPage/SearchResultsItem/utils';
import { getHandlersKeyFromSearchResult } from '~/components/SearchV2/SearchResult/SearchResult.utils';
import { trackSegment } from '~/components/SegmentAnalytics';
import { DocumentDetailsPanel } from '~/components/Shared/DocumentDetailsPanel';
import { useDocumentMultipleLocationsModal } from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { ExportExcel } from '~/components/Shared/ExportExcel';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { SEARCH_RESULTS_LIMIT } from '~/constants/max_lengths';
import {
  EXPORT_COMPLETED_TEXT,
  EXPORT_DEFAULT_TEXT,
  EXPORT_DUPLICATED_TEXT,
} from '~/constants/search';
import {
  ContentContainer,
  Layout,
  Table,
  types,
  useTableSelection,
} from '~/eds';
import { ClauseValue, FieldId, Filter } from '~/evifields';
import { testIsAnyDocumentNotMovable } from '~/features/search/utils';
import { FlagType, useFlag } from '~/flags';
import {
  useClientSubDomain,
  usePermission,
  useSearchColumns,
  useSearchNavigation,
} from '~/hooks';
import { api, selectors } from '~/redux';
import { Dashboard, TableViewSet } from '~/redux/api/methods';
import {
  SearchDocumentItem,
  SearchDocumentsResult,
} from '~/redux/api/methods/searchV3';
import dashboardV2 from '~/redux/slices/dashboardV2';
import { Query, SearchFilter } from '~/types';
import { ERROR, WARNING } from '~/types/toast.types';
import { openInNewTab } from '~/utils/browser';
import { testContainsGhostSearchResult } from '~/utils/document.utils';
import { getMaximumByLimit } from '~/utils/number';
import { HandlerName } from '~/utils/table';

import { getPath } from './DashboardV2SearchResults.utils';
import { DetailsPanel } from './DetailsPanel';

type Props = {
  currentTableView?: TableViewSet;
  dashboard?: Dashboard;
  isLoading: boolean;
  data?: SearchDocumentsResult;
  query: Query;
  pageSize?: number;
  searchFilters: Record<FieldId, SearchFilter>;
  tableSelection: ReturnType<typeof useTableSelection>;
  resultsCache?: Record<string, SearchDocumentItem>;
  onMove: () => void;
  onCopy: () => void;
  onEdit: () => void;
  onEditClauses: () => void;
  onDelete: () => void;
  onAddToGroup: () => void;
  onLoadView: (tableState: types.TableState) => void;
  onSaveView: (tableState: types.TableState) => void;
};

const DashboardV2SearchResults = ({
  currentTableView,
  dashboard,
  isLoading,
  data,
  pageSize,
  query,
  searchFilters,
  tableSelection,
  resultsCache = {},
  onMove,
  onCopy,
  onEdit,
  onEditClauses,
  onDelete,
  onAddToGroup,
  onLoadView,
  onSaveView,
}: Props) => {
  const page = useSelector(selectors.selectDashboardPage);
  const sortBy = useSelector(selectors.selectDashboardSortBy);
  const columnOrder = useSelector(selectors.selectDashboardColumnOrder);
  const columnWidths = useSelector(selectors.selectDashboardColumnWidths);
  const activeFilters = useSelector(selectors.selectDashboardActiveFilters);
  const featuredClauses = useMemo(() => {
    return (activeFilters.filter(
      (filter) =>
        filter.fieldId === 'clause' && filter.operatorId === 'contains_any',
    ) as Filter<ClauseValue>[]).map(
      (clauseFilter) => clauseFilter.values[0]?.provision ?? '',
    );
  }, [activeFilters]);

  const dispatch = useDispatch();
  const client = useClientSubDomain();
  const isUnifiedDetailsPanelEnabled = useFlag(
    FlagType.UnifiedDocumentDetailsPanel,
  );
  const [documentDetails, setDocumentDetails] = useState<SearchDocumentItem>();
  const [showExportExcel, setShowExportExcel] = useState(false);
  const [
    getDocumentOriginal,
  ] = api.endpoints.getDocumentOriginal.useLazyQuery();

  const hasExportExcel = useFlag(FlagType.DashboardsExportExcel);
  const hasGroupsEditPermission = usePermission({
    permission: {
      resourceId: 'document_groups',
      resourceType: 'edit',
    },
  });

  const hasDocumentPermission = usePermission({
    permission: {
      resourceId: 'documents',
      resourceType: 'edit',
    },
  });

  const isBulkActionOnDashboardEnabled = useFlag(
    FlagType.DashboardV2DocumentsBulkActions,
  );

  const showDetailsPanel = (rowData: any) => {
    setDocumentDetails(rowData);
    trackSegment('selectOverlayPanel', {
      name: rowData.id,
    });
  };
  const hideDetailsPanel = () => {
    trackSegment('selectClose', {
      name: documentDetails?.id,
    });
    setDocumentDetails(undefined);
  };

  const saveOriginalDocument = async (document: SearchDocumentItem) => {
    const documentHandler = get(document, 'document_handlers[0]', {});
    const { id, document_name, file_type } = documentHandler;
    if (canDownloadSearchResultItem(document)) {
      try {
        const response = await getDocumentOriginal(id).unwrap();
        saveAs(response!, `${document_name}.${file_type}`);
      } catch (e) {
        showToast(
          ERROR,
          `Something went wrong with downloading ${document_name}`,
        );
      }
    } else {
      showToast(
        WARNING,
        `You don't have permissions to download ${document_name}`,
      );
    }
  };

  const isDuplicateExport = data?.meta.is_duplicate_export;
  const exportButtonTooltip = isDuplicateExport
    ? `${EXPORT_DUPLICATED_TEXT}  ${EXPORT_COMPLETED_TEXT}`
    : EXPORT_DEFAULT_TEXT;

  const actions = hasExportExcel
    ? [
        {
          icon: 'file-spreadsheet',
          label: EXPORT_DEFAULT_TEXT,
          tooltip: exportButtonTooltip,
          disabled: isDuplicateExport,
          onClick: () => {
            setShowExportExcel(true);
          },
        },
      ]
    : [];

  const onOpenDocumentClick = () => {
    if (documentDetails) {
      if (documentDetails.document_handlers.length > 1) {
        //@ts-ignore callback type not supported in js hook
        setDocumentSelected(documentDetails);
        //@ts-ignore callback type not supported in js hook
        setOpenDocumentInNewTab(true);
      } else {
        openInNewTab(
          `/${client}/document/${documentDetails.document_handlers[0]?.id}`,
        );
      }
      trackSegment('selectDocument', {
        name: documentDetails.id,
      });
    }
  };

  const currentSelectedIndex = useMemo(() => {
    if (documentDetails) {
      return data?.results.indexOf(documentDetails);
    }
    return undefined;
  }, [data, documentDetails]);

  const prev = () => {
    currentSelectedIndex !== undefined &&
      setDocumentDetails(data?.results[currentSelectedIndex - 1]);
  };

  const next = () => {
    currentSelectedIndex !== undefined &&
      setDocumentDetails(data?.results[currentSelectedIndex + 1]);
  };

  const getPanelActions = (
    list: Array<SearchDocumentItem>,
    item: SearchDocumentItem,
  ) => {
    const isFirst = currentSelectedIndex === 0;
    const isLast = currentSelectedIndex === list.length - 1;

    const actions: types.Action[] = [
      {
        icon: 'open-in-new',
        label: 'Open Document',
        value: null,
        onClick: onOpenDocumentClick,
      },
      {
        icon: 'download',
        label: 'Download',
        value: null,
        onClick: () => {
          saveOriginalDocument(item);
          trackSegment('selectDownloadDocument', {
            name: item.id,
          });
        },
      },
      {
        disabled: isFirst,
        value: null,
        icon: 'chevron-left',
        label: 'Previous',
        onClick: () => {
          prev();
          trackSegment('selectPreviousDocument', {
            name: item.id,
          });
        },
      },
      {
        disabled: isLast,
        icon: 'chevron-right',
        label: 'Next',
        value: null,
        onClick: () => {
          next();
          trackSegment('selectNextDocument', {
            name: item.id,
          });
        },
      },
    ];
    return actions;
  };

  const testIsAnyNotMovable = (selectedRowIds: any[]) => {
    const selectedRows = selectedRowIds.map((id) => resultsCache[id]);

    return testIsAnyDocumentNotMovable(selectedRows);
  };

  const testContainsGhost = (selectedRowIds: string[]) => {
    const selectedRows = selectedRowIds.map((id) => resultsCache[id]);

    return testContainsGhostSearchResult(selectedRows);
  };

  const getBulkActions = ({ selectedRowIds }: types.TableState) => {
    if (!isBulkActionOnDashboardEnabled) return [];

    const isAnyNotMovable = testIsAnyNotMovable(selectedRowIds);
    const containsGhost = testContainsGhost(selectedIds);

    const bulkActions = [
      {
        icon: 'folder',
        label: isAnyNotMovable
          ? 'You do not have privileges to move one or more of the items you have selected.'
          : 'Move',
        onClick: onMove,
        disabled: isAnyNotMovable || !hasDocumentPermission.hasPermission,
      },
      {
        icon: 'clipboard',
        label: isAnyNotMovable
          ? 'You do not have privileges to copy one or more of the items you have selected.'
          : 'Copy',
        onClick: onCopy,
        disabled: isAnyNotMovable || !hasDocumentPermission.hasPermission,
      },
      {
        icon: 'edit',
        label: isAnyNotMovable
          ? 'You do not have privileges to edit one or more of the items you have selected.'
          : 'Edit Fields',
        onClick: onEdit,
        disabled: isAnyNotMovable || !hasDocumentPermission.hasPermission,
      },
      {
        icon: 'edit',
        label: isAnyNotMovable
          ? 'You do not have privileges to edit one or more of the items you have selected.'
          : 'Edit Clauses',
        onClick: onEditClauses,
        disabled: isAnyNotMovable || !hasDocumentPermission.hasPermission,
      },
      {
        icon: 'trash',
        label: isAnyNotMovable
          ? 'You do not have privileges to delete one or more of the items you have selected.'
          : 'Delete',
        onClick: onDelete,
        disabled: isAnyNotMovable || !hasDocumentPermission.hasPermission,
      },
      {
        icon: 'groups',
        label: containsGhost
          ? 'You do not have privileges to add one or more of the items you have selected to a group.'
          : 'Add to Group',
        onClick: onAddToGroup,
        disabled: containsGhost || !hasGroupsEditPermission.hasPermission,
      },
    ];

    return bulkActions;
  };

  /*
   * Navigation
   */

  const [handleNavigate] = useSearchNavigation({
    sortBy,
    query,
    pathname: getPath(),
    name: 'dashboard results',
  });
  const [
    setDocumentSelected,
    renderMultipleLocationModal,
    setOpenDocumentInNewTab,
  ] = useDocumentMultipleLocationsModal(
    getHandlersKeyFromSearchResult,
    false,
    handleNavigate,
  );
  /*
   * Navigation End
   */

  const onSetColumnOrder = useCallback(
    (updatedColumnOrder: Array<string>) => {
      dispatch(dashboardV2.actions.setColumnOrder(updatedColumnOrder));
      dispatch(dashboardV2.actions.setDashboardDirty(true));
    },
    [searchFilters],
  );

  const onPaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      if (pageIndex !== page) {
        dispatch(dashboardV2.actions.setPage(pageIndex));
      }
    },
    [page],
  );

  const onPageSizeChange = useCallback((pageSize: number) => {
    dispatch(dashboardV2.actions.setPageSize(pageSize));
  }, []);

  // Using type any since there no interface for inner table state.
  const handleUpdate = (state: any, action?: ActionType) => {
    onTableUpdate(state, action);
    if (action?.type === 'columnDoneResizing') {
      dispatch(
        dashboardV2.actions.patchColumnWidths(
          state.columnResizing.columnWidths,
        ),
      );
    }
    if (action?.type === 'toggleSortBy') {
      dispatch(dashboardV2.actions.setSortBy(state.sortBy[0]));
    }
  };

  /*
   * Columns
   */

  const hardcodedColumns = [
    {
      key: 'name',
      title: 'Name',
      minWidth: 'm',
      renderCell: (document: SearchDocumentItem) => {
        const handlers = document.document_handlers;
        return (
          <HandlerName
            onNavigate={handleNavigate}
            handlers={handlers as any}
            onClick={() => {
              //@ts-ignore callback type not supported in js hook
              setDocumentSelected(document);
              //@ts-ignore callback type not supported in js hook
              setOpenDocumentInNewTab(false);
            }}
          />
        );
      },
    },
  ];

  const searchFilterColumns = useMemo(
    () =>
      Object.values(searchFilters).filter(
        (searchFilters) => searchFilters.is_requestable_column,
      ),
    [searchFilters],
  );

  const columns = useSearchColumns({
    columnWidths,
    hardcodedColumns,
    searchFilterColumns,
  });

  const sectionsResult = api.endpoints.getFilterSections.useQuery(undefined);

  const {
    data: filterSections,
    isSuccess: isSuccessFilterSections,
  } = sectionsResult;

  const columnsMap = groupBy(columns, 'section');

  const columnGroups = useMemo(() => {
    if (filterSections?.fieldGroups) {
      return filterSections?.fieldGroups
        .map((group) => ({
          name: group.id,
          label: group.label,
          columns: columnsMap[group.id]?.map((c) => c.key),
        }))
        .filter((group) => group.columns?.length);
    }
    return undefined;
  }, [columnsMap, filterSections]);

  useEffect(() => {
    if (isSuccessFilterSections && currentTableView) {
      const hardcodedColumnKeys = hardcodedColumns.map((col) => col.key);
      const newColumnOrderFromBE = currentTableView.map((col) =>
        String(col.field_id),
      );
      onSetColumnOrder(uniq([...hardcodedColumnKeys, ...newColumnOrderFromBE]));
    }
  }, [currentTableView, isSuccessFilterSections]);

  /*
   * Columns End
   */

  const { selectedIds, isAllSelected, onTableUpdate } = tableSelection;

  const TableComponent = dashboard?.is_default ? Table : PersistedTable;
  const placeholderContent =
    data && !data.results.length ? { title: 'No documents found' } : undefined;
  return (
    <ContentContainer placeholderContent={placeholderContent}>
      <Layout direction="column" spacing={8}>
        <>
          <TableComponent
            context={`DASHBOARD:${dashboard?.id}`}
            name="search result"
            activeRowId={documentDetails?.id}
            totalCount={getMaximumByLimit(
              data?.meta?.total,
              SEARCH_RESULTS_LIMIT,
            )}
            columns={columns}
            columnGroups={columnGroups}
            data={data?.results}
            isLoading={isLoading}
            actions={actions}
            state={{
              columnOrder: columnOrder,
              pageIndex: page,
              pageSize,
              sortBy: [sortBy],
              isAllRowsSelected: isAllSelected,
              selectedRowIds: selectedIds,
            }}
            getBulkActions={getBulkActions}
            onPaginate={onPaginate}
            onPageSizeChange={onPageSizeChange}
            onSetColumnOrder={onSetColumnOrder}
            onUpdate={handleUpdate}
            onLoadView={onLoadView}
            onSaveView={onSaveView}
            rowDetails={{ onClick: showDetailsPanel }}
            options={{
              enableExportXlsx: false,
              enablePageSizeSelect: true,
              enableSelectRows: isBulkActionOnDashboardEnabled,
            }}
            reactTableOptions={{
              autoResetSortBy: false,
              disableSortRemove: true,
              manualSortBy: true,
            }}
          />
          {renderMultipleLocationModal(null)}
        </>

        {isUnifiedDetailsPanelEnabled ? (
          <DocumentDetailsPanel
            docHandlerId={documentDetails?.document_handlers[0].id}
            searchKeyworkMatches={documentDetails?.full_text_matches}
            documentName={documentDetails?.document_handlers[0].document_name}
            documentFormatType={documentDetails?.document_handlers[0].file_type}
            onHide={hideDetailsPanel}
            featuredClauses={featuredClauses}
            actionsConfig={{
              navigate: {
                onClick: onOpenDocumentClick,
              },
              download: {
                disabled:
                  documentDetails &&
                  !canDownloadSearchResultItem(documentDetails),
              },
              next: {
                onClick: next,
                disabled:
                  data && currentSelectedIndex === data.results.length - 1,
              },
              previous: {
                onClick: prev,
                disabled: currentSelectedIndex === 0,
              },
            }}
          />
        ) : (
          <DetailsPanel
            document={documentDetails}
            onHide={hideDetailsPanel}
            actions={getPanelActions(data?.results || [], documentDetails!)}
          />
        )}
        <ExportExcel
          query={query}
          isVisible={showExportExcel}
          onHide={() => setShowExportExcel(false)}
          totalDocs={data?.meta?.total}
          totalDupsAndDocs={data?.meta?.total_docs_including_dups}
          selectedFieldIds={columnOrder}
        />
      </Layout>
    </ContentContainer>
  );
};

export default DashboardV2SearchResults;
