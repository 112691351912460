import { Box, Layout, Text, TextInput } from '~/eds';
import { Input } from '~/eds/src/types';

import { isValidTime } from './utils';

export const DateTimeInput = ({
  name,
  value,
  disabled,
  width,
  onChange,
}: Input<string>) => {
  const error = isValidTime(value) ? '' : 'Invalid time (HH:MM)';
  return (
    <Layout direction="column">
      <TextInput
        name={name}
        value={value}
        onChange={onChange}
        width={width}
        error={error}
        disabled={disabled}
      />
      <Box minH={20}>
        <Text variant="tiny" color="status.danger" role="note">
          {error}
        </Text>
      </Box>
    </Layout>
  );
};
