import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  analyzerBulkCopy,
  analyzerBulkMove,
} from '~/components/AnalyzerResultsPage/AnalyzerResultsPage.data';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { BULK_COPY_LIMIT, BULK_MOVE_LIMIT } from '~/constants/max_lengths';
import { FlagType, useFlag } from '~/flags';
import { selectors } from '~/redux';
import search from '~/redux/slices/search';
import { MODAL_FOLDER_TREE } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { capitalize } from '~/utils/strings';

const CopyMoveSearchResults = ({ onActionComplete, resultsCache = {} }) => {
  const modal = useSelector(selectors.selectSearchResultModal);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(search.actions.setModal());
  const pilotSearchQuery = useSelector(selectors.selectPilotSearchQuery);
  const { modalType, context } = modal || {};
  const hasSearchFolderPanel = useFlag(FlagType.SearchFolderPanel);

  if (modalType !== MODAL_FOLDER_TREE) return null;

  const { bulkAction, isAllRowsSelected, selectedRowIds, totalCount } = context;
  const selectedItems = selectedRowIds.map((id) => resultsCache[id]);
  const selectedDocumentsCount = isAllRowsSelected
    ? totalCount
    : selectedItems.length;
  const limitCountPerActionType =
    bulkAction === 'move' ? BULK_MOVE_LIMIT : BULK_COPY_LIMIT;

  const bulkActionCapitalized = capitalize(bulkAction);

  const onBulkMoveCopy = (location) => {
    const handlersIds = isAllRowsSelected
      ? []
      : selectedItems.flatMap((document) =>
          document.document_handlers.map((handler) => handler.id),
        );

    const analyzerBulkAction =
      bulkAction === 'move' ? analyzerBulkMove : analyzerBulkCopy;

    analyzerBulkAction(
      location.id,
      handlersIds,
      pilotSearchQuery,
      isAllRowsSelected,
    )
      .then((res) => showToast(SUCCESS, res.detail))
      .catch((err) => showToast(ERROR, err.response.data.detail))
      .finally(() => onActionComplete());
  };

  return (
    <EcModal
      modalType={modalType}
      width="540px"
      title={`${bulkActionCapitalized} to Folder`}
      folderIdsSelected={[]}
      handleUploadLocationChange={onBulkMoveCopy}
      confirmButtonText={`${bulkActionCapitalized}`}
      hideModal={hideModal}
      bulkAction={bulkAction}
      overLimit={selectedDocumentsCount > limitCountPerActionType}
      enableSearch={hasSearchFolderPanel}
    />
  );
};

export default CopyMoveSearchResults;
