import { api } from '~/redux';
import { PilotId, User } from '~/types';

import { useCurrentUser } from './useCurrentUser';

export const useUsers = (): Record<PilotId, User> => {
  const currentUser = useCurrentUser();
  const clientId = currentUser?.client;

  const { data = {} } = api.endpoints.getAllUsers.useQueryState(clientId);
  return data as Record<PilotId, User>;
};
