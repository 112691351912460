import React from 'react';

import { Button, Layout, Text } from '../../../components';
import { pluralize } from '../../../utils';

function SelectedSummary({
  isAllRowsSelected,
  selectedCount,
  totalCount,
  onToggleSelectAllRows,
}) {
  return (
    <Layout align="center" flex="none">
      <Text>{pluralize(selectedCount, 'item')} selected</Text>
      <Button
        text={
          isAllRowsSelected
            ? 'Clear selection'
            : `Select all (${totalCount}) ${pluralize(totalCount, 'item', {
                enableCount: false,
              })}`
        }
        variant="action"
        onClick={() => onToggleSelectAllRows(!isAllRowsSelected)}
      />
    </Layout>
  );
}

export default SelectedSummary;
