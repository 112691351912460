import React from 'react';
import { components, GroupProps } from 'react-select';

import { Option } from '../../../types';

export const Group = (props: GroupProps<Option, boolean>) => {
  const { children, label } = props;

  return (
    <components.Group {...props}>
      <div aria-label={String(label)} role="group">
        {children}
      </div>
    </components.Group>
  );
};
