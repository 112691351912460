import { useEffect } from 'react';

export const useDisableBodyScroll = (disableScroll: boolean) => {
  useEffect(() => {
    const hideScroll = () => {
      document.body.style.overflow = 'hidden';
    };

    const showScroll = () => {
      document.body.style.overflow = 'unset';
    };

    if (disableScroll) {
      hideScroll();
    } else {
      showScroll();
    }

    return showScroll;
  }, [disableScroll]);
};
