import { atlas } from '~/services';
import { Nullable, Uuid } from '~/types';

import { QuestionGroupResponsePayload } from './types';
import { mapQuestionGroupResponse } from './utils';

type UpdateQuestionGroupParams = {
  id: Uuid;
  name?: string;
  questionIds?: Uuid[];
  pinnedAt?: Nullable<Date>;
  description?: string;
  userIds?: number[];
  departmentIds?: number[];
  visibility?: 'PRIVATE' | 'OPEN' | 'SHARED';
};

type UpdatableQuestionGroup = {
  data: {
    type: 'question_groups';
    attributes: {
      pinned_at?: Nullable<string>;
      description?: string;
      name?: string;
      question_ids?: Uuid[];
      user_ids?: number[];
      department_ids?: number[];
      visibility?: 'PRIVATE' | 'OPEN' | 'SHARED';
    };
  };
};

export const updateQuestionGroup = async ({
  id,
  ...questionGroup
}: UpdateQuestionGroupParams) => {
  const parsedQuestionGroup: UpdatableQuestionGroup = {
    data: {
      type: 'question_groups',
      attributes: {},
    },
  };
  Object.entries(questionGroup).forEach(([key, value]) => {
    if (key === 'pinnedAt') {
      parsedQuestionGroup.data.attributes.pinned_at = value
        ? (value as Date).toISOString().replace('Z', '')
        : null;
    }

    if (key === 'description' && value) {
      parsedQuestionGroup.data.attributes.description = value as string;
    }

    if (key === 'name' && value) {
      parsedQuestionGroup.data.attributes.name = value as string;
    }

    if (key === 'questionIds' && value) {
      parsedQuestionGroup.data.attributes.question_ids = value as Uuid[];
    }

    if (key === 'userIds' && value) {
      parsedQuestionGroup.data.attributes.user_ids = (questionGroup.visibility ===
      'SHARED'
        ? value
        : []) as number[];
    }

    if (key === 'departmentIds' && value) {
      parsedQuestionGroup.data.attributes.department_ids = (questionGroup.visibility ===
      'SHARED'
        ? value
        : []) as number[];
    }

    if (key === 'visibility' && value) {
      parsedQuestionGroup.data.attributes.visibility = value as
        | 'PRIVATE'
        | 'OPEN'
        | 'SHARED';
    }
  });

  const response: QuestionGroupResponsePayload = await atlas.patch(
    `question-groups/${id}`,
    parsedQuestionGroup,
  );
  return mapQuestionGroupResponse(response.data);
};
