export default `
<svg
  aria-hidden="true"
  aria-label="pause"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M10.25 9C9.8125 9 9.5 9.34375 9.5 9.75V14.25C9.5 14.6875 9.8125 15 10.25 15C10.6562 15 11 14.6875 11 14.25V9.75C11 9.34375 10.6562 9 10.25 9ZM13.75 9C13.3125 9 13 9.34375 13 9.75V14.25C13 14.6875 13.3125 15 13.75 15C14.1562 15 14.5 14.6875 14.5 14.25V9.75C14.5 9.34375 14.1562 9 13.75 9ZM12 4C7.5625 4 4 7.59375 4 12C4 16.4375 7.5625 20 12 20C16.4062 20 20 16.4375 20 12C20 7.59375 16.4062 4 12 4ZM12 18.5C8.40625 18.5 5.5 15.5938 5.5 12C5.5 8.4375 8.40625 5.5 12 5.5C15.5625 5.5 18.5 8.4375 18.5 12C18.5 15.5938 15.5625 18.5 12 18.5Z"
    fill="currentcolor"
  />
</svg>
`;
