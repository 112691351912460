import { ReactNode, useState } from 'react';

import { Layout, pluralize, Text, TextInput, types, useModal } from '~/eds';
import { capitalize } from '~/utils/strings';

interface Props {
  entity: 'fields' | 'clauses';
  isAllSelected: boolean;
  entityNames: string[];
  selectedCount: number;
  onDeleteClick: () => void;
  isDeleteActionLoading: boolean;
}

export const useDeleteConfirmationModal = ({
  entity,
  entityNames,
  isAllSelected = false,
  selectedCount,
  onDeleteClick,
  isDeleteActionLoading,
}: Props): [ReactNode, () => void] => {
  const [deleteConfirmation, setDeleteConfirmation] = useState<
    types.Nullable<string>
  >('');
  const [deleteModal, showDeleteModal] = useModal({
    title: `Delete ${entity}`,
    children: (
      <Layout direction="column" spacing={4}>
        <Text>
          Deleting{' '}
          <Text variant="body-bold">
            {isAllSelected ? 'all clauses' : `${entityNames.join(', ')}`}{' '}
          </Text>
          for the{' '}
          <Text variant="body-bold">
            {pluralize(selectedCount, 'selected document')}{' '}
          </Text>
          and {selectedCount > 1 ? 'their' : 'its'} duplicates documents is
          permanent and cannot be undone.
        </Text>
        <Text>Type 'DELETE' in the box below to proceed.</Text>
        <TextInput
          value={deleteConfirmation}
          onChange={setDeleteConfirmation}
          name="delete-confirmation"
        ></TextInput>
      </Layout>
    ),
    primaryAction: {
      variant: 'danger',
      icon: 'trash-permanent',
      text: `Delete ${capitalize(entity)}`,
      disabled: deleteConfirmation !== 'DELETE',
      onClick: onDeleteClick,
      isLoading: isDeleteActionLoading,
    },
  });

  return [deleteModal, showDeleteModal];
};
