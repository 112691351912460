import React from 'react';
import { connect, useDispatch } from 'react-redux';
import uuid from 'uuid';

import { ActionsMenu, Button, Layout, Text } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { useClientSubDomain, useHasFeatureFlag } from '~/hooks';
import { api } from '~/redux';
import searchSlice from '~/redux/slices/search';
import { useRouting } from '~/routing';
import { redirectToPage } from '~/services/redirects';

import { buildClauseAnalyzerParams, calculateF1Score } from '../../util';

const PredictiveInfo = ({
  model,
  onDeleteGuidedAi,
  onDownloadSamples,
  onRetrainGuidedAi,
}) => {
  const dispatch = useDispatch();
  const subdomain = useClientSubDomain();
  const { navigate } = useRouting();

  const hasAdvancedSearch = useHasFeatureFlag(FeatureFlagType.SearchV2);

  const f1Score = calculateF1Score(model?.current?.accuracyMetrics) || 0;

  const {
    data: sectionsAndFolders,
    isFetching: isFetchingSections,
  } = api.endpoints.getFilterSections.useQuery(undefined);

  const {
    data: idf,
    isFetching: isFetchingIdf,
  } = api.endpoints.getIdentifications.useQuery(
    { name: model.name },
    { skip: !model.name },
  );

  const redirectToAnalyzerResults = ({ name }) => {
    const params = new URLSearchParams(
      buildClauseAnalyzerParams(name),
    ).toString();
    redirectToPage(subdomain, `analyzer/results?${params}`);
  };

  const redirectToAdvancedSearch = ({ name }) => {
    const values = [{ provision: name, text_search: [] }];
    const filters = [
      { id: uuid.v4(), fieldId: 'clause', operatorId: 'contains_any', values },
    ];
    const fields = sectionsAndFolders?.fields;
    dispatch(searchSlice.actions.setFilters(filters));
    dispatch(
      searchSlice.actions.setQuery({ booleanQuery: '', filters, fields }),
    );
    navigate('/search');
  };

  const redirectToSearchResults = ({ name }) => {
    if (hasAdvancedSearch) {
      redirectToAdvancedSearch({ name });
    } else {
      redirectToAnalyzerResults({ name });
    }
  };

  const onActionsMenu = (v) => {
    switch (v) {
      case 'RETRAIN_MODEL':
        onRetrainGuidedAi();
        break;
      case 'DOWNLOAD_CSV':
        onDownloadSamples();
        break;
      case 'DELETE_MODEL':
        onDeleteGuidedAi();
        break;
      default:
        return;
    }
  };

  return (
    <Layout
      direction="column"
      border="border.divider"
      borderRadius="m"
      mb={4}
      p={4}
      spacing={4}
    >
      <Text variant="body-bold">Predictive Info</Text>
      <Layout>
        <Layout direction="column">
          <Text>Identifications Found</Text>
          <Text variant="title">
            {idf?.smartClauseTotalIdentifications ?? 0}
          </Text>
        </Layout>
        <Layout direction="column" pl={8}>
          <Text>F1 Score</Text>
          <Text variant="title">{f1Score}</Text>
        </Layout>
      </Layout>
      <Layout spacing={2} justify="space-between">
        <Button
          text="View Identifications"
          variant="primary"
          onClick={() => redirectToSearchResults({ name: model?.name })}
          isLoading={isFetchingSections || isFetchingIdf}
          disable={(idf?.smartClauseTotalIdentifications ?? 0) === 0}
        />
        <ActionsMenu
          name="more actions"
          actions={[
            { label: 'Retrain model', value: 'RETRAIN_MODEL' },
            { label: 'Download training data', value: 'DOWNLOAD_CSV' },
            { label: 'Delete model', value: 'DELETE_MODEL' },
          ]}
          onActionClick={onActionsMenu}
        />
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({ automation }) => ({
  model: automation.model,
});

export default connect(mapStateToProps, {})(PredictiveInfo);
