import React from 'react';

import downloadSrc from '~/assets/images/cta/download.svg';
import { CtaSection } from '~/ui';

const DashboardErrorMessage = () => {
  return (
    <CtaSection
      description="Please refresh the page."
      imageSrc={downloadSrc}
      title="Unable to load dashboard."
    />
  );
};

export default DashboardErrorMessage;
