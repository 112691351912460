import { add } from 'date-fns';
import { noop } from 'lodash';
import { useState } from 'react';

import { RETRY_ERROR } from '~/constants/errors';
import {
  DateInput,
  FormField,
  Layout,
  NumberInput,
  Panel,
  TextArea,
  useToast,
  useUuid,
} from '~/eds';
import { CreditSummary } from '~/features/billing';
import { api } from '~/redux';
import { useRouting } from '~/routing';
import { Nullable } from '~/types';

interface FormState {
  currentRecurringCredits: Nullable<number>;
  newRecurringCredits: Nullable<number>;
  effectiveDate: Nullable<Date>;
  renewalDate: Nullable<Date>;
  notes: Nullable<string>;
}

interface Props {
  creditSummary: Nullable<CreditSummary>;
  onClose: () => void;
}

const initialFormState = {
  currentRecurringCredits: 0,
  newRecurringCredits: 0,
  effectiveDate: null,
  renewalDate: add(new Date(), { years: 1 }),
  notes: '',
};

export const RecurringCreditsPanel = ({ creditSummary, onClose }: Props) => {
  const [id, updateId] = useUuid();
  const { params } = useRouting();

  const [
    updateRecurringCredits,
    { isLoading },
  ] = api.endpoints.updateRecurringCredits.useMutation();
  const [formState, setFormState] = useState<FormState>(
    creditSummary && creditSummary.recurringCredits
      ? {
          ...initialFormState,
          currentRecurringCredits: creditSummary.recurringCredits.credits,
          renewalDate: creditSummary.recurringCredits.renewalDate,
          notes: creditSummary.recurringCredits.notes,
        }
      : initialFormState,
  );

  const { toast } = useToast();

  const {
    currentRecurringCredits,
    newRecurringCredits,
    effectiveDate,
    renewalDate,
    notes,
  } = formState;

  const disableAddCreditsButton =
    newRecurringCredits! <= 0 || effectiveDate === null;

  return (
    <Panel
      footer={{
        actions: [
          {
            level: 'tertiary' as const,
            text: 'Cancel',
            onClick: onClose,
          },
          {
            disabled: disableAddCreditsButton,
            isLoading,
            level: 'primary' as const,
            text: 'Add Credits',
            tooltip: disableAddCreditsButton
              ? 'Please make sure all fields are filled correctly'
              : '',
            onClick: () => {
              updateRecurringCredits({
                clientId: Number(params.clientId),
                credits: newRecurringCredits!,
                effectiveDate: effectiveDate!,
                idempotencyKey: `recurring_${id}`,
                notes: notes!,
              })
                .unwrap()
                .then((data) => {
                  if (data) {
                    toast({
                      message: 'Credits added successfully',
                      status: 'success',
                    });
                    onClose();
                  }
                })
                .catch((err) => {
                  toast({
                    message: err[0].detail ?? RETRY_ERROR,
                    status: 'danger',
                  });
                });
            },
          },
        ],
      }}
      hidden={{
        onHide: onClose,
      }}
      position="right"
      title="Manage Recurring Credits"
      width="m"
    >
      <Layout preset="form-fields">
        <FormField<number, false>
          disabled={true}
          input={NumberInput}
          inputProps={{
            min: 1,
            max: 1_000_000_000, // 1 billion per product requirement
          }}
          label="Current Recurring Credit Amount"
          name="Current Recurring Credit Amount"
          value={currentRecurringCredits}
          onChange={noop}
        />
        <FormField<number, false>
          required
          input={NumberInput}
          inputProps={{
            min: 1,
          }}
          label="New Recurring Credit Amount"
          name="New Recurring Credit Amount"
          value={newRecurringCredits}
          onChange={(newRecurringCredits) => {
            updateId();
            setFormState({
              ...formState,
              newRecurringCredits,
            });
          }}
        />
        <FormField<Date, false>
          required
          input={DateInput}
          label="Effective Date"
          name="Effective Date"
          value={effectiveDate}
          onChange={(effectiveDate) => {
            updateId();
            setFormState({
              ...formState,
              effectiveDate,
              renewalDate: effectiveDate
                ? add(effectiveDate, { years: 1 })
                : creditSummary?.recurringCredits?.renewalDate
                ? new Date(creditSummary.recurringCredits.renewalDate)
                : add(new Date(), { years: 1 }),
            });
          }}
        />
        <FormField<Date, false>
          disabled={true}
          input={DateInput}
          label="Renewal Date"
          name="Renewal Date"
          width="100%"
          value={renewalDate}
          onChange={noop}
        />
        <FormField<string, false>
          footer="Maximum 300 characters"
          input={TextArea}
          inputProps={{
            maxLength: 300,
          }}
          label="Notes"
          maxLength={300}
          name="Notes"
          value={notes}
          onChange={(notes) => {
            updateId();
            setFormState({
              ...formState,
              notes,
            });
          }}
        />
      </Layout>
    </Panel>
  );
};
