import React from 'react';

import { Chips } from '../../../components';
import { SharedChipProps } from '../../../types';

interface Props {
  /** List of chips */
  chips: SharedChipProps[];
  /** Limit visible chips to display */
  limit?: number;
}

export const ChipsCell = ({ chips, limit }: Props) => {
  return <Chips chips={chips} limit={limit} />;
};
