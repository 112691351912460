import { button } from './button';

const base = {
  ...button.unset,
  border: 'border',
  borderRadius: 'pill',
  paddingBottom: 0.5,
  paddingLeft: 2,
  paddingRight: 2,
  paddingTop: 0.5,
  width: 'max-content',
};

export const pill = {
  reaction: {
    s: {
      ...base,
    },
    m: {
      ...base,
      paddingBottom: 1,
      paddingTop: 1,
    },
  },
};
