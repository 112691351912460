import { AsyncValue, Filter, OperatorId, Value } from '~/evifields';
import { Nullable } from '~/types';

export const clearFilter = (filter: Filter) => ({
  ...filter,
  asyncValue: undefined,
  operatorId: null,
  hasError: false,
  values: [],
});

export const clearFilterValues = (filter: Filter): Filter => ({
  ...filter,
  asyncValue: undefined,
  hasError: false,
  values: [],
});

export const updateFilterOperator = (
  filter: Filter,
  operatorId: Nullable<OperatorId>,
) => ({
  ...clearFilter(filter),
  operatorId,
});

export const updateFilterValues = (
  filter: Filter,
  values: Value[],
  asyncValue?: AsyncValue<Value>,
) => {
  return {
    ...filter,
    asyncValue,
    hasError: false,
    values,
  };
};
