import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { Actions } from '~/eds';

import EcClientItem from '../EcClientItem';
import styles from './EcClientTable.styles';

class EcClientTable extends Component {
  renderClientListItems() {
    const { listItems, handleShowModal } = this.props;

    return listItems.map((item, i) => {
      return (
        <EcClientItem item={item} key={i} handleShowModal={handleShowModal} />
      );
    });
  }
  renderNewClientButton() {
    const { handleShowModal } = this.props;
    const actions = [
      {
        text: 'Add New Client',
        onClick: () => handleShowModal('create'),
        level: 'action',
      },
      {
        text: 'Check Bucket',
        onClick: () => handleShowModal('checkBucket'),
        level: 'action',
      },
    ];
    return <Actions direction="column" actions={actions} />;
  }

  render() {
    const { classes } = this.props;

    return (
      <table className={classes.clientTable}>
        <thead>
          <tr>
            <th className={classes.headerColumn}>Clients</th>
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerColumn} />
            <th className={classes.headerExtraOptionsColumn}>
              {this.renderNewClientButton()}
            </th>
          </tr>
          <tr>
            <th>NAME</th>
            <th>BUCKET</th>
            <th>INDEX</th>
            <th>DOMAIN</th>
            <th>CLIENT TYPE</th>
            <th>
              TOTAL ACTIVE DOC <br /> COUNT
            </th>
            <th>PRE-SIG DOC COUNT</th>
            <th>POST-SIG DOC COUNT</th>
            <th>RESTORATION HUB COUNT</th>
            <th>BILLABLE DOC COUNT</th>
            <th>DOC LIMIT</th>
            <th className={classes.headerExtraOptionsColumn} />
          </tr>
        </thead>
        <tbody>{this.renderClientListItems()}</tbody>
      </table>
    );
  }
}

EcClientTable.propTypes = {
  classes: PropTypes.object.isRequired,
  listItems: PropTypes.array.isRequired,
};
export default injectSheet(styles)(EcClientTable);
