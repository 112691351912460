import { types } from '~/eds';
import { EntityType, SaveSearchPermissionType } from '~/enums';

import type { EntityVisibilityType } from './types';

export const getSaveSearchPermissionOptions = (clientName: string) => {
  return [
    { label: 'Only me (private)', value: SaveSearchPermissionType.Private },
    {
      label: 'Specific departments (groups) / users',
      value: SaveSearchPermissionType.Shared,
    },
    { label: `Anyone at ${clientName}`, value: SaveSearchPermissionType.Open },
  ];
};
export const getUserDepartmentArray = (
  entityVisibility: EntityVisibilityType,
) => {
  if (entityVisibility) {
    const { departmentIds = [], userIds = [] } = entityVisibility;
    const depObjs = departmentIds.map((id) => ({
      id,
      role: null,
      type: EntityType.Department,
    }));
    const userObjs = userIds.map((id) => ({
      id,
      role: null,
      type: EntityType.User,
    }));
    return [...depObjs, ...userObjs];
  }
  return [];
};

export const validateShareField = (field: types.Form.Field, value: unknown) => {
  if (field.name === 'shareField') {
    const { visibility, userIds, departmentIds } = value as {
      visibility: 'OPEN' | 'PRIVATE' | 'SHARED';
      userIds: number[];
      departmentIds: number[];
    };
    if (
      visibility === 'SHARED' &&
      (!userIds || userIds.length === 0) &&
      (!departmentIds || departmentIds.length === 0)
    ) {
      return 'Please select at least one user or department';
    }
  }
  return null;
};
