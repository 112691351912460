// brightness scales with semantic label if applicable
export const scales = [
  { label: 'quiet', value: 80 },
  { label: 'secondary', value: 100 },
  { value: 150 },
  { value: 200 },
  { value: 300 },
  { value: 400 },
  { label: 'base', value: 500 },
  { label: 'text.quiet', value: 600 },
  { value: 650 },
  { value: 700 },
  { value: 800 },
  { label: 'text', value: 900 },
];
