import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { Layout } from '~/eds';

import { ERROR, SUCCESS } from '../../../types/toast.types';
import EcButton from '../../Shared/EcButton';
import EcCard from '../../Shared/EcCard';
import { showToast } from '../../Shared/EcToast';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import LockIcon from '../../Shared/Icons/LockIcon';
import { updateUser } from './AccountInformation.data';
import styles from './AccountInformation.styles';
import AvatarPanel from './AvatarPanel';

const cardContentStyles = { padding: '24px 16px' };

class AccountInformation extends Component {
  constructor(props) {
    super(props);

    this.handleOnSaveClick = this.handleOnSaveClick.bind(this);
    this.handleShowToast = this.handleShowToast.bind(this);
    this.handleInputValueChange = this.handleInputValueChange.bind(this);

    this.state = {
      image: null,
      scale: 1.2,
      userData: {
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        email: this.props.user.email,
        avatar: this.props.user.avatar,
        client: this.props.user.client,
      },
    };
  }

  handleInputValueChange(e) {
    let newUserData = this.state.userData;
    newUserData[e.target.name] = e.target.value;

    this.setState({ userData: newUserData });
  }

  handleShowToast(type, text) {
    showToast(type, text);
  }

  handleOnSaveClick() {
    const { userData } = this.state;
    const { setCurrentUser } = this.props;

    updateUser(userData)
      .then((res) => {
        setCurrentUser(res);
        this.handleShowToast(SUCCESS, 'User information has been updated.');
      })
      .catch(() => {
        this.handleShowToast(ERROR, 'First and last name cannot be empty.');
      });
  }

  render() {
    const { classes, setCurrentUser } = this.props;
    // const { classes, user } = this.props;
    const { userData } = this.state;
    const isAvatarViable = 'avatar' in userData;
    return (
      <EcCard title="Account Information" contentStyles={cardContentStyles}>
        {/* <AvatarPanel user={user} /> */}
        {/* <hr className={classes.settingsRowSeparator} /> */}
        <Layout justify="space-between" spacing={0}>
          <Layout align="flex-start" direction="column">
            <div className={classes.settingsRow}>
              <div>
                <div className={classes.inputLabel}>FIRST NAME</div>
                <div className={classes.inputContainer}>
                  <input
                    type="text"
                    name="first_name"
                    value={userData.first_name}
                    onChange={this.handleInputValueChange}
                  />
                </div>
              </div>
              <div>
                <div className={classes.inputLabel}>LAST NAME</div>
                <div className={classes.inputContainer}>
                  <input
                    type="text"
                    name="last_name"
                    value={userData.last_name}
                    onChange={this.handleInputValueChange}
                  />
                </div>
              </div>
            </div>
            <hr className={classes.settingsRowSeparator} />
            <div className={classes.settingsRow}>
              <div>
                <div className={classes.inputLabel}>EMAIL ADDRESS</div>
                <div className={classes.fakeInputContainer}>
                  <div>{userData.email}</div>
                  <LockIcon size="20" />
                </div>
              </div>
            </div>
            <div className={classes.protectedInputsMessage}>
              Due to security reasons, we do not allow changing your email
              address. If you wish to update this, please contact your
              organization administrator.
            </div>
            <hr className={classes.settingsRowSeparator} />
            <div className={classes.settingsRow}>
              <EcButton
                id="user_accountInformation_saveChangesButton"
                yellow
                text="Save Changes"
                iconLeft={<CheckmarkIcon size="20" />}
                onClick={this.handleOnSaveClick}
                disabled={
                  !userData.first_name.length || !userData.last_name.length
                }
              />
            </div>
          </Layout>
          {isAvatarViable && (
            <Layout align="flex-start" direction="column">
              <div className={classes.settingsRow}>
                <div className={classes.inputLabel}>PROFILE PICTURE</div>
              </div>
              <AvatarPanel user={userData} setCurrentUser={setCurrentUser} />
            </Layout>
          )}
        </Layout>
      </EcCard>
    );
  }
}

export default injectSheet(styles)(AccountInformation);
