export default `
<svg
  aria-hidden="true"
  aria-label="format-code-block"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M14.2656 4.08034C13.7344 3.92409 13.2031 4.23659 13.0469 4.76784L9.04688 18.7678C8.89062 19.2991 9.20312 19.8303 9.73438 19.9866C10.2656 20.1428 10.7969 19.8303 10.9531 19.2991L14.9531 5.29909C15.1094 4.76784 14.7969 4.23659 14.2656 4.08034ZM16.7969 7.83034C16.3906 8.20534 16.3906 8.86159 16.7969 9.23659L19.5781 12.0178L16.7969 14.8303C16.3906 15.2053 16.3906 15.8616 16.7969 16.2366C17.1719 16.6428 17.8281 16.6428 18.2031 16.2366L21.7031 12.7366C22.1094 12.3616 22.1094 11.7053 21.7031 11.3303L18.2031 7.83034C17.8281 7.42409 17.1719 7.42409 16.7969 7.83034ZM7.20312 7.83034C6.82812 7.42409 6.17188 7.42409 5.79688 7.83034L2.29688 11.3303C1.89062 11.7053 1.89062 12.3616 2.29688 12.7366L5.79688 16.2366C6.17188 16.6428 6.82812 16.6428 7.20312 16.2366C7.60938 15.8616 7.60938 15.2053 7.20312 14.8303L4.42188 12.0178L7.20312 9.23659C7.60938 8.86159 7.60938 8.20534 7.20312 7.83034Z"
    fill="currentcolor"
  />
</svg>
`;
