import { findLastIndex } from 'lodash';

import { IconColor, IconType } from '../../types';
import { Section } from './types';

interface IconProps {
  color: IconColor;
  icon: IconType;
}

interface Props {
  isCurrent: boolean;
  section: Section;
}

export const getCompletedCount = (sections: Section[]): number => {
  return sections.filter((section) => section.isCompleted).length;
};

export const getIconProps = ({ isCurrent, section }: Props): IconProps => {
  const { error, isCompleted, isOptional, isLoading } = section;

  let color: IconColor;
  let icon: IconType;

  // order matters
  if (error) {
    color = 'status.danger';
    icon = 'status-danger';
  } else if (isCompleted) {
    color = 'status.active';
    icon = 'status-success';
  } else if (isOptional) {
    color = isCurrent ? 'status.active' : 'status.inactive';
    icon = isCurrent ? 'status-current' : 'status-optional';
  } else if (isLoading) {
    color = 'inherit';
    icon = 'loading';
  } else {
    color = isCurrent ? 'status.active' : 'status.info';
    icon = isCurrent ? 'status-current' : 'status-not-started';
  }

  return { color, icon };
};

export const findLastCompletedIndex = (sections: Section[]): number => {
  return findLastIndex(sections, ['isCompleted', true]);
};
