import React from 'react';

import { Chip, LoadingShimmer, NumberText } from '~/eds';
import { Uuid } from '~/types';

import { usePollModelProgress } from './hooks';

type ProgressMetric =
  | 'docsInScope'
  | 'docsProcessed'
  | 'status'
  | 'valuesFound';

interface Props {
  metric: ProgressMetric;
  modelId: Uuid;
}

export const ModelProgress = ({ metric, modelId }: Props) => {
  const { isLoading, progress, progressChip } = usePollModelProgress({
    modelId,
  });

  if (isLoading) {
    return <LoadingShimmer />;
  } else if (progress) {
    switch (metric) {
      case 'docsInScope':
      case 'docsProcessed':
      case 'valuesFound':
        const number = progress[metric];
        return <NumberText number={number} />;
      case 'status':
        return <Chip {...progressChip} />;
      default:
        return null;
    }
  } else {
    return null;
  }
};
