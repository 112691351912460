import React from 'react';

import { WizardPageLayout } from '~/ui';

import WorkflowTemplatesList from './WorkflowTemplatesList';

const steps = ['Select Workflow', 'Create Ticket', 'Take Action'];

const WizardWorkflowTemplatesList = (props) => {
  return (
    <WizardPageLayout
      currentStep="Select Workflow"
      steps={steps}
      title="New Ticket"
      onClose={props.hideModal}
    >
      <WorkflowTemplatesList {...props} />
    </WizardPageLayout>
  );
};

export default WizardWorkflowTemplatesList;
