import {
  black2,
  black3,
  black4,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  radioContainerContent: {
    display: 'inline-block',
    marginBottom: '16px',
    '& > div:not(:last-child)': {
      marginBottom: '12px',
    },
  },
  sectionSubtitle: {
    marginBottom: '8px',
    color: black2,
    fontSize: '12px',
    fontWeight: semibold,
  },
  excludeClientsInfo: {
    marginBottom: '8px',
    color: black3,
    fontSize: '13px',
  },
  sectionActions: {
    marginTop: '32px',
  },
  multipleValuesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '12px',
  },
  multipleValues: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '8px',
  },
  multipleValuesEmpty: {
    color: black4,
    fontSize: '15px',
  },
};

export default styles;
