export const filterSectionsByName = (sections, filterValue) =>
  sections.map((section) => ({
    title: section.title,
    fields: section.fields.filter((field) =>
      field.name.toLowerCase().includes(filterValue.toLowerCase()),
    ),
  }));

export const lowConfidenceFieldsIds = (sections) => {
  return sections.reduce(function (acc, section) {
    return acc.concat(
      section.fields
        .filter((field) => field.is_flagged)
        .map((field) => field.id),
    );
  }, []);
};
