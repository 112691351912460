import React, { useState } from 'react';
import uuid from 'uuid';

import { getEsignatureIntegrations } from '~/api/integrations';
import { EsignatureType, FeatureFlagType } from '~/enums';
import { withCurrentUser } from '~/hocs';
import { useAsync } from '~/hooks';
import { CardList, LoadingContainer, Text } from '~/ui';
import { featureFlagIncluded } from '~/utils/user';

import ESignatureIntegrationCard from './ESignatureIntegrationCard';

function testHasIntegration(currentUser, integration) {
  let hasIntegration;
  switch (integration.type) {
    case EsignatureType.Adobesign:
      hasIntegration = featureFlagIncluded(
        currentUser,
        FeatureFlagType.Adobesign,
      );
      break;
    case EsignatureType.Docusign:
      hasIntegration = featureFlagIncluded(
        currentUser,
        FeatureFlagType.Docusign,
      );
      break;
    default:
      hasIntegration = false;
  }
  return hasIntegration;
}

function ESignatureIntegrations({
  // injected,
  currentUser,
}) {
  const [esignatureIntegrations, setEsignatureIntegrations] = useState([]);
  const [refetchId, setRefetchId] = useState(uuid());

  const { isLoading } = useAsync(getEsignatureIntegrations, null, {
    condition: refetchId,
    deps: [refetchId],
    successHandler: (integrations) => {
      const integrationsWithPermissions = integrations.map((integration) => ({
        ...integration,
        disabled: !testHasIntegration(currentUser, integration),
      }));
      setEsignatureIntegrations(integrationsWithPermissions);
    },
  });

  async function handleRefetch() {
    setRefetchId(uuid());
  }

  if (!esignatureIntegrations) {
    return (
      <Text variant="m-spaced">
        Unable to fetch third party integrations, please try again.
      </Text>
    );
  }

  const activeEsignatureIntegrationCount = esignatureIntegrations.reduce(
    (count, { disabled, id }) => {
      return id && !disabled ? count + 1 : count;
    },
    0,
  );
  return (
    <CardList title="Electronic Signatures">
      <LoadingContainer isLoading={isLoading}>
        {esignatureIntegrations.map((esignatureIntegration) => (
          <ESignatureIntegrationCard
            key={esignatureIntegration.type}
            activeEsignatureIntegrationCount={activeEsignatureIntegrationCount}
            disabled={esignatureIntegration.disabled}
            esignatureIntegration={esignatureIntegration}
            isRefetching={isLoading}
            onRefetch={handleRefetch}
          />
        ))}
      </LoadingContainer>
    </CardList>
  );
}

export default withCurrentUser(ESignatureIntegrations);
