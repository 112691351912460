import React, { useState } from 'react';

import { Nullable } from '../../types';
import { Button } from '../Button';

interface Props<Item> {
  /** List of items */
  items: Item[];
  /** Limit the number of visible items */
  limit: number;
  /** Serialize the item to a display string */
  serializeItem: (item: Item) => string;
  /** Controls the number of items to reveal (reveals all items by default) */
  step?: number;
}

export const useShowMoreItems = <Item extends unknown>({
  items,
  limit: initialLimit,
  serializeItem,
  step = items.length,
}: Props<Item>): [Item[], Nullable<JSX.Element>, Nullable<JSX.Element>] => {
  const [limit, setLimit] = useState(initialLimit);

  const visibleItems = items.slice(0, limit);
  const hiddenItems = items.slice(limit);
  const tooltip = hiddenItems.map(serializeItem).join('\n');

  const handleShowMore = () => setLimit(limit + step);

  const showMoreText = `View all (${items.length})`;

  const showMore =
    hiddenItems.length > 0 ? (
      <Button
        PRIVATE_isPadded={false}
        text={showMoreText}
        tooltip={tooltip}
        variant="action"
        onClick={handleShowMore}
      />
    ) : null;

  const showLess =
    hiddenItems.length === 0 && limit > initialLimit ? (
      <Button
        PRIVATE_isPadded={false}
        text="View less"
        variant="action"
        onClick={() => setLimit(initialLimit)}
      />
    ) : null;

  return [visibleItems, showMore, showLess];
};
