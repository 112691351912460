export default `
<svg
  aria-hidden="true"
  aria-label="arrow-back"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M8.21875 18.1484L4.21875 14.1484C4.0625 14.0234 4 13.8359 4 13.6172C4 13.4297 4.0625 13.2422 4.21875 13.1172L8.21875 9.11719C8.5 8.80469 8.96875 8.80469 9.25 9.11719C9.5625 9.39844 9.5625 9.86719 9.25 10.1484L6.53125 12.8672H18.5V6.36719C18.5 5.96094 18.8125 5.61719 19.25 5.61719C19.6562 5.61719 20 5.96094 20 6.36719V13.6172C20 14.0547 19.6562 14.3672 19.25 14.3672H6.53125L9.25 17.1172C9.5625 17.3984 9.5625 17.8672 9.25 18.1484C8.96875 18.4609 8.5 18.4609 8.21875 18.1484Z"
    fill="currentcolor"
  />
</svg>
`;
