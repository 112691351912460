import { meanBy } from 'lodash';
import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { formatNumberPercentage } from '../../utils';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { ProgressScore } from './ProgressScore';
import { Steps } from './Steps';
import { Stage } from './types';
import { STEP_TAIL_OFFSET_PIXEL_SIZE, testHasSteps } from './utils';

interface Props {
  isActive: boolean;
  stage: Stage;
  onSelect: () => void;
}

export const ProgressStage = memo(({ isActive, stage, onSelect }: Props) => {
  const styles = useStyles();

  const {
    description,
    progress: overrideProgress,
    progressScore = 0,
    progressScoreBreakpoints,
    steps,
    title,
  } = stage;
  const progress = overrideProgress || meanBy(steps, 'progress') || 0;

  const hasSteps = testHasSteps(steps);
  const hasStarted = progress > 0;
  const isCompleted = progress === 1;

  const stepTailOffsetWidth =
    hasSteps && !isCompleted ? STEP_TAIL_OFFSET_PIXEL_SIZE : 0;

  const progressPercentage = formatNumberPercentage(progress, { decimal: 0 });

  const progressValueWidth = isActive
    ? `calc(${progressPercentage} + ${stepTailOffsetWidth}px)`
    : 'inherit';

  const componentStyles = {
    bar: {
      backgroundColor:
        !isActive && hasStarted ? 'status.active' : 'status.active.secondary',
      borderRadius: 'pill',
      height: progressIndicatorSize,
      minWidth: progressIndicatorSize,
      position: 'relative',
      width: isActive ? '100%' : progressIndicatorSize,
    },
    contents: {
      inset: 0,
      position: 'absolute',
    },
    value: {
      background:
        isCompleted || isActive
          ? styles.background.gradient.linear.ai
          : 'inherit',
      borderRadius: 'inherit',
      height: 'inherit',
      maxWidth: '100%',
      width: progressValueWidth,
    },
  };

  const progressValue = (
    <Layout align="center" justify="center" styles={componentStyles.value} />
  );

  let progressContents;
  if (!isActive) {
    if (isCompleted) {
      progressContents = (
        <Layout align="center" justify="center" h="100%">
          <Icon color="inverse.icon" icon="check" />
        </Layout>
      );
    }
  } else {
    if (hasSteps) {
      progressContents = <Steps steps={steps} />;
    } else {
      if (hasStarted) {
        progressContents = (
          <ProgressScore
            breakpoints={progressScoreBreakpoints}
            percentage={progressPercentage}
            score={progressScore}
          />
        );
      }
    }
  }

  return (
    <Layout
      align="flex-start"
      direction="column"
      flex={isActive ? 'auto' : 'none'}
      px={isActive ? 4 : undefined}
      spacing={4}
    >
      <Box styles={componentStyles.bar} onClick={onSelect}>
        {progressValue}
        <Box styles={componentStyles.contents}>{progressContents}</Box>
      </Box>
      <Layout direction="column">
        <Text variant="tiny-bold">{title}</Text>
        <Text variant="tiny">{description}</Text>
      </Layout>
    </Layout>
  );
});

const progressIndicatorSize = 'icon.m';
