import PT from 'prop-types';
import React, { Fragment } from 'react';

import Divider from '../Divider';
import FlexLayout from '../FlexLayout';
import PageLayout from '../PageLayout';
import Text from '../Text';

function Header({ currentStep, steps, title, onClose, rightContent }) {
  const centerContent = (
    <FlexLayout alignItems="center" space={3}>
      {steps.map((step, i) => {
        const isActiveStep = step === currentStep;
        return (
          <Fragment key={step}>
            <Text
              color="gray-800"
              disabled={!isActiveStep}
              variant={isActiveStep ? 's-dense-bold' : 's-dense'}
            >
              {step}
            </Text>
            {i < steps.length - 1 && <Divider width="m" />}
          </Fragment>
        );
      })}
    </FlexLayout>
  );
  return (
    <PageLayout.Header
      centerContent={centerContent}
      title={title}
      onClose={onClose}
      rightContent={rightContent}
    />
  );
}

Header.propTypes = {
  /** Current step */
  currentStep: PT.string,
  /** Steps */
  steps: PT.arrayOf(PT.string.isRequired).isRequired,
  /** Page title */
  title: PT.shape({
    text: PT.string.isRequired,
    maxWidth: PT.number,
  }).isRequired,
  /** Renders an optional close icon on the right */
  onClose: PT.func,
};

export default Header;
