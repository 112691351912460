import React, { memo } from 'react';

import {
  Box,
  ContentContainer,
  Layout,
  SearchInput,
  StatusMessage,
} from '../../components';
import {
  Action,
  MentionedEntity,
  Nullable,
  SharedChipProps,
  Uuid,
} from '../../types';
import { CommentsFilters } from './CommentsFilters';
import { CommentsFooter } from './CommentsFooter';
import { CommentsThread } from './CommentsThread';
import {
  Comment,
  CommentData,
  CommentFilters,
  CommentId,
  EnabledFilters,
  Entity,
  EntityResolvers,
  MentionEntityType,
  Mentions,
  User,
} from './types';
import { defaultFilters, getThreadMessage } from './utils';

interface Props {
  /** required */
  /** List of comments */
  comments: Comment[];
  /** The entity the comments belong to */
  entity: Entity<'document'>;
  /** Entity resolvers */
  entityResolvers: EntityResolvers;
  /* Flag used when loading comments. */
  isLoadingThread: boolean;
  /** Flag to check if mentions is loading */
  isMentionsLoading: boolean;
  /* Flag used when posting a new comment. */
  isSubmitting: boolean;
  /** This is the parent component that will be display first when the Entity is a comment. */
  parentComment: Nullable<Comment>;
  /** Search input string  */
  search: string;
  /** Main viewer (a User) */
  viewer: User;
  /* Handles the filtering of comments.*/
  onUpdateFilters: (filters: CommentFilters) => void;
  /** Copy link action is called */
  onCopyLink: (comment: Comment) => void;
  /** Delete a given comment */
  onDelete: (comment: Comment) => void;
  /** Select a comment to reply to */
  onReply: (comment: Comment) => void;
  /* Resolving a comment. */
  onResolveComment: (commentData: Comment) => void;
  /** Handles search update */
  onSearchChange: (updatedSearch: Nullable<string>) => void;
  /** Creating a new Comment to a ThreadId */
  onSubmit: (commentData: CommentData) => void;
  /* Unresolving a comment. */
  onUnresolveComment: (commentData: Comment) => void;
  /** Toggles visibility for highlights */
  onUpdate: (comment: Comment) => void;
  /** When provided, will navigate to the active comment */
  activeCommentId?: Nullable<CommentId>;
  /** Which filters are enabled */
  enabledFilters: EnabledFilters;

  /** optional */
  /** Wether the CommentDraft will appear or not */
  enableReply?: boolean;
  /** Filters applied to comments. */
  filters?: CommentFilters;
  /** Flag to check if mentions is fetching */
  isMentionsFetching?: boolean;
  /* When provided, will indicate loading state for the matching comment*/
  loadingCommentId?: Nullable<CommentId>;
  /** Mentions that are result of a search */
  mentions?: Mentions;
  /** Message to display when reply is not available */
  replyStatusMessage?: string;
  /** Resolved mentions */
  resolvedMentions?: Mentions;
  /** Collapse all comments that has collapisible text */
  shouldCollapseAllComments?: boolean;
  /** Chip to be added on the Text editor to provide context.  */
  textEditorChips?: SharedChipProps[];
  /** filter actions of comment menu for a given comment */
  onFilterAction?: (action: Action, comment: Comment) => Action;
  /* Handles highlight click */
  onHighlightClick?: (highlight: Uuid) => void;
  /** This optional callback is fired when mentioning.
   * @param mention the mentioned entity
   */
  onMention?: (mention: MentionedEntity<MentionEntityType>) => void;
  /** Callback for searching async mentions */
  onMentionSearch?: (updatedSearch: string) => void;
}

export const Comments = memo(
  ({
    activeCommentId,
    comments,
    enabledFilters,
    enableReply,
    entity,
    entityResolvers,
    filters = defaultFilters,
    isLoadingThread,
    isMentionsFetching: initialIsMentionsFetching,
    isMentionsLoading,
    isSubmitting,
    loadingCommentId,
    mentions = {},
    parentComment,
    replyStatusMessage = '',
    resolvedMentions = {},
    search,
    shouldCollapseAllComments,
    textEditorChips,
    viewer,
    onCopyLink,
    onDelete,
    onFilterAction,
    onHighlightClick,
    onMention,
    onMentionSearch,
    onReply,
    onResolveComment,
    onSearchChange,
    onSubmit,
    onUnresolveComment,
    onUpdate,
    onUpdateFilters,
  }: Props) => {
    const emptyCommentsMessage = comments.length
      ? undefined
      : getThreadMessage(filters);

    const isMentionsFetching = initialIsMentionsFetching ?? isMentionsLoading;

    return (
      <Layout bg="background" direction="column" h="100%">
        <ContentContainer
          loadingContent={{
            isLoading: isLoadingThread || isMentionsLoading,
            message: 'Loading comments…',
          }}
        >
          <>
            <Layout px={6} pt={6} pb={2}>
              <Box flex={1}>
                <SearchInput
                  disabled={comments.length === 0 && search.length === 0}
                  placeholder="Search Comments"
                  name="Search Comments"
                  value={search}
                  onChange={onSearchChange}
                />
              </Box>
              <CommentsFilters
                filters={filters}
                enabledFilters={enabledFilters}
                onUpdateFilters={onUpdateFilters}
              />
            </Layout>
            <CommentsThread
              activeCommentId={activeCommentId}
              comments={comments}
              emptyCommentsMessage={emptyCommentsMessage}
              entityResolvers={entityResolvers}
              isMentionsLoading={isMentionsFetching}
              loadingCommentId={loadingCommentId}
              mentions={mentions}
              parentComment={parentComment}
              resolvedMentions={resolvedMentions}
              shouldCollapseAllComments={shouldCollapseAllComments}
              viewer={viewer}
              onCopyLink={onCopyLink}
              onDelete={onDelete}
              onFilterAction={onFilterAction}
              onHighlightClick={onHighlightClick}
              onResolveComment={onResolveComment}
              onReply={onReply}
              onUnresolveComment={onUnresolveComment}
              onUpdate={onUpdate}
              onMentionSearch={onMentionSearch}
              onMention={onMention}
            />
            {enableReply ? (
              <CommentsFooter
                chips={textEditorChips}
                entity={entity}
                isMentionsLoading={isMentionsFetching}
                isSubmitting={isSubmitting}
                mentions={mentions}
                resolvedMentions={resolvedMentions}
                onMention={onMention}
                onMentionSearch={onMentionSearch}
                onSubmit={onSubmit}
              />
            ) : (
              replyStatusMessage && (
                <Layout justify="center" my={6}>
                  <StatusMessage message={replyStatusMessage} />
                </Layout>
              )
            )}
          </>
        </ContentContainer>
      </Layout>
    );
  },
);
