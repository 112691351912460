import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Prompt } from 'react-router-dom';

export function useClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
}

export function useEscape(handler) {
  useEffect(() => {
    function handleEscape(e) {
      if (e.key === 'Escape') {
        handler();
      }
    }

    document.addEventListener('keyup', handleEscape);

    return () => {
      document.removeEventListener('keyup', handleEscape);
    };
  }, [handler]);
}

export function useLockBodyScroll(shouldLock = true) {
  useLayoutEffect(() => {
    if (shouldLock) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [shouldLock]);
}

// https://usehooks.com/useHover/
// https://gist.github.com/gragland/cfc4089e2f5d98dde5033adc44da53f8#gistcomment-3022446
export function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = (e) => {
    const node = ref.current;
    if (!node) return setValue(false);
    setValue(e.target === node || node.contains(e.target));
  };

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        const doc = node.ownerDocument;
        doc.addEventListener('mouseover', handleMouseOver);

        return () => {
          doc.removeEventListener('mouseover', handleMouseOver);
        };
      }
    },
    [ref], // Recall only if ref changes
  );

  return [ref, value];
}

export function useConfirmChanges(
  condition,
  message = 'You have unsaved changes that will be lost if you decide to continue. \nAre you sure you want to leave this page?',
) {
  function confirmChanges(e) {
    if (condition) {
      e.returnValue = true;
    }
  }

  useEffect(
    () => {
      window.addEventListener('beforeunload', confirmChanges);
      return () => window.removeEventListener('beforeunload', confirmChanges);
    },
    [condition], // eslint-disable-line
  );

  return <Prompt when={condition} message={message} />;
}
