import { supportedFileExtensions } from '~/constants/esignature';
import { EsignatureType, FileExtensionType } from '~/enums';

export const testIsFileExtensionSupported = (
  fileExtension: string,
  esignatureProvider: EsignatureType,
): boolean =>
  fileExtension === FileExtensionType.Docx ||
  supportedFileExtensions[esignatureProvider].includes(
    fileExtension.toLowerCase() as FileExtensionType,
  );
