import { black3, evisortBlue } from '../../../assets/shared-styles/general';

const styles = {
  selectedOption: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    marginRight: '8px',
    padding: '3px 5px',
    borderBottom: '1px solid #ccc',
    backgroundColor: '#fff',
    color: evisortBlue,
    fontSize: '14px',
  },
  accountDepartmentItem: {
    width: '360px',
    display: 'flex',
    alignItems: 'center',
  },
  accountDepartmentSelected: {
    width: '120px',
    color: black3,
  },
  allowDownloadWrapper: {
    width: '250px',
  },
  allowDownloadContainer: {
    display: 'flex',
    alignItems: 'center',
    '& label': {
      color: black3,
    },
  },
  removeDepartmentIconWrapper: {
    backgroundColor: 'transparent',
    border: 'none',
  },
};

export default styles;
