import React, { memo } from 'react';

import { Button } from '../../Button';
import { BaseFooter } from './BaseFooter';

interface Props {
  /** Left action button */
  leftAction: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
}

export const DropdownFooter = memo(({ leftAction }: Props) => {
  return (
    <BaseFooter>
      <Button variant="action" {...leftAction} />
    </BaseFooter>
  );
});
