import React from 'react';

import { Icon, Layout, Modal, Text } from '~/eds';
import { BulkActionType } from '~/enums';
import { actionTypeContent, actionTypeTitle } from '~/utils';

interface Props {
  limit: number;
  count: number;
  actionType: BulkActionType;
  isVisible: boolean;
  hideModal: () => void;
}

/* This component can eventually hopefully be deprecated in favor of using `useLimitExceededModal` hook
 * once our legacy class components such as DocumentsPage are replaced with functional components. */
const LimitExceededModal = ({
  limit,
  count = 0,
  actionType,
  isVisible = false,
  hideModal,
}: Props) => {
  const children = (
    <Layout spacing={3}>
      <Icon color="status.warning" icon="status-warning" size="l" />
      <Text>
        Only {limit} documents can be {actionTypeContent(actionType)} at a time.
        Please select fewer documents to complete this action.
      </Text>
    </Layout>
  );

  return (
    <Modal
      title={`${actionTypeTitle(
        actionType,
      )} ${count} Documents: Limit Exceeded`}
      isVisible={isVisible}
      onHide={hideModal}
      onCancel={null}
      primaryAction={{
        text: 'Got it',
        variant: 'primary',
        onClick: hideModal,
      }}
      children={children}
    />
  );
};

export default LimitExceededModal;
