import React from 'react';

import {
  DateInput,
  FormField,
  NumberInput,
  RadioGroup,
  Select,
  TextArea,
  TextInput,
  typedMemo,
} from '~/eds';
import { Nullable } from '~/types';

import { DataFieldType } from '../enums';
import { DataFieldValue } from '../types';
import { FieldData } from './types';
import { testEnableSort } from './utils';

interface Props<T extends DataFieldType> {
  fieldData: FieldData<T>;
  value: Nullable<DataFieldValue<T>>;
  onChange: (updatedValue: Nullable<DataFieldValue<T>>) => void;
}

export const DefaultFieldValue = typedMemo(
  <T extends DataFieldType>({ fieldData, onChange, value }: Props<T>) => {
    const { input, inputProps } = getInput(fieldData);

    if (input === null) {
      return null;
    }

    return (
      <FormField<DataFieldValue<T>, false>
        optional
        label="Default Value"
        name={`${fieldData.fieldType} default value`}
        input={input}
        inputProps={inputProps}
        value={value}
        onChange={onChange}
      />
    );
  },
);

const getInput = <T extends DataFieldType>(fieldData: FieldData<T>) => {
  const { fieldConfig, fieldType } = fieldData;

  let input;
  let inputProps;
  switch (fieldType) {
    case DataFieldType.ARRAY_MULTIPLE:
    case DataFieldType.ARRAY_SINGLE: {
      const { enableMultiple, optionsConfig, optionValues } = fieldConfig;
      const disabled = optionValues.length === 0;
      const placeholder = disabled
        ? 'Please specify field value(s)'
        : 'Select default value';

      input = Select;
      inputProps = {
        disabled,
        enableSelectAll: false,
        enableSort: testEnableSort(optionsConfig),
        isMulti: enableMultiple,
        options: optionValues.map((value) => ({ label: value, value })),
        placeholder: placeholder,
      };
      break;
    }
    case DataFieldType.ATTACHMENT:
      input = null;
      break;
    case DataFieldType.BOOLEAN:
      input = RadioGroup;
      inputProps = {
        options: [
          {
            label: 'True',
            value: true,
          },
          {
            label: 'False',
            value: false,
          },
        ],
      };
      break;
    // TODO: DataFieldType.CLAUSE to be supported
    case DataFieldType.DATE:
      input = DateInput;
      break;
    case DataFieldType.NUMBER:
      input = NumberInput;
      break;
    case DataFieldType.TEXT_AREA:
      input = TextArea;
      break;
    case DataFieldType.STRING:
    default:
      input = TextInput;
      break;
  }

  return {
    input,
    inputProps,
  };
};
