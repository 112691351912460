import { pilot } from '../../../services';

export const updateClientWhitelist = (
  clientId,
  inboundUploadLocation,
  whitelistedUsers,
  whitelistedEmailList,
  whitelistedDomainList,
) => {
  return pilot.put(`client/${clientId}/inbound/`, {
    inbound_default_folder: inboundUploadLocation,
    inbound_whitelisted_users: whitelistedUsers,
    inbound_whitelisted_emails: whitelistedEmailList,
    inbound_whitelisted_domains: whitelistedDomainList,
  });
};

export const getClientWhitelist = (clientId) =>
  pilot.get(`client/${clientId}/inbound/`);
