import { decamelizeKeys } from 'humps';

import { pilot } from '../../../services';

export const createUser = (
  clientId,
  email,
  firstName,
  lastName,
  jobTitle,
  role,
  password,
  departmentsToSubmit,
  workflowAdmin,
  automationAdmin,
) => {
  return pilot.post('user/', {
    client: clientId,
    first_name: firstName,
    last_name: lastName,
    email,
    job_title: jobTitle,
    role,
    password,
    departments: departmentsToSubmit,
    is_workflow_admin: workflowAdmin,
    is_automation_admin: automationAdmin,
  });
};

export const updateUser = (
  userId,
  email,
  firstName,
  lastName,
  role,
  departmentsToSubmit,
  jobTitle,
  workflowAdmin,
  automationAdmin,
) => {
  return pilot.put(`user/${userId}/`, {
    id: userId,
    first_name: firstName,
    last_name: lastName,
    email,
    role,
    departments: departmentsToSubmit,
    job_title: jobTitle,
    is_workflow_admin: workflowAdmin,
    is_automation_admin: automationAdmin,
  });
};

export const getClientUsers = (clientId, page, searchText) =>
  pilot.get(`user/${clientId}/list/?page=${page}&name=${searchText}`);

export const getUser = (userId) => pilot.get(`user/${userId}/`);

export const deleteUser = (userId) => pilot.remove(`user/${userId}/`);

export const exportUsersExcelFile = ({ clientId, onlyHeaders }) => {
  const params = { client_id: clientId, only_headers: onlyHeaders };
  return pilot.get('user/export/', { params, responseType: 'blob' });
};

export const importUsersListExcelFile = (formData) =>
  pilot.post('user/import/summary/', formData);

export const downloadImportResultsExcelFile = ({
  clientId,
  importId,
  resultType,
}) => {
  const params = {
    client_id: clientId,
    import_id: importId,
    result_type: resultType,
  };
  return pilot.get('user/import/results/', { params, responseType: 'blob' });
};

export const startImportUsersListProcessing = (formData) =>
  pilot.post('user/import/', formData);

export const cancelImportJob = async ({ clientId, importId }) => {
  const params = decamelizeKeys({ clientId, importId });
  return pilot.patch('user/import/cancel-request/', params);
};

export const setImportJobAcknowledge = async ({ clientId, importId }) => {
  const params = decamelizeKeys({ clientId, importId });
  return pilot.patch('user/import/acknowledged/', params);
};
