import React from 'react';

import { Layout, Section, StatusMessage } from '../../components';
import { typedMemo } from '../../utils';
import { InfoContent } from './InfoContent';
import * as types from './types';

export const InfoSection = typedMemo(
  <T extends types.InfoType>({
    description,
    infos = [],
    title,
    toggle,
  }: types.InfoSection<T>) => {
    return (
      <Section
        key={title}
        description={description}
        title={title}
        toggle={toggle}
      >
        <Layout preset="subsections">
          {infos.map((info) => (
            <React.Fragment key={info.title}>
              <Section
                description={info.description}
                heading="h4"
                title={info.title}
                toggle={info.toggle}
              >
                <InfoContent {...info} />
              </Section>
              {info.statusMessage && <StatusMessage {...info.statusMessage} />}
            </React.Fragment>
          ))}
        </Layout>
      </Section>
    );
  },
);
