import { uniq, uniqBy } from 'lodash';
import { useMemo, useState } from 'react';

import { types } from '~/eds';
import {
  useDepartments,
  useResolveUsers,
  useSearchMentionWithPermission,
} from '~/hooks';
import { api, PermissionEntityType } from '~/redux';
import { createDepartmentMention, transformUserToMention } from '~/utils';

interface Props {
  searchText: string;
  entityPermission: types.Entity<PermissionEntityType>;
  userIds: types.PilotId[];
}

interface UseCommentMentionsState {
  isFetching: boolean;
  isLoading: boolean;
  isLoadingUsers: boolean;
  searchedMentions: types.Mentions;
  mentions: types.Mentions;
  resolvedUsers: Record<types.PilotId, types.User>;
  addMentionedEntity: (
    mention: types.MentionedEntity<PermissionEntityType>,
  ) => void;
}

export const useCommentMentions = ({
  searchText,
  entityPermission,
  userIds,
}: Props): UseCommentMentionsState => {
  const {
    mentions,
    isFetching: isMentionsFetching,
    isLoading: isMentionsLoading,
  } = useSearchMentionWithPermission({
    searchText,
    entityPermission,
  });

  const [mentionedEntities, setMentionedEntities] = useState<types.Mentions>(
    {},
  );

  const {
    users = {},
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
  } = useResolveUsers({ userIds, skip: !entityPermission });

  const userIdsToValidate = useMemo(() => {
    const mentionedUserIds = mentionedEntities?.user?.map(({ id }) => id) || [];
    return uniq([...userIds, ...mentionedUserIds]);
  }, [userIds, mentionedEntities]);

  const { data: permissionsData } = api.endpoints.getEntityPermissions.useQuery(
    {
      entity: entityPermission,
      userIds: userIdsToValidate.map((id) => Number(id)),
    },
    {
      skip:
        !userIdsToValidate.length ||
        !entityPermission.id ||
        !entityPermission.type,
    },
  );

  const userMentions = useMemo(() => {
    return (
      Object.values(users)
        // need to remove the deleted users
        .filter((user) => !user.isDeleted)
        .map((user) => {
          const hasAccess =
            permissionsData?.userPermissionMap?.[user.id]?.hasAccess;
          return transformUserToMention(user, !!hasAccess);
        })
    );
  }, [users, permissionsData]);

  // we need to re-check the permissions in case someone was added during mentioning
  const mentionedEntitiesWithUpdatedPermissions = useMemo(() => {
    return {
      ...mentionedEntities,
      user: [
        ...(mentionedEntities?.user || []).map((user) => ({
          ...user,
          isInvalid: !permissionsData?.userPermissionMap?.[user.id]?.hasAccess,
        })),
      ],
    };
  }, [permissionsData, mentionedEntities]);

  // TODO: we need to be able to resolve departments
  const departments = useDepartments();

  const departmentMentions = useMemo(
    () => Object.values(departments || {}).map(createDepartmentMention),
    [departments],
  );

  const allResolvedMentions = useMemo(() => {
    return {
      user: [
        ...(mentionedEntitiesWithUpdatedPermissions?.user || []),
        ...userMentions,
      ],
      department: [
        ...departmentMentions,
        ...(mentionedEntitiesWithUpdatedPermissions?.department || []),
      ],
    };
  }, [mentionedEntitiesWithUpdatedPermissions, userMentions]);

  const addMentionedEntity = (
    mention: types.MentionedEntity<PermissionEntityType>,
  ) => {
    const newMentions = {
      ...mentionedEntities,
      [mention.type]: uniqBy(
        [...(mentionedEntities[mention.type] || []), mention],
        'id',
      ),
    };
    setMentionedEntities(newMentions);
  };

  return {
    isFetching: isMentionsFetching || isFetchingUsers,
    isLoading: isMentionsLoading || isLoadingUsers,
    isLoadingUsers,
    searchedMentions: mentions,
    mentions: allResolvedMentions,
    resolvedUsers: users,
    addMentionedEntity,
  };
};
