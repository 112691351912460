import React, { useEffect, useMemo, useState } from 'react';

import {
  Layout,
  pluralize,
  RadioGroup,
  Section,
  Select,
  StatusMessage,
  types,
} from '~/eds';
import { DataFieldType } from '~/features/fields';
import { DocumentInformationField } from '~/features/search';
import { FlagType, useFlag } from '~/flags';

import { BulkEditFieldType } from '../types';
import { FieldEdit } from './FieldEdit';

type RadioGroupType = types.Nullable<BulkEditFieldType>;
const getRadioGroupOptions = (
  isDeleteEnabled: boolean,
  isAttachmentFieldSelected: boolean,
): types.Option<RadioGroupType>[] => [
  {
    label: 'Add to/Replacing existing values',
    info:
      'Replacing will overwrite any data currently in the field and cannot be undone.',
    value: 'add-replace',
    disabled: isAttachmentFieldSelected,
  },
  ...(isDeleteEnabled
    ? [
        {
          label: 'Delete existing values',
          info:
            'This will remove any data currently in the field and cannot be undone.',
          value: 'delete' as const,
        },
      ]
    : []),
];
const addReplaceRadioOptions = [
  {
    label: 'Add to existing values',
    value: 'add',
  },
  {
    label: 'Replace existing values',
    value: 'replace',
  },
];
export type FieldEditValue = {
  value: any;
  keepOldValues?: boolean;
};
export type EditFieldData = {
  selectedFields: string[];
  actionType: RadioGroupType;
  fieldValueRecord: Record<string, FieldEditValue>;
};
interface Props {
  fields: Record<string, DocumentInformationField>;
  onChange: (params: EditFieldData) => void;
}
export const EditFieldsAction = ({ fields, onChange }: Props) => {
  const isDeleteActionEnabled = useFlag(FlagType.BulkDeleteFieldValues);
  const [selectedFields, setSelectedField] = useState<string[]>([]);
  const [actionType, setActionType] = useState<RadioGroupType>(null);
  const [fieldValueRecord, setFieldValueRecord] = useState<
    Record<string, FieldEditValue>
  >({});

  const isAttachmentField = (field: string) =>
    fields[field].type === DataFieldType.ATTACHMENT;

  useEffect(() => {
    onChange({
      selectedFields,
      actionType,
      fieldValueRecord,
    });
  }, [fieldValueRecord, actionType, selectedFields]);

  const options = useMemo(() => {
    return Object.values(fields)
      .filter((field) => field.isEditable)
      .map((field) => ({ value: field.label, label: field.label }));
  }, [fields]);

  const selectedAttachmentFields = useMemo(
    () => selectedFields.filter(isAttachmentField),
    [selectedFields, fields],
  );

  const onFieldSelected = (updatedValue: string[] | null) => {
    const fieldsList = updatedValue ?? [];
    setSelectedField(fieldsList);
    const updatedFieldValueRecord = Object.fromEntries(
      fieldsList.map((field) => [field, fieldValueRecord[field] ?? {}]),
    );

    if (actionType === 'add-replace' && updatedValue?.some(isAttachmentField)) {
      setActionType(null);
    }

    setFieldValueRecord(updatedFieldValueRecord);
  };
  const onActionTypeChange = (updatedValue: RadioGroupType) => {
    setActionType(updatedValue);
    if (updatedValue === 'delete') {
      setFieldValueRecord({});
    }
  };

  const updateFieldValueRecord = (
    field: string,
    fieldEditValue: Partial<FieldEditValue>,
  ) => {
    setFieldValueRecord({
      ...fieldValueRecord,
      [field]: { ...fieldValueRecord[field], ...fieldEditValue },
    });
  };

  return (
    <Layout preset="subsections">
      <Section
        title="Data Fields"
        description="Select the data fields you want to edit."
      >
        <Select
          isMulti
          name="fields-select"
          onChange={onFieldSelected}
          value={selectedFields}
          options={options}
          enableSelectAll={false}
        />
        <RadioGroup
          name="action-type-radio"
          options={getRadioGroupOptions(
            isDeleteActionEnabled,
            selectedAttachmentFields.length > 0,
          )}
          value={actionType}
          onChange={onActionTypeChange}
        />
        {selectedAttachmentFields.length > 0 && (
          <StatusMessage
            message={`Can only delete the value in **${selectedAttachmentFields.join(
              ', ',
            )}** ${pluralize(selectedAttachmentFields.length, 'field', {
              enableCount: false,
            })}.`}
          />
        )}
      </Section>
      {selectedFields.length > 0 &&
        actionType === 'add-replace' &&
        selectedFields.map((field) => (
          <Layout key={field} preset="form-fields">
            <FieldEdit
              field={fields[field]}
              onChange={(updatedValue: any) =>
                updateFieldValueRecord(field, {
                  value: updatedValue,
                })
              }
              value={fieldValueRecord[field]?.value}
            />
            {fields[field].type === DataFieldType.ARRAY_MULTIPLE && (
              <RadioGroup
                columns={2}
                name={`field-${field}-add-replace-radio`}
                value={
                  fieldValueRecord[field]?.keepOldValues === undefined
                    ? null
                    : fieldValueRecord[field]?.keepOldValues
                    ? 'add'
                    : 'replace'
                }
                options={addReplaceRadioOptions}
                onChange={(selectedValue) =>
                  updateFieldValueRecord(field, {
                    keepOldValues: selectedValue === 'add',
                  })
                }
              />
            )}
          </Layout>
        ))}
    </Layout>
  );
};
