import { bold, evisortBlue } from '../../../assets/shared-styles/general';

const styles = {
  ecFilterField: {
    display: 'flex',
    alignItems: 'center',
  },
  removeFieldContainer: {
    marginRight: '8px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  fieldContainer: {
    width: '240px',
  },
  blueLine: {
    width: '16px',
    border: 0,
    borderTop: `2px solid ${evisortBlue}`,
    margin: 0,
  },
  provisionValuesContainer: {
    marginLeft: '28px',
  },
  provisionTextContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
  },
  provisionText: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '8px',
  },
  searchProvisionWrapper: {
    display: 'inline-block',
    marginTop: '12px',
  },
  searchProvisionAction: {
    display: 'flex',
    alignItems: 'center',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
    cursor: 'pointer',
    '& svg': {
      marginRight: '4px',
    },
  },
};

export default styles;
