import { createReducer } from '@reduxjs/toolkit';

import * as actions from '../actions';

const initialState = {
  id: null,
  departmentTree: [],
  folderTree: {},
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.clientSet, (state, action) => {
    const { id, departmentTree, folderTree } = action.payload;

    state.id = id;
    state.departmentTree = departmentTree;
    state.folderTree = folderTree;
  });
  builder.addCase(actions.clientSetFolderTree, (state, action) => {
    state.folderTree = action.payload;
  });
});
