import DEPRECATED_reducers from '~/reducers';

import api from '../api';
import { default as automation } from './automation';
import { chatbotSlice } from './chatbot';
import { default as clauseLibrary } from './clauseLibrary';
import currentUser from './currentUser';
import dashboards from './dashboards';
import dashboardV2 from './dashboardV2';
import documentEditors from './documentEditors';
import docGroup from './documentGroup';
import documents from './documents';
import documentsNavigation from './documentsNavigation';
import { fieldAiSlice } from './fieldAi';
import myDeletedItems from './myDeletedItems';
import pages from './pages';
import restorationHub from './restorationHub';
import savedDashboards from './savedDashboards';
import search from './search';
import searchV3 from './searchV3';
import { default as turnTracking } from './turnTracking';
import upload from './upload';
import webhooks from './webhooks';
import xRay from './x-ray';

const { clearPageBanner, setPageBanner } = pages.actions;
const { setCurrentUser } = currentUser.actions;
const { setDashboard } = dashboards.actions;
const {
  resetAutomationStates,
  resetAutomationFilters,
  setAutomationFilterFolderTree,
  setAutomationFilterClause,
  setAutomationFilterFields,
  setAutomationFilter,
  setAutomationFieldValues,
  setAutomationQuickAiDataForm,
  setAutomationGuidedAiDataForm,
  setAutomationGuidedAiCsvUploadId,
  setAutomationGuidedAiSampleLabel,
  resetAutomationQuickAiDataForm,
  resetAutomationGuidedAiDataForm,
  setAutomationClause,
  setAutomationSample,
  setAutomationModel,
  deleteAutomationModel,
  setAutomationManageStage,
  setAutomationClausePagination,
  setAutomationSamplePageSize,
  setAutomationSamplePagination,
} = automation.actions;
const {
  setClauseLibraryClauseForm,
  setClauseLibraryClauseSummaries,
  resetClauseLibraryExceptSelectedClause,
  cleanupClauseSummaries,
  setClauseLibraryEditMode,
  setClauseLibraryIsLoadingSummaries,
  setClauseLibraryView,
  setClauseLibraryVariationForm,
  setClauseLibraryClauseGuidanceNotes,
  setClauseLibraryVariationGuidanceNotes,
  setClauseLibraryVariationClauseText,
  resetClauseLibraryClauseFormGuidanceError,
  resetClauseLibraryVariationEditorsValidationErrors,
  resetClauseLibraryVariationGuidanceError,
  resetClauseLibraryVariationTextError,
  setClauseLibrarySelectedClause,
  setClauseLibraryFilter,
} = clauseLibrary.actions;

const { addEditor, removeEditor, setEditors } = documentEditors.actions;

const {
  addFieldAiTestCase,
  resetFieldAi,
  setFieldAiActiveModel,
  setFieldAiActiveTestcaseNumber,
  setFieldAiActiveVersionNumber,
  setFieldAiConfigId,
  setFieldAiConfiguration,
  setFieldAiCreditsSummary,
  setFieldAiDefaultOption,
  setFieldAiDismissedIncorrectStatusMessage,
  setFieldAiDocumentViewer,
  setFieldAiField,
  setFieldAiFilters,
  setFieldAiHasViewedWelcomeContent,
  setFieldAiInstructions,
  setFieldAiIsNewVersion,
  setFieldAiIsOpenEnded,
  setFieldAiModelAction,
  setFieldAiModelVersions,
  setFieldAiPublishMode,
  setFieldAiOptimize,
  setFieldAiOptions,
  setFieldAiRedirect,
  setFieldAiReviewIncorrectCount,
  setFieldAiTestCases,
  setFieldAiUpdatedTestCase,
} = fieldAiSlice.actions;

const {
  resetTurnTracking,
  setTurnTrackingHistory,
  setTurnTrackingParties,
} = turnTracking.actions;

const { setCurrentDocument } = documentsNavigation.actions;

export const actions = {
  clearPageBanner,
  setPageBanner,
  setCurrentUser,
  setDashboard,
  // Automation Hub
  resetAutomationStates,
  resetAutomationFilters,
  setAutomationFilterFolderTree,
  setAutomationFilterClause,
  setAutomationFilterFields,
  setAutomationFilter,
  setAutomationFieldValues,
  setAutomationQuickAiDataForm,
  setAutomationGuidedAiDataForm,
  setAutomationGuidedAiCsvUploadId,
  setAutomationGuidedAiSampleLabel,
  resetAutomationQuickAiDataForm,
  resetAutomationGuidedAiDataForm,
  setAutomationClause,
  setAutomationSample,
  setAutomationModel,
  deleteAutomationModel,
  setAutomationManageStage,
  setAutomationClausePagination,
  setAutomationSamplePageSize,
  setAutomationSamplePagination,
  // Clause Library
  setClauseLibraryClauseForm,
  setClauseLibraryClauseSummaries,
  setClauseLibraryIsLoadingSummaries,
  setClauseLibraryView,
  resetClauseLibraryExceptSelectedClause,
  cleanupClauseSummaries,
  setClauseLibraryEditMode,
  setClauseLibraryVariationForm,
  setClauseLibraryClauseGuidanceNotes,
  setClauseLibraryVariationGuidanceNotes,
  setClauseLibraryVariationClauseText,
  resetClauseLibraryClauseFormGuidanceError,
  resetClauseLibraryVariationEditorsValidationErrors,
  resetClauseLibraryVariationGuidanceError,
  resetClauseLibraryVariationTextError,
  setClauseLibrarySelectedClause,
  setClauseLibraryFilter,
  // document editors
  addEditor,
  removeEditor,
  setEditors,
  // Field AI
  addFieldAiTestCase,
  resetFieldAi,
  setFieldAiActiveModel,
  setFieldAiActiveTestcaseNumber,
  setFieldAiActiveVersionNumber,
  setFieldAiConfigId,
  setFieldAiConfiguration,
  setFieldAiCreditsSummary,
  setFieldAiDefaultOption,
  setFieldAiDismissedIncorrectStatusMessage,
  setFieldAiDocumentViewer,
  setFieldAiField,
  setFieldAiFilters,
  setFieldAiHasViewedWelcomeContent,
  setFieldAiInstructions,
  setFieldAiIsNewVersion,
  setFieldAiIsOpenEnded,
  setFieldAiModelAction,
  setFieldAiModelVersions,
  setFieldAiPublishMode,
  setFieldAiOptimize,
  setFieldAiOptions,
  setFieldAiRedirect,
  setFieldAiReviewIncorrectCount,
  setFieldAiTestCases,
  setFieldAiUpdatedTestCase,
  // Turn Tracking
  resetTurnTracking,
  setTurnTrackingHistory,
  setTurnTrackingParties,
  // Document Navigation
  setCurrentDocument,
};

export const reducer = {
  ...DEPRECATED_reducers,
  [api.reducerPath]: api.reducer,
  [automation.name]: automation.reducer,
  [chatbotSlice.name]: chatbotSlice.reducer,
  [clauseLibrary.name]: clauseLibrary.reducer,
  [currentUser.name]: currentUser.reducer,
  [dashboards.name]: dashboards.reducer,
  [dashboardV2.name]: dashboardV2.reducer,
  [documents.name]: documents.reducer, //eslint-disable-line import/no-named-as-default-member
  [documentEditors.name]: documentEditors.reducer,
  [docGroup.name]: docGroup.reducer, //eslint-disable-line import/no-named-as-default-member
  [documentsNavigation.name]: documentsNavigation.reducer,
  [fieldAiSlice.name]: fieldAiSlice.reducer,
  [myDeletedItems.name]: myDeletedItems.reducer, //eslint-disable-line import/no-named-as-default-member
  [pages.name]: pages.reducer,
  [restorationHub.name]: restorationHub.reducer, //eslint-disable-line import/no-named-as-default-member
  [savedDashboards.name]: savedDashboards.reducer,
  [search.name]: search.reducer, //eslint-disable-line import/no-named-as-default-member
  [searchV3.name]: searchV3.reducer,
  [turnTracking.name]: turnTracking.reducer,
  [upload.name]: upload.reducer,
  [webhooks.name]: webhooks.reducer,
  [xRay.name]: xRay.reducer,
};

export const slices = {
  upload,
  webhooks,
  xRay,
};
