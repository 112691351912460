import { pilotV3 } from '~/services';

import { NavigationHandlerParam } from '../../types/searchV3/navigation';
import { toSortParam } from '../../utils';

export const getPositionByHandler = ({
  handler_id,
  query,
  sortBy,
}: NavigationHandlerParam) => {
  return pilotV3.post(
    'search/position-by-handler/',
    { data: { attributes: { handler_id, query } } },
    { params: { sort: toSortParam(sortBy) } },
  );
};
