import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import EcButton from '../../Shared/EcButton';
import EcCheckbox from '../../Shared/EcCheckbox';
import EcModalCard from '../../Shared/EcModalCard';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import styles from './SaveNFlagModal.styles';

class SaveNFlagModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnSaveClick = this.handleOnSaveClick.bind(this);
    this.state = { note: '', reasons: [] };
  }

  handleOnSaveClick() {
    const { note, reasons } = this.state;
    const { handleActionClick, hideModal } = this.props;

    handleActionClick({ note, reasons });
    hideModal();
  }

  handleOnCheckboxChange(value) {
    const { reasons } = this.state;

    if (reasons.includes(value)) {
      this.setState({ reasons: reasons.filter((reason) => reason !== value) });
    } else {
      this.setState({ reasons: [...reasons, value] });
    }
  }

  render() {
    const { classes, title, hideModal } = this.props;
    const { reasons } = this.state;

    return (
      <EcModalCard
        title={title}
        content={
          <div className={classes.modalBodyContent}>
            <p className={classes.modalInfo}>
              Please select one or more problems you might have ran into while
              reviewing the document.
            </p>
            <div className={classes.reasonsContainer}>
              <div className={classes.reasonWrapper}>
                <EcCheckbox
                  checked={reasons.includes('Poor scan quality')}
                  id="SaveNFlagModal/PoorScanQualityCheckbox"
                  onClick={() =>
                    this.handleOnCheckboxChange('Poor scan quality')
                  }
                />
                <label
                  className={classes.checkboxLabel}
                  htmlFor="SaveNFlagModal/PoorScanQualityCheckbox"
                >
                  Poor scan quality
                </label>
              </div>
              <div className={classes.reasonWrapper}>
                <EcCheckbox
                  checked={reasons.includes('Foreign language')}
                  id="SaveNFlagModal/ForeignLanguageCheckbox"
                  onClick={() =>
                    this.handleOnCheckboxChange('Foreign language')
                  }
                />
                <label
                  className={classes.checkboxLabel}
                  htmlFor="SaveNFlagModal/ForeignLanguageCheckbox"
                >
                  Foreign language
                </label>
              </div>
              <div className={classes.reasonWrapper}>
                <EcCheckbox
                  checked={reasons.includes('Not a contract')}
                  id="SaveNFlagModal/NotAContractCheckbox"
                  onClick={() => this.handleOnCheckboxChange('Not a contract')}
                />
                <label
                  className={classes.checkboxLabel}
                  htmlFor="SaveNFlagModal/NotAContractCheckbox"
                >
                  Not a contract
                </label>
              </div>
              <div className={classes.reasonWrapper}>
                <EcCheckbox
                  checked={reasons.includes(
                    'Unsure about specific data field (list data field(s) below)',
                  )}
                  id="SaveNFlagModal/UnsureAboutSpecificDataFieldCheckbox"
                  onClick={() =>
                    this.handleOnCheckboxChange(
                      'Unsure about specific data field (list data field(s) below)',
                    )
                  }
                />
                <label
                  className={classes.checkboxLabel}
                  htmlFor="SaveNFlagModal/UnsureAboutSpecificDataFieldCheckbox"
                >
                  Unsure about specific data field (list data field(s) below)
                </label>
              </div>
            </div>
            <div className={classes.additionalNotesWrapper}>
              <p>ADDITIONAL NOTES</p>
              <textarea
                data-testid="additional-notes-textarea"
                name="additionalNotes"
                id="additionalNotesTextarea"
                cols="30"
                rows="7"
                onChange={(event) =>
                  this.setState({ note: event.target.value })
                }
              />
            </div>
          </div>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              yellow
              iconLeft={<CheckmarkIcon size="20" />}
              text="Save"
              onClick={this.handleOnSaveClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

SaveNFlagModal.propTypes = {
  title: PropTypes.string.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(SaveNFlagModal);
