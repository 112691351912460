import React from 'react';

import { Filters_DEPRECATED } from '~/components/Shared/Filters_DEPRECATED';
import { Field } from '~/components/Shared/Filters_DEPRECATED/types';
import { Filter } from '~/evifields';

import { Operation } from './types';
import { getFields } from './utils';

interface Props {
  filters: Filter[];
  onChange: (updatedFilters: Filter[]) => void;
}
export const AuditLogsFilters = ({ filters, onChange }: Props) => {
  const selectedOperation = filters[0]?.values?.[0] as Operation;
  const fields: Record<string, Field> = getFields(selectedOperation);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Filters_DEPRECATED
      fields={fields}
      filters={filters}
      groups={[]}
      options={{
        showInlineManageFilters: true,
        disableManageFilters: true,
        disableRemoveFilter: true,
        disableClearFilters: true,
      }}
      onChange={onChange}
    />
  );
};
