import React from 'react';
import { useDispatch } from 'react-redux';

import { ticketUpdate } from '~/actions';
import { patchTicketStage } from '~/api';
import rocketSrc from '~/assets/images/cta/rocket.svg';
import { showToast } from '~/components/Shared/EcToast';
import { useAsync } from '~/hooks';
import { api, TAGS } from '~/redux';
import { useRouting } from '~/routing';
import { SUCCESS } from '~/types/toast.types';
import { CtaSection } from '~/ui';

const SendTicketActionCta = ({ ticketId, title, nextStageString }) => {
  const { navigate } = useRouting();
  const dispatch = useDispatch();

  const {
    executor: handleNextStageClick,
    isLoading: isLoadingNextStage,
  } = useAsync(
    patchTicketStage,
    { ticketId },
    {
      successHandler: async (res) => {
        dispatch(api.util.invalidateTags([TAGS.TAG_BY_TICKET_SUMMARY]));
        dispatch(
          ticketUpdate({
            stages: res.stages,
          }),
        );
        showToast(SUCCESS, 'Successfully completed stage');
        navigate(`/workflow/tickets/${ticketId}`);
      },
      errorToast: 'Transition to the next stage failed. Please try again.',
    },
  );

  return (
    <CtaSection
      actionButton={{
        text: 'Send',
        onClick: handleNextStageClick,
        isLoading: isLoadingNextStage,
      }}
      description={'Send your ticket for ' + nextStageString}
      imageSrc={rocketSrc}
      title={title}
    />
  );
};

export default SendTicketActionCta;
