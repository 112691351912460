import { useMemo } from 'react';

import { types } from '~/eds';
import { api, PermissionEntityType } from '~/redux';
import { DepartmentV2 } from '~/types';
import { transformUserToMention } from '~/utils';

import { useClient } from './useClient';

type Props = {
  searchText: string;
  entityPermission: types.Entity<PermissionEntityType>;
};

type UseSearchMentionWithPermissionState = {
  isFetching: boolean;
  isLoading: boolean;
  mentions: types.Mentions;
};

export const useSearchMentionWithPermission = ({
  entityPermission,
  searchText,
}: Props): UseSearchMentionWithPermissionState => {
  const { id: clientId } = useClient();

  const {
    data: users = { results: [] },
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
  } = api.useFindUsersByNameOrEmailQuery({ query: searchText, pageSize: 10 });

  const {
    data: departments = { results: [] },
    isLoading: isLoadingDepartments,
    isFetching: isFetchingDepartments,
  } = api.useFindDepartmentsByNameQuery(
    {
      query: searchText,
      pageSize: 10,
      clientId,
    },
    {
      skip: !clientId,
    },
  );

  const {
    data: permissionsData,
    isLoading: isLoadingPermissions,
    isFetching: isFetchingPermissions,
  } = api.endpoints.getEntityPermissions.useQuery(
    {
      entity: entityPermission,
      userIds: users.results.map(({ id }: any) => id as number),
    },
    {
      skip:
        !users.results.length || !entityPermission.id || !entityPermission.type,
    },
  );

  const userMentions = useMemo(
    () =>
      users.results.map((user: types.User) => {
        const userHasAccess = !!permissionsData?.userPermissionMap?.[user.id]
          ?.hasAccess;
        const userMention: types.MentionedEntity<'user'> = transformUserToMention(
          user,
          userHasAccess,
        );
        return userMention;
      }),
    [users, permissionsData],
  );

  const departmentMentions = useMemo(() => {
    return departments.results.map(transformDepartment);
  }, [departments]);

  return {
    mentions: {
      user: userMentions,
      department: departmentMentions,
    },
    isLoading: isLoadingUsers || isLoadingDepartments || isLoadingPermissions,
    isFetching:
      isFetchingUsers || isFetchingDepartments || isFetchingPermissions,
  };
};

const transformDepartment = ({
  id,
  name,
  members_count: memberCount,
}: DepartmentV2): types.MentionedEntity<'department'> => ({
  id: String(id),
  isInvalid: false,
  label: name,
  tooltip: '',
  type: 'department',
  data: {
    department: {
      count: memberCount,
      id,
      name,
    },
  },
});
