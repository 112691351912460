import { theme } from '~/ui';

const { colors, sizes, space, text, zIndices } = theme;

export default function stylesFactory(error) {
  return {
    clearIndicator: (styles, state) => ({
      ...styles,
      color: colors['gray-500'],
      display: state.isFocused ? 'flex' : 'none',
      padding: 0,
    }),
    control: (styles, state) => ({
      ...styles,
      ...text['s-dense'],
      alignItems: 'normal',
      backgroundColor: colors.white,
      borderColor: error
        ? colors['red-400']
        : state.isFocused
        ? colors['blue-500']
        : colors['gray-500'],
      boxShadow: 'none',
      caretColor: colors['blue-500'],
      color: colors['gray-900'],
      minHeight: sizes['input-height'],
      opacity: state.isDisabled ? 0.5 : 1,
      '&:hover': {
        borderColor: error ? colors['red-400'] : colors['blue-500'],
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingRight: space[3],
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      padding: 0,
    }),
    option: (styles) => ({
      ...styles,
      color: colors['gray-900'],
    }),
    // https://github.com/JedWatson/react-select/issues/3030
    menuPortal: (styles) => ({
      ...styles,
      zIndex: zIndices.select,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: error ? colors['red-400'] : colors['gray-500'],
    }),
    singleValue: (styles) => ({
      ...styles,
      color: error ? colors['red-400'] : colors['gray-900'],
    }),
    valueContainer: (styles) => ({
      ...styles,
      color: error ? colors['red-400'] : colors['gray-600'],
    }),
  };
}
