import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  FormField,
  Layout,
  LoadingShimmer,
  Select,
  types,
  useToast,
} from '~/eds';
import { api, selectors } from '~/redux';
import {
  Nullable,
  TurnTrackingHistoryType,
  TurnTrackingPartyType,
  Uuid,
} from '~/types';

interface Props {
  history?: TurnTrackingHistoryType[];
  /* Either pass an array of parties or a workflow id for component to make api call */
  parties?: TurnTrackingPartyType[];
  workflowId?: Uuid;
  showDescription?: boolean;
  /* Error message for failed api call, provide if "parties" not passed */
  errorMessage?: { message: string; title?: string };
  width?: types.InputWidth;
  onChange: (partyId: Nullable<Uuid>) => void;
}

export const PartySelectField = ({
  history,
  parties = [],
  workflowId,
  showDescription = false,
  errorMessage,
  width = '100%',
  onChange,
}: Props) => {
  const { toast } = useToast();
  const { data = [], isError } = api.endpoints.getTurnTrackingParties.useQuery(
    {
      workflowId: workflowId as Uuid,
    },
    { skip: parties?.length > 0 || !workflowId },
  );
  const partyList = data.length > 0 ? data : parties;
  const showError = isError ?? false;

  useEffect(() => {
    if (showError && errorMessage?.message) {
      const message = errorMessage.message;
      const title = errorMessage?.title ? errorMessage.title : '';
      toast({ status: 'info', message, title });
    }
  }, [showError]);

  const selectHistory = useSelector(selectors.selectTurnTrackingHistory);
  const turnTrackingHistory = history || selectHistory;
  const currentParty = turnTrackingHistory?.[0]?.partyId;
  const partyOptions = partyList.map((party: TurnTrackingPartyType) => {
    if (currentParty && currentParty === party.id) {
      return { label: `${party.name} (currently with)`, value: party.id };
    }
    return { label: party.name, value: party.id };
  });

  const [selectedPartyId, setSelectedPartyId] = useState<Nullable<Uuid>>(
    currentParty || null,
  );

  return partyList.length > 0 ? (
    <FormField<string, false>
      name="time-tracker-select-party"
      label="Set responsible party"
      description={
        showDescription
          ? "Select the party that's responsible for current cycle"
          : ''
      }
      input={Select}
      inputProps={{
        options: partyOptions,
        isMulti: false,
        enablePortal: true,
        width,
      }}
      value={selectedPartyId}
      onChange={(partyId) => {
        setSelectedPartyId(partyId);
        onChange(partyId);
      }}
    />
  ) : (
    <Layout h="height.m" w="input.l.width">
      <LoadingShimmer />
    </Layout>
  );
};
