import React from 'react';

import { useRouting } from './useRouting';

export const withRouting = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  return (props: P) => {
    const { location, navigate, params } = useRouting();

    return (
      <Component
        {...(props as P)}
        location={location}
        navigate={navigate}
        params={params}
      />
    );
  };
};
