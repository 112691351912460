import React from 'react';
import { connect } from 'react-redux';

import { integrationsSetManageSyncPairStage } from '~/actions/integrations';
import { Button } from '~/eds';
import { ManageSyncPairStageType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlexLayout, Text } from '~/ui';

function EmptySyncPairsPlaceholder({
  // connected
  integrationsSetManageSyncPairStage,
}) {
  return (
    <FlexLayout
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      p={16}
      space={6}
      sx={{
        border: 'border',
        borderRadius: 'm',
      }}
    >
      <Text color="gray-600" variant="s-spaced">
        No sync pairs yet
      </Text>
      <Button
        id={OnboardingIdType.AddSyncPairButton}
        text="Add Sync Pair"
        onClick={() =>
          integrationsSetManageSyncPairStage(ManageSyncPairStageType.Provider)
        }
        variant="primary"
      />
    </FlexLayout>
  );
}

export default connect(null, {
  integrationsSetManageSyncPairStage,
})(EmptySyncPairsPlaceholder);
