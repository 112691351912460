import { generatePath } from 'react-router-dom';

import { RoutePathType } from './enums';

export const navActions = {
  [RoutePathType.AdminConsoleClient]: [
    {
      text: 'Admin Console',
      pathname: RoutePathType.AdminConsoleClient,
    },
  ],
  [RoutePathType.Admin]: [
    {
      text: 'Admin',
      pathname: RoutePathType.Admin,
    },
  ],
};

interface NavAction {
  text: string;
  pathname: RoutePathType;
}

export const generateNav = ({
  current,
  from,
  params,
}: {
  current: NavAction;
  from: keyof typeof navActions;
  params: object;
}) => {
  const actions = [...navActions[from], current].map((action) => ({
    ...action,
    pathname: generatePath(action.pathname, params),
  }));

  return { actions };
};
