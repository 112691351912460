import { HtmlEntityType } from '../../../enums';

export const truncate = ({ content = '' }) => {
  const base = {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  if (content) {
    return {
      ...base,
      position: 'relative',
      ':after': {
        backgroundImage: 'linear-gradient(white 0 0)',
        content: `'${content}${HtmlEntityType.Ellipsis}'`,
        direction: 'rtl',
        overflow: 'hidden',
        position: 'absolute',
        right: 0,
        textOverflow: 'ellipsis',
        maxWidth: '50%',
      },
    };
  }

  return base;
};
