export default `
<svg
  aria-hidden="true"
  aria-label="open-in-new"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M13.75 6.5C13.3125 6.5 13 6.1875 13 5.75C13 5.34375 13.3125 5 13.75 5H18.25C18.6562 5 19 5.34375 19 5.75V10.25C19 10.6875 18.6562 11 18.25 11C17.8125 11 17.5 10.6875 17.5 10.25V7.5625L11.25 13.7812C10.9688 14.0938 10.5 14.0938 10.2188 13.7812C9.90625 13.5 9.90625 13.0312 10.2188 12.7188L16.4375 6.5H13.75ZM5 7.75C5 6.8125 5.78125 6 6.75 6H10.25C10.6562 6 11 6.34375 11 6.75C11 7.1875 10.6562 7.5 10.25 7.5H6.75C6.59375 7.5 6.5 7.625 6.5 7.75V17.25C6.5 17.4062 6.59375 17.5 6.75 17.5H16.25C16.375 17.5 16.5 17.4062 16.5 17.25V13.75C16.5 13.3438 16.8125 13 17.25 13C17.6562 13 18 13.3438 18 13.75V17.25C18 18.2188 17.1875 19 16.25 19H6.75C5.78125 19 5 18.2188 5 17.25V7.75Z"
    fill="currentcolor"
  />
</svg>
`;
