import _ from 'lodash';

import { genAi, sylvanus } from '~/services';
import { GenClauseResult } from '~/types';

interface GenClauseRequest {
  instructions: string;
  static?: boolean;
  text?: string;
  useDelphi: boolean;
}

export const getGenClauseResult = async (
  request: GenClauseRequest,
): Promise<GenClauseResult> => {
  if (request.useDelphi) {
    if (_.isNil(request.text) || _.isEmpty(request.text)) {
      return await sylvanus.post('/clause/generate', request);
    } else {
      return await sylvanus.post('/clause/redline', request);
    }
  } else {
    return await genAi.post('/gen/clause', request);
  }
};

export const getGenClauseResults = async (
  request: GenClauseRequest,
): Promise<GenClauseResult[]> => {
  const requests = Array.from({ length: 3 }).map((_, i) =>
    getGenClauseResult({
      ...request,
      static: i === 0, // first request is always static
    }),
  );

  return (Promise.all(requests) as unknown) as Promise<GenClauseResult[]>;
};
