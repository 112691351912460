import React from 'react';

import { FlexLayout } from '~/ui';

export function Column({ children, space = 4 }) {
  return (
    <FlexLayout flexDirection="column" space={space}>
      {children}
    </FlexLayout>
  );
}

export function Row({ children }) {
  return (
    <FlexLayout alignItems="flex-end" flex="1 1 auto" space={6}>
      {children}
    </FlexLayout>
  );
}
