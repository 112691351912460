import React from 'react';

import { Box, Button, FlexLayout, styles } from '~/ui';

function Footer({ leftAction, rightAction }) {
  return (
    <Box
      bg="white"
      sx={{
        borderTop: 'border',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <FlexLayout
        justifyContent="space-between"
        mx="auto"
        py={6}
        sx={styles.layout.container}
      >
        {leftAction && <Button {...leftAction} variant="outlined" />}
        {rightAction && <Button {...rightAction} />}
      </FlexLayout>
    </Box>
  );
}

export default Footer;
