import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RETRY_ERROR } from '~/constants/errors';
import {
  Actions,
  Box,
  ButtonGroup,
  Icon,
  Layout,
  Paginate,
  types,
  useToast,
} from '~/eds';
import { actions, api, selectors } from '~/redux';
import { Nullable } from '~/types';

import {
  FEEDBACK_MAX_LENGTH,
  MAX_TESTCASE_NUMBER,
  REVIEW_TEST_CASE_LABEL_WIDTH,
  VALUE_DOES_NOT_EXIST_TEXT,
} from '../../constants';
import { FieldValue } from '../../fields/types';
import {
  getReviewPaginateTailTooltip,
  mapStatusToTestCaseOutcome,
  mapTestCaseOutcomeToStatus,
  testIsTestCaseEvaluating,
} from '../../fields/utils';
import { PromptModelTestCase, TestCaseReviewStatus } from '../../types';
import { ReviewGoldValueFeedback } from './ReviewGoldValueFeedback';

interface Props {
  testCase: PromptModelTestCase;
  totalCount: number;
  onUpdateTestCaseNumber: (updatedTestCaseNumber: number) => void;
  isLoading?: boolean;
  readOnly?: boolean;
}

// TODO: Split `ReviewActions` and `ReviewPaginate` into separate components
export const ReviewPaginate = ({
  isLoading: isPollingTestCase,
  readOnly,
  testCase,
  totalCount,
  onUpdateTestCaseNumber,
}: Props) => {
  const dispatch = useDispatch();
  const { toast } = useToast();

  const config = useSelector(selectors.selectFieldAiConfig);
  const { field, filters } = config;

  const [
    createTestCase,
    { isLoading: isCreatingTestCase, isError: isCreateTestCaseError },
  ] = api.endpoints.createPromptModelTestCase.useMutation();

  const [
    updateTestCase,
    { isLoading: isReviewingTestCase },
  ] = api.endpoints.updatePromptModelTestCase.useMutation();

  const {
    data: docsInScope = 0,
    isFetching: isFetchingDocsInScope,
  } = api.endpoints.getDocsInScope.useQuery(
    {
      fieldId: field!.id,
      filters,
    },
    { skip: !field },
  );

  // sync local state with props.testCase
  useEffect(() => {
    setGoldValue(testCase.goldValue);
    setFeedback(testCase.feedback ?? '');
  }, [testCase]);

  const [goldValue, setGoldValue] = useState<Nullable<FieldValue>>(
    testCase.goldValue ?? null,
  );

  const [feedback, setFeedback] = useState<string>(testCase.feedback ?? '');

  const draftTestCase = {
    ...testCase,
    goldValue,
    feedback: feedback || null,
  };

  const status = mapTestCaseOutcomeToStatus(testCase);
  const isDangerOrWarningStatus = status === 'danger' || status === 'warning';

  const canCreateTestCase = Boolean(
    testCase.number === totalCount && testCase.number < MAX_TESTCASE_NUMBER,
  );

  const enableNextAction = Boolean(
    canCreateTestCase &&
      !isCreateTestCaseError &&
      !readOnly &&
      isDangerOrWarningStatus,
  );

  const isLoadingTestCase =
    isPollingTestCase ||
    isReviewingTestCase ||
    isCreatingTestCase ||
    isFetchingDocsInScope;

  const disableNextAction = Boolean(
    isLoadingTestCase ||
      !goldValue ||
      (goldValue.type === 'unknown' && goldValue.value === null) ||
      (feedback && feedback.length > FEEDBACK_MAX_LENGTH),
  );

  const enableTooltip = Boolean(
    disableNextAction && !isLoadingTestCase && !readOnly,
  );

  const isTestCaseDirty = !isEqual(testCase, draftTestCase);
  const disablePagination =
    disableNextAction &&
    isDangerOrWarningStatus &&
    (enableNextAction ? isTestCaseDirty : true);

  const isEvaluatingTestCase = testIsTestCaseEvaluating(testCase);

  const disableActions = Boolean(
    readOnly || isLoadingTestCase || isEvaluatingTestCase,
  );

  const actionTooltip = isEvaluatingTestCase
    ? 'Evaluating results…'
    : undefined;

  const handleCreateTestCase = () => {
    createTestCase({
      modelId: testCase.modelId,
      version: testCase.versionNumber,
    })
      .unwrap()
      .then((newTestCase) => {
        if (newTestCase) {
          dispatch(actions.addFieldAiTestCase(newTestCase));
          onUpdateTestCaseNumber(newTestCase.number);
        }
      })
      .catch((error) => {
        const message = error.response.data.detail ?? RETRY_ERROR;
        switch (message) {
          case 'No (more) documents were found in the model scope.':
            break; // swallow error (product requirement)
          default:
            toast({
              message,
              status: 'danger',
            });
        }
      });
  };

  const handleNextAction = (shouldCreateTestCase: boolean) => {
    let preparedGoldValue = goldValue;
    if (
      goldValue &&
      goldValue.highlights === undefined &&
      testCase.goldValue &&
      testCase.goldValue.highlights
    ) {
      // Keep the highlights in sync
      preparedGoldValue = {
        ...goldValue,
        highlights: testCase.goldValue.highlights,
      };
    }
    updateTestCase({
      modelId: testCase.modelId,
      version: testCase.versionNumber,
      outcome:
        testCase.outcome === 'partially_correct'
          ? 'partially_correct'
          : 'incorrect',
      testCaseNumber: testCase.number,
      goldValue: preparedGoldValue,
      feedback,
    })
      .unwrap()
      .then((updatedTestCase) => {
        if (updatedTestCase) {
          dispatch(actions.setFieldAiUpdatedTestCase(updatedTestCase));
          if (shouldCreateTestCase && canCreateTestCase) {
            handleCreateTestCase();
          }
        }
      })
      .catch(() => {
        toast({
          message: RETRY_ERROR,
          status: 'danger',
        });
      });
  };

  const handleUpdatePageIndex = (updatedPageIndex: number) => {
    if (!readOnly && isDangerOrWarningStatus && isTestCaseDirty) {
      handleNextAction(false);
    }
    onUpdateTestCaseNumber(updatedPageIndex);
  };

  const paginate = {
    disabled: disablePagination,
    isLoading: isLoadingTestCase,
    loadingTooltip: 'Analyzing next document…',
    nextAction: enableNextAction
      ? {
          text: 'Next Document',
          disabled: disableNextAction,
          tooltip: enableTooltip
            ? `Provide a value, or select ‘${VALUE_DOES_NOT_EXIST_TEXT}’`
            : undefined,
          onClick: () => handleNextAction(true),
        }
      : undefined,
    pageIndex: testCase.number,
    getTooltips: ({ pageIndex }: { pageIndex: number }) => ({
      next: getReviewPaginateTailTooltip({ docsInScope, pageIndex }),
      last: getReviewPaginateTailTooltip({ docsInScope, pageIndex }),
    }),
    totalCount,
    onUpdatePageIndex: handleUpdatePageIndex,
  };
  const infoMessage =
    disablePagination && !enableNextAction
      ? `Provide a value, or select ‘${VALUE_DOES_NOT_EXIST_TEXT}’`
      : undefined;

  const reviewActions: types.UserAction[] = [
    {
      status: 'success',
      text: 'Correct',
      tooltip: actionTooltip,
    },
    {
      status: testCase.outcome === 'partially_correct' ? 'warning' : 'danger',
      text: 'Incorrect',
      tooltip: actionTooltip,
    },
    {
      status: 'inactive',
      text: 'Skip',
      tooltip: actionTooltip,
    },
  ];

  const handleReviewDocument = (updatedStatus: TestCaseReviewStatus) => {
    if (readOnly) {
      return; // noop
    }

    const outcome = mapStatusToTestCaseOutcome(updatedStatus);

    let preparedGoldValue: Nullable<FieldValue> | undefined; // intentionally undefined unless there are gold value highlights
    if (goldValue?.highlights) {
      if (outcome === 'correct') {
        preparedGoldValue = {
          highlights: goldValue.highlights,
          // @ts-expect-error - 'FieldValueType' is not assignable to 'classification'
          type: testCase.modelValue?.type,
          // @ts-expect-error - 'string[]' is not assignable to 'string'
          value: testCase.modelValue?.value,
        };
      } else if (outcome === 'incorrect' && !config.fieldClassification) {
        // Setting null value and 'unknown' type on an incorrect extraction test case indicates that this is
        // a special case where the user modified the source highlights rather than the gold value itself.
        preparedGoldValue = {
          highlights: goldValue.highlights,
          type: 'unknown',
          value: null,
        };
      } else {
        preparedGoldValue = goldValue;
      }
    } else if (
      !goldValue &&
      outcome === 'incorrect' &&
      testCase.modelValue?.highlights
    ) {
      // Copy the model value highlights to the gold value highlights in order to
      // preserve them when the gold value gets set for the first time.
      preparedGoldValue = {
        highlights: testCase.modelValue.highlights,
        type: config.fieldClassification ? 'classification-unknown' : 'unknown',
        value: null,
      };
    }

    updateTestCase({
      modelId: testCase.modelId,
      version: testCase.versionNumber,
      outcome,
      testCaseNumber: testCase.number,
      goldValue: preparedGoldValue,
    })
      .unwrap()
      .then((updatedTestCase) => {
        if (updatedTestCase) {
          dispatch(actions.setFieldAiUpdatedTestCase(updatedTestCase));
        }
      })
      .catch(() => {
        toast({
          message: RETRY_ERROR,
          status: 'danger',
        });
      });
  };

  useEffect(() => {
    if (testCase) {
      if (
        canCreateTestCase &&
        (testCase.outcome === 'correct' || testCase.outcome === 'skipped')
      ) {
        handleCreateTestCase();
      }
    }
  }, [testCase]);

  return (
    <Layout align="flex-start" direction="column" pt={py} spacing={py}>
      <Layout spacing={2}>
        {/* Phantom Box for alignment */}
        <Box w={REVIEW_TEST_CASE_LABEL_WIDTH} />
        <ButtonGroup
          actions={reviewActions.map((reviewAction) => ({
            ...reviewAction,
            disabled: disableActions,
            icon: undefined, // never set an icon
            level: 'secondary' as const, // always use a secondary level action
            status:
              status === reviewAction.status
                ? (reviewAction.status as types.StatusType)
                : undefined,
            onClick: () =>
              handleReviewDocument(reviewAction.status as TestCaseReviewStatus),
          }))}
        />
      </Layout>
      {isDangerOrWarningStatus && (
        <ReviewGoldValueFeedback
          readOnly={readOnly}
          testCase={testCase}
          goldValue={goldValue}
          feedback={feedback}
          onUpdateGoldValue={setGoldValue}
          onUpdateFeedback={setFeedback}
        />
      )}
      <Layout spacing={2}>
        {/* Phantom Box for alignment */}
        <Box
          w={REVIEW_TEST_CASE_LABEL_WIDTH}
          ml={paginate.nextAction ? -16 : 0}
        />
        <Layout preset="buttons">
          <Paginate mode="count" name="review card paginate" {...paginate} />
          {infoMessage && <Icon icon="info" tooltip={infoMessage} />}
          {paginate.nextAction && (
            <Actions
              actions={[
                {
                  ...paginate.nextAction,
                  level: 'secondary',
                },
              ]}
            />
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

const py = 6;
