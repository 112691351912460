import React from 'react';

import { Box, ErrorPage } from '~/eds';
import { RoutePathType, useRouting } from '~/routing';

const Page = () => {
  const { navigate } = useRouting();
  const navigateBack = () => navigate(RoutePathType.Home);

  return (
    <Box mt={100}>
      <ErrorPage
        preset="page-access"
        resolveAction={{ onClick: navigateBack }}
      />
    </Box>
  );
};

export default Page;
