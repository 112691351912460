import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  integrationsAddSyncPair,
  integrationsRemoveSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
  integrationsSetSyncPairs,
} from '~/actions/integrations';
import { deleteSyncPair } from '~/api';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { procurementIntegrations } from '~/constants/integrations';
import {
  Button,
  FileInput,
  Filename,
  Layout,
  Markdown,
  Modal,
  Section,
  Table,
  Text,
  Tooltip,
  useToast,
} from '~/eds';
import {
  AribaIntegrationType,
  FileExtensionType,
  FileMimeType,
  ManageSyncPairStageType,
  SyncPairStatusType,
} from '~/enums';
import { ConfigurationDetailPanel } from '~/features/integrations/procurement';
import { FlagType, useFlag } from '~/flags';
import { usePollingRequest } from '~/hooks';
import { api } from '~/redux';
import { useRouting } from '~/routing';
import { fileTypesToChips } from '~/utils';

import { resetSyncPairAuthData } from '../util';
import EmptySyncPairsPlaceholder from './EmptySyncPairsPlaceholder';

function ProcurementIntegrations({
  // connected
  integrationsRemoveSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
  integrationsSetSyncPairs,
}) {
  const PAGE_SIZE = 50;

  const enableSyncConfiguration = useFlag(FlagType.SyncConfiguration);
  const { toast } = useToast();

  const { params } = useRouting();
  const { clientId } = params;

  const [syncPairToDelete, setSyncPairToDelete] = useState(null);
  const [syncPairToConfig, setSyncPairToConfig] = useState(null);
  const [fileConfig, setFileConfig] = useState(null);
  const [deletingIds, setDeletingIds] = useState([]);
  const [page, setPage] = useState(1);
  const [activeConfig, setActiveConfig] = useState(null);
  const [isMissingColumn, setIsMissingColumn] = useState(false);

  const usingBidirectionalSync = useFlag(FlagType.AribaSyncPairConfig);
  const [
    validateCsv,
    validateResult,
  ] = api.endpoints.validateAribaDocumentIdFile.useMutation();
  const { isLoading: isLoadingValidate } = validateResult;
  const [
    updateSyncPair,
    updateFileResult,
  ] = api.endpoints.updateAribaFileSyncPair.useMutation();
  const { isLoading: isLoadingUpdate } = updateFileResult;

  const {
    data: aribaSyncPairs = [],
    isLoading: isLoadingSyncPairs,
    error: aribaSyncPairsError,
  } = usePollingRequest(
    api.endpoints.getSyncPairsAriba.useQuery,
    { clientId },
    { pollingInterval: 20000 },
  );

  const warningMessage =
    'We did not find a Primary Document designation, which is necessary to send Evisort data back to Ariba. Continuing without a Primary Document designation will not impact your ability to sync files and data into Evisort.';

  useEffect(() => {
    if (aribaSyncPairsError) {
      toast({
        message: 'Unable to fetch sync pairs, please try again.',
        status: 'danger',
      });

      integrationsSetSyncPairs([]);
    }

    if (aribaSyncPairs) {
      integrationsSetSyncPairs(aribaSyncPairs);
    }
  }, [aribaSyncPairsError, aribaSyncPairs]);

  const transformFileTypes = (fileTypes) => {
    return fileTypes ? fileTypes.map((f) => f.split(',')).flat() : '';
  };

  const handlePaginate = ({ pageIndex }) => {
    setPage(pageIndex);
  };

  const getIsOneTimeSync = (props) => {
    if (props.providerOptions?.syncType) {
      return props.providerOptions.syncType === AribaIntegrationType.ONE_TIME;
    } else {
      return props.providerOptions.tokens?.length === 1;
    }
  };

  const columns = [
    {
      key: 'provider',
      title: 'Type',
      renderCell: ({ provider }) => (
        <Layout spacing={2} align="center">
          <Tooltip>
            <ProviderLogo provider={provider} size="s" />
          </Tooltip>
          <Text>{procurementIntegrations[provider].name}</Text>
        </Layout>
      ),
    },
    {
      key: 'status',
      cellType: 'chips',
      title: 'Status',
      mapCellProps: (props) => {
        let icon;
        let text;
        let status;
        const isOneTimeSync = getIsOneTimeSync(props);
        switch (props.status) {
          case SyncPairStatusType.AuthError:
            text = 'Authentication error';
            status = 'danger';
            break;
          case SyncPairStatusType.OtherError:
          case SyncPairStatusType.DataAccessError:
          case SyncPairStatusType.OutOfSpaceError:
            text = 'Provider error';
            status = 'danger';
            break;
          case SyncPairStatusType.ConfigError:
            text = 'Configuration error';
            status = 'danger';
            break;
          case SyncPairStatusType.Active:
            if (isOneTimeSync) {
              text = 'One-time import';
              status = 'warning';
            } else {
              text = 'Active';
              status = 'new';
            }
            break;
          case SyncPairStatusType.Initial:
            if (isOneTimeSync) {
              text = 'Importing';
            } else {
              text = 'Syncing';
            }
            icon = 'loading';
            status = 'info';
            break;
          case SyncPairStatusType.Syncing:
            icon = 'loading';
            text = 'Syncing';
            status = 'info';
            break;
          case SyncPairStatusType.ActiveSync:
            text = 'Active';
            status = 'new';
            break;
          default:
            text = 'Unknown Error';
            status = 'danger';
        }
        const statusChip = {
          icon,
          text,
          status,
        };
        return {
          chips: [statusChip],
        };
      },
    },
    {
      key: 'externalSource',
      title: 'External Source',
      info: 'Realm ID for Ariba',
      cellType: 'text',
      mapCellProps: ({ providerOptions }) => ({
        text: providerOptions.realm,
      }),
    },
    {
      key: 'evisortFolderPath',
      cellType: 'filesystem',
      title: 'Evisort Folder',
      mapCellProps: ({ id, evisortFolderPath }) => ({
        folder: {
          id,
          name: 'Documents',
          path: evisortFolderPath,
          type: 'internal',
        },
      }),
    },
    {
      key: 'allowedSyncFileTypes',
      cellType: 'chips',
      title: 'File Types',
      mapCellProps: ({ allowedSyncFileTypes }) => ({
        chips: allowedSyncFileTypes
          ? fileTypesToChips(transformFileTypes(allowedSyncFileTypes))
          : [],
      }),
      mapCellValue: ({ allowedSyncFileTypes }) =>
        allowedSyncFileTypes ? allowedSyncFileTypes.join(',') : [],
      width: 'l',
    },
    {
      key: 'dateUpdated',
      cellType: 'datetime',
      title: 'Last Sync Activity',
      mapCellProps: ({ dateUpdated }) => ({
        datetime: dateUpdated ? new Date(dateUpdated) : undefined,
        format: 'iso',
      }),
      minWidth: 'auto',
    },
  ];

  const onClickDelete = async () => {
    try {
      setDeletingIds([...deletingIds, syncPairToDelete.id]);
      await deleteSyncPair(syncPairToDelete.id, clientId);
      integrationsRemoveSyncPair(syncPairToDelete);
      toast({
        status: 'success',
        message: `${syncPairToDelete.name} sync pair has been deleted.`,
      });
    } catch (error) {
      toast({
        status: 'danger',
        message: 'Unable to delete sync pair, please try again.',
      });
    }
  };

  const handleHideConfigurationDetailsPanel = () => {
    setActiveConfig(null);
  };

  const handleHideDeleteModal = () => {
    setSyncPairToDelete(null);
  };

  const handleHideUploadModal = () => {
    setIsMissingColumn(false);
    setFileConfig(null);
    setSyncPairToConfig(null);
  };

  const getFileUploadModalTitle = (syncPair) => {
    if (syncPair) {
      const { evisortFolderPath } = syncPair;
      return `${evisortFolderPath} - Upload new CSV`;
    }
  };

  const onClickUploadFile = () => {
    if (fileConfig) {
      updateSyncPair({
        syncPairId: syncPairToConfig.id,
        file: fileConfig,
      })
        .unwrap()
        .then(() => {
          const { evisortFolderPath } = syncPairToConfig;
          toast({
            status: 'success',
            message: `${evisortFolderPath} - new CSV uploaded.`,
          });
          setSyncPairToConfig(null);
          setFileConfig(null);
        })
        .catch((err) => {
          const message = err.response.data?.error;
          toast({
            status: 'danger',
            message: `Error uploading file: ${message}`,
          });
        });
    }
  };

  const getIsUploadFile = (syncPair) => {
    if (syncPair) {
      const { providerOptions } = syncPair;
      return (
        syncPair.status === SyncPairStatusType.Active &&
        usingBidirectionalSync &&
        providerOptions.syncType === AribaIntegrationType.ONE_TIME
      );
    } else {
      return false;
    }
  };

  const handleSelectedFile = (docIdsFile) => {
    if (!docIdsFile) {
      return;
    }
    const file = docIdsFile[0];
    validateCsv({
      file,
      syncPairToConfig,
    })
      .unwrap()
      .then(() => {
        setIsMissingColumn(false);
        setFileConfig(file);
      })
      .catch((err) => {
        const message = err.response.data?.error;
        if (message.includes('column is missing')) {
          // just show the warning message
          setIsMissingColumn(true);
          setFileConfig(file);
        } else {
          toast({
            status: 'danger',
            message: `Error processing file: ${message}`,
          });
        }
      });
  };

  if (!isLoadingSyncPairs && aribaSyncPairs.length === 0) {
    return <EmptySyncPairsPlaceholder />;
  }

  const rowActions = [
    {
      label: 'Reauthenticate',
      onClick: (d) => {
        integrationsSetActiveSyncPair({
          ...resetSyncPairAuthData(d),
          isTokenReauthenticate: true,
        });
        integrationsSetManageSyncPairStage(ManageSyncPairStageType.MultiAPIs);
      },
      condition: (d) =>
        [
          SyncPairStatusType.Active,
          SyncPairStatusType.AuthError,
          SyncPairStatusType.ConfigError,
          SyncPairStatusType.OtherError,
        ].includes(d.status),
    },
    {
      label: 'Upload CSV',
      onClick: (d) => {
        setSyncPairToConfig(d);
      },
      condition: (d) => getIsUploadFile(d),
    },
    {
      label: 'Delete',
      onClick: (d) => {
        setSyncPairToDelete(d);
      },
    },
  ];

  if (enableSyncConfiguration) {
    rowActions.push({
      label: 'Configuration',
      onClick: (d) => {
        setActiveConfig(d);
      },
      condition: (d) => d,
    });
  }
  const deleteConfirmationText =
    'Are you sure you want to delete this sync pair?\nDeleting the sync pair will not stop an active file transfer, and will not delete any files that have been transferred. **This action cannot be reverted.**';
  return (
    <>
      <Layout justify="flex-end" pb={4}>
        <Button
          text="Add Sync Pair"
          onClick={() =>
            integrationsSetManageSyncPairStage(
              ManageSyncPairStageType.ProviderProcurements,
            )
          }
          variant="primary"
        />
      </Layout>
      <Table
        name="storage-integrations"
        isLoading={isLoadingSyncPairs}
        columns={columns}
        totalCount={
          aribaSyncPairs.filter(
            (syncPair) => !deletingIds.includes(syncPair.id),
          ).length
        }
        data={aribaSyncPairs
          .filter((syncPair) => !deletingIds.includes(syncPair.id))
          .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)}
        rowActions={rowActions}
        options={{ enableManageColumns: false }}
        state={{ pageIndex: page, pageSize: PAGE_SIZE }}
        onPaginate={handlePaginate}
      />
      <Modal
        enableHideOnPrimaryAction={true}
        isVisible={syncPairToDelete}
        onHide={handleHideDeleteModal}
        primaryAction={{
          variant: 'danger',
          text: 'Delete',
          onClick: onClickDelete,
        }}
        title="Delete Sync Pair?"
      >
        <Markdown text={deleteConfirmationText} />
      </Modal>
      <Modal
        isVisible={syncPairToConfig}
        onHide={handleHideUploadModal}
        primaryAction={{
          text: 'Save Changes',
          onClick: onClickUploadFile,
          isLoading: isLoadingValidate || isLoadingUpdate,
        }}
        title={getFileUploadModalTitle(syncPairToConfig)}
      >
        {isMissingColumn && (
          <Layout py={2}>
            <Section
              statusMessage={{ message: warningMessage, status: 'danger' }}
            />
          </Layout>
        )}
        <FileInput
          accept={{ [FileMimeType.Csv]: [FileExtensionType.Csv] }}
          onChange={handleSelectedFile}
          name="Upload Document"
          isLoading={isLoadingValidate}
          isMultiple={false}
        />
        {fileConfig && (
          <Layout spacing={4} py={4} direction="column">
            <Filename name={fileConfig.name} includeExtension={true} />
          </Layout>
        )}
      </Modal>
      {activeConfig && (
        <ConfigurationDetailPanel
          config={activeConfig}
          onHide={handleHideConfigurationDetailsPanel}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
});

export default connect(mapStateToProps, {
  integrationsRemoveSyncPair,
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
  integrationsSetSyncPairs,
})(ProcurementIntegrations);
