import { max } from 'date-fns';

import { parseDate } from '~/eds';

export const getLatestDate = (date1, date2) => {
  const momentDate1 = date1 ? parseDate(date1) : date1;
  const momentDate2 = date2 ? parseDate(date2) : date2;

  if (momentDate1 && momentDate2) {
    return max([momentDate1, momentDate2]);
  } else if (momentDate1) {
    return momentDate1;
  } else if (momentDate2) {
    return momentDate2;
  } else {
    return null;
  }
};
