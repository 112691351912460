export default `
<svg
  aria-hidden="true"
  aria-label="double-arrow-right"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12.295 11.4845L6.79495 5.73447C6.5137 5.42197 6.04495 5.42197 5.73245 5.70322C5.5762 5.85947 5.5137 6.04697 5.5137 6.23447C5.5137 6.42197 5.5762 6.60947 5.7012 6.76572L10.7012 11.9845L5.7012 17.2345C5.41995 17.5157 5.41995 18.0157 5.73245 18.297C6.04495 18.5782 6.5137 18.5782 6.79495 18.2657L12.295 12.5157C12.5762 12.2345 12.5762 11.7657 12.295 11.4845ZM18.295 11.4845L12.795 5.73447C12.5137 5.42197 12.045 5.42197 11.7325 5.70322C11.5762 5.85947 11.5137 6.04697 11.5137 6.23447C11.5137 6.42197 11.5762 6.60947 11.7012 6.76572L16.7012 11.9845L11.7012 17.2345C11.42 17.5157 11.42 18.0157 11.7325 18.297C12.045 18.5782 12.5137 18.5782 12.795 18.2657L18.295 12.5157C18.5762 12.2345 18.5762 11.7657 18.295 11.4845Z"
    fill="currentcolor"
  />
</svg>
`;
