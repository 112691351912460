import { pilot } from '~/services';

import { BulkCopyMoveParams } from './types';

export const searchV2BulkMove = ({
  destinationFolder,
  documentHandlerIds,
  isAllSelected,
  searchAPI,
  searchQuery,
}: BulkCopyMoveParams) =>
  pilot.put('document/bulk-move/', {
    destination_folder: destinationFolder,
    document_ids: !isAllSelected ? documentHandlerIds : [],
    ...(searchAPI === 'v3'
      ? { search_query_v3: searchQuery }
      : { search_query: searchQuery }),
    select_all: isAllSelected,
  });
