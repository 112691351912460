import { PromptModelTestCase } from '~/features/x-ray';
import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { PromptModelVersion } from '../../types/sylvanus/model';
import { mapTestCase } from './transformers';

interface Request {
  modelId: Uuid;
  version: number;
}

type Response = PromptModelVersion;

export const getPromptModelTestCases = async ({
  modelId,
  version,
}: Request): Promise<PromptModelTestCase[]> => {
  const response: Response = await sylvanus.get(
    `/model/${modelId}/version/${version}`,
  );

  return Object.values(response.test_cases ?? {}).map(mapTestCase);
};
