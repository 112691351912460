import PT from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import {
  UI_ALERT_ACTION_ICON_TESTID,
  UI_ALERT_ICON_TESTID,
} from '~/constants/testids';
import { Button } from '~/eds';
import { Box, FlexLayout, Icon } from '~/ui';

export const styles = {
  info: {
    alert: {
      bg: 'gray-200',
      color: 'black-alpha-75',
    },
    icon: {
      color: 'blue-500',
    },
    action: {
      color: 'blue-500',
    },
  },
  success: {
    alert: {
      bg: 'green-100',
      color: 'black-alpha-75',
    },
    icon: {
      color: 'green-400',
    },
    action: {
      color: 'green-500',
    },
  },
  warning: {
    alert: {
      bg: 'yellow-100',
      color: 'black-alpha-75',
    },
    icon: {
      color: 'yellow-500',
    },
    action: {
      color: 'peach-800',
    },
  },
  danger: {
    alert: {
      bg: 'red-100',
      color: 'red-800',
    },
    icon: {
      color: 'red-400',
    },
    action: {
      color: 'red-600',
    },
  },
  critical: {
    alert: {
      bg: 'red-600',
      color: 'white',
    },
    icon: {
      color: 'white',
    },
    action: {
      color: 'white',
    },
  },
  loading: {
    alert: {
      bg: 'gray-200',
      color: 'gray-800',
    },
    icon: {
      color: 'blue-500',
    },
    action: {
      color: 'blue-500',
    },
  },
};

const icons = {
  success: 'checkmarkCircledFilled',
  info: 'info',
  warning: 'warning',
  danger: 'warning',
  critical: 'warning',
  loading: 'loadingSpinner',
};

const contentLineHeight = 20;

function Alert({
  actions = [],
  ariaLive = 'polite',
  children,
  enableIcon = false,
  variant = 'info',
  onDismiss,
}) {
  const contentRef = useRef();
  const [isMultiline, setIsMultiline] = useState(false);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement?.offsetHeight > contentLineHeight) {
      setIsMultiline(true);
    }
  }, []);

  const variantStyle = styles[variant] || styles.info;
  const icon = icons[variant] || 'info';

  if (onDismiss) {
    actions.push({
      icon: 'close',
      onClick: onDismiss,
    });
  }

  const px = 6;
  const py = isMultiline ? 4 : 0;

  return (
    <FlexLayout
      alignItems="stretch"
      aria-live={ariaLive}
      role="alert"
      justifyContent="space-between"
      px={px}
      py={py}
      sx={{
        ...variantStyle.alert,
        border: 'border-alpha-10',
        borderRadius: 'm',
        flex: 'none',
        minHeight: 'alert-min-height',
        variant: 'text.xs-dense-medium',
      }}
    >
      <FlexLayout
        alignItems="center"
        pr={actions.length > 0 ? px : undefined}
        space={3}
      >
        {enableIcon && (
          <Icon
            className={UI_ALERT_ICON_TESTID}
            color={variantStyle.icon.color}
            icon={icon}
            size="m"
            sx={{ alignSelf: isMultiline ? 'flex-start' : 'center' }}
          />
        )}
        <Box ref={contentRef}>{children}</Box>
      </FlexLayout>
      {actions.length > 0 && (
        <FlexLayout flex="none" mr={-px}>
          {actions.map((action, i) => {
            const { icon, text, onClick } = action;
            let content;
            if (icon) {
              content = (
                <Icon
                  aria-label="Dismiss alert"
                  className={UI_ALERT_ACTION_ICON_TESTID}
                  color={variantStyle.alert.color}
                  icon={icon}
                  size="m"
                  onClick={onClick}
                />
              );
            } else if (text) {
              content = (
                <Button text={text} variant="action" onClick={onClick} />
              );
            }
            return (
              <FlexLayout
                alignItems="center"
                key={i}
                my={-py}
                px={px}
                sx={{ borderLeft: 'border' }}
              >
                {content}
              </FlexLayout>
            );
          })}
        </FlexLayout>
      )}
    </FlexLayout>
  );
}

Alert.propTypes = {
  /** Render custom actions for the alert */
  actions: PT.arrayOf(
    PT.shape({
      /** click handler for action */
      onClick: PT.func,
      /** if specified, renders a text action */
      text: PT.string,
      /** if specified, renders an icon action */
      icon: PT.key,
    }).isRequired,
  ),
  /** Contents of the alert */
  children: PT.node,
  /** Renders the associated icon for the alert variant */
  enableIcon: PT.bool,
  /** Stylistic variant of the alert */
  variant: PT.oneOf([
    'info',
    'success',
    'warning',
    'danger',
    'critical',
    'loading',
  ]),
  /** If provided, renders a dismiss/close icon attaching the provided callback */
  onDismiss: PT.func,
};

export default Alert;
