import { pilot } from '~/services';
import { PilotId } from '~/types';

interface Request {
  clientId: PilotId;
  condition: 'failed' | 'unprocessed' | 'all' | 'stop';
  algorithms: string[];
}

export const reprocessAlgorithms = async ({
  clientId,
  condition,
  algorithms,
}: Request): Promise<boolean> => {
  await pilot.patch(`client/${clientId}/reprocess-documents/`, {
    algorithms,
    condition,
  });
  return true;
};
