import { cloneDeep } from 'lodash';
import { useState } from 'react';

import { Layout, Panel, Text } from '~/eds';
import {
  Nullable,
  TurnTrackingHistoryType,
  TurnTrackingPartyType,
} from '~/types';

import { EditTimeFields } from './EditTimeFields';
import { PartySelectField } from './PartySelectField';
import { TurnTrackingHistory } from './TurnTrackingHistory';
import { partyNameFromPartyIdAndParties } from './utils';

interface Props {
  history: TurnTrackingHistoryType[];
  parties: TurnTrackingPartyType[];
  disableSave: boolean;
  entry: TurnTrackingHistoryType;
  entryReference: TurnTrackingHistoryType;
  previousEntry: Nullable<TurnTrackingHistoryType>;
  nextEntry: Nullable<TurnTrackingHistoryType>;
  errorMessages: string[];
  isCurrentTurn: boolean;
  isLoadingActionButton: boolean;
  isCreatingNewEntry: boolean;
  position?: 'right';
  width?: 'm';
  isHidden?: boolean;
  onHide?: () => void;
  onBack: () => void;
  onSubmit: () => void;
  onEditEntry: (entry: TurnTrackingHistoryType) => void;
  onToggleCreatingNewEntry: (bool: boolean) => void;
}

export const EditCyclePanel = ({
  history,
  parties,
  disableSave,
  entry,
  entryReference,
  previousEntry,
  nextEntry,
  errorMessages,
  isCurrentTurn,
  isLoadingActionButton,
  isCreatingNewEntry,
  position,
  width,
  isHidden,
  onHide,
  onBack,
  onSubmit,
  onEditEntry,
  onToggleCreatingNewEntry,
}: Props) => {
  const [isPartySelected, setIsPartySelected] = useState(true);
  const currentPartyId = entryReference.partyId;
  const alternatePartyId = parties.filter(
    (party) => party.id !== currentPartyId,
  )[0].id;
  const partyName = partyNameFromPartyIdAndParties(entry.partyId, parties);
  return (
    <Panel
      backAction={{ text: 'Back', onClick: onBack }}
      title="Edit Cycle Time"
      footer={{
        actions: [
          {
            text: 'Cancel',
            level: 'secondary',
            onClick: onBack,
          },
          {
            text: isCreatingNewEntry ? 'Create Turn' : 'Save',
            level: 'primary',
            disabled: (disableSave && !isCreatingNewEntry) || !isPartySelected,
            onClick: onSubmit,
            isLoading: isLoadingActionButton,
          },
        ],
      }}
      {...(position && { position })}
      {...(width && { width })}
      {...(!!onHide && { hidden: { isHidden, onHide } })}
    >
      <Layout direction="column" spacing={8}>
        {nextEntry && (
          <Layout direction="column">
            <Text variant="section">Next Turn</Text>
            <TurnTrackingHistory parties={parties} historyEntry={nextEntry} />
          </Layout>
        )}
        <Layout direction="column">
          {!isCurrentTurn && <Text variant="section">Current Turn</Text>}
          {isCurrentTurn && (
            <Layout pb={4}>
              <PartySelectField
                history={history}
                parties={parties}
                onChange={(partyId) => {
                  if (partyId === currentPartyId) {
                    setIsPartySelected(true);
                    onToggleCreatingNewEntry(false);
                    onEditEntry(cloneDeep(entryReference));
                  } else if (partyId === alternatePartyId) {
                    setIsPartySelected(true);
                    onToggleCreatingNewEntry(true);
                    onEditEntry({
                      ...entry,
                      partyId: partyId!,
                      startTime: new Date(),
                    });
                  } else {
                    setIsPartySelected(false);
                  }
                }}
              />
            </Layout>
          )}
          <EditTimeFields
            partyName={partyName}
            entry={entry}
            disableInput={isCreatingNewEntry}
            onChange={onEditEntry}
          />
          {errorMessages.map((message: string) => (
            <Text variant="tiny" color="status.danger" role="alert">
              {message}
            </Text>
          ))}
        </Layout>
        {previousEntry && (
          <Layout direction="column">
            <Text variant="section">Previous Turn</Text>
            <TurnTrackingHistory
              parties={parties}
              historyEntry={previousEntry}
            />
          </Layout>
        )}
      </Layout>
    </Panel>
  );
};
