import Configuration from '../../../services/configuration';

const postUrl = `${Configuration.pilotEndpoint}/api/v2/document/upload/`;
export const componentConfig = { postUrl };

export const djsConfig = {
  autoQueue: false,
  autoProcessQueue: false,
  clickable: false,
  headers: {
    'X-Requested-With': null,
    'Cache-Control': null,
  },
  dictDefaultMessage: '',
  previewTemplate: '<div class="ignore"></div>',
  previewsContainer: null,
  chunking: false,
  forceChunking: false,
  retryChunks: true,
  parallelChunkUploads: false,
  parallelUploads: 1024,
  timeout: 0,
  withCredentials: true,
};
