export enum TicketStatusType {
  Approved = 'approved',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Editing = 'editing',
  Done = 'done',
  NotApplicable = 'not applicable',
  Pending = 'pending',
  PendingCompletion = 'pending completion',
  PendingFinalization = 'pending finalization',
  PendingSignatures = 'pending signatures', // inconvenience of data inconsistency with signature pending
  Queued = 'queued',
  Rejected = 'rejected',
  Review = 'in review',
  SignaturePending = 'signature pending',
  SignatureRequestNotSent = 'signature request not sent',
  Signed = 'signed',
  EmailDeliveryFailed = 'email delivery failed',
}
