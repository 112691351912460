export default `
<svg
  aria-hidden="true"
  aria-label="format-bold"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M6.55762 6.0166C6.55762 5.48535 6.99512 5.0166 7.55762 5.0166H13.5576C15.7451 5.0166 17.5576 6.8291 17.5576 9.0166C17.5576 10.0166 17.1826 10.9229 16.6201 11.6104C17.7764 12.2979 18.5576 13.5791 18.5576 15.0166C18.5576 17.2354 16.7451 19.0166 14.5576 19.0166H7.55762C6.99512 19.0166 6.55762 18.5791 6.55762 18.0166C6.55762 17.4854 6.99512 17.0166 7.55762 17.0166H8.05762V7.0166H7.55762C6.99512 7.0166 6.55762 6.5791 6.55762 6.0166ZM13.5576 11.0166C14.6514 11.0166 15.5576 10.1416 15.5576 9.0166C15.5576 7.92285 14.6514 7.0166 13.5576 7.0166H10.0576V11.0166H13.5576ZM10.0576 13.0166V17.0166H14.5576C15.6514 17.0166 16.5576 16.1416 16.5576 15.0166C16.5576 13.9229 15.6514 13.0166 14.5576 13.0166H10.0576Z"
    fill="currentcolor"
  />
</svg>
`;
