import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { shareTicketDocumentAttachments } from '~/api';
import { showToast } from '~/components/Shared/EcToast';
import { Button, IconButton } from '~/eds';
import { api, TAGS } from '~/redux';
import * as toastTypes from '~/types/toast.types';
import { coerceFileType } from '~/utils/files';

import OutboundEmailModal from '../../Modals/EmailModal/OutboundEmailModal';

function ShareDocumentButton({
  iconOnly = false,
  isDisabled,
  tooltip = 'Share current version',
  onShare,
  // connected
  ticket,
}) {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();
  const dispatch = useDispatch();

  function handleShowModal() {
    setShowModal(true);
  }

  function handleHideModal() {
    setShowModal(false);
  }

  const { name, type } = ticket.document;
  const attachments = type ? [{ name, type: coerceFileType(type || '') }] : [];
  const actionButton = {
    text: 'Share',
    variant: 'primary',
    errorHandler: () =>
      showToast(toastTypes.ERROR, 'Unable to share document, please try again'),
    promise: async function () {
      if (data) {
        await shareTicketDocumentAttachments({ data, ticketId: ticket.id });
        dispatch(api.util.invalidateTags([TAGS.TURN_TRACKING_TAG]));
        onShare();
        showToast(toastTypes.SUCCESS, 'Document successfully shared.');
      }
    },
  };
  return (
    <>
      {iconOnly ? (
        <IconButton
          disabled={isDisabled}
          icon="share"
          text="Share"
          tooltip={tooltip}
          onClick={handleShowModal}
        />
      ) : (
        <Button
          disabled={isDisabled}
          icon="share"
          text="Share"
          tooltip={tooltip}
          onClick={handleShowModal}
        />
      )}
      <OutboundEmailModal
        actionButton={actionButton}
        attachments={attachments}
        title="Share Document"
        visible={showModal}
        onHide={handleHideModal}
        onUpdate={setData}
      />
    </>
  );
}

const mapStateToProps = ({ ticket, currentUser }) => ({ ticket, currentUser });

export default connect(mapStateToProps)(ShareDocumentButton);
