import PropTypes from 'prop-types';
import React from 'react';

const InfoIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.color}
          fillOpacity={props.isHovered ? '.75' : props.opacity}
          fillRule="nonzero"
          d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm-1.6 8.4v8.4h2.868v-8.4H10.4zm1.385-1.75c.805 0 1.436-.635 1.436-1.453 0-.818-.63-1.453-1.436-1.453-.806 0-1.437.635-1.437 1.453 0 .818.63 1.453 1.437 1.453z"
        />
      </g>
    </svg>
  );
};

InfoIcon.defaultProps = {
  size: '24',
  color: '#FFF',
  opacity: '1',
};

InfoIcon.propTypes = {
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  opacity: PropTypes.string.isRequired,
};

export default InfoIcon;
