import React from 'react';
import { connect } from 'react-redux';

import { signerFieldDefinitions } from '~/constants/workflow';
import { Tooltip } from '~/ui';
import { getFieldDefinitionStatus, testDocumentField } from '~/utils/workflow';

import RadioItem from './RadioItem';

function RadioSigners({
  field,
  selectedFieldDefinition,
  onClick,
  onShowMore,
  // connected
  fieldLinks,
}) {
  return (
    <>
      {signerFieldDefinitions.map((fieldDefinition) => {
        const { isSelected } = getFieldDefinitionStatus({
          fieldDefinition,
          fieldLinks,
          selectedFieldDefinition,
        });
        const disabledTooltip =
          field &&
          !testDocumentField(field) &&
          fieldDefinition.key === 'signature'
            ? 'Cannot assign custom fields to the signature field'
            : undefined;
        return (
          <Tooltip content={disabledTooltip} key={fieldDefinition.key}>
            <RadioItem
              disabled={disabledTooltip}
              option={{
                label: fieldDefinition.label,
                value: fieldDefinition.key,
              }}
              selected={isSelected}
              onClick={() => {
                onClick(fieldDefinition);
                onShowMore();
              }}
              onShowMore={onShowMore}
            />
          </Tooltip>
        );
      })}
    </>
  );
}

const mapStateToProps = ({ workflow }) => ({
  fieldLinks: workflow.fieldLinks,
});

export default connect(mapStateToProps)(RadioSigners);
