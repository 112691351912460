import { IconColor, IconType, TextColor } from '../../types';
import { StepState } from './types';

interface Props {
  currentStepIndex: number;
  index: number;
  isCompleted: boolean;
}

export const getStepState = ({
  currentStepIndex,
  index,
  isCompleted,
}: Props): StepState => {
  const isCurrentStep = index === currentStepIndex;
  const isPreviousStep = index < currentStepIndex;
  if (isPreviousStep) {
    return 'completed';
  } else if (isCurrentStep) {
    return isCompleted ? 'completed' : 'current';
  } else {
    return 'inactive';
  }
};

interface StepStateConfig {
  bg: string;
  bgCondensed: string;
  color: TextColor;
  icon: IconType;
  iconColor: IconColor;
}

const stepStateConfigs: Record<StepState, StepStateConfig> = {
  completed: {
    bg: 'status.success',
    bgCondensed: 'status.success',
    color: 'inverse.text.primary',
    icon: 'status-complete',
    iconColor: 'inverse.icon',
  },
  current: {
    bg: 'status.warning.secondary',
    bgCondensed: 'status.warning',
    color: 'text.primary',
    icon: 'status-not-started',
    iconColor: 'status.warning',
  },
  inactive: {
    bg: 'status.inactive.secondary',
    bgCondensed: 'status.inactive.secondary',
    color: 'text.quiet',
    icon: 'status-inactive',
    iconColor: 'status.inactive',
  },
};

export const getStepStateConfig = (state: StepState): StepStateConfig => {
  return stepStateConfigs[state];
};
