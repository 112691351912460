import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { black5 } from '../../../assets/shared-styles/general';
import {
  searchWordsContainOptions,
  searchWordsScopeOptions,
} from '../../AnalyzerPage/TextSearchForm/TextSearchForm.utils';
import EcButton from '../EcButton';
import EcInput from '../EcInput';
import EcSelect from '../EcSelect';
import EcTooltip from '../EcTooltip';
import withHover from '../HOCs/withHover';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import RemoveIcon from '../Icons/RemoveIcon';
import SubArrowIcon from '../Icons/SubArrowIcon';
import styles from './EcFilterTextSearch.styles';

const RemoveIconWithHover = withHover(RemoveIcon);

class EcFilterTextSearch extends Component {
  constructor(props) {
    super(props);

    this.handleOnRelationChange = this.handleOnRelationChange.bind(this);
    this.handleOnScopeChange = this.handleOnScopeChange.bind(this);
  }

  handleOnTextChange(text) {
    this.props.onChange({ text, error: false });
  }

  handleOnRelationChange(contains) {
    this.props.onChange({ contains });
  }

  handleOnScopeChange(scope) {
    this.props.onChange({ scope });
  }

  renderTextSearchValues() {
    const { classes, text, error } = this.props;

    return (
      <div className={classes.textValuesContainer}>
        <SubArrowIcon color={black5} />
        <EcInput
          input={{
            value: text || '',
            onChange: (e) => this.handleOnTextChange(e.target.value),
          }}
          placeholder="space-separated list of words"
          width="100%"
          error={error && (!text || !text.trim())}
        />
      </div>
    );
  }

  render() {
    const {
      classes,
      area,
      contains,
      scope,
      onRemoveClick,
      onFilterNameClick,
    } = this.props;

    return (
      <div className={classes.ecFilterFieldWrapper}>
        <div className={classes.ecFilterField}>
          <button
            title="Remove field"
            className={classes.removeFieldContainer}
            onClick={onRemoveClick}
          >
            <RemoveIconWithHover size="20" />
          </button>
          <div
            className={classes.buttonContainer}
            data-tip
            data-for={`field-tooltip-${area.value}`}
          >
            <EcButton
              text={area.label}
              width="200px"
              height="44px"
              iconRight={<ChevronDownIcon />}
              onClick={onFilterNameClick}
            />
            <EcTooltip id={`field-tooltip-${area.value}`}>
              {area.label}
            </EcTooltip>
          </div>
          <hr className={classes.blueLine} />
          <div className={classes.fieldContainer}>
            <EcSelect
              value={contains}
              defaultValue={contains}
              options={searchWordsContainOptions}
              onChange={this.handleOnRelationChange}
            />
          </div>
          <hr className={classes.blueLine} />
          <div className={classes.fieldContainer}>
            <EcSelect
              value={scope}
              defaultValue={scope}
              options={searchWordsScopeOptions}
              onChange={this.handleOnScopeChange}
            />
          </div>
        </div>
        {this.renderTextSearchValues()}
      </div>
    );
  }
}

EcFilterTextSearch.propTypes = {
  entity: PropTypes.string.isRequired,
  area: PropTypes.object.isRequired,
  contains: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  scope: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onFilterNameClick: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default injectSheet(styles)(EcFilterTextSearch);
