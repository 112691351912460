import React, { useEffect, useRef, useState } from 'react';

import { Box, Mark, types } from '~/eds';
import { HighlightScaledPosition } from '~/types';
import { theme } from '~/ui';

export type OverlapPriority = 'normal' | 'high';

export interface HighlightProps {
  id: string;
  isActive?: boolean;
  position: HighlightScaledPosition;
  overlappingPriority?: OverlapPriority;
  variant?: types.HighlightType;
  onClick?: (highlightId: string) => void;
}

export default function Highlight({
  id,
  variant = 'default',
  isActive,
  overlappingPriority = 'normal',
  position,
  onClick,
}: HighlightProps) {
  const { rects } = position;
  const [isMouseDown, setIsMouseDown] = useState(false);
  const isMouseDownRef = useRef(false);
  const modifier = isActive ? ':active' : '';

  const onPdfHighlighterContainerMouseDown = () => {
    isMouseDownRef.current = true;
    setIsMouseDown(true);
  };

  const onPdfHighlighterContainerMouseUp = () => {
    isMouseDownRef.current = false;
    setIsMouseDown(false);
  };

  const addMouseEventsToPdfHighlighter = () => {
    const pdfHighlighterDiv = document.querySelector('.PdfHighlighter');
    if (pdfHighlighterDiv) {
      pdfHighlighterDiv?.addEventListener(
        'mousedown',
        onPdfHighlighterContainerMouseDown,
      );

      pdfHighlighterDiv?.addEventListener(
        'mouseup',
        onPdfHighlighterContainerMouseUp,
      );
    }

    return pdfHighlighterDiv;
  };

  useEffect(() => {
    const pdfHighlighterDiv = addMouseEventsToPdfHighlighter();

    return () => {
      if (pdfHighlighterDiv) {
        pdfHighlighterDiv.removeEventListener(
          'mousedown',
          onPdfHighlighterContainerMouseDown,
        );
        pdfHighlighterDiv.removeEventListener(
          'mouseup',
          onPdfHighlighterContainerMouseUp,
        );
      }
    };
  }, []);

  const onHighlightMouseDown = () => {
    setTimeout(() => {
      if (!isMouseDownRef.current) {
        onClick?.(id);
      }
    }, 100);
  };

  return (
    <Mark
      isActive={isActive}
      highlighter={variant}
      onMouseDown={onHighlightMouseDown}
    >
      {rects.map(({ height, width, top = 0, left = 0 }, index) => (
        <Box
          bg="inherit"
          id={index === 0 ? id : undefined}
          key={`${id}_${index}`}
          h={height}
          w={width}
          styles={{
            backgroundImage: 'inherit',
            top,
            left,
            //TODO: find a better way of allowing the user to select text under a highlight
            pointerEvents: isMouseDown ? 'none' : 'auto',
            cursor: 'pointer',
            zIndex:
              theme.zIndices.highlight[
                `${overlappingPriority}${modifier}` as keyof typeof theme.zIndices.highlight
              ],
          }}
          position="absolute"
        />
      ))}
    </Mark>
  );
}
