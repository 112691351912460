import { noop } from 'lodash';
import React, { useMemo } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import { ClearFilters } from '~/components/Shared/Filters_DEPRECATED';
import { Layout, LoadingShimmer, Text } from '~/eds';
import { Filter } from '~/evifields';
import { CrossFilters } from '~/features/advanced-search/cross-filters';
import { PinnedFilters } from '~/features/search/PinnedFilters';
import { useCurrentUser } from '~/hooks';
import { FiltersCount, PilotId, SearchFilter } from '~/types';

import FilterChips from './FilterChips';
import {
  addFilters,
  clearFilter,
  removeFilter,
  updateFilter,
} from './Filters.utils';
import ManageFilters from './ManageFilters';

interface Options {
  /** Disable managing filters */
  disableManageFilters: boolean;
  /** Disable remove icon on filter chips */
  disableRemoveFilter: boolean;
  /** Disable Clear Filters button to clear the filter values */
  disableClearFilters: boolean;
}

interface Props {
  /** this flag will enable alternating some filter views
   * based on filter.filterViewType property.
   */
  enableFilterViews?: boolean;
  disableDefaultLabel?: boolean;
  filters: Filter[];
  groupsOrder: string[];
  searchFilters: SearchFilter[];
  onChange: (filters: Filter[]) => void;
  crossFilters?: Filter[];
  filterCount?: number;
  isLoading?: boolean;
  operatorValue?: string;
  options?: Partial<Options>;
  placeholder?: string;
  pinnedFiltersIds?: Array<PilotId | string>;
  queryBuilder?: React.ReactNode;
  readOnly?: boolean;
  onChangeCrossFilters?: (crossFilters: Filter[]) => void;
  onClearFilters?: () => void;
  onOperatorChange?: (operatorValue: string) => void;
  onUpdatePinnedFilters?: (
    updatedPinnedFilters: Array<PilotId | string>,
  ) => void;
  onClickPinnedFilter?: (fieldId: PilotId | string) => void;
}

export interface FiltersProps extends Props {}

const Filters = ({
  enableFilterViews = false,
  disableDefaultLabel = false,
  crossFilters = [],
  filters,
  filterCount,
  groupsOrder,
  isLoading,
  operatorValue,
  searchFilters,
  options = {},
  placeholder,
  pinnedFiltersIds,
  queryBuilder,
  readOnly,
  onChange,
  onChangeCrossFilters = noop,
  onClearFilters,
  onOperatorChange,
  onUpdatePinnedFilters,
  onClickPinnedFilter,
}: Props) => {
  const hasOperators = !!onOperatorChange;
  const {
    disableManageFilters = false,
    disableRemoveFilter = false,
    disableClearFilters = false,
  } = options;
  const user = useCurrentUser();

  const searchFiltersMap = useMemo(() => {
    return searchFilters.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {} as Record<string, SearchFilter>);
  }, [searchFilters]);

  const handleAddFilters = (selectedFieldIds: string[]) =>
    onChange(addFilters(filters, selectedFieldIds));

  const handleClearFilters = () => {
    onChange(filters.map(clearFilter));
    onClearFilters?.();
  };

  const handleRemoveFilter = (filterToRemove: Filter) => {
    const field = searchFilters.find(
      (searchFilter) => searchFilter.id === filterToRemove.fieldId,
    );
    trackSegment('removeFilter', {
      fieldId: filterToRemove.fieldId,
      name: field?.label,
      user_id: user.id,
      client_id: user.client,
    });
    onChange(removeFilter(filters, filterToRemove));
  };

  const handleUpdateFilter = (updatedFilter: Filter) => {
    const field = searchFilters.find(
      (searchFilter) => searchFilter.id === updatedFilter.fieldId,
    );
    if (updatedFilter.operatorId) {
      trackSegment('updateFilter', {
        fieldId: updatedFilter.fieldId,
        values: updatedFilter.values,
        operatorId: updatedFilter.operatorId,
        name: field?.label,
        type: field?.type,
        isAllSelected: updatedFilter.asyncValue?.isAllSelected,
        user_id: user.id,
        client_id: user.client,
      });
    } else {
      trackSegment('clearFilter', {
        fieldId: updatedFilter.fieldId,
        name: field?.label,
        user_id: user.id,
        client_id: user.client,
      });
    }

    onChange(updateFilter(filters, updatedFilter));
  };

  const getActionButtonTooltip = ({ selectedFiltersCount }: FiltersCount) => {
    if (selectedFiltersCount === 0) {
      return 'Please select at least one filter.';
    }
    return '';
  };

  const title = 'Filters';

  const manageFilters = !readOnly && (
    <Layout align="center" mb={4} justify="flex-start">
      {!disableDefaultLabel && (
        <Text variant="body-bold" whiteSpace="nowrap" mr={4}>
          {title}
        </Text>
      )}
      {filters.length > 0 && !disableClearFilters && !isLoading && (
        <ClearFilters onClear={handleClearFilters} />
      )}
      {!disableManageFilters && !isLoading && (
        <ManageFilters
          preset="add-filters"
          getActionButtonTooltip={getActionButtonTooltip}
          groupsOrder={groupsOrder}
          onApply={handleAddFilters}
          pinnedFiltersIds={pinnedFiltersIds}
          searchFilters={searchFilters}
          onUpdatePinnedFilters={onUpdatePinnedFilters}
        />
      )}
      {queryBuilder}
    </Layout>
  );

  const totalCount = filterCount ?? filters.length + crossFilters.length;
  const loadingShimmers = Array.from({ length: totalCount }).map((_, i) => (
    <LoadingShimmer key={i} height="filter.height" />
  ));

  const filterComponents = (
    <Layout direction="column" minW={0} preset="s">
      {pinnedFiltersIds && onClickPinnedFilter && (
        <Layout align="center">
          <Text variant="body-bold" whiteSpace="nowrap" mr={4}>
            Pinned
          </Text>
          <PinnedFilters
            filterIds={pinnedFiltersIds}
            searchFilters={searchFiltersMap}
            onClick={onClickPinnedFilter}
          />
        </Layout>
      )}
      <Layout wrap role="list" aria-label="selected" align="center">
        {pinnedFiltersIds && (
          <Text variant="body-bold" whiteSpace="nowrap" mr={4}>
            Selected
          </Text>
        )}
        <FilterChips
          name="selected"
          enableFilterViews={enableFilterViews}
          disableRemove={disableRemoveFilter}
          searchFilters={searchFilters}
          filters={filters}
          operatorValue={operatorValue}
          placeholder={placeholder}
          readOnly={readOnly}
          onOperatorChange={onOperatorChange}
          onRemoveFilter={handleRemoveFilter}
          onUpdateFilter={handleUpdateFilter}
        />
        {crossFilters.length > 0 && (
          <CrossFilters
            searchFilters={searchFilters}
            filters={crossFilters}
            onChange={onChangeCrossFilters}
          />
        )}
      </Layout>
    </Layout>
  );

  return (
    <Layout direction="column" w="100%">
      {hasOperators ? null : manageFilters}
      <Layout
        wrap
        spacing={1}
        wrapSpacing={1}
        role="group"
        aria-label="Filters"
      >
        {isLoading ? loadingShimmers : filterComponents}
      </Layout>
    </Layout>
  );
};

export default Filters;
