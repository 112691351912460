import { FileExtensionType, FileMimeType } from '~/enums';

export const PAGE_INDEX = 1;

export const TABLE_OPTIONS = {
  enableExportXlsx: false,
  enableSelectRows: true,
  enableManageColumns: false,
};

export const DEFAULT_ACCEPTED_FILES = {
  [FileMimeType.Csv]: [FileExtensionType.Csv],
  [FileMimeType.Txt]: [FileExtensionType.Txt],
};
