import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { Card, Layout, Link, Text, useModal } from '~/eds';
import { AutomationStageType, QueryParamType } from '~/enums';
import { actions, api } from '~/redux';
import { buildQuery } from '~/redux/api/methods';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { LoadingContainer } from '~/ui';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';

import AccuracyMetrics from '../../AutomationHubGuidedAiPage/Content/AccuracyMetrics';
import AutomationHubSearchingFilters from '../../AutomationHubSearchFilters';
import {
  ACCURACY_METRIC_TRAINING_STAGES,
  TRAINING_STAGES,
} from '../../constants';

const GuidedAiStatus = ({
  model,
  filters,
  fields,
  manage,
  stage,
  resetAutomationFilters,
}) => {
  const isInViewMode = manage === AutomationStageType.ViewStatus;
  const currentMetrics = model?.current?.accuracyMetrics;
  const hasCurrentMetrics = !isEmpty(currentMetrics);

  const clauseId = getPageSearchQueryByKey(QueryParamType.clauseId, model.id);

  const [
    updatePredictionFilters,
    updatePredictionFiltersResults,
  ] = api.endpoints.updatePredictionFilters.useMutation();
  const {
    isError: isUpdatePredictionFiltersError,
    isSuccess: isUpdatePredictionFiltersSuccess,
    isLoading: isUpdatePredictionFiltersLoading,
  } = updatePredictionFiltersResults;

  const [modal, show, hide] = useModal({
    disableHideOnEscape: true,
    children: (
      <Layout direction="column" spacing={2}>
        <Text>
          You trained your model using All Types of contracts. What contracts
          would you like your model to tag with the {model.name} clause?
        </Text>
        <AutomationHubSearchingFilters prediction />
      </Layout>
    ),
    primaryAction: {
      isLoading: isUpdatePredictionFiltersLoading,
      text: 'Done',
      variant: 'primary',
      onClick: () => {
        const filterQuery = buildQuery({
          filters: filters.filter((f) => !!f.operatorId),
          fields,
          booleanQuery: '',
        });

        return updatePredictionFilters({
          clauseId,
          predictionFilters: filterQuery,
        });
      },
    },
    title: 'Apply Model to Specific Contracts',
    onCancel: () => resetAutomationFilters(),
    onHide: () => resetAutomationFilters(),
  });

  useEffect(() => {
    if (isUpdatePredictionFiltersSuccess) {
      const message = 'Update prediction filter successfully.';
      showToast(SUCCESS, message);

      hide();
      resetAutomationFilters();
    }
    if (isUpdatePredictionFiltersError) {
      const message = 'Update prediction filter failed. Please try again.';

      showToast(ERROR, message);
    }
  }, [isUpdatePredictionFiltersSuccess, isUpdatePredictionFiltersError]);

  if (!model?.current) return null;

  return (
    <LoadingContainer isLoading={!hasCurrentMetrics && !isInViewMode}>
      <Layout
        direction="column"
        align="left"
        alignSelf="center"
        spacing={12}
        mt={isInViewMode ? 8 : 0}
      >
        {!isInViewMode && (
          <Card mode="bordered">
            <Layout spacing={4}>
              <Layout direction="column" spacing={4}>
                <Text variant="subtitle">{TRAINING_STAGES[stage]?.title}</Text>
                {TRAINING_STAGES[stage]?.scoreDescription.map(
                  (description, index) =>
                    index <
                    TRAINING_STAGES[stage]?.scoreDescription.length - 1 ? (
                      <Text>{description}</Text>
                    ) : (
                      <Text>
                        {description}
                        {TRAINING_STAGES[stage]?.canAdjustPredictionFilters ? (
                          <Link onClick={show}>adjust your filters.</Link>
                        ) : null}
                      </Text>
                    ),
                )}
                <Text>
                  <strong>Training Round: </strong>
                  {TRAINING_STAGES[stage]?.trainingRound}
                </Text>
              </Layout>
            </Layout>
          </Card>
        )}
        {ACCURACY_METRIC_TRAINING_STAGES.includes(model.stage) && (
          <AccuracyMetrics />
        )}
      </Layout>
      {modal}
    </LoadingContainer>
  );
};

const mapStateToProps = ({ automation }) => ({
  filters: automation.filter.filters,
  fields: automation.filter.fields,
  model: automation.model,
  manage: automation.manage,
  stage: automation.model?.stage,
});

export default connect(mapStateToProps, {
  resetAutomationFilters: actions.resetAutomationFilters,
})(GuidedAiStatus);
