import React, { memo, useCallback, useMemo } from 'react';
import { useStyles } from 'uinix-ui';

import { STATUS_MESSAGE_AUTO_DISMISS_MS } from '../../constants';
import { useElapse, useToggle } from '../../hooks';
import { BaseStatusMessageProps, IconType, UserAction } from '../../types';
import { Actions } from '../Actions';
import { Box } from '../Box';
import { CollapsibleText } from '../CollapsibleText';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Markdown } from '../Markdown';
import { Text } from '../Text';

interface Props extends BaseStatusMessageProps {
  /** aria-live to indicate the type of announcement of the live placeholder region */
  ariaLive?: 'assertive' | 'polite';
  /** Enables auto dismissible status message */
  autoDismiss?: boolean;
  /** Specifies the limit messsage length before using a CollapsibleText */
  limit?: number;
  /** supported modes */
  mode?: 'banner' | 'note' | 'column';
}

export const StatusMessage = memo(
  ({
    action,
    ariaLive,
    autoDismiss,
    limit,
    message,
    mode = 'note',
    status = 'info',
    title,
    onDismiss,
  }: Props) => {
    const [isVisible, _toggleIsVisible, _show, dismiss] = useToggle(true);

    const handleDismiss = useCallback(() => {
      onDismiss?.();
      dismiss();
    }, [onDismiss]);

    const actions = useMemo(() => {
      const actions: UserAction[] = [];
      if (action) {
        actions.push(action);
      }
      if (onDismiss) {
        actions.push({
          icon: 'x',
          mode: 'icon',
          text: 'Dismiss',
          tooltip: 'Dismiss',
          onClick: handleDismiss,
        });
      }
      return actions;
    }, [action, handleDismiss]);

    useElapse(STATUS_MESSAGE_AUTO_DISMISS_MS, {
      disabled: !autoDismiss,
      callback: handleDismiss,
    });

    const styles = useStyles();

    if (!isVisible) {
      return null;
    }

    return (
      <Layout
        align={mode === 'column' ? 'start' : 'center'}
        aria-live={ariaLive}
        border="border"
        borderRadius={mode === 'banner' ? undefined : 'm'}
        direction={mode === 'column' ? 'column' : 'row'}
        justify="space-between"
        px={4}
        py={3}
        role="alert"
        spacing={4}
        styles={styles.colors.status}
        styleProps={{ status }}
      >
        <Layout align="center" spacing={2}>
          <Icon icon={`status-${status}` as IconType} />
          <Layout direction="column">
            {title && <Text variant="body-bold">{title}</Text>}
            {limit ? (
              <CollapsibleText
                limit={limit}
                text={message}
                whiteSpace="pre-wrap"
              />
            ) : (
              <Markdown text={message} />
            )}
          </Layout>
        </Layout>
        {actions.length > 0 && (
          <Box
            ml={mode === 'column' ? 3 : undefined}
            styles={styles.sticky.top(4)}
          >
            <Actions actions={actions} />
          </Box>
        )}
      </Layout>
    );
  },
);
