import React from 'react';

import { Checkbox } from '../../../components';
import { Nullable } from '../../../types';

interface Props {
  /** Checkbox aria-label */
  label: string;
  /** Checkbox input name */
  name: string;
  /** Checkbox value */
  value: Nullable<boolean>;
  /** Checkbox onChange handler */
  onChange: (updatedValue: Nullable<boolean>) => void;
  /** If checkbox is shift-clicked */
  onShiftClick?: () => void;
}

export const CheckboxCell = ({
  label,
  name,
  value,
  onChange,
  onShiftClick,
}: Props) => {
  const option = { label, value };
  return (
    <Checkbox
      labelMode="hidden"
      name={name}
      option={option}
      value={value}
      onChange={onChange}
      onShiftClick={onShiftClick}
    />
  );
};
