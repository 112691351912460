import { types } from '~/eds';
import { PilotSearchQuery } from '~/redux/api/methods';
import {
  BooleanQueryEntity,
  EntityQuery,
  FilterQueryEntity,
  Query,
  QueryOperatorEntity,
  SectionEntity,
} from '~/types';

import { SavedSearch } from '../types';

export const testIsFilter = (
  queryEntity: EntityQuery,
): queryEntity is FilterQueryEntity => queryEntity.type === 'filter';

export const testIsSection = (
  queryEntity: EntityQuery,
): queryEntity is SectionEntity => queryEntity.type === 'section';

export const testIsBooleanTextSearch = (
  queryEntity: EntityQuery,
): queryEntity is BooleanQueryEntity => queryEntity.type === 'bool_text_search';

export const testIsOperator = (
  queryEntity: EntityQuery,
): queryEntity is QueryOperatorEntity => queryEntity.type === 'operator';

export const testIsQueryV2 = (
  savedSearch: SavedSearch,
): savedSearch is SavedSearch & { query: PilotSearchQuery } => {
  return savedSearch.version === 2;
};

export const testIsQueryV3 = (
  savedSearch: SavedSearch,
): savedSearch is SavedSearch & { query: Query } => {
  return savedSearch.version === 3;
};

export const testHasNestedSections = (query: Query) => {
  const firstLevelSections = query.filter(testIsSection);
  return firstLevelSections.some((section) =>
    section.value.some(testIsSection),
  );
};

export const testHasOrOperator = (query: Query) =>
  query.filter(testIsOperator).some((operator) => operator.value === 'or');

export const testHasMixedOperatorInSection = (query: Query) =>
  query
    .filter(testIsSection)
    .some((section) => testHasMixedOperators(section.value));

export const testHasMixedOperators = (query: Query) =>
  query
    .filter(testIsOperator)
    .some((operator, _, arr) =>
      operator.value === 'and' || operator.value === 'or'
        ? arr.some((op) => op.value !== operator.value)
        : false,
    );

export const getActiveFilterIds = (query: Query): types.PilotId[] => {
  return query.filter(testIsFilter).map((filter) => filter.id);
};
