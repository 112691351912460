import Tippy from '@tippyjs/react';
import PT from 'prop-types';
import React, { useEffect, useState } from 'react';
import injectSheet from 'react-jss';
import 'tippy.js/dist/tippy.css';

import { Box } from '~/ui';

import styles from './Popover.styles';

function Popover({
  content,
  isVisible: initialIsVisible = false,
  placement = 'right-start',
  trigger,
  triggerStyle = {},
  onShow,
  onHide,
  hideWhenClickOutside = true,
  reference,
  offset,
  // injected
  classes,
}) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);

  useEffect(() => setIsVisible(initialIsVisible), [initialIsVisible]);

  function hide() {
    onHide && onHide();
    setIsVisible(false);
  }

  function onClickOutside() {
    hideWhenClickOutside && hide();
  }

  function show() {
    onShow && onShow(true);
    setIsVisible(true);
  }

  const Trigger = (
    <Box as="span" sx={triggerStyle} onClick={show}>
      {trigger}
    </Box>
  );

  return (
    <Tippy
      reference={reference}
      animation={false}
      appendTo={document.body}
      arrow={false}
      className={classes.popover}
      content={content}
      disabled={!isVisible || !content}
      interactive
      placement={placement}
      visible={true}
      onClickOutside={onClickOutside}
      onHide={hide}
      offset={offset}
    >
      {Trigger}
    </Tippy>
  );
}

Popover.propTypes = {
  /** Any react node */
  content: PT.node,
  /** Controlled behavior for showing the Popover */
  isVisible: PT.bool,
  /** Popover placement */
  placement: PT.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  /** React node to trigger popover */
  trigger: PT.node.isRequired,
  /** Optionally customize the trigger with styles */
  triggerStyle: PT.object,
  /** Optional callback when Popover is visible. Use in controlled mode. */
  onShow: PT.func,
  /** Optional callback when Popover is hidden. Use in controlled mode */
  onHide: PT.func,
  /** Optional boolean to close the popover when click outside */
  hideWhenClickOutside: PT.bool,
  /**Optional reference to placement prop */
  reference: PT.object,
  /**Optional prop to position the popover with offsets relative to 'reference' */
  offset: PT.arrayOf(PT.number),
};

export default injectSheet(styles)(Popover);
