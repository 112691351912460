import { black2, evisortBlue } from '../../../assets/shared-styles/general';

const styles = {
  filterSearch: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    height: '36px',
    borderRadius: '4px',
    paddingLeft: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.025)',

    '& input': {
      paddingRight: '40px',
      width: '100%',
      border: 'none',
      padding: '8px',
      backgroundColor: 'transparent',
      color: black2,
      fontSize: '14px',
      outline: 'none',
      caretColor: evisortBlue,
      '&::placeholder': {
        color: '#626868',
        fontSize: '14px',
      },
    },
    '& button': {
      position: 'absolute',
      right: '5px',
      cursor: 'pointer',
    },
  },
};

export default styles;
