import { first } from 'lodash';
import { useEffect, useMemo } from 'react';

import {
  Accordion,
  Box,
  ContentContainer,
  Layout,
  SearchInput,
  testHasTextMatch,
  useThrottledState,
} from '~/eds';
import { usePollingRequest } from '~/hooks';
import { api } from '~/redux';
import { ClauseContent, DocumentViewClause, Nullable, Uuid } from '~/types';

import { Clause } from './Clause';

interface Props {
  activeClauseId?: Nullable<number>;
  id?: Uuid;
  isLoading?: boolean;
  /**
   * If true, the clauses will be polled.
   */
  isReadyToPoll?: boolean;
  versionHasAi: boolean;
  onClauseClick?: (clause: ClauseContent) => void;
  onClearHighlight?: () => void;
}

export const Clauses = ({
  activeClauseId,
  id,
  isLoading,
  isReadyToPoll = true,
  versionHasAi,
  onClauseClick,
  onClearHighlight,
}: Props) => {
  const [throttledSearch, search, setSearch] = useThrottledState<
    Nullable<string>
  >('');
  const {
    data = [],
    isPollingFinished,
    isUninitialized,
    isError,
  } = usePollingRequest(
    api.endpoints.getDocumentDetailClauses.useQuery,
    { documentTag: id ?? '' },
    {
      skip: !id || !versionHasAi || !isReadyToPoll,
      maxRetries: 720,
      pollingInterval: 5000,
    },
  );

  useEffect(() => {
    // called when the clauses panel is destroyed
    return () => {
      onClearHighlight?.();
    };
  }, []);

  const identifiedClauses: number = useMemo(
    () =>
      data.reduce(
        (totalClauses: number, documentViewClause: DocumentViewClause) => {
          return totalClauses + documentViewClause.content.length;
        },
        0,
      ),
    [data],
  );

  const loadingMessage = `Clauses are being identified. Evisort AI already identified ${identifiedClauses} clauses for this contract. User driven clause identifications are continuing to run.`;

  // TODO: refactor to use `eds.Panel[placeholderContent]` directly instead of explicit `ContentContainer`
  if (!versionHasAi || !id) {
    return (
      <ContentContainer
        placeholderContent={{
          icon: 'field-clause',
          message:
            'No clauses available because user uploaded document without running AI. Please upload/save a new version to see clauses identified by Evisort AI.',
        }}
      />
    );
  }

  if (isError) {
    return (
      <ContentContainer
        placeholderContent={{
          icon: 'field-clause',
          message:
            'Something went wrong when retrieving the clauses. Please refresh and load again or contact your admin.',
        }}
      />
    );
  }

  if (!isLoading && isPollingFinished && !isUninitialized && data.length <= 0) {
    return (
      <ContentContainer
        placeholderContent={{
          icon: 'field-clause',
          message: 'No clauses exists for this document.',
        }}
      />
    );
  }

  const getClauseContent = (clauses: ClauseContent[], clauseName: string) => (
    <Clause
      activeClauseId={activeClauseId}
      clauseName={clauseName}
      clauses={clauses}
      onClauseClick={onClauseClick}
    />
  );

  const clauses: {
    content: JSX.Element;
    data: DocumentViewClause;
    title: string;
    isExpanded: boolean;
  }[] = data.map((clause: DocumentViewClause) => ({
    content: getClauseContent(clause.content, clause.name),
    data: clause,
    title: `${clause.name} (${clause.content.length})`,
    isExpanded: clause.content.some(
      (contentClause) => contentClause.id === activeClauseId,
    ),
  }));

  const filteredClauses = search?.length
    ? clauses.filter((clause) => testHasTextMatch(clause.title, search))
    : clauses;

  return (
    <Layout direction="column">
      <Box px={6} py={2}>
        <SearchInput
          placeholder="Search for clauses"
          name="Search Clauses"
          value={search}
          onChange={setSearch}
        />
      </Box>
      <ContentContainer
        loadingContent={{
          isLoading: isLoading,
          description: loadingMessage,
        }}
        placeholderContent={{
          message: loadingMessage,
        }}
      />
      <Accordion
        items={filteredClauses}
        search={throttledSearch ?? ''}
        onToggle={(isExpanding, item) => {
          const { content: clauses = [], name } = item.data ?? {};
          if (isExpanding) {
            const firstClause = first(clauses);
            if (firstClause) {
              onClauseClick?.({ ...firstClause, name });
            }
          } else {
            // when clicking another clause in the clause panel
            if (clauses.map((clause) => clause.id).includes(activeClauseId!)) {
              onClearHighlight?.();
            }
          }
        }}
      />
    </Layout>
  );
};
