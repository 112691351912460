export default `
<svg
  aria-hidden="true"
  aria-label="reaction-thumbsdown"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M8 12.9844V6.01562C8 5.45312 7.53125 5.01562 7 5.01562H5C4.4375 5.01562 4 5.48438 4 6.01562V12.9844C4 13.5156 4.4375 13.9531 5 13.9531H7C7.53125 13.9844 8 13.5469 8 12.9844ZM19.0312 11.1719C19.0625 11.0156 19.0938 10.8281 19.0938 10.6719C19.0938 9.95312 18.7188 9.32812 18.1875 8.95312C18.1875 8.85938 18.1875 8.76562 18.1875 8.67188C18.1875 7.95312 17.8438 7.32812 17.2812 6.95312C17.2188 5.85938 16.3125 5.01562 15.2188 5.01562H13.5625C12.4375 5.01562 11.3438 5.35938 10.4375 6.04688L9.28125 6.89062C9.09375 7.04688 9 7.26562 9 7.51562C9 7.89062 9.3125 8.26562 9.75 8.26562C9.90625 8.26562 10.0625 8.20312 10.1875 8.10938L11.3438 7.23438C11.9688 6.76562 12.75 6.48438 13.5625 6.48438H15.2188C15.5312 6.48438 15.8125 6.76562 15.8125 7.07812C15.8125 7.20312 15.75 7.23438 15.75 7.39062C15.75 8.23438 16.6875 7.95312 16.6875 8.67188C16.6875 8.95312 16.5 8.98438 16.5 9.35938C16.5 9.79688 16.8438 10.0469 17.0938 10.0781C17.375 10.1406 17.5938 10.3906 17.5938 10.6719C17.5938 11.0781 17.2188 11.0781 17.2188 11.5781C17.2188 11.9531 17.5312 12.2969 17.9375 12.3281C18.25 12.3281 18.5 12.6094 18.5 12.8906C18.5 13.2031 18.2188 13.4844 17.9062 13.4844H13.5938C13.1875 13.4844 12.8438 13.7969 12.8438 14.2031C12.8438 14.3281 12.875 14.4531 12.9375 14.5469C13.5 15.6094 13.75 16.6094 13.75 16.8594C13.75 17.1094 13.5625 17.4844 13.0312 17.4844C12.6562 17.4844 12.5938 17.4844 12.2812 16.6094C11.5 14.6719 10.3438 13.5156 9.75 13.5156C9.3125 13.5156 9 13.8594 9 14.2656C9 14.4844 9.09375 14.7031 9.28125 14.8281C11.4375 16.5469 10.5938 18.9844 13.0312 18.9844C14.4062 18.9844 15.25 17.8906 15.25 16.8594C15.25 16.4531 15.0625 15.7344 14.7812 14.9844H17.9062C19.0312 14.9844 20 14.0469 20 12.8906C20 12.1719 19.5938 11.5156 19.0312 11.1719Z"
    fill="currentcolor"
  />
</svg>
`;
