import { isDate, isMatch, parse, parseISO } from 'date-fns';

export const parseDate = (dateValue: any) => {
  if (isDate(dateValue)) {
    return dateValue;
  }

  // Date formats
  const formats = [
    'yyyy-MM-dd',
    "yyyy-MM-dd'T'HH:mm:ss",
    "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSSS'Z'",
    "yyyy-MM-dd'T'HH:mm:ss.SSSSSSxxx",
    "yyyy-MM-dd'T'HH:mm:ssXXX",
  ];

  //Check if the date value is valid format
  const isAValidFormat = (dateValue: any) =>
    formats.some((format) => isMatch(dateValue, format));

  //Get the format of the valid date
  const getFormat = (dateValue: any) =>
    formats.find((format) => isMatch(dateValue, format));

  if (isAValidFormat(dateValue)) {
    const format = getFormat(dateValue);
    switch (format) {
      case 'yyyy-MM-dd':
        return parse(dateValue, format, new Date());
      // Handle up to 9 digits of milliseconds
      case "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSSS'Z'":
      case "yyyy-MM-dd'T'HH:mm:ss.SSSSSSxxx":
      case "yyyy-MM-dd'T'HH:mm:ssXXX":
      case "yyyy-MM-dd'T'HH:mm:ss":
        return parseISO(dateValue);
      default:
        return null;
    }
  } else {
    return null;
  }
};
