import { EstimateUnit } from '~/features/billing';
import { codeCash } from '~/services';

import { GetEstimateApiResponse } from '../../types/codecash/external';

export const getCreditEstimation = async ({
  clientId,
  estimateUnit,
}: {
  clientId: number;
  estimateUnit: EstimateUnit;
}): Promise<number> => {
  const response: GetEstimateApiResponse = await codeCash.post(
    `clients/${clientId}/estimates`,
    {
      data: {
        type: 'estimates',
        attributes: {
          calculateBy: estimateUnit.unit,
          details: {
            modelId: estimateUnit.modelId,
            docsInScope: estimateUnit.value,
          },
        },
      },
    },
  );

  return response.data.attributes.credits;
};
