import React, { useEffect, useMemo, useState } from 'react';

import { ButtonGroup } from '~/eds';

interface Props {
  showTooltip?: boolean;
  value: string | undefined;
  onChange: (updatedValue: string) => void;
}

const AndOrToggle = ({
  showTooltip = false,
  value: initialValue = '',
  onChange,
}: Props) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const actions = useMemo(
    () =>
      ['and', 'or'].map((v) => ({
        status: value === v ? ('active' as const) : undefined,
        text: v.toUpperCase(),
        tooltip: showTooltip
          ? 'To prevent returning inaccurate or incomplete results, use either all AND or all OR within a group.'
          : '',
        onClick: () => {
          setValue(v);
          onChange(v);
        },
      })),
    [value, onChange],
  );

  return <ButtonGroup actions={actions} />;
};

export default AndOrToggle;
