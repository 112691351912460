import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import { approved, black2, rejected } from '~/assets/shared-styles/general';
import ApproveIcon from '~/components/Shared/Icons/ApproveIcon';
import RejectIcon from '~/components/Shared/Icons/RejectIcon';
import { replaceAllSpaces } from '~/utils/strings';

import PasswordInputField from '../PasswordInputField';
import styles from './LoginPassword.styles';

function LoginPassword({
  classes,
  passwordLabel = 'New password',
  confirmPasswordLabel = 'Re-enter new password',
  password,
  confirmPassword,
  setPassword,
  setConfirmPassword,
  validationResult,
}) {
  const setPasswordChange = (password) => {
    const trimmedPw = replaceAllSpaces(password);
    setPassword(trimmedPw);
  };

  const setConfirmPasswordChange = (confirmedPassword) => {
    const trimmedConfirmedPassword = replaceAllSpaces(confirmedPassword);
    setConfirmPassword(trimmedConfirmedPassword);
  };

  const iconDisplay = (valid, value) => {
    return (
      <>
        {valid ? (
          <ApproveIcon color={approved} size="16" />
        ) : (
          <RejectIcon color={value ? rejected : black2} size="16" />
        )}
      </>
    );
  };

  return (
    <div>
      <div>
        <div className={classes.inputContainer}>
          <PasswordInputField
            id="password"
            key="passwordfield"
            label={passwordLabel}
            value={password}
            onChange={setPasswordChange}
          />
        </div>

        <div className={classes.passwordValidation}>
          <div className={classes.validationEntity}>
            {iconDisplay(
              validationResult.validations.satisfiesMinimumCharacters,
              password,
            )}
            Minimum of 8 characters
          </div>
          <div className={classes.validationEntity}>
            {iconDisplay(
              validationResult.validations.satisfiesCapitalCharacter,
              password,
            )}
            At least one capital letter (A-Z)
          </div>
          <div className={classes.validationEntity}>
            {iconDisplay(
              validationResult.validations.satisfiesLowercaseCharacter,
              password,
            )}
            At least one lowercase letter (a-z)
          </div>
          <div className={classes.validationEntity}>
            {iconDisplay(
              validationResult.validations.satisfiesNumberCharacter,
              password,
            )}
            At least one number (0-9)
          </div>
          <div className={classes.validationEntity}>
            {iconDisplay(
              validationResult.validations.satisfiesSpecialCharacter,
              password,
            )}
            At least one special character
          </div>
        </div>
      </div>
      <div
        className={
          validationResult.isPasswordValid
            ? classes.formRow
            : classes.formRowDisabled
        }
      >
        <div className={classes.inputContainer}>
          <PasswordInputField
            disabled={!validationResult.isPasswordValid}
            id="confirmedPassword"
            key="confirmpasswordfield"
            label={confirmPasswordLabel}
            value={confirmPassword}
            onChange={setConfirmPasswordChange}
          />
        </div>
        <div className={classes.validationEntity}>
          {iconDisplay(validationResult.isMatching, confirmPassword)}
          Passwords match
        </div>
      </div>
    </div>
  );
}

LoginPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  passPasswords: PropTypes.func.isRequired,
  setValidMatchPassword: PropTypes.func.isRequired,
};

export default injectSheet(styles)(LoginPassword);
