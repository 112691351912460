import React, { useEffect, useMemo } from 'react';

import Filters from '~/components/Shared/Filters';
import { Button } from '~/eds';
import { Filter } from '~/evifields';
import { api } from '~/redux';
import { PilotId } from '~/types';

import { useFieldsWithLazyLoad } from './useFieldsWithLazyLoad';
import {
  attachFilterType,
  createRestrictedListFilters,
  createWhiteListFilters,
  getDefaultFilters,
} from './utils';

interface Props {
  enableFilterViews?: boolean;
  disableDefaultLabel?: boolean;
  filters: Filter[];
  onFilterChange: (filters: Filter[]) => void;
  crossFilters?: Filter[];
  defaultFilterIds?: Array<PilotId | string>;
  // TODO: isLoading and filters count for dashboard v2. Once we get to more refactoring will look into removing these dependencies.
  isLoading?: boolean;
  filterCount?: number;
  /** Placeholder message when no filters are applied */
  placeholder?: string;
  pinnedFiltersIds?: Array<PilotId | string>;
  /** Sets the filter in read-only mode (no editing) */
  readOnly?: boolean;
  whitelistFieldIds?: Array<PilotId | string>;
  restrictFilterIds?: Array<PilotId | string>;
  onClearFilters?: () => void;
  onCrossFiltersChange?: (crossFilters: Filter[]) => void;
  // * This will be remove once we work on refactoring QueryBuilder portion.
  onQueryButtonClick?: () => void;
  onUpdatePinnedFilters?: (
    updatedPinnedFilters: Array<PilotId | string>,
  ) => void;
  onClickPinnedFilter?: (fieldId: PilotId | string) => void;
}

export const SearchFilters = ({
  enableFilterViews = false,
  disableDefaultLabel = false,
  crossFilters,
  defaultFilterIds: defaultFilters,
  filters: selectedFilters,
  filterCount,
  isLoading: initialLoading,
  placeholder,
  pinnedFiltersIds,
  readOnly,
  whitelistFieldIds,
  restrictFilterIds,
  onClearFilters,
  onCrossFiltersChange,
  onFilterChange,
  onQueryButtonClick,
  onUpdatePinnedFilters,
  onClickPinnedFilter,
}: Props) => {
  const {
    data: searchFilters,
    isLoading: isLoadingFilters,
  } = api.endpoints.getFilters.useQuery(undefined);
  const {
    data: sections,
    isLoading: isLoadingSections,
  } = api.endpoints.getFilterSections.useQuery(undefined);

  // only enable defaultFilters if it is provided and selectedFilters is empty
  const enableDefaultFilters =
    selectedFilters.length === 0 && defaultFilters?.length;

  useEffect(() => {
    if (searchFilters) {
      if (enableDefaultFilters) {
        const parsedDefaultFilters = getDefaultFilters(
          defaultFilters,
          searchFilters,
        );
        handleFilterChange(parsedDefaultFilters);
      }
    }
  }, [enableDefaultFilters, searchFilters]);

  const restrictedSearchFilters = useMemo(
    () =>
      restrictFilterIds && searchFilters
        ? createRestrictedListFilters(searchFilters, restrictFilterIds)
        : searchFilters ?? {},
    [searchFilters, restrictFilterIds],
  );

  const filters = useMemo(
    () =>
      whitelistFieldIds && restrictedSearchFilters
        ? createWhiteListFilters(restrictedSearchFilters, whitelistFieldIds)
        : restrictedSearchFilters ?? {},
    [restrictedSearchFilters, whitelistFieldIds],
  );

  const groupsOrder = useMemo(
    () => sections?.fieldGroups.map((group) => group.id),
    [sections?.fieldGroups],
  );

  const fieldsWithLazyLoad = useFieldsWithLazyLoad({
    filters: Object.values(filters),
    fieldValues: {},
  });

  const handleFilterChange = (updatedFilters: Filter[]) => {
    const filtersWithTypes = attachFilterType(updatedFilters, searchFilters);
    onFilterChange(filtersWithTypes);
  };

  const isLoading = initialLoading || isLoadingFilters || isLoadingSections;
  return (
    <Filters
      disableDefaultLabel={disableDefaultLabel}
      enableFilterViews={enableFilterViews}
      crossFilters={crossFilters}
      filters={selectedFilters}
      filterCount={filterCount ?? defaultFilters?.length}
      groupsOrder={groupsOrder || []}
      isLoading={isLoading}
      placeholder={placeholder}
      pinnedFiltersIds={pinnedFiltersIds}
      readOnly={readOnly}
      searchFilters={fieldsWithLazyLoad}
      onChange={handleFilterChange}
      onChangeCrossFilters={onCrossFiltersChange}
      onClearFilters={onClearFilters}
      onUpdatePinnedFilters={onUpdatePinnedFilters}
      onClickPinnedFilter={onClickPinnedFilter}
      queryBuilder={
        onQueryButtonClick && (
          <Button
            disabled={!!crossFilters?.length}
            isLoading={isLoading}
            text="Query Builder"
            variant="action"
            onClick={onQueryButtonClick}
          />
        )
      }
    />
  );
};
