import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getFieldsBySectionId, getSectionById } from '~/reducers/intakeForm';
import { Box, FlexLayout, Icon, Text } from '~/ui';
import {
  getAnsweredFieldCount,
  getEnabledFieldCount,
} from '~/utils/intakeForm';

import WorkflowFormQuestion from './WorkflowFormQuestion';
import WorkflowFormSectionHeader from './WorkflowFormSectionHeader';

function WorkflowFormSection({ disabled, fields, section }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const enabledFieldCount = getEnabledFieldCount(fields);
  if (!enabledFieldCount) return null;

  const answeredFieldCount = getAnsweredFieldCount(fields);

  const headerContent = !disabled && (
    <FlexLayout alignItems="center" space={2} flexShrink={0}>
      {answeredFieldCount < enabledFieldCount ? (
        <Icon icon="donut" color="yellow-500" size="xs" />
      ) : (
        <Icon icon="checkmark" color="green-500" size="xs" />
      )}
      <Text color="gray-600" variant="xs-dense">
        {answeredFieldCount} answered ({enabledFieldCount} total)
      </Text>
    </FlexLayout>
  );

  const { id, name, description, questions } = section;

  return (
    <Box
      id={id}
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: 'm',
      }}
    >
      <WorkflowFormSectionHeader
        headerContent={headerContent}
        isCollapsed={isCollapsed}
        onToggleCollapse={() => setIsCollapsed(!isCollapsed)}
        title={name}
      />
      {!isCollapsed && (
        <FlexLayout flexDirection="column" p={4} pb={8} space={8}>
          {description && (
            <Text color="gray-600" variant="xs-dense">
              {description}
            </Text>
          )}
          {questions.map((questionId) => (
            <WorkflowFormQuestion
              key={questionId}
              disabled={disabled}
              questionId={questionId}
            />
          ))}
        </FlexLayout>
      )}
    </Box>
  );
}

WorkflowFormSection.propTypes = {
  disabled: PropTypes.bool,
  sectionId: PropTypes.string,
};

const mapStateToProps = ({ intakeForm }, { sectionId }) => ({
  section: getSectionById(intakeForm, sectionId),
  fields: getFieldsBySectionId(intakeForm, sectionId),
});

export default connect(mapStateToProps)(WorkflowFormSection);
