import PropTypes from 'prop-types';
import React from 'react';

const ApproveIcon = (props) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill={props.color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 14.6667C11.6821 14.6667 14.6668 11.6819 14.6668 8.00004C14.6668 4.31814 11.6821 1.33337 8.00016 1.33337C4.31826 1.33337 1.3335 4.31814 1.3335 8.00004C1.3335 11.6819 4.31826 14.6667 8.00016 14.6667ZM11.0115 5.72603C10.9917 5.80312 10.957 5.87557 10.9092 5.93924L7.27288 10.7875C7.22077 10.8569 7.15436 10.9144 7.07812 10.9559C7.00188 10.9975 6.91761 11.0222 6.83101 11.0283C6.7444 11.0345 6.65749 11.0219 6.57615 10.9916C6.49481 10.9612 6.42094 10.9137 6.35954 10.8523L4.54136 9.03424C4.43096 8.91994 4.36988 8.76686 4.37126 8.60796C4.37264 8.44906 4.43638 8.29706 4.54875 8.18469C4.66111 8.07233 4.81312 8.0086 4.97203 8.00722C5.13094 8.00583 5.28403 8.06692 5.39833 8.17731L6.72257 9.50149L9.93954 5.212C9.9873 5.14833 10.0471 5.09469 10.1156 5.05415C10.1841 5.0136 10.2599 4.98694 10.3387 4.97568C10.4175 4.96443 10.4977 4.9688 10.5748 4.98855C10.6519 5.0083 10.7244 5.04305 10.788 5.0908C10.8517 5.13855 10.9053 5.19837 10.9459 5.26685C10.9864 5.33533 11.0131 5.41113 11.0244 5.48992C11.0356 5.5687 11.0312 5.64893 11.0115 5.72603Z"
        fill="#009F7D"
      />
    </svg>
  );
};

ApproveIcon.defaultProps = {
  size: '16',
  color: '#000',
};

ApproveIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default ApproveIcon;
