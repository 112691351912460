import { diffWords, diffWordsWithSpace } from 'diff';

export interface DiffData {
  added?: boolean;
  removed?: boolean;
  value: string;
  count: number;
}

/**
 * Diffs two text by word algorithm (configurable in the future)
 */
export const diffText = (text: string, newText: string): DiffData[] =>
  diffWords(text, newText);
export const diffTextWithSpaces = (text: string, newText: string): DiffData[] =>
  diffWordsWithSpace(text, newText);
