import { uniq } from 'lodash';
import { useState } from 'react';

import { types as edsTypes, Select } from '~/eds';
import { Nullable } from '~/types';

interface Props {
  value: Nullable<edsTypes.Option<string>>;
  disabled?: boolean;
  onChange: (value: Nullable<edsTypes.Option<string>>) => void;
}

export const NotApplicableOptionSelect = ({
  value: initialValue,
  disabled = false,
  onChange,
}: Props) => {
  const initialOptions = uniq([
    'Silent',
    'Not Applicable',
    'Not Found',
    'Requires Human Review',
    ...(initialValue ? [initialValue.label] : []),
  ]);
  const [options, setOptions] = useState(initialOptions);
  const [selectedOption, setSelectedOption] = useState<Nullable<string>>(
    initialValue?.value ?? null,
  );
  return (
    <Select<string, false>
      disabled={disabled}
      isMulti={false}
      name="not-applicable-dropdown-option-select"
      options={options.map((option) => ({ value: option, label: option }))}
      value={selectedOption}
      onChange={(newOption) => {
        setSelectedOption(newOption);
        onChange(
          newOption
            ? {
                label: newOption,
                value: newOption,
              }
            : null,
        );
      }}
      onAddOption={(newOption) => setOptions([...options, newOption.label])}
    />
  );
};
