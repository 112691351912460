import { alphas } from '../../../tokens/colors/system/alphas';

// TODO: move filter CSS declarations to theme after updating to uinix-theme@1+
export const link = ({
  isActive = false,
  isDarkTheme = false,
  isNav = false,
}: {
  isActive?: boolean;
  isDarkTheme?: boolean;
  isNav?: boolean;
}) => {
  if (isNav) {
    return {
      alignItems: 'center',
      backgroundColor: isActive ? 'inverse.background.secondary' : undefined,
      display: 'flex',
      height: 'header.height.l',
      ':hover': {
        backgroundImage: isActive
          ? undefined
          : `linear-gradient(${alphas['lighten-20']} 0 0)`,
      },
      ':hover:active': {
        backgroundImage: `linear-gradient(${
          isActive ? alphas['lighten-10'] : alphas['lighten-30']
        } 0 0)`,
      },
    };
  }

  return {
    color: isDarkTheme ? 'inverse.text.link' : 'action.link',
    ':active': {
      filter: 'brightness(0.8)',
    },
    ':focus': {
      filter: 'brightness(0.8)',
    },
    ':hover': {
      filter: 'brightness(0.9)',
    },
  };
};
