import React from 'react';

const FolderEmptyIcon = () => {
  return (
    <svg width="160" height="153" viewBox="0 0 160 153">
      <defs>
        <linearGradient id="a" x1="50.003%" x2="50.003%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="41.17%"
          x2="58.825%"
          y1="130.086%"
          y2="-31.305%"
        >
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="37.577%"
          x2="62.435%"
          y1="136.051%"
          y2="-36.079%"
        >
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="-21.958%"
          x2="122.167%"
          y1="55.307%"
          y2="45.231%"
        >
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="e" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="url(#a)" d="M135.698 151.068h-23.079V57.014h43.095z" />
        <path fill="#E7EAEC" d="M134.549 151.068h-22.621V57.014h42.239z" />
        <path
          fill="url(#b)"
          d="M3.422 12.546l96.15-.072-.028 37.057-96.15.072z"
          transform="rotate(-15.62 51.483 31.038)"
        />
        <path
          fill="#C7CFD1"
          d="M1.321 26.416L92.353.891l9.632 34.553-91.032 25.524z"
        />
        <path
          fill="#F5F5F5"
          d="M29.446 31.595l22.903-6.422.488 1.753-22.902 6.422z"
        />
        <path
          fill="#FFF"
          d="M31.4 38.602l22.902-6.422.489 1.753-22.903 6.421zM30.421 35.098l36.805-10.32.489 1.752L30.91 36.85z"
        />
        <ellipse
          cx="20.865"
          cy="38.723"
          fill="#FFF"
          rx="4.234"
          ry="4.244"
          transform="rotate(-15.62 20.865 38.723)"
        />
        <path
          fill="url(#c)"
          d="M31.106 23.943l96.616-.092-.036 37.56-96.616.092z"
          transform="rotate(-20.42 79.396 42.677)"
        />
        <path
          fill="#D9E0E4"
          d="M28.853 42.557l88.583-33.074 12.481 33.622L41.335 76.18z"
        />
        <path
          fill="#F5F5F5"
          d="M57.311 45.357l22.287-8.322.633 1.706-22.287 8.321z"
        />
        <path
          fill="#FFF"
          d="M59.844 52.175l22.286-8.321.633 1.705-22.286 8.321zM58.956 48.829L94.88 35.414l.636 1.71L59.59 50.54z"
        />
        <ellipse
          cx="49.355"
          cy="53.182"
          fill="#FFF"
          rx="4.235"
          ry="4.244"
          transform="rotate(-20.42 49.355 53.182)"
        />
        <path
          fill="url(#d)"
          d="M90.286 10.994l37.79-.019-.048 96.346-37.79.019z"
          transform="rotate(-79.9 109.157 59.158)"
        />
        <path
          fill="#E7EAEC"
          d="M65.493 32.964l93.058 16.624-6.273 35.321L59.22 68.285z"
        />
        <path
          fill="#F5F5F5"
          d="M88.273 51.662l.318-1.792 23.412 4.183-.318 1.791z"
        />
        <path
          fill="#FFF"
          d="M87.002 58.827l.318-1.791 23.413 4.182-.319 1.792zM87.636 55.244l.318-1.791 37.624 6.721-.318 1.792z"
        />
        <ellipse
          cx="77.775"
          cy="52.557"
          fill="#FFF"
          rx="4.245"
          ry="4.233"
          transform="rotate(-79.9 77.775 52.557)"
        />
        <path
          fill="url(#e)"
          d="M37.818 55.908L22.24 44.969H.672V152.8H135.47V55.908z"
        />
        <path
          fill="#FFF"
          d="M23.061 46.396H1.825v104.672H134.55V57.014H38.4z"
        />
      </g>
    </svg>
  );
};

export default FolderEmptyIcon;
