// eslint-disable-next-line -- ~/flags can import from launchdarkly
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import { User } from '~/types';

interface Props {
  enableReady: boolean;
  user?: User;
}

export const useFlagsReady = ({ enableReady, user }: Props) => {
  const [isReady, setIsReady] = useState(enableReady);
  const ldClient = useLDClient();

  const ready = () => setIsReady(true);

  useEffect(() => {
    // if client is ready and user is well-defined
    if (ldClient && user && Object.keys(user).length) {
      const ldUser = userToLdUser(user);
      ldClient.identify(ldUser, undefined, ready);
    }
  }, [user]);

  return isReady;
};

const userToLdUser = (user: User) => {
  const clientId = user.client.toString();
  return {
    key: clientId,
    name: user.client_name,
    custom: {
      client_id: clientId,
      sub_domain: user.client_config?.sub_domain,
    },
  };
};
