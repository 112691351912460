import PropTypes from 'prop-types';
import React, { useState } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { ticketReviewerUpdateEntity } from '~/actions';
import { patchTicketStatus } from '~/api';
import {
  approved as approvedColor,
  pending as pendingColor,
  rejected as rejectedColor,
} from '~/assets/shared-styles/general';
import EcTextarea from '~/components/Shared/EcTextarea';
import { showToast } from '~/components/Shared/EcToast';
import FormLabel from '~/components/Shared/FormLabel';
import { TicketStatusType } from '~/enums';
import { withCurrentUser } from '~/hocs';
import { getEntityById } from '~/reducers/ticketReviewer';
import * as toastTypes from '~/types/toast.types';
import { enums, FlexLayout, Text, useModal } from '~/ui';
import { capitalize } from '~/utils/strings';

import ApproveReject from '../../shared/ApproveReject';

const { Approved, Pending, Rejected } = TicketStatusType;

const styles = {
  wrapper: {
    position: 'relative',
  },
  dash: {
    background: (props) => {
      switch (props.entity.status) {
        case Approved:
          return approvedColor;
        case Rejected:
          return rejectedColor;
        case Pending:
        default:
          return pendingColor;
      }
    },
    height: '2px',
    left: '-48px', // TODO: this is definitely hardcoded
    position: 'absolute',
    width: '24px',
  },
};

function ReviewEntityApproveReject({
  // injected
  classes,
  currentUser,
  // connected
  ticketId,
  entity,
  ticketReviewerUpdateEntity,
}) {
  const { id: entityId, approvals, entityType, name } = entity;

  const [comment, setComment] = useState('');
  const [status, setStatus] = useState();

  const isRejecting = status === Rejected;
  const disabled = !approvals.some(
    (approval) => approval.userId === currentUser.id,
  );

  const rejectApproveText = isRejecting ? 'reject' : 'complete';

  function onHide() {
    setComment('');
  }

  function handleUpdateApprovalStatus(updatedApprovalStatus) {
    setStatus(updatedApprovalStatus);
    showModal();
  }

  const [modal, showModal] = useModal({
    actionButton: {
      disabled:
        isRejecting &&
        (!comment || comment.length > enums.CHARACTER_INPUT_LIMIT_LONG),
      errorHandler: () =>
        showToast(
          toastTypes.ERROR,
          'An error occurred when submitting approval. Please try again.',
        ),
      text: 'Confirm',
      promise: async () => {
        const patched = await patchTicketStatus({
          ticketId,
          entityId,
          entityType,
          comment,
          status,
        });
        ticketReviewerUpdateEntity(patched);
      },
    },
    content: (
      <FlexLayout flexDirection="column" space={8}>
        <FlexLayout flexDirection="column" space={2}>
          <Text variant="xs-dense">
            Are you sure you want to {rejectApproveText} "{name}" issue?
          </Text>
        </FlexLayout>
        {isRejecting && (
          <FormLabel
            label="Note"
            description="Leave a comment outlining the reason for your rejection."
            input={
              <EcTextarea
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                placeholder={'Add a comment\u2026'}
                rows="5"
                height="auto"
                autoFocus
              />
            }
          />
        )}
      </FlexLayout>
    ),
    title: `${capitalize(rejectApproveText)} issue?`,
    onHide,
  });

  return (
    <div className={classes.wrapper}>
      <FlexLayout alignItems="center" justifyContent="space-between">
        <div className={classes.dash} />
        <ApproveReject
          disabled={disabled}
          status={entity.status}
          onUpdateStatus={handleUpdateApprovalStatus}
        />
      </FlexLayout>
      {modal}
    </div>
  );
}

ReviewEntityApproveReject.propTypes = {
  entityId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ ticket, ticketReviewer }, { entityId }) => ({
  entity: getEntityById(ticketReviewer, entityId),
  ticketId: ticket.id,
});

export default connect(mapStateToProps, { ticketReviewerUpdateEntity })(
  injectSheet(styles)(withCurrentUser(ReviewEntityApproveReject)),
);
