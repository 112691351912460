import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { EM_DASH } from '~/constants/unicode';
import { DataFieldType } from '~/enums';

import { black5 } from '../../../assets/shared-styles/general';
import { booleanOptions } from '../../DocumentsViewPage/DocumentOverviewPanel/DocumentOverviewPanel.utils';
import EcDateInput from '../EcDateInput';
import EcInput from '../EcInput';
import EcMultipleSelectValue from '../EcMultipleSelectValue';
import EcMultiSelect from '../EcMultiSelect';
import EcSelect from '../EcSelect';
import FieldValuesMultiSelect from '../FieldValuesMultiSelect';
import FieldValuesSingleSelect from '../FieldValuesSingleSelect';
import SubArrowIcon from '../Icons/SubArrowIcon';
import styles from './EcDataField.styles';

class EcDataField extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.field.type === DataFieldType.DATE) {
      return (
        this.props.fieldValue !== nextProps.fieldValue ||
        this.props.fieldFocused !== nextProps.fieldFocused
      );
    }

    return true;
  }

  renderBooleanField() {
    const { classes, field, fieldValue, onFieldChange } = this.props;

    let defaultValue = booleanOptions[0];

    if (fieldValue === null || fieldValue === undefined) {
      defaultValue = null;
    } else if (!fieldValue.value) {
      defaultValue = booleanOptions[1];
    }

    return (
      <div className={classes.fieldContainer}>
        <EcSelect
          defaultValue={defaultValue}
          options={booleanOptions}
          onChange={(newValue) => {
            const newState = {};
            newState[field.id] = newValue
              ? { display_value: newValue.label, value: newValue.value }
              : newValue;
            onFieldChange(newState);
          }}
          isDisabled={
            !!field.hasOwnProperty('is_editable') && !field.is_editable
          }
          isClearable={field.is_nullable}
          isSearchable={false}
        />
      </div>
    );
  }

  renderNumberField() {
    const { classes, field, onFieldChange, fieldValue } = this.props;

    return (
      <div className={classes.fieldContainer}>
        <EcInput
          input={{
            value: fieldValue ? fieldValue.value : '',
            onChange: (event) => {
              const newState = {};
              newState[field.id] =
                event.target.value === ''
                  ? null
                  : {
                      display_value: Number(event.target.value),
                      value: Number(event.target.value),
                    };
              onFieldChange(newState);
            },
          }}
          placeholder="enter amount"
          type="number"
          width="100%"
          isDisabled={
            !!field.hasOwnProperty('is_editable') && !field.is_editable
          }
        />
      </div>
    );
  }

  renderDateField() {
    const { classes, field, onFieldChange, fieldValue } = this.props;

    return (
      <div className={classes.fieldContainer}>
        <EcDateInput
          inputDate={fieldValue ? fieldValue.display_value : ''}
          onDateInputChange={(newDateObject) => {
            const newState = { [field.id]: newDateObject };
            onFieldChange(newState);
          }}
          error={fieldValue ? fieldValue.error : false}
          placeholder={`Enter ${field.name}`}
          isDisabled={
            !!field.hasOwnProperty('is_editable') && !field.is_editable
          }
        />
      </div>
    );
  }

  renderStringField() {
    const {
      classes,
      field,
      onFieldChange,
      fieldValue,
      fieldPlaceholder,
    } = this.props;

    return (
      <div className={classes.fieldContainer}>
        <EcInput
          input={{
            value: fieldValue ? fieldValue.value : '',
            onChange: (event) => {
              const newState = {};
              newState[field.id] =
                event.target.value === ''
                  ? null
                  : {
                      display_value: event.target.value,
                      value: event.target.value,
                    };
              onFieldChange(newState);
            },
          }}
          placeholder={fieldPlaceholder || `Enter ${field.name}`}
          width="100%"
          isDisabled={
            !!field.hasOwnProperty('is_editable') && !field.is_editable
          }
        />
      </div>
    );
  }

  renderTextAreaField() {
    const {
      classes,
      field,
      onFieldChange,
      fieldValue,
      fieldPlaceholder,
    } = this.props;

    return (
      <div className={classes.fieldContainer}>
        <textarea
          value={fieldValue ? fieldValue.value : ''}
          rows="7"
          className={classes.fieldTextArea}
          placeholder={fieldPlaceholder || `Enter ${field.name}`}
          onChange={(event) => {
            const newState = {};
            newState[field.id] =
              event.target.value === ''
                ? null
                : {
                    display_value: event.target.value,
                    value: event.target.value,
                  };
            onFieldChange(newState);
          }}
        />
      </div>
    );
  }

  renderSingleArrayField() {
    const {
      classes,
      field,
      onFieldChange,
      fieldValue,
      fieldPlaceholder,
    } = this.props;
    let presentValue = null;

    if (fieldValue) {
      presentValue = {
        label: fieldValue.display_value,
        value: fieldValue.value,
      };
    }

    const options = field.values.map((val) => ({
      label: val.display_value,
      value: val.value,
    }));

    return (
      <div className={classes.fieldContainer}>
        <FieldValuesSingleSelect
          defaultValue={presentValue}
          options={options}
          onChange={(newValue) => {
            const newState = {};
            newState[field.id] = newValue
              ? { display_value: newValue.label, value: newValue.value }
              : null;
            onFieldChange(newState);
          }}
          isDisabled={
            !!field.hasOwnProperty('is_editable') && !field.is_editable
          }
          isClearable={field.is_nullable}
          isCreatable={field.allow_new_options}
          isSearchable={field.is_searchable}
          placeholder={fieldPlaceholder}
          fieldId={field.id}
          fieldValues={field.values}
        />
      </div>
    );
  }

  renderMultipleValues(field) {
    const { classes, fieldValue, onFieldChange } = this.props;
    let presentValue = [];

    if (fieldValue && fieldValue.length) {
      presentValue = fieldValue.map((val) => ({
        label: val.display_value,
        value: val.value,
      }));
    }

    return (
      <div className={classes.multipleValuesContainer}>
        <SubArrowIcon color={black5} />
        {presentValue.length ? (
          presentValue.map((value, index) => (
            <EcMultipleSelectValue
              label={value.label}
              key={index}
              marginBottom="8px"
              closeHandler={() => {
                const newState = {};
                const newMultiState = [...fieldValue];
                newState[field.id] = newMultiState.filter(
                  (multiValue) => multiValue.value !== value.value,
                );
                onFieldChange(newState);
              }}
              isDisabled={
                !!field.hasOwnProperty('is_editable') && !field.is_editable
              }
            />
          ))
        ) : (
          <span className={classes.multipleValuesEmpty}>
            No values selected...
          </span>
        )}
      </div>
    );
  }

  renderMultipleArrayField() {
    const {
      classes,
      field,
      fieldValue,
      onFieldChange,
      fieldPlaceholder,
      getOptionValue,
    } = this.props;

    const presentValue =
      fieldValue && fieldValue.length
        ? fieldValue.map((val) => ({
            label: val.display_value,
            value: val.value,
          }))
        : [];
    const options = field.values
      ? field.values.map((val) => ({
          label: val.display_value,
          value: val.value,
        }))
      : [];

    return (
      <div className={classes.fieldContainerMultiple}>
        <FieldValuesMultiSelect
          value={presentValue}
          options={options}
          onChange={(newItems) => {
            const newState = {};
            newState[field.id] = newItems.map((item) => ({
              display_value: item.label,
              value: item.value,
            }));
            onFieldChange(newState);
          }}
          width="100%"
          placeholder={`Search for ${fieldPlaceholder || field.name}...`}
          noOptionsMessage={`No ${fieldPlaceholder || field.name} found`}
          isDisabled={
            !!field.hasOwnProperty('is_editable') && !field.is_editable
          }
          isCreatable={field.allow_new_options}
          getOptionValue={getOptionValue}
          openMenuOnClick={true}
          fieldId={field.id}
          fieldValues={field.values}
        />
        {this.renderMultipleValues(field)}
      </div>
    );
  }

  renderAttachmentField() {
    const {
      classes,
      field,
      fieldValue,
      onFieldChange,
      fieldPlaceholder,
      getOptionValue,
    } = this.props;

    const presentValue =
      fieldValue && fieldValue.length
        ? fieldValue.map((val) => ({
            label: val.display_value,
            value: val.value,
          }))
        : [];
    const options = field.values
      ? field.values.map((val) => ({
          label: val.display_value,
          value: val.value,
        }))
      : [];

    return (
      <div className={classes.fieldContainerMultiple}>
        <EcMultiSelect
          value={presentValue}
          options={options}
          onChange={(newItems) => {
            const newState = {};
            newState[field.id] = newItems.map((item) => ({
              display_value: item.label,
              value: item.value,
            }));
            onFieldChange(newState);
          }}
          width="100%"
          placeholder={`Search for ${fieldPlaceholder || field.name}...`}
          noOptionsMessage={`No ${fieldPlaceholder || field.name} found`}
          isDisabled={
            !!field.hasOwnProperty('is_editable') && !field.is_editable
          }
          isCreatable={field.allow_new_options}
          getOptionValue={getOptionValue}
          openMenuOnClick={true}
        />
        {this.renderMultipleValues(field)}
      </div>
    );
  }

  renderFieldValue() {
    const { field } = this.props;
    let returnValue;

    switch (field.type) {
      case DataFieldType.BOOLEAN:
        returnValue = this.renderBooleanField();
        break;
      case DataFieldType.DATE:
        returnValue = this.renderDateField();
        break;
      case DataFieldType.NUMBER:
        returnValue = this.renderNumberField();
        break;
      case DataFieldType.STRING:
        returnValue = this.renderStringField();
        break;
      case DataFieldType.TEXT_AREA:
        returnValue = this.renderTextAreaField();
        break;
      case DataFieldType.ARRAY_SINGLE:
        returnValue = this.renderSingleArrayField();
        break;
      case DataFieldType.ARRAY_MULTIPLE:
        returnValue = this.renderMultipleArrayField();
        break;
      case DataFieldType.ATTACHMENT:
        returnValue = this.renderAttachmentField();
        break;
      default:
        returnValue = EM_DASH;
        break;
    }

    return returnValue;
  }

  render() {
    const { classes, field } = this.props;

    return (
      <div className={classes.dataField} key={`field-${field.id}`}>
        <div className={classes.fieldTitle}>{field.name}</div>
        <div className={classes.fieldBody}>
          <div className={classes.fieldValue}>{this.renderFieldValue()}</div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

EcDataField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldValue: PropTypes.any,
  onFieldChange: PropTypes.func.isRequired,
  fieldPlaceholder: PropTypes.string,
  anchorDirection: PropTypes.string,
  customStyles: PropTypes.object,
};

export default injectSheet(styles)(EcDataField);
