import React, { useMemo } from 'react';

import { EVISORT, EVISORT_AI } from '~/constants/users';
import { Box, User as EdsUser, Layout, LoadingShimmer, types } from '~/eds';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { PilotId } from '~/types';

type UserPresets = 'evisort-ai' | 'evisort';
type UserProps<M extends types.UserMode> = {
  /** if id is provided, the user information will be fetched. */
  id?: types.Nullable<PilotId>;
  user?: types.User;
  mode: types.UserMode;
  options?: types.UserOptions<M>;
  /** render static users.  */
  preset?: UserPresets;
  placeholder?: string;
};

const presets: Record<UserPresets, types.User> = {
  'evisort-ai': EVISORT_AI,
  evisort: EVISORT,
};

export function User<M extends types.UserMode>({
  id,
  mode,
  options: userOptions,
  user: userToRender,
  preset,
  placeholder,
}: UserProps<M>) {
  const currentUser = useCurrentUser();
  const { isFetching, data: resolvedUser } = api.endpoints.resolveUser.useQuery(
    {
      id: id ?? 0,
      params: { clientId: currentUser.client, includeDeleted: true },
    },
    { skip: !currentUser || !id || !!preset || !!userToRender },
  );

  const user = getUser();
  const options = useMemo(() => {
    return {
      isCurrentUser: currentUser && currentUser.id === user?.id,
      ...userOptions,
    };
  }, [currentUser, user, userOptions]);

  if (!resolvedUser && isFetching) {
    switch (mode) {
      case 'avatar':
        return (
          <Box display="inline-flex" borderRadius="round" w={24} h={24} mr={1}>
            <LoadingShimmer />
          </Box>
        );
      case 'avatar-name':
      case 'info':
        return (
          <Box display="inline-flex" w={100} h={24} align="stretch">
            <Box borderRadius="round" w={24} h={24} mr={1}>
              <LoadingShimmer />
            </Box>
            <Layout flex="1">
              <LoadingShimmer />
            </Layout>
          </Box>
        );
      case 'name':
      default:
        return (
          <Box display="inline-flex" w={80} h={16}>
            <LoadingShimmer />
          </Box>
        );
    }
  }

  function getUser() {
    if (preset) {
      return presets[preset];
    } else if (userToRender) {
      return userToRender;
    }
    return resolvedUser;
  }

  return (
    <Box display="inline-block">
      <EdsUser
        mode={mode}
        user={user}
        options={options}
        placeholder={placeholder}
      />
    </Box>
  );
}
