import React from 'react';

import { withGetFieldValuesOnFocus } from '~/hocs';

import EcMultiSelect from '../EcMultiSelect';

const LazyEcMultiSelect = withGetFieldValuesOnFocus(EcMultiSelect);

const FieldValuesMultiSelect = (props) => {
  const { fieldId, searchSuggestions } = props;

  return (
    <LazyEcMultiSelect
      {...props}
      fieldId={fieldId}
      searchSuggestions={searchSuggestions}
    />
  );
};

export default FieldValuesMultiSelect;
