import { noop } from 'lodash';
import React from 'react';

import { MenuActions, Option, Theme } from '../../types';
import { typedMemo } from '../../utils';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { Menu } from '../Menu';

interface Props<V = unknown> {
  /** Array of actions */
  actions: MenuActions<V>;
  /** Menu name to uniquely identify the menu */
  name: string;
  /** Menu direction relative to the trigger */
  direction?: 'top' | 'bottom' | 'right' | 'left';
  /** Disables the action menu trigger */
  disabled?: boolean;
  /** Enables menu border */
  enableMenuBorder?: boolean;
  /** If true, menu is rendered as a direct child of document.body. */
  enablePortal?: boolean;
  /** Specific icons for triggering the dropdown */
  icon?: 'chevron-down' | 'gear' | 'filter' | 'help' | 'more';
  /** Unique id for menu trigger */
  id?: string;
  /** When enabled, embeds the menu in its parent container without rendering the trigger */
  isEmbedded?: boolean;
  /** Menu icon trigger size */
  size?: 's' | 'm';
  /** Display text for the menu trigger */
  text?: string;
  /** Menu layout trigger */
  trigger?: React.ReactNode;
  /** Menu trigger width */
  triggerWidth?: '100%';
  /** Light / Dark theme */
  theme?: Theme;
  /** Trigger tooltip */
  tooltip?: string;
  /** Handler when action is clicked.  If not provided, one should specify it in each actions[i].onClick */
  onActionClick?: (value: V) => void;
  /** PRIVATE APIs */
  PRIVATE_variant?: 'primary' | 'secondary' | 'tertiary';
}

export const ActionsMenu = typedMemo(
  <V extends unknown>({
    actions,
    enableMenuBorder = true,
    enablePortal,
    direction,
    disabled,
    icon = 'more',
    id,
    isEmbedded,
    name,
    size = 'm',
    text,
    theme,
    trigger: customTrigger,
    triggerWidth,
    tooltip = 'More',
    PRIVATE_variant = 'tertiary',
    onActionClick = noop,
  }: Props<V>) => {
    const trigger = text ? (
      <Button
        disabled={disabled}
        shouldTruncate
        icon={icon}
        id={id}
        text={text}
        theme={theme}
        tooltip={tooltip}
        variant={PRIVATE_variant}
        label={name}
      />
    ) : (
      <IconButton
        disabled={disabled}
        icon={icon}
        id={id}
        size={size}
        theme={theme}
        tooltip={tooltip}
        PRIVATE_variant={PRIVATE_variant}
      />
    );

    return (
      <Menu
        disabled={disabled}
        direction={direction}
        enableMenuBorder={enableMenuBorder}
        enablePortal={isEmbedded ? undefined : enablePortal}
        name={name}
        options={actions}
        trigger={isEmbedded ? null : customTrigger ?? trigger}
        triggerWidth={triggerWidth}
        triggerHeight={customTrigger ? '100%' : undefined}
        onSelectOption={(updatedOption) =>
          onActionClick((updatedOption as Option<V>).value)
        }
      />
    );
  },
);
