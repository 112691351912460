import {
  black1,
  black2,
  black3,
  bold,
  evisortBlue,
} from '../../../assets/shared-styles/general';

const styles = {
  departmentMembersTable: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    color: black1,
    textAlign: 'left',
  },
  noDataMessage: {
    padding: '15px',
    fontSize: '15px',
    color: black3,
  },
  tableRow: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '&:last-child': {
      border: 0,
    },
  },
  tableHeaderWrapper: {
    padding: '16px 0',
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  tableHeaderText: {
    width: '100%',
    color: black2,
    fontSize: '15px',
    fontWeight: bold,
  },
  headerButtonWrapper: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'right',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
    '& svg': {
      marginRight: '4px',
    },
  },
  headerButton: {
    display: 'inline-flex',
    alignItems: 'center',
    color: evisortBlue,
    fontWeight: bold,
    cursor: 'pointer',
  },
  noHeaderAction: {
    padding: '8px',
  },
};

export default styles;
