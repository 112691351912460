export default `
<svg
  aria-hidden="true"
  aria-label="checkbox-checked"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M7 5C5.875 5 5 5.90625 5 7V17C5 18.125 5.875 19 7 19H17C18.0938 19 19 18.125 19 17V7C19 5.90625 18.0938 5 17 5H7ZM15.5312 10.5312L11.5312 14.5312C11.2188 14.8438 10.75 14.8438 10.4688 14.5312L8.46875 12.5312C8.15625 12.25 8.15625 11.7812 8.46875 11.5C8.75 11.1875 9.21875 11.1875 9.53125 11.5L11 12.9688L14.4688 9.46875C14.75 9.1875 15.2188 9.1875 15.5 9.46875C15.8125 9.78125 15.8125 10.25 15.5 10.5312H15.5312Z"
    fill="currentcolor"
  />
</svg>
`;
