import { useEffect } from 'react';

interface Props {
  file: File;
  onLoad: (result: string) => void;
}

/**
 * Loads a File's string content
 */
export const useLoadFileContent = ({ file, onLoad }: Props) => {
  useEffect(() => {
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = function (event) {
      if (event.target) {
        const content = event.target.result;
        if (typeof content === 'string') {
          onLoad(content);
        }
      }
    };
  }, [file]);
};
