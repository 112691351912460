import _ from 'lodash';

import { Nullable } from '~/types';
import { removeBreaklinesFromText } from '~/utils/strings';

import { TOKEN_CLASS } from './constants';

const coerceHtmlTokenId = (node: Nullable<Node>): number => {
  if (node instanceof Element) {
    const htmlTokenId = node.getAttribute('id');
    return Number(htmlTokenId);
  }

  return -1; // invalid token id
};

// Evisort's highlighting logic is based on `HTMLSpanElement` with numeric DOM `id`s.
const getClosestSpanNode = (node: Nullable<Node>): Nullable<Node> => {
  // If the node is a TEXT_NODE, start from its parent node if is not empty, otherwise find the previous sibling node
  if (node?.nodeType === Node.TEXT_NODE) {
    if (/^\s*$/.test(node.textContent || '')) {
      node = node.previousSibling;
    } else {
      node = node.parentNode;
    }
  }

  // Traverse up the DOM tree to find the closest span element
  while (node) {
    if (
      node.nodeType === Node.ELEMENT_NODE &&
      (node as HTMLElement).tagName === 'SPAN'
    ) {
      return node;
    }
    node = node.parentNode;
  }

  return null;
};

export const getHtmlTokensFromRange = (range: Range) => {
  const { startContainer, endContainer } = range;

  const startNode = getClosestSpanNode(startContainer);
  const endNode = getClosestSpanNode(endContainer);

  let htmlTokens: number[] = [];
  const startId = coerceHtmlTokenId(startNode);
  const endId = coerceHtmlTokenId(endNode);
  // only generate htmlTokens if startId and endId are valid
  if (startId <= endId) {
    htmlTokens = _.range(startId, endId + 1);
  }

  return htmlTokens;
};

export const getTextContentFromHtmlTokens = (htmlTokens: number[]): string => {
  const textContent = htmlTokens.reduce((acc, tokenId) => {
    const tokenElement = getTokenElement(tokenId);
    return acc + (tokenElement?.textContent ?? '');
  }, '');

  return removeBreaklinesFromText(textContent.trim());
};

export const getTokenElement = (
  tokenId?: number | string,
): Nullable<HTMLElement> => {
  return document.querySelector(`.${TOKEN_CLASS}[id='${tokenId}']`);
};
