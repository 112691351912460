// coerces a string input value into a number.  Note that an empty string and null are valid input values and are coerced to an explicit emptyValue
export function coerceInputValueToNumber(inputValue, emptyValue = null) {
  const emptyInputValues = ['', null];
  return emptyInputValues.includes(inputValue)
    ? emptyValue
    : Number(inputValue);
}

// check if value is empty (note that `false` and `0` are not considered empty)
export function isEmptyValue(value) {
  return value === '' || value === null || value === undefined;
}

// check if a relation is "between_dates"
function isBetweenDates(relation) {
  if (!relation) return false;

  const { value } = relation;

  return 'between_dates' === value;
}

// Reset "second_value" if relation is not "between_dates"
export function checkBetweenDatesRelationAndResetValues(fieldProperties) {
  const { relation } = fieldProperties;

  return isBetweenDates(relation)
    ? fieldProperties
    : { ...fieldProperties, second_value: null };
}
