import { types } from '~/eds';
import { SavedSearch } from '~/features/search';
import { pilotV3 } from '~/services';

import { JsonApiGetResponse } from '../../ApiTypes';
import { SavedSearchAttributes, SavedSearchMeta } from './types';
import { mapSavedSearchResponseToSavedSearch } from './utils';

type Response = JsonApiGetResponse<
  'saved-search',
  SavedSearchAttributes,
  void,
  SavedSearchMeta
>;
export const getSavedSearch = async (
  id: types.PilotId,
): Promise<SavedSearch> => {
  const response: Response = await pilotV3.get(`/saved-search/${id}`);

  return mapSavedSearchResponseToSavedSearch(response);
};
