import { uniq } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { ContentContainer, Layout, testHasTextMatch } from '~/eds';
import { EntityType } from '~/enums';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { PilotId, TicketDocumentVersion, Uuid } from '~/types';
import { tokenizeContent } from '~/utils/comments';

import { ActivityLogItem } from './ActivityLogItem';
import { CustomActions } from './types';
import {
  getActivityLogContent,
  getQuote,
  tokenRenderer,
  tokenSearchRenderer,
} from './utils';

interface Props {
  versions: TicketDocumentVersion[];
  ticketId: Uuid;
  searchInput: string;
  customActions?: CustomActions;
}

function ActivityLogComponent({
  customActions,
  searchInput,
  ticketId,
  versions,
}: Props) {
  const {
    data: activities = [],
    isLoading: isLoadingActivities,
  } = api.endpoints.getActivities.useQuery({
    entityId: ticketId,
    entityType: EntityType.Ticket,
  });

  const currentUser = useCurrentUser();

  const [
    resolveUsers,
    { data: usersData = {}, isLoading: isLoadingUsers },
  ] = api.endpoints.resolveUsers.useLazyQuery();

  useEffect(() => {
    if (activities.length) {
      // extract the user ids from the activity log contents so we load them ahead.
      const userIds: number[] = uniq(
        activities
          .map((event) =>
            tokenizeContent(getActivityLogContent(event, versions, ticketId), {
              user: (userId: PilotId) => Number(userId),
            })
              .filter((value) => value.type === 'user')
              .map((value) => value.content),
          )
          .flat(),
      );
      resolveUsers({
        ids: userIds,
        params: { clientId: currentUser.client, includeDeleted: true },
      });
    }
  }, [activities]);

  const activityLogs = useMemo(() => {
    if (isLoadingUsers) {
      return [];
    }

    return activities.map((event) => {
      const componentsRenderer = tokenRenderer(
        usersData,
        customActions,
        versions,
      );

      const content = getActivityLogContent(event, versions, ticketId);
      const quote = getQuote(event);
      const contentTokens = tokenizeContent(content, componentsRenderer);

      const searchTextRenderer = tokenSearchRenderer(usersData);
      const contentSearchText = tokenizeContent(content, searchTextRenderer)
        .map((token) => token.content)
        .join('');

      return {
        id: event.id,
        quote: quote
          ? tokenizeContent(quote, componentsRenderer).map((token, i) => (
              <React.Fragment key={i}>{token.content}</React.Fragment>
            ))
          : undefined,
        content: contentTokens.map((token, i) => (
          <React.Fragment key={i}>{token.content}</React.Fragment>
        )),
        contentSearchText,
        quoteSearchText: quote
          ? tokenizeContent(quote, searchTextRenderer)
              .map((token) => token.content)
              .join('')
          : undefined,
        dateTime: event.modifiedDate,
      };
    });
  }, [activities, usersData]);

  const activityLogsFiltered = useMemo(() => {
    if (!searchInput) return activityLogs;
    return activityLogs.filter(
      (a) =>
        (a.contentSearchText &&
          testHasTextMatch(a.contentSearchText, searchInput)) ||
        (a.quoteSearchText && testHasTextMatch(a.quoteSearchText, searchInput)),
    );
  }, [searchInput, activityLogs, usersData]);

  return (
    <ContentContainer
      loadingContent={{
        isLoading: isLoadingActivities || isLoadingUsers,
        message: 'Activity Log is being loaded.',
      }}
      placeholderContent={
        activityLogsFiltered.length
          ? undefined
          : {
              icon: 'alert',
              message: 'There are no activities to be displayed.',
            }
      }
    >
      <Layout spacing={2} direction="column">
        {activityLogsFiltered.map((log) => (
          <ActivityLogItem
            key={log.id}
            content={log.content}
            dateTime={log.dateTime}
            quote={log.quote}
          />
        ))}
      </Layout>
    </ContentContainer>
  );
}

export const ActivityLog = React.memo(ActivityLogComponent);
