import PT from 'prop-types';
import React from 'react';

import { FlexLayout } from '~/ui';

function Footer({ actionButtons, leftButton }) {
  return (
    <FlexLayout
      alignItems="center"
      flexGrow="1"
      justifyContent="space-between"
      px={20}
      py={5}
      sx={{
        borderTop: 'border',
        minHeight: 'page-footer-height',
      }}
    >
      {leftButton || <div />}
      <FlexLayout space={4}>{actionButtons}</FlexLayout>
    </FlexLayout>
  );
}

Footer.propTypes = {
  /** Array of Button components */
  actionButtons: PT.arrayOf(PT.node.isRequired).isRequired,
  /** Left Button components */
  leftButton: PT.node,
  /** Hides Cancel button */
  disableCancelButton: PT.bool,
};

export default Footer;
