export default `
<svg
  aria-hidden="true"
  aria-label="wrench"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M19.8523 7.68091C19.7898 7.27466 19.4773 6.99341 19.1023 6.86841C18.696 6.77466 18.2898 6.89966 18.0398 7.14966L16.196 8.99341L15.1648 8.83716L15.0085 7.80591L16.8523 5.99341C17.1335 5.71216 17.2273 5.30591 17.1335 4.93091C17.0085 4.55591 16.7273 4.24341 16.321 4.14966C14.6648 3.74341 12.9773 4.21216 11.7585 5.43091C10.571 6.58716 10.1023 8.27466 10.4773 9.89966L4.72729 15.6184C4.25854 16.1184 4.00854 16.7747 4.00854 17.4309C4.00854 18.0872 4.25854 18.7434 4.72729 19.2434C5.16479 19.7122 5.88354 19.9934 6.53979 19.9934C7.19604 19.9934 7.85229 19.7434 8.35229 19.2434L14.071 13.5247C15.696 13.8997 17.3835 13.4309 18.571 12.2434C19.7585 11.0559 20.2585 9.30591 19.8523 7.68091ZM17.5085 11.1809C16.6335 12.0872 15.321 12.3997 14.1335 11.9934L13.696 11.8372L7.32104 18.1809C6.94604 18.5872 6.22729 18.5872 5.85229 18.1809C5.66479 17.9934 5.53979 17.7434 5.53979 17.4309C5.53979 17.1497 5.66479 16.8997 5.85229 16.6809L12.2273 10.3372L12.1023 9.89966C11.6335 8.68091 11.9148 7.36841 12.821 6.49341C13.446 5.83716 14.2898 5.49341 15.1335 5.49341H15.1648L13.4148 7.30591L13.8835 10.1184L16.696 10.6184L18.5085 8.80591C18.5085 9.68091 18.1648 10.5559 17.5085 11.1809ZM6.72729 16.7434C6.44604 16.7434 6.25854 16.9622 6.25854 17.2434C6.25854 17.4934 6.47729 17.7434 6.72729 17.7434C6.97729 17.7434 7.19604 17.4934 7.19604 17.2434C7.22729 16.9934 7.00854 16.7434 6.72729 16.7434Z"
    fill="currentcolor"
  />
</svg>
`;
