import React, { Component } from 'react';
import injectSheet from 'react-jss';

import EcFilterInput from '~/components/Shared/EcFilterInput';
import { ContentContainer } from '~/eds';
import { AlgorithmStatusType } from '~/enums';
import { FlagType, withFlags } from '~/flags';

import EcCard from '../../Shared/EcCard';
import WarningIcon from '../../Shared/Icons/WarningIcon';
import styles from './KeyInformation.styles';
import {
  filterSectionsByName,
  lowConfidenceFieldsIds,
} from './KeyInformation.utils';
import KeyInformationSection from './KeyInformationSection';

class KeyInformation extends Component {
  constructor(props) {
    super(props);

    this.handleOnFilterChange = this.handleOnFilterChange.bind(this);
    this.handleOnShowFlaggedClick = this.handleOnShowFlaggedClick.bind(this);
    this.state = {
      filterValue: '',
      showFlaggedOnly: false,
      showOCR: null,
      childSections: [],
    };
  }

  componentDidMount() {
    this.setState({ sections: this.props.sections });
  }

  static getDerivedStateFromProps(nextProps) {
    return { sections: nextProps.sections, showOCR: nextProps.showOCR };
  }

  handleOnFilterChange(filterValue) {
    this.setState({ filterValue });
    this.expandChildSections();
  }

  handleOnShowFlaggedClick() {
    this.setState({ showFlaggedOnly: !this.state.showFlaggedOnly });
    this.expandChildSections();
  }

  expandChildSections() {
    this.state.childSections.forEach((child) => child.expandSection());
  }

  render() {
    const {
      classes,
      flags,
      OCRStatus,
      userVisibilityLevel,
      onFieldTokenClick,
      ecCardContentStyles,
      isPdfHighlighter,
      queued,
    } = this.props;
    const {
      showOCR,
      sections,
      filterValue,
      showFlaggedOnly,
      childSections,
    } = this.state;
    const flaggedFieldIds = lowConfidenceFieldsIds(sections);
    const sectionsToShow = filterValue
      ? filterSectionsByName(sections, filterValue)
      : sections;
    const fieldResultsCount = sectionsToShow.reduce(
      (prev, curr) => [...prev, ...curr.fields],
      [],
    ).length;

    let buttonText = 'Show Flagged';
    if (showFlaggedOnly) buttonText = 'Show All';

    const enableUnprocessedPDFPreview = flags[FlagType.UnprocessedPDFPreview];

    return (
      <ContentContainer
        loadingContent={{
          isLoading:
            enableUnprocessedPDFPreview &&
            (OCRStatus === AlgorithmStatusType.InProgress || queued),
          message: 'Evisort AI is processing the file...',
        }}
      >
        <EcCard title="Key Information" contentStyles={ecCardContentStyles}>
          <div className={classes.keyInformationSearch}>
            <EcFilterInput
              ariaDescribedby="key-info-fields-found-status"
              value={filterValue}
              onChange={(filterValue) => this.handleOnFilterChange(filterValue)}
              onClearSearch={() => this.handleOnFilterChange('')}
              placeholder="Filter key information..."
            />
          </div>
          <div
            className="screenReaderText"
            aria-live="assertive"
            id="key-info-fields-found-status"
            role="status"
          >
            {!filterValue.length
              ? null
              : fieldResultsCount
              ? `${fieldResultsCount} item(s) found`
              : 'No items found'}
          </div>
          {flaggedFieldIds.length ? (
            <div className={classes.flaggedInfo}>
              <div className={classes.flaggedWarning}>
                <WarningIcon size="20" yellow />
                <span>
                  Our algorithms flagged {flaggedFieldIds.length} fields with
                  low confidence.
                </span>
              </div>
              <div className={classes.flaggedActions}>
                <button onClick={this.handleOnShowFlaggedClick}>
                  {buttonText}
                </button>
              </div>
            </div>
          ) : null}
          <div className={classes.sectionsWrapper}>
            {sectionsToShow.map((section) => (
              <KeyInformationSection
                showOCR={showOCR}
                userVisibilityLevel={userVisibilityLevel}
                section={section}
                key={section.title}
                showFlaggedOnly={showFlaggedOnly}
                onRef={(ref) => childSections.push(ref)}
                onFieldTokenClick={onFieldTokenClick}
                isPdfHighlighter={isPdfHighlighter}
              />
            ))}
          </div>
        </EcCard>
      </ContentContainer>
    );
  }
}

export default injectSheet(styles)(withFlags(KeyInformation));
