import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AutomationClauseType,
  AutomationContractType,
  AutomationDocsApplyTypes,
  AutomationFormErrorType,
  AutomationStageType,
  AutomationStatusType,
  SliceType,
  TrainingStage,
} from '~/enums';
import { Field, FieldId, Filters } from '~/evifields';
import {
  AnyObject,
  DropdownFieldValue,
  GuidedAiSample,
  GuidedAiSampleLabel,
  Nullable,
  PaginationParams,
  TrainingStatusNode,
  Uuid,
} from '~/types';
import { toLowercaseTrimmed } from '~/utils/strings';

interface ClauseState {
  name: string;
  typeFilter: AutomationClauseType[];
  statusFilter: AutomationStatusType[];
  types: AutomationClauseType[];
  status: AutomationStatusType;
  pagination: PaginationParams;
}

interface TrainingProgress {
  active: number;
  stages: { progress: number; description: string }[];
}

interface ModelState {
  id: Nullable<Uuid>;
  version: string;
  allVersions: string[];
  name: string;
  description: string;
  current: Nullable<TrainingStatusNode>;
  stage: TrainingStage;
  progress: Nullable<TrainingProgress>;
  showCreating: boolean;
}

interface SampleState {
  content: string;
  pagination: PaginationParams;
}

interface QuickAiDataForm {
  id: Nullable<string | number>;
  name: string;
  content: string;
  sampleName: string;
  applyTo: AutomationDocsApplyTypes[];
  contractType: AutomationContractType;
  errors: AutomationFormErrorType[];
  doesClauseExist: boolean;
}

interface Filter {
  filters: Filters;
  fieldValues: { [fieldId: string]: DropdownFieldValue[] | string[] };
  clause: string[];
  folderTree: DropdownFieldValue[];
  fields: Record<FieldId, Field>;
}

interface GuidedAiDataForm {
  id: Nullable<Uuid>;
  name: string;
  description: string;
  uploadId: Nullable<Uuid>;
  csvFile: any;
  samples: GuidedAiSample[];
  metadata?: AnyObject;
  errors: Nullable<string | { message: string; template: boolean }>;
  invalids: Nullable<string | { message: string; template: boolean }>;
}

interface State {
  manage: AutomationStageType;
  filter: Filter;
  quickAiForm: QuickAiDataForm;
  guidedAiForm: GuidedAiDataForm;
  clause: ClauseState;
  sample: SampleState;
  model: ModelState;
}

interface SampleReact {
  type: string;
  label: Nullable<GuidedAiSampleLabel>;
  value: boolean;
  count?: number;
}

interface PageSizeParams {
  pageSize: number;
}

export const name = SliceType.Automation;
const CLAUSE_PAGE_SIZE = 10;
const SAMPLE_PAGE_SIZE = 20;

const DEFAULT_APPLY_TO = [
  AutomationDocsApplyTypes.EXISTING,
  AutomationDocsApplyTypes.FUTURE,
];
const DEFAULT_CONTRACT_TYPE = AutomationContractType.AllTypes;

export const initialState: State = {
  manage: AutomationStageType.None,
  filter: {
    filters: [],
    fieldValues: {},
    clause: [],
    folderTree: [],
    fields: {},
  },
  quickAiForm: {
    id: null,
    name: '',
    content: '',
    sampleName: '',
    applyTo: DEFAULT_APPLY_TO,
    contractType: DEFAULT_CONTRACT_TYPE,
    errors: [],
    doesClauseExist: false,
  },
  guidedAiForm: {
    id: null,
    name: '',
    description: '',
    uploadId: null,
    csvFile: null,
    samples: [],
    errors: null,
    invalids: null,
  },
  clause: {
    name: '',
    typeFilter: [AutomationClauseType.QUICK_AI, AutomationClauseType.GUIDED_AI],
    statusFilter: [AutomationStatusType.ACTIVE],
    types: [],
    status: AutomationStatusType.ACTIVE,
    pagination: {
      pageIndex: 1,
      pageSize: CLAUSE_PAGE_SIZE,
    },
  },
  sample: {
    content: '',
    pagination: {
      pageIndex: 1,
      pageSize: SAMPLE_PAGE_SIZE,
    },
  },
  model: {
    id: null,
    version: '',
    allVersions: [],
    name: '',
    description: '',
    current: null,
    stage: TrainingStage.Empty,
    progress: null,
    showCreating: false,
  },
};

/*************
 * Reducers
 *************/

const resetAutomationStatesReducer = () => {
  return initialState;
};

const resetFiltersReducer = (state: State) => {
  state.filter = {
    filters: [],
    fieldValues: {},
    fields: {},
    clause: [],
    folderTree: [],
  };
};

const setFiltersReducer = (state: State, action: PayloadAction<Filters>) => {
  state.filter.filters = action.payload;
};

const setFilterFolderTreeReducer = (
  state: State,
  { payload }: { payload: DropdownFieldValue[] },
) => {
  state.filter.folderTree = payload;
};

const setFilterClauseReducer = (
  state: State,
  { payload }: { payload: string[] },
) => {
  state.filter.clause = payload;
};

const setFilterFieldsReducer = (
  state: State,
  { payload }: { payload: Record<FieldId, Field> },
) => {
  state.filter.fields = payload;
};

const setFieldValuesReducer = (
  state: State,
  {
    payload,
  }: { payload: { fieldId: string; values: DropdownFieldValue[] | string[] } },
) => {
  state.filter.fieldValues[payload.fieldId] = payload.values;
};

const setQuickAiDataFormReducer = (
  state: State,
  action: PayloadAction<QuickAiDataForm>,
) => {
  let errors = action.payload.errors || [];

  if (state.quickAiForm.sampleName !== action.payload.sampleName) {
    errors = errors.filter((e) => e !== AutomationFormErrorType.DupSampleName);
  }

  if (state.quickAiForm.content !== action.payload.content) {
    errors = errors.filter((e) => e !== AutomationFormErrorType.DupContent);
  }

  state.quickAiForm = { ...action.payload, errors };
};

const setGuidedAiDataFormReducer = (
  state: State,
  action: PayloadAction<GuidedAiDataForm>,
) => {
  state.guidedAiForm = { ...action.payload };
};

const setGuidedAiCsvUploadIdReducer = (
  state: State,
  action: PayloadAction<GuidedAiDataForm>,
) => {
  state.guidedAiForm.uploadId = action.payload.uploadId;
  state.guidedAiForm.csvFile = action.payload.csvFile;
  state.guidedAiForm.invalids = action.payload.invalids;
};

const setGuidedAiSampleLabelReducer = (
  state: State,
  action: PayloadAction<{ index: number; reacts: SampleReact[] }>,
) => {
  const { index, reacts } = action.payload;
  const label = toLowercaseTrimmed(reacts.find((r) => r.value)?.label ?? '');

  state.guidedAiForm.samples[index].Label = !!label
    ? (label as GuidedAiSampleLabel)
    : null;
};

const deleteGuidedAiReducer = (state: State) => {
  state.model = {
    id: null,
    version: '',
    allVersions: [],
    name: '',
    description: '',
    current: null,
    stage: TrainingStage.Empty,
    progress: null,
    showCreating: true,
  };
};

const resetQuickAiDataFormReducer = (state: State) => {
  state.quickAiForm = {
    id: null,
    name: '',
    sampleName: '',
    content: '',
    applyTo: DEFAULT_APPLY_TO,
    contractType: DEFAULT_CONTRACT_TYPE,
    errors: [],
    doesClauseExist: false,
  };
};

const resetGuidedAiDataFormReducer = (state: State) => {
  state.guidedAiForm = {
    id: null,
    name: '',
    description: '',
    uploadId: null,
    csvFile: null,
    samples: [],
    errors: null,
    invalids: null,
  };
};

const setClauseReducer = (state: State, action: PayloadAction<ClauseState>) => {
  state.clause = action.payload;
};

const setSampleReducer = (state: State, action: PayloadAction<SampleState>) => {
  state.sample = action.payload;
};

const setGuidedAiReducer = (
  state: State,
  action: PayloadAction<ModelState>,
) => {
  state.model = action.payload;
};

const setClausePaginationReducer = (
  state: State,
  action: PayloadAction<PaginationParams>,
) => {
  const { pageIndex, pageSize } = action.payload;

  if (state.clause.pagination.pageIndex !== pageIndex) {
    state.clause.pagination = { pageIndex, pageSize };
  }
};

const setAutomationSamplePageSizeReducer = (
  state: State,
  action: PayloadAction<PageSizeParams>,
) => {
  const { pageSize } = action.payload;

  if (state.sample.pagination.pageSize !== pageSize) {
    state.sample.pagination.pageSize = pageSize;
  }
};

const setSamplePaginationReducer = (
  state: State,
  action: PayloadAction<PaginationParams>,
) => {
  const { pageIndex, pageSize } = action.payload;

  if (state.sample.pagination.pageIndex !== pageIndex) {
    state.sample.pagination = { pageIndex, pageSize };
  }
};

const setManageAutomationStageReducer = (
  state: State,
  action: PayloadAction<AutomationStageType>,
) => {
  state.manage = action.payload;
};

/*************
 * Slice Definition
 *************/
export default createSlice({
  name,
  initialState,
  reducers: {
    resetAutomationStates: resetAutomationStatesReducer,
    resetAutomationFilters: resetFiltersReducer,
    setAutomationFilterFolderTree: setFilterFolderTreeReducer,
    setAutomationFilterClause: setFilterClauseReducer,
    setAutomationFilterFields: setFilterFieldsReducer,
    setAutomationFilter: setFiltersReducer,
    setAutomationFieldValues: setFieldValuesReducer,
    setAutomationQuickAiDataForm: setQuickAiDataFormReducer,
    resetAutomationQuickAiDataForm: resetQuickAiDataFormReducer,
    setAutomationGuidedAiDataForm: setGuidedAiDataFormReducer,
    setAutomationGuidedAiCsvUploadId: setGuidedAiCsvUploadIdReducer,
    setAutomationGuidedAiSampleLabel: setGuidedAiSampleLabelReducer,
    resetAutomationGuidedAiDataForm: resetGuidedAiDataFormReducer,
    setAutomationClause: setClauseReducer,
    setAutomationSample: setSampleReducer,
    setAutomationModel: setGuidedAiReducer,
    deleteAutomationModel: deleteGuidedAiReducer,
    setAutomationManageStage: setManageAutomationStageReducer,
    setAutomationClausePagination: setClausePaginationReducer,
    setAutomationSamplePageSize: setAutomationSamplePageSizeReducer,
    setAutomationSamplePagination: setSamplePaginationReducer,
  },
});
