import { FlagType, useFlag } from '~/flags';
import { Permission } from '~/types';

import { checkIsAdmin } from './checkIsAdmin';
import { and } from './utils';

const useUsersPage = () => {
  const userPermission = useFlag(FlagType.NewUsersPage);
  return userPermission;
};
export const canUseUsersPage: Permission = and([useUsersPage, checkIsAdmin]);
