import {
  black4,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  radioContainerContent: {
    display: 'inline-block',
    marginBottom: '10px',
    '& > div:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  folderAccessSettingContainer: {
    margin: '20px 0px 20px',
    display: 'block',
  },
  allowUsersDownloadContainer: {
    padding: '12px 15px',
    margin: '10px',
    backgroundColor: '#efefef',
    borderRadius: '3px',
  },
  allowUsersDownloadHeader: {
    marginBottom: '10px',
    fontSize: '12px',
    fontWeight: semibold,
  },
  privilegeOptionContainer: {
    display: 'flex',
  },
  allowUsersDownloadLabel: {
    marginLeft: '7px',
    fontSize: '13px',
  },
  aclControlsContainer: {
    paddingLeft: '3px',
  },
  aclControlsMessage: {
    marginBottom: '10px',
    padding: '10px',
    color: '#635d54',
    fontSize: '12px',
    lineHeight: '15px',
    backgroundColor: '#f5f5f5',
    borderLeft: '2px solid #c1c1c1',
    borderRadius: '3px',
  },
  inputFieldHeader: {
    margin: '20px 0 10px',
    display: 'block',
    fontSize: '12px',
  },
  accessDepartmentsContainerEmpty: {
    paddingBottom: '15px',
    borderBottom: '1px solid #ccc',
  },
  accessDepartmentsContainer: {
    paddingBottom: '15px',
  },
  noDeptMessage: {
    paddingLeft: '5px',
    color: black4,
    fontSize: '13px',
  },
  selectDepartmentCTA: {
    margin: '5px 0 15px 30px',
    color: evisortBlue,
    fontSize: '12px',
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      color: '#236d90',
    },
    '& svg': {
      position: 'relative',
      top: '3px',
    },
  },
};

export default styles;
