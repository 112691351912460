import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { SharedGridProps } from '../../types';
import { Box } from '../Box';

export const Grid = memo(
  ({
    align,
    columns = 1,
    columnSpacing,
    columnWidths,
    justify,
    rows,
    rowSpacing,
    ...rest
  }: SharedGridProps) => {
    const styles = useStyles();

    const styleProps = {
      align,
      columns,
      columnSpacing,
      columnWidths,
      justify,
      rows,
      rowSpacing,
    };

    return (
      <Box
        {...rest}
        styles={styles.gridColumns}
        styleProps={styleProps}
        role="grid"
      />
    );
  },
);
