import { sortStrings } from './sortStrings';

export function sortByDateValue(key, order = 'desc') {
  if (order === 'desc') {
    return (a, b) => new Date(b[key]) - new Date(a[key]);
  }
  return (a, b) => new Date(a[key]) - new Date(b[key]);
}

export function sortByNumericValue(key) {
  return (a, b) => a[key] - b[key];
}

export function sortByStringValue(key) {
  return (a, b) => sortStrings(a[key], b[key]);
}

export function getOptions(array, valueKey, labelKey) {
  return array
    .map((entry) => ({
      label: entry[labelKey] || '',
      value: entry[valueKey],
    }))
    .sort(sortByStringValue('label'));
}

export function coerceToArray(value) {
  return Array.isArray(value) ? value : [value];
}

export function getUniqueObjectsInArrayByKey(array, key) {
  return [...new Map(array.map((item) => [item[key], item])).values()];
}

export function getItemValue(array) {
  return array.map((itm) => itm.value);
}

/**
 * Joins a list of strings into a string with expressive delimiter support
 *
 * @params {string[]} list of strings
 * @param {Object} options - configuration options
 * @param {string} [options.delimiter] default join delimiter
 * @param {string} [options.lastDelimiter] the delimiter for the last item in the list
 * @returns {string}
 */
export const joinWith = (list, options = {}) => {
  const { delimiter = '', lastDelimiter = null } = options;
  const { length } = list;
  if (length === 1 || !lastDelimiter) {
    return list.join(delimiter);
  }

  return (
    list.slice(0, length - 1).join(delimiter) +
    `${lastDelimiter}${list[length - 1]}`
  );
};
