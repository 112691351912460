export function coercePageIndex(pageIndex) {
  const coercedPageIndex = Number(pageIndex);
  if (isNaN(coercedPageIndex) || coercedPageIndex < 0 || !coercedPageIndex) {
    return 0;
  }
  return coercedPageIndex - 1; // page index is 0 based
}

export const getMaximumByLimit = (number, max) => {
  if (isNaN(number) && !isNaN(max)) return max;
  else if (!isNaN(number) && isNaN(max)) return number;
  else if (isNaN(number) && isNaN(max)) return 0;
  else return number > max ? max : number;
};
