import { canUseWorkflowAdmin } from '~/permissions';

import { RoutePathType } from '../enums';
import { RouteResolver } from '../types';

/**
 * This creates the default resolvers for workflow admin routes:
 * - Provides the component to resolve to if user passes the permission canUseWorkflowAdmin.
 * - Redirects to RoutePathType.Home otherwise
 */
export const createWorkflowAdminResolvers = (
  component: React.ComponentType,
): RouteResolver[] => {
  return [
    {
      permission: canUseWorkflowAdmin,
      component,
    },
    {
      redirectPath: RoutePathType.Home,
    },
  ];
};
