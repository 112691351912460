import PropTypes from 'prop-types';
import React from 'react';

const DocumentTypeMovIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path fill="#8247BC" d="M4 2h12l4 4v16H4z" />
        <text
          fill="#FFF"
          fontFamily="SFMono-Heavy, SF Mono, Arial"
          fontSize="5.4"
          fontWeight="600"
        >
          <tspan x="6.437" y="19">
            MOV
          </tspan>
        </text>
        <path
          fill="#FFF"
          d="M10.787 5.55l3.628 2.54a.5.5 0 0 1 0 .82l-3.628 2.54a.5.5 0 0 1-.787-.41V5.96a.5.5 0 0 1 .787-.41z"
        />
      </g>
    </svg>
  );
};

DocumentTypeMovIcon.defaultProps = {
  size: '24',
};

DocumentTypeMovIcon.propTypes = {
  size: PropTypes.string,
};

export default DocumentTypeMovIcon;
