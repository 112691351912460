import {
  black3,
  bold,
  evisortBlue,
  evisortRed,
} from '../../../assets/shared-styles/general';

const styles = {
  ecFilterField: {
    display: 'flex',
    alignItems: 'center',
  },
  removeFieldContainer: {
    marginRight: '8px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  fieldContainer: {
    width: '240px',
  },
  fieldContainerError: {
    width: '240px',
    '& .react-datepicker__input-container > input': {
      border: '1px solid #f33737',
    },
  },
  multipleValuesContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0 0 28px',
    '& svg': {
      flexShrink: 0,
    },
  },
  multipleValues: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: '41px',
    marginLeft: '8px',
  },
  multipleValuesEmpty: {
    color: (props) => (props.error ? evisortRed : black3),
    fontSize: '15px',
  },
  foldersAddLink: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: '14px 0 0 28px',
    cursor: 'pointer',

    '& svg': {
      marginRight: '4px',
    },

    '& span': {
      color: evisortBlue,
      fontSize: '13px',
      fontWeight: bold,
    },
  },
  pathTooltip: {
    fontWeight: bold,
  },
};

export default styles;
