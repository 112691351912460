import { useEffect, useMemo } from 'react';

import {
  Button,
  ContentContainer,
  Layout,
  StatusMessage,
  useDebouncedState,
  useToast,
} from '~/eds';
import { useConversationPipeline } from '~/features/ask-anything/hooks';
import { api } from '~/redux';
import { Question } from '~/types';

import { useQuestionGroupFormModal } from '../QuestionGroups/useQuestionGroupFormModal';
import { QuestionList } from './QuestionList';
import { SearchAndFilters } from './SearchAndFilters';

const failedToLoadQuestionsMessage = {
  status: 'warning' as const,
  title: 'Error: Unable to Load Saved Questions',
  message: 'Failed to load saved questions.',
};

export const Questions = ({ disableRun }: { disableRun?: boolean }) => {
  const [debouncedSearch, search, setSearch] = useDebouncedState<string>('');
  const [
    questionGroupFormModal,
    openQuestionGroupFormModal,
  ] = useQuestionGroupFormModal({
    questionGroup: null,
  });
  const {
    data: questions,
    isError,
    isFetching,
  } = api.endpoints.getQuestions.useQuery({
    search: debouncedSearch,
  });

  const { toast } = useToast();

  const onFinishQueue = () => {
    setTimeout(() => {
      clearQueue();
    }, 3000);
  };

  const {
    postMessage,
    clearQueue,
    processingMessage: runningQuestion,
    queue: messageQueue,
  } = useConversationPipeline({
    onFinishQueue,
  });

  const runQuestion = (question: Question) => {
    postMessage({ id: question.id, message: question.text });
  };

  // Clear the queue when the component unmounts
  useEffect(() => {
    return clearQueue;
  }, []);

  useEffect(() => {
    if (isError) {
      toast(failedToLoadQuestionsMessage);
    }
  }, [isError]);

  const renderStatusMessage = () => {
    if (isError) {
      return <StatusMessage {...failedToLoadQuestionsMessage} />;
    }

    if (!isFetching && debouncedSearch && questions?.length === 0) {
      return (
        <StatusMessage
          status="info"
          title="No saved questions match the search"
          message="Try searching with a different text."
        />
      );
    }

    if (!isFetching && !debouncedSearch && questions?.length === 0) {
      return (
        <StatusMessage
          status="info"
          title="No saved questions yet"
          message="Save a question by clicking on the bookmark icon on the right of a question."
        />
      );
    }

    return null;
  };

  const questionWithStatus = useMemo(
    () =>
      (questions ?? [])?.map((question) => {
        const status = messageQueue.find(
          (message) => message.id === question.id,
        )?.status;
        return {
          ...question,
          status: status ?? ('idle' as const),
        };
      }),
    [questions, messageQueue],
  );

  return (
    <Layout direction="column" styles={componentStyles.mainContainer}>
      <Layout
        spacing={4}
        direction="column"
        styles={componentStyles.questionsContainer}
      >
        <SearchAndFilters search={search} onSearch={setSearch} />
        {renderStatusMessage() ?? (
          <ContentContainer
            loadingContent={{
              isLoading: isFetching,
              message: 'Loading questions...',
            }}
          >
            <QuestionList
              questions={questionWithStatus}
              disableRun={!!runningQuestion?.id || !!disableRun}
              onQuestionClick={runQuestion}
            />
          </ContentContainer>
        )}
      </Layout>
      {!isFetching && questions && questions.length > 0 && (
        <Layout
          justify="center"
          align="center"
          styles={componentStyles.createQuestionGroupButton}
        >
          <Button
            icon="plus"
            iconPosition="left"
            variant="action"
            text="Create a Question Group"
            onClick={openQuestionGroupFormModal}
          />
        </Layout>
      )}
      {questionGroupFormModal}
    </Layout>
  );
};

const componentStyles = {
  mainContainer: {
    overflow: 'hidden',
    height: '100%',
    justifyContent: 'space-between',
    width: 'sideContent.width',
    position: 'relative',
    flex: 1,
  },
  questionsContainer: {
    overflow: 'hidden',
    flex: 1,
    marginBottom: 8,
  },
  createQuestionGroupButton: {
    bottom: 0,
    width: '100%',
    position: 'absolute',
  },
};
