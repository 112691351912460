export default `
<svg
  aria-hidden="true"
  aria-label="field-date"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.5 12C11.75 12 12 12.25 12 12.5V15.5C12 15.7812 11.75 16 11.5 16H8.5C8.21875 16 8 15.7812 8 15.5V12.5C8 12.25 8.21875 12 8.5 12H11.5ZM9 4C9.40625 4 9.75 4.34375 9.75 4.75V6H14.25V4.75C14.25 4.34375 14.5625 4 15 4C15.4062 4 15.75 4.34375 15.75 4.75V6H17C18.0938 6 19 6.90625 19 8V18C19 19.125 18.0938 20 17 20H7C5.875 20 5 19.125 5 18V8C5 6.90625 5.875 6 7 6H8.25V4.75C8.25 4.34375 8.5625 4 9 4ZM17.5 10H6.5V18C6.5 18.2812 6.71875 18.5 7 18.5H17C17.25 18.5 17.5 18.2812 17.5 18V10Z"
    fill="currentcolor"
  />
</svg>
`;
