import { endOfDay, subDays } from 'date-fns';

import { Field } from '~/components/Shared/Filters_DEPRECATED/types';
import { Filter } from '~/evifields';

import { entityTypeOptions, operationOptions } from './constants';
import { EntityType, Operation } from './types';

const getEntityTypeOptions = (selectedOperation: Operation | undefined) => {
  const matchEntity = (entity: EntityType) => {
    const filteredEntity = entityTypeOptions.filter(
      (option) => option.value === entity,
    );
    return filteredEntity;
  };

  switch (selectedOperation) {
    case 'DEACTIVATE':
    case 'EDIT':
    case 'LOGOUT':
    case 'LOGIN':
      return matchEntity('USER' as EntityType);
    case 'DELETE':
    case 'DOWNLOAD':
    case 'VIEW':
    case 'FIELD_CHANGE':
      return matchEntity('DOCUMENT' as EntityType);
    case 'SECURITY':
      return matchEntity('FOLDER' as EntityType);
    case 'CREATE':
    default:
      return entityTypeOptions;
  }
};

export const validateFilters = (filters: Filter[]) => {
  const [operationFilter, entityFilter, ...restFilters] = filters;

  const operation = operationFilter.values[0] as Operation;
  const entityValue = entityFilter.values[0];

  const entityOptions = getEntityTypeOptions(operation);
  const validEntityValues = entityOptions.map((entity) => entity.value);
  const isValidEntity = validEntityValues.some(
    (validEntityValue) => validEntityValue === entityValue,
  );

  return isValidEntity
    ? filters
    : [
        operationFilter,
        { ...entityFilter, values: validEntityValues },
        ...restFilters,
      ];
};

export const getFields = (
  selectedOperation?: Operation,
): Record<string, Field> => {
  const today = new Date();
  const max = endOfDay(today);
  const min = subDays(today, 179);

  return {
    activity: {
      id: 'activity',
      label: 'Activity Type',
      type: 'enum',
      whitelistOperatorIds: ['equals'],
      settings: {
        options: operationOptions,
      },
    },
    entityType: {
      id: 'entityType',
      label: 'Record Type',
      type: 'enum',
      whitelistOperatorIds: ['equals'],
      settings: { options: getEntityTypeOptions(selectedOperation) },
    },
    datetime: {
      id: 'datetime',
      label: 'Date Range',
      type: 'date',
      whitelistOperatorIds: [
        'date_between',
        'date_on',
        'date_after',
        'date_before',
      ],
      settings: {
        max,
        min,
        getDayTooltip: (date: Date) => {
          if (date > max) {
            return 'You can not select a future date';
          } else if (date < min) {
            return 'You can not go back more than 180 days';
          }
        },
      },
    },
  };
};
