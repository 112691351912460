import React, { memo, useEffect, useState } from 'react';

import { Layout } from '../Layout';
import { ProgressStage } from './ProgressStage';
import { Stage } from './types';

const pt = 8; // ensure enough top padding for step labels

interface Props {
  /** A list of stages with progress */
  stages: Stage[];
  /** 0-based index */
  activeStage?: number;
}

export const EviProgress = memo(({ activeStage = 0, stages }: Props) => {
  const [activeStageIndex, setActiveStageIndex] = useState(activeStage);

  useEffect(() => {
    setActiveStageIndex(activeStage);
  }, [activeStage]);

  return (
    <Layout align="center" pt={pt} spacing={8}>
      {stages.map((stage, index) => (
        <ProgressStage
          key={index}
          isActive={index === activeStageIndex}
          stage={stage}
          onSelect={() => setActiveStageIndex(index)}
        />
      ))}
    </Layout>
  );
});
