export default `
<svg
  aria-hidden="true"
  aria-label="department"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M13 8C13 10.2188 11.1875 12 9 12C6.78125 12 5 10.2188 5 8C5 5.8125 6.78125 4 9 4C11.1875 4 13 5.8125 13 8ZM2 19.0938C2 16 4.46875 13.5 7.5625 13.5H10.4062C13.5 13.5 16 16 16 19.0938C16 19.5938 15.5625 20 15.0625 20H2.90625C2.40625 20 2 19.5938 2 19.0938ZM21.0312 20H16.7188C16.875 19.7188 17 19.375 17 19V18.75C17 16.875 16.125 15.1562 14.8125 14.0312C14.875 14.0312 14.9375 14 15.0312 14H16.9375C19.7188 14 22 16.2812 22 19.0625C22 19.5938 21.5625 20 21.0312 20ZM15.5 12C14.5312 12 13.6562 11.625 13 11C13.625 10.1562 14 9.125 14 8C14 7.1875 13.7812 6.375 13.4062 5.6875C14 5.28125 14.7188 5 15.5 5C17.4062 5 19 6.59375 19 8.5C19 10.4375 17.4062 12 15.5 12Z"
    fill="currentcolor"
  />
</svg>
`;
