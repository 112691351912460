import { ContentContainer, Markdown, PageLayout } from '~/eds';

const Page = () => {
  const welcome = {
    content: (
      <div>
        <ContentContainer
          placeholderContent={{
            image: 'error-page-access',
            title: 'Invalid Office 365 subscription',
          }}
        ></ContentContainer>
        <Markdown text={pageDescription} />
      </div>
    ),
  };

  return <PageLayout title="" welcome={welcome} />;
};

const pageDescription = `
Microsoft requires you to have an Office 365 subscription to use Word for the web. 

If you have a valid Office 365 subscription, try the following:

1. Clear your browser's cache.
2. Open a new browser tab and go to [https://office.com](https://office.com). Confirm that you can sign in in and use Word for the Web from your subscription.
3. Try to edit from Evisort again. If you get prompted for credentials, make sure to use the same credentials you did when signing in to office.com.

If the issue still persists, you can open a ticket with Evisort by emailing [support@evisort.com](mailto:support@evisort.com).
`;

export default Page;
