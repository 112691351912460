import { differenceInMinutes, sub } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Button, DateText, parseDate } from '~/eds';

const REMINDER_MINUTE_THRESHOLD = 60;
const SECOND = 1000;

function getTimeDifference(date) {
  return differenceInMinutes(new Date(), parseDate(date));
}

function SendReminder({
  lastReminderDate = sub(new Date(), { hours: 2 }).toISOString(),
  actionHandler,
}) {
  const [timeDifference, setTimeDifference] = useState(
    getTimeDifference(lastReminderDate),
  );

  useEffect(() => {
    let intervalId;
    setTimeDifference(getTimeDifference(lastReminderDate));
    intervalId = setInterval(() => {
      setTimeDifference(getTimeDifference(lastReminderDate));
    }, 60 * SECOND);

    return () => clearInterval(intervalId);
  }, [lastReminderDate]);

  const isReminderSent = timeDifference < REMINDER_MINUTE_THRESHOLD;

  const tooltip = isReminderSent ? (
    <>
      A reminder was sent{' '}
      <DateText
        date={lastReminderDate ? new Date(lastReminderDate) : undefined}
        format="duration"
        enableTick={true}
      />
      .
    </>
  ) : undefined;

  return (
    <Button
      text="Send Reminder"
      icon="expiring"
      variant={isReminderSent ? 'tertiary' : 'action'}
      disabled={isReminderSent}
      tooltip={tooltip}
      onClick={() => actionHandler()}
    />
  );
}

SendReminder.propTypes = {
  lastReminderDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  actionHandler: PropTypes.func,
};

export default SendReminder;
