import React, { ComponentProps, memo } from 'react';

import { BaseStatusMessageProps, SharedToggleProps } from '../../types';
import { Actions } from '../Actions';
import { Layout } from '../Layout';
import { Markdown } from '../Markdown';
import { StatusMessage } from '../StatusMessage';
import { Text } from '../Text';
import { Toggle } from '../Toggle';

interface Props {
  /** Section contents */
  children: React.ReactNode;
  /** Section title */
  title: string;
  /** Section actions */
  actions?: Pick<
    ComponentProps<typeof Actions>,
    'actions' | 'moreActions' | 'morePopover'
  >;
  /** Section description */
  description?: string;
  /** Heading level */
  heading?: 'h3' | 'h4';
  /** HTML ID */
  id?: string;
  /** Section Status Message */
  statusMessage?: BaseStatusMessageProps;
  /** Display an interactive toggle */
  toggle?: SharedToggleProps;
}

export const Section = memo(
  ({
    actions,
    children,
    description,
    heading = 'h3',
    id,
    statusMessage,
    title,
    toggle,
  }: Props) => {
    const isH3 = heading === 'h3';
    const variant = isH3 ? 'section' : 'body-bold';

    return (
      <Layout as="section" direction="column" id={id} spacing={2}>
        <Layout direction="column">
          <Layout align="center" justify="space-between">
            <Text as={heading} m={0} variant={variant}>
              {title}
            </Text>
            <Layout align="center" preset="s">
              {toggle && <Toggle {...toggle} />}
              {actions && <Actions {...actions} />}
            </Layout>
          </Layout>
          {description && <Markdown preset="description" text={description} />}
        </Layout>
        {statusMessage && <StatusMessage {...statusMessage} />}
        {children}
      </Layout>
    );
  },
);
