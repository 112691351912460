export enum RoutePathType {
  NotFound = '*',
  Activation = '/activation',
  Admin = '/admin',
  AdminConsole = '/admin/console',
  AdminConsoleClient = '/admin/console/:clientId',
  AdminConsoleClientAuditLogs = '/admin/console/:clientId/audit-logs',
  AdminConsoleClientCopyWorkflow = '/admin/console/:clientId/copy-workflow',
  AdminConsoleClientDataFields = '/admin/console/:clientId/data-fields',
  AdminConsoleClientDepartments = '/admin/console/:clientId/departments',
  AdminConsoleClientDepartmentsDepartment = '/admin/console/:clientId/department/:departmentId',
  AdminConsoleClientEmailIntake = '/admin/console/:clientId/email-intake',
  AdminConsoleClientIntegrations = '/admin/console/:clientId/integrations',
  AdminConsoleClientManagePartyLists = '/admin/console/:clientId/manage-party-lists',
  AdminConsoleClientMoreSettings = '/admin/console/:clientId/more-settings',
  AdminConsoleClientProcessDocuments = '/admin/console/:clientId/process-documents',
  AdminConsoleClientRestorationHub = '/admin/console/:clientId/restoration-hub',
  AdminConsoleClientSamlConfiguration = '/admin/console/:clientId/saml-configuration',
  AdminConsoleClientSamlConfigurationFormConfig = '/admin/console/:clientId/saml-configuration/form/:configId',
  AdminConsoleClientUsers = '/admin/console/:clientId/users',
  AdminClientCredits = '/admin/:clientId/credits',
  AdminRoles = '/admin/:clientId/roles/',
  AdminUsers = '/admin/:clientId/users',
  AutomationHub = '/automation',
  AutomationHubFields = '/automation/fields',
  AutomationHubFieldModel = '/automation/fields/:modelId',
  AutomationHubDetail = '/automation/detail',
  Alerts = '/alerts',
  Analyzer = '/analyzer',
  AnalyzerResults = '/analyzer/results',
  AnalyzerSavedSearches = '/analyzer/saved-searches',
  Onboarding = '/onboarding',
  ChangePassword = '/change-password',
  ClauseLibrary = '/clause-library/:clauseId?',
  Dashboards = '/dashboards/:dashboardId?',
  Document = '/document/:documentId',
  DocumentGroups = '/document-groups',
  DocumentGroupGroup = '/document-group/:groupId',
  Documents = '/documents',
  DocumentsFolder = '/documents/:folderId',
  DownloadEmailAttachmentPage = '/download-email-attachment',
  DownloadExportReportPage = '/download-export-report',
  ExpiredToken = '/expired-token',
  ExternalDocumentViewer = '/external-document-viewer',
  FoldersPermissionCheck = '/folders-permission-check',
  ForgotPassword = '/forgot-password',
  Home = '/',
  Login = '/login',
  LoginEmbed = '/login/embed',
  LoginEmbedSuccess = '/login/embed/success',
  ManagedEntities = '/entities/parties',
  MyDeletedDocuments = '/my-deleted-items',
  Reviewer = '/reviewer',
  ReviewerAdmin = '/reviewer/admin',
  ReviewerDataFieldReview = '/reviewer/data-field-review',
  SavedDashboards = '/saved-dashboards',
  SavedSearches = '/saved-searches',
  Search = '/search',
  SavedSearch = '/search/:searchId',
  Settings = '/settings',
  SuperAdminUsers = '/super-admin/users',
  TicketEmailActionError = '/invalid-email-action-token/',
  TicketEmailApproveConfirmation = '/email-action-approve-succeeds/',
  TicketEmailApproveComment = '/tickets/:ticket_id/judgment-results/:judgment_result_id/:jwt/approve/',
  TicketEmailRejectComment = '/tickets/:ticket_id/judgment-results/:judgment_result_id/:jwt/reject/',
  TicketEmailRejectConfirmation = '/email-action-reject-succeeds/',
  Unauthorized = '/unauthorized',
  Upload = '/upload',
  WorkflowBuilder = '/workflow/builder',
  WorkflowBuilderWorkflow = '/workflow/builder/:workflowId',
  WorkflowIntakeFormLink = '/workflow/:workflowId/intake-form/link',
  WorkflowTickets = '/workflow/tickets',
  WorkflowTicketsTicket = '/workflow/tickets/:ticketId',
  WorkflowTicketsTicketReviewerVersionNumber = '/workflow/tickets/:ticketId/reviewer/:versionNumber',
  WordOnlineFile = '/word-online/:fileId',
  WordOnlineError = '/word-online-invalid-subscription',
}
