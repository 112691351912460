import React from 'react';

import { Cell } from '../Cell';
import { UI_TABLE_SELECT_CELL_TESTID } from '../constants';

function SelectCell({
  dispatch,
  getToggleAllPageRowsSelectedProps,
  row,
  rows,
  state,
}) {
  const isHeader = !row;

  const {
    isMultiSelectRows,
    name,
    primaryColumnKey,
    trackedSelectedRowIndex,
  } = state;

  const { checked, indeterminate, onChange } = isHeader
    ? getToggleAllPageRowsSelectedProps()
    : row.getToggleRowSelectedProps();
  const value = indeterminate ? null : checked;

  const handleChange = (updatedValue) => {
    const dummyEvent = { target: { value: updatedValue } };
    onChange(dummyEvent);

    if (row) {
      dispatch({
        type: 'trackSelectedRow',
        payload: row.index,
      });
    }
  };

  /**
   * This handler implements the logic of multi-selection when shift-clicking:
   * - Read the tracked state.lastSelectedRowIndex
   * - Create a sorted (ascending) index tuple i.e. [start, large].  The ascending sorting is actually a behavior of the feature when shift-selecting.
   * - Get the selectedRowIds from the rows using the start and end index.
   * - Dispatch an action to handle and set the state in Redux.
   */
  const handleShiftClick = () => {
    if (row) {
      const [startIndex, endIndex] = [
        trackedSelectedRowIndex,
        row.index,
      ].sort();
      const rowIds = rows.slice(startIndex, endIndex + 1).map((row) => row.id);

      dispatch({
        type: 'toggleMultipleRowsSelected',
        payload: rowIds,
      });
    }
  };

  return (
    <Cell
      align="center"
      as={isHeader ? 'th' : 'td'}
      cellProps={{
        label: isHeader
          ? 'select all rows'
          : `select row ${row.original[primaryColumnKey] ?? row.id}`,
        name,
        value,
        onChange: handleChange,
        onShiftClick: isMultiSelectRows ? handleShiftClick : undefined,
      }}
      className={UI_TABLE_SELECT_CELL_TESTID}
      stickyLeft={0}
      type={isMultiSelectRows ? 'checkbox' : 'radio'}
    />
  );
}

export default SelectCell;
