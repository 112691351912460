export enum ManageSyncPairStageType {
  None = 0,
  Provider = 1,
  ProviderProcurements = 2,
  Authentication = 3,
  Folders = 4,
  Summary = 5,
  Realm = 6,
  Extract = 7,
  Discovery = 8,
  FileConfig = 9,
  MultiAPIs = 10,
  FolderAriba = 11,
  SummaryAriba = 12,
  FieldMapping = 13,
}
