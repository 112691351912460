import { InteractionPosition } from './types';

/**
 * This util returns the InteractionPosition (x, y) given the mouse event location (clientX, clientY) and offsets (initial values + containerRef).
 */
export const getInteractionPosition = ({
  clientX = 0,
  clientY = 0,
  containerRef,
  initialOffsetX = 0,
  initialOffsetY = 0,
}: {
  clientX?: number;
  clientY?: number;
  containerRef?: React.RefObject<HTMLDivElement>;
  initialOffsetX?: number;
  initialOffsetY?: number;
}): InteractionPosition => {
  const offsetX = (containerRef?.current?.offsetLeft ?? 0) + initialOffsetX;
  const offsetY = (containerRef?.current?.offsetTop ?? 0) + initialOffsetY;

  return {
    x: clientX - offsetX,
    y: clientY - offsetY,
  };
};
