import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import EcFilterInput from '~/components/Shared/EcFilterInput';
import { sortByStringValue } from '~/utils/array';

import { evisortGreen } from '../../../assets/shared-styles/general';
import { getDocumentsViewsetFields } from '../../DocumentsViewPage/Document.data';
import EcButton from '../../Shared/EcButton';
import EcDocumentsVisibleField from '../../Shared/EcDocumentsVisibleField';
import EcModalCard from '../../Shared/EcModalCard';
import AddIcon from '../../Shared/Icons/AddIcon';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import styles from './DocumentsColumnFieldsModal.styles';

class DocumentsColumnFieldsModal extends Component {
  constructor(props) {
    super(props);

    this.handleAddRow = this.handleAddRow.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.handleItemReorder = this.handleItemReorder.bind(this);
    this.handleAddAllFields = this.handleAddAllFields.bind(this);
    this.handleOnSaveClick = this.handleOnSaveClick.bind(this);

    this.state = {
      loading: false,
      filtering: false,
      notAddedDocumentColumnFields: [],
      filteredDocumentColumnFields: [],
      filteredNotAddedDocumentColumnFields: [],
    };
  }

  componentDidMount() {
    this.setState({ documentColumnFields: this.props.data });
    getDocumentsViewsetFields().then((data) => {
      const notAddedDocumentColumnFields = data
        .filter(
          (field) =>
            !this.props.data.some((item) => item.field_id === field.field_id),
        )
        .sort(sortByStringValue('field_name'));
      this.setState({ notAddedDocumentColumnFields });
    });
  }

  handleAddRow(field) {
    const {
      documentColumnFields,
      notAddedDocumentColumnFields,
      filteredDocumentColumnFields,
      filteredNotAddedDocumentColumnFields,
    } = this.state;

    const newDocumentColumnFields = [...documentColumnFields, field];
    const newNotAddedDocumentColumnFields = notAddedDocumentColumnFields.filter(
      (item) => item.field_id !== field.field_id,
    );

    const newFilteredDocumentColumnFields = [
      ...filteredDocumentColumnFields,
      field,
    ];
    const newFilteredNotAddedDocumentColumnFields = filteredNotAddedDocumentColumnFields.filter(
      (item) => item.field_id !== field.field_id,
    );

    this.setState({
      documentColumnFields: newDocumentColumnFields,
      notAddedDocumentColumnFields: newNotAddedDocumentColumnFields,
      filteredDocumentColumnFields: newFilteredDocumentColumnFields,
      filteredNotAddedDocumentColumnFields: newFilteredNotAddedDocumentColumnFields,
    });
  }

  handleRemoveRow(field) {
    const {
      documentColumnFields,
      notAddedDocumentColumnFields,
      filteredDocumentColumnFields,
      filteredNotAddedDocumentColumnFields,
    } = this.state;

    const newDocumentColumnFields = documentColumnFields.filter(
      (item) => item.field_id !== field.field_id,
    );
    const newNotAddedDocumentColumnFields = [
      ...notAddedDocumentColumnFields,
      field,
    ];

    const newFilteredDocumentColumnFields = filteredDocumentColumnFields.filter(
      (item) => item.field_id !== field.field_id,
    );

    this.setState({
      documentColumnFields: newDocumentColumnFields,
      notAddedDocumentColumnFields: newNotAddedDocumentColumnFields,
      filteredDocumentColumnFields: newFilteredDocumentColumnFields,
      filteredNotAddedDocumentColumnFields: [
        ...filteredNotAddedDocumentColumnFields,
        field,
      ],
    });
  }

  handleItemReorder(items) {
    this.setState({ documentColumnFields: items });
  }

  handleAddAllFields() {
    const {
      documentColumnFields,
      filteredDocumentColumnFields,
      notAddedDocumentColumnFields,
      filteredNotAddedDocumentColumnFields,
    } = this.state;

    this.setState({
      documentColumnFields: [
        ...documentColumnFields,
        ...notAddedDocumentColumnFields,
      ],
      notAddedDocumentColumnFields: [],
      filteredDocumentColumnFields: [
        ...filteredDocumentColumnFields,
        ...filteredNotAddedDocumentColumnFields,
      ],
      filteredNotAddedDocumentColumnFields: [],
    });
  }

  handleOnSaveClick() {
    const { handleSaveEditColumns, hideModal } = this.props;
    const { documentColumnFields } = this.state;

    const fields = documentColumnFields.map((field, index) => {
      return {
        column_number: index,
        field_id: field.field_id,
      };
    });

    handleSaveEditColumns(fields);
    hideModal();
  }

  handleFieldSearch(filterValue) {
    const { documentColumnFields, notAddedDocumentColumnFields } = this.state;
    const newDocumentColumnFields = documentColumnFields.filter((field) =>
      field.field_name.toLowerCase().includes(filterValue.toLowerCase()),
    );
    const newNotAddedDocumentColumnFields = notAddedDocumentColumnFields.filter(
      (field) =>
        field.field_name.toLowerCase().includes(filterValue.toLowerCase()),
    );

    this.setState({
      filterValue,
      filtering: true,
      filteredDocumentColumnFields: newDocumentColumnFields,
      filteredNotAddedDocumentColumnFields: newNotAddedDocumentColumnFields,
    });

    if (!filterValue) {
      this.setState({ filtering: false });
    }
  }

  renderSearchBox() {
    const { classes } = this.props;
    const { filterValue } = this.state;

    return (
      <div className={classes.searchboxContainer}>
        <div className={classes.filterSearch}>
          <EcFilterInput
            value={filterValue}
            onChange={(filterValue) => this.handleFieldSearch(filterValue)}
            onClearSearch={() => this.handleFieldSearch('')}
            placeholder="Filter fields..."
            id="documents-filter-fields-input"
          />
        </div>
      </div>
    );
  }

  renderVisibleColumns() {
    const {
      documentColumnFields,
      filtering,
      filteredDocumentColumnFields,
    } = this.state;

    return (
      <div>
        <EcDocumentsVisibleField
          filtering={filtering}
          itemsData={
            filtering ? filteredDocumentColumnFields : documentColumnFields
          }
          handleRemoveRow={this.handleRemoveRow}
          handleItemReorder={this.handleItemReorder}
        />
      </div>
    );
  }

  renderList(list) {
    const { classes } = this.props;
    return list.map((field) => {
      return (
        <div
          className={classes.itemFieldContainer}
          key={`field-${field.field_id}`}
        >
          <span className={classes.fieldName}>{field.field_name}</span>
          <button
            className={classes.itemRowActionButton}
            title={`Add ${field.field_name} field`}
            onClick={() => this.handleAddRow(field)}
          >
            <AddIcon color={evisortGreen} />
          </button>
        </div>
      );
    });
  }

  renderToAddColumnFields() {
    const { classes } = this.props;
    const {
      notAddedDocumentColumnFields,
      filtering,
      filteredNotAddedDocumentColumnFields,
    } = this.state;

    if (
      !notAddedDocumentColumnFields.length ||
      (filtering && !filteredNotAddedDocumentColumnFields.length)
    )
      return null;
    return (
      <div className={classes.otherFieldsContainer}>
        <div className={classes.headerContainer}>
          <span className={classes.titleContainer}>OTHER FIELDS</span>
          <button
            className={classes.titleActionContainer}
            onClick={this.handleAddAllFields}
          >
            Add All
          </button>
        </div>
        <div className={classes.listContainer}>
          {filtering
            ? this.renderList(filteredNotAddedDocumentColumnFields)
            : this.renderList(notAddedDocumentColumnFields)}
        </div>
      </div>
    );
  }

  render() {
    const { classes, hideModal } = this.props;
    const { loading, errorLoading } = this.state;

    if (loading && !errorLoading) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    } else if (!loading && errorLoading) {
      return (
        <div className={classes.errorMessage}>
          An error occurred while loading the document fields.
        </div>
      );
    }
    return (
      <EcModalCard
        title="View Settings"
        content={
          <div className={classes.modalBody}>
            <div className={classes.modalBodyContent}>
              {this.renderSearchBox()}
              {this.renderVisibleColumns()}
              {this.renderToAddColumnFields()}
            </div>
          </div>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              yellow
              iconLeft={<CheckmarkIcon size="20" />}
              text="Apply"
              onClick={this.handleOnSaveClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

DocumentsColumnFieldsModal.propTypes = {
  handleSaveEditColumns: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
};

export default injectSheet(styles)(DocumentsColumnFieldsModal);
