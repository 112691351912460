import { useMemo } from 'react';
import { connect } from 'react-redux';

import {
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import { FormField, Layout, Select, TextInput } from '~/eds';
import { AribaApiType, ManageSyncPairStageType } from '~/enums';
import { api } from '~/redux';

import { API_NAMES } from '../../constants';

function Realm({ activeSyncPair, integrationsSetActiveSyncPair }) {
  const stageProperties = activeSyncPair[ManageSyncPairStageType.Realm] ?? {};
  const isTokenReauthenticate = !!activeSyncPair?.isTokenReauthenticate;
  // if isReauth, the data is in the providerOptions
  const providerOptions = activeSyncPair.providerOptions ?? {};
  const { realm, location } = isTokenReauthenticate
    ? providerOptions
    : stageProperties;
  const { errors } = stageProperties ?? {};

  const {
    data: locationsRes,
    isSuccess: isGetLocationsSuccess,
    isFetching: isFetchingLocations,
  } = api.endpoints.getAribaLocations.useQuery(undefined);

  const locations = useMemo(() => {
    if (isGetLocationsSuccess) {
      return Object.keys(locationsRes).map((key) => {
        return {
          label: locationsRes[key],
          value: key,
        };
      });
    } else {
      return [];
    }
  }, [locationsRes, isGetLocationsSuccess]);

  const setRealm = (realm) => {
    const updatedValues = { ...stageProperties, realm, errors: {} };
    const updatedProperties = { ...providerOptions, realm };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      providerOptions: updatedProperties,
      [ManageSyncPairStageType.Realm]: updatedValues,
    });
  };

  const onSelectLocation = (location) => {
    const locationName = locationsRes[location];
    const updatedProperties = { ...providerOptions, location };
    const updatedValues = {
      ...stageProperties,
      location,
      locationName,
      errors: {},
    };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      providerOptions: updatedProperties,
      [ManageSyncPairStageType.Realm]: updatedValues,
    });
  };

  const getError = (apiType) => {
    if (!errors) {
      return null;
    }
    const errorData = errors[apiType];
    if (errorData && 'error' in errorData) {
      return errorData.error;
    }
    return null;
  };

  return (
    <Layout preset="form-fields">
      <FormField
        required
        label="Realm ID"
        placeholder="Enter a SAP Ariba Realm ID"
        value={realm}
        onChange={setRealm}
        input={TextInput}
        error={getError(API_NAMES[AribaApiType.REALM])}
      />
      <FormField
        required
        label="Data Center Location"
        value={location}
        inputProps={{
          options: locations,
          isMulti: false,
          isLoading: isFetchingLocations,
        }}
        onChange={onSelectLocation}
        input={Select}
        error={getError(API_NAMES[AribaApiType.REGION])}
      />
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
});

export default connect(mapStateToProps, {
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(Realm);
