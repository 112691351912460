import React, { memo } from 'react';

import { BasePlaceholderContentProps } from '../../types';
import { Actions } from '../Actions';
import { Icon } from '../Icon';
import { Image } from '../Image';
import { Layout } from '../Layout';
import { Text } from '../Text';

type Props = BasePlaceholderContentProps & {
  /** aria-live to indicate the type of announcement of the live placeholder region */
  ariaLive?: 'assertive' | 'polite';
  /** Icon label (for a11y) */
  label?: string;
};

export const PlaceholderContent = memo(
  ({
    action,
    ariaLive,
    icon,
    image,
    message = '',
    description = '',
    label,
    title,
  }: Props) => {
    return (
      <Layout
        align="center"
        aria-live={ariaLive}
        direction="column"
        flex="auto"
        h="100%"
        justify="center"
        spacing={6}
        p={4}
        w="100%"
      >
        {image && (
          <Image alt={`Image for "${title ?? message}"`} image={image} />
        )}
        <Layout align="center" direction="column" spacing={2} role="status">
          {icon && <Icon icon={icon} label={label} p={3} size="l" />}
          {title && <Text variant="subtitle">{title}</Text>}
          {message && (
            <Text color="text.secondary" textAlign="center">
              {message}
            </Text>
          )}
          {description && <Text color="text.quiet">{description}</Text>}
        </Layout>
        {action && <Actions actions={[action]} />}
      </Layout>
    );
  },
);
