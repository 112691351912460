import { TicketStatusType } from '~/enums';
import { ColumnSortOrder, TableTicket } from '~/types';

import { SortBy } from './workflowTicketTypes';

export const preProcessNewTicketsPageData = (
  tickets: TableTicket[],
): TableTicket[] => {
  const statuses = [
    TicketStatusType.Editing,
    TicketStatusType.Review,
    TicketStatusType.PendingSignatures,
    TicketStatusType.PendingFinalization,
    TicketStatusType.PendingCompletion,
    TicketStatusType.Completed,
  ];
  return tickets.map((item) => ({
    ...item,
    statusOrder: statuses.indexOf(item.status).toString(),
  }));
};

export const getSortBy = (sortBy: SortBy) => {
  switch (sortBy.id) {
    case 'name':
      return sortBy.desc ? '-name' : '+name';
    case 'submitted by':
      return sortBy.desc ? '-creator' : '+creator';
    case 'submitted on':
      return sortBy.desc ? '-createdDate' : '+createdDate';
    case 'last activity':
      return sortBy.desc ? '-modifiedDate' : '+modifiedDate';
    case 'status':
      return sortBy.desc ? '-status' : '+status';
    default:
      return '-modifiedDate';
  }
};

export const getSortByV2 = (sortBy: SortBy): ColumnSortOrder => {
  switch (sortBy.id) {
    case 'name':
      return {
        id: 'name_Ticket',
        desc: sortBy.desc,
      };
    case 'submitted by':
      return {
        id: 'submitter_Ticket',
        desc: sortBy.desc,
      };
    case 'submitted on':
      return {
        id: 'ticket_submit_date_Ticket',
        desc: sortBy.desc,
      };
    case 'status':
      return {
        id: 'stage_Ticket',
        desc: sortBy.desc,
      };
    case 'last activity':
    default:
      return {
        id: 'ticket_last_activity_date_Ticket',
        desc: sortBy.desc,
      };
  }
};
