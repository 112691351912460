import { compact } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import {
  AutomationHubGuidedAiPage,
  AutomationHubManagement,
  AutomationHubQuickAiPage,
} from '~/components/AutomationHub';
import withHover from '~/components/Shared/HOCs/withHover';
import BackIcon from '~/components/Shared/Icons/BackIcon';
import { ContentContainer, Tabs } from '~/eds';
import { AutomationStatusType, FeatureFlagType, QueryParamType } from '~/enums';
import { useHasFeatureFlag } from '~/hooks';
import { actions } from '~/redux';
import { useRouting } from '~/routing';
import { FlexLayout, Text, Tooltip } from '~/ui';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';

const Page = ({
  clause,
  model,
  resetAutomationStates,
  setAutomationClause,
}) => {
  const clauseName = getPageSearchQueryByKey(
    QueryParamType.Clause,
    clause.name,
  );

  const hasAutomationSmartClauseFull = useHasFeatureFlag(
    FeatureFlagType.AutomationSmartClauseFull,
  );

  const TABS = compact([
    clauseName && {
      label: 'Quick AI',
      value: 'QUICK_AI',
    },
    hasAutomationSmartClauseFull && {
      label: 'Guided AI',
      value: 'GUIDED_AI',
    },
  ]);

  const defaultTab = useMemo(() => {
    if (model.showCreating) {
      return 'GUIDED_AI';
    } else {
      return 'QUICK_AI';
    }
  }, [model]);

  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    setCurrentTab(defaultTab);
  }, [defaultTab]);

  const BackIconWithHover = withHover(BackIcon);
  const { navigate } = useRouting();

  const isLongClauseName = clauseName?.length > 64;
  const title = isLongClauseName
    ? `${clauseName?.substring(0, 64)}...`
    : clauseName;

  const onBackToClausePage = () => {
    resetAutomationStates();
    setAutomationClause({
      ...clause,
      name: '',
      status: AutomationStatusType.ACTIVE,
    });
    navigate(-1);
  };

  return (
    <ContentContainer>
      <FlexLayout flexDirection="column" space={8} mb={25}>
        <FlexLayout justifyContent="space-between">
          <FlexLayout space={2}>
            <button onClick={onBackToClausePage}>
              <BackIconWithHover />
            </button>
            {isLongClauseName ? (
              <Tooltip content={clauseName} placement="bottom">
                <Text variant="2xl-spaced-bold" color="gray-700">
                  {title}
                </Text>
              </Tooltip>
            ) : (
              <Text variant="2xl-spaced-bold" color="gray-700">
                {title}
              </Text>
            )}
          </FlexLayout>
        </FlexLayout>
        <Tabs
          enableContentPadding={false}
          selectedTab={currentTab}
          tabs={TABS}
          onSelectTab={setCurrentTab}
        />
        {currentTab === 'QUICK_AI' && <AutomationHubQuickAiPage />}
        {currentTab === 'GUIDED_AI' && <AutomationHubGuidedAiPage />}
        <AutomationHubManagement />
      </FlexLayout>
    </ContentContainer>
  );
};

const mapStateToProps = ({ automation }) => ({
  clause: automation.clause,
  model: automation.model,
  quickAiForm: automation.quickAiForm,
});

export default connect(mapStateToProps, {
  resetAutomationStates: actions.resetAutomationStates,
  setAutomationClause: actions.setAutomationClause,
})(Page);
