import { useState } from 'react';

import { useResponsiveWidth } from './useResponsiveWidth';

export function useHasOverflow(ref) {
  const [hasOverflow, setHasOverflow] = useState(false);

  const resize = () => {
    const element = ref.current;
    if (element) {
      setHasOverflow(element.scrollWidth > element.clientWidth);
    }
  };

  useResponsiveWidth(ref, resize);

  return hasOverflow;
}
