export default `
<svg
  aria-hidden="true"
  aria-label="flag"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.875 4C18.6562 4 18.4688 4.0625 18.25 4.15625C16.8438 4.8125 15.7812 5.03125 14.9062 5.03125C13.0312 5.03125 11.9375 4.03125 9.78125 4.03125C8.6875 4.03125 7.34375 4.28125 5.5 5.03125V4.75C5.5 4.34375 5.15625 4 4.75 4C4.3125 4 4 4.34375 4 4.75V19.25C4 19.6875 4.3125 20 4.75 20C5.15625 20 5.5 19.6875 5.5 19.25V16C7.15625 15.2812 8.5 15.0312 9.625 15.0312C11.9375 15.0312 13.5312 16 15.8438 16C16.8438 16 17.9688 15.8125 19.3125 15.2812C19.75 15.125 20 14.7812 20 14.4062V4.96875C20 4.375 19.4688 4 18.875 4ZM18.5 14C17.5312 14.3438 16.6562 14.5 15.8438 14.5C14.875 14.5 14.0312 14.2812 13.0938 14.0625C12.0938 13.8125 10.9688 13.5312 9.625 13.5312C8.3125 13.5312 6.9375 13.8125 5.5 14.375V6.65625L6.0625 6.40625C7.53125 5.8125 8.75 5.53125 9.78125 5.53125C10.6562 5.53125 11.3125 5.75 12.0625 5.96875C12.8438 6.21875 13.75 6.53125 14.9062 6.53125C16 6.53125 17.1562 6.25 18.5 5.6875V14Z"
    fill="currentcolor"
  />
</svg>
`;
