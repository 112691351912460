import React, { memo, useRef } from 'react';
import { useStyles } from 'uinix-ui';

import { useClickOutside, useEscape } from '../../hooks';
import { theme } from '../../system/theme';
import { SharedPageOverlayProps, SharedPanelProps } from '../../types';
import { Backdrop } from '../Backdrop';
import { Box } from '../Box';
import { ContentContainer } from '../ContentContainer';
import { Layout } from '../Layout';
import { Panel } from '../Panel';
import { Portal } from '../Portal';

interface Props extends SharedPageOverlayProps {
  /** Optional overlay footer */
  footer?: React.ReactNode;
  /** Optional overlay header */
  header?: React.ReactNode;
  /** Optional panel (only supported when `isFullPage=true` */
  panel?: SharedPanelProps;
}

/**
 * `PageOverlay` is a controlled component that renders popover content with action buttons.
 *
 * `PageOverlay` visibiliy behaviors
 * - `isVisible` controls the visibility
 * - `onHide` callback is called when the overlay is being hidden.
 */
export const PageOverlay = memo(
  ({
    backgroundGradient,
    children,
    disableHideOnClickOutside,
    disableHideOnEscape,
    enableContentPadding = true,
    footer,
    header,
    isFullPage = false,
    isStretched = false,
    isVisible = false,
    loadingContent,
    overflow = 'auto',
    panel,
    placeholderContent,
    width = 'm',
    onHide,
  }: Props) => {
    const overlayRef = useRef(null);

    const styles = useStyles();

    const enableHideOnEscape = isVisible && !disableHideOnEscape;

    const p = enableContentPadding ? 4 : undefined;

    const handleHide = () => {
      onHide?.();
    };

    const handleHideOnClickOutside = () => {
      if (!disableHideOnClickOutside) {
        handleHide();
      }
    };

    useClickOutside(overlayRef, handleHideOnClickOutside);

    useEscape(handleHide, enableHideOnEscape);

    if (!isVisible) {
      return null;
    }

    const ui = (
      <Backdrop disableBodyScroll={isVisible} mode="center">
        <div
          ref={overlayRef}
          style={componentStyles.overlay({ isFullPage, width })}
        >
          <Layout
            aria-modal
            direction="column"
            role="dialog"
            styles={componentStyles.container({ isFullPage })}
            variant={isFullPage ? undefined : 'borderRadius.all'}
          >
            {header}
            <Layout
              styles={[
                componentStyles.body({ overflow }),
                isFullPage
                  ? componentStyles.bodyFullPage
                  : isStretched
                  ? componentStyles.bodyStretch
                  : null,
                isFullPage && backgroundGradient
                  ? styles.background.gradient[backgroundGradient]
                  : null,
              ]}
            >
              <ContentContainer
                loadingContent={loadingContent}
                placeholderContent={placeholderContent}
              >
                <Layout
                  direction="column"
                  flex="auto"
                  overflowY={overflow}
                  p={p}
                >
                  {children}
                </Layout>
              </ContentContainer>
              {panel && (
                <Layout flex="none">
                  <Panel {...panel} />
                </Layout>
              )}
            </Layout>
            {footer && (
              <Box
                bg="background"
                borderTop="border.divider"
                px={p}
                variant={isFullPage ? undefined : 'borderRadius.bottom'}
              >
                {footer}
              </Box>
            )}
          </Layout>
        </div>
      </Backdrop>
    );

    return <Portal>{ui}</Portal>;
  },
);

const offsetMargin = theme.spacings[12] as number;

export const getBodyMaxHeight = () => {
  const headerHeight = theme.sizes['header.height'] as number;
  const bodyMaxHeight = theme.sizes['page-overlay.body.max-height'] as number;
  const footerHeight = theme.sizes['footer.height'] as number;
  return `min(calc(100vh - ${2 * offsetMargin}px - ${
    headerHeight + footerHeight
  }px), ${bodyMaxHeight}px)`;
};

export const componentStyles = {
  body: ({ overflow }: { overflow: SharedPageOverlayProps['overflow'] }) => ({
    flex: 'auto',
    overflowY: overflow,
    maxHeight: getBodyMaxHeight(),
  }),
  bodyStretch: {
    height: getBodyMaxHeight(),
  },
  bodyFullPage: {
    maxHeight: 'unset',
  },
  container: ({ isFullPage }: { isFullPage: boolean }) => ({
    backgroundColor: 'background',
    boxShadow: 'raised',
    flex: 'auto',
    height: '100%',
    marginBottom: isFullPage ? 0 : offsetMargin,
    marginTop: isFullPage ? 0 : offsetMargin,
    minWidth: 0,
    width: '100%',
  }),
  overlay: ({
    isFullPage,
    width,
  }: {
    isFullPage: boolean;
    width: SharedPanelProps['width'];
  }) =>
    ({
      display: 'flex',
      height: isFullPage ? '100vh' : 'fit-content',
      maxWidth: '100vw',
      width: isFullPage ? '100vw' : theme.sizes[`page-overlay.${width}`],
    } as object),
};
