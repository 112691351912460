import { cloneDeep } from 'lodash';

import { ActionsMenu, DateText, Layout, Text } from '~/eds';
import { TurnTrackingHistoryType, TurnTrackingPartyType } from '~/types';

import { calculateDuration, partyNameFromPartyIdAndParties } from './utils';

interface Props {
  parties: TurnTrackingPartyType[];
  historyEntry: TurnTrackingHistoryType;
  onSetEditingEntry?: (entry: TurnTrackingHistoryType) => void;
  onShowEditPanel?: () => void;
}

export const TurnTrackingHistory = ({
  parties,
  historyEntry,
  onSetEditingEntry,
  onShowEditPanel,
}: Props) => {
  const { startTime, endTime, partyId } = historyEntry;

  return (
    <Layout justify="space-between" align="center">
      <Layout direction="column">
        <Text color="text.secondary">
          <Text variant="body-bold" color="text.secondary">
            {partyNameFromPartyIdAndParties(partyId, parties)}{' '}
          </Text>
          {calculateDuration(startTime, endTime || new Date())} Days
        </Text>
        <Text color="text.secondary">
          <DateText date={startTime} format="full" variant="tiny" /> -{' '}
          {endTime ? (
            <DateText date={endTime} format="full" variant="tiny" />
          ) : (
            <Text variant="tiny" color="text.secondary">
              Present
            </Text>
          )}
        </Text>
      </Layout>
      {onSetEditingEntry && onShowEditPanel && (
        <ActionsMenu
          actions={[
            { label: 'Edit date and time', value: 'Edit date and time' },
          ]}
          name="more actions"
          onActionClick={() => {
            onShowEditPanel();
            onSetEditingEntry(cloneDeep(historyEntry));
          }}
        />
      )}
    </Layout>
  );
};
