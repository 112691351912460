const defaultOptions: ScrollIntoViewOptions = {
  behavior: 'auto',
};

/**
 * A convenient wrapper around HTMLElement.scrollIntoView
 *
 * @example
 * ```
 * scrollElementIntoView('does-not-exist') // does nothing
 * scrollElementIntoView('my-id') // scrolls to element with ID 'my-id'
 * scrollElementIntoView('my-id', options) // scrolls to element with ID 'my-id' using provided ScrollIntoViewOptions
 * scrollElementIntoView('my-id', options, container) // scrolls element with ID 'my-id' using provided ScrollIntoViewOptions within the specified `container` element.
 * ```
 **/

export const scrollElementIntoView = (
  elementId: string,
  options: ScrollIntoViewOptions = defaultOptions,
  container?: HTMLElement | null,
) => {
  const element = document.getElementById(elementId);
  if (element) {
    if (container) {
      const elementRect = element.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      // Calculate the scroll positions needed to bring the element into view
      const scrollLeft =
        elementRect.left - containerRect.left + container.scrollLeft;
      const scrollTop =
        elementRect.top - containerRect.top + container.scrollTop;

      container.scrollTo({
        ...options,
        top: scrollTop,
        left: scrollLeft,
      });
    } else {
      element.scrollIntoView?.(options);
    }
  }
};
