export default `
<svg
  aria-hidden="true"
  aria-label="format-align-right"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.25 5.25C18.6562 5.25 19 5.59375 19 6C19 6.4375 18.6562 6.75 18.25 6.75H10.75C10.3125 6.75 10 6.4375 10 6C10 5.59375 10.3125 5.25 10.75 5.25H18.25ZM18.25 9.25C18.6562 9.25 19 9.59375 19 10C19 10.4375 18.6562 10.75 18.25 10.75H5.75C5.3125 10.75 5 10.4375 5 10C5 9.59375 5.3125 9.25 5.75 9.25H18.25ZM18.25 14.75H10.75C10.3125 14.75 10 14.4375 10 14C10 13.5938 10.3125 13.25 10.75 13.25H18.25C18.6562 13.25 19 13.5938 19 14C19 14.4375 18.6562 14.75 18.25 14.75ZM18.25 17.25C18.6562 17.25 19 17.5938 19 18C19 18.4375 18.6562 18.75 18.25 18.75H5.75C5.3125 18.75 5 18.4375 5 18C5 17.5938 5.3125 17.25 5.75 17.25H18.25Z"
    fill="currentcolor"
  />
</svg>
`;
