export default `
<svg
  aria-hidden="true"
  aria-label="left-panel"
  width="16"
  height="14"
  viewBox="0 0 16 14"
  fill="none"
>
  <path d="M14 1C14.5531 1 15 1.44687 15 2V4H1V2C1 1.44687 1.44687 1 2 1H14ZM1 12V5H5V13H2C1.44687 13 1 12.5531 1 12ZM6 13V5H15V12C15 12.5531 14.5531 13 14 13H6ZM2 0C0.896875 0 0 0.896875 0 2V12C0 13.1031 0.896875 14 2 14H14C15.1031 14 16 13.1031 16 12V2C16 0.896875 15.1031 0 14 0H2Z" fill="currentcolor"/>  
</svg>
`;
