import classnames from 'classnames';
import PT from 'prop-types';
import React from 'react';
import InlineSvg from 'react-inlinesvg';

import { UI_ICON_TEST_ID } from '~/constants/testids';

import * as icons from '../../assets/icons';
import { withTooltip } from '../../hocs/withTooltip';
import styles from '../../styles';
import Box from '../Box';

const buttonResetStyle = {
  background: 'none',
  border: 'none',
  padding: 0,
};

const interactableStyle = {
  ...styles.interactions.hoverable,
  ...buttonResetStyle,
};

const Icon = React.forwardRef(
  (
    { className, color, icon, size = 'm', sx = {}, title, onClick, ...rest },
    ref,
  ) => {
    // eslint-disable-next-line import/namespace
    const iconSrc = icons[icon];
    if (!iconSrc) {
      return null;
    }

    function getIconSize(theme) {
      return theme.sizes[`icon-${size}`];
    }

    /* ticket 4898 todo need to investigate, the title is missing because some icons have tooltip or missing title entirely.
  If we add both title and tooltip, they both show up. This warning in local dev is too noisy.
  if (onClick && !title) {
    warn('<Icon> is a button but missing title - a11y');
  }*/

    return (
      <Box
        as={onClick ? 'button' : 'div'}
        className={classnames(UI_ICON_TEST_ID, className)}
        name={icon}
        ref={ref}
        sx={{
          ...sx,
          ...(onClick ? interactableStyle : {}),
          height: getIconSize,
          width: getIconSize,
          color: color || 'currentColor',
          flex: 'none',
        }}
        title={title}
        onClick={onClick}
        {...rest}
      >
        <InlineSvg src={iconSrc} title={title} height="100%" width="100%" />
      </Box>
    );
  },
);

export const iconKeyPropType = PT.oneOf(Object.keys(icons));

Icon.propTypes = {
  /** Classname */
  className: PT.string,
  /** Valid system color */
  color: PT.string,
  /** Conveniently disables pointer events on the element. Useful to disable user interaction */
  disabled: PT.bool,
  /** Valid icon key for accessing icon svg asset */
  icon: iconKeyPropType.isRequired,
  /** Element ID */
  id: PT.string,
  /** Forwards provided ref */
  // ref: PT.object,
  /** Icon size */
  size: PT.oneOf(['2xs', 'xs', 's', 'm', 'l', 'xl']),
  /** Supports sx styles */
  sx: PT.object,
  /** Icon title is expected when onClick is provided, in which case the component is rendered as a button without label,
   * and the title is necessary for a11y purpose. */
  title: PT.string,
  /** Tooltip */
  tooltip: PT.string,
};

export const DOCS__Icon = Icon;

export default withTooltip(Icon);
