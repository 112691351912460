import {
  black1,
  black2,
  black3,
  black4,
  bold,
  evisortBlue,
  evisortRed,
  evisortYellow,
  semibold,
} from '../../../assets/shared-styles/general';

const algoStatusDot = {
  margin: '4px 7px',
  width: '8px',
  height: '8px',
  display: 'flex',
  borderRadius: '8px',
};

const algoStatusIndicatorBar = {
  height: '10px',
  display: 'inline-block',
};

const styles = {
  documentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '32px',
  },
  documentBackAction: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    padding: 0,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
  },
  emptyBreadcrumbs: {
    width: '279px',
    height: '14px',
    marginBottom: '4px',
    backgroundColor: '#f0f0f0',
  },
  emptyDocumentName: {
    width: '460px',
    height: '27px',
    backgroundColor: '#f0f0f0',
  },
  documentFullTitle: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  breadcrumbs: {
    marginBottom: '4px',
    color: black3,
    fontSize: '13px',
    fontWeight: semibold,
    letterSpacing: '1px',
    '& span': {
      cursor: 'pointer',
      '&:hover': {
        color: evisortBlue,
      },
      '&:not(:last-child):after': {
        content: '" > "',
      },
    },
  },
  documentName: {
    margin: 0,
    fontSize: '24px',
    fontWeight: bold,
    wordBreak: 'break-word',
  },
  documentHeaderActions: {
    display: 'flex',
    alignItems: 'center',
  },
  documentGroupContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerSeparatorMajor: {
    width: '16px',
    display: 'block',
  },
  headerSeparatorMinor: {
    width: '10px',
  },
  documentHeaderMetadata: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  documentMetadataItem: {
    margin: '0 40px 24px 0',
  },
  borderlessBtn: {
    border: 'none',
    cursor: 'pointer',
  },
  metadataHeader: {
    margin: '0 0 8px 0',
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
  },
  metadataContent: {
    display: 'inline-block',
    '& span': {
      color: black2,
      fontSize: '15px',
    },
    '& svg': {
      marginRight: '8px',
    },
  },
  metadataSeparator: {
    display: 'inline-block',
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    margin: '0 8px 2px',
    backgroundColor: black4,
  },
  emptyMetadataHeader: {
    width: '48px',
    height: '16px',
    marginBottom: '8px',
    backgroundColor: '#f0f0f0',
  },
  emptyMetadataContent: {
    width: '112px',
    height: '20px',
    backgroundColor: '#f0f0f0',
  },
  partiesShowAll: {
    display: 'inline-block',
    color: evisortBlue,
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '13px',
    fontWeight: bold,
    cursor: 'pointer',
  },
  statusWrapper: {
    marginBottom: '10px',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'normal',
  },
  statusName: {
    marginLeft: '4px',
    color: black2,
    fontSize: '13px',
  },
  statusProgress: {
    fontStyle: 'italic',
  },
  algoStatusHeader: {
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  uploadStatusTooltip: {
    display: 'flex',
    whiteSpace: 'normal',
    lineHeight: '26px',
    '& svg': {
      marginTop: '3px',
    },
  },
  algoName: {
    fontSize: '14px',
    fontWeight: bold,
  },
  statusMessage: {
    marginLeft: '21px',
    fontSize: '10px',
  },
  statusErrorMessage: {
    marginLeft: '21px',
    color: evisortRed,
    fontSize: '10px',
    fontWeight: semibold,
  },
  algoStatusButton: {
    minWidth: '120px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  algoStatusGreenDot: {
    ...algoStatusDot,
    backgroundColor: '#61a028',
  },
  algoStatusRedDot: {
    ...algoStatusDot,
    backgroundColor: evisortRed,
  },
  algoStatusYellowDot: {
    ...algoStatusDot,
    backgroundColor: evisortYellow,
  },
  algoStatusBlueDot: {
    ...algoStatusDot,
    backgroundColor: evisortBlue,
  },
  algoStatusIndicatorBarWrapper: {
    marginTop: '8px',
    display: 'flex',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  algoStatusIndicatorBarGreen: {
    ...algoStatusIndicatorBar,
    backgroundColor: '#61a028',
  },
  algoStatusIndicatorBarYellow: {
    ...algoStatusIndicatorBar,
    backgroundColor: evisortYellow,
  },
  algoStatusIndicatorBarBlue: {
    ...algoStatusIndicatorBar,
    backgroundColor: evisortBlue,
  },
  algoStatusIndicatorBarRed: {
    ...algoStatusIndicatorBar,
    backgroundColor: evisortRed,
  },
};

export default styles;
