import { IPairsKeyValueToFillTemplate } from '~/constants/codedErrors';
import { CodedErrorCodeType } from '~/enums';
import { ApiError } from '~/types';
import { getCodedError, getCustomError } from '~/utils/codedError';

const getDetailFromApiError = (err: ApiError) => {
  let detail = err.response?.data?.detail;
  if (Array.isArray(detail)) {
    detail = detail.reduce(
      (concatenated, currentToken) => `${concatenated}, ${currentToken}`,
    );
  }
  return detail;
};

const getErrorCodeFromApiError = (err: ApiError) => err.response?.data?.code;

const getCustomErrorMessageFromApiError = (err: ApiError) =>
  err.response?.data?.error;

export const parseApiError = (
  err: any,
  customErrorCode: CodedErrorCodeType,
  pairsKeyValueToFillTemplate: IPairsKeyValueToFillTemplate = {},
) => {
  const code = getErrorCodeFromApiError(err);
  if (code) {
    const detail = getDetailFromApiError(err);
    if (detail) {
      return getCodedError(code, { detail });
    }
    return getCodedError(code);
  } else {
    const responseCustomErrorMessage = getCustomErrorMessageFromApiError(err);
    if (responseCustomErrorMessage) {
      return getCustomError(responseCustomErrorMessage);
    }
    return getCodedError(customErrorCode, pairsKeyValueToFillTemplate);
  }
};
