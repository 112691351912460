import { createActions } from '../utils/redux';

export const {
  intakeFormSet,
  intakeFormUpdateFieldOptions,
  intakeFormUpdateFieldValue,
  intakeFormUpdateImplicitField,
  intakeFormValidate,
} = createActions('intakeForm', [
  'set',
  'updateFieldOptions',
  'updateFieldValue',
  'updateImplicitField',
  'validate',
]);
