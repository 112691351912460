import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { CKEditor } from '~/components/Shared/CKEditor4';

import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import styles from './CKEditorModal.styles';

class CKEditorModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnSaveClick = this.handleOnSaveClick.bind(this);

    this.state = { tableContent: this.props.tableContent };
  }

  handleOnSaveClick() {
    const { tableContent } = this.state;

    this.props.hideModal();
    this.props.handleActionClick(tableContent);
  }

  handleContentChange() {
    return (editor) => {
      const newTableContent = editor.getData();
      this.setState({ tableContent: newTableContent });
    };
  }

  setEditorConfig() {
    const {
      allowRowChanges,
      allowColumnChanges,
      allowCellChanges,
    } = this.props;
    const editorConfig = {
      toolbar: ['bold', 'italic', '|', 'undo', 'redo'],
      table: {},
    };

    const tableConfig = [];
    if (allowRowChanges) tableConfig.push('tableRow');
    if (allowColumnChanges) tableConfig.push('tableColumn');
    if (allowCellChanges) tableConfig.push('mergeTableCells');

    editorConfig.table.contentToolbar = tableConfig;

    return editorConfig;
  }

  render() {
    const { classes, hideModal } = this.props;
    const { tableContent } = this.state;

    return (
      <EcModalCard
        title="Edit Table"
        content={
          <div className={classes.modalCkeditorContent}>
            <CKEditor
              config={this.setEditorConfig()}
              data={tableContent}
              onChange={this.handleContentChange()}
            />
          </div>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              yellow
              text="Save Table"
              onClick={this.handleOnSaveClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

CKEditorModal.propTypes = {
  classes: PropTypes.object.isRequired,
  tableContent: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  allowRowChanges: PropTypes.bool.isRequired,
  allowColumnChanges: PropTypes.bool.isRequired,
  allowCellChanges: PropTypes.bool.isRequired,
};

export default injectSheet(styles)(CKEditorModal);
