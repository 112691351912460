import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '~/eds';

/* eslint-enable import/no-internal-modules */
import { chatbotSlice } from '~/redux/slices/chatbot';
import { Entity, EntityType } from '~/types';

import { selectChatbotVisible } from './selectors';
import { useResolveChatBotEntity } from './useResolveChatBotEntity';

export const AskAnythingButton = () => {
  const dispatch = useDispatch();
  const chatbotData = useResolveChatBotEntity();
  const isChatBotVisible = useSelector(selectChatbotVisible);

  const handleToggleChatbot = () => {
    dispatch(chatbotSlice.actions.toggleChatbot());
  };

  useEffect(() => {
    if (isChatBotVisible) {
      dispatch(
        chatbotSlice.actions.setEntity(
          chatbotData.entity as Entity<EntityType>,
        ),
      );
      if (chatbotData.context) {
        dispatch(chatbotSlice.actions.setContext(chatbotData.context));
      }
    }
  }, [isChatBotVisible]);

  useEffect(() => {
    if (chatbotData.context) {
      dispatch(chatbotSlice.actions.setContext(chatbotData.context));
    }
  }, [chatbotData]);

  return (
    <Button
      iconPosition="left"
      preset="cta-ai"
      text="Ask AI"
      disabled={chatbotData.isEntityLoading}
      onClick={handleToggleChatbot}
    />
  );
};
