export default `
<svg
  aria-hidden="true"
  aria-label="alert"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M13.0156 5V5.5625C15.2656 5.9375 17.0156 7.90625 17.0156 10.25V11.3125C17.0156 12.7188 17.4844 14.0938 18.3594 15.2188L18.8281 15.7812C19.0156 16.0312 19.0469 16.3438 18.9219 16.5938C18.7969 16.8438 18.5469 17 18.2656 17H5.76561C5.45311 17 5.20311 16.8438 5.07811 16.5938C4.95311 16.3438 4.98436 16.0312 5.17186 15.7812L5.64061 15.2188C6.51561 14.0938 7.01561 12.7188 7.01561 11.3125V10.25C7.01561 7.90625 8.73436 5.9375 11.0156 5.5625V5C11.0156 4.46875 11.4531 4 12.0156 4C12.5469 4 13.0156 4.46875 13.0156 5ZM11.7656 7C9.95311 7 8.51561 8.46875 8.51561 10.25V11.3125C8.51561 12.8125 8.07811 14.25 7.26561 15.5H16.7344C15.9219 14.25 15.5156 12.8125 15.5156 11.3125V10.25C15.5156 8.46875 14.0469 7 12.2656 7H11.7656ZM14.0156 18C14.0156 18.5312 13.7969 19.0625 13.4219 19.4375C13.0469 19.8125 12.5156 20 12.0156 20C11.4844 20 10.9531 19.8125 10.5781 19.4375C10.2031 19.0625 10.0156 18.5312 10.0156 18H14.0156Z"
    fill="currentcolor"
  />
</svg>
`;
