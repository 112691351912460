import React from 'react';

import { Box, Layout, MarkedText, Text } from '~/eds';
import { FormSectionState } from '~/redux/selectors/intakeForm';

import { WorkflowQuestion } from './WorkflowQuestion';

interface Props {
  isEditing: boolean;
  search: string;
  section: FormSectionState;
}

export const WorkflowFormSection = ({ isEditing, search, section }: Props) => {
  const fields = section.questions.map((q) => (
    <WorkflowQuestion key={q} id={q} isEditing={isEditing} search={search} />
  ));
  return (
    <Layout direction="column" id={section.name} spacing={2}>
      <Box pb={2}>
        <Text preset="description">
          <MarkedText search={search} text={section.description} />
        </Text>
      </Box>
      {fields}
    </Layout>
  );
};
