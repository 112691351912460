import { DEFAULT_OPTIONS } from './constants';
import { Column, Mode, Options, RowAction, RowDetailsAction } from './types';

export const getTableCaption = ({
  columns,
  name,
  totalCount,
}: {
  columns: Column[];
  name: string;
  totalCount: number;
}) => {
  const columnsLength = columns.length;

  const sortableColumnsIndex = columns.reduce((acc, column, index) => {
    if (!column.disableSortBy) {
      acc.push(index + 1); // index is 1-based
    }
    return acc;
  }, []);

  const sortableColumnsText =
    sortableColumnsIndex.length === 0
      ? 'none'
      : sortableColumnsIndex.length === columnsLength
      ? 'all'
      : sortableColumnsIndex;

  return [
    `"${name}" table with ${totalCount} rows`,
    `${columnsLength} columns`,
    `sortable columns (${sortableColumnsText}).`,
  ].join(', ');
};

export const testIsNonPrimaryColumn = (primaryColumnKey?: string) => (
  column: Column,
) => column.key !== primaryColumnKey;

export const prependShowDetailsToRowActions = (
  rowActions: RowAction[] = [],
  rowDetailsOnClick?: RowDetailsAction['onClick'],
) =>
  rowDetailsOnClick
    ? [
        {
          label: 'View details',
          onClick: rowDetailsOnClick,
        },
        ...rowActions,
      ]
    : rowActions;

export const resolveOptions = (
  options: Options,
  config?: { mode: Mode },
): Options => {
  const resolvedOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  if (config?.mode === 'summary') {
    return {
      ...resolvedOptions,
      enableColumnHeaders: false,
      enableControls: false,
      enableExportXlsx: false,
      enablePageSizeSelect: false,
      enablePagination: false,
      enableManageColumns: false,
      enableSearch: false,
      enableSelectRows: false,
    };
  } else {
    return resolvedOptions;
  }
};
