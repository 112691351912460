import React, { memo, useEffect, useMemo, useState } from 'react';

import { ButtonGroup, Popover, types as Types } from '~/eds';
import { Nullable } from '~/types';

export type ContextMenuType = {
  icon: Types.IconType;
  value: string;
  text?: string;
  tooltip?: string;
  component?: JSX.Element;
  hidden?: boolean;
  onClick?: () => void;
};

interface Props {
  options?: ContextMenuType[];
  onOpen: () => void;
}

const ContextMenu = memo(({ options = [], onOpen }: Props) => {
  /**
   * This state is used to determine the visibility of the nested `Popover`s in `ContextMenu`.
   * - `null`: controls the initial visibility of the `Popover`.
   * - `-1`: indicates that the outer `Popover` is explicitly hidden.
   * - `n`: a valid index informs which nested `Popover` is visible.
   */
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<
    Nullable<number>
  >(null);

  useEffect(() => {
    return () => {
      window.getSelection()?.empty();
    };
  }, []);

  const visibleOptions = useMemo(
    () => options.filter((option) => !option.hidden),
    [options],
  );

  return (
    <>
      <Popover
        preset="dropdown"
        position="absolute"
        isVisible={!selectedOptionIndex && selectedOptionIndex !== 0}
        // hide when clicked outside
        onClickOutside={() => setSelectedOptionIndex(-1)}
      >
        <ButtonGroup
          actions={visibleOptions.map(
            ({ icon, text = '', tooltip, onClick }, index) => ({
              text: text ?? '',
              icon,
              mode: text ? undefined : 'icon',
              status: index === selectedOptionIndex ? 'active' : undefined,
              tooltip: tooltip ?? text,
              onClick: () => {
                onOpen();
                onClick?.();
                setSelectedOptionIndex(index);
              },
            }),
          )}
        />
      </Popover>
      {visibleOptions.map(({ component, hidden, value }, index) => {
        return !hidden && component ? (
          <Popover
            key={value}
            isVisible={index === selectedOptionIndex}
            position="absolute"
            maxWidth="none"
          >
            {component}
          </Popover>
        ) : null;
      })}
    </>
  );
});

export default ContextMenu;
