import React, { useEffect } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { TableViewContextType } from '~/enums';
import { api } from '~/redux';
import { CreateTableViewSet } from '~/redux/api/methods';
import { MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';

interface Props {
  context: TableViewContextType | undefined;
  fields: CreateTableViewSet;
  hideModal: () => void;
  modalType: string;
}

const BaseSaveView = ({ context, fields, hideModal, modalType }: Props) => {
  const [
    createTableView,
    createTableViewResult,
  ] = api.endpoints.createTableView.useMutation();
  const {
    isError: createTableViewError,
    isSuccess: createTableViewSuccess,
    isLoading,
    data,
  } = createTableViewResult;

  useEffect(() => {
    if (createTableViewError) {
      showToast(ERROR, 'Save table view failed. Please try again.');
      hideModal();
    }
  }, [createTableViewError]);

  useEffect(() => {
    if (createTableViewSuccess) {
      showToast(SUCCESS, 'Table View has been successfully saved.');
      trackSegment('Saving table view', data);
      hideModal();
    }
  }, [createTableViewSuccess]);

  const handleSave = (name: string) => {
    createTableView({ context, name, fields });
  };

  if (modalType !== MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE) return null;

  return (
    <EcModal
      modalType={modalType}
      width="800px"
      title="Save View"
      hideModal={hideModal}
      handleSaveView={handleSave}
      isLoading={isLoading}
    />
  );
};

export default BaseSaveView;
