import { types as edsTypes } from '~/eds';
import { PromptModelTestCase } from '~/features/x-ray';
import { Nullable, Uuid } from '~/types';

import { updatePromptModelTestCase } from './updatePromptModelTestCase';

interface Request {
  modelId: Uuid;
  version: number;
  testCaseNumber: number;
  outcome: PromptModelTestCase['outcome'];
  feedback?: Nullable<string>;
  goldValue?: PromptModelTestCase['goldValue'];
  fallbackGoldValue?: PromptModelTestCase['goldValue'];
}

type Response = {
  updatedTestCase?: PromptModelTestCase;
  responseToast?: { message: string; status: edsTypes.StatusType };
};

export const tagAnswerForPromptModelTestCase = async ({
  modelId,
  version,
  testCaseNumber,
  outcome,
  feedback,
  goldValue,
  fallbackGoldValue,
}: Request): Promise<Response> => {
  try {
    const response = await updatePromptModelTestCase({
      modelId,
      version,
      testCaseNumber,
      outcome: 'incorrect',
      feedback,
      goldValue,
    });
    return {
      updatedTestCase: response,
      responseToast: { message: 'Correct answer updated.', status: 'success' },
    };
  } catch (error: any) {
    if (error.response?.status === 422) {
      // validation failed, so try to save it as a source,
      // reverting to the original outcome and fallback gold value
      try {
        const response = await updatePromptModelTestCase({
          modelId,
          version,
          testCaseNumber,
          outcome,
          feedback,
          goldValue: fallbackGoldValue,
        });
        return {
          updatedTestCase: response,
          responseToast: {
            message:
              'The selection does not match the field type and has been saved as a source.',
            status: 'warning',
          },
        };
      } catch (error) {
        return {
          responseToast: {
            message:
              'The selection does not match the field type and was not saved as a source.',
            status: 'danger',
          },
        };
      }
    }
    return {};
  }
};
