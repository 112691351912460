export default `
<svg
  aria-hidden="true"
  aria-label="format-italic"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.9346 5.7832C18.9346 6.2207 18.5908 6.5332 18.1846 6.5332H16.0596L11.4658 17.5332H14.1846C14.5908 17.5332 14.9346 17.877 14.9346 18.2832C14.9346 18.7207 14.5908 19.0332 14.1846 19.0332H7.68457C7.24707 19.0332 6.93457 18.7207 6.93457 18.2832C6.93457 17.877 7.24707 17.5332 7.68457 17.5332H9.87207L14.4658 6.5332H11.6846C11.2471 6.5332 10.9346 6.2207 10.9346 5.7832C10.9346 5.37695 11.2471 5.0332 11.6846 5.0332H18.1846C18.5908 5.0332 18.9346 5.37695 18.9346 5.7832Z"
    fill="currentcolor"
  />
</svg>
`;
