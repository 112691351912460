export enum HttpStatusCodeType {
  Ok = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Gone = 410,
  UnprocessableContent = 422,
  Locked = 423,
  TooManyRequests = 429,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500,
}
