import { black1, black2, bold } from '../../../assets/shared-styles/general';

const styles = {
  clientTable: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    color: black1,
    textAlign: 'left',
    '& th': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '16px 0',
      color: black2,
      fontSize: '15px',
      fontWeight: bold,
    },
    '& td': {
      padding: '12px 20px 12px 0',
      color: black2,
      fontSize: '15px',
      wordBreak: 'break-word',
    },
    '& tr': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      '&:last-child': {
        border: 0,
      },
    },
  },
  headerExtraOptionsColumn: { width: '135px' },
};

export default styles;
