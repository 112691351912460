import { types } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { SavedSearch } from '~/features/search';
import {
  canUseAutomation,
  canUseManagedEntities,
  checkIsInternalSuperAdmin,
  checkIsRbacAdmin,
  checkIsWorkflowOnly,
} from '~/permissions';
import { DashboardAndPathname } from '~/redux/api/methods/dashboardV2';
import { RoutePathType } from '~/routing';
import { DashboardTab, User } from '~/types';

import { featureFlagIncluded } from './user';

export const createNavlinks = (
  currentUser: User,
  onNavLinkClick: (pathname: string) => void,
  dashboards?: DashboardTab[],
  activeSearch?: SavedSearch,
  hasAlertsInSearchSubNav?: boolean,
  hasClauseLibrary?: Boolean,
  hasDashboardV2?: boolean,
  enableFieldAi?: boolean,
  activeDashboard?: DashboardAndPathname,
  permissions: Record<string, boolean> = {
    folders: true,
    documents: true,
    documentGroups: true,
    workflowTickets: true,
    workflowBuilder: true,
    clauseLibrary: true,
    automation: true,
    documentXRay: true,
    documentsDashboard: true,
    workflowsDashboard: true,
    managedEntityParties: true,
  },
) => {
  const navLinks: types.NavLink[] = [];
  if (checkIsWorkflowOnly(currentUser)) {
    if (featureFlagIncluded(currentUser, 'WORKFLOW_DEMO')) {
      return [
        {
          label: 'Workflow',
          pathname: RoutePathType.WorkflowTickets,
          children: [],
        },
      ];
    }
    return [];
  }

  if (featureFlagIncluded(currentUser, 'REVIEWER')) {
    navLinks.push({
      label: 'Reviewer',
      pathname: RoutePathType.Reviewer,
      children: [],
    });
  } else {
    if (permissions['documents'] || permissions['documentGroups']) {
      navLinks.push({
        label: 'Documents',
        pathname: permissions['documents']
          ? RoutePathType.Documents
          : RoutePathType.DocumentGroups,
        children:
          permissions['documents'] && permissions['documentGroups']
            ? createSubNavLinks(currentUser, RoutePathType.Documents)
            : [],
      });
    }

    // temporary, should not be a top level navlink
    // incorporate with Documents
    if (permissions['managedEntityParties']) {
      if (canUseManagedEntities(currentUser)) {
        navLinks.push({
          label: 'Parties',
          pathname: RoutePathType.ManagedEntities,
        });
      }
    }

    if (permissions['documents']) {
      if (featureFlagIncluded(currentUser, FeatureFlagType.SearchV2)) {
        navLinks.push({
          label: 'Search',
          pathname: RoutePathType.Search,
          children: createSearchSubNavLinks({
            activeSearch,
            hasAlertsInSearchSubNav,
            onNavLinkClick,
          }),
          onClick: () => onNavLinkClick(RoutePathType.Search),
        });
      } else {
        navLinks.push({
          label: 'Analyzer',
          pathname: RoutePathType.Analyzer,
          children: [],
        });
      }
    }

    if (
      permissions['documentsDashboard'] ||
      permissions['workflowsDashboard']
    ) {
      navLinks.push({
        label: 'Dashboards',
        pathname: '/dashboards',
        children: createDashboardsSubNavLinks(
          currentUser,
          RoutePathType.Dashboards,
          dashboards,
          onNavLinkClick,
          hasDashboardV2,
          activeDashboard,
        ),
      });
    }
    if (permissions['workflowTickets'] || permissions['workflowBuilder']) {
      if (featureFlagIncluded(currentUser, 'WORKFLOW_DEMO')) {
        const hasAllWorkflowPermissions =
          permissions['workflowTickets'] && permissions['workflowBuilder'];
        navLinks.push({
          label: 'Workflow',
          pathname: permissions['workflowTickets']
            ? RoutePathType.WorkflowTickets
            : RoutePathType.WorkflowBuilder,
          children: hasAllWorkflowPermissions
            ? createSubNavLinks(currentUser, RoutePathType.WorkflowTickets)
            : [],
        });
      }
    }
    if (permissions['documents']) {
      if (!hasAlertsInSearchSubNav) {
        navLinks.push({
          label: 'Alerts',
          pathname: RoutePathType.Alerts,
          children: [],
        });
      }
    }

    if (permissions['clauseLibrary']) {
      if (hasClauseLibrary) {
        navLinks.push({
          label: 'Library',
          pathname: '/clause-library',
          children: [],
        });
      }
    }

    if (permissions['documents']) {
      if (featureFlagIncluded(currentUser, 'UPLOAD')) {
        navLinks.push({
          label: 'Upload',
          pathname: RoutePathType.Upload,
          children: [],
        });
      }
    }

    if (permissions['automation'] || permissions['documentXRay']) {
      const hasAllAutomationPermissions =
        permissions['automation'] && permissions['documentXRay'];
      if (canUseAutomation(currentUser)) {
        navLinks.push({
          label: 'Automation',
          pathname: permissions['automation']
            ? RoutePathType.AutomationHub
            : RoutePathType.AutomationHubFields,
          children: hasAllAutomationPermissions
            ? createAutomationSubNavLinks(permissions)
            : [],
        });
      }
    }

    if (checkIsInternalSuperAdmin(currentUser)) {
      navLinks.push({
        label: 'Admin',
        pathname: `/admin/console/${currentUser.client}`,
        children: [],
      });
      navLinks.push({
        label: 'Reviewer',
        pathname: RoutePathType.Reviewer,
        children: [],
      });
    } else if (
      featureFlagIncluded(currentUser, 'ADMIN') ||
      checkIsRbacAdmin(currentUser)
    ) {
      navLinks.push({
        label: 'Admin',
        pathname: `/admin/console/${currentUser.client}`,
        children: [],
      });
    }
  }

  return navLinks;
};

// TODO: deprecate this method for dedicated create*SubNavLinks methods, or completely rehaul how navlinks are defined
export const createSubNavLinks = (
  currentUser: User,
  pathname: string,
): types.NavLink[] => {
  if (shouldShowWorkflowSubNavbar(currentUser, pathname)) {
    return [
      {
        label: 'Tickets',
        pathname: RoutePathType.WorkflowTickets,
        activePatterns: ['^/workflow/tickets/.+'],
      },
      {
        label: 'Workflow Builder',
        pathname: RoutePathType.WorkflowBuilder,
      },
    ];
  }

  if (shouldShowDocumentSubNavBar(currentUser, pathname)) {
    return [
      {
        label: 'All Documents',
        pathname: RoutePathType.Documents,
        activePatterns: ['^/documents/\\d+'],
      },
      {
        label: 'Groups',
        pathname: RoutePathType.DocumentGroups,
        activePatterns: ['^/document-group/\\d+'],
      },
    ];
  }

  return [];
};

export const createAutomationSubNavLinks = (
  permissions: Record<string, boolean>,
) => {
  const subnavLinks = [];
  if (permissions['automation']) {
    subnavLinks.push({
      label: 'Clauses',
      pathname: RoutePathType.AutomationHub,
    });
  }
  if (permissions['documentXRay']) {
    subnavLinks.push({
      label: 'Fields',
      pathname: RoutePathType.AutomationHubFields,
    });
  }
  return subnavLinks;
};

export const createSearchSubNavLinks = ({
  activeSearch,
  hasAlertsInSearchSubNav,
  onNavLinkClick,
}: {
  activeSearch?: SavedSearch;
  hasAlertsInSearchSubNav?: boolean;
  onNavLinkClick?: (pathname: string) => void;
}): types.NavLink[] => {
  const searchSubNavs: types.NavLink[] = [
    {
      label: 'Search',
      pathname: RoutePathType.Search,
      onClick: () => onNavLinkClick?.(RoutePathType.Search),
    },
    {
      label: 'Analyzer',
      pathname: RoutePathType.Analyzer,
      onClick: () => onNavLinkClick?.(RoutePathType.Analyzer),
    },
    {
      label: 'Saved Searches',
      pathname: RoutePathType.SavedSearches,
      onClick: () => onNavLinkClick?.(RoutePathType.SavedSearches),
    },
  ];

  if (activeSearch) {
    searchSubNavs.push({
      label: activeSearch.name,
      onClick: () =>
        onNavLinkClick?.(
          RoutePathType.SavedSearch.replace(':searchId', `${activeSearch.id}`),
        ),
      pathname: RoutePathType.SavedSearch.replace(
        ':searchId',
        `${activeSearch.id}`,
      ),
    });
  }

  if (hasAlertsInSearchSubNav) {
    searchSubNavs.push({ label: 'Alerts', pathname: RoutePathType.Alerts });
  }
  return searchSubNavs;
};

export const createDashboardsSubNavLinks = (
  currentUser: User,
  pathname: string,
  dashboards?: any[],
  onNavLinkClick?: (pathname: string) => void,
  hasDashboardV2?: boolean,
  activeDashboard?: DashboardAndPathname,
): types.NavLink[] => {
  const dashboardSubNavs = (dashboards || []).map((link: DashboardTab) => {
    const pathname = link.is_default
      ? `/dashboards/${link.id}`
      : `/dashboards/${link.type}`;

    return {
      label: link.name,
      onClick: () => onNavLinkClick?.(pathname),
      pathname,
    };
  });

  if (hasDashboardV2) {
    dashboardSubNavs.push({
      label: 'Saved Dashboards',
      onClick: () => onNavLinkClick?.(RoutePathType.SavedDashboards),
      pathname: RoutePathType.SavedDashboards,
    });
  }
  if (
    activeDashboard &&
    activeDashboard.name &&
    shouldShowSavedDashboardLink(activeDashboard)
  ) {
    dashboardSubNavs.push({
      label: activeDashboard.name,
      onClick: () => onNavLinkClick?.(activeDashboard.pathname),
      pathname: activeDashboard.pathname,
    });
  }
  return dashboardSubNavs as types.NavLink[];
};

const lookerDashboardPagePattern = /(documents|expirations|tickets|workflows)/g;

export const shouldShowWorkflowSubNavbar = (
  currentUser: User,
  pathname: string,
) =>
  pathname.startsWith('/workflow') &&
  (pathname.includes(RoutePathType.WorkflowBuilder) ||
    pathname.includes(RoutePathType.WorkflowTickets)) &&
  featureFlagIncluded(currentUser, 'WORKFLOW_DEMO') &&
  currentUser['is_workflow_admin'];

const shouldShowSavedDashboardLink = (activeDashboard: DashboardAndPathname) =>
  activeDashboard?.pathname.includes('dashboards/') &&
  !activeDashboard?.pathname.match(lookerDashboardPagePattern) &&
  !activeDashboard.is_default;

export const shouldShowDocumentSubNavBar = (
  currentUser: User,
  pathname: string,
) =>
  !featureFlagIncluded(currentUser, 'WORKFLOW') &&
  (pathname.startsWith(RoutePathType.Documents) ||
    pathname.startsWith(RoutePathType.DocumentGroups));

export const isSearchPathname = (pathname: string) =>
  pathname.startsWith(RoutePathType.Search) ||
  pathname.startsWith(RoutePathType.Analyzer) ||
  pathname.startsWith(RoutePathType.SavedSearches) ||
  pathname.startsWith(RoutePathType.DownloadExportReportPage);

export const shouldShowSearchSubNavbar = (
  currentUser: User,
  pathname: string,
) =>
  featureFlagIncluded(currentUser, FeatureFlagType.SearchV2) &&
  isSearchPathname(pathname);
