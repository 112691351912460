/**
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Event/stopPropagation
 **/
interface StoppableEvent {
  stopPropagation: () => void;
}
export const stopPropagation = (event: StoppableEvent) => {
  event.stopPropagation();
};

/**
 * This util is useful if we wish to blur immediately after a click event.
 *
 * We use `currentTarget` to ensure that we receive the target that the click event is attached to (and avoid receiving the wrong event target from event bubbling).
 */
export const blurOnClick = (event: MouseEvent) => {
  if (event.currentTarget instanceof HTMLElement) {
    event.currentTarget.blur();
  }
};
