export enum TagType {
  Acl = 'Acl',
  AlgorithmMetrics = 'algorithmMetrics',
  ApiKey = 'ApiKey',
  Automation = 'Automation',
  ClauseLibrary = 'ClauseLibrary',
  ClauseLibraryV2 = 'ClauseLibraryV2',
  CodeCash = 'CodeCash',
  Comment = 'Comment',
  ConversationalAi = 'ConversationalAi',
  DashboardV2 = 'DashboardV2',
  Department = 'Department',
  Document = 'Document',
  DocumentRestore = 'DocumentRestore',
  Fields = 'Fields',
  Groups = 'Groups',
  Highlight = 'Highlight',
  Parties = 'Parties',
  PromptModel = 'PromptModel',
  PromptModelProgress = 'PromptModelProgress',
  PromptModelResult = 'PromptModelResult',
  Provisions = 'Provisions',
  Question = 'Question',
  QuestionGroup = 'QuestionGroup',
  Roles = 'Roles',
  SaveSearch = 'SaveSearch',
  SearchV3 = 'SearchV3',
  SmartClause = 'SmartClause',
  Sylvanus = 'Sylvanus',
  TableViews = 'TableViews',
  Ticket = 'Ticket',
  TurnTracking = 'TurnTracking',
  UploadFiles = 'UploadFiles',
  UserPreferences = 'UserPreferences',
  Users = 'Users',
  Webhooks = 'Webhooks',
  Workflows = 'Workflows',
}
