import PT from 'prop-types';
import React from 'react';

import { Button } from '~/eds';
import { WorkflowPublishActionType } from '~/enums';
import { Tooltip } from '~/ui';

function PublishAction({ isDraft, isPublished, canPublish, onClick }) {
  const publishAction = (
    <Tooltip
      content={
        canPublish
          ? null
          : 'Your workflow draft requires fixes before it can be published'
      }
    >
      <Button
        text={isDraft && isPublished ? 'Publish Latest Draft' : 'Publish'}
        disabled={!canPublish}
        variant={canPublish ? 'action' : 'tertiary'}
        onClick={() => onClick(WorkflowPublishActionType.Publish)}
      />
    </Tooltip>
  );

  return (
    <>
      {isPublished ? (
        <>
          {isDraft && publishAction}
          <Button
            text="Unpublish"
            variant="action"
            onClick={() => onClick(WorkflowPublishActionType.Unpublish)}
          />
        </>
      ) : (
        publishAction
      )}
    </>
  );
}

PublishAction.propTypes = {
  canPublish: PT.bool.isRequired,
  /** If workflow is a draft */
  isDraft: PT.bool.isRequired,
  /** If workflow is published */
  isPublished: PT.bool.isRequired,
  /** Handler on action click */
  onClick: PT.func.isRequired,
};

export default PublishAction;
