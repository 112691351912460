export default `
<svg
  aria-hidden="true"
  aria-label="rerun"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M5.5 4.75C5.90625 4.75 6.25 5.09375 6.25 5.5V8.71875C7.46875 6.625 9.75 5.28125 12.25 5.28125C16.0938 5.28125 19.25 8.40625 19.25 12.25C19.25 16.125 16.0938 19.25 12.25 19.25C10.5938 19.25 9 18.6875 7.75 17.6562C7.4375 17.375 7.40625 16.9062 7.65625 16.5938C7.9375 16.2812 8.40625 16.2188 8.71875 16.5C9.6875 17.3125 10.9375 17.75 12.25 17.75C15.2812 17.75 17.75 15.3125 17.75 12.25C17.75 9.21875 15.2812 6.75 12.25 6.75C10.1562 6.75 8.28125 7.96875 7.34375 9.75H10.5C10.9062 9.75 11.25 10.0938 11.25 10.5C11.25 10.9375 10.9062 11.25 10.5 11.25H5.5C5.0625 11.25 4.75 10.9375 4.75 10.5V5.5C4.75 5.09375 5.0625 4.75 5.5 4.75Z"
    fill="currentcolor"
  />
</svg>
`;
