import {
  Actions,
  formatDate,
  getUserName,
  Layout,
  Markdown,
  Tooltip,
  types,
  User,
} from '~/eds';

import { Message as MessageType } from '../types';

interface Props {
  message: MessageType;
  actions?: types.UserAction[];
  user: types.User;
}

export const UserMessage = ({ actions, message, user }: Props) => {
  const { text, timestamp } = message;
  const tooltip = `${getUserName(user)}\n${formatDate(timestamp, 'full')}`;
  return (
    <Layout justify="space-between" align="center">
      <Layout align="center" spacing={4}>
        <Layout alignSelf="flex-start">
          <Tooltip tooltip={tooltip} placement="left">
            <User
              mode="avatar"
              user={user}
              options={{ disableTooltip: true }}
            />
          </Tooltip>
        </Layout>
        <Markdown variant="body-medium" text={text} />
      </Layout>

      <Layout alignSelf="flex-start">
        {actions && <Actions actions={actions} />}
      </Layout>
    </Layout>
  );
};
