import { semibold } from '~/assets/shared-styles/general';

const styles = {
  container: {
    margin: '0 auto',
    width: 'fit-content',
    minWidth: '100%',
    textAlign: 'center',
  },
  logoContainer: {
    borderBottom: '1px solid #ccc',
  },
  logoImg: {
    margin: '20px',
    width: '230px',
    height: 'auto',
  },
  contentContainer: {
    margin: '0 auto',
    width: '480px',
    textAlign: 'center',
  },
  title: {
    margin: '60px 0 30px',
    fontSize: '22px',
    fontWeight: semibold,
    textAlign: 'center',
  },
};

export default styles;
