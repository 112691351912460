import React, { useMemo } from 'react';

import { Actions, Layout, Paginate } from '../../../components';
import { BasePaginateProps, UserAction } from '../../../types';
import { Format } from '../types';

interface Props {
  actions: UserAction[];
  enableFormat: boolean;
  formats: Format[];
  onMention: () => void;
  onToggleEnableFormat: () => void;
  enableMention?: boolean;
  paginate?: BasePaginateProps;
}

export const ActionsBar = ({
  actions: customActions,
  enableFormat,
  enableMention = true,
  formats,
  paginate,
  onMention,
  onToggleEnableFormat,
}: Props) => {
  const actions = useMemo(() => {
    if (customActions.length > 0) {
      return customActions;
    }

    const actions: UserAction[] = [];

    if (enableMention) {
      actions.push({
        icon: 'mention',
        mode: 'icon',
        tooltip: 'Mention someone',
        text: 'Mention',
        onClick: onMention,
      });
    }

    if (formats.length > 0) {
      actions.push({
        icon: 'highlight',
        mode: 'icon',
        status: 'active',
        text: 'Format',
        tooltip: enableFormat ? 'Hide formatting' : 'Show formatting',
        onClick: onToggleEnableFormat,
      });
    }

    return actions;
  }, [customActions, enableFormat, formats]);

  return (
    <Layout align="center" justify="space-between" spacing={2}>
      <Actions actions={actions} size="s" />
      {paginate && (
        <Paginate
          {...paginate}
          disableGoTo
          mode="count"
          name="text-editor-paginate"
        />
      )}
    </Layout>
  );
};
