// TODO EKP-2163= consolidate data field types
export enum SsoFieldType {
  Attributes = 'ATTRIBUTES',
  LongResponse = 'LONG_RESPONSE',
  MultiSelect = 'MULTI_SELECT',
  ShortResponse = 'SHORT_RESPONSE',
  SingleSelect = 'SINGLE_SELECT',
  Static = 'STATIC',
  Toggle = 'TOGGLE',
  RoleSelect = 'ROLE_SELECT',
}
