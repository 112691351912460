export default `
<svg
  aria-hidden="true"
  aria-label="status-reject"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12.8691 20.0044C17.2754 20.0044 20.8691 16.4419 20.8691 12.0044C20.8691 7.59814 17.2754 4.00439 12.8691 4.00439C8.43164 4.00439 4.86914 7.59814 4.86914 12.0044C4.86914 16.4419 8.43164 20.0044 12.8691 20.0044ZM10.3379 9.47314C10.6191 9.19189 11.0879 9.19189 11.3691 9.47314L12.8379 10.9419L14.3066 9.47314C14.6191 9.19189 15.0879 9.19189 15.3691 9.47314C15.6816 9.78564 15.6816 10.2544 15.3691 10.5356L13.9004 12.0044L15.3691 13.4731C15.6816 13.7856 15.6816 14.2544 15.3691 14.5356C15.0879 14.8481 14.6191 14.8481 14.3066 14.5356L12.8379 13.0669L11.3691 14.5356C11.0879 14.8481 10.6191 14.8481 10.3379 14.5356C10.0254 14.2544 10.0254 13.7856 10.3379 13.4731L11.8066 12.0044L10.3379 10.5356C10.0254 10.2544 10.0254 9.78564 10.3379 9.47314Z"
    fill="currentcolor"
  />
</svg>
`;
