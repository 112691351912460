import PT from 'prop-types';
import React from 'react';

import { Checkbox, FlexLayout, Text } from '~/ui';

function CheckboxGroup({
  disabled = false,
  label,
  enableMasterCheckbox,
  options = [],
  values = [],
  onChange,
}) {
  function handleUpdateValues(activeValue) {
    const updatedValues = values.includes(activeValue)
      ? values.filter((v) => v !== activeValue)
      : [...values, activeValue];
    onChange(updatedValues);
  }

  function handleChangeMasterValue(masterValue) {
    const updatedValues = masterValue ? options.map((opt) => opt.value) : [];
    onChange(updatedValues);
  }

  function getMasterValue() {
    if (values.length === options.length) {
      return true;
    } else if (values.length > 0) {
      return null;
    } else {
      return false;
    }
  }

  const masterCheckbox = enableMasterCheckbox && (
    <Checkbox
      className="ui/checkbox-group__checkbox-master"
      disabled={disabled}
      label={label}
      labelVariant="s-dense-bold"
      value={getMasterValue()}
      onChange={handleChangeMasterValue}
    />
  );

  const pl = enableMasterCheckbox ? 5 : 0;

  return (
    <FlexLayout flexDirection="column" space={4}>
      {label &&
        (enableMasterCheckbox ? (
          masterCheckbox
        ) : (
          <Text color="black" variant="s-dense-bold">
            {label}
          </Text>
        ))}
      <FlexLayout flexDirection="column" pl={pl} space={4}>
        {options.map(({ label, value }) => (
          <Checkbox
            className={`ui/checkbox-group__checkbox-${value}`}
            disabled={disabled}
            key={`checkbox-${value}`}
            label={label}
            value={values.includes(value)}
            onChange={() => handleUpdateValues(value)}
          />
        ))}
      </FlexLayout>
    </FlexLayout>
  );
}

CheckboxGroup.propTypes = {
  /** Disable selection */
  disabled: PT.bool,
  /** Group label */
  label: PT.string,
  /** Indicates if component should render master checkbox using provided `label` prop */
  enableMasterCheckbox: PT.bool,
  /** Array of options: { value, label } */
  options: PT.array.isRequired,
  /** Array of currently selected values */
  values: PT.array.isRequired,
  /** Callback that returns selected values */
  onChange: PT.func.isRequired,
};

export default CheckboxGroup;
