import React, { memo } from 'react';

import { Markdown } from '../Markdown';
import { Text } from '../Text';

interface Props {
  /** Simple string (markdown formatting supported) */
  text: string;
  textAlign?: string;
}

export const DescriptionText = memo(({ text, textAlign }: Props) => {
  return (
    <Text
      textAlign={textAlign}
      styles={componentStyles.text}
      whiteSpace="pre-wrap"
    >
      <Markdown text={text} />
    </Text>
  );
});

const componentStyles = {
  text: {
    '> p': {
      margin: 0,
    },
  },
};
