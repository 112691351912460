export enum TraceName {
  DocumentEditor = 'documentEditor',
}

export enum TraceAction {
  LoadDocument = 'loadDocument',
  ConvertToDocx = 'convertToDocx',
  CreateHighlights = 'createHighlights',
  FetchAndLoadDocument = 'fetchAndLoadDocument',
}
