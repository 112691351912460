import React from 'react';

import { enquote } from '~/eds';
import { Field } from '~/evifields';
import { DOCUMENT_ID_FILTER } from '~/features/filters';

import { TextContainer } from '../TextContainer';
import { Filter, TextValue } from '../types';

interface Props {
  filter: Filter<TextValue>;
  field: Field;
}

export const TextValuesPreview = ({ field, filter }: Props) => {
  const value = filter.values[0] ?? '';
  let text = enquote(value);

  if (field.label === DOCUMENT_ID_FILTER) {
    const ids = value.split(',');
    text = ids.length > 1 ? `of ${ids.length} values` : `of ${value}`;
  }

  return <TextContainer text={text} />;
};
