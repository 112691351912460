import PropTypes from 'prop-types';
import React from 'react';

const HelpIcon = (props) => {
  return (
    <svg
      width={props.size || '18'}
      height={props.size || '18'}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.color || '#FFF'}
          fillOpacity={props.opacity || props.isHovered ? '1' : '.5'}
          fillRule="nonzero"
          d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zM8.25 9.29h2.138c.048-.893.63-1.47 1.508-1.47.862 0 1.452.519 1.452 1.278 0 .76-.279 1.144-1.308 1.804-1.061.668-1.444 1.394-1.388 2.789l.016.551h2.098v-.551c0-.785.287-1.194 1.316-1.845 1.15-.719 1.668-1.612 1.668-2.823 0-1.962-1.492-3.273-3.71-3.273-2.306 0-3.758 1.344-3.79 3.54zm3.535 8.96c.805 0 1.436-.635 1.436-1.453 0-.818-.63-1.453-1.436-1.453-.806 0-1.437.635-1.437 1.453 0 .818.63 1.453 1.437 1.453z"
        />
      </g>
    </svg>
  );
};

HelpIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
};

export default HelpIcon;
