import { transformToStringArray } from '~/features/webhooks/utils';
import { courier } from '~/services';

import {
  /*CreateApiResponse,*/
  EndpointCreateRequestDto,
} from '../../types/courier/endpoints';

interface Request {
  configuration: {
    url: string;
    description?: string;
    events: Record<string, string[]>;
  };
}

type ServerRequest = EndpointCreateRequestDto;

export const createEndpoint = async ({
  configuration,
}: Request): Promise<boolean> => {
  const { url, description, events } = configuration;

  const params: ServerRequest = {
    type: 'HTTP',
    name: url,
    url,
    description,
    events: transformToStringArray(events),
  };

  const response = await courier.post('/endpoints', params);
  return Boolean(response);
};
