import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { black2, evisortBlue } from '../../../assets/shared-styles/general';
import { MODAL_DEPARTMENT_TREE } from '../../../types/modal.types';
import EcButton from '../../Shared/EcButton';
import EcCheckbox from '../../Shared/EcCheckbox';
import EcDepartmentSelectValueWithControls from '../../Shared/EcDepartmentSelectValueWithControls';
import EcModalCard from '../../Shared/EcModalCard';
import EcRadioButton from '../../Shared/EcRadioButton';
import EcRadioContainer from '../../Shared/EcRadioContainer';
import EcUserNameSearch from '../../Shared/EcUserNameSearch';
import ChevronRightIcon from '../../Shared/Icons/ChevronRightIcon';
import WarningIcon from '../../Shared/Icons/WarningIcon';
import styles from './AccessSettingsModal.styles';

class AccessSettingsModal extends Component {
  constructor(props) {
    super(props);

    this.handleAllowUserDownloadChange = this.handleAllowUserDownloadChange.bind(
      this,
    );
    this.handleActionClick = this.handleActionClick.bind(this);
    this.updateUserPrivilege = this.updateUserPrivilege.bind(this);
    this.updateDepartmentPrivilege = this.updateDepartmentPrivilege.bind(this);
    this.handleRemoveDepartment = this.handleRemoveDepartment.bind(this);

    this.state = {
      itemName: this.props.itemsCurrentName,
      folderId: this.props.folderId,
      deptAccessList: this.processDepartments(this.props.deptAccessList) || [],
      folderAccessList: this.props.folderAccessList || [],
      selectedOption: this.renderSelectedOption(
        this.props.deptAccessList,
        this.props.folderAccessList,
        this.props.visibilityLevel,
      ),
      allowUsersDownloadChecked:
        this.props.visibilityLevel === 'OPEN_NO_EDIT' ? true : false,
      userNameSearchResult: [],
      departmentsSelected: this.props.deptAccessList,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.departmentsSelected !== prevState.departmentsSelected) {
      return { departmentsSelected: nextProps.departmentsSelected };
    } else {
      return null;
    }
  }

  processDepartments(departments) {
    return departments && departments.length
      ? departments.map((dept) => {
          return {
            id: dept.department_id,
            department_id: dept.department_id,
            department_name: dept.department_name,
            label: dept.department_name,
            value: dept.department_name,
            name: dept.department_name,
            visibility_level: dept.visibility_level || 'OPEN',
          };
        })
      : [];
  }

  renderSelectedOption(deptAccessList, folderAccessList, visibilityLevel) {
    return visibilityLevel === 'RESTRICTED'
      ? 'specificViewEdit'
      : visibilityLevel === 'OPEN_NO_EDIT' ||
        visibilityLevel === 'OPEN_NO_EDIT_DOWNLOAD'
      ? 'everyoneView'
      : visibilityLevel === 'OPEN'
      ? 'everyoneViewEdit'
      : 'inherited';
  }

  handleInputChange(itemName) {
    this.setState({ itemName });
  }

  handleInputFocus(event) {
    event.target.select();
  }

  handleAllowUserDownloadChange() {
    this.setState((prevState) => ({
      allowUsersDownloadChecked: !prevState.allowUsersDownloadChecked,
    }));
  }

  renderAllowUsersToDownload() {
    const { classes } = this.props;
    const { allowUsersDownloadChecked } = this.state;

    return (
      <div className={classes.allowUsersDownloadContainer}>
        <div className={classes.allowUsersDownloadHeader}>
          ADDITIONAL ACCESS SETTINGS
        </div>
        <span className={classes.privilegeOptionContainer}>
          <EcCheckbox
            id="allowUsersDownloadCheckBox"
            checked={allowUsersDownloadChecked}
            onClick={this.handleAllowUserDownloadChange}
          />
          <label htmlFor="allowUsersDownloadCheckBox">
            Allow users to download
          </label>
        </span>
      </div>
    );
  }

  handleOptionSelection(selectedValue) {
    const { allowUsersDownloadChecked } = this.state;

    this.setState({
      selectedOption: selectedValue,
      allowUsersDownloadChecked:
        selectedValue !== 'everyoneView' ? false : allowUsersDownloadChecked,
    });
  }

  renderAccessSettingsOptions() {
    const { classes } = this.props;
    const { selectedOption } = this.state;

    return (
      <EcRadioContainer
        role="radiogroup"
        groupName="accessSettingsOptions"
        onChange={({ selectedValue }) =>
          this.handleOptionSelection(selectedValue)
        }
        defaultValue={selectedOption}
      >
        {(groupName, selectedValue, onOptionChange) => (
          <div className={classes.radioContainerContent}>
            <EcRadioButton
              id="inherited"
              groupName="accessSettingsOptions"
              value="inherited"
              label={
                <span>
                  Always inherit the access settings of the parent folder
                </span>
              }
              onChange={onOptionChange}
              selectedValue={selectedOption}
              fontSize="13px"
              labelColor={black2}
              role="radio"
            />

            {selectedOption === 'inherited' && this.renderSelectionMessage()}

            <EcRadioButton
              id="everyoneViewEdit"
              groupName="accessSettingsOptions"
              value="everyoneViewEdit"
              label={
                <span>
                  Allow <b>everyone</b> to <b>view and edit</b> this folder and
                  its contents
                </span>
              }
              onChange={onOptionChange}
              selectedValue={selectedOption}
              fontSize="13px"
              labelColor={black2}
              role="radio"
            />

            <EcRadioButton
              id="everyoneView"
              groupName="accessSettingsOptions"
              value="everyoneView"
              label={
                <span>
                  Allow <b>everyone</b> to <b>view</b> this folder and its
                  contents
                </span>
              }
              onChange={onOptionChange}
              selectedValue={selectedOption}
              fontSize="13px"
              labelColor={black2}
              role="radio"
            />

            {selectedOption === 'everyoneView' &&
              this.renderAllowUsersToDownload()}

            <EcRadioButton
              id="specificViewEdit"
              groupName="accessSettingsOptions"
              value="specificViewEdit"
              label={
                <span>
                  Allow <b>only specific users</b> to <b>view and edit</b> this
                  folder and its contents
                </span>
              }
              onChange={onOptionChange}
              selectedValue={selectedOption}
              fontSize="13px"
              labelColor={black2}
              role="radio"
            />

            {selectedOption === 'specificViewEdit' &&
              this.renderSelectionMessage()}
          </div>
        )}
      </EcRadioContainer>
    );
  }

  renderAccessSettings() {
    const { classes, client, folderAccessList, folderId } = this.props;
    const { selectedOption } = this.state;

    const userAccessList = folderAccessList
      ? folderAccessList.map((user) => {
          return {
            ...user,
            label: user.user_name,
            value: user.user_name,
          };
        })
      : [];

    if (folderId) {
      return (
        <div className={classes.folderAccessSettingContainer}>
          <div className={classes.headerText}>ACCESS SETTINGS</div>
          {this.renderAccessSettingsOptions()}
          {selectedOption === 'specificViewEdit' ||
          selectedOption === 'inherited' ? (
            <div className={classes.aclControlsContainer}>
              {this.renderDepartmentsSelection()}

              <EcUserNameSearch
                client={client}
                userAccessList={userAccessList}
                updateUserPrivilege={this.updateUserPrivilege}
              />
            </div>
          ) : null}
        </div>
      );
    }
  }

  renderSelectionMessage() {
    const { classes } = this.props;
    const { selectedOption } = this.state;

    if (
      selectedOption !== 'specificViewEdit' &&
      selectedOption !== 'inherited'
    ) {
      return null;
    }
    let aclUsageMessage =
      selectedOption === 'specificViewEdit' ? (
        <div className={classes.aclRestrictedControlsMessage}>
          Admin users always have full access to all folders.
          <br />
          Use the dropdowns to also give access to certain departments and power
          users.
        </div>
      ) : (
        <>
          <div className={classes.aclInheritedControlsMessage}>
            <WarningIcon size="20" />
            <span>
              All users that have access to the parent folder will have the same
              level of access to this folder.{' '}
              <b>
                If a user has Edit access to the parent folder, they will also
                have Edit access to this folder.
              </b>
              <br />
              <br />
              Grant access to additional users below.
            </span>
          </div>
        </>
      );

    return (
      <div className={classes.aclControlsContainer}>
        <div className={classes.aclControlsMessageBox}>{aclUsageMessage}</div>
      </div>
    );
  }

  renderDepartmentsSelection() {
    const { classes, handleShowSecondaryModal } = this.props;
    const { departmentsSelected } = this.state;
    const departmentLists =
      departmentsSelected && departmentsSelected.length
        ? departmentsSelected.map((list) => {
            return (
              <EcDepartmentSelectValueWithControls
                dept={list}
                key={`${list.department_name}-${list.department_id}`}
                updateDepartmentPrivilege={this.updateDepartmentPrivilege}
                closeHandler={() => this.handleRemoveDepartment(list)}
              />
            );
          })
        : null;

    return (
      <div
        className={
          !departmentsSelected
            ? classes.accessDepartmentsContainerEmpty
            : classes.accessDepartmentsContainer
        }
      >
        <div className={classes.inputFieldHeader}>
          DEPARTMENTS
          <button
            aria-haspopup={true}
            className={classes.selectDepartmentCTA}
            onClick={() => handleShowSecondaryModal(MODAL_DEPARTMENT_TREE)}
          >
            <ChevronRightIcon size="15" color={evisortBlue} />
            Select Departments
          </button>
        </div>
        {!departmentsSelected || !departmentsSelected.length ? (
          <div className={classes.noDeptMessage}>
            No department has been selected.
          </div>
        ) : (
          departmentLists
        )}
      </div>
    );
  }

  handleRemoveDepartment(dept) {
    const { updateDepartmentsArray } = this.props;
    const { departmentsSelected } = this.state;
    const newSelectedDepartments = departmentsSelected.filter(
      (account) => account.department_id !== dept.department_id,
    );

    this.setState({ departmentsSelected: newSelectedDepartments });
    updateDepartmentsArray(newSelectedDepartments);
  }

  updateDepartmentPrivilege(deptAndAccess) {
    const { departmentsSelected } = this.state;

    if (deptAndAccess) {
      const deptAccessList = departmentsSelected.map((account) =>
        Object.assign(
          account,
          [deptAndAccess].find(
            (dept) => dept.department_name === account.department_name,
          ),
        ),
      );

      this.setState({ deptAccessList });
    }
  }

  updateUserPrivilege(userAndAccess) {
    if (userAndAccess) {
      const folderAccessList = Object.values(userAndAccess).map((user) => {
        return {
          user_id: user.user_id,
          user_name: user.label,
          visibility_level: user.visibility_level || 'OPEN',
        };
      });

      this.setState({ folderAccessList });
    }
  }

  handleActionClick() {
    const {
      itemsParentFolderId,
      folderId,
      handleNameChange,
      hideModal,
    } = this.props;
    const {
      itemName,
      selectedOption,
      allowUsersDownloadChecked,
      folderAccessList,
      departmentsSelected,
    } = this.state;

    const visibilityLevel =
      selectedOption === 'everyoneViewEdit' && !allowUsersDownloadChecked
        ? 'OPEN'
        : selectedOption === 'everyoneView' && allowUsersDownloadChecked
        ? 'OPEN_NO_EDIT'
        : selectedOption === 'specificViewEdit'
        ? 'RESTRICTED'
        : selectedOption === 'inherited'
        ? null
        : 'OPEN_NO_EDIT_DOWNLOAD';

    const deptAccessList = departmentsSelected.map((item) => {
      return {
        department_id: item.department_id,
        department_name: item.department_name,
        visibility_level: item.visibility_level || 'OPEN',
      };
    });
    folderId
      ? handleNameChange(
          folderId,
          itemName,
          itemsParentFolderId,
          visibilityLevel,
          folderAccessList,
          deptAccessList,
        )
      : handleNameChange(
          itemName,
          itemsParentFolderId,
          folderAccessList,
          deptAccessList,
        );

    hideModal();
  }

  render() {
    const {
      classes,
      title,
      id,
      labelText,
      confirmButtonIcon,
      confirmButtonText,
      hideModal,
      folderId,
    } = this.props;
    const { itemName } = this.state;

    return (
      <EcModalCard
        title={title}
        content={
          <div className={classes.modalBody} id={id}>
            <div className={classes.modalBodyContent}>
              {!folderId && (
                <div>
                  <div className={classes.headerText}>{labelText}</div>
                  <input
                    data-testid="folder-name-input"
                    className={classes.folderName}
                    value={itemName}
                    onChange={(event) => {
                      this.handleInputChange(event.target.value);
                    }}
                    onFocus={(event) => {
                      this.handleInputFocus(event);
                    }}
                    title={`${title} name`}
                  />
                </div>
              )}
              {this.renderAccessSettings()}
            </div>
          </div>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              disabled={!itemName}
              yellow
              iconLeft={confirmButtonIcon}
              text={confirmButtonText}
              onClick={this.handleActionClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

AccessSettingsModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  labelText: PropTypes.string,
  id: PropTypes.string,
  itemsParentFolderId: PropTypes.number,
  itemsCurrentName: PropTypes.string.isRequired,
  confirmButtonIcon: PropTypes.object,
  confirmButtonText: PropTypes.string.isRequired,
  editItemName: PropTypes.func,
  createItemName: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(AccessSettingsModal);
