import { refreshAccessToken } from './refreshAccessToken';

type CurriedAsyncFn<T extends any[], R> = (...args: T) => Promise<R>;

export function with401Retry<T extends any[], R>(method: CurriedAsyncFn<T, R>) {
  const wrapped = (...args: T) => {
    // put try/catch into a function so that
    // we can recursively make the same request if we catch
    const makeRequest = async (wasRetrying = false): Promise<R> => {
      try {
        return await method(...args);
      } catch (error: any) {
        if (error.response?.status === 401) {
          if (wasRetrying) {
            throw error;
          }

          await refreshAccessToken();
          // if someone can reach this point, we assume there is a valid access_token

          return makeRequest(true);
        }

        // TODO: EKP-1398
        // swallow other 4xx error potentially
        throw error;
      }
    };

    return makeRequest();
  };

  return wrapped;
}
