import { camelizeKeys } from 'humps';

import {
  PermissionEntityType,
  PermissionResponse,
  Permissions,
  UserPermissionMapType,
} from '~/redux/types';
import { Entity, PilotId } from '~/types';

import { checkUsersPermissions as checkUsersPermissionsPilot } from './client';
import { checkUsersPermissions as checkUsersPermissionsEverest } from './permissions';

const transformResponse = (response: PermissionResponse) => {
  return {
    entity: {
      type: response.entity_type,
      id: response.entity_id,
    },
    userPermissionMap: camelizeKeys(
      response.user_permission_map,
    ) as UserPermissionMapType,
  };
};

export type GetEntityPermissionsParams = {
  userIds: PilotId[];
  entity: Entity<PermissionEntityType>;
  enableChunkSpliting?: boolean;
};

export const getEntityPermissions = async ({
  userIds,
  entity,
  enableChunkSpliting = false,
}: GetEntityPermissionsParams): Promise<Permissions> => {
  let permissions;

  switch (entity.type) {
    case 'ticket':
      permissions = await checkUsersPermissionsEverest({
        userIds,
        entity: entity as Entity<'ticket'>,
        enableChunkSpliting,
      });
      break;
    case 'document':
    case 'document_handler':
    default:
      permissions = await checkUsersPermissionsPilot({
        userIds,
        entity: entity as Entity<'document' | 'document_handler'>,
        enableChunkSpliting,
      });
      break;
  }

  // transform to object in shape: { [user.id]: { has_access: boolean, permission_type: string }}
  const mergedPermissions =
    permissions?.reduce(
      (prev, current) => ({ ...prev, ...current.user_permission_map }),
      {},
    ) || {};

  return transformResponse({
    entity_type: entity.type,
    entity_id: entity.id,
    user_permission_map: mergedPermissions,
  });
};
