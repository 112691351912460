import { atlas } from '~/services';
import { Nullable } from '~/types';

import { QuestionGroupsResponsePayload } from './types';
import { mapQuestionGroupResponse } from './utils';

type QuestionGroupsRequest = {
  pageSize?: number;
  pageLimit?: number;
  search?: string;
  visibility?: Nullable<'PRIVATE' | 'SHARED'>;
};

const defaultRequest = {
  pageSize: 0,
  pageLimit: 0,
  search: null,
  visibility: null,
};

export const getQuestionGroups = async (
  request: QuestionGroupsRequest = {},
) => {
  const options = { ...defaultRequest, ...request };
  const params = {
    'page[offset]': options.pageSize,
    'page[limit]': options.pageLimit,
    search: options.search,
    visibility: options.visibility,
  };
  const response: QuestionGroupsResponsePayload = await atlas.get(
    'question-groups',
    {
      params,
    },
  );

  return response.data.map(mapQuestionGroupResponse);
};
