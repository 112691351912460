import { palette } from './system/palette';

export const status = {
  'status.ai': palette['plum-500'],
  'status.ai.secondary': palette['plum-80'],
  'status.info': palette['gray-500'],
  'status.info.secondary': palette['gray-80'],
  'status.success': palette['green-600'],
  'status.success.secondary': palette['green-80'],
  'status.warning': palette['yellow-500'],
  'status.warning.secondary': palette['yellow-80'],
  'status.danger': palette['red-500'],
  'status.danger.secondary': palette['red-80'],
  'status.active': palette['blue-500'],
  'status.active.secondary': palette['blue-80'],
  'status.inactive': palette['gray-500'],
  'status.inactive.secondary': palette['gray-80'],
  'status.new': palette['blue-500'],
  'status.new.secondary': palette['blue-80'],
};
