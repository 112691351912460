import React, { useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import { builderSetShouldEditSetup, workflowUpdateSetup } from '~/actions';
import {
  createWorkflow,
  getWorkflowFile,
  parseTemplate,
} from '~/api/workflows';
import { showToast } from '~/components/Shared/EcToast';
import DescriptionTextAreaForm from '~/components/Workflow/shared/DescriptionTextAreaForm';
import { MAX_DOCUMENT_NAME_LENGTH } from '~/constants/max_lengths';
import { Button } from '~/eds';
import {
  FeatureFlagType,
  FileMimeType,
  WorkflowFieldType,
  WorkflowIntakeFormType,
} from '~/enums';
import { useAsync, useHasFeatureFlag } from '~/hooks';
import { useRouting } from '~/routing';
import { ERROR } from '~/types/toast.types';
import {
  Box,
  Card,
  CharacterLimit,
  FileExtensionMultiSelect,
  FileInput,
  FileLabel,
  FlexLayout,
  Icon,
  InputLabel,
  LoadingContainer,
  PageLayout,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from '~/ui';
import { CHARACTER_INPUT_LIMIT_SHORT } from '~/ui/enums/input';
import { toLowercaseTrimmed } from '~/utils/strings';
import {
  createFieldNameFromPlaceholder,
  createPartiesSigners,
  getFieldIdsByTrimmedKey,
} from '~/utils/workflow';

import Fields from './Fields';
import TemplateSteps from './TemplateSteps';

const NAME_LIMIT = CHARACTER_INPUT_LIMIT_SHORT;
const DESCRIPTION_LIMIT = CHARACTER_INPUT_LIMIT_SHORT;

const NON_UNIQUE_FIELD_NAMES_WARNING =
  'We detected document placeholders that share identical names but have different capitalization and/or spacing. Please edit the highlighted placeholders below so that they are identical, including capitalization and spaces, or use different names if they are intended to be different placeholders.';

const {
  Company,
  CompanyAndCounterparty,
  Counterparty,
} = WorkflowIntakeFormType;

export function getDisabledState(
  formData,
  description,
  nonUniqueFieldIds,
  isParsingTemplate,
) {
  const { name, file, type } = formData;
  let disabled = false;
  let disabledTooltip = '';

  if (!name) {
    disabled = true;
    disabledTooltip = 'Workflow name is required.';
  } else if (name.length > NAME_LIMIT) {
    disabled = true;
    disabledTooltip = `Workflow name exceeded the character limit (${NAME_LIMIT}).`;
  } else if (!type) {
    disabled = true;
    disabledTooltip = 'At least one document option must be specified.';
  } else if ([Company, CompanyAndCounterparty].includes(type) && !file) {
    disabled = true;
    disabledTooltip =
      ' A file must be provided when generating a document from a template.';
  } else if (description && description.length > DESCRIPTION_LIMIT) {
    disabled = true;
    disabledTooltip = `Description exceeded the character limit (${DESCRIPTION_LIMIT}).`;
  } else if (nonUniqueFieldIds.length > 0) {
    disabled = true;
    disabledTooltip = NON_UNIQUE_FIELD_NAMES_WARNING;
  } else if (isParsingTemplate) {
    disabled = true;
    disabledTooltip = 'Template is being processed.';
  }

  return [disabled, disabledTooltip];
}

function Setup({
  // connected
  workflow,
  builderSetShouldEditSetup,
  workflowUpdateSetup,
}) {
  const { navigate, params } = useRouting();

  const { workflowId } = params;

  const {
    description: initialDescription = '',
    fields: initialFields = {},
    name: initialName = '',
    type: initialType = '',
    acceptedFileTypes: initialAcceptedFileTypes = [],
  } = workflow;
  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);
  const [hasCompanyPaper, setHasCompanyPaper] = useState(
    [Company, CompanyAndCounterparty].includes(initialType),
  );
  const [hasCounterpartyPaper, setHasCounterpartyPaper] = useState(
    [Counterparty, CompanyAndCounterparty].includes(initialType),
  );
  const [fields, setFields] = useState(initialFields);
  const [file, setFile] = useState(null);
  const [hasUploadedNewFile, setHasUploadedNewFile] = useState(false);
  const [acceptedFileTypes, setAcceptedFileTypes] = useState(
    initialAcceptedFileTypes,
  );
  const hasIntakeFormAllFileTypesFeatureFlag = useHasFeatureFlag(
    FeatureFlagType.IntakeFormAllFileTypes,
  );

  const { isLoading: isLoadingFile } = useAsync(
    getWorkflowFile,
    { id: workflowId, type: 'original' },
    {
      errorToastMessage:
        'An error occurred while fetching file. Please try again.',
      successHandler: setFile,
      deps: [initialType],
      condition: workflowId && initialType !== Counterparty,
    },
  );

  const {
    executor: executeParseTemplate,
    isLoading: isParsingTemplate,
  } = useAsync(parseTemplate, file, {
    errorHandler: clearFile,
    successHandler: handleParseTemplate,
    successToastMessage: 'Template document successfully processed.',
    errorToastMessage:
      'Some errors occurred while parsing the template document for placeholders.  Please ensure all placeholders begin with "{{" and end with "}}", and they do not contain any special symbols (e.g. #, @)',
  });

  let type;
  if (hasCompanyPaper && hasCounterpartyPaper) {
    type = CompanyAndCounterparty;
  } else if (hasCompanyPaper) {
    type = Company;
  } else if (hasCounterpartyPaper) {
    type = Counterparty;
  }

  const formData = {
    description,
    file,
    name,
    fields,
    type,
    acceptedFileTypes: acceptedFileTypes,
  };

  const fieldIdsByUniquePlaceholder = getFieldIdsByTrimmedKey(
    fields,
    'placeholder',
  );
  const nonUniqueFieldIds = Object.values(fields)
    .filter(
      (field) =>
        fieldIdsByUniquePlaceholder[toLowercaseTrimmed(field.placeholder || '')]
          ?.length > 1,
    )
    .map((field) => field.id);

  const [disabled, disabledTooltip] = getDisabledState(
    formData,
    description,
    nonUniqueFieldIds,
    isParsingTemplate,
  );

  function onBack() {
    builderSetShouldEditSetup(false);
  }

  const { executor: executeCreateWorkflow, isLoading: isCreating } = useAsync(
    createWorkflow,
    { ...formData, ...createPartiesSigners() },
    {
      errorToastMessage: `Unable to create workflow "${name}", please try again.`,
      successHandler: (response) => {
        builderSetShouldEditSetup(false);
        navigate(`/workflow/builder/${response.id}`);
      },
      successToastMessage: `Workflow "${name}" has been successfully created.`,
    },
  );

  function handleOnUpdateClick() {
    if (hasUploadedNewFile) {
      workflowUpdateSetup(formData);
    } else {
      const { file: _file, ...restFormData } = formData;
      workflowUpdateSetup(restFormData);
    }
    builderSetShouldEditSetup(false);
  }

  function clearFile() {
    setFile(null);
  }

  function removeFile() {
    clearFile();
    setFields({});
  }

  function handleParseTemplate(parsed) {
    const { conditionals, placeholders } = parsed;

    const newFields = {};
    conditionals.forEach((conditionalText) => {
      const id = uuid();
      newFields[id] = {
        id,
        name: createFieldNameFromPlaceholder(conditionalText.placeholder),
        conditionalText,
        placeholder: conditionalText.placeholder,
        type: WorkflowFieldType.ConditionalText,
      };
    });
    placeholders.forEach((placeholder) => {
      const id = uuid();
      newFields[id] = {
        id,
        name: createFieldNameFromPlaceholder(placeholder),
        placeholder,
      };
    });
    setFields(newFields);
  }

  function validateDocumentName(file) {
    if (file.name.length > MAX_DOCUMENT_NAME_LENGTH) {
      showToast(
        ERROR,
        'Uploaded file name cannot be longer than ' +
          MAX_DOCUMENT_NAME_LENGTH +
          ' characters.',
      );
    } else {
      setFile(file);
      executeParseTemplate();
      setHasUploadedNewFile(true);
    }
  }

  let FileContent;
  if (hasCompanyPaper) {
    FileContent = file ? (
      <FlexLayout
        alignItems="center"
        bg="gray-200"
        justifyContent="space-between"
        px={6}
        sx={{ borderRadius: 'm', height: 'input-height' }}
      >
        <FileLabel enableDownload file={file} />
        <Icon color="gray-500" icon="close" size="s" onClick={removeFile} />
      </FlexLayout>
    ) : null;
  }

  return (
    <PageLayout
      header={
        <PageLayout.Header
          leftIcon={{
            icon: 'chevronLeft',
            onClick: onBack,
          }}
          title={{
            color: name ? 'gray-700' : 'gray-500',
            text: name || 'Untitled workflow',
          }}
        />
      }
    >
      <FlexLayout
        flex="1 1 auto"
        flexDirection="column"
        mx="auto"
        sx={{ maxWidth: '808px' }}
        space={12}
      >
        <FlexLayout flexDirection="column" space={4}>
          <InputLabel isRequired id="workflow-name" label="Workflow name">
            <TextInput
              autoFocus
              id="workflow--set-up-name"
              value={name}
              placeholder="e.g. Purchase Order or Standard MSA"
              width="fullWidth"
              onChange={setName}
            />
            {/* Will extract an input component when there are more use cases */}
            <FlexLayout justifyContent="flex-end">
              <CharacterLimit limit={NAME_LIMIT} value={name} />
            </FlexLayout>
          </InputLabel>
          <DescriptionTextAreaForm
            description={description}
            id="workflow--set-up-description"
            noDescriptionMessage="Provide a description (optional)..."
            onUpdate={setDescription}
          />
        </FlexLayout>
        <FlexLayout flexDirection="column" space={3}>
          <Card>
            <FlexLayout alignItems="center" justifyContent="space-between">
              <FlexLayout
                flexDirection="column"
                space={2}
                sx={{ maxWidth: '600px' }}
              >
                <Text color="gray-900" variant="m-dense-bold">
                  Users will be able to upload an existing document
                </Text>
                <Text color="gray-600" variant="s-dense">
                  The document will be collected from the ticket creator when
                  they submit a new request. You can specify any file types to
                  upload, but only .pdf, .doc and .docx will be processed by
                  Evisort’s AI upon ticket completion.{' '}
                </Text>
                {hasIntakeFormAllFileTypesFeatureFlag &&
                  hasCounterpartyPaper && (
                    <FileExtensionMultiSelect
                      onChange={setAcceptedFileTypes}
                      values={acceptedFileTypes}
                      placeholder="Add allowed file types"
                    />
                  )}
              </FlexLayout>
              <Switch
                id="workflow--set-up-has-counterparty-paper"
                value={hasCounterpartyPaper}
                onChange={setHasCounterpartyPaper}
              />
            </FlexLayout>
          </Card>
          <Card>
            <FlexLayout
              alignItems="center"
              justifyContent="space-between"
              space={2}
            >
              <FlexLayout
                flexDirection="column"
                space={2}
                sx={{ maxWidth: '600px' }}
              >
                <Text color="gray-900" variant="m-dense-bold">
                  Users will be able to generate document from a template
                </Text>
                <Text color="gray-600" variant="s-dense">
                  Upload existing document with marked{' '}
                  <Text color="gray-600" variant="s-dense-bold">
                    field
                  </Text>{' '}
                  and{' '}
                  <Text color="gray-600" variant="s-dense-bold">
                    conditional text
                  </Text>{' '}
                  to be used as a template.
                </Text>
              </FlexLayout>
              <Switch
                id="workflow--set-up-has-company-paper"
                value={hasCompanyPaper}
                onChange={(updatedValue) => {
                  if (!updatedValue) {
                    setFields({});
                  }
                  setHasCompanyPaper(updatedValue);
                  setFile();
                }}
              />
            </FlexLayout>
            <Box bg="gray-400" my={6} sx={{ height: '1px' }} />
            <LoadingContainer isLoading={isLoadingFile || isParsingTemplate}>
              <FileInput
                className="workflow/builder/setup__upload-company-paper"
                enableUpload={hasCompanyPaper}
                id="workflow--set-up-upload-company-paper-trigger"
                file={file}
                acceptedFiles={FileMimeType.Docx}
                enableDropzone
                onChange={(files) => {
                  validateDocumentName(files[0]);
                }}
              >
                {!file && <TemplateSteps />}
                {FileContent}
                {nonUniqueFieldIds.length > 0 && (
                  <FlexLayout alignItems="center" mt={8} space={3}>
                    <Icon color="yellow-500" icon="warning" size="s" />
                    <Text color="peach-800" variant="2xs-dense">
                      {NON_UNIQUE_FIELD_NAMES_WARNING}
                    </Text>
                  </FlexLayout>
                )}
                {file && (
                  <Fields
                    fields={fields}
                    nonUniqueFieldIds={nonUniqueFieldIds}
                  />
                )}
              </FileInput>
            </LoadingContainer>
          </Card>
          <FlexLayout pb={7} pt={2} space={3}>
            <Icon color="blue-500" icon="info" size="xs" />
            <Text isReadable color="gray-700" variant="2xs-dense">
              When both options are selected, the ticket creator will be able to
              choose if they want to generate or upload a document.
            </Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout justifyContent="flex-end">
          <Tooltip content={disabledTooltip}>
            {workflowId ? (
              <Button
                disabled={disabled}
                text="Update"
                variant="primary"
                onClick={handleOnUpdateClick}
              />
            ) : (
              <Button
                disabled={disabled}
                id="workflow--set-up-continue"
                isLoading={isCreating}
                text="Continue"
                variant="primary"
                onClick={executeCreateWorkflow}
              />
            )}
          </Tooltip>
        </FlexLayout>
      </FlexLayout>
    </PageLayout>
  );
}

function mapStateToProps({ workflow }) {
  return { workflow };
}

export default connect(mapStateToProps, {
  builderSetShouldEditSetup,
  workflowUpdateSetup,
})(Setup);
