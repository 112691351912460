import { black1, black2, bold } from '../../assets/shared-styles/general';

const styles = {
  listItemAction: {
    '& a': {
      textDecoration: 'none',
    },
  },

  metaData: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '8px',
    },
    '& div': {
      color: black2,
      fontSize: '13px',
    },
    '& span': {
      color: black1,
      fontWeight: bold,
    },
  },
};

export default styles;
