import { canUseWorkflow } from '~/permissions';

import { RoutePathType } from '../enums';
import { Component, RouteResolver } from '../types';

/**
 * This creates the default resolvers for workflow routes:
 * - Provides the component to resolve to if user passes the permission canUseWorkflow.
 * - Redirects to RoutePathType.Home otherwise
 */
export const createWorkflowResolvers = (
  component: Component,
): RouteResolver[] => {
  return [
    {
      permission: canUseWorkflow,
      component,
    },
    {
      redirectPath: RoutePathType.Home,
    },
  ];
};
