import { everest } from '~/services';
import {
  Nullable,
  TurnTrackingHistoryType,
  TurnTrackingPartyType,
  Uuid,
} from '~/types';

interface HistoryResponse {
  id: Uuid; // turn id
  start_time: Date;
  end_time: Nullable<Date>;
  party_id: Uuid;
}

const mapHistoryResponse = (
  response: HistoryResponse[],
): TurnTrackingHistoryType[] => {
  return response.map((entry) => {
    return {
      turnId: entry.id,
      startTime: new Date(entry.start_time),
      endTime: entry?.end_time ? new Date(entry.end_time) : null,
      partyId: entry.party_id,
    };
  });
};

export const getTurnTrackingHistory = async ({
  ticketId,
}: {
  ticketId: Uuid;
}): Promise<TurnTrackingHistoryType[]> => {
  const response: HistoryResponse[] = await everest.get(
    `tickets/${ticketId}/turn-trackings`,
  );
  return mapHistoryResponse(response);
};

export const getTurnTrackingParties = async ({
  workflowId,
}: {
  workflowId: Uuid;
}): Promise<TurnTrackingPartyType[]> => {
  return await everest.get(`workflows/${workflowId}/parties`);
};

export const updateTurnTrackingEntry = async ({
  ticketId,
  entry,
}: {
  ticketId: Uuid;
  entry: TurnTrackingHistoryType;
}) => {
  const data = {
    start_time: entry.startTime,
    end_time: entry.endTime,
    party_id: entry.partyId,
  };
  return await everest.patch(
    `tickets/${ticketId}/turn-trackings/${entry.turnId}`,
    data,
  );
};

export const createTurnTrackingEntry = async ({
  ticketId,
  partyId,
}: {
  ticketId: Uuid;
  partyId: Uuid;
}) => {
  const data = { party_id: partyId };
  return await everest.post(`tickets/${ticketId}/turn-trackings`, data);
};
