import { noop } from 'lodash';
import React from 'react';

import {
  Layout,
  Panel_DEPRECATED,
  SearchInput,
  types,
  useThrottledState,
} from '~/eds';
import { Nullable, TicketDocumentVersion } from '~/types';

import { ActivityLog } from './ActivityLog';
import { CustomActions } from './types';

interface Props {
  width?: 'm' | 'l' | 'fill';
  actions?: types.Action[];
  customActions?: CustomActions;
  ticketId: types.Uuid;
  versions: TicketDocumentVersion[];
}

export function ActivityLogPanel({
  actions,
  customActions,
  width = 'm',
  ticketId,
  versions,
}: Props) {
  const [throttledSearch, search, setSearch] = useThrottledState<
    Nullable<string>
  >('');

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      actions={actions}
      isEmbedded
      isPadded
      isVisible
      title="Activity Log"
      width={width}
      onHide={noop}
    >
      <Layout h="100%" direction="column" spacing={4}>
        <SearchInput
          name="search"
          placeholder="Search Activities"
          value={search}
          onChange={setSearch}
        />
        <Layout overflowY="auto">
          <ActivityLog
            ticketId={ticketId}
            versions={versions}
            searchInput={throttledSearch ?? ''}
            customActions={customActions}
          />
        </Layout>
      </Layout>
    </Panel_DEPRECATED>
  );
}
