import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.Automation, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.Automation as TagType],
  endpoints: {
    getClauses: {
      providesTags: [TAG_BY_LIST],
    },
    getIdentifications: {
      providesTags: [TAG_BY_LIST],
    },
    suspendClause: {
      invalidatesTags: [TAG_BY_LIST],
    },
    resumeClause: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deleteClause: {
      invalidatesTags: [TAG_BY_LIST],
    },
    getSamples: {
      providesTags: [TAG_BY_LIST],
    },
    getProvisions: {
      providesTags: [TAG_BY_LIST],
    },
    createSample: {
      invalidatesTags: [TAG_BY_LIST],
    },
    updateSample: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deleteSample: {
      invalidatesTags: [TAG_BY_LIST],
    },
    suspendSample: {
      invalidatesTags: [TAG_BY_LIST],
    },
    resumeSample: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deleteTrainingModel: {
      invalidatesTags: [TAG_BY_LIST],
    },
    renameClause: {
      invalidatesTags: [TAG_BY_LIST],
    },
  },
});
