import {
  black1,
  black4,
  evisortBlue,
  evisortRed,
} from '~/assets/shared-styles/general';

const styles = {
  ecTextarea: {
    width: '100%',
    height: (props) => props.height || '160px',
    padding: '7px',
    border: (props) =>
      props.error || props.errorMessage
        ? `1px solid ${evisortRed}`
        : '1px solid rgba(0,0,0,0.2)',
    borderRadius: '4px',
    backgroundColor: (props) => (props.disabled ? '#f3f3f3' : '#fff'),
    color: (props) => (props.disabled ? black4 : black1),
    fontSize: '15px',
    lineHeight: '20px',
    resize: 'none',

    '&:hover': {
      borderColor: (props) =>
        props.disabled ? null : props.error ? evisortRed : evisortBlue,
    },

    '&:focus': {
      outline: 'none',
    },
  },
};

export default styles;
