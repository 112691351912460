import codedErrors, {
  ICodedError,
  IPairsKeyValueToFillTemplate,
} from '~/constants/codedErrors';
import { CodedErrorCodeType } from '~/enums';
import { tokenizeContent } from '~/utils/comments';

class CodedError extends Error {
  code: number;
  name: string;

  constructor(errorData: ICodedError) {
    const { code, message, name } = errorData;
    super(message);

    this.name = name;
    this.code = code;
  }
}

export const defaultErrorMessage = 'An undetermined error has occurred.';

export const getCodedError = (
  code: CodedErrorCodeType,
  pairsKeyValueToFillTemplate: IPairsKeyValueToFillTemplate = {},
): Error | ICodedError => {
  const codedError = codedErrors[code];
  if (codedError) {
    const reducerToTokenRenders = (
      tokenRenderersInConstruction: any,
      key: string,
    ) => {
      tokenRenderersInConstruction[key] = () =>
        pairsKeyValueToFillTemplate[key];
      return tokenRenderersInConstruction;
    };

    const tokenRenderers = Object.keys(pairsKeyValueToFillTemplate).reduce(
      reducerToTokenRenders,
      {},
    );
    const templateMessagedFilled = tokenizeContent(
      codedError.message,
      tokenRenderers,
    )
      .map((t) => t.content)
      .join('');
    return new CodedError({ ...codedError, message: templateMessagedFilled });
  }

  return getDefaultError();
};

export const getCustomError = (message: any) => {
  if (!!message) {
    return new Error(message);
  }

  return getDefaultError();
};

export const getDefaultError = (): Error => new Error(defaultErrorMessage);
