export default `
<svg
  aria-hidden="true"
  aria-label="reply"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M20 14.474V18.224C20 18.6615 19.6562 18.974 19.25 18.974C18.8125 18.974 18.5 18.6615 18.5 18.2552V14.5365C18.5 12.7552 17.0312 11.3177 15.25 11.3177H6.75L10.7188 14.7552C11.0312 15.0365 11.0625 15.5052 10.8125 15.8177C10.6562 15.9115 10.4375 15.974 10.25 15.974C10.0625 15.974 9.875 15.9427 9.75 15.8177L4.25 11.099C4.09375 10.9115 4 10.6927 4 10.474C4 10.2552 4.09375 10.0677 4.25 9.9115L9.75 5.19275C10.0312 4.94275 10.5312 4.974 10.8125 5.2865C11.0625 5.599 11.0312 6.06775 10.7188 6.349L6.75 9.724H15.25C17.8438 9.724 20 11.8802 20 14.474Z"
    fill="currentcolor"
  />
</svg>
`;
