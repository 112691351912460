export const syntaxOptions = [
  {
    label: 'AND',
    description: 'Require both search terms',
    examples: [
      {
        text: 'california AND privacy',
        description:
          'Return documents that contain the words “california” and “privacy”.',
      },
    ],
  },
  {
    label: 'OR',
    description: 'Require either search term',
    examples: [
      {
        text: 'publicity OR trademark',
        description:
          'Return documents that contain the words “publicity” or “trademark”.',
      },
    ],
  },
  {
    label: 'NOT',
    description: 'Contains one and not another',
    examples: [
      {
        text: 'termination NOT convenience',
        description:
          'Return documents that contain the word “termination” and do not contain the word “convenience”.',
      },
    ],
  },
  {
    label: 'OR NOT',
    description: 'Contains one or not another',
    examples: [
      {
        text: 'termination OR NOT convenience',
        description:
          'Return documents that contain the word “termination” or do not contain the word “convenience”.',
      },
    ],
  },
  {
    label: '/n',
    description: 'Within “n” number of words',
    examples: [
      {
        text: 'data /5 breach',
        description:
          'Return documents that contain the word “data” within 5 words of the word “breach”.',
      },
    ],
  },
  {
    label: '-n',
    description: 'Within “n” words before',
    examples: [
      {
        text: 'indemnify -5 supplier',
        description:
          'Return documents where the word “supplier” appears within the 5 words preceding the word “indemnify”.',
      },
    ],
  },
  {
    label: '+n',
    description: 'Within “n” words after',
    examples: [
      {
        text: 'supplier +5 indemnify',
        description:
          'Return documents where the word “indemnify” appears within the 5 words following the word “supplier“.',
      },
    ],
  },
  {
    label: '“ ”',
    description: 'Exact phrase',
    examples: [
      {
        text: '“New York”',
        description:
          'Return documents that contain the exact phrase “New York”.',
      },
    ],
  },
  {
    label: '()',
    description: 'Order of operations',
    examples: [
      {
        text: '2020 AND (extend OR renew)',
        description:
          'Return documents that contain the word “2020,” plus either the word “extend” or the word “renew”.',
      },
    ],
  },
  {
    label: '✱',
    description: 'Treat word as stem',
    examples: [
      {
        text: 'service*',
        description:
          'Return documents that contain: "service", "services", "serviced", "servicing".',
      },
      {
        text: '*post',
        description:
          'Return documents that contain: "post", "repost", "lamppost".',
      },
      {
        text: '*post*',
        description:
          'Return documents that contain: "post", "lamppost", "repost", "poster", "posted", "posting".',
      },
    ],
  },
  {
    label: 'fuzzy ()',
    description: 'Fuzzy match',
    examples: [
      {
        text: 'fuzzy (confidential)',
        description:
          'Return documents that contain “confidntia”, “confidentil”, “onfidential”, etc. Can place one word or an entire phrase or sentence in parentheses.',
      },
    ],
  },
];
