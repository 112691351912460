import { Dispatch, SetStateAction, useState } from 'react';

import { INPUT_DEBOUNCE_MS } from '../constants';
import { useThrottledValue } from './useThrottledValue';

export function useThrottledState<V>(
  initialState: V,
  wait = INPUT_DEBOUNCE_MS,
): [V, V, Dispatch<SetStateAction<V>>] {
  const [state, setState] = useState(initialState);
  const throttledStateValue = useThrottledValue(state, wait);

  return [throttledStateValue, state, setState];
}
