import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Icon, Text } from '~/ui';
import { downloadFile, getFileIcon } from '~/utils/files';

function FileLabel({
  enableDownload = false,
  file,
  hasBackground = false,
  shouldTruncate = false,
  onRemove,
}) {
  return (
    <FlexLayout
      alignItems="center"
      bg={hasBackground ? 'gray-300' : undefined}
      px={hasBackground ? 3 : undefined}
      py={hasBackground ? 2 : undefined}
      space={3}
      sx={{
        borderRadius: 'm',
        maxWidth: 'fit-content',
      }}
      onClick={enableDownload ? () => downloadFile(file) : undefined}
    >
      <Icon color="gray-600" icon={getFileIcon(file)} size="s" />
      <Text shouldTruncate={shouldTruncate} color="gray-700" variant="xs-dense">
        {file.name || 'file'}
      </Text>
      {onRemove && (
        <Icon
          color="gray-500"
          icon="close"
          size="s"
          title="Remove file"
          onClick={() => onRemove(file)}
        />
      )}
    </FlexLayout>
  );
}

FileLabel.propTypes = {
  /** Enable downloading the file */
  enableDownload: PT.bool,
  /** A `File`-like object */
  file: PT.object.isRequired,
  /** Decide if a background should be rendered */
  hasBackground: PT.bool,
  /** Truncate file text if true */
  shouldTruncate: PT.bool,
  /** Remove callback.  When provided, renders a close icon */
  onRemove: PT.func,
};

export default FileLabel;
