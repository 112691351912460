export default `
<svg
  aria-hidden="true"
  aria-label="field-paragraph"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M13.5615 5.25C13.9678 5.25 14.3115 5.59375 14.3115 6C14.3115 6.4375 13.9678 6.75 13.5615 6.75H6.06152C5.62402 6.75 5.31152 6.4375 5.31152 6C5.31152 5.59375 5.62402 5.25 6.06152 5.25H13.5615ZM18.5615 9.25C18.9678 9.25 19.3115 9.59375 19.3115 10C19.3115 10.4375 18.9678 10.75 18.5615 10.75H6.06152C5.62402 10.75 5.31152 10.4375 5.31152 10C5.31152 9.59375 5.62402 9.25 6.06152 9.25H18.5615ZM5.31152 14C5.31152 13.5938 5.62402 13.25 6.06152 13.25H13.5615C13.9678 13.25 14.3115 13.5938 14.3115 14C14.3115 14.4375 13.9678 14.75 13.5615 14.75H6.06152C5.62402 14.75 5.31152 14.4375 5.31152 14ZM18.5615 17.25C18.9678 17.25 19.3115 17.5938 19.3115 18C19.3115 18.4375 18.9678 18.75 18.5615 18.75H6.06152C5.62402 18.75 5.31152 18.4375 5.31152 18C5.31152 17.5938 5.62402 17.25 6.06152 17.25H18.5615Z"
    fill="currentcolor"
  />
</svg>
`;
