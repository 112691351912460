import PT from 'prop-types';
import React from 'react';

import DepartmentSelect from '~/components/Shared/DepartmentSelect';
import UserSelect from '~/components/Shared/UserSelect';
import { selectWidths } from '~/constants/selectWidths';
import { customSettingsKeys } from '~/constants/workflow';
import { CurrencySelect, Select } from '~/eds';
import { WorkflowFieldType } from '~/enums';
import {
  Checkbox,
  FlexLayout,
  InputLabel,
  NumberInput,
  Text,
  TextInput,
} from '~/ui';

const inputTypes = {
  CHECKBOX: 'CHECKBOX',
  CURRENCY_MULTI_SELECT: 'CURRENCY_MULTI_SELECT',
  DEPARTMENT_MULTI_SELECT: 'DEPARTMENT_MULTI_SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  NUMBER_INPUT: 'NUMBER_INPUT',
  SINGLE_SELECT: 'SINGLE_SELECT',
  TEXT_INPUT: 'TEXT_INPUT',
  USER_MULTI_SELECT: 'USER_MULTI_SELECT',
};

function CustomSettingsInput({
  customSettings,
  disabled,
  description,
  id,
  isRequired = true,
  inputType = inputTypes.TEXT_INPUT,
  label,
  options,
  placeholder,
  settingsKey,
  width,
  customSettingsUpdateFactory,
}) {
  const value =
    inputType === inputTypes.CHECKBOX
      ? Boolean(customSettings[settingsKey])
      : customSettings[settingsKey];
  const onChange = customSettingsUpdateFactory(settingsKey);

  function getMin() {
    let min;
    if (
      settingsKey === customSettingsKeys[WorkflowFieldType.File].MIN_FILE_COUNT
    ) {
      return 0;
    }
    return min;
  }

  let Input;
  switch (inputType) {
    case inputTypes.CHECKBOX:
      Input = (
        <FlexLayout disabled={disabled} flexDirection="column" space={1}>
          <Checkbox id={id} label={label} value={value} onChange={onChange} />
          {description && (
            <Text color="gray-600" variant="2xs-dense">
              {description}
            </Text>
          )}
        </FlexLayout>
      );
      break;
    case inputTypes.CURRENCY_MULTI_SELECT:
      Input = (
        <CurrencySelect
          isMulti
          value={value || []}
          width={width}
          onChange={onChange}
        />
      );
      break;
    case inputTypes.DEPARTMENT_MULTI_SELECT:
      Input = (
        <DepartmentSelect
          isMulti
          value={value}
          width={width}
          onChange={onChange}
        />
      );
      break;
    case inputTypes.MULTI_SELECT:
      Input = (
        <Select
          isMulti
          options={options}
          placeholder={placeholder || 'Select'}
          value={value}
          width={selectWidths[width]}
          onChange={onChange}
        />
      );
      break;
    case inputTypes.NUMBER_INPUT:
      const min = getMin();
      Input = (
        <NumberInput
          min={min}
          placeholder={placeholder || '...'}
          value={value}
          width={width}
          onChange={onChange}
        />
      );
      break;
    case inputTypes.SINGLE_SELECT:
      Input = (
        <Select
          isClearable={false}
          options={options}
          placeholder={placeholder || 'Select'}
          value={value}
          width={selectWidths[width]}
          onChange={onChange}
        />
      );
      break;
    case inputTypes.TEXT_INPUT:
      Input = (
        <TextInput
          placeholder={placeholder || 'Enter text'}
          value={value || ''}
          width={width}
          onChange={onChange}
        />
      );
      break;
    case inputTypes.USER_MULTI_SELECT:
      Input = (
        <UserSelect
          value={value || []}
          isMulti={true}
          width="520px"
          onChange={onChange}
        />
      );
      break;
    default:
      break;
  }

  return inputType === inputTypes.CHECKBOX ? (
    Input
  ) : (
    <InputLabel
      disabled={disabled}
      flex={width === 'fullWidth' ? '1 1 auto' : undefined}
      id={id}
      isRequired={isRequired}
      label={label}
    >
      {Input}
    </InputLabel>
  );
}

CustomSettingsInput.propTypes = {
  customSettings: PT.object.isRequired,
  description: PT.string,
  disabled: PT.bool,
  id: PT.string.isRequired,
  inputType: PT.oneOf(Object.keys(inputTypes)),
  label: PT.string,
  options: PT.arrayOf(
    PT.shape({
      value: PT.any.isRequired,
      label: PT.string.isRequired,
    }).isRequired,
  ),
  placeholder: PT.string,
  settingsKey: PT.string,
  width: PT.string,
  customSettingsUpdateFactory: PT.func.isRequired,
};

export default CustomSettingsInput;
