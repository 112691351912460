import { uniqBy } from 'lodash';

import { Select, useDebouncedState } from '~/eds';
import { api } from '~/redux';
import { Nullable, Uuid } from '~/types';

import { useResolveQuestions, useResolveQuestionsFromGroup } from '../hooks';

interface QuestionSelectProps {
  name: string;
  groupId: Uuid;
  value: Nullable<string[]>;
  onChange: (value: Nullable<string[]>) => void;
}
export const QuestionSelect = ({
  value,
  groupId,
  ...rest
}: QuestionSelectProps) => {
  const [debouncedSearch, _search, setDebouncedSearch] = useDebouncedState('');

  const {
    data: searchQuestions = [],
    isFetching,
  } = api.endpoints.getQuestions.useQuery({
    search: debouncedSearch,
    pageSize: 0,
    pageLimit: 10,
  });

  const questionIds = value ?? [];

  const {
    questions: resolvedQuestionsFromResolver = [],
    isFetching: isFetchingResolvedQuestionsFromResolver,
  } = useResolveQuestions({
    questionIds,
    skip: !questionIds.length,
  });

  // this covers an edge case where the admin is looking into the group.
  // the admin doesnt have permission to request that question directly, only throught the `question_groups/<id>/questions` endpoint
  const {
    questions: resolvedQuestionsFromGroups = [],
    isFetching: isFetchingResolvedQuestionsFromGroups,
  } = useResolveQuestionsFromGroup({
    groupId,
    questionIds,
    skip: !questionIds.length || !groupId,
  });

  const isFetchingResolvedQuestions =
    isFetchingResolvedQuestionsFromGroups ||
    isFetchingResolvedQuestionsFromResolver;

  const questions = uniqBy(
    [
      ...resolvedQuestionsFromResolver,
      ...resolvedQuestionsFromGroups,
      ...searchQuestions,
    ],
    'id',
  );
  return (
    <Select
      {...rest}
      isMulti
      enableSelectAll={false}
      isLoading={isFetching || isFetchingResolvedQuestions}
      value={value}
      onSearch={setDebouncedSearch}
      options={questions.map((question) => ({
        value: question.id,
        label: question.name ?? question.text,
      }))}
    />
  );
};
