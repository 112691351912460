import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RedirectToXRayModal } from '~/features/x-ray';
import { FlagType, useFlag } from '~/flags';
import { selectors } from '~/redux';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { useRouting } from '~/routing';
import { openInNewTab } from '~/utils/browser';

import { selectDocument } from '../ask-anything-button/selectors';
import {
  testIsInADocViewerPage,
  testIsPreSig,
} from '../ask-anything-button/utils';
import { ChatBot } from '../chatbot';
import { useGetUserMessageActions } from './hooks';
import { useChatBotSideContent } from './SideContent';

const EXPANDED_WIDTH = '65vw';
const COLLAPSED_WIDTH = '525px';

export const ChatBotPanel = () => {
  const dispatch = useDispatch();
  const { location } = useRouting();
  const hasAskAnything = useFlag(FlagType.AskAnything);
  const hasQuestionGroups = useFlag(FlagType.AskAnythingQuestionGroups);
  const hasConversationalAIV2 = useFlag(FlagType.ConversationalAIV2);
  const currentChatbot = useSelector(selectors.selectCurrentChatbot);
  const isVisible = useSelector(selectors.selectIsChatbotVisible);
  const panelMode = useSelector(selectors.selectPanelMode);
  const chatbotWidth = useSelector(selectors.selectChatbotWidth);
  const isTextInputDisabled = useSelector(selectors.selectIsTextInputDisabled);
  const document = useSelector(selectDocument);

  const context = useSelector(selectors.selectChatbotContext);
  const selectedContext = useSelector(selectors.selectChatbotSelectedContext);

  const isDocViewerPage = useMemo(
    () => testIsInADocViewerPage(location.pathname),
    [location.pathname],
  );

  useEffect(() => {
    if (!isDocViewerPage) {
      dispatch(chatbotSlice.actions.setActiveSourceIndex(null));
      dispatch(chatbotSlice.actions.setSources([]));
      dispatch(chatbotSlice.actions.setWidth(EXPANDED_WIDTH));
    } else {
      dispatch(chatbotSlice.actions.setWidth(COLLAPSED_WIDTH));
    }
  }, [isDocViewerPage]);

  const disableInput = useMemo(() => {
    return (
      (isDocViewerPage &&
        currentChatbot?.type === 'ticket' &&
        testIsPreSig(location.pathname) &&
        !document?.isLatestVersion) ||
      isTextInputDisabled
    );
  }, [isDocViewerPage, currentChatbot, document, isTextInputDisabled]);

  // question, question group and history tabs
  const { sideContent } = useChatBotSideContent({
    disableRun: !!disableInput,
  });

  const userMessageActions = useGetUserMessageActions({
    scope: currentChatbot?.type as
      | 'document_handler'
      | 'document_version'
      | 'all_documents',
  });

  if (!(hasAskAnything && currentChatbot && isVisible)) {
    return null;
  }

  const isExpanded = chatbotWidth === EXPANDED_WIDTH;

  return (
    <>
      <ChatBot
        panelProps={{
          title: 'Ask AI',
          mode: panelMode,
          width: chatbotWidth,
          moreActionsPosition: 'left',
          icon: {
            icon: 'lock' as const,
            tooltip:
              'This chat is only visible to you and system administrators.',
            position: 'right',
          },
          actions: [
            {
              icon: 'help',
              tooltip: 'Knowledge Center',
              onClick: () => {
                openInNewTab(
                  'https://support.evisort.com/hc/en-us/articles/1500006219501-Documents-Overview-Managing-Your-Documents',
                );
              },
              text: '',
            },
            {
              text: isExpanded ? 'Condense' : 'Expand',
              onClick: () => {
                dispatch(
                  chatbotSlice.actions.setWidth(
                    isExpanded ? COLLAPSED_WIDTH : EXPANDED_WIDTH,
                  ),
                );
              },
              icon: isExpanded ? 'minimize' : 'maximize',
            },
          ],
          hidden: {
            isHidden: false,
            onHide: () => {
              dispatch(chatbotSlice.actions.closeChatbot());
            },
          },
          sideContent: hasQuestionGroups ? sideContent : undefined,
        }}
        hasAskAnything={true}
        context={context}
        selectedContext={selectedContext}
        disableInput={!!disableInput}
        entity={currentChatbot}
        userMessageActions={hasQuestionGroups ? userMessageActions : []}
        onSelectContextFilter={(context) => {
          if (hasConversationalAIV2) {
            dispatch(chatbotSlice.actions.setSelectedContext(context));
          } else {
            dispatch(chatbotSlice.actions.setSelectedContextInterim(context));
          }
        }}
        onDisclaimerChange={(accepted) => {
          if (!accepted) {
            // Override the panel's width
            dispatch(chatbotSlice.actions.setWidth(EXPANDED_WIDTH));
          }
        }}
        onSelectMessageSource={(message, sourceIndex) => {
          if (message.sources && message.sources.length) {
            dispatch(chatbotSlice.actions.setSources(message.sources));
            dispatch(chatbotSlice.actions.setActiveSourceIndex(sourceIndex));
          }
        }}
      />
      <RedirectToXRayModal />
    </>
  );
};
