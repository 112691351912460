export enum DataFieldType {
  AGE = 'AGE',
  ARRAY_MULTIPLE = 'ARRAY_MULTIPLE',
  ARRAY_SINGLE = 'ARRAY_SINGLE',
  ATTACHMENT = 'ATTACHMENT',
  BOOLEAN = 'BOOLEAN',
  CLAUSE = 'CLAUSE',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  TEXT_AREA = 'TEXT_AREA',
}
