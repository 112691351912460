import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';
import {
  TurnTrackingHistoryType as HistoryState,
  TurnTrackingPartyType as PartyState,
} from '~/types';

export interface State {
  parties: PartyState[];
  history: HistoryState[];
}

export const name = SliceType.TurnTracking;

const initialState: State = {
  parties: [],
  history: [],
};

/*************
 * Reducers
 *************/

const resetTurnTrackingReducer = () => {
  return initialState;
};

const setTurnTrackingHistoryReducer = (
  state: State,
  action: PayloadAction<HistoryState[]>,
) => {
  state.history = action.payload;
};

const setTurnTrackingPartiesReducer = (
  state: State,
  action: PayloadAction<PartyState[]>,
) => {
  state.parties = action.payload;
};

/*************
 * Slice Definition
 *************/

export default createSlice({
  name,
  initialState,
  reducers: {
    resetTurnTracking: resetTurnTrackingReducer,
    setTurnTrackingHistory: setTurnTrackingHistoryReducer,
    setTurnTrackingParties: setTurnTrackingPartiesReducer,
  },
});
