import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Datepicker from 'react-datepicker';
import injectSheet from 'react-jss';

import styles from './EcDatepicker.styles';

function EcDatepicker({
  classes,
  date,
  dateFormat = 'MMM d, yyyy',
  disabled = false,
  error = false,
  isClearable = false,
  maxDate,
  minDate,
  placeholder,
  title,
  showMonthDropdown = true,
  showMonthYearPicker = false,
  showYearDropdown = true,
  isInModal = false,
  onCalendarOpenClose,
  onDateChange,
}) {
  return (
    <Datepicker
      title={title}
      className={classnames(classes.datepicker, {
        [classes.datepickerError]: error,
      })}
      selected={date}
      onChange={onDateChange}
      onCalendarOpen={() => (isInModal ? onCalendarOpenClose(true) : null)}
      onCalendarClose={() => (isInModal ? onCalendarOpenClose(false) : null)}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      dropdownMode="select"
      isClearable={isClearable}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      placeholderText={placeholder}
      showMonthYearPicker={showMonthYearPicker}
      dateFormat={dateFormat}
    />
  );
}

EcDatepicker.propTypes = {
  classes: PropTypes.object,
  date: PropTypes.object,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  isClearable: PropTypes.bool,
  isInModal: PropTypes.bool,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  showMonthDropdown: PropTypes.bool,
  showMonthYearPicker: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  onCalendarOpenClose: PropTypes.func,
};

export default injectSheet(styles)(EcDatepicker);
