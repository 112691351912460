import { camelizeKeys } from 'humps';
import { orderBy } from 'lodash';

import { parseClauseList } from '~/components/DocumentsViewPage/DocumentKeyProvisions/ClausesTable/ClausesTable.utils';
import { types } from '~/eds';
import {
  AlgorithmStatusType,
  DataFieldType,
  FileMimeType,
  UploadStatusType,
} from '~/enums';
import { everest, muse, pilot } from '~/services';
import {
  ClauseContent,
  Document,
  DocumentClause,
  DocumentViewClause,
  HighlightCoordinatesResponse,
  Nullable,
  PilotId,
  ProvisionTextInstance,
  ServerClause,
  Uuid,
  VisibilityLevel,
} from '~/types';

import { getDocument } from './document-folder';

type Provision = {
  id: number;
  name: string;
  content: string;
};

export interface ProvisionsParams {
  page: number;
  pageSize?: number;
  docId: number;
}

interface SearchResponse {
  count: number;
  results: Provision[];
}

interface ProvisionsResponse<T> {
  count: number;
  results: T[];
}

interface UpdateProvisionParams {
  provisionId: number;
  content: string;
  handlerId: number;
}

interface DeleteProvisionParams {
  documentHandlerId: PilotId;
  provisionId: PilotId;
}

export interface DocumentViewClauseResponse {
  content: ServerClauseContent[];
  name: string;
}

type ServerClauseContent = {
  content: string;
  coordinates: HighlightCoordinatesResponse[];
  html: unknown;
  html_tokens: number[];
  id: number;
  is_flagged: boolean;
  json_value: unknown;
  text: string;
};

export type DocumentServerResponse = {
  id: PilotId;
  current_version: {
    file_name: string;
    file_type: string;
    id: PilotId;
  };
  date_added: string;
  user_added: PilotId;
  document_name: string;
  date_modified: string;
  file_type: string;
  document_types: string[];
  party_list: string[];
  provisions: string[];
  path: { id: PilotId; name: string }[];
  provision_list: ProvisionTextInstance[];
  rebate_table: unknown;
  payment_table: unknown;
  processing_status: string;
  visibility_level: VisibilityLevel;
  algo_status: {
    OCR: {
      status: AlgorithmStatusType;
      message: string;
    };
  };
  is_processing_coordinates: boolean;
};

export type FieldValue = {
  display_value: string;
  value: string;
};

export type Field = {
  allow_new_options: boolean;
  class_name: string;
  coordinates: number[];
  file_type_restrictions: string[];
  help_text: string;
  html_tokens: number[];
  id: PilotId;
  is_beta: boolean;
  is_editable: boolean;
  is_flagged: boolean;
  is_nullable: boolean;
  name: string;
  type: DataFieldType;
  value: Nullable<FieldValue | FieldValue[]>;
  displayValue: string;
  values: Nullable<string[]>;
};

export type Section = {
  title: string;
  fields: Field[];
};
export interface DocumentInformationResponse {
  date_modified: string;
  id: PilotId;
  sections: Section[];
}

export const getMigratedProvisions = async (
  params: ProvisionsParams,
): Promise<SearchResponse> => {
  return pilot.get(`document/${params.docId}/migrated-provisions/`, {
    params: { page: params.page },
  });
};

export const getAllProvisions = async ({
  docId,
  page,
  pageSize = 10,
}: ProvisionsParams): Promise<ProvisionsResponse<DocumentClause>> => {
  const response = (await pilot.get(`document/${docId}/all-provisions/`, {
    params: { page, page_size: pageSize },
  })) as ProvisionsResponse<ServerClause>;
  return {
    count: response?.count,
    results: parseClauseList(response?.results),
  };
};

export const updateProvisionContent = ({
  provisionId,
  content,
  handlerId,
}: UpdateProvisionParams) => {
  return pilot.patch(`document/provision/${provisionId}/edit/`, {
    content,
    handlerId,
  });
};

export const deleteProvision = ({
  documentHandlerId,
  provisionId,
}: DeleteProvisionParams) => {
  const params = { document: documentHandlerId };
  return pilot.remove(`/document/provision/${provisionId}/`, { params });
};

export const getDocumentDetailClauses = async ({
  documentTag,
  documentId,
}: {
  documentTag?: Uuid;
  documentId?: PilotId;
}): Promise<DocumentViewClause[]> => {
  const response = (await pilot.get(
    documentId ? `document/${documentId}/provisions` : 'document/provisions',
    {
      params: { tag: documentTag },
    },
  )) as DocumentViewClauseResponse[];

  return parseDocumentViewClauses(response);
};

export const getDocumentFullProvisionsList = async ({
  documentTag,
  documentId,
}: {
  documentTag?: Uuid;
  documentId?: PilotId;
}): Promise<DocumentViewClause[]> => {
  const response = (await pilot.get(
    documentId ? `document/${documentId}/provisions` : 'document/provisions',
    {
      params: { tag: documentTag },
    },
  )) as DocumentViewClauseResponse[];

  return parseDocumentViewClauses(response);
};

export const downloadOcrPdf = async ({ tag }: { tag: types.Uuid }) => {
  return await pilot.get('/document/download/ocr-pdf', {
    params: { tag },
    responseType: 'blob',
  });
};

interface ConvertPdfToDocxParams {
  documentId: Uuid;
  versionId: Uuid;
}

export const convertPdfToDocx = async ({
  documentId,
  versionId,
}: ConvertPdfToDocxParams) => {
  return await everest.post(
    `/documents/${documentId}/versions/${versionId}/convert-to-docx`,
  );
};

export const getDocumentByTag = async ({
  tag,
}: {
  tag: types.Uuid;
}): Promise<Document> => {
  const serverResponse = (await pilot.get('/document/', {
    params: { tag },
  })) as DocumentServerResponse;
  return transformDocumentTagServerResponse(serverResponse);
};

/**
 * Given a documentId, resolves to an appropriate `DocumentViewer`-compatible file based on its original file mime.
 *
 * The resolver is based on file mimetypes (unofficial mimetypes are handled, but should be deprecated by the broader platform in the future to simply rely on official native mimetypes).
 * - If a file cannot be resolved, the original file is returned instead.
 * - If a file can be resolved, it will be `DocumentViewer`-compatible i.e.
 *   - doc/docx files are resolved to HTML files
 *   - image/pdf files are resolved to PDF files
 *
 * NOTE: This API method can be shifted entirely to a BE API that should just resolve and return a simple Document { id, name, file } object.
 */
export const resolveDocument = async ({
  documentId,
  documentVersionId,
  options = {},
}: {
  documentId?: Nullable<PilotId>;
  documentVersionId?: Nullable<PilotId>;
  options?: {
    autoLatestVersion?: boolean;
  };
}): Promise<{
  documentId?: Nullable<PilotId>;
  documentVersionId?: Nullable<PilotId>;
  file: File;
}> => {
  const { autoLatestVersion = true } = options;

  // If a documentVersionId is provided and autoLatestVersion, resolve the latest document (handler)
  let resolvedDocumentId = documentId ?? documentVersionId;

  if (documentVersionId && autoLatestVersion) {
    const docFromVersion = await getDocumentFromVersion(documentVersionId!);
    if (!docFromVersion) {
      throw new Error('File not found.');
    } else {
      resolvedDocumentId = docFromVersion.id;
    }
  }
  if (!resolvedDocumentId) {
    throw new Error('Either document or document version ID must be provided');
  }

  const isExactDocumentVersion = documentVersionId && !autoLatestVersion;
  let doc;
  let file;
  if (isExactDocumentVersion) {
    file = await getDocumentVersionOriginal(documentVersionId);
  } else {
    file = await getDocumentOriginal(resolvedDocumentId);
    doc = await getDocument(resolvedDocumentId);
  }
  const docName = doc?.name ?? '';

  if (!file) {
    throw new Error('File does not exist.');
  }

  switch (file.type) {
    case FileMimeType.Doc:
    case FileMimeType.DocUnofficial:
    case FileMimeType.Docx:
    case FileMimeType.DocxUnofficial:
      // doc/docx files are resolved to HTML files.  This can change (e.g. resolve to SFDT if platform is using EJ2 as the default viewer when it is ready)
      file = new File(
        [
          isExactDocumentVersion
            ? (await getDocumentVersionContent(documentVersionId)).html
            : await getDocumentHtml(resolvedDocumentId),
        ],
        docName,
        {
          type: FileMimeType.Html,
        },
      );
      break;
    case FileMimeType.Jpg:
    case FileMimeType.JpgUnofficial:
    case FileMimeType.Jpeg:
    case FileMimeType.JpegUnofficial:
    case FileMimeType.Pdf:
    case FileMimeType.Png:
    case FileMimeType.PngUnofficial:
    case FileMimeType.Tiff:
    case FileMimeType.TiffUnofficial: {
      file = new File(
        [
          isExactDocumentVersion
            ? file
            : await getDocumentPdf(resolvedDocumentId),
        ],
        docName,
        {
          type: FileMimeType.Pdf,
        },
      );
      break;
    }
    default:
      break;
  }
  return {
    file,
    documentId: resolvedDocumentId,
    documentVersionId,
  };
};

export const getDocumentHtml = async (documentId: PilotId): Promise<string> => {
  const response = (await pilot.get(`document/content/${documentId}/`)) as {
    html: string;
  };
  return response.html.replace(/\\"/g, '"').replace(/<br\/>/gi, '');
};

export const getDocumentPdf = async (documentId: PilotId): Promise<File> => {
  return pilot.get(`document/download/pdf/${documentId}`, {
    responseType: 'blob',
  });
};

export const getDocumentOriginal: (documentId: PilotId) => Promise<File> = (
  documentId,
) =>
  pilot.get(`/document/download/original/${documentId}`, {
    responseType: 'blob',
  });

export const getProvisionTypes = () => {
  return pilot.get('/search/suggestion/provision-type/');
};

export const getDocumentFromVersion = async (
  documentVersionId: PilotId,
): Promise<{ id: PilotId; name: string } | undefined> => {
  try {
    const response = (await pilot.get(
      `version/${documentVersionId}/documents/`,
    )) as {
      results: {
        id: PilotId;
        document_name: string;
        file_type: string;
      }[];
    };
    const doc = response.results[0];
    return {
      id: doc.id,
      name: `${doc.document_name}${doc.file_type}`,
    };
  } catch (error: any) {
    if (error.response?.status === 404) {
      throw new Error('Document not found.');
    }
  }
};

export const transformDocumentTagServerResponse = (
  serverResponse: DocumentServerResponse,
) => {
  return camelizeKeys(serverResponse, {
    process: function (key, convert, options) {
      return /[A-Z]+$/.test(key) ? key.toLowerCase() : convert(key, options);
    },
  }) as Document;
};

const transformServerClause = (
  clausesContent: ServerClauseContent[],
): ClauseContent[] =>
  clausesContent.map((content) => camelizeKeys(content) as ClauseContent);

export const parseDocumentViewClauses = (
  clauses: DocumentViewClauseResponse[],
) =>
  orderBy(
    clauses.map((clause) => ({
      content: transformServerClause(clause.content),
      name: clause.name,
    })),
    'name',
  );

interface ConvertContentToSfdtParams {
  content: string;
  type: '.html';
}

interface ConvertContentToSfdtResponse {
  text: string;
}

export const convertContentToSfdt = async ({
  content,
  type,
}: ConvertContentToSfdtParams): Promise<ConvertContentToSfdtResponse> => {
  const response = (await muse.post('/document/content-to-sfdt', {
    content,
    type,
  })) as object;
  return {
    text: JSON.stringify(response),
  };
};

interface GetDocumentSfdtParams {
  documentId: Uuid;
  versionId: Uuid;
}

interface SaveDocumentSfdtParams extends GetDocumentSfdtParams {
  document: File;
  isDoneEditing: boolean;
}

export const getDocumentSfdt = async ({
  documentId,
  versionId,
}: GetDocumentSfdtParams) => {
  return (await everest.get(
    `/documents/${documentId}/versions/${versionId}/download/sfdt`,
    {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    },
  )) as object;
};

export const saveDocumentSfdt = async ({
  documentId,
  versionId,
  document,
  isDoneEditing,
}: SaveDocumentSfdtParams) => {
  const formData: FormData = new FormData();
  formData.append('file', document);
  return await everest.post(
    `/documents/${documentId}/versions/${versionId}/sfdt`,
    formData,
    {
      params: { isDoneEditing },
    },
  );
};

export const backupDocumentSfdt = async ({
  documentId,
  versionId,
  document,
}: Omit<SaveDocumentSfdtParams, 'isDoneEditing'>) => {
  const formData: FormData = new FormData();
  formData.append('file', document);
  formData.append('file_type', 'sfdt');
  return await everest.post(
    `/documents/${documentId}/versions/${versionId}/backups`,
    formData,
  );
};

export const fetchDocumentInformation = (
  documentId: PilotId,
): Promise<DocumentInformationResponse> =>
  pilot.get(`/document/${documentId}/document-information/`);

type TokenizeSfdtContentResponse = Record<number, Record<number, number[]>>;
interface TokenizeSfdtContentParams {
  clauseIds: number[];
  content: string[];
}
export const tokenizeSfdtContent = async ({
  clauseIds,
  content,
}: TokenizeSfdtContentParams): Promise<TokenizeSfdtContentResponse> => {
  return await pilot.post('/document/provisions/tokenize', {
    clauseIdList: clauseIds,
    textList: content,
  });
};

export const tokenizeRiskContent = async ({
  riskId,
  textList,
  highlightsIndex = String(0),
}: {
  riskId: string;
  textList: string[];
  highlightsIndex?: string;
}): Promise<any> => {
  const formData = new FormData();
  formData.append('riskId', riskId);
  formData.append('textList', JSON.stringify(textList));
  formData.append('highlightsIndex', highlightsIndex);
  return await everest.post('/activities/tokenize', formData);
};

type CompareDocumentsSfdtParams = {
  referenceVersionId: Uuid;
  targetVersionId: Uuid;
  documentId: Uuid;
};

export const compareDocumentsSfdt = async ({
  referenceVersionId,
  targetVersionId,
  documentId,
}: CompareDocumentsSfdtParams) => {
  return (await everest.post(
    `/documents/${documentId}/versions/compare`,
    { referenceVersionId, targetVersionId },
    {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    },
  )) as object;
};

export const getDocumentContent = async ({
  documentVersionId,
  tag,
}: {
  tag: Uuid;
  documentVersionId: number;
}): Promise<{ words: { word: string; id: Nullable<number> }[] }> =>
  await pilot.get(`/version/${documentVersionId}/words`, { params: { tag } });

export const getDocumentAlgoStatus = async ({
  id,
}: {
  id: PilotId;
}): Promise<{
  algoStatus: Record<string, { status: number; message?: string }>;
  processingStatus: UploadStatusType;
  id: number;
}> => {
  const response = await pilot.get(`document/${id}/algo-status/`);
  return camelizeKeys(response) as {
    algoStatus: Record<string, { status: number; message?: string }>;
    processingStatus: UploadStatusType;
    id: number;
  };
};

export const getDocumentVersionContent = (
  versionId: PilotId,
): Promise<{ html: string }> => pilot.get(`version/${versionId}/content/`);

export const getDocumentVersionOriginal = (versionId: PilotId): Promise<File> =>
  pilot.get(`/version/${versionId}/original/`, { responseType: 'blob' });
