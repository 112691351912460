import { TreeNode } from '../../types';

export const hoverNodes = (
  nodes: TreeNode[],
  currentNode: TreeNode,
  isHovered: boolean,
) => {
  if (!currentNode?.key) return nodes;

  return [...nodes].map((node: TreeNode) => {
    return {
      ...node,
      isHovered: node.key === currentNode.key ? isHovered : false,
      children: node.children.map((child) => ({
        ...child,
        isHovered: child.key === currentNode.key ? isHovered : false,
      })),
    };
  });
};

export const selectNodes = (
  nodes: TreeNode[],
  currentNode: TreeNode,
): TreeNode[] => {
  if (!currentNode?.key) return nodes;

  return nodes.map((node) => {
    const isSelected = node.key === currentNode.key;
    return {
      ...node,
      isSelected,
      isExpanded: isSelected || node.isExpanded,
      children: node.children.map((child) => ({
        ...child,
        isSelected,
      })),
    };
  });
};

export const toggleNodeIsExpanded = (
  nodes: TreeNode[],
  currentNode: TreeNode,
): TreeNode[] => {
  return nodes.map((node) => {
    if (node.key === currentNode.key) {
      return { ...node, isExpanded: !node.isExpanded };
    }
    return node;
  });
};
