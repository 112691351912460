import { api } from '../createApi';
import { TagType } from '../TagType';

export const TURN_TRACKING_TAG = { type: TagType.TurnTracking, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.TurnTracking as TagType],
  endpoints: {
    getTurnTrackingHistory: {
      providesTags: [TURN_TRACKING_TAG],
    },
    getTurnTrackingParties: {
      providesTags: [TURN_TRACKING_TAG],
    },
    updateTurnTrackingEntry: {
      invalidatesTags: [TURN_TRACKING_TAG],
    },
    createTurnTrackingEntry: {
      invalidatesTags: [TURN_TRACKING_TAG],
    },
  },
});
