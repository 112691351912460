import PT from 'prop-types';
import React from 'react';

import { Icon } from '~/eds';
import { FlexLayout, Text } from '~/ui';

function Section({ children, description, icon, id, title }) {
  return (
    <FlexLayout flexDirection="column" space={6}>
      <FlexLayout flexDirection="column" space={2}>
        <FlexLayout alignItems="center" space={2} id={id}>
          <Icon icon={icon} color="status.active" />
          <Text color="gray-900" variant="l-dense-bold">
            {title}
          </Text>
        </FlexLayout>
        <Text color="gray-600" variant="xs-dense-medium">
          {description}
        </Text>
      </FlexLayout>
      {children}
    </FlexLayout>
  );
}

Section.propTypes = {
  children: PT.node,
  description: PT.oneOfType([PT.string, PT.node]).isRequired,
  icon: PT.string.isRequired,
  title: PT.string.isRequired,
};

export default Section;
