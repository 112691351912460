import React, { memo } from 'react';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { HashLink } from '../Link';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { Section } from './types';
import { getIconProps } from './utils';

interface Props {
  /** Is the current section? */
  isCurrent: boolean;
  /** Is the last section? */
  isLast: boolean;
  /** Is the section ordered? */
  isOrdered: boolean;
  /** Is section unstarted (i.e. occurs after the last completed and current section) */
  isUnstarted: boolean;
  /** Stepper section */
  section: Section;
  /** optional Callback to invoke when a step is clicked. */
  onClick?: (sectionId: string) => void;
}

export const StepperSection = memo(
  ({ isCurrent, isLast, isOrdered, isUnstarted, section, onClick }: Props) => {
    const { id, description, error, title } = section;

    const border = 'active';
    const disabled = isOrdered && isUnstarted;
    const textColor = isUnstarted
      ? ('text.secondary' as const)
      : ('text.primary' as const);
    const textVariant = isCurrent ? ('tiny-bold' as const) : ('tiny' as const);

    const divider = <Box borderLeft={border} flex="auto" my={0.5} />;

    const titleProps = {
      color: textColor,
      hash: id,
      variant: textVariant,
      onClick,
    };
    const TitleElement = onClick ? HashLink : Text;

    return (
      <Layout as="li" align="stretch" disabled={disabled} spacing={2}>
        <Layout align="center" direction="column">
          <Tooltip tooltip={error}>
            <Icon {...getIconProps({ isCurrent, section })} />
          </Tooltip>
          {!isLast && divider}
        </Layout>
        <Layout direction="column" pb={isLast ? undefined : 6}>
          <TitleElement {...titleProps}>{title}</TitleElement>
          {description && (
            <Text color="text.secondary" variant="tiny">
              {description}
            </Text>
          )}
        </Layout>
      </Layout>
    );
  },
);
