import React from 'react';

import { FormField, TextInput } from '~/eds';
import {
  FormField as FormFieldType,
  Nullable,
  ShortResponseField as ShortResponseFieldType,
} from '~/types';

type Props = {
  formField: FormFieldType<ShortResponseFieldType>;
  errors?: string[];
  onChange: (value: Nullable<string>) => void;
  onExplain?: (value: string) => void;
};

export const ShortResponseField = ({
  formField,
  errors = [],
  onExplain,
  onChange,
}: Props) => {
  return (
    <FormField
      label={formField.title}
      input={TextInput}
      optional={!formField.required}
      disabled={formField.disabled}
      readOnly={formField.disabled}
      value={formField.field.value}
      description={formField.description}
      enableCopy={formField.copiable}
      onChange={onChange}
      error={
        errors.length > 0
          ? formField.patternError ?? errors.join(', ')
          : undefined
      }
      name="test"
      onExplain={onExplain ? () => onExplain(formField.fieldName) : undefined}
    />
  );
};
