import React from 'react';

import { Button } from '~/eds';
import { Box, FlexLayout, Text } from '~/ui';

function SummaryRow({ children, title, onChange }) {
  return (
    <FlexLayout
      alignItems="center"
      justifyContent="space-between"
      space={12}
      mt="-1px"
      p={6}
      sx={{ borderBottom: 'border', borderTop: 'border' }}
    >
      <Text
        color="gray-700"
        variant="s-dense-bold"
        sx={{
          flex: '0 0 112px',
        }}
      >
        {title}
      </Text>
      <Box sx={{ flex: 'auto' }}>{children}</Box>
      <Button text="Change" variant="action" onClick={onChange} />
    </FlexLayout>
  );
}

export default SummaryRow;
