import React, { useCallback, useEffect, useRef, useState } from 'react';

import EcBooleanTextSearchPopup from '~/components/Shared/EcBooleanTextSearchPopup';
import { SEARCH_DEBOUNCE_MS } from '~/constants/debounces';
import { IconButton, Text } from '~/eds';
import { api } from '~/redux';
import { Box, FlexLayout, TextInput, Tooltip } from '~/ui';
import { debounce } from '~/utils/debounce';

// cannot use lodash/debounce because of their timer issue with jest: https://github.com/facebook/jest/issues/3465#issuecomment-623393230

const BaseBooleanSearchBar = ({
  includeOperatorButton = true,
  isDisabled,
  isSubmitting,
  value,
  onChange,
  onSubmit,
  onValidationError,
}) => {
  const [showSyntaxPopup, setShowSyntaxPopup] = useState(false);

  const [
    validateBooleanQuery,
    validationQueryResult,
  ] = api.endpoints.validateBooleanQuery.useLazyQuery();
  const validateQuery = useCallback(
    debounce((query) => {
      validateBooleanQuery(query);
    }, SEARCH_DEBOUNCE_MS),
    [validateBooleanQuery],
  );

  const { error } = validationQueryResult;
  const booleanQueryError = error?.response?.data?.detail;
  const booleanQuery = value;
  const isInputValueInvalid = booleanQuery && booleanQueryError;
  const handleBooleanInputChange = onChange;

  const handleSubmit = () => {
    if (!isInputValueInvalid) {
      onSubmit();
    }
  };

  const renderSearchInputAppendix = () => {
    if (!isInputValueInvalid && includeOperatorButton && booleanQuery.length) {
      const keyToPressName =
        navigator.platform.toUpperCase().indexOf('MAC') >= 0
          ? 'Return'
          : 'Enter';
      return (
        <Box mt={1}>
          <Text color="text.secondary" variant="tiny">
            Press <strong>{keyToPressName}</strong> to run search.
          </Text>
        </Box>
      );
    } else {
      return null;
    }
  };

  const inputRef = useRef(null);
  const focusSearchbar = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 500);
  };

  useEffect(() => {
    focusSearchbar();
  }, []);

  useEffect(() => {
    if (booleanQuery) {
      validateQuery(booleanQuery);
    }
  }, [booleanQuery]);

  useEffect(() => {
    onValidationError?.(booleanQueryError);
  }, [booleanQueryError]);

  return (
    <Box sx={{ width: '100%' }} mb={4}>
      <FlexLayout flexDirection="column">
        <FlexLayout>
          <Box sx={{ width: '100%' }}>
            <Tooltip
              content={
                isDisabled
                  ? 'Edit in Analyzer to add a Boolean Text Search'
                  : null
              }
            >
              <TextInput
                error={isInputValueInvalid}
                ref={inputRef}
                placeholder="Search filenames and content"
                value={booleanQuery}
                onChange={handleBooleanInputChange}
                onEnter={handleSubmit}
                disabled={isDisabled || isSubmitting}
                width="fullWidth"
                flexGrow={1}
              />
            </Tooltip>
          </Box>
          {includeOperatorButton && (
            <IconButton
              icon="info"
              tooltip="Search Operator Reference Guide"
              onClick={() => setShowSyntaxPopup(true)}
            />
          )}
        </FlexLayout>
        {renderSearchInputAppendix()}
      </FlexLayout>
      {showSyntaxPopup && (
        <EcBooleanTextSearchPopup
          handleOnCloseClick={() => setShowSyntaxPopup(false)}
        />
      )}
    </Box>
  );
};

export default BaseBooleanSearchBar;
