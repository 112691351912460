import {
  black2,
  black4,
  evisortLightYellow,
  semibold,
} from '../../../assets/shared-styles/general';

const documentVersionControlContainer = {
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
};

const styles = {
  documentVersionControlContainer: {
    ...documentVersionControlContainer,
  },
  documentVersionControlContainerOutdated: {
    ...documentVersionControlContainer,
    backgroundColor: evisortLightYellow,
  },
  documentVersionBannerText: {
    marginRight: '30px',
    paddingLeft: '25px',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: '13px',
    '& svg': {
      position: 'absolute',
      left: '15px',
    },
  },
  versionDropdownContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  viewerAlgoWarningWrapper: {
    padding: '10px',
    backgroundColor: 'rgba(255, 196, 73, .5)',
    fontSize: '12px',
  },
  viewerWarningWrapper: {
    marginTop: '64px',
    textAlign: 'center',
  },
  viewerWarningHeader: {
    margin: '16px auto 8px auto',
    color: black2,
    fontSize: '17px',
    fontWeight: semibold,
  },
  viewerWarningBody: {
    maxWidth: '560px',
    margin: '0 auto',
    color: black4,
    fontSize: '15px',
  },
  viewerTitleRightControls: {
    display: 'flex',
  },
  documentViewerInModalHeader: {
    minHeight: '66px',
  },
  viewTypeSwitch: {
    display: 'flex',
    border: 'solid 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    color: black2,
    fontSize: '12px',
    fontWeight: semibold,
    cursor: 'pointer',
    '& button': {
      padding: '12px 16px',
    },
    '& button:first-child': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.25)',
    },
  },
  viewSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  documentContentWrapper: {
    wordBreak: 'break-word',
    '& ::selection': {
      backgroundColor: 'rgba(255, 196, 73, 0.25)',
      mixBlendMode: 'multiply',
    },
    '& img': {
      maxWidth: '100%',
    },
    '& table': {
      wordBreak: 'break-word',
    },
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '64px 0',
    textAlign: 'center',
  },
  documentViewerInModal: {
    marginTop: '20px',
    padding: '15px',
    minHeight: '70vh',
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'scroll',
  },
  closeModalIcon: {
    marginLeft: '7px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

export default styles;
