import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { IconType, SharedReactionProps } from '../../types';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Text } from '../Text';

interface Props extends SharedReactionProps {
  /** Provides the updated toggled reaction value and updated count (if provided) */
  onChange: (updatedValue: boolean, updatedCount?: number) => void;
  /** Checkbox or Radio role */
  role?: 'checkbox' | 'radio';
}

export const Reaction = memo(
  ({
    count = 0,
    label: initialLabel,
    role = 'checkbox',
    type,
    value: isActive,
    onChange,
  }: Props) => {
    const styles = useStyles();

    const color = isActive ? 'inverse.text.primary' : 'text.secondary';

    let label = null;
    if (initialLabel) {
      label = isActive ? initialLabel : null;
    } else {
      label = count > 1 ? String(count) : null;
    }

    const handleChange = () => {
      const updatedValue = !isActive;
      const updatedCount = isActive ? Math.max(count - 1, 0) : count + 1;
      onChange(updatedValue, updatedCount);
    };

    return (
      <Layout
        align="center"
        as="button"
        bg={isActive ? 'status.active' : 'background'}
        border="border"
        color={color}
        spacing={1}
        styles={styles.pill.reaction.s}
        styleProps={{ isActive, isInteractive: true }}
        onClick={handleChange}
      >
        <Icon
          aria-checked={isActive}
          color="inherit"
          icon={`reaction-${type}` as IconType}
          label={type}
          role={role}
        />
        {label && (
          <Text
            color="inherit"
            styles={styles.font.monospaceNumeric}
            variant="tiny"
          >
            {label}
          </Text>
        )}
      </Layout>
    );
  },
);
