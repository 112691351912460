import {
  LegacyWorkflowList,
  WorkflowList,
} from '~/components/Workflow/WorkflowListPage';
import { FlagType, useFlag } from '~/flags';

const WorkflowBuilderList = () => {
  const workflowAclEnabled = useFlag(FlagType.WorkflowACL);
  const WorkflowListPage = workflowAclEnabled
    ? WorkflowList
    : LegacyWorkflowList;

  return <WorkflowListPage />;
};

export default WorkflowBuilderList;
