import React from 'react';

import {
  BulkImportUserSummaryType,
  BulkImportUserToogleActionName,
} from '~/enums';
import { Text } from '~/ui';

export const buildImportSummaryContent = (importResults, actionsState) => {
  const {
    emailNewUsers,
    deleteMissingUsers,
    updateExistingUsers,
  } = actionsState;

  const newUserDescriptionPartAddedWhenOn = (
    <Text variant="tiny-bold">and emailed </Text>
  );
  const newUserDescription = (
    <Text variant="tiny">
      Users that will be added{' '}
      {emailNewUsers && newUserDescriptionPartAddedWhenOn}
      upon import.
    </Text>
  );

  const existingUserDescriptionPartAddedWhenOn = (
    <>
      {' '}
      <Text variant="tiny-bold">will have their information updated</Text> on
      import
    </>
  );
  const existingUserDescription = (
    <Text variant="tiny">
      Number of users that exist in the uploaded list
      {updateExistingUsers && existingUserDescriptionPartAddedWhenOn}.
    </Text>
  );

  const missingUserDescriptionPartAddedWhenOn = (
    <>
      <Text variant="tiny-bold" color="red-600">
        Users will be removed
      </Text>{' '}
      from your account on import.
    </>
  );
  const missingUserDescription = (
    <Text variant="tiny">
      Users present in your account, but not in the uploaded list.
      <br />
      {deleteMissingUsers && missingUserDescriptionPartAddedWhenOn}
    </Text>
  );

  return {
    newUsers: {
      resultTypeName: 'New users',
      resultTypeDescription: newUserDescription,
      resultTypeValue: importResults[BulkImportUserSummaryType.NewUsers],
      resultTypeActionName: 'Email new users sign-in information',
      resultTypeAction: BulkImportUserToogleActionName.EmailNewUsers,
    },
    existingUsers: {
      resultTypeName: 'Existing users',
      resultTypeDescription: existingUserDescription,
      resultTypeValue: importResults[BulkImportUserSummaryType.Existing],
      resultTypeActionName: 'Update existing users with data from upload',
      resultTypeAction: BulkImportUserToogleActionName.UpdateExistingUsers,
    },
    toBeDeletedUsers: {
      resultTypeName: 'Missing users',
      resultTypeDescription: missingUserDescription,
      resultTypeValue: importResults[BulkImportUserSummaryType.ToBeDeleted],
      resultTypeActionName: 'Delete missing users',
      resultTypeAction: BulkImportUserToogleActionName.DeleteMissingUsers,
      isDangerous: true,
    },
  };
};
