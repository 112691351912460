import React from 'react';

import { SharedFileSystemProps } from '../../types';
import { Filename } from '../Filename';
import { Folder } from '../Folder';

export const FileSystem = ({ file, folder }: SharedFileSystemProps) => {
  if (file) {
    return <Filename {...file} />;
  }
  if (folder) {
    return <Folder {...folder} />;
  }
  return null;
};
