/** This regex matches markdown link format. E.g. [Link Label](http://URL) */
export const MARKDOWN_LINK_REGEXP = /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g;

/** This regex matches a file name with an extension. e.g. "document 1.pdf" */
export const FILENAME_REGEXP = /\b[\w\-.,()(__)# ]+\.(pdf|doc|docx|tiff)\b/g;

export const THINKING_EVENTS_FADE_TIME = 1000;
export const THINKING_EVENTS_GENERATING_RESPONSE = 'Generating response...';
export const THINKING_EVENTS_ALL_DONE = 'ALL_DONE';
export const THINKING_EVENTS_SOURCE = 'RAG DATA FROM:';
