import React, { useState } from 'react';
import injectSheet from 'react-jss';

import { updatePassword } from '~/api';
import LoginPassword from '~/components/LoginPage/LoginPassword';
import PasswordInputField from '~/components/LoginPage/PasswordInputField';
import EcButton from '~/components/Shared/EcButton';
import EcCard from '~/components/Shared/EcCard';
import { showToast } from '~/components/Shared/EcToast';
import CheckmarkIcon from '~/components/Shared/Icons/CheckmarkIcon';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { useAsync, usePasswordsWithValidation } from '~/hooks';
import { ERROR } from '~/types/toast.types';

import styles from './ChangePassword.styles';

const cardContentStyles = { padding: '24px 16px' };

function ChangePassword({ classes }) {
  const [currentPassword, setCurrentPasswordChange] = useState();
  const [
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    validationResult,
  ] = usePasswordsWithValidation();

  function clearForm() {
    setCurrentPasswordChange('');
    setPassword('');
    setConfirmPassword('');
  }

  const formData = {
    current_password: currentPassword,
    new_password: password,
    verify_new_password: password,
  };

  const { executor: handleChangePassword, isLoading } = useAsync(
    updatePassword,
    { formData },
    {
      successHandler: () => clearForm(),
      successToastMessage: 'Password has been updated.',
      errorHandler: (error) => showToast(ERROR, `${error.message}`),
    },
  );

  return (
    <EcCard title="Change Password" contentStyles={cardContentStyles}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <form className="ChangePassword" onSubmit={handleChangePassword}>
          <div className={classes.settingsRow}>
            <PasswordInputField
              id="currentPassword"
              label="Current Password"
              labelClass={classes.inputLabel}
              value={currentPassword}
              onChange={setCurrentPasswordChange}
            />
          </div>

          <div className={classes.settingsRow}>
            <LoginPassword
              passwordLabel="New Password"
              confirmPasswordLabel="Verify Password"
              labelClass={classes.inputLabel}
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              validationResult={validationResult}
            />
          </div>

          <div className={classes.settingsRow}>
            <EcButton
              id="user_changePassword_updatePasswordButton"
              yellow
              text="Update Password"
              iconLeft={<CheckmarkIcon size="20" />}
              onClick={handleChangePassword}
              disabled={!currentPassword || !validationResult.allValid}
            />
          </div>
        </form>
      )}
    </EcCard>
  );
}

export default injectSheet(styles)(ChangePassword);
