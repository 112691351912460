export default `
<svg
  aria-hidden="true"
  aria-label="briefcase"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18 7.5H16V6.5C16 5.40625 15.0938 4.5 14 4.5H10C8.875 4.5 8 5.40625 8 6.5V7.5H6C4.875 7.5 4 8.40625 4 9.5V17.5C4 18.625 4.875 19.5 6 19.5H18C19.0938 19.5 20 18.625 20 17.5V9.5C20 8.40625 19.0938 7.5 18 7.5ZM9.5 6.5C9.5 6.25 9.71875 6 10 6H14C14.25 6 14.5 6.25 14.5 6.5V7.5H9.5V6.5ZM18.5 17.5C18.5 17.7812 18.25 18 18 18H6C5.71875 18 5.5 17.7812 5.5 17.5V9.5C5.5 9.25 5.71875 9 6 9H18C18.25 9 18.5 9.25 18.5 9.5V17.5Z"
    fill="currentcolor"
  />
</svg>
`;
