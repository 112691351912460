import React, { useEffect } from 'react';
import { useStyles } from 'uinix-ui';

import AuthenticationStore from '~/auth';
import { Button, Layout, Logo, Text } from '~/eds';
import { LOGIN_CONSTANTS } from '~/features/login';
import { getClientFromUrl, useRouting } from '~/routing';

const Page = () => {
  const AS = AuthenticationStore();

  const client = getClientFromUrl();

  const { getSearchParam, navigate } = useRouting();

  const styles = useStyles();

  // redirect if user is already logged in
  useEffect(() => {
    const isSessionExpired = AS.isAuthSessionExpired();
    const isInactiveTimeout = AS.isInactiveTimeoutReached();
    const isUserLoggedIn = AS.isLoggedIn();
    const isUserLoggedOut =
      isSessionExpired || isInactiveTimeout || !isUserLoggedIn;

    if (!isUserLoggedOut) {
      navigate(
        getSearchParam(LOGIN_CONSTANTS.SEARCH_PARAMS.LOGIN_REDIRECT_TO, '/'),
      );
    }
  }, []);

  const handleEmbedLogin = () => {
    const newUrl = new URL(window.location.href);
    const params = new URLSearchParams(newUrl.search);

    newUrl.pathname = `${client}/login`;
    newUrl.search = params.toString();

    window.open(newUrl.href, '_blank', 'width=480,height=700,left=200,top=200');
  };

  return (
    <Layout
      align="center"
      justify="center"
      styles={styles.background.darkChrome}
    >
      <Layout align="center" preset="sections">
        <Logo mode="icon" theme="dark" />
        <Text color="inherit" textAlign="center" variant="title">
          Log in to your workspace
        </Text>
        <Button
          isFullWidth
          text="Log in"
          variant="primary"
          onClick={handleEmbedLogin}
        />
      </Layout>
    </Layout>
  );
};

export default Page;
