import { black4, semibold } from '../../../assets/shared-styles/general';

const styles = {
  pageHeader: {
    marginBottom: '21px',
    display: 'inline-flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  pageBackAction: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    cursor: 'pointer',
  },
  pageTitle: {
    flexGrow: 1,
  },
  subTitle: {
    color: black4,
    fontSize: '13px',
    fontWeight: semibold,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  pageName: {
    margin: '4px 0',
  },
};

export default styles;
