import { identity } from 'lodash';
import React, { useMemo } from 'react';

import {
  Button,
  Card,
  ContentContainer,
  Grid,
  IconButton,
  Label,
  Layout,
} from '../../components';
import { BaseLoadingContentProps, Input, Nullable } from '../../types';
import { typedMemo } from '../../utils';

// C(R)UD actions!
type Action = 'create' | 'update' | 'delete';

interface Datum {
  [key: string]: any;
}

type InputProps = object;

type Column<D, V, M extends boolean> = {
  /** Column key is related to the of an item where its value is determined */
  key: string;
  /** A valid Input component (e.g. `TextInput`, `NumberInput`, `Select`, `Checkbox`) */
  input: React.ElementType;
  /** Column (input) label */
  label: string;
  /** Information that will be displayed in info icon tooltip */
  info?: string;
  /** Input props */
  inputProps?: InputProps;
  /** Factory API for `inputProps` */
  createInputProps?: (datum: D, index: number) => InputProps;
  /** Map an input value from its native onChange handler to another value */
  mapValue?: (value: V) => any;
} & Input<V, M>;

interface Props<V, M extends boolean, D extends Datum> {
  /** Crud actions */
  actions: {
    add: {
      text: string;
      disabled?: boolean;
      tooltip?: string;
    };
    delete: (
      datum: Datum,
    ) => {
      text?: string;
      disabled?: boolean;
      tooltip?: string;
    };
  };
  /** Specification for the column to render items (e.g. Input) */
  columns: Column<D, V, M>[];
  /** CSS gridTemplateColumns specification (e.g. ['1fr', '2fr'] */
  columnWidths: string[];
  /** Generic data */
  data: D[];
  /** Capture the updated data (the whole array) and actionMeta for further upstream handling of the specific updated data */
  onUpdate: (
    updatedData: D[],
    actionMeta: {
      action: Action;
      datum: D;
      index: number;
      value?: Nullable<V>;
    },
  ) => void;
  // Disables the entire form
  disabled?: boolean;
  /** Loading content */
  loadingContent?: BaseLoadingContentProps;
}

export const CrudForm = typedMemo(
  <V extends unknown, M extends boolean, D extends Datum>({
    actions,
    columns,
    columnWidths: overrideColumnWidths,
    data,
    disabled: overrideDisabled,
    loadingContent,
    onUpdate,
  }: Props<V, M, D>) => {
    // always append the remove action
    const columnWidths = useMemo(() => [...overrideColumnWidths, 'auto'], [
      overrideColumnWidths,
    ]);

    const header = useMemo(
      () => (
        <>
          {columns.map(({ info, label }) => (
            <Label key={label} info={info}>
              {label}
            </Label>
          ))}
          <div /> {/* empty header cell reserved for remove action */}
        </>
      ),
      [],
    );

    const fields = data.map((datum, index) => {
      const inputs = columns.map(
        ({
          key,
          createInputProps,
          disabled,
          error,
          id,
          input: Input,
          inputProps: overrideInputProps,
          mapValue = identity,
          name,
          readOnly,
          placeholder,
        }) => {
          const value = datum[key];
          const inputProps = {
            ...(createInputProps?.(datum, index) ?? overrideInputProps ?? {}),
            name,
            value,
            onChange: (updatedValue: V) => {
              const updatedDatum = {
                ...datum,
                [key]: mapValue(updatedValue),
              };
              const updatedData = data.map((d, i) =>
                i === index ? updatedDatum : d,
              );
              onUpdate(updatedData, {
                action: 'update',
                datum: updatedDatum,
                index,
                value: updatedValue,
              });
            },
            disabled: overrideDisabled || disabled,
            id,
            error,
            placeholder,
            readOnly,
          };
          return <Input key={key} {...inputProps} />;
        },
      );

      const deleteAction = {
        ...actions.delete(datum),
        onClick: () => {
          const updatedData = data.filter((_, i) => i !== index);
          onUpdate(updatedData, {
            action: 'delete',
            datum,
            index,
          });
        },
      };

      return (
        <>
          {inputs}
          <IconButton
            icon="trash"
            {...deleteAction}
            disabled={overrideDisabled || deleteAction.disabled}
          />
        </>
      );
    });

    return (
      <ContentContainer loadingContent={loadingContent}>
        <Card mode="bordered">
          <Layout direction="column" spacing={3}>
            <Grid
              columns={columns.length}
              columnSpacing={4}
              columnWidths={columnWidths}
              rowSpacing={3}
            >
              {header}
              {fields}
            </Grid>
            <Button
              {...actions.add}
              disabled={overrideDisabled || actions.add.disabled}
              icon="plus"
              iconPosition="left"
              onClick={() => {
                const newValue = null;
                const updatedDatum = Object.fromEntries(
                  columns.map(({ key }) => [key, newValue]),
                ) as D;
                onUpdate([...data, updatedDatum], {
                  action: 'create',
                  datum: updatedDatum,
                  value: newValue,
                  index: data.length,
                });
              }}
              variant="secondary"
            />
          </Layout>
        </Card>
      </ContentContainer>
    );
  },
);
