import React from 'react';

import { Box, HashLink } from '~/eds';

import { MAIN_CONTENT_ID } from './constants';

export const SkipToMainLink = () => {
  return (
    <Box styles={componentStyles.hidden}>
      <HashLink disableScroll hash={MAIN_CONTENT_ID}>
        Skip to main content
      </HashLink>
    </Box>
  );
};

const componentStyles = {
  hidden: {
    '> a': {
      backgroundColor: 'background',
      left: '-9999px',
      opacity: 0,
      padding: '12px',
      position: 'absolute',
      zIndex: 999,
    },
    '> a:focus': {
      left: 0,
      opacity: 1,
    },
  },
};
