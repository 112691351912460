import {
  black1,
  black4,
  semibold,
} from '../../../../assets/shared-styles/general';

const styles = {
  info: {
    marginTop: '5px',
    width: '800px',
    height: '20px',
    fontSize: '15px',
    color: '#404040',
  },
  folderEmailIntake: {
    marginTop: '19px',
    display: 'flex',
    alignItems: 'row',
    justifyContent: 'space-between',
    width: '430px',
  },
  folderSettingsContent: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      flexShrink: 0,
    },
  },
  folderNameWrapper: {
    paddingLeft: '12px',
  },
  folderName: {
    color: black1,
    fontSize: '16px',
  },
  folderPath: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '4px',
    color: black4,
    fontSize: '13px',
    fontWeight: semibold,
  },

  changeLocationAction: {
    paddingTop: '5px',
  },
};
export default styles;
