import { useEffect, useState } from 'react';

export const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsOnline(window.navigator.onLine);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return isOnline;
};
