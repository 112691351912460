import { User } from '../types';

interface Options {
  isCurrentUser?: boolean;
  isDeleted?: boolean;
  placeholderName?: string;
  shouldAbbreviateLastName?: boolean;
}

export const getUserName = (user: User, options: Partial<Options> = {}) => {
  const { firstName, lastName } = user;
  const {
    placeholderName = 'Unknown',
    shouldAbbreviateLastName = false,
    isDeleted = false,
    isCurrentUser = false,
  } = options;

  // TODO remove isDeleted from options
  const isUserDeleted = user.isDeleted ?? isDeleted;

  const nameList = [];
  if (firstName) {
    nameList.push(firstName);
  }
  if (lastName) {
    nameList.push(shouldAbbreviateLastName ? `${lastName[0]}.` : lastName);
  }
  if (isUserDeleted) {
    nameList.push('(Deactivated)');
  }
  if (isCurrentUser) {
    nameList.push('(You)');
  }

  return nameList.join(' ') || placeholderName;
};
