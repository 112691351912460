import { createSelector } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';
import { Uuid } from '~/types';

import { DocumentEditorType, RootState } from '../types';

const slice = (rootState: RootState) => rootState[SliceType.DocumentEditors];

export const selectEditors = ({
  documentEditor,
  versionId,
}: {
  documentEditor: DocumentEditorType;
  versionId: Uuid;
}) =>
  createSelector(
    slice,
    (state): Set<Uuid> => {
      return state[versionId]?.[documentEditor] ?? new Set();
    },
  );
