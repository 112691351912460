import { endOfDay, sub } from 'date-fns';

import { Field } from '~/components/Shared/Filters_DEPRECATED/types';
import { Nullable } from '~/types';

import { statusOptions } from './constants';
import {
  AttemptStatus,
  ConfigurationType,
  Endpoint,
  EndpointStatus,
  EventList,
  EventsType,
  FieldProps,
} from './types';

export const testIsActiveStatus = (status: EndpointStatus) =>
  status === 'active';

export const getStatusChip = (status: EndpointStatus | AttemptStatus) => {
  switch (status) {
    case 'active':
      return { text: 'Active', status: 'success' };
    case 'suspended':
      return { text: 'Suspended', status: 'danger' };
    case 'failed':
      return { text: 'Failed', status: 'danger' };
    case 'published':
      return { text: 'Success', status: 'success' };
    case 'pending':
      return { text: 'Pending', status: 'info' };
    default:
      return { text: 'Unknown', status: 'info' };
  }
};

export const getDisabledTooltip = (
  configuration: Nullable<ConfigurationType>,
) => {
  if (configuration) {
    if (!configuration?.url) {
      return 'Please provide a valid URL';
    } else if (Object.keys(configuration?.events).length === 0) {
      return 'Select at least one event';
    }
  }
};

export const getAttemptsDisabledTooltip = (
  isLoadingExportAttempts: boolean,
) => {
  return isLoadingExportAttempts ? 'Exporting attempts...' : 'Export Excel';
};

export const transformToStringArray = (events: EventsType) => [
  ...Object.keys(events).reduce<string[]>((acc, key) => {
    return [...acc, ...events[key]];
  }, []),
];

export const checkActiveEndpointEvents = ({
  activeEndpoint,
  eventGroups,
}: {
  activeEndpoint: Endpoint;
  eventGroups: EventList[];
}) => {
  const updatedEventGroups = eventGroups.map((group) => ({
    ...group,
    options: group.options.map((option) => ({
      ...option,
      checked: activeEndpoint.events.includes(option.value) ?? false,
    })),
  }));

  const transformEvents = updatedEventGroups.reduce((acc, group) => {
    acc[group.name] = group.options
      .filter((option) => option.checked === true)
      .map((option) => option.value);
    return acc;
  }, {} as EventsType);

  return transformEvents;
};

export const getFields = ({
  eventGroups,
  endpoints,
}: FieldProps): Record<string, Field> => {
  const today = new Date();
  const max = endOfDay(today);
  const min = sub(today, { days: 30 });

  return {
    endpointId: {
      id: 'endpointId',
      label: 'Endpoint ID',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        options: endpoints.map(({ id }) => ({
          label: id,
          value: id,
        })),
      },
    },
    endpointUrl: {
      id: 'endpointUrl',
      label: 'Endpoint URL',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        options: endpoints.map(({ endpointUrl }) => ({
          label: endpointUrl,
          value: endpointUrl,
        })),
      },
    },
    eventType: {
      id: 'eventType',
      label: 'Event Type',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        options: eventGroups,
      },
    },
    datetime: {
      id: 'datetime',
      label: 'Date Sent',
      type: 'date',
      whitelistOperatorIds: ['date_in_the_last', 'date_on'],
      settings: {
        max,
        min,
        getDayTooltip: (date: Date) => {
          if (date > max) {
            return 'You can not select a future date';
          } else if (date < min) {
            return 'You can not go back more than 30 days';
          }
        },
      },
    },
    status: {
      id: 'status',
      label: 'Status',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        options: statusOptions,
      },
    },
  };
};
