/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { forEach, groupBy, maxBy, min, minBy } from 'lodash';
import { PDFViewer } from 'pdfjs-dist/types/web/pdf_viewer';
import React from 'react';
import { v4 as uuid } from 'uuid';

import {
  Highlight as HighlightComponent,
  HighlightProps,
  OverlapPriority,
} from '~/components/Shared/EcPdfViewerHighlighter';
import { types } from '~/eds';
import { HighlightType } from '~/enums';
import {
  ClauseContent,
  Highlight,
  HighlightCoordinatesResponse,
  HighlightPosition,
  HighlightRectangle,
  Nullable,
  ScaledHighlight,
} from '~/types';
import { getViewport, scrollToPage } from '~/utils/pdfjs';

interface Page {
  width: number;
  height: number;
  rotation?: number;
}

interface Word {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  word: string;
  blockNumber: number;
  lineNumber: number;
  pageNumber: number;
  pageWidth: number;
  pageHeight: number;
  pageRotation: number;
}

export function calculateBoundingRectFromRectangles(
  rects: HighlightRectangle[],
  pageNumber: number,
  pageInfo: Page,
): HighlightRectangle {
  return {
    x1: minBy(rects, 'x1')!.x1,
    x2: maxBy(rects, 'x2')!.x2,
    y1: minBy(rects, 'y1')!.y1,
    y2: maxBy(rects, 'y2')!.y2,
    width: pageInfo.width,
    height: pageInfo.height,
    pageNumber: pageNumber,
  };
}

export function createPositionObject(
  highlightPositionApi: HighlightCoordinatesResponse[],
): Nullable<HighlightPosition> {
  let position = null;
  if (highlightPositionApi && highlightPositionApi.length > 0) {
    position = {
      ...highlightPositionApi[0].coordinates,
      boundingRect: createEmptyboundingRect(),
    };
  }
  return position;
}

export function validateHighlightCoordinatesFormat(
  highlightCoordinates: any,
): boolean {
  return (
    highlightCoordinates &&
    highlightCoordinates.length > 0 &&
    highlightCoordinates[0].coordinates &&
    highlightCoordinates[0].coordinates.rects &&
    highlightCoordinates[0].coordinates.pageNumber
  );
}

function createEmptyboundingRect(): HighlightRectangle {
  return {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
    pageNumber: 0,
    height: 0,
    width: 0,
  };
}

/** This function iterates over an array of words to create the position
 * object of a highlight. */
export function getHighlightPosition(
  words: Word[],
): Nullable<HighlightPosition> {
  let rects: HighlightRectangle[] = [];
  const pagesInfo: Record<number, Page> = {};
  const wordsByPage = groupBy(words, 'pageNumber');

  if (!words || words.length === 0) return null;

  forEach(wordsByPage, (words, page) => {
    const pageNumber = parseInt(page) + 1;
    const firstWord = words[0];
    pagesInfo[pageNumber] = {
      height: firstWord.pageHeight,
      width: firstWord.pageWidth,
      rotation: firstWord.pageRotation,
    };
    const blockRectangles = getCoordinatesPerBlock(words, pageNumber);
    rects = rects.concat(blockRectangles);
  });
  const minimumPage = min(Object.keys(wordsByPage));
  let minPage = 1;
  if (minimumPage) {
    minPage = parseInt(minimumPage) + 1;
  }
  const boundingRect: HighlightRectangle = {
    x1: minBy(rects, 'x1')!.x1,
    x2: maxBy(rects, 'x2')!.x2,
    y1: minBy(rects, 'y1')!.y1,
    y2: maxBy(rects, 'y2')!.y2,
    width: pagesInfo[minPage].width,
    height: pagesInfo[minPage].height,
    pageNumber: minPage,
  };

  return {
    boundingRect,
    pageNumber: minPage,
    rects,
  };
}

function getCoordinatesPerBlock(words: Word[], pageNumber: number) {
  const wordsByBlock = groupBy(words, 'blockNumber');
  let rectangles: HighlightRectangle[] = [];
  forEach(wordsByBlock, (words) => {
    const lineRectangles = getCoordinatesPerLine(words, pageNumber);
    rectangles = rectangles.concat(lineRectangles);
  });

  return rectangles;
}

function getCoordinatesPerLine(words: Word[], pageNumber: number) {
  const wordsByLine = groupBy(words, 'lineNumber');
  const rectangles: HighlightRectangle[] = [];
  forEach(wordsByLine, (words) => {
    const { pageWidth, pageHeight } = words[0];
    rectangles.push({
      x1: minBy(words, 'x0')!.x0,
      x2: maxBy(words, 'x1')!.x1,
      y1: minBy(words, 'y0')!.y0,
      y2: maxBy(words, 'y1')!.y1,
      width: pageWidth,
      height: pageHeight,
      pageNumber,
    });
  });
  return rectangles;
}

export function scrollToHighlight(
  viewer: PDFViewer | undefined,
  highlightPosition: HighlightPosition,
  distanceToTopOfViewer = 0,
) {
  const { pageNumber = 1, rects } = highlightPosition;
  const pageViewport = getViewport(viewer, pageNumber);
  let destArray;
  if (rects && rects.length > 0 && pageViewport) {
    const firstRect = minBy(
      rects
        .filter((rect) => rect.pageNumber === pageNumber)
        .map((rect) => scaledCoordinates(rect, pageViewport)),
      'top',
    );
    if (firstRect) {
      destArray = pageViewport.convertToPdfPoint(
        0,
        firstRect.top - distanceToTopOfViewer,
      );
    }
  }
  scrollToPage(viewer, pageNumber, destArray);
}

export const scaledCoordinates = (
  rectangle: HighlightRectangle,
  viewport: any,
) => {
  const { width, height } = viewport;
  const x1 = (width * rectangle.x1) / rectangle.width;
  const y1 = (height * rectangle.y1) / rectangle.height;

  const x2 = (width * rectangle.x2) / rectangle.width;
  const y2 = (height * rectangle.y2) / rectangle.height;

  return {
    left: x1,
    top: y1,
    width: x2 - x1,
    height: y2 - y1,
    pageNumber: rectangle.pageNumber,
  };
};

/**
 * @param highlight the scaled highlight object, which is the one used internally by the vendor
 * @param isActiveHighlight if the highlight is active or not
 * @param highlightProps The Highlight component props that can be overwritten.
 * @returns the Highlight Component with it's variants evaluated for each highlight.
 */
export const renderHighlight = (
  highlight: ScaledHighlight,
  isActiveHighlight: boolean,
  highlightProps: Partial<HighlightProps>,
) => {
  let variant;
  let isActive;
  let priority;
  if (highlight.type) {
    const isAIHighlight =
      highlight.type === HighlightType.Provision ||
      highlight.type === HighlightType.DocumentInfo;

    variant = isAIHighlight ? 'ai' : 'default';
    priority = isAIHighlight ? 'high' : 'normal';
    isActive = isAIHighlight || isActiveHighlight;
  }
  return (
    <HighlightComponent
      key={highlight.id ?? uuid()}
      variant={variant as types.HighlightType}
      isActive={isActive}
      overlappingPriority={priority as OverlapPriority}
      id={highlight.id}
      position={highlight.position}
      {...highlightProps}
    />
  );
};

export function getClauseHighlightId(id: number, type: HighlightType) {
  return `${type}-${id}`;
}

export const getClauseHighlight = (clause: ClauseContent): Highlight => {
  return {
    type: HighlightType.Provision,
    id: getClauseHighlightId(clause.id, HighlightType.Provision),
    position: createPositionObject(clause.coordinates) as HighlightPosition,
    content: { text: clause.content },
    comment: { text: '', emoji: '' },
  };
};

export const getClauseIdFromHighlight = (
  highlight: Nullable<string>,
): Nullable<number> => {
  const validFormatRegex = new RegExp(/(provision-\d+)/gm);
  const isValid = highlight && validFormatRegex.test(highlight);
  return isValid ? Number(highlight!.split('-')[1]) : null;
};
