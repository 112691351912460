export const judgmentResultActivity = (status) => [
  {
    id: 'e9b93f13-5d9a-4f38-a38e-c78abccb2b23',
    type: 'judgment result',
    modifiedDate: new Date().toISOString(),
    userId: 3,
    versionNumber: 1,
    data: {
      id: '7fc4efa5-3e58-47cf-9426-c9f4ea737696',
      status: status,
      note: status,
    },
  },
];

export const ticket = {
  id: 'ae7b43d2-9e10-4c9b-9838-796a5486fd37',
  name: 'Custody Agreement',
  creatorId: 1,
  createdDate: '2021-08-17T17:33:32.524842Z',
  stage: 'edit',
  status: 'editing',
  participantIds: [1],
  permissionMap: {
    EDIT: [
      'RISK_FLAGGING',
      'ACTIVITY_LOG',
      'REVIEWER',
      'EDIT_FORM',
      'SHARE',
      'EDIT_PARTICIPANTS',
      'VIEW_APPROVAL_DETAILED',
      'SEND_FOR_REVIEW',
      'DELETE',
      'EDIT_VIEW',
      'DOWNLOAD',
      'VIEW_PARTICIPANTS',
      'VIEW_APPROVAL_BASIC',
      'UPLOAD_VERSION',
      'VIEW_FORM',
    ],
    FINALIZE: [
      'SHARE',
      'COMPLETED_VIEW',
      'VIEW_APPROVAL_DETAILED',
      'VIEW_FORM',
      'ACTIVITY_LOG',
      'REVIEWER',
      'EDIT_FORM',
      'FINALIZE_ITEM',
      'RESET_ANY',
      'VIEW_ANY',
      'CHANGE_COORDINATOR',
      'ADD_ASSIGNEES',
      'DOWNLOAD',
      'VIEW_APPROVAL_BASIC',
      'RISK_FLAGGING',
      'FINALIZE_VIEW',
      'EDIT_PARTICIPANTS',
      'MARK_COMPLETE',
      'VIEW_PARTICIPANTS',
      'REASSIGN_ANY',
      'REMIND_FINALIZERS',
      'DELETE',
      'UPLOAD_VERSION',
    ],
    REVIEW: [
      'REVIEW_VIEW',
      'SHARE',
      'VIEW_APPROVAL_DETAILED',
      'VIEW_FORM',
      'ACTIVITY_LOG',
      'REVIEWER',
      'EDIT_FORM',
      'RESET_ANY',
      'VIEW_ANY',
      'CHANGE_COORDINATOR',
      'ADD_ASSIGNEES',
      'DOWNLOAD',
      'VIEW_APPROVAL_BASIC',
      'RISK_FLAGGING',
      'EDIT_PARTICIPANTS',
      'VIEW_PARTICIPANTS',
      'REASSIGN_ANY',
      'SEND_FOR_SIGNATURES',
      'REMIND_REVIEWERS',
      'DELETE',
      'UPLOAD_VERSION',
    ],
    SIGN: [
      'SHARE',
      'VIEW_APPROVAL_DETAILED',
      'REMIND_SIGNATORIES',
      'VIEW_FORM',
      'SEND_FOR_FINALIZATION',
      'BACK_TO_REVIEW',
      'ACTIVITY_LOG',
      'REVIEWER',
      'EDIT_FORM',
      'COLLECT_SIGNATURES',
      'RESET_ANY',
      'REMOVE_ANY',
      'CHANGE_COORDINATOR',
      'SIGN_VIEW',
      'ADD_ASSIGNEES',
      'DOWNLOAD',
      'VIEW_APPROVAL_BASIC',
      'RISK_FLAGGING',
      'UPLOAD_SIGNED',
      'EDIT_PARTICIPANTS',
      'VIEW_PARTICIPANTS',
      'REASSIGN_ANY',
      'DELETE',
      'UPLOAD_VERSION',
    ],
    TICKET: [
      'RISK_FLAGGING',
      'ACTIVITY_LOG',
      'REVIEWER',
      'EDIT_FORM',
      'SHARE',
      'EDIT_PARTICIPANTS',
      'MARK_COMPLETE',
      'DELETE',
      'DOWNLOAD',
      'VIEW_PARTICIPANTS',
      'TICKET_VIEW',
      'UPLOAD_VERSION',
      'VIEW_FORM',
    ],
  },
  document: {
    hasSignatures: false,
    id: '67ebe8fc-2b71-4eb6-a815-863f5f41be37',
    lastModifiedById: 1,
    modifiedDate: '2021-08-17T17:33:32.524842Z',
    name: 'Custody Agreement',
    version: 1,
    type: '',
  },
  documentVersions: [
    {
      fileType: 'docx',
      hasWorkingDraft: true,
      id: '78f794b0-678d-4439-9ca1-b8d84b8d49af',
      isCounterparty: false,
      isGenerated: false,
      lastUpdated: '2021-07-28T17:33:32.524842Z',
      referenceVersionId: null,
      targetVersionId: null,
      userId: 1,
      versionNumber: 4,
    },
    {
      fileType: 'docx',
      hasWorkingDraft: false,
      id: '18f794b0-678d-4439-9ca1-b8d84b8d49af',
      isCounterparty: true,
      isGenerated: true,
      lastUpdated: '2021-07-21T17:33:32.524842Z',
      referenceVersionId: '18f794b0-678d-4439-9ca1-b8d84b8d49af',
      targetVersionId: '98f794b0-678d-4439-9ca1-b8d84b8d49af',
      userId: 1,
      versionNumber: 3,
    },
    {
      fileType: 'pdf',
      hasWorkingDraft: false,
      id: '28f794b0-678d-4439-9ca1-b8d84b8d49af',
      isCounterparty: false,
      isGenerated: false,
      lastUpdated: '2021-07-17T17:33:32.524842Z',
      referenceVersionId: null,
      targetVersionId: null,
      userId: 1,
      versionNumber: 2,
    },
    {
      fileType: 'docx',
      hasWorkingDraft: false,
      id: '98f794b0-678d-4439-9ca1-b8d84b8d49af',
      isCounterparty: false,
      isGenerated: false,
      lastUpdated: '2021-08-17T17:33:32.524842Z',
      referenceVersionId: null,
      targetVersionId: null,
      userId: 1,
      versionNumber: 1,
    },
  ],
  attachments: [
    {
      userId: 1,
      fileName: 'test_1',
      fileSize: 27849,
      fileType: '.docx',
      id: '97cb0be6-674f-4ca8-9900-eb871457389d',
      lastUpdated: '2022-01-31T18:03:57.345776Z',
    },
    {
      userId: 1,
      fileName: 'test_2',
      fileSize: 32322,
      fileType: '.docx',
      id: '1689fbcb-455b-4cd5-b964-9e75cefef8c6',
      lastUpdated: '2022-01-31T18:24:50.413073Z',
    },
  ],
  hasEnvelope: false,
  hasEsignatureIntegration: false,
  hasUnpopulatedSignerField: false,
  stages: {
    edit: {
      coordinatorId: null,
      phaseIds: [],
      id: '10a2c242-63c7-4e13-bb98-856a6bf84596',
      isEnabled: true,
      status: 'pending',
    },
    finalize: {
      coordinatorId: 100005,
      phaseIds: [],
      id: 'e9a79115-c137-4b5d-84b0-98c8a4761dba',
      isEnabled: true,
      status: 'pending',
    },
    review: {
      coordinatorId: 100005,
      phaseIds: [],
      id: '3baf3ec0-842d-4da5-a71f-f72024e353d9',
      isEnabled: true,
      status: 'pending',
    },
    sign: {
      coordinatorId: 100006,
      phaseIds: [],
      id: '5e8a3164-0ca9-4b67-973a-35397388f245',
      isEnabled: true,
      status: 'pending',
    },
  },
  phases: {},
  judgments: {},
  risks: {},
  approvals: {},
  events: {
    '9acc7d36-9003-4558-81dc-bc9f63e9fc90': {
      action: 'download document',
      id: '9acc7d36-9003-4558-81dc-bc9f63e9fc90',
      type: 'version',
      createdDate: '2021-08-24T05:59:25.991402Z',
      user: {
        email: 'contact@safeguard.com',
        id: 1,
        isInternal: true,
        name: 'John Richardson',
      },
      data: {
        version: {
          id: '98f794b0-678d-4439-9ca1-b8d84b8d49af',
          tag: 1,
        },
      },
    },
    'd28a0e58-32f0-4956-9ef0-ecf5adab4cf4': {
      action: 'download document',
      id: 'd28a0e58-32f0-4956-9ef0-ecf5adab4cf4',
      type: 'version',
      createdDate: '2021-08-17T19:21:35.684174Z',
      user: {
        email: 'contact@safeguard.com',
        id: 1,
        isInternal: true,
        name: 'John Richardson',
      },
      data: {
        version: {
          id: '98f794b0-678d-4439-9ca1-b8d84b8d49af',
          tag: 1,
        },
      },
    },
    '32ab1c7e-d3f8-4c57-b095-9947288afc61': {
      action: 'create ticket',
      id: '32ab1c7e-d3f8-4c57-b095-9947288afc61',
      type: 'ticket',
      createdDate: '2021-08-17T17:33:32.524842Z',
      user: {
        email: 'contact@safeguard.com',
        id: 1,
        isInternal: true,
        name: 'John Richardson',
      },
      data: {
        document: {
          id: '67ebe8fc-2b71-4eb6-a815-863f5f41be37',
          name: 'Custody Agreement',
        },
      },
    },
  },
  participants: {
    1: {
      id: 1,
      role: 'creator',
    },
  },
  esignatureProvider: 'adobesign',
  workflow: {
    acceptedFileTypes: [],
    archiveLocation: {
      fileName:
        '{{field:Workflow Name}}_Insert custom field or text_{{field:Date Signed}}_{{field:Submitter Name}}',
      filePath: '',
      folderPath: null,
      id: '8f29b566-6386-40cc-bf09-abb51add9335',
      pilotFolderId: 1,
      status: 'pending',
      workflowId: '240894a1-d732-4df3-9c9d-eef3941b2dfa',
    },
    builderId: 1,
    clientId: '08ae7853-9c13-462f-9b13-77ec3ebe4164',
    conditions: {},
    createdBy: 1,
    createdDate: '2021-08-12T19:53:40.650628Z',
    description: '',
    fieldLinks: {},
    fieldMappings: [],
    fields: {},
    form: {
      sections: [],
    },
    id: '240894a1-d732-4df3-9c9d-eef3941b2dfa',
    implicitFields: [
      {
        description: 'date the ticket was created (submitted)',
        name: 'Ticket Created Date',
        place_holder: null,
        type: 'DATE',
      },
      {
        description:
          'date the final signature is collected (either DocuSign is completed or all signatures completed offline and uploaded)',
        name: 'Date Signed',
        place_holder: null,
        type: 'DATE',
      },
      {
        description: 'date the workflow is completed (finalize step completes)',
        name: 'Date Completed',
        place_holder: null,
        type: 'DATE',
      },
      {
        description: 'the name of the workflow',
        name: 'Workflow Name',
        place_holder: null,
        type: 'SHORT_RESPONSE',
      },
      {
        description: 'the name of the submitter',
        name: 'Submitter Name',
        place_holder: null,
        type: 'SHORT_RESPONSE',
      },
      {
        description:
          'the department or departments (can be multiple) that the user belongs to - should extend to parent departments if they’re in a sub-department',
        name: 'Submitter Department',
        place_holder: null,
        type: 'DEPARTMENT',
      },
      {
        customSettings: {
          options: [
            {
              label: 'Company Template',
              value: 'company',
            },
            {
              label: 'Counterparty Paper',
              value: 'counter',
            },
          ],
        },
        description:
          'Counterparty Paper OR Company Template - based on what is submitted in the intake form (if they submit a counterparty paper or choose to generate their own document)',
        name: 'Paper Type',
        place_holder: null,
        type: 'SINGLE_SELECT',
      },
    ],
    isDraft: false,
    lastModifiedById: '188abacb-281f-44ae-9fef-e1c8d0914501',
    modifiedDate: '2021-08-12T20:37:30.540097Z',
    name: 'sample',
    parentId: 'eda60567-c123-4621-b38c-30f1c3c58650',
    published: true,
    signers: {},
    stages: {
      edit: {
        coordinator: null,
        phases: [],
      },
      finalize: {
        coordinator: null,
        phases: [],
      },
      review: {
        coordinator: null,
        phases: [],
      },
      sign: {
        coordinator: null,
        phases: [],
      },
    },
    status: 'published',
    type: 'company',
    versionNumber: 1,
  },
  permissions: [
    'RISK_FLAGGING',
    'ACTIVITY_LOG',
    'REVIEWER',
    'EDIT_FORM',
    'SHARE',
    'EDIT_PARTICIPANTS',
    'VIEW_APPROVAL_DETAILED',
    'SEND_FOR_REVIEW',
    'DELETE',
    'EDIT_VIEW',
    'DOWNLOAD',
    'VIEW_PARTICIPANTS',
    'VIEW_APPROVAL_BASIC',
    'UPLOAD_VERSION',
    'VIEW_FORM',
  ],
  pilotDocHandlerId: null,
  wasManuallyUpdated: false,
  activities: [
    {
      action: 'download document',
      data: {
        version: {
          id: '98f794b0-678d-4439-9ca1-b8d84b8d49af',
          tag: 1,
        },
      },
      id: '9acc7d36-9003-4558-81dc-bc9f63e9fc90',
      modifiedDate: '2021-08-24T05:59:25.991402Z',
      type: 'version',
      user: {
        email: 'contact@safeguard.com',
        id: 1,
        isInternal: true,
        name: 'John Richardson',
      },
    },
    {
      action: 'download document',
      data: {
        version: {
          id: '98f794b0-678d-4439-9ca1-b8d84b8d49af',
          tag: 1,
        },
      },
      id: 'd28a0e58-32f0-4956-9ef0-ecf5adab4cf4',
      modifiedDate: '2021-08-17T19:21:35.684174Z',
      type: 'version',
      user: {
        email: 'contact@safeguard.com',
        id: 1,
        isInternal: true,
        name: 'John Richardson',
      },
    },
    {
      action: 'create ticket',
      data: {
        document: {
          id: '67ebe8fc-2b71-4eb6-a815-863f5f41be37',
          name: 'Custody Agreement',
        },
      },
      id: '32ab1c7e-d3f8-4c57-b095-9947288afc61',
      modifiedDate: '2021-08-17T17:33:32.524842Z',
      type: 'ticket',
      user: {
        email: 'contact@safeguard.com',
        id: 1,
        isInternal: true,
        name: 'John Richardson',
      },
    },
  ],
};
