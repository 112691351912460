import { useEffect, useState } from 'react';

import { AUTO_DATE_INTERVAL_MS } from '../constants';
import { DateFormat } from '../types';
import { formatDate } from '../utils';

interface Options {
  format: DateFormat;
  intervalMs: number;
}

const defaultOptions = {
  format: 'relative' as DateFormat,
  intervalMs: AUTO_DATE_INTERVAL_MS,
};

/**
 * Returns a serialized date text given a date and updates on the provided interval.
 */
export const useIntervalDateText = (
  date: Date,
  options: Partial<Options> = defaultOptions,
) => {
  const { format, intervalMs } = { ...defaultOptions, ...options };

  const [dateText, setDateText] = useState('');

  useEffect(() => {
    const updateInterval = setInterval(() => {
      setDateText(formatDate(date, format));
    }, intervalMs);
    return () => clearInterval(updateInterval);
  }, [date]);
  return dateText;
};
