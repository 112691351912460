import { alphas } from '../../../tokens/colors/system/alphas';

export const interactions = {
  ':hover': {
    boxShadow: `inset 0 0 0 1000px ${alphas['darken-3']}`,
  },
  ':focus': {
    boxShadow: `inset 0 0 0 1000px ${alphas['darken-3']}`,
  },
  ':active': {
    boxShadow: `inset 0 0 0 1000px ${alphas['darken-5']}`,
  },
  ':hover:focus': {
    boxShadow: `inset 0 0 0 1000px ${alphas['darken-5']}`,
  },
  ':hover:focus:active': {
    boxShadow: `inset 0 0 0 1000px ${alphas['darken-10']}`,
  },
};
