import PropTypes from 'prop-types';
import React from 'react';

const LinkIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          fill={props.color}
          d="M14.167 5.833h-2.5a.836.836 0 0 0-.834.834c0 .458.375.833.834.833h2.5c1.375 0 2.5 1.125 2.5 2.5s-1.125 2.5-2.5 2.5h-2.5a.836.836 0 0 0-.834.833c0 .459.375.834.834.834h2.5c2.3 0 4.166-1.867 4.166-4.167s-1.866-4.167-4.166-4.167zM6.667 10c0 .458.375.833.833.833h5a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.833h-5a.836.836 0 0 0-.833.833zm1.666 2.5h-2.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5h2.5a.836.836 0 0 0 .834-.833.836.836 0 0 0-.834-.834h-2.5A4.168 4.168 0 0 0 1.667 10c0 2.3 1.866 4.167 4.166 4.167h2.5a.836.836 0 0 0 .834-.834.836.836 0 0 0-.834-.833z"
        />
      </g>
    </svg>
  );
};

LinkIcon.defaultProps = {
  size: '20',
  color: '#007FBA',
};

LinkIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default LinkIcon;
