import React from 'react';

import { Users } from '../../../components';
import { User } from '../../../types';

interface Props {
  users: User[];
  limit?: number;
}

export const UsersCell = ({ limit, users }: Props) => {
  return <Users limit={limit} users={users} />;
};
