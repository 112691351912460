import { chunk } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  integrationsAddSyncPair,
  integrationsRemoveSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairPageIndex,
  integrationsSetManageSyncPairPageSize,
  integrationsSetManageSyncPairStage,
  integrationsSetSyncPairs,
} from '~/actions/integrations';
import { connectSyncPair, deleteSyncPair, getSyncPairs } from '~/api';
import { showToast } from '~/components/Shared/EcToast';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { storageIntegrations } from '~/constants/integrations';
import { Button, Chip, Layout } from '~/eds';
import {
  ManageSyncPairStageType,
  QueryParamType,
  SyncPairStatusType,
  TableContextType,
} from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import { useRouting } from '~/routing';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { FlexLayout, Folder, Text, Tooltip, useModal } from '~/ui';
import { createSyncPairData, fileTypesToChips } from '~/utils';
import { removeQueryParam } from '~/utils/browser';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';

import { buildProviderPath, resetSyncPairAuthData } from '../util';
import EmptySyncPairsPlaceholder from './EmptySyncPairsPlaceholder';

function StorageIntegrations({
  // connected
  activeSyncPair,
  syncPairs,
  tableState,
  integrationsAddSyncPair,
  integrationsRemoveSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
  integrationsSetManageSyncPairPageIndex,
  integrationsSetManageSyncPairPageSize,
  integrationsSetSyncPairs,
}) {
  const [syncPairToDelete, setSyncPairToDelete] = useState(null);

  const { location, navigate, params } = useRouting();

  const { clientId } = params;

  const enableSkipEmptyFolders = useFlag(FlagType.SkipEmptyFolders);

  const states = getPageSearchQueryByKey(QueryParamType.States, btoa('{}'));
  const { provider, state, code, syncPairId } = JSON.parse(atob(states));
  const { pageIndex, pageSize } = tableState;

  const columns = [
    {
      key: 'provider',
      title: 'T',
      renderCell: ({ name, provider }) => (
        <Tooltip content={name}>
          <ProviderLogo provider={provider} size="s" />
        </Tooltip>
      ),
    },
    {
      key: 'status',
      cellType: 'chips',
      title: 'Status',
      mapCellProps: (props) => {
        let icon;
        let text;
        let status;
        switch (props.status) {
          case SyncPairStatusType.AuthError:
            text = 'Authentication error';
            status = 'danger';
            break;
          case SyncPairStatusType.OtherError:
            text = 'Provider error';
            status = 'danger';
            break;
          case SyncPairStatusType.DataAccessError:
            text = 'Data access error';
            status = 'danger';
            break;
          case SyncPairStatusType.OutOfSpaceError:
            text = 'Out of space error';
            status = 'danger';
            break;
          case SyncPairStatusType.Active:
            text = 'Active';
            status = 'success';
            break;
          case SyncPairStatusType.Initial:
            icon = 'loading';
            text = 'Initiating';
            status = 'info';
            break;
          default:
            text = 'Unknown Error';
            status = 'danger';
        }
        const statusChip = {
          icon,
          text,
          status,
        };
        return {
          chips: [statusChip],
        };
      },
    },
    {
      key: 'providerFolderPath',
      title: 'External Folder',
      renderCell: ({
        id,
        name,
        providerFolderPath,
        providerPrefix,
        skipEmptyFolders,
      }) => (
        <FlexLayout flexDirection="row" space="8px">
          <Folder
            folder={{
              id,
              name,
              path: buildProviderPath(providerFolderPath, providerPrefix),
            }}
            isConcise={true}
            type="external"
          />
          {enableSkipEmptyFolders && skipEmptyFolders && (
            <Chip status="info" text="Skip empty folders" />
          )}
        </FlexLayout>
      ),
    },
    {
      key: 'evisortFolderPath',
      cellType: 'filesystem',
      title: 'Evisort Folder',
      mapCellProps: ({ id, evisortFolderPath }) => ({
        folder: {
          id,
          name: 'Documents',
          path: evisortFolderPath,
          type: 'internal',
          isConcise: true,
        },
      }),
    },
    {
      key: 'allowedSyncFileTypes',
      cellType: 'chips',
      title: 'File Types',
      mapCellProps: ({ allowedSyncFileTypes }) => ({
        chips: fileTypesToChips(allowedSyncFileTypes),
      }),
      mapCellValue: ({ allowedSyncFileTypes }) =>
        allowedSyncFileTypes.join(', '),
      width: 'l',
    },
    {
      key: 'dateUpdated',
      cellType: 'datetime',
      title: 'Last Sync Activity',
      mapCellProps: ({ dateUpdated }) => ({
        datetime: dateUpdated ? new Date(dateUpdated) : undefined,
        format: 'iso',
      }),
    },
  ];

  const { isLoadingSyncPairs } = useAsync(
    getSyncPairs,
    { clientId },
    {
      condition: true,
      errorToastMessage: 'Unable to fetch sync pairs, please try again.',
      successHandler: integrationsSetSyncPairs,
      intervalMs: 10000,
    },
  );

  useEffect(() => {
    const existingPairData = syncPairs?.find((el) => el.id === syncPairId);

    const isUpdatingPairData = !activeSyncPair && existingPairData;
    const isCreatingPairData = !activeSyncPair && !syncPairId;
    if (state) {
      if (code && (isCreatingPairData || isUpdatingPairData)) {
        const newPairData = createSyncPairData({
          ...storageIntegrations[provider],
        });
        const syncPairData = resetSyncPairAuthData(
          existingPairData || newPairData,
        );
        integrationsSetActiveSyncPair({ ...syncPairData, state, code });
        integrationsSetManageSyncPairStage(
          ManageSyncPairStageType.Authentication,
        );
      }
      if (!code) {
        integrationsSetActiveSyncPair(null);
        removeQueryParam(location, navigate, QueryParamType.States);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [states, syncPairs]);

  const [deleteSyncPairModal, showDeleteSyncPairModal] = useModal({
    actionButton: {
      variant: 'red',
      text: 'Delete',
      errorHandler: () => {
        showToast(ERROR, 'Unable to delete sync pair, please try again.');
      },
      promise: async () => {
        await deleteSyncPair(syncPairToDelete.id, clientId);
        integrationsRemoveSyncPair(syncPairToDelete);
        showToast(
          SUCCESS,
          `${syncPairToDelete.name} sync pair has been deleted.`,
        );
      },
    },
    onHide: () => setSyncPairToDelete(null),
    title: 'Delete Sync Pair?',
    content: (
      <FlexLayout flexDirection="column" space={2}>
        <Text color="gray-900" variant="s-spaced">
          Are you sure you want to delete this sync pair?
        </Text>
        <Text color="gray-900" variant="s-spaced">
          Deleting the sync pair will not stop an active file transfer, and will
          not delete any files that have been transfered.{' '}
          <Text variant="s-dense-bold">This action cannot be reverted.</Text>
        </Text>
      </FlexLayout>
    ),
  });

  const isLoading = isLoadingSyncPairs || (state && code);

  if (!isLoading && syncPairs.length === 0) {
    return <EmptySyncPairsPlaceholder />;
  }

  const rowActions = [
    {
      label: 'Try connecting again',
      onClick: async (d) => {
        const syncPair = await connectSyncPair(d);
        integrationsAddSyncPair(syncPair);
      },
      condition: (d) => {
        const providerErrors = [
          SyncPairStatusType.OtherError,
          SyncPairStatusType.DataAccessError,
          SyncPairStatusType.OutOfSpaceError,
        ];
        return providerErrors.includes(d.status);
      },
    },
    {
      label: 'Reauthenticate',
      onClick: (d) => {
        integrationsSetActiveSyncPair({
          ...resetSyncPairAuthData(d),
          isReauthenticate: true,
        });
        integrationsSetManageSyncPairStage(
          ManageSyncPairStageType.Authentication,
        );
      },
      condition: (d) =>
        [SyncPairStatusType.Active, SyncPairStatusType.AuthError].includes(
          d.status,
        ),
    },
    {
      label: 'Delete',
      onClick: (d) => {
        setSyncPairToDelete(d);
        showDeleteSyncPairModal();
      },
    },
  ];

  return (
    <>
      <Layout justify="flex-end" pb={4}>
        <Button
          text="Add Sync Pair"
          variant="primary"
          onClick={() =>
            integrationsSetManageSyncPairStage(ManageSyncPairStageType.Provider)
          }
        />
      </Layout>
      <PersistedTable
        context={TableContextType.SyncPairsManagement}
        state={{ pageIndex, pageSize }}
        name="storage-integrations"
        isLoading={isLoading}
        columns={columns}
        data={chunk(syncPairs, pageSize)[pageIndex - 1] || []}
        totalCount={syncPairs.length}
        rowActions={rowActions}
        options={{ enableManageColumns: false }}
        onPaginate={integrationsSetManageSyncPairPageIndex}
        onPageSizeChange={integrationsSetManageSyncPairPageSize}
      />
      {deleteSyncPairModal}
    </>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  syncPairs: Object.values(integrations.syncPairs).map((syncPair) => ({
    ...storageIntegrations[syncPair.provider],
    ...syncPair,
  })),
  tableState: integrations.tableState,
});

export default connect(mapStateToProps, {
  integrationsRemoveSyncPair,
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
  integrationsSetManageSyncPairPageIndex,
  integrationsSetManageSyncPairPageSize,
  integrationsSetSyncPairs,
})(StorageIntegrations);
