export default `
<svg
  aria-hidden="true"
  aria-label="arrow-previous"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M17.1328 9.28125C16.8203 9.5625 16.3516 9.5625 16.0703 9.28125L13.3828 6.5625V19.25C13.3828 19.6875 13.0391 20 12.6328 20H7.38281C6.94531 20 6.63281 19.6875 6.63281 19.25C6.63281 18.8438 6.94531 18.5 7.38281 18.5H11.8828V6.5625L9.13281 9.28125C8.85156 9.5625 8.38281 9.5625 8.10156 9.28125C7.78906 8.96875 7.78906 8.5 8.10156 8.21875L12.1016 4.21875C12.2266 4.09375 12.4141 4 12.6328 4C12.8203 4 13.0078 4.09375 13.1328 4.25L17.1328 8.25C17.4453 8.53125 17.4453 9 17.1328 9.28125Z"
    fill="currentcolor"
  />
</svg>
`;
