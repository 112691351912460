import React from 'react';
import { connect } from 'react-redux';

import { integrationsSetManageSyncPairStage } from '~/actions/integrations';
import { Button, Layout, Text } from '~/eds';
import { ManageSyncPairStageType } from '~/enums';

function EmptySyncPairsPlaceholder({
  // connected
  integrationsSetManageSyncPairStage,
}) {
  return (
    <Layout
      align="center"
      direction="column"
      justify="center"
      p={16}
      spacing={6}
      borderRadius="m"
      border="border.divider"
    >
      <Text color="text.secondary">No sync pairs yet</Text>
      <Button
        text="Add Sync Pair"
        variant="primary"
        onClick={() =>
          integrationsSetManageSyncPairStage(
            ManageSyncPairStageType.ProviderProcurements,
          )
        }
      />
    </Layout>
  );
}

export default connect(null, {
  integrationsSetManageSyncPairStage,
})(EmptySyncPairsPlaceholder);
