import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { ticketActivitiesSet, ticketParticipantsSet } from '~/actions';
import {
  addTicketParticipants,
  getActivities,
  removeTicketParticipant,
} from '~/api';
import UserAvatar from '~/components/Client/UserAvatar';
import UserName from '~/components/Client/UserName';
import { showToast } from '~/components/Shared/EcToast';
import { User } from '~/components/Shared/User';
import UserSelect from '~/components/Shared/UserSelect';
import {
  Box,
  Button,
  ContentContainer,
  FormField,
  Icon,
  IconButton,
  Layout,
  SearchInput,
  StatusMessage,
  testHasTextMatch,
  Text,
  types,
  useModal,
} from '~/eds';
import { EntityType, WorkflowTicketRoleType } from '~/enums';
import { useAsync, useResolveUsers } from '~/hooks';
import { api } from '~/redux';
import * as toastTypes from '~/types/toast.types';
import { getUserName } from '~/utils/user';

interface TicketContributorListProps {
  label: string;
  contributors: types.User[];
  onRemoveContributor?: (id: types.PilotId) => void;
  isEditing?: boolean;
  roles?: any;
}

type UserRole = Record<types.PilotId, { role: string; id: types.PilotId }>;

interface TicketParticipantsProps {
  ticketId: types.Uuid;
  //TODO: We still have to figure the ticket type
  ticket: any;
  ticketActivitiesSet: (data: any) => void;
  ticketParticipantsSet: (data: any) => void;
}

const TicketContributorList = ({
  label,
  contributors,
  onRemoveContributor,
  isEditing,
  roles,
}: TicketContributorListProps) => {
  if (contributors.length === 0) {
    return null;
  }

  return (
    <Layout direction="column" spacing={4}>
      <Text variant="tiny" color="text.quiet">
        {label}
      </Text>
      {contributors.map((contributor) => {
        const enableRemove =
          roles[contributor.id]?.role === WorkflowTicketRoleType.Participant;
        return (
          <Layout
            align="center"
            key={contributor.id}
            justify="space-between"
            mb={4}
          >
            <Layout align="center" spacing={2}>
              <UserAvatar userId={contributor.id} />
              <Text variant="body">
                <UserName userId={contributor.id} />
              </Text>
            </Layout>
            {isEditing && (
              <IconButton
                disabled={!enableRemove}
                icon="trash"
                text={`remove participant ${contributor.id}`}
                tooltip={
                  enableRemove
                    ? 'Remove participant'
                    : 'This user has roles assigned to them in this ticket and cannot be removed.'
                }
                onClick={() => onRemoveContributor?.(contributor.id as number)}
              />
            )}
          </Layout>
        );
      })}
    </Layout>
  );
};

const filterContributors = (
  contributors: types.User[],
  roles: UserRole,
  searchText: string,
) => {
  const filteredContributors = searchText.length
    ? contributors.filter(
        (contributor) =>
          contributor.firstName &&
          contributor.lastName &&
          (testHasTextMatch(contributor.firstName.toLowerCase(), searchText) ||
            testHasTextMatch(contributor.lastName.toLowerCase(), searchText)),
      )
    : contributors;
  const isAssignee = (user: types.User) =>
    roles[user.id as number]?.role === WorkflowTicketRoleType.Assignee;
  return {
    assignees: filteredContributors.filter(isAssignee),
    participants: filteredContributors.filter((user) => !isAssignee(user)),
  };
};

export const TicketParticipantsComponent = ({
  ticketId,
  // connected props
  ticket,
  ticketActivitiesSet,
  ticketParticipantsSet,
}: TicketParticipantsProps) => {
  const [userIds, setNewUserIDs] = useState<types.PilotId[]>([]);
  const [searchText, setSearchText] = useState('');
  const [
    participantToRemove,
    setParticipantToRemove,
  ] = useState<types.PilotId>();
  const [isEditing, setEditMode] = useState(false);
  const [isRemovingParticipant, setIsRemovingParticipant] = useState(false);
  const dispatch = useDispatch();

  const {
    users: resolvedUsers = {},
    isLoading: isLoadingUsers,
  } = useResolveUsers({
    userIds: [...Object.keys(ticket.participants), ...userIds],
    skip: !ticket?.participants,
  });

  const contributors = useMemo(() => {
    return Object.values(resolvedUsers).filter(
      (user) => ticket.participants[user.id],
    );
  }, [resolvedUsers]);

  const roles = ticket.participants;
  const user = participantToRemove && resolvedUsers[participantToRemove];

  const {
    data: ticketParticipants,
    refetch: fetchParticipants,
    isError,
    isFetching,
  } = api.endpoints.getTicketParticipants.useQuery(
    {
      ticketId,
    },
    { skip: !ticketId },
  );

  useEffect(() => {
    if (ticketParticipants) {
      ticketParticipantsSet(ticketParticipants.data);
    }
  }, [ticketParticipants]);

  function handleParticipantRemove(id: types.PilotId) {
    setParticipantToRemove(id);
    showModal();
  }

  function getUserNameText(userIds: types.PilotId[]) {
    const userNames = userIds.map((id) => getUserName(resolvedUsers[id] || {}));
    if (userNames.length === 1) return userNames[0];
    else if (userNames.length === 2) return userNames.join(' and ');
    else return `${userNames[0]} and ${userNames.length - 1} other people`;
  }

  const { executor: getTicketActivities } = useAsync(
    getActivities,
    { entityId: ticketId, entityType: EntityType.Ticket, status: '' },
    {
      successHandler: ticketActivitiesSet,
    },
  );

  const {
    executor: addParticipants,
    isLoading: isAddingParticipants,
  } = useAsync(
    addTicketParticipants,
    { ticketId, userIds },
    {
      successToastMessage: `${getUserNameText(userIds)}
        ${
          userIds.length > 1 ? 'have' : 'has'
        } been added to the list of participants.`,
      successHandler: async ({ data }) => {
        setNewUserIDs([]);
        ticketParticipantsSet(data);
        setEditMode(!isEditing);
        fetchParticipants();
        getTicketActivities();
        // @ts-ignore
        dispatch(api.util.invalidateTags([{ type: 'Acl', id: 'LIST' }]));
      },
      errorToastMessage: 'Adding participants failed.',
    },
  );

  function handleFilterChange(searchText: string) {
    setSearchText(searchText);
  }

  const [modal, showModal, hideModal] = useModal({
    title: 'Remove Participant',
    primaryAction: {
      text: 'Remove',
      variant: 'danger',
      isLoading: isRemovingParticipant,
      onClick: async () => {
        try {
          setIsRemovingParticipant(true);
          await removeTicketParticipant(ticketId, participantToRemove);
          fetchParticipants();
          getTicketActivities();
          setEditMode(false);
          // @ts-ignore
          dispatch(api.util.invalidateTags([{ type: 'Acl', id: 'LIST' }]));
          showToast(
            toastTypes.SUCCESS,
            `${getUserName(user)} has been removed as a participant.`,
          );
        } catch (e) {
          showToast(
            toastTypes.ERROR,
            'Remove participant failed. Please try again.',
          );
        } finally {
          setIsRemovingParticipant(false);
          hideModal();
        }
      },
    },
    children: (
      <div>
        Are you sure you want to remove participant{' '}
        {user && <User mode="name" user={user} />}?
      </div>
    ),
  });

  const { assignees, participants } = useMemo(
    () => filterContributors(contributors, roles, searchText),
    [contributors, roles, searchText],
  );

  if (isError) {
    return (
      <Box mb={4}>
        <StatusMessage
          message="An error occurred while loading participants."
          status="danger"
        />
      </Box>
    );
  }

  return (
    <ContentContainer
      loadingContent={{ isLoading: isLoadingUsers || isFetching }}
    >
      <Box>
        {modal}
        <Layout align="center" mb={2} justify="space-between">
          <Layout align="center">
            <Icon icon="department" />
            <Text variant="body-bold" ml={1}>
              Contributors
            </Text>
          </Layout>
          <Layout justify="flex-end">
            <Button
              variant="action"
              text="Edit"
              icon="edit"
              iconPosition="left"
              onClick={() => setEditMode(!isEditing)}
            />
          </Layout>
        </Layout>
        <Box mt={4}>
          <SearchInput
            name="filter-contributors"
            value={searchText}
            onChange={(searchText) => {
              handleFilterChange(searchText || '');
            }}
            placeholder="Filter Contributors"
          />
        </Box>
        <Box mb={4} mt={6}>
          {assignees.length + participants.length > 0 ? (
            <Layout direction="column" space={4}>
              <TicketContributorList
                roles={roles}
                label="Assignees"
                contributors={assignees}
                isEditing={isEditing}
              />
              <TicketContributorList
                roles={roles}
                label="Participants"
                contributors={participants}
                isEditing={isEditing}
                onRemoveContributor={handleParticipantRemove}
              />
            </Layout>
          ) : searchText ? (
            <Box p="20">
              <Text variant="tiny">No users matched your search.</Text>
            </Box>
          ) : null}
        </Box>
        {isEditing ? (
          <Box mb={2}>
            <FormField
              input={UserSelect}
              label="Users"
              name="select-role"
              disabled={isAddingParticipants}
              inputProps={{
                isMulti: true,
                notAvailableUsers: Object.keys(ticket.participants).map((key) =>
                  Number(key),
                ),
              }}
              value={userIds}
              onChange={(selectedUsers) =>
                setNewUserIDs((selectedUsers || []) as types.PilotId[])
              }
            />
            {userIds.length ? (
              <Layout my={2}>
                <Button
                  variant="action"
                  text="Cancel"
                  onClick={() => setNewUserIDs([])}
                />
                <Button
                  variant="action"
                  text="Save"
                  icon="check"
                  iconPosition="left"
                  onClick={addParticipants}
                  isLoading={isAddingParticipants}
                />
              </Layout>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </ContentContainer>
  );
};

function mapStateToProps({ ticket }: any) {
  return {
    ticket,
  };
}

export const TicketParticipants = connect(mapStateToProps, {
  ticketActivitiesSet,
  ticketParticipantsSet,
})(TicketParticipantsComponent);
