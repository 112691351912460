import { getDocumentVersions } from '../../DocumentsViewPage/DocumentVersions/DocumentVersions.data';

export function shouldExpandMenu(
  isTop,
  menuPageTop,
  menuPageBottom,
  totalPages,
) {
  const menuShouldExpandUp = isTop && menuPageTop > 1;
  const menuShouldExpandDown = !isTop && menuPageBottom < totalPages;
  return menuShouldExpandUp || menuShouldExpandDown;
}

export function createVersionListFromVersionData(versionData) {
  return versionData.map((version) => {
    return {
      ...version,
      label: `Version ${version.version_tag}`,
      value: version.id,
    };
  });
}

export async function loadDocumentVersions(documentId, page) {
  const documentVersionData = await getDocumentVersions(documentId, page);
  return createVersionListFromVersionData(documentVersionData.results);
}
