import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import EcFilterInput from '~/components/Shared/EcFilterInput';

import EcModalCard from '../../Shared/EcModalCard';
import styles from './ListModal.styles';

class ListModal extends Component {
  constructor(props) {
    super(props);

    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleOnFilterChange = this.handleOnFilterChange.bind(this);
    this.state = { filterValue: '' };
  }

  handleListItemClick(item) {
    this.props.returnListItem(item);
    this.props.hideModal();
  }

  handleOnFilterChange(filterValue) {
    this.setState({ filterValue });
  }

  filterListByName(listItems) {
    return listItems.filter(
      (item) =>
        item.label &&
        item.label.toLowerCase().includes(this.state.filterValue.toLowerCase()),
    );
  }

  renderEmptyFiltersListState() {
    const { classes } = this.props;

    return (
      <div className={classes.emptyListState}>
        <div className={classes.emptyListTitle}>No filters found.</div>
        <div className={classes.emptyListSubtitle}>
          Try searching for a different term.
        </div>
      </div>
    );
  }

  renderListItems() {
    const { classes, listItems, selectedItemIds } = this.props;

    const filteredListItems = this.filterListByName(listItems);

    return filteredListItems.length
      ? filteredListItems.map((item, index) => {
          const isSelected = selectedItemIds.includes(item.id);

          return (
            <div
              className={
                isSelected ? classes.selectedListItem : classes.listItem
              }
              key={index}
            >
              <button onClick={() => this.handleListItemClick(item)}>
                {item.label}
              </button>
            </div>
          );
        })
      : this.renderEmptyFiltersListState();
  }

  render() {
    const { classes, hideModal } = this.props;
    const { filterValue } = this.state;

    return (
      <EcModalCard
        title="Select Filter Option"
        content={
          <>
            <div className={classes.filtersSearch}>
              <EcFilterInput
                value={filterValue}
                onChange={(filterValue) =>
                  this.handleOnFilterChange(filterValue)
                }
                onClearSearch={() => this.handleOnFilterChange('')}
                placeholder="Search for list..."
              />
            </div>
            {this.renderListItems()}
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

ListModal.propTypes = {
  classes: PropTypes.object.isRequired,
  returnListItem: PropTypes.func.isRequired,
  listItems: PropTypes.array.isRequired,
  hideModal: PropTypes.func.isRequired,
  selectedItemIds: PropTypes.array,
};

export default injectSheet(styles)(ListModal);
