import React, { useState } from 'react';

import {
  EntityVisibilitySelect,
  EntityVisibilityType,
} from '~/components/Shared/EntityVisibilitySelect';
import { MAX_SAVED_SEARCH_NAME_LENGTH } from '~/constants/max_lengths';
import { FormField, Layout, Modal, TextInput } from '~/eds';
import { SaveSearchPermissionType } from '~/enums';

type Config = { name: string } & EntityVisibilityType;

type Props = {
  /** modal title */
  title: string;
  /** placeholder of textbox */
  placeholder: string;
  isVisible: boolean;
  onSubmit: (params: Config) => void;
  onHide: () => void;
  initialValues?: Config;
};

export const SaveEditEntityModal = ({
  initialValues,
  isVisible,
  placeholder,
  title,
  onHide,
  onSubmit,
}: Props) => {
  const {
    name: originalName,
    visibility = SaveSearchPermissionType.Private,
    departmentIds = [],
    userIds = [],
  } = initialValues ?? {};
  const [name, setName] = useState(originalName ?? null);
  const [
    entityPermission,
    setEntityPermission,
  ] = useState<EntityVisibilityType>({
    visibility: visibility,
    departmentIds,
    userIds,
  });

  const isMissingUserDepartments =
    entityPermission.visibility === SaveSearchPermissionType.Shared &&
    (!entityPermission.userIds || entityPermission.userIds?.length === 0) &&
    (!entityPermission.departmentIds ||
      entityPermission.departmentIds?.length === 0);

  const onActionClick = () => {
    if (name) {
      onSubmit({ name, ...entityPermission });
    }
  };

  const reset = () => {
    onHide();
    setName(originalName ?? null);
    setEntityPermission({
      visibility: visibility,
      departmentIds,
      userIds,
    });
  };

  const isNameLimitExceeded =
    (name ?? '').length > MAX_SAVED_SEARCH_NAME_LENGTH;

  const primaryAction = {
    text: 'Save',
    onClick: onActionClick,
    disabled: !name || isMissingUserDepartments || isNameLimitExceeded,
    tooltip: isNameLimitExceeded
      ? `The name cannot exceed ${MAX_SAVED_SEARCH_NAME_LENGTH} characters.`
      : '',
  };

  return (
    <Modal
      isVisible={isVisible}
      title={title}
      overflow="visible"
      primaryAction={primaryAction}
      onHide={reset}
    >
      <Layout preset="form-fields">
        <FormField<string, false>
          name="nameField"
          label="Name"
          placeholder={placeholder}
          value={name}
          input={TextInput}
          onChange={setName}
        />
        <EntityVisibilitySelect
          label="Visibility"
          value={entityPermission}
          onChange={setEntityPermission}
        />
      </Layout>
    </Modal>
  );
};
