// TODO: move to client components
import PropTypes from 'prop-types';
import React from 'react';

import { Box, FlexLayout, Text, theme } from '~/ui';

function FallbackContent({ icon, title, content = '', button }) {
  return (
    <FlexLayout justifyContent="center" mt={118}>
      <FlexLayout flexDirection="column" space={14}>
        <FlexLayout
          flexDirection="column"
          space={10}
          alignItems="center"
          justifyContent="space-between"
        >
          {icon}
          <Box sx={{ textAlign: 'center' }}>
            <Text color="gray-800" variant="2xl-spaced-bold">
              {title}
            </Text>
          </Box>
          {content && (
            <Text color="gray-700" variant="m-spaced">
              {content}
            </Text>
          )}
        </FlexLayout>
        <FlexLayout alignItems="center" flexDirection="column" space={10}>
          <Box
            sx={{
              borderBottom: `1px solid ${theme.colors['gray-400']}`,
              width: '100%',
            }}
          />
          {button}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
}

FallbackContent.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  button: PropTypes.object,
};

export default FallbackContent;
