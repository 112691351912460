import { pilot } from '../../services';

export const getDocument = (data, sortBy, page, pageSize) =>
  pilot.post(`search/?page=${page}&page_size=${pageSize}`, {
    ...data,
    order_by: sortBy,
  });

export const analyzerBulkMove = (
  destination_folder,
  document_ids,
  search_query,
  select_all,
) =>
  pilot.put('document/bulk-move/', {
    destination_folder,
    document_ids,
    search_query,
    select_all,
  });

export const analyzerBulkCopy = (
  destination_folder,
  document_ids,
  search_query,
  select_all,
) =>
  pilot.put('document/bulk-copy/', {
    destination_folder,
    document_ids,
    search_query,
    select_all,
  });

export const analyzerBulkDelete = (
  document_ids,
  search_query,
  select_all,
  reason,
  extra_details,
) =>
  pilot.put('document/bulk-delete/', {
    document_ids,
    search_query,
    select_all,
    reason,
    extra_details,
  });

export const analyzerBulkEditData = (document_ids, search_query, select_all) =>
  pilot.put('document/bulk-edit-options/', {
    document_ids,
    search_query,
    select_all,
  });

export const analyzerBulkEdit = (data, params) => {
  if (params) {
    const { order, order_by_field } = params;
    return pilot.put(
      `document/bulk-edit/?order_by_field=${order_by_field}&order=${order}`,
      data,
    );
  } else {
    return pilot.put('document/bulk-edit/', data);
  }
};
