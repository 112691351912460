import { evisortBlue } from '../../../assets/shared-styles/general';

const styles = {
  ecFilterField: {
    display: 'flex',
    alignItems: 'center',
  },
  removeFieldContainer: {
    marginRight: '8px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  fieldContainer: {
    width: '240px',
  },
  blueLine: {
    width: '16px',
    border: 0,
    borderTop: `2px solid ${evisortBlue}`,
    margin: 0,
  },
  textValuesContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0 0 28px',
    '& svg': {
      marginRight: '8px',
    },
  },
};

export default styles;
