import { black2, bold } from '../../../assets/shared-styles/general';

const styles = {
  loadingContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  folderPanelContainer: {
    position: 'absolute',
    top: (props) => (props.isRootFolder ? '40px' : '72px'),
    backgroundColor: '#e8eff1',
    width: '400px',
    maxHeight: '100vh',
    border: '1px solid #eaeaea',
    borderRadius: '3px',
    boxShadow: '4px 3px 7px 2px rgba(0, 0, 0, 0.05)',
    zIndex: 3,
    '& a': {
      textDecoration: 'none',
    },
  },
  folderPanelContent: {
    paddingBottom: '30px',
    maxHeight: '500px',
    overflow: 'auto',
    backgroundColor: '#fff',
    borderTop: '1px solid #e6e6e6',
  },
  folderPanelHeader: {
    padding: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      margin: '5px',
    },
  },
  folderPanelHeaderText: {
    display: 'inline-flex',
    alignItems: 'center',
    color: black2,
    fontWeight: bold,
  },
  closeIcon: {
    cursor: 'pointer',
  },
};

export default styles;
