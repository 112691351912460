import PropTypes from 'prop-types';
import React from 'react';

import UserName from '~/components/Client/UserName';
import { DateText, Layout } from '~/eds';
import { FlexLayout, Icon, Text } from '~/ui';
import { getFileIcon } from '~/utils/files';

const OutboundAttachment = ({
  name,
  size,
  uploadedBy,
  uploadDate,
  version,
  onRemove,
}) => {
  const formatFileSize = () => {
    let fileSize = `${Math.round(size / 1024)}`;
    if (fileSize > 1024) {
      return `${(fileSize / 1024).toFixed(2)} MB`;
    }
    return `${fileSize} KB`;
  };

  return (
    <FlexLayout
      sx={{
        ':hover': {
          backgroundColor: 'gray-100',
          '.trash-icon': {
            display: 'block',
          },
          '.version-text': {
            display: 'none',
          },
        },
        '.trash-icon': {
          display: 'none',
        },
      }}
      flexGrow="1"
      justifyContent="space-between"
      alignItems="center"
      space={3}
      py={2}
      px={4}
    >
      <Layout align="center" spacing={3}>
        <Icon icon={getFileIcon({ name })} size="m" />
        <Layout direction="column">
          <Text>{name}</Text>
          <Layout align="center">
            {uploadedBy && (
              <Text variant="tiny" color="gray-600">
                {'Uploaded by '}
                <UserName userId={uploadedBy} />{' '}
              </Text>
            )}
            {uploadDate && (
              <Text variant="tiny" color="gray-600">
                <DateText
                  variant="tiny"
                  date={uploadDate ? new Date(uploadDate) : undefined}
                />
                {' • '}
              </Text>
            )}
            {size && (
              <Text variant="tiny" color="gray-600">
                {formatFileSize()}
              </Text>
            )}
          </Layout>
        </Layout>
      </Layout>

      <Icon
        className="trash-icon"
        color="gray-500"
        icon="trash"
        size="m"
        title="remove"
        onClick={() => onRemove()}
      />
      {version && (
        <Text
          sx={{ border: 'border', borderRadius: 'm' }}
          className="version-text"
          py={1}
          px={3}
          variant="xs-dense-medium"
          color="black-alpha-75"
        >
          Version {version}
        </Text>
      )}
    </FlexLayout>
  );
};

OutboundAttachment.propTypes = {
  /** id of the file if it was attached from ticket */
  id: PropTypes.string,
  name: PropTypes.string,
  version: PropTypes.number,
  /** File object when uploaded */
  file: PropTypes.object,
  /** id of the user who uploaded the file  */
  uploadedBy: PropTypes.number,
  uploadDate: PropTypes.string,
  size: PropTypes.number,
};

export default OutboundAttachment;
