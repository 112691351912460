import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CheckboxGroups,
  ContentContainer,
  formatNumber,
  FormField,
  SearchInput,
  Section,
  TextArea,
  TextInput,
} from '~/eds';
import { api, slices } from '~/redux';

import { WEBHOOKS_DESCRIPTION_LENGTH } from './constants';
import { checkActiveEndpointEvents } from './utils';

export const Configuration = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>('');

  const { activeEndpoint, configuration } = useSelector(
    slices.webhooks.selectors.selectEndpointConfiguration,
  );
  const { url, description, events } = configuration;

  const {
    data: eventGroups = [],
    isLoading: isLoadingEvents,
  } = api.endpoints.getConfigurationEvents.useQuery(undefined);

  useEffect(() => {
    if (activeEndpoint) {
      dispatch(
        slices.webhooks.actions.setConfiguration({
          url: activeEndpoint.endpointUrl,
          description: activeEndpoint.description,
          events: checkActiveEndpointEvents({ activeEndpoint, eventGroups }),
        }),
      );
    }
  }, [activeEndpoint, eventGroups, isLoadingEvents]);

  return (
    <Section
      title="Listen to webhooks and events"
      description="Create webhooks and endpoints to help with your integration events."
    >
      <FormField<string, false>
        input={TextInput}
        inputProps={{
          placeholder: 'https://…',
        }}
        label="Endpoint URL"
        name="endpoint url"
        onChange={(url) => {
          dispatch(slices.webhooks.actions.setUrl(url ?? ''));
        }}
        value={url}
      />
      <FormField<string, false>
        footer={`${formatNumber(description?.length ?? 0)}/${formatNumber(
          WEBHOOKS_DESCRIPTION_LENGTH,
          {
            unit: 'character',
          },
        )}`}
        input={TextArea}
        inputProps={{
          maxLength: WEBHOOKS_DESCRIPTION_LENGTH,
          placeholder:
            'Add an optional description for what this endpoint is used for.',
        }}
        label="Description"
        name="description"
        onChange={(description) => {
          dispatch(slices.webhooks.actions.setDescription(description ?? ''));
        }}
        optional
        value={description ?? ''}
      />
      <FormField<string, false>
        input={SearchInput}
        description="You must select at least one event in order to create an endpoint."
        inputProps={{
          placeholder: 'Search',
        }}
        label="Select events"
        name="events"
        onChange={(search) => {
          setSearch(search ?? '');
        }}
        value={search ?? ''}
      />
      <ContentContainer loadingContent={{ isLoading: isLoadingEvents }}>
        <CheckboxGroups
          collapsible
          mode="descriptive"
          enableCount
          enableSelectAll={{
            label: 'Select all',
            name: 'select all',
            info:
              "Your endpoint will listen to all existing event types but won't automatically handle any new ones added later.",
          }}
          groups={eventGroups}
          onChange={(events) => {
            dispatch(slices.webhooks.actions.setEvents(events ?? {}));
          }}
          search={search ?? ''}
          value={events}
          onOptionClick={(option) => {
            dispatch(slices.webhooks.actions.setSampleEvent(option.value));
          }}
        />
      </ContentContainer>
    </Section>
  );
};
