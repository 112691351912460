import pluralize from 'pluralize';
import PT from 'prop-types';
import React from 'react';

import Field from '~/components/Workflow/shared/Field';
import { Box, FlexLayout, ListItems, Text } from '~/ui';
import { testConditionalTextField, testDocumentField } from '~/utils/workflow';

function FieldsGroup({ entity, fields, id, nonUniqueFieldIds }) {
  const count = fields.length;
  if (count === 0) {
    return null;
  }

  return (
    <FlexLayout flexDirection="column" pt={8} space={4}>
      <Text color="gray-900" variant="xs-dense-medium" id={id}>
        {pluralize(entity, count, true)} found in the document
      </Text>
      <ListItems columns={2}>
        {fields.map((field) => (
          <Box
            key={field.id}
            px={4}
            py={3}
            sx={{
              border: 'border',
              borderRadius: 'm',
            }}
          >
            <Field
              field={field}
              warningTooltip={
                nonUniqueFieldIds.includes(field.id)
                  ? `This field has a non-unique placeholder name ("${field.placeholder}")`
                  : undefined
              }
              shouldHideLabel={true}
            />
          </Box>
        ))}
      </ListItems>
    </FlexLayout>
  );
}

function Fields({ fields, nonUniqueFieldIds }) {
  const allFields = Object.values(fields);

  if (allFields.length === 0) {
    return null;
  }

  const conditionalFields = allFields.filter(testConditionalTextField);
  const placeholderFields = allFields.filter(testDocumentField);

  return (
    <FlexLayout flexDirection="column" space={8}>
      <FieldsGroup
        entity="field"
        fields={placeholderFields}
        id="workflow--placeholder-field-count"
        nonUniqueFieldIds={nonUniqueFieldIds}
      />
      <FieldsGroup
        entity="conditional text field"
        fields={conditionalFields}
        id="workflow--condition-field-count"
        nonUniqueFieldIds={nonUniqueFieldIds}
      />
    </FlexLayout>
  );
}

Fields.propTypes = {
  fields: PT.object.isRequired,
  nonUniqueFieldIds: PT.arrayOf(PT.string.isRequired),
};

export default Fields;
