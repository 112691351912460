import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { toBucketValue } from '~/components/DashboardV2/DashboardV2.utils';
import { trackSegment } from '~/components/SegmentAnalytics';
import { INPUT_DEBOUNCE_MS, SortableGrid, types, useDebounce } from '~/eds';
import { useCurrentUser } from '~/hooks';
import { Dashboard, SearchParams } from '~/redux/api/methods';
import {
  buildComplexQueryWithCrossFilters,
  buildQuery,
  BuildQueryParams,
} from '~/redux/api/methods/searchV3';
import { Chart, CrossFilter, Nullable } from '~/types';

import {
  CHART_TYPES,
  CHART_WIDTHS_2_COLUMNS,
  CHART_WIDTHS_3_COLUMNS,
  INTERVALS,
  SEARCH_COUNT_SINGLE_VALUE_ID,
} from './constants';
import { FilterDataChart } from './FilterDataChart';
import { SearchResultTotalChart } from './SearchResultTotalChart';

interface ChartsProps {
  columns?: number;
  currentChart?: Nullable<string>;
  charts: Chart[];
  entity?: Dashboard['entity'];
  intervals: Record<string, string>;
  chartWidths?: Record<string, string>;
  queryParams: BuildQueryParams;
  searchParams?: SearchParams;
  onCrossFiltersChange: (crossFilters: CrossFilter[], fieldId: string) => void;
  onIntervalChange: (action: types.Action<string>, searchFilter: Chart) => void;
  onChartWidthChange: (filter: Chart, newWidth: string) => void;
  onChatTypeChange: (filter: Chart, newType: string) => void;
  onRearrangeCharts: (filters: Chart[], activeFilter?: Chart) => void;
  onRemoveItem: (filter: Chart) => void;
}

const getColumns = () => {
  const { innerWidth } = window;
  if (innerWidth > 1280) {
    return 3;
  }
  if (innerWidth <= 1280 && innerWidth > 831) {
    return 2;
  }
  if (innerWidth <= 830) {
    return 1;
  }
  return 3;
};

export const Charts = memo(
  ({
    chartWidths,
    entity,
    charts,
    queryParams,
    searchParams,
    currentChart,
    intervals,
    onIntervalChange,
    onRearrangeCharts,
    onRemoveItem,
    onCrossFiltersChange,
    onChartWidthChange,
    onChatTypeChange,
  }: ChartsProps) => {
    const [columns, setColumns] = useState(getColumns());
    const user = useCurrentUser();

    const selectedBuckets: CrossFilter[] = useMemo(() => {
      if (!queryParams.crossFilters) return [];

      return queryParams.crossFilters.map((filter) => {
        const type =
          charts.find((f) => f.id === filter.fieldId)?.filterType || 'enum';

        const values =
          type === 'boolean'
            ? [filter.operatorId === 'is_true' ? 'True' : 'False']
            : filter.values;

        return {
          fieldId: filter.fieldId,
          value: toBucketValue(values, type),
          type,
        };
      });
    }, [queryParams.crossFilters]);

    const handleWindowResize = useDebounce(() => {
      setColumns(getColumns());
    }, INPUT_DEBOUNCE_MS);

    useEffect(() => {
      window.addEventListener('resize', handleWindowResize);
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    const getChartWidthOptions = () => {
      if (columns === 2) {
        return CHART_WIDTHS_2_COLUMNS;
      } else if (columns === 3) {
        return CHART_WIDTHS_3_COLUMNS;
      }
      return null;
    };

    const getNewCrossFilters = (crossFilter: CrossFilter) => {
      const indexWillUpdate = selectedBuckets.findIndex(
        (cf) => cf.fieldId === crossFilter.fieldId,
      );
      const currentField = charts.find(
        (filter) => filter.id === crossFilter.fieldId,
      );
      if (indexWillUpdate >= 0) {
        if (crossFilter.value) {
          trackSegment('selectUpdateChartFilter', {
            name: currentField?.label,
            value: crossFilter.value,
            user_id: user.id,
            client_id: user.client,
          });
          selectedBuckets[indexWillUpdate] = crossFilter;
        } else {
          trackSegment('selectClearChartFilter', {
            name: currentField?.label,
            user_id: user.id,
            client_id: user.client,
          });
          selectedBuckets.splice(indexWillUpdate, 1);
        }
        return [...selectedBuckets];
      } else {
        trackSegment('selectAddChartFilter', {
          name: currentField?.label,
          value: crossFilter.value,
          user_id: user.id,
          client_id: user.client,
        });
        return [...selectedBuckets, crossFilter];
      }
    };

    function onSelectChartWidth(filter: Chart, value: string) {
      onChartWidthChange(filter, value);
    }

    const renderChart = useCallback(
      (chart: Chart) => {
        if (chart.id === SEARCH_COUNT_SINGLE_VALUE_ID && searchParams) {
          /** This is a special chart which is not a filter. It will access the search result cache
           * to render the total.
           */
          return (
            <SearchResultTotalChart
              searchParams={searchParams}
              chart={chart}
              isFullWidth={
                chartWidths && parseInt(chartWidths?.[chart.id]) === columns
              }
            />
          );
        }

        const selectedBucket = selectedBuckets.find(
          (crossFilter) => crossFilter.fieldId === chart.id,
        );
        let limitedCrossFilters = queryParams.crossFilters || [];
        if (currentChart === chart.id) {
          limitedCrossFilters = limitedCrossFilters.filter(
            (crossFilter) => crossFilter.fieldId !== chart.id,
          );
        }

        let query;

        if (queryParams.queryBuilder && queryParams.searchFilters) {
          if (limitedCrossFilters.length) {
            query = buildComplexQueryWithCrossFilters(
              queryParams.queryBuilder,
              limitedCrossFilters,
              queryParams.searchFilters,
            );
          } else {
            query = queryParams.queryBuilder;
          }
        } else {
          query = buildQuery({
            ...queryParams,
            crossFilters: limitedCrossFilters,
          });
        }
        return (
          <FilterDataChart
            entity={entity}
            key={chart.id}
            chart={chart}
            query={query}
            selectedBucket={selectedBucket}
            selectedInterval={intervals[chart.id]}
            onBucketSelected={chart.filterType ? onBucketSelected : undefined}
          />
        );
      },
      [
        charts,
        queryParams,
        selectedBuckets,
        currentChart,
        intervals,
        searchParams,
        chartWidths,
      ],
    );

    function onBucketSelected(bucket: CrossFilter) {
      const newCrossFilters = getNewCrossFilters(bucket);
      onCrossFiltersChange?.(newCrossFilters, bucket.fieldId);
    }

    const renderChartTitle = useCallback((chart: Chart) => chart.label, [
      charts,
      queryParams,
    ]);

    const getChartActions = useCallback(
      (item: Chart) => {
        const rangeOptions = INTERVALS.map((interval) => ({
          ...interval,
          onClick: (action: types.Action<string>) =>
            onIntervalChange(action, item),
          isActive: intervals[item.id] === interval.value,
        }));

        const widthLabels = getChartWidthOptions();
        const widthOptions = widthLabels?.map((widthOption) => ({
          ...widthOption,
          onClick: (action: types.Action<string>) =>
            onSelectChartWidth(item, action.value),
          isActive: chartWidths?.[item.id] === widthOption.value,
        }));

        const chartTypeOptions = CHART_TYPES.map((chartType) => ({
          ...chartType,
          onClick: (action: types.Action<string>) =>
            onChatTypeChange(item, action.value),
          isActive: chartType.value === item.type,
        }));

        const menuOptions = [
          {
            label: '',
            options: [
              {
                value: '',
                label: 'Delete Chart',
                onClick: (_action: types.Action<string>) => {
                  onRemoveItem(item);
                },
              },
            ],
          },
        ];

        if (item.filterType === 'date') {
          menuOptions.push({
            label: 'X-axis grouping',
            options: rangeOptions,
          });
        }

        if (widthOptions) {
          menuOptions.push({
            label: 'Chart width',
            options: widthOptions,
          });
        }

        if (item.filterType === 'date') {
          menuOptions.push({
            label: 'Chart type',
            options: chartTypeOptions,
          });
        }

        return menuOptions;
      },
      [intervals, columns, chartWidths],
    );

    const items = useMemo(() => {
      return charts.map((filter) => ({
        item: filter,
        width: chartWidths?.[filter.id]
          ? getChartWidthBasedOnColumns(
              parseInt(chartWidths[filter.id]),
              columns,
            )
          : undefined,
      }));
    }, [charts, chartWidths, columns]);

    return (
      <>
        <SortableGrid
          columns={columns}
          columnSpacing={6}
          items={items}
          options={options}
          rowSpacing={6}
          renderContents={renderChart}
          renderTitle={renderChartTitle}
          onRemoveItem={onRemoveItem}
          onSortEnd={onRearrangeCharts}
          itemSettings={{
            moreActions: (item) => getChartActions(item),
          }}
        />
      </>
    );
  },
);

const options = {
  enableBorder: true,
};

function getChartWidthBasedOnColumns(columns: number, width: number) {
  if (width > columns) return columns;
  return width;
}
