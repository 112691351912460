export default `
<svg
  aria-hidden="true"
  aria-label="field-address"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M14.5 10.0117C14.5 11.418 13.375 12.5117 12 12.5117C10.5938 12.5117 9.5 11.418 9.5 10.0117C9.5 8.63672 10.5938 7.51172 12 7.51172C13.375 7.51172 14.5 8.63672 14.5 10.0117ZM12 9.01172C11.4375 9.01172 11 9.48047 11 10.0117C11 10.5742 11.4375 11.0117 12 11.0117C12.5312 11.0117 13 10.5742 13 10.0117C13 9.48047 12.5312 9.01172 12 9.01172ZM18 10.0117C18 12.7617 14.3438 17.6055 12.7188 19.6367C12.3438 20.1055 11.625 20.1055 11.25 19.6367C9.625 17.6055 6 12.7617 6 10.0117C6 6.69922 8.65625 4.01172 12 4.01172C15.3125 4.01172 18 6.69922 18 10.0117ZM12 5.51172C9.5 5.51172 7.5 7.54297 7.5 10.0117C7.5 10.418 7.625 11.0117 7.96875 11.8242C8.28125 12.5742 8.75 13.4492 9.28125 14.3242C10.1875 15.7617 11.2188 17.1367 12 18.1367C12.75 17.1367 13.7812 15.7617 14.6875 14.3242C15.2188 13.4492 15.6875 12.5742 16 11.8242C16.3438 11.0117 16.5 10.418 16.5 10.0117C16.5 7.54297 14.4688 5.51172 12 5.51172Z"
    fill="currentcolor"
  />
</svg>
`;
