import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Chip, Layout, User } from '~/eds';
import { TicketStatusType } from '~/enums';
import { withUsers } from '~/hocs';
import { externalUserToUser } from '~/utils/user';

import TicketApprovalItem from '../TicketApprovalItem';

const TicketRecipientsList = ({
  recipients,
  // connected props
  users,
  approvals,
}) => {
  const getUnknownUser = () => {
    return {
      name: '',
      email: '',
    };
  };

  const getCC = ({ recipient, user }) => (
    <Layout
      key={recipient.id}
      align="center"
      spacing={4}
      p={4}
      styles={{ border: 'border', borderRadius: 'm' }}
    >
      <User
        info={user ? user.email : ''}
        user={user ? user : getUnknownUser()}
      />
      <Chip text="CC" variant="info" />
    </Layout>
  );

  return recipients.map((recipient) => {
    const approvalItem =
      recipient.approvalIds && recipient.approvalIds.length
        ? approvals[recipient.approvalIds[0]]
        : null;
    if (!approvalItem) {
      let { userId, email, name } = recipient;
      let user = userId ? users[userId] : externalUserToUser({ name, email });
      return getCC({ recipient, user });
    } else if (approvalItem.status === TicketStatusType.NotApplicable) {
      const approvalItem = approvals[recipient.approvalIds[0]];
      const userId = approvalItem.userId;
      const externalUser = approvalItem.externalUser;
      const user = !!externalUser
        ? externalUserToUser(externalUser)
        : users[userId];
      return (
        <Layout
          key={recipient.id}
          align="center"
          spacing={4}
          p={4}
          styles={{ border: 'border', borderRadius: 'm' }}
        >
          <User
            info={user ? user.email : ''}
            user={user ? user : getUnknownUser()}
          />
          <Chip text="CC" variant="info" />
        </Layout>
      );
    } else {
      return recipient.approvalIds.map((approvalId) => {
        return (
          <TicketApprovalItem
            issueId={recipient.id}
            entityType={recipient.entityType}
            approvalId={approvalId}
            approvalName={recipient.name}
            key={recipient.id}
          />
        );
      });
    }
  });
};

TicketRecipientsList.propTypes = {
  recipients: PropTypes.array.isRequired,
};

function mapStateToProps({ ticket }) {
  const { approvals } = ticket;
  return approvals;
}

export default connect(mapStateToProps)(withUsers(TicketRecipientsList));
