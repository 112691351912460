import { createActions } from '../utils/redux';

export const {
  ticketReset,
  ticketSet,
  ticketActivitiesSet,
  ticketParticipantsSet,
  ticketDocModeSet,
  ticketCurrentVersionSet,
  ticketCurrentVersionIdSet,
  ticketIsInEditorModeSet,
  ticketDocumentVersionsSet,
  ticketAttachmentsSet,
  ticketDocumentSet,
  ticketStagesSet,
  ticketSummarySet,
  ticketEnvelopeSet,
  ticketEsignatureSet,
  ticketUpdate,
} = createActions('ticket', [
  'reset',
  'set',
  'activitiesSet',
  'participantsSet',
  'docModeSet',
  'currentVersionSet',
  'currentVersionIdSet',
  'isInEditorModeSet',
  'documentVersionsSet',
  'attachmentsSet',
  'documentSet',
  'stagesSet',
  'summarySet',
  'envelopeSet',
  'esignatureSet',
  'update',
]);
