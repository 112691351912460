import { noop } from 'lodash';
import React from 'react';

import { types as edsTypes, Layout, typedMemo } from '~/eds';

import { FeedbackDetails, Message as MessageType } from '../types';
import { AiMessage } from './AiMessage';
import { UserMessage } from './UserMessage';

interface Props {
  message: MessageType;
  user: edsTypes.User;
  onChoose: (message: MessageType, choice: string) => void;
  onSelectSource: (message: MessageType, sourceIndex: number) => void;
  onSubmitFeedback: (
    message: MessageType,
    feedbackDetails: FeedbackDetails,
  ) => void;
  disableChoices?: boolean;
  isLoading?: boolean;
  isReplying?: boolean;
  messageActions?: edsTypes.UserAction[];
  shouldShowActions?: boolean;
  thinkingEvents?: string[];
  userMessageActions?: edsTypes.UserAction[];
}

export const Message = typedMemo(
  ({
    disableChoices,
    isLoading,
    isReplying,
    message,
    messageActions = [],
    thinkingEvents,
    shouldShowActions = true,
    user,
    userMessageActions,
    onChoose,
    onSelectSource,
    onSubmitFeedback,
  }: Props) => {
    const { userType } = message;
    return (
      <Layout
        direction="column"
        px={6}
        py={2}
        mx={1}
        spacing={4}
        className={userType}
        onMouseEnter={noop}
        bg={isReplying ? 'background.ai' : undefined}
        borderRadius="l"
      >
        {message.userType === 'current' ? (
          <UserMessage
            message={message}
            actions={userMessageActions}
            user={user}
          />
        ) : (
          <AiMessage
            user={user}
            message={message}
            messageActions={messageActions}
            onChoose={onChoose}
            onSubmitFeedback={onSubmitFeedback}
            disableChoices={disableChoices}
            onSelectSource={onSelectSource}
            isReplying={isReplying}
            isLoading={isLoading}
            thinkingEvents={thinkingEvents}
            shouldShowActions={shouldShowActions}
          />
        )}
      </Layout>
    );
  },
);
