export default `
<svg
  aria-hidden="true"
  aria-label="chevron-left"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M14.3182 18.5001L8.59949 12.5313C8.44324 12.3438 8.38074 12.1563 8.38074 11.9688C8.38074 11.8126 8.44324 11.6251 8.56824 11.4688L14.287 5.50009C14.5682 5.18759 15.0682 5.18759 15.3495 5.46884C15.662 5.75009 15.662 6.21884 15.3807 6.53134L10.162 11.9688L15.412 17.4688C15.6932 17.7501 15.6932 18.2501 15.3807 18.5313C15.0995 18.8126 14.5995 18.8126 14.3182 18.5001Z"
    fill="currentcolor"
  />
</svg>
`;
