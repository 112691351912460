import React, { useState } from 'react';

import { DOWN, ENTER, LEFT, RIGHT, SPACE, TAB, UP } from '~/constants/keyCodes';

export function withTreeNodeKeyDownHandler(Base) {
  return function WrappedwithTreeNodeKeyDownHandler(props) {
    const [focusId, setFocusId] = useState();

    const handleKeyDown = (event, treeNode) => {
      if (event.keyCode === UP) {
        const prev = treeNode.getPrevVisibleNode();

        if (prev) {
          setFocusId(prev.id);
        }
      } else if (event.keyCode === DOWN) {
        const next = treeNode.getNextVisibleNode();

        if (next) {
          setFocusId(next.id);
        }
      }

      if (event.keyCode === TAB) {
        setFocusId();
      }

      const { isExpanded } = treeNode;

      if (!isExpanded && event.keyCode === RIGHT) {
        treeNode.isExpanded = true;
      } else if (isExpanded && event.keyCode === LEFT) {
        treeNode.isExpanded = false;
      }

      if (ENTER === event.keyCode || SPACE === event.keyCode) {
        props.onSelect(treeNode);

        return;
      }
    };

    return (
      <Base {...props} onTreeNodeKeyDown={handleKeyDown} focusId={focusId} />
    );
  };
}
