import React, { memo } from 'react';

import { SharedDropdownProps } from '../../types';
import { Layout } from '../Layout';
import { Popover } from '../Popover';

export const Dropdown = memo(
  ({
    children,
    footer,
    header,
    isVisible,
    trigger,
    triggerMode,
    onClickOutside,
    onHide,
    onShow,
  }: SharedDropdownProps) => {
    return (
      <Popover
        isVisible={isVisible}
        preset="dropdown"
        trigger={trigger}
        triggerStyle={{ minWidth: 0 }}
        containerStyle={componentStyles.container(triggerMode)}
        onClickOutside={onClickOutside}
        onHide={onHide}
        onShow={onShow}
      >
        <Layout direction="column" spacing={2} styles={componentStyles.base}>
          {header}
          {children}
          {footer}
        </Layout>
      </Popover>
    );
  },
);

export const componentStyles = {
  base: {
    minWidth: 'dropdown.min-width',
  },
  container: (mode: SharedDropdownProps['triggerMode']) => {
    switch (mode) {
      case 'shrink':
        return { minWidth: 0, display: 'flex' };
      default:
        return;
    }
  },
};
