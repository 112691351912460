import { RoleType } from '~/enums';
import { User } from '~/types';

import { adminPreBuiltRoles } from '../constants';
import { PreBuiltRoleAdminType } from '../types';

export function testIsPreBuiltAdmin(user: User) {
  return (
    user.role_uuid?.role_type === RoleType.PreBuiltRole &&
    adminPreBuiltRoles.includes(user.role_uuid.name as PreBuiltRoleAdminType)
  );
}
