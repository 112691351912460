import { sample } from 'lodash';
import React, { memo } from 'react';

import { HtmlEntityType } from '../../enums';
import { BaseLoadingContentProps } from '../../types';
import { enquote } from '../../utils';
import { LoadingShimmer } from '../LoadingShimmer';
import { PlaceholderContent } from '../PlaceholderContent';
import { quotes } from './quotes';

interface Props extends BaseLoadingContentProps {
  /** Icon label (for a11y) */
  label?: string;
  /** In `mode="quote"`, LoadingContent will use a random quote for the loading message */
  mode?: 'message' | 'quote';
  shimmer?: {
    height?: string;
    width?: '100%';
  };
}

export const LoadingContent = memo(
  ({
    message: initialMessage = '',
    description: initialDescription = '',
    label = 'loading',
    mode = 'message',
    shimmer,
    title,
  }: Props) => {
    if (shimmer) {
      return <LoadingShimmer {...shimmer} />;
    }

    const isQuoteMode = mode === 'quote';

    let message = initialMessage;
    let description = initialDescription;
    if (isQuoteMode) {
      const quote = sample(quotes);
      if (quote) {
        message = enquote(quote.text);
        description = `${HtmlEntityType.Dash} ${quote.cite}`;
      }
    }

    return (
      <PlaceholderContent
        ariaLive="polite"
        description={description}
        icon="loading-content"
        label={label}
        message={message}
        title={title}
      />
    );
  },
);
