export default `
<svg
  aria-hidden="true"
  aria-label="resume"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M9.875 8.625C10.0938 8.46875 10.4062 8.46875 10.625 8.625L15.125 11.375C15.3438 11.5 15.5 11.75 15.5 12C15.5 12.2812 15.3438 12.5312 15.125 12.6562L10.625 15.4062C10.4062 15.5312 10.0938 15.5625 9.875 15.4062C9.625 15.2812 9.5 15.0312 9.5 14.75V9.25C9.5 9 9.625 8.75 9.875 8.625ZM20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4C16.4062 4 20 7.59375 20 12ZM12 5.5C8.40625 5.5 5.5 8.4375 5.5 12C5.5 15.5938 8.40625 18.5 12 18.5C15.5625 18.5 18.5 15.5938 18.5 12C18.5 8.4375 15.5625 5.5 12 5.5Z"
    fill="currentcolor"
  />
</svg>
`;
