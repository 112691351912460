import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { Button, Card, Icon } from '~/eds';

import { MODAL_FOLDER_TREE } from '../../../../types/modal.types';
import EcModal from '../../../Shared/EcModal';
import styles from './FolderSettings.styles';

class FolderSettings extends Component {
  constructor(props) {
    super(props);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleInboundLocationChange = this.handleInboundLocationChange.bind(
      this,
    );
    this.renderFolderTreeModal = this.renderFolderTreeModal.bind(this);

    this.state = {
      modal: null,
      inboundUploadLocation: {
        name:
          this.props.inboundUploadLocation.folder_name === 'root'
            ? 'Documents'
            : this.props.inboundUploadLocation.folder_name,
        id: this.props.inboundUploadLocation.id,
        path: this.props.inboundUploadLocation.path,
      },
    };
  }

  handleShowModal(modal) {
    this.setState({ modal });
  }

  handleHideModal() {
    this.setState({ modal: null });
  }

  handleInboundLocationChange(location) {
    const { handleFolderChange } = this.props;

    let inboundLocation = '';
    if (location.path) {
      location.path.map((layer) =>
        layer.name !== 'Documents'
          ? (inboundLocation += layer.name + '/')
          : (inboundLocation = ''),
      );
    }
    inboundLocation += location.name + '/';
    this.setState({ inboundUploadLocation: location });
    handleFolderChange(location.id);
  }

  renderFolderTreeModal() {
    const { inboundUploadLocation } = this.state;
    if (inboundUploadLocation) {
      let selectedFolderId = [];
      selectedFolderId.push(inboundUploadLocation.id);
      return (
        <EcModal
          modalType={MODAL_FOLDER_TREE}
          width="540px"
          title="Choose Location"
          folderIdsSelected={selectedFolderId}
          uploadLocation={inboundUploadLocation}
          handleUploadLocationChange={this.handleInboundLocationChange}
          confirmButtonText="Choose Location"
          hideModal={this.handleHideModal}
        />
      );
    } else {
      return null;
    }
  }

  renderSelectedFolderPath() {
    const { classes } = this.props;
    const { inboundUploadLocation } = this.state;

    return inboundUploadLocation != null &&
      inboundUploadLocation.path &&
      inboundUploadLocation.path.length ? (
      <div className={classes.folderPath}>
        {inboundUploadLocation.path
          .map((pathChunk) => <span key={pathChunk.id}>{pathChunk.name}</span>)
          .reduce((prev, curr) => [
            prev,
            <Icon icon="chevron-right" size="s" key={curr} />,

            curr,
          ])}
      </div>
    ) : null;
  }

  render() {
    const { classes } = this.props;
    const { inboundUploadLocation, modal } = this.state;

    return (
      <div>
        <Card
          header={{
            title: 'Folder Settings',
          }}
          mode="bordered"
        >
          <Fragment>
            <div className={classes.info}>
              You can set up a specific folder where all documents emailed into
              the system get uploaded automatically.
            </div>

            <div className={classes.folderEmailIntake}>
              <div className={classes.folderSettingsContent}>
                <Icon icon="folder" size="l" />
                <div className={classes.folderNameWrapper}>
                  <div className={classes.folderName}>
                    {inboundUploadLocation.name}
                  </div>
                  {this.renderSelectedFolderPath()}
                </div>
              </div>
              <div className={classes.changeLocationAction}>
                <Button
                  text="Change Location"
                  onClick={() => this.handleShowModal(MODAL_FOLDER_TREE)}
                  size="s"
                />
              </div>
            </div>
          </Fragment>
        </Card>
        {modal === MODAL_FOLDER_TREE && inboundUploadLocation
          ? this.renderFolderTreeModal()
          : null}
      </div>
    );
  }
}

export default injectSheet(styles)(FolderSettings);
