import React from 'react';

import {
  CheckboxGroup,
  DropdownBody,
  DropdownFooter,
  IconButton,
  Layout,
  RadioGroup,
  Select,
  Text,
  useDropdown,
} from '../../components';
import {
  CommentFilters,
  CommentFiltersFlag,
  CommentFiltersOrder,
  CommentFiltersSource,
  CommentFiltersStatus,
  EnabledFilters,
  Nullable,
} from './types';
import { testIsFiltersUpdated } from './utils';

interface Props {
  filters: CommentFilters;
  enabledFilters: EnabledFilters;
  onUpdateFilters: (updatedFilters: CommentFilters) => void;
}

const COMMENT_FILTERS_STATUS_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Open Comments', value: 'open' },
  { label: 'Resolved Comments', value: 'isResolved' },
];

const COMMENT_FILTERS_SOURCE_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'In document comments', value: 'document' },
  { label: 'Ticket comments', value: 'ticket' },
];

const COMMENT_FILTERS_FLAG_OPTIONS = [
  { label: 'Comments mention me', value: 'mentionMe' },
  { label: 'Comments by me', value: 'byMe' },
];

const COMMENT_FILTERS_ORDER_OPTIONS = [
  { label: 'Oldest to newest', value: 'chronological' as CommentFiltersOrder },
  {
    label: 'Position in document',
    value: 'documentPosition' as CommentFiltersOrder,
  },
];

const CHECKBOX_CUSTOM_LABEL = 'Filter by:';

export const CommentsFilters = ({
  filters,
  enabledFilters,
  onUpdateFilters,
}: Props) => {
  const isFiltersUpdated = !testIsFiltersUpdated(filters);
  const [wrap, _show, _hide, toggle] = useDropdown({
    children: (
      <DropdownBody>
        <Layout direction="column" spacing={4}>
          <Text variant="body-medium">Status</Text>
          <Select
            disabled={!enabledFilters.status}
            enablePortal={true}
            enableSorting={false}
            enableValueContainer={true}
            isClearable={false}
            isMulti={false}
            isSearchable={false}
            name="Status"
            options={COMMENT_FILTERS_STATUS_OPTIONS}
            placeholder="Select a status"
            value={filters.status}
            onChange={(updatedValue) => {
              const updatedFilters = {
                ...filters,
                status: updatedValue as CommentFiltersStatus,
              };
              onUpdateFilters(updatedFilters);
            }}
          />

          <Text variant="body-medium">Source</Text>
          <Select
            disabled={!enabledFilters.source}
            enablePortal={true}
            enableSorting={false}
            enableValueContainer={true}
            isClearable={false}
            isMulti={false}
            isSearchable={false}
            name="Source"
            options={COMMENT_FILTERS_SOURCE_OPTIONS}
            placeholder="Select a source"
            value={filters.source}
            onChange={(updatedValue) => {
              const updatedFilters = {
                ...filters,
                source: updatedValue as CommentFiltersSource,
              };
              onUpdateFilters(updatedFilters);
            }}
          />

          <Text variant="body-medium">View comments by</Text>
          <RadioGroup
            disabled={!enabledFilters.order}
            name="view comments by"
            options={COMMENT_FILTERS_ORDER_OPTIONS}
            value={filters.order || null}
            onChange={(updatedValue: Nullable<CommentFiltersOrder>) => {
              const sourceValue =
                updatedValue === 'documentPosition'
                  ? 'document'
                  : filters.source;
              const updatedFilters = updatedValue
                ? { ...filters, order: updatedValue, source: sourceValue }
                : filters;
              onUpdateFilters(updatedFilters);
            }}
          />

          <Text variant="body-medium">{CHECKBOX_CUSTOM_LABEL}</Text>
          <CheckboxGroup
            disabled={!enabledFilters.flags}
            name={CHECKBOX_CUSTOM_LABEL}
            value={filters.flags}
            onChange={(values) => {
              const flags = values || [];
              const updatedFilters = {
                ...filters,
                flags: flags as CommentFiltersFlag[],
              };
              onUpdateFilters(updatedFilters);
            }}
            options={COMMENT_FILTERS_FLAG_OPTIONS}
          />
        </Layout>
      </DropdownBody>
    ),
    footer: (
      <DropdownFooter
        leftAction={{
          disabled: !isFiltersUpdated,
          text: 'Reset Filters',
          onClick: () =>
            onUpdateFilters({ status: 'open', flags: [], source: 'all' }),
        }}
      />
    ),
  });

  return wrap(
    <IconButton
      icon="filter"
      status={isFiltersUpdated ? 'active' : undefined}
      tooltip="Set filters"
      onClick={toggle}
    />,
  );
};
