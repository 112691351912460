export default `
<svg
  aria-hidden="true"
  aria-label="file"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M6 6C6 4.90625 6.875 4 8 4H13.1562C13.6875 4 14.1875 4.21875 14.5625 4.59375L17.4062 7.4375C17.7812 7.8125 18 8.3125 18 8.84375V18C18 19.125 17.0938 20 16 20H8C6.875 20 6 19.125 6 18V6ZM16.5 18V9H14C13.4375 9 13 8.5625 13 8V5.5H8C7.71875 5.5 7.5 5.75 7.5 6V18C7.5 18.2812 7.71875 18.5 8 18.5H16C16.25 18.5 16.5 18.2812 16.5 18Z"
    fill="currentcolor"
  />
</svg>
`;
