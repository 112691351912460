import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';

import { defaultConfig } from './sanitizeConfigs';

interface Props extends React.HTMLProps<HTMLDivElement> {
  html: string;
}

export default function HtmlContent({
  children: _unused,
  html,
  ...rest
}: Props): React.ReactElement {
  const sanitized = useMemo(() => {
    return DOMPurify.sanitize(html, defaultConfig) as string;
  }, [html]);
  return <div {...rest} dangerouslySetInnerHTML={{ __html: sanitized }} />;
}
