import { Question, QuestionGroup } from '~/types';

import { QuestionGroupPayload, QuestionPayload } from './types';

export const mapQuestionResponse = (question: QuestionPayload) => {
  const {
    attributes: {
      pilot_user_id: createdBy,
      created_at,
      pinned_at,
      name,
      text,
      updated_at,
      scope,
      question_group_ids,
    },
  } = question;

  return {
    id: question.id,
    createdAt: new Date(created_at),
    createdBy,
    name,
    pinnedAt: pinned_at ? new Date(pinned_at) : null,
    questionGroupIds: question_group_ids ?? [],
    scope,
    text,
    updatedAt: new Date(updated_at),
  } as Question;
};

export const mapQuestionGroupResponse = (
  questionGroup: QuestionGroupPayload,
) => {
  const {
    attributes: {
      created_at,
      description,
      name,
      pinned_at,
      updated_at,
      pilot_user_id: createdBy,
      updated_by_user_id: updatedBy,
      question_ids: questionIds,
      visibility,
      user_ids: userIds,
      department_ids: departmentIds,
    },
  } = questionGroup;

  return {
    id: questionGroup.id,
    createdAt: new Date(created_at),
    createdBy,
    description,
    name,
    pinnedAt: pinned_at ? new Date(pinned_at) : null,
    questionIds: questionIds ?? [],
    updatedAt: new Date(updated_at),
    updatedBy,
    visibility: visibility ?? 'PRIVATE',
    userIds: userIds ?? [],
    departmentIds: departmentIds ?? [],
  } as QuestionGroup;
};
