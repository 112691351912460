import { noop } from 'lodash';

import { Box, FormField, Layout, StatusText, Text, TextArea } from '~/eds';

import { OptimizeResult } from '../../../../../types';
import { mapTagToDescription } from '../../../utils';

interface Props {
  result: OptimizeResult;
}

export const BoostResultsOptionContent = ({ result }: Props) => {
  const { config, noUserConfigChanges, stats, tag } = result;
  const { instructions } = config;
  const { correct, delta_correct, total } = stats;
  const description = mapTagToDescription(tag);
  const correctDisplay =
    delta_correct < 0 ? `${delta_correct}` : `+${delta_correct}`;
  const correctStatus =
    delta_correct < 0 ? 'danger' : delta_correct > 0 ? 'success' : 'info';

  const initialCorrect = correct - delta_correct;
  const tooltip = `Boosted accuracy: ${correct} out of ${total}\nPrevious accuracy: ${initialCorrect} out of ${total}`;

  return (
    <Layout preset="subsections" pt={4}>
      <Text textAlign="left">{description}</Text>
      <Layout spacing={2} align="center">
        <StatusText
          status={correctStatus}
          text={correctDisplay}
          tooltip={tooltip}
          variant="banner"
        />
        <Box mb={-2}>
          <StatusText
            status="info"
            text="Correct"
            tooltip={tooltip}
            variant="section"
          />
        </Box>
      </Layout>
      <FormField<string, false>
        readOnly
        input={TextArea}
        label="Instructions"
        name="boost-option-instructions"
        statusText={
          !noUserConfigChanges
            ? { status: 'ai' as const, text: 'AI Suggested', icon: 'ai' }
            : undefined
        }
        value={instructions || ''}
        onChange={noop}
      />
    </Layout>
  );
};
