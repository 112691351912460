import React from 'react';
import { useStyles } from 'uinix-ui';

import { Button, Layout, Logo, Text } from '~/eds';
import { LOGIN_CONSTANTS } from '~/features/login';
import { useCountdown } from '~/hooks';

const Page = () => {
  const styles = useStyles();

  const handleClose = () => {
    window.close();
    // should always be called from a popup + iframe (but guarding with ?. in scenarios this is not the case)
    window?.opener?.top?.postMessage(
      {
        type: LOGIN_CONSTANTS.EVENTS.LOGIN_EMBED_SUCCESS,
      },
      '*',
    );
  };

  const countdown = useCountdown(
    5,
    (countdown) => countdown === 0 && handleClose(),
  );

  return (
    <Layout
      align="center"
      justify="center"
      styles={styles.background.darkChrome}
    >
      <Layout align="center" preset="sections">
        <Logo mode="icon" theme="dark" />
        <Text color="inherit" textAlign="center" variant="title">
          You have successfully logged in
        </Text>
        {countdown > 0 && (
          <Text color="inherit" textAlign="center">
            This window will automatically close in {countdown} seconds
          </Text>
        )}
        <Button isFullWidth text="Close" onClick={handleClose} />
      </Layout>
    </Layout>
  );
};

export default Page;
