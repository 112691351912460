import React, { Fragment, useRef, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
  workflowCreateCustomField,
  workflowFieldAssign,
} from '~/actions/workflow';
import FieldPlaceholder from '~/components/Workflow/shared/FieldPlaceholder';
import { Button, IconButton } from '~/eds';
import { WorkflowFieldType } from '~/enums';
import {
  excludedFieldTypes,
  getFieldById,
  getFieldDefinitionByFieldId,
  getLinkByFieldId,
} from '~/reducers/workflow';
import { Box, Checkbox, FlexLayout, Text, TextInput } from '~/ui';
import { testIsFieldNameUsed } from '~/utils/workflow';

import RadioContracts from './RadioContracts';
import RadioFieldTypes from './RadioFieldTypes';
import RadioItem from './RadioItem';
import RadioParties from './RadioParties';
import RadioPartiesWithSigners from './RadioPartiesWithSigners';
import RadioSigners from './RadioSigners';

function Content({
  onFieldAssign,
  onHide,
  //connected
  disableActions,
  field,
  fields,
  fieldLink,
  selectedFieldDefinition,
  workflowCreateCustomField,
  workflowFieldAssign,
}) {
  const initialFieldDefinition = selectedFieldDefinition;
  const initialFieldEntity = fieldLink;
  const initialFieldName = field.name || '';
  const initialIsEsig = field.isEsignatureTag || false;
  const initialIsEsigOptional =
    initialIsEsig && (field.isEsignatureTagOptional || false);
  const initialView = ['party', 'signer'].includes(
    initialFieldDefinition?.entity,
  )
    ? 'assignPartySigner'
    : 'default';

  const [fieldDefinition, setFieldDefinition] = useState(
    initialFieldDefinition,
  );
  const [fieldEntity, setFieldEntity] = useState(initialFieldEntity);
  const [fieldName, setFieldName] = useState(initialFieldName);
  const [fieldIsEsig, setFieldIsEsig] = useState(initialIsEsig);
  const [fieldIsEsigOptional, setFieldIsEsigOptional] = useState(
    initialIsEsigOptional,
  );
  const [view, setView] = useState(initialView);
  const inputRef = useRef(null);
  const isFieldNameUsed = testIsFieldNameUsed(
    fields,
    fieldName,
    initialFieldName,
  );
  const disabled = disableActions || isFieldNameUsed || !fieldName;
  const disableEsigSelection = excludedFieldTypes.includes(
    fieldDefinition?.fieldType,
  );

  let disabledTooltip = '';
  if (isFieldNameUsed) {
    disabledTooltip =
      'Field names must be unique.  The name you provided already exists.';
  } else if (!fieldName) {
    disabledTooltip = 'Please provide a name for the field.';
  }

  let isValid = false;
  switch (view) {
    case 'default':
      isValid = fieldDefinition?.entity === 'contract';
      break;
    case 'assignPartySigner':
      isValid =
        fieldEntity?.id &&
        (!fieldEntity?.entity ||
          fieldEntity?.entity === fieldDefinition?.entity);
      break;
    case 'customFieldTypes':
      isValid = fieldDefinition?.fieldType;
      break;
    default:
      isValid = false;
  }

  function reset() {
    setFieldName(initialFieldName);
    setFieldDefinition(initialFieldDefinition);
    setFieldEntity(initialFieldEntity);
    setFieldIsEsig(initialIsEsig);
    setFieldIsEsigOptional(initialIsEsigOptional);
    setView(initialView);
    onHide();
  }

  function handleSetFieldDefinition(definition) {
    setFieldIsEsig(initialIsEsig || definition?.isEsignatureTag);
    setFieldIsEsigOptional(
      initialIsEsigOptional || definition?.isEsignatureTagOptional,
    );
    setFieldDefinition(definition);
  }

  function handleFieldAssign() {
    const definition = fieldDefinition || {
      entity: 'contract',
      key: fieldName,
      fieldType: fieldEntity?.type || field.type,
    };
    const field_id = field.id ? field.id : uuid();
    if (!field.id) {
      workflowCreateCustomField({
        field: { id: field_id, name: fieldName, type: fieldEntity?.type },
      });
    }
    let entityId = null;
    if (['signer', 'party'].includes(definition.entity)) {
      entityId = fieldEntity?.id;
    }
    const fieldAssignData = {
      fieldId: field_id,
      fieldDefinition: definition,
      fieldName,
      entityId,
      fieldIsEsig,
      fieldIsEsigOptional,
    };
    workflowFieldAssign(fieldAssignData);
    onFieldAssign && onFieldAssign(fieldAssignData);
    reset();
  }

  function renderChoosePlacementButton() {
    return (
      <Box sx={{ borderTop: 'border', position: 'sticky' }}>
        <Box p={6}>
          <Button
            disabled={disabled}
            isFullWidth
            text="Edit placement"
            onClick={() => setView('assignEsigState')}
            variant="primary"
            tooltip={disabledTooltip}
          />
        </Box>
      </Box>
    );
  }

  function renderDoneButton() {
    return (
      <Box sx={{ borderTop: 'border', position: 'sticky' }}>
        <Box p={6}>
          <Button
            disabled={disabled}
            isFullWidth
            text="Done"
            onClick={handleFieldAssign}
            variant="primary"
            tooltip={disabledTooltip}
          />
        </Box>
      </Box>
    );
  }

  function renderAssignPartySignerHeader() {
    return (
      <>
        <Box
          sx={{
            left: 6,
            position: 'absolute',
            align: 'center',
          }}
        >
          <IconButton
            icon="arrow-left"
            onClick={() => {
              handleSetFieldDefinition(initialFieldDefinition);
              setView('default');
            }}
            size="s"
            text="previous"
          />
        </Box>
        <Text color="gray-900" variant="xs-dense-bold">
          {`Assign ${fieldDefinition?.entity}`}
        </Text>
      </>
    );
  }

  function renderAssignPartySignerContent() {
    return (
      <RadioPartiesWithSigners
        selectedFieldDefinition={fieldDefinition}
        selectedEntity={fieldEntity}
        onClick={(entity) => {
          setFieldEntity(entity);
          if (!fieldName.length) {
            inputRef.current.focus();
          }
        }}
      />
    );
  }

  function renderCustomFieldTypesHeader() {
    return (
      <>
        <Box
          sx={{
            left: 6,
            position: 'absolute',
            align: 'center',
          }}
        >
          <IconButton
            icon="arrow-left"
            onClick={() => {
              handleSetFieldDefinition(initialFieldDefinition);
              setView('default');
            }}
            size="s"
            text="previous"
          />
        </Box>
        <Text color="gray-900" variant="xs-dense-bold">
          Specify Custom Field Type
        </Text>
      </>
    );
  }

  function renderCustomFieldTypesContent() {
    return (
      <RadioFieldTypes
        selectedFieldDefinition={fieldDefinition}
        onClick={(updatedFieldType) => {
          setFieldDefinition({
            entity: 'contract',
            fieldType: updatedFieldType,
          });
          if (!fieldName.length) {
            inputRef.current.focus();
          }
        }}
      />
    );
  }

  function renderDefaultHeader() {
    return (
      <Text color="gray-900" variant="xs-dense-bold">
        Assign field type
      </Text>
    );
  }

  function renderDefaultContent() {
    const sections = [
      {
        title: 'Contract',
        content: (
          <RadioContracts
            selectedFieldDefinition={fieldDefinition}
            onClick={(definition) => {
              handleSetFieldDefinition(definition);
              if (!fieldName.length) {
                inputRef.current.focus();
              }
            }}
            onCustomFieldClick={() => {
              handleSetFieldDefinition({
                entity: 'custom',
                fieldType: field.type,
              });
              setView('customFieldTypes');
            }}
          />
        ),
      },
      {
        title: 'Parties',
        content: (
          <RadioParties
            selectedFieldDefinition={fieldDefinition}
            onClick={handleSetFieldDefinition}
            onShowMore={() => {
              setView('assignPartySigner');
            }}
          />
        ),
      },
      {
        title: 'Signers',
        content: (
          <RadioSigners
            field={field}
            selectedFieldDefinition={fieldDefinition}
            onClick={handleSetFieldDefinition}
            onShowMore={() => {
              setView('assignPartySigner');
            }}
          />
        ),
      },
    ];

    return sections.map((section, i) => (
      <Fragment key={section.title}>
        <Box>
          <Box px={6} py={3}>
            <Text color="gray-600" variant="xs-dense-caps">
              {section.title}
            </Text>
          </Box>
          {section.content}
        </Box>
        {i < sections.length - 1 && (
          <Box
            bg="gray-400"
            mx={6}
            my={3}
            sx={{ flexShrink: 0, height: '1px' }}
          />
        )}
      </Fragment>
    ));
  }

  function renderAssignEsigStateContent() {
    const not_esig_text = 'Insert intake form response into generated document';
    const not_esig_subtext =
      'Signer information will be populated based on what submitters put in the intake form';
    const esig_text = [
      'Insert field for signer to complete during e-signature process',
    ];
    const esig_subtext =
      'Information will be populated by the signer in DocuSign / Adobe Sign';
    const checkbox_text = 'Make this field required during e-signature';

    return (
      <>
        <FlexLayout flexDirection="column" sx={{ position: 'sticky' }}>
          <FlexLayout
            alignItems="center"
            justifyContent="center"
            py={5}
            sx={{ borderBottom: 'border', position: 'relative' }}
          >
            <Box
              sx={{
                left: 6,
                position: 'absolute',
                align: 'center',
              }}
            >
              <IconButton
                icon="arrow-left"
                onClick={() => {
                  setView('assignPartySigner');
                }}
                size="s"
                text="previous"
              />
            </Box>
            <Text color="gray-900" variant="xs-dense-bold">
              Choose placement
            </Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout flexDirection="column" flexGrow="1">
          <Box key="esig-status">
            <Box marginTop={3}>
              <RadioItem
                option={{
                  name: 'esig-radio',
                  label: not_esig_text,
                  sublabel: not_esig_subtext,
                  value: false,
                }}
                selected={!fieldIsEsig}
                onClick={() => setFieldIsEsig(false)}
                alignItems="top"
                disabled={disableEsigSelection}
              />
            </Box>
            <Box bg="gray-400" mx={6} my={3} sx={{ height: '1px' }} />
            <RadioItem
              option={{
                name: 'esig-radio',
                label: esig_text,
                sublabel: esig_subtext,
                value: true,
              }}
              selected={fieldIsEsig}
              onClick={() => setFieldIsEsig(true)}
              alignItems="top"
              disabled={disableEsigSelection}
            />
            <Box pl={12} my={3}>
              <Checkbox
                disabled={
                  !fieldIsEsig ||
                  (disableEsigSelection &&
                    ![WorkflowFieldType.Initials].includes(
                      fieldDefinition?.fieldType,
                    ))
                }
                shouldTruncate={false}
                label={checkbox_text}
                align="top"
                value={fieldIsEsig && !fieldIsEsigOptional}
                onChange={(value) => setFieldIsEsigOptional(value === false)}
              />
            </Box>
          </Box>
        </FlexLayout>
        <Box sx={{ borderTop: 'border', position: 'sticky' }}>
          <Box p={6}>
            <Button
              disabled={disabled}
              isFullWidth
              text="Done"
              onClick={handleFieldAssign}
              shouldTruncate
              tooltip={disabledTooltip}
            />
          </Box>
        </Box>
      </>
    );
  }

  function renderModelContent() {
    return (
      <>
        <FlexLayout flexDirection="column" sx={{ position: 'sticky' }}>
          <FlexLayout
            alignItems="center"
            justifyContent="center"
            py={5}
            sx={{ borderBottom: 'border', position: 'relative' }}
          >
            {view === 'default' && renderDefaultHeader()}
            {view === 'customFieldTypes' && renderCustomFieldTypesHeader()}
            {view === 'assignPartySigner' && renderAssignPartySignerHeader()}
          </FlexLayout>
          <FlexLayout flexDirection="column" p={6} space={2}>
            <FlexLayout alignItems="center" justifyContent="space-between">
              <Text color="gray-600" variant="2xs-dense">
                Field name
              </Text>
              <FieldPlaceholder value={field.placeholder} />
            </FlexLayout>
            <TextInput
              autoFocus
              ref={inputRef}
              value={fieldName}
              onChange={setFieldName}
              width="fullWidth"
            />
          </FlexLayout>
        </FlexLayout>
        <FlexLayout
          flexDirection="column"
          flexGrow="1"
          sx={{ overflowY: 'scroll' }}
        >
          {view === 'default' && renderDefaultContent()}
          {view === 'customFieldTypes' && renderCustomFieldTypesContent()}
          {view === 'assignPartySigner' && renderAssignPartySignerContent()}
        </FlexLayout>
        {isValid
          ? (view === 'assignPartySigner' && renderChoosePlacementButton()) ||
            renderDoneButton()
          : null}
      </>
    );
  }

  return (
    <FlexLayout flexDirection="column" sx={{ height: '716px', width: '328px' }}>
      {view === 'assignEsigState'
        ? renderAssignEsigStateContent()
        : renderModelContent()}
    </FlexLayout>
  );
}

const mapStateToProps = ({ builder, workflow }, { fieldId }) => ({
  disableActions: !builder.shouldEnableAllComponents,
  field: fieldId ? getFieldById(workflow, fieldId) : {},
  fields: workflow.fields,
  fieldLink: getLinkByFieldId(workflow, fieldId),
  selectedFieldDefinition: getFieldDefinitionByFieldId(workflow, fieldId),
});

export default connect(mapStateToProps, {
  workflowCreateCustomField,
  workflowFieldAssign,
})(Content);
