export default `
<svg
  aria-hidden="true"
  aria-label="lock"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M8 10V8C8 5.8125 9.78125 4 12 4C14.1875 4 16 5.8125 16 8V10H17C18.0938 10 19 10.9062 19 12V18C19 19.125 18.0938 20 17 20H7C5.875 20 5 19.125 5 18V12C5 10.9062 5.875 10 7 10H8ZM9.5 10H14.5V8C14.5 6.625 13.375 5.5 12 5.5C10.5938 5.5 9.5 6.625 9.5 8V10ZM6.5 18C6.5 18.2812 6.71875 18.5 7 18.5H17C17.25 18.5 17.5 18.2812 17.5 18V12C17.5 11.75 17.25 11.5 17 11.5H7C6.71875 11.5 6.5 11.75 6.5 12V18Z"
    fill="currentcolor"
  />
</svg>
`;
