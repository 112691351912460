export default `
<svg
  aria-hidden="true"
  aria-label="reaction-thumbsup"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M7 9.98438H5C4.4375 9.98438 4 10.4219 4 10.9531V17.9219C4 18.4531 4.4375 18.8906 5 18.8906H7C7.53125 18.8906 8 18.4531 8 17.9219V10.9844C8 10.4531 7.53125 9.98438 7 9.98438ZM20 11.0781C20 9.95312 19.0312 9.01562 17.9062 9.01562H14.7812C15.0625 8.23438 15.25 7.54688 15.25 7.14062C15.25 6.07812 14.4062 5.01562 13.0312 5.01562C11.5938 5.01562 11.1875 6.01562 10.875 6.82812C9.875 9.35938 9 8.89062 9 9.73438C9 10.1406 9.3125 10.4844 9.75 10.4844C9.90625 10.4844 10.0625 10.4531 10.1875 10.3281C12.5938 8.42188 12 6.51562 13.0312 6.51562C13.5625 6.51562 13.75 6.89062 13.75 7.14062C13.75 7.39062 13.5 8.39062 12.9375 9.39062C12.875 9.51562 12.8438 9.64062 12.8438 9.76562C12.8438 10.2031 13.1875 10.4844 13.5938 10.4844H17.875C18.2188 10.5156 18.5 10.7656 18.5 11.0781C18.5 11.3906 18.25 11.6719 17.9375 11.6719C17.5312 11.7031 17.2188 12.0469 17.2188 12.4219C17.2188 12.9219 17.5938 12.9219 17.5938 13.3281C17.5938 14.1094 16.5 13.7031 16.5 14.6406C16.5 14.9844 16.6875 15.0469 16.6875 15.3281C16.6875 16.0469 15.75 15.7656 15.75 16.6094C15.75 16.7656 15.8125 16.7969 15.8125 16.9219C15.8125 17.2344 15.5312 17.5156 15.2188 17.5156H13.5625C12.75 17.5156 11.9688 17.2344 11.3438 16.7656L10.1875 15.8906C10.0625 15.7969 9.90625 15.7344 9.75 15.7344C9.3125 15.7344 8.96875 16.1094 8.96875 16.4844C8.96875 16.7344 9.09375 16.9531 9.28125 17.1094L10.4375 17.9531C11.3438 18.6406 12.4375 18.9844 13.5625 18.9844H15.2188C16.3125 18.9844 17.2188 18.1406 17.2812 17.0469C17.8438 16.6719 18.1875 16.0469 18.1875 15.3281C18.1875 15.2344 18.1875 15.1406 18.1875 15.0469C18.7188 14.6719 19.0938 14.0469 19.0938 13.3281C19.0938 13.1719 19.0625 13.0156 19.0312 12.8281C19.5938 12.4531 20 11.8281 20 11.0781Z"
    fill="currentcolor"
  />
</svg>
`;
