import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  commentsCreate,
  commentsDelete,
  commentsLoad,
  commentsUpdate,
} from '~/actions';
import * as api from '~/api';
import CommentThread, {
  CommentContent,
  CommentHeader,
} from '~/components/Client/CommentThread';
import Collapse from '~/components/Shared/Collapse';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import StatusText from '~/components/Shared/StatusText';
import { DateText } from '~/eds';
import { TicketStatusType } from '~/enums';
import { withCurrentUser } from '~/hocs';
import { useAsync } from '~/hooks';
import { getCommentsByThreadId } from '~/reducers/comments';
import { getDocumentVersion } from '~/reducers/ticket';
import { getCanViewAllIssues, getEntityById } from '~/reducers/ticketReviewer';
import { withRouting } from '~/routing';
import { Box, FlexLayout, Text } from '~/ui';
import { capitalize } from '~/utils/strings';
import { getStatusTextStatus } from '~/utils/ticket';

import ApprovalSummary from '../../shared/ApprovalSummary';
import HighlightNavigator from './HighlightNavigator';
import ReviewEntityApproveReject from './ReviewEntityApproveReject';

function getStatusColor(status) {
  switch (status) {
    case TicketStatusType.Rejected:
      return 'red-600';
    case TicketStatusType.Approved:
      return 'green-500';
    case TicketStatusType.Pending:
    default:
      return 'yellow-500';
  }
}

function eventRenderer(event) {
  const { createdDate, note, status, userId } = event;
  const statusTextStatus = getStatusTextStatus(status);

  const statusText =
    status === TicketStatusType.Approved ? TicketStatusType.Completed : status;

  return (
    <FlexLayout flexDirection="column" space={4}>
      <CommentHeader datetime={createdDate} userId={userId} />
      <StatusText status={statusTextStatus}>
        {capitalize(statusText)} on{' '}
        <DateText
          date={createdDate ? new Date(createdDate) : undefined}
          format="full"
          variant="tiny"
        />
      </StatusText>
      {note && (
        <StatusText noBackground status={statusTextStatus}>
          <CommentContent content={note} />
        </StatusText>
      )}
    </FlexLayout>
  );
}

function ReviewEntity({
  entityId,
  index,
  // injected
  currentUser,
  // connected
  canViewAllIssues,
  comments,
  enableHighlights,
  entity,
  ticketId,
  participantIds,
  commentsCreate,
  commentsDelete,
  commentsLoad,
  commentsUpdate,
  onRiskClick,
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const {
    approvals,
    judgmentResults,
    description,
    entityType,
    htmlHighlights,
    riskId,
    name,
    status,
  } = entity;

  const showApprovals = canViewAllIssues && approvals.length > 0;

  const hasApproval = approvals.some(
    (approval) => approval.userId === currentUser.id,
  );

  const { isLoading: isLoadingComments } = useAsync(
    api.getComments,
    { entityId, entityType },
    {
      condition: entityId,
      deps: [entityId],
      successHandler: (response) => {
        commentsLoad([{ comments: response, threadId: entityId }]);
      },
    },
  );

  if (!canViewAllIssues && !hasApproval) {
    return null;
  }

  async function postComment(content) {
    const newComment = await api.addComment({
      content,
      entityId,
      entityType,
    });
    commentsCreate({ ...newComment, threadId: entityId });
    return newComment;
  }

  async function deleteComment(comment) {
    await api.deleteComment(comment);
    commentsDelete(comment);
  }

  async function updateComment(comment) {
    const updatedComment = await api.updateComment({
      id: comment.id,
      content: comment.content,
      entityId,
      entityType,
    });
    commentsUpdate({ ...updatedComment, threadId: entityId });
    return updatedComment;
  }

  const collapsedContent = (
    <FlexLayout flexDirection="column" ml={8} mt={2} space={2}>
      {comments.length > 0 && (
        <Text color="gray-600" variant="2xs-dense-caps">
          {comments.length} {pluralize('comment', comments.length)}
        </Text>
      )}
    </FlexLayout>
  );

  return (
    <Box
      mt={!isCollapsed || index === 0 ? 12 : 6}
      pt={isCollapsed ? 6 : 12}
      sx={{ borderTop: 'border' }}
    >
      <Box
        ml={2}
        sx={{
          borderLeftColor: getStatusColor(status),
          borderLeftStyle: 'solid',
          borderLeftWidth: isCollapsed ? '4px' : '6px',
        }}
      >
        <Box ml={3} py={1}>
          <Collapse
            isCollapsed={isCollapsed}
            collapsedContent={collapsedContent}
            iconColor="black1"
            trigger={
              <Text color="gray-900" variant="s-dense-bold">
                {name}
              </Text>
            }
            onToggleCollapse={() => {
              setIsCollapsed(!isCollapsed);
            }}
          >
            <FlexLayout flexDirection="column" ml={8} mt={8} pr={4} space={8}>
              {description && (
                <Text color="gray-900" variant="s-spaced">
                  {description}
                </Text>
              )}
              {showApprovals && <ApprovalSummary approvals={approvals} />}
              {enableHighlights && htmlHighlights && (
                <HighlightNavigator
                  htmlHighlights={htmlHighlights}
                  riskId={riskId}
                  status={status}
                  onRiskClick={onRiskClick}
                />
              )}
              <ReviewEntityApproveReject entityId={entityId} />
              {isLoadingComments ? (
                <FlexLayout alignItems="center" justifyContent="center">
                  <LoadingSpinner />
                </FlexLayout>
              ) : (
                <CommentThread
                  ticketId={ticketId}
                  participantIds={participantIds}
                  isEditable
                  comments={comments}
                  events={judgmentResults}
                  eventRenderer={eventRenderer}
                  promises={{
                    deleteComment,
                    updateComment,
                    postComment,
                  }}
                />
              )}
            </FlexLayout>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}

ReviewEntity.propTypes = {
  entityId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

const mapStateToProps = (
  { comments, ticket, ticketReviewer },
  { entityId, params },
) => ({
  canViewAllIssues: getCanViewAllIssues(ticketReviewer),
  comments: getCommentsByThreadId(comments, entityId),
  enableHighlights: getDocumentVersion(ticket, params.versionNumber)
    .isLatestVersion,
  entity: getEntityById(ticketReviewer, entityId),
  ticketId: ticket.id,
  participantIds: ticket.participantIds,
});

export default withRouting(
  connect(mapStateToProps, {
    commentsCreate,
    commentsDelete,
    commentsLoad,
    commentsUpdate,
  })(withCurrentUser(ReviewEntity)),
);
