import PT from 'prop-types';
import React from 'react';

import { Box } from '~/ui';

const FlexLayout = React.forwardRef(
  (
    {
      alignItems,
      children,
      flex,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      flexWrapSpace = 0,
      inline = false,
      justifyContent,
      space = 0,
      sx = {},
      ...rest
    },
    ref,
  ) => {
    const isFlexWrapped = flexWrap === 'wrap';
    const marginDirection = flexDirection === 'column' ? 'mb' : 'mr';

    return (
      <Box
        mt={isFlexWrapped ? -flexWrapSpace : undefined}
        ref={ref}
        sx={{
          ...sx,
          alignItems,
          display: inline ? 'inline-flex' : 'flex',
          flex,
          flexDirection,
          flexGrow,
          flexShrink,
          flexWrap,
          justifyContent,
          '> *': {
            mt: (theme) =>
              isFlexWrapped
                ? `${theme.space[flexWrapSpace]} !important`
                : undefined,
          },
          '> :not(:last-child)': {
            [marginDirection]: space,
          },
        }}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);

FlexLayout.propTypes = {
  /** CSS align-items property */
  alignItems: PT.string,
  /** Apply the specified HTML element string or React component for rendering  */
  as: PT.oneOfType([PT.string, PT.element]),
  /** React children */
  children: PT.node,
  /** Conveniently disables pointer events on the element.  Useful to disable user interaction */
  disabled: PT.bool,
  /** Element ID */
  id: PT.string,
  /** CSS flex property */
  flex: PT.string,
  /** CSS flex-direction property */
  flexDirection: PT.string,
  /** CSS flex-grow property */
  flexGrow: PT.string,
  /** CSS flex-shrinkj property */
  flexShrink: PT.string,
  /** CSS flex-wrap property */
  flexWrap: PT.string,
  /** Vertical distance in space units (see `space` props) for flex-wrapped elements */
  flexWrapSpace: PT.oneOfType([PT.number, PT.string]),
  /** Whether to use inline-flex instead of flex */
  inline: PT.bool,
  /** CSS justify-content property */
  justifyContent: PT.string,
  /** Forwards provided ref */
  // ref: PT.object,
  /** A valid theme value from theme.space (note that numeric space values will be DEPRECATED in favor of the formal `x1`, `x2`, `x3` etc tokens).  Optional CSS override values can be provided, but discouraged.*/
  space: PT.oneOfType([PT.number, PT.string]),
  /** Supports sx styles */
  sx: PT.object,
};

export default FlexLayout;
