export enum CodedErrorCodeType {
  BulkImportExistingNewOrRunningJob = 1000,
  BulkImportJobStatusCannotBeCancelled = 1001,
  BulkImportJobStatusCannotBeAcknowledged = 1002,
  BulkImportInvalidOrMissingImportId = 1003,
  BulkImportInvalidOrMissingClientId = 1004,
  BulkImportFileNotProvided = 1005,
  BulkImportFileProvidedNotValidExcel = 1006,
  BulkImportFileNameNotFound = 1007,
  BulkImportResultFileTypeNotValid = 1008,
  BulkImportFileInfected = 1009,
  BulkImportMissingColumns = 1012,
  BulkImportDuplicatedColumns = 1013,
  BulkImportEmptyColumns = 1014,
  BulkImportDocumentMetadataErrorSubmittingFile = 2000,
  BulkImportUserErrorSubmittingFile = 2001,
  BulkImportErrorFetchingJobs = 2002,
  BulkImportErrorDownloadingJobResult = 2003,
  BulkImportErrorCancellingJob = 2004,
  BulkImportErrorAcknowledgingJob = 2005,
  BulkImportError = 2006,
  BulkImportUserErrorQueuingJob = 2007,
  BulkExportDocumentMetadataErrorDownloading = 2008,
  BulkExportUserErrorDownloading = 2009,
  BulkExportUserTemplateErrorDownloading = 2010,
}
