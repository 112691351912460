import { useEffect, useState } from 'react';

import { useRouting } from '~/routing';

/**
 * Reusable state hook that sets URL params.
 * @param {string} paramKey
 * @returns {Array} [updated query param value, updater function that takes param's new value]
 */
export function useSearchParams(paramKey) {
  if (!paramKey) throw new Error("expected parameter 'paramKey'");

  const { location, navigate } = useRouting();
  const query = new URLSearchParams(location.search);
  const queryParamValue = query.get(paramKey);
  const [value, setValue] = useState(queryParamValue);

  useEffect(() => setValue(queryParamValue), [queryParamValue]);

  function updateValue(updatedValue) {
    setValue(updatedValue);

    query.set(paramKey, updatedValue);
    navigate(
      { path: location.pathname, search: `?${query}` },
      { replace: true },
    );
  }

  return [value, updateValue];
}
