import {
  black1,
  black3,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  loadingContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: 'rgba(249, 251, 252, 0.85)',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  radioContainerContent: {
    marginBottom: '10px',
    '& > div:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  searchResultsContainer: {
    marginTop: '20px',
  },
  searchResultsHeader: {
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
  },
  searchList: {
    margin: '15px 0',
    display: 'flex',
    backgroundColor: 'transparent',
    border: 'none',
  },
  searchGroupName: {
    marginLeft: '7px',
    display: 'inline-flex',
    alignItems: 'center',
  },
  searchResultsMessage: {
    margin: '10px',
    padding: '5px',
    color: black3,
    fontSize: '14px',
  },
  modalLabel: {
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
  },
  modalInput: {
    marginBottom: '20px',
    width: '100%',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    marginTop: '12px',
    padding: '12px 16px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    fontSize: '15px',
    caretColor: evisortBlue,
    '&:hover': {
      borderColor: evisortBlue,
    },
    '&:focus': {
      borderColor: evisortBlue,
      outline: 'none',
    },
  },
  modalTextArea: {
    marginTop: '10px',
    padding: '12px',
    width: '100%',
    maxWidth: '100%',
    height: '90px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    fontSize: '15px',
    '&:focus': {
      borderColor: evisortBlue,
      outline: 'none',
    },
  },
};

export default styles;
