import React from 'react';
import { connect } from 'react-redux';

import { getPartiesWithSigners } from '~/reducers/workflow';
import { Box, Tooltip } from '~/ui';
import { getFieldDefinitionStatus } from '~/utils/workflow';

import RadioItem from './RadioItem';

function RadioPartiesWithSigners({
  selectedFieldDefinition,
  selectedEntity,
  onClick,
  //connected
  fieldLinks,
  parties,
}) {
  const { counter, internal } = parties;
  const isPartyFieldDefinition = selectedFieldDefinition?.entity === 'party';
  const isSignerFieldDefinition = !isPartyFieldDefinition;

  function renderParties(parties) {
    return parties.map((party) => {
      const { id, name, signers } = party;
      const { isSelected, isUsed, tooltip } = getFieldDefinitionStatus({
        entityId: id,
        fieldDefinition: selectedFieldDefinition,
        fieldLinks,
        selectedEntityId: selectedEntity?.id,
        selectedFieldDefinition,
      });
      return (
        <Box key={id}>
          <Tooltip
            content={isPartyFieldDefinition && isUsed ? tooltip : undefined}
          >
            <RadioItem
              disabled={isSignerFieldDefinition || isUsed}
              option={{ label: name, value: id }}
              selected={isSelected}
              onClick={() => onClick(party)}
            />
          </Tooltip>
          {signers.map((signer) => {
            const { id, name } = signer;
            const { isSelected, isUsed, tooltip } = getFieldDefinitionStatus({
              entityId: id,
              fieldDefinition: selectedFieldDefinition,
              fieldLinks,
              selectedEntityId: selectedEntity?.id,
              selectedFieldDefinition,
            });
            return (
              <Tooltip
                key={id}
                content={
                  isSignerFieldDefinition && isUsed ? tooltip : undefined
                }
              >
                <RadioItem
                  disabled={isPartyFieldDefinition || isUsed}
                  indent={2}
                  option={{ label: name, value: id }}
                  selected={isSelected}
                  onClick={() => onClick(signer)}
                />
              </Tooltip>
            );
          })}
        </Box>
      );
    });
  }

  return (
    <Box>
      {renderParties(internal)}
      <Box bg="gray-400" mx={6} my={3} sx={{ height: '1px' }} />
      {renderParties(counter)}
    </Box>
  );
}

const mapStateToProps = ({ workflow }) => ({
  parties: getPartiesWithSigners(workflow),
  fieldLinks: workflow.fieldLinks,
});

export default connect(mapStateToProps)(RadioPartiesWithSigners);
