import { checkIsSuperAdmin } from '~/permissions';

import { RoutePathType } from '../enums';
import { RouteResolver } from '../types';

/**
 * This creates the default resolvers for super admin routes:
 * - Provides the component to resolve to if user passes the permission checkIsSuperAdmin.
 * - Redirects to RoutePathType.Home otherwise
 */
export const createSuperAdminResolvers = (
  component: React.ComponentType,
): RouteResolver[] => {
  return [
    {
      permission: checkIsSuperAdmin,
      component,
    },
    {
      redirectPath: RoutePathType.Home,
    },
  ];
};
