import React from 'react';

import { useDisableBodyScroll } from '../../hooks';
import { Box } from '../Box';

type Mode = 'center';

interface Props {
  /** Contents associated with the backdrop */
  children: React.ReactNode;
  /** Disables the body scroll when the backdrop is active */
  disableBodyScroll?: boolean;
  /** Supported modes */
  mode?: Mode;
}

export const Backdrop = ({
  children,
  disableBodyScroll = true,
  mode,
}: Props) => {
  useDisableBodyScroll(disableBodyScroll);

  return (
    <Box
      styles={[
        componentStyles.backdrop,
        mode === 'center' ? componentStyles.center : null,
      ]}
    >
      {children}
    </Box>
  );
};

const componentStyles = {
  backdrop: {
    bottom: 0,
    backgroundColor: 'background.fade',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 'popover',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
