import { createReducer } from '@reduxjs/toolkit';

import * as actions from '../actions';

const sortUploadingFiles = (files) => {
  return files.sort((a, b) => {
    if (a.date_modified > b.date_modified) return 1;
    if (a.document_name && b.document_name) {
      if (a.document_name.toLowerCase() > b.document_name.toLowerCase())
        return 1;
    }

    return -1;
  });
};

const getInitialState = () => ({
  uploadingFiles: [],
});

export default createReducer(getInitialState(), (builder) => {
  builder.addCase(actions.dropzoneEnqueueUploadingFile, (state, action) => {
    const newFiles = [...state.uploadingFiles, action.payload];
    state.uploadingFiles = sortUploadingFiles(newFiles);
  });
  builder.addCase(actions.dropzoneUpdateUploadingProgress, (state, action) => {
    let newFiles = [...state.uploadingFiles];
    let currentUploadingFile = {
      ...newFiles.find((file) => file.id === action.payload.id),
    };

    currentUploadingFile.progress = action.payload.progress;

    if (action.payload.processing_status) {
      currentUploadingFile.processing_status = action.payload.processing_status;
    }

    newFiles = newFiles.filter((file) => file.id !== action.payload.id);
    newFiles = [...newFiles, currentUploadingFile];
    state.uploadingFiles = sortUploadingFiles(newFiles);
  });
  builder.addCase(
    actions.dropzoneRemoveUploadedFiles,
    (state, { payload = [] }) => {
      state.uploadingFiles = [...state.uploadingFiles].filter(
        (file) => !payload.includes(file.id),
      );
    },
  );
});
