import PropTypes from 'prop-types';
import React from 'react';
import InlineSvg from 'react-inlinesvg';

import filter from '~/ui/assets/icons/filter.svg';

const FilterIcon = (props) => (
  <InlineSvg height={props.size} src={filter} width={props.size} />
);

FilterIcon.defaultProps = {
  color: 'rgba(0, 0, 0, 0.75)',
  size: '18',
  opacity: '1',
};

FilterIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  opacity: PropTypes.string,
};

export default FilterIcon;
