import React from 'react';

import { Actions, ActionsMenu, Layout, Paginate } from '../../../components';
import { DEPRECATED_actionsToUserActions } from '../../../utils';
import { CELL_PX, ROW_DEFAULT_HEIGHT, ROW_STICKY_ZINDEX } from '../constants';
import ExportXlsxAction from './ExportXlsxAction';
import SelectedSummary from './SelectedSummary';
import { SettingsMenu } from './SettingsMenu';

function Controls({
  actions: initialActions = [],
  bulkActions: initialBulkActions = [],
  getBulkActions,
  disabled = false,
  enableExportXlsx,
  enablePageSizeSelect,
  enablePagination,
  enableManageColumns,
  isAllRowsSelected,
  isMultiSelectRows,
  pageSizes,
  state,
  onAddRemoveColumns,
  onLoadView,
  onReorderColumns,
  onSaveView,
  onToggleSelectAllRows,
  onUpdatePageIndex,
  onUpdatePageSize,
}) {
  const { pageIndex, pageSize, selectedRowIds, totalCount } = state;

  const hasSelectedItems = isAllRowsSelected || selectedRowIds.length > 0;

  const actions = initialActions.map((action) => ({
    ...action,
    onClick: () => action.onClick(state),
  }));

  const _bulkActions = getBulkActions?.(state) ?? initialBulkActions;
  const bulkActions = _bulkActions.map((action) => ({
    ...action,
    onClick: () => action.onClick(state),
  }));

  const selectedCount = isAllRowsSelected ? totalCount : selectedRowIds.length;

  return (
    <Layout
      align="center"
      disabled={disabled}
      justify="space-between"
      px={CELL_PX}
      aria-label="Controls"
      styles={{
        height: ROW_DEFAULT_HEIGHT,
        left: 0,
        position: 'sticky',
        top: 0,
        zIndex: ROW_STICKY_ZINDEX,
      }}
    >
      {hasSelectedItems ? (
        <Layout align="center">
          {isMultiSelectRows && (
            <SelectedSummary
              isAllRowsSelected={isAllRowsSelected}
              selectedCount={selectedCount}
              totalCount={totalCount}
              onToggleSelectAllRows={onToggleSelectAllRows}
            />
          )}
          {bulkActions.length > 0 && (
            <ActionsMenu
              enablePortal
              actions={bulkActions}
              icon="chevron-down"
              name="bulk actions"
              text="Actions"
              tooltip={`Apply action to selected (${selectedCount}) rows`}
            />
          )}
        </Layout>
      ) : (
        <div />
      )}
      <Layout align="center">
        {enablePagination && (
          <Paginate
            name="Select page"
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={totalCount}
            onUpdatePageIndex={onUpdatePageIndex}
          />
        )}
        <Layout preset="icons">
          {actions.length > 0 && (
            <Actions
              actions={DEPRECATED_actionsToUserActions(actions, 'icon')}
              size="s"
            />
          )}
          {enableExportXlsx && <ExportXlsxAction state={state} />}
          {(enableManageColumns || enablePageSizeSelect) && (
            <SettingsMenu
              actions={actions}
              enableManageColumns={enableManageColumns}
              enablePageSizeSelect={enablePageSizeSelect}
              pageSize={pageSize}
              pageSizes={pageSizes}
              state={state}
              onAddRemoveColumns={onAddRemoveColumns}
              onLoadView={onLoadView}
              onReorderColumns={onReorderColumns}
              onSaveView={onSaveView}
              onUpdatePageSize={onUpdatePageSize}
            />
          )}
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Controls;
