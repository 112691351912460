import React, { useState } from 'react';

import { Button, FormField, Layout, Select, types } from '~/eds';
import { Nullable } from '~/types';
import { sortByStringValue } from '~/utils/array';

import { ClauseType } from './types';

interface Props {
  clauseTypes: ClauseType[];
  isLoading?: boolean;
  onSubmit: (clauseType: ClauseType) => void;
}

export const ClauseTypeSelect = ({
  clauseTypes,
  isLoading,
  onSubmit,
}: Props) => {
  const [value, setValue] = useState<Nullable<ClauseType>>(null);

  const options = transformClauseTypeOptions(clauseTypes);
  const clauseTypeSelectProps = {
    options,
    isLoading,
    isMulti: false,
  };
  return (
    <Layout preset="form-fields">
      <FormField<ClauseType, false>
        name="clause type select"
        label="Select a Clause Type"
        placeholder="Search and select..."
        input={Select}
        inputProps={clauseTypeSelectProps}
        value={value}
        onChange={setValue}
      />
      <Button
        disabled={!value}
        icon="status-new"
        iconPosition="left"
        text="Generate Suggestions"
        variant="primary"
        onClick={() => onSubmit(value!)}
      />
    </Layout>
  );
};

export const transformClauseTypeOptions = (
  clauseTypes: ClauseType[],
): types.Option<ClauseType, false>[] => {
  return clauseTypes
    .map((clauseType) => ({
      label: clauseType.name,
      value: clauseType,
      description: `Appears in ${clauseType.count} contracts`,
    }))
    .sort(sortByStringValue('label'));
};
