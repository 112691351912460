import React, { useEffect, useState } from 'react';

import { FormField, Layout, Modal, Select } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { Filter } from '~/evifields';
import { SearchFiltersPreview } from '~/features/filters';
import { useHasFeatureFlag } from '~/hooks';
import { api } from '~/redux';
import { isActiveFilter } from '~/redux/slices/search';
import { RoutePathType } from '~/routing';

import { getRestrictFilterIds } from './hooks';
import { ProvisionsType, ProvisionSuggestions } from './types';

const MAX_SELECTED_PROVISION_TYPES = 5;

export const TagClauseModal = ({
  hideModal,
  bulkTaggingInsufficientWords,
  createProvision,
}: any) => {
  const isClauseTaggingDisabled = useHasFeatureFlag(
    FeatureFlagType.DisableDocTagging,
  );

  const [
    selectedProvisionTypes,
    setSelectedProvisionTypes,
  ] = useState<ProvisionsType>([]);

  const [includesExistingProvisions, setIncludesExistingProvisions] = useState(
    false,
  );
  const [filters, setFilters] = useState<Filter[]>([]);

  const [selectedApplyTo, setSelectedApplyTo] = useState(false);
  const [exceedsProvisionTypeLimit, setExceedsProvisionTypeLimit] = useState(
    false,
  );
  const [provisionTypes, setProvisionTypes] = useState<ProvisionSuggestions[]>(
    [],
  );

  const {
    data: suggestions,
    isLoading: isLoadingSuggestions,
  } = api.endpoints.getProvisions.useQuery(undefined);

  const restrictedClauseFilterIds = getRestrictFilterIds();

  useEffect(() => {
    if (suggestions)
      setProvisionTypes(
        suggestions.suggestions.map((key: string) => ({
          label: key,
          value: key,
        })),
      );
  }, [suggestions]);

  useEffect(() => {
    setExceedsProvisionTypeLimit(
      Boolean(
        selectedProvisionTypes &&
          selectedProvisionTypes.length > MAX_SELECTED_PROVISION_TYPES,
      ),
    );
  }, [selectedProvisionTypes]);

  useEffect(() => {
    if (selectedProvisionTypes) {
      const hasExistingProvision = selectedProvisionTypes.some(
        (provisionType) => {
          return suggestions?.suggestions.includes(provisionType);
        },
      );
      setIncludesExistingProvisions(hasExistingProvision);
    }
  }, [selectedProvisionTypes, suggestions]);

  const shouldDisableClauseTagging =
    isClauseTaggingDisabled || bulkTaggingInsufficientWords;

  const navigateToAutomationHub = {
    additionalText: 'Existing filters can only be managed in',
    linkText: 'Automation Hub',
    pathname: `${RoutePathType.AutomationHub}`,
    tooltip: 'Go to Automation Hub page',
  };

  const activeFilters = filters.filter(isActiveFilter);

  return (
    <Modal
      width="l"
      isVisible
      onHide={hideModal}
      title="Quick AI"
      primaryAction={{
        disabled:
          selectedProvisionTypes?.length === 0 || exceedsProvisionTypeLimit,
        tooltip:
          selectedProvisionTypes?.length === 0
            ? 'Select at least one Clause'
            : exceedsProvisionTypeLimit
            ? `Maximum of ${MAX_SELECTED_PROVISION_TYPES} Clause selections`
            : undefined,
        text: 'Save',
        onClick: () => {
          if (selectedProvisionTypes)
            selectedProvisionTypes.forEach((selectedProvisionType) => {
              const searchQuery =
                !includesExistingProvisions && selectedApplyTo
                  ? activeFilters
                  : [];

              createProvision(
                selectedProvisionType,
                selectedApplyTo,
                searchQuery,
              );
            });
          hideModal();
        },
      }}
    >
      <Layout preset="sections">
        <Layout preset="form-fields">
          <FormField<ProvisionsType, false>
            id="select-clause-tagging-names"
            required
            footer="Maximum of 5 Clause selections"
            label="Clause"
            input={Select}
            value={selectedProvisionTypes}
            onChange={setSelectedProvisionTypes}
            name="Clause"
            error={
              exceedsProvisionTypeLimit
                ? `Maximum of ${MAX_SELECTED_PROVISION_TYPES} Clause selections`
                : undefined
            }
            description="The Clause(s) that this language will be associated with when identified"
            placeholder="Select Clauses"
            inputProps={{
              isLoading: isLoadingSuggestions,
              isMulti: true,
              onAddOption: () => setSelectedProvisionTypes,
              options: provisionTypes,
            }}
          />
          <FormField<boolean, false>
            id="select-clause-tagging-type"
            label="Apply Sample to"
            name="Apply Sample to"
            required
            input={Select}
            value={selectedApplyTo}
            onChange={(value) => {
              setSelectedApplyTo(value ?? false);
            }}
            placeholder="Select Clauses"
            inputProps={{
              disabled: shouldDisableClauseTagging,
              isMulti: false,
              isClearable: false,
              options: [
                { label: 'Only this document', value: false },
                { label: 'This and other documents', value: true },
              ],
            }}
          />
        </Layout>
        {selectedApplyTo && (
          <SearchFiltersPreview
            filters={filters}
            title="Document Filters"
            defaultFilterIds={
              includesExistingProvisions
                ? undefined
                : ['folder', 'Contract Type', 'Effective Date']
            }
            description={
              includesExistingProvisions
                ? undefined
                : 'Filters will be used to select documents that contain this exact clause'
            }
            descriptionLink={
              includesExistingProvisions ? navigateToAutomationHub : undefined
            }
            onUpdateFilters={(updatedFilters: Filter[]) => {
              setFilters(updatedFilters);
            }}
            docsInScope
            readOnly={includesExistingProvisions}
            restrictFilterIds={restrictedClauseFilterIds}
          />
        )}
      </Layout>
    </Modal>
  );
};
