export enum FlagType {
  ActivityLogPanel = 'activity-log-on-side-panel-EKP-14361',
  AddCustomPropertyToDocxPdf = 'add-custom-property-to-docx-pdf-pilot-EKP-15897',
  AdvancedBulkClauseEditing = 'bulk-clause-editing-panel-ekp-19460',
  AlertsInSearchSubNav = 'alerts-in-search-subnav-ui-EKP-15253',
  AribaIntegrationPhase1 = 'ariba-phase-1-ekp-16256',
  AribaIntegrationPhase2 = 'ariba-phase-2-ekp-17503',
  AribaIntegrationPhase3 = 'ariba-phase-3-ekp-18287',
  AribaFieldMappingCsvSupport = 'ariba-field-mapping-csv-support-ekp-23224',
  AribaMapEvisortDocumentURL = 'ekp-27330-ariba-map-evisort-url-field',
  AribaSyncPairConfig = 'ariba-sync-pair-configuration-ekp-25705',
  AskAnything = 'ask-anything-ekp-23870',
  AskAnythingConversationHistory = 'ask-anything-conversation-history-EKP-24670',
  AskAnythingPreSig = 'ask-ai-in-pre-sig-ekp-24800',
  AskAnythingQuestionGroups = 'question-groups-ekp-24729',
  AuditLogsNewPage = 'audit-logs-new-page-EKP-20963',
  BreakIntakeFormLink = 'break-intake-form-link-when-editing-a-document-ekp-23576',
  BringAiToWorkflow = 'BRING-AI-TO-WORKFLOW-EKP-10358',
  BulkDeleteFieldValues = 'bulk-delete-field-values-ekp-16651',
  BulkEditFieldsRebuild = 'bulk-edit-fields-rebuild-ekp-23444',
  CCRecipients = 'ADD_CC_TO_ENVELOPE_EKP-12080',
  CheckFormFieldFileExtension = 'check-form-fields-uploaded-file-type-everest-ekp-14687',
  CheckSignatureStatus = 'add-check-signature-status-button-ekp-20337',
  ClauseLibrary = 'clause-library-EKP-13390',
  ClauseLibraryAiSuggest = 'ai-suggest-mvp-ekp-16394',
  ClauseLibraryInDocumentEditor = 'clause-library-in-document-editor-ekp-24589',
  ClauseLibraryRBAC = 'rbac-support-in-clouseau-ekp-22697',
  ClauseValueExportSettings = 'use-clause-value-for-export-settings-ekp-18340',
  ComponentMonitoringInSentry = 'enable-component-monitoring-in-sentry-ekp-23728',
  ConversationalAI = 'conversational-ai-ekp-18072',
  ConversationalAIV2 = 'conversational-ai-v2-EKP-24929',
  ConversationalAIButtonOnDocPageHeader = 'ask-ai-button-on-document-page-ekp-22487',
  ConvertPdfToDocx = 'CONVERT-PDF-TO-DOCX-EKP-13176',
  CopyWorkflow = 'copy-workflow-ekp-26597',
  DashboardsExportExcel = 'dashboards-export-excel-ekp-14404',
  DashboardV2 = 'dashboard-v2-ui-EKP-11185',
  DashboardV3 = 'dashboards-v3-ekp-16349',
  DeprecateEditProvision = 'deprecate-doc-viewer-edit-provision-ekp-25488',
  DeprecatePilotSearch = 'deprecate-pilot-search-ekp-25910',
  DisableDocumentTableSorting = 'disable-document-table-sorting-ekp-22199',
  DocumentCommentImport = 'kafka-publish-comment-import-cmd-ekp-16656',
  DocumentsTableRevamp = 'documents-table-revamp-ekp-15821',
  DocumentXRayBilling = 'document-x-ray-billing-EKP-21569',
  DocumentXRayCloseEndedDropdowns = 'x-ray-close-ended-dropdowns-ekp-23086',
  DocumentXRayDebugOutput = `document-x-ray-enable-llm-output-EKP-21757`,
  DocumentXrayDataFields = 'document-x-ray-data-fields-ekp-23651',
  DocumentXRayEnhancedFilters = 'x-ray-enhanced-filters-ekp-26158',
  DocumentXRayExactMatch = 'x-ray-exact-match-EKP-26609',
  DocumentXrayOptimize = 'document-x-ray-optimize-ekp-23418',
  DocumentXRayTagging = 'x-ray-tagging-ekp-25732',
  DocumentXRayUnratedBoost = 'document-x-ray-allow-unrated-boost-ekp-23418',
  DocumentsBulkActionsOnSidepanel = 'display-document-bulk-actions-on-sidepanel-ekp-21793',
  DashboardV2DocumentsBulkActions = 'dashboard-v2-document-bulk-actions-ekp-20467',
  DownloadDocumentAttachment = 'download-document-attachment-ekp-27325',
  CanduScript = 'candu-script-ekp-24642',
  EJ2TrackEditHistory = 'track-edit-history-in-ej2-ekp-21219',
  EsignEnhancements = 'ESIG-ENHANCEMENTS-EKP-13011',
  EnableWebhooksComponents = 'enable-webhooks-components-ekp-27770',
  FieldAi = 'field-ai-ekp-18695',
  FieldMappingOneTimeImport = 'ariba-field-mapping-for-one-time-import-EKP-21044',
  FieldValueMigrationDeferredFeaturesBypass = 'fv-migration-deferred-features-bypass-EKP-15541',
  FolderAccessSettings = 'folder-access-settings-ekp-27990',
  GenAi = 'generative-ai-m1-ekp-15067',
  GenAiM2 = 'generative-ai-m2-ekp-14984',
  GenAiRedline = 'generative-ai-m1.5-ekp-15067',
  GenAiUsingDelphi = 'update-gen-ai-backend-to-use-azure-open-ai-gpt-4-model-ekp-22204',
  HideAwsS3SyncPairOption = 'EKP-25969-hide-aws-s-3-sync-pair',
  HideDocumentStatus = 'hide-document-status-ekp-18469',
  HideLegacyMultiSelectShowMore = 'hide-legacy-multi-select-show-more-EKP-9594',
  HideShareButton = 'disable-share-document-button-ekp-18915',
  IntakeFormFieldWithReadOnly = 'intake-form-field-with-read-only-fields-ekp-24165',
  IntakeFormPanel = 'intake-form-sidepanel-EKP-15260',
  LdPoc = 'LDPOC-UI-EKP-12166',
  ManageClientsPageNew = 'manage-clients-page-new-ekp-21040',
  ManagedEntities = 'managed-entities-ekp-28298',
  NewUsersPage = 'new-user-interface-ekp-16941',
  Onboarding = 'bento-onboarding-ekp-20003',
  PdfViewerZoom = 'pdf-viewer-zoom-ekp-23303',
  PinnedFilters = 'PinnedFilters-EKP-12494',
  PinnedFiltersV3 = 'pinned-filters-v3-EKP-20466',
  POCFlag = 'POCFlag',
  PremiumPermissions = 'premium-permissions-ekp-19952',
  PremiumAdministration = 'premium-administration-ekp-20639',
  PDFJSDisableRunningJSCode = 'disable-evaluating-js-pdf-preview-ekp-25474',
  ProcessableDocumentTypes = 'processable-document-types-pilot-EKP-16340',
  QueryBuilderEnhancedUx = 'query-builder-enhanced-ux-EKP-26624',
  QuickAiFilters = 'quick-ai-filters-EKP-26523',
  QuickAiTableSorting = 'Quick-AI-table-sorting-EKP-13386',
  Rebrand = 'brand-transformation-phase-1-EKP-13212',
  RestorationHub500PageSize = 'restoration-hub-500-page-size-ekp-15159',
  ReactivateUsersEmailNotification = 'reactivate-user-email-notification-ekp-18548',
  RolodexAsUserDataSource = 'send-kafka-event-for-rolodex-user-changes-ekp-25303',
  SamlEnforceV2 = 'ENFORCE-SSO-FOR-ADMINS-AEGIS-EKP-12904',
  SearchV3 = 'search-v3-ekp-21526',
  SearchNewUI = 'search-new-ui-EKP-26060',
  SearchV3Navigation = 'search-v3-navigation-EKP-14157',
  SearchAsHomePage = 'search-as-home-page-EKP-14947',
  SearchFolderPanel = 'search-folder-panel-ui-EKP-13241',
  SearchPageResultsForAnalyzer = 'search-page-results-for-analyzer-ui-EKP-14679',
  SharepointSyncSiteSearch = 'sharepoint-sync-site-search-ui-EKP-13316',
  ShareQuestionGroup = 'share-question-group-ekp-23010',
  SkipEmptyFolders = 'skip-empty-folders-ui-EKP-13941',
  SuperAdminSamlSSO = 'super-admin-saml-sso-EKP-21264',
  SyncConfiguration = 'sync-configuration-ekp-22664',
  TextDelimitedMultiselectFilter = 'multiselect-filter-copy-paste-view-ekp-9715',
  TextDelimitedDocumentIdFilter = 'text-delimited-document-id-filter-ekp-23900',
  TicketEmailActionWithComment = 'EMAIL-ACTIONS-COMMENT-EVEREST-EKP-14013',
  TicketListCancelledTab = 'ticket-list-cancelled-tab-ekp-14778',
  TicketListWithOpenSearch = 'ticket-list-opensearch-ekp-20053',
  TicketNaming = 'TICKET-NAMING-EVEREST-EKP-9884',
  Tracing = 'tracing-ui-EKP-12259',
  TurnTracking = 'turn-tracking-ekp-22552',
  UnifiedDocumentDetailsPanel = 'document-table-details-panel-ekp-20186',
  UnifyDocumentViewer = 'unify-pdf-document-viewer-ekp-25127',
  UnprocessedPDFPreview = 'Unprocessed-PDF-Preview-EKP-15808',
  UploadPageNew = 'revamp-upload-page-ekp-20203',
  Webhooks = 'webhooks-ekp-27768',
  WordEditingOnlineWorkflow = 'word-online-editing-workflow-EKP-20133',
  WorkflowACL = 'workflow-acl-ekp-15855',
}

export const flags = {
  [FlagType.ActivityLogPanel]: true,
  [FlagType.AddCustomPropertyToDocxPdf]: false,
  [FlagType.AdvancedBulkClauseEditing]: true,
  [FlagType.AribaFieldMappingCsvSupport]: true,
  [FlagType.AlertsInSearchSubNav]: false,
  [FlagType.AribaIntegrationPhase1]: true,
  [FlagType.AribaIntegrationPhase2]: true,
  [FlagType.AribaIntegrationPhase3]: true,
  [FlagType.AribaMapEvisortDocumentURL]: true,
  [FlagType.AribaSyncPairConfig]: true,
  [FlagType.AskAnything]: true,
  [FlagType.AskAnythingConversationHistory]: true,
  [FlagType.AskAnythingPreSig]: true,
  [FlagType.AskAnythingQuestionGroups]: true,
  [FlagType.AuditLogsNewPage]: true,
  [FlagType.BreakIntakeFormLink]: true,
  [FlagType.BringAiToWorkflow]: true,
  [FlagType.BulkDeleteFieldValues]: false,
  [FlagType.BulkEditFieldsRebuild]: true,
  [FlagType.CanduScript]: false,
  [FlagType.CCRecipients]: true,
  [FlagType.CheckFormFieldFileExtension]: true,
  [FlagType.CheckSignatureStatus]: true,
  [FlagType.ClauseLibrary]: true,
  [FlagType.ClauseLibraryAiSuggest]: false,
  [FlagType.ClauseLibraryInDocumentEditor]: true,
  [FlagType.ClauseLibraryRBAC]: true,
  [FlagType.ClauseValueExportSettings]: false,
  [FlagType.ComponentMonitoringInSentry]: false,
  [FlagType.ConversationalAI]: true,
  [FlagType.ConversationalAIV2]: true,
  [FlagType.ConversationalAIButtonOnDocPageHeader]: true,
  [FlagType.ConvertPdfToDocx]: true,
  [FlagType.CopyWorkflow]: true,
  [FlagType.DashboardsExportExcel]: true,
  [FlagType.DashboardV2]: true,
  [FlagType.DashboardV3]: true,
  [FlagType.DeprecateEditProvision]: false,
  [FlagType.DeprecatePilotSearch]: false,
  [FlagType.DisableDocumentTableSorting]: false,
  [FlagType.DocumentCommentImport]: false,
  [FlagType.DocumentsTableRevamp]: true,
  [FlagType.DocumentXRayBilling]: true,
  [FlagType.DocumentXRayCloseEndedDropdowns]: true,
  [FlagType.DocumentXRayDebugOutput]: false,
  [FlagType.DocumentXrayDataFields]: false,
  [FlagType.DocumentXRayEnhancedFilters]: false,
  [FlagType.DocumentXRayExactMatch]: true,
  [FlagType.DocumentXrayOptimize]: true,
  [FlagType.DocumentXRayTagging]: true,
  [FlagType.DocumentXRayUnratedBoost]: true,
  [FlagType.DocumentsBulkActionsOnSidepanel]: true,
  [FlagType.DashboardV2DocumentsBulkActions]: true,
  [FlagType.DownloadDocumentAttachment]: true,
  [FlagType.EnableWebhooksComponents]: false,
  [FlagType.EJ2TrackEditHistory]: 0,
  [FlagType.EsignEnhancements]: true,
  [FlagType.FieldAi]: true,
  [FlagType.FieldMappingOneTimeImport]: true,
  [FlagType.FieldValueMigrationDeferredFeaturesBypass]: false,
  [FlagType.FolderAccessSettings]: true,
  [FlagType.GenAi]: true,
  [FlagType.GenAiM2]: true,
  [FlagType.GenAiRedline]: true,
  [FlagType.GenAiUsingDelphi]: false,
  [FlagType.HideAwsS3SyncPairOption]: true,
  [FlagType.HideDocumentStatus]: false,
  [FlagType.HideLegacyMultiSelectShowMore]: false,
  [FlagType.HideShareButton]: false,
  [FlagType.IntakeFormFieldWithReadOnly]: true,
  [FlagType.IntakeFormPanel]: true,
  [FlagType.LdPoc]: true,
  [FlagType.ManageClientsPageNew]: false,
  [FlagType.ManagedEntities]: false,
  [FlagType.NewUsersPage]: true,
  [FlagType.Onboarding]: false,
  [FlagType.PdfViewerZoom]: true,
  [FlagType.PinnedFilters]: true,
  [FlagType.PinnedFiltersV3]: true,
  [FlagType.POCFlag]: true,
  [FlagType.PremiumPermissions]: true,
  [FlagType.PremiumAdministration]: true,
  [FlagType.ProcessableDocumentTypes]: ['.pdf', '.docx', '.doc', '.tiff'],
  [FlagType.PDFJSDisableRunningJSCode]: true,
  [FlagType.QueryBuilderEnhancedUx]: true,
  [FlagType.QuickAiFilters]: true,
  [FlagType.QuickAiTableSorting]: false,
  [FlagType.ReactivateUsersEmailNotification]: true,
  [FlagType.Rebrand]: true,
  [FlagType.RestorationHub500PageSize]: false,
  [FlagType.RolodexAsUserDataSource]: true,
  [FlagType.SamlEnforceV2]: true,
  [FlagType.SearchV3]: true,
  [FlagType.SearchNewUI]: true,
  [FlagType.SearchV3Navigation]: true,
  [FlagType.SearchAsHomePage]: false,
  [FlagType.SearchFolderPanel]: true,
  [FlagType.SearchPageResultsForAnalyzer]: false,
  [FlagType.SharepointSyncSiteSearch]: true,
  [FlagType.ShareQuestionGroup]: false,
  [FlagType.SkipEmptyFolders]: true,
  [FlagType.SuperAdminSamlSSO]: true,
  [FlagType.SyncConfiguration]: true,
  [FlagType.TextDelimitedMultiselectFilter]: true,
  [FlagType.TextDelimitedDocumentIdFilter]: true,
  [FlagType.TicketEmailActionWithComment]: false,
  [FlagType.TicketListCancelledTab]: false,
  [FlagType.TicketListWithOpenSearch]: true,
  [FlagType.TicketNaming]: true,
  [FlagType.Tracing]: false,
  [FlagType.TurnTracking]: true,
  [FlagType.UnifiedDocumentDetailsPanel]: true,
  [FlagType.UnifyDocumentViewer]: true,
  [FlagType.UnprocessedPDFPreview]: true,
  [FlagType.UploadPageNew]: false,
  [FlagType.Webhooks]: true,
  [FlagType.WordEditingOnlineWorkflow]: true,
  [FlagType.WorkflowACL]: true,
};
