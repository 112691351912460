import { useEffect, useRef, useState } from 'react';

import { INPUT_DEBOUNCE_MS } from '../constants';

// https://dev.to/loonywizard/react-usethrottle-hook-87h
export const useThrottledValue = <T>(value: T, ms = INPUT_DEBOUNCE_MS): T => {
  const [throttledValue, setThrottledValue] = useState<T>(value);
  const lastExecuted = useRef<number>(Date.now());

  useEffect(() => {
    if (Date.now() >= lastExecuted.current + ms) {
      lastExecuted.current = Date.now();
      setThrottledValue(value);
    } else {
      const timerId = setTimeout(() => {
        lastExecuted.current = Date.now();
        setThrottledValue(value);
      }, ms);

      return () => clearTimeout(timerId);
    }
  }, [value, ms]);

  return throttledValue;
};
