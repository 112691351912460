import React, { useMemo, useState } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import {
  Box,
  Button,
  CheckboxGroups,
  Layout,
  SearchInput,
  Text,
  useModal,
  useThrottledValue,
} from '~/eds';
import { useCurrentUser } from '~/hooks';
import { Nullable } from '~/types';

import { Field, FieldId, Group, GroupId } from './types';
import { groupFieldOptions } from './utils';

interface Props {
  fields: Record<FieldId, Field>;
  groups: Group[];
  onAdd: (fieldIds: Record<GroupId, FieldId[]>) => void;
  pinnedFieldIds?: FieldId[];
  onUpdatePinnedFieldIds?: (
    updatedFieldIds: FieldId[],
    action: { value: FieldId; type: 'pin' | 'unpin' },
  ) => void;
}

export const AddFilters = ({
  fields,
  groups,
  pinnedFieldIds,
  onAdd,
  onUpdatePinnedFieldIds,
}: Props) => {
  const [search, setSearch] = useState('');
  const user = useCurrentUser();
  const [selectedFieldIds, setSelectedFieldIds] = useState<
    Nullable<Record<GroupId, FieldId[]>>
  >({});
  const hasFieldsSelected =
    selectedFieldIds && Object.values(selectedFieldIds).flat().length > 0;

  // Determine throttleMs based on the data size.  Interim perf solution until CheckboxGroups can be virtualized via react-window.
  const throttleMs = useMemo(() => Object.keys(fields).length * 2, [fields]);
  const throttledSearch = useThrottledValue(search, throttleMs);

  const handleHide = () => {
    setSearch('');
    setSelectedFieldIds({});
  };

  const handleSearch = (updatedSearch: Nullable<string>) => {
    setSearch(updatedSearch || '');
  };

  const handleSubmit = () => {
    onAdd(selectedFieldIds || {});
    hideModal();
  };

  const handleShowFilters = () => {
    trackSegment('openFilters', {
      user_id: user.id,
      client_id: user.client,
    });
    showModal();
  };

  const checkboxGroups = React.useMemo(() => {
    const groupedFieldOptions = groupFieldOptions({ fields, groups });
    const optionsCount = groupedFieldOptions.reduce(
      (acc, group) => acc + group.options.length,
      0,
    );

    if (optionsCount === 0) {
      return <Text preset="description">No matching fields.</Text>;
    }

    return (
      <CheckboxGroups
        columns={3}
        groups={groupedFieldOptions}
        pins={pinnedFieldIds}
        search={throttledSearch}
        value={selectedFieldIds}
        onChange={setSelectedFieldIds}
        onUpdatePins={onUpdatePinnedFieldIds}
      />
    );
  }, [fields, groups, pinnedFieldIds, selectedFieldIds, throttledSearch]);

  const [modal, showModal, hideModal] = useModal({
    children: (
      <Layout direction="column" h="100%" spacing={6}>
        <Box flex="none">
          <SearchInput
            autoFocus
            name="fields"
            placeholder="Search fields"
            value={search}
            onChange={handleSearch}
          />
        </Box>
        <Box flex="auto" overflowY="auto">
          {checkboxGroups}
        </Box>
      </Layout>
    ),
    disableHideOnEscape: true,
    isStretched: true,
    primaryAction: {
      disabled: !hasFieldsSelected,
      text: 'Add',
      onClick: handleSubmit,
    },
    onHide: handleHide,
    title: 'Add Filters',
    width: 'l',
  });

  return (
    <>
      <Button text="Add Filters" variant="action" onClick={handleShowFilters} />
      {modal}
    </>
  );
};
