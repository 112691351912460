import { types } from '~/eds';
import { UploadStatusType } from '~/enums';
import { capitalize, toLowercaseTrimmed } from '~/utils/strings';

import type { AlgoStatus, BaseStatusMessageProps, UploadFile } from './types';

export const getChipStatus = (d: UploadFile) => {
  let text;
  let status;
  let icon;
  let tooltip;

  switch (d.status) {
    case UploadStatusType.Completed:
      icon = 'status-complete';
      text = 'Completed';
      tooltip = 'Your document was uploaded and processed successfully';
      status = 'success';
      break;
    case UploadStatusType.Queued:
      icon = 'clock';
      text = 'Queued';
      tooltip =
        'Your document was uploaded successfully and has been placed in line for processing, which may take several minutes';
      status = 'warning';
      break;
    case UploadStatusType.Processing:
      icon = 'sync';
      text = 'Processing';
      tooltip =
        'Your document is being processed and should be available shortly';
      status = 'active';
      break;
    case UploadStatusType.UploadFileTypeFailure:
      icon = 'x';
      text = 'Upload Failed';
      tooltip =
        'Your document was not uploaded because its file type is not supported.';
      status = 'danger';
      break;
    case UploadStatusType.ProcessingError:
    case UploadStatusType.UploadFailed:
    case UploadStatusType.OcrFailure:
    case UploadStatusType.AlgorithmFailure:
      icon = 'x';
      text = 'Processing Error';
      tooltip =
        'Your document was not processed due to a system error. Please try uploading again later or contact support.';
      status = 'danger';
      break;
    case UploadStatusType.Uploading:
      icon = 'arrow-up';
      text = 'Uploading';
      tooltip = 'Your document is uploading';
      status = 'active';
      break;
    case UploadStatusType.Duplicate:
      icon = 'status-warning';
      text = 'Duplicate';
      status = 'warning';
      break;
    default:
      status = 'info';
      text = 'Unknown status';
      tooltip = undefined;
  }

  return { text, status, icon, tooltip };
};

export const formatUploadSize = (d: UploadFile) => {
  const size = [
    UploadStatusType.Uploading,
    UploadStatusType.UploadFailed,
  ].includes(d.status)
    ? d.size
    : d.size * 1024;

  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const unit = units[i];

  const amount = (size / Math.pow(1024, i)).toFixed(2);

  return { amount, unit };
};

export const isFileUploading = (status: UploadStatusType) => {
  switch (status) {
    case UploadStatusType.Uploading:
    case UploadStatusType.UploadFailed:
    case UploadStatusType.ProcessingError:
      return true;
    default:
      return false;
  }
};

export const formatLabel = (label: string) => {
  return capitalize(toLowercaseTrimmed(label));
};

export const mapAlgoStatusMessageProps = (
  algoStatus: AlgoStatus,
): BaseStatusMessageProps => {
  const { title, status, message } = algoStatus;

  let statusMessage;
  let statusColor: types.StatusType;
  switch (status) {
    case 0:
      statusColor = 'success';
      statusMessage = 'In Progress';
      break;
    case -1:
      statusColor = 'danger';
      statusMessage = 'Failed';
      break;
    case 1:
      statusColor = 'success';
      break;
    case -2:
      statusColor = 'active';
      statusMessage = 'Partially Complete';
      break;
    default:
      statusColor = 'warning';
      statusMessage = message;
      break;
  }
  return {
    message: statusMessage ?? '',
    status: statusColor,
    title,
  };
};
