import React from 'react';

import { TextContainer } from '~/components/Shared/Filters_DEPRECATED';
import { enquote, Layout } from '~/eds';
import { ClauseValue, Filter } from '~/evifields';

interface Props {
  filter: Filter<ClauseValue>;
}

const ClauseValuesPreview = ({ filter }: Props) => {
  let provisionText = '';
  let clauseSearchOperatorLabel = '';
  let clauseSearchText = '';

  const clauseValue = filter.values[0];
  if (clauseValue) {
    provisionText = clauseValue.provision;
    const scopeText = clauseValue.text_search[0]?.scope?.label;
    clauseSearchOperatorLabel = scopeText ? `and ${scopeText}` : '';
    clauseSearchText = clauseValue.text_search[0]?.text || '';
  }

  return (
    <Layout align="center" spacing={1} minW={0}>
      <TextContainer text={provisionText || ''} />
      {clauseSearchOperatorLabel && (
        <strong>{clauseSearchOperatorLabel}</strong>
      )}
      {clauseSearchText && <TextContainer text={enquote(clauseSearchText)} />}
    </Layout>
  );
};

export default ClauseValuesPreview;
