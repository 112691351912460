import { noop } from 'lodash';
import React from 'react';
import { IndicatorProps } from 'react-select';

import { Option } from '../../../types';
import { IconButton } from '../../IconButton';

export const DropdownIndicator = (props: IndicatorProps<Option, boolean>) => {
  const { isEmbedded, menuIsOpen } = props.selectProps;

  if (isEmbedded) {
    return null;
  }

  return <IconButton preset={menuIsOpen ? 'hide' : 'show'} onClick={noop} />;
};
