import { createSelector } from '@reduxjs/toolkit';

import { types } from '~/eds';
import {
  getFieldByQuestionId,
  getQuestionById,
  getSectionById,
} from '~/reducers/intakeForm';
import { Nullable } from '~/types';

import {
  FormQuestion,
  FormSection,
  IntakeForm,
  QuestionField,
} from '../api/methods/ticket';

export interface QuestionFieldState extends QuestionField {
  question: string;
  errorMessage: Nullable<string>;
  modified: boolean;
  isPlaceHolder: boolean;
  isEnabled: boolean;
  warningMessage: Nullable<string>;
}

export interface FormQuestionState
  extends Omit<FormQuestion, 'field' | 'fieldId' | 'name'> {
  field: types.Uuid;
}

export interface FormSectionState extends Omit<FormSection, 'questions'> {
  questions: types.Uuid[];
}

interface IntakeFormState extends IntakeForm {
  isValidated: boolean;
  hasErrors: boolean;
  fields: QuestionFieldState[];
  questions: FormQuestionState[];
}

const selectFormSlice = (rootState: any): IntakeFormState =>
  rootState['intakeForm'];

const selectTicketSlice = (rootState: any): any => rootState['ticket'];

export const selectIntakeForm = createSelector(
  selectFormSlice,
  (state) => state,
);

export const selectFormValidation = createSelector(
  selectFormSlice,
  (state) => state.isValidated,
);

export const selectFormHasErrors = createSelector(
  selectFormSlice,
  (state) => state.hasErrors,
);

export const selectTicket = createSelector(
  selectTicketSlice,
  (ticket) => ticket,
);

export const selectTicketStage = createSelector(
  selectTicketSlice,
  (state) => state.stage,
);

export const selectFormSection = createSelector(
  [
    selectFormSlice,
    (_intakeForm: IntakeForm, sectionId: types.Uuid) => sectionId,
  ],
  (intakeForm, sectionId): FormSectionState =>
    getSectionById(intakeForm, sectionId),
);

export const selectFormQuestion = createSelector(
  [
    selectFormSlice,
    (_intakeForm: IntakeForm, questionId: types.Uuid) => questionId,
  ],
  (intakeForm, questionId): FormQuestionState =>
    getQuestionById(intakeForm, questionId),
);

export const selectQuestionField = createSelector(
  [
    selectFormSlice,
    (_intakeForm: IntakeForm, questionId: types.Uuid) => questionId,
  ],
  (intakeForm, questionId): QuestionFieldState =>
    getFieldByQuestionId(intakeForm, questionId),
);
