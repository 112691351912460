import { keyBy } from 'lodash';
import PT from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useGlobalFilter, useTable } from 'react-table';
import { useStyles } from 'uinix-ui';

import {
  Box,
  Chips,
  Layout,
  SearchInput,
  Text,
  TruncateText,
} from '../../components';
import {
  Body,
  createStateReducer,
  defaultColumn,
  fromSelectedRowIdsMap,
  getControlledStateProps,
  getCurrentData,
  getPrimaryColumnKey,
  getRowId,
  Head,
  hooks,
  prepareColumns,
} from './bridge';
import { DEFAULT_OPTIONS, LOADING_ROWS_LENGTH } from './constants';
import Controls from './Controls';
import { ManageColumnsPanel } from './ManageColumnsPanel';
import {
  getTableCaption,
  prependShowDetailsToRowActions,
  resolveOptions,
} from './utils';

/**
 * - Table will be formally typed in the near future.
 * - The following JSDoc loose type is to prevent confusion from TS consumers and indicate explicitly that this JS file is untyped.
 * @param {any} props
 */
function _Table({
  actions,
  activeRowId = null,
  columnGroups,
  columns: allColumns,
  data,
  header,
  isBordered = true,
  isLoading = false,
  mode,
  name,
  options: initialOptions = DEFAULT_OPTIONS,
  rowActions: initialRowActions,
  bulkActions,
  getBulkActions,
  getDisabledRow,
  getRowActions,
  getRowActionsMenuId,
  totalCount = data.length,
  // controlled state
  state: controlledState,
  onPageSizeChange, // TODO: rename to onUpdatePageSize for API consistency
  onUpdate,
  onPaginate,
  onLoadView,
  onSaveView,
  onSetColumnOrder,
  rowDetails,
  reactTableOptions = {}, // TODO: deprecate this to prevent leaking internal implementation details
  selectedColumnKeys,
  onSearch,
  onSelectColumns,
  searchText,
}) {
  const styles = useStyles();

  const isControlled = !!controlledState;

  const defaultColumnOrder = useMemo(
    () => allColumns.map((column) => column.key),
    [allColumns],
  );

  const initColumnOrder = isControlled
    ? controlledState.columnOrder ?? defaultColumnOrder
    : defaultColumnOrder;

  // uncontrolled is relative to the controlledState mechanism, this state is very much controlled relative to react-table
  const [uncontrolledColumnOrder, setUncontrolledColumnOrder] = useState(
    initColumnOrder,
  );

  const [manageColumnsMode, setManageColumnsMode] = useState(null);

  const columnOrder = isControlled
    ? controlledState.columnOrder ?? uncontrolledColumnOrder
    : uncontrolledColumnOrder;

  const handleColumnChange = (updatedColumnOrder) => {
    if (isControlled && onSetColumnOrder) {
      onSetColumnOrder(updatedColumnOrder);
    } else {
      setUncontrolledColumnOrder(updatedColumnOrder);
    }
  };

  const options = useMemo(() => resolveOptions(initialOptions, { mode }), [
    initialOptions,
  ]);

  const enableRowActions = Boolean(
    getRowActions || initialRowActions?.length || rowDetails,
  );

  const primaryColumnKey = useMemo(() => getPrimaryColumnKey(allColumns), [
    allColumns,
  ]);

  const columns = useMemo(
    () =>
      prepareColumns({
        allColumns,
        columnOrder,
        isLoading,
        options,
        enableRowActions,
        selectedColumnKeys,
        onSelectColumns,
      }),
    [
      allColumns,
      columnOrder,
      isLoading,
      options,
      enableRowActions,
      selectedColumnKeys,
      onSelectColumns,
    ],
  );

  const rowIds = useMemo(() => data.map((d) => d.id), [data]);

  const controlledStateProps = isControlled
    ? getControlledStateProps({
        rowIds,
        state: controlledState,
        totalCount,
      })
    : {};

  const stateReducer = createStateReducer({
    columnOrder,
    isAllRowsSelected: controlledState?.isAllRowsSelected,
    isControlled,
    isMultiSelectRows: options.isMultiSelectRows,
    name,
    primaryColumnKey,
    rowIds,
    totalCount,
    onUpdate,
  });

  const loadingRows = Array.from({ length: LOADING_ROWS_LENGTH }, (_, i) => ({
    id: i + 1,
  }));
  const tableData = useMemo(() => (isLoading ? loadingRows : data), [
    data,
    isLoading,
  ]);

  const {
    // core table
    headerGroups,
    page: rows,
    prepareRow: defaultPrepareRow,
    rows: allRows,
    // pagination
    gotoPage,
    // row selection,
    toggleAllRowsSelected,
    // state
    state: {
      pageIndex,
      pageSize,
      selectedRowIds: selectedRowIdsMap,
      sortBy,
      globalFilter,
      isAllRowsSelected,
    },
    setGlobalFilter,
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      getRowId,
      ...controlledStateProps,
      stateReducer,
      ...reactTableOptions,
      manualGlobalFilter: onSearch ? true : false,
    },
    useGlobalFilter,
    ...hooks,
  );

  const selectedRowIds = useMemo(
    () => fromSelectedRowIdsMap(selectedRowIdsMap),
    [selectedRowIdsMap],
  );
  /**
   * Controlled pagination sould be priorty.
   * For some reason react-table overwrites our inital state when using the sort reactTableOptions.
   * It seems like in other areas it is not affected because we wrap the table in `LoadingContainer`.
   * TODO: Verify with design how we should be loading table and discuss with FE team on how we can better handle table state.
   */
  const controlledPageIndex = controlledState?.pageIndex || pageIndex + 1;

  const state = {
    allColumns,
    columnOrder,
    data: getCurrentData({ columns, rows: allRows, selectedRowIds }),
    name,
    isAllRowsSelected,
    pageIndex: controlledPageIndex,
    pageSize,
    primaryColumnKey,
    selectedRowIds,
    sortBy,
    totalCount,
  };

  const handleUpdatePageIndex = (updatedPageIndex) => {
    if (onPaginate) {
      onPaginate({ pageIndex: updatedPageIndex });
    } else {
      gotoPage(updatedPageIndex - 1);
    }
  }; // map props for Paginate component (1-based)

  const handleSearch = (searchText) => {
    if (onSearch) {
      onSearch(searchText);
    } else {
      setGlobalFilter(searchText);
    }
  };

  const headerGroup = headerGroups[0];

  /**
   * This is a wrapper around `react-select`'s native prepareRow method.
   * - `react-select@7.0` unfortunately does not provide a way to access custom props.  This has to be attached to the row object. See https://spectrum.chat/react-table/general/v7-row-getrowprops-how-to-pass-custom-props-to-the-row~ff772376-0696-49d6-b259-36ef4e558821.
   * - TODO EKP-10414: refactor props.rowActions to use the Action interface to skip transform logic
   */
  const prepareRow = (row) => {
    const { original: rowData } = row;
    const rowActionsSource = getRowActions
      ? getRowActions(rowData)
      : initialRowActions;

    let hasRowDetails = Boolean(rowDetails);
    if (hasRowDetails) {
      hasRowDetails = rowDetails.condition
        ? rowDetails.condition(rowData)
        : true;
    }

    const rowActions = prependShowDetailsToRowActions(
      rowActionsSource,
      hasRowDetails ? rowDetails.onClick : undefined,
    );

    row.actions = rowActions
      // TODO EKP-16510: deprecate in favor of props.getRowActions
      .filter(({ condition }) => (condition ? condition(rowData) : true))
      .map((rowAction) => ({
        ...rowAction,
        onClick: () => rowAction.onClick(rowData),
      }));
    row.onShowDetails = hasRowDetails ? rowDetails.onClick : undefined;

    row.actionsMenuId = getRowActionsMenuId
      ? getRowActionsMenuId(row.original, row.index)
      : undefined;

    row.disabled = getDisabledRow?.(rowData);

    defaultPrepareRow(row);
  };

  const handleUpdatePageSize = (updatedPageSize) => {
    handleUpdatePageIndex(1);
    if (onPageSizeChange) {
      onPageSizeChange(updatedPageSize);
    }
    setPageSize(updatedPageSize);
  };

  const caption = useMemo(
    () => getTableCaption({ columns: allColumns, name, totalCount }),
    [allColumns, name, totalCount],
  );

  const groupedColumns = useMemo(() => {
    if (!columnGroups) return;

    const columnsMap = keyBy(allColumns, 'key');

    return columnGroups.map((group) => ({
      ...group,
      columns: group.columns
        .filter((id) => !!columnsMap[id])
        .map((id) => columnsMap[id]),
    }));
  }, [allColumns, columnGroups]);

  return (
    <>
      {options.enableSearch && (
        <Box mb={4}>
          <SearchInput
            onChange={handleSearch}
            placeholder="Search"
            value={onSearch ? searchText : globalFilter}
          />
        </Box>
      )}
      {options.enableEmptyState && rows.length === 0 && (
        <Box align="center" justify="center" p={4}>
          <Text variant="body-bold">No matching results</Text>
        </Box>
      )}
      {(!options.enableEmptyState || rows.length > 0) && (
        <Box styles={componentStyles.container}>
          {header && (
            <Layout
              align="center"
              p={3}
              spacing={2}
              styles={componentStyles.header}
            >
              <TruncateText variant="subtitle">{header.title}</TruncateText>
              {header.chips && <Chips chips={header.chips} />}
            </Layout>
          )}
          {options.enableControls && (
            <Controls
              actions={actions}
              bulkActions={bulkActions}
              getBulkActions={getBulkActions}
              disabled={isLoading}
              enableExportXlsx={options.enableExportXlsx}
              enablePagination={options.enablePagination}
              enableManageColumns={options.enableManageColumns}
              isAllRowsSelected={isAllRowsSelected}
              isMultiSelectRows={options.isMultiSelectRows}
              name={name}
              pageSizes={options.pageSizes}
              primaryColumnKey={primaryColumnKey}
              onToggleSelectAllRows={toggleAllRowsSelected}
              onAddRemoveColumns={() => setManageColumnsMode('selection')}
              onLoadView={onLoadView}
              onReorderColumns={() => setManageColumnsMode('order')}
              onSaveView={onSaveView}
              onUpdateColumnOrder={handleColumnChange}
              onUpdatePageIndex={handleUpdatePageIndex}
              onUpdatePageSize={handleUpdatePageSize}
              state={state}
            />
          )}
          <Layout as="table" direction="column" styles={componentStyles.table}>
            <Box as="caption" styles={styles.hidden.visual}>
              {caption}
            </Box>
            {options.enableColumnHeaders && headerGroup && (
              <Head
                headers={headerGroup.headers}
                hasSelectedRows={selectedRowIds.length > 0}
              />
            )}
            <Body
              activeRowId={activeRowId}
              prepareRow={prepareRow}
              rows={rows}
              isBordered={isBordered}
            />
          </Layout>
        </Box>
      )}
      <ManageColumnsPanel
        columns={allColumns}
        columnOrder={columnOrder}
        groupedColumns={groupedColumns}
        mode={manageColumnsMode}
        primaryColumnKey={primaryColumnKey}
        onHide={() => setManageColumnsMode(null)}
        onUpdateColumnOrder={handleColumnChange}
      />
    </>
  );
}

const widthPropType = PT.oneOf(['s', 'm', 'l', 'auto']);

const actionPropType = PT.shape({
  /** Valid icon key for accessing icon svg asset */
  icon: PT.string,
  /** Icon label required for a11y. */
  label: PT.string,
  /** Handler on action click */
  onClick: PT.func.isRequired,
  /** Function that takes data to determine visibility of an action */
  condition: PT.func,
  /** Classname */
  className: PT.string,
  /** Tooltip */
  tooltip: PT.string,
});

const statePropType = PT.shape({
  columnOrder: PT.arrayOf(PT.oneOfType([PT.string, PT.number])),
  isAllRowsSelected: PT.bool,
  pageIndex: PT.number,
  pageSize: PT.number,
  selectedRowIds: PT.arrayOf(PT.string),
  sortBy: PT.arrayOf(
    PT.shape({
      id: PT.string.isRequired,
      asc: PT.bool,
      desc: PT.bool,
    }).isRequired,
  ),
});

const componentStyles = {
  container: {
    border: 'border.divider',
    overflowX: 'auto',
    overflowY: 'hidden',
    position: 'relative',
    width: '100%',
    zIndex: '0',
  },
  header: {
    borderBottom: 'border.divider',
    position: 'sticky',
    left: 0,
  },
  table: {
    backgroundColor: 'background',
    borderCollapse: 'collapse',
    width: 'calc(100% - 2px)', // account for borders
  },
};

export const Table = React.memo(_Table);

Table.propTypes = {
  /** Indicate that the row is active */
  activeRowId: PT.string,
  /** Specify columns to control how data is displayed as cells in the table */
  columns: PT.arrayOf(
    PT.shape({
      /** Every datum should have a unique key/id */
      key: PT.string.isRequired,
      /** Cell horizontal alignment override */
      align: PT.oneOf(['left', 'center', 'right']),
      /** `propTypes` will be deprecated.  Refer to the formal `CellType` TS types */
      cellType: PT.string,
      /** Will disable column resizing explicitly */
      disableResizing: PT.bool,
      /** Will disable column sorting explicitly */
      disableSortBy: PT.bool,
      /** Defines the maximum width a column can assume. */
      maxWidth: widthPropType,
      /** Defines the minimum width a column can assume. */
      minWidth: widthPropType,
      /** Provide a custom render function with signature `render(cellProps) => JSX` to render custom cell contents.  Note that this should be used sparingly and one should preference rendering cells by specifying the relevant `cellType` and `mapCellProps` */
      renderCell: PT.func,
      /** Provide a custom sort function for advanced sorting. */
      sortCompare: PT.func,
      /** Title of the column. */
      title: PT.string.isRequired,
      /** Defines the initially rendered width of a column. */
      width: widthPropType,
      /** A method mapping datum to `cellProps`.  Used with `cellType`. */
      mapCellProps: PT.func,
      /** A method mapping datum to `cellValue`.  This cell value is used in exposing the cell's underlying value */
      mapCellValue: PT.func,
    }),
  ).isRequired,
  /** A simple array of objects is specified as data.  All datum MUST include a unique id property */
  data: PT.arrayOf(
    PT.shape({
      id: PT.oneOfType([PT.string, PT.number]).isRequired,
    }).isRequired,
  ).isRequired,
  /** All tables should have a name */
  name: PT.string.isRequired,
  /** Table actions to capture current state of table with relevant callbacks */
  actions: PT.arrayOf(actionPropType),
  /** Callback prop to capture unique row id base on row data, and row index */
  getRowActionsMenuId: PT.func,
  /** Disables a row using a predicate test */
  getDisabledRow: PT.func,
  /** Optional to configure table title and chips */
  header: PT.shape({
    title: PT.string.isRequired,
    chips: PT.arrayOf(
      PT.shape({
        text: PT.string.isRequired,
      }),
    ),
  }),
  /** Show table body borders */
  isBordered: PT.bool,
  /** Visually indicate that a table is loading */
  isLoading: PT.bool,
  /** Options to configure the table instance */
  options: PT.shape({
    enableExportXlsx: PT.bool,
    enablePagination: PT.bool,
    enableManageColumns: PT.bool,
    enableSearch: PT.bool,
    enableSelectRows: PT.bool,
  }),
  /** Row actions to capture row data with relevant callbacks when hovering on a row */
  rowActions: PT.arrayOf(actionPropType),
  /** Row details */
  rowDetails: PT.shape({
    onClick: PT.func.isRequired,
    /** Function that takes data to determine visibility of row details button. */
    condition: PT.func,
  }),
  /** Manage Table state (i.e. row selection, pagination, column sorting/orering etc) in a controlled manner  */
  state: statePropType,
  /** Specify the total count of the data (usually data.length, maybe a custom value when used with server-paginated data) */
  totalCount: PT.number,
  onLoadView: PT.func,
  onSaveView: PT.func,
  /** Callback handler for PazeSize change. Currently used to update pagination requests. */
  onPageSizeChange: PT.func,
  /** Callback that returns selected column keys */
  onSelectColumns: PT.func,
  /** Callback to capture state changes (i.e. row selection, pagination, column sorting/ordering etc) */
  onUpdate: PT.func,
  /**
   *  ! By default table does handle search. Do not use unless default search does not handle your use case. Default search > controlled search.
   */
  onSearch: PT.func,
  searchText: PT.string,
};
