import React, { memo } from 'react';

import { DatePeriod, DurationUnit, Input, Nullable, Option } from '../../types';
import { Layout } from '../Layout';
import { NumberInput } from '../NumberInput';
import { Select } from '../Select';

const defaultDatePeriod: DatePeriod = { value: null, unit: null };

type Props = Input<DatePeriod>;

export const DatePeriodInput = memo(
  ({ name, value: initialDatePeriod, onChange }: Props) => {
    const datePeriod = initialDatePeriod || defaultDatePeriod;
    const { value, unit } = datePeriod;

    const handleValueChange = (updatedValue: Nullable<number>) => {
      onChange({ value: updatedValue, unit });
    };

    const handleUnitChange = (updatedUnit: Nullable<DurationUnit>) => {
      onChange({ value, unit: updatedUnit });
    };

    return (
      <Layout align="center" minW="input.min-width" spacing={2}>
        <NumberInput
          name={`${name}-value`}
          min={0}
          placeholder="Value"
          value={value}
          width="input.s.width"
          onChange={handleValueChange}
        />
        <Select
          enablePortal={true}
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          name={`${name}-unit`}
          options={options}
          placeholder="Units"
          value={unit}
          width="input.s.width"
          onChange={handleUnitChange}
        />
      </Layout>
    );
  },
);

const options: Option<DurationUnit>[] = [
  { label: 'Day', value: 'days' },
  { label: 'Week', value: 'weeks' },
  { label: 'Month', value: 'months' },
  { label: 'Year', value: 'years' },
];
