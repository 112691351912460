import xlsx from 'xlsx';

// https://github.com/SheetJS/sheetjs/issues/817#issuecomment-331605640
export const exportXlsx = ({ data, name }: { data: any[]; name?: string }) => {
  const ws = xlsx.utils.json_to_sheet(data);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws);
  const filename = `${name ?? 'download'}.xlsx`;
  xlsx.writeFile(wb, filename);
};
