import { getPromptModelFieldIds } from '../../../redux/api/methods';
import { pilot } from '../../../services';
import { fieldTypeEnum } from './DataFields.enum';

export const getContractTypesByClientId = (client_id) => {
  return pilot.get(`client/${client_id}/contract-types/`);
};

export const getClientSectionsData = async (
  clientId,
  options = {
    includeXRayDetails: false,
  },
) => {
  const results = await pilot.get(`client/${clientId}/sections/`);

  if (options.includeXRayDetails) {
    const sylvannusModelFieldIds = await getPromptModelFieldIds();

    const newResult = results.map((section) => {
      const { doc_info_fields } = section;
      return {
        ...section,
        doc_info_fields: doc_info_fields.map((field) => ({
          ...field,
          is_xray: sylvannusModelFieldIds.includes(field.id),
        })),
      };
    });

    return newResult;
  } else {
    return results;
  }
};

export const createClientSectionData = (label, client_id) => {
  const data = { label, client_id };
  return pilot.post('client-sections/', data);
};

export const updateClientSectionName = (label, id) => {
  const data = { label };
  return pilot.put(`client-sections/${id}/`, data);
};

export const deleteClientSectionData = (section_id) => {
  return pilot.remove(`client-sections/${section_id}/`);
};

export const reorderClientSectionsData = (client_id, data) => {
  return pilot.put(`client/${client_id}/section-order/`, data);
};

export const moveFieldToSection = (field_id, section_id) => {
  const data = { section_id };
  return pilot.put(`document-information-fields/${field_id}/`, data);
};

export const createDataField = (data, id) => {
  return pilot.post('document-information-fields/', {
    allow_multiple: data.allow_multiple,
    allow_new_options: data.allow_new_options,
    section_id: id,
    default: data.defaultValue,
    doc_types: data.doc_types ? data.doc_types.map((item) => item.value) : [],
    doc_restriction: data.doc_restriction,
    field_type: fieldTypeEnum[data.field_type.value],
    help_text: data.help_text,
    name: data.name,
    options: data.options.map((option) => option.value),
    show_on_upload_form: data.show_on_upload_form,
    sort_options: data.sort_options,
  });
};

export const updateDataField = (data, sectionId, fieldId) => {
  return pilot.put(`document-information-fields/${fieldId}/`, {
    allow_multiple: data.allow_multiple,
    allow_new_options: data.allow_new_options,
    section_id: sectionId,
    default: data.defaultValue,
    doc_types: data.doc_types ? data.doc_types.map((item) => item.value) : [],
    doc_restriction: data.doc_restriction,
    field_type: data.field_type,
    help_text: data.help_text,
    is_beta: data.is_beta,
    is_smart: data.is_smart,
    is_visible: data.is_visible,
    name: data.name,
    options: data.options.map((item) => item.value),
    show_on_upload_form: data.show_on_upload_form,
    sort_options: data.sort_options,
  });
};

export const updateDataFieldOrder = (data, sectionId) => {
  return pilot.put(`client-sections/${sectionId}/field-order/`, data);
};

export const deleteDataField = (field_id) => {
  return pilot.remove(`document-information-fields/${field_id}/`);
};
