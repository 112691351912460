import { capitalize } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { copy, Markdown, Modal, Text, useToast } from '~/eds';
import { api, slices } from '~/redux';
import { Nullable } from '~/types';

import { Configuration } from '../Configuration';
import { ConfigurationType, Endpoint, EndpointType } from '../types';
import { getDisabledTooltip } from '../utils';

interface Props {
  action: Nullable<EndpointType>;
  endpoint?: Nullable<Endpoint>;
  sampleEvent?: Nullable<string>;
  configuration?: Nullable<ConfigurationType>;
  isLoading?: boolean;
  samplePayload?: any;
}

export const EndpointModal = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();

  const [
    createEndpoint,
    { isLoading: isCreatingEndpoint },
  ] = api.endpoints.createEndpoint.useMutation();

  const [
    deleteEndpoint,
    { isLoading: isDeletingEndpoint },
  ] = api.endpoints.deleteEndpoint.useMutation();

  const [
    suspendEndpoint,
    { isLoading: isSuspendingEndpoint },
  ] = api.endpoints.suspendEndpoint.useMutation();

  const [
    activateEndpoint,
    { isLoading: isActivatingEndpoint },
  ] = api.endpoints.activateEndpoint.useMutation();

  const [
    updateEndpoint,
    { isLoading: isUpdatingEndpoint },
  ] = api.endpoints.updateEndpoint.useMutation();

  const {
    activeEndpoint,
    activeEndpointType: action,
    sampleEvent,
    configuration,
  } = useSelector(slices.webhooks.selectors.selectEndpointConfiguration);

  const {
    data: eventSamplePayload,
    isLoading: isLoadingEventSamplePayload,
  } = api.endpoints.getEventSamplePayload.useQuery(
    {
      sampleEvent,
    },
    {
      skip: !sampleEvent,
    },
  );

  if (action === 'view' || action === null) {
    return null;
  }

  const handleHide = () =>
    dispatch(slices.webhooks.actions.resetEndpointConfiguration());

  const handleActionConfirm = () => {
    const queries = {
      delete: deleteEndpoint,
      suspend: suspendEndpoint,
      edit: updateEndpoint,
      add: createEndpoint,
      activate: activateEndpoint,
    };

    const query = queries[action];

    query({
      configuration,
      endpoint: activeEndpoint!,
    })
      ?.unwrap()
      .then(() => {
        toast({
          message: `${toastMessage?.success}`,
          status: 'success',
        });
      })
      .catch(() =>
        toast({
          message: `${toastMessage?.failure}`,
          status: 'danger',
        }),
      )
      .finally(handleHide);
  };

  const isLoading =
    isDeletingEndpoint ||
    isSuspendingEndpoint ||
    isUpdatingEndpoint ||
    isCreatingEndpoint ||
    isActivatingEndpoint;

  const {
    children,
    isFullPage,
    variant,
    panel,
    toastMessage,
    tooltip,
    disabled,
  } =
    resolveModalProps({
      action,
      endpoint: activeEndpoint,
      sampleEvent,
      configuration,
      isLoading: isLoadingEventSamplePayload,
      samplePayload: eventSamplePayload,
    }) ?? {};

  const modalProps = {
    children:
      children === typeof 'string' ? <Markdown text={children} /> : children,
    isFullPage,
    isVisible: Boolean(action),
    panel,
    primaryAction: {
      disabled,
      isLoading,
      onClick: handleActionConfirm,
      tooltip,
      text: `${capitalize(action ?? '')}`,
      variant,
    },
    onCancel: handleHide,
    onHide: handleHide,
    title: `${capitalize(action ?? '')} Endpoint`,
  };

  return <Modal {...modalProps} />;
};

const resolveModalProps = ({
  action,
  endpoint,
  sampleEvent,
  samplePayload,
  isLoading,
  configuration,
}: Props) => {
  const endpointUrl = endpoint?.endpointUrl ?? '';
  const disabledTooltip = getDisabledTooltip(configuration ?? null);

  switch (action) {
    case 'delete': {
      return {
        children: `Are you sure you want to delete ${endpointUrl}?`,
        variant: 'danger' as const,
        toastMessage: {
          success: `Endpoint \`${endpointUrl}\` deleted.`,
          failure: `There was an error deleting \`${endpointUrl}\`. Please try again.`,
        },
        isFullPage: false,
      };
    }
    case 'suspend': {
      return {
        children: `When you suspend your endpoint, all events will stop being
              triggered, meaning no events will be sent. 
            Are you sure you want to suspend ${endpointUrl}`,
        variant: 'danger' as const,
        toastMessage: {
          success: `Endpoint \`${endpointUrl}\` suspended.`,
          failure: `There was an error suspending  \`${endpointUrl}\`. Please try again.`,
        },
        isFullPage: false,
      };
    }
    case 'activate': {
      return {
        children: `Are you sure you want to re-activate ${endpointUrl}?`,
        variant: 'primary' as const,
        toastMessage: {
          success: `Endpoint \`${endpointUrl}\` active.`,
          failure: `There was an error re-activating \`${endpointUrl}\`. Please try again.`,
        },
        isFullPage: false,
      };
    }
    case 'edit':
    case 'add':
      return {
        children: <Configuration />,
        disabled: Boolean(disabledTooltip),
        tooltip: disabledTooltip,
        panel: {
          actions: [
            {
              disabled: !sampleEvent,
              tooltip: sampleEvent ? 'Copy' : 'Select an event to copy',
              text: 'Copy',
              icon: 'clipboard' as const,
              onClick: () => {
                copy(samplePayload);
              },
            },
          ],
          children: <Text>{samplePayload}</Text>,
          loadingContent: {
            isLoading,
            message: 'Loading event sample payload…',
          },
          placeholderContent: sampleEvent
            ? undefined
            : {
                image: 'empty-no-groups',
                message: 'Preview code when clicking on events types…',
              },
          title: 'Code Snippets',
          width: 'l',
        },
        isFullPage: true,
        status: 'primary' as const,
        toastMessage: {
          success: `Endpoint ${configuration?.url} added.`,
          failure: `There was an error creating the endpoint. Please try again.`,
        },
        variant: 'primary' as const,
      };
    case 'view':
    default:
      return null;
  }
};
