import PropTypes from 'prop-types';
import React from 'react';

import { FlexLayout, Text } from '~/ui';

function FormLabel({ description, input, label, className = '' }) {
  return (
    <FlexLayout
      alignItems="flex-start"
      flexDirection="column"
      space={1}
      className={className}
    >
      <Text color="gray-900" variant="xs-dense-bold-caps">
        {label}
      </Text>
      {description && (
        <Text color="gray-600" variant="xs-dense">
          {description}
        </Text>
      )}
      {input}
    </FlexLayout>
  );
}

FormLabel.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  input: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default FormLabel;
