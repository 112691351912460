import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Section, useToast } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { actions, api, selectors } from '~/redux';

import { FieldModel } from '../fields/types';
import { ReviewTestCases } from '../Review/ReviewTestCases';
import { StringMatchingLevel } from '../types';
import { EvaluateSettings } from './EvaluateSettings';

interface Props {
  model: FieldModel;
  mode?: 'summary-only';
  isConfigDirty?: boolean;
  readOnly?: boolean;
  sectionTitle?: string;
}

export const Evaluate = ({
  mode,
  model,
  isConfigDirty,
  readOnly,
  sectionTitle = '',
}: Props) => {
  const dispatch = useDispatch();

  const { toast } = useToast();

  const enableDocumentXRayExactMatch = useFlag(FlagType.DocumentXRayExactMatch);

  const dismissedIncorrectStatusMessage = useSelector(
    selectors.selectFieldAiDismissedIncorrectStatusMessage,
  );
  const isViewingPastVersion = useSelector(
    selectors.selectFieldAiIsViewingPastVersion,
  );
  const reviewErrorCount = useSelector(
    selectors.selectFieldAiReviewIncorrectCount,
  );
  const isNewVersion = useSelector(selectors.selectFieldAiIsNewVersion);
  const isTextField =
    model.config.field.type === 'STRING' ||
    model.config.field.type === 'TEXT_AREA';

  const [saveDraft] = api.endpoints.savePromptModelDraft.useMutation();

  const enableTextExactMatch = enableDocumentXRayExactMatch && isTextField;
  const isExactTextMatch =
    model.config.stringMatchingLevel === StringMatchingLevel.EXACT;

  const handleToggleIsExactTextMatch = useCallback(
    (updatedEnableTextExactMatch: boolean) => {
      const { id: modelId, config } = model;
      saveDraft({
        config: {
          ...config,
          stringMatchingLevel: updatedEnableTextExactMatch
            ? StringMatchingLevel.EXACT
            : StringMatchingLevel.MATCH_LLM_VALUES,
        },
        modelId,
      })
        .unwrap()
        .catch(() => {
          toast({
            message: 'Failed to apply exact match',
            status: 'danger',
          });
        });
    },
    [model],
  );

  const morePopover = useMemo(() => {
    if (enableTextExactMatch) {
      return {
        content: (
          <EvaluateSettings
            isExactTextMatch={isExactTextMatch}
            onToggleIsExactTextMatch={handleToggleIsExactTextMatch}
          />
        ),
        maxWidth: 420,
      };
    }
    return undefined;
  }, [
    enableTextExactMatch,
    handleToggleIsExactTextMatch,
    isExactTextMatch,
    model,
  ]);

  if (!model || model.testCases.length === 0) {
    return null;
  }

  const getStatusMessage = () => {
    if (!readOnly) {
      if (isViewingPastVersion && !isConfigDirty) {
        return {
          status: 'ai' as const,
          message:
            'Currently displayed values are based on the model instructions above.',
          title: 'Values Updated',
        };
      } else if (isConfigDirty) {
        return {
          status: 'ai' as const,
          message: 'Please click on "Save and Evaluate" to see new results.',
          title: 'Configuration Updated',
        };
      } else if (isNewVersion) {
        return {
          status: 'ai' as const,
          message:
            'Currently displayed values have been updated based on the new model instructions above.',
          title: 'Values Updated',
        };
      } else if (
        !isViewingPastVersion &&
        !dismissedIncorrectStatusMessage &&
        reviewErrorCount >= 2
      ) {
        return {
          status: 'ai' as const,
          message: 'Edit model instructions to improve model performance.',
          title: 'Edit Model Instructions',
          onDismiss: () =>
            dispatch(actions.setFieldAiDismissedIncorrectStatusMessage(true)),
        };
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  return (
    <Section
      description={
        readOnly
          ? undefined
          : 'Review and rate what the model found based on the instructions provided to the model.'
      }
      actions={{
        actions: [],
        morePopover,
      }}
      statusMessage={getStatusMessage()}
      title={sectionTitle}
    >
      <ReviewTestCases
        mode={mode}
        modelVersion={model}
        readOnly={isViewingPastVersion || readOnly}
      />
    </Section>
  );
};
