export default `
<svg
  aria-hidden="true"
  aria-label="tag"
  width="12"
  height="12"
  viewBox="-3 -3 18 18"
  fill="none"
>
  <path
    d="M5.41652 0C5.88276 0 6.32979 0.183792 6.6589 0.512973L11.4858 5.34095C12.1714 6.02675 12.1714 7.13773 11.4858 7.82352L7.82448 11.4857C7.13884 12.1714 6.02811 12.1714 5.34248 11.4857L0.515598 6.65768C0.18375 6.3285 0 5.8841 0 5.41776V1.31672C0 0.589782 0.589647 0 1.31642 0H5.41652ZM1.31642 5.41776C1.31642 5.53298 1.36304 5.64545 1.44532 5.72774L6.2722 10.5557C6.44224 10.7258 6.72197 10.7258 6.89201 10.5557L10.5533 6.89359C10.7233 6.72351 10.7233 6.44371 10.5533 6.27363L5.72643 1.44565C5.64416 1.36336 5.53171 1.31672 5.41652 1.31672H1.31642V5.41776ZM3.07165 2.19454C3.30441 2.19454 3.52763 2.28702 3.69222 2.45164C3.8568 2.61626 3.94926 2.83954 3.94926 3.07235C3.94926 3.30516 3.8568 3.52844 3.69222 3.69306C3.52763 3.85768 3.30441 3.95017 3.07165 3.95017C2.83889 3.95017 2.61567 3.85768 2.45108 3.69306C2.2865 3.52844 2.19404 3.30516 2.19404 3.07235C2.19404 2.83954 2.2865 2.61626 2.45108 2.45164C2.61567 2.28702 2.83889 2.19454 3.07165 2.19454Z"
    fill="currentcolor"
  />
</svg>
`;
