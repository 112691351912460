import { TrainingStage } from '~/enums';

export const SAMPLE_MIN_WORD_COUNT_BULK_TAGGING = 5;

export const LABELING_TRAINING_STAGES = [
  TrainingStage.FirstRoundLabeling,
  TrainingStage.SecondRoundLabeling,
  TrainingStage.ThirdRoundLabeling,
  TrainingStage.FourthRoundLabeling,
];

export const ACCURACY_METRIC_TRAINING_STAGES = [
  TrainingStage.FirstRoundF1Low,
  TrainingStage.FirstRoundF1Mid,
  TrainingStage.FirstRoundF1Ideal,
  TrainingStage.SecondRoundF1GoUp,
  TrainingStage.SecondRoundF1GoDown,
  TrainingStage.SecondRoundF1Ideal,
  TrainingStage.ThirdRoundF1GoUp,
  TrainingStage.ThirdRoundF1GoDown,
  TrainingStage.ThirdRoundF1Ideal,
  TrainingStage.FourthRoundF1GoUp,
  TrainingStage.FourthRoundF1GoDown,
  TrainingStage.FourthRoundF1Ideal,
  TrainingStage.Publishing,
  TrainingStage.Published,
  TrainingStage.Predicting,
  TrainingStage.Predicted,
];

const INVALID_FILE = 'File format is invalid.';
const EMPTY_LABEL =
  'Some samples are missing their labels that indicate whether the sample resembles the Clause.';
const FEWER_THAN_TEN_WORDS =
  'Sample text is missing or contains fewer than 10 words.';
const MORE_THAN_ONE_K_SAMPLES = 'File contains more than 1,000 samples.';

export const SAMPLE_FILE_ERRORS = [
  INVALID_FILE,
  EMPTY_LABEL,
  FEWER_THAN_TEN_WORDS,
  MORE_THAN_ONE_K_SAMPLES,
];

export const DEFAULT_RULES = { positive: [], negative: [] };
export const DEFAULT_PHRASES = { positive: [], negative: [] };

export const HELP_CENTER_LINK =
  'https://support.evisort.com/hc/en-us/articles/4662133918103-Automation-Center-Overview-Administering-and-Managing-Your-Clauses';

export const SIDE_BAR_WIDTH = 200;

export const CSV_TEMPLATE_HEADER = 'Text,Type,Represents Clause';

export const TRAINING_FILTER_TEXT = `You may set up filters to allow the model to focus its learning on specific contracts. That can help achieve the highest accuracy when identifying the Clause in the future.

For example, suppose you have a Clause that is specific to contracts stored in a particular folder. You should set training filters to target that folder so that the model's training is most effective. If you don't limit training, then the model may learn from contracts that aren't applicable to the Clause. The end result would be a model that may misidentify contracts in the future.

Don't fret if you don't have a way to limit your contracts. It's possible that your Clause is generic enough that it does need to be identified across all your contracts. In this case, it makes sense that you don't want to apply filters.`;

export const POSITIVE_LABELS = ['yes', '1', 'true'];
export const NEGATIVE_LABELS = ['no', '0', 'false'];

export const TRAINING_STAGES = {
  [TrainingStage.Empty]: {
    title: '',
    description: '',
    scoreDescription: [],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.FirstRoundInProgress]: {
    title: 'Initial Training in Progress',
    description:
      "The model is now learning from the samples you provided.  When it's done, you'll be able to provide feedback on new samples that the model discovered during this learning session.",
    scoreDescription: [],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.FirstRoundLabeling]: {
    title: 'Samples Ready for your Feedback',
    description:
      'The model has completed initial training and has samples for you to review.  Your feedback will help the model learn more.',
    scoreDescription: [],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.FirstRoundF1Low]: {
    title: "You're off to a great start!",
    description:
      "Model building requires several rounds of training.  Let's see what's in store for the next round.",
    scoreDescription: [
      "Model building requires several rounds of training.  Let's see what's in store for the next round.",
    ],
    image: 'evibot-meh',
    trainingRound: 'Initial Training',
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.FirstRoundF1Mid]: {
    title: "Wow, that's better than we expected!",
    description:
      "Model building requires several rounds of training.  Let's see what's in store for the next round.",
    scoreDescription: [
      "Model building requires several rounds of training.  Let's see what's in store for the next round.",
    ],
    image: 'evibot-happy',
    trainingRound: 'Initial Training',
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.FirstRoundF1Ideal]: {
    title: 'Congratulations, you achieved an ideal accuracy score!',
    description:
      'Model building usually requires several rounds of training.  You managed to train this model in just 1 round. We will use the same training filters to publish your model. Optionally, you can ',
    scoreDescription: [
      'Model building usually requires several rounds of training.  You managed to train this model in just 1 round.',
      'We will use the same training filters to publish your model. Optionally, you can ',
    ],
    image: 'evibot-lit',
    trainingRound: 'Initial Training',
    canAdjustPredictionFilters: true,
  },
  [TrainingStage.SecondRoundInProgress]: {
    title: 'Retraining in Progress',
    description:
      'The model is using your feedback to find new samples for you to review. This is an important step because the model continues to learn from the feedback you provide on the samples.',
    scoreDescription: [],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.SecondRoundLabeling]: {
    title: 'Samples Ready for your Feedback',
    description:
      "Time to review another set of samples. Reminder: Thumbsup if the sample represents the Clause, thumbsdown if the sample doesn't represent the Clause.",
    scoreDescription: [],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.SecondRoundF1GoUp]: {
    title: "You're well on your way!",
    description:
      'You model is learning quite a bit and could use another round of training. You can also choose to publish your model. We will use the same training filters to publish your model. Optionally, you can ',
    scoreDescription: [
      'You model is learning quite a bit and could use another round of training.',
      'You can also choose to publish your model. We will use the same training filters to publish your model. Optionally, you can ',
    ],
    image: 'evibot-happy',
    trainingRound: 'Retrain Round 1 (Max 3 Rounds)',
    canAdjustPredictionFilters: true,
  },
  [TrainingStage.SecondRoundF1GoDown]: {
    title: 'That was a challenging round of training.',
    description:
      "Don't be deterred.  It's normal for the model to run into some samples that are more nuanced.  Let's see what's in store for the next round. You can also choose to publish your model. We will use the same training filters to publish your model. Optionally, you can ",
    scoreDescription: [
      "Don't be deterred. It's normal for the model to run into some samples that are more nuanced.  Let's see what's in store for the next round.",
      'You can also choose to publish your model. We will use the same training filters to publish your model. Optionally, you can ',
    ],
    image: 'evibot-meh',
    trainingRound: 'Retrain Round 1 (Max 3 Rounds)',
    canAdjustPredictionFilters: true,
  },
  [TrainingStage.SecondRoundF1Ideal]: {
    title: 'Congratulations, you achieved an ideal accuracy score!',
    description:
      'Model building usually requires several rounds of training.  You managed to train this model in just 2 rounds. We will use the same training filters to publish your model. Optionally, you can ',
    scoreDescription: [
      'Model building usually requires several rounds of training.  You managed to train this model in just 2 rounds.',
      'We will use the same training filters to publish your model. Optionally, you can ',
    ],
    image: 'evibot-lit',
    trainingRound: 'Retrain Round 1 (Max 3 Rounds)',
    canAdjustPredictionFilters: true,
  },
  [TrainingStage.ThirdRoundInProgress]: {
    title: 'Retraining in Progress',
    description:
      'The model is using your feedback to find new samples for you to review. This is an important step because the model continues to learn from the feedback you provide on the samples.',
    scoreDescription: [],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.ThirdRoundLabeling]: {
    title: 'Samples Ready for your Feedback',
    description:
      "Time to review another set of samples. Reminder: Thumbsup if the sample represents the Clause, thumbsdown if the sample doesn't represent the Clause.",
    scoreDescription: [],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.ThirdRoundF1GoUp]: {
    title: "You're getting close!",
    description:
      'You model is learning quite a bit and could use another round of training. You can also choose to publish your model. We will use the same training filters to publish your model. Optionally, you can ',
    scoreDescription: [
      'You model is learning quite a bit and could use another round of training.',
      'You can also choose to publish your model. We will use the same training filters to publish your model. Optionally, you can ',
    ],
    image: 'evibot-happy',
    trainingRound: 'Retrain Round 2 (Max 3 Rounds)',
    canAdjustPredictionFilters: true,
  },
  [TrainingStage.ThirdRoundF1GoDown]: {
    title: 'That was a challenging round of training.',
    description:
      "Don't be deterred.  It's normal for the model to run into some samples that are more nuanced.  Let's see what's in store for the next round. You can also choose to publish your model. We will use the same training filters to publish your model. Optionally, you can ",
    scoreDescription: [
      "Don't be deterred.  It's normal for the model to run into some samples that are more nuanced.  Let's see what's in store for the next round.",
      'You can also choose to publish your model. We will use the same training filters to publish your model. Optionally, you can ',
    ],
    image: 'evibot-meh',
    trainingRound: 'Retrain Round 2 (Max 3 Rounds)',
    canAdjustPredictionFilters: true,
  },
  [TrainingStage.ThirdRoundF1Ideal]: {
    title: 'Congratulations, you achieved an ideal accuracy score!',
    description:
      'Model building usually requires several rounds of training.  You managed to train this model in just 3 rounds. We will use the same training filters to publish your model. Optionally, you can ',
    scoreDescription: [
      'Model building usually requires several rounds of training.  You managed to train this model in just 2 rounds.',
      'We will use the same training filters to publish your model. Optionally, you can ',
    ],
    image: 'evibot-lit',
    trainingRound: 'Retrain Round 2 (Max 3 Rounds)',
    canAdjustPredictionFilters: true,
  },
  [TrainingStage.FourthRoundInProgress]: {
    title: 'Retraining in Progress',
    description:
      'The model is using your feedback to find new samples for you to review. This is an important step because the model continues to learn from the feedback you provide on the samples.',
    scoreDescription: [],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.FourthRoundLabeling]: {
    title: 'Samples Ready for your Feedback',
    description:
      "Time to review another set of samples. Reminder: Thumbsup if the sample represents the Clause, thumbsdown if the sample doesn't represent the Clause.",
    scoreDescription: [],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.FourthRoundF1GoUp]: {
    title: "It's time to assess what really matters.",
    description:
      "Sometimes reaching a goal is not about the end number.  Instead, it's about whether the current situation is better than where you were before. We will use the same training filters to publish your model. Optionally, you can ",
    scoreDescription: [
      "Sometimes reaching a goal is not about the end number.  Instead, it's about whether the current situation is better than where you were before. You can also choose to publish your model. We will use the same training filters to publish your model. Optionally, you can ",
    ],
    image: 'evibot-happy',
    trainingRound: 'Retrain Round 3 (Max 3 Rounds)',
    canAdjustPredictionFilters: true,
  },
  [TrainingStage.FourthRoundF1GoDown]: {
    title: "It's time to assess what really matters.",
    description:
      "Sometimes reaching a goal is not about the end number.  Instead, it's about whether the current situation is better than where you were before. We will use the same training filters to publish your model. Optionally, you can ",
    scoreDescription: [
      "Sometimes reaching a goal is not about the end number.  Instead, it's about whether the current situation is better than where you were before.",
      'We will use the same training filters to publish your model. Optionally, you can ',
    ],
    image: 'evibot-meh',
    trainingRound: 'Retrain Round 3 (Max 3 Rounds)',
    canAdjustPredictionFilters: true,
  },
  [TrainingStage.FourthRoundF1Ideal]: {
    title: 'Congratulations, you achieved an ideal accuracy score!',
    description:
      'Your model is in the ideal state within the expected rounds of training. We will use the same training filters to publish your model.  Optionally, you can ',
    scoreDescription: [
      'Your model is in the ideal state within the expected rounds of training. We will use the same training filters to publish your model.  Optionally, you can ',
    ],
    image: 'evibot-lit',
    trainingRound: 'Retrain Round 3 (Max 3 Rounds)',
    canAdjustPredictionFilters: true,
  },
  [TrainingStage.Publishing]: {
    title: 'Identifying documents',
    description: 'This will take a few moments!',
    scoreDescription: ['This will take a few moments!'],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.Published]: {
    title: 'Identifying documents',
    description: 'This will take a few moments!',
    scoreDescription: ['This will take a few moments!'],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.Predicting]: {
    title: 'Finishing up!',
    description:
      'We are just a few moments away from finishing up your Guided AI.',
    scoreDescription: [
      'We are just a few moments away from finishing up your Guided AI.',
    ],
    canAdjustPredictionFilters: false,
  },
  [TrainingStage.Predicted]: {
    title: 'Finishing up!',
    description:
      'We are just a few moments away from finishing up your Guided AI.',
    scoreDescription: [
      'We are just a few moments away from finishing up your Guided AI.',
    ],
    canAdjustPredictionFilters: false,
  },
};
