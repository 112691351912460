import { useState } from 'react';

import { LocalStorageKeyType } from '~/enums';

const defaultOptions = {
  /** Skip updating local storage but still updates React state */
  skipUpdateLocalStorage: false,
};

/**
 * This hooks reads/updates both to localStorage and React state (to participate in React's render lifecycle).  Keys must be defined/maintained in LocalStorageKeyType.
 *
 * Error handling is internalized with the following behaviors (can be exposed in the future after usage patterns mature):
 * - return initial value if localStorage is not available or errors
 * - swallow error if failing to write to localStorage
 **/
export const useLocalStorageState = <V>(
  key: LocalStorageKeyType,
  initialValue: V,
  options = defaultOptions,
): [V, (value: V) => void] => {
  const [storedValue, setStoredValue] = useState<V>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: V) => {
    try {
      if (!options.skipUpdateLocalStorage) {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
      setStoredValue(value);
    } catch (error) {}
  };

  return [storedValue, setValue];
};
