import { api } from '../createApi';
import { TagType } from '../TagType';

export const ACL_TAG = { type: TagType.Acl, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.Acl as TagType],
  endpoints: {
    getEntityPermissions: {
      providesTags: [ACL_TAG],
    },
  },
});
