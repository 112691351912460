import { noop } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  intakeFormSet,
  intakeFormValidate,
  ticketDocumentVersionsSet,
  ticketUpdate,
} from '~/actions';
import { getTicketDocumentVersions, updateTicket } from '~/api';
import { useAppConfigContext } from '~/contexts';
import {
  Box,
  ContentContainer,
  Footer,
  Layout,
  Panel_DEPRECATED,
  StatusMessage,
  Stepper,
  types,
  useToast,
} from '~/eds';
import { TicketStageType, TicketStatusType } from '~/enums';
import { api, selectors } from '~/redux';
import { isFormValid } from '~/utils/intakeForm';
import { prepareIntakeFormSubmitData } from '~/utils/workflow';

import { INTAKE_FORM_CONTAINER_ID, INTAKE_FORM_SECTION_ID } from './constants';
import { WorkflowForm } from './WorkflowForm/WorkflowForm';

interface Props {
  ticketId: types.Uuid;
  title: string;
  actions?: types.Action[];
  width?: 'm' | 'l' | 'fill';
}
export const TicketIntakeForm = ({
  actions = [],
  ticketId,
  title,
  width = 'm',
}: Props) => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const { isPanelEnlarged } = useAppConfigContext();
  const {
    data,
    isLoading,
    error,
    refetch: refetchIntakeForm,
  } = api.endpoints.getTicketIntakeForm.useQuery(ticketId);
  const intakeForm = useSelector(selectors.selectIntakeForm);
  const ticket = useSelector(selectors.selectTicket);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const shouldDisableEditIntakeForm =
    ticket.status === TicketStatusType.Completed ||
    ticket.stage === TicketStageType.Finalize ||
    ticket.isCancelled ||
    isLoading;

  useEffect(() => {
    if (data) {
      dispatch(intakeFormSet({ res: { ...data } }));
    }
  }, [data]);

  const onFormSave = async () => {
    const { fields } = intakeForm;

    dispatch(intakeFormValidate());

    if (isFormValid(intakeForm)) {
      setIsSubmittingForm(true);
      const payload = prepareIntakeFormSubmitData({ fields });
      try {
        // TODO: Fix any type for correct payload type
        const res = await (updateTicket(ticketId, payload) as any);

        // update versions in case it was a template submission
        if (!res.wasManuallyUpdated) {
          const upToDateVersions = await getTicketDocumentVersions(
            res.document.id,
          );
          dispatch(ticketDocumentVersionsSet(upToDateVersions));
        }
        // May not need esigErrorMessage
        const esigErrorMessage = res?.esigErrorMessage;
        refetchIntakeForm();
        dispatch(
          ticketUpdate({
            document: res.document,
            stages: res.stages,
            modifiedDate: res.modifiedDate,
            name: res.name,
          }),
        );
        esigErrorMessage
          ? toast({
              message: esigErrorMessage,
              status: 'info',
            })
          : toast({
              message: 'Ticket has been successfully updated',
              status: 'success',
            });
      } catch {
        toast({
          message: 'An error occurred while updating a ticket.',
          status: 'danger',
        });
      } finally {
        setIsSubmittingForm(false);
        setIsEditing(false);
      }
    } else {
      toast({
        message: 'Please fill in all fields correctly.',
        status: 'warning',
      });
    }
  };

  const onCancel = () => {
    dispatch(intakeFormSet({ res: { ...data } }));
    setIsEditing(false);
  };

  const intakeFormActions = [
    ...(!isEditing
      ? [
          {
            disabled: shouldDisableEditIntakeForm,
            icon: 'edit' as types.IconType,
            label: 'Edit Form',
            value: 'Edit Form',
            onClick: () => setIsEditing(true),
          },
        ]
      : []),
    ...actions,
  ];

  const footer = (
    <Footer
      actions={[
        {
          disabled: isSubmittingForm,
          text: 'Cancel',
          level: 'tertiary',
          onClick: onCancel,
        },
        {
          isLoading: isSubmittingForm,
          text: 'Save Changes',
          mode: 'submit',
          level: 'primary',
          onClick: onFormSave,
        },
      ]}
    />
  );

  const formStatusMessage = useMemo(() => {
    if (shouldDisableEditIntakeForm) {
      return (
        <StatusMessage
          message="The contract has been signed or is completed. No changes can be made to the intake form."
          status="warning"
          title="Changes cannot be made to the intake form"
        />
      );
    } else if (ticket.wasManuallyUpdated) {
      return (
        <StatusMessage
          message="Uploading breaks the link between the document and the fields below. If changes are required, you will have to edit the document directly and upload a new version."
          status="warning"
          title="Changes made will not be reflected in the document."
        />
      );
    } else if (ticket.hasEnvelope) {
      return (
        <StatusMessage
          status="warning"
          message="Any changes made to the intake form will not be applied to the document"
          onDismiss={() => {}}
        />
      );
    } else {
      return <></>;
    }
  }, [ticket, shouldDisableEditIntakeForm]);

  const form = (
    <Layout
      borderLeft={isPanelEnlarged ? 'border' : undefined}
      direction="column"
      h="100%"
      id={INTAKE_FORM_CONTAINER_ID}
      overflowY="auto"
      spacing={2}
      w="100%"
    >
      <Box py={2} px={4}>
        {formStatusMessage}
      </Box>
      <WorkflowForm isEditing={isEditing && !isSubmittingForm} />
    </Layout>
  );

  const sections = intakeForm.sections
    ? Object.values(intakeForm.sections).map((section) => ({
        id: section.name,
        title: section.name,
        description: section.description,
        pathname: '',
      }))
    : [];

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      actions={intakeFormActions}
      footer={isEditing ? footer : undefined}
      isEmbedded
      isVisible
      title={title}
      width={width}
      onHide={noop}
    >
      <ContentContainer
        loadingContent={{ isLoading }}
        placeholderContent={
          error
            ? {
                icon: 'checklist',
                message: 'An error occurred while fetching an intake form.',
              }
            : undefined
        }
      >
        {isPanelEnlarged ? (
          <Layout h="100%">
            <Box px={6} py={4}>
              <Stepper
                containerId={INTAKE_FORM_CONTAINER_ID}
                currentSectionId={sections[0]?.id}
                name="intake form steps"
                sections={sections}
                sectionClassName={INTAKE_FORM_SECTION_ID}
              />
            </Box>
            {form}
          </Layout>
        ) : (
          form
        )}
      </ContentContainer>
    </Panel_DEPRECATED>
  );
};
