import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';
import {
  getInitialDateValue,
  initialFilters,
} from '~/features/webhooks/constants';

import { WebhooksState as State } from './types';

export const initialState: State = {
  activeEndpoint: null,
  activeEndpointType: null,
  activeAttempt: null,
  activeAttemptType: null,
  sampleEvent: null,
  selectedTab: 'endpoints',
  configuration: {
    url: '',
    description: '',
    events: {},
  },
  filters: initialFilters,
  totalEndpoints: 0,
};

const setConfigurationReducer = (
  state: State,
  action: PayloadAction<State['configuration']>,
) => {
  state.configuration = action.payload;
};

export default createSlice({
  name: SliceType.Webhooks,
  initialState,
  selectors: {
    selectActiveEndpoint: ({ activeEndpoint, activeEndpointType }: State) => ({
      activeEndpoint,
      activeEndpointType,
    }),
    selectSampleEvent: (state: State) => state.sampleEvent,
    selectConfiguration: (state: State) => state.configuration,
    selectEndpointConfiguration: ({
      activeEndpoint,
      activeEndpointType,
      sampleEvent,
      selectedTab,
      configuration,
    }: State) => {
      return {
        activeEndpoint,
        activeEndpointType,
        sampleEvent,
        selectedTab,
        configuration,
      };
    },
    selectTab: (state: State) => state.selectedTab,
    selectActiveAttempt: ({ activeAttempt, activeAttemptType }: State) => ({
      activeAttempt,
      activeAttemptType,
    }),
    selectedFilters: (state: State) => state.filters,
    selectEndpointsCount: (state: State) => state.totalEndpoints,
  },
  reducers: {
    resetEndpointConfiguration: (state: State) => {
      state.activeEndpoint = null;
      state.activeEndpointType = null;
      state.configuration = initialState.configuration;
      state.sampleEvent = null;
    },
    resetAttemptConfiguration: (state: State) => {
      state.activeAttempt = null;
      state.activeAttemptType = null;
    },
    setActiveEndpoint: (
      state: State,
      action: PayloadAction<State['activeEndpoint']>,
    ) => {
      state.activeEndpoint = action.payload;
    },

    setActiveAttempt: (
      state: State,
      action: PayloadAction<State['activeAttempt']>,
    ) => {
      state.activeAttempt = action.payload;
    },
    setActiveEndpointType: (
      state: State,
      action: PayloadAction<State['activeEndpointType']>,
    ) => {
      state.activeEndpointType = action.payload;
    },

    setActiveAttemptType: (
      state: State,
      action: PayloadAction<State['activeAttemptType']>,
    ) => {
      state.activeAttemptType = action.payload;
    },

    setTab: (state: State, action: PayloadAction<State['selectedTab']>) => {
      state.selectedTab = action.payload;
    },

    setSampleEvent: (
      state: State,
      action: PayloadAction<State['sampleEvent']>,
    ) => {
      state.sampleEvent = action.payload;
    },

    setUrl: (
      state: State,
      action: PayloadAction<State['configuration']['url']>,
    ) => {
      state.configuration.url = action.payload;
    },
    setDescription: (
      state: State,
      action: PayloadAction<State['configuration']['description']>,
    ) => {
      state.configuration.description = action.payload;
    },
    setEvents: (
      state: State,
      action: PayloadAction<State['configuration']['events']>,
    ) => {
      state.configuration.events = action.payload;
    },

    setConfiguration: setConfigurationReducer,
    setFilters: (state: State, action: PayloadAction<State['filters']>) => {
      const allValuesCleared = action.payload.every(
        (filter) => filter.values.length === 0,
      );

      if (allValuesCleared) {
        const updatedFilters = action.payload.map((filter) => {
          if (filter.id === 'datetime') {
            return {
              ...filter,
              operatorId: 'date_in_the_last' as const,
              fieldType: 'date' as const,
              values: getInitialDateValue(),
            };
          }
          return filter;
        });

        state.filters = updatedFilters;
      } else {
        state.filters = action.payload;
      }
    },
    setEndpointsCount: (state: State, action: PayloadAction<number>) => {
      state.totalEndpoints = action.payload;
    },
  },
});
