import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout } from '~/ui';

export const sizes = {
  s: {
    knobRadius: '7px',
    p: '3px',
    width: '40px',
  },
  m: {
    knobRadius: '10px',
    p: 1,
    width: '48px',
  },
};

export const styles = {
  default: {
    onBg: 'green-500',
    offBg: 'gray-400',
  },
  danger: {
    onBg: 'red-600',
    offBg: 'gray-400',
  },
};

function Switch({
  disabled,
  id,
  size = 'm',
  value = false,
  variant = 'default',
  onChange = () => {},
}) {
  const { knobRadius, p, width } = sizes[size];
  const variantStyle = styles[variant] || styles.default;

  return (
    <FlexLayout
      role="switch"
      alignItems="center"
      disabled={disabled}
      justifyContent={value ? 'flex-end' : 'flex-start'}
      id={id}
      p={p}
      sx={{
        bg: `${value ? variantStyle.onBg : variantStyle.offBg}`,
        borderRadius: 'pill',
        width,
      }}
      onClick={() => onChange(!value)}
    >
      <Box
        className="ui/switch__knob"
        p={knobRadius}
        sx={{
          bg: 'white',
          borderRadius: 'round',
        }}
      />
    </FlexLayout>
  );
}

Switch.propTypes = {
  /** Disable the switch */
  disabled: PT.bool,
  /** Size of switch */
  size: PT.oneOf(['s', 'm']),
  /** Indicates the state of the switch. */
  value: PT.bool,
  /** Callback that returns new state of the switch.  */
  onChange: PT.func,
};

export default Switch;
