export default `
<svg
  aria-hidden="true"
  aria-label="field"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M6.5 14.25C6.5 14.6875 6.15625 15 5.75 15C5.3125 15 5 14.6875 5 14.25V9.75C5 9.34375 5.3125 9 5.75 9C6.15625 9 6.5 9.34375 6.5 9.75V14.25ZM2 8C2 6.90625 2.875 6 4 6H20C21.0938 6 22 6.90625 22 8V16C22 17.125 21.0938 18 20 18H4C2.875 18 2 17.125 2 16V8ZM3.5 8V16C3.5 16.2812 3.71875 16.5 4 16.5H20C20.25 16.5 20.5 16.2812 20.5 16V8C20.5 7.75 20.25 7.5 20 7.5H4C3.71875 7.5 3.5 7.75 3.5 8Z"
    fill="currentcolor"
  />
</svg>
`;
