import {
  black3,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  emptyBreadcrumbs: {
    width: '279px',
    height: '14px',
    marginBottom: '4px',
    backgroundColor: '#f0f0f0',
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    height: '25px',
    marginBottom: '4px',
    letterSpacing: '1px',
    '& svg': {
      margin: '0 4px',
    },
  },
  breadcrumbLink: {
    color: black3,
    fontSize: '13px',
    fontWeight: semibold,
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      color: evisortBlue,
    },
  },
};

export default styles;
