import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '~/eds';
import { Box, FlexLayout, Text } from '~/ui';
import { copyToClipboard } from '~/utils/helper.utils';

const TicketInboundEmail = ({ ticketEmail }) => {
  return (
    <Box mt={4}>
      <Text color="gray-900" variant="s-dense">
        Send a file directly to this ticket to have it automatically added as a
        new version.
      </Text>
      <FlexLayout
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={1}
        space={4}
        bg="gray-100"
        sx={{
          border: 'border',
          borderColor: 'gray-500',
          borderRadius: 'm',
          height: 'input-height',
        }}
        mt={2}
      >
        <Text
          color="gray-700"
          variant="xs-dense"
          shouldTruncate={true}
          toolTipPlacement="bottom"
        >
          {ticketEmail}
        </Text>
        <Button
          text="Copy link"
          variant="action"
          onClick={() =>
            copyToClipboard(ticketEmail, 'Ticket email copied to clipboard.')
          }
        />
      </FlexLayout>
    </Box>
  );
};

TicketInboundEmail.propTypes = {
  ticketEmail: PropTypes.string.isRequired,
};

export default TicketInboundEmail;
