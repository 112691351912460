import { assign, cloneDeep, isEqual } from 'lodash';
import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import PageTitle from '~/components/Shared/PageTitle';
import {
  Box,
  Card,
  CollapsibleText,
  ContentContainer,
  EmptyPage,
  formatDate,
  Layout,
  scrollElementIntoView,
  Text,
  Tree,
  types,
  useDeleteModal,
} from '~/eds';
import { ClauseLibraryViewType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { actions, api } from '~/redux';
import {
  ClauseData as ClauseDataType,
  clauseLibraryGetClause,
  VariationData as VariationDataType,
} from '~/redux/api/methods';
import { initialState } from '~/redux/slices/clauseLibrary';
import { useRouting } from '~/routing';
import { ClauseLibraryFilters, Nullable, User, Uuid } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { HtmlContent } from '~/ui';
import { sortStrings } from '~/utils';
import { htmlToText } from '~/utils/strings';
import { testIsAdmin, testIsSuperAdmin } from '~/utils/user';

import ClauseFilter from './ClauseFilter';
import ClauseLibraryManagement from './ClauseManagement';
import ClauseVariation from './ClauseVariation';
import { CLAUSE_LIBRARY_CHAR_DISPLAY_LIMIT } from './constants';
import {
  ClauseForm,
  State as ClauseLibraryState,
  VariationForm,
} from './types';
import { sortVariationsByPositionAndName } from './utils';

interface ClauseLibraryProps {
  hasEditPermission: boolean;
  clauseSummaries: types.TreeNode[];
  clauseForm: ClauseForm;
  isLoadingSummaries: boolean;
  selectedClause: types.TreeNode | undefined;
  setClauseLibrarySelectedClause: Dispatch<types.TreeNode | undefined>;
  setClauseLibraryClauseForm: Dispatch<ClauseForm>;
  setClauseLibraryClauseSummaries: Dispatch<types.TreeNode[]>;
  setClauseLibraryIsLoadingSummaries: Dispatch<boolean>;
  setClauseLibraryVariationForm: Dispatch<VariationForm>;
  setClauseLibraryEditMode: Dispatch<boolean>;
  setClauseLibraryView: Dispatch<ClauseLibraryViewType>;
  cleanupClauseSummaries: Dispatch<void>;
  currentUser: User;
  filters: Nullable<ClauseLibraryFilters>;
}

const ClauseLibrary = ({
  hasEditPermission,
  clauseSummaries,
  clauseForm,
  isLoadingSummaries,
  selectedClause,
  setClauseLibrarySelectedClause,
  setClauseLibraryClauseForm,
  setClauseLibraryClauseSummaries,
  setClauseLibraryIsLoadingSummaries,
  setClauseLibraryVariationForm,
  setClauseLibraryEditMode,
  setClauseLibraryView,
  cleanupClauseSummaries,
  currentUser,
  filters,
}: ClauseLibraryProps) => {
  const { location, params } = useRouting();
  const { hash } = location;
  const { navigate } = useRouting();
  const { clauseId } = params as { clauseId: string };

  const [idToBeDeleted, setIdToBeDeleted] = useState<Uuid>();
  const [nameToBeDeleted, setNameToBeDeleted] = useState<string>();
  const [idForURL, setIdForURL] = useState<Uuid>();

  const useRBAC = useFlag(FlagType.ClauseLibraryRBAC);
  const canEdit = useRBAC
    ? testIsSuperAdmin(currentUser) || hasEditPermission
    : testIsAdmin(currentUser);

  const {
    data: clauseSummariesData,
    error: clauseSummariesError,
    isLoading: clauseSummariesLoading,
  } = api.endpoints.clauseLibraryListClauses.useQuery({ filters });

  const {
    data: clauseData,
    error: clauseDataError,
    isLoading: clauseDataLoading,
  } = api.endpoints.clauseLibraryGetClause.useQuery(
    { clauseId, filters },
    { skip: !clauseId },
  );

  const [
    deleteClause,
    deleteClauseResult,
  ] = api.endpoints.clauseLibraryDeleteClause.useMutation();
  const {
    isError: isDeleteClauseError,
    isSuccess: isDeleteClauseSuccess,
  } = deleteClauseResult;

  const [
    deleteVariation,
    deleteVariationResult,
  ] = api.endpoints.clauseLibraryDeleteVariation.useMutation();
  const {
    isError: isDeleteVariationError,
    isSuccess: isDeleteVariationSuccess,
  } = deleteVariationResult;

  const postDeleteClause = () => {
    navigate('/clause-library');
  };

  const postDeleteVariation = () => {
    navigate(`/clause-library/${idForURL}`);
  };

  const updateClauseSummaries = (
    apiData: types.TreeNode[],
    initialData: types.TreeNode[],
  ): types.TreeNode[] => {
    const result: types.TreeNode[] = [];
    [...apiData].forEach((clauseSummaryData) => {
      const matchingClause = [...initialData].find(
        (clause) => clauseSummaryData.key === clause.key,
      );
      if (!!matchingClause) {
        const clauseResult = assign(
          {},
          { ...matchingClause },
          { ...clauseSummaryData },
        );
        if (clauseSummaryData.children?.length) {
          const children: types.TreeNode[] = [];
          clauseSummaryData.children.forEach((variationData) => {
            const matchingVariation = matchingClause.children?.find(
              (variation) => variationData.key === variation.key,
            );
            if (!!matchingVariation) {
              const variationResult = assign(
                {},
                { ...matchingVariation },
                { ...variationData },
              );
              children.push(variationResult);
            } else {
              children.push(variationData);
            }
          });
          children.sort(sortVariationsByPositionAndName);
          clauseResult.children = children;
        }
        result.push(clauseResult);
      } else {
        result.push(clauseSummaryData);
      }
    });
    return result?.sort((a: types.TreeNode, b: types.TreeNode) =>
      sortStrings(a.name, b.name),
    );
  };

  useEffect(() => {
    if (!!clauseId) {
      const selected: types.TreeNode | undefined = clauseSummariesData?.find(
        (clause) => clause.key === clauseId,
      );
      setClauseLibrarySelectedClause(selected);
      if (!selected) {
        navigate('/clause-library');
      } else if (hash) {
        scrollElementIntoView(hash.slice(1));
      }
    }

    if (!!clauseSummariesData) {
      const summaries = getClauseSummaries(clauseSummariesData);
      const result = updateClauseSummaries(summaries, clauseSummaries);
      setClauseLibraryClauseSummaries(result);
      setClauseLibraryIsLoadingSummaries(false);
    }

    return () => {
      cleanupClauseSummaries();
    };
  }, [clauseSummariesData, navigate]);

  useEffect(() => {
    if (clauseSummariesError) {
      showToast(ERROR, 'Failed to load clauses');
    }
  }, [clauseSummariesError]);

  useEffect(() => {
    if (clauseDataError && !filters?.query && !filters?.chips) {
      showToast(ERROR, `Failed to load clause id ${clauseId}`);
    }
  }, [clauseDataError]);

  useEffect(() => {
    if (isDeleteClauseSuccess) {
      hideDeleteClauseModal();
      showToast(SUCCESS, `${nameToBeDeleted} successfully deleted.`);
      postDeleteClause();
    }
    if (isDeleteClauseError) {
      showToast(ERROR, `${nameToBeDeleted} failed to delete.`);
    }
  }, [isDeleteClauseError, isDeleteClauseSuccess]);

  useEffect(() => {
    if (isDeleteVariationSuccess) {
      hideDeleteVariationModal();
      showToast(SUCCESS, `${nameToBeDeleted} successfully deleted.`);
      postDeleteVariation();
    }
    if (isDeleteVariationError) {
      showToast(ERROR, `${nameToBeDeleted} failed to delete.`);
    }
  }, [isDeleteVariationError, isDeleteVariationSuccess]);

  const handleCreateClause = () => {
    setClauseLibraryClauseForm({ ...clauseForm, name: '' });
    setClauseLibraryView(ClauseLibraryViewType.ClauseForm);
  };

  const handleEditClause = async (clauseId: Uuid) => {
    const { name, guidance: guidanceNotes } = await clauseLibraryGetClause({
      clauseId,
    });
    setClauseLibraryClauseForm({ title: name, name, guidanceNotes, clauseId });
    setClauseLibraryEditMode(true);
    setClauseLibraryView(ClauseLibraryViewType.ClauseForm);
  };

  const handleDeleteClause = (clauseId: Uuid, clauseName: string) => {
    setIdToBeDeleted(clauseId);
    setNameToBeDeleted(clauseName);
    showDeleteClauseModal();
  };

  const handleCreateClauseVariation = (clauseId: Uuid, clauseName: string) => {
    const namePlaceholder = clauseName + '_' + formatDate(new Date(), 'utc');
    setClauseLibraryClauseForm({
      ...initialState.clauseForm,
      name: clauseName,
    });
    setClauseLibraryVariationForm({
      ...initialState.variationForm,
      clauseId,
      namePlaceholder,
    });
    setClauseLibraryView(ClauseLibraryViewType.VariationForm);
  };

  const handleEditVariation = async (clauseId: Uuid, variationId: Uuid) => {
    const clause = await clauseLibraryGetClause({ clauseId });
    const variation = clause?.variations?.find(
      (variation) => variation.key === variationId,
    );
    const namePlaceholder = clause.name + '_' + formatDate(new Date(), 'utc');
    setClauseLibraryVariationForm({
      clauseId,
      variationId: variation?.key,
      name: variation?.name ?? '',
      title: variation?.name,
      position: variation?.position,
      guidanceNotes: variation?.guidance ?? '',
      clauseText: variation?.text ?? '',
      namePlaceholder,
    });
    setClauseLibraryEditMode(true);
    setClauseLibraryView(ClauseLibraryViewType.VariationForm);
  };

  const handleDeleteVariation = (
    variationId: Uuid,
    variationName: string,
    clauseId: Uuid,
  ) => {
    setIdToBeDeleted(variationId);
    setNameToBeDeleted(variationName);
    setIdForURL(clauseId);
    showDeleteVariationModal();
  };

  const handleTreeUpdate = (updatedData: types.TreeNode[]) => {
    if (!isEqual(updatedData, clauseSummaries)) {
      setClauseLibraryClauseSummaries(updatedData);
    }
  };

  const [
    deleteClauseModal,
    showDeleteClauseModal,
    hideDeleteClauseModal,
  ] = useDeleteModal({
    children: (
      <Layout direction="column" spacing={4}>
        <Text>
          Deleting <b>{nameToBeDeleted}</b> will delete all its variations. This
          action is not reversible.
        </Text>
      </Layout>
    ),
    title: `Delete ${nameToBeDeleted}`,
    primaryActionText: 'Delete Clause',
    onDelete: () => deleteClause({ clauseId: idToBeDeleted ?? '' }),
  });

  const [
    deleteVariationModal,
    showDeleteVariationModal,
    hideDeleteVariationModal,
  ] = useDeleteModal({
    children: (
      <Layout direction="column" spacing={4}>
        <Text>Deleting a clause variation is not reversible</Text>
      </Layout>
    ),
    title: 'Delete clause variation?',
    primaryActionText: 'Delete Clause Variation',
    onDelete: () => deleteVariation({ variationId: idToBeDeleted as Uuid }),
  });

  // Mapping can't occur in helper function, clause actions use redux unavailable outside components
  const getClauseSummaries = (
    summaryData: types.TreeNode[],
  ): types.TreeNode[] =>
    summaryData?.map(
      (clause): types.TreeNode => {
        // clause is not extensible, necessitating deep copy
        const clauseSummary = cloneDeep(clause);
        clauseSummary.actions = canEdit
          ? [
              {
                mode: 'icon',
                text: 'Add Clause Variation',
                icon: 'plus',
                onClick: () =>
                  handleCreateClauseVariation(clause.key, clause.name),
              },
            ]
          : [];
        clauseSummary.moreActions = canEdit
          ? [
              {
                label: 'Edit',
                value: clause.key,
                onClick: () => handleEditClause(clause.key),
              },
              {
                label: 'Delete',
                value: clause.key,
                onClick: () => handleDeleteClause(clause.key, clause.name),
              },
            ]
          : [];
        clauseSummary.children?.forEach((variation) => {
          variation.moreActions = canEdit
            ? [
                {
                  label: 'Edit',
                  value: variation.key,
                  onClick: () => handleEditVariation(clause.key, variation.key),
                },
                {
                  label: 'Delete',
                  value: variation.key,
                  onClick: () =>
                    handleDeleteVariation(
                      variation.key,
                      variation.name,
                      clause.key,
                    ),
                },
              ]
            : [];
        });
        clauseSummary.children?.sort(sortVariationsByPositionAndName);
        return clauseSummary;
      },
    );

  const summaryHeaderActions: types.UserAction[] = canEdit
    ? [
        {
          mode: 'icon',
          text: 'Add Clause',
          tooltip: 'Add Clause',
          icon: 'plus',
          onClick: handleCreateClause,
        },
      ]
    : [];

  const clauseHeaderActions: types.UserAction[] = [
    {
      icon: 'plus',
      mode: 'icon',
      level: 'tertiary',
      text: 'Add clause variation',
      tooltip: 'Add Clause Variation',
      onClick: () =>
        handleCreateClauseVariation(
          selectedClause?.key ?? '',
          selectedClause?.name ?? '',
        ),
    },
  ];

  const clauseHeaderMoreActions = [
    {
      text: 'Edit',
      onClick: () => handleEditClause(selectedClause?.key ?? ''),
    },
    {
      text: 'Delete',
      onClick: () =>
        handleDeleteClause(
          selectedClause?.key ?? '',
          selectedClause?.name ?? '',
        ),
    },
  ];

  const clauseVariations = (clause: ClauseDataType) => {
    if (clause?.variations?.length) {
      // clauseData is not extensible, necessitating deep copy for sorting
      const clauseCopy: ClauseDataType = cloneDeep(clause);
      clauseCopy?.variations?.sort(sortVariationsByPositionAndName);
      return clauseCopy?.variations?.map((variation: VariationDataType) => (
        <ClauseVariation
          canEdit={canEdit}
          key={variation.key}
          variation={variation}
          clauseId={clauseCopy.key}
          handleDelete={handleDeleteVariation}
        />
      ));
    }
    return (
      <EmptyPage
        preset={
          canEdit ? 'no-clause-variations-admin' : 'no-clause-variations-user'
        }
      />
    );
  };

  return (
    <Layout direction="column" spacing={8}>
      <PageTitle title="Clause Library" />
      <ClauseFilter />
      <ClauseLibraryManagement />
      <Layout
        direction="row"
        flex="auto"
        mx={-8}
        mb={-6}
        borderTop="border.divider"
        h="calc(100vh - 234px)"
      >
        <Box
          flex="none"
          w="25%"
          minW="sidebar.width.s"
          maxW="sidebar.width.m"
          borderRight="border.divider"
        >
          {!clauseSummariesLoading && !isLoadingSummaries && (
            <Tree
              name="Clauses"
              tree={clauseSummaries}
              title="Clauses"
              headerActions={summaryHeaderActions}
              onUpdate={handleTreeUpdate}
              selectedNodeKey={selectedClause?.key}
              emptyContent={
                <EmptyPage
                  preset={
                    !!filters?.query || !!filters?.chips
                      ? 'no-matches'
                      : canEdit
                      ? 'no-clauses-admin'
                      : 'no-clauses-user'
                  }
                />
              }
            />
          )}
        </Box>
        {!clauseSummariesLoading && !clauseId && !!clauseSummaries?.length ? (
          <EmptyPage preset="no-clause-selected" />
        ) : (
          <Layout
            aria-label="Clause Content"
            position="relative"
            overflowY="auto"
            minH="100%"
            w="100%"
            minW="0"
            flex="1"
          >
            <ContentContainer
              loadingContent={{
                isLoading: clauseSummariesLoading || clauseDataLoading,
              }}
            >
              {!!clauseData && !clauseDataError ? (
                <Layout direction="column" w="100%">
                  <Card
                    header={
                      !!selectedClause?.name
                        ? {
                            title: selectedClause.name,
                            actions: canEdit ? clauseHeaderActions : undefined,
                            moreActions: canEdit
                              ? clauseHeaderMoreActions
                              : undefined,
                          }
                        : undefined
                    }
                    mode="embedded"
                    name="List Clauses"
                    role="list"
                  >
                    {!!clauseData.guidance && (
                      <Box styles={componentStyles.wrap} pt={3} px={6}>
                        <CollapsibleText
                          text={htmlToText(clauseData.guidance)}
                          fullContent={
                            <Text>
                              <HtmlContent html={clauseData.guidance} />
                            </Text>
                          }
                          limit={CLAUSE_LIBRARY_CHAR_DISPLAY_LIMIT}
                          mode="icon"
                        />
                      </Box>
                    )}
                    {clauseVariations(clauseData)}
                  </Card>
                </Layout>
              ) : (
                <EmptyPage
                  preset={
                    !!filters?.query || !!filters?.chips
                      ? 'no-matches'
                      : canEdit
                      ? 'no-clauses-admin'
                      : 'no-clauses-user'
                  }
                />
              )}
            </ContentContainer>
          </Layout>
        )}
      </Layout>
      {deleteClauseModal}
      {deleteVariationModal}
    </Layout>
  );
};

const componentStyles = {
  wrap: {
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
};

const mapStateToProps = ({
  clauseLibrary,
  currentUser,
}: {
  clauseLibrary: ClauseLibraryState;
  currentUser: User;
}) => ({
  clauseSummaries: clauseLibrary.clauseSummaries,
  clauseForm: clauseLibrary.clauseForm,
  isLoadingSummaries: clauseLibrary.isLoadingSummaries,
  selectedClause: clauseLibrary.selectedClause,
  currentUser,
  filters: clauseLibrary.filters,
});

export default connect(mapStateToProps, {
  setClauseLibraryClauseForm: actions.setClauseLibraryClauseForm,
  setClauseLibraryClauseSummaries: actions.setClauseLibraryClauseSummaries,
  setClauseLibraryIsLoadingSummaries:
    actions.setClauseLibraryIsLoadingSummaries,
  setClauseLibraryVariationForm: actions.setClauseLibraryVariationForm,
  setClauseLibraryEditMode: actions.setClauseLibraryEditMode,
  setClauseLibraryView: actions.setClauseLibraryView,
  setClauseLibrarySelectedClause: actions.setClauseLibrarySelectedClause,
  cleanupClauseSummaries: actions.cleanupClauseSummaries,
})(ClauseLibrary);
