import PropTypes from 'prop-types';
import React from 'react';

import {
  evisortBlue,
  evisortYellow,
} from '../../../assets/shared-styles/general';

const TreeIcon = (props) => {
  const { blue, yellow } = props;

  let color = '#000';
  if (blue) {
    color = evisortBlue;
  } else if (yellow) {
    color = evisortYellow;
  } else {
    color = props.color;
  }

  return (
    <svg
      aria-hidden="true"
      width={props.size}
      height={props.size}
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h32v32H0z" />
        <path
          fill={color}
          d="M20 4a2.667 2.667 0 0 1 2.667 2.667v2.666A2.667 2.667 0 0 1 20 12h-2.667v2.667h8V20H28a2.667 2.667 0 0 1 2.667 2.667v2.666A2.667 2.667 0 0 1 28 28h-8a2.667 2.667 0 0 1-2.667-2.667v-2.666A2.667 2.667 0 0 1 20 20h2.667v-2.667H9.333V20H12a2.667 2.667 0 0 1 2.667 2.667v2.666A2.667 2.667 0 0 1 12 28H4a2.667 2.667 0 0 1-2.667-2.667v-2.666A2.667 2.667 0 0 1 4 20h2.667v-5.333h8V12H12a2.667 2.667 0 0 1-2.667-2.667V6.667A2.667 2.667 0 0 1 12 4h8z"
        />
      </g>
    </svg>
  );
};

TreeIcon.defaultProps = {
  size: '24',
};

TreeIcon.propTypes = {
  size: PropTypes.string,
};

export default TreeIcon;
