import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import {
  workflowCreateSectionQuestion,
  workflowSetSectionDescription,
  workflowSortFormQuestions,
} from '~/actions';
import DescriptionTextAreaForm from '~/components/Workflow/shared/DescriptionTextAreaForm';
import { Box, Button } from '~/eds';
import { FlexLayout, useModalSortable } from '~/ui';
import { toSnakeTrimmed } from '~/utils/strings';
import { coerceName } from '~/utils/workflow';

import Question from './Question';

function Section({
  // connected
  disabled,
  position,
  section,
  workflowCreateSectionQuestion,
  workflowSetSectionDescription,
  workflowSortFormQuestions,
}) {
  const [
    sortableQuestionListModal,
    showSortableQuestionListModal,
  ] = useModalSortable({
    confirm: (items) =>
      workflowSortFormQuestions({ sectionId: section.id, items }),
    items: section.questions.map((question) => ({
      value: question,
      label: question.name,
    })),
    text: 'Sort',
    title: 'Sort Question',
  });

  const onCreateQuestion = (sectionId) => {
    const data = {
      id: uuid(),
      position: section.questions.length,
      creating: true,
      name: '',
    };
    workflowCreateSectionQuestion({ sectionId, data });
  };

  const coercedSectionName = coerceName(section.name, 'Form Section', position);
  const sectionName = toSnakeTrimmed(coercedSectionName);

  return (
    <>
      <FlexLayout disabled={disabled} flexDirection="column" space={8}>
        <DescriptionTextAreaForm
          description={section.description}
          id={`workflow--form-section-name=${sectionName}-add-description`}
          noDescriptionMessage="No description provided for this section."
          onUpdate={(description) =>
            workflowSetSectionDescription({
              sectionId: section.id,
              description,
            })
          }
        />
        <FlexLayout flexDirection="column" space={6}>
          {section.questions.map((question) => (
            <Question
              key={question.id}
              question={question}
              sectionId={section.id}
              onReorder={showSortableQuestionListModal}
            />
          ))}
          <Box>
            <Button
              id={`workflow--form-section-name=${sectionName}-add-question`}
              text="Add Question"
              onClick={() => onCreateQuestion(section.id)}
            />
          </Box>
        </FlexLayout>
      </FlexLayout>
      {sortableQuestionListModal}
    </>
  );
}

const mapStateToProps = ({ builder, workflow }, { sectionId }) => ({
  disabled: !builder.shouldEnableAllComponents,
  section: workflow.form.sections.find((section) => section.id === sectionId),
});

export default connect(mapStateToProps, {
  workflowCreateSectionQuestion,
  workflowSortFormQuestions,
  workflowSetSectionDescription,
})(Section);
