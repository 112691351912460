import PropTypes from 'prop-types';
import React from 'react';

import { evisortBlue } from '../../../assets/shared-styles/general';

const PlusIcon = (props) => {
  let color = props.color;
  let opacity = props.opacity;

  if (props.isActive) {
    color = evisortBlue;
    opacity = '1';
  }

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          fill={color}
          fillOpacity={opacity}
          d="M15.833 10.833h-5v5H9.167v-5h-5V9.167h5v-5h1.666v5h5z"
        />
      </g>
    </svg>
  );
};

PlusIcon.defaultProps = {
  size: '16',
  color: '#FFF',
  opacity: '1',
};

PlusIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
};

export default PlusIcon;
