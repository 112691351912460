import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { EditableField, Layout, Text, TextArea } from '~/eds';
import { api } from '~/redux';

import AutomationHubSearchingFilters from '../../AutomationHubSearchFilters';

const FilterSummary = ({ model }) => {
  const [description, setDescription] = useState(model.description || '');

  const [updateDescription] = api.endpoints.updateDescription.useMutation();

  const trainingFilter = model.current?.filter?.trainingFilter ?? [];
  const predictionFilter = model.current?.filter?.predictionFilter ?? [];

  const rules = model?.current?.userInput?.rules ?? {
    positive: [],
    negative: [],
  };
  const numOfPositiveRules = rules.positive.length;
  const numOfNegativeRules = rules.negative.length;

  const phrases = model?.current?.userInput?.phrases ?? {
    positive: [],
    negative: [],
  };
  const numOfPositivePhrases = phrases.positive.length;
  const numOfNegativePhrases = phrases.negative.length;

  const TrainingFilterSummary = useMemo(
    () => (
      <Layout direction="column" spacing={4}>
        <Text variant="body-medium">Training Filters</Text>
        <AutomationHubSearchingFilters preview={trainingFilter} />
      </Layout>
    ),
    [trainingFilter],
  );

  const PredictFilterSummary = useMemo(
    () => (
      <Layout direction="column" spacing={4}>
        <Text variant="body-medium">Predictive Filters</Text>
        <AutomationHubSearchingFilters preview={predictionFilter} />
      </Layout>
    ),
    [predictionFilter],
  );

  const handleUpdateDescription = (description) => {
    setDescription(description.trim());
    updateDescription({ clauseId: model.id, description: description.trim() });
  };

  return (
    <Layout border="border" boxShadow="base" direction="column">
      <Layout p={6} direction="column" spacing={6}>
        <EditableField
          input={TextArea}
          label="Description"
          placeholder="Please add the description"
          value={description}
          onSave={handleUpdateDescription}
          getValueMessage={(value) => {
            if (value?.length > 500) {
              return {
                message: `${value.length}/500 (Description cannot be longer than 500 characters)`,
                status: 'error',
              };
            } else {
              return { message: '', status: 'info' };
            }
          }}
        />
        {TrainingFilterSummary}
        {PredictFilterSummary}
        <Layout direction="column" spacing={4}>
          <Text variant="body-medium">Rules</Text>
          <Text>
            {numOfPositiveRules + numOfNegativePhrases <= 0 ? (
              'None'
            ) : (
              <>
                {numOfPositiveRules} positive, {numOfNegativeRules} negative
              </>
            )}
          </Text>
        </Layout>
        <Layout direction="column" spacing={4}>
          <Text variant="body-medium">Phrases</Text>
          <Text>
            {numOfPositivePhrases + numOfNegativePhrases <= 0 ? (
              'None'
            ) : (
              <>
                {numOfPositivePhrases} positive, {numOfNegativePhrases} negative
              </>
            )}
          </Text>
        </Layout>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({ automation }) => ({
  model: automation.model,
});

export default connect(mapStateToProps, {})(FilterSummary);
