import {
  ADMIN_PERMISSION_IDS,
  CLAUSE_LIBRARY_PERMISSION_IDS,
  CLAUSE_SAMPLES_PERMISSION,
  DASHBOARDS_PERMISSION_IDS,
  DOCUMENT_GROUPS_PERMSSION_IDS,
  DOCUMENT_PERMISSION_IDS,
  FIELD_AI_PERMISSION,
  WORKFLOW_BUILDER_ID,
  WORKFLOW_TICKETS_EDIT_ID,
  WORKFLOW_TICKETS_VIEW_ID,
} from './constants';

export const getLandingPage = (userPermissions: string[], clientId: number) => {
  if (!userPermissions || userPermissions?.length === 0) {
    return '/unauthorized';
  }
  if (
    DOCUMENT_PERMISSION_IDS.some((permission) =>
      userPermissions.includes(permission),
    )
  ) {
    return '/documents';
  }

  if (
    DOCUMENT_GROUPS_PERMSSION_IDS.some((permission) =>
      userPermissions.includes(permission),
    )
  ) {
    return '/document-groups';
  }

  if (
    [WORKFLOW_TICKETS_VIEW_ID, WORKFLOW_TICKETS_EDIT_ID].some((permission) =>
      userPermissions.includes(permission),
    )
  ) {
    return '/workflow/tickets';
  }
  if (userPermissions.includes(WORKFLOW_BUILDER_ID)) {
    return '/workflow/builder';
  }

  if (
    CLAUSE_LIBRARY_PERMISSION_IDS.some((permission) =>
      userPermissions.includes(permission),
    )
  ) {
    return '/clause-library';
  }

  if (
    [CLAUSE_SAMPLES_PERMISSION].some((permission) =>
      userPermissions.includes(permission),
    )
  ) {
    return '/automation';
  }

  if (
    [FIELD_AI_PERMISSION].some((permission) =>
      userPermissions.includes(permission),
    )
  ) {
    return '/automation/fields';
  }

  if (
    ADMIN_PERMISSION_IDS.some((permission) =>
      userPermissions.includes(permission),
    )
  ) {
    return `/admin/console/${clientId}`;
  }

  if (
    DASHBOARDS_PERMISSION_IDS.some((permission) =>
      userPermissions.includes(permission),
    )
  ) {
    return `/dashboards/`;
  }
};
