import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';
import { Nullable, Uuid } from '~/types';

import { ChatbotState as State } from '../types';

export const initialState: State = {
  activeSourceIndex: null,
  context: [],
  currentConversationId: null,
  entity: null,
  isVisible: false,
  messageQueue: {
    queue: [],
    index: 0,
  },
  mode: 'floating',
  selectedContext: null,
  isTextInputDisabled: false,
  sideContent: {
    isVisible: false,
    selectedTab: 'questions',
  },
  sources: [],
  width: '525px',
};

const closeChatbot = (state: State) => {
  state.entity = null;
  state.selectedContext = null;
  state.isVisible = false;
  state.currentConversationId = null;
  state.sources = [];
  state.isTextInputDisabled = false;
  state.mode = 'floating';
  state.sideContent = {
    isVisible: false,
    selectedTab: 'questions',
  };
  state.messageQueue = {
    queue: [],
    index: 0,
  };
};

export const chatbotSlice = createSlice({
  name: SliceType.Chatbot,
  initialState,
  reducers: {
    // toggle the visibility of the chatbot. It will also set to null the entity if not visible
    clearMessageQueue: (state: State) => {
      state.messageQueue.queue = [];
      state.messageQueue.index = 0;
    },
    closeChatbot: closeChatbot,
    openSideContent: (
      state: State,
      action: PayloadAction<State['sideContent']['selectedTab'] | undefined>,
    ) => {
      const tab = action.payload;
      if (tab) {
        state.sideContent.selectedTab = tab;
      }
      state.sideContent.isVisible = true;
    },
    postMessageToQueue: (
      state: State,
      action: PayloadAction<
        | { id: Uuid; message: string; parentId?: string }
        | { id: Uuid; message: string; parentId?: string }[]
      >,
    ) => {
      if (Array.isArray(action.payload)) {
        const messages = action.payload.map((message) => ({
          ...message,
          status: 'pending' as const,
        }));
        state.messageQueue.queue.push(...messages);
      } else {
        state.messageQueue.queue.push({
          ...action.payload,
          status: 'pending',
        });
      }
    },
    processNextMessage: (state: State) => {
      if (state.messageQueue.queue.length > 0) {
        state.messageQueue.queue[state.messageQueue.index].status = 'complete';
        if (state.messageQueue.index < state.messageQueue.queue.length - 1) {
          state.messageQueue.index += 1;
        }
      }
    },
    removeMessageFromQueue: (state: State) => {
      state.messageQueue.queue = state.messageQueue.queue.slice(1);
    },
    setActiveSourceIndex: (
      state: State,
      action: PayloadAction<Nullable<number>>,
    ) => {
      state.activeSourceIndex = action.payload;
    },
    setIsTextInputDisabled: (state: State, action: PayloadAction<boolean>) => {
      state.isTextInputDisabled = action.payload;
    },
    setContext: (state: State, action: PayloadAction<State['context']>) => {
      state.context = action.payload;
    },
    setCurrentConversationId: (
      state: State,
      action: PayloadAction<State['currentConversationId']>,
    ) => {
      state.currentConversationId = action.payload;
    },
    setEntity(state: State, action: PayloadAction<State['entity']>) {
      if (!state.entity) {
        state.entity = action.payload;
      }
    },
    setPanelMode: (state: State, action: PayloadAction<State['mode']>) => {
      state.mode = action.payload;
    },
    setSelectedContextInterim: (
      state: State,
      action: PayloadAction<State['selectedContext']>,
    ) => {
      if (state.entity?.type === 'document_version' && action.payload) {
        state.entity = {
          type: 'all_documents',
          id: action.payload.id,
        };
      }
      state.selectedContext = action.payload;
    },
    setSelectedContext: (
      state: State,
      action: PayloadAction<State['selectedContext']>,
    ) => {
      state.selectedContext = action.payload;
    },
    setSideContentTab: (
      state: State,
      action: PayloadAction<State['sideContent']['selectedTab']>,
    ) => {
      state.sideContent.selectedTab = action.payload;
    },
    setSources: (state: State, action: PayloadAction<State['sources']>) => {
      state.sources = action.payload;
    },
    setWidth: (state: State, action: PayloadAction<State['width']>) => {
      state.width = action.payload;
    },
    toggleChatbot: (state: State) => {
      if (state.isVisible) {
        closeChatbot(state);
      } else {
        state.isVisible = true;
        state.selectedContext =
          state.context.find((item) => item.primary) ?? null;
      }
    },
    toggleSideContent: (state: State) => {
      state.sideContent.isVisible = !state.sideContent.isVisible;
    },
    updateMessageStatus: (
      state: State,
      action: PayloadAction<{
        status: 'complete' | 'running' | 'pending';
        id: Uuid;
      }>,
    ) => {
      const index = state.messageQueue.queue.findIndex(
        (message) => message.id === action.payload.id,
      );
      if (index >= 0) {
        state.messageQueue.queue[index].status = action.payload.status;
      }
    },
  },
});
