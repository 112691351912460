import { groupBy } from 'lodash';
import React, { useMemo, useState } from 'react';

import { Accordion, Layout, SearchInput, Text } from '~/eds';
import { SearchFilter } from '~/types';

import { FilterItem } from './FilterItem';

type Props = {
  pinnedFiltersIds?: (string | number)[];
  searchFilters: SearchFilter[];
};

const ACCORDION_WIDTH = '260px';

export const FiltersAccordion = ({
  pinnedFiltersIds = [],
  searchFilters,
}: Props) => {
  const [search, setSearch] = useState('');

  const sections = useMemo(() => {
    const filtered = searchFilters.filter((filter) =>
      filter.label.toLowerCase().includes(search.toLowerCase()),
    );
    return groupBy(filtered, 'section');
  }, [search, searchFilters]);

  const items = useMemo(() => {
    const pinnedFilters = searchFilters.filter(
      (filter) =>
        pinnedFiltersIds.includes(filter.id) &&
        filter.label.toLowerCase().includes(search.toLowerCase()),
    );

    return [
      ...(pinnedFilters.length > 0
        ? [renderSection('Pinned Filters', pinnedFilters, search)]
        : []),
      ...Object.entries(sections).map(([section, filters]) =>
        renderSection(section, filters, search),
      ),
    ];
  }, [sections, search, pinnedFiltersIds, searchFilters]);

  return (
    <Layout direction="column" spacing={4} h="100%">
      <Layout direction="column" px={4} pt={4} spacing={4}>
        <Text variant="body-bold">Filter</Text>
        <SearchInput
          name="search"
          placeholder="Search by…"
          value={search}
          onChange={(value) => setSearch(value as string)}
        />
      </Layout>

      <Layout h="100%" overflowY="scroll" px={4}>
        <Accordion
          minW={ACCORDION_WIDTH}
          w={ACCORDION_WIDTH}
          hasItemsGap
          mode="compact"
          disableContentPadding={true}
          togglePosition="right"
          border="none"
          items={items}
        />
      </Layout>
    </Layout>
  );
};

const renderFilter = (
  { id, label }: SearchFilter,
  index: number,
  search: string,
) => (
  <FilterItem key={`${id}_${index}`} id={id} label={label} search={search} />
);

const renderSection = (
  section: string,
  filters: SearchFilter[],
  search: string,
) => ({
  title: section,
  isExpanded: true,
  content: (
    <Layout direction="column">
      {filters.map((filter, index) => renderFilter(filter, index, search))}
    </Layout>
  ),
});
