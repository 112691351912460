import { api } from '~/redux';
import { Uuid } from '~/types';

export const useResolveQuestions = ({
  questionIds,
  skip,
}: {
  questionIds: Uuid[];
  skip?: boolean;
}) => {
  const { data: questions, ...rest } = api.endpoints.resolveQuestions.useQuery(
    {
      ids: questionIds,
    },
    { skip },
  );

  return { questions, ...rest };
};

export const useResolveQuestionsFromGroup = ({
  groupId,
  questionIds,
  skip,
}: {
  groupId: Uuid;
  questionIds: Uuid[];
  skip?: boolean;
}) => {
  const {
    data: questions,
    ...rest
  } = api.endpoints.getQuestionGroupQuestions.useQuery(
    {
      id: groupId,
    },
    { skip },
  );

  const filteredQuestions = questions?.filter((question) =>
    questionIds.includes(question.id),
  );

  return { questions: filteredQuestions, ...rest };
};
