import React from 'react';

const AnalyzerResultsEmptyStateIcon = () => {
  return (
    <svg width="160" height="173" viewBox="0 0 160 173">
      <defs>
        <linearGradient id="a" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <path id="b" d="M.138.183h36.676v24.682H.138z" />
        <path id="d" d="M.138.107h36.676v24.682H.138z" />
        <linearGradient id="f" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#E7EAEC" d="M.66 7.718h114.382v153.446H.66z" opacity=".5" />
        <path fill="#E7EAEC" d="M2.15 9.801h110.808v148.094H2.15z" />
        <path fill="#E0E0E0" d="M10.789 16.045h38.127v1.189H10.789z" />
        <path
          fill="#FFC449"
          d="M10.789 21.397h38.127v1.189H10.789z"
          opacity=".7"
        />
        <path
          fill="#E0E0E0"
          d="M54.576 16.045h38.127v1.189H54.576zM60.532 49.948h38.127v1.189H60.532zM61.427 53.217h38.127v1.189H61.427zM63.215 56.489h38.127v1.189H63.215zM60.532 102.582h38.127v1.189H60.532zM61.427 105.854h38.127v1.189H61.427zM63.215 109.124h38.127v1.189H63.215z"
        />
        <path
          fill="url(#a)"
          d="M.008.041h40.509v41.038H.008z"
          opacity=".5"
          transform="translate(11.08 37.712)"
        />
        <path fill="#F5F5F5" d="M11.343 38.647h39.872V77.9H11.343z" />
        <path
          fill="url(#a)"
          d="M.138.003h37.189v25.574H.138z"
          opacity=".5"
          transform="translate(12.61 41.023)"
        />
        <path fill="#FFF" d="M13.003 41.323h36.676v24.682H13.003z" />
        <g transform="translate(58.522 56.556)">
          <mask id="c" fill="#fff">
            <use href="#b" />
          </mask>
          <g fill="#6C63FF" mask="url(#c)" opacity=".4">
            <g transform="translate(-49.993 -14.515)">
              <path d="M1.365 23.073L10.6 8.798l3.871 6.244 8.341-8.623 7.15 8.623 6.254-12.786L52.3 27.83.173 26.045z" />
              <ellipse cx="8.514" cy="2.849" rx="2.681" ry="2.676" />
            </g>
          </g>
        </g>
        <path
          fill="url(#a)"
          d="M.008.222h40.509V41.26H.008z"
          opacity=".5"
          transform="translate(11.08 90.168)"
        />
        <path fill="#F5F5F5" d="M11.343 91.281h39.872v39.253H11.343z" />
        <path
          fill="url(#a)"
          d="M.138.183h37.189v25.574H.138z"
          opacity=".5"
          transform="translate(12.61 93.479)"
        />
        <path fill="#FFF" d="M13.003 93.957h36.676v24.682H13.003z" />
        <g transform="translate(58.522 109.266)">
          <mask id="e" fill="#fff">
            <use href="#d" />
          </mask>
          <g fill="#BDBDBD" mask="url(#e)" opacity=".4">
            <g transform="translate(-49.993 -14.515)">
              <path d="M1.365 22.997L10.6 8.72l3.871 6.247 8.341-8.625 7.15 8.625L36.217 2.18 52.3 27.753.173 25.971z" />
              <ellipse cx="8.514" cy="2.776" rx="2.681" ry="2.676" />
            </g>
          </g>
        </g>
        <path
          fill="url(#f)"
          d="M-5.421 29.28l153.456.05.036 114.373-153.456-.049z"
          opacity=".5"
          transform="rotate(-78.76 79.941 76.026)"
        />
        <path
          fill="#FFF"
          d="M19.552 147.722L48.466 2.47 157.15 24.03l-28.915 145.254z"
        />
        <path
          fill="#E0E0E0"
          d="M55.033 10.74l77.13 15.303-.232 1.166-77.13-15.303zM33.086 120.994l77.13 15.303-.231 1.167-77.131-15.303zM31.748 127.7l59.601 11.825-.232 1.167-59.601-11.825z"
        />
        <path
          fill="#FFC449"
          d="M30.413 134.411l22.203 4.405-.232 1.166-22.203-4.405zM53.695 17.45l38.564 7.65-.232 1.167-38.564-7.65z"
          opacity=".7"
        />
        <path
          fill="#E0E0E0"
          d="M52.363 24.158l69.533 13.795-.232 1.166L52.13 25.324z"
        />
        <path
          fill="#FFC449"
          d="M51.026 30.867L67.97 34.23l-.232 1.166-16.944-3.361z"
          opacity=".7"
        />
        <path
          fill="#E0E0E0"
          d="M49.692 37.576l77.13 15.303-.231 1.166L49.46 38.742z"
        />
        <path
          fill="#EEE"
          d="M48.592 105.579l7.896-39.667 69.24 13.738-7.896 39.666z"
          opacity=".5"
        />
        <path
          fill="#E0E0E0"
          d="M48.356 44.286l77.131 15.303-.232 1.167-77.13-15.303z"
        />
        <path
          fill="#007FBA"
          fillOpacity=".2"
          d="M62.994 72.961l8.474 1.681-5.632 28.293-8.474-1.682z"
        />
        <path
          fill="#21A1DD"
          fillOpacity=".2"
          d="M76.328 85.31l8.473 1.68-3.774 18.96-8.473-1.682z"
        />
        <path
          fill="#57B8E5"
          fillOpacity=".2"
          d="M89.78 97.074l8.474 1.681-2.032 10.208-8.473-1.681z"
        />
        <path
          fill="#1793CD"
          fillOpacity=".2"
          d="M107.063 89.588l8.474 1.68-4.123 20.71-8.473-1.68z"
        />
      </g>
    </svg>
  );
};

export default AnalyzerResultsEmptyStateIcon;
