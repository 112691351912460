import {
  black1,
  black2,
  black3,
  bold,
  evisortBlue,
  evisortRed,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  modalBodyContent: {
    display: 'flex',
    height: '480px',
    '& label': {
      color: black1,
      fontSize: '12px',
      fontWeight: semibold,
    },
  },
  infoSection: {
    width: '405px',
    paddingRight: '24px',
    maxHeight: '100%',
    overflowY: 'scroll',
  },
  documentsSection: {
    width: '520px',
    maxHeight: '100%',
    paddingLeft: '24px',
    overflowY: 'auto',
  },
  sectionSeparator: {
    height: '100%',
    border: 0,
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    margin: 0,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '15px',
    fontWeight: bold,
  },
  sectionTitle: {
    paddingBottom: '12px',
  },
  sectionHeaderAction: {
    paddingBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    color: evisortBlue,
    cursor: 'pointer',
  },
  addDocsButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    color: evisortBlue,
  },
  horizontalSeparator: {
    margin: 0,
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  subsection: {
    marginBottom: '24px',
  },
  subsectionTitle: {
    marginBottom: '6px',
    color: evisortBlue,
    fontSize: '12px',
    fontWeight: semibold,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justify: 'center',
    gap: '8px',
  },
  folderLocation: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '16px 0',
  },
  folderContent: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      flexShrink: 0,
    },
  },
  folderInfo: {
    marginLeft: '8px',
  },
  folderName: {
    color: black1,
    fontSize: '16px',
  },
  folderPath: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '4px',
    color: black3,
    fontSize: '13px',
    fontWeight: semibold,
  },
  documentInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0',
    '& svg': {
      flexShrink: 0,
    },
  },
  documentName: {
    width: '567px',
    marginLeft: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  documentSize: {
    width: '90px',
    marginRight: '30px',
    textAlign: 'right',
    '& span': {
      fontWeight: bold,
    },
  },
  multipleValuesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '& svg': {
      flexShrink: 0,
    },
  },
  multipleValues: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '8px',
  },
  multipleValuesEmpty: {
    color: black3,
    fontSize: '15px',
  },
  textArea: {
    padding: '7px',
    maxWidth: '380px',
    maxHeight: '90px',
    fontSize: '15px',
    lineHeight: '20px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '2px',
    '&:focus': {
      outline: 'none',
    },
  },
  errorMessage: {
    margin: '20px',
    color: evisortRed,
    fontSize: '15px',
    textAlign: 'center',
  },
  modalErrorMessage: {
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    marginTop: '24px',
    padding: '12px 16px',
    backgroundColor: 'rgba(243, 55, 55, 0.1)',
    color: black2,
    fontSize: '15px',
  },
};

export default styles;
