import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  CopyButton,
  DiffText,
  diffText,
  Layout,
  Paginate,
  scrollElementIntoView,
  Text,
  TextArea,
} from '~/eds';

import { Mode } from '../types';

interface Props {
  mode: Mode;
  pageIndex: number;
  resultText: string;
  text: string;
  totalCount: number;
  onUpdatePageIndex: (updatedPageIndex: number) => void;
  onUpdateResultText: (updatedResultText: string) => void;
}

// TODO: isolate responsibility of Result[mode] instead of overloading a single component.  Do this after design/product commits to a unified flow for GenAI apps.
export const Result = ({
  mode,
  pageIndex,
  resultText,
  text,
  totalCount,
  onUpdatePageIndex,
  onUpdateResultText,
}: Props) => {
  const isRedlineMode = mode === 'redline';

  useEffect(() => {
    scrollElementIntoView(GENI_AI_RESULT_ELEMENT_ID, { behavior: 'smooth' });
  }, [pageIndex]);

  const [isEditMode, setIsEditMode] = useState(false);

  const diff = diffText(text, resultText);

  const { added, removed } = diff.reduce(
    (result, d) => {
      const wordsCount = d.value.split(' ').length;
      if (d.added) {
        result.added += wordsCount;
      } else if (d.removed) {
        result.removed += wordsCount;
      }
      return result;
    },
    { added: 0, removed: 0 },
  );

  return (
    <Layout
      direction="column"
      id={GENI_AI_RESULT_ELEMENT_ID}
      minH="100%"
      py={6}
      mt={-6}
      spacing={4}
    >
      <Layout justify="space-between" spacing={4}>
        <Text textTransform="capitalize" variant="body-bold">
          Suggested {mode} {pageIndex}
        </Text>
        {isRedlineMode && (
          <Checkbox
            name="show redline"
            option={{
              label: 'Edit Mode',
              value: 'edit-mode',
            }}
            value={isEditMode}
            onChange={() => setIsEditMode(!isEditMode)}
          />
        )}
      </Layout>
      {isRedlineMode && (
        <Text color="text.secondary" variant="body-bold">
          {added} words added | {removed} words removed
        </Text>
      )}
      {!isRedlineMode || isEditMode ? (
        <TextArea
          name="result-text"
          rows={8}
          value={resultText}
          onChange={(updatedResultText) =>
            onUpdateResultText(updatedResultText ?? '')
          }
        />
      ) : (
        <DiffText text={text} newText={resultText} />
      )}
      <Layout justify="space-between">
        <Paginate
          mode="count"
          name="gen-ai-result-paginate"
          pageIndex={pageIndex}
          pageSize={1}
          totalCount={totalCount}
          onUpdatePageIndex={(updatedPageIndex) => {
            onUpdatePageIndex(updatedPageIndex);
            setIsEditMode(false);
          }}
        />
        <Layout preset="buttons">
          <CopyButton
            copyTooltip={`Copy suggested ${mode}`}
            copiedToastMessage={`Suggested ${mode} copied to the clipboard`}
            copyText={resultText}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

const GENI_AI_RESULT_ELEMENT_ID = 'gen-ai-result';
