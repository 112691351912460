import { noop } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RETRY_ERROR } from '~/constants/errors';
import {
  parseContentAst,
  serializeContentAst,
  testIsEmptyAst,
  TextEditor,
  types,
  useToast,
  useUuid,
} from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { api, selectors } from '~/redux';
import { useRouting } from '~/routing';
import { Nullable } from '~/types';

import { PROMPT_WORD_LIMIT } from '../../constants';
import { testIsPromptOverLimit } from '../../utils';

interface Props {
  value: Nullable<string>;
  onChange: (updatedContent: string) => void;
  placeholder?: string;
  readOnly?: boolean;
}

export const InstructionsEditor = ({
  onChange,
  placeholder,
  value,
  readOnly = false,
}: Props) => {
  const { params } = useRouting();
  const { modelId = '' } = params;
  const { toast } = useToast();
  const enableOptimize = useFlag(FlagType.DocumentXrayOptimize);

  const config = useSelector(selectors.selectFieldAiConfig);
  const fieldId = useSelector(selectors.selectFieldAiField);

  const [isEditing, setIsEditing] = useState(false);
  const [uuid, setUuid] = useUuid();
  const content = useMemo(() => parseContentAst(value ?? ''), [value]);
  const [draftContent, setDraftContent] = useState<types.ContentAst>(content);
  const [isAiSuggestion, setIsAiSuggestion] = useState(false);

  const [
    getSuggestion,
    {
      data: aiSuggestion = '',
      isLoading: isLoadingSuggestion,
      isFetching: isFetchingSuggestion,
    },
  ] = api.endpoints.suggestPromptModelInstructions.useLazyQuery();

  // sync when props.value (content) changes.  Slate does not support controlled usage, so we update with a new generated UUID key.
  useEffect(() => {
    setDraftContent(content);
    setUuid();
  }, [content]);

  const handleSave = () => {
    setIsEditing(false);
    const updatedValue = serializeContentAst(draftContent);
    onChange(updatedValue);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setUuid();
    setDraftContent(content);
  };

  const handleAiSuggest = () => {
    setIsAiSuggestion(true);
    getSuggestion({
      config,
      modelId,
    })
      .then(() => {
        setUuid();
      })
      .catch(() => {
        toast({
          status: 'danger',
          message: RETRY_ERROR,
        });
        setIsAiSuggestion(false);
      });
  };

  const handleAiAccept = () => {
    setIsAiSuggestion(false);
    setIsEditing(false);
    setDraftContent(parseContentAst(aiSuggestion));
    onChange(aiSuggestion);
  };

  const handleAiReject = () => {
    setIsAiSuggestion(false);
    setUuid();
  };

  const disableAiSuggest = modelId !== 'new' && enableOptimize;

  const isEmptyContent = testIsEmptyAst(content);
  const isEmptyDraftContent = testIsEmptyAst(draftContent);
  const enableSaveCancelActions =
    !isAiSuggestion &&
    isEditing &&
    (!isEmptyDraftContent || (isEmptyDraftContent && !isEmptyContent));

  const status = isEditing ? (isAiSuggestion ? 'ai' : undefined) : 'inactive';
  const suggestionIsLoading = isLoadingSuggestion || isFetchingSuggestion;
  const displayContent =
    isAiSuggestion && !suggestionIsLoading
      ? parseContentAst(aiSuggestion)
      : draftContent;

  const overlimitTooltip = testIsPromptOverLimit(
    serializeContentAst(displayContent),
  )
    ? `Instructions must be ${PROMPT_WORD_LIMIT} words or fewer.`
    : undefined;

  const actions = [
    ...(!isEditing
      ? [
          {
            disabled: readOnly,
            tooltip: readOnly ? 'You are in read-only mode.' : undefined,
            icon: 'edit' as const,
            iconPosition: 'left' as const,
            text: 'Edit',
            onClick: () => setIsEditing(true),
          },
        ]
      : []),
    ...(fieldId && isEditing
      ? [
          {
            disabled: disableAiSuggest,
            icon: isAiSuggestion ? ('rerun' as const) : ('ai' as const),
            iconPosition: 'left' as const,
            status: 'ai' as const,
            text: 'Suggest Instructions',
            tooltip: disableAiSuggest
              ? 'Evaluate documents and use AI Boost to suggest better instructions.'
              : undefined,
            onClick: handleAiSuggest,
          },
        ]
      : []),
    ...(isAiSuggestion
      ? [
          {
            disabled: Boolean(overlimitTooltip),
            icon: 'check' as const,
            iconPosition: 'left' as const,
            text: 'Accept',
            tooltip: overlimitTooltip,
            onClick: handleAiAccept,
          },
          {
            icon: 'x' as const,
            iconPosition: 'left' as const,
            text: 'Reject',
            onClick: handleAiReject,
          },
        ]
      : []),
    ...(enableSaveCancelActions
      ? [
          {
            disabled: Boolean(overlimitTooltip),
            icon: 'check' as const,
            iconPosition: 'left' as const,
            text: 'Save',
            tooltip: overlimitTooltip,
            onClick: handleSave,
          },
          {
            icon: 'x' as const,
            iconPosition: 'left' as const,
            text: 'Cancel',
            onClick: handleCancel,
          },
        ]
      : []),
  ];

  return (
    <TextEditor
      key={uuid}
      disablePostButton
      actions={suggestionIsLoading ? [] : actions}
      content={displayContent}
      enableMention={false}
      enableResize={true}
      limit={PROMPT_WORD_LIMIT}
      limitMode="words"
      loadingContent={{
        isLoading: suggestionIsLoading,
        message: 'Loading… This may take a minute or more.',
      }}
      placeholder={placeholder}
      readOnly={!isEditing || isAiSuggestion}
      status={status}
      onChange={setDraftContent}
      onPost={noop}
    />
  );
};
