import React from 'react';

import { Box, LoadingShimmer } from '../../../components';
import { ROW_DEFAULT_HEIGHT } from '../constants';

export const LoadingCell = () => {
  return (
    <Box flex="auto" h={ROW_DEFAULT_HEIGHT / 2}>
      <LoadingShimmer />
    </Box>
  );
};
