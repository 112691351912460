import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import styles from './EcPersistentTooltip.styles';

class EcPersistentTooltip extends Component {
  render() {
    const { classes, children, tooltipOptions } = this.props;

    return (
      <div className={classes.persistentTooltipWrapper}>
        {children}
        {!!Object.keys(tooltipOptions).length && (
          <div className={classes.tooltip}>{tooltipOptions.message}</div>
        )}
      </div>
    );
  }
}

EcPersistentTooltip.propTypes = {
  tooltipOptions: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default injectSheet(styles)(EcPersistentTooltip);
