import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { Box, Divider, Layout } from '~/eds';
import { AribaIntegrationType, ManageSyncPairStageType } from '~/enums';
import { FlagType, useFlag } from '~/flags';

import AribaFooter from '../AribaFooter';
import Realm from './Realm';

function AribaGlobalSetup({
  // connected,
  activeSyncPair,
  manageSyncPairStage,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const { provider } = activeSyncPair;
  const providerOptions = activeSyncPair.providerOptions ?? {};
  const stageProperties = activeSyncPair[manageSyncPairStage];
  const { realm, location } = stageProperties ?? providerOptions;
  const isTokenReauthenticate = !!activeSyncPair?.isTokenReauthenticate;

  const aribaPhase2 = useFlag(FlagType.AribaIntegrationPhase2);
  const aribaPhase3 = useFlag(FlagType.AribaIntegrationPhase3);

  const handleClose = () => {
    integrationsSetActiveSyncPair(null);
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.None);
  };

  const handleCreateNext = () => {
    if (isTokenReauthenticate) {
      // the only way we have to know if is one time import is the number of tokens
      let syncType = AribaIntegrationType.ONE_TIME;
      if (providerOptions.tokens.length > 1) {
        syncType = AribaIntegrationType.CONTINUOUS;
      }
      const updatedValues = {
        ...stageProperties,
        location,
        realm,
      };
      integrationsSetActiveSyncPair({
        ...activeSyncPair,
        [manageSyncPairStage]: updatedValues,
        syncType,
      });
      integrationsSetManageSyncPairStage(ManageSyncPairStageType.MultiAPIs);
    } else {
      if (aribaPhase2 || aribaPhase3) {
        integrationsSetManageSyncPairStage(ManageSyncPairStageType.FileConfig);
      } else {
        integrationsSetManageSyncPairStage(ManageSyncPairStageType.Extract);
      }
    }
  };

  return (
    <Layout align="center" direction="column" spacing={8} w="100%">
      <Layout align="center">
        <ProviderLogo provider={provider} />
      </Layout>
      <Divider />
      <Box w="60%">
        <Realm />
      </Box>
      <AribaFooter
        secondaryAction={{
          text: 'Cancel',
          onClick: handleClose,
        }}
        mainAction={{
          text: 'Next',
          onClick: handleCreateNext,
          disabled: !realm || !location,
        }}
      />
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(AribaGlobalSetup);
