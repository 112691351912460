import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout, InputLabel, Switch, Text } from '~/ui';

// TODO: formalize with Form/Field component with layout options
const widths = {
  s: '200px',
  m: '260px',
  l: '520px',
  fullWidth: '100%',
};

function SwitchLabel({
  disabled,
  id,
  isRequired = false,
  label,
  message,
  value = false,
  width = 'm',
  onChange,
}) {
  return (
    <InputLabel id={id} isRequired={isRequired} label={label}>
      <FlexLayout space={8} sx={{ width: widths[width] }}>
        <Text color="gray-900" variant="s-dense">
          {message}
        </Text>
        <Box sx={{ flex: 'none' }}>
          <Switch
            disabled={disabled}
            size="s"
            value={value}
            onChange={onChange}
          />
        </Box>
      </FlexLayout>
    </InputLabel>
  );
}

SwitchLabel.propTypes = {
  disabled: PT.bool,
  id: PT.string.isRequired,
  isRequired: PT.bool,
  label: PT.string.isRequired,
  value: PT.bool,
  width: PT.oneOf(['s', 'm', 'l', 'fullWidth']),
  onChange: PT.func.isRequired,
};

export default SwitchLabel;
