import { useEffect } from 'react';

import {
  refreshAccessToken,
  shouldRefreshAccessToken,
} from '../auth/refreshAccessToken';

type Params = {
  interval: number;
};

export const useRefreshTokenWithInterval = (
  { interval }: Params = { interval: 1000 },
) => {
  useEffect(() => {
    const refreshTokenInterval = setInterval(() => {
      if (shouldRefreshAccessToken()) {
        refreshAccessToken();
      }
    }, interval);
    return () => clearInterval(refreshTokenInterval);
  }, []);
};
