import React from 'react';

import { Card, ContentContainer, Icon, Layout, Text } from '~/eds';
import { api } from '~/redux';
import { PilotId } from '~/types';

import { getFieldTypeLabel, getIconFromFieldType } from '../utils';

interface Props {
  id: PilotId;
}

export const FieldInfo = ({ id }: Props) => {
  // TODO: design specs to handle/retry async error
  const {
    data = { results: [] },
    isFetching: isLoading,
  } = api.endpoints.getFields.useQuery({
    ids: [id],
    limit: 1,
  });

  const field = data.results[0];

  const loadingContent = {
    isLoading,
    shimmer: {
      height: 'height.m',
      width: '100%' as const,
    },
  };

  return (
    <Card mode="bordered" size="s" status="info" width="100%">
      <ContentContainer loadingContent={loadingContent}>
        {field && (
          <Layout align="center" spacing={2}>
            <Icon icon={getIconFromFieldType(field.type)} />
            <Layout direction="column">
              <Text>{field.label}</Text>
              <Text preset="help">{getFieldTypeLabel(field.type)}</Text>
            </Layout>
          </Layout>
        )}
      </ContentContainer>
    </Card>
  );
};
