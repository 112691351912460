import { createReducer, createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import uuid from 'uuid';

import * as actions from '~/actions';
import {
  filter,
  fromExpression,
  getUniqueFieldsInExpressionTree,
} from '~/components/Shared/ConditionExpressionBuilder/utils';
import {
  hasValidApprovalValue,
  testCircularDependencies,
  testValidApprover,
} from '~/components/Workflow/Workflow.utils';
import { testIsValidDefaultValue } from '~/components/Workflow/WorkflowBuilderPage/Form/QuestionEditable.utils';
import operatorDefinitions from '~/components/Workflow/WorkflowBuilderPage/Sidebar/Conditions/operatorDefinitions';
import {
  contractFieldDefinitions,
  partyFieldDefinitions,
  signerFieldDefinitions,
} from '~/constants/workflow';
import {
  FileExtensionType,
  WorkflowFieldType,
  WorkflowPartyType,
  WorkflowReviewFinalizeApprovalType,
  WorkflowUserDepartmentInclusionType,
} from '~/enums';
import { isConditionFieldValid } from '~/reducers/intakeForm/utils';
import {
  CHARACTER_INPUT_LIMIT_SHORT,
  CHARACTER_INPUT_LIMIT_TEMPORARY,
  PHASE_TITLE_CHARACTER_LIMIT,
} from '~/ui/enums/input';
import {
  testValidFieldsForArchiveSubFolder,
  testValidFieldsInTicketNamingConvention,
} from '~/utils';
import { getOptions } from '~/utils/array';
import { getArchiveLocationPath } from '~/utils/folders';
import {
  createConditionRule,
  getDefaultCustomSettingsByFieldType,
  getPartyName,
  mergeFields,
  testConditionalTextField,
  testContractField,
  testCustomField,
  testESignatureField,
  testImplicitField,
  testInvalidConditionRule,
  testInvalidField,
  testInvalidFieldMapping,
  testInvalidSigner,
  testIsStageEnabledOrIsDisabledWithCondition,
  testLackCounterpartySignerQuestion,
  testShouldEvaluateStageConditions,
  testSignerFieldDefinitionRequired,
  testUnassignedField,
} from '~/utils/workflow';

export const getInitialState = () => ({
  acceptedFileTypes: [FileExtensionType.Doc, FileExtensionType.Docx],
  conditions: {},
  fields: {},
  fieldLinks: {},
  parties: {},
  form: {
    sections: [],
  },
  signers: {},
  stages: {
    edit: {
      conditionRule: null,
      isEnabled: true,
    },
    review: {
      coordinators: [],
      conditionRule: null,
      isEnabled: true,
      phases: [],
    },
    sign: {
      coordinators: [],
      conditionRule: null,
      isEnabled: true,
      phases: [],
    },
    finalize: {
      coordinators: [],
      conditionRule: null,
      isEnabled: true,
      phases: [],
    },
  },
  settings: {
    archiveLocation: {
      pilotFolderId: null,
      filePath: [],
      fileName: [],
    },
    dataFieldsMapping: {},
    notifications: [],
    permissions: {
      creator: {
        EDIT: [],
        REVIEW: [],
        SIGN: [],
        FINALIZE: [],
        TICKET: [],
      },
      fullAccess: {
        rule: WorkflowUserDepartmentInclusionType.Include,
        userDepartments: [],
      },
    },
    isTurnTrackingEnabled: true,
    ticketNamingConvention: [],
  },
  versions: [],
});

const createSigningPhase = () => ({
  id: uuid(),
  description: '',
  title: '',
  approvals: [],
  conditionRule: null,
  signers: [createSigner()],
});

// note: it is intended (and accepted by the server) that the default value of signer IDs is set to `null`.  A `null` signer ID indicates a non-assigned signer that still shows up in the UI.
const createSigner = () => ({ id: null, conditionRule: null, default: null });

const createApprovalPhase = () => ({
  id: uuid(),
  conditionRule: null,
  title: '',
  description: '',
  approvals: [],
});

const createApproval = () => ({
  id: uuid(),
  description: '',
  name: '',
  approvers: [],
  approvalType: 'everyone',
  minApprovalCount: 1,
  conditionRule: null,
});

function sortByOptions(array, ids) {
  return array.sort(function (a, b) {
    return ids.indexOf(a.id) - ids.indexOf(b.id);
  });
}

export default createReducer(getInitialState(), (builder) => {
  builder.addCase(actions.workflowLoad, (_state, action) => {
    return { ...getInitialState(), ...action.payload };
  });
  builder.addCase(actions.workflowReset, () => {
    return getInitialState();
  });
  builder.addCase(actions.workflowUpdateSetup, (state, action) => {
    const {
      acceptedFileTypes,
      description,
      fields,
      file,
      name,
      type,
    } = action.payload;
    state.acceptedFileTypes = acceptedFileTypes;
    state.description = description;
    state.name = name;
    state.file = file;
    state.type = type;
    const [mergedFields, mergedFieldLinks] = mergeFields(
      fields,
      state.fields,
      state.fieldLinks,
    );
    state.fields = mergedFields;
    state.fieldLinks = mergedFieldLinks;
  });
  builder.addCase(actions.workflowSetReviewCoordinator, (state, action) => {
    const coordinator = action.payload;
    state.stages.review.coordinators = coordinator ? [coordinator] : [];
  });
  builder.addCase(actions.workflowSetReviewPhaseName, (state, action) => {
    const { id, title } = action.payload;
    const phase = getReviewPhaseById(state, id);
    phase.title = title;
  });
  builder.addCase(
    actions.workflowSetReviewPhaseDescription,
    (state, action) => {
      const { phaseId, value } = action.payload;
      const phase = getReviewPhaseById(state, phaseId);
      phase.description = value;
    },
  );
  builder.addCase(actions.workflowReviewAddApprover, (state, action) => {
    const phase = getReviewPhaseById(state, action.payload);
    phase.approvals.push(createApproval());
  });
  builder.addCase(actions.workflowAddReviewPhase, (state) => {
    state.stages.review.phases.push(createApprovalPhase());
  });
  builder.addCase(actions.workflowRemoveReviewPhase, (state, action) => {
    const phases = state.stages.review.phases.filter(
      (phase) => phase.id !== action.payload,
    );
    state.stages.review.phases = phases;
  });
  builder.addCase(
    actions.workflowToggleReviewConditionRule,
    (state, action) => {
      const { conditionRule, id, phaseId } = action.payload;
      const phase = getReviewPhaseById(state, phaseId);
      const approval = findById(phase.approvals, id);
      approval.conditionRule = conditionRule;
    },
  );
  builder.addCase(actions.workflowToggleReviewApprovalType, (state, action) => {
    const { approvalType, id, phaseId } = action.payload;
    const phase = getReviewPhaseById(state, phaseId);
    const approval = findById(phase.approvals, id);
    approval.approvalType = approvalType;
  });
  builder.addCase(actions.workflowSetReviewApprovalCount, (state, action) => {
    const { minApprovalCount, id, phaseId } = action.payload;
    const phase = getReviewPhaseById(state, phaseId);
    const approval = findById(phase.approvals, id);
    approval.minApprovalCount = minApprovalCount;
  });
  builder.addCase(
    actions.workflowRemoveReviewApprovalCount,
    (state, action) => {
      const { id, phaseId } = action.payload;
      const phase = getReviewPhaseById(state, phaseId);
      const approval = findById(phase.approvals, id);
      delete approval.minApprovalCount;
    },
  );
  builder.addCase(actions.workflowSetReviewApprovalOrder, (state, action) => {
    const { orders, phaseId } = action.payload;
    const phase = getReviewPhaseById(state, phaseId);
    sortByOptions(phase.approvals, orders);
  });
  builder.addCase(actions.workflowRemoveReviewApproval, (state, action) => {
    const { id, phaseId } = action.payload;
    const phase = getReviewPhaseById(state, phaseId);
    const approvals = phase.approvals.filter((appr) => appr.id !== id);
    phase.approvals = approvals;
  });
  builder.addCase(actions.workflowSetReviewApprovalTitle, (state, action) => {
    const { value, id, phaseId } = action.payload;
    const phase = getReviewPhaseById(state, phaseId);
    const approval = findById(phase.approvals, id);
    approval.name = value;
  });
  builder.addCase(
    actions.workflowSetReviewApprovalDescription,
    (state, action) => {
      const { value, id, phaseId } = action.payload;
      const phase = getReviewPhaseById(state, phaseId);
      const approval = findById(phase.approvals, id);
      approval.description = value;
    },
  );
  builder.addCase(actions.workflowSetReviewPhaseApprovers, (state, action) => {
    const { approvers, id, phaseId } = action.payload;
    const phase = getReviewPhaseById(state, phaseId);
    const approval = findById(phase.approvals, id);
    approval.approvers = approvers;
  });
  builder.addCase(actions.workflowSortReviewPhases, (state, action) => {
    sortByOptions(state.stages.review.phases, action.payload);
  });
  builder.addCase(actions.workflowAddSigner, (state, action) => {
    const phase = getSignPhaseById(state, action.payload);
    phase.signers.push(createSigner());
  });
  builder.addCase(actions.workflowAddSigningPhase, (state) => {
    state.stages.sign.phases.push(createSigningPhase());
  });
  builder.addCase(actions.workflowSetSignPhaseName, (state, action) => {
    const { id, title } = action.payload;
    const phase = getSignPhaseById(state, id);
    phase.title = title;
  });
  builder.addCase(actions.workflowDeleteCondition, (state, action) => {
    delete state.conditions[action.payload];
  });
  builder.addCase(actions.workflowSetCondition, (state, action) => {
    state.conditions[action.payload.id] = action.payload;
  });
  builder.addCase(actions.workflowSetPartiesSigners, (state, action) => {
    const oldPartiesIds = Object.keys(state.parties);
    const oldSigners = Object.keys(state.signers);
    state.parties = {};
    state.signers = {};
    action.payload.forEach((party) => {
      const { signers, ...restParty } = party;
      state.parties[restParty.id] = restParty;
      signers.forEach((signer) => {
        state.signers[signer.id] = signer;
      });
    });

    const deletedPartiesIds = oldPartiesIds.filter(
      (id) => !Object.keys(state.parties).includes(id),
    );
    const deletedSignersIds = oldSigners.filter(
      (id) => !Object.keys(state.signers).includes(id),
    );
    [...deletedPartiesIds, ...deletedSignersIds].forEach((id) => {
      Object.keys(state.fieldLinks).forEach((linkId) => {
        const fieldLink = state.fieldLinks[linkId];
        const field = state.fields[linkId];
        if (fieldLink.id === id) {
          if (testCustomField(field)) {
            state.fieldLinks[linkId] = {
              ...fieldLink,
              entity: 'custom',
            };
          } else {
            field.type = undefined;
            delete state.fieldLinks[linkId];
          }
        }
      });
    });
  });
  builder.addCase(actions.workflowSetSignCoordinator, (state, action) => {
    const coordinator = action.payload;
    state.stages.sign.coordinators = coordinator ? [coordinator] : [];
  });
  builder.addCase(actions.workflowSetSigner, (state, action) => {
    const { phaseId, index, signerId, userId, defaultSigner } = action.payload;
    const phase = getSignPhaseById(state, phaseId);
    phase.signers[index].id = signerId;
    if (signerId) {
      state.signers[signerId].userId = userId;
      state.signers[signerId].default = defaultSigner;
    }
  });
  builder.addCase(actions.workflowSetSignerConditionRule, (state, action) => {
    const { index, phaseId, conditionRule } = action.payload;
    const phase = getSignPhaseById(state, phaseId);
    phase.signers[index].conditionRule = conditionRule;
  });
  builder.addCase(actions.workflowSortSignPhases, (state, action) => {
    sortByOptions(state.stages.sign.phases, action.payload);
  });
  builder.addCase(actions.workflowRemoveSigningPhase, (state, action) => {
    const phases = state.stages.sign.phases.filter(
      (phase) => phase.id !== action.payload,
    );
    state.stages.sign.phases = phases;
  });
  builder.addCase(actions.workflowRemoveSigner, (state, action) => {
    const { index, phaseId } = action.payload;
    const phaseToUpdate = state.stages.sign.phases.find(
      (phase) => phase.id === phaseId,
    );
    phaseToUpdate.signers.splice(index, 1);
  });
  builder.addCase(actions.workflowRemoveFormSection, (state, action) => {
    const sections = state.form.sections.filter(
      (section) => section.id !== action.payload,
    );
    state.form.sections = sections;
  });
  builder.addCase(actions.workflowSetFormDescription, (state, action) => {
    const { description } = action.payload;
    state.form.description = description;
  });
  builder.addCase(actions.workflowSetFormSectionName, (state, action) => {
    const { title: sectionName, id: sectionId } = action.payload;
    const section = state.form.sections.find(
      (section) => section.id === sectionId,
    );
    section.name = sectionName;
  });
  builder.addCase(actions.workflowSortFormSections, (state, action) => {
    sortByOptions(state.form.sections, action.payload);
  });
  builder.addCase(actions.workflowCreateFormSection, (state) => {
    state.form.sections.push({
      id: uuid(),
      name: '',
      description: '',
      conditionRule: null,
      position: state.form.sections.length,
      questions: [],
    });
  });
  builder.addCase(actions.workflowSortFormQuestions, (state, action) => {
    const { sectionId, items } = action.payload;
    const section = state.form.sections.find(
      (section) => section.id === sectionId,
    );
    section.questions = items.map((question) => question.value);
  });
  builder.addCase(actions.workflowRemoveQuestion, (state, action) => {
    const { sectionId, questionId } = action.payload;
    const section = state.form.sections.find(
      (section) => section.id === sectionId,
    );
    const questions = section.questions.filter(
      (question) => question.id !== questionId,
    );
    section.questions = questions;
  });
  builder.addCase(actions.workflowSetSectionDescription, (state, action) => {
    const { sectionId, description } = action.payload;
    const section = state.form.sections.find(
      (section) => section.id === sectionId,
    );
    section.description = description;
  });
  builder.addCase(actions.workflowSetSectionQuestion, (state, action) => {
    const { sectionId, data, fieldCustomSettings } = action.payload;
    const section = state.form.sections.find(
      (section) => section.id === sectionId,
    );
    let question = section.questions.find(
      (question) => question.id === data.id,
    );
    Object.keys(data).forEach((key) => {
      question[key] = data[key];
    });
    const { fieldId } = data;
    const field = state.fields[fieldId];
    field.customSettings = fieldCustomSettings;
  });
  builder.addCase(actions.workflowCreateSectionQuestion, (state, action) => {
    const { sectionId, data } = action.payload;
    const section = state.form.sections.find(
      (section) => section.id === sectionId,
    );
    section.questions.push(data);
  });
  builder.addCase(actions.workflowSetFinalizeCoordinator, (state, action) => {
    const coordinator = action.payload;
    state.stages.finalize.coordinators = coordinator ? [coordinator] : [];
  });
  builder.addCase(actions.workflowSetFinalizeTaskName, (state, action) => {
    const { id, title } = action.payload;
    const phase = getFinalizeTaskById(state, id);
    phase.title = title;
  });
  builder.addCase(
    actions.workflowSetFinalizeTaskDescription,
    (state, action) => {
      const { phaseId, value } = action.payload;
      const phase = getFinalizeTaskById(state, phaseId);
      phase.description = value;
    },
  );
  builder.addCase(actions.workflowAddFinalizeApproval, (state, action) => {
    const phase = getFinalizeTaskById(state, action.payload);
    phase.approvals.push(createApproval());
  });
  builder.addCase(actions.workflowAddFinalizeTask, (state) => {
    state.stages.finalize.phases.push(createApprovalPhase());
  });
  builder.addCase(actions.workflowRemoveFinalizeTask, (state, action) => {
    const phases = state.stages.finalize.phases.filter(
      (phase) => phase.id !== action.payload,
    );
    state.stages.finalize.phases = phases;
  });
  builder.addCase(actions.workflowRemoveFinalizeApproval, (state, action) => {
    const { id, phaseId } = action.payload;
    const phase = getFinalizeTaskById(state, phaseId);
    const approvals = phase.approvals.filter((appr) => appr.id !== id);
    phase.approvals = approvals;
  });
  builder.addCase(
    actions.workflowSetFinalizeTaskApprovalTitle,
    (state, action) => {
      const { value, id, phaseId } = action.payload;
      const phase = getFinalizeTaskById(state, phaseId);
      const approval = findById(phase.approvals, id);
      approval.name = value;
    },
  );
  builder.addCase(
    actions.workflowSetFinalizeTaskApprovalDescription,
    (state, action) => {
      const { value, id, phaseId } = action.payload;
      const phase = getFinalizeTaskById(state, phaseId);
      const approval = findById(phase.approvals, id);
      approval.description = value;
    },
  );
  builder.addCase(
    actions.workflowToggleFinalizeConditionRule,
    (state, action) => {
      const { conditionRule, id, phaseId } = action.payload;
      const phase = getFinalizeTaskById(state, phaseId);
      const approval = findById(phase.approvals, id);
      approval.conditionRule = conditionRule;
    },
  );
  builder.addCase(
    actions.workflowToggleFinalizeApprovalType,
    (state, action) => {
      const { approvalType, id, phaseId } = action.payload;
      const phase = getFinalizeTaskById(state, phaseId);
      const approval = findById(phase.approvals, id);
      approval.approvalType = approvalType;
    },
  );
  builder.addCase(actions.workflowSetFinalizeApprovalCount, (state, action) => {
    const { minApprovalCount, id, phaseId } = action.payload;
    const phase = getFinalizeTaskById(state, phaseId);
    const approval = findById(phase.approvals, id);
    approval.minApprovalCount = minApprovalCount;
  });
  builder.addCase(actions.workflowSetFinalizeApprovalOrder, (state, action) => {
    const { orders, phaseId } = action.payload;
    const phase = getFinalizeTaskById(state, phaseId);
    sortByOptions(phase.approvals, orders);
  });
  builder.addCase(
    actions.workflowRemoveFinalizeApprovalCount,
    (state, action) => {
      const { id, phaseId } = action.payload;
      const phase = getFinalizeTaskById(state, phaseId);
      const approval = findById(phase.approvals, id);
      delete approval.minApprovalCount;
    },
  );
  builder.addCase(actions.workflowToggleFinalizeCondition, (state, action) => {
    const { isSpecified, id, phaseId } = action.payload;
    const phase = getFinalizeTaskById(state, phaseId);
    const approval = findById(phase.approvals, id);
    approval.conditionRule = isSpecified ? createConditionRule() : null;
  });
  builder.addCase(actions.workflowSetFinalizeTaskApproval, (state, action) => {
    const { approvers, id, phaseId } = action.payload;
    const phase = getFinalizeTaskById(state, phaseId);
    const approval = findById(phase.approvals, id);
    approval.approvers = approvers;
  });
  builder.addCase(actions.workflowSortFinalizeTasks, (state, action) => {
    sortByOptions(state.stages.finalize.phases, action.payload);
  });
  builder.addCase(actions.workflowSetArchiveLocation, (state, action) => {
    state.settings.archiveLocation = {
      ...state.settings.archiveLocation,
      ...action.payload,
    };
  });
  builder.addCase(
    actions.workflowSetArchiveLocationFilePath,
    (state, action) => {
      state.settings.archiveLocation.pilotFolderId = action.payload;
    },
  );
  builder.addCase(actions.workflowSetDefaultTicketName, (state, action) => {
    state.settings.keepSystemDefaultTicketName = action.payload;
  });
  builder.addCase(actions.workflowSetTicketNameConventions, (state, action) => {
    state.settings.ticketNamingConvention = action.payload;
  });
  // Data Fields Mapping
  builder.addCase(actions.workflowAddDataFieldsMapping, (state) => {
    state.settings.dataFieldsMapping[null] = null;
  });
  builder.addCase(actions.workflowRemoveDataFieldsMapping, (state, action) => {
    delete state.settings.dataFieldsMapping[action.payload];
  });
  builder.addCase(actions.workflowClearFile, (state) => {
    delete state.file;
  });
  builder.addCase(actions.workflowSetDataFieldsMapping, (state, action) => {
    state.settings.dataFieldsMapping = action.payload;
  });
  builder.addCase(actions.workflowSetIsTurnTrackingEnabled, (state, action) => {
    state.settings.isTurnTrackingEnabled = action.payload;
  });

  builder.addCase(actions.workflowSetConditionalTextField, (state, action) => {
    const { id, conditionRule, name } = action.payload;
    const field = state.fields[id];
    field.conditionRule = conditionRule;
    field.name = name;
  });
  builder.addCase(actions.workflowFieldUnassign, (state, action) => {
    const { fieldId } = action.payload;
    const field = state.fields[fieldId];
    if (testCustomField(field)) {
      delete state.fields[fieldId];
    } else if (testESignatureField(field)) {
      field.esignaturePlaceHolder = null;
      field.isEsignatureTag = false;
    } else {
      field.type = null;
    }
    delete state.fieldLinks[fieldId];
  });
  builder.addCase(actions.workflowFieldAssign, (state, action) => {
    const {
      fieldId,
      fieldDefinition,
      fieldName,
      entityId,
      fieldIsEsig,
      fieldIsEsigOptional,
    } = action.payload;
    const { entity, fieldType, key } = fieldDefinition;

    // TODO: move this || higher up.
    state.fieldLinks[fieldId] = {
      entity,
      id: entityId || null,
      key: key || null,
    };
    const field = state.fields[fieldId];
    field.name = fieldName;
    if (field.type && field.type !== fieldType) {
      field.customSettings = getDefaultCustomSettingsByFieldType(fieldType);
    }
    field.type = fieldType;
    field.isEsignatureTag = fieldIsEsig;
    field.isEsignatureTagOptional = fieldIsEsigOptional;
  });
  builder.addCase(actions.workflowCreateCustomField, (state, action) => {
    const { field } = action.payload;
    state.fields[field.id] = field;
  });
  builder.addCase(actions.workflowSetNotifications, (state, action) => {
    state.settings.notifications = action.payload;
  });
  builder.addCase(actions.workflowSetCreatorPermissions, (state, action) => {
    const { stage, values } = action.payload;
    state.settings.permissions.creator[stage.toUpperCase()] = values;
  });
  builder.addCase(actions.workflowSetFullAccessPermissions, (state, action) => {
    state.settings.permissions.fullAccess = action.payload;
  });
  builder.addCase(actions.workflowSetCreatorFullAccess, (state, action) => {
    state.settings.permissions.isCreatorFullAccess = action.payload;
  });
  builder.addCase(actions.workflowSetSettings, (state, action) => {
    state.settings = action.payload;
  });
  builder.addCase(
    actions.workflowUpdatePrefillableIntakeFormLink,
    (state, action) => {
      const { prefilledFormData } = action.payload;
      state.prefilledFormData = prefilledFormData;
    },
  );
  builder.addCase(actions.workflowSetStageConditionRule, (state, action) => {
    const { stage, conditionRule } = action.payload;
    state.stages[stage].conditionRule = conditionRule;
  });
  builder.addCase(actions.workflowToggleSkipStage, (state, action) => {
    const { stage, isSkipped } = action.payload;
    state.stages[stage].isEnabled = !isSkipped;
  });
});

export function getConditionOptions(state) {
  return getOptions(Object.values(state.conditions), 'id', 'name');
}

export function getSignerOptions(state) {
  return Object.keys(state.signers).map((signerId) => {
    const signer = getCoercedSignerById(state, signerId);
    const { name, partyId } = signer;
    const partyType = state.parties[partyId].type;
    return {
      label: `${name} (${
        partyType === WorkflowPartyType.Counterparty
          ? 'Counterparty'
          : 'Internal'
      })`,
      value: signerId,
    };
  });
}

export function getPartiesWithSigners(state) {
  const internal = [];
  const counter = [];
  Object.keys(state.parties).forEach((partyId) => {
    const party = getCoercedPartyById(state, partyId);
    const partyWithSigners = {
      ...party,
      signers: getSignersByPartyId(state, partyId),
    };
    if (partyWithSigners.type === WorkflowPartyType.Counterparty) {
      counter.push(partyWithSigners);
    } else {
      internal.push(partyWithSigners);
    }
  });

  return { internal, counter };
}

export function getParties(state) {
  return Object.values(state.parties);
}

export const getPartyIds = createSelector(getParties, (parties) => {
  const internal = [];
  const counter = [];
  parties.forEach((party) => {
    if (party.type === WorkflowPartyType.Counterparty) {
      counter.push(party.id);
    } else {
      internal.push(party.id);
    }
  });
  return [...internal, ...counter];
});

// coerce name based on index if not available
export function getCoercedPartyById(state, partyId) {
  const party = state.parties[partyId];
  if (!party) {
    return null;
  }
  const { name, type } = party;
  const parties = Object.values(state.parties);
  const index = parties
    .filter((p) => p.type === type)
    .map((p) => p.id)
    .indexOf(partyId);
  return {
    ...party,
    index,
    name: name || getPartyName(type, index),
  };
}

// coerce name based on index if not available
export function getCoercedSignerById(state, signerId) {
  const signer = state.signers[signerId];
  if (!signer) {
    return null;
  }
  const index = Object.values(state.signers)
    .map((signer) => signer.id)
    .indexOf(signerId);
  const { name, partyId } = signer;
  return {
    ...signer,
    index,
    partyType: state.parties[partyId].type,
    name: name || `Signer ${index + 1}`,
  };
}

export function getSignersByPartyId(state, partyId) {
  return Object.keys(state.signers)
    .map((signerId) => getCoercedSignerById(state, signerId))
    .filter((signer) => signer.partyId === partyId);
}

export function getFieldLinksByFieldId(state, fieldId) {
  const { form } = state;

  let length = 0;
  const formItemsMap = {};

  form.sections.forEach((section) => {
    section.questions.forEach((question) => {
      if (question.fieldId === fieldId) {
        const { id, name } = question;
        formItemsMap[id] = { id, name };
        length++;
      }
    });
  });

  return {
    groups: [{ title: 'Form Questions', items: Object.values(formItemsMap) }],
    length,
  };
}

export function getConditionLinks(state, conditionId) {
  const { conditions, fields, form, stages } = state;
  const condition = conditions[conditionId];

  if (!condition) {
    return [];
  }

  let length = 0;
  const formItemsMap = {};
  const signItemsMap = {};
  const reviewItemsMap = {};
  const finalizeItemsMap = {};
  const conditionalTextsMap = {};

  form.sections.forEach((section) => {
    section.questions.forEach((question) => {
      if (question.conditionRule?.id === conditionId) {
        const { id, name } = question;
        if (!formItemsMap[id]) {
          formItemsMap[id] = { id, name };
          length++;
        }
      }
    });
    stages.sign.phases.forEach((phase) => {
      phase.signers.forEach((signer) => {
        if (signer.conditionRule?.id === conditionId) {
          const { id, name } = getCoercedSignerById(state, signer.id);
          if (!signItemsMap[id]) {
            signItemsMap[id] = { id, name };
            length++;
          }
        }
      });
    });
    stages.review.phases.forEach((phase) => {
      phase.approvals.forEach((approval, approvalIndex) => {
        if (approval.conditionRule?.id === conditionId) {
          const { id, name } = approval;
          if (!reviewItemsMap[id]) {
            reviewItemsMap[id] = {
              id,
              name: name || `Review Phase ${approvalIndex + 1}`,
            };
            length++;
          }
        }
      });
    });
    stages.finalize.phases.forEach((phase) => {
      phase.approvals.forEach((approval, approvalIndex) => {
        if (approval.conditionRule?.id === conditionId) {
          const { id, name } = approval;
          if (!finalizeItemsMap[id]) {
            finalizeItemsMap[id] = {
              id,
              name: name || `Finalize Phase ${approvalIndex + 1}`,
            };
            length++;
          }
        }
      });
    });
    Object.values(fields).forEach((field) => {
      if (field.conditionRule?.id === conditionId) {
        const { id, name } = field;
        if (!conditionalTextsMap[id]) {
          conditionalTextsMap[id] = { id, name };
          length++;
        }
      }
    });
  });

  return {
    groups: [
      { title: 'Form Questions', items: Object.values(formItemsMap) },
      { title: 'Reviewers', items: Object.values(reviewItemsMap) },
      { title: 'Signers', items: Object.values(signItemsMap) },
      { title: 'Finalize', items: Object.values(finalizeItemsMap) },
      { title: 'Conditional Texts', items: Object.values(conditionalTextsMap) },
    ],
    length,
  };
}

export function getSidebarConditionItems(state) {
  return Object.values(state.conditions).map((condition) => ({
    ...condition,
    links: getConditionLinks(state, condition.id),
  }));
}

export const excludedFieldTypes = [
  WorkflowFieldType.DateSigned,
  WorkflowFieldType.Signature,
  WorkflowFieldType.ConditionalText,
  WorkflowFieldType.Initials,
];

export const getFieldOptions = (state, enableImplicitFields = false) => {
  const fields = getFields(state);
  const assignableFields = fields.filter((field) => {
    const testValidFieldOption = (field) =>
      field.type && !excludedFieldTypes.includes(field.type);

    const implicitFieldResult =
      enableImplicitFields || !testImplicitField(field);

    return implicitFieldResult && testValidFieldOption(field);
  });
  return getOptions(assignableFields, 'id', 'name');
};

export function getReviewPhaseById(state, id) {
  const { phases } = state.stages.review;
  return phases.find((phase) => phase.id === id);
}

export function getReviewPhases(state) {
  return state.stages.review.phases;
}

export function getSignPhaseById(state, id) {
  const { phases } = state.stages.sign;
  return phases.find((phase) => phase.id === id);
}

export function getSignPhases(state) {
  return state.stages.sign.phases.map((phase, index) => ({
    ...phase,
    title: phase.title || `Signing Phase ${index + 1}`,
    signers: phase.signers.map((signer) => ({
      ...signer,
      ...getCoercedSignerById(state, signer.id),
    })),
  }));
}

export function getFinalizeTasks(state) {
  return state.stages.finalize.phases;
}

export function getFinalizeTaskById(state, id) {
  const { phases } = state.stages.finalize;
  return phases.find((phase) => phase.id === id);
}

export function findById(array, id) {
  return array.find((item) => item.id === id);
}

export function getFields(state) {
  return Object.values(state.fields);
}

export function getFieldLinks(state) {
  return state.fieldLinks;
}

export const getConditionalTextFields = createSelector(getFields, (fields) =>
  fields.filter(testConditionalTextField),
);

export const getContractFields = createSelector(
  [getFields, getFieldLinks],
  (fields, fieldLinks) =>
    fields.filter((field) => testContractField(field, fieldLinks)),
);

export const getUnassignedFields = createSelector(getFields, (fields) =>
  fields.filter(testUnassignedField),
);

export function getFieldById(state, id) {
  return state.fields[id];
}

export function getConditionById(state, id) {
  return state.conditions[id];
}

export function getPartyFieldsByPartyId(state, partyId) {
  const { fields, fieldLinks } = state;
  const partyFields = [];
  Object.keys(fieldLinks).forEach((fieldId) => {
    const field = fields[fieldId];
    const link = fieldLinks[fieldId];
    if (link && link.entity === 'party' && link.id === partyId && field) {
      partyFields.push(field);
    }
  });
  return partyFields;
}

export function getSignerFieldsBySignerId(state, signerId) {
  const { fields, fieldLinks } = state;
  const signerFields = [];
  Object.keys(fieldLinks).forEach((fieldId) => {
    const field = fields[fieldId];
    const link = fieldLinks[fieldId];
    if (link && link.entity === 'signer' && link.id === signerId && field) {
      signerFields.push(field);
    }
  });
  return signerFields;
}

export function getLinkByFieldId(state, fieldId) {
  return state.fieldLinks[fieldId];
}

export const getFieldDefinitionByFieldId = createSelector(
  getLinkByFieldId,
  (link) => {
    if (!link) {
      return null;
    }

    function findFieldDefinition(fieldDefinitions, link) {
      return fieldDefinitions.find(
        (fieldDefinition) => fieldDefinition.key === link.key,
      );
    }

    switch (link.entity) {
      case 'contract':
        return findFieldDefinition(contractFieldDefinitions, link);
      case 'party':
        return findFieldDefinition(partyFieldDefinitions, link);
      case 'signer':
        return findFieldDefinition(signerFieldDefinitions, link);
      default:
        return null;
    }
  },
);

export function getUsedFormFieldIds(state) {
  const { form } = state;
  const usedFormFieldIdsSet = form.sections.reduce((set, section) => {
    section.questions.forEach((question) => {
      const { fieldId } = question;
      if (fieldId) {
        set.add(fieldId);
      }
    });
    return set;
  }, new Set());
  return Array.from(usedFormFieldIdsSet);
}

export function getUsedSignerIds(state) {
  const { stages } = state;
  const usedSignerIds = stages.sign.phases.reduce((set, phase) => {
    phase.signers.forEach((signer) => {
      const { id } = signer;
      if (id) {
        set.add(id);
      }
    });
    return set;
  }, new Set());
  return Array.from(usedSignerIds);
}

export function getSourceErrors() {
  let count = 0;
  return {
    count,
  };
}

export function getFieldErrors(state, builder) {
  const {
    conditions,
    fields,
    fieldLinks,
    signers,
    type: workflowType,
    settings,
  } = state;

  let invalidFieldMappingIds = [];

  const signerFieldLinks = Object.values(fieldLinks).filter(
    (fieldLink) => fieldLink.entity === 'signer',
  );

  let count = 0;

  Object.values(fields).forEach((field) => {
    if (testConditionalTextField(field)) {
      if (
        !field.conditionRule ||
        testInvalidConditionRule(field.conditionRule, conditions)
      ) {
        count++;
      }
    }
    if (testInvalidFieldMapping(field, builder, settings)) {
      invalidFieldMappingIds.push(field.id);
      count++;
    }
  });

  Object.keys(signers).forEach((signerId) => {
    signerFieldDefinitions.forEach((signerFieldDefinition) => {
      const signer = getCoercedSignerById(state, signerId);
      const isRequired = testSignerFieldDefinitionRequired(
        signer,
        signerFieldDefinition,
        workflowType,
      );
      const isLinked = signerFieldLinks.some(
        (signerFieldLink) =>
          signerFieldLink.id === signerId &&
          signerFieldLink.key === signerFieldDefinition.key,
      );
      if (isRequired && !isLinked) {
        count++;
      }
    });
  });

  return {
    count,
    invalidFieldMappingIds,
  };
}

/**
 * A condition is invalid if:
 * - It uses non-implicit fieldIds that have not been assigned.
 * - it contains empty/unassigned values
 */
export function getConditionErrors(state) {
  const { conditions } = state;

  let count = 0;
  let invalidConditionIds = new Set();

  const usedConditionFieldIdMap = Object.values(conditions).reduce(
    (acc, condition) => {
      const { id } = condition;
      const fieldIds = getUniqueFieldsInExpressionTree(
        fromExpression(condition.expression),
      );
      fieldIds.forEach((fieldId) => {
        if (!acc[id]) {
          acc[id] = [];
        }
        acc[id].push(fieldId);
      });
      return acc;
    },
    new Set(),
  );

  const assignedFields = getAssignedFields(state, true);

  function validateValueOptions(assignedField, nodeId, values) {
    if (
      assignedField &&
      (assignedField.type === WorkflowFieldType.MultiSelect ||
        assignedField.type === WorkflowFieldType.SingleSelect) &&
      nodeId === assignedField.id
    ) {
      const optionsMap = get(assignedField, 'customSettings.options');
      if (optionsMap) {
        const optionsValues = optionsMap.map((item) => item.value);
        return values.some((elem) => !optionsValues.includes(elem));
      }
    }
    return false;
  }

  Object.entries(usedConditionFieldIdMap).forEach(
    ([conditionId, usedConditionFieldIds]) => {
      usedConditionFieldIds.forEach((usedConditionFieldId) => {
        const assignedField = assignedFields[usedConditionFieldId];
        if (!assignedField) {
          invalidConditionIds.add(conditionId);
        }

        /** evaluate if a condition is valid for a given field,
         * in this case CONTAINS_ALL is not supported by single select **/
        const condition = conditions[conditionId];
        if (!isConditionFieldValid(condition, assignedField)) {
          invalidConditionIds.add(conditionId);
        }

        // track condition IDs with empty value in field nodes.
        const expressionTree = fromExpression(condition.expression);
        filter(expressionTree, (node) => {
          const operatorDefinition = operatorDefinitions[node.operator];
          let operatorValueCardinality =
            operatorDefinition?.valueCardinality || 0;
          operatorValueCardinality =
            operatorValueCardinality === Infinity
              ? 1
              : operatorValueCardinality;
          const nodeType = node.type;
          const nodeValues = node.values ?? []; // TODO: the null coercion here is to address a bug that is likely upstream (malformed condition expression)
          if (
            nodeType === 'field' &&
            nodeValues.filter((value) => value != null).length <
              operatorValueCardinality
          ) {
            invalidConditionIds.add(conditionId);
          }
          if (
            nodeType === 'field' &&
            validateValueOptions(assignedField, node.fieldId, nodeValues)
          ) {
            invalidConditionIds.add(conditionId);
          }
        });
      });
    },
  );

  count += invalidConditionIds.size;

  return {
    count,
    invalidConditionIds,
  };
}

export function getFormErrors(state) {
  const { conditions, fields, fieldLinks } = state;
  const { form } = state;

  let count = 0;
  let invalidConditionIds = [];
  let invalidFieldIds = [];
  let invalidPhaseIds = [];
  let questionCount = 0;

  if (form.description?.length > CHARACTER_INPUT_LIMIT_SHORT) {
    count++;
  }

  form.sections.forEach((section) => {
    if (section.description?.length > CHARACTER_INPUT_LIMIT_SHORT) {
      count++;
    }

    if (section.name?.length > PHASE_TITLE_CHARACTER_LIMIT) {
      invalidPhaseIds.push(section.id);
      count++;
    }

    if (testInvalidConditionRule(section.conditionRule, conditions)) {
      invalidConditionIds.push(section.conditionRule.id);
      count++;
    }

    section.questions.forEach((question) => {
      if (testInvalidConditionRule(question.conditionRule, conditions)) {
        invalidConditionIds.push(question.conditionRule.id);
        count++;
      }

      // new questions won't have fieldId, so we check it
      const questionFieldCustomSettings =
        fields[question?.fieldId]?.customSettings;
      if (
        !testIsValidDefaultValue(question?.field, questionFieldCustomSettings)
      ) {
        count++;
      }

      const fieldId = question.fieldId;
      if (testInvalidField(fieldId, fields, fieldLinks)) {
        invalidFieldIds.push(question.fieldId);
        count++;
      }

      questionCount++;
    });
  });

  if (questionCount === 0) {
    count++;
  }

  let circularDependencies = testCircularDependencies(state);

  if (circularDependencies) {
    count++;
  }

  let lackCounterpartySignerQuestion = testLackCounterpartySignerQuestion(
    state,
  );

  if (lackCounterpartySignerQuestion) {
    count++;
  }

  return {
    circularDependencies,
    count,
    invalidConditionIds,
    invalidFieldIds,
    questionCount,
    invalidPhaseIds,
    lackCounterpartySignerQuestion,
  };
}

export function getReviewErrors(state, users) {
  const { conditions, stages } = state;
  const { review } = stages;

  let count = 0;
  let invalidConditionIds = [];
  let invalidPhaseIds = [];
  let unspecifiedCoordinator = false;

  review.phases.forEach((phase) => {
    if (phase.description?.length > CHARACTER_INPUT_LIMIT_SHORT) {
      count++;
    }

    if (phase.title?.length > PHASE_TITLE_CHARACTER_LIMIT) {
      invalidPhaseIds.push(phase.id);
      count++;
    }

    phase.approvals.forEach((approval) => {
      if (approval.description?.length > CHARACTER_INPUT_LIMIT_TEMPORARY) {
        count++;
      }
      if (
        testShouldEvaluateStageConditions(
          review.isEnabled,
          review.conditionRule,
        ) &&
        testInvalidConditionRule(approval.conditionRule, conditions)
      ) {
        invalidConditionIds.push(approval.conditionRule.id);
        count++;
      }
      if (!approval.approvers.length) {
        count++;
      }
      if (users && !hasValidApprovalValue(approval, users)) {
        count++;
      }
      if (
        approval.approvalType === WorkflowReviewFinalizeApprovalType.Specific &&
        !approval.approvers.length
      ) {
        count++;
      }
    });
  });

  if (
    !review.isEnabled &&
    testInvalidConditionRule(review.conditionRule, conditions)
  ) {
    count++;
  }

  if (
    (review.isEnabled || (!review.isEnabled && review.conditionRule)) &&
    review.coordinators.length === 0
  ) {
    unspecifiedCoordinator = true;
    count++;
  }

  return {
    count,
    invalidConditionIds,
    invalidPhaseIds,
    unspecifiedCoordinator,
  };
}

export function getFinalizeErrors(state, users) {
  const { conditions, stages } = state;
  const { finalize } = stages;

  let count = 0;
  let invalidConditionIds = [];
  let invalidPhaseIds = [];
  let unspecifiedCoordinator = false;

  finalize.phases.forEach((phase) => {
    if (phase.description?.length > CHARACTER_INPUT_LIMIT_SHORT) {
      count++;
    }

    if (phase.title?.length > PHASE_TITLE_CHARACTER_LIMIT) {
      invalidPhaseIds.push(phase.id);
      count++;
    }

    phase.approvals.forEach((approval) => {
      if (approval.description?.length > CHARACTER_INPUT_LIMIT_SHORT) {
        count++;
      }
      if (
        testShouldEvaluateStageConditions(
          finalize.isEnabled,
          finalize.conditionRule,
        ) &&
        testInvalidConditionRule(approval.conditionRule, conditions)
      ) {
        invalidConditionIds.push(approval.conditionRule.id);
        count++;
      }
      if (!approval.approvers.length) {
        count++;
      }
      if (users && !hasValidApprovalValue(approval, users)) {
        count++;
      }
      if (
        approval.approvalType === WorkflowReviewFinalizeApprovalType.Specific &&
        !approval.approvers.length
      ) {
        count++;
      }
    });
  });

  if (
    !finalize.isEnabled &&
    testInvalidConditionRule(finalize.conditionRule, conditions)
  ) {
    count++;
  }

  if (
    (finalize.isEnabled || (!finalize.isEnabled && finalize.conditionRule)) &&
    finalize.coordinators.length === 0
  ) {
    unspecifiedCoordinator = true;
    count++;
  }

  return {
    count,
    invalidConditionIds,
    invalidPhaseIds,
    unspecifiedCoordinator,
  };
}

export function getSettingsErrors(state, builder, client) {
  const { fields, settings } = state;

  let count = 0;
  let invalidFieldIds = [];

  Object.keys(settings.dataFieldsMapping).forEach((fieldId) => {
    if (
      !fields[fieldId] ||
      testInvalidFieldMapping(fields[fieldId], builder, settings)
    ) {
      invalidFieldIds.push(fieldId);
      count++;
    }
  });

  settings.notifications.forEach((notification) => {
    if (notification.message?.length > CHARACTER_INPUT_LIMIT_SHORT) {
      count++;
    }
  });

  const { archiveLocation, ticketNamingConvention } = settings;
  const { pilotFolderId, fileName, filePath } = archiveLocation;
  const folderPath = getArchiveLocationPath(
    client.folderTree,
    settings.archiveLocation.pilotFolderId,
  );

  // validate ticket archive location folder exists
  if (!pilotFolderId || !folderPath[pilotFolderId]) {
    count++;
  }

  // validate if the fields exists in the archive location sub folder and naming
  if (
    !testValidFieldsForArchiveSubFolder(fileName, fields) ||
    !testValidFieldsForArchiveSubFolder(filePath, fields)
  ) {
    count++;
  }

  // validate if the fields are valid
  if (
    !testValidFieldsInTicketNamingConvention(ticketNamingConvention, fields)
  ) {
    count++;
  }

  // validate ticket naming format field types
  return {
    count,
    invalidFieldIds,
  };
}

export function getSignErrors(state, users) {
  const { conditions, signers } = state;
  const { sign } = state.stages;

  let count = 0;
  let invalidConditionIds = [];
  let invalidPhaseIds = [];
  let invalidSignerIds = [];
  let unspecifiedCoordinator = false;

  if (
    testIsStageEnabledOrIsDisabledWithCondition(
      sign.isEnabled,
      sign.conditionRule,
    ) &&
    sign.coordinators.length === 0
  ) {
    unspecifiedCoordinator = true;
    count++;
  }

  const specifiedSignerIdSet = new Set();
  sign.phases.forEach((phase) => {
    if (phase.title?.length > PHASE_TITLE_CHARACTER_LIMIT) {
      invalidPhaseIds.push(phase.id);
      count++;
    }

    if (testInvalidConditionRule(phase.conditionRule, conditions)) {
      invalidConditionIds.push(phase.conditionRule.id);
      count++;
    }

    phase.signers.forEach((signer) => {
      const signerId = signer.id;
      specifiedSignerIdSet.add(signerId);
      if (testInvalidSigner(signer, signers)) {
        invalidSignerIds.push(signerId);
        count++;
      }

      if (
        testShouldEvaluateStageConditions(sign.isEnabled, sign.conditionRule) &&
        testInvalidConditionRule(signer.conditionRule, conditions)
      ) {
        invalidConditionIds.push(signer.conditionRule.id);
        count++;
      }
    });
  });

  const unspecifiedSignerIds = Object.keys(signers).filter(
    (signerId) => !specifiedSignerIdSet.has(signerId),
  );
  count += unspecifiedSignerIds.length;

  const defaultSigners = Object.values(signers)
    .filter((signer) => signer.default)
    .map((signer) => signer.default);

  if (
    users &&
    !defaultSigners.every((signer) => testValidApprover(signer, users))
  ) {
    count++;
  }

  const doesStageHaveConditionError =
    !sign.isEnabled && testInvalidConditionRule(sign.conditionRule, conditions);
  if (doesStageHaveConditionError) {
    count++;
  }

  return {
    count,
    invalidConditionIds,
    invalidPhaseIds,
    invalidSignerIds,
    unspecifiedCoordinator,
    unspecifiedSignerIds,
  };
}

const getEditErrors = (state) => {
  const { stages, conditions } = state;
  let count = 0;
  // TODO: drop check on stages.edit after feature is released (unfortunately there is no way to perform feature-flag checks in the current feature flag system without knowledge of the currentUser context)
  if (
    stages.edit &&
    !stages.edit.isEnabled &&
    testInvalidConditionRule(stages.edit.conditionRule, conditions)
  ) {
    count++;
  }
  return { count };
};

export function getErrors(workflow, builder, client, users) {
  return {
    source: getSourceErrors(workflow),
    form: getFormErrors(workflow),
    edit: getEditErrors(workflow),
    review: getReviewErrors(workflow, users),
    sign: getSignErrors(workflow, users),
    finalize: getFinalizeErrors(workflow, users),
    settings: getSettingsErrors(workflow, builder, client),
    conditions: getConditionErrors(workflow),
    fields: getFieldErrors(workflow, builder),
  };
}

/** A field is assigned if it is assigned in the Sidebar (i.e. having a valid type) and assigned in Form questions */
export function getAssignedFields(state, includeImplicitFields = false) {
  const { form } = state;
  const formFieldIdSet = form.sections.reduce((acc, section) => {
    section.questions.forEach((question) => {
      const { fieldId } = question;
      if (fieldId) {
        acc.add(fieldId);
      }
    });
    return acc;
  }, new Set());

  return getFields(state).reduce((acc, field) => {
    const { id } = field;
    if (
      formFieldIdSet.has(id) ||
      (includeImplicitFields && testImplicitField(field))
    ) {
      acc[id] = field;
    }
    return acc;
  }, {});
}
