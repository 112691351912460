import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom-v5-compat';

import { DataFieldType } from '~/enums';

import { isEmptyValue } from '../../Shared/EcFilterField/EcFilterField.utils';
import EcTooltip from '../../Shared/EcTooltip';
import styles from './SearchResultsSidebar.styles';

let FILTER_INDEX = 0;

class SearchResultsSidebar extends Component {
  renderEmptyState() {
    const { classes } = this.props;

    return (
      <div className={classes.filtersEmptyState}>
        No filters have been applied.
      </div>
    );
  }

  renderTextSearchEntity(filter) {
    const { classes } = this.props;

    return (
      <div className={classes.filterItem} key={`filter-item-${FILTER_INDEX++}`}>
        <span>{filter.area.label}</span>
        &nbsp;
        {filter.contains.label}
        &nbsp;
        {filter.scope.label}
        &nbsp; &mdash; &nbsp;
        {`“${filter.text}”.`}
      </div>
    );
  }

  renderSectionEntity(filter) {
    const filters = filter.section.flat(Infinity);

    return (
      <Fragment key={`section-${FILTER_INDEX++}`}>
        {this.renderFiltersBody(filters)}
      </Fragment>
    );
  }

  renderProvisionEntity(filter) {
    const { classes } = this.props;

    return (
      <div className={classes.filterItem} key={`filter-item-${FILTER_INDEX++}`}>
        {filter.contains.label}
        &nbsp;
        {filter.entity}
        &nbsp;
        <span>{filter.provision}</span>
        &nbsp;
        {filter.text_search.length ? (
          <Fragment>
            {'and provision '}
            {filter.text_search
              .map((search, index) => (
                <span key={`${search.text}-${index}`}>
                  {search.contains.label}
                  &nbsp;
                  {search.scope.label}
                  &nbsp;
                  {`“${search.text}“`}
                </span>
              ))
              .reduce((prev, curr) => [prev, ' and ', curr])}
          </Fragment>
        ) : null}
        .
      </div>
    );
  }

  renderOperatorEntity(filter) {
    const { classes } = this.props;

    return (
      <div
        className={classes.filterOperator}
        key={`filter-operator-${FILTER_INDEX++}`}
      >
        <div className={classes.operatorSideline} />
        <div className={classes.operatorText}>
          {filter.operator.toUpperCase()}
        </div>
        <div className={classes.operatorSideline} />
      </div>
    );
  }

  renderFolderEntity(filter) {
    const { classes } = this.props;
    const itemIndex = FILTER_INDEX++;

    return (
      <div className={classes.filterItem} key={`filter-item-${itemIndex}`}>
        Search in&nbsp;&mdash;&nbsp;
        {filter.folders
          ?.map((folder, index) => {
            const path = folder.path
              .map((pathFolder) => pathFolder.name)
              .join(' → ');

            return (
              <Fragment key={`folder-name-${index}`}>
                <span data-tip data-for={`folder-name-${index}`}>
                  {folder.name}
                </span>
                <EcTooltip id={`folder-name-${index}`} place="top">
                  {path}
                </EcTooltip>
              </Fragment>
            );
          })
          .reduce((prev, curr) => [prev, ' and ', curr])}
        .
      </div>
    );
  }

  renderFieldArrayMultipleEntity(filter) {
    const { classes } = this.props;

    return (
      <div className={classes.filterItem} key={`filter-item-${FILTER_INDEX++}`}>
        <span>{filter.label}</span>
        &nbsp;
        {filter.first_value ? (
          <Fragment>
            {filter.relation.label}
            &nbsp;&mdash;&nbsp;
            {filter.first_value
              .map((val, index) => (
                <span key={`${val}-${index}`}>{`“${val}“`}</span>
              ))
              .reduce((prev, curr) => [prev, ', ', curr])}
          </Fragment>
        ) : (
          filter.relation.label
        )}
        .
      </div>
    );
  }

  renderFieldArraySingleEntity(filter) {
    const { classes } = this.props;

    return (
      <div className={classes.filterItem} key={`filter-item-${FILTER_INDEX++}`}>
        <span>{filter.label}</span>
        &nbsp;
        {filter.relation.label}
        {filter.first_value ? <span>{` ${filter.first_value}`}</span> : null}.
      </div>
    );
  }

  renderFieldBooleanEntity(filter) {
    const { classes } = this.props;

    return (
      <div className={classes.filterItem} key={`filter-item-${FILTER_INDEX++}`}>
        <span>{filter.label}</span>
        &nbsp;
        {filter.relation ? (
          filter.relation.label
        ) : (
          <Fragment>
            {'equals '}
            <span>{filter.first_value}</span>
          </Fragment>
        )}
        .
      </div>
    );
  }

  renderFieldAttachmentEntity(filter) {
    const { classes } = this.props;

    // TODO: Remove need to have FILTER_INDEX. Also, refactor relation labels to use ES6 string templates
    return (
      <div className={classes.filterItem} key={`filter-item-${FILTER_INDEX++}`}>
        <span>{filter.label}</span>
        &nbsp;
        {filter.relation ? (
          filter.relation.label
        ) : (
          <Fragment>
            {'equals '}
            <span>{filter.first_value}</span>
          </Fragment>
        )}
        .
      </div>
    );
  }

  renderFieldStringEntity(filter) {
    const { classes } = this.props;

    return (
      <div className={classes.filterItem} key={`filter-item-${FILTER_INDEX++}`}>
        <span>{filter.label}</span>
        &nbsp;
        {filter.relation.label}
        {filter.first_value ? <span>{` ${filter.first_value}`}</span> : null}
        {filter.second_value ? (
          <Fragment>
            {' and '}
            <span>{filter.second_value}</span>
          </Fragment>
        ) : null}
        .
      </div>
    );
  }

  renderFieldNumberEntity(filter) {
    const { classes } = this.props;

    return (
      <div className={classes.filterItem} key={`filter-item-${FILTER_INDEX++}`}>
        <span>{filter.label}</span>
        &nbsp;
        {filter.relation.label}
        {!isEmptyValue(filter.first_value) && (
          <span>{` ${filter.first_value}`}</span>
        )}
        {!isEmptyValue(filter.second_value) && (
          <Fragment>
            {' and '}
            <span>{filter.second_value}</span>
          </Fragment>
        )}
        .
      </div>
    );
  }

  renderFieldDateEntity(filter) {
    const { classes } = this.props;

    return (
      <div className={classes.filterItem} key={`filter-item-${FILTER_INDEX++}`}>
        <span>{filter.label}</span>
        &nbsp;
        {filter.relation.label}
        {filter.first_value ? <span>{` ${filter.first_value}`}</span> : null}
        {filter.second_value ? (
          Date.parse(filter.second_value) ? (
            <Fragment>
              {' and '}
              <span>{filter.second_value}</span>
            </Fragment>
          ) : (
            <span>{` ${filter.second_value.toLowerCase()}(s)`}</span>
          )
        ) : null}
        .
      </div>
    );
  }

  renderFieldEntity(filter) {
    switch (filter.type) {
      case DataFieldType.DATE:
        return this.renderFieldDateEntity(filter);
      case DataFieldType.NUMBER:
        return this.renderFieldNumberEntity(filter);
      case DataFieldType.STRING:
        return this.renderFieldStringEntity(filter);
      case DataFieldType.BOOLEAN:
        return this.renderFieldBooleanEntity(filter);
      case DataFieldType.ARRAY_SINGLE:
        return this.renderFieldArraySingleEntity(filter);
      case DataFieldType.ARRAY_MULTIPLE:
        return this.renderFieldArrayMultipleEntity(filter);
      case DataFieldType.ATTACHMENT:
        return this.renderFieldAttachmentEntity(filter);
      default:
        return null;
    }
  }

  renderBooleanTextSearchEntity(filter) {
    const { classes } = this.props;

    return (
      <div className={classes.filterItem} key={`filter-item-${FILTER_INDEX++}`}>
        <span>Boolean Text Search</span>
        &nbsp;&mdash;&nbsp;
        {`“${filter.query}”.`}
      </div>
    );
  }

  renderFiltersBody(data = []) {
    const { classes } = this.props;

    return (
      <div className={classes.filtersBody}>
        {data.map((filter) => {
          switch (filter.entity) {
            case 'bool_text_search':
              return this.renderBooleanTextSearchEntity(filter);
            case 'field':
              return this.renderFieldEntity(filter);
            case 'folder':
              return this.renderFolderEntity(filter);
            case 'operator':
              return this.renderOperatorEntity(filter);
            case 'provision':
              return this.renderProvisionEntity(filter);
            case 'section':
              return this.renderSectionEntity(filter);
            case 'text_search':
              return this.renderTextSearchEntity(filter);
            default:
              return null;
          }
        })}
      </div>
    );
  }

  renderFiltersHeader() {
    const { classes, data } = this.props;
    const filtersCount = data
      ? data.filter((filter) => filter.entity !== 'operator').length
      : 0;

    return (
      <div className={classes.filtersHeader}>
        <div className={classes.filtersTitle}>
          Filters
          <div className={classes.filtersCounter}>{filtersCount}</div>
        </div>
        <Link
          id="analyzer-results-edit-filters-link"
          to="/analyzer"
          className={classes.filtersEdit}
        >
          Edit Filters
        </Link>
      </div>
    );
  }

  render() {
    const { data } = this.props;

    return (
      <Fragment>
        {this.renderFiltersHeader()}
        {data && data.length
          ? this.renderFiltersBody(data)
          : this.renderEmptyState()}
      </Fragment>
    );
  }
}

SearchResultsSidebar.propTypes = {
  data: PropTypes.array.isRequired,
};

export default injectSheet(styles)(SearchResultsSidebar);
