import { noop } from 'lodash';
import React, { useEffect } from 'react';

import { ContextMenu as SharedContextMenu } from '~/components/Shared/ContextMenu';
import { useDocumentEditorContext } from '~/components/Shared/DocumentEditor';
import { Box, useToggle } from '~/eds';
import { useStateAndRef } from '~/hooks';

import { ContextMenuOption } from './types';

export const MIN_TOP_OFFSET = 180;
export const CONTEXT_MENU_INITIAL_POSITION = {
  left: 0,
  offset: 0,
  top: 0,
};

type ContextMenuPosition = {
  left: number;
  offset: number;
  top: number;
};

interface ContextMenuProps {
  options: ContextMenuOption[];
  parentWidth: string;
  onOpen?: () => void;
  /**
   * callback that will provide the start selection and end selection in EJ2 format "section;block;offset", e.g "0;0;0"
   */
  onSelectionFinish?: (selectionStart: string, selectionEnd: string) => void;
}

export const ContextMenu = ({
  options,
  parentWidth,
  onOpen = noop,
  onSelectionFinish,
}: ContextMenuProps) => {
  const { documentEditor, isDocumentReady } = useDocumentEditorContext();
  const [
    isContextMenuVisible,
    _toggleContextMenuVisibility,
    showContextMenu,
    hideContextMenu,
  ] = useToggle(false);
  const [
    contextMenuPosition,
    setContextMenuPosition,
    contextMenuPositionRef,
  ] = useStateAndRef<ContextMenuPosition>(CONTEXT_MENU_INITIAL_POSITION);
  const [
    _onMouseDownPosition,
    setOnMouseDownPosition,
    onMouseDownPositionRef,
  ] = useStateAndRef<ContextMenuPosition>(CONTEXT_MENU_INITIAL_POSITION);

  // reposition the context menu if the editor resizes
  useEffect(() => {
    if (isContextMenuVisible && documentEditor) {
      const xPos =
        documentEditor.documentHelper.currentPage.boundingRectangle.x +
        documentEditor.selection.start.location.x;
      setContextMenuPosition({ ...contextMenuPositionRef.current, left: xPos });
    }
  }, [parentWidth]);

  useEffect(() => {
    if (isDocumentReady) {
      setupMouseEvents();
    }
    return removeMouseEvents;
  }, [isDocumentReady]);

  const handleMouseUp = (ev: MouseEvent) => {
    const isNotCommentMark = !(ev.target as HTMLElement).classList.contains(
      'e-de-cmt-mark-icon',
    );
    if (documentEditor) {
      if (!documentEditor.selection.isEmpty && isNotCommentMark) {
        const isSameLine =
          documentEditor.selection.start.location.y ===
          documentEditor.selection.end.location.y;
        const leftPadding =
          documentEditor.documentHelper.currentPage?.bodyWidgets?.[0]?.x ?? 0;
        const xPos =
          documentEditor.documentHelper.currentPage.boundingRectangle.x +
          (isSameLine
            ? documentEditor.selection.start.location.x
            : leftPadding);
        const yPos = ev.clientY;
        setContextMenuPosition({
          ...onMouseDownPositionRef.current,
          left: xPos,
          top: yPos,
        });
        setOnMouseDownPosition({
          ...onMouseDownPositionRef.current,
          left: xPos,
          top: yPos,
        });
        showContextMenu();
      } else {
        setContextMenuPosition(CONTEXT_MENU_INITIAL_POSITION);
        setOnMouseDownPosition(CONTEXT_MENU_INITIAL_POSITION);
        hideContextMenu();
      }
      if (!documentEditor.selection.isEmpty) {
        const startSelection = documentEditor.selection.startOffset;
        const endSelection = documentEditor.selection.endOffset;
        onSelectionFinish?.(startSelection, endSelection);
      } else {
        onSelectionFinish?.('', '');
      }
    }
  };

  const handleMouseDown = (ev: MouseEvent) => {
    if (documentEditor) {
      hideContextMenu();
      const xPos =
        documentEditor.documentHelper.currentPage.boundingRectangle.x +
        documentEditor.selection.start.location.x;
      setOnMouseDownPosition({
        top: ev.clientY,
        left: xPos,
        offset: documentEditor.viewer.containerTop,
      });
    }
  };

  const handleScroll = () => {
    if (documentEditor) {
      if (contextMenuPositionRef.current.top <= MIN_TOP_OFFSET) {
        hideContextMenu();
      } else {
        showContextMenu();
      }
      const topOffset =
        documentEditor.viewer.containerTop -
        onMouseDownPositionRef.current.offset;
      setContextMenuPosition((prev) => ({
        ...prev,
        top: onMouseDownPositionRef.current.top - topOffset,
      }));
    }
  };

  const setupMouseEvents = () => {
    if (documentEditor && documentEditor.documentHelper) {
      documentEditor.documentHelper.viewerContainer.addEventListener(
        'mouseup',
        handleMouseUp,
      );
      documentEditor.documentHelper.viewerContainer.addEventListener(
        'mousedown',
        handleMouseDown,
      );
      documentEditor.documentHelper.viewerContainer.addEventListener(
        'scroll',
        handleScroll,
      );
    }
  };

  const removeMouseEvents = () => {
    if (documentEditor && documentEditor.documentHelper) {
      documentEditor.documentHelper.viewerContainer.removeEventListener(
        'mouseup',
        handleMouseUp,
      );
      documentEditor.documentHelper.viewerContainer.removeEventListener(
        'mousedown',
        handleMouseDown,
      );
      documentEditor.documentHelper.viewerContainer.removeEventListener(
        'scroll',
        handleScroll,
      );
    }
  };

  const enabledOptions = options.map((option) => {
    if (typeof option === 'function') {
      return option(showContextMenu, hideContextMenu);
    }
    return option;
  });

  return (
    <Box
      position="absolute"
      styles={{ left: contextMenuPosition.left, top: contextMenuPosition.top }}
    >
      <Box position="relative">
        {isContextMenuVisible && (
          <SharedContextMenu options={enabledOptions} onOpen={onOpen} />
        )}
      </Box>
    </Box>
  );
};
