import React, { useState } from 'react';

import { Icon } from '~/eds';
import { FlexLayout, Tabs, Text } from '~/ui';

import BlankFormLink from '../BlankFormLink';

function IntakeFormLink({ intakeFormLinkId }) {
  const [selectedTab, setSelectedTab] = useState('BLANK_FORM_LINK');

  const tabsConfig = {
    BLANK_FORM_LINK: {
      label: 'Blank Form Link',
      value: 'BLANK_FORM_LINK',
      component: <BlankFormLink intakeFormLinkId={intakeFormLinkId} />,
    },
  };

  return (
    <FlexLayout flexDirection="column" space={4}>
      <FlexLayout alignItems="center" space={2} mb={0}>
        <Icon icon="link" color="status.new" />
        <Text color="gray-900" variant="l-dense-bold">
          Intake Form Link
        </Text>
      </FlexLayout>
      <>
        <Tabs
          justifyContent="flex-start"
          selectedTab={selectedTab}
          tabs={Object.values(tabsConfig)}
          onSelectTab={(tab) => setSelectedTab(tab)}
        />
        {tabsConfig[selectedTab].component}
      </>
    </FlexLayout>
  );
}

export default IntakeFormLink;
