/**
 * Returns folder's full path from folder tree given its id
 * @param {string|number} id of the folder
 * @param {Object} folderTree object
 * @returns {object} path to the folder
 */
export function getFullFolderPath(id, folderTree) {
  const folderPaths = {};
  function createPaths(treeItem, path) {
    if (!folderPaths[treeItem.id]) {
      folderPaths[treeItem.id] = path;
      treeItem.children.forEach((item) =>
        createPaths(item, `${path}/${item.name}`),
      );
    }
  }

  createPaths(folderTree, `${folderTree.name}`);
  return { [id]: folderPaths[id] };
}

/**
 * Validates folder path with regex
 * @param {string} path string
 * @return {boolean}
 */
export function validateFolderPath(path) {
  const PATH_REGEX = /^[^/]+(\/[^/]+)+$/;
  return PATH_REGEX.test(path);
}

/**
 * Calculates path for a given folder by its id and nested folder structure
 * @param {Object} folderTree nested structure
 * @param {string|number} folderId
 * @returns {string|null} folder's path as a string
 */
export function getArchiveLocationPath(folderTree, folderId) {
  return Object.values(folderTree).length
    ? getFullFolderPath(folderId, folderTree)
    : null;
}
