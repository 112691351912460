import React from 'react';

import { Variation } from '../../../../types';
import { ClauseVariation } from './ClauseVariation';

interface Props {
  variations: Variation[];
}

export const ClauseVariations = ({ variations }: Props) => {
  return (
    <>
      {variations.map((variation) => (
        <ClauseVariation key={variation.id} variation={variation} />
      ))}
    </>
  );
};
