import {
  black2,
  black3,
  black4,
  bold,
  evisortBlue,
  heavy,
  semibold,
} from '../../../../assets/shared-styles/general';

const styles = {
  activityLog: {
    width: '720px',
  },
  loadingContainer: {
    padding: '32px 0',
    textAlign: 'center',
  },
  activityLogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  activityLogHeaderTitle: {
    color: black2,
    fontSize: '24px',
    fontWeight: heavy,
  },
  activityLogHeaderFilter: {
    display: 'flex',
    alignItems: 'center',
    color: evisortBlue,
    fontSize: '14px',
    fontWeight: bold,
    cursor: 'pointer',
    '& span': {
      margin: '0 4px',
    },
  },
  activityLogSubheader: {
    color: black3,
    fontSize: '12px',
    fontWeight: semibold,
  },
  commentTextarea: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    margin: '12px 0 16px 0',
    backgroundColor: '#fff',
  },
  textareaField: {
    width: '100%',
    border: 'none',
    borderRadius: '4px',
    padding: '12px 16px',
    resize: 'none',
    caretColor: evisortBlue,
    outline: 'none',
    '&::placeholder': {
      color: black4,
      fontSize: '15px',
    },
  },
  textareaActions: {
    display: 'flex',
    alignItems: 'flex-start',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  activityLogList: {
    marginTop: '32px',
  },
  logItem: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '16px 0',
  },
  logContent: {
    marginLeft: '16px',
  },
  logInfo: {
    marginBottom: '4px',
    color: black2,
    fontSize: '15px',
  },
  logDate: {
    color: black3,
    fontSize: '13px',
  },
  logMessage: {
    display: 'inline-block',
    borderRadius: '4px',
    marginTop: '12px',
    padding: '12px 16px',
    backgroundColor: '#f3f3f3',
    color: black2,
    fontSize: '15px',
    lineHeight: '1.47',
    whiteSpace: 'pre-Wrap',
  },
};

export default styles;
