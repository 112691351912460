import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';
import ReactPaginate from 'react-paginate';

import ActionsIcon from '../Icons/ActionsIcon';
import ChevronLeftIcon from '../Icons/ChevronLeftIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import styles from './EcPaginate.styles';

const EcPaginate = (prop) => {
  const { classes, onPageChange, pageCount, forcePage } = prop;

  return (
    <ReactPaginate
      previousLabel={
        <span className={classes.iconButton} title="go to previous page">
          <ChevronLeftIcon />
        </span>
      }
      nextLabel={
        <span className={classes.iconButton} title="go to next page">
          <ChevronRightIcon />
        </span>
      }
      breakLabel={<ActionsIcon />}
      previousLinkClassName={classes.paginationButtonsLinks}
      nextLinkClassName={classes.paginationButtonsLinks}
      disabledClassName={classes.disabledClassName}
      containerClassName={classes.paginationContainer}
      breakClassName={classes.paginationBrake}
      pageClassName={classes.paginationPage}
      pageLinkClassName={classes.paginatonLink}
      activeClassName={classes.paginationActivePage}
      activeLinkClassName={classes.paginatonActiveLink}
      pageCount={pageCount}
      marginPagesDisplayed={3}
      pageRangeDisplayed={2}
      onPageChange={onPageChange}
      forcePage={forcePage}
    />
  );
};

EcPaginate.propTypes = {
  classes: PropTypes.object.isRequired,
  pageCount: PropTypes.number.isRequired,
  forcePage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default injectSheet(styles)(EcPaginate);
