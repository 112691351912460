import { pilot } from '~/services';
import { ApiKey } from '~/types';

export async function getApiKeys(): Promise<ApiKey[]> {
  return await pilot.get('client/api-keys/');
}

export async function createApiKey(): Promise<ApiKey> {
  return await pilot.post('client/api-keys/');
}

export async function deleteApiKey({ id }: { id: string }): Promise<void> {
  return await pilot.remove(`client/api-keys/${id}/`);
}

export async function updateApiKey({
  id,
  data,
}: {
  id: string;
  data: Partial<ApiKey>;
}): Promise<ApiKey> {
  return await pilot.patch(`client/api-keys/${id}/`, data);
}
