import { BarDatum } from '@nivo/bar';
import { truncate } from 'lodash';
import React from 'react';

type Props = {
  //missing nivo bars props typing ... export type BarCustomLayer = (props: any) => React.ReactNode
  bars: any;
  orientation: 'horizontal' | 'vertical';
  onClick: (data: BarDatum) => void;
};
export const DEFAULT_MARGIN_HORIZONTAL = 10;
export const SQUARE_SIZE = 25;
export const WORD_SPACE_VALUE = 3;
export const BarChartLabel = ({ bars, orientation, onClick }: Props) => {
  return bars.map((bar: any) => {
    const {
      key,
      width,
      height,
      x,
      y,
      data: { value },
    } = bar;

    const getLabelTruncationValue = () => {
      if (orientation === 'vertical' && bars.length < 4) {
        return 16;
      } else {
        return 8;
      }
    };
    const truncateValue = truncate(`${value}`, {
      length: getLabelTruncationValue(),
    });

    const horizontalTransform = `translate(${
      width + DEFAULT_MARGIN_HORIZONTAL
    }, ${height / 2 - SQUARE_SIZE / 2})`;
    const horizontalTransformText = `translate(${
      width + SQUARE_SIZE / 2 + DEFAULT_MARGIN_HORIZONTAL
    }, ${height / 2 + 5})`;
    const verticalTransform = `translate(${
      width / 2 - SQUARE_SIZE / 2
    }, -${SQUARE_SIZE})`;

    const verticalTransformText = `translate(${width / 2}, -${
      SQUARE_SIZE / 2.5
    })`;

    return (
      <g key={key} transform={`translate(${x}, ${y})`}>
        <rect
          transform={
            orientation === 'vertical' ? verticalTransform : horizontalTransform
          }
          fill="transparent"
          cursor="pointer"
          width={SQUARE_SIZE}
          height={SQUARE_SIZE}
          onClick={() => {
            onClick(bar.data?.data);
          }}
        ></rect>
        <text
          transform={
            orientation === 'vertical'
              ? verticalTransformText
              : horizontalTransformText
          }
          textAnchor="middle"
          fontSize="11px"
          pointerEvents="none"
          fontFamily="sans-serif"
        >
          {truncateValue}
        </text>
      </g>
    );
  });
};
