import { BEM_REGEXP } from '~/constants/regexp';
import { TestNamespaceType } from '~/enums';

interface Options {
  namespace: TestNamespaceType;
  block: string;
  element?: string;
  modifier?: string;
}

/**
 * Creates a namespaced BEM test ID e.g. workflow/setup__upload-modal--is-active
 * See http://getbem.com/
 */
export const createTestId = ({
  namespace,
  block,
  element,
  modifier,
}: Options): string => {
  if (!Object.values(TestNamespaceType).includes(namespace)) {
    throw new Error(`"${namespace}" is not a valid namespace enum.`);
  }

  if (!block) {
    throw new Error('A block must be specified.');
  }

  let bem = block;
  if (element) {
    bem += `__${element}`;
  }
  if (modifier) {
    bem += `--${modifier}`;
  }

  if (!BEM_REGEXP.test(bem)) {
    throw new Error(`"${bem}" does not comply with BEM convention.`);
  }

  return `${namespace}/${bem}`;
};
