import React from 'react';

import { Select } from '~/eds';

const options = [
  { value: true, label: 'is true' },
  { value: false, label: 'is false' },
];
type AvailableProps =
  | 'disabled'
  | 'error'
  | 'placeholder'
  | 'value'
  | 'width'
  | 'onChange'
  | 'name';
interface Props
  extends Pick<React.ComponentProps<typeof Select>, AvailableProps> {}

function BooleanSelect({
  disabled = false,
  error,
  placeholder,
  value,
  width,
  onChange,
  name,
}: Props) {
  return (
    <Select
      enablePortal={true}
      isMulti={false}
      disabled={disabled}
      error={error}
      isClearable={false}
      options={options}
      placeholder={placeholder}
      value={value}
      width={width}
      onChange={onChange}
      name={name}
    />
  );
}

export default BooleanSelect;
