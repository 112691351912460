import React from 'react';

import { FlagType } from './flags';
import { useFlags } from './useFlags';

type WithFlagsType = {
  flags: { [k in FlagType]: boolean };
};

export const withFlags = <P extends object>(
  ElementComponent: React.ComponentType<P>,
) => (props: P & WithFlagsType) => (
  <ElementComponent {...props} flags={useFlags()} />
);
