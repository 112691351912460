import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout, Icon, Text, Tooltip } from '~/ui';

function Tab({ isSelected, tab, onClick }) {
  const { disabled, iconLeft, iconRight, id, label, tooltip, value } = tab;

  return (
    <Tooltip content={tooltip}>
      <FlexLayout
        disabled={disabled}
        id={id}
        role="tab"
        px={3}
        sx={{ height: 'tab-height', position: 'relative' }}
        onClick={() => onClick(value)}
      >
        <FlexLayout alignItems="center" flex="0 0 auto" space={3}>
          {iconLeft && (
            <Icon
              color={isSelected ? 'black' : 'gray-700'}
              icon={iconLeft}
              size="xs"
            />
          )}
          <Text
            color={isSelected ? 'gray-900' : 'gray-700'}
            variant="xs-dense-bold"
          >
            {label}
          </Text>
          {iconRight}
        </FlexLayout>
        {isSelected && (
          <Box
            bg="gray-800"
            sx={{
              height: '4px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </FlexLayout>
    </Tooltip>
  );
}

export const tabPropType = PT.shape({
  disabled: PT.bool,
  iconLeft: PT.string,
  iconRight: PT.node,
  label: PT.string.isRequired,
  tooltip: PT.string,
  value: PT.any.isRequired,
});

Tab.propTypes = {
  /** Indicates if tab is active */
  isSelected: PT.bool,
  /** Tab Object */
  tab: tabPropType,
  /** Callback function to trigger change of tabs */
  onClick: PT.func,
};

export default Tab;
