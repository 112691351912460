import React, { Fragment } from 'react';

import { Box, Divider, Section } from '~/eds';
import { SnippetPart } from '~/redux/api/methods';

import CollapsibleSnippets from '../CollapsibleSnippets';

type Props = {
  title: string;
  snippets: SnippetPart[][];
  limit?: number;
};
function TextSnippets({ title = '', snippets = [], limit = 300 }: Props) {
  return (
    <Section title={title}>
      {snippets.map((parts, index) => {
        return (
          <Fragment key={index}>
            <Box>
              <CollapsibleSnippets snippets={parts} limit={limit} />
            </Box>
            {index < snippets.length - 1 ? <Divider /> : null}
          </Fragment>
        );
      })}
    </Section>
  );
}

export default TextSnippets;
