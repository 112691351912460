import React, { memo } from 'react';

import { useToggle } from '../../hooks';
import { Input } from '../../types';
import { Box } from '../Box';
import { Divider } from '../Divider';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { TextInput } from '../TextInput';

interface Props extends Input<string> {
  info?: {
    onClick: () => void;
    tooltip: string;
  };
}

export const SearchInput = memo((props: Props) => {
  const {
    disabled,
    error,
    hint,
    info,
    placeholder = 'Search',
    value,
    width,
  } = props;

  const [isFocused, _toggle, setOnFocus, setOffFocus] = useToggle(false);

  return (
    <>
      <Box
        disabled={disabled}
        // @ts-ignore -- uinix type limitation, valid usage
        styles={[componentStyles.container]}
        styleProps={{ hasInfo: Boolean(info) }}
        w={width}
      >
        <TextInput
          {...props}
          placeholder={placeholder}
          type="search"
          onFocus={setOnFocus}
          onBlur={setOffFocus}
        />
        <Box styles={componentStyles.icon}>
          <Icon icon="search" color={isFocused ? 'status.active' : undefined} />
        </Box>
        {info && (
          <Box styles={componentStyles.info}>
            <Divider isHorizontal={false} />
            <Box ml={1}>
              <Icon
                color={isFocused ? 'status.active' : undefined}
                icon="info"
                tooltip={info.tooltip}
                onClick={info.onClick}
              />
            </Box>
          </Box>
        )}
      </Box>
      {hint && value && (
        <Box role="alert" aria-live="polite" mt={2}>
          <Text color="text.secondary" variant="tiny">
            {hint}
          </Text>
        </Box>
      )}
      {error && (
        <Box role="alert" aria-live="polite" mt={2}>
          <Text color="status.danger" variant="tiny">
            {error}
          </Text>
        </Box>
      )}
    </>
  );
});

export const componentStyles = {
  container: ({ hasInfo }: { hasInfo: boolean }) => ({
    position: 'relative',
    '> input': {
      paddingLeft: 8, // hardcoded use of "!important" but still preferable to overloading TextInput
      paddingRight: hasInfo ? 36 : undefined,
    },
  }),
  icon: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    position: 'absolute',
    left: 2,
    top: 0,
  },
  info: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    bottom: 0,
    display: 'flex',
    position: 'absolute',
    right: 0,
    top: 0,
  },
};
