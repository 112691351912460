// eslint-disable-next-line -- ~/flags can import from launchdarkly
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { getEnvVariable } from '~/dev';

import { LD_ALL_USERS_CONTEXT } from './constants';

export const withFlagsProvider = () =>
  asyncWithLDProvider({
    clientSideID: getEnvVariable('LAUNCHDARKLY_CLIENT_SIDE_ID'),
    context: LD_ALL_USERS_CONTEXT,
    options: { streaming: false },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });
