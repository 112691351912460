import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import injectSheet from 'react-jss';

import { validateBooleanQuery } from '~/api/search';
import EcBooleanTextSearchPopup from '~/components/Shared/EcBooleanTextSearchPopup';
import withHover from '~/components/Shared/HOCs/withHover';
import RemoveIcon from '~/components/Shared/Icons/RemoveIcon';
import { Button, FlexLayout, Text } from '~/ui';

import styles from './EcFilterBooleanTextSearch.styles';

const EcFilterBooleanTextSearch = ({
  classes,
  onRemoveClick,
  query,
  onChange,
  error,
}) => {
  const [showSyntaxPopup, setShowSyntaxPopup] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [validationWarning, setValidationWarning] = useState(null);

  const RemoveIconWithHover = withHover(RemoveIcon);

  const handleBooleanInputChange = (e) => {
    const query = e.target.value;
    onChange({ query, entity: 'bool_text_search' });
    validateBooleanQuery(query)
      .then((res) => {
        setValidationError(null);
        setValidationWarning(res.detail);
        onChange({ query, entity: 'bool_text_search', error: false });
      })
      .catch((err) => {
        setValidationError(err?.response?.data?.detail);
        setValidationWarning(null);
        onChange({ query, entity: 'bool_text_search', error: true });
      });
  };

  return (
    <>
      <FlexLayout flexDirection="column" space={2}>
        <FlexLayout
          alignItems="center"
          className={classes.ecFilterFieldHeader}
          justifyContent="space-between"
        >
          <Text color="gray-900" variant="xs-dense-medium">
            BOOLEAN TEXT SEARCH
          </Text>
          <FlexLayout alignItems="center" space={1}>
            <Button
              iconLeft="help"
              text="Help"
              variant="link"
              onClick={() => setShowSyntaxPopup(true)}
            />
          </FlexLayout>
        </FlexLayout>
        <FlexLayout space={2}>
          <div className={classes.removeFieldContainer}>
            <RemoveIconWithHover size="20" onClick={onRemoveClick} />
          </div>
          <FlexLayout
            flexDirection="column"
            className={classes.ecFilterInputContainer}
            space={2}
          >
            <DebounceInput
              debounceTimeout={300}
              placeholder="Type in a search query..."
              className={
                error || validationError
                  ? classes.ecFilterInputError
                  : classes.ecFilterInput
              }
              value={query}
              autoFocus={true}
              onChange={handleBooleanInputChange}
            />
            {validationError && (
              <Text color="red-400" variant="xs-dense-medium">
                {validationError}
              </Text>
            )}
            {validationWarning && (
              <Text color="blue-500" variant="xs-dense-medium">
                {validationWarning}
              </Text>
            )}
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      {showSyntaxPopup && (
        <EcBooleanTextSearchPopup
          handleOnCloseClick={() => setShowSyntaxPopup(false)}
        />
      )}
    </>
  );
};

EcFilterBooleanTextSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default injectSheet(styles)(EcFilterBooleanTextSearch);
