import React, { memo } from 'react';
import { useDropzone } from 'react-dropzone';

import { Input } from '../../types';
import { Box } from '../Box';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Text } from '../Text';

interface Props extends Input<File, true> {
  /** Accepted file types (recommend organizing collections via enums, see https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker) */
  accept?: Record<string, string[]>;
  /** Single vs multiple files */
  isMulti?: boolean;
  /** Max number of files */
  maxFiles?: number;
  /** Max file size (in bytes) */
  maxSize?: number;
  /** Min file size (in bytes) */
  minSize?: number;
  /** UI mode */
  mode?: 'card';
}

export const FileInput = memo(
  ({
    accept = {},
    autoFocus,
    disabled,
    error,
    isMulti,
    maxFiles,
    maxSize,
    minSize,
    mode,
    name,
    onChange,
    placeholder,
    width,
  }: Props) => {
    const handleDrop = <T extends File>(acceptedFiles: T[]) => {
      const nonEmptyFiles = acceptedFiles.filter((file) => file.size > 0);
      return onChange(nonEmptyFiles);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept,
      autoFocus,
      disabled,
      maxFiles,
      maxSize,
      minSize,
      multiple: isMulti,
      onDrop: handleDrop,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(Array.from(event.target.files ?? []));
    };

    const nativeInput = (
      <Box
        {...getInputProps()}
        as="input"
        name={name}
        placeholder={placeholder}
        type="file"
        onChange={handleChange}
      />
    );

    const isCard = mode === 'card';
    const direction = isCard ? 'column' : 'row';
    const iconSize = isCard ? 'l' : 'm';
    const textVariant = isCard ? 'section' : 'body';

    const instructions = isDragActive ? (
      <strong>Drop files here…</strong>
    ) : (
      placeholder ?? (
        <>
          <strong>Drag and drop</strong>{' '}
          <Text color="text.secondary" variant={textVariant}>
            {isMulti ? '' : 'a '}
            {Object.values(accept).flat().join(', ')} file
            {isMulti ? '(s)' : ''}
          </Text>
        </>
      )
    );

    return (
      <div {...getRootProps()}>
        <Layout
          align="center"
          direction={direction}
          disabled={disabled}
          preset="buttons"
          styles={componentStyles.container}
          styleProps={{ error, width }}
        >
          <Icon icon="upload" size={iconSize} />
          <Text flex="auto" variant={textVariant}>
            {instructions}
          </Text>
          <Text color="text.secondary" textTransform="uppercase">
            or
          </Text>
          <Button text="Upload from computer" />
          {nativeInput}
        </Layout>
      </div>
    );
  },
);

const componentStyles = {
  container: {
    backgroundColor: 'background.quiet',
    border: 'border',
    borderRadius: 'm',
    borderStyle: 'dashed',
    paddingBottom: 3,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 3,
  },
};
