export default `
<svg
  aria-hidden="true"
  aria-label="reconnect"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12 6.5C9.5625 6.5 7.5 8.09375 6.78125 10.25C6.625 10.6562 6.21875 10.8438 5.8125 10.7188C5.4375 10.5938 5.21875 10.1562 5.34375 9.78125C6.28125 7 8.90625 5 12 5C14.5312 5 16.75 6.375 18 8.40625V7.25C18 6.84375 18.3125 6.5 18.75 6.5C19.1562 6.5 19.5 6.84375 19.5 7.25V10.25C19.5 10.6875 19.1562 11 18.75 11H15.75C15.3125 11 15 10.6875 15 10.25C15 9.84375 15.3125 9.5 15.75 9.5H16.875C15.9688 7.71875 14.125 6.5 12 6.5ZM12.75 12.25C12.75 12.6875 12.4062 13 12 13C11.5625 13 11.25 12.6875 11.25 12.25V8.75C11.25 8.34375 11.5625 8 12 8C12.4062 8 12.75 8.34375 12.75 8.75V12.25ZM11 15C11 14.4688 11.4375 14 12 14C12.5312 14 13 14.4688 13 15C13 15.5625 12.5312 16 12 16C11.4375 16 11 15.5625 11 15ZM5.25 17.5C4.8125 17.5 4.5 17.1875 4.5 16.75V13.75C4.5 13.3438 4.8125 13 5.25 13H8.25C8.65625 13 9 13.3438 9 13.75C9 14.1875 8.65625 14.5 8.25 14.5H7.09375C8 16.2812 9.84375 17.5 12 17.5C14.4062 17.5 16.4688 15.9375 17.1875 13.7812C17.3438 13.375 17.75 13.1562 18.1562 13.3125C18.5312 13.4375 18.75 13.875 18.625 14.25C17.6875 17.0312 15.0625 19 12 19C9.4375 19 7.21875 17.6562 6 15.625V16.75C6 17.1875 5.65625 17.5 5.25 17.5Z"
    fill="currentcolor"
  />
</svg>
`;
