import {
  black1,
  black2,
  bold,
} from '../../../../../assets/shared-styles/general';

const styles = {
  summaryTable: {
    maxWidth: '900px',
    minWidth: '732px',
    wordBreak: 'break-word',
    tableLayout: 'auto',
    borderCollapse: 'collapse',
    color: black1,
    padding: '20px 24px',
    fontSize: '14px',
    textAlign: 'right',
    '& th': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '20px 24px',
      color: black2,
      fontWeight: bold,
      '&:first-child': {
        width: '35%',
        textAlign: 'left',
      },
      '&:nth-child(2)': {
        width: '10%',
        wordBreak: 'normal',
      },
      '&:nth-child(3)': {
        width: '50%',
      },
    },
    '& td': {
      padding: '20px 24px',
      wordBreak: 'break-word',
    },
    '& tr': {
      padding: '25px 24px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      '& td:nth-child(1)': {
        minWidth: '400px',
      },
      '& td:nth-child(2)': {
        width: 'auto',
        wordBreak: 'normal',
      },
      '& td:nth-child(3)': {
        paddingRight: '8px',
      },
      '& td:nth-child(4)': {
        width: 'auto',
        paddingLeft: '0px',
      },
      '&:last-child': {
        borderBottom: 0,
        '& td:first-child': {
          textAlign: 'left',
        },
        '& td:last-child': {
          padding: '20px 24px',
        },
      },
    },
  },
  headerColumn: {
    backgroundColor: '#4040400d',
  },
  titleContent: {
    fontWeight: bold,
    textAlign: 'left',
  },
  titleDescription: {
    textAlign: 'left',
  },
  titleContentError: {
    fontWeight: bold,
    textAlign: 'left',
    color: '#ff2020b5',
  },
  resultContentError: {
    fontWeight: bold,
    color: '#ff2020b5',
  },
};

export default styles;
