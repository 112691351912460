import React from 'react';

import { formatNumber, FormField, TextArea } from '~/eds';
import { Nullable } from '~/types';

import { GEN_AI_MAX_INSTRUCTIONS_LENGTH } from '../constant';
import { Prompt } from '../types';

interface Props {
  prompt: Prompt;
  onUpdatePrompt: (updatedPrompt: Prompt) => void;
}

export const CreatorPrompt = ({ prompt, onUpdatePrompt }: Props) => {
  const textAreaInputProps = {
    rows: 8,
  };

  const handleUpdateInstructions = (updatedValue: Nullable<string>) => {
    onUpdatePrompt({
      ...prompt,
      instructions: updatedValue ?? '',
    });
  };

  return (
    <FormField<string, false>
      label="Instructions"
      description="What kind of clause do you want us to generate?"
      input={TextArea}
      inputProps={textAreaInputProps}
      name="instructions"
      placeholder={placeholder}
      value={prompt.instructions}
      onChange={handleUpdateInstructions}
      error={
        prompt.instructions.length > GEN_AI_MAX_INSTRUCTIONS_LENGTH
          ? `${formatNumber(prompt.instructions.length)}/${formatNumber(
              GEN_AI_MAX_INSTRUCTIONS_LENGTH,
              {
                unit: 'character',
              },
            )}. Shorten your instructions text.`
          : undefined
      }
      footer={`${formatNumber(prompt.instructions.length)}/${formatNumber(
        GEN_AI_MAX_INSTRUCTIONS_LENGTH,
        {
          unit: 'character',
        },
      )}`}
      required
    />
  );
};

const placeholder =
  "Sample instructions:\nWrite an indemnification clause that includes reasonable fees and expenses; sellers' indemnification obligations should extend to all affiliates or agents; it should survive the termination of the agreement; and indemnification exceptions for negligence, unlawful actions, and bad faith are acceptable.";
