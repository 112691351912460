import { api } from '../createApi';
import { TagType } from '../TagType';

export const TAG_BY_CREDIT_SUMMARY = {
  type: TagType.CodeCash,
  id: 'CREDIT_SUMMARY',
};

api.enhanceEndpoints({
  addTagTypes: [TagType.CodeCash as TagType],
  endpoints: {
    getCreditSummary: {
      providesTags: [TAG_BY_CREDIT_SUMMARY],
    },
    createOneTimeCreditBalance: {
      invalidatesTags: [TAG_BY_CREDIT_SUMMARY],
    },
    editOneTimeCreditBalance: {
      invalidatesTags: [TAG_BY_CREDIT_SUMMARY],
    },
    updateRecurringCredits: {
      invalidatesTags: [TAG_BY_CREDIT_SUMMARY],
    },
  },
});
