import { useEffect, useState } from 'react';

import { User } from '~/components/Shared/User';
import {
  Box,
  ContentContainer,
  ErrorPage,
  Layout,
  PageOverlay,
  Text,
} from '~/eds';
import { useTrackDocumentEditors } from '~/features/document';
import { api } from '~/redux';
import { useRouting } from '~/routing';

function WordOnlinePage() {
  const { params } = useRouting();
  const { fileId = '' } = params;
  const [, , versionId] = fileId.split('_');
  const [
    getWopiSetup,
    {
      data: wopiSetup,
      isLoading: isWopiSetupLoading,
      isError: isWopiSetupError,
    },
  ] = api.endpoints.getWopiSetup.useLazyQuery();

  const [showWordIframe, setShowWordIframe] = useState(false);
  const [isLoading, setIsLoading] = useState(isWopiSetupLoading);
  const [isConfigError, setIsConfigError] = useState(false);

  const {
    editors: ej2Editors,
    isReady: isEj2EditorsReady,
  } = useTrackDocumentEditors({
    documentEditor: 'ej2',
    versionId,
  });

  const { edit, stopEdit } = useTrackDocumentEditors({
    documentEditor: 'wopi',
    versionId,
  });

  const canUseWopi = isEj2EditorsReady && ej2Editors.size === 0;

  useEffect(() => {
    if (canUseWopi) {
      getWopiSetup({ fileId });
    }
  }, [canUseWopi]);

  useEffect(() => {
    if (wopiSetup) renderWopiFrame();
  }, [wopiSetup]);

  useEffect(() => {
    return () => {
      stopEdit();
    };
  }, []);

  const renderWopiFrame = () => {
    const form = document.getElementById('office_form') as HTMLFormElement;
    const iframe = document.getElementById('office_frame') as HTMLIFrameElement;

    if (!form || !iframe || !wopiSetup) {
      setIsLoading(false);
      setIsConfigError(true);
      return;
    }

    const { url, accessToken, accessTokenTtl } = wopiSetup;

    if (!url || !accessToken) {
      setIsLoading(false);
      setIsConfigError(true);
      return;
    }

    edit();
    form.action = url;
    form.access_token.value = accessToken;
    form.access_token_ttl.value = accessTokenTtl;
    form.submit();
    setShowWordIframe(true);
    setIsLoading(false);
  };

  if (!canUseWopi || isWopiSetupError || isConfigError) {
    return (
      <Box py={16}>
        {!canUseWopi ? (
          <Layout direction="column">
            <Text variant="title" textAlign="center">
              The document is being edited
            </Text>
            <Text variant="body" textAlign="center">
              The document is being edited by{' '}
              <User mode="name" id={ej2Editors.values().next().value} /> in
              another editor. Please try again later.
            </Text>
          </Layout>
        ) : (
          <ErrorPage
            preset={isConfigError ? 'page-error' : 'service-unavailable'}
          />
        )}
      </Box>
    );
  }

  return (
    <PageOverlay isFullPage isVisible disableHideOnEscape={false}>
      <ContentContainer
        loadingContent={{
          isLoading: isLoading,
          message: 'Loading Word for the web...',
        }}
      />

      <form
        id="office_form"
        name="office_form"
        target="office_frame"
        method="post"
      >
        <input name="access_token" type="hidden" />
        <input name="access_token_ttl" type="hidden" />
      </form>

      <span id="frameholder">
        <iframe
          style={{
            ...WordOnlinePageStyle.iframe,
            position: 'absolute',
            display: showWordIframe ? 'block' : 'none',
          }}
          allowFullScreen
          id="office_frame"
          name="office_frame"
          title="Word for the web"
          sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
        ></iframe>
      </span>
    </PageOverlay>
  );
}

const WordOnlinePageStyle = {
  iframe: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    border: 'none',
  },
};

export default WordOnlinePage;
