// Generator: https://noeldelgado.github.io/shadowlord (%20)
const palette = {
  black: '#000000',
  transparent: 'transparent',
  white: '#FFFFFF',
  'blue-100': '#F2F8FB',
  'blue-200': '#E1F2FA',
  'blue-500': '#0C78B8',
  'gray-100': '#FAFAFA',
  'gray-200': '#F5F5F5',
  'gray-300': '#EDEDED',
  'gray-400': '#E0E0E0',
  'gray-500': '#BDBDBD',
  'gray-600': '#808080',
  'gray-700': '#404040',
  'gray-800': '#262626',
  'gray-900': '#111111',
  'green-100': '#EFFCF5',
  'green-200': '#DAF5E6',
  'green-400': '#52B982',
  'green-500': '#0DA64E',
  'peach-500': '#FFAE85',
  'peach-800': '#F48F5D',
  'peach-850': '#F09F69',
  'plum-500': '#6853BB',
  'red-100': '#FFEBE8',
  'red-400': '#FF5D52',
  'red-600': '#F33D37',
  'red-700': '#9E0A00',
  'red-800': '#900500',
  'teal-500': '#009F7D',
  'yellow-100': '#FFF8EB',
  'yellow-200': '#FFEBC2',
  'yellow-500': '#FFC449',
};

const alphas = {
  'black-alpha-02': 'rgba(0, 0, 0, 0.02)',
  'black-alpha-04': 'rgba(0, 0, 0, 0.04)',
  'black-alpha-10': 'rgba(0, 0, 0, 0.1)',
  'black-alpha-20': 'rgba(0, 0, 0, 0.2)',
  'black-alpha-25': 'rgba(0, 0, 0, 0.25)',
  'black-alpha-50': 'rgba(0, 0, 0, 0.50)',
  'black-alpha-75': 'rgba(0, 0, 0, 0.75)',
  'blue-alpha-05': 'rgba(0, 124, 186, 0.05)',
  'blue-alpha-25': 'rgba(0, 124, 186, 0.25)',
  'red-alpha-25': 'rgba(255, 93, 82, 0.25)',
  'white-alpha-10': 'rgba(255, 255, 255, 0.1)',
  'white-alpha-50': 'rgba(255, 255, 255, 0.5)',
};

export default {
  alphas,
  palette,
};
