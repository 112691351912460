import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import styles from './EcToggle.styles';

const EcToggle = (props) => {
  const { id, classes, active, onClick } = props;
  const sliderClassName = active ? classes.toggleOn : classes.toggleOff;

  return (
    <button
      id={id}
      className={sliderClassName}
      role="switch"
      aria-checked={active}
      onClick={onClick}
    >
      <div className={classes.toggleButton} />
    </button>
  );
};

EcToggle.defaultProps = {
  active: false,
  onClick: () => {},
};

EcToggle.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'm']),
  onClick: PropTypes.func,
};

export default injectSheet(styles)(EcToggle);
