import { useDispatch } from 'react-redux';

import { ticketActivitiesSet } from '~/actions';
import { getActivities } from '~/api';
import { EntityType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { TAG_BY_ACTIVITY_LOG } from '~/redux/api/endpoints/tickets';
import { Uuid } from '~/types';

interface Props {
  ticketId: Uuid;
}
export function useActivityLogUpdater({ ticketId }: Props) {
  const dispatch = useDispatch();
  const isActivityLogPanelEnabled = useFlag(FlagType.ActivityLogPanel);

  async function updateActivityLog() {
    if (isActivityLogPanelEnabled) {
      dispatch(api.util.invalidateTags([TAG_BY_ACTIVITY_LOG]));
    } else {
      const activities = await getActivities({
        entityId: ticketId,
        entityType: EntityType.Ticket,
        status: null,
      });
      dispatch(ticketActivitiesSet(activities));
    }
  }

  return updateActivityLog;
}
