import React from 'react';
import { MultiValueProps } from 'react-select';

import { Option } from '../../../types';
import { Box } from '../../Box';

export const MultiValueContainer = (props: MultiValueProps<Option>) => {
  return (
    <Box maxW="100%" py={0.5}>
      {props.children}
    </Box>
  );
};
