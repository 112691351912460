import { FieldData } from './types';

export const initialFieldData: FieldData = {
  defaultValue: null,
  docRestriction: 'NONE',
  docTypes: [],
  fieldConfig: {
    enableMultiple: false,
    optionsConfig: [],
    optionValues: [],
  },
  fieldName: null,
  fieldSectionId: null,
  fieldType: null,
  helpText: '',
  showOnUploadForm: false,
};
