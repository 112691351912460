import { WorkflowFieldType } from '~/enums';
import { hasCircularDefinitions } from '~/reducers/intakeForm/conditions';
import { testConditionalTextField } from '~/utils/workflow';

export function getFieldColor(field) {
  return field.placeholder ? 'teal-500' : 'peach-500';
}

export function getFieldTypeIcon(field) {
  switch (field.type) {
    case WorkflowFieldType.Address:
      return 'fieldAddress';
    case WorkflowFieldType.Boolean:
      return 'fieldDropdown';
    case WorkflowFieldType.Checkbox:
      return 'fieldMultipleChoice';
    case WorkflowFieldType.Date:
      return 'fieldDate';
    case WorkflowFieldType.DateSigned:
      return 'fieldDate';
    case WorkflowFieldType.Department:
      return 'department';
    case WorkflowFieldType.Email:
      return 'fieldEmail';
    case WorkflowFieldType.File:
      return 'fieldAttachment';
    case WorkflowFieldType.Initials:
      return 'signature';
    case WorkflowFieldType.LongResponse:
      return 'fieldParagraph';
    case WorkflowFieldType.MonetaryValue:
      return 'fieldMonetary';
    case WorkflowFieldType.MultiSelect:
      return 'fieldMultipleChoice';
    case WorkflowFieldType.Number:
      return 'fieldNumber';
    case WorkflowFieldType.Percentage:
      return 'fieldPercentage';
    case WorkflowFieldType.Radio:
      return 'fieldRadio';
    case WorkflowFieldType.ShortResponse:
      return 'fieldShortResponse';
    case WorkflowFieldType.Signature:
      return 'signature';
    case WorkflowFieldType.SingleSelect:
      return 'fieldDropdown';
    case WorkflowFieldType.TimePeriod:
      return 'fieldTimePeriod';
    case WorkflowFieldType.User:
      return 'user';
    default:
      return testConditionalTextField(field) ? 'help' : 'warning';
  }
}

export const PROCESSING = 'Processing';

export function testCircularDependencies(workflow) {
  const {
    conditions,
    form: { sections },
  } = workflow;

  const questions = sections.reduce((questions, section) => {
    questions.push(...section.questions);

    return questions;
  }, []);

  const fields = questions
    .map((q) => {
      const { fieldId: id, conditionRule } = q;

      return { id, conditionRule };
    })
    .reduce((obj, f) => {
      obj[f.id] = f;

      return obj;
    }, {});

  return hasCircularDefinitions(conditions, fields);
}

export function testPhaseNonEmpty(phase) {
  return phase.judgments.length > 0 || phase.risks.length > 0;
}

export function testValidApprover(approver, users) {
  const hasValidUser = approver.type === 'user' && !!users[approver.id];
  const hasValidRole = approver.type === 'user' && !!approver.role;
  const hasValidDepartment = approver.type === 'department' && !!approver.id;
  return hasValidUser || hasValidRole || hasValidDepartment;
}

export function hasValidApprovalValue(approval, users) {
  if (approval.approvers.length === 0) return true;
  return approval.approvers.every((approver) =>
    testValidApprover(approver, users),
  );
}

export const getEntityAndContext = (documentVersion) => {
  let entity = null;
  let context = [];
  if (documentVersion) {
    entity = {
      id: documentVersion.id,
      type: 'document_version',
    };

    context.push({
      query: [],
      label: 'This Document Version',
      title: documentVersion.fileName,
      icon: documentVersion.file_type === '.pdf' ? 'file-pdf' : 'file-doc',
      primary: true,
    });
  }
  return { entity, context };
};

export const parseDataFieldSections = (data) => {
  const dataFields = {};
  if (!data) return [];
  data.forEach((section) => {
    section.doc_info_fields.forEach((field) => {
      dataFields[field.id] = {
        id: field.id,
        fieldType: field.field_type,
        label: field.label,
        position: field.position,
        sectionId: section.id,
      };
    });
  });
  return dataFields;
};
