import { DataFieldType } from '~/enums';
import { DocumentInformationField } from '~/features/search';
import { pilot } from '~/services';
import { PilotId } from '~/types';

type DocumentInformationFieldResponse = {
  allow_new_options: boolean;
  help_text: string;
  id: PilotId;
  is_beta: boolean;
  is_editable: boolean;
  is_nullable: boolean;
  keep_old_values: boolean;
  label: string;
  type: DataFieldType;
  values: unknown[];
};
type Response = {
  fields: DocumentInformationFieldResponse[];
};

export const searchV2BulkEditData = async (): Promise<
  DocumentInformationField[]
> => {
  const fieldsResponse: Response = await pilot.put(
    'document/bulk-edit-options/',
    {},
  );
  return mapResponse(fieldsResponse);
};

const mapResponse = (response: Response): DocumentInformationField[] =>
  response.fields.map((field) => ({
    allowNewOptions: field.allow_new_options,
    helpText: field.help_text,
    id: field.id,
    isBeta: field.is_beta,
    isEditable: field.is_editable,
    isNullable: field.is_nullable,
    keepOldValues: field.keep_old_values,
    label: field.label,
    type: field.type,
    values: field.values,
  }));
