import { trimStart } from 'lodash';

import { types } from '~/eds';

export const fileTypesToChips = (
  fileTypes: string[],
): types.SharedChipProps[] =>
  fileTypes.map((fileType) => ({
    text: trimStart(fileType, '.'),
  }));
