import React from 'react';

import { SharedStepsProps } from '../../../../types';
import { Layout } from '../../../Layout';
import { Instruction } from './Instruction';
import { StepSummary } from './StepSummary';

interface Props {
  index: number;
  step: SharedStepsProps['steps'][0];
  onSelectStep: (updatedStepId: string) => void;
  activeInstructionId?: string;
  isActive?: boolean;
  isCompleted?: boolean;
  mode?: 'summary';
}

export const Step = ({
  activeInstructionId,
  index,
  isActive,
  isCompleted,
  mode,
  step,
  onSelectStep,
}: Props) => {
  if (mode === 'summary') {
    return (
      <StepSummary
        index={index}
        isActive={isActive}
        isCompleted={isCompleted}
        mode="summary"
        step={step}
        onSelectStep={onSelectStep}
      />
    );
  }

  return (
    <Layout direction="column">
      <StepSummary
        index={index}
        isActive={isActive}
        isCompleted={isCompleted}
        step={step}
        onSelectStep={onSelectStep}
      />
      {isActive &&
        step.instructions.map((instruction) => (
          <Instruction
            key={instruction.id}
            instruction={instruction}
            isActive={activeInstructionId === instruction.id}
          />
        ))}
    </Layout>
  );
};
