import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom-v5-compat';

import { black4 } from '../../../assets/shared-styles/general';
import { documentTypeIcon } from '../../DocumentsPage/Document.utils';
import EcModalCard from '../../Shared/EcModalCard';
import ChevronRightIcon from '../../Shared/Icons/ChevronRightIcon';
import styles from './MultipleLocationsModal.styles';

class MultipleLocationsModal extends Component {
  constructor(props) {
    super(props);

    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  handleLinkClick(document) {
    const { hideModal, multipleLocationsCallback } = this.props;
    hideModal();
    multipleLocationsCallback?.(document); //eslint-disable-line no-unused-expressions
  }

  renderDocumentPath(path) {
    return path.map((folder, folderIndex) => (
      <Fragment key={`${folder.id}-${folderIndex}`}>
        <span>{folder.name}</span>
        {path.length - 1 !== folderIndex && (
          <ChevronRightIcon size="16" color={black4} />
        )}
      </Fragment>
    ));
  }

  renderDocumentItems() {
    const { classes, documents, openDocumentInNewTab } = this.props;

    return documents.map((document, index) => (
      <Fragment key={`${document.document_name}-${index}`}>
        <div className={classes.documentItem}>
          {documentTypeIcon(document.file_type)}
          <div className={classes.documentInfo}>
            <div className={classes.documentItemName}>
              {document.document_name}
            </div>
            <div className={classes.documentPath}>
              {this.renderDocumentPath(document.path)}
            </div>
          </div>
          <Link
            className={classes.linkButton}
            target={openDocumentInNewTab ? '_blank' : '_self'}
            to={`/document/${document.id}`}
            onClick={() => this.handleLinkClick(document)}
          >
            View
          </Link>
        </div>
        {documents.length - 1 !== index && (
          <hr className={classes.documentItemSeparator} />
        )}
      </Fragment>
    ));
  }

  render() {
    const { classes, documentName, hideModal } = this.props;

    return (
      <EcModalCard
        title="Document Found in Multiple Locations"
        content={
          <div>
            <div className={classes.modalInfo}>
              We found identical copies of the{' '}
              <span className={classes.documentName}>{documentName}</span>{' '}
              document in multiple locations. Please select which one you would
              like to view.
            </div>
            <div className={classes.modalBodyContent}>
              {this.renderDocumentItems()}
            </div>
          </div>
        }
        hideModal={hideModal}
      />
    );
  }
}

MultipleLocationsModal.propTypes = {
  documentName: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number,
      document_name: PropTypes.string,
      path: PropTypes.array,
      visibility_level: PropTypes.array,
      file_type: PropTypes.string,
    }),
  ),
  multipleLocationsCallback: PropTypes.func,
};

export default injectSheet(styles)(MultipleLocationsModal);
