import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { showToast } from '~/components/Shared/EcToast';
import {
  MAX_RETRIES_TO_FETCH_IMPORT_JOBS,
  POLLING_INTERVAL,
} from '~/constants/bulkImportPollingSettings';
import { CodedErrorCodeType } from '~/enums';
import { api } from '~/redux';
import { ERROR } from '~/types/toast.types';
import {
  parseCurrentAndPreviousFinalImportJobs,
  parseInitialAndFinalImportJobs,
} from '~/utils';
import { parseApiError } from '~/utils/parseApiError';

const jobsKindValues = {
  Users: 'user',
  DocumentMetadata: 'documentMetadata',
};

const ImportJobsPolling = ({
  clientId,
  importedJobId = null,
  jobsKind,
  children,
  nullifyImportedJobIdProp,
}) => {
  const [stopPolling, setStopPolling] = useState(false);

  const refStoreToAvoidRerenderingLoop = useRef({
    totalRetries: 0,
    currentImportJobsData: {
      initialStatusJobs: [],
      finalStatusJobs: [],
    },
  });

  let {
    totalRetries,
    currentImportJobsData,
  } = refStoreToAvoidRerenderingLoop.current;
  const persistTotalRetriesInRef = (totalRetries) =>
    (refStoreToAvoidRerenderingLoop.current.totalRetries = totalRetries);
  const persistCurrentImportJobsDataInRef = (currentImportJobsData) =>
    (refStoreToAvoidRerenderingLoop.current.currentImportJobsData = currentImportJobsData);

  useEffect(() => {
    setStopPolling(false);
  }, [importedJobId]);

  const getImportJobsHook =
    jobsKind === jobsKindValues.Users
      ? api.useGetUserImportJobQuery
      : api.useGetDocumentMetadataImportJobQuery;

  const importStatusResponse = getImportJobsHook(
    { clientId, importId: importedJobId },
    {
      skip: stopPolling || isNaN(Number(clientId)),
      pollingInterval: POLLING_INTERVAL,
    },
  );

  const checkIfPollingShouldBeStopped = () => {
    const { initialStatusJobs } = currentImportJobsData;
    if (initialStatusJobs.length === 0) {
      stopImportJobsPolling();
    }
  };

  const updateFinalImportJobs = (acknowledgedImporId) => {
    const { finalStatusJobs } = currentImportJobsData;
    const filteredFinalStatusJobs = finalStatusJobs.filter(
      ({ importId }) => importId !== acknowledgedImporId,
    );

    currentImportJobsData = {
      ...currentImportJobsData,
      finalStatusJobs: filteredFinalStatusJobs,
    };
    persistCurrentImportJobsDataInRef(currentImportJobsData);
  };

  const handleAcknowledgeImportJobSuccessUpdate = ({ acknowledgedImporId }) => {
    const { initialStatusJobs } = currentImportJobsData;

    if (!initialStatusJobs.length) {
      nullifyImportedJobIdProp(acknowledgedImporId);
      setStopPolling(false);
    } else {
      updateFinalImportJobs(acknowledgedImporId);

      const { refetch } = importStatusResponse;
      refetch();
    }
  };

  const handleErrorResponse = (errorResponse) => {
    totalRetries++;
    persistTotalRetriesInRef(totalRetries);

    if (totalRetries > MAX_RETRIES_TO_FETCH_IMPORT_JOBS) {
      stopImportJobsPolling();

      const error = parseApiError(
        errorResponse,
        CodedErrorCodeType.BulkImportErrorFetchingJobs,
      );
      showToast(ERROR, error.message);
    }
  };

  const handleSuccessResponse = (isFetching, importJobsData = []) => {
    if (!isFetching) {
      totalRetries = 0;
      persistTotalRetriesInRef(totalRetries);
      currentImportJobsData = parseImportJobsData(importJobsData);
      persistCurrentImportJobsDataInRef(currentImportJobsData);
      checkIfPollingShouldBeStopped();
    }
  };

  const parseImportJobsData = (incomingImportJobs) => {
    const {
      initialStatusJobs,
      finalStatusJobs,
    } = parseInitialAndFinalImportJobs(incomingImportJobs);

    if (importedJobId) {
      const {
        finalStatusJobs: previousFinalStatusJobs,
      } = currentImportJobsData;
      const currentFinalStatusJobs = parseCurrentAndPreviousFinalImportJobs(
        initialStatusJobs,
        finalStatusJobs,
        previousFinalStatusJobs,
      );

      return { initialStatusJobs, finalStatusJobs: currentFinalStatusJobs };
    }

    return { initialStatusJobs, finalStatusJobs };
  };

  const parseImportStatusResponse = ({
    data: incomingImportJobs,
    isFetching,
    isError,
    error,
  }) => {
    if (stopPolling) return;

    if (isError) {
      handleErrorResponse(error);
    } else {
      handleSuccessResponse(isFetching, incomingImportJobs);
    }
  };

  const stopImportJobsPolling = () => {
    setStopPolling(true);
  };

  parseImportStatusResponse(importStatusResponse);

  const { initialStatusJobs, finalStatusJobs } = currentImportJobsData;

  return (
    <>
      {React.cloneElement(children, {
        importJobsData: [...initialStatusJobs, ...finalStatusJobs],
        clientId: clientId,
        handleAcknowledgeImportJobSuccessUpdate: handleAcknowledgeImportJobSuccessUpdate,
      })}
    </>
  );
};

ImportJobsPolling.propTypes = {
  clientId: PropTypes.number.isRequired,
  importedJobId: PropTypes.string,
  jobsKind: PropTypes.oneOf(Object.values(jobsKindValues)).isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  handleAcknowledgeImportJob: PropTypes.func.isRequired,
};

export default ImportJobsPolling;
