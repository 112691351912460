import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';

import { UploadState as State } from './types';

export const initialState: State = {
  activeFile: null,
  selectedFiles: [],
  configuration: {
    enableSettings: false,
    fields: [],
  },
};

const setConfigurationReducer = (
  state: State,
  action: PayloadAction<State['configuration']>,
) => {
  state.configuration = action.payload;
};

export default createSlice({
  name: SliceType.Upload,
  initialState,
  selectors: {
    selectActiveFile: ({ activeFile, selectedFiles }: State) => ({
      activeFile,
      selectedFiles,
    }),
    selectConfiguration: (state: State) => state.configuration,
    selectUploadConfiguration: ({
      activeFile,
      selectedFiles,
      configuration,
    }: State) => {
      return {
        activeFile,
        selectedFiles,
        configuration,
      };
    },
  },
  reducers: {
    resetFileConfiguration: (state: State) => {
      state.activeFile = null;
      state.selectedFiles = [];
      state.configuration = initialState.configuration;
    },
    setActiveFile: (
      state: State,
      action: PayloadAction<State['activeFile']>,
    ) => {
      state.activeFile = action.payload;
    },
    setFields: (
      state: State,
      action: PayloadAction<State['configuration']['fields']>,
    ) => {
      state.configuration.fields = action.payload;
    },
    setSelectedFiles: (
      state: State,
      action: PayloadAction<State['selectedFiles']>,
    ) => {
      state.selectedFiles = action.payload;
    },
    setConfiguration: setConfigurationReducer,
  },
});
