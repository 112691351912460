import { saveAs } from 'file-saver';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSegment } from '~/components/SegmentAnalytics';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { formatDate } from '~/eds';
import { FileMimeType, HttpStatusCodeType } from '~/enums';
import { selectors } from '~/redux';
import search from '~/redux/slices/search';
import { MODAL_EXCEL_EXPORT } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';

const ExportSearchResults = ({
  onActionComplete,
  searchResult = {},
  onExport,
  exportResult,
}) => {
  const modal = useSelector(selectors.selectSearchResultModal);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(search.actions.setModal());
  const pilotSearchQuery = useSelector(selectors.selectPilotSearchQuery);
  const { modalType } = modal || {};

  const onSuccess = (res) => {
    let excelMessage = 'Your results have started exporting. ';
    if (res.status === HttpStatusCodeType.Ok) {
      showToast(SUCCESS, excelMessage);
      const blob = new Blob([res.data], { type: FileMimeType.Xlsx });
      const dateString = formatDate(new Date(), 'iso_datetime');
      saveAs(blob, `${dateString} analyzer.xlsx`);
    } else if (res.status === HttpStatusCodeType.Accepted) {
      showToast(
        SUCCESS,
        excelMessage + 'You will receive an email when the export is complete.',
      );
    }
  };

  const onError = () => {
    showToast(ERROR, 'Error exporting data, contact support@evisort for help.');
  };

  const { data, isSuccess, isError } = exportResult;

  useEffect(() => {
    if (isSuccess) {
      onSuccess(data);
      onActionComplete();
      hideModal();
    }
    if (isError) {
      onError(data);
      onActionComplete();
      hideModal();
    }
  }, [data, isSuccess, isError]);

  if (modalType !== MODAL_EXCEL_EXPORT) return null;

  const handleExport = (exportSetting) => {
    const requestData = { query: pilotSearchQuery };

    trackSegment('Exporting excel', requestData);

    // according to the documentation for getTimezoneOffset(), pt (-7) would be 7 instead
    const offsetFromUTCInHour = -(new Date().getTimezoneOffset() / 60);
    const formData = exportSetting
      ? { ...requestData, offsetFromUTCInHour, exportSetting }
      : { ...requestData, offsetFromUTCInHour };

    onExport(formData);
  };

  const resultCount = searchResult.count;
  const totalDocsIncludingDups = searchResult.totalDocsIncludingDups;

  return (
    <EcModal
      modalType={modalType}
      width="800px"
      analyzerSearchQuery={pilotSearchQuery}
      title="Search Result Excel Export Settings"
      totalDocs={resultCount}
      totalDups={totalDocsIncludingDups}
      onExport={handleExport}
      hideModal={hideModal}
    />
  );
};

export default ExportSearchResults;
