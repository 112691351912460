import { ExcelExportTabType } from '~/enums';
import { getItemValue } from '~/utils/array';
import { getObjectFlattenedValue } from '~/utils/object';

export const isOneBooleanTrue = (...booleanSet) =>
  booleanSet.some((item) => !!item);

export const areAllBooleansTrue = (...booleanSet) =>
  booleanSet.every((item) => !!item);

function getAllFilteredFieldValueArray(array) {
  const getArray = () => {
    let results = [];
    array.forEach((item) => {
      results.push(item.fields.map((f) => f.value));
    });
    return results.flat();
  };
  return !array ? [] : getArray();
}

export function getSelectAllCheckboxStatus(
  selectedTab,
  filteredExcelExportSettingData,
  formData,
  setAllSelected,
) {
  let allSelected;
  const someFieldsSelected = getAllFilteredFieldValueArray(
    filteredExcelExportSettingData.fields,
  ).some((f) => getObjectFlattenedValue(formData.fields).includes(f));
  const someProvionsSelected =
    filteredExcelExportSettingData.provisions?.length > 0 &&
    getItemValue(filteredExcelExportSettingData.provisions).some((item) =>
      formData.provisions.includes(item),
    );
  const someTablesSelected =
    filteredExcelExportSettingData.tables?.length > 0 &&
    getItemValue(filteredExcelExportSettingData.tables).some((item) =>
      formData.tables.includes(item),
    );

  const allFieldsSelected = getAllFilteredFieldValueArray(
    filteredExcelExportSettingData.fields,
  ).every((f) => getObjectFlattenedValue(formData.fields).includes(f));
  const allProvionsSelected =
    !filteredExcelExportSettingData.provisions?.length ||
    (filteredExcelExportSettingData.provisions?.length > 0 &&
      getItemValue(filteredExcelExportSettingData.provisions).every((item) =>
        formData.provisions.includes(item),
      ));
  const allTablesSelected =
    !filteredExcelExportSettingData.tables?.length ||
    (filteredExcelExportSettingData.tables?.length > 0 &&
      getItemValue(filteredExcelExportSettingData.tables).every((item) =>
        formData.tables.includes(item),
      ));

  switch (selectedTab) {
    case ExcelExportTabType.Fields:
      allSelected = allFieldsSelected || (someFieldsSelected ? null : '');
      break;
    case ExcelExportTabType.Provisions:
      allSelected = allProvionsSelected || (someProvionsSelected ? null : '');
      break;
    case ExcelExportTabType.Tables:
      allSelected = allTablesSelected || (someTablesSelected ? null : '');
      break;
    default:
      const partiallySelected = isOneBooleanTrue(
        someFieldsSelected,
        someProvionsSelected,
        someTablesSelected,
      );
      allSelected =
        areAllBooleansTrue(
          allFieldsSelected,
          allProvionsSelected,
          allTablesSelected,
        ) || (partiallySelected ? null : '');
  }
  setAllSelected(allSelected);
}
