export enum AribaFieldSubType {
  BigDecimalValue = 'bigDecimalValue',
  BooleanValue = 'booleanValue',
  DateValue = 'dateValue',
  FlexMasterDataType = 'flexMasterDataType',
  IntValue = 'intValue',
  MoneyValue = 'moneyValue',
  MultiLineTextValue = 'multiLineTextValue',
  NumberValue = 'numberValue',
  TextMultiSelectValue = 'textMultiSelectValue',
  TextValue = 'textValue',
  UserMultiSelectValue = 'userMultiSelectValue',
}
