import { format, Locale } from 'date-fns';

interface Options {
  locale?: Locale;
}

export const formatCaption = (date: Date, options?: Options) =>
  format(date, 'MMM y', options);

export const formatMonthCaption = (date: Date, options?: Options) =>
  format(date, 'MMM', options);
