import { useEffect, useRef, useState } from 'react';

/**
 * Provides a ref to be used around an HTMLElement and extracts the element.textContent to a state variable.  Useful to imperatively retrieve textContent from React elements (not supported in native React APIs).
 */
export const useTextContent = <E extends HTMLElement>(): [
  React.RefObject<E>,
  string,
] => {
  const ref = useRef<E>(null);
  const [textContent, setTextContent] = useState('');

  useEffect(() => {
    const element = ref.current;
    if (element) {
      setTextContent(element.textContent || '');
    }
  }, [ref]);

  return [ref, textContent];
};
