import { black1, bold } from '../../../assets/shared-styles/general';

const styles = {
  ecModalCardWrapper: {
    position: 'relative',
    display: 'inline-block',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    maxWidth: '100%',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  ecModalCardTitleWrapper: {
    padding: '19px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px 4px 0 0',
    backgroundColor: '#fafafa',
  },
  ecModalCardTitle: {
    display: 'flex',
    alignItems: 'center',
    color: black1,
    fontFamily: 'OatmealPro',
    fontSize: '16px',
    fontWeight: bold,
    '& svg': {
      cursor: 'pointer',
    },
  },
  ecModalCardTitleControl: {
    display: 'flex',
  },
  ecModalCardContent: {
    padding: '24px',
    maxWidth: '100%',
    fontSize: '15px',
  },
  ecModalCardFooter: {
    padding: '15px 24px 16px',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    '& button': {
      marginLeft: '15px',
    },
  },
};

export default styles;
