import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchableFields } from '~/api';
import GroupDocumentsTable from '~/components/GroupDocumentsTable';
import { getHandlersKeyFromDocumentGroup } from '~/components/SearchV2/SearchResult/SearchResult.utils';
import { getSearchableFields } from '~/components/SearchV2/utils';
import { useDocumentMultipleLocationsModal } from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { TableContextType, TableViewContextType } from '~/enums';
import { useTableSettings } from '~/hooks';
import { api, selectors } from '~/redux';
import documentGroup from '~/redux/slices/documentGroup';
import {
  MODAL_DOCUMENT_GROUP_RELATION,
  MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
  MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
  MODAL_REMOVE_DOCUMENT_IN_GROUP,
} from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { DEFAULT_TABLE_SETTINGS } from '~/utils/table';

import RemoveDocumentInGroupModal from '../RemoveDocumentInGroupModal';
import GroupDocumentsLoadView from './GroupDocumentsLoadView';
import GroupDocumentsSaveView from './GroupDocumentsSaveView';
import LinkToParent from './LinkToParent';

function GroupDocuments({ groupId, numberOfDocs, hasEditPermission = true }) {
  const dispatch = useDispatch();

  const [
    setDocumentSelected,
    renderMultipleLocationModal,
  ] = useDocumentMultipleLocationsModal(getHandlersKeyFromDocumentGroup);

  const [
    unlinkDocumentsFromGroup,
    unlinkDocsResult,
  ] = api.endpoints.unlinkDocumentsFromGroup.useMutation();
  const {
    isError: isUnlinkDocsError,
    isSuccess: isUnlinkDocsSuccess,
  } = unlinkDocsResult;

  const tableRef = useRef(null);

  const {
    data: tableViewSet,
    isFetching: isFetchingTableViewSet,
  } = api.endpoints.getCurrentTableView.useQuery({
    context: TableViewContextType.GROUP,
  });

  const {
    data: filterSections,
    isSuccess: isGetFilterSectionsSuccess,
    isFetching: isFetchingFilterSections,
  } = api.endpoints.getFilterSections.useQuery(undefined);

  const showSaveView = useCallback((groupData) => {
    dispatch(
      documentGroup.actions.setModal({
        modalType: MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
        context: { ...groupData },
      }),
    );
  }, []);

  const showLoadView = useCallback((groupDocuments) => {
    dispatch(
      documentGroup.actions.setModal({
        modalType: MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
        context: { ...groupDocuments },
      }),
    );
  }, []);

  const columnOrder = useSelector(selectors.selectDocGroupColumnOrder);
  const columnWidths = useSelector(selectors.selectDocGroupColumnWidths);
  const tableState = { columnOrder };

  const {
    tableSettings = {
      columnOrder,
      columnWidths,
      pageSize: DEFAULT_TABLE_SETTINGS.pageSize,
      sortBy: DEFAULT_TABLE_SETTINGS.sortBy,
    },
    updateTableSettings,
  } = useTableSettings(TableContextType.GroupDocuments);

  const setColumnOrderAndQueryFields = (updatedColumnOrder) => {
    dispatch(documentGroup.actions.setColumnOrder(updatedColumnOrder));

    updateTableSettings({
      ...tableSettings,
      columnOrder: updatedColumnOrder,
    });

    const isDataFieldId = (columnKey) =>
      !!filterSections?.fields[columnKey] &&
      SearchableFields.DocumentGroup !== columnKey;

    const toDataField = (columnKey) => filterSections?.fields[columnKey];

    const queryFields = updatedColumnOrder
      .filter(isDataFieldId)
      .map(toDataField);

    dispatch(documentGroup.actions.setQueryFields(queryFields));
  };

  const handleLoadView = (id) => {
    dispatch(documentGroup.actions.setCurrentTableView(id));
  };

  const [updateTableView] = api.endpoints.updateTableView.useMutation();

  const updateCurrentTableView = (columnOrder) => {
    const context = TableViewContextType.GROUP;
    const fields = getSearchableFields(columnOrder);

    updateTableView({ context, fields });
  };

  const handleColumnOrderChange = (updatedColumnOrder, actionMeta) => {
    setColumnOrderAndQueryFields(updatedColumnOrder, actionMeta);

    updateCurrentTableView(updatedColumnOrder);
  };

  const showLinkToParent = useCallback((state) => {
    dispatch(
      documentGroup.actions.setModal({
        modalType: MODAL_DOCUMENT_GROUP_RELATION,
        context: { ...state },
      }),
    );
  }, []);

  const unlinkFromParent = useCallback(
    (innerTableState) => {
      const { isAllRowsSelected, selectedRowIds } = innerTableState;
      unlinkDocumentsFromGroup({
        groupId,
        documentGroupIds: isAllRowsSelected ? undefined : selectedRowIds,
        selectAll: isAllRowsSelected,
      });
    },
    [groupId],
  );

  const stickyColumnOrder = ['name'];

  useEffect(() => {
    if (tableViewSet && isGetFilterSectionsSuccess) {
      dispatch(documentGroup.actions.setTableViewSet(tableViewSet));
      const defaultColumnOrder = tableViewSet
        .map((column) => column.field_id)
        .map(String);
      setColumnOrderAndQueryFields(
        [...stickyColumnOrder, ...defaultColumnOrder],
        { action: 'select-option' },
      );
    }
  }, [tableViewSet, isGetFilterSectionsSuccess]);

  const handleSetColumnOrder = () => {
    dispatch(documentGroup.actions.setTableViewSet(tableViewSet));
    const currentColumnOrder = tableViewSet
      .map((column) => column.field_id)
      .map(String);
    const defaultColumnOrder = [...stickyColumnOrder, ...currentColumnOrder];
    dispatch(documentGroup.actions.initColumnOrder(defaultColumnOrder));
    handleColumnOrderChange(defaultColumnOrder, { action: 'select-option' });
  };

  const onLinkToParentComplete = () => {
    handleSetColumnOrder();
    tableRef?.current?.clearSelectedRows();
  };

  useEffect(() => {
    if (isUnlinkDocsSuccess) {
      handleSetColumnOrder();
      tableRef?.current?.clearSelectedRows();
      showToast(SUCCESS, 'Documents have been detached from parent.');
    }
  }, [isUnlinkDocsSuccess]);

  useEffect(() => {
    if (isUnlinkDocsError) {
      showToast(
        ERROR,
        'It was not possible to detach the documents from the parent, try again later.',
      );
    }
  }, [isUnlinkDocsError]);

  const isLoading = isFetchingTableViewSet || isFetchingFilterSections;

  const handleUpdate = (state, action) => {
    if (action?.type === 'columnDoneResizing') {
      dispatch(
        documentGroup.actions.patchColumnWidths(
          state.columnResizing.columnWidths,
        ),
      );
    }
  };

  const queryFields = useSelector(selectors.selectDocGroupQueryFields);

  const dataFields = filterSections?.dataFields;

  const handleRemove = useCallback((documentRecordInGroup) => {
    dispatch(
      documentGroup.actions.setModal({
        modalType: MODAL_REMOVE_DOCUMENT_IN_GROUP,
        context: { ...documentRecordInGroup },
      }),
    );
  }, []);

  const rowActions = [
    {
      condition: (row) => !!row.handlers,
      label: 'Remove file from group',
      onClick: handleRemove,
    },
  ];

  return (
    <>
      <GroupDocumentsTable
        ref={tableRef}
        enableSelectRows={hasEditPermission}
        columnWidths={columnWidths}
        dataFields={dataFields}
        groupId={groupId}
        isLoading={isLoading}
        onNameClick={setDocumentSelected}
        onLoadView={showLoadView}
        onSaveView={showSaveView}
        onSetColumnOrder={handleColumnOrderChange}
        onShowLinkToParent={showLinkToParent}
        onUnlinkFromParent={unlinkFromParent}
        onUpdate={handleUpdate}
        rowActions={hasEditPermission ? rowActions : []}
        stickyColumnOrder={stickyColumnOrder}
        tableState={tableState}
        numberOfDocs={numberOfDocs}
        queryFields={queryFields}
      />
      <LinkToParent groupId={groupId} onComplete={onLinkToParentComplete} />
      <RemoveDocumentInGroupModal />
      <GroupDocumentsSaveView />
      <GroupDocumentsLoadView onViewSelected={handleLoadView} />
      {renderMultipleLocationModal()}
    </>
  );
}

export default GroupDocuments;
