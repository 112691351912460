export default `
<svg
  aria-hidden="true"
  aria-label="field-email"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M4 8C4 6.90625 4.875 6 6 6H18C19.0938 6 20 6.90625 20 8V16C20 17.125 19.0938 18 18 18H6C4.875 18 4 17.125 4 16V8ZM5.5 8V8.71875L10.875 13.125C11.5312 13.6562 12.4375 13.6562 13.0938 13.125L18.5 8.71875V8C18.5 7.75 18.25 7.5 18 7.5H6C5.71875 7.5 5.5 7.75 5.5 8ZM5.5 10.6562V16C5.5 16.2812 5.71875 16.5 6 16.5H18C18.25 16.5 18.5 16.2812 18.5 16V10.6562L14.0625 14.2812C12.8438 15.2812 11.125 15.2812 9.90625 14.2812L5.5 10.6562Z"
    fill="currentcolor"
  />
</svg>
`;
