import { isEqual } from 'lodash';
import { Node } from 'slate';

/**
 * TODO: This module should be implemented as a shared package by the ContentAst/weaver domain.
 *
 * Exact utils and specs to be confirmed by ContentAst/weaver domain.
 */
import { AnyNode, ContentAst, Entity, MentionNode, Uuid } from '../types';

interface Options {
  testNode?: (node: AnyNode) => boolean;
}

/**
 * Visit each node of the ast
 */
export const visit = (
  ast: ContentAst,
  cb: (node: AnyNode) => void,
  options: Options = {},
) => {
  const { testNode = (_node: AnyNode) => true } = options;
  ast.forEach((node) => {
    cb(node);
    if ('children' in node && testNode(node)) {
      visit(node.children, cb, options);
    }
  });
};

/*
  Extracts a mention entities.
*/
export const extractMentionEntities = (ast: ContentAst, entity: Entity) => {
  const entities = new Set<Uuid>();

  visit(ast, (node) => {
    const mentionNode = node as MentionNode;
    if (
      mentionNode.type &&
      mentionNode.type === 'mention' &&
      mentionNode.entity.type === entity.type
    ) {
      entities.add(mentionNode.entity.id);
    }
  });

  return entities;
};

/**
 * Serializes the AST to a simple string.
 */
export const serializeContentAst = (ast: ContentAst): string => {
  return ast.map((node) => Node.string(node)).join('\n');
};

/**
 * Parses a simple text to a simple AST with a single `p` and text node.
 */
export const parseContentAst = (text: string): ContentAst => {
  return text.split('\n').map((subtext) => ({
    type: 'p',
    children: [
      {
        text: subtext,
      },
    ],
  }));
};

const emptyAst: ContentAst = [
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
];

/**
 * Tests if the AST is empty.
 */
export const testIsEmptyAst = (ast: ContentAst) =>
  ast.length === 0 || isEqual(ast, emptyAst);

/**
 * Coerces the AST to `emptyAst` if no nodes are found, otherwise returns the AST.
 */
export const coerceAst = (ast: ContentAst): ContentAst => {
  return testIsEmptyAst(ast) ? emptyAst : ast;
};

/**
 * Resets and returns the `emptyAst`.
 */
export const resetAst = () => emptyAst;
