import { identity } from 'lodash';

/**
 * Renames an oldKey to a newKey while preserving other properties and sort order of the object.
 * @param {string} oldKey old key name
 * @param {string} newKey new key name
 * @param {object} object JS object
 * @returns {object} object with renamed key
 */
export function renameKey(oldKey, newKey, object) {
  return Object.keys(object).reduce((acc, key) => {
    if (key === oldKey) {
      acc[newKey] = object[oldKey];
    } else {
      acc[key] = object[key];
    }
    return acc;
  }, {});
}

/**
 * Reduces an array into an object based on provided item key.
 */
export function fromArray(array, key) {
  return array.reduce((object, item) => {
    if (item.hasOwnProperty(key)) {
      object[item[key]] = item;
    }
    return object;
  }, {});
}

export function tryDeserializeJsonObject(jsonString) {
  try {
    const output = JSON.parse(jsonString);
    if (typeof output === 'object' && output) {
      return output;
    }
  } catch (e) {
    return null;
  }
}

export function combineValueInObject(obj1, obj2) {
  for (const key in obj1) {
    if (obj2[key]) {
      obj1[key] = [...new Set([...obj1[key], ...obj2[key]])];
    }
  }
  return obj1;
}

export function excludeValueInObject(obj1, obj2) {
  for (const key in obj1) {
    if (obj2[key]) {
      obj1[key] = obj1[key].filter((v) => !obj2[key].includes(v));
    }
  }
  return obj1;
}

export function getObjectFlattenedValue(obj) {
  return Object.values(obj).flat();
}

export const getValidEntity = (entityMap, entityIds) =>
  entityIds.map((id) => entityMap[id]).filter(identity);
