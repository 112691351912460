import {
  black2,
  black3,
  black5,
  evisortBlue,
  evisortRed,
} from '~/assets/shared-styles/general';

const stylesFactory = (hasError) => ({
  container: (styles, state) => {
    return {
      ...styles,
      width: state.selectProps.width,
    };
  },
  clearIndicator: (styles) => {
    return {
      ...styles,
      color: black3,
      cursor: 'pointer',
      '&:hover': { color: black2 },
    };
  },
  control: (styles, state) => {
    return {
      ...styles,
      height: state.selectProps.height || '42px',
      borderColor: state.isFocused
        ? evisortBlue
        : hasError
        ? evisortRed
        : 'rgba(0, 0, 0, 0.2)',
      borderWidth: state.isFocused ? 2 : 1,
      color: black2,
      fontSize: '15px',
      boxShadow: 'none',
      cursor: 'pointer',
      caretColor: evisortBlue,
      '&:hover': {
        borderColor: evisortBlue,
      },
      '@media (prefers-contrast: more)': {
        outline: state.isFocused ? 'auto' : null,
      },
    };
  },
  dropdownIndicator: () => {
    return {
      padding: '8px',
      color: black2,
    };
  },
  loadingIndicator: (styles) => {
    return {
      ...styles,
      '& span': {
        backgroundColor: evisortBlue,
      },
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      borderRadius: '4px',
    };
  },
  option: (styles, state) => {
    const { isFocused, isSelected, isDisabled } = state;

    return {
      ...styles,
      backgroundColor: isSelected || isFocused ? evisortBlue : '#fff',
      color: isSelected || isFocused ? '#fff' : isDisabled ? black5 : black2,
      fontSize: '15px',
      cursor: 'pointer',
      // react-select has no focus-visible, and isFocused has effect in light mode.
      // Therefore, to provide an outline for dark mode, theme detection is the best option for now
      '@media (prefers-contrast: more)': {
        outline: isFocused ? 'auto' : null,
      },
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: hasError ? evisortRed : black3,
    };
  },
});

export default stylesFactory;
