import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { getAllUsersForAdmins } from '~/api';

import EcMultiSelect from '../../Shared/EcMultiSelect/EcMultiSelect';
import EcUserNameSelectValueWithControls from '../EcUserNameSelectValueWithControls';
import styles from './EcUserNameSearch.styles';

class EcUserNameSearch extends Component {
  constructor(props) {
    super(props);

    this.renderUserNameInput = this.renderUserNameInput.bind(this);
    this.updateUserPrivilege = this.updateUserPrivilege.bind(this);

    this.state = {
      userAccounts: [],
      selectedAccounts: this.props.userAccessList,
    };
  }

  UNSAFE_componentWillMount() {
    this.loadClientUsers();
  }

  loadClientUsers() {
    const { client } = this.props;

    getAllUsersForAdmins(client)
      .then((res) => {
        if (res) {
          const listUserNames = res
            .filter((usr) => usr.role === 4)
            .map((user) => {
              return {
                label: `${user.first_name} ${user.last_name}`,
                value: `${user.first_name} ${user.last_name}`,
                user_id: user.id,
                user_name: `${user.first_name} ${user.last_name}`,
              };
            });
          this.setState({
            userAccounts: listUserNames,
            loadingUsers: false,
            errorLoadingUsers: false,
          });
        }
      })
      .catch(() => {
        this.setState({ loadingUsers: false, errorLoadingUsers: true });
      });
  }

  handleAddUser(users) {
    const { updateUserPrivilege } = this.props;

    this.setState({ selectedAccounts: [...users] });
    updateUserPrivilege(users);
  }

  handleRemoveUser(user) {
    const { updateUserPrivilege } = this.props;
    const { selectedAccounts } = this.state;
    const newSelectedAccounts = selectedAccounts.filter(
      (account) => account.value !== user.value,
    );

    this.setState({ selectedAccounts: newSelectedAccounts });
    updateUserPrivilege(newSelectedAccounts);
  }

  updateUserPrivilege(userAndAccessSettings) {
    const { updateUserPrivilege } = this.props;
    const { selectedAccounts } = this.state;
    const newValues = selectedAccounts.map((account) =>
      Object.assign(
        account,
        [userAndAccessSettings].find((user) => user.value === account.value),
      ),
    );

    updateUserPrivilege(newValues);
  }

  renderUserNameInput() {
    const { classes } = this.props;
    const { selectedAccounts, userAccounts } = this.state;

    const usersItems = userAccounts.map((account) => ({
      label: account.label,
      value: account.value,
      user_id: account.user_id,
      id: account.user_id,
      can_download: false,
      can_edit: true,
      can_view: false,
    }));

    return (
      <div className={classes.accountUserNameSearch}>
        <div className="select">
          <EcMultiSelect
            data-testid="user-name-search-by-client-id"
            value={selectedAccounts}
            options={usersItems}
            onChange={(items) => this.handleAddUser(items)}
            noOptionsMessage="No user found"
            placeholder="Search for user..."
            openMenuOnClick={true}
          />
        </div>
      </div>
    );
  }

  renderUserNamesSelected() {
    const { selectedAccounts } = this.state;

    const selectedUsrs =
      selectedAccounts && selectedAccounts.length
        ? selectedAccounts.map((usr) => {
            return {
              user_id: usr.user_id,
              user_name: usr.user_name,
              label: usr.label,
              value: usr.value,
              folder_id: usr.folder_id,
              visibility_level: usr.visibility_level || 'OPEN',
            };
          })
        : [];

    if (selectedUsrs.length) {
      return selectedUsrs.map((list) => (
        <EcUserNameSelectValueWithControls
          accountUser={list}
          key={`${list.value}`}
          updateUserPrivilege={this.updateUserPrivilege}
          closeHandler={() => this.handleRemoveUser(list)}
        />
      ));
    } else {
      return null;
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.userNameSearchContainer}>
          <div className={classes.inputFieldHeader}>USERS</div>
          {this.renderUserNameInput()}
          {this.renderUserNamesSelected()}
        </div>
      </div>
    );
  }
}

EcUserNameSearch.propTypes = {
  client: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  confirmButtonIcon: PropTypes.object,
};

export default injectSheet(styles)(EcUserNameSearch);
