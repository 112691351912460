import React from 'react';

const TicketReviewEmptyStateIcon = () => {
  return (
    <svg width="137px" height="170px" viewBox="0 0 137 170" version="1.1">
      <defs>
        <linearGradient
          x1="55.1148219%"
          y1="66.2713262%"
          x2="23.1723395%"
          y2="52.8521217%"
          id="linearGradient-1"
        >
          <stop stopColor="#EDF3FD" offset="0%" />
          <stop stopColor="#D6E3FA" offset="100%" />
        </linearGradient>
        <filter
          x="-8.9%"
          y="-29.2%"
          width="117.8%"
          height="158.3%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="5"
            dy="5"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 0.706612669   0 0 0 0 0.0893702652  0 0 0 0.17334692 0"
            type="matrix"
            in="shadowOffsetOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          x1="90.6083424%"
          y1="61.2824072%"
          x2="32.9575052%"
          y2="50%"
          id="linearGradient-3"
        >
          <stop stopColor="#FFE883" offset="0%" />
          <stop stopColor="#FECC4C" offset="100%" />
        </linearGradient>
        <radialGradient
          cx="78.1014846%"
          cy="23.7111546%"
          fx="78.1014846%"
          fy="23.7111546%"
          r="85.7181565%"
          gradientTransform="translate(0.781015,0.237112),scale(0.984375,1.000000),rotate(117.127213),scale(1.000000,1.861752),translate(-0.781015,-0.237112)"
          id="radialGradient-4"
        >
          <stop stopColor="#FFF4CA" offset="0%" />
          <stop stopColor="#B05300" offset="100%" />
        </radialGradient>
      </defs>
      <g
        id="Tickets"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Evisort-—-Ticket-(Ticket-Creator-Flow,-Review,-No-Access)"
          transform="translate(-651.000000, -275.000000)"
        >
          <g id="phone" transform="translate(651.000000, 275.000000)">
            <g
              id="screen"
              transform="translate(18.524462, 0.000000)"
              opacity="0.600000024"
            >
              <g id="4" transform="translate(0.262231, 134.091977)">
                <rect
                  id="Rectangle-16"
                  fill="url(#linearGradient-1)"
                  x="0"
                  y="0"
                  width="117.729941"
                  height="35.6947162"
                  rx="6.26223092"
                />
                <g id="Group" transform="translate(4.213307, 4.908023)">
                  <circle
                    id="Oval-Copy-2"
                    fill="#A1B8E3"
                    cx="13.1506849"
                    cy="13.1506849"
                    r="13.1506849"
                  />
                  <path
                    d="M18.3035299,6.71461134 C18.7907873,6.04463243 19.7289127,5.89650737 20.3988916,6.38376476 C21.02946,6.84235995 21.1977634,7.70033993 20.8091522,8.35825768 L20.7297382,8.47912643 L12.7297382,19.4791264 C12.3012266,20.0683299 11.517359,20.259247 10.8719745,19.9513651 L10.7448904,19.8831083 L5.74489042,16.8831083 C5.03452002,16.456886 4.80417242,15.5354957 5.23039466,14.8251253 C5.63154501,14.1565413 6.4713218,13.913168 7.16097142,14.2421935 L7.28837768,14.3106295 L11.111,16.604 L18.3035299,6.71461134 Z"
                    id="Path-3"
                    fillOpacity="0.7"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                  />
                </g>
                <rect
                  id="Rectangle"
                  fill="#A1B8E3"
                  x="35.6947162"
                  y="6.26223092"
                  width="72.9549902"
                  height="5.32289628"
                  rx="2.66144814"
                />
                <rect
                  id="Rectangle-Copy"
                  fill="#A1B8E3"
                  x="35.6947162"
                  y="15.0293542"
                  width="55.7338552"
                  height="5.32289628"
                  rx="2.66144814"
                />
                <rect
                  id="Rectangle-Copy-2"
                  fill="#A1B8E3"
                  opacity="0.5"
                  x="35.6947162"
                  y="25.6751468"
                  width="20.665362"
                  height="3.75733855"
                  rx="1.87866928"
                />
              </g>
              <g id="3" transform="translate(0.262231, 90.882583)">
                <rect
                  id="Rectangle-16"
                  fill="url(#linearGradient-1)"
                  x="0"
                  y="0"
                  width="117.729941"
                  height="35.6947162"
                  rx="6.26223092"
                />
                <g id="Group" transform="translate(4.213307, 5.117417)">
                  <circle
                    id="Oval-Copy-2"
                    fill="#A1B8E3"
                    cx="13.1506849"
                    cy="13.1506849"
                    r="13.1506849"
                  />
                  <path
                    d="M18.3035299,6.71461134 C18.7907873,6.04463243 19.7289127,5.89650737 20.3988916,6.38376476 C21.02946,6.84235995 21.1977634,7.70033993 20.8091522,8.35825768 L20.7297382,8.47912643 L12.7297382,19.4791264 C12.3012266,20.0683299 11.517359,20.259247 10.8719745,19.9513651 L10.7448904,19.8831083 L5.74489042,16.8831083 C5.03452002,16.456886 4.80417242,15.5354957 5.23039466,14.8251253 C5.63154501,14.1565413 6.4713218,13.913168 7.16097142,14.2421935 L7.28837768,14.3106295 L11.111,16.604 L18.3035299,6.71461134 Z"
                    id="Path-3"
                    fillOpacity="0.7"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                  />
                </g>
                <rect
                  id="Rectangle"
                  fill="#A1B8E3"
                  x="35.6947162"
                  y="6.26223092"
                  width="72.9549902"
                  height="5.32289628"
                  rx="2.66144814"
                />
                <rect
                  id="Rectangle-Copy"
                  fill="#A1B8E3"
                  x="35.6947162"
                  y="15.0293542"
                  width="55.7338552"
                  height="5.32289628"
                  rx="2.66144814"
                />
                <rect
                  id="Rectangle-Copy-2"
                  fill="#A1B8E3"
                  opacity="0.5"
                  x="35.6947162"
                  y="25.6751468"
                  width="20.665362"
                  height="3.75733855"
                  rx="1.87866928"
                />
              </g>
              <g id="1" transform="translate(0.262231, 0.706458)">
                <rect
                  id="Rectangle-16"
                  fill="url(#linearGradient-1)"
                  x="0"
                  y="0"
                  width="117.729941"
                  height="35.6947162"
                  rx="6.26223092"
                />
                <circle
                  id="Oval-Copy-2"
                  fill="#A1B8E3"
                  cx="17.8473581"
                  cy="17.8473581"
                  r="13.1506849"
                />
                <path
                  d="M23.0002031,11.4112845 C23.4874605,10.7413056 24.4255859,10.5931806 25.0955648,11.0804379 C25.7261332,11.5390331 25.8944366,12.3970131 25.5058254,13.0549309 L25.4264114,13.1757996 L17.4264114,24.1757996 C16.9978998,24.7650031 16.2140322,24.9559201 15.5686477,24.6480383 L15.4415636,24.5797815 L10.4415636,21.5797815 C9.73119321,21.1535592 9.50084561,20.2321688 9.92706785,19.5217984 C10.3282182,18.8532145 11.167995,18.6098411 11.8576446,18.9388667 L11.9850509,19.0073027 L15.8076732,21.3006732 L23.0002031,11.4112845 Z"
                  id="Path-3"
                  fillOpacity="0.7"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                />
                <rect
                  id="Rectangle"
                  fill="#A1B8E3"
                  x="35.6947162"
                  y="6.26223092"
                  width="72.9549902"
                  height="5.32289628"
                  rx="2.66144814"
                />
                <rect
                  id="Rectangle-Copy"
                  fill="#A1B8E3"
                  x="35.6947162"
                  y="15.0293542"
                  width="55.7338552"
                  height="5.32289628"
                  rx="2.66144814"
                />
                <rect
                  id="Rectangle-Copy-2"
                  fill="#A1B8E3"
                  opacity="0.5"
                  x="35.6947162"
                  y="25.6751468"
                  width="20.665362"
                  height="3.75733855"
                  rx="1.87866928"
                />
              </g>
            </g>
            <g
              id="yellow-comment"
              filter="url(#filter-2)"
              transform="translate(0.000000, 42.976517)"
            >
              <rect
                id="Rectangle-16"
                fill="url(#linearGradient-3)"
                x="0"
                y="0"
                width="117.729941"
                height="35.6947162"
                rx="6.26223092"
              />
              <path
                d="M18,5.20313759 C24.955338,5.20313759 30.593776,10.8415175 30.593776,17.796875 C30.593776,24.7522069 24.955338,30.3906124 18,30.3906124 C11.044662,30.3906124 5.40625,24.7522069 5.40625,17.796875 C5.40625,10.8415175 11.044662,5.20313759 18,5.20313759 Z"
                id="base"
                fill="url(#radialGradient-4)"
                opacity="0.600000024"
              />
              <path
                d="M38.3561644,6.26223092 L105.988258,6.26223092 C107.458136,6.26223092 108.649706,7.45380184 108.649706,8.92367906 C108.649706,10.3935563 107.458136,11.5851272 105.988258,11.5851272 L38.3561644,11.5851272 C36.8862872,11.5851272 35.6947162,10.3935563 35.6947162,8.92367906 C35.6947162,7.45380184 36.8862872,6.26223092 38.3561644,6.26223092 Z"
                id="Rectangle"
                fill="#FFB417"
              />
              <path
                d="M38.3561644,15.0293542 L88.7671233,15.0293542 C90.2370005,15.0293542 91.4285714,16.2209251 91.4285714,17.6908023 C91.4285714,19.1606796 90.2370005,20.3522505 88.7671233,20.3522505 L38.3561644,20.3522505 C36.8862872,20.3522505 35.6947162,19.1606796 35.6947162,17.6908023 C35.6947162,16.2209251 36.8862872,15.0293542 38.3561644,15.0293542 Z"
                id="Rectangle-Copy"
                fill="#FFB417"
              />
              <path
                d="M37.5733855,25.6751468 L54.481409,25.6751468 C55.5189694,25.6751468 56.3600783,26.5162557 56.3600783,27.553816 C56.3600783,28.5913764 55.5189694,29.4324853 54.481409,29.4324853 L37.5733855,29.4324853 C36.5358251,29.4324853 35.6947162,28.5913764 35.6947162,27.553816 C35.6947162,26.5162557 36.5358251,25.6751468 37.5733855,25.6751468 Z"
                id="Rectangle-Copy-2"
                fill="#FFB417"
                opacity="0.5"
              />
              <polygon
                id="Path"
                fill="#FFFFFF"
                fillRule="nonzero"
                points="19 9.02348337 19 17.609 23.7071068 22.3163766 22.2928932 23.7305901 17 18.4376969 17 9.02348337"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TicketReviewEmptyStateIcon;
