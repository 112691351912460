import PropTypes from 'prop-types';
import React from 'react';

const PinIcon = (props) => {
  const { size, color, opacity, onClick } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      {...(onClick && { onClick })}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          fill={color}
          fillOpacity={opacity}
          d="M17.237 6.569a.897.897 0 0 1-1.096 1.404l-3.099 5.166c.233.348.458.705.675 1.07a.897.897 0 0 1-.137 1.092l-.149.15a.897.897 0 0 1-1.269 0l-2.854-2.856-5.994 4.795a.501.501 0 0 1-.704-.704l4.795-5.994L4.55 7.838a.897.897 0 0 1 0-1.269l.149-.149a.897.897 0 0 1 1.092-.137c.365.217.722.442 1.071.675l5.166-3.098a.897.897 0 0 1 1.403-1.097l3.806 3.806z"
        />
      </g>
    </svg>
  );
};

PinIcon.defaultProps = {
  size: '24',
  opacity: '1',
  color: 'currentColor',
};

PinIcon.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.string,
  size: PropTypes.string,
};

export default PinIcon;
