import React from 'react';
import { IndicatorContainerProps } from 'react-select';

import { Option } from '../../../types';
import { IconButton } from '../../IconButton';
import { Layout } from '../../Layout';

export const IndicatorsContainer = (
  props: IndicatorContainerProps<Option, boolean>,
) => {
  const { children, hasValue, clearValue, selectProps } = props;
  const { name, inputValue, isClearable, isDisabled } = selectProps;
  const shouldClearValue =
    !isDisabled && isClearable && (hasValue || inputValue);

  return (
    <Layout preset="icons">
      {shouldClearValue && (
        <IconButton
          preset="clear-all"
          text={`clear all ${name}`}
          onClick={clearValue}
        />
      )}
      {children}
    </Layout>
  );
};
