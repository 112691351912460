export default `
<svg
  aria-hidden="true"
  aria-label="trash"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.25 6.5C18.6562 6.5 19 6.84375 19 7.25C19 7.6875 18.6562 8 18.25 8H17.875L17.125 18.1562C17.0312 19.2188 16.1875 20 15.125 20H8.84375C7.78125 20 6.9375 19.2188 6.84375 18.1562L6.09375 8H5.75C5.3125 8 5 7.6875 5 7.25C5 6.84375 5.3125 6.5 5.75 6.5H7.90625L9.0625 4.78125C9.375 4.3125 9.9375 4 10.5312 4H13.4375C14.0312 4 14.5938 4.3125 14.9062 4.78125L16.0625 6.5H18.25ZM10.5312 5.5C10.4375 5.5 10.3438 5.5625 10.3125 5.625L9.71875 6.5H14.25L13.6562 5.625C13.625 5.5625 13.5312 5.5 13.4375 5.5H10.5312ZM16.375 8H7.59375L8.34375 18.0625C8.375 18.3125 8.59375 18.5 8.84375 18.5H15.125C15.375 18.5 15.5938 18.3125 15.625 18.0625L16.375 8Z"
    fill="currentcolor"
  />
</svg>
`;
