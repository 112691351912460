import { uniq } from 'lodash';

import { Highlight } from '~/features/document-viewer';
import { Nullable } from '~/types';

import {
  FieldClassificationValue,
  FieldModelConfigState,
  FieldValue,
} from '../types';
import { TestCaseOutcome } from '../types/server';

/** Prepares the gold value value for tagging an answer. */
export const prepareGoldValueValueForTagAnswer = ({
  value,
  goldValue,
  modelValue,
  config,
}: {
  value: string;
  goldValue: Nullable<FieldValue>;
  modelValue: Nullable<FieldValue>;
  config: FieldModelConfigState;
}) => {
  if (config.fieldClassification) {
    if (config.fieldClassification.isMulti) {
      // Append the highlighted value to the existing gold value (or model value if gold value is not set)
      return uniq([
        ...((goldValue?.value as FieldClassificationValue['value']) ??
          (modelValue?.value as FieldClassificationValue['value']) ??
          []),
        value,
      ]);
    }
    return [value];
  }
  return value;
};

/** Prepares the gold value for tagging a source highlight. */
export const prepareGoldValueForTagSource = ({
  goldValue,
  modelValue,
  outcome,
  highlight,
}: {
  goldValue: Nullable<FieldValue>;
  modelValue: Nullable<FieldValue>;
  outcome: TestCaseOutcome;
  highlight: Highlight;
}): FieldValue => ({
  highlights: [
    ...(goldValue?.highlights ?? modelValue?.highlights ?? []),
    highlight,
  ],
  type:
    outcome === 'correct' || goldValue?.value
      ? modelValue?.type
      : modelValue?.type === 'classification'
      ? 'classification-unknown'
      : 'unknown',
  // @ts-expect-error string[] is not assignable to string
  value: goldValue?.value
    ? goldValue.value
    : outcome === 'correct'
    ? modelValue?.value
    : null,
});
