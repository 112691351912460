import { isEqual } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSegment } from '~/components/SegmentAnalytics';
import { Charts } from '~/components/Shared/Charts';
import { useCurrentUser } from '~/hooks';
import { selectors } from '~/redux';
import { Dashboard, SearchParams } from '~/redux/api/methods';
import { BuildQueryParams } from '~/redux/api/methods/searchV3';
import dashboardV2 from '~/redux/slices/dashboardV2';
import { Chart } from '~/types';

import { toFilter } from '../DashboardV2.utils';

interface DashboardV2ChartsProps {
  charts: Chart[];
  queryParams: BuildQueryParams;
  entity?: Dashboard['entity'];
  searchParams?: SearchParams;
}

export const DashboardV2Charts = ({
  entity,
  charts,
  queryParams,
  searchParams,
}: DashboardV2ChartsProps) => {
  const dispatch = useDispatch();

  const currentChart = useSelector(selectors.selectDashboardCurrentChart);
  const dashboard = useSelector(selectors.selectActiveDashboard);
  const intervals = useSelector(selectors.selectDashboardIntevals);
  const chartWidths = useSelector(selectors.selectDashboardChartWidths);

  const user = useCurrentUser();

  const onRearrangeCharts = useCallback(
    (updatedFilters: Chart[], activeFilter?: Chart) => {
      const currentOrder = charts.map((chart) => chart.id);
      const newOrder = updatedFilters.map((chart) => chart.id);
      if (!isEqual(currentOrder, newOrder)) {
        trackSegment('selectReorderChart', {
          id: dashboard.id,
          name: dashboard.name,
          value: activeFilter?.label,
          user_id: user.id,
          client_id: user.client,
        });

        dispatch(dashboardV2.actions.setDashboardDirty(true));
      }
      dispatch(dashboardV2.actions.setChartFilters(updatedFilters));
    },
    [charts],
  );

  function onChartWidthChange(filter: Chart, newWidth: string) {
    dispatch(
      dashboardV2.actions.setChartWidth({ id: filter.id, value: newWidth }),
    );
  }

  function onChartTypeChange(filter: Chart, newType: string) {
    dispatch(
      dashboardV2.actions.setChartType({ id: filter.id, value: newType }),
    );
  }

  return (
    <Charts
      intervals={intervals}
      chartWidths={chartWidths}
      searchParams={searchParams}
      onIntervalChange={(action, filter) => {
        trackSegment('selectCustomInterval', {
          filter_id: filter.id,
          name: filter.label,
          value: action.value,
          dashboard_id: dashboard.id,
          dashboard_name: dashboard.name,
          user_id: user.id,
          client_id: user.client,
        });
        dispatch(
          dashboardV2.actions.setChartInterval({
            id: filter.id,
            value: action.value,
          }),
        );
      }}
      onRemoveItem={(searchFilter) => {
        trackSegment('selectDeleteCharts', {
          id: dashboard.id,
          name: dashboard.name,
          value: searchFilter.label,
          user_id: user.id,
          client_id: user.client,
        });

        dispatch(dashboardV2.actions.deleteChart(searchFilter));
      }}
      onRearrangeCharts={onRearrangeCharts}
      onChartWidthChange={onChartWidthChange}
      onChatTypeChange={onChartTypeChange}
      charts={charts}
      onCrossFiltersChange={(crossFilters, fieldId) => {
        const newFilters = crossFilters.map((crossFilter) => {
          return toFilter(
            crossFilter.fieldId,
            crossFilter.value!,
            crossFilter.type,
          );
        });
        dispatch(dashboardV2.actions.setCrossFilters(newFilters));

        dispatch(dashboardV2.actions.setCurrentChart(fieldId));
      }}
      queryParams={queryParams}
      currentChart={currentChart}
      entity={entity}
    ></Charts>
  );
};
