import PropTypes from 'prop-types';
import React from 'react';

import {
  evisortBlue,
  evisortYellow,
} from '../../../assets/shared-styles/general';

const FolderIcon = (props) => {
  const { blue, yellow, color } = props;

  let fillColor = color || '#000';
  if (blue) {
    fillColor = evisortBlue;
  } else if (yellow) {
    fillColor = evisortYellow;
  }

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          id="item"
          fill={fillColor}
          fillRule="nonzero"
          fillOpacity={props.opacity}
          d="M10.05 4.5H4.2c-1.073 0-1.94.886-1.94 1.969L2.25 18.28c0 1.083.877 1.969 1.95 1.969h15.6c1.072 0 1.95-.886 1.95-1.969V8.438c0-1.083-.878-1.97-1.95-1.97H12L10.05 4.5z"
        />
      </g>
    </svg>
  );
};

FolderIcon.defaultProps = {
  size: '24',
  opacity: 1,
};

FolderIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default FolderIcon;
