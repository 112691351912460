import classnames from 'classnames';
import PT from 'prop-types';
import React, { useRef } from 'react';

import { UI_CHECKBOX_ICON_TESTID } from '~/constants/testids';
import { FlexLayout, Icon, Text } from '~/ui';

function Checkbox({
  className,
  shouldTruncate = true,
  disabled = false,
  id,
  label,
  labelVariant = 's-dense',
  value,
  align = 'center',
  onChange,
}) {
  let icon = null;
  if (value) {
    icon = 'checkmark';
  } else if (value === null) {
    icon = 'dash';
  }

  const isActive = !!icon;
  const ref = useRef();
  const isLabelElement = label && typeof label === 'object' && ref;
  const labelTitle = isLabelElement ? ref.current?.innerText : label;

  const checkboxStyles = {
    backgroundColor: isActive ? 'blue-500' : 'white',
    border: 'border-dark',
    borderColor: isActive ? 'transparent' : undefined,
    borderRadius: 's',
    height: 'icon-xs',
    width: 'icon-xs',
    minWidth: 'icon-xs',
  };

  return (
    <FlexLayout
      alignItems={align}
      className={classnames(UI_CHECKBOX_ICON_TESTID, className)}
      disabled={disabled}
      id={id}
      space={2}
      sx={{ width: 'fit-content' }}
      onClick={() => onChange(!value)}
    >
      <FlexLayout
        alignItems="center"
        justifyContent="center"
        sx={checkboxStyles}
      >
        {icon && (
          <Icon
            color="white"
            icon={icon}
            size="xs"
            title={labelTitle || 'checkbox'}
          />
        )}
      </FlexLayout>
      {label && (
        <Text
          ref={ref}
          color="black"
          variant={labelVariant}
          shouldTruncate={shouldTruncate}
        >
          {label}
        </Text>
      )}
    </FlexLayout>
  );
}

Checkbox.propTypes = {
  /** Disable selection */
  disabled: PT.bool,
  /** Checkbox label */
  label: PT.oneOf([PT.string, PT.element]),
  /** Checkbox label variant */
  labelVariant: PT.string,
  /** Checkbox value. One of [true, false, null] for Checked, Unchecked and Partially Checked states */
  value: PT.oneOf([true, false, null]),
  /** Callback that sets new selected values */
  onChange: PT.func.isRequired,
};

export default Checkbox;
