import { evisortBlue } from '../../assets/shared-styles/general';

const styles = {
  documentHeader: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: '0',
    marginBottom: '32px',
  },
  documentFullTitle: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  documentHeaderActions: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  nameCell: {
    display: 'block',
    color: evisortBlue,
    textDecoration: 'none',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default styles;
