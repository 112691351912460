import React from 'react';

import { Radio } from '../../../components';
import { Nullable } from '../../../types';

interface Props {
  /** Radio aria-label */
  label: string;
  /** Radio input name */
  name: string;
  /** Radio value */
  value: Nullable<boolean>;
  /** Radio onChange handler */
  onChange: (updatedValue: Nullable<boolean>) => void;
}

export const RadioCell = ({ label, name, value, onChange }: Props) => {
  const option = { label, value };
  return (
    <Radio
      checked={Boolean(value)}
      labelMode="hidden"
      name={name}
      option={option}
      onChange={onChange}
      value={value}
    />
  );
};
