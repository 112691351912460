import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { ENTER } from '~/constants/keyCodes';

import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import styles from './DeleteDepartmentModal.styles';

class DeleteDepartmentModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnKeyboardPress = this.handleOnKeyboardPress.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);

    this.state = {
      confirmText: '',
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleOnKeyboardPress, true);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleOnKeyboardPress, true);
  }

  handleOnKeyboardPress(event) {
    if (event.keyCode === ENTER) {
      this.handleDeleteClick();
    }
  }

  handleDeleteClick() {
    const { deleteItem, hideModal } = this.props;

    deleteItem();
    hideModal();
  }

  handleInputChange(confirmText) {
    this.setState({ confirmText });
  }

  renderInfoComponent() {
    const { classes, infoIcon, infoMessage } = this.props;

    return infoIcon && infoMessage ? (
      <div className={classes.infoComponent}>
        {infoIcon}
        {infoMessage}
      </div>
    ) : null;
  }

  render() {
    const {
      classes,
      title,
      text,
      confirmButtonIcon,
      confirmButtonText,
      includeConfirmation,
      hideModal,
    } = this.props;
    const { confirmText } = this.state;

    return (
      <EcModalCard
        title={title}
        content={
          <div>
            <div>{text}</div>
            {this.renderInfoComponent()}
            {includeConfirmation ? (
              <div>
                <input
                  className={classes.modalInput}
                  value={confirmText}
                  onChange={(event) => {
                    this.handleInputChange(event.target.value);
                  }}
                />
              </div>
            ) : null}
          </div>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              red
              iconLeft={confirmButtonIcon}
              text={confirmButtonText}
              disabled={includeConfirmation && confirmText !== 'DELETE'}
              onClick={this.handleDeleteClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

DeleteDepartmentModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  infoIcon: PropTypes.object,
  infoMessage: PropTypes.object,
  confirmButtonIcon: PropTypes.object,
  confirmButtonText: PropTypes.string.isRequired,
  deleteItem: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  includeConfirmation: PropTypes.bool,
};

export default injectSheet(styles)(DeleteDepartmentModal);
