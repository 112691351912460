import { UserRoleType } from '~/enums';
import { Department, DepartmentTree, DepartmentV2, PilotId } from '~/types';

import { pilot } from '../../../services';

interface DepartmentResponse {
  results: DepartmentServer[];
}

export interface DepartmentServer {
  children: DepartmentServer[];
  department_id: PilotId;
  department_name: string;
  members_count: number;
  parent: PilotId | null;
}

export interface DepartmentMembersServer {
  client: number;
  departments: number[];
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  is_automation_admin: boolean;
  is_workflow_admin: boolean;
  job_title: string;
  last_login: string | null;
  last_name: string;
  position_name: string;
  role: UserRoleType;
}

interface DepartmentChildrenResponse {
  count: number;
  next?: string;
  previous?: string;
  results: Array<DepartmentTree>;
}

type DepartmentChildrenParams = {
  clientId: number;
  departmentId: number;
};

type DepartmentSearchParams = {
  clientId?: PilotId;
  query: string;
  page?: number;
  pageSize?: number;
};

type FindDepartmentsByNamePayload = {
  results: DepartmentV2[];
  count: number;
};

type ListDepartmentMembersParams = {
  departmentId: number;
  page?: number;
  pageSize?: number;
};

interface ListDepartmentMembersResponse {
  count: number;
  next?: string;
  previous?: string;
  results: Array<DepartmentMembersServer>;
}

const transformDepartments = (
  serverDepartments: DepartmentServer[],
): Department[] =>
  serverDepartments.map((serverDepartment) => {
    const children = transformDepartments(serverDepartment.children);
    return {
      children,
      id: serverDepartment.department_id,
      memberCount: serverDepartment.members_count,
      name: serverDepartment.department_name,
      parentId: serverDepartment.parent,
    };
  });

export const getDepartmentChildren = ({
  clientId,
  departmentId,
}: DepartmentChildrenParams): Promise<DepartmentChildrenResponse> => {
  return pilot.get(`client/${clientId}/departments/${departmentId}/`);
};

export const listDepartmentMembers = ({
  departmentId,
  page,
  pageSize,
}: ListDepartmentMembersParams): Promise<ListDepartmentMembersResponse> => {
  return pilot.get(`department/${departmentId}/members/`, {
    params: { page, page_size: pageSize },
  });
};

export const getAllDepartments = async (clientId: PilotId) => {
  const response: DepartmentResponse = await pilot.get(
    `client/${clientId}/departments-info/`,
  );
  return transformDepartments(response.results);
};

export const findDepartmentsByName = async ({
  clientId,
  query,
  page,
  pageSize,
}: DepartmentSearchParams): Promise<FindDepartmentsByNamePayload> => {
  return pilot.get(`client/${clientId}/departments-search/`, {
    params: { search: query, page, page_size: pageSize },
  });
};
