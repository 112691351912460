export default `
<svg
  aria-hidden="true"
  aria-label="code"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M4.8125 11.9845L9.75 16.7032C10.0625 16.9845 10.0625 17.4532 9.78125 17.7657C9.5 18.0782 9.03125 18.0782 8.71875 17.797L3.21875 12.547C3.0625 12.3907 3 12.2032 3 11.9845C3 11.797 3.0625 11.6095 3.21875 11.4532L8.71875 6.20322C9.03125 5.92197 9.5 5.92197 9.78125 6.23447C10.0625 6.54697 10.0625 7.01572 9.75 7.29697L4.8125 11.9845ZM14.2188 7.29697C13.9062 7.01572 13.9062 6.54697 14.1875 6.23447C14.4688 5.92197 14.9375 5.92197 15.25 6.20322L20.75 11.4532C20.9062 11.6095 21 11.797 21 11.9845C21 12.2032 20.9062 12.3907 20.75 12.547L15.25 17.797C14.9375 18.0782 14.4688 18.0782 14.1875 17.7657C13.9062 17.4532 13.9062 16.9845 14.2188 16.7032L19.1562 11.9845L14.2188 7.29697Z"
    fill="currentcolor"
  />
</svg>
`;
