import { parse } from 'date-fns';
import { groupBy } from 'lodash';

import { EM_DASH } from '~/constants/unicode';
import { formatDate } from '~/eds';
import { DataFieldType } from '~/enums';
import { Nullable, PilotId } from '~/types';
import { getUniqueFieldValues } from '~/utils/document.utils';
import { capitalizeWords } from '~/utils/strings';

import {
  fetchDocumentInformation,
  FieldValue,
  getAllProvisions,
  ProvisionsParams,
} from '../methods';

export const getFieldValue = (
  type: DataFieldType,
  value: Nullable<FieldValue | FieldValue[]>,
) => {
  if (!value) return EM_DASH;

  switch (type) {
    case DataFieldType.ARRAY_MULTIPLE:
    case DataFieldType.ATTACHMENT:
      if (!(value as FieldValue[]).length) return EM_DASH;
      return getUniqueFieldValues(value as FieldValue[])
        .map((val) => `- ${val.display_value}`)
        .join('\n');

    case DataFieldType.DATE:
      const dateValue = (value as FieldValue).display_value;
      if (!dateValue) return EM_DASH;
      return formatDate(parse(dateValue, 'yyyy-MM-dd', new Date()), 'long');

    case DataFieldType.BOOLEAN:
      if (!(value as FieldValue).display_value) return EM_DASH;

      return capitalizeWords((value as FieldValue).display_value.toString());

    default:
      return (value as FieldValue).display_value || EM_DASH;
  }
};

export const getDocumentInformation = async (documentId: PilotId) => {
  const response = await fetchDocumentInformation(documentId);
  const sections = response.sections.map((section) => {
    const fields = section.fields.map((field) => {
      return {
        ...field,
        displayValue: `${getFieldValue(field.type, field.value)}`,
      };
    });
    return {
      ...section,
      fields,
    };
  });
  return { sections, documentId: response.id };
};

export const getAllProvisionsGrouped = async (params: ProvisionsParams) => {
  const data = await getAllProvisions(params);
  const clausesGroupedByName = groupBy(data.results, 'title');
  return Object.keys(clausesGroupedByName).map((key) => ({
    name: key,
    content: clausesGroupedByName[key],
  }));
};
