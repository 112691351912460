import { CustomEditor, MentionNode } from '../types';

export const withMentions = (editor: CustomEditor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return (element as MentionNode).type === 'mention'
      ? true
      : isInline(element);
  };

  editor.isVoid = (element) => {
    return (element as MentionNode).type === 'mention' ? true : isVoid(element);
  };

  return editor;
};
