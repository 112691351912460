// https://atomiks.github.io/tippyjs/v5/themes/
import { TIPPY_PRESETS as presets } from '../../../constants';

const tooltipStyle = {
  maxWidth: 'tooltip.max-width',
  paddingBottom: 2,
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 2,
  zIndex: 'tooltip',
};

export const tippy = {
  // general
  [`.${presets.popover.className} > .tippy-content`]: {
    borderRadius: 'm',
    padding: 0,
  },
  // popover
  [`.${presets.popover.className}.tippy-box`]: {
    backgroundColor: 'background',
    border: 'border',
    boxShadow: 'raised',
    color: 'text.primary',
    padding: 4,
    zIndex: 'popover',
  },
  // dropdown
  [`.${presets.dropdown.className}.tippy-box`]: {
    backgroundColor: 'background',
    boxShadow: 'raised',
    color: 'text.primary',
    padding: 0,
    zIndex: 'dropdown',
  },
  // tooltip
  [`.${presets.tooltip.className}.tippy-box`]: {
    ...tooltipStyle,
    backgroundColor: 'inverse.background',
    color: 'inverse.text.primary',
  },
  [`.${presets.tooltip.className} > .tippy-arrow`]: {
    color: 'inverse.background',
  },
  // tooltip.inverse
  [`.${presets['tooltip.inverse'].className}.tippy-box`]: {
    ...tooltipStyle,
    backgroundColor: 'background',
    color: 'text.primary',
  },
  [`.${presets['tooltip.inverse'].className} > .tippy-arrow`]: {
    color: 'background',
  },
};
