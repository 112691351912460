import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';
import { Filter } from '~/evifields';
import { CreditSummary } from '~/features/billing';
import { PromptModelTestCase } from '~/features/x-ray';
import { DataField } from '~/features/x-ray/fields';
import { Nullable } from '~/types';

import { FieldAiState as State } from '../types';

export const initialState: State = {
  activeModel: null,
  activeTestcaseNumber: 1,
  activeVersionNumber: 1,
  creditsSummary: null,
  configuration: {
    field: null,
    filters: [],
    instructions: null,
    internal: null,
  },
  dismissedIncorrectStatusMessage: false,
  documentViewer: {},
  hasViewedWelcomeContent: false,
  isNewVersion: false,
  modelAction: null,
  modelVersions: [],
  optimize: null,
  redirect: null,
  reviewIncorrectCount: 0,
  publishMode: 'all_docs_in_scope',
};

const initialFieldClassification: State['configuration']['fieldClassification'] = {
  isMulti: false,
  options: [],
};

const resetReducer = (_state: State) => {
  return initialState;
};

const setConfigurationReducer = (
  state: State,
  action: PayloadAction<State['configuration']>,
) => {
  state.configuration = action.payload;
};

const setFiltersReducer = (state: State, action: PayloadAction<Filter[]>) => {
  state.configuration.filters = action.payload;
};

const setInstructionsReducer = (
  state: State,
  action: PayloadAction<Nullable<string>>,
) => {
  state.configuration.instructions = action.payload;
};

const setInternalReducer = (state: State, action: PayloadAction<string>) => {
  state.configuration.internal = action.payload;
};

export const fieldAiSlice = createSlice({
  name: SliceType.FieldAi,
  initialState,
  reducers: {
    addFieldAiTestCase: (
      state: State,
      action: PayloadAction<PromptModelTestCase>,
    ) => {
      if (state.activeModel) {
        const { testCases } = state.activeModel;
        state.activeModel.testCases = [...testCases, action.payload];
      }
    },
    resetFieldAi: resetReducer,
    setFieldAiActiveModel: (
      state: State,
      action: PayloadAction<State['activeModel']>,
    ) => {
      state.activeModel = action.payload;
    },
    setFieldAiActiveTestcaseNumber: (
      state: State,
      action: PayloadAction<number>,
    ) => {
      state.activeTestcaseNumber = action.payload;
    },
    setFieldAiActiveVersionNumber: (state, action: PayloadAction<number>) => {
      state.activeVersionNumber = action.payload;
    },
    setFieldAiConfigId: setInternalReducer,
    setFieldAiConfiguration: setConfigurationReducer,
    setFieldAiCreditsSummary: (
      state: State,
      action: PayloadAction<CreditSummary>,
    ) => {
      state.creditsSummary = action.payload;
    },
    setFieldAiDefaultOption: (
      state,
      action: PayloadAction<
        NonNullable<
          State['configuration']['fieldClassification']
        >['defaultOption']
      >,
    ) => {
      if (!state.configuration.fieldClassification) {
        state.configuration.fieldClassification = initialFieldClassification;
      }
      state.configuration.fieldClassification.defaultOption = action.payload;
    },
    setFieldAiDismissedIncorrectStatusMessage: (
      state: State,
      action: PayloadAction<boolean>,
    ) => {
      state.dismissedIncorrectStatusMessage = action.payload;
    },
    setFieldAiDocumentViewer: (
      state: State,
      action: PayloadAction<State['documentViewer']>,
    ) => {
      state.documentViewer = action.payload;
    },
    setFieldAiField: (
      state: State,
      action: PayloadAction<{
        field: Nullable<DataField>;
        fieldClassification?: State['configuration']['fieldClassification'];
      }>,
    ) => {
      state.configuration.field = action.payload.field;
      state.configuration.fieldClassification =
        action.payload.fieldClassification;
    },
    setFieldAiFilters: setFiltersReducer,
    setFieldAiHasViewedWelcomeContent: (
      state: State,
      action: PayloadAction<boolean>,
    ) => {
      state.hasViewedWelcomeContent = action.payload;
    },
    setFieldAiInstructions: setInstructionsReducer,
    setFieldAiIsNewVersion: (state, action: PayloadAction<boolean>) => {
      state.isNewVersion = action.payload;
    },
    setFieldAiIsOpenEnded: (
      state,
      action: PayloadAction<
        NonNullable<
          State['configuration']['fieldClassification']
        >['isOpenEnded']
      >,
    ) => {
      if (!state.configuration.fieldClassification) {
        state.configuration.fieldClassification = initialFieldClassification;
      }
      state.configuration.fieldClassification.isOpenEnded = action.payload;
    },
    setFieldAiModelAction: (
      state: State,
      action: PayloadAction<Nullable<State['modelAction']>>,
    ) => {
      state.modelAction = action.payload;
    },
    setFieldAiPublishMode: (
      state: State,
      action: PayloadAction<State['publishMode']>,
    ) => {
      state.publishMode = action.payload;
    },
    setFieldAiModelVersions: (state, action: PayloadAction<number[]>) => {
      state.modelVersions = action.payload;
    },
    setFieldAiOptimize: (state, action: PayloadAction<State['optimize']>) => {
      state.optimize = action.payload;
    },
    setFieldAiOptions: (
      state,
      action: PayloadAction<
        NonNullable<State['configuration']['fieldClassification']>['options']
      >,
    ) => {
      if (!state.configuration.fieldClassification) {
        state.configuration.fieldClassification = initialFieldClassification;
      }
      state.configuration.fieldClassification.options = action.payload;
    },
    setFieldAiRedirect: (
      state: State,
      action: PayloadAction<State['redirect']>,
    ) => {
      state.redirect = action.payload;
    },
    setFieldAiReviewIncorrectCount: (state, action: PayloadAction<number>) => {
      state.reviewIncorrectCount = action.payload;
    },
    setFieldAiTestCases: (
      state: State,
      action: PayloadAction<PromptModelTestCase[]>,
    ) => {
      if (state.activeModel) {
        state.activeModel.testCases = action.payload;
      }
    },
    setFieldAiUpdatedTestCase: (
      state: State,
      action: PayloadAction<PromptModelTestCase>,
    ) => {
      if (state.activeModel) {
        const { testCases } = state.activeModel;
        const updatedTestCase = action.payload;
        state.activeModel.testCases = testCases.map((testCase) => {
          if (testCase.number === updatedTestCase.number) {
            return updatedTestCase;
          }
          return testCase;
        });
      }
    },
  },
});
