import React from 'react';

import { Box, ContentContainer, Layout } from '~/eds';
import { DocumentViewer } from '~/features/document-viewer';
import { api, coerceRtkqError } from '~/redux';
import { useRouting } from '~/routing';

const Page = () => {
  const { getSearchParam } = useRouting();

  const id = getSearchParam('id');

  const {
    data: file,
    error,
    isFetching,
  } = api.endpoints.getExternalDocument.useQuery({
    id,
  });

  return (
    <Layout h="100vh" justify="center" overflowY="auto" p={6} w="100vw">
      <Box maxW="1200px" w="80%">
        <ContentContainer
          loadingContent={{
            isLoading: isFetching,
            message: 'Loading document…',
          }}
          placeholderContent={
            error
              ? {
                  icon: 'status-warning',
                  message: coerceRtkqError(error).message,
                }
              : undefined
          }
        >
          <>{file && <DocumentViewer file={file} />}</>
        </ContentContainer>
      </Box>
    </Layout>
  );
};

export default Page;
