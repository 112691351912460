import PropTypes from 'prop-types';
import React from 'react';

import { IconButton } from '~/eds';
import { Box, FlexLayout, Text } from '~/ui';

function EcMultipleSelectValue({ isDisabled = false, label, closeHandler }) {
  return (
    <Box
      margin="4px 8px 4px 0"
      px={3}
      py={2}
      sx={{
        border: 'border',
        borderRadius: 'm',
        display: 'inline-flex',
      }}
    >
      <FlexLayout alignItems="center" space={2}>
        <Text color="gray-700" variant="2xs-dense">
          {label}
        </Text>
        {!isDisabled && (
          <IconButton
            icon="x"
            onClick={closeHandler}
            size="s"
            text="remove"
            tooltip={`Click to remove ${label}`}
          />
        )}
      </FlexLayout>
    </Box>
  );
}

EcMultipleSelectValue.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default EcMultipleSelectValue;
