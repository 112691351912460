import { createSlice } from '@reduxjs/toolkit';

import { SavedDashboardsActions, SliceType } from '~/enums';
import { SavedDashboard } from '~/redux/api/methods';
import { Nullable } from '~/types';

const name = SliceType.SavedDashboards;

type DashboardModal = {
  name: SavedDashboardsActions;
  selectedDashboard: SavedDashboard;
};

export type SavedDashboardsSlice = {
  activeModal: Nullable<DashboardModal>;
};

export const initialState: SavedDashboardsSlice = {
  activeModal: null,
};

export default createSlice({
  name,
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.activeModal = {
        selectedDashboard: action.payload.dashboard,
        name: action.payload.name,
      };
    },

    closeModal: (state) => {
      state.activeModal = null;
    },

    reset: () => {
      return {
        ...initialState,
      };
    },
  },
});
