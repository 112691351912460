const styles = {
  searchboxContainer: {
    marginBottom: '20px',
    width: '100%',
  },
  itemRow: {
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ccc',
    fontSize: '16px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  itemName: {
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  itemActions: {
    display: 'flex',
  },
  itemActionsDefault: {
    marginRight: '46px',
    display: 'flex',
  },
  actionsWrapper: {
    margin: '3px 0 0 10px',
  },
};

export default styles;
