export default `
<svg
  aria-hidden="true"
  aria-label="workflow"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M7.43066 5C8.36816 5 9.18066 5.8125 9.18066 6.75V7.25H15.1807V6.75C15.1807 5.8125 15.9619 5 16.9307 5H19.4307C20.3682 5 21.1807 5.8125 21.1807 6.75V9.25C21.1807 10.2188 20.3682 11 19.4307 11H16.9307C15.9619 11 15.1807 10.2188 15.1807 9.25V8.75H9.18066V9.25C9.18066 9.5 9.11816 9.71875 9.02441 9.9375L11.3994 13.0938C11.5557 13.0312 11.7432 13 11.9307 13H14.4307C15.3682 13 16.1807 13.8125 16.1807 14.75V17.25C16.1807 18.2188 15.3682 19 14.4307 19H11.9307C10.9619 19 10.1807 18.2188 10.1807 17.25V14.75C10.1807 14.5312 10.2119 14.3125 10.3057 14.0938L7.93066 10.9375C7.77441 11 7.58691 11 7.43066 11H4.93066C3.96191 11 3.18066 10.2188 3.18066 9.25V6.75C3.18066 5.8125 3.96191 5 4.93066 5H7.43066ZM7.43066 6.5H4.93066C4.77441 6.5 4.68066 6.625 4.68066 6.75V9.25C4.68066 9.40625 4.77441 9.5 4.93066 9.5H7.43066C7.55566 9.5 7.68066 9.40625 7.68066 9.25V6.75C7.68066 6.625 7.55566 6.5 7.43066 6.5ZM14.4307 14.5H11.9307C11.7744 14.5 11.6807 14.625 11.6807 14.75V17.25C11.6807 17.4062 11.7744 17.5 11.9307 17.5H14.4307C14.5557 17.5 14.6807 17.4062 14.6807 17.25V14.75C14.6807 14.625 14.5557 14.5 14.4307 14.5ZM16.6807 6.75V9.25C16.6807 9.40625 16.7744 9.5 16.9307 9.5H19.4307C19.5557 9.5 19.6807 9.40625 19.6807 9.25V6.75C19.6807 6.625 19.5557 6.5 19.4307 6.5H16.9307C16.7744 6.5 16.6807 6.625 16.6807 6.75Z"
    fill="currentcolor"
  />
</svg>
`;
