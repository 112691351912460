import { black2, bold } from '../../../assets/shared-styles/general';

const styles = {
  loadingContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  modalBodyTitle: {
    padding: '16px',
    color: black2,
    fontSize: '15px',
    fontWeight: bold,
  },
  horizontalSeparator: {
    margin: 0,
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  modalBodyContent: {
    height: '400px',
    overflowY: 'scroll',
  },
  modalFolderTreeActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px',
    '& button:not(:last-child)': {
      marginRight: '16px',
    },
  },
  modalHelpText: {
    marginTop: '8px',
    width: '300px',
    height: '20px',
    fontSize: '14px',
    color: '#9a9a9a',
  },
  noDeptMessage: {
    fontSize: '13px',
    lineHeight: '22px',
  },
};

export default styles;
