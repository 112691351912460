import { evisortRed, semibold } from '../../../assets/shared-styles/general';
import { ERROR, WARNING } from '../../../types/toast.types';

const styles = {
  persistentTooltipWrapper: {
    position: 'relative',
  },
  tooltip: {
    width: '100%',
    borderRadius: '4px',
    padding: '12px 16px',
    position: 'absolute',
    top: '52px',
    backgroundColor: ({ tooltipOptions }) => {
      if (tooltipOptions.type === ERROR) {
        return evisortRed;
      }

      if (tooltipOptions.type === WARNING) {
        return '#eb890f';
      }

      return '#fff';
    },
    color: '#fff',
    fontSize: '14px',
    fontWeight: semibold,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    zIndex: 1,
    '&::before': {
      content: '""',
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: ({ tooltipOptions }) => {
        if (tooltipOptions.type === ERROR) {
          return `5px solid ${evisortRed}`;
        }

        if (tooltipOptions.type === WARNING) {
          return '5px solid #eb890f';
        }

        return '5px solid #fff';
      },
      position: 'absolute',
      top: '-5px',
      right: '50%',
    },
  },
};

export default styles;
