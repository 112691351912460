import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import injectSheet from 'react-jss';

import EcCard from '~/components/Shared/EcCard';
import { Button, Table } from '~/eds';
import { api } from '~/redux';
import { MODAL_SIMPLE } from '~/types/modal.types';
import { Box, Text } from '~/ui';

import EcModal from '../../../Shared/EcModal';
import styles from './MigratedProvisions.styles';

const _MigratedProvisions = (props) => {
  const [provision, setProvision] = useState({});
  const [page, setPage] = useState(1);
  const { documentId, classes } = props;

  const { data, isFetching } = api.endpoints.getMigratedProvisions.useQuery({
    docId: documentId,
    page,
  });

  const columns = [
    {
      key: 'name',
      title: 'Provision Title',
      renderCell: (cell) => {
        return <Text className={classes.provisionTitle}>{cell.name}</Text>;
      },
    },
    {
      key: 'content',
      title: 'Provision Text',
      width: 'auto',
      disableResizing: true,
      renderCell: (cell) => {
        return (
          <Box className={classes.provisionText}>
            {/* TODO: pull truncate into a global utility function */}
            <Text variant="body1">
              {truncate(cell.content, { length: 500, omission: '...' })}
            </Text>
            {cell.content.length > 500 ? (
              <Button
                onClick={() => openProvision(cell)}
                variant="action"
                text="Read More"
              />
            ) : null}
          </Box>
        );
      },
    },
  ];

  const openProvision = (prov) => {
    setProvision(prov);
  };
  const clearProvision = () => {
    setProvision({});
  };

  const renderProvisionModal = () => {
    return provision.content ? (
      <EcModal
        width="64vw"
        verticalCenter
        modalType={MODAL_SIMPLE}
        title={provision.name}
        hideModal={clearProvision}
        modalButtons={
          <Button variant="tertiary" text="Close" onClick={clearProvision} />
        }
      >
        <div className={classes.provisionTextContainer}>
          <Text variant="body1" className={classes.provisionText}>
            {provision.content}
          </Text>
        </div>
      </EcModal>
    ) : null;
  };

  return data?.results.length ? (
    <EcCard
      title="Migrated Provisions"
      contentStyles={{ display: 'flex', padding: '16px', marginBottom: '24px' }}
    >
      <Table
        name="Migrated Provisions"
        data={data?.results}
        columns={columns}
        state={{ pageIndex: page, pageSize: 10 }}
        options={{
          enableExportXlsx: false,
          enableSelectRows: false,
          enableManageColumns: false,
        }}
        totalCount={data.count}
        isLoading={isFetching}
        onPaginate={({ pageIndex }) => {
          setPage(pageIndex);
        }}
      />
      {renderProvisionModal()}
    </EcCard>
  ) : null;
};

const MigratedProvisions = React.memo(injectSheet(styles)(_MigratedProvisions));

MigratedProvisions.propTypes = {
  documentId: PropTypes.number.isRequired,
};

export default MigratedProvisions;
