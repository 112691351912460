export const LOGIN_CONSTANTS = {
  HEADERS: {
    CSRF_TOKEN: 'x-csrftoken',
  },
  EVENTS: {
    LOGIN_EMBED_SUCCESS: 'evisort-login-embed-success',
  },
  SEARCH_PARAMS: {
    LOGIN_REDIRECT_TO: 'login_redirect_to',
  },
};
