export default `
<svg
  aria-hidden="true"
  aria-label="status-not-started"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M20.9346 12.0044C20.9346 16.4419 17.3408 20.0044 12.9346 20.0044C8.49707 20.0044 4.93457 16.4419 4.93457 12.0044C4.93457 7.59814 8.49707 4.00439 12.9346 4.00439C17.3408 4.00439 20.9346 7.59814 20.9346 12.0044ZM12.9346 5.50439C9.34082 5.50439 6.43457 8.44189 6.43457 12.0044C6.43457 15.5981 9.34082 18.5044 12.9346 18.5044C16.4971 18.5044 19.4346 15.5981 19.4346 12.0044C19.4346 8.44189 16.4971 5.50439 12.9346 5.50439Z"
    fill="currentcolor"
  />
</svg>
`;
