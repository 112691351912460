import PT from 'prop-types';
import React from 'react';

import Text from '../Text';

function ButtonText({ disabled, text, variant, onClick }) {
  return (
    <Text
      as="button"
      disabled={disabled}
      py={1}
      px={2}
      sx={{
        bg: 'inherit',
        border: 'border',
        borderColor: 'inherit',
        borderRadius: 'm',
        color: 'inherit',
        outline: 'none',
        '&:hover': {
          opacity: 0.8,
        },
      }}
      variant={variant}
      onClick={onClick}
    >
      {text}
    </Text>
  );
}

ButtonText.propTypes = {
  /** Disables the button */
  disabled: PT.bool,
  /** Button text */
  text: PT.string.isRequired,
  /** Supported Text variant */
  variant: PT.string,
  /** Click handler */
  onClick: PT.func.isRequired,
};

export default ButtonText;
