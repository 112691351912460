import React, { useState } from 'react';

import { Alert, Box, Text } from '~/ui';

export const EXPORT_EXCEL_LIMIT = 1000000;

const ExportExcelLimitWarning = ({ resultCount }) => {
  const [showAlert, setShowAlert] = useState(true);

  return showAlert && resultCount > EXPORT_EXCEL_LIMIT ? (
    <Box mb={4}>
      <Alert onDismiss={() => setShowAlert(false)} enableIcon variant="warning">
        <Text>
          Your search query generated more than {EXPORT_EXCEL_LIMIT} results.{' '}
        </Text>
        <Text variant="s-dense-bold">
          Only {EXPORT_EXCEL_LIMIT} search results can be exported,{' '}
        </Text>
        please refine
        <Text> your search query to export results.</Text>
      </Alert>
    </Box>
  ) : null;
};

export default ExportExcelLimitWarning;
