import { useEffect, useState } from 'react';

import { getGroupDocuments } from '~/api';

import { useHash } from './useHash';

const ROOT = '__ROOT__';

const generateIdForLoadMoreRow = (parentId) =>
  `load-more-for-parent-${parentId}`;

const generateLoadMoreRow = (parentId) => {
  return {
    id: generateIdForLoadMoreRow(parentId),
    isLoadMore: true,
    parentId,
  };
};

export function useGroupDocumentsBrowser({ id, fields, documentGroupIds }) {
  // states that should cause re-renders
  const [isExpanded, setIsExpanded] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [maxLevelLoaded, setMaxLevelLoaded] = useState(0);
  const [sortBy, setSortBy] = useState({});

  // states that should not cause re-renders
  const {
    get: getCurrentPagesHash,
    getById: getCurrentPage,
    set: setCurrentPagesHash,
  } = useHash(0);
  const {
    get: getChildrenCountsHash,
    getById: getChildrenCount,
    set: setChildrenCountsHash,
  } = useHash(0);
  const {
    get: getChildrenHash,
    getById: getChildren,
    set: setChildrenHash,
  } = useHash();
  const { get: getLevelsHash, getById: getLevel, set: setLevelsHash } = useHash(
    0,
  );

  const init = () => {
    setLevelsHash({});
    setChildrenCountsHash({});
    setChildrenHash({});
    setIsExpanded({});
    setIsLoading({});
    setCurrentPagesHash({});

    fetchChildren(ROOT);
  };

  useEffect(() => {
    if (id && fields) {
      init();
    }
  }, [id, fields, sortBy]);

  const _isLoading = (nodeId) => isLoading[nodeId];
  const _isExpanded = (nodeId) => isExpanded[nodeId];

  const expand = (nodeId) => {
    setIsExpanded({
      ...isExpanded,
      [nodeId]: true,
    });

    if (!getChildren(nodeId)?.length && !_isLoading(nodeId)) {
      fetchChildren(nodeId);
    }
  };

  const collapse = (nodeId) => {
    setIsExpanded({
      ...isExpanded,
      [nodeId]: false,
    });
  };

  const fetchChildrenNextPage = async (parentId) => {
    const currentPage = getCurrentPage(parentId);
    const nextPage = currentPage + 1;

    let sortOrder;
    switch (sortBy.desc) {
      case true:
        sortOrder = 'desc';
        break;
      case false:
        sortOrder = 'asc';
        break;
      default:
        break;
    }

    const result = await getGroupDocuments({
      parentId: parentId === ROOT ? undefined : parentId,
      groupId: id,
      page: nextPage,
      fields,
      sortBy: sortBy.id,
      sortOrder,
      //TODO: remove once "load more" is not selectable anymore
      documentGroupIds: documentGroupIds?.filter(
        (documentGroup) => !isNaN(documentGroup),
      ),
    });

    // track how many pages we have loaded for a parent
    setCurrentPagesHash({
      ...getCurrentPagesHash(),
      [parentId]: nextPage,
    });

    return result;
  };

  const fetchChildren = async (parentId) => {
    if (!isLoading[parentId]) {
      setIsLoading({
        ...isLoading,
        [parentId]: true,
      });

      const result = await fetchChildrenNextPage(parentId);

      // extend the children array for a parent
      const children = (getChildren(parentId) || []).concat(result.results);
      setChildrenHash({
        ...getChildrenHash(),
        [parentId]: children,
      });

      // track the level of each node
      const parentLevel = getLevel(parentId);
      const childLevel = parentLevel + 1;
      const childrenLevels = result.results.reduce((prev, curr) => {
        prev[curr.id] = childLevel;
        return prev;
      }, {});
      const hasMore = result.count > children.length;
      if (hasMore) {
        childrenLevels[generateIdForLoadMoreRow(parentId)] = childLevel;
      }
      setLevelsHash({
        ...getLevelsHash(),
        ...childrenLevels,
      });

      // track the total children count for a parent
      const { count } = result;
      setChildrenCountsHash({
        ...getChildrenCountsHash(),
        [parentId]: count,
      });

      setMaxLevelLoaded(Math.max(maxLevelLoaded, childLevel));

      setIsLoading({
        ...isLoading,
        [parentId]: false,
      });
    }
  };

  const flatternTree = (parentId = ROOT) => {
    const children = getChildren(parentId) || [];
    let list = [];
    for (const child of children) {
      list.push(child);

      // recurssively flatterning the sub trees if expanded
      if (_isExpanded(child.id)) {
        list = list.concat(flatternTree(child.id));
      }
    }

    // so we can render a Load more button in a table row
    if (hasMoreChildren(parentId)) {
      list.push(generateLoadMoreRow(parentId));
    }
    return list;
  };

  const hasMoreChildren = (parentId = ROOT) => {
    const loadedCount = getChildren(parentId)?.length || 0;
    const total = getChildrenCount(parentId);
    return total > loadedCount;
  };

  return {
    isExpanded: _isExpanded,
    isLoading: _isLoading,
    expand,
    collapse,
    fetchNextPage: fetchChildren,
    getLevel,
    getList: flatternTree,
    maxLevelLoaded,
    sortBy,
    setSortBy,
    rootId: ROOT,
  };
}
