import { AlgorithmStatusType, UploadStatusType } from '~/enums';
import { Document } from '~/features/document-viewer';
import { pilot } from '~/services';
import { PilotId } from '~/types';

export interface AlgoStatus {
  title: string;
  status: AlgorithmStatusType;
  message: string;
  count: number;
}

interface Response {
  id: number;
  current_version: {
    file_name: string;
    file_type: string;
    id: PilotId;
  };
  document: number;
  date_added: string;
  user_added: number;
  algo_status: AlgoStatus[];
  date_modified: string;
  document_name: string;
  document_types: string[];
  file_type: string;
  is_processing_coordinates: boolean;
  parent_folder: number;
  party_list: string[];
  path: any[];
  payment_table: null;
  processing_status: UploadStatusType;
  provision_list: any[];
  provisions: any[];
  rebate_table: null;
  visibility_level: string;
}

export const getDocument = async (
  documentId: PilotId | undefined,
): Promise<Document> => {
  if (documentId === undefined) {
    throw new Error('Document ID is required');
  }
  const response: Response = await pilot.get(`document/${documentId}/`);

  return {
    id: response.id,
    name: response.document_name,
  };
};
