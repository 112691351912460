import React, { useMemo } from 'react';

import { Panel } from '~/eds';
import { useRouting } from '~/routing';
import { Uuid } from '~/types';

import { Clause } from '../../../types';
import { ClauseVariations } from './ClauseVariations';

export const RightPane = () => {
  const { params } = useRouting();
  const { clauseId } = params as { clauseId: Uuid };

  const { data: activeClause, isFetching } = mockQueryResponse(clauseId);

  // TODO: integrate the API state above
  const { footer, loadingContent, placeholderContent, title } = useMemo(
    () => ({
      title: activeClause?.name ?? '',
      placeholderContent:
        clauseId && !isFetching
          ? undefined
          : {
              title: 'No Clause Selected',
              description: 'Select a clause on the left to view content',
            },
      loadingContent: {
        isLoading: false, // TODO: retrieve status of AI suggest job, set to true if job is in progress
        message: 'Evisort AI is completing your request…',
        description:
          'The processing time depends on the number of contracts being processed.',
      },
      footer: undefined,
    }),
    [isFetching, activeClause],
  );

  return (
    <Panel
      enableContentPadding={false}
      placeholderContent={placeholderContent}
      footer={footer}
      loadingContent={loadingContent}
      title={title}
    >
      {activeClause && (
        <ClauseVariations variations={activeClause.variations} />
      )}
    </Panel>
  );
};

const mockClauses: Clause[] = [
  {
    name: 'Acceptance',
    id: 'acceptance',
    tab: 'published',
    variations: [],
  },
  {
    name: 'Assignment',
    id: 'assignment',
    tab: 'published',
    variations: [],
  },
  {
    name: 'Confidentiality',
    id: 'confidentiality',
    tab: 'published',
    variations: [],
  },
  {
    name: 'Force Majeure',
    id: 'force-majeure',
    tab: 'published',
    variations: [],
  },
  {
    name: 'Governing Law',
    id: 'governing-law',
    tab: 'ai-suggested',
    variations: [
      {
        name: 'California',
        content: 'Mock content',
        generatedBy: 400,
        generatedOn: new Date(),
        id: 'california',
        position: 'Preferred',
        clauseId: 'governing-law',
        contractsCount: 570,
        variationsCount: 12,
        tab: 'ai-suggested',
      },
      {
        name: 'Delaware',
        content: 'Mock content',
        generatedBy: 400,
        generatedOn: new Date(),
        id: 'delaware',
        position: 'Fallback',
        clauseId: 'governing-law',
        contractsCount: 109,
        variationsCount: 25,
        tab: 'ai-suggested',
      },
      {
        name: 'Kansas',
        content: 'Mock content',
        generatedBy: 400,
        generatedOn: new Date(),
        id: 'kansas',
        position: 'Walk away',
        clauseId: 'governing-law',
        contractsCount: 6,
        variationsCount: 2,
        tab: 'ai-suggested',
      },
      {
        name: 'Netherlands',
        content: 'Mock content',
        generatedBy: 400,
        generatedOn: new Date(),
        id: 'netherlands',
        position: 'Walk away',
        clauseId: 'governing-law',
        contractsCount: 1,
        variationsCount: 4,
        tab: 'ai-suggested',
      },
    ],
  },
  {
    name: 'Indemnification',
    id: 'indemnification',
    tab: 'review',
    variations: [
      {
        name: 'Limitation of Liability',
        content: 'Mock content',
        generatedBy: 400,
        generatedOn: new Date(),
        id: 'limitation-of-liability',
        position: 'Preferred',
        clauseId: 'indemnification',
        contractsCount: 41,
        variationsCount: 19,
        tab: 'review',
      },
    ],
  },
];

const mockQueryResponse = (clauseId: Uuid) => ({
  data: mockClauses.find((clause) => clause.id === clauseId),
  isFetching: false,
  isSuccess: true,
  error: null,
});
