import {
  black1,
  black2,
  black3,
  black4,
  bold,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  resultsItemCardWrapper: {
    marginBottom: '16px',
  },
  resultsItemContentWrapper: {
    padding: '12px 16px 16px 16px',
  },
  resultsItemHeader: {
    display: 'flex',
    alignItems: 'start',
    marginBottom: '24px',
  },
  resultsItemTitle: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
    '& a': {
      color: black2,
      fontSize: '18px',
      fontWeight: bold,
      textDecoration: 'none',

      '&:hover': {
        color: evisortBlue,
        textDecoration: 'none',
      },
    },
  },
  resultsItemIcon: {
    display: 'flex',
    margin: '0 4px',
  },
  resultsItemNameLink: {
    margin: 0,
    padding: 0,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  resultsItemName: {
    margin: 0,
    padding: 0,
    color: black2,
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '18px',
    fontWeight: bold,
    cursor: 'pointer',
    '&:hover': {
      color: evisortBlue,
    },
  },
  resultsItemMetadata: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  metadataItem: {
    width: '200px',
    marginBottom: '16px',
    paddingRight: '16px',
  },
  metadataHeader: {
    marginBottom: '8px',
    color: black3,
    fontSize: '12px',
    fontWeight: semibold,
  },
  metadataBody: {
    color: black1,
    fontSize: '15px',
  },
  metadataSeparator: {
    display: 'inline-block',
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    margin: '0 4px',
    backgroundColor: black1,
  },
  metadataListItem: {
    marginBottom: '8px',
  },
  metadataListShowAll: {
    color: evisortBlue,
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '13px',
    fontWeight: bold,
    cursor: 'pointer',
  },

  toggleProvisionsVisibility: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    color: evisortBlue,
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '14px',
    fontWeight: bold,
    cursor: 'pointer',

    '& svg': {
      marginRight: '4px',
    },
  },
  provisionsWrapper: {
    marginTop: '16px',
  },
  provisionsEmptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '117px',
    border: 'solid 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    backgroundColor: '#fafafa',
    color: black4,
    fontSize: '16px',
    boxShadow: '0 2px 4px 0 rgba(17, 17, 17, 0.05)',
  },
  provisionsTable: {
    width: '100%',
    borderCollapse: 'collapse',
    border: 'solid 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    backgroundColor: '#fafafa',
    textAlign: 'left',
    boxShadow: '0 2px 4px 0 rgba(17, 17, 17, 0.05)',
    '& thead': {
      display: 'block',
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
      '& th': {
        padding: '8px 12px',
        fontSize: '13px',
        fontWeight: bold,
      },
    },
    '& tbody': {
      display: 'block',
      maxHeight: '560px',
      fontSize: '14px',
      overflowY: 'scroll',
      '& td': {
        display: 'table-cell',
        padding: '8px 12px',
      },
    },
    '& tr:not(:last-child)': {
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
    },
  },
  provisionsHeaderTitle: {
    width: '150px',
  },
  provisionsBodyTitle: {
    width: '150px',
    verticalAlign: 'top',
    fontWeight: semibold,
  },
  provisionsBodyText: {
    '& p': {
      marginBlockStart: 0,
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
    },
  },
  tablesItem: {
    display: 'flex',
    flexGrow: '1',
    '&:not(:last-child)': {
      marginBottom: '16px',
    },
    '& > *': {
      width: '100%',
    },
    '& table': {
      width: '100%',
      border: 'solid 1px rgba(0, 0, 0, 0.15)',
      borderRadius: '4px',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      backgroundColor: '#fafafa',
      tableLayout: 'fixed',
      boxShadow: '0 2px 4px 0 rgba(17, 17, 17, 0.05)',
      wordBreak: 'break-word',
      '& thead': {
        '& th': {
          border: 'solid 1px rgba(0, 0, 0, 0.1)',
          padding: '8px 12px',
          fontSize: '13px',
          fontWeight: bold,
        },
      },
      '& tbody': {
        fontSize: '14px',
        '& td, th': {
          border: 'solid 1px rgba(0, 0, 0, 0.1)',
          padding: '8px 12px',
        },
        '& tr:not(:last-child) td': {
          borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
  dropdownMenuButton: {
    backgroundColor: 'transparent',
    border: 'none',
  },
};

export default styles;
