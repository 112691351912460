import { createElement as h } from 'react';
import { createSystem, load } from 'uinix-ui';

import { config } from './config';
import { icons } from './icons';
import { styles } from './styles';
import { theme } from './theme';

const system = createSystem({
  icons,
  styles,
  theme,
});

export const initialize = () => load({ config, h, system });

export { config, system };
