import React, { useRef } from 'react';

import { Box, Layout, StatusText, Tooltip, TruncateText } from '~/eds';

import {
  THINKING_EVENTS_ALL_DONE,
  THINKING_EVENTS_SOURCE,
} from '../chatbot/constants';

type Props = {
  events: string[];
};

export const ThinkingEvents = ({ events }: Props) => {
  const hasFinished = events.some((event) =>
    event.includes(THINKING_EVENTS_ALL_DONE),
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const conatinerHeight =
    containerRef?.current?.getBoundingClientRect().height ?? 0;

  const fadeStyle = {
    opacity: hasFinished ? 0 : 1,
    transition:
      'opacity 1s ease-in-out, transform 1s ease-in-out, margin-top 1s ease-in-out',
    position: 'relative' as const,
    marginTop: hasFinished ? `-${conatinerHeight}px` : '0',
  };

  return (
    <div style={fadeStyle} ref={containerRef}>
      <Layout direction="column">
        {events.map((event, index) => {
          const isLastItem = index === events.length - 1;
          const isEventSource = event.indexOf(THINKING_EVENTS_SOURCE) !== -1;
          return event.indexOf('ALL_DONE') === -1 ? (
            <Layout mb={2} key={`thinking_event_${index}`}>
              {isEventSource ? (
                <Box pl="28px" w="100%" pr={2}>
                  <Tooltip
                    tooltip={event.replace(THINKING_EVENTS_SOURCE, '')}
                    triggerStyle={{ maxWidth: '100%' }}
                  >
                    <TruncateText
                      color="text.secondary"
                      variant="tiny"
                      whiteSpace="nowrap"
                    >
                      {event.replace(THINKING_EVENTS_SOURCE, '')}
                    </TruncateText>
                  </Tooltip>
                </Box>
              ) : (
                <StatusText
                  iconColor={!isLastItem ? 'status.success' : undefined}
                  iconSpacing="s"
                  icon={isLastItem ? 'loading-dashed' : 'check'}
                  text={event}
                />
              )}
            </Layout>
          ) : null;
        })}
      </Layout>
    </div>
  );
};
