export default `
<svg
  aria-hidden="true"
  aria-label="dashboard"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M5 6.5C5 5.6875 5.65625 5 6.5 5H9.5C10.3125 5 11 5.6875 11 6.5V11.5C11 12.3438 10.3125 13 9.5 13H6.5C5.65625 13 5 12.3438 5 11.5V6.5ZM6.5 11.5H9.5V6.5H6.5V11.5ZM13 12.5C13 11.6875 13.6562 11 14.5 11H17.5C18.3125 11 19 11.6875 19 12.5V17.5C19 18.3438 18.3125 19 17.5 19H14.5C13.6562 19 13 18.3438 13 17.5V12.5ZM14.5 17.5H17.5V12.5H14.5V17.5ZM9.5 14C10.3125 14 11 14.6875 11 15.5V17.5C11 18.3438 10.3125 19 9.5 19H6.5C5.65625 19 5 18.3438 5 17.5V15.5C5 14.6875 5.65625 14 6.5 14H9.5ZM9.5 15.5H6.5V17.5H9.5V15.5ZM13 6.5C13 5.6875 13.6562 5 14.5 5H17.5C18.3125 5 19 5.6875 19 6.5V8.5C19 9.34375 18.3125 10 17.5 10H14.5C13.6562 10 13 9.34375 13 8.5V6.5ZM14.5 8.5H17.5V6.5H14.5V8.5Z"
    fill="currentcolor"
  />
</svg>
`;
