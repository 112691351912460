import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, CollapsibleCard, Layout, Progress, Text } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { actions, api } from '~/redux';

import { BOOST_CYCLE } from '../../constants';
import { FieldModel } from '../../fields/types';
import { getNewRatedTestCases } from '../../utils';

interface Props {
  modelVersion: FieldModel;
  readOnly: boolean;
}
export const BoostTrigger = ({ modelVersion, readOnly }: Props) => {
  const enableUnratedBoost = useFlag(FlagType.DocumentXRayUnratedBoost);
  const dispatch = useDispatch();

  const { id: modelId, testCases, version } = modelVersion;

  const [
    optimizePromptModel,
    { isLoading: isTriggeringOptimizeRun },
  ] = api.endpoints.optimizePromptModel.useMutation();

  const {
    data: optimizeTestCases = [],
    isFetching: isLoadingOptimizeTestCases,
  } = api.endpoints.getPromptModelOptimizeTestCases.useQuery({ modelId });

  const ratedCount = getNewRatedTestCases(optimizeTestCases, testCases).length;

  const disableBoost = readOnly
    ? true
    : enableUnratedBoost
    ? false
    : ratedCount < BOOST_CYCLE;

  const [hideBoost, setHideBoost] = useState(disableBoost);

  // hide/unhide boost based on if it is disabled
  useEffect(() => setHideBoost(disableBoost), [disableBoost]);

  const handleOptimize = () => {
    optimizePromptModel({ modelId, version })
      .unwrap()
      .then(() => {
        // TODO: no need to persist in redux
        dispatch(
          actions.setFieldAiOptimize({
            modelId,
            version,
          }),
        );
      });
  };

  const boostAction = {
    disabled: disableBoost,
    level: 'ai' as const,
    text: 'Boost',
    onClick: handleOptimize,
    isLoading: isTriggeringOptimizeRun,
  };

  const optimizeInstructions = (
    <Layout align="center" direction="column" preset="l">
      <Text textAlign="center">
        AI Boost looks for optimizations in the model and instructions to
        improve its accuracy. You can unlock an AI Boost after rating{' '}
        {enableUnratedBoost ? 'any number of' : BOOST_CYCLE} documents.
      </Text>
      <Button {...boostAction} />
    </Layout>
  );

  if (isLoadingOptimizeTestCases) {
    return null;
  }

  return (
    <CollapsibleCard
      header={{
        actions: hideBoost && !disableBoost ? [boostAction] : [],
        calloutContent: disableBoost ? (
          <Progress
            value={ratedCount}
            max={BOOST_CYCLE}
            mode="donut"
            name="boost-progress"
            status="ai"
            statsLabel={{
              formatLabel: (value, max) => `${value}/${max}`,
              variant: 'body-medium',
            }}
          />
        ) : undefined,
        icon: {
          icon: 'ai' as const,
          color: 'status.ai' as const,
        },
        size: 'l',
        title: 'Unlock an AI Boost',
      }}
      isCollapsed={hideBoost}
      name="Unlock an AI Boost"
      status="ai"
      onToggle={setHideBoost}
    >
      {optimizeInstructions}
    </CollapsibleCard>
  );
};
