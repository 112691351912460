import { pilot } from '~/services';
import { PilotId } from '~/types';

interface Request {
  clientId: PilotId;
  algorithms: string[];
}

export const stopReprocessAlgorithms = async ({
  clientId,
  algorithms,
}: Request): Promise<boolean> => {
  const params = { algorithms };

  await pilot.remove(`client/${clientId}/reprocessing-task/`, {
    data: params,
  });
  return true;
};
