import { createSelector } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';

import { DashboardV2Slice } from '../slices/dashboardV2';
import { isActiveFilter } from '../slices/search';

const slice = (rootState: any): DashboardV2Slice =>
  rootState[SliceType.DashboardV2];

export const selectCharts = createSelector(slice, (state) => state.charts);
const _selectColumnOrder = createSelector(slice, (state) => state.columnOrder);
export const selectColumnOrder = createSelector(
  _selectColumnOrder,
  (columnOrder) => columnOrder.map(String),
);
export const selectColumnWidths = createSelector(
  slice,
  (state) => state.columnWidths,
);
export const selectCurrentChart = createSelector(
  slice,
  (state) => state.currentChart,
);
export const selectActiveDashboard = createSelector(
  slice,
  (state) => state.activeDashboard,
);
export const selectDashboardDirty = createSelector(
  slice,
  (state) => state.dashboardDirty,
);
export const selectFieldValues = createSelector(
  slice,
  (state) => state.fieldValues,
);
export const selectPage = createSelector(slice, (state) => state.page);
export const selectPageSize = createSelector(slice, (state) => state.pageSize);
export const selectSearchText = createSelector(
  slice,
  (state) => state.searchText,
);
export const selectQueryBuilder = createSelector(
  slice,
  (state) => state.queryBuilder,
);
export const selectQueryBuilderPanelVisible = createSelector(
  slice,
  (state) => state.queryBuilderPanelVisible,
);

export const selectSelectedFilters = createSelector(
  slice,
  (state) => state.selectedFilters,
);
export const selectActiveFilters = createSelector(
  selectSelectedFilters,
  (filters) => filters.filter(isActiveFilter),
);
export const selectCrossFilters = createSelector(
  slice,
  (state) => state.crossFilters,
);
export const selectSortBy = createSelector(slice, (state) => state.sortBy);
export const selectIntervals = createSelector(
  slice,
  (state) => state.intervals,
);
export const selectChartWidths = createSelector(
  slice,
  (state) => state.chartWidths,
);

export const selectResultsCache = createSelector(
  slice,
  (state) => state.searchResultCache,
);
