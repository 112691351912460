import { useMemo } from 'react';

import { parseDataFieldSections } from '~/components/Workflow/Workflow.utils';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { AllDataFields, FieldSectionsResponse } from '~/types';

export const useDataFieldSection = () => {
  const client = useCurrentUser()?.client;

  const { data, isFetching } = api.endpoints.getDataFieldSections.useQuery(
    client,
    {
      skip: !client,
    },
  ) as FieldSectionsResponse;

  const allDataFields: AllDataFields = useMemo(() => {
    return parseDataFieldSections(data) as AllDataFields;
  }, [data]);

  return { allDataFields, isFetching };
};
