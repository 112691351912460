import axios from 'axios';

import { buildFilterQuery } from '~/redux/api/methods/searchV3';

import { pilot } from '../../services';

// PAGE DATA
export const getDocumentViewPageData = (documentId, callback, errorHandler) => {
  // TODO: refactor this so that either getDocument or getDocumentContent is the source of truth for the document_name
  //       it will make maintaining the backend endpoint logic much easier going forward
  return pilot
    .concurrentRequests([
      getDocument(documentId),
      getDocumentContent(documentId),
      getDocumentInformationSections(documentId),
      getDocumentTables(documentId),
    ])
    .then(
      axios.spread(
        (
          documentRes,
          documentContentRes,
          informationSectionsRes,
          documentTableRes,
        ) =>
          callback(
            documentRes,
            documentContentRes,
            informationSectionsRes,
            documentTableRes,
          ),
      ),
    )
    .catch((err) => errorHandler(err));
};

export const bulkMove = (
  folderId,
  documents,
  folders,
  parent_folder,
  select_all,
) =>
  pilot.put(`folder/${folderId}/move/`, {
    documents,
    folders,
    parent_folder,
    select_all,
  });

export const bulkCopy = (
  folderId,
  documents,
  folders,
  parent_folder,
  select_all,
) =>
  pilot.put(`folder/${folderId}/copy/`, {
    documents,
    folders,
    parent_folder,
    select_all,
  });

export const bulkDelete = (
  folderId,
  documents,
  folders,
  select_all,
  reason,
  extra_details,
  force = false,
) =>
  pilot.put(`folder/${folderId}/delete/`, {
    documents,
    folders,
    select_all,
    reason,
    extra_details,
    force,
  });

export const getMultiEditData = (folderId, documents, folders, select_all) =>
  pilot.post(`folder/${folderId}/bulk-edit/`, {
    documents,
    folders,
    select_all,
  });

export const bulkUpdate = (folderId, data) =>
  pilot.put(`folder/${folderId}/bulk-edit/`, data);

// SINGLE DOCUMENT DATA
export const getDocument = (documentId) => pilot.get(`document/${documentId}/`);

export const getDocumentAlgoStatus = (documentId) =>
  pilot.get(`document/${documentId}/algo-status/`);

export const getDocumentContent = (documentId) =>
  pilot.get(`document/content/${documentId}/`);

export const getDocumentPath = (documentId) =>
  pilot.get(`path/document/${documentId}/`);

export const getDocHandlerPaths = (docHandlerIds) =>
  pilot.get('path/documents/', { params: { ids: docHandlerIds } });

export const renameDocument = (documentId, documentName, parentFolder) => {
  return pilot.put(`document/rename/${documentId}`, {
    folder: parentFolder,
    document_name: documentName,
  });
};

export const editDocument = (documentId, data) =>
  pilot.put(`/document/update/${documentId}`, data);

export const deleteDocument = (documentId, reason, extra_details) =>
  pilot.remove(`document/${documentId}/`, { data: { reason, extra_details } });

export const getDocumentOCR = (documentId) =>
  pilot.get(`/document/download/ocr/${documentId}`, { responseType: 'blob' });

export const getDocumentPDF = (documentId) =>
  pilot.get(`/document/download/pdf/${documentId}`, { responseType: 'blob' });

export const getDocumentInformationSections = (documentId) =>
  pilot.get(`/document/${documentId}/document-information/`);

export const getDocumentTables = (documentId) =>
  pilot.get(`/document/${documentId}/tables/`);

export const updateDocumentTable = (documentId, tableId, data) =>
  pilot.put(`/document/${documentId}/tables/${tableId}/`, { html: data });

export const deleteDocumentTable = (documentId, tableId) =>
  pilot.remove(`/document/${documentId}/tables/${tableId}/`);

export const getUserCanModifyFolder = (folderId = null) => {
  const url = folderId
    ? `/folder/visibility/${folderId}/`
    : '/folder/visibility/';
  return pilot.get(url);
};

export const getFolderACL = (folderId = null, clientId) => {
  const url = folderId
    ? `/folder/visibility/${folderId}/`
    : '/folder/visibility/';
  return pilot.get(url, { params: { client_id: clientId } });
};

//SINGLE FOLDER DATA
export const createFolder = (folderName, parentFolder) => {
  return pilot.post('/folder/', {
    parent_folder: parentFolder,
    folder_name: folderName,
  });
};

export const updateFolderAccessSettings = (
  folderId,
  folderName,
  parentFolder,
  visibilityLevel,
  folderAccessList,
  departmentAccessList,
  clientId,
) => {
  return pilot.put(
    `/folder/update/${folderId}/`,
    {
      parent_folder: parentFolder,
      folder_name: folderName,
      id: folderId,
      visibility_level: visibilityLevel,
      folder_access_list: {
        members: folderAccessList,
        departments: departmentAccessList,
      },
    },
    { params: { client_id: clientId } },
  );
};

export const createDocumentProvision = ({
  documentId,
  provisionName,
  htmlTokens,
  coordinates,
  content,
  selectedApplyTo,
  sampleName,
  searchQuery,
}) => {
  const query = searchQuery
    ? buildFilterQuery({
        booleanQuery: '',
        filters: searchQuery,
      })
    : undefined;

  return pilot.post('/document/provision/', {
    coordinates,
    document: documentId,
    name: provisionName,
    html_tokens: htmlTokens,
    apply_to_all: selectedApplyTo,
    content: content,
    sample_name: sampleName,
    search_query: query,
  });
};

export const getDocumentProvisions = (documentId) => {
  return pilot.get(`/document/${documentId}/provisions/`);
};

export const deleteDocumentProvision = (documentId, provisionId) => {
  const params = { document: documentId };
  return pilot.remove(`/document/provision/${provisionId}/`, { params });
};

export const updateDocumentProvision = (
  documentId,
  provisionId,
  clauseId,
  provisionName,
  htmlTokens,
  content,
  selectedApplyTo,
) => {
  return pilot.put(`/document/provision/${provisionId}/`, {
    document: documentId,
    clause_id: clauseId,
    name: provisionName,
    html_tokens: htmlTokens,
    apply_to_all: selectedApplyTo,
    content: content,
  });
};

export const getDocumentsDefaultViewset = () => pilot.patch('viewset/default/');

export const getDocumentsViewsetById = (viewId) =>
  pilot.patch(`viewset/${viewId}/load/`);

export const getDocumentsViewsetFields = () => pilot.get('viewset/fields/');

export const getDocumentsViewsets = (data) => pilot.get('viewset/', data);

export const saveDocumentsViewset = (data) => pilot.post('viewset/', data);

export const editDocumentsViewsetFields = (data) => pilot.put('viewset/', data); // edit columns

export const editDocumentsViewset = (id, name) =>
  pilot.patch(`viewset/${id}/`, { new_name: name }); // rename view

export const deleteDocumentsViewset = (viewId) =>
  pilot.remove(`viewset/${viewId}`);

export const getContent = (folderId = '') => {
  return pilot.get(`folder/content/${folderId}/`);
};

export const getFolderData = (
  folderId = null,
  order_by,
  order,
  page = 1,
  pageSize,
) => {
  const contentUrl = folderId
    ? `folder/content/${folderId}/?order_by_field=${order_by}&order=${order}&page=${page}&page_size=${pageSize}`
    : `folder/content/?order_by_field=${order_by}&order=${order}&page=${page}&page_size=${pageSize}`;
  return pilot.get(contentUrl);
};

export const renameFolder = (folderId, folderName, parentFolder) => {
  return pilot.put(`/folder/rename/${folderId}/`, {
    parent_folder: parentFolder,
    folder_name: folderName,
  });
};

export const reprocessDocument = (documentId) =>
  pilot.put(`/document/reprocess/${documentId}`);

export const getDocumentStatus = (documentId) =>
  pilot.get(`document/${documentId}/status/`);

export const updateDocumentStatus = (documentId, status) => {
  return pilot.put(`/document/${documentId}/status/`, {
    doc_status: status,
  });
};

// TODO: use DocumentGroupsPage endpoints instead
export const getGroupForDocument = (handlerId, includeTree = false) => {
  const params = { handler: handlerId, includeTree: includeTree };
  return pilot.get('/group/', { params });
};

export const getGroupsForDocuments = (documentIds, callback) => {
  const promises = documentIds.map((documentId) =>
    getGroupForDocument(documentId),
  );
  // this is only used for rendering the group icon for now so we'll just swallow the errors
  const promisesResolved = promises.map((promise) => promise.catch(() => {}));
  return pilot.concurrentRequests(promisesResolved).then((data) => {
    for (let i = 0; i < data.length; i++) {
      const groupResp = data[i];
      if (!groupResp) continue;
      groupResp.documentId = documentIds[i];
    }
    data = data.filter((resp) => !!resp);
    callback(data);
  });
};
