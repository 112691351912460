export const widths = {
  'button.max-width': 300,
  'chip.max-width': 240,
  'chip.auto.max-width': 'min(100%, 240px)', // note: must be based on chip.max-width
  'accordion.min-width': 320,
  'container.width': 972,
  'dateinput.date.width': 72,
  'dateinput.width': 280,
  'divider.width.s': 20,
  'dropdown.max-width': 600,
  'dropdown.min-width': 252,
  'form.width.m': 400,
  'input.min-width': 252,
  'input.s.width': 136,
  'input.m.width': 260,
  'input.l.width': 520,
  'page-overlay.m': 600,
  'page-overlay.l': 900,
  'navbar.min-width': 200,
  'navlink.max-width': 200,
  'sidebar.width.s': 320,
  'sidebar.width.m': 504,
  'panel.width.m': 504,
  'panel.width.l': 724,
  'sideContent.width': 364,
  'step.bar.width': 20,
  'step.separator.width': 16,
  'toast.width': 520,
  'tooltip.max-width': 328,
};
