import React from 'react';

const ProvisionIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          fill="currentColor"
          fillOpacity={props.isHovered ? '1' : '.5'}
          fillRule="nonzero"
          d="M10.833 7.5H2.5a.836.836 0 0 0-.833.833c0 .459.375.834.833.834h8.333a.836.836 0 0 0 .834-.834.836.836 0 0 0-.834-.833zm0-3.333H2.5A.836.836 0 0 0 1.667 5c0 .458.375.833.833.833h8.333A.836.836 0 0 0 11.667 5a.836.836 0 0 0-.834-.833zM15 10.833v-2.5a.836.836 0 0 0-.833-.833.836.836 0 0 0-.834.833v2.5h-2.5a.836.836 0 0 0-.833.834c0 .458.375.833.833.833h2.5V15c0 .458.375.833.834.833A.836.836 0 0 0 15 15v-2.5h2.5a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.834H15zM2.5 12.5h5a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.834h-5a.836.836 0 0 0-.833.834c0 .458.375.833.833.833z"
        />
      </g>
    </svg>
  );
};

export default ProvisionIcon;
