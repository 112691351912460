import React, { useMemo } from 'react';

import { Select } from '~/eds';
import { useClientId } from '~/hooks';
import { api } from '~/redux';

interface Props {
  value: string[];
  onChange: (updatedValue: string[]) => void;
}

export const ClientContractTypeSelect = ({ value, onChange }: Props) => {
  const clientId = useClientId();

  const {
    data: contractTypes = [],
    isFetching: isLoading,
  } = api.endpoints.getClientContractTypes.useQuery({
    clientId,
  });

  const options = useMemo(
    () =>
      contractTypes.map((contractType) => ({
        label: contractType,
        value: contractType,
      })),
    [contractTypes],
  );

  return (
    <Select
      enableSelectAll={false}
      enableSorting={true}
      isMulti={true}
      isLoading={isLoading}
      name="document type select"
      placeholder="Select document types"
      value={value}
      options={options}
      onChange={(updatedValue) => onChange(updatedValue ?? [])}
    />
  );
};
