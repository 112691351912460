import { FieldId } from '~/evifields';
import { SearchFilter } from '~/types';

import {
  fetchFiltersV2,
  SearchFilterApiResponse,
  SearchType,
} from '../methods';
import { toEviFilterType } from '../methods/searchV3';

export const getFiltersV2 = async ({ type }: { type: SearchType }) => {
  const response = await fetchFiltersV2({ type });

  return Object.fromEntries(
    response.data.map((filter) => [
      filter.id,
      convertSearchV2ToSearchFilter(filter.attributes, filter.id),
    ]),
  ) as Record<FieldId, SearchFilter>;
};

function convertSearchV2ToSearchFilter(
  filterV2: SearchFilterApiResponse,
  id: FieldId,
): SearchFilter {
  return {
    id,
    is_chartable: filterV2.is_chartable,
    is_requestable_column: filterV2.is_requestable_column,
    operators: filterV2.operators.map((op) =>
      `${op.cardinality}` === 'infinity'
        ? { ...op, cardinality: Number.POSITIVE_INFINITY }
        : op,
    ),
    label: filterV2.label,
    section: filterV2.section,
    settings: {},
    type: toEviFilterType(filterV2.type),
  };
}
