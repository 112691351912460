import React, { memo } from 'react';

import { Input } from '../../../types';
import { DayPicker } from '../DayPicker';
import { CommonProps, DateRange } from '../types';

type Props = Input<DateRange> & CommonProps;

export const DateRangeInput = memo((props: Props) => {
  return <DayPicker isRange {...props} />;
});
