import { useState } from 'react';

import {
  ActionsMenu,
  Box,
  Icon,
  Layout,
  LoadingSpinner,
  Text,
  Tooltip,
  useDeleteModal,
  useDeletePermanentModal,
  useToast,
} from '~/eds';
import { api } from '~/redux';
import { Question } from '~/types';

import { useEditQuestionModal } from './useEditQuestionModal';

interface QuestionItemProps {
  question: Question;
  onQuestionClick: (question: Question) => void;
  disableActions?: boolean;
  disableRun?: boolean;
  status?: 'running' | 'complete' | 'pending' | 'idle';
}

export const QuestionItem = ({
  disableActions,
  disableRun,
  status,
  question,
  onQuestionClick,
}: QuestionItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  // pending means that it's on the queue to be run
  const isRunning = status === 'running' || status === 'pending';
  const isComplete = status === 'complete';

  const [
    updateQuestion,
    { isLoading: isUpdating },
  ] = api.endpoints.updateQuestion.useMutation();

  const [
    deleteQuestion,
    { isLoading: isDeleting },
  ] = api.endpoints.deleteQuestion.useMutation();

  const { toast } = useToast();

  const [editModal, showEditModal] = useEditQuestionModal({ question });

  const handlePin = async () => {
    try {
      await updateQuestion({
        id: question.id,
        pinnedAt: question.pinnedAt ? null : new Date(),
      }).unwrap();
      toast({
        status: 'success',
        message: `The question has been successfully ${
          question.pinnedAt ? 'unpinned' : 'pinned'
        }.`,
      });
    } catch (apiError: any) {
      toast({
        status: 'danger',
        message: `The ${
          question.pinnedAt ? 'unpin' : 'pin'
        } action failed due to ${apiError.errors[0].title}.`,
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteQuestion({ id: question.id }).unwrap();
      toast({
        status: 'success',
        message: 'The question has been successfully deleted.',
      });
    } catch (apiError: any) {
      toast({
        status: 'danger',
        message: `The delete action failed due to ${apiError.errors[0].title}.`,
      });
    }
  };

  const [
    confirmationDeleteModal,
    showConfirmationDeleteModal,
  ] = useDeletePermanentModal({
    confirmText: 'DELETE',
    children: (
      <Text>
        This question is also included in {question.questionGroupIds.length}{' '}
        question groups. Are you sure you want to delete the question you
        selected? You won't be able to undo this action.
      </Text>
    ),
    title: 'Delete Saved Question',
    onDelete: handleDelete,
    isDeleting,
  });

  const [deleteModal, showDeleteModal] = useDeleteModal({
    onDelete: handleDelete,
    isDeleting,
    title: 'Delete Saved Question',
    children: (
      <Text>
        Are you sure you want to delete the 1 item you have selected? You won't
        be able to undo this action.
      </Text>
    ),
  });

  const questionName = question.name ?? question.text;
  const isNameTruncated = questionName.length > 100;

  const truncatedText = isNameTruncated
    ? `${questionName.slice(0, 100)}...`
    : questionName;

  // we should hide if there are any questions running but not if the actual question is running
  const shouldHideRun = disableRun && !isRunning;
  // we show if it's hoevered and not hidden or if the question is running
  const isRunButtonVisible =
    (isHovered && !shouldHideRun) || isRunning || isComplete;

  const runButtonText = isRunning
    ? 'Running Question...'
    : isComplete
    ? 'Running Complete'
    : 'Run Question';

  return (
    <Layout
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      align="flex-start"
      borderRadius="8px"
      p={4}
    >
      <Layout mr={4}>
        {isComplete ? (
          <Icon icon="check" color="status.success" />
        ) : isRunning ? (
          <LoadingSpinner mode="dashed" />
        ) : (
          <Icon icon="question" color="status.inactive" />
        )}
      </Layout>
      <Layout
        flex={1}
        direction="column"
        align="flex-start"
        role="button"
        data-eds-styles-disable-interactable
        onClick={() => {
          if (!disableRun && !isRunning) {
            onQuestionClick(question);
          }
        }}
      >
        <Tooltip tooltip={isNameTruncated ? questionName : ''}>
          <Text>{truncatedText}</Text>
        </Tooltip>
        <Box styles={{ visibility: isRunButtonVisible ? 'visible' : 'hidden' }}>
          <Text size="s" variant="tiny" color="action.link">
            {runButtonText}
          </Text>
        </Box>
      </Layout>
      {!disableActions && (
        <Layout align="flex-start" justify="flex-end" minW={38} minH={40}>
          {isHovered && !disableRun ? (
            <ActionsMenu
              disabled={isUpdating || isDeleting}
              direction="right"
              name="question item actions"
              enablePortal={true}
              actions={[
                {
                  label: question.pinnedAt ? 'Unpin' : 'Pin',
                  value: question.pinnedAt ? 'unpin' : 'pin',
                  onClick: () => handlePin(),
                },
                {
                  label: 'Edit',
                  value: 'edit',
                  onClick: showEditModal,
                },
                {
                  label: 'Delete',
                  value: 'delete',
                  onClick: question.questionGroupIds.length
                    ? showConfirmationDeleteModal
                    : showDeleteModal,
                },
              ]}
            />
          ) : (
            question.pinnedAt && <Icon icon="pin" size="s" />
          )}
        </Layout>
      )}
      {question.questionGroupIds.length ? confirmationDeleteModal : deleteModal}
      {editModal}
    </Layout>
  );
};
