import React from 'react';
import { components, OptionProps } from 'react-select';

import { Option as IOption } from '../../../types';
import { MenuOption } from '../../Menu';
import { resetGetStyles } from './resetGetStyles';

export const SELECT_ALL_OPTION: IOption<any> = {
  label: 'Select All',
  value: '__EDS_SELECT_ALL_VALUE__',
};

export const toValue: <V extends unknown>(option: IOption<V>) => V = (option) =>
  option.value;

export const Option = (props: OptionProps<IOption, boolean>) => {
  const { data: option, isFocused, isSelected, selectProps } = props;
  const {
    enableOptionIndicator,
    inputValue,
    isAllSelected,
    isIndeterminate,
    isMulti,
  } = selectProps;

  return (
    <components.Option {...props} getStyles={resetGetStyles}>
      <MenuOption
        enableIndicator={enableOptionIndicator}
        isFocused={isFocused}
        isIndeterminate={
          option.value === SELECT_ALL_OPTION.value && isIndeterminate
        }
        isMulti={isMulti}
        isSelected={
          (option.value === SELECT_ALL_OPTION.value && isAllSelected) ||
          isSelected
        }
        option={option}
        search={inputValue}
      />
    </components.Option>
  );
};
