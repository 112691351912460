import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { api, selectors } from '~/redux';
import documentGroup from '~/redux/slices/documentGroup';
import { MODAL_REMOVE_DOCUMENT_IN_GROUP } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Modal } from '~/ui/';

const RemoveDocumentInGroupModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(selectors.selectDocGroupModal);
  const resetModal = () => dispatch(documentGroup.actions.setModal());
  const { modalType, context } = modal || {};
  const { id } = context || {};

  const [
    removeDocumentInGroup,
    removeDocumentResult,
  ] = api.endpoints.removeDocumentInGroup.useMutation();
  const {
    isError: removeDocumentError,
    isSuccess: removeDocumentSuccess,
  } = removeDocumentResult;

  const actionButton = {
    text: 'Remove',
    variant: 'red',
    promise: () => removeDocumentInGroup(id),
  };

  useEffect(() => {
    if (removeDocumentError) {
      showToast(ERROR, 'Remove document from Group failed. Please try again.');
    }
  }, [removeDocumentError]);

  useEffect(() => {
    if (removeDocumentSuccess) {
      showToast(SUCCESS, 'Document has been successfully removed.');
    }
  }, [removeDocumentSuccess]);

  if (modalType === MODAL_REMOVE_DOCUMENT_IN_GROUP) {
    return (
      <Modal
        actionButton={actionButton}
        title="Remove Document from Group?"
        visible={modalType === MODAL_REMOVE_DOCUMENT_IN_GROUP}
        onCancel={resetModal}
        onHide={resetModal}
      >
        Are you sure you want to remove {context?.handlers[0]?.document_name}{' '}
        from this group? You won’t be able to undo this action.
      </Modal>
    );
  } else {
    return null;
  }
};

export default RemoveDocumentInGroupModal;
