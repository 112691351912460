export enum LoginViewType {
  Domain = 'DOMAIN',
  Email = 'EMAIL',
  Error = 'ERROR',
  FormSubmitting = 'FORM_SUBMITTING',
  FormSubmitted = 'FORM_SUBMITTED',
  LoggedIn = 'LOGGED_IN',
  LoggedInWithWelcome = 'LOGGED_IN_WITH_WELCOME',
  SamlError = 'SAML_ERROR',
  TokenMissing = 'TOKEN_MISSING',
}
