import { createSelector } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';

import { RootState, ChatbotState as State } from '../types';

const selectState = (rootState: RootState): State =>
  rootState[SliceType.Chatbot];

export const selectCurrentChatbot = createSelector(
  selectState,
  (state: State) => state.entity,
);

export const selectPanelMode = createSelector(
  selectState,
  (state: State) => state.mode,
);
export const selectChatbotContext = createSelector(
  selectState,
  (state: State) => state.context,
);

export const selectChatbotSelectedContext = createSelector(
  selectState,
  (state: State) => state.selectedContext,
);

export const selectCurrentSource = createSelector(
  selectState,
  (state: State) => state.sources[state.activeSourceIndex ?? 0],
);

export const selectIsChatbotVisible = createSelector(
  selectState,
  (state: State) => state.isVisible,
);

export const selectIsSideContentVisible = createSelector(
  selectState,
  (state: State) => state.sideContent.isVisible,
);

export const selectSideContentTab = createSelector(
  selectState,
  (state: State) => state.sideContent.selectedTab,
);

export const selectChatbotWidth = createSelector(
  selectState,
  (state: State) => state.width,
);
export const selectIsTextInputDisabled = createSelector(
  selectState,
  (state: State) => state.isTextInputDisabled,
);
export const selectMessageQueue = createSelector(
  selectState,
  (state: State) => state.messageQueue.queue,
);
export const selectIsMessageQueueProcessing = createSelector(
  selectState,
  (state: State) =>
    state.messageQueue.queue.some(
      (queueMessage) => queueMessage.status !== 'complete',
    ),
);
export const selectIsMessageQueueRunning = createSelector(
  selectState,
  (state: State) =>
    state.messageQueue.queue.some(
      (queueMessage) => queueMessage.status === 'running',
    ),
);
export const selectCurrentConversationId = createSelector(
  selectState,
  (state: State) => state.currentConversationId,
);
export const selectProcessingMessage = createSelector(
  selectMessageQueue,
  (queue) => queue[0],
);
export const selectMessageQueueIndex = createSelector(
  selectState,
  (state: State) => state.messageQueue.index,
);
