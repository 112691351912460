import PropTypes from 'prop-types';

import { withCurrentUser, withUsers } from '~/hocs';

import { getUserName } from '../../utils/user';

function UserName({
  userId,
  // injected
  users,
  currentUser,
}) {
  const user = users[userId] || null;
  const userName = getUserName(user);
  return currentUser && currentUser.id === userId
    ? userName + ' (You)'
    : userName;
}

UserName.propTypes = {
  // TODO: enforce all userIds to be string-type in the future
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default withUsers(withCurrentUser(UserName));
