import pluralize from 'pluralize';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  addDepartment,
  deleteDepartment,
  getClientDepartments,
  getClientDepartmentsInfo,
  renameDepartment,
} from '~/components/Admin/DepartmentsPage';
import EcCard from '~/components/Shared/EcCard';
import EcDepartmentsTable from '~/components/Shared/EcDepartmentsTable';
import EcMembersTable from '~/components/Shared/EcMembersTable';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { PAGE_START } from '~/constants/page';
import {
  ContentContainer,
  Layout,
  Modal,
  PageLayout,
  Paginate,
  StatusMessage,
  Text,
} from '~/eds';
import { QueryParamType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { withCurrentUser } from '~/hocs';
import { api } from '~/redux';
import { TagType } from '~/redux/api/TagType';
import { generateNav, RoutePathType } from '~/routing';
import { MODAL_DELETE_DEPARTMENT, MODAL_NAME } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';

const tableStyles = { padding: '0 16px' };
const PAGE_SIZE = 20; //BE defines

class Page extends Component {
  constructor(props) {
    super(props);

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.onDepartmentCreate = this.onDepartmentCreate.bind(this);
    this.onDepartmentRename = this.onDepartmentRename.bind(this);
    this.onDepartmentDelete = this.onDepartmentDelete.bind(this);
    this.handleAddUnassignedUsers = this.handleAddUnassignedUsers.bind(this);

    this.state = {
      selectedDepartment: null,
      selectedItem: null,
      unassignedUsers: [],
      unassignedUsersDisplayed: false,
      departmentsSelected: [],
    };
  }

  componentDidMount() {
    const page = getPageSearchQueryByKey(QueryParamType.Page, PAGE_START);
    this.getClientDepartmentsInfo();
    this.setState({ page }, () => {
      this.getClientDepartments();
      updatePageSearchQuery({
        [QueryParamType.Page]: page,
      });
    });
  }

  getClientDepartmentsInfo() {
    const {
      match: {
        params: { clientId },
      },
    } = this.props;

    getClientDepartmentsInfo(clientId)
      .then((data) => {
        const { unassignedUsers } = data;
        this.setState({
          loading: false,
          unassignedUsers,
        });
      })
      .catch((error) => {
        const {
          response: { status },
        } = error;
        if (status === 403) {
          this.setState({ noAccess: true });
        } else {
          this.setState({ errorLoading: true });
        }
      });
  }

  getClientDepartments() {
    const {
      match: {
        params: { clientId },
      },
    } = this.props;
    const { page } = this.state;

    this.setState({ loading: true });

    // to re-fetch the departments we need to invalidate the flag,
    // since this component wasn't connected to redux and doesn't use RTKQ to manage requests,
    // we need to do it manually.
    this.props.dispatch(
      api.util.invalidateTags([{ type: TagType.Department, id: 'LIST' }]),
    );

    getClientDepartments(clientId, page)
      .then((res) => {
        this.setState({
          departments: res,
          loading: false,
          errorLoading: false,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          this.setState({
            loadingUsers: false,
            errorLoadingDepartments: false,
          });
        } else {
          this.setState({ loadingUsers: false, errorLoadingDepartments: true });
        }
      });
  }

  handleShowModal(modal, departmentName, forSubdepartment = false) {
    this.setState({
      modal,
      selectedDepartment: departmentName,
      forSubdepartment,
    });
  }

  handleHideModal() {
    this.setState({
      modal: null,
      selectedItem: null,
    });
  }

  handleAddUnassignedUsers(members) {
    this.setState({ members });
  }

  handlePageClick(clickedPage) {
    const selectedPage = clickedPage.selected + 1;
    this.setState({ page: selectedPage }, () => {
      this.getClientDepartments();
      updatePageSearchQuery({ [QueryParamType.Page]: selectedPage }, true);
    });
  }

  renderPagination() {
    const { departments, page } = this.state;
    return (
      departments &&
      departments.count > PAGE_SIZE && (
        <Layout justify="center">
          <Paginate
            totalCount={departments.count}
            pageSize={PAGE_SIZE}
            pageIndex={page}
            onUpdatePageIndex={(selectedPage) => {
              this.setState({ page: selectedPage }, () => {
                this.getClientDepartments();
                updatePageSearchQuery(
                  { [QueryParamType.Page]: selectedPage },
                  true,
                );
              });
            }}
          />
        </Layout>
      )
    );
  }

  renderDepartmentNameModal() {
    const { selectedDepartment, forSubdepartment } = this.state;

    const labelText = 'DEPARTMENT NAME';
    let title;
    let itemsCurrentName;
    let itemsParentDepartmentId;

    let confirmButtonText;
    let handleNameChange;

    if (selectedDepartment && !forSubdepartment) {
      title = 'Rename Department';
      itemsCurrentName = selectedDepartment.department_name;
      itemsParentDepartmentId = null;
      confirmButtonText = 'Save';
      handleNameChange = this.onDepartmentRename;
    } else if (selectedDepartment && !!forSubdepartment) {
      title = 'Add Subdepartment';
      itemsCurrentName = '';
      itemsParentDepartmentId = selectedDepartment.department_id;

      confirmButtonText = 'Create Subdepartment';
      handleNameChange = this.onDepartmentCreate;
    } else {
      title = 'Add Department';
      itemsCurrentName = '';
      itemsParentDepartmentId = null;

      confirmButtonText = 'Create Department';
      handleNameChange = this.onDepartmentCreate;
    }

    return (
      <EcModal
        id={OnboardingIdType.AddDepartmentModal}
        modalType={MODAL_NAME}
        width="560px"
        title={title}
        labelText={labelText}
        itemsCurrentName={itemsCurrentName}
        itemsParentFolderId={itemsParentDepartmentId}
        confirmButtonText={confirmButtonText}
        handleNameChange={handleNameChange}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderDeleteDepartmentModal() {
    const { selectedDepartment } = this.state;

    return (
      <Modal
        title="Delete Department?"
        children={
          <Text>
            Are you sure you want to delete {selectedDepartment.department_name}{' '}
            department? You won’t be able to undo this action.
          </Text>
        }
        onHide={this.handleHideModal}
        isVisible={true}
        primaryAction={{
          text: 'Delete',
          variant: 'danger',
          onClick: this.onDepartmentDelete,
        }}
      />
    );
  }

  onDepartmentCreate(name) {
    const {
      match: {
        params: { clientId },
      },
    } = this.props;

    const { selectedDepartment } = this.state;

    const members = [];
    const parent = selectedDepartment ? selectedDepartment.department_id : null;

    addDepartment(name, members, parent, clientId)
      .then((data) => {
        this.getClientDepartments();
        showToast(
          SUCCESS,
          `${data.department_name} department has been created.`,
        );
      })
      .catch((err) => {
        if (
          err &&
          err.response.status === 400 &&
          err.response &&
          err.response.data
        ) {
          showToast(ERROR, err.response.data[0]);
        } else {
          showToast(
            ERROR,
            'An error occurred while creating the new department.',
          );
        }
      });
  }

  onDepartmentRename(name) {
    const { selectedDepartment } = this.state;
    const {
      match: {
        params: { clientId },
      },
    } = this.props;
    const departmentId = selectedDepartment.department_id;

    renameDepartment(clientId, departmentId, name)
      .then((data) => {
        showToast(SUCCESS, `Department is renamed to ${data.department_name}.`);
        this.getClientDepartments();
      })
      .catch((err) => {
        if (
          err &&
          err.response.status === 400 &&
          err.response &&
          err.response.data
        ) {
          showToast(ERROR, err.response.data[0]);
        } else {
          showToast(
            ERROR,
            'An error occurred while renaming the new department.',
          );
        }
      });
  }

  onDepartmentDelete() {
    const { selectedDepartment } = this.state;
    const departmentId = selectedDepartment.department_id;

    deleteDepartment(departmentId)
      .then(() => {
        showToast(SUCCESS, 'Department is deleted.');
        this.getClientDepartments();
        this.setState({ modal: null });
      })
      .catch(() => {
        showToast(ERROR, 'An error occurred while deleting the department.');
        this.setState({ modal: null });
      });
  }

  renderUnassignedUsers() {
    const { unassignedUsers, unassignedUsersDisplayed } = this.state;

    if (!unassignedUsers.length) {
      return null;
    } else if (!unassignedUsersDisplayed) {
      const users = pluralize('User', unassignedUsers.length);

      return (
        <StatusMessage
          message={`There are ${unassignedUsers.length} ${users} not assigned to any departments.`}
          status="default"
          action={{
            text: `Show ${users}`,
            level: 'action',
            onClick: () => this.setState({ unassignedUsersDisplayed: true }),
          }}
        />
      );
    }
    return (
      <EcCard contentStyles={tableStyles}>
        <EcMembersTable
          header="Unassigned Users"
          listItems={unassignedUsers}
          handleShowModal={this.handleShowModal}
          handleAddUnassignedUsers={this.handleAddUnassignedUsers}
        />
      </EcCard>
    );
  }

  renderDepartmentsTableData() {
    const {
      match: {
        params: { clientId },
      },
    } = this.props;
    const { departments, loading } = this.state;

    if (loading) {
      return (
        <ContentContainer
          loadingContent={{
            isLoading: true,
          }}
        />
      );
    } else if (departments && departments.results.length) {
      return (
        <Layout mt={6}>
          <EcCard contentStyles={tableStyles}>
            <EcDepartmentsTable
              clientId={clientId}
              listItems={departments.results}
              handleShowModal={this.handleShowModal}
            />
          </EcCard>
        </Layout>
      );
    } else {
      return null;
    }
  }

  render() {
    const { modal, errorLoading, noAccess } = this.state;

    const nav = generateNav({
      current: {
        text: 'Departments',
        pathname: RoutePathType.AdminConsoleClientDepartments,
      },
      from: RoutePathType.AdminConsoleClient,
      params: {
        clientId: this.props.currentUser.client,
      },
    });
    const actions = [
      {
        id: OnboardingIdType.AddDepartmentButton,
        level: 'primary',
        text: 'Add Department',
        onClick: () => this.handleShowModal(MODAL_NAME),
      },
    ];

    const noAccessPlaceholder = noAccess
      ? {
          message:
            'You do not have permission to access this page. To request permission, contact your organization administrator.',
        }
      : undefined;

    const errorPlaceholder = errorLoading
      ? { message: 'An error occurred while loading this page.' }
      : undefined;

    return (
      <PageLayout
        title="Departments"
        nav={nav}
        actions={actions}
        placeholderContent={errorPlaceholder || noAccessPlaceholder}
      >
        <Layout direction="column">
          {this.renderUnassignedUsers()}
          {this.renderDepartmentsTableData()}
          {this.renderPagination()}
          {modal === MODAL_NAME ? this.renderDepartmentNameModal() : null}
          {modal === MODAL_DELETE_DEPARTMENT
            ? this.renderDeleteDepartmentModal()
            : null}
        </Layout>
      </PageLayout>
    );
  }
}

Page = connect()(Page);

export default withCurrentUser(Page);
