export default `
<svg
  aria-hidden="true"
  aria-label="sync"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.2031 13.0312C17.7969 12.9062 17.3906 13.125 17.2656 13.5312C16.6094 15.875 14.4219 17.5 11.9844 17.5C9.89062 17.5 8.01562 16.3125 7.07812 14.5H10.2344C10.6406 14.5 10.9844 14.1875 10.9844 13.75C10.9844 13.3438 10.6719 13 10.2344 13H5.23438C4.82812 13 4.48438 13.3438 4.48438 13.75V18.75C4.48438 19.1875 4.82812 19.5 5.23438 19.5C5.67188 19.5 5.98438 19.1875 5.98438 18.75V15.5625C7.23438 17.6562 9.51562 19 11.9844 19C15.0781 19 17.8281 16.9375 18.7031 13.9688C18.8281 13.5625 18.6094 13.1562 18.2031 13.0312ZM18.7656 4.5C18.3281 4.5 18.0156 4.84375 18.0156 5.25V8.46875C16.7656 6.375 14.4844 5 12.0156 5C8.92188 5 6.14062 7.09375 5.26562 10.0625C5.17188 10.4375 5.39062 10.875 5.79688 10.9688C6.20312 11.0938 6.60938 10.875 6.70312 10.4688C7.39062 8.15625 9.57812 6.5 12.0156 6.5C14.0781 6.5 15.9531 7.71875 16.8906 9.5H13.7656C13.3281 9.5 13.0156 9.84375 13.0156 10.25C13.0156 10.6875 13.3281 11 13.7656 11H18.7656C19.1719 11 19.5156 10.6875 19.5156 10.25V5.25C19.5156 4.84375 19.1719 4.5 18.7656 4.5Z"
    fill="currentcolor"
  />
</svg>
`;
