import React, { useState } from 'react';

import { FormField, Modal, TextInput } from '~/eds';
import { Nullable } from '~/types';

const RenameClauseModal = ({
  error,
  onClose,
  onSubmit,
  value,
}: {
  error: string;
  onClose: any;
  onSubmit: any;
  value: string;
}) => {
  const [clauseName, setClauseName] = useState<Nullable<string>>(value);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);

  const doesClauseNameExist = !!clauseName?.trim();
  const isClauseNameOverLimit = (clauseName?.length || 0) > 500;
  const isClauseNameUnchanged = value === clauseName;
  const isClauseNameValid =
    doesClauseNameExist && !isClauseNameOverLimit && !isClauseNameUnchanged;

  const footerDefault = `${clauseName?.length ?? 0}/500`;
  const footerError = `${
    clauseName?.length ?? 0
  }/500 (Name cannot be longer than 500 characters)`;
  const footer = isClauseNameOverLimit
    ? footerError
    : shouldDisplayError && error
    ? error
    : footerDefault;

  const onCancel = () => {
    setShouldDisplayError(false);
    onClose('');
  };

  return (
    <Modal
      title="Rename Clause"
      isVisible
      primaryAction={{
        text: 'Save',
        disabled: !isClauseNameValid || shouldDisplayError,
        onClick: () => {
          setShouldDisplayError(true);
          onSubmit({ oldName: value, newName: clauseName });
        },
      }}
      onHide={onCancel}
      onCancel={onCancel}
    >
      {/* @ts-ignore */}
      <FormField
        label="Rename Clause"
        input={TextInput}
        error={
          isClauseNameOverLimit
            ? footerError
            : shouldDisplayError && error
            ? error
            : ''
        }
        footer={footer}
        value={clauseName}
        onChange={(name) => {
          // @ts-ignore
          setClauseName(name);
          setShouldDisplayError(false);
        }}
      />
    </Modal>
  );
};

export default RenameClauseModal;
