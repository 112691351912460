import React, { useState } from 'react';

import {
  FormField,
  Layout,
  Modal,
  RadioGroup,
  TextInput,
  useToast,
} from '~/eds';
import { api } from '~/redux';

import { BasePartyModalProps, Party } from '../types';

export const AddPartyModal = ({ onHide, clientId }: BasePartyModalProps) => {
  const { toast } = useToast();

  const [addParty, { isLoading }] = api.endpoints.addParty.useMutation();

  const initialParty = {
    id: 0,
    name: '',
    isInternal: true,
  };

  const [newParty, setNewParty] = useState<Party>(initialParty);

  const children = (
    <Layout preset="form-fields">
      <RadioGroup
        name="Add Party"
        title="Select Party"
        options={[
          {
            label: 'Internal Parties',
            value: true,
            info: 'Add a party to you internal list.',
          },
          {
            label: 'Counter Parties',
            value: false,
            info: 'Add a party to your counter list.',
          },
        ]}
        onChange={(value: boolean | null) => {
          setNewParty({ ...newParty, isInternal: value! });
        }}
        value={newParty.isInternal}
      />
      <FormField<string, false>
        label="Add New Party"
        input={TextInput}
        name="Edit Party Name"
        value={newParty.name}
        onChange={(updatedPartyName) =>
          setNewParty({ ...newParty, name: updatedPartyName ?? '' })
        }
      />
    </Layout>
  );

  return (
    <Modal
      children={children}
      enableHideOnPrimaryAction={true}
      isVisible={true}
      primaryAction={{
        isLoading,
        text: 'Add Party',
        disabled: !newParty.name,
        tooltip: !newParty.name
          ? 'Please enter a party name to continue.'
          : 'Add your Party',
        onClick: () => {
          addParty({
            party: newParty,
            clientId,
          })
            .unwrap()
            .then(() => {
              toast({
                message: `Party "${newParty.name}" has been added.`,
                status: 'success',
              });
            })
            .catch((error) => {
              toast({
                message: `${error.response.data.detail}`,
                status: 'danger',
              });
            });
          onHide();
        },
        variant: 'primary',
      }}
      onHide={onHide}
      title="New Party List"
    />
  );
};
