export default `
<svg
  aria-hidden="true"
  aria-label="highlight"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12.4688 17H10.9688L9.59375 18.375C9.25 18.7188 8.78125 18.8125 8.375 18.6875L7.34375 19.7188C7.15625 19.9062 6.90625 20 6.625 20H4C3.4375 20 3 19.5625 3 19V18.875C3 18.5938 3.09375 18.3438 3.28125 18.1562L5.5625 15.875C5.4375 15.4688 5.53125 14.9688 5.875 14.6562L7.21875 13.2812V11.7812C7.21875 11.3125 7.46875 10.8438 7.875 10.5625L16.5312 4.34375C16.8438 4.125 17.1875 4 17.5312 4C17.9688 4 18.4062 4.1875 18.7188 4.5L19.75 5.53125C20.0625 5.84375 20.2188 6.28125 20.2188 6.71875C20.2188 7.0625 20.125 7.40625 19.9062 7.71875L13.6875 16.375C13.4062 16.7812 12.9375 17 12.4688 17ZM7.09375 15.5312L8.71875 17.1562L10.3438 15.5H12.4688L12.9688 14.8125L9.4375 11.2812L8.71875 11.7812V13.9062L7.09375 15.5312ZM13.8438 13.5625L18.6875 6.84375C18.7188 6.8125 18.7188 6.75 18.7188 6.71875C18.7188 6.65625 18.7188 6.625 18.6875 6.59375L17.6562 5.5625C17.625 5.53125 17.5938 5.5 17.5312 5.5C17.5 5.5 17.4375 5.53125 17.4062 5.5625L10.6875 10.4062L13.8438 13.5625ZM5.03125 18.5H6.4375L7.1875 17.75L6.5 17.0625L5.03125 18.5ZM20.25 18.5C20.6562 18.5 21 18.8438 21 19.25C21 19.6875 20.6562 20 20.25 20H10.75C10.3125 20 10 19.6875 10 19.25C10 18.8438 10.3125 18.5 10.75 18.5H20.25Z"
    fill="currentcolor"
  />
</svg>
`;
