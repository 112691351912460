import PT from 'prop-types';
import React, { useState } from 'react';

import styles from '../../styles';
import Box from '../Box';
import Button from '../Button';
import FlexLayout from '../FlexLayout';
import { PX } from '../PageLayout/constants';

function Footer({ backButton, actionButton }) {
  const [isBackButtonLoading, setIsBackButtonLoading] = useState(false);
  const [isActionButtonLoading, setIsActionButtonLoading] = useState(false);

  const handleBackButtonClick = async () => {
    setIsBackButtonLoading(true);
    await backButton?.promise();
    setIsBackButtonLoading(false);
  };

  const handleActionButtonClick = async () => {
    setIsActionButtonLoading(true);
    await actionButton?.promise();
    setIsActionButtonLoading(false);
  };

  return (
    <Box
      bg="white"
      px={PX}
      sx={{
        borderTop: 'border',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <FlexLayout
        justifyContent="space-between"
        mx="auto"
        py={6}
        sx={styles.layout.container}
      >
        {backButton ? (
          <Button
            {...backButton}
            variant="outlined"
            isLoading={isBackButtonLoading}
            onClick={handleBackButtonClick}
          />
        ) : (
          <div />
        )}
        {actionButton && (
          <Button
            {...actionButton}
            isLoading={isActionButtonLoading}
            onClick={handleActionButtonClick}
          />
        )}
      </FlexLayout>
    </Box>
  );
}

// extends Button.prop
const buttonPropTypes = PT.shape({
  /** An async callback.  You should handle exceptions */
  promise: PT.func,
});

Footer.propTypes = {
  /** Primary action button */
  actionButton: buttonPropTypes.isRequired,
  /** Optional back button */
  backButton: buttonPropTypes,
};

export default Footer;
