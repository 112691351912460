export function tokenizeContent(content, tokenRenderers) {
  const parts = content.split(/({{.*?}})/g);
  const tokens = [];
  const tokenTypes = Object.keys(tokenRenderers);

  parts.forEach((part) => {
    let isTextToken = true;
    tokenTypes.forEach((type) => {
      const regexp = new RegExp(`{{${type}:(.*?)}}`, 'g');
      const matched = regexp.exec(part);
      if (matched) {
        const matchedValue = matched[1];
        tokens.push({
          type,
          content: tokenRenderers[type](matchedValue),
        });
        isTextToken = false;
      }
    });

    if (isTextToken) {
      tokens.push({
        type: 'text',
        content: part,
      });
    }
  });
  return tokens;
}

export function getMentions(content, type = 'user') {
  const regexp = new RegExp(`{{${type}:(.*?)}}`, 'g');

  const mentions = [];
  const parts = content.split(/({{.*?}})/g);
  parts.forEach((part) => {
    const matched = regexp.exec(part);
    if (matched) {
      // TODO: Casting to PilotUserId integers until all system IDs are UUIDs
      mentions.push(Number(matched[1]));
    }
  });

  return mentions;
}
