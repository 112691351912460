import React from 'react';

import { Button, Layout, Text } from '~/eds';

function SummaryRowAriba({ children, title, onChange }) {
  return (
    <Layout mt="-1px" p={10}>
      <Text w="30%" color="gray-700" variant="body-bold">
        {title}
      </Text>
      <Layout align="flex-start" w="60%">
        {children}
      </Layout>
      {!onChange || (
        <Layout align="flex-start" w="10%">
          <Button text="Edit" variant="action" onClick={onChange} />
        </Layout>
      )}
    </Layout>
  );
}

export default SummaryRowAriba;
