export default `
<svg
  aria-hidden="true"
  aria-label="review"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M3.51172 6.99438V14.9944C3.51172 15.2756 3.73047 15.4944 4.01172 15.4944H12.8867C12.6992 15.7444 12.5742 15.9944 12.5117 16.2756L12.3242 16.9944H4.01172C2.88672 16.9944 2.01172 16.1194 2.01172 14.9944V6.99438C2.01172 5.90063 2.88672 4.99438 4.01172 4.99438H18.0117C19.1055 4.99438 20.0117 5.90063 20.0117 6.99438V9.02563C19.5117 9.08813 19.0742 9.30688 18.6992 9.68188L18.5117 9.86938V6.99438C18.5117 6.74438 18.2617 6.49438 18.0117 6.49438H4.01172C3.73047 6.49438 3.51172 6.74438 3.51172 6.99438ZM12.2617 11.4944C12.668 11.4944 13.0117 11.8381 13.0117 12.2444C13.0117 12.6819 12.668 12.9944 12.2617 12.9944H5.76172C5.32422 12.9944 5.01172 12.6819 5.01172 12.2444C5.01172 11.8381 5.32422 11.4944 5.76172 11.4944H12.2617ZM5.01172 9.24438C5.01172 8.83813 5.32422 8.49438 5.76172 8.49438H15.2617C15.668 8.49438 16.0117 8.83813 16.0117 9.24438C16.0117 9.68188 15.668 9.99438 15.2617 9.99438H5.76172C5.32422 9.99438 5.01172 9.68188 5.01172 9.24438ZM21.6367 10.8381C22.1055 11.3069 22.1055 12.0881 21.6367 12.5881L20.6992 13.5256L18.4805 11.3069L19.418 10.3694C19.8867 9.90063 20.6992 9.90063 21.168 10.3694L21.6367 10.8381ZM13.7305 16.0256L17.793 11.9944L20.0117 14.2131L15.9492 18.2444C15.8242 18.3694 15.668 18.4631 15.5117 18.5256L13.6055 18.9944C13.4492 19.0256 13.2617 18.9944 13.1367 18.8694C13.0117 18.7444 12.9805 18.5569 13.0117 18.3694L13.4805 16.4944C13.5117 16.3381 13.6055 16.1819 13.7305 16.0256Z"
    fill="currentcolor"
  />
</svg>
`;
