import React from 'react';

import { usePermissions } from '~/hooks';
import { RbacPermission } from '~/types';

export const withPermissions = <P extends object>(
  ElementComponent: React.ComponentType<P>,
  permissions: RbacPermission[],
) => (props: P) => {
  const { hasSomePermissions, checkPermission, isLoading } = usePermissions({
    permissions,
  });

  return (
    <ElementComponent
      {...props}
      hasSomePermissions={hasSomePermissions}
      isPermissionsLoading={isLoading}
      checkPermission={checkPermission}
    />
  );
};
