export default `
<svg
  aria-hidden="true"
  aria-label="cloud-download"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M8.96875 13.7812C8.65625 13.5 8.65625 13.0312 8.96875 12.7188C9.25 12.4375 9.71875 12.4375 10 12.7188L11.25 13.9688V9.75C11.25 9.34375 11.5625 9 12 9C12.4062 9 12.75 9.34375 12.75 9.75V13.9688L13.9688 12.7188C14.25 12.4375 14.7188 12.4375 15 12.7188C15.3125 13.0312 15.3125 13.5 15 13.7812L12.5 16.2812C12.2188 16.5938 11.75 16.5938 11.4688 16.2812L8.96875 13.7812ZM6.5 19C4 19 2 17 2 14.5C2 12.5625 3.25 10.875 5 10.2812V10.25C5 7.375 7.34375 5 10.25 5C11.8125 5 13.25 5.71875 14.2188 6.84375C14.6875 6.625 15.1875 6.5 15.75 6.5C17.8125 6.5 19.5 8.1875 19.5 10.25C19.5 10.4688 19.4688 10.6562 19.4375 10.875C20.9375 11.5312 22 13.0312 22 14.75C22 17.0312 20.2188 18.875 18 19H6.5ZM10.25 6.5C8.15625 6.5 6.5 8.1875 6.5 10.25V11.3438L5.46875 11.6875C4.3125 12.0938 3.5 13.2188 3.5 14.5C3.5 16.1562 4.8125 17.5 6.5 17.5H17.9062C19.3438 17.4375 20.5 16.2188 20.5 14.75C20.5 13.625 19.8125 12.6562 18.8438 12.25L17.7812 11.7812L17.9688 10.625C17.9688 10.5 18 10.375 18 10.25C18 9.03125 16.9688 8 15.75 8C15.4062 8 15.0938 8.09375 14.8125 8.21875L13.8125 8.65625L13.0625 7.8125C12.375 7.03125 11.375 6.5 10.2188 6.5H10.25Z"
    fill="currentcolor"
  />
</svg>
`;
