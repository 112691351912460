const styles = {
  root: {
    alignItems: 'center',
    backgroundColor: 'white',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 1,
  },
};

export default styles;
