import { camelizeKeys, decamelizeKeys } from 'humps';
import { isEmpty, toLower } from 'lodash';

import { types } from '~/eds';
import { pilot } from '~/services';
import { TableQuery } from '~/types';
import { getBasename } from '~/utils/files';
import { formatUserNameAndEmail } from '~/utils/user';

export interface DeletedDocumentResult {
  id: number;
  name: string;
  deletedBy: string; //deleted by user email
  deletedDate: string; //datetime
  deletedById: types.PilotId;
  originalFolder: string;
  fileType: string;
  reason: string;
  extraDetails: string;
  firstName: string; //deleted by user first name
  lastName: string; //deleted by user last name
}

interface PermanentlyDeleteDocumentsRequest {
  documentIds: number[];
  allSelected: boolean;
  filters: Record<string, string[]>;
}

interface RestoreDocumentsRequest {
  documentIds: number[];
  allSelected: boolean;
  folderId: number;
  filters: Record<string, string[]>;
}

interface DeletedDocumentResponse {
  count: number;
  next: string;
  previous: string;
  results: DeletedDocumentResult[];
}

interface DeletedDocumentOriginalFolderRequest {
  substringQuery: string;
}
interface DeletedDocumentOriginalFolder {
  id: number;
  name: string;
  path: string;
}

export interface DeletedDocumentOriginalFolderOption {
  value: string;
  display_value: string;
  path: string;
}

interface DeletedDocumentDeletedByUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface DeletedDocumentDeletedByUserOption {
  value: string;
  label: string;
}

export const getDeletedDocuments = async ({
  page,
  pageSize,
  sort,
  filters,
}: TableQuery): Promise<DeletedDocumentResponse> => {
  const response: DeletedDocumentResponse = await pilot.get(
    '/restoration-center/documents/',
    {
      params: {
        page,
        pageSize,
        order: sort.desc ? 'desc' : 'asc',
        order_by: sort.id,
        ...decamelizeKeys(filters || {}),
      },
    },
  );
  const mappedResults = response?.results.map(
    (result) => camelizeKeys(result) as DeletedDocumentResult,
  );
  return { ...response, results: mappedResults };
};

export const getDeletedDocumentsForCurrentUser = async ({
  page,
  pageSize,
  sort,
  filters,
}: TableQuery): Promise<DeletedDocumentResponse> => {
  const response: DeletedDocumentResponse = await pilot.get(
    '/restoration-center/documents/current-user/',
    {
      params: {
        page,
        pageSize,
        order: sort.desc ? 'desc' : 'asc',
        order_by: sort.id,
        ...decamelizeKeys(filters || {}),
      },
    },
  );
  const mappedResults = response?.results.map(
    (result) => camelizeKeys(result) as DeletedDocumentResult,
  );
  return { ...response, results: mappedResults };
};

export const downloadDeletedDocument = (documentId: number) => {
  return pilot.get(
    `/restoration-center/documents/download/original/${documentId}`,
    {
      responseType: 'blob',
    },
  );
};

export const downloadDeletedDocumentForCurrentUser = (documentId: number) => {
  return pilot.get(
    `/restoration-center/documents/download/current-user/original/${documentId}`,
    {
      responseType: 'blob',
    },
  );
};

export const permanentlyDeleteDocuments = async ({
  documentIds: deletedHandlerIds,
  allSelected: selectAll,
  filters,
}: PermanentlyDeleteDocumentsRequest): Promise<DeletedDocumentResponse> => {
  return pilot.post(
    '/restoration-center/documents/delete-permanently/',
    decamelizeKeys({
      deletedHandlerIds: selectAll ? [] : deletedHandlerIds,
      selectAll,
    }),
    { params: { ...decamelizeKeys(filters || {}) } },
  );
};

export const permanentlyDeleteDocumentsForCurrentUser = async ({
  documentIds: deletedHandlerIds,
  allSelected: selectAll,
  filters,
}: PermanentlyDeleteDocumentsRequest): Promise<DeletedDocumentResponse> => {
  return pilot.post(
    '/restoration-center/documents/delete-permanently/current-user/',
    decamelizeKeys({
      deletedHandlerIds: selectAll ? [] : deletedHandlerIds,
      selectAll,
    }),
    { params: { ...decamelizeKeys(filters || {}) } },
  );
};

export const restoreDeletedDocuments = async ({
  documentIds,
  allSelected,
  folderId,
  filters,
}: RestoreDocumentsRequest): Promise<DeletedDocumentResponse> => {
  return pilot.post(
    '/restoration-center/documents/restore/',
    {
      deleted_handler_ids: allSelected ? [] : documentIds,
      select_all: allSelected,
      folder_id: folderId,
    },
    { params: { ...decamelizeKeys(filters || {}) } },
  );
};

export const restoreDeletedDocumentsForCurrentUser = async ({
  documentIds,
  allSelected,
  folderId,
  filters,
}: RestoreDocumentsRequest): Promise<DeletedDocumentResponse> => {
  return pilot.post(
    '/restoration-center/documents/restore/current-user/',
    {
      deleted_handler_ids: allSelected ? [] : documentIds,
      select_all: allSelected,
      folder_id: folderId,
    },
    { params: { ...decamelizeKeys(filters || {}) } },
  );
};

const transformResponseAndPickTopItems = (
  folders: DeletedDocumentOriginalFolder[],
  substringQuery = '',
): DeletedDocumentOriginalFolderOption[] => {
  const options = folders.map((f) => {
    if (f.path !== '')
      return {
        display_value: getBasename(f.path),
        value: f.path,
        path: `Documents/${f.path}`,
      };
    else
      return {
        display_value: 'Documents',
        value: `${f.path}`,
        path: 'Documents',
      };
  });

  const subset = isEmpty(substringQuery)
    ? options
    : options.filter(
        (o) =>
          toLower(o.display_value).includes(toLower(substringQuery)) ||
          toLower(o.path).includes(toLower(substringQuery)),
      );

  return subset.slice(0, 30);
};

export const getDeletedDocumentsOriginalFolder = async ({
  substringQuery = '',
}: DeletedDocumentOriginalFolderRequest): Promise<
  DeletedDocumentOriginalFolderOption[]
> => {
  const folders: DeletedDocumentOriginalFolder[] = await pilot.get(
    '/restoration-center/documents/folders/',
  );

  return transformResponseAndPickTopItems(folders, substringQuery);
};

export const getDeletedDocumentsOriginalFolderForCurrentUser = async ({
  substringQuery = '',
}: DeletedDocumentOriginalFolderRequest): Promise<
  DeletedDocumentOriginalFolderOption[]
> => {
  const folders: DeletedDocumentOriginalFolder[] = await pilot.get(
    '/restoration-center/documents/folders/current-user/',
  );

  return transformResponseAndPickTopItems(folders, substringQuery);
};

export const getDeletedDocumentsDeletedByUsers = async (): Promise<
  DeletedDocumentDeletedByUserOption[]
> => {
  const users: DeletedDocumentDeletedByUser[] = await pilot.get(
    '/restoration-center/documents/delete-by-users/',
  );

  return users.map((u) => {
    u = camelizeKeys(u) as DeletedDocumentDeletedByUser;
    return {
      label: formatUserNameAndEmail(u.firstName, u.lastName, u.email),
      value: u.email,
    };
  });
};
