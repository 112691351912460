import {
  black1,
  black4,
  evisortBlue,
  evisortRed,
} from '~/assets/shared-styles/general';

const styles = {
  datepicker: {
    flexGrow: 1,
    border: '1px solid rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    padding: '12px 8px',
    backgroundColor: (props) => (props.disabled ? '#f3f3f3' : '#fff'),
    color: (props) => (props.disabled ? black4 : black1),
    fontSize: '15px',
    outline: 'none',
    caretColor: evisortBlue,
    '&:focus': {
      border: `2px solid ${evisortBlue}`,
    },
  },
  datepickerError: {
    color: evisortRed,
    borderColor: evisortRed,

    '&::placeholder': {
      color: evisortRed,
    },
  },
};

export default styles;
