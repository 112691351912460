import { useDispatch, useSelector } from 'react-redux';

import { Layout, Modal, Text, useToast, useUuid } from '~/eds';
import { CreditBalance } from '~/features/billing';
import { useClientId } from '~/hooks';
import { api, selectors, slices } from '~/redux';
import { Uuid } from '~/types';

import {
  calculateCreditEstimate,
  getCreditsTooltip,
  getEstimatedCreditsTooltip,
} from '../billing';
import { TargetEntityDetails } from '../types';

interface Props {
  modelId: Uuid;
  version: number;
  targetEntityDetails: TargetEntityDetails;
}
export const ConfirmResumePublishModal = ({
  modelId,
  version,
  targetEntityDetails,
}: Props) => {
  const clientId = useClientId();
  const dispatch = useDispatch();
  const [id] = useUuid();
  const { toast } = useToast();

  const {
    data: modelProgress = {
      docsProcessed: 0,
      docsInScope: 0,
      publishStatus: null,
      valuesFound: 0,
      docsInScopeProcessing: false,
    },
    isLoading,
  } = api.endpoints.getPromptModelProgress.useQuery({ modelId });

  const { docsInScope, docsProcessed, docsInScopeProcessing } = modelProgress;

  const newDocsInScope = calculateCreditEstimate(docsProcessed, docsInScope);

  const [
    publishFieldModel,
    { isLoading: isPublishingFieldModel },
  ] = api.endpoints.publishPromptModel.useMutation();

  const handlePublish = () => {
    publishFieldModel({
      modelId,
      version,
      publishMode: 'new_docs_in_scope',
      idempotencyKey: id,
    })
      .then(() => {
        dispatch(slices.xRay.actions.setModelAction(null));
        toast({
          message: `**${targetEntityDetails.label}** is active and is processing documents.`,
          status: 'success',
        });
      })
      .catch(() => {
        toast({
          message: 'Failed to publish the model. Please try again.',
          status: 'danger',
        });
      });
  };

  const creditSummary = useSelector(selectors.selectFieldAiCreditsSummary);

  const insufficientCredits =
    creditSummary && creditSummary.credits < newDocsInScope;

  const modalProps = {
    title: 'Confirm to Publish',
    children: (
      <Layout direction="column" spacing={4}>
        <Text>
          Publishing this model will consume the estimated credits shown below.
          Please confirm to proceed.
        </Text>
        <CreditBalance
          clientId={clientId}
          estimateUnit={{
            modelId,
            isLoadingUnitValue: isLoading || docsInScopeProcessing,
            unit: 'document-count',
            value: newDocsInScope,
          }}
          getCreditsTooltip={getCreditsTooltip}
          getEstimatedCreditsTooltip={getEstimatedCreditsTooltip}
        />
      </Layout>
    ),
    isVisible: true,
    primaryAction: {
      disabled:
        isLoading || docsInScopeProcessing || Boolean(insufficientCredits),
      tooltip:
        isLoading || docsInScopeProcessing
          ? 'Estimating credits…'
          : insufficientCredits
          ? 'You do not have enough credits to publish.'
          : undefined,
      isLoading: isPublishingFieldModel,
      text: 'Confirm',
      onClick: handlePublish,
    },
    onCancel: () => {
      dispatch(slices.xRay.actions.setModelAction(null));
    },
  };
  return <Modal {...modalProps} />;
};
