/*
 * Onboarding IDs should be named in this pattern: `onboarding_<component>_<actionfeature>`
 * - everything is lowercased
 * - <component>: e.g. `button`, `input`, `link`, `modal`
 * - <feature>: e.g. `lower-kebab-case`
 */

export enum OnboardingIdType {
  AccessSettingsTab = 'onboarding_menu_access-settings',
  AccessSettingsModal = 'onboarding_modal_access-settings',
  AddDocuments = 'onboarding_button_add-documents',
  AdminDepartmentTab = 'onboarding_link_to-department-page',
  AdminSamlTab = 'onboarding_link_to-saml-page',
  AdminUserTab = 'onboarding_link_to-user-page',
  AddNewUser = 'onboarding_button_add-new-user',
  AddDepartmentButton = 'onboarding_button_add-department',
  AddDepartmentModal = 'onboarding_modal_add-department',
  AddSyncPairButton = 'onboarding_button_storage-add-sync-pair',
  AddSyncPairLink = 'onboarding_link_to-storage-add-sync-pair',
  AddSamlConfiguration = 'onboarding_button_add-saml-configuration',
  CreateDepartmentButton = 'onboarding_button_create-department',
  ConfirmSyncPair = 'onboarding_button_storage-confirm-sync-pair',
  DocumentUpload = 'onboarding_button_upload-document',
  ImportNewUser = 'onboarding_button_import-new-user',
  LogIntoProvider = 'onboarding_modal_log-into-provider',
  LoginWithProviderButton = 'onboarding_button_login-with-provider',
  OnboardingButton = 'onboarding_button_onboarding',
  OnboardingGuide = 'onboarding_page_onboarding-guide',
  SelectEvisortFolder = 'onboarding_modal_select-evisort-folder',
  SelectFileTypes = 'onboarding_card_select-file-types',
  SelectProviderFolder = 'onboarding_modal_select-provider-folder',
  SelectProvider = 'onboarding_modal_storage-select-provider',
  UploadButton = 'onboarding_button_upload-dropzone',
  UploadButtonModal = 'onboarding_modal_upload-dropzone',
  UploadContractFields = 'onboarding_modal_upload-contract-fields',
  SplashPageCardOne = 'onboarding_card_splash-page-card-one',
  SplashPageCardTwo = 'onboarding_card_splash-page-card-two',
  SplashPageCardThree = 'onboarding_card_splash-page-card-three',
}
