export default `
<svg
  aria-hidden="true"
  aria-label="folder-move"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12.5 9.96875C12.1875 9.6875 11.7188 9.6875 11.4375 9.96875L8.9375 12.4688C8.625 12.7812 8.625 13.25 8.9375 13.5312C9.21875 13.8438 9.6875 13.8438 10 13.5312L11.25 12.3125V15.5C11.25 15.9375 11.5625 16.25 12 16.25C12.4062 16.25 12.75 15.9375 12.75 15.5V12.3125L13.9688 13.5312C14.0938 13.6875 14.2812 13.75 14.5 13.75C14.6875 13.75 14.875 13.6875 15 13.5312C15.3125 13.25 15.3125 12.7812 15 12.5L12.5 9.96875ZM18 7H12.5938L11.0625 5.59375C10.6875 5.21875 10.1875 5 9.65625 5H6C4.875 5 4 5.90625 4 7V17C4 18.125 4.875 19 6 19H18C19.0938 19 20 18.125 20 17V9C20 7.90625 19.0938 7 18 7ZM18.5 17C18.5 17.2812 18.25 17.5 18 17.5H6C5.71875 17.5 5.5 17.2812 5.5 17V7C5.5 6.75 5.71875 6.5 6 6.5H9.65625C9.78125 6.5 9.90625 6.5625 10 6.65625L12 8.5H18C18.25 8.5 18.5 8.75 18.5 9V17Z"
    fill="currentcolor"
  />
</svg>
`;
