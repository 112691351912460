import { Dispatch, SetStateAction, useState } from 'react';

import { INPUT_DEBOUNCE_MS } from '../constants';
import { useDebouncedValue } from './useDebouncedValue';

export function useDebouncedState<V>(
  initialState: V,
  wait = INPUT_DEBOUNCE_MS,
): [V, V, Dispatch<SetStateAction<V>>] {
  const [state, setState] = useState(initialState);
  const debouncedStateValue = useDebouncedValue(state, wait);

  return [debouncedStateValue, state, setState];
}
