import { PromptModelTestCase } from '~/features/x-ray';
import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { PromptModelTestCase as PromptModelTestCaseResponse } from '../../types/sylvanus/model';
import { mapTestCase } from './transformers';

interface Request {
  modelId: Uuid;
  version: number;
}

type Response = PromptModelTestCaseResponse;

export const createPromptModelTestCase = async ({
  modelId,
  version,
}: Request): Promise<PromptModelTestCase> => {
  const response = (await sylvanus.post(
    `/model/${modelId}/version/${version}/testcase`,
  )) as Response;

  return mapTestCase(response);
};
