import React from 'react';

import { TextArea } from '~/eds';
import { EnumValue, Field } from '~/evifields';
import { DOCUMENT_ID_FILTER } from '~/features/filters';
import { FlagType, useFlag } from '~/flags';
import { Nullable } from '~/types';

import { TextDelimitedEnumSetValue } from '../subcomponents/TextDelimitedEnumSetValue';
import { Filter, TextValue } from '../types';

interface Props {
  filter: Filter<TextValue>;
  field: Field;
  onChange: (_updatedValues: TextValue[]) => void;
}

export const TextValues = ({ field, filter, onChange }: Props): JSX.Element => {
  const { id, values } = filter;
  const [value = ''] = values;

  const hasTextDelimitedDocIdFilter = useFlag(
    FlagType.TextDelimitedDocumentIdFilter,
  );

  // Special filter case. It has text type but the operators are EnumSetValue.
  const isDocumentIdFilter = field.label === DOCUMENT_ID_FILTER;

  const handleChange = (updatedValue: Nullable<string>) => {
    onChange(updatedValue ? [updatedValue] : []);
  };

  const handleTextDelimitedChange = (updatedValue: EnumValue[]) => {
    onChange(updatedValue.length ? [updatedValue.join(',')] : []);
  };

  if (isDocumentIdFilter && hasTextDelimitedDocIdFilter) {
    return (
      <TextDelimitedEnumSetValue
        fieldId={field.id}
        onChange={handleTextDelimitedChange}
        values={value ? value.split(',') : []}
      />
    );
  }

  return (
    <TextArea
      name={id}
      value={value}
      placeholder="Content"
      onChange={handleChange}
    />
  );
};
