export default `
<svg
  aria-hidden="true"
  aria-label="caret-down"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.297 14.707C11.672 15.1133 12.3282 15.1133 12.7032 14.707L16.7032 10.707C16.9845 10.4258 17.0782 9.98828 16.922 9.61328C16.7657 9.23828 16.422 8.98828 16.0157 8.98828H8.0157C7.60945 8.98828 7.23445 9.23828 7.0782 9.61328C6.92195 9.98828 7.0157 10.4258 7.29695 10.707L11.297 14.707Z"
    fill="currentcolor"
  />
</svg>
`;
