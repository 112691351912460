import { differenceInSeconds, isValid } from 'date-fns';
import { compact, round } from 'lodash';

import { formatDate } from '~/eds';
import { TrainingStage, TrainingStatus } from '~/enums';
import {
  AccuracyMetrics,
  DropdownFieldValue,
  FolderTree,
  Nullable,
} from '~/types';

type ProgressParams = {
  stage: TrainingStage;
  iters: number;
  createdAt: string;
  updatedAt: string;
  status: TrainingStatus;
  prediction: string;
  score: number;
};

export const flattenFolderTree = (data: FolderTree[]) => {
  return data.reduce((prev: DropdownFieldValue[], cur) => {
    prev.push({ value: cur.id, display_value: cur.name });
    if (cur.children?.length) {
      prev = prev.concat(flattenFolderTree(cur.children));
    }
    return prev;
  }, []);
};

export const buildClauseAnalyzerParams = (clauseName: string) => ({
  page: 1,
  sortedColumn: 'name-asc',
  expanded: false,
  isAllSelected: false,
  selectedItems: [],
  query: JSON.stringify([
    {
      entity: 'provision',
      provision: clauseName,
      text_search: [],
      contains: { value: 'contains', label: 'contains' },
    },
  ]),
});

export const getShortName = (name: string, limit = 50): string => {
  if (name.length > limit) {
    return name.substring(0, limit) + '...';
  }

  return name;
};

export const getSampleName = (name: string): string => {
  return `${name.substring(0, 20)}_${formatDate(new Date(), 'iso_datetime')}`;
};

export const isSampleNameChanged = (name: string) => {
  if (!name) return false;
  return !name.match(/^.{1,20}_\d{14}$/);
};

export const verCmp = (l: string, r: string) => {
  const regexStrip0 = /(\.0+)+$/;
  const segmentsL = l.replace(regexStrip0, '').split('.');
  const segmentsR = r.replace(regexStrip0, '').split('.');
  const len = Math.min(segmentsL.length, segmentsR.length);

  for (let i = 0; i < len; i++) {
    const diff = parseInt(segmentsR[i], 10) - parseInt(segmentsL[i], 10);

    if (diff) {
      return diff;
    }
  }

  return segmentsR.length - segmentsL.length;
};

export const calculateF1Score = (
  metrics?: AccuracyMetrics,
): Nullable<number> => {
  if (!metrics || (!metrics.fullSample && !metrics.outOfSample)) return null;

  const { fullSample, outOfSample } = metrics;
  const { recall, precision } = !!fullSample ? fullSample : outOfSample;

  if (precision + recall <= 0) return 0;
  return round((2 * (precision * recall)) / (precision + recall), 2);
};

export const getCurrentStage = (
  prevIterVersions: string[],
  training: Record<string, boolean>,
  trends: Record<string, boolean>,
  postTraining: Record<string, boolean>,
): TrainingStage => {
  const {
    isTrainingInProgress,
    isAwaitingUserLabels,
    isAwaitingDecision,
  } = training;
  const {
    isScoreLow,
    isScoreOverExp,
    isScoreIdeal,
    isScoreUp,
    isScoreDown,
  } = trends;
  const { isPublishing, isPublished, isPredicting, isPredicted } = postTraining;

  if (isPredicting) return TrainingStage.Predicting;
  if (isPredicted) return TrainingStage.Predicted;

  if (isPublishing) return TrainingStage.Publishing;
  if (isPublished) return TrainingStage.Published;

  switch (prevIterVersions.length) {
    case 0:
      if (isTrainingInProgress) return TrainingStage.FirstRoundInProgress;
      if (isAwaitingUserLabels) return TrainingStage.FirstRoundLabeling;
      if (isAwaitingDecision) {
        if (isScoreLow) return TrainingStage.FirstRoundF1Low;
        if (isScoreOverExp) return TrainingStage.FirstRoundF1Mid;
        if (isScoreIdeal) return TrainingStage.FirstRoundF1Ideal;
      }
      break;
    case 1:
      if (isTrainingInProgress) return TrainingStage.SecondRoundInProgress;
      if (isAwaitingUserLabels) return TrainingStage.SecondRoundLabeling;
      if (isAwaitingDecision) {
        if (isScoreIdeal) return TrainingStage.SecondRoundF1Ideal;
        else {
          if (isScoreUp) return TrainingStage.SecondRoundF1GoUp;
          if (isScoreDown) return TrainingStage.SecondRoundF1GoDown;
        }
      }
      break;
    case 2:
      if (isTrainingInProgress) return TrainingStage.ThirdRoundInProgress;
      if (isAwaitingUserLabels) return TrainingStage.ThirdRoundLabeling;
      if (isAwaitingDecision) {
        if (isScoreIdeal) return TrainingStage.ThirdRoundF1Ideal;
        else {
          if (isScoreUp) return TrainingStage.ThirdRoundF1GoUp;
          if (isScoreDown) return TrainingStage.ThirdRoundF1GoDown;
        }
      }
      break;
    case 3:
      if (isTrainingInProgress) return TrainingStage.FourthRoundInProgress;
      if (isAwaitingUserLabels) return TrainingStage.FourthRoundLabeling;
      if (isAwaitingDecision) {
        if (isScoreIdeal) return TrainingStage.FourthRoundF1Ideal;
        else {
          if (isScoreUp) return TrainingStage.FourthRoundF1GoUp;
          if (isScoreDown) return TrainingStage.FourthRoundF1GoDown;
        }
      }
      break;
    default:
      return TrainingStage.Empty;
  }

  return TrainingStage.Empty;
};

export const calculateProgress = (
  time: string,
  stepper = [300, 900, 1800],
  offsite = 0.01,
  max = 0.93,
) => {
  const date = isValid(new Date(time)) ? new Date(time) : new Date();
  const timePassed = differenceInSeconds(new Date(), date);

  if (timePassed <= stepper[0]) {
    return round(Math.min(timePassed / (stepper[0] / 0.5) + offsite, 0.5), 2);
  }

  if (timePassed <= stepper[1]) {
    return round(
      Math.min(
        (timePassed - stepper[0]) / ((stepper[1] - stepper[0]) / 0.3) +
          0.5 +
          offsite,
        0.8,
      ),
      2,
    );
  }

  if (timePassed <= stepper[2]) {
    return round(
      Math.min(
        (timePassed - stepper[1]) / ((stepper[2] - stepper[1]) / 0.13) +
          0.8 +
          offsite,
        max,
      ),
      2,
    );
  }

  return max;
};

export const calcPublishingProgress = (
  time: string,
  status: TrainingStatus,
) => {
  if (status === TrainingStatus.READY_TO_DEPLOY) {
    return calculateProgress(time, [150, 300, 600]);
  }
  return 0;
};

export const calcPredictionProgress = (progress: string) => {
  return round(
    Math.min((progress ? parseFloat(progress) / 100 : 0) + 0.02, 1),
    2,
  );
};

export const getCurrentProgress = ({
  stage,
  iters,
  createdAt,
  updatedAt,
  status,
  prediction,
  score,
}: ProgressParams) => {
  const completeTraining = {
    description: 'Training in Progress',
    progress: 1,
  };
  const pendingLabeling = {
    description: 'Samples Ready for Your Feedback',
    progress: 0,
  };
  const completeLabeling = {
    description: 'Samples Ready for Your Feedback',
    progress: 1,
  };
  const pendingRetrain = {
    description: 'Retrain',
    progress: 0,
  };
  const pendingPublish = {
    description: 'Publish',
    progress: 0,
  };
  const pendingIdentify = {
    description: 'Identify',
    progress: 0,
  };
  switch (stage) {
    case TrainingStage.FirstRoundInProgress:
      return {
        active: 0,
        stages: [
          [
            {
              description: 'Training in Progress',
              progress: calculateProgress(createdAt),
            },
            pendingLabeling,
            pendingRetrain,
          ],
          pendingPublish,
          pendingIdentify,
        ],
      };

    case TrainingStage.FirstRoundLabeling:
      return {
        active: 0,
        stages: [
          [completeTraining, pendingLabeling, pendingRetrain],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.FirstRoundF1Low:
    case TrainingStage.FirstRoundF1Mid:
    case TrainingStage.FirstRoundF1Ideal:
      return {
        active: 0,
        stages: [
          [completeTraining, completeLabeling, pendingRetrain],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.SecondRoundInProgress:
      return {
        active: 0,
        stages: [
          [
            completeTraining,
            completeLabeling,
            {
              description: 'Retrain 1: Training in Progress',
              progress: calculateProgress(createdAt, [150, 450, 900]),
            },
          ],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.SecondRoundLabeling:
      return {
        active: 0,
        stages: [
          [
            completeTraining,
            completeLabeling,
            {
              description: 'Retrain 1: Samples Ready for your Feedback',
              progress: 0.99,
            },
          ],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.SecondRoundF1GoDown:
    case TrainingStage.SecondRoundF1GoUp:
    case TrainingStage.SecondRoundF1Ideal:
      return {
        active: 0,
        stages: [
          [
            completeTraining,
            completeLabeling,
            {
              description: 'Retrain 1: Ready for Retrain or Publish',
              progress: 1,
            },
          ],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.ThirdRoundInProgress:
      return {
        active: 0,
        stages: [
          [
            completeTraining,
            completeLabeling,
            {
              description: 'Retrain 2: Training in Progress',
              progress: calculateProgress(createdAt, [150, 450, 900]),
            },
          ],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.ThirdRoundLabeling:
      return {
        active: 0,
        stages: [
          [
            completeTraining,
            completeLabeling,
            {
              description: 'Retrain 2: Samples Ready for your Feedback',
              progress: 0.99,
            },
          ],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.ThirdRoundF1GoDown:
    case TrainingStage.ThirdRoundF1GoUp:
    case TrainingStage.ThirdRoundF1Ideal:
      return {
        active: 0,
        stages: [
          [
            completeTraining,
            completeLabeling,
            {
              description: 'Retrain 2: Ready for Retrain or Publish',
              progress: 1,
            },
          ],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.FourthRoundInProgress:
      return {
        active: 0,
        stages: [
          [
            completeTraining,
            completeLabeling,
            {
              description: 'Retrain 3: Training in Progress',
              progress: calculateProgress(createdAt, [150, 450, 900]),
            },
          ],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.FourthRoundLabeling:
      return {
        active: 0,
        stages: [
          [
            completeTraining,
            completeLabeling,
            {
              description: 'Retrain 3: Samples Ready for your Feedback',
              progress: 0.99,
            },
          ],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.FourthRoundF1GoDown:
    case TrainingStage.FourthRoundF1GoUp:
    case TrainingStage.FourthRoundF1Ideal:
      return {
        active: 0,
        stages: [
          [
            completeTraining,
            completeLabeling,
            {
              description: 'Retrain 3: Ready for Retrain or Publish',
              progress: 1,
            },
          ],
          pendingPublish,
          pendingIdentify,
        ],
      };
    case TrainingStage.Publishing:
      return {
        active: 1,
        stages: [
          compact([
            completeTraining,
            completeLabeling,
            iters <= 0
              ? null
              : {
                  description: `Retrain ${iters}: Ready for Retrain or Publish`,
                  progress: 1,
                },
          ]),
          {
            description: `Publishing - ${Math.trunc(
              calcPublishingProgress(updatedAt, status) * 100,
            )}%`,
            progress: calcPublishingProgress(updatedAt, status),
            progressScore: score,
          },
          pendingIdentify,
        ],
      };
    case TrainingStage.Published:
      return {
        active: 1,
        stages: [
          compact([
            completeTraining,
            completeLabeling,
            iters <= 0
              ? null
              : {
                  description: `Retrain ${iters}: Ready for Retrain or Publish`,
                  progress: 1,
                },
          ]),
          {
            description: 'Published',
            progress: 1,
            progressScore: score,
          },
          pendingIdentify,
        ],
      };
    case TrainingStage.Predicting:
      return {
        active: 2,
        stages: [
          compact([
            completeTraining,
            completeLabeling,
            iters <= 0
              ? null
              : {
                  description: `Retrain ${iters}: Ready for Retrain or Publish`,
                  progress: 1,
                },
          ]),
          {
            description: 'Published',
            progress: 1,
            progressScore: score,
          },
          {
            description: `Identifying - ${Math.floor(parseFloat(prediction))}%`,
            progress: calcPredictionProgress(prediction),
            progressScore: score,
          },
        ],
      };
    case TrainingStage.Predicted:
      return {
        active: 2,
        stages: [
          compact([
            completeTraining,
            completeLabeling,
            iters <= 0
              ? null
              : {
                  description: `Retrain ${iters}: Ready for Retrain or Publish`,
                  progress: 1,
                },
          ]),
          {
            description: 'Published',
            progress: 1,
            progressScore: score,
          },
          {
            description: 'Identified',
            progress: 1,
            progressScore: score,
          },
        ],
      };
    default:
      return null;
  }
};
