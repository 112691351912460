import PropTypes from 'prop-types';
import React from 'react';

import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';

function SendPasswordEmailModal({
  confirmButtonText,
  text,
  title,
  hideModal,
  resetPassword,
}) {
  const handleActionClick = () => {
    resetPassword();
    hideModal();
  };

  return (
    <EcModalCard
      title={title}
      content={text}
      footer={
        <>
          <EcButton white borderless text="Cancel" onClick={hideModal} />
          <EcButton
            yellow
            text={confirmButtonText}
            onClick={handleActionClick}
          />
        </>
      }
      hideModal={hideModal}
    />
  );
}

SendPasswordEmailModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default SendPasswordEmailModal;
