import { types } from '~/eds';
import { BulkClauseEditType } from '~/features/document-bulk-actions';
import { pilot } from '~/services';

type Request = {
  type: BulkClauseEditType;
  /** query should only be provided if you want to affect all documents returned
   * by the query.
   */
  query?: object;
  /** use search v2 or v3 query */
  searchAPI: 'v2' | 'v3';
  /** bulk action will affect all documents in the list */
  documentIds?: types.PilotId[];
  /** folder ids should only be provided if you want to affect all documents inside
   * the folders.
   */
  folderIds?: types.PilotId[];
  clauseNames?: string[];
  /** clause_values is a Record that is used when type is 'add' */
  clauseAdditions?: Record<string, string>;
};

interface Response {
  documentIds?: types.PilotId[];
  clauseNames?: string[];
  clauseAdditions?: Record<string, string>;
  query?: object;
}

export const searchV2BulkEditClause = ({
  type,
  documentIds,
  query,
  searchAPI,
  clauseNames,
  clauseAdditions,
}: Request): Promise<Response> => {
  return pilot.post('document/bulk-edit-clause/', {
    type,
    document_ids: documentIds,
    ...(searchAPI === 'v3' ? { query_v3: query } : { query: query }),
    clause_names: clauseNames,
    clause_additions: clauseAdditions,
  });
};
