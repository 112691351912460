import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  createEsignatureIntegration,
  deleteEsignatureIntegration,
  getEsignatureIntegrationLoginUrl,
  updateEsignatureIntegration,
} from '~/api/integrations';
import logos from '~/components/Shared/ProviderLogo/logos';
import { esignatureIntegrations } from '~/constants/integrations';
import { Button } from '~/eds';
import { useAsync } from '~/hooks';
import {
  Alert,
  Box,
  Card,
  Divider,
  FlexLayout,
  InputLabel,
  Link,
  RadioGroup,
  Text,
} from '~/ui';

import IntegrationDetailCard from '../../IntegrationDetailCard';
import ContactIntegrationMessage from '../ContactIntegrationMessage';
import StatusAndActions from './StatusAndActions';
import SwitchLabel from './SwitchLabel';

const useCompanyAccountOptions = [
  {
    label: 'Individually (needs paid plan)',
    value: false,
  },
  {
    label: 'Using the provided company account (needs paid plan)',
    value: true,
  },
];

const makeDisabledDescription = (
  providerName,
) => `This integration is not currently available. \
To start using ${providerName}, remove the currently active e-signature provider first.`;

function renderUnpurchasedMsg() {
  return (
    <Text color="gray-600" variant="s-dense">
      To activate, please reach out to{' '}
      <Link href="mailto:support@evisort.com" variant="s-dense">
        support@evisort.com
      </Link>
    </Text>
  );
}

function ESignatureIntegrationCard({
  activeEsignatureIntegrationCount,
  disabled = false,
  esignatureIntegration,
  isRefetching,
  onRefetch,
}) {
  const [draft, setDraft] = useState(esignatureIntegration);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { error, id, type } = esignatureIntegration;
  const { name, provider } = esignatureIntegrations[type];
  const description = `Use ${name}'s e-signature tool for your workflows. Contracts created or processed through our workflow tool will be routed for signature using ${name}.`; // eslint-disable-line
  const logoSrc = logos[provider];

  const isUndefined = !disabled && !id;
  const hasOtherActiveIntegration =
    activeEsignatureIntegrationCount > 0 && isUndefined;

  useEffect(() => {
    setDraft(esignatureIntegration);
  }, [esignatureIntegration]);

  const { executor: fetchIntegrationLoginUrl } = useAsync(
    getEsignatureIntegrationLoginUrl,
    {
      integrationId: draft.id,
      integrationUrl: window.location.href,
    },
    {
      errorToastMessage:
        'Unable to fetch esignature integration login url, please try again',
      successHandler: ({ redirectUrl }) => (window.location.href = redirectUrl),
    },
  );

  const {
    executor: executeDeleteIntegration,
    isLoading: isDeleting,
  } = useAsync(deleteEsignatureIntegration, esignatureIntegration, {
    errorToastMessage:
      'Unable to delete esignature integration, please try again',
    successHandler: onRefetch,
  });

  const {
    executor: executeCreateIntegration,
    isLoading: isCreating,
  } = useAsync(createEsignatureIntegration, draft, {
    errorToastMessage:
      'Unable to create esignature integration, please try again',
    successHandler: (res) => {
      setDraft(res);
      if (!res.isUserLoggedIn) {
        fetchIntegrationLoginUrl();
      } else {
        onRefetch(res);
      }
    },
  });

  const {
    executor: executeUpdateIntegration,
    isLoading: isUpdating,
  } = useAsync(updateEsignatureIntegration, draft, {
    errorToastMessage:
      'Unable to update esignature integration, please try again',
    successHandler: (res) => {
      setDraft(res);
      if (!res.isUserLoggedIn) {
        fetchIntegrationLoginUrl();
      } else {
        onRefetch(res);
      }
    },
  });

  const isUnchanged = isEqual(draft, esignatureIntegration);
  const isLoading = isRefetching || isCreating || isDeleting || isUpdating;
  const hasError = !!error;

  function handleCancel() {
    setDraft(esignatureIntegration);
    setIsCollapsed(true);
  }

  function handleConnect() {
    setIsCollapsed(false);
  }

  function handleSave() {
    const executeSave = id
      ? executeUpdateIntegration
      : executeCreateIntegration;
    executeSave();
  }

  function handleUpdateDraft(key) {
    return (updatedValue) => {
      setDraft((previousDraft) => ({
        ...previousDraft,
        [key]: updatedValue,
      }));
    };
  }

  const headerRightContent = (
    <StatusAndActions
      disabled={disabled}
      esignatureIntegration={esignatureIntegration}
      isActive={!isCollapsed}
      isLoading={isLoading}
      isPending={!isUnchanged}
      onConnect={handleConnect}
      onDelete={executeDeleteIntegration}
      onToggleCollapse={() => setIsCollapsed(!isCollapsed)}
    />
  );

  const footer = (
    <Card.Footer
      actionButtons={[
        <Button
          disabled={isUnchanged || isLoading}
          text="Cancel"
          variant="tertiary"
          onClick={handleCancel}
        />,
        <Button
          disabled={isUnchanged && !hasError}
          isLoading={isLoading}
          text="Save"
          onClick={handleSave}
        />,
      ]}
    />
  );

  return (
    <IntegrationDetailCard
      isCollapsed={isCollapsed}
      footer={footer}
      headerRightContent={headerRightContent}
      logoSrc={logoSrc}
      title={name}
      onToggleCollapse={setIsCollapsed}
    >
      <FlexLayout
        alignItems="stretch"
        as="form"
        flexDirection="column"
        space={6}
      >
        {disabled && (
          <>
            <ContactIntegrationMessage
              description={description}
              enableIcon={true}
              renderUnpurchasedMsg={renderUnpurchasedMsg}
            />
            <Divider />
          </>
        )}
        {!hasOtherActiveIntegration && (
          <Box sx={{ alignSelf: 'flex-start' }}>
            <Alert enableIcon variant="warning">
              Please make sure to set correct permissions before generating the
              key (Evisort can modify envelopes for all users).
            </Alert>
          </Box>
        )}
        {hasOtherActiveIntegration && (
          <>
            <ContactIntegrationMessage
              description={description}
              disabledFeatureDescription={makeDisabledDescription(name)}
              enableIcon={true}
              isUnpurchased={false}
            />
            <Divider />
          </>
        )}
        <FlexLayout
          disabled={disabled || hasOtherActiveIntegration}
          flexDirection="column"
          space={6}
        >
          <InputLabel
            isRequired
            id="use-company-account"
            label="When collecting signatures, Evisort users will authenticate:"
          >
            <RadioGroup
              options={useCompanyAccountOptions}
              value={draft.shouldUseCompanyAccount}
              onChange={handleUpdateDraft('shouldUseCompanyAccount')}
            />
          </InputLabel>
          <Divider />
          <SwitchLabel
            isRequired
            disabled={activeEsignatureIntegrationCount < 2}
            id="is-default"
            label="Default e-signature provider"
            message="All documents will be signed using this provider"
            value={draft.isDefault}
            onChange={handleUpdateDraft('isDefault')}
            width="fullWidth"
          />
        </FlexLayout>
      </FlexLayout>
    </IntegrationDetailCard>
  );
}

export default ESignatureIntegrationCard;
