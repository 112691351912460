export default `
<svg
  aria-hidden="true"
  aria-label="format-align-center"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M15.25 5.2666C15.6562 5.2666 16 5.61035 16 6.0166C16 6.4541 15.6562 6.7666 15.25 6.7666H8.75C8.3125 6.7666 8 6.4541 8 6.0166C8 5.61035 8.3125 5.2666 8.75 5.2666H15.25ZM18.25 9.2666C18.6562 9.2666 19 9.61035 19 10.0166C19 10.4541 18.6562 10.7666 18.25 10.7666H5.75C5.3125 10.7666 5 10.4541 5 10.0166C5 9.61035 5.3125 9.2666 5.75 9.2666H18.25ZM8 14.0166C8 13.6104 8.3125 13.2666 8.75 13.2666H15.25C15.6562 13.2666 16 13.6104 16 14.0166C16 14.4541 15.6562 14.7666 15.25 14.7666H8.75C8.3125 14.7666 8 14.4541 8 14.0166ZM18.25 17.2666C18.6562 17.2666 19 17.6104 19 18.0166C19 18.4541 18.6562 18.7666 18.25 18.7666H5.75C5.3125 18.7666 5 18.4541 5 18.0166C5 17.6104 5.3125 17.2666 5.75 17.2666H18.25Z"
    fill="currentcolor"
  />
</svg>
`;
