import React, { useEffect, useState } from 'react';

import UserSelect from '~/components/Shared/UserSelect';
import { getUserName, Label, Layout, TextInput, types } from '~/eds';
import { withUsers } from '~/hocs';
import { Option } from '~/types';

interface Props {
  users: Record<number, types.User>;
  setRecipient: (recipient: {
    email?: string;
    name?: string;
    userId?: number;
  }) => void;
  internalRecipientIds: [number];
}

const UserEmailSearch = ({
  users,
  internalRecipientIds,
  setRecipient,
}: Props) => {
  const [newCcId, setNewCcId] = useState<types.Nullable<number>>(null);
  const [newCCEmail, setNewCCEmail] = useState('');
  const [newCCName, setNewCCName] = useState('');

  const handleOnAddRecipient = (updatedValues: Option<string>) => {
    setNewCcId(null);
    setNewCCName('');
    setNewCCEmail(updatedValues.value);
  };

  useEffect(() => {
    setRecipient({
      email: newCCEmail,
      name: newCCName,
      userId: newCcId ?? undefined,
    });
  }, [newCCName, newCcId, newCCEmail]);

  const onUserSelect = (id: types.Nullable<types.PilotId>) => {
    if (id) {
      const user = users[id as number];
      if (user) {
        setNewCcId(id as number);
        const name = getUserName(user);
        setNewCCName(name);
      }
    } else {
      setNewCcId(null);
      setNewCCName('');
      setNewCCEmail('');
    }
  };

  const onNameChange = (updatedValue: types.Nullable<string>) => {
    setNewCCName(updatedValue || '');
  };

  return (
    <Layout direction="column" spacing={8} w="100%">
      <Layout direction="column" space={4}>
        <Label htmlFor="cc-email">Email</Label>
        <UserSelect
          notAvailableUsers={internalRecipientIds}
          isMulti={false}
          name="cc-email"
          value={newCcId || newCCEmail}
          placeholder="Add user..."
          onChange={onUserSelect}
          onAddOption={handleOnAddRecipient}
          enablePortal={true}
        />
      </Layout>
      <Layout direction="column" space={4}>
        <Label htmlFor="cc-name">Name</Label>
        <TextInput
          name="cc-name"
          id="recipient-set-name"
          disabled={!!newCcId || !newCCEmail}
          value={newCCName}
          onChange={onNameChange}
        />
      </Layout>
    </Layout>
  );
};
export default withUsers(UserEmailSearch);
