import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';
import { Uuid } from '~/types';

import { DocumentEditorType, DocumentEditorsState as State } from '../types';

export const name = SliceType.DocumentEditors;

const initialState: State = {};

/**
 * This slice tracks, adds, removes editors (as a set) on document versions on specific document editors (e.g. ej2, wopi)
 */
export default createSlice({
  name,
  initialState,
  reducers: {
    addEditor: (
      state: State,
      action: PayloadAction<{
        documentEditor: DocumentEditorType;
        editor: Uuid;
        versionId: Uuid;
      }>,
    ) => {
      const { documentEditor, editor, versionId } = action.payload;

      if (!state[versionId]) {
        state[versionId] = {
          ej2: new Set(),
          wopi: new Set(),
        };
      }

      state[versionId][documentEditor].add(editor);
    },
    removeEditor: (
      state: State,
      action: PayloadAction<{
        documentEditor: DocumentEditorType;
        editor: Uuid;
        versionId: Uuid;
      }>,
    ) => {
      const { documentEditor, editor, versionId } = action.payload;

      state?.[versionId]?.[documentEditor].delete(editor);
    },
    setEditors: (
      state: State,
      action: PayloadAction<{
        documentEditor: DocumentEditorType;
        editors: Set<Uuid>;
        versionId: Uuid;
      }>,
    ) => {
      const { documentEditor, editors, versionId } = action.payload;

      if (!state[versionId]) {
        state[versionId] = {
          ej2: new Set(),
          wopi: new Set(),
        };
      }

      state[versionId][documentEditor] = editors;
    },
  },
});
