import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import UserDepartmentSelect from '~/components/Shared/UserDepartmentSelect';
import { EntityType, FeatureFlagType, SaveSearchPermissionType } from '~/enums';
import { useCurrentUser, useHasFeatureFlag } from '~/hooks';
import { selectors } from '~/redux';
import {
  CharacterLimit,
  FlexLayout,
  InputLabel,
  SingleSelect,
  TextInput,
} from '~/ui/';
import { CHARACTER_INPUT_LIMIT_SHORT } from '~/ui/enums/input';

import { getUserDepartmentArray } from '../SearchV2.utils';
import { getSaveSearchPermissionOptions } from '../utils';

const SaveSearchForm = ({ isDuplicate = false, handleFormDataChanges }) => {
  const currentUser = useCurrentUser();
  const hasSearchV2Flag = useHasFeatureFlag(FeatureFlagType.SearchV2);
  const modal = useSelector(selectors.selectSearchResultModal);
  const { context } = modal || {};

  const defaultPermissionBasedOnFlag = hasSearchV2Flag
    ? SaveSearchPermissionType.Private
    : SaveSearchPermissionType.Open;
  const getDefaultPermissionOption = () =>
    context?.search_visibility || defaultPermissionBasedOnFlag;

  const getPlaceholderName = (savedSearch) =>
    isDuplicate ? `Copy of ${savedSearch?.name}` : savedSearch?.name;
  const PERMISSION_OPTIONS = getSaveSearchPermissionOptions(
    currentUser.client_name,
  );
  const usersDepartmentsArray = getUserDepartmentArray(context);

  const [name, setName] = useState(context?.name);
  const [selectedItemPermission, setSelectedItemPermission] = useState(
    getDefaultPermissionOption(),
  );
  const [userDepartments, setUsersDepartments] = useState(
    usersDepartmentsArray,
  );

  const isSharedPermissionSelected =
    selectedItemPermission === SaveSearchPermissionType.Shared;

  useEffect(() => {
    setName(getPlaceholderName(context));
    setSelectedItemPermission(getDefaultPermissionOption());
    setUsersDepartments(usersDepartmentsArray);
  }, [context]);

  useEffect(() => {
    const user_ids = userDepartments
      .filter((ud) => ud.type === EntityType.User)
      .map((item) => item.id);
    const department_ids = userDepartments
      .filter((ud) => ud.type === EntityType.Department)
      .map((item) => item.id);

    const basicFormData = {
      id: context?.id,
      name: name,
      query: context?.query,
      search_visibility: selectedItemPermission,
    };
    const formData = isSharedPermissionSelected
      ? {
          ...basicFormData,
          user_ids,
          department_ids,
        }
      : basicFormData;
    handleFormDataChanges(formData);
  }, [name, selectedItemPermission, userDepartments]);

  return (
    <FlexLayout flexDirection="column" space={3}>
      <InputLabel isRequired id="save-search-name" label="Search name">
        <TextInput
          autoFocus
          id="save-search--set-up-name"
          value={name}
          placeholder="Enter a name"
          width="fullWidth"
          onChange={setName}
        />
        <FlexLayout justifyContent="flex-end">
          <CharacterLimit limit={CHARACTER_INPUT_LIMIT_SHORT} value={name} />
        </FlexLayout>
      </InputLabel>
      {hasSearchV2Flag && (
        <>
          <InputLabel
            isRequired
            id="save-search-permission-option"
            label="Who can see and use this search?"
          >
            <SingleSelect
              isClearable={false}
              isSearchable={false}
              options={PERMISSION_OPTIONS}
              shouldHideSelectedOptions={false}
              value={selectedItemPermission}
              width="fullWidth"
              onChange={setSelectedItemPermission}
            />
          </InputLabel>
          {isSharedPermissionSelected && (
            <UserDepartmentSelect
              enablePortal={true}
              value={userDepartments}
              onChange={setUsersDepartments}
            />
          )}
        </>
      )}
    </FlexLayout>
  );
};

export default SaveSearchForm;
