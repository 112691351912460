import React from 'react';

import { Input, Nullable, Option } from '../../types';
import { testHasTextMatch, typedMemo } from '../../utils';
import { Box } from '../Box';
import { Grid } from '../Grid';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Radio } from '../Radio';
import { Text } from '../Text';

type RadioGroupProps<V> = Input<V> & {
  /** Radio options */
  options: Option<V>[];
  /** Group columns */
  columns?: number;
  /** Disables all Radio options */
  disabled?: boolean;
  /** Group info tooltip */
  info?: string;
  /** When option.label matches search, render a text match */
  search?: string;
  /** Group Title */
  title?: string;
  /** Callback to invoke on value change of RadioGroup */
  onChange: (value: V) => void;
};

type CheckedValue = Nullable<boolean>;

export const RadioGroup = typedMemo(
  <V extends unknown>({
    columns = 1,
    disabled,
    info,
    name,
    options,
    search,
    title,
    value: groupValue,
    onChange,
  }: RadioGroupProps<V>) => {
    if (options.length === 0) {
      return null;
    }

    const filteredOptions = search
      ? options.filter((option) => testHasTextMatch(option.label, search))
      : options;
    const handleChange = (value: V) => (updatedChecked: CheckedValue) => {
      if (updatedChecked) {
        onChange(value);
      }
    };

    return (
      <Layout
        as="fieldset"
        direction="column"
        spacing={2}
        styles={componentStyles.fieldset}
      >
        {title && (
          <Box as="legend" styles={componentStyles.legend}>
            <Text variant="body-medium">{title}</Text>
            {info && <Icon icon="info" tooltip={info} />}
          </Box>
        )}
        <Grid columns={columns} columnSpacing={6} pl={title ? 4 : undefined}>
          {filteredOptions.map((option) => {
            const { disabled: optionDisabled, value } = option;
            return (
              <Layout
                key={`${name}_${value}`}
                align="baseline"
                justify="space-between"
              >
                <Radio
                  checked={groupValue === value}
                  disabled={disabled || optionDisabled}
                  name={name || ''}
                  option={option}
                  search={search}
                  value={groupValue === value}
                  onChange={handleChange(value)}
                />
              </Layout>
            );
          })}
          {filteredOptions.length === 0 && (
            <Text preset="description">No matching options.</Text>
          )}
        </Grid>
      </Layout>
    );
  },
);

const componentStyles = {
  fieldset: {
    border: 'none',
    padding: 'unset',
  },
  legend: {
    backgroundColor: 'background.quiet',
    borderRadius: 'm',
    display: 'inline-flex',
    paddingBottom: 2,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    width: '100%',
  },
};
