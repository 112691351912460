import PropTypes from 'prop-types';
import React from 'react';

const ChevronUpIcon = (props) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      onClick={props.onClick}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.color}
          fillOpacity={props.opacity}
          fillRule="nonzero"
          d="M7.839 15.69a1.1 1.1 0 0 1-1.525 0 1.051 1.051 0 0 1 0-1.5l4.929-4.88a1.077 1.077 0 0 1 1.514 0l4.929 4.88a1.051 1.051 0 0 1 0 1.5 1.077 1.077 0 0 1-1.514 0l-4.167-4.125-4.166 4.124z"
        />
      </g>
    </svg>
  );
};

ChevronUpIcon.defaultProps = {
  size: '24',
  color: '#000',
  opacity: '.75',
  onClick: () => {},
};

ChevronUpIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
  onClick: PropTypes.func,
};

export default ChevronUpIcon;
