import { getMonth, set } from 'date-fns';
import React from 'react';

import { YearMonth } from './YearMonth';

interface Props {
  displayDate: Date;
  formatMonth: (date: Date) => React.ReactNode;
  onSelectMonth: (month: number) => void;
}

export const Months = ({ displayDate, formatMonth, onSelectMonth }: Props) => {
  const currentMonth = getMonth(displayDate);

  const months = Array.from({ length: 12 }).map((_, i) => i);

  return (
    <>
      {months.map((month) => (
        <YearMonth
          key={month}
          isActive={currentMonth === month}
          text={formatMonth(set(displayDate, { month })) as string}
          onClick={() => onSelectMonth(month)}
        />
      ))}
    </>
  );
};
