import PT from 'prop-types';
import React, { forwardRef } from 'react';

import Box from '../Box';

const Iframe = forwardRef(({ height, src, title }, ref) => {
  return (
    <Box
      as="iframe"
      ref={ref}
      src={src}
      title={title}
      sx={{
        border: 0,
        height,
        width: '100%',
      }}
    />
  );
});

Iframe.propTypes = {
  /** Iframe height */
  height: PT.number,
  /** Iframe src */
  src: PT.string.isRequired,
  /** Iframe title */
  title: PT.string.isRequired,
};

export default Iframe;
