import { EDS_TEXT_PRIMARY, ESD_TEXT_QUIET } from '~/ui/constants';

import {
  black3,
  bold,
  evisortBlue,
  evisortYellow,
  semibold,
} from '../../../assets/shared-styles/general';

const panelStyles = {
  display: 'flex',
  alignItems: 'center',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '4px',
  padding: '16px',
  backgroundColor: 'rgba(0, 0, 0, 0.02)',
  fontSize: '15px',
};

const styles = {
  editCommandPanel: {
    ...panelStyles,
  },
  editCommandPanelActive: {
    ...panelStyles,
    backgroundColor: 'rgba(255, 196, 73, 0.1)',
  },
  panelText: {
    flexGrow: 1,
  },
  editDate: {
    fontWeight: bold,
  },
  verticalSeparator: {
    width: '16px',
  },
  horizontalSeparator: {
    height: '24px',
  },
  overviewTitleRightComponent: {
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: '700',
    textDecoration: 'none',
  },
  overviewInformation: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  overviewsRow: {
    display: 'flex',
    marginBottom: '30px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  overviewItem: {
    width: '25%',
    minWidth: '200px',
    marginBottom: '30px',
    paddingRight: '16px',
  },
  overviewItemMultiple: {
    width: '100%',
    marginBottom: '30px',
    paddingRight: '16px',
  },
  overviewItemTitle: {
    marginBottom: '8px',
    display: 'inline-flex',
    color: ESD_TEXT_QUIET,
    fontSize: '12px',
    fontWeight: semibold,
    textTransform: 'uppercase',
  },
  overviewItemContent: {
    color: EDS_TEXT_PRIMARY,
    fontSize: '15px',
    fontWeight: semibold,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  partiesShowAll: {
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
    cursor: 'pointer',
  },
  editItemContainer: {
    flexGrow: 1,
  },
  editItemContainerMultiple: {
    display: 'block',
    flexGrow: 1,
  },
  multipleValuesContainer: {
    alignItems: 'center',
    marginTop: '12px',
  },
  multipleValues: {
    marginLeft: '30px',
    position: 'relative',
    top: '-25px',
  },
  multipleValuesEmpty: {
    color: black3,
    fontSize: '15px',
  },
  textArea: {
    padding: '7px',
    maxWidth: '100%',
    maxHeight: '90px',
    fontSize: '15px',
    lineHeight: '20px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '2px',
    '&:focus': {
      outline: 'none',
    },
  },
  betaTooltip: {
    marginLeft: '3px',
    padding: '2px 3px 1px',
    display: 'inline-block',
    height: '13px',
    position: 'relative',
    top: 0,
    borderRadius: '3px',
    backgroundColor: evisortYellow,
    color: '#272727',
    fontSize: '8px',
    fontWeight: semibold,
  },
  tooltipContent: {
    textTransform: 'none',
  },
  helpTextTooltip: {
    marginLeft: '5px',
    display: 'inline-block',
  },
};

export default styles;
