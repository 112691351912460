import React, { useMemo, useState } from 'react';

import { ButtonVariant, Nullable, SharedButtonProps } from '../../types';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { TextInput } from '../TextInput';
import { useModal } from './useModal';

type Callback = () => void;

interface Props {
  children: React.ReactNode;
  confirmText: string;
  title: string;
  onDelete: () => void;
  isDeleting?: boolean;
  secondaryActions?: SharedButtonProps[];
}

export const useDeletePermanentModal = ({
  children,
  confirmText,
  isDeleting,
  secondaryActions,
  title,
  onDelete,
}: Props): [React.ReactNode, Callback, Callback] => {
  const [text, setText] = useState<Nullable<string>>('');

  const contents = useMemo(
    () => (
      <Layout direction="column" spacing={4}>
        {children}
        <Text>
          You won't be able to revert this action. If you are sure, please type
          "{confirmText}" in the box below:
        </Text>
        <TextInput name="confirm-delete" value={text} onChange={setText} />
      </Layout>
    ),
    [children],
  );

  const disabled = text !== confirmText;

  const handleHide = () => setText('');

  const props = useMemo(
    () => ({
      children: contents,
      primaryAction: {
        disabled,
        isLoading: isDeleting,
        text: 'Permanently delete',
        variant: 'danger' as ButtonVariant,
        onClick: onDelete,
      },
      secondaryActions,
      title,
      onHide: handleHide,
    }),
    [children, disabled, isDeleting, secondaryActions, title],
  );

  return useModal(props);
};
