export default `
<svg
  aria-hidden="true"
  aria-label="search-gradient"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M16.4648 15.599L12.9753 12.1094C13.7305 11.1979 14.1471 10.026 14.1471 8.75C14.1471 5.78125 11.6992 3.33334 8.73047 3.33334C5.73568 3.33334 3.33984 5.78125 3.33984 8.75C3.33984 11.7448 5.76172 14.1667 8.73047 14.1667C9.98047 14.1667 11.1523 13.75 12.0898 12.9948L15.5794 16.4844C15.7096 16.6146 15.8659 16.6667 16.0482 16.6667C16.2044 16.6667 16.3607 16.6146 16.4648 16.4844C16.7253 16.25 16.7253 15.8594 16.4648 15.599ZM4.58984 8.75C4.58984 6.45834 6.4388 4.58334 8.75651 4.58334C11.0482 4.58334 12.9232 6.45834 12.9232 8.75C12.9232 11.0677 11.0482 12.9167 8.75651 12.9167C6.4388 12.9167 4.58984 11.0677 4.58984 8.75Z"
    fill="url(#paint0_linear_3982_253)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_3982_253"
      x1="10"
      y1="3.33334"
      x2="10"
      y2="16.6667"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#7413FF" />
      <stop offset="1" stop-color="#4136FF" />
    </linearGradient>
  </defs>
</svg>
`;
