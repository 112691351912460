export const sortOptions = [
  { label: 'Sort by: Name ↓', value: 'name-desc' },
  { label: 'Sort by: Name ↑', value: 'name-asc' },
  { label: 'Sort by: Upload Date ↓', value: 'date_added-desc' },
  { label: 'Sort by: Upload Date ↑', value: 'date_added-asc' },
  { label: 'Sort by: Expiration Date ↓', value: 'ex_date-desc' },
  { label: 'Sort by: Expiration Date ↑', value: 'ex_date-asc' },
  { label: 'Sort by: Effective Date ↓', value: 'effective_date-desc' },
  { label: 'Sort by: Effective Date ↑', value: 'effective_date-asc' },
  { label: 'Sort by: Start Date ↓', value: 'start_date-desc' },
  { label: 'Sort by: Start Date ↑', value: 'start_date-asc' },
];

export function transformSelectedItemsForQueryParam(items) {
  return items.length
    ? items.map((item) => {
        return {
          document_handlers: item.document_handlers.map((itm) =>
            itm.id ? itm.id : itm,
          ),
          id: item.id,
          is_movable: item.is_movable,
        };
      })
    : [];
}
