import React, { memo } from 'react';

import { User as UserType } from '../../types';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { UserInfoOptions } from './types';
import { UserAvatar } from './UserAvatar';
import { UserName } from './UserName';

interface Props {
  user?: UserType;
  placeholder?: string;
  options?: UserInfoOptions;
}

export const UserInfo = memo(
  ({ options = {}, user, placeholder = 'Unknown User' }: Props) => {
    const { date, info, infoTooltip } = options;

    return (
      <Layout align="center" spacing={2}>
        <UserAvatar
          options={{ date, size: 'l' }}
          user={user}
          placeholder={placeholder}
        />
        <Layout direction="column">
          <UserName
            mode="info"
            user={user}
            options={options}
            placeholder={placeholder}
          />
          <Tooltip tooltip={infoTooltip}>
            <Text preset="help">{info}</Text>
          </Tooltip>
        </Layout>
      </Layout>
    );
  },
);
