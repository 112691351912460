// keep in sync with everest/persistence/enums.py
export enum TicketPermissionType {
  CompletedView = 'COMPLETED_VIEW',
  EditView = 'EDIT_VIEW',
  ReviewView = 'REVIEW_VIEW',
  SignView = 'SIGN_VIEW',
  FinalizeView = 'FINALIZE_VIEW',
  TicketView = 'TICKET_VIEW',
  SendForReview = 'SEND_FOR_REVIEW',
  EditForm = 'EDIT_FORM',
  UploadVersion = 'UPLOAD_VERSION',
  Reviewer = 'REVIEWER',
  ReassignAny = 'REASSIGN_ANY',
  Approve = 'APPROVE',
  Reject = 'REJECT',
  SendForSignatures = 'SEND_FOR_SIGNATURES',
  MarkForSignatures = 'MARK_FOR_SIGNATURES',
  UploadSigned = 'UPLOAD_SIGNED',
  BackToReview = 'BACK_TO_REVIEW',
  CollectSignatures = 'COLLECT_SIGNATURES',
  RemindReviewers = 'REMIND_REVIEWERS',
  RemindSignatories = 'REMIND_SIGNATORIES',
  RemindFinalizers = 'REMIND_FINALIZERS',
  SendForFinalization = 'SEND_FOR_FINALIZATION',
  FinalizeItem = 'FINALIZE_ITEM',
  RiskFlagging = 'RISK_FLAGGING',
  ActivityLog = 'ACTIVITY_LOG',
  MarkComplete = 'MARK_COMPLETE',
  Delete = 'DELETE',
  Admin = 'ADMIN',
  Download = 'DOWNLOAD',
  Share = 'SHARE',
  ViewAny = 'VIEW_ANY',
  ViewApprovalBasic = 'VIEW_APPROVAL_BASIC',
  ViewApprovalDetailed = 'VIEW_APPROVAL_DETAILED',
  Cancel = 'CANCEL_TICKET',
}
