import { black2 } from '../../../../assets/shared-styles/general';

const sectionTitleStyles = {
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  backgroundColor: '#e8e8e8',
  border: 'none',
  width: '100%',
  '& span': {
    marginLeft: '4px',
    color: black2,
    fontSize: '11px',
    textTransform: 'uppercase',
  },
};

const styles = {
  sectionTitleOpen: {
    ...sectionTitleStyles,
  },
  sectionTitleClosed: {
    ...sectionTitleStyles,
    '& svg': {
      transform: 'rotateZ(180deg)',
    },
  },
  sectionContent: {
    padding: '8px 0',
  },
};

export default styles;
