export const typography = {
  banner: {
    fontFamily: 'heading',
    fontSize: '2xl',
    fontWeight: 'medium',
    letterSpacing: '0px',
    lineHeight: '2xl',
  },
  title: {
    fontFamily: 'heading',
    fontSize: 'xl',
    fontWeight: 'bold',
    letterSpacing: '0px',
    lineHeight: 'xl',
  },
  subtitle: {
    fontFamily: 'heading',
    fontSize: '2l',
    fontWeight: 'bold',
    letterSpacing: '0px',
    lineHeight: '2l',
  },
  section: {
    fontFamily: 'body',
    fontSize: 'l',
    fontWeight: 'bold',
    letterSpacing: '0px',
    lineHeight: 'l',
  },
  navlink: {
    fontFamily: 'heading',
    fontSize: 'm',
    fontWeight: 'bold',
    letterSpacing: '0px',
    lineHeight: 'm',
    maxWidth: 'navlink.max-width',
    padding: 4,
  },
  'section-title': {
    fontFamily: 'heading',
    fontSize: 'm',
    fontWeight: 'medium',
    letterSpacing: '0px',
    lineHeight: 'l',
  },
  body: {
    fontFamily: 'body',
    fontSize: 'm',
    fontWeight: 'regular',
    letterSpacing: '0px',
    lineHeight: 'm',
  },
  'body-bold': {
    fontFamily: 'body',
    fontSize: 'm',
    fontWeight: 'bold',
    letterSpacing: '0px',
    lineHeight: 'm',
  },
  'body-medium': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'm',
    letterSpacing: '0px',
    lineHeight: 'm',
  },
  tiny: {
    fontFamily: 'body',
    fontSize: 's',
    fontWeight: 'medium',
    letterSpacing: '0px',
    lineHeight: 's',
  },
  'tiny-bold': {
    fontFamily: 'body',
    fontSize: 's',
    fontWeight: 'bold',
    letterSpacing: '0px',
    lineHeight: 's',
  },
};
