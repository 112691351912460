import { createSelector } from '@reduxjs/toolkit';

import { name } from '../slices/automation';

const selectSlice = (rootState: any) => rootState[name];

export const selectModelFilter = createSelector(
  selectSlice,
  (state) => state.model.current?.filter,
);
export const selectFilters = createSelector(
  selectSlice,
  (state) => state.filter.filters,
);
export const selectFieldValues = createSelector(
  selectSlice,
  (state) => state.filter.fieldValues,
);
export const selectClauseSuggestions = createSelector(
  selectSlice,
  (state) => state.filter.clause,
);
export const selectFolderTree = createSelector(
  selectSlice,
  (state) => state.filter.folderTree,
);
