import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Text } from '~/eds';
import { withUsers } from '~/hocs';
import { FlexLayout } from '~/ui';
import { sortByStringValue } from '~/utils/array';

import { getUserName } from '../../utils/user';
import EcMultipleSelectValue from '../Shared/EcMultipleSelectValue';
import EcMultiSelect from '../Shared/EcMultiSelect';

function DeprecatedUserMultiSelect({
  ariaLabel,
  usersProvided,
  selectedUserIds,
  width = '300px',
  onChange,
  // injected
  users,
}) {
  function getId(option) {
    return option.value;
  }

  function getOptions(userIds) {
    const options = [];
    userIds.forEach((userId) => {
      const user = users[userId];
      if (user) {
        options.push({
          label: getUserName(user),
          value: user.id,
        });
      }
    });
    return options;
  }

  const usersList = usersProvided ? usersProvided : users;
  const options = getOptions(Object.keys(usersList)).sort(
    sortByStringValue('label'),
  );
  const selectedOptions = getOptions(selectedUserIds);

  return (
    <FlexLayout flexDirection="column" space={1}>
      <EcMultiSelect
        aria-label={ariaLabel}
        value={selectedOptions}
        noOptionsMessage="No users found"
        options={options}
        placeholder="Search for users…"
        width={width}
        onChange={(updatedOptions) => {
          const updatedUserIds = updatedOptions.map(getId);
          onChange(updatedUserIds);
        }}
        getOptionLabel={(option) => option.label}
      />
      <FlexLayout alignItems="center" flexWrap="wrap" space={2}>
        <Icon icon="arrow-forward" role="presentation" />
        {selectedOptions.map(({ label, value }) => (
          <EcMultipleSelectValue
            key={value}
            label={label}
            closeHandler={() => {
              const updatedUserIds = selectedOptions
                .filter((user) => user.value !== value)
                .map(getId);
              onChange(updatedUserIds);
            }}
          />
        ))}
        {selectedOptions.length === 0 && (
          <Text color="text.secondary" variant="body-medium">
            No users selected
          </Text>
        )}
      </FlexLayout>
    </FlexLayout>
  );
}

DeprecatedUserMultiSelect.propTypes = {
  ariaLabel: PropTypes.string,
  usersProvided: PropTypes.object,
  selectedUserIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  width: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default withUsers(DeprecatedUserMultiSelect);
