import {
  Dashboard,
  DashboardTableColumn,
  fetchDashboardV2,
  fetchSavedDashboards,
  SavedDashboardsParams,
} from '../methods';

export const getDashboardV2: (uuid: string) => Promise<Dashboard> = async (
  uuid: string,
) => {
  const result = await fetchDashboardV2({ uuid });
  return {
    charts: result.data.attributes.charts,
    columns: result.data.attributes.result_table.map(
      (f: DashboardTableColumn) => String(f.filter_id),
    ),
    filters: result.data.attributes.filters as string[],
    id: result.data.id,
    name: result.data.attributes.name,
    entity: result.data.attributes.entity_type,
    query: result.data.attributes.query,
    is_default: result.data.attributes.is_default,
    visibility: result.data.attributes.visibility,
    userIds: result.data.attributes.user_ids,
    departmentIds: result.data.attributes.department_ids,
    creatorId: result.data.attributes.creator_id,
  };
};

export const getDashboardsV2 = async (params: SavedDashboardsParams) => {
  const savedDashboardsResponse = await fetchSavedDashboards(params);
  const results = (savedDashboardsResponse?.data || []).map(
    (savedDashboard) => ({
      ...savedDashboard.attributes,
      id: savedDashboard.id,
    }),
  );

  return {
    results,
    total: savedDashboardsResponse?.meta?.total,
  };
};
