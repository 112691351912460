import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { ENTER } from '~/constants/keyCodes';
import { OnboardingIdType } from '~/features/onboarding';
import { testIsDirty } from '~/utils/helper.utils';
import { isValueStrictlyEmpty } from '~/utils/strings';

import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import styles from './NameModal.styles';

class NameModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnKeyboardPress = this.handleOnKeyboardPress.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);

    this.state = {
      itemName: this.props.itemsCurrentName,
      itemsCurrentDescription: this.props.itemsCurrentDescription,
      bucketInfo: null,
    };

    this.descriptionTextRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleOnKeyboardPress, true);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleOnKeyboardPress, true);
  }

  handleOnKeyboardPress(event) {
    const { itemsCurrentName, itemsCurrentDescription } = this.props;
    const { itemName, itemsCurrentDescription: desciptionValue } = this.state;

    if (event.target === this.descriptionTextRef.current) {
      return;
    }
    const hasChanges =
      !isValueStrictlyEmpty(itemName) &&
      testIsDirty(
        { itemsCurrentName, itemsCurrentDescription },
        {
          itemsCurrentName: itemName,
          itemsCurrentDescription: desciptionValue,
        },
      );
    if (event.keyCode === ENTER && hasChanges) {
      this.handleActionClick();
    }
  }

  handleInputChange(itemName) {
    this.setState({ itemName });
  }

  handleTextAreaChange(itemsCurrentDescription) {
    this.setState({ itemsCurrentDescription });
  }

  handleInputFocus(event) {
    event.target.select();
  }

  handleActionClick() {
    const { itemName, itemsCurrentDescription } = this.state;
    const {
      itemsParentFolderId,
      handleNameChange,
      hideModal,
      bucketInfo,
    } = this.props;

    handleNameChange(itemName, itemsParentFolderId || itemsCurrentDescription);
    if (typeof bucketInfo === 'undefined') hideModal();
  }

  renderErrorMessage() {
    const { classes, errorMessage } = this.props;
    return errorMessage && errorMessage.length ? (
      <div className={classes.modalErrorMessage}>{errorMessage}</div>
    ) : null;
  }

  renderBucketMessage() {
    const { classes, bucketInfo } = this.props;
    return bucketInfo && bucketInfo.length ? (
      <div className={classes.modalBucketMessage}>{bucketInfo}</div>
    ) : null;
  }

  render() {
    const { itemName, itemsCurrentDescription: desciptionValue } = this.state;
    const {
      classes,
      id,
      itemsCurrentName,
      itemsCurrentDescription,
      title,
      labelText,
      includeTextArea,
      labelTextArea,
      confirmButtonIcon,
      confirmButtonText,
      hideModal,
    } = this.props;

    const hasChanges =
      !isValueStrictlyEmpty(itemName) &&
      testIsDirty(
        { itemsCurrentName, itemsCurrentDescription },
        {
          itemsCurrentName: itemName,
          itemsCurrentDescription: desciptionValue,
        },
      );

    return (
      <EcModalCard
        id={id}
        title={title}
        content={
          <div>
            <label className={classes.modalInputLabel}>{labelText}</label>
            <input
              className={classes.modalInput}
              value={itemName}
              onChange={(event) => {
                this.handleInputChange(event.target.value);
              }}
              onFocus={(event) => {
                this.handleInputFocus(event);
              }}
              data-testid="name-modal-text-input"
              title={`${title} name input text`}
            />
            {includeTextArea && (
              <div className={classes.modalTextAreaContainer}>
                <label className={classes.modalInputLabel}>
                  {labelTextArea}
                </label>
                <textarea
                  ref={this.descriptionTextRef}
                  className={classes.modalTextArea}
                  title={`${title} ${labelTextArea.toLowerCase()} input text`}
                  value={desciptionValue}
                  onChange={(event) => {
                    this.handleTextAreaChange(event.target.value);
                  }}
                />
              </div>
            )}
            {this.renderBucketMessage()}
            {this.renderErrorMessage()}
          </div>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              id={OnboardingIdType.CreateDepartmentButton}
              disabled={!hasChanges}
              yellow
              iconLeft={confirmButtonIcon}
              text={confirmButtonText}
              onClick={this.handleActionClick}
              data-testid="name-modal-confirm-button"
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

NameModal.propTypes = {
  title: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  id: PropTypes.string,
  itemsParentFolderId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  itemsCurrentName: PropTypes.string,
  itemsCurrentDescription: PropTypes.string,
  confirmButtonIcon: PropTypes.object,
  confirmButtonText: PropTypes.string.isRequired,
  editItemName: PropTypes.func,
  createItemName: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(NameModal);
