import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom-v5-compat';

import { evisortYellow } from '../../../assets/shared-styles/general';
import {
  AI,
  DOWNLOAD,
  ERROR,
  INFO,
  LOADING,
  SUCCESS,
  WARNING,
} from '../../../types/toast.types';
import CheckmarkIcon from '../Icons/CheckmarkIcon';
import DownloadIcon from '../Icons/DownloadIcon';
import InfoIcon from '../Icons/InfoIcon';
import LoadingSpinner from '../Icons/LoadingSpinner';
import SparkleIcon from '../Icons/SparkleIcon';
import WarningIcon from '../Icons/WarningIcon';
import styles from './EcToast.styles';

class EcToast extends Component {
  renderToastIcon(type) {
    switch (type) {
      case AI:
        return <SparkleIcon size="20" color="#FFF" />;
      case INFO:
        return <InfoIcon size="20" />;
      case SUCCESS:
        return <CheckmarkIcon size="20" green />;
      case WARNING:
        return <WarningIcon size="20" yellow />;
      case ERROR:
        return <WarningIcon size="20" red />;
      case DOWNLOAD:
        return <DownloadIcon size="20" color={evisortYellow} />;
      case LOADING:
        return <LoadingSpinner />;
      default:
        return <InfoIcon size="20" />;
    }
  }

  render() {
    const { classes, type, text, href, isExternal } = this.props;

    return href ? (
      // react-router-dom Link component does not support external links
      isExternal ? (
        <a
          href={href}
          className={classes.toastContainterLink}
          target="_blank"
          rel="noreferrer"
        >
          <div className={classes.toastContainter}>
            {this.renderToastIcon(type)}
            <div className={classes.toastText}>{text}</div>
          </div>
        </a>
      ) : (
        <Link to={href} className={classes.toastContainterLink}>
          <div className={classes.toastContainter}>
            {this.renderToastIcon(type)}
            <div className={classes.toastText}>{text}</div>
          </div>
        </Link>
      )
    ) : (
      <div className={classes.toastContainter}>
        {this.renderToastIcon(type)}
        <div className={classes.toastText}>{text}</div>
      </div>
    );
  }
}

EcToast.defaultProps = {
  type: INFO,
};

EcToast.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  isExternal: PropTypes.bool,
};

export default injectSheet(styles)(EcToast);
