import React, { useEffect } from 'react';

import { sortVariationsByPositionAndName } from '~/components/ClauseLibrary/utils';
import {
  Chip,
  formatNumber,
  FormField,
  Layout,
  Select,
  TextArea,
  types,
} from '~/eds';
import { api } from '~/redux';
import { ClauseData, GetClauseResponseData } from '~/redux/api/methods';
import { ClauseContent, Nullable } from '~/types';

import { GEN_AI_MAX_INSTRUCTIONS_LENGTH } from '../constant';
import { Prompt } from '../types';
import { getInstructions } from '../util';

interface Props {
  prompt: Prompt;
  selectedClauses: ClauseContent[];
  onUpdatePrompt: (updatedPrompt: Prompt) => void;
}

export const RedlinePrompt = ({
  prompt,
  selectedClauses,
  onUpdatePrompt,
}: Props) => {
  const {
    data: listClausesData,
  } = api.endpoints.clauseLibrarySearchClauses.useQuery({});

  let clauseOptions: types.Option<string>[] = [];
  if (listClausesData?.data?.length) {
    clauseOptions = [...listClausesData.data].map((clause) => ({
      label: clause.attributes.name,
      value: clause.id,
    }));
  }

  const [
    loadVariations,
    { data: selectedClauseData },
  ] = api.endpoints.clauseLibraryGetClause.useLazyQuery();

  let variationOptions: types.Option<string>[] = [];
  if (selectedClauseData?.variations?.length) {
    variationOptions = [...selectedClauseData.variations]
      .sort(sortVariationsByPositionAndName)
      .map((variation) => ({
        label: variation.name,
        value: variation.key,
        leftContent: (
          <Chip status={variation.status} text={variation.position} />
        ),
      }));
  }

  const selectClauseInputProps = {
    isMulti: false,
    options: clauseOptions,
    noOptionsMessage: 'No clauses found in your library.',
  };

  const selectVariationInputProps = {
    isMulti: false,
    options: prompt.clause ? variationOptions : [],
    noOptionsMessage: prompt.clause
      ? 'Selected clause has no variations.'
      : 'Select a clause type above to see its variations.',
  };

  const textAreaInputProps = {
    rows: 8,
  };

  const mapDataToClause = (clauseData: GetClauseResponseData): ClauseData => {
    return {
      key: clauseData.id,
      name: clauseData.attributes.name,
      guidance: '', // placeholder guidance is set in handleUpdateClause
    };
  };

  const getClauseDataFromName = (
    name = '',
  ): Nullable<ClauseData | undefined> => {
    const clauseData = [...listClausesData!.data].find(
      (clause) => clause.attributes.name === name,
    );
    if (clauseData) {
      return mapDataToClause(clauseData);
    }
  };

  const getClauseDataFromId = (
    id?: Nullable<string>,
  ): Nullable<ClauseData | undefined> => {
    if (!id) return null;
    if (listClausesData?.data?.length) {
      const clauseData = [...listClausesData.data].find(
        (clause) => clause.id === id,
      );
      if (clauseData) {
        return mapDataToClause(clauseData);
      }
    }
  };

  const handleUpdateClause = async (updatedClauseId?: Nullable<string>) => {
    let updatedVariation;
    const updatedClause = getClauseDataFromId(updatedClauseId);
    if (updatedClause) {
      // Fetch guidance and variation data of the selected clause, or reload from cache if previously fetched
      const { data } = await loadVariations(
        { clauseId: updatedClause.key },
        true,
      );
      updatedClause.guidance = data?.guidance ?? '';
      if (data?.variations?.length) {
        // auto-select "first" variation
        updatedVariation = [...data.variations].sort(
          sortVariationsByPositionAndName,
        )[0];
      }
    }
    onUpdatePrompt({
      ...prompt,
      clause: updatedClause ?? null,
      instructions: getInstructions(updatedClause, updatedVariation) ?? '',
      variation: updatedVariation ?? null,
    });
  };

  const handleUpdateVariation = (updatedVariationId: Nullable<string>) => {
    let updatedVariation;
    if (selectedClauseData?.variations?.length) {
      updatedVariation = [...selectedClauseData.variations].find(
        (variation) => variation.key === updatedVariationId,
      );
    }
    onUpdatePrompt({
      ...prompt,
      instructions: getInstructions(prompt.clause, updatedVariation),
      variation: updatedVariation ?? null,
    });
  };

  const handleUpdateInstructions = (updatedValue: Nullable<string>) => {
    onUpdatePrompt({
      ...prompt,
      instructions: updatedValue ?? '',
    });
  };

  useEffect(() => {
    if (selectedClauses.length && listClausesData?.data?.length) {
      let hasMatch = false;
      for (const selectedClause of selectedClauses) {
        const matchingClauseFromLibrary = getClauseDataFromName(
          selectedClause.name,
        );
        if (matchingClauseFromLibrary) {
          hasMatch = true;
          handleUpdateClause(matchingClauseFromLibrary.key);
          break;
        }
      }
      if (!hasMatch) {
        handleUpdateClause();
      }
    }
  }, [selectedClauses, listClausesData]);

  return (
    <Layout preset="sections">
      <FormField<string, false>
        label="Clause Type"
        input={Select}
        inputProps={selectClauseInputProps}
        name="clauseType"
        placeholder={placeholders.clause}
        value={prompt.clause?.key ?? null}
        onChange={(updatedValue) => handleUpdateClause(updatedValue)}
      />
      <FormField<string, false>
        label="Clause Variation"
        input={Select}
        inputProps={selectVariationInputProps}
        name="clauseVariation"
        placeholder={placeholders.variation}
        value={prompt.variation?.key ?? null}
        onChange={handleUpdateVariation}
      />
      <FormField<string, false>
        label="Instructions"
        description="Write instructions on how you want the AI to modify the clause."
        input={TextArea}
        inputProps={textAreaInputProps}
        name="instructions"
        placeholder={placeholders.instructions}
        value={prompt.instructions}
        onChange={handleUpdateInstructions}
        error={
          prompt.instructions.length > GEN_AI_MAX_INSTRUCTIONS_LENGTH
            ? `${formatNumber(prompt.instructions.length)}/${formatNumber(
                GEN_AI_MAX_INSTRUCTIONS_LENGTH,
                {
                  unit: 'character',
                },
              )}. Shorten your instructions text.`
            : undefined
        }
        footer={`${formatNumber(prompt.instructions.length)}/${formatNumber(
          GEN_AI_MAX_INSTRUCTIONS_LENGTH,
          {
            unit: 'character',
          },
        )}`}
        required
      />
    </Layout>
  );
};

const placeholders = {
  clause: 'Select Clause Type',
  instructions:
    'Sample instructions:\nModify the limitation of liability clause to exclude damages from gross negligence or willful misconduct.',
  variation: 'Select Clause Variation',
};
