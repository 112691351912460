import PropTypes from 'prop-types';
import React, { useState } from 'react';
import injectSheet from 'react-jss';

import { sendForgotPasswordEmail } from '~/api';
import {
  black2,
  evisortRed,
  evisortYellow,
  semibold,
} from '~/assets/shared-styles/general';
import { LoginPageContainer } from '~/components/LoginPage';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { useAsync } from '~/hooks';
import { Alert } from '~/ui';
import { isEmailValid } from '~/utils/helper.utils';

function Page(props) {
  const { classes } = props;

  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState();
  const [workspace, handleWorkspaceChange] = useState();
  const [email, handleEmailChange] = useState();

  const validEmail = isEmailValid(email);

  const { executor: handleForgotPasswordEmail, isLoading } = useAsync(
    sendForgotPasswordEmail,
    { client: workspace, userId: email },
    {
      successHandler: () => {
        setEmailSent(true);
      },
      errorHandler: () => {
        setError(true);
      },
    },
  );

  return (
    <div>
      {emailSent ? (
        <LoginPageContainer classes={classes} title="Check your inbox">
          <Alert enableIcon variant="success">
            You will receive reset link shortly. You may need to check your spam
            folder or unblock evisort@evisort.com
          </Alert>
        </LoginPageContainer>
      ) : (
        <LoginPageContainer classes={classes} title="Link has expired">
          <div className={classes.contentCopy}>
            Enter your workspace and email to receive a new link. You may need
            to check your spam folder or unblock evisort@evisort.com
          </div>
          {error && (
            <div className={classes.errorMessage}>
              <Alert enableIcon variant="danger">
                An error ocurred. Please try again.
              </Alert>
            </div>
          )}
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <form onSubmit={handleForgotPasswordEmail}>
              <div className={classes.rowContainer}>
                <label className={classes.loginFormLabel} htmlFor="workspace">
                  Workspace
                </label>
                <input
                  data-testid="login/reset-password__token-expired-workspace"
                  type="text"
                  onChange={(e) => handleWorkspaceChange(e.target.value)}
                  className={classes.textInput}
                />
              </div>
              <div className={classes.rowContainer}>
                <label className={classes.loginFormLabel} htmlFor="email">
                  Email
                </label>
                <input
                  data-testid="login/reset-password__token-expired-email"
                  type="text"
                  onChange={(e) => handleEmailChange(e.target.value)}
                  className={classes.textInput}
                />
              </div>
              <div className={classes.formButtonContainer}>
                <button
                  type="submit"
                  className={classes.formButton}
                  onClick={handleForgotPasswordEmail}
                  disabled={!workspace || !validEmail}
                >
                  Send new link
                </button>
              </div>
            </form>
          )}
        </LoginPageContainer>
      )}
    </div>
  );
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

const formButton = {
  padding: '10px 20px',
  width: '360px',
  display: 'inline-block',
  backgroundColor: evisortYellow,
  border: 'solid 1px rgba(0, 0, 0, 0.25)',
  borderRadius: '3px',
  color: black2,
  fontSize: '15px',
  cursor: 'pointer',
  textDecoration: 'none',
  '&:disabled': {
    opacity: 0.5,
  },
};

const styles = {
  rowContainer: {
    marginBottom: '24px',
    position: 'relative',
    display: 'inline-block',
  },
  formButtonContainer: {
    marginTop: '20px',
  },
  formButton: {
    ...formButton,
  },
  loginFormLabel: {
    display: 'block',
    fontSize: '15px',
    fontWeight: semibold,
    textAlign: 'left',
  },
  textInput: {
    margin: '10px 0',
    padding: '10px',
    width: '360px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    color: black2,
    fontSize: '16px',
    '&:placeholder': {
      opacity: 0.5,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  contentCopy: {
    margin: '0 auto 30px',
    width: '360px',
    color: black2,
    fontSize: '15px',
    textAlign: 'left',
    lineHeight: '24px',
  },
  errorMessage: {
    margin: '20px',
    color: evisortRed,
    fontSize: '15px',
  },
};

export default injectSheet(styles)(Page);
