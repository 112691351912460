import { bold, evisortBlue } from '../../../assets/shared-styles/general';

const styles = {
  uploadInfoPopup: {
    position: 'fixed',
    bottom: 0,
    left: '48px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px 0 rgba(17, 17, 17, 0.15)',
    fontSize: '14px',
    zIndex: 99,
  },
  uploadInfoContainer: {
    display: 'flex',
    padding: '13px 13px 10px',
    width: '350px',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eee',
    fontWeight: bold,
  },
  itemContainer: {
    width: '350px',
    maxHeight: '300px',
    overflow: 'auto',
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  itemRow: {
    padding: '10px',
    width: '100%',
    borderBottom: '1px solid #eee',
    justifyContent: 'space-between',
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(0, 127, 186, .08)',
    },

    '& a': {
      position: 'relative',
      top: '-3px',
      marginLeft: '4px',
      color: evisortBlue,
      textAlign: 'right',
      fontSize: '13px',
      fontWeight: bold,
      textDecoration: 'none',
    },
  },
  documentTypeIcon: {
    display: 'inline-flex',
    position: 'relative',
    top: '4px',
    margin: '0 5px',
  },
  documentName: {
    width: '65%',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  docUploadStatus: {
    width: '11%',
    display: 'inline-block',
  },
};

export default styles;
