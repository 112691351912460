import { capitalize } from 'lodash';
import React from 'react';

import { ActionsMenu } from '../../components';
import { Action } from '../../types';
import { Comment, User } from './types';
import { testIsViewerUser } from './utils';

interface Props {
  comment: Comment;
  viewer: User;
  onCopyLink: (comment: Comment) => void;
  onDelete: (comment: Comment) => void;
  onResolveComment: (commentData: Comment) => void;
  onUnresolveComment: (commentData: Comment) => void;
  onUpdate: (comment: Comment) => void;
  onFilterAction?: (action: Action, comment: Comment) => Action;
}

type CommentActionsType =
  | 'copyLink'
  | 'delete'
  | 'edit'
  | 'resolve'
  | 'unresolve';

const getTooltipMessage = (
  action: CommentActionsType,
  comment: Comment,
  viewer: User,
) => {
  switch (action) {
    case 'edit':
      if (comment.isResolved) return 'Cannot edit a resolved comment';
      if (!testIsViewerUser(comment.createdBy, viewer))
        return 'Only the owner can edit this comment';
      return 'Edit the comment';
    case 'resolve':
    case 'unresolve':
      if (!!comment.threadId) return `Cannot ${action} a reply comment`;
      return `${capitalize(action)} a comment`;
    case 'delete':
      if (!testIsViewerUser(comment.createdBy, viewer))
        return 'Only the owner can delete this comment';
      return 'Delete the comment';
    case 'copyLink':
      if (comment.isResolved) return 'Unresolve this comment to copy the link';
      return 'Copy the link to this comment';
    default:
      return '';
  }
};

export const CommentActions = ({
  comment,
  viewer,
  onCopyLink,
  onDelete,
  onFilterAction,
  onResolveComment,
  onUnresolveComment,
  onUpdate,
}: Props) => {
  const { createdBy, isResolved, threadId } = comment;

  const actions: Record<CommentActionsType, (comment: Comment) => void> = {
    edit: onUpdate,
    copyLink: onCopyLink,
    delete: onDelete,
    resolve: onResolveComment,
    unresolve: onUnresolveComment,
  };

  const isViewerUser = testIsViewerUser(createdBy, viewer);

  const actionsLabel = [
    {
      label: isResolved ? 'Unresolve' : 'Resolve',
      value: isResolved ? 'unresolve' : 'resolve',
      disabled: !!threadId,
      tooltip: getTooltipMessage(
        isResolved ? 'unresolve' : 'resolve',
        comment,
        viewer,
      ),
    },
    {
      label: 'Edit',
      value: 'edit',
      disabled: isResolved || !isViewerUser,
      tooltip: getTooltipMessage('edit', comment, viewer),
    },
    {
      label: 'Copy link',
      value: 'copyLink',
      disabled: isResolved,
      tooltip: getTooltipMessage('copyLink', comment, viewer),
    },
    {
      label: 'Delete',
      value: 'delete',
      disabled: !isViewerUser,
      tooltip: getTooltipMessage('delete', comment, viewer),
    },
  ];

  const activeActions = onFilterAction
    ? actionsLabel.map((action) => onFilterAction(action, comment))
    : actionsLabel;

  return (
    <ActionsMenu
      enablePortal
      actions={activeActions}
      name="comment actions"
      onActionClick={(actionType) => {
        const action = actions[actionType as CommentActionsType];
        action?.(comment);
      }}
    />
  );
};
