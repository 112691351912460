import React from 'react';

import { HtmlEntityType } from '../../../enums';
import {
  DepartmentOption,
  Option,
  PilotId,
  SharedSelectProps,
  User,
} from '../../../types';
import { Select } from '../Select';
import { transformUserOption } from './UserSelect';

interface Props<M, Role> extends SharedSelectProps<PilotId | Role, M> {
  users: User[];
  departments: DepartmentOption[];
  enableEmail?: boolean;
  enablePortal?: boolean;
  roles?: Option<Role>[];
}

export const UserDepartmentSelect = <M extends boolean, Role extends string>({
  users,
  departments,
  enableEmail = true,
  enablePortal,
  roles = [],
  value,
  onChange,
  onSearch,
  isLoading,
  ...rest
}: Props<M, Role>) => {
  const userOptions = users.map(transformUserOption({ enableEmail }));
  const departmentOptions = departments.map(transformToDepartmentOption);
  const options = [...roles, ...departmentOptions, ...userOptions];
  return (
    <Select
      enableErrorMessage={true}
      enablePortal={enablePortal}
      enableSelectAll={false}
      isClearable={false}
      options={options}
      value={value}
      isLoading={isLoading}
      onChange={onChange}
      onSearch={onSearch}
      {...rest}
    />
  );
};

export const transformToDepartmentOption = (department: DepartmentOption) => {
  return {
    value: `${department.id}`,
    description: `${department.path.join(` ${HtmlEntityType.RightArrow} `)} ${
      HtmlEntityType.RightArrow
    } ${department.name}`,
    label: department.name,
    leftIcon: 'department' as const,
  };
};
