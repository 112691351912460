import { Select, types } from '~/eds';
import { Nullable } from '~/types';

interface FieldSelectProps {
  filteredValues: string[];
  id: string;
  name: string;
  options: types.Option<string>[];
  shouldValidate: boolean;
  value: Nullable<string>;
  onChange: (value: Nullable<string>) => void;
  placeholder?: string;
}

export const FieldSelect = ({
  filteredValues,
  id,
  name,
  options,
  placeholder,
  shouldValidate,
  value,
  onChange,
}: FieldSelectProps) => {
  let error;
  if (shouldValidate) {
    if (value == null) {
      error = 'Missing Field';
    } else if (!options.find((option) => option.value === value)) {
      error = 'Invalid Field';
    }
  }

  const filterOptions = (option: types.Option<string>) =>
    filteredValues.indexOf(option.value) === -1;

  return (
    <Select<string, false>
      error={error}
      filterOption={filterOptions}
      id={id}
      isMulti={false}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={error ?? placeholder}
      value={value}
    />
  );
};
