export default `
<svg
  aria-hidden="true"
  aria-label="expiring"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C10.3438 20 8.84375 19.5312 7.5625 18.6875C7.21875 18.4688 7.125 18 7.34375 17.6562C7.59375 17.3125 8.0625 17.2188 8.40625 17.4375C9.4375 18.125 10.6562 18.5 12 18.5C15.5625 18.5 18.5 15.5938 18.5 12C18.5 8.4375 15.5625 5.5 12 5.5C9.6875 5.5 7.65625 6.71875 6.5 8.5H8.25C8.65625 8.5 9 8.84375 9 9.25C9 9.6875 8.65625 10 8.25 10H4.75C4.3125 10 4 9.6875 4 9.25V5.75C4 5.34375 4.3125 5 4.75 5C5.15625 5 5.5 5.34375 5.5 5.75V7.34375C6.9375 5.34375 9.3125 4 12 4ZM12 8C12.4062 8 12.75 8.34375 12.75 8.75V11.7188L14.75 13.7188C15.0625 14.0312 15.0625 14.5 14.75 14.7812C14.4688 15.0938 14 15.0938 13.7188 14.7812L11.4688 12.5312C11.3125 12.4062 11.25 12.2188 11.25 12V8.75C11.25 8.34375 11.5625 8 12 8Z"
    fill="currentcolor"
  />
</svg>
`;
