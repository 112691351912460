import React, { memo } from 'react';

import {
  Folder,
  IconType,
  Option,
  PilotId,
  SharedSelectProps,
} from '../../../types';
import { Select } from '../Select';

interface Props<M> extends SharedSelectProps<PilotId, M> {
  /** Folders that will be available as options. */
  folders: Folder[];
}

export const FolderSelect = memo(
  <M extends boolean>({ folders, onChange, value, ...rest }: Props<M>) => {
    const options = folders.map(
      (f: Folder): Option<PilotId> => ({
        value: f.id,
        label: f.name,
        leftIcon: 'folder' as IconType,
        description: f.path,
        disabled: f.disabled,
      }),
    );

    return (
      <Select
        {...rest}
        options={options}
        placeholder="Search folders…"
        value={value}
        onChange={onChange}
      />
    );
  },
);
