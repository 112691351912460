import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import injectSheet from 'react-jss';
import {
  useBlockLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';

import EcFilterInput from '~/components/Shared/EcFilterInput';
import EcPaginate from '~/components/Shared/EcPaginate';
import EcSelect from '~/components/Shared/EcSelect';
import { PAGE_SIZE_LIST, PAGE_START } from '~/constants/page';
import { Icon } from '~/eds';
import { QueryParamType } from '~/enums';
import { FlexLayout } from '~/ui';
import { getStorageItem } from '~/utils/browser';
import { coercePageIndex } from '~/utils/number';
import { toSnakeTrimmed } from '~/utils/strings';

import styles from './ReactTable.styles';
import {
  getTableColumnWidthName,
  persistTableColumnWidth,
} from './ReactTable.utils';

const CELL_MIN_WIDTH = 150;
const CELL_WIDTH = 200;
const CELL_MAX_WIDTH = 1000;

const GlobalFilter = (props) => {
  const { resetPagination, setGlobalFilter, title, globalFilter } = props;

  return (
    <EcFilterInput
      value={globalFilter || ''}
      onChange={(searchString) => {
        setGlobalFilter(searchString || undefined);
        if (!searchString) {
          resetPagination();
        }
      }}
      onClearSearch={() => {
        setGlobalFilter('');
        resetPagination();
      }}
      placeholder={`Filter ${title}...`}
    />
  );
};

const compareIgnoreCase = (a, b) => {
  if (typeof a === 'string') {
    return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
  } else {
    return a > b ? 1 : -1;
  }
};

const Table = (props) => {
  const {
    classes,
    columns,
    data,
    handleTablePropsChange,
    title,
    settings,
  } = props;

  const {
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = useTable(
    {
      columns,
      data: data,
      disableMultiSort: true,
      disableSortRemove: true,
      initialState: {
        pageIndex: coercePageIndex(settings.page),
        pageSize: settings.pageSize,
        sortBy: [
          { id: settings.sortedColumn, desc: settings.sortedOrder === 'true' },
        ],
      },
      manualPagination: false,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          return compareIgnoreCase(
            row1.values[columnName],
            row2.values[columnName],
          );
        },
      },
    },
    useBlockLayout,
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
  );

  // hide options that are greater than X elements
  const filteredPageSizes = PAGE_SIZE_LIST.filter(
    (size) => size <= data.length,
  ).map((size) => ({
    label: size,
    value: size,
  }));

  let oldSortBy = {
    sortedColumn: settings.sortedColumn,
    sortedOrderDesc: settings.sortedOrder,
  };
  const [colOrd, setColOrd] = useState(Object.values(oldSortBy).toString());

  useEffect(
    () => {
      const newColOrder = Object.values({
        sortedColumn: state.sortBy[0].id,
        sortedOrderDesc: state.sortBy[0].desc,
      }).toString();

      if (colOrd !== newColOrder) {
        gotoPage(0);
        handleTablePropsChange({
          [QueryParamType.Page]: PAGE_START,
          [QueryParamType.SortedColumn]: state.sortBy[0].id,
          [QueryParamType.SortedOrder]: state.sortBy[0].desc,
        });
      }
      setColOrd(newColOrder);
    },
    [state.sortBy[0]], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const tableName = toSnakeTrimmed(title);
    persistTableColumnWidth(state.columnResizing, tableName);
  }, [state.columnResizing, title]);

  function resetPagination() {
    gotoPage(coercePageIndex(PAGE_START));
    handleTablePropsChange({ [QueryParamType.Page]: PAGE_START });
  }

  return (
    <FlexLayout flexDirection="column" space={6}>
      <div className={classes.searchInput}>
        <GlobalFilter
          title={title}
          globalFilter={state.globalFilter}
          handleTablePropsChange={handleTablePropsChange}
          setGlobalFilter={setGlobalFilter}
          resetPagination={resetPagination}
        />
      </div>
      <div style={{ border, overflowX: 'auto' }}>
        <div {...getTableProps()}>
          <caption>
            <span className="screenReaderText">
              {title} table column headers with buttons are sortable
            </span>
          </caption>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                if (column.render('Header')) {
                  const getSortedStatus = () => {
                    if (!column.isSorted) {
                      return 'with buttons are sortable';
                    } else {
                      return column.isSortedDesc
                        ? 'sorted descending'
                        : 'sorted ascending';
                    }
                  };
                  return (
                    <>
                      <div
                        className={classes.tableHeaderCell}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                      >
                        <button
                          className={classes.headerCellContent}
                          title={`${
                            column.Header
                          } column header ${getSortedStatus()}`}
                        >
                          <div className={classes.headerCellLabel}>
                            {column.render('Header')}
                          </div>
                          <div className={classes.headerCellIcon}>
                            {column.isSorted &&
                              (column.isSortedDesc ? (
                                <Icon
                                  icon="arrow-down"
                                  label="sort descending"
                                />
                              ) : (
                                <Icon icon="arrow-up" label="sort ascending" />
                              ))}
                          </div>
                        </button>
                      </div>

                      {column.canResize && (
                        <div
                          className={classes.headerCellResizer}
                          {...column.getResizerProps()}
                          tabIndex={-1}
                          title="resize column"
                        />
                      )}
                    </>
                  );
                } else {
                  // dot dot menu still consider one column, but blank one.
                  return <div className={classes.tableHeaderCell} />;
                }
              })}
            </div>
          ))}
          <div {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <div className={classes.tableRow} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <div className={classes.tableCell} {...cell.getCellProps()}>
                      <div className={classes.cellContent}>
                        {cell.render('Cell')}
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
            {page.length === 0 && (
              <div className={classes.tableEmptyState}>
                No {title} match your filter.
              </div>
            )}
          </div>
        </div>
      </div>

      {rows.length > state.pageSize && (
        <FlexLayout alignItems="center" justifyContent="space-between">
          <EcPaginate
            onPageChange={(pageClicked) => {
              gotoPage(pageClicked.selected);
              handleTablePropsChange(
                {
                  [QueryParamType.Page]: pageClicked.selected + 1,
                },
                true,
              );
            }}
            pageCount={Math.ceil(rows.length / state.pageSize)}
            forcePage={state.pageIndex}
          />

          {filteredPageSizes.length ? (
            <EcSelect
              width="100px"
              options={filteredPageSizes}
              defaultValue={{ label: state.pageSize, value: state.pageSize }}
              onChange={(option) => {
                gotoPage(0);
                setPageSize(option.value);
                handleTablePropsChange({
                  [QueryParamType.Page]: PAGE_START,
                  [QueryParamType.PageSize]: option.value,
                });
              }}
              isSearchable={false}
            />
          ) : null}
        </FlexLayout>
      )}
    </FlexLayout>
  );
};

const ReactTable = (props) => {
  const {
    classes,
    title,
    handleTablePropsChange,
    settings,
    columns,
    data,
  } = props;
  const tableName = toSnakeTrimmed(title);

  const tableColumns = columns.map((column) => {
    const {
      label,
      value,
      width,
      disableGlobalFilter,
      sortable = true,
      resizable = false,
      renderCustomCell,
    } = column;

    return {
      Header: label,
      accessor: value,
      Cell: ({ row, cell }) =>
        renderCustomCell ? renderCustomCell(row.original) : cell.value,
      disableSortBy: !sortable,
      disableResizing: !resizable,
      disableGlobalFilter,
      minWidth: width < CELL_MIN_WIDTH ? width : CELL_MIN_WIDTH,
      width:
        getStorageItem(getTableColumnWidthName(tableName, column.value)) ||
        width ||
        CELL_WIDTH,
      maxWidth: width > CELL_MAX_WIDTH ? width : CELL_MAX_WIDTH,
    };
  });

  const columnsMapped = useMemo(
    () => [...tableColumns],
    [tableColumns, props], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { pageSize } = settings;

  return (
    <Table
      classes={classes}
      title={title}
      handleTablePropsChange={handleTablePropsChange}
      settings={settings}
      columns={columnsMapped}
      data={data}
      pageCount={Math.ceil(data.length / pageSize)}
    />
  );
};

const border = '1px solid rgba(0, 0, 0, 0.2)';

export const columnPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  width: PropTypes.number,
  disableGlobalFilter: PropTypes.bool,
  sortable: PropTypes.bool,
  resizable: PropTypes.bool,
  renderCustomCell: PropTypes.func,
});

export const settingsPropTypes = PropTypes.shape({
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortedColumn: PropTypes.string,
  sortedOrder: PropTypes.string,
});

ReactTable.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.arrayOf(columnPropTypes).isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  settings: PropTypes.arrayOf(settingsPropTypes),
};

export default injectSheet(styles)(ReactTable);
