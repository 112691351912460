export default `
<svg
  aria-hidden="true"
  aria-label="save-as-version"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M5.36914 16.6001V6.6001C5.36914 6.3501 5.58789 6.1001 5.86914 6.1001H6.36914V9.3501C6.36914 9.7876 6.68164 10.1001 7.11914 10.1001H13.1191C13.5254 10.1001 13.8691 9.7876 13.8691 9.3501V6.2251L16.3066 8.69385C16.3379 8.7251 16.3691 8.75635 16.3691 8.81885V12.4438L17.8691 10.9438V8.7251C17.8691 8.3501 17.6816 7.94385 17.4004 7.6626L14.8066 5.06885C14.5254 4.7876 14.1191 4.6001 13.7441 4.6001H5.86914C4.74414 4.6001 3.86914 5.50635 3.86914 6.6001V16.6001C3.86914 17.7251 4.74414 18.6001 5.86914 18.6001H11.9629L12.2754 17.1001H5.86914C5.58789 17.1001 5.36914 16.8813 5.36914 16.6001ZM7.86914 6.1001H12.3691V8.6001H7.86914V6.1001ZM13.4629 16.7563C13.3691 16.8501 13.3379 16.9438 13.3066 17.0376L12.8691 19.2876C12.8066 19.4751 12.9941 19.6626 13.1816 19.6001L15.4316 19.1626C15.5254 19.1313 15.6191 19.1001 15.7129 19.0063L19.6191 15.1001L17.3691 12.8501L13.4629 16.7563ZM10.8691 11.6001C9.74414 11.6001 8.86914 12.5063 8.86914 13.6001C8.86914 14.7251 9.74414 15.6001 10.8691 15.6001C11.9629 15.6001 12.8691 14.7251 12.8691 13.6001C12.8691 12.5063 11.9629 11.6001 10.8691 11.6001ZM21.5254 11.6001L20.8691 10.9438C20.4316 10.5063 19.7129 10.5063 19.2754 10.9438L18.0879 12.1313L20.3379 14.3813L21.5254 13.1938C21.9629 12.7563 21.9629 12.0376 21.5254 11.6001Z"
    fill="currentcolor"
  />
</svg>
`;
