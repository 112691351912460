import React from 'react';
import { components } from 'react-select';

import { UI_SELECT_CONTROL_TESTID } from '~/constants/testids';

import Box from '../Box';
import Chip from '../Chip';
import FlexLayout from '../FlexLayout';
import Icon from '../Icon';
import Text from '../Text';
import Tooltip from '../Tooltip';

function Control(props) {
  const { selectProps } = props;

  return (
    <components.Control
      {...props}
      className={UI_SELECT_CONTROL_TESTID}
      innerProps={{
        ...props.innerProps,
        'aria-label': selectProps.name,
        role: selectProps.role,
      }}
    />
  );
}

function DropdownIndicator(props) {
  const { isCreatable } = props.selectProps;

  if (isCreatable) return null;
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        color={props.isFocused ? 'gray-800' : 'gray-500'}
        icon="chevronDown"
        size="s"
      />
    </components.DropdownIndicator>
  );
}

function IndicatorSeparator() {
  return null;
}

function Menu(props) {
  return (
    <components.Menu {...props}>
      <Box
        py={2}
        sx={{
          border: 'border',
          borderRadius: 'm',
          boxShadow: 'depth-3',
          zIndex: 'modal',
        }}
      >
        {props.children}
      </Box>
    </components.Menu>
  );
}

function NoOptionsMessage(props) {
  return (
    <components.NoOptionsMessage {...props}>
      <Text color="gray-600" variant="s-dense">
        {props.children}
      </Text>
    </components.NoOptionsMessage>
  );
}

function Option(props) {
  const { children, data, isDisabled, isFocused, isSelected } = props;
  const { icon, indent = 0, isDefault, tooltip } = data;
  return (
    <components.Option {...props} getStyles={() => {}}>
      <Tooltip arrow={false} content={tooltip} placement="bottom">
        <FlexLayout
          alignItems="center"
          bg={isSelected ? 'gray-200' : isFocused ? 'blue-100' : 'white'}
          disabled={isDisabled}
          justifyContent="space-between"
          pl={Math.max(indent - 1, 0) * 10 + 4}
          pr={4}
          py={2}
          space={2}
          sx={{
            cursor: 'pointer',
            minHeight: 'input-height',
            '@media (prefers-contrast: more)': {
              outline: isFocused ? 'auto' : null,
            },
          }}
        >
          <FlexLayout alignItems="center" space={2}>
            {indent > 0 && <Icon color="gray-500" icon="subArrow" size="s" />}
            {icon}
            <Text variant="s-dense">{children}</Text>
          </FlexLayout>
          {isDefault && <Chip text="Default" />}
        </FlexLayout>
      </Tooltip>
    </components.Option>
  );
}

function Placeholder(props) {
  return (
    <components.Placeholder {...props}>
      <Text variant="s-dense">{props.children}</Text>
    </components.Placeholder>
  );
}

function SingleValue(props) {
  const { icon, tooltip, valueLabelOverride } = props.data;
  const label = valueLabelOverride || props.children;
  // TODO: <Text shouldTruncate /> should auto handle tooltip based on its contents in the future.
  return (
    <components.SingleValue {...props}>
      <Tooltip arrow={false} content={tooltip || label} placement="bottom">
        <FlexLayout alignItems="center" px={2} space={2}>
          {icon}
          <Text variant="s-dense">{label}</Text>
        </FlexLayout>
      </Tooltip>
    </components.SingleValue>
  );
}

export default {
  Control,
  DropdownIndicator,
  IndicatorSeparator,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
};
