import { types } from '~/eds';
import { pilotV3 } from '~/services';

type Request = {
  id: types.PilotId;
};

export const deleteSearchV3SavedSearch = ({ id }: Request): Promise<void> => {
  return pilotV3.remove(`/saved-search/${id}/`);
};
