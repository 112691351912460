import React, { useEffect, useState } from 'react';
import * as ReactTabs from 'react-tabs';

import { Tab, UserAction } from '../../types';
import { typedMemo } from '../../utils';
import { Actions } from '../Actions';
import { Box } from '../Box';
import { ContentContainer } from '../ContentContainer';
import { Layout } from '../Layout';
import { TabItem } from './TabItem';

interface Props<V> {
  /** Selected tab (value) */
  selectedTab: string;
  /** List of tabs */
  tabs: Tab<V>[];
  /** Callback when tab is selected */
  onSelectTab: (updatedSelectedTab: V) => void;
  /** Tabs actions */
  actions?: UserAction[];
  /** Enables bottom border of tabs */
  enableBorder?: boolean;
  /** Enables content padding of tab panels */
  enableContentPadding?: boolean;
  /** Tab panel height */
  panelHeight?: '100%' | 'none';
}

export const Tabs = typedMemo(
  <V extends string>({
    actions,
    enableBorder = false,
    enableContentPadding = true,
    panelHeight = 'none',
    selectedTab,
    tabs,
    onSelectTab,
  }: Props<V>) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
      const selectedTabIndex = tabs.findIndex(
        (tab) => tab.value === selectedTab,
      );
      setSelectedIndex(Math.max(selectedTabIndex, 0)); // set to first tab index if no valid tab index can be found
    }, [selectedTab, tabs]);

    const handleSelectTab = (updatedSelectedIndex: number) => {
      setSelectedIndex(updatedSelectedIndex);
      onSelectTab(tabs[updatedSelectedIndex].value);
    };

    // TODO: there are explicit assignments of null to various classNames.  This is a result of legacy components still using custom global `react-tabs.css` styles.  Once those are removed, these can be removed/simplified as well.
    return (
      <Box
        styles={componentStyles.wrapper({
          enableBorder,
          enableContentPadding,
          panelHeight,
        })}
      >
        <ReactTabs.Tabs
          className={null}
          selectedIndex={selectedIndex}
          onSelect={handleSelectTab}
        >
          <Layout justify="space-between" align="center" spacing={4}>
            <ReactTabs.TabList className={null}>
              {tabs.map(({ count, disabled, label, tooltip, value }, index) => (
                <ReactTabs.Tab
                  key={value}
                  className={null}
                  disabled={disabled}
                  disabledClassName={null}
                  selectedClassName={null}
                >
                  <TabItem
                    count={count}
                    disabled={disabled}
                    isFocused={selectedIndex === index}
                    isSelected={tabs[selectedIndex].value === value}
                    label={label}
                    tooltip={tooltip}
                  />
                </ReactTabs.Tab>
              ))}
            </ReactTabs.TabList>
            {actions && <Actions actions={actions} />}
          </Layout>
          {tabs.map((tab) => {
            const { loadingContent, placeholderContent, panel } = tab;
            return (
              <ReactTabs.TabPanel
                key={tab.value}
                className={null}
                forceRender={tab.forceRender}
                selectedClassName={null}
                style={{
                  display: tab.value === selectedTab ? 'block' : 'none',
                }}
              >
                <ContentContainer
                  loadingContent={loadingContent}
                  placeholderContent={placeholderContent}
                >
                  <>{panel}</>
                </ContentContainer>
              </ReactTabs.TabPanel>
            );
          })}
        </ReactTabs.Tabs>
      </Box>
    );
  },
);

const componentStyles = {
  wrapper: ({
    enableBorder,
    enableContentPadding,
    panelHeight,
  }: {
    enableBorder: boolean;
    enableContentPadding: boolean;
    panelHeight: '100%' | 'none';
  }) => {
    const padding = enableContentPadding ? 4 : 0;
    return {
      height: '100%',
      width: '100%',
      minHeight: 0,
      '& [data-rttabs=true]': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      '& [role=tablist]': {
        alignItems: 'center',
        display: 'flex',
        borderBottom: enableBorder ? 'border.divider' : 'none',
        flex: 'none',
        listStyle: 'none',
        margin: 0,
        padding: 0,
      },
      '& [role=tabpanel]': {
        height: panelHeight,
        overflowY: 'auto',
        padding,
      },
    };
  },
};
