import {
  black2,
  black3,
  bold,
  evisortBlue,
  evisortGreenDark,
  heavy,
  semibold,
} from '../../../../assets/shared-styles/general';

const reviewPhaseStyles = {
  border: '1px solid  transparent',
  borderRadius: '4px',
  margin: '24px 0',
  '&:last-child': {
    marginBottom: 0,
  },
};

const styles = {
  reviewPhaseCollapsed: {
    ...reviewPhaseStyles,
    border: '1px solid transparent',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.032)',
    },
  },
  reviewPhaseExpanded: {
    ...reviewPhaseStyles,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  reviewSummary: {
    padding: '14px 16px',
    cursor: 'pointer',
  },
  phaseName: {
    flexGrow: 1,
    marginLeft: '8px',
    color: black2,
    fontSize: '18px',
    fontWeight: heavy,
  },
  phaseApproved: {
    borderRadius: '14px',
    padding: '6px 12px',
    backgroundColor: evisortGreenDark,
  },
  horizontalSeparator: {
    width: '24px',
  },
  phasePending: {
    borderRadius: '14px',
    padding: '6px 12px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  reviewDetails: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '16px',
  },
  phaseSubsection: {
    '&:not(:last-child)': {
      marginBottom: '40px',
    },
  },
  subsectionTitle: {
    marginBottom: '12px',
    color: evisortBlue,
    fontSize: '12px',
    fontWeight: semibold,
  },
  criterionName: {
    marginBottom: '8px',
    color: black2,
    fontSize: '16px',
    fontWeight: bold,
  },
  criterionDescription: {
    marginBottom: '16px',
    maxWidth: 'fit-content',
    color: black3,
    fontSize: '14px',
    lineHeight: 1.5,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
};

export default styles;
