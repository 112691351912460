import PT from 'prop-types';
import React from 'react';
import { Box as ThemeBox } from 'theme-ui';

import { getClickableStyle } from '../../utils';

const Box = React.forwardRef((props, ref) => {
  const { disabled, sx = {}, onClick, ...rest } = props;
  const clickableStyle = getClickableStyle(props);
  return (
    <ThemeBox
      disabled={disabled}
      ref={ref}
      sx={{ ...clickableStyle, ...sx }}
      onClick={!disabled && onClick ? onClick : undefined}
      {...rest}
    />
  );
});

Box.propTypes = {
  /** Apply the specified HTML element string or React component for rendering  */
  as: PT.oneOfType([PT.string, PT.elementType]),
  /** Conveniently disables pointer events on the element.  Useful to disable user interaction */
  disabled: PT.bool,
  /** Element ID */
  id: PT.string,
  /** Forwards provided ref */
  // ref: PT.object,
  /** Supports sx styles */
  sx: PT.object,
};

export default Box;
