export default `
<svg
  aria-hidden="true"
  aria-label="chevron-up"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M5.49991 14.3339L11.4687 8.61511C11.6562 8.45886 11.8437 8.36511 12.0312 8.36511C12.2187 8.36511 12.3749 8.42761 12.5312 8.55261L18.4999 14.2714C18.8124 14.5839 18.8124 15.0526 18.5312 15.3339C18.2499 15.6464 17.7812 15.6464 17.4687 15.3651L12.0312 10.1776L6.53116 15.4276C6.24991 15.7089 5.74991 15.7089 5.46866 15.3964C5.18741 15.0839 5.18741 14.6151 5.49991 14.3339Z"
    fill="currentcolor"
  />
</svg>
`;
