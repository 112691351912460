import { union } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { ContentContainer, Layout, Text, useTableSelection } from '~/eds';
import { TableViewContextType } from '~/enums';
import { Filter } from '~/evifields';
import { CrossFilters } from '~/features/advanced-search/cross-filters';
import { Filters } from '~/features/advanced-search/filters';
import { QueryBuilder as QueryBuilderEnhancedUx } from '~/features/advanced-search/query-builder';
import { DEFAULT_CHAT_CONTEXT } from '~/features/ask-anything';
import { SearchFilters, useFieldsWithLazyLoad } from '~/features/filters';
import { useDocumentsBulkActionsPanel } from '~/features/search';
import { AddSearchResultsToGroup } from '~/features/search/AddSearchResultsToGroup';
import { ReadOnlyComplexQuery } from '~/features/search/QueryBuilder';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser, usePinnedFilters, useTableSettings } from '~/hooks';
import { api, selectors } from '~/redux';
import {
  Dashboard,
  generateDashboardToSave,
} from '~/redux/api/methods/dashboardV2';
import {
  buildComplexQueryWithCrossFilters,
  buildQuery,
  QueryGroup,
} from '~/redux/api/methods/searchV3';
import { chatbotSlice } from '~/redux/slices/chatbot';
import dashboardV2 from '~/redux/slices/dashboardV2';
import { PilotId, QueryOperatorEntity } from '~/types';
import {
  MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
  MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
} from '~/types/modal.types';
import { ERROR } from '~/types/toast.types';
import { capitalize } from '~/utils/strings';
import { DEFAULT_TABLE_SETTINGS } from '~/utils/table';
import { testIsAdmin } from '~/utils/user';

import { toFilter } from '../SearchV2';
import { getSearchableFields } from '../SearchV2/utils';
import { trackSegment } from '../SegmentAnalytics';
import BaseLoadView from '../Shared/BaseLoadView';
import BaseSaveView from '../Shared/BaseSaveView';
import SearchV3BooleanSearchBar from '../Shared/SearchV3BooleanSearchBar';
import DashboardV2Charts from './DashboardV2Charts';
import DashboardV2SearchResults from './DashboardV2SearchResults';
import { EditCharts } from './EditCharts';
import QueryBuilderPanel from './QueryBuilderPanel';
import SaveDashboard from './SaveDashboard';

type Props = {
  dashboard: Dashboard;
};

const DashboardV2 = ({ dashboard }: Props) => {
  const user = useCurrentUser();

  const {
    data: searchFilters,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFilters.useQuery(undefined);

  const searchFiltersList = useFieldsWithLazyLoad({
    filters: Object.values(searchFilters ?? {}),
    fieldValues: {},
  });

  const [
    fetchTableView,
    tableViewResults,
  ] = api.endpoints.getCurrentTableView.useLazyQuery();
  const {
    data: currentTableView,
    isFetching: isFetchingCurrentTableView,
  } = tableViewResults;

  const [isAddToGroupVisible, setIsAddToGroupVisible] = useState(false);
  const [isSaveViewVisible, setIsSaveViewVisible] = useState(false);
  const [isLoadViewVisible, setIsLoadViewVisible] = useState(false);

  // Pinned Filters
  const [localPinnedFilterIds, setLocalPinnedFilterIds] = useState<
    Array<PilotId | string>
  >([]);
  const {
    getPinnedFilters,
    result: {
      data: userPreferencesDefaultFilters,
      isFetching: isFetchingPinnedFilters,
    },
  } = usePinnedFilters(searchFilters);

  const [updatePinnedFilters] = api.endpoints.updatePinnedFilters.useMutation();

  const pinnedFiltersIds = useMemo(() => {
    return userPreferencesDefaultFilters?.map((filter) =>
      isNaN(Number(filter.fieldId)) ? filter.fieldId : Number(filter.fieldId),
    );
  }, [userPreferencesDefaultFilters]);

  const activeFilters = useSelector(selectors.selectDashboardActiveFilters);
  const crossFilters = useSelector(selectors.selectDashboardCrossFilters);

  const fieldValues = useSelector(selectors.selectDashboardFieldValues);
  const fieldsWithLazyLoad = useFieldsWithLazyLoad({
    filters: Object.values(searchFilters || {}),
    fieldValues,
  });
  const activeDashboard = useSelector(selectors.selectActiveDashboard);
  const page = useSelector(selectors.selectDashboardPage);
  const pageSize = useSelector(selectors.selectDashboardPageSize);
  const sortBy = useSelector(selectors.selectDashboardSortBy);
  const searchText = useSelector(selectors.selectDashboardSearchText);

  const queryBuilder = useSelector(selectors.selectQueryBuilder);
  const queryBuilderPanelVisible = useSelector(
    selectors.selectQueryBuilderPanelVisible,
  );
  const columns = useSelector(selectors.selectDashboardColumnOrder);

  const selectedFilters = useSelector(selectors.selectDashboardSelectedFilters);
  const chartFilters = useSelector(selectors.selectDashboardCharts);
  const intervals = useSelector(selectors.selectDashboardIntevals);
  const chartWidths = useSelector(selectors.selectDashboardChartWidths);
  const hasDataChanges = useSelector(selectors.selectDashboardDirty);

  const resultsCache = useSelector(selectors.selectDashboardResultsCache);
  const tableSelection = useTableSelection();
  const canSaveDashboard = testIsAdmin(user) || dashboard.creatorId === user.id;

  /** tracks if redux active dashboard is the same as the route dashboard. */
  const isCurrentDashboardActive = activeDashboard.id === dashboard.id;

  const isBulkActionOnDashboardEnabled = useFlag(
    FlagType.DashboardV2DocumentsBulkActions,
  );
  const hasPinnedFiltersV3 = useFlag(FlagType.PinnedFiltersV3);
  const hasTextDelimitedFilterView = useFlag(
    FlagType.TextDelimitedMultiselectFilter,
  );
  const hasNewSearchUI = useFlag(FlagType.SearchNewUI);
  const hasQueryBuilderEnhancedUx = useFlag(FlagType.QueryBuilderEnhancedUx);

  const query = useMemo(() => {
    if (queryBuilder && searchFilters) {
      if (crossFilters?.length) {
        return buildComplexQueryWithCrossFilters(
          queryBuilder,
          crossFilters,
          searchFilters,
        );
      } else {
        return queryBuilder;
      }
    } else if (activeFilters?.length || crossFilters?.length || searchFilters) {
      return buildQuery({
        booleanQuery: searchText,
        crossFilters,
        filters: activeFilters,
        searchFilters,
      });
    } else {
      return [];
    }
  }, [activeFilters, crossFilters, queryBuilder, searchFilters, searchText]);

  const queryToSave = useMemo(() => {
    if (queryBuilder && searchFilters) {
      return queryBuilder;
    } else if (activeFilters?.length || searchFilters) {
      return buildQuery({
        booleanQuery: searchText,
        crossFilters: [],
        filters: activeFilters,
        searchFilters,
      });
    } else {
      return [];
    }
  }, [activeFilters, crossFilters, queryBuilder, searchFilters, searchText]);

  const dashboardBooleanSearchText = dashboard.query.find(
    (q: any) => q.type === 'bool_text_search',
  )?.value;

  const dashboardData: Dashboard = useMemo(
    () => ({
      charts: chartFilters.map((item, index) => ({
        filter_id: item.id,
        sorted_index: index,
        interval: intervals[item.id],
        width: chartWidths[item.id] ? chartWidths[item.id] : undefined,
        type: item.type,
      })),
      columns: columns || [],
      filters: selectedFilters
        .filter((f) => !f.values.length && !f.operatorId)
        .map((f) => f.fieldId.toString()),
      id: dashboard.id,
      name: dashboard.name,
      entity: dashboard.entity,
      query,
      is_default: !!dashboard.is_default,
      creatorId: dashboard.creatorId,
    }),
    [dashboard, chartFilters, columns, selectedFilters, intervals, chartWidths],
  );

  const pinnedFilterIds = userPreferencesDefaultFilters?.map(
    (filter) => filter.fieldId,
  );

  const dashboardToSave = generateDashboardToSave(
    dashboardData,
    dashboard.id,
    queryToSave,
  );

  const dispatch = useDispatch();

  const onSearch = (search: string) => {
    dispatch(dashboardV2.actions.setSearchText(search));
    dispatch(dashboardV2.actions.setPage(1));
    dispatch(
      dashboardV2.actions.setDashboardDirty(
        dashboardBooleanSearchText !== search,
      ),
    );
    if (search) {
      trackSegment('searchKeywords', {
        name: search,
      });
    }
  };

  const hideQueryBuilderPanel = () => {
    dispatch(dashboardV2.actions.setQueryBuilderPanelVisible(false));
  };
  const {
    data: searchV3Data,
    isLoading: isLoadingSearchResults,
    isFetching: isFetchingSearchResults,
    isError: isErrorSearchDocuments,
    refetch: refetchSearch,
  } = api.endpoints.searchV3Documents.useQuery(
    {
      query,
      page,
      pageSize,
      sortBy,
      filters: columns?.filter((column) => column !== 'name'),
      store_as_recent_search: false,
    },
    {
      skip: !isCurrentDashboardActive,
    },
  );

  const initDashboard = async () => {
    if (!searchFilters) return;

    const isDefaultDocumentsDashboard =
      dashboard.is_default && dashboard.name === 'Documents';

    if (isDefaultDocumentsDashboard && hasNewSearchUI) {
      const finalPinnedFilters =
        pinnedFilterIds ??
        (await getPinnedFilters('SEARCH').unwrap())?.data ??
        [];
      const dashboardWithPinnedFilters = {
        ...dashboard,
        filters: union(dashboard.filters, finalPinnedFilters),
      };

      dispatch(
        dashboardV2.actions.initDashboard({
          dashboard: dashboardWithPinnedFilters,
          searchFilters,
          tableSettings,
        }),
      );
    } else {
      dispatch(
        dashboardV2.actions.initDashboard({
          dashboard,
          searchFilters,
          tableSettings,
        }),
      );
    }
  };

  useEffect(() => {
    if (!!isErrorSearchDocuments) {
      showToast(ERROR, 'An error occurred while searching for documents.');
    }
  }, [isErrorSearchDocuments]);

  const {
    tableSettings = {
      ...DEFAULT_TABLE_SETTINGS,
      columnOrder: dashboard.columns || [],
    },
  } = useTableSettings(`DASHBOARD:${dashboard.id}`);
  useEffect(() => {
    initDashboard();
  }, [searchFilters, dashboard]);

  const isLoading =
    isFetchingFilters ||
    isLoadingSearchResults ||
    isFetchingPinnedFilters ||
    isFetchingCurrentTableView;

  useEffect(() => {
    if (hasPinnedFiltersV3) {
      getPinnedFilters('SEARCH');
    }
  }, [hasPinnedFiltersV3]);

  useEffect(() => {
    if (selectedFilters?.length) {
      dispatch(dashboardV2.actions.setPersistedFilters(selectedFilters));
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (pinnedFiltersIds && pinnedFiltersIds.length) {
      setLocalPinnedFilterIds(pinnedFiltersIds ?? []);
    }
  }, [pinnedFiltersIds]);

  const removeCrossFilters = () => {
    trackSegment('clearFilters', {
      user_id: user.id,
      client_id: user.client,
    });
    dispatch(dashboardV2.actions.setCrossFilters([]));
  };

  const openQueryBuilderPanel = () => {
    dispatch(dashboardV2.actions.setQueryBuilderPanelVisible(true));
  };

  const onFilterChange = (filters: Filter[]) => {
    dispatch(dashboardV2.actions.setSelectedFilters(filters));
    dispatch(dashboardV2.actions.setPage(1));
    dispatch(dashboardV2.actions.setDashboardDirty(true));
  };

  const onCrossFiltersChange = (filters: Filter[]) => {
    dispatch(dashboardV2.actions.setCrossFilters(filters));
  };

  const onFiltersReset = () => {
    if (searchFilters) {
      dispatch(dashboardV2.actions.setSelectedFilters([]));
      dispatch(dashboardV2.actions.setPersistedFilters([]));
      dispatch(dashboardV2.actions.setCrossFilters([]));
      dispatch(dashboardV2.actions.setPage(1));
      initDashboard();
    }
  };

  const resetSearchResultSelection = () => {
    tableSelection.clear();
  };

  const handleUpdatePinnedFilters = async (
    updatedPinnedFilters: Array<PilotId | string>,
  ) => {
    setLocalPinnedFilterIds(updatedPinnedFilters);
    await updatePinnedFilters({
      context: 'SEARCH',
      pinnedFilters: updatedPinnedFilters as string[],
    });
    getPinnedFilters('SEARCH');
  };

  const handleAddPinFilter = (fieldId: PilotId | string) => {
    dispatch(
      dashboardV2.actions.setSelectedFilters([
        ...selectedFilters,
        toFilter(fieldId as string),
      ]),
    );
  };

  // Viewset
  const showSaveView = useCallback(() => {
    setIsSaveViewVisible(true);
  }, []);

  const showLoadView = useCallback(() => {
    setIsLoadViewVisible(true);
  }, []);

  useEffect(() => {
    resetSearchResultSelection();
  }, [query]);

  const refreshSearchResult = useCallback(() => {
    resetSearchResultSelection();
    refetchSearch();
  }, []);

  useEffect(() => {
    if (searchV3Data?.results) {
      if (searchV3Data.results.length > 0) {
        dispatch(
          chatbotSlice.actions.setContext([
            DEFAULT_CHAT_CONTEXT,
            {
              label: 'All Results',
              query: query,
              title: `All Results (${searchV3Data.meta.total})`,
              icon: 'search',
              primary: true,
              searchEntity: 'document',
              selectedIds: [],
              id: dashboard.id,
            },
          ]),
        );
      }

      dispatch(
        dashboardV2.actions.patchSearchResultsCache(searchV3Data.results),
      );
    }

    return () => {
      dispatch(chatbotSlice.actions.setContext([DEFAULT_CHAT_CONTEXT]));
    };
  }, [searchV3Data]);

  const {
    panel,
    limitExceededModal,
    setActivePanel,
  } = useDocumentsBulkActionsPanel({
    selectedDocumentIds: tableSelection?.selectedIds,
    isAllSelected: tableSelection?.isAllSelected,
    totalCount: searchV3Data?.meta?.total ?? 0,
    query: query,
    searchAPI: 'v3',
    onActionCompleted: refreshSearchResult,
    resultsCache: resultsCache,
  });

  const handleMove = useCallback(() => {
    setActivePanel('move');
  }, []);

  const handleCopy = useCallback(() => {
    setActivePanel('copy');
  }, []);

  const handleEdit = useCallback(() => {
    setActivePanel('edit');
  }, []);

  const handleEditClauses = useCallback(() => {
    setActivePanel('edit-clauses');
  }, []);

  const handleDelete = useCallback(() => {
    setActivePanel('delete');
  }, []);

  const handleSuccessAddToGroup = useCallback(() => {
    refreshSearchResult();
    setIsAddToGroupVisible(false);
  }, []);

  const handleSearchQueryBuilder = (
    groupsEntity: (QueryGroup | QueryOperatorEntity)[],
  ) => {
    const queryBuilderPayload = buildQuery({
      booleanQuery: '',
      filters: [],
      searchFilters,
      queryBuilder: groupsEntity,
    });
    dispatch(dashboardV2.actions.setQueryBuilder(queryBuilderPayload));
    dispatch(dashboardV2.actions.setPage(1));
    dispatch(dashboardV2.actions.setDashboardDirty(true));
    dispatch(dashboardV2.actions.setQueryBuilderPanelVisible(false));
    dispatch(dashboardV2.actions.setSearchText(''));
  };

  const onSaveSuccess = () => {
    dispatch(dashboardV2.actions.setDashboardDirty(false));
  };

  const loadingContent = {
    isLoading: !isCurrentDashboardActive,
    message: 'Loading Dashboard...',
  };
  const loadingTableContent = { isLoading };

  return (
    <ContentContainer loadingContent={loadingContent}>
      <>
        <Layout align="center" justify="space-between" spacing={8}>
          <Text as="h1" m={0} variant="title">
            {capitalize(dashboard.name)}
          </Text>
          <Layout align="center" spacing={4}>
            <EditCharts />
          </Layout>
        </Layout>

        <>
          <Layout align="flex-start" w="100%" spacing={2}>
            <Layout direction="column" flex={1}>
              <SearchV3BooleanSearchBar
                value={searchText}
                onSubmit={onSearch}
                isSubmitting={isLoading}
              />
            </Layout>
            <SaveDashboard
              dashboard={dashboardToSave}
              isDashboardDirty={hasDataChanges}
              hasSavePermission={canSaveDashboard}
              onSaveSucess={onSaveSuccess}
            />
          </Layout>
          {queryBuilder ? (
            <>
              <ReadOnlyComplexQuery
                searchFilters={searchFilters || {}}
                onClear={() => {
                  dispatch(dashboardV2.actions.setQueryBuilder(undefined));
                  dispatch(
                    dashboardV2.actions.setQueryBuilderPanelVisible(false),
                  );
                }}
                onEdit={() => {
                  dispatch(
                    dashboardV2.actions.setQueryBuilderPanelVisible(true),
                  );
                }}
                queryBuilder={queryBuilder}
              />
              <Layout align="center" spacing={2} role="group">
                <CrossFilters
                  searchFilters={fieldsWithLazyLoad}
                  filters={crossFilters}
                  onChange={onCrossFiltersChange}
                />
              </Layout>
            </>
          ) : (
            <>
              {hasNewSearchUI ? (
                <Filters
                  selectedFilters={selectedFilters}
                  searchFilters={searchFiltersList}
                  pinnedFiltersIds={
                    hasPinnedFiltersV3 ? localPinnedFilterIds : undefined
                  }
                  crossFilters={crossFilters}
                  onChange={onFilterChange}
                  onQueryButtonClick={openQueryBuilderPanel}
                  onFiltersReset={onFiltersReset}
                  onChangeCrossFilters={onCrossFiltersChange}
                  onUpdatePinnedFilters={
                    hasPinnedFiltersV3 ? handleUpdatePinnedFilters : undefined
                  }
                />
              ) : (
                <SearchFilters
                  enableFilterViews={hasTextDelimitedFilterView}
                  isLoading={isFetchingFilters}
                  filterCount={
                    dashboard.query.length > 0
                      ? dashboard.query.length
                      : dashboard.filters.length
                  }
                  filters={selectedFilters}
                  crossFilters={crossFilters}
                  pinnedFiltersIds={
                    hasPinnedFiltersV3 ? localPinnedFilterIds : undefined
                  }
                  onClearFilters={removeCrossFilters}
                  onCrossFiltersChange={onCrossFiltersChange}
                  onFilterChange={onFilterChange}
                  onQueryButtonClick={openQueryBuilderPanel}
                  onUpdatePinnedFilters={
                    hasPinnedFiltersV3 ? handleUpdatePinnedFilters : undefined
                  }
                  onClickPinnedFilter={handleAddPinFilter}
                />
              )}
            </>
          )}
          <DashboardV2Charts
            charts={chartFilters}
            queryParams={{
              booleanQuery: searchText,
              filters: activeFilters,
              crossFilters,
              searchFilters,
              queryBuilder,
            }}
          />
          {isBulkActionOnDashboardEnabled && (
            <>
              {limitExceededModal}
              {panel}
            </>
          )}
          {!isErrorSearchDocuments && (
            <ContentContainer loadingContent={loadingTableContent}>
              <DashboardV2SearchResults
                currentTableView={currentTableView}
                dashboard={dashboard}
                searchFilters={searchFilters || {}}
                isLoading={isFetchingSearchResults}
                data={searchV3Data}
                query={query}
                tableSelection={tableSelection}
                resultsCache={resultsCache}
                pageSize={tableSettings?.pageSize}
                onMove={handleMove}
                onCopy={handleCopy}
                onEdit={handleEdit}
                onEditClauses={handleEditClauses}
                onDelete={handleDelete}
                onAddToGroup={() => setIsAddToGroupVisible(true)}
                onLoadView={showLoadView}
                onSaveView={showSaveView}
              />
            </ContentContainer>
          )}
          <>
            {hasQueryBuilderEnhancedUx ? (
              <>
                {queryBuilderPanelVisible && (
                  <QueryBuilderEnhancedUx
                    value={queryBuilder}
                    searchFilters={searchFiltersList}
                    onHide={hideQueryBuilderPanel}
                    onSubmit={handleSearchQueryBuilder}
                    pinnedFiltersIds={
                      hasPinnedFiltersV3 ? localPinnedFilterIds : undefined
                    }
                  />
                )}
              </>
            ) : (
              <QueryBuilderPanel
                visible={!!queryBuilderPanelVisible}
                onHide={hideQueryBuilderPanel}
              />
            )}
          </>

          {isAddToGroupVisible && (
            <AddSearchResultsToGroup
              query={query}
              tableSelection={tableSelection}
              onHide={() => setIsAddToGroupVisible(false)}
              onCompleted={handleSuccessAddToGroup}
            />
          )}

          {isSaveViewVisible && (
            <BaseSaveView
              context={TableViewContextType.SEARCH}
              fields={getSearchableFields(columns)}
              hideModal={() => setIsSaveViewVisible(false)}
              modalType={MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE}
            />
          )}

          {isLoadViewVisible && (
            <BaseLoadView
              context={TableViewContextType.SEARCH}
              modalType={MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH}
              hideModal={() => setIsLoadViewVisible(false)}
              onViewSelected={() => {
                fetchTableView({ context: TableViewContextType.SEARCH });
              }}
            />
          )}
        </>
      </>
    </ContentContainer>
  );
};

export default DashboardV2;
