import { BulkImportUserToogleActionName } from '~/enums';

const usersUpdateWarningTitle =
  'Are you sure you want to update your user list?';
const usersUpdateWarningMessage = `By enabling these options, your user list will be updated according to the
options. This action is permanent and cannot be undone. Are you sure you want to continue?`;

const usersDeleteWarningTitle = 'Permanently delete missing users?';
const usersDeleteWarningMessage = `By enabling this option, all users currently in the platform, but not in the uploaded
spreadsheet will be removed. This action is permanent and cannot be undone.`;

const usersUpdateAndDeleteWarningTitle =
  'Permanently delete missing users and update existing users?';
const usersUpdateAndDeleteWarningMessage = `By enabling these options, all users currently in the platform, but not in the
uploaded spreadsheet will be removed. Users will also be updated with the newest
list imported. This action is permanent and cannot be undone.`;

const confirmationTextToDeleteUsers = 'DELETE';
const warningMessageToDeleteUsers = `Please type "${confirmationTextToDeleteUsers}" to confirm you want to proceed`;

type BulkImportUserAction = Record<BulkImportUserToogleActionName, boolean>;

export const warningModalBasePropsMap = {
  updateUsers: {
    actionButtonText: 'Continue to Import',
    actionButtonVariant: 'primary',
    warningMessage: usersUpdateWarningMessage,
    warningTitle: usersUpdateWarningTitle,
    textToConfirm: false,
  },
  deleteUsers: {
    actionButtonText: 'Delete Permanently',
    actionButtonVariant: 'red',
    warningMessage: usersDeleteWarningMessage,
    warningTitle: usersDeleteWarningTitle,
    requireConfirm: true,
    warningMessageToConfirm: warningMessageToDeleteUsers,
    textToConfirm: confirmationTextToDeleteUsers,
  },
  deleteAndUpdateUsers: {
    actionButtonText: 'Delete Permanently',
    actionButtonVariant: 'red',
    warningMessage: usersUpdateAndDeleteWarningMessage,
    warningTitle: usersUpdateAndDeleteWarningTitle,
    requireConfirm: true,
    warningMessageToConfirm: warningMessageToDeleteUsers,
    textToConfirm: confirmationTextToDeleteUsers,
  },
};

export const getBaseWarningModalProps = (
  importActions: BulkImportUserAction,
) => {
  const { deleteMissingUsers, updateExistingUsers } = importActions;
  if (deleteMissingUsers && updateExistingUsers) {
    return warningModalBasePropsMap.deleteAndUpdateUsers;
  }
  if (deleteMissingUsers) {
    return warningModalBasePropsMap.deleteUsers;
  }

  return warningModalBasePropsMap.updateUsers;
};
