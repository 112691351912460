export default `
<svg
  aria-hidden="true"
  aria-label="edit"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M5.8409 14.1L15.3409 4.59998C16.1222 3.81873 17.4034 3.81873 18.1847 4.59998L19.4034 5.81873C19.4972 5.91248 19.5909 6.03748 19.6534 6.13123C20.1847 6.91248 20.0909 7.97498 19.4034 8.66248L9.9034 18.1625C9.87215 18.1937 9.80965 18.225 9.7784 18.2875C9.4659 18.5375 9.12215 18.725 8.74715 18.85L4.9659 19.9437C4.7159 20.0375 4.43465 19.975 4.24715 19.7562C4.0284 19.5687 3.9659 19.2875 4.0284 19.0375L5.1534 15.2562C5.2784 14.8187 5.5284 14.4125 5.8409 14.1ZM6.5909 15.6937L5.87215 18.1312L8.30965 17.4125C8.49715 17.35 8.68465 17.2562 8.8409 17.1L15.9972 9.94373L14.0284 8.00623L6.9034 15.1625C6.87215 15.1625 6.87215 15.1937 6.8409 15.225C6.7159 15.35 6.6534 15.5062 6.5909 15.6937Z"
    fill="currentcolor"
  />
</svg>
`;
