import { DataField, DataFieldType } from '~/features/fields';
import { pilot } from '~/services';
import { PilotId } from '~/types';

interface Request {
  ids?: PilotId[];
  isSmart?: boolean;
  limit?: number;
  search?: string;
}

interface Response {
  count: number;
  next: string;
  results: {
    id: PilotId;
    label: string;
    type: DataFieldType;
    is_smart: boolean;
    help_text: string;
  }[];
}

export const getFields = async ({
  ids,
  isSmart,
  limit,
  search,
}: Request): Promise<{
  count: number;
  results: DataField[];
}> => {
  const response: Response = await pilot.get('/field/', {
    params: {
      ids: ids?.join(','),
      is_smart: isSmart,
      limit,
      search,
    },
  });

  return {
    count: response.count,
    results: response.results.map((field) => ({
      id: field.id,
      helpText: field.help_text,
      isSmart: field.is_smart,
      label: field.label,
      type: field.type,
    })),
  };
};
