import { PromptModelTestCase } from '../types';

// Filters newTestCases that are rated, and also not in the oldTestCases (keyed on entity.id)
export const getNewRatedTestCases = (
  oldTestCases: PromptModelTestCase[],
  newTestCases: PromptModelTestCase[],
) => {
  const testIsTestCaseRated = ({ outcome, goldValue }: PromptModelTestCase) =>
    outcome === 'correct' ||
    (goldValue && (outcome === 'partially_correct' || outcome === 'incorrect'));

  // The unique key for a test case is composite on entity.id + entity.type
  const keyTestCase = ({ entity }: PromptModelTestCase) =>
    `${entity.type}-${entity.id}`;

  const oldRatedTestCasesMap = Object.fromEntries(
    oldTestCases
      .filter(testIsTestCaseRated)
      .map((testCase) => [keyTestCase(testCase), testCase]),
  );

  return newTestCases.filter(
    (testCase) =>
      testIsTestCaseRated(testCase) &&
      !oldRatedTestCasesMap[keyTestCase(testCase)],
  );
};

export const testIsTestCaseEvaluating = ({ state }: PromptModelTestCase) =>
  state === 'not_started' || state === 'queued' || state === 'running';
