import React from 'react';
import { connect } from 'react-redux';

import {
  workflowReviewAddApprover,
  workflowSetReviewPhaseDescription,
} from '~/actions';
import { Button } from '~/eds';
import { getReviewPhaseById } from '~/reducers/workflow';
import { Box } from '~/ui';
import { toSnakeTrimmed } from '~/utils/strings';
import { coerceName } from '~/utils/workflow';

import DescriptionTextAreaForm from '../../shared/DescriptionTextAreaForm';
import PhaseItem from './PhaseItem';

function Phase({
  phaseId,
  position,
  // connected
  disabled,
  phase,
  workflowSetReviewPhaseDescription,
  workflowReviewAddApprover,
}) {
  const coercedPhaseName = coerceName(phase.name, 'Review Phase', position);
  const phaseName = toSnakeTrimmed(coercedPhaseName);

  return (
    <Box disabled={disabled}>
      <Box pb={2}>
        <DescriptionTextAreaForm
          description={phase.description}
          disabled={disabled}
          id={`workflow--review-phase-name=${phaseName}-add-description`}
          onUpdate={(value) =>
            workflowSetReviewPhaseDescription({ value, phaseId })
          }
        />
      </Box>

      {phase.approvals.map((approval, index) => {
        return (
          <PhaseItem
            index={index}
            canReorder={phase.approvals.length > 1}
            phaseId={phase.id}
            approval={approval}
            key={approval.id}
          />
        );
      })}

      <Box mt={4}>
        <Button
          id={`workflow--review-phase-name=${phaseName}-add-review`}
          text="Add Review"
          onClick={() => workflowReviewAddApprover(phaseId)}
        />
      </Box>
    </Box>
  );
}

function mapStateToProps({ builder, workflow }, { phaseId }) {
  return {
    disabled: !builder.shouldEnableAllComponents,
    phase: getReviewPhaseById(workflow, phaseId),
  };
}

export default connect(mapStateToProps, {
  workflowSetReviewPhaseDescription,
  workflowReviewAddApprover,
})(Phase);
