import { useDispatch, useSelector } from 'react-redux';

import { Layout, Panel, StatusMessage } from '~/eds';
import { slices } from '~/redux';

import { mapAlgoStatusMessageProps } from './utils';

export const AlgoStatusPanel = () => {
  const dispatch = useDispatch();
  const { activeFile } = useSelector(slices.upload.selectors.selectActiveFile);

  if (!activeFile) {
    return null;
  }

  return (
    <Panel
      hidden={{
        placement: 'left',
        onHide: () => {
          dispatch(slices.upload.actions.resetFileConfiguration());
        },
      }}
      position="right"
      title="AlgoStatus"
    >
      <Layout direction="column" spacing={2}>
        {activeFile.algoStatuses.map((algoStatus) => (
          <StatusMessage
            key={algoStatus.status}
            {...mapAlgoStatusMessageProps(algoStatus)}
          />
        ))}
      </Layout>
    </Panel>
  );
};
