import { createSelector } from '@reduxjs/toolkit';

import { DocumentsSlice, name } from '../slices/documents';

const documentsSlice = (rootState: any): DocumentsSlice => rootState[name];

export const selectCurrentTableView = createSelector(
  documentsSlice,
  (state) => state.currentTableView,
);

export const selectCurrentAddToGroupStep = createSelector(
  documentsSlice,
  (state) => state.currentAddToGroupStep,
);

export const selectSelectedDocumentId = createSelector(
  documentsSlice,
  (state) => state.selectedDocumentId,
);

export const selectSelectedGroupId = createSelector(
  documentsSlice,
  (state) => state.selectedGroupId,
);
