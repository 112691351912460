import pluralize from 'pluralize';
import PT from 'prop-types';
import React from 'react';

import { enums, Text } from '~/ui';

export default function CharacterLimit({
  limit = enums.CHARACTER_INPUT_LIMIT_LONG,
  value = '',
}) {
  const characters = typeof value === 'string' ? value.length : 0;
  const difference = characters - limit;
  const differenceString = pluralize('character', Math.abs(difference), true);

  let message = `${characters}/${limit} characters`;
  let color = 'gray-700';

  if (difference > 0) {
    color = 'red-600';
    message = `You have exceeded the character limit (${limit}) by ${differenceString}`;
  } else if (characters >= limit * 0.9) {
    color = 'peach-500';
    message = `${differenceString} left`;
  }

  return (
    <Text mt={2} color={color} variant="2xs-dense">
      {message}
    </Text>
  );
}

CharacterLimit.propTypes = {
  /** character limit before a warning a thrown */
  limit: PT.number,
  /** string value */
  value: PT.string,
};
