import { capitalize } from '~/utils/strings';

import { TargetEntityDetails } from '../types';

export const resolveModalProps = (
  modalAction: 'delete' | 'suspend',
  entityDetails: TargetEntityDetails,
) => {
  switch (modalAction) {
    case 'delete':
      return {
        description: `Please confirm that you would like to delete ${capitalize(
          entityDetails.type,
        )} Model “**${
          entityDetails.label
        }**”.\n\nThis will not delete all values previously found by the model, but will remove this model and stop it from running on any new documents that fit within the model scope. This action cannot be undone.`,
        variant: 'danger' as const,
        toastMessage: `Deleted **${entityDetails.label}**`,
      };
    case 'suspend': {
      return {
        description: `Confirm that you would like to suspend ${capitalize(
          entityDetails.type,
        )} Model **“${entityDetails.label}”**.\nThis will suspend the ${
          entityDetails.type
        } identifications run by the ${capitalize(
          entityDetails.type,
        )} Model.\nYou can resume the ${capitalize(
          entityDetails.type,
        )} Model back to the published state after suspending.`,
        variant: 'primary' as const,
        toastMessage: `Suspended **${entityDetails.label}**`,
      };
    }
  }
};
