import PropTypes from 'prop-types';
import React from 'react';

import { black3 } from '../../../assets/shared-styles/general';

const DocumentOutlineIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          fill={props.color}
          fillOpacity={props.opacity}
          d="M11.667 1.667H5c-.917 0-1.658.75-1.658 1.666l-.009 13.334c0 .916.742 1.666 1.659 1.666H15c.917 0 1.667-.75 1.667-1.666v-10l-5-5zM5 16.667V3.333h5.833V7.5H15v9.167H5z"
        />
      </g>
    </svg>
  );
};

DocumentOutlineIcon.defaultProps = {
  size: '24',
  color: black3,
  opacity: '1',
};

DocumentOutlineIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
};

export default DocumentOutlineIcon;
