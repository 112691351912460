import { types } from '~/eds';
import { BulkEditFieldType } from '~/features/document-bulk-actions';
import { pilot } from '~/services';

type Field = {
  id: types.PilotId;
  keepOldValues: boolean;
  value: string | string[] | number;
};

interface Request {
  type: BulkEditFieldType;
  documentIds?: types.PilotId[];
  searchQuery?: object;
  searchAPI: 'v2' | 'v3';
  isAllSelected?: boolean;
  /** Used when type is 'delete' */
  fieldIds?: types.PilotId[];
  /** Used when type is 'add/replace' */
  fieldUpdates?: Field[];
}

type Params = {
  type: BulkEditFieldType;
  document_ids?: types.PilotId[];
  query?: object;
  field_ids?: types.PilotId[];
  field_updates?: {
    id: types.PilotId;
    value: string | string[] | number;
    keep_old_values: boolean;
  }[];
};
export const documentBulkEditFields = (
  request: Request,
): Promise<{ detail: string }> =>
  pilot.post('document/bulk-edit-field/', mapRequestToParams(request));

const mapRequestToParams = ({
  type,
  documentIds,
  fieldIds,
  fieldUpdates,
  searchQuery,
  searchAPI,
  isAllSelected,
}: Request): Params => ({
  document_ids: !isAllSelected && documentIds ? documentIds : [],
  field_ids: fieldIds,
  field_updates: fieldUpdates?.map(({ id, keepOldValues, value }) => ({
    id,
    value,
    keep_old_values: keepOldValues,
  })),
  ...(isAllSelected
    ? searchAPI === 'v3'
      ? { query_v3: searchQuery }
      : { query: searchQuery }
    : undefined),
  type,
});
