import uuid from 'uuid';

import { sleep } from '~/api/mocks/utils';
import { CreditTransaction } from '~/features/billing';

// TODO: Remove this endpoint as we will be updating credit endpoitn to get this data.
export const getCreditLedger = async (): Promise<{
  transactions: CreditTransaction[];
  totalCount: number;
}> => {
  const transactions = Array.from({ length: 10 }).map(() => ({
    credits: Math.round(Math.random() * 100),
    creditType: 'Added',
    createdBy: 'System',
    effectiveDate: new Date(),
    eventDate: new Date(),
    expirationDate: new Date(),
    id: uuid.v4(),
    notes: 'Added credits',
    renewalDate: new Date(),
  }));

  await sleep(4000);

  return { transactions, totalCount: transactions.length };
};
