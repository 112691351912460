import { getYear } from 'date-fns';
import React from 'react';

import { floorYear, YEAR_PAGE_SIZE } from './utils';
import { YearMonth } from './YearMonth';

interface Props {
  displayDate: Date;
  onSelectYear: (year: number) => void;
}

export const Years = ({ displayDate, onSelectYear }: Props) => {
  const currentYear = getYear(displayDate);

  const years = Array.from({ length: YEAR_PAGE_SIZE }).map(
    (_, i) => floorYear(currentYear) + i,
  );

  return (
    <>
      {years.map((year) => (
        <YearMonth
          key={year}
          isActive={currentYear === year}
          text={year.toString()}
          onClick={() => onSelectYear(year)}
        />
      ))}
    </>
  );
};
