import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import EcTooltip from '../../EcTooltip';
import ChevronLeftIcon from '../../Icons/ChevronLeftIcon';
import ChevronRightIcon from '../../Icons/ChevronRightIcon';
import styles from '../withSideCollapse/withSideCollapse.styles';

function withSideCollapse(WrappedComponent) {
  class WithSideCollapse extends Component {
    constructor(props) {
      super(props);

      this.handleOnCollapseToggle = this.handleOnCollapseToggle.bind(this);
      this.state = { collapsed: false };
    }

    handleOnCollapseToggle() {
      this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
    }

    renderTooltip(tooltipId, tooltipMessage) {
      const { collapseToRight } = this.props;
      const tooltipDirection = collapseToRight ? 'left' : 'right';

      return (
        <EcTooltip id={tooltipId} place={tooltipDirection}>
          {tooltipMessage}
        </EcTooltip>
      );
    }

    render() {
      const {
        classes,
        collapseToRight,
        tooltipExpand,
        tooltipCollapse,
      } = this.props;
      const { collapsed } = this.state;

      const ChevronOpenIcon = collapseToRight
        ? ChevronLeftIcon
        : ChevronRightIcon;
      const ChevronCloseIcon = collapseToRight
        ? ChevronRightIcon
        : ChevronLeftIcon;

      return (
        <div className={classes.collapseWrapper}>
          {collapseToRight ? null : (
            <WrappedComponent {...this.props} collapsed={collapsed} />
          )}
          {collapsed ? (
            <button
              className={classes.showComponent}
              onClick={this.handleOnCollapseToggle}
              data-tip
              data-for="sidebar_expand"
              aria-label="sidebar expand"
            >
              <ChevronOpenIcon opacity="0.5" />
              {this.renderTooltip('sidebar_expand', tooltipExpand)}
            </button>
          ) : (
            <button
              className={classes.hideComponent}
              onClick={this.handleOnCollapseToggle}
              data-tip
              data-for="sidebar_collapse"
              aria-label="sidebar collapse"
            >
              <div className={classes.chevronIconWrapper}>
                <ChevronCloseIcon opacity="0.25" />
                {this.renderTooltip('sidebar_collapse', tooltipCollapse)}
              </div>
            </button>
          )}
          {collapseToRight ? (
            <WrappedComponent {...this.props} collapsed={collapsed} />
          ) : null}
        </div>
      );
    }
  }

  WithSideCollapse.defaultProps = {
    collapseToRight: false,
    tooltipExpand: 'Show Sidebar',
    tooltipCollapse: 'Hide Sidebar',
  };

  WithSideCollapse.propTypes = {
    collapseToRight: PropTypes.bool,
    tooltipExpand: PropTypes.string,
    tooltipCollapse: PropTypes.string,
  };

  return injectSheet(styles)(WithSideCollapse);
}

export default withSideCollapse;
