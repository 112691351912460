import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useRef } from 'react';
import injectSheet from 'react-jss';

import { black4 } from '../../../assets/shared-styles/general';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import styles from './EcDepartmentTreeItem.styles';

function EcDepartmentTreeItem(props) {
  const {
    classes,
    department,
    departmentsSelected,
    focusId,
    getIsSelected,
    onSelect,
    setIsSelected,
  } = props;

  const itemHeaderClass = department.children.length
    ? classes.itemHeader
    : classes.emptyItemHeader;

  const treeItem = useRef();

  useEffect(() => {
    if (focusId === department.id) {
      treeItem.current.focus();
    }
  }, [department, focusId]);

  useEffect(() => {
    const isSelected = !!departmentsSelected
      ?.map((d) => d.department_id)
      .includes(department.id);

    setIsSelected(department.id, isSelected);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  function renderSubtree() {
    if (department.isExpanded) {
      return (
        <div className={classes.itemBody}>
          {department.children.map((child) => {
            return (
              <EcDepartmentTreeItem
                {...props}
                key={child.id}
                department={child}
                highLight={classes.selectedDepartment}
              />
            );
          })}
        </div>
      );
    }
    return null;
  }

  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [__unused__, forceUpdate] = useReducer((x) => x + 1, 0); //eslint-disable-line no-unused-vars

  const handleKeyDown = (event) => {
    props?.onTreeNodeKeyDown(event, department); //eslint-disable-line no-unused-expressions

    forceUpdate(); //TODO: remove forceUpdate, requires making TreeNode a component/redux state so React is aware of its state change
  };

  const handleOnExpandClick = () => {
    props.department.isExpanded = !props.department.isExpanded;

    forceUpdate();
  };

  const isSelected = getIsSelected(department.id);

  return (
    <ul className={classes.departmentTreeItem}>
      <li
        className={!department.name ? classes.itemHidden : itemHeaderClass}
        onClick={() => onSelect(department)}
        onKeyDown={handleKeyDown}
        ref={treeItem}
        role="menuitem"
        tabIndex={0}
      >
        {department.children && department.children.length ? (
          department.isExpanded ? (
            <ChevronDownIcon
              size="20"
              onClick={handleOnExpandClick}
              color={black4}
            />
          ) : (
            <ChevronRightIcon
              size="20"
              onClick={handleOnExpandClick}
              color={black4}
            />
          )
        ) : null}

        <span
          role="menu"
          tabIndex={0}
          className={isSelected ? classes.selectedItemName : classes.itemName}
        >
          {department.name}
        </span>
      </li>
      {renderSubtree()}
    </ul>
  );
}

EcDepartmentTreeItem.propTypes = {
  classes: PropTypes.object.isRequired,
  department: PropTypes.object,
  departmentSelected: PropTypes.object,
  onSelect: PropTypes.func,
};

export default injectSheet(styles)(EcDepartmentTreeItem);
