import { evisortYellow, semibold } from '../../../assets/shared-styles/general';

const fieldTokenControl = {
  cursor: 'pointer',
  '&:last-child': {
    marginLeft: '5px',
  },
};

const documentContentSideInfoBase = {
  height: '100%',
  flexShrink: 0,
  width: '320px',
  overflowY: 'scroll',
  marginLeft: '24px',
  '& > div': {
    height: 'auto',
  },
};

const styles = {
  documentSearchBar: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      margin: '0 8px',
    },
  },
  documentContentsWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    '& img': {
      maxWidth: '100%',
    },
  },
  documentViewerWrapper: {
    position: 'relative',
    flexGrow: 1,
    width: 'calc(100% - 280px)',
    minWidth: '500px',
  },
  documentContentSideInfo: {
    ...documentContentSideInfoBase,
    '& > div:not(:last-child)': {
      marginBottom: '24px',
    },
  },
  documentContentSideInfoIn2Columns: {
    ...documentContentSideInfoBase,
    display: 'flex',
  },
  fabWrapper: {
    top: '100px',
    position: 'absolute',
    transform: 'translate(-50%)',
    zIndex: '5',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '64px 0',
    textAlign: 'center',
  },
  fieldTokenWrapper: {
    padding: '0 10px 0 20px',
    display: 'flex',
    alignItems: 'center',
    height: '34px',
    top: '80px',
    right: '-7%',
    backgroundColor: evisortYellow,
    borderRadius: '26px',
    boxShadow: '0 2px 4px 0 rgba(17, 17, 17, 0.15)',
    position: 'absolute',
    transform: 'translate(-50%)',
    zIndex: '5',
  },
  fieldTokenName: {
    marginRight: '10px',
    display: 'inline-flex',
    fontSize: '12px',
    fontWeight: semibold,
    textTransform: 'uppercase',
  },
  fieldTokenControlButtons: {
    display: 'inline-flex',
  },
  fieldTokenControlEnabled: {
    ...fieldTokenControl,
  },
  fieldTokenControlDisabled: {
    ...fieldTokenControl,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    opacity: 0.3,
  },
  closeFieldTokenHightlightButtons: {
    position: 'absolute',
    top: '-9px',
    right: '-9px',
    width: '25px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#000',
    borderRadius: '25px',
    border: '1px solid #fff',
    cursor: 'pointer',
  },
};

export default styles;
