import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import EcCheckbox from '../../Shared/EcCheckbox';
import styles from './EcMemberItem.styles';

class EcMemberItem extends Component {
  constructor(props) {
    super(props);

    this.handleMemberCheck = this.handleMemberCheck.bind(this);

    this.state = {
      memberChecked: false,
    };
  }

  handleMemberCheck() {
    const { item, handleMemberCheck } = this.props;
    const { memberChecked } = this.state;

    handleMemberCheck(item.id, !memberChecked);
    this.setState({ memberChecked: !memberChecked });
  }

  render() {
    const { classes, item, isForUnassignedMembers } = this.props;
    const { memberChecked } = this.state;

    const checkboxId =
      'EcMemberItem/checkbox-' + item.first_name + item.last_name;

    return (
      <div className={classes.itemRow}>
        {!isForUnassignedMembers ? (
          <div className={classes.checkboxContainer}>
            <EcCheckbox
              checked={memberChecked}
              id={checkboxId}
              onClick={this.handleMemberCheck}
            />
            <label className={classes.checkboxLabel} htmlFor={checkboxId}>
              {`${item.full_name}`}
            </label>
          </div>
        ) : (
          <div>{`${item.full_name}`}</div>
        )}
      </div>
    );
  }
}

EcMemberItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  handleMemberCheck: PropTypes.func,
  handleShowModal: PropTypes.func,
};

export default injectSheet(styles)(EcMemberItem);
