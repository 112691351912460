import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';

import { FormField, Label, Layout, Text, TextInput } from '~/eds';
import { actions } from '~/redux';
import { Alert } from '~/ui';
import { htmlToText } from '~/utils/strings';

import { CKEditor } from '../../Shared/CKEditor4';
import {
  CLAUSE_LIBRARY_NAME_CHAR_LIMIT,
  GUIDANCE_NOTES_CHAR_LIMIT,
} from '../constants';
import {
  ClauseForm as ClauseFormInterface,
  State as ClauseLibraryState,
} from '../types';

const editorConfig = {
  toolbar: [
    { name: 'editing', items: ['Undo', 'Redo'] },
    {
      name: 'text',
      items: ['Format', 'Bold', 'Italic', 'Underline', 'Strike'],
    },
    { name: 'link', items: ['Link', 'Unlink'] },
    {
      name: 'format',
      items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
    },
    {
      name: 'paragraph',
      items: ['BulletedList', 'NumberedList', 'BidiLtr', 'BidiRtl'],
    },
  ],
  toolbarGroups: [
    { name: 'group1', groups: ['editing'] },
    { name: 'group2', groups: ['text'] },
    { name: 'group3', groups: ['link'] },
    { name: 'group4', groups: ['format'] },
    { name: 'group5', groups: ['paragraph'] },
  ],
};

const ClauseForm = ({
  clauseForm,
  setClauseForm,
  setClauseFormGuidanceNotes,
  resetClauseLibraryClauseFormGuidanceError,
}: {
  clauseForm: ClauseFormInterface;
  setClauseForm: Dispatch<ClauseFormInterface>;
  setClauseFormGuidanceNotes: Dispatch<string>;
  resetClauseLibraryClauseFormGuidanceError: Dispatch<void>;
}) => {
  const [clauseName, setClauseName] = useState(clauseForm.name);
  const [guidanceNotes, setGuidanceNotes] = useState(
    clauseForm.guidanceNotes || '',
  );

  const clauseNameFooter = `${
    clauseName.trim().length
  }/${CLAUSE_LIBRARY_NAME_CHAR_LIMIT}`;
  const clauseNameOverLimit =
    clauseName.trim().length > CLAUSE_LIBRARY_NAME_CHAR_LIMIT;
  // must come from validation error because it shouldn't display until submission
  const clauseNameMissing = clauseForm.validationErrors?.find(
    (message) => message === 'clause name missing',
  );
  const clauseNameAPIErrors = clauseForm.errors?.filter(
    (error) => error.source?.pointer === '/name',
  );
  const getClauseNameError = () => {
    if (clauseNameOverLimit)
      return `${clauseNameFooter} (Name cannot be longer than ${CLAUSE_LIBRARY_NAME_CHAR_LIMIT} characters)`;
    if (clauseNameMissing) return 'This is a required field';
    if (clauseNameAPIErrors?.length)
      return clauseNameAPIErrors.map((error) => error.detail).join(' ');
    return undefined;
  };

  const guidanceNotesText = htmlToText(guidanceNotes).trim();
  const guidanceNotesFooter = `${guidanceNotesText.length}/${GUIDANCE_NOTES_CHAR_LIMIT}`;
  const guidanceNotesOverLimit =
    guidanceNotesText.length > GUIDANCE_NOTES_CHAR_LIMIT;
  const guidanceNotesAPIErrors = clauseForm.errors?.filter(
    (error) => error.source?.pointer === '/guidance',
  );
  const getGuidanceNotesError = () => {
    if (guidanceNotesOverLimit)
      return `${guidanceNotesFooter} (Guidance notes cannot be longer than ${GUIDANCE_NOTES_CHAR_LIMIT} characters)`;
    if (guidanceNotesAPIErrors?.length)
      return guidanceNotesAPIErrors.map((error) => error.detail).join(' ');
    return undefined;
  };

  function handleClauseNameUpdate(name: string) {
    setClauseName(name);
    const errors = clauseForm.errors?.filter(
      (error) => error.source?.pointer && error.source.pointer !== '/name',
    );
    const validationErrors = clauseForm.validationErrors?.filter(
      (error) => error !== 'clause name missing',
    );
    setClauseForm({ ...clauseForm, name, errors, validationErrors });
  }

  function handleGuidanceNotesUpdate(editor: any) {
    const guidanceNotes = editor.getData();
    setGuidanceNotes(guidanceNotes);
    setClauseFormGuidanceNotes(guidanceNotes);
    resetClauseLibraryClauseFormGuidanceError();
  }

  function handleDismissErrorAlert() {
    setClauseForm({ ...clauseForm, errors: undefined });
  }

  const CLAUSE_FORM_POINTERS = ['/name', '/guidance'];

  return (
    <Layout direction="column" spacing={8} w="100%">
      {!!clauseForm?.errors &&
        clauseForm.errors.map((error) => {
          if (
            error.source?.pointer &&
            CLAUSE_FORM_POINTERS?.includes(error.source.pointer)
          )
            return <></>;
          return (
            <Alert
              key="warning"
              enableIcon
              variant="danger"
              onDismiss={handleDismissErrorAlert}
            >
              <Layout direction="column" spacing={2}>
                <Text variant="body">{error.detail}</Text>
              </Layout>
            </Alert>
          );
        })}
      <FormField
        name="clause-library-clause-name"
        label="Clause Name"
        input={TextInput}
        placeholder="Start typing to name the new clause"
        value={clauseName}
        // @ts-ignore
        onChange={handleClauseNameUpdate}
        footer={clauseNameFooter}
        error={getClauseNameError()}
        required
      />
      <Layout direction="column" spacing={4} aria-label="Guidance">
        <Label>Guidance</Label>
        <Layout direction="column" spacing={2}>
          <CKEditor
            data={guidanceNotes}
            config={editorConfig}
            onChange={handleGuidanceNotesUpdate}
          />
          {getGuidanceNotesError() ? (
            <Text color="status.danger" variant="tiny" role="note">
              {getGuidanceNotesError()}
            </Text>
          ) : (
            <Text color="text.secondary" variant="tiny" role="note">
              {guidanceNotesFooter}
            </Text>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({
  clauseLibrary,
}: {
  clauseLibrary: ClauseLibraryState;
}) => ({
  clauseForm: clauseLibrary.clauseForm,
});

export default connect(mapStateToProps, {
  setClauseForm: actions.setClauseLibraryClauseForm,
  setClauseFormGuidanceNotes: actions.setClauseLibraryClauseGuidanceNotes,
  resetClauseLibraryClauseFormGuidanceError:
    actions.resetClauseLibraryClauseFormGuidanceError,
})(ClauseForm);
