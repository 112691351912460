import { useMemo } from 'react';

import { User } from '~/components/Shared/User';
import { Chip, Layout, SortableList } from '~/eds';
import { TicketStatusType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { testIsCounterpartySigner } from '~/utils/ticket';
import { externalUserToUser } from '~/utils/user';

import { UserInfo } from '../TicketUserItem';

const SortRecipients = ({
  recipients,
  approvals,
  onSort,
  onDelete,
  judgments,
  stage,
}) => {
  const hasCCRecipientsFlag = useFlag(FlagType.CCRecipients);

  const onSortEnd = (sortedRecipients) => {
    onSort(sortedRecipients.map((item) => item.data));
  };

  const items = useMemo(
    () =>
      recipients.map((recipient, index) => {
        const approvalItem =
          recipient.approvalIds && recipient.approvalIds.length
            ? approvals[recipient.approvalIds[0]]
            : null;
        let judgment = null;
        let isCounterpartySigner = false;
        let userId;
        let externalUser;
        let isCarbonCopy = true;
        if (approvalItem) {
          const {
            judgmentId,
            status,
            userId: approvalItemUserId,
            externalUser: approvalItemExternalUser,
          } = approvalItem;
          judgment = judgments[judgmentId];
          isCounterpartySigner =
            status === TicketStatusType.SignatureRequestNotSent
              ? testIsCounterpartySigner(judgment)
              : approvalItemUserId === null;
          isCarbonCopy = status === TicketStatusType.NotApplicable;
          userId = approvalItem.userId;
          externalUser = approvalItemExternalUser
            ? externalUserToUser(approvalItemExternalUser)
            : undefined;
        } else {
          // If approvalItem is null, it means the user has just been added as CC recipient.
          const { name, email, userId: recipientUserId } = recipient;
          userId = recipientUserId;
          externalUser = !userId
            ? externalUserToUser({ name, email })
            : undefined;
        }
        return {
          item: {
            data: recipient,
            content: (
              <Layout key={index} role="menuitem" tabIndex={0}>
                {hasCCRecipientsFlag ? (
                  <Layout justify="space-between" w="100%">
                    <span>
                      <Layout align="center" spacing={4} p={2}>
                        <User
                          id={userId}
                          user={externalUser}
                          placeholder="Unassigned Signer"
                          mode="info"
                        />
                        {isCarbonCopy && <Chip text="CC" variant="info" />}
                        {isCounterpartySigner && (
                          <Chip text="Counterparty Signer" variant="info" />
                        )}
                      </Layout>
                    </span>
                  </Layout>
                ) : (
                  <UserInfo
                    isCounterpartySigner={isCounterpartySigner}
                    userId={userId}
                    externalUser={externalUser}
                    currentStage={stage}
                  />
                )}
              </Layout>
            ),
          },
          isRemovable: isCarbonCopy,
        };
      }),
    [recipients.length],
  );

  return (
    <SortableList
      items={items}
      renderTitle={(item) => item.content}
      onChange={onSortEnd}
      onRemoveItem={(_, index) => onDelete(index)}
      itemConfig={{ enableBorder: true, padding: 'l' }}
      rowSpacing="s"
      isRemovable
    />
  );
};

export default SortRecipients;
