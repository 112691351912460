import React from 'react';

import { Box, Layout } from '~/eds';

interface Props {
  children: React.ReactNode;
  sidebar: React.ReactNode;
}

const AppLayout = ({ children, sidebar }: Props) => {
  return (
    <Layout h="100%">
      <Box flex="auto">{children}</Box>
      {sidebar && <Box flex="none">{sidebar}</Box>}
    </Layout>
  );
};

export default AppLayout;
