import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { images } from '../../tokens/images';
import { ImageType, SharedBoxProps } from '../../types';
import { Box } from '../Box';

interface Props extends SharedBoxProps {
  /** Image alt attribute */
  alt: string;
  /** Image token */
  image?: ImageType;
  /** Image src attribute */
  src?: string;
}

export const Image = memo(
  ({ alt, disabled, image, src, onClick, ...rest }: Props) => {
    const styles = useStyles();

    const imageElement = (
      <Box
        {...rest}
        alt={alt}
        as="img"
        display="block"
        src={image ? images[image] : src}
        onClick={onClick}
      />
    );

    return onClick ? (
      <Box
        as="button"
        disabled={disabled}
        onClick={onClick}
        styles={[styles.button.unset]}
      >
        {imageElement}
      </Box>
    ) : (
      imageElement
    );
  },
);
