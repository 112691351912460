import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_QUESTION_BY_LIST = { type: TagType.Question, id: 'LIST' };
const TAG_QUESTION_GROUP_BY_LIST = { type: TagType.QuestionGroup, id: 'LIST' };

const invalidateQuestionListTag = (_result: any, error: any) =>
  error ? [] : [TAG_QUESTION_BY_LIST];

const invalidateQuestionGroupListTag = (_result: any, error: any) =>
  error ? [] : [TAG_QUESTION_GROUP_BY_LIST];

api.enhanceEndpoints({
  addTagTypes: [TagType.QuestionGroup as TagType],
  endpoints: {
    createQuestion: {
      invalidatesTags: invalidateQuestionListTag,
    },
    deleteQuestion: {
      invalidatesTags: invalidateQuestionListTag,
    },
    getQuestions: {
      providesTags: [TAG_QUESTION_BY_LIST],
    },
    updateQuestion: {
      invalidatesTags: invalidateQuestionListTag,
    },
    getQuestionGroups: {
      providesTags: [TAG_QUESTION_GROUP_BY_LIST],
    },
    createQuestionGroup: {
      invalidatesTags: invalidateQuestionGroupListTag,
    },
    updateQuestionGroup: {
      invalidatesTags: invalidateQuestionGroupListTag,
    },
    deleteQuestionGroup: {
      invalidatesTags: invalidateQuestionGroupListTag,
    },
  },
});
