import PT from 'prop-types';
import React, { useState } from 'react';
import Select, { components as selectComponents } from 'react-select';
import Creatable from 'react-select/creatable';

import { sortByNumericValue } from '~/utils/array';
import { textMatch } from '~/utils/strings';

import { withInputError } from '../../hocs';
import Box from '../Box';
import FlexLayout from '../FlexLayout';
import HtmlContent from '../HtmlContent';
import Icon from '../Icon';
import Text from '../Text';
import components from './components';
import stylesFactory from './stylesFactory';

const maxMenuHeight = 264;

const widths = {
  s: '200px',
  m: '260px',
  l: '520px',
  fullWidth: '100%',
};

export const defaultFormatCreateLabel = (label) => `Add ${label}`;

export const formatCreateLabelFactory = (
  formatter = defaultFormatCreateLabel,
) => (label) => {
  return (
    <FlexLayout alignItems="center" justifyContent="space-between" space={1}>
      <Text color="gray-600" shouldTruncate variant="s-dense">
        {formatter(label)}
      </Text>
      <Icon color="gray-600" icon="returnArrow" size="s" />
    </FlexLayout>
  );
};

// Note: It is not ideal to use dangerouslySetInnerHTML, but this is the best and most intuitive implemention of formatting the option label string value.  Tokenizing and reconstructing the component from tokens involves more complex logic.
export function formatOptionLabel(option, meta) {
  const { label, shouldDisableTextMatch } = option;

  if (typeof label !== 'string' || shouldDisableTextMatch) {
    return label;
  }

  return <HtmlContent html={textMatch(label, meta.inputValue)} />;
}

export function getOptionsData({
  blacklistValues,
  initialOptions,
  whitelistValues,
  optionIconRenderer,
}) {
  const optionsMap = initialOptions.reduce((acc, option, index) => {
    const { value } = option;
    const updatedOption = {
      ...option,
      index,
    };
    if (optionIconRenderer) {
      updatedOption.icon = optionIconRenderer(option);
    }

    const shouldFilter =
      whitelistValues.length > 0 || blacklistValues.length > 0;
    const isWhitelisted =
      whitelistValues.length > 0 && whitelistValues.includes(value);
    const isNotBlacklisted =
      blacklistValues.length > 0 && !blacklistValues.includes(value);

    updatedOption.isShown = !shouldFilter || isWhitelisted || isNotBlacklisted;

    acc[value] = updatedOption;

    return acc;
  }, {});

  const options = Object.values(optionsMap)
    .filter((option) => option.isShown)
    .sort(sortByNumericValue('index'));

  return {
    options,
    optionsMap,
  };
}

export function MenuListRenderer(props, menuHeaderRenderer) {
  return (
    <selectComponents.MenuList {...props}>
      {menuHeaderRenderer && (
        <Box mx={4} pb={3}>
          {menuHeaderRenderer()}
        </Box>
      )}
      {props.children}
    </selectComponents.MenuList>
  );
}

function getSelectValue(value, optionsMap) {
  return optionsMap[value] || null;
}

// TODO: Create BaseSelect component that provides reusable methods, components, props, prop-types for implementing SingleSelect and MultiSelect
function SingleSelect({
  blacklistValues = [],
  disabled = false,
  enableDocumentBodyMenuPortal = false,
  error,
  formatCreateLabel = defaultFormatCreateLabel,
  id,
  isClearable = true,
  isLoading = false,
  isInputClearedAfterBlur = false,
  isSearchable = true,
  noOptionsMessage = 'No options',
  options: initialOptions,
  placeholder = 'Select',
  shouldHideSelectedOptions = true,
  value,
  whitelistValues = [],
  width = 'm',
  filterOption,
  menuHeaderRenderer,
  menuPlacement = 'auto',
  onChange,
  onAddOption,
  onFocus,
  onInputChange,
  optionIconRenderer,
  name,
}) {
  const isCreatable = !!onAddOption;
  const SelectComponent = isCreatable ? Creatable : Select;
  const [inputValue, setInputValue] = useState('');

  const { optionsMap, options } = getOptionsData({
    blacklistValues,
    initialOptions,
    whitelistValues,
    optionIconRenderer,
  });

  return (
    <Box id={id} sx={{ flexShrink: 0, width: widths[width] }}>
      <SelectComponent
        components={{
          ...components,
          MenuList: (props) => MenuListRenderer(props, menuHeaderRenderer),
        }}
        isLoading={isLoading}
        filterOption={filterOption}
        formatCreateLabel={formatCreateLabelFactory(formatCreateLabel)}
        formatOptionLabel={formatOptionLabel}
        hideSelectedOptions={shouldHideSelectedOptions}
        inputValue={isInputClearedAfterBlur ? undefined : inputValue}
        isClearable={isClearable}
        isCreatable={isCreatable}
        isDisabled={disabled}
        isSearchable={isSearchable || isCreatable}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        menuPortalTarget={
          enableDocumentBodyMenuPortal ? document.body : undefined
        }
        noOptionsMessage={() => noOptionsMessage}
        options={options}
        placeholder={placeholder}
        styles={stylesFactory(error)}
        value={getSelectValue(value, optionsMap)}
        onCreateOption={(newValue) => {
          if (!optionsMap[newValue]) {
            onAddOption({ value: newValue, label: newValue });
            onChange(newValue);
          }
        }}
        onChange={(updatedOption) => {
          onChange(updatedOption ? updatedOption.value : null);
        }}
        onFocus={onFocus}
        onInputChange={(value, action) => {
          if (
            !isInputClearedAfterBlur &&
            action?.action !== 'input-blur' &&
            action?.action !== 'menu-close'
          ) {
            setInputValue(value);
          }
          onInputChange?.(value, action);
        }}
        role={isSearchable ? 'combobox' : 'listbox'}
        name={name}
      />
    </Box>
  );
}

export const optionPropType = PT.shape({
  label: PT.string.isRequired,
  value: PT.any,
  // props below configure option rendering behaviors
  errorLabel: PT.string,
  indent: PT.number,
  isDisabled: PT.bool,
  isLoading: PT.bool,
  isShown: PT.bool,
  shouldDisableTextMatch: PT.bool,
  tooltip: PT.string,
  valueLabelOverride: PT.string,
});

SingleSelect.propTypes = {
  /** Exclude options that match any of the values that are blacklisted */
  blacklistValues: PT.arrayOf(PT.any),
  /** Indicates if component is disabled */
  disabled: PT.bool,
  /** When rendering component in modals and popovers, this is required to allow menu to be attached to document.body and support floating above its attached node */
  enableDocumentBodyMenuPortal: PT.bool,
  /** Error that gets passed to component */
  error: PT.string,
  /** Customize the label when creating a new option */
  formatCreateLabel: PT.func,
  /** Supports clear indicator */
  isClearable: PT.bool,
  /** Indicates if options are searchable */
  isSearchable: PT.bool,
  /** Message to display in menu when no options are found */
  noOptionsMessage: PT.string,
  /** Array of options: { value, label } */
  options: PT.arrayOf(optionPropType.isRequired).isRequired,
  /** Placeholder */
  placeholder: PT.string,
  /** Determines if selected options are hidden in the menu */
  shouldHideSelectedOptions: PT.bool,
  /** Currently selected value */
  value: PT.any,
  /** Explicitly isShown options that match any of the values that are whitelisted */
  whitelistValues: PT.arrayOf(PT.any),
  /** Input width */
  width: PT.oneOf(['s', 'm', 'l', 'fullWidth']),
  /** Custom filtering to display options when search input value changes */
  filterOption: PT.func,
  /** Renderer for the menu header */
  menuHeaderRenderer: PT.func,
  /** Dropdown position */
  menuPlacement: PT.string,
  /** Callback that returns new selected value */
  onChange: PT.func.isRequired,
  /** Callback that returns a newly created option - transforms component to `Creatable` (see `react-select` package) */
  onAddOption: PT.func,
  /** Callback when select is focused */
  onFocus: PT.func,
  /** Callback when select search input changes */
  onInputChange: PT.func,
  /** Renderer for option icon */
  optionIconRenderer: PT.func,
};

export const DOCS__SingleSelect = SingleSelect;

export default withInputError(SingleSelect);
