import { SHORTHAND_STYLE_PROPS } from '../../constants';

const {
  bg,
  border,
  borderBottom,
  borderLeft,
  borderTop,
  borderRadius,
  borderRight,
  boxShadow,
  color,
  display,
  flex,
  h,
  m,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
  maxH,
  maxW,
  minH,
  minW,
  overflowX,
  overflowY,
  p,
  pb,
  pl,
  pr,
  pt,
  px,
  py,
  position,
  w,
} = SHORTHAND_STYLE_PROPS;

export const elementShorthandPropsMapping = {
  backgroundColor: [bg],
  border: [border],
  borderBottom: [borderBottom, border],
  borderLeft: [borderLeft, border],
  borderTop: [borderTop, border],
  borderRadius: [borderRadius],
  borderRight: [borderRight, border],
  boxShadow: [boxShadow],
  color: [color],
  display: [display],
  flex: [flex],
  height: [h],
  margin: [m],
  marginBottom: [mb, my, m],
  marginLeft: [ml, mx, m],
  marginRight: [mr, mx, m],
  marginTop: [mt, my, m],
  maxHeight: [maxH],
  maxWidth: [maxW],
  minHeight: [minH],
  minWidth: [minW],
  overflowX: [overflowX],
  overflowY: [overflowY],
  padding: [p],
  paddingBottom: [pb, py, p],
  paddingLeft: [pl, px, p],
  paddingRight: [pr, px, p],
  paddingTop: [pt, py, p],
  position: [position],
  width: [w],
};
