import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Pill from '~/components/Workflow/shared/Pill';
import { getConditionById } from '~/reducers/workflow';
import { testInvalidConditionRule } from '~/utils/workflow';

function ConditionRulePill({
  conditionRule,
  // connected,
  condition,
  isInvalid,
}) {
  if (!conditionRule) {
    return null;
  }

  let bg;
  let color;
  let icon = 'condition';
  let text;

  if (conditionRule && condition) {
    bg = 'plum-500';
    color = 'white';
    text = `Show if "${condition.name}" is ${conditionRule.satisfiedWhenCondMet}`;
  }

  if (isInvalid) {
    bg = 'red-600';
    icon = 'warning';
    color = 'white';
    text = 'Missing Condition';
  }

  return <Pill bg={bg} color={color} icon={icon} text={text} />;
}

const conditionRulePropTypes = PT.shape({
  id: PT.string,
  enabledWhenSatisified: PT.bool,
  satisfiedWhenCondMet: PT.bool,
});

ConditionRulePill.propTypes = {
  conditionRule: conditionRulePropTypes,
};

const mapStateToProps = ({ workflow }, { conditionRule }) => ({
  condition: getConditionById(workflow, conditionRule?.id),
  isInvalid: testInvalidConditionRule(conditionRule, workflow.conditions),
});

export default connect(mapStateToProps)(ConditionRulePill);
