import { escapeRegExp } from 'lodash';

// TODO: we should just use `string` npm package instead of our own custom methods!

/**
 * Capitalizes the first letter of a string
 * @param {string} string input string (could be null/undefined)
 * @returns {string} capitalized string
 */
export function capitalize(string: string) {
  return !string ? '' : string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Capitalizes the first letter of words in a string
 * @param {string} string input string
 * @param {string} delimiter delimiter to split the string on
 * @returns {string} word-capitalized string
 */
export function capitalizeWords(string: string, delimiter = ' ') {
  return string.split(delimiter).map(capitalize).join(delimiter);
}

/**
 * Validates email with regex (RFC5322 compliant)
 * https://www.ietf.org/rfc/rfc5322.txt
 * @param {string} string input string
 * @returns {boolean} true/false
 */
export function validateEmail(string: string) {
  return Boolean(
    string.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line max-len
    ),
  );
}

/**
 * Returns matched text with <strong /> tags applied
 * @param {input string} string
 * @returns {string} matched string
 */
export function textMatch(string: string, pattern: string, tag = 'strong') {
  if (!string) {
    return '';
  }
  if (!pattern) {
    return string;
  }
  const re = new RegExp(`(${escapeRegExp(pattern)})`, 'gi');
  return string.replace(re, (match) => `<${tag}>${match}</${tag}>`);
}

/**
 * Lowercases and trims a string
 * @param {string} string input string
 * @returns {string} trimmed and lowercased string
 */
export function toLowercaseTrimmed(string: string) {
  return string.trim().toLowerCase();
}

export function replaceAllSpaces(string: string) {
  return !string ? '' : string.replace(/\s/g, '');
}

export function toSnakeTrimmed(string: string) {
  return string ? string.trim().replace(/[\s]/g, '_') : '';
}

export function isValueStrictlyEmpty(value: any) {
  return (
    value === null ||
    value === undefined ||
    (typeof value === 'string' && toLowercaseTrimmed(value) === '') ||
    (typeof value === 'object' && !Object.values(value).length) ||
    (Array.isArray(value) && !value.length)
  );
}

export function searchByString(data: string, searchString: string) {
  return data?.toLowerCase().includes(searchString?.toLowerCase());
}

export const countWords = (str: string) => {
  str = str.replace(/\W/g, ' ');
  return Math.max((str.split(/\S+/) || []).length - 1, 0);
};

export const sliceWords = (str: string, n: number) => {
  const spaced = str.replace(/\W/g, ' ');

  let flag = false;
  let count = 0;

  for (let i = 0; i < spaced.length; i++) {
    if (spaced[i] !== ' ') {
      flag = true;
    } else {
      if (flag) count += 1;
      flag = false;
    }

    if (count >= n) return str.substring(0, i);
  }

  return str;
};

export const htmlToText = (html: string): string => {
  const preparedHtml = `<div>${html}</div>`;
  const parser = new DOMParser();
  const parsed = parser.parseFromString(preparedHtml, 'text/html');
  const errorNode = parsed.querySelector('parsererror');
  if (errorNode) {
    // parsing failed
    return '';
  } else {
    // parsing succeeded
    const documentText = parsed.documentElement.textContent;
    return documentText ?? '';
  }
};

/**
 * Removes breaklines from a string.
 * It will remove breaklines if they are at the beginning or end of a line, or replace with a space if they are in the middle of a line.
 * @param text
 * @returns string
 */
export const removeBreaklinesFromText = (text: string): string => {
  // Step 1: Remove breaklines from the beginning and end of the text
  let processedText = text.replace(/(^\s*[\r\n]+|[\r\n]+\s*$)/g, '');

  // Step 2: Replace breaklines in the middle of the text with a space
  // Also consider multiple breaklines
  processedText = processedText.replace(/(\r\n|\n|\r)+/g, ' ');

  return processedText;
};
