export default `
<svg
  aria-hidden="true"
  aria-label="circle-stop"
  width="22"
  height="22"
  viewBox="0 0 22 22"
  fill="none"
>
  <path 
    d="M15.331 6.66897C16.4797 7.81763 17.125 9.37555 17.125 11C17.125 12.6245 16.4797 14.1824 15.331 15.331C14.1824 16.4797 12.6245 17.125 11 17.125C9.37555 17.125 7.81763 16.4797 6.66897 15.331C5.52031 14.1824 4.875 12.6245 4.875 11C4.875 9.37555 5.52031 7.81763 6.66897 6.66897C7.81763 5.52031 9.37555 4.875 11 4.875C12.6245 4.875 14.1824 5.52031 15.331 6.66897ZM6.05025 6.05025C4.7375 7.36301 4 9.14348 4 11C4 12.8565 4.7375 14.637 6.05025 15.9497C7.36301 17.2625 9.14348 18 11 18C12.8565 18 14.637 17.2625 15.9497 15.9497C17.2625 14.637 18 12.8565 18 11C18 9.14348 17.2625 7.36301 15.9497 6.05025C14.637 4.7375 12.8565 4 11 4C9.14348 4 7.36301 4.7375 6.05025 6.05025ZM9.25 8.375C8.76602 8.375 8.375 8.76602 8.375 9.25V12.75C8.375 13.234 8.76602 13.625 9.25 13.625H12.75C13.234 13.625 13.625 13.234 13.625 12.75V9.25C13.625 8.76602 13.234 8.375 12.75 8.375H9.25Z"
    fill="currentcolor"
  />
</svg>
`;
