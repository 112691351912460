import React, { ReactNode } from 'react';

import { Text, useDetailModal } from '~/eds';

import styles from './styles';

type Props = {
  content: string;
  title: string;
  onClose: () => void;
};

const useShowMoreClauseModal = ({
  content,
  title,
  onClose,
}: Props): [ReactNode, () => void] => {
  const [clauseModal, showClauseModal] = useDetailModal({
    title: title,
    isFullPage: true,
    onCancel: onClose,
    primaryAction: {
      variant: 'tertiary',
      text: 'Close',
      onClick: onClose,
    },
    children: (
      <Text styles={styles.clauseContent} px={6}>
        {content}
      </Text>
    ),
  });

  return [clauseModal, showClauseModal];
};

export default useShowMoreClauseModal;
