import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { checkESignatureLogin } from '~/api';
import { Button, useToast } from '~/eds';
import { TicketStageType, TicketStatusType } from '~/enums';
import { withUsers } from '~/hocs';
import { useAsync } from '~/hooks';
import { api } from '~/redux';
import { testIsFileExtensionSupported } from '~/utils/esignature';
import {
  getTicketDocumentFileExtension,
  shouldFillFormQuestionsInSignStage,
  testHasInvalidSigners,
} from '~/utils/ticket';

function CollectSignatureAction({
  // connected
  esignatureProvider,
  hasEnvelope,
  hasEsignatureIntegration,
  ticketId,
  signStatus,
  ticket,
  users,
}) {
  const [eSignatureInfo, setESignatureInfo] = useState({ error: true });
  const { toast } = useToast();

  const [
    collectSignatures,
    result,
  ] = api.endpoints.collectSignatures.useLazyQuery();
  const {
    isLoading: isCollectSignaturesLoading,
    error: collectSignaturesError,
  } = result;

  useEffect(() => {
    if (collectSignaturesError) {
      // if the integration is missing, we should show a warning message
      if (collectSignaturesError?.response?.status === 404) {
        toast({
          title: 'Missing Electronic Signature Integration',
          message:
            'Please contact your system administrator to verify and activate the Electronic Signature Integration.',
          status: 'warning',
          autoClose: false,
        });
      } else if (
        collectSignaturesError?.response?.status === 400 &&
        // TODO: we shouldn't be veryfing the error message like this, should be better parsed in BE.
        collectSignaturesError.response?.data?.data.includes('sfdt')
      ) {
        toast({
          title: 'We received an error',
          message:
            "We're unable to open this document. Please use Word on the Web or edit in Word on your computer and upload again.",
          status: 'danger',
          autoClose: false,
        });
      } else {
        toast({
          title: 'We received an error',
          message: collectSignaturesError.response?.data?.data,
          status: 'danger',
          autoClose: false,
        });
      }
    }
  }, [collectSignaturesError]);

  useEffect(() => {
    const redirectUrl = result?.data?.redirectUrl;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [result]);

  const executeCollectSignatures = () => {
    collectSignatures({
      ticketId,
      esignatureProvider,
      ticketUrl: window.location.href,
    });
  };

  useAsync(
    checkESignatureLogin,
    { ticketId, esignatureProvider, ticketUrl: window.location.href },
    {
      condition: !!esignatureProvider,
      deps: [ticketId],
      successHandler: setESignatureInfo,
      errorToastMessage:
        'There was an issue communicating with the e-signature provider. Please try again.',
    },
  );

  if (
    signStatus === TicketStatusType.Signed ||
    hasEnvelope ||
    !esignatureProvider
  ) {
    return null;
  }

  const handleCollectSignaturesClick = () => {
    if (eSignatureInfo.error) {
      toast({
        message:
          'Unable to collect the signatures. Please refresh the page and try again.',
        status: 'danger',
      });
      return;
    }

    if (eSignatureInfo.isUserEsignatureLoggedIn) {
      executeCollectSignatures();
    } else {
      window.location.href = eSignatureInfo.redirectUrl;
    }
  };

  if (shouldFillFormQuestionsInSignStage(ticket, users)) {
    return null;
  }

  let fileExtension = getTicketDocumentFileExtension(ticket);
  const isFileExtensionSupported = testIsFileExtensionSupported(
    fileExtension,
    esignatureProvider,
  );

  const tooltip = !hasEsignatureIntegration
    ? 'This account has not set up an e-signature provider yet.'
    : !isFileExtensionSupported
    ? 'The file type is not supported by your e-signature provider.' +
      ' Please upload your contract in a supported file type and try again.'
    : undefined;

  return (
    <Button
      disabled={
        !hasEsignatureIntegration ||
        !isFileExtensionSupported ||
        (ticket.stage === TicketStageType.Sign &&
          testHasInvalidSigners(ticket, users))
      }
      isLoading={isCollectSignaturesLoading}
      onClick={handleCollectSignaturesClick}
      text="Collect Signatures"
      tooltip={tooltip}
      variant="primary"
    />
  );
}

function mapStateToProps({ ticket }) {
  return {
    esignatureProvider: ticket.esignatureProvider,
    hasEnvelope: ticket.hasEnvelope,
    hasEsignatureIntegration: ticket.hasEsignatureIntegration,
    ticketId: ticket.id,
    signStatus: ticket.stages.sign?.status,
    ticket: ticket,
  };
}

export default withUsers(connect(mapStateToProps)(CollectSignatureAction));
