export default `
<svg
  aria-hidden="true"
  aria-label="chip"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M14 9H10C9.4375 9 9 9.46875 9 10V14C9 14.5625 9.4375 15 10 15H14C14.5312 15 15 14.5625 15 14V10C15 9.46875 14.5312 9 14 9ZM13.5 13.5H10.5V10.5H13.5V13.5ZM19.25 12.75C19.6562 12.75 20 12.4375 20 12C20 11.5938 19.6562 11.25 19.25 11.25H18V9.75H19.25C19.6562 9.75 20 9.4375 20 9C20 8.59375 19.6562 8.25 19.25 8.25H18V8C18 6.90625 17.0938 6 16 6H15.75V4.75C15.75 4.34375 15.4062 4 15 4C14.5625 4 14.25 4.34375 14.25 4.75V6H12.75V4.75C12.75 4.34375 12.4062 4 12 4C11.5625 4 11.25 4.34375 11.25 4.75V6H9.75V4.75C9.75 4.34375 9.40625 4 9 4C8.5625 4 8.25 4.34375 8.25 4.75V6H8C6.875 6 6 6.90625 6 8V8.25H4.75C4.3125 8.25 4 8.59375 4 9C4 9.4375 4.3125 9.75 4.75 9.75H6V11.25H4.75C4.3125 11.25 4 11.5938 4 12C4 12.4375 4.3125 12.75 4.75 12.75H6V14.25H4.75C4.3125 14.25 4 14.5938 4 15C4 15.4375 4.3125 15.75 4.75 15.75H6V16C6 17.125 6.875 18 8 18H8.25V19.25C8.25 19.6875 8.5625 20 9 20C9.40625 20 9.75 19.6875 9.75 19.25V18H11.25V19.25C11.25 19.6875 11.5625 20 12 20C12.4062 20 12.75 19.6875 12.75 19.25V18H14.25V19.25C14.25 19.6875 14.5625 20 15 20C15.4062 20 15.75 19.6875 15.75 19.25V18H16C17.0938 18 18 17.125 18 16V15.75H19.25C19.6562 15.75 20 15.4375 20 15C20 14.5938 19.6562 14.25 19.25 14.25H18V12.75H19.25ZM16.5 16C16.5 16.2812 16.25 16.5 16 16.5H8C7.71875 16.5 7.5 16.2812 7.5 16V8C7.5 7.75 7.71875 7.5 8 7.5H16C16.25 7.5 16.5 7.75 16.5 8V16Z"
    fill="currentcolor"
  />
</svg>
`;
