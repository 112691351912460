import orderBy from 'lodash/orderBy';
import React from 'react';
import { connect } from 'react-redux';

import { FlexLayout, Icon, Text, useToggle } from '~/ui';

// TODO - expand the path to the selected folder by default
function TreeItem({ isRoot = false, item, selectedItemId, setSelectedItemId }) {
  const [isExpanded, toggleIsExpanded] = useToggle(isRoot);

  const isEmpty = !item.children?.length;
  const isSelected = item.id === selectedItemId;

  const sortedItemChildren = orderBy(item.children, [
    (child) => child.name.toLowerCase(),
  ]);

  return (
    <FlexLayout flexDirection="column">
      <FlexLayout alignItems="center" space={2}>
        {!isEmpty && (
          <Icon
            color="gray-700"
            icon={isExpanded ? 'chevronDown' : 'chevronRight'}
            size="s"
            title={isExpanded ? 'collapse' : 'expand'}
            onClick={toggleIsExpanded}
          />
        )}
        <Text
          color={isSelected ? 'gray-900' : 'gray-800'}
          ml={isEmpty ? '28px' : 0}
          variant={isSelected ? 'm-spaced-bold' : 'm-spaced'}
          onClick={() => setSelectedItemId(item.id)}
        >
          {item.name}
        </Text>
      </FlexLayout>
      {isExpanded && (
        <FlexLayout flexDirection="column" sx={{ pl: 6 }}>
          {sortedItemChildren.map((child) => (
            <TreeItem
              item={child}
              key={child.id}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
            />
          ))}
        </FlexLayout>
      )}
    </FlexLayout>
  );
}

function FolderTree({
  selectedItemId,
  setSelectedItemId,
  // connected
  folderTree,
}) {
  return (
    <FlexLayout flexDirection="column">
      <TreeItem
        isRoot
        item={folderTree}
        selectedItemId={selectedItemId}
        setSelectedItemId={setSelectedItemId}
      />
    </FlexLayout>
  );
}

function mapStateToProps({ client }) {
  return { folderTree: client.folderTree };
}

export default connect(mapStateToProps)(FolderTree);
