export default `
<svg
  aria-hidden="true"
  aria-label="compress"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.4688 9.78125C11.5312 9.875 11.7188 10 12 10C12.25 10 12.4375 9.875 12.5 9.78125L14.75 7.53125C14.9062 7.40625 15 7.21875 15 7C15 6.59375 14.625 6.25 14.25 6.25C14.0312 6.25 13.8438 6.34375 13.7188 6.5L12.75 7.46875V4.75C12.75 4.34375 12.4062 4 12 4C11.5625 4 11.25 4.34375 11.25 4.75V7.46875L10.25 6.5C10.125 6.34375 9.9375 6.25 9.75 6.25C9.3125 6.25 9 6.625 9 7C9 7.21875 9.0625 7.40625 9.21875 7.53125L11.4688 9.78125ZM12.5 14.2188C12.3125 14.0312 12.0938 14 12 14C11.875 14 11.6562 14.0312 11.4688 14.25L9.21875 16.5C9.0625 16.625 9 16.8125 9 17C9 17.4375 9.34375 17.75 9.75 17.75C9.9375 17.75 10.125 17.6875 10.25 17.5312L11.25 16.5625V19.25C11.25 19.6875 11.5625 20 12 20C12.4062 20 12.75 19.6875 12.75 19.25V16.5625L13.7188 17.5312C13.8438 17.6875 14.0312 17.75 14.25 17.75C14.8125 17.75 15 17.2188 15 17C15 16.8125 14.9062 16.625 14.75 16.5L12.5 14.2188ZM18.25 11.25H5.75C5.3125 11.25 5 11.5938 5 12C5 12.375 5.3125 12.75 5.75 12.75H18.25C18.6562 12.75 19 12.4375 19 12C19 11.5938 18.6562 11.25 18.25 11.25Z"
    fill="currentcolor"
  />
</svg>
`;
