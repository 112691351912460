import { FlagType, useFlag } from '~/flags';

import DEPRECATED_ManageClientsPage from './DEPRECATED_index';

const Page = () => {
  const enableManageClientsPageNew = useFlag(FlagType.ManageClientsPageNew);

  if (!enableManageClientsPageNew) {
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    return <DEPRECATED_ManageClientsPage />;
  }

  return <div>CONTENT TBD</div>;
};

export default Page;
