import React from 'react';

import { Chips, Layout, Text } from '~/eds';
import { Entity, EntityType } from '~/types';

type Props = {
  entity: Entity<EntityType>;
};

export const Footer = ({ entity }: Props) => (
  <Layout align="center" spacing={2}>
    <Text color="text.quiet" variant="tiny">
      Looking in:
    </Text>
    <Chips
      chips={[
        {
          text: 'This Document',
          disabled:
            entity.type !== 'document_handler' &&
            entity.type !== 'document_version',
        },
        {
          text: 'All Documents',
          disabled: entity.type !== 'all_documents',
        },
        { text: 'Document Group', disabled: true, tooltip: 'Coming Soon' },
      ]}
    />
  </Layout>
);
