import PT from 'prop-types';
import React, { useMemo, useState } from 'react';
import TreeView from 'react-accessible-treeview';

import styles from '../../styles';
import Box from '../Box';
import { folderShape } from '../Folder';
import {
  getDefaultGeneratedIds,
  getSelectedFolderData,
  prepareData,
  resolveNodeProps,
} from './bridge';
import FolderNode from './FolderNode';
import { setSubfolders } from './util';

const FolderTree = ({
  emptyContentMessage = 'No Folders',
  folders: initialFolders = [],
  isMulti,
  label,
  folderNodeStyle,
  value,
  onChange,
  asyncFetchSubfolders,
}) => {
  const [folders, setFolders] = useState(initialFolders);
  const data = useMemo(() => prepareData(folders), [folders]);

  if (initialFolders && initialFolders.length <= 0) {
    return emptyContentMessage;
  }

  const {
    defaultDisabledIds,
    defaultExpandedIds,
    defaultSelectedIds,
  } = getDefaultGeneratedIds({ data, value });

  const multiSelectProps = isMulti
    ? {
        multiSelect: true,
        propagateSelect: true,
        propagateSelectUpwards: true,
        togglableSelect: true,
      }
    : {};

  // there is a bug in react-accessible-treeview where the treeState does not honor updates to defaultDisabledIds.  Explicity update it when nodes are expanded
  const handleExpand = ({ treeState }) => {
    treeState.disabledIds = new Set(defaultDisabledIds);
  };

  const handleSelect = ({ treeState }) => {
    const { lastSelectedFolder, selectedIds } = getSelectedFolderData(
      treeState,
      data,
    );
    onChange(isMulti ? selectedIds : selectedIds[0], lastSelectedFolder);
  };

  const handleFetchSubfolders = async (currentFolder) => {
    const subfolders = await asyncFetchSubfolders(currentFolder);

    setFolders((prevFolders) =>
      setSubfolders({
        folders: prevFolders,
        currentFolder,
        subfolders,
      }),
    );

    return subfolders;
  };

  return (
    <Box sx={styles.layout.ulReset}>
      <TreeView
        {...multiSelectProps}
        aria-label={label}
        data={data}
        defaultDisabledIds={defaultDisabledIds}
        defaultExpandedIds={defaultExpandedIds}
        defaultSelectedIds={defaultSelectedIds}
        nodeRenderer={(nodeProps) => (
          <FolderNode
            {...resolveNodeProps(nodeProps)}
            isMulti={isMulti}
            style={folderNodeStyle}
            onFetchSubfolders={
              asyncFetchSubfolders ? handleFetchSubfolders : undefined
            }
          />
        )}
        onExpand={handleExpand}
        onSelect={handleSelect}
      />
    </Box>
  );
};

const folderIdPropType = PT.oneOfType([
  PT.string.isRequired,
  PT.number.isRequired,
]);

export const folderTreePropType = {
  /** Message if folder tree is empty */
  emptyContentMessage: PT.oneOfType([PT.string, PT.node]),
  /** An array of folder objects */
  folders: PT.arrayOf(PT.shape(folderShape).isRequired).isRequired,
  /** Additional styles on a folder node */
  folderNodeStyle: PT.object,
  /** When true, allows multiple selections of folders */
  isMulti: PT.bool,
  /** Label to identify the FolderTree */
  label: PT.string.isRequired,
  /** The selected folder ID(s).  Value signature depends on the "isMulti" prop */
  value: PT.oneOfType([folderIdPropType, PT.arrayOf(folderIdPropType)]),
  /** Callback to capture the selected folder ID(s).  The second parameter provides the currently selected folder object. */
  onChange: PT.func,
  /** An async function returning subfolders that will be appended and managed by the component */
  asyncFetchSubfolders: PT.func,
};

FolderTree.propTypes = folderTreePropType;

export default FolderTree;
