import PropTypes from 'prop-types';
import React from 'react';

import { evisortYellow } from '../../../assets/shared-styles/general';

const FolderLockIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 16">
      <path
        fill={evisortYellow}
        fillRule="nonzero"
        d="M8.05.5H2.2a2,2,0,0,0-1.94,2V14.28a2,2,0,0,0,2,2H17.8a2,2,0,0,0,1.95-2V4.44a2,2,0,0,0-1.95-2H10Z"
        transform="translate(-0.25 -0.5)"
      />
      <path
        fill="#333"
        d="M18.85,11.67a.52.52,0,0,0-.36-.14h-.17v-1a2.36,2.36,0,0,0-4-1.67,2.27,2.27,0,0,0-.7,1.67v1h-.17a.48.48,0,0,0-.5.5v3a.52.52,0,0,0,.14.36.49.49,0,0,0,.36.15h5.07a.51.51,0,0,0,.51-.51V12A.49.49,0,0,0,18.85,11.67Zm-1.54-.14h-2.7v-1A1.35,1.35,0,0,1,16,9.16a1.37,1.37,0,0,1,1.35,1.35Z"
        transform="translate(-0.25 -0.5)"
      />
    </svg>
  );
};

FolderLockIcon.defaultProps = {
  size: '24',
};

FolderLockIcon.propTypes = {
  size: PropTypes.string,
};

export default FolderLockIcon;
