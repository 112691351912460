import { isEmpty, values, without } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid';

import { mapValueObjectsToOptions } from '~/components/Shared/EcSelect/EcSelect.utils';
import {
  Filters_DEPRECATED,
  FiltersPreview,
} from '~/components/Shared/Filters_DEPRECATED';
import { Layout, Text } from '~/eds';
import {
  EnumValue,
  Field,
  FieldId,
  Filter,
  Filters as FiltersType,
} from '~/evifields';
import { api, selectors } from '~/redux';
import {
  PilotSearchQuery,
  testIsSupportedFilterType,
  toFilters,
} from '~/redux/api/methods';
import { DEFAULT_MORE_FILTER_FIELD_IDS } from '~/redux/api/transformers';
import automation from '~/redux/slices/automation';
import { DropdownFieldValue, Nullable } from '~/types';
import * as componentTypes from '~/types';
import { LoadingSpinner } from '~/ui';
import { debounce } from '~/utils/debounce';

import { flattenFolderTree } from '../util';

type FilterSerializerProps = {
  field: componentTypes.Filters.Field;
  values?: DropdownFieldValue[];
};

type Fields = Record<FieldId, Field>;
type FieldValues = { [fieldId: string]: DropdownFieldValue[] };

type FilterProps = { fields: Fields; fieldValues: FieldValues };

const updateSettings = (field: componentTypes.Filters.Field): Field => {
  switch (field.type) {
    case 'enum':
    case 'enum_set':
      break;

    default:
      return field;
  }

  const shouldEnableSelectAll = (filter: Filter<EnumValue>) => {
    return (
      field.type === 'enum_set' &&
      (filter.operatorId === 'contains_any' ||
        filter.operatorId === 'not_contains_any')
    );
  };

  field.settings = {
    ...field.settings,
    __testEnableSelectAll: shouldEnableSelectAll,
  };

  return field;
};

const attachRenderer = (field: componentTypes.Filters.Field): Field => {
  // only need to attach data source for single/multi select filters
  // maybe one day we will support suggestions for text and/or other filters?
  switch (field.type) {
    case 'enum':
    case 'enum_set':
    case 'folder':
      break;

    default:
      return field;
  }

  const AsyncFolderOptionsRenderer: componentTypes.Filters.FieldRendererProps = ({
    Component,
    innerProps,
  }) => {
    const [
      getFieldValues,
      result,
    ] = api.endpoints.getFolderFilterOptions.useLazyQuery();
    const debouncedGetFieldValues = useCallback(debounce(getFieldValues, 500), [
      getFieldValues,
    ]);
    const { data, error, isFetching } = result;
    const options = (data || []).map((value) => ({
      value: value.value,
      label: value.display_value,
      description: value.path,
      leftIcon: 'folder',
    }));
    const asyncOptions = { data: options, error, isFetching };
    const fetchOptions = (substringQuery = '') => {
      debouncedGetFieldValues({ substringQuery }, true);
    };

    const asyncProps = {
      asyncOptions,
      onMount: fetchOptions,
      onQuery: fetchOptions,
    };

    return <Component asyncProps={asyncProps} innerProps={innerProps} />;
  };

  const AsyncOptionsContainer: componentTypes.Filters.FieldRendererProps = ({
    Component,
    innerProps,
  }) => {
    const [
      getFieldValues,
      result,
    ] = api.endpoints.getFieldValues.useLazyQuery();
    const debouncedGetFieldValues = useCallback(debounce(getFieldValues, 300), [
      getFieldValues,
    ]);
    const { data, error, isFetching } = result;
    const options = mapValueObjectsToOptions(data?.values || []);
    const asyncOptions = { data: options, error, isFetching };
    const fetchOptions = (substringQuery = '') => {
      debouncedGetFieldValues(
        {
          fieldId: field.id,
          searchSuggestions: true,
          substringQuery,
        },
        true,
      );
    };
    const asyncProps = {
      asyncOptions,
      onMount: fetchOptions,
      onQuery: fetchOptions,
    };

    return <Component asyncProps={asyncProps} innerProps={innerProps} />;
  };

  if (field.type === 'folder') {
    field.render = AsyncFolderOptionsRenderer;
  } else {
    field.render = AsyncOptionsContainer;
  }

  return field;
};

const FIELDS_WITH_COMPLEX_VALUES: string[] = ['document_group_id', 'folder'];

const attachSerializer = ({ field, values }: FilterSerializerProps): Field => {
  if (!FIELDS_WITH_COMPLEX_VALUES.includes(field.id)) {
    return field;
  }

  const filterSerializer = (filter: Filter) => {
    return values?.find((value) => value.value === filter.values[0])
      ?.display_value;
  };

  field.serialize = filterSerializer;

  return field;
};

const attachRenderersAndSerializers = ({
  fields,
  fieldValues,
}: FilterProps): Fields => {
  const entries = Object.entries(fields)
    // objects created by RTKQ are immutable, make a shallow copy, so we can extend it later
    .map(([fieldId, field]): [FieldId, Field] => [fieldId, { ...field }])
    .map(([fieldId, field]): [FieldId, Field] => [
      fieldId,
      updateSettings(field),
    ])
    .map(([fieldId, field]): [FieldId, Field] => [
      fieldId,
      attachRenderer(field),
    ])
    .map(([fieldId, field]) => [
      fieldId,
      attachSerializer({ field, values: fieldValues[field.id] }),
    ]);

  return Object.fromEntries(entries);
};

const PreFetchFieldValues = memo(({ fieldId }: { fieldId: string }) => {
  const dispatch = useDispatch();

  const result = api.endpoints.getFieldValues.useQuery({
    fieldId,
    searchSuggestions: true,
    substringQuery: '',
  });

  useEffect(() => {
    if (result.data) {
      dispatch(
        automation.actions.setAutomationFieldValues({
          fieldId,
          values: result.data.values,
        }),
      );
    }
  }, [result]);

  return null;
});

const DataContainer = () => {
  const filters = useSelector(selectors.selectAutomationFilters);

  const filtersRequiringFieldValues = filters.filter((filter: any) =>
    FIELDS_WITH_COMPLEX_VALUES.includes(filter.fieldId),
  );

  return (
    <>
      {filtersRequiringFieldValues.map((filter: any) => {
        if (filter.fieldId === 'folder') {
          return null;
        } else {
          return (
            <PreFetchFieldValues key={filter.id} fieldId={filter.fieldId} />
          );
        }
      })}
    </>
  );
};

interface SearchProps {
  fields: Fields;
  groups: componentTypes.Filters.Group[];
  preview: Nullable<PilotSearchQuery>;
}

const SearchFilters = memo(
  ({ preview, fields = {}, groups = [] }: SearchProps) => {
    const dispatch = useDispatch();
    const setAutomationFilter = (filters: FiltersType) => {
      dispatch(automation.actions.setAutomationFilter(filters));
    };

    const buildFolderOption = (folderTree: DropdownFieldValue[]) =>
      folderTree?.map((item) => ({
        label: item.display_value,
        value: item.value,
      })) ?? [];

    const filters = useSelector(selectors.selectAutomationFilters);
    const fieldValues = useSelector(selectors.selectAutomationFieldValues);
    const clauses = useSelector(selectors.selectAutomationClauseSuggestions);
    const folderTree = useSelector(selectors.selectAutomationFolderTree);

    const fieldsWithLazyLoad = useMemo(() => {
      const fieldItems = attachRenderersAndSerializers({ fields, fieldValues });

      fieldItems.folder = {
        ...fieldItems.folder,
        settings: {
          __testEnableSelectAll: (_: Filter<EnumValue>) => false,
          options: buildFolderOption(folderTree),
        },

        render: isEmpty(preview) ? fieldItems.folder.render : undefined,
      };

      fieldItems.clause = {
        ...fieldItems.clause,
        settings: {
          clauses,
        },
      };

      return fieldItems;
    }, [clauses, fields, fieldValues, folderTree]);

    if (preview != null) {
      if (preview.length <= 0) return <Text>No Filter</Text>;

      const supportedFieldEntities = preview.filter((filter) =>
        testIsSupportedFilterType(filter),
      );
      const previewFilters = toFilters(supportedFieldEntities) as FiltersType;

      return (
        <FiltersPreview fields={fieldsWithLazyLoad} filters={previewFilters} />
      );
    }

    return (
      <>
        <DataContainer />
        {/* eslint-disable-next-line react/jsx-pascal-case -- deprecating */}
        <Filters_DEPRECATED
          fields={fieldsWithLazyLoad}
          filters={filters}
          groups={groups}
          onChange={setAutomationFilter}
          options={{
            disableManageFilters: false,
          }}
        />
      </>
    );
  },
);

const AutomationHubSearchingFilters = ({
  preview,
  training = false,
  prediction = false,
  retraining = false,
}: {
  preview: Nullable<PilotSearchQuery>;
  training: boolean;
  prediction: boolean;
  retraining: boolean;
}) => {
  const dispatch = useDispatch();

  const {
    data: sectionsAndFolders,
    isFetching: isFetchingSections,
  } = api.endpoints.getFilterSections.useQuery(
    without(DEFAULT_MORE_FILTER_FIELD_IDS, 'clause'),
  );

  const {
    data: clauseSuggestions,
  } = api.endpoints.getClauseSuggestions.useQuery(undefined);
  const { data: folderTreeData } = api.endpoints.getFolderTree.useQuery(
    undefined,
  );

  const fields = sectionsAndFolders?.fields;
  const fieldGroups = sectionsAndFolders?.fieldGroups;

  const modelFilters = useSelector(selectors.selectAutomationModelFilter);
  const predictions: PilotSearchQuery = modelFilters?.predictionFilter;
  const trainings: PilotSearchQuery = modelFilters?.trainingFilter;

  const folders = folderTreeData?.children;
  const folderTree = !folders ? [] : flattenFolderTree(folders);

  useEffect(() => {
    if (fields) dispatch(automation.actions.setAutomationFilterFields(fields));
  }, [fields]);

  useEffect(() => {
    if (folderTree.length)
      dispatch(automation.actions.setAutomationFilterFolderTree(folderTree));
  }, [folderTree]);

  useEffect(() => {
    if (clauseSuggestions)
      dispatch(
        automation.actions.setAutomationFilterClause(
          clauseSuggestions.suggestions,
        ),
      );
  }, [clauseSuggestions]);

  const ctf = values(fields).find((f) => f.label === 'Contract Type');

  if (prediction) {
    const supportedFieldEntities =
      predictions?.filter((filter) => testIsSupportedFilterType(filter)) ?? [];
    const filters = toFilters(supportedFieldEntities);

    dispatch(automation.actions.setAutomationFilter(filters as FiltersType));
  }

  if (retraining) {
    const supportedFieldEntities =
      trainings?.filter((filter) => testIsSupportedFilterType(filter)) ?? [];
    const filters = toFilters(supportedFieldEntities);

    dispatch(automation.actions.setAutomationFilter(filters as FiltersType));
  }

  if (training) {
    const filters = [
      { fieldId: ctf?.id, id: uuid.v4(), operatorId: null, values: [] },
      { fieldId: 'folder', id: uuid.v4(), operatorId: null, values: [] },
    ].filter((f) => !!f.fieldId);

    dispatch(automation.actions.setAutomationFilter(filters as FiltersType));
  }

  if (isFetchingSections || !fields || !fieldGroups) {
    return (
      <Layout w="100%" direction="column" align="center">
        <LoadingSpinner />
      </Layout>
    );
  }

  return (
    <SearchFilters preview={preview} fields={fields} groups={fieldGroups} />
  );
};

/* Prevent re-render when using filter on parent components */
export default React.memo(AutomationHubSearchingFilters);
