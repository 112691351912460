import React, { useEffect, useState } from 'react';

import { showToast } from '~/components/Shared/EcToast';
import { IconButton } from '~/eds';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Divider, FlexLayout, Link, LoadingContainer, Text } from '~/ui';
import { copyToClipboard } from '~/utils/helper.utils';

const buildInstructions = (externalId) =>
  [
    'Step 1) How to create the bucket access policy',
    '  1. Sign in to the AWS Management Console as an administrator of the Production account, and open the IAM console.',
    '  2. In the navigation pane on the left, choose Policies and then choose Create policy.',
    '  3. Choose the JSON tab and copy the text from the following JSON policy document. Paste this text into the JSON text box, replacing "EVISORT_SHARED_BUCKET_NAME" with the name of the bucket you will be sharing with Evisort.',
    ' ',
    '{',
    '  "Version": "2012-10-1<7",',
    '  "Statement": [',
    '    {',
    '      "Effect": "Allow",',
    '      "Action": "s3:ListAllMyBuckets",',
    '      "Resource": "*"',
    '    },',
    '    {',
    '      "Effect": "Allow",',
    '      "Action": [',
    '        "s3:ListBucket",',
    '        "s3:GetBucketLocation"',
    '      ],',
    '      "Resource": "arn:aws:s3:::EVISORT_SHARED_BUCKET_NAME',
    '    },',
    '    {',
    '      "Effect": "Allow"',
    '      "Action": [',
    '        "s3:GetObject",',
    '        "s3:PutObject",',
    '        "s3:DeleteObject"',
    '      ],',
    '      "Resource": "arn:aws:s3:::EVISORT_SHARED_BUCKET_NAME',
    '    }',
    '  ]',
    '}',
    ' ',
    '  4. Resolve any security warnings, errors, or general warnings generated during policy validation, and then choose Review policy.',
    '  5. On the Review page, type "read-write-evisort-bucket" for the policy name. Review the policy Summary to see the permissions granted by your policy, and then choose Create policy to save your work.',
    '',
    'Step 2) How to create the bucket access role',
    '  1. In the navigation pane of the IAM console, choose Roles and then choose Create role.',
    '  2. Choose the Another AWS account role type.',
    `  3. For Account ID, type "216611480031".  Your External ID is "${externalId}", and type the ID provided to you along with this document.`,
    '  4. Choose Next: Permissions to set the permissions that will be associated with the role.',
    '  5. Select the box next to the "read-write-evisort-bucket" policy that you created previously, then choose Next: Tags.',
    '  6. Choose Next: Review and type "EvisortSync" for the role name.  You must use this name, as our internal permissions system is checking for it.',
    '  7. After reviewing the role, choose "Create" role.',
    '',
    'Step 3) How to obtain the ARN of the bucket access role',
    '  1. In the navigation pane of the IAM console, choose Roles.',
    '  2. In the list of roles, choose the "EvisortSync" role.',
    '  3. In the Summary section of the details pane, copy the Role ARN value.',
    '  4. Provide the Role ARN value to the Evisort Admin, who will use it when setting up the Sync Pair.',
  ].join('\n');

function AmazonS3Instruction() {
  const AWS_MANAGEMENT_CONSOLE = 'https://aws.amazon.com/console/';

  const [doesShowInstructions, setDoesShowInstructions] = useState(true);

  const currentUser = useCurrentUser();

  const { data, error, isFetching } = api.endpoints.getExternalId.useQuery({
    clientId: currentUser.client,
  });

  const externalId = data?.externalId ?? '';

  useEffect(() => {
    if (error) {
      showToast(ERROR, 'Failed to fetch external ID. Please try again.');
    }
  }, [error]);

  const redirectToAWSManagementConsole = () => {
    window.open(AWS_MANAGEMENT_CONSOLE, '_blank');
  };

  return (
    <FlexLayout flexDirection="column" sx={{ width: '100%' }}>
      <FlexLayout pb={3}>
        <Text>Login your </Text>
        <Link onClick={() => redirectToAWSManagementConsole()}>
          AWS Management Console
        </Link>
      </FlexLayout>

      <FlexLayout
        flexDirection="column"
        sx={{ border: '#e5e5e5 solid 1px', borderRadius: '3px' }}
      >
        <FlexLayout
          alignItems="center"
          justifyContent="space-between"
          p={3}
          sx={{ width: '100%' }}
        >
          <FlexLayout alignItems="center" space={1}>
            <IconButton
              icon={doesShowInstructions ? 'chevron-up' : 'chevron-down'}
              onClick={() => setDoesShowInstructions(!doesShowInstructions)}
            />
            <Text>
              Instructions for AWS Admin to get access to source bucket
            </Text>
          </FlexLayout>
          <IconButton
            icon="clipboard"
            onClick={() =>
              copyToClipboard(
                buildInstructions(externalId),
                'Data copied to clipboard.',
                SUCCESS,
              )
            }
            tooltip="Copy to clipboard"
          />
        </FlexLayout>

        <Divider />

        <LoadingContainer isLoading={isFetching}>
          {doesShowInstructions && !isFetching && (
            <>
              <FlexLayout pl={3} pt={5}>
                <Text variant="subtitle">
                  Step 1) How to create the bucket access policy
                </Text>
              </FlexLayout>
              <FlexLayout flexDirection="column" space={3} mx={5} p={5} as="ol">
                <Text as="li" variant="xs-dense">
                  Sign in to the AWS Management Console as an administrator of
                  the Production account, and open the IAM console.
                </Text>
                <Text as="li" variant="xs-dense">
                  In the navigation pane on the left, choose Policies and then
                  choose Create policy.
                </Text>
                <Text as="li" variant="xs-dense">
                  Choose the JSON tab and copy the text from the following JSON
                  policy document. Paste this text into the JSON text box,
                  replacing "<strong>EVISORT_SHARED_BUCKET_NAME</strong>" with
                  the name of the bucket you will be sharing with Evisort.
                </Text>

                <FlexLayout bg="#f7f6f3" p={5}>
                  <Text variant="2xs-dense" color="gray-600">
                    &#123;
                    <br />
                    <Text ml={4} variant="2xs-dense" color="#8c1c54">
                      "Version"
                    </Text>
                    :&nbsp;
                    <Text variant="2xs-dense" color="#71972b">
                      "2012-10-17"
                    </Text>
                    ,<br />
                    <Text ml={4} variant="2xs-dense" color="#8c1c54">
                      "Statement"
                    </Text>
                    : [<br />
                    <Text ml={6} color="#9f9f9f">
                      &#123;
                    </Text>
                    <br />
                    <Text ml={10} variant="2xs-dense" color="#8c1c54">
                      "Effect"
                    </Text>
                    :&nbsp;
                    <Text variant="2xs-dense" color="#71972b">
                      "Allow"
                    </Text>
                    ,<br />
                    <Text ml={10} variant="2xs-dense" color="#8c1c54">
                      "Action"
                    </Text>
                    :&nbsp;
                    <Text variant="2xs-dense" color="#71972b">
                      "s3:ListAllMyBuckets"
                    </Text>
                    ,<br />
                    <Text ml={10} variant="2xs-dense" color="#8c1c54">
                      "Resource"
                    </Text>
                    : <Text color="#71972b">"*"</Text>
                    <br />
                    <Text ml={6} variant="2xs-dense" color="#9f9f9f">
                      &#125;
                    </Text>
                    ,<br />
                    <Text ml={6} variant="2xs-dense" color="#9f9f9f">
                      &#123;
                    </Text>
                    <br />
                    <Text ml={10} variant="2xs-dense" color="#8c1c54">
                      "Effect"
                    </Text>
                    :&nbsp;
                    <Text variant="2xs-dense" color="#71972b">
                      "Allow"
                    </Text>
                    ,<br />
                    <Text ml={10} variant="2xs-dense" color="#8c1c54">
                      "Action"
                    </Text>
                    : [<br />
                    <Text ml={12} variant="2xs-dense" color="#71972b">
                      "s3:ListBucket"
                    </Text>
                    ,<br />
                    <Text ml={12} variant="2xs-dense" color="#71972b">
                      "s3:GetBucketLocation"
                    </Text>
                    <br />
                    <Text ml={10} variant="2xs-dense" color="#gray-600">
                      ]
                    </Text>
                    ,<br />
                    <Text ml={10} variant="2xs-dense" color="#8c1c54">
                      "Resource"
                    </Text>
                    :
                    <Text variant="2xs-dense" color="#71972b">
                      &nbsp; "arn:aws:s3:::EVISORT_SHARED_BUCKET_NAME"
                    </Text>
                    <br />
                    <Text ml={6} variant="2xs-dense" color="#9f9f9f">
                      &#125;
                    </Text>
                    ,<br />
                    <Text ml={6} variant="2xs-dense" color="#9f9f9f">
                      &#123;
                    </Text>
                    <br />
                    <Text ml={8} variant="2xs-dense" color="#8c1c54">
                      "Effect"
                    </Text>
                    :&nbsp;
                    <Text variant="2xs-dense" color="#71972b">
                      "Allow"
                    </Text>
                    ,<br />
                    <Text ml={8} variant="2xs-dense" color="#8c1c54">
                      "Action"
                    </Text>
                    : [<br />
                    <Text ml={12} variant="2xs-dense" color="#71972b">
                      "s3:GetObject"
                    </Text>
                    ,<br />
                    <Text ml={12} variant="2xs-dense" color="#71972b">
                      "s3:PutObject"
                    </Text>
                    ,<br />
                    <Text ml={12} variant="2xs-dense" color="#71972b">
                      "s3:DeleteObject"
                    </Text>
                    <br />
                    <Text ml={10} variant="2xs-dense" color="#gray-600">
                      ]
                    </Text>
                    ,<br />
                    <Text ml={10} variant="2xs-dense" color="#8c1c54">
                      "Resource"
                    </Text>
                    :&nbsp;
                    <Text variant="2xs-dense" color="#71972b">
                      "arn:aws:s3:::EVISORT_SHARED_BUCKET_NAME/*"
                    </Text>
                    <br />
                    <Text ml={6} variant="2xs-dense" color="gray-600">
                      &#125;
                    </Text>
                    <br />
                    <Text ml={4} variant="2xs-dense" color="gray-600">
                      ]
                    </Text>
                    <br />
                    &#125;
                  </Text>
                </FlexLayout>

                <Text as="li" variant="xs-dense">
                  Resolve any security warnings, errors, or general warnings
                  generated during policy validation, and then choose Review
                  policy.
                </Text>
                <Text as="li" variant="xs-dense">
                  On the Review page, type "
                  <strong>read-write-evisort-bucket</strong>" for the policy
                  name. Review the policy Summary to see the permissions granted
                  by your policy, and then choose Create policy to save your
                  work.
                </Text>
              </FlexLayout>

              <Divider />

              <FlexLayout pl={3} pt={5}>
                <Text variant="subtitle">
                  Step 2) How to create the bucket access role
                </Text>
              </FlexLayout>
              <FlexLayout flexDirection="column" space={3} mx={5} p={5} as="ol">
                <Text as="li" variant="xs-dense">
                  In the navigation pane of the IAM console, choose Roles and
                  then choose Create role.
                </Text>
                <Text as="li" variant="xs-dense">
                  Choose the Another AWS account role type.
                </Text>

                <Text as="li" variant="xs-dense">
                  For <strong>Account ID</strong>, type "
                  <strong>216611480031</strong>". Your&nbsp;
                  <strong>External ID</strong> is "<strong>{externalId}</strong>
                  ", and type the ID provided to you along with this document.
                </Text>

                <Text as="li" variant="xs-dense">
                  Choose Next: Permissions to set the permissions that will be
                  associated with the role.
                </Text>
                <Text as="li" variant="xs-dense">
                  Select the box next to the "
                  <strong>read-write-evisort-bucket</strong>" policy that you
                  created previously, then choose Next: Tags.
                </Text>
                <Text as="li" variant="xs-dense">
                  Choose Next: Review and type "<strong>EvisortSync</strong>"
                  for the role name. You must use this name, as our internal
                  permissions system is checking for it.
                </Text>
                <Text as="li" variant="xs-dense">
                  After reviewing the role, choose "<strong>Create</strong>"
                  role.
                </Text>
              </FlexLayout>

              <Divider />

              <FlexLayout pl={3} pt={5}>
                <Text variant="subtitle">
                  Step 3) How to obtain the ARN of the bucket access role
                </Text>
              </FlexLayout>
              <FlexLayout flexDirection="column" space={3} mx={5} p={5} as="ol">
                <Text as="li" variant="xs-dense">
                  In the navigation pane of the IAM console, choose Roles.
                </Text>
                <Text as="li" variant="xs-dense">
                  In the list of roles, choose the "<strong>EvisortSync</strong>
                  " role.
                </Text>
                <Text as="li" variant="xs-dense">
                  In the Summary section of the details pane, copy the Role ARN
                  value.
                </Text>
                <Text as="li" variant="xs-dense">
                  Provide the Role ARN value to the Evisort Admin, who will use
                  it when setting up the Sync Pair.
                </Text>
              </FlexLayout>
            </>
          )}
        </LoadingContainer>
      </FlexLayout>
    </FlexLayout>
  );
}

export default AmazonS3Instruction;
