export default `
<svg
  aria-hidden="true"
  aria-label="message"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M15.2812 8.54749H8.78125C8.34375 8.54749 8.03125 8.89124 8.03125 9.29749C8.03125 9.73499 8.34375 10.0475 8.78125 10.0475H15.2812C15.6875 10.0475 16.0312 9.73499 16.0312 9.29749C16.0312 8.89124 15.6875 8.54749 15.2812 8.54749ZM12.2812 11.5475H8.78125C8.34375 11.5475 8.03125 11.8912 8.03125 12.2975C8.03125 12.735 8.34375 13.0475 8.78125 13.0475H12.2812C12.6875 13.0475 13.0312 12.735 13.0312 12.2975C13.0312 11.8912 12.6875 11.5475 12.2812 11.5475ZM18 4.04749H6C4.875 4.04749 4 4.95374 4 6.04749V15.0162C4 16.11 4.875 16.985 6 16.985H9V19.5787C9 19.8912 9.34375 20.0475 9.59375 19.8912L13.5 16.9537H18C19.0938 16.9537 20 16.0475 20 14.985V6.04749C20 4.95374 19.125 4.04749 18 4.04749ZM18.5312 15.0475C18.5312 15.3287 18.2812 15.5475 18.0312 15.5475H13.0312L10.5312 17.4225V15.5475H6.03125C5.75 15.5475 5.53125 15.3287 5.53125 15.0475V6.04749C5.53125 5.79749 5.75 5.54749 6.03125 5.54749H18.0312C18.2812 5.54749 18.5312 5.79749 18.5312 6.04749V15.0475Z"
    fill="currentcolor"
  />
</svg>
`;
