import { NavLink } from '../../types';

/**
 * Return the 1-based index of an array of width where the cummulative sum of width values is still bounded by the targetWidth.
 */
export const getBoundedWidthIndex = (
  targetWidth: number,
  widths: number[],
  offsetWidth = 200, // static offset width accounting for configurable UI whitespace (simpler to specify than to derive dynamically from the DOM)
) => {
  return widths.reduce(
    ({ index, totalWidth }, width) => {
      totalWidth += width;
      if (totalWidth <= targetWidth) {
        index++;
      }
      return { index, totalWidth };
    },
    {
      index: 0,
      totalWidth: offsetWidth,
    },
  ).index;
};

/**
 * Predicate to test if a link is active.
 *
 * Return true if link.pathname matches activePathname.
 * Otherwise check if any link.children matches activePathname.
 */
export const testIsActiveLink = (activePathname: string) => (link: NavLink) => {
  const matchesPathname = link.pathname === activePathname;
  const matchesActivePatterns = Boolean(
    link.activePatterns?.some((pattern) =>
      new RegExp(pattern).test(activePathname),
    ),
  );
  const matchesChildrenPathname = Boolean(
    link.children?.some(testIsActiveLink(activePathname)),
  );
  return matchesActivePatterns || matchesChildrenPathname || matchesPathname;
};
