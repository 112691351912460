import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import { showToast } from '~/components/Shared/EcToast';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { Divider, FormField, Layout, Section, TextInput } from '~/eds';
import {
  AribaApiType,
  AribaIntegrationType,
  ManageSyncPairStageType,
} from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { ERROR, SUCCESS } from '~/types/toast.types';

import { API_NAMES, API_VERSIONS } from '../../constants';
import AribaFooter from '../AribaFooter';
import Realm from './Realm';

function AribaMultiAPISetup({
  // connected,
  activeSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const aribaPhase3 = useFlag(FlagType.AribaIntegrationPhase3);
  const usingOneTimeImportFlag = useFlag(FlagType.FieldMappingOneTimeImport);
  const isTokenReauthenticate = !!activeSyncPair?.isTokenReauthenticate;
  const { provider, syncType, id } = activeSyncPair;
  const stageProperties = activeSyncPair[ManageSyncPairStageType.Realm] ?? {};
  const providerOptions = activeSyncPair.providerOptions ?? {};
  const { realm, location } = isTokenReauthenticate
    ? providerOptions
    : stageProperties;
  const { errors: realmErrors } = stageProperties ?? {};
  let sectionIndex = 1;
  const getCredentialsApi = (apiType) => {
    const tokens = providerOptions.tokens ?? [];
    for (const token of tokens) {
      if (token.tokenType === apiType) {
        return token.apiKey;
      }
    }
    return '';
  };
  const extractApiCredentials = activeSyncPair[AribaApiType.EXTRACT] ?? {
    apiKey: providerOptions.apiKey ?? '',
    oauthClientId: '',
    oauthClientSecret: '',
  };
  const discoveryApiCredentials = activeSyncPair[AribaApiType.DISCOVERY] ?? {
    apiKey: getCredentialsApi(API_NAMES[AribaApiType.DISCOVERY]),
    oauthClientId: '',
    oauthClientSecret: '',
  };
  const retrievalApiCredentials = activeSyncPair[AribaApiType.RETRIEVAL] ?? {
    apiKey: getCredentialsApi(API_NAMES[AribaApiType.RETRIEVAL]),
    oauthClientId: '',
    oauthClientSecret: '',
  };
  const authenticateErrorMessage =
    'Authentication error due to incorrect input value(s). Check and refill the input value(s) and try again.';

  const tokenErrorMessage =
    'Check and refill the input value(s) and try again.';

  const [
    createAribaMultiTokens,
    createMultiTokenResult,
  ] = api.endpoints.createAribaMultiTokens.useMutation();

  const [
    updateTokensSyncPair,
    updateTokensSyncPairResult,
  ] = api.endpoints.updateSyncPairTokens.useMutation();

  const {
    data: tokenMultiData,
    isLoading: isLoadingMulti,
    isError: isErrorMulti,
    error,
  } = createMultiTokenResult;
  const {
    data: updatedData,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: updateError,
  } = updateTokensSyncPairResult;

  const getSectionIndex = () => {
    return sectionIndex++;
  };

  const handleBack = () => {
    if (usingOneTimeImportFlag) {
      integrationsSetActiveSyncPair(null);
      integrationsSetManageSyncPairStage(ManageSyncPairStageType.None);
    } else {
      integrationsSetManageSyncPairStage(ManageSyncPairStageType.FileConfig);
    }
  };
  const handleNext = () => {
    if (usingOneTimeImportFlag) {
      integrationsSetManageSyncPairStage(ManageSyncPairStageType.FileConfig);
    } else {
      integrationsSetManageSyncPairStage(ManageSyncPairStageType.FolderAriba);
    }
  };

  const setExtractApiCredentials = (key, value) => {
    const updatedValues = {
      ...extractApiCredentials,
      [key]: value,
    };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [AribaApiType.EXTRACT]: updatedValues,
    });
  };

  const setDiscoveryApiCredentials = (key, value) => {
    const updatedValues = { ...discoveryApiCredentials, [key]: value };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [AribaApiType.DISCOVERY]: updatedValues,
    });
  };

  const setRetrievalApiCredentials = (key, value) => {
    const updatedValues = { ...retrievalApiCredentials, [key]: value };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [AribaApiType.RETRIEVAL]: updatedValues,
    });
  };

  useEffect(() => {
    if (isErrorMulti || isErrorUpdate) {
      const errors = updateError ?? error;
      showToast(ERROR, authenticateErrorMessage);
      integrationsSetActiveSyncPair({
        ...activeSyncPair,
        [ManageSyncPairStageType.Realm]: {
          ...activeSyncPair[ManageSyncPairStageType.Realm],
          errors: errors?.response?.data,
        },
      });
    }
  }, [isErrorMulti, isErrorUpdate]);

  const getError = (apiType) => {
    if (isErrorMulti | isErrorUpdate) {
      const errorResponse = updateError ?? error;
      const errorData = errorResponse?.response?.data?.[apiType];
      if (errorData && 'error' in errorData) {
        return errorData.error || tokenErrorMessage;
      }
    }
    return null;
  };

  function hasNull(target) {
    for (var member in target) {
      if (target[member] === '') return true;
    }
    return false;
  }

  const isDisabled = () => {
    if (
      realmErrors &&
      (realmErrors[API_NAMES[AribaApiType.REALM]]?.error ||
        realmErrors[API_NAMES[AribaApiType.REGION]]?.error)
    ) {
      return true;
    }

    if (!realm || !location) {
      return true;
    }

    if (usingOneTimeImportFlag) {
      return (
        hasNull(extractApiCredentials) ||
        hasNull(discoveryApiCredentials) ||
        hasNull(retrievalApiCredentials)
      );
    }

    if (syncType === AribaIntegrationType.CONTINUOUS) {
      if (aribaPhase3) {
        return (
          hasNull(extractApiCredentials) ||
          hasNull(discoveryApiCredentials) ||
          hasNull(retrievalApiCredentials)
        );
      } else {
        return (
          hasNull(extractApiCredentials) || hasNull(discoveryApiCredentials)
        );
      }
    } else {
      return hasNull(extractApiCredentials);
    }
  };

  const handleCreateMultiToken = useCallback(() => {
    const tokens = [
      {
        ...extractApiCredentials,
        apiType: API_NAMES[AribaApiType.EXTRACT],
        location,
        version: API_VERSIONS[AribaApiType.EXTRACT][0],
      },
      {
        ...discoveryApiCredentials,
        apiType: API_NAMES[AribaApiType.DISCOVERY],
        location,
        version: API_VERSIONS[AribaApiType.DISCOVERY][0],
      },
      {
        ...retrievalApiCredentials,
        apiType: API_NAMES[AribaApiType.RETRIEVAL],
        location,
        version: API_VERSIONS[AribaApiType.RETRIEVAL][0],
      },
    ];

    if (isTokenReauthenticate) {
      updateTokensSyncPair({ id, tokens, realm, location });
    } else {
      createAribaMultiTokens({ credentials: tokens, realm, location });
    }
  }, [
    location,
    realm,
    extractApiCredentials,
    discoveryApiCredentials,
    retrievalApiCredentials,
  ]);

  useEffect(() => {
    if (!tokenMultiData) {
      return;
    }
    showToast(SUCCESS, 'Authentication success');
    const syncPairId = tokenMultiData.syncPair;
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      syncPair: syncPairId,
    });
    handleNext();
  }, [tokenMultiData]);

  useEffect(() => {
    if (!updatedData) {
      return;
    }
    if (!isErrorUpdate) {
      showToast(SUCCESS, 'Authentication success');
      integrationsSetManageSyncPairStage(ManageSyncPairStageType.None);
    }
  }, [updatedData]);

  return (
    <Layout align="center" direction="column" spacing={8} w="100%">
      <Layout align="center">
        <ProviderLogo provider={provider} />
      </Layout>
      <Divider />
      <Layout preset="sections" spacing={8} w="60%">
        {usingOneTimeImportFlag && (
          <Section title={`${getSectionIndex()}. Ariba Realm Data`}>
            <Realm />
          </Section>
        )}
        <Section title={`${getSectionIndex()}. External Approval API`}>
          <Layout preset="subsections">
            <FormField
              label="API Key"
              name="API Key"
              placeholder="API Key value"
              input={TextInput}
              value={extractApiCredentials['apiKey'] ?? ''}
              onChange={(value) => setExtractApiCredentials('apiKey', value)}
              error={getError(API_NAMES[AribaApiType.EXTRACT])}
            />
            <FormField
              label="OAuth Client ID"
              name="OAuth Client ID"
              placeholder="Client ID value"
              input={TextInput}
              value={extractApiCredentials['oauthClientId'] ?? ''}
              onChange={(value) =>
                setExtractApiCredentials('oauthClientId', value)
              }
              error={getError(API_NAMES[AribaApiType.EXTRACT])}
            />
            <FormField
              label="OAuth Client Secret"
              name="OAuth Client Secret"
              placeholder="Client Secret value"
              input={TextInput}
              value={extractApiCredentials['oauthClientSecret'] ?? ''}
              onChange={(value) =>
                setExtractApiCredentials('oauthClientSecret', value)
              }
              error={getError(API_NAMES[AribaApiType.EXTRACT])}
            />
          </Layout>
        </Section>
        {(usingOneTimeImportFlag ||
          syncType === AribaIntegrationType.CONTINUOUS) && (
          <Section
            title={`${getSectionIndex()}. Operational Reporting API - Asynchronous`}
          >
            <Layout preset="subsections">
              <FormField
                label="API Key"
                placeholder="API Key value"
                input={TextInput}
                value={discoveryApiCredentials['apiKey'] ?? ''}
                onChange={(value) =>
                  setDiscoveryApiCredentials('apiKey', value)
                }
                error={getError(API_NAMES[AribaApiType.DISCOVERY])}
              />
              <FormField
                label="OAuth Client ID"
                placeholder="Client ID value"
                input={TextInput}
                value={discoveryApiCredentials['oauthClientId'] ?? ''}
                onChange={(value) =>
                  setDiscoveryApiCredentials('oauthClientId', value)
                }
                error={getError(API_NAMES[AribaApiType.DISCOVERY])}
              />
              <FormField
                label="OAuth Client Secret"
                placeholder="Client Secret value"
                input={TextInput}
                value={discoveryApiCredentials['oauthClientSecret'] ?? ''}
                onChange={(value) =>
                  setDiscoveryApiCredentials('oauthClientSecret', value)
                }
                error={getError(API_NAMES[AribaApiType.DISCOVERY])}
              />
            </Layout>
          </Section>
        )}
        {(usingOneTimeImportFlag ||
          (syncType === AribaIntegrationType.CONTINUOUS && aribaPhase3)) && (
          <Section
            title={`${getSectionIndex()}. Strategic Contract Workspace API`}
          >
            <Layout preset="subsections">
              <FormField
                label="API Key"
                placeholder="API Key value"
                input={TextInput}
                value={retrievalApiCredentials['apiKey'] ?? ''}
                onChange={(value) =>
                  setRetrievalApiCredentials('apiKey', value)
                }
                error={getError(API_NAMES[AribaApiType.RETRIEVAL])}
              />
              <FormField
                label="OAuth Client ID"
                placeholder="Client ID value"
                input={TextInput}
                value={retrievalApiCredentials['oauthClientId'] ?? ''}
                onChange={(value) =>
                  setRetrievalApiCredentials('oauthClientId', value)
                }
                error={getError(API_NAMES[AribaApiType.RETRIEVAL])}
              />
              <FormField
                label="OAuth Client Secret"
                placeholder="Client Secret value"
                input={TextInput}
                value={retrievalApiCredentials['oauthClientSecret'] ?? ''}
                onChange={(value) =>
                  setRetrievalApiCredentials('oauthClientSecret', value)
                }
                error={getError(API_NAMES[AribaApiType.RETRIEVAL])}
              />
            </Layout>
          </Section>
        )}
      </Layout>
      <AribaFooter
        secondaryAction={{
          text: usingOneTimeImportFlag ? 'Cancel' : 'Back',
          onClick: handleBack,
        }}
        mainAction={{
          text: isTokenReauthenticate ? 'Reauthenticate' : 'Authenticate',
          tooltip:
            isLoadingMulti || isLoadingUpdate
              ? 'System is processing authentication request. This may take a few seconds.'
              : '',
          onClick: handleCreateMultiToken,
          isLoading: isLoadingMulti || isLoadingUpdate,
          disabled: isDisabled(),
        }}
      />
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
});

export default connect(mapStateToProps, {
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(AribaMultiAPISetup);
