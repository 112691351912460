import { uniqBy } from 'lodash';

import { Select, useDebouncedState } from '~/eds';
import { api } from '~/redux';
import { Nullable } from '~/types';

import { useResolveQuestionGroups } from '../hooks';

interface QuestionGroupSelectProps {
  name: string;
  value: Nullable<string[]>;
  onChange: (value: Nullable<string[]>) => void;
}
export const QuestionGroupSelect = ({
  value,
  ...rest
}: QuestionGroupSelectProps) => {
  const [debouncedSearch, _search, setDebouncedSearch] = useDebouncedState('');

  const {
    data: searchQuestionGroups = [],
    isFetching,
  } = api.endpoints.getQuestionGroups.useQuery({
    search: debouncedSearch,
    pageSize: 0,
    pageLimit: 10,
  });

  const questionGroupIds = value ?? [];

  const {
    questionGroups: resolvedQuestionGroups = [],
    isFetching: isFetchingResolvedQuestionGroups,
  } = useResolveQuestionGroups({
    questionGroupIds,
    skip: !questionGroupIds.length,
  });

  const questionGroups = uniqBy(
    [...resolvedQuestionGroups, ...searchQuestionGroups],
    'id',
  );
  return (
    <Select
      {...rest}
      isMulti
      enableSelectAll={false}
      isLoading={isFetching || isFetchingResolvedQuestionGroups}
      value={value}
      onSearch={setDebouncedSearch}
      options={questionGroups.map((questionGroups) => ({
        value: questionGroups.id,
        label: questionGroups.name,
        description: questionGroups.description,
      }))}
    />
  );
};
