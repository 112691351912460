import React from 'react';

import { Layout } from '../../Layout';

interface Props {
  /** Children */
  children: React.ReactNode;
}

export const BaseFooter = ({ children }: Props) => {
  // TODO: Replace with DropdownActionsFooter
  return (
    <Layout align="center" borderTop="border.divider" px={4} py={2}>
      {children}
    </Layout>
  );
};
