import { maxBy, throttle } from 'lodash';
import React, { useEffect } from 'react';

import { Box, IconButton } from '~/eds';
import { useStateAndRef } from '~/hooks';

import { useDocumentEditorContext } from './DocumentEditorContext';
import { Variant } from './types';

const VIEWER_RESIZER_THROTTLE = 100;

export interface IHighlight {
  id: string;
  rects: HighlightRects[];
  variant: Variant;
  isVisible: boolean;
}

export interface HighlightRects {
  width: number;
  height: number;
  x: number;
  y: number;
}

const COLOR: Record<Variant, string> = {
  clause: 'rgba(182, 162, 246, 0.35)',
  risk: 'rgba(182, 162, 246, 0.35)',
  comment: 'rgba(0, 238, 253, 0.35)',
  revision: 'rgba(0, 238, 253, 0.35)',
  source: 'rgba(182, 162, 246, 0.35)',
};

interface HighlightProps {
  /**
   * The bookmark in the document that will be highlighted
   */
  highlightRects: HighlightRects[];
  /**
   * If the highlight is active and should be visible
   */
  isActive: boolean;
  /**
   * The variant of the highlight
   */
  variant: Variant;
  /**
   * A callback to be used when clicking in the button to side of the highlight
   * @param bookmarkId the clicked bookmark id
   */
  onClick?: () => void;
}

export const Highlight = ({
  isActive,
  onClick,
  highlightRects,
  variant,
}: HighlightProps) => {
  const { documentEditor } = useDocumentEditorContext();
  const [
    _viewerClientWidth,
    setViewerClientWidth,
    viewerClientWidthRef,
  ] = useStateAndRef(0);

  /**
   * The viewerContainer shrinks when opening the sidepanel and it's not monitored by documentEditor,
   * nor has any callback. We need to watch for this resize to be able to position the highlight correctly
   */
  useEffect(() => {
    const element = documentEditor?.documentHelper?.viewerContainer;

    if (element) {
      const resize = throttle((entries) => {
        if (!entries || entries.length === 0) {
          return;
        }

        if (element.clientWidth !== viewerClientWidthRef.current) {
          setViewerClientWidth(element.clientWidth);
        }
      }, VIEWER_RESIZER_THROTTLE);

      const resizeObserver = new ResizeObserver(resize);
      resizeObserver.observe(element);

      return () => resizeObserver.unobserve(element);
    }
  }, []);

  if (!documentEditor) return null;

  const navigationButtonLeftPosition = maxBy(highlightRects, 'width') || {
    width: 0,
    x: 0,
  };

  return (
    <Box>
      {highlightRects.map((rect, index) => (
        <Box
          key={`${variant}_${index}`}
          h={rect.height}
          w={rect.width}
          display={isActive ? 'block' : 'none'}
          styles={{
            position: 'absolute',
            backgroundColor: COLOR[variant],
            top: rect.y,
            left: rect.x,
            pointerEvents: 'none',
          }}
        />
      ))}

      {onClick && (
        <Box
          styles={{
            position: 'absolute',
            top: highlightRects[0].y, // position the top at the first rect Y position
            left:
              navigationButtonLeftPosition?.width +
              navigationButtonLeftPosition?.x, // position the left at the biggest width
          }}
        >
          <IconButton icon="field-clause" onClick={onClick} />
        </Box>
      )}
    </Box>
  );
};
