import React from 'react';
import { useStyles } from 'uinix-ui';

import { Icon, Image, Layout, Menu, Text, Tooltip } from '../../components';
import { IconType, Option } from '../../types';

interface Props {
  tooltip: string;
  icon?: IconType;
  id?: string;
  isBordered?: boolean;
  menu?: {
    name: string;
    options: Option[];
    offsetX?: number;
    offsetY?: number;
  };
  mode?: 'icon' | 'image';
  text?: React.ReactNode;
  imgSrc?: string;
  onClick?: () => void;
}

// This component is specifically designed and does not fall under any existing EDS pattern.  Abstract it once the patterns are confirmed.
export const NavPill = ({
  isBordered,
  icon,
  id,
  menu,
  mode,
  text,
  tooltip,
  imgSrc,
  onClick,
}: Props) => {
  const styles = useStyles();

  const isIconOnly = mode === 'icon';
  const isImageOnly = mode === 'image';
  // We need to check if the image is viable because we don't want to render an unsigned url.
  const isImageViable = Boolean(imgSrc);

  let content = (
    <Layout
      id={id}
      align="center"
      aria-label={isIconOnly ? text : undefined}
      as="button"
      justify="center"
      spacing={1}
      styles={[
        styles.interactionsInverse,
        componentStyles.pill({ isBordered, isImageOnly }),
      ]}
      onClick={onClick}
      title={menu ? tooltip : undefined}
    >
      {icon && <Icon color="inherit" icon={icon} />}
      {isImageViable && (
        <Image
          src={imgSrc}
          width="35px"
          height="35px"
          borderRadius="50%"
          alt="avatar image"
        ></Image>
      )}
      {!isIconOnly && !(isImageOnly && isImageViable) && (
        <Text color="inherit">{text}</Text>
      )}
    </Layout>
  );

  if (tooltip && !menu) {
    content = <Tooltip tooltip={tooltip}>{content}</Tooltip>;
  }

  if (menu) {
    return <Menu {...menu} enablePortal={true} trigger={content} />;
  }

  return content;
};

export const componentStyles = {
  pill: ({
    isBordered,
    isImageOnly,
  }: {
    isBordered?: boolean;
    isImageOnly?: boolean;
  }) => ({
    backgroundColor: 'inverse.background',
    border: isBordered ? 'border' : 'none',
    borderColor: 'inverse.border',
    borderRadius: 'pill',
    borderWidth: 'm',
    color: 'inverse.text.primary',
    cursor: 'pointer',
    height: 'height.m',
    minWidth: 'height.m',
    paddingLeft: isImageOnly ? 0 : 2,
    paddingRight: isImageOnly ? 0 : 2,
  }),
};
