import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.Parties, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.Parties as TagType],
  endpoints: {
    getParties: {
      providesTags: [TAG_BY_LIST],
    },
    addParty: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deleteParty: {
      invalidatesTags: [TAG_BY_LIST],
    },
    updateParty: {
      invalidatesTags: [TAG_BY_LIST],
    },
    uploadParty: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deleteParties: {
      invalidatesTags: [TAG_BY_LIST],
    },
  },
});
