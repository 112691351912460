export default `
<svg
  aria-hidden="true"
  aria-label="chevron-right"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M9.68167 5.51572L15.4004 11.4845C15.5254 11.6407 15.6192 11.8282 15.6192 12.0157C15.6192 12.2032 15.5254 12.3907 15.4004 12.5157L9.68167 18.4845C9.40042 18.797 8.90042 18.797 8.61917 18.5157C8.30667 18.2345 8.30667 17.7657 8.58792 17.4532L13.8379 11.9845L8.58792 6.54697C8.30667 6.26572 8.30667 5.76572 8.61917 5.48447C8.90042 5.20322 9.40042 5.20322 9.68167 5.51572Z"
    fill="currentcolor"
  />
</svg>
`;
