export default `
<svg
  aria-hidden="true"
  aria-label="alert-off"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M7.73425 7.70837C8.453 6.58337 9.6405 5.77087 11.0155 5.55212V4.98962C11.0155 4.45837 11.453 3.98962 12.0155 3.98962C12.5468 3.98962 13.0155 4.45837 13.0155 4.98962V5.55212C15.2655 5.92712 17.0155 7.89587 17.0155 10.2396V11.3021C17.0155 12.7084 17.4843 14.0834 18.3593 15.2084L18.828 15.7709C18.9843 15.9896 19.0468 16.2709 18.953 16.4896L21.703 18.6771C22.0468 18.9271 22.1093 19.3959 21.828 19.7084C21.578 20.0521 21.1093 20.1146 20.7968 19.8334L2.29675 5.33337C1.953 5.08337 1.8905 4.61462 2.17175 4.30212C2.42175 3.95837 2.8905 3.89587 3.203 4.17712L7.73425 7.70837ZM8.92175 8.64587L16.078 14.2396C15.703 13.3334 15.5155 12.3334 15.5155 11.3021V10.2396C15.5155 8.45837 14.0468 6.98962 12.2655 6.98962H11.7655C10.5468 6.98962 9.48425 7.67712 8.92175 8.64587ZM7.0155 11.3021V10.9584L8.453 12.0834C8.328 13.3021 7.92175 14.4896 7.2655 15.4896H12.7968L14.703 16.9896H5.7655C5.453 16.9896 5.203 16.8334 5.078 16.5834C4.953 16.3334 4.98425 16.0209 5.17175 15.7709L5.6405 15.2084C6.5155 14.0834 7.0155 12.7084 7.0155 11.3021ZM14.0155 17.9896C14.0155 18.5209 13.7968 19.0521 13.4218 19.4271C13.0468 19.8021 12.5155 19.9896 12.0155 19.9896C11.4843 19.9896 10.953 19.8021 10.578 19.4271C10.203 19.0521 10.0155 18.5209 10.0155 17.9896H14.0155Z"
    fill="currentcolor"
  />
</svg>
`;
