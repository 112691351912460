import { black2, semibold } from '~/assets/shared-styles/general';

const inputLabel = {
  display: 'block',
  fontSize: '15px',
  fontWeight: semibold,
  textAlign: 'left',
};

const styles = {
  inputContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  inputLabel: {
    ...inputLabel,
  },
  inputLabelDisabled: {
    ...inputLabel,
    opacity: 0.5,
  },
  textInput: {
    margin: '10px 0',
    padding: '10px',
    width: '360px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    color: black2,
    fontSize: '16px',
    '&:placeholder': {
      opacity: 0.5,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  inputShowHideButton: {
    padding: '6px 8px',
    position: 'absolute',
    top: '18px',
    right: '-55px',
    color: black2,
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '11px',
    cursor: 'pointer',
    '&:active': {
      outline: 'none',
    },
    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
      cursor: 'default',
    },
  },
};

export default styles;
