export default `<svg
  aria-hidden="true"
  aria-label="chatbot"
  fill="none"
  x="0px"
  y="0px"
  viewBox="0 0 40 40"
  style="enable-background: new 0 0 40 40"
  xml:space="preserve"
>
  <style type="text/css">
    .st0 {
      display: none;
    }
    .st1 {
      display: inline;
    }
    .st2 {
      opacity: 0.1;
    }
    .st3 {
      fill: #ff0000;
    }
  </style>
  <g id="Layer_2" class="st0">
    <g class="st1">
      <g class="st2">
        <rect x="5.4" class="st3" width="29.3" height="40" />
      </g>
      <g class="st2">
        <rect x="0" y="5.4" class="st3" width="40" height="29.3" />
      </g>
    </g>
  </g>
  <g id="Layer_1">
    <g>
      <path
        fill="currentcolor"
        d="M31.2,23.5L27,21.9l-1.6-4.2c-0.1-0.3-0.4-0.6-0.8-0.6c-0.4,0-0.7,0.2-0.8,0.6l-1.6,4.2l-4.2,1.6
		c-0.3,0.1-0.6,0.4-0.6,0.8c0,0.4,0.2,0.7,0.6,0.8l4.2,1.6l1.6,4.2c0.1,0.3,0.4,0.6,0.8,0.6c0.4,0,0.7-0.2,0.8-0.6l1.6-4.2l4.2-1.6
		c0.3-0.1,0.6-0.4,0.6-0.8C31.7,24,31.5,23.6,31.2,23.5z"
      />
      <path
        fill="currentcolor"
        d="M36.4,16l-2.8-1l-1-2.8c-0.1-0.2-0.3-0.4-0.5-0.4c-0.2,0-0.4,0.1-0.5,0.4l-1,2.8l-2.8,1c-0.2,0.1-0.3,0.3-0.3,0.5
		c0,0.2,0.1,0.4,0.3,0.5l2.8,1l1,2.8c0.1,0.2,0.3,0.4,0.5,0.4c0.2,0,0.4-0.1,0.5-0.4l1-2.8l2.8-1c0.2-0.1,0.4-0.3,0.4-0.5
		C36.8,16.3,36.6,16,36.4,16z"
      />
      <path
        fill="currentcolor"
        d="M21.2,29.3c-0.2-0.6-0.8-1-1.4-0.9c-0.5,0-0.9,0.1-1.4,0.1c-1.6,0-3.1-0.2-4.6-0.7l-1.3-0.4l-1.2,0.8
		c-0.8,0.6-2,1.3-3.4,1.7c0.4-0.7,0.8-1.5,1.2-2.4l0.3-0.8c0.2-0.5,0.1-1.1-0.3-1.5l-0.6-0.7c-1.1-1.1-2.3-3-2.3-5.5
		c0-5.2,5.5-9.5,12.3-9.5c3.4,0,6.5,1.1,8.8,2.8c0.7,0.5,1.7,0.4,2.1-0.4l0,0c0.4-0.6,0.2-1.4-0.3-1.8c-2.7-2.2-6.4-3.5-10.5-3.5
		C10,6.8,3.3,12.3,3.3,19.1c0,2.5,0.9,4.9,2.5,6.8c0.4,0.4,0.5,1,0.2,1.6c-0.9,1.9-2.3,3.4-2.4,3.4c-0.4,0.4-0.5,1-0.3,1.5
		c0.2,0.5,0.7,0.9,1.3,0.9c3.3,0,5.9-1.2,7.7-2.4c0.3-0.2,0.8-0.3,1.1-0.2c1.5,0.4,3.2,0.7,4.9,0.7c0.6,0,1.1,0,1.7-0.1
		C21,31.2,21.5,30.2,21.2,29.3L21.2,29.3z"
      />
    </g>
  </g>
  <g id="Layer_3" class="st0">
    <g class="st1">
      <path
        fill="currentcolor"
        d="M21.6,11c6.2,0.8,10.9,4.9,10.9,9.9c0,0.4,0,0.7-0.1,1.1l1.8,3.9l0,0c0.8-1.5,1.2-3.2,1.2-5c0-5.7-4.5-10.6-10.8-12.4
		l-3,2.3L21.6,11z"
      />
      <path
        fill="currentcolor"
        d="M15.1,8.3l2.3,0.9l0.9,2.3c0.1,0.2,0.2,0.3,0.4,0.3c0.2,0,0.4-0.1,0.4-0.3L20,9.2l2.3-0.9c0.2-0.1,0.3-0.2,0.3-0.4
		c0-0.2-0.1-0.4-0.3-0.4L20,6.6l-0.9-2.3C19.1,4.2,18.9,4,18.7,4c-0.2,0-0.4,0.1-0.4,0.3l-0.9,2.3l-2.3,0.9
		c-0.2,0.1-0.2,0.2-0.2,0.4C14.8,8.1,14.9,8.3,15.1,8.3z"
      />
      <path
        fill="currentcolor"
        d="M3.7,12.4l3.4,1.3l1.3,3.4c0.1,0.3,0.4,0.5,0.7,0.5c0.3,0,0.5-0.2,0.7-0.5l1.3-3.4l3.4-1.3c0.3-0.1,0.5-0.4,0.5-0.7
		c0-0.3-0.2-0.5-0.5-0.7L11,9.8L9.7,6.4C9.6,6.1,9.3,6,9,6C8.8,6,8.5,6.1,8.4,6.4L7.1,9.8l-3.4,1.3c-0.3,0.1-0.5,0.4-0.5,0.7
		C3.3,12.1,3.4,12.3,3.7,12.4z"
      />
      <path
        fill="currentcolor"
        d="M36.3,28.9l-3.7-1.4l-1.4-3.7c-0.1-0.4-0.4-0.5-0.7-0.5c-0.3,0-0.6,0.1-0.7,0.5l-1.4,3.7l-3.7,1.4
		c-0.4,0.1-0.5,0.4-0.5,0.7c0,0.3,0.1,0.6,0.5,0.7l3.7,1.4l1.4,3.7c0.1,0.3,0.4,0.5,0.7,0.5c0.3,0,0.6-0.2,0.7-0.5l1.4-3.7l3.7-1.4
		c0.3-0.1,0.5-0.4,0.5-0.7C36.7,29.3,36.6,29,36.3,28.9z"
      />
      <path
        fill="currentcolor"
        d="M19.4,30.9c-1.7,0-3.3-0.3-4.9-0.8L13,29.7l-1.2,0.9c-0.9,0.6-2.1,1.3-3.6,1.8c0.5-0.8,0.9-1.6,1.3-2.5l0.7-1.8l-1.3-1.4
		c-1.1-1.2-2.5-3.2-2.5-5.9c0-0.9,0.2-1.7,0.4-2.5c-0.1-0.2-0.2-0.3-0.3-0.5l0,0l-1.5-2.7C2.9,18.5,2.5,22.7,5,27
		c0.2,0.4,0.5,0.7,0.8,1c0.5,0.5,0.6,1.2,0.3,1.8c-1,2-2.5,3.6-2.5,3.6c-0.4,0.4-0.5,1.1-0.3,1.6C3.6,35.6,4.2,36,4.8,36
		c3.9,0,6.9-1.6,8.8-2.9c1.8,0.6,3.8,0.9,5.8,0.9c2.2,0,4.3-0.4,6.2-1L22.7,31c-0.3-0.2-0.7-0.3-1.1-0.2
		C20.9,30.9,20.1,30.9,19.4,30.9z"
      />
    </g>
  </g></svg
>`;
