import PropTypes from 'prop-types';
import React from 'react';

import { black3 } from '~/assets/shared-styles/general';

const ReorderIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none">
      <rect x="8" y="2" width="2" height="3" rx="0.8" fill={props.color} />
      <rect x="8" y="7" width="2" height="3" rx="0.8" fill={props.color} />
      <rect x="8" y="12" width="2" height="3" rx="0.8" fill={props.color} />
      <rect x="14" y="2" width="2" height="3" rx="0.8" fill={props.color} />
      <rect x="14" y="7" width="2" height="3" rx="0.8" fill={props.color} />
      <rect x="14" y="12" width="2" height="3" rx="0.8" fill={props.color} />
    </svg>
  );
};

ReorderIcon.defaultProps = {
  size: '20',
  color: black3,
};

ReorderIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default ReorderIcon;
