import { action } from './action';
import { backgrounds } from './backgrounds';
import { borders } from './borders';
import { brand } from './brand';
import { highlight } from './highlight';
import { inverse } from './inverse';
import { status } from './status';
import { text } from './text';

export const colors = {
  ...action,
  ...backgrounds,
  ...borders,
  ...brand,
  ...highlight,
  ...inverse,
  ...status,
  ...text,
};
