import { cloneDeep } from 'lodash';

import { Folder, FolderNode, Id, TreeViewNode } from './types';

type FolderMatch = TreeViewNode | null;

export const findFolder = (
  id: Id,
  folders: TreeViewNode[],
  match: FolderMatch = null,
): FolderMatch => {
  for (const folder of folders) {
    if (folder.id === id) {
      return folder;
    } else {
      match = findFolder(id, folder.children, match);
    }
  }
  return match;
};

export const setSubfolders = ({
  currentFolder,
  folders,
  subfolders,
}: {
  currentFolder: Folder;
  folders: TreeViewNode[];
  subfolders: FolderNode[];
}): FolderNode[] => {
  const folder = findFolder(currentFolder.id, folders);

  if (folder !== null) {
    folder.children = subfolders;
  }
  return cloneDeep(folders);
};
