import { noop } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useStyles } from 'uinix-ui';

import {
  Box,
  Button,
  CollapsibleText,
  DateText,
  Icon,
  IconButton,
  Layout,
  LoadingSpinner,
  Quote,
  Text,
  Tooltip,
  User,
} from '../../components';
import { HtmlEntityType } from '../../enums';
import { useElapse } from '../../hooks';
import { Action, MentionedEntity, Uuid } from '../../types';
import { formatDate, pluralize, serializeContentAst } from '../../utils';
import { TextEditor } from '../TextEditor';
import { CommentActions } from './CommentActions';
import { CommentDraft } from './CommentDraft';
import {
  Comment,
  CommentData,
  EntityResolvers,
  MentionEntityType,
  Mentions,
  User as UserType,
} from './types';
import { getHighlightFromContext, resolveHighlightEntity } from './utils';

const IS_NEW_TIME_WINDOW = 15 * 1000; // 15 seconds

const ACTIONS_CLASSNAME = 'comments__actions';

interface Props {
  comment: Comment;
  entityResolvers: EntityResolvers;
  mentions: Mentions;
  isActive: boolean;
  isLoading: boolean;
  isMentionsLoading: boolean;
  resolvedMentions: Mentions;
  /** Collapse comment if possible */
  shouldCollapseComment?: boolean;
  viewer: UserType;
  enableReply: boolean;
  /** Copy link action is called */
  onCopyLink: (comment: Comment) => void;
  /** Delete a given comment */
  onDelete: (comment: Comment) => void;
  /** Select a comment to reply to */
  onReply: (comment: Comment) => void;
  /** Update a comment */
  onUpdate: (comment: Comment) => void;
  onResolveComment: (commentData: Comment) => void;
  onUnresolveComment: (commentData: Comment) => void;
  onFilterAction?: (action: Action, comment: Comment) => Action;
  onHighlightClick?: (highlight: Uuid) => void;
  /** This callback is fired when mentioning.
   * @param mention the mentioned entity
   */
  onMention?: (mention: MentionedEntity<MentionEntityType>) => void;
  /** Callback for searching async mentions */
  onMentionSearch?: (updatedSearch: string) => void;
}

export const CommentPost = ({
  comment,
  enableReply,
  entityResolvers,
  isActive,
  isLoading,
  isMentionsLoading,
  mentions,
  resolvedMentions,
  shouldCollapseComment,
  viewer,
  onCopyLink,
  onDelete,
  onFilterAction,
  onHighlightClick,
  onMention,
  onMentionSearch,
  onReply,
  onResolveComment,
  onUnresolveComment,
  onUpdate,
}: Props) => {
  const {
    content,
    createdBy,
    createdDate,
    entity,
    id,
    isResolved,
    modifiedDate,
    replies,
    threadId,
  } = comment;

  const styles = useStyles();

  const [isEditing, setIsEditing] = useState(false);

  const [hasTimeWindowElapsed] = useElapse(IS_NEW_TIME_WINDOW);
  const serializedContent = useMemo(() => serializeContentAst(content), [
    content,
  ]);

  const highlightEntity = getHighlightFromContext(comment.context);

  const resolvedHighlight = resolveHighlightEntity(
    highlightEntity,
    entityResolvers,
  );

  const isNew =
    Date.now() - createdDate.getTime() < IS_NEW_TIME_WINDOW &&
    !hasTimeWindowElapsed;

  const isEdited = modifiedDate > createdDate;

  const isReply = !!threadId;

  const getUserInfo = () => {
    let info = formatDate(createdDate, 'relative');

    if (isResolved) {
      info = `${info} ${HtmlEntityType.Bullet} Resolved`;
    }

    return info;
  };

  const handleUpdate = (updatedComment: CommentData) => {
    onUpdate({
      ...comment,
      content: updatedComment.content,
      mentions: updatedComment.mentions,
    });
    setIsEditing(false);
  };

  return (
    <Layout
      align="flex-start"
      direction="column"
      id={id}
      role="comment"
      position="relative"
      px={6}
      py={4}
      spacing={3}
      styles={[
        styles.colors.status,
        isEditing ? null : componentStyles.container,
      ]}
      styleProps={{
        status: isNew || isActive || isEditing ? 'active' : undefined,
      }}
    >
      {isNew && (
        <Layout position="absolute" px={2} styles={componentStyles.newTag}>
          <Text color="text.secondary">New comment</Text>
        </Layout>
      )}
      <Layout align="flex-start" justify="space-between" w="100%">
        <Layout>
          <User
            options={{
              infoTooltip: formatDate(createdDate, 'full'),
              info: getUserInfo(),
            }}
            placeholder="Deactivated User"
            //a dummy user object is created upstream. check if user object has name to show placeholder
            user={
              createdBy.firstName && createdBy.lastName ? createdBy : undefined
            }
            mode="info"
          />
          {comment.isCommentEmbedded !== undefined && (
            <Layout alignSelf="start">
              <Icon
                tooltip={
                  comment.isCommentEmbedded
                    ? 'This comment thread is visible to anyone with access to the document.'
                    : 'This comment thread is only visible to your users that can see this ticket.'
                }
                icon={comment.isCommentEmbedded ? 'globe' : 'lock'}
              />
            </Layout>
          )}
        </Layout>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Box className={ACTIONS_CLASSNAME} styles={componentStyles.actions}>
            <Layout preset="icons">
              {enableReply && (
                <IconButton
                  icon="reply"
                  tooltip="Reply"
                  onClick={() => onReply(comment)}
                />
              )}
              <Box>
                <CommentActions
                  comment={comment}
                  viewer={viewer}
                  onDelete={onDelete}
                  onCopyLink={onCopyLink}
                  onResolveComment={onResolveComment}
                  onUpdate={() => setIsEditing(true)}
                  onUnresolveComment={onUnresolveComment}
                  onFilterAction={onFilterAction}
                />
              </Box>
            </Layout>
          </Box>
        )}
      </Layout>
      {!isReply && highlightEntity && (
        <Box
          w="100%"
          onClick={() =>
            resolvedHighlight
              ? onHighlightClick?.(resolvedHighlight.id)
              : noop()
          }
        >
          <Quote>
            {resolvedHighlight?.text || (
              <Layout justify="center">
                <LoadingSpinner />
              </Layout>
            )}
          </Quote>
        </Box>
      )}
      {isEditing ? (
        <CommentDraft
          content={content}
          entity={entity}
          isFocused={true}
          isMentionsLoading={isMentionsLoading}
          mentions={mentions}
          resolvedMentions={resolvedMentions}
          onSubmit={handleUpdate}
          onMention={onMention}
          onCancel={() => setIsEditing(false)}
          onMentionSearch={onMentionSearch}
        />
      ) : (
        <Text w="100%">
          <CollapsibleText
            limit={200}
            fullContent={
              <>
                <TextEditor
                  content={content}
                  isMentionsLoading={isMentionsLoading}
                  mentions={mentions}
                  mode="preview"
                  resolvedMentions={resolvedMentions}
                  onChange={noop}
                  onPost={noop}
                />
                {isEdited && (
                  <Tooltip
                    tooltip={<DateText date={modifiedDate} format="full" />}
                  >
                    <Text color="text.quiet" ml={2}>
                      (Edited)
                    </Text>
                  </Tooltip>
                )}
              </>
            }
            shouldResetToInitialState={shouldCollapseComment}
            text={serializedContent}
          />
        </Text>
      )}
      {enableReply && replies > 0 && (
        <Button
          text={pluralize(replies, 'Reply')}
          variant="action"
          onClick={() => onReply(comment)}
        />
      )}
    </Layout>
  );
};

const componentStyles = {
  actions: {
    visibility: 'hidden',
  },
  container: {
    ':hover': {
      backgroundColor: 'background.quiet',
      [`& .${ACTIONS_CLASSNAME}`]: {
        visibility: 'visible',
      },
    },
  },
  newTag: {
    top: -26,
    right: 0,
    backgroundColor: 'background.quiet',
  },
};
