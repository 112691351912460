export enum WorkflowOperatorType {
  AllOfTheseWords = 'ALL_OF_THESE_WORDS',
  AnyOfTheseWords = 'ANY_OF_THESE_WORDS',
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  ContainsAll = 'CONTAINS_ALL',
  ContainsAnyOf = 'CONTAINS_ANY_OF',
  DoesNotContain = 'DOES_NOT_CONTAIN',
  ExactPhrase = 'EXACT_PHRASE',
  Equal = '==',
  GreaterThan = '>',
  GreaterThanEqual = '>=',
  IsBlank = 'IS_BLANK',
  IsNotBlank = 'IS_NOT_BLANK',
  LessThan = '<',
  LessThanEqual = '<=',
  NotEqual = '!=',
}
