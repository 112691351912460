import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = ({ client }) => ({ client });

export function withClient(WrappedComponent) {
  function ClientContainer({ client, ...rest }) {
    return <WrappedComponent client={client} {...rest} />;
  }
  return connect(mapStateToProps)(ClientContainer);
}
