import { camelizeKeys, decamelizeKeys } from 'humps';
import { isEmpty, toLower } from 'lodash';

import { SearchQueryFormatTypes } from '~/enums';
import { pilot, publicApi } from '~/services';
import {
  CancelTrainingParams,
  CreateRetrainParams,
  CreateTrainingParams,
  CreateTrainingResponse,
  DeleteClauseParams,
  DeleteSampleParams,
  DeleteTrainingModelParams,
  FlattenFolderTree,
  FolderTree,
  GetFiltersDataParams,
  GetFiltersDataResponse,
  GetFolderFilterOptionsParams,
  GetPredictionStatusParams,
  GetPredictionStatusResponse,
  GetSampleCsvFileParams,
  GetTrainingStatusParams,
  GetTrainingStatusResponse,
  PublishTrainedModelParams,
  RenameClauseParams,
  SampleParams,
  SamplesResponse,
  UpdateClauseParams,
  UpdateDescriptionParams,
  UpdatePredictionFiltersParams,
  UpdateSampleParams,
  UpdateTrainingLabelsParams,
  Uuid,
} from '~/types';
import { exportCsvBlob } from '~/utils';

/******** Custom Quick AI API ********/

const flattenFolderTreeHelper = (data: FolderTree[], path: string) => {
  return data.reduce((prev: FlattenFolderTree[], cur: FolderTree) => {
    const curPath = `${path}/${cur.name}`;

    prev.push({
      value: String(cur.id),
      display_value: cur.name,
      path: curPath,
    });

    if (cur.children?.length) {
      prev = prev.concat(flattenFolderTreeHelper(cur.children, curPath));
    }
    return prev;
  }, []);
};

export const getFolderFilterOptions = async ({
  substringQuery = '',
}: GetFolderFilterOptionsParams) => {
  const result = (await pilot.get('/path/')) as FolderTree;
  const options = flattenFolderTreeHelper(result.children, 'Document');

  const subset = isEmpty(substringQuery)
    ? options
    : options.filter(
        (o) =>
          toLower(o.display_value).includes(toLower(substringQuery)) ||
          toLower(o.path).includes(toLower(substringQuery)),
      );

  return subset.slice(0, 30);
};

// This is a super admin only api. Backend will check permissions
export const getClientClause = async ({
  clientId,
}: {
  clientId: string;
}): Promise<{ count: number }> => {
  return await pilot.get(`/client/${clientId}/automation-hub/`);
};

export const suspendClause = async ({ name }: UpdateClauseParams) => {
  return pilot.put('/ai-command-center/suspend/', { clause_name: name });
};

export const resumeClause = async ({ name }: UpdateClauseParams) => {
  return pilot.put('/ai-command-center/resume/', { clause_name: name });
};

export const deleteClause = async ({ name }: DeleteClauseParams) => {
  return pilot.remove('/ai-command-center/delete/', {
    params: { clause_name: name },
  });
};

export const renameClause = async ({
  oldName,
  newName,
}: RenameClauseParams) => {
  return pilot.patch(`/ai-command-center/rename/${oldName}`, {
    new_name: newName,
  });
};

export const getSamples = async ({
  clause,
  status,
  pageIndex: page,
  pageSize,
}: SampleParams) => {
  const response = await pilot.get('/ai-command-center/highlights/', {
    params: { clause, status, page, pageSize },
  });

  return camelizeKeys(response) as SamplesResponse;
};

export const suspendSample = async ({ id }: UpdateSampleParams) => {
  return pilot.put(`/ai-command-center/highlights/suspend/${id}/`);
};

export const resumeSample = async ({ id }: UpdateSampleParams) => {
  return pilot.put(`/ai-command-center/highlights/resume/${id}/`);
};

export const updateSample = async ({
  id,
  sampleName,
  content,
}: UpdateSampleParams) => {
  const params = { highlight: sampleName?.trim(), content: content?.trim() };
  return pilot.put(
    `/ai-command-center/highlights/${id}/`,
    decamelizeKeys(params),
  );
};

export const deleteSample = async ({ id }: DeleteSampleParams) => {
  return pilot.remove(`/ai-command-center/highlights/${id}/`);
};

/******** Guided AI Public API ********/

export const createTraining = async ({
  params,
  clauseId = '',
}: {
  params: CreateTrainingParams;
  clauseId?: Uuid;
}): Promise<CreateTrainingResponse> => {
  return publicApi.post(`/train/${clauseId}`, params);
};

export const createRetraining = async ({
  clauseId,
  config,
}: CreateRetrainParams) => {
  return publicApi.post(`/train/${clauseId}/iterate`, { config });
};

export const getTrainingStatus = async ({
  clauseId,
}: GetTrainingStatusParams): Promise<GetTrainingStatusResponse> => {
  return publicApi.get(`/train/${clauseId}/status`);
};

export const getFiltersData = async ({
  clauseId,
}: GetFiltersDataParams): Promise<GetFiltersDataResponse> => {
  return publicApi.get(`/train/${clauseId}/filters`);
};

export const getPredictionStatus = async ({
  requestId,
}: GetPredictionStatusParams): Promise<GetPredictionStatusResponse> => {
  return publicApi.get(`/predict/${requestId}/status`);
};

export const getSampleCsvFile = async ({
  clauseId,
  version,
}: GetSampleCsvFileParams) => {
  return publicApi
    .get(`/train/${clauseId}/${version}/training_data`, {
      responseType: 'blob',
    })
    .then((response) =>
      exportCsvBlob(response, `full_training_data_${clauseId}_${version}.csv`),
    );
};

export const updateTrainingLabels = async ({
  clauseId,
  samples,
  metadata,
  evaluate,
}: UpdateTrainingLabelsParams) => {
  return publicApi.patch(`/train/${clauseId}/label`, {
    samples,
    evaluate,
    metadata,
  });
};

export const updatePredictionFilters = async ({
  clauseId,
  predictionFilters,
  queryFormat = SearchQueryFormatTypes.V2,
}: UpdatePredictionFiltersParams) => {
  return publicApi.patch(`/train/${clauseId}/filters`, {
    queryFormat,
    predictionFilters,
  });
};

export const updateDescription = async ({
  clauseId,
  description,
}: UpdateDescriptionParams) => {
  return publicApi.patch(`/train/${clauseId}/description`, { description });
};

export const publishTrainedModel = async ({
  clauseId,
}: PublishTrainedModelParams) => {
  return publicApi.post(`/train/${clauseId}/publish`, {});
};

export const deleteTrainingModel = async ({
  clauseId,
}: DeleteTrainingModelParams) => {
  return publicApi.remove(`/train/${clauseId}/delete`);
};

export const cancelTraining = async ({ clauseId }: CancelTrainingParams) => {
  return publicApi.remove(`/train/${clauseId}/cancel`);
};

export async function uploadCsvFile(file: any): Promise<{ id: string }> {
  const formData = new FormData();
  formData.append('file', file);
  return publicApi.post('/train/training_data', formData);
}
