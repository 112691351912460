import { noop } from 'lodash';
import React, { useMemo, useRef } from 'react';

import { ActionsMenu, types, useClickOutside, useEscape } from '~/eds';

import { InteractionPosition } from './types';

interface Props {
  actions: types.Action[];
  onCloseMenu?: () => void;
  position?: InteractionPosition;
}

export const InteractionHighlightActionsMenu = ({
  actions: initialActions,
  onCloseMenu = noop,
  position,
}: Props) => {
  const ref = useRef(null);

  useClickOutside(ref, onCloseMenu);
  useEscape(onCloseMenu);

  // rebind the close menu callback onto the action handler
  const actions = useMemo(() => {
    return initialActions.map((action) => ({
      ...action,
      onClick: () => {
        action.onClick?.(action);
        onCloseMenu();
      },
    }));
  }, [initialActions, onCloseMenu]);

  return (
    <div
      ref={ref}
      style={{
        width: 'fit-content',
        ...(position
          ? {
              position: 'absolute',
              left: position.x,
              top: position.y,
            }
          : {}),
      }}
    >
      <ActionsMenu isEmbedded actions={actions} name="highlight-actions-menu" />
    </div>
  );
};
