const styles = {
  toastContainterLink: {
    textDecoration: 'none',
  },
  toastContainter: {
    display: 'flex',
    alignItems: 'center',
    width: '361px',
    borderRadius: '4px',
    padding: '16px',
    backgroundColor: '#404040',
    color: '#FFF',
    boxShadow: '0 4px 8px 0 rgba( 0, 0, 0, 0.25 )',
    '& svg': {
      flexShrink: 0,
      marginRight: '16px',
    },
  },
  toastText: {
    flexGrow: 1,
    wordBreak: 'break-word',
  },
};

export default styles;
