import React, { useState } from 'react';

import { FlexLayout, Modal, SortableList } from '~/ui';

const promise = () => new Promise((resolve) => resolve(true));

export function useModal({
  content,
  disableAutoHide,
  disableCancelButton,
  disableCloseButton,
  overflowDisplay,
  title,
  width,
  actionButton,
  secondaryButton,
  onHide,
  onCancel,
}) {
  const [visible, setVisible] = useState(false);

  function showModal() {
    setVisible(true);
  }

  function hideModal() {
    onHide && onHide();
    setVisible(false);
  }

  function cancelModal() {
    onCancel && onCancel();
    setVisible(false);
  }

  const modal = (
    <Modal
      actionButton={actionButton}
      disableAutoHide={disableAutoHide}
      disableCancelButton={disableCancelButton}
      disableCloseButton={disableCloseButton}
      overflowDisplay={overflowDisplay}
      secondaryButton={secondaryButton}
      title={title}
      visible={visible}
      width={width}
      onHide={hideModal}
      onCancel={cancelModal}
    >
      {content}
    </Modal>
  );

  return [modal, showModal];
}

export function useModalSimple({
  confirm,
  content,
  disableAutoHide,
  disableCancelButton,
  text,
  title,
  width,
  onHide,
}) {
  const [modal, showModal] = useModal({
    content,
    disableAutoHide,
    disableCancelButton,
    title,
    actionButton: {
      text,
      errorHandler: () => {},
      promise: async () => {
        await promise();
        confirm();
      },
    },
    width,
    onHide,
  });

  return [modal, showModal];
}

export function useModalSortable({
  confirm,
  contentText,
  items: defaultItems,
  text,
  title,
}) {
  const [items, setItems] = useState(defaultItems);

  const [modal, showModal] = useModalSimple({
    confirm: () => confirm(items),
    disableCancelButton: true,
    text,
    title,
    content: (
      <FlexLayout flexDirection="column" space={4}>
        {contentText}
        <SortableList items={defaultItems} onSort={setItems} />
      </FlexLayout>
    ),
  });

  return [modal, showModal];
}
