import { FileExtensionType } from '../enums';

type FileIconType =
  | 'file'
  | 'file-doc'
  | 'file-image'
  | 'file-pdf'
  | 'file-powerpoint'
  | 'file-spreadsheet';

export const FILE_ICONS: Record<string, FileIconType> = {
  [FileExtensionType.Csv]: 'file-spreadsheet',
  [FileExtensionType.Doc]: 'file-doc',
  [FileExtensionType.Docx]: 'file-doc',
  [FileExtensionType.Jpeg]: 'file-image',
  [FileExtensionType.Jpg]: 'file-image',
  [FileExtensionType.Pdf]: 'file-pdf',
  [FileExtensionType.Png]: 'file-image',
  [FileExtensionType.Ppt]: 'file-powerpoint',
  [FileExtensionType.Pptm]: 'file-powerpoint',
  [FileExtensionType.Pptx]: 'file-powerpoint',
  [FileExtensionType.Tiff]: 'file-image',
  [FileExtensionType.Webp]: 'file-image',
  [FileExtensionType.Xls]: 'file-spreadsheet',
  [FileExtensionType.Xlsm]: 'file-spreadsheet',
  [FileExtensionType.Xlsx]: 'file-spreadsheet',
};
