import { pilot } from '~/services';

import { BaseRequest } from './types';

interface BulkDeleteParams extends BaseRequest {
  reason?: string;
  extraDetails?: string;
}

export const searchV2BulkDelete = ({
  documentHandlerIds,
  searchAPI,
  isAllSelected,
  searchQuery,
  extraDetails,
  reason,
}: BulkDeleteParams): Promise<{ detail: string }> =>
  pilot.put('document/bulk-delete/', {
    document_ids: !isAllSelected ? documentHandlerIds : [],
    ...(searchAPI === 'v3'
      ? { search_query_v3: searchQuery }
      : { search_query: searchQuery }),
    select_all: isAllSelected,
    reason,
    extra_details: extraDetails,
  });
