import React from 'react';
import { Redirect } from 'react-router-dom';

import { RouteResolver } from '~/routing';
import { User } from '~/types';

export const nullComponent = () => null;

export const withRedirect = (to: string): React.ComponentType => {
  return (props: object) => <Redirect {...props} to={to} />;
};

type ResolvedComponent = React.ComponentType | (() => null);

export interface ResolveContext {
  routeParams?: Record<string, any>;
  user: User;
}

export const resolveComponent = (
  resolvers: RouteResolver[],
  context: ResolveContext,
): ResolvedComponent => {
  let resolvedComponent: ResolvedComponent = nullComponent;

  for (const resolver of resolvers) {
    const {
      component = nullComponent,
      paramsPredicates = [],
      permission,
      redirectPath,
    } = resolver;

    // set resolvedComponent to the specified component or a <Redirect /> if redirectPath is provided
    resolvedComponent = component;
    if (redirectPath) {
      resolvedComponent = withRedirect(redirectPath);
    }

    // If permission is defined, evaluate it and return the resolvedComponent immediately (order matters).
    if (permission) {
      const isValidPermission = permission(context.user);
      const isValidParams = paramsPredicates.every(
        (paramPredicate) =>
          context.routeParams && paramPredicate(context.routeParams),
      );

      const isValid = isValidPermission && isValidParams;
      if (isValid) {
        return resolvedComponent;
      }
      // return component immediately if there are no permissions
    } else {
      return resolvedComponent;
    }
  }

  return nullComponent;
};
