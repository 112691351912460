export default `
<svg
  aria-hidden="true"
  aria-label="field-folder"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18 7H12.625L11.0938 5.59375C10.7188 5.21875 10.2188 5 9.6875 5H6C4.875 5 4 5.90625 4 7V17C4 18.125 4.875 19 6 19H18C19.0938 19 20 18.125 20 17V9C20 7.90625 19.125 7 18 7ZM18.5 17C18.5 17.2812 18.25 17.5 18 17.5H6C5.71875 17.5 5.5 17.2812 5.5 17V7C5.5 6.75 5.71875 6.5 6 6.5H9.65625C9.78125 6.5 9.90625 6.5625 10 6.65625L12 8.5H18C18.25 8.5 18.5 8.75 18.5 9V17Z"
    fill="currentcolor"
  />
</svg>
`;
