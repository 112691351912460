import { formatDate } from '~/eds';

export const ANALYZER_FORM_NAME = 'analyzerForm';

export const preprocessQueryData = (data) => {
  if (data.length === 1 && data[0].entity === 'bool_text_search') {
    return checkIfEmptyBooleanSearch(data[0]);
  }

  const filteredData = filterOutEmptySectionsAndOperators(data);
  return addTimezoneOffsetInformation(filteredData);
};

export const updateFilterValues = (sectionsData, data) => {
  const newData = [];
  for (const i in data) {
    if (data[i].entity === 'field') {
      newData.push({
        ...data[i],
        values: sectionsData.find((item) => item.id === data[i].id)?.values,
      });
    } else if (data[i].entity === 'section') {
      const newSection = updateFilterValues(sectionsData, data[i].section);
      if (newSection.length) {
        newData.push({ entity: 'section', section: newSection });
      }
    } else {
      newData.push(data[i]);
    }
  }

  return newData;
};

function addTimezoneOffsetInformation(data) {
  function dateFormater(d) {
    if (!d) return null;

    const date = new Date(d);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return formatDate(date, 'iso_complete');
  }

  function timezoneOffsetMapper(d) {
    const { first_value, second_value, name, relation } = d;
    if (
      name === 'Upload Date' &&
      relation.value !== 'last' &&
      relation.value !== 'next'
    ) {
      return {
        ...d,
        first_value_v2: dateFormater(first_value),
        second_value_v2: dateFormater(second_value),
      };
    }

    return d;
  }

  return data.map(timezoneOffsetMapper);
}

function checkIfEmptyBooleanSearch(dataItem) {
  if (dataItem.query.length) return [dataItem];
  return [];
}

/**
 * Create new `data` array (to query the analyzer) without empty sections and corresponding operators, recursively down the filters tree.
 * If an empty section (or a subsection) is the last item, remove it together with its preceding operator.
 * If an empty section (or a subsection) is not the last item, remove it together with its following operator.
 *
 * @param {Object[]} data - an array of entity objects (fields, operators, sections, text searches, boolean text searches)
 * */
export function filterOutEmptySectionsAndOperators(data) {
  const newData = [];

  for (let i = 0; i < data.length; i++) {
    if (data[i].entity !== 'section') {
      const { values: _values, ...rest } = data[i];
      newData.push(rest);
      continue;
    }

    const newSection = filterOutEmptySectionsAndOperators(data[i].section);
    if (newSection.length) {
      newData.push({ entity: 'section', section: newSection });
    } else {
      if (i === data.length - 1) {
        newData.pop();
      } else {
        i++;
      }
    }
  }

  return newData;
}
