import { createAction } from '@reduxjs/toolkit';

import { capitalize } from './strings';

export const createActions = (namespace, types) => {
  return types.reduce((actions, type) => {
    const key = `${namespace}${capitalize(type)}`;
    /* Comma operator gives better performance on jsperf */
    return (actions[key] = createAction(`${namespace}/${type}`)), actions; // eslint-disable-line no-sequences
  }, {});
};
