import { types } from '~/eds';
import { api } from '~/redux';

import { useCurrentUser } from './useCurrentUser';

interface Props {
  /** user ids to fetch */
  userIds: types.PilotId[];
  skip?: boolean;
}

export function useResolveUsers({ userIds, skip = false }: Props) {
  const currentUser = useCurrentUser();
  const { data: usersResponse, ...rest } = api.endpoints.resolveUsers.useQuery(
    {
      ids: userIds,
      params: { includeDeleted: true, clientId: currentUser.client },
    },
    { skip: !userIds || !userIds.length || skip },
  );

  return { users: usersResponse, ...rest };
}
