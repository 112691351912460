import { isDev } from '../../../../dev';
import { Nullable } from '../../types';
import { testIsEmailValid } from '../../utils';

export const testCanLogin = ({
  email,
  password,
}: {
  email: Nullable<string>;
  password: Nullable<string>;
}) => isDev || (testIsEmailValid(email) && Boolean(password));
