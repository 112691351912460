import { formatDate, parseDate } from '~/eds';
import { DataFieldType } from '~/enums';

import { isEmptyValue } from '../../Shared/EcFilterField/EcFilterField.utils';

export function createFormattedDateString(dateString, timeString) {
  const parsedDate = timeString
    ? parseDate(`${dateString}T${timeString}`)
    : parseDate(dateString);
  const formattedDate = parsedDate
    ? formatDate(parsedDate, 'iso_datetime')
    : '';
  return formattedDate;
}

export const processData = (data) => {
  return {
    active: true,
    name: data.name,
    message: data.message,
    folders: data.folders.map((folder) => folder.id),
    start_date: data.startDate
      ? createFormattedDateString(data.startDate)
      : data.startDate,
    end_date: data.occurrenceEndDate
      ? createFormattedDateString(data.occurrenceEndDate)
      : data.occurrenceEndDate,
    occurrence_interval: parseInt(data.occurrenceInterval) || null,
    occurrence_period: data.occurrencePeriod.value,
    recurrences: parseInt(data.occurrenceEndLimit) || null,
    users: data.users,
    user_fields: data.userFields.map((field) => field.id),
    departments: data.departments.map((department) => department.department_id),
    excluded_users: data.excludedUsers,
    edit_access: data.editAccess,
    query: data.filters,
  };
};

export function validateFiltersForm(filters) {
  let filtersFormValid = true;
  const validatedFilters = validateFilters(
    filters,
    ({ valid }) => (filtersFormValid = valid),
  );

  return { filtersFormValid, validatedFilters };
}

function validateFilters(filters, onErrorCallback) {
  return filters.map((filter) => {
    const { entity, type, relation, first_value, second_value } = filter;

    if (entity === 'field') {
      if (
        relation &&
        relation &&
        ['is_blank', 'is_not_blank'].includes(relation.value)
      ) {
        const {
          first_value: _first_value,
          second_value: _second_value,
          ...filterRest
        } = filter;
        return { ...filterRest, error: false };
      }

      if (isEmptyValue(first_value)) {
        onErrorCallback({ valid: false });
        return { ...filter, error: true };
      }

      if (type !== DataFieldType.BOOLEAN && !relation) {
        onErrorCallback({ valid: false });
        return { ...filter, error: true };
      }

      if (type === DataFieldType.ARRAY_MULTIPLE && !first_value.length) {
        onErrorCallback({ valid: false });
        return { ...filter, error: true };
      }

      if (
        (type === DataFieldType.DATE || type === DataFieldType.NUMBER) &&
        ['next', 'last', 'between_dates', 'between'].includes(relation.value) &&
        !second_value
      ) {
        onErrorCallback({ valid: false });
        return { ...filter, error: true };
      }

      if (type === DataFieldType.STRING && !first_value.trim()) {
        onErrorCallback({ valid: false });
        return { ...filter, error: true };
      }
    } else if (filter.entity === 'section') {
      const sectionFields = validateFilters(filter.section, onErrorCallback);
      if (sectionFields?.length === 0) {
        onErrorCallback({ valid: false });
      }

      return { entity: 'section', section: sectionFields };
    } else if (filter.entity === 'text_search') {
      if (!filter.text || !filter.text.trim()) {
        onErrorCallback({ valid: false });
        return { ...filter, error: true };
      }
    } else if (entity === 'bool_text_search') {
      if (filter.error || filter.query === '') {
        onErrorCallback({ valid: false });
        return { ...filter, error: true, validationError: true };
      }
    } else if (entity === 'folder') {
      if (
        !filter.folder_ids.length ||
        !(filter.folders && filter.folders.length)
      ) {
        onErrorCallback({ valid: false });
        return { ...filter, error: true };
      }
    }

    return filter;
  });
}
