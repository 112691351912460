import { Endpoint } from '~/features/webhooks';
import { courier } from '~/services';

import {
  DeleteApiV1EndpointsByEndpointIdApiArg,
  /*DeleteApiV1EndpointsByEndpointIdApiResponse,*/
} from '../../types/courier/endpoints';

interface Request {
  endpoint: Endpoint;
}

export const deleteEndpoint = async ({
  endpoint,
}: Request): Promise<boolean> => {
  const param: DeleteApiV1EndpointsByEndpointIdApiArg = {
    endpointId: endpoint.id,
  };

  const response = await courier.remove(`/endpoints/${param.endpointId}`);
  return Boolean(response);
};
