import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

// TODO: import colors explicitly in the future UI system.

/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as colors from '~/assets/shared-styles/general';
import { FlexLayout, useToggle } from '~/ui';

import ChevronDownIcon from '../Icons/ChevronDownIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';

const styles = {
  trigger: {
    cursor: 'pointer',
    flexGrow: 1,
  },
  triggerIcon: {
    display: 'flex',
    flexShrink: 0,
  },
};

function Collapse({
  children,
  collapsedContent,
  iconColor = 'black4',
  iconSize = '24',
  isCollapsed: defaultIsCollapsed = false,
  trigger,
  triggerClassName,
  triggerRightContent,
  onToggleCollapse,
  // injected
  classes,
}) {
  const [isCollapsed, toggleIsCollapsed] = useToggle(defaultIsCollapsed);
  const CollapseIcon = isCollapsed ? ChevronRightIcon : ChevronDownIcon;

  function handleToggleCollapse() {
    toggleIsCollapsed();
    onToggleCollapse && onToggleCollapse();
  }

  return (
    <div>
      <FlexLayout
        alignItems="center"
        className={triggerClassName}
        flexGrow="1"
        justifyContent="space-between"
        space={2}
      >
        <FlexLayout
          alignItems="center"
          className={classes.trigger}
          onClick={handleToggleCollapse}
          space={2}
        >
          <div className={classes.triggerIcon}>
            <CollapseIcon color={colors[iconColor]} size={iconSize} />
          </div>
          {trigger}
        </FlexLayout>
        {triggerRightContent}
      </FlexLayout>
      {isCollapsed ? collapsedContent : children}
    </div>
  );
}

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  collapsedContent: PropTypes.node,
  iconSize: PropTypes.string,
  isCollapsed: PropTypes.bool,
  trigger: PropTypes.node.isRequired,
  triggerClassName: PropTypes.string,
  triggerRightContent: PropTypes.node,
  onToggleCollapse: PropTypes.func,
};

export default injectSheet(styles)(Collapse);
