import pluralize from 'pluralize';
import PT from 'prop-types';
import React, { useRef } from 'react';
import DropzoneComponent from 'react-dropzone-component';

import { Icon } from '~/eds';
import { FileExtensionType, FileMimeType } from '~/enums';
import { Box, Button, Divider, FlexLayout, Link, Text, Tooltip } from '~/ui';
import { coerceFileType } from '~/utils/files';

function FileInput({
  acceptedFiles = [
    FileExtensionType.Doc,
    FileExtensionType.Docx,
    FileExtensionType.Pdf,
  ].join(', '),
  children = null,
  className,
  enableDropzone = true,
  enableMultiple = false,
  enableUpload = true,
  enableDivider = true,
  file,
  id,
  label: defaultLabel,
  layout = 'default',
  onChange,
  error = null,
}) {
  const fileInputRef = useRef();

  if (!enableUpload) {
    return children;
  }

  const isCondensed = layout === 'condensed';

  const pluralizeCount = enableMultiple ? 2 : 1;
  const label =
    defaultLabel || `Upload ${pluralize('document', pluralizeCount)}`;

  function attachFiles() {
    const fileInput = fileInputRef.current;
    fileInput.value = '';
    fileInput.onchange = () => onChange(Array.from(fileInput.files));
    fileInput.click();
  }

  const fileInput = (
    <input
      accept={acceptedFiles}
      className="ui/file-input__input"
      multiple={enableMultiple ? 'multiple' : undefined}
      ref={fileInputRef}
      style={{ display: 'none' }}
      type="file"
      data-testid="fileInput"
    />
  );

  if (!enableDropzone) {
    return (
      <>
        <Button
          iconLeft="attachment"
          text={label}
          variant="outlined"
          onClick={attachFiles}
        />
        {fileInput}
      </>
    );
  }

  const fileText = pluralize('file', pluralizeCount);
  let fileExtensionsText;
  if (acceptedFiles !== '*') {
    const fileExtensions = acceptedFiles.split(/,\s+/);
    fileExtensionsText =
      fileExtensions.length < 10 ? (
        <Text color="gray-700" variant="s-dense-bold">
          {acceptedFiles === FileMimeType.Docx
            ? '.docx'
            : acceptedFiles.replace(/,/gi, ', ')}{' '}
          {fileText}
        </Text>
      ) : (
        <Text color="gray-700" variant="s-dense-bold">
          <Tooltip content={acceptedFiles}>
            <span>{fileText}</span>
          </Tooltip>
        </Text>
      );
  }

  return (
    <DropzoneComponent
      config={{
        postUrl: 'no-url',
      }}
      djsConfig={{
        acceptedFiles,
        autoProcessQueue: false,
        dictDefaultMessage: '',
        previewTemplate: '<div class="ignore"></div>',
      }}
      eventHandlers={{
        addedfiles: (files) => {
          if (files) {
            const acceptedFileTypes = acceptedFiles.split(',');
            let fileTypes = acceptedFileTypes.includes(FileExtensionType.Docx)
              ? acceptedFileTypes.concat(FileExtensionType.Doc)
              : acceptedFileTypes;

            // @TODO review this behaviour
            //
            // If only file extensions are configured for the FileInput component, files will
            // not be recognized when dropping them into the drag-and-drop zone.
            // This because the drop zone seems to threat them based on the MIME type.
            // To fix this behaviour, it is needed to also configure the `FileInput` component to
            // allow for Excel MIME types, by passing them as accepted files.
            // Nonetheless, this worsen UX, as MIME types will be displayed on the UI.
            // To avoid this, here we configure the MIME types related to excel files,
            // both, for XLS and XLSX file extensions.
            fileTypes = fileTypes.map((type) => type.trim());

            fileTypes = acceptedFileTypes.includes(FileExtensionType.Xlsx)
              ? fileTypes.concat(FileMimeType.Xlsx)
              : fileTypes;

            fileTypes = acceptedFileTypes.includes(FileExtensionType.Xls)
              ? fileTypes.concat(FileMimeType.Xls)
              : fileTypes;

            const fileTypesArray = fileTypes.map((item) =>
              coerceFileType(item),
            );
            const validFiles =
              acceptedFiles === '*'
                ? files
                : files.filter((file) =>
                    fileTypesArray.some((type) => file.type.includes(type)),
                  );
            if (enableMultiple) {
              onChange(validFiles);
            } else {
              const [firstFile] = validFiles;
              onChange(firstFile ? [firstFile] : []);
            }
          }
        },
      }}
    >
      <FlexLayout flexDirection="column" space={6}>
        {!file && (
          <FlexLayout
            as="button"
            aria-label="Drag and drop or click here to upload a file from your computer."
            data-testid="fileInputContainer"
            alignItems="center"
            bg={error ? 'red-100' : 'gray-200'}
            className={className}
            flexDirection={isCondensed ? 'column' : 'row'}
            id={id}
            onClick={attachFiles}
            px={8}
            py={4}
            space={isCondensed ? 4 : 0}
            sx={{
              border: 'border',
              borderRadius: 'm',
              borderColor: error ? 'red-400' : 'black-alpha-20',
            }}
          >
            {error ? (
              <FlexLayout
                alignItems="center"
                flexDirection={isCondensed ? 'column' : 'row'}
                pr={4}
                space={4}
                sx={isCondensed ? undefined : { borderRight: 'border' }}
              >
                <Icon color="status.danger" icon="status-danger" size="ml" />
                {typeof error === 'string' ? (
                  <Text color="red-400" variant="xs-dense-medium">
                    {error}
                  </Text>
                ) : (
                  error /** render as a react component */
                )}
              </FlexLayout>
            ) : (
              <FlexLayout
                alignItems="center"
                pr={4}
                space={4}
                sx={isCondensed ? undefined : { borderRight: 'border' }}
              >
                <Icon icon="attachment" />
                <Text color="gray-700" variant="s-spaced-bold">
                  {label}
                </Text>
              </FlexLayout>
            )}
            {isCondensed && (
              <Box mx={-8} sx={{ alignSelf: 'stretch' }}>
                {enableDivider && <Divider />}
              </Box>
            )}
            <FlexLayout flex="1 1 auto" pl={4} justifyContent="center">
              <Text color="gray-600" variant="xs-dense-medium">
                Drag-and-drop {pluralizeCount > 1 ? ' ' : 'a '}
                {fileExtensionsText}, or{' '}
                <Link variant="xs-dense-medium">click here to upload</Link> it
                from your computer.
              </Text>
            </FlexLayout>
          </FlexLayout>
        )}
        {children}
      </FlexLayout>
      {fileInput}
    </DropzoneComponent>
  );
}

FileInput.propTypes = {
  /** File input contents */
  children: PT.node,
  /** Optional CSS class */
  className: PT.string,
  /** Acceptable mime types (comma-separated) */
  acceptedFiles: PT.string,
  /** Disable the input */
  disabled: PT.bool,
  /** If dropzone should be enabled */
  enableDropzone: PT.bool,
  /** If multiple files can be uploaded */
  enableMultiple: PT.bool,
  /** If dropzone should show divider */
  enableDivider: PT.bool,
  /** Layout mode of the file input. Condensed layout is useful for applications in narrow sidebars */
  layout: PT.oneOf(['default', 'condensed']),
  /** Change handler */
  onChange: PT.func.isRequired,
  /** Show error if not null */
  error: PT.oneOf([PT.string, PT.node]),
};

export default FileInput;
