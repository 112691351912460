import {
  FocusableItem,
  MenuButton,
  MenuDivider,
  MenuItem,
  Menu as ReactMenu,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import PT from 'prop-types';
import React from 'react';

import { black2, evisortBlue } from '~/assets/shared-styles/general';
import EcTooltip from '~/components/Shared/EcTooltip';

import './react-menu_override.css';

const getMenuButtonStyles = (styles = {}) => (buttonStates) => {
  const defaultStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const { open } = buttonStates;

  if (open) {
    defaultStyles.backgroundColor = 'rgba(0, 127, 186, 0.1)';
    defaultStyles.borderColor = evisortBlue;
    defaultStyles.border = `solid 1px ${evisortBlue}`;
  }

  if (typeof styles === 'function') {
    styles = styles(buttonStates);
  }

  return {
    ...defaultStyles,
    ...styles,
  };
};

const getMenuItemStyles = (styles) => (itemStates) => {
  const defaultStyles = {
    color: black2,
  };

  const { active, disabled, hover } = itemStates;

  if (hover) {
    defaultStyles.backgroundColor = '#F2F8FB'; // override @szhsin/react-menu/dist/index.css with legacy select highlight color
    // defaultStyles.color = '#1eb4fa';
  }

  if (active) {
    defaultStyles.backgroundColor = 'rgba(0, 127, 186, 0.4)';
  }

  if (disabled) {
    defaultStyles.color = 'rgb(154, 154, 154)';
  }

  if (typeof styles === 'function') {
    styles = styles(itemStates);
  }

  return {
    ...defaultStyles,
    ...styles,
  };
};

function getChildren(headerItem, items, footerItem) {
  const children = [];

  if (headerItem) {
    children.push(<FocusableItem>{() => headerItem}</FocusableItem>);
  }

  children.push(items);

  if (footerItem) {
    children.push(
      <FocusableItem>{({ closeMenu }) => footerItem(closeMenu)}</FocusableItem>,
    );
  }

  return children;
}

function Menu({
  disabled,
  footerItem,
  headerItem,
  id,
  items,
  menuButtonProps = {},
  menuItemStyles,
  offsetY = 10,
  title,
  ...rest
}) {
  const listItems = items.map((item, index) => {
    if (item.isDivider) {
      return <MenuDivider key={`divider-${index}`} />;
    }
    return item.tooltip ? (
      <MenuItem
        {...item}
        key={item.id}
        styles={menuItemStyles || getMenuItemStyles(item.styles)}
      >
        <div data-tip data-for="helpDeleteMenuTooltip">
          {item.content}
          <EcTooltip id="helpDeleteMenuTooltip" place="right" width="400px">
            The selected item(s) contain synchronizations with external storage
            providers, please contact your Evisort admin to stop synchronization
            before deleting the selected item(s).
          </EcTooltip>
        </div>
      </MenuItem>
    ) : (
      <MenuItem
        {...item}
        key={item.id}
        styles={menuItemStyles || getMenuItemStyles(item.styles)}
      >
        {item.content}
      </MenuItem>
    );
  });

  return (
    <ReactMenu
      {...rest}
      animation={false}
      id={id}
      menuButton={
        menuButtonProps.render?.() || (
          <MenuButton
            styles={getMenuButtonStyles(menuButtonProps.styles)}
            disabled={disabled}
            aria-label={title}
          >
            {menuButtonProps.content}
          </MenuButton>
        )
      }
      offsetY={offsetY}
      title={title}
    >
      {getChildren(headerItem, listItems, footerItem)}
    </ReactMenu>
  );
}

export const itemPropType = PT.shape({
  content: PT.oneOfType([PT.string, PT.element]),
  id: PT.string.isRequired,
  onClick: PT.func,
  styles: PT.object,
});

Menu.propTypes = {
  disabled: PT.bool,
  footerItem: PT.element,
  headerItem: PT.element,
  id: PT.string,
  items: PT.arrayOf(itemPropType),
  menuButtonProps: PT.shape({
    content: PT.element,
    render: PT.func,
    styles: PT.object,
  }),
  menuItemStyles: PT.oneOfType([PT.object, PT.func]),
  title: PT.string,
};

export default Menu;
