import { atlas } from '~/services';
import { Uuid } from '~/types';

type DeleteQuestionGroupParams = {
  id: Uuid;
};

export const deleteQuestionGroup = async ({
  id,
}: DeleteQuestionGroupParams) => {
  return await atlas.remove(`question-groups/${id}`);
};
