import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { ENTER } from '~/constants/keyCodes';

import EcButton from '../../Shared/EcButton';
import EcInput from '../../Shared/EcInput';
import EcModalCard from '../../Shared/EcModalCard';
import { styles } from './ClientPartyModal.styles';

class ClientPartyModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnKeyboardPress = this.handleOnKeyboardPress.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);

    this.state = {
      name: this.props.name,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleOnKeyboardPress, true);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleOnKeyboardPress, true);
  }

  handleOnKeyboardPress(event) {
    const { name } = this.state;

    if (event.keyCode === ENTER && name.length) {
      this.handleActionClick();
    }
  }

  handleInputChangeName(name) {
    this.setState({ name });
  }

  handleInputFocus(event) {
    event.target.select();
  }

  handleActionClick() {
    const { name } = this.state;
    const { handleNameChange } = this.props;

    handleNameChange(name);
  }

  renderErrorMessage() {
    const { classes, errorMessage } = this.props;

    return errorMessage.length ? (
      <div
        className={classes.modalErrorMessage}
        data-testid="client-party-modal-error"
      >
        {errorMessage}
      </div>
    ) : null;
  }

  render() {
    const { name } = this.state;
    const {
      classes,
      confirmButtonIcon,
      confirmButtonText,
      hideModal,
      title,
    } = this.props;

    return (
      <EcModalCard
        testId="client-party-modal"
        title={<div data-testid="client-party-modal-title">{title}</div>}
        content={
          <div data-testid="client-party-modal-content">
            <label
              className={classes.modalInputLabel}
              htmlFor="ClientPartyModal/PartyName"
            >
              PARTY NAME
            </label>
            <EcInput
              id="ClientPartyModal/PartyName"
              input={{
                'data-testid': 'client-party-modal-name-input',
                value: name,
                onChange: (event) => {
                  this.handleInputChangeName(event.target.value?.trimLeft());
                },
                onFocus: (event) => {
                  this.handleInputFocus(event);
                },
              }}
              width="100%"
            />
            {this.renderErrorMessage()}
          </div>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              yellow
              iconLeft={confirmButtonIcon}
              text={confirmButtonText}
              onClick={this.handleActionClick}
              disabled={!name.trim().length}
              testId="client-party-modal-confirm-button"
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

ClientPartyModal.propTypes = {
  confirmButtonIcon: PropTypes.object,
  confirmButtonText: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  handleNameChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default injectSheet(styles)(ClientPartyModal);
