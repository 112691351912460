import { debounce, noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSegment } from '~/components/SegmentAnalytics';
import { RETRY_ERROR } from '~/constants/errors';
import { EVISORT_AI } from '~/constants/users';
import { types, useToast } from '~/eds';
import { HttpStatusCodeType } from '~/enums';
import { PROMPT_WORD_LIMIT } from '~/features/x-ray';
import { FlagType, useFlag } from '~/flags';
import { api, coerceRtkqError, selectors } from '~/redux';
import {
  mapUserRatingFromFeedbackDetails,
  MessageStreamEvent,
  parseServerMessage,
} from '~/redux/api/transformers';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { Entity, EntityType, Nullable, Uuid } from '~/types';

import { Chat } from '../chat';
import {
  THINKING_EVENTS_ALL_DONE,
  THINKING_EVENTS_FADE_TIME,
  THINKING_EVENTS_GENERATING_RESPONSE,
} from '../chatbot/constants';
import { useConversationPipeline } from '../hooks';
import {
  FeedbackDetails,
  MessageAction,
  Message as MessageType,
} from '../types';
import type { EntityContext, EntityContextItem } from '../types/';
import { Footer } from './Footer';
import {
  useDocumentStatusChecker,
  useGetMessageActions,
  useLegalDisclaimer,
  useSse,
} from './hooks';
import { NewConversation } from './NewConversation';
import {
  getStreamEndpoint,
  parseStreamEndMessage,
  replyAiMessage,
  replyUserMessage,
  SSE_REQUEST_PROPS,
  STATIC_CONTEXT_ACTIONS,
} from './utils';

export interface Props {
  entity: Entity<EntityType>;
  context?: EntityContext;
  selectedContext?: Nullable<EntityContextItem>;
  hasAskAnything?: boolean;
  disableInput?: boolean;
  onDisclaimerChange?: (hasAccepted: boolean) => void;
  onSelectContextFilter?: (context: Nullable<EntityContextItem>) => void;
  onSelectMessageSource: (message: MessageType, sourceIndex: number) => void;
  shouldDisableSources?: (message: MessageType) => boolean;
  panelProps?: types.SharedPanelProps;
  userMessageActions?: ({
    resolveIcon?: (message: MessageType) => types.IconType;
  } & MessageAction)[];
}

export const ChatBot = ({
  entity,
  context,
  selectedContext,
  hasAskAnything = false,
  panelProps,
  disableInput,
  userMessageActions,
  onDisclaimerChange,
  onSelectContextFilter,
  onSelectMessageSource,
  shouldDisableSources,
}: Props) => {
  const hasConversationalAIV2 = useFlag(FlagType.ConversationalAIV2);
  const { createSseConnection } = useSse();
  const { isActive, toast } = useToast();
  let toastId: string | number = '';
  let toastIndex = 0;
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [streamMessage, setStreamMessage] = useState<
    types.Nullable<MessageType>
  >(null);
  const [isLoadingReply, setIsLoadingReply] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [thinkingEvents, setThinkingEvents] = useState<string[]>([]);

  const isEntityDocumentVersion = entity.type === 'document_version';

  const messageActions = useGetMessageActions();

  // Since this component manages fetching conversation data, it should also dispatch the current conversation ID to the store.
  const dispatch = useDispatch();
  const currentConversationId = useSelector(
    selectors.selectCurrentConversationId,
  );

  const {
    data: conversations,
    isLoading: isLoadingConversations,
    isError: isErrorConversations,
    error: errorConversations,
    isSuccess: isSuccessGetConversations,
    refetch: refetchGetConversations,
  } = api.endpoints.getConversations.useQuery(entity);

  const [
    createConversation,
    {
      isLoading: isLoadingCreateConversation,
      isError: isErrorCreateConversation,
      error: errorCreateConversation,
    },
  ] = api.endpoints.createConversation.useMutation();
  const [
    getConversation,
    {
      isLoading: isLoadingGetConversation,
      isError: isErrorGetConversation,
      isUninitialized: isUninitializedGetConversation,
    },
  ] = api.endpoints.getConversation.useLazyQuery();

  const [
    postUserMessage,
    { isLoading: isLoadingSendMessage },
  ] = api.endpoints.postUserMessage.useMutation();

  const [
    interruptAiMessage,
    {
      isLoading: isInterruptingAiMessage,
      isSuccess: isInterruptingAiMessageSuccess,
    },
  ] = api.endpoints.interruptAiMessage.useMutation();

  const [postUserFeedback] = api.endpoints.postUserFeedback.useMutation();

  const onFinishDocumentProcessing = () => {
    if (
      errorCreateConversation &&
      (errorCreateConversation as Error).cause === HttpStatusCodeType.NotFound
    ) {
      handleNewConversation();
    }
  };
  const { statusMessage } = useDocumentStatusChecker({
    entity,
    onFinishProcessing: onFinishDocumentProcessing,
  });

  const hasAcceptedLegalDisclaimer =
    coerceRtkqError(errorConversations)?.request?.status !==
    HttpStatusCodeType.UnavailableForLegalReasons;

  const isLoadingRenderData =
    isSuccessGetConversations &&
    isUninitializedGetConversation &&
    !isErrorCreateConversation;

  const isLoadingConversation =
    isLoadingConversations ||
    isLoadingCreateConversation ||
    isLoadingGetConversation ||
    isLoadingRenderData;

  const isErrorConversation =
    (isErrorConversations && hasAcceptedLegalDisclaimer) ||
    isErrorCreateConversation ||
    isErrorGetConversation;
  const isPosting = isStreaming || isLoadingSendMessage;
  const disclaimer = useLegalDisclaimer({
    hasAccepted: hasAcceptedLegalDisclaimer,
    onAccept: refetchGetConversations,
  });

  useEffect(() => {
    onDisclaimerChange?.(disclaimer.hasAccepted);
  }, [hasAcceptedLegalDisclaimer]);

  useEffect(() => {
    if (conversations) {
      if (conversations.items.length > 0) {
        const currentConversationId = conversations.items[0].id;
        dispatch(
          chatbotSlice.actions.setCurrentConversationId(currentConversationId),
        );
      } else {
        handleNewConversation();
      }
    }
  }, [conversations]);

  useEffect(() => {
    if (currentConversationId) {
      getConversation({
        conversationId: currentConversationId,
      })
        .unwrap()
        .then((conversation) => {
          if (conversation) {
            setMessages(conversation.messages as MessageType[]);
          }
        });
    }
  }, [currentConversationId]);

  useEffect(() => {
    if (isInterruptingAiMessageSuccess) {
      // Clear the stream message if doesn't contain any text
      if (!streamMessage?.text) {
        setStreamMessage(null);
      }
    }
  }, [isInterruptingAiMessageSuccess]);

  const resetStream = () => {
    setStreamMessage(null);
    setIsStreaming(false);
    onFinishMessage();
  };

  const handleNewConversation = () =>
    createConversation(entity)
      .unwrap()
      .then((newConversationId) => {
        if (newConversationId && newConversationId !== currentConversationId) {
          dispatch(
            chatbotSlice.actions.setCurrentConversationId(newConversationId),
          );

          if (!hasAskAnything) {
            setStreamMessage(replyAiMessage());
            setIsStreaming(true);
            handleNewSseConnection(newConversationId);
          }
        }
      });

  const handleNewSseConnection = (id: Uuid) =>
    createSseConnection(
      getStreamEndpoint(id),
      {
        onAuthenticationError: resetStream,
        onClose: noop,
        onError: resetStream,
        onMessage: handleStreamMessage,
        onOpen: handleStreamOpen,
        onRateLimitError: resetStream,
      },
      SSE_REQUEST_PROPS,
    );

  const handleStreamOpen = () => {
    setThinkingEvents([]);
    setStreamMessage(replyAiMessage());
  };

  const clearStreamMessage = debounce(
    () => setStreamMessage(null),
    THINKING_EVENTS_FADE_TIME,
  );
  const debouncedSetMessages = useCallback(
    debounce((newMessage: MessageType) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    }, THINKING_EVENTS_FADE_TIME),
    [],
  );

  const runAfterAnimation = (fn: Function) => {
    setTimeout(() => fn(), THINKING_EVENTS_FADE_TIME);
  };

  const handleStreamMessage = (data: unknown) => {
    const messageEvent = data as MessageStreamEvent;
    switch (messageEvent.eventType) {
      case 'thinking':
        setThinkingEvents((prevEvents) => [
          ...prevEvents,
          messageEvent.text ?? '',
        ]);

        if (messageEvent.text === THINKING_EVENTS_GENERATING_RESPONSE) {
          setIsLoadingReply(true);
        }
        break;
      case 'message_start': {
        setStreamMessage((prevMessage) => {
          if (prevMessage) {
            return { ...prevMessage, text: messageEvent.text ?? '' };
          }
          return prevMessage;
        });
        break;
      }
      case 'message_delta': {
        setIsLoadingReply(false);
        if (messageEvent?.text) {
          setStreamMessage((prevMessage) => {
            if (prevMessage) {
              const text = prevMessage.text + messageEvent.text;
              return {
                ...prevMessage,
                text: text,
              };
            }
            return prevMessage;
          });
        }
        break;
      }
      case 'message_response_suggestion': {
        setStreamMessage((prevMessage) => {
          if (prevMessage && messageEvent?.text) {
            const updatedSuggestions = prevMessage.choices
              ? [...prevMessage.choices, messageEvent.text]
              : [messageEvent.text];
            return { ...prevMessage, choices: updatedSuggestions };
          }
          return prevMessage;
        });
        break;
      }
      case 'message_end': {
        if (messageEvent.message) {
          const newMessage = parseServerMessage(messageEvent.message);
          debouncedSetMessages(newMessage);
        }
        setThinkingEvents((prevEvents) => [
          ...prevEvents,
          THINKING_EVENTS_ALL_DONE,
        ]);
        clearStreamMessage();

        break;
      }
      case 'stream_end': {
        setIsStreaming(false);
        runAfterAnimation(onFinishMessage);
        if (messageEvent.text) {
          const serverMessage = parseStreamEndMessage(messageEvent.text);
          showToast(serverMessage);
        }
        clearStreamMessage();
        break;
      }
      default:
        return;
    }
  };
  const handleSubmitFeedback = useCallback(
    (message: MessageType, feedbackDetails: FeedbackDetails) => {
      postUserFeedback({
        conversationId: currentConversationId!,
        messageId: message.id,
        feedbackDetails,
      })
        .unwrap()
        .then(() => {
          trackSegment('selectChatMessageFeedback', {
            messageId: message.id,
            userRating: mapUserRatingFromFeedbackDetails(feedbackDetails),
          });
        })
        .catch((error) => showToast(error?.detail ?? RETRY_ERROR));
    },
    [currentConversationId],
  );

  const handleChoose = useCallback((message: MessageType, choice: string) => {
    trackSegment('selectChatMessageChoice', {
      messageId: message.id,
      choice,
    });
  }, []);
  const handlePost = useCallback(
    (
      updatedText: string,
      metadata?: { questionId: Uuid; questionGroupId: Uuid },
    ) => {
      if (hasAskAnything && !selectedContext) {
        showToast('Please select a context to continue.');
      } else {
        if (currentConversationId) {
          setMessages((prevMessages) => [
            ...prevMessages,
            replyUserMessage(updatedText),
          ]);
          postUserMessage({
            conversationId: currentConversationId,
            message: updatedText,
            metadata: metadata,
            context: selectedContext
              ? [
                  {
                    search_entity: selectedContext.searchEntity,
                    search_query: selectedContext.query,
                    selected_ids: selectedContext.selectedIds,
                    entities_in_view: selectedContext.searchEntityContext,
                  },
                ]
              : [],
          })
            .unwrap()
            .then((message) => {
              if (message) {
                setMessages((prevMessages) => {
                  const updatedMessages = [...prevMessages];
                  const userMessage = updatedMessages.pop();
                  updatedMessages.push({
                    ...userMessage,
                    ...(message as MessageType),
                  });
                  return updatedMessages;
                });
                setIsStreaming(true);
                handleNewSseConnection(currentConversationId);
                trackSegment('enterChatQuestion', {
                  conversationId: currentConversationId,
                });
              }
            })
            .catch((error) => {
              onFinishMessage();
              showToast(error?.detail ?? RETRY_ERROR);
            });
        }
      }
    },
    [currentConversationId, selectedContext, hasAskAnything],
  );

  // this will allow other components to call the handlePost function
  // returning noop if no context is selected
  const { onFinishMessage } = useConversationPipeline({
    processHandler: selectedContext ? handlePost : noop,
  });

  const handleInterruptMessage = useCallback(() => {
    if (currentConversationId) {
      interruptAiMessage(currentConversationId);
      trackSegment('chatStopGenerating', {
        conversationId: currentConversationId,
      });
    }
  }, [currentConversationId]);

  // TODO: Update API to only send the handlerFunction
  const interruptAction = useMemo(() => {
    return {
      icon: 'circle-stop',
      isLoading: isInterruptingAiMessage,
      onClick: handleInterruptMessage,
      text: 'Stop Generating',
    };
  }, [handleInterruptMessage, isInterruptingAiMessage]);

  const showToast = (message: string) => {
    if (!isActive(toastId)) {
      toastId = toast({
        message,
        status: 'danger',
        key: `chatbot-error-${toastIndex}`,
        onDismiss: () => toastIndex++,
      });
    }
  };

  const loadingContent = disclaimer.hasAccepted
    ? {
        isLoading: isLoadingConversation,
        message: 'Loading chat…',
      }
    : {
        isLoading: false,
      };

  const placeholderContent =
    isErrorCreateConversation &&
    conversations &&
    conversations.items.length === 0
      ? {
          icon: 'chatbot' as const,
          message:
            'Unable to create a conversation. If the document is still processing, Ask AI will automatically refresh when it is ready.',
        }
      : isErrorConversation
      ? {
          icon: 'chatbot' as const,
          message:
            'Unable to load chat history. Refresh the page to try again.',
        }
      : undefined;

  const moreActions = hasAcceptedLegalDisclaimer
    ? [
        {
          text: 'Clear your chat history',
          tooltip: isStreaming ? 'Disabled while generating.' : undefined,
          disabled: isLoadingConversation || isStreaming,
          onClick: () => {
            setMessages([]);
            handleNewConversation();
            trackSegment('selectClearChatHistory', {
              conversationId: currentConversationId,
            });
          },
        },
      ]
    : [];
  const contextFilters: types.UserAction[] = useMemo(
    () =>
      (context ?? []).map((contextItem) => ({
        text: contextItem.label,
        onClick: () => {
          // Disable callback if scope question already selected
          if (selectedContext?.label !== contextItem.label) {
            onSelectContextFilter?.(contextItem);
          }
        },
        mode: 'chip' as const,
        level:
          selectedContext?.label === contextItem.label
            ? 'secondary-active'
            : undefined,
      })),
    [context, selectedContext, isEntityDocumentVersion],
  );

  const footer = useMemo(() => <Footer entity={entity} />, []);

  const getActiveContext = () => {
    if (!hasAskAnything) return undefined;

    if (selectedContext) {
      return {
        icon: selectedContext.icon,
        text: selectedContext.title,
        onReset: () => onSelectContextFilter?.(null),
        // Disable reset if the entity is a document version interim solution while we fully support scope switch
        disableReset: !hasConversationalAIV2
          ? entity.type === 'document_version'
          : false,
      };
    } else {
      return {
        icon: 'filter' as const,
        text: 'Documents',
        onReset: undefined,
      };
    }
  };

  return (
    <Chat
      disclaimer={disclaimer}
      footer={!hasAskAnything ? footer : null}
      statusMessage={statusMessage}
      context={
        hasAskAnything
          ? {
              label: 'Search',
              actions: contextFilters,
            }
          : undefined
      }
      disableInput={disableInput}
      activeContext={getActiveContext()}
      activeContextActions={STATIC_CONTEXT_ACTIONS}
      interruptAction={interruptAction}
      emptyConversation={hasAskAnything ? <NewConversation /> : undefined}
      isPosting={isPosting}
      isPrivate={true}
      limit={PROMPT_WORD_LIMIT}
      loadingContent={loadingContent}
      isLoadingReply={isLoadingReply}
      messages={messages}
      moreActions={moreActions}
      otherUser={EVISORT_AI}
      placeholderContent={placeholderContent}
      replyMessage={streamMessage}
      shouldDisableSources={shouldDisableSources}
      title="Conversational AI"
      messageActions={messageActions}
      panelProps={panelProps}
      chatActions={
        hasAskAnything
          ? [
              {
                icon: 'plus',
                mode: 'icon',
                text: '',
                tooltip: 'New Conversation',
                onClick: () => {
                  setMessages([]);
                  handleNewConversation();
                  trackSegment('selectClearChatHistory', {
                    conversationId: currentConversationId,
                  });
                },
              },
            ]
          : []
      }
      onChoose={handleChoose}
      onPost={handlePost}
      onSelectMessageSource={onSelectMessageSource}
      onSubmitFeedback={handleSubmitFeedback}
      thinkingEvents={thinkingEvents}
      userMessageActions={userMessageActions}
    />
  );
};
