import { pilot } from '~/services';
import {
  AddToGroupParams,
  CreateDocumentGroupParams,
  DocumentGroup,
  PilotId,
  UnlinkDocumentsFromGroup,
} from '~/types';

import { List } from '../ApiTypes';

export const getAllDocumentGroups = (): Promise<List<DocumentGroup>> =>
  pilot.get('group/');

export const getDocumentGroup = ({
  id,
}: {
  id: string;
}): Promise<DocumentGroup> => pilot.get(`group/${id}/`);

export const addDocumentsToGroup = ({
  groupId,
  documentIds,
  query,
  query_v3,
}: AddToGroupParams): Promise<DocumentGroup> =>
  pilot.post(`/group/${groupId}/documents/`, {
    documents: documentIds,
    query,
    query_v3,
  });

export const addDocumentsToGroupByFolder = ({
  groupId,
  folder,
}: AddToGroupParams): Promise<DocumentGroup> =>
  pilot.post(`/group/${groupId}/folder/`, { folder });

export const addDocumentsToGroupLinkParent = ({
  groupId,
  parentId,
  documentIds,
  query,
  query_v3,
  folder,
}: AddToGroupParams): Promise<DocumentGroup> =>
  pilot.post(`/group/${groupId}/documents/link-parent/${parentId}/`, {
    documents: documentIds,
    query,
    query_v3,
    folder,
  });

export const linkDocumentsToGroup = ({
  groupId,
  parentId,
  children,
}: AddToGroupParams): Promise<DocumentGroup> =>
  pilot.patch(`/group/${groupId}/link-documents/${parentId}/`, { children });

export const unlinkDocumentsFromGroup = ({
  groupId,
  documentGroupIds,
  selectAll,
}: UnlinkDocumentsFromGroup): Promise<DocumentGroup> =>
  pilot.patch(`/group/${groupId}/documents/detach-parent/`, {
    document_group_ids: documentGroupIds,
    select_all: selectAll,
  });

export const createDocumentGroup = (
  data: CreateDocumentGroupParams,
): Promise<DocumentGroup> => pilot.post('group/', data);

export const removeDocumentInGroup = (
  docGroupId: number,
): Promise<DocumentGroup> => pilot.remove(`/group/document/${docGroupId}/`);

type EditDocumentGroupParams = {
  groupId: PilotId;
  data: {
    name: string;
    note: string;
  };
};
export const editDocumentGroup = ({ groupId, data }: EditDocumentGroupParams) =>
  pilot.patch(`group/${groupId}/`, data);

export const deleteDocumentGroup = (groupId: PilotId) =>
  pilot.remove(`group/${groupId}/`);
