import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SEARCH_DEBOUNCE_MS } from '~/constants/debounces';
import { Accordion, Layout, SearchInput, Text, useThrottledValue } from '~/eds';
import { getSectionById, getSectionIds } from '~/reducers/intakeForm';
import { selectors } from '~/redux';
import { Nullable } from '~/types';

import { INTAKE_FORM_SECTION_ID } from '../constants';
import { WorkflowFormSection } from './WorkflowFormSection';

interface Props {
  isEditing: boolean;
}

export const WorkflowForm = ({ isEditing }: Props) => {
  const [search, setSearch] = useState('');
  const throttledSearch = useThrottledValue(search, SEARCH_DEBOUNCE_MS);
  const intakeForm = useSelector(selectors.selectIntakeForm);
  const sections = intakeForm.sections ? getSectionIds(intakeForm) : [];
  const accordionItems = sections.map((id) => {
    const section = getSectionById(intakeForm, id);

    return {
      content: (
        <WorkflowFormSection
          isEditing={isEditing}
          search={throttledSearch}
          section={section}
        />
      ),
      hash: section.name,
      headerClass: INTAKE_FORM_SECTION_ID,
      isExpanded: true,
      title: section.name,
    };
  });

  const handleSearch = (updatedSearch: Nullable<string>) =>
    setSearch(updatedSearch || '');

  return (
    <Layout direction="column" spacing={2}>
      <Layout direction="column" px={4} spacing={2}>
        <SearchInput
          placeholder="Search Form"
          name="Search Form"
          value={search}
          onChange={handleSearch}
        />
        {intakeForm.description && (
          <Text preset="description">{intakeForm.description}</Text>
        )}
      </Layout>
      <Accordion isIndented items={accordionItems} />
    </Layout>
  );
};
