import React from 'react';
import injectSheet from 'react-jss';

import { ContentContainer } from '~/eds';

import styles from './SplashPage.styles';

// TODO: remove this component indirection
const SplashPage = (props) => {
  const { classes } = props;
  return (
    <div className={classes.splashPage}>
      <ContentContainer loadingContent={{ isLoading: true }} />
    </div>
  );
};

export default injectSheet(styles)(SplashPage);
