import PropTypes from 'prop-types';
import React from 'react';

import { evisortBlue } from '../../../assets/shared-styles/general';

const SubArrowIcon = (props) => {
  let color = props.color;
  let opacity = props.opacity;

  if (props.isActive) {
    color = evisortBlue;
    opacity = '1';
  }

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
          d="M18.29 15.71l-4.58 4.58c-.39.39-1.03.39-1.42 0-.39-.39-.39-1.03 0-1.42L15.17 16H5c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1s1 .45 1 1v9h9.17l-2.88-2.87c-.39-.39-.39-1.03 0-1.42.39-.39 1.03-.39 1.42 0l4.58 4.58c.39.39.39 1.03 0 1.42z"
        />
      </g>
    </svg>
  );
};

SubArrowIcon.defaultProps = {
  size: '24',
  color: '#fff',
  opacity: '1',
};

SubArrowIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
};

export default SubArrowIcon;
