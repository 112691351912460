import { Icon, Layout, LoadingShimmer, NumberText, Text, Tooltip } from '~/eds';

import { ERROR_TOOLTIP } from './constants';

interface Props {
  // title to display for balance
  title: string;
  // Available Credits.
  credits: number;
  // Fail to retrieve credits
  isError?: boolean;
  // Are the credits loading.
  isLoading?: boolean;
  /** status to use for text color. */
  status?: 'active' | 'danger' | 'success';
  // String to display inside of tooltip
  tooltip?: string;
}

export const CreditBalanceDisplay = ({
  credits,
  isError,
  isLoading,
  status = 'active',
  title,
  tooltip,
}: Props) => {
  return (
    <Tooltip tooltip={isError ? ERROR_TOOLTIP : tooltip}>
      <Layout direction="column">
        <Layout>
          <Text preset="description"> {title} </Text>
          <Icon icon="info" />
        </Layout>
        {isLoading ? (
          <LoadingShimmer height="20px" width="100px" />
        ) : isError ? (
          <Layout>
            <Text preset="error">-</Text>
            <Icon icon="status-warning" color="status.danger" />
          </Layout>
        ) : (
          <Layout>
            <NumberText number={credits} status={status} />
            {status === 'danger' && (
              <Icon icon="status-warning" color="status.danger" />
            )}
          </Layout>
        )}
      </Layout>
    </Tooltip>
  );
};
