import {
  black2,
  black4,
  black5,
  semibold,
} from '../../../assets/shared-styles/general';

const listItemStyles = {
  margin: '0 16px',
  padding: '16px 0',
  fontSize: '15px',
  '&:not(:last-child)': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  '& button': {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
};

const styles = {
  listItem: {
    ...listItemStyles,
    color: black2,
  },
  selectedListItem: {
    ...listItemStyles,
    color: black5,
    pointerEvents: 'none',
  },
  filtersSearch: {
    marginBottom: '20px',
  },
  emptyListState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  emptyListTitle: {
    marginBottom: '8px',
    color: black2,
    fontSize: '17px',
    fontWeight: semibold,
  },
  emptyListSubtitle: {
    color: black4,
    fontSize: '15px',
  },
};

export default styles;
