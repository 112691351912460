import React from 'react';

import UserRoleSingleSelect from '~/components/Client/UserRoleSingleSelect';
import { workflowTicketRoleOptions } from '~/constants/workflow';

const WorkflowUserRoleSingleSelect = (props) => {
  return (
    <UserRoleSingleSelect {...props} roleOptions={workflowTicketRoleOptions} />
  );
};

export default WorkflowUserRoleSingleSelect;
