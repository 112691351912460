import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import injectSheet from 'react-jss';

import { Tooltip } from '~/eds';

import styles from './EcButton.styles';

const EcButton = forwardRef(
  (
    {
      ariahaspopup,
      classes,
      disabled,
      highlighted,
      iconLeft,
      iconRight,
      id,
      onClick,
      onKeyDown,
      tabIndex = '0',
      testId,
      text,
      tooltip,
      type,
    },
    ref,
  ) => (
    <Tooltip tooltip={tooltip}>
      <button
        aria-haspopup={ariahaspopup}
        id={id}
        className={classes.evisortButton}
        data-testid={testId}
        disabled={disabled}
        {...(onClick && { onClick })}
        {...(onKeyDown && { onKeyDown })}
        tabIndex={tabIndex}
        {...(type && { type })}
        {...(highlighted && { highlighted })}
        ref={ref}
      >
        {iconLeft ? iconLeft : null}
        <span className={classes.evisortButtonText}>{text ? text : null}</span>
        {iconRight ? iconRight : null}
      </button>
    </Tooltip>
  ),
);

EcButton.defaultProps = {
  height: '36px',
  fontSize: '14px',
  type: 'button',
};

EcButton.propTypes = {
  id: PropTypes.string,
  ariahaspopup: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  clicked: PropTypes.bool,
  yellow: PropTypes.bool,
  red: PropTypes.bool,
  highlighted: PropTypes.string,
  iconLeft: PropTypes.element,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textAlign: PropTypes.string,
  fontSize: PropTypes.string,
  iconRight: PropTypes.element,
  onClick: PropTypes.func,
  testId: PropTypes.string,
  // TODO: consolidate all button variants in new `Button` component
  borderless: PropTypes.bool,
  inverse: PropTypes.bool,
};

export default injectSheet(styles)(EcButton);
