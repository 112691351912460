import React, { Fragment } from 'react';
import injectSheet from 'react-jss';

import styles from '~/components/AnalyzerPage/AnalyzersQueryTable/AnalyzersQueryTable.styles';
import EcTooltip from '~/components/Shared/EcTooltip';
import { DataFieldType } from '~/enums';

let SEARCH_ITEM_INDEX = 0;

const SearchQueryEntry = ({ classes, query }) => {
  function renderFieldAttachmentEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    // Look into refactoring EcToolTip into Tooltip for the rest of these methods
    return (
      <Fragment key={`string-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`string-entity-${entityIndex}`}
        >
          {search.name}
        </span>
        <EcTooltip
          id={`string-entity-${entityIndex}`}
          place="right"
          width="280px"
        >
          <div className={classes.tooltip}>
            <span>{search.label}</span>
            &nbsp;
            {search.relation ? search.relation.label : ''}
            {search.first_value && <span>{` ${search.first_value}`}</span>}
            {search.second_value && (
              <>
                {' and '}
                <span>{search.second_value}</span>
              </>
            )}
            .
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function renderFieldStringEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`string-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`string-entity-${entityIndex}`}
        >
          {search.name}
        </span>
        <EcTooltip
          id={`string-entity-${entityIndex}`}
          place="right"
          width="280px"
        >
          <div className={classes.tooltip}>
            <span>{search.label}</span>
            &nbsp;
            {search.relation ? search.relation.label : ''}
            {search.first_value ? (
              <span>{` ${search.first_value}`}</span>
            ) : null}
            {search.second_value ? (
              <>
                {' and '}
                <span>{search.second_value}</span>
              </>
            ) : null}
            .
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function renderFieldNumberEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`number-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`number-entity-${entityIndex}`}
        >
          {search.name}
        </span>
        <EcTooltip id={`number-entity-${entityIndex}`} place="right">
          <div className={classes.tooltip}>
            <span>{search.label}</span>
            &nbsp;
            {search.relation ? search.relation.label : ''}
            {search.first_value ? (
              <span>{` ${search.first_value}`}</span>
            ) : null}
            {search.second_value ? (
              <>
                {' and '}
                <span>{search.second_value}</span>
              </>
            ) : null}
            .
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function renderFieldDateEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`date-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`date-entity-${entityIndex}`}
        >
          {search.name}
        </span>
        <EcTooltip id={`date-entity-${entityIndex}`} place="right">
          <div className={classes.tooltip}>
            <span>{search.label}</span>
            &nbsp;
            {search.relation ? search.relation.label : ''}
            {search.first_value ? (
              <span>{` ${search.first_value}`}</span>
            ) : null}
            {search.second_value ? (
              Date.parse(search.second_value) ? (
                <>
                  {' and '}
                  <span>{search.second_value}</span>
                </>
              ) : (
                <span>{` ${search.second_value.toLowerCase()}(s)`}</span>
              )
            ) : null}
            .
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function renderFieldArraySingleEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`array-single-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`array-single-entity-${entityIndex}`}
        >
          {search.name}
        </span>
        <EcTooltip id={`array-single-entity-${entityIndex}`} place="right">
          <div className={classes.tooltip}>
            <span>{search.label}</span>
            &nbsp;
            {search.relation ? search.relation.label : ''}
            {search.first_value ? (
              <span>{` ${search.first_value}`}</span>
            ) : null}
            .
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function renderFieldArrayMultipleEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`array-multiple-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`array-multiple-entity-${entityIndex}`}
        >
          {search.name}
        </span>
        <EcTooltip
          id={`array-multiple-entity-${entityIndex}`}
          place="right"
          width="280px"
        >
          <div className={classes.tooltip}>
            <span>{search.label}</span>
            &nbsp;
            {search.first_value && search.first_value.length ? (
              <>
                {search.relation ? search.relation.label : ''}
                &nbsp;&mdash;&nbsp;
                {search.first_value
                  .map((val, index) => (
                    <span key={`${val}-${index}`}>{`“${val}“`}</span>
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr], [])}
              </>
            ) : search.relation ? (
              search.relation.label
            ) : (
              ''
            )}
            .
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function renderFieldBooleanEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`boolean-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`boolean-entity-${entityIndex}`}
        >
          {search.name}
        </span>
        <EcTooltip id={`boolean-entity-${entityIndex}`} place="right">
          <div className={classes.tooltip}>
            <span>{search.label}</span>
            &nbsp;
            {search.relation ? (
              search.relation.label
            ) : (
              <>
                {'equals '}
                <span>{search.first_value}</span>
              </>
            )}
            .
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function renderBooleanTextSearchEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`boolean-text-search-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`boolean-text-search-entity-${entityIndex}`}
        >
          Boolean Text Search
        </span>
        <EcTooltip
          id={`boolean-text-search-entity-${entityIndex}`}
          place="right"
          width="280px"
        >
          <div className={classes.tooltip}>
            <span>Boolean Text Search</span>
            &nbsp;&mdash;&nbsp;
            {`“${search.query}”.`}
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function renderFieldEntity(search) {
    switch (search.type) {
      case DataFieldType.ARRAY_MULTIPLE:
        return renderFieldArrayMultipleEntity(search);
      case DataFieldType.ARRAY_SINGLE:
        return renderFieldArraySingleEntity(search);
      case DataFieldType.BOOLEAN:
        return renderFieldBooleanEntity(search);
      case DataFieldType.DATE:
        return renderFieldDateEntity(search);
      case DataFieldType.NUMBER:
        return renderFieldNumberEntity(search);
      case DataFieldType.TEXT_AREA:
      case DataFieldType.STRING:
        return renderFieldStringEntity(search);
      case DataFieldType.ATTACHMENT:
        return renderFieldAttachmentEntity(search);
      default:
        return null;
    }
  }

  function renderFolderEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`folder-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`folder-entity-${entityIndex}`}
        >
          Folders
        </span>
        <EcTooltip id={`folder-entity-${entityIndex}`} place="right">
          <div className={classes.tooltip}>
            {search.folders?.map((folder) => {
              const path = folder.path
                .map((pathFolder) => pathFolder.name)
                .join(' → ');

              return (
                <div className={classes.recentFoldersTooltip} key={folder.name}>
                  <span>{folder.name}</span> ({path})
                </div>
              );
            })}
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function renderOperatorEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`operator-entity-${entityIndex}`}>
        <span className={classes.recentSearchOperator}>
          {search.operator.toUpperCase()}
        </span>
      </Fragment>
    );
  }

  function renderProvisionEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`provision-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`provision-entity-${entityIndex}`}
        >
          {search.provision}
        </span>
        <EcTooltip
          id={`provision-entity-${entityIndex}`}
          place="right"
          width="280px"
        >
          <div className={classes.tooltip}>
            {search.contains.label}
            &nbsp;
            {search.entity}
            &nbsp;
            <span>{search.provision}</span>
            &nbsp;
            {search.text_search.length ? (
              <>
                {'and provision '}
                {search.text_search
                  .map((item, index) => (
                    <span key={`${item.text}-${index}`}>
                      {item.contains.label}
                      &nbsp;
                      {item.scope.label}
                      &nbsp;
                      {`“${item.text}“`}
                    </span>
                  ))
                  .reduce((prev, curr) => [prev, ' and ', curr])}
              </>
            ) : null}
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function renderSectionEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`section-entity-${entityIndex}`}>
        <div className={classes.recentSearchSection}>
          <span className={classes.recentSearchSectionSeparator}>(</span>
          {generateSearchContent(search.section)}
          <span className={classes.recentSearchSectionSeparator}>)</span>
        </div>
      </Fragment>
    );
  }

  function renderTextSearchEntity(search) {
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`text-search-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`text-search-entity-${entityIndex}`}
        >
          Text Search
        </span>
        <EcTooltip
          id={`text-search-entity-${entityIndex}`}
          place="right"
          width="280px"
        >
          <div className={classes.tooltip}>
            <span>{search.area.label}</span>
            &nbsp;
            {search.contains.label}
            &nbsp;
            {search.scope.label}
            &nbsp; &mdash; &nbsp;
            {`“${search.text}”.`}
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  function generateSearchContent(searches) {
    if (searches && searches.length) {
      return searches.map((search) => {
        switch (search.entity) {
          case 'bool_text_search':
            return renderBooleanTextSearchEntity(search);
          case 'field':
            return renderFieldEntity(search);
          case 'folder':
            return renderFolderEntity(search);
          case 'operator':
            return renderOperatorEntity(search);
          case 'provision':
            return renderProvisionEntity(search);
          case 'section':
            return renderSectionEntity(search);
          case 'text_search':
            return renderTextSearchEntity(search);
          default:
            return null;
        }
      });
    } else return null;
  }

  return <>{generateSearchContent(query)}</>;
};

export default injectSheet(styles)(SearchQueryEntry);
