import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
  integrationsSetSyncPairs,
} from '~/actions/integrations';
import { getSyncPairs } from '~/api';
import { storageIntegrations } from '~/constants/integrations';
import { Icon, Text } from '~/eds';
import { ManageSyncPairStageType, StorageProviderType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import { useRouting } from '~/routing';
import { FlexLayout } from '~/ui';
import { createSyncPairData } from '~/utils';

import { getSyncPairCardStatus } from '../util';
import IntegrationCardList from './IntegrationCardList';

function StorageIntegrationCardList({
  isCentered = false,
  // connected
  syncPairs,
  integrationsSetSyncPairs,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const hideAwsS3SyncPairOption = useFlag(FlagType.HideAwsS3SyncPairOption);
  const { params } = useRouting();

  const { clientId } = params;

  useAsync(
    getSyncPairs,
    { clientId },
    {
      condition: true,
      errorToastMessage: 'Unable to fetch sync pairs, please try again.',
      successHandler: integrationsSetSyncPairs,
    },
  );

  const renderSyncPairCardStatus = ({ color, icon, count }) => {
    if (!count || count <= 0) {
      return <Icon color={color} icon={icon} />;
    }

    return (
      <FlexLayout alignItems="center" space={2}>
        <Icon color={color} icon={icon} />
        <Text color={color} variant="tiny-bold">
          {count}
        </Text>
      </FlexLayout>
    );
  };

  let integrationCards = Object.values(storageIntegrations)
    .filter((integration) => {
      if (hideAwsS3SyncPairOption) {
        return integration.provider !== StorageProviderType.AmazonS3;
      }

      return integration;
    })
    .map((integration) => ({
      ...integration,
      status: renderSyncPairCardStatus(
        getSyncPairCardStatus(integration.provider, syncPairs),
      ),
      description: `Synchronize documents stored on ${integration.name} with Evisort.`,
      action: {
        id: OnboardingIdType.AddSyncPairLink,
        text: 'Add Sync Pair',
        onClick: () => {
          const syncPairData = createSyncPairData(integration);
          integrationsSetActiveSyncPair(syncPairData);
          integrationsSetManageSyncPairStage(
            ManageSyncPairStageType.Authentication,
          );
        },
      },
    }));

  return (
    <IntegrationCardList
      isCentered={isCentered}
      integrationCards={integrationCards}
    />
  );
}

const mapStateToProps = ({ integrations }) => ({
  syncPairs: Object.values(integrations.syncPairs).map((syncPair) => ({
    ...storageIntegrations[syncPair.provider],
    ...syncPair,
  })),
});

export default connect(mapStateToProps, {
  integrationsSetSyncPairs,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(StorageIntegrationCardList);
