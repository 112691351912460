export const COLOR_PALETTE = [
  '#699AFF',
  '#F7D96C',
  '#FBA05E',
  '#6C6EC9',
  '#69C0FF',
  '#9BDA5D',
  '#5DBCDA',
  '#5DD3DA',
  '#323232',
  '#979797',
];

export const BARCHART_COLOR = '#1684D0';
export const UNACTIVE_CHART_COLOR = '#D8D8D8';
export const SEARCH_COUNT_SINGLE_VALUE_ID = 'search_count_chart';
export const INTERVALS = [
  {
    label: 'Day',
    value: 'DAY',
  },
  {
    label: 'Week',
    value: 'WEEK',
  },
  {
    label: 'Month',
    value: 'MONTH',
  },
  {
    label: '3 months',
    value: 'QUARTER',
  },
  {
    label: 'Year',
    value: 'YEAR',
  },
];

export const CHART_WIDTHS_2_COLUMNS = [
  {
    value: '1',
    label: 'Half width',
  },
  {
    value: '2',
    label: 'Full',
  },
];
export const CHART_WIDTHS_3_COLUMNS = [
  {
    value: '1',
    label: 'One third',
  },
  {
    value: '2',
    label: 'Two thirds',
  },
  {
    value: '3',
    label: 'Full',
  },
];

export const CHART_TYPES = [
  {
    value: 'vertical_bar',
    label: 'Vertical Bar chart',
  },
  {
    value: 'line',
    label: 'Line chart',
  },
];

export const MAX_BUCKET_LENGTH = 156;
