import { useEffect, useState } from 'react';

import { INPUT_DEBOUNCE_MS } from '../constants';

export const useDebouncedValue = <T>(value: T, ms = INPUT_DEBOUNCE_MS): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedValue(value);
    }, ms);

    return () => {
      clearTimeout(timerId);
    };
  }, [value, ms]);

  return debouncedValue;
};
