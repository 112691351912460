export default `
<svg
  aria-hidden="true"
  aria-label="pin"
  width="10"
  height="14"
  viewBox="0 0 10 14"
  fill="none"
>
  <path d="M0.845735 1.16671C0.845735 0.723999 1.21032 0.333374 1.67907 0.333374H8.34573C8.78844 0.333374 9.17907 0.723999 9.17907 1.16671C9.17907 1.63546 8.78844 2.00004 8.34573 2.00004H7.56448L7.85094 5.88025C8.81448 6.40108 9.56969 7.26046 9.93428 8.32817L9.96032 8.40629C10.0384 8.66671 10.0124 8.95317 9.85615 9.1615C9.6999 9.36983 9.43948 9.50004 9.17907 9.50004H0.845735C0.559276 9.50004 0.324901 9.39587 0.168651 9.1615C-0.0136403 8.95317 -0.0396819 8.66671 0.0384431 8.40629L0.0644847 8.32817C0.429068 7.26046 1.18428 6.40108 2.14782 5.88025L2.43428 2.00004H1.67907C1.21032 2.00004 0.845735 1.63546 0.845735 1.16671ZM4.17907 10.3334H5.84573V12.8334C5.84573 13.3021 5.45511 13.6667 5.0124 13.6667C4.54365 13.6667 4.17907 13.3021 4.17907 12.8334V10.3334Z" fill="currentcolor"/>
</svg>
`;
