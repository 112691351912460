import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component, createRef, Fragment } from 'react';
import { DebounceInput } from 'react-debounce-input';
import Highlighter from 'react-highlight-words';
import injectSheet from 'react-jss';

import { validateBooleanQuery } from '~/api/search';
import { SEARCH_DEBOUNCE_MS } from '~/constants/debounces';
import { ENTER } from '~/constants/keyCodes';

import { black4, evisortBlue } from '../../../assets/shared-styles/general';
import { documentTypeIcon } from '../../DocumentsPage/Document.utils';
import EcBooleanTextSearchPopup from '../../Shared/EcBooleanTextSearchPopup';
import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import EcMultipleLocationsLink from '../../Shared/EcMultipleLocationsLink';
import AnalyzerResultsEmptyStateIcon from '../../Shared/Icons/AnalyzerResultsEmptyStateIcon';
import ChevronRightIcon from '../../Shared/Icons/ChevronRightIcon';
import EnterIcon from '../../Shared/Icons/EnterIcon';
import HelpIcon from '../../Shared/Icons/HelpIcon';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import PlusIcon from '../../Shared/Icons/PlusIcon';
import SearchIcon from '../../Shared/Icons/SearchIcon';
import { getRecentlyViewed, searchDocuments } from './AutocompleteModal.data';
import styles from './AutocompleteModal.styles';

const MIN_QUERY_LENGTH = 3;

class AutocompleteModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnInputChange = this.handleOnInputChange.bind(this);
    this.handleOnInputKeyDown = this.handleOnInputKeyDown.bind(this);
    this.handleBooleanPopupOpen = this.handleBooleanPopupOpen.bind(this);
    this.handleBooleanPopupClose = this.handleBooleanPopupClose.bind(this);
    this.handleOnDocumentClick = this.handleOnDocumentClick.bind(this);

    this.state = {
      searchQuery: '',
      loading: false,
      loadingError: false,
      validationError: null,
      validationWarning: null,
      defaultResults: [],
      results: [],
      remainingResults: 0,
      totalResultsCount: 0,
      isSyntaxPopupOpen: false,
    };
  }

  helpButton = createRef();

  componentDidMount() {
    const { forGroupTreeView } = this.props;
    // getRecentlyViewed does not have document ID for add to group to work
    if (!forGroupTreeView) {
      this.setState({ loading: true });

      getRecentlyViewed().then((res) => {
        const recentlyViewedList = res.results.map((item) => ({
          fileType: item.file_type,
          label: item.document_name,
          id: item.document_handler_id,
          path: item.path,
        }));
        this.setState({ loading: false, defaultResults: recentlyViewedList });
      });
    }
  }

  handleBooleanPopupOpen() {
    this.setState({ isSyntaxPopupOpen: true });
  }

  handleBooleanPopupClose() {
    this.setState({ isSyntaxPopupOpen: false });
    this.helpButton.current.focus();
  }

  handleOnInputChange(event) {
    const query = event.target.value;
    this.setState({ searchQuery: query });

    if (query.length >= MIN_QUERY_LENGTH) {
      validateBooleanQuery(query)
        .then((res) => {
          this.setState({
            loading: true,
            loadingError: false,
            validationError: null,
            validationWarning: res.detail,
          });

          const requestQuery = [{ entity: 'bool_text_search', query }];
          searchDocuments(requestQuery)
            .then((res) => {
              const results = res.results.map((document) => ({
                label: document.document_handlers[0].document_name,
                documentId: document.id,
                document_handlers: document.document_handlers,
              }));
              const remainingResults = Math.max(0, res.count - results.length);

              this.setState({ loading: false, results, remainingResults });
              this.setState({
                loading: false,
                results,
                remainingResults,
                totalResultsCount: res.count,
              });
            })
            .catch(() =>
              this.setState({
                loading: false,
                loadingError: true,
                validationError: 'Your query is invalid.',
              }),
            );
        })
        .catch((err) =>
          this.setState({
            loading: false,
            validationError: err?.response?.data?.detail,
          }),
        );
    }
  }

  handleOnInputKeyDown(event) {
    const { handleOnShowMoreClick } = this.props;
    const { searchQuery, validationError } = this.state;

    if (
      event.keyCode === ENTER &&
      searchQuery &&
      searchQuery.length >= MIN_QUERY_LENGTH &&
      !validationError
    ) {
      handleOnShowMoreClick(searchQuery);
    }
  }

  handleOnDocumentClick(id) {
    const { handleOnSearchItemClick, hideModal } = this.props;

    hideModal();
    handleOnSearchItemClick(id);
  }

  renderDocumentPath(path) {
    return path.map((folder, folderIndex) => (
      <Fragment key={`${folder.id}-${folderIndex}`}>
        <div>{folder.name}</div>
        {path.length - 1 !== folderIndex && (
          <ChevronRightIcon size="16" color={black4} />
        )}
      </Fragment>
    ));
  }

  renderDefaultResultsContainer() {
    const { classes } = this.props;
    const { defaultResults, loading } = this.state;

    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    }
    return defaultResults.length ? (
      <div className={classes.resultsList}>
        <div className={classes.resultsListLabel}>
          RECENTLY VIEWED DOCUMENTS
        </div>
        {defaultResults.map((item, index) => (
          <button
            className={classes.listItem}
            key={index}
            onClick={() => this.handleOnDocumentClick(item.id)}
          >
            <span aria-hidden={true}>{documentTypeIcon(item.fileType)}</span>
            <div className={classes.listItemContent}>
              <div className={classes.listItemName}>{item.label}</div>
              <div className={classes.listItemPath}>
                {this.renderDocumentPath(item.path)}
              </div>
            </div>
          </button>
        ))}
      </div>
    ) : null;
  }

  renderEmptyResultsList() {
    const { classes } = this.props;

    return (
      <div className={classes.emptyResultsList}>
        <AnalyzerResultsEmptyStateIcon />
        <div className={classes.emptyResultsListTitle}>No documents found.</div>
        <div className={classes.emptyResultsListSubtitle}>
          Try searching for something else.
        </div>
      </div>
    );
  }

  renderResultsList() {
    const {
      classes,
      handleOnShowMoreClick,
      toViewDocument,
      handleOnSearchItemClick,
    } = this.props;
    const { searchQuery, results, remainingResults } = this.state;

    const renderedSearchList = results.map((item, index) => {
      if (toViewDocument) {
        return (
          <Fragment key={index}>
            <EcMultipleLocationsLink
              documentName={item.label}
              documentHandlers={item.document_handlers}
              wrapperStyles={classes.listItem}
              multipleLocationsCallback={() => this.props.hideModal()}
            >
              <span aria-hidden={true}>
                {documentTypeIcon(item.document_handlers[0].file_type)}
              </span>
              <Highlighter
                highlightClassName="highlight"
                searchWords={[searchQuery]}
                autoEscape={true}
                textToHighlight={item.label}
              />
            </EcMultipleLocationsLink>
          </Fragment>
        );
      } else {
        return (
          <div className={classes.listItem} key={index}>
            {documentTypeIcon(item.document_handlers[0].file_type)}
            <button onClick={() => handleOnSearchItemClick(item.documentId)}>
              {item.label}
            </button>
          </div>
        );
      }
    });

    return (
      <div className={classes.resultsList}>
        <div className={classes.resultsListLabel}>
          {pluralize('document', 2)}
        </div>
        {renderedSearchList}
        {remainingResults > 0 && (
          <button
            className={classes.remainingResults}
            onClick={() => handleOnShowMoreClick(searchQuery)}
          >
            <PlusIcon size="20" color={evisortBlue} />
            {remainingResults} more {pluralize('document', remainingResults)}
          </button>
        )}
      </div>
    );
  }

  renderResultsListContainer() {
    const { results } = this.state;

    return results.length
      ? this.renderResultsList()
      : this.renderEmptyResultsList();
  }

  renderResultsContainer() {
    const { classes } = this.props;
    const {
      loading,
      validationError,
      loadingError,
      validationWarning,
    } = this.state;

    return loading ? (
      <div className={classes.loadingContainer}>
        <LoadingSpinner size="medium" />
      </div>
    ) : validationError && !loadingError ? (
      <div className={classes.errorMessage}>{validationError}</div>
    ) : loadingError ? (
      <div className={classes.errorMessage}>
        An error occurred while querying the search.
      </div>
    ) : validationWarning ? (
      <div>
        <div className={classes.boolWarningMessage}>{validationWarning}</div>
        {this.renderResultsListContainer()}
      </div>
    ) : (
      this.renderResultsListContainer()
    );
  }

  render() {
    const { classes, hideModal, toViewDocument } = this.props;
    const { searchQuery, isSyntaxPopupOpen, totalResultsCount } = this.state;
    const renderTitleControls = () => (
      <div className={classes.searchActions}>
        <button
          autoFocus
          ref={this.helpButton}
          aria-haspopup={true}
          className={classes.searchHelpButton}
          onClick={this.handleBooleanPopupOpen}
        >
          <HelpIcon size="20" color={evisortBlue} opacity="1" />
          Help
        </button>
      </div>
    );

    return (
      <>
        <EcModalCard
          title="Search"
          renderTitleControls={renderTitleControls}
          content={
            <>
              <div className={classes.searchInputContainer}>
                <SearchIcon color="#000" opacity="0.25" />
                <DebounceInput
                  aria-describedby="quick-search-results-list"
                  autoFocus={true}
                  debounceTimeout={SEARCH_DEBOUNCE_MS}
                  type="search"
                  placeholder="Search for anything…"
                  className={classes.searchInput}
                  onChange={this.handleOnInputChange}
                  onKeyDown={this.handleOnInputKeyDown}
                />
                {searchQuery.length >= MIN_QUERY_LENGTH && (
                  <div className={classes.searchLabel}>
                    Search
                    <EnterIcon />
                  </div>
                )}
                <div
                  aria-live="assertive"
                  className="screenReaderText"
                  id="quick-search-results-list"
                  role="status"
                >
                  {!searchQuery.length
                    ? null
                    : totalResultsCount
                    ? `${totalResultsCount} document(s) found`
                    : 'No documents found.'}
                </div>
              </div>
              {searchQuery.length ? (
                searchQuery.length < MIN_QUERY_LENGTH ? (
                  <div
                    role="alert"
                    aria-live="polite"
                    className={classes.warningMessage}
                  >
                    Type at least {MIN_QUERY_LENGTH} characters &hellip;
                  </div>
                ) : (
                  this.renderResultsContainer()
                )
              ) : (
                this.renderDefaultResultsContainer()
              )}
              {toViewDocument ? null : (
                <div className={classes.modalActions}>
                  <EcButton text="Cancel" onClick={hideModal} />
                </div>
              )}
            </>
          }
          hideModal={hideModal}
        />
        {isSyntaxPopupOpen && (
          <EcBooleanTextSearchPopup
            relativeContained
            handleOnCloseClick={this.handleBooleanPopupClose}
          />
        )}
      </>
    );
  }
}

AutocompleteModal.propTypes = {
  classes: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  handleOnSearchItemClick: PropTypes.func.isRequired,
  handleOnShowMoreClick: PropTypes.func.isRequired,
};

export default injectSheet(styles)(AutocompleteModal);
