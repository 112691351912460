import {
  black1,
  black2,
  black4,
  bold,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  modalBodyContent: {
    padding: '0 24px',
    maxHeight: '700px',
    overflowY: 'scroll',
    '& label': {
      marginBottom: '10px',
    },
    '& input': {
      width: '100%',
      caretColor: evisortBlue,
      '&:hover': {
        borderColor: evisortBlue,
      },
      '&:focus': {
        borderColor: evisortBlue,
        outline: 'none',
      },
    },
  },
  modalSectionActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px',
    '& button:not(:last-child)': {
      marginRight: '16px',
    },
  },
  labelText: {
    margin: '8px 0',
    display: 'block',
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
  },
  divider: {
    borderTop: '1px solid #ccc',
    margin: '15px 0 22px',
  },
  spacer: {
    height: '10px',
  },
  descriptionText: {
    fontSize: '14px',
    color: black4,
  },
  blueButton: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
    cursor: 'pointer',
    '& svg': {
      marginRight: '4px',
    },
  },
  advancedOptionsWrapper: {
    margin: '0 0 20px 20px',
  },
  multipleValuesContainer: {
    alignItems: 'center',
    marginTop: '5px',
  },
  multipleValues: {
    marginLeft: '30px',
    position: 'relative',
    top: '-25px',
  },
  multipleValuesEmpty: {
    color: black4,
    fontSize: '15px',
  },
  optionWrapper: {
    marginLeft: '20px',
    display: 'flex',
  },
  optionLabelText: {
    marginRight: '10px',
    display: 'flex',
    position: 'relative',
    top: '14px',
    height: '24px',
    color: black2,
    fontSize: '14px',
    fontWeight: semibold,
  },
  optionSelectionContainer: {
    display: 'inline-block',
    width: '100%',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    '& label': {
      color: black2,
    },
  },
  checkboxLabel: {
    marginLeft: '5px',
    display: 'flex',
    fontSize: '13px',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: '5px',
    display: 'flex',
  },
  flagContainer: {
    marginLeft: '20px',
  },
  sectionName: {
    padding: '5px 15px',
    display: 'flex',
    position: 'absolute',
    right: '15px',
    top: '15px',
    backgroundColor: '#f5f5f5',
    borderRadius: '3px',
    fontSize: '14px',
    fontWeight: semibold,
    lineHeight: '21px',
    color: '#635d54',
  },
  textArea: {
    padding: '7px',
    border: '1px solid #ccc',
    borderRadius: '2px',
    maxWidth: '100%',
    maxHeight: '200px',
    fontSize: '14px',
    '&:focus': {
      outline: 'none',
    },
  },
};

export default styles;
