import React from 'react';

import { Button, FormField, Layout, Text, TextInput } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import {
  AttributesField as AttributesFieldType,
  FormField as FormFieldType,
} from '~/types';

type AttributesValue = {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle?: string;
  department?: string;
  role?: string;
};

type Props = {
  formField: FormFieldType<AttributesFieldType>;
  onExplain?: (fieldId: string) => void;
  onChange: (value: AttributesValue) => void;
};

export const UserAttributesField = ({
  formField,
  onExplain,
  onChange,
}: Props) => {
  const {
    email,
    firstName,
    lastName,
    jobTitle,
    department,
    role,
  } = formField.field.value;

  const isPremiumAdministrationEnabled = useFlag(
    FlagType.PremiumAdministration,
  );

  return (
    <Layout direction="column" minW="input.min-width" spacing={1} w="100%">
      <Layout direction="column">
        <Layout>
          <Text variant="section">{formField.title}</Text>
          {onExplain && (
            <Button
              text="Explain this"
              variant="action"
              onClick={() => onExplain(formField.fieldName)}
            />
          )}
        </Layout>
        <Text preset="description">{formField.description}</Text>
      </Layout>

      <Layout direction="column" spacing={4}>
        <FormField
          input={TextInput}
          label="Email"
          placeholder="Text or URL"
          name="email"
          description="This attribute is used to sign in the user."
          value={email}
          error={email === '' ? 'Email is required' : undefined}
          onChange={(email) =>
            onChange({ ...formField.field?.value, email: email as string })
          }
        ></FormField>
        <FormField
          input={TextInput}
          label="First name"
          placeholder="Text or URL"
          name="firstName"
          error={firstName === '' ? 'First name is required' : undefined}
          value={firstName}
          onChange={(firstName) =>
            onChange({
              ...formField.field?.value,
              firstName: firstName as string,
            })
          }
        ></FormField>
        <FormField
          input={TextInput}
          label="Last name"
          placeholder="Text or URL"
          name="lastName"
          error={lastName === '' ? 'Last name is required' : undefined}
          value={lastName}
          onChange={(lastName) =>
            onChange({
              ...formField.field?.value,
              lastName: lastName as string,
            })
          }
        ></FormField>
        <FormField
          input={TextInput}
          label="Job title"
          placeholder="Text or URL"
          name="jobTitle"
          value={jobTitle}
          optional={true}
          disabled={!isPremiumAdministrationEnabled}
          tooltip={
            isPremiumAdministrationEnabled
              ? undefined
              : 'Available with the Advanced Administration add-on.'
          }
          onChange={(jobTitle) =>
            onChange({
              ...formField.field?.value,
              jobTitle: jobTitle as string,
            })
          }
        ></FormField>
        <FormField
          input={TextInput}
          label="Department"
          placeholder="Text or URL"
          name="department"
          value={department}
          optional={true}
          disabled={!isPremiumAdministrationEnabled}
          tooltip={
            isPremiumAdministrationEnabled
              ? undefined
              : 'Available with the Advanced Administration add-on.'
          }
          onChange={(department) =>
            onChange({
              ...formField.field?.value,
              department: department as string,
            })
          }
        ></FormField>
        <FormField
          input={TextInput}
          label="Role"
          placeholder="Text or URL"
          name="role"
          value={role}
          optional={true}
          disabled={!isPremiumAdministrationEnabled}
          tooltip={
            isPremiumAdministrationEnabled
              ? undefined
              : 'Available with the Advanced Administration add-on.'
          }
          onChange={(role) =>
            onChange({ ...formField.field?.value, role: role as string })
          }
        ></FormField>
      </Layout>
    </Layout>
  );
};
