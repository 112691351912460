import { TicketStageType, TicketStatusType } from '~/enums';

/**
 * Calculate overall statuses of single phase
 * @param {Object[]} approvals - Single phase object
 * @return {Object} - object with occurrences of pending, approved, rejected and signed statuses
 */
export function calculatePhaseStatues(approvals, stage) {
  const initialPhaseStatus = {
    pending: 0,
    approved: 0,
    rejected: 0,
  };

  return approvals.reduce((obj, approval) => {
    const { status } = approval;
    if (stage === TicketStageType.Sign && status !== TicketStatusType.Signed) {
      // EKP-2977: for sign phase, all non-signed status are considered as pending status
      return {
        ...obj,
        [TicketStatusType.Pending]: obj[TicketStatusType.Pending] + 1,
      };
    } else {
      return { ...obj, [status]: obj[status] + 1 };
    }
  }, initialPhaseStatus);
}

/**
 * Determines whether to lock a review phase based on the previous one
 * @param {Object} previousPhase
 * @returns {boolean}
 */
export function lockReviewPhase(previousPhase) {
  return !(
    previousPhase === undefined ||
    previousPhase.status === TicketStatusType.Approved ||
    previousPhase.status === TicketStatusType.Done ||
    previousPhase.status === TicketStatusType.Signed
  );
}
