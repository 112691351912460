import { TestNamespaceType } from '~/enums';

import { createTestId } from './createTestId';

export const HIGHLIGHT_NAVIGATOR_UP_TESTID = createTestId({
  namespace: TestNamespaceType.EmailModal,
  block: 'highlight-button-navigator-up',
});

export const HIGHLIGHT_NAVIGATOR_DOWN_TESTID = createTestId({
  namespace: TestNamespaceType.EmailModal,
  block: 'highlight-button-navigator-down',
});
