import PropTypes from 'prop-types';
import React from 'react';

const BackIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 40 40">
      <g fill="none" fillRule="evenodd">
        <circle
          cx="20"
          cy="20"
          r="20"
          fill="#000"
          fillOpacity={props.isHovered ? '.15' : '.1'}
          fillRule="nonzero"
        />
        <path d="M8 8h24v24H8z" />
        <path
          fill="#404040"
          fillRule="nonzero"
          d="M29 19H14.83l3.58-3.59L17 14l-6 6 6 6 1.41-1.41L14.83 21H29z"
        />
      </g>
    </svg>
  );
};

BackIcon.propTypes = {
  size: PropTypes.string,
  isHovered: PropTypes.bool,
};

BackIcon.defaultProps = {
  size: '40',
  isHovered: false,
};

export default BackIcon;
