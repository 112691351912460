export const filterFields = {
  nameField: {
    name: 'Filter By Name',
    id: 'entered_name',
    value: '',
    type: 'STRING',
  },
  activeField: {
    id: 'active',
    value: {},
    values: [
      { display_value: 'Active', value: 'True' },
      { display_value: 'Inactive', value: 'False' },
    ],
    type: 'ARRAY_SINGLE',
    is_nullable: true,
  },
};
