import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import { showToast } from '~/components/Shared/EcToast';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { Divider, FormField, Layout, TextInput } from '~/eds';
import {
  AribaApiType,
  AribaIntegrationType,
  ManageSyncPairStageType,
  SyncPairStatusType,
} from '~/enums';
import { api } from '~/redux';
import { ERROR, SUCCESS } from '~/types/toast.types';

import { API_NAMES, API_VERSIONS } from '../../constants';
import AribaFooter from '../AribaFooter';

function APIDetailsSetup({
  // connected,
  activeSyncPair,
  manageSyncPairStage,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const authenticateErrorMessage =
    'Authentication error due to incorrect input value(s). Check and refill the input value(s) and try again.';
  const { provider } = activeSyncPair;
  const stageProperties = activeSyncPair[manageSyncPairStage] ?? {};
  const { clientSecret, clientSecretId, apiKey } = stageProperties;
  const { location } = activeSyncPair[ManageSyncPairStageType.Realm];
  const version = API_VERSIONS[AribaApiType.EXTRACT][0];
  const isTokenReauthenticate = !!activeSyncPair?.isTokenReauthenticate;

  const handleBack = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.Realm);
  };
  const handleNext = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.FolderAriba);
  };

  const [
    createAribaTokenId,
    createTokenResult,
  ] = api.endpoints.createAribaTokenId.useMutation();
  const { data: tokenData, isLoading, isError } = createTokenResult;

  const [
    updateSyncPair,
    updateSyncPairResult,
  ] = api.endpoints.updateSyncPair.useMutation();
  const { data: updatedPair, isError: isUpdateError } = updateSyncPairResult;

  const handleCreateToken = useCallback(() => {
    const params = {
      location,
      clientSecretId,
      clientSecret,
      apiType: API_NAMES[manageSyncPairStage],
    };
    createAribaTokenId(params);
  }, [location, clientSecretId, clientSecret]);

  useEffect(() => {
    if (isError) {
      showToast(ERROR, authenticateErrorMessage);
    }
    if (isUpdateError) {
      showToast(ERROR, 'Failed to update Sync Pair. Please try again.');
    }
  }, [isError, isUpdateError]);

  useEffect(() => {
    if (updatedPair) {
      showToast(SUCCESS, `${activeSyncPair.name} sync pair has been updated.`);
      integrationsSetManageSyncPairStage(ManageSyncPairStageType.None);
    }
  }, [updatedPair]);

  useEffect(() => {
    if (!tokenData) {
      return;
    }
    showToast(SUCCESS, 'Authentication success');
    const updatedValues = {
      ...stageProperties,
      tokenId: tokenData.id,
      version,
    };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      syncType: AribaIntegrationType.ONE_TIME,
      [manageSyncPairStage]: updatedValues,
    });
    if (isTokenReauthenticate && !isError) {
      const { realm, location } = activeSyncPair[ManageSyncPairStageType.Realm];
      const providerOptions = {
        ...activeSyncPair.providerOptions,
        realm,
        location,
        apiKey,
      };
      const updatedSyncPair = {
        ...activeSyncPair,
        tokenId: tokenData.id,
        version: version,
        tokenType: API_NAMES[manageSyncPairStage],
        providerOptions,
        status: SyncPairStatusType.Initial,
      };
      updateSyncPair(updatedSyncPair);
      integrationsSetActiveSyncPair(updatedSyncPair);
    } else {
      handleNext();
    }
  }, [tokenData]);

  const setApiKey = (apiKey) => {
    const updatedValues = { ...stageProperties, apiKey };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [manageSyncPairStage]: updatedValues,
    });
  };

  const setClientSecretId = (clientSecretId) => {
    const updatedValues = { ...stageProperties, clientSecretId };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [manageSyncPairStage]: updatedValues,
    });
  };

  const setClientSecret = (clientSecret) => {
    const updatedValues = { ...stageProperties, clientSecret };
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      [manageSyncPairStage]: updatedValues,
    });
  };

  return (
    <Layout align="center" direction="column" spacing={8} w="100%">
      <Layout align="center">
        <ProviderLogo provider={provider} />
      </Layout>
      <Divider />
      <Layout direction="column" spacing={8} w="60%">
        <FormField
          required
          label="API Key"
          placeholder="Input API Key"
          value={apiKey ?? ''}
          onChange={setApiKey}
          input={TextInput}
        />
        <FormField
          required
          label="OAuth Client ID"
          placeholder="Input Client ID"
          value={clientSecretId ?? ''}
          onChange={setClientSecretId}
          input={TextInput}
        />
        <FormField
          required
          label="OAuth Client Secret"
          placeholder="Input Client Secret"
          value={clientSecret ?? ''}
          onChange={setClientSecret}
          input={TextInput}
        />
      </Layout>
      <AribaFooter
        secondaryAction={{
          text: 'Back',
          onClick: handleBack,
        }}
        mainAction={{
          text: isTokenReauthenticate ? 'Reauthenticate' : 'Authenticate',
          onClick: handleCreateToken,
          isLoading: isLoading,
          disabled: !apiKey || !clientSecretId || !clientSecret,
        }}
      />
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps, {
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(APIDetailsSetup);
