import { createSlice } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';
import { withEdsUserFields } from '~/utils/user';

import { CurrentUserState as State } from '../types';

const name = SliceType.CurrentUser;

const initialState = {} as State;

export default createSlice({
  name,
  initialState,
  reducers: {
    setCurrentUser: (_state, action) => withEdsUserFields(action.payload),
  },
});
