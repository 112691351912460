export default `
<svg
  aria-hidden="true"
  aria-label="unlink"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M6.823 7.0155C6.8855 7.0155 6.948 6.98425 7.0105 6.98425H10.2605C10.6667 6.98425 11.0105 7.328 11.0105 7.73425C11.0105 8.17175 10.6667 8.48425 10.2605 8.48425H8.7605L12.2605 11.2343H15.2605C15.6667 11.2343 16.0105 11.578 16.0105 11.9843C16.0105 12.4218 15.6667 12.7343 15.2605 12.7343H14.1667L17.6042 15.453C19.2605 15.1405 20.5105 13.7343 20.5105 11.9843C20.5105 10.078 18.9167 8.48425 17.0105 8.48425H13.7605C13.323 8.48425 13.0105 8.17175 13.0105 7.73425C13.0105 7.328 13.323 6.98425 13.7605 6.98425H17.0105C19.7605 6.98425 22.0105 9.23425 22.0105 11.9843C22.0105 14.0468 20.7917 15.7968 19.0417 16.578L21.698 18.6718C22.0417 18.9218 22.1042 19.3905 21.823 19.703C21.573 20.0468 21.1042 20.1093 20.7917 19.828L2.29175 5.328C1.948 5.078 1.8855 4.60925 2.16675 4.29675C2.41675 3.953 2.8855 3.8905 3.198 4.17175L6.823 7.0155ZM2.0105 11.9843C2.0105 10.5155 2.6355 9.203 3.6355 8.29675L4.85425 9.23425C4.0105 9.8905 3.5105 10.8905 3.5105 11.9843C3.5105 13.9218 5.073 15.4843 7.0105 15.4843H10.2605C10.6667 15.4843 11.0105 15.828 11.0105 16.2343C11.0105 16.6718 10.6667 16.9843 10.2605 16.9843H7.0105C4.22925 16.9843 2.0105 14.7655 2.0105 11.9843Z"
    fill="currentcolor"
  />
</svg>
`;
