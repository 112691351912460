import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceType } from '../types';
import { State } from './types';

export const initialState: State = {
  activeModelResultInfo: null,
  modelAction: null,
};

export default createSlice({
  name: SliceType.XRay,
  initialState,
  selectors: {
    selectActiveModelResultInfo: (state) => state.activeModelResultInfo,
    selectModelAction: (state) => state.modelAction,
  },
  reducers: {
    setActiveModelResultInfo: (
      state,
      action: PayloadAction<State['activeModelResultInfo']>,
    ) => {
      state.activeModelResultInfo = action.payload;
    },
    setModelAction: (state, action: PayloadAction<State['modelAction']>) => {
      state.modelAction = action.payload;
    },
  },
});
