import React from 'react';

import UserDepartmentSelect from '~/components/Shared/UserDepartmentSelect';
import { workflowTicketRoleOptions } from '~/constants/workflow';

const WorkflowUserDepartmentMultiSelect = (props) => {
  return <UserDepartmentSelect {...props} roles={workflowTicketRoleOptions} />;
};

export default WorkflowUserDepartmentMultiSelect;
