import { alphas } from './system/alphas';
import { palette } from './system/palette';

// TODO EKP-14822: deprecate tokens in favor of style rule or consolidated inverse styles
export const inverse = {
  'inverse.background': 'black',
  'inverse.background.secondary': palette['gray-800'],
  'inverse.background.quiet': palette['gray-900'],
  'inverse.border': alphas['lighten-20'],
  'inverse.border.hover': alphas['lighten-30'],
  'inverse.border.divider': alphas['lighten-10'],
  // TODO this token will be deprecated and simplified as icons would just inherit their sibling text color.
  'inverse.icon': 'white',
  'inverse.text.link': palette['blue-300'],
  'inverse.text.primary': 'white',
  'inverse.text.secondary': palette['gray-200'],
  'inverse.text.quiet': palette['gray-500'],
};
