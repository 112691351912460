import React, { Component } from 'react';

import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import EcSelect from '../../Shared/EcSelect';

export default class MoveFieldToSectionModal extends Component {
  constructor(props) {
    super(props);

    this.state = { sections: [] };

    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    const { sections } = this.props;
    this.setState({
      sections: sections.map((s) => ({
        value: s.name,
        label: s.name,
        id: s.id,
      })),
      selectedSection: this.props.selectedSection
        ? {
            label: this.props.selectedSection.label,
            value: this.props.selectedSection.value,
            id: this.props.selectedSection.id,
          }
        : {},
    });
  }

  handleActionClick() {
    const { fieldId, handleActionClick, hideModal } = this.props;
    const { selectedSection } = this.state;
    handleActionClick(fieldId, selectedSection.id);
    hideModal();
  }

  handleOnChange(selectedSection) {
    this.setState({ selectedSection });
  }

  render() {
    const { text, hideModal } = this.props;
    const { sections, selectedSection } = this.state;

    return (
      <EcModalCard
        title="Move Field To Section"
        content={
          <div>
            {text}
            <EcSelect
              data-testid="data-fields-section-options-selection-menu"
              width="100%"
              {...(selectedSection && {
                value: {
                  label: selectedSection.label,
                  value: selectedSection.value,
                },
              })}
              options={sections}
              onChange={this.handleOnChange}
            />
          </div>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton yellow text="Move" onClick={this.handleActionClick} />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}
