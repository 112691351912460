import {
  black2,
  black3,
  bold,
  evisortBlue,
  semibold,
} from '../../assets/shared-styles/general';

const styles = {
  uploadCard: {
    marginTop: '24px',
    width: 'fit-content',
    minWidth: '100%',
  },
  uploadZoneContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '290px',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 0',
  },
  uploadContentIcon: {
    marginRight: '40px',
  },
  uploadText: {
    marginBottom: '8px',
    fontSize: '17px',
    fontWeight: semibold,
  },
  uploadTextSmall: {
    color: black3,
    fontSize: '15px',
  },
  uploadAction: {
    marginTop: '24px',
  },
  uploadLogsTable: {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left',
    '& th': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      paddingTop: '16px',
      paddingBottom: '16px',
      color: black2,
      fontSize: '15px',
      fontWeight: bold,
      '&:nth-child(2)': {
        paddingLeft: '18px',
        paddingRight: '40px',
      },
    },
    '& td': {
      color: black2,
      fontSize: '15px',
      overflow: 'hidden',
      whiteSpace: 'normal',
      '&:nth-child(2)': {
        paddingLeft: '18px',
        paddingRight: '40px',
      },
      '&:last-child, &:last-child a': {
        color: evisortBlue,
        fontWeight: bold,
        textAlign: 'right',
        textDecoration: 'none',
      },
    },
    '& tr': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      '&:last-child': {
        border: 0,
      },
    },
  },
  noUploadMessage: {
    margin: '30px',
    color: black2,
    textAlign: 'center',
  },
  headerSize: {
    width: '160px',
    textAlign: 'right',
  },
  headerDate: { width: '200px' },
  headerStatus: { width: '170px' },
  headerAction: { width: '55px' },
  cellDocumentName: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 8px 12px 0',
    '& svg': {
      flexShrink: 0,
      marginRight: '8px',
    },
    '& a': {
      display: 'flex',
      alignItems: 'center',
      color: black2,
      textDecoration: 'none',
      wordBreak: 'break-word',
      '&:visited': {
        color: black2,
        textDecoration: 'none',
      },
    },
  },
  cellSize: {
    textAlign: 'right',
  },
  cellAction: {
    '& span': {
      cursor: 'pointer',
    },
  },
  horizontalSeparator: {
    display: 'block',
    height: '24px',
  },
};

export default styles;
