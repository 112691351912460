import { base } from './base';
import { scales } from './scales';

export const palette = Object.entries(base).reduce(
  (acc: Record<string, string>, [color, colorDatum]) => {
    scales.forEach((scale) => {
      const { value } = scale;
      const [h, s] = colorDatum;
      const l = 100 - value / 10;
      const a = 1;
      const hsla = `hsla(${h}, ${s}%, ${l}%, ${a})`;
      const key = `${color}-${value}`;
      acc[key] = hsla;
    });

    return acc;
  },
  {},
);
