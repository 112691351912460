import PT from 'prop-types';
import React, { useState } from 'react';

import { IconButton } from '~/eds';
import { FlexLayout } from '~/ui';
import Menu from '~/ui/components/Menu';

import './react-menu_override.css';

const menuButtonStyles = {
  padding: '8px 15px 6px',
  backgroundColor: 'transparent',
  border: '1px solid rgba(0, 0, 0, 0.25)',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: 'blue-500',
  },
  '&:focus': {
    backgroundColor: 'transparent',
    border: 'none',
  },
};

function DocVersionMenu({
  renderMenuHeaderText = () => null,
  renderMenuButton,
  renderListItem,
  renderMenuFooter = () => null,
  ...rest
}) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Menu
      {...rest}
      className="document-version-menu"
      footerItem={(item) => renderMenuFooter(item)}
      headerItem={
        <FlexLayout
          justifyContent={renderMenuHeaderText() ? 'space-between' : 'flex-end'}
          p={4}
          space={6}
          sx={{ borderBottom: 'border', width: '100%' }}
        >
          {renderMenuHeaderText()}
          <IconButton
            icon="expand"
            status={expanded ? 'active' : undefined}
            text="toggle menu"
            tooltip={expanded ? 'Collapse' : 'Expand'}
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        </FlexLayout>
      }
      items={renderListItem(expanded)}
      menuButtonProps={{
        content: renderMenuButton(),
        styles: menuButtonStyles,
      }}
    />
  );
}

DocVersionMenu.propTypes = {
  onMenuItemClick: PT.func,
};

export default DocVersionMenu;
