type PropsWithPreset<Props, Presets> = Props & {
  preset?: keyof Presets;
};

/**
 * If `PropsWithPreset` is provided with a `Presets` object, resolves the provided `props` based on `props.preset` by spreading attributes of `presets[props.preset]` last.
 *
 * This behavior applies preset props as a final override, therefore placing emphasis and honoring the preset props more than the provided props.
 */
export const resolvePresetProps = <Props, Presets>(
  props: PropsWithPreset<Props, Presets>,
  presets: Presets,
): Props => {
  const { preset } = props;
  const presetProps = preset ? presets[preset] : {};
  return {
    ...props,
    ...presetProps,
  };
};
