export default `
<svg
  aria-hidden="true"
  aria-label="field-short-response"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M19.1846 10.75H6.68457C6.24707 10.75 5.93457 10.4375 5.93457 10C5.93457 9.59375 6.24707 9.25 6.68457 9.25H19.1846C19.5908 9.25 19.9346 9.59375 19.9346 10C19.9346 10.4375 19.5908 10.75 19.1846 10.75ZM14.1846 14.75H6.68457C6.24707 14.75 5.93457 14.4375 5.93457 14C5.93457 13.5938 6.24707 13.25 6.68457 13.25H14.1846C14.5908 13.25 14.9346 13.5938 14.9346 14C14.9346 14.4375 14.5908 14.75 14.1846 14.75Z"
    fill="currentcolor"
  />
</svg>
`;
