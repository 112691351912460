import React from 'react';

import { Box } from '../Box';
import { useShowMoreItems } from '../hooks/useShowMoreItems';
import { Layout } from '../Layout';

interface Props<T> {
  name: string;
  /** items to be rendered. */
  items: T[];
  /** limit of visible items */
  limit: number;
  /** render function. if not provided, items will be used like a JSX. */
  renderItem?: (item: T) => React.ReactNode;
  /** limit of visible items. */
  /** serializeItem function is used to render the items on the more tooltip. Should be provided
   * unless items are primitive values (e.g. strings or numbers).
   */
  serializeItem?: (item: T) => string;
}

const defaultRenderItem = <T extends unknown>(item: T) => `${item}`;
const defaultSerializeItem = <T extends unknown>(item: T) => `${item}`;

export const CollapsibleList = <T extends unknown>({
  name,
  items,
  limit,
  renderItem = defaultRenderItem,
  serializeItem = defaultSerializeItem,
}: Props<T>) => {
  const [visibleItems, showMore, showLess] = useShowMoreItems<
    typeof items[number]
  >({
    items,
    limit,
    serializeItem: serializeItem,
  });

  return (
    <Layout w="100%" direction="column" spacing={2}>
      <Layout name={name} spacing={1} p={0} m={0} direction="column" as="ul">
        {visibleItems.map((item, index) => {
          return (
            <Layout key={index} as="li">
              {renderItem(item)}
            </Layout>
          );
        })}
      </Layout>
      {items.length > limit && <Box>{showMore ?? showLess}</Box>}
    </Layout>
  );
};
