/**
 * This function calculates the distance of `element` to the edges of the `window`
 * @param element HTML Element that will be used as reference */
export const getDistanceToWindow = (
  element: HTMLElement,
): { top: number; left: number; bottom: number; right: number } => {
  const rect = element.getBoundingClientRect();

  const { top, left, bottom, right } = rect;
  const windowHeight =
    window.innerHeight ?? document.documentElement.clientHeight;
  const windowWidth = window.innerWidth ?? document.documentElement.clientWidth;

  return {
    top: top,
    left: left,
    bottom: windowHeight - bottom,
    right: windowWidth - right,
  };
};
