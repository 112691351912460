import pluralize from 'pluralize';
import React from 'react';
import { connect } from 'react-redux';

import { DateText, StatusMessage } from '~/eds';
import { TicketStatusType } from '~/enums';
import { useResolveUsers } from '~/hooks';
import { Box, FlexLayout, Text } from '~/ui';

import TicketDocumentNotice from './TicketDocumentNotice';

/**
 * Return coerced value of TicketStatusType.Completed if status === TicketStatusType.Approved.  Passthrough the status otherwise.
 */
export const coerceApprovalStatus = ({ status }) => {
  return status === TicketStatusType.Approved
    ? TicketStatusType.Completed
    : status;
};

function TicketDocumentNotices({
  // connected
  currentUser,
  ticket,
}) {
  const { stages, phases, judgments, approvals, risks } = ticket;

  const reviewPhaseIds = stages.review?.phaseIds;
  const reviewApprovals = Object.values(approvals).filter((approval) =>
    reviewPhaseIds?.includes(approval.phaseId),
  );
  const isDocumentCompleted = reviewApprovals.every(
    (approval) => approval.status === TicketStatusType.Approved,
  );

  const userIds = reviewApprovals
    .filter((approval) => approval.userId)
    .map((approval) => approval.userId);

  const { users, isFetching } = useResolveUsers({
    userIds: userIds,
  });

  if (!users && isFetching) {
    return null;
  }

  if (isDocumentCompleted) {
    return (
      <Box mb={6}>
        <TicketDocumentNotice
          status={TicketStatusType.Approved}
          description={<Text variant="xs-dense">No actions needed</Text>}
        />
      </Box>
    );
  }

  const unassignedCount = reviewApprovals.reduce((count, approval) => {
    const isApproved = approval.status === TicketStatusType.Approved;
    if (!isApproved && !approval.userId && !users[approval.userId]?.isDeleted) {
      count++;
    }
    return count;
  }, 0);

  const invalidAssingments = reviewApprovals
    .filter((approval) => {
      const isApproved = approval.status === TicketStatusType.Approved;
      return (
        !isApproved && approval.userId && users[approval.userId]?.isDeleted
      );
    })
    .map((approval) => {
      return (
        <StatusMessage
          status="info"
          message={`The user **${
            users[approval.userId]?.email
          }** has been deactivated. Re-assign their task and contact your Workflow Administrators to update the workflow.`}
        />
      );
    });

  const unassignedTasks = !!unassignedCount && (
    <StatusMessage
      status="danger"
      message={`You have **${unassignedCount} ${pluralize(
        'unassigned task',
        unassignedCount,
      )}** that ${unassignedCount > 1 ? 'need' : 'needs'} to be assigned.`}
    />
  );

  const currentUserApprovals = reviewApprovals.filter(
    (approval) => approval.userId === currentUser.id,
  );
  const completedApprovals = [];
  let pendingCount = 0;
  currentUserApprovals.forEach((approval) => {
    if (approval.status === TicketStatusType.Pending) {
      ++pendingCount;
    } else {
      completedApprovals.push(approval);
    }
  });

  let pendingApprovals = null;
  if (pendingCount) {
    pendingApprovals = (
      <TicketDocumentNotice
        status={TicketStatusType.Pending}
        description={
          <Text variant="xs-dense">
            You have{' '}
            <Text variant="xs-dense-bold">
              {pendingCount} {pluralize('review', pendingCount)}
            </Text>{' '}
            pending completion.
          </Text>
        }
      />
    );
  }

  return (
    <FlexLayout flexDirection="column" mb={6} space={2}>
      {completedApprovals.map((approval) => {
        const phaseName = phases[approval.phaseId].name;
        let description = `You ${coerceApprovalStatus(approval)}`;
        if (approval.judgmentId) {
          const judgmentName = judgments[approval.judgmentId].name;
          if (judgmentName === 'General Approvers') {
            description += ` "${phaseName}" `;
          } else {
            description += ` "${phaseName} \u2192 ${judgmentName}" `;
          }
        } else {
          const riskName = risks[approval.riskId].name;
          description += ` "${phaseName} \u2192 ${riskName}" `;
        }

        const descriptionComponent = (
          <Text variant="xs-dense">
            {description}{' '}
            <Text variant="xs-dense-bold">
              <DateText
                date={
                  approval.lastModified
                    ? new Date(approval.lastModified)
                    : undefined
                }
                format="duration"
                enableTick
              />
            </Text>
            .
          </Text>
        );

        return (
          <TicketDocumentNotice
            key={`notice-${approval.id}`}
            status={approval.status}
            description={descriptionComponent}
            note={approval.comment}
          />
        );
      })}
      {unassignedTasks}
      {invalidAssingments}
      {pendingApprovals}
    </FlexLayout>
  );
}

function mapStateToProps({ currentUser, ticket }) {
  return { currentUser, ticket };
}

export default connect(mapStateToProps)(TicketDocumentNotices);
