import { TestNamespaceType } from '~/enums';

import { createTestId } from './createTestId';

export const TICKET_REVIEWER_PAGE_UPLOAD_VERSION_BUTTON_TESTID = createTestId({
  namespace: TestNamespaceType.TicketReviewerPage,
  block: 'upload-new-version',
});

export const TICKET_REVIEWER_PAGE_SHARE_DOCUMENT_BUTTON_TESTID = createTestId({
  namespace: TestNamespaceType.TicketReviewerPage,
  block: 'share-document',
});
