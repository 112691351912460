import React from 'react';

import { Header } from '~/eds';
import { Nullable } from '~/types';

import { FieldModel, PromptModelPublishStatus } from '../../types';
import { getModelHeader } from '../utils';

interface Props {
  modelVersion: FieldModel;
  publishStatus: Nullable<PromptModelPublishStatus>;
}
export const ModelTitle = ({ publishStatus, modelVersion }: Props) => {
  const header = getModelHeader({
    ...modelVersion,
    publishStatus,
  });

  return <Header {...header} enablePadding={false} />;
};
