export default {
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  DEPARTMENT: 'DEPARTMENT',
  MULTI_SELECT: 'MULTI_SELECT',
  NUMBER: 'NUMBER',
  SINGLE_SELECT: 'SINGLE_SELECT',
  TEXT: 'TEXT',
  USER: 'USER',
};
