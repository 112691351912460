import { useDispatch, useSelector } from 'react-redux';

import { workflowSetIsTurnTrackingEnabled } from '~/actions';
import { Checkbox, ContentContainer, Icon, Layout, Text } from '~/eds';
import { selectIsTurnTrackingEnabled } from '~/redux/selectors';
import { Nullable } from '~/types';

export const CycleTimeTracker = () => {
  const isTurnTrackingEnabled = useSelector(selectIsTurnTrackingEnabled);
  const dispatch = useDispatch();

  const handleOnChange = (value: Nullable<boolean>) => {
    dispatch(workflowSetIsTurnTrackingEnabled(Boolean(value)));
  };
  return (
    <ContentContainer>
      <Layout direction="column">
        <Layout direction="row" spacing={2} align="center">
          <Icon icon="turn-tracking" size="l" color="status.active" />
          <Text variant="section">Cycle Time Tracker</Text>
        </Layout>
        <Text variant="body" color="text.quiet" mb={4} mt={2}>
          Display Cycle Time Tracking on the Intake Form, allowing users to set
          a Responsible Party when creating a ticket.
        </Text>
        <Layout>
          <Checkbox
            option={{
              value: 'isTurnTrackingEnabled',
              label: 'Enable cycle time tracking',
            }}
            value={isTurnTrackingEnabled}
            onChange={handleOnChange}
            name="toggleTurnTracking"
          />
        </Layout>
      </Layout>
    </ContentContainer>
  );
};
