import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getStorageItem, setStorageItem } from '../utils/browser';

export type ReduxPath = string[];

export const getPersistedState = (path: ReduxPath, defaultValue: any) =>
  getStorageItem(path.toString(), defaultValue);

const selectRoot = (root: any) => root;

const selectStateByPath = (path: ReduxPath) =>
  createSelector(selectRoot, (rootState) => get(rootState, path));

const usePersistRedux = (path: ReduxPath) => {
  const data = useSelector(selectStateByPath(path));

  useEffect(() => {
    setStorageItem(path.toString(), data);
  }, [path, data]);

  return null;
};

export const PersistRedux = ({ path }: { path: ReduxPath }) => {
  usePersistRedux(path);

  return null;
};
