import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { evisortBlue } from '~/assets/shared-styles/general';
import EcButton from '~/components/Shared/EcButton';
import EcInput from '~/components/Shared/EcInput';
import EcModalCard from '~/components/Shared/EcModalCard';
import EcTooltip from '~/components/Shared/EcTooltip';
import ChevronRightIcon from '~/components/Shared/Icons/ChevronRightIcon';
import HelpIcon from '~/components/Shared/Icons/HelpIcon';
import { ClientIdType, FeatureFlagType, UserRoleType } from '~/enums';
import { canUseWorkflow, checkIsInternalSuperAdmin } from '~/permissions';
import { MODAL_DEPARTMENT_TREE } from '~/types/modal.types';
import { Checkbox, SingleSelect } from '~/ui';
import { isEmailValid } from '~/utils/helper.utils';
import { featureFlagIncluded, testIsSuperAdmin } from '~/utils/user';

import { styles } from './AdminUserModal.styles';

const roleOptions = [
  { value: UserRoleType.Admin, label: 'Admin' },
  { value: UserRoleType.PowerUserWithEdit, label: 'Power User' },
  { value: UserRoleType.WorkflowOnly, label: 'Workflow Only User' },
];

const superAdminRoleOptions = [
  ...roleOptions,
  { value: UserRoleType.Reviewer, label: 'Reviewer' },
];

function useStateFromProp(initialValue) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => setValue(initialValue), [initialValue]);

  return [value, setValue];
}

const AdminUserModal = (props) => {
  const [formFields, handleInputChange] = useStateFromProp(props.formFields);

  const {
    classes,
    clientId,
    forEditing,
    title,
    confirmButtonIcon,
    confirmButtonText,
    confirmButtonDisabled,
    hideModal,
    errorMessage,
    currentUser,
    handleShowSecondaryModal,
    onUserFormUpdate,
  } = props;

  const isGeneralClient = Number.parseInt(clientId) === ClientIdType.General;
  const options =
    checkIsInternalSuperAdmin(currentUser) && isGeneralClient
      ? superAdminRoleOptions
      : roleOptions;

  function handleFormFieldChange(name, value) {
    const shouldEnableAllAdmin =
      name === 'role' && value === UserRoleType.Admin;
    const shouldDisableAllAdmin =
      name === 'role' &&
      [UserRoleType.WorkflowOnly, UserRoleType.PowerUserWithEdit].includes(
        value,
      );
    const updatedAutomationAdmin =
      name === 'automationAdmin' ? value : formFields.automationAdmin;
    const updatedWorkflowAdmin =
      name === 'workflowAdmin' ? value : formFields.workflowAdmin;
    const automationAdmin =
      shouldEnableAllAdmin ||
      (!shouldDisableAllAdmin && updatedAutomationAdmin);
    const workflowAdmin =
      shouldEnableAllAdmin || (!shouldDisableAllAdmin && updatedWorkflowAdmin);

    handleInputChange({
      ...formFields,
      [name]: value,
      automationAdmin,
      workflowAdmin,
    });
    onUserFormUpdate({
      ...formFields,
      [name]: value,
      automationAdmin,
      workflowAdmin,
    });
  }

  function handleInputFocus(event) {
    event.target.select();
  }

  function handleActionClick() {
    const {
      email,
      firstName,
      lastName,
      role,
      password,
      jobTitle,
      workflowAdmin,
      automationAdmin,
      shouldSendEmail,
    } = formFields;
    const { handleNameChange } = props;

    handleNameChange(
      email.trim(),
      firstName.trim(),
      lastName.trim(),
      role,
      password,
      jobTitle,
      workflowAdmin,
      automationAdmin,
      shouldSendEmail,
    );
  }

  function renderPassword() {
    const isSuperAdmin = testIsSuperAdmin(currentUser);
    return forEditing || !isSuperAdmin ? null : (
      <div>
        <label htmlFor="AdminUserModal/password">
          INITIAL PASSWORD (Click dropdown next to user to send a password setup
          email)
        </label>
        <div className={classes.passwordHelp} data-tip data-for="helpTooltip">
          <HelpIcon size="20" color={evisortBlue} opacity="1" />
          <EcTooltip id="helpTooltip" width="224px" place="right">
            Passwords must be a minimum of 8 characters, containing at least one
            capital letter (A-Z), at least one number (0-9), and at least one of
            the following special characters (@ * _ - . ! & ').
          </EcTooltip>
        </div>
        <EcInput
          id="AdminUserModal/password"
          input={{
            value: formFields.password,
            onChange: (event) => {
              handleFormFieldChange('password', event.target.value);
            },
            onFocus: (event) => {
              handleInputFocus(event);
            },
          }}
          error={errorMessage.includes('password')}
        />
      </div>
    );
  }

  function renderErrorMessage() {
    const { classes, errorMessage } = props;

    return errorMessage.length ? (
      <div className={classes.modalErrorMessage}>{errorMessage}</div>
    ) : null;
  }

  function renderJobTitle() {
    return (
      <div>
        <label htmlFor="AdminUserModal/jobTitle">JOB TITLE</label>
        <EcInput
          id="AdminUserModal/jobTitle"
          input={{
            value: formFields.jobTitle,
            onChange: (event) => {
              handleFormFieldChange('jobTitle', event.target.value);
            },
            onFocus: (event) => {
              handleInputFocus(event);
            },
          }}
        />
      </div>
    );
  }

  const isFormInvalid =
    !formFields.firstName?.trim().length ||
    !formFields.lastName?.trim().length ||
    !isEmailValid(formFields.email.trim());

  return (
    <EcModalCard
      title={title}
      content={
        <div className={classes.modalBodyContent}>
          <label htmlFor="AdminUserModal/email">EMAIL *</label>
          <EcInput
            id="AdminUserModal/email"
            input={{
              'data-testid': 'admin-user-modal-email-input',
              value: formFields.email,
              onChange: (event) => {
                handleFormFieldChange('email', event.target.value);
              },
              onFocus: (event) => {
                handleInputFocus(event);
              },
            }}
            error={errorMessage.includes('email')}
          />

          <label htmlFor="AdminUserModal/firstName">FIRST NAME *</label>
          <EcInput
            id="AdminUserModal/firstName"
            input={{
              'data-testid': 'admin-user-modal-first-name-input',
              value: formFields.firstName,
              onChange: (event) => {
                handleFormFieldChange('firstName', event.target.value);
              },
              onFocus: (event) => {
                handleInputFocus(event);
              },
            }}
          />
          <label htmlFor="AdminUserModal/lastName">LAST NAME *</label>
          <EcInput
            id="AdminUserModal/lastName"
            input={{
              'data-testid': 'admin-user-modal-last-name-input',
              value: formFields.lastName,
              onChange: (event) => {
                handleFormFieldChange('lastName', event.target.value);
              },
              onFocus: (event) => {
                handleInputFocus(event);
              },
            }}
          />

          {renderPassword()}

          {renderJobTitle()}

          <div className={classes.accessCopy}>
            <div className={classes.subHeader}>Access Level and Department</div>
            <p>
              Assign a specific access level for the user you are adding to the
              platform. You can also assign them to a specific department for
              easier access control management.
            </p>
          </div>
          <label htmlFor="AdminUserModal/accessLevel">ACCESS LEVEL</label>
          <SingleSelect
            id="AdminUserModal/accessLevel"
            blacklistValues={
              canUseWorkflow(currentUser) ? [] : [UserRoleType.WorkflowOnly]
            }
            options={options}
            value={formFields.role}
            width="fullWidth"
            isClearable={false}
            shouldHideSelectedOptions={false}
            onChange={(updatedValue) =>
              handleFormFieldChange('role', updatedValue)
            }
          />
          {featureFlagIncluded(currentUser, FeatureFlagType.Workflow) ? (
            <div className={classes.workflowAdminContainer}>
              <Checkbox
                disabled={formFields.role === UserRoleType.WorkflowOnly}
                label="Workflow Admin"
                value={formFields.workflowAdmin}
                onChange={(value) =>
                  handleFormFieldChange('workflowAdmin', value)
                }
              />
            </div>
          ) : null}
          <div className={classes.workflowAdminContainer}>
            <Checkbox
              disabled={formFields.role === UserRoleType.WorkflowOnly}
              label="Automation Admin"
              value={formFields.automationAdmin}
              onChange={(value) =>
                handleFormFieldChange('automationAdmin', value)
              }
            />
          </div>
          <div className={classes.departmentContainer}>
            <div className={classes.departmentLabel}>DEPARTMENT</div>
            <div>
              {!formFields.departmentsSelected ||
              !formFields.departmentsSelected.length ? (
                <div className={classes.noDeptMessage}>
                  No department has been selected.
                </div>
              ) : (
                <div className={classes.selectedDepartmentName}>
                  {formFields.departmentsSelected
                    .map((d) => d.department_name)
                    .join(' • ')}
                </div>
              )}
              <button
                aria-haspopup="true"
                className={classes.selectDepartmentCTA}
                onClick={() => handleShowSecondaryModal(MODAL_DEPARTMENT_TREE)}
              >
                <ChevronRightIcon size="15" color={evisortBlue} />
                Select Department
              </button>
            </div>
          </div>
          {renderErrorMessage()}
        </div>
      }
      footer={
        <>
          <EcButton white borderless text="Cancel" onClick={hideModal} />
          {!forEditing && (
            <Checkbox
              label="Email user sign-in info"
              value={formFields.shouldSendEmail}
              onChange={(value) =>
                handleFormFieldChange('shouldSendEmail', value)
              }
            />
          )}
          <EcButton
            testId="admin-user-modal-submit-button"
            yellow
            iconLeft={confirmButtonIcon}
            text={confirmButtonText}
            onClick={handleActionClick}
            disabled={isFormInvalid || confirmButtonDisabled}
          />
        </>
      }
      hideModal={hideModal}
    />
  );
};

AdminUserModal.propTypes = {
  title: PropTypes.string.isRequired,
  confirmButtonIcon: PropTypes.object,
  confirmButtonText: PropTypes.string.isRequired,
  confirmButtonDisabled: PropTypes.bool,
  formFields: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.number,
    password: PropTypes.string,
    jobTitle: PropTypes.string,
    workflowAdmin: PropTypes.bool,
    automationAdmin: PropTypes.bool,
    shouldSendEmail: PropTypes.bool,
  }),
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ currentUser }) => ({ currentUser });

export default injectSheet(styles)(connect(mapStateToProps)(AdminUserModal));
