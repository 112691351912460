import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { Button, formatDate, Icon, parseDate } from '~/eds';
import { withFlags } from '~/flags';

import { ERROR, SUCCESS } from '../../../types/toast.types';
import EcCard from '../../Shared/EcCard';
import EcDatepicker from '../../Shared/EcDatepicker/EcDatepicker';
import EcMultipleSelectValue from '../../Shared/EcMultipleSelectValue';
import EcMultiSelect from '../../Shared/EcMultiSelect';
import EcRadioButton from '../../Shared/EcRadioButton';
import EcRadioContainer from '../../Shared/EcRadioContainer';
import { saveReviewerSettings } from '../ReviewerAdminPage.data';
import {
  ALL_DOCUMENTS,
  ALL_DOCUMENTS_AFTER,
  ALL_DOCUMENTS_BEFORE,
  ALL_DOCUMENTS_RANGE,
} from './ReviewerAdminDocuments.enum';
import styles from './ReviewerAdminDocuments.styles';

const ecCardContentStyles = { padding: '16px' };

class ReviewerAdminDocuments extends Component {
  constructor(props) {
    super(props);

    this.handleOnSaveChangesClick = this.handleOnSaveChangesClick.bind(this);
    this.state = {
      selectedFileTypes: [],
      documentsShownValue: null,
      documentsShownStartDate: null,
      documentsShownEndDate: null,
      showOnlyFlaggedFields: null,
      reviewAllTypes: null,
      submitting: false,
    };
  }

  componentDidMount() {
    const {
      documentsShownStartDate,
      documentsShownEndDate,
      selectedFileTypes,
      showOnlyFlaggedFields,
      reviewAllTypes,
    } = this.props;

    const documentsShownValue = ReviewerAdminDocuments.defaultDocumentsShown(
      documentsShownStartDate,
      documentsShownEndDate,
    );

    this.setState({
      documentsShownStartDate,
      documentsShownEndDate,
      selectedFileTypes,
      documentsShownValue,
      showOnlyFlaggedFields,
      reviewAllTypes,
    });
  }

  static defaultDocumentsShown(startDate, endDate) {
    if (startDate && endDate) {
      return ALL_DOCUMENTS_RANGE;
    } else if (endDate) {
      return ALL_DOCUMENTS_BEFORE;
    } else if (startDate) {
      return ALL_DOCUMENTS_AFTER;
    } else {
      return ALL_DOCUMENTS;
    }
  }

  static renderDatesSubtitle(documentsShownValue) {
    switch (documentsShownValue) {
      case ALL_DOCUMENTS_AFTER:
        return 'SELECT START DATE';
      case ALL_DOCUMENTS_BEFORE:
        return 'SELECT END DATE';
      case ALL_DOCUMENTS_RANGE:
        return 'DATE RANGE';
      default:
        break;
    }
  }

  static processDocumentsData(state) {
    const start_date = state.documentsShownStartDate;
    const end_date = state.documentsShownEndDate;
    const show_only_flagged_fields = state.showOnlyFlaggedFields;
    const review_all_types = state.reviewAllTypes;
    const selected_types = review_all_types
      ? []
      : state.selectedFileTypes.map((type) => type.value);

    let datesState;
    switch (state.documentsShownValue) {
      case ALL_DOCUMENTS_RANGE:
        datesState = { start_date, end_date };
        break;
      case ALL_DOCUMENTS_AFTER:
        datesState = { start_date, end_date: null };
        break;
      case ALL_DOCUMENTS_BEFORE:
        datesState = { start_date: null, end_date };
        break;
      case ALL_DOCUMENTS:
        datesState = { start_date: null, end_date: null };
        break;
      default:
        break;
    }

    return {
      ...datesState,
      show_only_flagged_fields,
      review_all_types,
      selected_types,
    };
  }

  handleOnSaveChangesClick() {
    const { showToast } = this.props;
    const processedData = ReviewerAdminDocuments.processDocumentsData(
      this.state,
    );

    this.setState({ submitting: true });
    saveReviewerSettings(processedData)
      .then(() => showToast(SUCCESS, 'Document settings saved.'))
      .catch(() => showToast(ERROR, 'Could not save document settings.'))
      .finally(() => this.setState({ submitting: false }));
  }

  renderDatesContainer() {
    const { classes } = this.props;
    const {
      documentsShownValue,
      documentsShownStartDate,
      documentsShownEndDate,
    } = this.state;

    if (documentsShownValue === ALL_DOCUMENTS) return null;

    const datesSubtitle = ReviewerAdminDocuments.renderDatesSubtitle(
      documentsShownValue,
    );

    return (
      <div className={classes.datesWrapper}>
        <div className={classes.sectionSubtitle}>{datesSubtitle}</div>
        <div className={classes.datesContainer}>
          {[ALL_DOCUMENTS_RANGE, ALL_DOCUMENTS_AFTER].includes(
            documentsShownValue,
          ) && (
            <EcDatepicker
              date={
                documentsShownStartDate
                  ? parseDate(documentsShownStartDate)
                  : null
              }
              onDateChange={(date) => {
                const newDate = date ? formatDate(date) : null;
                this.setState({ documentsShownStartDate: newDate });
              }}
              maxDate={
                documentsShownValue === ALL_DOCUMENTS_RANGE
                  ? parseDate(documentsShownEndDate)
                  : null
              }
            />
          )}
          {documentsShownValue === ALL_DOCUMENTS_RANGE ? (
            <span className={classes.datesSeparator}>to</span>
          ) : null}
          {[ALL_DOCUMENTS_RANGE, ALL_DOCUMENTS_BEFORE].includes(
            documentsShownValue,
          ) && (
            <EcDatepicker
              date={
                documentsShownEndDate ? parseDate(documentsShownEndDate) : null
              }
              onDateChange={(date) => {
                const newDate = date ? formatDate(date) : null;
                this.setState({ documentsShownEndDate: newDate });
              }}
              minDate={
                documentsShownValue === ALL_DOCUMENTS_RANGE
                  ? parseDate(documentsShownStartDate)
                  : null
              }
            />
          )}
        </div>
      </div>
    );
  }

  renderDocumentsShown() {
    const { classes } = this.props;
    const { documentsShownValue } = this.state;

    return (
      <div>
        <div className={classes.sectionSubtitle}>DOCUMENTS SHOWN</div>
        <EcRadioContainer
          groupName="documents_shown"
          defaultValue={documentsShownValue}
          onChange={({ selectedValue }) =>
            this.setState({ documentsShownValue: selectedValue })
          }
        >
          {(groupName, selectedValue, onOptionChange) => (
            <div className={classes.radioContainerContent}>
              <EcRadioButton
                id="all_documents"
                groupName={groupName}
                value={ALL_DOCUMENTS}
                label="Show all documents"
                onChange={onOptionChange}
                selectedValue={selectedValue}
              />
              <EcRadioButton
                id="all_documents_before"
                groupName={groupName}
                value={ALL_DOCUMENTS_BEFORE}
                label="Show all documents uploaded before selected date"
                onChange={onOptionChange}
                selectedValue={selectedValue}
              />
              <EcRadioButton
                id="all_documents_after"
                groupName={groupName}
                value={ALL_DOCUMENTS_AFTER}
                label="Show all documents uploaded after selected date"
                onChange={onOptionChange}
                selectedValue={selectedValue}
              />
              <EcRadioButton
                id="all_documents_range"
                groupName={groupName}
                value={ALL_DOCUMENTS_RANGE}
                label="Show all documents uploaded in date range"
                onChange={onOptionChange}
                selectedValue={selectedValue}
              />
            </div>
          )}
        </EcRadioContainer>
        {this.renderDatesContainer()}
        <hr className={classes.horizontalSeparator} />
      </div>
    );
  }

  renderDocumentFields() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.sectionSubtitle}>DOCUMENT FIELDS</div>
        <EcRadioContainer
          groupName="document_fields"
          defaultValue={this.state.showOnlyFlaggedFields}
          onChange={({ selectedValue }) =>
            this.setState({ showOnlyFlaggedFields: selectedValue })
          }
        >
          {(groupName, selectedValue, onOptionChange) => (
            <div className={classes.radioContainerContent}>
              <EcRadioButton
                id="all_fields"
                groupName={groupName}
                value={false}
                label="Show all fields"
                onChange={onOptionChange}
                selectedValue={selectedValue}
              />
              <EcRadioButton
                id="only_flagged_fields"
                groupName={groupName}
                value={true}
                label="Show only flagged fields"
                sublabel="(Documents without flags won't show up in the review process)"
                onChange={onOptionChange}
                selectedValue={selectedValue}
              />
            </div>
          )}
        </EcRadioContainer>
        <hr className={classes.horizontalSeparator} />
      </div>
    );
  }

  renderSelectedFileTypesContainer() {
    const { classes } = this.props;
    const { selectedFileTypes } = this.state;

    return (
      <div className={classes.multipleValuesContainer}>
        <Icon icon="arrow-forward" color="status.info" />
        <div className={classes.multipleValues}>
          {selectedFileTypes.length ? (
            selectedFileTypes.map((value, index) => (
              <EcMultipleSelectValue
                label={value.display_value}
                key={index}
                closeHandler={() => {
                  const newSelectedFileTypes = selectedFileTypes.filter(
                    (multiValue) => multiValue.value !== value.value,
                  );
                  this.setState({ selectedFileTypes: newSelectedFileTypes });
                }}
              />
            ))
          ) : (
            <span className={classes.multipleValuesEmpty}>
              No values selected...
            </span>
          )}
        </div>
      </div>
    );
  }

  renderFileTypes() {
    const { classes, allFileTypes } = this.props;
    const { selectedFileTypes } = this.state;

    return (
      <Fragment>
        <div className={classes.sectionSubtitle}>FILE TYPES</div>
        <EcRadioContainer
          groupName="file_types"
          defaultValue={this.state.reviewAllTypes}
          onChange={({ selectedValue }) =>
            this.setState({ reviewAllTypes: selectedValue })
          }
        >
          {(groupName, selectedValue, onOptionChange) => (
            <div className={classes.radioContainerContent}>
              <EcRadioButton
                id="all_file_types"
                groupName={groupName}
                value={true}
                label="Show all file types"
                onChange={onOptionChange}
                selectedValue={selectedValue}
              />
              <EcRadioButton
                id="selected_file_types"
                groupName={groupName}
                value={false}
                label="Show only selected file types"
                onChange={onOptionChange}
                selectedValue={selectedValue}
              />
            </div>
          )}
        </EcRadioContainer>
        {!this.state.reviewAllTypes && (
          <div className={classes.dataFieldWrapper}>
            <div className={classes.sectionSubtitle}>FILE TYPES</div>
            <div className={classes.dataFieldContainer}>
              <EcMultiSelect
                value={selectedFileTypes}
                options={allFileTypes}
                onChange={(selectedFileTypes) =>
                  this.setState({ selectedFileTypes })
                }
                getOptionLabel={(option) => option.display_value}
                placeholder="Search for files..."
                noOptionsMessage="No files found"
              />
              {this.renderSelectedFileTypesContainer()}
            </div>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    const { submitting } = this.state;

    return (
      <div className={classes.cardWrapper}>
        <EcCard title="Documents" contentStyles={ecCardContentStyles}>
          {this.renderDocumentsShown()}
          {this.renderDocumentFields()}
          {this.renderFileTypes()}
          <div className={classes.sectionActions}>
            <Button
              text="Save Changes"
              disabled={submitting}
              icon="check"
              variant="primary"
              iconPosition="left"
              onClick={this.handleOnSaveChangesClick}
            />
          </div>
        </EcCard>
      </div>
    );
  }
}

ReviewerAdminDocuments.propTypes = {
  documentsShownStartDate: PropTypes.string,
  documentsShownEndDate: PropTypes.string,
  showOnlyFlaggedFields: PropTypes.bool,
  reviewAllTypes: PropTypes.bool,
  selectedFileTypes: PropTypes.array,
  allFileTypes: PropTypes.array,
  showToast: PropTypes.func.isRequired,
};

export default injectSheet(styles)(withFlags(ReviewerAdminDocuments));
