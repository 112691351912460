export const input = {
  border: 'border',
  borderRadius: 'm',
  color: 'gray-900',
  height: 'input-height',
  outline: 'none',
  px: 4,
  ':focus': {
    borderColor: 'blue-500',
  },
  '::placeholder': {
    color: 'gray-600',
  },
};

export const numericInput = {
  '::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
  },
  MozAppearance: 'textfield',
};

export const error = {
  borderColor: 'red-400',
  color: 'red-400',
};
