import React, { useState } from 'react';
import { connect } from 'react-redux';

import { workflowDeleteCondition } from '~/actions/workflow';
import { Button } from '~/eds';
import {
  getConditionErrors,
  getSidebarConditionItems,
} from '~/reducers/workflow';
import { Box, FlexLayout, useModal } from '~/ui';

import ConditionModal from './ConditionModal';
import ConfirmDelete from './ConfirmDelete';
import Item from './Item';

function Conditions({
  // connected
  conditions,
  disabled,
  errors,
  workflowDeleteCondition,
}) {
  const [activeCondition, setActiveCondition] = useState(null);
  const [showConditionBuilderModal, setShowConditionBuilderModal] = useState(
    false,
  );

  function removeActiveCondition() {
    setActiveCondition(null);
  }

  const [deleteModal, showDeleteModal] = useModal({
    actionButton: {
      text: 'Delete',
      variant: 'red',
      errorHandler: () => {},
      promise: async () => {
        workflowDeleteCondition(activeCondition?.id);
      },
    },
    content: <ConfirmDelete condition={activeCondition} />,
    disableAutoHide: true,
    title: 'Delete Condition',
    onHide: removeActiveCondition,
  });

  return (
    <>
      <FlexLayout flexDirection="column" sx={{ minHeight: 0 }}>
        {disabled ? null : (
          <Box px={10} py={3} sx={{ borderBottom: 'border' }}>
            <Button
              id="workflow-sidebar--add-condition-button"
              isFullWidth
              text="Add Condition"
              onClick={() => {
                setActiveCondition();
                setShowConditionBuilderModal(true);
              }}
            />
          </Box>
        )}
        <FlexLayout
          flexDirection="column"
          py={5}
          sx={{ borderBottom: 'border', overflow: 'auto' }}
        >
          {conditions.length === 0 && <Item />}
          {conditions.map((condition) => (
            <Item
              disabled={disabled}
              key={condition.id}
              hasError={errors.invalidConditionIds.has(condition.id)}
              item={condition}
              onEdit={() => {
                setActiveCondition(condition);
                setShowConditionBuilderModal(true);
              }}
              onDelete={() => {
                setActiveCondition(condition);
                if (condition.links.length > 0) {
                  showDeleteModal();
                } else {
                  workflowDeleteCondition(condition.id);
                }
              }}
            />
          ))}
        </FlexLayout>
      </FlexLayout>
      <ConditionModal
        condition={activeCondition}
        onHide={() => setShowConditionBuilderModal(false)}
        visible={showConditionBuilderModal}
      />
      {deleteModal}
    </>
  );
}

const mapStateToProps = ({ builder, workflow }) => ({
  conditions: getSidebarConditionItems(workflow),
  disabled: !builder.shouldEnableAllComponents,
  errors: getConditionErrors(workflow),
});

export default connect(mapStateToProps, { workflowDeleteCondition })(
  Conditions,
);
