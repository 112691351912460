import { trackSegment as trackSegmentEvent } from '~/components/SegmentAnalytics';

import { useClientId } from './useClientId';
import { useCurrentUser } from './useCurrentUser';

export const useTrackSegment = () => {
  const user = useCurrentUser();
  const clientId = useClientId();

  const trackSegment = (event: string, properties: Record<string, any>) => {
    trackSegmentEvent(event, { userId: user.id, clientId, ...properties });
  };

  return trackSegment;
};
