import React from 'react';

import { useToggle } from '../../hooks';
import { SharedModalProps } from '../../types';
import { Modal } from '../Modal';

type Callback = () => void;

export const useModal = (
  props: SharedModalProps,
): [React.ReactNode, Callback, Callback] => {
  const [isVisible, _toggle, show, hide] = useToggle();

  const handleShow = () => {
    if (!isVisible) {
      show();
    }
  };

  const handleHide = () => {
    if (isVisible) {
      props.onHide?.();
      hide();
    }
  };

  const modal = <Modal {...props} isVisible={isVisible} onHide={handleHide} />;

  return [modal, handleShow, handleHide];
};
