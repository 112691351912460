import React, { useEffect, useMemo, useState } from 'react';

import { showToast } from '~/components/Shared/EcToast';
import { api } from '~/redux';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Box, FlexLayout, Text, useModal } from '~/ui';

import ApiKeyRow from './ApiKeyRow';

function ApiKeysTable({ currentUser, keys = [], newKeyId }) {
  const [selectedKey, setSelectedKey] = useState(null);

  const [deleteApiKey, deleteResult] = api.endpoints.deleteApiKey.useMutation();
  const { isSuccess, isError } = deleteResult;

  function getKeyName() {
    return keys.find((k) => k.id === selectedKey.id).name || 'Untitled API Key';
  }

  useEffect(() => {
    if (isSuccess) {
      showToast(SUCCESS, `"${getKeyName()}" API Key successfully removed.`);
      setSelectedKey(null);
    } else if (isError) {
      showToast(ERROR, `"Failed to remove ${getKeyName()}".`);
    }
  }, [isSuccess, isError]); // eslint-disable-line react-hooks/exhaustive-deps
  const [modal, showModal] = useModal({
    title: 'Delete API Key?',
    actionButton: {
      disabled: false,
      text: 'Delete',
      variant: 'red',
      errorHandler: () => {
        showToast(ERROR, 'Delete API Key failed. Please try again.');
      },
      promise: async () => {
        deleteApiKey(selectedKey);
      },
    },
    content: (
      <Text variant="xs-dense">
        Are you sure you want to remove{' '}
        <Text variant="xs-dense-bold">
          "
          {keys.find((k) => k.id === selectedKey?.id)?.name ||
            'Untitled API Key'}
          "
        </Text>{' '}
        API Key?&nbsp;
        <Text variant="xs-dense-bold">
          You won’t be able to undo this action.
        </Text>
      </Text>
    ),
  });

  const currentUserActiveKey = keys.find(
    (k) => k.is_enabled && currentUser.id === k.user_id,
  );

  const sortedKeys = useMemo(() => {
    const _keys = [...keys];
    return _keys.sort((a, b) => (a.date_created < b.date_created ? 1 : -1));
  }, [keys]);

  return (
    <FlexLayout role="table" flexDirection="column">
      <FlexLayout
        alignItems="center"
        px={6}
        py={4}
        sx={{ borderTop: 'border' }}
      >
        <Box pl={4} sx={{ flexBasis: '300px', flexGrow: '1' }}>
          <Text color="gray-700" variant="2xs-dense-bold">
            Generated Keys
          </Text>
        </Box>
        <Box sx={{ width: '300px' }}>
          <Text color="gray-700" variant="2xs-dense-bold">
            Created
          </Text>
        </Box>
        <Box sx={{ width: '300px' }}>
          <Text color="gray-700" variant="2xs-dense-bold">
            Last access
          </Text>
        </Box>
        <Box sx={{ width: '220px' }}>
          <Text color="gray-700" variant="2xs-dense-bold">
            Status
          </Text>
        </Box>
      </FlexLayout>
      {sortedKeys.map((key) => (
        <ApiKeyRow
          activeKey={currentUserActiveKey}
          disabled={key.user_id !== currentUser.id}
          item={key}
          key={key.id}
          shouldAnimate={newKeyId === key.id}
          removeItem={(item) => {
            setSelectedKey(item);
            showModal();
          }}
        />
      ))}
      {modal}
    </FlexLayout>
  );
}

export default ApiKeysTable;
