import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Markdown, Modal, useToast } from '~/eds';
import { api, slices } from '~/redux';
import { Nullable } from '~/types';

import { Attempt, AttemptType } from '../types';

export const AttemptModal = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();

  const [
    archiveAttempt,
    { isLoading: isArchivingAttempt },
  ] = api.endpoints.archiveAttempt.useMutation();

  const [
    resendAttempt,
    { isLoading: isResendingAttempt },
  ] = api.endpoints.resendAttempt.useMutation();

  const { activeAttempt, activeAttemptType: action } = useSelector(
    slices.webhooks.selectors.selectActiveAttempt,
  );

  if (action === 'view' || action === null) {
    return null;
  }

  const handleHide = () =>
    dispatch(slices.webhooks.actions.resetAttemptConfiguration());

  const handleActionConfirm = () => {
    const queries = {
      archive: archiveAttempt,
      resend: resendAttempt,
    };

    const query = queries[action];

    query({
      attempt: activeAttempt!,
    })
      ?.unwrap()
      .then(() => {
        toast({
          status: 'success',
          message: `${toastMessage?.success}`,
        });
      })
      .catch(() => {
        toast({
          status: 'danger',
          message: `${toastMessage?.failure}`,
        });
      })
      .finally(() => {
        handleHide();
      });
  };

  const isLoading = isArchivingAttempt || isResendingAttempt;

  const { children, isFullPage, variant, toastMessage } =
    resolveModalProps({ action, attempt: activeAttempt }) ?? {};

  const modalProps = {
    children:
      children === typeof 'string' ? <Markdown text={children} /> : children,
    isFullPage,
    isVisible: Boolean(action),
    primaryAction: {
      isLoading,
      onClick: handleActionConfirm,
      text: `${capitalize(action ?? '')}`,
      variant,
    },
    onCancel: handleHide,
    onHide: handleHide,
    title: `${capitalize(action ?? '')} Attempt`,
  };

  return <Modal {...modalProps} />;
};

const resolveModalProps = ({
  action,
  attempt,
}: {
  action: Nullable<AttemptType>;
  attempt: Nullable<Attempt>;
}) => {
  switch (action) {
    case 'archive':
      return {
        children: `Are you sure you want to archive ${attempt?.endpointUrl}`,
        variant: 'danger' as const,
        isFullPage: false,
        toastMessage: {
          success: `Attempt ${attempt?.endpointUrl} (<${attempt?.id}>) archived.`,
          failure: 'Failed to archived attempt.',
        },
      };
    case 'resend':
      return {
        //TODO: change the text when figma is updated
        children: `Resending an attempt can lead to loss of delivery status data and hinder troubleshooting. Are you sure you want to resend ${attempt?.endpointUrl}`,
        variant: 'primary' as const,
        isFullPage: false,
        toastMessage: {
          success: `Attempt ${attempt?.endpointUrl} (<${attempt?.id}>) resended.`,
          failure: 'Failed to resend attempt.',
        },
      };
    case 'view':
    default:
      return null;
  }
};
