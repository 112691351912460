import {
  AttributesField,
  CustomAttributes,
  FormField,
  MultiSelectField,
  ShortResponseField,
  SingleSelectField,
} from '~/types';

export const getFieldErrors = (formField: FormField<any>) => {
  let parsedFormField;
  const errors = [];

  switch (formField.field.type) {
    case 'SHORT_RESPONSE':
    case 'LONG_RESPONSE':
      parsedFormField = formField as FormField<ShortResponseField>;
      const fieldValue = parsedFormField.field.value;
      const pattern = new RegExp(parsedFormField.pattern ?? '', 'i');
      const isPatternMatch = parsedFormField.pattern
        ? pattern.test(fieldValue)
        : true;

      if (parsedFormField.required && fieldValue === '') {
        errors.push('This field is required.');
      }
      if (!isPatternMatch && fieldValue) {
        errors.push('The value entered does not match the required pattern.');
      }

      break;

    case 'SINGLE_SELECT':
    case 'ROLE_SELECT':
      parsedFormField = formField as FormField<SingleSelectField>;
      if (
        parsedFormField.required &&
        parsedFormField.field?.value?.value === ''
      ) {
        errors.push('This field is required.');
      }
      break;

    case 'MULTI_SELECT':
      parsedFormField = formField as FormField<MultiSelectField>;
      if (
        parsedFormField.required &&
        parsedFormField.field?.value?.length === 0
      ) {
        errors.push('At least one option must be selected.');
      }
      break;
    // ATTRIBUTES: will have its own validation on the specific component
  }

  return errors;
};

export const isFieldValid = (formField: FormField<any>) => {
  switch (formField.field.type) {
    case 'SHORT_RESPONSE':
    case 'LONG_RESPONSE':
      const longResponseField = formField as FormField<ShortResponseField>;
      const fieldValue = longResponseField.field.value;
      const pattern = new RegExp(longResponseField.pattern ?? '', 'i');
      const isPatternMatch = longResponseField.pattern
        ? pattern.test(fieldValue)
        : true;

      if (longResponseField.required) {
        return !!fieldValue && isPatternMatch;
      } else if (fieldValue) {
        return isPatternMatch;
      } else {
        return true;
      }
    case 'SINGLE_SELECT':
      const singleSelectField = formField as FormField<SingleSelectField>;
      if (singleSelectField.required) {
        return !!singleSelectField.field?.value?.value;
      } else {
        return true;
      }
    case 'MULTI_SELECT':
      const multiSelectField = formField as FormField<MultiSelectField>;
      if (multiSelectField.required) {
        return multiSelectField.field?.value?.length > 0;
      } else {
        return true;
      }

    case 'ATTRIBUTES':
      const attributesField = formField as FormField<AttributesField>;
      if (attributesField.required) {
        const { email, firstName, lastName } = attributesField.field.value;
        return !!email && !!firstName && !!lastName && !!email;
      } else {
        return true;
      }

    case 'CUSTOM_ATTRIBUTES':
      const customAttributesField = formField as FormField<CustomAttributes>;

      const isSomeNameEmpty = Object.keys(
        customAttributesField.field.value,
      ).some((name) => !name);
      const isSomeLabelEmpty = Object.values(
        customAttributesField.field.value,
      ).some((label) => !label);

      return !isSomeLabelEmpty && !isSomeNameEmpty;

    default:
      return true;
  }
};
