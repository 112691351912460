import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.Groups, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.Groups as TagType],
  endpoints: {
    getAllDocumentGroups: {
      providesTags: [TAG_BY_LIST],
    },
    getDocumentGroup: {
      providesTags: [TAG_BY_LIST],
    },
    addDocumentsToGroup: {
      invalidatesTags: [TAG_BY_LIST],
    },
    addDocumentsToGroupLinkParent: {
      invalidatesTags: [TAG_BY_LIST],
    },
    createDocumentGroup: {
      invalidatesTags: [TAG_BY_LIST],
    },
    removeDocumentInGroup: {
      invalidatesTags: [TAG_BY_LIST],
    },
    editDocumentGroup: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deleteDocumentGroup: {
      invalidatesTags: [TAG_BY_LIST],
    },
  },
});
