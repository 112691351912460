import React from 'react';
import { Editor, Transforms } from 'slate';

import { MentionTriggerType } from '../../../enums';
import {
  CustomEditor,
  ElementNode,
  ElementProps,
  EntityType,
  MentionedEntity,
  MentionNode,
  Mentions,
  TextNode,
} from '../types';
import { focusEditor } from '../utils';

export const insertMention = (
  editor: CustomEditor,
  mention: MentionedEntity<EntityType>,
) => {
  // TODO: generalize this for all future mention types in a switch/case
  const mentionNode: MentionNode = {
    type: 'mention',
    entity: {
      id: mention.id,
      type: mention.type,
    },
    children: [
      {
        text: mention.label,
      },
    ],
    isInvalid: mention.isInvalid,
    tooltip: mention.tooltip,
  };
  Transforms.insertNodes(editor, mentionNode);
  Transforms.move(editor);
};

export const triggerMentions = (editor: CustomEditor) => () => {
  if (!editor.selection) {
    Transforms.select(editor, Editor.end(editor, []));
  }

  editor.insertText(MentionTriggerType.User);

  focusEditor(editor);
};

interface EnhanceData {
  mentions: Mentions;
}

export function withEnhanceElement(
  ElementComponent: React.ComponentType<ElementProps<ElementNode>>,
  enhanceData: EnhanceData,
) {
  function ElementComponentWithMentions(props: ElementProps<ElementNode>) {
    const transformElement = (element: ElementNode): ElementNode => {
      switch (element.type) {
        case 'mention': {
          const mentionedEntity = enhanceData.mentions[
            element.entity.type
          ]?.find((mention) => mention.id === element.entity.id);

          const _node: [TextNode] = mentionedEntity
            ? element.children
            : [{ text: `${element.children[0].text} (Deactivated)` }];

          return {
            ...element,
            children: _node,
            tooltip: mentionedEntity?.tooltip,
            isInvalid: mentionedEntity?.isInvalid ?? true,
          };
        }

        default:
          return element;
      }
    };
    return (
      <ElementComponent {...props} element={transformElement(props.element)} />
    );
  }
  return ElementComponentWithMentions;
}
