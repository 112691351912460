export default `
<svg
  aria-hidden="true"
  aria-label="status-inactive"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.5605 4.74627C11.7168 5.25594 11.4355 5.82931 10.9043 5.98858C8.4043 6.75308 6.62305 9.11028 6.62305 11.8497C6.62305 15.2263 9.31055 17.9657 12.623 17.9657C15.9355 17.9657 18.623 15.2263 18.623 11.8497C18.623 9.11028 16.8105 6.75308 14.3105 5.98858C13.8105 5.82931 13.498 5.25594 13.6543 4.74627C13.8105 4.20475 14.373 3.88621 14.9043 4.04548C18.1855 5.06481 20.623 8.15466 20.623 11.8497C20.623 16.373 17.0293 20.0044 12.623 20.0044C8.18555 20.0044 4.62305 16.373 4.62305 11.8497C4.62305 8.15466 7.0293 5.06481 10.3105 4.04548C10.8418 3.88621 11.4043 4.20475 11.5605 4.74627Z"
    fill="currentcolor"
  />
</svg>
`;
