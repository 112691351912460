import { useEffect, useState } from 'react';

import { Layout, NumberInput, Select } from '~/eds';

import { Filter, NumberValue } from '../types';

interface Props {
  filter: Filter<NumberValue>;
  onChange: (updatedValues: NumberValue[]) => void;
}

const options = [
  { label: 'Day', value: 1 },
  { label: 'Week', value: 7 },
  { label: 'Month', value: 30 },
  { label: 'Year', value: 365 },
];

export const AgeValues = ({ filter, onChange }: Props) => {
  const { id, operatorId, values } = filter;
  const [min = null, max = null] = values;

  const [unitValue, setUnitValue] = useState(1);
  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);

  useEffect(() => {
    if (operatorId === 'between') {
      const adjustedMin = minValue ? minValue * unitValue : null;
      const adjustedMax = maxValue ? maxValue * unitValue : null;
      onChange([adjustedMin, adjustedMax]);
    } else {
      const adjustedValue = minValue ? minValue * unitValue : null;
      onChange(minValue ? [adjustedValue] : []);
    }
  }, [minValue, maxValue, unitValue, operatorId]);

  const inputs = () => {
    switch (operatorId) {
      case 'between': {
        return (
          <>
            <NumberInput
              name={`${id}-min`}
              value={minValue}
              placeholder="Min"
              width="50%"
              onChange={setMinValue}
            />
            <NumberInput
              name={`${id}-max`}
              value={maxValue}
              placeholder="Max"
              width="50%"
              onChange={setMaxValue}
            />
          </>
        );
      }
      default:
        return (
          <NumberInput
            name={id}
            value={minValue}
            placeholder="Amount"
            width="50%"
            onChange={setMinValue}
          />
        );
    }
  };

  return (
    <Layout spacing={2}>
      {inputs()}
      <Select
        enablePortal={true}
        isClearable={false}
        isSearchable={false}
        isMulti={false}
        name="unit"
        options={options}
        placeholder="Unit"
        width="input.s.width"
        value={unitValue}
        onChange={(selectedOption) =>
          selectedOption && setUnitValue(selectedOption)
        }
      />
    </Layout>
  );
};
