import React from 'react';

import { Box, Layout, LoadingShimmer } from '~/eds';

export const ActivityLogItemShimmer = () => (
  <Layout w="100%" spacing={2}>
    <Box h={40} w={40}>
      <LoadingShimmer />
    </Box>
    <Layout direction="column" w="100%" spacing={1}>
      <Box h={18}>
        <LoadingShimmer />
      </Box>
      <Box h={18}>
        <LoadingShimmer />
      </Box>
    </Layout>
  </Layout>
);
