/** @jsxRuntime classic */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import Tippy from '@tippyjs/react';
import PT from 'prop-types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- debug why this is not working
import React from 'react';
import 'tippy.js/dist/tippy.css';

import theme from '../../theme';
import Box from '../Box';

const DEFAULT_MAX_WIDTH = 328;

const className = css({
  '&.tippy-box': {
    ...theme.text['2xs-dense-bold'],
    backgroundColor: theme.colors['gray-900'],
    padding: `${theme.space[2]} ${theme.space[4]}`,
    '.tippy-arrow': {
      color: theme.colors['gray-900'],
    },
    '.tippy-content': {
      padding: 0,
      whiteSpace: 'pre-wrap',
    },
  },
});

function Tooltip({
  arrow = true,
  children,
  content,
  placement = 'top',
  shouldHideOnClick = true,
}) {
  if (!content) {
    return children;
  }

  // https://github.com/atomiks/tippyjs-react#component-children
  const component =
    typeof children.type === 'string' ? (
      children
    ) : (
      <Box
        sx={{
          display: 'flex',
          ':focus': {
            outline: 'none', // remove focus-ring set by tippy
          },
        }}
        tabIndex="0"
      >
        {children}
      </Box>
    );

  return (
    <Tippy
      arrow={arrow}
      css={className}
      content={content}
      hideOnClick={shouldHideOnClick}
      maxWidth={DEFAULT_MAX_WIDTH}
      placement={placement}
    >
      {component}
    </Tippy>
  );
}

Tooltip.propTypes = {
  /** Indicates the presence of tooltip arrow. */
  arrow: PT.bool,
  /** React children */
  children: PT.node.isRequired,
  /** Any react node */
  content: PT.node,
  /** Tooltip placement */
  placement: PT.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  /** Hides tooltip when trigger is clicked */
  shouldHideOnClick: PT.bool,
};

export default Tooltip;
