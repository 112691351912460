import type { Link, Parent } from 'mdast';
import remarkParse from 'remark-parse';
import remarkStringify from 'remark-stringify';
import { unified } from 'unified';
import { visit } from 'unist-util-visit';

import { Citation } from '../types';

export const linkVisitor = (): any => {
  return (tree: Parent, file: any) => {
    const linkReferences = new Set<string>();
    //@ts-ignore
    visit(
      tree,
      'linkReference',
      (node: Link, _index: number, _parent: Parent) => {
        linkReferences.add(node.label as string);
      },
    );
    file.data.links = Array.from(linkReferences);
  };
};

export const removeHTMLNodes = () => {
  return (tree: Parent) => {
    //@ts-ignore
    visit(tree, 'html', (_node: any, index: number, parent: Parent) => {
      parent.children.splice(index, 1);
    });
  };
};

export const postProcessMarkdown = (
  text: string,
  citationsMap: Record<string, Citation>,
) => {
  const file = unified()
    .use(remarkParse)
    .use(removeHTMLNodes)
    .use(linkVisitor)
    .use(remarkStringify)
    .processSync(text);

  const links = file.data.links as string[];
  const citations: Citation[] = links.map((link) => ({
    ...citationsMap[link],
    key: link,
  }));

  return { citations, text: String(file) };
};
