import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Select } from '~/eds';
import { getConditionOptions } from '~/reducers/workflow';

function ConditionSelect({
  value,
  onChange,
  // connected
  options,
  shouldValidate,
}) {
  let error;
  if (shouldValidate) {
    if (value == null) {
      error = 'Missing Condition';
    } else if (!options.find((option) => option.value === value)) {
      error = 'Invalid Condition';
    }
  }

  return (
    <Select
      error={error}
      options={options}
      enablePortal={true}
      placeholder={error || 'Condition'}
      value={value}
      width="input.l.width"
      onChange={onChange}
    />
  );
}

ConditionSelect.propTypes = {
  /** selected item's value */
  value: PropTypes.any,
  /** callback that returns newly selected party id */
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps({ builder, workflow }) {
  return {
    options: getConditionOptions(workflow),
    shouldValidate: builder.shouldValidate,
  };
}

export default connect(mapStateToProps)(ConditionSelect);
