import React, { memo } from 'react';

import { Icon } from '../Icon';

interface Props {
  label?: string;
  mode?: 'dashed' | 'solid';
}

export const LoadingSpinner = memo(
  ({ label = 'Loading', mode = 'solid' }: Props) => {
    return (
      <Icon
        icon={mode === 'dashed' ? 'loading-dashed' : 'loading'}
        label={label}
        role="status"
      />
    );
  },
);
