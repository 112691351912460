import isEmpty from 'lodash/isEmpty';

export const testValueError = (config) =>
  testInvalidUrl(config) || testEmptyValue(config);

export const testEmptyValue = (config) => {
  const fieldValue = config.field.value;

  if (config.fieldName === 'attributes') {
    const requiredKeys = ['email', 'firstName', 'lastName'];

    return requiredKeys.some((requiredKey) => fieldValue[requiredKey] === '');
  }
  return config.required && fieldValue === '';
};

export const testInvalidUrl = (config) => {
  const fieldValue = config.field.value;

  if (['sso_url', 'idp_metadata_url'].includes(config.fieldName)) {
    return fieldValue && !/^https?:\/\/.+$/gi.test(fieldValue);
  }
  return false;
};

export const testIsValidForm = (form) =>
  form.sections.every((section) =>
    section.configs.every((config) => {
      if (config.fieldName === 'custom_attributes') {
        const { value } = config.field;
        if (Object.keys(value).length === 0) {
          return true;
        } else {
          return Object.values(value).every((v) => !isEmpty(v));
        }
      } else if (config.fieldName === 'attributes') {
        const requiredAttributes = ['email', 'firstName', 'lastName'];
        return requiredAttributes.every(
          (attr) => !isEmpty(config.field.value[attr]),
        );
      } else {
        return config.required ? !isEmpty(config.field.value) : true;
      }
    }),
  );
