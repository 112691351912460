import {
  black2,
  black3,
  evisortBlue,
  evisortYellow,
  semibold,
} from '../../../../assets/shared-styles/general';

const sectionField = {
  minHeight: '38px',
  padding: '12px 16px',
  position: 'relative',
};

const styles = {
  sectionFieldWrapper: {
    width: '100%',
  },
  sectionField: {
    ...sectionField,
  },
  sectionFieldEditMode: {
    ...sectionField,
    position: 'static',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
  sectionFieldLoading: {
    ...sectionField,
    opacity: 0.1,
  },
  sectionFieldEditModeLoading: {
    ...sectionField,
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    opacity: 0.1,
  },
  fieldTitle: {
    marginBottom: '16px',
    paddingRight: '24px',
    display: 'inline-flex',
    color: (props) => (props.field.is_flagged ? evisortYellow : black3),
    fontSize: '11px',
    fontWeight: semibold,
    textTransform: 'uppercase',
    wordBreak: 'break-word',
  },
  fieldValue: {
    color: black2,
    fontSize: '14px',
  },
  fieldActionIcons: {
    paddingRight: '15px',
    position: 'absolute',
    top: '12px',
    bottom: 0,
    right: 0,
    alignItems: 'center',
    '& button': {
      padding: 0,
      backgroundColor: 'transparent',
      border: 'none',
    },
    '& svg': {
      cursor: 'pointer',
    },
  },
  fieldFooter: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
    color: evisortBlue,
    fontSize: '13px',
    '& button': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      color: evisortBlue,
      '&:first-child': {
        marginRight: '16px',
      },
      '& svg': {
        marginRight: '4px',
      },
    },
  },
  horizontalSeparator: {
    width: '8px',
  },
  multipleValuesContainer: {
    marginTop: '4px',
    display: 'flex',
  },
  multipleValues: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textArea: {
    padding: '7px',
    maxWidth: '93%',
    maxHeight: '90px',
    fontSize: '15px',
    lineHeight: '20px',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '2px',
    '&:focus': {
      outline: 'none',
    },
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editItemContainer: {
    '& svg[role=presentation]': {
      display: 'none',
    },
  },
  betaTooltip: {
    marginLeft: '3px',
    padding: '2px 3px 1px',
    display: 'inline-block',
    height: '13px',
    position: 'relative',
    top: '-1px',
    flexShrink: 0,
    borderRadius: '3px',
    backgroundColor: evisortYellow,
    color: '#272727',
    fontSize: '8px',
    fontWeight: semibold,
  },
  tooltipContent: {
    textTransform: 'none',
  },
  helpTextTooltip: {
    marginLeft: '7px',
    padding: '2px 3px 1px',
    display: 'inline-block',
    position: 'relative',
    top: '-4px',
  },
  fieldTokenIndicator: {
    marginLeft: '4px',
    position: 'relative',
    top: '-2px',
    cursor: 'pointer',
  },
  showEditButton: {
    opacity: 1,
  },
  hideEditButton: {
    opacity: 0,
  },
};

export default styles;
