import { noop, orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  Comments as EdsComments,
  getUserName,
  Panel_DEPRECATED,
  types as Types,
  useDebouncedState,
  useThrottledState,
  useToast,
} from '~/eds';
import { EntityType } from '~/enums';
import { useCurrentUser, useUsers } from '~/hooks';
import { api } from '~/redux';
import {
  CommentFilters,
  ContextEntityType,
  MentionEntityType,
  Nullable,
  Comment as ServerComment,
  CommentData as ServerCommentData,
  Uuid,
} from '~/types';

import { useCommentMentions } from './useCommentMentions';
import {
  extractUserMentionsFromComments,
  filterByParentComment,
  filterComments,
  getEnabledFilters,
  resolveCommentAuthorName,
  resolveIsCommentEmbedded,
  searchComments,
  transformEdsCommentToComment,
  transformServerComments,
} from './utils';

const defaultFilters: CommentFilters = {
  order: 'chronological',
  source: 'all',
  status: 'open',
  flags: [],
};

type EnabledFilters = {
  [key in keyof CommentFilters]?: boolean;
};

const defaultEnabledFilters: Types.Comments.EnabledFilters = {
  order: false,
  source: false,
  flags: true,
  status: true,
};

type SidebarEntityType = 'document';
type Comment = Types.Comments.Comment;
type CommentAction =
  | 'reply'
  | 'delete'
  | 'resolve'
  | 'unresolve'
  | 'update'
  | 'create'
  | 'beforeCreate';

interface Props {
  /** required */
  /** The entity id which the comments are from */
  entity: Types.Entity<SidebarEntityType>;
  /** is the comments panel expanded */
  isExpanded: boolean;
  /** A callback to resolve the copy link button click */
  onCopyLink: (comment: Comment) => void;

  /** optional */
  /** The panel actions */
  actions?: Types.Action[];
  /** The comment that will be active and visible */
  activeCommentId?: Uuid;
  /** The current version id of the document */
  context?: Types.Entity<ContextEntityType>[];
  /** which filters are enabled */
  enabledFilters?: EnabledFilters;
  /** Comments to be added to the panel */
  initialComments?: ServerComment[];
  /** if the editor, if one present, is in edit mode */
  isInEditMode?: boolean;
  /** Chip to be added on the Text editor to provide context.  */
  textEditorChips?: Types.SharedChipProps[];
  /** The width of the panel according to expanse */
  width?: 'm' | 'l' | 'fill';
  /** resolve external comments name */
  commentAuthorNameResolver?: (comment: ServerComment) => string;
  /** resolve if the comment is embedded in the document. */
  isCommentEmbeddedResolver?: (comment: ServerComment) => boolean;
  /** An exposed callback to do additional filtering */
  filterComment?: (
    comment: Comment,
    index?: number,
    array?: Comment[],
  ) => boolean;
  /** function to resolve highlights */
  highlightResolver?: (
    highlightEntity: Types.Entity,
  ) => Nullable<{ id: string; text: string }>;
  /** This optional callback is fired when mentioning.
   * @param mention the mentioned entity
   */
  onMention?: (mention: Types.MentionedEntity<MentionEntityType>) => void;
  /** A callback when the view changes, eg. from listing all to listing replies */
  onCommentViewChange?: (comment: Nullable<Comment>) => void;
  /**
   * A callback to filter the comment actions that appear in the comment actions menu
   *
   * @param action the action to be evaluated
   * @param comment the comment which this action is uppon
   */
  onFilterAction?: (action: Types.Action, comment: Comment) => Types.Action;
  /** A callback to handle highlights click */
  onHighlightClick?: (highlight: Uuid) => void;
  /**
   * A callback that will be called whenever an action is invoked. Actions are: 'reply' | 'delete' | 'resolve' | 'unresolve' | 'update' | 'create'
   *
   * @param action the action that called the callback
   * @param comment the comment object
   */
  onUpdate?: (comment: ServerComment, action?: CommentAction) => void;
  /* A callback to run when comments are filtered inside CommentSidebar. */
  onUpdateFilters?: (filteredComments: ServerComment[]) => void;
  /** An exposed callback to order comments */
  orderComments?: (comments: Comment[], filters: CommentFilters) => Comment[];
}

export const Comments = ({
  actions = [],
  activeCommentId,
  commentAuthorNameResolver,
  context = [],
  enabledFilters: initialEnabledFilters = defaultEnabledFilters,
  entity,
  initialComments = [],
  isCommentEmbeddedResolver,
  isExpanded,
  isInEditMode = false,
  textEditorChips,
  width = 'm',
  filterComment,
  highlightResolver,
  onCommentViewChange,
  onCopyLink,
  onFilterAction,
  onHighlightClick,
  onMention,
  onUpdate,
  onUpdateFilters,
  orderComments,
}: Props) => {
  // states
  const [parentComment, setParentComment] = useState<Nullable<Comment>>(null);
  const [shouldFindActiveComment, setShouldFindActiveComment] = useState(
    Boolean(activeCommentId),
  );
  const [internalActiveCommentId, setInternalActiveCommentId] = useState<
    Nullable<Uuid>
  >(activeCommentId || null);
  const [loadingCommentId, setLoadingCommentId] = useState<Nullable<Uuid>>();
  const [filters, setFilters] = useState<CommentFilters>(defaultFilters);
  const [throttledSearch, search, setSearch] = useThrottledState<
    Nullable<string>
  >('');
  const currentUser = useCurrentUser();
  const users = useUsers();
  const enabledFilters = { ...defaultEnabledFilters, ...initialEnabledFilters };
  const { toast } = useToast();

  const [
    debouncedMentionSearch,
    _mentionSearch,
    setMentionSearch,
  ] = useDebouncedState('');

  // RTQK
  const [
    getComments,
    { data: commentData = [], isFetching: isLoadingThread, currentData },
  ] = api.endpoints.getComments.useLazyQuery();

  const [
    createComment,
    { isLoading: isCreatingComment },
  ] = api.endpoints.createComment.useMutation();
  const [updateComment] = api.endpoints.updateComment.useMutation();
  const [deleteComment] = api.endpoints.deleteComment.useMutation();
  const [resolveComment] = api.endpoints.resolveComment.useMutation();
  const [unresolveComment] = api.endpoints.unresolveComment.useMutation();

  // callbacks
  const onUpdateCommentFilters = (filters: CommentFilters) => {
    setFilters(filters);
    const filteredComments = commentData.filter(
      filterComments({ filters, currentUser, parentComment }),
    );
    if (onUpdateFilters) {
      onUpdateFilters(filteredComments);
    }
  };

  const onCreateComment = (newComment: ServerCommentData) => {
    const updatedNewComment: ServerCommentData = {
      ...newComment,
      context: context,
      threadId: parentComment?.id || null,
    };
    onUpdate?.(updatedNewComment as ServerComment, 'beforeCreate');
    setFilters(defaultFilters);
    createComment(updatedNewComment)
      .unwrap()
      .then((updatedComment) => {
        if (updatedComment) {
          const type = updatedComment.threadId ? 'reply' : 'create';
          onUpdate?.(updatedComment, type);
        }
      })
      .catch((e: any) => {
        if (e.response.status === 403) {
          toast({
            message:
              "You don't have permission to create comments. Please contact your Evisort admin for assistance.",
            status: 'info',
          });
        } else {
          toast({
            message: 'An error occurred while creating the comment.',
            status: 'danger',
          });
        }
      });
  };

  const onUpdateComment = (updatedComment: Comment) => {
    setLoadingCommentId(updatedComment.id);
    updateComment(transformEdsCommentToComment(updatedComment))
      .unwrap()
      .then((comment) => {
        comment && onUpdate?.(comment, 'update');
        if (comment && isParentComment(comment.id)) {
          const updatedParentComment: Comment = {
            ...updatedComment,
            content: comment.content,
            modifiedDate: comment.modifiedDate,
          };
          setParentComment(updatedParentComment);
        }
      })
      .catch(() => {
        toast({
          message: 'An error occurred while updating the comment.',
          status: 'danger',
        });
      })
      .finally(() => {
        setLoadingCommentId(null);
      });
  };

  const onReplyComment = (comment: Comment) => {
    setParentComment(comment);
    setInternalActiveCommentId(null);
    setSearch('');
  };

  const onBack = () => {
    parentComment && setInternalActiveCommentId(parentComment.id);
    setParentComment(null);
  };

  const onDeleteComment = (comment: Comment) => {
    setLoadingCommentId(comment.id);
    deleteComment(transformEdsCommentToComment(comment))
      .unwrap()
      .then(() => {
        onUpdate?.(transformEdsCommentToComment(comment), 'delete');
      })
      .catch(() => {
        toast({
          message: 'An error occurred while deleting the comment.',
          status: 'danger',
        });
      })
      .finally(() => {
        if (isParentComment(comment.id)) {
          setParentComment(null);
        }
        setLoadingCommentId(null);
        setInternalActiveCommentId(null);
      });
  };

  const onResolveComment = (comment: Comment) => {
    setLoadingCommentId(comment.id);
    resolveComment(transformEdsCommentToComment(comment))
      .unwrap()
      .then(() => {
        onUpdate?.(transformEdsCommentToComment(comment), 'resolve');
      })
      .catch(() => {
        toast({
          message: 'An error occurred while resolving the comment.',
          status: 'danger',
        });
      })
      .finally(() => {
        if (isParentComment(comment.id)) {
          setParentComment({
            ...comment,
            isResolved: true,
          });
        }
        setLoadingCommentId(null);
        setInternalActiveCommentId(null);
      });
  };

  const onUnresolveComment = (comment: Comment) => {
    setLoadingCommentId(comment.id);
    unresolveComment(transformEdsCommentToComment(comment))
      .unwrap()
      .then(() => {
        onUpdate?.(transformEdsCommentToComment(comment), 'unresolve');
      })
      .catch(() => {
        toast({
          message: 'An error occurred while unresolving the comment.',
          status: 'danger',
        });
      })
      .finally(() => {
        if (isParentComment(comment.id)) {
          setParentComment({
            ...comment,
            isResolved: false,
          });
        }
        setLoadingCommentId(null);
        setInternalActiveCommentId(null);
      });
  };

  useEffect(() => {
    if (entity.id && entity.type) {
      getComments(entity)
        .unwrap()
        .catch((e: any) => {
          if (e.response?.status === 403) {
            toast({
              message:
                "You don't have access to view this comment. Please contact your Evisort admin for assistance.",
              status: 'info',
            });
          } else {
            toast({
              message: 'An error occurred while loading the comments.',
              status: 'danger',
            });
          }
        });
    }
  }, [entity.id, entity.type]);

  /**
   * This is a list of users that need to be resolved in order to display the comments.
   */
  const usersToResolve = useMemo(
    () => [
      ...extractUserMentionsFromComments(commentData),
      ...commentData
        ?.filter((comment) => !!comment.createdBy)
        .map((comment) => comment.createdBy!),
    ],
    [commentData],
  );

  const {
    mentions,
    searchedMentions,
    resolvedUsers,
    isFetching: isMentionsFetching,
    isLoading: isMentionsLoading,
    isLoadingUsers,
    addMentionedEntity,
  } = useCommentMentions({
    searchText: debouncedMentionSearch,
    entityPermission: entity,
    userIds: usersToResolve,
  });

  useEffect(() => {
    const findableCommentId = activeCommentId;
    if (shouldFindActiveComment && currentData && findableCommentId) {
      findActiveComment(findableCommentId);
    }
  }, [currentData, shouldFindActiveComment]);

  useEffect(() => {
    if (activeCommentId) {
      setShouldFindActiveComment(true);
      setParentComment(null);
    } else {
      setInternalActiveCommentId(null);
    }
  }, [activeCommentId]);

  useEffect(() => {
    setFilters(getEnabledFilters({ filters, enabledFilters }));
  }, [initialEnabledFilters]);

  useEffect(() => {
    onCommentViewChange?.(parentComment);
  }, [parentComment]);

  const memoizedResolvers = useMemo(
    () => ({
      user: (userMention: Types.Entity<'user'>) =>
        users[(userMention.id as unknown) as number],
    }),
    [users],
  );

  const isParentComment = (commentId: Uuid) =>
    parentComment && parentComment.id === commentId;

  const comments: Comment[] = useMemo(() => {
    const allComments = [...commentData, ...initialComments];
    const filteredComments = allComments
      .filter(filterByParentComment(parentComment))
      .filter(filterComments({ filters, currentUser, parentComment }))
      .filter(searchComments(throttledSearch ?? ''))
      .map(resolveCommentAuthorName(commentAuthorNameResolver))
      .map(resolveIsCommentEmbedded(isCommentEmbeddedResolver))
      .map(
        transformServerComments({
          users: resolvedUsers,
          serverComments: allComments,
        }),
      );
    const orderedComments = orderComments
      ? orderComments(filteredComments, filters)
      : orderBy(filteredComments, ['createdDate'], ['asc']);

    return filterComment
      ? orderedComments.filter(filterComment)
      : orderedComments;
  }, [
    commentData,
    parentComment,
    resolvedUsers,
    filters,
    throttledSearch,
    initialComments,
  ]);

  /** sometimes the parent comment gets selected before the comments are
   * filled with the resolved user data, so we force update it */
  useEffect(() => {
    if (
      resolvedUsers &&
      parentComment &&
      !isLoadingUsers &&
      !parentComment.createdBy.firstName
    ) {
      const parentCommentUser = resolvedUsers[parentComment.createdBy.id];
      if (parentCommentUser) {
        setParentComment({
          ...parentComment,
          createdBy: parentCommentUser,
        });
      }
    }
  }, [resolvedUsers, isLoadingUsers]);

  const findActiveComment = (commentId: string) => {
    const activeComment: ServerComment | undefined = commentData.find(
      (comment) => comment.id === commentId,
    );
    if (activeComment && activeComment.threadId) {
      const activeCommentParent =
        comments.find((comment) => comment.id === activeComment.threadId) ||
        null;
      setParentComment(activeCommentParent);
    }
    setInternalActiveCommentId(commentId);
    setShouldFindActiveComment(false);
  };

  const enableReply = useMemo(
    () =>
      parentComment &&
      parentComment.context.some(
        (context) => context.type === EntityType.EditorHighlight,
      )
        ? isInEditMode
        : true,
    [isInEditMode, parentComment],
  );

  const panelTitle = parentComment
    ? `Replies to ${getUserName(parentComment.createdBy, {
        placeholderName: 'Deactivated User',
      })}`
    : `Comments (${comments.length})`;

  const handleMentionSearch = (updatedSearch: string) => {
    setMentionSearch(updatedSearch);
  };

  const handleOnMention = (
    mention: Types.MentionedEntity<MentionEntityType>,
  ) => {
    onMention?.(mention);
    addMentionedEntity(mention);
  };

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      actions={actions}
      isEmbedded
      isVisible
      title={panelTitle}
      width={width}
      onHide={noop}
      onBack={parentComment ? onBack : undefined}
    >
      <>
        <EdsComments
          activeCommentId={internalActiveCommentId}
          comments={comments}
          enabledFilters={enabledFilters}
          enableReply={enableReply}
          entity={entity}
          entityResolvers={{
            user: memoizedResolvers.user,
            highlight: highlightResolver,
          }}
          filters={filters}
          isSubmitting={isCreatingComment}
          isLoadingThread={isLoadingThread || isMentionsLoading}
          isMentionsLoading={isMentionsLoading}
          isMentionsFetching={isMentionsFetching}
          loadingCommentId={loadingCommentId}
          mentions={searchedMentions}
          parentComment={parentComment}
          replyStatusMessage="To reply to this comment, click Edit on the left."
          resolvedMentions={mentions}
          search={search ?? ''}
          shouldCollapseAllComments={!isExpanded}
          viewer={currentUser}
          textEditorChips={textEditorChips}
          onCopyLink={onCopyLink}
          onDelete={onDeleteComment}
          onFilterAction={onFilterAction}
          onHighlightClick={onHighlightClick}
          onMention={handleOnMention}
          onMentionSearch={handleMentionSearch}
          onReply={onReplyComment}
          onResolveComment={onResolveComment}
          onSearchChange={setSearch}
          onSubmit={onCreateComment}
          onUnresolveComment={onUnresolveComment}
          onUpdate={onUpdateComment}
          onUpdateFilters={onUpdateCommentFilters}
        />
      </>
    </Panel_DEPRECATED>
  );
};
