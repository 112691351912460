import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';

import EcButton from '~/components/Shared/EcButton';
import { CommentPostType } from '~/enums';
import { withUsers } from '~/hocs';
import { Box, CharacterLimit, enums, FlexLayout } from '~/ui';
import { sortByStringValue } from '~/utils/array';
import { getUserName } from '~/utils/user';

import mentionStyles from './mentionStyles';

// TODO: This wrapper is not needed if Button handles variants.
function CommentPostButton({ isCancelButton, isLoading, postType, onClick }) {
  const isEditPostType = postType === CommentPostType.Edit;
  let submitText;
  if (isEditPostType) {
    submitText = isLoading ? 'Saving…' : 'Save';
  } else {
    submitText = isLoading ? 'Posting…' : 'Post';
  }
  return (
    <EcButton
      borderless={isCancelButton}
      inverse={!isCancelButton}
      height="32px"
      text={isCancelButton ? 'Cancel' : submitText}
      onClick={onClick}
    />
  );
}

function CommentPost({
  autoFocus = false,
  content = '',
  isLoading = false,
  postType,
  onCancel,
  onSubmit,
  onUpdateContent,
  // injected
  users,
}) {
  const [isFocused, setIsFocused] = useState(false);

  function handleCancel() {
    onCancel();
    setIsFocused(false);
  }

  const userMentionsData = Object.values(users)
    .map((user) => ({
      id: user.id,
      display: getUserName(user),
    }))
    .sort(sortByStringValue('display'));

  function handleSubmit() {
    onSubmit(content);
    setIsFocused(false);
  }

  function renderUserSuggestion({ id }) {
    const user = users[id];
    return getUserName(user);
  }

  function displayTransform(id) {
    const user = users[id];
    return getUserName(user);
  }

  const loadingStyle = isLoading
    ? {
        filter: 'grayscale(100%)',
        opacity: 0.3,
        pointerEvents: 'none',
        userSelect: 'none',
      }
    : {};

  return (
    <FlexLayout
      flexDirection="column"
      justifyContent="space-between"
      py={3}
      px={4}
      sx={{
        border: 'border',
        borderRadius: 'm',
        ...loadingStyle,
      }}
    >
      <MentionsInput
        autoFocus={autoFocus}
        placeholder="Add comment"
        style={mentionStyles.mentionsInput}
        value={content}
        onChange={(_, newValue) => onUpdateContent(newValue)}
        onFocus={(e) => {
          const input = e.target;
          const inputLength = input.value.length;
          input.setSelectionRange(inputLength, inputLength);
          setIsFocused(true);
        }}
      >
        <Mention
          data={userMentionsData}
          markup="{{user:__id__}}"
          style={mentionStyles.mention}
          trigger="@"
          displayTransform={displayTransform}
          renderSuggestion={renderUserSuggestion}
        />
      </MentionsInput>
      {isFocused && (
        <>
          <Box mt={5}>
            <CharacterLimit value={content} />
          </Box>
          {content.length <= enums.CHARACTER_INPUT_LIMIT_LONG && (
            <FlexLayout mt={8} space={4}>
              <CommentPostButton
                isLoading={isLoading}
                postType={postType}
                onClick={handleSubmit}
              />
              <CommentPostButton
                isCancelButton
                isLoadingis={isLoading}
                postType={postType}
                onClick={handleCancel}
              />
            </FlexLayout>
          )}
        </>
      )}
    </FlexLayout>
  );
}

CommentPost.propTypes = {
  autoFocus: PropTypes.bool,
  content: PropTypes.string,
  isLoading: PropTypes.bool,
  postType: PropTypes.oneOf(Object.values(CommentPostType)).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdateContent: PropTypes.func.isRequired,
};

export default withUsers(CommentPost);
