import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import { ManageSyncPairStageType } from '~/enums';
import { Box, Divider, FlexLayout, PageLayout, Text } from '~/ui';

const stages = [
  {
    label: 'Provider',
    value: ManageSyncPairStageType.Provider,
  },
  {
    label: 'Authentication',
    value: ManageSyncPairStageType.Authentication,
  },
  {
    label: 'Folders & File types',
    value: ManageSyncPairStageType.Folders,
  },
  {
    label: 'Summary',
    value: ManageSyncPairStageType.Summary,
  },
];

function Header({
  onToggleEnableGuide,
  // connected
  activeSyncPair,
  manageSyncPairStage,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const isReauthenticate = !!activeSyncPair?.isReauthenticate;

  const prefix = activeSyncPair ? `${activeSyncPair.name} - ` : '';
  const title = `${prefix}${
    isReauthenticate ? 'Reauthentciate' : 'New Sync Pair'
  }`;

  const handleClose = () => {
    onToggleEnableGuide(true);
    integrationsSetActiveSyncPair(null);
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.None);
  };

  const centerContent = (
    <FlexLayout alignItems="center" space={3}>
      {stages.map((stage, i) => {
        const isActiveStage = stage.value === manageSyncPairStage;
        return (
          <Fragment key={stage.value}>
            <Text
              color="gray-800"
              disabled={!isActiveStage}
              variant={isActiveStage ? 's-dense-bold' : 's-dense'}
            >
              {stage.label}
            </Text>
            {i < stages.length - 1 && (
              <Box sx={{ width: '24px' }}>
                <Divider />
              </Box>
            )}
          </Fragment>
        );
      })}
    </FlexLayout>
  );

  return (
    <PageLayout.Header
      title={{
        text: title,
      }}
      centerContent={centerContent}
      onClose={handleClose}
    />
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(Header);
