export default `
<svg
  aria-hidden="true"
  aria-label="format"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.1339 17.9663L12.8839 5.46631C12.6339 4.90381 11.7276 4.90381 11.4776 5.46631L6.22762 17.9663C6.07137 18.3413 6.25887 18.7788 6.63387 18.9663C7.00887 19.1226 7.44637 18.9351 7.63387 18.5601L8.66512 16.0288H15.6964L16.7276 18.5601C16.8526 18.8413 17.1339 18.9976 17.4464 18.9976C17.5401 18.9976 17.6339 18.9976 17.7276 18.9663C18.1026 18.7788 18.2901 18.3413 18.1339 17.9663ZM9.32137 14.5288L12.1964 7.68506L15.0401 14.4976H9.32137V14.5288Z"
    fill="currentcolor"
  />
</svg>
`;
