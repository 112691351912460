import {
  black1,
  black3,
  bold,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  headerText: {
    marginBottom: '15px',
    color: black1,
    fontSize: '13px',
    fontWeight: semibold,
  },
  radioContainerContent: {
    display: 'inline-block',
    marginBottom: '10px',
    '& > div:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  relationshipLabel: {
    display: 'block',
  },
  pickADocument: {
    marginLeft: '30px',
    fontSize: '14px',
    '& label': {
      fontSize: '13px',
    },
  },
  lightText: {
    color: black3,
  },
  documentSelectedDisplay: {
    display: 'flex',
    alignItems: 'center',
  },
  selectDocumentCTA: {
    margin: '8px 0 20px',
    display: 'flex',
    color: evisortBlue,
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '13px',
    fontWeight: bold,
    cursor: 'pointer',
  },
};

export default styles;
