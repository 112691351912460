import React, { useMemo } from 'react';

import { Select, types, useDebouncedState } from '~/eds';
import { api } from '~/redux';
import { DropdownFieldValue } from '~/types';

interface Props<M>
  extends types.SharedSelectProps<DropdownFieldValue['value'], M> {
  fieldId: types.PilotId;
}
export const FieldValueSelect = <M extends boolean>({
  fieldId,
  value,
  onChange,
  name,
  ...rest
}: Props<M>) => {
  const [debouncedSearch, _search, setSearch] = useDebouncedState<string>('');
  const { data, isFetching } = api.endpoints.getFieldValues.useQuery({
    fieldId: fieldId as string,
    searchSuggestions: true,
    substringQuery: debouncedSearch,
  });
  const options: types.Option<DropdownFieldValue['value']>[] = useMemo(() => {
    if (data) {
      return data.values.map(({ value, display_value }) => ({
        label: display_value,
        value: value,
      }));
    }
    return [];
  }, [data]);

  return (
    <Select
      {...rest}
      isLoading={isFetching}
      enableSelectAll={false}
      value={value}
      onChange={onChange}
      name={name}
      options={options}
      onSearch={setSearch}
    />
  );
};
