import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { UI_DOCUMENT_METADATA_IMPORT_WIZARD_TESTID } from '~/constants/testids';
import { BulkImportDocumentMetadataType } from '~/enums';

import { SummaryStep, UploadStep } from './Steps';

const steps = [
  BulkImportDocumentMetadataType.Upload,
  BulkImportDocumentMetadataType.Summary,
];

function BulkImportDocumentMetadataWizard(props) {
  const { onClose, clientId, handleImportedJob } = props;

  const mapStepsComponents = {
    [BulkImportDocumentMetadataType.Upload]: UploadStep,
    [BulkImportDocumentMetadataType.Summary]: SummaryStep,
  };

  const [step, setStep] = useState(BulkImportDocumentMetadataType.Upload);
  const [stepData, setStepData] = useState({});

  const moveToStep = (step, stepData) => {
    setStepData(stepData);
    setStep(step);
  };
  const CurrentStep = mapStepsComponents[step];

  return (
    <CurrentStep
      stepCommonWizardProps={{
        onClose,
        steps,
        title: { text: 'Bulk Import' },
        isContainer: true,
      }}
      clientId={clientId}
      moveToStep={moveToStep}
      dataTestid={UI_DOCUMENT_METADATA_IMPORT_WIZARD_TESTID}
      data={stepData}
      handleImportedJob={handleImportedJob}
    />
  );
}

BulkImportDocumentMetadataWizard.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default BulkImportDocumentMetadataWizard;
