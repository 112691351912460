export const SYSTEM_ERROR =
  'Apologies, the system is currently experiencing technical difficulties and cannot process your request at this time.';

export const RETRY_LATER_ERROR =
  'Something went wrong. Please try again later.';
export const RETRY_ERROR = 'An error occurred, please try again.';

export const REFRESH_AND_RETRY_ERROR =
  'An error occurred. Refresh the page and try again.';
export const REFRESH_TO_RETRY_ERROR =
  'An error occurred. Refresh the page to try again.';
