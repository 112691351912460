import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.ClauseLibrary, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.ClauseLibrary as TagType],
  endpoints: {
    clauseLibraryListClauses: {
      providesTags: [TAG_BY_LIST],
    },
    clauseLibraryGetClause: {
      providesTags: [TAG_BY_LIST],
    },
    clauseLibrarySearchClauses: {
      providesTags: [TAG_BY_LIST],
    },
    clauseLibraryCreateClause: {
      invalidatesTags: [TAG_BY_LIST],
    },
    clauseLibraryEditClause: {
      invalidatesTags: [TAG_BY_LIST],
    },
    clauseLibraryDeleteClause: {
      invalidatesTags: [TAG_BY_LIST],
    },
    clauseLibraryCreateVariation: {
      invalidatesTags: [TAG_BY_LIST],
    },
    clauseLibraryEditVariation: {
      invalidatesTags: [TAG_BY_LIST],
    },
    clauseLibraryDeleteVariation: {
      invalidatesTags: [TAG_BY_LIST],
    },
  },
});
