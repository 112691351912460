import { createReducer } from '@reduxjs/toolkit';
import uuid from 'uuid';

import * as actions from '~/actions';
import { testPhaseNonEmpty } from '~/components/Workflow/Workflow.utils';
import {
  EntityType,
  TicketActivityType,
  TicketPermissionType,
  TicketReviewerViewType,
  TicketStatusType,
} from '~/enums';

const initialState = {
  entities: {},
  documentVersions: [],
  isLocked: false,
  judgmentResults: {},
  phases: {},
  selectedDocumentHighlights: { id: null, highlights: [] },
  activities: [],
  viewType: TicketReviewerViewType.AssignedToMe,
};

function activityToJudgmentResult(activity) {
  return {
    id: activity.id,
    note: activity.data.comment,
    createdDate: activity.modifiedDate,
    status: activity.data.status ?? 'pending',
    userId: activity.user.id,
  };
}

function activitiesToJudgmentResult(state, entityId, activities) {
  const judgmentResIds = state.entities[entityId].judgmentResultIds;
  activities.forEach((activity) => {
    switch (activity.type) {
      case TicketActivityType.JudgmentResult: {
        const judgmentResult = activityToJudgmentResult(activity);
        const { id: judgmentResultId } = judgmentResult;

        state.judgmentResults[judgmentResultId] = judgmentResult;
        if (!judgmentResIds.includes(judgmentResultId)) {
          state.entities[entityId].judgmentResultIds.push(judgmentResultId);
        }
        break;
      }
      default:
        break;
    }
  });
}

function normalizeEntities(state, phaseId, entities, entityType) {
  entities.forEach((entity) => {
    const { id, activities = [], tokenIds } = entity;
    state.entities[id] = {
      ...entity,
      id,
      entityType,
      judgmentResultIds: [],
      phaseId,
      htmlHighlights:
        entityType === EntityType.Risk && Array.isArray(tokenIds)
          ? tokenIds.map((tokens) => ({
              id: uuid(),
              tokens: tokens.map((token) => token.toString()),
            }))
          : null,
      riskId: id,
    };
    state.phases[phaseId].entityIds.push(id);
    activitiesToJudgmentResult(state, id, activities);
  });
}

function updatePhaseStatus(state, entityId) {
  const phase = getPhaseByEntityId(state, entityId);
  const entities = phase.entityIds.map((entityId) =>
    getEntityById(state, entityId),
  );
  const statusScores = {
    [TicketStatusType.Approved]: 2,
    [TicketStatusType.Pending]: 1,
    [TicketStatusType.Rejected]: 0,
  };
  const statusScore = entities.reduce((score, entity) => {
    return score + statusScores[entity.status];
  }, 0);

  let status;
  if (statusScore === 0) {
    status = TicketStatusType.Rejected;
  } else if (statusScore / 2 === entities.length) {
    status = TicketStatusType.Approved;
  } else {
    status = TicketStatusType.Pending;
  }
  phase.status = status;
}

const getJudgmentActivities = (activities, judgment) => {
  return activities.filter((activity) =>
    judgment.approvals.some((approval) => approval.id === activity.data.id),
  );
};

// TODO: potentially move entire reducer into reducers/ticket.js
export default createReducer(initialState, (builder) => {
  builder.addCase(actions.ticketSummarySet, (state, action) => {
    if (action.payload.permissions.includes(TicketPermissionType.Admin)) {
      state.viewType = TicketReviewerViewType.AllIssues;
    }
  });

  builder.addCase(actions.ticketStagesSet, (state, action) => {
    state.phases = {};
    state.entities = {};

    const { phases = [] } =
      action.payload.name === 'review' ? action.payload : {};
    phases.filter(testPhaseNonEmpty).forEach((phase) => {
      const { id: phaseId, name, judgments, risks, status } = phase;
      state.phases[phaseId] = {
        id: phaseId,
        isRiskDetection: risks.length > 0, // TODO: backend to formalize these flags
        entityIds: [],
        name,
        status,
      };
      const judgmentsWithActivities = judgments.map((judgment) => {
        return {
          ...judgment,
          activities: getJudgmentActivities(state.activities, judgment),
        };
      });
      normalizeEntities(
        state,
        phaseId,
        judgmentsWithActivities,
        EntityType.TicketJudgment,
      );
      normalizeEntities(state, phaseId, risks, EntityType.Risk);
    });
  });
  builder.addCase(actions.ticketSet, (state, action) => {
    const ticket = action.payload;
    state.ticket = ticket;

    if (action.payload.permissions.includes(TicketPermissionType.Admin)) {
      state.viewType = TicketReviewerViewType.AllIssues;
    }

    state.phases = {};
    state.entities = {};

    const { phases = [] } = action.payload.stages.review || {};
    phases.filter(testPhaseNonEmpty).forEach((phase) => {
      const { id: phaseId, name, judgments, risks, status } = phase;
      state.phases[phaseId] = {
        id: phaseId,
        isRiskDetection: risks.length > 0, // TODO: backend to formalize these flags
        entityIds: [],
        name,
        status,
      };
      normalizeEntities(state, phaseId, judgments, EntityType.TicketJudgment);
      normalizeEntities(state, phaseId, risks, EntityType.Risk);
    });
  });
  builder.addCase(actions.ticketActivitiesSet, (state, action) => {
    state.activities = action.payload;
    const entities = Object.values(state.entities);
    entities.forEach((entity) => {
      activitiesToJudgmentResult(
        state,
        entity.id,
        getJudgmentActivities(state.activities, entity),
      );
    });
  });
  builder.addCase(
    actions.ticketReviewerSetSelectedDocumentHighlights,
    (state, action) => {
      state.selectedDocumentHighlights = action.payload;
    },
  );
  builder.addCase(actions.ticketReviewerUpdateEntity, (state, action) => {
    const patchedEntity = action.payload;
    const { id, status, comment, activities = [] } = patchedEntity;

    state.entities[id]['status'] = status;
    state.entities[id]['comment'] = comment;

    activitiesToJudgmentResult(state, id, activities);
    updatePhaseStatus(state, id);
  });
  builder.addCase(actions.ticketReviewerSetViewType, (state, action) => {
    state.viewType = action.payload;
  });
});

export function getCanViewAllIssues(state) {
  return state.viewType === TicketReviewerViewType.AllIssues;
}

export function getPhases(state) {
  let currentPhaseIndex;
  return Object.values(state.phases).map((phase, phaseIndex) => {
    if (
      !Number.isInteger(currentPhaseIndex) &&
      phase.status !== TicketStatusType.Approved
    ) {
      currentPhaseIndex = phaseIndex;
    }
    return {
      ...phase,
      isCollapsed: phaseIndex !== currentPhaseIndex,
      isDisabled:
        Number.isInteger(currentPhaseIndex) && phaseIndex > currentPhaseIndex,
    };
  });
}

export function getPhaseById(state, phaseId) {
  const phase = state.phases[phaseId];
  const entities = phase.entityIds.map((entityId) =>
    getEntityById(state, entityId),
  );
  let status;
  if (entities.every((entity) => entity.status === TicketStatusType.Approved)) {
    status = TicketStatusType.Approved;
  } else if (
    entities.every((entity) => entity.status === TicketStatusType.Rejected)
  ) {
    status = TicketStatusType.Rejected;
  } else {
    status = TicketStatusType.Pending;
  }
  return {
    ...phase,
    status,
  };
}

export function getPhaseByEntityId(state, entityId) {
  const phaseId = state.entities[entityId]?.phaseId;
  return state.phases[phaseId];
}

export function getJudgmentById(state, judgmentResultId) {
  return state.judgments[judgmentResultId];
}

export function getJudgmentResultById(state, judgmentResultId) {
  return state.judgmentResults[judgmentResultId];
}

export function getEntityById(state, entityId) {
  const entity = state.entities[entityId];
  const judgmentResults = entity.judgmentResultIds.map((judgmentResultId) =>
    getJudgmentResultById(state, judgmentResultId),
  );
  return {
    ...entity,
    judgmentResults,
  };
}
