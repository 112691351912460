import React, { useMemo } from 'react';

import { ContentContainer, Info, Layout } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { RoleAttributes } from '~/redux/api/methods';

import { permissionGroupsToInfoItem } from './RoleDetails.utils';

type Props = {
  role: RoleAttributes;
};

export const RoleDetails = ({ role }: Props) => {
  const hasAskAnything = useFlag(FlagType.AskAnything);
  const {
    data: permissionGroups,
    isFetching,
  } = api.endpoints.getPermissions.useQuery({ hasAskAnything });

  const infos = useMemo(() => {
    return (
      permissionGroups
        ?.map((permissionGroup) =>
          permissionGroupsToInfoItem(permissionGroup, role.permissions),
        )
        .filter((permissionGroups) => permissionGroups.props.length) ?? []
    );
  }, [permissionGroups, role.permissions]);

  return (
    <Layout pr={6} pl={6} pb={6}>
      <ContentContainer loadingContent={{ isLoading: isFetching }}>
        <Info
          sections={[
            {
              title: '',
              description: role.description,
              infos,
            },
          ]}
        />
      </ContentContainer>
    </Layout>
  );
};
