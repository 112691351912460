import {
  black2,
  black4,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  adminUsersTabContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  paginationWrapper: {
    marginTop: '24px',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '21px',
  },
  pageBackAction: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    cursor: 'pointer',
  },
  pageTitle: {
    flexGrow: 1,
  },
  subTitle: {
    color: black4,
    fontSize: '13px',
    fontWeight: semibold,
    letterSpacing: '1px',
  },
  title: {
    margin: '4px 0',
    color: black2,
    fontSize: '32px',
  },
  userSearchRow: {
    display: 'flex',
  },
  userSearchHeader: {
    marginBottom: '12px',
    color: black2,
    fontSize: '12px',
    fontWeight: semibold,
  },
  userSearchContainer: {
    marginRight: '16px',
    marginBottom: '16px',
    '& input': {
      width: '300px',
      padding: '10px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '3px',
      color: black2,
      fontSize: '16px',
      outline: 'none',
      '&::placeholder': {
        color: 'rgba 0 0 0, 0.5',
        fontSize: '14px',
      },
    },
  },
};

export default styles;
