import React, { useEffect, useMemo } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import {
  Box,
  ContentContainer,
  DescriptionListAccordion,
  SearchInput,
  Text,
  useThrottledState,
} from '~/eds';
import { api, coerceRtkqError } from '~/redux';
import { Field, Section } from '~/redux/api/methods';
import { Nullable, PilotId } from '~/types';
import { ERROR } from '~/types/toast.types';

import { filterSections } from './Fields.utils';

type Props = {
  handlerId: PilotId;
  documentId?: PilotId;
};

const DEFAULT_TRACKING_DEBOUNCED_MS = 1000;

export const Fields = ({ handlerId, documentId }: Props) => {
  const {
    data,
    isFetching,
    isError,
    error,
  } = api.endpoints.getDocumentInformation.useQuery(handlerId);
  const [throttledSearch, search, setSearch] = useThrottledState(
    '',
    DEFAULT_TRACKING_DEBOUNCED_MS,
  );

  useEffect(() => {
    if (isError) {
      showToast(
        ERROR,
        coerceRtkqError(error)?.response?.data?.detail ||
          'An error occurred while loading document information.',
      );
    }
  }, [isError]);

  useEffect(() => {
    if (throttledSearch) {
      trackSegment('searchFields', {
        name: documentId,
        value: throttledSearch,
      });
    }
  }, [throttledSearch]);

  const sections = useMemo(() => {
    if (isError || !data) return [];

    return data.sections.map(toAccordionSection);
  }, [isError, data]);

  const filteredSections = useMemo(() => {
    return filterSections(sections, search);
  }, [sections, search]);

  const handleSearch = (text: Nullable<string>) => {
    setSearch(text ?? '');
  };

  return (
    <ContentContainer loadingContent={{ isLoading: isFetching }}>
      <>
        <Box p={4}>
          <SearchInput
            disabled={isError || isFetching}
            placeholder="Search fields"
            name="search"
            onChange={(text) => {
              handleSearch(text);
            }}
            value={search}
          />
        </Box>
        <DescriptionListAccordion
          isEmbedded={true}
          columns={2}
          items={filteredSections}
          search={search}
          onToggle={(isExpanded: boolean, item) => {
            trackSegment('selectAccordion', {
              name: documentId,
              value: item.title,
              isExpanded,
            });
          }}
        />

        {filteredSections.length === 0 && search && (
          <Box mt={2}>
            <Text color="text.quiet">No matching fields</Text>
          </Box>
        )}
      </>
    </ContentContainer>
  );
};

export const toAccordionSection = (section: Section) => {
  return {
    title: section.title,
    terms: section.fields.map(toAccordionTerm),
    isExpanded: true,
  };
};

export const toAccordionTerm = (field: Field) => {
  return {
    title: field.name,
    description: field.displayValue,
    tooltip: field.help_text,
  };
};
