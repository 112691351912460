import React, { useEffect } from 'react';

import { RolesSelect } from '~/components/Shared/RolesSelect';
import { FormField } from '~/eds';
import { RoleType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { FormField as FormFieldType, SingleSelectField } from '~/types';

type Props = {
  clientId?: string;
  formField: FormFieldType<SingleSelectField>;
  errors?: string[];
  onExplain?: (fieldName: string) => void;
  onChange: (value: string) => void;
};

export const RolesSelectField = ({
  clientId,
  formField,
  errors = [],
  onExplain,
  onChange,
}: Props) => {
  const isPremiumAdministrationEnabled = useFlag(
    FlagType.PremiumAdministration,
  );
  const { data: roles } = api.endpoints.getRolesSimple.useQuery(
    {
      page: 1,
      pageSize: 100,
      client_id: clientId,
    },
    { skip: isPremiumAdministrationEnabled },
  );

  useEffect(() => {
    if (roles) {
      const powerUserRole = roles.results.find(
        (role) =>
          role.roleType === RoleType.PreBuiltRole &&
          role.name.toLowerCase() === 'power user',
      );
      if (powerUserRole) {
        onChange(powerUserRole.id);
      }
    }
  }, [roles]);

  return (
    <FormField
      label={formField.title}
      description={formField.description}
      onChange={(value) => {
        onChange((value as unknown) as string);
      }}
      input={RolesSelect}
      onExplain={onExplain ? () => onExplain(formField.fieldName) : undefined}
      inputProps={{
        endpoint: formField.field.endpoint,
        clientId,
      }}
      disabled={!isPremiumAdministrationEnabled}
      tooltip={
        isPremiumAdministrationEnabled
          ? undefined
          : 'Available with the Advanced Administration add-on.'
      }
      error={errors.join(', ')}
      name={formField.fieldName}
      value={formField?.field?.value}
    />
  );
};
