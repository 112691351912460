import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  generateTextStringForFilterTags,
  toFilter,
} from '~/components/SearchV2/SearchV2.utils';
import { checkSearchQueryHasUnsupportedEntity } from '~/components/SearchV2/utils';
import { trackSegment } from '~/components/SegmentAnalytics';
import BaseBooleanSearchBar from '~/components/Shared/BaseBooleanSearchBar';
import { SEARCH_DEBOUNCE_MS } from '~/constants/debounces';
import { QUICK_SEARCH_DOCUMENT_COUNT } from '~/constants/max_lengths';
import {
  Box,
  Button,
  ContentContainer,
  Divider,
  Layout,
  Link,
  Menu,
  PageOverlay,
  Text,
} from '~/eds';
import { QueryEntityType } from '~/enums';
import { Filters as FiltersType, Option } from '~/evifields';
import { FlagType, useFlag } from '~/flags';
import { usePinnedFilters } from '~/hooks';
import { api } from '~/redux';
import {
  BooleanTextSearchEntity,
  Folder,
  PilotSearchQuery,
  RecentSearches,
  testIsSupportedFilterType,
  toFilters,
} from '~/redux/api/methods';
import search, { isActiveFilter } from '~/redux/slices/search';
import searchV3 from '~/redux/slices/searchV3';
import { useRouting } from '~/routing';
import { debounce } from '~/utils/debounce';
import { getFileIconNameByFileType } from '~/utils/fileTypeIcon';

import EcBooleanTextSearchPopup from '../EcBooleanTextSearchPopup';

type Props = {
  isVisible: boolean;
  hideModal: () => void;
  onSearchSubmit: (query: string) => void;
};

const QuickSearchModal = ({
  isVisible,
  hideModal,
  onSearchSubmit = (_query: string) => '',
}: Props) => {
  const dispatch = useDispatch();
  const { navigate } = useRouting();
  const hasSearchV3 = useFlag(FlagType.SearchV3);
  const hasUserPreferencesPinnedFilters = useFlag(FlagType.PinnedFilters);
  const { data: sections } = api.endpoints.getFilterSections.useQuery(
    undefined,
  );
  const [isSyntaxPopupOpen, setIsSyntaxPopupOpen] = useState(false);
  const [booleanQuery, setBooleanQuery] = useState('');
  const [validationError, setValidationError] = useState(false);

  const {
    getPinnedFilters,
    result: {
      data: userPreferencesDefaultFilters,
      isFetching: isFetchingPinnedFilters,
      isSuccess: isSuccessPinnedFilters,
    },
  } = usePinnedFilters(sections?.fields);

  const isLoadingPinnedFilters =
    hasUserPreferencesPinnedFilters &&
    (!isSuccessPinnedFilters || isFetchingPinnedFilters);

  const hasNewPins = isLoadingPinnedFilters || userPreferencesDefaultFilters;
  const defaultFilters = hasNewPins
    ? userPreferencesDefaultFilters
    : sections?.defaultFilters && hasUserPreferencesPinnedFilters
    ? [...sections?.defaultFilters, ...['folder', 'clause'].map(toFilter)]
    : sections?.defaultFilters;

  const [
    getQuickSearchRecords,
    getQuickSearchRecordsResponse,
  ] = api.endpoints.getQuickSearchRecords.useLazyQuery();
  const {
    data: quickSearchRecordsResponse,
    isLoading: isFetchingQuickSearchRecords,
  } = getQuickSearchRecordsResponse;

  const [
    quickSearchDocs,
    quickSearchDocumentsResponse,
  ] = api.endpoints.quickSearchDocuments.useMutation();
  const {
    data: quickSearchDocumentsResult,
    isError: isRunningQuickSearchError,
    isLoading: isSearchingDocs,
  } = quickSearchDocumentsResponse;

  const totalCount = quickSearchDocumentsResult?.documents?.count || 0;

  const { data: getFiltersResponse } = api.endpoints.getFilterSections.useQuery(
    undefined,
  );
  const fields = getFiltersResponse?.fields;

  useEffect(() => {
    if (!booleanQuery) {
      getQuickSearchRecords(undefined);
    }
  }, [booleanQuery]);

  useEffect(() => {
    if (hasUserPreferencesPinnedFilters) {
      getPinnedFilters('SEARCH');
    }
  }, [hasUserPreferencesPinnedFilters]);

  const resetSearchQueries = () => {
    dispatch(search.actions.reset());
    dispatch(searchV3.actions.reset());
  };

  const runQuickSearch = useCallback(
    debounce((value) => {
      trackSegment('quickSearchSearchKeywords', {
        name: value,
      });
      quickSearchDocs({
        query: { booleanQuery: value },
        size: QUICK_SEARCH_DOCUMENT_COUNT,
      });
    }, SEARCH_DEBOUNCE_MS),
    [quickSearchDocs],
  );

  const handleQuickSearch = (value: string) => {
    setBooleanQuery(value);
    if (!validationError) {
      runQuickSearch(value);
    }
  };

  const handleSearchBarSubmit = () => {
    trackSegment('quickSearchSelectAdvancedSearch', {
      name: totalCount ? 'With Search Results' : 'No Search Results',
    });
    resetSearchQueries();
    onSearchSubmit(booleanQuery);
    hideModal();
  };

  const handleAdvancedSearchLinkClick = () => {
    hideModal();
    dispatch(search.actions.setPage(1));
    navigate('/search');
  };

  const handleSearchWithQuery = (searchRecord: Option) => {
    trackSegment('quickSearchSelectRecentSearch');
    dispatch(search.actions.clearUnsupportedQuery());
    const searchQuery = searchRecord?.value as PilotSearchQuery;
    const isComplexSearch = checkSearchQueryHasUnsupportedEntity(searchQuery);
    dispatch(search.actions.clearBooleanQuery());
    dispatch(search.actions.clearQuery());
    dispatch(search.actions.clearUnsupportedQuery());
    dispatch(search.actions.setFilters([]));

    if (isComplexSearch) {
      dispatch(search.actions.setUnsupportedQuery(searchQuery));
    } else {
      const booleanSearchItem = searchQuery.find(
        (q) => q.entity === QueryEntityType.BoolTextSearch,
      ) as BooleanTextSearchEntity;
      const bq = booleanSearchItem ? booleanSearchItem.query : '';
      const supportedFieldEntities = searchQuery.filter((f) =>
        testIsSupportedFilterType(f),
      );
      const filters = toFilters(supportedFieldEntities) as FiltersType;

      const filterSet = [...defaultFilters!, ...filters] as FiltersType;

      dispatch(
        search.actions.setQuery({
          booleanQuery: bq,
          filters: filterSet.filter(isActiveFilter),
          fields,
        }),
      );
      dispatch(search.actions.setBooleanQuery(bq));
      dispatch(search.actions.setFilters(filters));
    }
    handleAdvancedSearchLinkClick();
  };

  const onRecentViewedDocClick = (docOption: Option) => {
    trackSegment('quickSearchSelectRecentDocument', {
      name: docOption.value,
    });
    handleDocumentClick(docOption);
  };

  const onQuickSearchDocClick = (docOption: Option) => {
    trackSegment('quickSearchSelectResult', {
      name: docOption.value,
    });
    handleDocumentClick(docOption);
  };

  const handleDocumentClick = (docOption: Option) => {
    hideModal();
    navigate(`/document/${docOption.value}`);
  };

  const renderRecentRecords = () => {
    if (isSearchingDocs || booleanQuery) {
      return <></>;
    }
    return (
      <>
        {renderRecentlyViewedDocs()}
        <Layout direction="column" py={4}>
          <Divider />
        </Layout>
        <>{!hasSearchV3 && renderRecentSearches()}</>
      </>
    );
  };

  const renderDocumentPath = (path: Folder[]) =>
    path.map((f) => f.name).join(' / ');

  const renderRecentlyViewedDocs = () => {
    const recentlyViewedDocs =
      quickSearchRecordsResponse?.recent_document_handlers;

    if (recentlyViewedDocs) {
      const options = recentlyViewedDocs.map((item) => {
        return {
          label: item.document_name,
          value: item.document_handler_id,
          leftIcon: getFileIconNameByFileType(item.file_type),
          description: renderDocumentPath(item.path),
          descriptionTruncateMode: 'center' as 'center',
          pathname: `/document/${item.document_handler_id}`,
        };
      });

      return (
        <>
          <Layout direction="column" mb={4}>
            <Text color="text.secondary" variant="tiny-bold">
              RECENTLY VIEWED DOCUMENTS
            </Text>
          </Layout>
          {options.length ? (
            <Menu
              enableMenuBorder={false}
              name="Recently viewed documents"
              options={options}
              trigger={null}
              onSelectOption={onRecentViewedDocClick}
            />
          ) : (
            <Text>No recently viewed documents.</Text>
          )}
        </>
      );
    }
  };

  const renderRecentSearches = () => {
    const recentSearches = quickSearchRecordsResponse?.recent_searches;
    if (recentSearches) {
      const options = recentSearches.map((record) => {
        const booleanSearchRecord = record.query.find(
          (item) => item.entity === QueryEntityType.BoolTextSearch,
        ) as BooleanTextSearchEntity;
        const tags = generateTextStringForFilterTags(record as RecentSearches);

        return {
          label: booleanSearchRecord?.query || '',
          value: record?.query as PilotSearchQuery,
          leftIcon: 'expiring' as const,
          tags,
        };
      });
      return (
        <>
          <Box mb={4}>
            <Text color="text.secondary" variant="tiny-bold">
              RECENT ADVANCED SEARCHES
            </Text>
          </Box>
          {options.length ? (
            <Menu
              enableMenuBorder={false}
              name="Recent advanced searches"
              options={options}
              trigger={null}
              onSelectOption={handleSearchWithQuery}
            />
          ) : (
            <Text>No recent searches.</Text>
          )}
        </>
      );
    }
  };

  const renderMessage = (title: string, content: JSX.Element) => {
    return (
      <Layout
        direction="column"
        alignSelf="center"
        align="center"
        w="250px"
        mt={8}
      >
        <Box>
          <Text variant="subtitle">{title}</Text>
        </Box>
        {content}
      </Layout>
    );
  };

  const renderQuickSearchDocuments = () => {
    const docs = quickSearchDocumentsResult?.documents?.results;
    const options = docs?.map((item) => {
      return {
        label: item.document_name,
        value: item.document_id,
        leftIcon: getFileIconNameByFileType(item.file_type),
        description: renderDocumentPath(item.path),
        descriptionTruncateMode: 'center' as 'center',
        pathname: `/document/${item.document_id}`,
      };
    });

    const onClickNoResultsGoToAdvancedSearch = () => {
      trackSegment('quickSearchSelectAdvancedSearch', {
        name: 'No Search Results',
      });
      handleAdvancedSearchLinkClick();
    };

    if (!booleanQuery || (isSearchingDocs && booleanQuery)) {
      return <></>;
    } else if (
      booleanQuery &&
      !validationError &&
      options &&
      options.length === 0
    ) {
      const message = (
        <Text>
          Try searching for something else or go to {''}
          <Link onClick={onClickNoResultsGoToAdvancedSearch}>Search</Link> to
          use filters
        </Text>
      );
      return renderMessage('No results', message);
    } else if (options?.length) {
      return (
        <Menu
          enableMenuBorder={false}
          name="Search results"
          options={options}
          search={booleanQuery}
          trigger={null}
          onSelectOption={onQuickSearchDocClick}
        />
      );
    } else if (isRunningQuickSearchError && !validationError) {
      const message = (
        <Text>while searching for documents. Please try again.</Text>
      );
      return renderMessage('An error occurred', message);
    } else {
      return <></>;
    }
  };

  const onOperatorsClick = () => {
    trackSegment('quickSearchSelectOperators');
    setIsSyntaxPopupOpen(true);
  };

  const renderValidationErrorMessage = () => {
    if (validationError) {
      const message = (
        <Text>
          <Link onClick={() => setIsSyntaxPopupOpen(true)}>Search</Link> cheat
          sheet may help build complex searches
        </Text>
      );
      return renderMessage('Unsupported search query', message);
    } else {
      return null;
    }
  };

  // TODO: design to recomend formal usage with eds.Header patterns
  const pageHeader = (
    <>
      <Layout
        borderBottom="border.divider"
        direction="column"
        flex="none"
        px={6}
        pt={4}
        spacing={4}
      >
        <Layout justify="space-between" spacing={6}>
          <Button
            variant="action"
            text="Operators"
            onClick={onOperatorsClick}
          />
          {totalCount && booleanQuery ? (
            <Link onClick={handleSearchBarSubmit}>
              See all {totalCount} matching {pluralize('document', totalCount)}{' '}
              in Search
            </Link>
          ) : (
            <Link
              onClick={() => {
                trackSegment('quickSearchSelectAdvancedSearch', {
                  name: booleanQuery ? 'No Search Results' : 'No Search Run',
                });
                resetSearchQueries();
                handleAdvancedSearchLinkClick();
              }}
            >
              Search
            </Link>
          )}
        </Layout>
        <BaseBooleanSearchBar
          includeOperatorButton={false}
          isDisabled={false}
          isSubmitting={false}
          value={booleanQuery}
          onChange={handleQuickSearch}
          onSubmit={handleSearchBarSubmit}
          onValidationError={setValidationError}
        />
      </Layout>
    </>
  );

  return (
    <PageOverlay
      disableHideOnEscape={false}
      header={pageHeader}
      isStretched
      isVisible={isVisible}
      width="l"
      onHide={hideModal}
    >
      <ContentContainer
        loadingContent={{ isLoading: isFetchingQuickSearchRecords }}
      >
        {renderRecentRecords()}
      </ContentContainer>
      <ContentContainer loadingContent={{ isLoading: isSearchingDocs }}>
        {renderQuickSearchDocuments()}
      </ContentContainer>
      {renderValidationErrorMessage()}
      {isSyntaxPopupOpen && (
        <EcBooleanTextSearchPopup
          handleOnCloseClick={() => setIsSyntaxPopupOpen(false)}
        />
      )}
    </PageOverlay>
  );
};

export default QuickSearchModal;
