import '../../eds/src/assets/fonts/IBMPlexSans/index.css';
import '../../eds/src/assets/fonts/OatmealPro/index.css';

const fonts = {
  body: 'IBMPlexSans, Helvetica, Arial',
  heading: 'OatmealPro',
  monospace: 'monospace',
};

const fontWeights = {
  normal: 'normal',
  medium: '500',
  bold: '600',
  bolder: '700',
  black: '800',
};

export const tokens = {
  title: {
    // 2xl-spaced-bold
    fontFamily: fonts.heading,
    fontSize: '30px',
    fontWeight: fontWeights.bolder,
    letterSpacing: '-0.2px',
    lineHeight: '38px',
  },
  subtitle: {
    // m-spaced-bold
    fontFamily: fonts.heading,
    fontSize: '16px',
    fontWeight: fontWeights.bold,
    letterSpacing: 'normal',
    lineHeight: '28px',
  },
  body1: {
    // xs-dense
    fontFamily: fonts.body,
    fontSize: '14px',
    letterSpacing: 'normal',
    lineHeight: '20px',
  },
  body2: {
    // xs-dense-bold
    fontFamily: fonts.body,
    fontSize: '14px',
    fontWeight: fontWeights.medium,
    letterSpacing: '0.2px',
    lineHeight: '20px',
  },
  tiny: {
    // 2xs-dense
    fontFamily: fonts.body,
    fontSize: '12px',
    letterSpacing: 'normal',
    lineHeight: '16px',
  },
};

// TODO 3781: deprecate old variants
// Mapping based on https://www.figma.com/file/FIGkLr0nm8KOCefQMCAOsS/Typography-Sandbox?node-id=175%3A5729
const variants = {
  ...tokens,
  '2xl-spaced-bold': tokens.title,
  'xl-spaced-bold': tokens.subtitle,
  'm-spaced': tokens.body1,
  'm-spaced-bold': tokens.subtitle,
  's-spaced': tokens.body1,
  's-spaced-bold': tokens.body2,
  'xs-spaced': tokens.body1,
  'xl-dense-black': tokens.subtitle,
  'xl-dense-bold': tokens.subtitle,
  'l-dense-bold': tokens.subtitle,
  'm-dense-bold': tokens.body2,
  's-dense': tokens.body1,
  's-dense-bold': tokens.body2,
  'xs-dense': tokens.body1,
  'xs-dense-medium': tokens.body1,
  'xs-dense-bold': tokens.body2,
  'xs-dense-caps': tokens.body2,
  'xs-dense-bold-caps': tokens.body2,
  '2xs-dense': tokens.tiny,
  '2xs-dense-bold': tokens.tiny,
  '2xs-dense-caps': tokens.tiny,
};

export default {
  fonts,
  fontWeights,
  variants,
};
