import { createSlice } from '@reduxjs/toolkit';
import { pick } from 'lodash';

import { types } from '~/eds';
import { AddToGroupStepType, BulkActionType } from '~/enums';
import {
  Field,
  FieldId,
  Filter,
  Filters,
  getOperator,
  testIsActiveFilter,
} from '~/evifields';
import { getPersistedState, ReduxPath } from '~/redux/persist';
import {
  ColumnSortOrder,
  DropdownFieldValue,
  FlattenFolderTree,
} from '~/types';
import { DEFAULT_TABLE_SORT_COLUMN_ORDER } from '~/utils/table';

import {
  DocumentSearchResultItem,
  PilotSearchQuery,
  TableViewSet,
} from '../api/methods';

type ModalContext = types.TableState & { bulkAction?: BulkActionType };
type Modal = { modalType: string; context: ModalContext };

type ColumnWidths = {
  [key: string]: number;
};

export type SearchSlice = {
  booleanQuery: string;
  page: number;
  pageSize: number;
  sortBy: ColumnSortOrder;
  modal?: Modal;
  fieldValues: {
    [fieldId: string]: DropdownFieldValue[];
  };
  filters: Filters;
  defaultFilters: Filters;
  filtersAreDirty: boolean;
  folderTree: FlattenFolderTree[];
  clause: string[];
  columnOrder: string[];
  columnWidths: ColumnWidths;
  query?: {
    booleanQuery: string;
    filters?: Filter[];
    fields?: Record<FieldId, Field>;
  };
  queryFields: Field[];
  searchResultsCache: {
    [key: number]: DocumentSearchResultItem;
  };
  searchResultViewSet: TableViewSet;
  unsupportedSavedSearchQuery?: PilotSearchQuery;
  currentTableView: number | string | undefined;
  currentAddToGroupStep: AddToGroupStepType;
  selectedDocument: number | undefined;
  selectedGroup: number | undefined;
  pinnedFieldIds?: FieldId[];
};

export const name = 'search_v2';

const keysToPersist: (keyof SearchSlice)[] = ['columnWidths'];

type KeyMapToPersist = Partial<Record<keyof SearchSlice, ReduxPath>>;
const keyMapToPersist: KeyMapToPersist = keysToPersist.reduce<KeyMapToPersist>(
  (prev, curr) => {
    prev[curr] = [name, curr];
    return prev;
  },
  {},
);

export const pathsToPersist = Object.values(keyMapToPersist);

const persistedState = {
  columnWidths: getPersistedState(keyMapToPersist.columnWidths!, {}),
};

export const initialState: SearchSlice = {
  booleanQuery: '',
  page: 1,
  pageSize: 20,
  sortBy: DEFAULT_TABLE_SORT_COLUMN_ORDER,
  modal: undefined,
  fieldValues: {},
  filters: [],
  defaultFilters: [],
  filtersAreDirty: false,
  folderTree: [],
  clause: [],
  columnOrder: [],
  query: undefined,
  queryFields: [],
  searchResultsCache: {},
  searchResultViewSet: [],
  currentTableView: undefined,
  currentAddToGroupStep: AddToGroupStepType.GROUP,
  selectedDocument: undefined,
  selectedGroup: undefined,
  unsupportedSavedSearchQuery: undefined,
  ...persistedState,
};

export const isActiveFilter = (filter: Filter): boolean => {
  const op = getOperator(filter.operatorId);
  return !!op && testIsActiveFilter(filter, op);
};

const isValid = (filters: Filters): boolean => {
  return filters.every(isActiveFilter);
};

export interface SetQueryPayload {
  booleanQuery: string;
  filters?: Filters;
  fields?: Record<FieldId, Field>;
}

const setQueryReducer = (
  state: SearchSlice,
  action: { payload: SetQueryPayload },
) => {
  const { booleanQuery, filters, fields } = action.payload;
  if (!filters || isValid(filters)) {
    state.query = { booleanQuery, filters, fields };
    state.page = 1;
  }
};

const clearFilterValue = (filter: Filter) => {
  filter.operatorId = null;
  filter.values = [];
  return filter;
};

export default createSlice({
  name,
  initialState,
  reducers: {
    setBooleanQuery: (state, action) => {
      state.booleanQuery = action.payload;
    },
    clearBooleanQuery: (state) => {
      state.booleanQuery = '';
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setModal: (
      state,
      action: {
        payload: Modal | undefined;
      },
    ) => {
      state.modal = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.filtersAreDirty = true;
    },
    setFieldValues: (
      state,
      {
        payload,
      }: { payload: { fieldId: string; values: DropdownFieldValue[] } },
    ) => {
      state.fieldValues[payload.fieldId] = payload.values;
    },
    setClauseSuggestions: (
      state,
      { payload }: { payload: { fieldId: string; values: string[] } },
    ) => {
      state.clause = payload.values;
    },
    setFolderTree: (
      state,
      {
        payload,
      }: { payload: { fieldId: string; values: FlattenFolderTree[] } },
    ) => {
      state.folderTree = payload.values;
    },
    clearFilterValues: (state) => {
      state.filters = state.filters.map(clearFilterValue);
    },
    setSearchResultViewSet: (state, action) => {
      state.searchResultViewSet = action.payload;
    },
    setQuery: setQueryReducer,
    clearQuery: (state) => {
      state.query = undefined;
    },
    setUnsupportedQuery: (state, action) => {
      state.unsupportedSavedSearchQuery = action.payload;
    },
    clearUnsupportedQuery: (state) => {
      state.unsupportedSavedSearchQuery = undefined;
    },
    setColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
    patchColumnWidths: (state, action: { payload: ColumnWidths }) => {
      state.columnWidths = {
        ...state.columnWidths,
        ...action.payload,
      };
    },
    setQueryFields: (state, action) => {
      state.queryFields = action.payload;
    },
    setCurrentTableView: (state, action) => {
      state.currentTableView = action.payload;
    },
    setCurrentAddToGroupStep: (
      state,
      action: { payload: AddToGroupStepType },
    ) => {
      state.currentAddToGroupStep = action.payload;
    },
    setSelectedDocument: (state, action) => {
      state.selectedDocument = action.payload;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    patchSearchResultsCache: (
      state,
      { payload = [] }: { payload: DocumentSearchResultItem[] },
    ) => {
      payload.forEach((item) => (state.searchResultsCache[item.id] = item));
    },
    setDefaultFilters: (state, { payload }) => {
      state.defaultFilters = payload;
    },
    setPinnedFieldIds: (state, { payload }) => {
      state.pinnedFieldIds = payload;
    },
    reset: (state) => {
      return {
        ...initialState,
        ...pick(state, ['defaultFilters', 'searchResultsCache']),
        filters: state.defaultFilters,
      };
    },
  },
});
