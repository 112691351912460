import { useMemo } from 'react';

import { Highlight } from './types';

export const useHighlightsMap = (highlights: Highlight[]) => {
  const highlightsMap = useMemo(
    () =>
      Object.fromEntries(
        highlights.map((highlight) => [highlight.id, highlight]),
      ),
    [highlights],
  );

  return highlightsMap;
};
