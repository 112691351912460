import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { black5 } from '../../../assets/shared-styles/general';
import { getUnassignedMembersInDepartment } from '../../Admin/DepartmentsPage/DepartmentsPage.data';
import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import EcMultipleSelectValue from '../../Shared/EcMultipleSelectValue';
import EcMultiSelect from '../../Shared/EcMultiSelect';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import SubArrowIcon from '../../Shared/Icons/SubArrowIcon';
import styles from './DepartmentMembersModal.styles';

class DepartmentMembersModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnSaveClick = this.handleOnSaveClick.bind(this);

    this.state = {
      loading: false,
      editData: {
        members: [],
      },
    };
  }

  componentDidMount() {
    const { clientId, departmentId } = this.props;

    this.setState({ loading: true });
    getUnassignedMembersInDepartment(clientId, departmentId)
      .then((data) => {
        if (data) {
          const members = data.map((member) => {
            return {
              label: `${member.full_name}`,
              value: `${member.full_name}`,
              id: member.id,
            };
          });
          this.setState({ loading: false, members });
        }
      })
      .catch(() => this.setState({ loading: false, errorLoading: true }));
  }

  handleOnSaveClick() {
    const { handleActionClick, hideModal } = this.props;
    const {
      editData: { members },
    } = this.state;
    const memberIds = members.length ? members.map((member) => member.id) : [];
    if (memberIds) {
      handleActionClick(memberIds);
    }
    hideModal();
  }

  renderMultiSelectMenu() {
    const defaultItems = this.state.members;
    const presentValue = this.state.editData['members'];

    return (
      <div>
        <EcMultiSelect
          value={presentValue}
          options={defaultItems}
          onChange={(newItems) => {
            const newState = {};
            newState['members'] = newItems;
            this.setState({
              editData: { ...this.state.editData, ...newState },
            });
          }}
          noOptionsMessage="No users found"
          placeholder="Enter users..."
          openMenuOnClick={true}
        />
        {this.renderItemValues()}
      </div>
    );
  }

  renderItemValues() {
    const { classes } = this.props;
    const presentValues = this.state.editData['members'];

    return (
      <div
        className={classes.multipleValuesContainer}
        data-testid="department-add-users-modal"
      >
        <SubArrowIcon color={black5} />
        <div className={classes.multipleValues}>
          {presentValues.length ? (
            presentValues.map((value) => (
              <EcMultipleSelectValue
                label={value.label}
                key={value.value}
                closeHandler={() => {
                  const newState = {};
                  const newMultiState = [...this.state.editData['members']];
                  newState['members'] = newMultiState.filter(
                    (multiValue) => multiValue.value !== value.value,
                  );
                  this.setState({
                    editData: { ...this.state.editData, ...newState },
                  });
                }}
              />
            ))
          ) : (
            <span className={classes.multipleValuesEmpty}>
              No values selected...
            </span>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { classes, title, hideModal } = this.props;
    const {
      loading,
      editData: { members },
      errorLoading,
    } = this.state;

    if (loading && !errorLoading) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    } else if (!loading && errorLoading) {
      return (
        <div className={classes.errorMessage}>
          An error occurred while loading the users.
        </div>
      );
    }
    return (
      <EcModalCard
        title={title}
        content={this.renderMultiSelectMenu()}
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              yellow
              tooltip="Add Users"
              disabled={!members.length}
              text="Add Users"
              onClick={this.handleOnSaveClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

DepartmentMembersModal.propTypes = {
  title: PropTypes.string.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  hideModal: PropTypes.func,
};

export default injectSheet(styles)(DepartmentMembersModal);
