import { sortByStringValue } from './array';

const defaultKeys = {
  id: 'id',
  editable: 'editable',
  children: 'children',
  name: 'name',
  sync_paired: 'sync_paired',
};

export class TreeNode {
  constructor(treeNode, sorter, keys = defaultKeys) {
    this.keys = { ...defaultKeys, ...keys };
    this.treeNode = treeNode;
    this.isVisible = false;
    this._isExpanded = false;

    const sortByName = sortByStringValue(keys.name);

    sorter = sorter || sortByName;

    this.treeNode.children.sort(sorter);

    this.children = [];
    for (let i = 0; i < this.treeNode.children.length; i++) {
      const node = this.treeNode.children[i];
      this.children[i] = new TreeNode(node, sorter, keys);
      this.children[i].parent = this;
    }

    // link siblings for easy travesal
    for (let i = 0; i < this.children.length; i++) {
      const prev = this.children[i - 1];
      const curr = this.children[i];
      const next = this.children[i + 1];
      curr.prev = prev;
      curr.next = next;
    }

    if (treeNode.isVirtual) {
      this.isExpanded = true;
    }
  }

  static fromArray = (nodes, sorter, keys = defaultKeys) => {
    const virtualRoot = { children: nodes, isVirtual: true };
    const parent = new TreeNode(virtualRoot, sorter, keys);

    return parent;
  };

  get treeNodeChildren() {
    return this.treeNode[this.keys.children];
  }

  get editable() {
    return this.treeNode[this.keys.editable];
  }

  get id() {
    return this.treeNode[this.keys.id];
  }

  get name() {
    return this.treeNode[this.keys.name];
  }

  updateChildrenVisibility = (isVisible) => {
    for (const child of this.children) {
      child.isVisible = isVisible;

      if (
        child.isExpanded || //make grand children visible if child is expanded
        !isVisible
      ) {
        //or make all decendants NOT visible if child is collapsed
        child.updateChildrenVisibility(isVisible);
      }
    }
  };

  get isExpanded() {
    return this._isExpanded;
  }

  set isExpanded(val) {
    this._isExpanded = val;

    this.updateChildrenVisibility(val);
  }

  getLastVisibleNode = () => {
    let node = this;

    const len = node.children.length;

    if (!node.isExpanded || !len) {
      return node;
    }

    // must have at least one expanded child, recurse
    node = node.children[len - 1].getLastVisibleNode();

    return node;
  };

  getPrevVisibleNode = () => {
    let prev = this.prev?.getLastVisibleNode() || this.parent;

    return prev;
  };

  getNextVisibleNode = () => {
    let parent = this.parent;
    let next;

    if (this.isExpanded) {
      next = this.children[0] || this.next;
    } else {
      next = this.next;
    }

    while (parent && !next) {
      if (!parent.isVisible || !parent.next) {
        parent = parent.parent;
        continue;
      }

      next = parent.next;
    }

    return next;
  };
}
