import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout, Text, Tooltip } from '~/ui';
import { getUserInitials, getUserName } from '~/utils/user';

function Avatar({ showTooltip, size = 'xl', user }) {
  const { avatar } = user;
  const userName = getUserName(user);
  const userInitials = getUserInitials(user);

  return (
    <Tooltip
      content={showTooltip && userName ? userName : null}
      placement="bottom"
    >
      <FlexLayout
        alignItems="center"
        bg="gray-500"
        flexShrink="0"
        justifyContent="center"
        sx={{
          borderRadius: size === 's' ? 's' : 'm',
          cursor: 'pointer',
          height: (theme) => theme.sizes[`icon-${size}`],
          width: (theme) => theme.sizes[`icon-${size}`],
        }}
      >
        {avatar ? (
          <Box
            alt={`${userName}'s avatar`}
            as="img"
            src={avatar}
            sx={{ height: '100%', width: '100%' }}
          />
        ) : (
          // TODO add typography with fontSize 8px for Avatar component
          <Text
            color="black-alpha-75"
            variant="2xs-dense-caps"
            sx={{ fontSize: size === 's' ? '8px' : null }}
          >
            {userInitials}
          </Text>
        )}
      </FlexLayout>
    </Tooltip>
  );
}

const userPropType = PT.shape({
  /** Avatar source */
  avatar: PT.string,
  /** First name */
  firstName: PT.string,
  /** Last name */
  lastName: PT.string,
});

Avatar.propTypes = {
  /** Indicates if tooltip should be shown */
  showTooltip: PT.bool,
  /** Avatar size */
  size: PT.oneOf(['xs', 's', 'm', 'l', 'xl']),
  /** User object: { avatarSrc, firstName, lastName }  */
  user: userPropType,
};

export default Avatar;
