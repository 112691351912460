import PropTypes from 'prop-types';
import React from 'react';

const RejectIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none">
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C5.576 2 2 5.576 2 10C2 14.424 5.576 18 10 18C14.424 18 18 14.424 18 10C18 5.576 14.424 2 10 2ZM13.44 13.44C13.366 13.5142 13.2781 13.573 13.1813 13.6131C13.0845 13.6533 12.9808 13.674 12.876 13.674C12.7712 13.674 12.6675 13.6533 12.5707 13.6131C12.4739 13.573 12.386 13.5142 12.312 13.44L10 11.128L7.688 13.44C7.53842 13.5896 7.33554 13.6736 7.124 13.6736C6.91246 13.6736 6.70958 13.5896 6.56 13.44C6.41042 13.2904 6.32638 13.0875 6.32638 12.876C6.32638 12.7713 6.34701 12.6675 6.3871 12.5708C6.42718 12.474 6.48593 12.3861 6.56 12.312L8.872 10L6.56 7.688C6.41042 7.53842 6.32638 7.33554 6.32638 7.124C6.32638 6.91246 6.41042 6.70958 6.56 6.56C6.70958 6.41042 6.91246 6.32638 7.124 6.32638C7.33554 6.32638 7.53842 6.41042 7.688 6.56L10 8.872L12.312 6.56C12.3861 6.48593 12.474 6.42718 12.5708 6.3871C12.6675 6.34701 12.7713 6.32638 12.876 6.32638C12.9807 6.32638 13.0845 6.34701 13.1812 6.3871C13.278 6.42718 13.3659 6.48593 13.44 6.56C13.5141 6.63407 13.5728 6.72199 13.6129 6.81877C13.653 6.91554 13.6736 7.01926 13.6736 7.124C13.6736 7.22874 13.653 7.33246 13.6129 7.42923C13.5728 7.52601 13.5141 7.61393 13.44 7.688L11.128 10L13.44 12.312C13.744 12.616 13.744 13.128 13.44 13.44Z"
        fill={props.color}
      />
    </svg>
  );
};

RejectIcon.defaultProps = {
  size: '20',
  color: '#000',
};

RejectIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default RejectIcon;
