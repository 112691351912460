import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Button, Markdown, Text } from '~/eds';
import { ManageSyncPairStageType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlexLayout, PageLayout, styles } from '~/ui';

import {
  testIsAmzS3SyncPair,
  testIsAribaSyncPair,
  testIsValidSyncPair,
} from '../util';
import AribaHeader from './AribaHeader';
import AmazonS3Auth from './Content/AmazonS3Auth';
import APIDetailsSetup from './Content/AribaDetailsSetup';
import AribaSetup from './Content/AribaGlobalSetup';
import AribaMultiAPISetup from './Content/AribaMultiAPISetup';
import Authentication from './Content/Authentication';
import FieldMappingSetup from './Content/FieldMappingSetup';
import FileConfigSetup from './Content/FileConfigSetup';
import FolderAriba from './Content/FolderAriba';
import Folders from './Content/Folders';
import ProcurementProvider from './Content/ProcurementProvider';
import Provider from './Content/Provider';
import Summary from './Content/Summary';
import SummaryAriba from './Content/SummaryAriba';
import Header from './Header';

function ManageSyncPair(props) {
  const { activeSyncPair, manageSyncPairStage } = props;
  const [enableGuide, setEnableGuide] = useState(true);
  const [showInstruction, setShowInstruction] = useState(false);

  if (manageSyncPairStage === ManageSyncPairStageType.None) {
    return null;
  }

  const isValidSyncPair = testIsValidSyncPair(activeSyncPair);
  const isAmzS3SyncPair = testIsAmzS3SyncPair(activeSyncPair);
  var isAribaSyncPair = testIsAribaSyncPair(activeSyncPair);

  const ARIBA_DESCRIPTION_TEXT = `Specify the credentials for [External Approval API for Sourcing and Supplier Management.](https://api.sap.com/api/sourcing_approval_v2/overview)
    If you would like to learn more on how to retrieve the OAuth Client ID and Client Secret, [click here](https://help.sap.com/docs/ariba-apis/help-for-sap-ariba-developer-portal/generating-oauth-secret-and-base64-encoded-client-id-and-secret).`;

  const ARIBA_DISCOVERY_TEXT = `Specify the credentials for [Operational Reporting for Sourcing API.](https://api.sap.com/api/sourcing_reporting_details/overview)
    If you would like to learn more on how to retrieve the OAuth Client ID and Client Secret, [click here](https://help.sap.com/docs/ariba-apis/help-for-sap-ariba-developer-portal/generating-oauth-secret-and-base64-encoded-client-id-and-secret).`;

  const ARIBA_MULTI_DESCRIPTION_TEXT = `Specify the credentials for External Approval API, Operational Reporting API - Asynchronous, and Strategic Contract Workspace API.`;

  const getContent = (manageSyncPairStage) => {
    switch (manageSyncPairStage) {
      case ManageSyncPairStageType.Authentication:
        return isAmzS3SyncPair
          ? {
              title: showInstruction
                ? null
                : enableGuide
                ? 'Syncing AWS S3'
                : 'Provide AWS Role ARN details',
              description: showInstruction
                ? null
                : 'In order to synchronize documents and folders on your S3 bucket with Evisort, you first need to configure it correctly for use with Evisort.',
              content: () => (
                <AmazonS3Auth
                  enableGuide={enableGuide}
                  showInstruction={showInstruction}
                  onToggleEnableGuide={setEnableGuide}
                  onToggleShowInstruction={setShowInstruction}
                />
              ),
            }
          : {
              id: OnboardingIdType.LogIntoProvider,
              title: 'Provide authentication details',
              description:
                'Specify the account details that will be used to access your files and folders, in order to keep them synchronized with Evisort. This account should have full access to any folder you may wish to synchronize with Evisort.',
              content: Authentication,
            };
      case ManageSyncPairStageType.Folders:
        return {
          title: 'Pick the folders and file types you wish to sync',
          content: Folders,
        };
      case ManageSyncPairStageType.Provider:
        return {
          title: `${isValidSyncPair ? 'Change' : 'Select'} storage provider`,
          description: isValidSyncPair
            ? 'This will reset authentication, folders, and filetypes.'
            : null,
          content: Provider,
        };
      case ManageSyncPairStageType.ProviderProcurements:
        isAribaSyncPair = true;
        return {
          title: 'Select procurement provider',
          content: ProcurementProvider,
        };
      case ManageSyncPairStageType.Realm:
        return {
          title: 'Provide authentication details',
          description: 'Specify the account details from your Ariba account.',
          content: AribaSetup,
        };
      case ManageSyncPairStageType.Extract:
        return {
          title: 'Provide authentication details',
          description: ARIBA_DESCRIPTION_TEXT,
          content: APIDetailsSetup,
        };
      case ManageSyncPairStageType.Discovery:
        return {
          title: 'Provide authentication details',
          description: ARIBA_DISCOVERY_TEXT,
          content: APIDetailsSetup,
        };
      case ManageSyncPairStageType.MultiAPIs:
        return {
          title: 'Provide authentication details',
          description: ARIBA_MULTI_DESCRIPTION_TEXT,
          content: AribaMultiAPISetup,
        };
      case ManageSyncPairStageType.FileConfig:
        return {
          title: 'Import or sync your files',
          description:
            'Choose either to do an one-time import of existing or continuously synchronization for data from Ariba.',
          content: FileConfigSetup,
        };
      case ManageSyncPairStageType.FolderAriba:
        return {
          title:
            'Select the folders and documents you wish to sync with Evisort',
          content: FolderAriba,
        };
      case ManageSyncPairStageType.SummaryAriba:
        return {
          title: 'Ready to create a new sync pair',
          description:
            'Once you confirm, the sync will begin. This might take some time depending on the number of documents that need to be synced.',
          content: SummaryAriba,
        };
      case ManageSyncPairStageType.Summary:
        return {
          title: 'Ready to create a new sync pair',
          description:
            'Once you confirm, the synchronization process will begin. This might take some time, depending on the number of documents that need to be synced.',
          content: Summary,
        };
      case ManageSyncPairStageType.FieldMapping:
        return {
          title: 'Map the fields when syncing between Evisort and Ariba',
          content: FieldMappingSetup,
        };
      default:
        return null;
    }
  };

  const { description, title, content: Content, id } = getContent(
    manageSyncPairStage,
  );

  const footer = showInstruction && (
    <PageLayout.Footer
      leftButton={
        <Button
          variant="secondary"
          text="Back"
          onClick={() => {
            setShowInstruction(false);
            setEnableGuide(true);
          }}
        />
      }
      actionButtons={[
        <Button
          text="I have my IAM Role ARN"
          variant="primary"
          onClick={() => {
            setShowInstruction(false);
            setEnableGuide(false);
          }}
        />,
      ]}
    />
  );

  return (
    <PageLayout
      header={
        isAribaSyncPair ? (
          <AribaHeader />
        ) : (
          <Header onToggleEnableGuide={setEnableGuide} />
        )
      }
      footer={footer}
    >
      <FlexLayout
        alignItems="center"
        flexDirection="column"
        py={8}
        space={6}
        sx={styles.layout.container}
      >
        <Text color="text.primary" fontSize="20px" fontWeight="700">
          {title}
        </Text>
        {description && (
          <Text textAlign="center" whiteSpace="pre-wrap">
            <Markdown text={description} />
          </Text>
        )}
        <FlexLayout id={id} justifyContent="center" sx={{ width: '100%' }}>
          <Content />
        </FlexLayout>
      </FlexLayout>
    </PageLayout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps)(ManageSyncPair);
