import React from 'react';

import { Checkbox, FormField } from '~/eds';
import {
  FormField as FormFieldType,
  Nullable,
  ToggleField as ToggleFieldType,
} from '~/types';

type Props = {
  formField: FormFieldType<ToggleFieldType>;
  onChange: (value: Nullable<boolean> | boolean[]) => void;
  onExplain?: (fieldId: string) => void;
};

export const ToggleField = ({ formField, onChange, onExplain }: Props) => {
  return (
    <FormField
      input={Checkbox}
      inputProps={{
        option: {
          label: formField.field.name,
          value: formField.field.value,
        },
      }}
      onExplain={onExplain ? () => onExplain(formField.fieldName) : undefined}
      label={formField.title}
      name={formField.fieldName}
      description={formField.field.description}
      value={formField.field.value}
      onChange={onChange}
    />
  );
};
