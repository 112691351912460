import { useEffect } from 'react';

import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';

export const AppTracingInitializer = () => {
  const hasTracing = useFlag(FlagType.Tracing);
  const currentUser = useCurrentUser();

  useEffect(() => {
    const initTracing = async () => {
      if (hasTracing && currentUser.id) {
        const { init } = await import('./tracing');
        init({
          userId: currentUser.id,
          clientId: currentUser.client,
        });
      }
    };
    initTracing();
  }, [hasTracing]);

  return null;
};
