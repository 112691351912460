import PropTypes from 'prop-types';
import React from 'react';

import { evisortBlue } from '../../../assets/shared-styles/general';

const AlertsIcon = (props) => {
  let color = props.blue ? evisortBlue : '#000';
  let opacity = props.opacity;

  if (props.isActive) {
    color = evisortBlue;
    opacity = '1';
  }

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h32v32H0z" />
        <path
          fill={color}
          opacity={opacity}
          d="M24 21.333v-6.666c0-4.094-2.187-7.52-6-8.427v-.907c0-1.106-.907-2-2.013-2a1.986 1.986 0 0 0-1.987 2v.907c-3.827.907-6 4.32-6 8.427v6.666l-1.733 1.72c-.84.84-.254 2.28.933 2.28h17.56c1.187 0 1.787-1.44.947-2.28L24 21.333zm-8.013 8c1.466 0 2.666-1.2 2.666-2.666H13.32a2.666 2.666 0 0 0 2.667 2.666zM9.027 6.307c.56-.507.573-1.374.04-1.907a1.333 1.333 0 0 0-1.854-.027 13.899 13.899 0 0 0-4.36 8.08A1.348 1.348 0 0 0 4.187 14c.64 0 1.2-.467 1.306-1.107.4-2.586 1.68-4.893 3.534-6.586zM24.8 4.373a1.349 1.349 0 0 0-1.867.027 1.31 1.31 0 0 0 .04 1.893c1.84 1.694 3.134 4 3.534 6.587.093.64.653 1.107 1.306 1.107.814 0 1.454-.734 1.32-1.547A13.984 13.984 0 0 0 24.8 4.373z"
        />
      </g>
    </svg>
  );
};

AlertsIcon.defaultProps = {
  size: '24',
  opacity: '.75',
};

AlertsIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

export default AlertsIcon;
