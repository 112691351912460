import { FormatNumberOptions } from '../types';
import { formatNumber } from './formatNumber';

/**
 * TODO: deprecate in favor of just formatNumber(number, { style: 'percent' }})
 * Formats a number based on Intl.NumberFormat.
 */
export const formatNumberPercentage = (
  number: number,
  options: FormatNumberOptions = {},
): string => {
  return formatNumber(number * 100, options) + '%';
};
