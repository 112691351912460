import React, { useEffect, useState } from 'react';

import { Divider, Footer, Layout, useToast } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { GenClauseResult } from '~/types';

import { Mode, PromptMvp as PromptState } from '../types';
import { Prompt } from './Prompt';
import { Result } from './Result';

interface Props {
  mode: Mode;
}

export const PromptResultPanel = ({ mode }: Props) => {
  const useDelphi = useFlag(FlagType.GenAiUsingDelphi);

  const { toast } = useToast();

  const [pageIndex, setPageIndex] = useState(1);
  const [prompt, setPrompt] = useState<PromptState>(defaultPrompt);
  const [resultTexts, setResultTexts] = useState<string[]>([]);

  const [
    getGenClauseResults,
    { isFetching: isFetchingResults, isError: isErrorResults },
  ] = api.endpoints.getGenClauseResults.useLazyQuery();

  const [
    getGenClauseResult,
    { isFetching: isFetchingResult, isError: isErrorResult },
  ] = api.endpoints.getGenClauseResult.useLazyQuery();

  const isRedlineMode = mode === 'redline';
  const disabled = isRedlineMode
    ? !(prompt.text && prompt.instructions)
    : !prompt.instructions;
  const disabledTooltip = isRedlineMode
    ? 'Please specify both clause text and instructions.'
    : 'Please specify instructions for creating a clause.';

  const isError = isErrorResults || isErrorResult;
  const isLoading = isFetchingResults || isFetchingResult;

  const resultText = resultTexts[pageIndex - 1];

  const handleReset = () => {
    setPageIndex(1);
    setPrompt(defaultPrompt);
    setResultTexts([]);
  };

  const handleUpdateResultText = (updatedResultText: string) => {
    const updatedResultTexts = resultTexts.map((text, index) =>
      index + 1 === pageIndex ? updatedResultText : text,
    );
    setResultTexts(updatedResultTexts);
  };

  useEffect(() => {
    if (isError) {
      toast({
        message: 'Service is temporarily unavailable. Please retry later.',
        status: 'warning',
      });
    }
  }, [isError]);

  // create footer actions
  const actions = [];
  if (resultText) {
    actions.push({
      text: 'Reset',
      onClick: handleReset,
    });
  }
  actions.push({
    disabled,
    isLoading,
    text: resultText ? 'Generate More' : 'Generate',
    tooltip: disabled ? disabledTooltip : undefined,
    level: 'primary' as const,
    onClick: async () => {
      if (resultText) {
        const {
          data: newResult,
          isError: isNewResultError,
        } = await getGenClauseResult({ ...prompt, useDelphi });
        if (!isNewResultError && newResult) {
          const updatedResultTexts = [...resultTexts, newResult.text];
          setResultTexts(updatedResultTexts);
          setPageIndex(updatedResultTexts.length);
        }
      } else {
        setResultTexts(
          toResultTexts(
            (await getGenClauseResults({ ...prompt, useDelphi }))?.data ?? [],
          ),
        );
      }
    },
  });

  return (
    <Layout direction="column" h="100%" justify="space-between">
      <Layout direction="column" h="100%" overflowY="auto" px={6} spacing={8}>
        <Prompt mode={mode} prompt={prompt} onUpdatePrompt={setPrompt} />
        {resultText && (
          <>
            <Divider />
            <Result
              mode={mode}
              pageIndex={pageIndex}
              text={prompt.text}
              resultText={resultText}
              totalCount={resultTexts.length}
              onUpdatePageIndex={setPageIndex}
              onUpdateResultText={handleUpdateResultText}
            />
          </>
        )}
      </Layout>
      <Footer actions={actions} />
    </Layout>
  );
};

const defaultPrompt = {
  instructions: '',
  text: '',
};

const toResultTexts = (results: GenClauseResult[]) =>
  results.map(({ text }) => text);
