export const hasInvalidMapping = (
  errors: { invalidFieldMappingIds: string[] },
  fieldId: string,
) => errors.invalidFieldMappingIds.includes(fieldId);

export const getErrorMessage = (
  errors: { invalidFieldMappingIds: string[] },
  fieldId: string,
  fieldMapping: { [key: string]: number },
) => {
  if (hasInvalidMapping(errors, fieldId)) {
    if (fieldMapping[fieldId] === 0) {
      return 'Field mapping not found. Assign it to an existing field in Settings.';
    }
    return 'Data Fields Mapping Error. The field type is incompatible with the mapped data in Settings.';
  }

  return undefined;
};
