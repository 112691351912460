import React from 'react';

import { HtmlEntityType } from '../../../enums';
import {
  DepartmentOption,
  Option,
  PilotId,
  SharedSelectProps,
} from '../../../types';
import { typedMemo } from '../../../utils';
import { Select } from '../Select';

interface Props<M> extends SharedSelectProps<PilotId, M> {
  /** Departments that will be available as options. */
  departments: DepartmentOption[];
  /** Enable select all */
  enableSelectAll?: boolean;
}

export const DepartmentSelect = typedMemo(
  <M extends boolean>({
    departments,
    value,
    onChange,
    onSearch,
    ...rest
  }: Props<M>) => {
    const options = departments.map(
      (dept: DepartmentOption): Option<PilotId> => {
        const deptPath = dept.path.join(` ${HtmlEntityType.RightArrow} `);
        return {
          value: dept.id,
          label: dept.name || '',
          leftIcon: 'department',
          description: `${deptPath} ${HtmlEntityType.RightArrow} ${dept.name}`,
        };
      },
    );

    return (
      <Select
        placeholder="Select department..."
        {...rest}
        options={options}
        value={value}
        onChange={onChange}
        onSearch={onSearch}
      />
    );
  },
);
