const styles = {
  documentsPage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  documentsDropzone: {
    height: '100%',
  },
  loadingContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  errorLoadingMessage: {
    marginBottom: '30px',
  },
  emptyPageWrapper: {
    display: 'flex',
    flexGrow: '1',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70%',
  },
  headerWrapper: {
    display: 'flex',
  },
  horizontalSeparator: {
    display: 'block',
    height: '24px',
  },
  documentsPaginationAndSize: {
    '& > ul': {
      display: 'inline-flex',
    },
  },
  documentsPageSize: {
    display: 'inline-block',
    float: 'right',
  },
};

export default styles;
