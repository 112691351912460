import React, { memo, useMemo } from 'react';

import { User } from '../../types';
import { formatDate, getUserInitials, getUserName } from '../../utils';
import { Image } from '../Image';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { UserAvatarOptions } from './types';

interface Props {
  user?: User;
  placeholder?: string;
  options?: UserAvatarOptions;
}

export const UserAvatar = memo(
  ({ options = {}, user, placeholder = 'Unknown User' }: Props) => {
    const { date, disableTooltip, size } = options;
    const isLarge = size === 'l';
    const avatarSize = isLarge ? 40 : 24;
    const textVariant = isLarge ? 'body-medium' : 'tiny';

    const tooltip = user
      ? `${getUserName(user)}${date ? '\n' + formatDate(date, 'full') : ''}`
      : placeholder;
    const isInactiveUser = !user || user.isDeleted;

    const avatar = useMemo(() => {
      if (user) {
        return Boolean(user.avatar) && !user.isDeleted ? (
          <Image
            width={avatarSize - 2}
            height={avatarSize - 2}
            borderRadius="50%"
            src={user.avatar}
            alt="User Avatar Picture"
          />
        ) : (
          <Text variant={textVariant}>{getUserInitials(user)}</Text>
        );
      } else {
        return <Text variant={textVariant}>?</Text>;
      }
    }, [user]);

    return (
      <Tooltip tooltip={disableTooltip ? null : tooltip}>
        <Layout
          align="center"
          bg={isInactiveUser ? 'background.quiet' : 'background.secondary'}
          styles={{ opacity: isInactiveUser ? 0.7 : 1 }}
          border="border"
          borderRadius="round"
          flex="none"
          h={avatarSize}
          maxW={avatarSize}
          justify="center"
          w={avatarSize}
        >
          {avatar}
        </Layout>
      </Tooltip>
    );
  },
);
