import React, { memo } from 'react';

import { Card, Grid, Link, Text } from '../../../components';
import type { Nav } from './types';

interface Props {
  navs: Nav[];
  columns?: number;
}

const spacing = 6;

export const NavCards = memo(({ columns, navs }: Props) => {
  return (
    <Grid columns={columns} columnSpacing={spacing} rowSpacing={spacing}>
      {navs.map(({ description, link, title }) => (
        <Link key={title} {...link}>
          <Card>
            <Text variant="subtitle">{title}</Text>
            {description && <Text preset="description">{description}</Text>}
          </Card>
        </Link>
      ))}
    </Grid>
  );
});
