import {
  black1,
  black2,
  black3,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  fieldTable: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    color: black1,
    textAlign: 'left',
    '& td': {
      padding: '12px 0',
      color: black2,
      fontSize: '15px',
      wordBreak: 'break-all',
    },
    '& tr': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
    },
  },
  fieldName: {
    paddingRight: '30px',
    display: 'flex',
    lineHeight: '22px',
    wordBreak: 'break-word',
    '& svg': {
      margin: '3px 6px 0',
    },
  },
  fieldTypeRule: {
    display: 'flex',
    lineHeight: '22px',
    '& svg': {
      margin: '3px 6px 0',
    },
  },
  smartTooltip: {
    marginLeft: '5px',
    padding: '0 7px',
    height: '22px',
    borderRadius: '2px',
    backgroundColor: black3,
    color: '#fff',
    fontSize: '11px',
    fontWeight: semibold,
    wordBreak: 'break-word',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: black1,
    },
  },
  ruleTooltip: {
    position: 'relative',
    top: '4px',
    borderRadius: '2px',
    color: '#fff',
    fontSize: '11px',
    fontWeight: semibold,
    wordBreak: 'break-word',
    cursor: 'pointer',
  },

  fieldRow: {
    padding: '17px 15px 8px 0',
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid #ccc',
    color: black3,
    fontSize: '14px',
    lineHeight: '23px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  fieldNameContainer: {
    width: '43%',
    display: 'flex',
  },
  fieldTypeContainer: {
    width: '23%',
    display: 'flex',
  },
  fieldDocTypeContainer: {
    width: '33%',
    display: 'flex',
  },
  fieldAppliesToContainer: {
    width: '28%',
    display: 'flex',
  },
};

export default styles;
