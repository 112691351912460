import { pilot } from '../services';

export async function getClientDepartmentsTree(clientId) {
  const departmentsResponse = await pilot.get(
    `client/${clientId}/departments-info/`,
  );
  return departmentsResponse.results;
}

/**
 * getClient is a JS API composing three BE endpoints.
 * - GET department trees (no permission)
 * - GET sections (requires permission)
 * - GET data fields (requires permission)
 * We handle permission errors from the BE endpoints by catching them and ensuring they are initialized with the right default value.
 */
export async function getClient(clientId) {
  const departmentTree = await getClientDepartmentsTree(clientId);
  let folderTreeResponse = {};
  try {
    folderTreeResponse = await pilot.get('/path/');
  } catch (e) {}

  return {
    id: clientId,
    departmentTree,
    folderTree: folderTreeResponse,
  };
}

export async function getClientBySubdomain({ subdomain }) {
  return await pilot.get(`client/${subdomain}/`);
}

export const getDataFieldSections = (clientId) => {
  return pilot.get(`client/${clientId}/sections/`);
};
