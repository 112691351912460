import { FlagType, useFlag } from '~/flags';
import { Permission, User } from '~/types';

import { checkIsSuperAdmin } from '../checkIsSuperAdmin';
import { and } from '../utils';

export const checkIsRbac: Permission = and([
  () => useFlag(FlagType.NewUsersPage),
  (user: User) => !checkIsSuperAdmin(user),
]);
