import { Layout } from '~/eds';
import { Question } from '~/types';

import { QuestionItem } from './QuestionItem';

type QuestionWithStatus = Question & {
  status?: 'running' | 'complete' | 'pending' | 'idle';
};

interface QuestionListProps {
  questions: QuestionWithStatus[];
  onQuestionClick: (question: Question) => void;
  disableActions?: boolean;
  disableRun?: boolean;
}

export const QuestionList = ({
  disableActions,
  questions,
  disableRun,
  onQuestionClick,
}: QuestionListProps) => {
  return (
    <Layout direction="column" overflowY="auto" height="100%" flex={1}>
      {questions?.map((question) => (
        <QuestionItem
          key={question.id}
          disableRun={disableRun}
          disableActions={disableActions}
          status={question.status}
          question={question}
          onQuestionClick={onQuestionClick}
        />
      ))}
    </Layout>
  );
};
