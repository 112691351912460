import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useStyles } from 'uinix-ui';

import {
  Box,
  Dropdown,
  DropdownBody,
  DropdownSelectHeader,
  Icon,
  IconButton,
  Layout,
  TruncateText,
  types,
  useToggle,
} from '~/eds';
import { DOCUMENT_ID_FILTER } from '~/features/filters';
import { FlagType, useFlag } from '~/flags';
import { Nullable } from '~/types';

import {
  FilterViewType,
  getFilterValuesLength,
  getOperator,
  testIsActiveFilter,
  testIsEmptyFilter,
} from '../../../evifields';
import { FieldIcon } from './FieldIcon';
import { OperatorSelect } from './OperatorSelect';
import { TextContainer } from './TextContainer';
import { AsyncValue, Field, Filter, OperatorId, Value } from './types';
import {
  clearFilter,
  clearFilterValues,
  getLabeledOperator,
  getOperatorsByFieldType,
  testIsEmptyValueFilter,
  updateFilterOperator,
  updateFilterValues,
} from './utils';
import { Values } from './Values';
import { ValuesPreview } from './ValuesPreview';

interface Props {
  enableFilterViews?: boolean;
  disableRemove: boolean;
  field: Field;
  filter: Filter;
  onChange: (_updatedFilter: Filter) => void;
  onRemove: (_filterToRemove: Filter) => void;
  hasError?: boolean;
}

export const FilterChip = ({
  enableFilterViews = false,
  disableRemove,
  field,
  filter: initialFilter,
  hasError,
  onChange: onCommitChange,
  onRemove,
}: Props) => {
  const styles = useStyles();

  // sync local state with controlled state
  const [filter, setFilter] = useState(initialFilter);
  useEffect(() => setFilter(initialFilter), [initialFilter]);
  const hasTextDelimitedDocIdFilter = useFlag(
    FlagType.TextDelimitedDocumentIdFilter,
  );
  const [isVisible, _toggle, show, _hide] = useToggle();

  // EKP-13822 debug notes
  // The setTimeout is needed to allow React to finish updating the 'filter'
  // state - the result of setFilter that were called from, i.e. the onChange of
  // the components like <Values> or <OperatorSelect>.
  // That MUST complete before they get unmounted by tippy, which calls the
  // local 'handleHide', who only commits the change if filter has changed.
  const hide = () => setTimeout(_hide, 0);

  const isRemovedField = Boolean(!field);
  const getField = (field: Field) =>
    !field ? ({ label: 'Removed', type: 'text' } as Field) : field;
  const { label, type } = getField(field);
  const { operatorId } = filter;
  const operators = useMemo(() => getOperatorsByFieldType(getField(field)), [
    field,
  ]);
  const hasOperator = Boolean(operatorId);
  const hasValues = getFilterValuesLength(filter) > 0;

  const operator = getLabeledOperator(field, operatorId);
  const isActive = Boolean(
    operator && testIsActiveFilter(filter, operator, type),
  );
  const isAllBooleanOperator = operators.every(
    (operator) => operator.cardinality === 0,
  );
  const hasOperators = operators.length >= 1;
  const isFolderField = type === 'folder';
  const isEmpty = testIsEmptyFilter(filter);
  const isEmptyValue = testIsEmptyValueFilter(filter);

  const handleRemove = () => {
    if (isEmpty || isRemovedField) {
      onRemove(filter);
    } else {
      // clear only the filter values if there is zero or one operator, otherwise clear the filter entirely (values and operator)
      const updateFilter = hasOperators ? clearFilter : clearFilterValues;
      onCommitChange(updateFilter(filter));
    }
    hide();
  };

  const handleUpdateOperator = (updatedOperatorId: Nullable<OperatorId>) => {
    setFilter(updateFilterOperator(filter, updatedOperatorId));
    const updatedOperator = getOperator(updatedOperatorId);
    if (updatedOperator?.cardinality === 0) {
      hide();
    }
  };

  const handleUpdateValues = (
    updatedValues: Value[],
    updatedAsyncValue?: AsyncValue<Value>,
  ) => {
    setFilter(updateFilterValues(filter, updatedValues, updatedAsyncValue));
    // for certain field types (single-valued selections), hide the dropdown after values are updated
    if (
      type === 'enum' ||
      (type === 'enum_set' && filter.filterView === 'text-delimited') ||
      (hasTextDelimitedDocIdFilter &&
        type === 'text' &&
        field.label === DOCUMENT_ID_FILTER)
    ) {
      hide();
    }
  };

  const handleUpdateFilterView = (
    filterView: types.Nullable<FilterViewType>,
  ) => {
    setFilter({
      ...clearFilterValues(filter),
      filterView: filterView ?? undefined,
    });
  };

  const handleHide = () => {
    // if a filter does not have values assigned, clear it before hiding it.
    const filterToCommit = isEmptyValue ? clearFilter(filter) : filter;

    setFilter(filterToCommit);
    if (!isEqual(initialFilter, filterToCommit)) {
      onCommitChange(filterToCommit);
    }

    hide();
  };

  const handleShow = () => {
    if (!hasOperator && !isAllBooleanOperator) {
      const firstOperator = operators[0];
      setFilter(updateFilterOperator(filter, firstOperator.id));
    }

    show();
  };

  const disableHeader = isAllBooleanOperator || isFolderField || !hasOperators;

  const header = !disableHeader && (
    <DropdownSelectHeader
      select={
        <OperatorSelect
          operators={operators}
          value={operatorId}
          onChange={handleUpdateOperator}
        />
      }
    />
  );

  let contents;
  if (operator?.cardinality) {
    contents = (
      <DropdownBody>
        <Values
          field={field}
          filter={filter}
          onChange={handleUpdateValues}
          onViewChange={enableFilterViews ? handleUpdateFilterView : undefined}
        />
      </DropdownBody>
    );
  } else if (isAllBooleanOperator) {
    contents = (
      <OperatorSelect
        isEmbedded={isAllBooleanOperator}
        operators={operators}
        value={operatorId}
        onChange={handleUpdateOperator}
      />
    );
  }

  const filterText = (
    <Layout spacing={1}>
      <Box minW="min-content">
        <TextContainer isBold={false} text={label} />
      </Box>
      {operator && <strong> {operator.label}</strong>}
      {hasValues && <ValuesPreview field={field} filter={filter} />}
    </Layout>
  );

  const trigger = (
    <Layout
      align="center"
      styles={[componentStyles.base, styles.colors.status]}
      styleProps={{
        status: hasError ? 'danger' : isActive ? 'active' : undefined,
      }}
      role="listitem"
    >
      <Layout
        align="center"
        as="button"
        borderRadius="m"
        color="inherit"
        h="100%"
        px={2}
        spacing={2}
        styles={[
          styles.button.unset,
          componentStyles.leftContent,
          { flexShrink: 1 },
        ]}
        onClick={handleShow}
        minW={0}
      >
        <FieldIcon fieldType={type} />
        <TruncateText color="text.primary" variant="tiny" whiteSpace="nowrap">
          {filterText}
        </TruncateText>
        {disableRemove && (
          <Icon
            color="currentColor"
            icon={isVisible ? 'chevron-up' : 'chevron-down'}
            label={isVisible ? 'Expanded field' : 'Collapsed field'}
          />
        )}
        {!disableRemove && (
          <IconButton
            icon="x"
            size="s"
            tooltip={isEmpty ? 'Remove filter' : 'Clear values'}
            onClick={handleRemove}
          />
        )}
      </Layout>
    </Layout>
  );

  if (isRemovedField) {
    return (
      <Layout
        align="center"
        bg="status.info.secondary"
        styles={[
          componentStyles.base,
          componentStyles.leftContent,
          componentStyles.crossedOut,
        ]}
      >
        <Icon color="status.warning" icon="status-danger" />
        <TruncateText as="s" variant="tiny">
          Field <strong>(removed)</strong>
        </TruncateText>
        <IconButton
          icon="x"
          size="s"
          tooltip="Remove filter"
          onClick={handleRemove}
        />
      </Layout>
    );
  } else if (isVisible) {
    return (
      <Dropdown
        header={header}
        isVisible={true}
        trigger={trigger}
        onClickOutside={hide}
        onHide={handleHide}
        onShow={handleShow}
      >
        {contents}
      </Dropdown>
    );
  } else {
    return <>{trigger}</>;
  }
};

export const componentStyles = {
  base: {
    border: 'border',
    borderRadius: 'm',
    height: 'filter.height',
    outline: 'none',
    maxWidth: '100%',
    width: 'min-content',
  },
  leftContent: {
    borderTopLeftRadius: 'm',
    borderBottomLeftRadius: 'm',
  },
  removedField: {
    cursor: 'default',
  },
  crossedOut: {
    textDecoration: 'line-through',
  },
};
