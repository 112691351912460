import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { AxiosError } from 'axios';

import { ColumnSortOrder } from '~/types';

interface Identifiable {
  id: any;
}

export const createQueryFn = <ResultType, QueryArg>(
  asyncMethod: (arg: QueryArg) => Promise<ResultType>,
) => async (arg: QueryArg): Promise<{ data?: ResultType; error: any }> => {
  let data, error: any;
  try {
    data = await asyncMethod(arg);
  } catch (e) {
    error = e;
  }

  return { data, error };
};

export const toSortParam = (sortBy: ColumnSortOrder): string => {
  return `${sortBy.desc ? '-' : ''}${sortBy.id}`;
};

export const testIsAxiosError = <E>(error: unknown): error is AxiosError<E> =>
  (error as AxiosError<E>).isAxiosError;

export function patchById<T extends Identifiable>(
  list: T[] | undefined,
  id: any,
  patch: T | undefined,
) {
  const item = list?.find((key) => key.id === id);

  if (item) {
    Object.assign(item, patch);
  }
}

export function filterById<T extends Identifiable>(
  list: T[] | undefined,
  id: any,
) {
  return list?.filter((item) => item.id !== id);
}

// TODO EKP-27750: this util exists to coerce RTKQ errors to the `any` type (as legacy code is consuming errors as `any`).  It should be deprecated once all API errors are transformed to RTKQ error interfaces (i.e. FetchBaseQueryError | SerializedError)
export const coerceRtkqError = (
  rtkqError: FetchBaseQueryError | SerializedError | undefined,
): any => {
  return rtkqError as any;
};
