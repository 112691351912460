import { throttle } from 'lodash';
import React, { useEffect } from 'react';

import { RESIZE_THROTTLE_MS } from '../constants';
import { testHasOverflown } from '../utils';

interface Props<T extends HTMLElement> {
  /** Callback capturing if the element has ove */
  callback: (hasOverflown: boolean) => void;
  /** React ref pointing to the HTML*Element */
  ref: React.RefObject<T>;
  /** Optional dependencies */
  deps?: any[];
  /** Element selector of the provided ref */
  selectRefElement?: (ref: React.RefObject<T>) => HTMLElement | null;
}

export const useHasOverflown = <T extends HTMLElement>({
  callback,
  deps = [],
  ref,
  selectRefElement = (ref) => ref.current,
}: Props<T>) => {
  useEffect(() => {
    const element = selectRefElement(ref);

    if (element) {
      const resize = throttle((entries) => {
        if (!entries || entries.length === 0) {
          return;
        }

        callback(testHasOverflown(element));
      }, RESIZE_THROTTLE_MS);

      const resizeObserver = new ResizeObserver(resize);
      resizeObserver.observe(element);

      return () => resizeObserver.unobserve(element);
    }
  }, deps);
};
