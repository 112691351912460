import { PanelType } from '~/enums';

interface Panel_DEPRECATED {
  icon: string;
  label: string;
  tooltip: string;
  value: string;
}

export const panels: Record<PanelType, Panel_DEPRECATED> = {
  [PanelType.ActivityLogs]: {
    icon: 'expiring',
    label: 'activity logs',
    tooltip: 'Activity Logs',
    value: 'activityLogs',
  },
  [PanelType.ClauseLibrary]: {
    icon: 'library',
    label: 'Clause Library',
    tooltip: 'Clause Library',
    value: 'clauseLibrary',
  },
  [PanelType.AskAI]: {
    icon: 'evisort-ai-static',
    label: 'ask AI',
    tooltip: 'Ask AI',
    value: 'askAI',
  },
  [PanelType.Comments]: {
    icon: 'message',
    label: 'comments',
    tooltip: 'Comments',
    value: 'comments',
  },
  [PanelType.CycleTimeTracker]: {
    icon: 'turn-tracking',
    label: 'Cycle time tracker',
    tooltip: 'Cycle Time Tracker',
    value: 'cycleTimeTracker',
  },
  [PanelType.DocumentDetails]: {
    icon: 'field-file',
    label: 'document details',
    tooltip: 'Document Details',
    value: 'documentDetails',
  },
  [PanelType.GenAi]: {
    icon: 'automation',
    label: 'AI Drafting Tools',
    tooltip: 'AI Drafting Tools',
    value: 'genAi',
  },
  [PanelType.IntakeForm]: {
    icon: 'checklist',
    label: 'intake form',
    tooltip: 'Intake Form',
    value: 'intakeForm',
  },
  [PanelType.TrackChanges]: {
    icon: 'review',
    label: 'Track changes',
    tooltip: 'Track changes',
    value: 'revisions',
  },
};
