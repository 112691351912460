import React from 'react';
import { connect } from 'react-redux';

import { Button } from '~/eds';
import { FlexLayout, Text } from '~/ui';
import { copyToClipboard } from '~/utils/helper.utils';
import { getClientDomainWindowLocation } from '~/utils/user';

function BlankFormLink({ currentUser, intakeFormLinkId }) {
  const intakeFormLink = `${getClientDomainWindowLocation(
    currentUser,
  )}/workflow/${intakeFormLinkId}/intake-form/link`;

  function handleCopyAction() {
    copyToClipboard(intakeFormLink, 'Intake form link copied to clipboard');
  }

  return (
    <>
      <FlexLayout flexDirection="column" space={2}>
        <Text color="gray-600" variant="xs-dense-medium">
          Unique URL for a blank Intake Form. This field will automatically
          populate with a URL after your workflow is published.
        </Text>
      </FlexLayout>

      <FlexLayout
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={1}
        space={4}
        sx={{
          bg: 'gray-300',
          borderRadius: 'm',
          height: 'input-height',
        }}
      >
        <Text color="gray-700" variant="xs-dense">
          {intakeFormLinkId
            ? intakeFormLink
            : 'A URL for this workflow will appear when the workflow is published'}
        </Text>
        {intakeFormLinkId && (
          <Button
            id="workflow--settings-copy-intake-form-url"
            text="Copy link"
            variant="action"
            onClick={handleCopyAction}
          />
        )}
      </FlexLayout>
    </>
  );
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

export default connect(mapStateToProps)(BlankFormLink);
