import React from 'react';

import { Box, Text } from '~/ui';

// TODO: explore formal form validation with web standards: https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation
export function withInputError(WrappedComponent, validate = () => {}) {
  return React.forwardRef((props, ref) => {
    const {
      ariaDescribedby,
      customErrorStyles = {},
      disableErrorMessage,
      error,
      flexGrow,
    } = props;
    const errorMessage = error || validate(props);
    const shouldShowErrorMessage = !disableErrorMessage && !!errorMessage;

    return (
      <Box sx={{ position: 'relative', flexGrow }}>
        <WrappedComponent {...props} error={errorMessage} ref={ref} />
        {shouldShowErrorMessage && (
          <Text
            id={ariaDescribedby}
            color="red-400"
            sx={{
              bottom: -5,
              left: 0,
              position: 'absolute',
              ...customErrorStyles,
            }}
            variant="2xs-dense"
          >
            {errorMessage}
          </Text>
        )}
      </Box>
    );
  });
}
