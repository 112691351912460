import { atlas } from '~/services';

import { QuestionsResponsePayload } from './types';
import { mapQuestionResponse } from './utils';

type QuestionsRequest = {
  pageSize?: number;
  pageLimit?: number;
  search?: string;
};

const defaultRequest = {
  pageSize: 0,
  pageLimit: 0,
  search: '',
};

export const getQuestions = async (request: QuestionsRequest = {}) => {
  const options = { ...defaultRequest, ...request };
  const params = {
    'page[offset]': options.pageSize,
    'page[limit]': options.pageLimit,
    search: options.search,
  };
  const response: QuestionsResponsePayload = await atlas.get('questions', {
    params,
  });

  return response.data.map(mapQuestionResponse);
};
