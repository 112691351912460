import React from 'react';
import { connect } from 'react-redux';

import { ContentContainer, InfoCard, Layout } from '~/eds';
import { AutomationStageType } from '~/enums';
import { actions } from '~/redux';

const AutomationTypes = ({ setAutomationManageStage }) => {
  return (
    <Layout align="center" alignSelf="center">
      <ContentContainer>
        <Layout direction="column" preset="cards">
          <InfoCard
            title="AI for Standard Clauses"
            description="A quick way to find standard language in your contracts."
            action={{
              text: 'Get Started',
              onClick: () =>
                setAutomationManageStage(AutomationStageType.QuickAiForm),
            }}
          />
          <InfoCard
            title="AI for Non-standard Clauses"
            description="Use this when you have non-standard language or need more control over the process."
            action={{
              text: 'Get Started',
              onClick: () =>
                setAutomationManageStage(AutomationStageType.GuidedAiForm),
            }}
          />
        </Layout>
      </ContentContainer>
    </Layout>
  );
};

const mapStateToProps = ({ automation }) => ({
  view: automation.view,
});

export default connect(mapStateToProps, {
  setAutomationManageStage: actions.setAutomationManageStage,
})(AutomationTypes);
