import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContentContainer, exportXlsx, Layout, Table, useToast } from '~/eds';
import { Filter } from '~/evifields';
import { FlagType, useFlag } from '~/flags';
import { api, slices } from '~/redux';

import { AttemptsFilters } from '../AttemptsFilters';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { Attempt, AttemptType } from '../types';
import { getAttemptsDisabledTooltip, getStatusChip } from '../utils';

export const Attempts = () => {
  const enableWebhookExtraFeatures = useFlag(FlagType.EnableWebhooksComponents);
  const dispatch = useDispatch();
  const { toast } = useToast();

  const filters = useSelector(slices.webhooks.selectors.selectedFilters);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [sortBy, setSortBy] = useState<
    { id: keyof Attempt; desc: boolean } | undefined
  >({
    id: 'dateSent',
    desc: true,
  });

  const { activeEndpoint } = useSelector(
    slices.webhooks.selectors.selectActiveEndpoint,
  );

  useEffect(() => {
    if (activeEndpoint) {
      const updatedFilters = filters.map((filter) => {
        if (filter.id === 'endpointId') {
          return {
            ...filter,
            values: [activeEndpoint.id],
          };
        }
        if (filter.id === 'datetime') {
          return {
            ...filter,
            values: [
              {
                unit: 'days',
                value: 30,
              },
            ],
          };
        }
        return filter;
      });
      dispatch(slices.webhooks.actions.setFilters(updatedFilters));
    }
  }, [activeEndpoint]);

  const {
    data = { results: [], total: 0 },
    isLoading: isLoadingAttempts,
    error: attemptsError,
  } = api.endpoints.getAttempts.useQuery({
    filters,
    page: pageIndex,
    pageSize,
  });

  const [
    getAttemptsExport,
    { isLoading: isLoadingExportAttempts },
  ] = api.endpoints.getAttempts.useLazyQuery();

  const columns = [
    {
      key: 'endpointUrl',
      title: 'Endpoint URL',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.endpointUrl,
      }),
    },
    {
      key: 'eventType',
      title: 'Event Type',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.eventType,
      }),
    },
    {
      key: 'status',
      title: 'Status',
      cellType: 'chips',
      mapCellProps: (attempt: Attempt) => {
        const { text, status } = getStatusChip(attempt.status);
        return {
          chips: [
            {
              text,
              status,
            },
          ],
        };
      },
    },
    {
      key: 'messageId',
      title: 'Message ID',
      cellType: 'text',
      mapCellProps: (attempt: Attempt) => ({
        text: attempt.messageId,
      }),
    },
    {
      key: 'dateSent',
      title: 'Date Sent',
      cellType: 'datetime',
      mapCellProps: (attempt: Attempt) => ({
        datetime: attempt.dateSent,
        format: 'full',
      }),
      width: 'm',
    },
  ];

  const handlePaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      setPageIndex(pageIndex + 1);
    },
    [pageIndex],
  );

  const handlePageSizeChange = useCallback(
    ({ pageSize }: { pageSize: number }) => {
      setPageIndex(1);
      setPageSize(pageSize);
    },
    [pageSize],
  );

  const handleUpdate = useCallback(
    (state: any, action?: any) => {
      if (action?.type === 'toggleSortBy') {
        const updatedSortBy = state.sortBy[0];

        setSortBy(updatedSortBy);
      }
    },
    [sortBy],
  );

  const handleUpdateAttempt = (attempt: Attempt, action: AttemptType) => {
    dispatch(slices.webhooks.actions.setActiveAttempt(attempt));
    dispatch(slices.webhooks.actions.setActiveAttemptType(action));
  };

  const placeholderContent = attemptsError
    ? {
        title: 'An error occurred',
        description: 'Please try again.',
        image: 'error-page-error',
      }
    : data && !data.results.length && !isLoadingAttempts
    ? { title: 'No attempts found', description: 'Try changing your filters.' }
    : undefined;

  const disabledTooltip = getAttemptsDisabledTooltip(isLoadingExportAttempts);

  return (
    <Layout preset="sections">
      <AttemptsFilters
        filters={filters}
        onChange={(updatedFilters: Filter[]) => {
          dispatch(slices.webhooks.actions.setFilters(updatedFilters));
        }}
      />
      <ContentContainer placeholderContent={placeholderContent}>
        <Table
          actions={[
            {
              label: 'Export',
              icon: 'download',
              disabled: isLoadingExportAttempts,
              tooltip: disabledTooltip,
              onClick: (tableState: any) => {
                const { columnOrder, name } = tableState;
                getAttemptsExport({
                  filters,
                  sortBy,
                  pageSize: data.total,
                  page: 1,
                }).then((response) => {
                  const prepareResponse = (response.data?.results ?? []).map(
                    (record) => {
                      return columnOrder.reduce(
                        (newRecord: any, key: string) => {
                          if (key in record) {
                            newRecord[key] = record[key as keyof Attempt];
                          }

                          return newRecord;
                        },
                        {},
                      );
                    },
                  );
                  exportXlsx({
                    data: prepareResponse,
                    name,
                  });
                  toast({
                    status: 'success',
                    message: 'Attempts exported successfully.',
                  });
                });
              },
            },
          ]}
          data={data.results}
          columns={columns}
          isLoading={isLoadingAttempts}
          options={{
            enableManageColumns: false,
            enableSelectRows: false,
            enableExportXlsx: false,
          }}
          rowDetails={{
            onClick: (attempt: Attempt) => {
              handleUpdateAttempt(attempt, 'view');
            },
          }}
          rowActions={
            enableWebhookExtraFeatures
              ? [
                  {
                    label: 'Resend',
                    onClick: (attempt: Attempt) => {
                      handleUpdateAttempt(attempt, 'resend');
                    },
                  },
                  {
                    label: 'Archive',
                    onClick: (attempt: Attempt) => {
                      handleUpdateAttempt(attempt, 'archive');
                    },
                  },
                ]
              : []
          }
          state={{
            pageIndex,
            pageSize,
            sortBy: [sortBy],
          }}
          onPaginate={handlePaginate}
          onPageSizeChange={handlePageSizeChange}
          onUpdate={handleUpdate}
          reactTableOptions={{
            manualSortBy: true,
          }}
        />
      </ContentContainer>
    </Layout>
  );
};
