import { Filter } from '~/evifields';

export const sortFilters = (
  filters: Filter[],
  pinnedFiltersIds: (string | number)[],
) => {
  return [...filters].sort((a, b) => {
    const aFieldId = Number(a.fieldId) || a.fieldId;
    const bFieldId = Number(b.fieldId) || b.fieldId;

    const isAPinned = pinnedFiltersIds.indexOf(aFieldId) !== -1;
    const isBPinned = pinnedFiltersIds.indexOf(bFieldId) !== -1;

    return isAPinned === isBPinned ? 0 : isAPinned ? -1 : 1;
  });
};
