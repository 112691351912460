export default `
<svg
  aria-hidden="true"
  aria-label="reauthenticate"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M19.1201 6.625L13.1201 4.125C12.9639 4.0625 12.7139 4 12.5576 4C12.3701 4 12.1201 4.0625 11.9639 4.125L5.96387 6.625C5.40137 6.875 5.05762 7.40625 5.05762 8C5.05762 16.0625 10.9639 20 12.5264 20C14.1514 20 20.0576 16 20.0576 8C20.0576 7.40625 19.6826 6.875 19.1201 6.625ZM12.5264 18.5C9.65137 17.3125 6.55762 13.5 6.55762 8.03125L12.4951 5.53125C12.5264 5.53125 12.5264 5.53125 12.5576 5.53125L18.5576 8C18.5576 14.3438 14.6201 17.6562 12.5264 18.5ZM10.8076 10.5C10.8076 11.2188 11.2139 11.8125 11.8076 12.0938V14.25C11.8076 14.6875 12.1201 15 12.5576 15C12.9639 15 13.3076 14.6875 13.3076 14.25V12.0938C13.8701 11.8125 14.3076 11.2188 14.3076 10.5C14.3076 9.5625 13.4951 8.75 12.5576 8.75C11.5889 8.75 10.8076 9.5625 10.8076 10.5Z"
    fill="currentcolor"
  />
</svg>
`;
