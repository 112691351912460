import { Layout, TextInput } from '~/eds';
import { Nullable } from '~/types';

import { BaseFilter } from './VisibilityFilter';

type ShareFilters = 'PRIVATE' | 'SHARED';

interface SearchAndFiltersProps {
  search: string;
  visibility: Nullable<ShareFilters>;
  onFilter?: (updatedValue: Nullable<ShareFilters>) => void;
  onSearch: (updatedValue: string) => void;
}

export const SearchAndFilters = ({
  search,
  visibility,
  onSearch,
  onFilter,
}: SearchAndFiltersProps) => {
  const visibilityText = !visibility
    ? 'Share status'
    : visibility === 'PRIVATE'
    ? 'Share status is Mine'
    : 'Share Status is Shared';
  return (
    <Layout direction="column" spacing={2}>
      <TextInput
        name="search question groups"
        placeholder="Search"
        value={search}
        onChange={(value) => onSearch(value ?? '')}
      />
      {onFilter && (
        <BaseFilter<Nullable<ShareFilters>>
          name="share filter"
          value={visibility}
          onChange={onFilter}
          options={[
            {
              label: 'Mine',
              value: 'PRIVATE',
            },
            {
              label: 'Shared',
              value: 'SHARED',
            },
          ]}
          filterText={visibilityText}
        />
      )}
    </Layout>
  );
};
