export enum WorkflowFieldDefaultValueInputType {
  CountrySingleSelect = 'CountrySingleSelect',
  CurrencySingleSelect = 'CurrencySingleSelect',
  Email = 'Email',
  MultiSelect = 'MultiSelect',
  NumberInput = 'NumberInput',
  SingleSelect = 'SingleSelect',
  StateSingleSelect = 'StateSingleSelect',
  TextInput = 'TextInput',
}
