const styles = {
  modalOverlay: {
    position: 'fixed',
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.6)',
    zIndex: '10',
  },
  modalWrapper: {
    position: 'relative',
    display: 'inline-block',
    left: '50%',
    maxWidth: '100%',
    borderRadius: '4px',
    backgroundColor: '#fff',
    top: (props) => (props.verticalCenter ? '50%' : '15vh'),
    transform: (props) =>
      props.verticalCenter
        ? 'translateX(-50%) translateY(-50%)'
        : 'translateX(-50%)',
    width: (props) => props.width || '688px',
    '@media (max-width: 1024px)': {
      top: 0,
    },
  },
};

export default styles;
