export default `
<svg
  aria-hidden="true"
  aria-label="turn-tracking"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M7.37476 4.3C7.7909 4.3 8.07809 4.61504 8.07809 5.00334V6.17556H12.3245V5.00334C12.3245 4.61504 12.638 4.3 13.0278 4.3C13.4176 4.3 13.7311 4.61504 13.7311 5.00334V6.17556H14.9034C15.9378 6.17556 16.7789 7.01516 16.7789 8.05111V9.92667H5.03032V17.4289C5.03032 17.6868 5.26652 17.8978 5.4992 17.8978H11.9875C12.345 18.4487 12.808 18.9264 13.3443 19.3044H5.52558C4.48963 19.3044 3.65002 18.4634 3.65002 17.4289V8.05111C3.65002 7.01516 4.48963 6.17556 5.52558 6.17556H6.6978V5.00334C6.6978 4.61504 7.01137 4.3 7.40114 4.3H7.37476ZM20.53 15.0844C20.53 17.4142 18.6398 19.3044 16.31 19.3044C13.9802 19.3044 12.0636 17.4142 12.0636 15.0844C12.0636 12.7547 13.9802 10.8644 16.31 10.8644C18.6398 10.8644 20.53 12.7547 20.53 15.0844ZM15.8148 13.2089V15.0844C15.8148 15.3423 16.0521 15.5533 16.2836 15.5533H17.7167C17.9746 15.5533 18.1856 15.3423 18.1856 15.0844C18.1856 14.8266 17.9746 14.6156 17.7167 14.6156H16.7525V13.2089C16.7525 12.951 16.5679 12.74 16.2836 12.74C16.0521 12.74 15.8148 12.951 15.8148 13.2089Z"
    fill="currentcolor"
  />
</svg>
`;
