import _pluralize from 'pluralize';

import { HtmlEntityType } from '../enums';
import { Nullable } from '../types';

interface Options {
  /** Count placeholder string if count value is null */
  countPlaceholder: string;
  enableCount: boolean;
}

const defaultOptions = {
  countPlaceholder: HtmlEntityType.Dash,
  enableCount: true,
};

/**
 * Pluralizes a unit based on the provided count.  Uses `pluralize` under the hood with a constrained and friendlier API.
 */
export const pluralize = (
  count: Nullable<number>,
  unit: string,
  options: Partial<Options> = defaultOptions,
) => {
  const { countPlaceholder, enableCount } = { ...defaultOptions, ...options };
  const pluralizedUnits = _pluralize(unit, count);
  const countText = count === null ? countPlaceholder : String(count);

  if (!enableCount) {
    return pluralizedUnits;
  }

  return unit ? `${countText} ${pluralizedUnits}` : countText;
};
