export const borderRadius = {
  all: {
    borderRadius: 'm',
  },
  bottom: {
    borderBottomLeftRadius: 'm',
    borderBottomRightRadius: 'm',
  },
  top: {
    borderTopLeftRadius: 'm',
    borderTopRightRadius: 'm',
  },
};
