import { createReducer } from '@reduxjs/toolkit';
import { has } from 'lodash';

import * as actions from '~/actions';
import {
  attributeMapping,
  configs,
  enforce,
  provider,
} from '~/reducers/saml/fields';

const getPolicy = (field, value) => {
  return field.options.find((opt) => opt.value === value);
};

const getAuthContext = (field, value) => {
  if (!value || value.length === 0) return field.options[0];
  return field.options.find((opt) => opt.value === value[0]);
};

const getDomains = (values) => {
  return values.map((domain) => ({ label: domain, value: domain }));
};

const toggleEnforcedDomain = (state, value) => {
  state.sections.forEach((section) => {
    section.configs.forEach((config) => {
      if (config.fieldName === 'allowed_domains') {
        config.visible = value === 'enforce_domains';
      }
    });
  });
};

const setAttributes = (value, config) => {
  const getValue = (name, config) => {
    if (!config.attributes) return '';
    return config.attributes[name];
  };

  const getStaticAttributeValue = (name, config) => {
    if (!config.custom_attribute_map || !config.custom_attribute_map?.mappings)
      return '';
    const { mappings } = config.custom_attribute_map;
    const mapping = mappings.find(
      (m) => m.evisort_static_attr === name && m.type === 'static',
    );
    return mapping?.saml_attr;
  };

  value.email =
    getStaticAttributeValue('email', config) || getValue('email', config);
  value.firstName =
    getStaticAttributeValue('first_name', config) ||
    getValue('first_name', config);
  value.lastName =
    getStaticAttributeValue('last_name', config) ||
    getValue('last_name', config);
  value.jobTitle = getStaticAttributeValue('job_title', config);
  value.department = getStaticAttributeValue('department', config);
  value.role = getStaticAttributeValue('role', config);
};

const getInitialState = () => {
  return {
    sections: [provider, configs, attributeMapping, enforce],
    configs: [],
    current: null,
  };
};

export default createReducer(getInitialState(), (builder) => {
  builder.addCase(actions.samlFormValueUpdate, (state, action) => {
    const { sectionId, configId, value } = action.payload;
    const lambda = (id) => (data) => data.id === id;

    const section = state.sections.find(lambda(sectionId));
    const config = section.configs.find(lambda(configId));

    if (config.fieldName === 'allowed_domains') {
      config.field.value = value.map((v) => ({
        value: v.value.toLowerCase(),
        label: v.label.toLowerCase(),
      }));
    } else {
      config.field.value = value;
    }

    if (config.fieldName === 'policy') {
      toggleEnforcedDomain(state, value.value);
    }
  });
  builder.addCase(actions.samlConfigsSet, (state, action) => {
    state.configs = action.payload;
  });
  builder.addCase(actions.samlConfigEdit, (state, action) => {
    state.current = action.payload;
    const current = action.payload;

    state.sections.forEach((section) => {
      section.configs.forEach((config) => {
        const name = config.fieldName;
        const field = config.field;

        if (has(current, name)) {
          switch (name) {
            case 'policy':
              field.value = getPolicy(field, current[name]);
              toggleEnforcedDomain(state, current[name]);
              break;
            case 'auth_context_class':
              field.value = getAuthContext(field, current[name]);
              break;
            case 'allowed_domains':
              field.value = getDomains(current[name]);
              break;
            case 'attributes':
              setAttributes(field.value, current);
              break;
            default:
              field.value = current[name];
              break;
          }
        }
      });
    });
  });
  builder.addCase(actions.samlFormReset, (state) => {
    state.sections = getInitialState().sections;
    state.current = null;
  });
});
