import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { RbacPermission } from '~/types';

type Props = {
  permission?: RbacPermission;
};

export const usePermission = ({ permission }: Props) => {
  const hasNewUsersPage = useFlag(FlagType.NewUsersPage);
  const skip = !permission || !hasNewUsersPage;
  const {
    data: permissionResponse,
    isFetching,
  } = api.endpoints.checkPermission.useQuery(
    {
      resourceId: permission?.resourceId ?? '',
      resourceType: permission?.resourceType ?? '',
    },
    { skip },
  );

  return {
    hasPermission: skip
      ? true
      : permissionResponse?.data?.attributes?.permission,
    isLoading: isFetching,
  };
};
