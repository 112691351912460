import React, { memo, useMemo } from 'react';
import { useStyles } from 'uinix-ui';

import { SharedFooterProps, SharedHeaderProps, StatusType } from '../../types';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Layout } from '../Layout';
import { Text } from '../Text';

export interface Props {
  /** Card contents */
  children: React.ReactNode;
  /** Supported semantic DOM element */
  as?: 'details';
  /** Banner text */
  banner?: {
    /** A11y-friendly label */
    label: string;
    /** Banner status display color */
    status: StatusType;
    /** Banner display text */
    text: string;
  };
  /** Disabled state */
  disabled?: boolean;
  /** DOM props when used in conjunction as `props.as` */
  domProps?: Partial<{
    'aria-selected': boolean;
    open: boolean;
  }>;
  /** Flex */
  flex?: string;
  /** Optional card footer */
  footer?: SharedFooterProps;
  /** Optional card header */
  header?: SharedHeaderProps;
  /** UI mode */
  mode?: 'bordered' | 'embedded' | 'raised';
  /** Optional accesibility name to define the current element */
  name?: string;
  /** Configures card size (e.g. padding) */
  size?: 's' | 'm' | 'l';
  /** Optional status indicator */
  status?: StatusType;
  /** CSS width value */
  width?: number | string;
  /** Optional accesibility role to define the current element */
  role?: string;
  /** Click handler */
  onClick?: (event?: MouseEvent) => void;
}

export const Card = memo(
  ({
    as,
    banner,
    children,
    disabled,
    domProps = {},
    flex,
    footer,
    header,
    mode = 'raised',
    name,
    onClick,
    size = 'm',
    status,
    width,
    role = 'region',
  }: Props) => {
    const styles = useStyles();

    const isEmbedded = mode === 'embedded';
    const hasBoxShadow = mode === 'raised' && !isEmbedded;
    const hasBorder = mode === 'bordered' && !isEmbedded;

    const px = useMemo(() => {
      if (isEmbedded) return 0;

      switch (size) {
        case 's':
          return 3;
        case 'm':
          return 6;
        case 'l':
          return 6;
        default:
          return 3;
      }
    }, [size, isEmbedded]);

    const py = useMemo(() => {
      if (isEmbedded) return 0;

      switch (size) {
        case 's':
          return 2;
        case 'm':
          return 6;
        case 'l':
          return 6;
        default:
          return 2;
      }
    }, [size, isEmbedded]);

    const componentStyles = {
      base: {
        border: hasBorder ? 'border' : undefined,
        borderColor: status ? `status.${status}` : undefined,
        borderRadius: size,
        borderTopLeftRadius: banner ? 0 : size,
        borderTopRightRadius: banner ? 0 : size,
        boxShadow: hasBoxShadow ? 'raised' : undefined,
        minWidth: width,
        overflowY: banner ? 'visible' : 'auto',
        position: 'relative',
        width,
      },
      banner: {
        borderTopLeftRadius: size,
        borderTopRightRadius: size,
        height: 'card.banner.height',
        left: '-1px',
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 0.5,
        position: 'absolute',
        right: '-1px',
        textAlign: 'center',
        top: 0,
        transform: 'translateY(-100%)',
      },
    };

    return (
      <Layout
        {...domProps}
        aria-label={name}
        as={as}
        role={role}
        direction="column"
        disabled={disabled}
        flex={flex}
        styles={[componentStyles.base, styles.colors.status]}
        styleProps={{ status }}
        onClick={disabled ? undefined : onClick}
      >
        {banner && (
          <Text
            aria-label={banner.label}
            color="inverse.text.primary"
            styles={[componentStyles.banner, styles.colors.status]}
            styleProps={{ status: banner.status, variant: 'primary' }}
            variant="tiny-bold"
          >
            {banner.text}
          </Text>
        )}
        {header && <Header {...header} />}
        {children && (
          <Layout direction="column" flex="auto" px={px} py={py} w="100%">
            {children}
          </Layout>
        )}
        {footer && <Footer {...footer} />}
      </Layout>
    );
  },
);
