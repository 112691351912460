import { black3 } from '../../../assets/shared-styles/general';

const styles = {
  headerContainer: {
    marginBottom: '10px',
    display: 'flex',
  },
  titleVersionContainer: {
    paddingRight: '30px',
    width: '10%',
    display: 'inline',
    textAlign: 'right',
  },
  titleFilenameContainer: {
    width: '23%',
    display: 'flex',
  },
  titleLastModContainer: {
    width: '25%',
    display: 'flex',
  },
  titleDescripContainer: {
    width: '28%',
    display: 'flex',
  },
  noSearchResultMessage: {
    margin: '20px 52px',
    color: black3,
    fontSize: '14px',
  },
};

export default styles;
