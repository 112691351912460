import { saveAs } from 'file-saver';
import { isEqual } from 'lodash';

import { showToast } from '~/components/Shared/EcToast';
import { FileMimeType } from '~/enums';
import { INFO } from '~/types/toast.types';

import { htmlToText } from './strings';

export const exportExcelBlob = (file, name) => {
  const blob = new Blob([file], { type: 'application/xlsx' });
  saveAs(blob, name);
};

export const exportCsvBlob = (file, name) => {
  const blob = new Blob([file], { type: 'text/csv' });
  saveAs(blob, name);
};

export const flatten = (array) =>
  array.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

export const algorithmStatusHelper = (algoStatus) => {
  return algoStatus
    ? Object.keys(algoStatus).map((algo) => {
        return {
          name: algo,
          ...algoStatus[algo],
        };
      })
    : null;
};

export const debounce = (fn, time) => {
  let timeout;

  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(this, arguments), time);
  };
};

async function filter(lst, callback) {
  const fail = Symbol();
  const res = await Promise.all(
    lst.map(async (item) => ((await callback(item)) ? item : fail)),
  );
  return res.filter((i) => i !== fail);
}

/**
 * Filter for valid files (exclude folders i.e. `type === ''`)
 * @param {FileList | File[]} files accepts a FileList or array of File
 * @return {File[]} returns filtered array of files
 **/
export const filterValidFiles = async (files) => {
  /* Any files with 0 byte size are invalid */
  const nonEmptyFiles = [...files].filter((file) => file.size > 0);

  /*
   * We cannot rely on file.type, since a file without
   * an extension will have a type of "". However, a
   * folder named "someFolder.jpg" will also have its
   * type as image/jpeg.
   */
  if (Blob.prototype.hasOwnProperty('arrayBuffer')) {
    return await filter(nonEmptyFiles, async (file) => {
      try {
        const blob = file.slice(0, 1);
        await blob.arrayBuffer();
        return true;
      } catch (err) {
        return false;
      }
    });
  }
  // fallback to file type if blob.arrayBuffer is not supported
  return await filter(nonEmptyFiles, async (file) => {
    return Object.values(FileMimeType).includes(file.type);
  });
};

// RFC 5322 official standard: https://emailregex.com/
export const isEmailValid = (emailString) =>
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    emailString,
  );

function onCopySuccess(infoMessage, icon, callback, displayToast) {
  if (!displayToast) return;
  if (callback) {
    callback(infoMessage);
  } else showToast(icon, infoMessage);
}

export const copyHtmlToClipboard = (
  htmlToCopy,
  infoMessage,
  icon = INFO,
  callback,
  displayToast = true,
) => {
  const textBlob = new Blob([htmlToText(htmlToCopy)], { type: 'text/plain' });
  const htmlBlob = new Blob([htmlToCopy], { type: 'text/html' });
  const data = [
    // eslint-disable-next-line no-undef
    new ClipboardItem({ 'text/plain': textBlob, 'text/html': htmlBlob }),
  ];

  return navigator.clipboard
    .write(data)
    .then(() => onCopySuccess(infoMessage, icon, callback, displayToast));
};

export const copyToClipboard = (
  stringToCopy,
  infoMessage,
  icon = INFO,
  callback,
  displayToast = true,
) =>
  navigator.clipboard
    .writeText(stringToCopy)
    .then(() => onCopySuccess(infoMessage, icon, callback, displayToast));

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const testIsDirty = (oldValue, newValue) => {
  return !isEqual(oldValue, newValue);
};

export const formatLocaleDateTime = (
  datetime,
  locale = [], //an empty array means to use the browser's default locale
  options = { dateStyle: 'short', timeStyle: 'short' },
) => new Intl.DateTimeFormat(locale, options).format(new Date(datetime));
