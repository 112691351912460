import React, { useEffect } from 'react';

import AuthenticationStore from '../../../../auth';
import { withCurrentUser } from '../../../../hocs';
import { integrateSegment } from '../../../SegmentAnalytics';
import SplashPage from '../../../SplashPage';

function withSplashScreen(WrappedComponent) {
  function WithSplashScreen({ currentUser, ...restProps }) {
    const isUserLoggedIn = AuthenticationStore().isLoggedIn();

    useEffect(() => {
      if (currentUser && isUserLoggedIn) {
        integrateSegment(currentUser);
      }
    }, [currentUser, isUserLoggedIn, restProps?.location?.pathname]);

    if (currentUser === undefined && isUserLoggedIn) {
      return <SplashPage />;
    } else {
      return <WrappedComponent user={currentUser} {...restProps} />;
    }
  }

  return withCurrentUser(WithSplashScreen);
}

export default withSplashScreen;
