import { add } from 'date-fns';
import { noop } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { RETRY_ERROR } from '~/constants/errors';
import {
  DateInput,
  FormField,
  Layout,
  NumberInput,
  Panel,
  TextArea,
  useToast,
  useUuid,
} from '~/eds';
import { Balance } from '~/features/billing';
import { api } from '~/redux';
import { TAG_BY_CREDIT_SUMMARY } from '~/redux/api/endpoints/codecash';
import { useRouting } from '~/routing';
import { Nullable } from '~/types';

interface FormState {
  credits: Nullable<number>;
  effectiveDate: Nullable<Date>;
  expirationDate: Nullable<Date>;
  notes: Nullable<string>;
}

interface Props {
  onClose: () => void;

  balance?: Balance;
}

const InitialFormState: FormState = {
  credits: 0,
  effectiveDate: new Date(),
  expirationDate: add(new Date(), { years: 1 }),
  notes: '',
};

export const OneTimeCreditPanel = ({ balance, onClose }: Props) => {
  const [id, updateId] = useUuid();
  const { params } = useRouting();
  const [
    createOneTimeCreditBalance,
    { isLoading: isCreateBalanceLoading },
  ] = api.endpoints.createOneTimeCreditBalance.useMutation();

  const [
    editOneTimeCreditBalance,
    { isLoading: isEditingBalanceLoading },
  ] = api.endpoints.editOneTimeCreditBalance.useMutation();
  const [formState, setFormState] = useState<FormState>(
    balance
      ? {
          credits: 0,
          effectiveDate: balance.effectiveDate ?? null,
          expirationDate: balance.expirationDate ?? null,
          notes: balance.notes,
        }
      : InitialFormState,
  );

  const dispatch = useDispatch();
  const { toast } = useToast();

  const { credits, effectiveDate, expirationDate, notes } = formState;

  const editBalance = () =>
    editOneTimeCreditBalance({
      balanceId: balance!.id,
      clientId: Number(params.clientId),
      body: {
        data: {
          type: 'balances',
          attributes: {
            credits: credits!,
            expirationDate: expirationDate!.toISOString(),
            idempotencyKey: `balance_${id}`,
            notes: notes!,
          },
        },
      },
    })
      .unwrap()
      .then((data) => {
        if (data) {
          toast({
            message: 'Credits edited successfully',
            status: 'success',
          });
          dispatch(api.util.invalidateTags([TAG_BY_CREDIT_SUMMARY]));
        }
      })
      .catch((err) => {
        toast({
          message: err[0].detail ?? RETRY_ERROR,
          status: 'danger',
        });
      })
      .finally(onClose);

  const createBalance = () =>
    createOneTimeCreditBalance({
      clientId: Number(params.clientId),
      body: {
        data: {
          type: 'balances',
          attributes: {
            credits: credits!,
            expirationDate: expirationDate!.toISOString(),
            idempotencyKey: `balance_${id}`,
            notes: notes!,
          },
        },
      },
    })
      .unwrap()
      .then((data) => {
        if (data) {
          toast({
            message: 'Credits added successfully',
            status: 'success',
          });
          dispatch(api.util.invalidateTags([TAG_BY_CREDIT_SUMMARY]));
          onClose();
        }
      })
      .catch((err) => {
        toast({
          message: err[0].detail ?? RETRY_ERROR,
          status: 'danger',
        });
      });

  return (
    <Panel
      footer={{
        actions: [
          {
            level: 'tertiary' as const,
            text: 'Cancel',
            onClick: onClose,
          },
          {
            disabled: credits! <= 0,
            isLoading: isCreateBalanceLoading || isEditingBalanceLoading,
            level: 'primary' as const,
            text: balance ? 'Update Credits' : 'Add Credits',
            tooltip:
              credits! <= 0
                ? 'Please make sure all fields are filled correctly'
                : '',
            onClick: () => (balance ? editBalance() : createBalance()),
          },
        ],
      }}
      hidden={{
        onHide: onClose,
      }}
      position="right"
      title={balance ? 'Update One-Time Credits' : 'Add One-Time Credits'}
      width="m"
    >
      <Layout preset="form-fields">
        {balance && (
          <FormField<number, false>
            readOnly
            input={NumberInput}
            label="Current Credit Amount"
            name="Current Credit Amount "
            value={balance.credits}
            onChange={noop}
          />
        )}
        <FormField<number, false>
          required
          input={NumberInput}
          inputProps={{
            min: 1,
            max: 1_000_000_000, // 1 billion per product requirement
          }}
          label={balance ? 'New Credit Amount' : 'Credits'}
          name={balance ? 'New Credit Amount' : 'Credits'}
          value={credits}
          onChange={(credits) => {
            updateId();
            setFormState({ ...formState, credits });
          }}
        />
        <FormField<Date, false>
          disabled={true}
          required
          input={DateInput}
          label="Effective Date"
          name="Effective Date"
          value={effectiveDate}
          onChange={noop}
        />
        <FormField<Date, false>
          required
          input={DateInput}
          inputProps={{
            min: new Date(),
          }}
          label="Expiration Date"
          name="Expiration Date"
          width="100%"
          value={expirationDate}
          onChange={(expirationDate) => {
            updateId();
            setFormState({ ...formState, expirationDate });
          }}
        />
        <FormField<string, false>
          footer="Maximum 300 characters"
          input={TextArea}
          inputProps={{
            maxLength: 300,
          }}
          label="Notes"
          maxLength={300}
          name="Notes"
          value={notes}
          onChange={(notes) => {
            updateId();
            setFormState({ ...formState, notes });
          }}
        />
      </Layout>
    </Panel>
  );
};
