export default `
<svg
  aria-hidden="true"
  aria-label="condition"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.5 11.75H14.0938C14.4062 10.75 15.375 10 16.5 10C17.875 10 19 11.125 19 12.5C19 13.9062 17.875 15 16.5 15C15.375 15 14.4062 14.2812 14.0938 13.25H11.5C10.2188 13.25 9.09375 12.7812 8.25 11.9688V15.125C9.25 15.4375 10 16.4062 10 17.5C10 18.9062 8.875 20 7.5 20C6.09375 20 5 18.9062 5 17.5C5 16.4062 5.71875 15.4375 6.75 15.125V8.90625C5.71875 8.59375 5 7.625 5 6.5C5 5.125 6.09375 4 7.5 4C8.875 4 10 5.125 10 6.5C10 7.625 9.25 8.5625 8.25 8.90625C8.4375 10.5 9.8125 11.75 11.5 11.75ZM7.5 7.5C8.03125 7.5 8.5 7.0625 8.5 6.5C8.5 5.96875 8.03125 5.5 7.5 5.5C6.9375 5.5 6.5 5.96875 6.5 6.5C6.5 7.0625 6.9375 7.5 7.5 7.5ZM7.5 18.5C8.03125 18.5 8.5 18.0625 8.5 17.5C8.5 16.9688 8.03125 16.5 7.5 16.5C6.9375 16.5 6.5 16.9688 6.5 17.5C6.5 18.0625 6.9375 18.5 7.5 18.5ZM16.5 11.5C15.9375 11.5 15.5 11.9688 15.5 12.5C15.5 13.0625 15.9375 13.5 16.5 13.5C17.0312 13.5 17.5 13.0625 17.5 12.5C17.5 11.9688 17.0312 11.5 16.5 11.5Z"
    fill="currentcolor"
  />
</svg>
`;
