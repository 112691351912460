import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Layout } from '~/eds';
import { actions, selectors } from '~/redux';

import { FieldModel } from '../../fields/types';
import {
  useBulkExecuteModelTestCases,
  usePollPromptModelTestCases,
} from '../../hooks';
import { ReviewPaginate } from './ReviewPaginate';
import { ReviewSummary } from './ReviewSummary';
import { ReviewTestCase } from './ReviewTestCase';

interface Props {
  modelVersion: FieldModel;
  mode?: 'summary-only';
  readOnly?: boolean;
}

export const ReviewTestCases = memo(
  ({ mode, modelVersion, readOnly }: Props) => {
    const dispatch = useDispatch();

    const { id: modelId, testCases, version } = modelVersion;

    const activeTestCaseNumber =
      useSelector(selectors.selectFieldAiActiveTestcaseNumber) ??
      testCases.length;
    const activeTestCase =
      testCases[Math.min(testCases.length, activeTestCaseNumber) - 1]; // ensure activeTestCaseNumber is within bounds

    useBulkExecuteModelTestCases({
      testCases,
      modelId,
      version,
    });

    usePollPromptModelTestCases({
      testCases,
      modelId,
      version,
      onUpdateTestCases: (updatedTestCases) =>
        dispatch(actions.setFieldAiTestCases(updatedTestCases)),
    });

    const handleUpdateTestcaseNumber = (testcaseNumber: number) => {
      dispatch(actions.setFieldAiActiveTestcaseNumber(testcaseNumber));
    };

    if (testCases.length === 0) {
      return null;
    }

    return (
      <Layout preset="subsections">
        <ReviewSummary
          hideNavLinks={mode === 'summary-only'}
          testCases={testCases}
          onUpdateTestCaseNumber={handleUpdateTestcaseNumber}
        />
        {mode !== 'summary-only' && (
          <Card mode="bordered">
            <ReviewTestCase testCase={activeTestCase} />
            <Layout direction="column" spacing={1}>
              <ReviewPaginate
                readOnly={readOnly}
                testCase={activeTestCase}
                totalCount={testCases.length}
                onUpdateTestCaseNumber={handleUpdateTestcaseNumber}
              />
            </Layout>
          </Card>
        )}
      </Layout>
    );
  },
);
