import PT from 'prop-types';
import React from 'react';

import { MarkedText } from '~/eds';
import { FlexLayout, Text } from '~/ui';

// TODO update docs after UI inputs are formally implemented.  For now, this implementation allows reusability even with EcInput* components.
function InputLabel({
  children,
  description,
  disabled = false,
  flex,
  id,
  isRequired = false,
  label,
  labelRightContent,
  search = '',
}) {
  if (!label) {
    return children;
  }

  return (
    <FlexLayout
      disabled={disabled}
      flex={flex}
      flexDirection="column"
      space={3}
    >
      <FlexLayout flexDirection="column">
        <FlexLayout
          alignItems="center"
          justifyContent="space-between"
          space={2}
        >
          <FlexLayout alignItems="center" space={2}>
            <Text as="label" color="gray-700" id={id} variant="xs-dense-bold">
              <MarkedText search={search} text={label} />
            </Text>
            {!isRequired && (
              <Text color="gray-600" variant="2xs-dense">
                (optional)
              </Text>
            )}
          </FlexLayout>
          {labelRightContent}
        </FlexLayout>
        {description && (
          <Text color="gray-600" variant="2xs-dense">
            {description}
          </Text>
        )}
      </FlexLayout>
      {children}
    </FlexLayout>
  );
}

InputLabel.propTypes = {
  /** Supported UI Input component */
  children: PT.node.isRequired,
  /** If input is disabled */
  disabled: PT.bool,
  /** Input error */
  error: PT.instanceOf(Error),
  /** Flex property */
  flex: PT.string,
  /** Description providing details for the input */
  description: PT.string,
  /** Input + Label ID for semantic HTML */
  id: PT.string.isRequired,
  /** Is the input field required */
  isRequired: PT.bool,
  /** label */
  label: PT.string,
  /** labelRightContent */
  labelRightContent: PT.node,
  /** used if we want to include label in search */
  search: PT.string,
};

export default InputLabel;
