import PropTypes from 'prop-types';
import React from 'react';

import { User } from '~/components/Shared/User';
import { TicketStatusType } from '~/enums';
import { Box } from '~/ui';

import StatusIcon from './StatusIcon';

function UserAvatarWithStatusIcon({ userId, status }) {
  return (
    <Box sx={{ position: 'relative' }}>
      <User id={userId} mode="avatar" options={{ size: 'l' }} />
      <Box
        sx={{
          bottom: '-2px',
          display: 'flex',
          position: 'absolute',
          right: '-2px',
        }}
      >
        <StatusIcon status={status} />
      </Box>
    </Box>
  );
}

UserAvatarWithStatusIcon.propTypes = {
  status: PropTypes.oneOf(Object.values(TicketStatusType)),
  userId: PropTypes.number.isRequired,
};

export default UserAvatarWithStatusIcon;
