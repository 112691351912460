import React from 'react';
import { useStyles } from 'uinix-ui';

import {
  ActionMode,
  IconType,
  Nullable,
  Option,
  SharedChipProps,
} from '../../../types';
import { Box } from '../../Box';
import { Chips } from '../../Chips';
import { Icon } from '../../Icon';
import { Layout } from '../../Layout';
import { Link } from '../../Link';
import { MarkedText } from '../../MarkedText';
import { CenterTruncateText, TruncateText } from '../../Text';
import { Tooltip } from '../../Tooltip';

const iconMarginTopOffset = '3px'; // this is a hack but flex center alignments do not work due to the complex layout needs with non-interoperable icon and text line-heights, so this value is explicitly used to express the desire to center a small icon against a body text.
const iconMarginXOffset = -1;
const iconSpacing = 1;

interface Props<V = unknown> {
  option: Option<V>;
  enableIndicator?: boolean;
  pathname?: string;
  isFocused?: boolean;
  isIndeterminate?: boolean;
  isMulti?: boolean;
  isSelected?: boolean;
  search?: string;
  onClick?: (value: V) => void;
  onHover?: (option: Option) => void;
}

export const MenuOption = ({
  option,
  enableIndicator,
  pathname,
  isFocused,
  isIndeterminate,
  isMulti,
  isSelected,
  search,
  onClick,
  onHover,
}: Props) => {
  const {
    chips,
    disabled,
    href,
    isDanger,
    label,
    leftContent,
    leftIcon,
    leftIconTooltip,
    mode,
    rightIcon,
    rightIconTooltip,
    description,
    descriptionTruncateMode,
    tags,
    tooltip,
  } = option;
  const styles = useStyles();

  const handleClick = () => onClick?.(option.value);

  const handleHover = () => onHover?.(option);

  const menuContent = (
    <Tooltip tooltip={tooltip} triggerStyle={componentStyles.tooltipTrigger}>
      <Layout
        align="center"
        disabled={disabled}
        justify="space-between"
        spacing={2}
        styles={[componentStyles.base, styles.colors.status]}
        styleProps={{
          status:
            option.isActive || isSelected
              ? 'active'
              : isFocused
              ? 'info'
              : undefined,
        }}
        onClick={handleClick}
        onMouseEnter={handleHover}
        role="option"
      >
        <LeftContent
          enableIndicator={enableIndicator}
          isDanger={isDanger}
          isIndeterminate={isIndeterminate}
          isMulti={isMulti}
          isSelected={isSelected}
          label={label}
          leftIcon={leftIcon}
          mode={mode}
          overrideContent={leftContent}
          search={search}
          description={description}
          descriptionTruncateMode={descriptionTruncateMode}
          tags={tags}
          tooltip={leftIconTooltip}
        />
        <RightContent
          chips={chips}
          rightIcon={rightIcon}
          tooltip={rightIconTooltip}
        />
      </Layout>
    </Tooltip>
  );

  const isLink = Boolean(pathname) || Boolean(href);

  return (
    <Box>
      {isLink ? (
        <Link href={href} pathname={pathname}>
          {menuContent}
        </Link>
      ) : (
        menuContent
      )}
    </Box>
  );
};

interface LeftContentProps {
  label: string;
  enableIndicator?: boolean;
  isDanger?: boolean;
  isIndeterminate?: boolean;
  isMulti?: boolean;
  isSelected?: boolean;
  leftIcon?: IconType;
  mode?: ActionMode;
  overrideContent?: React.ReactNode;
  search?: string;
  description?: string;
  descriptionTruncateMode?: 'center' | 'end';
  tags?: string[];
  tooltip?: string;
}

// can be extended to support other left content e.g. UserAvatar
const LeftContent = ({
  label,
  enableIndicator,
  isDanger = false,
  isIndeterminate,
  isMulti,
  isSelected,
  leftIcon,
  mode,
  overrideContent,
  search,
  description,
  descriptionTruncateMode,
  tooltip,
  tags = [],
}: LeftContentProps) => {
  const iconColor = isSelected ? 'status.active' : undefined;

  let indicatorIcon: Nullable<IconType> = null;
  if (enableIndicator) {
    indicatorIcon = isMulti
      ? isIndeterminate
        ? 'checkbox-indeterminate'
        : isSelected
        ? 'checkbox-checked'
        : 'checkbox-empty'
      : isSelected
      ? 'radio-checked'
      : 'radio-empty';
  }

  const DescriptionTextComponent =
    descriptionTruncateMode === 'center' ? CenterTruncateText : TruncateText;

  const chips = tags.map((tag) => ({ text: tag }));
  const color =
    mode === 'link'
      ? 'action.link'
      : isDanger
      ? 'status.danger'
      : 'text.primary';

  return (
    <Layout align="center" minW={0} spacing={iconSpacing}>
      <Layout align="flex-start" ml={iconMarginXOffset} spacing={iconSpacing}>
        {indicatorIcon && (
          <Icon
            color={iconColor}
            icon={indicatorIcon}
            mt={iconMarginTopOffset}
          />
        )}
        {overrideContent ||
          (leftIcon && (
            <Icon
              color={iconColor}
              icon={leftIcon}
              mt={iconMarginTopOffset}
              tooltip={tooltip}
            />
          ))}
      </Layout>
      <Layout direction="column" minW={0}>
        <Box>
          {label && (
            <TruncateText color={color}>
              <MarkedText search={search} text={label} />
            </TruncateText>
          )}
          {chips && <Chips chips={chips}></Chips>}
        </Box>
        {description && (
          <DescriptionTextComponent color="text.secondary" variant="tiny">
            <MarkedText search={search} text={description} />
          </DescriptionTextComponent>
        )}
      </Layout>
    </Layout>
  );
};

interface RightContentProps {
  chips?: SharedChipProps[];
  rightIcon?: IconType;
  tooltip?: string;
}

// can be extended to support other right content e.g. Chips
const RightContent = ({ chips, rightIcon, tooltip }: RightContentProps) => {
  if (chips) {
    return <Chips chips={chips} />;
  } else if (rightIcon) {
    return (
      <Icon
        icon={rightIcon}
        mr={iconMarginXOffset}
        mt={iconMarginTopOffset}
        tooltip={tooltip}
      />
    );
  } else {
    return null;
  }
};

const componentStyles = {
  base: {
    cursor: 'pointer',
    minHeight: 'height.m',
    paddingBottom: 2,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    width: '100%',
  },
  tooltipTrigger: {
    minWidth: '100%',
  },
};
