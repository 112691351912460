import { black5, evisortBlue } from '../../../assets/shared-styles/general';

const ecCheckboxStyles = {
  alignItems: 'center',
  width: '16px',
  height: '16px',
  border: `solid 1px ${black5}`,
  borderRadius: '2px',
  cursor: 'pointer',
  content: '',
  display: 'inline-block',
  position: 'relative',
  top: 0,
};

const styles = {
  checkboxContainer: {
    marginRight: '5px',
    display: 'inline-flex',
    '&:focus': {
      outline: 'none',
    },
  },
  checkboxHidden: {
    opacity: 0,
    position: 'absolute',
    '&:disabled': {
      '& ~ span': {
        opacity: 0.5,
        pointerEvents: 'none',
        cursor: 'default',
      },
    },
    '&:focus': {
      '& ~ span': {
        outline: 'auto',
      },
    },
  },
  ecCheckbox: {
    ...ecCheckboxStyles,
    backgroundColor: '#fff',
  },
  ecCheckboxSelected: {
    ...ecCheckboxStyles,
    border: 'none',
    backgroundColor: evisortBlue,
  },
};

export default styles;
