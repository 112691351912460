import PropTypes from 'prop-types';
import React from 'react';

import { black5 } from '../../../assets/shared-styles/general';

const DocumentStrokedIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.color}
          fillOpacity={props.opacity || props.isHovered ? '1' : '.5'}
          fillRule="nonzero"
          d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z"
        />
      </g>
    </svg>
  );
};

DocumentStrokedIcon.defaultProps = {
  size: '24',
  color: black5,
};

DocumentStrokedIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
};

export default DocumentStrokedIcon;
