import PropTypes from 'prop-types';
import React from 'react';

const CloseIcon = (props) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      onClick={props.onClick}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.color}
          fillOpacity={props.opacity}
          d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
        />
      </g>
    </svg>
  );
};

CloseIcon.defaultProps = {
  size: '24',
  color: '#000',
  opacity: '1',
};

CloseIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
  onClick: PropTypes.func,
};

export default CloseIcon;
