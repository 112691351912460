import React from 'react';

import { types as Types } from '~/eds';
import { useCommentPdfHighlights } from '~/hooks';
import {
  Comment,
  ContextEntityType,
  CreateCommentHighlightParams,
  HighlightEntityType,
  Nullable,
  Uuid,
} from '~/types';

interface ChildrenParams {
  activeCommentId: Nullable<string>;
  activeHighlightId: Nullable<string>;
  documentVersionHighlights?: any[];
  onUpdateFilters: (filteredComments: Comment[]) => void;
  highlightResolver: (
    highlightEntity: Types.Entity,
  ) => Nullable<{ id: Uuid; text: string }>;
  onCreateComment: (
    param: CreateCommentHighlightParams,
  ) => Promise<Nullable<Comment>>;
  setActiveCommentId: (commentId: string) => void;
  setActiveHighlight: (highlightId: string) => void;
  setIsDocumentLoading: (isLoading: boolean) => void;
}

interface Props {
  entity: Types.Entity;
  render: (params: ChildrenParams) => React.ReactNode;
  context: Types.Entity<ContextEntityType>[];
  highlightEntity: HighlightEntityType;
  activeCommentId?: string;
}

export default function CommentHighlightRenderProps({
  activeCommentId: initialActiveCommentId,
  context,
  entity,
  highlightEntity,
  render,
}: Props) {
  const {
    activeCommentId,
    activeHighlightId,
    highlights,
    onUpdateFilters,
    highlightResolver,
    onCreateComment,
    setActiveCommentId,
    setActiveHighlight,
    setIsDocumentLoading,
  } = useCommentPdfHighlights({
    activeCommentId: initialActiveCommentId,
    context,
    entity,
    highlightEntity,
  });

  return render({
    activeCommentId,
    activeHighlightId,
    documentVersionHighlights: highlights,
    onUpdateFilters,
    highlightResolver,
    onCreateComment,
    setActiveCommentId,
    setActiveHighlight,
    setIsDocumentLoading,
  });
}
