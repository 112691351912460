import React, { useEffect, useState } from 'react';

import { SearchableFields } from '~/api';
import GroupDocumentsTable from '~/components/GroupDocumentsTable';
import { getHandlersKeyFromDocumentGroup } from '~/components/SearchV2/SearchResult/SearchResult.utils';
import { useDocumentMultipleLocationsModal } from '~/components/Shared/EcModal';
import { api } from '~/redux';
import { Box, FlexLayout, Text } from '~/ui';

const SelectDocumentInGroup = ({
  group,
  onDocumentSelected,
  documentGroupIds,
  autoSelectOnlyOption = false,
  title = 'Optional: choose a parent file',
}) => {
  const stickyColumnOrder = ['name'];
  const [columnOrder, setColumnOrder] = useState(stickyColumnOrder);
  const [queryFields, setQueryFields] = useState();
  const [columnWidths, setColumnWidths] = useState();

  const [
    setDocumentSelected,
    renderMultipleLocationModal,
  ] = useDocumentMultipleLocationsModal(getHandlersKeyFromDocumentGroup, true);

  const {
    data: tableViewSet,
    isSuccess: isGetTableViewSetSuccess,
    isFetching: isFetchingTableViewSet,
  } = api.endpoints.getCurrentTableView.useQuery({ context: 'GROUP' });

  const {
    data: filterSections,
    isSuccess: isGetFilterSectionsSuccess,
    isFetching: isFetchingFilterSections,
  } = api.endpoints.getFilterSections.useQuery(undefined);

  const tableState = { columnOrder };
  const handleColumnOrderChange = (updatedColumnOrder) => {
    setColumnOrder(updatedColumnOrder);

    const isDataFieldId = (columnKey) =>
      !!filterSections?.fields[columnKey] &&
      SearchableFields.DocumentGroup !== columnKey;

    const toDataField = (columnKey) => filterSections?.fields[columnKey];

    const mappedQueryFields = updatedColumnOrder
      .filter(isDataFieldId)
      .map(toDataField);

    setQueryFields(mappedQueryFields);
  };

  useEffect(() => {
    if (isGetTableViewSetSuccess && isGetFilterSectionsSuccess) {
      const currentColumnOrder = tableViewSet.map((column) => column.field_id);
      const defaultColumnOrder = [...stickyColumnOrder, ...currentColumnOrder];
      handleColumnOrderChange(defaultColumnOrder);
    }
  }, [isGetTableViewSetSuccess, isGetFilterSectionsSuccess]);

  const isLoading = isFetchingTableViewSet || isFetchingFilterSections;

  const handleUpdate = (state, action) => {
    if (action?.type === 'columnDoneResizing') {
      setColumnWidths({
        ...columnWidths,
        ...state.columnResizing.columnWidths,
      });
    }

    if (action?.type === 'toggleRowSelected') {
      onDocumentSelected(state.selectedRowIds[0]);
    }
  };

  const dataFields = filterSections?.dataFields;

  return (
    <Box pb="100px">
      <FlexLayout mb={8} justifyContent="center">
        <Text variant="subtitle">{title}</Text>
      </FlexLayout>

      <GroupDocumentsTable
        autoSelectOnlyOption={autoSelectOnlyOption}
        columnWidths={columnWidths}
        dataFields={dataFields}
        documentGroupIds={documentGroupIds}
        enableManageColumns={false}
        groupId={group}
        isLoading={isLoading}
        isMultiSelectRows={false}
        onNameClick={setDocumentSelected}
        onUpdate={handleUpdate}
        stickyColumnOrder={stickyColumnOrder}
        tableState={tableState}
        queryFields={queryFields}
        openTarget="_blank"
      />

      {renderMultipleLocationModal()}
    </Box>
  );
};

export default SelectDocumentInGroup;
