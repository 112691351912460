import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getSampleCsvFile, uploadCsvFile } from '~/api';
import {
  Box,
  CollapsibleText,
  FormField,
  IconButton,
  Layout,
  Link,
  Section,
  Stepper,
  Text,
  TextArea,
  TextInput,
} from '~/eds';
import { FileExtensionType, QueryParamType, TrainingStage } from '~/enums';
import { actions } from '~/redux';
import { Alert, FileInput, FileLabel, useModal } from '~/ui';
import { exportCsvBlob } from '~/utils';
import { openInNewTab } from '~/utils/browser';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';

import AutomationHubSearchingFilters from '../../AutomationHubSearchFilters';
import {
  CSV_TEMPLATE_HEADER,
  SAMPLE_FILE_ERRORS,
  SIDE_BAR_WIDTH,
  TRAINING_FILTER_TEXT,
} from '../../constants';

const GuidedAiForm = ({
  clause,
  guidedAiForm,
  filters,
  model,
  setAutomationGuidedAiDataForm,
  setAutomationGuidedAiCsvUploadId,
}) => {
  const shouldShowTemplate = SAMPLE_FILE_ERRORS.includes(
    guidedAiForm.invalids?.error,
  );

  const trainingDataError = (
    <Layout direction="column" align="center" spacing={2}>
      <Text color="status.danger">{guidedAiForm.invalids?.error}</Text>
      {shouldShowTemplate && (
        <Text color="status.danger">
          Make sure that your file conforms to the{' '}
          <Link
            onClick={() => exportCsvBlob(CSV_TEMPLATE_HEADER, 'template.csv')}
          >
            CSV template.
          </Link>
        </Text>
      )}
    </Layout>
  );

  useEffect(() => {
    setAutomationGuidedAiDataForm({
      ...guidedAiForm,
      name:
        model.name || clause.name
          ? (model.name || clause.name).slice(0, 500)
          : guidedAiForm.name,
      description: model.description
        ? model.description
        : guidedAiForm.description,
    });
  }, [model, clause]);

  const [currentSectionId, setCurrentSectionId] = useState('basic-information');
  const [clauseName, setClauseName] = useState(
    guidedAiForm.name || model.name || clause.name,
  );
  const [descriptionValue, setDescriptionValue] = useState(
    guidedAiForm.description || model.description,
  );

  const [removeSamplesWarning, showRemoveSamplesWarning] = useModal({
    title: 'Remove Samples?',
    content: 'Are you sure you want to remove the samples?',
    actionButton: {
      text: 'Remove',
      promise: async () => {
        setCurrentSectionId('add-samples');
        setAutomationGuidedAiDataForm({
          ...guidedAiForm,
          uploadId: null,
          samples: [],
        });
      },
    },
    secondaryButton: {
      text: 'Close',
      variant: 'outlined',
      promise: async () => {},
    },
    disableCancelButton: true,
  });

  const hasExistingModel = guidedAiForm.errors?.some(
    (e) => e && e.includes('already exists'),
  );
  const hasCommonErrors = !hasExistingModel && guidedAiForm.errors?.length > 0;

  const doesClauseNameExist = !!clauseName;
  const isClauseNameOverLimit = clauseName.length > 500;
  const isClauseDescriptionOverLimit =
    (guidedAiForm.description?.length || 0) > 500;

  const isBasicInformationValid =
    doesClauseNameExist &&
    !isClauseNameOverLimit &&
    !isClauseDescriptionOverLimit;

  const modelNameFooter = `${clauseName.length} / 500`;
  const modelNameError = `${clauseName.length} / 500 (Name cannot be longer than 500 characters)`;
  const modelNameDupError =
    'A Clause already exists with this name. Choose a different name.';
  const modelDescriptionFooter = `${guidedAiForm.description.length} / 500`;
  const modelDescriptionError = `${guidedAiForm.description.length} / 500 (Description cannot be longer than 500 characters)`;

  const openSupportLink = () => {
    const url = 'https://support.evisort.com/hc/en-us/articles/5777617260823';
    openInNewTab(url);
  };

  const clauseId = getPageSearchQueryByKey(QueryParamType.clauseId, model.id);

  const onDownloadSamples = () => {
    getSampleCsvFile({ clauseId, version: model.current?.version });
  };

  return (
    <Layout>
      <Box flex="none" w={SIDE_BAR_WIDTH} position="fixed">
        <Stepper
          currentSectionId={currentSectionId}
          name="guided ai steps"
          sections={[
            {
              id: 'basic-information',
              title: 'Basic Information',
              isCompleted: isBasicInformationValid,
              error: isClauseNameOverLimit || isClauseDescriptionOverLimit,
            },
            {
              id: 'add-samples',
              title: 'Add Samples',
              isCompleted: guidedAiForm.samples?.length,
              error: !!guidedAiForm.invalids,
            },
            {
              id: 'training-filters',
              title: 'Training Filters',
              isCompleted: (filters?.[0]?.values?.length ?? 0) > 0,
            },
          ]}
        />
      </Box>
      <Box pl={SIDE_BAR_WIDTH}>
        {hasCommonErrors && (
          <Layout direction="column" mb={4} align="center">
            <Alert
              key="warning"
              enableIcon
              variant="danger"
              onDismiss={() =>
                setAutomationGuidedAiDataForm({ ...guidedAiForm, errors: [] })
              }
            >
              <Layout direction="column" spacing={2}>
                {guidedAiForm.errors.map((e) => (
                  <Text variant="body">{e}</Text>
                ))}
              </Layout>
            </Alert>
          </Layout>
        )}
        <Layout preset="sections">
          <Section id="basic-information" title="Basic Information">
            <Layout preset="form-fields">
              <FormField
                name="custom-model-clause-name"
                label="Clause Name"
                description="For what Clause are you creating this model? You'll use this Clause to find contracts after training is complete, so make sure this name is meaningful."
                input={TextInput}
                placeholder="Create a New Clause"
                value={clauseName}
                disabled={!!clause.name}
                onChange={(name) => {
                  setCurrentSectionId('basic-information');
                  setClauseName(name.trimLeft());
                  setAutomationGuidedAiDataForm({
                    ...guidedAiForm,
                    name: name.trimLeft(),
                    errors: null,
                  });
                }}
                footer={modelNameFooter}
                error={
                  (isClauseNameOverLimit && modelNameError) ||
                  (hasExistingModel && modelNameDupError)
                }
              ></FormField>
              <FormField
                name="custom-model-clause-description"
                label="Description (Optional)"
                description="Give information that might be helpful for you and your colleagues in the future, such as why you created this new Clause."
                input={TextArea}
                placeholder="Description"
                value={descriptionValue}
                onChange={(description) => {
                  setDescriptionValue(description);
                  setCurrentSectionId('basic-information');
                  setAutomationGuidedAiDataForm({
                    ...guidedAiForm,
                    description,
                  });
                }}
                footer={modelDescriptionFooter}
                error={
                  isClauseDescriptionOverLimit ? modelDescriptionError : null
                }
              ></FormField>
            </Layout>
          </Section>
          <Section id="add-samples" title="Add Samples">
            <Text>
              Samples give the model a place to start learning. You must provide
              a minimum of 10 samples that represent the Clause language you
              want to capture. Additionally, you could provide samples that
              represent language that you'd like to avoid capturing.
            </Text>
            <Text>
              <Link
                onClick={() =>
                  exportCsvBlob(CSV_TEMPLATE_HEADER, 'template.csv')
                }
              >
                Use this template
              </Link>{' '}
              to quickly get started.{' '}
              <Link onClick={openSupportLink}>Learn more here.</Link>
            </Text>
            {model.stage === TrainingStage.Predicted && (
              <Layout direction="column">
                <Text>For retraining:</Text>
                <Text>
                  Step 1:{' '}
                  <Link onClick={onDownloadSamples}>
                    Download the existing training data csv (# examples of the
                    Assignment clause)
                  </Link>
                </Text>
                <Text>Step 2: Add/Edit/Remove examples form this csv</Text>
                <Text>Step 3: Save and re-upload</Text>
              </Layout>
            )}
            {guidedAiForm.uploadId &&
            guidedAiForm.csvFile &&
            !guidedAiForm.invalids ? (
              <>
                <Layout
                  bg="background.quiet"
                  justify="space-between"
                  p={6}
                  spacing={3}
                  styles={{ border: 'border', borderRadius: 'm' }}
                >
                  <FileLabel file={guidedAiForm.csvFile} />
                  <IconButton
                    icon="x"
                    title="Remove file"
                    onClick={showRemoveSamplesWarning}
                  />
                </Layout>
              </>
            ) : (
              <FileInput
                file={guidedAiForm.invalids ? null : guidedAiForm.uploadId}
                layout="condensed"
                acceptedFiles={FileExtensionType.Csv}
                enableUpload={true}
                enableDropzone={true}
                enableMultiple={false}
                onChange={(f) => {
                  setCurrentSectionId('add-samples');
                  uploadCsvFile(f[0])
                    .then((response) => {
                      setAutomationGuidedAiCsvUploadId({
                        uploadId: response.id,
                        csvFile: f[0],
                      });
                    })
                    .catch((error) => {
                      setAutomationGuidedAiCsvUploadId({
                        csvFile: f[0],
                        invalids: error.response?.data,
                      });
                    });
                }}
                error={guidedAiForm.invalids && trainingDataError}
              />
            )}
          </Section>
          <Section
            id="training-filters"
            title="Train Model using Specific Contracts (Optional)"
          >
            <Layout direction="column" spacing={2}>
              <Text whiteSpace="pre-wrap">
                <CollapsibleText
                  limit={181}
                  text={TRAINING_FILTER_TEXT}
                ></CollapsibleText>
              </Text>
              {model.stage === TrainingStage.Predicted ? (
                <AutomationHubSearchingFilters retraining />
              ) : (
                <AutomationHubSearchingFilters training />
              )}
            </Layout>
          </Section>
        </Layout>
      </Box>
      {removeSamplesWarning}
    </Layout>
  );
};

const mapStateToProps = ({ automation }) => ({
  clause: automation.clause,
  filters: automation.filter.filters,
  guidedAiForm: automation.guidedAiForm,
  model: automation.model,
});

export default connect(mapStateToProps, {
  setAutomationGuidedAiDataForm: actions.setAutomationGuidedAiDataForm,
  setAutomationGuidedAiCsvUploadId: actions.setAutomationGuidedAiCsvUploadId,
})(GuidedAiForm);
