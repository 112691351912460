import React, { memo, useMemo } from 'react';

import { Breakpoints, EviScore } from '../EviScore';
import { Layout } from '../Layout';
import { STEP_TAIL_OFFSET_PIXEL_SIZE } from './utils';

interface Props {
  percentage: string;
  score: number;
  breakpoints?: Breakpoints;
}

export const ProgressScore = memo(
  ({ breakpoints, percentage, score }: Props) => {
    const componentStyles = useMemo(
      () => ({
        container: {
          bottom: 0,
          left: percentage,
          marginLeft: -STEP_TAIL_OFFSET_PIXEL_SIZE,
          position: 'absolute',
          top: 0,
        },
      }),
      [percentage],
    );

    if (!score) {
      return null;
    }

    return (
      <Layout
        align="center"
        justify="center"
        styles={componentStyles.container}
      >
        <EviScore disableLabel breakpoints={breakpoints} score={score} />
      </Layout>
    );
  },
);
