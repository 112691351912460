import React, { memo } from 'react';

import { BaseHeader } from './BaseHeader';

interface Props {
  /** Must be an instance of the `Select` component */
  select: React.ReactNode;
}

// This will be tightened with runtime assertions in the near future
export const DropdownSelectHeader = memo(({ select }: Props) => {
  return <BaseHeader>{select}</BaseHeader>;
});
