import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Accordion, Footer, Panel_DEPRECATED } from '../../../components';
import { Column, GroupedColumn } from '../types';
import { ColumnOrder } from './ColumnOrder';
import { ColumnSelection } from './ColumnSelection';

interface Props {
  columns: Column[];
  columnOrder: string[];
  mode: null | 'selection' | 'order';
  primaryColumnKey: string;
  onHide: () => void;
  onUpdateColumnOrder: (updatedColumnOrder: string[]) => void;
  groupedColumns?: GroupedColumn[];
}

export const ManageColumnsPanel = ({
  columns,
  columnOrder: initialColumnOrder,
  groupedColumns,
  mode,
  primaryColumnKey,
  onHide,
  onUpdateColumnOrder,
}: Props) => {
  const [draftColumnOrder, setDraftColumnOrder] = useState(initialColumnOrder);

  useEffect(() => {
    setDraftColumnOrder(initialColumnOrder);
  }, [initialColumnOrder]);

  const isDirty = !isEqual(initialColumnOrder, draftColumnOrder);

  const handleHide = () => {
    setDraftColumnOrder(initialColumnOrder);
    onHide();
  };

  const handleUpdate = () => {
    onUpdateColumnOrder(draftColumnOrder);
    onHide();
  };

  const accordionItems = [
    {
      title: 'Column selection',
      content: (
        <ColumnSelection
          columns={columns}
          columnOrder={draftColumnOrder}
          groupedColumns={groupedColumns}
          primaryColumnKey={primaryColumnKey}
          onUpdateColumnOrder={setDraftColumnOrder}
        />
      ),
      isExpanded: mode === 'selection',
    },
    {
      title: 'Column order',
      content: (
        <ColumnOrder
          columns={columns}
          columnOrder={draftColumnOrder}
          primaryColumnKey={primaryColumnKey}
          onUpdateColumnOrder={setDraftColumnOrder}
        />
      ),
      isExpanded: mode === 'order',
    },
  ];

  const footer = (
    <Footer
      actions={[
        { text: 'Cancel', level: 'tertiary' as const, onClick: handleHide },
        {
          text: 'Update',
          level: 'primary' as const,
          onClick: handleUpdate,
          disabled: !isDirty,
        },
      ]}
    />
  );

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      footer={footer}
      isVisible={mode !== null}
      mode="blocking"
      title="Manage columns"
      onHide={handleHide}
    >
      <Accordion items={accordionItems} />
    </Panel_DEPRECATED>
  );
};
