import { useStyles } from 'uinix-ui';

import { SharedToggleProps } from '../../types';
import { Box } from '../Box';
import { Tooltip } from '../Tooltip';

export const Toggle = ({
  disabled,
  enabled,
  name,
  readOnly,
  status = 'active',
  tooltip,
  onToggle,
}: SharedToggleProps) => {
  const styles = useStyles();

  return (
    <Tooltip tooltip={tooltip}>
      <Box
        aria-checked={enabled}
        aria-label={name}
        as="button"
        disabled={disabled || readOnly}
        role="switch"
        styles={[
          styles.button.unset,
          styles.colors.status,
          componentStyles.container,
        ]}
        styleProps={{
          status: enabled ? status : 'info',
          variant: 'primary',
        }}
        onClick={() => onToggle(!enabled)}
      >
        <Box
          styles={[
            componentStyles.toggle,
            enabled ? componentStyles.toggled : null,
          ]}
        />
      </Box>
    </Tooltip>
  );
};

const componentStyles = {
  container: {
    borderRadius: 'pill',
    flex: 'none',
    height: 20,
    position: 'relative',
    width: 40,
  },
  toggle: {
    backgroundColor: 'background',
    borderRadius: 'round',
    position: 'absolute',
    height: 12,
    top: 1,
    left: 1,
    transition: 'transform 0.3s ease',
    transform: 'translateX(0)',
    width: 12,
  },
  toggled: {
    transform: 'translateX(20px)',
  },
};
