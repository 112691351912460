import React from 'react';

import { Button, Layout } from '~/eds';

export type FilterInstance<V> = {
  text: string;
  value: V;
};

interface Props<V> {
  options: FilterInstance<V>[];
  selectedOptions: V[];
  onChange: (updatedOptions: V[]) => void;
}

export function TableToggleFilter<V extends string>({
  options,
  selectedOptions,
  onChange,
}: Props<V>) {
  const isOptionSelected = (option: V) => {
    return selectedOptions.includes(option);
  };

  const onFilterClick = (option: V) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    onChange(updatedOptions);
  };

  return (
    <Layout spacing={4}>
      {options.map((option) => (
        <Button
          key={option.value}
          text={option.text}
          onClick={() => onFilterClick(option.value)}
          variant={isOptionSelected(option.value) ? 'primary' : 'secondary'}
        />
      ))}
    </Layout>
  );
}
