import React, { useState } from 'react';
import { v4 } from 'uuid';

import { Filters_DEPRECATED } from '~/components/Shared/Filters_DEPRECATED';
import { FieldId, Field as FieldType, Filter } from '~/evifields';
import {
  getDepartmentsFilterRendererAndSerializer,
  getRolesFilterRendererAndSerializer,
} from '~/features/filters';
import { UserAttributes } from '~/redux/api/methods';
import { Filters, PilotId } from '~/types';

import { UsersFiltersType } from './types';

interface Props {
  filters: UsersFiltersType;
  onChangeFilter: (filters: UsersFiltersType) => void;
  clientId: PilotId;
}
const getInitialFilterValues = (filters: UsersFiltersType): Filter[] => [
  {
    id: v4(),
    fieldId: 'departments',
    operatorId: filters.departments?.length ? 'contains_any' : null,
    values: filters.departments ?? [],
  },
  {
    id: v4(),
    fieldId: 'roles',
    operatorId: filters.roles?.length ? 'contains_any' : null,
    values: filters.roles ?? [],
  },
  {
    id: v4(),
    fieldId: 'status',
    operatorId: filters.status?.length ? 'contains_any' : null,
    values: filters.status ?? [],
  },
];

const statusesFilterOptions: {
  label: string;
  value: UserAttributes['status'];
}[] = [
  { value: 'Active', label: 'Active' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Deactivated', label: 'Deactivated' },
];

export const UsersFilters = ({ clientId, filters, onChangeFilter }: Props) => {
  const [filterData, setFilterData] = useState<Filter[]>(
    getInitialFilterValues(filters),
  );
  const { render, serialize } = getDepartmentsFilterRendererAndSerializer({
    clientId,
  });
  const {
    render: roleRenderer,
    serialize: roleSerializer,
  } = getRolesFilterRendererAndSerializer({
    clientId,
  });
  const DEFAULT_FIELDS: Record<FieldId, Filters.Field> = {
    departments: {
      id: 'departments',
      label: 'Departments',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        __testEnableSelectAll: () => false,
        options: [],
      },
      render: render,
      serialize: serialize,
    },
    roles: {
      id: 'roles',
      label: 'Roles',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        __testEnableSelectAll: () => false,
        options: [],
      },
      render: roleRenderer,
      serialize: roleSerializer,
    },
    status: {
      id: 'status',
      label: 'Status',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        __testEnableSelectAll: () => false,
        options: statusesFilterOptions,
      },
    },
  };

  const onFilterChange = (filters: Filter[]) => {
    setFilterData(filters);
    const filtersPayload = filters.reduce((acc, filter) => {
      return {
        ...acc,
        [filter.fieldId]: filter.values,
      };
    }, {} as UsersFiltersType);
    onChangeFilter(filtersPayload);
  };

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Filters_DEPRECATED
      fields={DEFAULT_FIELDS as Record<FieldId, FieldType>}
      filters={filterData}
      groups={[]}
      options={{
        disableManageFilters: true,
        disableRemoveFilter: true,
        disableClearFilters: true,
      }}
      onChange={onFilterChange}
    />
  );
};
