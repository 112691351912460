// Had an agreement to use name field to extract the contract type
// Relates to EP-1241: https://evisort.atlassian.net/browse/EP-1241
function getFieldValue(field) {
  if (field.value?.map) {
    return field.value?.map((v) => v.value);
  } else if (field.value?.value) {
    return [field.value.value];
  }
}
export function getDocumentTypes(sections) {
  return sections.reduce((type, section) => {
    if (!section.fields) return type;

    // TODO: May revise the field name to extract Contract Type later
    // Use class_name referring a reliable key in backend for Contract Type
    // The name field referring label in backend and can be customized
    const typeField = section.fields.find(
      (item) => item.class_name === 'Contract Type',
    );

    return typeField ? getFieldValue(typeField) : type;
  }, null);
}

export function getTabIndexWithinRange(activeIndex, maxIndex) {
  const coercedActiveIndex = Number(activeIndex);
  if (
    isNaN(coercedActiveIndex) ||
    coercedActiveIndex < 0 ||
    coercedActiveIndex > maxIndex
  ) {
    return 0;
  }
  return coercedActiveIndex;
}
