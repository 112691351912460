import {
  black1,
  black2,
  bold,
  evisortBlue,
} from '../../../assets/shared-styles/general';

const itemHeaderStyles = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '15px',
  '& svg': {
    marginRight: '8px',
    cursor: 'pointer',
  },
};

const itemNameStyles = {
  marginRight: '20px',
  color: black1,
  cursor: 'pointer',
  '&:hover': {
    color: black2,
  },
};

const styles = {
  departmentTreeItem: {
    margin: '12px 0',
    paddingLeft: '16px',
  },
  itemHeader: {
    ...itemHeaderStyles,
  },
  itemHidden: {
    display: 'none',
  },
  emptyItemHeader: {
    ...itemHeaderStyles,
    marginLeft: '28px',
  },
  itemName: {
    ...itemNameStyles,
  },
  selectedItemName: {
    ...itemNameStyles,
    color: evisortBlue,
    fontWeight: bold,
    '&:hover': {
      color: evisortBlue,
    },
  },
};

export default styles;
