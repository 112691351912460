import { types as edsTypes, Select } from '~/eds';

interface Props {
  options: edsTypes.Option<string>[];
  value: edsTypes.Option<string>[];
  disabled?: boolean;
  onChange: (options: edsTypes.Option<string>[]) => void;
  onAddOption: (newOption: edsTypes.Option<string>) => void;
}

export const DropdownOptionsSelect = ({
  disabled = false,
  options: initialOptions,
  value,
  onChange,
  onAddOption,
}: Props) => {
  return (
    <Select<string, true>
      isMulti
      disabled={disabled}
      name="dropdown-options-select"
      options={initialOptions.map((option) => ({
        label: option.label,
        value: option.value,
      }))}
      value={value.map((option) => option.value)}
      width="100%"
      onChange={(optionStrings) => {
        const options = optionStrings?.map((option) => ({
          label: option,
          value: option,
        }));
        onChange(options ?? []);
      }}
      onAddOption={(newOption) => onAddOption(newOption)}
    />
  );
};
