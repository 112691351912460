import { useRef } from 'react';

export function useHash(defaultValue) {
  const ref = useRef({});
  const get = () => ref.current;
  const getById = (id) => ref.current[id] || defaultValue;
  const set = (update) => (ref.current = update);

  return {
    get,
    getById,
    set,
  };
}
