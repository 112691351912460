import { createStyles } from 'uinix-ui';

import { globalStyles as global } from './global';
import * as rules from './rules';
import { typography } from './typography';
import { variants } from './variants';

export const styles = createStyles({
  ...rules,
  breakpoints: ['1280px'],
  global,
  typography,
  variants,
});
