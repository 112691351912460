import React, { ReactNode, useEffect, useState } from 'react';

import { showToast } from '~/components/Shared/EcToast';
import { Box, TextArea, useModal } from '~/eds';
import { api } from '~/redux';
import { DocumentClause } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';

import styles from './styles';

const useEditClauseModal = (
  clause: DocumentClause,
  handlerId: number,
  onClose: () => void,
): [ReactNode, () => void] => {
  const [content, setContent] = useState('');
  const [
    updateProvision,
    updateProvisionResult,
  ] = api.endpoints.updateProvisionContent.useMutation();
  const { isError, isSuccess, isLoading } = updateProvisionResult;

  const onChangeClauseContent = (text: string | null) => {
    if (text) {
      setContent(text);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      showToast(SUCCESS, 'Provision updated correctly');
      hideEditModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(ERROR, 'Something went wrong, please try again later.');
    }
  }, [isError]);

  useEffect(() => {
    if (clause) {
      setContent(clause.content);
    }
  }, [clause]);

  const [editModal, showEditModal, hideEditModal] = useModal({
    title: `Edit ${clause.title}`,
    isFullPage: true,
    onCancel: onClose,
    primaryAction: {
      text: 'Save',
      isLoading,
      onClick: () => {
        updateProvision({ provisionId: clause.id, content, handlerId });
      },
    },
    children: (
      <Box styles={styles.editClauseContainer}>
        <TextArea
          name="test"
          value={content}
          onChange={onChangeClauseContent}
        />
      </Box>
    ),
  });

  return [editModal, showEditModal];
};

export default useEditClauseModal;
