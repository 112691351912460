import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Collapse from '~/components/Shared/Collapse';
import StatusText from '~/components/Shared/StatusText';
import { withCurrentUser } from '~/hocs';
import { getCanViewAllIssues, getPhaseById } from '~/reducers/ticketReviewer';
import { Box, FlexLayout, Icon, Text } from '~/ui';
import { capitalize } from '~/utils/strings';
import { getStatusTextStatus } from '~/utils/ticket';

import ReviewEntity from './ReviewEntity';

function ReviewPhase({
  isCollapsed,
  isDisabled,
  currentUser,
  onRiskClick,
  // connected
  canViewAllIssues,
  entities,
  phase,
}) {
  const { isRiskDetection, entityIds, name, status } = phase;

  let canViewPhase = false;
  phase.entityIds.forEach((entityId) => {
    const { approvals } = entities[entityId];
    if (approvals.some((approval) => approval.userId === currentUser.id)) {
      canViewPhase = true;
    }
  });

  if (!canViewAllIssues && !canViewPhase) {
    return null;
  }

  return (
    <Box
      key={isCollapsed.toString()}
      pl={5}
      pr={6}
      py={11}
      sx={{
        borderBottom: 'border',
        pointerEvents: isDisabled ? 'none' : undefined,
        opacity: isDisabled ? 0.5 : undefined,
      }}
    >
      <Collapse
        isCollapsed={isCollapsed}
        trigger={
          <FlexLayout alignItems="center" space={2}>
            <Text color="gray-700" variant="m-spaced-bold">
              {name}
            </Text>
            {isRiskDetection && <Icon color="gray-700" icon="chip" size="xs" />}
          </FlexLayout>
        }
        triggerRightContent={
          <StatusText status={getStatusTextStatus(status)}>
            {capitalize(status)}
          </StatusText>
        }
      >
        {entityIds.map((entityId, index) => (
          <ReviewEntity
            key={entityId}
            entityId={entityId}
            index={index}
            onRiskClick={onRiskClick}
          />
        ))}
      </Collapse>
    </Box>
  );
}

ReviewPhase.propTypes = {
  isCollapsed: PropTypes.bool,
  isDisabled: PropTypes.bool,
  phaseId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ ticketReviewer }, { phaseId }) => ({
  canViewAllIssues: getCanViewAllIssues(ticketReviewer),
  entities: ticketReviewer.entities,
  phase: getPhaseById(ticketReviewer, phaseId),
});

export default connect(mapStateToProps)(withCurrentUser(ReviewPhase));
