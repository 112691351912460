import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { Box, getUserName, Layout, Text, types, useModal, User } from '~/eds';
import { api } from '~/redux';
import { TagType } from '~/redux/api';
import { Nullable, Uuid } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';

interface Props {
  ticketId: Uuid;
}

type Entity = {
  type: 'user' | 'department';
  value: types.User | types.Department;
};

const getModalMessage = (entity: Entity) => {
  if (entity.type === 'user') {
    return (
      <Text>
        <Box display="inline-block">
          <User mode="name" user={entity.value as types.User} />
        </Box>
        <Text>
          {' '}
          is not currently a participant in this Workflow ticket. Add this user?
        </Text>
      </Text>
    );
  }

  if (entity.type === 'department') {
    const department = entity.value as types.Department;
    return (
      <Text>
        <Box display="inline-block">
          <Text variant="body-bold">{department.name}</Text>
        </Box>
        <Text>
          {' '}
          has {department.count} members. Add them all as participants to this
          Workflow ticket?
        </Text>
      </Text>
    );
  }

  return null;
};

export function useAddTicketParticipantModal({ ticketId }: Props) {
  const [entity, setEntity] = useState<Nullable<Entity>>(null);
  const dispatch = useDispatch();
  const [
    addParticipant,
    { isLoading },
  ] = api.endpoints.addTicketParticipants.useMutation();

  const pluralize = entity?.type === 'department' ? 's' : '';

  const [modal, _showModal, closeModal] = useModal({
    title: 'Add participant' + pluralize,
    children: (
      <Layout direction="column" spacing={2}>
        {entity && getModalMessage(entity)}
      </Layout>
    ),
    primaryAction: {
      onClick: onAddAsParticipantClick,
      text: 'Add User' + pluralize,
      isLoading,
    },
    onCancel: clearEntity,
    onHide: clearEntity,
  });

  async function onAddAsParticipantClick() {
    if (entity) {
      const entityName =
        entity.type === 'user'
          ? getUserName(entity.value as types.User)
          : (entity.value as types.Department).name;
      try {
        await addParticipant({
          type: entity.type,
          ticketId,
          typeIds: [entity.value.id as number],
        }).unwrap();

        showToast(SUCCESS, `${entityName} was added as a ticket participant`);
        dispatch(api.util.invalidateTags([{ type: TagType.Acl, id: 'LIST' }]));
      } catch {
        showToast(
          ERROR,
          `An error occured while adding ${entityName} as a participant`,
        );
      }
      clearEntity();
      closeModal();
    }
  }

  function clearEntity() {
    setEntity(null);
  }

  function showModal(entity: {
    type: 'user' | 'department';
    value: types.User | types.Department;
  }) {
    setEntity(entity);
    _showModal();
  }

  return [modal, showModal] as const;
}
