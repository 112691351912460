import { FlagType, useFlag } from '~/flags';
import { Permission } from '~/types';

import { and } from './utils';

const useAuditLogs = () => {
  return useFlag(FlagType.AuditLogsNewPage);
};

export const canUseAuditLogs: Permission = and([useAuditLogs]);
