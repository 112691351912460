import { toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Layout, ReactionCard, Section, Stepper, Text } from '~/eds';
import { useClientSubDomain } from '~/hooks';
import { actions } from '~/redux';
import { Alert } from '~/ui';
import { openInNewTab } from '~/utils/browser';
import { testIsAdmin } from '~/utils/user';

import {
  NEGATIVE_LABELS,
  POSITIVE_LABELS,
  SIDE_BAR_WIDTH,
} from '../../constants';

const createSectionId = (index) => `sample-${index + 1}`;

const GuidedAiReview = ({
  allSamples,
  setAutomationGuidedAiSampleLabel,
  currentUser,
}) => {
  const client = useClientSubDomain();

  const isAdmin = testIsAdmin(currentUser);

  const [currentSectionId, setCurrentSectionId] = useState(createSectionId(0));

  const stepperDescription = (label) =>
    POSITIVE_LABELS.includes(toLower(label)) ? 'Fits' : "Doesn't fit";

  const availableSamples = allSamples
    .map((sample, index) => ({ ...sample, index }))
    .filter((sample) => !!sample.Text);

  const onViewDocument = (index) => {
    const evisortID = allSamples[index]?.evisortID ?? '';
    openInNewTab(`/${client}/document/${evisortID}`);
  };

  useEffect(() => {
    if (isAdmin) {
      availableSamples.every((sample, index) => {
        if (sample.Label === null) {
          const element = document.getElementById(createSectionId(index));
          element.scrollIntoView({ behavior: 'smooth' });
          setCurrentSectionId(createSectionId(index));
          return false;
        }
        return true;
      });
    }
  }, []);

  return (
    <Layout pb="20%">
      <Box position="fixed" w={SIDE_BAR_WIDTH} h="75%" overflowY="auto">
        <Stepper
          currentSectionId={currentSectionId}
          name="guided ai review steps"
          sections={availableSamples.map((sample, index) => ({
            id: createSectionId(index),
            isCompleted: !!allSamples[sample.index]?.Label,
            title: `Sample ${sample.index + 1}`,
            description: allSamples[sample.index]?.Label
              ? stepperDescription(allSamples[sample.index].Label)
              : null,
          }))}
        />
      </Box>
      <Box pl={SIDE_BAR_WIDTH}>
        {!isAdmin && (
          <Box pb="8">
            <Alert variant="warning" enableIcon>
              You have access to {availableSamples.length} of{' '}
              {allSamples.length} samples. Mark the samples below and then
              contact your admin to mark the remaining samples.
            </Alert>
          </Box>
        )}
        <Layout direction="column" preset="cards">
          {availableSamples?.map((fb, sectionIndex) => {
            const index = fb.index;
            return (
              <Section id={createSectionId(sectionIndex)}>
                <ReactionCard
                  actions={[
                    {
                      mode: 'icon',
                      icon: 'open-in-new',
                      level: 'tertiary',
                      text: 'View document',
                      tooltip: 'View document',
                      onClick: () => onViewDocument(fb.index),
                    },
                  ]}
                  isMulti={false}
                  reactions={[
                    {
                      type: 'thumbsup',
                      label: 'Yes',
                      value: POSITIVE_LABELS.includes(toLower(fb.Label)),
                    },
                    {
                      type: 'thumbsdown',
                      label: 'No',
                      value: NEGATIVE_LABELS.includes(toLower(fb.Label)),
                    },
                  ]}
                  description="Does this sample represent your Clause?"
                  title={`Sample ${index + 1}`}
                  onReact={(reacts) => {
                    const element = document.getElementById(
                      createSectionId(sectionIndex + 1),
                    );
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                    setCurrentSectionId(createSectionId(sectionIndex + 1));
                    setAutomationGuidedAiSampleLabel({ index, reacts });
                  }}
                >
                  <Text
                    id={createSectionId(sectionIndex)}
                    onClick={(e) => setCurrentSectionId(e.target.id)}
                  >
                    {fb.Text}
                  </Text>
                </ReactionCard>
              </Section>
            );
          })}
        </Layout>
      </Box>
    </Layout>
  );
};

const mapStateToProps = ({ automation, currentUser }) => ({
  allSamples: automation.guidedAiForm.samples,
  currentUser,
});

export default connect(mapStateToProps, {
  setAutomationGuidedAiSampleLabel: actions.setAutomationGuidedAiSampleLabel,
})(GuidedAiReview);
