import { textMatch } from './textMatch';

/**
 * Returns true if there are text matches evaluated via the textMatch utility.
 *
 *  * @example
 * ```js
 * testHasTextMatch('hello Hello HELLO world', 'lo h'); // true
 */
export const testHasTextMatch = (text: string, pattern = ''): boolean => {
  const matches = textMatch(text, pattern);
  return matches.length > 1;
};
