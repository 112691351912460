import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.SearchV3, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.SearchV3 as TagType],
  endpoints: {
    searchV3Documents: {
      providesTags: [TAG_BY_LIST],
    },
    exportExcelV2: {
      invalidatesTags: [TAG_BY_LIST],
    },
  },
});
