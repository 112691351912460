import {
  black1,
  black2,
  bold,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  modalBodyTitle: {
    margin: '0 0 24px 0',
    fontSize: '24px',
    fontWeight: bold,
  },
  modalBodyContent: {
    '& label': {
      color: black1,
      fontSize: '12px',
      fontWeight: semibold,
    },
    '& input': {
      width: '100%',
      border: 'solid 1px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      marginTop: '12px',
      padding: '12px 16px',
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      fontSize: '15px',
      caretColor: evisortBlue,
      '&:hover': {
        borderColor: evisortBlue,
      },
      '&:focus': {
        borderColor: evisortBlue,
        outline: 'none',
      },
    },
  },
  modalErrorMessage: {
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    marginTop: '24px',
    padding: '12px 16px',
    backgroundColor: 'rgba(243, 55, 55, 0.1)',
    color: black2,
    fontSize: '15px',
  },
  modalBucketMessage: {
    border: 'solid 1px rgba(206, 206, 206, 0.5)',
    borderRadius: '4px',
    marginTop: '24px',
    padding: '12px 16px',
    backgroundColor: 'rgba(206, 206, 206, 0.3)',
    color: black2,
    fontSize: '15px',
  },
  modalTextAreaLabel: {
    margin: '20px 0 12px',
    display: 'block',
  },
  modalTextArea: {
    padding: '12px',
    width: '100%',
    maxWidth: '100%',
    height: '90px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    fontSize: '15px',
    '&:focus': {
      borderColor: evisortBlue,
      outline: 'none',
    },
  },
};

export default styles;
