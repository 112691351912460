import { useMemo, useState } from 'react';

import { sortVariationsByPositionAndName } from '~/components/ClauseLibrary/utils';
import {
  Button,
  Chip,
  CollapsibleText,
  ContentContainer,
  copy,
  FormField,
  Layout,
  Panel,
  Select,
  Text,
  types,
  useToast,
} from '~/eds';
import { api } from '~/redux';
import { Nullable } from '~/types';
import { HtmlContent } from '~/ui';
import { htmlToText } from '~/utils/strings';

interface Props {
  actions?: types.UserAction[];
  isInsertClauseEnabled?: boolean;
  onInsertClause?: (clause: string) => void;
}

const VISIBLE_CHAR_LIMIT = 100;

const placeholders = {
  clause: 'Select Clause Type',
  variation: 'Select Clause Variation',
};

export const ClauseLibraryPanel = ({
  actions,
  isInsertClauseEnabled,
  onInsertClause,
}: Props) => {
  //RTQK
  const {
    data: clauseListData,
    isFetching: isFetchingClauseList,
  } = api.endpoints.clauseLibrarySearchClauses.useQuery({});

  const [
    loadVariations,
    { data: clauseDetailsData, isFetching: isFetchingClauseDetails },
  ] = api.endpoints.clauseLibraryGetClause.useLazyQuery();

  // hooks
  const { toast } = useToast();

  // State
  const [selectedClauseId, setSelectedClauseId] = useState<Nullable<string>>(
    null,
  );
  const [selectedVariationId, setSelectedVariationId] = useState<
    Nullable<string>
  >(null);

  // options
  const clauseOptions: types.Option<string>[] = useMemo(() => {
    if (clauseListData?.data?.length) {
      return [...clauseListData.data].map((clause) => ({
        label: clause.attributes.name,
        value: clause.id,
      }));
    }
    return [];
  }, [clauseListData]);

  const variationOptions = useMemo(() => {
    if (clauseDetailsData?.variations?.length) {
      return [...clauseDetailsData.variations]
        .sort(sortVariationsByPositionAndName)
        .map((variation) => ({
          label: variation.name,
          value: variation.key,
          leftContent: (
            <Chip status={variation.status} text={variation.position} />
          ),
        }));
    }
    return [];
  }, [clauseDetailsData]);

  // select props
  const selectClauseInputProps = {
    isLoading: isFetchingClauseList,
    isMulti: false,
    noOptionsMessage: 'No clauses found in your library.',
    options: clauseOptions,
  };

  const selectVariationInputProps = {
    isLoading: isFetchingClauseDetails,
    isMulti: false,
    noOptionsMessage: selectedClauseId
      ? 'Selected clause has no variations.'
      : 'Select a clause type above to see its variations.',
    options: variationOptions,
  };

  // handlers
  const handleUpdateClause = (updatedValue: Nullable<string>) => {
    setSelectedClauseId(updatedValue);
    selectedVariationId && setSelectedVariationId(null);
    if (updatedValue) {
      loadVariations({ clauseId: updatedValue });
    }
  };

  const handleUpdateVariation = (updatedValue: Nullable<string>) => {
    setSelectedVariationId(updatedValue);
  };

  const handleCopy = (text: string) => {
    const strippedText = htmlToText(text);
    copy(strippedText);
    toast({
      status: 'success',
      message: 'Copied to clipboard',
    });
  };

  // derivations
  const selectedClause = clauseDetailsData;
  const selectedVariation = clauseDetailsData?.variations?.find(
    (variation) => variation.key === selectedVariationId,
  );

  return (
    <Panel actions={actions} title="Clause Library">
      <Layout preset="sections" p={2}>
        <Text>
          Select a clause to view the pre-approved language from the Clause
          Library
        </Text>
        <FormField<string, false>
          label="Clause Type"
          input={Select}
          inputProps={selectClauseInputProps}
          name="clauseType"
          placeholder={placeholders.clause}
          value={selectedClauseId}
          onChange={handleUpdateClause}
        />
        <FormField<string, false>
          label="Clause Variation"
          input={Select}
          inputProps={selectVariationInputProps}
          name="clauseVariation"
          placeholder={placeholders.variation}
          value={selectedVariationId}
          onChange={handleUpdateVariation}
        />
        <ContentContainer
          loadingContent={{ isLoading: isFetchingClauseDetails }}
          placeholderContent={
            !selectedClauseId || !selectedVariationId
              ? {
                  message:
                    'Select a clause in the form to view the pre-approved language from Clause Library',
                  icon: 'search',
                }
              : undefined
          }
        >
          {selectedClause && selectedVariation && (
            <>
              <Layout direction="column" spacing={2}>
                <Text variant="section">{selectedClause.name}</Text>
                <CollapsibleText
                  color="text.quiet"
                  limit={VISIBLE_CHAR_LIMIT}
                  text={htmlToText(selectedClause.guidance)}
                  fullContent={
                    <Text>
                      <HtmlContent html={selectedClause.guidance} />
                    </Text>
                  }
                />
                <Layout spacing={2} align="center">
                  <Text variant="body-medium">{selectedVariation.name}</Text>
                  <Chip
                    status={selectedVariation.status}
                    text={selectedVariation.position}
                  />
                </Layout>
                {Boolean(selectedVariation.guidance?.length) && (
                  <CollapsibleText
                    color="text.quiet"
                    limit={VISIBLE_CHAR_LIMIT}
                    text={htmlToText(selectedVariation.guidance)}
                    fullContent={
                      <Text>
                        <HtmlContent html={selectedVariation.guidance} />
                      </Text>
                    }
                  />
                )}
                {Boolean(selectedVariation.text?.length) && (
                  <Layout
                    direction="column"
                    bg="background.quiet"
                    borderRadius="m"
                    py={2}
                    px={3}
                    spacing={2}
                    styles={{
                      maxHeight: 300,
                      overflow: 'auto',
                    }}
                  >
                    <CollapsibleText
                      text={htmlToText(selectedVariation.text)}
                      limit={VISIBLE_CHAR_LIMIT - 20}
                      isExpanded={true}
                      fullContent={
                        <Text>
                          <HtmlContent html={selectedVariation.text} />
                        </Text>
                      }
                    />
                  </Layout>
                )}
              </Layout>
              <Layout spacing={2} justify="end">
                <Button
                  onClick={() => handleCopy(selectedVariation.text)}
                  variant="secondary"
                  text="Copy"
                />
                {onInsertClause && (
                  <Button
                    onClick={() =>
                      onInsertClause(htmlToText(selectedVariation.text))
                    }
                    variant="secondary"
                    text="Insert Clause"
                    tooltip={
                      isInsertClauseEnabled
                        ? 'Insert Clause'
                        : 'Turn on edit in the document viewer'
                    }
                    disabled={!isInsertClauseEnabled}
                  />
                )}
              </Layout>
            </>
          )}
        </ContentContainer>
      </Layout>
    </Panel>
  );
};
