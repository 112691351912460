import { createActions } from '~/utils/redux';

export const {
  samlFormValueUpdate,
  samlFormReset,
  samlConfigsSet,
  samlConfigEdit,
} = createActions('saml', [
  'formValueUpdate',
  'formReset',
  'configsSet',
  'configEdit',
]);
