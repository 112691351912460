import React from 'react';

import { Icon, MenuOption } from '../../../components';
import { MentionedEntity } from '../types';

interface Props {
  isFocused: boolean;
  search: string;
  mention: MentionedEntity<'department'>;
  onClick: (mention: MentionedEntity<'department'>) => void;
}

export const DepartmentSuggestion = ({
  isFocused,
  mention,
  search,
  onClick,
}: Props) => {
  const { department } = (mention as MentionedEntity<'department'>).data;
  return (
    <MenuOption
      isFocused={isFocused}
      search={search}
      option={{
        label: mention.label,
        value: mention.id,
        description: `${department.count} members`,
        leftContent: <Icon icon="department" />,
        rightIcon: mention.isInvalid ? 'block' : undefined,
        rightIconTooltip:
          'Some members in this department do not have access to the file. Share the file with the department to give everyone access.',
      }}
      onClick={() => onClick(mention)}
    />
  );
};
