export const fieldTypeEnum = {
  Text: 'STRING',
  Dropdown: 'ARRAY_MULTIPLE',
  'Text Area': 'TEXT_AREA',
  Number: 'NUMBER',
  Date: 'DATE',
  Attachment: 'ATTACHMENT',
};

export const fieldTypesDisplay = {
  ARRAY_SINGLE: 'Dropdown',
  ARRAY_MULTIPLE: 'Dropdown',
  ATTACHMENT: 'Attachment',
  BOOLEAN: 'True/False',
  DATE: 'Date',
  NUMBER: 'Number',
  STRING: 'Text',
  TEXT_AREA: 'Text Area',
};
