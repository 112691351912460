import { formatDate, formatNumber } from '~/eds';
import { CreditSummary } from '~/features/billing';

import { NO_CREDITS_TOOLTIP } from './constants';

export const calculateCreditEstimate = (
  docsProcessed: number,
  docsInScope: number,
) => Math.max(docsInScope - docsProcessed, 0);

export const getCreditsTooltip = ({
  credits,
  recurringCredits,
  balances,
}: CreditSummary): string => {
  const secondaryinfo = credits <= 0 ? NO_CREDITS_TOOLTIP : null;

  const recurringBalance = balances?.find((balance) => balance.renewalDate);
  const formattedRecurringCredits = recurringCredits
    ? formatNumber(recurringBalance?.credits ?? 0)
    : null;
  const formattedRemainingCredits =
    recurringBalance && recurringBalance.remainingCredits > 0
      ? formatNumber(recurringBalance.remainingCredits)
      : 0;

  const formattedDate = recurringCredits
    ? formatDate(recurringCredits.renewalDate)
    : null;

  const renewalInfo = formattedRecurringCredits
    ? `${formattedRemainingCredits} Recurring Credits will reset to ${formattedRecurringCredits} on\n${formattedDate}.`
    : '';

  const balanceCreditsMap = balances?.reduce<Map<string, number>>(
    (acc, curr) => {
      if (curr.renewalDate || !curr.expirationDate) {
        return acc;
      }
      const formattedExpirationDate = formatDate(curr.expirationDate);
      const currentCredits = acc.get(formattedExpirationDate);
      if (currentCredits) {
        acc.set(
          formattedExpirationDate,
          currentCredits + curr.remainingCredits,
        );
      } else {
        acc.set(formattedExpirationDate, curr.remainingCredits);
      }
      return acc;
    },
    new Map(),
  );

  let creditExpirationInfo = '';

  if (balanceCreditsMap) {
    balanceCreditsMap.forEach((credits, expirationDate) => {
      if (credits > 0) {
        creditExpirationInfo = `${creditExpirationInfo}\n${formatNumber(
          credits,
        )} Credits will expire on ${expirationDate}`;
      }
    });
  }

  return `${renewalInfo}${
    creditExpirationInfo
      ? `${renewalInfo ? '\n' : ''}${creditExpirationInfo}`
      : ''
  }${secondaryinfo ? `${renewalInfo ? '\n\n' : ''}${secondaryinfo}` : ''}`;
};

export const getEstimatedCreditsTooltip = (
  credits: number,
  estimatedCredits: number,
): string => {
  const formattedEstimation = formatNumber(estimatedCredits);
  const exceedCreditsTooltip =
    'You do not have sufficient credits to publish this model.\n\nWhen the estimated credits exceed credits available, you can save models as drafts, but not publish any models.\n\nAdjust the scope or contact Evisort to purchase additional credits.';
  const estimatedCreditsTooltip = `This model will utilize approximately ${formattedEstimation} credits on publishing. Additional credits may be used as new documents are uploaded that fit within the scope selected.`;
  const zeroCreditsTooltip =
    'Estimated Credits is 0 because there are no documents in scope. Adjust the number of documents in scope by clicking on the filters below scope.';
  return estimatedCredits > credits
    ? exceedCreditsTooltip
    : estimatedCredits === 0
    ? zeroCreditsTooltip
    : estimatedCreditsTooltip;
};
