import { noop } from 'lodash';
import React, { useState } from 'react';

import Box from '../Box';
import Checkbox from '../Checkbox';
import FlexLayout from '../FlexLayout';
import Folder from '../Folder';
import Icon from '../Icon';
import { Radio } from '../RadioGroup';
import Text from '../Text';
import Tooltip from '../Tooltip';

const iconColor = 'gray-500';
const iconSize = 'm';
const space = 4;

function FolderNode({
  ariaProps,
  folder,
  isBranch,
  isExpanded,
  isIndeterminate,
  isMulti,
  isSelected,
  level,
  style,
  onExpand,
  onFetchSubfolders,
  onSelect,
}) {
  const [isFetching, setIsFetching] = useState(false);
  const [hasSubfolders, setHasSubfolders] = useState(
    !!onFetchSubfolders || isBranch,
  );

  const { id, disabled, tooltip, expandable = true } = folder;

  const getIndentWidth = (theme) => {
    const spaceMargin = space * 4;
    const iconWidth = theme.sizes[`icon-${iconSize}`];
    return `calc(${level - 1} * (${spaceMargin}px + ${iconWidth}))`;
  };

  const bg = isSelected ? 'blue-100' : undefined;
  const option = { label: '', value: id };
  const indentStyle = { ml: getIndentWidth };
  const chevronVisible = expandable && hasSubfolders;

  const input = isMulti ? (
    <Checkbox value={isIndeterminate ? null : isSelected} onChange={noop} />
  ) : (
    <Radio selected={isSelected} option={option} onChange={noop} />
  );

  const handleExpand = async (event) => {
    if (onFetchSubfolders && !isBranch) {
      try {
        setIsFetching(true);
        const subfolders = await onFetchSubfolders(folder);
        setHasSubfolders(subfolders.length > 0);
      } catch (_error) {
        // swallow error: consumers to handle
      } finally {
        setIsFetching(false);
      }
    }
    event.stopPropagation();
    onExpand(!isExpanded);
  };

  return (
    <Box tabIndex={-1} {...ariaProps} bg={bg} sx={style} onClick={onSelect}>
      <FlexLayout alignItems="center" py={3} space={space} sx={indentStyle}>
        <Icon
          color={iconColor}
          icon={isExpanded ? 'chevronDown' : 'chevronRight'}
          size={iconSize}
          sx={{ visibility: chevronVisible ? 'visible' : 'hidden' }}
          tabIndex={-1}
          title={isExpanded ? 'Hide subfolders' : 'Show subfolders'}
          onClick={handleExpand}
        />
        <Tooltip content={tooltip}>
          <FlexLayout
            alignItems="center"
            disabled={disabled || isFetching}
            flex="auto"
            space={space}
          >
            {input}
            <Folder folder={folder} isConcise={true} isLoading={isFetching} />
            {!hasSubfolders && (
              <Text color="gray-500" variant="2xs-dense">
                No subfolders
              </Text>
            )}
          </FlexLayout>
        </Tooltip>
      </FlexLayout>
    </Box>
  );
}

export default FolderNode;
