export default `
<svg
  aria-hidden="true"
  aria-label="library"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M20.75 5.04064L20.5 5.13439V16.6344L12 18.5094L3.5 16.6344V5.13439L3.21875 5.04064C2.59375 4.88439 2 5.38439 2 6.04064V17.0094C2 17.4781 2.3125 17.8844 2.78125 17.9781L11.7812 19.9469C11.9062 19.9781 12.0625 19.9781 12.1875 19.9469L21.1875 17.9781C21.6562 17.8844 22 17.4781 22 17.0094V6.04064C22 5.38439 21.375 4.88439 20.75 5.04064ZM5.03125 15.7594L11.7812 17.5094C11.8438 17.5094 11.9062 17.5094 12 17.5094C12.0625 17.5094 12.125 17.5094 12.1875 17.5094L18.9375 15.7594C19.25 15.6656 19.5 15.3531 19.5 15.0094V4.75939C19.5 4.54064 19.375 4.35314 19.2188 4.19689C19.0312 4.04064 18.8125 4.00939 18.5938 4.04064L12 5.25939L5.375 4.04064C5.15625 4.00939 4.9375 4.04064 4.75 4.19689C4.59375 4.35314 4.5 4.54064 4.5 4.75939V15.0094C4.5 15.3531 4.71875 15.6656 5.03125 15.7594ZM12.75 6.63439L18 5.66564V14.4469L12.75 15.7906V6.63439ZM6 5.66564L11.25 6.63439V15.7906L6 14.4469V5.66564Z"
    fill="currentcolor"
  />
</svg>
`;
