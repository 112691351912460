import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ticketParticipantsSet, ticketStagesSet } from '~/actions';
import { getTicketParticipants, patchTicketApproval } from '~/api';
import EcTextarea from '~/components/Shared/EcTextarea';
import FormLabel from '~/components/Shared/FormLabel';
import { TicketStatusType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import { api } from '~/redux';
import { Button, enums, FlexLayout, Text, useModal } from '~/ui';

import { useActivityLogUpdater } from '../../shared/useActivityLogUpdater';

function ApprovalItemApproveReject({ ticketId, approvalId, approvalName }) {
  const [note, setNote] = useState('');
  const [status, setStatus] = useState('');
  const useTicketEmailActionWithComment = useFlag(
    FlagType.TicketEmailActionWithComment,
  );
  const dispatch = useDispatch();
  const updateActivityLog = useActivityLogUpdater({ ticketId });
  const [
    getTicketCurrentStage,
  ] = api.endpoints.getTicketCurrentStage.useLazyQuery();

  useEffect(() => {
    if (status) {
      if (status === TicketStatusType.Rejected) showModal();
      else if (useTicketEmailActionWithComment) showModal();
      else patchTicketApprovalReject();
    }
  }, [status]);

  function handleApproveRejectItem(status) {
    setStatus(status);
  }

  let noteToDisplay;
  if (useTicketEmailActionWithComment || status === TicketStatusType.Rejected) {
    noteToDisplay = note;
  } else {
    noteToDisplay = null;
  }

  const { executor: patchTicketApprovalReject, isLoading } = useAsync(
    patchTicketApproval,
    {
      ticketId,
      status,
      approvalId,
      note: noteToDisplay,
    },
    {
      errorToastMessage:
        'Updating judgment result failed. Please refresh the page and try again.',
      errorHandler: () => {
        setStatus('');
      },
      successHandler: async () => {
        const res = await getTicketCurrentStage({ ticketId }).unwrap();
        dispatch(ticketStagesSet(res));
        await updateActivityLog();
        const participants = await getTicketParticipants({ ticketId });
        dispatch(ticketParticipantsSet(participants.data));
        setStatus('');
      },
    },
  );

  let titleToDisplay;
  if (useTicketEmailActionWithComment) {
    titleToDisplay =
      status === TicketStatusType.Rejected
        ? 'Reject the Item?'
        : 'Approve the Item?';
  } else {
    titleToDisplay = 'Reject the Item?';
  }

  let isDisabled;
  if (useTicketEmailActionWithComment) {
    isDisabled =
      status === TicketStatusType.Rejected &&
      (!note || note.length > enums.CHARACTER_INPUT_LIMIT_LONG);
  } else {
    isDisabled = !note || note.length > enums.CHARACTER_INPUT_LIMIT_LONG;
  }

  let descriptionToDisplay;
  if (useTicketEmailActionWithComment) {
    descriptionToDisplay =
      status === TicketStatusType.Rejected
        ? 'Leave a comment outlining the reason for your rejection.'
        : 'Leave a comment outlining the reason for your approval.';
  } else {
    descriptionToDisplay =
      'Leave a comment outlining the reason for your rejection.';
  }

  const [modal, showModal] = useModal({
    title: titleToDisplay,
    actionButton: {
      disabled: isDisabled,
      text: 'Confirm',
      promise: () => patchTicketApprovalReject(),
    },
    content: (
      <FlexLayout flexDirection="column" space={8}>
        <FlexLayout flexDirection="column" space={2}>
          <Text variant="xs-dense">
            Are you sure you want to{' '}
            {status === TicketStatusType.Rejected ? 'reject' : 'approve'} the
            item {`"${approvalName}"`}?
          </Text>
        </FlexLayout>
        <FormLabel
          label="Note"
          description={descriptionToDisplay}
          input={
            <EcTextarea
              value={note}
              onChange={(event) => setNote(event.target.value)}
              placeholder={'Add a comment\u2026'}
              rows="5"
              height="auto"
              autoFocus
            />
          }
        />
      </FlexLayout>
    ),
    onCancel: () => setStatus(''),
  });

  return (
    <FlexLayout alignItems="center" space={4}>
      {modal}
      <Button
        variant="green"
        iconLeft="checkmark"
        isLoading={isLoading}
        onClick={() => handleApproveRejectItem(TicketStatusType.Approved)}
      />
      <Button
        variant="red"
        iconLeft="close"
        isLoading={isLoading}
        onClick={() => handleApproveRejectItem(TicketStatusType.Rejected)}
      />
    </FlexLayout>
  );
}
export default ApprovalItemApproveReject;
