import React, { memo, useState } from 'react';

import { useHasOverflown, useTextContent } from '../../../hooks';
import type { Props as TextProps } from '../Text';
import { TruncateText } from './TruncateText';

export const CenterTruncateText = memo((props: TextProps) => {
  const [hasOverflown, setHasOverflown] = useState(false);

  const [ref, textContent] = useTextContent();

  useHasOverflown({
    callback: setHasOverflown,
    ref,
    selectRefElement: (ref) => ref.current?.firstChild as HTMLElement,
  });

  return (
    <span ref={ref}>
      <TruncateText
        {...props}
        styleProps={{ content: hasOverflown ? textContent : undefined }}
      />
    </span>
  );
});
