import React from 'react';

import * as types from '../../../types';
import { getUserName, typedMemo } from '../../../utils';
import { User } from '../../User';
import { Select } from '../Select';

interface Props<M, Role>
  extends types.SharedSelectProps<types.PilotId | Role, M> {
  /** Users that will be available as options. */
  users: types.User[];
  /** Enable user emails */
  enableEmail?: boolean;
  /** Enable select all */
  enableSelectAll?: boolean;
  /** Roles that will be available as options. */
  roles?: types.Option<Role>[];
  /** Serialize contextual user info (activity, timestamp, role, email) */
  serializeUserInfo?: (user: types.User) => string;
}

export const UserSelect = typedMemo(
  <M extends boolean, Role>({
    enableEmail = true,
    roles,
    users,
    value,
    onChange,
    serializeUserInfo,
    ...rest
  }: Props<M, Role>) => {
    const options = users.map(
      transformUserOption({ enableEmail, serializeUserInfo }),
    );
    const enhancedOptions =
      roles && roles.length ? [...roles, ...options] : options;
    return (
      <Select
        placeholder="Select user..."
        {...rest}
        options={enhancedOptions}
        value={value}
        onChange={onChange}
      />
    );
  },
);

export const transformUserOption = ({
  serializeUserInfo,
  enableEmail,
}: {
  serializeUserInfo?: (user: types.User) => string;
  enableEmail?: boolean;
}) => (user: types.User): types.Option<types.PilotId> => ({
  value: user.id,
  label: getUserName(user, { isDeleted: user.isDeleted }),
  leftContent: (
    <User mode="avatar" options={{ disableTooltip: true }} user={user} />
  ),
  description: serializeUserInfo
    ? serializeUserInfo(user)
    : (enableEmail && user.email) || undefined,
});
