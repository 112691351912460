import { Step } from './types';

export const findCurrentStepIndex = (steps: Step[]) =>
  steps.findIndex((step) => step.progress < 1); // i.e. the first uncompleted step

export const testHasSteps = (steps: Step[]) => steps.length > 0;

export const testHasStarted = (step: Step) => step.progress > 0;

export const testIsAllStepsCompleted = (steps: Step[]) =>
  steps.every(testIsCompleted);

export const testIsCompleted = (step: Step) => step.progress === 1;

export const STEP_TAIL_OFFSET_PIXEL_SIZE = 32; // the "tail" border radius of a step, used as an offset in CSS calcs.
