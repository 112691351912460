import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from 'uinix-ui';

import { Box, Link } from '../../components';
import { useHasOverflown } from '../../hooks';
import { NavLink } from '../../types';
import { NavPill } from './NavPill';
import { getBoundedWidthIndex, testIsActiveLink } from './utils';

interface Props {
  activePathname: string;
  links: NavLink[];
}

/**
 * An accessible navigation list of link items (i.e. `nav.ul.li.a`).
 *
 * The `MainNav` autohides overflown nav items in an accessible `ActionsMenu`, implemented by attaching the respective callback to `useHasOverflown` hook (see `testHasOverflown` for relating details).
 *
 * The autohide program is based on `element.offsetWidth`: https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetWidth
 * - Content, padding, border are included.
 * - Margin is not included and the implementation must note to explicitly exclude margins on participating elements in the program.
 * - The DOM does not provide a convenient API that returns the effective width of an element includes the margin.
 */
export const MainNav = ({ activePathname, links }: Props) => {
  const styles = useStyles();

  const ref = useRef<HTMLUListElement>(null);

  const [itemWidths, setItemWidths] = useState<number[]>([]);

  const [visibleItemIndex, setVisibleItemIndex] = useState(links.length);

  // capture item widths
  useEffect(() => {
    const element = ref.current;
    if (element) {
      const itemElements = Array.from(element.children) as HTMLLIElement[];
      setItemWidths(itemElements.map((itemElement) => itemElement.offsetWidth));
    }
  }, [links]);

  const handleAutoHide = () => {
    const element = ref.current;
    if (element) {
      const boundedWidthIndex = getBoundedWidthIndex(
        element.offsetWidth,
        itemWidths,
      );
      setVisibleItemIndex(Math.max(1, boundedWidthIndex)); // at least 1 item must be visible
    }
  };

  useHasOverflown({ callback: handleAutoHide, deps: [itemWidths], ref });

  const visibleLinks = links.slice(0, visibleItemIndex);
  const moreLinks = links.slice(visibleItemIndex);

  const componentStyles = {
    list: {
      alignItems: 'center',
      display: 'flex',
      ...styles.list.unset,
    },
  };

  return (
    <Box
      as="nav"
      aria-label="main navigation"
      flex="auto"
      minW="navbar.min-width"
    >
      <ul ref={ref} style={componentStyles.list}>
        {visibleLinks.map((link) => {
          const { label, pathname, onClick } = link;
          const isActiveLink = testIsActiveLink(activePathname)(link);
          const linkColor = isActiveLink
            ? 'inverse.text.primary'
            : 'inverse.text.secondary';

          return (
            <li key={pathname}>
              <Link
                isNav
                color={linkColor}
                isActive={isActiveLink}
                pathname={pathname}
                variant="navlink"
                onClick={onClick}
              >
                {label}
              </Link>
            </li>
          );
        })}
        {moreLinks.length > 0 && (
          <NavPill
            icon="more"
            menu={{
              options: moreLinks.map((link) => ({
                ...link,
                value: link.pathname,
              })),
              name: 'more navigation menu',
              offsetY: MENU_OFFSET_Y,
            }}
            text={<Box pr={2}>More</Box>}
            tooltip="More items"
          />
        )}
      </ul>
    </Box>
  );
};
/** Offset to Y axis to improve menu visibility in Nav bar */
const MENU_OFFSET_Y = 8;
