import { AxiosError } from 'axios';
import React, { useMemo, useState } from 'react';

import { Layout, Markdown, Panel, pluralize, useToast } from '~/eds';
import { HttpStatusCodeType } from '~/enums';
import {
  BulkClauseEditType,
  EditClausesAction,
  EditClausesData,
} from '~/features/document-bulk-actions';
import { api } from '~/redux';

import { BaseBulkActionProps } from '../types';
import { useDeleteConfirmationModal } from './hooks/useDeleteConfirmationModal';

interface Props extends BaseBulkActionProps {
  onHide: () => void;
  selectedCount: number;
}

export const EditClausesActionPanel = ({
  selectedDocumentIds = [],
  isAllSelected,
  onActionCompleted,
  query = {},
  selectedCount,
  searchAPI,
  onHide,
}: Props) => {
  const { toast } = useToast();
  const [
    bulkEditClause,
    { isLoading },
  ] = api.endpoints.searchV2BulkEditClause.useMutation();

  const [editClausesData, setEditClausesData] = useState<
    EditClausesData | undefined
  >();
  const {
    actionType,
    isAllClausesSelected = false,
    clausesNames = [],
    clausesNewValues,
  } = editClausesData ?? {};

  const save = async () => {
    if (actionType) {
      try {
        await bulkEditClause({
          documentIds: !isAllSelected ? selectedDocumentIds : undefined,
          type: getActionTypePayload(actionType, isAllClausesSelected),
          query: isAllSelected ? query : undefined,
          searchAPI,
          clauseNames: actionType === 'delete' ? clausesNames : undefined,
          clauseAdditions: actionType === 'add' ? clausesNewValues : undefined,
        }).unwrap();
        toast({
          status: 'inactive',
          ...getSuccessToastMessage(
            actionType!,
            isAllClausesSelected,
            clausesNames.length,
          ),
        });
        onActionCompleted?.();
      } catch (error) {
        const axiosError = (error as AxiosError<{
          detail: string;
          block_specific_clauses: boolean;
        }>).response;
        let message: string;
        let isDangerStatus = true;
        if (axiosError?.status === HttpStatusCodeType.BadRequest) {
          if ('block_all_clauses' in axiosError?.data) {
            message =
              'Currently processing other bulk actions on clauses; additional actions are blocked until completion. Please try again in a few minutes.';
          } else if ('block_specific_clauses' in axiosError?.data) {
            isDangerStatus = false;
            message =
              'Currently processing other bulk actions on selected clauses; additional actions are blocked until completion. You can choose different clauses or try again in a few minutes.';
          } else {
            message =
              'An error occured when trying to edit clauses. The request is malformed';
          }
        } else {
          message =
            axiosError?.data.detail ||
            'An error occurred while editing clauses.';
        }
        toast({
          status: isDangerStatus ? 'danger' : 'warning',
          message: message ?? 'An error occurred while editing clauses.',
        });
      }
    }
  };

  const [deleteModal, showDeleteModal] = useDeleteConfirmationModal({
    entity: 'clauses',
    selectedCount,
    entityNames: clausesNames,
    isAllSelected: isAllClausesSelected,
    onDeleteClick: save,
    isDeleteActionLoading: isLoading,
  });

  const handleOnSaveClicked = () => {
    if (actionType === 'delete') {
      showDeleteModal();
    } else {
      save();
    }
  };

  const isSaveButtonDisabled = useMemo(() => {
    const areClausesEmpty = !clausesNames?.length && !isAllClausesSelected;
    const areClauseValuesEmpty =
      !clausesNewValues ||
      Object.values(clausesNewValues).some(
        (newClauseValue) => !newClauseValue.length,
      );
    return (
      !actionType ||
      areClausesEmpty ||
      (actionType === 'add' && areClauseValuesEmpty)
    );
  }, [actionType, clausesNames, isAllClausesSelected, clausesNewValues]);

  const footer = {
    actions: [
      {
        text: 'Save',
        onClick: handleOnSaveClicked,
        level: 'primary' as const,
        disabled: isSaveButtonDisabled,
        isLoading,
      },
    ],
  };

  return (
    <>
      <Panel
        title="Edit Clauses"
        mode="blocking"
        footer={footer}
        width="m"
        hidden={{ isHidden: false, onHide }}
        position="right"
      >
        <Layout preset="sections">
          <Markdown
            text={`Editing is limited to the documents and clauses you have permission to edit. Specify which clauses and values to edit across the **${selectedCount}** selected document(s) and their duplicate(s).`}
          />
          <EditClausesAction onChange={setEditClausesData} />
        </Layout>
      </Panel>
      {deleteModal}
    </>
  );
};

const getActionTypePayload = (
  actionType: EditClausesData['actionType'],
  isAllClausesSelected: boolean,
): BulkClauseEditType => {
  if (actionType === 'delete' && isAllClausesSelected) {
    return 'delete_all';
  }

  return actionType === 'delete' ? 'delete' : 'add';
};

const getSuccessToastMessage = (
  actionType: EditClausesData['actionType'],
  isAllClausesSelected: boolean,
  numberOfClauses: number,
): { title: string; message: string } => {
  let title;
  if (actionType === 'delete') {
    if (isAllClausesSelected) {
      title = 'Deleting all clause values';
    } else {
      title = 'Deleting clause values';
    }
  } else {
    title = `Updating text for ${pluralize(numberOfClauses, 'clause')}`;
  }

  return { title, message: 'You can browse away.' };
};
