import React, { memo } from 'react';

import { SharedReactionProps as Reaction, UserAction } from '../../../types';
import { Layout } from '../../Layout';
import { ReactionGroup } from '../../Reaction';
import { Text } from '../../Text';
import { Card } from '../Card';

export interface Props {
  /** Card contents */
  children: React.ReactNode;
  /** Description for reactions */
  description: string;
  /** An array of reactions */
  reactions: Reaction[];
  /** Card title */
  title: string;
  /** Callback to handle updated reactions */
  onReact: (updatedReactions: Reaction[]) => void;
  /** Optional header actions */
  actions?: UserAction[];
  /** Enables single or multi reactions */
  isMulti?: boolean;
}

export const ReactionCard = memo(
  ({
    actions,
    children,
    description,
    isMulti,
    reactions,
    title,
    onReact,
  }: Props) => {
    const footer = {
      children: (
        <Layout align="center" preset="s">
          <Text preset="description">{description}</Text>
          <ReactionGroup
            isMulti={isMulti}
            reactions={reactions}
            onChange={onReact}
          />
        </Layout>
      ),
    };

    return (
      <Card
        footer={footer}
        header={{
          actions,
          title,
        }}
      >
        {children}
      </Card>
    );
  },
);
