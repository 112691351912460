import { evisortBlue, evisortRed } from '~/assets/shared-styles/general';

const chipBackgroundColor = '#F2F8FB';
const chipCreatedBackgroundColor = '#F5F5F5';
const chipInvalidBackgroundColor = '#FFEBE8';
const textColor = 'rgba(0, 0, 0, 0.75)';

const getMultiValueBackgroundColor = (state) => {
  const isAddedOption = state.data.isAddedOption;
  const isValidEmail = state.data.isValid;
  return isAddedOption
    ? isValidEmail
      ? chipCreatedBackgroundColor
      : chipInvalidBackgroundColor
    : chipBackgroundColor;
};

const getBorderColor = (state) => {
  const hasError = state.selectProps.hasError;
  return hasError ? evisortRed : evisortBlue;
};

export default {
  multiValue: (provided, state) => {
    return {
      ...provided,
      backgroundColor: getMultiValueBackgroundColor(state),
      borderRadius: 4,
      border: '1px solid rgba(0, 0, 0, 0.06)',
    };
  },
  multiValueRemove: (provided, state) => {
    return {
      ...provided,
      '&:hover': {
        backgroundColor: getMultiValueBackgroundColor(state),
        color: textColor,
      },
    };
  },
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    '&:hover': {
      borderColor: getBorderColor(state),
    },
  }),
};
