import { colors } from '../../styles/rules';
import { typography } from '../../styles/typography';

const textVariants = typography.variants;

export const reactDayPicker = {
  '.rdp': {
    ...textVariants.body,
    backgroundColor: 'background',
    margin: 0,
    padding: 0,
    width: 'dateinput.width',
  },
  '.rdp-button[disabled]': {
    opacity: 'disabled',
  },
  '.rdp-button:focus:not([disabled]), .rdp-button:active:not([disabled])': {
    backgroundColor: 'transparent',
    border: 'border',
  },
  '.rdp-button:hover:not([disabled])': {
    ...colors.status({ status: 'info' }),
  },
  '.rdp-day': {
    borderRadius: 0,
    height: 'dateinput.day',
    maxWidth: 'unset',
    minWidth: 'dateinput.day',
    width: 'unset',
  },
  '.rdp-day_range_end.rdp-day_range_start': {
    borderRadius: 0,
  },
  '.rdp-day_selected:not([disabled])': {
    backgroundColor: 'status.active',
  },
  '.rdp-day_selected:focus:not([disabled]), .rdp-day_selected:active:not([disabled])': {
    backgroundColor: 'status.active',
    borderColor: 'active',
  },
  '.rdp-day_selected:hover:not([disabled])': {
    backgroundColor: 'status.active',
  },
  '.rdp-day_selected.rdp-day_range_middle:not([disabled])': {
    backgroundColor: 'status.active.secondary',
    color: 'text.primary',
  },
  '.rdp-day_selected.rdp-day_range_middle:focus:not([disabled]), .rdp-day_selected.rdp-day_range_middle:active:not([disabled])': {
    borderColor: 'active',
  },
  '.rdp-day_selected.rdp-day_range_middle:hover:not([disabled])': {
    ...colors.status({ status: 'info' }),
  },
  '.rdp-day_today:not(.rdp-day_outside)': {
    fontWeight: 'normal',
    textDecoration: 'underline',
  },
  '.rdp-head_cell': {
    ...textVariants.tiny,
    color: 'text.secondary',
  },
};
