import React, { memo, useMemo } from 'react';

import { BasePaginateProps, StatusType, UserAction } from '../../../types';
import { Actions } from '../../Actions';
import { ButtonGroup } from '../../ButtonGroup';
import { Icon } from '../../Icon';
import { Layout } from '../../Layout';
import { Paginate } from '../../Paginate';
import { Card } from '../Card';

/** The following union values correspond to the equivalent StatusType  */
export type ReviewStatus = 'success' | 'danger' | 'warning' | 'inactive' | null;

/** Review action */
interface ReviewAction {
  status: ReviewStatus;
  text: string;
  disabled?: boolean;
  tooltip?: string;
}

interface PaginateProps extends BasePaginateProps {
  /** Optional action to indicate fetching the next record that is out of range of the paginate */
  nextAction?: UserAction;
  /** Tooltip when paginate is loading */
  loadingTooltip?: string;
}

interface Props {
  /** Contents */
  children: React.ReactNode;
  /** Specifies the review action status (null means unspecified) */
  status: ReviewStatus;
  /** Update review status */
  onChange: (updatedStatus: ReviewStatus) => void;
  /** Specify review actions */
  actions?: ReviewAction[];
  /** Disables actions and paginate */
  disabled?: boolean;
  /** message info */
  infoMessage?: string;
  /** Optional paginate */
  paginate?: PaginateProps;
  /** Render status details when the review status is applied */
  renderStatusDetails?: (status: ReviewStatus) => React.ReactNode;
}

export const ReviewCard = memo(
  ({
    actions: reviewActions = [],
    children,
    disabled,
    infoMessage,
    paginate,
    onChange,
    status: reviewStatus = null,
    renderStatusDetails,
  }: Props) => {
    const actions = useMemo(
      () =>
        reviewActions.map((reviewAction) => ({
          ...reviewAction,
          disabled: disabled || reviewAction.disabled,
          icon: undefined, // never set an icon
          level: 'secondary' as const, // always use a secondary level action
          status:
            reviewStatus === reviewAction.status
              ? (reviewStatus as StatusType)
              : undefined,
          onClick: () => onChange(reviewAction.status),
        })),
      [disabled, reviewActions, reviewStatus, onChange],
    );

    return (
      <Card mode="bordered">
        {children}
        <Layout align="center" direction="column" pt={py} spacing={py}>
          {actions.length > 0 && <ButtonGroup actions={actions} />}
          {reviewStatus !== null && renderStatusDetails?.(reviewStatus)}
          {paginate && (
            <Layout preset="buttons">
              <Paginate
                disabled={disabled}
                loadingTooltip={`Analyzing next document…`}
                mode="count"
                name="review card paginate"
                {...paginate}
              />
              {infoMessage && <Icon icon="info" tooltip={infoMessage} />}
              {paginate.nextAction && (
                <Actions
                  actions={[
                    {
                      ...paginate.nextAction,
                      level: 'secondary',
                    },
                  ]}
                />
              )}
            </Layout>
          )}
        </Layout>
      </Card>
    );
  },
);

const py = 6;
