import { pilot, rolodex } from '../services';

/**
 * @param {object} params
 * @param {string} params.client client/workspace
 * @param {string} params.userId user email
 * @returns {Promise<{msg: string}>}
 */
export const sendForgotPasswordEmail = (params) => {
  return pilot.post('login/forgot-password/', params);
};

export const sendInvitationEmail = (data) => {
  return pilot.post('login/admin-reset-password/', data);
};

export const changePassword = ({ slug, tokenType, token, formData }) => {
  if (!!slug && !!tokenType) {
    const url = `proxy/password?token=${token}&slug=${slug}&token_type=${tokenType}`;
    return rolodex.post(url, formData);
  } else {
    return pilot.post(`login/change-password/${token}/`, formData);
  }
};

export const activateUserAccount = ({ slug, tokenType, token, formData }) => {
  if (!!slug && !!tokenType) {
    const url = `proxy/password?token=${token}&slug=${slug}&token_type=${tokenType}`;
    return rolodex.post(url, formData);
  } else {
    return pilot.post(`login/activation/${token}/`, formData);
  }
};

export const updatePassword = ({ formData }) => {
  return pilot.put('/user/change-password/', formData);
};
