import { alphas } from './system/alphas';
import { palette } from './system/palette';

export const backgrounds = {
  background: 'white',
  'background.fade': alphas['darken-50'],
  'background.secondary': palette['gray-100'],
  'background.quiet': palette['gray-80'],
  'background.ai': 'rgba(243, 233, 251, 0.3)',
};
