import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { black2 } from '../../../assets/shared-styles/general';
import { SUCCESS, WARNING } from '../../../types/toast.types';
import {
  addDocumentsToGroup,
  addDocumentsToGroupByFolderId,
  addDocumentsToGroupBySearch,
  createDocumentGroup,
  createDocumentGroupByFolderId,
  createDocumentGroupBySearch,
  getAllDocumentGroups,
} from '../../DocumentGroupsPage/DocumentGroupsPage.data';
import EcButton from '../../Shared/EcButton';
import EcFilterInput from '../../Shared/EcFilterInput';
import EcModalCard from '../../Shared/EcModalCard';
import EcRadioButton from '../../Shared/EcRadioButton';
import EcRadioContainer from '../../Shared/EcRadioContainer';
import { showToast } from '../../Shared/EcToast';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import TreeIcon from '../../Shared/Icons/TreeIcon';
import styles from './DocumentGroupModal.styles';

class DocumentGroupModal extends Component {
  constructor(props) {
    super(props);

    this.onGroupAddDocument = this.onGroupAddDocument.bind(this);

    this.state = {
      selectedOption: 'existingGroup',
      filterValue: '',
      groupSearchResults: [],
      groupData: [],
      groupName: '',
      groupNote: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.loadDocumentGroupsData();
  }

  loadDocumentGroupsData() {
    this.setState({ loading: true });
    getAllDocumentGroups()
      .then((groupData) => {
        this.setState({ groupData: groupData.results });
      })
      .catch(() => {
        this.setState({ errorLoadingMessage: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleOptionSelection(selectedOption) {
    this.setState({ selectedOption });
  }

  handleGroupSearch(filterValue) {
    const { groupData } = this.state;
    const groupSearchResults = groupData.length
      ? groupData.filter((v) =>
          v.name.toLowerCase().includes(filterValue.toLowerCase()),
        )
      : [];
    this.setState({
      filterValue,
      groupSearchResults,
    });
  }

  handleInputChange(groupName) {
    this.setState({ groupName });
  }

  handleInputFocus(event) {
    event.target.select();
  }

  handleTextAreaChange(groupNote) {
    this.setState({ groupNote });
  }

  async handleAddDocumentToGroup(groupId) {
    const { hideModal, reloadDocumentContent, folderId } = this.props;
    const { documentIds, searchQuery } = this.props;

    const handleSuccess = (data) => {
      hideModal();
      showToast(
        SUCCESS,
        `Document(s) added to ${data.name} group successfully.`,
      );
      if (reloadDocumentContent) reloadDocumentContent();
    };
    const handleError = (err) => {
      hideModal();
      if (
        err.response &&
        err.response.data &&
        err.response.data.message &&
        err.response.status === 400
      ) {
        showToast(WARNING, `${err.response.data.message}`);
      } else {
        showToast(
          WARNING,
          'An error occurred while creating a new document group.',
        );
      }
    };

    try {
      let data;
      if (searchQuery) {
        data = await addDocumentsToGroupBySearch(groupId, searchQuery);
      } else if (folderId) {
        data = await addDocumentsToGroupByFolderId(groupId, folderId);
      } else {
        data = await addDocumentsToGroup(groupId, documentIds);
      }
      handleSuccess(data);
    } catch (e) {
      handleError(e);
    }
  }

  async onGroupAddDocument() {
    const {
      documentIds,
      hideModal,
      reloadDocumentContent,
      searchQuery,
      folderId,
    } = this.props;
    const { groupName, groupNote } = this.state;

    const handleSuccess = (data) => {
      showToast(
        SUCCESS,
        `${data.name} group has been created. Document(s) added to the group successfully.`,
      );
      if (reloadDocumentContent) reloadDocumentContent();
      hideModal();
    };
    const handleError = (err) => {
      hideModal();
      if (
        err.response &&
        err.response.data &&
        err.response.data.message &&
        err.response.status === 400
      ) {
        showToast(WARNING, `${err.response.data.message}`);
      } else {
        showToast(
          WARNING,
          'An error occurred while adding the document(s) to the group.',
        );
      }
    };

    try {
      let data;
      if (searchQuery) {
        data = await createDocumentGroupBySearch(
          groupName,
          groupNote,
          searchQuery,
        );
      } else if (folderId) {
        data = await createDocumentGroupByFolderId(
          groupName,
          groupNote,
          folderId,
        );
      } else {
        data = await createDocumentGroup({
          name: groupName,
          note: groupNote,
          documents: documentIds,
        });
      }
      handleSuccess(data);
    } catch (e) {
      handleError(e);
    }
  }

  renderAddToGroupOptions() {
    const { classes } = this.props;
    const { selectedOption } = this.state;

    return (
      <EcRadioContainer
        groupName="addToGroupOptions"
        onChange={({ selectedValue }) =>
          this.handleOptionSelection(selectedValue)
        }
        defaultValue={selectedOption}
      >
        {(groupName, selectedValue, onOptionChange) => (
          <div className={classes.radioContainerContent}>
            <EcRadioButton
              id="existingGroup"
              isInline={true}
              groupName="addToGroupOptions"
              value="existingGroup"
              label="Add to Existing Group"
              onChange={onOptionChange}
              selectedValue={selectedOption}
              fontSize="13px"
              labelColor={black2}
            />

            <EcRadioButton
              id="newGroup"
              isInline={true}
              groupName="addToGroupOptions"
              value="newGroup"
              label="New Group"
              onChange={onOptionChange}
              selectedValue={selectedOption}
              fontSize="13px"
              labelColor={black2}
            />
          </div>
        )}
      </EcRadioContainer>
    );
  }

  renderSearchBar() {
    const { filterValue, selectedOption, loading } = this.state;

    if (selectedOption === 'existingGroup') {
      return (
        <EcFilterInput
          id="search-input"
          value={filterValue}
          onChange={(filterValue) => this.handleGroupSearch(filterValue)}
          onClearSearch={() => this.handleGroupSearch('')}
          placeholder="Search for groups..."
          disabled={loading}
        />
      );
    } else return null;
  }

  renderGroupSearchResults() {
    const { classes } = this.props;
    const { groupSearchResults, filterValue } = this.state;

    if (!groupSearchResults.length && filterValue) {
      return (
        <div className={classes.searchResultsMessage}>
          There is no group that matches this name.
        </div>
      );
    } else if (filterValue) {
      return (
        <div className={classes.searchResultsContainer}>
          <div className={classes.searchResultsHeader}>GROUPS</div>
          {groupSearchResults.map((group) => (
            <button
              className={classes.searchList}
              key={group.groupId}
              onClick={() => this.handleAddDocumentToGroup(group.groupId)}
            >
              <TreeIcon blue size="18" />
              <span className={classes.searchGroupName}>{group.name}</span>
            </button>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }

  renderGroupNameInput() {
    const { classes } = this.props;
    const { groupName, groupNote } = this.state;

    return (
      <>
        <label
          className={classes.modalLabel}
          htmlFor="DocumentGroupModal/GroupName"
        >
          GROUP NAME
        </label>
        <input
          id="DocumentGroupModal/GroupName"
          className={classes.modalInput}
          value={groupName}
          onChange={(event) => {
            this.handleInputChange(event.target.value);
          }}
          onFocus={(event) => {
            this.handleInputFocus(event);
          }}
        />

        <div>
          <label
            className={classes.modalLabel}
            htmlFor="DocumentGroupModal/GroupNote"
          >
            NOTE
          </label>
          <textarea
            id="DocumentGroupModal/GroupNote"
            className={classes.modalTextArea}
            value={groupNote}
            onChange={(event) => {
              this.handleTextAreaChange(event.target.value);
            }}
          />
        </div>
      </>
    );
  }

  renderGroupInputByOption() {
    const { selectedOption } = this.state;

    if (selectedOption === 'existingGroup') {
      return (
        <>
          {this.renderSearchBar()}
          {this.renderGroupSearchResults()}
        </>
      );
    } else {
      return <>{this.renderGroupNameInput()}</>;
    }
  }

  render() {
    const { classes, title, hideModal } = this.props;
    const { selectedOption, groupName, loading } = this.state;

    return (
      <>
        <EcModalCard
          title={title}
          content={
            <div className={classes.modalBody}>
              <div className={classes.modalBodyContent}>
                {this.renderAddToGroupOptions()}
                {this.renderGroupInputByOption()}
              </div>
            </div>
          }
          footer={
            <>
              {selectedOption === 'existingGroup' ? (
                <EcButton white borderless text="Cancel" onClick={hideModal} />
              ) : (
                <>
                  <EcButton
                    white
                    borderless
                    text="Cancel"
                    onClick={hideModal}
                  />
                  <EcButton
                    disabled={!groupName}
                    yellow
                    text="Create Group"
                    onClick={this.onGroupAddDocument}
                  />
                </>
              )}
            </>
          }
          hideModal={hideModal}
        />
        {loading ? (
          <div
            data-testid="loading-container"
            className={classes.loadingContainer}
          >
            <LoadingSpinner size="medium" />
          </div>
        ) : null}
      </>
    );
  }
}

DocumentGroupModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  labelText: PropTypes.string,
  itemsParentFolderId: PropTypes.number,
  confirmButtonIcon: PropTypes.object,
  confirmButtonText: PropTypes.string,
  editItemName: PropTypes.func,
  createItemName: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
  searchQuery: PropTypes.array,
  folderId: PropTypes.number,
};

export default injectSheet(styles)(DocumentGroupModal);
