// TODO: Move selectors into ~/selectors, currently this is not possible due to the initializaion weaver issue
import { createSelector } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';
import { DocumentGroupSlice } from '~/redux/slices/documentGroup';
import { DocumentNavigationSlice } from '~/redux/slices/documentsNavigation';
import { DEPRECATED_Client } from '~/types';

export const selectDocument = createSelector(
  (root: any) => root[SliceType.DocumentsNavigation],
  (state: DocumentNavigationSlice) => state.currentDocument,
);

export const selectDocumentGroup = createSelector(
  (root: any) => root[SliceType.DocumentGroup],
  (state: DocumentGroupSlice) => state.group,
);

export const selectFolderTree = createSelector(
  (root: any) => root[SliceType.Client],
  (state: DEPRECATED_Client) => state.folderTree,
);

export const selectChatbotVisible = createSelector(
  (root: any) => root[SliceType.Chatbot],
  (state) => state.isVisible,
);
