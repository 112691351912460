import React, { memo } from 'react';

import { diffText } from '../../utils';
import { Box } from '../Box';

interface Props {
  /** Original text */
  text: string;
  /** New text to diff/compare */
  newText?: string;
}

export const DiffText = memo(({ text, newText = text }: Props) => {
  return (
    <Box styles={{ whiteSpace: 'pre-wrap' }}>
      {text === newText
        ? text
        : diffText(text, newText).map((diff, index) => (
            <Box
              key={index}
              as={diff.added ? 'ins' : diff.removed ? 'del' : 'span'}
            >
              {diff.value}
            </Box>
          ))}
    </Box>
  );
});
