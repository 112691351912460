export default `
<svg
  aria-hidden="true"
  aria-label="format-blockquote"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M19 13.25C19 15.3438 17.3125 17 15.25 17H15C14.4375 17 14 16.5625 14 16C14 15.4688 14.4375 15 15 15H15.25C16.1875 15 17 14.2188 17 13.25V13H15C13.875 13 13 12.125 13 11V9C13 7.90625 13.875 7 15 7H17C18.0938 7 19 7.90625 19 9V13.25ZM11 13.25C11 15.3438 9.3125 17 7.25 17H7C6.4375 17 6 16.5625 6 16C6 15.4688 6.4375 15 7 15H7.25C8.1875 15 9 14.2188 9 13.25V13H7C5.875 13 5 12.125 5 11V9C5 7.90625 5.875 7 7 7H9C10.0938 7 11 7.90625 11 9V13.25Z"
    fill="currentcolor"
  />
</svg>
`;
