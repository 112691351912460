import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { api, selectors } from '~/redux';
import search from '~/redux/slices/search';
import { MODAL_DELETE_SAVED_SEARCH } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { FlexLayout, Modal, Text } from '~/ui/';

const DeleteSavedSearchModal = () => {
  const modal = useSelector(selectors.selectSearchResultModal);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(search.actions.setModal());
  const { modalType, context } = modal || {};

  const [
    deleteSavedSearch,
    deleteSavedSearchResult,
  ] = api.endpoints.deleteSavedSearch.useMutation();
  const {
    isError: deleteSavedSearchError,
    isSuccess: deleteSavedSearchSuccess,
  } = deleteSavedSearchResult;

  const actionButton = {
    text: 'Delete',
    variant: 'red',
    promise: () => deleteSavedSearch(context.id),
  };

  useEffect(() => {
    if (deleteSavedSearchError) {
      showToast(ERROR, 'Delete saved search failed. Please try again.');
    }
  }, [deleteSavedSearchError]);

  useEffect(() => {
    if (deleteSavedSearchSuccess) {
      showToast(
        SUCCESS,
        `${context?.name} search has been successfully deleted.`,
      );
    }
  }, [deleteSavedSearchSuccess]);

  return (
    <Modal
      actionButton={actionButton}
      title="Delete Saved Search?"
      visible={modalType === MODAL_DELETE_SAVED_SEARCH}
      onCancel={hideModal}
      onHide={hideModal}
    >
      <FlexLayout flexDirection="column" space={4}>
        <Text variant="xs-dense">
          {`Are you sure you want to delete "${context?.name}" query? You won’t be able to undo this action.`}
        </Text>
      </FlexLayout>
    </Modal>
  );
};

export default DeleteSavedSearchModal;
