import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { Input } from '../../types';
import { Box } from '../Box';

interface Props extends Input<string> {
  /** Valid input types for text input (consider using a `SearchInput` directly instead) */
  type?: 'email' | 'password' | 'search' | 'text'; // TODO EKP-18796: `*Input` components should ideally just be implemented by a universal `Input` component (a wrapper around `HTMLInputElement`)
}

export const TextInput = memo((props: Props) => {
  const styles = useStyles();

  const { error, type = 'text', width, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.value);

  return (
    <Box
      {...props}
      as="input"
      styles={styles.input.container}
      styleProps={{ error, width }}
      type={type}
      onChange={handleChange}
    />
  );
});
