import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout } from '~/ui';

import { tabPropType } from '../Tabs/Tab';
import SidebarTabs from './SidebarTabs';

function Sidebar({ children, selectedTab, tabs, width = 'm', onSelectTab }) {
  const Content = tabs ? (
    <FlexLayout
      flexDirection="column"
      flex="1 1 auto"
      pb={5}
      space={3}
      sx={{ overflow: 'hidden' }}
    >
      <SidebarTabs
        selectedTab={selectedTab}
        tabs={tabs}
        onSelectTab={onSelectTab}
      />
      <Box sx={{ flex: '1 1 auto', overflow: 'auto' }}>{children}</Box>
    </FlexLayout>
  ) : (
    children
  );

  function getSidebarWidth(w) {
    if (w === 's') {
      return 'page-toolbar-width';
    } else if (w === 'm') {
      return 'page-sidebar-width';
    }
    return 'page-sidebar-toolbar-width';
  }

  return (
    <FlexLayout
      bg="white"
      flex="0 0 auto"
      sx={{
        borderRight: 'border',
        overflow: width === 's' ? 'none' : 'auto',
        width: getSidebarWidth(width),
      }}
    >
      {Content}
    </FlexLayout>
  );
}

Sidebar.propTypes = {
  /** Build a sidebar with supported Sidebar* components */
  children: PT.node,
  /** Selected tab key */
  selectedTab: PT.string,
  /** List of tab objects */
  tabs: PT.arrayOf(tabPropType.isRequired),
  /** Callback to set selected tab */
  onSelectTab: PT.func,
};

export default Sidebar;
