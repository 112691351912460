import React from 'react';

import { Select, types } from '~/eds';
import { Nullable } from '~/types';

import { DEFAULT_FIELD_VALUE } from '../../constants';
import { FieldClassificationValue } from '../../types';

interface Props {
  initialValue: Nullable<FieldClassificationValue>;
  isMulti: boolean;
  options: types.Option<string>[];
  value: Nullable<FieldClassificationValue>;
  onAddOption: (newOption: types.Option<string>) => void;
  onChange: (updatedValue: Nullable<FieldClassificationValue>) => void;
  readOnly?: boolean;
}

export const FieldClassificationGoldValue = ({
  initialValue,
  isMulti,
  options,
  readOnly,
  value: goldValue,
  onAddOption,
  onChange,
}: Props) => {
  const { value }: FieldClassificationValue =
    goldValue && goldValue.type !== 'unknown'
      ? goldValue
      : // Pre-populate selected gold values with prompt model values (for multi-value dropdowns only)
      isMulti && initialValue
      ? initialValue
      : goldValue ?? { ...(DEFAULT_FIELD_VALUE as FieldClassificationValue) };

  const handleChange = (updatedValue: Nullable<string | string[]>) => {
    onChange({
      highlights: goldValue?.highlights,
      type: 'classification',
      value: isMulti
        ? (updatedValue as Nullable<string[]>) ?? []
        : (updatedValue as Nullable<string>) === null
        ? []
        : [updatedValue as string],
    });
  };

  return (
    <Select<string, boolean>
      isMulti={isMulti}
      isSearchable
      name="correct-options-value"
      options={options}
      readOnly={readOnly}
      value={value}
      onAddOption={onAddOption}
      onChange={handleChange}
    />
  );
};
