import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '~/ui';

const Orientation = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
};

const defaultColor = 'gray-400';
const thickness = '1px';

function Divider({ orientation = Orientation.Horizontal, width }) {
  if (orientation === Orientation.Vertical) {
    return <Box bg={defaultColor} sx={{ width: thickness, height: '100%' }} />;
  }

  return (
    <Box
      bg={defaultColor}
      sx={{
        height: thickness,
        width: width ? `divider-width-${width}` : undefined,
      }}
    />
  );
}

Divider.propTypes = {
  /** Renders a horizontal (default) or vertical divider */
  orientation: PropTypes.oneOf([Orientation.Horizontal, Orientation.Vertical]),
  /** Renders with a fixed width */
  width: PropTypes.oneOf(['m']),
};

export default Divider;
