export const avatarSize = 220;
export const border = 20;
export const layoutSize = avatarSize + border * 2;

export const styles = {
  dropzone: {
    width: layoutSize,
    height: layoutSize,
  },
  avatarImg: {
    width: avatarSize,
    height: avatarSize,
  },
};
