export default `
<svg
  aria-hidden="true"
  aria-label="search"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M19.7578 18.7188L15.5703 14.5312C16.4766 13.4375 16.9766 12.0312 16.9766 10.5C16.9766 6.9375 14.0391 4 10.4766 4C6.88281 4 4.00781 6.9375 4.00781 10.5C4.00781 14.0938 6.91406 17 10.4766 17C11.9766 17 13.3828 16.5 14.5078 15.5938L18.6953 19.7812C18.8516 19.9375 19.0391 20 19.2578 20C19.4453 20 19.6328 19.9375 19.7578 19.7812C20.0703 19.5 20.0703 19.0312 19.7578 18.7188ZM5.50781 10.5C5.50781 7.75 7.72656 5.5 10.5078 5.5C13.2578 5.5 15.5078 7.75 15.5078 10.5C15.5078 13.2812 13.2578 15.5 10.5078 15.5C7.72656 15.5 5.50781 13.2812 5.50781 10.5Z"
    fill="currentcolor"
  />
</svg>
`;
