export default `
<svg
  aria-hidden="true"
  aria-label="arrow-right"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M13.25 6.20322L18.75 11.4532C18.9062 11.6095 19 11.797 19 12.0157C19 12.2032 18.9062 12.3907 18.75 12.547L13.25 17.797C12.9688 18.0782 12.4688 18.0782 12.1875 17.7657C11.9062 17.4845 11.9062 16.9845 12.2188 16.7032L16.375 12.7657H5.75C5.3125 12.7657 5 12.422 5 12.0157C5 11.5782 5.3125 11.2657 5.75 11.2657H16.375L12.2188 7.29697C11.9062 7.01572 11.9062 6.51572 12.1875 6.23447C12.4688 5.92197 12.9375 5.92197 13.25 6.20322Z"
    fill="currentcolor"
  />
</svg>
`;
