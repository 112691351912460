import { Filter } from '~/evifields';

import { AttemptStatus } from './types';

export const WEBHOOKS_DESCRIPTION_LENGTH = 10000;

export const DEFAULT_PAGE_SIZE = 500;

export const statusOptions: Record<AttemptStatus, string> = {
  published: 'SUCCESS',
  failed: 'FAILED',
  pending: 'PENDING',
};

export const getInitialDateValue = () => {
  const sevenDaysAgo = [
    {
      unit: 'days',
      value: 7,
    },
  ];

  return sevenDaysAgo;
};

export const initialFilters: Filter[] = [
  {
    id: 'endpointId',
    fieldId: 'endpointId',
    operatorId: 'contains_any',
    values: [],
    fieldType: 'text',
  },
  {
    id: 'endpointUrl',
    fieldId: 'endpointUrl',
    operatorId: 'contains_any',
    values: [],
    fieldType: 'text',
  },
  {
    id: 'eventType',
    fieldId: 'eventType',
    operatorId: 'contains_any',
    values: [],
    fieldType: 'text',
  },
  {
    id: 'status',
    fieldId: 'status',
    operatorId: 'contains_any',
    values: [],
    fieldType: 'text',
  },
  {
    id: 'datetime',
    fieldId: 'datetime',
    operatorId: 'date_in_the_last',
    values: getInitialDateValue(),
    fieldType: 'date',
  },
];

export const statusTypeOptions = Object.entries(
  statusOptions,
).map(([value, label]) => ({ value, label }));
