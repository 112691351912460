import {
  black2,
  black3,
  bold,
  evisortBlue,
  evisortYellow,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  alertsFiltersForm: {
    display: (props) => (props.collapsed ? 'none' : null),
    width: '280px',
  },
  filterFormTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',
    color: black2,
  },
  formTitle: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'OatmealPro',
    fontSize: '21px',
    fontWeight: bold,
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      marginLeft: '8px',
      backgroundColor: evisortYellow,
      fontSize: '12px',
    },
  },
  formTitleMeta: {
    display: 'flex',
    alignItems: 'center',
  },
  resultsMeta: {
    fontSize: '13px',
  },
  resetMeta: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    color: evisortBlue,
    cursor: 'pointer',
    backgroundColor: 'white',
    '& svg': {
      marginRight: '4px',
    },
  },
  filterFormFields: {
    position: 'relative',
  },
  formField: {
    '&:not(:last-child)': {
      marginBottom: '24px',
    },
  },
  fieldTitle: {
    marginBottom: '8px',
    color: black2,
    fontSize: '12px',
    fontWeight: semibold,
    textTransform: 'uppercase',
  },
  fieldBody: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  fieldDatepicker: {
    '& input': {
      width: '125px',
    },
  },
  departmentsPlaceholder: {
    margin: '8px 0 16px 0',
    color: black3,
    fontSize: '15px',
  },
  fieldActions: {
    display: 'inline-block',
    marginTop: '6px',
  },
  fieldAction: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
    backgroundColor: 'white',
    cursor: 'pointer',
    '& svg': {
      marginRight: '4px',
    },
  },
  datesSeparator: {
    margin: '0 8px',
    color: black3,
    fontSize: '14px',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(249, 251, 252, 0.85)',
    zIndex: 1,
  },
  filtersErrorContainer: {
    textAlign: 'center',
  },
  filtersErrorMessage: {
    marginBottom: '8px',
    color: black2,
    fontSize: '17px',
  },
  filtersErrorAction: {
    display: 'inline-block',
    color: evisortBlue,
    cursor: 'pointer',
  },
};

export default styles;
