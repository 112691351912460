export enum EntityType {
  // everest= keep in sync with everest/persistence/enums.py
  AllDocuments = 'all_documents',
  Risk = 'risk',
  Role = 'role',
  Ticket = 'ticket',
  TicketDocumentVersion = 'ticket_document_version',
  TicketJudgment = 'ticket judgment',
  // pilot
  Department = 'department',
  Field = 'field',
  User = 'user',
  Document = 'document',
  DocumentVersion = 'document_version',
  DocumentHandler = 'document_handler',
  EditorHighlight = 'editor_highlight',
  Highlight = 'highlight',
}
