import Tippy from '@tippyjs/react';
import { noop } from 'lodash';
import React, { ComponentProps, memo, useEffect, useRef } from 'react';
import { Placement } from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import { TIPPY_PRESETS as presets } from '../../constants';
import { useToggle } from '../../hooks';
import { plugins } from './plugins';

type Position = 'absolute' | 'fixed';
interface Props {
  // Node to append popover to (e.g. document.body)
  appendTo?: Element | 'parent';
  /** Popover contents */
  children?: React.ReactChild;
  /** Popover presets */
  preset?: keyof typeof presets;
  /** If the popover can be interacted (i.e. supports pointer events) */
  isInteractive?: boolean;
  /** Controlled visibility state */
  isVisible?: boolean;
  /** Sets the position attribute */
  position?: Position;
  /** Popover trigger element */
  trigger?: React.ReactElement;
  /** Optional styles assigned on the trigger node */
  triggerStyle?: React.CSSProperties;
  /** Maximum tooltip width (limited configurations) */
  maxWidth?: number | string;
  /** Optional styles assigned on the container node */
  containerStyle?: React.CSSProperties;
  /** placement of the popover. */
  placement?: Placement;
  /** distance between the trigger and the Popover */
  offset?: [number, number];
  /** Callback when dropdown is click-defocused */
  onClickOutside?: () => void;
  /** Callback when popover is hidden */
  onHide?: () => void;
  /** Callback when popover is shown */
  onShow?: () => void;
}

export const Popover = memo(
  ({
    appendTo,
    children,
    isInteractive = true,
    isVisible,
    offset,
    preset = 'popover',
    placement: placementProp,
    position = 'fixed',
    trigger,
    triggerStyle = {},
    containerStyle = {},
    maxWidth,
    onClickOutside,
    onHide = noop,
    onShow = noop,
  }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [_toggledValue, _toggle, hide, show] = useToggle();

    useEffect(() => {
      if (isVisible && ref.current) {
        ref.current.focus();
      }
    }, [isVisible]);

    const handleClickOutside = () => {
      onClickOutside?.();
    };

    const handleHide = () => {
      // Tippy does not remove blur on interactive elements.  See https://github.com/atomiks/tippyjs/issues/72#issuecomment-313588293
      // TODO: Investigate this more to resolve the issue with inputs losing focus when we hover over an interactive tooltip.
      // * This runs everytime even when an input is the active element. The input will be blured and lose focus.
      if (isInteractive) {
        const activeElement = document.activeElement as HTMLElement;
        activeElement.blur();
      }

      hide();
      onHide?.();
    };

    const handleShow = () => {
      show();
      onShow?.();
    };

    const { className, tippyProps } = presets[preset];
    const placement: Placement | undefined =
      placementProp ?? (tippyProps as ComponentProps<typeof Tippy>).placement;
    return (
      <div style={containerStyle}>
        <Tippy
          {...tippyProps}
          trigger={isVisible === undefined ? tippyProps.trigger : undefined}
          interactive={isInteractive}
          animation={false}
          appendTo={appendTo}
          className={['eds-popover', className].join(' ')}
          content={
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <div ref={ref} role="alertdialog" tabIndex={0}>
              {children}
            </div>
          }
          maxWidth={maxWidth}
          offset={offset}
          popperOptions={getPopperOptions({ position })}
          visible={isVisible}
          plugins={plugins}
          onClickOutside={handleClickOutside}
          onHide={handleHide}
          onShow={handleShow}
          placement={placement}
        >
          <span
            style={{
              ...componentStyles.trigger,
              ...triggerStyle,
            }}
          >
            {trigger}
          </span>
        </Tippy>
      </div>
    );
  },
);

const getPopperOptions = (config: { position: Position }) => ({
  strategy: config.position, // tippy does not expose PositioningStrategy type
});

export const componentStyles = {
  trigger: {
    cursor: 'pointer',
    display: 'inline-flex',
  },
};
