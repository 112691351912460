import { trimStart } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import {
  getInternalFolders,
  getProviderFolders,
  getRemoteFolders,
} from '~/api';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { Button, Checkbox, Icon, TextInput } from '~/eds';
import { ManageSyncPairStageType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import {
  Divider,
  FlexLayout,
  FolderInput,
  InputLabel,
  Link,
  MultiSelect,
  Text,
  Tooltip,
} from '~/ui';
import { debounce } from '~/utils/helper.utils';

import { DEFAULT_FILE_TYPES } from '../../constants';
import { testIsFileTypesEqual, testIsValidSyncPair } from '../../util';
import Footer from '../Footer';

function Folders({
  // connected,
  activeSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const {
    allowedSyncFileTypes,
    evisortFolder,
    name,
    path,
    provider,
    providerFolder,
    tokenId,
  } = activeSyncPair;

  const enableSharepointSyncSiteSearch = useFlag(
    FlagType.SharepointSyncSiteSearch,
  );
  const enableSkipEmptyFolders = useFlag(FlagType.SkipEmptyFolders);

  const [providerFolders, setProviderFolders] = useState([]);
  const [search, setSearchTerm] = useState([]);

  const fetchProviderSubfolders = async (folder) => {
    return await getRemoteFolders({
      path: folder.path,
      provider,
      providerOptions: folder.providerOptions,
      providerPrefix: folder.providerPrefix,
      tokenId,
    });
  };

  const { isLoading: isLoadingProviderFolders } = useAsync(
    getProviderFolders,
    {
      path,
      provider,
      tokenId,
      search,
    },
    {
      condition: true,
      deps: [provider, search],
      successHandler: setProviderFolders,
      errorToastMessage:
        'There is an error fetching remote folders, please try refreshing the page.',
    },
  );

  const {
    isLoading: isLoadingInternalFolders,
    response: internalFolders,
  } = useAsync(
    getInternalFolders,
    {},
    {
      condition: true,
      errorToastMessage:
        'There is an error fetching Evisort folders, please try refreshing the page.',
    },
  );

  const fileTypeOptions = allowedSyncFileTypes.map((fileExtensionType) => {
    const option = {
      value: fileExtensionType,
      label: trimStart(fileExtensionType, '.'),
    };
    if (DEFAULT_FILE_TYPES.includes(fileExtensionType)) {
      option.isDefault = true;
    }
    return option;
  });

  const handleNext = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.Summary);
  };

  const handleBack = () => {
    // remove tokenId, state, code to get a new auth token when going back
    const {
      tokenId: _tokenId,
      state: _state,
      code: _code,
      ...activeSyncPairData
    } = activeSyncPair;
    integrationsSetActiveSyncPair(activeSyncPairData);
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.Authentication);
  };

  function handleSkipEmptyFolders() {
    const skipEmptyFolders = !activeSyncPair?.skipEmptyFolders;
    integrationsSetActiveSyncPair({ ...activeSyncPair, skipEmptyFolders });
  }

  const setAllowedSyncFileTypes = (types) => {
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      allowedSyncFileTypes: types.map((type) => '.' + trimStart(type, '.')),
    });
  };

  const onSelectInternalFolder = (folder) => {
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      folderId: folder.folderId,
      evisortFolder: folder,
    });
  };

  const onSelectProviderFolder = (folder) => {
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      providerFolder: folder,
      providerPrefix: folder.providerPrefix,
      providerFolderPath: folder.path,
      providerOptions: {
        ...folder.providerOptions,
        _folder_id: folder.id,
      },
    });
  };

  const isFileTypesEqual = testIsFileTypesEqual(allowedSyncFileTypes);

  const errors = {};

  const emptyProviderFolderModalContent = (
    <FlexLayout alignItems="center" flexDirection="column">
      <Text color="gray-600" variant="xs-dense">
        You do not have any folders in your account.
      </Text>
      <Text color="gray-600" variant="xs-dense">
        Please{' '}
        <Link variant="xs-dense" onClick={handleBack}>
          re-try and reauthenticate
        </Link>{' '}
        your account
      </Text>
    </FlexLayout>
  );

  if (allowedSyncFileTypes.length === 0) {
    errors.allowedSyncFileTypes =
      'You have not added any file types. Please include at least one file type';
  }

  const searchUpdate = debounce((text) => {
    integrationsSetActiveSyncPair({
      ...activeSyncPair,
      folderId: null,
    });
    setSearchTerm(text);
  }, 500);

  const sharepointSearchDescription =
    'If the Sharepoint site you are looking for cannot be found in the folder list above, enter a search term to narrow down the list of sites.';

  const processableDocumentTypes = useFlag(FlagType.ProcessableDocumentTypes);

  return (
    <FlexLayout alignItems="stretch" flexDirection="column" space={8}>
      <FlexLayout
        alignItems="center"
        py={2}
        space={6}
        sx={{ alignSelf: 'center' }}
      >
        <ProviderLogo provider={provider} />
        <Text color="gray-500" variant="2xl-spaced-bold">
          ⇄
        </Text>
        <ProviderLogo provider="evisort" />
      </FlexLayout>
      <InputLabel isRequired label={`${name} folder`}>
        <FolderInput
          folder={providerFolder}
          folderTreeProps={{
            emptyContentMessage: emptyProviderFolderModalContent,
            folders: providerFolders,
            label: `${name} Folder Tree`,
            asyncFetchSubfolders: fetchProviderSubfolders,
          }}
          id={OnboardingIdType.SelectProviderFolder}
          isLoading={isLoadingProviderFolders}
          modalTitle={`Select a ${name} folder`}
          onChange={onSelectProviderFolder}
        />
        {enableSkipEmptyFolders && (
          <FlexLayout flexDirection="row">
            <Checkbox
              option={{
                value: 'skip-empty-folders',
                label: 'Skip empty folders',
              }}
              value={!!activeSyncPair?.skipEmptyFolders}
              onChange={handleSkipEmptyFolders}
            />
            <Tooltip
              content="Skipping empty folders may speed up initial synchronization time."
              placement="bottom"
            >
              <Icon icon="info" />
            </Tooltip>
          </FlexLayout>
        )}
      </InputLabel>
      {provider === 'SHARE_POINT' && enableSharepointSyncSiteSearch && (
        <InputLabel
          hidden={provider !== 'SHARE_POINT'}
          label="Sharepoint site search term"
          description={sharepointSearchDescription}
        >
          <TextInput
            id="sharepoint-site-search"
            placeholder="Enter a sharepoint site search term"
            onChange={searchUpdate}
            width="fullWidth"
          />
        </InputLabel>
      )}
      <InputLabel isRequired label="Evisort folder">
        <FolderInput
          folderTreeProps={{
            folders: internalFolders,
            label: 'Evisort Folder Tree',
          }}
          folder={evisortFolder}
          id={OnboardingIdType.SelectEvisortFolder}
          isLoading={isLoadingInternalFolders}
          modalTitle="Select an Evisort folder"
          onChange={onSelectInternalFolder}
        />
      </InputLabel>
      <Divider />
      <FlexLayout
        alignItems="flex-end"
        id={OnboardingIdType.SelectFileTypes}
        space={8}
      >
        <InputLabel
          isRequired
          description={
            <span>
              Selected file types will show in your Evisort folders, but only{' '}
              <strong>
                {Array.isArray(processableDocumentTypes) &&
                processableDocumentTypes.length > 0
                  ? processableDocumentTypes.join(', ') + ' '
                  : '.pdf, .docx, .doc '}
              </strong>
              will be processed by Evisort’s AI.
            </span>
          }
          label="Synchronize the following file types"
        >
          <MultiSelect
            error={errors.allowedSyncFileTypes}
            noOptionsMessage="Enter an extension for a file type you want to add"
            options={fileTypeOptions}
            placeholder="Add a file extension"
            values={allowedSyncFileTypes}
            width="fullWidth"
            formatCreateLabel={(label) => `Add custom .${label} file type`}
            onAddOption={(x) => x}
            onChange={setAllowedSyncFileTypes}
          />
        </InputLabel>
        <FlexLayout flex="none">
          <Tooltip
            content={isFileTypesEqual ? null : DEFAULT_FILE_TYPES.join(', ')}
          >
            <Button
              disabled={isFileTypesEqual}
              text="Reset to defaults"
              variant="secondary"
              onClick={() => setAllowedSyncFileTypes(DEFAULT_FILE_TYPES)}
            />
          </Tooltip>
        </FlexLayout>
      </FlexLayout>
      <Footer
        leftAction={{
          text: 'Reauthenticate',
          onClick: handleBack,
        }}
        rightAction={{
          disabled: !testIsValidSyncPair(activeSyncPair),
          iconRight: 'arrowRight',
          text: 'Next',
          onClick: handleNext,
        }}
      />
    </FlexLayout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(Folders);
