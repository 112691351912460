import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { SharedChipProps } from '../../types';
import { resolvePresetProps } from '../../utils';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Layout } from '../Layout';
import { LoadingShimmer } from '../LoadingShimmer';
import { TruncateText } from '../Text';
import { Tooltip } from '../Tooltip';
import { presets } from './presets';

export const Chip = memo((props: SharedChipProps) => {
  const styles = useStyles();
  const {
    disabled,
    icon,
    isLoading,
    mode,
    status = 'info',
    text,
    tooltip,
    width = 'min-content',
    onRemove,
  } = resolvePresetProps(props, presets);

  const isTagMode = mode === 'tag';

  const border = isTagMode ? undefined : 'border';
  const borderRadius = isTagMode ? 'm' : 'pill';

  const content = isLoading ? (
    <LoadingShimmer height="calc(100% - 8px)" width="100%" />
  ) : (
    <>
      {icon && <Icon icon={icon} ml={-1} />}
      <TruncateText variant="tiny" whiteSpace="nowrap">
        {text}
      </TruncateText>
      {onRemove && (
        <Box mr={-1}>
          <IconButton icon="x" size="s" tooltip="Remove" onClick={onRemove} />
        </Box>
      )}
    </>
  );

  return (
    <Tooltip tooltip={tooltip}>
      <Layout
        role="listitem"
        align="center"
        border={border}
        borderRadius={borderRadius}
        disabled={disabled}
        spacing={1}
        styles={[componentStyles.base, styles.colors.status]}
        styleProps={{
          boxSizing: onRemove ? 'content-box' : 'border-box',
          status,
          width,
        }}
      >
        {content}
      </Layout>
    </Tooltip>
  );
});

export const componentStyles = {
  base: ({
    boxSizing,
    width,
  }: {
    boxSizing: 'border-box' | 'content-box';
    width: string;
  }) => ({
    border: 'none',
    boxSizing,
    height: 'height.s',
    maxWidth:
      boxSizing === 'content-box' ? 'chip.max-width' : 'chip.auto.max-width',
    outline: 'none',
    paddingLeft: 3,
    paddingRight: 3,
    width,
  }),
};
