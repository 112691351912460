import {
  DocumentEditorContainerComponent,
  internalZoomFactorChange,
} from '@syncfusion/ej2-react-documenteditor';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import AuthenticationStore from '~/auth';
import { isDev, isTest } from '~/dev';
import { Box, getUserName, useToast } from '~/eds';
import { BaseDocumentViewerProps } from '~/features/document-viewer/types';
import {
  useCurrentUser,
  useRefreshTokenWithInterval,
  useStateAndRef,
} from '~/hooks';
import Configuration from '~/services/configuration';
import { Nullable } from '~/types';
import { captureException } from '~/utils';

import {
  DOCUMENT_EDITOR_CONTAINER_ID,
  EDITOR_SIZE_ADJUSTMENT_INTERVAL,
} from './constants';
import { useDocumentEditorContext } from './DocumentEditorContext';
import { Highlight, IHighlight } from './Highlight';
import './styles';

interface DocumentViewerProps
  extends BaseDocumentViewerProps<{ type: string }> {
  /**
   * The file that will be loaded in the document editor
   */
  file: File;
  /**
   * width of the document editor
   */
  width: string;
  // /**
  //  * the highlights to be added to the document
  //  */
  // highlights?: DocumentEditorHighlight[];
  /** function to resolve the document content, must be passed to generate source and clause highlights */
  contentResolver?: () => { words: { word: string; id: Nullable<number> }[] };
  /**
   * callback to be called before loading a document
   *
   * @param sfdt the loaded document sfdt
   */
  onBeforeDocumentLoad?: () => void;
  /**
   * callback to be called after loading a document
   *
   * @param sfdt the loaded document sfdt
   */
  onDocumentLoad?: (sfdt: any) => void;
}

export const DocumentViewer = memo(
  ({
    file,
    highlights = [],
    width,
    contentResolver,
    onBeforeDocumentLoad,
    onDocumentLoad,
  }: DocumentViewerProps) => {
    const documentEditorRef = useRef<DocumentEditorContainerComponent | null>();
    const {
      isDocumentReady,
      viewerClientWidth,
      zoomFactor,
      parseHighlights,
      setIsLoading,
      setDocumentEditorContainer,
      setIsDocumentReady,
      setZoomFactor,
    } = useDocumentEditorContext();
    const statusBarRef = useRef<HTMLElement>();
    const [isEditorReady, setIsEditorReady] = useState(false);
    const currentUser = useCurrentUser();

    const [editorHeight, setEditorHeight, editorHeightRef] = useStateAndRef(0);
    const { toast } = useToast();
    // this will update the token in case of inactivity
    useRefreshTokenWithInterval();

    const setupHeaders = (args: any) => {
      const AS = AuthenticationStore();
      const token = AS.getAccessToken();
      if (token) {
        args.headers = [{ Authorization: `Bearer ${token}` }];
      } else {
        args.withCredentials = true;
      }
    };

    const onLoad = async () => {
      setIsDocumentReady(false);
      const { type } = file;
      const documentEditor = documentEditorRef.current?.documentEditor;
      let content = '';
      switch (type) {
        case 'application/json':
        case 'application/sfdt':
          content = await file.text();
          break;
        default:
          throw new Error('Unsuported file type');
      }
      if (documentEditor) {
        try {
          onBeforeDocumentLoad?.();
          documentEditor.open(content);
          setIsDocumentReady(true);
          onDocumentLoad?.(JSON.parse(documentEditor.serialize() || ''));
        } catch (e) {
          captureException('An error occurred when loading the document.', e, {
            section: 'ej2 document editor',
          });
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    };

    const cleanup = () => {
      // we need to destroy it since it's not a direct child of the editor.
      // similar issue related in syncfusion https://www.syncfusion.com/forums/169679/failed-to-execute-removechild-on-node-the-node-to-be-removed-is-not-a-child-of-this-node
      // please, do not change the order
      statusBarRef.current?.remove();
      documentEditorRef.current?.documentEditor?.documentHelper?.viewerContainer?.remove();
      setIsDocumentReady(false);
    };

    useEffect(() => {
      if (isEditorReady && file) {
        setIsLoading(true);
        onLoad();
      }
    }, [isEditorReady, file]);

    const onInit = (scope: DocumentEditorContainerComponent) => {
      if (scope) {
        // really helpful when debugging.
        if (isDev || isTest) {
          window.documentEditor = scope;
        }
        if (!documentEditorRef.current) {
          documentEditorRef.current = scope;
          // adds a listener to the zoom factor change
          documentEditorRef.current.documentEditor.on(
            internalZoomFactorChange,
            () =>
              setZoomFactor(
                documentEditorRef.current?.documentEditor?.zoomFactor || 1,
              ),
          );
          setDocumentEditorContainer(scope);
          setIsEditorReady(true);
        }
      }
    };

    // EJ2 has some issues with the height of the container, so we need to adjust it manually
    useEffect(() => {
      const container = document.getElementById(DOCUMENT_EDITOR_CONTAINER_ID);
      if (container && isDocumentReady) {
        const internval = setInterval(() => {
          const { y } = container.getBoundingClientRect();
          const fixedHeight = window.innerHeight - y;
          if (fixedHeight !== editorHeightRef.current) {
            setEditorHeight(fixedHeight);
          }
        }, EDITOR_SIZE_ADJUSTMENT_INTERVAL);

        return () => clearInterval(internval);
      }
    }, [isDocumentReady]);

    const formattedHighlights = useMemo(() => {
      if (!documentEditorRef.current?.documentEditor && !isDocumentReady) {
        return [];
      }
      const documentContent = contentResolver?.();
      const extractedHighlights: IHighlight[] = parseHighlights(
        documentEditorRef.current!.documentEditor,
        highlights,
        documentContent,
      );
      if (extractedHighlights.length !== highlights.length) {
        toast({
          message: 'Some highlights could not be found in the document.',
          status: 'danger',
        });
      }
      return extractedHighlights;
    }, [highlights, viewerClientWidth, zoomFactor, isDocumentReady]);

    /**
     * Creates the portal where the highlights will be rendered
     */
    const renderHighlightsPortal = () => {
      if (documentEditorRef.current) {
        const highlightComponents = formattedHighlights.map(
          (highlight, index) => (
            <Highlight
              highlightRects={highlight.rects}
              variant={highlight.variant}
              isActive={highlight.isVisible}
              key={`${highlight.variant}_${index}`}
            />
          ),
        );
        return createPortal(
          highlightComponents,
          documentEditorRef.current.documentEditor.documentHelper
            .viewerContainer,
        );
      }
      return null;
    };

    return (
      <Box
        // these styles were needed to adjust the screen after adding the button in the statusBar of EJ2
        id={DOCUMENT_EDITOR_CONTAINER_ID}
        h="100%"
        styles={{
          // this hides the commentsReviewPanel
          '& .e-de-review-pane': {
            display: 'none',
            width: '0px',
          },
        }}
      >
        {/**
         * do not change this rendering order. Portal should always come first
         * https://github.com/facebook/react/issues/14811#issuecomment-518430470
         */}
        {isDocumentReady && renderHighlightsPortal()}
        <DocumentEditorContainerComponent
          destroyed={cleanup}
          currentUser={getUserName(currentUser)}
          ref={onInit}
          width={width}
          enableToolbar={false}
          height={editorHeight ? `${editorHeight}px` : '100%'}
          toolbarItems={[]}
          restrictEditing={true}
          readOnly={true}
          serviceUrl={`${Configuration.museEndpoint}/document/`}
          serverActionSettings={{ systemClipboard: 'content-to-sfdt' }}
          beforeXmlHttpRequestSend={setupHeaders}
          enableTrackChanges={true}
          enableComment={false}
          documentEditorSettings={{
            optimizeSfdt: false,
            searchHighlightColor: '#FFFF00',
          }}
        />
      </Box>
    );
  },
);
