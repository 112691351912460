import React, { useEffect, useState } from 'react';

import DisabledFeatureAlert from '~/components/Shared/DisabledFeatureAlert';
import { showToast } from '~/components/Shared/EcToast';
import UnpurchasedMsg from '~/components/Shared/UnpurchasedMsg';
import { Button } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { withCurrentUser } from '~/hocs';
import { api } from '~/redux';
import * as toastTypes from '~/types/toast.types';
import {
  Alert,
  Box,
  CardList,
  FlexLayout,
  Link,
  LoadingContainer,
  Text,
  TextInput,
} from '~/ui';
import evisortLogoSrc from '~/ui/assets/images/logo-evisort.svg';
import { copyToClipboard } from '~/utils/helper.utils';
import { featureFlagIncluded } from '~/utils/user';

import ApiKeysTable from './ApiKeysTable';
import IntegrationDetailCard from './IntegrationDetailCard';

const errorToastMessage = 'Failed to load API keys. Please try again.';
const successToastMessage = 'New API key generated.';

function ApiManagement({
  // injected
  currentUser,
}) {
  const isFeatureEnabled = featureFlagIncluded(
    currentUser,
    FeatureFlagType.ExternalApi,
  );

  const [createdApiKey, setCreatedApiKey] = useState(null);

  const result = api.endpoints.getApiKeys.useQuery(undefined, {
    skip: !isFeatureEnabled,
  });
  const { data: apiKeys = [], isError, isLoading } = result;

  useEffect(() => {
    if (isError) {
      showToast(toastTypes.ERROR, errorToastMessage);
    }
  }, [isError]);

  const [executor, createResult] = api.endpoints.createApiKey.useMutation();
  const { data: newKey, isLoading: isGeneratingKey, isSuccess } = createResult;

  useEffect(() => {
    if (isSuccess && newKey) {
      showToast(toastTypes.SUCCESS, successToastMessage);
      setCreatedApiKey(newKey);
    }
  }, [isSuccess, newKey]);

  if (!isFeatureEnabled) {
    return <DisabledFeatureAlert renderUnpurchasedMsg={UnpurchasedMsg} />;
  }

  const existingKey = apiKeys.find(
    (key) => key.user_id === currentUser.id && key.is_enabled,
  );

  return (
    <CardList title="API Management">
      <LoadingContainer isLoading={isLoading}>
        <IntegrationDetailCard
          title="Evisort API Keys"
          logoSrc={evisortLogoSrc}
        >
          <FlexLayout flexDirection="column" space={12}>
            <FlexLayout flexDirection="column" space={6}>
              {createdApiKey ? (
                <FlexLayout flexDirection="column" space={4}>
                  <Text color="gray-800" variant="s-dense-bold">
                    Copy your API key and treat it like a password. For your
                    security we won’t show it again!
                  </Text>
                  <FlexLayout>
                    <Box sx={{ width: '752px' }}>
                      <TextInput
                        disabled
                        placeholder={createdApiKey.raw_key_nonce}
                        width="fullWidth"
                        onChange={() => {}}
                      />
                    </Box>
                    <Button
                      text="Copy"
                      onClick={() =>
                        copyToClipboard(
                          createdApiKey.raw_key_nonce,
                          'API key copied to clipboard.',
                        )
                      }
                    />
                  </FlexLayout>
                </FlexLayout>
              ) : (
                <Text
                  color="gray-800"
                  variant="s-dense"
                  sx={{ width: '835px' }}
                >
                  Need access to Evisort outside of our platform? Our API allows
                  you to upload/download documents, extract metadata, train
                  models, search and{' '}
                  <Link href="https://developers.evisort.com">much more</Link>.
                  Create an API key below to start building your Evisort
                  integration.
                </Text>
              )}
              <FlexLayout
                flexDirection="column"
                alignItems="flex-start"
                space={3}
              >
                {!!existingKey && (
                  <Alert enableIcon variant="warning">
                    <Text color="gray-800" variant="xs-dense-medium">
                      You’ve already created an API token (
                      {existingKey.name || 'Untitled API Key'}
                      ). To create an additional key, first disable the
                      currently active one.
                    </Text>
                  </Alert>
                )}
                <Button
                  disabled={!!existingKey}
                  isLoading={isGeneratingKey}
                  text="Generate new API key"
                  onClick={executor}
                />
              </FlexLayout>
            </FlexLayout>
            {!!apiKeys.length && (
              <ApiKeysTable
                currentUser={currentUser}
                keys={apiKeys}
                newKeyId={createdApiKey?.id}
              />
            )}
          </FlexLayout>
        </IntegrationDetailCard>
      </LoadingContainer>
    </CardList>
  );
}

export default withCurrentUser(ApiManagement);
