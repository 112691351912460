import React, { useEffect, useMemo, useState } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import {
  Box,
  Button,
  Divider,
  Label,
  Layout,
  Select,
  TextArea,
  useToggle,
} from '~/eds';
import { useCurrentUser } from '~/hooks';
import { Nullable, Option } from '~/types';

import {
  ClauseOperatorId,
  TextSearch,
  toTextSearchOption,
  toTextSearchOptionLookup,
  toTextSearchPair,
} from '../../../../evifields';
import { ClauseValue, Field, Filter } from '../types';

interface Props {
  field: Field<'clause'>;
  filter: Filter<ClauseValue>;
  onChange: (updatedValues: ClauseValue[]) => void;
}

export const ClauseValues = ({ field, filter, onChange }: Props) => {
  const { settings } = field;
  const { clauses } = settings;
  const { id, values, operatorId } = filter;
  const [clauseValue] = values;
  const textSearch = clauseValue?.text_search[0] || null;
  const provisionValue = clauseValue?.provision || null;
  const hasProvision = Boolean(provisionValue);
  const isContainsAny = operatorId === 'contains_any';
  const shouldEnableSearchWithClause = hasProvision && isContainsAny;
  const user = useCurrentUser();

  useEffect(() => {
    if (!isContainsAny) {
      handleCancelClauseSearch();
    }
  }, [isContainsAny]);

  const [draftClauseOperatorId, setDraftClauseOperatorId] = useState<
    Nullable<ClauseOperatorId>
  >(null);
  const [draftClauseText, setDraftClauseText] = useState<Nullable<string>>(
    null,
  );

  const [
    shouldSearchWithinClause,
    _toggleSearchWithinClause,
    enableSearchWithinClause,
    disableSearchWithinClause,
  ] = useToggle(false);

  useEffect(() => {
    resetDraft(textSearch);
  }, [textSearch]);

  // reset draft state based on textSearch derived from prop.
  const resetDraft = (textSearch: Nullable<TextSearch>) => {
    let updatedDraftClauseOperatorId = null;
    let updatedDraftClauseText = null;
    if (textSearch) {
      updatedDraftClauseOperatorId = toTextSearchOption(textSearch).value;
      updatedDraftClauseText = textSearch.text;
    }

    setDraftClauseOperatorId(updatedDraftClauseOperatorId);
    setDraftClauseText(updatedDraftClauseText);
  };

  const handleClauseProvisionChange = (
    updatedClauseProvision: Nullable<string>,
  ) => {
    if (updatedClauseProvision !== null) {
      const updatedClause = {
        provision: updatedClauseProvision,
        text_search: [],
      };
      onChange([updatedClause]);
    }
  };

  const handleCancelClauseSearch = () => {
    trackSegment('Clause Type - Cancel', {
      user_id: user.id,
      client_id: user.client,
    });
    resetDraft(textSearch);
    disableSearchWithinClause();
  };

  const handleConfirmClauseSearch = () => {
    trackSegment('Clause Type - Confirm', {
      user_id: user.id,
      client_id: user.client,
    });

    let updatedTextSearch: TextSearch[] = [];
    if (draftClauseOperatorId) {
      const [contains, scope] = toTextSearchPair(draftClauseOperatorId);
      updatedTextSearch = [{ contains, scope, text: draftClauseText || '' }];
    }
    const updatedClauseValues: ClauseValue[] = provisionValue
      ? [
          {
            ...clauseValue,
            provision: provisionValue,
            text_search: updatedTextSearch,
          },
        ]
      : [];

    onChange(updatedClauseValues);
    disableSearchWithinClause();
  };

  const options = useMemo(
    () => clauses.map((clause) => ({ value: clause, label: clause })),
    [clauses],
  );

  const clauseContent = shouldSearchWithinClause ? (
    <Layout direction="column" px={px} spacing={2}>
      <Label>{provisionValue}</Label>
      <ClauseOperatorSelect
        value={draftClauseOperatorId}
        onChange={setDraftClauseOperatorId}
      />
      <TextArea
        name="clause-text"
        value={draftClauseText}
        onChange={setDraftClauseText}
      />
    </Layout>
  ) : (
    <Select
      enableOptionIndicator
      enableSearchIcon
      isEmbedded
      isClearable={false}
      isMulti={false}
      name={id}
      options={options}
      placeholder="Search for a clause..."
      value={provisionValue}
      onChange={handleClauseProvisionChange}
    />
  );

  const actions = shouldSearchWithinClause ? (
    <Layout preset="buttons">
      <Button preset="cancel" onClick={handleCancelClauseSearch} />
      <Button
        disabled={!(draftClauseOperatorId && draftClauseText)}
        text="Confirm"
        variant="action"
        onClick={handleConfirmClauseSearch}
      />
    </Layout>
  ) : (
    shouldEnableSearchWithClause && (
      <Layout direction="column" spacing={2}>
        <Divider />
        <Box px={px}>
          <Button
            text="Search within clause text"
            variant="action"
            onClick={enableSearchWithinClause}
          />
        </Box>
      </Layout>
    )
  );

  return (
    <Box mx={-px}>
      {clauseContent}
      {actions}
    </Box>
  );
};

interface ClauseOperatorSelectProps {
  value: Nullable<ClauseOperatorId>;
  onChange: (updatedClauseOperatorId: Nullable<ClauseOperatorId>) => void;
}

const ClauseOperatorSelect = ({
  value,
  onChange,
}: ClauseOperatorSelectProps) => {
  return (
    <Select
      enablePortal
      isClearable={false}
      isMulti={false}
      isSearchable={false}
      name="clause-operator"
      options={clauseOptions}
      placeholder="Choose an operator"
      value={value}
      onChange={onChange}
    />
  );
};

const clauseOptions = Object.values(toTextSearchOptionLookup).reduce(
  (acc, textSearchScopeLookup) => {
    return [...acc, ...Object.values(textSearchScopeLookup)];
  },
  [] as Option<ClauseOperatorId>[],
);

const px = 4;
