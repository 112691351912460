import { noop } from 'lodash';
import { EventBus } from 'pdfjs-dist/types/web/interfaces';
import { SimpleLinkService } from 'pdfjs-dist/types/web/pdf_link_service';
import { PDFViewer } from 'pdfjs-dist/types/web/pdf_viewer';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

interface Context {
  viewer?: PDFViewer;
  eventBus?: EventBus;
  linkService?: SimpleLinkService;
  setViewer: Dispatch<SetStateAction<PDFViewer | undefined>>;
  setEventBus: Dispatch<SetStateAction<EventBus | undefined>>;
  setLinkService: Dispatch<SetStateAction<SimpleLinkService | undefined>>;
}

interface Props {
  children: React.ReactNode;
}

export const PdfHighlighterContext = React.createContext<Context>({
  setViewer: noop,
  setEventBus: noop,
  setLinkService: noop,
});

export const PdfHighlighterProvider = ({ children }: Props) => {
  const [viewer, setViewer] = useState<PDFViewer>();
  const [eventBus, setEventBus] = useState<EventBus>();
  const [linkService, setLinkService] = useState<SimpleLinkService>();

  return (
    <PdfHighlighterContext.Provider
      value={{
        viewer,
        eventBus,
        linkService,
        setViewer,
        setEventBus,
        setLinkService,
      }}
    >
      {children}
    </PdfHighlighterContext.Provider>
  );
};

export const usePdfHighlighterContext = (): Context =>
  useContext(PdfHighlighterContext);
