import { keyBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import uuid from 'uuid';

import { SearchableFields } from '~/api';
import { getHandlersKeyFromSearchResult } from '~/components/SearchV2/SearchResult/SearchResult.utils';
import BaseBooleanSearchBar from '~/components/Shared/BaseBooleanSearchBar';
import { useDocumentMultipleLocationsModal } from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { Table } from '~/eds';
import { api } from '~/redux';
import { ERROR } from '~/types/toast.types';
import { Box, FlexLayout, LoadingContainer, Text } from '~/ui';
import {
  DEFAULT_TABLE_SORT_COLUMN_ORDER,
  HandlerName,
  renderCellValue,
} from '~/utils/table';

function SearchDocumentsToAddToGroup({ onDocumentsSelect }) {
  const [booleanQuery, setBooleanQuery] = useState('');
  const [query, setQuery] = useState();
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [sortBy, setSortBy] = useState(DEFAULT_TABLE_SORT_COLUMN_ORDER);
  const [columnOrder, setColumnOrder] = useState([]);
  const [fields, setQueryFields] = useState();

  const [
    setDocumentSelected,
    renderMultipleLocationModal,
  ] = useDocumentMultipleLocationsModal(getHandlersKeyFromSearchResult, true);

  const {
    data: tableViewSet,
    isSuccess: isGetTableViewSetSuccess,
    isFetching: isFetchingTableViewSet,
  } = api.endpoints.getCurrentTableView.useQuery({ context: 'GROUP' });

  const {
    data: filterSections,
    isSuccess: isGetFilterSectionsSuccess,
    isFetching: isFetchingFilterSections,
  } = api.endpoints.getFilterSections.useQuery(undefined);

  const dataFields = filterSections?.dataFields;

  const searchQueryParams = useMemo(() => {
    return {
      query,
      fields,
      page,
      pageSize,
      sortBy,
    };
  }, [query, fields, page, pageSize, sortBy]);

  const { data, error, isFetching } = api.endpoints.searchDocuments.useQuery(
    searchQueryParams,
    {
      skip: !query,
    },
  );

  useEffect(() => {
    if (error) {
      showToast(ERROR, 'An error occurred while searching for documents.');
    }
  }, [error]);

  const updateQueryFields = (updatedColumnOrder) => {
    const isDataFieldId = (columnKey) =>
      !!filterSections?.fields[columnKey] &&
      SearchableFields.DocumentGroup !== columnKey;

    const toDataField = (columnKey) => filterSections?.fields[columnKey];

    const queryFields = updatedColumnOrder
      .filter(isDataFieldId)
      .map(toDataField);

    setQueryFields(queryFields);
  };

  const stickyColumnOrder = ['name'];
  useEffect(() => {
    if (isGetTableViewSetSuccess && isGetFilterSectionsSuccess) {
      const currentColumnOrder = tableViewSet.map((column) => column.field_id);
      const defaultColumnOrder = [...stickyColumnOrder, ...currentColumnOrder];
      setColumnOrder(defaultColumnOrder);
      updateQueryFields(defaultColumnOrder);
    }
  }, [isGetTableViewSetSuccess, isGetFilterSectionsSuccess]);

  const isLoading =
    isFetchingTableViewSet || isFetchingFilterSections || isFetching;

  const handlePaginate = ({ pageIndex }) => {
    setPage(pageIndex);
  };

  const handleToggleSortBy = (newSortBy) => {
    if (newSortBy.id !== sortBy.id) {
      newSortBy.desc = true;
    }
    setSortBy(newSortBy);
  };

  const handleUpdate = (state, action) => {
    onDocumentsSelect(state.selectedRowIds);

    if (action?.type === 'toggleSortBy') {
      handleToggleSortBy(state.sortBy[0]);
    }
  };

  const handleSearchBarSubmit = () => {
    setPage(1);
    const query = {
      booleanQuery,
      filters: [
        {
          fieldId: 'document_group_id',
          id: uuid.v4(),
          operatorId: 'is_blank',
          values: [],
        },
      ],
      fields: filterSections?.fields,
    };
    setQuery(query);
  };

  const hardcodedColumns = [
    {
      key: 'name',
      title: 'Name',
      minWidth: 'm',
      renderCell: (document) => {
        const handlers = getHandlersKeyFromSearchResult(document);
        return (
          <HandlerName
            handlers={handlers}
            openTarget="_blank"
            onClick={() => setDocumentSelected(document)}
          />
        );
      },
    },
  ];

  const hardcodedColumnsMapByKey = keyBy(hardcodedColumns, 'key');
  const hardcodedColumnsMapByTitle = keyBy(hardcodedColumns, 'title');

  const wasNotHardcodedAlready = (field) =>
    !hardcodedColumnsMapByKey[field.id] &&
    !hardcodedColumnsMapByTitle[field.name];

  const fieldToColumn = (field) => ({
    key: String(field.id),
    field,
    title: field.label,
    renderCell: (document) => {
      return (
        <Text shouldTruncate={true} color="gray-900" variant="s-dense">
          {renderCellValue(field, document.selected_field_values)}
        </Text>
      );
    },
  });

  const fieldsToColumns = (fieldItems) => {
    return fieldItems.map(fieldToColumn);
  };

  const columns = dataFields
    ? [
        ...hardcodedColumns,
        ...fieldsToColumns(dataFields.filter(wasNotHardcodedAlready)),
      ]
    : hardcodedColumns;

  const hasResults = !error && data?.results.length > 0;
  const noResult = !error && data?.results.length === 0;
  const noSearch = !error && !data;

  return (
    <>
      <Box mb={8}>
        <BaseBooleanSearchBar
          isSubmitting={isFetchingFilterSections}
          onSubmit={handleSearchBarSubmit}
          value={booleanQuery}
          onChange={setBooleanQuery}
        />
      </Box>
      <Box sx={{ overflowY: 'scroll' }}>
        <LoadingContainer isLoading={isLoading || !columns.length}>
          {hasResults && (
            <>
              <Table
                name="search result"
                totalCount={data.count}
                columns={columns}
                data={data.results}
                state={{
                  pageIndex: page,
                  pageSize,
                  sortBy: [sortBy],
                  columnOrder,
                }}
                onUpdate={handleUpdate}
                onPaginate={handlePaginate}
                options={{
                  enableExportXlsx: false,
                  enableManageColumns: false,
                }}
                reactTableOptions={{
                  autoResetSortBy: false,
                  disableSortRemove: true,
                  manualSortBy: true,
                }}
              />
              {renderMultipleLocationModal()}
            </>
          )}
          {noResult && (
            <FlexLayout
              alignItems="center"
              justifyContent="center"
              sx={{ height: '100px' }}
            >
              <Text variant="subtitle">No documents found</Text>
            </FlexLayout>
          )}
          {noSearch && (
            <FlexLayout
              alignItems="center"
              justifyContent="center"
              sx={{ height: '100px' }}
            >
              <Text variant="subtitle">
                Enter a search query to see results
              </Text>
            </FlexLayout>
          )}
        </LoadingContainer>
      </Box>
    </>
  );
}

export default SearchDocumentsToAddToGroup;
