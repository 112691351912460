import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { Button, EmptyPage, formatDate, Icon } from '~/eds';

import {
  MODAL_ALERT,
  MODAL_DELETE,
  MODAL_SIMPLE,
} from '../../../types/modal.types';
import { ERROR, SUCCESS } from '../../../types/toast.types';
import ActionsMenu from '../../Shared/ActionsMenu';
import EcButton from '../../Shared/EcButton';
import EcCard from '../../Shared/EcCard';
import EcCheckbox from '../../Shared/EcCheckbox';
import EcModal from '../../Shared/EcModal';
import EcPaginate from '../../Shared/EcPaginate';
import { showToast } from '../../Shared/EcToast';
import AlertsIcon from '../../Shared/Icons/AlertsIcon';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import NotificationsOffIcon from '../../Shared/Icons/NotificationsOffIcon';
import SelectDeselectAllMenu from '../../Shared/SelectDeselectAllMenu';
import {
  deleteAlerts,
  sendAlertEmail,
  toggleMuteAlert,
  updateAlert,
} from '../AlertsPage.data';
import styles from './AlertsList.styles';

const ecCardContentStyles = { padding: '0 16px' };

const MODAL_SIMPLE_SEND_EMAIL = 'MODAL_SIMPLE_SEND_EMAIL';
const MODAL_SIMPLE_MUTE = 'MODAL_SIMPLE_MUTE';
const MODAL_SIMPLE_ACTIVATE = 'MODAL_SIMPLE_ACTIVATE';

class AlertsList extends Component {
  constructor(props) {
    super(props);

    this.handleHideModal = this.handleHideModal.bind(this);
    this.state = {
      allSelected: false,
      selectedItemsList: [],
      pageSize: 20,
      primaryModal: null,
    };
  }

  resetSelection() {
    this.setState({ allSelected: false, selectedItemsList: [] });
  }

  handleOnSelectClick(isSelected) {
    const { alerts } = this.props;

    let newSelectedItems = [];
    if (isSelected) alerts.forEach((item) => newSelectedItems.push(item.id));

    this.setState({
      allSelected: isSelected,
      selectedItemsList: newSelectedItems,
    });
  }

  handleOnSelectItemClick(itemId) {
    const { selectedItemsList } = this.state;

    const selectedItemIndex = selectedItemsList.indexOf(itemId);

    let newSelectedItems = [...selectedItemsList];
    if (selectedItemIndex > -1) {
      newSelectedItems.splice(selectedItemIndex, 1);
    } else {
      newSelectedItems.push(itemId);
    }

    this.setState({ selectedItemsList: newSelectedItems });
  }

  handleHideModal() {
    this.setState({ primaryModal: null });
  }

  handleToggleMuteClick(alert, actionText) {
    const { handleUpdateItem } = this.props;

    toggleMuteAlert(alert.id, !alert.muted).then(() =>
      showToast(
        SUCCESS,
        `This alert has been successfully ${actionText.toLocaleLowerCase()}d.`,
      ),
    );
    handleUpdateItem({ ...alert, muted: !alert.muted });
    this.handleHideModal();
  }

  handleActivateClick(alert, actionText) {
    const { handleUpdateItem } = this.props;

    updateAlert(alert.id, { active: !alert.active }).then((ret) => {
      handleUpdateItem({ ...ret, active: !alert.active });
      showToast(
        SUCCESS,
        `This alert has been successfully ${actionText.toLocaleLowerCase()}d.`,
      );
    });
    this.handleHideModal();
  }

  renderAlertModal() {
    const { onItemUpdate, onItemCreate } = this.props;
    const { primaryModal } = this.state;
    const { alert, step, isDuplicated } = primaryModal;

    return (
      <EcModal
        modalType={MODAL_ALERT}
        width="1080px"
        alertId={alert.id}
        step={step}
        isDuplicated={isDuplicated}
        handleAlertAction={isDuplicated ? onItemCreate : onItemUpdate}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderActivateModal() {
    const { classes } = this.props;
    const { primaryModal } = this.state;

    const { alert } = primaryModal;

    const actionText = alert.active ? 'Deactivate' : 'Activate';
    const modalTitle = `${actionText} Alert?`;

    return (
      <EcModal
        modalType={MODAL_SIMPLE}
        title={modalTitle}
        width="560px"
        includeCancelButton={true}
        hideModal={this.handleHideModal}
        modalButtons={
          <EcButton
            yellow
            text={actionText}
            onClick={() => this.handleActivateClick(alert, actionText)}
          />
        }
      >
        <div className={classes.simpleModalText}>
          Are you sure you want to {actionText.toLocaleLowerCase()} the{' '}
          <span>{alert.name}</span> alert?
        </div>
      </EcModal>
    );
  }

  renderMuteAlertModal() {
    const { classes } = this.props;
    const { primaryModal } = this.state;

    const { alert } = primaryModal;

    const actionText = alert.muted ? 'Unmute' : 'Mute';
    const modalTitle = `${actionText} Alert?`;
    const ActionIcon = alert.muted ? AlertsIcon : NotificationsOffIcon;

    return (
      <EcModal
        modalType={MODAL_SIMPLE}
        title={modalTitle}
        width="560px"
        includeCancelButton={true}
        hideModal={this.handleHideModal}
        modalButtons={
          <EcButton
            yellow
            iconLeft={<ActionIcon size="20" opacity="0.75" />}
            text={actionText}
            onClick={() => this.handleToggleMuteClick(alert, actionText)}
          />
        }
      >
        <div className={classes.simpleModalText}>
          Are you sure you want to {actionText.toLocaleLowerCase()} the{' '}
          <span>{alert.name}</span> alert? Going forward, you{' '}
          {alert.muted ? 'will receive' : 'won’t receive any'} emails regarding
          this alert.
        </div>
      </EcModal>
    );
  }

  handleSendEmail(alertId, sendToAll) {
    const toastMessage = sendToAll
      ? 'The email has been sent to all the recipients of the alert.'
      : 'The email has been sent.';

    sendAlertEmail(alertId, sendToAll)
      .then(() => showToast(SUCCESS, toastMessage))
      .catch(() =>
        showToast(ERROR, 'Something went wrong with sending an email.'),
      );
  }

  renderSendEmailModal() {
    const { classes } = this.props;
    const { primaryModal } = this.state;

    const { alert } = primaryModal;

    return (
      <EcModal
        modalType={MODAL_SIMPLE}
        title="Send Email"
        width="560px"
        includeCancelButton={true}
        hideModal={this.handleHideModal}
        modalButtons={
          <Fragment>
            <EcButton
              text="Email All Recipients"
              onClick={() => {
                this.handleSendEmail(alert.id, true);
                this.handleHideModal();
              }}
            />
            <EcButton
              yellow
              text="Email Only Me"
              onClick={() => {
                this.handleSendEmail(alert.id, false);
                this.handleHideModal();
              }}
            />
          </Fragment>
        }
      >
        <div className={classes.simpleModalText}>
          In order to review the alert, you can trigger it immediately. This
          will not affect the regular schedule and frequency.
        </div>
      </EcModal>
    );
  }

  renderDeleteModal() {
    const { selectedItemsList, primaryModal } = this.state;
    const { handleDeleteItems } = this.props;

    const { alert } = primaryModal;

    const itemsToDelete = selectedItemsList.length
      ? selectedItemsList
      : [alert];
    const modalTitle = selectedItemsList.length
      ? 'Delete Items?'
      : 'Delete Item?';
    const messageSubjectText = selectedItemsList.length ? (
      <Fragment>
        <span>{itemsToDelete.length} items</span> you have selected?
      </Fragment>
    ) : (
      <Fragment>
        <span>{alert.name}</span> alert?
      </Fragment>
    );

    return (
      <EcModal
        modalType={MODAL_DELETE}
        width="560px"
        title={modalTitle}
        text={
          <Fragment>
            Are you sure you want to delete the {messageSubjectText} You won’t
            be able to undo this action.
          </Fragment>
        }
        confirmButtonText="Delete"
        deleteItem={() => {
          const itemsToDelete = selectedItemsList.length
            ? selectedItemsList
            : [alert.id];
          deleteAlerts(itemsToDelete)
            .then(() => {
              showToast(SUCCESS, 'The alert has been successfully deleted.');
              handleDeleteItems(itemsToDelete);
            })
            .catch(() => {
              showToast(
                ERROR,
                'Something went wrong with deleting this alert.',
              );
            });
          this.resetSelection();
        }}
        hideModal={this.handleHideModal}
      />
    );
  }

  getMenuItems = (alert) => {
    // TO DO: move to a new file
    const items = [];

    if (alert.is_editable) {
      items.push(
        {
          content: 'Edit Info and Schedule',
          id: `edit_info_and_schedule_for_alert?id=${alert.id}`,
          onClick: () =>
            this.setState({
              primaryModal: { type: MODAL_ALERT, alert, step: 1 },
            }),
        },
        {
          content: 'Edit Filters',
          id: `edit_filters_for_alert?id=${alert.id}`,
          onClick: () =>
            this.setState({
              primaryModal: { type: MODAL_ALERT, alert, step: 2 },
            }),
        },
        {
          content: 'Edit Recipients',
          id: `edit_recipients_for_alert?id=${alert.id}`,
          onClick: () =>
            this.setState({
              primaryModal: { type: MODAL_ALERT, alert, step: 3 },
            }),
        },
      );
    }

    items.push(
      {
        content: 'Duplicate',
        onClick: () =>
          this.setState({
            primaryModal: {
              type: MODAL_ALERT,
              alert,
              step: 1,
              isDuplicated: true,
            },
          }),
      },
      {
        content: `${alert.muted ? 'Unmute' : 'Mute'}`,
        onClick: () =>
          this.setState({ primaryModal: { type: MODAL_SIMPLE_MUTE, alert } }),
      },
      {
        content: `Make ${alert.active ? 'Inactive' : 'Active'}`,
        onClick: () =>
          this.setState({
            primaryModal: { type: MODAL_SIMPLE_ACTIVATE, alert },
          }),
      },
      {
        content: 'Send Email Now',
        onClick: () =>
          this.setState({
            primaryModal: { type: MODAL_SIMPLE_SEND_EMAIL, alert },
          }),
      },
      {
        content: 'Delete',
        onClick: () =>
          this.setState({ primaryModal: { type: MODAL_DELETE, alert } }),
      },
    );

    return items;
  };

  renderAlertsItems() {
    const { classes, alerts } = this.props;
    const { selectedItemsList } = this.state;

    return alerts.map((alert) => {
      const {
        id,
        name,
        recipients,
        created_by,
        last_alert_date,
        upcoming_alert_date,
        active,
        muted,
      } = alert;

      const lastAlert = formatDate(
        last_alert_date ? new Date(last_alert_date) : null,
        'full',
      );
      const upcomingAlert = formatDate(
        upcoming_alert_date ? new Date(upcoming_alert_date) : null,
        'full',
      );

      return (
        <tr key={id}>
          <td>
            <EcCheckbox
              id={`select-alert-id=${id}-checkbox`}
              checked={selectedItemsList.includes(id)}
              onClick={() => this.handleOnSelectItemClick(id)}
            />
            <label
              className="screenReaderText"
              htmlFor={`select-alert-id=${id}-checkbox`}
            >
              Select {name} alert
            </label>
          </td>
          <td className={classes.cellName}>
            <div className={classes.alertNameText} title={name}>
              {name}
            </div>
          </td>
          <td className={classes.cellRecipients}>{`${recipients} ${pluralize(
            'recipient',
            recipients,
          )}`}</td>
          <td className={classes.cellCreatedBy}>
            <div className={classes.createdByText} title={created_by}>
              {created_by}
            </div>
          </td>
          <td>
            <div className={classes.dateCellWrapper}>{lastAlert}</div>
          </td>
          <td>
            <div className={classes.dateCellWrapper}>{upcomingAlert}</div>
          </td>
          <td className={classes.cellActive}>
            {active ? (
              <Icon
                color="status.success"
                icon="check"
                tooltip="Active alert"
              />
            ) : (
              <Icon color="status.danger" icon="x" tooltip="Inactive alert" />
            )}
          </td>
          <td className={classes.cellMuted}>
            {muted ? (
              <Icon
                color="status.inactive"
                icon="alert-off"
                tooltip="Muted alert"
              />
            ) : (
              <Icon
                color="background.fade"
                icon="alert"
                tooltip="Unmuted alert"
              />
            )}
          </td>
          <td className={classes.cellActions}>
            <ActionsMenu
              id={`alerts_row_actions_menu?id=${alert.id}`}
              items={this.getMenuItems(alert)}
              align="end"
              title="edit alert menu"
            />
          </td>
        </tr>
      );
    });
  }

  renderAlertsTable() {
    const {
      classes,
      alerts,
      listError,
      handleRetry,
      searchQueries,
    } = this.props;
    const { allSelected, selectedItemsList } = this.state;

    if (listError) {
      return (
        <div className={classes.emptyContainer}>
          <div className={classes.primaryEmptyMessage}>
            There was an error loading alerts.
          </div>
          <button className={classes.emptyMessageAction} onClick={handleRetry}>
            Retry
          </button>
        </div>
      );
    }

    if (!alerts.length) {
      return (
        <div className={classes.emptyContainer}>
          {searchQueries && Object.keys(searchQueries).length ? (
            <Fragment>
              <div className={classes.primaryEmptyMessage}>
                No alerts match the selected filters.
              </div>
            </Fragment>
          ) : (
            <EmptyPage preset="no-alerts" />
          )}
        </div>
      );
    }

    return (
      <table className={classes.alertsTable}>
        <thead>
          <tr>
            <th className={classes.headerCheckbox}>
              <div className={classes.firstCellInRowWrapper}>
                <div className={classes.checkboxWrapper}>
                  <EcCheckbox
                    id="select-all-alert-items"
                    checked={allSelected}
                    partiallyChecked={!!selectedItemsList.length}
                    onClick={() =>
                      this.handleOnSelectClick(!selectedItemsList.length)
                    }
                  />
                  <label
                    className="screenReaderText"
                    htmlFor="select-all-alert-items"
                  >
                    Select all alert items
                  </label>
                </div>
                <div className={classes.chevronIcon}>
                  <SelectDeselectAllMenu
                    name="alerts"
                    onChange={(selectAll) =>
                      this.handleOnSelectClick(selectAll)
                    }
                  />
                </div>
              </div>
            </th>
            {selectedItemsList.length ? (
              <th colSpan="8">
                <div className={classes.headerActions}>
                  <Button
                    onClick={() =>
                      this.setState({ primaryModal: { type: MODAL_DELETE } })
                    }
                    text="Delete"
                    variant="action"
                  />
                </div>
              </th>
            ) : (
              <Fragment>
                <th className={classes.headerName}>NAME</th>
                <th className={classes.headerRecipients}>RECIPIENTS</th>
                <th>CREATED BY</th>
                <th>LAST SCHEDULED SEND</th>
                <th>NEXT SCHEDULED SEND</th>
                <th className={classes.headerActive}>ACTIVE</th>
                <th className={classes.headerMuted}>MUTED</th>
                <th className={classes.headerAlertActions} />
              </Fragment>
            )}
          </tr>
        </thead>
        <tbody>{this.renderAlertsItems()}</tbody>
      </table>
    );
  }

  renderLoadingContainer() {
    const { classes } = this.props;

    return (
      <div className={classes.loadingContainer}>
        <LoadingSpinner size="medium" />
      </div>
    );
  }

  renderPagination() {
    const { pageSize } = this.state;
    const { page, pageCount, handleOnPageChange } = this.props;

    if (pageCount <= pageSize) return null;

    return (
      <EcPaginate
        onPageChange={handleOnPageChange}
        pageCount={Math.ceil(pageCount / pageSize)}
        forcePage={page - 1}
      />
    );
  }

  render() {
    const { classes, listLoading } = this.props;
    const { primaryModal } = this.state;

    return (
      <div
        className={classes.alertsList}
        role="region"
        aria-labelledby="alerts-list"
      >
        <EcCard contentStyles={ecCardContentStyles}>
          {listLoading
            ? this.renderLoadingContainer()
            : this.renderAlertsTable()}
        </EcCard>
        <span className={classes.verticalSeparator} />
        {this.renderPagination()}
        {primaryModal &&
          primaryModal.type === MODAL_DELETE &&
          this.renderDeleteModal()}
        {primaryModal &&
          primaryModal.type === MODAL_SIMPLE_SEND_EMAIL &&
          this.renderSendEmailModal()}
        {primaryModal &&
          primaryModal.type === MODAL_SIMPLE_MUTE &&
          this.renderMuteAlertModal()}
        {primaryModal &&
          primaryModal.type === MODAL_SIMPLE_ACTIVATE &&
          this.renderActivateModal()}
        {primaryModal &&
          primaryModal.type === MODAL_ALERT &&
          this.renderAlertModal()}
      </div>
    );
  }
}

AlertsList.propTypes = {
  alerts: PropTypes.array.isRequired,
  listLoading: PropTypes.bool.isRequired,
  listError: PropTypes.bool.isRequired,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  handleOnPageChange: PropTypes.func.isRequired,
  handleDeleteItems: PropTypes.func.isRequired,
  handleUpdateItem: PropTypes.func.isRequired,
  handleRetry: PropTypes.func.isRequired,
};

export default injectSheet(styles)(AlertsList);
