export default `
<svg
  aria-hidden="true"
  aria-label="block"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4C16.4062 4 20 7.59375 20 12ZM6.875 7.96875C6 9.09375 5.5 10.5 5.5 12C5.5 15.5938 8.40625 18.5 12 18.5C13.5 18.5 14.9062 18 16.0312 17.125L6.875 7.96875ZM18.5 12C18.5 8.4375 15.5625 5.5 12 5.5C10.4688 5.5 9.0625 6.03125 7.9375 6.90625L17.0938 16.0625C17.9688 14.9375 18.5 13.5312 18.5 12Z"
    fill="currentcolor"
  />
</svg>
`;
