export const SAVE_DOCUMENT_CACHE_KEY = 'save-sfdt-reviewer-page';
export const TICKET_COMMENT_CHIP = {
  icon: 'lock' as const,
  text: 'Ticket comment',
};
export const DOCUMENT_COMMENT_CHIP = {
  icon: 'globe' as const,
  text: 'Document comment',
};
export const ERROR_MESSAGES = {
  403: {
    preset: 'page-access',
    title: 'No Access to Ticket',
    description:
      "You don't have access to this Ticket. Contact your administrator for access. Please return to Tickets to continue.",
  },
  default: {
    preset: 'page-not-found',
    title: 'Ticket Not Found',
    description:
      'We could not find the ticket requested. Please return to Tickets to continue',
  },
};
