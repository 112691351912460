import React, { memo } from 'react';

import { TextVariant } from '../../../types';
import {
  scrollElementIntoView,
  testIsInteractiveElement,
} from '../../../utils';
import { Link } from '../Link';

interface Props {
  /** Hash */
  hash: string;
  /** Link content */
  children: React.ReactNode;
  /** Color */
  color?: 'text.primary' | 'text.secondary';
  /** Disables the link */
  disabled?: boolean;
  /** Disable scroll */
  disableScroll?: boolean;
  /** Text variant */
  variant?: TextVariant;
  /** optional Callback to invoke when a hash is clicked. */
  onClick?: (hash: string) => void;
}

export const HashLink = memo(
  ({
    children,
    color,
    disabled,
    disableScroll = false,
    hash,
    variant,
    onClick,
  }: Props) => {
    return (
      <Link
        color={color}
        disabled={disabled}
        hash={hash}
        variant={variant}
        onClick={() => {
          focusElement(hash);
          if (!disableScroll) {
            scrollElementIntoView(hash);
          }
          onClick?.(hash);
        }}
      >
        {children}
      </Link>
    );
  },
);

/**
 *
 * The browser native behavior when clicking a hash link is:
 * - If the target element is not focusable, then focus is moved to the target element, but the target element is not focused.
 * - If the target element is focusable (interactive elements and elements with a tabindex), then the target element is focused.
 *
 * `HashLink` recreates this behavior (as the behavior is lost in the `react-router-dom` vendor) by:
 * - For focusable elements, it calls element.focus() and leaves focus on the target element.
 * - For non-focusable elements, it calls element.focus() followed by element.blur() (using a temporary tabindex to ensure that the element can be focused programmatically) so that focus moves to the target element but does not remain on it or trigger any style changes.
 *
 * Reference: https://github.com/rafgraph/react-router-hash-link/blob/f834e6df962eb266d5bf26fd5ae679ecf14cdfff/README.md#focus-management
 */
const focusElement = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    const originalTabIndex = element.getAttribute('tabindex');
    const noTabIndex = originalTabIndex === null;
    if (testIsInteractiveElement(element)) {
      element.focus();
    } else {
      if (noTabIndex) {
        element.setAttribute('tabindex', '-1');
      }
      element.focus();
      element.blur();
      if (noTabIndex) {
        element.removeAttribute('tabindex');
      }
    }
  }
};
