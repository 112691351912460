export default `
<svg
  aria-hidden="true"
  aria-label="automation-gradient"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M14.9992 11.5598L13.1242 10.8455L12.41 8.97055C12.3653 8.83662 12.2314 8.7027 12.0528 8.7027C11.8743 8.7027 11.7403 8.79198 11.6957 8.97055L10.9814 10.8455L9.10647 11.5598C8.97255 11.6044 8.83862 11.7383 8.83862 11.9169C8.83862 12.0955 8.92791 12.2294 9.10647 12.274L10.9814 12.9883L11.6957 14.8633C11.7403 14.9972 11.8743 15.1311 12.0528 15.1311C12.2314 15.1311 12.3653 15.0418 12.41 14.8633L13.1242 12.9883L14.9992 12.274C15.1331 12.2294 15.267 12.0955 15.267 11.9169C15.2224 11.783 15.1331 11.6044 14.9992 11.5598Z"
    fill="url(#paint0_linear_3438_109848)"
  />
  <path
    d="M17.3213 8.21162L16.0713 7.7652L15.6249 6.51523C15.5802 6.42594 15.491 6.33666 15.4017 6.33666C15.3124 6.33666 15.2231 6.3813 15.1785 6.51523L14.732 7.7652L13.4821 8.21162C13.3928 8.25626 13.3481 8.34554 13.3481 8.43482C13.3481 8.52411 13.3928 8.61339 13.4821 8.65803L14.732 9.10445L15.1785 10.3544C15.2231 10.4437 15.3124 10.533 15.4017 10.533C15.491 10.533 15.5802 10.4883 15.6249 10.3544L16.0713 9.10445L17.3213 8.65803C17.4106 8.61339 17.4998 8.52411 17.4998 8.43482C17.4998 8.34554 17.4106 8.21162 17.3213 8.21162Z"
    fill="url(#paint1_linear_3438_109848)"
  />
  <path
    d="M10.5353 14.149C10.446 13.8811 10.1781 13.7026 9.9103 13.7472C9.68709 13.7472 9.50852 13.7919 9.28531 13.7919C8.57104 13.7919 7.90142 13.7026 7.23179 13.4794L6.65145 13.3008L6.11574 13.6579C5.75861 13.9258 5.22291 14.2383 4.59792 14.4168C4.77649 14.1044 4.95506 13.7472 5.13362 13.3454L5.26755 12.9883C5.35683 12.7651 5.31219 12.4973 5.13362 12.3187L4.86577 12.0062C4.37471 11.5151 3.83901 10.6669 3.83901 9.55089C3.83901 7.22952 6.29431 5.30992 9.32995 5.30992C10.8478 5.30992 12.2317 5.80098 13.2584 6.55989C13.5709 6.7831 14.0173 6.73846 14.1959 6.38132C14.3745 6.11347 14.2852 5.75634 14.062 5.57777C12.8567 4.59565 11.2049 4.0153 9.3746 4.0153C5.5354 4.10459 2.5444 6.55989 2.5444 9.59553C2.5444 10.7116 2.94617 11.783 3.66044 12.6312C3.83901 12.8097 3.88365 13.0776 3.74973 13.3454C3.34795 14.1936 2.72296 14.8633 2.67832 14.8633C2.49976 15.0418 2.45511 15.3097 2.5444 15.5329C2.63368 15.7561 2.85689 15.9347 3.12474 15.9347C4.59792 15.9347 5.75861 15.399 6.56216 14.8633C6.69609 14.774 6.9193 14.7293 7.05322 14.774C7.72285 14.9526 8.48176 15.0865 9.24067 15.0865C9.50852 15.0865 9.73173 15.0865 9.99958 15.0418C10.446 14.9972 10.6692 14.5508 10.5353 14.149Z"
    fill="url(#paint2_linear_3438_109848)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_3438_109848"
      x1="12.0528"
      y1="8.7027"
      x2="12.0528"
      y2="15.1311"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#7413FF" />
      <stop offset="1" stop-color="#4136FF" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_3438_109848"
      x1="15.424"
      y1="6.33666"
      x2="15.424"
      y2="10.533"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#7413FF" />
      <stop offset="1" stop-color="#4136FF" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_3438_109848"
      x1="8.39533"
      y1="4.0153"
      x2="8.39533"
      y2="15.9347"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#7413FF" />
      <stop offset="1" stop-color="#4136FF" />
    </linearGradient>
  </defs>
</svg>

`;
