import PT from 'prop-types';
import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { FlexLayout, Icon, Text } from '~/ui';

function SortableList({ items, onSort }) {
  const [listItems, setListItems] = useState(items);

  useEffect(() => {
    onSort(listItems);
  }, [listItems, onSort]);

  function arrayMove(arr, oldIndex, newIndex) {
    const element = arr[oldIndex];
    arr.splice(oldIndex, 1);
    arr.splice(newIndex, 0, element);
    return arr;
  }

  function onSortEnd({ oldIndex, newIndex }) {
    const newItems = arrayMove([...listItems], oldIndex, newIndex);
    setListItems(newItems);
  }

  const SortableItem = SortableElement(({ entry }) => (
    <FlexLayout
      alignItems="center"
      p={3}
      space={2}
      sx={{
        border: 'border',
        borderRadius: 'm',
        zIndex: 'sortableList',
      }}
    >
      <Icon
        color="black-alpha-50"
        icon="reorder"
        sx={{ cursor: 'row-resize' }}
      />
      <Text color="black" variant="m-dense-bold">
        {entry.label}
      </Text>
    </FlexLayout>
  ));

  const SortContainer = SortableContainer(({ items }) => {
    return (
      <FlexLayout flexDirection="column" space={4}>
        {items.map((entry, index) => (
          <SortableItem
            entry={entry}
            index={index}
            key={`item-${index}`}
            sortIndex={index}
          />
        ))}
      </FlexLayout>
    );
  });

  return <SortContainer items={listItems} lockAxis="y" onSortEnd={onSortEnd} />;
}

const itemPropType = PT.shape({
  /** List item value */
  value: PT.any.isRequired,
  /** List item label */
  label: PT.string.isRequired,
});

SortableList.propTypes = {
  /** Array of list items. Item signature: { value, label } */
  items: PT.arrayOf(itemPropType.isRequired).isRequired,
  /** Returns a sorted list */
  onSort: PT.func.isRequired,
};

export default SortableList;
