import { noop } from 'lodash';
import React from 'react';

import Row from './Row';

function BodyRow({
  activeRowId,
  children,
  data,
  disabled,
  isSelected = false,
  isBordered = true,
  onClick = noop,
}) {
  const isActive = activeRowId === data.id;

  return (
    <Row
      disabled={disabled}
      isActive={isActive}
      isSelected={isSelected}
      isBordered={data.isLast && !isBordered ? true : isBordered}
      onClick={() => onClick(data)}
    >
      {children}
    </Row>
  );
}

export default BodyRow;
