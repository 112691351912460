import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BaseLoadView from '~/components/Shared/BaseLoadView';
import { TableViewContextType } from '~/enums';
import { selectors } from '~/redux';
import documentGroup from '~/redux/slices/documentGroup';

const GroupDocumentsLoadView = ({ onViewSelected }) => {
  const modal = useSelector(selectors.selectDocGroupModal);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(documentGroup.actions.setModal());
  const { modalType } = modal || {};

  return (
    <BaseLoadView
      context={TableViewContextType.GROUP}
      modalType={modalType}
      hideModal={hideModal}
      onViewSelected={onViewSelected}
    />
  );
};

export default GroupDocumentsLoadView;
