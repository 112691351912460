import React, { memo } from 'react';

import { ReactionType, SharedReactionProps } from '../../../types';
import { Layout } from '../../Layout';
import { Reaction } from '../Reaction';

type Reactions = SharedReactionProps[];

interface Props {
  /** An array of reactions */
  reactions: Reactions;
  /** Callback to handle updated reactions */
  onChange: (updatedReactions: Reactions) => void;
  /** Sets the component in single/multi-select mode (i.e. radio/checkbox) */
  isMulti?: boolean;
}

export const ReactionGroup = memo(
  ({ isMulti = true, reactions, onChange }: Props) => {
    const role = isMulti ? 'checkbox' : 'radio';

    const handleChange = (type: ReactionType, isMulti: boolean) => (
      updatedValue: boolean,
      updatedCount?: number,
    ) => {
      const updatedReactions = reactions.map((reaction) =>
        reaction.type === type
          ? {
              ...reaction,
              count: updatedCount,
              value: updatedValue,
            }
          : {
              ...reaction,
              value: isMulti ? reaction.value : !updatedValue,
            },
      );

      onChange(updatedReactions);
    };

    return (
      <Layout wrap role="group" spacing={1} wrapSpacing={1}>
        {reactions.map((reaction) => (
          <Reaction
            key={reaction.type}
            {...reaction}
            role={role}
            onChange={handleChange(reaction.type, isMulti)}
          />
        ))}
      </Layout>
    );
  },
);
