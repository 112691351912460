import PT from 'prop-types';
import React, { useRef } from 'react';

import { Box, styles, theme, Tooltip, useHasOverflow } from '~/ui';

import { tokens } from '../../theme/typography';

const Text = React.forwardRef((props, initialRef) => {
  const textRef = useRef();
  const ref = initialRef || textRef;
  const hasOverflow = useHasOverflow(ref);

  const {
    as = 'span',
    children,
    isBold = false,
    isNumeric = false,
    isReadable = false,
    shouldTruncate = false,
    shouldWrap = false,
    sx = {},
    variant = 'body1',
    toolTipPlacement = 'top',
    ...rest
  } = props;
  const truncateStyle = shouldTruncate ? styles.typography.truncate : {};
  const wrapStyle = shouldWrap ? styles.typography.wrap : {};

  // TODO 3781: remove and favor explicit props when deprecating old variants.
  const DEPRECATED_isBold = isBold || variant.includes('bold');

  const contents = (
    <Box
      as={as}
      sx={{
        display: isReadable ? 'inline-block' : undefined,
        fontVariantNumeric: isNumeric ? 'tabular-nums' : undefined,
        maxWidth: isReadable ? 'readable-width' : undefined,
        whiteSpace: 'pre-wrap',
        ...truncateStyle,
        ...wrapStyle,
        ...sx,
        variant: sx.variant || `text.${variant}`,
        fontWeight: DEPRECATED_isBold ? 'bold' : undefined,
      }}
      {...rest}
    >
      {children}
    </Box>
  );

  if (shouldTruncate) {
    return (
      <Tooltip
        content={hasOverflow ? children : null}
        placement={toolTipPlacement}
      >
        <Box ref={ref} sx={styles.typography.truncate}>
          {contents}
        </Box>
      </Tooltip>
    );
  }

  return contents;
});

Text.propTypes = {
  toolTipPlacement: PT.string,
  /** Apply the specified HTML element string or React component for rendering  */
  as: PT.oneOfType([PT.string, PT.elementType]),
  /** Any react node */
  children: PT.node,
  /** Valid system color */
  color: PT.string,
  /** Conveniently disables pointer events on the element.  Useful to disable user interaction */
  disabled: PT.bool,
  /** Element ID */
  id: PT.string,
  /** Renders with fontWeight = 'bold' if true */
  isBold: PT.bool,
  /** Renders text with equal widths of numeral characters */
  isNumeric: PT.bool,
  /** Sets readable width */
  isReadable: PT.bool,
  /** Forwards provided ref */
  // ref: PT.object,
  /** Truncate text if contents exceed width */
  shouldTruncate: PT.bool,
  /** Supports sx styles */
  sx: PT.object,
  /** Text variant */
  variant: PT.oneOf([...Object.keys(tokens), ...Object.keys(theme.text)]),
};

export default Text;
