// TODO: deprecate across the platform

/**
 * NOTE: please avoid changes in this file as it has security implications.  Have #team-security review any changes to sanitize configs in this file
 */
import DOMPurify, { Config } from 'dompurify';
import React, { useMemo } from 'react';

/**
 * Extending dompurify's default/safe config
 * - support <head /> tag: https://github.com/cure53/DOMPurify/issues/501
 */
const defaultConfig: Config = {
  WHOLE_DOCUMENT: true,
  ADD_TAGS: ['head'],
};

interface Props extends React.HTMLProps<HTMLDivElement> {
  html: string;
}

export const HtmlContent = ({ children: _unused, html, ...rest }: Props) => {
  const sanitized = useMemo(() => {
    return DOMPurify.sanitize(html, defaultConfig) as string;
  }, [html]);

  return <div {...rest} dangerouslySetInnerHTML={{ __html: sanitized }} />;
};
