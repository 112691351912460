export const MODAL_ADD_DOCUMENTS_TO_GROUP = 'modal_add_documents_to_group';
export const MODAL_EDIT_DOCUMENTS_GROUP = 'modal_edit_documents_group';
export const MODAL_DELETE_DOCUMENTS_GROUP = 'modal_delete_documents_group';
export const MODAL_REMOVE_DOCUMENT_IN_GROUP = 'modal_remove_document_in_group';
export const MODAL_DELETE = 'modal_delete';
export const MODAL_DELETE_SAVED_SEARCH = 'modal_delete_saved_search';
export const MODAL_DUPLICATE_SAVED_SEARCH = 'modal_duplicate_saved_search';
export const MODAL_EDIT_SAVED_SEARCH = 'modal_edit_saved_search';
export const MODAL_NAME = 'modal_name';
export const MODAL_LIST = 'modal_list';
export const MODAL_ADMIN_USER = 'modal_admin_user';
export const MODAL_ACCESS_SETTINGS = 'modal_access_settings';
export const MODAL_SEND_EMAIL = 'modal_send_email';
export const MODAL_UPLOAD = 'modal_upload';
export const MODAL_FOLDER_TREE = 'modal_folder_tree';
export const MODAL_AUTOCOMPLETE = 'modal_autocomplete';
export const MODAL_TAG_CLAUSE = 'modal_tag_clause';
export const MODAL_SEARCH_WITHIN_PROVISION = 'modal_search_within_provision';
export const MODAL_SAVE_AND_FLAG = 'modal_save_and_flag';
export const MODAL_LEAVE_REVIEW = 'modal_leave_review';
export const MODAL_INACTIVITY_TIMER = 'modal_inactivity_timer';
export const MODAL_CLIENT_PARTY = 'modal_client_party';
export const MODAL_CKEDITOR = 'modal_ckeditor';
export const MODAL_CUSTOM_FIELD = 'modal_custom_field';
export const MODAL_REORDER_SECTIONS = 'modal_reorder_sections';
export const MODAL_CUSTOM_FIELD_MOVE = 'modal_custom_field_move';
export const MODAL_MULTIPLE_LOCATIONS = 'modal_multiple_locations';
export const MODAL_DEPARTMENT_MEMBERS = 'modal_department_members';
export const MODAL_DEPARTMENT_TREE = 'modal_department_tree';
export const MODAL_DELETE_DEPARTMENT = 'modal_delete_department';
export const MODAL_MULTI_EDIT = 'MODAL_MULTI_EDIT';
export const MODAL_ALERT = 'MODAL_ALERT';
export const MODAL_SIMPLE = 'MODAL_SIMPLE';
export const MODAL_VERSION_DOCUMENT_VIEWER = 'MODAL_VERSION_DOCUMENT_VIEWER';
export const MODAL_ADD_FILTER = 'MODAL_ADD_FILTER';
export const MODAL_DOCUMENTS_COLUMN_FIELDS = 'MODAL_DOCUMENTS_COLUMN_FIELDS';
export const MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE =
  'MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE';
export const MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH =
  'MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH';
export const MODAL_DOCUMENT_GROUP = 'MODAL_DOCUMENT_GROUP';
export const MODAL_DOCUMENT_GROUP_RELATION = 'MODAL_DOCUMENT_GROUP_RELATION';
export const MODAL_EXCEL_EXPORT = 'MODAL_EXCEL_EXPORT';
export const MODAL_BULK_USER_IMPORT = 'MODAL_BULK_USER_IMPORT';
export const MODAL_QUICK_SEARCH = 'MODAL_QUICK_SEARCH';
export const MODAL_AUTH_REQUIRE_POPUP = 'MODAL_AUTH_REQUIRE_POPUP';
