import { saveAs } from 'file-saver';
import { noop } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RETRY_ERROR } from '~/constants/errors';
import { DEFAULT_MAX_LIMIT } from '~/constants/max_lengths';
import {
  Button,
  FormField,
  Layout,
  Paginate,
  scrollElementIntoView,
  Section,
  Text,
  types,
  useModal,
  useToast,
  useToggle,
} from '~/eds';
import { FileMimeType } from '~/enums';
import { Filter } from '~/evifields';
import {
  CreateFieldPanel,
  DataFieldType,
  FieldInfo,
  FieldSelect,
} from '~/features/fields';
import { SearchFiltersPreview } from '~/features/filters';
import { FlagType, useFlag } from '~/flags';
import { actions, api, selectors } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';
import { Nullable, PilotId } from '~/types';

import { MODEL_INSTRUCTIONS_ELEMENT_ID } from '../../constants';
import { DataField, FieldModel } from '../../fields/types';
import {
  isDataFieldUnavailable,
  sortDataFieldOptions,
  testIsClassificationModel,
  testIsMultiClassificationModel,
  testIsTestCaseEvaluating,
  testIsTextExtractionModel,
} from '../../fields/utils';
import { PromptModel, PromptModelTestCase } from '../../types';
import { ConfigureDropdownOptions } from './DropdownOptions/ConfigureDropdownOptions';
import { InstructionsEditor } from './InstructionsEditor';

interface Props {
  isConfigDirty: boolean;
  modelVersion: Nullable<FieldModel>;
  model: Nullable<PromptModel>;
  readOnly?: boolean;
}

export const Configuration = ({
  isConfigDirty,
  modelVersion,
  model,
  readOnly = false,
}: Props) => {
  const dispatch = useDispatch();
  const enableCloseEndedDropdowns = useFlag(
    FlagType.DocumentXRayCloseEndedDropdowns,
  );
  const enableDocumentXRayDownloadOptimize = useFlag(
    FlagType.DocumentXRayDebugOutput,
  );

  const enableFiltersEnhancement = useFlag(
    FlagType.DocumentXRayEnhancedFilters,
  );

  const enableDocumentXRayOptimize = useFlag(FlagType.DocumentXrayOptimize);

  const { navigate, params } = useRouting();
  const { modelId = '' } = params;

  const field = useSelector(selectors.selectFieldAiField);
  const filters = useSelector(selectors.selectFieldAiFilters);
  const instructions = useSelector(selectors.selectFieldAiInstructions);
  const config = useSelector(selectors.selectFieldAiConfig);
  const versions = useSelector(selectors.selectFieldAiModelVersions);
  const activeVersionNumber = useSelector(
    selectors.selectFieldAiActiveVersionNumber,
  );

  const activeFilters = useSelector(selectors.selectFieldAiActiveFilters);

  const { toast } = useToast();

  const isNew = !modelVersion;
  const currentOptimizeVersion = model?.currentOptimizeInfo ?? null;

  const {
    data: fieldModelFieldIds,
    isFetching: isFetchingFieldModelFieldIds,
  } = api.endpoints.getPromptModelFieldIds.useQuery({});

  const {
    data: smartFieldsResponse,
    isFetching: isLoadingSmartFieldIDs,
  } = api.endpoints.getFields.useQuery(
    {
      isSmart: true,
      limit: DEFAULT_MAX_LIMIT,
    },
    {
      skip: enableFiltersEnhancement,
    },
  );

  const smartFieldIds = useMemo(
    () => (smartFieldsResponse?.results ?? []).map(({ id }) => id),
    [smartFieldsResponse],
  );

  const fieldModelIdsSet = useMemo(() => new Set(fieldModelFieldIds ?? []), [
    fieldModelFieldIds,
  ]);

  const [
    saveDraft,
    { isLoading: isLoadingSaveDraft },
  ] = api.endpoints.savePromptModelDraft.useMutation();

  const [
    getOptimizeSummary,
    { isLoading: isFetchingOptimizeSummary },
  ] = api.endpoints.getPromptModelOptimizeSummary.useLazyQuery();

  const [getFieldValues] = api.endpoints.getFieldValues.useLazyQuery();

  const [draftField, setDraftField] = useState<Nullable<DataField>>(null);

  const [
    isCreateFieldPanelVisible,
    _toggleCreateFieldModalVisible,
    showCreateFieldPanel,
    hideCreateFieldPanel,
  ] = useToggle(false);

  const hasNotStartedOrErrorTestCases = (test: PromptModelTestCase) =>
    test.state === 'error' || test.state === 'not_started';

  const hasNoTestCases = modelVersion?.testCases
    ? modelVersion?.testCases.length === 0
    : true;

  const hasTestCasesToEvaluate =
    modelVersion?.testCases && !hasNoTestCases
      ? modelVersion.testCases.some(hasNotStartedOrErrorTestCases)
      : false;

  const hasTestCasesToRetry =
    modelVersion?.testCases && !hasNoTestCases
      ? modelVersion.testCases.some((test) => test.state === 'error')
      : false;

  const hasEvaluatingTestCases =
    modelVersion?.testCases && !hasNoTestCases
      ? modelVersion.testCases.some(testIsTestCaseEvaluating)
      : false;

  const isEvaluating = hasEvaluatingTestCases || isLoadingSaveDraft;

  const handleConfirmField = async (updatedField: Nullable<DataField>) => {
    if (updatedField) {
      let fieldClassification;
      if (testIsClassificationModel(updatedField)) {
        try {
          const fieldValuesResponse = await getFieldValues({
            fieldId: String(updatedField.id),
          });
          const dataFieldOptions = (fieldValuesResponse.data?.values ?? []).map(
            (option) => ({
              label: option.display_value,
              value: String(option.value),
            }),
          );

          fieldClassification = {
            isMulti: testIsMultiClassificationModel(updatedField),
            options: dataFieldOptions,
          };
        } catch (error) {
          toast({
            message: 'An error occurred while fetching field values.',
            status: 'danger',
          });
        }
      }
      dispatch(
        actions.setFieldAiField({
          field: draftField,
          fieldClassification,
        }),
      );
    }
  };

  const [confirmFieldSelectModal, showConfirmFieldSelectModal] = useModal({
    children: (
      <Text>
        Once confirmed, the field selection cannot be changed. You can exit and
        restart the process to automate a different field.
      </Text>
    ),
    enableHideOnPrimaryAction: true,
    title: 'Confirm Field Selection',
    primaryAction: {
      text: 'Confirm',
      onClick: () => handleConfirmField(draftField),
    },
    onHide: () => setDraftField(field),
  });

  const mapOption = (
    option: types.Option<PilotId, DataField>,
  ): types.Option<PilotId, DataField> => {
    const { isSmart, type } = option.data!;
    const isUnavailable = isDataFieldUnavailable(type);
    const hasExistingModel = fieldModelIdsSet.has(option.value);
    const disabledTooltip = isUnavailable
      ? "This field is currently not available for model creation as it is of a data type that isn't currently supported."
      : hasExistingModel
      ? 'This field is not available for model creation as it has a pre-existing associated AI model.'
      : isSmart
      ? 'Field AI is not supported for Smart Fields.'
      : undefined;
    return {
      ...option,
      disabled: Boolean(disabledTooltip),
      tooltip: disabledTooltip,
    };
  };

  const fieldSelectFooterAction = {
    text: 'Create new field',
    level: 'tertiary' as const,
    mode: 'link' as const,
    onClick: showCreateFieldPanel,
  };

  const handleChangeDraftField = (updatedDraftField: Nullable<DataField>) => {
    setDraftField(updatedDraftField);
    showConfirmFieldSelectModal();
  };

  const handleChangeInstructions = (updatedInstructions: Nullable<string>) => {
    dispatch(actions.setFieldAiInstructions(updatedInstructions));
  };
  const handleFilterChange = (updatedFilters: Filter[]) =>
    dispatch(actions.setFieldAiFilters(updatedFilters));

  const handleEvaluate = () => {
    saveDraft({
      modelId: isNew ? null : modelId,
      config: {
        ...config,
        filters: activeFilters,
      },
    })
      .unwrap()
      .then((updatedModel) => {
        if (updatedModel) {
          if (isNew) {
            navigate(`${RoutePathType.AutomationHubFields}/${updatedModel.id}`);
          } else {
            dispatch(actions.setFieldAiDismissedIncorrectStatusMessage(false));
            dispatch(actions.setFieldAiReviewIncorrectCount(0));
          }
        }
      })
      .catch((error) => {
        const message = error.response?.data?.detail ?? RETRY_ERROR;
        if (
          message === 'No (more) documents were found in the model scope.' ||
          error.message === 'No (more) documents were found in the model scope.'
        ) {
          // toast will be rendered in the Configuration component
          if (isNew) {
            const newModelId = error.cause;
            if (newModelId) {
              navigate(`${RoutePathType.AutomationHubFields}/${newModelId}`);
            }
          }
        } else {
          toast({
            message,
            status: 'danger',
          });
        }
      });
  };

  const configRequrementsMet = Boolean(field && instructions);
  const shouldRetryTestCases =
    hasTestCasesToRetry && !isEvaluating && !isConfigDirty;

  const hasField = Boolean(field);

  useEffect(() => {
    if (modelVersion?.testCases?.length) {
      scrollElementIntoView(MODEL_INSTRUCTIONS_ELEMENT_ID, {
        behavior: 'smooth',
      });
    }
  }, [modelVersion?.id]);

  const handlePageIndexUpdate = (pageIndex: number) => {
    dispatch(actions.setFieldAiDocumentViewer({}));
    dispatch(actions.setFieldAiActiveVersionNumber(pageIndex));
  };

  const isClassificationModel = field && testIsClassificationModel(field);

  const isTextExtractionModel = field && testIsTextExtractionModel(field);

  const instructionsDescription = isTextExtractionModel
    ? 'Tell the model what to do or let Evisort AI suggest instructions. For best results, we recommend including a short definition of the field, and a few examples of the answer in the format that you prefer. e.g. If you want to extract email addresses, you can specify that you only want the AI to return “abc@evisort.com” or “xyz@evisort.com,” and no more additional text.'
    : 'Tell the model what to do or let Evisort AI suggest instructions. You can also ask the model a question.';

  const instructionsPlaceholder = isTextExtractionModel
    ? 'If there are external T&Cs, extract the URL where they appear. Format your answer as a URL, such as “www.abc.com/xyz” or “www.x-ray.com”.'
    : 'e.g. What is the vendor penalty if they can’t deliver?';

  const instructionsExample = isClassificationModel
    ? {
        content:
          'Example Model Instructions:\r\n1. Find all the email addresses in the document. Return “abc@evisort.com” or “xyz@evisort.com” and no more additional text.\r\n2. Find all the funds names in the exhibits.\r\n3. Find all the signatories in the document. Return only their name, and not their titles. For example, “Jane Doe”, or “John Taylor”.',
        label: 'Show me examples',
      }
    : undefined;

  const fieldId = field?.id ?? null;

  const isMissingDropdownOptions = Boolean(
    enableCloseEndedDropdowns &&
      config.fieldClassification &&
      !config.fieldClassification.isOpenEnded &&
      (!config.fieldClassification.options.length ||
        !config.fieldClassification.defaultOption),
  );

  return (
    <Section title="">
      <Layout justify="space-between" align="center"></Layout>
      <Layout align="flex-end" preset="form-fields">
        <FormField<PilotId, false>
          required
          description="Choose a field for the AI to populate. Once confirmed, the selection cannot be changed."
          input={null}
          label="Field"
          name="field-select"
          value={fieldId}
          onChange={noop}
        >
          {fieldId ? (
            <FieldInfo id={fieldId} />
          ) : (
            <FieldSelect
              disabled={hasField}
              footerAction={fieldSelectFooterAction}
              isLoading={isFetchingFieldModelFieldIds}
              value={fieldId}
              mapOption={mapOption}
              onChange={handleChangeDraftField}
              sortOption={sortDataFieldOptions}
            />
          )}
        </FormField>
        {isClassificationModel && enableCloseEndedDropdowns && (
          <ConfigureDropdownOptions config={config} />
        )}
        <SearchFiltersPreview
          description="Use filters to choose which documents the model will run on."
          filters={filters}
          isLoading={isLoadingSmartFieldIDs}
          defaultFilterIds={
            isNew
              ? ['folder', 'clause', 'Contract Type', 'Effective Date']
              : undefined
          }
          onUpdateFilters={handleFilterChange}
          readOnly={readOnly}
          whitelistFieldIds={
            enableFiltersEnhancement
              ? undefined
              : [...smartFieldIds, 'folder', 'clause']
          }
          title="Scope"
        />
        <FormField<string, false>
          required
          description={instructionsDescription}
          example={instructionsExample}
          input={InstructionsEditor}
          label="Instructions for the Model"
          name={MODEL_INSTRUCTIONS_ELEMENT_ID}
          placeholder={instructionsPlaceholder}
          value={instructions}
          onChange={handleChangeInstructions}
          inputProps={{
            readOnly: readOnly,
          }}
        />
        <Layout align="center" justify="space-between" w="100%">
          {versions.length > 1 && (
            <Paginate
              disableGoTo
              mode="count"
              name="model-version-paginate"
              pageIndex={activeVersionNumber}
              totalCount={versions.length}
              onUpdatePageIndex={handlePageIndexUpdate}
            />
          )}
          <Button
            disabled={
              !configRequrementsMet ||
              isMissingDropdownOptions ||
              (!isConfigDirty && !hasNoTestCases && !hasTestCasesToEvaluate) ||
              readOnly
            }
            isLoading={isLoadingSaveDraft}
            text={
              isLoadingSaveDraft
                ? 'Saving…'
                : shouldRetryTestCases
                ? 'Retry Evaluation'
                : 'Save and Evaluate'
            }
            tooltip={
              readOnly
                ? 'You are in read-only mode'
                : isEvaluating // order matters
                ? undefined
                : !configRequrementsMet
                ? 'Please select a field and fill in instructions to evaluate the field model.'
                : isMissingDropdownOptions
                ? 'Provide at least one dropdown option and the not applicable option, or choose Create with AI.'
                : shouldRetryTestCases
                ? 'Some model results failed to evaluate. Click to retry.'
                : !isConfigDirty && !hasNoTestCases
                ? 'There are no new changes to evaluate.'
                : undefined
            }
            onClick={handleEvaluate}
          />
          {enableDocumentXRayDownloadOptimize && enableDocumentXRayOptimize && (
            <Button
              disabled={!currentOptimizeVersion}
              isLoading={isFetchingOptimizeSummary}
              tooltip={
                !currentOptimizeVersion
                  ? 'This model has not been optimized. Please optimize the model before downloading the boost summary.'
                  : isFetchingOptimizeSummary
                  ? 'Downloading boost summary…'
                  : undefined
              }
              text="Boost Summary"
              onClick={() => {
                if (currentOptimizeVersion) {
                  getOptimizeSummary({
                    modelId,
                    version: currentOptimizeVersion.version,
                  }).then(({ isSuccess, data }) => {
                    const json = JSON.stringify(data, null, 2);
                    if (isSuccess) {
                      const file = new File([json], `${modelId}_boost.json`, {
                        type: FileMimeType.Json,
                      });
                      toast({
                        message: 'A file has been generated with the summary.',
                        status: 'success',
                      });
                      saveAs(file);
                    } else {
                      toast({
                        message:
                          'A file could not be generated for the boost summary.',
                        status: 'danger',
                      });
                    }
                  });
                }
              }}
            />
          )}
        </Layout>
      </Layout>
      {confirmFieldSelectModal}
      {isCreateFieldPanelVisible && (
        <CreateFieldPanel
          acceptFieldTypes={[
            DataFieldType.ARRAY_MULTIPLE,
            DataFieldType.ARRAY_SINGLE,
            DataFieldType.DATE,
            DataFieldType.NUMBER,
            DataFieldType.STRING,
            DataFieldType.TEXT_AREA,
          ]}
          onCreate={(newField, fieldData) => {
            let fieldClassification;
            if (testIsClassificationModel(newField)) {
              fieldClassification = {
                isMulti: fieldData.fieldConfig.enableMultiple,
                options: fieldData.fieldConfig.optionValues.map((value) => ({
                  label: value,
                  value,
                })),
              };
            }
            dispatch(
              actions.setFieldAiField({
                field: newField,
                fieldClassification,
              }),
            );
          }}
          onHide={hideCreateFieldPanel}
        />
      )}
    </Section>
  );
};
