import { interactions } from '../rules';

export const reactMenu = {
  '.eds__react-menu': {
    borderRadius: 'm',
    boxShadow: 'dropdown',
    maxHeight: 'menu.max-height',
    maxWidth: 'dropdown.max-width',
    minWidth: 'dropdown.min-width',
    overflowY: 'auto',
    paddingBottom: 2,
    paddingTop: 2,
  },
  '.eds__react-menu--embedded': {
    borderRadius: 'unset',
    boxShadow: 'unset',
    minWidth: 'unset',
    padding: 'unset',
    position: 'unset',
    width: '100%',
  },
  '.eds__react-menu__group:not(:last-child)': {
    borderBottom: 'border.divider',
  },
  '.eds__react-menu__header': {
    padding: 'unset',
    paddingBottom: 1,
    paddingTop: 3,
  },
  '.eds__react-menu__menu-button': {
    backgroundColor: 'transparent',
    border: 'unset',
    borderRadius: 'm',
    cursor: 'pointer',
    padding: 0,
    outlineColor: 'transparent',
  },
  '.eds__react-menu__menu-button:hover': {
    border: 'unset', // can be removed once ./react-menu_override.css is deprecated
    ...interactions[':hover'],
  },
  '.eds__react-menu__menu-button:focus': interactions[':focus'],
  '.eds__react-menu__menu-button:active': interactions[':active'],
  '.eds__react-menu__menu-button:hover:focus': interactions[':hover:focus'],
  '.eds__react-menu__menu-button:hover:focus:active':
    interactions[':hover:focus:active'],
  '.eds__react-menu__menu-button--embedded': {
    visibility: 'hidden',
  },
  '.eds__react-menu__menu-item': {
    display: 'block',
    padding: 0,
  },
};
