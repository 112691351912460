export default `
<svg
  aria-hidden="true"
  aria-label="report"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M9.25 11.75C8.8125 11.75 8.5 12.0938 8.5 12.5C8.5 12.9375 8.8125 13.25 9.25 13.25C9.65625 13.25 10 12.9375 10 12.5C10 12.0938 9.65625 11.75 9.25 11.75ZM9.25 14.75C8.8125 14.75 8.5 15.0938 8.5 15.5C8.5 15.9375 8.8125 16.25 9.25 16.25C9.65625 16.25 10 15.9375 10 15.5C10 15.0938 9.65625 14.75 9.25 14.75ZM15 15H11.5C11.2188 15 11 15.25 11 15.5C11 15.7812 11.2188 16 11.5 16H15C15.25 16 15.5 15.7812 15.5 15.5C15.5 15.25 15.25 15 15 15ZM16 6H14.4375C14.1875 4.875 13.1875 4 12 4C10.7812 4 9.78125 4.875 9.53125 6H8C6.875 6 6 6.90625 6 8V18C6 19.125 6.875 20 8 20H16C17.0938 20 18 19.125 18 18V8C18 6.90625 17.0938 6 16 6ZM12 5.5C12.4062 5.5 12.75 5.84375 12.75 6.25C12.75 6.6875 12.4062 7 12 7C11.5625 7 11.25 6.6875 11.25 6.25C11.25 5.84375 11.5625 5.5 12 5.5ZM16.5 18C16.5 18.2812 16.25 18.5 16 18.5H8C7.71875 18.5 7.5 18.2812 7.5 18V8C7.5 7.75 7.71875 7.5 8 7.5H8.5625C8.5 7.6875 8.5 7.84375 8.5 8V8.5C8.5 8.78125 8.71875 9 9 9H15C15.25 9 15.5 8.78125 15.5 8.5V8C15.5 7.84375 15.4688 7.6875 15.4062 7.5H16C16.25 7.5 16.5 7.75 16.5 8V18ZM15 12H11.5C11.2188 12 11 12.25 11 12.5C11 12.7812 11.2188 13 11.5 13H15C15.25 13 15.5 12.7812 15.5 12.5C15.5 12.25 15.25 12 15 12Z"
    fill="currentcolor"
  />
</svg>
`;
