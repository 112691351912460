import { evisortBlue, semibold } from '../../../assets/shared-styles/general';

const styles = {
  linkColor: {
    color: evisortBlue,
    fontWeight: semibold,
    textDecoration: 'none',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export default styles;
