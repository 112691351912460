import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { IconButton } from '~/eds';

// eslint-disable-line import/default
import EcCustomFieldItem from '../EcCustomFieldItem';
import styles from './EcCustomFieldTable.styles';

class EcCustomFieldTable extends Component {
  constructor(props) {
    super(props);

    this.onSortEnd = this.onSortEnd.bind(this);
    this.state = {
      reordering: false,
      items: this.props.listItems,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.listItems !== prevState.items && !prevState.reordering) {
      return { items: nextProps.listItems };
    } else {
      return null;
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      reordering: true,
      items: arrayMove(items, oldIndex, newIndex),
    }));
    setTimeout(() => {
      this.callServer();
    }, 300);
  };

  callServer() {
    const {
      section: { id },
      handleFieldReorder,
    } = this.props;
    const { items } = this.state;
    const formData = items.map((item, index) => {
      return {
        id: item.id,
        position: index,
      };
    });
    handleFieldReorder(formData, id);
  }

  renderCustomFieldItems() {
    const { classes, section, handleShowModal } = this.props;
    const { items } = this.state;

    const DragHandle = SortableHandle(() => <IconButton icon="reorder" />);

    const SortableItem = SortableElement(({ value }) => (
      <EcCustomFieldItem
        section={section}
        item={value}
        dragHandle={<DragHandle />}
        handleShowModal={handleShowModal}
      />
    ));

    const SortableList = SortableContainer(({ items }) => (
      <div>
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    ));

    if (!items.length) {
      return (
        <div className={classes.emptyMessage}>
          No field data in this section.
        </div>
      );
    }
    return (
      <SortableList
        items={items}
        onSortEnd={this.onSortEnd}
        useDragHandle
        helperClass={classes.sortableHelper}
      />
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.fieldTable}>
        <div className={classes.tableHeaderContainer}>
          <span className={classes.fieldNameContainer}>Field</span>
          <span className={classes.fieldTypeContainer}>Field Type</span>
          <span className={classes.fieldDocTypeContainer}>Document Types</span>
          <span className={classes.fieldDocTypeContainer}>Applies To</span>
          <span className={classes.headerColumn} />
        </div>
        {this.renderCustomFieldItems()}
      </div>
    );
  }
}

EcCustomFieldTable.propTypes = {
  classes: PropTypes.object.isRequired,
  listItems: PropTypes.array.isRequired,
};

export default injectSheet(styles)(EcCustomFieldTable);
