import {
  black1,
  black2,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const filterCategoryStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '44px',
  padding: '12px 8px 12px 16px',
  backgroundColor: 'transparent',
  border: 'none',
  color: black2,
  fontSize: '15px',
  fontWeight: semibold,
  cursor: 'pointer',
  textAlign: 'left',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
};

const styles = {
  modalContent: {
    display: 'flex',
  },
  filterCategoriesWrapper: {
    width: '50%',
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
  },
  filterSearchWrapper: {
    margin: '0 10px 10px',
  },
  filterSearch: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    '& input': {
      flexGrow: 1,
      border: 'none',
      margin: '0 8px',
      backgroundColor: 'transparent',
      fontSize: '14px',
      outline: 'none',
    },
    '& svg:last-child': {
      cursor: 'pointer',
    },
  },
  filterCategories: {
    overflowY: 'auto',
  },
  filterCategory: {
    ...filterCategoryStyles,
  },
  activeFilterCategory: {
    ...filterCategoryStyles,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    color: evisortBlue,
  },
  filterList: {
    width: '424px',
    height: '430px',
    overflowY: 'auto',
  },
  filterListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '92%',
    height: '48px',
    padding: '12px 0',
    margin: '0 16px',
    backgroundColor: 'transparent',
    border: 'none',
    color: black1,
    fontSize: '15px',
    '& span': {
      cursor: 'pointer',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
  filterListButton: {
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'left',
  },
};

export default styles;
