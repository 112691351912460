export default `
<svg
  aria-hidden="true"
  aria-label="trash-permanent"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M9.46875 10.4688C9.75 10.1875 10.2188 10.1875 10.5 10.4688L11.9688 11.9688L13.4688 10.4688C13.75 10.1875 14.2188 10.1875 14.5 10.4688C14.8125 10.7812 14.8125 11.25 14.5 11.5312L13.0312 13L14.5 14.4688C14.8125 14.7812 14.8125 15.25 14.5 15.5312C14.2188 15.8438 13.75 15.8438 13.4688 15.5312L11.9688 14.0625L10.5 15.5312C10.2188 15.8438 9.75 15.8438 9.46875 15.5312C9.15625 15.25 9.15625 14.7812 9.46875 14.4688L10.9375 13L9.46875 11.5312C9.15625 11.25 9.15625 10.7812 9.46875 10.4688ZM14.9062 4.78125L16.0625 6.5H18.25C18.6562 6.5 19 6.84375 19 7.25C19 7.6875 18.6562 8 18.25 8H18V17.5C18 18.9062 16.875 20 15.5 20H8.5C7.09375 20 6 18.9062 6 17.5V8H5.75C5.3125 8 5 7.6875 5 7.25C5 6.84375 5.3125 6.5 5.75 6.5H7.90625L9.0625 4.78125C9.375 4.3125 9.9375 4 10.5312 4H13.4375C14.0312 4 14.5938 4.3125 14.9062 4.78125ZM9.71875 6.5H14.25L13.6562 5.625C13.625 5.5625 13.5312 5.5 13.4375 5.5H10.5312C10.4375 5.5 10.3438 5.5625 10.3125 5.625L9.71875 6.5ZM7.5 17.5C7.5 18.0625 7.9375 18.5 8.5 18.5H15.5C16.0312 18.5 16.5 18.0625 16.5 17.5V8H7.5V17.5Z"
    fill="currentcolor"
  />
</svg>
`;
