import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout, Text } from '~/ui';

function Header({ rightContent, title }) {
  return (
    <FlexLayout
      alignItems="center"
      flexGrow="1"
      justifyContent="space-between"
      py={3}
      space={2}
      sx={{ minHeight: 'card-header-height' }}
    >
      {typeof title === 'string' ? (
        <Text color="black" variant="l-dense-bold" sx={{ flexGrow: 1 }}>
          {title}
        </Text>
      ) : (
        title
      )}
      <Box onClick={(e) => e.stopPropagation()}>{rightContent}</Box>
    </FlexLayout>
  );
}

Header.propTypes = {
  /** React children */
  rightContent: PT.node,
  /** Title for the card */
  title: PT.oneOfType([PT.string, PT.node]).isRequired,
};

export default Header;
