const bottom = (bottom = 0) => ({
  bottom,
  position: 'sticky',
  zIndex: 'foward',
});

const top = (top = 0) => ({
  position: 'sticky',
  top,
  zIndex: 'foward',
});

export const sticky = {
  bottom,
  pageHeader: {
    ...top(0),
    zIndex: 'panel',
  },
  top,
};
