import {
  black1,
  black2,
  black3,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const inputContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '44px',
  border: 'solid 1px rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
  marginRight: '24px',
  padding: '12px 16px',
  paddingRight: '0',
};

const styles = {
  settingsRow: {
    display: 'flex',
  },
  settingsRowSeparator: {
    margin: '24px 0',
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  inputLabel: {
    marginBottom: '12px',
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
  },
  inputContainer: {
    ...inputContainerStyles,
    width: '240px',
    '& input': {
      flexGrow: 1,
      border: 'none',
      borderRadius: '4px',
      color: black2,
      fontSize: '15px',
      outline: 'none',
      caretColor: evisortBlue,
    },
  },
  fakeInputContainer: {
    ...inputContainerStyles,
    width: '504px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '& div': {
      color: black3,
      fontSize: '15px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& svg': {
      margin: '0 12px',
    },
  },
  protectedInputsMessage: {
    width: '580px',
    marginTop: '16px',
    color: black2,
    fontSize: '13px',
    lineHeight: '1.47',
  },
};

export default styles;
