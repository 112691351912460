import React, { useState } from 'react';

import { SEARCH_RESULTS_LIMIT } from '~/constants/max_lengths';
import { Alert, Box, Text } from '~/ui';

const SearchLimitWarning = ({ resultCount }) => {
  const [showAlert, setShowAlert] = useState(true);

  return showAlert && resultCount > SEARCH_RESULTS_LIMIT ? (
    <Box mb={4}>
      <Alert onDismiss={() => setShowAlert(false)} enableIcon variant="warning">
        <Text>
          Your search query generated more than {SEARCH_RESULTS_LIMIT} results.{' '}
        </Text>
        <Text variant="s-dense-bold">
          Only {SEARCH_RESULTS_LIMIT} search results will be shown,{' '}
        </Text>
        please refine
        <Text>
          {' '}
          your search query or export the full set of search result for viewing.
        </Text>
      </Alert>
    </Box>
  ) : null;
};

export default SearchLimitWarning;
