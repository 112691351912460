import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AddSearchResultsToGroup,
  AddSearchResultsToGroupV2,
  CopyMoveSearchResults,
  DeleteSearchResults,
  EditInAnalyzer,
  EditSearchResults,
  ExportSearchResults,
  flattenFolderTree,
  SearchBarControls,
  SearchFilters,
  SearchResult,
  toFilter,
} from '~/components/SearchV2';
import { getHandlersKeyFromSearchResult } from '~/components/SearchV2/SearchResult/SearchResult.utils';
import { useDocumentMultipleLocationsModal } from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { EmptyPage, Layout, types, useTableSelection } from '~/eds';
import { BulkActionType, FeatureFlagType, TableContextType } from '~/enums';
import { DEFAULT_CHAT_CONTEXT } from '~/features/ask-anything';
import { DEFAULT_SEARCH_ID } from '~/features/ask-anything/ask-anything-button/utils';
import {
  convertQueryV2ToQueryV3,
  useDocumentsBulkActionsPanel,
} from '~/features/search';
import { FlagType, useFlag } from '~/flags';
import { useHasFeatureFlag, usePinnedFilters, useTableSettings } from '~/hooks';
import { api, selectors } from '~/redux';
import { toSearchField } from '~/redux/api/methods';
import { PersistRedux } from '~/redux/persist';
import { selectSearchResultsCache } from '~/redux/selectors/search';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { initContext } from '~/redux/slices/documentsNavigation';
import searchSlice, { pathsToPersist } from '~/redux/slices/search';
import { ColumnSortOrder, FolderTree } from '~/types';
import {
  MODAL_DELETE,
  MODAL_DOCUMENT_GROUP,
  MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
  MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
  MODAL_EXCEL_EXPORT,
  MODAL_FOLDER_TREE,
  MODAL_MULTI_EDIT,
} from '~/types/modal.types';
import { ERROR } from '~/types/toast.types';
import { parseNavigationResponse } from '~/utils';
import { defaultErrorMessage } from '~/utils/codedError';

function Search() {
  const dispatch = useDispatch();

  const handleOnNavigate = async () => {
    const initOptions = {
      api: navigationAPI,
      getPosition,
      name: 'search results',
      parseResponse: parseNavigationResponse,
      fromLocation: {
        pathname: '/search',
        hash: '',
        search: '',
      },
    };
    // @ts-ignore -- skip typing legacy code from redux upgrade
    dispatch(initContext(initOptions as any));
  };

  const [
    setDocumentSelected,
    renderMultipleLocationModal,
    setOpenDocumentInNewTab,
  ] = useDocumentMultipleLocationsModal(
    getHandlersKeyFromSearchResult,
    false,
    handleOnNavigate,
  );

  const query = useSelector(selectors.selectQuery);
  const pilotSearchQuery = useSelector(selectors.selectPilotSearchQuery);
  const unsupportedSavedSearchQuery = useSelector(
    selectors.selectUnsupportedSavedSearchQuery,
  );
  const queryFields = useSelector(selectors.selectQueryFields);
  const filters = useSelector(selectors.selectActiveSearchFilters);
  const filtersRef = useRef(filters);
  const booleanQuery = useSelector(selectors.selectBooleanQuery);
  const pageSize = useSelector(selectors.selectSearchPageSize);
  const page = useSelector(selectors.selectSearchPage);
  const sortBy = useSelector(selectors.selectSearchSortBy);
  const resultsCache = useSelector(selectSearchResultsCache);

  const tableSelection = useTableSelection();

  const shouldUseNewGroupUI = useHasFeatureFlag(
    FeatureFlagType.DocumentGroupRevamp,
  );
  const isBulkActionsOnSidepanelEnabled = useFlag(
    FlagType.DocumentsBulkActionsOnSidepanel,
  );
  const hasUserPreferencesPinnedFilters = useFlag(FlagType.PinnedFilters);

  const bulkActionsContext = useSelector(selectors.selectSearchResultModal);

  const {
    panel,
    limitExceededModal,
    setActivePanel,
  } = useDocumentsBulkActionsPanel({
    selectedDocumentIds: (bulkActionsContext?.context as types.TableState)
      ?.selectedRowIds,
    isAllSelected: (bulkActionsContext?.context as types.TableState)
      ?.isAllRowsSelected,
    totalCount: (bulkActionsContext?.context as types.TableState)?.totalCount,
    query: pilotSearchQuery,
    searchAPI: 'v2',
    onActionCompleted: refreshSearchResult,
    resultsCache: resultsCache,
  });

  const [
    pilotSearchDocuments,
    pilotSearchDocumentsResult,
  ] = api.endpoints.pilotSearchDocuments.useLazyQuery();

  useEffect(() => {
    if (pilotSearchDocumentsResult.isError) {
      showToast(
        ERROR,
        get(
          pilotSearchDocumentsResult,
          'error.response.data.detail',
          defaultErrorMessage,
        ),
      );
    }
  }, [pilotSearchDocumentsResult.isError]);

  const {
    data: sections,
    isFetching: isFetchingSections,
  } = api.endpoints.getFilterSections.useQuery(undefined);
  const {
    getPinnedFilters,
    result: {
      data: userPreferencesDefaultFilters,
      isFetching: isFetchingPinnedFilters,
      isSuccess: isSuccessPinnedFilters,
    },
  } = usePinnedFilters(sections?.fields);

  const isLoadingPinnedFilters =
    hasUserPreferencesPinnedFilters &&
    (!isSuccessPinnedFilters || isFetchingPinnedFilters);
  const [
    getPositionByHandler,
  ] = api.endpoints.getSearchPositionByHandler.useLazyQuery();
  const [
    getHandlersByPosition,
  ] = api.endpoints.getSearchHandlersByPosition.useLazyQuery();

  const {
    data: clauseSuggestions,
  } = api.endpoints.getClauseSuggestions.useQuery(undefined);

  const { data: folderTreeData } = api.endpoints.getFolderTree.useQuery(
    undefined,
  );

  const { tableSettings } = useTableSettings(TableContextType.Search);
  useEffect(() => {
    if (tableSettings) {
      dispatch(searchSlice.actions.setColumnOrder(tableSettings.columnOrder));
      dispatch(
        searchSlice.actions.patchColumnWidths(tableSettings.columnWidths),
      );
      dispatch(searchSlice.actions.setPageSize(tableSettings.pageSize));
      dispatch(searchSlice.actions.setSortBy(tableSettings.sortBy));
    }
  }, [tableSettings]);

  const fields = sections?.fields;
  const fieldGroups = sections?.fieldGroups;

  const hasNewPins = isLoadingPinnedFilters || userPreferencesDefaultFilters;
  const defaultFilters = useMemo(() => {
    return hasNewPins
      ? userPreferencesDefaultFilters
      : sections?.defaultFilters && hasUserPreferencesPinnedFilters
      ? [...sections?.defaultFilters, ...['folder', 'clause'].map(toFilter)]
      : sections?.defaultFilters;
  }, [hasNewPins, sections?.defaultFilters, hasUserPreferencesPinnedFilters]);

  const folders = folderTreeData?.children;
  const folderTree = useMemo(
    () => (!folders ? [] : flattenFolderTree(folders as FolderTree[])),
    [folders],
  );

  const filterAreDirty = useSelector(selectors.selectSearchFiltersAreDirty);

  useEffect(() => {
    if (hasUserPreferencesPinnedFilters) {
      getPinnedFilters('SEARCH');
    }
  }, [hasUserPreferencesPinnedFilters]);

  useEffect(() => {
    if (!filterAreDirty && defaultFilters?.length) {
      dispatch(searchSlice.actions.setFilters(defaultFilters));
    }
    if (defaultFilters?.length) {
      dispatch(searchSlice.actions.setDefaultFilters(defaultFilters));
    }
  }, [defaultFilters]);

  const searchDocumentsParams = {
    query: pilotSearchQuery,
    fields: queryFields.map((item) => toSearchField(item)),
    page,
    pageSize,
    sortBy,
  };

  useEffect(() => {
    if (query || unsupportedSavedSearchQuery) {
      pilotSearchDocuments(searchDocumentsParams);
    }
  }, [query, queryFields, page, pageSize, sortBy, unsupportedSavedSearchQuery]);

  useEffect(() => {
    if (
      fields &&
      (filters.length || filtersRef.current.length) &&
      filterAreDirty
    ) {
      dispatch(searchSlice.actions.clearUnsupportedQuery());
      dispatch(searchSlice.actions.setQuery({ booleanQuery, filters, fields }));
    }
    filtersRef.current = filters;
  }, [filters]);

  useEffect(() => {
    if (folderTree.length) {
      dispatch(
        searchSlice.actions.setFolderTree({
          fieldId: 'folder',
          values: folderTree,
        }),
      );
    }
  }, [folderTree]);

  useEffect(() => {
    if (clauseSuggestions) {
      dispatch(
        searchSlice.actions.setClauseSuggestions({
          fieldId: 'clause',
          values: clauseSuggestions.suggestions,
        }),
      );
    }
  }, [clauseSuggestions]);

  const handlePaginate = useCallback(({ page = 1 }) => {
    dispatch(searchSlice.actions.setPage(page));
  }, []);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    dispatch(searchSlice.actions.setPageSize(pageSize));
  }, []);

  const handleSortBy = useCallback((sortBy: ColumnSortOrder) => {
    dispatch(searchSlice.actions.setSortBy(sortBy));
  }, []);

  const {
    data,
    error,
    isFetching,
  } = api.endpoints.pilotSearchDocuments.useQueryState(searchDocumentsParams);

  useEffect(() => {
    if (data?.results) {
      dispatch(searchSlice.actions.patchSearchResultsCache(data.results));
      if (data?.results.length > 0) {
        dispatch(
          chatbotSlice.actions.setContext([
            DEFAULT_CHAT_CONTEXT,
            {
              label: 'All Results',
              query: convertQueryV2ToQueryV3(
                searchDocumentsParams?.query ?? [],
              ),
              title: `All Results (${data.count})`,
              icon: 'search',
              primary: true,
              searchEntity: 'document',
              selectedIds: [],
              id: DEFAULT_SEARCH_ID,
            },
          ]),
        );
      }
    }
  }, [data]);

  const handleAddToGroup = useCallback((tableState: types.TableState) => {
    dispatch(
      searchSlice.actions.setModal({
        modalType: MODAL_DOCUMENT_GROUP,
        context: tableState,
      }),
    );
  }, []);
  const handleCopyOrMove = useCallback(
    (bulkAction: BulkActionType, tableState: types.TableState) => {
      setActivePanel(bulkAction);

      dispatch(
        searchSlice.actions.setModal({
          modalType: MODAL_FOLDER_TREE,
          context: { ...tableState, bulkAction },
        }),
      );
    },
    [],
  );
  const handleCopy = useCallback(
    (tableState: types.TableState) => {
      handleCopyOrMove('copy', tableState);
    },
    [handleCopyOrMove],
  );
  const handleMove = useCallback(
    (tableState: types.TableState) => {
      handleCopyOrMove('move', tableState);
    },
    [handleCopyOrMove],
  );
  const handleEdit = useCallback((tableState: types.TableState) => {
    dispatch(
      searchSlice.actions.setModal({
        modalType: MODAL_MULTI_EDIT,
        context: {
          ...tableState,
        },
      }),
    );
    setActivePanel('edit');
  }, []);

  const handleEditClauses = useCallback((tableState: types.TableState) => {
    dispatch(
      searchSlice.actions.setModal({
        modalType: '',
        context: {
          ...tableState,
        },
      }),
    );
    setActivePanel('edit-clauses');
  }, []);

  const handleDelete = useCallback((tableState: types.TableState) => {
    dispatch(
      searchSlice.actions.setModal({
        modalType: MODAL_DELETE,
        context: { ...tableState },
      }),
    );
    setActivePanel('delete');
  }, []);

  const resetSearchResultSelection = () => {
    tableSelection.clear();
  };

  useEffect(() => {
    resetSearchResultSelection();
  }, [query]);
  useEffect(() => {
    dispatch(
      chatbotSlice.actions.setContext([
        { ...DEFAULT_CHAT_CONTEXT, primary: true },
      ]),
    );
  }, []);

  function refreshSearchResult() {
    resetSearchResultSelection();
    pilotSearchDocuments(searchDocumentsParams);
  }

  const navigationAPI = (position: number) => {
    return getHandlersByPosition({
      order_by_field: sortBy.id,
      order: sortBy.desc ? 'desc' : 'asc',
      query: pilotSearchQuery!,
      position,
    }).unwrap();
  };

  const getPosition = (handler_id: number) => {
    return getPositionByHandler({
      order_by_field: sortBy.id,
      order: sortBy.desc ? 'desc' : 'asc',
      handler_id,
      query: pilotSearchQuery!,
    }).unwrap();
  };

  const showExportModal = useCallback((tableState: types.TableState) => {
    dispatch(
      searchSlice.actions.setModal({
        modalType: MODAL_EXCEL_EXPORT,
        context: { ...tableState },
      }),
    );
  }, []);

  const showSaveView = useCallback((tableState: types.TableState) => {
    dispatch(
      searchSlice.actions.setModal({
        modalType: MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
        context: { ...tableState },
      }),
    );
  }, []);

  const showLoadView = useCallback((tableState: types.TableState) => {
    dispatch(
      searchSlice.actions.setModal({
        modalType: MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
        context: { ...tableState },
      }),
    );
  }, []);

  const [exportExcel, exportResult] = api.endpoints.exportExcel.useLazyQuery();
  const { isFetching: isExporting } = exportResult;

  return (
    <>
      {pathsToPersist.map((path, index) => (
        <PersistRedux key={index} path={path!} />
      ))}
      <SearchBarControls />
      {!isFetchingSections &&
        fields &&
        fieldGroups &&
        !unsupportedSavedSearchQuery && (
          <SearchFilters
            fields={fields}
            groups={fieldGroups}
            showInlineManageFilters={false}
          />
        )}
      {unsupportedSavedSearchQuery && (
        <Layout spacing={4}>
          <EditInAnalyzer query={unsupportedSavedSearchQuery} />
        </Layout>
      )}
      {!isFetching && !query && !unsupportedSavedSearchQuery ? (
        <EmptyPage preset="no-search" />
      ) : (
        <SearchResult
          data={data}
          error={error}
          isLoading={isFetching}
          onPaginate={handlePaginate}
          //Missing type in useDocumentMultipleLocationsModal
          onViewResult={(document: any, openInNewTab: boolean) => {
            setDocumentSelected(document);
            // @ts-ignore action param type
            setOpenDocumentInNewTab(openInNewTab);
          }}
          tableSelection={tableSelection}
          page={page}
          pageSize={pageSize}
          onAddToGroup={handleAddToGroup}
          onCopy={handleCopy}
          onMove={handleMove}
          onEdit={handleEdit}
          onEditClauses={handleEditClauses}
          onDelete={handleDelete}
          onExport={showExportModal}
          onSaveView={showSaveView}
          onLoadView={showLoadView}
          onNavigate={handleOnNavigate}
          isExporting={isExporting}
          resultsCache={resultsCache}
          sortBy={sortBy}
          onSort={handleSortBy}
          onPageSizeChange={handlePageSizeChange}
        />
      )}
      {shouldUseNewGroupUI ? (
        <AddSearchResultsToGroupV2 />
      ) : (
        <AddSearchResultsToGroup />
      )}
      {!isBulkActionsOnSidepanelEnabled ? (
        <>
          <CopyMoveSearchResults
            onActionComplete={refreshSearchResult}
            resultsCache={resultsCache}
          />
          <EditSearchResults
            onActionComplete={refreshSearchResult}
            resultsCache={resultsCache}
          />
          <DeleteSearchResults
            onActionComplete={refreshSearchResult}
            resultsCache={resultsCache}
          />
        </>
      ) : (
        <>
          {limitExceededModal}
          {panel}
        </>
      )}
      <ExportSearchResults
        onActionComplete={refreshSearchResult}
        searchResult={data}
        onExport={exportExcel}
        exportResult={exportResult}
      />
      {renderMultipleLocationModal(null)}
    </>
  );
}

export default Search;
