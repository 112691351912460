import React from 'react';

const EnterIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 26">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M5.71 15.71l4.58 4.58c.39.39 1.03.39 1.42 0 .39-.39.39-1.03 0-1.42L8.83 16H19c.55 0 1-.45 1-1V6.2c0-.55-.45-1-1-1s-1 .45-1 1V14H8.83l2.88-2.87c.39-.39.39-1.03 0-1.42-.39-.39-1.03-.39-1.42 0l-4.58 4.58c-.39.39-.39 1.03 0 1.42z"
        />
      </g>
    </svg>
  );
};

export default EnterIcon;
