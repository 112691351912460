import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSearchableFields } from '~/components/SearchV2/utils';
import BaseSaveView from '~/components/Shared/BaseSaveView';
import { TableViewContextType } from '~/enums';
import { selectors } from '~/redux';
import documentGroup from '~/redux/slices/documentGroup';

const GroupDocumentsSaveView = () => {
  const modal = useSelector(selectors.selectDocGroupModal);
  const columnOrder = useSelector(selectors.selectDocGroupColumnOrder);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(documentGroup.actions.setModal());
  const { modalType } = modal || {};

  const fields = getSearchableFields(columnOrder);

  return (
    <BaseSaveView
      context={TableViewContextType.GROUP}
      fields={fields}
      hideModal={hideModal}
      modalType={modalType}
    />
  );
};

export default GroupDocumentsSaveView;
