import {
  testIsFilter,
  testIsOperator,
} from '~/features/search/utils/queryUtils';
import { toSearchV3Filter } from '~/redux/api/methods/searchV3';
import { SearchFilter, SectionEntity } from '~/types';

export const toSectionFiltersOperators = (
  query: SectionEntity[],
  searchFilters: Record<string, SearchFilter>,
) => {
  return query.map((item: SectionEntity) => {
    if (item.type === 'operator') {
      return item;
    } else {
      const filters = item.value
        .filter(testIsFilter)
        .map((fieldOperator) => toSearchV3Filter(fieldOperator, searchFilters));
      const filterOperator = item.value.filter(testIsOperator)[0];
      let val;
      if (filterOperator) {
        val = { filters, operator: filterOperator };
      } else {
        val = { filters };
      }
      return {
        type: 'section',
        value: val,
      };
    }
  });
};
