export default `
<svg
  aria-hidden="true"
  aria-label="file-spreadsheet"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <g clip-path="url(#clip0_24907_214277)">
    <path
      d="M18 11H14V10H18M18 13H14V12H18M18 15H14V14H18M19.7109 7C19.9036 7.19271 20 7.42969 20 7.71094V19C20 19.1406 19.974 19.2708 19.9219 19.3906C19.8698 19.5104 19.7995 19.6172 19.7109 19.7109C19.6172 19.7995 19.5104 19.8698 19.3906 19.9219C19.2708 19.974 19.1406 20 19 20H9C8.85938 20 8.72917 19.974 8.60938 19.9219C8.48958 19.8698 8.38542 19.7995 8.29688 19.7109C8.20312 19.6172 8.13021 19.5104 8.07812 19.3906C8.02604 19.2708 8 19.1406 8 19V17H4.89844C4.77344 17 4.65885 16.9766 4.55469 16.9297C4.44531 16.8828 4.34896 16.8177 4.26562 16.7344C4.18229 16.651 4.11719 16.5573 4.07031 16.4531C4.02344 16.3438 4 16.2266 4 16.1016V8.89844C4 8.77344 4.02344 8.65885 4.07031 8.55469C4.11719 8.44531 4.18229 8.34896 4.26562 8.26562C4.34896 8.18229 4.44531 8.11719 4.55469 8.07031C4.65885 8.02344 4.77344 8 4.89844 8H8V5C8 4.85938 8.02604 4.72917 8.07812 4.60938C8.13021 4.48958 8.20312 4.38542 8.29688 4.29688C8.38542 4.20312 8.48958 4.13021 8.60938 4.07812C8.72917 4.02604 8.85938 4 9 4H16.2891C16.5703 4 16.8073 4.09635 17 4.28906M17 7H18.2891L17 5.71094M8.52344 13.0156C8.54948 13.099 8.58594 13.1771 8.63281 13.25L9.57031 15H10.7344L9.17188 12.4766L10.6953 10H9.60156L8.73438 11.5781C8.70312 11.6354 8.67448 11.6953 8.64844 11.7578C8.6224 11.8151 8.59115 11.8724 8.55469 11.9297C8.53906 11.8932 8.52083 11.8464 8.5 11.7891C8.47396 11.7318 8.44271 11.6667 8.40625 11.5938L7.59375 10H6.40625L7.88281 12.4922L6.26562 15H7.4375M19 8H17C16.8594 8 16.7292 7.97396 16.6094 7.92188C16.4896 7.86979 16.3854 7.79948 16.2969 7.71094C16.2031 7.61719 16.1302 7.51042 16.0781 7.39062C16.026 7.27083 16 7.14062 16 7V5H9V8H12.1016C12.2266 8 12.3438 8.02344 12.4531 8.07031C12.5573 8.11719 12.651 8.18229 12.7344 8.26562C12.8177 8.34896 12.8828 8.44531 12.9297 8.55469C12.9766 8.65885 13 8.77344 13 8.89844V16.1016C13 16.2266 12.9766 16.3438 12.9297 16.4531C12.8828 16.5573 12.8177 16.651 12.7344 16.7344C12.651 16.8177 12.5573 16.8828 12.4531 16.9297C12.3438 16.9766 12.2266 17 12.1016 17H9V19H19V8Z"
      fill="black"
    />
    <path
      d="M19 19.5H9C8.86458 19.5 8.7474 19.4505 8.64844 19.3516C8.54948 19.2526 8.5 19.1354 8.5 19V5C8.5 4.86458 8.54948 4.7474 8.64844 4.64844C8.7474 4.54948 8.86458 4.5 9 4.5H16.2891C16.4297 4.5 16.5495 4.54948 16.6484 4.64844L19.3516 7.35156C19.4505 7.45052 19.5 7.57031 19.5 7.71094V19C19.5 19.1354 19.4505 19.2526 19.3516 19.3516C19.2526 19.4505 19.1354 19.5 19 19.5Z"
      fill="#FAFAFA"
    />
    <path
      d="M19.7109 7C19.9036 7.19271 20 7.42969 20 7.71094V19C20 19.1406 19.974 19.2708 19.9219 19.3906C19.8698 19.5104 19.7995 19.6172 19.7109 19.7109C19.6172 19.7995 19.5104 19.8698 19.3906 19.9219C19.2708 19.974 19.1406 20 19 20H9C8.85938 20 8.72917 19.974 8.60938 19.9219C8.48958 19.8698 8.38542 19.7995 8.29688 19.7109C8.20312 19.6172 8.13021 19.5104 8.07812 19.3906C8.02604 19.2708 8 19.1406 8 19V5C8 4.85938 8.02604 4.72917 8.07812 4.60938C8.13021 4.48958 8.20312 4.38542 8.29688 4.29688C8.38542 4.20312 8.48958 4.13021 8.60938 4.07812C8.72917 4.02604 8.85938 4 9 4H16.2891C16.5703 4 16.8073 4.09635 17 4.28906M17 7H18.2891L17 5.71094M19 19V8H17C16.8594 8 16.7292 7.97396 16.6094 7.92188C16.4896 7.86979 16.3854 7.79948 16.2969 7.71094C16.2031 7.61719 16.1302 7.51042 16.0781 7.39062C16.026 7.27083 16 7.14062 16 7V5H9V19H19Z"
      fill="#797774"
    />
    <path d="M18 13H14V12H18V13Z" fill="#107C41" />
    <path d="M18 11H14V10H18V11Z" fill="#33C481" />
    <path d="M18 15H14V14H18V15Z" fill="#134A2C" />
    <path
      d="M12.1016 17H4.89844C4.77344 17 4.65885 16.9766 4.55469 16.9297C4.44531 16.8828 4.34896 16.8177 4.26562 16.7344C4.18229 16.651 4.11719 16.5573 4.07031 16.4531C4.02344 16.3438 4 16.2266 4 16.1016V8.89844C4 8.77344 4.02344 8.65885 4.07031 8.55469C4.11719 8.44531 4.18229 8.34896 4.26562 8.26562C4.34896 8.18229 4.44531 8.11719 4.55469 8.07031C4.65885 8.02344 4.77344 8 4.89844 8H12.1016C12.2266 8 12.3438 8.02344 12.4531 8.07031C12.5573 8.11719 12.651 8.18229 12.7344 8.26562C12.8177 8.34896 12.8828 8.44531 12.9297 8.55469C12.9766 8.65885 13 8.77344 13 8.89844V16.1016C13 16.2266 12.9766 16.3438 12.9297 16.4531C12.8828 16.5573 12.8177 16.651 12.7344 16.7344C12.651 16.8177 12.5573 16.8828 12.4531 16.9297C12.3438 16.9766 12.2266 17 12.1016 17Z"
      fill="#107C41"
    />
    <path
      d="M6.26562 15L7.88281 12.4922L6.40625 10H7.59375L8.40625 11.5938C8.44271 11.6667 8.47135 11.7318 8.49219 11.7891C8.51302 11.8464 8.53385 11.8932 8.55469 11.9297C8.59115 11.8724 8.6224 11.8151 8.64844 11.7578C8.67448 11.6953 8.70312 11.6354 8.73438 11.5781L9.60156 10H10.6953L9.17188 12.4766L10.7344 15H9.57031L8.63281 13.25C8.58594 13.1771 8.54948 13.099 8.52344 13.0156L7.4375 15H6.26562Z"
      fill="white"
    />
  </g>
  <defs>
    <clipPath id="clip0_24907_214277">
      <rect width="16" height="16" fill="white" transform="translate(4 4)" />
    </clipPath>
  </defs>
</svg>
`;
