import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  analyzerBulkEdit,
  analyzerBulkEditData,
} from '~/components/AnalyzerResultsPage/AnalyzerResultsPage.data';
import EcModal from '~/components/Shared/EcModal';
import { BULK_EDIT_LIMIT } from '~/constants/max_lengths';
import { selectors } from '~/redux';
import search from '~/redux/slices/search';
import { MODAL_MULTI_EDIT } from '~/types/modal.types';

const EditSearchResults = ({ onActionComplete, resultsCache = {} }) => {
  const modal = useSelector(selectors.selectSearchResultModal);
  const sortBy = useSelector(selectors.selectSearchSortBy);

  const dispatch = useDispatch();
  const hideModal = () => dispatch(search.actions.setModal());
  const pilotSearchQuery = useSelector(selectors.selectPilotSearchQuery);

  const { modalType, context } = modal || {};

  if (modalType !== MODAL_MULTI_EDIT) return null;

  const { data, isAllRowsSelected, selectedRowIds, totalCount } = context;
  const selectedDocumentsCount = isAllRowsSelected ? totalCount : data.length;

  const getHandlerIds = () => {
    const selectedItems = selectedRowIds.map((id) => resultsCache[id]);

    const handlersIds = selectedItems.flatMap((document) =>
      document.document_handlers.map((handler) => handler.id),
    );

    return handlersIds;
  };

  const getModalData = () => {
    return analyzerBulkEditData(
      getHandlerIds(),
      pilotSearchQuery,
      isAllRowsSelected,
    );
  };

  return (
    <EcModal
      modalType={modalType}
      width="800px"
      hideModal={hideModal}
      getModalData={getModalData}
      overLimit={selectedDocumentsCount > BULK_EDIT_LIMIT}
      update={(fieldsData) =>
        analyzerBulkEdit(
          {
            fields: fieldsData,
            query: isAllRowsSelected ? pilotSearchQuery : undefined,
            document_handler_ids: isAllRowsSelected ? [] : getHandlerIds(),
          },
          {
            order_by_field: sortBy.id,
            order: sortBy.desc ? 'desc' : 'asc',
          },
        )
      }
      onUpdateComplete={onActionComplete}
    />
  );
};

export default EditSearchResults;
