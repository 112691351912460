import {
  Box,
  ContentContainer,
  Filename,
  FileSystem,
  formatDate,
  Layout,
  StatusText,
} from '~/eds';
import { HttpStatusCodeType } from '~/enums';

import { ResolvedCitationEntity } from '../../../types';

interface Props {
  isLoading?: boolean;
  text: string;
  resolvedCitation?: ResolvedCitationEntity;
  error?: Error;
}

export const CitationDetails = ({
  error,
  isLoading,
  text,
  resolvedCitation,
}: Props) => {
  const loadingContent = { isLoading, shimmer: { height: '30px' } };
  return (
    <Layout spacing={3} direction="column">
      <ContentContainer loadingContent={loadingContent}>
        {resolvedCitation?.fileName ? (
          <Filename
            name={resolvedCitation?.fileName ?? ''}
            link={{ pathname: resolvedCitation?.link }}
          />
        ) : undefined}
      </ContentContainer>

      <Box borderRadius="m" p={3} bg="status.active.secondary">
        {text}
      </Box>
      <ContentContainer loadingContent={loadingContent}>
        <Layout direction="column" spacing={1} color="text.secondary">
          {
            // If the error is not found, show a message that the file has been deleted
            error?.cause === HttpStatusCodeType.NotFound ? (
              <StatusText
                text={error.message}
                icon="status-warning"
                iconColor="status.warning"
                textColor="text.secondary"
                iconSpacing="s"
              />
            ) : (
              <>
                {resolvedCitation?.path ? (
                  <FileSystem
                    folder={{
                      path: resolvedCitation?.path,
                    }}
                  />
                ) : null}
                {resolvedCitation?.lastModified ? (
                  <StatusText
                    text={`Last modified ${formatDate(
                      resolvedCitation?.lastModified ?? null,
                      'full',
                    )}`}
                    icon="field-date"
                    iconColor="status.info"
                    textColor="text.secondary"
                    iconSpacing="s"
                  />
                ) : null}
              </>
            )
          }
        </Layout>
      </ContentContainer>
    </Layout>
  );
};
