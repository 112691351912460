import React, { memo } from 'react';

import { Layout, Section } from '../../../components';
import { SharedPageLayoutProps } from '../../../types';
import { PageLayout } from '../PageLayout';
import { NavCards } from './NavCards';
import type { Nav } from './types';

interface Props extends SharedPageLayoutProps {
  navs?: Nav[];
  sections?: {
    title: string;
    navs: Nav[];
    description?: string;
  }[];
  columns?: number;
}

export const NavsPageLayout = memo(
  ({ columns = 1, navs, sections, ...rest }: Props) => {
    return (
      <PageLayout {...rest}>
        {navs && <NavCards navs={navs} />}
        {sections && (
          <Layout preset="sections">
            {sections.map((section) => (
              <Section
                key={section.title}
                description={section.description}
                title={section.title}
              >
                <NavCards columns={columns} navs={section.navs} />
              </Section>
            ))}
          </Layout>
        )}
      </PageLayout>
    );
  },
);
