import { auth } from '~/services';
import { Nullable } from '~/types';

export const getSsoProvider = async (
  client: string,
): Promise<Nullable<{ id: string; name: string }>> => {
  const request = { client };
  const response = (await auth.post('auth/login-info', request)) as {
    password: {
      enabled: boolean;
    };
    sso: {
      enabled: boolean;
      error: Nullable<string>;
      provider_name: Nullable<string>;
      signon_url: Nullable<string>;
    };
  };

  if (response.sso.error) {
    throw new Error(response.sso.error);
  }

  if (
    !response.password.enabled &&
    response.sso.provider_name &&
    response.sso.signon_url
  ) {
    return {
      id: response.sso.signon_url,
      name: response.sso.provider_name,
    };
  }

  return null;
};
