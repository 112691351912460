import React from 'react';
import { ValueContainerProps } from 'react-select';

import { Option } from '../../../types';
import { Layout } from '../../Layout';

export const ValueContainer = (props: ValueContainerProps<Option, boolean>) => {
  return (
    <Layout
      wrap
      align="center"
      flex="auto"
      overflowX="hidden"
      position="relative"
      py={0.5}
      spacing={1}
      minW={0}
    >
      {props.children}
    </Layout>
  );
};
