import React from 'react';

import { fieldTypeLabels } from '~/constants/workflow';
import { WorkflowFieldType } from '~/enums';
import { sortByStringValue } from '~/utils/array';

import RadioItem from './RadioItem';

const fieldTypeOptions = Object.values(WorkflowFieldType)
  .map((fieldType) => ({
    label: fieldTypeLabels[fieldType],
    value: fieldType,
  }))
  .filter(
    (option) =>
      ![
        WorkflowFieldType.Boolean,
        WorkflowFieldType.DateSigned,
        WorkflowFieldType.Signature,
        WorkflowFieldType.ConditionalText,
        WorkflowFieldType.Initials,
      ].includes(option.value),
  )
  .sort(sortByStringValue('label'));

function RadioFieldTypes({ selectedFieldDefinition, onClick }) {
  return (
    <>
      {fieldTypeOptions.map((option) => (
        <RadioItem
          key={option.value}
          option={option}
          selected={selectedFieldDefinition?.fieldType === option.value}
          onClick={() => onClick(option.value)}
        />
      ))}
    </>
  );
}

export default RadioFieldTypes;
