import React, { memo } from 'react';
import { Text as UinixText } from 'uinix-ui';
import { TextProps } from 'uinix-ui/lib/components/text';

import {
  SharedBoxProps,
  TextColor,
  TextPreset,
  TextVariant,
} from '../../types';
import { resolvePresetProps } from '../../utils';
import { presets } from './presets';

export interface Props extends TextProps, SharedBoxProps {
  /** Text color */
  color?: TextColor;
  /** Text preset */
  preset?: TextPreset;
  /** Text variant */
  variant?: TextVariant;
}

export const Text = memo((props: Props) => {
  const resolvedProps = resolvePresetProps(props, presets);
  // TODO: research and safely remove default `color` set on `Text`.  It is causing more problems than it is solving.
  const { color = 'text.primary', variant = 'body', ...rest } = resolvedProps;

  return <UinixText {...rest} color={color} variant={variant} />;
});
