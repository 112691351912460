import React from 'react';

import { Layout, Text, useDropdown } from '~/eds';

import { Operator } from '../operator';

type Props = {
  value: 'and' | 'or';
  onChange: (value: 'and' | 'or') => void;
};
export const OperatorSelect = ({ value, onChange }: Props) => {
  const [wrap, _, hide, toggle] = useDropdown({
    children: (
      <Layout p={2} direction="column">
        <Layout
          p={1}
          onClick={() => {
            hide();
            onChange('and');
          }}
        >
          <Text>AND</Text>
        </Layout>
        <Layout
          p={1}
          onClick={() => {
            hide();
            onChange('or');
          }}
        >
          <Text>OR</Text>
        </Layout>
      </Layout>
    ),
  });

  return wrap(
    <Layout onClick={toggle}>
      <Operator value={value} icon="chevron-down" />
    </Layout>,
  );
};
