export default `
<svg
  aria-hidden="true"
  aria-label="drive"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M13.5 14.75C13.0625 14.75 12.75 15.0938 12.75 15.5C12.75 15.9375 13.0625 16.25 13.5 16.25C13.9062 16.25 14.25 15.9375 14.25 15.5C14.25 15.0938 13.9062 14.75 13.5 14.75ZM18 5H6C4.875 5 4 5.90625 4 7V17C4 18.125 4.875 19 6 19H18C19.0938 19 20 18.125 20 17V7C20 5.90625 19.0938 5 18 5ZM18.5 17C18.5 17.2812 18.25 17.5 18 17.5H6C5.71875 17.5 5.5 17.2812 5.5 17V14C5.5 13.75 5.71875 13.5 6 13.5H18C18.25 13.5 18.5 13.75 18.5 14V17ZM18.5 12.0938C18.3125 12.0312 18.1562 12 18 12H6C5.8125 12 5.65625 12.0312 5.5 12.0938V7C5.5 6.75 5.71875 6.5 6 6.5H18C18.25 6.5 18.5 6.75 18.5 7V12.0938ZM16.5 14.75C16.0625 14.75 15.75 15.0938 15.75 15.5C15.75 15.9375 16.0625 16.25 16.5 16.25C16.9062 16.25 17.25 15.9375 17.25 15.5C17.25 15.0938 16.9062 14.75 16.5 14.75Z"
    fill="currentcolor"
  />
</svg>
`;
