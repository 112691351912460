import { chain } from 'lodash';

import { ClauseType } from '~/enums';
import { DocumentClause, ServerClause } from '~/types';

export function sortProvisions(a: ServerClause, b: ServerClause) {
  // Send all migrated provisions to the bottom
  if (
    a.provision_type === ClauseType.Migrated &&
    b.provision_type !== ClauseType.Migrated
  ) {
    return 1;
  }

  // Send all non-migrated provisions to the top
  if (
    a.provision_type !== ClauseType.Migrated &&
    b.provision_type === ClauseType.Migrated
  ) {
    return -1;
  }

  // Sort all migrated provisions based on ID
  if (
    a.provision_type === ClauseType.Migrated &&
    b.provision_type === ClauseType.Migrated
  ) {
    return a.id > b.id ? 1 : -1;
  }

  // Sort all non-migrated provisions base on html_tokens (document's position)
  return (a.html_tokens[0] || Number.NEGATIVE_INFINITY) >
    (b.html_tokens[0] || Number.NEGATIVE_INFINITY)
    ? 1
    : -1;
}

export const parseClauseList = (list: Array<ServerClause> | undefined) => {
  if (!list) return [];

  // Group provisions by name
  const provisions = chain(list)
    // @ts-ignore false positive in lodash types
    .groupBy('name')
    .map((value: ServerClause[], key: string) => ({
      name: key,
      content: value.sort(sortProvisions),
    }))
    .value();
  const result: DocumentClause[] = [];
  provisions.forEach((clause: { name: string; content: ServerClause[] }) => {
    clause.content.forEach((occurrence: ServerClause, index: number) => {
      result.push({
        id: occurrence.id,
        name: index === 0 ? clause.name : '',
        title: clause.name,
        content: occurrence.content,
        provisionType: occurrence.provision_type,
        htmlTokens: occurrence.html_tokens,
        provisionVersion: `${occurrence.provision_version}.0`,
        version: occurrence.version,
        isEdited: occurrence.is_edited,
        isLast: index === clause.content.length - 1,
      });
    });
  });
  return result;
};
