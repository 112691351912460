import { codeCash } from '~/services';

import {
  CreateCreditBalancesApiArg,
  CreateCreditBalancesApiResponse,
  CreditAmount,
} from '../../types/codecash/external';

export const createOneTimeCreditBalance = async ({
  clientId,
  body,
}: CreateCreditBalancesApiArg): Promise<CreditAmount> => {
  const response: CreateCreditBalancesApiResponse = await codeCash.post(
    `clients/${clientId}/credits/balances`,
    { data: body.data },
  );

  return response.meta.available;
};
