import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDisabledSaveSearchActionButtonTooltip,
  shouldSaveSearchActionButtonDisabled,
} from '~/components/SearchV2/SearchV2.utils';
import { showToast } from '~/components/Shared/EcToast';
import { api, selectors } from '~/redux';
import search from '~/redux/slices/search';
import { MODAL_EDIT_SAVED_SEARCH } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Modal } from '~/ui/';

import SaveSearchForm from '../SaveSearchForm';

const EditSavedSearchModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(selectors.selectSearchResultModal);
  const resetModal = () => dispatch(search.actions.setModal());
  const { modalType } = modal || {};

  const [
    updateSavedSearch,
    updateSavedSearchResult,
  ] = api.endpoints.updateSavedSearch.useMutation();
  const {
    data,
    isError: updateSearchError,
    isSuccess: updateSearchSuccess,
  } = updateSavedSearchResult;

  const [formData, setFormData] = useState();

  const actionButton = {
    disabled: shouldSaveSearchActionButtonDisabled(formData),
    text: 'Update',
    tooltip: getDisabledSaveSearchActionButtonTooltip(formData),
    promise: () => updateSavedSearch(formData),
  };

  useEffect(() => {
    if (updateSearchError) {
      showToast(ERROR, 'Edit saved search failed. Please try again.');
    }
  }, [updateSearchError]);

  useEffect(() => {
    if (updateSearchSuccess) {
      showToast(SUCCESS, `${data?.name} search has been successfully updated.`);
    }
  }, [updateSearchSuccess]);

  return (
    <Modal
      actionButton={actionButton}
      overflowDisplay="visible"
      title="Edit Saved Search"
      visible={modalType === MODAL_EDIT_SAVED_SEARCH}
      onCancel={resetModal}
      onHide={resetModal}
    >
      <SaveSearchForm handleFormDataChanges={setFormData} />
    </Modal>
  );
};

export default EditSavedSearchModal;
