import { useMemo } from 'react';

import { Field, FieldId, Filter } from '~/evifields';
import { api } from '~/redux';

export function usePinnedFilters(fields: Record<FieldId, Field> = {}) {
  const [
    getPinnedFilters,
    getPinnedFiltersResult,
  ] = api.endpoints.getPinnedFilters.useLazyQuery();
  const {
    data: serverPinnedFilters,
    isFetching,
    isSuccess,
  } = getPinnedFiltersResult;

  const userPreferencesDefaultFilters = useMemo(() => {
    const result: Array<Filter> = [];
    if (serverPinnedFilters?.data) {
      serverPinnedFilters?.data?.forEach((filterId: string) => {
        const field = fields[filterId];
        if (field) {
          result.push({
            id: String(field.id),
            fieldId: String(field.id),
            operatorId: null,
            values: [],
          });
        }
      });
    } else {
      return undefined;
    }

    return result;
  }, [serverPinnedFilters, fields]);

  return {
    getPinnedFilters,
    result: { data: userPreferencesDefaultFilters, isFetching, isSuccess },
  };
}
