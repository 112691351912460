import React, { memo } from 'react';

import { SharedChipProps } from '../../types';
import { Chip } from '../Chip';
import { useShowMoreItems } from '../hooks/useShowMoreItems';
import { Layout } from '../Layout';

interface Props {
  /** A list of chips */
  chips: SharedChipProps[];
  /** Optionally indicate layout direction of the chip list ('row' by default) */
  direction?: 'column' | 'row';
  /** Limit the number of visible chips */
  limit?: number;
  /** Number of hidden chips to display */
  step?: number;
}

const serializeChip = (chip: SharedChipProps): string => {
  const { text } = chip;
  // this is intentional and `Chips` should only work with `Chip` that has simple `text` content (for serializing in tooltips).  We serialize to the empty string if this is not valid.
  return typeof text === 'string' ? text : '';
};

export const Chips = memo(
  ({ chips, direction = 'row', limit = chips.length, step }: Props) => {
    const [
      visibleChips,
      showMore,
      showLess,
    ] = useShowMoreItems<SharedChipProps>({
      items: chips,
      limit,
      serializeItem: serializeChip,
      step,
    });

    const align = direction === 'column' ? 'flex-start' : 'center';

    return (
      <Layout preset="chips" direction={direction} align={align} role="list">
        {visibleChips.map((chip, i) => (
          <Chip key={i} {...chip} />
        ))}
        {showMore}
        {showLess}
      </Layout>
    );
  },
);
