import { DEFAULT_FILE_TYPES } from '~/components/Admin/IntegrationPage/constants';
import { StorageProviderType } from '~/enums';

interface SyncPairData {
  name: string;
  provider: StorageProviderType;
  tokenId?: string;
  allowedSyncFileTypes?: string[];
}

export const createSyncPairData = (data: SyncPairData) => {
  const defaults = {
    allowedSyncFileTypes: DEFAULT_FILE_TYPES,
  };
  return { ...defaults, ...data };
};
