import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastType } from 'react-toastify';

import { showToast } from '~/components/Shared/EcToast';
import { EntityVisibilitySelect } from '~/components/Shared/EntityVisibilitySelect';
import type { EntityVisibilityType } from '~/components/Shared/EntityVisibilitySelect';
import { Layout, Text } from '~/eds';
import { SavedDashboardsActions, SaveSearchPermissionType } from '~/enums';
import { api, selectors } from '~/redux';
import savedDashboards from '~/redux/slices/savedDashboards';
import { Modal } from '~/ui';

export function ShareDashboard() {
  const activeModal = useSelector(selectors.selectSavedDashboardsActiveModal);
  const dispatch = useDispatch();
  const dashboard = activeModal?.selectedDashboard;
  const [
    entityPermission,
    setEntityPermission,
  ] = useState<EntityVisibilityType>({
    visibility: dashboard?.visibility ?? SaveSearchPermissionType.Private,
  });
  const { currentData: dashboardInfo } = api.endpoints.getDashboardV2.useQuery(
    dashboard?.id ?? '',
    {
      skip:
        !dashboard || dashboard.visibility !== SaveSearchPermissionType.Shared,
    },
  );

  const [shareDashboard] = api.endpoints.updateDashboard.useMutation();

  const isMissingUserDepartments =
    entityPermission.visibility === SaveSearchPermissionType.Shared &&
    (!entityPermission.userIds || entityPermission.userIds?.length === 0) &&
    (!entityPermission.departmentIds ||
      entityPermission.departmentIds?.length === 0);
  const isShareDisabled = !dashboard || isMissingUserDepartments;

  useEffect(() => {
    if (dashboard) {
      setEntityPermission({
        visibility: dashboard.visibility as SaveSearchPermissionType,
      });
    }
  }, [dashboard]);

  useEffect(() => {
    if (dashboardInfo) {
      setEntityPermission({
        visibility: dashboardInfo.visibility as SaveSearchPermissionType,
        userIds: dashboardInfo.userIds,
        departmentIds: dashboardInfo.departmentIds,
      });
    }
  }, [dashboardInfo]);

  const onShare = async () => {
    const { visibility, userIds, departmentIds } = entityPermission;
    if (dashboard) {
      const payload = {
        uuid: dashboard?.id,
        attributes: {
          visibility,
          user_ids: userIds,
          department_ids: departmentIds,
        },
      };
      try {
        await shareDashboard(payload).unwrap();
        showToast(ToastType.SUCCESS, `${dashboard.name} shared.`);
        dispatch(savedDashboards.actions.closeModal());
      } catch (error: any) {
        if (error.response.status === 403) {
          showToast(
            ToastType.ERROR,
            'You do not have permission to share this dashboard.',
          );
        } else {
          showToast(
            ToastType.ERROR,
            'An error occurred while sharing the dashboard.',
          );
        }
      }
    }
  };
  return (
    <Modal
      overflowDisplay="visible"
      title="Share Dashboard"
      visible={activeModal?.name === SavedDashboardsActions.Share}
      onHide={() => {
        dispatch(savedDashboards.actions.closeModal());
      }}
      onCancel={() => {
        dispatch(savedDashboards.actions.closeModal());
      }}
      actionButton={{
        disabled: isShareDisabled,
        text: 'Share',
        tooltip: !isShareDisabled
          ? 'Share'
          : 'Specify at least one user or department to share the dashboard.',
        promise: onShare,
      }}
    >
      <Layout direction="column" spacing={4}>
        <Layout direction="column">
          <Text variant="body-bold">Dashboard name</Text>
          <Text>{dashboard?.name}</Text>
        </Layout>
        <EntityVisibilitySelect
          value={entityPermission}
          label="Who can see and use this dashboard ?"
          onChange={setEntityPermission}
        />
      </Layout>
    </Modal>
  );
}
