import PT from 'prop-types';
import React from 'react';

import { Button, Icon, IconButton } from '~/eds';
import { EsignatureType } from '~/enums';
import { Box, Chip, FlexLayout, styles, Tooltip } from '~/ui';

function StatusAndActions({
  disabled,
  esignatureIntegration,
  isActive = false,
  isLoading = false,
  isPending = false,
  onConnect,
  onDelete,
  onToggleCollapse,
}) {
  // TODO: abstract into reusable UI component
  const IconLoading = (
    <Box
      sx={{
        animation: `${styles.keyframes.spin} 1s linear infinite`,
      }}
    >
      <Icon icon="loading" />
    </Box>
  );

  const { id, error, isDefault } = esignatureIntegration;
  const items = [];

  if (disabled) {
    items.push(<Button disabled={disabled} text="Connect" variant="action" />);
  } else {
    // default status
    if (isDefault) {
      items.push(<Chip key="default" text="Default" variant="info" />);
    }

    // esignatureIntegration status
    if (!isActive && !id) {
      items.push(
        <Button
          disabled={disabled}
          text="Connect"
          onClick={onConnect}
          variant="action"
        />,
      );
    } else if (error) {
      items.push(
        <Tooltip key="error" content={error.message}>
          <Chip text="Validation error" variant="danger" />
        </Tooltip>,
      );
    } else if (isActive && isPending) {
      items.push(<Chip key="pending" text="Pending" variant="warning" />);
    } else if (isActive && !id) {
      items.push(
        <Chip key="not-connected" text="Not connected" variant="info" />,
      );
    } else if (id) {
      items.push(<Chip key="connected" text="Connected" variant="success" />);
    }

    // delete status
    if (id) {
      items.push(
        <Box key="delete" ml={6} pl={8} sx={{ borderLeft: 'border' }}>
          {isLoading ? (
            IconLoading
          ) : (
            <IconButton
              disabled={isLoading}
              icon="trash"
              label="remove"
              onClick={onDelete}
            />
          )}
        </Box>,
      );
    }
  }

  return (
    <FlexLayout alignItems="center" space={2} onClick={onToggleCollapse}>
      {items}
    </FlexLayout>
  );
}

StatusAndActions.propTypes = {
  esignatureIntegration: PT.shape({
    id: PT.string,
    config: PT.object.isRequired,
    error: PT.shape({ message: PT.string.isRequired }),
    isDefault: PT.bool.isRequired,
    isUserLoggedIn: PT.bool,
    shouldUseCompanyAccount: PT.bool,
    type: PT.oneOf(Object.values(EsignatureType)),
  }).isRequired,
  isActive: PT.bool,
  isLoading: PT.bool,
  isPending: PT.bool,
  onConnect: PT.func.isRequired,
  onDelete: PT.func.isRequired,
  onToggleCollapse: PT.func.isRequired,
};

export default StatusAndActions;
