import PropTypes from 'prop-types';
import React from 'react';

import { black2, black4 } from './../../../assets/shared-styles/general';

const RemoveIcon = (props) => {
  const { size, isHovered, onClick } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...(onClick && { onClick })}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={isHovered ? black2 : black4}
          fillRule="nonzero"
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z"
        />
      </g>
    </svg>
  );
};

RemoveIcon.propTypes = {
  size: PropTypes.string,
};

RemoveIcon.defaultProps = {
  size: '24',
};

export default RemoveIcon;
