import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Text } from '~/ui';

import { AND, OR } from './utils';

function Label({ isActive, label, onClick }) {
  return (
    <Text
      px={2}
      py={1}
      sx={{ bg: isActive ? 'white' : 'gray-300', borderRadius: 'm' }}
      variant="xs-dense-bold-caps"
      onClick={onClick}
    >
      {label}
    </Text>
  );
}

function AndOrNode({ node, onChange }) {
  const { value } = node;
  return (
    <FlexLayout
      bg="gray-300"
      mx="auto"
      p={1}
      sx={{ borderRadius: 'm', width: 'fit-content' }}
      variant="xs-dense-caps-bold"
    >
      <Label
        isActive={value === AND}
        label={AND}
        onClick={() => onChange(AND)}
      />
      <Label isActive={value === OR} label={OR} onClick={() => onChange(OR)} />
    </FlexLayout>
  );
}

AndOrNode.propTypes = {
  node: PT.shape({
    value: PT.oneOf([AND, OR]).isRequired,
  }).isRequired,
};

export default AndOrNode;
