import {
  black1,
  black3,
  bold,
  evisortBlue,
  evisortRed,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  searchActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  searchHelpButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    fontSize: '13px',
    fontWeight: bold,
    backgroundColor: 'transparent',
    border: 'none',
    color: evisortBlue,
    cursor: 'pointer',

    '& svg': {
      marginRight: '4px',
    },
  },
  searchInputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.025)',
  },
  searchInput: {
    flexGrow: '1',
    border: 'none',
    marginLeft: '6px',
    backgroundColor: 'transparent',
    outline: 'none',
    caretColor: evisortBlue,
    '&::placeholder': {
      color: '#626868',
    },
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '64px',
  },
  warningMessage: {
    marginTop: '8px',
    fontSize: '13px',
    fontWeight: semibold,
  },
  errorMessage: {
    marginTop: '8px',
    color: evisortRed,
    fontSize: '13px',
    fontWeight: semibold,
  },
  boolWarningMessage: {
    marginTop: '8px',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: semibold,
  },
  resultsList: {
    marginTop: '32px',
  },
  resultsListLabel: {
    marginBottom: '12px',
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
    textTransform: 'uppercase',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    padding: '4px 0',
    color: black1,
    lineHeight: '20px',
    cursor: 'pointer',
    '&:hover': {
      color: evisortBlue,
    },
    '& svg': {
      marginRight: '8px',
    },
  },
  listItemName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: semibold,
    wordBreak: 'break-word',
    cursor: 'pointer',

    '&:hover': {
      color: evisortBlue,
    },
  },
  listItemPath: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '2px',
    color: black3,
    fontSize: '13px',
  },
  remainingResults: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0 2px 0',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: 'bold',
    cursor: 'pointer',

    '& svg': {
      marginRight: '4px',
    },
  },
  searchLabel: {
    display: 'flex',
    alignItems: 'center',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,

    '& svg': {
      marginLeft: '4px',
    },
  },
  emptyResultsList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '32px 0 16px 0',
  },
  emptyResultsListTitle: {
    margin: '24px 0 8px 0',
    fontSize: '17px',
    fontWeight: semibold,
  },
  emptyResultsListSubtitle: {
    color: black3,
    fontSize: '15px',
  },
  modalActions: {
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export default styles;
