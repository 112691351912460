import React from 'react';

import { Select, useDebouncedState } from '~/eds';
import { api } from '~/redux';

type ClientSelectProps = React.ComponentProps<typeof Select>;

export const ClientSelect = (props: ClientSelectProps) => {
  const [debouncedSearch, _search, setSearch] = useDebouncedState('');
  const { data: clients = [], isFetching } = api.endpoints.getClients.useQuery(
    {
      searchText: debouncedSearch,
    },
    {
      skip: !!props.value && !debouncedSearch,
    },
  );

  const options = clients.map((client) => ({
    label: `${client.name} (${client.id})`,
    value: client.id,
  }));

  return (
    <Select
      {...props}
      isLoading={isFetching}
      options={options}
      onSearch={(value) => {
        setSearch(value);
      }}
    />
  );
};
