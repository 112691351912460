const styles = {
  editClauseContainer: {
    width: '80%',
    maxWidth: 'modal.l',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
};

export default styles;
