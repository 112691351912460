import { parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  CharacterLimit,
  Checkbox,
  DateInput,
  formatDate,
  Layout,
  NumberInput,
  TextArea,
} from '~/eds';
import { DataFieldType } from '~/features/fields';
import { selectors } from '~/redux';
import { Nullable } from '~/types';

import {
  CORRECT_VALUE_MAX_LENGTH,
  VALUE_DOES_NOT_EXIST_TEXT,
} from '../../../constants';
import { DEFAULT_FIELD_VALUE } from '../../constants';
import { FieldValue } from '../../types';
import { mapDataFieldTypeToOutputType } from '../../utils';

interface Props {
  readOnly: boolean;
  type: DataFieldType;
  value: Nullable<FieldValue>;
  onChange: (goldValue: Nullable<FieldValue>) => void;
}

export const FieldExtractionGoldValue = ({
  readOnly,
  type,
  value: goldValue,
  onChange,
}: Props) => {
  const [valueNotPresent, setValueNotPresent] = useState(false);
  const valueNotPresentChecked =
    (goldValue?.value === null && goldValue.type !== 'unknown') ||
    valueNotPresent;

  const { value } = goldValue ?? {
    ...DEFAULT_FIELD_VALUE,
    type,
  };

  const activeTestcaseNumber = useSelector(
    selectors.selectFieldAiActiveTestcaseNumber,
  );

  const handleChange = <V,>(
    type: DataFieldType,
    serialize: (value: V) => string = String,
  ) => (updatedValue: Nullable<V>) => {
    if (updatedValue === null) {
      if (goldValue?.highlights) {
        // preserve the highlights
        onChange({
          highlights: goldValue.highlights,
          type: 'unknown',
          value: null,
        });
      } else {
        onChange(null);
      }
    } else {
      setValueNotPresent(false);
      onChange({
        highlights: goldValue?.highlights,
        type: mapDataFieldTypeToOutputType(type),
        value: serialize(updatedValue),
      });
    }
  };

  const input = useMemo(() => {
    switch (type) {
      case DataFieldType.DATE:
        return (
          <DateInput
            name="correct-date-value"
            readOnly={readOnly}
            value={value ? parseISO(value as string) : null}
            onChange={handleChange(
              type,
              (d: Date) => formatDate(d, 'iso_date') ?? '',
            )}
          />
        );
      case DataFieldType.NUMBER:
        return (
          <NumberInput
            name="correct-float-value"
            readOnly={readOnly}
            value={value ? Number(value) : null}
            onChange={handleChange(type, String)}
          />
        );
      case DataFieldType.STRING:
      case DataFieldType.TEXT_AREA:
      default:
        return (
          <>
            <TextArea
              name="correct-text-value"
              key={`correct-text-value-${activeTestcaseNumber}`}
              readOnly={readOnly}
              value={value ? String(value) : null}
              onChange={handleChange(type)}
              maxLength={CORRECT_VALUE_MAX_LENGTH}
            />
            <CharacterLimit
              limit={CORRECT_VALUE_MAX_LENGTH}
              mode="characters"
              text={value ? String(value) : ''}
            />
          </>
        );
    }
  }, [type, value]);

  return (
    <Layout direction="column">
      {input}
      <Checkbox
        disabled={readOnly}
        option={{
          label: VALUE_DOES_NOT_EXIST_TEXT,
          value: valueNotPresentChecked,
        }}
        name="value-not-present-checkbox"
        value={valueNotPresentChecked}
        onChange={(updatedValueNotPresent: Nullable<boolean>) => {
          setValueNotPresent(updatedValueNotPresent ?? false);
          onChange(
            updatedValueNotPresent
              ? // Preserve the highlights and set the type back to the actual type
                // to indicate that the user explicitly set the value to null.
                {
                  highlights: goldValue?.highlights,
                  type: mapDataFieldTypeToOutputType(type),
                  value: null,
                }
              : goldValue?.highlights
              ? // Preserve the highlights but set the type to 'unknown'
                // to indicate that the checkbox should be unchecked.
                {
                  highlights: goldValue.highlights,
                  type: 'unknown',
                  value: null,
                }
              : null,
          );
        }}
      />
    </Layout>
  );
};
