import React, { memo, useEffect } from 'react';

import { HighlightType, TextColor, TextVariant } from '../../types';
import { Box } from '../Box';
import { useShowMoreText } from '../hooks/useShowMoreText';
import { Layout } from '../Layout';
import { MarkedText } from '../MarkedText';
import { Text } from '../Text';

interface Props {
  /** Source text */
  text: string;
  /** Text color */
  color?: TextColor;
  /** Full content to render if all text is shown */
  fullContent?: React.ReactNode;
  /** Specifies the highlighter for text matching on the `search` string */
  highlighter?: HighlightType;
  /** Indicates if the text should be expanded */
  isExpanded?: boolean;
  /** Limit the number of visible characters */
  limit?: number;
  /** Indicates if Show More / Show Less should display as text or an icon button */
  mode?: 'text' | 'icon';
  /** Enables text matching based on the search string */
  search?: string;
  /** Number of hidden characteres to display */
  step?: number;
  /** When set to true, it will reset to its initial state (closed) */
  // TODO: this prop smells and we should analyze it at some point
  shouldResetToInitialState?: boolean;
  /** Text variant */
  variant?: TextVariant;
  whiteSpace?: 'pre-wrap' | 'normal';
}

export const CollapsibleText = memo(
  ({
    fullContent,
    highlighter,
    isExpanded = false,
    shouldResetToInitialState,
    search,
    step,
    text,
    limit = text.length,
    mode = 'text',
    variant,
    color,
    whiteSpace,
  }: Props) => {
    const {
      visibleText,
      showMore,
      shouldShowMore,
      handleShowLess,
      handleShowMore,
    } = useShowMoreText({
      limit,
      step,
      text,
      mode,
    });

    const visibleContent =
      !shouldShowMore && fullContent ? (
        fullContent
      ) : (
        <Text variant={variant} color={color}>
          <MarkedText
            highlighter={highlighter}
            search={search}
            text={visibleText}
            whiteSpace={whiteSpace}
          />
        </Text>
      );

    useEffect(() => {
      if (isExpanded) {
        handleShowMore();
      }
    }, [isExpanded]);

    useEffect(() => {
      if (shouldResetToInitialState) {
        handleShowLess();
      }
    }, [shouldResetToInitialState]);

    return mode === 'icon' ? (
      <Layout direction="row" justify="space-between">
        <Box minW="0">{visibleContent}</Box>
        {showMore}
      </Layout>
    ) : (
      <span>
        {visibleContent} {showMore}
      </span>
    );
  },
);

export const componentStyles = {
  base: {
    background: 'none',
    color: 'inherit',
    fontWeight: 'bolder',
  },
};
