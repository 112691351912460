import React, { memo } from 'react';

import { SharedSplitButtonProps } from '../../types';
import { ActionsMenu } from '../ActionsMenu';
import { Box } from '../Box';
import { Button } from '../Button';

export const SplitButton = memo(
  ({
    actions,
    disabled,
    enablePortal,
    isLoading,
    name,
    text,
    tooltip,
    variant = 'secondary',
    onClick,
  }: SharedSplitButtonProps) => {
    return (
      <Box styles={componentStyles.container({ variant })}>
        <Button
          disabled={disabled}
          isLoading={isLoading}
          text={text}
          tooltip={tooltip}
          variant={variant}
          onClick={onClick}
        />
        <ActionsMenu
          actions={actions}
          disabled={disabled || isLoading}
          icon="chevron-down"
          enablePortal={enablePortal}
          name={name}
          PRIVATE_variant={variant}
        />
      </Box>
    );
  },
);

const componentStyles = {
  container: ({ variant }: { variant: 'primary' | 'secondary' }) => ({
    display: 'flex',
    // CSS overrides for the buttons under the container
    '& button:first-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      marginRight: variant === 'primary' ? '1px' : '-1px',
    },
    '& [role="button"]': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  }),
};
