import { black2, evisortBlue } from '../../../assets/shared-styles/general';

const styles = {
  searchboxContainer: {
    margin: '20px',
    width: '280px',
  },
  filterSearch: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    borderRadius: '4px',
    paddingLeft: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    '& input': {
      width: '300px',
      border: 'none',
      padding: '8px',
      backgroundColor: 'transparent',
      color: black2,
      fontSize: '14px',
      outline: 'none',
      caretColor: evisortBlue,
      '&::placeholder': {
        color: 'rgba 0 0 0, 0.5',
        fontSize: '14px',
      },
    },
  },
  horizontalSeparator: {
    display: 'block',
    height: '24px',
  },
};

export default styles;
