const styles = {
  documentHeaderContainer: {
    display: 'block',
    position: 'relative',
  },
  documentHeader: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: '0',
    marginBottom: '32px',
  },
  documentBackAction: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    padding: 0,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
  },
  documentFullTitle: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  emptyfolderName: {
    width: '460px',
    height: '27px',
    marginLeft: '8px',
    backgroundColor: '#f0f0f0',
  },
  folderIcon: {
    padding: 0,
    cursor: 'pointer',
  },
};

export default styles;
