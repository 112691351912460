import React from 'react';
import { connect } from 'react-redux';

import ActionsMenu from '~/components/Shared/ActionsMenu';
import ConditionRuleInput from '~/components/Workflow/shared/ConditionRuleInput';
import SignerSelect from '~/components/Workflow/shared/SignerSelect';
import { WorkflowPartyType } from '~/enums';
import { useUsers } from '~/hooks';
import { getUsedSignerIds } from '~/reducers/workflow';
import { Divider, FlexLayout, InputLabel, Text } from '~/ui';
import { testInvalidSigner } from '~/utils/workflow';

import WorkflowUserRoleSingleSelect from '../../shared/WorkflowUserRoleSingleSelect';
import { testValidApprover } from '../../Workflow.utils';

function Signer({
  signer,
  onUpdateConditionRule,
  onRemoveSigner,
  onUpdateSigner,
  onUpdateSignerUser,
  // connected
  isInvalid,
  usedSignerIds,
}) {
  const {
    id,
    conditionRule,
    default: defaultSigner = null,
    name,
    partyType,
  } = signer;
  const users = useUsers();
  let signerName = name;
  if (isInvalid) {
    signerName = id === null ? 'Unspecified Signer' : 'Invalid Signer';
  }

  const enableUserSelect = id && partyType === WorkflowPartyType.Party;

  return (
    <FlexLayout
      bg="black-alpha-04"
      flexDirection="column"
      sx={{ borderRadius: 'm' }}
    >
      <FlexLayout alignItems="center" justifyContent="space-between" p={6}>
        <Text color="gray-700" variant="m-dense-bold">
          {signerName}
        </Text>
        <ActionsMenu
          id={`workflow_builder_sign_signer_menu_id=${id}`}
          items={[
            {
              content: 'Remove signer',
              onClick: () => onRemoveSigner(),
            },
          ]}
          align="end"
        />
      </FlexLayout>
      <Divider />
      <FlexLayout flexDirection="column" p={6} space={6}>
        <FlexLayout alignItems="center" space={6}>
          <InputLabel id={`signer-${id}`} isRequired label="Signer">
            <SignerSelect
              blacklistValues={usedSignerIds}
              value={id}
              onChange={onUpdateSigner}
            />
          </InputLabel>
          {enableUserSelect && (
            <InputLabel
              isRequired
              flex="1 1 auto"
              id={`signer-${id}-user`}
              label="Default Value"
            >
              <WorkflowUserRoleSingleSelect
                error={
                  defaultSigner && !testValidApprover(defaultSigner, users)
                    ? "There are users that don't exist anymore"
                    : ''
                }
                placeholder="Select a user.."
                value={defaultSigner}
                width="fullWidth"
                onChange={onUpdateSignerUser}
              />
            </InputLabel>
          )}
        </FlexLayout>
        <Divider />
        <ConditionRuleInput
          conditionRule={conditionRule}
          onChange={onUpdateConditionRule}
        />
      </FlexLayout>
    </FlexLayout>
  );
}

function mapStateToProps({ workflow }, { signer }) {
  return {
    isInvalid: testInvalidSigner(signer, workflow.signers),
    usedSignerIds: getUsedSignerIds(workflow),
  };
}

export default connect(mapStateToProps)(Signer);
