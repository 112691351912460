import React, { memo } from 'react';

import { HighlightType } from '../../types';
import { textMatch } from '../../utils';
import { Mark } from '../Mark';

interface Props {
  // Source text
  text: string;
  /** Highlighter */
  highlighter?: HighlightType;
  // Pattern to match
  search?: string;

  whiteSpace?: 'pre-wrap' | 'normal';

  as?: any;
}

export const MarkedText = memo(
  ({
    as: Element = 'span',
    highlighter,
    text,
    whiteSpace,
    search = '',
  }: Props) => {
    return (
      <Element style={{ whiteSpace }}>
        {textMatch(text, search).map((substring, i) =>
          i % 2 ? (
            <Mark highlighter={highlighter} key={i}>
              {substring}
            </Mark>
          ) : (
            substring
          ),
        )}
      </Element>
    );
  },
);
