import { FolderAccess } from '~/features/upload';
import { pilot } from '~/services';
import { PilotId } from '~/types';

interface Request {
  folderId: PilotId;
}

interface Response {
  can_modify_folder: boolean;
  folder_access_list: {
    members: any[];
    departments: any[];
  };
  visibility_level: string;
}

export const getFolderVisibility = async ({
  folderId,
}: Request): Promise<FolderAccess> => {
  const url = folderId
    ? `/folder/visibility/${folderId}/`
    : '/folder/visibility/';

  const response: Response = await pilot.get(url);

  if (!response.can_modify_folder) {
    throw new Error('You do not have permission to modify this folder.');
  }
  return { canModifyFolder: response.can_modify_folder };
};
