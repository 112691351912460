import { heights } from './heights';
import { values } from './values';
import { widths } from './widths';

// TODO: study and consolidate size values with design
export const sizes = {
  ...heights,
  ...widths,
  ...values,
};
