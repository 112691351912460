import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import styles from './LeaveReviewModal.styles';

class LeaveReviewModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnSaveClick = this.handleOnSaveClick.bind(this);
    this.handleOnDismissClick = this.handleOnDismissClick.bind(this);
  }

  handleOnDismissClick() {
    const { handleDismissChanges, hideModal } = this.props;

    handleDismissChanges();
    hideModal();
  }

  handleOnSaveClick() {
    const { handleActionClick, hideModal } = this.props;

    handleActionClick();
    hideModal();
  }

  render() {
    const { classes, hideModal, title } = this.props;

    return (
      <EcModalCard
        title={title}
        content={
          <div className={classes.modalBodyContent}>
            <p className={classes.modalInfo}>
              You have unsaved changes, are you sure you want to leave this
              page?
            </p>
          </div>
        }
        footer={
          <>
            <EcButton
              white
              borderless
              text="Dismiss changes and continue"
              onClick={this.handleOnDismissClick}
            />
            <EcButton
              yellow
              text="Save changes and continue"
              onClick={this.handleOnSaveClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

LeaveReviewModal.propTypes = {
  title: PropTypes.string.isRequired,
  handleDismissChanges: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(LeaveReviewModal);
