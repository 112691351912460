import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { debounce } from '../../../utils/helper.utils';
import EcInput from '../EcInput';
import EcPersistentTooltip from '../EcPersistentTooltip';
import { validateDate } from './EcDateInput.data';
import { errorTooltip, warningTooltip } from './EcDateInput.utils';

class EcDateInput extends Component {
  constructor(props) {
    super(props);

    this.state = { call: null, warning: false, tooltipOptions: {} };
  }

  cancelValidateCall() {
    const { call } = this.state;

    if (call) {
      call.cancel('Canceling this request due to new one.');
    }
  }

  hideTooltip() {
    this.setState({ tooltipOptions: {} });
  }

  showTooltip(tooltipOptions) {
    this.setState({ tooltipOptions });
  }

  validateDateError(inputDate, err) {
    const { onDateInputChange } = this.props;

    if (!axios.isCancel(err)) {
      this.showTooltip(errorTooltip);
      onDateInputChange({
        display_value: inputDate,
        value: inputDate,
        error: true,
      });
    }
  }

  validateDateSuccess(res) {
    this.props.onDateInputChange({
      display_value: res.raw_value,
      value: res.value,
    });

    if (res.show_warning) {
      this.showTooltip(warningTooltip);
    } else {
      this.hideTooltip();
    }
  }

  dateValidator(inputDate) {
    this.setState({ call: axios.CancelToken.source() }, () => {
      validateDate(inputDate, this.state.call.token)
        .then(this.validateDateSuccess.bind(this))
        .catch(this.validateDateError.bind(this, inputDate));
    });
  }

  debouncedDateValidator = debounce(this.dateValidator, 400);

  render() {
    const {
      inputDate,
      placeholder,
      isDisabled,
      error,
      onDateInputChange,
    } = this.props;

    return (
      <EcPersistentTooltip tooltipOptions={this.state.tooltipOptions}>
        <EcInput
          input={{
            value: inputDate,
            onChange: (event) => {
              const dateInput = event.target.value;
              onDateInputChange({
                display_value: dateInput,
                value: dateInput,
                disableForm: true,
              });
              this.hideTooltip();

              if (dateInput.length > 2) {
                this.cancelValidateCall();
                this.debouncedDateValidator(dateInput);
              } else if (dateInput === '') {
                onDateInputChange(null);
              }
            },
            onBlur: () => this.hideTooltip(),
            onFocus: () => error && this.showTooltip(errorTooltip),
          }}
          placeholder={placeholder}
          width="100%"
          isDisabled={isDisabled}
          error={error}
        />
      </EcPersistentTooltip>
    );
  }
}

EcDateInput.defaultProps = {
  inputDate: '',
  isDisabled: false,
  error: false,
};

EcDateInput.propTypes = {
  inputDate: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  onDateInputChange: PropTypes.func.isRequired,
};

export default EcDateInput;
