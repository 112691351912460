import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import injectSheet from 'react-jss';

import { ESCAPE } from '~/constants/keyCodes';

import { MODAL_AUTH_REQUIRE_POPUP } from '../../../types/modal.types';
import AuthRequireWarningModal from '../../Modals/AuthRequireWarningModal';
import styles from './EcModalNestedOverlay.styles';

class EcModalNestedOverlay extends Component {
  constructor(props) {
    super(props);

    this.handleOnEscPress = this.handleOnEscPress.bind(this);
    this.handleOnOverlayClick = this.handleOnOverlayClick.bind(this);
    this.handleOnDialogClick = this.handleOnDialogClick.bind(this);

    this.modalRoot = document.getElementById('modal-root');
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleOnEscPress, true);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleOnEscPress, true);
  }

  handleOnEscPress(event) {
    if (event.key === ESCAPE) {
      this.props.hideModal();
    }
  }

  handleOnOverlayClick() {
    this.props.hideModal();
  }

  handleOnDialogClick(event) {
    event.stopPropagation();
  }

  renderModal() {
    switch (this.props.modalType) {
      case MODAL_AUTH_REQUIRE_POPUP:
        return <AuthRequireWarningModal {...this.props} />;
      default:
        return null;
    }
  }

  render() {
    const modalUI = (
      <div>
        <div>{this.renderModal()}</div>
      </div>
    );

    return ReactDOM.createPortal(modalUI, this.modalRoot);
  }
}

EcModalNestedOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(EcModalNestedOverlay);
