import React from 'react';

import Filters from '~/components/Shared/Filters/Filters';
import { Box, Button, IconButton, Layout, Text } from '~/eds';
import { Filter } from '~/evifields';
import { useFieldsWithLazyLoad } from '~/features/filters';
import { FlagType, useFlag } from '~/flags';
import { SearchFilter } from '~/types';

interface Props {
  filters?: Filter[];
  index: number;
  operatorValue: string;
  searchFilters: Record<string, SearchFilter> | undefined;
  goSelectFilter: () => void;
  setFiltersInGroup: (index: number, filtersValue: Filter[]) => void;
  setFiltersOperator: (index: number, operatorValue: string) => void;
  onRemove: (index: number) => void;
}

const QueryBuilderGroup = ({
  filters,
  index,
  operatorValue,
  searchFilters,
  goSelectFilter,
  setFiltersInGroup,
  setFiltersOperator,
  onRemove,
}: Props) => {
  const enableFilterViews = useFlag(FlagType.TextDelimitedMultiselectFilter);
  const fieldsWithLazyLoad = useFieldsWithLazyLoad({
    filters: Object.values(searchFilters || {}),
    fieldValues: {},
  });

  const getFilters = () => {
    if (filters?.length) {
      return (
        <Filters
          enableFilterViews={enableFilterViews}
          filters={filters}
          groupsOrder={[]}
          searchFilters={fieldsWithLazyLoad}
          operatorValue={operatorValue}
          onChange={(filters: Filter[]) => {
            setFiltersInGroup(index, filters);
          }}
          onOperatorChange={(operatorVal: string) => {
            setFiltersOperator(index, operatorVal);
          }}
        />
      );
    }
  };

  return (
    <Layout
      direction="column"
      border="border"
      borderRadius="m"
      p={4}
      pl={2}
      position="relative"
      spacing={2}
    >
      <Layout justify="end">
        <IconButton
          icon="x"
          size="s"
          tooltip="Remove group"
          onClick={() => onRemove(index)}
        />
      </Layout>
      <Layout pl={4}>
        {!filters?.length ? (
          <Text>Add a filter and run a custom search.</Text>
        ) : (
          <Box minW={0}>{getFilters()}</Box>
        )}
      </Layout>
      <Layout justify="start">
        <Button text="Add filter" variant="action" onClick={goSelectFilter} />
      </Layout>
    </Layout>
  );
};

export default QueryBuilderGroup;
