import React from 'react';

import { TextContainer } from '../TextContainer';
import { Filter, NumberValue } from '../types';
import { coerceFormatNumber } from '../utils';

interface Props {
  filter: Filter<NumberValue>;
}

export const NumberValuesPreview = ({ filter }: Props) => {
  const { values } = filter;
  const [value1, value2] = values;

  return values.length === 1 ? (
    <TextContainer text={coerceFormatNumber(value1)} />
  ) : (
    <TextContainer.Pair
      delimiter="to"
      texts={[coerceFormatNumber(value1), coerceFormatNumber(value2)]}
    />
  );
};
