import { EventFilterList } from '~/features/webhooks/types';
import { courier } from '~/services';

import { ListTypesApiResponse } from '../../types/courier/eventTypes';

type Response = ListTypesApiResponse;

export const getEventFields = async (): Promise<EventFilterList[]> => {
  const response: Response = await courier.get(
    `/event-types/available/flattened`,
  );

  return mapResponseToEventsList(response);
};

const mapResponseToEventsList = (
  data: ListTypesApiResponse,
): EventFilterList[] => {
  const response = data.map((value: string) => ({
    label: value,
    value,
  }));

  return response;
};
