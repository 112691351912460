import React, { Fragment } from 'react';

import { Box } from '../../../components';
import { BodyRow } from '../Row';

function Body({ activeRowId, rows, prepareRow, isBordered = true }) {
  return (
    <Box as="tbody" bg="inherit">
      {rows.map((row) => {
        prepareRow(row);
        const { cells, disabled, id, isSelected, onShowDetails } = row;
        return (
          <BodyRow
            key={id}
            activeRowId={activeRowId}
            data={row.original}
            disabled={disabled}
            isSelected={isSelected}
            isBordered={isBordered}
            onClick={onShowDetails}
          >
            {cells.map((cell) => (
              <Fragment key={cell.column.key}>{cell.render('Cell')}</Fragment>
            ))}
          </BodyRow>
        );
      })}
    </Box>
  );
}

export default Body;
