import React from 'react';

import { Box, Select } from '~/eds';
import { Nullable } from '~/types';

import { AsyncSelectComponent } from '../AsyncSelectComponent';
import { EnumValue, Field, Filter } from '../types';
import { testEnableSearch } from '../utils';

interface Props {
  field: Field<'enum'>;
  filter: Filter<EnumValue>;
  onChange: (updatedValues: EnumValue[]) => void;
}

export const EnumValues = ({ field, filter, onChange }: Props) => {
  const { render, settings } = field;
  const { options } = settings;
  const { id, values } = filter;

  const handleChange = (updatedValue: Nullable<EnumValue>) => {
    onChange(updatedValue === null ? [] : [updatedValue]);
  };

  const isAsync = Boolean(render);

  const enableSearch = testEnableSearch(isAsync, options.length);

  const innerProps = {
    enableControl: enableSearch,
    enableSearchIcon: true,
    enableSorting: enableSearch,
    isEmbedded: true,
    isClearable: false,
    isMulti: false,
    name: id,
    value: values[0],
    onChange: handleChange,
  };

  return (
    <Box mt={enableSearch ? undefined : -4} mx={-4}>
      {render?.({ Component: AsyncSelectComponent, innerProps }) || (
        <Select {...innerProps} options={options} />
      )}
    </Box>
  );
};
