import PT from 'prop-types';
import React from 'react';

import { Layout } from '~/eds';
import { Box, FlexLayout, styles, Tabs, useLockBodyScroll } from '~/ui';

import { tabPropType } from '../Tabs/Tab';
import { PX, PY } from './constants';

function PageLayout({
  bg = 'background',
  children,
  footer,
  header,
  isContentCentered = true,
  selectedTab,
  tabs,
  sidebar,
  onSelectTab,
  isContentFill = false,
  isPadded = true,
}) {
  useLockBodyScroll();

  const py = isPadded ? PY : 0;
  const px = isPadded ? PX : 0;
  const contentStyle = isContentCentered
    ? {
        height: '100%',
        maxWidth: isContentFill ? undefined : 'page-content-max-width',
        mx: 'auto',
      }
    : {};

  const Content = (
    <Box
      px={px}
      py={py}
      space={4}
      sx={{
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <Box sx={contentStyle}>
        {children}
        <Box pb={py} /> {/* spacer */}
      </Box>
    </Box>
  );
  return (
    <Layout
      direction="column"
      bg={bg}
      position="fixed"
      styles={{ ...styles.layout.fullPage, zIndex: 'panel' }}
      role="dialog"
    >
      {header}
      <FlexLayout sx={styles.layout.stretch}>
        <FlexLayout
          flex="1 1 auto"
          sx={{
            height: (theme) => {
              const headerFooterOffset = footer
                ? `${theme.sizes['page-header-height']} - ${theme.sizes['page-footer-height']}`
                : `${theme.sizes['page-header-height']}`;
              return header ? `calc(100vh - ${headerFooterOffset})` : '100vh';
            },
          }}
        >
          {sidebar}
          <Box
            sx={{
              flex: '1 1 auto',
              overflow: 'auto',
            }}
          >
            {tabs ? (
              <FlexLayout
                flexDirection="column"
                sx={{ height: '100%', overflow: 'hidden' }}
              >
                <Box bg="white">
                  <Tabs
                    selectedTab={selectedTab}
                    tabs={tabs}
                    onSelectTab={onSelectTab}
                  />
                </Box>
                {Content}
              </FlexLayout>
            ) : (
              Content
            )}
          </Box>
        </FlexLayout>
      </FlexLayout>
      {footer}
    </Layout>
  );
}

PageLayout.propTypes = {
  /** Page background color */
  bg: PT.string,
  /** main contents of the page layout */
  children: PT.node,
  /** Footer of page layout. */
  footer: PT.node,
  /** Use one of the supported headers of page layout. */
  header: PT.node,
  /** Is content container centered */
  isContentCentered: PT.bool,
  /** Selected tab key */
  selectedTab: PT.string,
  /** Use one of the supported sidebars of page layout */
  sidebar: PT.node,
  /** List of tab objects */
  tabs: PT.arrayOf(tabPropType.isRequired),
  /** Callback to set selected tab */
  onSelectTab: PT.func,
};

export default PageLayout;
