export default `
<svg
  aria-hidden="true"
  aria-label="checklist"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M6.53906 9.26988C6.41406 9.39488 6.22656 9.48863 6.00781 9.48863C5.82031 9.51988 5.60156 9.42613 5.47656 9.26988L4.22656 8.01988C3.91406 7.73863 3.91406 7.26988 4.22656 6.98863C4.50781 6.67613 4.97656 6.67613 5.25781 6.98863L5.97656 7.67613L7.69531 5.73863C7.97656 5.45738 8.44531 5.42613 8.75781 5.70738C9.03906 5.98863 9.07031 6.45738 8.78906 6.76988L6.53906 9.26988ZM6.53906 14.2699C6.41406 14.3949 6.22656 14.4886 6.00781 14.4886C5.82031 14.5199 5.60156 14.4261 5.47656 14.2699L4.22656 13.0199C3.91406 12.7386 3.91406 12.2699 4.22656 11.9574C4.50781 11.6761 4.97656 11.6761 5.25781 11.9574L5.97656 12.6761L7.69531 10.7386C7.97656 10.4574 8.44531 10.4261 8.75781 10.7074C9.03906 10.9574 9.07031 11.4574 8.78906 11.7699L6.53906 14.2699ZM10.7578 8.23863C10.3203 8.23863 10.0078 7.92613 10.0078 7.48863C10.0078 7.08238 10.3203 6.73863 10.7578 6.73863H19.2578C19.6641 6.73863 20.0078 7.08238 20.0078 7.48863C20.0078 7.92613 19.6641 8.23863 19.2578 8.23863H10.7578ZM10.0078 12.4886C10.0078 12.0824 10.3203 11.7386 10.7578 11.7386H19.2578C19.6641 11.7386 20.0078 12.0824 20.0078 12.4886C20.0078 12.9261 19.6641 13.2386 19.2578 13.2386H10.7578C10.3203 13.2386 10.0078 12.9261 10.0078 12.4886ZM9.00781 17.4886C9.00781 17.0824 9.32031 16.7386 9.75781 16.7386H19.2578C19.6641 16.7386 20.0078 17.0824 20.0078 17.4886C20.0078 17.9261 19.6641 18.2386 19.2578 18.2386H9.75781C9.32031 18.2386 9.00781 17.9261 9.00781 17.4886ZM6.00781 18.4886C5.44531 18.4886 5.00781 18.0511 5.00781 17.4886C5.00781 16.9574 5.44531 16.4886 6.00781 16.4886C6.53906 16.4886 7.00781 16.9574 7.00781 17.4886C7.00781 18.0511 6.53906 18.4886 6.00781 18.4886Z"
    fill="currentcolor"
  />
</svg>
`;
