import React from 'react';

import { api } from '~/redux';

import { withCurrentUser } from './withCurrentUser';

export function withUsers(WrappedComponent) {
  function UsersContainer({ currentUser, ...rest }) {
    const clientId = currentUser?.client;

    const { data = {} } = api.endpoints.getAllUsers.useQueryState(clientId);

    return (
      <WrappedComponent {...rest} users={data} currentUser={currentUser} />
    );
  }
  return withCurrentUser(UsersContainer);
}
