import React from 'react';

import { Button, Layout } from '~/eds';
import { Box } from '~/ui';

function AribaFooter({ secondaryAction, mainAction }) {
  return (
    <Box
      bg="white"
      sx={{
        borderTop: 'border',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Layout py={4} spacing={4} justify="flex-end" mx="20px">
        {secondaryAction && <Button {...secondaryAction} variant="tertiary" />}
        {mainAction && <Button {...mainAction} variant="primary" />}
      </Layout>
    </Box>
  );
}

export default AribaFooter;
