const defaultGridItemProps: {
  width?: number;
  height?: number;
} = {};

export const gridItem = ({ width, height } = defaultGridItemProps) =>
  width || height
    ? {
        gridColumn: width ? `span ${width}` : undefined,
        gridRow: height ? `span ${height}` : undefined,
      }
    : null;
