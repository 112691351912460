import { noop } from 'lodash';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import { View } from '../types';

interface Context {
  view: View;
  setView: Dispatch<SetStateAction<View>>;
}

interface Props {
  children: React.ReactNode;
}

const ViewContext = React.createContext<Context>({
  view: 'day',
  setView: noop,
});

export const ViewProvider = ({ children }: Props) => {
  const [view, setView] = useState<View>('day');

  return (
    <ViewContext.Provider value={{ view, setView }}>
      {children}
    </ViewContext.Provider>
  );
};

export const useView = (): Context => useContext(ViewContext);
