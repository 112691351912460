import fileUpload from '~/slices/fileUpload';

import analyzer from './analyzer';
import builder from './builder';
import client from './client';
import comments from './comments';
import documentsViewPage from './documentsViewPage';
import dropzone from './dropzone';
import intakeForm from './intakeForm';
import integrations from './integrations';
import saml from './saml';
import ticket from './ticket';
import ticketReviewer from './ticketReviewer';
import workflow from './workflow';

export default {
  analyzer,
  builder,
  client,
  comments,
  documentsViewPage,
  dropzone,
  fileUpload,
  intakeForm,
  integrations,
  saml,
  ticket,
  ticketReviewer,
  workflow,
};
