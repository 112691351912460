export default `
<svg
  aria-hidden="true"
  aria-label="field-time-period"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M8 13C8 12.4688 8.4375 12 9 12C9.53125 12 10 12.4688 10 13C10 13.5625 9.53125 14 9 14C8.4375 14 8 13.5625 8 13ZM15 15C15.5312 15 16 15.4688 16 16C16 16.5625 15.5312 17 15 17C14.4375 17 14 16.5625 14 16C14 15.4688 14.4375 15 15 15ZM11 13C11 12.5938 11.3125 12.25 11.75 12.25H15.25C15.6562 12.25 16 12.5938 16 13C16 13.4375 15.6562 13.75 15.25 13.75H11.75C11.3125 13.75 11 13.4375 11 13ZM12.25 15.25C12.6562 15.25 13 15.5938 13 16C13 16.4375 12.6562 16.75 12.25 16.75H8.75C8.3125 16.75 8 16.4375 8 16C8 15.5938 8.3125 15.25 8.75 15.25H12.25ZM9.75 6H14.25V4.75C14.25 4.34375 14.5625 4 15 4C15.4062 4 15.75 4.34375 15.75 4.75V6H17C18.0938 6 19 6.90625 19 8V18C19 19.125 18.0938 20 17 20H7C5.875 20 5 19.125 5 18V8C5 6.90625 5.875 6 7 6H8.25V4.75C8.25 4.34375 8.5625 4 9 4C9.40625 4 9.75 4.34375 9.75 4.75V6ZM6.5 18C6.5 18.2812 6.71875 18.5 7 18.5H17C17.25 18.5 17.5 18.2812 17.5 18V10H6.5V18Z"
    fill="currentcolor"
  />
</svg>
`;
