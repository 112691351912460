import { black2 } from '../../../assets/shared-styles/general';

const styles = {
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  itemRow: {
    padding: '13px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  checkboxContainer: {
    display: 'flex',
    '& label': {
      color: black2,
    },
  },
  checkboxLabel: {
    margin: '0 8px',
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

export default styles;
