import pluralize from 'pluralize';
import React from 'react';

import {
  cancelImportJob,
  setImportJobAcknowledge,
} from '~/components/Admin/UsersPage/UsersPage.data';
import { showToast } from '~/components/Shared/EcToast';
import {
  BulkImportUserResultType,
  BulkImportUserStatusType,
  CodedErrorCodeType,
} from '~/enums';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { parseApiError } from '~/utils/parseApiError';

import DownloadImportResultsFileLink from './DownloadImportResultsFileLink';

const handleImportProcessCancelAction = async (clientId, importData) => {
  const { importId } = importData;
  try {
    await cancelImportJob({ clientId, importId });
    showToast(SUCCESS, 'Cancelled import job successfully.');
  } catch (errorResponse) {
    handleApiError(
      errorResponse,
      CodedErrorCodeType.BulkImportErrorCancellingJob,
    );
  }
};

const handleImportJobAcknowledgeClick = async (
  clientId,
  importData,
  handleAcknowledgeUpdate,
) => {
  const { importId } = importData;
  try {
    await setImportJobAcknowledge({ clientId, importId });

    showToast(SUCCESS, 'Acknowledged import job successfully.');
    handleAcknowledgeUpdate(importId);
  } catch (errorResponse) {
    handleApiError(
      errorResponse,
      CodedErrorCodeType.BulkImportErrorAcknowledgingJob,
    );
  }
};

const handleApiError = (errorResponse, customCodeError) => {
  const error = parseApiError(errorResponse, customCodeError);
  showToast(ERROR, error.message);
};

const getErrorMessage = (prefixMessage, errors) =>
  `${prefixMessage} ${errors} users data failed to import.`;

const getErrorDownloadMessage = (importId, clientId, prefixMessage, errors) => {
  return (
    <>
      {getErrorMessage(prefixMessage, errors)}
      <DownloadImportResultsFileLink
        text="Download the error list"
        importId={importId}
        clientId={clientId}
        resultType={BulkImportUserResultType.ProcessErrors}
      />{' '}
      to fix errors.
    </>
  );
};

const getFinishedStatusMessage = ({ importId, counters }, clientId) => {
  const { processed, total, errors } = counters;
  const finishedImportMessage = `Processed ${processed} ${pluralize(
    'user',
    processed,
  )} of ${total}.`;
  if (errors === 0) return finishedImportMessage;

  return getErrorDownloadMessage(
    importId,
    clientId,
    finishedImportMessage,
    errors,
  );
};

const getCancelledStatusMessage = ({ counters }) => {
  const { processed, total, errors } = counters;
  const cancelledImportMessage = `Cancelled - Processed ${processed} ${pluralize(
    'user',
    processed,
  )} of ${total}.`;
  if (errors === 0) return cancelledImportMessage;

  return getErrorMessage(cancelledImportMessage, errors);
};

export const barItemBaseProps = {
  [BulkImportUserStatusType.New]: {
    getIcon: () => 'loading',
    getBarItemText: () => <>Users import in queue.</>,
    getActions: ({ clientId, importData }) => [
      {
        onClick: () => handleImportProcessCancelAction(clientId, importData),
        text: 'Cancel Import',
      },
    ],
  },
  [BulkImportUserStatusType.Failed]: {
    getIcon: () => 'danger',
    getBarItemText: () => <>Users import failed.</>,
    getActions: () => [],
    getDismissAction: ({ clientId, importData, handleAcknowledgeUpdate }) =>
      handleImportJobAcknowledgeClick(
        clientId,
        importData,
        handleAcknowledgeUpdate,
      ),
  },
  [BulkImportUserStatusType.Running]: {
    getIcon: () => 'loading',
    getBarItemText: ({ counters }) => (
      <>
        Processed {counters.processed} {pluralize('user', counters.processed)}{' '}
        of {counters.total}.
      </>
    ),
    getActions: ({ clientId, importData }) => [
      {
        onClick: () => handleImportProcessCancelAction(clientId, importData),
        text: 'Cancel Import',
      },
    ],
  },
  [BulkImportUserStatusType.Finished]: {
    getIcon: ({ errors }) => (errors === 0 ? 'success' : 'warning'),
    getBarItemText: (importData, clientId) =>
      getFinishedStatusMessage(importData, clientId),
    getActions: () => [],
    getDismissAction: ({ clientId, importData, handleAcknowledgeUpdate }) =>
      handleImportJobAcknowledgeClick(
        clientId,
        importData,
        handleAcknowledgeUpdate,
      ),
  },
  [BulkImportUserStatusType.Cancelled]: {
    getIcon: () => 'warning',
    getBarItemText: (importData, clientId) =>
      getCancelledStatusMessage(importData, clientId),
    getActions: () => [],
    getDismissAction: ({ clientId, importData, handleAcknowledgeUpdate }) =>
      handleImportJobAcknowledgeClick(
        clientId,
        importData,
        handleAcknowledgeUpdate,
      ),
  },
};
