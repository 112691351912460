export default `
<svg
  aria-hidden="true"
  aria-label="format-strikethrough"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M19.8076 11.5272H13.5264C13.2451 11.4647 12.1826 11.1522 12.1826 11.1522C9.58887 10.4022 8.87012 9.74593 9.12012 8.37093C9.40137 6.65218 11.6514 6.33968 13.4951 6.65218C14.0264 6.71468 14.6514 6.87093 15.4639 7.15218C15.8701 7.30843 16.3076 7.08968 16.4326 6.71468C16.5576 6.30843 16.3701 5.87093 15.9639 5.74593C15.0576 5.43343 14.3389 5.24593 13.7451 5.15218C10.4639 4.65218 7.99512 5.83968 7.62012 8.12093C7.30762 9.83968 8.08887 10.8709 9.12012 11.5272H5.30762C4.87012 11.5272 4.55762 11.8709 4.55762 12.2459C4.55762 12.6522 4.87012 12.9959 5.30762 12.9959H19.8076C20.2139 12.9959 20.5576 12.6522 20.5576 12.2459C20.5576 11.8709 20.2139 11.5272 19.8076 11.5272ZM15.8389 14.5272C15.9951 14.8709 16.0576 15.2459 15.9639 15.7147C15.6826 17.4334 13.4326 17.7147 11.5889 17.4334C10.7764 17.3397 9.80762 16.9959 8.99512 16.6834L8.55762 16.5584C8.15137 16.4022 7.71387 16.6209 7.58887 16.9959C7.43262 17.4022 7.65137 17.8084 8.05762 17.9647L8.46387 18.1209C9.37012 18.4334 10.4014 18.7772 11.3389 18.9334C11.8389 18.9959 12.3389 19.0272 12.7764 19.0272C15.3076 19.0272 17.1514 17.9022 17.4639 15.9647C17.5576 15.4334 17.5576 14.9647 17.4639 14.5272H15.8389Z"
    fill="currentcolor"
  />
</svg>
`;
