import { isEmpty } from 'lodash';

import { everest } from '~/services';

type CopyWorkflowParams = {
  sourceWorkflowId: string;
  destinationClientId: number;
  destinationWorkflowId?: string;
};
export const copyWorkflow = async ({
  sourceWorkflowId,
  destinationClientId,
  destinationWorkflowId,
}: CopyWorkflowParams) => {
  return everest.post(`workflows/${sourceWorkflowId}/clone`, {
    target_client_id: destinationClientId,
    target_workflow_id: !isEmpty(destinationWorkflowId)
      ? destinationWorkflowId
      : undefined,
  });
};
