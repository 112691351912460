import { useEffect } from 'react';

export const useKeyDown = (
  key: string,
  callback: () => void,
  isEnabled = true,
) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (isEnabled && event.key === key) {
        callback();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => window.removeEventListener('keydown', handleEscape);
  }, [isEnabled, callback]);
};
