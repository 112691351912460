import PropTypes from 'prop-types';
import React from 'react';

import { User } from '~/components/Shared/User';
import { formatDate } from '~/eds';
import { FlexLayout, Text } from '~/ui';

function CommentHeader({ datetime, metadata, status, userId }) {
  return (
    <FlexLayout alignItems="center" space={3}>
      <FlexLayout flexDirection="column">
        <FlexLayout>
          <Text color="gray-700" variant="xs-dense-medium">
            <User
              id={userId}
              mode="info"
              options={{ info: formatDate(new Date(datetime), 'full') }}
            />
          </Text>
          {metadata}
        </FlexLayout>
        <Text color="gray-600" variant="2xs-dense">
          <FlexLayout alignItems="center">
            <FlexLayout mr={1}>{status}</FlexLayout>
          </FlexLayout>
        </Text>
      </FlexLayout>
    </FlexLayout>
  );
}

CommentHeader.propTypes = {
  datetime: PropTypes.string.isRequired,
  metadata: PropTypes.node,
  status: PropTypes.string,
  userId: PropTypes.number.isRequired,
};

export default CommentHeader;
