import React, { useState } from 'react';

import { Checkbox, Layout, SearchInput } from '~/eds';
import { Nullable } from '~/types';

type Props = {
  options?: string[];
  search?: string;
  selectAll: Nullable<boolean>;
  onSearch: (search: string) => void;
  onSelectAllToggle: (selectAll: Nullable<boolean>) => void;
};

export const OptionsManager = ({
  options = [],
  search = '',
  selectAll,
  onSearch,
  onSelectAllToggle,
}: Props) => {
  const [localSearch, setSearch] = useState(search);
  return (
    <Layout justify="space-between" pb={4} pr={2} pl={2} align="center">
      <SearchInput
        autoFocus
        name="search"
        value={localSearch}
        onChange={(value) => {
          setSearch(value || '');
          onSearch(value || '');
        }}
        placeholder="Search"
      />
      <Layout>
        <Checkbox
          disabled={!options.length}
          name="selectAll"
          value={selectAll}
          onChange={onSelectAllToggle}
          option={{
            label: 'Select All',
            value: selectAll,
          }}
        />
      </Layout>
    </Layout>
  );
};
