import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getSectionIds } from '~/reducers/intakeForm';
import { FlexLayout } from '~/ui';

import WorkflowFormSection from './WorkflowFormSection';

function WorkflowForm({ disabled, sections }) {
  return (
    <FlexLayout flexDirection="column" space={8}>
      {sections.map((sectionId) => {
        return (
          <WorkflowFormSection
            key={sectionId}
            disabled={disabled}
            sectionId={sectionId}
          />
        );
      })}
    </FlexLayout>
  );
}

WorkflowForm.propTypes = {
  disabled: PropTypes.bool,
};

const mapStateToProps = ({ intakeForm }) => ({
  sections: getSectionIds(intakeForm),
});

export default connect(mapStateToProps)(WorkflowForm);
