export function addScript({
  src,
  async,
  type,
  onLoad,
}: {
  src: string;
  async: boolean;
  type: string;
  onLoad: () => void;
}) {
  const script = document.createElement('script');

  script.setAttribute('src', src);
  script.setAttribute('type', type);
  script.async = async;
  script.onload = onLoad;

  document.body.appendChild(script);
}
