import { evisortRed } from '../../assets/shared-styles/general';

const styles = {
  documentViewPage: {
    display: 'flex',
    flexDirection: 'column',
  },
  documentContents: {
    display: 'flex',
  },
  documentTabs: {
    width: '100%',
  },
  documentViewerWrapper: {
    flexGrow: '1',
  },
  loadingContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  ecCardTitleWrapper: {
    fontSize: '15px',
  },
  documentContentWrapper: {
    maxHeight: '1043px',
    padding: '16px',
    overflowY: 'scroll',
    '& img': {
      maxWidth: '200px',
    },
  },
  errorLoadingDocument: {
    margin: '250px auto',
    textAlign: 'center',
    color: evisortRed,
    '& span': {
      marginLeft: '10px',
      position: 'relative',
      top: '-6px',
      lineHeight: '25px',
    },
  },
};

export default styles;
