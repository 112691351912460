import PropTypes from 'prop-types';
import React from 'react';

const ChevronRightIcon = (props) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      onClick={props.onClick}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 24V0h24v24z" />
        <path
          fill={props.color}
          fillOpacity={props.opacity}
          fillRule="nonzero"
          d="M9.31 16.161l4.125-4.166-4.124-4.167a1.077 1.077 0 0 1 0-1.514 1.051 1.051 0 0 1 1.499 0l4.88 4.929a1.077 1.077 0 0 1 0 1.514l-4.88 4.929a1.051 1.051 0 0 1-1.5 0 1.1 1.1 0 0 1 0-1.525z"
        />
      </g>
    </svg>
  );
};

ChevronRightIcon.defaultProps = {
  size: '24',
  color: 'currentColor',
  opacity: '.75',
  onClick: () => {},
};

ChevronRightIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
  onClick: PropTypes.func,
};

export default ChevronRightIcon;
