import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { Box } from '../Box';

interface Props {
  height?: string;
  width?: string;
}

export const LoadingShimmer = memo(({ height, width }: Props) => {
  const styles = useStyles();

  return (
    <Box role="status" styles={styles.shimmer} styleProps={{ height, width }} />
  );
});
