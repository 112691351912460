import { Option } from '../types';

interface Config<V = unknown> {
  includeValues?: V[];
  excludeValues?: V[];
}

/**
 * Creates a callback that filters included/excluded values to be used with `onFilterOption` in `Select`
 */
export function createOnFilterOption<V extends Object>({
  includeValues = [],
  excludeValues = [],
}: Config<V>) {
  return (option: Option<V>) => {
    const shouldFilter = includeValues.length > 0 || excludeValues.length > 0;
    const isIncluded =
      includeValues.length > 0 && includeValues.includes(option.value);
    const isNotIncluded =
      excludeValues.length > 0 && !excludeValues.includes(option.value);

    return !shouldFilter || isIncluded || isNotIncluded;
  };
}
