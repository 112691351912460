import React from 'react';

import { Link, Text } from '~/ui';

function UnpurchasedMsg() {
  return (
    <Text color="gray-600" variant="s-dense">
      To activate, please reach out to{' '}
      <Link href="mailto:support@evisort.com" variant="s-dense">
        support@evisort.com
      </Link>
    </Text>
  );
}

export default UnpurchasedMsg;
