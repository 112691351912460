import { AlignFormat, Format, FormatConfig, ListFormat } from './types';

export const FORMAT_CONFIGS: Record<Format, FormatConfig> = {
  bold: {
    format: 'bold',
    icon: 'format-bold',
    tooltip: 'Bold (⌘ + B)',
    type: 'mark',
  },
  italic: {
    format: 'italic',
    icon: 'format-italic',
    tooltip: 'Italicize (⌘ + I)',
    type: 'mark',
  },
  underline: {
    format: 'underline',
    icon: 'format-underline',
    tooltip: 'Underline (⌘ + U)',
    type: 'mark',
  },
  strikethrough: {
    format: 'strikethrough',
    icon: 'format-strikethrough',
    tooltip: 'Strikethrough (⌘ + ⇧ + X)',
    type: 'mark',
  },
  code: {
    format: 'code',
    icon: 'format-code',
    tooltip: 'Code (⌘ + ⇧ + C)',
    type: 'mark',
  },
  h1: {
    format: 'h1',
    icon: 'format-heading-1',
    tooltip: 'Heading 1',
    type: 'block',
  },
  h2: {
    format: 'h2',
    icon: 'format-heading-2',
    tooltip: 'Heading 2',
    type: 'block',
  },
  ol: {
    format: 'ol',
    icon: 'format-numbered-list',
    tooltip: 'Numbered List (⌘ + ⇧ + 7)',
    type: 'block',
  },
  ul: {
    format: 'ul',
    icon: 'format-bulleted-list',
    tooltip: 'Bulleted List (⌘ + ⇧ + 8)',
    type: 'block',
  },
  blockquote: {
    format: 'blockquote',
    icon: 'format-blockquote',
    tooltip: 'Blockquote',
    type: 'block',
  },
  pre: {
    format: 'pre',
    icon: 'format-code-block',
    tooltip: 'Code Block',
    type: 'block',
  },
  left: {
    format: 'left',
    icon: 'format-align-left',
    tooltip: 'Align Left',
    type: 'block',
  },
  center: {
    format: 'center',
    icon: 'format-align-center',
    tooltip: 'Align Center',
    type: 'block',
  },
  right: {
    format: 'right',
    icon: 'format-align-right',
    tooltip: 'Align Right',
    type: 'block',
  },
  justify: {
    format: 'justify',
    icon: 'format-align-justify',
    tooltip: 'Align Justify',
    type: 'block',
  },
};

export const FORMAT_HOTKEYS: Record<string, Format> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+shift+c': 'code',
  'mod+shift+x': 'strikethrough',
  'mod+shift+7': 'ol',
  'mod+shift+8': 'ul',
};

export const LIST_FORMAT_TYPES: ListFormat[] = ['ol', 'ul'];

export const ALIGN_FORMAT_TYPES: AlignFormat[] = [
  'left',
  'center',
  'right',
  'justify',
];
