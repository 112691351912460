import React from 'react';
import { useStyles } from 'uinix-ui';

import type { StatusType, TextColor } from '../../../../types';
import { Badge } from '../../../Badge';
import { Layout } from '../../../Layout';
import { Text } from '../../../Text';
import { Tooltip } from '../../../Tooltip';
import * as types from './types';

interface Props {
  index: number;
  step: types.Step;
  onSelectStep: (updatedStepId: string) => void;
  isActive?: boolean;
  isCompleted?: boolean;
  mode?: 'summary';
}

export const StepSummary = ({
  index,
  isActive,
  isCompleted,
  mode,
  step,
  onSelectStep,
}: Props) => {
  const styles = useStyles();

  const { id, title } = step;
  const isSummaryMode = mode === 'summary';

  const { status, titleColor } = resolveStepStyles({
    isActive,
    isCompleted,
  });

  const disabled = !(isCompleted || isActive);

  return (
    <Tooltip tooltip={isSummaryMode ? title : null}>
      <Layout
        as="button"
        align="center"
        spacing={2}
        styles={styles.button.unset}
        onClick={() => onSelectStep(step.id)}
      >
        <Layout disabled={disabled} id={id}>
          <Badge status={status} number={index + 1} />
        </Layout>
        {!isSummaryMode && (
          <Text color={titleColor} variant="section">
            {title}
          </Text>
        )}
      </Layout>
    </Tooltip>
  );
};

const resolveStepStyles = ({
  isActive,
  isCompleted,
}: {
  isActive?: boolean;
  isCompleted?: boolean;
}): {
  status: StatusType;
  titleColor: TextColor;
} => {
  if (isActive) {
    return {
      status: 'active',
      titleColor: 'status.active',
    };
  } else if (isCompleted) {
    return {
      status: 'success',
      titleColor: 'text.quiet',
    };
  } else {
    return {
      status: 'info',
      titleColor: 'text.quiet',
    };
  }
};
