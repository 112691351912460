import { trim } from 'lodash';
import PT from 'prop-types';
import React from 'react';

import styles from '../../styles';
import Box from '../Box';
import FlexLayout from '../FlexLayout';
import Icon from '../Icon';
import Text from '../Text';
import Tooltip from '../Tooltip';

function Folder({
  emptyFolderMessage = 'No folder',
  folder,
  isConcise = false,
  isLoading = false,
  shouldTruncate = false,
  subtext = '',
}) {
  const { name, path, rootPrefix = '', type } = folder || {};
  const subfolders = trim(path || '', '/').split('/');
  const hasSubfolders = subfolders.length > 0 && subfolders[0] !== '';
  const lastFolder = subfolders[subfolders.length - 1];

  // TODO: test this behavior in component test
  const subfolderPath =
    hasSubfolders &&
    subfolders.map((subfolder, i) => {
      const element = i === 0 ? 'span' : 'strong';
      const isLast = i === subfolders.length - 1;
      if (isLast) {
        return (
          <Box as={element} key={i}>
            {subfolder}
          </Box>
        );
      } else {
        return `${subfolder}/`;
      }
    });

  const color = 'gray-700';
  const variant = 'xs-dense';

  let content;
  if (!folder) {
    content = (
      <Text color="gray-600" variant={variant}>
        {emptyFolderMessage}
      </Text>
    );
  } else if (isConcise) {
    content = (
      <Tooltip content={path}>
        <Text color={color} variant={variant} sx={styles.typography.truncate}>
          {rootPrefix}
          {hasSubfolders ? lastFolder : name}
        </Text>
      </Tooltip>
    );
  } else {
    content = (
      <FlexLayout flexDirection="column">
        <Text shouldTruncate={shouldTruncate} color={color} variant={variant}>
          {rootPrefix}
          {hasSubfolders ? subfolderPath : name}
        </Text>
        {subtext && (
          <Text color="gray-700" variant="tiny">
            {subtext}
          </Text>
        )}
      </FlexLayout>
    );
  }

  let icon;
  switch (type) {
    case 'drive':
      icon = 'drive';
      break;
    case 'external':
      icon = 'folderOutline';
      break;
    case 'internal':
    default:
      icon = 'folder';
  }
  if (isLoading) {
    icon = 'spinner';
  }

  return (
    <FlexLayout
      alignItems="center"
      space={3}
      sx={{
        borderRadius: 'm',
        maxWidth: 'fit-content',
      }}
    >
      <Icon color="gray-600" icon={icon} title={icon} />
      {content}
    </FlexLayout>
  );
}

const folderIdPropType = PT.oneOfType([
  PT.string.isRequired,
  PT.number.isRequired,
]);

export const folderShape = {
  id: folderIdPropType.isRequired,
  name: PT.string.isRequired,
  type: PT.oneOf(['internal', 'external', 'drive']),
};
Folder.propTypes = {
  /** Placeholder message if folder is an empty value */
  emptyFolderMessage: PT.string,
  /** A valid folder object */
  folder: PT.shape(folderShape),
  /** Renders only the last folder path with a tooltip for the full path */
  isConcise: PT.bool,
  /** Renders a loading spinner in place of the folder icon */
  isLoading: PT.bool,
  /** Truncate folder path if true */
  shouldTruncate: PT.bool,
};

export default Folder;
