import { managedEntities } from '~/services';

interface Request {
  clientId: number;
}

interface Response {
  type?: string;
  id: string;
  attributes: {};
  relationships?: {};
}

export const getEntityInstanceByClient = async ({
  clientId,
}: Request): Promise<Response> => {
  const response: Response = await managedEntities.get(
    `/entity-instances/client/${clientId}`,
  );
  return response;
};
