import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getDisabledSaveSearchActionButtonTooltip,
  shouldSaveSearchActionButtonDisabled,
} from '~/components/SearchV2/SearchV2.utils';
import { showToast } from '~/components/Shared/EcToast';
import { Layout } from '~/eds';
import { api, selectors } from '~/redux';
import { buildQuery } from '~/redux/api/methods';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Button, useModal } from '~/ui';

import SaveSearchForm from '../SaveSearchForm';

const SaveSearch = ({ queryProvided }) => {
  const query = useSelector(selectors.selectQuery);
  const unsupportedSavedSearchQuery = useSelector(
    selectors.selectUnsupportedSavedSearchQuery,
  );

  const hasSearchQuery =
    unsupportedSavedSearchQuery?.length ||
    queryProvided?.length ||
    query?.booleanQuery?.length > 0 ||
    query?.filters?.length > 0;

  const [formData, setFormData] = useState();

  const [saveSearch, saveSearchResult] = api.endpoints.saveSearch.useMutation();
  const {
    isError: saveSearchError,
    isSuccess: saveSearchSuccess,
  } = saveSearchResult;

  const resetSaveSearchInputs = () => {
    setFormData(undefined);
  };

  useEffect(() => {
    if (saveSearchError) {
      showToast(ERROR, 'Save search failed. Please try again.');
    }
  }, [saveSearchError]);

  useEffect(() => {
    if (saveSearchSuccess) {
      resetSaveSearchInputs();
      showToast(SUCCESS, 'Search has been successfully saved.');
    }
  }, [saveSearchSuccess]);

  const getSaveSearchIconTooltip = () => {
    if (!query && !queryProvided && !unsupportedSavedSearchQuery?.length) {
      return 'Enter text in the search bar and/or apply filters to perform a search before saving';
    } else {
      return 'Save Search';
    }
  };

  const legacyQuery = unsupportedSavedSearchQuery || queryProvided;

  const noQueryProvided = !(
    unsupportedSavedSearchQuery ||
    queryProvided ||
    query
  );
  const [saveSearchModal, showSaveSearchModal] = useModal({
    onHide: () => resetSaveSearchInputs(),
    onCancel: () => resetSaveSearchInputs(),
    actionButton: {
      disabled:
        shouldSaveSearchActionButtonDisabled(formData) || noQueryProvided,
      promise: () => {
        const queryToSave = legacyQuery ?? buildQuery(query);
        const formDataToSubmit = { ...formData, query: queryToSave };
        saveSearch(formDataToSubmit);
      },
      text: 'Save Search',
      tooltip: getDisabledSaveSearchActionButtonTooltip(formData),
    },
    overflowDisplay: 'visible',
    title: 'Save Search',
    content: <SaveSearchForm handleFormDataChanges={setFormData} />,
  });

  return (
    <Layout minW="120" ml={3}>
      <Button
        disabled={!hasSearchQuery}
        text="Save Search"
        tooltip={getSaveSearchIconTooltip()}
        variant="primary"
        onClick={showSaveSearchModal}
      />
      {saveSearchModal}
    </Layout>
  );
};

export default SaveSearch;
