import PropTypes from 'prop-types';
import React from 'react';

import { Button, FlexLayout } from '~/ui';

import { SaveSearchesTypes } from '../utils';

const SavedSearchesTypesButtonGroup = ({ types, setTypesChange }) => {
  const handleTypeToggle = (type) => {
    const typesToSet = types.includes(type)
      ? types.filter((item) => item !== type)
      : [...types, type];
    setTypesChange(typesToSet);
  };

  const getButtons = () => {
    return SaveSearchesTypes.map((type) => {
      return (
        <Button
          key={type.value}
          text={type.label}
          variant={types.includes(type.value) ? 'primary' : 'secondary'}
          onClick={() => handleTypeToggle(type.value)}
        />
      );
    });
  };

  return (
    <FlexLayout pb={8} space={3}>
      {getButtons()}
    </FlexLayout>
  );
};

SavedSearchesTypesButtonGroup.propTypes = {
  types: PropTypes.array.isRequired,
  setTypesChange: PropTypes.func.isRequired,
};

export default SavedSearchesTypesButtonGroup;
