import { types } from '~/eds';
import { pilot } from '~/services';

import { BaseRequest } from './types';

type Field = {
  id: types.PilotId;
  keepOldValues: boolean;
  value: string | string[] | number;
};
interface Request extends BaseRequest {
  fields?: Field[];
}

type Params = {
  document_handler_ids: types.PilotId[];
  query?: object;
  fields?: {
    id: types.PilotId;
    value: string | string[] | number;
    keep_old_values: boolean;
  }[];
};
export const documentBulkAddReplaceFields = (
  request: Request,
): Promise<{ detail: string }> =>
  pilot.put('document/bulk-edit/', mapRequestToParams(request));

const mapRequestToParams = ({
  documentHandlerIds,
  fields,
  searchQuery,
  isAllSelected,
  searchAPI,
}: Request): Params => ({
  document_handler_ids:
    !isAllSelected && documentHandlerIds ? documentHandlerIds : [],
  fields: fields?.map(({ id, keepOldValues, value }) => ({
    id,
    value,
    keep_old_values: keepOldValues,
  })),
  ...(isAllSelected
    ? searchAPI === 'v3'
      ? { query_v3: searchQuery }
      : { query: searchQuery }
    : undefined),
});
