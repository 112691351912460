import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { Button } from '~/eds';
import { HtmlContent } from '~/ui';

import { evisortBlue } from '../../../assets/shared-styles/general';
import { MODAL_CKEDITOR, MODAL_DELETE } from '../../../types/modal.types';
import EcCard from '../EcCard';
import EcModal from '../EcModal';
import DocumentOutlineIcon from '../Icons/DocumentOutlineIcon';
import styles from './EcTable.styles';

const cardContentStyles = { padding: '16px' };

class EcTable extends Component {
  constructor(props) {
    super(props);

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleSaveTable = this.handleSaveTable.bind(this);
    this.handleDeleteTable = this.handleDeleteTable.bind(this);
    this.state = { currentModal: null };
  }

  handleShowModal(modal) {
    this.setState({ currentModal: modal });
  }

  handleHideModal() {
    this.setState({ currentModal: null });
  }

  handleDeleteTable() {
    const { tableId, handleTableDelete } = this.props;

    handleTableDelete(tableId);
  }

  handleSaveTable(newTableContent) {
    const { tableId, handleTableUpdate } = this.props;

    handleTableUpdate(tableId, newTableContent);
  }

  renderCKEditorModal() {
    const {
      tableContent,
      allowRowChanges,
      allowColumnChanges,
      allowCellChanges,
    } = this.props;

    return (
      <EcModal
        width="80%"
        modalType={MODAL_CKEDITOR}
        tableContent={tableContent}
        hideModal={this.handleHideModal}
        handleActionClick={this.handleSaveTable}
        allowRowChanges={allowRowChanges}
        allowColumnChanges={allowColumnChanges}
        allowCellChanges={allowCellChanges}
      />
    );
  }

  renderDeleteModalText() {
    const { tableName } = this.props;

    return (
      <Fragment>
        Are you sure you want to delete
        <span> {tableName}</span>? You won’t be able to undo this action.
      </Fragment>
    );
  }

  renderDeleteModal() {
    return (
      <EcModal
        modalType={MODAL_DELETE}
        width="560px"
        title="Delete Table?"
        text={this.renderDeleteModalText()}
        confirmButtonText="Delete"
        deleteItem={this.handleDeleteTable}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderRightComponent() {
    return (
      <>
        <Button
          text="Edit Table"
          onClick={() => this.handleShowModal(MODAL_CKEDITOR)}
          variant="action"
        />
        <Button
          text="Delete Table"
          onClick={() => this.handleShowModal(MODAL_DELETE)}
          variant="action"
        />
      </>
    );
  }

  render() {
    const { classes, tableName, tableContent } = this.props;
    const { currentModal } = this.state;

    return (
      <Fragment>
        <EcCard
          title={
            <Fragment>
              <DocumentOutlineIcon color={evisortBlue} />
              {tableName}
            </Fragment>
          }
          contentStyles={cardContentStyles}
          titleRightComponent={this.renderRightComponent()}
        >
          <HtmlContent className={classes.tablesItem} html={tableContent} />
        </EcCard>
        <div className={classes.horizontalSeparator} />
        {currentModal === MODAL_CKEDITOR ? this.renderCKEditorModal() : null}
        {currentModal === MODAL_DELETE ? this.renderDeleteModal() : null}
      </Fragment>
    );
  }
}

EcTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableId: PropTypes.number.isRequired,
  tableName: PropTypes.string.isRequired,
  tableContent: PropTypes.string.isRequired,
  handleTableUpdate: PropTypes.func.isRequired,
  handleTableDelete: PropTypes.func.isRequired,
  allowRowChanges: PropTypes.bool.isRequired,
  allowColumnChanges: PropTypes.bool.isRequired,
  allowCellChanges: PropTypes.bool.isRequired,
};

export default injectSheet(styles)(EcTable);
