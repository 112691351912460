import React from 'react';
import injectSheet from 'react-jss';

import styles from './UnsupportedBrowserPage.styles';

function UnsupportedBrowserPage(props) {
  const { classes } = props;

  return (
    <div className={classes.unsupportedBrowserContainer}>
      <div className={classes.unsupportedBrowserMessage}>
        <div className={classes.unsupportedBrowserImageContainer}>
          <img
            className={classes.unsupportedBrowserContainerLogo}
            alt="Evisort"
            title="Evisort"
            src="./evisort.png"
          />
        </div>
        Your web browser is not supported. Please log into Evisort {}
        <a
          href="https://support.evisort.com/hc/en-us/articles/360044832854-System-Requirements"
          target="_blank"
          rel="noreferrer"
        >
          using a supported web browser
        </a>
        .
      </div>
    </div>
  );
}

export default injectSheet(styles)(UnsupportedBrowserPage);
