import React, { Component } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom-v5-compat';

import { getUploadLogs } from '~/api';
import { handle403 } from '~/api/errorHandlers';
import { documentTypeIcon } from '~/components/DocumentsPage/Document.utils';
import { getUserCanModifyFolder } from '~/components/DocumentsViewPage/Document.data';
import { trackSegment } from '~/components/SegmentAnalytics';
import EcButton from '~/components/Shared/EcButton';
import EcCard from '~/components/Shared/EcCard';
import EcModal from '~/components/Shared/EcModal';
import EcPaginate from '~/components/Shared/EcPaginate';
import CheckmarkIcon from '~/components/Shared/Icons/CheckmarkIcon';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import UploadFilesIcon from '~/components/Shared/Icons/UploadFilesIcon';
import PageTitle from '~/components/Shared/PageTitle';
import {
  getFolderTree,
  styles,
  UploadSize,
  UploadStatus,
} from '~/components/UploadPage';
import { Box, formatDate } from '~/eds';
import { UploadStatusType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, withFlags } from '~/flags';
import { uploadDocuments } from '~/slices/fileUpload';
import { MODAL_FOLDER_TREE, MODAL_UPLOAD } from '~/types/modal.types';
import { filterValidFiles } from '~/utils/helper.utils';
import { getClientInfo, getUserClientInfo } from '~/utils/user';

const cardContentStyles = { minHeight: '556px', padding: '0 16px 16px 16px' };

class Page extends Component {
  constructor(props) {
    super(props);

    this.handleUploadLocationChange = this.handleUploadLocationChange.bind(
      this,
    );
    this.handleAddUploadFiles = this.handleAddUploadFiles.bind(this);
    this.handleRemoveUploadFile = this.handleRemoveUploadFile.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleShowSecondaryModal = this.handleShowSecondaryModal.bind(this);
    this.handleHideSecondaryModal = this.handleHideSecondaryModal.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleLeavePage = this.handleLeavePage.bind(this);

    this.dropzone = null;
    this.state = {
      loading: true,
      folderTree: null,
      uploadLocation: null,
      uploadFiles: [],
      uploadLogs: [],
      upload_form_data: [],
      page: 1,
      pageCount: 1,
      pageSize: 10,
      primaryModal: null,
      secondaryModal: null,
      hasUploadPermission: null,
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleLeavePage, false);

    getFolderTree().then((folderTree) => this.setState({ folderTree }));

    this.loadUploadLogs();

    this.interval = setInterval(() => this.refreshUploadLogs(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('beforeunload', this.handleLeavePage, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.primaryModal && !this.state.primaryModal) {
      this.setState({ uploadLocation: null, uploadFiles: [] });
    }
  }

  handleLeavePage(event) {
    const { uploadingFiles } = this.props;

    if (uploadingFiles.length > 0) {
      const message = 'Changes you made may not be saved.';
      event.returnValue = message;
      return message;
    }
  }

  loadUploadLogsForPage = async (page) => {
    const res = await getUploadLogs(page);

    this.setState({ uploadLogs: res.results, pageCount: res.count });
  };

  loadUploadLogs() {
    const { page } = this.state;

    this.setState({ loading: true });
    this.loadUploadLogsForPage(page)
      .then(() => {
        this.setState({ loading: false, errorLoadingUploadLogs: false });
      })
      .catch(() => {
        this.setState({ errorLoadingUploadLogs: true, loading: false });
      });
  }

  refreshUploadLogs() {
    const { uploadingFiles } = this.props;
    const { page, uploadLogs } = this.state;

    const anyProcessingFile = uploadLogs.some(
      (log) =>
        log.processing_status === UploadStatusType.Uploading ||
        log.processing_status === UploadStatusType.Queued ||
        log.processing_status === UploadStatusType.Processing,
    );

    if (uploadingFiles.length || anyProcessingFile) {
      this.loadUploadLogsForPage(page).catch(() => {
        this.setState({ errorLoadingUploadLogs: true, loading: false });
      });
    }
  }

  handleShowModal(modal) {
    this.setState({ primaryModal: modal });
  }

  handleHideModal() {
    this.setState({ primaryModal: null });
  }

  handleShowSecondaryModal(modal) {
    this.setState({ secondaryModal: modal });
  }

  handleHideSecondaryModal() {
    this.setState({ secondaryModal: null });
  }

  handleUploadLocationChange(location) {
    getUserCanModifyFolder(location.id)
      .then((res) => {
        this.setState({
          uploadLocation: location,
          hasUploadPermission: res['can_modify_folder'],
        });
      })
      .catch(handle403);
  }

  handleAddUploadFiles(files) {
    const newFiles = Object.keys(files).map((key) => files[key]);

    getUserCanModifyFolder()
      .then((res) => {
        let hasPermissionToUpload = res['can_modify_folder'];
        this.setState({
          uploadFiles: [...this.state.uploadFiles, ...newFiles],
          hasUploadPermission: hasPermissionToUpload,
        });
      })
      .catch(handle403);
  }

  handleRemoveUploadFile(uploadId) {
    const newStateFiles = this.state.uploadFiles.filter(
      (file) => file.upload.uuid !== uploadId,
    );
    this.setState({ uploadFiles: newStateFiles });
  }

  handlePageClick(clickedPage) {
    const selectedPage = clickedPage.selected + 1;
    this.setState({ page: selectedPage }, this.loadUploadLogs);
  }

  renderFolderTreeModal() {
    const { uploadLocation } = this.state;
    const { flags } = this.props;
    const hasSearchFolderPanel = flags[FlagType.SearchFolderPanel];

    return (
      <EcModal
        modalType={MODAL_FOLDER_TREE}
        width="540px"
        title="Choose Location"
        uploadLocation={uploadLocation}
        folderIdsSelected={[]}
        handleUploadLocationChange={this.handleUploadLocationChange}
        confirmButtonIcon={<CheckmarkIcon size="20" />}
        confirmButtonText="Choose Location"
        hideModal={this.handleHideSecondaryModal}
        enableSearch={hasSearchFolderPanel}
      />
    );
  }

  renderUploadModal() {
    const {
      folderTree,
      uploadFiles,
      uploadLocation,
      hasUploadPermission,
    } = this.state;

    if (!folderTree) return null;

    const { uploadDocuments, user } = this.props;

    const extendedUploadFiles =
      uploadFiles && uploadFiles.length
        ? uploadFiles.map((file) => {
            file.folderId = uploadLocation ? uploadLocation.id : folderTree.id;
            return file;
          })
        : [];

    if (extendedUploadFiles.length) {
      return (
        <EcModal
          key="primary-modal"
          modalType={MODAL_UPLOAD}
          width="960px"
          title="Upload"
          uploadLocationName={
            uploadLocation ? uploadLocation.name : folderTree.name
          }
          uploadLocationPath={uploadLocation?.path ? uploadLocation.path : []}
          uploadFiles={extendedUploadFiles}
          hasUploadPermission={hasUploadPermission}
          confirmButtonText={'Upload Documents'}
          handleAddUploadFiles={this.handleAddUploadFiles}
          handleRemoveUploadFile={this.handleRemoveUploadFile}
          hideModal={this.handleHideModal}
          handleUploadFiles={(formFields) => {
            trackSegment('User Uploads Document', {
              fileNames: extendedUploadFiles.map((file) => file.name),
              groupId: getClientInfo(user),
              userId: getUserClientInfo(user),
            });
            (async () => {
              await uploadDocuments({
                files: extendedUploadFiles,
                uploadFormFields: formFields,
                apiConfig: {
                  service: 'pilot',
                  method: 'post',
                  url: '/document/upload/',
                },
              });

              this.loadUploadLogsForPage(this.state.page);
            })();
          }}
          showSecondaryModal={this.handleShowSecondaryModal}
        />
      );
    } else {
      return null;
    }
  }

  renderPagination() {
    const { page, pageCount, pageSize } = this.state;

    if (pageCount <= pageSize) return null;

    return (
      <EcPaginate
        onPageChange={this.handlePageClick}
        pageCount={Math.ceil(pageCount / pageSize)}
        forcePage={page - 1}
      />
    );
  }

  renderLogAction({ processing_status, id }) {
    switch (processing_status) {
      case UploadStatusType.Uploading:
        // return <span>Cancel</span>;
        return null;
      case UploadStatusType.UploadFailed:
      case UploadStatusType.ProcessingError:
        return <span>&ndash;</span>;
      default:
        return (
          <Link
            id={`upload_row_viewLink?document=${id}`}
            to={`/document/${id}`}
          >
            View
          </Link>
        );
    }
  }

  renderUploadRow(log) {
    const { classes, user } = this.props;

    const logSize = [
      UploadStatusType.Uploading,
      UploadStatusType.UploadFailed,
      UploadStatusType.UploadFileTypeFailure,
    ].includes(log.processing_status)
      ? log.size
      : log.size * 1024;

    return (
      <tr key={log.id}>
        <td className={classes.cellDocumentName}>
          <Link
            id={`upload_row_docNameLink?document=${log.id}`}
            to={`/document/${log.id}`}
          >
            {documentTypeIcon(log.file_type)}
            {log.document_name}
          </Link>
        </td>
        <td className={classes.cellSize}>
          <UploadSize size={logSize} />
        </td>
        <td>
          {formatDate(log.date_added ? new Date(log.date_added) : null, 'full')}
        </td>
        <td>
          <UploadStatus
            user={user}
            logId={log.id}
            status={log.processing_status}
            progress={log.progress}
            algoStatus={log.algo_status_message}
          />
        </td>
        <td className={classes.cellAction}>{this.renderLogAction(log)}</td>
      </tr>
    );
  }

  renderUploadLogTable() {
    const { classes, uploadingFiles } = this.props;
    const { uploadLogs, page } = this.state;

    return (
      <table className={classes.uploadLogsTable}>
        <thead>
          <tr>
            <th>Document Name</th>
            <th className={classes.headerSize}>Size</th>
            <th className={classes.headerDate}>Upload Date</th>
            <th className={classes.headerStatus}>Status</th>
            <th className={classes.headerAction} />
          </tr>
        </thead>
        <tbody>
          {page === 1
            ? uploadingFiles.map((log) => this.renderUploadRow(log))
            : null}
          {uploadLogs.map((log) => this.renderUploadRow(log))}
        </tbody>
      </table>
    );
  }

  render() {
    const { classes, uploadingFiles } = this.props;
    const {
      loading,
      errorLoadingUploadLogs,
      primaryModal,
      secondaryModal,
      uploadLogs,
    } = this.state;

    const componentConfig = { postUrl: 'no-url' };
    const djsConfig = {
      autoQueue: false,
      autoProcessQueue: false,
      clickable: '#upload_dropzone_selectDocumentsButton',
      dictDefaultMessage: '',
      previewTemplate: '<div class="ignore"></div>',
    };
    const eventHandlers = {
      init: (dz) => (this.dropzone = dz),
      addedfiles: async (files) => {
        const validFiles = await filterValidFiles(files);
        if (validFiles.length > 0) {
          setTimeout(() => {
            this.handleAddUploadFiles(validFiles);
            this.handleShowModal(MODAL_UPLOAD);
          }, 0);
        }
      },
    };

    return (
      <div className={classes.uploadPage}>
        <PageTitle title="Upload Documents" />
        <div className={classes.uploadCard}>
          <EcCard>
            <DropzoneComponent
              className={classes.uploadZoneContent}
              config={componentConfig}
              djsConfig={djsConfig}
              eventHandlers={eventHandlers}
            >
              <div className={classes.uploadContentIcon}>
                <UploadFilesIcon />
              </div>
              <div className={classes.uploadContentText}>
                <div className={classes.uploadText}>
                  Drag files here to upload them...
                </div>
                <div className={classes.uploadTextSmall}>
                  … or use the button below to choose documents from your
                  machine.
                </div>
                <div className={classes.uploadAction}>
                  <Box id={OnboardingIdType.UploadButton}>
                    <EcButton
                      id="upload_dropzone_selectDocumentsButton"
                      yellow
                      text="Select Documents"
                    />
                  </Box>
                </div>
              </div>
            </DropzoneComponent>
          </EcCard>
        </div>
        <div className={classes.uploadCard}>
          <EcCard title="Upload Log" contentStyles={cardContentStyles}>
            {loading && !errorLoadingUploadLogs ? (
              <div className={classes.loadingContainer}>
                <LoadingSpinner size="medium" />
              </div>
            ) : !loading && errorLoadingUploadLogs ? (
              <div className={classes.loadingContainer}>
                An error occurred while loading the upload logs.
              </div>
            ) : uploadingFiles.length || (uploadLogs && uploadLogs.length) ? (
              this.renderUploadLogTable()
            ) : (
              <div className={classes.noUploadMessage}>
                You haven’t uploaded any documents yet.
              </div>
            )}
          </EcCard>
          <span className={classes.horizontalSeparator} />
          {this.renderPagination()}
        </div>
        {primaryModal ? this.renderUploadModal() : null}
        {secondaryModal ? this.renderFolderTreeModal() : null}
      </div>
    );
  }
}

const mapStateToProps = ({ currentUser, dropzone }) => ({
  user: currentUser,
  uploadingFiles: dropzone.uploadingFiles,
});

Page = connect(mapStateToProps, { uploadDocuments })(Page);
export default injectSheet(styles)(withFlags(Page));
