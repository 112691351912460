import { atlas } from '~/services';
import { Uuid } from '~/types';

import { QuestionsResponsePayload } from './types';
import { mapQuestionResponse } from './utils';

export const getQuestionGroupQuestions = async ({ id }: { id: Uuid }) => {
  const response: QuestionsResponsePayload = await atlas.get(
    `question-groups/${id}/questions`,
  );

  return response.data.map(mapQuestionResponse);
};
