import { noop } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import chatBackground from '~/assets/images/chatbot/chatbot-background.gif';
import { trackSegment } from '~/components/SegmentAnalytics';
import { RETRY_ERROR } from '~/constants/errors';
import { copy, Divider, Layout, Markdown, useToast } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { usePermission } from '~/hooks';
import { actions, api } from '~/redux';

import { MessageAction, Message as MessageType } from '../../types';

export const useGetMessageActions = () => {
  const dispatch = useDispatch();
  const enableFieldAi = useFlag(FlagType.FieldAi);
  const { toast } = useToast();
  const { hasPermission: hasAutomationPermission } = usePermission({
    permission: { resourceId: 'document_x_ray', resourceType: 'view' },
  });

  return useMemo(() => {
    const messageActions: MessageAction[] = [];
    const xRayAction: MessageAction = {
      icon: 'status-new',
      disabled: !enableFieldAi,
      tooltip: enableFieldAi
        ? 'Automate with Document X-Ray'
        : 'Automate with Document X-Ray\r\n(Contact Evisort to Purchase)',
      onClick: enableFieldAi
        ? (message: MessageType, messages: MessageType[] = []) => {
            const index = messages.findIndex((m) => m.id === message.id);
            const previousMessage = index >= 1 ? messages[index - 1] : null;
            const previousMessageText =
              previousMessage?.userType === 'current'
                ? previousMessage.text
                : '';
            if (previousMessageText) {
              dispatch(
                actions.setFieldAiRedirect({
                  instructions: previousMessageText,
                }),
              );
            }
          }
        : noop,
    };
    const tooltipAction: MessageAction = {
      icon: 'clipboard',
      tooltip: 'Copy to clipboard',
      onClick: (message: MessageType, messages: MessageType[] = []) => {
        const index = messages.findIndex((m) => m.id === message.id);
        const previousMessage = index > 1 ? messages[index - 1] : null;
        const previousMessageText =
          previousMessage?.userType === 'current' ? previousMessage.text : '';
        const copyText = previousMessageText
          ? `${previousMessageText}\r\n\r\n${message.text}`
          : message.text;
        copy(copyText);
        toast({
          message: 'Chat message copied successfully.',
          status: 'success',
        });
        trackSegment('selectChatMessageAction', {
          messageId: message.id,
          action: 'copy',
        });
      },
    };
    if (hasAutomationPermission) {
      messageActions.push(xRayAction);
    }

    messageActions.push(tooltipAction);

    return messageActions;
  }, []);
};

export const useLegalDisclaimer = ({
  hasAccepted,
  onAccept,
}: {
  hasAccepted: boolean;
  onAccept: () => void;
}) => {
  const [
    acceptLegalDisclaimer,
    { isError, isFetching: isLoading, isSuccess },
  ] = api.endpoints.acceptLegalDisclaimer.useLazyQuery();

  const { toast } = useToast();

  useEffect(() => {
    if (isError) {
      toast({
        message: RETRY_ERROR,
        status: 'danger',
      });
    }
  }, [isError]);

  const accept = {
    isLoading,
    text: 'Get Started',
    onClick: async () => {
      await acceptLegalDisclaimer({});
      await onAccept();
      trackSegment('selectChatTerms', {
        value: 'accept',
      });
    },
  };

  return useMemo(
    () => ({
      accept,
      hasAccepted: hasAccepted || isSuccess,
      welcomeContent: {
        backgroundURL: chatBackground,
        content: (
          <Layout direction="column" spacing={2}>
            <Divider />
            <Markdown
              text="Evisort's conversational search experience is powered by AI, and may provide responses that do not reflect the view of Evisort. It should be used as a supplement, not a replacement for legal advice or legal analysis, and is not intended to replace the advice from, or review by, a licensed attorney."
              color="text.secondary"
            />
          </Layout>
        ),
        ctaAction: accept,
        icon: 'evisort-ai-animated' as const,
        link: {
          href:
            'https://support.evisort.com/hc/en-us/articles/1500006219501-Documents-Overview-Managing-Your-Documents',
          text: 'Evisort Knowledge Center',
        },
        listItems: [
          {
            description: '',
            icon: 'search-gradient' as const,
            title: 'Find information in your documents',
          },
          {
            description: '',
            icon: 'automation-gradient' as const,
            title: 'Get contractual terms explained',
          },
          {
            description: '',
            icon: 'status-new-gradient' as const,
            title: 'See where the AI found its answer with the source buttons',
          },
        ],
        mode: 'hero' as const,
        status: 'ai' as const,
        title: 'Welcome to Ask AI',
      },
    }),
    [hasAccepted, isLoading, isSuccess],
  );
};
