export enum TrainingStatus {
  AWAITING_DECISION = 'awaiting user decision to publish',
  AWAITING_LABELS = 'awaiting user labels',
  EVAL_PERF = 'evaluating performance',
  IN_PROGRESS = 'training in progress',
  PUBLISH_COMPLETED = 'publish completed successfully',
  PUBLISH_ERROR = 'error during publish',
  PUBLISH_IN_PROGRESS = 'publish in progress',
  READY_TO_DEPLOY = 'ready to deploy',
  TRAINING_ERROR = 'general error',
}
