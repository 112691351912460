import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import { Icon, IconButton } from '~/eds';

import { syntaxOptions } from './EcBooleanTextSearchPopup.options';
import styles from './EcBooleanTextSearchPopup.styles';

function EcBooleanTextSearchPopup({ classes, handleOnCloseClick }) {
  return (
    <div
      className={classes.booleanSyntaxPopup}
      role="dialog"
      aria-labelledby="searchOperatorTitle"
    >
      <div className={classes.booleanSyntaxHeader}>
        <div className={classes.booleanSyntaxTitle} id="searchOperatorTitle">
          <Icon icon="info" />
          Search Operators
        </div>
        <IconButton
          icon="x"
          text="close search operators hint dialog"
          tooltip="Close"
          onClick={handleOnCloseClick}
        />
      </div>
      <div className={classes.booleanSyntaxOptionsList}>
        {syntaxOptions.map((option, index) => {
          const getLabel = () => {
            return option.examples.map((example) => {
              return `${example.text} ${example.description}`;
            });
          };
          const getTooltipContent = () => {
            return option.examples.map((example, exampleIndex) => {
              return (
                <div
                  className={classes.optionTooltipExample}
                  key={`example-${exampleIndex}`}
                >
                  <div className={classes.optionTooltipText}>
                    Example: <span>{example.text}</span>
                  </div>
                  <div className={classes.optionTooltipDescription}>
                    {example.description}
                  </div>
                </div>
              );
            });
          };
          return (
            <div
              className={classes.booleanSyntaxOption}
              key={`syntax-option-${index}`}
            >
              <div className={classes.optionLabelContainer}>
                <div className={classes.optionLabel}>{option.label}</div>
              </div>
              <div className={classes.optionDescription}>
                {option.description}
              </div>
              <Icon
                icon="info"
                label={getLabel()}
                tooltip={getTooltipContent()}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

EcBooleanTextSearchPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  handleOnCloseClick: PropTypes.func.isRequired,
};

export default injectSheet(styles)(EcBooleanTextSearchPopup);
