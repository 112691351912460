import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Text } from '~/ui';

// TODO: design should formally spec this composed layout
function CardList({ children, title }) {
  return (
    <FlexLayout flexDirection="column" space={6}>
      <Text color="gray-900" variant="l-dense-bold">
        {title}
      </Text>
      <FlexLayout flexDirection="column" space={3}>
        {children}
      </FlexLayout>
    </FlexLayout>
  );
}

CardList.propTypes = {
  /** Children should be instances of `Card` components */
  children: PT.node,
  title: PT.string.isRequired,
};

export default CardList;
