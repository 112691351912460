export default `
<svg
  aria-hidden="true"
  aria-label="maximize"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M20.4375 3H14.8125C14.5031 3 14.25 3.25158 14.25 3.5625C14.25 3.87342 14.5031 4.125 14.8125 4.125H19.0805L13.2902 9.91523C13.0705 10.135 13.0705 10.4911 13.2902 10.7105C13.51 10.9298 13.8661 10.9302 14.0855 10.7105L19.875 4.92059V9.1875C19.875 9.49687 20.1281 9.75 20.4375 9.75C20.7469 9.75 21 9.49687 21 9.1875V3.5625C21 3.25158 20.7469 3 20.4375 3ZM9.91523 13.2902L4.125 19.0805V14.8125C4.125 14.5031 3.87328 14.25 3.5625 14.25C3.25172 14.25 3 14.5031 3 14.8125V20.4375C3 20.7469 3.25158 21 3.5625 21H9.1875C9.49687 21 9.75 20.7469 9.75 20.4375C9.75 20.1281 9.49687 19.875 9.1875 19.875H4.92059L10.7108 14.0848C10.9305 13.865 10.9305 13.5089 10.7108 13.2895C10.4911 13.0702 10.1332 13.0688 9.91523 13.2902Z"
    fill="currentcolor"
  />
</svg>
`;
