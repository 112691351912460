import { absolute } from './absolute';
import { borderRadius } from './borderRadius';
import { container } from './container';
import { highlight } from './highlight';
import { mention } from './mention';
import { menu } from './menu';
import { unset } from './unset';

export const variants = {
  absolute,
  borderRadius,
  container,
  highlight,
  menu,
  mention,
  unset,
};
