import PropTypes from 'prop-types';
import React from 'react';

import { FlexLayout, Icon, Text } from '~/ui';

function WorkflowFormSectionHeader({
  headerContent,
  isCollapsed,
  onToggleCollapse,
  title,
}) {
  return (
    <FlexLayout
      data-testid={`workflowFormSectionHeader-${title}`}
      alignItems="center"
      justifyContent="space-between"
      p={4}
      bg="gray-200"
      sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', width: '100%' }}
      onClick={onToggleCollapse}
    >
      <FlexLayout alignItems="center" space={2}>
        <Icon
          icon={isCollapsed ? 'chevronRight' : 'chevronDown'}
          color="gray-500"
        />
        <Text
          color="gray-700"
          variant="l-dense-bold"
          sx={{ wordBreak: 'break-word' }}
        >
          {title}
        </Text>
      </FlexLayout>
      {headerContent}
    </FlexLayout>
  );
}

WorkflowFormSectionHeader.propTypes = {
  headerContent: PropTypes.node,
  isCollapsed: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
};

export default WorkflowFormSectionHeader;
