export const SHORTHAND_STYLE_PROPS = {
  bg: 'bg',
  border: 'border',
  borderBottom: 'borderBottom',
  borderLeft: 'borderLeft',
  borderRadius: 'borderRadius',
  borderRight: 'borderRight',
  borderTop: 'borderTop',
  boxShadow: 'boxShadow',
  color: 'color',
  display: 'display',
  flex: 'flex',
  h: 'h',
  m: 'm',
  mb: 'mb',
  ml: 'ml',
  mr: 'mr',
  mt: 'mt',
  mx: 'mx',
  my: 'my',
  maxH: 'maxH',
  maxW: 'maxW',
  minH: 'minH',
  minW: 'minW',
  overflowX: 'overflowX',
  overflowY: 'overflowY',
  p: 'p',
  pb: 'pb',
  pl: 'pl',
  pr: 'pr',
  pt: 'pt',
  px: 'px',
  py: 'py',
  position: 'position',
  w: 'w',
};
