import React, { memo } from 'react';
import { Icon as UinixIcon } from 'uinix-ui';

import { SharedBoxProps, SharedIconProps } from '../../types';
import { Tooltip } from '../Tooltip';

interface Props extends SharedIconProps, SharedBoxProps {}

export const Icon = memo(
  ({ color, label, size = 'm', tooltip, ...rest }: Props) => {
    // label takes priority as a descriptive label for an icon if it is explicitly provided
    // label is SR friendly plain string, tooltip is HTML markup friendly
    const ariaLabel = label || tooltip;

    return (
      <Tooltip tooltip={tooltip} triggerStyle={componentStyles.tooltipTrigger}>
        <UinixIcon
          {...rest}
          aria-label={ariaLabel}
          aria-hidden="true"
          color={color}
          size={`icon.${size}`}
          styles={componentStyles.svg}
          // an `Icon` is never interactive, except when tooltips are specified (for tab-focus discovery of the tooltip)
          tabIndex={tooltip ? 0 : undefined}
        />
      </Tooltip>
    );
  },
);

const componentStyles = {
  svg: {
    '> svg': {
      pointerEvents: 'none',
    },
  },
  tooltipTrigger: {
    display: 'flex',
  },
};
