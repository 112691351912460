import uuid from 'uuid';

import { formatDate } from '~/eds';
import {
  DataFieldOperators,
  EntityType,
  SaveSearchPermissionType,
} from '~/enums';
import {
  FieldType,
  Filter,
  OperatorId,
  operatorLabelsByFieldType,
  operators,
} from '~/evifields';
import {
  FieldEntity,
  fromEntityToFilterNameValue,
  RecentSearches,
  SaveSearchEntity,
  testIsSupportedFilterTypeForTags,
} from '~/redux/api/methods';
import { FlattenFolderTree, FolderTree } from '~/types';
import { CHARACTER_INPUT_LIMIT_SHORT } from '~/ui/enums/input';

// Because Pilot field relation value and eds Filter operator id do not match all the time
// if returns relationValue as is, that means they match, else returns the mapped operator id value

export const relationValueToOperatorId = (relationValue: string) => {
  switch (relationValue) {
    case DataFieldOperators.LessThanOrEqualsTo:
      return operators.less_than_equals.id;
    case DataFieldOperators.Exactly:
      return operators.equals.id;
    case DataFieldOperators.GreaterThanOrEqualsTo:
      return operators.greater_than_equals.id;
    case DataFieldOperators.ContainsText:
      return operators.text_contains_any.id;
    case DataFieldOperators.DoesNotContainText:
      return operators.text_not_contains_any.id;
    case DataFieldOperators.DateBefore:
      return operators.date_before.id;
    case DataFieldOperators.DateEquals:
      return operators.date_on.id;
    case DataFieldOperators.DateAfter:
      return operators.date_after.id;
    case DataFieldOperators.DateBetween:
      return operators.date_between.id;
    case DataFieldOperators.Next:
      return operators.date_in_the_next.id;
    case DataFieldOperators.Last:
      return operators.date_in_the_last.id;
    case DataFieldOperators.DoesNotEqual:
      return operators.not_equals.id;
    case DataFieldOperators.Contains:
      return operators.contains_any.id;
    case DataFieldOperators.DoesNotContain:
      return operators.not_contains_any.id;
    default:
      return relationValue;
  }
};

type UserDepartmentType = EntityType.Department | EntityType.User;
type UserDepartmentEntity = {
  department_ids: number[] | undefined;
  user_ids: number[] | undefined;
};

function getUserDepartmentObject(id: number, type: UserDepartmentType) {
  return {
    id,
    role: null,
    type,
  };
}

export const getUserDepartmentArray = (entry: UserDepartmentEntity) => {
  if (entry) {
    const { department_ids = [], user_ids = [] } = entry;
    const depObjs = department_ids.map((id) =>
      getUserDepartmentObject(id, EntityType.Department),
    );
    const userObjs = user_ids.map((id) =>
      getUserDepartmentObject(id, EntityType.User),
    );
    return [...depObjs, ...userObjs];
  }
  return [];
};

// flatten folder tree to render as enum_set Array Multiple as a short term solution for folder search
export const flattenFolderTree = (
  data: FolderTree[],
  path = '',
  shouldDisable: (folder: FolderTree) => boolean = () => false,
) => {
  return data.reduce((prev: FlattenFolderTree[], cur: FolderTree) => {
    const curPath = `${path}/${cur.name}`;

    prev.push({
      value: String(cur.id),
      display_value: cur.name,
      path: curPath,
      disabled: shouldDisable(cur),
    });

    if (cur.children?.length) {
      prev = prev.concat(
        flattenFolderTree(cur.children, curPath, shouldDisable),
      );
    }
    return prev;
  }, []);
};

export const generateTextStringForFilterTags = (record: RecentSearches) => {
  const tags: string[] = [];
  const supportedEntities = record.query.filter((queryEntity) =>
    testIsSupportedFilterTypeForTags(queryEntity),
  );
  supportedEntities.forEach((rcd) => {
    if (rcd.entity === 'field') {
      const fieldNameRelationValues = fromEntityToFilterNameValue(
        rcd as FieldEntity,
      );
      if (fieldNameRelationValues) {
        let value;
        const { name, operatorId, type } = fieldNameRelationValues;
        const relation =
          operatorLabelsByFieldType[type as FieldType][
            operatorId as OperatorId
          ];
        const valuesTransformed = fieldNameRelationValues.values?.map((v) =>
          v instanceof Date ? formatDate(v) : v,
        );
        const isDatePeriod = valuesTransformed?.find(
          (item) => item && item.unit && item.value,
        );
        if (isDatePeriod) {
          value = `${isDatePeriod.value} ${isDatePeriod.unit}`;
        } else {
          value = valuesTransformed?.join(' - ');
        }
        tags.push(
          name === 'Document Group'
            ? `${name}`
            : `${name} ${relation} ${value}`,
        );
        return tags;
      } else {
        return '';
      }
    } else if (rcd.entity === 'folder') {
      tags.push('Folder');
    } else if (rcd.entity === 'provision') {
      tags.push('Clause Type');
    } else if (rcd.entity === 'section') {
      tags.push('Complex search');
    } else if (rcd.entity === 'text_search') {
      tags.push('Text Search');
    } else {
      tags.push('');
    }
  });
  return tags;
};

export const toFilter = (fieldId: string): Filter => ({
  id: uuid.v4(),
  fieldId,
  operatorId: null,
  values: [],
});

const getIsSharedWithNoUsersSelected = (formData: SaveSearchEntity) => {
  return (
    formData?.search_visibility === SaveSearchPermissionType.Shared &&
    formData?.user_ids?.length === 0 &&
    formData?.department_ids?.length === 0
  );
};

export const shouldSaveSearchActionButtonDisabled = (
  formData: SaveSearchEntity,
) => {
  return (
    getIsSharedWithNoUsersSelected(formData) ||
    !formData?.name ||
    formData?.name.length > CHARACTER_INPUT_LIMIT_SHORT
  );
};

export const getDisabledSaveSearchActionButtonTooltip = (
  formData: SaveSearchEntity,
) => {
  if (getIsSharedWithNoUsersSelected(formData)) {
    return 'Please select at least one user or department';
  } else if (!formData?.name) {
    return 'Please enter a name';
  } else if (formData?.name.length > CHARACTER_INPUT_LIMIT_SHORT) {
    return 'Name exceeds 500 character limit';
  } else {
    return null;
  }
};
