import { saveAs } from 'file-saver';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { exportUsersExcelFile } from '~/components/Admin/UsersPage';
import { trackSegment } from '~/components/SegmentAnalytics';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { DeactivateUserModal, UserFormPanel } from '~/components/SuperAdmin';
import { ResetUserPasswordModal } from '~/components/Users';
import {
  ContentContainer,
  formatDate,
  Layout,
  PageLayout,
  SearchInput,
  useDebouncedState,
  useToast,
} from '~/eds';
import { TableContextType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser, useTableSettings } from '~/hooks';
import { api, coerceRtkqError } from '~/redux';
import { UserAttributes } from '~/redux/api/methods';
import { ParsedType } from '~/redux/api/transformers';
import { generateNav, RoutePathType } from '~/routing';
import { Nullable } from '~/types';

const current = {
  text: 'Users',
  pathname: RoutePathType.AdminUsers,
};

const COLUMNS = [
  'name',
  'email',
  'role',
  'departments',
  'job_title',
  'status',
  'last_login',
];

const STATUSES = {
  Active: 'success',
  Deactivated: 'inactive',
  Pending: 'warning',
};

const Page = () => {
  const [debouncedState, search, setSearch] = useDebouncedState<
    Nullable<string>
  >('');

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [panelVisible, setPanelVisible] = useState(false);
  const [deactivateVisible, setDeactivateVisible] = useState(false);
  const [resetPasswordVisible, setResetPassword] = useState(false);
  const { toast } = useToast();
  const [sendInvitationEmail] = api.endpoints.sendInvitationEmail.useMutation();
  const [
    resendReactivatedUserInvite,
  ] = api.endpoints.resendReactivatedUserInvite.useMutation();
  const isEmailNotificationEnabled = useFlag(
    FlagType.ReactivateUsersEmailNotification,
  );
  const isRolodexDataSourceEnabled = useFlag(FlagType.RolodexAsUserDataSource);
  const [
    reactivateUser,
    reactivateUserResult,
  ] = api.endpoints.reactivateUser.useMutation();
  const {
    isError: isErrorReactivateUser,
    isLoading: isLoadingReactivateUser,
    isSuccess: isSuccessReactivateUser,
  } = reactivateUserResult;
  const [
    cancelUserInvite,
    cancelUserInviteResult,
  ] = api.endpoints.cancelUserInvite.useMutation();
  const {
    isError: isErrorCancelUserInvite,
    isLoading: isLoadingCancelUserInvite,
    isSuccess: isSuccessCancelUserInvite,
  } = cancelUserInviteResult;

  const currentUser = useCurrentUser();
  const [selectedUser, setSelectedUser] = useState<
    Nullable<ParsedType<UserAttributes>>
  >(null);

  const nav = generateNav({
    current,
    from: RoutePathType.AdminConsoleClient,
    params: {
      clientId: currentUser.client,
    },
  });

  const {
    tableSettings = {
      pageSize: 10,
      columnOrder: COLUMNS,
    },
  } = useTableSettings(TableContextType.Users);

  const [
    getUsers,
    { isFetching, data },
  ] = api.endpoints.getUsers.useLazyQuery();
  const results = data?.results ?? [];

  const [pageSize, setPageSize] = useState(tableSettings.pageSize);

  const customAttributesColumns = useMemo(
    () =>
      (data?.columns ?? []).map((column) => ({
        key: column.key,
        title: column.label,
        width: 'm',
        disableSortBy: true,
        mapCellProps: (user: ParsedType<UserAttributes>) => ({
          text: get(user.customAttributeValues, column.key, ''),
        }),
      })),
    [data?.columns],
  );

  const columns = useMemo(
    () => [
      {
        key: 'name',
        title: 'User',
        width: 'l',
        cellType: 'user',
        disableSortBy: true,
        mapCellProps: (user: ParsedType<UserAttributes>) => ({
          user: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
          },
          mode: 'avatar-name',
        }),
      },
      {
        key: 'email',
        title: 'Email',
        disableSortBy: true,
        mapCellProps: (user: ParsedType<UserAttributes>) => ({
          text: user.email,
        }),
      },
      {
        key: 'role',
        title: 'Role',
        disableSortBy: true,
        mapCellProps: (user: ParsedType<UserAttributes>) => ({
          text: user.role,
        }),
      },
      {
        key: 'departments',
        title: 'Department',
        disableSortBy: true,
        mapCellProps: (user: ParsedType<UserAttributes>) => ({
          text: user.departments
            .map((department) => department.departmentName)
            .join(', '),
        }),
      },
      {
        key: 'job_title',
        title: 'Job Title',
        disableSortBy: true,
        mapCellProps: (user: ParsedType<UserAttributes>) => ({
          text: user.jobTitle,
        }),
      },
      {
        key: 'status',
        title: 'Status',
        cellType: 'chips',
        disableSortBy: true,
        mapCellProps: (user: ParsedType<UserAttributes>) => ({
          chips: [{ text: user.status, status: STATUSES[user.status] }],
        }),
      },
      {
        key: 'last_login',
        title: 'Last sign in',
        width: 'm',
        disableSortBy: true,
        cellType: 'datetime',
        mapCellProps: ({ lastLogin }: ParsedType<UserAttributes>): Object => {
          return {
            datetime: lastLogin ? new Date(lastLogin) : lastLogin,
            format: 'full',
          };
        },
      },
      ...customAttributesColumns,
    ],
    [customAttributesColumns],
  );

  const columnGroups = useMemo(() => {
    return [
      {
        name: 'default',
        label: 'Default Attributes',
        columns: [
          'name',
          'email',
          'role',
          'departments',
          'job_title',
          'status',
          'last_login',
        ],
      },
      {
        name: 'custom',
        label: 'Custom Attributes',
        columns: customAttributesColumns.map((column) => column.key),
      },
    ];
  }, [columns, customAttributesColumns]);

  const getRowActions = useCallback(() => {
    return [
      {
        label: 'Deactivate user',
        onClick: async (user: ParsedType<UserAttributes>): Promise<void> => {
          setDeactivateVisible(true);
          setSelectedUser(user);
        },
        icon: '',
        condition: (user: ParsedType<UserAttributes>) =>
          user.status === 'Active',
      },
      {
        label: 'Reactivate user',
        onClick: async (user: ParsedType<UserAttributes>) => {
          trackSegment('submitReactivateUser', {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            user_id: currentUser.id,
            client_id: currentUser.client,
          });
          reactivateUser({
            userId: user.id,
            useRolodex: isRolodexDataSourceEnabled,
          });
          setSelectedUser(user);
        },
        icon: '',
        condition: (user: ParsedType<UserAttributes>) =>
          user.status === 'Deactivated',
      },
      {
        label: 'Reset password',
        onClick: async (user: ParsedType<UserAttributes>) => {
          setResetPassword(true);
          setSelectedUser(user);
        },
        icon: '',
        condition: (user: ParsedType<UserAttributes>) =>
          user.status === 'Active' && !user.ssoUser,
      },
      {
        label: 'Cancel invite',
        onClick: (user: ParsedType<UserAttributes>) => {
          trackSegment('submitCancelUserInvite', {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            user_id: currentUser.id,
            client_id: currentUser.client,
          });
          cancelUserInvite({
            userId: user.id,
            useRolodex: isRolodexDataSourceEnabled,
          });
          setSelectedUser(user);
        },
        icon: '',
        condition: (user: ParsedType<UserAttributes>) =>
          user.status === 'Pending',
      },
      {
        label: 'Send invite',
        onClick: async (user: ParsedType<UserAttributes>) => {
          trackSegment('submitCancelUserInvite', {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            user_id: currentUser.id,
            client_id: currentUser.client,
          });
          setIsLoading(true);
          try {
            const resendRequest = isEmailNotificationEnabled
              ? resendReactivatedUserInvite
              : sendInvitationEmail;
            await resendRequest({
              userId: user.id,
              useRolodex: isRolodexDataSourceEnabled,
            }).unwrap();
            toast({
              title: 'Invite sent',
              message: `User will receive an email at “${user.email}”`,
              status: 'success',
            });
          } catch (e: any) {
            const resp = e?.response || {};
            const msg = resp.data?.errors?.[0];
            toast({
              message:
                msg?.title ||
                'An error occurred while resending the user invite.',
              status: 'danger',
            });
          } finally {
            setIsLoading(false);
          }
        },
        icon: '',
        condition: (user: ParsedType<UserAttributes>) =>
          user.status === 'Pending',
      },
    ];
  }, [currentUser]);

  const onPaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      if (pageIndex !== page) {
        setPage(pageIndex);
      }
    },
    [page],
  );
  const onPageSizeChange = useCallback((pageSize: number) => {
    setPage(1);
    setPageSize(pageSize);
  }, []);

  const handleHidePanel = () => {
    setPanelVisible(false);
    setSelectedUser(null);
  };

  const handleHideDeactivate = () => {
    setDeactivateVisible(false);
    setSelectedUser(null);
  };

  const handleHideResetPassword = () => {
    setResetPassword(false);
    setSelectedUser(null);
  };

  const handleSearch = (search: Nullable<string>) => {
    setSearch(search);
  };

  const handleExportUsers = async () => {
    const filename =
      'evisort-users-' + formatDate(new Date(), 'iso_datetime') + '.xlsx';
    setIsLoading(true);
    try {
      const response = await exportUsersExcelFile({
        clientId: currentUser.client,
        onlyHeaders: false,
      });
      toast({
        message: 'File downloaded successfully.',
        status: 'success',
      });
      // Adding unkown due lacking types in the functions library
      saveAs((response as unknown) as Blob, filename);
    } catch (e) {
      toast({
        message: 'Error exporting file',
        status: 'danger',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers({
      searchValue: search ?? undefined,
      page,
      pageSize,
      useRolodex: isRolodexDataSourceEnabled,
    });
  }, [page, pageSize]);

  useEffect(() => {
    if (isSuccessReactivateUser) {
      if (!isEmailNotificationEnabled) {
        sendInvitationEmail({
          userId: selectedUser!.id,
          useRolodex: isRolodexDataSourceEnabled,
        });
      }
      toast({
        message: `“${selectedUser?.firstName} ${selectedUser?.lastName}”  reactivated.`,
        status: 'success',
      });
      setSelectedUser(null);
    }
  }, [isSuccessReactivateUser]);

  useEffect(() => {
    if (isSuccessCancelUserInvite) {
      toast({
        title: 'Invite cancelled',
        message: "The user's activation link is no longer valid",
        status: 'success',
      });
      setSelectedUser(null);
    }
  }, [isSuccessCancelUserInvite]);

  useEffect(() => {
    if (isErrorReactivateUser) {
      const resp = coerceRtkqError(reactivateUserResult.error)?.response || {};
      const msg = resp.data?.errors?.[0];
      toast({
        message: msg?.title || 'An error occurred while reactivating the user.',
        status: 'danger',
      });
      setSelectedUser(null);
    }
  }, [isErrorReactivateUser]);

  useEffect(() => {
    if (isErrorCancelUserInvite) {
      const resp =
        coerceRtkqError(cancelUserInviteResult.error)?.response || {};
      const msg = resp.data?.errors?.[0];
      toast({
        message:
          msg?.title || 'An error occurred while cancelling the user invite.',
        status: 'danger',
      });
      setSelectedUser(null);
    }
  }, [isErrorCancelUserInvite]);

  useEffect(() => {
    setPage(1);
    if (debouncedState && debouncedState.length > 2) {
      getUsers({
        searchValue: debouncedState,
        page: 1,
        pageSize,
        useRolodex: isRolodexDataSourceEnabled,
      });
      trackSegment('searchTextUsers', {
        search,
        user_id: currentUser.id,
        client_id: currentUser.client,
      });
    } else if (!debouncedState) {
      getUsers({
        searchValue: undefined,
        page: 1,
        pageSize,
        useRolodex: isRolodexDataSourceEnabled,
      });
    }
  }, [debouncedState]);

  return (
    <PageLayout
      nav={nav}
      title="Users"
      description="Create users or manage user information, roles, and permissions."
      actions={[
        {
          level: 'secondary',
          text: 'Export Users',
          isLoading: isLoading,
          onClick: () => {
            trackSegment('exportUsers', {
              user_id: currentUser.id,
              client_id: currentUser.client,
            });
            handleExportUsers();
          },
        },
        {
          id: OnboardingIdType.AddNewUser,
          level: 'primary',
          text: 'New User',
          onClick: () => {
            setPanelVisible(true);
            trackSegment('openCreateUserPanel', {
              user_id: currentUser.id,
              client_id: currentUser.client,
            });
          },
        },
      ]}
    >
      <Layout justify="space-between">
        <Layout flex="1" mr={4}>
          <SearchInput
            width="100%"
            name="search"
            value={search}
            onChange={handleSearch}
            placeholder="Search users"
          />
        </Layout>
      </Layout>
      <Layout direction="column" spacing={8} mt={6}>
        <PersistedTable
          name={TableContextType.Users}
          data={results}
          options={{
            enableSelectRows: false,
            enablePageSizeSelect: true,
          }}
          getRowActions={getRowActions}
          state={{
            pageIndex: page,
            pageSize,
            columnOrder: tableSettings.columnOrder,
          }}
          isLoading={
            isFetching ||
            isLoadingReactivateUser ||
            isLoadingCancelUserInvite ||
            isLoading
          }
          columns={columns}
          columnGroups={columnGroups}
          context={TableContextType.Users}
          totalCount={data?.total}
          onPaginate={onPaginate}
          onPageSizeChange={onPageSizeChange}
        />
        {data?.results?.length === 0 && search && (
          <Layout align="center" justify="center">
            <ContentContainer
              placeholderContent={{
                title: 'No Users Matched this Search',
                description:
                  'Try another search, or use the filters next to the search bar to find a user by role, department, and more.',
              }}
            />
          </Layout>
        )}
      </Layout>
      <UserFormPanel visible={panelVisible} onHide={handleHidePanel} />
      <DeactivateUserModal
        isVisible={deactivateVisible}
        onHide={handleHideDeactivate}
        user={selectedUser}
      />
      <ResetUserPasswordModal
        visible={resetPasswordVisible}
        onHide={handleHideResetPassword}
        user={selectedUser}
      />
    </PageLayout>
  );
};

export default Page;
