export default `
<svg
  aria-hidden="true"
  aria-label="plus"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.5 11.9688C18.5 12.4062 18.1562 12.7188 17.75 12.7188H12.75V17.7188C12.75 18.1562 12.4062 18.5 12 18.5C11.5625 18.5 11.25 18.1562 11.25 17.7188V12.7188H6.25C5.8125 12.7188 5.5 12.4062 5.5 12C5.5 11.5625 5.8125 11.2188 6.25 11.2188H11.25V6.21875C11.25 5.8125 11.5625 5.5 12 5.5C12.4062 5.5 12.75 5.8125 12.75 6.21875V11.2188H17.75C18.1562 11.2188 18.5 11.5625 18.5 11.9688Z"
    fill="currentcolor"
  />
</svg>
`;
