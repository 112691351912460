import { isNull } from 'lodash';

import {
  ClauseValue,
  DatePeriod,
  FieldType,
  Filter,
  Nullable,
  Operator,
} from '../types';
import { getFilterValuesLength } from './getFilterValuesLength';

/**
 * TODO: This util will eventually be deprecated when evifields specify the `field.validate` API implemented explicitly per field type.
 *
 * A filter is active under the following conditions (order matters)
 * - if its operator cardinality is infinite and contains at least one value.
 * - if its operator cardinality does not match up to its value length.
 * - if it passes specific checks based on its operator.
 * - true otherwise
 */
export const testIsActiveFilter = (
  filter: Filter,
  operator: Operator,
  type?: FieldType,
): boolean => {
  const { values } = filter;
  const { id: operatorId, cardinality } = operator;
  const valuesLength = getFilterValuesLength(filter);
  const isCardinalityInfinity =
    cardinality === Number.POSITIVE_INFINITY ||
    cardinality?.toString() === 'infinity';

  // field type checks
  switch (type) {
    case 'clause':
      const clauseValue = values[0] as Nullable<ClauseValue>;
      if (clauseValue) {
        const { text_search: textSearch } = clauseValue;
        if (textSearch.length > 0) {
          const { contains, scope, text } = textSearch[0];
          return Boolean(contains && scope && text);
        }
      }
      break; // continue
    default:
      break; // continue
  }

  // cardinality checks
  if (isCardinalityInfinity && valuesLength > 0) {
    return true;
  } else if (cardinality !== valuesLength) {
    return false;
  }

  // operator checks
  switch (operatorId) {
    case 'date_in_the_last':
    case 'date_in_the_next': {
      const datePeriod = values[0] as Nullable<DatePeriod>;
      return !(
        isNull(datePeriod) ||
        isNull(datePeriod.value) ||
        isNull(datePeriod.unit)
      );
    }
    case 'date_between':
    case 'between': {
      return values.filter(isNull).length === 0;
    }
    default: {
      return true;
    }
  }
};
