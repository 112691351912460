import { CardSelect } from '~/eds';

import { OptimizeResult, OptimizeResultTag } from '../../../../../types';
import { OPTIMIZE_OPTION_WIDTH } from '../../../constants';
import { mapTagToLabel } from '../../../utils';
import { BoostResultsOptionContent } from './BoostResultsOptionContent';

interface Props {
  results: OptimizeResult[];
  selectedOption: OptimizeResultTag;
  onSelectOption: (tag: OptimizeResultTag) => void;
}

export const BoostResultsOptions = ({
  results,
  selectedOption,
  onSelectOption,
}: Props) => {
  const options = results.map((result) => {
    const { tag, isRecommended } = result;
    const option = {
      ariaLabel: tag,
      content: <BoostResultsOptionContent result={result} />,
      label: mapTagToLabel(tag),
      labelVariant: 'subtitle' as const,
      value: tag,
      width: OPTIMIZE_OPTION_WIDTH,
    };

    if (isRecommended) {
      return {
        ...option,
        banner: {
          label: 'Recommended',
          status: 'active' as const,
          text: 'Recommended',
        },
      };
    }
    return option;
  });

  return (
    <CardSelect
      spacing="xl"
      direction="row"
      isMulti={false}
      name="boost-option-select"
      options={options}
      value={selectedOption}
      onChange={(result) => onSelectOption(result!)}
    />
  );
};
