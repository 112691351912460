import React from 'react';
import { connect } from 'react-redux';

import {
  workflowSetDefaultTicketName,
  workflowSetTicketNameConventions,
} from '~/actions/workflow';
import {
  TICKET_NAMING_CONVENTION_ALLOWED_IMPLICIT_TYPES,
  TICKET_NAMING_CONVENTION_ALLOWED_TYPES,
} from '~/constants/ticket';
import { Icon, Layout, Tooltip } from '~/eds';
import { Checkbox, FlexLayout, Text, withInputError } from '~/ui';
import { testValidFieldsInTicketNamingConvention } from '~/utils';
import { pathObjectToString } from '~/utils/workflow';

import Section from '../Section';
import TicketNameInput from './TicketNameInput';

const SYSTEM_DEFAULT_NAME = 'Workflow Name_Counterparties_Ticket Created Date';

function TicketNamePreview({
  useDefaultName,
  ticketNamingConvention,
  fields,
  id,
  error,
}) {
  const previewedName = useDefaultName
    ? SYSTEM_DEFAULT_NAME
    : pathObjectToString(ticketNamingConvention, fields);
  return (
    <FlexLayout flexDirection="column" space={2}>
      <FlexLayout
        id={id}
        alignItems="center"
        px={4}
        py={2}
        space={2}
        sx={{
          bg: 'black-alpha-04',
          border: error ? 'border' : 'none',
          borderColor: error ? 'red-400' : 'none',
          borderRadius: 'm',
        }}
      >
        <Icon icon="review" color="status.info" />
        <Text color="gray-600" variant="xs-dense-medium">
          {previewedName}
        </Text>
      </FlexLayout>
    </FlexLayout>
  );
}

function validateTicketNamePreview({
  ticketNamingConvention,
  fields,
  useDefaultName,
}) {
  const ticketNameString = pathObjectToString(ticketNamingConvention, fields);
  return ticketNameString || useDefaultName ? null : 'Invalid ticket name.';
}

function formatNamingOutput(string) {
  return string.replace(/\//g, '');
}

function TicketName({
  //connected
  ticketNamingConvention,
  keepSystemDefaultTicketName,
  fields,
  workflowSetDefaultTicketName,
  workflowSetTicketNameConventions,
}) {
  const hasErrors = !testValidFieldsInTicketNamingConvention(
    ticketNamingConvention,
    fields,
  );

  const TicketNamePreviewWithError = withInputError(
    TicketNamePreview,
    validateTicketNamePreview,
  );

  const filteredFields = Object.entries(fields)
    .filter(
      ([key, value]) =>
        (TICKET_NAMING_CONVENTION_ALLOWED_TYPES.includes(value.type) &&
          key !== 'Date Signed' &&
          key !== 'Date Completed') ||
        TICKET_NAMING_CONVENTION_ALLOWED_IMPLICIT_TYPES.includes(key),
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  return (
    <Section title="Ticket Naming Conventions" icon="review">
      <TicketNameInput
        disabled={keepSystemDefaultTicketName}
        ticketNamingConvention={ticketNamingConvention}
        fields={filteredFields}
        id="workflow--settings-ticket-naming-format-entry"
        label="Ticket naming format"
        placeholder="Specify ticket name"
        formatOutput={formatNamingOutput}
        onChange={(ticketNamingConvention) =>
          workflowSetTicketNameConventions(ticketNamingConvention)
        }
        errorMessage={hasErrors ? 'Invalid fields in ticket name.' : ''}
      />
      <TicketNamePreviewWithError
        useDefaultName={keepSystemDefaultTicketName}
        ticketNamingConvention={ticketNamingConvention}
        fields={fields}
        id="workflow--settings-ticket-naming-convention-preview"
      />
      <Layout flexDirection="column" spacing={1}>
        <Checkbox
          label="Keep system default name"
          value={keepSystemDefaultTicketName}
          onChange={workflowSetDefaultTicketName}
        />
        <Tooltip tooltip={`"${SYSTEM_DEFAULT_NAME}"`}>
          <Icon icon="info" color="gray-600" size="xs" />
        </Tooltip>
      </Layout>
    </Section>
  );
}

function mapStateToProps({ workflow }) {
  // only include fields that are assigned and have valid types defined.
  return {
    ticketNamingConvention: workflow.settings.ticketNamingConvention,
    keepSystemDefaultTicketName: workflow.settings.keepSystemDefaultTicketName,
    fields: workflow.fields,
  };
}

export default connect(mapStateToProps, {
  workflowSetDefaultTicketName,
  workflowSetTicketNameConventions,
})(TicketName);
