import { createReducer } from '@reduxjs/toolkit';

import * as actions from '~/actions';
import {
  fromExpression,
  getUniqueFieldsInExpressionTree,
} from '~/components/Shared/ConditionExpressionBuilder/utils.js';
import { WorkflowFieldType } from '~/enums';
import { isConditionFieldValid } from '~/reducers/intakeForm/utils.js';
import { getAssignedFields } from '~/reducers/workflow.js';
import { getAnsweredFieldCount } from '~/utils/intakeForm';
import { isValueStrictlyEmpty, validateEmail } from '~/utils/strings';
import { normalizeIntakeForm } from '~/utils/workflow';

import { applyConditions as _applyConditions } from './conditions.js';

const initialState = {};

export const applyConditions = _applyConditions;

export const getSections = (state) => {
  return Object.values(state.sections);
};

export const getSectionIds = (state) => {
  return Object.keys(state.sections);
};

export const getSectionById = (state, id) => {
  return state.sections[id];
};

export const getQuestions = (state) => {
  return Object.values(state.questions);
};

export const getQuestionById = (state, id) => {
  return state.questions[id];
};

export const getQuestionByFieldId = (state, id) => {
  return state.questions[state.fields[id].question];
};

export const getFields = (state) => {
  return Object.values(state.fields);
};

export const getFieldById = (state, id) => {
  return state.fields[id];
};

export const getFieldByQuestionId = (state, id) => {
  return state.fields[state.questions[id].field];
};

export const getFieldsBySectionId = (state, id) => {
  const questionIds = getSectionById(state, id).questions;
  const fieldIds = questionIds.map(
    (questionId) => getQuestionById(state, questionId).field,
  );

  return fieldIds.map((fieldId) => getFieldById(state, fieldId));
};

export const validateForm = (state) => {
  const questions = getQuestions(state);
  questions.forEach((question) => validateQuestion(state, question));
};

// validate field for an associated question and update errorMessage if appropriate
export const validateQuestion = (state, question) => {
  const field = getFieldByQuestionId(state, question.id);

  field.errorMessage = null;
  field.warningMessage = null;

  if (question.isRequired) {
    let requiredFields = field.value;
    if (field.type === WorkflowFieldType.Address) {
      // STREET_2 field is optional
      const { street_2: _street_2, ...rest } = field.value;
      requiredFields = rest;
    }

    // the min number of files attached is only mandatory when the question is required
    if (
      field.type === WorkflowFieldType.File &&
      field.value?.value?.length < field.customSettings.min_file_count
    ) {
      field.errorMessage = `Minimum attachment(s): ${field.customSettings.min_file_count}`;
    }

    const emptyValues = Object.values(requiredFields).filter((value) =>
      isValueStrictlyEmpty(value),
    ).length;

    if (emptyValues) {
      field.errorMessage = 'This is a required field.';
    }
  } else if (field.isSignerField) {
    const missingValue = Object.values(field.value).filter((value) =>
      isValueStrictlyEmpty(value),
    ).length;

    if (missingValue) {
      field.warningMessage = 'Missing signer information';
    }
  }

  if (
    field.type === WorkflowFieldType.MonetaryValue &&
    !field.value.currency &&
    field.value.amount
  ) {
    field.errorMessage = 'Currency selection is required';
  }

  if (
    field.type === WorkflowFieldType.Email &&
    field.value &&
    field.value.value &&
    !validateEmail(field.value.value)
  ) {
    field.errorMessage = 'Invalid Email';
  }
};

export const evaluateFieldWithCondition = (state) => {
  const appliedConditions = applyConditions(
    state.conditions,
    state.fields,
    state.implicitFields,
  );
  Object.keys(appliedConditions).forEach((fieldId) => {
    state.fields[fieldId].isEnabled = appliedConditions[fieldId];
  });
};

export function hasConditionErrors(state) {
  const { conditions } = state;
  let invalidConditionIds = new Set();

  const usedConditionFieldIdMap = Object.values(conditions).reduce(
    (acc, condition) => {
      const { id } = condition;
      const fieldIds = getUniqueFieldsInExpressionTree(
        fromExpression(condition.expression),
      );
      fieldIds.forEach((fieldId) => {
        if (!acc[id]) {
          acc[id] = [];
        }
        acc[id].push(fieldId);
      });
      return acc;
    },
    new Set(),
  );

  const assignedFields = getAssignedFields(state, true);

  Object.entries(usedConditionFieldIdMap).forEach(
    ([conditionId, usedConditionFieldIds]) => {
      usedConditionFieldIds.forEach((usedConditionFieldId) => {
        const assignedField = assignedFields[usedConditionFieldId];
        /** evaluate if a condition is valid for a given field,
         * in this case CONTAINS_ALL is not supported by single select **/
        const condition = conditions[conditionId];
        if (!isConditionFieldValid(condition, assignedField)) {
          invalidConditionIds.add(conditionId);
        }
      });
    },
  );

  return invalidConditionIds.size > 0;
}

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.intakeFormSet, (state, action) => {
    state = normalizeIntakeForm(action.payload.res);
    if (
      hasConditionErrors({ ...state, form: { sections: getSections(state) } })
    ) {
      state.hasErrors = true;
    } else {
      state.hasErrors = false;
      evaluateFieldWithCondition(state);
    }
    validateForm(state);

    // form can either be empty or filled.  if it is empty, we treat it as validated.
    const isFormEmpty =
      getAnsweredFieldCount(Object.values(state.fields)) === 0;
    state.isValidated = !isFormEmpty;
    return state;
  });
  builder.addCase(actions.intakeFormUpdateImplicitField, (state, action) => {
    const { id, value } = action.payload;
    const implicitField = state.implicitFields[id];
    implicitField.value = value;
    evaluateFieldWithCondition(state);
  });
  builder.addCase(actions.intakeFormUpdateFieldOptions, (state, action) => {
    const { id, options } = action.payload;
    const field = getFieldById(state, id);
    field.customSettings.options = options;
  });
  builder.addCase(actions.intakeFormUpdateFieldValue, (state, action) => {
    const { id, value } = action.payload;
    const field = getFieldById(state, id);
    field.value = value;
    field.modified = true;
    field.isTouched = true;
    const question = getQuestionByFieldId(state, id);
    evaluateFieldWithCondition(state);
    validateQuestion(state, question);
  });
  builder.addCase(actions.intakeFormValidate, (state) => {
    if (state.fields) {
      Object.values(state.fields).forEach((field) => {
        field.isTouched = true;
      });
    }
    state.isValidated = true;
  });
});
