import React from 'react';
import { connect } from 'react-redux';

import { contractFieldDefinitions } from '~/constants/workflow';
import { FlexLayout, Icon, Text } from '~/ui';
import { getFieldDefinitionStatus } from '~/utils/workflow';

import RadioItem from './RadioItem';

function RadioContracts({
  selectedFieldDefinition,
  onClick,
  onCustomFieldClick,
  // connected
  fieldLinks,
}) {
  return (
    <>
      {contractFieldDefinitions.map((fieldDefinition) => {
        const { isSelected } = getFieldDefinitionStatus({
          fieldDefinition,
          fieldLinks,
          selectedFieldDefinition,
        });
        return (
          <RadioItem
            key={fieldDefinition.key}
            option={{
              label: fieldDefinition.label,
              value: fieldDefinition.key,
            }}
            selected={isSelected}
            onClick={() => onClick(fieldDefinition)}
          />
        );
      })}
      <FlexLayout
        alignItems="center"
        px={6}
        py={3}
        space={4}
        sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'gray-200' } }}
        onClick={onCustomFieldClick}
      >
        <Icon color="blue-500" icon="plus" size="xs" />
        <Text color="blue-500" variant="xs-dense-bold">
          Custom Field
        </Text>
      </FlexLayout>
    </>
  );
}

const mapStateToProps = ({ workflow }) => ({
  fieldLinks: workflow.fieldLinks,
});

export default connect(mapStateToProps)(RadioContracts);
