import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, api, selectors } from '~/redux';
import { Box, Iframe, LoadingContainer } from '~/ui';

import DashboardErrorMessage from './DashboardErrorMessage';
import {
  DashboardStatusType,
  useIsDashboardReady,
} from './useIsDashboardReady';

const DASHBOARD_MAX_HEIGHT = 2700;

const DashboardIframe = ({ type }) => {
  const dispatch = useDispatch();
  const dashboard = useSelector(selectors.selectDashboardByType(type));

  const [status, setStatus, iframeRef] = useIsDashboardReady();
  const {
    data: dashboards = [],
    isError: listDashboardsError,
  } = api.endpoints.getDashboards.useQuery();
  const dashboardId = dashboards.find(({ type: t }) => t === type)?.id;
  const { isSuccess, isError, data } = api.endpoints.getDashboard.useQuery(
    { dashboardId },
    { skip: !dashboardId },
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(actions.setDashboard(data));
    } else if (listDashboardsError || isError) {
      setStatus(DashboardStatusType.ERROR);
    }
  }, [data, dispatch, isError, isSuccess, listDashboardsError]);

  const isLoading = status === DashboardStatusType.Loading;

  let content = null;
  if (status === DashboardStatusType.Error) {
    content = <DashboardErrorMessage />;
  } else if (dashboard) {
    const { embedSrc, name } = dashboard;
    // we need to still mount (but hide) the iframe to trigger iframe events when loading
    content = (
      <Box mt={-6} sx={{ display: isLoading ? 'none' : 'block' }}>
        <Iframe
          height={DASHBOARD_MAX_HEIGHT}
          ref={iframeRef}
          src={embedSrc}
          title={name}
        />
      </Box>
    );
  }

  return (
    <>
      <LoadingContainer isLoading={isLoading} />
      {content}
    </>
  );
};

export default DashboardIframe;
