import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { UI_BUTTON_GROUP_TESTID } from '~/constants/testids';
import { Button, Divider, FlexLayout, Text } from '~/ui';
import { capitalize } from '~/utils/strings';

const ButtonGroup = ({
  className,
  isMulti,
  options,
  title,
  value: selectedValue,
  onChange,
}) => {
  const handleChange = (updatedOptionValue) => {
    let updatedValue = updatedOptionValue;
    if (isMulti) {
      updatedValue = selectedValue.includes(updatedOptionValue)
        ? selectedValue.filter((item) => updatedOptionValue !== item)
        : [...selectedValue, updatedValue];
    }
    onChange(updatedValue);
  };

  const renderOptions = (menuOptions) => {
    return menuOptions.map((item, index) => {
      const { disabled, icon, label, value: optionValue, title } = item;
      const isLast = index === menuOptions.length - 1;
      const isSelected = isMulti
        ? selectedValue.includes(optionValue)
        : optionValue === selectedValue;

      return (
        <Fragment key={`button_group_${optionValue}`}>
          <Button
            aria-label={label}
            aria-pressed={isSelected}
            disabled={disabled}
            iconLeft={icon}
            key={optionValue}
            text={capitalize(label) || ''}
            title={title}
            px={5}
            py={2}
            sx={{
              backgroundColor: isSelected ? 'gray-200' : 'white',
              border: 'none',
              color: isSelected ? 'gray-900' : 'gray-600',
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              opacity: isSelected ? 1 : 0.7,
              '&:disabled': {
                backgroundColor: 'white',
                opacity: 0.5,
              },
            }}
            variant="secondary"
            onClick={() => handleChange(optionValue)}
          />

          {!isLast && <Divider orientation="vertical" />}
        </Fragment>
      );
    });
  };

  return (
    <FlexLayout
      className={classnames(UI_BUTTON_GROUP_TESTID, className)}
      inline
      alignItems="center"
      sx={{
        border: 'border',
        borderRadius: 'm',
        height: 'input-height',
        overflow: 'hidden',
      }}
    >
      {title && (
        <>
          <FlexLayout space={3} px={3}>
            <Text color="gray-700" variant="xs-dense-bold" my={2}>
              {title}
            </Text>
          </FlexLayout>
          <Divider orientation="vertical" />
        </>
      )}
      {renderOptions(options)}
    </FlexLayout>
  );
};

const optionType = PropTypes.shape({
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
});

ButtonGroup.propTypes = {
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(optionType).isRequired,
  title: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default ButtonGroup;
