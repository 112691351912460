import React, { useMemo } from 'react';

import { Image, Layout, Link, Text } from '~/eds';
import { HttpStatusCodeType } from '~/enums';
import { Chart } from '~/types';

interface EmptyChartProps {
  errorCode: number | undefined;
  chart: Chart;
  isDataOverload?: boolean;
  isError: boolean;
  width: number;
}

export const EmptyChart = ({
  errorCode,
  chart,
  isDataOverload,
  isError,
  width,
}: EmptyChartProps) => {
  const getImage = useMemo(() => {
    if (isError) {
      return <Image alt="Error Loading Chart" image="chart-error" />;
    }

    if (isDataOverload) {
      return <Image alt="Error Loading Chart" image="chart-too-much-data" />;
    }

    return <Image alt="Empty Chart" image="chart-empty" />;
  }, [isError, isDataOverload]);

  const getContent = useMemo(() => {
    if (isError) {
      if (errorCode === HttpStatusCodeType.TooManyRequests) {
        return (
          <>
            <Text mb={1} textAlign="center">
              There was an error while loading this chart.
            </Text>
            <Text textAlign="center">
              Please contact Evisort Support at{' '}
              <Link href="mailto:support@evisort.com">support@evisort.com</Link>
            </Text>
          </>
        );
      } else if (errorCode === HttpStatusCodeType.UnprocessableContent) {
        return (
          <>
            <Text mb={1} textAlign="center">
              There’s no <Text variant="body-bold">{chart.label}</Text> data
              available.
            </Text>
            <Text textAlign="center">Please select a different chart.</Text>
          </>
        );
      }
      return (
        <>
          <Text mb={1} textAlign="center">
            We can’t load this chart right now.
          </Text>
          <Text textAlign="center">
            Please refresh to reload this dashboard and try to update.
          </Text>
        </>
      );
    } else if (isDataOverload) {
      return (
        <>
          <Text mb={1} textAlign="center">
            There’s too much <Text variant="body-bold">{chart.label}</Text> data
            to display.
          </Text>
          <Text textAlign="center">
            Please select a smaller date range or a different grouping.
          </Text>
        </>
      );
    }
    return (
      <>
        <Text mb={1} textAlign="center">
          There's no <Text variant="body-bold">{chart.label}</Text> data that
          matches your search.
        </Text>
        <Text textAlign="center">
          You can try a different search or filters.
        </Text>
      </>
    );
  }, [errorCode, isError, isDataOverload]);

  return (
    <Layout
      w={width}
      h="300px"
      align="center"
      direction="column"
      p={4}
      justify="center"
    >
      {getImage}
      <Layout direction="column" mt={2} p={10}>
        {getContent}
      </Layout>
    </Layout>
  );
};
