import { Pie, PieTooltipProps } from '@nivo/pie';
import { truncate } from 'lodash';
import React from 'react';

import { ChartProps } from '~/types';

import { ChartTooltip } from './ChartTooltip';
import {
  BARCHART_COLOR,
  COLOR_PALETTE,
  UNACTIVE_CHART_COLOR,
} from './constants';
import defaultTheme from './theme';

const CustomTooltip = ({
  datum,
}: PieTooltipProps<{ id: string; value: number; total: number }>) => {
  const percentage = ((datum.data.value / datum.data.total) * 100).toFixed(2);
  const labelValue = `${datum.data.id}: ${datum.data.value} (${percentage}%)`;
  return <ChartTooltip tooltipContent={labelValue} />;
};

export const DonutChart = ({
  buckets,
  width,
  selectedBucket,
  onBucketSelected,
}: ChartProps) => {
  const total = buckets.reduce((a, b) => {
    return a + b.value;
  }, 0);

  const nivoData = buckets.map(({ label, value, bucketValue }) => ({
    id: bucketValue ?? label,
    label: truncate(label, { length: 20 }),
    value,
    total,
  }));

  return (
    <Pie
      width={width}
      height={300}
      fit={false}
      animate={false}
      data={nivoData}
      margin={{ top: 40, right: 0, bottom: 40, left: 160 }}
      innerRadius={0.5}
      padAngle={0.7}
      activeOuterRadiusOffset={0}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      tooltip={CustomTooltip}
      onClick={(datum) => {
        if (datum.data.id === selectedBucket) {
          onBucketSelected?.(null);
        } else {
          onBucketSelected?.(`${datum.data.id}`);
        }
      }}
      colors={
        !selectedBucket
          ? COLOR_PALETTE
          : (bar) => {
              if (bar.data.id === selectedBucket) return BARCHART_COLOR;

              return UNACTIVE_CHART_COLOR;
            }
      }
      theme={defaultTheme}
      enableArcLinkLabels={false}
      legends={[
        {
          anchor: 'top',
          direction: 'column',
          justify: false,
          translateX: -200,
          translateY: 0,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 10,
          symbolShape: 'square',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};
