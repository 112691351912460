import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { forEach } from 'lodash';

import { PilotId, Uuid } from '~/types';

interface Item {
  id: number;
  name: string;
  position: number;
}

export type Context = {
  currentIndex: number;
  getItems: (index: number, folderId?: number) => Promise<any>;
  getPosition?: (
    handler_id: number,
    folderId?: number,
  ) => Promise<PositionResponse>;
  isInitialized: boolean;
  list: Array<any>;
  name: string;
  total: number;
  fromLocation?: Location;
  fetching?: boolean;
  folderId?: number;
  onClick?: () => unknown;
};

export type CurrentDocumentInfo = {
  id: number;
  versionId: PilotId;
  handlerId: PilotId;
  name: string;
  type: string;
  folder: PilotId;
  isLatestVersion?: boolean;
  ticketVersionId?: Uuid;
  isEdited?: boolean;
};

interface InitOptions {
  api: (position?: number, folderId?: number) => Promise<ApiResponse>;
  getPosition?: (
    handler_id: number,
    folderId?: number,
  ) => Promise<PositionResponse>;
  name: string;
  parseResponse: (resp: ApiResponse) => Array<Item>;
  fromLocation?: Location;
  folderId?: number;
  onClick?: () => unknown;
}

export type DocumentNavigationSlice = {
  context: Context;
  currentDocument?: CurrentDocumentInfo;
};

interface HandlerAtPosition {
  position: number;
  id: number;
  name: string;
  file_type: string;
}

export interface ApiResponse {
  previous: HandlerAtPosition;
  current: HandlerAtPosition;
  next: HandlerAtPosition;
  total: number;
}
interface PositionResponse {
  position: number;
  total: number;
}

const setIndexByIdReducer = (
  state: DocumentNavigationSlice,
  action: PayloadAction<{ value: number | string }>,
) => {
  const id = Number(action.payload.value);
  if (isNaN(id)) return;

  const index = state.context.list.findIndex((item) => item?.id === id);

  if (index < 0) return;

  state.context.currentIndex = index;
};

const updateContextReducer = (
  state: DocumentNavigationSlice,
  action: PayloadAction<Partial<Context>>,
) => {
  state.context = {
    ...state.context,
    ...action.payload,
  };
};

const resetNavigationReducer = (state: DocumentNavigationSlice) => {
  state.context = {
    ...initialState.context,
  };
};

const setCurrentDocumentReducer = (
  state: DocumentNavigationSlice,
  action: PayloadAction<any>,
) => {
  state.currentDocument = action.payload;
};

const fetchMore = async (payload: { context: Context; index: number }) => {
  const { context, index } = payload;

  let { list } = context;
  const items = await context.getItems(index, context.folderId);
  const listWillUpdate = [...list];
  forEach(items, (value) => {
    if (value && value.position >= 0) {
      listWillUpdate[value.position] = value;
    }
  });
  list = listWillUpdate;
  return {
    list,
  };
};

const initContextFulfilledReducer = (
  state: DocumentNavigationSlice,
  action: PayloadAction<Context>,
) => {
  state.context = action.payload;
};

const initContextRejectedReducer = (state: DocumentNavigationSlice) => {
  //TODO: handle state on rejected state, for now just reseting navigator to initial state
  // I.E state.error = true; state.errorMessage: action.error.message
  state.context = {
    ...initialState.context,
  };
};

const initFactory = ({
  api,
  name,
  parseResponse,
  fromLocation,
  getPosition,
  folderId,
  onClick,
}: InitOptions) => {
  return async () => {
    const list: Array<Item> = [];
    const currentIndex = -1;
    const total = 0;

    const getItems = async (position: number, folder?: number) => {
      const resp = await api(position, folder);
      return parseResponse(resp);
    };

    return {
      currentIndex,
      onClick,
      getItems,
      getPosition,
      isInitialized: true,
      list,
      name,
      total,
      fromLocation,
      folderId,
    };
  };
};

export const sliceName = 'documentsNavigation';

export const selectItem = (context: Context, offset = 0) => {
  return context.list[context.currentIndex + offset];
};

export const initContext = createAsyncThunk(
  `${sliceName}/initContext`,
  (initOptions: InitOptions) => {
    const initFn = initFactory(initOptions);

    return initFn();
  },
);

export const setCurrentDocumentAction = createAsyncThunk(
  `${sliceName}/setCurrentDocumentAction`,
  async (
    payload: { id: number; name: string; file_type: string },
    { dispatch },
  ) => {
    dispatch(setCurrentDocument(payload));
  },
);

export const fillCurrentIndexList = createAsyncThunk(
  `${sliceName}/getItem`,
  async (
    payload: {
      context: Context;
      index: number;
      callback?: (list: Array<Item>) => void;
    },
    { dispatch },
  ) => {
    const { callback } = payload;
    const updatedListAndNextPage = await fetchMore(payload);
    callback?.(updatedListAndNextPage.list);
    dispatch(updateContext(updatedListAndNextPage));
  },
);

export const verifyHandlerId = createAsyncThunk(
  `${sliceName}/verifyId`,
  async (payload: { handlerId: number; context: Context }, { dispatch }) => {
    const { handlerId, context } = payload;

    let index: number = context.list.findIndex((item) => item.id == handlerId); //eslint-disable-line eqeqeq
    let total = 0;

    if (index === -1) {
      dispatch(updateContext({ fetching: true }));
      const response = await context.getPosition?.(handlerId, context.folderId);
      if (response) {
        index = response.position;
        total = response.total;
        if (index >= 0) {
          const updatedListAndNextPage = await fetchMore({ context, index });
          dispatch(updateContext(updatedListAndNextPage));
        }
      }
      dispatch(updateContext({ fetching: false }));
    }
    dispatch(
      updateContext({
        currentIndex: index,
        total: total || context.total,
      }),
    );
  },
);

export const initialState: DocumentNavigationSlice = {
  context: {
    currentIndex: -1,
    getItems: () => Promise.resolve([]),
    getPosition: () =>
      Promise.resolve({
        position: -1,
        total: 0,
      }),
    isInitialized: false,
    list: [],
    name: '',
    total: 0,
  },
  currentDocument: undefined,
};
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setIndexById: setIndexByIdReducer,
    updateContext: updateContextReducer,
    resetNavigation: resetNavigationReducer,
    setCurrentDocument: setCurrentDocumentReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(initContext.fulfilled, initContextFulfilledReducer);
    builder.addCase(initContext.rejected, initContextRejectedReducer);
  },
});

export const {
  setIndexById,
  updateContext,
  resetNavigation,
  setCurrentDocument,
} = slice.actions;

export default slice;
