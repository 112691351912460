import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';

import { SEARCH_DEBOUNCE_MS } from '~/constants/debounces';
import { Nullable } from '~/types';

type CallbackFunction<P> = (args: P) => void;

/**
 *
 * @param callback - The function that will be used to fetch for the data.
 * @param initialParams - The params for the callback at first run, if not provided, won't trigger the first fetch.
 * @returns a debounced function to search for data
 */
export const useDebouncedCallback = <P extends Object>(
  callback: CallbackFunction<P>,
  initialParams: Nullable<P> = null,
) => {
  useEffect(() => {
    initialParams && callback(initialParams);
  }, [initialParams]);
  return useCallback(debounce(callback, SEARCH_DEBOUNCE_MS), [callback]);
};
