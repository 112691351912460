export default `
<svg
  aria-hidden="true"
  aria-label="caret-left"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M8.28125 11.7188C7.875 11.3438 7.875 10.6875 8.28125 10.3125L12.2812 6.3125C12.5625 6.03125 13 5.9375 13.375 6.09375C13.75 6.25 14 6.59375 14 7V15C14 15.4062 13.75 15.7812 13.375 15.9375C13 16.0938 12.5625 16 12.2812 15.7188L8.28125 11.7188Z"
    fill="currentcolor"
  />
</svg>
`;
