import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { black1 } from '../../../assets/shared-styles/general';
import EcCheckbox from '../../Shared/EcCheckbox';
import EcSelect from '../../Shared/EcSelect';
import CloseIcon from '../Icons/CloseIcon';
import styles from './EcDepartmentSelectValueWithControls.styles';

class EcDepartmentSelectValueWithControls extends Component {
  constructor(props) {
    super(props);

    this.handleAllowDownload = this.handleAllowDownload.bind(this);

    this.state = {
      dept: this.props.dept,
      allowDownload:
        this.props.dept.visibility_level === 'OPEN_NO_EDIT' ? true : false,
      selectedPrivilege: {
        label: this.renderPreselectedPrivilegeLabel(
          this.props.dept.visibility_level,
        ),
        value: this.renderPreselectedPrivilegeValue(
          this.props.dept.visibility_level,
        ),
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dept !== prevState.dept) {
      return { dept: nextProps.dept };
    } else {
      return null;
    }
  }

  renderPreselectedPrivilegeLabel(visibilityLevel) {
    return visibilityLevel === 'OPEN_NO_EDIT' ||
      visibilityLevel === 'OPEN_NO_EDIT_DOWNLOAD'
      ? 'Can View'
      : 'Can Edit';
  }

  renderPreselectedPrivilegeValue(visibilityLevel) {
    return visibilityLevel === 'OPEN_NO_EDIT' ||
      visibilityLevel === 'OPEN_NO_EDIT_DOWNLOAD'
      ? 'canView'
      : 'canEdit';
  }

  handleAllowDownload() {
    const { updateDepartmentPrivilege } = this.props;
    const { dept, allowDownload } = this.state;

    this.setState({ allowDownload: !allowDownload }, () => {
      updateDepartmentPrivilege({
        department_id: dept.department_id,
        label: dept.department_name,
        value: dept.department_name,
        department_name: dept.department_name,
        visibility_level: this.state.allowDownload
          ? 'OPEN_NO_EDIT'
          : 'OPEN_NO_EDIT_DOWNLOAD',
      });
    });
  }

  handleDepartmentPrivilegeChange(option) {
    const { updateDepartmentPrivilege } = this.props;
    const { dept } = this.state;

    this.setState({
      selectedPrivilege: { label: option.label, value: option.value },
    });

    updateDepartmentPrivilege({
      department_id: dept.department_id,
      department_name: dept.department_name,
      id: dept.department_id,
      name: dept.department_name,
      visibility_level:
        option.value === 'canView' ? 'OPEN_NO_EDIT_DOWNLOAD' : 'OPEN',
    });
  }

  renderPrivilegesControl() {
    const { classes, closeHandler, dept } = this.props;
    const { selectedPrivilege, allowDownload } = this.state;
    const checkboxId =
      'EcDepartmentSelectValueWithControls/AllowDownload-' +
      dept?.department_name;
    const privilegeOptions = [
      {
        label: 'Can Edit',
        value: 'canEdit',
      },
      {
        label: 'Can View',
        value: 'canView',
      },
    ];

    return (
      <div className={classes.accountDepartmentItem}>
        <div className={classes.allowDownloadWrapper}>
          {selectedPrivilege.value === 'canView' && (
            <span className={classes.allowDownloadContainer}>
              <EcCheckbox
                checked={allowDownload}
                onClick={this.handleAllowDownload}
                id={checkboxId}
              />
              <label className={classes.checkboxLabel} htmlFor={checkboxId}>
                Allow department to download
              </label>
            </span>
          )}
        </div>
        <EcSelect
          width="130px"
          height="40px"
          options={privilegeOptions}
          value={selectedPrivilege}
          placeholder="Can Edit"
          onChange={(option) => this.handleDepartmentPrivilegeChange(option)}
          closeHandler={closeHandler}
        />
      </div>
    );
  }

  render() {
    const { classes, closeHandler, isDisabled, isGroup } = this.props;
    const { dept } = this.state;

    return (
      <div
        className={classes.selectedOption}
        key={`${dept.department_name}-${dept.department_id}`}
      >
        <span className={classes.accountDepartmentSelected}>
          {dept.department_name}
        </span>
        {this.renderPrivilegesControl()}
        {isDisabled || isGroup ? null : (
          <button
            className={classes.removeDepartmentIconWrapper}
            onClick={closeHandler}
          >
            <CloseIcon color={black1} size="20" />
          </button>
        )}
      </div>
    );
  }
}

EcDepartmentSelectValueWithControls.propTypes = {
  classes: PropTypes.object.isRequired,
  dept: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default injectSheet(styles)(EcDepartmentSelectValueWithControls);
