import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  intakeFormUpdateFieldOptions,
  intakeFormUpdateFieldValue,
} from '~/actions';
import { downloadTicketAttachment, uploadAtttachments } from '~/api';
import { documentTypeIcon } from '~/components/DocumentsPage/Document.utils';
import DepartmentSelect from '~/components/Shared/DepartmentSelect';
import EcTextarea from '~/components/Shared/EcTextarea';
import { showToast } from '~/components/Shared/EcToast';
import UserSelect from '~/components/Shared/UserSelect';
import {
  customSettingsKeys,
  customSettingsOptionValues,
  valueKeys,
} from '~/constants/workflow';
import {
  CountrySelect,
  CurrencySelect,
  EmailInput,
  Select,
  StateProvinceSelect,
  Tooltip,
} from '~/eds';
import {
  FileExtensionType,
  TicketSpecialOptionValueType,
  TicketStageType,
  WorkflowFieldType,
} from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { getFieldByQuestionId, getQuestionById } from '~/reducers/intakeForm';
import * as toastTypes from '~/types/toast.types';
import {
  Box,
  CheckboxGroup,
  DateInput,
  FileInput,
  FlexLayout,
  Icon,
  InputLabel,
  NumberInput,
  RadioGroup,
  Text,
  TextInput,
} from '~/ui';
import { createOnFilterOption } from '~/utils';
import { downloadFile } from '~/utils/files';

const handleValueChange = () => {};

const handleOptionsChange = () => {};

export const renderQuestionField = ({
  disabled,
  field,
  showError = false,
  updateField = handleValueChange,
  updateOptions = handleOptionsChange,
  validateAttachments = false,
}) => {
  const {
    id,
    type,
    value,
    customSettings,
    customSettings: { format, options },
  } = field;

  const valueKey = valueKeys[type];

  function handleFileAttachError(error, fileName) {
    let message = 'Attach file failed. Please try again.';
    if (validateAttachments && error.response.status === 400) {
      message = `${fileName} failed to upload. ${error.response.data.data}`;
    }
    showToast(toastTypes.ERROR, message);
  }

  function downloadFileAttachment(fileObject) {
    downloadTicketAttachment(fileObject.id)
      .then((file) => {
        showToast(
          toastTypes.DOWNLOAD,
          `${fileObject.fileName}.${fileObject.fileType} successfully downloaded.`,
        );
        downloadFile(file, `${fileObject.fileName}.${fileObject.fileType}`);
      })
      .catch(() =>
        showToast(
          toastTypes.ERROR,
          'Download attachment failed. Please try again.',
        ),
      );
  }

  function getFieldValueByKey(key) {
    return value[key];
  }

  function setFieldValueByKey(key, newValue) {
    updateField({ id, value: { ...value, [key]: newValue } });
  }

  function setFieldOptions(options) {
    updateOptions({ id, options });
  }

  switch (type) {
    case WorkflowFieldType.Address: {
      // STREET_2 and STATE fields are non-required
      return (
        <FlexLayout flexDirection="column" space={2}>
          <InputLabel isRequired id="country" label="Select a Country">
            <CountrySelect
              name="country-select"
              disabled={disabled}
              error={!getFieldValueByKey(valueKey.COUNTRY) && showError}
              value={getFieldValueByKey(valueKey.COUNTRY) || null}
              width="input.l.width"
              enablePortal
              onChange={(val) => setFieldValueByKey(valueKey.COUNTRY, val)}
              onBlur={() =>
                setFieldValueByKey(
                  valueKey.COUNTRY,
                  getFieldValueByKey(valueKey.COUNTRY),
                )
              }
            />
          </InputLabel>
          <InputLabel isRequired id="address" label="Address">
            <TextInput
              disabled={disabled}
              error={!getFieldValueByKey(valueKey.STREET_1) && showError}
              placeholder="Street address 1"
              value={getFieldValueByKey(valueKey.STREET_1)}
              width="l"
              onChange={(val) => setFieldValueByKey(valueKey.STREET_1, val)}
              onBlur={() =>
                setFieldValueByKey(
                  valueKey.STREET_1,
                  getFieldValueByKey(valueKey.STREET_1),
                )
              }
            />
            <TextInput
              disabled={disabled}
              placeholder="Street address 2"
              value={getFieldValueByKey(valueKey.STREET_2)}
              width="l"
              onChange={(val) => setFieldValueByKey(valueKey.STREET_2, val)}
              onBlur={() =>
                setFieldValueByKey(
                  valueKey.STREET_2,
                  getFieldValueByKey(valueKey.STREET_2),
                )
              }
            />
          </InputLabel>
          <FlexLayout space={2}>
            <InputLabel isRequired id="city" label="Town / City">
              <TextInput
                disabled={disabled}
                error={!getFieldValueByKey(valueKey.CITY) && showError}
                placeholder="City"
                value={getFieldValueByKey(valueKey.CITY)}
                width="m"
                onChange={(val) => setFieldValueByKey(valueKey.CITY, val)}
                onBlur={() =>
                  setFieldValueByKey(
                    valueKey.CITY,
                    getFieldValueByKey(valueKey.CITY),
                  )
                }
              />
            </InputLabel>
            <InputLabel isRequired id="city" label="State / Province">
              <StateProvinceSelect
                name="state-province-select"
                country={getFieldValueByKey(valueKey.COUNTRY)}
                disabled={disabled}
                error={!getFieldValueByKey(valueKey.STATE) && showError}
                value={getFieldValueByKey(valueKey.STATE) || null}
                width="input.m.width"
                enablePortal
                onChange={(val) => setFieldValueByKey(valueKey.STATE, val)}
                onBlur={() =>
                  setFieldValueByKey(
                    valueKey.STATE,
                    getFieldValueByKey(valueKey.STATE),
                  )
                }
              />
            </InputLabel>
          </FlexLayout>
          <InputLabel isRequired id="zipCode" label="Zip Code / Post Code">
            <TextInput
              disabled={disabled}
              error={!getFieldValueByKey(valueKey.ZIP_CODE) && showError}
              placeholder={
                getFieldValueByKey(valueKey.COUNTRY) === 'United States'
                  ? 'Zip Code'
                  : 'Post Code'
              }
              value={getFieldValueByKey(valueKey.ZIP_CODE)}
              width="s"
              onChange={(val) => setFieldValueByKey(valueKey.ZIP_CODE, val)}
              onBlur={() =>
                setFieldValueByKey(
                  valueKey.ZIP_CODE,
                  getFieldValueByKey(valueKey.ZIP_CODE),
                )
              }
            />
          </InputLabel>
        </FlexLayout>
      );
    }
    case WorkflowFieldType.Boolean: {
      const BOOLEAN_OPTIONS = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ];
      return (
        <Select
          name="boolean-select"
          disabled={disabled}
          error={!getFieldValueByKey(valueKey.VALUE) && showError}
          options={BOOLEAN_OPTIONS}
          value={getFieldValueByKey(valueKey.VALUE) || null}
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          enablePortal
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        />
      );
    }
    case WorkflowFieldType.Checkbox: {
      return (
        <FlexLayout flexDirection="column" space={4}>
          <CheckboxGroup
            disabled={disabled}
            options={options || []}
            values={getFieldValueByKey(valueKey.VALUE) || []}
            onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
            onBlur={() =>
              setFieldValueByKey(
                valueKey.VALUE,
                getFieldValueByKey(valueKey.VALUE),
              )
            }
          />
          {getFieldValueByKey(valueKey.VALUE)?.includes(
            TicketSpecialOptionValueType.Other,
          ) && (
            <TextInput
              error={!getFieldValueByKey(valueKey.OTHER_VALUE) && showError}
              disabled={disabled}
              placeholder="Specify other option..."
              value={getFieldValueByKey(valueKey.OTHER_VALUE)}
              width="m"
              onChange={(val) => setFieldValueByKey(valueKey.OTHER_VALUE, val)}
              onBlur={() =>
                setFieldValueByKey(
                  valueKey.OTHER_VALUE,
                  getFieldValueByKey(valueKey.OTHER_VALUE),
                )
              }
            />
          )}
        </FlexLayout>
      );
    }
    case WorkflowFieldType.Date: {
      return (
        <div
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        >
          <DateInput
            disabled={disabled}
            error={showError}
            format={format}
            value={getFieldValueByKey(valueKey.VALUE)}
            onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          />
        </div>
      );
    }
    case WorkflowFieldType.Department: {
      const { departments } = customSettings;
      const blacklistOptions =
        customSettings[customSettingsKeys[type].RULE] ===
        customSettingsOptionValues[type].RULE.ALL_EXCEPT
          ? departments
          : [];
      const whitelistOptions =
        customSettings[customSettingsKeys[type].RULE] ===
        customSettingsOptionValues[type].RULE.SPECIFIC
          ? departments
          : [];

      return customSettings[customSettingsKeys[type].ALLOW_MULTIPLE] ? (
        <DepartmentSelect
          notAvailableDepartments={blacklistOptions}
          disabled={disabled}
          isMulti
          value={getFieldValueByKey(valueKey.VALUE) || []}
          specificDepartments={whitelistOptions}
          width="l"
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          enablePortal
          error={showError}
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        />
      ) : (
        <DepartmentSelect
          notAvailableDepartments={blacklistOptions}
          disabled={disabled}
          value={getFieldValueByKey(valueKey.VALUE) || null}
          specificDepartments={whitelistOptions}
          width="l"
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          enablePortal
          error={!getFieldValueByKey(valueKey.VALUE) && showError}
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        />
      );
    }
    case WorkflowFieldType.Email: {
      return (
        <EmailInput
          disabled={disabled}
          error={!getFieldValueByKey(valueKey.VALUE) && showError}
          value={getFieldValueByKey(valueKey.VALUE)}
          width="input.l.width"
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        />
      );
    }
    case WorkflowFieldType.File: {
      const allFileTypes = Object.values(FileExtensionType);
      const allowedFileTypes =
        customSettings[customSettingsKeys[type].ALLOWED_FILE_TYPES];
      const acceptedFileTypes = allFileTypes
        .filter((type) => !allowedFileTypes?.includes(type))
        .concat(allowedFileTypes?.filter((t) => !allowedFileTypes?.includes(t)))
        .toString();
      const allowMultipleFiles =
        customSettings[customSettingsKeys[type].FILE_LIMIT] !==
        customSettingsOptionValues[type].FILE_LIMIT.SINGLE;
      const customSettingsAllowedTypes =
        customSettings[customSettingsKeys[type].ALLOWED_FILE_TYPE_RULE];
      const fileTypes =
        customSettingsAllowedTypes ===
        customSettingsOptionValues[type].FILE_TYPE_RULE.ANY_EXCEPT
          ? acceptedFileTypes
          : customSettings[customSettingsKeys[type].ALLOWED_FILE_TYPES]
          ? customSettings[
              customSettingsKeys[type].ALLOWED_FILE_TYPES
            ].toString()
          : '*';

      let currentValue = value.value;
      function handleFileDataChange(newFile) {
        currentValue = [...(currentValue || []), ...newFile];
        updateField({
          id,
          value: { ...value, value: currentValue.length ? currentValue : null },
        });
      }

      return (
        <FlexLayout
          flexDirection="column"
          space={8}
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        >
          {value?.value &&
            value.value.map((fileObject) => {
              return (
                <FlexLayout
                  alignItems="center"
                  bg="gray-200"
                  key={fileObject.fileName}
                  justifyContent="space-between"
                  mb={3}
                  px={3}
                  space={6}
                  sx={{ borderRadius: 'm', height: 'input-height' }}
                >
                  <FlexLayout
                    alignItems="center"
                    onClick={() => downloadFileAttachment(fileObject)}
                  >
                    {documentTypeIcon(fileObject?.fileType)}
                    <Text variant="xs-dense-bold" color="blue-500">
                      {fileObject.fileName}
                    </Text>
                  </FlexLayout>
                  <Box disabled={disabled}>
                    <Icon
                      color="gray-600"
                      icon="trash"
                      size="s"
                      title="remove file"
                      onClick={() => {
                        const newFiles = value.value.filter(
                          (f) => f.fileName !== fileObject.fileName,
                        );
                        setFieldValueByKey(
                          valueKey.VALUE,
                          newFiles.length ? newFiles : null,
                        );
                      }}
                      onBlur={() => {
                        const newFiles = value.value.filter(
                          (f) => f.fileName !== fileObject.fileName,
                        );
                        setFieldValueByKey(
                          valueKey.VALUE,
                          newFiles.length ? newFiles : null,
                        );
                      }}
                    />
                  </Box>
                </FlexLayout>
              );
            })}
          {value?.value?.length && !allowMultipleFiles ? null : (
            <FileInput
              error={!getFieldValueByKey(valueKey.VALUE) && showError}
              acceptedFiles={fileTypes}
              enableUpload={!disabled}
              enableDropzone
              enableMultiple={allowMultipleFiles}
              onChange={(files) =>
                uploadAtttachments(
                  files,
                  handleFileDataChange,
                  handleFileAttachError,
                  validateAttachments ? id : undefined,
                )
              }
            />
          )}
        </FlexLayout>
      );
    }
    case WorkflowFieldType.LongResponse: {
      return (
        <EcTextarea
          disabled={disabled}
          error={!getFieldValueByKey(valueKey.VALUE) && showError}
          value={getFieldValueByKey(valueKey.VALUE)}
          width="100%"
          onChange={(e) => setFieldValueByKey(valueKey.VALUE, e.target.value)}
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        />
      );
    }
    case WorkflowFieldType.MonetaryValue: {
      const currencyRule =
        customSettings[customSettingsKeys[type].CURRENCY_RULE];
      const currencyList =
        customSettings[customSettingsKeys[type].CURRENCY_LIST];
      return (
        <FlexLayout space={4}>
          <Box>
            <CurrencySelect
              name="currency-select"
              width="input.m.width"
              onFilterOption={createOnFilterOption({
                includeValues:
                  currencyRule ===
                    customSettingsOptionValues[type].CURRENCY_RULE.SPECIFIC &&
                  currencyList,
                excludeValues:
                  currencyRule ===
                    customSettingsOptionValues[type].CURRENCY_RULE.ANY_EXCEPT &&
                  currencyList,
              })}
              disabled={disabled}
              error={!getFieldValueByKey(valueKey.CURRENCY) && showError}
              value={getFieldValueByKey(valueKey.CURRENCY) || null}
              onChange={(val) => setFieldValueByKey(valueKey.CURRENCY, val)}
              enablePortal
              onBlur={() =>
                setFieldValueByKey(
                  valueKey.CURRENCY,
                  getFieldValueByKey(valueKey.CURRENCY),
                )
              }
            />
          </Box>
          <NumberInput
            disabled={disabled}
            error={!getFieldValueByKey(valueKey.AMOUNT) && showError}
            value={getFieldValueByKey(valueKey.AMOUNT)}
            onChange={(val) => setFieldValueByKey(valueKey.AMOUNT, val)}
            onBlur={() =>
              setFieldValueByKey(
                valueKey.AMOUNT,
                getFieldValueByKey(valueKey.AMOUNT),
              )
            }
          />
        </FlexLayout>
      );
    }
    case WorkflowFieldType.MultiSelect: {
      function handleAddOption(newOption) {
        setFieldOptions([...options, newOption]);
      }
      return (
        <Select
          name="multi-select"
          disabled={disabled}
          error={showError}
          isMulti
          limit={100}
          options={options || []}
          placeholder="Pick an option..."
          value={getFieldValueByKey(valueKey.VALUE) || null}
          width="input.l.width"
          onAddOption={
            customSettings[customSettingsKeys[type].ENABLE_CREATE_OPTIONS]
              ? handleAddOption
              : undefined
          }
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          enablePortal
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        />
      );
    }
    case WorkflowFieldType.Number: {
      const { allow_decimals } = customSettings;
      return (
        <NumberInput
          disabled={disabled}
          error={!getFieldValueByKey(valueKey.VALUE) && showError}
          value={getFieldValueByKey(valueKey.VALUE)}
          width="s"
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          allowDecimals={!!allow_decimals}
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        />
      );
    }
    case WorkflowFieldType.Percentage: {
      const { min, max } = customSettings;
      return (
        <NumberInput
          disabled={disabled}
          error={!getFieldValueByKey(valueKey.VALUE) && showError}
          max={max}
          min={min}
          value={getFieldValueByKey(valueKey.VALUE)}
          width="s"
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        />
      );
    }
    case WorkflowFieldType.Radio: {
      return (
        <FlexLayout flexDirection="column" space={4}>
          <RadioGroup
            disabled={disabled}
            options={options || []}
            value={getFieldValueByKey(valueKey.VALUE)}
            onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          />
          {getFieldValueByKey(valueKey.VALUE) ===
            TicketSpecialOptionValueType.Other && (
            <Box mt={4}>
              <TextInput
                disabled={disabled}
                placeholder="Specify other option..."
                value={getFieldValueByKey(valueKey.OTHER_VALUE)}
                width="m"
                onChange={(val) =>
                  setFieldValueByKey(valueKey.OTHER_VALUE, val)
                }
              />
            </Box>
          )}
        </FlexLayout>
      );
    }
    case WorkflowFieldType.ShortResponse: {
      if (disabled) {
        return (
          <Text color="gray-600" variant="xs-dense">
            {getFieldValueByKey(valueKey.VALUE)}
          </Text>
        );
      }
      return (
        <TextInput
          disabled={disabled}
          error={!getFieldValueByKey(valueKey.VALUE) && showError}
          value={getFieldValueByKey(valueKey.VALUE)}
          width="l"
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
        />
      );
    }
    case WorkflowFieldType.SingleSelect: {
      function handleAddOption(newOption) {
        setFieldOptions([...options, newOption]);
      }
      if (disabled) {
        return (
          <Text color="gray-600" variant="xs-dense">
            {getFieldValueByKey(valueKey.VALUE)}
          </Text>
        );
      }
      return (
        <Select
          name="single-select"
          disabled={disabled}
          limit={100}
          error={!getFieldValueByKey(valueKey.VALUE) && showError}
          options={options || []}
          value={getFieldValueByKey(valueKey.VALUE) || null}
          width="input.l.width"
          enablePortal
          onAddOption={
            customSettings[customSettingsKeys[type].ENABLE_CREATE_OPTIONS]
              ? handleAddOption
              : undefined
          }
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        />
      );
    }
    case WorkflowFieldType.TimePeriod: {
      return (
        <FlexLayout space={2}>
          <NumberInput
            disabled={disabled}
            error={!getFieldValueByKey(valueKey.AMOUNT) && showError}
            value={getFieldValueByKey(valueKey.AMOUNT)}
            width="s"
            onChange={(val) => setFieldValueByKey(valueKey.AMOUNT, val)}
            onBlur={() =>
              setFieldValueByKey(
                valueKey.AMOUNT,
                getFieldValueByKey(valueKey.AMOUNT),
              )
            }
          />
          <Select
            name="time-period"
            disabled={disabled}
            error={!getFieldValueByKey(valueKey.PERIOD) && showError}
            options={options || []}
            value={getFieldValueByKey(valueKey.PERIOD) || null}
            width="input.m.width"
            onChange={(val) => setFieldValueByKey(valueKey.PERIOD, val)}
            enablePortal
            onBlur={() =>
              setFieldValueByKey(
                valueKey.PERIOD,
                getFieldValueByKey(valueKey.PERIOD),
              )
            }
          />
        </FlexLayout>
      );
    }
    case WorkflowFieldType.User: {
      const { users } = customSettings;
      if (customSettings[customSettingsKeys[type].ALLOW_MULTIPLE]) {
        return (
          <UserSelect
            name="user-select"
            notAvailableUsers={
              customSettings[customSettingsKeys[type].RULE] ===
              customSettingsOptionValues[type].RULE.ALL_EXCEPT
                ? users
                : undefined
            }
            disabled={disabled}
            enablePortal
            isMulti
            error={showError}
            placeholder="Specify one or more users…"
            value={getFieldValueByKey(valueKey.VALUE) || null}
            specificUsers={
              customSettings[customSettingsKeys[type].RULE] ===
              customSettingsOptionValues[type].RULE.SPECIFIC
                ? users
                : undefined
            }
            width="520px"
            onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
            onBlur={() =>
              setFieldValueByKey(
                valueKey.VALUE,
                getFieldValueByKey(valueKey.VALUE),
              )
            }
          />
        );
      }
      return (
        <UserSelect
          name="user-select"
          notAvailableUsers={
            customSettings[customSettingsKeys[type].RULE] ===
            customSettingsOptionValues[type].RULE.ALL_EXCEPT
              ? users
              : undefined
          }
          disabled={disabled}
          enablePortal
          error={!getFieldValueByKey(valueKey.VALUE) && showError}
          placeholder="Specify a user…"
          value={getFieldValueByKey(valueKey.VALUE) || null}
          specificUsers={
            customSettings[customSettingsKeys[type].RULE] ===
            customSettingsOptionValues[type].RULE.SPECIFIC
              ? users
              : undefined
          }
          width="520px"
          onChange={(val) => setFieldValueByKey(valueKey.VALUE, val)}
          onBlur={() =>
            setFieldValueByKey(
              valueKey.VALUE,
              getFieldValueByKey(valueKey.VALUE),
            )
          }
        />
      );
    }
    default:
      return null;
  }
};

function WorkflowFormQuestion({
  disabled,
  // connected
  currentUser,
  field,
  question,
  stage,
  intakeFormUpdateFieldValue: updateField,
  intakeFormUpdateFieldOptions: updateOptions,
}) {
  const { title, isRequired, description } = question;
  const {
    customSettings,
    errorMessage,
    id,
    isEnabled,
    type,
    warningMessage,
  } = field;

  const isCheckFormFieldFileExtensionEnabled = useFlag(
    FlagType.CheckFormFieldFileExtension,
  );

  const isIntakeFormFieldWithReadOnlyEnabled = useFlag(
    FlagType.IntakeFormFieldWithReadOnly,
  );

  const isReadOnly = isIntakeFormFieldWithReadOnlyEnabled
    ? question.isReadOnly
    : false;

  // populate email when email_type is submitter
  useEffect(() => {
    const isSubmitterType =
      customSettings?.[
        customSettingsKeys[WorkflowFieldType.Email].EMAIL_TYPE
      ] ===
      customSettingsOptionValues?.[WorkflowFieldType.Email].EMAIL_TYPE
        .SUBMITTER;
    if (type === WorkflowFieldType.Email && isSubmitterType) {
      updateField({ id, value: { value: currentUser.email } });
    }
  }, []);

  if (
    !isEnabled ||
    [WorkflowFieldType.DateSigned, WorkflowFieldType.Signature].includes(type)
  ) {
    return null;
  }

  const showError = field.isTouched && !!errorMessage;
  const isDisabled =
    (field.isPlaceHolder &&
      [TicketStageType.Sign, TicketStageType.Finalize].includes(stage)) ||
    disabled;

  return (
    <FlexLayout flexDirection="column" space={2}>
      <FlexLayout alignItems="center" justifyContent="space-between">
        <Text color="gray-700" variant="body2">
          {isRequired ? `${title} *` : title}
        </Text>
      </FlexLayout>
      {description && (
        <Text color="gray-600" variant="xs-dense">
          {description}
        </Text>
      )}
      <FlexLayout flexDirection="column" space={2}>
        <Tooltip
          tooltip={
            isReadOnly &&
            'This is a synced field and cannot be edited in Evisort'
          }
        >
          {renderQuestionField({
            disabled: isDisabled || isReadOnly,
            field,
            showError,
            updateField,
            updateOptions,
            validateAttachments: isCheckFormFieldFileExtensionEnabled,
          })}
        </Tooltip>
        {showError && (
          <Text color="red-600" variant="xs-dense">
            {errorMessage}
          </Text>
        )}
        {
          // do not show the warningMessage when creating a ticket but need to show it afterwards (refer to EKP-2186)
          !!stage && warningMessage && (
            <Text color="peach-800" variant="xs-dense">
              {warningMessage}
            </Text>
          )
        }
      </FlexLayout>
    </FlexLayout>
  );
}

WorkflowFormQuestion.propTypes = {
  disabled: PropTypes.bool,
  questionId: PropTypes.string,
};

const mapStateToProps = (
  { intakeForm, ticket, currentUser },
  { questionId },
) => ({
  currentUser,
  field: getFieldByQuestionId(intakeForm, questionId),
  isValidated: intakeForm.isValidated,
  question: getQuestionById(intakeForm, questionId),
  stage: ticket.stage,
});

export default connect(mapStateToProps, {
  intakeFormUpdateFieldValue,
  intakeFormUpdateFieldOptions,
})(WorkflowFormQuestion);
