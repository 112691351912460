import { SavedSearch } from '~/features/search';

import { JsonApiGetResponse } from '../../ApiTypes';
import { SavedSearchAttributes, SavedSearchMeta } from './types';

export const mapSavedSearchResponseToSavedSearch = ({
  data: savedSearch,
}: JsonApiGetResponse<
  'saved-search',
  SavedSearchAttributes,
  void,
  SavedSearchMeta
>): SavedSearch => {
  return {
    id: savedSearch.id,
    clientId: savedSearch.attributes.client_id,
    dateAdded: savedSearch.attributes.date_added,
    filters: savedSearch.attributes.filters,
    query: savedSearch.attributes.query,
    version: savedSearch.meta?.version ?? 3,
    userId: savedSearch.attributes.user_id,
    name: savedSearch.attributes.name,
    visibility: savedSearch.attributes.search_visibility,
    departmentIds: savedSearch.attributes.department_ids,
    userIds: savedSearch.attributes.user_ids,
    resultTable:
      savedSearch.attributes.result_table?.map((result) => ({
        filterId: result.filter_id,
        columnIndex: result.column_index,
      })) ?? [],
    isComplexQuery: savedSearch.meta?.is_complex_query ?? false,
  };
};
