import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import ActionsMenu from '~/components/Shared/ActionsMenu';
import EcFilterInput from '~/components/Shared/EcFilterInput';

import { black2 } from '../../../assets/shared-styles/general';
import { MODAL_DELETE, MODAL_NAME } from '../../../types/modal.types';
import { ERROR, SUCCESS } from '../../../types/toast.types';
import {
  deleteDocumentsViewset,
  editDocumentsViewset,
  getDocumentsViewsets,
} from '../../DocumentsViewPage/Document.data';
import EcButton from '../../Shared/EcButton';
import EcModal from '../../Shared/EcModal';
import EcModalCard from '../../Shared/EcModalCard';
import { showToast } from '../../Shared/EcToast';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import SearchIcon from '../../Shared/Icons/SearchIcon';
import styles from './DocumentsColumnFieldsViewSwitchModal.styles';

class DocumentsColumnFieldsViewSwitchModal extends Component {
  constructor(props) {
    super(props);

    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleViewSearch = this.handleViewSearch.bind(this);
    this.handleRenameView = this.handleRenameView.bind(this);

    this.state = {
      views: [],
      filteredViews: [],
      filtering: false,
    };
  }

  componentDidMount() {
    this.getDocumentsViewsets();
  }

  getDocumentsViewsets() {
    const { context } = this.props;
    getDocumentsViewsets({ params: { context } })
      .then((data) => {
        this.setState({ views: data });
      })
      .catch(() => {
        this.setState({ errorLoading: true });
        showToast(ERROR, 'An error occurred while loading view list.');
      });
  }

  getMenuItems = (item) => {
    const items = [];

    items.push(
      {
        content: 'Rename',
        onClick: () => this.handleShowModal(MODAL_NAME, item),
      },
      {
        content: 'Delete',
        onClick: () => this.handleShowModal(MODAL_DELETE, item),
      },
    );

    return items;
  };

  handleViewSearch(filterValue) {
    const { views } = this.state;
    const filteredViews = views.filter((view) =>
      view.name.toLowerCase().includes(filterValue),
    );

    this.setState({ filteredViews, filterValue, filtering: true });

    if (!filterValue) {
      this.setState({ views, filtering: false });
    }
  }

  handleRenameView(newName) {
    const { selectedItem } = this.state;

    editDocumentsViewset(selectedItem.id, newName)
      .then((res) => {
        this.handleHideModal();
        showToast(
          SUCCESS,
          `"${selectedItem.name}" view has been renamed to "${res.name}".`,
        );
        this.getDocumentsViewsets();
      })
      .catch(() => {
        this.handleHideModal();
        showToast(ERROR, 'An error occurred while renaming view.');
      });
  }

  handleDeleteView(viewId) {
    deleteDocumentsViewset(viewId)
      .then((res) => {
        this.handleHideModal();
        showToast(SUCCESS, `"${res.name}" view has been deleted.`);
        this.getDocumentsViewsets();
      })
      .catch(() => {
        this.handleHideModal();
        showToast(ERROR, 'An error occurred while deleting the view.');
      });
  }

  handleShowModal(modal, selectedItem = null) {
    this.setState({
      primaryModal: modal,
      selectedItem: selectedItem,
    });
  }

  handleHideModal() {
    this.setState({ primaryModal: null, selectedItem: null });
  }

  renderNameModal() {
    const { selectedItem } = this.state;

    return (
      <EcModal
        modalType={MODAL_NAME}
        width="560px"
        title="Rename View"
        labelText="VIEW NAME"
        itemsCurrentName={selectedItem.name}
        confirmButtonIcon={<CheckmarkIcon size="20" color="#fff" />}
        confirmButtonText="Rename"
        handleNameChange={this.handleRenameView}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderDeleteViewModalText() {
    return (
      <>
        Are you sure you want to delete
        <span> {this.state.selectedItem.name} </span>? You won’t be able to undo
        this action.
      </>
    );
  }

  renderDeleteModal() {
    const { selectedItem } = this.state;

    return (
      <EcModal
        modalType={MODAL_DELETE}
        width="560px"
        title="Delete View?"
        text={this.renderDeleteViewModalText()}
        confirmButtonText="Delete"
        deleteItem={() => this.handleDeleteView(selectedItem.id)}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderSearchBox() {
    const { classes } = this.props;
    const { filterValue } = this.state;

    return (
      <div className={classes.searchboxContainer}>
        <div className={classes.filterSearch}>
          <EcFilterInput
            value={filterValue}
            onChange={(filterValue) => this.handleViewSearch(filterValue)}
            onClearSearch={() => this.handleViewSearch('')}
            placeholder="Filter saved views..."
          />
        </div>
      </div>
    );
  }

  renderDefaultView() {
    const { classes, handleApplyDefaultViewClick } = this.props;
    const { filtering } = this.state;

    if (filtering) return null;

    return (
      <div className={classes.itemRow} role="listitem">
        <div className={classes.itemName}>Default View</div>
        <div className={classes.itemActionsDefault}>
          <EcButton
            iconLeft={<SearchIcon size="20" color={black2} opacity="1" />}
            text="Apply"
            onClick={handleApplyDefaultViewClick}
          />
        </div>
      </div>
    );
  }

  renderItems() {
    const { views, filtering, filteredViews } = this.state;

    const sortedViews = views.length
      ? views.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
        )
      : [];

    if (!sortedViews.length && !filtering) {
      return (
        <div>
          <p>You currently have no saved views.</p>
        </div>
      );
    } else if (filtering) {
      if (!filteredViews.length) {
        return (
          <div>
            <p>No views match your filter.</p>
          </div>
        );
      }
      return this.renderList(filteredViews);
    }
    return this.renderList(sortedViews);
  }

  renderList(list) {
    const { classes, handleApplyViewClick } = this.props;
    return list.map((item) => {
      return (
        <div className={classes.itemRow} key={item.id} role="listitem">
          <div className={classes.itemName}>{item.name}</div>
          <div className={classes.itemActions}>
            <EcButton
              iconLeft={<SearchIcon size="20" color={black2} opacity="1" />}
              text="Apply"
              onClick={() => handleApplyViewClick(item.id)}
            />
            <div className={classes.actionsWrapper}>
              <ActionsMenu
                id={`documents_column_fields_view_switch_actions_menu?${item.type}=${item.id}`}
                items={this.getMenuItems(item)}
                align="end"
                title="documents column fields view switch actions menu"
              />
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    const { classes, hideModal } = this.props;
    const { primaryModal } = this.state;

    return (
      <>
        <EcModalCard
          title="Saved Views"
          content={
            <div className={classes.modalBody}>
              <div className={classes.modalBodyContent} role="group">
                {this.renderSearchBox()}
                {this.renderDefaultView()}
                {this.renderItems()}
              </div>
            </div>
          }
          hideModal={hideModal}
        />
        {primaryModal === MODAL_NAME ? this.renderNameModal() : null}
        {primaryModal === MODAL_DELETE ? this.renderDeleteModal() : null}
      </>
    );
  }
}

DocumentsColumnFieldsViewSwitchModal.propTypes = {
  handleApplyDefaultViewClick: PropTypes.func.isRequired,
  handleApplyViewClick: PropTypes.func.isRequired,
  handleRenameView: PropTypes.func,
  handleDeleteView: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(DocumentsColumnFieldsViewSwitchModal);
