import React from 'react';

import { BaseStatusTextProps } from '../../types';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Text, TruncateText } from '../Text';
import { Tooltip } from '../Tooltip';

export const StatusText = ({
  icon,
  iconColor = 'inherit',
  iconSpacing,
  iconTooltip,
  shouldTruncate,
  status,
  text,
  textColor = 'inherit',
  tooltip,
  variant,
}: BaseStatusTextProps) => {
  const iconSize =
    variant === 'title'
      ? 'l'
      : variant === 'tiny' || variant === 'tiny-bold'
      ? 's'
      : 'm';

  const TextComponent = shouldTruncate ? TruncateText : Text;

  return (
    <Tooltip tooltip={tooltip}>
      <Layout align="center" color={`status.${status}`} preset={iconSpacing}>
        {icon && (
          <Icon
            color={iconColor}
            icon={icon}
            size={iconSize}
            tooltip={iconTooltip}
          />
        )}
        <TextComponent color={textColor} variant={variant}>
          {text}
        </TextComponent>
      </Layout>
    </Tooltip>
  );
};
