import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import {
  black5,
  evisortBlue,
  evisortRed,
} from '../../../assets/shared-styles/general';
import { MODAL_FOLDER_TREE } from '../../../types/modal.types';
import EcButton from '../EcButton';
import EcModal from '../EcModal';
import EcMultipleSelectValue from '../EcMultipleSelectValue';
import EcTooltip from '../EcTooltip';
import withHover from '../HOCs/withHover';
import CheckmarkIcon from '../Icons/CheckmarkIcon';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import PlusIcon from '../Icons/PlusIcon';
import RemoveIcon from '../Icons/RemoveIcon';
import SubArrowIcon from '../Icons/SubArrowIcon';
import styles from './EcFilterFolderField.styles';

const RemoveIconWithHover = withHover(RemoveIcon);

const toFolderFilterValues = (folder) => pick(folder, ['id', 'name', 'path']);

class EcFilterFolderField extends Component {
  constructor(props) {
    super(props);

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleFoldersChange = this.handleFoldersChange.bind(this);
    this.state = { currentModal: null };
  }

  handleShowModal(currentModal) {
    this.setState({ currentModal });
  }

  handleHideModal() {
    this.setState({ currentModal: null });
  }

  handleFoldersChange(locations) {
    const { folders, onChange } = this.props;

    let newFolders = folders ? [...folders] : [];
    newFolders = [...newFolders, ...locations].map(toFolderFilterValues);

    const folder_ids = newFolders.map((f) => f.id);
    onChange({ folders: newFolders, folder_ids, error: false });
  }

  renderMultiSelectFolderTreeModal() {
    const { folders } = this.props;

    const folderIdsSelected = folders ? folders.map((folder) => folder.id) : [];

    return (
      <EcModal
        modalType={MODAL_FOLDER_TREE}
        width="540px"
        title="Choose Folders to Search"
        folderIdsSelected={folderIdsSelected}
        handleChooseMultipleLocationsClick={this.handleFoldersChange}
        confirmButtonIcon={<CheckmarkIcon size="20" />}
        confirmButtonText="Choose Folders"
        hideModal={this.handleHideModal}
        isMultiSelect
      />
    );
  }

  renderSelectedFolderTooltip(folder, index) {
    const { classes } = this.props;
    const path = folder.path.map((pathFolder) => pathFolder.name).join(' → ');

    return (
      <EcTooltip id={`folder-tooltip-${folder.id}-${index}`}>
        <span className={classes.pathTooltip}>{path}</span>
      </EcTooltip>
    );
  }

  renderMultipleValues() {
    const { classes, folders, onChange } = this.props;

    if (!(folders && folders.length)) {
      return (
        <span className={classes.multipleValuesEmpty}>
          No values selected...
        </span>
      );
    }

    return folders.map((value, index) => (
      <Fragment key={index}>
        <div data-tip data-for={`folder-tooltip-${value.id}-${index}`}>
          <EcMultipleSelectValue
            label={value.name}
            closeHandler={() => {
              const multipleValues = [...folders];
              multipleValues.splice(index, 1);

              const folder_ids = multipleValues.map((f) => f.id);
              onChange({ folders: multipleValues, folder_ids });
            }}
          />
        </div>
        {this.renderSelectedFolderTooltip(value, index)}
      </Fragment>
    ));
  }

  render() {
    const { classes, onRemoveClick, onFilterNameClick, error } = this.props;
    const { currentModal } = this.state;

    return (
      <div className={classes.ecFilterFieldWrapper}>
        <div className={classes.ecFilterField}>
          <button
            title="Remove field"
            className={classes.removeFieldContainer}
            onClick={onRemoveClick}
          >
            <RemoveIconWithHover size="20" />
          </button>
          <EcButton
            text="Folder"
            width="200px"
            height="44px"
            iconRight={<ChevronDownIcon />}
            onClick={onFilterNameClick}
          />
        </div>
        <button
          className={classes.foldersAddLink}
          onClick={() => this.handleShowModal(MODAL_FOLDER_TREE)}
        >
          <PlusIcon size="20" color={evisortBlue} />
          <span>Add a Folder</span>
        </button>
        <div className={classes.multipleValuesContainer}>
          <SubArrowIcon color={error ? evisortRed : black5} />
          <div className={classes.multipleValues}>
            {this.renderMultipleValues()}
          </div>
        </div>
        {currentModal === MODAL_FOLDER_TREE &&
          this.renderMultiSelectFolderTreeModal()}
      </div>
    );
  }
}

EcFilterFolderField.propTypes = {
  entity: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onFilterNameClick: PropTypes.func.isRequired,
  folders: PropTypes.array,
  error: PropTypes.bool,
};

export default injectSheet(styles)(EcFilterFolderField);
