import { createSlice } from '@reduxjs/toolkit';

import { AddToGroupStepType } from '~/enums';
import { Field } from '~/evifields';

import { TableViewSet } from '../api/methods';
import { getPersistedState, ReduxPath } from '../persist';

type Modal = { modalType: string; context: object };

type ColumnWidths = {
  [key: string]: number;
};

export type DocumentGroupSlice = {
  group?: {
    name: string;
    groupId: number;
  };
  columnOrder: string[];
  columnWidths: ColumnWidths;
  currentTableView: number | undefined;
  modal?: Modal;
  tableViewSet: TableViewSet;
  queryFields?: Field[];
  currentAddToGroupStep: AddToGroupStepType;
  selectedDocumentIds?: number[];
  selectedDocumentId?: number;
};

export const name = 'Document Group';

const keysToPersist: (keyof DocumentGroupSlice)[] = ['columnWidths'];

type KeyMapToPersist = Partial<Record<keyof DocumentGroupSlice, ReduxPath>>;
const keyMapToPersist: KeyMapToPersist = keysToPersist.reduce<KeyMapToPersist>(
  (prev, curr) => {
    prev[curr] = [name, curr];
    return prev;
  },
  {},
);

export const pathsToPersist = Object.values(keyMapToPersist);

const persistedState = {
  columnWidths: getPersistedState(keyMapToPersist.columnWidths!, {}),
};

export const initialState: DocumentGroupSlice = {
  group: undefined,
  columnOrder: [],
  currentTableView: undefined,
  modal: undefined,
  tableViewSet: [],
  queryFields: undefined,
  currentAddToGroupStep: AddToGroupStepType.DOCUMENTS,
  selectedDocumentIds: undefined,
  selectedDocumentId: undefined,
  ...persistedState,
};

export default createSlice({
  name,
  initialState,
  reducers: {
    initColumnOrder: (state, action) => {
      if (!state.columnOrder) {
        state.columnOrder = action.payload;
      }
    },
    setGroup: (state, action) => {
      state.group = action.payload;
    },
    setColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
    patchColumnWidths: (state, action: { payload: ColumnWidths }) => {
      state.columnWidths = {
        ...state.columnWidths,
        ...action.payload,
      };
    },
    setModal: (
      state,
      action: {
        payload: Modal | undefined;
      },
    ) => {
      state.modal = action.payload;
    },
    setTableViewSet: (state, action) => {
      state.tableViewSet = action.payload;
    },
    setCurrentTableView: (state, action) => {
      state.currentTableView = action.payload;
    },
    setQueryFields: (state, action) => {
      state.queryFields = action.payload;
    },
    setCurrentAddToGroupStep: (state, action) => {
      state.currentAddToGroupStep = action.payload;
    },
    setSelectedDocumentIds: (state, action) => {
      state.selectedDocumentIds = action.payload;
    },
    setSelectedDocumentId: (state, action) => {
      state.selectedDocumentId = action.payload;
    },
  },
});
