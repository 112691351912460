import React from 'react';

import ProviderLogo from '~/components/Shared/ProviderLogo';
import { Button, Card, Divider, Icon, Layout, Text } from '~/eds';
import { Box, FlexLayout, useHover } from '~/ui';

const CARD_PADDING = 4;

const Footer = ({ children }) => (
  <Layout
    align="center"
    flex="none"
    direction="column"
    py={4}
    sx={{
      borderTop: 'border',
      height: '48px',
    }}
  >
    {children}
  </Layout>
);

function IntegrationCard({
  action,
  description,
  inactiveMessage,
  name,
  provider,
  status,
  enableStatusDetails = true,
}) {
  const [hoverRef, isHovered] = useHover();

  const statusDetails = inactiveMessage ? (
    <Text>{inactiveMessage}</Text>
  ) : status ? (
    status
  ) : (
    <Icon icon="plus-circle" />
  );

  const actionButton = (
    <Button
      text={action.text}
      id={action.id}
      variant="action"
      onClick={action.onClick}
    />
  );

  const details = description && isHovered && (
    <FlexLayout
      bg="white"
      alignItems="stretch"
      flexDirection="column"
      justifyContent="space-between"
      p={CARD_PADDING}
      sx={{
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    >
      <Text>{description}</Text>
      <Footer>{actionButton}</Footer>
    </FlexLayout>
  );

  return (
    <Box
      disabled={Boolean(inactiveMessage)}
      ref={hoverRef}
      sx={{
        border: 'border',
        borderColor: isHovered ? 'gray-600' : undefined,
        borderRadius: 'm',
        overflow: 'hidden',
        position: 'relative',
        width: '214px',
      }}
    >
      <Card>
        <Layout align="center" direction="column" spacing={6}>
          <ProviderLogo provider={provider} />
          <Text variant="body-bold">{name}</Text>
          <Divider />
          <Layout align="center">
            {enableStatusDetails ? statusDetails : actionButton}
          </Layout>
          {enableStatusDetails && details}
        </Layout>
      </Card>
    </Box>
  );
}
export default IntegrationCard;
