const addFilters = {
  buttonText: 'Add Filters',
  buttonVariant: 'action' as const,
  ctaText: 'Add',
};

const addFiltersButton = {
  buttonText: 'Add Filters',
  buttonVariant: 'primary' as const,
  ctaText: 'Add',
  buttonIcon: 'filter' as const,
  buttonSize: 's' as const,
};

const editCharts = {
  buttonText: 'Edit Charts',
  buttonVariant: 'secondary' as const,
  ctaText: 'Apply Changes',
};

export const presets = {
  'add-filters-button': addFiltersButton,
  'add-filters': addFilters,
  'edit-charts': editCharts,
  default: addFilters,
};
