import { RouteResolver } from '../types';

/**
 * This creates a resolver with a single step that simply passthroughs the provided component.
 *
 * Use this to indicate explicitly that routes do not need to be resolved in any form.
 */
export const createPassthroughResolvers = (
  component: React.ComponentType,
): RouteResolver[] => {
  return [{ component }];
};
