export default `
<svg
  aria-hidden="true"
  aria-label="layout"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M5 6.5C5 5.6875 5.65625 5 6.5 5H9.5C10.3125 5 11 5.6875 11 6.5V9.5C11 10.3438 10.3125 11 9.5 11H6.5C5.65625 11 5 10.3438 5 9.5V6.5ZM6.5 9.5H9.5V6.5H6.5V9.5ZM5 14.5C5 13.6875 5.65625 13 6.5 13H9.5C10.3125 13 11 13.6875 11 14.5V17.5C11 18.3438 10.3125 19 9.5 19H6.5C5.65625 19 5 18.3438 5 17.5V14.5ZM6.5 17.5H9.5V14.5H6.5V17.5ZM17.5 5C18.3125 5 19 5.6875 19 6.5V9.5C19 10.3438 18.3125 11 17.5 11H14.5C13.6562 11 13 10.3438 13 9.5V6.5C13 5.6875 13.6562 5 14.5 5H17.5ZM17.5 6.5H14.5V9.5H17.5V6.5ZM13 14.5C13 13.6875 13.6562 13 14.5 13H17.5C18.3125 13 19 13.6875 19 14.5V17.5C19 18.3438 18.3125 19 17.5 19H14.5C13.6562 19 13 18.3438 13 17.5V14.5ZM14.5 17.5H17.5V14.5H14.5V17.5Z"
    fill="currentcolor"
  />
</svg>
`;
