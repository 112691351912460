import React, { memo } from 'react';

import { IconColor, SharedInfoCardProps } from '../../../types';
import { Badge } from '../../Badge';
import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { Layout } from '../../Layout';
import { Markdown } from '../../Markdown';
import { Text } from '../../Text';
import { Card } from '../Card';

export const InfoCard = memo(
  ({
    action,
    description,
    icon,
    iconStatus,
    number,
    size = 'm',
    status,
    title,
  }: SharedInfoCardProps) => {
    const indicator = icon ? (
      <Icon
        color={
          iconStatus
            ? (`status.${iconStatus}` as IconColor)
            : status
            ? (`status.${status}` as IconColor)
            : undefined
        }
        icon={icon}
        size="l"
      />
    ) : number ? (
      <Badge status={status} number={number} />
    ) : null;

    return (
      <Card size={size} status={status}>
        <Layout align="center" direction="column" preset="l">
          {indicator}
          <Text textAlign="center" variant="section">
            {title}
          </Text>
          {description && <Markdown text={description} textAlign="center" />}
          {action && (
            <Button {...action} variant={action.level ?? 'secondary'} />
          )}
        </Layout>
      </Card>
    );
  },
);
