import React, { memo } from 'react';

import { useToggle } from '../../hooks';
import { BaseWelcomeContentProps, IconType } from '../../types';
import { Box } from '../Box';
import { Button } from '../Button';
import { Card, InfoCard } from '../Card';
import { Checkbox } from '../Checkbox';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Link } from '../Link';
import { ListItems } from '../ListItems';
import { Markdown } from '../Markdown';
import { Text } from '../Text';

interface Props extends BaseWelcomeContentProps {
  /** Allows the user to dismiss the welcome content */
  onDismiss?: () => void;
}

export const WelcomeContent = memo(
  ({
    content,
    ctaAction,
    description,
    icon,
    infoCards,
    listItems,
    link,
    mode,
    title,
    status,
    onDismiss,
  }: Props) => {
    const [isDismissed, toggleIsDismissed] = useToggle(false);
    const isHeroMode = mode === 'hero';
    const Container = isHeroMode ? Card : Layout;

    return (
      <Layout
        h="100%"
        preset="sections"
        justify={isHeroMode ? 'center' : 'space-between'}
        align="center"
      >
        <Box maxW="600px">
          <Container size="l">
            <Layout preset="sections">
              <Layout direction="column" spacing={2}>
                <Icon name="evisort-ai" icon={icon as IconType} size="l" />
                <Text
                  textAlign={isHeroMode ? 'left' : 'center'}
                  variant={isHeroMode ? 'banner' : 'subtitle'}
                >
                  {title}
                </Text>
                {description && <Markdown text={description} />}
              </Layout>

              <Layout direction="column" spacing="4">
                {infoCards?.map((item, index) => (
                  <InfoCard key={index} {...item} size="s" status={status} />
                ))}
                {listItems && <ListItems as="ul" items={listItems} />}
              </Layout>
              {link && <Link href={link.href}>{link.text}</Link>}
              {content}
              <Layout justify="center">
                {ctaAction && (
                  <Button
                    preset="cta"
                    text={ctaAction.text}
                    onClick={ctaAction.onClick}
                    isLoading={ctaAction.isLoading}
                  />
                )}
              </Layout>
            </Layout>
          </Container>
        </Box>

        {onDismiss && (
          <Layout pb="4" alignSelf="flex-end" flex="none">
            <Checkbox
              name="dismiss-welcome-content"
              option={{
                value: isDismissed,
                label: 'Do not show this again',
              }}
              value={isDismissed}
              onChange={() => {
                toggleIsDismissed();
                onDismiss();
              }}
            />
          </Layout>
        )}
      </Layout>
    );
  },
);
