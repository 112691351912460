import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.UserPreferences, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.UserPreferences as TagType],
  endpoints: {
    getPinnedFilters: {
      providesTags: [TAG_BY_LIST],
    },
  },
});
