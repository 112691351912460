import React from 'react';

import { User } from '../../../components/';
import {
  PilotId,
  RequireOnlyOne,
  SharedUserProps,
  UserMode,
  User as UserType,
} from '../../../types';

/**
 * Accepts either the intended `SharedUserProps`, or an `asyncUser` prop that
 * - requires an user `id` to be specified
 * - a `render` component that implements the `SharedUserProps` interface including the `id` prop.
 */
type Props<M extends UserMode = 'avatar'> = RequireOnlyOne<{
  asyncUser: {
    id: PilotId;
    render: React.ComponentType<
      SharedUserProps<M> & {
        id: PilotId;
      }
    >;
  };
  user: UserType;
}> &
  SharedUserProps<M>;

export const UserCell = ({
  asyncUser,
  mode = 'avatar',
  options,
  user,
}: Props) => {
  if (asyncUser) {
    const { id, render: AsyncUserComponent } = asyncUser;
    return <AsyncUserComponent id={id} options={options} mode={mode} />;
  }

  return <User user={user} mode={mode} options={options} />;
};
