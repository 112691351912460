import { CELL_WIDTHS } from '../constants';
import Cell from './Cell';
import HeaderCell from './HeaderCell';
import { RowActionCell } from './RowActionCell';
import SelectCell from './SelectCell';
import { getPrimaryColumnKey } from './utils';

/**
 * Reduces an array into an object based on provided item key.
 */
const fromArray = (array, key) => {
  return array.reduce((object, item) => {
    if (item.hasOwnProperty(key)) {
      object[item[key]] = item;
    }
    return object;
  }, {});
};

// TODO: discuss with design to deprecate various align/width configurations
export const CELL_CONFIGS = {
  checkbox: {
    align: 'center',
    maxWidth: 'xs',
    minWidth: 'xs',
    width: 'xs',
  },
  chips: {
    align: 'left',
    minWidth: 's',
    width: 'm',
  },
  datetime: {
    align: 'left',
    minWidth: 's',
    width: 's',
  },
  link: {
    align: 'left',
    minWidth: 's',
    width: 'l',
  },
  radio: {
    align: 'center',
    maxWidth: 'xs',
    minWidth: 'xs',
    width: 'xs',
  },
  text: {
    align: 'left',
    minWidth: 's',
    width: 'l',
  },
  user: {
    align: 'left',
    minWidth: 's',
    width: 'm',
  },
  users: {
    align: 'left',
    minWidth: 's',
    width: 'm',
  },
};

const getWidth = (config, key, defaultSize, overrideSize) => {
  const size = config[key];

  if (overrideSize) {
    return CELL_WIDTHS[overrideSize] ?? overrideSize;
  } else if (size) {
    return CELL_WIDTHS[size];
  } else {
    return CELL_WIDTHS[defaultSize];
  }
};

const prepareColumn = (column) => {
  const {
    align,
    cellType,
    key,
    maxWidth,
    minWidth,
    sortCompare,
    width,
    rawWidth,
    rawMinWidth,
  } = column;
  const cellConfig = CELL_CONFIGS[cellType] || CELL_CONFIGS['text'];

  const preparedColumn = {
    ...column,
    accessor: key,
    align: align || cellConfig.align,
    maxWidth: getWidth(cellConfig, 'maxWidth', 'xl', maxWidth),
    minWidth:
      rawMinWidth !== undefined
        ? rawMinWidth
        : getWidth(cellConfig, 'minWidth', 's', minWidth),
    width:
      rawWidth !== undefined
        ? rawWidth
        : getWidth(cellConfig, 'width', 'm', width),
  };

  if (sortCompare) {
    preparedColumn.sortType = (rowA, rowB) =>
      column.sortCompare(rowA.original, rowB.original);
  }

  return preparedColumn;
};

export const defaultColumn = prepareColumn({
  Cell,
  Header: HeaderCell,
  cellType: 'text',
  disableResizing: false,
  disableSortBy: false,
});

export const rowActionColumn = prepareColumn({
  Cell: RowActionCell,
  Header: RowActionCell,
  key: 'row-action',
  disableResizing: true,
  disableSortBy: true,
});

export const selectColumn = prepareColumn({
  Cell: SelectCell,
  Header: SelectCell,
  key: 'id',
  cellType: 'checkbox',
  disableResizing: true,
  disableSortBy: true,
});

export const singleSelectColumn = prepareColumn({
  Cell: SelectCell,
  Header: HeaderCell,
  key: 'id',
  cellType: 'checkbox',
  disableResizing: true,
  disableSortBy: true,
});

export const prepareColumns = ({
  allColumns,
  columnOrder: initialColumnOrder,
  isLoading,
  options,
  enableRowActions,
  selectedColumnKeys = [],
  onSelectColumns,
}) => {
  const allColumnsMap = fromArray(allColumns, 'key');
  const mapLoadingColumn = (column) => ({
    ...column,
    cellType: 'loading',
    disableResizing: true,
    mapCellProps: undefined,
    renderCell: undefined,
  });

  // Always ensure that the primary column key is sorted to the front regardless of how it is provided.
  const primaryColumnKey = getPrimaryColumnKey(allColumns);
  const columnOrder = primaryColumnKey
    ? [
        primaryColumnKey,
        ...initialColumnOrder.filter(
          (columnKey) => columnKey !== primaryColumnKey,
        ),
      ]
    : [];

  let columns = columnOrder
    .filter((key) => Boolean(allColumnsMap[key])) // only include valid column keys
    .map((key, i) => {
      const preparedColumn = prepareColumn(allColumnsMap[key]);
      return i === 0 // the first column is always the primary column
        ? {
            ...preparedColumn,
            isPrimary: true,
          }
        : preparedColumn;
    });

  if (isLoading) {
    columns = columns.map(mapLoadingColumn);
  } else {
    if (options.enableSelectRows) {
      columns.unshift(
        options.isMultiSelectRows ? selectColumn : singleSelectColumn,
      );
    }
    if (enableRowActions) {
      columns.push(rowActionColumn);
    }
  }

  if (onSelectColumns) {
    columns = columns.map((column) => ({
      ...column,
      isMultiSelectColumns: options.isMultiSelectColumns,
      isSelected: selectedColumnKeys.includes(column.key),
      onSelectColumn: () => {
        const columnKey = column.key;
        let updatedColumnKeys = [];
        if (options.isMultiSelectColumns) {
          updatedColumnKeys = selectedColumnKeys.includes(columnKey)
            ? selectedColumnKeys.filter((key) => key !== columnKey)
            : [...selectedColumnKeys, columnKey];
        } else {
          updatedColumnKeys = [columnKey];
        }
        onSelectColumns(updatedColumnKeys);
      },
    }));
  }
  return columns;
};
