import {
  black1,
  black2,
  black3,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  fieldBody: {
    display: 'flex',
  },
  fieldValue: {
    flexGrow: '1',
  },
  fieldTitle: {
    marginBottom: ({ field }) => (field.name ? '8px' : '0'),
    color: black2,
    fontSize: '12px',
    fontWeight: semibold,
    textTransform: 'uppercase',
  },
  multipleValuesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '12px',
  },
  multipleValuesEmpty: {
    color: black3,
    fontSize: '15px',
  },
  fieldTextArea: {
    display: 'block',
    width: '100%',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    padding: '6px',
    color: black1,
    fontSize: '15px',
    outline: 'none',
    resize: 'none',
  },
};

export default styles;
