import React from 'react';

import FallbackContent from '~/components/Shared/FallbackContent';
import { Icon, Link, types } from '~/eds';
import { HttpStatusCodeType, QueryParamType } from '~/enums';
import { api, coerceRtkqError } from '~/redux';
import { useRouting } from '~/routing';

const Page = () => {
  const { getSearchParam } = useRouting();
  const documentId = getSearchParam(QueryParamType.DocumentId);
  const versionId = getSearchParam(QueryParamType.VersionId);
  const {
    data,
    error,
    isFetching,
  } = api.endpoints.downloadTicketDocumentVersionEmailAttachment.useQuery({
    documentId,
    versionId,
  });
  const status = coerceRtkqError(error)?.response?.status;
  const notFoundDocumentVersionId = status === HttpStatusCodeType.NotFound;

  function generateContent(
    icon: types.IconType,
    iconColor: types.IconColor,
    content: any,
  ) {
    return (
      <FallbackContent
        icon={<Icon color={iconColor} icon={icon} size="l" />}
        title={content}
      />
    );
  }

  if (isFetching) {
    return generateContent(
      'loading',
      'status.info',
      'Your download link is being verified.',
    );
  } else if (notFoundDocumentVersionId) {
    return generateContent(
      'status-reject',
      'status.danger',
      'Your download link does not exist.',
    );
  } else {
    const url = data?.url;
    if (url) {
      const btn = (
        <Link variant="button" href={url}>
          Click here to download attachment
        </Link>
      );
      return generateContent('status-success', 'status.success', btn);
    } else {
      return generateContent(
        'status-reject',
        'status.danger',
        'Your download link is not found. Please re-check your email.',
      );
    }
  }
};

export default Page;
