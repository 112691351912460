import { camelizeKeys } from 'humps';
import React, { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';

import { getCurrentUser, getSyncPairs } from '~/api';
import {
  addNewClient,
  ClientEditPage,
  deleteClient,
  getBucketInfo,
  getClients,
  getCustomAlgorithmChoices,
  updateClient,
} from '~/components/Admin/ClientsPage';
import EcCard from '~/components/Shared/EcCard';
import EcClientTable from '~/components/Shared/EcClientTable';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import CheckmarkIcon from '~/components/Shared/Icons/CheckmarkIcon';
import { PAGE_START } from '~/constants/page';
import { Box, ContentContainer, Layout, PageLayout, Paginate } from '~/eds';
import { ClientType, QueryParamType, SyncType } from '~/enums';
import { actions } from '~/redux';
import { MODAL_DELETE, MODAL_NAME } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';

const tableStyles = { padding: '0 16px' };
const PAGE_SIZE = 10; //BE defines

class Page extends Component {
  constructor(props) {
    super(props);

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModel = this.handleCloseModel.bind(this);
    this.handleClosePage = this.handleClosePage.bind(this);
    this.onAddNewClient = this.onAddNewClient.bind(this);
    this.onClientUpdate = this.onClientUpdate.bind(this);
    this.onClientDelete = this.onClientDelete.bind(this);
    this.getBucketInfo = this.getBucketInfo.bind(this);
    this.getActiveSyncPairs = this.getActiveSyncPairs.bind(this);

    this.state = {
      clients: [],
      errorMessage: null,
      page: 1,
      searchText: '',
      resultCount: 0,
      loading: true,
      currentPage: null,
      selectedClient: null,
      enabledSaveButton: true,
      customAlgorithmChoices: [],
      bucketInfo: '',
      activePairs: null,
      newClientData: {
        name: '',
        subdomain: '',
        descrip: '',
        bucket: '',
        clientType: ClientType.Internal,
        syncType: SyncType.Evifs,
        customAlgorithms: [],
        clauseCountLimit: 5,
        dashboardEnabled: false,
        inboundEmailEnabled: false,
        externalApiEnabled: false,
        ssoEnabled: false,
        syncPairWebhookEnabled: false,
        workflowEnabled: false,
        urlBuilderEnabled: false,
      },
    };
  }

  componentDidMount() {
    const urlParams = {
      page: getPageSearchQueryByKey(QueryParamType.Page, PAGE_START),
      searchText: getPageSearchQueryByKey(QueryParamType.SearchText, ''),
    };
    this.getCustomAlgorithmChoices();
    this.setState({ ...urlParams }, () => {
      this.getClients();
      updatePageSearchQuery({
        [QueryParamType.Page]: urlParams.page,
        [QueryParamType.SearchText]: urlParams.searchText,
      });
    });
  }

  getClients() {
    const { page, searchText } = this.state;

    getClients(page, searchText)
      .then((res) => {
        this.setState({
          clients: res.results,
          loading: false,
          searchText,
          resultCount: res.count,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        showToast(ERROR, 'An error occurred while loading the client list.');
      });
  }

  getCustomAlgorithmChoices() {
    getCustomAlgorithmChoices().then((res) => {
      if (res && res.data)
        this.setState({
          customAlgorithmChoices: res.data.map((key) => ({
            label: key[1],
            value: key[1],
          })),
        });
    });
  }

  getActiveSyncPairs(clientId) {
    getSyncPairs({ clientId })
      .then((res) => {
        this.setState({ activePairs: res });
      })
      .catch(() => {
        showToast(ERROR, 'An error occurred while loading active sync pairs.');
      });
  }

  getBucketInfo(bucket) {
    getBucketInfo(bucket)
      .then((res) => {
        this.setState({ bucketInfo: res.data.message });
      })
      .catch(() => {
        this.setState({
          errorMessage: ['Please make sure you have entered a valid bucket'],
        });
      });
  }

  handleShowModal(modal, selectedClient = null) {
    this.setState({
      currentPage: modal,
      selectedClient: selectedClient,
    });
    if (selectedClient) {
      this.getActiveSyncPairs(selectedClient.id);
    }
  }

  handleCloseModel() {
    this.setState({
      errorMessage: null,
      bucketInfo: '',
      currentPage: null,
      activePairs: null,
    });
  }

  handleClosePage() {
    this.setState({
      errorMessage: null,
      bucketInfo: '',
      currentPage: null,
      activePairs: null,
      newClientData: {
        name: '',
        subdomain: '',
        descrip: '',
        bucket: '',
        clientType: ClientType.Internal,
        syncType: SyncType.Evifs,
        customAlgorithms: [],
        clauseCountLimit: 5,
        dashboardEnabled: false,
        inboundEmailEnabled: false,
        externalApiEnabled: false,
        ssoEnabled: false,
        syncPairWebhookEnabled: false,
        workflowEnabled: false,
        urlBuilderEnabled: false,
      },
    });
  }

  handleInputChangeSearchText = (e) => {
    const { value } = e.target;

    this.setState({ searchText: value, page: 1 }, () => {
      this.getClients();
      updatePageSearchQuery({
        [QueryParamType.Page]: 1,
        [QueryParamType.SearchText]: value,
      });
    });
  };

  onAddNewClient(configs) {
    this.setState({ isCreating: true });
    const { name, subdomain, bucket, descrip, clientType } = configs;

    addNewClient(configs)
      .then((res) => {
        let clients = [...this.state.clients];
        clients.push(res);
        this.setState({ clients: clients, errorMessage: null });

        this.handleClosePage();
        showToast(SUCCESS, `Client ${name} has been created!`);
        this.setState({ isCreating: false });
      })
      .catch((error) => {
        this.setState({ isCreating: false });

        let message = ['A server error occurred. Please try again later.'];
        if (error.response && error.response.status === 400) {
          message = error.response.data;
        }

        this.handleClientError(
          message,
          name,
          subdomain,
          bucket,
          descrip,
          clientType,
        );
      });
  }

  onClientUpdate(configs) {
    this.setState({ isUpdating: true });

    const { selectedClient } = this.state;
    const { setCurrentUser } = this.props;
    const { name, bucket, descrip, clientType } = configs;

    updateClient(configs)
      .then((res) => {
        const newClients = this.state.clients.map((item) =>
          Object.assign(
            item,
            [res].find((itm) => itm.id === item.id),
          ),
        );
        this.setState({
          clients: newClients,
          errorMessage: null,
          isUpdating: false,
        });
        showToast(
          SUCCESS,
          `Client information for "${selectedClient.name}" has been updated.`,
        );
        this.handleClosePage();
        getCurrentUser().then((currentUser) => setCurrentUser(currentUser));
      })
      .catch((error) => {
        this.setState({ isUpdating: false });

        let errorMsg = ['A server error occurred. Please try again later.'];
        if (error.response && error.response.status === 400) {
          errorMsg = error.response.data;
        }
        this.handleClientError(
          errorMsg,
          name,
          selectedClient.sub_domain,
          bucket,
          descrip,
          clientType,
        );
      });
  }

  onClientDelete() {
    const { selectedClient } = this.state;

    deleteClient(selectedClient.id)
      .then(() => {
        showToast(SUCCESS, 'The client has been deleted.');
        this.getClients();
      })
      .catch(() =>
        showToast(ERROR, 'An error occurred while deleting the client.'),
      );
  }

  renderDeleteClientModalText() {
    return <div>Type the name of the client to confirm deletion</div>;
  }

  renderAddClientPage(title) {
    const {
      newClientData,
      customAlgorithmChoices,
      errorMessage,
      isCreating,
    } = this.state;
    return (
      <ClientEditPage
        title={title}
        errorMessage={errorMessage}
        isLoading={isCreating}
        currentPage={this.state.currentPage}
        customAlgorithmChoices={customAlgorithmChoices}
        clientData={newClientData}
        onCancel={this.handleClosePage}
        onSubmit={this.onAddNewClient}
      />
    );
  }

  renderUpdateClientPage(title) {
    const {
      selectedClient,
      customAlgorithmChoices,
      activePairs,
      errorMessage,
      isUpdating,
    } = this.state;
    return (
      <ClientEditPage
        title={title}
        errorMessage={errorMessage}
        isLoading={isUpdating}
        activePairs={activePairs}
        currentPage={this.state.currentPage}
        customAlgorithmChoices={customAlgorithmChoices}
        clientData={camelizeKeys(selectedClient)}
        onCancel={this.handleClosePage}
        onSubmit={this.onClientUpdate}
      />
    );
  }

  renderDeleteClientModal(title) {
    const { selectedClient } = this.state;
    return (
      <EcModal
        modalType={MODAL_DELETE}
        width="560px"
        title={title}
        text={this.renderDeleteClientModalText()}
        confirmButtonText="Delete"
        deleteItem={this.onClientDelete}
        hideModal={this.handleCloseModel}
        includeConfirmation={true}
        confirmationText={selectedClient.name}
      />
    );
  }

  renderTestBucketModal(title) {
    const { errorMessage, enabledSaveButton, bucketInfo } = this.state;
    return (
      <EcModal
        modalType={MODAL_NAME}
        width="560px"
        title={title}
        confirmButtonIcon={<CheckmarkIcon color="#000" size="20" />}
        hideModal={this.handleCloseModel}
        handleShowModal={this.handleShowModal}
        errorMessage={errorMessage}
        enabledSaveButton={enabledSaveButton}
        confirmButtonText="Check Bucket"
        itemsCurrentName=""
        handleNameChange={this.getBucketInfo}
        bucketInfo={bucketInfo}
      />
    );
  }

  handleClientError(
    errorMessage,
    name = '',
    subdomain = '',
    bucket = 1,
    descrip = '',
    clientType = '',
  ) {
    const newClientData = { name, subdomain, descrip, bucket, clientType };

    if (!Array.isArray(errorMessage)) {
      errorMessage = Object.entries(errorMessage).map(
        ([p, val]) => `${p}: ${val}`,
      );
    }

    this.setState({ errorMessage, newClientData });
  }

  renderPagination() {
    const { page, resultCount, searchText } = this.state;

    if (resultCount <= PAGE_SIZE) return null;

    return (
      <Layout justify="center">
        <Paginate
          totalCount={resultCount}
          pageSize={PAGE_SIZE}
          pageIndex={page}
          onUpdatePageIndex={(selectedPage) => {
            this.setState({ page: selectedPage, loading: true }, () => {
              this.getClients();
              updatePageSearchQuery(
                {
                  [QueryParamType.Page]: selectedPage,
                  [QueryParamType.SearchText]: searchText,
                },
                true,
              );
            });
          }}
        />
      </Layout>
    );
  }

  render() {
    const { clients, loading, currentPage, searchText } = this.state;

    return (
      <PageLayout title="Manage Clients">
        <Layout flex="auto" preset="sections">
          <Box
            styles={{
              right: '16px',
              bottom: '16px',
              '& input': {
                width: '300px',
                padding: '10px',
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                borderRadius: '3px',
                color: 'rgba(64, 64, 64, 1)', //black2
                fontSize: '16px',
                outline: 'none',
                caretColor: 'hsl(220,160%,50%)', // evisort blue
                '&::placeholder': {
                  color: 'rgba 0 0 0, 0.5',
                  fontSize: '14px',
                },
              },
            }}
          >
            <DebounceInput
              autoFocus={true}
              debounceTimeout={300}
              placeholder="Client name"
              value={searchText}
              onChange={this.handleInputChangeSearchText}
            />
          </Box>
          <ContentContainer
            loadingContent={{ isLoading: loading }}
            placeholderContent={
              clients.length === 0
                ? searchText
                  ? { message: 'No clients match this name.' }
                  : { message: 'No clients found.' }
                : undefined
            }
          >
            <EcCard contentStyles={tableStyles}>
              <EcClientTable
                handleShowModal={this.handleShowModal}
                listItems={[...clients]}
              />
            </EcCard>
            {this.renderPagination()}
          </ContentContainer>
          {currentPage === 'create'
            ? this.renderAddClientPage('Add new client information')
            : null}
          {currentPage === 'update'
            ? this.renderUpdateClientPage('Edit Client Information')
            : null}
          {currentPage === 'delete'
            ? this.renderDeleteClientModal('Delete Client')
            : null}
          {currentPage === 'checkBucket'
            ? this.renderTestBucketModal('Check Bucket')
            : null}
        </Layout>
      </PageLayout>
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

Page = connect(mapStateToProps, { setCurrentUser: actions.setCurrentUser })(
  Page,
);

export default Page;
