import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { HighlightType } from '../../types';
import { Box } from '../Box';

interface Props {
  /** Contents to mark/highlight */
  children: React.ReactNode;
  /** DOM ID */
  id?: string;
  /** Highlighter */
  highlighter?: HighlightType;
  /** To determine if a highlight is active */
  isActive?: boolean;
  /** Optional callback to indicate marked content is interactable */
  onClick?: () => void;
  /** Optinal callback used for highlighting PDFs and indicate marked content is interactabe */
  onMouseDown?: () => void;
}

export const Mark = memo(
  ({
    id,
    isActive,
    children,
    highlighter = 'default',
    onClick,
    onMouseDown,
  }: Props) => {
    const styles = useStyles();

    return (
      <Box
        as="mark"
        id={id}
        styles={[
          styles.variants['highlight'][highlighter],
          isActive ? styles.interactions[':active'] : null,
        ]}
        onClick={onClick}
        onMouseDown={onMouseDown}
      >
        {children}
      </Box>
    );
  },
);
