/**
 * Link is implemented using ReactRouter.Link APIs.
 *
 * Please refer to https://v5.reactrouter.com/web/api/Link/to-object for detailed documentation.
 */
import React, { memo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom-v5-compat';
import { Layout, useStyles } from 'uinix-ui';

import { SharedLinkProps } from '../../types';
import { Icon } from '../Icon';
import { Text, TruncateText } from '../Text';
import { Tooltip } from '../Tooltip';

export const Link = memo(
  ({
    children,
    color,
    disabled,
    display = 'flex',
    hash,
    href,
    isActive,
    isInverse,
    isNav,
    maxW,
    pathname = '/',
    search,
    shouldTruncate = false,
    state,
    text,
    tooltip,
    variant,
    onClick,
    enableNewTab = Boolean(href),
  }: SharedLinkProps) => {
    const styles = useStyles();

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
      if (disabled) {
        event.preventDefault();
        return;
      } else if (onClick) {
        // prevents default navigation event explicitly and run the onClick handler if provided
        event.preventDefault();
        event.stopPropagation();
        onClick();
      }
    };

    const TextComponent = shouldTruncate ? TruncateText : Text;

    const textContent = (
      <Tooltip tooltip={tooltip}>
        <TextComponent
          color={color || (isInverse ? 'inverse.text.link' : 'action.link')}
          disabled={disabled}
          styles={styles.link}
          maxW={maxW}
          styleProps={{ isActive, isNav }}
          variant={variant}
        >
          {text ?? children}
        </TextComponent>
      </Tooltip>
    );

    const contents = enableNewTab ? (
      <Layout align="center" display={display}>
        {textContent}
        <Icon disabled={disabled} icon="launch" />
      </Layout>
    ) : (
      textContent
    );

    const commonProps = {
      'aria-current': isActive ? ('page' as const) : undefined,
      disabled,
      target: enableNewTab ? '_blank' : undefined,
      onClick: handleClick,
      style: { overflow: shouldTruncate ? 'hidden' : '' },
    };

    if (href) {
      return (
        <a {...commonProps} href={href}>
          {contents}
        </a>
      );
    } else {
      return (
        <ReactRouterLink
          {...commonProps}
          state={state}
          to={{
            hash,
            pathname,
            search,
          }}
        >
          {contents}
        </ReactRouterLink>
      );
    }
  },
);
