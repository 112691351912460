import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import styles from './SimpleModal.styles';

function SimpleModal({
  children,
  title,
  modalButtons,
  includeCancelButton,
  hideModal,
}) {
  return (
    <EcModalCard
      title={title}
      content={children}
      footer={
        <>
          {includeCancelButton && (
            <EcButton white borderless text="Cancel" onClick={hideModal} />
          )}
          {modalButtons}
        </>
      }
      hideModal={hideModal}
    />
  );
}

SimpleModal.propTypes = {
  title: PropTypes.string.isRequired,
  modalButtons: PropTypes.object,
  width: PropTypes.string,
  includeCancelButton: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
};

export default injectSheet(styles)(SimpleModal);
