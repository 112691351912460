import { logout as logoutApi } from '~/api/auth';
import AuthenticationStore from '~/auth';
import {
  redirectToDomainLogin,
  redirectToLogin,
  redirectToLoginEmbed,
} from '~/services/redirects';

const AS = AuthenticationStore();

export const logout = (currentUser) => {
  const { client_config: clientConfig } = currentUser;
  const subDomain = clientConfig?.sub_domain;

  logoutApi().finally(() => {
    AS.clearAuthentication();
    if (AS.isEmbed()) {
      redirectToLoginEmbed();
    } else if (!!subDomain) {
      redirectToLogin(subDomain);
    } else {
      redirectToDomainLogin();
    }
  });
};
