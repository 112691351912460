import React, { memo, ReactNode } from 'react';

import { TooltipType } from '../../types';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip/Tooltip';

interface Props {
  /** Contents for mention (simple text content preferred) */
  children: ReactNode;
  /** Indicates the mention is disabled or invalid */
  disabled?: boolean;
  /** If the mention is active */
  isActive?: boolean;
  /** Tooltip to be displayed when hovering mention. */
  tooltip?: TooltipType;
}

export const Mention = memo(
  ({ children, disabled = false, tooltip }: Props) => {
    return (
      <Tooltip tooltip={tooltip}>
        <Layout
          align="baseline" // this is important as Mentions are usually used with text nodes
          bg="background.secondary"
          border="border"
          borderRadius="pill"
          disabled={disabled}
          my={0.5}
          px={2}
          spacing={1}
        >
          <Text>{children}</Text>
        </Layout>
      </Tooltip>
    );
  },
);
