import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout, Icon, useHover } from '~/ui';

function SidebarSectionItem({
  borderLeftColor = 'gray-400',
  borderLeftIcon,
  children,
  disabled = false,
  indent = 0,
  py = 3,
  showStem = false,
  sx = {},
  onClick,
  onClickBorderLeftIcon,
}) {
  const [hoverRef, isHovered] = useHover();

  const enableBorderIcon = borderLeftIcon && !!onClickBorderLeftIcon;

  return (
    <FlexLayout
      bg={isHovered ? 'gray-200' : 'transparent'}
      disabled={disabled}
      flex="1 1 auto"
      pl="30px"
      pr={10}
      ref={hoverRef}
      onClick={onClick}
      role="listitem"
    >
      <FlexLayout
        flex="1 1 auto"
        pl={`${30 * (indent + 1)}px`}
        py={py}
        sx={{
          ...sx,
          borderLeftColor:
            !enableBorderIcon || (enableBorderIcon && !isHovered)
              ? borderLeftColor
              : 'transparent',
          borderLeftStyle: 'solid',
          borderLeftWidth: '4px',
          position: 'relative',
        }}
      >
        {showStem && (
          <Box
            bg="gray-400"
            sx={{
              alignSelf: 'center',
              height: '2px',
              left: 0,
              position: 'absolute',
              width: '22px',
            }}
          />
        )}
        {children}
        {enableBorderIcon && isHovered && (
          <Icon
            color="gray-600"
            icon={borderLeftIcon}
            size="xs"
            sx={{
              left: '-2px',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            title="Remove"
            onClick={(e) => {
              e.stopPropagation();
              onClickBorderLeftIcon();
            }}
          />
        )}
      </FlexLayout>
    </FlexLayout>
  );
}

SidebarSectionItem.propTypes = {
  borderLeftColor: PT.string,
  borderLeftIcon: PT.string,
  children: PT.node,
  indent: PT.number,
  onClick: PT.func,
  onClickBorderLeftIcon: PT.func,
};

export default SidebarSectionItem;
