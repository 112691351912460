export default `
<svg
  aria-hidden="true"
  aria-label="status-indicator-warning"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M17.0718 12C17.0718 14.7812 14.8218 17 12.0718 17C9.29053 17 7.07178 14.7812 7.07178 12C7.07178 9.25 9.29053 7 12.0718 7C14.8218 7 17.0718 9.25 17.0718 12Z"
    fill="#ffc400"
  />
</svg>
`;
