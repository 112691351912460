import PT from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import { Box } from '~/ui';
import { withInputError } from '~/ui/hocs';

import {
  defaultFormatCreateLabel,
  formatCreateLabelFactory,
  formatOptionLabel,
  getOptionsData,
  MenuListRenderer,
  optionPropType,
} from '../SingleSelect';
import components from './components';
import stylesFactory from './stylesFactory';

const widths = {
  s: '200px',
  m: '260px',
  l: '520px',
  fullWidth: '100%',
};

function getSelectValue({ optionsMap, values, invalidOptionLabelRenderer }) {
  return values
    ? values.map((value) => {
        const option = optionsMap[value];
        if (option) {
          return option;
        } else {
          const label = invalidOptionLabelRenderer(value);
          return {
            errorLabel: label,
            label,
            value,
          };
        }
      })
    : '';
}
function MultiSelect({
  blacklistValues = [],
  containerSx = {},
  disabled = false,
  enableDocumentBodyMenuPortal = false,
  error,
  formatCreateLabel = defaultFormatCreateLabel,
  id,
  isClearable = true,
  isInputClearedAfterBlur = false,
  isSearchable = true,
  noOptionsMessage = 'No options',
  options: initialOptions,
  placeholder = 'Select / Add',
  shouldHideSelectedOptions = true,
  values = [],
  whitelistValues = [],
  width = 'l',
  filterOption,
  menuHeaderRenderer,
  menuPlacement = 'auto',
  onChange,
  onAddOption,
  onFocus,
  onInputChange,
  optionIconRenderer,
  invalidOptionLabelRenderer = () => 'Removed',
}) {
  const isCreatable = !!onAddOption;

  const { optionsMap, options } = getOptionsData({
    blacklistValues,
    initialOptions,
    whitelistValues,
    optionIconRenderer,
  });

  const SelectComponent = isCreatable ? Creatable : Select;

  const [inputValue, setInputValue] = useState('');

  /**
   * The following code section implements sortable values based on the formal recommendation:
   * https://react-select.com/advanced#sortable-multiselect
   */
  const arrayMove = (array, from, to) => {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  };
  const sortProps = {
    axis: 'xy',
    distance: 4,
    helperClass: 'overrideReactSortableHoc',
    onSortEnd: ({ oldIndex, newIndex }) => {
      const updatedValues = arrayMove(values, oldIndex, newIndex);
      onChange(updatedValues, { action: 'sort-option' });
    },
    getHelperDimensions: ({ node }) => node.getBoundingClientRect(),
  };

  return (
    <Box sx={{ flexShrink: 0, width: widths[width], ...containerSx }} id={id}>
      <SelectComponent
        components={{
          ...components,
          MenuList: (props) => MenuListRenderer(props, menuHeaderRenderer),
        }}
        filterOption={filterOption}
        formatCreateLabel={formatCreateLabelFactory(formatCreateLabel)}
        formatOptionLabel={formatOptionLabel}
        hideSelectedOptions={shouldHideSelectedOptions}
        inputValue={isInputClearedAfterBlur ? undefined : inputValue}
        isClearable={isClearable}
        isCreatable={isCreatable}
        isDisabled={disabled}
        isMulti
        isSearchable={isSearchable || isCreatable}
        menuPlacement={menuPlacement}
        menuPortalTarget={
          enableDocumentBodyMenuPortal ? document.body : undefined
        }
        noOptionsMessage={() => noOptionsMessage}
        options={options}
        placeholder={placeholder}
        styles={stylesFactory(error)}
        value={getSelectValue({
          optionsMap,
          values,
          invalidOptionLabelRenderer,
        })}
        onCreateOption={(newValue) => {
          if (!optionsMap[newValue]) {
            onAddOption({ value: newValue, label: newValue });
            onChange([...values, newValue], { action: 'create-option' });
          }
        }}
        onChange={(updatedOptions, actionMeta) => {
          const updatedValues = updatedOptions.map((option) => option.value);
          onChange(updatedValues, actionMeta);
        }}
        onFocus={onFocus}
        onInputChange={(value, action) => {
          if (
            !isInputClearedAfterBlur &&
            action?.action !== 'input-blur' &&
            action?.action !== 'menu-close'
          ) {
            setInputValue(value);
          }
          onInputChange?.(value, action);
        }}
        sortProps={sortProps}
      />
    </Box>
  );
}

MultiSelect.propTypes = {
  /** Exclude options that match any of the values that are blacklisted */
  blacklistValues: PT.arrayOf(PT.any),
  /** Indicates if component is disabled */
  disabled: PT.bool,
  /** When rendering component in modals and popovers, this is required to allow menu to be attached to document.body and support floating above its attached node */
  enableDocumentBodyMenuPortal: PT.bool,
  /** Error that gets passed to component */
  error: PT.oneOfType([PT.bool, PT.string]),
  /** Customize the label when creating a new option */
  formatCreateLabel: PT.func,
  /** Supports clear indicator */
  isClearable: PT.bool,
  /** Indicates if options are searchable */
  isSearchable: PT.bool,
  /** Message to display in menu when no options are found */
  noOptionsMessage: PT.string,
  /** Array of options: { value, label } */
  options: PT.arrayOf(optionPropType.isRequired).isRequired,
  /** Placeholder */
  placeholder: PT.string,
  /** Determines if selected options are hidden in the menu */
  shouldHideSelectedOptions: PT.bool,
  /** Array of currently selected values */
  values: PT.arrayOf(PT.any),
  /** Explicitly include options that match any of the values that are whitelisted */
  whitelistValues: PT.arrayOf(PT.any),
  /** Input width */
  width: PT.oneOf(['s', 'm', 'l', 'fullWidth']),
  /** Custom filtering to display options when search input value changes */
  filterOption: PT.func,
  /** Label renderer for an invalid option that is not found in provided options */
  invalidOptionLabelRenderer: PT.func,
  /** Renderer for the menu header */
  menuHeaderRenderer: PT.func,
  /** Dropdown position */
  menuPlacement: PT.string,
  /** Callback that returns new selected value */
  onChange: PT.func.isRequired,
  /** Callback that returns a newly created option - transforms component to `Creatable` (see `react-select` package) */
  onAddOption: PT.func,
  /** Callback when select is focused */
  onFocus: PT.func,
  /** Callback when select search input changes */
  onInputChange: PT.func,
  /** Renderer for option icon */
  optionIconRenderer: PT.func,
};

export const DOCS__MultiSelect = MultiSelect;

export default withInputError(MultiSelect);
