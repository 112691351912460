import { noop } from 'lodash';
import PT from 'prop-types';
import React from 'react';

import { Box, ConfigurationCard } from '~/ui';

import ConditionRuleInput, {
  conditionRulePropType,
} from './ConditionRuleInput';

const SkipStageAccordion = ({
  conditionRule,
  isSkipped,
  onChange,
  onToggle,
}) => {
  return (
    <ConfigurationCard
      isEnabled={isSkipped}
      title="Skip Stage"
      onToggleIsEnabled={onToggle}
      onToggleCollapse={noop}
    >
      <Box disabled={!isSkipped}>
        <ConditionRuleInput
          conditionRule={conditionRule}
          label="Skip when"
          onChange={onChange}
        />
      </Box>
    </ConfigurationCard>
  );
};

SkipStageAccordion.propTypes = {
  /** A ConditionRule interface */
  conditionRule: conditionRulePropType,
  /** If the skip stage is skipped */
  isSkipped: PT.bool.isRequired,
  /** Callback capturing the updated `conditionRule` value */
  onChange: PT.func.isRequired,
  /** Callback capturing toggled isSkipped boolean */
  onToggle: PT.func.isRequired,
};

export default SkipStageAccordion;
