import PropTypes from 'prop-types';
import React from 'react';

const DocumentTypeMusicIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path fill="#8247BC" d="M4 2h12l4 4v16H4z" />
        <text
          fill="#FFF"
          fontFamily="SFMono-Heavy, SF Mono, Arial"
          fontSize="6"
          fontWeight="600"
        >
          <tspan x="6.437" y="19">
            MP3
          </tspan>
        </text>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M11.5 5v4.103a1.757 1.757 0 0 0-.833-.214C9.746 8.889 9 9.585 9 10.444c0 .86.746 1.556 1.667 1.556.92 0 1.666-.696 1.666-1.556V6.556H14V5h-2.5z"
        />
      </g>
    </svg>
  );
};

DocumentTypeMusicIcon.defaultProps = {
  size: '24',
};

DocumentTypeMusicIcon.propTypes = {
  size: PropTypes.string,
};

export default DocumentTypeMusicIcon;
