import { atlas } from '~/services';

import { QuestionGroupResponsePayload } from './types';
import { mapQuestionGroupResponse } from './utils';

export const resolveQuestionGroups = async ({ ids }: { ids: string[] }) => {
  const questionGroupPromises: Promise<QuestionGroupResponsePayload>[] = ids.map(
    (id) => atlas.get(`question-groups/${id}`),
  );

  const questionGroups = await Promise.all(questionGroupPromises);

  return questionGroups.map((questionGroup) =>
    mapQuestionGroupResponse(questionGroup.data),
  );
};
