import { camelizeKeys, decamelizeKeys } from 'humps';

import { pilot } from '~/services';

export const fromUsersListToMap = (users = []) => {
  const map = {};

  users.forEach((user) => {
    map[user.id] = {
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      avatar: user.avatar,
    };
  });

  return map;
};

export async function getAllUsers(clientId) {
  try {
    const res = await pilot.get(`user/${clientId}/list_all_limited/`);
    // transform
    return fromUsersListToMap(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

export const getAllUsersForAdmins = (clientId) => {
  return pilot.get(`user/${clientId}/list_all_min/`);
};

export const getUploadLogs = (page = 1) =>
  pilot.get(`/user/my-uploads/?page=${page}`);

export const getUserImportJobsData = async ({
  clientId,
  importId,
  timeout,
  cancelToken,
}) => {
  const params = decamelizeKeys({ clientId, importId });
  const result = await pilot.get('user/import/status/', {
    params,
    timeout,
    cancelToken,
  });

  return camelizeKeys(result);
};
