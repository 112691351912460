import get from 'lodash/get';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectDocumentInGroup from '~/components/SearchV2/AddSearchResultsToGroupV2/SelectDocumentInGroup';
import { showToast } from '~/components/Shared/EcToast';
import { AddToGroupStepType, HttpStatusCodeType } from '~/enums';
import { api, selectors } from '~/redux';
import documentGroup from '~/redux/slices/documentGroup';
import { MODAL_DOCUMENT_GROUP_RELATION } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { LoadingContainer, WizardPageLayout } from '~/ui';

const MAX_TITLE_WIDTH = 355;

const LinkToParent = ({ groupId, onComplete }) => {
  const step = AddToGroupStepType.STRUCTURE;
  const selectedDocumentId = useSelector(
    selectors.selectDocGroupSelectedDocumentId,
  );
  const modal = useSelector(selectors.selectDocGroupModal);
  const { modalType, context } = modal || {};

  const hideModal = () => {
    dispatch(documentGroup.actions.setModal());
    dispatch(documentGroup.actions.setSelectedDocumentId(undefined));
  };

  const dispatch = useDispatch();

  const [
    linkDocumentsToGroup,
    linkDocumentsToGroupResult,
  ] = api.endpoints.linkDocumentsToGroup.useMutation();
  const {
    isError: linkDocumentsToGroupError,
    isSuccess: linkDocumentsToGroupSuccess,
    isLoading: linkDocumentsToGroupLoading,
    error: linkDocumentsToGroupResponseError,
  } = linkDocumentsToGroupResult;

  useEffect(() => {
    if (linkDocumentsToGroupSuccess) {
      showToast(SUCCESS, 'Documents added to the group successfully!');
      hideModal();
      onComplete?.();
    }
  }, [linkDocumentsToGroupSuccess]);

  useEffect(() => {
    if (linkDocumentsToGroupError) {
      const { response } = linkDocumentsToGroupResponseError;
      const defaultErrorMessage =
        'Error on adding documents to the group, please try again later.';
      if (response && response.status === HttpStatusCodeType.BadRequest) {
        showToast(ERROR, get(response, 'data.message', defaultErrorMessage));
      } else {
        showToast(ERROR, defaultErrorMessage);
      }
      hideModal();
    }
  }, [linkDocumentsToGroupError]);

  const handleSelectedDocument = useCallback((documentId) => {
    dispatch(documentGroup.actions.setSelectedDocumentId(documentId));
  }, []);

  const handleLinkDocuments = () => {
    if (selectedDocumentId) {
      linkDocumentsToGroup({
        groupId,
        parentId: selectedDocumentId,
        //TODO: remove once "load more" is not selectable anymore
        children: context?.selectedRowIds?.filter(
          (docGroupId) => !isNaN(docGroupId),
        ),
      });
    }
    dispatch(documentGroup.actions.setSelectedDocumentId(undefined));
  };

  const steps = useMemo(() => {
    return {
      [AddToGroupStepType.STRUCTURE]: {
        title: 'Linking documents to parent file',
        component: (
          <SelectDocumentInGroup
            group={groupId}
            onDocumentSelected={handleSelectedDocument}
            documentGroupIds={context?.selectedRowIds}
            autoSelectOnlyOption={true}
            title="Select parent file to link to"
          />
        ),
      },
    };
  }, [groupId, context]);

  const isLoading = linkDocumentsToGroupLoading;

  if (modalType !== MODAL_DOCUMENT_GROUP_RELATION) return null;

  return (
    <WizardPageLayout
      currentStep={AddToGroupStepType.STRUCTURE}
      steps={[AddToGroupStepType.STRUCTURE]}
      title={{ text: steps[step].title, maxWidth: MAX_TITLE_WIDTH }}
      onClose={hideModal}
      actionButton={{
        ...steps[step].action,
        text: 'Link Documents',
        promise: handleLinkDocuments,
        disabled: !selectedDocumentId,
      }}
      isContentFill={true}
    >
      <LoadingContainer isLoading={isLoading}>
        {steps[step].component}
      </LoadingContainer>
    </WizardPageLayout>
  );
};

export default LinkToParent;
