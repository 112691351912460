import PT from 'prop-types';
import React from 'react';

import { Button, Icon, IconButton } from '~/eds';
import { CrmType } from '~/enums';
import { Box, Chip, FlexLayout, Tooltip } from '~/ui';

function IntegrationStatusAndActions({
  disabled,
  integration,
  isActive = false,
  isLoading = false,
  isPending = false,
  onConnect,
  onDelete,
  onToggleCollapse,
}) {
  const { id, error } = integration;
  const items = [];

  if (disabled) {
    items.push(<Button disabled={disabled} text="Connect" variant="action" />);
  } else {
    // integration status
    if (!isActive && !id) {
      items.push(
        <Button
          disabled={disabled}
          onClick={onConnect}
          text="Connect"
          variant="action"
        />,
      );
    } else if (error) {
      items.push(
        <Tooltip key="error" content={error.message}>
          <Chip text="Validation error" variant="danger" />
        </Tooltip>,
      );
    } else if (isActive && isPending) {
      items.push(<Chip key="pending" text="Pending" variant="warning" />);
    } else if (isActive && !id) {
      items.push(
        <Chip key="not-connected" text="Not connected" variant="info" />,
      );
    } else if (id) {
      items.push(<Chip key="connected" text="Connected" variant="success" />);
    }

    // delete status
    if (id) {
      items.push(
        <Box key="delete" ml={6} pl={8} sx={{ borderLeft: 'border' }}>
          {isLoading ? (
            <Icon icon="loading" />
          ) : (
            <IconButton
              disabled={isLoading}
              icon="trash"
              label="remove"
              onClick={onDelete}
            />
          )}
        </Box>,
      );
    }
  }

  return (
    <FlexLayout alignItems="center" space={2} onClick={onToggleCollapse}>
      {items}
    </FlexLayout>
  );
}

// TODO: make a common StatusAndActions component that takes different integration object types
IntegrationStatusAndActions.propTypes = {
  integration: PT.shape({
    id: PT.string,
    config: PT.object.isRequired,
    error: PT.shape({ message: PT.string.isRequired }),
    isUserLoggedIn: PT.bool,
    type: PT.oneOf(Object.values(CrmType)),
  }).isRequired,
  isActive: PT.bool,
  isLoading: PT.bool,
  isPending: PT.bool,
  onConnect: PT.func.isRequired,
  onDelete: PT.func.isRequired,
  onToggleCollapse: PT.func.isRequired,
};

export default IntegrationStatusAndActions;
