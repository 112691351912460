import { Filter } from '~/evifields';
import { sylvanus } from '~/services';

import {
  PromptModelDocsInScope,
  SearchScope,
} from '../../types/sylvanus/model';
import { buildFilterQuery } from '../searchV3';

export const getDocsInScope = async ({
  fieldId,
  filters,
}: {
  fieldId: number;
  filters: Filter[];
}): Promise<number> => {
  const query = buildFilterQuery({
    booleanQuery: '',
    filters,
  });

  const scope: SearchScope = {
    data: {
      attributes: {
        filters: [],
        query,
        store_as_recent_search: false,
      },
    },
    type: 'document',
  };

  const response: PromptModelDocsInScope = await sylvanus.post(
    `/model/docs-in-scope`,
    {
      target_entity: {
        id: String(fieldId),
        type: 'field',
      },
      scope,
    },
  );

  return response.docs_in_scope;
};
