import { theme } from '../../system/theme';
import { BreakpointConfig, BreakpointKey, Breakpoints } from './types';

const backgroundColor = theme.colors.background as string;
const brandColor = theme.colors.brand as string;
const brandAiColor = theme.colors['brand.ai'] as string;

export const defaultBreakpoints: Breakpoints = {
  neutral: [0, 0.74],
  good: [0.74, 0.89],
  great: [0.89, 1],
};

export const getBreakpointKey = (
  breakpoints: Breakpoints,
  score: number,
): BreakpointKey => {
  const breakpointKey = Object.entries(breakpoints).reduce((acc, entry) => {
    const [key, [min, max]] = entry;
    if (score > min && score <= max) {
      return key;
    }
    return acc;
  }, 'neutral');

  return breakpointKey as BreakpointKey;
};

// Create the intended background gradient from two color values
export const getBackgroundGradient = (color1: string, color2: string) => {
  return `linear-gradient(${backgroundColor}, ${backgroundColor}), radial-gradient(circle at top left, ${color1}, ${color2})`;
};

export const breakpointConfigs: Record<BreakpointKey, BreakpointConfig> = {
  neutral: {
    gradient: getBackgroundGradient(brandColor, brandColor),
    image: 'evibot-meh',
    label: 'Neutral',
  },
  good: {
    gradient: getBackgroundGradient(brandAiColor, brandAiColor),
    image: 'evibot-happy',
    label: 'Good',
  },
  great: {
    gradient: getBackgroundGradient(brandColor, brandAiColor),
    image: 'evibot-lit',
    label: 'Great',
  },
};
