import { useState } from 'react';

type EmptyCallback = () => void;

export const useToggle = (
  initialValue = false,
): [boolean, EmptyCallback, EmptyCallback, EmptyCallback] => {
  const [value, setValue] = useState(initialValue);

  const toggle = () => setValue((prev) => !prev);
  const on = () => setValue(true);
  const off = () => setValue(false);

  return [value, toggle, on, off];
};
