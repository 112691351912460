import React from 'react';

import { UserAction } from '../../types';
import { Box } from '../Box';
import { Button } from '../Button';

type Mode = 'pill' | 'chip';

interface Props {
  /** Button actions */
  actions: UserAction[];
  /** Disables the entire button group */
  disabled?: boolean;
  /** Stretches the width of the button to its parent container */
  isFullWidth?: boolean;
  /** UI modes */
  mode?: Mode;
}

export const ButtonGroup = ({
  actions,
  disabled: allDisabled = false,
  isFullWidth,
  mode: groupMode,
}: Props) => {
  return (
    <Box display="flex" role="group">
      {actions.map((action, index) => {
        const { disabled, icon, mode, status, text, tooltip, onClick } = action;
        const isActive = status === 'active';
        const isFirst = index === 0;
        const isLast = index === actions.length - 1;
        const isPill = groupMode === 'pill';
        // TODO: use formal chip mode button API
        const isChip = groupMode === 'chip';

        return (
          <Box
            key={index}
            flex={isFullWidth ? 'auto' : 'none'}
            styles={[
              componentStyles.button,
              isLast ? componentStyles.last({ isPill }) : {},
              isFirst ? componentStyles.first({ isPill }) : {},
              isActive && !status ? componentStyles.active : {},
              isChip ? componentStyles.chip : {},
            ]}
          >
            <Button
              aria-pressed={Boolean(status)}
              disabled={allDisabled || disabled}
              icon={icon}
              isFullWidth={isFullWidth}
              mode={mode}
              status={status}
              text={text}
              tooltip={tooltip}
              onClick={onClick}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const componentStyles = {
  button: {
    '& button': {
      border: 'border',
      borderLeftColor: 'transparent',
      borderRadius: 0,
    },
  },
  chip: {
    '& button': {
      borderTopLeftRadius: 'l',
      borderBottomLeftRadius: 'l',
      borderTopRightRadius: 'l',
      borderBottomRightRadius: 'l',
      marginRight: 2,
      borderColor: 'border',
      height: 'auto',
      paddingTop: 1,
      paddingBottom: 1,
    },
  },
  first: ({ isPill }: { isPill: boolean }) => {
    return {
      '& button': {
        borderLeftColor: 'border',
        borderBottomLeftRadius: isPill ? 'pill' : 'm',
        borderTopLeftRadius: isPill ? 'pill' : 'm',
      },
    };
  },
  // TODO: drive the logic purely via `Button.status` (discuss with design to prefer `Button[status="active"]`)
  active: {
    '& button': {
      backgroundColor: 'background.quiet',
    },
  },
  last: ({ isPill }: { isPill: boolean }) => {
    return {
      '& button': {
        borderBottomRightRadius: isPill ? 'pill' : 'm',
        borderTopRightRadius: isPill ? 'pill' : 'm',
      },
    };
  },
};
