import PT from 'prop-types';
import React, { Children } from 'react';

import { Box, FlexLayout } from '~/ui';

function ListItems({ alignItems, children, columns = 1, mb = 2, space = 9 }) {
  return (
    <FlexLayout
      alignItems={alignItems}
      flexWrap="wrap"
      space={space}
      sx={{
        mr: (theme) => `-${theme.space[space]}`,
      }}
    >
      {Children.map(children, (child) => {
        if (child === null) {
          return null;
        }

        return (
          <Box
            mb={mb}
            sx={{
              flex: (theme) =>
                `0 0 calc(${100 / columns}% - ${theme.space[space]})`,
            }}
          >
            {child}
          </Box>
        );
      })}
    </FlexLayout>
  );
}

ListItems.propTypes = {
  /** CSS align-items property */
  alignItems: PT.string,
  /** React children */
  children: PT.node,
  /** Number of columns */
  columns: PT.number,
  /** vertical margin bottom for each items (useful when flex-wrapped) */
  mb: PT.number,
  /** Horizontal space between items */
  space: PT.oneOfType([PT.number, PT.string]),
};

export default ListItems;
