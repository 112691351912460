import React from 'react';
import { connect } from 'react-redux';

import {
  workflowAddFinalizeTask,
  workflowRemoveFinalizeTask,
  workflowSetFinalizeCoordinator,
  workflowSetFinalizeTaskName,
  workflowSetStageConditionRule,
  workflowSortFinalizeTasks,
  workflowToggleSkipStage,
} from '~/actions';
import { FormField } from '~/eds';
import { TicketStageType } from '~/enums';
import { getFinalizeErrors, getFinalizeTasks } from '~/reducers/workflow';
import { FlexLayout } from '~/ui';
import { coerceName } from '~/utils/workflow';

import SkipStageAccordion from '../../shared/SkipStageAccordion';
import StageItems from '../../shared/StageItems';
import WorkflowUserRoleSingleSelect from '../../shared/WorkflowUserRoleSingleSelect';
import Phase from './Phase';

function Finalize({
  // connected
  conditionRule,
  coordinator,
  disabled,
  errors,
  isSkipped,
  finalizePhases,
  workflowAddFinalizeTask,
  workflowSetFinalizeCoordinator,
  workflowSortFinalizeTasks,
  workflowRemoveFinalizeTask,
  workflowSetFinalizeTaskName,
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
}) {
  const finalizeItems = finalizePhases.map((phase, index) => ({
    ...phase,
    title: coerceName(phase.title, 'Finalize Phase', index),
    content: <Phase key={phase.id} phaseId={phase.id} position={index} />,
  }));

  const handleUpdateStageConditionRule = (updatedConditionRule) => {
    workflowSetStageConditionRule({
      conditionRule: updatedConditionRule,
      stage: TicketStageType.Finalize,
    });
  };

  const handleToggleSkipStage = (updatedIsSkipped) => {
    workflowToggleSkipStage({
      isSkipped: updatedIsSkipped,
      stage: TicketStageType.Finalize,
    });
  };

  return (
    <FlexLayout disabled={disabled} flexDirection="column" space={6}>
      <SkipStageAccordion
        conditionRule={conditionRule}
        isSkipped={isSkipped}
        onChange={handleUpdateStageConditionRule}
        onToggle={handleToggleSkipStage}
      />
      <FlexLayout flexDirection="column" mb={8} space={4}>
        <FormField
          description="A task list coordinator will be in charge of making sure that all the tasks have been reviewed."
          id="workflow--finalize-coordinator"
          input={WorkflowUserRoleSingleSelect}
          label="TASK LIST COORDINATOR"
          placeholder="Not specified"
          width="fullWidth"
          value={coordinator}
          onChange={workflowSetFinalizeCoordinator}
          error={
            errors.unspecifiedCoordinator
              ? 'You need to specify a finalize coordinator.'
              : undefined
          }
        />
      </FlexLayout>
      <StageItems
        disabled={disabled}
        items={finalizeItems}
        invalidPhaseIds={errors.invalidPhaseIds}
        removeModalTitle="Remove Finalize Phase"
        sortModalTitle="Reorder Finalize Phases"
        onRemove={workflowRemoveFinalizeTask}
        onSort={workflowSortFinalizeTasks}
        onEditTitle={workflowSetFinalizeTaskName}
        newItemAction={{
          id: 'workflow--add-finalize-phase',
          text: 'Add finalize phase',
          handler: workflowAddFinalizeTask,
        }}
      />
    </FlexLayout>
  );
}

function mapStateToProps({ builder, workflow }) {
  const stage = workflow.stages[TicketStageType.Finalize];
  return {
    conditionRule: stage.conditionRule,
    coordinator: stage.coordinators[0] || null,
    isSkipped: !stage.isEnabled,
    disabled: !builder.shouldEnableAllComponents,
    finalizePhases: getFinalizeTasks(workflow),
    errors: getFinalizeErrors(workflow),
  };
}

export default connect(mapStateToProps, {
  workflowAddFinalizeTask,
  workflowSetFinalizeCoordinator,
  workflowSortFinalizeTasks,
  workflowRemoveFinalizeTask,
  workflowSetFinalizeTaskName,
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
})(Finalize);
