import React, { useMemo } from 'react';

import {
  Actions,
  formatNumber,
  IconButton,
  Layout,
  Popover,
  useToggle,
} from '~/eds';
import { FlagType, useFlag } from '~/flags';

import './popover_overrides.css';
import SearchBar from './SearchBar';

type ZoomScale = 'page-width' | number | undefined;

interface Props {
  searchListenOn: React.RefObject<HTMLElement>;
  zoom: {
    scale: ZoomScale;
    onChange: (scale: ZoomScale) => void;
  };
}

const Toolbar = ({ searchListenOn, zoom }: Props) => {
  const [isVisible, toggleSearchBar] = useToggle(false);

  const enablePdfViewerZoom = useFlag(FlagType.PdfViewerZoom);

  const zoomActions = useMemo(() => {
    const { scale, onChange } = zoom;

    const isFitPageWidth = scale === 'page-width';
    const zoomScale = Number(scale) || 1;
    const zoomInScale = Math.min(zoomScale + 0.25, 10);
    const zoomOutScale = Math.max(zoomScale - 0.25, 0.25);

    return [
      {
        icon: 'dash' as const,
        mode: 'icon' as const,
        text: 'Zoom out',
        tooltip: `Zoom out (${formatNumber(zoomOutScale, {
          decimal: 0,
          style: 'percent',
        })})`,
        onClick: () => onChange(zoomOutScale),
      },
      {
        icon: 'plus' as const,
        mode: 'icon' as const,
        text: 'Zoom in',
        tooltip: `Zoom in (${formatNumber(zoomInScale, {
          decimal: 0,
          style: 'percent',
        })})`,
        onClick: () => onChange(zoomInScale),
      },
      {
        icon: 'maximize' as const,
        mode: 'icon' as const,
        status: scale === 'page-width' ? ('active' as const) : undefined,
        text: 'Fit page width',
        tooltip: isFitPageWidth ? 'Reset to original size' : 'Fit page width',
        onClick: () => onChange(isFitPageWidth ? 1 : 'page-width'),
      },
    ];
  }, [zoom]);

  return (
    <Layout preset="icons">
      <Popover
        preset="dropdown"
        trigger={
          <IconButton
            icon="search"
            status={isVisible ? 'active' : undefined}
            tooltip="Search"
            onClick={toggleSearchBar}
          />
        }
        isVisible={isVisible}
      >
        <SearchBar
          listenOn={searchListenOn}
          toggleVisiblity={toggleSearchBar}
          isVisible={isVisible}
        />
      </Popover>
      {enablePdfViewerZoom && <Actions actions={zoomActions} />}
    </Layout>
  );
};

export default Toolbar;
