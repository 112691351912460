import React from 'react';

import { Button, Layout, Text } from '~/eds';
import { FormField, StaticField as StaticFieldType } from '~/types';

type Props = {
  formField: FormField<StaticFieldType>;
  onExplain?: (fieldId: string) => void;
};

export const StaticField = ({ formField, onExplain }: Props) => {
  return (
    <Layout direction="column" spacing={2}>
      <Layout direction="column">
        <Layout spacing="1">
          <Text variant="body-medium">{formField.title}</Text>
          {!formField.required && (
            <Text variant="tiny" color="text.secondary">
              (optional)
            </Text>
          )}
          {onExplain && (
            <Button
              text="Explain this"
              variant="action"
              onClick={() => onExplain(formField.fieldName)}
            />
          )}
        </Layout>
        <Text color="text.secondary">{formField.description}</Text>
      </Layout>

      {formField.field.value.map((staticField, index) => (
        <Layout direction="column" key={`static_field_${index}`}>
          <Text variant="tiny" color="text.secondary">
            {staticField.title}
          </Text>
          <Text variant="body-medium">{staticField.value}</Text>
        </Layout>
      ))}
    </Layout>
  );
};
