import React from 'react';
import { SingleValueProps } from 'react-select';

import { Option } from '../../../types';
import { TruncateText } from '../../Text';

export const SingleValue = (props: SingleValueProps<Option>) => {
  const { selectProps } = props;
  const { enableValueContainer, isDisabled } = selectProps;

  if (!enableValueContainer) {
    return null;
  }

  return (
    <TruncateText
      color={isDisabled ? 'text.quiet' : 'text.primary'}
      position="absolute"
      w="100%"
    >
      {props.children}
    </TruncateText>
  );
};
