import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import { deleteCrmIntegration, getCrmIntegrationLoginUrl } from '~/api/crms';
import logos from '~/components/Shared/ProviderLogo/logos';
import { crmIntegrations } from '~/constants/integrations';
import { Button } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { useAsync, useHasFeatureFlag } from '~/hooks';
import { useRouting } from '~/routing';
import { Card, FlexLayout, Link, Text } from '~/ui';

import IntegrationDetailCard from '../../IntegrationDetailCard';
import ContactIntegrationMessage from '../ContactIntegrationMessage';
import StatusAndActions from './StatusAndActions';

function CrmIntegrationCard({
  disabled = false,
  crmIntegration,
  isRefetching,
  onRefetch,
}) {
  const { navigate, params } = useRouting();
  const [draft, setDraft] = useState(crmIntegration);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { provider: type } = crmIntegration;
  const { name, provider, title } = crmIntegrations[type];
  const logoSrc = logos[provider];

  const hasApiIntegration = useHasFeatureFlag(FeatureFlagType.ExternalApi);

  useEffect(() => {
    setDraft(crmIntegration);
  }, [crmIntegration]);

  const { executor: fetchIntegrationLoginUrl } = useAsync(
    getCrmIntegrationLoginUrl,
    {
      integrationUrl: window.location.href,
    },
    {
      errorToastMessage:
        'Unable to fetch CRM integration login url, please try again.',
      successHandler: ({ redirectUrl }) => (window.location.href = redirectUrl),
    },
  );

  const {
    executor: executeDeleteIntegration,
    isLoading: isDeleting,
  } = useAsync(deleteCrmIntegration, crmIntegration, {
    errorToastMessage: 'Unable to delete CRM integration, please try again',
    successHandler: onRefetch,
  });

  const isUnchanged = isEqual(draft, crmIntegration);
  const isLoading = isRefetching || isDeleting;

  function handleClose() {
    setIsCollapsed(true);
  }

  function handleConnect() {
    setIsCollapsed(false);
  }

  const headerRightContent = (
    <StatusAndActions
      disabled={disabled}
      integration={crmIntegration}
      isActive={!isCollapsed}
      isLoading={isLoading}
      isPending={!isUnchanged}
      onConnect={handleConnect}
      onDelete={executeDeleteIntegration}
      onToggleCollapse={() => setIsCollapsed(!isCollapsed)}
    />
  );

  const footer = (
    <Card.Footer
      actionButtons={[
        <Button
          key="Close"
          disabled={isLoading}
          text="Close"
          onClick={handleClose}
          variant="tertiary"
        />,
      ]}
    />
  );

  const disabledFeatureDescription = (
    <FlexLayout flexDirection="column" space={6}>
      <Text color="gray-800" variant="s-dense">
        Combine Evisort and Salesforce to allow legal and sales teams to
        collaborate more efficiently and effectively, creating true visibility
        into your contracts and providing key data to your sales team.
      </Text>
      <Text color="gray-800" variant="s-dense">
        Evisort enables a two-way integration that:
      </Text>
      <Text color="gray-800" variant="s-dense">
        (1) allows Salesforce users to initiate contract requests and monitor
        their statuses from Salesforce,
      </Text>
      <Text color="gray-800" variant="s-dense">
        (2) automatically ingests and analyzes contracts from Salesforce;
        extracted contract clauses, fields, and metadata can be pulled back to
        Salesforce for analysis, dashboarding, and alerts.
      </Text>
    </FlexLayout>
  );

  return (
    <IntegrationDetailCard
      isCollapsed={isCollapsed}
      footer={footer}
      headerRightContent={headerRightContent}
      logoSrc={logoSrc}
      title={title || name}
      onToggleCollapse={setIsCollapsed}
    >
      <FlexLayout alignItems="stretch" flexDirection="row" space={6}>
        {hasApiIntegration && (
          <FlexLayout flexDirection="column" space={8}>
            <Text color="gray-800" variant="s-dense" isReadable>
              Part 1: Generate an Evisort API Key to use in your Salesforce
              configuration (configuration guide can be found&nbsp;
              <Link href="https://support.evisort.com/hc/en-us/articles/360060522013-Salesforce-Integration-How-To-Install-the-Evisort-Salesforce-Package-from-the-AppExchange">
                here
              </Link>
              ).&nbsp;
              <Link
                onClick={() =>
                  navigate(
                    `/admin/console/${params.clientId}/integrations?tab=api-management`,
                  )
                }
              >
                Go to Evisort API Management
              </Link>
              .
            </Text>
            <Text color="gray-800" variant="s-dense" isReadable>
              Part 2: Log into your Salesforce account to authenticate and
              enable the integration.&nbsp;
              <Link onClick={fetchIntegrationLoginUrl}>
                Authenticate your account
              </Link>
              .
            </Text>
          </FlexLayout>
        )}
        {!hasApiIntegration && (
          <ContactIntegrationMessage
            description={disabledFeatureDescription}
            enableIcon={true}
            isUnpurchased={true}
          />
        )}
      </FlexLayout>
    </IntegrationDetailCard>
  );
}

export default CrmIntegrationCard;
