export const SPACE_VALUE = 4;

const DEPRECATED_space = Array(80)
  .fill(0)
  .map((_, i) => `${i * SPACE_VALUE}px`)
  .reduce((acc, space, i) => {
    acc[i] = space;
    return acc;
  }, {});

// Spec: https://www.figma.com/file/zf5OLlrDvg80ifs880Eeo1/Evisort-Design-System?node-id=4751%3A526
export default {
  x1: '4px',
  x2: '8px',
  x3: '12px',
  x4: '16px',
  x6: '24px',
  x8: '32px',
  x12: '48px',
  x16: '64px',
  x20: '80px',
  x24: '96px',
  ...DEPRECATED_space,
};
