import PropTypes from 'prop-types';
import React from 'react';

import { Card, FlexLayout, Text } from '~/ui';
import { toSnakeTrimmed } from '~/utils/strings';

function Header({ id, rightContent, title }) {
  var headerTitle = (
    <Text id={id} variant="l-dense-bold">
      {title}
    </Text>
  );

  return (
    <Card.Header id={id} rightContent={rightContent} title={headerTitle} />
  );
}

function SignStageItems({ disabled, items }) {
  return (
    <FlexLayout flexDirection="column" space={6}>
      {items.map((item) => {
        const { id, title, content } = item;
        const titleName = toSnakeTrimmed(title);

        return (
          <Card
            key={id}
            header={
              <Header
                disabled={disabled}
                id={`workflow-form-section-name=${titleName}-title-edit`}
                title={title}
              />
            }
            onToggleCollapse={() => {}}
          >
            {content}
          </Card>
        );
      })}
    </FlexLayout>
  );
}

SignStageItems.propTypes = {
  /** Array of item objects */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Item id */
      id: PropTypes.string,
      /** Item title text */
      title: PropTypes.string,
      /** Item content */
      content: PropTypes.node,
    }).isRequired,
  ),
};

export default SignStageItems;
