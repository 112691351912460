import { Modal, Text, useToast } from '~/eds';
import { api } from '~/redux';

import { ExtendedPartyModalProps } from '../types';

export const DeletePartyModal = ({
  onHide,
  party,
  clientId,
}: ExtendedPartyModalProps) => {
  const { toast } = useToast();

  const [deleteParty, { isLoading }] = api.endpoints.deleteParty.useMutation();

  const children = (
    <Text>
      Are you sure you want to delete {party.name}? You won’t be able to undo
      this action.
    </Text>
  );
  return (
    <Modal
      children={children}
      enableHideOnPrimaryAction={true}
      isVisible={true}
      primaryAction={{
        isLoading,
        text: 'Delete',
        onClick: () => {
          deleteParty({
            isInternal: party.isInternal,
            party,
            clientId,
          })
            .then(() => {
              toast({
                message: `Party "${party.name}" has been removed from the list.`,
                status: 'success',
              });
            })
            .catch((error) => {
              toast({
                message: `${error.response.data.detail}`,
                status: 'danger',
              });
            });
          onHide();
        },
        variant: 'danger',
      }}
      onHide={onHide}
      title="Delete Selected Party?"
    />
  );
};
