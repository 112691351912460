import { fetchSearchExportOptions, PilotSearchQuery } from '../methods';

type ExportOptionsParams = {
  query: PilotSearchQuery;
};

export const getSearchExportOptions = async ({
  query,
}: ExportOptionsParams) => {
  const response = await fetchSearchExportOptions(query);
  return response.data.attributes;
};
