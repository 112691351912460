import { black2 } from '../../../assets/shared-styles/general';

const styles = {
  modalBody: {
    padding: '16px',
    color: black2,
    fontSize: '15px',
    lineHeight: '1.47',
  },
  modalInfo: {
    margin: '0 0 24px 0',
  },
};

export default styles;
