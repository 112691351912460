// keep in sync with everest/persistence/enums.py
export enum WorkflowTicketRoleType {
  Assignee = 'assignee',
  Commenter = 'commenter',
  Coordinator = 'coordinator',
  Creator = 'creator',
  FullAccess = 'full access',
  Mentioned = 'mentioned',
  Participant = 'participant',
}
