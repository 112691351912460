import { createPortal } from 'react-dom';

interface Props {
  children: React.ReactNode;
}

export const Portal = ({ children }: Props) => {
  return typeof document === 'object'
    ? createPortal(children, document.body)
    : null;
};
