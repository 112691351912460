import { noop } from 'lodash';
import React from 'react';

import { FormField, Select } from '~/eds';
import {
  FormField as FormFieldType,
  MultiSelectField as MultiSelectFieldType,
} from '~/types';

type Option = { label: string; value: string };

type Props = {
  formField: FormFieldType<MultiSelectFieldType>;
  errors?: string[];
  onExplain?: (fieldId: string) => void;
  onChange: (value: Option[]) => void;
};

export const MultiSelectField = ({
  formField,
  errors = [],
  onExplain,
  onChange,
}: Props) => {
  return (
    <FormField
      onExplain={onExplain ? () => onExplain(formField.fieldName) : undefined}
      input={Select}
      inputProps={{
        options: formField.field?.options ?? [],
        isMulti: true,
        onAddOption: noop,
      }}
      disabled={formField.disabled}
      name={formField.fieldName}
      value={(formField?.field?.value ?? []).map((val) => val.value)}
      onChange={(value) =>
        onChange((value as string[]).map((val) => ({ label: val, value: val })))
      }
      label={formField.title}
      description={formField.description}
      error={errors.join(', ')}
      required={formField.required}
    />
  );
};
