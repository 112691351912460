import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import { Box, CharacterLimit, FlexLayout, Text } from '~/ui';

import styles from './EcTextarea.styles';

function EcTextarea({
  classes,
  disabled,
  limit,
  placeholder,
  value,
  onChange,
  errorMessage,
  ...rest
}) {
  // TODO: unify and formalize error/validation logic for all input components. error = false,
  return (
    <Box sx={{ width: '100%' }}>
      <textarea
        className={classes.ecTextarea}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...rest}
      />
      <FlexLayout justifyContent="flex-end">
        {limit && <CharacterLimit limit={limit} value={value} />}
      </FlexLayout>
      {errorMessage && (
        <Text mt={2} color="red-600" variant="2xs-dense">
          {errorMessage}
        </Text>
      )}
    </Box>
  );
}

EcTextarea.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  limit: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default injectSheet(styles)(EcTextarea);
