import { setStorageItem } from '~/utils/browser';
import { toSnakeTrimmed } from '~/utils/strings';

export function getTableColumnWidthName(tableName, column) {
  const columnName = toSnakeTrimmed(column);
  return `${tableName}_table_${columnName}_column_width`;
}

export function persistTableColumnWidth(columnResizing, tableName) {
  if (columnResizing.isResizingColumn === null) {
    // null means user stopped dragging
    const tableNameTitle = toSnakeTrimmed(tableName);
    const columnWidthArray = Object.entries(columnResizing.columnWidths).map(
      ([key, value]) => {
        return { key, value };
      },
    );
    columnWidthArray.forEach((item) =>
      setStorageItem(
        getTableColumnWidthName(tableNameTitle, item.key),
        item.value,
      ),
    );
  }
}
