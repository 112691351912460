import PropTypes from 'prop-types';
import React from 'react';

function checkPermissions(userPermissions, requiredPermission) {
  return userPermissions.includes(requiredPermission);
}

function AccessControl({
  userPermissions = [],
  requiredPermission,
  renderNoAccess = () => null,
  children,
}) {
  const permitted = checkPermissions(userPermissions, requiredPermission);

  if (!permitted) {
    return renderNoAccess();
  }

  return <>{children}</>;
}

AccessControl.propTypes = {
  userPermissions: PropTypes.array.isRequired,
  requiredPermission: PropTypes.string.isRequired,
  renderNoAccess: PropTypes.func,
};

export default AccessControl;
