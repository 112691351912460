import { sylvanus } from '~/services';
import { Uuid } from '~/types';

interface Request {
  modelId: Uuid;
}

type Response = void;

export const updatePromptModelProgress = async ({
  modelId,
}: Request): Promise<boolean> => {
  const response: Response = await sylvanus.patch(`/model/${modelId}/progress`);

  return Boolean(response);
};
