import { useEffect } from 'react';

import { DEFAULT_POLL_INTERVAL_MS } from '~/eds';
import { usePollingRequest } from '~/hooks';
import { api } from '~/redux';
import { Uuid } from '~/types';

import { testIsTestCaseEvaluating } from '../fields/utils';
import { PromptModelTestCase } from '../types';

export const usePollPromptModelTestCases = ({
  testCases,
  modelId,
  version,
  onUpdateTestCases,
}: {
  testCases: PromptModelTestCase[];
  modelId: Uuid;
  version: number;
  onUpdateTestCases: (testCases: PromptModelTestCase[]) => void;
}) => {
  const isEvaluatingTestCases = testCases.some(testIsTestCaseEvaluating);

  const { data: updatedTestCases } = usePollingRequest(
    api.endpoints.getPromptModelTestCases.useQuery,
    {
      modelId,
      version,
    },
    {
      skip: !isEvaluatingTestCases,
      pollingInterval: DEFAULT_POLL_INTERVAL_MS,
      maxRetries: 100,
    },
  );

  useEffect(() => {
    if (updatedTestCases) {
      onUpdateTestCases(updatedTestCases);
    }
  }, [modelId, version, updatedTestCases]);
};
