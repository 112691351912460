import { Operator, OperatorId } from './types';

export const operators: Record<OperatorId, Operator> = {
  // folder search
  in_folder: {
    id: 'in_folder',
    cardinality: Number.POSITIVE_INFINITY,
  },
  // blank operators
  is_blank: {
    id: 'is_blank',
    cardinality: 0,
  },
  is_not_blank: {
    id: 'is_not_blank',
    cardinality: 0,
  },
  // boolean operators
  is_true: {
    id: 'is_true',
    cardinality: 0,
  },
  is_false: {
    id: 'is_false',
    cardinality: 0,
  },
  // equality operators
  equals: {
    id: 'equals',
    cardinality: 1,
  },
  not_equals: {
    id: 'not_equals',
    cardinality: 1,
  },
  // numeric operators
  between: {
    id: 'between',
    cardinality: 2,
  },
  greater_than: {
    id: 'greater_than',
    cardinality: 1,
  },
  greater_than_equals: {
    id: 'greater_than_equals',
    cardinality: 1,
  },
  less_than: {
    id: 'less_than',
    cardinality: 1,
  },
  less_than_equals: {
    id: 'less_than_equals',
    cardinality: 1,
  },
  // multivalue operators
  contains_any: {
    id: 'contains_any',
    cardinality: Number.POSITIVE_INFINITY,
  },
  contains_all: {
    id: 'contains_all',
    cardinality: Number.POSITIVE_INFINITY,
  },
  not_contains_any: {
    id: 'not_contains_any',
    cardinality: Number.POSITIVE_INFINITY,
  },
  // date operators
  date_before: {
    id: 'date_before',
    cardinality: 1,
  },
  date_on: {
    id: 'date_on',
    cardinality: 1,
  },
  date_after: {
    id: 'date_after',
    cardinality: 1,
  },
  date_between: {
    id: 'date_between',
    cardinality: 2,
  },
  date_in_the_last: {
    id: 'date_in_the_last',
    cardinality: 1,
  },
  date_in_the_next: {
    id: 'date_in_the_next',
    cardinality: 1,
  },
  // text operators
  text_contains_any: {
    id: 'text_contains_any',
    cardinality: 1,
  },
  text_not_contains_any: {
    id: 'text_not_contains_any',
    cardinality: 1,
  },
  // text search operators
  text_contains_all_of_these_words: {
    id: 'text_contains_all_of_these_words',
    cardinality: 1,
  },
  text_contains_any_of_these_words: {
    id: 'text_contains_any_of_these_words',
    cardinality: 1,
  },
  text_contains_exact_phrase: {
    id: 'text_contains_exact_phrase',
    cardinality: 1,
  },
  text_not_contains_all_of_these_words: {
    id: 'text_not_contains_all_of_these_words',
    cardinality: 1,
  },
  text_not_contains_any_of_these_words: {
    id: 'text_not_contains_any_of_these_words',
    cardinality: 1,
  },
  text_not_contains_exact_phrase: {
    id: 'text_not_contains_exact_phrase',
    cardinality: 1,
  },
};
