import { black1, black2 } from '../../../../assets/shared-styles/general';

const styles = {
  ticketApprovalItemWrapper: {
    position: 'relative',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
  approvalItemStatus: {
    padding: '11px 0',
  },
  disabledReminderTooltip: {
    borderRadius: '4px',
    padding: '8px 12px',
    backgroundColor: black1,
    color: '#fff',
    fontSize: '12px',
  },
  horizontalSeparator: {
    width: '16px',
  },
  note: {
    borderRadius: '0 4px 4px 4px',
    marginTop: '11px',
    marginBottom: '5px',
    padding: '12px 16px',
    position: 'relative',
    backgroundColor: '#f3f3f3',
    color: black2,
    fontSize: '15px',
    lineHeight: 1.47,
    '&::before': {
      content: '""',
      display: 'block',
      borderTop: '8px solid transparent',
      borderBottom: '8px solid #f3f3f3',
      borderLeft: '8px solid #f3f3f3',
      borderRight: '8px solid transparent',
      position: 'absolute',
      top: '-8px',
      left: 0,
    },
  },
};

export default styles;
