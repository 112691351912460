export default `
<svg
  aria-hidden="true"
  aria-label="not-visible"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M6.703 6.8905C8.10925 5.828 9.85925 4.98425 12.0155 4.98425C14.5155 4.98425 16.5468 6.1405 18.0155 7.5155C19.4843 8.85925 20.453 10.4843 20.9218 11.6093C21.0155 11.8593 21.0155 12.1405 20.9218 12.3905C20.5155 13.3905 19.6718 14.828 18.4218 16.078L21.703 18.6718C22.0468 18.9218 22.1093 19.3905 21.828 19.703C21.578 20.0468 21.1093 20.1093 20.7968 19.828L2.29675 5.328C1.953 5.078 1.8905 4.60925 2.17175 4.29675C2.42175 3.953 2.8905 3.8905 3.203 4.17175L6.703 6.8905ZM7.92175 7.85925L9.35925 8.98425C10.078 8.35925 10.9843 7.98425 12.0155 7.98425C14.203 7.98425 16.0155 9.79675 16.0155 11.9843C16.0155 12.6718 15.828 13.2968 15.5468 13.828L17.2343 15.1405C18.2968 14.078 19.0468 12.8593 19.453 11.9843C19.0155 11.0468 18.203 9.73425 16.9843 8.60925C15.703 7.42175 14.0468 6.48425 12.0155 6.48425C10.4218 6.48425 9.04675 7.04675 7.92175 7.85925ZM14.328 12.8905C14.453 12.6093 14.5155 12.2968 14.5155 11.9843C14.5155 10.6093 13.3905 9.48425 12.0155 9.48425C11.9843 9.48425 11.953 9.48425 11.9218 9.48425C11.9843 9.67175 12.0155 9.828 12.0155 9.98425C12.0155 10.328 11.9218 10.6093 11.7968 10.8905L14.328 12.8905ZM14.6405 16.953L15.953 17.9843C14.7968 18.6093 13.4843 18.9843 12.0155 18.9843C9.48425 18.9843 7.453 17.8593 5.98425 16.4843C4.5155 15.1093 3.54675 13.4843 3.078 12.3905C2.98425 12.1405 2.98425 11.8593 3.078 11.6093C3.3905 10.8905 3.8905 9.98425 4.60925 9.04675L5.7655 9.98425C5.203 10.703 4.79675 11.4218 4.54675 11.9843C4.98425 12.9218 5.79675 14.2655 7.0155 15.3905C8.29675 16.578 9.953 17.4843 12.0155 17.4843C12.953 17.4843 13.828 17.2968 14.6405 16.953ZM8.0155 11.9843C8.0155 11.9218 8.0155 11.828 8.0155 11.7343L9.7655 13.1093C10.078 13.7655 10.703 14.2655 11.453 14.4218L13.203 15.828C12.828 15.9218 12.4218 15.9843 11.9843 15.9843C9.79675 15.9843 7.98425 14.203 7.98425 11.9843H8.0155Z"
    fill="currentcolor"
  />
</svg>
`;
