import React from 'react';

import { Icon, IconButton, Layout } from '~/eds';
import { Text } from '~/ui';
import { HandlerName } from '~/utils/table';

const RestrictedName = () => {
  return (
    <>
      <Icon icon="file" label="File" />
      <Text>Restricted File</Text>
    </>
  );
};

export const NameCell = ({
  document,
  openTarget = null,
  onClick,
  onCollapse,
  onExpand,
  indent,
  isExpanded,
}) => {
  const isRestricted = !document.document_id;
  const hasChildren = !!document.number_of_children;
  const toggle = isExpanded ? onCollapse : onExpand;

  return (
    <Layout
      pl={(indent - 1) * 40}
      spacing={4}
      styles={{ maxWidth: '100%' }}
      flex="1 0"
    >
      {hasChildren && (
        <IconButton
          text="Toggle collapse"
          icon={isExpanded ? 'caret-down' : 'caret-right'}
          onClick={() => toggle(document)}
        />
      )}
      <Layout
        align="center"
        spacing={1}
        pl={hasChildren ? 0 : 40}
        styles={{ maxWidth: 'calc(100% - 40px)' }}
      >
        {isRestricted ? (
          <RestrictedName />
        ) : (
          <HandlerName
            handlers={document.handlers}
            openTarget={openTarget}
            onClick={() => onClick(document)}
          />
        )}
      </Layout>
    </Layout>
  );
};
