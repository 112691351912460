import React, { memo, useEffect, useState } from 'react';

import {
  DEFAULT_PLACEHOLDER_LABEL,
  ONE_MINUTE_IN_MILLISECONDS as ONE_MINUTE_INTERVAL,
} from '../../constants';
import { DateFormat, TextVariant } from '../../types';
import { formatDate } from '../../utils';
import { Text } from '../Text';

interface Props {
  /** JS Date */
  date?: Date;
  /** Date format */
  format?: DateFormat;
  /** Text variant */
  variant?: TextVariant;
  /** Enable tick */
  enableTick?: boolean;
}

export const DateText = memo(
  ({
    date: initialDate,
    format,
    variant = 'body',
    enableTick = false,
  }: Props) => {
    const [date, setDate] = useState(initialDate);

    useEffect(() => setDate(initialDate), [initialDate]);

    useEffect(() => {
      if (enableTick) {
        const intervalId = setInterval(() => {
          setDate(initialDate);
        }, ONE_MINUTE_INTERVAL);

        return () => clearInterval(intervalId);
      }
    }, [enableTick]);

    return (
      <Text color="unset" variant={variant}>
        {date ? formatDate(date, format) : DEFAULT_PLACEHOLDER_LABEL}
      </Text>
    );
  },
);
