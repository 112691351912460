import PropTypes from 'prop-types';
import React from 'react';

import { TicketStatusType } from '~/enums';
import { FlexLayout } from '~/ui';

import StatusIcon from './StatusIcon';
import UserAvatarWithStatusIcon from './UserAvatarWithStatusIcon';

function ApprovalSummary({ approvals = [], statusOnly = false }) {
  if (approvals.length === 0) {
    return null;
  }

  return (
    <FlexLayout flexWrap="wrap" space={2}>
      {approvals.map(({ id, status, userId }) => {
        if (statusOnly) {
          return <StatusIcon key={id} status={status} />;
        }

        return (
          <UserAvatarWithStatusIcon key={id} status={status} userId={userId} />
        );
      })}
    </FlexLayout>
  );
}

const approvalPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(TicketStatusType)).isRequired,
  userId: PropTypes.number.isRequired,
});

ApprovalSummary.propTypes = {
  approvals: PropTypes.arrayOf(approvalPropTypes.isRequired),
  statusOnly: PropTypes.bool,
};

export default ApprovalSummary;
