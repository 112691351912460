import React from 'react';

import { SharedUserProps } from '../../types';
import { UserMode, UserOptions } from './types';
import { UserAvatar } from './UserAvatar';
import { UserAvatarName } from './UserAvatarName';
import { UserInfo } from './UserInfo';
import { UserName } from './UserName';

export const User = <M extends UserMode>({
  options,
  user,
  mode,
  placeholder,
}: SharedUserProps<M>) => {
  switch (mode) {
    case 'avatar':
      return (
        <UserAvatar
          options={options as UserOptions<'avatar'>}
          user={user}
          placeholder={placeholder}
        />
      );
    case 'info':
      return (
        <UserInfo
          options={options as UserOptions<'info'>}
          user={user}
          placeholder={placeholder}
        />
      );
    case 'avatar-name':
      return (
        <UserAvatarName
          options={options as UserOptions<'avatar-name'>}
          user={user}
          placeholder={placeholder}
        />
      );
    case 'name':
    default:
      return (
        <UserName
          mode={mode}
          options={options as UserOptions<'name'>}
          user={user}
          placeholder={placeholder}
        />
      );
  }
};
