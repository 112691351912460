/**
 * Helper color tokens to adjust brightness (darken/lighten) through the use of `rgba(black|white, alpha)`.
 *
 * Commonly applied through the use of the `backgroundImage: linear-gradient(alpha, 0, 0)` CSS rule.
 */

const darken = (scale: number) => `rgba(0, 0, 0, ${scale})`;

const lighten = (scale: number) => `rgba(255, 255, 255, ${scale})`;

export const alphas = {
  'darken-3': darken(0.03),
  'darken-5': darken(0.05),
  'darken-10': darken(0.1),
  'darken-15': darken(0.15),
  'darken-20': darken(0.2),
  'darken-30': darken(0.3),
  'darken-40': darken(0.4),
  'darken-50': darken(0.5),
  'darken-60': darken(0.6),
  'darken-70': darken(0.7),
  'darken-80': darken(0.8),
  'darken-90': darken(0.9),
  'lighten-3': lighten(0.03),
  'lighten-5': lighten(0.05),
  'lighten-10': lighten(0.1),
  'lighten-15': lighten(0.15),
  'lighten-20': lighten(0.2),
  'lighten-30': lighten(0.3),
  'lighten-40': lighten(0.4),
  'lighten-50': lighten(0.5),
  'lighten-60': lighten(0.6),
  'lighten-70': lighten(0.7),
  'lighten-80': lighten(0.8),
  'lighten-90': lighten(0.9),
  'lighten-92': lighten(0.92),
};
