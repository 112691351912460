import { cloneDeep, debounce, keyBy, noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { flattenFolderTree } from '~/components/SearchV2/SearchV2.utils';
import { FOLDER_SEARCH_VISIBLE_RESULTS } from '~/constants/max_lengths';
import {
  Box,
  Chip,
  ContentContainer,
  FolderSelect,
  Layout,
  types,
} from '~/eds';
import { withTreeNodeKeyDownHandler } from '~/hocs';
import { api } from '~/redux';
import { FlattenFolderTree, FolderTree } from '~/types';
import { ERROR } from '~/types/toast.types';
import { TreeNode } from '~/utils/TreeNode';

import EcFolderTreeItem from '../EcFolderTreeItem';
import { showToast } from '../EcToast';

type Props = {
  folderPathId?: number;
  isRootFolder: boolean;
  onSelect: (folder: types.Folder) => void;
  disableSyncPaired?: boolean;
  selectedFolder?: types.Folder;
  shouldDisable?: (folder: FolderTree) => boolean;
  w?: number;
};

const FolderTreeItem = withTreeNodeKeyDownHandler(EcFolderTreeItem);
const FolderPanel = ({
  folderPathId,
  isRootFolder,
  onSelect,
  disableSyncPaired,
  selectedFolder,
  shouldDisable = () => false,
  w,
}: Props) => {
  const [folderSearch, onFolderSearch] = useState<string>('');
  const {
    data: folderTreeData,
    isFetching,
    isError,
  } = api.endpoints.getFolderTree.useQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const folderTree = useMemo(() => {
    return folderTreeData?.id ? new TreeNode(cloneDeep(folderTreeData)) : null;
  }, [folderTreeData]);

  const searchFolders = useCallback(
    (folder: types.Folder) => {
      return (
        folder.name
          .toLocaleLowerCase()
          .indexOf(folderSearch.toLocaleLowerCase()) >= 0
      );
    },
    [folderSearch],
  );

  const flatFolderList = useMemo(() => {
    return folderTreeData
      ? flattenFolderTree([folderTreeData], '', shouldDisable).map(asFolderItem)
      : [];
  }, [folderTreeData, shouldDisable]);

  const folderMap = useMemo(() => {
    return keyBy(flatFolderList, 'id');
  }, [flatFolderList]);

  const folderList = useMemo(() => {
    return folderSearch
      ? flatFolderList
          .filter(searchFolders)
          .slice(0, FOLDER_SEARCH_VISIBLE_RESULTS)
      : [];
  }, [flatFolderList, folderSearch]);

  useEffect(() => {
    if (isError) {
      showToast(ERROR, 'Something went wrong, please try again later.');
    }
  }, [isError]);

  return (
    <Layout h="100%" direction="column" p={1} w={w}>
      <FolderSelect
        // @ts-ignore missing prop mapping
        isClearable={true}
        isEmbedded={true}
        folders={folderList}
        isMulti={false}
        name="folder filter"
        disabled={isFetching || isError}
        noOptionsMessage={folderSearch ? 'No folders match your search' : null}
        onSearch={debounce(onFolderSearch, 300)}
        onChange={(id) => {
          if (id) {
            onSelect(folderMap[String(id)]);
          }
        }}
      />

      <ContentContainer loadingContent={{ isLoading: isFetching }}>
        <Box h="100%" overflowY="auto">
          {!folderSearch && selectedFolder && (
            <Layout display="flex" direction="row" align="center" pl={4} pt={2}>
              <Box pr={2}>Selected folder: </Box>
              <Chip text={selectedFolder.path || selectedFolder.name} />
            </Layout>
          )}
          {folderTree && !folderSearch && (
            <FolderTreeItem
              autoFocus={true}
              firstToOpenId={
                folderPathId ? Number(folderPathId) : selectedFolder?.id
              }
              folderIdsSelected={[]}
              folder={folderTree}
              isRootFolder={isRootFolder}
              isMultiSelect={false}
              setIsSelected={noop}
              getIsSelected={noop}
              onSelect={(folder: FolderTree) =>
                onSelect(folderMap[String(folder.id)])
              }
              disableSyncPaired={disableSyncPaired}
            />
          )}
        </Box>
      </ContentContainer>
    </Layout>
  );
};

const asFolderItem = (folder: FlattenFolderTree): types.Folder => {
  return {
    id: folder.value,
    name: folder.display_value,
    path: folder.path,
    disabled: folder.disabled,
  };
};

export default FolderPanel;
