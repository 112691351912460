import React from 'react';

import { Layout, Section } from '~/eds';
import { FlagType, useFlag } from '~/flags';

import CrmIntegrationCardList from './CrmIntegrationCardList';
import EsignatureIntegrationCardList from './EsignatureIntegrationCardList';
import ProcurementIntegrationCardList from './ProcurementIntegrationCardList';
import StorageIntegrationCardList from './StorageIntegrationCardList';

function AllIntegrations() {
  const aribaIntegration = useFlag(FlagType.AribaIntegrationPhase1);

  const providers = [
    {
      title: 'Electronic Signatures',
      component: <EsignatureIntegrationCardList />,
    },
    { title: 'Sales / CRM', component: <CrmIntegrationCardList /> },
    { title: 'Storage', component: <StorageIntegrationCardList /> },
  ];

  if (aribaIntegration) {
    providers.push({
      title: 'Procurement',
      component: <ProcurementIntegrationCardList />,
    });
  }

  return (
    <Layout preset="sections">
      {providers.map((provider) => (
        <Section
          children={provider.component}
          key={provider.title}
          title={provider.title}
        />
      ))}
    </Layout>
  );
}
export default AllIntegrations;
