import React from 'react';
import { connect } from 'react-redux';

import DocVersionMenu from '~/components/Shared/DocVersionMenu';
import {
  getOptionLabel,
  getVersionLabelText,
  getVersionNumberIds,
} from '~/components/Shared/DocVersionMenu/utils';
import { IconButton, Layout, Text } from '~/eds';
import { useRouting } from '~/routing';

function CurrentDocVersionMenu({ documentVersions, ticketId, ...rest }) {
  const { navigate, params } = useRouting();

  const { versionNumber: versionNumberString } = params;
  const versionNumber = Number(versionNumberString);

  function renderListItem(expanded) {
    const versionsNumberIds = getVersionNumberIds(documentVersions);
    return documentVersions.map((documentVersion) => {
      const isSelected = documentVersion.versionNumber === versionNumber;
      return {
        content: getOptionLabel({
          versionsObject: versionsNumberIds,
          expanded,
          version: documentVersion,
          isSelected,
        }),
        onClick: () =>
          navigate(
            `/workflow/tickets/${ticketId}/reviewer/${documentVersion.id}`,
          ),
      };
    });
  }

  function renderMenuButton() {
    return (
      <Layout preset="buttons">
        <Text>{getVersionLabelText(versionNumber)}</Text>
        <IconButton icon="chevron-down" size="s" />
      </Layout>
    );
  }

  return (
    <DocVersionMenu
      {...rest}
      renderListItem={renderListItem}
      renderMenuButton={() => renderMenuButton()}
      documentVersions={documentVersions}
      ticketId={ticketId}
    />
  );
}

const mapStateToProps = ({ ticket }) => ({
  ticketId: ticket.id,
  documentVersions: ticket.documentVersions,
});

export default connect(mapStateToProps)(CurrentDocVersionMenu);
