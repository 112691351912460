import React from 'react';
import { connect } from 'react-redux';

import { signerFieldDefinitions } from '~/constants/workflow';
import { Icon } from '~/eds';
import { getFieldErrors, getSignerFieldsBySignerId } from '~/reducers/workflow';
import { Collapsible, FlexLayout, PageLayout, Text, useToggle } from '~/ui';
import {
  coerceName,
  testSignerFieldDefinitionRequired,
} from '~/utils/workflow';

import FieldSectionItem from '../FieldSectionItem';
import { getErrorMessage } from '../utils';

const { SidebarSectionItem } = PageLayout;

function MissingDefinition({ disabled, name }) {
  return (
    <SidebarSectionItem
      borderLeftColor="yellow-500"
      disabled={disabled}
      indent={2}
      py={1}
      sx={{ height: 'sidebar-section-item-height' }}
    >
      <FlexLayout
        alignItems="center"
        bg="gray-100"
        flex="1 1 auto"
        ml="-50px"
        pl="46px"
        py={2}
        space={4}
        sx={{ borderRadius: 's' }}
      >
        <Icon
          icon="status-warning"
          color="status.warning"
          label="status warning"
        />
        <Text color="gray-700" variant="xs-dense-medium">
          {name} Missing
        </Text>
      </FlexLayout>
    </SidebarSectionItem>
  );
}

function Signer({
  signer,
  // connected
  disabled,
  fieldLinks,
  signerFields,
  workflowType,
  errors,
  fieldMapping,
}) {
  const [isCollapsed, toggleIsCollapsed] = useToggle(false);

  let Content;
  if (!isCollapsed) {
    Content = (
      <>
        {signerFieldDefinitions.map((definition) => {
          const field = signerFields.find((signerField) => {
            const link = fieldLinks[signerField.id];
            return (
              link &&
              link.id === signer.id &&
              link.entity === definition.entity &&
              link.key === definition.key
            );
          });
          if (field) {
            return (
              <FieldSectionItem
                key={field.id}
                field={field}
                indent={2}
                isAssigned
                errorMessage={getErrorMessage(errors, field.id, fieldMapping)}
              />
            );
          } else if (
            testSignerFieldDefinitionRequired(signer, definition, workflowType)
          ) {
            return (
              <MissingDefinition
                key={definition.key}
                name={definition.label}
                disabled={disabled}
              />
            );
          } else {
            return null;
          }
        })}
      </>
    );
  }

  return (
    <>
      <SidebarSectionItem indent={1} py={0} showStem>
        <Collapsible
          isCollapsed={isCollapsed}
          sx={{ height: 'collapsible-header-height' }}
          title={coerceName(signer.name, 'Signer', signer.index)}
          onToggleCollapse={toggleIsCollapsed}
        />
      </SidebarSectionItem>
      {Content}
    </>
  );
}

function mapStateToProps({ builder, workflow }, { signer }) {
  return {
    disabled: !builder.shouldEnableAllComponents,
    fieldLinks: workflow.fieldLinks,
    signerFields: getSignerFieldsBySignerId(workflow, signer.id),
    workflowType: workflow.type,
    errors: getFieldErrors(workflow, builder),
    fieldMapping: workflow.settings.dataFieldsMapping,
  };
}

export default connect(mapStateToProps)(Signer);
