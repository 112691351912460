// [hue, saturation] pair
type ColorDatum = [number, number];

export const base: Record<string, ColorDatum> = {
  gray: [0, 0],
  lemon: [60, 100],
  yellow: [46, 100],
  peach: [14, 100],
  red: [358, 88],
  plum: [274, 72],
  blue: [220, 100],
  aqua: [184, 100],
  green: [147, 62],
};
