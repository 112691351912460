import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { BULK_DELETE_LIMIT } from '~/constants/max_lengths';
import { Alert, Box, LoadingSpinner } from '~/ui';

import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import styles from './DeleteModal.styles';

class DeleteModal extends Component {
  constructor(props) {
    super(props);

    this.handleDeleteClick = this.handleDeleteClick.bind(this);

    this.state = {
      confirmText: '',
      isMounted: false,
      overServerLimit: false,
    };
  }

  async componentDidMount() {
    const { fetchDocumentsCount, overLimit } = this.props;

    if (fetchDocumentsCount && !overLimit) {
      const { document_count } = await fetchDocumentsCount();
      this.setState({
        isMounted: true,
        overServerLimit: document_count > BULK_DELETE_LIMIT,
      });
    } else {
      this.setState({
        isMounted: true,
      });
    }
  }

  handleDeleteClick() {
    const { deleteItem, hideModal } = this.props;

    deleteItem();
    hideModal();
  }

  handleInputChange(confirmText) {
    this.setState({ confirmText });
  }

  renderInfoComponent() {
    const { classes, infoIcon, infoMessage } = this.props;

    return infoIcon && infoMessage ? (
      <div className={classes.infoComponent}>
        {infoIcon}
        {infoMessage}
      </div>
    ) : null;
  }

  renderLimitWarning() {
    const { overLimit } = this.props;
    const { overServerLimit } = this.state;

    if (overLimit || overServerLimit) {
      return (
        <Box mb={5}>
          <Alert enableIcon variant="warning">
            Only {BULK_DELETE_LIMIT} documents can be deleted at a time. Please
            select fewer documents to complete this action.
          </Alert>
        </Box>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      classes,
      title,
      text,
      confirmButtonIcon,
      confirmButtonText,
      includeConfirmation,
      hideModal,
      confirmationText,
      overLimit,
    } = this.props;
    const { confirmText, isMounted, overServerLimit } = this.state;
    return (
      <EcModalCard
        title={title}
        content={
          <>
            {!isMounted ? (
              <div className={classes.loadingContainer}>
                <LoadingSpinner size="medium" />
              </div>
            ) : (
              <>
                {this.renderLimitWarning()}
                <div>{text}</div>
                {this.renderInfoComponent()}
                {includeConfirmation ? (
                  <div>
                    <input
                      className={classes.modalInput}
                      value={confirmText}
                      onChange={(event) => {
                        this.handleInputChange(event.target.value);
                      }}
                    />
                  </div>
                ) : null}
              </>
            )}
          </>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              red
              iconLeft={confirmButtonIcon}
              text={confirmButtonText}
              disabled={
                overLimit ||
                overServerLimit ||
                (includeConfirmation && confirmText !== confirmationText) ||
                !isMounted
              }
              onClick={this.handleDeleteClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  infoIcon: PropTypes.object,
  infoMessage: PropTypes.object,
  confirmButtonIcon: PropTypes.object,
  confirmButtonText: PropTypes.string.isRequired,
  deleteItem: PropTypes.func.isRequired,
  overLimit: PropTypes.bool,
  fetchDocumentsCount: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
  includeConfirmation: PropTypes.bool,
};

export default injectSheet(styles)(DeleteModal);
