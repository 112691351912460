import React, { useMemo, useState } from 'react';

import GroupedCheckboxSelect from '~/components/Shared/GroupedCheckboxSelect';
import {
  Box,
  Button,
  Layout,
  Panel,
  SearchInput,
  Text,
  types,
  useThrottledValue,
} from '~/eds';
import { FieldId } from '~/evifields';
import { Nullable, PilotId } from '~/types';

import { presets } from './presets';
import { groupFilterOptions } from './utils';

export type SelectedFilters = Record<string, FieldId[]>;

export type OptionsType = { section: string; id: string; label: string };

const resolvePresetProps = (props: Props) => {
  const { preset } = props;
  const presetProps = preset ? presets[preset] : presets['default'] ?? {};
  return {
    ...presetProps,
    ...props,
  };
};

type Props = {
  buttonIcon?: types.IconType;
  buttonSize?: 'xs' | 's' | 'm' | 'l';
  buttonText?: string;
  buttonVariant?: 'action' | 'secondary';
  collapsible?: boolean;
  ctaText?: string;
  groupsOrder: string[];
  limit?: number;
  pinnedFiltersIds?: Array<PilotId | string>;
  preset?: keyof typeof presets;
  searchFilters: OptionsType[];
  selectedFilters?: SelectedFilters;
  getActionButtonTooltip: (state: { selectedFiltersCount: number }) => string;
  onApply: (ids: string[]) => void;
  onOpenQueryBuilder?: () => void;
  onUpdatePinnedFilters?: (
    updatedPinnedFilters: Array<PilotId | string>,
  ) => void;
  renderHelpText?: (state: {
    selectedFiltersCount: number;
  }) => JSX.Element | null;
  renderLimitHeader?: () => JSX.Element;
};

export const ManageFilters = (props: Props) => {
  const {
    buttonText,
    buttonIcon,
    buttonSize,
    buttonVariant,
    collapsible = false,
    getActionButtonTooltip,
    groupsOrder,
    limit,
    pinnedFiltersIds,
    renderHelpText,
    renderLimitHeader,
    searchFilters,
    selectedFilters = {},
    onApply,
    onOpenQueryBuilder,
    onUpdatePinnedFilters,
  } = resolvePresetProps(props);
  const [search, setSearch] = useState('');
  const [visible, setVisible] = useState(false);
  const [selectedFilterIds, setSelectedFilterIds] = useState<
    Nullable<SelectedFilters>
  >(selectedFilters);

  const selectedFiltersCount = Object.values(selectedFilterIds || {}).flat()
    .length;
  const hasNoFiltersSelected = selectedFiltersCount === 0;
  const hasOverLimitSelected = Boolean(limit && selectedFiltersCount > limit);

  const throttleMs = useMemo(() => searchFilters.length * 2, [searchFilters]);
  const throttledSearch = useThrottledValue(search, throttleMs);

  const handleShowModal = () => {
    setVisible(true);
    setSelectedFilterIds(selectedFilters);
  };

  const handleHide = () => {
    setSearch('');
    setVisible(false);
  };

  const handleSearch = (updatedSearch: Nullable<string>) => {
    setSearch(updatedSearch || '');
  };

  const generateFilterIdsInNewOrder = () => {
    const existingIDs = Object.values(selectedFilters).flat();
    const allIDs = Object.values(selectedFilterIds || {}).flat();
    const newlyAddedIDs = allIDs.filter((item) => !existingIDs.includes(item));
    const remainingIDs = allIDs.filter((item) => !newlyAddedIDs.includes(item));
    return [...newlyAddedIDs, ...remainingIDs];
  };

  const handleSubmit = () => {
    const ids = generateFilterIdsInNewOrder();
    onApply(ids);
    handleHide();
  };

  const checkboxGroups = React.useMemo(() => {
    const groupedFieldOptions = groupFilterOptions({
      searchFilters,
      search,
      groupsOrder,
    });
    const optionsCount = groupedFieldOptions.reduce(
      (acc, group) => acc + group.options.length,
      0,
    );

    if (optionsCount === 0) {
      return <Text preset="description">No matching fields.</Text>;
    }

    return (
      <GroupedCheckboxSelect<FieldId>
        collapsible={collapsible}
        pins={pinnedFiltersIds as string[]}
        search={throttledSearch}
        optionGroups={groupedFieldOptions}
        value={selectedFilterIds}
        onChange={setSelectedFilterIds}
        onUpdatePinnedFilters={onUpdatePinnedFilters}
      />
    );
  }, [searchFilters, selectedFilterIds, throttledSearch, groupsOrder]);

  return (
    <>
      <Button
        iconPosition="left"
        size={buttonSize}
        icon={buttonIcon}
        text={buttonText}
        variant={buttonVariant}
        onClick={handleShowModal}
      />
      {visible && (
        <Panel
          width="l"
          mode="floating"
          hidden={{
            isHidden: !visible,
            onHide: handleHide,
          }}
          title="Filters"
          footer={{
            leftActions: onOpenQueryBuilder
              ? [
                  {
                    level: 'action',
                    text: 'Advanced Query Builder',
                    icon: 'plus-circle',
                    iconPosition: 'left',
                    onClick: onOpenQueryBuilder,
                  },
                ]
              : [],
            actions: [
              {
                text: 'Cancel',
                onClick: handleHide,
              },
              {
                disabled: hasNoFiltersSelected || hasOverLimitSelected,
                text: 'Add Filters',
                level: 'primary',
                tooltip: getActionButtonTooltip({ selectedFiltersCount }),
                onClick: handleSubmit,
              },
            ],
          }}
        >
          <Layout direction="column" h="100%" spacing={6} w="100%">
            {renderLimitHeader?.()}
            <Box flex="none">
              <SearchInput
                autoFocus
                name="fields"
                placeholder="Search by fields…"
                value={search}
                onChange={handleSearch}
              />
            </Box>
            {renderHelpText?.({ selectedFiltersCount })}
            <Box flex="auto" overflowY="auto">
              {checkboxGroups}
            </Box>
          </Layout>
        </Panel>
      )}
    </>
  );
};
