/**
 * Logic in this file is referenced from https://github.com/ianstormtaylor/slate/blob/9892cf0ffbd741cc2880d1f0bd0d7c1b36145bbd/site/examples/richtext.tsx
 */
import { Editor, Element, Transforms } from 'slate';

import { BlockNode, CustomEditor } from '../types';
import { focusEditor } from '../utils';
import { ALIGN_FORMAT_TYPES, LIST_FORMAT_TYPES } from './constants';
import {
  AlignFormat,
  Format,
  FormatType,
  ListFormat,
  MarkFormat,
} from './types';

const getBlockType = (format: Format) =>
  ALIGN_FORMAT_TYPES.includes(format as AlignFormat) ? 'align' : 'type';

export const testIsBlock = (type: FormatType) => type === 'block';

export const testIsMarkActive = (editor: CustomEditor, format: Format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format as MarkFormat] === true : false;
};

export const testIsBlockActive = (editor: CustomEditor, format: Format) => {
  const { selection } = editor;
  if (!selection) {
    return false;
  } else {
    const blockType = getBlockType(format);
    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          (n as BlockNode)[blockType] === format,
      }),
    );
    return !!match;
  }
};

export const testIsFormatActive = (
  editor: CustomEditor,
  format: Format,
  type: FormatType,
) => {
  const isBlock = testIsBlock(type);
  const test = isBlock ? testIsBlockActive : testIsMarkActive;
  return test(editor, format);
};

export const toggleFormat = (
  editor: CustomEditor,
  format: Format,
  type: FormatType,
) => {
  const isBlock = testIsBlock(type);
  const toggle = isBlock ? toggleBlock : toggleMark;
  toggle(editor, format);
  focusEditor(editor);
};

export const toggleMark = (editor: CustomEditor, format: Format) => {
  const isActive = testIsFormatActive(editor, format, 'mark');

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const toggleBlock = (editor: CustomEditor, format: Format) => {
  const isActive = testIsFormatActive(editor, format, 'block');
  const isList = LIST_FORMAT_TYPES.includes(format as ListFormat);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      LIST_FORMAT_TYPES.includes((n as BlockNode).type as ListFormat) &&
      !ALIGN_FORMAT_TYPES.includes(format as AlignFormat),
    split: true,
  });
  let newProperties: Partial<Element>;
  if (ALIGN_FORMAT_TYPES.includes(format as AlignFormat)) {
    newProperties = {
      // @ts-ignore figure typing out (type complexity would exceed comprehension at this point)
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      // @ts-ignore figure typing out (type complexity would exceed comprehension at this point)
      type: isActive ? 'p' : isList ? 'li' : format,
    };
  }
  Transforms.setNodes<Element>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block as BlockNode);
  }
};
