import React from 'react';

import { default as DEPRECATED_ClauseLibrary } from '~/components/ClauseLibrary/index';
import { ClauseLibrary } from '~/features/clause-library';
import { FlagType, useFlag } from '~/flags';
import { withPermissions } from '~/hocs';

const ClauseLibraryEditPermission = {
  resourceId: 'clause_library',
  resourceType: 'edit',
};

const Page = ({
  checkPermission,
}: {
  checkPermission: (resourceId: string, resourceType: string) => boolean;
}) => {
  const hasEditPermission = checkPermission(
    ClauseLibraryEditPermission.resourceId,
    ClauseLibraryEditPermission.resourceType,
  );
  const enableV2 = useFlag(FlagType.ClauseLibraryAiSuggest);
  return enableV2 ? (
    <ClauseLibrary />
  ) : (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <DEPRECATED_ClauseLibrary hasEditPermission={hasEditPermission} />
  );
};

export default withPermissions(Page, [ClauseLibraryEditPermission]);
