import React from 'react';

import { FlexLayout } from '~/ui';

import CrmIntegrations from './CrmIntegrations';
import EmailIntegrations from './EmailIntegrations';
import ESignatureIntegrations from './ESignatureIntegrations';
import StorageIntegrations from './StorageIntegrations';

export default function ThirdPartyIntegrations() {
  return (
    <FlexLayout flexDirection="column" space={10}>
      <ESignatureIntegrations />
      <CrmIntegrations />
      <EmailIntegrations />
      <StorageIntegrations />
    </FlexLayout>
  );
}
