import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { testHasOverflown } from '../../../utils';
import { Text } from '../Text';
import type { Props as TextProps } from '../Text';

/**
 * This implements a truncatable Text component.
 *
 * - The `styles.truncate` is always applied.
 * - We render a tooltip effect through the `HTMLElement`'s `title` attribute.  This is triggered `onMouseEnter` and sets the `element.textContent` as the content for the `title` attribute if the `element` has overflown (i.e. `scrollWidth > offsetWidth`)
 *
 * The implementation has a few advantages:
 * - The tooltip implementation is DOM-based, implemented via an element's `title` attribute.  It is lightweight and has no effect on the DOM layout tree.
 * - By default, no `title` is assigned.  This keeps the DOM tree simple and also prevents screen readers from reading the information twice.
 * - For a disabled person, the existing `Text` markup contains all the information in the element node.
 * - For a non-disabled person, the `title` is triggered only on the `onMouseEnter` event, a low-volume mouse event that will never be triggered by a disabled person.  The tooltip solution is therefore only relevant to a non-disabled person.
 */
export const TruncateText = memo((props: TextProps) => {
  const styles = useStyles();

  const handleMouseEnter = (event: MouseEvent) => {
    const element = event.target as HTMLElement;
    const { textContent } = element;
    element.title = textContent && testHasOverflown(element) ? textContent : '';
  };

  return (
    <Text
      data-eds-styles-disable-interactable
      {...props}
      styles={styles.truncate}
      onMouseEnter={handleMouseEnter}
    />
  );
});
