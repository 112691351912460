import React, { Component, Fragment } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import injectSheet from 'react-jss';

import { getCurrentUser } from '~/api';
import FieldValuesMultiSelect from '~/components/Shared/FieldValuesMultiSelect';
import FieldValuesSingleSelect from '~/components/Shared/FieldValuesSingleSelect';
import { EM_DASH } from '~/constants/unicode';
import { formatDate, Icon, parseDate } from '~/eds';
import { OnboardingIdType } from '~/features/onboarding';
import { withFlags } from '~/flags';
import { getFields } from '~/redux/api/methods';
import { filterValidFiles } from '~/utils/helper.utils';

import { black5, evisortBlue } from '../../../assets/shared-styles/general';
import { MODAL_FOLDER_TREE } from '../../../types/modal.types';
import {
  booleanOptions,
  preprocessEditData,
} from '../../DocumentsViewPage/DocumentOverviewPanel/DocumentOverviewPanel.utils';
import EcButton from '../../Shared/EcButton';
import EcDatepicker from '../../Shared/EcDatepicker';
import EcInput from '../../Shared/EcInput';
import EcModalCard from '../../Shared/EcModalCard';
import EcMultipleSelectValue from '../../Shared/EcMultipleSelectValue';
import EcSelect from '../../Shared/EcSelect';
import ChevronRightIcon from '../../Shared/Icons/ChevronRightIcon';
import CloseIcon from '../../Shared/Icons/CloseIcon';
import DocumentStrokedIcon from '../../Shared/Icons/DocumentStrokedIcon';
import FolderIcon from '../../Shared/Icons/FolderIcon';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import PlusIcon from '../../Shared/Icons/PlusIcon';
import SubArrowIcon from '../../Shared/Icons/SubArrowIcon';
import UploadSize from '../../UploadPage/UploadSize';
import styles from './UploadModal.styles';

class UploadModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnUploadDocumentsClick = this.handleOnUploadDocumentsClick.bind(
      this,
    );
    this.handleChangeFormField = this.handleChangeFormField.bind(this);
    this.state = { formData: {} };
  }
  componentDidMount() {
    this.setState({ loading: true });
    getCurrentUser()
      .then((res) => {
        this.setState({
          errorLoading: false,
          uploadFormFields: res.client_config.upload_form_fields,
        });

        const selectedFieldsIds = res.client_config.upload_form_fields.map(
          (item) => item.id,
        );

        getFields({ ids: selectedFieldsIds }).then((response) => {
          const helpText = response.results.reduce((acc, item) => {
            acc[item.id] = item.helpText;
            return acc;
          }, {});

          this.setState({ helpText });
        });
      })
      .catch((error) => {
        if (error && error.response && error.response.status !== 401) {
          this.setState({ errorLoading: true });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  handleOnUploadDocumentsClick() {
    const { formData } = this.state;
    const processedFields = preprocessEditData(formData).filter(
      (field) => field.value && field.value.length,
    );
    this.props.handleUploadFiles(processedFields);
    this.props.hideModal();
  }

  handleChangeFormField(id, value) {
    const data = { [id]: value };
    this.setState({ formData: { ...this.state.formData, ...data } });
  }

  onCalendarOpenClose = (open) => {
    this.props.disableEscapeKey(open);
  };

  renderSelectedFolderPath() {
    const { classes, uploadLocationPath = [] } = this.props;
    let path = [];

    if (!Array.isArray(uploadLocationPath)) {
      path = uploadLocationPath.split('/').map((folder, index) => ({
        id: index,
        name: folder,
      }));
    } else {
      path = uploadLocationPath;
    }
    return path.length ? (
      <div className={classes.folderPath}>
        {path
          .map((pathChunk) => <span key={pathChunk.id}>{pathChunk.name}</span>)
          .reduce((prev, curr) => [
            prev,
            <ChevronRightIcon size="16" key={curr} />,
            curr,
          ])}
      </div>
    ) : null;
  }

  renderBooleanItem(item) {
    const { classes } = this.props;

    return (
      <div className={classes.fieldContainer}>
        <EcSelect
          options={booleanOptions}
          onChange={(newValue) => {
            const changeValue = newValue ? newValue.value : null;
            this.handleChangeFormField(item.id, changeValue);
          }}
          isClearable={true}
          isSearchable={false}
        />
      </div>
    );
  }

  renderDateItem(item) {
    const { classes } = this.props;
    const dateField = this.state.formData[item.id];

    return (
      <div className={classes.fieldContainer}>
        <EcDatepicker
          date={dateField ? parseDate(dateField) : null}
          isInModal={true}
          onDateChange={(date) => {
            this.setState({
              formData: {
                ...this.state.formData,
                [item.id]: date ? formatDate(date) : null,
              },
            });
          }}
          onCalendarOpenClose={this.onCalendarOpenClose}
          isClearable={true}
          placeholder="Select date"
        />
      </div>
    );
  }

  renderNumberItem(item) {
    const { classes } = this.props;
    const field = this.state.formData[item.id];

    return (
      <div className={classes.fieldContainer}>
        <EcInput
          input={{
            value: field || '',
            onChange: (event) =>
              this.handleChangeFormField(item.id, event.target.value),
          }}
          placeholder={`enter ${item.label}`}
          type="number"
          width="100%"
        />
      </div>
    );
  }

  renderStringItem(item) {
    const { classes } = this.props;
    const field = this.state.formData[item.id];

    return (
      <div className={classes.fieldContainer}>
        <EcInput
          input={{
            value: field || '',
            onChange: (event) =>
              this.handleChangeFormField(item.id, event.target.value),
          }}
          placeholder={`enter ${item.label}`}
          width="100%"
        />
      </div>
    );
  }

  renderTextAreaItem(item) {
    const { classes } = this.props;
    const presentValue = this.state.formData[item.id] || '';

    return (
      <div className={classes.fieldContainer}>
        <textarea
          value={presentValue}
          rows="5"
          cols="59"
          className={classes.textArea}
          onChange={(event) =>
            this.handleChangeFormField(item.id, event.target.value)
          }
        />
      </div>
    );
  }

  renderSingleArrayItem(item) {
    const { classes } = this.props;

    return (
      <div className={classes.fieldContainer}>
        <FieldValuesSingleSelect
          onChange={(newValue) => {
            const changeValue = newValue ? newValue.value : null;
            this.handleChangeFormField(item.id, changeValue);
          }}
          isClearable={true}
          isCreatable={item.allow_new_options}
          fieldId={item.id}
          fieldValues={item.values}
        />
      </div>
    );
  }

  renderMultipleArrayItem(item) {
    const { classes } = this.props;
    const selectedValues = this.state.formData[item.id] || [];

    return (
      <div className={classes.editItemContainerMultiple}>
        <FieldValuesMultiSelect
          value={selectedValues}
          onChange={(newItems) => {
            this.handleChangeFormField(item.id, newItems);
          }}
          width="100%"
          placeholder={`Search for ${item.label}...`}
          noOptionsMessage={`No ${item.label} found`}
          isCreatable={item.allow_new_options}
          fieldId={item.id}
          fieldValues={item.values}
        />
        {this.renderMultipleValues(item)}
      </div>
    );
  }

  renderMultipleValues(item) {
    const { classes } = this.props;
    const selectedValues = this.state.formData[item.id];

    if (!selectedValues) return;

    return (
      <div className={classes.multipleValuesContainer}>
        <SubArrowIcon color={black5} />
        <div className={classes.multipleValues}>
          {selectedValues.length ? (
            selectedValues.map((value, index) => (
              <EcMultipleSelectValue
                label={value.label}
                key={index}
                marginBottom="8px"
                closeHandler={() => {
                  const newMultiState = [...selectedValues];
                  this.handleChangeFormField(
                    item.id,
                    newMultiState.filter(
                      (multiValue) => multiValue.value !== value.value,
                    ),
                  );
                }}
              />
            ))
          ) : (
            <span className={classes.multipleValuesEmpty}>
              No values selected...
            </span>
          )}
        </div>
      </div>
    );
  }

  renderField(item) {
    let returnValue;
    switch (item.type) {
      case 'BOOLEAN':
        returnValue = this.renderBooleanItem(item);
        break;
      case 'DATE':
        returnValue = this.renderDateItem(item);
        break;
      case 'NUMBER':
        returnValue = this.renderNumberItem(item);
        break;
      case 'STRING':
        returnValue = this.renderStringItem(item);
        break;
      case 'TEXT_AREA':
        returnValue = this.renderTextAreaItem(item);
        break;
      case 'ARRAY_SINGLE':
        returnValue = this.renderSingleArrayItem(item);
        break;
      case 'ARRAY_MULTIPLE':
        returnValue = this.renderMultipleArrayItem(item);
        break;
      default:
        returnValue = EM_DASH;
        break;
    }

    return returnValue;
  }

  renderFormFields() {
    const { classes } = this.props;
    const { uploadFormFields, loading, errorLoading, helpText } = this.state;

    if (loading && !errorLoading) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    } else if (!loading && errorLoading) {
      return (
        <div className={classes.errorMessage}>
          An error occurred while loading the upload form fields.
        </div>
      );
    }

    return (
      <div className={classes.documentTagsContainer}>
        {uploadFormFields
          ? uploadFormFields.map((item) => (
              <div className={classes.subsection} key={`form-field-${item.id}`}>
                <div className={classes.subsectionTitle}>
                  {item.label}
                  {helpText && helpText[item.id] ? (
                    <Icon icon="info" tooltip={helpText[item.id]} />
                  ) : undefined}
                </div>

                {this.renderField(item)}
              </div>
            ))
          : null}
      </div>
    );
  }

  renderAddedDocuments() {
    const { classes, uploadFiles, handleRemoveUploadFile } = this.props;

    return uploadFiles.map((doc, index) => {
      return doc ? (
        <Fragment key={`doc-${index}`}>
          <div className={classes.documentInfo}>
            <DocumentStrokedIcon />
            <div className={classes.documentName}>{doc.name}</div>
            <div className={classes.documentSize}>
              <UploadSize size={doc.size} />
            </div>
            <button
              className={classes.closeIcon}
              title="Remove document"
              onClick={() => handleRemoveUploadFile(doc.upload.uuid)}
            >
              <CloseIcon />
            </button>
          </div>
          <hr className={classes.horizontalSeparator} />
        </Fragment>
      ) : null;
    });
  }

  render() {
    const {
      classes,
      displayInfoSection = true,
      docsSectionStyles = {},
      docsSectionTitle = 'Documents',
      title,
      uploadLocationName,
      uploadFiles,
      confirmButtonIcon,
      confirmButtonText,
      hideModal,
      showSecondaryModal,
      handleAddUploadFiles,
      hasUploadPermission,
    } = this.props;

    return (
      <EcModalCard
        id={OnboardingIdType.UploadContractFields}
        title={title}
        content={
          <DropzoneComponent
            config={{ postUrl: 'no-url' }}
            djsConfig={{
              autoProcessQueue: false,
              clickable: '#add_documents',
              dictDefaultMessage: '',
              previewTemplate: '<div class="ignore"></div>',
            }}
            eventHandlers={{
              addedfiles: async (files) => {
                const validFiles = await filterValidFiles(files);
                setTimeout(() => {
                  handleAddUploadFiles(validFiles);
                });
              },
            }}
          >
            <div className={classes.modalBodyContent}>
              {displayInfoSection ? (
                <div className={classes.infoSection}>
                  <div className={classes.sectionHeader}>
                    <div className={classes.sectionTitle}>
                      Location and Metadata
                    </div>
                  </div>
                  <div className={classes.subsection}>
                    <div className={classes.subsectionTitle}>LOCATION</div>
                    <div className={classes.folderLocation}>
                      <div className={classes.folderContent}>
                        <FolderIcon blue />
                        <div className={classes.folderInfo}>
                          <div className={classes.folderName}>
                            {uploadLocationName}
                          </div>
                          {this.renderSelectedFolderPath()}
                        </div>
                      </div>
                      <div className={classes.folderAction}>
                        <EcButton
                          text="Change Location"
                          height="28px"
                          fontSize="12px"
                          onClick={() => showSecondaryModal(MODAL_FOLDER_TREE)}
                        />
                      </div>
                    </div>
                  </div>
                  {this.renderFormFields()}
                </div>
              ) : null}
              {displayInfoSection ? (
                <span className={classes.sectionSeparator} />
              ) : null}
              <div
                className={classes.documentsSection}
                style={docsSectionStyles}
              >
                <div className={classes.sectionHeader}>
                  <div className={classes.sectionTitle}>
                    {docsSectionTitle} ({uploadFiles.length})
                  </div>
                  <div
                    className={classes.sectionHeaderAction}
                    id={OnboardingIdType.AddDocuments}
                  >
                    <button
                      id="add_documents"
                      className={classes.addDocsButton}
                    >
                      <PlusIcon size="20" color={evisortBlue} />
                      Add {docsSectionTitle}
                    </button>
                  </div>
                </div>
                <div className={classes.documentsList}>
                  <hr className={classes.horizontalSeparator} />
                  {this.renderAddedDocuments()}
                </div>
                {!hasUploadPermission && (
                  <div className={classes.modalErrorMessage}>
                    Please select a folder you have access to.
                  </div>
                )}
              </div>
            </div>
          </DropzoneComponent>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              yellow
              id={OnboardingIdType.UploadButtonModal}
              disabled={!hasUploadPermission || uploadFiles.length === 0}
              iconLeft={confirmButtonIcon}
              text={confirmButtonText}
              onClick={this.handleOnUploadDocumentsClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

export default injectSheet(styles)(withFlags(UploadModal));
