import { FileExtensionType, types } from '~/eds';
import { DocumentHandler } from '~/features/document-handler';
import { pilot } from '~/services';

import { DocumentServerResponse } from '../document';

export const getDocumentHandler = async (
  id: types.PilotId,
): Promise<DocumentHandler> => {
  const docHandler: DocumentServerResponse = await pilot.get(`document/${id}/`);
  return mapResponse(docHandler);
};

const mapResponse = (response: DocumentServerResponse): DocumentHandler => {
  return {
    id: response.id,
    currentVersion: {
      fileName: response.current_version.file_name,
      fileType: response.current_version.file_type,
      id: response.current_version.id,
    },
    dateAdded: new Date(response.date_added),
    dateModified: new Date(response.date_modified),
    userAdded: response.user_added,
    documentName: response.document_name,
    path: response.path,
    fileType: response.file_type as FileExtensionType,
    documentTypes: response.document_types,
    partyList: response.party_list,
    provisions: response.provisions,
    provisionList: response.provision_list,
    rebateTable: response.rebate_table,
    paymentTable: response.payment_table,
    processingStatus: response.processing_status,
    visibilityLevel: response.visibility_level,
    algoStatus: response.algo_status,
    isProcessingCoordinates: response.is_processing_coordinates,
  };
};
