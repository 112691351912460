import React from 'react';

import { Actions, Text, types } from '~/eds';
import { Highlight } from '~/features/document-viewer';
import { Uuid } from '~/types';

interface Props {
  highlights: Highlight[];
  activeHighlightId?: Uuid;
  onHighlightSourceClick: (highlight: Highlight) => void;
}

export const HighlightSources = ({
  highlights,
  activeHighlightId,
  onHighlightSourceClick,
}: Props) => {
  const actions = highlights.map((highlight, index) => ({
    text: `${index + 1}`,
    onClick: () => {
      onHighlightSourceClick(highlight);
    },
    status: (activeHighlightId && highlight.id === activeHighlightId
      ? 'ai'
      : undefined) as types.StatusType,
  }));

  return (
    <>
      {highlights.length === 0 ? (
        <Text>There are no sources.</Text>
      ) : (
        <Actions actions={actions} size="s" wrap />
      )}
    </>
  );
};
