const base = {
  tableCell: {
    paddingTop: 6,
    paddingBottom: 6,
    whiteSpace: 'pre-wrap',
  },
};

const styles = {
  provisionTitle: {
    ...base.tableCell,
    alignSelf: 'flex-start',
  },
  versionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default { ...styles, ...base };
