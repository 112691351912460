import { FlagType, useFlag } from '~/flags';
import { Permission } from '~/types';

import { and } from './utils';

const useManagedEntities = () => {
  return useFlag(FlagType.ManagedEntities);
};

export const canUseManagedEntities: Permission = and([useManagedEntities]);
