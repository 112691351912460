import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import React from 'react';

import { showToast } from '~/components/Shared/EcToast';
import { formatDate } from '~/eds';
import { CodedErrorCodeType } from '~/enums';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { Link } from '~/ui';
import { parseApiError } from '~/utils/parseApiError';

import { downloadImportResultsExcelFile } from '../UsersPage.data';

const DownloadImportResultsFileLink = ({
  text,
  importId,
  clientId,
  resultType,
}) => {
  const handleImportErrorsFileDownloadClick = async () => {
    try {
      const result = await downloadImportResultsExcelFile({
        clientId,
        importId,
        resultType,
      });

      showToast(SUCCESS, `${resultType} file downloaded successfully.`);
      const timestamp = formatDate(new Date(), 'iso_datetime');
      saveAs(result, `evisort-users-${resultType}-${timestamp}.xlsx`);
    } catch (errorResponse) {
      handleApiError(
        errorResponse,
        CodedErrorCodeType.BulkImportErrorDownloadingJobResult,
        { resultType },
      );
    }
  };

  const handleApiError = (
    errorResponse,
    customCodeError,
    pairsKeyValueToFillTemplate = {},
  ) => {
    const error = parseApiError(
      errorResponse,
      customCodeError,
      pairsKeyValueToFillTemplate,
    );
    showToast(ERROR, error.message);
  };

  return (
    <Link variant="body2" onClick={handleImportErrorsFileDownloadClick}>
      {text}
    </Link>
  );
};

DownloadImportResultsFileLink.propTypes = {
  text: PropTypes.string.isRequired,
  importId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  resultType: PropTypes.any.isRequired, // @TODO check for value in BulkImportUserResultType
};

export default DownloadImportResultsFileLink;
