import { QueryParamType } from '~/enums';
import { tryDeserializeJsonObject } from '~/utils/object';

export function getPageSearchQuery() {
  return new URLSearchParams(window.location.search.substring(1));
}

export function getPageSearchQueryByKey(key, defaultValue) {
  const params = getPageSearchQuery();
  const value = params.get(key);
  let valueReturned = value;
  if (!key) return null;
  else if (
    key.includes(QueryParamType.ActiveTab) ||
    key.includes(QueryParamType.Page) ||
    key.includes(QueryParamType.PageSize)
  ) {
    valueReturned = !value ? null : Number(value);
  } else if (
    key.includes(QueryParamType.Query) ||
    key.includes(QueryParamType.SelectedItems)
  ) {
    valueReturned = tryDeserializeJsonObject(value);
  }
  return valueReturned ?? defaultValue;
}

export function updatePageSearchQuery(
  searchParamsObject,
  shouldPushHistoryState = false,
) {
  const url = new URL(window.location);
  for (const [key, value] of Object.entries(searchParamsObject)) {
    const valueToSet =
      typeof value === 'object' ? JSON.stringify(value) : value;
    url.searchParams.set(key, valueToSet);
  }

  if (shouldPushHistoryState) {
    return window.history.pushState({}, '', url);
  }
  return window.history.replaceState({}, '', url);
}

export function updatePageSearchParam(key, value) {
  const url = new URL(window.location);
  url.searchParams.set(key, value);
  window.history.replaceState({}, '', url);
}

export function deletePageSearchParam(key) {
  const url = new URL(window.location);
  url.searchParams.delete(key);
  window.history.replaceState({}, '', url);
}
