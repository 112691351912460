import React from 'react';
import { connect } from 'react-redux';

import {
  workflowRemoveFinalizeApproval,
  workflowRemoveFinalizeApprovalCount,
  workflowSetFinalizeApprovalCount,
  workflowSetFinalizeApprovalOrder,
  workflowSetFinalizeTaskApproval,
  workflowSetFinalizeTaskApprovalDescription,
  workflowSetFinalizeTaskApprovalTitle,
  workflowToggleFinalizeApprovalType,
  workflowToggleFinalizeConditionRule,
} from '~/actions';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import { hasValidApprovalValue } from '~/components/Workflow/Workflow.utils';
import { WorkflowReviewFinalizeApprovalType } from '~/enums';
import { useUsers } from '~/hooks';
import { getFinalizeTaskById } from '~/reducers/workflow';
import {
  Box,
  EditableText,
  FlexLayout,
  NumberInput,
  RadioGroup,
  Text,
  useModal,
  useModalSortable,
} from '~/ui';
import { toSnakeTrimmed } from '~/utils/strings';
import { coerceName } from '~/utils/workflow';

import ConditionRuleInput from '../../../shared/ConditionRuleInput';
import DescriptionTextAreaForm from '../../../shared/DescriptionTextAreaForm';
import WorkflowUserDepartmentMultiSelect from '../../../shared/WorkflowUserDepartmentMultiSelect';

const formSegmentNested = {
  mb: 5,
  backgroundColor: 'black-alpha-04',
  borderRadius: 'm',
};

function PhaseItem({
  phaseId,
  approval,
  index,
  canReorder,
  //connected
  disabled,
  workflow,
  workflowRemoveFinalizeApproval,
  workflowSetFinalizeTaskApproval,
  workflowSetFinalizeTaskApprovalTitle,
  workflowSetFinalizeTaskApprovalDescription,
  workflowToggleFinalizeConditionRule,
  workflowToggleFinalizeApprovalType,
  workflowSetFinalizeApprovalCount,
  workflowRemoveFinalizeApprovalCount,
  workflowSetFinalizeApprovalOrder,
}) {
  const { id } = approval;
  const finalizeApprovals =
    getFinalizeTaskById(workflow, phaseId)?.approvals || [];
  const [sortableListModal, showSortableListModal] = useModalSortable({
    confirm: (sortedItems) =>
      workflowSetFinalizeApprovalOrder({
        orders: sortedItems.map((sortedItem) => sortedItem.value),
        phaseId,
      }),
    items: finalizeApprovals.map((item, index) => ({
      value: item.id,
      label: coerceName(item.name, 'Finalize Task', index),
    })),
    text: 'Sort',
    title: 'Reorder Finalize Phases',
  });

  const users = useUsers();

  const [deleteModal, showDeleteModal] = useModal({
    actionButton: {
      variant: 'red',
      text: 'Remove',
      errorHandler: () => {},
      promise: async () =>
        await workflowRemoveFinalizeApproval({ id, phaseId }),
    },
    title: 'Remove Approval?',
    content: (
      <Text color="gray-900" variant="s-spaced">
        Are you sure you want to remove the approval?{' '}
        <Text variant="s-spaced-bold">
          You won’t be able to undo this action.
        </Text>
      </Text>
    ),
  });

  const coercedTaskName = coerceName(approval.name, 'Finalize Task', index);
  const taskName = toSnakeTrimmed(coercedTaskName);

  const getMenuItems = () => {
    const items = [];

    items.push({
      content: 'Reorder Finalize Phases',
      disabled: !canReorder,
      onClick: () => showSortableListModal(),
    });

    items.push({
      content: 'Remove Finalize Task',
      onClick: () => showDeleteModal(),
    });

    return items;
  };

  const hasUserDepartmentError = !hasValidApprovalValue(approval, users);

  return (
    <Box>
      <Box sx={formSegmentNested}>
        <Box sx={{ borderBottom: 'border' }}>
          <FlexLayout p={4} alignItems="center" justifyContent="space-between">
            <EditableText
              id={`workflow--finalize-edit-task-name=${taskName}-name`}
              placeholder={`Finalize Task ${index + 1}`}
              value={approval.name}
              onChange={(value) =>
                workflowSetFinalizeTaskApprovalTitle({ value, id, phaseId })
              }
            />
            <Box pl={4}>
              <ActionsMenu
                id={`workflow_builder_finalize_phase_actions_menu_id=${id}`}
                items={getMenuItems()}
                align="end"
              />
            </Box>
          </FlexLayout>
        </Box>
        <Box p={4}>
          <Box pt={2} pb={2} sx={{ borderBottom: 'border' }}>
            <DescriptionTextAreaForm
              description={approval.description}
              disabled={disabled}
              id={`workflow--finalize-edit-task-name=${taskName}-description`}
              onUpdate={(value) =>
                workflowSetFinalizeTaskApprovalDescription({
                  value,
                  id,
                  phaseId,
                })
              }
            />
          </Box>
          <Box pt={4} pb={4} mt={4} sx={{ borderBottom: 'border' }}>
            <ConditionRuleInput
              conditionRule={approval.conditionRule}
              id={`workflow--finalize-edit-task-name=${taskName}-condition-rule`}
              onChange={(conditionRule) =>
                workflowToggleFinalizeConditionRule({
                  conditionRule,
                  id,
                  phaseId,
                })
              }
            />
          </Box>
          <Box mt={4} mb={4}>
            <Text variant="xs-dense-caps">Finalizers</Text>
          </Box>
          <Box mb={4}>
            <WorkflowUserDepartmentMultiSelect
              error={
                hasUserDepartmentError
                  ? "There are users that don't exist anymore"
                  : ''
              }
              id={`workflow--finalize-edit-task-name=${taskName}-finalizers`}
              value={approval.approvers}
              onChange={(updatedApprovers) => {
                if (!updatedApprovers.length) {
                  workflowRemoveFinalizeApprovalCount({ id, phaseId });
                }
                workflowSetFinalizeTaskApproval({
                  approvers: updatedApprovers,
                  id,
                  phaseId,
                });
              }}
            />
          </Box>

          <Box mt={4}>
            <Text variant="xs-dense-caps">Assign to</Text>
            <RadioGroup
              id={`workflow--finalize-edit-task-name=${taskName}-assign-to`}
              inline={true}
              label="Require Approval From"
              value={approval.approvalType}
              options={[
                {
                  label: 'Everyone',
                  value: WorkflowReviewFinalizeApprovalType.Everyone,
                },
                {
                  label: 'Specific number of finalizers',
                  value: WorkflowReviewFinalizeApprovalType.Specific,
                },
              ]}
              onChange={(approvalType) =>
                workflowToggleFinalizeApprovalType({
                  approvalType,
                  id,
                  phaseId,
                })
              }
            />
          </Box>

          {approval.approvalType !==
            WorkflowReviewFinalizeApprovalType.Everyone && (
            <FlexLayout alignItems="baseline" space={3} mt={4}>
              <Text
                color={!approval.approvers.length && 'gray-500'}
                variant="xs-dense"
              >
                Number of finalizers to assign this to
              </Text>
              <NumberInput
                disabled={!approval.approvers.length}
                id={`workflow--finalize-edit-task-name=${taskName}-number-finalizers`}
                value={approval.minApprovalCount}
                min={1}
                onChange={(minApprovalCount) =>
                  workflowSetFinalizeApprovalCount({
                    minApprovalCount,
                    id,
                    phaseId,
                  })
                }
              />
            </FlexLayout>
          )}
        </Box>
      </Box>
      {sortableListModal}
      {deleteModal}
    </Box>
  );
}

function mapStateToProps({ builder, workflow }) {
  return {
    disabled: !builder.shouldEnableAllComponents,
    workflow,
  };
}

export default connect(mapStateToProps, {
  workflowRemoveFinalizeApproval,
  workflowSetFinalizeTaskApproval,
  workflowSetFinalizeTaskApprovalTitle,
  workflowSetFinalizeTaskApprovalDescription,
  workflowToggleFinalizeConditionRule,
  workflowToggleFinalizeApprovalType,
  workflowSetFinalizeApprovalCount,
  workflowRemoveFinalizeApprovalCount,
  workflowSetFinalizeApprovalOrder,
})(PhaseItem);
