import { publicApi } from '~/services';

interface Request {
  id: string;
}

export const getExternalDocument = async ({ id }: Request): Promise<File> => {
  try {
    return await publicApi.post(
      `contracts/documents/presigned-documents`,
      { id },
      { responseType: 'blob' },
    );
  } catch (error: any) {
    throw new Error('Document not found');
  }
};
