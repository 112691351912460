import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import CheckmarkIcon from '../Icons/CheckmarkIcon';
import DashIcon from '../Icons/DashIcon';
import styles from './EcCheckbox.styles';

function EcCheckbox(props) {
  const { classes, checked, disabled, id, partiallyChecked, onClick } = props;

  let checkboxClass = classes.ecCheckbox;
  let checkboxIcon = null;
  if (checked || partiallyChecked) {
    checkboxClass = classes.ecCheckboxSelected;
    checkboxIcon = checked ? (
      <CheckmarkIcon size="16" white />
    ) : (
      <DashIcon white size="16" />
    );
  }

  function onKeyDownHold() {
    return undefined;
  }

  return (
    <div
      className={classes.checkboxContainer}
      aria-checked={checked}
      role="checkbox"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDownHold}
    >
      <input
        className={classes.checkboxHidden}
        defaultChecked={checked}
        disabled={disabled}
        id={id}
        type="checkbox"
      />
      <span className={checkboxClass}>{checkboxIcon}</span>
    </div>
  );
}

EcCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  partiallyChecked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default injectSheet(styles)(EcCheckbox);
