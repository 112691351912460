import React from 'react';

import { TextContainer } from '../TextContainer';
import { DateValue, Filter } from '../types';
import { coerceFormatDate } from '../utils';

interface Props {
  filter: Filter<DateValue>;
}

export const DateValuesPreview = ({ filter }: Props) => {
  const { values } = filter;
  const [value1, value2] = values;

  return values.length === 1 ? (
    <TextContainer text={coerceFormatDate(value1)} />
  ) : (
    <TextContainer.Pair
      delimiter="to"
      texts={[coerceFormatDate(value1), coerceFormatDate(value2)]}
    />
  );
};
