import React, { useMemo } from 'react';

import { Box, Layout, Modal } from '~/eds';
import { FormSection, isFieldValid } from '~/features/sso-settings';
import { FormSection as FormSectionType } from '~/types';

type Props = {
  clientId: string;
  sections: FormSectionType[];
  onHide: () => void;
  onSubmit: () => void;
  onDelete: () => void;
};

export const SAMLConfigEdit = ({
  clientId,
  sections,
  onHide,
  onSubmit,
  onDelete,
}: Props) => {
  const isFormValid = useMemo(() => {
    return sections.every((section) =>
      section.configs.every((formField) => isFieldValid(formField)),
    );
  }, [sections]);

  return (
    <Modal
      isVisible={true}
      isFullPage={true}
      title="Edit SAML SSO"
      onCancel={onHide}
      onHide={onHide}
      primaryAction={{
        text: 'Submit',
        onClick: onSubmit,
        disabled: !isFormValid,
      }}
      secondaryActions={[
        {
          text: 'Delete Configuration',
          onClick: onDelete,
          variant: 'danger',
        },
      ]}
    >
      <Layout w="60%" direction="column" alignSelf="center">
        {sections.map((section, index) => (
          <Box mb={8} key={`${index}_saml_sso_section`}>
            <FormSection
              clientId={clientId}
              section={section}
              onExplain={undefined}
            />
          </Box>
        ))}
      </Layout>
    </Modal>
  );
};
