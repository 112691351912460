import { regular } from '../../../assets/shared-styles/general';

const styles = {
  tooltipContainer: {
    width: (props) => props.width,
    maxWidth: (props) => props.maxWidth,
    padding: '8px 12px',
    fontSize: '12px',
    fontWeight: regular,
    lineHeight: '1.5',
    whiteSpace: 'pre-wrap',
  },
};

export default styles;
