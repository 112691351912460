import { PilotId } from '~/eds/src/types';
import { pilotV3 } from '~/services';

import { NavigationHandlerParam } from '../../types/searchV3/navigation';
import { toSortParam } from '../../utils';

type DocumentHandlerPosition = {
  type: string;
  id: PilotId;
  attributes: {
    name: string;
    position: number;
    file_type: string;
  };
};

export type PositionResponse = {
  data: {
    type: string;
    attributes: {
      current: DocumentHandlerPosition;
      next?: DocumentHandlerPosition;
      previous?: DocumentHandlerPosition;
    };
  };
  meta: {
    total: number;
  };
};

export const getSearchHandlersByPosition: (
  props: NavigationHandlerParam,
) => Promise<PositionResponse> = ({ position, query, sortBy }) => {
  return pilotV3.post(
    'search/handlers-by-position/',
    {
      data: {
        attributes: {
          position,
          query,
        },
      },
    },
    { params: { sort: toSortParam(sortBy) } },
  );
};
