import { useState } from 'react';

import {
  EntityVisibilitySelect,
  validateShareField,
} from '~/components/Shared/EntityVisibilitySelect';
import { ContentContainer, Form, pluralize, useModal, useToast } from '~/eds';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { Nullable, Workflow } from '~/types';

type Visibility = 'OPEN' | 'PRIVATE' | 'SHARED';

type InitialStateType = {
  shareField: {
    visibility: Visibility;
    userIds: number[];
    departmentIds: number[];
  };
};

interface ShareWorkflowModalProps {
  workflow: Nullable<Workflow>;
  onHide?: () => void;
}

const fields = {
  shareField: {
    order: 1,
    key: 'shareField',
    type: 'field' as const,
    name: 'shareField',
    label: 'Visibility',
    description: 'Who can see and use this workflow template.',
    input: EntityVisibilitySelect,
    inputProps: {
      enablePortal: false,
    },
  },
};

export const useShareWorkflowModal = ({
  workflow,
  onHide,
}: ShareWorkflowModalProps) => {
  const currentUser = useCurrentUser();
  const { toast } = useToast();
  const [isValid, setIsValid] = useState(true);
  const [currentState, setCurrentState] = useState<{
    visibility: Visibility;
    userIds: number[];
    departmentIds: number[];
  }>({
    visibility: 'OPEN',
    userIds: [],
    departmentIds: [],
  });

  const workflowId = workflow?.id;

  const { data, isFetching } = api.endpoints.getWorkflowShareSettings.useQuery(
    {
      workflowId: workflowId!,
      currentUserId: currentUser.id,
    },
    {
      skip: !workflowId || !currentUser,
    },
  );

  const [
    updateWorkflowShareSettings,
    { isLoading: isUpdating },
  ] = api.endpoints.updateWorkflowShareSettings.useMutation();

  const initialState = {
    shareField: {
      value: data
        ? {
            visibility: data.visibility as Visibility,
            userIds: data.userIds,
            departmentIds: data.departmentIds,
          }
        : currentState,
    },
  };

  const getSuccessMessage = () => {
    let sharedWith = 'Shared with';
    if (currentState.departmentIds.length > 0) {
      sharedWith += ` ${pluralize(
        currentState.departmentIds.length,
        'department',
      )}`;
    }
    if (
      currentState.departmentIds.length > 0 &&
      currentState.userIds.length > 0
    ) {
      sharedWith += ' and';
    }
    if (currentState.userIds.length > 0) {
      sharedWith += ` ${pluralize(currentState.userIds.length, 'person')}`;
    }
    if (
      currentState.departmentIds.length === 0 &&
      currentState.userIds.length === 0
    ) {
      sharedWith += ` everyone at ${currentUser.client_name}`;
    }
    return sharedWith;
  };

  const handleUpdateShareSettings = async () => {
    try {
      await updateWorkflowShareSettings({
        workflowId: workflowId!,
        shareSettings: {
          userIds: currentState.userIds,
          departmentIds: currentState.departmentIds,
        },
        hasSettings: data!.hasSettings,
      }).unwrap();
      hide();
      toast({
        status: 'success',
        message: getSuccessMessage(),
      });
    } catch (e: any) {
      if (e?.response?.status === 403) {
        toast({
          status: 'danger',
          message:
            'You do not have permission to update this workflow share settings.',
        });
      } else {
        toast({
          status: 'danger',
          message: 'Failed to update this workflow share settings.',
        });
      }
    }
  };

  const [modal, show, hide] = useModal({
    title: `Share "${workflow?.name}"`,
    onHide,
    primaryAction: {
      onClick: handleUpdateShareSettings,
      text: 'Save',
      isLoading: isUpdating || isFetching,
      disabled: !isValid,
      tooltip: !isValid ? 'Please fix the errors before submitting' : undefined,
    },
    children: (
      <ContentContainer
        loadingContent={{
          isLoading: isFetching,
          message: 'Loading share settings...',
        }}
      >
        <Form<InitialStateType>
          fields={fields}
          onChange={(updatedState) => {
            const visibility = updatedState.state.shareField.value.visibility;
            const userIds =
              visibility === 'PRIVATE'
                ? [currentUser.id]
                : visibility === 'OPEN'
                ? []
                : updatedState.state.shareField.value.userIds;
            setCurrentState({
              visibility,
              userIds,
              departmentIds:
                updatedState.state.shareField.value.departmentIds ?? [],
            });
          }}
          state={initialState}
          validators={[validateShareField]}
          onValidate={(updatedState) => {
            setIsValid(Object.keys(updatedState.errors ?? {}).length === 0);
          }}
        />
      </ContentContainer>
    ),
  });

  return [modal, show, hide] as const;
};
