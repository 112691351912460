import PropTypes from 'prop-types';
import React from 'react';

import UserName from '~/components/Client/UserName';
import { Text } from '~/ui';
import { tokenizeContent } from '~/utils/comments';

const mentionRenderers = {
  user: (value) => (
    <Text variant="s-spaced-bold">
      <UserName userId={Number(value)} />
    </Text>
  ),
};

function CommentContent({ content }) {
  const tokens = tokenizeContent(content || '', mentionRenderers);
  return (
    <Text variant="s-spaced" sx={{ whiteSpace: 'pre-wrap' }}>
      {tokens.map((token, i) => (
        <React.Fragment key={i}>{token.content}</React.Fragment>
      ))}
    </Text>
  );
}

CommentContent.propTypes = {
  content: PropTypes.string,
};

export default CommentContent;
