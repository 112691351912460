import PT from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box, LoadingContainer } from '~/ui';
import { blobToDataUrl } from '~/utils/files';

/**
 * This component, along with all subcomponents under Document*Viewer should be appropriately renamed to File*Viewer.  Keeping the names close to the current source code for now.
 *
 * This family of components will conveniently and consistently try to render previews for the provided file.  These should be formalized in EDS eventually so we can leave notes for future implementation.
 *
 * This component tries to render an image file into an <img /> Element.
 **/
const DocumentImageViewer = ({ file }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const dataUrl = await blobToDataUrl(file);
        setSrc(dataUrl);
      } catch {
        // no error is handled here for now and we assume consumers will provide valid image files.  Error handling will be addressed when Design has taken into consideration how file error messages should be displayed consistently for all file previews.
      } finally {
        setIsLoading(false);
      }
    };
    loadImage();
  }, [file]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <Box
        as="img"
        alt={file.name}
        src={src}
        sx={{
          objectFit: 'contain',
          width: '100%',
        }}
      />
    </LoadingContainer>
  );
};

DocumentImageViewer.propTypes = {
  // a JS File object (should be an image file)
  file: PT.any.isRequired,
};

export default DocumentImageViewer;
