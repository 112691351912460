import {
  black1,
  black4,
  evisortBlue,
  evisortRed,
} from '~/assets/shared-styles/general';

const styles = {
  ecInput: {
    width: (props) => props.width || '240px',
    height: (props) => props.height || '44px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    padding: '11px 15px',
    backgroundColor: (props) => (props.isDisabled ? '#f3f3f3' : '#fff'),
    color: (props) => (props.isDisabled ? black4 : black1),
    fontSize: (props) => props.fontSize || '15px',
    outline: 'none',
    caretColor: evisortBlue,

    '&::placeholder': {
      color: '#626868',
      fontSize: '15px',
    },

    '&:hover': {
      borderColor: (props) => (props.isDisabled ? null : evisortBlue),
    },

    '&:focus': {
      borderWidth: 2,
      borderColor: (props) => (props.isDisabled ? null : evisortBlue),
    },
  },
  ecInputError: {
    color: evisortRed,
    borderColor: evisortRed,

    '&::placeholder': {
      color: evisortRed,
    },
  },
};

export default styles;
