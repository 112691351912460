import { useDispatch } from 'react-redux';

import { actions } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';
import { capitalize } from '~/utils/strings';

import { TargetEntity } from '../types';

export const useCreateAction = (entityType: TargetEntity['type']) => {
  const dispatch = useDispatch();
  const { navigate } = useRouting();

  return {
    level: 'primary' as const,
    text: `New ${capitalize(entityType)} Model`,
    onClick: () => {
      switch (entityType) {
        case 'field':
        default:
          dispatch(actions.resetFieldAi());
          navigate(`${RoutePathType.AutomationHubFields}/new`);
      }
    },
  };
};
