import React from 'react';

import { Layout, Text } from '~/eds';

interface Props {
  value: string | number;
  width: number;
  height: number;
}
export function SingleValueChart({ value, height, width }: Props) {
  return (
    <Layout h={height} align="center" justify="center" w={width}>
      <Text variant="title">{value}</Text>
    </Layout>
  );
}
