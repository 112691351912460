import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import { mutedBackground } from '~/assets/shared-styles/general';
import DocumentTypeDocIcon from '~/components/Shared/Icons/DocumentTypeDocIcon';
import { FlexLayout, Text } from '~/ui';

const styles = {
  container: {
    background: mutedBackground,
    borderRadius: '4px',
    padding: '6px 12px',
  },
};

const fileIcons = {
  docx: DocumentTypeDocIcon,
};

function Attachment({
  attachment,
  // injected
  classes,
}) {
  const { name, type } = attachment;
  const FileIcon = fileIcons[type] || fileIcons.docx;
  return (
    <FlexLayout alignItems="center" className={classes.container} space={2}>
      <FileIcon />
      <Text variant="xs-dense">
        {name}.{type}
      </Text>
    </FlexLayout>
  );
}

export const attachmentPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

Attachment.propTypes = {
  attachment: attachmentPropTypes.isRequired,
};

export default injectSheet(styles)(Attachment);
