import { pilot } from '../../services';

export const getAlerts = (page = 1, queries) =>
  pilot.get('/alert/', { params: { page, ...queries } });

export const getAlert = (id) => pilot.get(`/alert/${id}/`);

export const createAlert = (data) => pilot.post('alert/', data);

export const updateAlert = (id, data) =>
  pilot.patch(`/alert/${id}/`, { ...data });

export const deleteAlerts = (alert_ids) =>
  pilot.put('/alert/delete/', { alert_ids });

export const toggleMuteAlert = (id, mute) =>
  pilot.patch(`/alert/${id}/mute/`, { mute });

export const sendAlertEmail = (id, send_to_all) =>
  pilot.post(`/alert/${id}/trigger/`, { send_to_all });

export const getFiltersFields = () => pilot.get('/alert/sections/');

export const togglePinFilterField = (id) =>
  pilot.patch(`/alert/${id}/pin-field/`);

export const getAlertCustomFieldsByClient = (clientId) =>
  pilot.get(`user/${clientId}/list_user_fields/`);
