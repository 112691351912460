import { useEffect, useState } from 'react';

import { useDocumentEditorContext } from '~/components/Shared/DocumentEditor';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { captureException } from '~/utils';

interface UseTrackEditHistoryProps {
  versionId: string;
  documentId: string;
}

export const useTrackEditHistory = ({
  versionId,
  documentId,
}: UseTrackEditHistoryProps) => {
  const editFrequency = useFlag(FlagType.EJ2TrackEditHistory);
  const [saveDocument] = api.endpoints.backupDocumentSfdt.useMutation();
  const {
    documentEditor,
    isDocumentReady,
    isInEditMode,
  } = useDocumentEditorContext();
  const [isEditing, setIsEditing] = useState(isInEditMode);

  const backupDocument = async () => {
    try {
      if (!documentEditor) return null;
      const documentBlob = await documentEditor.saveAsBlob('Sfdt');
      const document = new File([documentBlob], 'document.json', {
        type: 'application/json',
      });
      await saveDocument({
        documentId,
        versionId,
        document,
      }).unwrap();
    } catch (error) {
      captureException(
        'An error occurred when trying to save a backup.',
        error,
        {
          section: 'ej2 document editor',
        },
      );
    }
  };

  // setup saving interval when is editing
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (
      editFrequency &&
      isDocumentReady &&
      versionId &&
      documentId &&
      isInEditMode
    ) {
      interval = setInterval(() => {
        backupDocument();
      }, editFrequency);
    }
    return () => {
      clearInterval(interval);
    };
  }, [editFrequency, isDocumentReady, isInEditMode, versionId, documentId]);

  // whenever we exit the edit mode, we save a backup of the document
  useEffect(() => {
    if (!isInEditMode && isEditing) {
      backupDocument();
    }
    setIsEditing(isInEditMode);
  }, [isInEditMode]);
};
