import { types } from '~/eds';

import { DataFieldType } from './enums';

export const getIconFromFieldType = (type: DataFieldType): types.IconType => {
  switch (type) {
    case DataFieldType.ARRAY_MULTIPLE:
    case DataFieldType.ARRAY_SINGLE:
      return 'field-dropdown';
    case DataFieldType.ATTACHMENT:
      return 'attachment';
    case DataFieldType.BOOLEAN:
      return 'field-boolean';
    case DataFieldType.CLAUSE:
      return 'field-clause';
    case DataFieldType.DATE:
      return 'field-date';
    case DataFieldType.NUMBER:
      return 'field-number';
    case DataFieldType.STRING:
      return 'field-short-response';
    case DataFieldType.TEXT_AREA:
      return 'field-paragraph';
    default:
      return 'field';
  }
};

export const getFieldTypeLabel = (type: DataFieldType): string => {
  switch (type) {
    case DataFieldType.ARRAY_MULTIPLE:
      return 'Dropdown (allows multiple values)';
    case DataFieldType.ARRAY_SINGLE:
      return 'Dropdown (allows single value)';
    case DataFieldType.ATTACHMENT:
      return 'Attachment';
    case DataFieldType.BOOLEAN:
      return 'Boolean';
    case DataFieldType.CLAUSE:
      return 'Clause';
    case DataFieldType.DATE:
      return 'Date';
    case DataFieldType.NUMBER:
      return 'Number';
    case DataFieldType.STRING:
      return 'Text';
    case DataFieldType.TEXT_AREA:
      return 'Text area';
    default:
      return 'Unknown';
  }
};
