import PropTypes from 'prop-types';
import React, { Fragment, useMemo, useState } from 'react';
import injectSheet from 'react-jss';

import { Button, Card, Link, Text, Tooltip } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { useHasFeatureFlag } from '~/hooks';
import { sortByStringValue } from '~/utils/array';

import { evisortBlue } from '../../../assets/shared-styles/general';
import ChevronDownIcon from '../../Shared/Icons/ChevronDownIcon';
import ChevronUpIcon from '../../Shared/Icons/ChevronUpIcon';
import styles from './ContractSummary.styles';
import { sortProvisions } from './utils';

function ContractSummary({
  classes,
  documentProvisions,
  handleNavigateToClauseTab,
  onProvisionClick,
  isPdfHighlighter,
}) {
  const [expandedProvisions, setExpandedProvisions] = useState({});
  const hasEditFeatureFlag = useHasFeatureFlag(FeatureFlagType.EditClause);
  const hasHidePhantomProvisions = useHasFeatureFlag(
    FeatureFlagType.HidePhantomProvisions,
  );
  const getViewProvisionButton = (content, provisionName) => {
    if (isNonPhantomProvision(content)) {
      return (
        <Button
          variant="action"
          text="View"
          onClick={() => onProvisionClick(content, provisionName)}
        />
      );
    } else {
      return (
        <Tooltip
          isInteractive
          tooltip={
            <div>
              <Text color="inverse.text.primary">
                Unable to highlight clause, please click here to see{' '}
                {hasEditFeatureFlag ? '"Key Clauses"' : '"Key Provisions"'} tab
                for the full text of the provision.
              </Text>
              <br />
              <br />
              <Text color="inverse.text.primary">
                To learn more on why this clause could not be highlighted,
                please see this{' '}
                {
                  <Link
                    isInverse
                    enableNewTab
                    href="https://support.evisort.com/hc/en-us/articles/7896267863575"
                  >
                    help center FAQ
                  </Link>
                }
              </Text>
            </div>
          }
        >
          <Button
            style={{ opacity: 0.5 }}
            variant="action"
            text="View"
            onClick={() => {
              handleNavigateToClauseTab(content.id);
            }}
          />
        </Tooltip>
      );
    }
  };

  const isNonPhantomProvision = (content) => {
    return isPdfHighlighter
      ? content.coordinates.length > 0
      : content.html_tokens.length > 0;
  };

  const getNonPhantomProvisions = (provision) =>
    provision.content.filter((cnt) => isNonPhantomProvision(cnt));

  const filterNonPhantomProvisions = (provisions) =>
    provisions.reduce((acc, provision) => {
      const nonPhantomProvisions = getNonPhantomProvisions(provision);
      return nonPhantomProvisions.length
        ? acc.concat({ ...provision, content: nonPhantomProvisions })
        : acc;
    }, []);

  const provisions = useMemo(() => {
    let provisionsList = documentProvisions;
    if (hasHidePhantomProvisions) {
      provisionsList = filterNonPhantomProvisions(provisionsList);
    }
    return provisionsList.sort(sortByStringValue('name'));
  }, [documentProvisions]);

  function renderExpandedProvisions(provision) {
    const sortedProvisionContentByPosition = provision.content.sort(
      sortProvisions,
    );

    return sortedProvisionContentByPosition.map((content, index) => {
      return (
        <div className={classes.summaryContainer} key={provision.name + index}>
          <div className={classes.label}>
            &nbsp;&nbsp;
            {provision.name} #{index + 1}
          </div>
          {getViewProvisionButton(content, provision.name)}
        </div>
      );
    });
  }

  return (
    <Card
      mode="bordered"
      header={{
        title: 'Contract Summary',
      }}
      role="list"
    >
      {provisions.length ? (
        provisions.map((provision) => {
          return (
            <Fragment key={provision.name}>
              <div className={classes.summaryContainer} role="listitem">
                <div className={classes.label}>{provision.name}</div>
                {provision.content.length === 1 ? (
                  getViewProvisionButton(provision.content[0], provision.name)
                ) : (
                  <button
                    aria-label={`toggle ${provision.name} provisions`}
                    aria-expanded={!!expandedProvisions[provision.name]}
                    className={classes.expandButton}
                    onClick={() =>
                      setExpandedProvisions({
                        ...expandedProvisions,
                        [provision.name]: !expandedProvisions[provision.name],
                      })
                    }
                  >
                    <span aria-hidden="true">{provision.content.length}</span>
                    {expandedProvisions[provision.name] ? (
                      <ChevronUpIcon
                        size="16"
                        opacity="1"
                        color={evisortBlue}
                      />
                    ) : (
                      <ChevronDownIcon
                        size="16"
                        color={evisortBlue}
                        opacity="1"
                      />
                    )}
                  </button>
                )}
              </div>
              {expandedProvisions[provision.name] &&
                renderExpandedProvisions(provision)}
            </Fragment>
          );
        })
      ) : (
        <div className={classes.noContentMessage}>
          Highlight and tag provisions to begin building contract summary.
        </div>
      )}
    </Card>
  );
}

ContractSummary.propTypes = {
  classes: PropTypes.object,
  documentProvisions: PropTypes.array.isRequired,
  onProvisionClick: PropTypes.func.isRequired,
  /** attribute to indicate if document viewer is the pdf preview highlighter */
  isPdfHighlighter: PropTypes.bool,
};

export default injectSheet(styles)(ContractSummary);
