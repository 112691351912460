const styles = {
  clauseContent: {
    marginRight: 'auto',
    marginLeft: 'auto',
    textAlign: 'justify',
    textJustify: 'interWord',
    maxWidth: 'modal.l',
    width: '80%',
    whiteSpace: 'pre-wrap',
  },
};

export default styles;
