import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { ActionsMenu, Chips, Icon, Layout, Text } from '~/eds';
import { getLabelForDocumentCount } from '~/utils/document.utils';

import {
  MODAL_CUSTOM_FIELD,
  MODAL_CUSTOM_FIELD_MOVE,
  MODAL_DELETE,
} from '../../../types/modal.types';
import styles from './EcCustomFieldItem.styles';

const fieldTypes = {
  ARRAY_MULTIPLE: 'Dropdown',
  ATTACHMENT: 'Attachment',
  STRING: 'Text',
  NUMBER: 'Number',
  DATE: 'Date',
  BOOLEAN: 'True/False',
  ARRAY_SINGLE: 'Dropdown',
  TEXT_AREA: 'Text Area',
};

class EcCustomFieldItem extends Component {
  renderExtraOptions(item) {
    const { section, handleShowModal } = this.props;

    function getMenuItems() {
      const items = [];

      items.push({
        label: 'Edit',
        onClick: () => handleShowModal(MODAL_CUSTOM_FIELD, section, item),
      });

      if (!item.is_smart) {
        items.push({
          label: 'Delete',
          onClick: () => handleShowModal(MODAL_DELETE, section, item, true),
          disabled: item.is_xray,
          tooltip: item.is_xray
            ? 'To delete a field attached to an X-Ray model, you must first delete the model under Automation.'
            : undefined,
        });
      }

      items.push({
        label: 'Move to Section...',
        onClick: () => handleShowModal(MODAL_CUSTOM_FIELD_MOVE, section, item),
      });

      return items;
    }

    return (
      <ActionsMenu
        id={`client_list_actions_menu?id=${item.id}`}
        actions={getMenuItems()}
        name="client list actions menu"
      />
    );
  }

  renderRuleTooltip(item) {
    const tooltip =
      item.doc_restriction === 'EXCLUDE' && item.doc_types.length
        ? `Rule: All document types except ${item.doc_types.join(', ')}`
        : `Rule: document types include: ${item.doc_types.join(', ')}`;

    return (
      <Layout align="center" justify="center">
        <Text>
          {item.doc_restriction === 'EXCLUDE' && !item.doc_types.length
            ? 'All document types'
            : item.doc_restriction === 'EXCLUDE' && item.doc_types.length
            ? `${
                item.num_doc_types - item.doc_types.length
              } document ${pluralize(
                'type',
                item.num_doc_types - item.doc_types.length,
              )}`
            : `${item.doc_types.length} document ${pluralize(
                'type',
                item.doc_types.length,
              )}`}
        </Text>
        <span data-tip data-for={`ruleText-${item.id}`}>
          {item.doc_restriction === 'EXCLUDE' &&
          !item.doc_types.length ? null : (
            <Icon
              icon="info"
              label="info"
              tooltip={tooltip}
              id={`ruleText-${item.id}`}
            />
          )}
        </span>
      </Layout>
    );
  }

  render() {
    const { classes, item, dragHandle } = this.props;

    const appliesToCol = getLabelForDocumentCount(
      item?.num_docs_applied_to?.file_count,
      item?.num_docs_applied_to?.document_count,
    );

    const getChips = (field) => {
      const chips = [];

      if (field.is_smart) {
        chips.push({
          text: 'SMART',
          tooltip:
            'This field is an intelligent data field. Data will be automatically populated in this field when found by our algorithm.',
        });
      }
      if (field.is_beta) {
        chips.push({ preset: 'beta' });
      }
      if (field.is_xray) {
        chips.push({
          status: 'ai',
          text: 'X-Ray',
        });
      }
      return chips;
    };

    return (
      <>
        <div className={classes.fieldRow}>
          <span className={classes.fieldNameContainer}>
            <span className={classes.fieldName}>
              {dragHandle}
              <Layout align="center" justify="center" spacing={2}>
                <Text>{item.label}</Text>
                <Chips chips={getChips(item)} />
              </Layout>
            </span>
          </span>
          <span style={{ width: '23%', display: 'flex' }}>
            <Layout align="center" justify="center" spacing={2}>
              <Text>{fieldTypes[item.field_type]}</Text>
            </Layout>
          </span>
          <span className={classes.fieldDocTypeContainer}>
            <Layout align="center" justify="center" spacing={2}>
              <Text>{this.renderRuleTooltip(item)}</Text>
            </Layout>
          </span>
          <span className={classes.fieldAppliesToContainer}>
            {appliesToCol}
          </span>
          {this.renderExtraOptions(item)}
        </div>
      </>
    );
  }
}

EcCustomFieldItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  handleShowModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(EcCustomFieldItem);
