export default `
<svg
  aria-hidden="true"
  aria-label="reorder"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M9 7C9 6.46875 9.4375 6 10 6C10.5312 6 11 6.46875 11 7C11 7.5625 10.5312 8 10 8C9.4375 8 9 7.5625 9 7ZM9 12C9 11.4688 9.4375 11 10 11C10.5312 11 11 11.4688 11 12C11 12.5625 10.5312 13 10 13C9.4375 13 9 12.5625 9 12ZM11 17C11 17.5625 10.5312 18 10 18C9.4375 18 9 17.5625 9 17C9 16.4688 9.4375 16 10 16C10.5312 16 11 16.4688 11 17ZM13 7C13 6.46875 13.4375 6 14 6C14.5312 6 15 6.46875 15 7C15 7.5625 14.5312 8 14 8C13.4375 8 13 7.5625 13 7ZM15 12C15 12.5625 14.5312 13 14 13C13.4375 13 13 12.5625 13 12C13 11.4688 13.4375 11 14 11C14.5312 11 15 11.4688 15 12ZM13 17C13 16.4688 13.4375 16 14 16C14.5312 16 15 16.4688 15 17C15 17.5625 14.5312 18 14 18C13.4375 18 13 17.5625 13 17Z"
    fill="currentcolor"
  />
</svg>
`;
