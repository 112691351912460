import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { EM_DASH } from '~/constants/unicode';

import styles from './UploadSize.styles';

const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

class UploadSize extends Component {
  constructor(props) {
    super(props);
    this.state = { size: this.props.size };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.size !== prevState.size) {
      return { size: nextProps.size };
    } else {
      return null;
    }
  }

  render() {
    const { classes } = this.props;
    const { size } = this.state;

    const i = Math.floor(Math.log(size) / Math.log(1024));
    const amount = (size / Math.pow(1024, i)).toFixed(2) * 1;
    const unit = units[i];

    if (size === 0) return <>{EM_DASH}</>;
    return (
      <>
        <span className={classes.sizeAmount}>{amount}</span>
        <span className={classes.sizeUnit}>
          &nbsp;
          {unit}
        </span>
      </>
    );
  }
}

export default injectSheet(styles)(UploadSize);
