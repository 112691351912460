import { sylvanus } from '~/services';
import { Uuid } from '~/types';

interface Request {
  modelId: Uuid;
  version: number;
}

export const deletePromptModel = async ({
  modelId,
}: Request): Promise<undefined> => {
  return sylvanus.remove(`/model/${modelId}`);
};
