import React, { memo } from 'react';

import { formatNumberPercentage } from '../../utils';
import { Box } from '../Box';
import { Image } from '../Image';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { Breakpoints } from './types';
import {
  breakpointConfigs,
  defaultBreakpoints,
  getBreakpointKey,
} from './utils';

interface Props {
  /** Score value */
  score: number;
  /** Score breakpoints */
  breakpoints?: Breakpoints;
  /** Size */
  size?: 's' | 'm';
  /** Disables the score label */
  disableLabel?: boolean;
}

const sizes = {
  s: 36,
  m: 48,
};

export const EviScore = memo(
  ({
    breakpoints = defaultBreakpoints,
    disableLabel,
    size = 's',
    score,
  }: Props) => {
    const sizeValue = sizes[size];
    const scorePercentage = formatNumberPercentage(score, { decimal: 0 });
    const breakpointKey = getBreakpointKey(breakpoints, score);

    const { gradient, image, label } = breakpointConfigs[breakpointKey];
    const alt = `${label} (${score})`;

    const componentStyles = {
      bordered: {
        border: 'double 4px transparent',
        borderRadius: 'round',
        backgroundImage: gradient,
        backgroundClip: 'content-box, border-box',
      },
    };

    const img = <Image alt={alt} h={sizeValue} image={image} w={sizeValue} />;

    const content = disableLabel ? (
      <Box styles={componentStyles.bordered}>
        <Box p={2}>{img}</Box>
      </Box>
    ) : (
      <Layout align="center" direction="column">
        {img}
        <Text variant="body-bold">{scorePercentage}</Text>
      </Layout>
    );

    return (
      <Tooltip tooltip={disableLabel ? scorePercentage : null}>
        {content}
      </Tooltip>
    );
  },
);
