import React, { useMemo, useState } from 'react';

import { Panel_DEPRECATED, types } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { usePermission } from '~/hooks';

import { Mode } from '../types';
import { PromptResultPanel } from './PromptResultPanel';

interface Props {
  actions: types.Action[];
  width: 'm' | 'l' | 'fill';
  onHide: () => void;
}

export const GenAiPanel = ({ actions, width, onHide }: Props) => {
  // This panel has been deprecated and will have no users on it. Add permission check just for safety.
  const { hasPermission: hasGenAiPermission } = usePermission({
    permission: {
      resourceId: 'ai_drafting_tools',
      resourceType: 'edit',
    },
  });
  const enableGenAi = useFlag(FlagType.GenAi);
  const enableGenAiRedline = useFlag(FlagType.GenAiRedline);

  const [_mode, setMode] = useState<Mode>('redline');

  const tabs = useMemo(() => {
    const tabs = [];
    if (enableGenAiRedline && hasGenAiPermission) {
      tabs.push({
        forceRender: true,
        label: 'Automated Redlining',
        value: 'redline',
        panel: <PromptResultPanel mode="redline" />,
      });
    }
    if (enableGenAi && hasGenAiPermission) {
      tabs.push({
        forceRender: true,
        label: 'Clause Creator',
        value: 'clause',
        panel: <PromptResultPanel mode="clause" />,
      });
    }
    return tabs;
  }, [enableGenAi, enableGenAiRedline, hasGenAiPermission]);

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      isEmbedded
      isVisible
      actions={actions}
      tabs={tabs}
      title="Auto-Generate"
      width={width}
      onHide={onHide}
      onSelectTab={setMode}
    />
  );
};
