import { capitalize, sortBy } from 'lodash';

import { FORM_INFO_TAB_INDEX } from '~/constants/ticket';
import {
  Box,
  formatDate,
  getFileBasename,
  getFileExtension,
  getUserName,
  Link,
  Text,
  types,
  User,
} from '~/eds';
import {
  EsignatureType,
  InboundAttachmentException,
  TicketActivityType,
  TicketStageType,
  TicketStatusType,
} from '~/enums';
import { RoutePathType } from '~/routing';
import {
  ActivityLogEvent,
  ActivityLogUser,
  Nullable,
  PilotId,
  TicketDocumentVersion,
  Uuid,
} from '~/types';
import { joinWith } from '~/utils/array';

import { AttachmentData, CustomActions } from './types';

function getPlaceholder(data: Array<string | number>) {
  return `{{${data.join(':')}}}`;
}

function getEventActor(event: ActivityLogEvent) {
  return getPlaceholder(['user', event.user.id]);
}

const joinStrings = (array: string[]) =>
  joinWith(array, { delimiter: ', ', lastDelimiter: ' and ' });

function getSharedVersionsMessage(
  attachments: AttachmentData[],
  ticketId: Uuid,
) {
  const existingVersionsSorted = sortBy(
    attachments.filter((attachment) => attachment.type === 'existingVersion'),
    (attachment) => attachment.tag,
  ).map(
    (version) => `${getPlaceholder(['versionLink', version.tag!, ticketId])}`,
  );

  return joinStrings(existingVersionsSorted);
}

function getShareRecipientsMessage(emails: string[]) {
  return joinStrings(
    emails.map((email) => `${getPlaceholder(['bold', email])}`),
  );
}

function formatEventPhaseAndTask(phase: string, task: string) {
  return getPlaceholder(['bold', `${phase} -- ${task}`]);
}

export const tokenRenderer = (
  users: Record<PilotId, types.User>,
  customActions: CustomActions = {},
  versions: TicketDocumentVersion[],
) => ({
  bold: (value: string) => <Text variant="body-bold">{value}</Text>,
  versionLink: (value: string) => {
    const [versionNumber, ticketId] = value.split(':');
    const isValidVersion = versions.some(
      (documentVersion) =>
        documentVersion.versionNumber === Number(versionNumber),
    );
    if (isValidVersion) {
      return (
        <Link
          variant="body"
          pathname={RoutePathType.WorkflowTicketsTicketReviewerVersionNumber.replace(
            ':ticketId',
            ticketId,
          ).replace(':versionNumber', versionNumber)}
        >
          version {versionNumber}
        </Link>
      );
    } else {
      return `version ${versionNumber}`;
    }
  },
  intakeFormLink: (value: string) => {
    const [ticketId] = value.split(':');

    return customActions.intakeFormAction ? (
      <Link variant="body" onClick={customActions.intakeFormAction}>
        form information
      </Link>
    ) : (
      <Link
        variant="body"
        pathname={RoutePathType.WorkflowTicketsTicket.replace(
          ':ticketId',
          ticketId,
        )}
        search={`activeTab=${FORM_INFO_TAB_INDEX}`}
      >
        form information
      </Link>
    );
  },
  user: (value: string) => {
    const [userId] = value.split(':');
    const user = users[parseInt(userId)];
    return user ? (
      <Box display="inline-block">
        <User
          mode="name"
          user={{
            firstName: user.firstName,
            id: user.id,
            lastName: user.lastName,
            email: null,
          }}
          options={{ isDeleted: user.isDeleted, shouldTruncate: false }}
        />
      </Box>
    ) : (
      <Text variant="body-bold">Deactivated User</Text>
    );
  },
  breakLine: () => {
    return <br />;
  },
  indent: () => {
    return <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
  },
});

export const tokenSearchRenderer = (users: Record<PilotId, types.User>) => ({
  bold: (value: string) => value,
  versionLink: (value: string) => {
    const [versionNumber, _ticketId] = value.split(':');
    return `version ${versionNumber}`;
  },
  user: (value: string) => {
    const [userId] = value.split(':');
    const user = users[parseInt(userId)];
    return user ? getUserName(user) : 'Deactivated User';
  },
});

export function getQuote(event: ActivityLogEvent): Nullable<string> {
  switch (event.action) {
    case TicketActivityType.JudgmentResultStatusUpdate:
    case TicketActivityType.DocumentUpload:
      return event.data.comment;
    case TicketActivityType.CancelTicket:
      return event.data.reason;
    default:
      return null;
  }
}

export function getActivityLogContent(
  event: ActivityLogEvent,
  versions: TicketDocumentVersion[],
  ticketId: Uuid,
) {
  switch (event.action) {
    case TicketActivityType.AddParticipant:
      return `${getEventActor(event)} added ${getPlaceholder([
        'user',
        event.data.user.id,
      ])} to the ticket.`;
    case TicketActivityType.CancelEnvelope: {
      const { esignatureProvider } = event.data;
      return esignatureProvider
        ? `${getEventActor(event)} canceled the ${getEsigProviderText(
            esignatureProvider,
          )} signatures.`
        : `${getEventActor(event)} canceled the signatures.`;
    }
    case TicketActivityType.CancelTicket:
      return `${getEventActor(event)} cancelled this ticket.`;
    case TicketActivityType.CollectSignatures:
      return `${getEventActor(
        event,
      )} is sending this document to collect signatures.`;
    case TicketActivityType.CollectSignaturesCancelled:
      return `${getEventActor(event)} canceled signature collection.`;
    case TicketActivityType.CoordinatorReassignment:
      return `${getEventActor(event)} reassigned ${capitalize(
        event.data.stage,
      )} Coordinator to ${getPlaceholder(['user', event.data.newUser.id])}.`;
    case TicketActivityType.CreateEnvelope: {
      const { esignatureProvider } = event.data;
      return esignatureProvider
        ? `${getEventActor(
            event,
          )} is sending this ticket to collect signatures via ${getEsigProviderText(
            esignatureProvider,
          )}.`
        : `${getEventActor(
            event,
          )} is sending this ticket to collect signatures.`;
    }
    case TicketActivityType.CreateTicket:
      return `${getEventActor(event)} created this ticket.`;
    case TicketActivityType.DeclineEnvelope:
      return `${getEventActor(event)} declined to sign the document.`;
    case TicketActivityType.DeleteVersion:
      return `${getEventActor(event)} deleted version ${
        event.data.version.tag
      }`;
    case TicketActivityType.DocumentEdit:
      const {
        fileName: documentEditFileName,
        platform: editorType = 'Evisort',
        users: editors = [],
      } = event.data;
      const formattedFileName = getFileBasename(documentEditFileName);
      const editorPlaceholders = editors.map((editor: ActivityLogUser) =>
        getPlaceholder(['user', editor.id]),
      );
      const editorString = editors.length
        ? joinStrings(editorPlaceholders)
        : getEventActor(event);
      return `${editorString} edited ${formattedFileName} in ${editorType}.`;
    case TicketActivityType.DocumentSave:
      const { comment, fileName } = event.data;
      if (comment === TicketActivityType.ConvertedDocument) {
        const formattedFileName = getFileBasename(fileName);
        const version = versions.find(
          (version) => version.id === event.data.version.id,
        );
        return `${getEventActor(event)} converted ${getPlaceholder([
          'bold',
          formattedFileName,
        ])} into a docx format which generated ${getPlaceholder([
          'versionLink',
          version ? version.versionNumber : '',
          ticketId,
        ])}`;
      } else if (event.data.referenceVersionId !== event.data.targetVersionId) {
        return `${getEventActor(
          event,
        )} uploaded a new document with redlines, creating ${getPlaceholder([
          'versionLink',
          event.data.version.tag,
          ticketId,
        ])}`;
      } else {
        return `${getEventActor(event)} saved document as ${getPlaceholder([
          'versionLink',
          event.data.version.tag,
          ticketId,
        ])}`;
      }
    case TicketActivityType.DocumentUpload:
      const fileFormat = getFileExtension(event.data.fileName).substring(1); //Remove the "." from the file extension
      return `${getEventActor(
        event,
      )} uploaded a ${fileFormat.toUpperCase()} to ${getPlaceholder([
        'versionLink',
        event.data.version.tag,
        ticketId,
      ])}`;
    case TicketActivityType.DownloadVersion:
    case TicketActivityType.DownloadDocument:
      return `${getEventActor(event)} downloaded ${getPlaceholder([
        'versionLink',
        event.data.version.tag,
        ticketId,
      ])}`;
    case TicketActivityType.Esignature:
      const { externalUser, internalUser } = event.data;
      const signatureUser = internalUser
        ? `${getPlaceholder(['user', internalUser.id])}`
        : `${getPlaceholder(['bold', externalUser.name])}`;
      return `${signatureUser} signed the document via eSignature.`;
    case TicketActivityType.InboundAttachmentAccepted: {
      const {
        counterpartySenderEmail,
        fileName,
        version,
        fileSize,
      } = event.data;
      const message = fileSize
        ? 'via email submission, and its file size exceeds limitation in e-signature'
        : 'via email submission';

      return `${getPlaceholder([
        'bold',
        counterpartySenderEmail,
      ])} uploaded ${getPlaceholder([
        'bold',
        fileName,
      ])} which generated ${getPlaceholder([
        'versionLink',
        version.tag,
        ticketId,
      ])} ${message}.`;
    }
    case TicketActivityType.InboundAttachmentRejected: {
      const {
        counterpartySenderEmail,
        exception,
        stageAttempted,
        ticketName,
        totalAttachments,
      } = event.data;
      let message = '';
      switch (exception) {
        case InboundAttachmentException.WrongStageException: {
          message = ` in ${stageAttempted}`;
          break;
        }
        case InboundAttachmentException.TicketAlreadyCompletedException: {
          message = ` because ${ticketName} was completed`;
          break;
        }
        case InboundAttachmentException.TicketCancelledException: {
          message = ` because ${ticketName} was cancelled`;
          break;
        }
        case InboundAttachmentException.NoneOrMultipleAttachmentsException: {
          const quantifier =
            totalAttachments > 0 ? 'multiple attachments' : 'no attachment';
          message = ` for adding ${quantifier}`;
          break;
        }
        default: {
          message = '';
        }
      }

      return `Email submission sent in by ${getPlaceholder([
        'bold',
        counterpartySenderEmail,
      ])} was rejected${message}.`;
    }
    case TicketActivityType.IntakeFormEdit:
      return `${getEventActor(event)} edited the ${getPlaceholder([
        'intakeFormLink',
        ticketId,
      ])}.`;
    case TicketActivityType.JudgmentResultAssignment:
      return `${getPlaceholder([
        'user',
        event.data.newUser.id,
      ])} has been assigned approval of ${formatEventPhaseAndTask(
        event.data.phase.name,
        event.data.name,
      )}.`;
    case TicketActivityType.JudgmentReminderSent:
      return `${getEventActor(
        event,
      )} sent a reminder for ${formatEventPhaseAndTask(
        event.data.phase,
        event.data.name,
      )}
      to ${getPlaceholder(['bold', event.data.recipient_name])}`;
    case TicketActivityType.JudgmentResultReassignment:
      if (event.data.currentStage === TicketStageType.Sign) {
        return `${getEventActor(
          event,
        )} reassigned an approval to ${getPlaceholder([
          'user',
          event.data.newUser.id,
        ])}.`;
      }
      return `${getEventActor(
        event,
      )} reassigned approval of ${formatEventPhaseAndTask(
        event.data.phase.name,
        event.data.name,
      )} to ${getPlaceholder(['user', event.data.newUser.id])}.`;
    case TicketActivityType.JudgmentResultStatusUpdate:
      return `${getEventActor(
        event,
      )} updated the status of ${formatEventPhaseAndTask(
        event.data.phase.name,
        event.data.name,
      )} to ${getPlaceholder(['bold', getStatusText(event.data.status)])}.`;
    case TicketActivityType.NextStage:
      const { newStage, oldStage } = event.data;
      return `${getEventActor(event)} sent the ticket from ${getPlaceholder([
        'bold',
        oldStage,
      ])} to ${getPlaceholder(['bold', newStage])}.`;
    case TicketActivityType.DownloadRedline:
      const version = versions.find(
        (version) => version.id === event.data.targetVersionId,
      );
      return `${getEventActor(
        event,
      )} downloaded the redlines for ${getPlaceholder([
        'versionLink',
        version ? version.versionNumber : '',
        ticketId,
      ])}.`;
    case TicketActivityType.PreviousStage:
      return `${getEventActor(event)} sent the ticket back to ${getPlaceholder([
        'bold',
        event.data.newStage,
      ])}.`;
    case TicketActivityType.RemoveParticipant:
      return `${getEventActor(event)} removed ${getPlaceholder([
        'user',
        event.data.user.id,
      ])} from the ticket.`;
    case TicketActivityType.RenameTicket:
      return `${getEventActor(event)} renamed ${event.data.oldName} to ${
        event.data.newName
      }`;
    case TicketActivityType.ResetApprovals:
      return `${getEventActor(event)} reset all signatures.`;
    case TicketActivityType.ShareDocument:
      let message;
      if (event.type === 'ticket') {
        message = `${getEventActor(event)} shared ${getSharedVersionsMessage(
          event.data.attachments,
          ticketId,
        )} with ${getShareRecipientsMessage(event.data.emails)}.`;
      } else {
        message = `${getEventActor(event)} shared ${getPlaceholder([
          'versionLink',
          event.data.version.tag,
          ticketId,
        ])} with ${getShareRecipientsMessage(event.data.emails)}.`;
      }
      return message;
    case TicketActivityType.SignatureUpload:
      return `${getEventActor(event)} uploaded a document with signature(s)`;
    case TicketActivityType.SignerReassignment:
      return `${getEventActor(
        event,
      )} reassigned the signature to ${getPlaceholder([
        'user',
        event.data.newSigner.id,
      ])}`;
    case TicketActivityType.TicketCompletion:
      return `${getEventActor(event)} completed the ticket workflow.`;
    case TicketActivityType.VersionBumpForEditing:
      return `${getEventActor(event)} created ${getPlaceholder([
        'versionLink',
        event.data.version.tag,
        ticketId,
      ])} for editing.`;
    case TicketActivityType.TurnTrackEdit:
      const previousStartTime = formatDate(
        new Date(event.data.previousStartTime),
        'full',
      );
      const previousEndTime = event.data.previousEndTime
        ? formatDate(new Date(event.data.previousEndTime), 'full')
        : 'present';
      const newStartTime = formatDate(
        new Date(event.data.newStartTime),
        'full',
      );
      const newEndTime = event.data.newEndTime
        ? formatDate(new Date(event.data.newEndTime), 'full')
        : 'present';
      return `${getEventActor(event)} edited the time for  ${
        event.data.party
      } ${getPlaceholder(['breakLine', ''])} 
      From: ${getPlaceholder(['breakLine', ''])}
      ${getPlaceholder(['indent', ''])} ${getPlaceholder([
        'bold',
        previousStartTime,
      ])} and ${getPlaceholder(['bold', previousEndTime])} ${getPlaceholder([
        'breakLine',
        '',
      ])}
      To: ${getPlaceholder(['breakLine', ''])}
      ${getPlaceholder(['indent', ''])} ${getPlaceholder([
        'bold',
        newStartTime,
      ])} and ${getPlaceholder(['bold', newEndTime])}`;
    default:
      return null;
  }
}

function getEsigProviderText(esignatureProvider: EsignatureType): string {
  switch (esignatureProvider) {
    case EsignatureType.Docusign:
      return 'DocuSign';
    case EsignatureType.Adobesign:
      return 'AdobeSign';
    default:
      return 'Esignature';
  }
}

function getStatusText(status: TicketStatusType): string {
  switch (status) {
    case TicketStatusType.Approved:
      return 'Approved';
    case TicketStatusType.Rejected:
      return 'Rejected';
    case TicketStatusType.Done:
      return 'Completed';
    case TicketStatusType.Pending:
      return 'Pending';
    default:
      return '';
  }
}
