import React from 'react';
import { Redirect } from 'react-router-dom';

import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { getLandingPage } from '~/permissions';

const Page = () => {
  const user = useCurrentUser();
  const searchAsHomePage = useFlag(FlagType.SearchAsHomePage);
  const landing = getLandingPage(
    user.role_uuid?.permissions || [],
    user.client,
  );

  if (searchAsHomePage && landing === '/documents')
    return <Redirect to={{ pathname: '/search' }} />;

  return <Redirect to={{ pathname: landing }} />;
};

export default Page;
