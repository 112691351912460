import PropTypes from 'prop-types';
import React, { useState } from 'react';
import injectSheet from 'react-jss';

import { sendForgotPasswordEmail } from '~/api';
import {
  black2,
  evisortRed,
  evisortYellow,
  semibold,
} from '~/assets/shared-styles/general';
import { LoginPageContainer } from '~/components/LoginPage';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { QueryParamType } from '~/enums';
import { useAsync } from '~/hooks';
import { Alert } from '~/ui';
import { isEmailValid } from '~/utils/helper.utils';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';

function Page(props) {
  const { classes } = props;

  const workspace = getPageSearchQueryByKey(QueryParamType.Workspace);

  const [email, setEmail] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState();

  const { executor: handleForgotPasswordEmail, isLoading } = useAsync(
    sendForgotPasswordEmail,
    { client: workspace, userId: email },
    {
      successHandler: () => {
        setEmailSent(true);
      },
      errorHandler: () => {
        setError(true);
      },
    },
  );

  return (
    <div>
      {emailSent ? (
        <LoginPageContainer classes={classes} title="Check your inbox">
          <Alert enableIcon variant="success">
            You will receive reset link shortly. You may need to check your spam
            folder or unblock evisort@evisort.com
          </Alert>
        </LoginPageContainer>
      ) : (
        <LoginPageContainer classes={classes} title="Reset password">
          <div className={classes.contentCopy}>
            Enter your email address to reset your password. You may need to
            check your spam folder or unblock evisort@evisort.com
          </div>
          {error && (
            <div className={classes.errorMessage}>
              <Alert enableIcon variant="danger">
                An error ocurred. Please try again.
              </Alert>
            </div>
          )}
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <form onSubmit={handleForgotPasswordEmail}>
              <div>
                <input
                  autoFocus={true}
                  type="text"
                  value={email}
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  className={classes.textInput}
                />
              </div>
              <div className={classes.formButtonContainer}>
                <button
                  type="submit"
                  className={classes.formButton}
                  onClick={handleForgotPasswordEmail}
                  disabled={!isEmailValid(email)}
                >
                  Send password reset link
                </button>
              </div>
            </form>
          )}
        </LoginPageContainer>
      )}
    </div>
  );
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  formButtonContainer: {
    marginTop: '20px',
  },
  formButton: {
    padding: '10px 20px',
    width: '360px',
    display: 'inline-block',
    backgroundColor: evisortYellow,
    border: 'solid 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    color: black2,
    fontSize: '15px',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:disabled': {
      opacity: 0.5,
    },
  },
  loginFormLabel: {
    marginBottom: '8px',
    fontSize: '15px',
    fontWeight: semibold,
    textAlign: 'left',
  },
  textInput: {
    margin: '10px 0',
    padding: '10px',
    width: '360px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    color: black2,
    fontSize: '16px',
    '&:placeholder': {
      opacity: 0.5,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  contentCopy: {
    margin: '0 auto 30px',
    width: '360px',
    color: black2,
    fontSize: '14px',
    textAlign: 'left',
    lineHeight: '22px',
  },
  errorMessage: {
    margin: '20px',
    color: evisortRed,
    fontSize: '15px',
  },
};

export default injectSheet(styles)(Page);
