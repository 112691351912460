import React, { memo } from 'react';

import { colors } from '../../tokens';
import { StatusType } from '../../types';
import { Box } from '../Box';
import { Tooltip } from '../Tooltip';

interface Props {
  /** Max value */
  max: number;
  /** Unique identifier for the Donut */
  name: string;
  /** Current value */
  value: number;
  /** Size of donut */
  size?: 's';
  /** Status of donut */
  status?: StatusType;
  /** Size of strokeWidth (in px) */
  strokeWidth?: number;
  /** Optional tooltip */
  tooltip?: string;
}

const radii = {
  s: 15,
};

export const Donut = memo(
  ({
    max,
    name,
    size = 's',
    status = 'active',
    strokeWidth = 4,
    tooltip,
    value,
  }: Props) => {
    const cr = radii[size] ?? radii.s; // outer radius
    const r = cr - strokeWidth / 2; // inner radius
    const c = 2 * Math.PI * r;

    return (
      <Tooltip tooltip={tooltip}>
        <Box
          as="svg"
          aria-valuenow={value}
          aria-valuemax={max}
          id={name}
          role="progressbar"
          styles={{
            height: 2 * cr,
            transform: 'rotate(-90deg)',
            width: 2 * cr,
          }}
        >
          <Box
            as="circle"
            cx={cr}
            cy={cr}
            r={r}
            styles={{
              fill: 'transparent',
              stroke: colors[`status.${status}.secondary`],
              strokeWidth,
            }}
          />
          <Box
            as="circle"
            cx={cr}
            cy={cr}
            r={r}
            styles={{
              fill: 'transparent',
              stroke: colors[`status.${status}`],
              strokeDasharray: `${c} ${c}`,
              strokeDashoffset: (1 - value / max) * c,
              strokeWidth,
            }}
          />
        </Box>
      </Tooltip>
    );
  },
);
