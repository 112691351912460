import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { samlFormValueUpdate } from '~/actions';
import { Layout, Section, StatusMessage, Text } from '~/eds';
import { FormField, FormSection as FormSectionType, Mapping } from '~/types';

import { CustomAttributes } from './CustomAttributes';
import { getFieldErrors } from './FormSection.utils';
import { LongResponseField } from './LongResponseField';
import { MultiSelectField } from './MultiSelectField';
import { RolesSelectField } from './RolesSelectField';
import { ShortResponseField } from './ShortResponseField';
import { SingleSelectField } from './SingleSelectField';
import { StaticField } from './StaticField';
import { ToggleField } from './ToggleField';
import { UserAttributesField } from './UserAttributesField';

type Props = {
  clientId?: string;
  section: FormSectionType;
  onExplain?: (fieldId: string) => void;
};

export const FormSection = ({ clientId, section, onExplain }: Props) => {
  const dispatch = useDispatch();

  const handleChange = (formField: FormField<any>, value: any) => {
    dispatch(
      samlFormValueUpdate({
        sectionId: section.id,
        configId: formField.id,
        value: value,
      }),
    );
  };

  const renderField = (formField: FormField<any>) => {
    if (formField.visible === false) return null;
    switch (formField.field.type) {
      case 'STATIC':
        return <StaticField onExplain={onExplain} formField={formField} />;
      case 'SHORT_RESPONSE':
        return (
          <ShortResponseField
            errors={getFieldErrors(formField)}
            onExplain={onExplain}
            onChange={(value) => handleChange(formField, value)}
            formField={formField}
          />
        );
      case 'LONG_RESPONSE':
        return (
          <LongResponseField
            errors={getFieldErrors(formField)}
            onExplain={onExplain}
            onChange={(value) => handleChange(formField, value)}
            formField={formField}
          />
        );
      case 'TOGGLE':
        return (
          <ToggleField
            onExplain={onExplain}
            onChange={(value) => handleChange(formField, value)}
            formField={formField}
          />
        );

      case 'ATTRIBUTES':
        return (
          <UserAttributesField
            formField={formField}
            onExplain={onExplain}
            onChange={(value) => handleChange(formField, value)}
          />
        );
      case 'SINGLE_SELECT':
        return (
          <SingleSelectField
            errors={getFieldErrors(formField)}
            formField={formField}
            onExplain={onExplain}
            onChange={(value) => handleChange(formField, value)}
          />
        );
      case 'MULTI_SELECT':
        return (
          <MultiSelectField
            errors={getFieldErrors(formField)}
            formField={formField}
            onExplain={onExplain}
            onChange={(value) => handleChange(formField, value)}
          />
        );

      case 'ROLE_SELECT':
        return (
          <RolesSelectField
            clientId={clientId}
            errors={getFieldErrors(formField)}
            formField={formField}
            onExplain={onExplain}
            onChange={(value) => handleChange(formField, value)}
          />
        );

      case 'CUSTOM_ATTRIBUTES':
        const { mappings } = formField.field.value;
        const initialValue = mappings
          ? mappings
              .filter((mapping: Mapping) => mapping.type === 'custom')
              .reduce((acc: Record<string, string>, mapping: Mapping) => {
                acc[mapping.saml_attr] = mapping.evisort_custom_attr;
                return acc;
              }, {})
          : undefined;
        return (
          <CustomAttributes
            initialValue={initialValue}
            formField={formField}
            onExplain={onExplain}
            onChange={(value) => handleChange(formField, value)}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Layout direction="column" spacing={4}>
      <Layout preset="sections">
        <Section title={section.title}>
          <Text>{section.description}</Text>
          {section.alerts?.map((alert, index) => (
            <StatusMessage
              key={`${alert.message}_${index}`}
              message={alert.message}
              status={alert.status}
            />
          ))}
        </Section>
      </Layout>
      <Layout direction="column" spacing={4}>
        {section.configs.map((formField: FormField<any>) => {
          return (
            <Fragment key={formField.id}>{renderField(formField)}</Fragment>
          );
        })}
      </Layout>
    </Layout>
  );
};
