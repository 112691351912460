// https://medium.com/takeaway-tech/the-search-for-a-regex-to-match-hyphenated-bem-css-class-names-5f8b66cc2bd9
export const BEM_REGEXP = /^.[a-z]([a-z0-9-]+)?(__([a-z0-9]+-?)+)?(--([a-z0-9]+-?)+){0,2}$/;

// this will take any email present on the string that starts with "+"
// will also accept +john+doe@email.com but not jonh+doe@email.com
// this way we make sure to only find the mentioned users.
export const EMAIL_MENTION_REGEXP = /(([^A-Za-z0-9]\+)|(^))([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;

/** REGEX to validate an email based on RFC 5322 Format */
// eslint-disable-next-line max-len
export const EMAIL_REGEXP = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const URL_REGEXP =
  '^(https?:\\/\\/)?(www\\.)?[a-z0-9\\-\\.]+\\.[a-z]{2,}(\\/.*?)?$';

export const EVISORT_URL_REGEXP = /^(https?:\/\/)?([\w-]+\.)*evisort\.com(\/.*)?$/;
