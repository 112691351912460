export default `<svg
  width="12"
  height="16"
  aria-hidden="true"
  aria-label="file-magnifying-glass"
  viewBox="0 0 12 16"
  fill="none"
>
  <g clip-path="url(#clip0_1694_21368)">
    <path
      d="M7.5 10C7.5 9.46957 7.28929 8.96086 6.91421 8.58579C6.53914 8.21071 6.03043 8 5.5 8C4.96957 8 4.46086 8.21071 4.08579 8.58579C3.71071 8.96086 3.5 9.46957 3.5 10C3.5 10.5304 3.71071 11.0391 4.08579 11.4142C4.46086 11.7893 4.96957 12 5.5 12C6.03043 12 6.53914 11.7893 6.91421 11.4142C7.28929 11.0391 7.5 10.5304 7.5 10ZM7.2375 12.4469C6.74688 12.7937 6.14687 13 5.5 13C3.84375 13 2.5 11.6562 2.5 10C2.5 8.34375 3.84375 7 5.5 7C7.15625 7 8.5 8.34375 8.5 10C8.5 10.6469 8.29375 11.2469 7.94688 11.7375L9.35625 13.1469C9.55 13.3406 9.55 13.6594 9.35625 13.8531C9.1625 14.0469 8.84375 14.0469 8.65 13.8531L7.24062 12.4438L7.2375 12.4469ZM11 14V6H7.5C6.67188 6 6 5.32812 6 4.5V1H2C1.44687 1 1 1.44687 1 2V14C1 14.5531 1.44687 15 2 15H10C10.5531 15 11 14.5531 11 14ZM10.9844 5C10.9625 4.9125 10.9187 4.83125 10.8531 4.76875L7.23125 1.14688C7.16562 1.08125 7.0875 1.0375 7 1.01562V4.5C7 4.775 7.225 5 7.5 5H10.9844ZM0 2C0 0.896875 0.896875 0 2 0H6.87813C7.275 0 7.65625 0.159375 7.9375 0.440625L11.5594 4.05937C11.8406 4.34062 12 4.72188 12 5.11875V14C12 15.1031 11.1031 16 10 16H2C0.896875 16 0 15.1031 0 14V2Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_1694_21368">
      <rect width="12" height="16" fill="currentColor" />
    </clipPath>
  </defs>
</svg>
`;
