import React, { useEffect, useState } from 'react';
import injectSheet from 'react-jss';

import { logout } from '~/api';
import AuthenticationStore from '~/auth';
import EcCard from '~/components/Shared/EcCard';
import { Button } from '~/eds';
import { withCurrentUser } from '~/hocs';
import {
  redirectToDomainLogin,
  redirectToLoginEmbed,
} from '~/services/redirects';
import { User } from '~/types';

import styles from './AuthRequireWarningModal.styles';

const AuthRequireWarningModal = ({
  currentUser,
  classes,
}: {
  currentUser: User;
  classes: any;
}) => {
  const AS = AuthenticationStore();
  const { pathname } = window.location;

  const userConfigInactiveTimeout =
    currentUser?.client_config?.inactive_timeout_in_seconds;
  const inactiveTimeoutInSeconds =
    userConfigInactiveTimeout ?? AS.constants.S_UNTIL_INACTIVE_TIMEOUT;

  const [showLogoutPopup, setShowLogoutPopup] = useState(false);

  const clearSession = () => logout().finally(() => AS.clearAuthentication());
  const resetInactiveTimer = () =>
    AS.resetInactiveTimer(inactiveTimeoutInSeconds);

  const getSubdomainInSession = () => {
    return AS.getSubDomainInSession();
  };
  const getSubdomainOnTab = () => {
    const { pathname } = window.location;
    return pathname.split('/')[1];
  };
  const checkIsSubdomainMatch = () => {
    const subdomainOnTab = getSubdomainOnTab();
    const subdomainInSession = getSubdomainInSession();
    return (
      subdomainInSession &&
      subdomainOnTab &&
      subdomainInSession === subdomainOnTab
    );
  };

  const onReloadDifferentSubdomain = () => {
    const subdomainOnTab = getSubdomainOnTab();
    const subdomainInSession = getSubdomainInSession();

    if (!subdomainInSession || !subdomainOnTab) return;
    const { origin, pathname, search } = window.location;
    const path = pathname.replace(subdomainOnTab, subdomainInSession);

    window.location.href = `${origin}${path}${search}`;
  };

  useEffect(() => {
    resetInactiveTimer();

    document.addEventListener('mousemove', resetInactiveTimer);
    document.addEventListener('keypress', resetInactiveTimer);
    return () => {
      document.removeEventListener('mousemove', resetInactiveTimer);
      document.removeEventListener('keypress', resetInactiveTimer);
    };
  }, []);

  useEffect(() => {
    setInterval(() => {
      const isSessionExpired = AS.isAuthSessionExpired();
      const isInactiveTimeout = AS.isInactiveTimeoutReached();
      const isUserLoggedIn = AS.isLoggedIn();

      if (isSessionExpired || isInactiveTimeout || !isUserLoggedIn) {
        setShowLogoutPopup(true);

        if (isInactiveTimeout && isUserLoggedIn) clearSession();
      } else {
        if (!AS.isEmbed()) {
          // no need to check subdomain for embed sessions controlled by iframes
          setShowLogoutPopup(!checkIsSubdomainMatch());
        }
      }
    }, 1000);
  }, []);

  if (pathname.includes('folders-permission-check') || !showLogoutPopup)
    return null;

  // TODO: use modal from eds instead once we have way to resolve
  //       cover issue of page overlays
  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modalWrapper}>
        <EcCard>
          <div className={classes.modalBody}>
            <div className={classes.modalBodyTitle}>Sign in Required</div>
            <div className={classes.modalBodyContent}>
              <p>
                {' '}
                You were signed out of your account. Press “Reload” to sign in
                to Evisort again.
              </p>
              <div className={classes.modalActions}>
                <Button
                  variant="primary"
                  text="Reload"
                  onClick={() => {
                    if (checkIsSubdomainMatch() && !AS.isEmbed()) {
                      window.location.reload();
                    } else {
                      const subdomainInSession = getSubdomainInSession();
                      if (subdomainInSession) {
                        onReloadDifferentSubdomain();
                      } else {
                        AS.isEmbed()
                          ? redirectToLoginEmbed()
                          : redirectToDomainLogin();
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </EcCard>
      </div>
    </div>
  );
};

export default injectSheet(styles)(withCurrentUser(AuthRequireWarningModal));
