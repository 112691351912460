import PropTypes from 'prop-types';
import React from 'react';

const StopIcon = (props) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      onClick={props.onClick}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" opacity=".87" />
        <path
          fill={props.color}
          fillRule="nonzero"
          d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3a.996.996 0 0 1-1.41 0L12 13.41 9.11 16.3a.996.996 0 1 1-1.41-1.41L10.59 12 7.7 9.11A.996.996 0 1 1 9.11 7.7L12 10.59l2.89-2.89a.996.996 0 1 1 1.41 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z"
        />
      </g>
    </svg>
  );
};

StopIcon.defaultProps = {
  size: '24',
  color: '#fdfdfd',
};

StopIcon.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

export default StopIcon;
