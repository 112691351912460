import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { getCurrentUser } from '~/api';
import { black2 } from '~/assets/shared-styles/general';
import AdminConsolePageNavHeader from '~/components/Admin/AdminConsolePageNavHeader';
import ImportJobsPolling from '~/components/Admin/ImportJobsPolling';
import {
  BulkImportDocumentMetadataWizard,
  DocumentMetadataStatusBarList,
  styles,
  updateClientAdmin,
} from '~/components/Admin/MoreSettingsPage';
import {
  getFolderACL,
  updateFolderAccessSettings,
} from '~/components/DocumentsViewPage/Document.data';
import EcCheckbox from '~/components/Shared/EcCheckbox';
import EcDepartmentSelectValueWithControls from '~/components/Shared/EcDepartmentSelectValueWithControls';
import EcModal from '~/components/Shared/EcModal';
import EcRadioButton from '~/components/Shared/EcRadioButton';
import EcRadioContainer from '~/components/Shared/EcRadioContainer';
import { showToast } from '~/components/Shared/EcToast';
import EcUserNameSearch from '~/components/Shared/EcUserNameSearch';
import CheckmarkIcon from '~/components/Shared/Icons/CheckmarkIcon';
import { Box, Button, ContentContainer, Layout, Link } from '~/eds';
import { FeatureFlagType } from '~/enums';
import { withFlags } from '~/flags';
import { withPermissions } from '~/hocs';
import { testHasFlag } from '~/permissions';
import { actions } from '~/redux';
import { withRouting } from '~/routing';
import { MODAL_DEPARTMENT_TREE } from '~/types/modal.types';
import { ERROR, SUCCESS, WARNING } from '~/types/toast.types';
import { Card, CheckboxGroup, FlexLayout, Text } from '~/ui';
import { parseApiError } from '~/utils/parseApiError';

import SessionTimeout from './session-timeout';

const PERMISSIONS = [
  {
    resourceId: 'root_folder_access_settings',
    resourceType: 'view',
  },
  {
    resourceId: 'documents_metadata_bulk_upload',
    resourceType: 'view',
  },
  {
    resourceId: 'clause_quick_ai',
    resourceType: 'view',
  },
  {
    resourceId: 'deleted_documents',
    resourceType: 'view',
  },
];

class Page extends Component {
  constructor(props) {
    super(props);

    this.navigateBack = this.navigateBack.bind(this);
    this.handleAllowUserDownloadChange = this.handleAllowUserDownloadChange.bind(
      this,
    );
    this.handleRootFolderAccessSubmit = this.handleRootFolderAccessSubmit.bind(
      this,
    );
    this.updateUserPrivilege = this.updateUserPrivilege.bind(this);
    this.updateDepartmentPrivilege = this.updateDepartmentPrivilege.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleRemoveDepartment = this.handleRemoveDepartment.bind(this);
    this.handleClauseTaggingSubmit = this.handleClauseTaggingSubmit.bind(this);

    this.state = {
      isLoadingFolderACL: true,
      isLoadingRootFolderAccess: false,
      isLoadingClauseTagging: false,
      isLoadingDeletedDocumentConfigs: false,
      deptAccessList: [],
      folderAccessList: [],
      selectedOption: 'everyoneViewEdit',
      allowUsersDownloadChecked: false,
      userNameSearchResult: [],
      departmentsSelected: [],
      modal: null,
      visibilityLevel: null,
      showImportWizard: false,
      importedJobId: null,
      clauseTagsAccessMode: [],
      documentRestoreMode: [],
    };
  }

  navigateBack() {
    this.props.navigate(-1);
  }

  componentDidMount() {
    this.initializeACLData();
    this.initializeModes();
  }

  handleApiError = (errorResponse, customCodeError) => {
    const error = parseApiError(errorResponse, customCodeError);
    showToast(ERROR, error.message);
  };

  initializeACLData() {
    const {
      match: {
        params: { clientId },
      },
    } = this.props;

    getFolderACL(null, clientId).then((rootFolderContent) => {
      this.setState({
        folderAccessList: rootFolderContent.folder_access_list?.members || [],
        deptAccessList:
          this.processDepartments(
            rootFolderContent.folder_access_list?.departments,
          ) || [],
        selectedOption: this.renderSelectedOption(
          rootFolderContent.folder_access_list?.departments,
          rootFolderContent.folder_access_list?.members,
          rootFolderContent.visibility_level,
        ),
        allowUsersDownloadChecked:
          rootFolderContent.visibility_level === 'OPEN_NO_EDIT',
        departmentsSelected:
          rootFolderContent.folder_access_list?.departments || [],
        visibilityLevel: rootFolderContent.content_visibility_level,
        isLoadingFolderACL: false,
      });
    });
  }

  initializeModes() {
    const { currentUser } = this.props;
    const documentDeleteReasonRequired = !!currentUser?.client_config
      ?.document_delete_reason_required;

    const clauseTagsAccessMode = [];
    const documentRestoreMode = [];

    if (testHasFlag(FeatureFlagType.DisableDocTagging)(currentUser)) {
      clauseTagsAccessMode.push('restrict_all_users');
    }

    if (documentDeleteReasonRequired) {
      documentRestoreMode.push('reason_required');
    }

    this.setState({
      ...this.state,
      clauseTagsAccessMode,
      documentRestoreMode,
    });
  }
  processDepartments(departments) {
    return departments && departments.length
      ? departments.map((dept) => {
          return {
            id: dept.department_id,
            department_id: dept.department_id,
            department_name: dept.department_name,
            label: dept.department_name,
            value: dept.department_name,
            name: dept.department_name,
            visibility_level: dept.visibility_level || 'OPEN',
          };
        })
      : [];
  }

  handleShowModal(modal) {
    this.setState({ modal });
  }

  handleHideModal() {
    this.setState({ modal: null });
  }

  handleDepartmentChange(departmentsSelected) {
    this.setState({ departmentsSelected });
  }

  handleAllowUserDownloadChange() {
    this.setState((prevState) => ({
      allowUsersDownloadChecked: !prevState.allowUsersDownloadChecked,
    }));
  }

  handleImportedJob(importedJobId) {
    this.setState({ importedJobId });
  }

  handleOptionSelection(selectedValue) {
    const { allowUsersDownloadChecked } = this.state;

    this.setState({
      selectedOption: selectedValue,
      allowUsersDownloadChecked:
        selectedValue !== 'everyoneView' ? false : allowUsersDownloadChecked,
    });
  }

  handleRemoveDepartment(dept) {
    const { departmentsSelected } = this.state;
    const newSelectedDepartments = departmentsSelected.filter(
      (account) => account.department_id !== dept.department_id,
    );

    this.setState({ departmentsSelected: newSelectedDepartments });
  }

  updateDepartmentPrivilege(deptAndAccess) {
    const { departmentsSelected } = this.state;

    if (deptAndAccess) {
      const deptAccessList = departmentsSelected.map((account) =>
        Object.assign(
          account,
          [deptAndAccess].find(
            (dept) => dept.department_name === account.department_name,
          ),
        ),
      );

      this.setState({ deptAccessList });
    }
  }

  updateUserPrivilege(userAndAccess) {
    if (userAndAccess) {
      const folderAccessList = Object.values(userAndAccess).map((user) => {
        return {
          user_id: user.user_id,
          user_name: user.label,
          visibility_level: user.visibility_level || 'OPEN',
        };
      });

      this.setState({ folderAccessList });
    }
  }

  getClientId = () => {
    const {
      match: {
        params: { clientId },
      },
    } = this.props;

    return clientId;
  };

  handleRootFolderAccessSubmit() {
    this.setState({ ...this.state, isLoadingRootFolderAccess: true });

    const {
      match: {
        params: { clientId },
      },
      currentUser,
    } = this.props;
    const {
      selectedOption,
      allowUsersDownloadChecked,
      departmentsSelected,
      folderAccessList,
    } = this.state;

    const visibilityLevel =
      selectedOption === 'everyoneViewEdit' && !allowUsersDownloadChecked
        ? 'OPEN'
        : selectedOption === 'everyoneView' && allowUsersDownloadChecked
        ? 'OPEN_NO_EDIT'
        : selectedOption === 'specificViewEdit'
        ? 'RESTRICTED'
        : selectedOption === 'inherited'
        ? null
        : 'OPEN_NO_EDIT_DOWNLOAD';

    const deptAccessList = departmentsSelected.map((item) => {
      return {
        department_id: item.department_id,
        department_name: item.department_name,
        visibility_level: item.visibility_level || 'OPEN',
      };
    });

    updateFolderAccessSettings(
      currentUser.root_folder,
      'root',
      null,
      visibilityLevel,
      folderAccessList,
      deptAccessList,
      clientId,
    )
      .then(() => {
        showToast(
          SUCCESS,
          'Root folder access settings have been updated.',
          `/admin/console/${clientId}/more-settings`,
        );
        this.initializeACLData();
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          showToast(
            ERROR,
            'There is a folder somewhere in your organization with the same name. Please choose a different name.',
          );
        } else {
          showToast(WARNING, 'An error occurred while updating this folder.');
        }
      })
      .finally(() =>
        this.setState({ ...this.state, isLoadingRootFolderAccess: false }),
      );
  }

  async handleClauseTaggingSubmit() {
    this.setState({ ...this.state, isLoadingClauseTagging: true });

    const {
      match: {
        params: { clientId },
      },
    } = this.props;

    const disableDocTagging = this.state.clauseTagsAccessMode.includes(
      'restrict_all_users',
    );

    updateClientAdmin(clientId, { disableDocTagging })
      .then(() => {
        getCurrentUser().then((currentUser) =>
          this.props.setCurrentUser(currentUser),
        );
        showToast(
          SUCCESS,
          `Clause tagging has been ${
            disableDocTagging ? 'disabled' : 'enabled'
          }.`,
        );
      })
      .catch((_err) => {
        showToast(
          ERROR,
          `An error occurred while ${
            disableDocTagging ? 'disabling' : 'enabling'
          } clause tagging.`,
        );
      })
      .finally(() =>
        this.setState({ ...this.state, isLoadingClauseTagging: false }),
      );
  }

  async handleDeletedDocumentConfigsSubmit() {
    this.setState({ ...this.state, isLoadingDeletedDocumentConfigs: true });

    const {
      match: {
        params: { clientId },
      },
    } = this.props;

    const documentDeleteReasonRequired = this.state.documentRestoreMode.includes(
      'reason_required',
    );

    updateClientAdmin(clientId, { documentDeleteReasonRequired })
      .then(() => {
        getCurrentUser().then((currentUser) =>
          this.props.setCurrentUser(currentUser),
        );
        showToast(
          SUCCESS,
          `Requiring users to input reason when deleting a document has been ${
            documentDeleteReasonRequired ? 'enabled' : 'disabled'
          }.`,
        );
      })
      .catch((_err) => {
        showToast(
          ERROR,
          `An error occurred while ${
            documentDeleteReasonRequired ? 'enabling' : 'disabling'
          } requirement for users to input a reason when deleting a document.`,
        );
      })
      .finally(() =>
        this.setState({
          ...this.state,
          isLoadingDeletedDocumentConfigs: false,
        }),
      );
  }

  renderUserBulkDocumentBody() {
    const KNOWLEDGE_CENTER_URL =
      'https://support.evisort.com/hc/en-us/articles/4434176568599-Admin-How-To-Bulk-Import-Document-Data';
    return (
      <FlexLayout justifyContent="space-between" alignItems="center">
        <FlexLayout flexWrap="wrap">
          <Text>
            Upload a .xls or .xlsx for the documents to update. Use{' '}
            {<Link pathname={'/search'}>Search</Link>} to identify documents and
            fields names and Export to Excel to produce the file to import. For
            more information, visit the{' '}
            <a href={KNOWLEDGE_CENTER_URL} target="_blank" rel="noreferrer">
              Knowledge Center
            </a>
            {'.'}
          </Text>
        </FlexLayout>

        <Button
          text="Upload"
          onClick={this.handleShowImportWizard}
          variant="primary"
        />
      </FlexLayout>
    );
  }

  handleHideImportWizard = () => {
    this.setState({
      showImportWizard: false,
    });
  };

  handleShowImportWizard = () => {
    this.setState({ showImportWizard: true });
  };

  renderSelectedOption(deptAccessList, folderAccessList, visibilityLevel) {
    return visibilityLevel === 'RESTRICTED' ||
      (deptAccessList && deptAccessList.length) ||
      (folderAccessList && folderAccessList.length)
      ? 'specificViewEdit'
      : visibilityLevel === 'OPEN_NO_EDIT' ||
        visibilityLevel === 'OPEN_NO_EDIT_DOWNLOAD'
      ? 'everyoneView'
      : visibilityLevel === 'OPEN'
      ? 'everyoneViewEdit'
      : 'inherited';
  }

  renderAccessSettingsOptions() {
    const { classes } = this.props;
    const { selectedOption } = this.state;

    return (
      <EcRadioContainer
        groupName="accessSettingsOptions"
        onChange={({ selectedValue }) =>
          this.handleOptionSelection(selectedValue)
        }
        defaultValue={selectedOption}
      >
        {(groupName, selectedValue, onOptionChange) => (
          <div className={classes.radioContainerContent}>
            <EcRadioButton
              id="everyoneViewEdit"
              groupName="accessSettingsOptions"
              value="everyoneViewEdit"
              label={
                <span>
                  Allow <b>everyone</b> to <b>view and edit</b> this folder and
                  its contents
                </span>
              }
              onChange={onOptionChange}
              selectedValue={selectedOption}
              fontSize="13px"
              labelColor={black2}
            />

            <EcRadioButton
              id="everyoneView"
              groupName="accessSettingsOptions"
              value="everyoneView"
              label={
                <span>
                  Allow <b>everyone</b> to <b>view</b> this folder and its
                  contents
                </span>
              }
              onChange={onOptionChange}
              selectedValue={selectedOption}
              fontSize="13px"
              labelColor={black2}
            />

            {selectedOption === 'everyoneView' &&
              this.renderAllowUsersToDownload()}

            <EcRadioButton
              id="specificViewEdit"
              groupName="accessSettingsOptions"
              value="specificViewEdit"
              label={
                <span>
                  Allow <b>only specific users</b> to <b>view and edit</b> this
                  folder and its contents
                </span>
              }
              onChange={onOptionChange}
              selectedValue={selectedOption}
              fontSize="13px"
              labelColor={black2}
            />
          </div>
        )}
      </EcRadioContainer>
    );
  }

  renderAccessSettings() {
    const {
      classes,
      match: {
        params: { clientId },
      },
    } = this.props;
    const { selectedOption, folderAccessList } = this.state;

    const userAccessList = folderAccessList.map((user) => {
      return {
        ...user,
        label: user.user_name,
        value: user.user_name,
      };
    });

    return (
      <FlexLayout flexDirection="column" space={4}>
        <FlexLayout flexDirection="column" space={2}>
          {this.renderAccessSettingsOptions()}
          {selectedOption === 'specificViewEdit' && (
            <div className={classes.aclControlsContainer}>
              <div className={classes.aclControlsMessage}>
                Admin users always have full access to all folders.
                <br />
                Use the dropdowns to also give access to certain departments and
                power users.
              </div>

              {this.renderDepartmentsSelection()}

              <EcUserNameSearch
                client={clientId}
                userAccessList={userAccessList}
                updateUserPrivilege={this.updateUserPrivilege}
              />
            </div>
          )}
        </FlexLayout>
        <Box>
          <Button
            text="Save Changes"
            onClick={this.handleRootFolderAccessSubmit}
            isLoading={this.state.isLoadingRootFolderAccess}
            variant="primary"
          />
        </Box>
      </FlexLayout>
    );
  }

  renderAllowUsersToDownload() {
    const { classes } = this.props;
    const { allowUsersDownloadChecked } = this.state;

    return (
      <div className={classes.allowUsersDownloadContainer}>
        <div className={classes.allowUsersDownloadHeader}>
          ADDITIONAL ACCESS SETTINGS
        </div>
        <div className={classes.privilegeOptionContainer}>
          <EcCheckbox
            checked={allowUsersDownloadChecked}
            id="MoreSettingsPage/AllowUsersToDownloadCheckbox"
            onClick={this.handleAllowUserDownloadChange}
            sx={{ border: 'border' }}
          />
          <label
            className={classes.allowUsersDownloadLabel}
            htmlFor="MoreSettingsPage/AllowUsersToDownloadCheckbox"
          >
            Allow users to download documents.
          </label>
        </div>
      </div>
    );
  }

  renderBulkImportUserWizard = () => {
    return (
      <BulkImportDocumentMetadataWizard
        onClose={this.handleHideImportWizard}
        clientId={this.getClientId()}
        handleImportedJob={(importedJobId) =>
          this.handleImportedJob(importedJobId)
        }
      />
    );
  };

  renderDepartmentTreeModal() {
    const {
      match: {
        params: { clientId },
      },
    } = this.props;
    const { departmentsSelected } = this.state;
    return (
      <EcModal
        modalType={MODAL_DEPARTMENT_TREE}
        width="540px"
        client={clientId}
        title="Select a Department"
        departmentsSelected={departmentsSelected}
        handleDepartmentChange={this.handleDepartmentChange}
        confirmButtonIcon={<CheckmarkIcon size="20" />}
        confirmButtonText="Select Department"
        hideModal={this.handleHideModal}
      />
    );
  }

  renderDepartmentsSelection() {
    const { classes } = this.props;
    const { departmentsSelected } = this.state;
    const departmentLists =
      departmentsSelected && departmentsSelected.length
        ? departmentsSelected.map((list) => {
            return (
              <EcDepartmentSelectValueWithControls
                dept={list}
                key={`${list.department_name}-${list.department_id}`}
                updateDepartmentPrivilege={this.updateDepartmentPrivilege}
                closeHandler={() => this.handleRemoveDepartment(list)}
              />
            );
          })
        : null;
    return (
      <div
        className={
          !departmentsSelected
            ? classes.accessDepartmentsContainerEmpty
            : classes.accessDepartmentsContainer
        }
      >
        <Layout
          align="center"
          styles={{
            margin: '20px 0 10px',
          }}
        >
          <Text>DEPARTMENTS</Text>
          <Button
            icon="chevron-right"
            iconPosition="left"
            text="Select Departments"
            onClick={() => this.handleShowModal(MODAL_DEPARTMENT_TREE)}
            variant="action"
          />
        </Layout>
        {departmentsSelected === undefined || !departmentsSelected.length ? (
          <div className={classes.noDeptMessage}>
            No department has been selected.
          </div>
        ) : (
          departmentLists
        )}
      </div>
    );
  }

  renderRootFolderAccessSettings() {
    return (
      <Card
        collapseIconPosition="left"
        onToggleCollapse={() => {}}
        header={<Card.Header title="Root Folder Access Settings" />}
      >
        {this.renderAccessSettings()}
      </Card>
    );
  }

  renderDocumentsMetadataBulkUpload() {
    return (
      <Card
        collapseIconPosition="left"
        onToggleCollapse={() => this.setState({ importedJobId: null })}
        header={<Card.Header title="Documents Metadata Bulk Upload" />}
      >
        <ImportJobsPolling
          clientId={this.getClientId()}
          importedJobId={this.state.importedJobId}
          jobsKind="documentMetadata"
          nullifyImportedJobIdProp={() =>
            this.setState({ importedJobId: null })
          }
        >
          <DocumentMetadataStatusBarList />
        </ImportJobsPolling>
        {this.renderUserBulkDocumentBody()}
      </Card>
    );
  }

  renderClauseTaggingAccessSetting() {
    return (
      <Card
        collapseIconPosition="left"
        onToggleCollapse={() => {}}
        header={<Card.Header title="Clause Quick AI" />}
      >
        <FlexLayout flexDirection="column" space={6}>
          <CheckboxGroup
            options={[
              {
                label: (
                  <Text variant="xs-dense">
                    Restrict <Text isBold>all users</Text> from applying clause
                    tags to all documents from the Document Viewer.
                  </Text>
                ),
                value: 'restrict_all_users',
              },
            ]}
            values={this.state.clauseTagsAccessMode}
            onChange={(mode) =>
              this.setState({ ...this.state, clauseTagsAccessMode: mode })
            }
          />
          <Box>
            <Button
              text="Save Changes"
              onClick={() => this.handleClauseTaggingSubmit()}
              isLoading={this.state.isLoadingClauseTagging}
              variant="primary"
            />
          </Box>
        </FlexLayout>
      </Card>
    );
  }

  renderDocumentRestoreSetting() {
    return (
      <Card
        collapseIconPosition="left"
        onToggleCollapse={() => {}}
        header={<Card.Header title="Deleted Documents" />}
      >
        <FlexLayout flexDirection="column" space={6}>
          <CheckboxGroup
            options={[
              {
                label: (
                  <Text variant="xs-dense">
                    Require users to input a reason when deleting a document
                  </Text>
                ),
                value: 'reason_required',
              },
            ]}
            values={this.state.documentRestoreMode}
            onChange={(mode) =>
              this.setState({ ...this.state, documentRestoreMode: mode })
            }
          />
          <Box>
            <Button
              text="Save Changes"
              onClick={() => this.handleDeletedDocumentConfigsSubmit()}
              isLoading={this.state.isLoadingDeletedDocumentConfigs}
              variant="primary"
            />
          </Box>
        </FlexLayout>
      </Card>
    );
  }

  render() {
    const { currentUser, checkPermission, isPermissionsLoading } = this.props;

    const hasBulkDocumentMetadataFeatureFlag = testHasFlag(
      FeatureFlagType.BulkDocumentMetadata,
    )(currentUser);

    return (
      <div>
        <AdminConsolePageNavHeader
          title="More Settings"
          navigateBack={this.navigateBack}
        />
        <ContentContainer loadingContent={{ isLoading: isPermissionsLoading }}>
          <FlexLayout flexDirection="column" space="x4">
            {checkPermission('root_folder_access_settings', 'view') &&
              this.renderRootFolderAccessSettings()}
            {hasBulkDocumentMetadataFeatureFlag &&
              checkPermission('documents_metadata_bulk_upload', 'view') &&
              this.renderDocumentsMetadataBulkUpload()}
            {checkPermission('clause_quick_ai', 'view') &&
              this.renderClauseTaggingAccessSetting()}
            {<SessionTimeout />}
            {checkPermission('deleted_documents', 'view') &&
              this.renderDocumentRestoreSetting()}
          </FlexLayout>
        </ContentContainer>

        {this.state.modal === MODAL_DEPARTMENT_TREE &&
          this.renderDepartmentTreeModal()}
        {this.state.showImportWizard && this.renderBulkImportUserWizard()}
      </div>
    );
  }
}

Page.propTypes = {
  title: PropTypes.string,
  confirmButtonIcon: PropTypes.object,
};

const mapStateToProps = ({ currentUser }) => ({ currentUser });

Page = connect(mapStateToProps, { setCurrentUser: actions.setCurrentUser })(
  withRouting(Page),
);

export default injectSheet(styles)(
  withFlags(withPermissions(Page, PERMISSIONS)),
);
