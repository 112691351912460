export default `
<svg
  aria-hidden="true"
  aria-label="reload"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M19.25 5.5V10.5C19.25 10.9375 18.9062 11.25 18.5 11.25H13.5C13.0625 11.25 12.75 10.9375 12.75 10.5C12.75 10.0938 13.0625 9.75 13.5 9.75H16.625C15.6875 7.96875 13.8125 6.75 11.75 6.75C8.6875 6.75 6.25 9.21875 6.25 12.25C6.25 15.3125 8.6875 17.75 11.75 17.75C13.0312 17.75 14.2812 17.3125 15.25 16.5C15.5625 16.2188 16.0312 16.2812 16.3125 16.5938C16.5625 16.9062 16.5312 17.375 16.2188 17.6562C14.9688 18.6875 13.375 19.25 11.7188 19.25C7.875 19.25 4.75 16.125 4.75 12.25C4.75 8.40625 7.875 5.28125 11.7188 5.28125C14.2188 5.28125 16.5 6.625 17.75 8.6875V5.5C17.75 5.09375 18.0625 4.75 18.5 4.75C18.9062 4.75 19.25 5.09375 19.25 5.5Z"
    fill="currentcolor"
  />
</svg>
`;
