import React, { memo } from 'react';

import { Input } from '../../types';
import { DayPicker } from './DayPicker';
import { CommonProps } from './types';

type Props = Input<Date> & CommonProps;

export const DateInput = memo((props: Props) => {
  return <DayPicker isRange={false} {...props} />;
});
