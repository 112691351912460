import React, { useEffect, useRef, useState } from 'react';

import { Chip, IconButton, Layout, pluralize, Text, TextInput } from '~/eds';
import { KeyboardEventCodeType } from '~/enums';
import { Nullable } from '~/types';
import { theme } from '~/ui';

import { usePdfHighlighterContext } from './PdfHighlighterContext';

interface Props {
  isVisible: boolean;
  listenOn: React.MutableRefObject<HTMLElement | null>;
  toggleVisiblity: () => void;
}

type MatchesCount = {
  current: number;
  total: number;
};

export enum SearchTypeEnum {
  Find = 'find',
  FindAgain = 'again',
}

export enum SearchResultEnum {
  Found = 0,
  NotFound = 1,
  Wrapped = 2,
  Pending = 3,
}

type ControlStateArgs = {
  state: SearchResultEnum;
  matchesCount: MatchesCount;
};

const SearchBar = ({ isVisible, listenOn, toggleVisiblity }: Props) => {
  const [searchText, setSearchText] = useState<Nullable<string>>('');
  const [matchesCount, setMatchesCount] = useState<MatchesCount>({
    current: 0,
    total: 0,
  });
  const { eventBus, viewer } = usePdfHighlighterContext();

  const shouldShowEndOfSearchMessage =
    matchesCount.total > 0 &&
    matchesCount.current === matchesCount.total &&
    searchText;

  // eventListeners doesn't access the mutated state, so we use a ref to get the updated value
  const searchTextRef = useRef(searchText);
  const showRef = useRef(isVisible);

  const nextSearch = (previousEnabled = false) => {
    eventBus?.dispatch(SearchTypeEnum.Find, {
      type: SearchTypeEnum.FindAgain,
      query: searchTextRef.current,
      phraseSearch: true,
      findPrevious: previousEnabled,
      highlightAll: true,
    });
  };

  const triggerSearch = (searchValue: Nullable<string>) => {
    eventBus?.dispatch(SearchTypeEnum.Find, {
      query: searchValue,
      phraseSearch: true,
      highlightAll: true,
    });
  };

  const triggerNextOnEnter = (event: KeyboardEvent) => {
    if (event.code === KeyboardEventCodeType.Enter) {
      nextSearch(event.shiftKey);
    }
  };

  const closeSearchBar = (event: KeyboardEvent) => {
    if (event.code === KeyboardEventCodeType.Escape) {
      if (showRef.current) {
        showRef.current = false;
        toggleSearchBar();
        clearSearch();
      }
    }
  };

  const interceptDefaultSearch = (e: KeyboardEvent) => {
    if (
      e.code === KeyboardEventCodeType.F3 ||
      ((e.ctrlKey || e.metaKey) && e.code === KeyboardEventCodeType.F)
    ) {
      e.preventDefault();
      showRef.current = !showRef.current;
      toggleSearchBar();
    }
  };

  const toggleSearchBar = () => {
    if (!showRef.current) {
      setTimeout(() => listenOn.current?.focus(), 100);
    }
    toggleVisiblity();
  };

  const clearSearch = () => {
    triggerSearch('');
  };

  useEffect(() => {
    showRef.current = isVisible;
    if (!isVisible) {
      searchText && clearSearch();
    } else {
      // popover calls for focus so both calls will be triggering in random order, we wait a couple ms to get priority.
      setTimeout(
        () => document.getElementById('search-field-input')?.focus(),
        100,
      );
    }
  }, [isVisible]);

  const setZIndexToHighlightedWords = () => {
    const searchHighlights = document.querySelectorAll<HTMLElement>(
      '.PdfHighlighter span',
    );
    searchHighlights.forEach((spans) => {
      // Sets the search highlight to have zIndex higher than all highlights
      spans.style.zIndex = `${theme.zIndices.highlight['high:active'] + 1}`;
    });
  };

  useEffect(() => {
    listenOn.current?.setAttribute?.('tabIndex', '0');
    listenOn.current?.addEventListener('keydown', interceptDefaultSearch);
    listenOn.current?.addEventListener('keydown', triggerNextOnEnter);
    listenOn.current?.addEventListener('keydown', closeSearchBar);

    return () => {
      listenOn.current?.removeEventListener('keydown', interceptDefaultSearch);
      listenOn.current?.removeEventListener('keydown', triggerNextOnEnter);
      listenOn.current?.removeEventListener('keydown', closeSearchBar);
    };
  }, []);

  useEffect(() => {
    const updateFindControlStateListener = (arg: ControlStateArgs) => {
      if (arg.state !== SearchResultEnum.Pending) {
        setMatchesCount(arg.matchesCount);
        setZIndexToHighlightedWords();
      }
    };

    const updateFindMatchesCountListener = (
      arg: Omit<ControlStateArgs, 'state'>,
    ) => {
      setMatchesCount(arg.matchesCount);
    };

    if (eventBus) {
      eventBus.on('updatefindcontrolstate', updateFindControlStateListener);
      eventBus.on('updatefindmatchescount', updateFindMatchesCountListener);
    }

    return () => {
      if (eventBus) {
        eventBus.off('updatefindcontrolstate', updateFindControlStateListener);
        eventBus.off('updatefindmatchescount', updateFindMatchesCountListener);
      }
    };
  }, [eventBus]);

  useEffect(() => {
    if (viewer?.findController && isVisible) {
      searchTextRef.current = searchText;
      triggerSearch(searchText);
    }
  }, [searchText, isVisible]);

  return (
    <Layout
      border="border"
      bg="white"
      borderRadius={4}
      boxShadow="raised"
      mt={5}
      p={1}
      px={4}
      position="absolute"
    >
      <Layout direction="column" spacing={1}>
        <Layout spacing={3} align="center">
          <TextInput
            id="search-field-input"
            name="pdfSearchField"
            placeholder="Type to find"
            value={searchText}
            onChange={setSearchText}
          />
          <IconButton
            icon="chevron-up"
            tooltip="Previous"
            onClick={() => nextSearch(true)}
          />
          <IconButton
            icon="chevron-down"
            tooltip="Next"
            onClick={() => nextSearch()}
          />
          {searchText && (
            <Chip
              text={`${matchesCount.current} of ${pluralize(
                matchesCount.total,
                'match',
              )}`}
            />
          )}
        </Layout>
        {shouldShowEndOfSearchMessage && (
          <Text preset="error" flex="flex" variant="tiny">
            Reached last result, continue from top
          </Text>
        )}
      </Layout>
    </Layout>
  );
};

export default SearchBar;
