import PT from 'prop-types';

import { Select } from '~/eds';
import { BooleanSelect, Box, DateInput, NumberInput, TextInput } from '~/ui';

import DepartmentSelect from '../DepartmentSelect';
import UserSelect from '../UserSelect';
import dataTypes from './dataTypes';

function SingleValueInput({
  dataType,
  disabled,
  error,
  options = [],
  value,
  onChange,
  name,
}) {
  let Input;
  switch (dataType) {
    case dataTypes.BOOLEAN:
      Input = (
        <BooleanSelect
          disabled={disabled}
          error={error}
          placeholder="..."
          value={value}
          width="100%"
          onChange={onChange}
          name={name}
        />
      );
      break;
    case dataTypes.DATE:
      Input = (
        <DateInput
          disabled={disabled}
          error={error}
          placeholder="..."
          placement="bottom-end"
          value={value}
          width="fullWidth"
          onChange={onChange}
          name={name}
        />
      );
      break;
    case dataTypes.DEPARTMENT:
      Input = (
        <DepartmentSelect
          enablePortal
          disabled={disabled}
          placeholder="..."
          enableErrorMessage
          error={error}
          value={value ?? null}
          width="fullWidth"
          onChange={onChange}
          name={name}
        />
      );
      break;
    case dataTypes.NUMBER:
      Input = (
        <NumberInput
          disableErrorMessage
          disabled={disabled}
          error={error}
          placeholder="..."
          value={value}
          width="fullWidth"
          onChange={onChange}
          name={name}
        />
      );
      break;
    case dataTypes.MULTI_SELECT:
    case dataTypes.SINGLE_SELECT:
      const isValidOption = Array.from(
        options.map((item) => item.value),
      ).includes(value);
      Input = (
        <Select
          enableErrorMessage
          enablePortal
          disabled={disabled}
          error={isValidOption ? error : 'Provide a value'}
          options={options}
          placeholder="..."
          value={isValidOption ? value : '...'}
          width="fullWidth"
          onChange={onChange}
          name={name}
        />
      );
      break;
    case dataTypes.TEXT:
      Input = (
        <TextInput
          disableErrorMessage
          disabled={disabled}
          error={error}
          placeholder="..."
          value={value}
          width="fullWidth"
          onChange={onChange}
          name={name}
        />
      );
      break;
    case dataTypes.USER:
      Input = (
        <UserSelect
          enablePortal
          name={name}
          disabled={disabled}
          placeholder="..."
          value={value}
          onChange={onChange}
          error={error}
        />
      );
      break;
    default:
      Input = null;
  }

  return <Box sx={{ flex: '1 1 auto' }}>{Input}</Box>;
}

SingleValueInput.propTypes = {
  dataType: PT.oneOf(Object.keys(dataTypes)).isRequired,
  disabled: PT.bool,
  error: PT.string,
  options: PT.arrayOf(
    PT.shape({ label: PT.string.isRequired, value: PT.string.isRequired })
      .isRequired,
  ),
  value: PT.any,
  onChange: PT.func.isRequired,
};

export default SingleValueInput;
