import PropTypes from 'prop-types';
import React, { useState } from 'react';

import EcModal from '~/components/Shared/EcModal';
import { ENTER, SPACE } from '~/constants/keyCodes';
import { MODAL_ADD_FILTER } from '~/types/modal.types';

const AnalyzerAddFilter = ({
  render,
  filters,
  filterOptions,
  updateFilters,
  updateFilterOptions,
  provisionOptions,
}) => {
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const showModal = () => setShouldShowModal(true);

  const hideModal = () => setShouldShowModal(false);

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER || e.keyCode === SPACE) {
      showModal();
    }
  };

  const addNewEntity = (entity, filter) => {
    const newFilter = { ...filter, entity };
    const newFilters = [...filters];

    if (filters.length) {
      const operator = { entity: 'operator', operator: 'and' };
      newFilters.push(operator);
    }

    newFilters.push(newFilter);

    updateFilters(newFilters);
  };

  const handleAddFilter = (filter) => {
    addNewEntity('field', filter);
  };

  const handleAddProvision = (provision) => {
    addNewEntity('provision', provision);
  };

  const handleAddTextSearch = (textSearch) => {
    addNewEntity('text_search', textSearch);
  };

  const handleAddBooleanTextSearch = (boolTextSearch) => {
    addNewEntity('bool_text_search', boolTextSearch);
  };

  return (
    <>
      {render({
        onClick: showModal,
        onKeyDown: handleKeyDown,
      })}
      {shouldShowModal && (
        <EcModal
          modalType={MODAL_ADD_FILTER}
          hideModal={hideModal}
          width="720px"
          enablePinning={true}
          enableTextSearch={true}
          filterOptions={filterOptions}
          provisionOptions={provisionOptions}
          handleFilterOptionsChange={updateFilterOptions}
          handleAddFilter={handleAddFilter}
          handleAddProvision={handleAddProvision}
          handleAddTextSearch={handleAddTextSearch}
          handleAddBooleanTextSearch={handleAddBooleanTextSearch}
        />
      )}
    </>
  );
};

AnalyzerAddFilter.propTypes = {
  render: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  filterOptions: PropTypes.array.isRequired,
  provisionOptions: PropTypes.array.isRequired,
  updateFilters: PropTypes.func.isRequired,
  updateFilterOptions: PropTypes.func.isRequired,
};

export default AnalyzerAddFilter;
