import React from 'react';
import { useStyles } from 'uinix-ui';

import { TextContainer } from '~/components/Shared/Filters_DEPRECATED';
import { Box, Icon, Layout, TruncateText } from '~/eds';
import { PilotId, SearchFilter } from '~/types';

type Props = {
  searchFilter: SearchFilter;
  onClick?: (fieldId: PilotId | string) => void;
};

export const PinnedFilterChip = ({ searchFilter, onClick }: Props) => {
  const { label } = searchFilter;
  const styles = useStyles();
  const filterText = (
    <Layout spacing={1}>
      <Box minW="min-content">
        <TextContainer isBold={false} text={label} />
      </Box>
    </Layout>
  );

  const trigger = (
    <Layout
      align="center"
      styles={[componentStyles.base, styles.colors.status]}
      mb={1}
      role="listitem"
    >
      <Layout
        align="center"
        as="button"
        borderRadius="m"
        color="inherit"
        h="100%"
        minW={0}
        px={2}
        spacing={2}
        styles={[styles.button.unset, componentStyles.leftContent]}
        onClick={() => onClick?.(searchFilter.id)}
      >
        <Icon icon="push-pin-filled" />
        <TruncateText color="text.primary" variant="tiny" whiteSpace="nowrap">
          {filterText}
        </TruncateText>
        <Icon icon="plus" />
      </Layout>
    </Layout>
  );

  return trigger;
};

export const componentStyles = {
  base: {
    border: 'border',
    borderRadius: 'm',
    height: 'filter.height',
    outline: 'none',
    maxWidth: '100%',
    width: 'min-content',
  },
  leftContent: {
    borderTopLeftRadius: 'm',
    borderBottomLeftRadius: 'm',
    flex: '0 1 auto',
  },
  disabled: {
    opacity: '50%',
  },
};
