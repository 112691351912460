import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { Button, getUserName } from '~/eds';
import { EntityType, TicketStatusType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useClientId } from '~/hooks';
import { api } from '~/redux';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { FlexLayout, Text, useModal, useModalSimple } from '~/ui';
import { validateEmail } from '~/utils/strings';

import TicketApprovalList from '../TicketApprovalList';
import SortRecipients from './SortRecipients';
import TicketRecipientsList from './TicketRecipientsList';
import styles from './TicketSigningPhase.styles';
import UserEmailSearch from './UserEmailSearch';

const TicketSigningPhase = ({
  phaseId,
  // injected props
  classes,
  // connected props
  phases,
  judgments,
  risks,
  approvals,
  hasEnvelope,
  ticketId,
  stages,
}) => {
  const hasCCRecipientsFlag = useFlag(FlagType.CCRecipients);
  const hasESigEnhancementFeatureFlag = useFlag(FlagType.EsignEnhancements);
  const clientId = useClientId();

  const phase = phases[phaseId];
  const { judgmentIds, riskIds } = phase;

  const originalRecipients = useMemo(() => {
    return [
      ...judgmentIds.map((judgmentId) => {
        const judgment = judgments[judgmentId];
        return {
          ...judgment,
          entityType: EntityType.TicketJudgment,
        };
      }),
      ...riskIds.map((riskId) => ({
        ...risks[riskId],
        entityType: EntityType.Risk,
        newCC: false,
      })),
    ].filter(removeCCSigners);
  }, [judgmentIds]);

  const [updateRecipients] = api.endpoints.updateRecipients.useMutation();
  const [deleteRecipients] = api.endpoints.deleteRecipients.useMutation();
  const [resolveUser] = api.endpoints.resolveUser.useLazyQuery();

  const [editMode, setEditMode] = useState(false);

  const [newRecipient, setNewRecipient] = useState(null);
  const [recipients, setRecipients] = useState(originalRecipients);

  // once the ticket envelope is created signers order can't be modified
  // or if any user already signed the ticket uploading a document
  const isEditActive =
    !hasEnvelope &&
    stages.sign?.status !== TicketStatusType.Signed &&
    (hasCCRecipientsFlag || hasESigEnhancementFeatureFlag);

  const [
    sortRecipientsModal,
    showSortRecipientsModal,
  ] = useSortRecipientsModal();

  function removeCCSigners(judgment) {
    const judgmentApproval =
      judgment.approvalIds && approvals[judgment.approvalIds[0]];
    return (
      judgmentApproval &&
      (hasCCRecipientsFlag ||
        judgmentApproval.status !== TicketStatusType.NotApplicable)
    );
  }

  function showEdit() {
    if (hasCCRecipientsFlag) {
      setEditMode(true);
    } else {
      showSortRecipientsModal();
    }
  }

  useEffect(() => {
    setRecipients([...originalRecipients]);
  }, [originalRecipients]);

  function hasInvalidRecipient(recipient) {
    if (recipient) {
      if (recipient.userId) return false;

      if (recipient.name.length) {
        return !validateEmail(recipient.email);
      }
      return true;
    }
    return true;
  }

  const internalRecipients = useMemo(() => {
    const internal = recipients.filter(
      (recipient) =>
        (recipient.newCC && recipient.userId) ||
        (recipient.approvalIds &&
          recipient.approvalIds.length &&
          approvals[recipient.approvalIds[0]].userId),
    );
    return internal.map((recipient) => {
      if (recipient.newCC) {
        return recipient.userId;
      } else {
        const judgment = approvals[recipient.approvalIds[0]];
        return judgment.userId;
      }
    });
  }, [recipients]);

  const [addCCModal, showAddCCModal] = useModal({
    actionButton: {
      disabled: hasInvalidRecipient(newRecipient),
      text: 'Save',
      errorHandler: () =>
        showToast(ERROR, 'An error ocurred adding the Carbon Copy Recipient.'),
      promise: async () => {
        setRecipients([
          ...recipients,
          {
            name: newRecipient.name,
            email: newRecipient.email,
            userId: newRecipient.userId,
            newCC: true,
            deleteCC: false,
            position: recipients.length,
          },
        ]);
      },
    },
    text: 'Save',
    title: 'Add CC Recipient',
    overflowDisplay: 'visible',
    content: (
      <UserEmailSearch
        internalRecipientIds={internalRecipients}
        setRecipient={setNewRecipient}
      />
    ),
  });

  function useSortRecipientsModal() {
    const [modal, showModal] = useModalSimple({
      confirm: onSave,
      text: 'Sort',
      title: 'Re-arrange the signing order.',
      content: (
        <SortRecipients
          recipients={recipients}
          approvals={approvals}
          judgments={judgments}
          stage={phase.stage}
          onSort={setRecipients}
        />
      ),
    });
    return [modal, showModal];
  }

  async function onDelete(index) {
    const recipient = recipients[index];
    if (recipient.newCC) {
      setRecipients([
        ...recipients.slice(0, index),
        ...recipients.slice(index + 1),
      ]);
    } else {
      try {
        const judgmentResult = approvals[recipient.approvalIds[0]];
        let user = judgmentResult.externalUser;
        if (!user) {
          user = await resolveUser({
            id: judgmentResult.userId,
            params: {
              clientId,
            },
          }).unwrap();
        }

        await deleteRecipients({
          ticketId,
          recipients: [
            {
              email: user.email,
              judgmentResultId: judgmentResult.id,
              ...(judgmentResult.externalUser
                ? { externalUserId: user.id, name: user.name }
                : { userId: user.id, name: getUserName(user) }),
            },
          ],
        }).unwrap();
        showToast(SUCCESS, 'Recipient was suscessfully deleted.');
      } catch {
        showToast(ERROR, 'An error occured when deleting the recipient.');
      }
    }
  }

  async function onSave() {
    try {
      await updateRecipients({
        ticketId,
        updatedRecipients: recipients.map((recipient, index) => ({
          newCC: !!recipient.newCC,
          judgmentResultId:
            recipient.approvalIds && recipient.approvalIds.length
              ? recipient.approvalIds[0]
              : null,
          position: index,
          ...(recipient.newCC
            ? {
                userId: recipient.userId,
                email: recipient.email,
                name: recipient.name,
              }
            : {}),
        })),
      }).unwrap();
      showToast(SUCCESS, 'Recipients edited with success.');
      setEditMode(false);
    } catch {
      showToast(ERROR, 'An error occurred when editing the recipients.');
    }
  }
  if (editMode) {
    return (
      <>
        <FlexLayout flexDirection="column" space={4}>
          <Text color="gray-600" variant="xs-dense-caps">
            Recipients
          </Text>
          <Text variant="xs-dense">Re-arrange the signing order</Text>
          <span>
            <SortRecipients
              recipients={recipients}
              approvals={approvals}
              judgments={judgments}
              stage={phase.stage}
              onSort={setRecipients}
              onDelete={onDelete}
            />
          </span>
          <FlexLayout
            alignItems="center"
            justifyContent="flex-end"
            px={4}
            space={4}
          >
            <Button
              preset="cancel"
              text="Cancel"
              onClick={() => {
                setRecipients([...originalRecipients]);
                setEditMode(false);
              }}
            />
            <Button variant="primary" onClick={showAddCCModal} text="Add CC" />
            <Button variant="primary" onClick={onSave} text="Save" />
          </FlexLayout>
        </FlexLayout>
        {addCCModal}
      </>
    );
  }

  return (
    <>
      <FlexLayout flexDirection="column" space={3}>
        <FlexLayout justifyContent="space-between">
          <Text color="gray-600" variant="xs-dense-caps">
            Recipients
          </Text>
          {isEditActive && (
            <Button
              text="Edit"
              icon="edit"
              iconPosition="left"
              variant="action"
              onClick={showEdit}
            />
          )}
        </FlexLayout>
        <>
          {hasESigEnhancementFeatureFlag ? (
            <FlexLayout flexDirection="column" space={3}>
              <TicketRecipientsList
                recipients={originalRecipients}
                approvals={approvals}
              />
            </FlexLayout>
          ) : (
            recipients.map((reviewCriterion) => {
              const { id, approvalIds, entityType, name } = reviewCriterion;
              return (
                <div className={classes.phaseSubsection} key={id}>
                  <TicketApprovalList
                    issueId={id}
                    entityType={entityType}
                    approvalName={name}
                    approvalList={approvalIds}
                  />
                </div>
              );
            })
          )}
        </>
      </FlexLayout>
      {sortRecipientsModal}
    </>
  );
};

TicketSigningPhase.propTypes = {
  phases: PropTypes.object.isRequired,
  phaseId: PropTypes.string.isRequired,
};

function mapStateToProps({ ticket }) {
  const {
    phases,
    judgments,
    risks,
    approvals,
    hasEnvelope,
    id,
    stages,
  } = ticket;
  return {
    phases,
    judgments,
    risks,
    approvals,
    hasEnvelope,
    ticketId: id,
    stages,
  };
}

export default injectSheet(styles)(
  connect(mapStateToProps)(TicketSigningPhase),
);
