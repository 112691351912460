export default `
<svg
  aria-hidden="true"
  aria-label="field-multiple-choice"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.5938 14.625C11.25 14.9688 10.7188 14.9688 10.375 14.625L8.375 12.625C8.03125 12.2812 8.03125 11.75 8.375 11.4062C8.71875 11.0625 9.25 11.0625 9.59375 11.4062L11 12.7812L14.375 9.40625C14.7188 9.0625 15.25 9.0625 15.5938 9.40625C15.9375 9.75 15.9375 10.2812 15.5938 10.625L11.5938 14.625ZM5 7C5 5.90625 5.875 5 7 5H17C18.0938 5 19 5.90625 19 7V17C19 18.125 18.0938 19 17 19H7C5.875 19 5 18.125 5 17V7ZM6.5 7V17C6.5 17.2812 6.71875 17.5 7 17.5H17C17.25 17.5 17.5 17.2812 17.5 17V7C17.5 6.75 17.25 6.5 17 6.5H7C6.71875 6.5 6.5 6.75 6.5 7Z"
    fill="currentcolor"
  />
</svg>
`;
