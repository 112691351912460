import React, { useState } from 'react';

import { Box, Chips, Layout } from '../../components';
import { SharedChipProps } from '../../types';
import { TextEditor } from '../TextEditor';
import { ContentAst, Format, MentionedEntity } from '../TextEditor/types';
import { CommentData, Entity, MentionEntityType, Mentions } from './types';

const formats: Format[] = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'ol',
  'ul',
];
interface Props {
  entity: Entity;
  mentions: Mentions;
  isMentionsLoading: boolean;
  resolvedMentions: Mentions;
  onSubmit: (commentData: CommentData) => void;
  content?: ContentAst;
  isFocused?: boolean;
  isSubmitting?: boolean;
  chips?: SharedChipProps[];
  onCancel?: () => void;
  onMentionSearch?: (updatedSearch: string) => void;
  onMention?: (mention: MentionedEntity<MentionEntityType>) => void;
}

export const CommentDraft = ({
  chips,
  content: initialContent = [],
  entity,
  isFocused,
  isMentionsLoading,
  isSubmitting,
  mentions,
  resolvedMentions,
  onMention,
  onCancel,
  onMentionSearch,
  onSubmit,
}: Props) => {
  const [content, setContent] = useState<ContentAst>(initialContent);

  const handleSubmitComment = () =>
    onSubmit({
      content,
      mentions: [],
      context: [],
      entity,
    });

  return (
    <Layout direction="column" w="100%">
      {chips?.length && (
        <Box mb={3}>
          <Chips chips={chips} />
        </Box>
      )}
      <TextEditor
        content={content}
        isFocused={isFocused}
        formats={formats}
        isMentionsLoading={isMentionsLoading}
        isPosting={isSubmitting}
        mentions={mentions}
        placeholder="Comment or @ to mention"
        resolvedMentions={resolvedMentions}
        onMention={onMention}
        onCancel={onCancel}
        onChange={setContent}
        onMentionSearch={onMentionSearch}
        onPost={handleSubmitComment}
      />
    </Layout>
  );
};
