// Evisort - Shared styles variables
import { EDS_ACTION_COLOR } from '~/ui';

// Colors
export const evisortBlue = EDS_ACTION_COLOR;
export const evisortYellow = '#FFC449';
export const evisortLightYellow = '#FDF0D4';
export const evisortGreen = '#1DDB6E';
export const evisortGreenDark = '#0DA64E';
export const evisortRed = '#E02B2B';
export const evisortOrange = '#F79862';

export const black1 = '#111';
export const black2 = '#404040';
export const black3 = '#767676';
export const black4 = '#9A9A9A';
export const black5 = '#D4D4D4';

// status text colors
export const mutedBackground = '#FAFAFA';

export const approved = '#0DA64E';
export const pending = '#FFC449';
export const rejected = '#F33737';

// Font Weights
export const regular = '400';
export const semibold = '500';
export const bold = '700';
export const heavy = '800';
