import { atlas } from '~/services';

import { QuestionGroupResponsePayload } from './types';
import { mapQuestionGroupResponse } from './utils';

type CreateQuestionGroupParams = {
  name: string;
  questionIds: string[];
  visibility: 'OPEN' | 'PRIVATE' | 'SHARED';
  userIds: number[];
  departmentIds: number[];
  description?: string;
};

export const createQuestionGroup = async (
  questionGroup: CreateQuestionGroupParams,
) => {
  const newQuestionGroup = {
    data: {
      type: 'question_groups',
      attributes: {
        name: questionGroup.name,
        description: questionGroup.description,
        question_ids: questionGroup.questionIds,
        visibility: questionGroup.visibility,
        user_ids: questionGroup.userIds,
        department_ids: questionGroup.departmentIds,
      },
    },
  };
  const response: QuestionGroupResponsePayload = await atlas.post(
    'question-groups',
    newQuestionGroup,
  );
  return mapQuestionGroupResponse(response.data);
};
