import { atlas } from '~/services';
import { Uuid } from '~/types';

type DeleteQuestionParams = {
  id: Uuid;
};

export const deleteQuestion = async ({ id }: DeleteQuestionParams) => {
  return await atlas.remove(`questions/${id}`);
};
