import { checkIsReviewer, checkIsWorkflowOnly } from '~/permissions';

import { RoutePathType } from '../enums';
import { Component, RouteResolver } from '../types';

/**
 * This creates the default resolvers for routes:
 * - It will always redirect to RoutePathType.Reviewer if user passes for permission checkIsReviewer.
 * - It will always redirect to RoutePathType.WorkflowTickets if user passes for permission checkIsWorkflowOnly.
 * - Renders the provided component otherwise
 */
export const createDefaultResolvers = (
  component: Component,
): RouteResolver[] => {
  return [
    {
      permission: checkIsReviewer,
      redirectPath: RoutePathType.Reviewer,
    },
    {
      permission: checkIsWorkflowOnly,
      redirectPath: RoutePathType.WorkflowTickets,
    },
    {
      component,
    },
  ];
};
