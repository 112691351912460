import { atlas } from '~/services';
import { Nullable, Uuid } from '~/types';

import { QuestionResponsePayload } from './types';
import { mapQuestionResponse } from './utils';

type UpdateQuestionParams = {
  id: Uuid;
  pinnedAt?: Nullable<Date>;
  text?: string;
  name?: string;
  questionGroupIds?: Uuid[];
};

type UpdatableQuestion = {
  data: {
    type: 'questions';
    attributes: {
      pinned_at?: Nullable<string>;
      text?: string;
      name?: string;
      question_group_ids?: Uuid[];
    };
  };
};

export const updateQuestion = async ({
  id,
  ...question
}: UpdateQuestionParams) => {
  const newQuestion: UpdatableQuestion = {
    data: {
      type: 'questions',
      attributes: {},
    },
  };

  Object.entries(question).forEach(([key, value]) => {
    if (key === 'pinnedAt') {
      newQuestion.data.attributes.pinned_at = value
        ? (value as Date).toISOString().replace('Z', '')
        : null;
    }

    if (key === 'text' && value) {
      newQuestion.data.attributes.text = value as string;
    }

    if (key === 'name' && value) {
      newQuestion.data.attributes.name = value as string;
    }

    if (key === 'questionGroupIds' && value) {
      newQuestion.data.attributes.question_group_ids = (value ?? []) as Uuid[];
    }
  });

  const response: QuestionResponsePayload = await atlas.patch(
    `questions/${id}`,
    newQuestion,
  );
  return mapQuestionResponse(response.data);
};
