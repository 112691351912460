import {
  black2,
  bold,
  evisortBlue,
} from '../../../assets/shared-styles/general';

const styles = {
  keyInformationSearch: {
    margin: '8px',
  },
  flaggedInfo: {
    padding: '16px',
  },
  flaggedWarning: {
    display: 'flex',
    alignItems: 'center',
    color: black2,
    fontSize: '13px',
    '& svg': {
      flexShrink: 0,
      marginRight: '8px',
    },
  },
  flaggedActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '8px',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
    '& span': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:first-child': {
        marginRight: '16px',
      },
      '& svg': {
        marginRight: '4px',
      },
    },
  },
};

export default styles;
