import React, { useMemo, useState } from 'react';

import {
  CheckboxGroup,
  CheckboxGroups,
  Layout,
  SearchInput,
} from '../../../components';
import { useThrottledValue } from '../../../hooks';
import { Nullable } from '../../../types';
import { Column, GroupedColumn } from '../types';
import { testIsNonPrimaryColumn } from '../utils';

type ColumnKeyToGroupMap = Record<string, string>;

type GroupedValue = Record<string, string[]>;

interface Props {
  columns: Column[];
  columnOrder: string[];
  onUpdateColumnOrder: (updatedColumnOrder: string[]) => void;
  groupedColumns?: GroupedColumn[];
  primaryColumnKey?: string;
}

export const ColumnSelection = ({
  columns,
  columnOrder,
  groupedColumns = [],
  primaryColumnKey,
  onUpdateColumnOrder,
}: Props) => {
  const [search, setSearch] = useState('');

  const throttledSearch = useThrottledValue(search);

  // for reverse lookup of column key to its group.
  const columnKeyToGroupMap = useMemo(
    () =>
      groupedColumns.reduce((acc, { name, columns }) => {
        columns.forEach((column) => {
          acc[column.key] = name;
        });
        return acc;
      }, {} as ColumnKeyToGroupMap),
    [groupedColumns],
  );

  const groupedOptions = useMemo(
    () =>
      groupedColumns.map(({ name, label, columns }) => ({
        name,
        label,
        options: columns
          .filter(testIsNonPrimaryColumn(primaryColumnKey))
          .map(columnToOption),
      })),
    [groupedColumns],
  );

  const options = useMemo(
    () =>
      columns
        .filter(testIsNonPrimaryColumn(primaryColumnKey))
        .map(columnToOption),
    [columns],
  );

  const groupedValue = useMemo(
    () => groupColumnValue(columnOrder, columnKeyToGroupMap),
    [columnOrder, columnKeyToGroupMap],
  );

  const checkboxes =
    groupedColumns.length > 0 ? (
      <CheckboxGroups
        columns={2}
        groups={groupedOptions}
        search={throttledSearch}
        value={groupedValue}
        onChange={(groupedValue) =>
          onUpdateColumnOrder(ungroupColumnValues(groupedValue))
        }
      />
    ) : (
      <CheckboxGroup
        columns={2}
        name="columns"
        options={options}
        search={throttledSearch}
        value={columnOrder}
        onChange={(updatedColumnOrder) =>
          onUpdateColumnOrder(updatedColumnOrder ?? [])
        }
      />
    );

  return (
    <Layout direction="column" spacing={4}>
      <SearchInput
        autoFocus
        name="search columns"
        value={search}
        onChange={(updatedSearch) => setSearch(updatedSearch ?? '')}
      />
      {checkboxes}
    </Layout>
  );
};

const groupColumnValue = (
  columnOrder: string[],
  columnKeyToGroupMap: ColumnKeyToGroupMap,
): GroupedValue => {
  const groupedValue: GroupedValue = {};
  columnOrder.forEach((columnKey) => {
    const groupKey = columnKeyToGroupMap[columnKey];
    if (groupKey) {
      if (!(groupKey in groupedValue)) {
        groupedValue[groupKey] = [];
      }
      groupedValue[groupKey].push(columnKey);
    }
  });
  return groupedValue;
};

const ungroupColumnValues = (groupedValue: Nullable<GroupedValue>) =>
  Object.values(groupedValue ?? {}).flat();

const columnToOption = (column: Column) => ({
  info: column.info,
  label: column.title,
  value: column.key,
});
