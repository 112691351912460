export default `
<svg
  aria-hidden="true"
  aria-label="caret-up"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12.7032 9.29297C12.3282 8.88672 11.672 8.88672 11.297 9.29297L7.29695 13.293C7.0157 13.5742 6.92195 14.0117 7.0782 14.3867C7.23445 14.7617 7.60945 15.0117 8.0157 15.0117H16.0157C16.3907 15.0117 16.7657 14.7617 16.922 14.3867C17.0782 14.0117 16.9845 13.5742 16.7032 13.293L12.7032 9.29297Z"
    fill="currentcolor"
  />
</svg>
`;
