export const highlight = {
  default: {
    backgroundColor: 'highlight',
    color: 'inherit',
  },
  ai: {
    backgroundColor: 'highlight.ai',
    color: 'inherit',
  },
  search: {
    backgroundColor: 'highlight.search',
    color: 'inherit',
  },
};
