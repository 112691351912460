import { api } from '~/redux';
import { Uuid } from '~/types';

export const useResolveQuestionGroups = ({
  questionGroupIds,
  skip,
}: {
  questionGroupIds: Uuid[];
  skip?: boolean;
}) => {
  const {
    data: questionGroups,
    ...rest
  } = api.endpoints.resolveQuestionGroups.useQuery(
    {
      ids: questionGroupIds,
    },
    { skip },
  );

  return { questionGroups, ...rest };
};
