import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.SaveSearch, id: 'LIST' };
const TAG_BY_ID = { type: TagType.SaveSearch, id: 'ID' };
api.enhanceEndpoints({
  addTagTypes: [TagType.SaveSearch as TagType],
  endpoints: {
    deleteSavedSearch: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deleteSearchV3SavedSearch: {
      invalidatesTags: [TAG_BY_LIST],
    },
    getSavedSearches: {
      providesTags: [TAG_BY_LIST],
    },
    getSearchV3SavedSearches: {
      providesTags: [TAG_BY_LIST],
    },
    getSavedSearch: {
      providesTags: [TAG_BY_ID],
    },
    putSavedSearch: {
      invalidatesTags: [TAG_BY_ID],
    },
    saveSearch: {
      invalidatesTags: [TAG_BY_LIST, TAG_BY_ID],
    },
    updateSavedSearch: {
      invalidatesTags: [TAG_BY_LIST, TAG_BY_ID],
    },
  },
});
