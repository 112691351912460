import { interactions } from './interactions';

// interactable elements should have the following consistent applied styles
export const interactable = (props: {
  'data-eds-styles-disable-interactable'?: boolean;
  onClick?: Function;
  onMouseEnter?: Function;
  onMouseDown?: Function;
}) => {
  const { onClick, onMouseDown, onMouseEnter } = props;
  const disableInteractableStyle =
    props['data-eds-styles-disable-interactable'];

  const isClickable = Boolean(onClick || onMouseDown);
  const isInteractable = Boolean(onClick || onMouseDown || onMouseEnter);

  if (!isInteractable) {
    return null;
  }

  const interactableStyle = {
    cursor: isClickable ? 'pointer' : undefined,
  };

  return disableInteractableStyle
    ? interactableStyle
    : {
        ...interactableStyle,
        ...interactions,
      };
};
