import PropTypes from 'prop-types';
import React from 'react';

import {
  evisortBlue,
  evisortGreen,
  evisortGreenDark,
} from '../../../assets/shared-styles/general';

const CheckmarkIcon = (props) => {
  const { green, darkGreen, blue, white } = props;

  let color = 'rgba(0,0,0,0.75)';
  if (green) {
    color = evisortGreen;
  } else if (darkGreen) {
    color = evisortGreenDark;
  } else if (blue) {
    color = evisortBlue;
  } else if (white) {
    color = '#fff';
  }

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={color}
          fillRule="nonzero"
          d="M19.285 6.05a1 1 0 0 1 1.43 1.4L9.008 19.421l-5.715-5.715a1 1 0 0 1 1.414-1.414l4.285 4.285L19.285 6.05z"
        />
      </g>
    </svg>
  );
};

CheckmarkIcon.defaultProps = {
  size: '24',
};

CheckmarkIcon.propTypes = {
  size: PropTypes.string,
};

export default CheckmarkIcon;
