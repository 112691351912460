import PropTypes from 'prop-types';
import React from 'react';

import { Alert, FlexLayout } from '~/ui';

import { barItemBaseProps } from './DocumentMetadataStatusBarPresets';

const DocumentMetadataStatusBarList = ({
  clientId,
  handleAcknowledgeImportJobSuccessUpdate,
  importJobsData = [],
}) => {
  const buildBarItemProperties = (importData) => {
    const { counters, importId, status } = importData;
    const barItemBaseProperties = barItemBaseProps[status];
    return {
      importId,
      enableIcon: true,
      variant: barItemBaseProperties.getIcon(counters),
      actions: barItemBaseProperties.getActions({ clientId, importData }),
      children: barItemBaseProperties.getBarItemText({ clientId, importData }),
      onDismiss: barItemBaseProperties.handleImportJobAcknowledgeClick
        ? () =>
            barItemBaseProperties.handleImportJobAcknowledgeClick({
              clientId,
              handleAcknowledgeImportJobUpdate,
              importData,
            })
        : null,
    };
  };

  const handleAcknowledgeImportJobUpdate = (acknowledgedImportId) => {
    handleAcknowledgeImportJobSuccessUpdate({ acknowledgedImportId });
  };

  const importStatusBarItemsProps = importJobsData.map((importData) =>
    buildBarItemProperties(importData),
  );

  return (
    <FlexLayout flexDirection="column" space={2} mb={4}>
      {importStatusBarItemsProps.map((props) => (
        <Alert key={`barItem_${props.importId}`} {...props} />
      ))}
    </FlexLayout>
  );
};

DocumentMetadataStatusBarList.propTypes = {
  clientId: PropTypes.number.isRequired,
  handleAcknowledgeImportJobSuccessUpdate: PropTypes.func.isRequired,
  importJobsData: PropTypes.array.isRequired,
};

export default DocumentMetadataStatusBarList;
