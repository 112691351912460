import React from 'react';
import { components, ControlProps } from 'react-select';
import { SortableContainer } from 'react-sortable-hoc';
import { useStyles } from 'uinix-ui';

import { theme } from '../../../system/theme';
import { Option } from '../../../types';
import { Box } from '../../Box';
import { Layout } from '../../Layout';
import { resetGetStyles } from './resetGetStyles';

// SortableElement is implemented in the sister ./MultiValue component
const SortableControl = SortableContainer(components.Control);

export const Control = (props: ControlProps<Option, boolean>) => {
  const styles = useStyles();

  const { selectProps } = props;
  const {
    isDisabled,
    enableControl,
    enableErrorMessage,
    error,
    isEmbedded,
    inputWidth,
    sortProps,
    role,
    name,
  } = selectProps;

  return (
    <SortableControl
      {...props}
      {...sortProps}
      getStyles={resetGetStyles}
      innerProps={{
        ...props.innerProps,
        role,
        'aria-label': name,
      }}
    >
      <Layout
        align="center"
        disabled={isDisabled}
        position="relative"
        spacing={2}
        styles={[
          styles.input.container,
          isDisabled ? styles.input.disabled : null,
          isEmbedded ? componentStyles.embedded : null,
          enableControl ? null : componentStyles.hidden,
        ]}
        styleProps={{
          enableReadOnly: false,
          error,
          width: inputWidth,
        }}
      >
        {props.children}
        {enableErrorMessage && (
          <Box styles={componentStyles.errorMessage} role="note">
            {error}
          </Box>
        )}
      </Layout>
    </SortableControl>
  );
};

const mx = theme.spacings[4] as number;

export const componentStyles = {
  embedded: {
    marginLeft: `${mx}px`,
    minWidth: 'input.min-width',
    marginRight: `${mx}px`,
    width: `calc(100% - ${mx * 2}px)`,
  },
  errorMessage: {
    bottom: 0,
    color: 'status.danger',
    fontSize: 's',
    left: 0,
    position: 'absolute',
    transform: 'translateY(100%)',
  },
  hidden: {
    height: 0,
    minHeight: 0,
    minWidth: 0,
    opacity: 0,
    width: 0,
  },
};
