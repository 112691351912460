export default `
<svg
  aria-hidden="true"
  aria-label="minimize"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M10.3993 13.0666H5.066C4.77134 13.0666 4.53267 13.3066 4.53267 13.5999C4.53267 13.8932 4.77134 14.1332 5.066 14.1332H9.11266L4.15601 19.0899C3.94767 19.2982 3.94767 19.6359 4.15601 19.8439C4.36434 20.0519 4.702 20.0522 4.91 19.8439L9.86599 14.8866V18.9332C9.86599 19.2265 10.106 19.4665 10.3993 19.4665C10.6927 19.4665 10.9327 19.2265 10.9327 18.9332V13.5999C10.9327 13.3066 10.6927 13.0666 10.3993 13.0666ZM19.8426 4.15625C19.6343 3.94792 19.2966 3.94792 19.0886 4.15625L14.1326 9.11324V5.06658C14.1326 4.77191 13.8926 4.53325 13.5993 4.53325C13.306 4.53325 13.066 4.77191 13.066 5.06658V10.3999C13.066 10.6932 13.306 10.9332 13.5993 10.9332H18.9326C19.226 10.9332 19.466 10.6932 19.466 10.3999C19.466 10.1066 19.226 9.86657 18.9326 9.86657H14.886L19.8426 4.90991C20.0526 4.70191 20.0526 4.36458 19.8426 4.15625Z"
    fill="currentcolor"
  />
</svg>
`;
