import React, { Fragment } from 'react';

import { Box } from '../../../components';
import { HeadRow } from '../Row';

function Head({ headers }) {
  return (
    <Box as="thead" bg="inherit">
      <HeadRow>
        {headers.map((column) => (
          <Fragment key={column.key}>{column.render('Header')}</Fragment>
        ))}
      </HeadRow>
    </Box>
  );
}

export default Head;
