import React from 'react';

import { SharedFolderProps } from '../../types';
import { Button } from '../Button';
import { Folder } from '../Folder';
import { Layout } from '../Layout';
import { LoadingContent } from '../LoadingContent';

interface SharedFolderInputProps {
  folder: SharedFolderProps;
  onChange?: () => void;
  isLoading?: boolean;
}

export const FolderInput = ({
  folder,
  onChange,
  isLoading,
}: SharedFolderInputProps) => {
  if (isLoading) {
    return <LoadingContent isLoading />;
  }

  return (
    <Layout spacing={2}>
      <Folder {...folder} />
      <Button
        size="s"
        text="Change Location"
        variant="action"
        onClick={onChange}
      />
    </Layout>
  );
};
