import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CardSelect } from '~/eds';
import { CreditBalance } from '~/features/billing';
import { useCurrentUser } from '~/hooks';
import { actions, selectors } from '~/redux';

import { calculateCreditEstimate } from '../../billing/utils';
import { FieldModel } from '../../fields/types';
import { ModelProgress } from '../../types';

interface Props {
  progress: ModelProgress;
  modelVersion: FieldModel;
  hasOnlyFiltersChanged: boolean;
  onStopPolling: (stopPolling: boolean) => void;
}
export const PublishOptions = ({
  progress,
  modelVersion,
  hasOnlyFiltersChanged,
  onStopPolling,
}: Props) => {
  const dispatch = useDispatch();
  const { client } = useCurrentUser();

  const { docsInScope, docsProcessed, docsInScopeProcessing } = progress;

  const selectPublishMode = useSelector(selectors.selectFieldAiPublishMode);

  const newDocsInScope = calculateCreditEstimate(docsProcessed, docsInScope);

  const publishOptions = useMemo(() => {
    const options = [];

    if (hasOnlyFiltersChanged) {
      options.push({
        ariaLabel: 'new documents in scope option',
        content: (
          <CreditBalance
            enableCreditsAvailable={false}
            clientId={client}
            estimateUnit={{
              modelId: modelVersion.id,
              isLoadingUnitValue: docsInScopeProcessing,
              unit: 'document-count',
              value: newDocsInScope ?? 0,
            }}
          />
        ),
        contentDirection: 'row' as const,
        description:
          'Only apply edits to documents in scope that the model has not yet run on.',
        label: 'Apply edits to unprocessed documents in scope',
        value: 'new_docs_in_scope' as const,
      });
    } else {
      options.push(
        {
          ariaLabel: 'new documents in scope option',
          content: (
            <CreditBalance
              enableCreditsAvailable={false}
              clientId={client}
              estimateUnit={{
                modelId: modelVersion.id,
                isLoadingUnitValue: docsInScopeProcessing,
                unit: 'document-count',
                value: newDocsInScope ?? 0,
              }}
            />
          ),
          contentDirection: 'row' as const,
          description:
            'Only apply edits to documents in scope that the model has not yet run on.',
          label: 'Apply edits to unprocessed documents in scope',
          value: 'new_docs_in_scope' as const,
        },
        {
          ariaLabel: 'all documents in scope option',
          content: (
            <CreditBalance
              enableCreditsAvailable={false}
              clientId={client}
              estimateUnit={{
                modelId: modelVersion.id,
                isLoadingUnitValue: docsInScopeProcessing,
                unit: 'document-count',
                value: docsInScope,
              }}
            />
          ),
          contentDirection: 'row' as const,
          description:
            'Apply edits to all documents in scope, including ones that the model has already run on. The new answers will overwrite existing answers found by X-Ray.',
          label: 'Apply edits to all documents in scope',
          value: 'all_docs_in_scope' as const,
        },
      );
    }

    return options;
  }, [
    hasOnlyFiltersChanged,
    docsInScope,
    docsProcessed,
    docsInScopeProcessing,
    progress,
  ]);

  useEffect(() => {
    if (!docsInScopeProcessing) {
      onStopPolling(docsInScopeProcessing);
    }
  }, [docsInScopeProcessing]);

  return (
    <CardSelect
      direction="column"
      isMulti={false}
      name="post-publish-options"
      onChange={(updatedValue) => {
        dispatch(
          actions.setFieldAiPublishMode(updatedValue ?? 'new_docs_in_scope'),
        );
      }}
      options={publishOptions}
      value={selectPublishMode}
    />
  );
};
