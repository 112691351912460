import React from 'react';

import { pluralize, StatusMessage } from '~/eds';
import { BulkImportStatusType } from '~/enums';
import { IBulkImportJob } from '~/types';

interface Props {
  status: IBulkImportJob;
  onAcknowledge: () => void;
  onDownloadErrorList: () => void;
  onCancelImportJob: () => void;
}

type StatusMessageProps = React.ComponentProps<typeof StatusMessage>;

export const ImportStatus = ({
  status,
  onAcknowledge,
  onCancelImportJob,
  onDownloadErrorList,
}: Props) => {
  const preset = presets[status.status];
  const message = preset.getMessage(status);
  const statusIcon = preset.getStatus(status);

  const isStillRunning =
    status.status === BulkImportStatusType.New ||
    status.status === BulkImportStatusType.Running;

  return (
    <StatusMessage
      status={statusIcon}
      message={message}
      onDismiss={!isStillRunning ? onAcknowledge : undefined}
      action={
        isStillRunning
          ? { text: 'Cancel Import', onClick: onCancelImportJob }
          : status.status === BulkImportStatusType.Finished &&
            status.counters.errors
          ? { text: 'Download errors list', onClick: onDownloadErrorList }
          : undefined
      }
    />
  );
};

type Preset = {
  getStatus: (status: IBulkImportJob) => StatusMessageProps['status'];
  getMessage: (status: IBulkImportJob) => string;
};
const presets: Record<BulkImportStatusType, Preset> = {
  [BulkImportStatusType.New]: {
    getStatus: () => 'info',
    getMessage: () => 'Users import in queue.',
  },
  [BulkImportStatusType.Finished]: {
    getStatus: (status: IBulkImportJob) =>
      status.counters.errors ? 'warning' : 'success',
    getMessage: (status: IBulkImportJob) => {
      let message = '';
      if (Number.isInteger(status.counters.usersCreated)) {
        message += `${pluralize(
          status.counters.usersCreated,
          'user',
        )} successfully created. `;
      }
      if (Number.isInteger(status.counters.usersUpdated)) {
        message += `${pluralize(
          status.counters.usersUpdated,
          'user',
        )} updated. `;
      }
      if (status.counters.errors) {
        message += `\n${pluralize(
          status.counters.errors,
          'user',
        )} failed to import. `;
      }
      return message;
    },
  },
  [BulkImportStatusType.Running]: {
    getStatus: () => 'info',
    getMessage: (status: IBulkImportJob) =>
      `Processing ${status.counters.processed} out of ${status.counters.total}`,
  },
  [BulkImportStatusType.Failed]: {
    getStatus: () => 'danger',
    getMessage: () => 'Users import failed.',
  },
  [BulkImportStatusType.Cancelled]: {
    getStatus: () => 'danger',
    getMessage: () => 'User import job was cancelled.',
  },
};
