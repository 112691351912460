import { PromptModelTestCase } from '~/features/x-ray';
import { Uuid } from '~/types';

import { DEPRECATED_getPromptModelVersion } from './DEPRECATED_getPromptModelVersion';
import { getPromptModel } from './getPromptModel';

interface Request {
  modelId: Uuid;
}

export const getPromptModelOptimizeTestCases = async ({
  modelId,
}: Request): Promise<PromptModelTestCase[]> => {
  const { currentOptimizeInfo } = await getPromptModel({ modelId });

  if (currentOptimizeInfo) {
    const { version } = currentOptimizeInfo;
    const { testCases } = await DEPRECATED_getPromptModelVersion({
      modelId,
      version,
    });
    return testCases;
  }

  return [];
};
