export const borderRadius = {
  containerLeft: {
    borderBottomLeftRadius: 'l',
    borderTopLeftRadius: 'l',
  },
  containerRight: {
    borderBottomRightRadius: 'l',
    borderTopRightRadius: 'l',
  },
};
