import { api } from '../createApi';
import { TagType } from '../TagType';

export const COMMENT_TAG = { type: TagType.Comment, id: 'LIST' };
const TAG_BY_LIST_HIGHLIGHT = { type: TagType.Highlight, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.Comment as TagType, TagType.Highlight as TagType],
  endpoints: {
    getComments: {
      providesTags: [COMMENT_TAG],
    },
    createComment: {
      invalidatesTags: [COMMENT_TAG],
    },
    updateComment: {
      invalidatesTags: [COMMENT_TAG],
    },
    deleteComment: {
      invalidatesTags: [COMMENT_TAG, TAG_BY_LIST_HIGHLIGHT],
    },
    resolveComment: {
      invalidatesTags: [COMMENT_TAG],
    },
    unresolveComment: {
      invalidatesTags: [COMMENT_TAG],
    },
  },
});
