import { sortByNumericValue } from './array';

export function createDepartmentOption(department, normalizedDepartments) {
  const { id, name, parentIds, position } = department;
  const path = [
    ...parentIds.map((parentId) => normalizedDepartments[parentId].name),
    name,
  ].join(' ➔ ');
  return {
    label: name,
    parentIds,
    path,
    position,
    value: id,
  };
}

export function getDepartmentOptions(department) {
  const normalizedDepartments = normalizeDepartmentTree(department);
  return Object.values(normalizedDepartments)
    .map((department) =>
      createDepartmentOption(department, normalizedDepartments),
    )
    .sort(sortByNumericValue('position'));
}

export function normalizeDepartmentTree(departmentTree) {
  let normalizedDepartments = {};
  let position = 0;

  function normalizeDepartmentTreeR(departmentTree, parentIds) {
    departmentTree.forEach((department) => {
      const {
        children,
        department_id: id,
        department_name: name,
        parent,
      } = department;

      // track parent if it exists and is untracked
      if (parent && parentIds[parentIds.length - 1] !== parent) {
        parentIds = [...parentIds, parent];
      }

      // add current department
      position++;
      normalizedDepartments[id] = {
        id,
        name,
        parentIds,
        position,
      };

      // recursively normalize and add departments
      normalizeDepartmentTreeR(children, parentIds);
    });
  }

  normalizeDepartmentTreeR(departmentTree, []);

  return normalizedDepartments;
}
