import React from 'react';
import { useStyles } from 'uinix-ui';

import { Box } from '../../Box';

interface Props {
  isActive: boolean;
  text: string;
  onClick: () => void;
}

export const YearMonth = ({ text, onClick }: Props) => {
  const styles = useStyles();

  return (
    <Box
      as="button"
      border="none"
      borderRadius={0}
      className="rdp-button rdp-day"
      w="dateinput.date.width"
      px={4}
      py={2}
      styles={styles.button.base}
      onClick={onClick}
    >
      {text}
    </Box>
  );
};
