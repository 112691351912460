import React from 'react';
import { connect } from 'react-redux';

import {
  workflowRemoveReviewApproval,
  workflowRemoveReviewApprovalCount,
  workflowSetReviewApprovalCount,
  workflowSetReviewApprovalDescription,
  workflowSetReviewApprovalOrder,
  workflowSetReviewApprovalTitle,
  workflowSetReviewPhaseApprovers,
  workflowToggleReviewApprovalType,
  workflowToggleReviewConditionRule,
} from '~/actions';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import { hasValidApprovalValue } from '~/components/Workflow/Workflow.utils';
import { WorkflowReviewFinalizeApprovalType } from '~/enums';
import { useUsers } from '~/hooks';
import { getReviewPhaseById } from '~/reducers/workflow';
import {
  Box,
  EditableText,
  FlexLayout,
  NumberInput,
  RadioGroup,
  Text,
  useModal,
  useModalSortable,
} from '~/ui';
import { CHARACTER_INPUT_LIMIT_TEMPORARY } from '~/ui/enums/input';
import { toSnakeTrimmed } from '~/utils/strings';
import { coerceName } from '~/utils/workflow';

import ConditionRuleInput from '../../../shared/ConditionRuleInput';
import DescriptionTextAreaForm from '../../../shared/DescriptionTextAreaForm';
import WorkflowUserDepartmentMultiSelect from '../../../shared/WorkflowUserDepartmentMultiSelect';

const formSegmentNested = {
  mb: 5,
  backgroundColor: 'black-alpha-04',
  borderRadius: 'm',
};

function PhaseItem({
  phaseId,
  approval,
  index,
  canReorder,
  //connected
  disabled,
  workflow,
  workflowRemoveReviewApproval,
  workflowSetReviewApprovalTitle,
  workflowSetReviewApprovalDescription,
  workflowSetReviewPhaseApprovers,
  workflowToggleReviewConditionRule,
  workflowToggleReviewApprovalType,
  workflowSetReviewApprovalCount,
  workflowRemoveReviewApprovalCount,
  workflowSetReviewApprovalOrder,
}) {
  const { id } = approval;
  const reviewApprovals =
    getReviewPhaseById(workflow, phaseId)?.approvals || [];
  const [sortableListModal, showSortableListModal] = useModalSortable({
    confirm: (sortedItems) =>
      workflowSetReviewApprovalOrder({
        orders: sortedItems.map((sortedItem) => sortedItem.value),
        phaseId,
      }),
    items: reviewApprovals.map((item, index) => ({
      value: item.id,
      label: coerceName(item.name, 'Review Task', index),
    })),
    text: 'Sort',
    title: 'Reorder Reviews',
  });

  const users = useUsers();

  const [deleteModal, showDeleteModal] = useModal({
    actionButton: {
      variant: 'red',
      text: 'Remove',
      errorHandler: () => {},
      promise: async () => await workflowRemoveReviewApproval({ id, phaseId }),
    },
    title: 'Remove Review?',
    content: (
      <Text color="gray-900" variant="s-spaced">
        Are you sure you want to remove the review?{' '}
        <Text variant="s-spaced-bold">
          You won’t be able to undo this action.
        </Text>
      </Text>
    ),
  });

  const coercedTaskName = coerceName(approval.name, 'Review Task', index);
  const taskName = toSnakeTrimmed(coercedTaskName);

  const getMenuItems = () => {
    const items = [];

    items.push({
      content: 'Reorder Reviews',
      disabled: !canReorder,
      onClick: () => showSortableListModal(),
    });

    items.push({
      content: 'Delete Review',
      onClick: () => showDeleteModal(),
    });

    return items;
  };

  const hasUserDepartmentError = !hasValidApprovalValue(approval, users);

  return (
    <Box>
      <Box sx={formSegmentNested}>
        <Box sx={{ borderBottom: 'border' }}>
          <FlexLayout p={4} alignItems="center" justifyContent="space-between">
            <EditableText
              id={`workflow--review-edit-task-name=${taskName}-name`}
              placeholder={`Review Task ${index + 1}`}
              value={approval.name}
              onChange={(value) =>
                workflowSetReviewApprovalTitle({ value, id, phaseId })
              }
            />
            <Box pl={4}>
              <ActionsMenu
                id={`workflow_builder_review_phase_menu_id=${phaseId}`}
                items={getMenuItems()}
                align="end"
              />
            </Box>
          </FlexLayout>
        </Box>
        <Box p={4}>
          <Box pb={2} sx={{ borderBottom: 'border' }}>
            <DescriptionTextAreaForm
              description={approval.description}
              disabled={disabled}
              id={`workflow--review-edit-task-name=${taskName}-description`}
              limit={CHARACTER_INPUT_LIMIT_TEMPORARY}
              onUpdate={(value) =>
                workflowSetReviewApprovalDescription({ value, id, phaseId })
              }
            />
          </Box>
          <Box pt={4} pb={4} mt={4} sx={{ borderBottom: 'border' }}>
            <ConditionRuleInput
              conditionRule={approval.conditionRule}
              id={`workflow--review-edit-task-name=${taskName}-condition-rule`}
              onChange={(conditionRule) =>
                workflowToggleReviewConditionRule({
                  conditionRule,
                  id,
                  phaseId,
                })
              }
            />
          </Box>
          <Box mt={4} mb={4}>
            <Text variant="xs-dense-caps">Reviewers</Text>
          </Box>

          <Box mb={4}>
            <WorkflowUserDepartmentMultiSelect
              error={
                hasUserDepartmentError
                  ? "There are users that don't exist anymore"
                  : ''
              }
              id={`workflow--review-edit-task-name=${taskName}-reviewers`}
              value={approval.approvers}
              onChange={(updatedApprovers) => {
                if (!updatedApprovers.length) {
                  workflowRemoveReviewApprovalCount({ id, phaseId });
                }
                workflowSetReviewPhaseApprovers({
                  approvers: updatedApprovers,
                  id,
                  phaseId,
                });
              }}
            />
          </Box>

          <Box mt={4}>
            <Text variant="xs-dense-caps">Assign to</Text>
            <RadioGroup
              id={`workflow--review-edit-task-name=${taskName}-assign-to`}
              inline={true}
              label="Require Approval From"
              value={approval.approvalType}
              options={[
                {
                  label: 'Everyone',
                  value: WorkflowReviewFinalizeApprovalType.Everyone,
                },
                {
                  label: 'Specific number of reviewers',
                  value: WorkflowReviewFinalizeApprovalType.Specific,
                },
              ]}
              onChange={(approvalType) =>
                workflowToggleReviewApprovalType({ approvalType, id, phaseId })
              }
            />
          </Box>

          {approval.approvalType !==
            WorkflowReviewFinalizeApprovalType.Everyone && (
            <FlexLayout alignItems="baseline" space={4} mt={4}>
              <Text
                color={!approval.approvers.length && 'gray-500'}
                variant="xs-dense"
              >
                Number of reviewers to assign this to
              </Text>
              <NumberInput
                disabled={!approval.approvers.length}
                id={`workflow--review-edit-task-name=${taskName}-number-reviewers`}
                value={approval.minApprovalCount}
                min={1}
                onChange={(minApprovalCount) =>
                  workflowSetReviewApprovalCount({
                    minApprovalCount,
                    id,
                    phaseId,
                  })
                }
              />
            </FlexLayout>
          )}
        </Box>
      </Box>
      {sortableListModal}
      {deleteModal}
    </Box>
  );
}

function mapStateToProps({ builder, workflow }) {
  return {
    disabled: !builder.shouldEnableAllComponents,
    workflow,
  };
}

export default connect(mapStateToProps, {
  workflowRemoveReviewApproval,
  workflowSetReviewApprovalTitle,
  workflowSetReviewApprovalDescription,
  workflowSetReviewPhaseApprovers,
  workflowToggleReviewConditionRule,
  workflowToggleReviewApprovalType,
  workflowSetReviewApprovalCount,
  workflowRemoveReviewApprovalCount,
  workflowSetReviewApprovalOrder,
})(PhaseItem);
