import { api } from '~/redux';
import { Department, PilotId } from '~/types';

import { useClient } from './useClient';

export const useDepartments = (): Record<PilotId, Department> => {
  const client = useClient();
  const clientId = client?.id;

  const { data = {} } = api.endpoints.getAllDepartments.useQueryState(
    clientId,
    { skip: !clientId },
  );
  return data as Record<PilotId, Department>;
};
