import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import {
  searchWordsContainOptions,
  searchWordsScopeOptions,
} from '../../AnalyzerPage/TextSearchForm/TextSearchForm.utils';
import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import EcSelect from '../../Shared/EcSelect';
import CloseIcon from '../../Shared/Icons/CloseIcon';
import PlusIcon from '../../Shared/Icons/PlusIcon';
import styles from './SearchWithinProvisionModal.styles';

class SearchWithinProvisionModal extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleAddTextFilter = this.handleAddTextFilter.bind(this);
    this.handleRemoveTextFilter = this.handleRemoveTextFilter.bind(this);

    this.state = {
      filterOptions: {
        filterContains: searchWordsContainOptions[0],
        filterScope: searchWordsScopeOptions[0],
        filterPhrase: '',
      },
      textFilters: this.props.filter.textSearch || [],
    };
  }

  handleInputChange(event) {
    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        filterPhrase: event.target.value,
      },
    });
  }

  handleActionClick() {
    const { handleUpdateFilter, filterIndex, filter, hideModal } = this.props;
    const {
      filterOptions: { filterPhrase },
      textFilters,
    } = this.state;

    if (filterPhrase.length) {
      this.setState({ addButtonHighlighted: true });
    } else {
      handleUpdateFilter(filterIndex, { ...filter, textSearch: textFilters });
      hideModal();
    }
  }

  handleAddTextFilter() {
    const { filterOptions } = this.state;

    this.setState((prevState) => ({
      textFilters: [...prevState.textFilters, filterOptions],
      filterOptions: {
        ...prevState.filterOptions,
        filterPhrase: '',
      },
      addButtonHighlighted: false,
    }));
  }

  handleRemoveTextFilter(index) {
    let newTextFilters = [...this.state.textFilters];
    newTextFilters.splice(index, 1);

    this.setState({ textFilters: newTextFilters });
  }

  renderSearchFilters() {
    const { classes } = this.props;
    const { textFilters } = this.state;

    return textFilters.map((filter, index) => (
      <div key={`text-filter-${index}`} className={classes.filterRow}>
        <hr className={classes.textSearchRowSeparator} />
        <div className={classes.searchOptions}>
          <div className={classes.textSearchSelectItem}>
            {filter.filterContains.label}
          </div>
          <div className={classes.textSearchSelectItem}>
            {filter.filterScope.label}
          </div>
          <div className={classes.textSearchInputItem}>
            {filter.filterPhrase}
          </div>
          <button
            className={classes.textSearchRemoveButton}
            onClick={() => this.handleRemoveTextFilter(index)}
          >
            <CloseIcon size="20" />
          </button>
        </div>
        <hr className={classes.textSearchRowSeparator} />
      </div>
    ));
  }

  renderTextSearchOptions() {
    const { classes } = this.props;
    const { filterOptions, addButtonHighlighted } = this.state;

    const inputPlaceholder =
      filterOptions.filterScope.value === 'exact_phrase'
        ? 'type in a phrase…'
        : 'space-separated list of words';

    return (
      <Fragment>
        <div className={classes.textSearchSelectItem}>
          <EcSelect
            options={searchWordsContainOptions}
            value={filterOptions.filterContains}
            onChange={(option) =>
              this.setState({
                filterOptions: {
                  ...this.state.filterOptions,
                  filterContains: option,
                },
              })
            }
          />
        </div>
        <div className={classes.textSearchSelectItem}>
          <EcSelect
            options={searchWordsScopeOptions}
            value={filterOptions.filterScope}
            onChange={(option) =>
              this.setState({
                filterOptions: {
                  ...this.state.filterOptions,
                  filterScope: option,
                },
              })
            }
          />
        </div>
        <div className={classes.textSearchInputItem}>
          <div className={classes.inputContainer}>
            <input
              type="text"
              placeholder={inputPlaceholder}
              value={filterOptions.filterPhrase}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className={classes.textSearchOptionItem}>
          <EcButton
            text="Add"
            highlighted={
              addButtonHighlighted ? addButtonHighlighted.toString() : ''
            }
            iconLeft={<PlusIcon color="#000" />}
            onClick={this.handleAddTextFilter}
            disabled={!filterOptions.filterPhrase.length}
          />
        </div>
      </Fragment>
    );
  }

  renderAddButtonNotClickedMessage() {
    const { classes } = this.props;

    return (
      <div className={classes.errorMessage}>
        Select "Add" to add the text search filter.
      </div>
    );
  }

  render() {
    const { classes, title, hideModal, filter } = this.props;
    const { addButtonHighlighted } = this.state;

    return (
      <EcModalCard
        title={title}
        content={
          <>
            {addButtonHighlighted && this.renderAddButtonNotClickedMessage()}
            <div className={classes.provisionLabel}>PROVISION</div>
            <div className={classes.provisionType}>
              {filter.filterValue.label}
            </div>
            <div className={classes.searchLabel}>
              SEARCH WITHIN SELECTED PROVISION
            </div>
            <div className={classes.searchOptions}>
              {this.renderTextSearchOptions()}
            </div>
            <div className={classes.searchFilters}>
              {this.renderSearchFilters()}
            </div>
          </>
        }
        footer={
          <>
            <EcButton white text="Cancel" onClick={hideModal} />
            <EcButton
              yellow
              text="Search Within Provision"
              onClick={this.handleActionClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

export default injectSheet(styles)(SearchWithinProvisionModal);
