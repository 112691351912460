import React, { Dispatch, useEffect } from 'react';
import { connect } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { Layout, Modal } from '~/eds';
import { ClauseLibraryViewType } from '~/enums';
import { actions, api, coerceRtkqError } from '~/redux';
import { clauseLibraryListClauses } from '~/redux/api/methods';
import { useRouting } from '~/routing';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { htmlToText } from '~/utils/strings';

import {
  CLAUSE_LIBRARY_NAME_CHAR_LIMIT,
  GUIDANCE_NOTES_CHAR_LIMIT,
} from '../constants';
import {
  ClauseForm as ClauseFormInterface,
  State as ClauseLibraryState,
  Position,
  VariationForm as VariationFormInterface,
} from '../types';
import ClauseForm from './ClauseForm';
import VariationForm from './VariationForm';

const ClauseLibraryManagement = ({
  clauseForm,
  editMode,
  variationForm,
  view,
  resetClauseLibraryExceptSelectedClause,
  setClauseForm,
  setVariationForm,
  setClauseLibraryView,
  setClauseLibraryEditMode,
}: {
  clauseForm: ClauseFormInterface;
  variationForm: VariationFormInterface;
  editMode: boolean;
  view: ClauseLibraryViewType;
  resetClauseLibraryExceptSelectedClause: Dispatch<void>;
  setClauseForm: Dispatch<ClauseFormInterface>;
  setVariationForm: Dispatch<VariationFormInterface>;
  setClauseLibraryView: Dispatch<ClauseLibraryViewType>;
  setClauseLibraryEditMode: Dispatch<boolean>;
}) => {
  const { navigate } = useRouting();

  //// SERVER CALLS
  const [
    createClause,
    createClauseResult,
  ] = api.endpoints.clauseLibraryCreateClause.useMutation();
  const {
    error: createClauseError,
    isError: isCreateClauseError,
    isSuccess: isCreateClauseSuccess,
  } = createClauseResult;

  const [
    editClause,
    editClauseResult,
  ] = api.endpoints.clauseLibraryEditClause.useMutation();
  const {
    error: editClauseError,
    isError: isEditClauseError,
    isSuccess: isEditClauseSuccess,
  } = editClauseResult;

  const [
    createVariation,
    createVariationResult,
  ] = api.endpoints.clauseLibraryCreateVariation.useMutation();
  const {
    error: createVariationError,
    isError: isCreateVariationError,
    isSuccess: isCreateVariationSuccess,
  } = createVariationResult;

  const [
    editVariation,
    editVariationResult,
  ] = api.endpoints.clauseLibraryEditVariation.useMutation();
  const {
    error: editVariationError,
    isError: isEditVariationError,
    isSuccess: isEditVariationSuccess,
  } = editVariationResult;

  //// FORM VALIDATION
  const clauseName = clauseForm.name?.trim();
  const clauseNameMissing = !clauseName;
  const clauseNameOverLimit =
    (clauseName?.length || 0) > CLAUSE_LIBRARY_NAME_CHAR_LIMIT;
  const clauseNameInvalid = clauseNameMissing || clauseNameOverLimit;
  const clauseGuidanceNotes = clauseForm.guidanceNotes;
  const clauseGuidanceNotesOverLimit =
    htmlToText(clauseGuidanceNotes || '').trim().length >
    GUIDANCE_NOTES_CHAR_LIMIT;
  const clauseFormInvalid = clauseNameInvalid || clauseGuidanceNotesOverLimit;

  const variationName = variationForm.name?.trim();
  const variationNameOverLimit =
    (variationName?.length || 0) > CLAUSE_LIBRARY_NAME_CHAR_LIMIT;
  const variationPositionMissing = !variationForm?.position;
  const variationGuidanceNotes = variationForm.guidanceNotes;
  const variationGuidanceNotesMissing = !variationGuidanceNotes;
  const variationGuidanceNotesOverLimit =
    htmlToText(variationGuidanceNotes || '').trim().length >
    GUIDANCE_NOTES_CHAR_LIMIT;
  const variationText = variationForm.clauseText;
  const variationTextMissing = !variationText;
  const variationTextOverLimit =
    htmlToText(variationText || '').trim().length > GUIDANCE_NOTES_CHAR_LIMIT;
  const variationFormInvalid =
    variationNameOverLimit ||
    variationPositionMissing ||
    variationGuidanceNotesOverLimit ||
    variationTextOverLimit ||
    (variationGuidanceNotesMissing && variationTextMissing);

  //// POST-SUBMIT, DISPLAY TOAST, & ERROR HANDLING
  const postCreateClause = async () => {
    // selectedClause relies on id in url which is not available on creation
    const response = await clauseLibraryListClauses({});
    const clause = response.find((clause) => clause.name === clauseForm.name);
    resetClauseLibraryExceptSelectedClause();
    navigate(`/clause-library/${clause?.key || ''}`);
  };

  const postEditClause = () => {
    resetClauseLibraryExceptSelectedClause();
    navigate(`/clause-library/${clauseForm.clauseId}`);
  };

  const postCreateVariation = async () => {
    // selectedClause relies on id in url which is not available on creation
    const response = await clauseLibraryListClauses({});
    const clause = response.find(
      (clause) => clause.key === variationForm.clauseId,
    );
    const variation = clause?.children.find(
      (variation) =>
        variation.name ===
        (variationForm.name ?? variationForm.namePlaceholder),
    );
    resetClauseLibraryExceptSelectedClause();
    navigate(`/clause-library/${clause?.key ?? ''}#${variation?.key ?? ''}`);
  };

  const postEditVariation = () => {
    resetClauseLibraryExceptSelectedClause();
    navigate(
      `/clause-library/${variationForm.clauseId}#${variationForm.variationId}`,
    );
  };

  useEffect(() => {
    if (isCreateClauseSuccess) {
      showToast(SUCCESS, `${clauseName} successfully added`);
      postCreateClause();
    }
    if (isCreateClauseError) {
      showToast(ERROR, `Error occurred while creating ${clauseName}`);
      if (
        !!coerceRtkqError(createClauseError)?.response?.data?.errors?.length
      ) {
        setClauseForm({
          ...clauseForm,
          errors: coerceRtkqError(createClauseError).response.data.errors,
        });
      }
    }
  }, [isCreateClauseSuccess, isCreateClauseError]);

  useEffect(() => {
    if (isEditClauseSuccess) {
      showToast(SUCCESS, `Changes to ${clauseName} saved successfully`);
      postEditClause();
    }
    if (isEditClauseError) {
      showToast(ERROR, `Error occurred while saving changes to ${clauseName}`);
      if (!!coerceRtkqError(editClauseError)?.response?.data?.errors?.length) {
        setClauseForm({
          ...clauseForm,
          errors: coerceRtkqError(editClauseError).response.data.errors,
        });
      }
    }
  }, [isEditClauseSuccess, isEditClauseError]);

  useEffect(() => {
    if (isCreateVariationSuccess) {
      showToast(SUCCESS, `${variationName} successfully added`);
      postCreateVariation();
    }
    if (isCreateVariationError) {
      showToast(ERROR, `Error occurred while creating ${variationName}`);
      if (
        !!coerceRtkqError(createVariationError)?.response?.data?.errors?.length
      ) {
        setVariationForm({
          ...variationForm,
          errors: coerceRtkqError(createVariationError).response.data.errors,
        });
      }
    }
  }, [isCreateVariationSuccess, isCreateVariationError]);

  useEffect(() => {
    if (isEditVariationSuccess) {
      showToast(SUCCESS, `Changes to ${variationName} saved successfully`);
      postEditVariation();
    }
    if (isEditVariationError) {
      showToast(
        ERROR,
        `Error occurred while saving changes to ${variationName}`,
      );
      if (
        !!coerceRtkqError(editVariationError)?.response?.data?.errors?.length
      ) {
        setVariationForm({
          ...variationForm,
          errors: coerceRtkqError(editVariationError).response.data.errors,
        });
      }
    }
  }, [isEditVariationSuccess, isEditVariationError]);

  //// VIEW LOGIC
  function handleClauseFormSubmission() {
    if (editMode) {
      editClause({
        clauseId: clauseForm.clauseId as string,
        name: clauseName,
        guidance: clauseGuidanceNotes,
      });
    } else {
      createClause({ name: clauseName, guidance: clauseGuidanceNotes });
    }
  }

  function handleVariationFormSubmission() {
    if (editMode) {
      editVariation({
        variationId: variationForm.variationId ?? '',
        nickname: variationName || (variationForm.namePlaceholder ?? ''),
        guidance: variationGuidanceNotes,
        text: variationText,
        position: variationForm.position as Position,
        requiresApproval: false,
      });
    } else {
      createVariation({
        clauseId: variationForm.clauseId ?? '',
        nickname: variationName || (variationForm.namePlaceholder ?? ''),
        guidance: variationGuidanceNotes,
        text: variationText,
        position: variationForm.position as Position,
        requiresApproval: false,
      });
    }
  }

  function displayClauseErrors() {
    const validationErrors = [];
    if (clauseNameMissing) validationErrors.push('clause name missing');
    setClauseForm({ ...clauseForm, validationErrors });
  }

  function displayVariationErrors() {
    const validationErrors = [];
    if (variationPositionMissing) validationErrors.push('position missing');
    if (variationTextMissing) validationErrors.push('text missing');
    setVariationForm({ ...variationForm, validationErrors });
  }

  const content = {
    [ClauseLibraryViewType.ClauseForm]: {
      component: ClauseForm,
      title: editMode ? `Edit ${clauseForm.title}` : 'New Clause',
      primary: {
        text: editMode ? 'Save Changes' : 'Add Clause',
        onClick: clauseFormInvalid
          ? displayClauseErrors
          : handleClauseFormSubmission,
      },
      onCancel: () => {
        setClauseLibraryView(ClauseLibraryViewType.MainPage);
        setClauseLibraryEditMode(false);
        setClauseForm({
          name: '',
          guidanceNotes: '',
          title: '',
          errors: [],
          validationErrors: [],
        });
      },
    },
    [ClauseLibraryViewType.VariationForm]: {
      component: VariationForm,
      title: editMode ? `Edit ${variationForm.title}` : 'New Clause Variation',
      primary: {
        text: editMode ? 'Save Changes' : 'Add Clause Variation',
        onClick: variationFormInvalid
          ? displayVariationErrors
          : handleVariationFormSubmission,
      },
      onCancel: () => {
        setClauseLibraryView(ClauseLibraryViewType.MainPage);
        setClauseLibraryEditMode(false);
        setVariationForm({
          name: '',
          guidanceNotes: '',
          clauseText: '',
          title: '',
          validationErrors: [],
        });
      },
    },
  };

  if (view === ClauseLibraryViewType.MainPage) return null;

  //   @ts-ignore
  const { component: Content, title, primary, onCancel } = content[view];
  return (
    <Modal
      title={title}
      isFullPage={true}
      // @ts-ignore
      isVisible={!!content[view]}
      primaryAction={primary}
      // secondaryActions={}
      onCancel={onCancel}
      cancelText="Cancel"
    >
      <Layout w="80%" maxW="1000px" p={4} alignSelf="center" justify="center">
        <Content />
      </Layout>
    </Modal>
  );
};

const mapStateToProps = ({
  clauseLibrary,
}: {
  clauseLibrary: ClauseLibraryState;
}) => ({
  clauseForm: clauseLibrary.clauseForm,
  variationForm: clauseLibrary.variationForm,
  editMode: clauseLibrary.editMode,
  view: clauseLibrary.view,
});

export default connect(mapStateToProps, {
  resetClauseLibraryExceptSelectedClause:
    actions.resetClauseLibraryExceptSelectedClause,
  setClauseForm: actions.setClauseLibraryClauseForm,
  setVariationForm: actions.setClauseLibraryVariationForm,
  setClauseLibraryEditMode: actions.setClauseLibraryEditMode,
  setClauseLibraryView: actions.setClauseLibraryView,
})(ClauseLibraryManagement);
