import pluralize from 'pluralize';
import React from 'react';
import { connect } from 'react-redux';

import {
  workflowAddSigner,
  workflowAddSigningPhase,
  workflowRemoveSigner,
  workflowRemoveSigningPhase,
  workflowSetSignCoordinator,
  workflowSetSigner,
  workflowSetSignerConditionRule,
  workflowSetSignPhaseName,
  workflowSetStageConditionRule,
  workflowSortSignPhases,
  workflowToggleSkipStage,
} from '~/actions';
import { Box, Button, FormField } from '~/eds';
import { TicketStageType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { getSignErrors, getSignPhases } from '~/reducers/workflow';
import { FlexLayout, Text } from '~/ui';

import SignStageItems from '../../shared/SignStageItems';
import SkipStageAccordion from '../../shared/SkipStageAccordion';
import StageItems from '../../shared/StageItems';
import WorkflowUserRoleSingleSelect from '../../shared/WorkflowUserRoleSingleSelect';
import Signer from './Signer';

function Sign({
  // connected
  conditionRule,
  coordinator,
  disabled,
  errors,
  isSkipped,
  signPhases,
  workflowAddSigner,
  workflowAddSigningPhase,
  workflowRemoveSigner,
  workflowRemoveSigningPhase,
  workflowSetSignCoordinator,
  workflowSetSigner,
  workflowSetSignerConditionRule,
  workflowSortSignPhases,
  workflowSetSignPhaseName,
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
}) {
  const hasESigEnhancementFeatureFlag = useFlag(FlagType.EsignEnhancements);

  if (
    hasESigEnhancementFeatureFlag &&
    (!signPhases || signPhases.length === 0)
  ) {
    // add an initial sign phase if the workflow doesn't have one
    workflowAddSigningPhase();
  }

  const checkPhaseTitle = (signPhase) => {
    if (hasESigEnhancementFeatureFlag) {
      signPhase.title = 'Recipients';
    }
    return signPhase;
  };

  const signItems = signPhases.map((phase) => ({
    ...checkPhaseTitle(phase),
    content: (
      <FlexLayout disabled={disabled} flexDirection="column" space={8}>
        <FlexLayout flexDirection="column" space={4}>
          {phase.signers.map((signer, index) => (
            <Signer
              key={index}
              signer={signer}
              onRemoveSigner={() => {
                workflowRemoveSigner({ index, phaseId: phase.id });
              }}
              onUpdateSigner={(signerId) => {
                workflowSetSigner({ signerId, index, phaseId: phase.id });
              }}
              onUpdateSignerUser={(defaultSigner) => {
                workflowSetSigner({
                  signerId: signer.id,
                  index,
                  phaseId: phase.id,
                  defaultSigner,
                });
              }}
              onUpdateConditionRule={(conditionRule) => {
                workflowSetSignerConditionRule({
                  conditionRule,
                  index,
                  phaseId: phase.id,
                });
              }}
            />
          ))}
          <Box>
            <Button
              text="Add Signer"
              onClick={() => workflowAddSigner(phase.id)}
            />
          </Box>
        </FlexLayout>
      </FlexLayout>
    ),
  }));

  const unspecifiedSignerIdsCount = errors.unspecifiedSignerIds.length;

  const handleUpdateStageConditionRule = (updatedConditionRule) => {
    workflowSetStageConditionRule({
      conditionRule: updatedConditionRule,
      stage: TicketStageType.Sign,
    });
  };

  const handleToggleSkipStage = (updatedIsSkipped) => {
    workflowToggleSkipStage({
      isSkipped: updatedIsSkipped,
      stage: TicketStageType.Sign,
    });
  };

  return (
    <FlexLayout disabled={disabled} flexDirection="column" space={6}>
      <SkipStageAccordion
        conditionRule={conditionRule}
        isSkipped={isSkipped}
        onChange={handleUpdateStageConditionRule}
        onToggle={handleToggleSkipStage}
      />
      <FlexLayout flexDirection="column" space={4}>
        <FormField
          description="A signature coordinator will be in charge of making sure that all the signatures have been collected."
          id="workflow--sign-coordinator"
          input={WorkflowUserRoleSingleSelect}
          label="Signing Coordinator"
          disabled={disabled}
          width="fullWidth"
          placeholder="Not specified"
          error={
            errors.unspecifiedCoordinator
              ? 'You need to specify a signing coordinator.'
              : undefined
          }
          value={coordinator}
          onChange={workflowSetSignCoordinator}
        />
      </FlexLayout>
      {unspecifiedSignerIdsCount > 0 && (
        <Text
          bg="red-100"
          color="red-600"
          p={4}
          sx={{ borderRadius: 'm' }}
          variant="xs-dense"
        >
          Please specify{' '}
          {pluralize('additional signer', unspecifiedSignerIdsCount, true)} or
          remove the unassigned signers on this workflow via the sidebar.
        </Text>
      )}
      {hasESigEnhancementFeatureFlag ? (
        <SignStageItems
          items={signItems}
          invalidPhaseIds={errors.invalidPhaseIds}
        />
      ) : (
        <StageItems
          disabled={disabled}
          items={signItems}
          invalidPhaseIds={errors.invalidPhaseIds}
          removeModalTitle="Remove Signing Phase"
          sortModalTitle="Reorder Signing Phases"
          onRemove={workflowRemoveSigningPhase}
          onSort={workflowSortSignPhases}
          onEditTitle={workflowSetSignPhaseName}
          newItemAction={{
            text: 'Add a signing phase',
            handler: workflowAddSigningPhase,
          }}
        />
      )}
    </FlexLayout>
  );
}

function mapStateToProps({ builder, workflow }) {
  var stage = workflow.stages[TicketStageType.Sign];
  return {
    conditionRule: stage.conditionRule,
    coordinator: stage.coordinators[0] || null,
    disabled: !builder.shouldEnableAllComponents,
    errors: getSignErrors(workflow),
    isSkipped: !stage.isEnabled,
    signPhases: getSignPhases(workflow),
  };
}

export default connect(mapStateToProps, {
  workflowAddSigner,
  workflowAddSigningPhase,
  workflowRemoveSigner,
  workflowRemoveSigningPhase,
  workflowSetSignCoordinator,
  workflowSetSigner,
  workflowSetSignPhaseName,
  workflowSetSignerConditionRule,
  workflowSortSignPhases,
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
})(Sign);
