import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ticketActivitiesSet } from '~/actions';
import {
  downloadTicketDocument,
  getActivities,
  shareTicketDocument,
} from '~/api';
import airplaneSrc from '~/assets/images/cta/airplane.svg';
import downloadSrc from '~/assets/images/cta/download.svg';
import { showToast } from '~/components/Shared/EcToast';
import { EntityType, TicketStageType } from '~/enums';
import { useAsync } from '~/hooks';
import { getDocumentVersion } from '~/reducers/ticket';
import { useRouting } from '~/routing';
import { DOWNLOAD, ERROR, SUCCESS } from '~/types/toast.types';
import { CtaSection, FlexLayout, Text } from '~/ui';
import { coerceFileType, downloadFile, getBasename } from '~/utils/files';

import OutboundEmailModal from '../../../../Modals/EmailModal/OutboundEmailModal';

const ShareAndDownloadCta = ({
  nextStageString,
  ticketId,
  title,
  shouldShowShare,
  shouldShowDownload,
  stage,
  // connected
  ticket,
  ticketActivitiesSet,
  version,
}) => {
  const { navigate } = useRouting();
  const { document } = ticket;
  const { id: documentId, name, type } = document;
  const versionId = version?.id;
  const attachments = type ? [{ name, type: coerceFileType(type || '') }] : [];
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();
  let shareDescription = 'Share the contract';
  if (stage !== TicketStageType.Review && nextStageString) {
    shareDescription += ' before submitting it for ' + nextStageString;
  }

  function handleShowModal() {
    setShowModal(true);
  }

  function handleHideModal() {
    setShowModal(false);
  }

  const { executor: handleOnShareSuccess } = useAsync(
    getActivities,
    { entityId: ticketId, entityType: EntityType.Ticket },
    {
      successHandler: ticketActivitiesSet,
    },
  );

  const actionButton = {
    text: 'Share',
    variant: 'primary',
    errorHandler: () =>
      showToast(ERROR, 'Unable to share document, please try again'),
    promise: async function () {
      if (data) {
        await shareTicketDocument({ data, documentId, versionId: versionId });
        handleOnShareSuccess();
        navigate(`/workflow/tickets/${ticketId}`);
        showToast(SUCCESS, 'Document successfully shared.');
      }
    },
  };

  const { executor: handleOnDownloadClick } = useAsync(
    downloadTicketDocument,
    { documentId, versionId },
    {
      successToastMessage: `"${ticket.name}" is now downloading\u2026`,
      successToastType: DOWNLOAD,
      successHandler: async (file) => {
        downloadFile(
          file,
          `${getBasename(document.name)}_${document.version}.${
            version.fileType
          }`,
        );
        const activities = await getActivities({
          entityId: ticket.id,
          entityType: EntityType.Ticket,
        });
        ticketActivitiesSet(activities);
      },
      errorToastMessage:
        'There has been an error with downloading this document. Please try again.',
    },
  );

  if (!shouldShowShare) {
    return (
      <CtaSection
        actionButton={{
          disabled: !versionId,
          text: 'Download',
          onClick: handleOnDownloadClick,
        }}
        description={
          <FlexLayout>
            <Text>Download the contract for use offline.</Text>
          </FlexLayout>
        }
        imageSrc={downloadSrc}
      />
    );
  }

  return (
    <CtaSection
      actionButton={{
        text: 'Share',
        onClick: handleShowModal,
      }}
      title={title}
      description={
        <FlexLayout>
          <div>
            {shareDescription}
            {shouldShowDownload ? (
              <Text>
                &nbsp;or&nbsp;
                <Text
                  color="blue-500"
                  variant="xs-dense-bold"
                  disabled={!versionId}
                  onClick={handleOnDownloadClick}
                >
                  Download
                </Text>
                &nbsp;it for use offline
              </Text>
            ) : null}
            .
          </div>
          <OutboundEmailModal
            actionButton={actionButton}
            attachments={attachments}
            title="Share Document"
            visible={showModal}
            onHide={handleHideModal}
            onUpdate={setData}
          />
        </FlexLayout>
      }
      imageSrc={airplaneSrc}
    />
  );
};

const mapStateToProps = ({ ticket }) => ({
  ticket,
  version: getDocumentVersion(ticket),
});

const mapDispatchToProps = { ticketActivitiesSet };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareAndDownloadCta);
