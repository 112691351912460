import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';

export const AppData = () => {
  const currentUser = useCurrentUser();

  const clientId = currentUser?.client;
  /* All users have permission to query other users */
  api.endpoints.getAllUsers.useQuery(clientId, {
    skip: !clientId,
  });

  // TODO: Once we introduce new department endpoint this should be removed. We will not be requesting all departments at once.
  api.endpoints.getAllDepartments.useQuery(clientId, {
    skip: !clientId,
  });

  return null;
};
