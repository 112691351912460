export default `
<svg
  aria-hidden="true"
  aria-label="launch"
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
>
  <path
    d="M14.6611 6.5C14.2236 6.5 13.9111 6.1875 13.9111 5.75C13.9111 5.34375 14.2236 5 14.6611 5H19.1611C19.5674 5 19.9111 5.34375 19.9111 5.75V10.25C19.9111 10.6875 19.5674 11 19.1611 11C18.7236 11 18.4111 10.6875 18.4111 10.25V7.5625L12.1611 13.7812C11.8799 14.0938 11.4111 14.0938 11.1299 13.7812C10.8174 13.5 10.8174 13.0312 11.1299 12.7188L17.3486 6.5H14.6611ZM5.91113 7.75C5.91113 6.8125 6.69238 6 7.66113 6H11.1611C11.5674 6 11.9111 6.34375 11.9111 6.75C11.9111 7.1875 11.5674 7.5 11.1611 7.5H7.66113C7.50488 7.5 7.41113 7.625 7.41113 7.75V17.25C7.41113 17.4062 7.50488 17.5 7.66113 17.5H17.1611C17.2861 17.5 17.4111 17.4062 17.4111 17.25V13.75C17.4111 13.3438 17.7236 13 18.1611 13C18.5674 13 18.9111 13.3438 18.9111 13.75V17.25C18.9111 18.2188 18.0986 19 17.1611 19H7.66113C6.69238 19 5.91113 18.2188 5.91113 17.25V7.75Z"
    fill="currentcolor"
  />
</svg>
`;
