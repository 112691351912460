import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { Input, Nullable } from '../../types';
import { Box } from '../Box';

type Props = Input<number> & {
  /** Supported UI modes */
  mode?: 'digits';
  /** Max value allowed */
  max?: Nullable<number>;
  /** Min value allowed */
  min?: Nullable<number>;
  /** HTML `step` attribute */
  step?: number | 'any';
};

export const NumberInput = memo(
  ({ error, mode, onChange, step = 'any', value, width, ...rest }: Props) => {
    const styles = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const valueString = event.target.value;
      if (event.target.checkValidity()) {
        onChange(valueString === '' ? null : Number(valueString));
      }
    };

    const handleFocus = (focusEvent: React.FocusEvent<HTMLInputElement>) => {
      focusEvent.target.addEventListener(
        'wheel',
        (wheelEvent: Event) => wheelEvent.preventDefault(),
        { passive: false },
      );
    };

    return (
      <Box
        {...rest}
        as="input"
        step={step}
        onFocus={handleFocus}
        styles={[
          styles.input.container,
          mode === 'digits' ? componentStyles.digitsWidth : null,
        ]}
        styleProps={{ error, value, width }}
        type="number"
        value={value === null ? '' : value}
        onChange={handleChange}
      />
    );
  },
);

const componentStyles = {
  digitsWidth: ({ value }: { value: Nullable<number> }) => ({
    minWidth: 'unset',
    paddingRight: 0,
    width: `calc(${(value ?? 1).toString().length}ch + 36px)`,
  }),
};
