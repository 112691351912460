import { atlas } from '~/services';

import { QuestionResponsePayload } from './types';
import { mapQuestionResponse } from './utils';

export const resolveQuestions = async ({ ids }: { ids: string[] }) => {
  const questionPromises: Promise<QuestionResponsePayload>[] = ids.map((id) =>
    atlas.get(`questions/${id}`),
  );

  const questionsResults = await Promise.allSettled(questionPromises);

  const questions = questionsResults
    .filter((result) => result.status === 'fulfilled')
    // @ts-ignore
    .map((result) => result.value);

  return questions.map((question) => mapQuestionResponse(question.data));
};
