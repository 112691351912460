import {
  customSettingsKeys,
  customSettingsOptionValues,
} from '~/constants/workflow';
import { WorkflowFieldType } from '~/enums';
import { validateEmail } from '~/utils/strings';

export function testIsInvalidQuestion(
  question,
  questionFieldCustomSettings,
  questionTitle,
) {
  let isInvalid;
  if (question?.type === WorkflowFieldType.File) {
    isInvalid =
      questionFieldCustomSettings[
        customSettingsKeys[WorkflowFieldType.File].MIN_FILE_COUNT
      ] < 0 ||
      !questionTitle ||
      !question?.id;
  } else if (
    question?.type === WorkflowFieldType.Email &&
    customSettingsOptionValues[question?.type].EMAIL_TYPE.MANUAL ===
      questionFieldCustomSettings[
        customSettingsKeys[WorkflowFieldType.Email].EMAIL_TYPE
      ]
  ) {
    isInvalid = !(
      questionFieldCustomSettings.defaultValue &&
      questionFieldCustomSettings.defaultValue.value &&
      validateEmail(questionFieldCustomSettings.defaultValue.value)
    );
  } else {
    isInvalid = !questionTitle || !question?.id;
  }
  return isInvalid;
}

export function testIsValidDefaultValue(
  questionField,
  questionFieldCustomSettings,
) {
  const defaultValue = questionFieldCustomSettings?.defaultValue?.value;
  const optionsArray = questionFieldCustomSettings?.options;
  const options = [];
  for (const index in optionsArray) {
    options.push(optionsArray[index].value);
  }
  if (questionField?.type === WorkflowFieldType.SingleSelect) {
    return !!defaultValue ? options.includes(defaultValue) : true;
  } else if (questionField?.type === WorkflowFieldType.MultiSelect) {
    for (const index in defaultValue) {
      if (!options.includes(defaultValue[index])) return false;
    }
  }
  return true;
}
