import useInterval from '@use-it/interval';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';

import { showToast } from '../components/Shared/EcToast';
import * as toastTypes from '../types/toast.types';
import {
  CancellablePromise,
  createCancellablePromise,
} from '../utils/createCancellablePromise';

export interface Options {
  condition: boolean;
  deps: any[];
  errorHandler: (error: Error) => void;
  errorToastMessage: string;
  intervalMs: number | null;
  successHandler: (response: any) => void;
  successToastMessage: string;
  successToastType: any; // Note: this was unintentionally introduced so leaving a note to deprecate/remove this accordingly.
}

interface State<R> {
  isLoading: boolean;
  error?: Error;
  response?: R;
}

interface HooksData<R> extends State<R> {
  executor: () => void;
}

/**
 * Reusable effect hook to process async callbacks.
 * - Provide a callback and params to run when condition is true.
 * - Rerun the hooks when provided deps change.
 * - Capture response and error in relevant callbacks.
 * - Conveniently render toast status via the errorToastMessage and successToastMessage options.
 * - Returns the response, isLoading, and error fields for the request.
 */
export const useAsync = <P, _T, R>(
  callback: (params: P) => Promise<R>,
  params: P,
  options: Partial<Options>,
): HooksData<R> => {
  const {
    condition = false,
    deps = [],
    errorHandler = noop,
    errorToastMessage,
    intervalMs,
    successHandler = noop,
    successToastMessage,
    successToastType = toastTypes.SUCCESS,
  } = options;

  const [shouldExecute, setShouldExecute] = useState(false);
  const [state, setState] = useState<State<R>>({ isLoading: false });

  function executor() {
    setShouldExecute(true);
  }

  const executeAsync = () => {
    let cancellablePromise: CancellablePromise<R>;
    if (shouldExecute || condition) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      cancellablePromise = createCancellablePromise(callback(params));
      cancellablePromise.promise
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            response,
            isLoading: false,
          }));
          successHandler(response);
          successToastMessage &&
            showToast(successToastType, successToastMessage);
        })
        .catch((error) => {
          if (!error.cancelled) {
            setState((prevState) => ({
              ...prevState,
              error,
              isLoading: false,
            }));
            errorHandler(error);
            let errorToastMessageWithReponse = errorToastMessage;
            if (error.response) {
              const { data: resp_error_msg } = error.response.data;
              const errorContext = resp_error_msg ? `(${resp_error_msg})` : '';
              errorToastMessageWithReponse = `${
                errorToastMessageWithReponse ?? ''
              }${errorContext}`;
            }
            errorToastMessageWithReponse &&
              showToast(toastTypes.ERROR, errorToastMessageWithReponse);
          }
        })
        .finally(() => {
          setShouldExecute(false);
        });

      return () => {
        if (cancellablePromise) {
          cancellablePromise.cancel();
        }
        setShouldExecute(false);
      };
    }
  };

  const executeAsyncWithInterval = () => {
    if (intervalMs) {
      executeAsync();
    }
  };

  useEffect(
    executeAsync,
    [...deps, shouldExecute], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useInterval(executeAsyncWithInterval, intervalMs || null);

  return { ...state, executor };
};
