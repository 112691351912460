import React from 'react';

import { Button } from '~/eds';

interface Props {
  onClear: () => void;
}

export const ClearFilters = ({ onClear }: Props) => {
  return <Button text="Clear Filters" variant="action" onClick={onClear} />;
};
