import React, { ReactNode } from 'react';

import { formatDate, Layout, Quote, Text } from '~/eds';

interface Props {
  content: ReactNode;
  quote?: ReactNode;
  dateTime: Date;
}
function ActivityLogItemComponent({ content, quote, dateTime }: Props) {
  return (
    <Layout wrap={true} direction="column">
      <Text>{content}</Text>
      {quote && <Quote wrap>{quote}</Quote>}
      <Text preset="help">{formatDate(dateTime, 'full')}</Text>
    </Layout>
  );
}

export const ActivityLogItem = React.memo(ActivityLogItemComponent);
