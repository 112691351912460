import PropTypes from 'prop-types';
import React from 'react';

import { Alert, FlexLayout } from '~/ui';

import { barItemBaseProps } from './ImportStatusBarPresets';

const UserImportStatusBarList = ({
  clientId,
  importJobsData,
  handleAcknowledgeImportJobSuccessUpdate,
}) => {
  const handleAcknowledgeUpdate = (acknowledgedImportId) => {
    handleAcknowledgeImportJobSuccessUpdate({ acknowledgedImportId });
  };

  const buildBarItemProperties = (importData) => {
    const { counters, importId, status } = importData;
    const barItemBaseProperties = barItemBaseProps[status];
    return {
      importId,
      enableIcon: true,
      variant: barItemBaseProperties.getIcon(counters),
      actions: barItemBaseProperties.getActions({ clientId, importData }),
      children: barItemBaseProperties.getBarItemText(importData, clientId),
      onDismiss: barItemBaseProperties.getDismissAction
        ? () =>
            barItemBaseProperties.getDismissAction({
              clientId,
              importData,
              handleAcknowledgeUpdate,
            })
        : null,
    };
  };

  const renderImportStatusBarList = (importStatusBarItemsProps) => (
    <FlexLayout flexDirection="column" space={1} mb={1}>
      {importStatusBarItemsProps.map((props) => (
        <Alert key={`barItem_${props.importId}`} {...props} />
      ))}
    </FlexLayout>
  );

  const importStatusBarItemsProps = importJobsData.map((importData) =>
    buildBarItemProperties(importData),
  );

  return renderImportStatusBarList(importStatusBarItemsProps);
};

UserImportStatusBarList.propTypes = {
  clientId: PropTypes.number.isRequired,
  importJobsData: PropTypes.array.isRequired,
  handleAcknowledgeImportJobSuccessUpdate: PropTypes.func,
};

export default UserImportStatusBarList;
