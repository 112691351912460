import { Placement } from 'tippy.js';

export const TIPPY_PRESETS = {
  popover: {
    className: 'eds-popover',
    tippyProps: {
      arrow: false,
      trigger: 'click',
    },
  },
  'popover-hover': {
    className: 'eds-popover',
    tippyProps: {
      arrow: false,
      trigger: 'mouseenter focusin',
    },
  },
  dropdown: {
    className: 'eds-dropdown',
    tippyProps: {
      arrow: false,
      trigger: 'click',
      placement: 'bottom-start' as Placement,
    },
  },
  tooltip: {
    className: 'eds-tooltip',
    tippyProps: {
      arrow: true,
      trigger: 'mouseenter focusin',
    },
  },
  'tooltip.inverse': {
    className: 'eds-tooltip-inverse',
    tippyProps: {
      arrow: true,
      trigger: 'mouseenter focusin',
    },
  },
};
