import get from 'lodash/get';
import noop from 'lodash/noop';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectDocumentInGroup from '~/components/SearchV2/AddSearchResultsToGroupV2/SelectDocumentInGroup';
import SelectGroup from '~/components/SearchV2/AddSearchResultsToGroupV2/SelectGroup';
import { AddToGroupStepType, HttpStatusCodeType } from '~/enums';
import { api, selectors } from '~/redux';
import documents from '~/redux/slices/documents';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { LoadingContainer, WizardPageLayout } from '~/ui';

import { showToast } from '../../Shared/EcToast';

const MAX_TITLE_WIDTH = 355;

const AddToGroup = ({
  hideModal = noop,
  documentIds,
  folderId,
  reloadDocumentContent = noop,
}) => {
  const step = useSelector(selectors.selectDocumentsCurrentAddToGroupStep);

  const selectedGroupId = useSelector(selectors.selectDocumentsSelectedGroupId);
  const selectedDocumentId = useSelector(
    selectors.selectDocumentsSelectedDocumentId,
  );

  const dispatch = useDispatch();

  const [
    addDocumentsToGroup,
    addDocumentsToGroupResult,
  ] = api.endpoints.addDocumentsToGroup.useMutation();
  const {
    isError: addDocumentsToGroupError,
    isSuccess: addDocumentsToGroupSuccess,
    isLoading: addDocumentsToGroupLoading,
    error: addDocumentsToGroupResponseError,
  } = addDocumentsToGroupResult;

  const [
    addDocumentsToGroupLinkParent,
    addDocumentsToGroupLinkParentResult,
  ] = api.endpoints.addDocumentsToGroupLinkParent.useMutation();
  const {
    isError: addDocumentsToGroupLinkParentError,
    isSuccess: addDocumentsToGroupLinkParentSuccess,
    isLoading: addDocumentsToGroupLinkParentLoading,
    error: addDocumentsToGroupLinkParentResponseError,
  } = addDocumentsToGroupLinkParentResult;

  const [
    addDocumentsToGroupByFolder,
    addDocumentsToGroupByFolderResult,
  ] = api.endpoints.addDocumentsToGroupByFolder.useMutation();

  const {
    isError: addDocumentsToGroupByFolderError,
    isSuccess: addDocumentsToGroupByFolderSuccess,
    isLoading: addDocumentsToGroupByFolderLoading,
    error: addDocumentsToGroupByFolderResponseError,
  } = addDocumentsToGroupByFolderResult;

  const [
    createDocumentGroup,
    createDocumentGroupResult,
  ] = api.endpoints.createDocumentGroup.useMutation();
  const {
    isError: createDocumentGroupError,
    isSuccess: createDocumentGroupSuccess,
    isLoading: createDocumentGroupLoading,
    error: createDocumentGroupResponseError,
    data: createDocumentGroupData,
  } = createDocumentGroupResult;

  const resetAddToGroupModal = () => {
    hideModal();
    dispatch(
      documents.actions.setCurrentAddToGroupStep(AddToGroupStepType.GROUP),
    );
    dispatch(documents.actions.setSelectedDocumentId(undefined));
  };

  useEffect(() => {
    if (createDocumentGroupSuccess) {
      handleAddDocumentsToGroup(createDocumentGroupData.groupId);
    }
  }, [createDocumentGroupSuccess]);

  useEffect(() => {
    if (
      addDocumentsToGroupSuccess ||
      addDocumentsToGroupLinkParentSuccess ||
      addDocumentsToGroupByFolderSuccess
    ) {
      showToast(SUCCESS, 'Documents added to the group successfully!');
      resetAddToGroupModal();
      reloadDocumentContent();
    }
  }, [
    addDocumentsToGroupSuccess,
    addDocumentsToGroupLinkParentSuccess,
    addDocumentsToGroupByFolderSuccess,
  ]);

  useEffect(() => {
    if (
      addDocumentsToGroupError ||
      addDocumentsToGroupLinkParentError ||
      addDocumentsToGroupByFolderError ||
      createDocumentGroupError
    ) {
      const error =
        addDocumentsToGroupResponseError ||
        addDocumentsToGroupLinkParentResponseError ||
        addDocumentsToGroupByFolderResponseError ||
        createDocumentGroupResponseError;
      const { response } = error;
      const defaultErrorMessage =
        'Error on adding documents to the group, please try again later!';
      if (response.status === HttpStatusCodeType.BadRequest) {
        showToast(ERROR, get(response, 'data.message', defaultErrorMessage));
      } else {
        showToast(ERROR, defaultErrorMessage);
      }
      resetAddToGroupModal();
    }
  }, [
    addDocumentsToGroupError,
    addDocumentsToGroupLinkParentError,
    addDocumentsToGroupByFolderError,
    createDocumentGroupError,
  ]);

  const handleSelectGroup = useCallback((groupId) => {
    dispatch(
      documents.actions.setCurrentAddToGroupStep(AddToGroupStepType.STRUCTURE),
    );
    dispatch(documents.actions.setSelectedGroupId(groupId));
  }, []);

  const handleSelectedDocument = useCallback((documentId) => {
    dispatch(documents.actions.setSelectedDocumentId(documentId));
  }, []);

  const handleAddDocumentsToGroup = (groupId) => {
    if (selectedDocumentId) {
      addDocumentsToGroupLinkParent({
        groupId: selectedGroupId || groupId,
        parentId: selectedDocumentId,
        documentIds,
        folder: folderId,
      });
    } else {
      if (folderId) {
        addDocumentsToGroupByFolder({
          groupId: selectedGroupId || groupId,
          folder: folderId,
        });
      } else {
        addDocumentsToGroup({
          groupId: selectedGroupId || groupId,
          documentIds,
        });
      }
    }
    dispatch(documents.actions.setSelectedDocumentId(undefined));
    dispatch(documents.actions.setSelectedGroupId(undefined));
  };

  const handleNewGroupCreated = ({ name, note }) => {
    createDocumentGroup({ name, note });
  };

  const steps = useMemo(() => {
    return {
      [AddToGroupStepType.GROUP]: {
        title: 'Adding documents to group',
        component: (
          <SelectGroup
            onSelectGroup={handleSelectGroup}
            onNewGroupCreated={handleNewGroupCreated}
          />
        ),
        action: {
          promise: () => resetAddToGroupModal(),
          text: 'Cancel',
          variant: 'ghost',
        },
      },
      [AddToGroupStepType.STRUCTURE]: {
        title: 'Add to group',
        component: (
          <SelectDocumentInGroup
            group={selectedGroupId}
            onDocumentSelected={handleSelectedDocument}
          />
        ),
        action: {
          text: 'Add to group',
        },
      },
    };
  }, [selectedGroupId]);

  const isLoading =
    addDocumentsToGroupLoading ||
    addDocumentsToGroupLinkParentLoading ||
    addDocumentsToGroupByFolderLoading ||
    createDocumentGroupLoading;

  const buttonTitle = selectedDocumentId
    ? 'Add to group as linked documents'
    : steps[step].action.text;
  const handleActionPromise =
    step === AddToGroupStepType.STRUCTURE
      ? handleAddDocumentsToGroup
      : steps[step].action.promise;
  return (
    <WizardPageLayout
      currentStep={step}
      steps={[AddToGroupStepType.GROUP, AddToGroupStepType.STRUCTURE]}
      title={{ text: steps[step].title, maxWidth: MAX_TITLE_WIDTH }}
      onClose={resetAddToGroupModal}
      actionButton={{
        ...steps[step].action,
        text: buttonTitle,
        promise: handleActionPromise,
      }}
      isContentFill={true}
    >
      <LoadingContainer isLoading={isLoading}>
        {steps[step].component}
      </LoadingContainer>
    </WizardPageLayout>
  );
};

export default AddToGroup;
