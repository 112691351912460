export default `
<svg
  aria-hidden="true"
  aria-label="share"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M20.75 8.44739L16.25 4.22864C15.9375 3.94739 15.4688 3.94739 15.1875 4.25989C14.9062 4.57239 14.9062 5.04114 15.2188 5.32239L18.3438 8.25989H13.25C11.9688 8.19739 10.7812 8.69739 9.90625 9.60364C9 10.5099 8.5 11.6974 8.5 12.9786V15.7286C8.5 16.1349 8.8125 16.4474 9.25 16.4474C9.65625 16.4474 10 16.1661 10 15.7286V12.9786C10 12.1036 10.3125 11.2911 10.9375 10.6661C11.5625 10.0724 12.3125 9.69739 13.25 9.72864H18.3438L15.2188 12.6974C14.9062 12.9786 14.9062 13.4474 15.1875 13.7599C15.3438 13.8849 15.5312 14.0099 15.7188 14.0099C15.9062 14.0099 16.0938 13.9161 16.25 13.7911L20.75 9.57239C20.9062 9.38489 21 9.19739 21 8.97864C21 8.79114 20.9062 8.60364 20.75 8.44739ZM14.25 15.9786C13.8125 15.9786 13.5 16.3224 13.5 16.7286V18.1974C13.5 18.3224 13.375 18.4161 13.25 18.4161H4.75C4.59375 18.4161 4.5 18.2911 4.5 18.1974V9.72864C4.5 9.60364 4.59375 9.50989 4.75 9.50989H6.5C6.90625 9.50989 7.25 9.19739 7.25 8.79114C7.25 8.41614 6.90625 8.07239 6.5 8.07239H4.75C3.78125 8.07239 3 8.85364 3 9.79114V18.2599C3 19.1974 3.78125 19.9786 4.75 19.9786H13.25C14.1875 19.9786 15 19.1974 15 18.2599V16.7911C15 16.3224 14.6562 15.9786 14.25 15.9786Z"
    fill="currentcolor"
  />
</svg>
`;
