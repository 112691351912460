import { parseISO } from 'date-fns';

import { Balance, CreditSummary } from '~/features/billing';
import { codeCash } from '~/services';

import {
  GetSummaryApiResponse,
  IncludedBalances,
  IncludedRecurringCredits,
} from '../../types/codecash/external';
import { getIncludedDataFromRelations } from './utils';

export const getCreditSummary = async (
  clientId: number,
): Promise<CreditSummary> => {
  const response: GetSummaryApiResponse = await codeCash.get(
    `clients/${clientId}/credits`,
  );

  const includeData = getIncludedDataFromRelations<
    GetSummaryApiResponse['data']['relationships'],
    IncludedRecurringCredits | IncludedBalances
  >(response.data.relationships, response.included ?? []);

  const recurringCredits:
    | IncludedRecurringCredits['attributes']
    | undefined = includeData?.recurringCredits
    ? (includeData.recurringCredits[0]
        .attributes as IncludedRecurringCredits['attributes'])
    : undefined;

  const parsedRecurringCredits = recurringCredits
    ? {
        ...recurringCredits,
        renewalDate: parseISO(recurringCredits.renewalDate),
        notes: recurringCredits.notes ?? '',
      }
    : undefined;

  let totalCredits = 0;

  const balances = includeData?.balances?.map(
    (balance): Balance => {
      const attributes = balance.attributes as IncludedBalances['attributes'];
      const isRecurringBalance =
        (includeData?.recurringCredits?.[0] as IncludedRecurringCredits)
          ?.relationships?.balances?.data.id === balance.id;

      totalCredits += attributes.credits;

      return {
        ...attributes,
        createdBy: attributes.addedBy,
        effectiveDate: parseISO(attributes.effectiveDate),
        eventDate: parseISO(attributes.eventDate),
        expirationDate: isRecurringBalance
          ? undefined
          : parseISO(attributes.expirationDate),
        renewalDate:
          parsedRecurringCredits && isRecurringBalance
            ? parsedRecurringCredits.renewalDate
            : undefined,
        id: balance.id,
      };
    },
  );

  return {
    credits: response.data.attributes.available,
    balances,
    recurringCredits: parsedRecurringCredits,
    totalCredits,
  };
};
