export const debounce = <F extends (...args: any[]) => unknown>(
  fn: F,
  timeout: number,
): ((...args: Parameters<F>) => void) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<F>): void => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), timeout);
  };
};
