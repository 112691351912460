import { types } from '~/eds';
import { VariationData } from '~/redux/api/methods';
import { sortStrings } from '~/utils';

import { Position } from './types';

const sortByPosition = (a: Position, b: Position): number => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  const order = {
    Preferred: -1,
    Fallback: 0,
    'Walk away': 1,
  };
  return order[a] - order[b];
};

export const sortVariationsByPositionAndName = (
  a: VariationData | types.TreeNode,
  b: VariationData | types.TreeNode,
): number => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  const aPosition: Position =
    (a as VariationData).position ?? (a as types.TreeNode).status?.label;
  const bPosition: Position =
    (b as VariationData).position ?? (b as types.TreeNode).status?.label;
  if (aPosition !== bPosition) {
    // compare positions
    return sortByPosition(aPosition, bPosition);
  } else {
    // same position, sort alphabetically by name
    return sortStrings(a.name, b.name);
  }
};
