import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User } from '~/components/Shared/User';
import { TableContextType } from '~/enums';
import { Balance } from '~/features/billing';
import { useTableSettings } from '~/hooks';
import { Uuid } from '~/types';

interface Props {
  pageIndex: number;
  totalCount: number;
  transactions: Balance[];
  setPanel: (panelName: string, id?: Uuid) => void;
}

export const CreditsLedger = ({
  totalCount,
  transactions,
  setPanel,
}: Props) => {
  const {
    tableSettings = {
      // pageSize: 10,
      columnOrder: [],
    },
  } = useTableSettings(TableContextType.CreditLedger);

  const { columnOrder } = tableSettings;

  const columns = [
    {
      key: 'event date',
      cellType: 'datetime',
      title: 'Event Date',
      disableSortBy: true,
      disableResizing: true,
      width: 'm',
      mapCellProps: (transaction: Balance) => ({
        datetime: transaction.eventDate,
      }),
    },
    {
      key: 'added by',
      cellType: 'user',
      disableSortBy: true,
      title: 'Added By',
      mapCellProps: (transaction: Balance) => ({
        asyncUser: {
          id: transaction.createdBy,
          render: User,
        },
        mode: 'name',
      }),
    },
    {
      key: 'effective date',
      cellType: 'datetime',
      title: 'Effective Date',
      disableSortBy: true,
      disableResizing: true,
      width: 'm',
      mapCellProps: (transaction: Balance) => ({
        datetime: transaction.effectiveDate,
      }),
    },
    {
      key: 'expiration date',
      cellType: 'datetime',
      title: 'Expiration Date',
      disableSortBy: true,
      disableResizing: true,
      width: 'm',
      mapCellProps: (transaction: Balance) => ({
        datetime: transaction.expirationDate,
      }),
    },
    {
      key: 'renewal date',
      cellType: 'datetime',
      title: 'Renewal Date',
      width: 'm',
      disableSortBy: true,
      disableResizing: true,
      mapCellProps: (transaction: Balance) => ({
        datetime: transaction.renewalDate,
      }),
    },
    {
      key: 'entry type',
      cellType: 'text',
      title: 'Entry Type',
      width: 'm',
      disableSortBy: true,
      disableResizing: true,
      mapCellProps: (transaction: Balance) => ({
        text: transaction.entryType,
      }),
    },
    {
      key: 'credits',
      align: 'right',
      cellType: 'number',
      title: 'Credits',
      width: 'm',
      disableSortBy: true,
      disableResizing: true,
      mapCellProps: (transaction: Balance) => ({
        number: transaction.credits,
      }),
    },
  ];

  return (
    <PersistedTable
      name={TableContextType.CreditLedger}
      columns={columns}
      context={TableContextType.CreditLedger}
      data={transactions}
      options={{
        enableSelectRows: false,
        enablePagination: false,
      }}
      rowActions={[
        {
          label: 'Edit Balance',
          onClick: async (balance: Balance) => {
            if (balance.renewalDate) {
              setPanel('recurring-credits');
            } else {
              setPanel('one-time-credits', balance.id);
            }
          },
        },
      ]}
      state={{
        // pageIndex,
        // pageSize,
        columnOrder,
      }}
      totalCount={totalCount}
      // onPaginate={handlePaginate}
    />
  );
};
