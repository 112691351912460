import { types } from '~/eds';
import { RequireOnlyOne } from '~/eds/src/types';
import {
  EntityType,
  EsignatureType,
  TicketActivityType,
  WorkflowFieldType,
} from '~/enums';
import { everest } from '~/services';
import {
  ActivityLogEvent,
  Nullable,
  PilotId,
  TicketDocument,
  TicketDocumentVersion,
  TicketStage,
  TicketSummary,
  Uuid,
} from '~/types';

interface CancelTicketRequestParams {
  ticketId: Uuid;
  note?: string;
}

interface GetTicketDocumentRequestParams {
  ticketId: string;
}

interface GetTicketCurrentStageRequestParams {
  ticketId: string;
}
interface GetTicketSummaryRequestParams {
  ticketId: string;
}
interface GetActivitiesRequestParams {
  entityId: Uuid;
  entityType: EntityType;
}

interface CreateActivityLogParams {
  documentId: Uuid;
  isGenerated: boolean;
  referenceVersionId: Uuid;
  targetVersionId: Uuid;
  ticketId: Uuid;
  type: TicketActivityType;
  versionId: Uuid;
  versionNumber: number;
}

export interface QuestionField {
  customSettings: any;
  esignaturePlaceHolder: Nullable<any>;
  id: Uuid;
  isEsignaturePlaceholder: Nullable<any>;
  isEsignatureTag: boolean;
  isEsignatureTagOptional: boolean;
  isSignerField: boolean;
  name: string;
  options: any;
  placeholder: Nullable<any>;
  type: WorkflowFieldType;
  value: any;
}

export interface FormQuestion {
  description: Nullable<string>;
  field: QuestionField;
  fieldId: Uuid;
  id: Uuid;
  isRequired: boolean;
  isReadOnly: boolean;
  name: string;
  position: number;
  title: string;
}

export interface FormSection {
  description: string;
  id: Uuid;
  name: string;
  position: number;
  questions: FormQuestion[];
}

export interface IntakeForm {
  acceptedFileTypes: string[];
  conditions: any[];
  description: string;
  id: Uuid;
  implicitFields: any;
  name: string;
  sections: FormSection[];
  type: string;
  workflow: any;
  workflowId: Uuid;
}

export const getTicketIntakeForm = async (
  ticketId: Uuid,
): Promise<IntakeForm> => {
  return await everest.get(`/tickets/${ticketId}/intake-form`);
};

type JudgmentResultApiParam = {
  judgmentResultId: Uuid;
  email?: string;
  name?: string;
  userId?: number;
};

interface UpdatedRecipientsParams extends JudgmentResultApiParam {
  newCC: boolean;
  position: number;
}

interface DeleteRecipientsParams extends JudgmentResultApiParam {
  externalId?: Uuid;
}

interface ParticipantData {
  role: 'participant' | 'assignee';
  userId: types.PilotId;
}

interface CollectSignaturesResponse {
  redirectUrl: string;
}

export const cancelTicket = async ({
  ticketId,
  note,
}: CancelTicketRequestParams) => {
  return await everest.post(`tickets/${ticketId}/cancel/`, { reason: note });
};

export const getTicketDocument = async ({
  ticketId,
}: GetTicketDocumentRequestParams): Promise<TicketDocument> => {
  return await everest.get(`tickets/${ticketId}/document`);
};

export const getTicketCurrentStage = async ({
  ticketId,
}: GetTicketCurrentStageRequestParams): Promise<TicketStage> => {
  return await everest.get(`tickets/${ticketId}/current-stage`);
};

export const getTicketSummary = async ({
  ticketId,
}: GetTicketSummaryRequestParams): Promise<TicketSummary> => {
  return await everest.get(`tickets/${ticketId}/summary`);
};

export const getActivities = async ({
  entityId,
  entityType,
}: GetActivitiesRequestParams): Promise<ActivityLogEvent[]> => {
  const activities = (await everest.get('activities', {
    params: { entityId, entityType },
  })) as ActivityLogEvent[];
  return activities.map((activity) => ({
    ...activity,
    modifiedDate: new Date(activity.modifiedDate),
  }));
};

export async function createActivityLog({
  documentId,
  isGenerated,
  referenceVersionId,
  targetVersionId,
  ticketId,
  type,
  versionId,
  versionNumber,
}: CreateActivityLogParams) {
  const data = {
    documentId,
    isGenerated,
    referenceVersionId,
    targetVersionId,
    ticketId,
    type,
    versionId,
    versionNumber,
  };
  return await everest.post('/activities/', data);
}

export function updateRecipients({
  ticketId,
  updatedRecipients,
}: {
  ticketId: Uuid;
  updatedRecipients: UpdatedRecipientsParams[];
}) {
  return everest.patch(`/tickets/${ticketId}/signers`, {
    data: updatedRecipients,
  });
}

export function deleteRecipients({
  ticketId,
  recipients,
}: {
  ticketId: Uuid;
  recipients: DeleteRecipientsParams[];
}) {
  return everest.remove(`/tickets/${ticketId}/signers`, { data: recipients });
}

export function bumpVersion({
  documentId,
  versionId,
}: {
  documentId: Uuid;
  versionId: Uuid;
}): Promise<TicketDocumentVersion> {
  return everest.post(
    `/documents/${documentId}/versions/${versionId}/bump-version/`,
  );
}

type Ids = {
  userIds: PilotId[];
  typeIds: PilotId[];
};

export function addTicketParticipants({
  ticketId,
  type = 'user',
  typeIds,
  userIds,
}: {
  ticketId: Uuid;
  type: 'user' | 'department';
} & RequireOnlyOne<Ids>) {
  const ids = { ...(userIds && { userIds }), ...(typeIds && { typeIds }) };
  return everest.post(`/tickets/${ticketId}/participants`, { ...ids, type });
}

export function getTicketParticipants({
  ticketId,
}: {
  ticketId: Uuid;
}): Promise<{ data: ParticipantData[] }> {
  return everest.get(`/tickets/${ticketId}/participants/`);
}

export function collectSignatures({
  ticketId,
  esignatureProvider,
  ticketUrl,
}: {
  ticketId: Uuid;
  esignatureProvider: EsignatureType;
  ticketUrl: string;
}): Promise<{ data: CollectSignaturesResponse }> {
  return everest.post(`tickets/${ticketId}/create-envelope`, {
    esignatureProvider,
    ticketUrl,
  });
}

export function updateEnvelope({
  ticketId,
}: {
  ticketId: Uuid;
}): Promise<{ data: string }> {
  return everest.patch(`tickets/${ticketId}/update-envelope`, null, {
    // @ts-ignore
    fullResponse: true,
  });
}
