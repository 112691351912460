import React from 'react';
import { useStyles } from 'uinix-ui';

import {
  Box,
  ContentContainer,
  Layout,
  Link,
  Logo,
  StatusMessage,
} from '../../components';
import { DEPRECATED_REBRAND_PAGE_HEADER_TESTID } from '../../constants';
import {
  Action,
  NavLink,
  Nullable,
  Option,
  StatusType,
  User,
} from '../../types';
import { getUserInitials, getUserName } from '../../utils';
import { MainNav } from './MainNav';
import { NavPill } from './NavPill';
import { SubNav } from './SubNav';
import { testIsActiveLink } from './utils';

interface Props {
  activePathname: string;
  ctaButton?: React.ReactNode;
  help: {
    actions: Action[];
  };
  links: NavLink[];
  isLoading?: boolean;
  profile: {
    user: User;
    settings: Option[];
  };
  onSearchClick: () => void;
  onHelpClick?: () => void;
  onOnboardClick?: () => void;
  banner?: Nullable<{
    status: StatusType;
    message: string;
    onDismiss?: () => void;
  }>;
}

export const PageHeader = ({
  banner,
  links,
  activePathname,
  help,
  isLoading,
  profile,
  ctaButton,
  onSearchClick,
  onHelpClick,
}: Props) => {
  const styles = useStyles();

  const sublinks = links.find(testIsActiveLink(activePathname))
    ?.children as NavLink[];

  return (
    <Layout
      as="header"
      bg="background"
      data-testid={DEPRECATED_REBRAND_PAGE_HEADER_TESTID}
      direction="column"
      styles={styles.sticky.pageHeader}
    >
      <Layout
        align="center"
        bg="inverse.background"
        h="header.height.l"
        justify="space-between"
        px={px}
        spacing={px}
      >
        <Link pathname="/">
          <Logo mode="icon" theme="dark" />
        </Link>
        <ContentContainer loadingContent={{ isLoading }}>
          <MainNav activePathname={activePathname} links={links} />
        </ContentContainer>

        {ctaButton}

        <Layout preset="icons">
          <NavPill
            icon="search"
            mode="icon"
            text="Search"
            tooltip={SEARCH_TOOLTIP}
            onClick={onSearchClick}
          />

          <NavPill
            icon="help"
            id="help-button"
            text="Help"
            menu={
              help.actions.length
                ? {
                    name: 'help menu',
                    options: help.actions,
                    offsetY: MENU_OFFSET_Y,
                  }
                : undefined
            }
            onClick={onHelpClick}
            mode="icon"
            tooltip="Help"
          />

          <NavPill
            isBordered
            mode="image"
            imgSrc={profile.user.avatar}
            text={getUserInitials(profile.user)}
            tooltip={getUserName(profile.user)}
            menu={{
              name: 'user profile menu',
              options: profile.settings,
              offsetY: MENU_OFFSET_Y,
            }}
          />
        </Layout>
      </Layout>
      {sublinks && (
        <Box px={px}>
          <SubNav activePathname={activePathname} links={sublinks} />
        </Box>
      )}
      {banner && <StatusMessage {...banner} mode="banner" />}
    </Layout>
  );
};

const px = 8;
/** Offset to Y axis to improve menu visibility in Nav bar */
const MENU_OFFSET_Y = 8;
const SEARCH_TOOLTIP =
  'Search ' +
  (navigator.platform.toUpperCase().indexOf('MAC') >= 0
    ? '\u2318 + J'
    : 'Ctrl + J');
