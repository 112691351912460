import { decamelizeKeys } from 'humps';
import uuid from 'uuid';

import { AutomationClauseType, AutomationStatusType } from '~/enums';
import { pilot } from '~/services';
import { Uuid } from '~/types';

interface Request {
  clauseType: AutomationClauseType[];
  status: AutomationStatusType;
  pageIndex: number;
  pageSize: number;
}

interface ClausesResponse {
  results: {
    name: string;
    createdBy: string;
    customModelId: Uuid;
    status: 'active' | 'suspended';
    highlightCount: number;
    types: 'bulk_tagging' | 'smart_clause';
  }[];
  count: number;
  limit: number;
  next: string | null;
  previous: string | null;
  total: number;
}

interface Response {
  results: {
    name: string;
    created_by: string;
    custom_model_id: Uuid;
    highlight_count: number;
    status: 'active' | 'suspended';
    types: 'bulk_tagging' | 'smart_clause';
  }[];
  count: number;
  limit: number;
  next: string | null;
  previous: string | null;
  total: number;
}

export const getClauses = async ({
  clauseType,
  status,
  pageIndex: page,
  pageSize,
}: Request): Promise<ClausesResponse> => {
  const response: Response = await pilot.get('/ai-command-center/', {
    params: { ...decamelizeKeys({ clauseType }), status, page, pageSize },
  });

  return {
    ...response,
    results: response.results.map((c) => {
      return {
        ...c,
        id: uuid.v4(),
        createdBy: c.created_by,
        customModelId: c.custom_model_id,
        highlightCount: c.highlight_count,
      };
    }),
  };
};
