import PropTypes from 'prop-types';
import React, { useState } from 'react';
import injectSheet from 'react-jss';

import styles from './PasswordInputField.styles';

function PasswordInputField({ classes, disabled, id, label, value, onChange }) {
  const [passwordHidden, setPasswordHidden] = useState(true);

  return (
    <>
      <label className={classes.inputLabel} htmlFor={id}>
        {label}
      </label>
      <div className={classes.inputContainer}>
        <input
          className={classes.textInput}
          disabled={disabled}
          id={id}
          type={passwordHidden ? 'password' : 'text'}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <button
          className={classes.inputShowHideButton}
          disabled={disabled}
          type="button"
          onClick={() => setPasswordHidden(!passwordHidden)}
        >
          {passwordHidden ? 'SHOW' : 'HIDE'}
        </button>
      </div>
    </>
  );
}

PasswordInputField.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default injectSheet(styles)(PasswordInputField);
