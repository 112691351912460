import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EcModal from '~/components/Shared/EcModal';
import { selectors } from '~/redux';
import search from '~/redux/slices/search';
import { MODAL_DOCUMENT_GROUP } from '~/types/modal.types';

const AddSearchResultsToGroup = () => {
  const modal = useSelector(selectors.selectSearchResultModal);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(search.actions.setModal());
  const searchQuery = useSelector(selectors.selectQuery);
  const { modalType, context } = modal || {};

  if (modalType !== MODAL_DOCUMENT_GROUP) return null;

  const { selectedRowIds, isAllRowsSelected: isAllSelected } = context;

  return (
    <EcModal
      modalType={modalType}
      width="660px"
      title="Add to Group"
      documentIds={selectedRowIds}
      searchQuery={isAllSelected ? searchQuery : undefined}
      hideModal={hideModal}
    />
  );
};

export default AddSearchResultsToGroup;
