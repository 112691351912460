import { omit, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import { ticketAttachmentsSet as ticketAttachmentsSetAction } from '~/actions';
import { getTicketAttachments } from '~/api';
import TicketFilesSelect from '~/components/Modals/EmailModal/TicketFilesSelect';
import EcInput from '~/components/Shared/EcInput';
import EcTextarea from '~/components/Shared/EcTextarea';
import FormLabel from '~/components/Shared/FormLabel';
import {
  EMAIL_MODAL_BODY_INPUT_TESTID,
  EMAIL_MODAL_SUBJECT_INPUT_TESTID,
  EMAIL_MODAL_TO_INPUT_TESTID,
} from '~/constants/testids';
import { ButtonGroup, StatusMessage, Text } from '~/eds';
import { FileExtensionType, OutboundEmailFileType } from '~/enums';
import { PartySelectField } from '~/features/turn-tracking';
import { FlagType, useFlag } from '~/flags';
import { withCurrentUser, withUsers } from '~/hocs';
import { useAsync } from '~/hooks';
import { Divider, EmailSelect, enums, FileInput, FlexLayout } from '~/ui';
import Modal, { actionButtonPT } from '~/ui/components/Modal';
import { sortByStringValue } from '~/utils/array';
import { getFileExtension } from '~/utils/files';
import { validateEmail } from '~/utils/strings';
import { getVersionFileName } from '~/utils/ticket';
import { getUserName } from '~/utils/user';

import { attachmentPropTypes } from './Attachment';
import OutboundAttachment from './OutboundAttachment';

function OutboundEmailModal({
  actionButton,
  title,
  visible,
  onHide,
  onUpdate,
  // connected
  users,
  currentUser,
  ticket,
  ticketAttachmentsSet,
}) {
  const currentUserName = getUserName(users[currentUser.id]);
  const { document, documentVersions } = ticket;

  const currentVersion = documentVersions.find(
    (d) => d.versionNumber === document.version,
  );
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const defaultData = {
    to: [],
    cc: [],
    bcc: [],
    subject: `Document shared: ${getVersionFileName(
      document,
      currentVersion,
    )} from ${currentUserName}`,
    body:
      'Hi,\n\nPlease find documents for your review attached.\n\nSincerely,\n' +
      currentUserName,
  };

  const [draft, setDraft] = useState(defaultData);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const enableTurnTracking = useFlag(FlagType.TurnTracking);

  // update the draft data when opening the modal
  useEffect(() => {
    setDraft(defaultData);
  }, [visible]);

  useAsync(getTicketAttachments, ticket.id, {
    condition: visible,
    deps: [visible],
    successHandler: async (attachmentsResponse) => {
      ticketAttachmentsSet(attachmentsResponse);
    },
    errorToastMessage: 'There was an error when fetching for the attachments.',
  });

  useEffect(() => {
    const initialDefaultAttachment = generateDefaultAttachment();
    onUpdate(defaultData);
    if (initialDefaultAttachment) {
      setSelectedFiles([{ type: 'version', id: initialDefaultAttachment.id }]);
    }
  }, []);

  function generateDefaultAttachment() {
    const latestVersion = ticket.documentVersions[0];
    if (!latestVersion) return null;

    return {
      type: OutboundEmailFileType.ExistingVersion,
      id: latestVersion.id,
      name: getVersionFileName(document, currentVersion),
      version: latestVersion.versionNumber,
      uploadedBy: latestVersion.userId,
      uploadDate: latestVersion.lastUpdated,
      size: latestVersion.fileSize,
    };
  }

  function generateVersionAttachments() {
    return orderBy(
      selectedFiles
        .filter(
          (selectedFile) =>
            selectedFile.type === OutboundEmailFileType.ExistingVersion,
        )
        .map((selectedFile) => {
          const newVersion = ticket.documentVersions.find(
            (version) => version.id === selectedFile.id,
          );

          return {
            type: OutboundEmailFileType.ExistingVersion,
            id: newVersion.id,
            name: getVersionFileName(document, newVersion),
            version: newVersion.versionNumber,
            uploadedBy: newVersion.userId,
            uploadDate: newVersion.lastUpdated,
            size: newVersion.fileSize,
          };
        }),
      ['version'],
      ['desc'],
    );
  }

  function generateFileAttachments() {
    return selectedFiles
      .filter(
        (selectedFile) =>
          selectedFile.type === OutboundEmailFileType.ExistingAttachment,
      )
      .map((selectedFile) => {
        const newAttachment = ticket.attachments.find(
          (attachment) => attachment.id === selectedFile.id,
        );

        return {
          type: OutboundEmailFileType.ExistingAttachment,
          id: newAttachment.id,
          name: `${newAttachment.fileName}${newAttachment.fileType}`,
          version: newAttachment.versionNumber,
          uploadedBy: newAttachment.userId,
          uploadDate: newAttachment.lastUpdated,
          size: newAttachment.fileSize,
        };
      });
  }

  function generateUploadedAttachments(files) {
    return files.map((file) => ({
      type: OutboundEmailFileType.UploadedFile,
      id: uuid(),
      name: file.name,
      version: null,
      file: file,
      uploadedBy: null,
      uploadDate: null,
      size: file.size,
    }));
  }

  function handleHide() {
    setDraft(defaultData);
    onUpdate(defaultData);
    setShowCc(false);
    setShowBcc(false);
    onHide();
    setUploadedFiles([]);
    const defaultVersionAttachment = generateDefaultAttachment();
    setSelectedFiles([
      {
        type: OutboundEmailFileType.ExistingVersion,
        id: defaultVersionAttachment.id,
      },
    ]);
  }

  useEffect(() => {
    if (
      document.version &&
      ticket.documentVersions &&
      ticket.documentVersions.length > 0
    ) {
      const defaultVersionAttachment = generateDefaultAttachment();
      setSelectedFiles([
        {
          type: OutboundEmailFileType.ExistingVersion,
          id: defaultVersionAttachment.id,
        },
      ]);
    }
  }, [document.version, ticket.documentVersions]);

  useEffect(() => {
    const selectedVersions = generateVersionAttachments();
    const selectedAttachments = generateFileAttachments();

    const platformAttachments = [...selectedVersions, ...selectedAttachments];

    const newAttachments = [...platformAttachments, ...uploadedFiles];

    setAttachments(newAttachments);
    // remove unused fields and update data
    const attachmentsOmitted = platformAttachments.map((attachment) =>
      omit(attachment, ['version', 'uploadedBy', 'uploadDate', 'size']),
    );

    onUpdate((data) => ({
      ...data,
      attachments: attachmentsOmitted,
      uploadedAttachments: uploadedFiles.map(
        (uploadedAttachment) => uploadedAttachment.file,
      ),
    }));
  }, [selectedFiles, uploadedFiles]);

  function hasInvalidEmail(recipients) {
    return recipients.some((recipient) => !validateEmail(recipient.value));
  }

  function getEmailError(recipients) {
    if (hasInvalidEmail(recipients)) {
      return 'There is an error with your email list, please double check and add the right email.';
    }
    return '';
  }

  function disableShareButton(draft, body) {
    return (
      draft.to.length + draft.cc.length + draft.bcc.length === 0 ||
      body.length > enums.CHARACTER_INPUT_LIMIT_LONG ||
      body.length === 0 ||
      hasInvalidEmail(draft.to) ||
      hasInvalidEmail(draft.cc) ||
      hasInvalidEmail(draft.bcc)
    );
  }

  function getEmailBodyError(body) {
    if (!body) {
      return 'Missing characters. Need as least 1 character.';
    }
    return '';
  }

  function onUploadFiles(files) {
    if (files) {
      const fileList = generateUploadedAttachments(files);
      setUploadedFiles((filesInList) => [...filesInList, ...fileList]);
    }
  }

  function handleUpdateDraft(key, value) {
    const updatedDraft = { ...draft, [key]: value };
    setDraft(updatedDraft);
    const updatedData = {
      to: updatedDraft.to.map((item) => item.value),
      cc: updatedDraft.cc.map((item) => item.value),
      bcc: updatedDraft.bcc.map((item) => item.value),
      subject: updatedDraft.subject,
      body: updatedDraft.body,
      ...(enableTurnTracking && { turnTracking: updatedDraft.turnTracking }),
    };
    onUpdate((shareData) => ({
      ...shareData,
      ...updatedData,
    }));
  }

  const { to, cc, bcc, subject, body } = draft;
  const disabled = disableShareButton(draft, body);

  const findDocDocxInAttachment = () => {
    return attachments.some((file) => {
      const fileExtension = getFileExtension(file.name);
      return (
        fileExtension === FileExtensionType.Docx ||
        fileExtension === FileExtensionType.Doc
      );
    });
  };

  const isAttachmentSizeGreaterThan10MB = () => {
    let totalSizeInBytes = 0;
    const tenMBInBytes = 10 * 1024 * 1024;
    attachments.forEach((file) => (totalSizeInBytes += file.size));
    return totalSizeInBytes > tenMBInBytes;
  };

  const renderWarning = () => {
    let warningComponent = null;
    const hasDocOrDocx = findDocDocxInAttachment();
    const isSizeGreaterThanThan10MB = isAttachmentSizeGreaterThan10MB();

    let text = '';
    if (hasDocOrDocx && !isSizeGreaterThanThan10MB) {
      text =
        'Your document may contain comments. Please review and/or remove any comments from your file prior to sharing.';
    } else if (!hasDocOrDocx && isSizeGreaterThanThan10MB) {
      text =
        'If your attachments exceed the size allowed by the email provider, your email may fail to send.';
    } else if (hasDocOrDocx && isSizeGreaterThanThan10MB) {
      text =
        'If your email attachments exceed the size allowed by the email provider, your email may fail to send. Additionally, your document may contain comments. Please review and/or remove comments from your file prior to sharing.';
    }
    if (text) {
      warningComponent = <StatusMessage status="warning" message={text} />;
    }

    return warningComponent;
  };
  const options = useMemo(() => {
    return Object.values(users)
      .map((user) => ({
        value: user.email,
        label: `${getUserName(user)} (${user.email})`,
        chipLabel: getUserName(user),
      }))
      .sort(sortByStringValue('label'));
  }, [users]);

  const removeAttachment = (id, type) => {
    const updateFunction =
      type !== OutboundEmailFileType.UploadedFile
        ? setSelectedFiles
        : setUploadedFiles;
    updateFunction((prevAttachments) =>
      prevAttachments.filter((attachment) => attachment.id !== id),
    );
  };

  const renderAttachments = () => {
    return attachments.map((attachment, index) => (
      <FlexLayout flexDirection="column" key={index}>
        <OutboundAttachment
          onRemove={() => removeAttachment(attachment.id, attachment.type)}
          {...attachment}
        />
        {index < attachments.length - 1 && <Divider />}
      </FlexLayout>
    ));
  };

  return (
    <Modal
      disableCancelButton
      actionButton={{ ...actionButton, disabled }}
      title={title}
      visible={visible}
      onHide={handleHide}
      disableAutoHide
    >
      <FlexLayout flexDirection="column" space={8} sx={{ textAlign: 'left' }}>
        <FlexLayout justifyContent="space-between">
          <FormLabel
            className={EMAIL_MODAL_TO_INPUT_TESTID}
            input={
              <EmailSelect
                name="to recipients"
                inputId="emailSelect-to"
                onChange={(updatedEmails) => {
                  handleUpdateDraft('to', updatedEmails);
                }}
                values={to}
                options={options}
                errorMessage={getEmailError(to)}
              />
            }
            label="To Recipients"
          />
          <ButtonGroup
            actions={[
              {
                text: 'CC',
                onClick: () => setShowCc(!showCc),
                disabled: showCc,
              },
              {
                text: 'BCC',
                onClick: () => setShowBcc(!showBcc),
                disabled: showBcc,
              },
            ]}
          />
        </FlexLayout>
        {showCc && (
          <FormLabel
            className={EMAIL_MODAL_TO_INPUT_TESTID}
            input={
              <EmailSelect
                inputId="emailSelect-cc"
                onChange={(updatedEmails) => {
                  handleUpdateDraft('cc', updatedEmails);
                }}
                values={cc}
                options={options}
                errorMessage={getEmailError(cc)}
              />
            }
            label="CC"
          />
        )}
        {showBcc && (
          <FormLabel
            className={EMAIL_MODAL_TO_INPUT_TESTID}
            input={
              <EmailSelect
                inputId="emailSelect-bcc"
                onChange={(updatedEmails) => {
                  handleUpdateDraft('bcc', updatedEmails);
                }}
                values={bcc}
                options={options}
                errorMessage={getEmailError(bcc)}
              />
            }
            label="BCC"
          />
        )}
        {enableTurnTracking && (
          <PartySelectField
            workflowId={ticket.workflowId}
            onChange={(value) => {
              handleUpdateDraft('turnTracking', { partyId: value });
            }}
            errorMessage={{
              message: 'Try again in a few minutes.',
              title:
                'We’re experiencing difficulty loading Responsible Parties.',
            }}
          />
        )}
        <FormLabel
          className={EMAIL_MODAL_SUBJECT_INPUT_TESTID}
          input={
            <EcInput
              input={{
                value: subject,
                onChange: (e) => handleUpdateDraft('subject', e.target.value),
              }}
              width="100%"
            />
          }
          label="Subject"
        />
        <FormLabel
          className={EMAIL_MODAL_BODY_INPUT_TESTID}
          input={
            <EcTextarea
              value={body}
              placeholder="Please add a message to send."
              errorMessage={getEmailBodyError(body)}
              onChange={(e) => {
                handleUpdateDraft('body', e.target.value);
              }}
            />
          }
          label="Message"
        />
        <FlexLayout flexDirection="column" space={2}>
          <FlexLayout flexDirection="column" space={2}>
            <Text variant="body-bold">Attachments</Text>
            {renderWarning()}
          </FlexLayout>
          <FlexLayout flexDirection="column" space={6}>
            <FlexLayout flexDirection="column">
              {renderAttachments()}
            </FlexLayout>
          </FlexLayout>
          <FlexLayout mt={4} space={4}>
            <TicketFilesSelect
              value={selectedFiles}
              onSelect={setSelectedFiles}
            />
            <FileInput
              acceptedFiles={null}
              enableMultiple
              enableDropzone={false}
              label="Upload"
              onChange={onUploadFiles}
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
}

OutboundEmailModal.propTypes = {
  actionButton: actionButtonPT.isRequired,
  attachments: PropTypes.arrayOf(attachmentPropTypes.isRequired),
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ticket }) => ({ ticket });

export default connect(mapStateToProps, {
  ticketAttachmentsSet: ticketAttachmentsSetAction,
})(withUsers(withCurrentUser(OutboundEmailModal)));
