import { DEFAULT_LOCALE } from '../constants';
import { FormatNumberOptions } from '../types';
import { pluralize } from './pluralize';

/**
 * Formats a number based on Intl.NumberFormat.
 */
export const formatNumber = (
  number: number,
  options: FormatNumberOptions = {},
): string => {
  // Taking into account negative sign.
  const numberString = number.toString().replace('-', '');
  const defaultDecimal = numberString.split('.')[1]?.length || 0;
  const defaultPrecision =
    defaultDecimal === 0 ? numberString.replace('.', '').length : undefined;

  const {
    currency,
    decimal = defaultDecimal,
    enablePluralUnits = true,
    precision = defaultPrecision,
    style,
    unit,
  } = options;

  // assemble Intl.NumberFormatOptions
  const numberFormatOptions: Intl.NumberFormatOptions = {};
  numberFormatOptions.minimumFractionDigits = decimal;
  numberFormatOptions.maximumFractionDigits = decimal;
  numberFormatOptions.minimumSignificantDigits = precision;
  numberFormatOptions.maximumSignificantDigits = precision;
  numberFormatOptions.style = style;
  if (currency) {
    numberFormatOptions.style = 'currency';
    numberFormatOptions.currency = currency;
  }

  // create formatter
  const formatter = Intl.NumberFormat(DEFAULT_LOCALE, numberFormatOptions);

  let formatted = formatter.format(number);
  if (unit) {
    if (enablePluralUnits) {
      formatted += ` ${pluralize(number, unit, { enableCount: false })}`;
    } else {
      formatted += ` ${unit}`;
    }
  }
  return formatted;
};
