import { types } from '~/eds';

export const getSelectedUsers = (
  value: types.PilotId | types.PilotId[],
  previouslySelectedUsers: Record<types.PilotId, types.User>,
  searchableUsers: Record<types.PilotId, types.User>,
): Record<types.PilotId, types.User> => {
  const updatedSelectedUsers: Record<types.PilotId, types.User> = {};
  if (Array.isArray(value)) {
    value.forEach((userId: types.PilotId) => {
      const selectedUser =
        previouslySelectedUsers[userId] ?? searchableUsers[userId];
      if (selectedUser) {
        updatedSelectedUsers[userId] = selectedUser;
      }
    });
  } else {
    const selectedUser =
      previouslySelectedUsers[value] ?? searchableUsers[value];
    if (selectedUser) {
      updatedSelectedUsers[value] = selectedUser;
    }
  }
  return updatedSelectedUsers;
};

export const getUsers = (
  ids: number | number[],
  users: Record<number, types.User>,
): Record<types.PilotId, types.User> => {
  const usersArray = Array.isArray(ids)
    ? ids.map((id) => users[id])
    : [users[ids]];
  return usersArray.reduce((acc, user) => {
    return user ? { ...acc, [user.id]: user } : acc;
  }, {});
};
