import { useEffect } from 'react';

import { InteractionPosition } from '../types';
import { getInteractionPosition } from '../utils';
import { DATA_HIGHLIGHT_KEY, HIGHLIGHT_Y_OFFSET } from './constants';
import { HtmlTokenHighlight } from './types';
import { getTokenElement } from './utils';

export const useHighlightClick = ({
  containerRef,
  deps = [],
  highlights = [],
  onHighlightClick,
}: {
  containerRef?: React.RefObject<HTMLDivElement>;
  deps?: any[];
  highlights?: HtmlTokenHighlight[];
  onHighlightClick?: (
    highlightId: string,
    position: InteractionPosition,
  ) => void;
}) => {
  const enableHighlightClick = Boolean(onHighlightClick);

  // apply highlights to nodes with associated IDs.
  useEffect(() => {
    const handleHighlightClick = ({ clientX, clientY, target }: MouseEvent) => {
      if (target instanceof HTMLElement) {
        const highlightId = target.getAttribute(DATA_HIGHLIGHT_KEY);
        if (onHighlightClick && highlightId) {
          const position = getInteractionPosition({
            clientX,
            clientY,
            containerRef,
            initialOffsetY: HIGHLIGHT_Y_OFFSET,
          });
          onHighlightClick(highlightId, position);
        }
      }
    };

    highlights.forEach(({ id, className, key }) => {
      const element = getTokenElement(id);
      if (element) {
        element.classList.add(className);
        element.setAttribute(DATA_HIGHLIGHT_KEY, key);
        if (enableHighlightClick) {
          element.style.cursor = 'pointer';
          element.addEventListener('click', handleHighlightClick);
        }
      }
    });

    return () => {
      highlights.forEach(({ id, className }) => {
        const element = getTokenElement(id);
        if (element) {
          element.classList.remove(className);
          element.removeAttribute(DATA_HIGHLIGHT_KEY);
          element.style.cursor = 'auto';
          element.removeEventListener('click', handleHighlightClick);
        }
      });
    };
  }, [enableHighlightClick, highlights, ...deps]);
};
