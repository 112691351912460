export default `
<svg
  aria-hidden="true"
  aria-label="format-align-left"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M13.873 5.2666C14.2793 5.2666 14.623 5.61035 14.623 6.0166C14.623 6.4541 14.2793 6.7666 13.873 6.7666H6.37305C5.93555 6.7666 5.62305 6.4541 5.62305 6.0166C5.62305 5.61035 5.93555 5.2666 6.37305 5.2666H13.873ZM18.873 9.2666C19.2793 9.2666 19.623 9.61035 19.623 10.0166C19.623 10.4541 19.2793 10.7666 18.873 10.7666H6.37305C5.93555 10.7666 5.62305 10.4541 5.62305 10.0166C5.62305 9.61035 5.93555 9.2666 6.37305 9.2666H18.873ZM5.62305 14.0166C5.62305 13.6104 5.93555 13.2666 6.37305 13.2666H13.873C14.2793 13.2666 14.623 13.6104 14.623 14.0166C14.623 14.4541 14.2793 14.7666 13.873 14.7666H6.37305C5.93555 14.7666 5.62305 14.4541 5.62305 14.0166ZM18.873 17.2666C19.2793 17.2666 19.623 17.6104 19.623 18.0166C19.623 18.4541 19.2793 18.7666 18.873 18.7666H6.37305C5.93555 18.7666 5.62305 18.4541 5.62305 18.0166C5.62305 17.6104 5.93555 17.2666 6.37305 17.2666H18.873Z"
    fill="currentcolor"
  />
</svg>
`;
