import React, { useState } from 'react';
import uuid from 'uuid';

import { getCrmIntegrations } from '~/api/crms';
import { CrmType } from '~/enums';
import { useAsync } from '~/hooks';
import { CardList, LoadingContainer, Text } from '~/ui';

import CrmIntegrationCard from './CrmIntegrationCard';

function testHasIntegration(integration) {
  let hasIntegration;
  switch (integration.provider) {
    case CrmType.Salesforce:
      hasIntegration = true;
      break;
    default:
      hasIntegration = false;
  }
  return hasIntegration;
}

function CrmIntegrations() {
  const [crmIntegrations, setCrmIntegrations] = useState([]);
  const [refetchId, setRefetchId] = useState(uuid());

  const { isLoading } = useAsync(getCrmIntegrations, null, {
    condition: refetchId,
    deps: [refetchId],
    successHandler: (integrations) => {
      const integrationsWithPermissions = integrations.map((integration) => ({
        ...integration,
        disabled: !testHasIntegration(integration),
      }));
      setCrmIntegrations(integrationsWithPermissions);
    },
  });

  async function handleRefetch() {
    setRefetchId(uuid());
  }

  if (!crmIntegrations) {
    return (
      <Text variant="m-spaced">
        Unable to fetch third party integrations, please try again.
      </Text>
    );
  }

  return (
    <CardList title="CRM / Sales">
      <LoadingContainer isLoading={isLoading}>
        {crmIntegrations.map((integration) => (
          <CrmIntegrationCard
            key={integration.provider}
            disabled={!!integration && integration.disabled}
            crmIntegration={integration}
            crmType={integration.provider}
            isRefetching={isLoading}
            onRefetch={handleRefetch}
          />
        ))}
      </LoadingContainer>
    </CardList>
  );
}

export default CrmIntegrations;
