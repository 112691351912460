import { pilot } from '../../../services';

export const getDocumentVersions = (documentId, page, searchText) => {
  const params = { page, search_text: searchText };
  return pilot.get(`version/document/${documentId}/`, { params });
};

export const getDocumentVersionContent = (versionId) =>
  pilot.get(`version/${versionId}/content/`);

export const getDocumentVersionDOCX = (versionId) =>
  pilot.get(`/version/${versionId}/docx/`, { responseType: 'blob' });

export const getDocumentVersionOriginal = (versionId) =>
  pilot.get(`/version/${versionId}/original/`, { responseType: 'blob' });

export const updateDocumentVersionOrder = (orderData, documentId) =>
  pilot.put(`version/document/${documentId}/order/`, {
    version_ids: orderData,
  });

export const editDocumentVersion = (
  versionId,
  file_name,
  description,
  documentId,
) =>
  pilot.patch(`version/${versionId}/`, { file_name, description, documentId });

export const replaceDocumentVersion = (versionId, file, documentId) =>
  pilot.put(`version/${versionId}/replace/${documentId}/`, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const restoreDocumentVersion = (versionId, documentId) =>
  pilot.put(`version/${versionId}/restore/`, { handler_id: documentId });

export const deleteDocumentVersion = (versionId, documentId) => {
  const params = { handler_id: documentId };
  return pilot.remove(`version/${versionId}/`, { params });
};
