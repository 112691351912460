export default `
<svg
  aria-hidden="true"
  aria-label="user"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12 12C14.1875 12 16 10.2188 16 8C16 5.8125 14.1875 4 12 4C9.78125 4 8 5.8125 8 8C8 10.2188 9.78125 12 12 12ZM10.5625 13.5C7.46875 13.5 5 16 5 19.0938C5 19.5938 5.40625 20 5.90625 20H18.0625C18.5625 20 19 19.5938 19 19.0938C19 16 16.5 13.5 13.4062 13.5H10.5625Z"
    fill="currentcolor"
  />
</svg>
`;
