import React, { memo } from 'react';

import { ActionsMenu } from '../../../components';
import { MenuActions } from '../../../types';
import { State } from '../types';

interface Props {
  enableManageColumns: boolean;
  enablePageSizeSelect: boolean;
  pageSize: number;
  pageSizes: number[];
  state: State;
  onAddRemoveColumns: () => void;
  onReorderColumns: () => void;
  onUpdatePageSize: (updatedPageSize: number) => void;
  onLoadView?: (state: State) => void;
  onSaveView?: (state: State) => void;
}

export const SettingsMenu = memo(
  ({
    enableManageColumns = true,
    enablePageSizeSelect = true,
    pageSize,
    pageSizes,
    state,
    onAddRemoveColumns,
    onLoadView,
    onReorderColumns,
    onSaveView,
    onUpdatePageSize,
  }: Props) => {
    const viewActions = [];
    if (onSaveView) {
      viewActions.push({
        value: 'save-view',
        label: 'Save view',
        onClick: () => onSaveView(state),
      });
    }
    if (onLoadView) {
      viewActions.push({
        value: 'load-view',
        label: 'Load view',
        onClick: () => onLoadView(state),
      });
    }

    const groupedActions: MenuActions<string | number> = [
      ...(enableManageColumns
        ? [
            {
              label: '',
              options: [
                {
                  value: 'add-remove-columns',
                  label: 'Add or delete columns',
                  onClick: onAddRemoveColumns,
                },
                {
                  value: 'reorder-columns',
                  label: 'Reorder columns',
                  onClick: onReorderColumns,
                },
                ...viewActions,
              ],
            },
          ]
        : []),
      ...(enablePageSizeSelect
        ? [
            {
              label: 'Show up to',
              options: pageSizes.map((count) => {
                const isActive = pageSize === count;
                return {
                  ariaLabel: `${count} rows per page`,
                  isActive,
                  value: count,
                  label: `${count} rows`,
                  onClick: isActive ? undefined : () => onUpdatePageSize(count),
                };
              }),
            },
          ]
        : []),
    ];

    return (
      <ActionsMenu
        enablePortal
        icon="gear"
        actions={groupedActions}
        name={`table settings menu has ${pageSize} rows per page selected`}
        size="s"
        tooltip="Table settings"
      />
    );
  },
);
