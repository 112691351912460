import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ActionsMenu from '~/components/Shared/ActionsMenu';
import {
  ButtonAddSection,
  Card,
  EditableText,
  FlexLayout,
  Text,
  useModalSimple,
  useModalSortable,
} from '~/ui';
import { PHASE_TITLE_CHARACTER_LIMIT } from '~/ui/enums';
import { toSnakeTrimmed } from '~/utils/strings';

function Header({
  disabled,
  id,
  isTitleInvalid,
  rightContent,
  title,
  onEditTitle,
}) {
  const headerTitle = (
    <EditableText
      disabled={disabled}
      id={id}
      placeholder="Untitled Section"
      value={title}
      onChange={onEditTitle}
      error={
        isTitleInvalid
          ? `Your title may contain up to ${PHASE_TITLE_CHARACTER_LIMIT} characters.`
          : null
      }
    />
  );

  return (
    <Card.Header id={id} rightContent={rightContent} title={headerTitle} />
  );
}

function StageItems({
  disabled,
  items,
  invalidPhaseIds,
  newItemAction,
  removeModalTitle,
  sortModalTitle,
  onEditTitle,
  onRemove,
  onSort,
}) {
  const [selectedItem, setSelectedItem] = useState({});

  const [sortableListModal, showSortableListModal] = useModalSortable({
    confirm: (sortedItems) =>
      onSort(sortedItems.map((sortedItem) => sortedItem.value)),
    items: items.map((item) => ({ value: item.id, label: item.title })),
    text: 'Sort',
    title: sortModalTitle,
  });

  const [removeModal, showRemoveModal] = useModalSimple({
    confirm: () => onRemove(selectedItem.id),
    text: 'Remove',
    title: removeModalTitle,
    content: (
      <FlexLayout py={2}>
        <Text variant="xs-dense">
          Are you sure you want to remove{' '}
          <Text variant="xs-dense-bold">{selectedItem.title}</Text>?
        </Text>
      </FlexLayout>
    ),
  });

  return (
    <FlexLayout flexDirection="column" space={6}>
      {items.map((item) => {
        const { id, title, content } = item;
        const titleName = toSnakeTrimmed(title);

        const getMenuItems = () => {
          const menuItems = [];

          menuItems.push({
            content: sortModalTitle,
            disabled: items.length === 1 || disabled,
            onClick: () => showSortableListModal(),
          });

          menuItems.push({
            content: removeModalTitle,
            disabled: disabled,
            onClick: () => {
              setSelectedItem(item);
              showRemoveModal();
            },
          });

          return menuItems;
        };

        return (
          <Card
            key={id}
            header={
              <Header
                disabled={disabled}
                id={`workflow-form-section-name=${titleName}-title-edit`}
                rightContent={
                  disabled ? null : (
                    <ActionsMenu items={getMenuItems()} align="end" />
                  )
                }
                title={title}
                isTitleInvalid={invalidPhaseIds.some(
                  (invalidId) => invalidId === id,
                )}
                onEditTitle={(title) => onEditTitle({ id, title })}
              />
            }
            onToggleCollapse={() => {}}
          >
            {content}
          </Card>
        );
      })}
      <ButtonAddSection
        disabled={disabled}
        id={newItemAction.id}
        text={newItemAction.text}
        onClick={() => newItemAction.handler()}
      />
      {sortableListModal}
      {removeModal}
    </FlexLayout>
  );
}

StageItems.propTypes = {
  /** Array of item objects */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Item id */
      id: PropTypes.string,
      /** Item title text */
      title: PropTypes.string,
      /** Item content */
      content: PropTypes.node,
    }).isRequired,
  ),
  /** New item action object */
  newItemAction: PropTypes.shape({
    /** Action text */
    text: PropTypes.string,
    /** Action handler */
    handler: PropTypes.func,
  }),
  /** Title for remove item modal */
  removeModalTitle: PropTypes.string,
  /** Title for sort items modal */
  sortModalTitle: PropTypes.string,
  /** Handler for item title edit */
  onEditTitle: PropTypes.func,
  /** Handler for item removal */
  onRemove: PropTypes.func,
  /** Handler for items sort */
  onSort: PropTypes.func,
};

export default StageItems;
