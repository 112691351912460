import React from 'react';

import { Button } from '~/eds';
import { Box, Card, FlexLayout } from '~/ui';

import ItemPartySigner from './ItemPartySigner';

function PartySignerCard({
  parties,
  title,
  type,
  onAddParty,
  onAddSigner,
  onRemoveParty,
  onRemoveSigner,
}) {
  return (
    <Box sx={{ width: '400px' }}>
      <Card
        isFullHeight
        header={
          <Card.Header
            title={title}
            rightContent={
              <Button
                text="Add Party"
                variant="action"
                onClick={() => onAddParty({ type })}
              />
            }
          />
        }
      >
        {parties.map((party, partyIndex) => (
          <React.Fragment key={party.id}>
            {partyIndex > 0 && <Box my={4} sx={{ borderBottom: 'border' }} />}
            <FlexLayout flexDirection="column" space={1}>
              <ItemPartySigner
                name={party.name}
                onRemove={() => onRemoveParty({ partyId: party.id, type })}
              />
              <FlexLayout flexDirection="column" ml={6} space={5}>
                <FlexLayout flexDirection="column" space={1}>
                  {party.signers.map((signer) => (
                    <ItemPartySigner
                      key={signer.id}
                      name={signer.name}
                      onRemove={() =>
                        onRemoveSigner({ signerId: signer.id, type })
                      }
                    />
                  ))}
                </FlexLayout>
                <Button
                  text="Add Signers"
                  variant="action"
                  onClick={() => onAddSigner({ partyId: party.id, type })}
                />
              </FlexLayout>
            </FlexLayout>
          </React.Fragment>
        ))}
      </Card>
    </Box>
  );
}

export default PartySignerCard;
