import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormField,
  IconButton,
  Layout,
  Text,
  TextInput,
} from '~/eds';
import { FlagType, useFlag } from '~/flags';
import {
  CustomAttributes as CustomAttributesType,
  FormField as FormFieldType,
  Nullable,
} from '~/types';

type Props = {
  formField: FormFieldType<CustomAttributesType>;
  initialValue?: Record<string, string>;
  onExplain?: (fieldId: string) => void;
  onChange: (value: Record<string, string>) => void;
};

type CustomAttribute = {
  name: Nullable<string>;
  label: Nullable<string>;
};

export const CustomAttributes = ({
  formField,
  initialValue,
  onExplain,
  onChange,
}: Props) => {
  const [customAttributes, setCustomAttributes] = useState<CustomAttribute[]>(
    [],
  );
  const isPremiumAdministrationEnabled = useFlag(
    FlagType.PremiumAdministration,
  );

  const handleAddNewAttribute = () => {
    setCustomAttributes([...customAttributes, { name: null, label: null }]);
    onChange(
      customAttributes.reduce(
        (acc, { name, label }) => ({ ...acc, [name ?? '']: label }),
        {},
      ),
    );
  };

  const handleRemoveAttribute = (index: number) => {
    const newCustomAttributes = [...customAttributes];
    newCustomAttributes.splice(index, 1);
    setCustomAttributes(newCustomAttributes);
    onChange(
      newCustomAttributes.reduce(
        (acc, { name, label }) => ({ ...acc, [name ?? '']: label }),
        {},
      ),
    );
  };

  const handleSetName = (index: number, name: string) => {
    const newCustomAttributes = [...customAttributes];
    newCustomAttributes[index].name = name;
    setCustomAttributes(newCustomAttributes);
    onChange(
      newCustomAttributes.reduce(
        (acc, { name, label }) => ({ ...acc, [name ?? '']: label }),
        {},
      ),
    );
  };

  const handleSetLabel = (index: number, label: string) => {
    const newCustomAttributes = [...customAttributes];
    newCustomAttributes[index].label = label;
    setCustomAttributes(newCustomAttributes);
    onChange(
      newCustomAttributes.reduce(
        (acc, { name, label }) => ({ ...acc, [name ?? '']: label }),
        {},
      ),
    );
  };

  useEffect(() => {
    if (initialValue && Object.keys(initialValue).length > 0) {
      setCustomAttributes(
        Object.entries(initialValue).map(([name, label]) => ({ name, label })),
      );
    }
  }, [initialValue]);

  return (
    <Layout direction="column" minW="input.min-width" spacing={2} w="100%">
      <Layout direction="column">
        <Layout>
          <Text variant="section">{formField.title}</Text>
          {onExplain && (
            <Button
              text="Explain this"
              variant="action"
              onClick={() => onExplain(formField.fieldName)}
            />
          )}
        </Layout>
        <Text preset="description">{formField.description}</Text>
      </Layout>
      <Layout direction="column" spacing={4}>
        {customAttributes.map((customAttribute, i) => (
          <Layout key={`custom_attribute_${i}`}>
            <Layout flex="1" mr={4}>
              <FormField
                input={TextInput}
                placeholder="Text or URL"
                label={i === 0 ? 'IDP attribute name' : ''}
                description={i === 0 ? 'Copy and paste from your IDP' : ''}
                name="customAttributeName"
                value={customAttribute.name}
                disabled={!isPremiumAdministrationEnabled}
                tooltip={
                  isPremiumAdministrationEnabled
                    ? undefined
                    : 'Available with the Advanced Administration add-on.'
                }
                onChange={(name) => handleSetName(i, name as string)}
                error={
                  customAttribute.name === ''
                    ? 'This field is required'
                    : undefined
                }
              />
            </Layout>

            <Layout flex="1" mr={4}>
              <FormField
                input={TextInput}
                placeholder="Eg: User ID, Manager"
                label={i === 0 ? 'Evisort display name' : ''}
                description={
                  i === 0 ? 'This is used for display purposes only' : ''
                }
                name="customAttributeLabel"
                value={customAttribute.label}
                disabled={!isPremiumAdministrationEnabled}
                tooltip={
                  isPremiumAdministrationEnabled
                    ? undefined
                    : 'Available with the Advanced Administration add-on.'
                }
                onChange={(label) => handleSetLabel(i, label as string)}
                error={
                  customAttribute.label === ''
                    ? 'This field is required'
                    : undefined
                }
              />
            </Layout>
            {isPremiumAdministrationEnabled && (
              <Box mt={i === 0 ? 12 : 1}>
                <IconButton
                  text="Remove"
                  icon="trash"
                  onClick={() => handleRemoveAttribute(i)}
                />
              </Box>
            )}
          </Layout>
        ))}
        <Layout>
          <Button
            disabled={!isPremiumAdministrationEnabled}
            text="Add Custom Attribute"
            tooltip={
              isPremiumAdministrationEnabled
                ? undefined
                : 'Available with the Advanced Administration add-on.'
            }
            variant="secondary"
            onClick={handleAddNewAttribute}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};
