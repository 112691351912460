import PropTypes from 'prop-types';
import React from 'react';

const QueueIcon = (props) => {
  return (
    <svg fill="#000" width={props.size} height={props.size} viewBox="0 0 50 42">
      <path
        d="M0,35.51,16.81,46.19l33.05-4.42L50,33.51l-4.22.42L45.73,11.6,29.25,3.33l-.05,2.6,12.3,6.23.1,20.24,2.69,1.76-4.64.56-.19-22.24L23,4V6.77L35.38,13V33.14L38,35l-4.69.55V13.22L16.71,4.73V7.51l12.35,6.32L29.16,34l2.73,1.81-4.82.55L27,14.06,10.45,5.56l0,2.7,12.44,6.36L23,34.67l2.51,1.86L20.84,37l0-22.24L4.32,6.35,4.27,27.29l15.32,10-3,.23L.05,27Z"
        transform="translate(0 -3.33)"
        fillRule="evenodd"
      />
    </svg>
  );
};

QueueIcon.defaultProps = {
  size: '20px',
};

QueueIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default QueueIcon;
