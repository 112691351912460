import { FieldModelConfig } from '../types';

export const compareConfig = (
  config1: FieldModelConfig,
  config2: FieldModelConfig,
) => {
  const hasFiltersChanged = config1.filters !== config2.filters;
  const hasInstructionsChanged = config1.instructions !== config2.instructions;

  return { hasFiltersChanged, hasInstructionsChanged };
};
