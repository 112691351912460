import React from 'react';

import { Box, Layout, MenuOption, types } from '~/eds';
import { useRouting } from '~/routing';
import { Uuid } from '~/types';

import { Clause } from '../../../../types';
import { formatUrl } from '../../../../utils';

interface Props {
  clause: Clause;
}

export const ClauseSummary = ({ clause }: Props) => {
  const { navigate } = useRouting();

  const options: types.Option<Uuid>[] = clause.variations.map((variation) => ({
    label: variation.name,
    leftContent: <Box pl={8} />, // left padding to align with accordion header text
    pathname: formatUrl(variation.tab, variation.clauseId, variation.id),
    description: `${variation.variationsCount} variations across ${variation.contractsCount} contracts`,
    value: variation.id,
  }));

  const handleOptionClick = (pathname: string) => {
    navigate(pathname);
  };

  return (
    <Layout direction="column">
      {options.map((option) => (
        <MenuOption
          key={option.value}
          option={option}
          onClick={() => handleOptionClick(option.pathname!)}
        />
      ))}
    </Layout>
  );
};
