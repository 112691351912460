import { DataFieldType } from '~/enums';
import { pilot } from '~/services';
import { FolderTree, PilotId } from '~/types';

export type SearchableFieldId = string;

export type ClauseSuggestions = {
  suggestions: string[];
};

export type PilotField = {
  // values - deprecated, field values should be retrieved from the getFieldValues api endpoint
  id: SearchableFieldId | PilotId;
  type: DataFieldType;
  chained_fields: SearchableFieldId[];
  name: string;
  label: string;
  is_pinned: boolean;
  groupId?: string;
  edit_type?: DataFieldType;
  help_text?: string;
};
export type PilotFieldSection = {
  title: string;
  fields: PilotField[];
};
export type AlertSectionsResponse = {
  sections: PilotFieldSection[];
};

export const getAlertSections = (): Promise<AlertSectionsResponse> =>
  pilot.get('/alert/sections/');
export const getFolderTree = (): Promise<FolderTree> => pilot.get('/path/');
export const getClauseSuggestions = (): Promise<ClauseSuggestions> =>
  pilot.get('/search/suggestion/provision-type/');
