import { useEffect } from 'react';

import { getTokenElement } from './utils';

/**
 * Scroll to a specific highlight element. Updates if `invalidate`d.
 **/
export const useScrollToHighlight = ({
  activeElementId,
  deps = [],
}: {
  activeElementId?: string;
  deps?: any[];
}) => {
  useEffect(() => {
    if (activeElementId) {
      const element = getTokenElement(activeElementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [activeElementId, ...deps]);
};
