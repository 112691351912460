import React from 'react';

import AuthenticationStore from '~/auth';
import { Layout, Logo, Text } from '~/eds';

const AS = AuthenticationStore();

function Page() {
  const isLoggedIn = AS.isLoggedIn();

  return (
    <Layout
      as="header"
      direction="column"
      spacing={16}
      pt={isLoggedIn ? 16 : 0}
    >
      {isLoggedIn ? null : (
        <Layout
          align="center"
          bg="inverse.background"
          h="header.height"
          justify="space-between"
          px="6"
        >
          <a href="/" title="Evisort login page">
            <Logo theme="dark" />
          </a>
        </Layout>
      )}
      <Layout align="center" direction="column">
        <Text as="h1" variant="subtitle">
          Ticket approved
        </Text>
      </Layout>
    </Layout>
  );
}

export default Page;
