import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { ticketDocumentVersionsSet, ticketStagesSet } from '~/actions';
import { getTicketDocumentVersions, uploadSignedDocument } from '~/api';
import { documentTypeIcon } from '~/components/DocumentsPage/Document.utils';
import EcCheckbox from '~/components/Shared/EcCheckbox';
import { Button, IconButton, User, useToast } from '~/eds';
import { FileExtensionType, TicketStageType, TicketStatusType } from '~/enums';
import { useCurrentUser } from '~/hooks';
import { api, TAGS } from '~/redux';
import { TagType } from '~/redux/api/TagType';
import { Box, FileInput, FlexLayout, Modal, Switch, Text } from '~/ui';
import { getFileExtension } from '~/utils/files';
import {
  shouldFillFormQuestionsInSignStage,
  testHasInvalidSigners,
  testIsCcSigner,
} from '~/utils/ticket';
import { testIsEverestAdmin } from '~/utils/user';
import { getAcceptedFiles } from '~/utils/workflow';

const defaultFileExtensions = [FileExtensionType.Docx, FileExtensionType.Pdf];

function getSignedUserIds(approvals) {
  return Object.values(approvals)
    .filter(({ status }) => status === TicketStatusType.Signed)
    .map(({ externalUser, userId }) => (!!userId ? userId : externalUser.id));
}

function UploadSignedButton({ approvals, phases, selectedSignerIds, ticket }) {
  const { toast } = useToast();

  const signersList = useMemo(() => {
    return Object.values(approvals)
      .filter(
        (approval) => phases[approval.phaseId].stage === TicketStageType.Sign,
      )
      .filter((approval) => !testIsCcSigner(approval));
  }, [approvals]);

  const [
    resolveUsers,
    { data: users = {}, isSuccess: hasResolvedUsers },
  ] = api.endpoints.resolveUsers.useLazyQuery();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const signers = useMemo(() => {
    const userIds = signersList
      .filter((approval) => approval.userId)
      .map((approval) => approval.userId);
    const hasUnresolvedUsers = userIds.some((id) => !users[id]);
    return !hasUnresolvedUsers ? userIds.map((userId) => users[userId]) : [];
  }, [signersList, users]);

  const externalSigners = useMemo(() =>
    signersList
      .filter((approval) => approval.externalUser)
      .map((approval) => approval.externalUser, [signersList]),
  );
  const [selectedDocument, setSelectedDocument] = useState();
  const [selectedSigners, setSelectedSigners] = useState(selectedSignerIds);
  const [isKeepingUploadedFilename, setIsKeepingUploadedFilename] = useState(
    false,
  );
  const hasDeletedUsers = signers.some((signer) => signer.isDeleted);

  useEffect(() => {
    if (signersList && signersList.length) {
      const userIds = signersList
        .filter((approval) => approval.userId)
        .map((approval) => approval.userId);
      if (userIds.length) {
        resolveUsers({
          ids: userIds,
          params: { clientId: currentUser.client, includeDeleted: true },
        });
      }
    }
  }, [signersList]);

  if (
    ticket.stage !== TicketStageType.Sign ||
    ticket.stages.sign?.status === TicketStatusType.Signed
  ) {
    return null;
  }

  function handleOnAttachFiles(files) {
    setSelectedDocument(files[0]);
  }

  function handleOnRemoveFileClick() {
    setSelectedDocument(null);
  }

  function handleOnSignerCheckboxClick(userId) {
    let newSelectedUsers = [...selectedSigners];

    if (selectedSigners.includes(userId)) {
      newSelectedUsers = newSelectedUsers.filter((user) => user !== userId);
    } else {
      newSelectedUsers.push(userId);
    }

    setSelectedSigners(newSelectedUsers);
  }

  const actionButton = {
    disabled: hasDeletedUsers || !selectedDocument || !selectedSigners.length,
    tooltip:
      hasDeletedUsers && 'You have Deactivated users assigned as signers',
    text: 'Upload',
    variant: 'primary',
    errorHandler: (error) => {
      const additionalErrorMessage = error?.response?.data?.data;
      toast({
        message: `Unable to upload document, ${
          additionalErrorMessage ? additionalErrorMessage : 'please try again'
        }.`,
        status: 'danger',
      });
    },
    promise: async function () {
      const res = await uploadSignedDocument({
        ticketId: ticket.id,
        file: selectedDocument,
        signerIds: selectedSigners,
        isKeepingUploadedFilename: isKeepingUploadedFilename,
      });
      const esigErrorMessage = res?.esigErrorMessage;
      esigErrorMessage
        ? toast({
            message: esigErrorMessage,
            status: 'info',
          })
        : toast({
            message: 'Document has been successfully uploaded.',
            status: 'success',
          });
      const documentVersions = await getTicketDocumentVersions(
        ticket.document.id,
      );
      dispatch(ticketDocumentVersionsSet(documentVersions));
      dispatch(
        api.util.invalidateTags([
          { type: TagType.Ticket, id: 'CURRENT_VERSION' },
          TAGS.TAG_BY_TICKET_SUMMARY,
          TAGS.TAG_CURRENT_STAGE,
        ]),
      );
    },
  };

  function renderUser(user, label = null) {
    const signerUserStyle = {
      '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      },
    };

    const userInfo =
      label === 'COUNTERPARTY'
        ? { firstName: user.name, email: user.email, id: user.id }
        : {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            id: user.id,
          };

    return (
      <FlexLayout
        key={user.id}
        alignItems="center"
        py={3}
        space={4}
        sx={signerUserStyle}
      >
        <EcCheckbox
          checked={selectedSigners.includes(user.id)}
          onClick={() => handleOnSignerCheckboxClick(user.id)}
        />
        <User
          mode="avatar-name"
          user={userInfo}
          options={{
            isCurrentUser: user.id === currentUser.id,
            isDeleted: user.isDeleted,
          }}
        />
      </FlexLayout>
    );
  }

  function renderUsersField() {
    return (
      <FlexLayout flexDirection="column" space={2}>
        <Text variant="xs-dense-caps" color="gray-900">
          SIGNERS
        </Text>
        <Text variant="xs-dense" color="gray-600">
          Check all the signers who have signed this document.
        </Text>
        <Box
          px={4}
          py={1}
          sx={{ borderRadius: 'm', boxShadow: 'depth-2', border: 'border' }}
        >
          {signers.map((user) => renderUser(user))}
          {externalSigners.map((user) => renderUser(user, 'COUNTERPARTY'))}
        </Box>
      </FlexLayout>
    );
  }

  function renderFileField() {
    return (
      <FlexLayout alignItems="flex-start" flexDirection="column" space={2}>
        <Text color="gray-900" variant="xs-dense-caps">
          DOCUMENT
        </Text>
        {selectedDocument ? (
          <FlexLayout
            alignItems="center"
            bg="black-alpha-04"
            p={2}
            space={3}
            sx={{ borderRadius: 'm' }}
          >
            {documentTypeIcon(getFileExtension(selectedDocument.name))}
            <Text color="gray-900" variant="s-spaced">
              {selectedDocument.name}
            </Text>
            <IconButton
              icon="x"
              tooltip="remove"
              onClick={handleOnRemoveFileClick}
            />
          </FlexLayout>
        ) : (
          <Box id="add_document">
            <FileInput
              acceptedFiles={getAcceptedFiles(
                ticket.workflow,
                defaultFileExtensions,
                defaultFileExtensions,
              )}
              enableDropzone={false}
              label="Attach File"
              onChange={handleOnAttachFiles}
            />
          </Box>
        )}
      </FlexLayout>
    );
  }

  function renderKeepFilenameToggle() {
    return (
      testIsEverestAdmin(currentUser) && (
        <FlexLayout mt={5} space={3}>
          <Switch
            id="workflow--ticket-upload-signed-version-keep-filename"
            size="s"
            value={isKeepingUploadedFilename}
            onChange={(updatedValue) =>
              setIsKeepingUploadedFilename(updatedValue)
            }
          />
          <Text color="gray-900" variant="body2">
            Keep file name after upload
          </Text>
        </FlexLayout>
      )
    );
  }

  function renderFileModal() {
    return (
      <Modal
        actionButton={actionButton}
        disableCancelButton
        title="Upload a Signed Document"
        visible={showModal}
        onHide={() => {
          setSelectedDocument();
          setSelectedSigners(getSignedUserIds(ticket.approvals));
          setShowModal(false);
        }}
      >
        <FlexLayout flexDirection="column" space={8}>
          {renderFileField()}
          {renderUsersField()}
          {renderKeepFilenameToggle()}
        </FlexLayout>
      </Modal>
    );
  }

  if (shouldFillFormQuestionsInSignStage(ticket, users)) {
    return null;
  }

  return (
    <>
      <Button
        disabled={testHasInvalidSigners(ticket, users)}
        isLoading={signers.length && !hasResolvedUsers}
        onClick={() => setShowModal(true)}
        text="Upload Signed"
        variant="primary"
      />
      {renderFileModal()}
    </>
  );
}

const mapStateToProps = ({ ticket }) => ({
  approvals: ticket.approvals,
  phases: ticket.phases,
  selectedSignerIds: getSignedUserIds(ticket.approvals),
  ticket,
});

export default connect(mapStateToProps, { ticketStagesSet })(
  UploadSignedButton,
);
