export enum FileExtensionType {
  Bmp = '.bmp',
  Csv = '.csv',
  Doc = '.doc',
  Docm = '.docm',
  Docx = '.docx',
  Dot = '.dot',
  Dotm = '.dotm',
  Dotx = '.dotx',
  Gif = '.gif',
  Heic = '.heic',
  Htm = '.htm',
  Html = '.html',
  Jpeg = '.jpeg',
  Jpg = '.jpg',
  Msg = '.msg',
  Pdf = '.pdf',
  Png = '.png',
  Pot = '.pot',
  Potx = '.potx',
  Pps = '.pps',
  Ppt = '.ppt',
  Pptm = '.pptm',
  Pptx = '.pptx',
  Rtf = '.rtf',
  Tif = '.tif',
  Tiff = '.tiff',
  Txt = '.txt',
  Webp = '.webp',
  Wopitest = '.wopitest',
  Wopitestx = '.wopitestx',
  Wpd = '.wpd',
  Xhtml = '.xhtml',
  Xls = '.xls',
  Xlsm = '.xlsm',
  Xlsx = '.xlsx',
  Xps = '.xps',
}
