import { everest } from '~/services';
import { getMentions } from '~/utils/comments';

export async function getComments({ entityId, entityType }) {
  const params = { entityId, entityType };
  return await everest.get('/comments/', { params });
}

export async function getComment({ id }) {
  return await everest.get(`/comments/${id}/`);
}

export async function addComment({
  content,
  entityId,
  entityType,
  addParticipants = false,
}) {
  const postData = {
    content,
    entityId,
    entityType,
    mentions: getMentions(content),
    addParticipants,
  };
  const newComment = await everest.post('/comments/', postData);
  return getComment(newComment);
}

export async function updateComment({ id, content, entityId, entityType }) {
  const data = {
    content,
    entityId,
    entityType,
    mentions: getMentions(content),
  };
  return everest.patch(`/comments/${id}/`, data);
}

export async function deleteComment({ id }) {
  return await everest.remove(`/comments/${id}/`);
}
