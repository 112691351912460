import { useMemo } from 'react';

import { aribaPasswordAdapterChoices } from '~/api/evisync';
import { SYNC_SYMBOL } from '~/components/Admin/IntegrationPage/constants';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { CopyButton, Layout, Text } from '~/eds';
import { AribaSyncType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useClientId } from '~/hooks';
import { api } from '~/redux';

import { InfoDataType, SyncPairConfigInfo } from './types';

export const useConfigurationInfos = ({
  id: syncPairId,
  providerOptions,
  createdBy,
  evisortFolderPath,
  allowedSyncFileTypes,
  dateCreated,
  dateUpdated,
  provider,
}: SyncPairConfigInfo) => {
  const clientId = useClientId();
  const enableFieldMappingOneTimeImport = useFlag(
    FlagType.FieldMappingOneTimeImport,
  );
  const enableCsvFieldMapping = useFlag(FlagType.AribaFieldMappingCsvSupport);

  interface aribaField {
    fieldName?: string;
  }

  interface evisortField {
    label?: string;
  }

  interface mappingField {
    sync: AribaSyncType;
    meta: {
      aribaField?: aribaField;
      evisortField?: evisortField;
    };
  }

  const { data: userCreate } = api.endpoints.resolveUser.useQuery({
    id: createdBy,
    params: { clientId, includeDeleted: true },
  });

  const { data: userUpdate } = api.endpoints.resolveUser.useQuery({
    id: providerOptions.updatedBy ?? createdBy,
    params: { clientId, includeDeleted: true },
  });

  const userCreateInfo = useMemo(() => {
    if (!userCreate) {
      return {
        firstName: '',
        lastName: '',
        email: '',
        id: '',
      };
    }

    const { firstName, lastName, email, id } = userCreate;
    return { firstName, lastName, email, id };
  }, [userCreate]);

  const userUpdateInfo = useMemo(() => {
    if (!userUpdate) {
      return userCreateInfo;
    }
    const { firstName, lastName, email, id } = userUpdate;
    return { firstName, lastName, email, id };
  }, [userUpdate]);

  const enableAribaOnetimeSyncInfo = providerOptions.syncType === 'one_time';

  const { mapping, importFile } = providerOptions;

  const FieldMapping = () => {
    if (mapping?.length === 0) {
      return <Text>No field mappings</Text>;
    }
    return (
      <Layout direction="column" preset="m">
        {mapping?.map((field: mappingField) => {
          const syncDirection = SYNC_SYMBOL[field.sync];

          const { aribaField = {}, evisortField = {} } = field.meta || {};

          const fieldName = aribaField?.fieldName ?? 'No field available';
          const label = evisortField?.label ?? 'No field available';

          return (
            <Layout align="space-between">
              <Text>{[label, syncDirection, fieldName].join(' ')}</Text>
            </Layout>
          );
        })}
      </Layout>
    );
  };

  const chips = allowedSyncFileTypes.map((fileType: string) => ({
    text: fileType,
  }));

  const oneTimeAribaSync = [
    {
      title: 'Ariba Documents',
      type: InfoDataType.FILESYSTEM,
      props: {
        file: {
          name: importFile ?? 'no available files',
        },
      },
    },
  ];

  const oneTimeAndCsvSync = [
    {
      title: 'Ariba Documents',
      type: InfoDataType.FILESYSTEM,
      props: {
        file: {
          name: importFile ?? 'no available files',
        },
      },
    },
    {
      title: 'Date Created',
      type: InfoDataType.USER_ACTIVITY,
      props: {
        activity: 'Date Created',
        date: new Date(dateCreated),
        user: {
          id: userCreateInfo.id,
          firstName: userCreateInfo.firstName,
          lastName: userCreateInfo.lastName,
          email: userCreateInfo.email,
        },
        format: 'full',
      },
    },
    {
      title: 'Date Updated',
      type: InfoDataType.USER_ACTIVITY,
      props: {
        activity: 'Date Updated',
        date: new Date(dateUpdated),
        user: {
          id: userUpdateInfo.id,
          firstName: userUpdateInfo.firstName,
          lastName: userUpdateInfo.lastName,
          email: userUpdateInfo.email,
        },
        format: 'full',
      },
    },
  ];

  const continuousAribaSync = [
    {
      title: 'File Types',
      type: InfoDataType.CHIPS,
      props: {
        chips,
      },
    },
    {
      title: 'Date Created',
      type: InfoDataType.USER_ACTIVITY,
      props: {
        activity: 'Date Created',
        date: new Date(dateCreated),
        user: {
          id: userCreateInfo.id,
          firstName: userCreateInfo.firstName,
          lastName: userCreateInfo.lastName,
          email: userCreateInfo.email,
        },
      },
    },
    {
      title: 'Date Updated',
      type: InfoDataType.USER_ACTIVITY,
      props: {
        activity: 'Date Updated',
        date: new Date(dateUpdated),
        user: {
          id: userUpdateInfo.id,
          firstName: userUpdateInfo.firstName,
          lastName: userUpdateInfo.lastName,
          email: userUpdateInfo.email,
        },
      },
    },
  ];

  const extraContextNarrowedType = providerOptions.extraContext
    ? (providerOptions.extraContext as {
        taskDiscoveryDetails?: {
          enabled: boolean;
          approver?: {
            id: string;
            passwordAdapter: string;
          };
        };
      })
    : { taskDiscoveryDetails: { enabled: false } };

  const taskDiscoveryInfo = [
    {
      title: 'Use Ariba Task for Faster Data Sync',
      type: InfoDataType.TEXT,
      props: {
        text: extraContextNarrowedType.taskDiscoveryDetails?.enabled
          ? 'Yes'
          : 'No',
      },
    },
    {
      title: 'Ariba Task User',
      type: InfoDataType.TEXT,
      props: {
        text:
          extraContextNarrowedType.taskDiscoveryDetails?.approver?.id ?? '--',
      },
    },
    {
      title: 'Ariba Task User Authentication Method',
      type: InfoDataType.TEXT,
      props: {
        text:
          aribaPasswordAdapterChoices
            .filter(
              (choice) =>
                choice.value ===
                extraContextNarrowedType.taskDiscoveryDetails?.approver
                  ?.passwordAdapter,
            )
            .map((choice) => choice.label)[0] ?? '--',
      },
    },
  ];

  const enableFieldMapping =
    !enableAribaOnetimeSyncInfo ||
    (enableAribaOnetimeSyncInfo && enableFieldMappingOneTimeImport)
      ? [
          {
            title: 'List of Field Mappings (Evisort - Ariba)',
            type: InfoDataType.CONTENT,
            props: {
              children: <FieldMapping />,
            },
          },
        ]
      : [];

  const infos = useMemo(() => {
    return [
      {
        title: 'Provider',
        type: InfoDataType.CONTENT,
        props: {
          children: (
            <Layout spacing={2} align="center">
              <ProviderLogo provider={provider} size="s" />
              <Text>{provider}</Text>
            </Layout>
          ),
        },
      },
      {
        title: 'Realm ID',
        type: InfoDataType.TEXT,
        props: {
          text: providerOptions.realm,
        },
      },
      {
        title: 'Data Center',
        type: InfoDataType.TEXT,
        props: {
          text: providerOptions.location,
        },
      },
      {
        title: 'Evisort Folder',
        type: InfoDataType.FILESYSTEM,
        props: {
          folder: { name: evisortFolderPath, path: evisortFolderPath },
        },
      },
      {
        title: 'Sync Pair ID',
        type: InfoDataType.CONTENT,
        props: {
          children: (
            <Layout spacing={2} align="center">
              <Text>{syncPairId}</Text>
              <CopyButton copyText={syncPairId} size="s" />
            </Layout>
          ),
        },
      },
      ...(enableAribaOnetimeSyncInfo
        ? enableCsvFieldMapping
          ? oneTimeAndCsvSync
          : oneTimeAribaSync
        : continuousAribaSync),
      ...taskDiscoveryInfo,
      ...enableFieldMapping,
    ];
  }, [
    providerOptions,
    createdBy,
    evisortFolderPath,
    allowedSyncFileTypes,
    dateCreated,
    dateUpdated,
    userCreateInfo,
    userUpdateInfo,
  ]);

  return infos;
};
