export default `
<svg
  aria-hidden="true"
  aria-label="inbox"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M19.875 13.5312L17.9688 5.78125C17.8438 5.3125 17.4375 5 17 5H7C6.53125 5 6.125 5.3125 6 5.75L4.09375 13.5312C4.03125 13.8438 4 14.1875 4 14.5V17C4 18.125 4.875 19 6 19H18C19.0938 19 20 18.125 20 17V14.5C20 14.1875 19.9375 13.8438 19.875 13.5312ZM7.375 6.5H16.5938L18.1875 13H15.5C15.1875 13 14.9375 13.1875 14.8125 13.4375L14.0312 15H9.9375L9.15625 13.4375C9.03125 13.1875 8.78125 13 8.5 13H5.78125L7.375 6.5ZM18.5 17C18.5 17.2812 18.25 17.5 18 17.5H6C5.71875 17.5 5.5 17.2812 5.5 17V14.5H8.03125L8.8125 16.0938C8.9375 16.3438 9.1875 16.5 9.5 16.5H14.5C14.7812 16.5 15.0312 16.3438 15.1562 16.0938L15.9375 14.5H18.5V17Z"
    fill="currentcolor"
  />
</svg>
`;
