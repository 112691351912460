import React from 'react';
import { connect } from 'react-redux';

import { Button } from '~/eds';
import { Box, FlexLayout } from '~/ui';

import FieldAssignmentMenu from '../FieldAssignmentMenu';
import ConditionalTextFieldSection from './ConditionalTextFieldSection';
import ContractFieldSection from './ContractFieldSection';
import PartySignerFieldSection from './PartySignerFieldSection';
import UnassignedFieldSection from './UnassignedFieldSection';

function Fields({
  // connected
  disabled,
}) {
  return (
    <FlexLayout flexDirection="column" sx={{ minHeight: 0 }}>
      {disabled ? null : (
        <Box px={10} py={3} sx={{ borderBottom: 'border' }}>
          <FieldAssignmentMenu
            trigger={
              <Button
                id="workflow-sidebar--add-field-button"
                isFullWidth
                text="Add Field"
                variant="secondary"
              />
            }
          />
        </Box>
      )}
      <FlexLayout flexDirection="column" sx={{ overflow: 'auto' }}>
        <UnassignedFieldSection />
        <ContractFieldSection />
        <PartySignerFieldSection />
        <ConditionalTextFieldSection />
      </FlexLayout>
    </FlexLayout>
  );
}

function mapStateToProps({ builder }) {
  return {
    disabled: !builder.shouldEnableAllComponents,
  };
}

export default connect(mapStateToProps)(Fields);
