import { debounce } from 'lodash';
import { useCallback } from 'react';

import { SEARCH_DEBOUNCE_MS } from '~/constants/debounces';
import { Filter } from '~/evifields';
import { api } from '~/redux';
import { Filters, PilotId } from '~/types';

export const getRolesFilterRendererAndSerializer = ({
  clientId,
}: {
  clientId: PilotId;
}) => {
  const [searchRoles, result] = api.endpoints.getRolesSimple.useLazyQuery();
  const { data, error, isFetching } = result;

  const AsyncRolesOptionsContainer: Filters.FieldRendererProps = ({
    Component,
    innerProps,
  }) => {
    const debouncedSearchRoles = useCallback(
      debounce(searchRoles, SEARCH_DEBOUNCE_MS),
      [searchRoles],
    );
    const fetchedRoles = data?.results ?? [];
    const options = fetchedRoles.map((role) => ({
      value: `${role.id}`,
      label: role.name,
    }));
    const asyncOptions = { data: options, error, isFetching };
    const fetchOptions = (query = '') => {
      debouncedSearchRoles(
        { name: query, client_id: clientId, page: 1, pageSize: 10 },
        true,
      );
    };

    const asyncProps = {
      asyncOptions,
      onMount: fetchOptions,
      onQuery: fetchOptions,
    };

    return <Component asyncProps={asyncProps} innerProps={innerProps} />;
  };

  return {
    render: AsyncRolesOptionsContainer,
    serialize: (filter: Filter) => {
      const role = data?.results.find(
        (dep) => `${dep.id}` === filter.values[0],
      );
      return role?.name ?? '';
    },
  };
};
