import { useEffect, useMemo, useState } from 'react';

import { EntityVisibilityType } from '~/components/Shared/EntityVisibilitySelect';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import {
  FilterInstance,
  TableToggleFilter,
} from '~/components/Shared/TableToggleFilter';
import { User } from '~/components/Shared/User';
import { PAGE_SIZE } from '~/constants/page';
import {
  ContentContainer,
  Markdown,
  PageLayout,
  types,
  useModal,
  useToast,
} from '~/eds';
import { TableContextType, TableViewContextType } from '~/enums';
import { useCurrentUser, useTableSettings } from '~/hooks';
import { api } from '~/redux';
import { useRouting } from '~/routing';
import { ColumnSortOrder } from '~/types';
import { testIsAdmin } from '~/utils/user';

import { SaveEditEntityModal } from '../../SaveEditEntityModal';
import { EntityVisibilityQueryParam, SavedSearch } from '../../types';
import { convertQueryV2ToQueryV3 } from '../../utils/convertQueryV2ToQueryV3';
import { testIsQueryV2, testIsQueryV3 } from '../../utils/queryUtils';

const filterOptions: FilterInstance<EntityVisibilityQueryParam>[] = [
  { text: 'My Saved Searches', value: 'mine' },
  { text: 'Shared with me', value: 'shared' },
];
export const SavedSearchesList = () => {
  const currentUser = useCurrentUser();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState<ColumnSortOrder | undefined>();
  const [typesParam, setTypesParam] = useState<EntityVisibilityQueryParam[]>(
    [],
  );
  const [activeItem, setActiveItem] = useState<types.Nullable<SavedSearch>>(
    null,
  );
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const { toast } = useToast();
  const { navigate } = useRouting();
  //RTKQ
  const {
    data,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = api.endpoints.getSearchV3SavedSearches.useQuery({
    page,
    pageSize,
    sort: sortBy,
    types: typesParam,
  });
  const [
    deleteSavedSearch,
  ] = api.endpoints.deleteSearchV3SavedSearch.useMutation();
  const [deleteV2SavedSearch] = api.endpoints.deleteSavedSearch.useMutation();
  const [saveSavedSearch] = api.endpoints.postSavedSearch.useMutation();
  const [putSavedSearch] = api.endpoints.putSavedSearch.useMutation();

  const {
    data: currentTableViewSet,
    isLoading: isLoadingCurrentTableViewSet,
  } = api.endpoints.getDefaultTableView.useQuery({
    context: TableViewContextType.SEARCH,
  });

  const resultsTable: string[] = useMemo(() => {
    return currentTableViewSet?.map((column) => `${column.field_id}`) ?? [];
  }, [currentTableViewSet]);

  useEffect(() => {
    if (isError) {
      toast({
        status: 'danger',
        message: 'An error occured while fetching saved searches.',
      });
    }
  }, [isError]);

  const [deleteModal, showDeleteModal, hideDeleteModal] = useModal({
    title: 'Delete Saved Search',
    children: (
      <Markdown
        text={`Are you sure you want to delete ***${activeItem?.name}*** saved search?`}
      />
    ),
    primaryAction: {
      onClick: async () => {
        try {
          if (activeItem) {
            if (activeItem.version === 3) {
              await deleteSavedSearch({ id: activeItem.id }).unwrap();
            } else {
              await deleteV2SavedSearch(activeItem.id as number).unwrap();
            }
            toast({
              status: 'success',
              message: 'Saved Search successfully deleted.',
            });
          }
        } catch {
          toast({
            status: 'danger',
            message: 'An error occured while deleting the saved search',
          });
        } finally {
          hideDeleteModal();
        }
      },
      variant: 'danger',
      text: 'DELETE',
    },
  });

  const loadingContent = {
    isLoading: isLoading || isLoadingCurrentTableViewSet,
  };
  const placeholderContent = isError
    ? {
        title: 'An error occured while fetching saved searches.',
        image: 'error-page-error',
      }
    : data?.results.length === 0
    ? {
        title: 'No saved searches found',
      }
    : undefined;

  const {
    tableSettings = {
      pageSize: PAGE_SIZE,
      columnWidths: {},
    },
  } = useTableSettings(TableContextType.SavedSearches);

  const handleClickSearchV2 = async (savedSearch: SavedSearch) => {
    if (testIsQueryV2(savedSearch)) {
      try {
        const response = await saveSavedSearch({
          name: savedSearch.name,
          query: convertQueryV2ToQueryV3(savedSearch.query),
          filters: savedSearch.filters ?? [],
          visibility: savedSearch.visibility,
          userIds: savedSearch.userIds,
          departmentIds: savedSearch.departmentIds,
          resultTable: resultsTable.map((column, index) => ({
            filterId: column,
            columnIndex: index,
          })),
          searchV2Id: savedSearch.id as number,
        }).unwrap();
        if (response) {
          navigate(`/search/${response.id}`);
        }
      } catch {
        toast({
          status: 'danger',
          message: `An error occured while opening saved search.`,
        });
      }
    }
  };

  const columns = [
    {
      key: 'name',
      cellType: 'link',
      title: 'Name',
      width: 'l',
      mapCellProps: (savedSearch: SavedSearch) => ({
        text: savedSearch.name,
        pathname: `/search/${savedSearch.id}`,
        onClick: () => {
          if (testIsQueryV3(savedSearch)) {
            navigate(`/search/${savedSearch.id}`);
          }
          handleClickSearchV2(savedSearch);
        },
      }),
    },
    {
      key: 'created_by',
      cellType: 'user',
      title: 'Created By',
      disableSortBy: true,
      mapCellProps: ({ userId }: SavedSearch) => ({
        asyncUser: {
          id: userId,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
    {
      key: 'created_date',
      cellType: 'datetime',
      title: 'Created on date',
      width: 'l',
      disableSortBy: true,
      mapCellProps: ({ dateAdded }: SavedSearch) => ({
        datetime: dateAdded ? new Date(dateAdded) : undefined,
      }),
    },
  ];

  const handleEditSavedSearch = async (
    data: EntityVisibilityType & { name: string },
  ) => {
    if (activeItem) {
      try {
        let response: SavedSearch | undefined = undefined;
        if (testIsQueryV2(activeItem)) {
          response = await saveSavedSearch({
            name: data.name,
            query: convertQueryV2ToQueryV3(activeItem.query),
            filters: activeItem.filters ?? [],
            visibility: data.visibility,
            userIds: data.userIds,
            departmentIds: data.departmentIds,
            resultTable: resultsTable.map((column, index) => ({
              filterId: column,
              columnIndex: index,
            })),
            searchV2Id: activeItem.id as number,
          }).unwrap();
        } else if (testIsQueryV3(activeItem)) {
          response = await putSavedSearch({
            id: activeItem.id,
            savedSearch: {
              name: data.name,
              visibility: data.visibility,
              userIds: data.userIds,
              departmentIds: data.departmentIds,
              query: activeItem.query,
              filters: activeItem.filters,
              resultTable: resultsTable.map((column, index) => ({
                filterId: column,
                columnIndex: index,
              })),
            },
          }).unwrap();
        }
        toast({
          status: 'success',
          message: `Saved Search **${
            response?.name ?? ''
          }** successfully edited.`,
        });
        setIsEditModalVisible(false);
        refetch();
      } catch {
        toast({
          status: 'danger',
          message: `An error occured while editing saved search.`,
        });
      }
    }
  };

  const canEditSavedSearch = (search: SavedSearch) =>
    testIsQueryV2(search)
      ? search.userId === currentUser.id
      : search.userId === currentUser.id || testIsAdmin(currentUser);

  const rowActions = [
    {
      condition: (row: SavedSearch) =>
        row.userId === currentUser.id || testIsAdmin(currentUser),
      label: 'Delete',
      onClick: (row: SavedSearch) => {
        setActiveItem(row);
        showDeleteModal();
      },
    },
    {
      condition: canEditSavedSearch,
      label: 'Edit',
      onClick: (row: SavedSearch) => {
        setActiveItem(row);
        setIsEditModalVisible(true);
      },
    },
  ];

  const handlePaginate = ({ pageIndex }: { pageIndex: number }) => {
    if (pageIndex !== page) {
      setPage(pageIndex);
    }
  };

  const onPageSizeChange = (pageSize: number) => {
    setPage(1);
    setPageSize(pageSize);
  };

  const handleUpdate = (state: any, action?: types.TableActionType) => {
    if (action?.type === 'toggleSortBy') {
      setSortBy(state.sortBy[0]);
    }
  };

  return (
    <>
      <PageLayout loadingContent={loadingContent} title="Saved Searches">
        <TableToggleFilter
          options={filterOptions}
          selectedOptions={typesParam}
          onChange={setTypesParam}
        />
        <ContentContainer placeholderContent={placeholderContent}>
          <PersistedTable
            name="saved searches"
            context={TableContextType.SavedSearches}
            totalCount={data?.meta.total}
            columns={columns}
            data={data?.results}
            rowActions={rowActions}
            isLoading={isFetching}
            state={{
              pageIndex: page,
              pageSize,
              columnWidths: tableSettings.columnWidths,
              sortBy: [sortBy],
            }}
            onUpdate={handleUpdate}
            onPaginate={handlePaginate}
            onPageSizeChange={onPageSizeChange}
            options={{
              enableExportXlsx: false,
              enableSelectRows: false,
              enablePageSizeSelect: true,
            }}
          />
        </ContentContainer>
      </PageLayout>
      {deleteModal}
      {isEditModalVisible && (
        <SaveEditEntityModal
          placeholder="Saved Search Name"
          initialValues={
            activeItem
              ? {
                  name: activeItem.name,
                  visibility: activeItem.visibility,
                  userIds: activeItem.userIds,
                  departmentIds: activeItem.departmentIds,
                }
              : undefined
          }
          onSubmit={handleEditSavedSearch}
          onHide={() => setIsEditModalVisible(false)}
          isVisible={isEditModalVisible}
          title="Edit Search"
        />
      )}
    </>
  );
};
