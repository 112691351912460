import React, { useEffect, useRef } from 'react';
import { useStyles } from 'uinix-ui';
import { v4 as uuid } from 'uuid';

import { Input, Option } from '../../types';
import { typedMemo } from '../../utils';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { MarkedText } from '../MarkedText';
import { Text } from '../Text';

type Props<V> = Input<boolean> & {
  /** Checkbox options */
  option: Option<V>;
  /** Checkbox count */
  count?: number;
  /** Supported label modes */
  labelMode?: 'hidden';
  /** Label typography variant */
  labelVariant?: 'body' | 'body-medium' | 'body-bold';
  /** When option.label matches search, render a text match */
  search?: string;
  /** Callback capturing option when the option label is clicked */
  onOptionClick?: (option: Option<V>) => void;
  /** If the checkbox is clicked with the shift modifier key */
  onShiftClick?: () => void;
};

/**
 * `Checkbox` is a component implementing the `Input` and `Option` interfaces.
 * - The `Input` value assumes `boolean | null` type which informs the `checked` and `indeterminate` attributes set on the `HTMLInputElement`.
 * - The `Option` value and label interfaces inform how the label is marked up.
 * - The `Option` interface is useful to harmonize usage of `Checkbox` with other `Option`-based input components (e.g. `Select`).
 * - In grouped checkboxes, the `HTMLInputElement` will appropriately use the `option.value` for the `value` attribute, adhereing to semantic guidelines.
 *
 * `Checkbox` is accessible by nature of implementation against the semantic `HTMLInputElement`.
 * - The implementation follows the guidelines provided in https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox.
 * - Appropriate use of `aria-hidden` label ensures the underlying `HTMLInputElement` is only visually hidden but never hidden to the screen reader.  On the contrary, the custom checkbox icons are always hidden to the screen reader.
 */
export const Checkbox = typedMemo(
  <V extends unknown>({
    count,
    disabled,
    id: initialId,
    labelMode,
    labelVariant,
    option,
    name,
    placeholder,
    search = '',
    value,
    onChange,
    onOptionClick,
    onShiftClick,
  }: Props<V>) => {
    const id = initialId ?? uuid();
    const styles = useStyles();

    // DOM checkbox input attributes
    const { info, label, value: optionValue } = option;
    const indeterminate = value === null;
    const checked = Boolean(value);
    const checkboxValue = String(optionValue);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      const input = inputRef.current;
      if (input) {
        input.indeterminate = indeterminate;
      }
    }, [indeterminate]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked ?? !checked);
    };

    const handleClick = (event: MouseEvent) => {
      event.stopPropagation();
      if (event.shiftKey) {
        event.preventDefault(); // ensure that default browser behaviors are prevented (as browsers implement event.shiftKey differently for input[type="checkbox"])
        onShiftClick?.();
      }
    };

    const handleOptionClick = onOptionClick
      ? (event: MouseEvent) => {
          event.stopPropagation();
          event.preventDefault();
          onOptionClick(option);
        }
      : undefined;

    const input = (
      <input
        ref={inputRef}
        aria-hidden={false}
        checked={checked}
        disabled={option.disabled || disabled}
        id={id}
        name={name}
        placeholder={placeholder}
        type="checkbox"
        value={checkboxValue}
        onChange={handleChange}
      />
    );

    const icon = (
      <Icon
        icon={
          checked
            ? 'checkbox-checked'
            : indeterminate
            ? 'checkbox-indeterminate'
            : 'checkbox-empty'
        }
        onClick={handleChange}
      />
    );

    const labelText = (
      <Text
        as="label"
        htmlFor={id}
        color={onOptionClick ? 'action.link' : 'text.primary'}
        styles={labelMode === 'hidden' ? styles.hidden.visual : null}
        variant={labelVariant}
        onClick={handleOptionClick}
      >
        <MarkedText search={search} text={label} />
      </Text>
    );

    return (
      <Layout
        data-eds-styles-disable-interactable
        align="flex-start"
        disabled={disabled}
        onClick={handleClick}
        spacing={1}
        styles={componentStyles.base}
      >
        {input}
        {icon}
        {labelText}
        {info && <Icon icon="info" tooltip={info} />}
        {count && <Text variant="body-bold">({count})</Text>}
      </Layout>
    );
  },
);

export const componentStyles = {
  base: {
    userSelect: 'none',
    '> input': {
      opacity: 0,
      position: 'absolute' as const,
      width: 0,
    },
  },
};
