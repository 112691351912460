import { useEffect, useState } from 'react';

import { DateInput, Layout, Select, Text } from '~/eds';
import { Nullable, Period, TurnTrackingHistoryType } from '~/types';

import { DateTimeInput } from './DateTimeInput';
import {
  calculateDuration,
  formatDateTimeUpdate,
  getInitialTimeValues,
  isValidTime,
} from './utils';

interface Props {
  partyName: string;
  entry: TurnTrackingHistoryType;
  disableInput: boolean;
  onChange: (entry: TurnTrackingHistoryType) => void;
}

const width = 'input.s.width';

export const EditTimeFields = ({
  partyName,
  entry,
  disableInput,
  onChange,
}: Props) => {
  const {
    initialStartPeriod,
    initialEndPeriod,
    initialStartTime,
    initialEndTime,
  } = getInitialTimeValues(entry);

  const [startTime, setStartTime] = useState<Nullable<string>>(
    initialStartTime,
  );
  const [startPeriod, setStartPeriod] = useState<Period>(
    initialStartPeriod as Period,
  );
  const [endTime, setEndTime] = useState<Nullable<string>>(
    initialEndTime || null,
  );
  const [endPeriod, setEndPeriod] = useState<Nullable<Period>>(
    initialEndPeriod as Period,
  );

  useEffect(() => {
    setStartTime(initialStartTime);
    setStartPeriod(initialStartPeriod);
  }, [entry.partyId]);

  useEffect(() => {
    if (isValidTime(startTime)) {
      const formattedDateTime = formatDateTimeUpdate(
        entry.startTime,
        startTime!,
        startPeriod,
      );
      onChange({ ...entry, startTime: formattedDateTime });
    }
  }, [startTime, startPeriod, initialStartTime, initialStartPeriod]);

  useEffect(() => {
    if (entry?.endTime && isValidTime(endTime)) {
      const formattedDateTime = formatDateTimeUpdate(
        entry.endTime,
        endTime!,
        endPeriod!,
      );
      onChange({ ...entry, endTime: formattedDateTime });
    }
  }, [endTime, endPeriod]);

  return (
    <Layout direction="column">
      {entry?.endTime ? (
        <Text color="text.secondary" pb={2}>
          <Text variant="body-bold" color="text.secondary">
            {partyName}{' '}
          </Text>
          {calculateDuration(entry.startTime, entry.endTime)} Days
        </Text>
      ) : (
        <Text variant="body-bold" pb={2}>
          Start time
        </Text>
      )}
      <Layout direction="column">
        <Layout spacing={2}>
          <Layout maxH={4}>
            <DateInput
              width={width}
              name="start date"
              value={entry.startTime}
              disabled={disableInput}
              onChange={(value) => {
                if (value) {
                  const startTime = formatDateTimeUpdate(
                    entry.startTime,
                    value,
                  );
                  onChange({ ...entry, startTime });
                }
              }}
            />
          </Layout>
          <DateTimeInput
            name="start time"
            value={startTime}
            disabled={disableInput}
            onChange={(value) => setStartTime(value)}
            width={width}
          />
          <Select
            name="start AM/PM"
            isMulti={false}
            isClearable={false}
            value={startPeriod}
            disabled={disableInput}
            width={width}
            options={[
              { label: 'am', value: 'am' },
              { label: 'pm', value: 'pm' },
            ]}
            onChange={(value) => setStartPeriod(value as Period)}
          />
        </Layout>
        {entry?.endTime && (
          <Layout direction="column">
            <Text>To</Text>
            <Layout spacing={2}>
              <>
                <DateInput
                  width={width}
                  name="end date"
                  value={entry.endTime}
                  onChange={(value) => {
                    if (value) {
                      const endTime = formatDateTimeUpdate(
                        entry.endTime,
                        value,
                      );
                      onChange({ ...entry, endTime });
                    }
                  }}
                />
                <DateTimeInput
                  name="end time"
                  value={endTime}
                  onChange={(value) => setEndTime(value)}
                  width={width}
                />
                <Select
                  name="end time AM/PM"
                  isMulti={false}
                  isClearable={false}
                  value={endPeriod}
                  width={width}
                  options={[
                    { label: 'am', value: 'am' },
                    { label: 'pm', value: 'pm' },
                  ]}
                  onChange={(value) => setEndPeriod(value as Period)}
                />
              </>
            </Layout>
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};
