import PT from 'prop-types';
import React from 'react';

import { ENTER } from '~/constants/keyCodes';
import { Box, styles } from '~/ui';
import { withInputError } from '~/ui/hocs';

const widths = {
  s: '200px',
  m: '260px',
  l: '520px',
  fullWidth: '100%',
};

const TextInput = React.forwardRef(
  (
    {
      autoFocus = false,
      disabled = false,
      error,
      id,
      placeholder,
      value,
      width = 'm',
      onChange,
      onEnter,
      onBlur,
      name,
    },
    ref,
  ) => {
    const handleKeydown = (e) => {
      if (e.keyCode === ENTER && onEnter) {
        onEnter(e);
      }
    };

    return (
      <Box
        as="input"
        aria-label={name}
        autoFocus={autoFocus}
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        role="textbox"
        name={name}
        px={4}
        ref={ref}
        sx={{
          ...styles.form.input,
          ...(error ? styles.form.error : {}),
          width: widths[width],
        }}
        type="text"
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        onFocus={(e) => e.target.select()}
        onBlur={onBlur}
        onKeyDown={handleKeydown}
      />
    );
  },
);

TextInput.propTypes = {
  ariaLabel: PT.string,
  /** Input name */
  name: PT.string,
  /** Autofocus input */
  autoFocus: PT.bool,
  /** Disables the input component */
  disabled: PT.bool,
  /** Input error */
  error: PT.string,
  /** Placeholder */
  placeholder: PT.string,
  /** Text input value */
  value: PT.string,
  /** Input width */
  width: PT.oneOf(['s', 'm', 'l', 'fullWidth']),
  /** Callback that returns new selected value */
  onChange: PT.func.isRequired,
};

TextInput.displayName = 'TextInput';

export default withInputError(TextInput);
