import React, { useMemo } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import {
  commentsCreate,
  commentsDelete,
  commentsLoad,
  commentsUpdate,
  ticketActivitiesSet,
} from '~/actions';
import * as api from '~/api';
import CommentThread from '~/components/Client/CommentThread';
import { ActivityLogItemShimmer } from '~/components/Shared/LoadingShimmers';
import { EntityType, FeatureFlagType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { useAsync, useHasFeatureFlag } from '~/hooks';
import { getCommentsByThreadId } from '~/reducers/comments';
import { getEvents } from '~/reducers/ticket';
import { FlexLayout, Text } from '~/ui';

import styles from './ActivityLog.styles';
import ActivityLogEvent from './ActivityLogEvent';

function commentDataRenderer(data) {
  return data ? (
    <Text color="gray-900" pl="2px" variant="xs-dense">
      commented on <strong>{data.issueName}</strong>
    </Text>
  ) : null;
}

function eventRenderer(event) {
  return <ActivityLogEvent event={event} />;
}

function ActivityLog({
  ticketId,
  // connected
  participantIds,
  comments,
  events,
  commentsCreate,
  commentsDelete,
  commentsLoad,
  commentsUpdate,
  ticketActivitiesSet,
}) {
  const entityId = ticketId;
  const entityType = EntityType.Ticket;
  const isPreSigCommentsEnabled = useHasFeatureFlag(
    FeatureFlagType.PreSigComments,
  );
  const isActivityLogPanelEnabled = useFlag(FlagType.ActivityLogPanel);

  const { isLoading: isLoadingActivities } = useAsync(
    api.getActivities,
    { entityId, entityType },
    {
      condition: ticketId && !isActivityLogPanelEnabled,
      deps: [ticketId],
      successHandler: ticketActivitiesSet,
    },
  );

  const { isLoading: isLoadingComments } = useAsync(
    api.getTicketComments,
    { ticketId },
    {
      condition: ticketId && !isPreSigCommentsEnabled,
      deps: [ticketId],
      successHandler: (response) => {
        commentsLoad([{ comments: response, threadId: ticketId }]);
      },
    },
  );

  async function postComment(content, addParticipants = false) {
    const newComment = await api.addComment({
      content,
      entityId,
      entityType,
      addParticipants,
    });
    commentsCreate({ ...newComment, threadId: entityId });
    return newComment;
  }

  async function deleteComment(comment) {
    await api.deleteComment(comment);
    commentsDelete(comment);
  }

  async function updateComment(comment) {
    const updatedComment = await api.updateComment({
      id: comment.id,
      content: comment.content,
      entityId,
      entityType,
    });
    commentsUpdate({ ...updatedComment, threadId: entityId });
    return updatedComment;
  }

  const visibleComments = useMemo(
    () => (isPreSigCommentsEnabled ? [] : comments),
    [isPreSigCommentsEnabled, comments],
  );

  const title = useMemo(() => {
    if (!isPreSigCommentsEnabled && isActivityLogPanelEnabled) {
      return 'Comments';
    }
    return 'Activity Log';
  }, [isPreSigCommentsEnabled, isActivityLogPanelEnabled]);

  return (
    <FlexLayout flexDirection="column" space={8} sx={{ width: '720px' }}>
      <Text variant="xl-dense-bold">{title}</Text>
      {isLoadingActivities || isLoadingComments ? (
        <ActivityLogItemShimmer />
      ) : (
        <CommentThread
          ticketId={ticketId}
          participantIds={participantIds}
          isEditable
          isLocked={isPreSigCommentsEnabled}
          commentDataRenderer={commentDataRenderer}
          comments={visibleComments}
          displayCount={5}
          events={isActivityLogPanelEnabled ? [] : events}
          eventRenderer={eventRenderer}
          promises={{
            deleteComment,
            postComment,
            updateComment,
          }}
        />
      )}
    </FlexLayout>
  );
}

function mapStateToProps({ comments, ticket }) {
  return {
    comments: getCommentsByThreadId(comments, ticket.id),
    events: getEvents(ticket),
    participantIds: ticket.participantIds,
  };
}

export default connect(mapStateToProps, {
  commentsCreate,
  commentsDelete,
  commentsLoad,
  commentsUpdate,
  ticketActivitiesSet,
})(injectSheet(styles)(ActivityLog));
