export default `
<svg
  aria-hidden="true"
  aria-label="mention"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12.2188 4.51745C10.1875 4.45495 8.25 5.20495 6.78125 6.6112C5.3125 8.0487 4.5 9.9862 4.5 12.0487C4.5 15.7362 7.21875 18.955 10.875 19.4862C10.9062 19.4862 10.9375 19.4862 11 19.4862C11.3438 19.4862 11.6562 19.2362 11.7188 18.8612C11.7812 18.455 11.5 18.08 11.0938 18.0175C8.1875 17.58 6 15.0175 6 12.0487C6 10.3925 6.625 8.82995 7.8125 7.70495C9 6.5487 10.5 5.95495 12.1562 6.01745C15.375 6.1112 18 8.9237 18 12.33V12.9237C18 13.6737 17.375 14.2987 16.625 14.2987C15.8438 14.2987 15.25 13.6737 15.25 12.9237V9.26745C15.25 8.8612 14.9062 8.5487 14.5 8.5487C14.125 8.5487 13.8125 8.82995 13.75 9.1737C13.1875 8.7362 12.5 8.4862 11.75 8.4862C9.8125 8.4862 8.25 10.08 8.25 11.9862C8.25 13.8612 9.8125 15.455 11.75 15.455C12.6875 15.455 13.5625 15.0487 14.1875 14.4237C14.7188 15.205 15.5938 15.7362 16.625 15.7362C18.1875 15.7362 19.5 14.455 19.5 12.8612V12.33C19.5 8.14245 16.2188 4.6112 12.2188 4.51745ZM11.75 13.9862C10.625 13.9862 9.75 13.1112 9.75 12.0175C9.75 10.9237 10.625 10.0487 11.75 10.0487C12.8438 10.0487 13.75 10.955 13.75 12.0175C13.75 13.1112 12.8438 13.9862 11.75 13.9862Z"
    fill="currentcolor"
  />
</svg>
`;
