import numeral from 'numeral';

import { NumeralFormatType } from '~/enums';

export const formatNumeral = (format?: NumeralFormatType) => (
  value: number,
): string => {
  const coercedFormat = Object.values(NumeralFormatType).includes(
    format as NumeralFormatType,
  )
    ? format
    : NumeralFormatType.Number;
  return numeral(value).format(coercedFormat);
};
