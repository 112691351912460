import React, { memo, useEffect, useRef } from 'react';
import { useStyles } from 'uinix-ui';

import { Input } from '../../types';
import { autosizeTextarea } from '../../utils';
import { Box } from '../Box';

type Props = Input<string> & {
  /** Enables auto-resizing the textarea height */
  enableAutoResize?: boolean;
  /** Initial number of textarea rows */
  rows?: number;
  /** Disables container styles to support embedded textarea usage in other components (e.g. TextEditor) */
  isEmbedded?: boolean;
  /** maxHeight property. Will override default. */
  maxH?: number | string;
};

export const TextArea = memo(
  ({
    autoFocus,
    disabled,
    enableAutoResize = true,
    id,
    error,
    isEmbedded,
    maxH,
    maxLength,
    name,
    placeholder,
    readOnly,
    rows = 3,
    width,
    value,
    onBlur,
    onChange,
    onFocus,
  }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const styles = useStyles();

    useEffect(() => {
      const container = ref.current as HTMLDivElement;
      // only resize the textarea if there is an input value and the container ref exists.
      if (enableAutoResize && value && container) {
        autosizeTextarea(container.firstChild as HTMLTextAreaElement);
      }
    }, [enableAutoResize, value]);

    /**
     * Overrides the default keydown event to support opinionated behaviors:
     * - Supports newlines when `Enter` is paired with the following key modifiers: `Alt`, `Control`, `Meta`.  Updates the range selection cursor.
     * - Prevents default newline behavior on unmodified `Enter` keypress, and executes the `onPost` callback instead.
     * - Retains all other default textarea behaviors
     */
    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        const shouldInsertNewline =
          event.altKey || event.ctrlKey || event.metaKey;
        if (shouldInsertNewline) {
          // add a newline and update range selection (cursor) and autosize textarea
          const textarea = event.target as HTMLTextAreaElement;
          textarea.value += '\n';
          textarea.selectionStart = textarea.selectionEnd =
            textarea.value.length;
        } else if (event.shiftKey) {
          // passthrough for code intetion: do nothing (default behavior)
        } else {
          event.preventDefault();
        }
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const textarea = event.target as HTMLTextAreaElement;
      onChange(textarea.value);
    };

    return (
      // uinix-ui does not support a (react) ref API so this is assigned on a wrapping container div
      <div ref={ref}>
        <Box
          as="textarea"
          autoFocus={autoFocus}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          py={isEmbedded ? 0 : 2}
          readOnly={readOnly}
          rows={rows}
          styles={[
            styles.input.unset.textarea,
            isEmbedded ? { width } : styles.input.container,
          ]}
          maxH={maxH}
          styleProps={{ error, width }}
          type="text"
          value={value ?? ''}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
        />
      </div>
    );
  },
);
