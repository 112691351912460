export default `
<svg
  aria-hidden="true"
  aria-label="clipboard"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M19.7031 6.21875L17.7969 4.3125C17.6094 4.125 17.3594 4 17.0781 4H11.9844C10.8594 4 9.98438 4.90625 9.98438 6V14C10.0156 15.125 10.8906 16 12.0156 16H18.0156C19.1094 16 20.0156 15.125 20.0156 14V6.9375C20.0156 6.65625 19.8906 6.40625 19.7031 6.21875ZM18.5156 14C18.5156 14.2812 18.2656 14.5 18.0156 14.5H11.9844C11.7031 14.5 11.4844 14.2812 11.4844 14V6.03125C11.4844 5.75 11.7031 5.53125 11.9844 5.53125H15.9844L16.0156 7C16.0156 7.5625 16.4531 8 17.0156 8H18.4844V14H18.5156ZM12.5156 18C12.5156 18.2812 12.2656 18.5 12.0156 18.5H5.98438C5.70312 18.5 5.48438 18.2812 5.48438 18V10.0312C5.48438 9.75 5.73438 9.53125 5.98438 9.53125H9.01562V8H5.98438C4.89062 8 3.98438 8.90625 3.98438 10L4.01562 18C4.01562 19.125 4.89062 20 6.01562 20H12.0156C13.1094 20 14.0156 19.125 14.0156 18V17H12.5156V18Z"
    fill="currentcolor"
  />
</svg>
`;
