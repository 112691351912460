import React from 'react';

import { ROW_STICKY_ZINDEX } from '../constants';
import Row from './Row';

function HeadRow({ children }) {
  return (
    <Row
      isHeaderRow={true}
      styles={{
        borderTop: 'border.divider',
        marginTop: '-1px',
        position: 'sticky',
        top: 0,
        zIndex: ROW_STICKY_ZINDEX,
      }}
    >
      {children}
    </Row>
  );
}

export default HeadRow;
