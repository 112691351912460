import React from 'react';

import { FormField, Layout, TextArea, types } from '~/eds';

import { Mode, PromptMvp as PromptState } from '../types';

interface Props {
  mode: Mode;
  prompt: PromptState;
  onUpdatePrompt: (updatedPrompt: PromptState) => void;
}

export const Prompt = ({ mode, prompt, onUpdatePrompt }: Props) => {
  const textAreaInputProps = {
    rows: 8,
  };

  const handleUpdatePrompt = (key: keyof PromptState) => (
    updatedValue: types.Nullable<string>,
  ) => {
    onUpdatePrompt({
      ...prompt,
      [key]: updatedValue ?? '',
    });
  };

  return (
    <Layout preset="sections">
      {mode === 'redline' && (
        <FormField<string, false>
          label="Clause Text"
          input={TextArea}
          inputProps={textAreaInputProps}
          name="clause text"
          placeholder={placeholders.text}
          value={prompt.text}
          onChange={handleUpdatePrompt('text')}
        />
      )}
      <FormField<string, false>
        label="Instructions"
        input={TextArea}
        inputProps={textAreaInputProps}
        name="instructions"
        placeholder={placeholders.instructions}
        value={prompt.instructions}
        onChange={handleUpdatePrompt('instructions')}
      />
    </Layout>
  );
};

const placeholders = {
  text: 'Copy and paste the clause to redline.',
  instructions:
    'Write instructions in plain English: e.g., “governing law clause with California as jurisdiction”.',
};
