export default `
<svg
  aria-hidden="true"
  aria-label="status-success"
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
>
  <path
    d="M12.9111 20C17.3174 20 20.9111 16.4375 20.9111 12C20.9111 7.59375 17.3174 4 12.9111 4C8.47363 4 4.91113 7.59375 4.91113 12C4.91113 16.4375 8.47363 20 12.9111 20ZM16.4424 10.5312L12.4424 14.5312C12.1299 14.8438 11.6611 14.8438 11.3799 14.5312L9.37988 12.5312C9.06738 12.25 9.06738 11.7812 9.37988 11.5C9.66113 11.1875 10.1299 11.1875 10.4424 11.5L11.9111 12.9688L15.3799 9.46875C15.6611 9.1875 16.1299 9.1875 16.4111 9.46875C16.7236 9.78125 16.7236 10.25 16.4111 10.5312H16.4424Z"
    fill="currentcolor"
  />
</svg>
`;
