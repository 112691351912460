import { useEffect } from 'react';

import { InteractionPosition } from '../types';
import { getInteractionPosition } from '../utils';
import { HIGHLIGHT_Y_OFFSET } from './constants';
import { HtmlHighlightData } from './types';
import { getHtmlTokensFromRange, getTextContentFromHtmlTokens } from './utils';

/** Enable text selection event listener
 * This code is not very robust, but it relates to how `htmlTokens` are processed i.e. span elements containing text nodes. The behavior is somewhat buggy near <p> and <br /> newline nodes.
 */
export const useHighlightCreate = ({
  containerRef,
  htmlContentRef,
  onHighlightCreate,
}: {
  htmlContentRef: React.RefObject<HTMLDivElement>;
  containerRef?: React.RefObject<HTMLDivElement>;
  onHighlightCreate?: (
    highlightData: HtmlHighlightData,
    position: InteractionPosition,
  ) => void;
}) => {
  const enableHighlightCreate = Boolean(onHighlightCreate);

  useEffect(() => {
    const handleCreateHighlight = ({ clientX, clientY }: MouseEvent) => {
      const selection = window.getSelection();

      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const htmlTokens = getHtmlTokensFromRange(range);
        if (htmlTokens.length > 0) {
          if (onHighlightCreate) {
            const position = getInteractionPosition({
              clientX,
              clientY,
              containerRef,
              initialOffsetY: HIGHLIGHT_Y_OFFSET,
            });
            onHighlightCreate(
              {
                htmlTokens,
                text: getTextContentFromHtmlTokens(htmlTokens),
              },
              position,
            );
          }
        }
      }
    };

    const htmlContent = htmlContentRef.current;

    if (enableHighlightCreate && htmlContent) {
      htmlContent.addEventListener('mouseup', handleCreateHighlight);
    }

    return () => {
      if (htmlContent) {
        htmlContent.removeEventListener('mouseup', handleCreateHighlight);
      }
    };
  }, [enableHighlightCreate]);
};
