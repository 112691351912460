import { Text, useModal } from '~/eds';
import {
  WorkflowPublishActionType,
  WorkflowPublishCategoryType,
} from '~/enums';
import { Nullable, Workflow } from '~/types';

interface WorkflowActionProps {
  workflow: Nullable<Workflow>;
  action: Nullable<WorkflowPublishActionType | 'DELETE'>;
  onHide: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

export const useWorkflowActionModal = ({
  workflow,
  action,
  onHide,
  onConfirm,
  isLoading,
}: WorkflowActionProps) => {
  const checkIfUnpublishAction = () =>
    action === WorkflowPublishActionType.Unpublish;

  const getWorkflowActionType = () => {
    if (!workflow) {
      return null;
    }
    const prefilledFormURLExists = !!workflow.prefilledFormData;
    const unpublishAction = checkIfUnpublishAction();

    if (action === 'DELETE') {
      return 'DELETE';
    }

    if (prefilledFormURLExists && !unpublishAction) {
      return WorkflowPublishCategoryType.OveridePublish;
    } else if (prefilledFormURLExists && unpublishAction) {
      return WorkflowPublishCategoryType.OverideUnpublish;
    } else if (!prefilledFormURLExists && unpublishAction) {
      return WorkflowPublishCategoryType.RegularUnpublish;
    } else {
      return WorkflowPublishCategoryType.RegularPublish;
    }
  };

  const workflowActionType = getWorkflowActionType();

  const getPublishUnpublishContent = () => {
    switch (workflowActionType) {
      case WorkflowPublishCategoryType.OverideUnpublish:
        return (
          <>
            Are you sure you want to unpublish this workflow? Please note that:
            <br />
            <ul>
              <li>
                Users will not be able to create new tickets based on this
                workflow.
              </li>
              <li>
                Your Prefillable Form Link will be cleared. Upon republishing,
                you will need to re-map and re-generate your Prefillable Form
                Link in the Settings tab of Workflow Builder.
              </li>
            </ul>
            Pending tickets from this workflow will not be effected.
          </>
        );
      case WorkflowPublishCategoryType.RegularUnpublish:
        return (
          <Text>
            Are you sure you want to unpublish this workflow? Users will not be
            able to create new tickets based on this workflow.{' '}
            <Text variant="body-bold">
              Any pending tickets will not be affected.
            </Text>
          </Text>
        );
      case WorkflowPublishCategoryType.OveridePublish:
        return (
          <>
            Republishing the workflow will necessitate re-mapping and
            re-generating your Prefillable Form Link in Settings.
          </>
        );
      case 'DELETE':
        return (
          <Text>
            Are you sure you want to delete{' '}
            <Text variant="body-bold">{workflow?.name}</Text> Workflow?&nbsp;
            <Text variant="body-bold">This action cannot be reverted.</Text>
          </Text>
        );
      default:
        return 'Are you sure you want to publish this workflow? It will be available to users and they will be able to create new tickets based on this workflow.';
    }
  };

  function getActionText() {
    switch (workflowActionType) {
      case WorkflowPublishCategoryType.OveridePublish:
        return 'Reset Mapping & Publish';
      case WorkflowPublishCategoryType.OverideUnpublish:
        return 'Reset Mapping & Unpublish';
      case WorkflowPublishCategoryType.RegularUnpublish:
        return 'Unpublish Workflow';
      case 'DELETE':
        return 'Delete Workflow';
      default:
        return 'Publish Workflow';
    }
  }

  return useModal({
    children: getPublishUnpublishContent(),
    title: checkIfUnpublishAction() ? 'Unpublish Workflow' : 'Publish Workflow',
    onHide,
    primaryAction: {
      text: getActionText(),
      onClick: onConfirm,
      level: workflowActionType === 'DELETE' ? 'danger' : 'primary',
      isLoading,
    },
  });
};
