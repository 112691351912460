import {
  black1,
  black3,
  bold,
  evisortBlue,
  evisortLightYellow,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  headerText: {
    marginBottom: '15px',
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
  },
  radioContainerContent: {
    display: 'inline-block',
    marginBottom: '10px',
    '& > div:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  folderName: {
    width: '100%',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    marginTop: '12px',
    padding: '12px 16px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    fontSize: '15px',
    caretColor: evisortBlue,
    '&:hover': {
      borderColor: evisortBlue,
    },
    '&:focus': {
      borderColor: evisortBlue,
      outline: 'none',
    },
  },
  folderSettingsContent: {
    marginTop: '20px',
    fontSize: '13px',
  },
  boldText: {
    fontWeight: bold,
  },
  changeFolderSettingsBtn: {
    padding: 0,
    color: evisortBlue,
    border: 'none',
    fontWeight: bold,
    cursor: 'pointer',
  },
  accessSettingOptionLabel: {
    cursor: 'pointer',
  },
  folderAccessSettingOption: {
    fontSize: '13px',
    '& input[type="radio"]': {
      marginRight: '2px',
      width: '20px',
      position: 'relative',
      top: '1px',
    },
  },
  allowUsersDownloadContainer: {
    padding: '12px 15px',
    margin: '10px',
    backgroundColor: '#efefef',
    borderRadius: '3px',
  },
  allowUsersDownloadHeader: {
    marginBottom: '10px',
    fontSize: '12px',
    fontWeight: semibold,
  },
  privilegeOptionContainer: {
    display: 'flex',
    alignItems: 'center',
    '& label': {
      fontSize: '13px',
    },
  },
  aclControlsContainer: {
    paddingLeft: '3px',
  },
  aclControlsMessageBox: {
    color: '#635d54',
    fontSize: '12px',
    borderLeft: '2px solid #c1c1c1',
    borderRadius: '3px',
  },
  aclInheritedControlsMessage: {
    backgroundColor: evisortLightYellow,
    padding: '10px',
    display: 'block',
    '& svg': {
      flexShrink: 0,
      marginRight: '10px',
    },
  },
  aclRestrictedControlsMessage: {
    backgroundColor: '#f5f5f5',
    marginBottom: '10px',
    padding: '10px',
  },
  inputFieldHeader: {
    margin: '20px 0 10px',
    display: 'block',
    fontSize: '12px',
  },
  accessDepartmentsContainerEmpty: {
    paddingBottom: '15px',
    borderBottom: '1px solid #ccc',
  },
  accessDepartmentsContainer: {
    paddingBottom: '15px',
  },
  noDeptMessage: {
    paddingLeft: '5px',
    color: black3,
    fontSize: '13px',
  },
  selectDepartmentCTA: {
    margin: '5px 0 15px 30px',
    color: evisortBlue,
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '12px',
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      color: '#236d90',
    },
    '& svg': {
      position: 'relative',
      top: '3px',
    },
  },
};

export default styles;
