import React, { memo, useEffect } from 'react';

import {
  Layout,
  Markdown,
  pluralize,
  RadioGroup,
  Text,
  TextArea,
  types,
} from '~/eds';

export type DeleteDataType = {
  comment: string;
  reason: types.Nullable<Reason>;
};
export type Reason = 'duplicate' | 'mistake' | 'other';

interface Props extends DeleteDataType {
  type: 'document' | 'folder';
  selectedCount: number;
  onChange: (params: DeleteDataType) => void;
}
const options: types.Option<Reason>[] = [
  {
    label: 'Duplicate',
    value: 'duplicate',
  },
  {
    label: 'Added by mistake',
    value: 'mistake',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
const CHAR_LIMIT = 100;
export const DeleteAction = memo(
  ({
    comment: deleteComment,
    reason: deleteReason,
    selectedCount,
    type,
    onChange,
  }: Props) => {
    const pluralizedEntity = `${pluralize(selectedCount, type || 'item', {
      enableCount: false,
    })}`;

    const handleCommentChange = (updatedComment: string | null): void => {
      if (!updatedComment) {
        onChange({
          reason: deleteReason,
          comment: '',
        });
      }
      if (updatedComment && updatedComment?.length <= CHAR_LIMIT) {
        onChange({
          reason: deleteReason,
          comment: updatedComment.slice(0, CHAR_LIMIT),
        });
      }
    };

    useEffect(() => {
      onChange({
        reason: deleteReason,
        comment: deleteComment,
      });
    }, [deleteReason, deleteComment]);

    const handleReasonChange = (
      updatedReason: types.Nullable<Reason>,
    ): void => {
      onChange({
        reason: updatedReason,
        comment: deleteComment,
      });
    };

    const multiple = selectedCount > 1;
    const duplicatesText = `and ${
      selectedCount > 1 ? 'their' : 'its'
    } duplicates`;
    return (
      <Layout direction="column" spacing={4} p={4}>
        <Markdown
          text={`Only documents you have permission to edit will be deleted. Deleting **${selectedCount}** selected ${pluralizedEntity} ${duplicatesText} is permanent and cannot be undone.`}
        />
        <Layout direction="column" spacing={2}>
          <Text variant="body-medium">
            Please put in a reason why you want to remove
            {multiple ? ' these ' : ' this '} {type}
            {multiple ? 's' : ''}:
          </Text>
          <RadioGroup
            name="deleteReason"
            options={options}
            value={deleteReason}
            onChange={handleReasonChange}
          />
        </Layout>
        <Layout direction="column">
          <Layout direction="column" spacing={2}>
            <Text variant="body-medium">
              Tell us more{' '}
              {deleteReason === 'other' ? '(Required)' : '(Optional)'}
            </Text>
            <TextArea
              placeholder="Your reason"
              value={deleteComment}
              onChange={handleCommentChange}
              name="reason"
            />
          </Layout>
          <Text variant="tiny" color="text.secondary">
            {deleteComment.length} / {CHAR_LIMIT} characters
          </Text>
        </Layout>
      </Layout>
    );
  },
);
