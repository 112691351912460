import PropTypes from 'prop-types';
import React from 'react';

const FlagIcon = (props) => (
  <svg width={props.size} height={props.size} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={props.color}
        fillRule="nonzero"
        fillOpacity={props.opacity}
        d="M14.4 6l-.24-1.2c-.09-.46-.5-.8-.98-.8H6c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1s1-.45 1-1v-6h5.6l.24 1.2c.09.47.5.8.98.8H19c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1h-4.6z"
      />
    </g>
  </svg>
);

FlagIcon.defaultProps = {
  color: 'rgba(0, 0, 0, 0.75)',
  size: '24',
  opacity: '1',
};

FlagIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  opacity: PropTypes.string,
};

export default FlagIcon;
