import { sortBy } from 'lodash';
import React from 'react';

import { getHandlersKeyFromSearchResult } from '~/components/SearchV2/SearchResult/SearchResult.utils';
import { trackSegment } from '~/components/SegmentAnalytics';
import { Box, Image, Layout, Panel_DEPRECATED, Text, types } from '~/eds';
import { api } from '~/redux';
import { DocumentSearchResultItem } from '~/redux/api/methods';

import ClauseMatches from './ClauseMatches';
import { Fields } from './Fields';
import SearchMatches from './SearchMatches';

export interface Props {
  actions?: types.Action<unknown>[];
  document?: DocumentSearchResultItem;
  onHide: () => void;
}

function NoMatch({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <Layout align="center" direction="column" spacing={4}>
      <Image alt="empty results" image="empty-no-search" />
      <Text textAlign="center" variant="subtitle">
        {title}
      </Text>
      <Text preset="description" textAlign="center">
        {description}
      </Text>
    </Layout>
  );
}

function DetailsPanel({ actions, document, onHide }: Props) {
  // TODO: team to refactor to specify tab.panel to be compliant with a11y

  if (!document) {
    return null;
  }

  const handlers = getHandlersKeyFromSearchResult(document);
  const title = handlers[0].document_name;
  const fullTextMatches = document.full_text_matches.map((item) => ({
    content: [item],
  }));
  const provisions = sortBy(document.provision_list, 'name'); // for some reason the data isn't sorted
  const hasNoMatch = !fullTextMatches.length;

  api.endpoints.getDocumentInformation.useQuery(
    document.document_handlers[0].id,
  );

  const tabs = [
    {
      label: 'Fields',
      value: 'fields',
      panel: (
        <Fields
          handlerId={document.document_handlers[0].id}
          documentId={document.id}
        />
      ),
    },
    {
      label: 'Clauses',
      value: 'clauses',
      panel: (
        <Box pt={4} pr={4} pl={4}>
          {!provisions.length && (
            <NoMatch
              title="No key clause found"
              description="Refine your Clause Type filter to show key clause matches"
            />
          )}
          <Layout direction="column" spacing={8}>
            <ClauseMatches clauses={provisions} />
          </Layout>
        </Box>
      ),
    },
    {
      label: 'Keyword Matches',
      value: 'matches',
      panel: (
        <Box pt={4} pr={4} pl={4}>
          {hasNoMatch && (
            <NoMatch
              title="No keyword text matches"
              description="Refine your search query to show keyword text matches"
            />
          )}
          <Layout direction="column" spacing={8}>
            <SearchMatches matches={fullTextMatches} />
          </Layout>
        </Box>
      ),
    },
  ];

  const handleSelectTab = (tab: string) => {
    trackSegment('selectTab', {
      name: document?.id,
      value: tab,
    });
  };

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      isPadded
      title={title}
      actions={actions}
      isVisible={true}
      onHide={onHide}
      tabs={tabs}
      onSelectTab={handleSelectTab}
    />
  );
}

export default DetailsPanel;
