import { debounce } from 'lodash';
import { DependencyList, useCallback } from 'react';

// using lodash.debounce's DebouncedFunc type signature.
export const useDebounce = <C extends (...args: any[]) => any>(
  callback: C,
  wait: number,
  deps: DependencyList = [callback],
) => {
  return useCallback(debounce(callback, wait), deps);
};
