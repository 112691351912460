import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import {
  PromptModelConfig,
  PromptModelVersion,
} from '../../types/sylvanus/model';

interface Request {
  modelId: Uuid;
  config: PromptModelConfig;
}

type Response = PromptModelVersion;

export const createPromptModelVersion = async ({
  modelId,
  config,
}: Request): Promise<Response> => {
  return sylvanus.post(`/model/${modelId}/version`, {
    config,
  });
};
