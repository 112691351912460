import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { black5, evisortBlue } from '../../../assets/shared-styles/general';
import { MODAL_SEARCH_WITHIN_PROVISION } from '../../../types/modal.types';
import { searchWordsContainOptions } from '../../AnalyzerPage/TextSearchForm/TextSearchForm.utils';
import EcButton from '../EcButton';
import EcModal from '../EcModal';
import EcMultipleSelectValue from '../EcMultipleSelectValue';
import EcSelect from '../EcSelect';
import EcTooltip from '../EcTooltip';
import withHover from '../HOCs/withHover';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import RemoveIcon from '../Icons/RemoveIcon';
import SearchIcon from '../Icons/SearchIcon';
import SubArrowIcon from '../Icons/SubArrowIcon';
import styles from './EcFilterProvision.styles';

const RemoveIconWithHover = withHover(RemoveIcon);

class EcFilterProvision extends Component {
  constructor(props) {
    super(props);

    this.handleOnRelationChange = this.handleOnRelationChange.bind(this);
    this.handleOnSearchProvisionClick = this.handleOnSearchProvisionClick.bind(
      this,
    );
    this.handleUpdateFilter = this.handleUpdateFilter.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);

    this.state = { modal: null };
  }

  handleOnRelationChange(contains) {
    this.props.onChange({ contains });
  }

  handleOnSearchProvisionClick() {
    this.setState({ modal: MODAL_SEARCH_WITHIN_PROVISION });
  }

  handleUpdateFilter(filterIndex, filter) {
    const text_search = filter.textSearch.map((searchFilter) => {
      return {
        contains: searchFilter.filterContains,
        scope: searchFilter.filterScope,
        text: searchFilter.filterPhrase,
      };
    });

    this.props.onChange({
      text_search: [...this.props.text_search, ...text_search],
    });
  }

  handleOnProvisionRemoveClick(textIndex) {
    const { text_search } = this.props;

    const newTextSearch = [...text_search];
    newTextSearch.splice(textIndex, 1);

    this.props.onChange({ text_search: newTextSearch });
  }

  handleHideModal() {
    this.setState({ modal: null });
  }

  renderSearchProvisionModal() {
    const { provision, contains: filterRelation } = this.props;

    const filterValue = { label: provision, value: provision };
    const selectedFilter = { filterRelation, filterValue };

    return (
      <EcModal
        modalType={MODAL_SEARCH_WITHIN_PROVISION}
        width="790px"
        title="Search Within Provision"
        hideModal={this.handleHideModal}
        filter={selectedFilter}
        filterIndex={null}
        handleUpdateFilter={this.handleUpdateFilter}
      />
    );
  }

  renderProvisionTextValues() {
    const { classes, text_search, contains } = this.props;

    if (contains.value === 'does_not_contain') return null;

    return (
      <div className={classes.provisionValuesContainer}>
        {text_search.map((provisionTextValue, index) => {
          const { contains, scope, text } = provisionTextValue;
          const provision = (
            <Fragment>
              {contains.label} {scope.label}
              &nbsp;
              <b>{text.split(' ').join(', ')}</b>
            </Fragment>
          );

          return (
            <div
              className={classes.provisionTextContainer}
              key={`provision-text-${index}`}
            >
              <SubArrowIcon color={black5} />
              <div className={classes.provisionText}>
                <EcMultipleSelectValue
                  label={provision}
                  key={index}
                  closeHandler={() => this.handleOnProvisionRemoveClick(index)}
                />
              </div>
            </div>
          );
        })}

        <div className={classes.searchProvisionWrapper}>
          <button
            className={classes.searchProvisionAction}
            onClick={this.handleOnSearchProvisionClick}
          >
            <SearchIcon size="20" opacity="1" color={evisortBlue} />
            Search within provision
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      classes,
      provision,
      contains,
      onRemoveClick,
      onFilterNameClick,
    } = this.props;
    const { modal } = this.state;

    return (
      <div className={classes.ecFilterFieldWrapper}>
        <div className={classes.ecFilterField}>
          <button
            title="Remove field"
            className={classes.removeFieldContainer}
            onClick={onRemoveClick}
          >
            <RemoveIconWithHover size="20" />
          </button>
          <div className={classes.fieldContainer}>
            <EcSelect
              defaultValue={contains}
              options={searchWordsContainOptions}
              placeholder="select option"
              onChange={this.handleOnRelationChange}
            />
          </div>
          <hr className={classes.blueLine} />
          <div
            className={classes.buttonContainer}
            data-tip
            data-for={`field-tooltip-${provision}`}
          >
            <EcButton
              text={provision}
              width="200px"
              height="44px"
              iconRight={<ChevronDownIcon />}
              onClick={onFilterNameClick}
            />
            <EcTooltip id={`field-tooltip-${provision}`}>{provision}</EcTooltip>
          </div>
        </div>
        {this.renderProvisionTextValues()}
        {modal === MODAL_SEARCH_WITHIN_PROVISION &&
          this.renderSearchProvisionModal()}
      </div>
    );
  }
}

EcFilterProvision.propTypes = {
  entity: PropTypes.string.isRequired,
  provision: PropTypes.string.isRequired,
  contains: PropTypes.object.isRequired,
  text_search: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onFilterNameClick: PropTypes.func.isRequired,
};

export default injectSheet(styles)(EcFilterProvision);
