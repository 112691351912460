import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { StatusType } from '../../types';
import { Text } from '../Text';

interface Props {
  /** Integer values, must fit within the badge size */
  number: number;
  /** Status of the badge */
  status?: StatusType;
}

export const Badge = memo(({ status = 'info', number }: Props) => {
  const styles = useStyles();

  return (
    <Text
      color="inverse.text.primary"
      styles={[componentStyles.base, styles.colors.status]}
      styleProps={{ status, variant: 'primary' }}
      variant="body-bold"
    >
      {number}
    </Text>
  );
});

const componentStyles = {
  base: {
    alignItems: 'center',
    borderRadius: 'round',
    display: 'flex',
    flex: 'none',
    height: 'icon.l',
    justifyContent: 'center',
    width: 'icon.l',
  },
};
