import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { GetOptimizeSummaryApiV1ModelModelIdVersionVersionNumberOptimizeSummaryGetApiResponse } from '../../types/sylvanus/model';

interface Request {
  modelId: Uuid;
  version: number;
}

type Response = string;

type ServerResponse = GetOptimizeSummaryApiV1ModelModelIdVersionVersionNumberOptimizeSummaryGetApiResponse;

export const getPromptModelOptimizeSummary = async ({
  version,
  modelId,
}: Request): Promise<Response> => {
  const response: ServerResponse = await sylvanus.get(
    `/model/${modelId}/version/${version}/optimize/summary`,
    { params: { format: 'json' } },
  );

  return response;
};
