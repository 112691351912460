import React, { memo } from 'react';

import { BaseLinkProps } from '../../types';
import { Box } from '../Box';
import { useShowMoreItems } from '../hooks/useShowMoreItems';
import { Layout } from '../Layout';
import { Link } from '../Link';

interface LinkProps extends BaseLinkProps {
  text: string;
  tooltip?: string;
  onClick?: () => void;
}

interface Props {
  /** Links */
  links: LinkProps[];
  /** Limit the number of visible links to display */
  limit?: number;
  /** Number of hidden links to display */
  step?: number;
}

export const CollapsibleLinks = memo(
  ({ links, limit = links.length, step }: Props) => {
    const [visibleLinks, showMore] = useShowMoreItems<LinkProps>({
      items: links,
      limit,
      serializeItem: serializeLink,
      step,
    });

    return (
      <Layout preset="chips">
        {visibleLinks.map((link, i) => (
          <Box key={i} display="inline-flex">
            <Link key={i} {...link}>
              {link.text}
            </Link>
            {i < visibleLinks.length - 1 && ', '}
          </Box>
        ))}
        {showMore}
      </Layout>
    );
  },
);

const serializeLink = (link: LinkProps): string => link.tooltip ?? link.text;
