import { throttle } from 'lodash';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom-v5-compat';

import { UploadStatusTooltip } from '~/components/UploadPage/UploadStatus/UploadStatusTooltip.utils';
import { UploadStatusType } from '~/enums';
import { withRouting } from '~/routing';
import { closeUploadInfoPanel, getUserUploads } from '~/slices/fileUpload';

import {
  black2,
  evisortBlue,
  evisortOrange,
  evisortRed,
  evisortYellow,
} from '../../../assets/shared-styles/general';
import { featureFlagIncluded } from '../../../utils/user';
import { documentTypeIcon } from '../../DocumentsPage/Document.utils';
import EcCard from '../../Shared/EcCard';
import EcTooltip from '../../Shared/EcTooltip';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import CloseIcon from '../../Shared/Icons/CloseIcon';
import QueueIcon from '../../Shared/Icons/QueueIcon';
import SyncIcon from '../../Shared/Icons/SyncIcon';
import UploadArrowUpIcon from '../../Shared/Icons/UploadArrowUpIcon';
import WarningIcon from '../../Shared/Icons/WarningIcon';
import styles from './UploadInfoPanel.styles';

const REFRESH_LOG_INTERVAL_MS = 5000;

class UploadInfoPanel extends Component {
  constructor(props) {
    super(props);

    this.loadUploadLogs = throttle(
      this.loadUploadLogs,
      REFRESH_LOG_INTERVAL_MS,
    );
  }

  componentDidMount() {
    this.props.onRef(this);
    this.loadUploadLogs();
    this.interval = setInterval(() => this.refreshUploadLogs(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadUploadLogs() {
    this.props.getUserUploads();
  }

  refreshUploadLogs() {
    const { open, uploadLogs } = this.props;
    const anyProcessingFile =
      uploadLogs &&
      uploadLogs.some(
        (log) =>
          log.processing_status === UploadStatusType.Uploading ||
          log.processing_status === UploadStatusType.Queued ||
          log.processing_status === UploadStatusType.Processing,
      );

    if (open && anyProcessingFile) {
      this.loadUploadLogs();
    }
  }

  renderStatusIcon(status) {
    const { user } = this.props;

    switch (status) {
      case UploadStatusType.Completed:
        return <CheckmarkIcon size="20" green />;
      case UploadStatusType.Queued:
        return <QueueIcon color={black2} />;
      case UploadStatusType.Processing:
        return <SyncIcon size="20" color={evisortBlue} />;
      case UploadStatusType.ProcessingError:
      case UploadStatusType.UploadFailed:
        return <CloseIcon size="20" color={evisortRed} />;
      case UploadStatusType.Uploading:
        return <UploadArrowUpIcon size="20" color={evisortYellow} />;
      case UploadStatusType.Duplicate:
        return featureFlagIncluded(user, 'DUPLICATE_STATUS') ? (
          <WarningIcon size="20" color={evisortOrange} />
        ) : (
          <CheckmarkIcon size="20" green />
        );
      case UploadStatusType.OcrFailure:
      case UploadStatusType.AlgorithmFailure:
        return <WarningIcon size="20" yellow />;
      default:
        break;
    }
  }

  renderLogs() {
    const { classes } = this.props;
    const { uploadLogs } = this.props;
    const content = uploadLogs.length
      ? uploadLogs.map((log) => {
          return (
            <div className={classes.itemRow} key={log.id}>
              <span className={classes.documentTypeIcon}>
                {documentTypeIcon(log.file_type)}
              </span>
              <span className={classes.documentName}>{log.document_name}</span>
              <span
                className={classes.docUploadStatus}
                data-tip
                data-for={`uploadStatusTooltip-${log.id}`}
              >
                {this.renderStatusIcon(log.processing_status)}
                {log.processing_status !== 'DUPLICATE' && (
                  <EcTooltip
                    id={`uploadStatusTooltip-${log.id}`}
                    width="224px"
                    place="right"
                  >
                    {UploadStatusTooltip[log.processing_status]}
                  </EcTooltip>
                )}
              </span>
              <Link
                id={`upload_panel_viewLink?document=${log.id}`}
                to={`/document/${log.id}`}
              >
                View
              </Link>
            </div>
          );
        })
      : null;
    return <div className={classes.itemContainer}>{content}</div>;
  }

  render() {
    const {
      classes,
      location: { pathname },
    } = this.props;
    const { open } = this.props;

    if (pathname === '/upload' || !open) return null;
    return (
      <div className={classes.uploadInfoPopup}>
        <EcCard>
          <div className={classes.uploadInfoContainer}>
            Upload Log
            <button
              className={classes.closeIcon}
              onClick={() => this.props.closeUploadInfoPanel()}
            >
              <CloseIcon size="19" />
            </button>
          </div>
          {this.renderLogs()}
        </EcCard>
      </div>
    );
  }
}

const mapStateToProps = ({ fileUpload }) => ({
  open: fileUpload.isUploadInfoPanelOpen,
  uploadLogs: fileUpload.uploadLogs,
});
const mapDispatchToProps = {
  getUserUploads,
  closeUploadInfoPanel,
};

UploadInfoPanel = connect(mapStateToProps, mapDispatchToProps)(UploadInfoPanel);

UploadInfoPanel = withRouting(UploadInfoPanel);

export default injectSheet(styles)(UploadInfoPanel);
