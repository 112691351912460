import { Party } from '~/features/manage-parties';
import { pilot } from '~/services';
import { PilotId } from '~/types';

interface Request {
  clientId: PilotId;
  isInternal: boolean;
  oldName: string;
  party: Party;
}

export const updateParty = async ({
  clientId,
  isInternal,
  oldName,
  party,
}: Request): Promise<boolean> => {
  const params = {
    internal: isInternal,
    old_name: oldName,
    party,
  };

  await pilot.post(`client/${clientId}/update-party-list/`, params);

  return true;
};
