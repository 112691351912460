import { everest } from '~/services';
import { toFile } from '~/utils/api';
import { sortByStringValue } from '~/utils/array';
import { cleanupWorkflow, mergeImplicitFields } from '~/utils/workflow';

const createDefaultFilename = () => [
  {
    type: 'field',
    value: 'Workflow Name',
  },
  {
    type: 'user-content',
    value: '_',
  },
  {
    type: 'user-content',
    value: 'Insert custom field or text_',
  },
  {
    type: 'field',
    value: 'Date Signed',
  },
  {
    type: 'user-content',
    value: '_',
  },
  {
    type: 'field',
    value: 'Submitter Name',
  },
];

function toFormData(workflow) {
  const { file, ...payload } = workflow;
  const formData = new FormData();
  formData.append('payload', JSON.stringify(payload));
  if (file) {
    formData.append('file', file);
  }
  return formData;
}

export async function getWorkflows() {
  const workflows = await everest.get('/workflows/');
  return workflows.map((workflow) => ({
    ...workflow,
    isDraft: workflow.status === 'draft',
    isPublished: workflow.published,
    canPublish: workflow.publishable,
    lastModifiedBy: workflow.lastModifiedById,
  }));
}

export async function getAvailableWorkflows() {
  const workflows = await everest.get('/workflows/available/');
  return workflows
    .map((workflow) => ({
      ...workflow,
      isDraft: workflow.status === 'draft',
      isPublished: workflow.status === 'published',
      lastModifiedBy: workflow.lastModifiedById,
    }))
    .sort(sortByStringValue('name'));
}

export async function getWorkflow({ id, folderTreeId }) {
  const workflow = await everest.get(`/workflows/${id}/`);
  let {
    archiveLocation,
    ticketNamingConvention = null,
    keepSystemDefaultTicketName = true,
  } = workflow.settings;

  if (!archiveLocation) {
    archiveLocation = {
      pilotFolderId: null,
      filePath: [],
      fileName: [],
    };
  }

  if (!archiveLocation.pilotFolderId) {
    archiveLocation.pilotFolderId = folderTreeId;
  }

  if (!archiveLocation.fileName.length) {
    archiveLocation.fileName = createDefaultFilename();
  }

  const updatedSettings = {
    ...workflow.settings,
    ticketNamingConvention,
    keepSystemDefaultTicketName,
    archiveLocation,
  };

  return {
    ...mergeImplicitFields(workflow),
    settings: updatedSettings,
  };
}

export async function patchWorkflowSettings(workflow) {
  const cleanedWorkflow = cleanupWorkflow(workflow);
  return await everest.patch(
    `/workflows/${workflow.id}/settings`,
    cleanedWorkflow.settings,
  );
}

export async function createWorkflow(workflow) {
  const formData = toFormData(workflow);
  return await everest.post('/workflows/', formData);
}

export async function deleteWorkflow({ id }) {
  return await everest.remove(`/workflows/${id}`);
}

export async function patchWorkflow(workflow) {
  const formData = toFormData(cleanupWorkflow(workflow));
  return await everest.patch(`/workflows/${workflow.id}`, formData);
}

export async function savePrefillableIntakeFormUrl({
  workflow,
  formDataString,
}) {
  const formData = new FormData();
  formData.append('payload', JSON.stringify(formDataString));
  return everest.patch(`/workflows/${workflow.id}/form-data`, formData);
}

export async function publishWorkflow({ id }) {
  return await everest.patch(`/workflows/${id}/publish`);
}

export async function discardDraft({ id }) {
  return await everest.patch(`/workflows/${id}/discard-draft`);
}

export async function saveAndPublishWorkflow(workflow) {
  const formData = toFormData(cleanupWorkflow(workflow, true));
  return await everest.patch(`/workflows/${workflow.id}/publish`, formData);
}

export async function unpublishWorkflow({ id }) {
  return await everest.patch(`/workflows/${id}/unpublish`);
}

export async function getWorkflowFile({ id, type = 'original' }) {
  const config = {
    fullResponse: true,
    responseType: 'blob',
  };
  const response = await everest.get(
    `/workflows/${id}/download/${type}`,
    config,
  );
  return toFile(response);
}

export async function getWorkflowIntakeForm(id) {
  return await everest.get(`/workflows/${id}/intake-form/link/`);
}

export async function parseTemplate(file) {
  const formData = toFormData({ file });
  return await everest.put('/workflows/parse-template', formData);
}
