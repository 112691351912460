export const keyframes = {
  shimmer: {
    '0%': {
      backgroundPosition: '-400px 0',
    },
    '100%': {
      backgroundPosition: '400px 0',
    },
  },
  slideInRight: {
    from: {
      transform: 'translateX(100%)',
    },
    to: {
      transform: 'translateX(0)',
    },
  },
};
