import {
  black2,
  black4,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  provisionLabel: {
    marginBottom: '8px',
    color: black4,
    fontSize: '11px',
    fontWeight: semibold,
  },
  provisionType: {
    marginBottom: '24px',
    color: black2,
    fontSize: '14px',
  },
  searchLabel: {
    marginBottom: '12px',
    color: black2,
    fontSize: '12px',
    fontWeight: semibold,
  },
  searchOptions: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
  },
  textSearchRowSeparator: {
    margin: '16px 0',
    border: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  textSearchSelectItem: {
    width: '25%',
    marginRight: '16px',
    color: black2,
    '& span': {
      fontWeight: semibold,
    },
  },
  textSearchInputItem: {
    flexGrow: 1,
    marginRight: '16px',
    color: black2,
  },
  textSearchOptionItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '83px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '44px',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    '& input': {
      flexGrow: 1,
      border: 'none',
      borderRadius: '4px',
      padding: '12px 16px',
      color: black2,
      fontSize: '15px',
      outline: 'none',
      caretColor: evisortBlue,
      '&::placeholder': {
        color: 'rgba 0 0 0, 0.5',
        fontWeight: black4,
      },
    },
  },
  textSearchRemoveButton: {
    height: '20px',
    cursor: 'pointer',
  },
  errorMessage: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    marginBottom: '16px',
    padding: '16px',
    backgroundColor: 'rgba(243, 55, 55, 0.1)',
    color: black2,
    fontSize: '15px',
  },
};

export default styles;
