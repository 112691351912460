export default `
<svg
  aria-hidden="true"
  aria-label="file-pdf"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M8 4C6.875 4 6 4.90625 6 6V18C6 19.125 6.875 20 8 20H16C17.0938 20 18 19.125 18 18V9H14C13.4375 9 13 8.5625 13 8V4H8ZM14 4V8H18L14 4ZM8 11H8.75C9.6875 11 10.5 11.8125 10.5 12.75C10.5 13.7188 9.6875 14.5 8.75 14.5H8.5V15.5C8.5 15.7812 8.25 16 8 16C7.71875 16 7.5 15.7812 7.5 15.5V11.5C7.5 11.25 7.71875 11 8 11ZM8.75 13.5C9.15625 13.5 9.5 13.1875 9.5 12.75C9.5 12.3438 9.15625 12 8.75 12H8.5V13.5H8.75ZM11 11.5C11 11.25 11.2188 11 11.5 11H12.25C13.0625 11 13.75 11.6875 13.75 12.5V14.5C13.75 15.3438 13.0625 16 12.25 16H11.5C11.2188 16 11 15.7812 11 15.5V11.5ZM12 15H12.25C12.5 15 12.75 14.7812 12.75 14.5V12.5C12.75 12.25 12.5 12 12.25 12H12V15ZM15 11H16.5C16.75 11 17 11.25 17 11.5C17 11.7812 16.75 12 16.5 12H15.5V13H16.5C16.75 13 17 13.25 17 13.5C17 13.7812 16.75 14 16.5 14H15.5V15.5C15.5 15.7812 15.25 16 15 16C14.7188 16 14.5 15.7812 14.5 15.5V11.5C14.5 11.25 14.7188 11 15 11Z"
    fill="#f00f17"
  />
</svg>
`;
