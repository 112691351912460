export enum UploadStatusType {
  AlgorithmFailure = 'ALGORITHM_FAILURE',
  Completed = 'COMPLETED',
  Duplicate = 'DUPLICATE',
  OcrFailure = 'OCR_FAILURE',
  Processing = 'PROCESSING',
  ProcessingError = 'PROCESSING_ERROR',
  Queued = 'QUEUED',
  UploadFailed = 'UPLOAD_FAILED',
  UploadFileTypeFailure = 'UPLOAD_FILETYPE_FAILURE',
  Uploading = 'UPLOADING',
}
