import { createSlice } from '@reduxjs/toolkit';

export type DocumentRestoreSlice = {
  columnOrder: string[] | undefined;
};

export const name = 'Restoration Hub';

export const initialState: DocumentRestoreSlice = {
  columnOrder: undefined,
};

export default createSlice({
  name,
  initialState,
  reducers: {
    setColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
  },
});
