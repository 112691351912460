export default `
<svg
  aria-hidden="true"
  aria-label="format-heading-2"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M17.623 5.0332H7.62305C6.49805 5.0332 5.62305 5.93945 5.62305 7.0332V17.0332C5.62305 18.1582 6.49805 19.0332 7.62305 19.0332H17.623C18.7168 19.0332 19.623 18.1582 19.623 17.0332V7.0332C19.623 5.93945 18.7168 5.0332 17.623 5.0332ZM18.123 17.0332C18.123 17.3145 17.873 17.5332 17.623 17.5332H7.62305C7.3418 17.5332 7.12305 17.3145 7.12305 17.0332V7.0332C7.12305 6.7832 7.3418 6.5332 7.62305 6.5332H17.623C17.873 6.5332 18.123 6.7832 18.123 7.0332V17.0332ZM14.873 14.5332H12.3105L14.2793 12.752C14.8105 12.252 15.0918 11.5332 15.0918 10.8145C15.0918 10.0645 14.8105 9.37695 14.2793 8.8457C13.1855 7.7832 11.4043 7.7832 10.3105 8.8457L9.99805 9.18945C9.68555 9.4707 9.68555 9.93945 9.9668 10.252C10.2793 10.5332 10.748 10.5645 11.0293 10.252L11.373 9.9082C11.873 9.4082 12.7168 9.4082 13.2168 9.9082C13.4668 10.1582 13.5918 10.4707 13.5918 10.8145C13.5918 11.127 13.4668 11.4395 13.248 11.6582L9.8418 14.752C9.62305 14.9395 9.56055 15.2832 9.6543 15.5645C9.7793 15.8457 10.0605 16.0332 10.373 16.0332H14.873C15.2793 16.0332 15.623 15.7207 15.623 15.2832C15.623 14.877 15.2793 14.5332 14.873 14.5332Z"
    fill="currentcolor"
  />
</svg>
`;
