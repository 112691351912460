export default `
<svg
  aria-hidden="true"
  aria-label="caret-right"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M14.707 12.703C15.1133 12.328 15.1133 11.6718 14.707 11.2968L10.707 7.29677C10.4258 7.01552 9.98828 6.92177 9.61328 7.07802C9.23828 7.23427 8.98828 7.57802 8.98828 7.98427V15.9843C8.98828 16.3905 9.23828 16.7655 9.61328 16.9218C9.98828 17.078 10.4258 16.9843 10.707 16.703L14.707 12.703Z"
    fill="currentcolor"
  />
</svg>
`;
