import React, { useCallback, useEffect } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { types } from '~/eds';
import { TableViewContextType } from '~/enums';
import { api } from '~/redux';
import { MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH } from '~/types/modal.types';
import { ERROR } from '~/types/toast.types';

interface Props {
  /** if undefined, the default context is Documents table. */
  context: TableViewContextType | undefined;
  modalType: string;
  hideModal: () => void;
  onViewSelected: (id: types.PilotId) => void;
}

const BaseLoadView = ({
  context,
  modalType,
  hideModal,
  onViewSelected,
}: Props) => {
  const [
    loadTableView,
    loadTableViewResult,
  ] = api.endpoints.loadTableView.useMutation();
  const {
    data: loadTableResponseData,
    isError: loadTableError,
    isSuccess: loadTableSuccess,
  } = loadTableViewResult;

  const [
    loadDefaultTableView,
    loadDefaultTableViewResult,
  ] = api.endpoints.loadDefaultTableView.useMutation();
  const {
    isError: loadDefaultTableViewError,
    isSuccess: loadDefaultTableViewSuccess,
    data: loadDefaultTableViewData,
  } = loadDefaultTableViewResult;

  // adds the context to segment.
  const segmentFeature = context ? context.toLowerCase() : 'documents page';

  useEffect(() => {
    if (loadTableSuccess && loadTableResponseData) {
      onViewSelected(loadTableResponseData.id);
      trackSegment(
        `New view selected for ${segmentFeature}`,
        loadTableResponseData,
      );
      hideModal();
    }
  }, [loadTableSuccess]);

  useEffect(() => {
    if (loadDefaultTableViewSuccess) {
      onViewSelected('DEFAULT');
      trackSegment(
        `Default view selected for ${segmentFeature}`,
        loadDefaultTableViewData,
      );
      hideModal();
    }
  }, [loadDefaultTableViewSuccess]);

  useEffect(() => {
    if (loadTableError) {
      showToast(ERROR, 'Error on loading view. Please try again.');
      hideModal();
    }
  }, [loadTableError]);

  useEffect(() => {
    if (loadDefaultTableViewError) {
      showToast(ERROR, 'Error on restoring default view. Please try again.');
      hideModal();
    }
  }, [loadDefaultTableViewError]);

  const handleViewSelected = useCallback((id: number) => {
    loadTableView({ context, id });
  }, []);

  const handleDefaultViewSelected = useCallback(() => {
    loadDefaultTableView({ context });
  }, []);

  if (modalType !== MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH) return null;

  return (
    <EcModal
      context={context}
      modalType={modalType}
      width="800px"
      title="Load View"
      hideModal={hideModal}
      handleApplyDefaultViewClick={handleDefaultViewSelected}
      handleApplyViewClick={handleViewSelected}
    />
  );
};

export default BaseLoadView;
