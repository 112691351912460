export enum HtmlEntityType {
  Bullet = '•',
  CloseQuotation = '”',
  Dash = '—',
  Ellipsis = '…',
  RightArrow = '→',
  OpenQuotation = '“',
  DoubleDash = '⸺',
  LeftArrow = '←',
  LeftRightArrow = '⇄',
  RightLeftArrow = '⇆',
}
