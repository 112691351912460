const styles = {
  provisionTextContainer: {
    maxHeight: '63vh',
  },
  provisionText: {
    marginTop: '12px',
    marginBottom: '12px',
    lineHeight: '24px',
  },
  provisionTitle: {
    alignSelf: 'flex-start',
    marginTop: '16px',
    fontWeight: '500', // TODO: update to body-medium once is ready
  },
};

export default styles;
