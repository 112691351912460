import { HtmlEntityType } from '~/eds';
import { AribaApiType, AribaSyncType, FileExtensionType } from '~/enums';

export const DEFAULT_FILE_TYPES = [
  FileExtensionType.Pdf,
  FileExtensionType.Docx,
  FileExtensionType.Doc,
];

export const CONTRACT_WORKSPACE_EVISORT_VIEW = 'ContractWorkspaceEvisortView';

export const API_VERSIONS = {
  [AribaApiType.EXTRACT]: ['v2'],
  [AribaApiType.DISCOVERY]: ['v1'],
  [AribaApiType.RETRIEVAL]: ['v1'],
};

export const API_NAMES = {
  [AribaApiType.EXTRACT]: 'ariba',
  [AribaApiType.DISCOVERY]: 'ariba_discovery',
  [AribaApiType.RETRIEVAL]: 'ariba_retrieval',
  [AribaApiType.REALM]: 'realm',
  [AribaApiType.REGION]: 'region',
};

export const SYNC_SYMBOL = {
  [AribaSyncType.BIDIRECTIONAL]: HtmlEntityType.RightLeftArrow,
  [AribaSyncType.INBOUND]: HtmlEntityType.LeftArrow,
  [AribaSyncType.OUTBOUND]: HtmlEntityType.RightArrow,
};
