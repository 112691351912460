import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { CountChip } from '../Chip';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

interface Props {
  /** Tab label */
  label: string;
  /** If the tab is focused */
  isFocused: boolean;
  /** If the tab is selected */
  isSelected: boolean;
  /** Count display */
  count?: number;
  /** If tab is disabled */
  disabled?: boolean;
  /** Tooltip content */
  tooltip?: string;
}

export const TabItem = memo(
  ({ count, disabled, isFocused, isSelected, label, tooltip }: Props) => {
    const styles = useStyles();

    return (
      <Layout
        h="header.height"
        spacing={2}
        styles={[styles.button.unset, styles.tabs.tab]}
        styleProps={{ isFocused, isSelected }}
      >
        <Tooltip tooltip={tooltip}>
          <>
            <Text disabled={disabled} variant="body-medium">
              {label}
            </Text>
            {count ? <CountChip count={count} /> : null}
          </>
        </Tooltip>
      </Layout>
    );
  },
);
