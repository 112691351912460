import React from 'react';

import { Box, Mention } from '../../../components';
import { ElementProps, MentionNode } from '../types';

export const MentionElement = ({
  children,
  element,
}: ElementProps<MentionNode>) => {
  const { text } = element.children[0];

  return (
    <Box as="span" contentEditable={false} styles={componentStyles.mention}>
      <Mention tooltip={element.tooltip} disabled={element.isInvalid}>
        {text}
        {children}
      </Mention>
    </Box>
  );
};

const componentStyles = {
  mention: {
    display: 'inline-block',
  },
};
