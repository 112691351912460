import React from 'react';
import { PlaceholderProps } from 'react-select';

import { Option } from '../../../types';
import { Icon } from '../../Icon';
import { Layout } from '../../Layout';
import { TruncateText } from '../../Text';

export const Placeholder = (props: PlaceholderProps<Option, boolean>) => {
  const { children, selectProps } = props;
  const { enableSearchIcon } = selectProps;
  return (
    <Layout align="center" color="text.quiet" spacing={1} variant="absolute.y">
      {enableSearchIcon && <Icon color="inherit" icon="search" />}
      <TruncateText color="inherit">{children}</TruncateText>
    </Layout>
  );
};
