import { SharedPanelProps } from '../../types';

export const getPanelPosition = ({
  mode,
  position,
}: {
  mode: SharedPanelProps['mode'];
  position: SharedPanelProps['position'];
}) => {
  let positionStyle = {};
  if (mode === 'floating') {
    positionStyle = {
      bottom: 3,
      right: 3,
      top: '76px',
    };
  } else if (position === 'right') {
    positionStyle = {
      bottom: 0,
      right: 0,
      top: '64px',
    };
  } else if (mode === 'fullscreen') {
    positionStyle = {
      top: 0,
      right: 0,
    };
  }

  return {
    position: 'fixed',
    ...positionStyle,
  };
};

export const basePanelWrapperStyle = {
  boxShadow: 'raised',
  zIndex: 'panel',
  borderRadius: 'm',
};
