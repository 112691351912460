import { pilot } from '../../services';

export const getAllDocumentGroups = () => pilot.get('group/');

export const createDocumentGroup = (data) => pilot.post('group/', data);

export const createDocumentGroupBySearch = (name, note, query) =>
  pilot.post('group/', { name, note, query });

export const createDocumentGroupByFolderId = (name, note, folderId) =>
  pilot.post('group/folder/', { name, note, folder: folderId });

export const editDocumentGroup = (groupId, data) =>
  pilot.patch(`group/${groupId}/`, data);

export const deleteDocumentGroup = (groupId) =>
  pilot.remove(`group/${groupId}/`);

export const getDocumentGroupDetails = (groupId) =>
  pilot.get(`group/${groupId}/`);

export const addDocumentsToGroup = (groupId, documentIds) =>
  pilot.post(`/group/${groupId}/documents/`, { documents: documentIds });

export const addDocumentsToGroupBySearch = (groupId, query) =>
  pilot.post(`/group/${groupId}/documents/`, { query });

export const addDocumentsToGroupByFolderId = (groupId, folderId) =>
  pilot.post(`/group/${groupId}/folder/`, { folder: folderId });

export const deleteDocumentFromGroup = (docGroupId) =>
  pilot.remove(`group/document/${docGroupId}/`);

export const editDocumentRelationshipGroup = (documentGroupId, data) =>
  pilot.patch(`group/document/${documentGroupId}/`, data);

export const linkParentChildDocumentsInGroup = (groupId, data) =>
  pilot.patch(`group/${groupId}/link-document/`, data);
