import {
  black1,
  black2,
  black3,
  bold,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

export const styles = {
  modalBody: {
    padding: '16px',
  },
  modalBodyTitle: {
    marginBottom: '24px',
    fontSize: '24px',
    fontWeight: bold,
  },
  modalBodyContent: {
    '& label': {
      display: 'inline-block',
      marginBottom: '12px',
      color: black1,
      fontSize: '12px',
      fontWeight: semibold,
    },
    '& input': {
      width: '100%',
      marginBottom: '16px',
      caretColor: evisortBlue,
      '&:hover': {
        borderColor: evisortBlue,
      },
      '&:focus': {
        borderColor: evisortBlue,
        outline: 'none',
      },
    },
  },
  passwordHelp: {
    display: 'inline-block',
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
      marginRight: '4px',
      marginBottom: '-5px',
    },
  },
  modalErrorMessage: {
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    marginTop: '24px',
    padding: '12px 16px',
    backgroundColor: 'rgba(243, 55, 55, 0.1)',
    color: black2,
    fontSize: '15px',
  },
  accessCopy: {
    margin: '15px 0',
    fontSize: '13px',
  },
  subHeader: {
    fontSize: '14px',
    fontWeight: 700,
  },
  departmentContainer: {
    marginTop: '20px',
  },
  departmentLabel: {
    color: black2,
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '12px',
  },
  noDeptMessage: {
    color: black3,
    fontSize: '13px',
  },
  selectedDepartmentName: {
    marginLeft: '5px',
    fontSize: '14px',
  },
  selectDepartmentCTA: {
    display: 'block',
    marginTop: '20px',
    backgroundColor: 'transparent',
    border: 'none',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      color: '#236d90',
    },
    '& svg': {
      position: 'relative',
      top: '3px',
    },
  },
  workflowAdminContainer: {
    marginTop: '10px',
  },
};
