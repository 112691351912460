import PropTypes from 'prop-types';
import React from 'react';

const InboxIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.color}
          fillRule="nonzero"
          d="M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2zm0 12h-3.13c-.47 0-.85.34-.98.8A3.006 3.006 0 0 1 12 18c-1.37 0-2.54-.93-2.89-2.2-.13-.46-.51-.8-.98-.8H5V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v9z"
        />
      </g>
    </svg>
  );
};

InboxIcon.defaultProps = {
  size: '24',
  color: '#000',
};

InboxIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default InboxIcon;
