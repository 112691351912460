import PT from 'prop-types';
import React from 'react';

import Button from '../Button';
import Card from '../Card';
import FlexLayout from '../FlexLayout';
import Switch from '../Switch';
import Text from '../Text';
import Preview from './Preview';

const ConfigurationCard = ({
  children,
  description,
  isEnabled = false,
  preview,
  title,
  onCancel,
  onSave,
  onToggleIsEnabled,
  onToggleCollapse,
}) => {
  const actionButtons = [];
  if (onCancel) {
    actionButtons.push(
      <Button key="cancel" text="Cancel" variant="ghost" onClick={onCancel} />,
    );
  }
  if (onSave) {
    actionButtons.push(<Button key="save" text="Save" onClick={onSave} />);
  }

  const footer = actionButtons.length > 0 && (
    <Card.Footer actionButtons={actionButtons} />
  );

  const header = (
    <Card.Header
      rightContent={<Switch value={isEnabled} onChange={onToggleIsEnabled} />}
      title={title}
    />
  );

  return (
    <Card footer={footer} header={header} onToggleCollapse={onToggleCollapse}>
      <FlexLayout flexDirection="column" space="x6">
        {description && <Text color="gray-800">{description}</Text>}
        <FlexLayout space="x6">
          {children}
          {preview && <Preview>{preview}</Preview>}
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};

ConfigurationCard.propTypes = {
  /** Card content */
  children: PT.node.isRequired,
  /** Description of the card */
  description: PT.string,
  /** Enables the configuration */
  isEnabled: PT.bool,
  /** React node for the preview section */
  preview: PT.node,
  /** Card title */
  title: PT.string.isRequired,
  /** Callback when the configuration is cancelled */
  onCancel: PT.func,
  /** Callback when the configuration is saved */
  onSave: PT.func,
  /** Callback to toggle enabling of configuration */
  onToggleIsEnabled: PT.func.isRequired,
  /** Callback to toggle card collapse */
  onToggleCollapse: PT.func,
};

export default ConfigurationCard;
