import { useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid';

import { Box, Layout, LoadingShimmer, Text } from '~/eds';
import { HighlightSources } from '~/features/document';
import { Highlight } from '~/features/document-viewer';
import { actions } from '~/redux';
import { Nullable, Uuid } from '~/types';

import { INFO_LABEL_FLEX, INFO_SPACING } from '../../constants';

interface Props {
  documentVersionId: Nullable<number>;
  highlights?: Highlight[];
  isLoading?: boolean;
}

export const ReviewHighlightSources = ({
  documentVersionId,
  highlights,
  isLoading,
}: Props) => {
  const dispatch = useDispatch();
  const [
    activeHighlightSourceId,
    setActiveHighlightSourceId,
  ] = useState<Uuid>();

  const handleSetActiveHighlightSourceId = (highlight: Highlight) => {
    setActiveHighlightSourceId(highlight.id);
    dispatch(
      actions.setFieldAiDocumentViewer({
        activeHighlightId: highlight.id,
        documentVersionId,
        highlights: [highlight],
        invalidateActiveHighlightId: uuid.v4(),
      }),
    );
  };

  return (
    <Layout align="center" spacing={INFO_SPACING}>
      <Text flex={INFO_LABEL_FLEX} variant="body-bold">
        Source(s)
      </Text>
      {isLoading ? (
        <LoadingShimmer height="14px" />
      ) : (
        <Box py={INFO_SPACING}>
          <HighlightSources
            highlights={highlights ?? []}
            activeHighlightId={activeHighlightSourceId}
            onHighlightSourceClick={handleSetActiveHighlightSourceId}
          />
        </Box>
      )}
    </Layout>
  );
};
