import { createSlice } from '@reduxjs/toolkit';

import { DocumentRestoreSlice } from './restorationHub';

export const name = 'My Deleted Items';

export const initialState: DocumentRestoreSlice = {
  columnOrder: undefined,
};

export default createSlice({
  name,
  initialState,
  reducers: {
    setColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
  },
});
