import PropTypes from 'prop-types';
import React from 'react';

const DownloadIcon = (props) => {
  const { color, size, white, opacity } = props;

  let fillColor = color;

  if (white) fillColor = '#fff';
  if (color) fillColor = color;

  return (
    <svg width={size} height={size} viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          fill={fillColor}
          fillOpacity={opacity}
          fillRule="nonzero"
          d="M16.125 8.367A6.242 6.242 0 0 0 10 3.333 6.246 6.246 0 0 0 4.458 6.7 4.995 4.995 0 0 0 0 11.667c0 2.758 2.242 5 5 5h10.833c2.3 0 4.167-1.867 4.167-4.167 0-2.2-1.708-3.983-3.875-4.133zm-1.958 2.466L10 15l-4.167-4.167h2.5V7.5h3.334v3.333h2.5z"
        />
      </g>
    </svg>
  );
};

DownloadIcon.defaultProps = {
  color: '#000',
  size: '20',
  opacity: '1',
};

DownloadIcon.propTypes = {
  white: PropTypes.bool,
  color: PropTypes.string,
  opacity: PropTypes.string,
};

export default DownloadIcon;
