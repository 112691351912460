import { isEqual, unionBy } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';

import { TreeNode, UserAction } from '../../types';
import { Actions } from '../Actions';
import { ActionsMenu } from '../ActionsMenu';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Layout } from '../Layout';
import { Link } from '../Link';
import { Text, TruncateText } from '../Text';
import { hoverNodes, selectNodes, toggleNodeIsExpanded } from './utils';

interface Props {
  emptyContent: React.ReactNode;
  title: string;
  tree: TreeNode[];
  headerActions?: UserAction[];
  selectedNodeKey?: string;
  onUpdate?: (treeData: TreeNode[]) => void;
  /** Optional accesibility name to define the current element */
  name?: string;
}

export const Tree = ({
  emptyContent,
  title,
  tree,
  headerActions = [],
  selectedNodeKey,
  onUpdate = (_treeData: TreeNode[]) => {},
  name,
}: Props) => {
  const [nodes, setNodes] = useState(tree);

  useEffect(() => {
    if (!isEqual(tree, nodes)) {
      setNodes(tree);
    }
  }, [tree]);

  const updateNodes = (updatedNodes: TreeNode[]) => {
    const mergedNodes = unionBy(updatedNodes, nodes, 'key');
    onUpdate(mergedNodes);
  };

  const setNodeAndChildrenSelected = (currentNode: TreeNode) => {
    const updatedNodes = selectNodes(nodes, currentNode);
    updateNodes(updatedNodes);
  };

  const setParentAndSiblingsSelected = (currentNode: TreeNode) => {
    const parent = nodes.find((node) => node.key === currentNode.parentKey);
    setNodeAndChildrenSelected(parent as TreeNode);
  };

  const selectNode = (node: TreeNode) => {
    node.parentKey
      ? setParentAndSiblingsSelected(node)
      : setNodeAndChildrenSelected(node);
  };

  useEffect(() => {
    if (selectedNodeKey) {
      const selectedNode = nodes.find((node) => node.key === selectedNodeKey);
      if (selectedNode) {
        selectNode(selectedNode);
      }
    }
  }, [selectedNodeKey]);

  const setNodeIsExpanded = (currentNode: TreeNode) => {
    const updatedNodes = toggleNodeIsExpanded(nodes, currentNode);
    updateNodes(updatedNodes);
  };

  // TODO: break into individual components and remove render*
  const renderExpandCollapseIcon = (node: TreeNode) => (
    // TODO: create icon preset for expand/collapse
    <IconButton
      icon={node.isExpanded ? 'caret-down' : 'caret-right'}
      text={node.isExpanded ? 'Collapse' : 'Expand'}
      size="s"
      onClick={() => setNodeIsExpanded(node)}
    />
  );

  const hoverNode = (currentNode: TreeNode, isHovered: boolean) => {
    const updatedNodes = hoverNodes(nodes, currentNode, isHovered);
    updateNodes(updatedNodes);
  };

  const renderNode = (node: TreeNode) => {
    const childCount = node.children.length;
    const handleMouseEnter = () => {
      hoverNode(node, true);
    };
    const handleMouseLeave = () => {
      hoverNode(node, false);
    };
    return (
      <Layout
        key={node.key}
        aria-label={node.name}
        role="treeitem"
        align="center"
        justify="space-between"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        styles={componentStyles.row}
        styleProps={{ childCount, isHovered: node.isHovered }}
      >
        {node.isSelected && (
          <Box
            styles={componentStyles.selected}
            styleProps={{
              isSelected: node.isSelected,
              parentKey: node.parentKey,
            }}
          />
        )}
        <Layout
          align="center"
          spacing={1}
          styles={componentStyles.indentRow}
          styleProps={{ childCount, parentKey: node.parentKey }}
        >
          {Boolean(childCount) && renderExpandCollapseIcon(node)}
          <Box styles={componentStyles.nodeName}>
            <Link
              color="text.primary"
              variant={node.isSelected ? 'body-medium' : 'body'}
              pathname={node.pathname}
              hash={node.hash}
            >
              <TruncateText>{node.name}</TruncateText>
            </Link>
          </Box>
        </Layout>
        <Layout align="center" spacing={1}>
          {Boolean(node.actions?.length) && node.isHovered && (
            <Actions actions={node.actions ?? []} />
          )}
          {Boolean(node.moreActions?.length) && node.isHovered && (
            <ActionsMenu
              name="more-actions"
              icon="more"
              size="s"
              enablePortal={true}
              actions={node.moreActions ?? []}
            />
          )}
          <Box role="status" w={24} align="center">
            {node.status && (
              <Icon
                color={node.status.color}
                icon={node.status.icon}
                label={node.status.label}
                tooltip={node.status.tooltip}
              />
            )}
            {!node.status && (
              <Text align="center" preset="help">
                {childCount}
              </Text>
            )}
          </Box>
        </Layout>
      </Layout>
    );
  };

  const renderChildren = (children: TreeNode[]) => children.map(renderNode);

  const content = nodes.map((node, index) => (
    <Fragment key={index}>
      {renderNode(node)}
      {Boolean(node.children.length) &&
        node.isExpanded &&
        renderChildren(node.children)}
    </Fragment>
  ));

  const header = (
    <Layout
      as="header"
      align="center"
      justify="space-between"
      styles={componentStyles.header}
    >
      <TruncateText role="subheading" variant="subtitle">
        {title}
      </TruncateText>
      <Layout align="center" spacing={1}>
        {Boolean(headerActions.length) && <Actions actions={headerActions} />}
        <Box role="status" w={24} align="center">
          <Text align="center" preset="help">
            {nodes.length}
          </Text>
        </Box>
      </Layout>
    </Layout>
  );

  return (
    <Layout aria-label={name} direction="column" styles={componentStyles.panel}>
      {header}
      {Boolean(nodes.length) ? (
        <Layout role="tree" direction="column" styles={componentStyles.content}>
          {content}
        </Layout>
      ) : (
        <>{emptyContent}</>
      )}
    </Layout>
  );
};

const componentStyles = {
  panel: {
    width: '100%',
    height: '100%',
  },
  nodeName: {
    flex: 'auto',
    minWidth: 0,
    '> *': {
      display: 'block',
      width: '100%',
      height: 'height.m',
      '> * > *': {
        lineHeight: '40px !important',
      },
    },
  },
  header: {
    height: 'header.height',
    borderBottom: 'border.divider',
    flex: 'none',
    paddingLeft: 6,
    paddingRight: 4,
  },
  content: {
    overflowY: 'auto',
    width: '100%',
  },
  fillWidth: {
    width: '100%',
    maxWidth: '100%',
    minWidth: 0,
  },
  row: ({ childCount }: { childCount?: number; isHovered?: boolean }) => ({
    height: 'height.m',
    padding: 4,
    paddingLeft: childCount ? 4 : 6,
    width: '100%',
    maxWidth: '100%',
    minWidth: 0,
    position: 'relative',
  }),
  selected: ({
    isSelected,
    parentKey,
  }: {
    isSelected?: boolean;
    parentKey?: string;
  }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 'auto',
    width: 8,
    backgroundColor: isSelected
      ? parentKey
        ? 'status.active.secondary'
        : 'status.active'
      : '',
  }),
  indentRow: ({
    childCount,
    parentKey,
  }: {
    childCount?: number;
    parentKey?: string;
  }) => ({
    paddingLeft: parentKey ? 12 : childCount ? 0 : 6,
    width: '100%',
    maxWidth: '100%',
    minWidth: 0,
  }),
};
