const styles = {
  bodyContent: {
    width: (props) => (props.width ? props.width : 'auto'),
    maxHeight: '70vh',
    overflow: 'auto',
    padding: '16px',
  },
  modalButtonActions: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: '10px',
    },
  },
};

export default styles;
