import { PromptModelTestCase } from '~/features/x-ray';
import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { PromptModelTestCase as PromptModelTestCaseResponse } from '../../types/sylvanus/model';
import { mapTestCase } from './transformers';

interface Request {
  modelId: Uuid;
  testcaseNumber: number;
  version: number;
}

type Response = PromptModelTestCaseResponse;

export const getPromptModelTestCase = async ({
  modelId,
  testcaseNumber,
  version,
}: Request): Promise<PromptModelTestCase> => {
  const response: Response = await sylvanus.get(
    `/model/${modelId}/version/${version}/testcase/${testcaseNumber}`,
  );

  return mapTestCase(response);
};
