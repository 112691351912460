import { ChipPreset, IconType, StatusType } from '../../types';

export interface Preset {
  /** CSS background-color  */
  bg?: string;
  /** Renders a system icon */
  icon?: IconType;
  /** Chip Status */
  status?: StatusType;
  /** Chip text */
  text?: string;
}

export const presets: Record<ChipPreset, Preset> = {
  ai: {
    icon: 'ai',
    status: 'ai',
  },
  beta: {
    status: 'new',
    text: 'BETA',
  },
  error: {
    icon: 'status-danger',
    status: 'danger',
  },
  inactive: {
    icon: 'status-inactive',
    status: 'inactive',
  },
  info: {
    status: 'info',
  },
  loading: {
    icon: 'loading',
    status: 'info',
  },
  new: {
    icon: 'status-new',
    status: 'new',
    text: 'NEW',
  },
  pending: {
    icon: 'status-pending',
    status: 'warning',
  },
  removable: {
    status: 'info',
  },
  success: {
    icon: 'status-success',
    status: 'success',
  },
};
