import * as Sentry from '@sentry/react';

import { isDev } from '~/dev';

export const captureException = (
  message: string,
  error: any,
  tags: Record<string, string>,
) => {
  if (!isDev) {
    Sentry.captureException(new Error(message), {
      tags: tags,
      extra: { error },
    });
  }
};

export const getClientName = (pathname: string) => {
  const clientRegex = /^\/(\w+)/;
  const client = pathname.match(clientRegex)?.[1];
  if (!client || client === '/') return null;
  return client;
};

export const formatPathname = (pathname: string) => {
  // gets the frist
  const clientRegex = /^\/(\w+)/;
  const uuidRegex = /\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g;
  const digitsRegex = /\/\d+/g;
  const result = pathname
    .replace(clientRegex, '/<sub_domain>')
    .replace(uuidRegex, '/<uuid>')
    .replace(digitsRegex, '/<digits>');
  return result;
};
