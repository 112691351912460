import React, { memo } from 'react';

import { SharedToastProps } from '../../types';
import { Box } from '../Box';
import { StatusMessage } from '../StatusMessage';

export const Toast = memo((props: SharedToastProps) => {
  return (
    <Box w="toast.width">
      <StatusMessage {...props} />
    </Box>
  );
});
