import PT from 'prop-types';
import React from 'react';

import { withTooltip } from '../../hocs/withTooltip';
import FlexLayout from '../FlexLayout';
import Icon from '../Icon';
import Text from '../Text';

const styles = {
  info: {
    bg: 'gray-200',
    color: 'gray-600',
  },
  success: {
    bg: 'green-100',
    color: 'green-400',
  },
  warning: {
    bg: 'yellow-100',
    color: 'peach-850',
  },
  danger: {
    bg: 'red-100',
    color: 'red-600',
  },
  white: {
    bg: 'white',
    border: 'border',
    color: 'gray-700',
  },
};

function Chip({
  isInverted = false,
  isLoading = false,
  text,
  variant = 'info',
}) {
  const style = styles[variant] || styles.info;

  const invertedStyle = {
    border: 'none',
    bg: 'none',
  };

  let icon;
  if (isLoading) {
    icon = 'spinner';
  } else if (isInverted) {
    icon = 'dotLarge';
  }

  return (
    <FlexLayout
      alignItems="center"
      flex="none"
      px={3}
      py="6px"
      space={1}
      sx={{
        ...style,
        ...(isInverted ? invertedStyle : {}),
        borderRadius: 'm',
        display: 'inline-block',
        flex: 'none',
      }}
    >
      <Text variant="2xs-dense-bold">{text}</Text>
      {icon && <Icon icon={icon} size="xs" />}
    </FlexLayout>
  );
}

const propTypes = {
  /** If true, will invert the visuals of the chip */
  isInverted: PT.bool,
  /** If true, will render a loading indicator */
  isLoading: PT.bool,
  /** Content of the chip, should be a short string */
  text: PT.string.isRequired,
  /** Stylistic variant of chip */
  variant: PT.oneOf(['info', 'success', 'warning', 'danger', 'white']),
  /** Tooltip */
  tooltip: PT.string,
};

Chip.propTypes = propTypes;

export const chipPropType = PT.shape(propTypes);

export const DOCS__Chip = Chip;

export default withTooltip(Chip);
