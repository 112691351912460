export default `
<svg
  aria-hidden="true"
  aria-label="dash"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M20 12C20 12.4375 19.6562 12.75 19.25 12.75H4.75C4.3125 12.75 4 12.4375 4 12C4 11.5938 4.3125 11.25 4.75 11.25H19.25C19.6562 11.25 20 11.5938 20 12Z"
    fill="currentcolor"
  />
</svg>
`;
