import React from 'react';

import { DateText, Layout, User } from '../../../components/';
import {
  DateFormat,
  PilotId,
  RequireOnlyOne,
  SharedUserProps,
  UserMode,
  User as UserType,
} from '../../../types';

/**
 * Accepts either the intended `SharedUserProps`, or an `asyncUser` prop that
 * - requires an user `id` to be specified
 * - a `render` component that implements the `SharedUserProps` interface including the `id` prop.
 */
type Props<M extends UserMode = 'avatar'> = RequireOnlyOne<{
  asyncUser: {
    id: PilotId;
    render: React.ComponentType<
      SharedUserProps<M> & {
        id: PilotId;
      }
    >;
  };
  user: UserType;
  /** JS Date */
  datetime?: Date;
  /** Date format */
  format?: DateFormat;
}> &
  SharedUserProps<M>;

export const UserDatetimeCell = ({
  asyncUser,
  mode = 'avatar',
  options,
  user,
  datetime,
  format,
}: Props) => {
  const getUser = () => {
    if (asyncUser) {
      const { id, render: AsyncUserComponent } = asyncUser;
      return <AsyncUserComponent id={id} options={options} mode={mode} />;
    }

    return <User user={user} mode={mode} options={options} />;
  };

  return (
    <Layout direction="column">
      {getUser()}
      <DateText date={datetime} format={format} />
    </Layout>
  );
};
