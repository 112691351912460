import { DataFieldSection } from '~/features/fields/types';
import { pilot } from '~/services';
import { Nullable, PilotId } from '~/types';

interface Request {
  limit?: number;
}

interface Response {
  count: number;
  next: string;
  previous: Nullable<string>;
  results: {
    field_ids: PilotId[];
    id: PilotId;
    label: string;
  }[];
}

export const getFieldSections = async ({
  limit,
}: Request): Promise<{
  count: number;
  results: DataFieldSection[];
}> => {
  const params = { limit };

  const response: Response = await pilot.get('/field-section/', {
    params,
  });

  return {
    count: response.count,
    results: response.results.map((result) => ({
      fieldIds: result.field_ids,
      id: result.id,
      label: result.label,
    })),
  };
};
