/**
 * The utilities in this file support derived data dealing with `ClauseValue.text_search` interfaces (which are temporarily represented and expected to be improved in the future).
 */
import {
  ClauseOperatorId,
  Option,
  TextSearch,
  TextSearchContains,
  TextSearchScope,
} from '../types';

export const toTextSearchPairLookup: Record<
  ClauseOperatorId,
  [TextSearchContains, TextSearchScope]
> = {
  contains_all_of_these_words: [
    { value: 'contains', label: 'contains' },
    { value: 'all_of_these_words', label: 'all of these words' },
  ],
  contains_any_of_these_words: [
    { value: 'contains', label: 'contains' },
    { value: 'any_of_these_words', label: 'any of these words' },
  ],
  contains_exact_phrase: [
    { value: 'contains', label: 'contains' },
    { value: 'exact_phrase', label: 'exact phrase' },
  ],
  does_not_contain_all_of_these_words: [
    { value: 'does_not_contain', label: 'does not contain' },
    { value: 'all_of_these_words', label: 'all of these words' },
  ],
  does_not_contain_any_of_these_words: [
    { value: 'does_not_contain', label: 'does not contain' },
    { value: 'any_of_these_words', label: 'any of these words' },
  ],
  does_not_contain_exact_phrase: [
    { value: 'does_not_contain', label: 'does not contain' },
    { value: 'exact_phrase', label: 'exact phrase' },
  ],
};

export const toTextSearchOptionLookup: Record<
  string,
  Record<string, Option<ClauseOperatorId>>
> = {
  contains: {
    all_of_these_words: {
      value: 'contains_all_of_these_words',
      label: 'contains all of these words',
    },
    any_of_these_words: {
      value: 'contains_any_of_these_words',
      label: 'contains any of these words',
    },
    exact_phrase: {
      value: 'contains_exact_phrase',
      label: 'contains exact phrase',
    },
  },
  does_not_contain: {
    all_of_these_words: {
      value: 'does_not_contain_all_of_these_words',
      label: 'does not contain all of these words',
    },
    any_of_these_words: {
      value: 'does_not_contain_any_of_these_words',
      label: 'does not contain any of these words',
    },
    exact_phrase: {
      value: 'does_not_contain_exact_phrase',
      label: 'does not contain exact phrase',
    },
  },
};

export const toTextSearchOption = (
  textSearch: TextSearch,
): Option<ClauseOperatorId> => {
  const { contains, scope } = textSearch;
  return toTextSearchOptionLookup[contains.value][scope.value];
};

export const toTextSearchPair = (
  ClauseOperatorId: ClauseOperatorId,
): [TextSearchContains, TextSearchScope] => {
  return toTextSearchPairLookup[ClauseOperatorId];
};
