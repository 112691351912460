import { noop } from 'lodash';
import React, { memo } from 'react';

import { CardSelect, FormField, Image, Layout, Select } from '~/eds';

import { DataFieldType } from '../enums';
import { FieldConfig } from './types';
import { testEnableSort } from './utils';

interface Props {
  config: FieldConfig;
  fieldType: DataFieldType;
  onChange: (updatedConfig: FieldConfig) => void;
}

export const FieldConfiguration = memo(
  ({ config, fieldType, onChange }: Props) => {
    const { enableMultiple, optionsConfig, optionValues } = config;
    switch (fieldType) {
      case DataFieldType.ARRAY_MULTIPLE: {
        return (
          <Layout preset="form-fields">
            <FormField<boolean, false>
              label="Field Settings"
              name="field settings"
              input={CardSelect}
              inputProps={{
                options: [
                  {
                    content: (
                      <Image
                        alt="single select preview"
                        image="preview-field-single-select"
                      />
                    ),
                    description:
                      'Use this when only a single value should be selected.',
                    label: 'Single Select',
                    value: false,
                  },
                  {
                    content: (
                      <Image
                        alt="multiple select preview"
                        image="preview-field-multiple-select"
                      />
                    ),
                    description:
                      'Use this when one or more values can be selected.',
                    label: 'Multiple Select',
                    value: true,
                  },
                ],
              }}
              value={enableMultiple}
              onChange={(updatedEnableMultiple) =>
                onChange({
                  ...config,
                  enableMultiple: updatedEnableMultiple ?? false,
                })
              }
            />
            <FormField<string, true>
              description="AI generated options will be added if field is added to a field model."
              label="Field Option(s)"
              name="field-options"
              input={Select}
              inputProps={{
                enableSelectAll: false,
                enableSorting: testEnableSort(optionsConfig),
                isClearable: false,
                isMulti: true,
                noOptionsMessage: 'Type to create a new option',
                options: optionValues.map((value) => ({
                  label: value,
                  value,
                })),
                onAddOption: noop,
              }}
              placeholder="Create or select an option"
              value={optionValues}
              onChange={(updatedOptionValues) =>
                onChange({
                  ...config,
                  optionValues: updatedOptionValues ?? [],
                })
              }
            />
            <CardSelect<'enable_add' | 'enable_sort', true>
              isMulti={true}
              name="configure field options"
              options={[
                {
                  label: 'Allow users to add their own options',
                  description:
                    'Any user in the platform can create values for this field.',
                  value: 'enable_add',
                },
                {
                  label: 'Sort options alphabetically',
                  description:
                    'Enable to sort options alphabetically, otherwise keep the original options order.',
                  value: 'enable_sort',
                },
              ]}
              value={optionsConfig}
              onChange={(updatedOptionsConfig) =>
                onChange({
                  ...config,
                  optionsConfig: updatedOptionsConfig ?? [],
                })
              }
            />
          </Layout>
        );
      }
      default:
        return null;
    }
  },
);
