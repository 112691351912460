import React from 'react';

import UserDepartmentSelect from '~/components/Shared/UserDepartmentSelect';
import { FormField, Layout, Select } from '~/eds';
import { EntityType, SaveSearchPermissionType } from '~/enums';
import { useCurrentUser } from '~/hooks';
import { Nullable } from '~/types';

import { EntityVisibilityType } from './types';
import {
  getSaveSearchPermissionOptions,
  getUserDepartmentArray,
} from './utils';

type UserDepartmentSelectValue = {
  id: number;
  type: EntityType;
};

interface Props {
  label: string;
  value: EntityVisibilityType;
  onChange: (entityVisibility: EntityVisibilityType) => void;
  enablePortal?: boolean;
}

export function EntityVisibilitySelect({
  label,
  value,
  onChange,
  enablePortal = true,
}: Props) {
  const currentUser = useCurrentUser();
  const { visibility } = value;

  const onChangeVisibility = (
    updatedSelectValue: Nullable<
      SaveSearchPermissionType | SaveSearchPermissionType[]
    >,
  ) => {
    let payload;
    if (updatedSelectValue !== SaveSearchPermissionType.Shared) {
      payload = {
        visibility: updatedSelectValue as SaveSearchPermissionType,
      };
    } else {
      payload = {
        ...value,
        visibility: updatedSelectValue as SaveSearchPermissionType,
      };
    }
    onChange(payload);
  };

  const onUserDepartmentChange = (values: UserDepartmentSelectValue[]) => {
    onChange({
      ...value,
      userIds: values.length
        ? values
            .filter((item) => item.type === EntityType.User)
            .map((item) => item.id)
        : undefined,
      departmentIds: values.length
        ? values
            .filter((item) => item.type === EntityType.Department)
            .map((item) => item.id)
        : undefined,
    });
  };

  return (
    <Layout direction="column" spacing={4}>
      <FormField
        name="visibility-select"
        label={label}
        input={Select}
        onChange={onChangeVisibility}
        value={visibility}
        inputProps={{
          options: getSaveSearchPermissionOptions(currentUser.client_name),
          enablePortal: enablePortal,
          isSearchable: false,
          isClearable: false,
        }}
      />
      {visibility === SaveSearchPermissionType.Shared && (
        <UserDepartmentSelect
          enablePortal={enablePortal}
          value={getUserDepartmentArray(value)}
          onChange={onUserDepartmentChange}
        />
      )}
    </Layout>
  );
}
