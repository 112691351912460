import { debounce, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';

import { Button } from '~/eds';
import { FlagType, useFlag } from '~/flags';

import stylesFactory from './EcMultiSelect.styles';

const MIN_INPUT_LENGTH = 3;
const SELECT_ALL_OPTION = { label: 'Select All', value: '*', bold: true };
const ADDITIONAL_DISPLAY_SIZE = 6;

const Input = (props) => {
  const ariaExpanded = props.selectProps.menuIsOpen;
  const label = props['aria-label'];
  return (
    <components.Input
      {...props}
      aria-label={`${label} combo box ${
        ariaExpanded ? 'expanded' : 'collapsed'
      }`}
    />
  );
};

const MenuList = (props) => {
  const { selectProps } = props;
  const { onShowMore } = selectProps;

  const menuRef = useRef();

  const handleShowMore = () => {
    // setTimeout is required to give DOM a chance to update its height
    const prevScrollHeight = menuRef.current.parentNode.scrollHeight;
    setTimeout(() => {
      menuRef.current.parentNode.scrollTop = prevScrollHeight;
    }, 0);
    onShowMore();
  };

  return (
    <>
      <components.MenuList {...props}>
        <div ref={menuRef}>{props.children}</div>
      </components.MenuList>
      {onShowMore && (
        <Button
          isFullWidth
          onClick={handleShowMore}
          variant="action"
          text="Show more"
        />
      )}
    </>
  );
};

const EcMultiSelect = ({
  allowSelectAll = false,
  disableSelectAll = false,
  error = false,
  enableDocumentBodyMenuPortal = false,
  id,
  isCreatable = false,
  isDisabled = false,
  menuPosition,
  noOptionsMessage,
  openMenuOnClick = true,
  options = [],
  placeholder,
  useMinInputLength = false,
  value,
  width,
  getOptionLabel,
  getOptionValue,
  onChange,
  onBlur,
  debounceTimeout = undefined,
  onInputChange = noop,
  ...rest
}) => {
  const isHideLegacyMultiSelectShowMoreEnabled = useFlag(
    FlagType.HideLegacyMultiSelectShowMore,
  );
  const [inputValue, setInputValue] = useState('');
  const [displaySize, setDisplaySize] = useState(ADDITIONAL_DISPLAY_SIZE);
  const increaseDisplaySize = () =>
    setDisplaySize(displaySize + ADDITIONAL_DISPLAY_SIZE);

  const handleInputChage = (val) => {
    setInputValue(val);
    if (!isHideLegacyMultiSelectShowMoreEnabled) {
      setDisplaySize(ADDITIONAL_DISPLAY_SIZE);
    }
    handleInputChange(val);
  };
  const debouncedSetInputValue = useCallback(
    debounce(handleInputChage, 100),
    [],
  );
  const handleInputChange = useCallback(
    debounceTimeout ? debounce(onInputChange, debounceTimeout) : onInputChange,
    [],
  );

  const filteredOptions = useMemo(() => {
    if (!inputValue && !value) return options;

    if (useMinInputLength && inputValue.length < MIN_INPUT_LENGTH) {
      return [];
    }

    return options.filter(
      (option) =>
        option.label?.toLowerCase().includes(inputValue.toLowerCase()) &&
        !value.find((val) => val.value === option.value),
    );
  }, [inputValue, options, value, useMinInputLength]);

  const slicedOptions = useMemo(() => {
    if (!isHideLegacyMultiSelectShowMoreEnabled) {
      return filteredOptions.slice(0, displaySize);
    } else {
      return filteredOptions;
    }
  }, [filteredOptions, displaySize]);

  const handleChange = (values, { option: selectedOption }) => {
    let updatedValues = values;
    if (selectedOption?.value === SELECT_ALL_OPTION.value) {
      // REMOVE SELECT_ALL FROM VALUE
      updatedValues.pop();
      updatedValues = [...updatedValues, ...filteredOptions];
    }
    onChange(updatedValues);
  };

  // We want to disable selectAll when there are more than 30 options And FF is on due to DB limitation
  const displaySelectAll =
    allowSelectAll && (!disableSelectAll || slicedOptions.length <= 30);
  const customOptions = () => {
    return displaySelectAll
      ? [SELECT_ALL_OPTION, ...slicedOptions]
      : slicedOptions;
  };

  const customFilterOptions = (option) => {
    // no need to filter anything again
    if (filteredOptions.length) {
      return true;
    }

    // when isCreatable={true}, the lib will append a special option
    // in case user enters a value that filters out all existing options
    // we still want to display this special option
    if (option.data.__isNew__) {
      return true;
    }
  };

  const customNoOptionsMessage = () => {
    return useMinInputLength && inputValue.length < MIN_INPUT_LENGTH
      ? `Type in at least ${MIN_INPUT_LENGTH} letters...`
      : noOptionsMessage;
  };

  const SelectComponent = isCreatable ? Creatable : Select;

  const hasMore =
    !isHideLegacyMultiSelectShowMoreEnabled &&
    displaySize < filteredOptions.length;

  return (
    <SelectComponent
      {...rest}
      backspaceRemovesValue={false}
      components={{ DropdownIndicator: null, Input, MenuList }}
      value={value}
      options={customOptions()}
      filterOption={customFilterOptions}
      onBlur={onBlur}
      onChange={handleChange}
      onInputChange={debouncedSetInputValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      placeholder={placeholder}
      menuPortalTarget={
        enableDocumentBodyMenuPortal ? document.body : undefined
      }
      noOptionsMessage={customNoOptionsMessage}
      width={width}
      styles={stylesFactory(error)}
      id={id}
      isMulti
      isDisabled={isDisabled}
      openMenuOnClick={openMenuOnClick}
      menuPosition={menuPosition}
      onShowMore={hasMore ? increaseDisplaySize : undefined}
    />
  );
};

EcMultiSelect.propTypes = {
  allowSelectAll: PropTypes.bool,
  disableSelectAll: PropTypes.bool,
  error: PropTypes.bool,
  enableDocumentBodyMenuPortal: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  menuPosition: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  openMenuOnClick: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  useMinInputLength: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  width: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
};

export default EcMultiSelect;
