import { Endpoint } from '~/features/webhooks';
import { ReactivateApiResponse } from '~/redux/api/types/courier/endpoints';
import { courier } from '~/services';

interface Request {
  endpoint: Endpoint;
}

export const activateEndpoint = async ({
  endpoint,
}: Request): Promise<boolean> => {
  const response: ReactivateApiResponse = await courier.patch(
    `/endpoints/${endpoint.id}/reactivate`,
  );

  return Boolean(response);
};
