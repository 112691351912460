export const presets = {
  'page-access': {
    description:
      'You do not have permission to access this page. To request permission, contact your organization administrator.',
    image: 'error-page-access' as const,
    imageAlt: 'page access error',
    resolveActionText: 'Take me home',
    title: 'No Access to Page',
  },
  'page-error': {
    description:
      'There was an error with our system. Please return to Home to access Evisort.',
    image: 'error-page-error' as const,
    imageAlt: 'page error',
    resolveActionText: 'Take me home',
    title: 'Page Error',
  },
  'page-not-found': {
    description:
      'We could not find this page. Please return to Home to access Evisort.',
    image: 'error-page-not-found' as const,
    imageAlt: 'page not found error',
    resolveActionText: 'Take me home',
    title: 'Page Not Found',
  },
  'sso-access': {
    description:
      "You don't have access to the system. Please contact your access administrator to request access to Evisort.",
    image: 'error-sso-access' as const,
    imageAlt: 'sso access error',
    resolveActionText: null,
    title: 'No SSO Access to System',
  },
  'service-unavailable': {
    description: 'Please retry later.',
    image: 'error-service-unavailable' as const,
    imageAlt: 'service unavailable',
    resolveActionText: null,
    title: 'Service is temporarily unavailable.',
  },
  'system-access': {
    description:
      "You don't have access to the system. Please sign in with the proper credentials to use Evisort. If you feel you should access to Evisort, contact customer support.",
    image: 'error-system-access' as const,
    imageAlt: 'system access error',
    resolveActionText: 'Sign In',
    title: 'No Access to System',
  },
};
