import {
  black1,
  black2,
  black4,
  black5,
  bold,
  evisortBlue,
  heavy,
} from '../../../assets/shared-styles/general';

const styles = {
  filtersHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '32px',
  },
  filtersTitle: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    color: black2,
    fontSize: '16px',
    fontWeight: heavy,
  },
  filtersCounter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '17px',
    marginLeft: '8px',
    borderRadius: '10.5px',
    backgroundColor: black5,
    color: black1,
    fontSize: '11px',
    fontWeight: bold,
  },
  filtersEdit: {
    display: 'flex',
    alignItems: 'center',
    color: evisortBlue,
    textDecoration: 'none',
    fontSize: '13px',
    fontWeight: bold,

    '& svg': {
      marginRight: '4px',
    },
  },
  filtersEmptyState: {
    color: black4,
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: 1.5,
  },
  filtersBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterOperator: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0',
  },
  operatorText: {
    margin: '0 8px',
    color: black4,
    fontSize: '12px',
    fontWeight: bold,
  },
  operatorSideline: {
    flexGrow: 1,
    minWidth: '1px',
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  filterItem: {
    color: black2,
    fontSize: '14px',
    lineHeight: 1.5,
    wordBreak: 'break-word',

    '& span': {
      color: black1,
      fontWeight: bold,
    },
  },
};

export default styles;
