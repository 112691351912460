import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout, PageLayout } from '~/ui';

import { tabPropType } from './SidebarTab';

function SidebarTabs({ selectedTab, tabs, onSelectTab }) {
  return (
    <FlexLayout alignItems="center" role="tablist">
      {tabs.map((tab) => (
        <Box
          key={tab.value}
          sx={{
            flex: `0 0 ${100 / tabs.length}%`,
          }}
          role="tab"
        >
          <PageLayout.SidebarTab
            isSelected={tab.value === selectedTab}
            tab={tab}
            onClick={onSelectTab}
          />
        </Box>
      ))}
    </FlexLayout>
  );
}

SidebarTabs.propTypes = {
  /** Name of currently selected tab */
  selectedTab: PT.string,
  /** List of tab objects: { isSelected, tab, onClick } */
  tabs: PT.arrayOf(tabPropType.isRequired),
  /** Callback function to trigger change of tabs */
  onSelectTab: PT.func,
};

export default SidebarTabs;
