import PT from 'prop-types';
import React from 'react';

import { withGetDataFieldSections } from '~/hocs';
import { SingleSelect } from '~/ui';
import { getOptions } from '~/utils/array';

function DataFieldSelect({
  blacklistValues,
  fieldTypes = [],
  id,
  placeholder,
  value,
  onChange,
  // injected
  allDataFields,
  isLoading,
}) {
  const dataFields = Object.values(allDataFields).filter((dataField) => {
    return fieldTypes.length === 0 || fieldTypes.includes(dataField.fieldType);
  });
  const options = getOptions(dataFields, 'id', 'label');

  return (
    <SingleSelect
      isLoading={isLoading}
      blacklistValues={blacklistValues}
      id={id}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}

DataFieldSelect.propTypes = {
  blacklistValues: PT.arrayOf(PT.number),
  fieldTypes: PT.arrayOf(PT.string.isRequired),
  id: PT.string,
  placeholder: PT.string,
  value: PT.number,
  onChange: PT.func.isRequired,
};

export default withGetDataFieldSections(DataFieldSelect);
