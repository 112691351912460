import PT from 'prop-types';
import React from 'react';

import { useHover } from '../../hooks';
import Button from '../Button';
import FlexLayout from '../FlexLayout';
import Text from '../Text';
import CtaImage from './CtaImage';

const CtaSection = ({ actionButton, description, imageSrc, title }) => {
  const [ref, isHovered] = useHover();
  const isConcise = !title;

  const width = isConcise ? 'cta-section-width' : '100%';
  const space = isConcise ? 4 : 6;
  const imageSize = isConcise ? 'm' : 'l';

  const styles = {
    container: {
      borderRadius: 'm',
      textAlign: 'center',
      width,
    },
  };

  return (
    <FlexLayout
      as="section"
      alignItems="center"
      flexDirection="column"
      justifyContent="space-between"
      p={6}
      ref={ref}
      sx={styles.container}
    >
      <FlexLayout
        alignItems="center"
        flexDirection="column"
        pb={space * 1.5}
        space={space}
      >
        <CtaImage isHovered={isHovered} size={imageSize} src={imageSrc} />
        {title && (
          <Text color="gray-900" variant="xl-spaced-bold">
            {title}
          </Text>
        )}
        <Text color="gray-800" variant="s-spaced">
          {description}
        </Text>
      </FlexLayout>
      {actionButton && <Button {...actionButton} variant="outlined" />}
    </FlexLayout>
  );
};

CtaSection.propTypes = {
  /** Action button props */
  actionButton: PT.object,
  /** Description for the action */
  description: PT.node.isRequired,
  /** Image source */
  imageSrc: PT.string.isRequired,
  /** Display a prominent title and updates the component with wider spacing rules */
  title: PT.string,
};

export default CtaSection;
