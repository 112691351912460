import { FieldModel } from '~/features/x-ray/fields';
import { sylvanus } from '~/services';

import { PagePromptModel, PromptModel } from '../../types/sylvanus/model';
import { getFilters } from '../searchV3';
import { mapModelVersionToFieldModel } from './transformers';

export const DEPRECATED_getPromptModels = async ({
  pageIndex,
  pageSize,
}: {
  pageIndex: number;
  pageSize: number;
}): Promise<{ models: FieldModel[]; totalCount: number }> => {
  const response: PagePromptModel = await sylvanus.get('/model', {
    params: {
      include_latest_version: true,
      page: pageIndex,
      size: pageSize,
    },
  });

  // TODO: remove this once BE cleans up bad dev data
  const models = response.items.filter(
    (model: PromptModel) => model.version_numbers?.length,
  );
  const searchFilters = await getFilters();
  const modelVersions = models.map((model: PromptModel) => {
    // @ts-ignore (ParsedType<number[]> is not assignable to number[])
    const versionNumbers: number[] = model.version_numbers;
    const versionNumber = String(Math.max(...versionNumbers));
    const version =
      model.versions?.[versionNumber as keyof typeof model.versions];
    // TODO: remove this once BE cleans up bad dev data
    if (!version)
      throw new Error(
        `Cannot get model: No version found for model with id ${model.id}.`,
      );

    return mapModelVersionToFieldModel({
      modelId: model.id,
      version,
      fieldId: parseInt(model.target_entity.id),
      searchFilters,
      optimizeState:
        model.current_optimize?.version_number === version.version_number
          ? model.current_optimize?.state
          : null,
      publishStatus: model.current_publish?.status ?? null,
    });
  });
  return {
    models: modelVersions,
    totalCount: response.total ?? 0,
  };
};
