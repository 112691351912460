import PropTypes from 'prop-types';
import React from 'react';

import { evisortBlue } from '../../../assets/shared-styles/general';

const ReloadIcon = (props) => {
  const color = props.blue ? evisortBlue : props.color;

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={color}
          d="M17.65 6.35a7.95 7.95 0 0 0-6.48-2.31c-3.67.37-6.69 3.35-7.1 7.02C3.52 15.91 7.27 20 12 20a7.98 7.98 0 0 0 7.21-4.56c.32-.67-.16-1.44-.9-1.44-.37 0-.72.2-.88.53a5.994 5.994 0 0 1-6.8 3.31c-2.22-.49-4.01-2.3-4.48-4.52A6.002 6.002 0 0 1 12 6c1.66 0 3.14.69 4.22 1.78l-1.51 1.51c-.63.63-.19 1.71.7 1.71H19c.55 0 1-.45 1-1V6.41c0-.89-1.08-1.34-1.71-.71l-.64.65z"
        />
      </g>
    </svg>
  );
};

ReloadIcon.defaultProps = {
  size: '24',
  color: '#000',
};

ReloadIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  blue: PropTypes.bool,
};

export default ReloadIcon;
