import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { black1 } from '../../../assets/shared-styles/general';
import EcCheckbox from '../../Shared/EcCheckbox';
import EcSelect from '../../Shared/EcSelect';
import CloseIcon from '../Icons/CloseIcon';
import styles from './EcUserNameSelectValueWithControls.styles';

class EcUserNameSelectValueWithControls extends Component {
  constructor(props) {
    super(props);

    this.handleAllowDownload = this.handleAllowDownload.bind(this);

    this.state = {
      accountUser: this.props.accountUser,
      allowDownload: this.props.accountUser.visibility_level === 'OPEN_NO_EDIT',
      selectedPrivilege: {
        label: this.renderPreselectedPrivilegeLabel(
          this.props.accountUser.visibility_level,
        ),
        value: this.renderPreselectedPrivilegeValue(
          this.props.accountUser.visibility_level,
        ),
      },
    };
  }

  renderPreselectedPrivilegeLabel(visibilityLevel) {
    return visibilityLevel === 'OPEN_NO_EDIT' ||
      visibilityLevel === 'OPEN_NO_EDIT_DOWNLOAD'
      ? 'Can View'
      : 'Can Edit';
  }

  renderPreselectedPrivilegeValue(visibilityLevel) {
    return visibilityLevel === 'OPEN_NO_EDIT' ||
      visibilityLevel === 'OPEN_NO_EDIT_DOWNLOAD'
      ? 'canView'
      : 'canEdit';
  }

  handleAllowDownload() {
    const { updateUserPrivilege } = this.props;
    const { accountUser, allowDownload } = this.state;

    this.setState({ allowDownload: !allowDownload }, () => {
      updateUserPrivilege({
        user_id: accountUser.user_id,
        label: accountUser.label,
        value: accountUser.value,
        name: accountUser.name,
        visibility_level: this.state.allowDownload
          ? 'OPEN_NO_EDIT'
          : 'OPEN_NO_EDIT_DOWNLOAD',
      });
    });
  }

  handleUserPrivilegeChange(option) {
    const { updateUserPrivilege } = this.props;
    const { accountUser } = this.state;

    this.setState({
      selectedPrivilege: { label: option.label, value: option.value },
    });

    updateUserPrivilege({
      user_id: accountUser.user_id,
      label: accountUser.label,
      value: accountUser.value,
      user_name: accountUser.value,
      visibility_level:
        option.value === 'canView' ? 'OPEN_NO_EDIT_DOWNLOAD' : 'OPEN',
    });
  }

  renderPrivilegesControl() {
    const { classes, closeHandler, isDisabled, isGroup } = this.props;
    const { accountUser, allowDownload, selectedPrivilege } = this.state;

    const checkboxId =
      'EcUserNameSelectValueWithControls/allowDownloadCheckbox-' +
      accountUser.label;
    const privilegeOptions = [
      {
        label: 'Can Edit',
        value: 'canEdit',
      },
      {
        label: 'Can View',
        value: 'canView',
      },
    ];

    return (
      <div className={classes.accountUserNameItem}>
        <div className={classes.allowDownloadWrapper}>
          {selectedPrivilege.value === 'canView' && (
            <span className={classes.allowDownloadContainer}>
              <EcCheckbox
                id={checkboxId}
                checked={allowDownload}
                onClick={this.handleAllowDownload}
              />
              <label htmlFor={checkboxId}>Allow user to download</label>
            </span>
          )}
        </div>
        <EcSelect
          width="130px"
          height="40px"
          options={privilegeOptions}
          value={selectedPrivilege}
          placeholder="Can Edit"
          onChange={(option) => this.handleUserPrivilegeChange(option)}
          closeHandler={closeHandler}
        />
        {isDisabled || isGroup ? null : (
          <button
            className={classes.removeUserIconWrapper}
            title="remove user"
            onClick={closeHandler}
          >
            <CloseIcon color={black1} size="20" />
          </button>
        )}
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { accountUser } = this.state;

    return (
      <div className={classes.selectedOption} key={accountUser.label}>
        <span>{accountUser.label}</span>
        {this.renderPrivilegesControl()}
      </div>
    );
  }
}

EcUserNameSelectValueWithControls.propTypes = {
  classes: PropTypes.object.isRequired,
  accountUser: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default injectSheet(styles)(EcUserNameSelectValueWithControls);
