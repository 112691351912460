import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { ticketParticipantsSet, ticketUpdate } from '~/actions';
import { backToReview, getTicketParticipants } from '~/api';
import { Button, useToast } from '~/eds';
import { api, TAGS } from '~/redux';
import { Text, useModal } from '~/ui';

import { useActivityLogUpdater } from '../../shared/useActivityLogUpdater';

function BackToReviewAction({
  // connected
  ticketId,
  ticketName,
  ticketUpdate,
}) {
  const [shouldResetApprovals, setResetApprovals] = useState(false);
  const updateActivityLog = useActivityLogUpdater({ ticketId });
  const { toast } = useToast();
  const dispatch = useDispatch();

  const [modal, showModal] = useModal({
    title: 'Back to Review?',
    onHide: () => setResetApprovals(false),
    actionButton: {
      text: 'Back to review',
      errorHandler: () =>
        toast({
          message:
            'Transition back to Review failed. Please refresh the page and try again.',
          status: 'danger',
        }),
      promise: async () => {
        const res = await backToReview({ ticketId, shouldResetApprovals });
        ticketUpdate({
          stages: res.stages,
        });
        dispatch(api.util.invalidateTags([TAGS.TAG_BY_TICKET_SUMMARY]));
        const esigErrorMessage = res?.esigErrorMessage;
        esigErrorMessage
          ? toast({
              message: esigErrorMessage,
              status: 'info',
            })
          : toast({
              message: `Ticket "${ticketName}" successfully returned to Review.`,
              status: 'success',
            });

        await updateActivityLog();

        const participants = await getTicketParticipants({ ticketId });
        ticketParticipantsSet(participants.data);
      },
    },
    content: (
      <Text color="gray-900" variant="s-spaced">
        Moving the process back to review will invalidate all signatures that
        have been submitted and retract all that have been sent.
      </Text>
    ),
  });

  return (
    <>
      <Button text="Back to Review" onClick={showModal} />
      {modal}
    </>
  );
}

function mapStateToProps({ ticket }) {
  return { ticketId: ticket.id, ticketName: ticket.name };
}

export default connect(mapStateToProps, {
  ticketParticipantsSet,
  ticketUpdate,
})(BackToReviewAction);
