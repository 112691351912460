import PT from 'prop-types';
import React from 'react';

import styles from '../../styles';
import FlexLayout from '../FlexLayout';
import PageLayout from '../PageLayout';
import Text from '../Text';
import Footer from './Footer';
import Header from './Header';

function WizardPageLayout({
  actionButton,
  backButton,
  children,
  currentStep,
  description,
  isContentCentered,
  isContainer = false,
  steps = [],
  title,
  onClose,
  rightContent,
  isContentFill = false,
}) {
  const header = (
    <Header
      currentStep={currentStep}
      description={description}
      steps={steps}
      title={title}
      onClose={onClose}
      rightContent={rightContent}
    />
  );

  const contentStyles = isContainer
    ? {
        ...layoutStyles.main,
        ...styles.layout.container,
      }
    : layoutStyles.main;

  return (
    <PageLayout
      isContentCentered={isContentCentered}
      header={header}
      isContentFill={isContentFill}
    >
      <FlexLayout flexDirection="column" space={12} sx={contentStyles}>
        {description && (
          <Text
            color="gray-800"
            isReadble={true}
            variant="xl-spaced-bold"
            sx={layoutStyles.description}
          >
            {description}
          </Text>
        )}
        {children}
      </FlexLayout>
      {actionButton && (
        <Footer actionButton={actionButton} backButton={backButton} />
      )}
    </PageLayout>
  );
}

const layoutStyles = {
  main: {
    height: '100%',
    width: '100%',
  },
  description: {
    textAlign: 'center',
  },
};

// extends Button.prop
const buttonPropTypes = PT.shape({
  /** An async callback.  You should handle exceptions */
  promise: PT.func,
});

WizardPageLayout.propTypes = {
  /** If specified, will render the action button in the footer */
  actionButton: buttonPropTypes,
  /** Renders a back button in the footer */
  backButton: buttonPropTypes,
  /** Page contents */
  children: PT.node,
  /** Current step */
  currentStep: PT.string,
  /** Page description */
  description: PT.node,
  /** Is content container centered */
  isContentCentered: PT.bool,
  /** Steps */
  steps: PT.arrayOf(PT.string.isRequired),
  /** Page title */
  title: PT.shape({
    text: PT.string.isRequired,
    maxWidth: PT.number,
  }).isRequired,
  /** Renders an optional close icon on the right */
  onClose: PT.func,
};

export default WizardPageLayout;
