export default `
<svg
  aria-hidden="true"
  aria-label="format-code"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M9.6875 7.71875C10.0938 7.34375 10.0938 6.6875 9.6875 6.3125C9.3125 5.90625 8.65625 5.90625 8.28125 6.3125L3.28125 11.3125C2.875 11.6875 2.875 12.3438 3.28125 12.7188L8.28125 17.7188C8.65625 18.125 9.3125 18.125 9.6875 17.7188C10.0938 17.3438 10.0938 16.6875 9.6875 16.3125L5.40625 12L9.6875 7.71875ZM14.2812 7.71875L18.5625 12L14.2812 16.3125C13.875 16.6875 13.875 17.3438 14.2812 17.7188C14.6562 18.125 15.3125 18.125 15.6875 17.7188L20.6875 12.7188C21.0938 12.3438 21.0938 11.6875 20.6875 11.3125L15.6875 6.3125C15.3125 5.90625 14.6562 5.90625 14.2812 6.3125C13.875 6.6875 13.875 7.34375 14.2812 7.71875Z"
    fill="currentcolor"
  />
</svg>
`;
