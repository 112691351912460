export default `
<svg
  aria-hidden="true"
  aria-label="signature"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M4.1365 15.2562C4.2615 14.8187 4.5115 14.4125 4.824 14.1L14.324 4.59998C15.1052 3.81873 16.3865 3.81873 17.1677 4.59998L18.3865 5.81873C18.4802 5.91248 18.574 6.03748 18.6365 6.13123C19.1677 6.91248 19.074 7.97498 18.3865 8.66248L8.8865 18.1625C8.85525 18.1937 8.79275 18.225 8.7615 18.2875C8.449 18.5375 8.10525 18.725 7.73025 18.85L3.949 19.9437C3.699 20.0375 3.41775 19.975 3.23025 19.7562C3.0115 19.5687 2.949 19.2875 3.0115 19.0375L4.1365 15.2562ZM4.85525 18.1312L7.29275 17.4125C7.48025 17.35 7.66775 17.2562 7.824 17.1L14.9802 9.94373L13.0115 8.00623L5.8865 15.1625C5.85525 15.1625 5.85525 15.1937 5.824 15.225C5.699 15.35 5.6365 15.5062 5.574 15.6937L4.85525 18.1312ZM20.2615 18.475C20.6677 18.475 21.0115 18.8187 21.0115 19.225C21.0115 19.6625 20.6677 19.975 20.2615 19.975H10.7615C10.324 19.975 10.0115 19.6625 10.0115 19.225C10.0115 18.8187 10.324 18.475 10.7615 18.475H20.2615Z"
    fill="currentcolor"
  />
</svg>
`;
