// sort by key name, then by value to ensure docs read well
export default {
  'alert-min-height': '40px',
  'card-footer-height': '68px',
  'card-header-height': '68px',
  'collapsible-header-height': '68px',
  'container-width': '732px',
  'cta-section-width': '292px',
  'divider-width-m': '24px',
  'divider-width-full': '100%',
  'document-max-width': '944px',
  'icon-2xs': '12px',
  'icon-xs': '16px',
  'icon-s': '20px',
  'icon-m': '24px',
  'icon-l': '28px',
  'icon-xl': '32px',
  'input-height': '40px',
  'input-width-2xs': '84px',
  'input-width-xs': '192px',
  'input-width-s': '300px',
  'input-width-m': '408px',
  'input-width-l': '516px',
  'input-width-xl': '624px',
  'input-width-2xl': '732px',
  'modal-footer-height': '68px',
  'modal-header-height': '68px',
  'modal-width': '720px',
  'modal-width-l': '1000px',
  'multi-select-chip-height': '28px',
  'page-content-max-width': '944px',
  'page-footer-height': '64px',
  'page-header-height': '64px',
  'page-toolbar-width': '64px',
  'page-sidebar-width': '400px',
  'page-sidebar-toolbar-width': '464px',
  'readable-width': '500px',
  'sidebar-section-item-height': '56px',
  'step-separator-height': '2px',
  'step-separator-width': '16px',
  'step-bar-height': '6px',
  'step-bar-width': '20px',
  'tab-height': '64px',
};
