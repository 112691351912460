import { api } from '../createApi';
import { TagType } from '../TagType';
import { ACL_TAG } from './acl';

export const TAG_CURRENT_STAGE = { type: TagType.Ticket, id: 'CURRENT_STAGE' };
export const TAG_BY_ACTIVITY_LOG = { type: TagType.Ticket, id: 'ACTIVITY_LOG' };
export const TAG_BY_TICKET_SUMMARY = {
  type: TagType.Ticket,
  id: 'TICKET_SUMMARY',
};
export const TAG_CURRENT_VERSION = {
  type: TagType.Ticket,
  id: 'CURRENT_VERSION',
};
const TAG_PARTICIPANTS = { type: TagType.Ticket, id: 'PARTICIPANTS' };

api.enhanceEndpoints({
  addTagTypes: [TagType.Ticket as TagType],
  endpoints: {
    getTicketCurrentStage: {
      providesTags: [TAG_CURRENT_STAGE],
    },
    getTicketSummary: {
      providesTags: [TAG_BY_TICKET_SUMMARY],
    },
    getActivities: {
      providesTags: [TAG_BY_ACTIVITY_LOG],
    },
    getTicketParticipants: {
      providesTags: [TAG_PARTICIPANTS],
    },
    createActivityLogEvent: {
      invalidatesTags: [TAG_BY_ACTIVITY_LOG],
    },
    getTicketDocument: {
      providesTags: [TAG_CURRENT_VERSION],
    },
    updateRecipients: {
      invalidatesTags: [TAG_CURRENT_STAGE],
    },
    deleteRecipients: {
      invalidatesTags: [TAG_CURRENT_STAGE],
    },
    addTicketParticipants: {
      invalidatesTags: [TAG_PARTICIPANTS, ACL_TAG],
    },
    bumpVersion: {
      invalidatesTags: [TAG_BY_TICKET_SUMMARY],
    },
  },
});
