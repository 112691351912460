import { FlagType } from '~/flags';

export interface Tab {
  label: string;
  value: string;
  count?: number;
}

export interface TabsConfig {
  value: TicketCompletionType;
  label: string;
  emptyTitle: string;
  emptyMessage?: string;
  filter: string;
  featureFlag?: FlagType;
}

export interface SortBy {
  id: string;
  desc: boolean;
}

export enum TicketCompletionType {
  NeedsAction,
  InProgress,
  Cancelled,
  Done,
  All,
}
