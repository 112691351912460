import React from 'react';

import { Info, Panel } from '~/eds';

import { ConfigurationPanelProps } from './types';
import { useConfigurationInfos } from './useConfigurationInfos';

export const ConfigurationDetailPanel = ({
  config,
  onHide,
}: ConfigurationPanelProps) => {
  const {
    id,
    allowedSyncFileTypes,
    createdBy,
    dateCreated,
    dateUpdated,
    evisortFolderPath,
    provider,
    providerOptions,
  } = config;

  return (
    <Panel
      hidden={{
        placement: 'left',
        onHide,
      }}
      position="right"
      title="Sync Pair Configuration"
      width="m"
    >
      <Info
        sections={[
          {
            title: '',
            infos: useConfigurationInfos({
              id,
              providerOptions,
              createdBy,
              evisortFolderPath,
              allowedSyncFileTypes,
              dateCreated,
              dateUpdated,
              provider,
            }),
          },
        ]}
      />
    </Panel>
  );
};
