import React from 'react';

import { useEscape } from '~/ui';

export function withUseEscape(Base) {
  return function WrappedWithUseEscape(props) {
    useEscape(props.onEsc);

    return <Base {...props} />;
  };
}
