import React, { memo } from 'react';
import { useStyles } from 'uinix-ui';

import { MenuActions, UserAction } from '../../types';
import { ActionsMenu } from '../ActionsMenu';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Layout } from '../Layout';
import { Link } from '../Link';

interface Props {
  /** Array of link actions in the ordered list of nav breadcrumbs */
  actions: UserAction[];
}

const MAX_VISIBLE_ACTIONS = 4;

export const Breadcrumbs = memo(({ actions }: Props) => {
  const styles = useStyles();

  const limit = Math.min(actions.length, MAX_VISIBLE_ACTIONS);
  const visibleActions = actions.slice(0, limit);
  const hiddenActions = actions.slice(limit);

  return (
    <nav>
      <Layout as="ol" styles={styles.list.unset}>
        {actions.length > MAX_VISIBLE_ACTIONS && (
          <li>
            <ActionsMenu
              actions={toMenuActions(hiddenActions)}
              name="more actions"
            />
          </li>
        )}
        {visibleActions.map((action, i) => {
          const { hash, href, pathname, state, text, onClick } = action;
          const isLastAction = i === visibleActions.length - 1;
          const isLink = Boolean(href || pathname);
          const enableSeparator = (i === 0 && hiddenActions.length) || i > 0;
          const disabled = Boolean(action.disabled || isLastAction);

          const crumb = (
            <Button
              disabled={disabled}
              shouldTruncate
              text={text}
              variant="tertiary"
              onClick={onClick}
            />
          );

          return (
            <Layout as="li" key={i}>
              {enableSeparator && (
                <Icon disabled={disabled} icon="chevron-right" px={0.5} />
              )}
              {isLink ? (
                <Link
                  disabled={disabled}
                  hash={hash}
                  href={href}
                  pathname={pathname}
                  state={state}
                >
                  {crumb}
                </Link>
              ) : (
                crumb
              )}
            </Layout>
          );
        })}
      </Layout>
    </nav>
  );
});

const toMenuActions = (actions: UserAction[]): MenuActions<undefined> =>
  actions.map((link) => ({
    ...link,
    label: link.text,
    value: undefined,
  }));
