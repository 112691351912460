import PT from 'prop-types';
import React from 'react';

import { FlexLayout } from '~/ui';

import Tab, { tabPropType } from './Tab';

function Tabs({ justifyContent = 'center', selectedTab, tabs, onSelectTab }) {
  return (
    <FlexLayout
      alignItems="center"
      justifyContent={justifyContent}
      space={6}
      sx={{ borderBottom: 'border' }}
      role="tablist"
    >
      {tabs.map((tab) => (
        <Tab
          id={tab.id}
          key={tab.value}
          isSelected={tab.value === selectedTab}
          tab={tab}
          onClick={onSelectTab}
        />
      ))}
    </FlexLayout>
  );
}

Tabs.propTypes = {
  /** CSS justify-content of tabs */
  justifyContent: PT.string,
  /** Name of currently selected tab */
  selectedTab: PT.string,
  /** List of tab objects: { isSelected, tab, onClick } */
  tabs: PT.arrayOf(tabPropType.isRequired),
  /** Callback function to trigger change of tabs */
  onSelectTab: PT.func,
};

export default Tabs;
