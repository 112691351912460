import { createSelector } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';

import { isActiveFilter } from '../slices/search';
import { RootState, FieldAiState as State } from '../types';

const selectState = (rootState: Partial<RootState>): State =>
  rootState[SliceType.FieldAi];

export const selectActiveModel = createSelector(
  selectState,
  (state: State) => state.activeModel,
);

// TODO: deprecate
export const selectActiveTestcaseNumber = createSelector(
  selectState,
  (state: State) => state.activeTestcaseNumber,
);

export const selectHasViewedWelcomeContent = createSelector(
  selectState,
  (state: State) => state.hasViewedWelcomeContent,
);

export const selectConfig = createSelector(
  selectState,
  (state: State) => state.configuration,
);

export const selectDismissedIncorrectStatusMessage = createSelector(
  selectState,
  (state: State) => state.dismissedIncorrectStatusMessage,
);

export const selectDocumentViewer = createSelector(
  selectState,
  (state: State) => state.documentViewer,
);

export const selectFilters = createSelector(
  selectConfig,
  (config: State['configuration']) => config.filters,
);

export const selectField = createSelector(
  selectConfig,
  (config: State['configuration']) => config.field,
);

export const selectInstructions = createSelector(
  selectConfig,
  (config: State['configuration']) => config.instructions,
);

export const selectIsNewVersion = createSelector(
  selectState,
  (state: State) => state.isNewVersion,
);

export const selectModelAction = createSelector(
  selectState,
  (state: State) => state.modelAction,
);

export const selectPublishMode = createSelector(selectState, (state: State) => {
  return state.publishMode;
});

export const selectOptimize = createSelector(
  selectState,
  (state: State) => state.optimize,
);

export const selectRedirect = createSelector(
  selectState,
  (state: State) => state.redirect,
);

export const selectActiveFilters = createSelector(selectFilters, (filters) =>
  filters.filter(isActiveFilter),
);

export const selectModelVersions = createSelector(
  selectState,
  (state) => state.modelVersions,
);

export const selectActiveVersionNumber = createSelector(
  selectState,
  (state) => state.activeVersionNumber,
);

export const selectIsViewingPastVersion = createSelector(
  selectState,
  ({ modelVersions, activeVersionNumber }) =>
    activeVersionNumber !== modelVersions.length,
);

export const selectReviewIncorrectCount = createSelector(
  selectState,
  (state) => state.reviewIncorrectCount,
);

export const selectCreditsSummary = createSelector(
  selectState,
  (state) => state.creditsSummary,
);
