import React, { useMemo } from 'react';

import { ButtonVariant, SharedButtonProps } from '../../types';
import { useModal } from './useModal';

type Callback = () => void;

interface Props {
  children: React.ReactNode;
  title: string;
  onDelete: () => void;
  isDeleting?: boolean;
  secondaryActions?: SharedButtonProps[];
  primaryActionText?: string;
}

export const useDeleteModal = ({
  children,
  isDeleting,
  secondaryActions,
  title,
  onDelete,
  primaryActionText,
}: Props): [React.ReactNode, Callback, Callback] => {
  const props = useMemo(
    () => ({
      children,
      primaryAction: {
        isLoading: isDeleting,
        text: primaryActionText || 'Delete',
        variant: 'danger' as ButtonVariant,
        onClick: onDelete,
      },
      secondaryActions,
      title,
    }),
    [children, isDeleting, secondaryActions, title],
  );

  return useModal(props);
};
