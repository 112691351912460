import React from 'react';

import { Accordion } from '~/eds';
import { useRouting } from '~/routing';
import { Uuid } from '~/types';
import { sortByStringValue } from '~/utils/array';

import { Clause, TabValue } from '../../../../types';
import { formatUrl } from '../../../../utils';
import { ClauseSummary } from './ClauseSummary';

export const ClauseSummaries = () => {
  const { getSearchParam, navigate, params } = useRouting();
  const { clauseId } = params as { clauseId: Uuid };

  const tab = getSearchParam<TabValue>('tab');
  const { data: clauses } = mockQueryResponse;

  const clauseSummaries = clauses
    .filter((clause) => clause.tab === tab)
    .map((clause) => ({
      title: clause.name,
      content: <ClauseSummary clause={clause} />,
      isExpanded: clause.id === clauseId,
      onToggle: (isExpanded: boolean) => {
        isExpanded && navigate(formatUrl(clause.tab, clause.id));
      },
    }))
    .sort(sortByStringValue('title'));

  return <Accordion items={clauseSummaries} />;
};

const mockClauses: Clause[] = [
  {
    name: 'Acceptance',
    id: 'acceptance',
    tab: 'published',
    variations: [],
  },
  {
    name: 'Assignment',
    id: 'assignment',
    tab: 'published',
    variations: [],
  },
  {
    name: 'Confidentiality',
    id: 'confidentiality',
    tab: 'published',
    variations: [],
  },
  {
    name: 'Force Majeure',
    id: 'force-majeure',
    tab: 'published',
    variations: [],
  },
  {
    name: 'Governing Law',
    id: 'governing-law',
    tab: 'ai-suggested',
    variations: [
      {
        name: 'California',
        content: 'Mock content',
        generatedBy: 400,
        generatedOn: new Date(),
        id: 'california',
        position: 'Preferred',
        clauseId: 'governing-law',
        contractsCount: 570,
        variationsCount: 12,
        tab: 'ai-suggested',
      },
      {
        name: 'Delaware',
        content: 'Mock content',
        generatedBy: 400,
        generatedOn: new Date(),
        id: 'delaware',
        position: 'Fallback',
        clauseId: 'governing-law',
        contractsCount: 109,
        variationsCount: 25,
        tab: 'ai-suggested',
      },
      {
        name: 'Kansas',
        content: 'Mock content',
        generatedBy: 400,
        generatedOn: new Date(),
        id: 'kansas',
        position: 'Walk away',
        clauseId: 'governing-law',
        contractsCount: 6,
        variationsCount: 2,
        tab: 'ai-suggested',
      },
      {
        name: 'Netherlands',
        content: 'Mock content',
        generatedBy: 400,
        generatedOn: new Date(),
        id: 'netherlands',
        position: 'Walk away',
        clauseId: 'governing-law',
        contractsCount: 1,
        variationsCount: 4,
        tab: 'ai-suggested',
      },
    ],
  },
  {
    name: 'Indemnification',
    id: 'indemnification',
    tab: 'review',
    variations: [
      {
        name: 'Limitation of Liability',
        content: 'Mock content',
        generatedBy: 400,
        generatedOn: new Date(),
        id: 'limitation-of-liability',
        position: 'Preferred',
        clauseId: 'indemnification',
        contractsCount: 41,
        variationsCount: 19,
        tab: 'review',
      },
    ],
  },
];

const mockQueryResponse = {
  data: mockClauses,
  isFetching: false,
  isSuccess: true,
  error: null,
};
