export default `
<svg
  aria-hidden="true"
  aria-label="field-monetary"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12.7658 6.3125C13.6095 6.40625 14.3908 6.5625 14.9845 6.6875C15.3908 6.8125 15.6408 7.1875 15.547 7.59375C15.4533 8 15.047 8.25 14.6408 8.15625C13.672 7.9375 12.2658 7.65625 11.0783 7.78125C10.4845 7.8125 10.0158 7.9375 9.67202 8.15625C9.35952 8.375 9.10952 8.65625 9.04702 9.125C8.95327 9.46875 9.01577 9.71875 9.10952 9.875C9.17202 10.0625 9.35952 10.25 9.64077 10.4062C10.2345 10.7812 11.1095 11.0312 12.172 11.2812L12.2658 11.3125C13.2345 11.5625 14.3283 11.8438 15.172 12.3438C15.6095 12.625 15.9845 12.9688 16.2345 13.4688C16.5158 13.9688 16.547 14.5625 16.4533 15.1875C16.2345 16.3125 15.422 17.0312 14.422 17.4062C13.922 17.5938 13.3595 17.7188 12.7658 17.75V19.25C12.7658 19.6875 12.422 20 12.0158 20C11.5783 20 11.2658 19.6875 11.2658 19.25V17.7188C11.1408 17.6875 11.047 17.6875 10.9533 17.6875L10.922 17.6562C10.172 17.5625 8.79702 17.25 7.95327 16.875C7.57827 16.6875 7.39077 16.25 7.57827 15.875C7.73452 15.5 8.17202 15.3125 8.54702 15.5C9.20327 15.7812 10.4533 16.0938 11.1408 16.1875C12.2658 16.3438 13.2033 16.25 13.8908 16C14.547 15.75 14.8908 15.375 14.9533 14.9062C15.047 14.5312 14.9845 14.3125 14.8908 14.1562C14.8283 13.9688 14.6408 13.7812 14.3595 13.625C13.7658 13.25 12.8908 13 11.8283 12.75L11.7345 12.7188C10.7658 12.4688 9.67202 12.1875 8.82827 11.6875C8.39077 11.4062 8.01577 11.0625 7.76577 10.5625C7.48452 10.0312 7.45327 9.46875 7.54702 8.84375C7.73452 7.9375 8.20327 7.3125 8.85952 6.90625C9.48452 6.5 10.2345 6.34375 10.9533 6.28125C11.047 6.28125 11.1408 6.25 11.2658 6.25V4.75C11.2658 4.34375 11.5783 4 12.0158 4C12.422 4 12.7658 4.34375 12.7658 4.75V6.3125Z"
    fill="currentcolor"
  />
</svg>
`;
