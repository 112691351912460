import pluralize from 'pluralize';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import { black4 } from '~/assets/shared-styles/general';
import { preprocessEditData } from '~/components/DocumentsViewPage/DocumentOverviewPanel/DocumentOverviewPanel.utils';
import {
  getNextDocument,
  getNextFlaggedDocument,
  getPreviousDocument,
  getPreviousFlaggedDocument,
  reviewDocument,
  ReviewerDataFields,
  styles,
} from '~/components/ReviewersViewPage';
import EcButton from '~/components/Shared/EcButton/EcButton';
import EcDocumentViewer from '~/components/Shared/EcDocumentViewer';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import withHover from '~/components/Shared/HOCs/withHover';
import BackIcon from '~/components/Shared/Icons/BackIcon';
import CheckmarkIcon from '~/components/Shared/Icons/CheckmarkIcon';
import ChevronLeftIcon from '~/components/Shared/Icons/ChevronLeftIcon';
import ChevronRightIcon from '~/components/Shared/Icons/ChevronRightIcon';
import CloseIcon from '~/components/Shared/Icons/CloseIcon';
import DocumentStrokedIcon from '~/components/Shared/Icons/DocumentStrokedIcon';
import FlagIcon from '~/components/Shared/Icons/FlagIcon';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import TimelapseIcon from '~/components/Shared/Icons/TimelapseIcon';
import { formatDate, parseDate } from '~/eds';
import { withFlags } from '~/flags';
import { getDocumentOriginal } from '~/redux/api/methods';
import { withRouting } from '~/routing';
import { MODAL_LEAVE_REVIEW, MODAL_SAVE_AND_FLAG } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';

const defaultContainerStyle = { padding: '16px', overflow: 'auto' };
let docSideInfoStyle;

const BackIconWithHover = withHover(BackIcon);

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.loadFileDocument = this.loadFileDocument.bind(this);
    this.loadNextDocumentData = this.loadNextDocumentData.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.handleOCRClick = this.handleOCRClick.bind(this);
    this.handlePDFClick = this.handlePDFClick.bind(this);
    this.handleOnSaveClick = this.handleOnSaveClick.bind(this);
    this.handleOnPreviousClick = this.handleOnPreviousClick.bind(this);
    this.handleOnNextClick = this.handleOnNextClick.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleSaveFlaggedClick = this.handleSaveFlaggedClick.bind(this);
    this.handleDismissChangesClick = this.handleDismissChangesClick.bind(this);
    this.handleSaveChangesAndContinueClick = this.handleSaveChangesAndContinueClick.bind(
      this,
    );
    this.handleSubmitButtonStatus = this.handleSubmitButtonStatus.bind(this);
    this.resizeContentContainer = this.resizeContentContainer.bind(this);
    this.onKeyInformationFieldTokenSelect = this.onKeyInformationFieldTokenSelect.bind(
      this,
    );
  }

  UNSAFE_componentWillMount() {
    docSideInfoStyle = {
      ...defaultContainerStyle,
      maxHeight: window.innerHeight - 268 + 'px',
    };
    this.setState({ docSideInfoStyle });
  }

  componentDidMount() {
    if (this.props.location.state.flagged === null) {
      this.props.navigate('/reviewer');
    } else {
      this.setState(
        { isFlagged: this.props.location.state.flagged },
        this.loadNextDocumentData,
      );
    }
    window.addEventListener('resize', this.resizeContentContainer);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.outsideOfDocumentClickHandle);
    window.removeEventListener('resize', this.resizeContentContainer);
  }

  resizeContentContainer() {
    docSideInfoStyle = {
      ...defaultContainerStyle,
      maxHeight: window.innerHeight - 268 + 'px',
    };
    this.setState({ docSideInfoStyle });
  }

  getInitialState() {
    return {
      documentId: null,
      documentName: null,
      reviewsLeft: null,
      htmlData: null,
      loading: true,
      numPages: null,
      pdfFile: null,
      dataFields: [],
      flagData: {},
      isFlagged: false,
      childFieldsComponent: null,
      errorLoadDocument: false,
      enableSaveButton: true,
      showOCR: true,
      fieldTokenIndex: 0,
      shouldShowFieldHighlightControls: false,
    };
  }

  navigateBack() {
    this.props.navigate(-1);
  }

  loadDocumentData(getDocument, documentId = '') {
    getDocument(documentId)
      .then((res) => {
        const newState = {
          documentId: res.id,
          documentName: res.document_name,
          reviewsLeft: res.left_to_review,
          fileType: res.file_type,
          htmlData: res.html_content.replace(/\\"/g, '"'),
          dataFields: res.document_information,
          loading: false,
        };

        if (this.state.isFlagged) {
          newState['flagData'] = res.flag_data || {};
        }

        this.setState({
          ...newState,
          errorLoadDocument: false,
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.props.navigate('/reviewer');
        } else {
          this.onDocumentLoadError();
        }
      });
  }

  loadNextDocumentData(documentId) {
    const initialState = this.getInitialState();
    const isFlagged = this.props.location.state.flagged;

    this.setState({ ...initialState, isFlagged, loading: true }, () => {
      const getDocument = isFlagged ? getNextFlaggedDocument : getNextDocument;
      this.loadDocumentData(getDocument, documentId);
    });
  }

  loadPreviousDocumentData(documentId) {
    const initialState = this.getInitialState();
    const isFlagged = this.props.location.state.flagged;

    this.setState({ ...initialState, isFlagged, loading: true }, () => {
      const getDocument = isFlagged
        ? getPreviousFlaggedDocument
        : getPreviousDocument;
      this.loadDocumentData(getDocument, documentId);
    });
  }

  loadFileDocument() {
    getDocumentOriginal(this.state.documentId).then((resBlob) => {
      const myFile = new File([resBlob], 'file.pdf');
      this.setState({ pdfFile: myFile });
    });
  }

  onDocumentLoadSuccess(document) {
    this.setState({
      numPages: document.numPages,
      errorLoadDocument: false,
    });
  }

  onDocumentLoadError = () => {
    this.setState({ loading: false, errorLoadDocument: true });
  };

  handleOCRClick() {
    this.setState({ showOCR: true });
  }

  handlePDFClick() {
    this.setState({ showOCR: false });
  }

  handleShowModal(modal) {
    this.setState({ currentModal: modal });
  }

  handleHideModal() {
    this.setState({ currentModal: null });
  }

  handleShowToast(type, text) {
    showToast(type, text);
  }

  static filterOutChangedFields(editedFieldsIds, editData) {
    return editedFieldsIds.reduce(
      (newEditData, fieldId) => ({
        ...newEditData,
        [fieldId]: editData[fieldId],
      }),
      {},
    );
  }

  handleOnMouseDown = () => {
    // needed for issue fix when clicked inside of selection (otherwise it wouldn't show correct isCollapsed value)
    window.getSelection().empty();

    this.unhighlightHtmlTokens();
    // needed for issue fix when selecting is started outside of document and ends inside
    this.setState({ fieldTokenIndex: 0 });
  };

  submitReview(flagged_data = null) {
    const {
      documentId,
      childFieldsComponent: {
        state: { editData, editedFieldsIds },
      },
    } = this.state;

    const dataFieldsToSubmit = Page.filterOutChangedFields(
      editedFieldsIds,
      editData,
    );
    const document_information = preprocessEditData(dataFieldsToSubmit);

    const requestData = {
      document_id: documentId,
      document_information,
      flagged_data,
    };

    return reviewDocument(requestData);
  }

  handleOnSaveClick() {
    const { documentName } = this.state;
    this.setState({ enableSaveButton: false });

    this.submitReview()
      .then(() => {
        this.handleShowToast(
          SUCCESS,
          `Document ${documentName} reviewed and saved.`,
        );
        this.loadNextDocumentData(this.state.documentId);
      })
      .catch(() => {
        this.handleShowToast(ERROR, `Review for ${documentName} failed.`);
        this.setState({ enableSaveButton: true });
      });
  }

  handleSaveFlaggedClick(flagged_data) {
    const { documentName } = this.state;

    this.submitReview(flagged_data)
      .then(() => {
        this.handleShowToast(
          SUCCESS,
          `Document ${documentName} reviewed and saved.`,
        );
        this.loadNextDocumentData(this.state.documentId);
      })
      .catch(() => {
        this.handleShowToast(ERROR, `Review for ${documentName} failed.`);
      });
  }

  handleDismissChangesClick(isNext) {
    const { documentName, documentId } = this.state;

    this.handleShowToast(SUCCESS, `Changes for ${documentName} dismissed.`);
    if (isNext) {
      this.loadNextDocumentData(documentId);
    } else {
      this.loadPreviousDocumentData(documentId);
    }
  }

  handleSaveChangesAndContinueClick(isNext) {
    const { documentName, documentId } = this.state;

    this.submitReview().then(() => {
      this.handleShowToast(SUCCESS, `Changes for ${documentName} saved.`);
      if (isNext) {
        this.loadNextDocumentData(documentId);
      } else {
        this.loadPreviousDocumentData(documentId);
      }
    });
  }

  handleOnNextClick() {
    const fieldsChanged = !!this.state.childFieldsComponent.state
      .editedFieldsIds.length;

    if (fieldsChanged) {
      this.handleShowModal({
        modal: MODAL_LEAVE_REVIEW,
        navigationDirection: 'next',
      });
    } else {
      this.loadNextDocumentData(this.state.documentId);
    }
  }

  handleOnPreviousClick() {
    const fieldsChanged = !!this.state.childFieldsComponent.state
      .editedFieldsIds.length;

    if (fieldsChanged) {
      this.handleShowModal({
        modal: MODAL_LEAVE_REVIEW,
        navigationDirection: 'previous',
      });
    } else {
      this.loadPreviousDocumentData(this.state.documentId);
    }
  }

  renderSaveNFlagModal() {
    return (
      <EcModal
        modalType={MODAL_SAVE_AND_FLAG}
        title="Save and Flag"
        width="560px"
        hideModal={this.handleHideModal}
        handleActionClick={this.handleSaveFlaggedClick}
      />
    );
  }

  renderLeaveReviewModal() {
    const {
      currentModal: { navigationDirection },
    } = this.state;

    return (
      <EcModal
        modalType={MODAL_LEAVE_REVIEW}
        title="Suspend Highlight?"
        width="560px"
        hideModal={this.handleHideModal}
        handleDismissChanges={() =>
          this.handleDismissChangesClick(navigationDirection === 'next')
        }
        handleActionClick={() =>
          this.handleSaveChangesAndContinueClick(navigationDirection === 'next')
        }
      />
    );
  }

  renderFlaggedMetadata() {
    const { classes } = this.props;
    const { date_added, user_added, reasons, note } = this.state.flagData;

    return (
      <div className={classes.metadataWrapper}>
        <div className={classes.metadataHeader}>
          <div className={classes.flagWrapper}>
            <FlagIcon size="32" color="#eb890f" />
          </div>
          <div className={classes.metaAbout}>
            This document was flagged by <span>{user_added}</span> on
            <span>
              &nbsp;
              {formatDate(parseDate(date_added), 'long')}
            </span>
            .
          </div>
        </div>
        <div className={classes.metadataSection}>
          <div className={classes.metadataSectionHeader}>
            REASONS FOR FLAGGING
          </div>
          <div className={classes.metadataReasonsContent}>
            {reasons &&
              reasons.map((item) => (
                <div className={classes.metadataReason} key={item}>
                  {item}
                </div>
              ))}
          </div>
        </div>
        <div className={classes.metadataSection}>
          <div className={classes.metadataSectionHeader}>ADDITIONAL NOTES</div>
          <div className={classes.metadataAdditional}>{note}</div>
        </div>
        <hr className={classes.horizontalSeparator} />
      </div>
    );
  }

  renderLoadingSpinner() {
    const { classes } = this.props;

    return (
      <div className={classes.loadingContainer} data-testid="loading-spinner">
        <LoadingSpinner size="medium" />
      </div>
    );
  }

  renderReviewerActions() {
    const { classes } = this.props;
    const { isFlagged, enableSaveButton, reviewsLeft } = this.state;

    return (
      <div className={classes.reviewerActions} data-testid="reviewer-actions">
        <EcButton
          text="Previous"
          iconLeft={<ChevronLeftIcon size="20" />}
          disabled={reviewsLeft === 0}
          onClick={this.handleOnPreviousClick}
        />
        <span className={classes.verticalSeparator} />
        <EcButton
          text="Next"
          iconRight={<ChevronRightIcon size="20" />}
          disabled={reviewsLeft === 0}
          onClick={this.handleOnNextClick}
        />
        <span className={classes.verticalSeparator} />
        <EcButton
          text="Save"
          yellow
          iconLeft={<CheckmarkIcon size="20" />}
          onClick={this.handleOnSaveClick}
          disabled={!enableSaveButton}
        />
        {!isFlagged && (
          <Fragment>
            <span className={classes.verticalSeparator} />
            <EcButton
              text="Save and Flag"
              iconLeft={<FlagIcon size="20" />}
              onClick={() =>
                this.handleShowModal({ modal: MODAL_SAVE_AND_FLAG })
              }
            />
          </Fragment>
        )}
      </div>
    );
  }

  renderReviewerHeader() {
    const { classes } = this.props;
    const { loading, reviewsLeft, isFlagged } = this.state;

    return (
      <div className={classes.reviewerHeader}>
        <button
          className={classes.reviewerBackAction}
          onClick={() => this.navigateBack()}
        >
          <BackIconWithHover />
        </button>
        <div className={classes.reviewerPageTitle}>
          <span className={classes.subTitle}>CONTRACT REVIEWER</span>
          <h2 className={classes.title}>
            {isFlagged ? 'Flagged - ' : null}
            Data Field Review
          </h2>
        </div>
        {loading ? null : (
          <div className={classes.headerMetaInfo}>
            <TimelapseIcon size="20" blue />
            <div>
              <span>{`${reviewsLeft} ${pluralize(
                'document',
                reviewsLeft,
              )}`}</span>{' '}
              left to review.
            </div>
          </div>
        )}
      </div>
    );
  }

  handleSubmitButtonStatus(enableSaveButton) {
    this.setState({ enableSaveButton });
  }

  unhighlightHtmlTokens() {
    this.setState({ shouldShowFieldHighlightControls: false });
    Array.from(document.getElementsByClassName('token-highlighted')).forEach(
      (tokenElement) => {
        tokenElement.classList.remove('token-highlighted');
      },
    );
  }

  highlightHtmlTokensArray(tokenIds) {
    if (!tokenIds) return null;

    tokenIds.forEach((tokenId) => {
      const tokenElement = document.getElementById(tokenId);
      if (tokenElement) tokenElement.classList.add('token-highlighted');
    });
  }

  highlightFieldToken(position) {
    const { selectedFieldToHighlight } = this.state;
    this.setState(
      { fieldTokenIndex: this.state.fieldTokenIndex + position },
      () => this.onKeyInformationFieldTokenSelect(selectedFieldToHighlight),
    );
  }

  onKeyInformationFieldTokenSelect(fieldToHighlight) {
    const { showOCR } = this.state;
    if (!showOCR) return;
    this.unhighlightHtmlTokens();
    const { fieldTokenIndex, selectedFieldToHighlight } = this.state;

    this.setState(
      {
        fieldTokenIndex:
          selectedFieldToHighlight &&
          selectedFieldToHighlight.id !== fieldToHighlight.id
            ? 0
            : fieldTokenIndex,
      },
      () => {
        const { fieldTokenIndex } = this.state;
        this.highlightHtmlTokensArray(
          fieldToHighlight.html_tokens[fieldTokenIndex],
        );

        if (
          !fieldToHighlight.html_tokens ||
          !fieldToHighlight.html_tokens[fieldTokenIndex]
        )
          return null;

        if (fieldToHighlight.html_tokens[fieldTokenIndex].length > 0) {
          const firstToken = document.getElementById(
            fieldToHighlight.html_tokens[fieldTokenIndex][0],
          );

          if (firstToken)
            firstToken.scrollIntoView({ behavior: 'auto', block: 'center' });

          this.setState({
            selectedTokens: fieldToHighlight.html_tokens,
            shouldShowFieldHighlightControls:
              fieldToHighlight.html_tokens.length > 1 ? true : false,
            selectedFieldToHighlight: { ...fieldToHighlight },
          });
        }
      },
    );
  }

  renderFieldTokensControls() {
    const { classes } = this.props;
    const { fieldTokenIndex, selectedFieldToHighlight, showOCR } = this.state;

    if (showOCR) {
      return (
        <div className={classes.fieldTokenWrapper}>
          <div className={classes.fieldTokenName}>
            {selectedFieldToHighlight.name}
          </div>
          <div className={classes.fieldTokenControlButtons}>
            <button
              className={
                fieldTokenIndex === 0
                  ? classes.fieldTokenControlDisabled
                  : classes.fieldTokenControlEnabled
              }
              onClick={() => this.highlightFieldToken(-1)}
            >
              <ChevronLeftIcon />
            </button>
            <button
              className={
                fieldTokenIndex ===
                selectedFieldToHighlight.html_tokens.length - 1
                  ? classes.fieldTokenControlDisabled
                  : classes.fieldTokenControlEnabled
              }
              onClick={() => this.highlightFieldToken(1)}
            >
              <ChevronRightIcon />
            </button>
            <button
              className={classes.closeFieldTokenHightlightButtons}
              onClick={() =>
                this.setState({
                  shouldShowFieldHighlightControls: false,
                  fieldTokenIndex: 0,
                })
              }
            >
              <CloseIcon color="#fff" size="16" />
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { classes } = this.props;
    const {
      documentId,
      documentName,
      loading,
      errorLoadDocument,
      htmlData,
      numPages,
      pdfFile,
      dataFields,
      isFlagged,
      fileType,
      currentModal,
      docSideInfoStyle,
      showOCR,
      shouldShowFieldHighlightControls,
    } = this.state;

    const keyInfoStyle = {
      maxHeight: parseInt(docSideInfoStyle.maxHeight) + 90 + 'px',
      overflow: docSideInfoStyle.overflow,
    };

    return (
      <div className={classes.reviewerViewPage}>
        {this.renderReviewerHeader()}
        {loading ? (
          this.renderLoadingSpinner()
        ) : (
          <div className={classes.reviewerContents}>
            <div className={classes.reviewerContentsHeader}>
              <div className={classes.documentNameWrapper}>
                <DocumentStrokedIcon color={black4} />
                <div className={classes.documentNameDetails}>
                  <h4>{documentName}</h4>
                  <div>
                    <span>Current Document ID: </span>
                    {documentId}
                  </div>
                </div>
              </div>
              {this.renderReviewerActions()}
            </div>
            <div className={classes.reviewerContentsWrapper} role="document">
              <div className={classes.documentViewerWrapper}>
                <EcDocumentViewer
                  errorLoadDocument={errorLoadDocument}
                  htmlData={htmlData}
                  warningState={false}
                  fileType={fileType}
                  numPages={numPages}
                  pdfFile={pdfFile}
                  handleOCRClick={this.handleOCRClick}
                  handlePDFClick={this.handlePDFClick}
                  loadDocument={this.loadFileDocument}
                  onMouseDownHandler={this.handleOnMouseDown}
                  onDocumentLoadSuccessHandler={this.onDocumentLoadSuccess}
                  onDocumentLoadErrorHandler={this.onDocumentLoadError}
                  containerStyle={docSideInfoStyle}
                />
                {shouldShowFieldHighlightControls &&
                  this.renderFieldTokensControls()}
              </div>
              <div
                className={classes.reviewerContentSideInfo}
                style={keyInfoStyle}
              >
                {isFlagged && this.renderFlaggedMetadata()}
                <ReviewerDataFields
                  showOCR={showOCR}
                  dataFields={dataFields}
                  onFieldTokenClick={this.onKeyInformationFieldTokenSelect}
                  onRef={(ref) => this.setState({ childFieldsComponent: ref })}
                  handleSubmitButtonStatus={this.handleSubmitButtonStatus}
                />
              </div>
            </div>
            {currentModal &&
              currentModal.modal === MODAL_SAVE_AND_FLAG &&
              this.renderSaveNFlagModal()}
            {currentModal &&
              currentModal.modal === MODAL_LEAVE_REVIEW &&
              this.renderLeaveReviewModal()}
          </div>
        )}
      </div>
    );
  }
}

export default injectSheet(styles)(withFlags(withRouting(Page)));
