import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import { ENTER } from '~/constants/keyCodes';

import EcButton from '../../Shared/EcButton';
import EcModalCard from '../../Shared/EcModalCard';
import CheckmarkIcon from '../../Shared/Icons/CheckmarkIcon';
import styles from './DocumentsColumnFieldsSaveModal.styles';

class DocumentsColumnFieldsSaveModal extends Component {
  constructor(props) {
    super(props);

    this.handleOnKeyboardPress = this.handleOnKeyboardPress.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);

    this.state = {
      itemName: this.props.itemsCurrentName,
      itemDescription: this.props.itemsCurrentDescription,
      bucketInfo: null,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleOnKeyboardPress, true);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleOnKeyboardPress, true);
  }

  handleOnKeyboardPress(event) {
    if (event.keyCode === ENTER) {
      this.handleActionClick();
    }
  }

  handleInputChange(itemName) {
    this.setState({ itemName });
  }

  handleTextAreaChange(itemDescription) {
    this.setState({ itemDescription });
  }

  handleInputFocus(event) {
    event.target.select();
  }

  handleActionClick() {
    const { itemName } = this.state;
    const { handleSaveView } = this.props;

    if (!itemName) {
      return;
    }

    handleSaveView(itemName);
  }

  render() {
    const { itemName } = this.state;
    const { classes, title, labelText, hideModal } = this.props;

    return (
      <EcModalCard
        title={title}
        content={
          <div className={classes.modalBody}>
            <div className={classes.modalBodyContent}>
              <label>{labelText}</label>
              <input
                className={classes.modalInput}
                value={itemName}
                onChange={(event) => {
                  this.handleInputChange(event.target.value);
                }}
                autoFocus
                onFocus={(event) => {
                  this.handleInputFocus(event);
                }}
                data-testid="documents-column-fields-save-modal-text-input"
              />
            </div>
          </div>
        }
        footer={
          <>
            <EcButton white borderless text="Cancel" onClick={hideModal} />
            <EcButton
              disabled={!itemName}
              yellow
              iconLeft={<CheckmarkIcon size="20" />}
              text="Save"
              onClick={this.handleActionClick}
              data-testid="documents-column-fields-save-modal-confirm-button"
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

DocumentsColumnFieldsSaveModal.propTypes = {
  title: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  itemsCurrentName: PropTypes.string,
  editItemName: PropTypes.func,
  createItemName: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
};

export default injectSheet(styles)(DocumentsColumnFieldsSaveModal);
