import {
  black2,
  black4,
  evisortRed,
} from '../../../assets/shared-styles/general';

const styles = {
  modalBody: {
    padding: '16px',
    color: black2,
    fontSize: '15px',
    lineHeight: '1.47',
  },
  modalInfo: {
    margin: '0 0 24px 0',
  },
  multipleValuesContainer: {
    alignItems: 'center',
    marginTop: '15px',
  },
  multipleValues: {
    marginLeft: '30px',
    position: 'relative',
    top: '-30px',
  },
  multipleValuesEmpty: {
    color: black4,
    fontSize: '15px',
    display: 'flex',
    marginTop: '2px',
  },
  errorMessage: {
    margin: '20px',
    color: evisortRed,
    fontSize: '15px',
    textAlign: 'center',
  },
};

export default styles;
