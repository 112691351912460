import React, { Component } from 'react';

import {
  AlertsFilters,
  AlertsList,
  createAlert,
  getAlerts,
  updateAlert,
} from '~/components/AlertsPage';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import withSideCollapse from '~/components/Shared/HOCs/withSideCollapse';
import { PAGE_START } from '~/constants/page';
import { Layout, PageLayout } from '~/eds';
import { QueryParamType } from '~/enums';
import { MODAL_ALERT } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';

const AlertFiltersWithSideCollapse = withSideCollapse(AlertsFilters);

class Page extends Component {
  constructor(props) {
    super(props);

    this.loadAlerts = this.loadAlerts.bind(this);
    this.onItemCreate = this.onItemCreate.bind(this);
    this.onItemUpdate = this.onItemUpdate.bind(this);
    this.handleOnPageChange = this.handleOnPageChange.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleOnFiltersChange = this.handleOnFiltersChange.bind(this);
    this.handleDeleteItems = this.handleDeleteItems.bind(this);
    this.handleUpdateItem = this.handleUpdateItem.bind(this);

    this.state = {
      alerts: [],
      listLoading: true,
      listError: false,
      page: 1,
      pageCount: 1,
      filteredResultsCount: null,
      searchQueries: null,
      currentModal: null,
    };
  }

  componentDidMount() {
    const page = getPageSearchQueryByKey(QueryParamType.Page, PAGE_START);
    this.setState({ page }, () => {
      this.loadAlerts();
      updatePageSearchQuery({
        [QueryParamType.Page]: page,
      });
    });
  }

  loadAlerts() {
    const { page, searchQueries } = this.state;
    this.setState({ listLoading: true, listError: false });

    getAlerts(page, searchQueries)
      .then(({ results, count }) =>
        this.setState({
          alerts: results,
          pageCount: count,
          listLoading: false,
        }),
      )
      .catch(() => this.setState({ listLoading: false, listError: true }));
  }

  onItemCreate(data) {
    createAlert(data)
      .then((res) => {
        this.setState({ page: PAGE_START }, () => {
          this.loadAlerts();
          updatePageSearchQuery({
            [QueryParamType.Page]: PAGE_START,
          });
        });
        showToast(SUCCESS, `Alert "${res.name}" has been created!`);
      })
      .catch(() => {
        showToast(ERROR, 'An error occurred while creating the alert.');
      });
  }

  onItemUpdate(id, data) {
    updateAlert(id, data)
      .then((res) => {
        this.handleUpdateItem(res);
        showToast(SUCCESS, `Alert "${res.name}" has been updated!`);
      })
      .catch(() => {
        showToast(ERROR, 'An error occurred while updating the alert.');
      });
  }

  handleUpdateItem(alert) {
    const { id } = alert;
    const { alerts } = this.state;

    const newAlerts = [...alerts];

    const alertIndex = newAlerts.map((al) => al.id).indexOf(id);
    newAlerts[alertIndex] = { ...alert };

    this.setState({ alerts: newAlerts });
  }

  handleDeleteItems(selectedItems) {
    const newAlerts = this.state.alerts.filter(
      (alert) => !selectedItems.includes(alert.id),
    );
    this.setState({ alerts: newAlerts });
  }

  handleShowModal(modal) {
    this.setState({ currentModal: modal });
  }

  handleHideModal() {
    this.setState({ currentModal: null });
  }

  handleOnPageChange(clickedPage) {
    const selectedPage = clickedPage.selected + 1;

    this.setState({ page: selectedPage }, () => {
      this.loadAlerts();
      updatePageSearchQuery({ [QueryParamType.Page]: selectedPage }, true);
    });
  }

  handleOnFiltersChange(searchQueries) {
    this.setState({ searchQueries }, () =>
      this.handleOnPageChange({ selected: 0 }),
    );
  }

  renderAddAlertModal() {
    return (
      <EcModal
        modalType={MODAL_ALERT}
        width="1080px"
        handleAlertAction={this.onItemCreate}
        hideModal={this.handleHideModal}
      />
    );
  }

  render() {
    const {
      alerts,
      page,
      pageCount,
      listLoading,
      currentModal,
      listError,
      searchQueries,
    } = this.state;

    return (
      <PageLayout
        title="Alerts"
        actions={[
          {
            text: 'Add Alert',
            level: 'primary',
            onClick: () => this.handleShowModal(MODAL_ALERT),
          },
        ]}
      >
        <Layout flex="auto">
          <AlertFiltersWithSideCollapse
            handleOnSearch={this.handleOnFiltersChange}
            resultsCount={!listLoading ? pageCount : null}
          />
          <AlertsList
            alerts={alerts}
            page={page}
            pageCount={pageCount}
            listLoading={listLoading}
            listError={listError}
            onItemUpdate={this.onItemUpdate}
            onItemCreate={this.onItemCreate}
            handleOnPageChange={this.handleOnPageChange}
            handleDeleteItems={this.handleDeleteItems}
            handleUpdateItem={this.handleUpdateItem}
            handleRetry={this.loadAlerts}
            searchQueries={searchQueries}
          />
        </Layout>
        {currentModal === MODAL_ALERT && this.renderAddAlertModal()}
      </PageLayout>
    );
  }
}

export default Page;
