import React from 'react';

import { SharedModalProps } from '../../types';
import { useModal } from './useModal';

type Callback = () => void;

/**
 * A DetailModal is a simple modal used to render details.  It never supports any actions.
 */
export const useDetailModal = (
  props: SharedModalProps,
): [React.ReactNode, Callback, Callback] => {
  return useModal({
    ...props,
    // the following props should not be overwritable
    cancelText: 'Close',
    disableFooter: false,
    primaryAction: undefined,
    secondaryActions: [],
  });
};
