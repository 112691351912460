import { debounce, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';

import { Icon } from '~/eds';

import stylesFactory from './EcSelect.styles';

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon icon="chevron-down" />
      </components.DropdownIndicator>
    )
  );
};

const Input = (props) => {
  const ariaExpanded = props.selectProps.menuIsOpen;
  const label = props['aria-label'];
  return (
    <components.Input
      {...props}
      aria-label={`${label} combo box ${
        ariaExpanded ? 'expanded' : 'collapsed'
      }`}
    />
  );
};

const EcSelect = (props) => {
  const {
    components,
    customDropdownIndicator,
    defaultValue,
    value,
    error = false,
    id,
    isCreatable = false,
    isDisabled = false,
    isSearchable = true,
    options,
    placeholder = 'Select',
    onChange,
    debounceTimeout = undefined,
    onInputChange = noop,
  } = props;

  const SelectComponent = isCreatable ? Creatable : Select;
  const componentsPropObject = {
    ...components,
    IndicatorSeparator: null,
    Input,
  };

  const handleInputChange = useCallback(
    debounceTimeout ? debounce(onInputChange, debounceTimeout) : onInputChange,
    [],
  );

  return (
    <SelectComponent
      {...props}
      id={id}
      defaultValue={defaultValue}
      value={value}
      options={options}
      styles={stylesFactory(error)}
      onChange={onChange}
      onInputChange={handleInputChange}
      components={
        customDropdownIndicator
          ? { ...componentsPropObject, DropdownIndicator }
          : componentsPropObject
      }
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      placeholder={placeholder}
    />
  );
};

EcSelect.propTypes = {
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  error: PropTypes.bool,
  height: PropTypes.string,
  id: PropTypes.string,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

export default EcSelect;
