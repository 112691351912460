import { chunk } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Box, CollapsibleText, Layout, Paginate, Text } from '~/eds';

const PAGE_SIZE = 25;
// TODO: ui.Table does not have the appropriate features and behaviors and
//       this should be refactored once EDS has formally implemented table specs
const SampleTable = ({ samples }) => {
  const [totalCount, setTotalCount] = useState(0);
  const [sampleChunks, setSampleChunks] = useState([[]]);

  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    setTotalCount(samples.length ?? 0);
    setSampleChunks(chunk(samples, PAGE_SIZE));
  }, [samples]);

  const commonProps = {
    w: '100%',
    p: 2,
    align: 'center',
    borderBottom: 'border.divider',
  };

  return (
    <Box boxShadow="base" border="border.divider" borderRadius="m">
      {samples.length > 1 && (
        <Layout {...commonProps} justify="right" styles={{ minHeight: '50px' }}>
          <Paginate
            name="sample-table-paginate"
            pageIndex={pageIndex}
            pageSize={PAGE_SIZE}
            totalCount={totalCount}
            onUpdatePageIndex={setPageIndex}
          />
        </Layout>
      )}
      <Layout
        {...commonProps}
        justify="space-between"
        px={4}
        styles={{ minHeight: '50px' }}
      >
        <Text>Sample</Text>
        <Text>Represents Clause</Text>
      </Layout>
      {sampleChunks[pageIndex - 1]?.map(({ Text: text, Label: label }, i) => (
        <Layout
          {...commonProps}
          key={pageIndex * PAGE_SIZE + i}
          justify="space-between"
          borderBottom={i < PAGE_SIZE - 1 ? 'border.divider' : 'none'}
          px={4}
          styles={{ minHeight: '65px' }}
        >
          <Box w="90%">
            <CollapsibleText limit={150} text={text} />
          </Box>
          <Layout w="10%" align="center" justify="right">
            <Text>{label === 0 ? 'No' : 'Yes'}</Text>
          </Layout>
        </Layout>
      ))}
    </Box>
  );
};

export default SampleTable;
