import { colors as colorTokens } from '../../../tokens/colors';
import { alphas } from '../../../tokens/colors/system/alphas';
import { StatusType } from '../../../types';

const alphaGradient = (alpha: keyof typeof alphas) => ({
  backgroundImage: `linear-gradient(${alphas[alpha]} 0 0)`,
});

const status = ({
  status,
  variant = 'secondary',
}: {
  status: StatusType;
  variant?: 'primary' | 'secondary';
}) => {
  const statusColor = `status.${status}` as const;

  // if status color is not found in color tokens, skip applying the style rule.
  if (!colorTokens[statusColor]) {
    return;
  }

  switch (variant) {
    case 'primary': {
      return {
        backgroundColor: statusColor,
        color: 'inverse.text.primary',
      };
    }
    case 'secondary':
    default:
      return {
        backgroundColor: statusColor,
        color: 'text.primary',
        ...alphaGradient('lighten-92'),
      };
  }
};

export const colors = {
  alphaGradient,
  status,
};
