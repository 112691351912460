import React, { useEffect, useRef } from 'react';
import { Mention, MentionsInput } from 'react-mentions';

import {
  getFieldColor,
  getFieldTypeIcon,
} from '~/components/Workflow/Workflow.utils';
import { Box, FlexLayout, Icon, Text, theme } from '~/ui';
import { getOptions } from '~/utils/array';
import { tokenizeContent } from '~/utils/comments';
import { getFieldDisplayName } from '~/utils/workflow';

const border = `solid 1px ${theme.colors['black-alpha-10']}`;
const fontSize = theme.text['s-dense'].fontSize;

const styles = {
  mentionsInput: {
    highlighter: {
      fontSize,
    },
    input: {
      border: 0,
      fontSize,
      outline: 0,
      minHeight: '18px',
    },
    suggestions: {
      borderRadius: theme.radii['m'],
      list: {
        backgroundColor: 'white',
        border,
        borderRadius: theme.radii['m'],
        maxHeight: '240px',
        overflowY: 'auto',
        padding: `${theme.space[3]} 0`,
      },
      item: {
        fontSize,
        padding: `${theme.space[2]} ${theme.space[6]}`,
        '&focused': {
          background: theme.colors['gray-300'],
          color: theme.colors['blue-500'],
        },
      },
    },
  },
  mention: {
    background: 'rgba(0, 159, 125, 0.2)',
  },
};

function ArchiveLocationInput({
  archiveLocation,
  fields,
  id,
  label,
  placeholder,
  formatOutput,
  onChange,
  errorMessage,
}) {
  const fieldMentionsData = getOptions(Object.values(fields), 'id', 'name').map(
    ({ value, label }) => ({
      id: value,
      display: label,
    }),
  );

  const toString = (archiveLocation) => {
    return archiveLocation
      .map((item) => {
        if (item.type === 'field') {
          return `{{field:${item.value}}}`;
        }
        return item.value;
      })
      .join('');
  };

  const fromString = (string) => {
    const tokens = tokenizeContent(string || '', { field: (id) => id });
    return tokens.map((token) => {
      if (token.type === 'field') {
        return { type: 'field', value: token.content };
      }
      return { type: 'user-content', value: token.content };
    });
  };

  function renderFieldSuggestion({ id }, _, highlightedDisplay) {
    const field = fields[id];
    return (
      <FlexLayout alignItems="center" space={2}>
        <Icon
          bg={getFieldColor(field)}
          color="white"
          icon={getFieldTypeIcon(field)}
          p={1}
          sx={{ borderRadius: 'm' }}
        />
        <Text color="gray-900" variant="s-dense">
          {highlightedDisplay}
        </Text>
      </FlexLayout>
    );
  }

  function displayTransform(id) {
    return getFieldDisplayName(fields[id]);
  }

  const mentionRef = useRef();

  useEffect(() => {
    if (mentionRef.current) {
      mentionRef.current.maxLength = 256;
    }
  }, [mentionRef.current]);

  return (
    <FlexLayout flexDirection="column" space={2}>
      <Text variant="xs-dense-caps">{label}</Text>
      <FlexLayout
        id={id}
        alignItems="center"
        bg="white"
        px={4}
        py={3}
        space="4"
        sx={{
          border: 'border',
          borderRadius: 'm',
          borderColor: errorMessage ? 'red-400' : 'gray-200',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MentionsInput
            placeholder={placeholder}
            style={styles.mentionsInput}
            value={toString(archiveLocation)}
            onChange={(_, newValue) => {
              const formattedValue = formatOutput
                ? formatOutput(newValue)
                : newValue;
              onChange(fromString(formattedValue));
            }}
            singleLine={true}
            inputRef={mentionRef}
          >
            <Mention
              data={fieldMentionsData}
              markup="{{field:__id__}}"
              style={styles.mention}
              trigger={/(#([A-Za-z0-9_.]*))$/}
              displayTransform={displayTransform}
              renderSuggestion={renderFieldSuggestion}
            />
          </MentionsInput>
        </Box>
        <Text color="gray-600" variant="2xs-dense-bold" sx={{ flexShrink: 0 }}>
          # to add tags
        </Text>
      </FlexLayout>
      {errorMessage && (
        <Text mt={2} color="red-600" variant="2xs-dense">
          {errorMessage}
        </Text>
      )}
    </FlexLayout>
  );
}

export default ArchiveLocationInput;
