import React, { useState } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import { FileInput, Files, Layout, Modal, RadioGroup, useToast } from '~/eds';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { getUserClientInfo } from '~/utils/user';

import { DEFAULT_ACCEPTED_FILES } from '../constants';
import { BasePartyModalProps } from '../types';

export const UploadPartyModal = ({ onHide, clientId }: BasePartyModalProps) => {
  const user = useCurrentUser();

  const { toast } = useToast();

  const [files, setFiles] = useState<File[]>([]);
  const [isInternal, setIsInternal] = useState(true);

  const [
    uploadParty,
    { isLoading: isUploading },
  ] = api.endpoints.uploadParty.useMutation();

  const handleRemoveFile = (
    file: File,
    removedFileIndex: number | undefined,
  ) => {
    if (removedFileIndex !== undefined) {
      const updatedFiles = files.filter((_file, i) => removedFileIndex !== i);
      setFiles(updatedFiles);
    }
  };

  const children = (
    <Layout preset="sections">
      <RadioGroup
        name="Upload Party"
        title="Select Party"
        options={[
          {
            label: 'Internal Parties',
            value: true,
            info: 'Upload a party file to you internal list.',
          },
          {
            label: 'Counter Parties',
            value: false,
            info: 'Upload a party file to your counter list.',
          },
        ]}
        onChange={(updatedValue) => {
          setIsInternal(updatedValue ?? false);
        }}
        value={isInternal}
      />
      <FileInput
        accept={DEFAULT_ACCEPTED_FILES}
        isMulti={true}
        name="upload"
        onChange={(updatedFiles) => {
          setFiles(updatedFiles ?? []);
        }}
        value={null}
      />
      <Files
        files={files}
        onRemoveFile={handleRemoveFile}
        title="Party Lists"
      />
    </Layout>
  );

  return (
    <Modal
      children={children}
      enableHideOnPrimaryAction={true}
      isVisible={true}
      primaryAction={{
        disabled: files.length === 0,
        isLoading: isUploading,
        onClick: () => {
          trackSegment('User Uploads Document', {
            fileNames: files.map((file) => file.name),
            groupId: clientId,
            userId: getUserClientInfo(user),
          });
          uploadParty({
            clientId,
            isInternal,
            files,
          })
            .unwrap()
            .then(() => {
              toast({
                message: `Your file(s) has been added successfully.`,
                status: 'success',
              });
            })
            .catch((error) => {
              toast({
                message: `${error.response.data.detail}`,
                status: 'danger',
              });
            });
          onHide();
        },
        text: 'Upload Party',
        tooltip:
          files.length === 0
            ? 'Please upload a file to continue.'
            : 'Upload your Parties',
        variant: 'primary',
      }}
      onHide={onHide}
      title="Upload "
    />
  );
};
