import PT from 'prop-types';
import React from 'react';

import { Button } from '~/eds';
import { FlexLayout } from '~/ui';

import AndOrNode from './AndOrNode';
import FieldNode from './FieldNode';
import {
  ANDOR,
  createFieldNode,
  createSectionNode,
  FIELD,
  ROOT,
  SECTION,
  testNodeType,
} from './utils';

function Tree(props) {
  const {
    maxNestLevel,
    node,
    nestLevel = 0,
    onAddNode,
    onRemoveNode,
    onUpdateNode,
  } = props;
  const { id, children, type } = node;
  const isRootNode = testNodeType(node, ROOT);
  const isSectionNode = testNodeType(node, SECTION);

  let Content;
  switch (type) {
    case ROOT:
    case SECTION:
      // use index as component keys because the tree IDs are always regenerated
      Content = children.map((child, i) => (
        <Tree {...props} key={i} node={child} nestLevel={nestLevel + 1} />
      ));
      break;
    case FIELD:
      Content = (
        <FieldNode
          node={node}
          onChange={(data) => onUpdateNode(id, data)}
          onRemove={() => onRemoveNode(id)}
        />
      );
      break;
    case ANDOR:
      Content = (
        <AndOrNode
          node={node}
          onChange={(updatedValue) => onUpdateNode(id, { value: updatedValue })}
        />
      );
      break;
    default:
      break;
  }

  const isMaxNestLevelExceeded = nestLevel > maxNestLevel;

  return (
    <FlexLayout
      bg={isSectionNode && nestLevel > 0 ? 'black-alpha-04' : undefined}
      flexDirection="column"
      px={4}
      py={isSectionNode ? 4 : undefined}
      space={6}
      sx={{ borderRadius: 'm' }}
      role={isSectionNode ? 'region' : undefined}
    >
      {Content}
      {(isRootNode || isSectionNode) && (
        <FlexLayout justifyContent="space-between">
          <FlexLayout space={6}>
            <Button
              text="Add Rule"
              variant="action"
              onClick={() => onAddNode(id, createFieldNode())}
            />
            {nestLevel < maxNestLevel && (
              <Button
                disabled={isMaxNestLevelExceeded}
                text="Add Section"
                variant="action"
                onClick={() => onAddNode(id, createSectionNode())}
              />
            )}
          </FlexLayout>
          {isSectionNode && (
            <Button
              text="Remove Section"
              variant="action"
              onClick={() => onRemoveNode(id)}
            />
          )}
        </FlexLayout>
      )}
    </FlexLayout>
  );
}

Tree.propTypes = {
  maxNestLevel: PT.number,
  nestLevel: PT.number,
  node: PT.object.isRequired,
  onAddNode: PT.func.isRequired,
  onRemoveNode: PT.func.isRequired,
  onUpdateNode: PT.func.isRequired,
};

export default Tree;
