import React from 'react';

import Menu from '~/ui/components/Menu';

import ActionsIcon from '../Icons/ActionsIcon';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import SettingsIcon from '../Icons/SettingsIcon';

const IconSize = Object.freeze({
  SMALL: '20',
  REGULAR: '24',
});

const ButtonStyle = Object.freeze({
  SMALL: { width: '25px', height: '20px' },
  REGULAR: { width: '36px', height: '31px' },
});

function ActionsMenu({ icon, id, items, smallIcon, title, ...rest }) {
  let menuButtonIcon;
  if (icon === 'cog') {
    menuButtonIcon = <SettingsIcon />;
  } else if (icon === 'chevronDown') {
    menuButtonIcon = (
      <ChevronDownIcon size={smallIcon ? IconSize.SMALL : IconSize.REGULAR} />
    );
  } else {
    menuButtonIcon = (
      <ActionsIcon size={smallIcon ? IconSize.SMALL : IconSize.REGULAR} />
    );
  }

  return (
    <Menu
      {...rest}
      id={id}
      items={items}
      menuButtonProps={{
        content: menuButtonIcon,
        styles: {
          ...(smallIcon ? ButtonStyle.SMALL : ButtonStyle.REGULAR),
        },
      }}
      title={title}
    />
  );
}

export default ActionsMenu;
