import React from 'react';
import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
  integrationsSetSyncPairs,
} from '~/actions/integrations';
import { createSyncPair, getSyncPairs } from '~/api/evisync';
import { storageIntegrations } from '~/constants/integrations';
import { ManageSyncPairStageType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import { useRouting } from '~/routing';
import { ChipList, FlexLayout, Folder, Text } from '~/ui';
import { fileTypesToChips } from '~/utils';

import { resetSyncPairAuthData } from '../../util';
import Footer from '../Footer';
import SummaryRow from './SummaryRow';

function Summary({
  // connected,
  activeSyncPair,
  integrationsSetSyncPairs,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const {
    allowedSyncFileTypes,
    name,
    provider,
    evisortFolder,
    providerFolder,
  } = activeSyncPair;

  const { params } = useRouting();

  const { clientId } = params;

  const enableSkipEmptyFolders = useFlag(FlagType.SkipEmptyFolders);

  const { isLoading: isCreating, executor: executeCreate } = useAsync(
    createSyncPair,
    activeSyncPair,
    {
      errorToastMessage: 'Unable to create sync pair, please try again.',
      successHandler: () => {
        getSyncPairs({ clientId }).then((pairs) =>
          integrationsSetSyncPairs(pairs),
        );
        integrationsSetManageSyncPairStage(ManageSyncPairStageType.None);
      },
      successToastMessage: `${activeSyncPair.name} sync pair has been created.`,
    },
  );

  const handleGoToStage = (stage) => () => {
    integrationsSetManageSyncPairStage(stage);

    const authRequiredStages = [
      ManageSyncPairStageType.Provider,
      ManageSyncPairStageType.Authentication,
    ];

    if (authRequiredStages.includes(stage)) {
      integrationsSetActiveSyncPair(resetSyncPairAuthData(activeSyncPair));
    }
  };

  const handleBack = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.Folders);
  };

  return (
    <FlexLayout
      alignItems="stretch"
      flexDirection="column"
      sx={{ width: '100%' }}
    >
      <SummaryRow
        title="Provider"
        onChange={handleGoToStage(ManageSyncPairStageType.Provider)}
      >
        <Text color="gray-900" variant="s-dense">
          {name}
        </Text>
      </SummaryRow>
      <SummaryRow
        title="Authentication"
        onChange={handleGoToStage(ManageSyncPairStageType.Authentication)}
      >
        <Text color="gray-900" variant="s-dense">
          {storageIntegrations[provider].name} Account
        </Text>
      </SummaryRow>
      <SummaryRow
        title="Folders"
        onChange={handleGoToStage(ManageSyncPairStageType.Folders)}
      >
        <FlexLayout flexDirection="column" space={4}>
          <Folder
            folder={providerFolder}
            subtext={
              enableSkipEmptyFolders && !!activeSyncPair?.skipEmptyFolders
                ? 'Skip empty folders'
                : ''
            }
          />
          <Folder folder={evisortFolder} />
        </FlexLayout>
      </SummaryRow>
      <SummaryRow
        title="File types"
        onChange={handleGoToStage(ManageSyncPairStageType.Folders)}
      >
        <ChipList chips={fileTypesToChips(allowedSyncFileTypes)} limit={100} />
      </SummaryRow>
      <Footer
        leftAction={{
          text: 'Back',
          onClick: handleBack,
        }}
        rightAction={{
          id: OnboardingIdType.ConfirmSyncPair,
          isLoading: isCreating,
          text: 'Set up Sync Pair',
          onClick: executeCreate,
        }}
      />
    </FlexLayout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetSyncPairs,
  integrationsSetManageSyncPairStage,
})(Summary);
