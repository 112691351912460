import React from 'react';

import {
  TicketList,
  TicketListV2,
} from '~/components/Workflow/WorkflowTicketsPage';
import { FlagType, useFlag, withFlags } from '~/flags';

const Page = (props: any) => {
  const isTicketListWithOpenSearchEnabled = useFlag(
    FlagType.TicketListWithOpenSearch,
  );

  const TicketListComponent = withFlags(
    isTicketListWithOpenSearchEnabled ? TicketListV2 : TicketList,
  );

  // @ts-ignore
  return <TicketListComponent {...props} />;
};

export default Page;
