import { PermissionGroupAttributes, RoleAttributes } from '~/redux/api/methods';

export const permissionGroupsToInfoItem = (
  permissionGroup: PermissionGroupAttributes,
  permissions: RoleAttributes['permissions'],
) => {
  return {
    title: permissionGroup.name,
    type: 'descriptions' as const,
    props: permissionGroup.permissions
      .map((permission) => {
        return {
          id: permission.id,
          title: permission.name,
          description: permission.description,
        };
      })
      .filter((permission) => permissions.includes(permission.id)),
  };
};
