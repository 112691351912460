import React, { memo, useEffect, useMemo, useState } from 'react';

import { Action } from '../../types';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { Layout } from '../Layout';

export interface Panel_DEPRECATED<V> {
  action: Action<V>;
  component: React.ReactNode;
}

interface Props<V extends string> {
  /** Items that will be displayed in sidebar. */
  panels: Record<V, Panel_DEPRECATED<V>>;
  /** is the panel expanded. */
  isExpanded?: boolean;
  /** Sets the selected panel */
  selectedPanel?: V;
  /** sidebar content width */
  width?: 's' | 'm' | 'fill';
  /** The distance from the top 's' would be a small distance (64px) and m would be a medium one (112px) all calculate according to navbar*/
  topDistance?: 's' | 'm';
  /** optional function invoked when sidebar closes */
  onClose?: () => void;
  /** callback called whenever a panel is selected */
  onUpdate?: (panel: V) => void;
}

const NAVBAR_HEIGHT = 64;
const SUBNAVBAR_HEIGHT = 48;
const RIGHT_SPACE = '48px';

export const PageSidebar = memo(
  ({
    isExpanded,
    selectedPanel: overridingPanel,
    panels,
    topDistance = 's',
    width = 's',
    onClose,
    onUpdate,
  }: Props<string>) => {
    const [selectedPanel, setSelectedPanel] = useState(
      overridingPanel || Object.keys(panels)[0],
    );
    const contentWidth = getWidth(width);

    const handleClose = () => {
      onClose?.();
    };

    useEffect(() => {
      if (overridingPanel && overridingPanel !== selectedPanel) {
        setSelectedPanel(overridingPanel);
      }
    }, [overridingPanel]);

    const topPositionStyles = useMemo(() => {
      const top =
        topDistance === 's' ? NAVBAR_HEIGHT : NAVBAR_HEIGHT + SUBNAVBAR_HEIGHT;
      return {
        height: `calc(100vh - ${top}px)`,
        top,
      };
    }, [topDistance]);

    return (
      <Layout style={componentStyles.container}>
        <Box
          bg="background"
          styles={[
            componentStyles.expandedContent,
            {
              visibility: isExpanded ? 'visible' : 'hidden',
              ...topPositionStyles,
            },
          ]}
          w={isExpanded ? contentWidth : 0}
        >
          {panels[selectedPanel].component}
        </Box>
        {/* this is just a placeholder to ocuppy space to stretch/shrink neighbour content */}
        <Box mr={12} w={isExpanded ? contentWidth : 0} />
        <Layout
          as="aside"
          align="flex-start"
          bg="white"
          p={1}
          styles={[componentStyles.contents, topPositionStyles]}
        >
          <Layout preset="buttons" direction="column">
            {Object.entries(panels).map(([panelName, panel]) => {
              const action = panel.action;
              const isSelected = panelName === selectedPanel;
              return action?.icon ? (
                <IconButton
                  key={`${action.icon}_${action.label}`}
                  disabled={action.disabled}
                  icon={action.icon}
                  status={isExpanded && isSelected ? 'active' : undefined}
                  tooltip={action.tooltip || ''}
                  onClick={() => {
                    if (isExpanded) {
                      if (isSelected) {
                        action.onClick?.(action);
                        handleClose();
                      } else {
                        setSelectedPanel(panelName);
                        onUpdate?.(panelName);
                      }
                    } else {
                      action.onClick?.(action);
                      setSelectedPanel(panelName);
                      onUpdate?.(panelName);
                    }
                  }}
                />
              ) : null;
            })}
          </Layout>
        </Layout>
      </Layout>
    );
  },
);

const componentStyles = {
  container: {
    height: '100%',
  },
  contents: {
    boxShadow: 'raised',
    position: 'fixed',
    right: 0,
  },
  expandedContent: {
    boxShadow: 'raised',
    position: 'fixed',
    right: 48, // icons list width
  },
  icon: {
    padding: 3,
  },
};

const getWidth = (width: string) => {
  switch (width) {
    case 'fill':
      return `calc(100% - ${RIGHT_SPACE})`;
    case 'm':
      return 'sidebar.width.m';
    default:
      return 'sidebar.width.s';
  }
};
