import React from 'react';

const RemoveProvisionIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          fill="currentColor"
          fillOpacity={props.isHovered ? '1' : '.5'}
          fillRule="nonzero"
          d="M9.833 7.5H2.5a.836.836 0 0 0-.833.833c0 .459.375.834.833.834h7.333a.836.836 0 0 0 .834-.834.836.836 0 0 0-.834-.833zm1-3.333c.459 0 .834.375.834.833a.836.836 0 0 1-.834.833H2.5A.836.836 0 0 1 1.667 5c0-.458.375-.833.833-.833h8.333zm4.512 7.5l1.768 1.767a.836.836 0 0 1 0 1.179.836.836 0 0 1-1.179 0l-1.767-1.768-1.768 1.768a.836.836 0 0 1-1.179 0 .836.836 0 0 1 0-1.179l1.768-1.767-1.768-1.768a.836.836 0 0 1 0-1.179.836.836 0 0 1 1.179 0l1.768 1.768 1.767-1.768a.836.836 0 0 1 1.179 0 .836.836 0 0 1 0 1.179l-1.768 1.768zM2.5 12.5h5a.836.836 0 0 0 .833-.833.836.836 0 0 0-.833-.834h-5a.836.836 0 0 0-.833.834c0 .458.375.833.833.833z"
        />
      </g>
    </svg>
  );
};

export default RemoveProvisionIcon;
