import PT from 'prop-types';
import React from 'react';

import { Box, Collapsible, FlexLayout, useToggle } from '~/ui';

function SidebarSection({
  children,
  disabled = false,
  headerRightContent = null,
  isCollapsed: initialIsCollapsed = false,
  itemLayout = 'flat',
  title,
  onToggleCollapse,
}) {
  const [isCollapsed, toggleIsCollapsed] = useToggle(initialIsCollapsed);

  function handleToggleCollapse() {
    toggleIsCollapsed();
    onToggleCollapse && onToggleCollapse();
  }

  const Content = !isCollapsed && children;

  return (
    <Box>
      <FlexLayout
        disabled={disabled}
        flexDirection="column"
        pb={isCollapsed ? undefined : 3}
        sx={{ borderBottom: 'border' }}
        role="list"
        aria-label={title}
      >
        <FlexLayout flexDirection="column" pl={5} pr={10}>
          <Collapsible
            headerRightContent={headerRightContent}
            id={`workflow-sidebar--${title.toLowerCase()}`}
            isCollapsed={isCollapsed}
            sx={{ height: '65px' }}
            title={title}
            onToggleCollapse={handleToggleCollapse}
          />
          {itemLayout === 'nested' && Content}
        </FlexLayout>
        {itemLayout === 'flat' && Content}
      </FlexLayout>
    </Box>
  );
}

SidebarSection.propTypes = {
  children: PT.node,
  disabled: PT.bool,
  headerRightContent: PT.node,
  isCollapsed: PT.bool,
  /** Decides if items are laid out at the same level of the section header (flat) or nested inside the section (nested) */
  itemLayout: PT.oneOf(['nested', 'flat']),
  title: PT.string.isRequired,
  onToggleCollapse: PT.func,
};

export default SidebarSection;
