import PropTypes from 'prop-types';
import React, { useState } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom-v5-compat';

import { changePassword } from '~/api';
import {
  black2,
  evisortRed,
  evisortYellow,
} from '~/assets/shared-styles/general';
import {
  getDecodedUserInfoFromLoginToken,
  LoginPageContainer,
  LoginPassword,
} from '~/components/LoginPage';
import { trackSegment } from '~/components/SegmentAnalytics';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { formatDate } from '~/eds';
import { LoginViewType, QueryParamType } from '~/enums';
import { useAsync, usePasswordsWithValidation } from '~/hooks';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';

function ErrorMessageView({ classes }) {
  return (
    <div className={classes.errorMessage}>
      An error occurred. Please <Link to="/">go here</Link> and try again.
    </div>
  );
}

function ChangedPasswordView() {
  return (
    <div>
      Your password has been successfully updated. You will be reidrected to the
      platform. If not, go to <Link to="/">login page</Link> and log in.
    </div>
  );
}

function NoTokenView({ classes }) {
  return (
    <div className={classes.errorMessage}>
      No valid reset password link found.
    </div>
  );
}

function FormView({ classes, submitResetPassword, ...rest }) {
  const { validationResult } = rest;
  return (
    <div className={classes.formContainer}>
      <form onSubmit={submitResetPassword}>
        <LoginPassword {...rest} />
        <div className={classes.formButtonContainer}>
          <button
            type="submit"
            className={classes.formButton}
            onClick={submitResetPassword}
            disabled={!validationResult.allValid}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

function Page(props) {
  const { classes } = props;

  const [
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    validationResult,
  ] = usePasswordsWithValidation();
  const [view, setView] = useState();

  const token = getPageSearchQueryByKey(QueryParamType.Token);
  const slug = getPageSearchQueryByKey(QueryParamType.Slug);
  const tokenType = getPageSearchQueryByKey(QueryParamType.StytchTokenType);

  const formData = {
    new_password: password,
  };

  function submitResetPassword(e) {
    e.preventDefault();
    if (!token) setView(LoginViewType.TokenMissing);
    else {
      setView(LoginViewType.FormSubmitting);
      handleResetPassword();
    }
  }

  const { executor: handleResetPassword } = useAsync(
    changePassword,
    { token, slug, tokenType, formData },
    {
      successHandler: async (response) => {
        setView(LoginViewType.FormSubmitted);
        if (response?.msg) {
          const redirectUrl = response.msg;
          try {
            if (response.user_id || response.client_id) {
              trackSegment('User Reset Password', {
                groupId: response.client_id,
                userId: `${response.user_id}_${response.client_id}`,
                firstLoginDate: formatDate(new Date(), 'utc'),
              });
            } else {
              const { client_id, user_id } = getDecodedUserInfoFromLoginToken(
                redirectUrl,
              );
              trackSegment('User Reset Password', {
                groupId: client_id,
                userId: `${user_id}_${client_id}`,
                firstLoginDate: formatDate(new Date(), 'utc'),
              });
            }
          } catch (error) {
            console.error(error);
          }
          setTimeout(() => {
            window.location.href = redirectUrl;
          }, 1000);
        }
      },
      errorHandler: () => {
        setView(LoginViewType.Error);
      },
    },
  );

  function renderView() {
    switch (view) {
      case LoginViewType.Error:
        return <ErrorMessageView classes={classes} />;
      case LoginViewType.FormSubmitting:
        return <LoadingSpinner />;
      case LoginViewType.FormSubmitted:
        return <ChangedPasswordView />;
      case LoginViewType.TokenMissing:
        return <NoTokenView />;
      default:
        return (
          <FormView
            classes={classes}
            submitResetPassword={submitResetPassword}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            validationResult={validationResult}
          />
        );
    }
  }

  return (
    <LoginPageContainer title="Create new password">
      {renderView()}
    </LoginPageContainer>
  );
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  formContainer: {
    margin: '40px auto',
    width: '360px',
  },
  formButtonContainer: {
    marginTop: '40px',
  },
  formButton: {
    padding: '10px 20px',
    width: '360px',
    display: 'inline-block',
    backgroundColor: evisortYellow,
    borderRadius: '3px',
    border: 'solid 1px rgba(0, 0, 0, 0.25)',
    color: black2,
    fontSize: '15px',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:disabled': {
      opacity: 0.5,
    },
  },
  errorMessage: {
    margin: '20px',
    color: evisortRed,
    fontSize: '15px',
  },
};

export default injectSheet(styles)(Page);
