import PT from 'prop-types';
import React, { useMemo } from 'react';

import { Select } from '~/eds';

// always render labels in lowercase without `.`-prefix
export const formatLabel = (value) =>
  value.replace(/^\.+(.*)/, '$1').toLowerCase();

// always coerce values in lowercase with `.`-prefix
export const formatValue = (value) => '.' + formatLabel(value);

function FileExtensionMultiSelect({
  enableDocumentBodyMenuPortal = false,
  onChange,
  values,
  placeholder,
}) {
  const options = useMemo(
    () =>
      values.map((value) => ({
        label: formatLabel(value),
        value,
      })),
    [values],
  );

  const handleChange = (updatedValues) => {
    onChange(updatedValues.map(formatValue));
  };

  const handleAddOption = (updatedOption) => {
    const updatedOptionValue = formatValue(updatedOption.value);
    const updatedValues = [...values, updatedOptionValue];
    onChange(updatedValues);
  };

  return (
    <Select
      isMulti={true}
      enablePortal={enableDocumentBodyMenuPortal}
      options={options}
      value={values}
      onAddOption={handleAddOption}
      onChange={handleChange}
      placeholder={placeholder}
      noOptionsMessage="Enter an extension for a file type you want to add"
    />
  );
}

FileExtensionMultiSelect.propTypes = {
  /** When rendering component in modals and popovers, this is required to allow menu to be attached to document.body and support floating above its attached node */
  enableDocumentBodyMenuPortal: PT.bool,
  /** Function to handle when new file type is added */
  onChange: PT.func,
  /** Array of currently selected values */
  values: PT.arrayOf(PT.any),
};

export default FileExtensionMultiSelect;
