import {
  black3,
  black5,
  bold,
  evisortBlue,
} from '../../../assets/shared-styles/general';

const styles = {
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  iconButton: {
    color: 'currentColor',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  paginationButtonsLinks: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.75)',
    fontSize: '16px',
    fontWeight: bold,
    cursor: 'pointer',
    textAlign: 'center',
  },
  disabledClassName: {
    color: black5,
    cursor: 'default',
    '& > *': {
      color: black5,
      cursor: 'default',
    },
  },
  paginationPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '29px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  paginatonLink: {
    padding: '4px 8px',
    color: black3,
    fontSize: '16px',
    textAlign: 'center',
    '&:hover': {
      color: evisortBlue,
    },
  },
  paginationActivePage: {
    border: 'solid 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  paginatonActiveLink: {
    color: 'rgba(0, 0, 0, 0.75)',
    fontWeight: bold,
    cursor: 'default',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.75)',
    },
  },
  paginationBrake: {
    width: '28px',
    height: '29px',
    color: black5,
    fontSize: '16px',
    fontWeight: bold,
    cursor: 'pointer',
    lineHeight: 1.81,
    letterSpacing: '4px',
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
  },
};

export default styles;
