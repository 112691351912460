import React from 'react';

import TextSnippets from '~/components/Shared/TextSnippet';
import { ContentContainer, Layout } from '~/eds';
import { SearchTextMatch } from '~/types';

interface Props {
  matches: {
    name: string;
    content: SearchTextMatch;
  }[];
}

export function SearchMatches({ matches = [] }: Props) {
  const placeholderContent = !matches.length
    ? {
        title: 'No keyword text matches',
        description: 'Refine your search query to show keyword text matches',
        image: 'empty-no-search',
      }
    : undefined;
  return (
    <ContentContainer placeholderContent={placeholderContent}>
      <Layout preset="sections" p={4}>
        {matches.map((match, index) => {
          return (
            <TextSnippets
              key={index}
              title={match.name}
              snippets={match.content}
            ></TextSnippets>
          );
        })}
      </Layout>
    </ContentContainer>
  );
}
