import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import ReactTooltip from 'react-tooltip';

import styles from './EcTooltip.styles';

class EcTooltip extends Component {
  render() {
    const { classes, id, place, children, delayShow } = this.props;

    return (
      <ReactTooltip
        delayShow={delayShow}
        id={id}
        type="dark"
        effect="solid"
        place={place}
      >
        <div className={classes.tooltipContainer}>{children}</div>
      </ReactTooltip>
    );
  }
}

EcTooltip.defaultProps = {
  place: 'bottom',
};

EcTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  id: PropTypes.string.isRequired,
  place: PropTypes.string,
};

export default injectSheet(styles)(EcTooltip);
