export const formatFileSize = (fileSize: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(fileSize) / Math.log(1024));
  const amount = Number((fileSize / Math.pow(1024, i)).toFixed(2));
  const unit = units[i];

  if (fileSize === 0) {
    return '-';
  }

  return `${amount}${unit}`;
};
