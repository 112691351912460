import throttle from 'lodash.throttle';
import { useEffect, useState } from 'react';

const THROTTLE_MS = 500;

export function useResponsiveWidth(ref, callback) {
  const [width, setWidth] = useState();

  useEffect(() => {
    const element = ref.current;

    // Update width using a resize observer.  Throttle the resizing callback.
    const resize = throttle((entries) => {
      if (!entries || entries.length === 0) {
        return;
      }
      const updatedWidth = entries[0].contentRect.width;
      setWidth(updatedWidth);
      if (callback) {
        callback(updatedWidth);
      }
    }, THROTTLE_MS);

    if ('ResizeObserver' in window && element) {
      // IE11 does not have native ResizeObserver, either add a polyfill or ignore it.
      const resizeObserver = new ResizeObserver(resize);
      resizeObserver.observe(element);
      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, [ref, callback]);

  return width;
}
