import { DateFormat } from '../types/DateFormat';

type Locale = string | string[] | undefined; // DOM typings for Intl.Locale

/**
 * The default locale is set to the system locale when unspecified.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument
 */
export const DEFAULT_LOCALE: Locale = undefined;

export const DATE_TIME_FORMAT_PARAMS: Record<
  DateFormat,
  [Locale, Intl.DateTimeFormatOptions]
> = {
  relative: [DEFAULT_LOCALE, {}],
  duration: [DEFAULT_LOCALE, {}],
  full: [DEFAULT_LOCALE, {}],
  full_datetime: [DEFAULT_LOCALE, {}],
  utc: [DEFAULT_LOCALE, {}],
  iso: [
    'en-CA',
    {
      dateStyle: 'short',
    },
  ],
  iso_date: [DEFAULT_LOCALE, {}],
  iso_datetime: [DEFAULT_LOCALE, {}],
  iso_complete: [DEFAULT_LOCALE, {}],
  short: [
    DEFAULT_LOCALE,
    {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    },
  ],
  us_date: [DEFAULT_LOCALE, {}],
  eu_date: [DEFAULT_LOCALE, {}],
  prose: [DEFAULT_LOCALE, {}],
  long: [
    DEFAULT_LOCALE,
    {
      dateStyle: 'long',
    },
  ],
};
