import { FieldModelConfigState } from '~/features/x-ray/fields/types';
import { sylvanus } from '~/services';

import { ModelConfigCheckApiV1ModelConfigCheckPostApiResponse } from '../../types/sylvanus/model';
import {
  mapFieldClassificationConfigAttributes,
  mapModelConfigAttributes,
} from './transformers';

interface Request {
  config: FieldModelConfigState;
}

type Response = ModelConfigCheckApiV1ModelConfigCheckPostApiResponse;

export const checkPromptModelConfig = async ({
  config,
}: Request): Promise<any> => {
  const { field, internal } = config;

  if (field) {
    const response: Response = await sylvanus.post(`model/config-check`, {
      field_name: field.label,
      internal,
      instructions: '', // required by API but unimportant
      ...mapFieldClassificationConfigAttributes(config),
      ...mapModelConfigAttributes(config),
    });
    return response;
  }
};
