import PropTypes from 'prop-types';
import React from 'react';

const DocumentTypeImgIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path fill="#2A7CE5" d="M4 2h12l4 4v16H4z" />
        <text
          fill="#FFF"
          fontFamily="SFMono-Heavy, SF Mono, Arial"
          fontSize="6"
          fontWeight="600"
        >
          <tspan x="6.437" y="19">
            IMG
          </tspan>
        </text>
        <path fill="#FFF" d="M6 12h12l-3-5-3 4-3-3z" />
        <circle cx="11" cy="6" r="1" fill="#FFF" />
      </g>
    </svg>
  );
};

DocumentTypeImgIcon.defaultProps = {
  size: '24',
};

DocumentTypeImgIcon.propTypes = {
  size: PropTypes.string,
};

export default DocumentTypeImgIcon;
