import { Instance } from 'tippy.js';

// https://atomiks.github.io/tippyjs/v6/plugins/#hideonesc
const hideOnEsc = {
  name: 'hideOnEsc',
  defaultValue: true,
  fn({ hide }: Instance) {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        hide();
      }
    };

    return {
      onHide() {
        document.removeEventListener('keydown', onKeyDown);
      },
      onShow() {
        document.addEventListener('keydown', onKeyDown);
      },
    };
  },
};

export const plugins = [hideOnEsc];
