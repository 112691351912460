import React, { useState } from 'react';

import { IconButton } from '~/eds';
import Text from '~/ui/components/Text';
import { countWords, sliceWords } from '~/utils/strings';

export const SampleContentCell = ({ sampleName, text }) => {
  const [doesShowFullText, setDoesShowFullText] = useState(false);

  const nameCount = countWords(sampleName);
  const contentCount = Math.max(80 - nameCount, 10);

  const name = sampleName ? (
    <>
      <strong>{sampleName}</strong> -{' '}
    </>
  ) : null;

  if (!text || countWords(text) <= contentCount) {
    return (
      <Text variant="xs-dense">
        {name}
        {text}
      </Text>
    );
  }

  const content = doesShowFullText
    ? text.trim()
    : `${sliceWords(text.trim(), contentCount)}...`;

  const chevron = doesShowFullText ? 'chevron-up' : 'chevron-down';

  return (
    <Text variant="xs-dense">
      {name}
      {content}
      <IconButton
        icon={chevron}
        isActive={doesShowFullText ? true : false}
        onClick={() => setDoesShowFullText(!doesShowFullText)}
      />
    </Text>
  );
};
