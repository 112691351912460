import { borderWidths } from './borderWidths';
import { colors } from './colors';

const createBorder = (
  color: string,
  borderWidth: keyof typeof borderWidths = 's',
) => `${borderWidths[borderWidth]}px solid ${color}`;

export const borders = {
  active: createBorder(colors['status.active']),
  avatar: createBorder(colors.background, 'm'),
  border: createBorder(colors.border),
  'border.divider': createBorder(colors['border.divider']),
  quote: createBorder(colors.border, 'l'),
};
