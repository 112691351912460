import { wopi } from '~/services';

export interface WopiSetup {
  url: string;
  accessToken: string;
  accessTokenTtl: string;
}

export interface WopiSetupResponse {
  url: string;
  access_token: string;
  access_token_ttl: string;
}

export const getWopiSetup = async ({
  fileId,
}: {
  fileId: string;
}): Promise<WopiSetup> => {
  const result: WopiSetupResponse = await wopi.get(`/setup/${fileId}`);

  return {
    url: result.url,
    accessToken: result.access_token,
    accessTokenTtl: result.access_token_ttl,
  };
};
