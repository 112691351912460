import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import ReorderIcon from '../../Shared/Icons/ReorderIcon';
// eslint-disable-line import/default
import EcVersionItem from '../EcVersionItem';
import styles from './EcVersionsTable.styles';

class EcVersionsTable extends Component {
  constructor(props) {
    super(props);

    this.onSortEnd = this.onSortEnd.bind(this);
    this.state = {
      items: [],
      filterValue: '',
    };
  }

  componentDidMount() {
    this.setState({ items: this.props.versionsData });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      items: nextProps.versionsData,
      filterValue: nextProps.filterValue,
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
    setTimeout(() => {
      this.callServer();
    }, 300);
  };

  callServer() {
    const { handleVersionReorder } = this.props;
    const { items } = this.state;
    const formData = items.map((item) => item.id);
    handleVersionReorder(formData);
  }

  renderVersionItems() {
    const {
      classes,
      userVisibilityLevel,
      handleShowModal,
      handleVersionReplace,
      handleVersionRestore,
    } = this.props;
    const { items, filterValue } = this.state;

    const DragHandle = SortableHandle(() => <ReorderIcon />);

    const SortableItem = SortableElement(({ value }) => (
      <EcVersionItem
        item={value}
        userVisibilityLevel={userVisibilityLevel}
        isLastVersionLeft={items.length === 1}
        dragHandle={
          <span
            aria-label="Reorder version item button"
            role="button"
            tabIndex={0}
          >
            <DragHandle />
          </span>
        }
        handleShowModal={handleShowModal}
        handleVersionReplace={handleVersionReplace}
        handleVersionRestore={handleVersionRestore}
      />
    ));

    if (items.length) {
      const SortableList = SortableContainer(({ items }) => (
        <div>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </div>
      ));

      return (
        <SortableList
          items={items}
          onSortEnd={this.onSortEnd}
          useDragHandle
          helperClass={classes.sortableHelper}
        />
      );
    } else if (filterValue) {
      return (
        <div className={classes.noSearchResultMessage}>
          No document versions match selected filters.
        </div>
      );
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.headerContainer}>
          <span className={classes.titleVersionContainer}>Version</span>
          <span className={classes.titleFilenameContainer}>Filename</span>
          <span className={classes.titleLastModContainer}>Last Modified</span>
          <span className={classes.titleDescripContainer}>Description</span>
        </div>
        {this.renderVersionItems()}
      </div>
    );
  }
}

EcVersionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  versionsData: PropTypes.array.isRequired,
};

export default injectSheet(styles)(EcVersionsTable);
