import { bold } from '../../../assets/shared-styles/general';

const styles = {
  modalCkeditorContent: {
    flexGrow: '1',
    '& > *': {
      width: '100%',
    },
    '& table': {
      width: '100%',
      border: 'solid 1px rgba(0, 0, 0, 0.15)',
      borderRadius: '4px',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      backgroundColor: '#fafafa',
      tableLayout: 'fixed',
      boxShadow: '0 2px 4px 0 rgba(17, 17, 17, 0.05)',
      '& thead': {
        '& th': {
          border: 'solid 1px rgba(0, 0, 0, 0.1)',
          padding: '8px 12px',
          fontSize: '13px',
          fontWeight: bold,
        },
      },
      '& tbody': {
        fontSize: '14px',
        '& td, th': {
          border: 'solid 1px rgba(0, 0, 0, 0.1)',
          padding: '8px 12px',
        },
        '& tr:not(:last-child) td': {
          borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
  modalCkeditorActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',

    '& button:not(:last-child)': {
      marginRight: '16px',
    },
  },
};

export default styles;
