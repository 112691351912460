import React from 'react';

import AndOrToggle from '~/components/Shared/AndOrToggle';
import { Text } from '~/eds';

type Props = {
  index: number;
  operatorValue: string | undefined;
  onToggleClick: (value: string) => void;
};

const FilterOperator = ({ index, operatorValue, onToggleClick }: Props) => {
  if (index === 0) {
    return <AndOrToggle value={operatorValue} onChange={onToggleClick} />;
  } else {
    return <Text>{operatorValue}</Text>;
  }
};

export default FilterOperator;
