import React from 'react';

import { enquote, Layout } from '~/eds';

import { TextContainer } from '../TextContainer';
import { ClauseValue, Filter } from '../types';
import { serializeClauseFilter } from '../utils';

interface Props {
  filter: Filter<ClauseValue>;
}

export const ClauseValuesPreview = ({ filter }: Props) => {
  const {
    provisionText,
    clauseSearchOperatorLabel,
    clauseSearchText,
  } = serializeClauseFilter(filter);

  return (
    <Layout align="center" spacing={1} minW={0}>
      <TextContainer text={provisionText || ''} />
      {clauseSearchOperatorLabel && (
        <strong>{clauseSearchOperatorLabel}</strong>
      )}
      {clauseSearchText && <TextContainer text={enquote(clauseSearchText)} />}
    </Layout>
  );
};
