import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Text } from '~/ui';

function Links({ bg = 'gray-300', links }) {
  if (links.length === 0) {
    return null;
  }

  return (
    <FlexLayout flexDirection="column" space={4}>
      {links.groups.map((group) => {
        const { items, title } = group;
        return (
          items.length > 0 && (
            <FlexLayout key={title} flexDirection="column" space={1}>
              <Text variant="xs-dense-bold">{title}</Text>
              {items
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((item) => {
                  return (
                    <Text
                      key={item.id}
                      bg={bg}
                      color="gray-900"
                      px={6}
                      py={3}
                      variant="xs-dense"
                      sx={{ borderRadius: 's' }}
                    >
                      {item.name}
                    </Text>
                  );
                })}
            </FlexLayout>
          )
        );
      })}
    </FlexLayout>
  );
}

const groupPropType = PT.shape({
  title: PT.string.isRequired,
  items: PT.arrayOf(
    PT.shape({ id: PT.string.isRequired, name: PT.string.isRequired }),
  ),
});

Links.propTypes = {
  links: PT.shape({
    groups: PT.arrayOf(groupPropType.isRequired).isRequired,
    length: PT.number.isRequired,
  }),
};

export default Links;
