import PT from 'prop-types';
import React from 'react';

import Box from '../Box';

const sizeValues = {
  m: 120,
  l: 160,
};

// Note: This can eventually be promoted to a ~/ui component if we need to reuse it.
const CtaImage = ({ isHovered, size = 'm', src }) => {
  const sizeValue = sizeValues[size];
  const boxShadowOffset = sizeValue / 2;

  const styles = {
    container: {
      flex: 'none',
      height: sizeValue,
      position: 'relative',
      width: sizeValue,
    },
    glow: {
      boxShadow: (theme) =>
        `0 0 ${boxShadowOffset}px ${boxShadowOffset}px ${
          theme.colors[isHovered ? 'yellow-200' : 'yellow-100']
        }`,
      borderRadius: 'round',
      height: 0,
      left: '50%',
      position: 'absolute',
      top: '50%',
      width: 0,
    },
    image: {
      bottom: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100%',
    },
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.glow} />
      <Box as="img" src={src} sx={styles.image} />
    </Box>
  );
};

CtaImage.propTypes = {
  /** If yes, apply a glow effect on hover */
  isHovered: PT.bool,
  /** Image size */
  size: PT.oneOf(['m', 'l']),
  /** Image source */
  src: PT.string.isRequired,
};

export default CtaImage;
