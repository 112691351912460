import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b';
import { useRef } from 'react';

import { getEnvVariable } from '~/dev';
import { rolodex } from '~/services';

const StytchOptions = {
  cookieOptions: {
    opaqueTokenCookieName: 'session_token',
    jwtCookieName: 'session_jwt',
    path: '',
    availableToSubdomains: false,
    domain: '',
  },
};

export const useStytchClient = () => {
  const StytchB2BUIClientRef = useRef<StytchB2BUIClient>(
    new StytchB2BUIClient(
      getEnvVariable('REACT_APP_STYTCH_PUBLIC_TOKEN'),
      StytchOptions,
    ),
  );

  StytchB2BUIClientRef.current.passwords.authenticate = async (
    request: never,
  ) => {
    // This is a temporary solution, as stytch doesn't support htpponly, we have to use
    // rolodex as a proxy to set the token with httponly flag
    return await rolodex.post('proxy/passwords/authenticate', request);
  };

  return {
    b2bClient: StytchB2BUIClientRef.current,
  };
};
