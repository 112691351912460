import { uniq } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  Divider,
  formatNumber,
  Layout,
  Panel,
  Text,
  types,
  useToast,
} from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { ClauseContent, GenClauseResult } from '~/types';

import {
  GEN_AI_MAX_INSTRUCTIONS_LENGTH,
  GEN_AI_MAX_SELECTION_LENGTH,
} from './constant';
import { CreatorPrompt } from './Prompt/CreatorPrompt';
import { RedlinePrompt } from './Prompt/RedlinePrompt';
import { Result } from './Result';
import { Mode, Prompt } from './types';

interface Props {
  actions: types.UserAction[];
  disableAccept: boolean;
  mode: Mode;
  selectedClauses: ClauseContent[];
  selectedText?: string;
  onAccept: (newText: string) => void;
  onUpdateMode: (mode: Mode) => void;
}

export const GenAiPanel = ({
  actions,
  disableAccept,
  mode,
  selectedClauses: selectedClausesFromDoc,
  selectedText = '',
  onAccept,
  onUpdateMode,
}: Props) => {
  const useDelphi = useFlag(FlagType.GenAiUsingDelphi);

  const { toast } = useToast();

  const [pageIndex, setPageIndex] = useState(1);
  const [prompt, setPrompt] = useState<Prompt>(defaultPrompt);
  const [selectedClauses, setSelectedClauses] = useState(
    selectedClausesFromDoc,
  );
  const [resultTexts, setResultTexts] = useState<string[]>([]);

  const [
    getGenClauseResults,
    { isFetching: isFetchingResults, isError: isErrorResults },
  ] = api.endpoints.getGenClauseResults.useLazyQuery();

  const [
    getGenClauseResult,
    { isFetching: isFetchingResult, isError: isErrorResult },
  ] = api.endpoints.getGenClauseResult.useLazyQuery();

  const isRedlineMode = mode === 'redline';
  const disabled =
    !prompt.instructions ||
    prompt.instructions.length > GEN_AI_MAX_INSTRUCTIONS_LENGTH;

  const disabledTooltip =
    prompt.instructions.length > GEN_AI_MAX_INSTRUCTIONS_LENGTH
      ? 'Shorten your instructions text.'
      : 'Specify instructions for creating a clause.';

  const isError = isErrorResults || isErrorResult;
  const isLoading = isFetchingResults || isFetchingResult;

  const resultText = resultTexts[pageIndex - 1];

  const handleFullReset = () => {
    setPageIndex(1);
    setResultTexts([]);
    setPrompt(defaultPrompt);
  };

  const handleResetFields = () => {
    setPageIndex(1);
    setResultTexts([]);
    setPrompt({
      ...prompt,
      clause: null,
      instructions: '',
      variation: null,
    });
  };

  const handleUpdateResultText = (updatedResultText: string) => {
    const updatedResultTexts = resultTexts.map((text, index) =>
      index + 1 === pageIndex ? updatedResultText : text,
    );
    setResultTexts(updatedResultTexts);
  };

  const handleUpdatePrompt = (updatedPrompt: Prompt) => {
    setPrompt({
      ...updatedPrompt,
      text: isRedlineMode ? selectedText : '',
    });
  };

  const handleAccept = () => {
    onAccept(resultText);
  };

  useEffect(() => {
    handleFullReset();
  }, [mode]);

  useEffect(() => {
    setSelectedClauses(selectedClausesFromDoc);
  }, [selectedClausesFromDoc]);

  useEffect(() => {
    if (isRedlineMode) {
      if (resultText) {
        // Reset the panel when new text is selected while viewing a previously generated result.
        handleFullReset();
      }
      if (selectedText.length > GEN_AI_MAX_SELECTION_LENGTH) {
        toast({
          message: `You selected ${formatNumber(
            selectedText.length,
          )}/${formatNumber(GEN_AI_MAX_SELECTION_LENGTH, {
            unit: 'character',
          })}. Reduce the text selection to under ${formatNumber(
            GEN_AI_MAX_SELECTION_LENGTH,
            {
              unit: 'character',
            },
          )} to generate a redline.`,
          status: 'warning',
        });
      } else {
        setPrompt({
          // Overwrite the prompt fields when new text is selected, unless making a new text selection within the same auto-fill clause.
          ...(selectedClausesFromDoc &&
          selectedClauses === selectedClausesFromDoc
            ? prompt
            : defaultPrompt),
          text: selectedText,
        });
      }
    }
  }, [selectedText]);

  useEffect(() => {
    if (isError) {
      toast({
        message: 'Service is temporarily unavailable. Please retry later.',
        status: 'warning',
      });
    }
  }, [isError]);

  let instructionsText =
    "Create clauses with the help of Evisort AI. Once you're happy with the generated clause, place the mouse cursor in the document where you want to insert the clause, then click Insert Clause.";

  if (isRedlineMode) {
    if (selectedText && selectedText.length <= GEN_AI_MAX_SELECTION_LENGTH) {
      instructionsText =
        "Automate redlining with the help of Evisort AI. You can either write instructions on how you'd like to modify the clause, or you can pre-populate the instructions by selecting the clause you want to redline from your clause library.";
    } else {
      instructionsText = `To get started, select text in the document under ${formatNumber(
        GEN_AI_MAX_SELECTION_LENGTH,
        {
          unit: 'character',
        },
      )}.`;
    }
  }

  const PanelComponent = (
    <Layout direction="column" h="100%" justify="space-between">
      <Layout direction="column" h="100%" overflowY="auto" spacing={8}>
        <Text variant="body-medium" whiteSpace="pre-wrap">
          {instructionsText}
        </Text>
        {isRedlineMode ? (
          <>
            {selectedText &&
              selectedText.length <= GEN_AI_MAX_SELECTION_LENGTH && (
                <RedlinePrompt
                  prompt={prompt}
                  selectedClauses={selectedClauses}
                  onUpdatePrompt={handleUpdatePrompt}
                />
              )}
          </>
        ) : (
          <CreatorPrompt prompt={prompt} onUpdatePrompt={handleUpdatePrompt} />
        )}
        {resultText && (
          <>
            <Divider />
            <Result
              mode={mode}
              pageIndex={pageIndex}
              text={prompt.text}
              resultText={resultText}
              totalCount={resultTexts.length}
              disableAccept={disableAccept}
              onAccept={handleAccept}
              onUpdatePageIndex={setPageIndex}
              onUpdateResultText={handleUpdateResultText}
            />
          </>
        )}
      </Layout>
    </Layout>
  );

  const tabs = {
    selectedTab: mode,
    onSelectTab: (tab: string) => onUpdateMode(tab as Mode),
    tabs: [
      {
        label: 'Automated Redlining',
        value: 'redline',
        panel: PanelComponent,
      },
      {
        label: 'Clause Creator',
        value: 'clause',
        panel: PanelComponent,
      },
    ],
  };

  const footer: { actions: types.UserAction[] } = {
    actions: [],
  };

  if (resultText) {
    footer.actions.push({
      text: 'Reset',
      onClick: handleResetFields,
    });
  }
  footer.actions.push({
    disabled,
    isLoading,
    text: resultText ? 'Generate More' : 'Generate',
    tooltip: disabled ? disabledTooltip : undefined,
    level: 'primary' as const,
    onClick: async () => {
      if (resultText) {
        const { data: newResult } = await getGenClauseResult({
          ...prompt,
          useDelphi,
        });
        if (!isError && newResult) {
          const updatedResultTexts = [...resultTexts, newResult.text];
          const uniqueUpdatedResultTexts = uniq(updatedResultTexts);
          if (uniqueUpdatedResultTexts.length > resultTexts.length) {
            setResultTexts(uniqueUpdatedResultTexts);
            setPageIndex(uniqueUpdatedResultTexts.length);
          } else {
            // "New" result is not unique, so it won't show up.
            toast({
              message:
                'The new results are the same. Update your instructions to get a different result.',
              status: 'ai',
            });
          }
        }
      } else {
        const { data: initialResults = [] } = await getGenClauseResults({
          ...prompt,
          useDelphi,
        });
        if (!isError) {
          const initialResultTexts = toResultTexts(initialResults);
          const uniqueResultTexts = uniq(initialResultTexts);
          setResultTexts(uniqueResultTexts);
        }
      }
    },
  });

  return (
    <Panel
      actions={actions}
      tabs={tabs}
      title="AI Drafting Tools"
      footer={footer}
    />
  );
};

const defaultPrompt = {
  clause: null,
  instructions: '',
  text: '',
  variation: null,
};

const toResultTexts = (results: GenClauseResult[]) =>
  results.map(({ text }) => text);
