import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { CustomEditor } from './types';

export const blurEditor = (editor: CustomEditor) => {
  ReactEditor.blur(editor);
};

export const deselectEditor = (editor: CustomEditor) => {
  ReactEditor.deselect(editor);
};

// See https://github.com/ianstormtaylor/slate/issues/4780#issuecomment-1008238058
export const clearEditor = (editor: CustomEditor) => {
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });
};

export const focusEditor = (editor: CustomEditor, mode?: 'end') => {
  ReactEditor.focus(editor);

  if (mode === 'end') {
    Transforms.select(editor, {
      anchor: Editor.end(editor, []),
      focus: Editor.end(editor, []),
    });
  }
};
