import { noop } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';

import { useToggle } from '~/eds';

interface Context {
  /** represents if the content is enlarged (width='full-width') */
  isContentEnlarged: boolean;
  /** represents if the sidebar is expanded */
  isSidebarExpanded: boolean;
  /** represents if the panel is enlarged (width='full-width') */
  isPanelEnlarged: boolean;
  /** the panel size in format of 'm'| 'l' */
  panelWidth: 'm' | 'l';
  /** the sidebar width in pixels */
  sidebarWidth: string;
  /** closes the sidebar */
  closeSidebar: () => void;
  /** condenses panel */
  condensePanel: () => void;
  /** condenses content */
  condenseContent: () => void;
  /** enlarge content */
  enlargeContent: () => void;
  /** enlarge panel */
  enlargePanel: () => void;
  /** opens the sidebar */
  openSidebar: () => void;
  /** set the panel widith in format 'm' | 'l' */
  setPanelWidth: (size: 'm' | 'l') => void;
  /**  toggle to enlarge/codense content */
  toggleContentEnlarged: () => void;
  /** toggle to open/close sidebar */
  toggleSidebarExpand: () => void;
  /** toggle to enlarge/condense panel */
  togglePanelEnlarged: () => void;
}

interface Props {
  children: React.ReactNode;
}

export const AppConfigContext = React.createContext<Context>({
  isContentEnlarged: false,
  isPanelEnlarged: false,
  isSidebarExpanded: false,
  sidebarWidth: '',
  panelWidth: 'm',
  closeSidebar: noop,
  condenseContent: noop,
  condensePanel: noop,
  enlargeContent: noop,
  enlargePanel: noop,
  openSidebar: noop,
  setPanelWidth: noop,
  toggleContentEnlarged: noop,
  toggleSidebarExpand: noop,
  togglePanelEnlarged: noop,
});

const SIDE_PANEL_SIZES = {
  l: 724,
  m: 504,
};

export const AppConfigProvider = ({ children }: Props) => {
  const [
    isPanelEnlarged,
    togglePanelEnlarged,
    enlargePanel,
    condensePanel,
  ] = useToggle(false);
  const [
    isContentEnlarged,
    toggleContentEnlarged,
    enlargeContent,
    condenseContent,
  ] = useToggle(false);
  const [
    isSidebarExpanded,
    toggleSidebarExpand,
    openSidebar,
    closeSidebar,
  ] = useToggle(false);
  const [panelWidth, setPanelWidth] = useState<keyof typeof SIDE_PANEL_SIZES>(
    'm',
  );
  const sidebarWidth = useMemo(() => {
    if (isSidebarExpanded) {
      return isPanelEnlarged
        ? '100vw'
        : `${SIDE_PANEL_SIZES[panelWidth] + 48}px`; // 48 is the sidebar actions width
    } else {
      return '48px';
    }
  }, [isSidebarExpanded, isPanelEnlarged, panelWidth]);

  return (
    <AppConfigContext.Provider
      value={{
        isContentEnlarged,
        isSidebarExpanded,
        isPanelEnlarged,
        sidebarWidth,
        panelWidth,
        closeSidebar,
        condenseContent,
        condensePanel,
        enlargeContent,
        enlargePanel,
        openSidebar,
        toggleContentEnlarged,
        toggleSidebarExpand,
        togglePanelEnlarged,
        setPanelWidth,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfigContext = (): Context => useContext(AppConfigContext);
