export default `
<svg
  aria-hidden="true"
  aria-label="status-info"
  width="20"
  height="2"
  viewBox="0 0 20 20"
  fill="none"
>
  <path d="M9.99998 3.33337C6.31769 3.33337 3.33331 6.31775 3.33331 10C3.33331 13.6823 6.31769 16.6667 9.99998 16.6667C13.6823 16.6667 16.6666 13.6823 16.6666 10C16.6666 6.31775 13.6823 3.33337 9.99998 3.33337ZM9.99998 15.4167C7.013 15.4167 4.58331 12.9868 4.58331 10C4.58331 7.01332 7.013 4.58337 9.99998 4.58337C12.987 4.58337 15.4166 7.01332 15.4166 10C15.4166 12.9868 12.987 15.4167 9.99998 15.4167ZM11.0416 12.0834H10.625V9.79171C10.625 9.44796 10.3463 9.16671 9.99998 9.16671H9.16665C8.8229 9.16671 8.54165 9.44796 8.54165 9.79171C8.54165 10.1355 8.8229 10.4167 9.16665 10.4167H9.37498V12.0834H8.95831C8.61456 12.0834 8.33331 12.3646 8.33331 12.7084C8.33331 13.0521 8.61456 13.3334 8.95831 13.3334H11.0416C11.3867 13.3334 11.6666 13.0534 11.6666 12.7084C11.6666 12.3633 11.388 12.0834 11.0416 12.0834ZM9.99998 8.33337C10.4601 8.33337 10.8333 7.9602 10.8333 7.50004C10.8333 7.03988 10.4601 6.66671 9.99998 6.66671C9.53982 6.66671 9.16665 7.0391 9.16665 7.50004C9.16665 7.96098 9.53904 8.33337 9.99998 8.33337Z" fill="currentcolor"/>
</svg>
`;
