import React from 'react';

import {
  Actions,
  Box,
  Breadcrumbs,
  Chips,
  ContentContainer,
  DescriptionText,
  Layout,
  Logo,
  Paginate,
  Panel,
  StatusMessage,
  Tabs,
  Text,
  TruncateText,
} from '../../components';
import { useDisableBodyScroll } from '../../hooks';
import { SharedPageLayoutProps } from '../../types';

// TODO EKP-16459: this explicitly indicates throughout the implementation the usage of negative margins.  The reason is because `PrivateRoute` unfortunately sets opinionated spacings above the `PageLayout`.  Once all pages are formally using `PageLayout`, we can refactor this away from `PrivateRoute`.
const DEPRECATED_mx = 8;

export const PageLayout = ({
  actions = [],
  children = null,
  chips,
  description,
  footerActions,
  header: headerContents,
  headerCalloutContent,
  height,
  loadingContent,
  moreActions,
  nav,
  panel,
  placeholderContent,
  statusMessage,
  tabs,
  toolbar,
  title,
  subtitle,
  welcome,
}: SharedPageLayoutProps) => {
  useDisableBodyScroll(Boolean(height));

  const hasActions = actions.length > 0 || Boolean(moreActions);

  const textAlign = welcome ? 'center' : 'left';

  const pageTitle = (
    <TruncateText as="h1" m={0} textAlign={textAlign} variant="title">
      {title}
    </TruncateText>
  );

  const pageDescription = description && (
    <DescriptionText text={description} textAlign={textAlign} />
  );

  const pageSubtitle = subtitle && (
    <Text align="center" variant="body-bold">
      {subtitle}
    </Text>
  );
  const pageNav = nav && (
    <Layout
      align="center"
      alignSelf="flex-start"
      justify="space-between"
      ml={-4}
      spacing={4}
      w="100%"
    >
      <Breadcrumbs actions={nav.actions} />
      {nav.paginate && (
        <Paginate {...nav.paginate} mode="count" name="page nav paginate" />
      )}
    </Layout>
  );

  const contents = (
    <Layout flex="auto" minH={0} spacing={8}>
      <Layout
        direction="column"
        flex="auto"
        py={tabs ? 0 : 4}
        minW={0}
        spacing={4}
      >
        {statusMessage && <StatusMessage {...statusMessage} />}
        <ContentContainer
          loadingContent={loadingContent}
          placeholderContent={placeholderContent}
        >
          <>{tabs ? <Tabs {...tabs} /> : children}</>
        </ContentContainer>
      </Layout>
      {toolbar && (
        <Box styles={componentStyles.toolbar}>
          <Actions actions={toolbar.actions} direction="column" />
        </Box>
      )}
    </Layout>
  );

  const pageActions = <Actions actions={actions} />;

  const footer = footerActions && (
    <Box as="footer" styles={componentStyles.footer}>
      <Actions actions={footerActions} />
    </Box>
  );

  if (welcome) {
    const { onboardingIds, content, enableLogo } = welcome;
    return (
      <Layout
        as="header"
        align="center"
        direction="column"
        h={height}
        px={DEPRECATED_mx}
        spacing={12}
      >
        {enableLogo && <Logo />}
        {pageNav}
        <Layout direction="column" py={4} spacing={4}>
          {pageTitle}
          {pageSubtitle}
          {pageDescription}
        </Layout>
        <ContentContainer loadingContent={loadingContent}>
          <>
            {content}
            {onboardingIds && (
              <Layout preset="cards">
                {onboardingIds.map((onboardingId) => (
                  <Box id={onboardingId} p={4} minW={300} minH={360} />
                ))}
              </Layout>
            )}
            {pageActions}
          </>
        </ContentContainer>
        {footer}
      </Layout>
    );
  }

  const isLoading = loadingContent?.isLoading;

  const header = (
    <Layout
      as="header"
      borderBottom="border.divider"
      direction="column"
      mx={-DEPRECATED_mx}
      pb={3}
      pt={nav ? undefined : 2}
      px={DEPRECATED_mx}
      spacing={3}
    >
      {pageNav}
      <Layout align="center" justify="space-between" spacing={4}>
        <Layout align="center" spacing={4}>
          {pageTitle}
          {chips && <Chips chips={chips} />}
        </Layout>
        <Layout align="center" spacing={2}>
          {headerCalloutContent}
          {hasActions && (
            <Actions
              actions={actions.map((action) => ({
                ...action,
                disabled: isLoading || action.disabled,
              }))}
              moreActions={moreActions?.map((action) => ({
                ...action,
                disabled: isLoading || action.disabled,
              }))}
            />
          )}
        </Layout>
      </Layout>
      {pageDescription}
      {headerContents}
    </Layout>
  );

  return (
    <Layout
      h={height}
      minH="100%"
      mx={-DEPRECATED_mx}
      position="relative"
      px={DEPRECATED_mx}
      spacing={3}
    >
      <Layout direction="column" h="100%" w="100%">
        {header}
        {contents}
        {footer}
      </Layout>
      {panel && <Panel position="right" {...panel} />}
    </Layout>
  );
};

const componentStyles = {
  footer: {
    backgroundColor: 'background',
    borderTop: 'border.divider',
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    left: 0,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    position: 'fixed',
    right: 0,
  },
  toolbar: {
    backgroundColor: 'background',
    border: 'border.divider',
    height: '100%',
    marginTop: '-1px',
    padding: 1,
    position: 'fixed',
    right: 0,
  },
};
