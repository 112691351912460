export default `
<svg
  aria-hidden="true"
  aria-label="status-pending"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M11.25 7.75C11.25 7.34375 11.5625 7 12 7C12.4062 7 12.75 7.34375 12.75 7.75V11.625L15.4062 13.375C15.75 13.625 15.8438 14.0938 15.5938 14.4375C15.375 14.7812 14.9062 14.875 14.5625 14.625L11.5625 12.625C11.375 12.5 11.25 12.25 11.25 12V7.75ZM12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4ZM5.5 12C5.5 15.5938 8.40625 18.5 12 18.5C15.5625 18.5 18.5 15.5938 18.5 12C18.5 8.4375 15.5625 5.5 12 5.5C8.40625 5.5 5.5 8.4375 5.5 12Z"
    fill="currentcolor"
  />
</svg>
`;
