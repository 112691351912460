/**
 * Tests if a DOM element is interactive.
 *
 * Reference: https://github.com/mjlumetta/react-router-hash-link/blob/83dba7c9c5b18c1b19ffd4f58ea603f3101d1102/src/index.js#L19-L24
 */

export const testIsInteractiveElement = (element: HTMLElement) => {
  const formTags = ['BUTTON', 'INPUT', 'SELECT', 'TEXTAREA'];
  const linkTags = ['A', 'AREA'];
  return (
    (formTags.includes(element.tagName) && !element.hasAttribute('disabled')) ||
    (linkTags.includes(element.tagName) && element.hasAttribute('href'))
  );
};
