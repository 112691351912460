import { black2, semibold } from '../../../assets/shared-styles/general';

const styles = {
  modalBody: {
    padding: '16px',
    color: black2,
    fontSize: '15px',
    lineHeight: '1.47',
  },
  modalInfo: {
    margin: '0 0 24px 0',
  },
  infoComponent: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    marginTop: '16px',
    padding: '8px 16px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontSize: '14px',
    lineHeight: '21px',
    '& svg': {
      marginRight: '12px',
    },
  },
  reasonWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
  },
  checkboxLabel: {
    marginLeft: '8px',
  },
  additionalNotesWrapper: {
    marginTop: '32px',
    '& p': {
      margin: '0 0 8px 0',
      fontSize: '12px',
      fontWeight: semibold,
    },
    '& textarea': {
      display: 'block',
      width: '100%',
      border: 'solid 1px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      padding: '6px',
      color: black2,
      outline: 'none',
      resize: 'none',
    },
  },
};

export default styles;
