import { PilotId } from '~/types';

import { pilot } from '../../../services';

type DocumentsCountParams = {
  folder_id: PilotId;
  documents: PilotId[];
  folders: PilotId[];
  select_all?: boolean;
};

export type DocumentsCountPayload = {
  document_count: number;
};

export const getDocumentsCount = async ({
  folder_id,
  documents,
  folders,
  select_all,
}: DocumentsCountParams): Promise<DocumentsCountPayload> => {
  return pilot.post(`folder/${folder_id}/count/`, {
    folder_id,
    documents,
    folders,
    select_all,
  });
};
