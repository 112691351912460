import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';

import AuthenticationStore from '~/auth';
import { ContentContainer } from '~/eds';
import { usePermissions } from '~/hooks';
import ActivationPage from '~/pages/activation';
import ChangePasswordPage from '~/pages/change-password';
import ExpiredTokenPage from '~/pages/expired-token';
import ExternalDocumentViewer from '~/pages/external-document-viewer';
import ForgotPasswordPage from '~/pages/forgot-password';
import LoginPage from '~/pages/login';
import WordOnlineErrorPage from '~/pages/word-online-invalid-subscription';
import { RoutePathType } from '~/routing';
import { RbacPermission } from '~/types';
import { Box } from '~/ui';

interface Props {
  component: React.ComponentType<RouteProps>;
  permissions?: RbacPermission[];
}

const AuthenticatedRoute = ({ permissions, component, ...restProps }: any) => {
  const { isLoading, hasSomePermissions } = usePermissions({
    permissions,
  });
  const hasPermission = hasSomePermissions;
  const Component = component;

  return (
    <ContentContainer loadingContent={{ isLoading }}>
      {/* TODO EKP-16459: cleanup and absorb padding into `eds.PageLayout` after all pages are updated. */}
      {/* @ts-ignore type theme-ui and ~/ui components */}
      <Box px={8} py={3}>
        {hasPermission ? (
          <Component {...restProps} />
        ) : (
          <Redirect to={{ pathname: '/unauthorized' }} />
        )}
      </Box>
    </ContentContainer>
  );
};

const PrivateRoute = ({
  component: Component,
  permissions,
  ...restProps
}: Props): React.ReactElement<RouteProps> => {
  const AS = AuthenticationStore();

  const userAuthenticated = AS.isLoggedIn();

  return (
    <CompatRoute
      {...restProps}
      render={(props: any) => {
        return userAuthenticated ? (
          <AuthenticatedRoute
            component={Component}
            permissions={permissions}
            {...props}
          />
        ) : window.location.pathname === '/' ? (
          <LoginPage {...props} />
        ) : window.location.pathname === '/forgot-password' ? (
          <ForgotPasswordPage {...props} />
        ) : window.location.pathname === '/activation' ? (
          <ActivationPage {...props} />
        ) : window.location.pathname === '/expired-token' ? (
          <ExpiredTokenPage {...props} />
        ) : window.location.pathname ===
          RoutePathType.ExternalDocumentViewer ? (
          <ExternalDocumentViewer {...props} />
        ) : window.location.pathname === '/change-password' ? (
          <ChangePasswordPage {...props} />
        ) : window.location.pathname === '/word-online-invalid-subscription' ? (
          <WordOnlineErrorPage />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
