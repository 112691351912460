export default `
<svg
  aria-hidden="true"
  aria-label="file-image"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M8 4C6.875 4 6 4.90625 6 6V18C6 19.125 6.875 20 8 20H16C17.0938 20 18 19.125 18 18V9H14C13.4375 9 13 8.5625 13 8V4H8ZM14 4V8H18L14 4ZM10 12C10 12.5625 9.53125 13 9 13C8.4375 13 8 12.5625 8 12C8 11.4688 8.4375 11 9 11C9.53125 11 10 11.4688 10 12ZM12.75 13C12.9062 13 13.0625 13.0938 13.1562 13.2188L15.9062 17.2188C16 17.375 16 17.5938 15.9375 17.75C15.8438 17.9062 15.6875 18 15.5 18H12.75H11.5H10H8.5C8.3125 18 8.125 17.9062 8.0625 17.75C7.96875 17.5938 7.96875 17.4062 8.0625 17.25L9.5625 14.75C9.65625 14.5938 9.8125 14.5 10 14.5C10.1562 14.5 10.3125 14.5938 10.4062 14.75L10.8125 15.4375L12.3125 13.2188C12.4062 13.0938 12.5625 13.0312 12.75 13.0312V13Z"
    fill="#5216e9"
  />
</svg>
`;
