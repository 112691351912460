import PropTypes from 'prop-types';
import React from 'react';

import StatusIcon from '~/components/Workflow/StatusIcon';
import { TicketStatusType } from '~/enums';
import { Box, FlexLayout, Text } from '~/ui';

const noticeColors = {
  [TicketStatusType.Approved]: 'green-100',
  [TicketStatusType.Rejected]: 'red-100',
  [TicketStatusType.Pending]: 'yellow-100',
};

function TicketDocumentNotice({ status, description, note }) {
  return (
    <Box bg={noticeColors[status]} px={4} py={3} sx={{ borderRadius: 'm' }}>
      <FlexLayout alignItems="center" space={4}>
        <StatusIcon status={status} />
        <Box>{description}</Box>
      </FlexLayout>
      {note && (
        <Box ml={9} mt={4}>
          <Text color="gray-700" variant="s-spaced-bold">
            Note:{' '}
          </Text>
          <Text color="gray-700" variant="s-spaced">
            {note}
          </Text>
        </Box>
      )}
    </Box>
  );
}

TicketDocumentNotice.propTypes = {
  status: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  note: PropTypes.string,
};

export default TicketDocumentNotice;
