export default `
<svg
  aria-hidden="true"
  aria-label="loading-content"
  width="128"
  height="64"
  viewBox="0 0 128 64"
  fill="none"
>
  <style>
    .Bar_5_31 {
      animation: 0.9s linear 0.9s infinite forwards running Bar_5_31;
      transform: translateX(112px) translateY(24px);
      height: 16px;
      fill: rgba(205, 254, 244, 1);
    }
    @keyframes Bar_5_31 {
      0% {
        transform: translateX(112px) translateY(24px);
        height: 16px;
        fill: rgba(205, 254, 244, 1);
      }
      55.56% {
        transform: translateX(112px) translateY(24px);
        height: 16px;
        fill: rgba(205, 254, 244, 1);
      }
      66.67% {
        transform: translateX(112px) translateY(16px);
        height: 32px;
      }
      77.78% {
        transform: translateX(112px) translateY(0px);
        height: 64px;
        fill: rgba(2, 151, 121, 1);
      }
      88.89% {
        transform: translateX(112px) translateY(16px);
        height: 32px;
      }
      100% {
        transform: translateX(112px) translateY(24px);
        height: 16px;
        fill: rgba(205, 254, 244, 1);
      }
    }
    .Bar_6_637 {
      animation: 0.9s linear 0.9s infinite forwards running Bar_6_637;
      transform: translateX(84px) translateY(24px);
      height: 16px;
      fill: rgba(255, 211, 203, 1);
    }
    @keyframes Bar_6_637 {
      0% {
        transform: translateX(84px) translateY(24px);
        height: 16px;
        fill: rgba(255, 211, 203, 1);
      }
      44.44% {
        transform: translateX(84px) translateY(24px);
        height: 16px;
        fill: rgba(255, 211, 203, 1);
      }
      100% {
        transform: translateX(84px) translateY(24px);
        height: 16px;
        fill: rgba(255, 211, 203, 1);
      }
      55.56% {
        transform: translateX(84px) translateY(16px);
        height: 32px;
      }
      66.67% {
        transform: translateX(84px) translateY(0px);
        height: 64px;
        fill: rgba(255, 131, 109, 1);
      }
      77.78% {
        transform: translateX(84px) translateY(16px);
        height: 32px;
      }
      88.89% {
        transform: translateX(84px) translateY(24px);
        height: 16px;
        fill: rgba(255, 211, 203, 1);
      }
    }
    .Bar_7_650 {
      animation: 0.9s linear 0.9s infinite forwards running Bar_7_650;
      transform: translateX(56px) translateY(24px);
      height: 16px;
      fill: rgba(203, 220, 255, 1);
    }
    @keyframes Bar_7_650 {
      0% {
        transform: translateX(56px) translateY(24px);
        height: 16px;
        fill: rgba(203, 220, 255, 1);
      }
      33.33% {
        transform: translateX(56px) translateY(24px);
        height: 16px;
        fill: rgba(203, 220, 255, 1);
      }
      100% {
        transform: translateX(56px) translateY(24px);
        height: 16px;
        fill: rgba(203, 220, 255, 1);
      }
      44.44% {
        transform: translateX(56px) translateY(16px);
        height: 32px;
      }
      55.56% {
        transform: translateX(56px) translateY(0px);
        height: 64px;
        fill: rgba(68, 129, 255, 1);
      }
      66.67% {
        transform: translateX(56px) translateY(16px);
        height: 32px;
      }
      77.78% {
        transform: translateX(56px) translateY(24px);
        height: 16px;
        fill: rgba(203, 220, 255, 1);
      }
    }
    .Bar_8_31 {
      animation: 0.9s linear 0.9s infinite forwards running Bar_8_31;
      transform: translateX(28px) translateY(24px);
      height: 16px;
      fill: rgba(205, 254, 244, 1);
    }
    @keyframes Bar_8_31 {
      0% {
        transform: translateX(28px) translateY(24px);
        height: 16px;
        fill: rgba(205, 254, 244, 1);
      }
      22.22% {
        transform: translateX(28px) translateY(24px);
        height: 16px;
        fill: rgba(205, 254, 244, 1);
      }
      100% {
        transform: translateX(28px) translateY(24px);
        height: 16px;
        fill: rgba(205, 254, 244, 1);
      }
      33.33% {
        transform: translateX(28px) translateY(16px);
        height: 32px;
      }
      44.44% {
        transform: translateX(28px) translateY(0px);
        height: 64px;
        fill: rgba(2, 151, 121, 1);
      }
      55.56% {
        transform: translateX(28px) translateY(16px);
        height: 32px;
      }
      66.67% {
        transform: translateX(28px) translateY(24px);
        height: 16px;
        fill: rgba(205, 254, 244, 1);
      }
    }
    .Bar_9_744 {
      animation: 0.9s linear 0.9s infinite forwards running Bar_9_744;
      transform: translateX(0px) translateY(24px);
      height: 16px;
      fill: rgba(255, 211, 203, 1);
    }
    @keyframes Bar_9_744 {
      0% {
        transform: translateX(0px) translateY(24px);
        height: 16px;
        fill: rgba(255, 211, 203, 1);
      }
      11.11% {
        transform: translateX(0px) translateY(16px);
        height: 32px;
      }
      22.22% {
        transform: translateX(0px) translateY(0px);
        height: 64px;
        fill: rgba(255, 131, 109, 1);
      }
      33.33% {
        transform: translateX(0px) translateY(16px);
        height: 32px;
      }
      44.44% {
        transform: translateX(0px) translateY(24px);
        height: 16px;
        fill: rgba(255, 211, 203, 1);
      }
      100% {
        transform: translateX(0px) translateY(24px);
        height: 16px;
        fill: rgba(255, 211, 203, 1);
      }
    }
  </style>
  <g width="128" height="64" data-name="LoadingPageSpinner">
    <rect
      width="16"
      height="16"
      rx="8"
      fill="#CDFEF4"
      data-name="Bar 5"
      class="Bar_5_31"
    />
    <rect
      width="16"
      height="16"
      rx="8"
      fill="#FFD4CC"
      data-name="Bar 6"
      class="Bar_6_637"
    />
    <rect
      width="16"
      height="16"
      rx="8"
      fill="#CCDDFF"
      data-name="Bar 7"
      class="Bar_7_650"
    />
    <rect
      width="16"
      height="19.36"
      rx="8"
      fill="#B8F3E7"
      data-name="Bar 8"
      class="Bar_8_31"
    />
    <rect
      width="16"
      height="57.28"
      rx="8"
      fill="#FF8B77"
      data-name="Bar 9"
      class="Bar_9_744"
    />
  </g>
</svg>

`;
