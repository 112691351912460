import React from 'react';
import { Placement } from 'tippy.js';

import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { Popover } from '../Popover';

interface Props {
  /** Popover contents */
  content: React.ReactNode;
  /** Name to uniquely identify the popover trigger */
  name: string;
  /** Action button icon */
  icon?: 'chevron-down' | 'gear' | 'filter' | 'help' | 'more';
  /** Max width of popover */
  maxWidth?: number | string;
  /** Placement of the popover. */
  placement?: Placement;
  /** Trigger size */
  size?: 's' | 'm';
  /** Display text for the popover trigger */
  text?: string;
  /** Custom trigger element */
  trigger?: React.ReactElement;
  /** Trigger tooltip */
  tooltip?: string;
}

export const ActionsPopover = ({
  content,
  icon = 'more',
  name,
  maxWidth = 'none',
  placement = 'bottom',
  size = 'm',
  text,
  trigger: customTrigger,
  tooltip,
}: Props) => {
  const ButtonComponent = text ? Button : IconButton;
  const trigger = customTrigger ?? (
    <ButtonComponent
      icon={icon}
      label={name}
      size={size}
      text={text}
      tooltip={tooltip}
      variant="tertiary"
    />
  );

  return (
    <Popover maxWidth={maxWidth} placement={placement} trigger={trigger}>
      <>{content}</>
    </Popover>
  );
};
