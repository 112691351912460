import { api } from '../createApi';
import { TagType } from '../TagType';

const CONVERSATION_MESSAGES_TAG = {
  type: TagType.ConversationalAi,
  id: 'MESSAGES',
};
const ENTITY_CONVERSATIONS_TAG = {
  type: TagType.ConversationalAi,
  id: 'CONVERSATION',
};

api.enhanceEndpoints({
  addTagTypes: [TagType.ConversationalAi as TagType],
  endpoints: {
    getConversations: {
      providesTags: [ENTITY_CONVERSATIONS_TAG],
    },
    getConversation: {
      providesTags: [CONVERSATION_MESSAGES_TAG],
    },
    createConversation: {
      invalidatesTags: [ENTITY_CONVERSATIONS_TAG],
    },
    postUserMessage: {
      invalidatesTags: [CONVERSATION_MESSAGES_TAG],
    },
  },
});
