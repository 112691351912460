export default `
<svg
  aria-hidden="true"
  aria-label="save"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M12 12C10.875 12 10 12.9062 10 14C10 15.125 10.875 16 12 16C13.0938 16 14 15.125 14 14C14 12.9062 13.0938 12 12 12ZM18.5312 8.0625L15.9062 5.4375C15.6562 5.1875 15.25 5 14.875 5H7C5.875 5 5 5.90625 5 7V17C5 18.125 5.875 19 7 19H17C18.0938 19 19 18.125 19 17V9.125C19 8.75 18.8125 8.34375 18.5312 8.0625ZM9 6.5H13.5V9H9V6.5ZM17.5 17C17.5 17.2812 17.25 17.5 17 17.5H7C6.71875 17.5 6.5 17.2812 6.5 17V7C6.5 6.75 6.71875 6.5 7 6.5H7.5V9.75C7.5 10.1875 7.8125 10.5 8.25 10.5H14.25C14.6562 10.5 15 10.1875 15 9.75V6.625L17.4375 9.09375C17.4688 9.125 17.5 9.15625 17.5 9.21875V17Z"
    fill="currentcolor"
  />
</svg>
`;
