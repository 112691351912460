export default `
<svg
  aria-hidden="true"
  aria-label="format-numbered-list"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M4.60169 5.76562C4.60169 5.32812 4.94544 5.01562 5.35169 5.01562H6.35169C6.78919 5.01562 7.10169 5.32812 7.10169 5.76562V9.48438H7.60169C8.03919 9.48438 8.35169 9.82812 8.35169 10.2344C8.35169 10.6719 8.03919 10.9844 7.60169 10.9844H5.10169C4.69544 10.9844 4.35169 10.6719 4.35169 10.2344C4.35169 9.82812 4.69544 9.48438 5.10169 9.48438H5.60169V6.51562H5.35169C4.94544 6.51562 4.60169 6.17188 4.60169 5.76562ZM6.57044 14.6719C6.38294 14.4219 6.00794 14.4531 5.82044 14.7031L5.47669 15.1719C5.22669 15.5156 4.75794 15.6094 4.44544 15.3594C4.10169 15.1094 4.00794 14.6406 4.25794 14.3281L4.60169 13.8281C5.35169 12.7969 6.85169 12.7031 7.69544 13.6719C8.38294 14.4219 8.35169 15.5781 7.66419 16.3281L6.57044 17.4844H7.63294C8.03919 17.4844 8.38294 17.8281 8.38294 18.2344C8.38294 18.6719 8.03919 18.9844 7.63294 18.9844H4.88294C4.57044 18.9844 4.28919 18.8281 4.19544 18.5469C4.07044 18.2656 4.10169 17.9531 4.32044 17.7344L6.57044 15.2969C6.72669 15.1094 6.72669 14.8594 6.57044 14.6719ZM19.1329 6.23438C19.5392 6.23438 19.8829 6.57812 19.8829 6.98438C19.8829 7.42188 19.5392 7.73438 19.1329 7.73438H10.6329C10.1954 7.73438 9.88294 7.42188 9.88294 6.98438C9.88294 6.57812 10.1954 6.23438 10.6329 6.23438H19.1329ZM19.1329 11.2344C19.5392 11.2344 19.8829 11.5781 19.8829 11.9844C19.8829 12.4219 19.5392 12.7344 19.1329 12.7344H10.6329C10.1954 12.7344 9.88294 12.4219 9.88294 11.9844C9.88294 11.5781 10.1954 11.2344 10.6329 11.2344H19.1329ZM19.1329 16.2344C19.5392 16.2344 19.8829 16.5781 19.8829 16.9844C19.8829 17.4219 19.5392 17.7344 19.1329 17.7344H10.6329C10.1954 17.7344 9.88294 17.4219 9.88294 16.9844C9.88294 16.5781 10.1954 16.2344 10.6329 16.2344H19.1329Z"
    fill="currentcolor"
  />
</svg>
`;
