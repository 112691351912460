import useInterval from '@use-it/interval';
import { useEffect, useRef, useState } from 'react';

const DASHBOARD_READY_EVENT_TYPE = 'dashboard:loaded';

export enum DashboardStatusType {
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

interface Options {
  intervalMs: number;
  retries: number;
}

/**
 * This hook checks if a dashboard is ready by confirming existence of the iframe `'dashboard:run:complete'` event.
 *
 * Behaviors:
 * - Initialize with `Loading` status.
 * - Listen for the 'dashboard:run:complete' window message events from the iframe.
 *  - Set status to `Loaded` if message is received.
 *  - If message is not recevied, retry and check on an interval of `options.intervalMs`.
 *  - Set status to `Error` if `options.retries` is exhausted.
 * - Terminate the interval if status is either `Loaded` or `Error`.
 * - Cleanup the window message event listener.
 * - Return the status state variables.
 */
export const useIsDashboardReady = ({
  intervalMs = 1000,
  retries = 60,
}: Partial<Options> = {}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [status, setStatus] = useState<DashboardStatusType>(
    DashboardStatusType.Loading,
  );

  const shouldTerminate = [
    DashboardStatusType.Error,
    DashboardStatusType.Ready,
  ].includes(status);

  // Based on official recommendations from https://docs.looker.com/reference/embedding/embed-javascript-events#add_embed_domain
  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const iframe = iframeRef.current;
      if (iframe?.contentWindow === event.source) {
        const eventData = JSON.parse(event.data);
        if (eventData.type === DASHBOARD_READY_EVENT_TYPE) {
          setStatus(DashboardStatusType.Ready);
        }
      }
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  }, []);

  const retry = () => {
    setRetryCount(retryCount + 1);
    if (retryCount === retries) {
      setStatus(DashboardStatusType.Error);
    }
  };

  useInterval(retry, shouldTerminate ? null : intervalMs);

  return [status, setStatus, iframeRef];
};
