export default `
<svg
  aria-hidden="true"
  aria-label="arrow-left"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M18.9844 11.9845C18.9844 12.422 18.6406 12.7657 18.2344 12.7657H7.60938L11.7656 16.7032C12.0781 16.9845 12.0781 17.4845 11.7969 17.7657C11.5156 18.0782 11.0469 18.0782 10.7344 17.797L5.23438 12.547C5.07812 12.3907 5.01562 12.2032 5.01562 11.9845C5.01562 11.797 5.07812 11.6095 5.23438 11.4532L10.7344 6.20322C11.0469 5.92197 11.5156 5.92197 11.7969 6.23447C12.0781 6.51572 12.0781 7.01572 11.7656 7.29697L7.60938 11.2345H18.2344C18.6719 11.2345 18.9844 11.5782 18.9844 11.9845Z"
    fill="currentcolor"
  />
</svg>
`;
