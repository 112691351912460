import React, { useRef, useState } from 'react';

import { useLoadFileContent } from '~/eds';

import { InteractionPosition } from '../types';
import { HtmlContent } from './HtmlContent';
import { HtmlHighlightData, HtmlTokenHighlight } from './types';
import { useHighlightClick } from './useHighlightClick';
import { useHighlightCreate } from './useHighlightCreate';
import { useScrollToHighlight } from './useScrollToHighlight';

interface Props {
  file: File;
  activeElementId?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
  highlights?: HtmlTokenHighlight[];
  invalidateActiveHighlightId?: string;
  onHighlightClick?: (
    highlightId: string,
    position: InteractionPosition,
  ) => void;
  onHighlightCreate?: (
    highlightData: HtmlHighlightData,
    position: InteractionPosition,
  ) => void;
}

export const HtmlDocumentViewer = ({
  activeElementId,
  containerRef,
  file,
  highlights = [],
  invalidateActiveHighlightId,
  onHighlightClick,
  onHighlightCreate,
}: Props) => {
  const htmlContentRef = useRef<HTMLDivElement>(null);

  const [html, setHtml] = useState('');

  useLoadFileContent({
    file,
    onLoad: setHtml,
  });

  useHighlightClick({
    containerRef,
    highlights,
    onHighlightClick,
    deps: [html],
  });

  useHighlightCreate({
    htmlContentRef,
    containerRef,
    onHighlightCreate,
  });

  useScrollToHighlight({
    activeElementId,
    deps: [invalidateActiveHighlightId, html],
  });

  return (
    <div ref={htmlContentRef}>
      <HtmlContent html={html} />
    </div>
  );
};
