/**
 * Password rules include: minimum 8 characters, one lowercase, one uppercase, one number, and one special character
 */
export function validatePassword(password) {
  if (!password) return {};
  return {
    satisfiesMinimumCharacters: password?.length >= 8,
    satisfiesCapitalCharacter: /[A-Z]/.test(password),
    satisfiesLowercaseCharacter: /[a-z]/.test(password),
    satisfiesNumberCharacter: /\d/.test(password),
    satisfiesSpecialCharacter: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(
      password,
    ),
  };
}

export function passwordsValidation(p1, p2) {
  const validations = validatePassword(p1);
  const isPasswordValid =
    Object.values(validations).length > 0 &&
    Object.values(validations).every((validation) => !!validation);
  const isMatching = p1 === p2;
  const result = {
    validations,
    isMatching,
    isPasswordValid,
    allValid: isPasswordValid && isMatching,
  };

  return result;
}
