import { Layout } from '../../components';
import { MentionedEntity, SharedChipProps } from '../../types';
import { CommentDraft } from './CommentDraft';
import { CommentData, Entity, MentionEntityType, Mentions } from './types';

interface Props {
  entity: Entity;
  isMentionsLoading: boolean;
  isSubmitting: boolean;
  mentions: Mentions;
  resolvedMentions: Mentions;
  chips?: SharedChipProps[];
  onSubmit: (commentData: CommentData) => void;
  onMentionSearch?: (updatedSearch: string) => void;
  onMention?: (mention: MentionedEntity<MentionEntityType>) => void;
}

export const CommentsFooter = ({
  chips,
  entity,
  isMentionsLoading,
  isSubmitting,
  mentions,
  resolvedMentions,
  onMentionSearch,
  onMention,
  onSubmit,
}: Props) => {
  return (
    <Layout borderTop="border.divider" px={6} py={3}>
      <CommentDraft
        chips={chips}
        entity={entity}
        isFocused={true}
        isMentionsLoading={isMentionsLoading}
        isSubmitting={isSubmitting}
        mentions={mentions}
        resolvedMentions={resolvedMentions}
        onMention={onMention}
        onMentionSearch={onMentionSearch}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};
