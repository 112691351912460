import React, { useMemo } from 'react';

import { Select, types } from '~/eds';
import { Nullable } from '~/types';

import { DataFieldType } from '../enums';
import { getIconFromFieldType } from '../utils';

interface Props {
  value: Nullable<DataFieldType>;
  onChange: (updatedValue: Nullable<DataFieldType>) => void;
  config?: {
    acceptFieldTypes?: DataFieldType[];
    shouldMergeFieldTypes?: boolean;
  };
}

export const FieldTypeSelect = ({ config = {}, value, onChange }: Props) => {
  const {
    acceptFieldTypes = Object.values(DataFieldType),
    shouldMergeFieldTypes = true,
  } = config;

  const options = useMemo(() => {
    const options: types.Option<DataFieldType>[] = [];
    const fieldTypeSet = new Set(acceptFieldTypes);
    // LEGACY feature: if either ARRAY_SINGLE or ARRAY_MULTIPLE is present, merge and only resolve to ARRAY_MULTIPLE
    if (shouldMergeFieldTypes) {
      if (
        fieldTypeSet.has(
          DataFieldType.ARRAY_SINGLE ||
            fieldTypeSet.has(DataFieldType.ARRAY_MULTIPLE),
        )
      ) {
        fieldTypeSet.add(DataFieldType.ARRAY_SINGLE);
        fieldTypeSet.add(DataFieldType.ARRAY_MULTIPLE);
        fieldTypeSet.delete(DataFieldType.ARRAY_SINGLE);
      }
    }
    fieldTypeSet.forEach((fieldType) => {
      const label =
        shouldMergeFieldTypes &&
        [DataFieldType.ARRAY_SINGLE, DataFieldType.ARRAY_MULTIPLE].includes(
          fieldType,
        )
          ? 'Dropdown'
          : fieldTypeLabels[fieldType];
      options.push({
        leftIcon: getIconFromFieldType(fieldType),
        label,
        value: fieldType,
      });
    });
    return options;
  }, [acceptFieldTypes]);

  return (
    <Select
      enableSorting
      isClearable={false}
      isMulti={false}
      isSearchable={false}
      name="field-type-select"
      options={options}
      placeholder="Select a field type"
      value={value}
      onChange={onChange}
    />
  );
};

const fieldTypeLabels: Record<DataFieldType, string> = {
  [DataFieldType.AGE]: 'Number',
  [DataFieldType.ARRAY_MULTIPLE]: 'Dropdown (Multiple)',
  [DataFieldType.ARRAY_SINGLE]: 'Dropdown (Single)',
  [DataFieldType.ATTACHMENT]: 'Attachment',
  [DataFieldType.BOOLEAN]: 'Boolean',
  [DataFieldType.CLAUSE]: 'Clause',
  [DataFieldType.DATE]: 'Date',
  [DataFieldType.NUMBER]: 'Number',
  [DataFieldType.STRING]: 'Text',
  [DataFieldType.TEXT_AREA]: 'Text Area',
};
