import { union } from 'lodash';
import { useState } from 'react';

import type { TableActionType, TableState } from '../types/Table';

export const useTableSelection = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const clear = () => {
    setSelectedIds([]);
    setIsAllSelected(false);
    setTotalCount(0);
  };

  const onTableUpdate = (
    { selectedRowIds, isAllRowsSelected, totalCount }: TableState,
    action?: TableActionType,
  ) => {
    setTotalCount(totalCount);
    if (action?.type === 'toggleRowSelected') {
      setSelectedIds(selectedRowIds);
    }

    if (action?.type === 'toggleAllPageRowsSelected') {
      if (selectedRowIds.length > selectedIds.length) {
        setSelectedIds(union(selectedIds, selectedRowIds));
      } else {
        setSelectedIds(selectedRowIds);
      }
    }

    if (action?.type === 'toggleAllRowsSelected') {
      setIsAllSelected(isAllRowsSelected);
      if (!isAllRowsSelected) {
        setSelectedIds([]);
      }
    }

    if (action?.type === 'toggleMultipleRowsSelected') {
      setSelectedIds(union(selectedIds, selectedRowIds));
    }
  };

  return {
    clear,
    onTableUpdate,
    selectedIds,
    isAllSelected,
    totalCount,
  };
};
