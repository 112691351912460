import PropTypes from 'prop-types';
import React from 'react';

import { evisortBlue } from '../../../assets/shared-styles/general';

const CopyToClipboardIcon = (props) => {
  const { blue, color } = props;

  let iconColor = blue ? evisortBlue : color;

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={iconColor}
          fillRule="nonzero"
          d="M14.632 2H4.789C3.805 2 3 2.818 3 3.818v11.818c0 .5.403.91.895.91s.894-.41.894-.91V4.727c0-.5.403-.909.895-.909h8.948c.492 0 .894-.409.894-.909S15.124 2 14.632 2zm.527 4.173l4.322 4.39c.331.337.519.8.519 1.282v8.337c0 1-.805 1.818-1.79 1.818H8.36c-.985 0-1.781-.818-1.781-1.818l.009-12.727c0-1 .796-1.819 1.78-1.819h5.52c.475 0 .931.191 1.271.537zM14.632 12h4.026l-4.921-5v4.09c0 .5.402.91.895.91z"
        />
      </g>
    </svg>
  );
};

CopyToClipboardIcon.defaultProps = {
  size: '24',
  color: '#000',
};

CopyToClipboardIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default CopyToClipboardIcon;
