export default `
<svg
  aria-hidden="true"
  aria-label="help"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path d="M12 4C7.58125 4 4 7.58125 4 12C4 16.4187 7.58125 20 12 20C16.4187 20 20 16.4187 20 12C20 7.58125 16.4187 4 12 4ZM12 18.5C8.41563 18.5 5.5 15.5841 5.5 12C5.5 8.41594 8.41563 5.5 12 5.5C15.5844 5.5 18.5 8.41594 18.5 12C18.5 15.5841 15.5844 18.5 12 18.5ZM12 14.5C11.4375 14.5 11 14.9375 11 15.5C11 16.0625 11.4094 16.5 12 16.5C12.5344 16.5 13 16.0625 13 15.5C13 14.9375 12.5344 14.5 12 14.5ZM13.0344 8H11.4375C10.2188 8 9.25 8.96875 9.25 10.1875C9.25 10.5938 9.59375 10.9375 10 10.9375C10.4062 10.9375 10.75 10.5938 10.75 10.1875C10.75 9.8125 11.0344 9.5 11.4094 9.5H13.0063C13.4094 9.5 13.75 9.8125 13.75 10.1875C13.75 10.4375 13.625 10.6281 13.4062 10.7531L11.625 11.8438C11.375 12 11.25 12.25 11.25 12.5V13C11.25 13.4062 11.5938 13.75 12 13.75C12.4062 13.75 12.75 13.4062 12.75 13V12.9375L14.1594 12.0625C14.8156 11.6562 15.2219 10.9375 15.2219 10.1875C15.25 8.96875 14.2813 8 13.0344 8Z" fill="currentcolor"/>
</svg>
`;
