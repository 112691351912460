import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_ROLES_BY_LIST = { type: TagType.Roles, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.Roles as TagType],
  endpoints: {
    getRoles: {
      providesTags: [TAG_ROLES_BY_LIST],
    },
    createRole: {
      invalidatesTags: [TAG_ROLES_BY_LIST],
    },
    updateRole: {
      invalidatesTags: [TAG_ROLES_BY_LIST],
    },
    deleteRole: {
      invalidatesTags: [TAG_ROLES_BY_LIST],
    },
  },
});
