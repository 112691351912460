import { decamelizeKeys } from 'humps';
import React from 'react';
import { connect } from 'react-redux';

import { samlConfigEdit, samlConfigsSet, samlFormReset } from '~/actions';
import {
  createSAMLConfig,
  deleteSAMLConfig,
  fetchSAMLConfigs,
  fetchSAMLData,
  updateSAMLConfig,
} from '~/api';
import AdminConsolePageNavHeader from '~/components/Admin/AdminConsolePageNavHeader';
import {
  SAMLConfigForm,
  testIsValidForm,
} from '~/components/Admin/SSOConfigPage';
import { showToast } from '~/components/Shared/EcToast';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { Button } from '~/eds';
import { SAMLConfigEdit, SAMLConfigWizard } from '~/features/sso-settings';
import { FlagType, useFlag } from '~/flags';
import { useAsync } from '~/hooks';
import { useRouting } from '~/routing';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { FlexLayout, Text, useModal } from '~/ui';

function Page({
  client,
  currentUser,
  saml,
  // actions
  samlConfigsSet,
  samlConfigEdit,
}) {
  const { navigate, params } = useRouting();
  const { configId, clientId } = params;

  const isEditing = configId !== 'new';
  const isValidForm = testIsValidForm(saml);
  const invalidFormTooltip = isValidForm
    ? ''
    : 'Some configuration fields are missing or invalid';
  const hasNewUsersPage = useFlag(FlagType.NewUsersPage);

  const { sub_domain } = currentUser.client_config;
  const { isLoading: initializing } = useAsync(
    fetchSAMLData,
    {
      clientId: clientId ?? client.id,
      clientName: sub_domain,
      configId,
      isEditing,
    },
    {
      condition: client && currentUser && configId,
      deps: [client, currentUser, configId],
      successHandler: samlConfigEdit,
    },
  );

  const parseData = () => {
    const data = {};
    const mappings = [];

    saml.sections.forEach((section) => {
      section.configs.forEach((config) => {
        const name = config.fieldName;
        const value = config.field.value;
        const snakeCasedValue = decamelizeKeys(value ?? {});

        switch (name) {
          case 'attributes':
            //Keeping legacy mapping for backwards compatibility
            data[name] = {};
            data[name].email = value.email;
            data[name].first_name = value.firstName;
            data[name].last_name = value.lastName;

            Object.keys(snakeCasedValue).forEach((key) => {
              mappings.push({
                evisort_static_attr: key,
                evisort_custom_attr: null,
                saml_attr: snakeCasedValue[key] ?? '',
                type: 'static',
              });
            });

            break;
          case 'custom_attribute_map':
            // skip if legacy mapping
            if (value?.mappings) {
              mappings.push(
                ...value.mappings.filter(
                  (mapping) => mapping.type === 'custom',
                ),
              );
              break;
            }

            Object.keys(value ?? {}).forEach((key) => {
              mappings.push({
                evisort_static_attr: null,
                evisort_custom_attr: value[key],
                saml_attr: key,
                type: 'custom',
              });
            });
            break;
          case 'policy':
            data[name] = value.value;
            break;
          case 'auth_context_class':
            /* TODO remove list wrapper when support multiple contexts */
            data[name] = [value.value];
            break;
          case 'allowed_domains':
            data[name] = value.map((v) => v.value);
            break;
          case '':
            break;
          default:
            data[name] = value;
        }
      });
    });

    data.custom_attribute_map = {
      mappings,
    };

    return data;
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const { isLoading: isCreating, executor: createConfig } = useAsync(
    createSAMLConfig,
    { clientId: clientId ?? client.id, data: parseData() },
    {
      successToastMessage: 'Configuration has been saved successfully.',
      successHandler: navigateBack,
      errorToastMessage:
        'An error occurred while creating a new SAML Configuration.',
    },
  );

  const { isLoading: isUpdating, executor: updateConfig } = useAsync(
    updateSAMLConfig,
    {
      clientId: clientId ?? client.id,
      configId: saml.current?.id,
      data: parseData(),
    },
    {
      successToastMessage: 'Configuration has been saved successfully.',
      successHandler: navigateBack,
      errorToastMessage:
        'An error occurred while updating a new SAML Configuration.',
    },
  );

  const [modal, showModal] = useModal({
    disableCancelButton: false,
    title: 'Delete Configuration?',
    actionButton: {
      disabled: false,
      text: 'Delete',
      variant: 'red',
      errorHandler: () =>
        showToast(
          ERROR,
          'An error occurred while deleting SAML Configuration.',
        ),
      promise: async () => {
        await deleteSAMLConfig({
          clientId: clientId ?? client.id,
          configId: saml.current?.id,
        });

        const configs = await fetchSAMLConfigs({ clientId: client.id });
        samlConfigsSet(configs.results);

        navigateBack();
        showToast(SUCCESS, 'Configuration has been deleted successfully.');
      },
    },
    content: (
      <Text variant="xs-dense">
        {`Are you sure you want to remove the ${saml.current?.idp_name} configuration? `}
        <Text variant="xs-dense-bold">
          You won’t be able to undo this action.
        </Text>
      </Text>
    ),
  });

  const { isLoading } = useAsync(
    fetchSAMLConfigs,
    { clientId: client.id },
    {
      condition: client.id,
      deps: [client.id],
      successHandler: (response) => {
        samlFormReset();
        samlConfigsSet(response.results);
      },
    },
  );

  if (!isEditing && !!saml.configs.length && !!client.id) {
    navigate(`/admin/console/${client.id}/saml-configuration`, {
      replace: true,
    });
  }

  const handleSubmit = () => {
    if (isValidForm) {
      if (isEditing) {
        updateConfig();
      } else {
        createConfig();
      }
    } else {
      showToast(ERROR, 'Some configuration fields are missing or invalid');
    }
  };

  const getSAMLWizard = () => {
    if (hasNewUsersPage) {
      if (isEditing) {
        return (
          <SAMLConfigEdit
            clientId={clientId ?? client.id}
            onDelete={showModal}
            onSubmit={handleSubmit}
            onHide={navigateBack}
            sections={saml.sections}
          />
        );
      } else {
        return (
          <SAMLConfigWizard
            clientId={clientId ?? client.id}
            onSubmit={handleSubmit}
            onHide={navigateBack}
            sections={saml.sections}
          />
        );
      }
    } else {
      return <SAMLConfigForm />;
    }
  };

  return (
    <div data-testid="party-list-tab">
      {initializing || isCreating || isUpdating || isLoading ? (
        <FlexLayout justifyContent="center">
          <LoadingSpinner size="medium" />
        </FlexLayout>
      ) : (
        <>
          <FlexLayout
            alignItems="center"
            justifyContent="space-between"
            sx={{ minWidth: '1200px' }}
          >
            {!isEditing && (
              <>
                <AdminConsolePageNavHeader
                  title="New Configuration"
                  navigateBack={navigateBack}
                />
                <FlexLayout alignItems="center" space={4} px={16}>
                  <Button
                    text="Save Configuration"
                    disabled={!isValidForm}
                    onClick={() => createConfig()}
                    tooltip={invalidFormTooltip}
                    variant="primary"
                  />
                </FlexLayout>
              </>
            )}

            {isEditing && (
              <>
                <AdminConsolePageNavHeader
                  title="Edit Configuration"
                  navigateBack={navigateBack}
                />
                <FlexLayout alignItems="center" space={4} px={16}>
                  <Button
                    text="Update Configuration"
                    disabled={!isValidForm}
                    onClick={() => updateConfig()}
                    tooltip={invalidFormTooltip}
                    variant="primary"
                  />
                  <Button text="Delete Configuration" onClick={showModal} />
                </FlexLayout>
              </>
            )}
          </FlexLayout>
          {getSAMLWizard()}
        </>
      )}
      {modal}
    </div>
  );
}

const mapStateToProps = ({ saml, client, currentUser }) => ({
  saml,
  client,
  currentUser,
});

export default connect(mapStateToProps, {
  samlConfigsSet,
  samlConfigEdit,
})(Page);
