export const reactDatePicker = {
  '.react-datepicker__navigation--next': {
    borderLeftColor: 'text.primary',
  },
  '.react-datepicker__navigation--previous': {
    borderRightColor: 'text.primary',
  },
  '.react-datepicker__header': {
    backgroundColor: 'background.quiet',
  },
};
