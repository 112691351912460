import { saveAs } from 'file-saver';
import React from 'react';
import injectSheet from 'react-jss';

import { getDocumentOCR } from '~/components/DocumentsViewPage/Document.data';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import { showToast } from '~/components/Shared/EcToast';
import { getDocumentOriginal } from '~/redux/api/methods';
import { ERROR } from '~/types/toast.types';
import { hasOcrContent } from '~/utils/document.utils';

import styles from './SearchResultsItem.styles';
import { canDownloadSearchResultItem } from './utils';

function DownloadResultItemMenu({ document }) {
  const { document_handlers } = document;
  const [documentHandler] = document_handlers;
  const { id, document_name, file_type } = documentHandler;

  const displayError = () =>
    showToast(ERROR`Something went wrong with downloading ${document_name}`);

  const handleOnDownloadOriginalClick = () => {
    getDocumentOriginal(id)
      .then((res) => saveAs(res, `${document_name}.${file_type}`))
      .catch(displayError);
  };

  const handleOnDownloadOcrClick = () => {
    getDocumentOCR(id)
      .then((res) =>
        saveAs(res, `${document_name}.${res.type.split('/').pop()}`),
      )
      .catch(displayError);
  };

  const items = [];

  if (hasOcrContent(documentHandler)) {
    items.push({
      content: 'Download (Text)',
      onClick: handleOnDownloadOcrClick,
    });
  }

  items.push({
    content: 'Download (Original)',
    onClick: handleOnDownloadOriginalClick,
  });

  return canDownloadSearchResultItem(document) ? (
    <ActionsMenu align="end" title="Download document menu" items={items} />
  ) : null;
}

export default injectSheet(styles)(DownloadResultItemMenu);
