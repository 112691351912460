import PT from 'prop-types';
import React from 'react';

import { Box, FlexLayout, Icon, Text } from '~/ui';

function Step({ step }) {
  const { description, imgSrc, title } = step;
  return (
    <FlexLayout flexDirection="column" space={6}>
      <img alt={title} src={imgSrc} />
      <FlexLayout flexDirection="column" space={2}>
        <Text color="gray-800" variant="xs-dense-bold">
          {title}
        </Text>
        <Text color="gray-600" variant="xs-dense">
          {description}
        </Text>
      </FlexLayout>
    </FlexLayout>
  );
}

function FieldCardDetail({ bg, steps, title, onClose }) {
  return (
    <Box sx={{ position: 'relative', textAlign: 'center' }}>
      <Box
        bg={bg}
        sx={{
          top: 0,
          height: '200px',
          left: 0,
          position: 'absolute',
          width: '100%',
        }}
      />
      <FlexLayout flexDirection="column" px={6} py={10} space={10}>
        <FlexLayout justifyContent="space-between" sx={{ zIndex: 1 }}>
          <div />
          <Text color="gray-900" variant="s-dense-bold">
            {title}
          </Text>
          <Icon
            color="gray-600"
            icon="close"
            size="m"
            title="go back"
            onClick={onClose}
          />
        </FlexLayout>
        <FlexLayout space={2} sx={{ zIndex: 1 }}>
          {steps.map((step, i) => (
            <React.Fragment key={i}>
              <Step step={step} />
              {i < steps.length - 1 && (
                <Icon
                  color="gray-600"
                  icon="arrowRight"
                  size="xl"
                  sx={{ transform: 'translateY(50px)' }}
                />
              )}
            </React.Fragment>
          ))}
        </FlexLayout>
      </FlexLayout>
    </Box>
  );
}

const stepPropTypes = PT.shape({
  description: PT.string.isRequired,
  imgSrc: PT.string.isRequired,
  title: PT.string.isRequired,
});

FieldCardDetail.propTypes = {
  bg: PT.string.isRequired,
  steps: PT.arrayOf(stepPropTypes.isRequired).isRequired,
  title: PT.string.isRequired,
  onClose: PT.func.isRequired,
};

export default FieldCardDetail;
