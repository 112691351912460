import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import {
  getReviewerSettings,
  ReviewerAdminClients,
  ReviewerAdminDocuments,
  saveReviewerSettings,
  styles,
} from '~/components/ReviewerAdminPage';
import EcCard from '~/components/Shared/EcCard';
import { showToast } from '~/components/Shared/EcToast';
import EcToggle from '~/components/Shared/EcToggle';
import withHover from '~/components/Shared/HOCs/withHover/withHover';
import BackIcon from '~/components/Shared/Icons/BackIcon';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { withRouting } from '~/routing';

const ecCardContentStyles = { padding: '16px' };

const BackIconWithHover = withHover(BackIcon);

class Page extends Component {
  constructor(props) {
    super(props);

    this.handleOnToggleClick = this.handleOnToggleClick.bind(this);
    this.handleShowToast = this.handleShowToast.bind(this);
    this.state = {
      loading: true,
      errorLoading: false,
      show_already_reviewed: false,
    };
  }

  componentDidMount() {
    getReviewerSettings()
      .then((res) => {
        this.setState({ ...res, loading: false, errorLoading: false });
      })
      .catch(() => {
        this.setState({ loading: false, errorLoading: true });
      });
  }

  navigateBack = () => {
    this.props.navigate(-1);
  };

  handleOnToggleClick() {
    const { show_already_reviewed } = this.state;

    this.setState({ show_already_reviewed: !show_already_reviewed }, () => {
      saveReviewerSettings({
        show_already_reviewed: this.state.show_already_reviewed,
      }).catch(() =>
        this.setState({
          show_already_reviewed: !this.state.show_already_reviewed,
        }),
      );
    });
  }

  handleShowToast(type, text) {
    showToast(type, text);
  }

  renderLoadingSpinner() {
    const { classes } = this.props;

    return (
      <div className={classes.loadingContainer}>
        <LoadingSpinner size="medium" />
      </div>
    );
  }

  renderErrorLoading() {
    const { classes } = this.props;

    return (
      <div className={classes.errorMessage}>
        An error occurred while loading the page.
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      errorLoading,
      review_all_clients,
      selected_clients,
      selected_types,
      start_date,
      end_date,
      show_only_flagged_fields,
      review_all_types,
      show_already_reviewed,
    } = this.state;

    return (
      <div className={classes.reviewersPage}>
        <div className={classes.reviewerHeader}>
          <button
            className={classes.reviewerBackAction}
            onClick={this.navigateBack}
          >
            <BackIconWithHover />
          </button>
          <div className={classes.reviewerPageTitle}>
            <span className={classes.subTitle}>CONTRACT REVIEWER</span>
            <h2 className={classes.pageName}>Administration</h2>
          </div>
        </div>
        <div className={classes.contentContainer}>
          {loading && !errorLoading ? (
            this.renderLoadingSpinner()
          ) : !loading && errorLoading ? (
            this.renderErrorLoading()
          ) : (
            <Fragment>
              <ReviewerAdminClients
                reviewAllClients={review_all_clients}
                selectedClients={selected_clients.value}
                allClients={selected_clients.values}
                showToast={this.handleShowToast}
              />
              <ReviewerAdminDocuments
                documentsShownStartDate={start_date}
                documentsShownEndDate={end_date}
                showOnlyFlaggedFields={show_only_flagged_fields}
                reviewAllTypes={review_all_types}
                selectedFileTypes={selected_types.value}
                allFileTypes={selected_types.values}
                showToast={this.handleShowToast}
              />
              <div className={classes.cardWrapper}>
                <EcCard
                  title="Reviewed Documents"
                  contentStyles={ecCardContentStyles}
                >
                  <div className={classes.reviewedDocumentsContent}>
                    <div htmlFor="showDocumentsReviewedToggle">
                      Show documents that have already been reviewed
                    </div>
                    <EcToggle
                      active={show_already_reviewed}
                      id="showDocumentsReviewedToggle"
                      onClick={this.handleOnToggleClick}
                    />
                  </div>
                </EcCard>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(withRouting(Page));
