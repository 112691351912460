import PT from 'prop-types';
import React from 'react';

import { useShowMoreItems } from '../../hooks';
import Chip, { chipPropType } from '../Chip';
import FlexLayout from '../FlexLayout';

const mapChipText = (chip) => chip.text;

function ChipList({ chips, limit = 3 }) {
  const [visibleChips, showMore] = useShowMoreItems({
    items: chips,
    limit,
    mapItemText: mapChipText,
  });

  return (
    <FlexLayout alignItems="center" flexWrap="wrap" flexWrapSpace={1} space={1}>
      {visibleChips.map((chip, i) => (
        <Chip key={i} {...chip} />
      ))}
      {showMore}
    </FlexLayout>
  );
}

ChipList.propTypes = {
  /** An array of Chip props */
  chips: PT.arrayOf(chipPropType),
  /** Number of chips to display */
  limit: PT.number,
};

export default ChipList;
