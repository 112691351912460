import { formatNumber } from '~/eds';

import {
  OptimizeResult,
  OptimizeResultTag,
  OptimizeTableRow,
  OptimizeTestCase,
} from '../../types';

export const mapTagToLabel = (tag: OptimizeResultTag) => {
  switch (tag) {
    case 'best_no_user_config_changes':
    case 'best_overall_no_user_config_changes':
    default:
      return 'Current Instructions';
    case 'best_overall':
      return 'Highest Accuracy';
    case 'best_strictly_better':
      return 'Strictly Better';
  }
};

export const mapTagToDescription = (tag: OptimizeResultTag) => {
  switch (tag) {
    case 'best_no_user_config_changes':
    case 'best_overall_no_user_config_changes':
    default:
      return 'The model has been optimized to improve accuracy while keeping your current instructions.';
    case 'best_overall':
      return 'The model and instructions have been optimized to generate the highest accuracy.';
    case 'best_strictly_better':
      return 'The model and instructions have been optimized to correct errors without affecting correct answers.';
  }
};

export const mapStatsToStatusText = (stats: OptimizeResult['stats']) => {
  const { correct, delta_correct, total } = stats;

  const percentage = formatNumber(correct / total, {
    style: 'percent',
    decimal: 1,
  });
  const tooltip = 'Overall Accuracy';
  if (delta_correct > 0) {
    return {
      status: 'success' as const,
      text: percentage,
      tooltip,
      variant: 'section' as const,
    };
  }
  if (delta_correct < 0) {
    return {
      status: 'danger' as const,
      text: percentage,
      tooltip,
      variant: 'section' as const,
    };
  }
  return {
    status: 'info' as const,
    text: percentage,
    tooltip,
    variant: 'section' as const,
  };
};

export const mapTestCaseOutcomeToStatus = (testCase: OptimizeTestCase) => {
  const { outcome } = testCase;
  switch (outcome) {
    case 'correct':
      return {
        color: 'status.success',
        icon: 'status-success',
        tooltip: 'Correct',
      };
    case 'incorrect':
      return {
        color: 'status.danger',
        icon: 'status-danger',
        tooltip: 'Incorrect',
      };
    case 'partially_correct':
      return {
        color: 'status.warning',
        icon: 'status-warning',
        tooltip: 'Partially Correct',
      };
    default:
      return {
        color: 'status-info',
        icon: undefined,
        tooltip: undefined,
      };
  }
};

export const mapResultsToTableRows = (
  results: OptimizeResult[],
): OptimizeTableRow[] => {
  const rows: OptimizeTableRow[] = [];
  (results[0]?.testCases ?? []).forEach((testCase, index) => {
    const row: OptimizeTableRow = {
      id: (index + 1).toString(),
      entityId: testCase.entity.id,
    };
    results.forEach((result) => {
      row[result.tag] = result.testCases[index];
    });
    rows.push(row);
  });
  return rows;
};
