import React, { useEffect, useState } from 'react';

import { black2, black3, evisortBlue } from '~/assets/shared-styles/general';
import { Button, Layout } from '~/eds';
import { MODAL_SIMPLE } from '~/types/modal.types';

// use EcModal because it needs to support 4x zooming
import EcModal from '../EcModal';
import EcRadioButton from '../EcRadioButton';
import EcRadioContainer from '../EcRadioContainer';

const DocumentStatusOptions = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
  {
    label: 'Unknown',
    value: 'UNKNOWN',
  },
];

const DocumentStatusEditButton = ({ status, handleDocStatusChange }) => {
  const [modal, showEditDocStatusModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(status);

  const generateStatusString = () => {
    if (status === 'ACTIVE') {
      return <span style={styles.activeStatus}>ACTIVE</span>;
    } else if (status === 'INACTIVE') {
      return <span style={styles.inactiveStatus}>INACTIVE</span>;
    } else {
      return <span style={styles.unknownStatus}>UNKNOWN</span>;
    }
  };

  useEffect(() => {
    return () => {
      setSelectedValue(status);
    };
  }, [modal]);

  const renderOptions = (onOptionChange) => {
    return DocumentStatusOptions.map((opt) => {
      return (
        <EcRadioButton
          id={`${opt.label}_Status`}
          groupName="docStatusGroupOptions"
          value={opt.value}
          label={opt.label}
          onChange={onOptionChange}
          selectedValue={selectedValue}
          fontSize="13px"
          labelColor={black2}
        />
      );
    });
  };

  const renderStatusOptions = () => {
    return (
      <EcRadioContainer
        groupName="docStatusGroupOptions"
        onChange={({ selectedValue }) => setSelectedValue(selectedValue)}
        defaultValue={selectedValue}
      >
        {(groupName, selectedValue, onOptionChange) => (
          <div>{renderOptions(onOptionChange)}</div>
        )}
      </EcRadioContainer>
    );
  };

  const handleSaveDocumentStatus = () => {
    handleDocStatusChange(selectedValue);
    showEditDocStatusModal(false);
  };

  const renderModal = () => {
    if (modal) {
      return (
        <EcModal
          includeCancelButton={true}
          width="560px"
          modalType={MODAL_SIMPLE}
          title="Edit Document Status"
          hideModal={() => showEditDocStatusModal(false)}
          modalButtons={
            <Button
              variant="primary"
              text="Save"
              onClick={handleSaveDocumentStatus}
            />
          }
        >
          {renderStatusOptions()}
        </EcModal>
      );
    } else {
      return null;
    }
  };

  return (
    <Layout ml={2} mr={2}>
      <button
        aria-haspopup={true}
        style={styles.statusButton}
        onClick={() => showEditDocStatusModal(true)}
      >
        <div style={styles.statusHeader}>STATUS</div>
        {generateStatusString()}
      </button>
      {renderModal()}
    </Layout>
  );
};

const statusIndicator = {
  padding: '5px',
  width: '83px',
  display: 'inline-block',
  color: '#fff',
  borderRadius: '40px',
  fontSize: '11px',
  textAlign: 'center',
};

const styles = {
  statusButton: {
    cursor: 'pointer',
  },
  statusHeader: {
    marginBottom: '4px',
    display: 'block',
    fontSize: '10px',
    textAlign: 'center',
  },
  activeStatus: {
    ...statusIndicator,
    backgroundColor: evisortBlue,
  },
  inactiveStatus: {
    ...statusIndicator,
    backgroundColor: '#666',
  },
  unknownStatus: {
    ...statusIndicator,
    backgroundColor: black3,
  },
};

export default DocumentStatusEditButton;
