/**
 * Simplified implementation of MDN recommendation for autogrowing textarea based on its contents.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLTextAreaElement#autogrowing_textarea_example
 **/
export const autosizeTextarea = (textarea: HTMLTextAreaElement) => {
  textarea.style.height = 'auto';
  textarea.style.height = `${
    (textarea.scrollHeight === 0 ? 100 : textarea.scrollHeight) + OFFSET
  }px`;
};

const OFFSET = 4; // offsets to compensate for spillover pixels that may trigger overflows.
