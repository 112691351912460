import PT from 'prop-types';
import React from 'react';

import { ENTER } from '~/constants/keyCodes';
import { Box, styles } from '~/ui';
import { withInputError } from '~/ui/hocs';

const widths = {
  s: '200px',
  m: '260px',
  l: '520px',
  fitContent: 'fit-content',
  fullWidth: '100%',
};

function toInputValue(value) {
  return value == null || value === '' ? '' : Number(value);
}

function fromInputValue(inputValue, allowDecimals) {
  let numberValue = inputValue === '' ? null : Number(inputValue);
  if (numberValue && !allowDecimals) {
    numberValue = Math.trunc(numberValue);
  }
  return numberValue;
}

function validate({ max, min, value }) {
  let error;
  if (value != null) {
    if (min != null && max != null && (value < min || value > max)) {
      error = `Value must be between ${min} and ${max}`;
    } else if (value < min) {
      error = `Value must be greater than ${min}`;
    } else if (value > max) {
      error = `Value must be smaller than ${max}`;
    }
  }
  return error;
}

export function NumberInput({
  allowDecimals = true,
  ariaDescribedby,
  disabled = false,
  error,
  id,
  max,
  min,
  placeholder = '...',
  step,
  value,
  width = 'm',
  onChange,
  onBlur,
  onEnter,
  name,
}) {
  const handleKeyDown = (e) => {
    if (onEnter && e.keyCode === ENTER) {
      onEnter(fromInputValue(e.target.value, allowDecimals));
    }
  };

  const handleFocus = (focusEvent) => {
    focusEvent.target.addEventListener(
      'wheel',
      (wheelEvent) => wheelEvent.preventDefault(),
      { passive: false },
    );
  };
  return (
    <Box
      aria-describedby={ariaDescribedby}
      aria-label={name}
      as="input"
      disabled={disabled}
      id={id}
      max={max}
      min={min}
      placeholder={placeholder}
      step={step}
      role="spinbutton"
      name={name}
      sx={{
        ...styles.form.input,
        ...styles.form.numericInput,
        ...(error ? styles.form.error : {}),
        width: widths[width],
      }}
      type="number"
      value={toInputValue(value)}
      onFocus={handleFocus}
      onChange={(e) => onChange(fromInputValue(e.target.value, allowDecimals))}
      onBlur={(e) => onBlur?.(fromInputValue(e.target.value, allowDecimals))}
      onKeyDown={handleKeyDown}
    />
  );
}

NumberInput.propTypes = {
  /** flag to indicate if the value should truncate the decimal part */
  allowDecimals: PT.bool,
  /** Optional aria-describedby label for the input component */
  ariaDescribedby: PT.string,
  /** Optional aria label for the input component */
  ariaLabel: PT.string,
  /** Disables the input component */
  disabled: PT.bool,
  /** Input error */
  error: PT.string,
  /** Input ID */
  id: PT.string,
  /** Max allowed value */
  max: PT.number,
  /** Min allowed value */
  min: PT.number,
  /** Placeholder */
  placeholder: PT.string,
  /** Numeric step value */
  step: PT.number,
  /** Numeric input value (null value supported) */
  value: PT.number,
  /** Input width */
  width: PT.oneOf(['s', 'm', 'l', 'fitContent', 'fullWidth']),
  /** Callback that returns new selected value */
  onChange: PT.func.isRequired,
  /** Callback that returns new selected value */
  onBlur: PT.func,
  /** Callback that returns new selected value */
  onEnter: PT.func,
};

export default withInputError(NumberInput, validate);
