import { v4 as uuid } from 'uuid';

import { URL_REGEXP } from '~/constants/regexp';

export const provider = {
  id: uuid(),
  name: 'Provider SAML Information',
  title: 'Evisort service provider details',
  description: 'Enter the following fields into your IDP.',
  instructions: [
    {
      id: 'saml_sp_url',
      title: 'Assertion consumer service (ACS) URL',
      description:
        'The ACS URL tells the IDP where to redirect an authenticated user after sign-in. ',
    },
    {
      id: 'sp_id',
      title: 'Service provider (SP) entity ID',
      description:
        'This is Evisort’s unique identifier and does not change per implementation.',
    },
    {
      id: 'preferred_settings',
      title: 'Additional settings',
      description:
        'This section is optional and only applies if your IDP supports configuring verification certificates or signed requests.',
    },
    {
      id: 'provider_details_4',
      title: 'Downloadable image (Optional)',
      description:
        'Upload the Evisort logo into your IDP.\n\n[View Evisort logo](https://cdn-assets-cloud.frontify.com/s3/frontify-cloud-files-us/eyJwYXRoIjoiZnJvbnRpZnlcL2ZpbGVcL1lMbVYyVGh0ajJnelhEUW5IODlnLnBuZyJ9:frontify:FowozH95lCVpdRUE9VWBMR2BdHRjVod5vNpo9wfX2Fw)',
    },
  ],
  configs: [
    {
      id: uuid(),
      title: 'Assertion consumer service (ACS) URL',
      description: 'This is also known as Reply URL.',
      fieldName: 'saml_sp_url',
      copiable: true,
      disabled: true,
      visible: true,
      required: true,
      field: {
        id: uuid(),
        type: 'SHORT_RESPONSE',
        value: null,
      },
    },
    {
      id: uuid(),
      title: 'Service provider (SP) entity ID',
      description: '',
      fieldName: 'sp_id',
      copiable: true,
      disabled: true,
      visible: true,
      required: true,
      field: {
        id: uuid(),
        type: 'SHORT_RESPONSE',
        value: null,
      },
    },
    {
      id: uuid(),
      title: 'Additional settings',
      description: 'Enter these details if specified by your IDP.',
      fieldName: 'preferred_settings',
      copiable: false,
      disabled: false,
      visible: true,
      required: false,
      field: {
        id: uuid(),
        type: 'STATIC',
        value: [
          {
            title: 'Assertion Signature',
            value: 'Signed',
          },
          {
            title: 'Signature Algorithm',
            value: 'RSA-SHA256',
          },
        ],
      },
    },
  ],
};

export const configs = {
  id: uuid(),
  name: 'SAML Configuration',
  title: 'Identity provider details',
  description: 'Retrieve the following fields from your IDP.',
  instructions: [
    {
      id: 'idp_name',
      title: 'IDP name',
      description:
        'The name of your identity provider (Eg: Azure, Okta, Google) that users will be signing in with. The name entered here is for display purposes only.',
    },
    {
      id: 'sso_url',
      title: 'SSO URL',
      description:
        'This is the URL where Evisort will redirect users to sign in to your IDP.',
    },
    {
      id: 'idp_id',
      title: 'IDP ID',
      description: 'This is your IDP’s unique identifier.',
    },
    {
      id: 'idp_metadata_url',
      title: 'IDP metadata URL',
      description:
        'Input this if the X.509 Cert is variable or rotating. \n\n Note: In some cases, if you provide this then you do not need to enter the X.509 certificate. However, this depends on your setup and it may be required to save the configuration.',
    },
    {
      id: 'x509_cert',
      title: 'X.509 cert',
      description:
        'Find and open the metadata XML file in your IDP. Copy and paste the X.509 Base64 certificate code here. You can find the code between BEGIN CERTIFICATE and END CERTIFICATE.',
    },
    {
      id: 'auth_context_class',
      title: 'Authentication context class',
      description:
        'Identifies the SAML authentication context class for the assertion’s authentication statement. The options are: Password Protected Transport (default), None, Password, Unspecified, TLS Client, X.509 Certificate, and Kerberos. \n\n Note: If you are using Azure AD, you can set this to None.',
    },
  ],
  configs: [
    {
      id: uuid(),
      title: 'IDP name',
      fieldName: 'idp_name',
      description: 'This is used for display purposes only.',
      copiable: false,
      disabled: false,
      visible: true,
      required: true,
      field: {
        id: uuid(),
        type: 'SHORT_RESPONSE',
        value: null,
      },
    },
    {
      id: uuid(),
      title: 'SSO URL',
      description: 'This is also known as the SAML 2.0 Endpoint.',
      fieldName: 'sso_url',
      copiable: false,
      disabled: false,
      visible: true,
      required: true,
      pattern: URL_REGEXP,
      patternError: 'Enter a valid URL.',
      field: {
        id: uuid(),
        type: 'SHORT_RESPONSE',
        value: null,
      },
    },
    {
      id: uuid(),
      title: 'IDP ID',
      description:
        'This is also known as the Identity Provider Issuer or Entity ID.',
      fieldName: 'idp_id',
      copiable: false,
      disabled: false,
      visible: true,
      required: true,
      field: {
        id: uuid(),
        type: 'SHORT_RESPONSE',
        value: null,
      },
    },
    {
      id: uuid(),
      title: 'IDP metadata URL',
      description: 'Enter this if the X.509 Cert is variable or rotating.',
      fieldName: 'idp_metadata_url',
      copiable: false,
      disabled: false,
      visible: true,
      required: false,
      pattern: URL_REGEXP,
      patternError: 'Enter a valid URL.',
      field: {
        id: uuid(),
        type: 'SHORT_RESPONSE',
        value: null,
      },
    },
    {
      id: uuid(),
      title: 'X.509 cert',
      description:
        "Copy and paste the certificate. You can find this in your IDP's metadata.xml file.",
      fieldName: 'x509_cert',
      copiable: false,
      disabled: false,
      visible: true,
      required: true,
      field: {
        id: uuid(),
        type: 'LONG_RESPONSE',
        value: null,
      },
    },
    {
      id: uuid(),
      title: 'Authentication Context Class',
      description: 'Enter this if specified by your IDP.',
      fieldName: 'auth_context_class',
      copiable: false,
      disabled: false,
      visible: true,
      required: false,
      field: {
        id: uuid(),
        options: [
          {
            label: 'Password Protected Transport',
            value: 'password_protected_transport',
            descriptions: [],
          },
          {
            label: 'None',
            value: 'none',
            descriptions: [],
          },
          {
            label: 'Password',
            value: 'password',
            descriptions: [],
          },
          {
            label: 'Unspecified',
            value: 'unspecified',
            descriptions: [],
          },
          {
            label: 'TLS Client',
            value: 'tls_client',
            descriptions: [],
          },
          {
            label: 'X.509 Certificate',
            value: 'x509',
            descriptions: [],
          },
          {
            label: 'X.509 Multifactor',
            value: 'x509_multifactor',
            descriptions: [],
          },
          {
            label: 'Kerberos',
            value: 'kerberos',
            descriptions: [],
          },
        ],
        type: 'SINGLE_SELECT',
        value: {
          label: 'Password Protected Transport',
          value: 'password_protected_transport',
          descriptions: [],
        },
      },
    },
  ],
};

export const attributeMapping = {
  id: uuid(),
  name: 'User attribute mapping',
  title: 'User attribute mapping',
  instructions: [
    {
      id: 'attributes',
      title: 'Supported User Attributes',
      description:
        'Attribute mappings are used to control which user profile details are exchanged between your IDP and Evisort during the user provisioning process. \n\n Evisort requires the Email, First name, and Last name attributes to create and sign in a user. \n\n Note: The attributes can be named however you want, just ensure they match between the IDP side and the Evisort side. \n\n See guides for these IDPs: \n - [View Okta example](https://support.evisort.com/hc/en-us/articles/360048160873-Configuring-Single-Sign-On-SSO-#01H7TWQ34TKEHXMHEW205EB8NB) \n - [View Microsoft Entra ID example](https://support.evisort.com/hc/en-us/articles/360048160873-Configuring-Single-Sign-On-SSO-#01H7TWQ34TF2Y3NBZ7K5TTFQNY) \n - [View OneLogin example](https://support.evisort.com/hc/en-us/articles/360048160873-Configuring-Single-Sign-On-SSO-#01H7TWQ34TCPH1JNJABJNSWS0A)',
    },
    {
      id: 'custom_attribute_map',
      title: 'Custom user attributes',
      description:
        'You also have the option to add and map additional attributes that will be visible on the Users table. This is useful if your organization uses other attributes to categorize or define users. \n\n Example attributes: \n - Security group \n - User ID \n - Cost center',
    },
  ],
  configs: [
    {
      id: uuid(),
      title: 'Supported user attributes',
      description:
        'Copy the attribute names or URLs from your IDP and paste them here. You can use any attribute name, but ensure the name here matches the name in your IDP.',
      fieldName: 'attributes',
      copiable: false,
      disabled: false,
      visible: true,
      required: true,
      field: {
        id: uuid(),
        type: 'ATTRIBUTES',
        value: {
          email: null,
          firstName: null,
          lastName: null,
          jobTitle: null,
          department: null,
          role: null,
        },
      },
    },
    {
      id: uuid(),
      title: 'Custom user attributes (optional)',
      description:
        'Copy the attribute names or URLs from your IDP and paste them here. You can use any attribute name, but ensure the name here matches the name in your IDP.',
      copiable: false,
      disabled: false,
      visible: true,
      required: false,
      fieldName: 'custom_attribute_map',
      field: {
        id: uuid(),
        type: 'CUSTOM_ATTRIBUTES',
        value: {},
      },
    },
  ],
};

export const enforce = {
  id: uuid(),
  name: 'Enforcement Options',
  title: 'SSO Enforcement',
  alerts: [
    {
      message:
        'SSO must be enabled for All users in order for Evisort to sync with your IDP.',
      status: 'info',
    },
  ],
  instructions: [
    {
      id: 'is_enabled',
      title: 'SSO enablement',
      description:
        'When enable SSO is: \n - Checked: the single sign-on option shows on the user sign in page and user attributes sync with your IDP \n - Unchecked: the single sign-on option does not show on the user sign in page and user attributes do not sync with your IDP',
    },
    {
      id: 'policy',
      title: 'SSO enforced users',
      description:
        'This can be set to: \n - None: users are not required to sign in via SSO \n - All Users: users (all roles) are required to sign in via SSO \n - Non-Admin users: users (exlcuding Admins) are required to sign in via SSO \n - Select Domains: select which domains sign in via SSO',
    },
    {
      id: 'default_role',
      title: 'Default role',
      description:
        'Newly provisioned users who sign in for the first time through SSO are automatically assigned a default role. You can change this default role at any time.\n\n Note: To assign a different role to users who have already signed in, navigate to the Users page.',
    },
  ],
  configs: [
    {
      id: uuid(),
      title: 'SSO enablement',
      description:
        'This will turn on the single sign-on option on the sign in page and sync user attributes with your IDP.',
      fieldName: 'is_enabled',
      copiable: false,
      disabled: false,
      visible: true,
      required: false,
      field: {
        id: uuid(),
        type: 'TOGGLE',
        name: 'Enable SSO',
        description: 'Users will be able to log in via the SSO provider.',
        value: true,
      },
    },
    {
      id: uuid(),
      title: 'SSO enforced users',
      description: 'Select which users must use SSO to sign in.',
      fieldName: 'policy',
      copiable: false,
      disabled: false,
      visible: true,
      required: false,
      field: {
        id: uuid(),
        options: [
          {
            label: 'Not Enforced',
            value: 'enforce_none',
            descriptions: [
              'Users will have the option to log in via the SSO provider or using their username and password.',
            ],
          },
          {
            label: 'Enforced for All Users',
            value: 'enforce_all',
            descriptions: [
              'All users will have to log in using the SSO provider.',
              'Note: Users with email addresses that end with "@evisort.com" will be able to log into the platform using their email and password to provide support to your organization.',
            ],
          },
          {
            label: 'Enforced for All Non-Admin Users',
            value: 'enforce_all_non_admin',
            descriptions: [
              'Only Admins will be able to log into the platform using their email and password. All other users will have to log in using the SSO provider.',
            ],
          },
          {
            label: 'Enforced for Select Domains',
            value: 'enforce_domains',
            descriptions: [
              'All users with email addresses from the provided list of domains must log in using SSO.',
              'Note: Users with email addresses that end with "evisort.com" will be able to log into the platform using their email and password to provide support to your organization.',
            ],
          },
        ],
        description:
          'Users will have the option to log in via the SSO provider or using their username and password.',
        type: 'SINGLE_SELECT',
        value: {
          label: 'Enforced for All Users',
          value: 'enforce_all',
          descriptions: [
            'Users will have the option to log in via the SSO provider or using their username and password.',
          ],
        },
      },
    },
    {
      id: uuid(),
      title: 'Enforced domains',
      description: 'Enter email addresses that must log in via SSO.',
      fieldName: 'allowed_domains',
      copiable: false,
      disabled: false,
      visible: false,
      required: false,
      field: {
        id: uuid(),
        type: 'MULTI_SELECT',
        options: [],
        value: [],
      },
    },
    {
      id: uuid(),
      title: 'Default Role',
      description: 'Assign a role to new users who sign in via SSO.',
      copiable: false,
      disabled: false,
      visible: true,
      required: false,
      fieldName: 'default_role',
      field: {
        id: uuid(),
        type: 'ROLE_SELECT',
        options: [],
        value: null,
      },
    },
  ],
};
