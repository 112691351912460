import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import ActionsIcon from '~/components/Shared/Icons/ActionsIcon';
import ChevronDownIcon from '~/components/Shared/Icons/ChevronDownIcon';
import ChevronRightIcon from '~/components/Shared/Icons/ChevronRightIcon';
import StatusIcon from '~/components/Workflow/StatusIcon';
import { EntityType, TicketStageType, TicketStatusType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { withUsers } from '~/hocs';
import { FlexLayout, Icon, Text } from '~/ui';
import { testIsUnassignedUser } from '~/utils/ticket';

import TicketApprovalList from '../TicketApprovalList';
import styles from './TicketReviewPhase.styles';
import {
  calculatePhaseStatues,
  lockReviewPhase,
} from './TicketReviewPhase.utils';

const { Approved, Completed, Rejected, Pending } = TicketStatusType;
const getPendingTaskName = (stage) => {
  switch (stage) {
    case TicketStageType.Review:
      return 'review';
    case TicketStageType.Sign:
      return 'signature';
    case TicketStageType.Finalize:
      return 'finalization';
    default:
      return 'task';
  }
};

const TicketReviewPhase = ({
  phaseId,
  previousPhaseId,
  stage,
  // injected props
  classes,
  // connected props
  phases,
  judgments,
  risks,
  approvals,
  users,
}) => {
  const phase = phases[phaseId];
  const previousPhase = phases[previousPhaseId];
  const previousPhaseName = previousPhase ? previousPhase.name : null;

  const isLocked =
    stage === TicketStageType.Sign ? false : lockReviewPhase(previousPhase);
  const isCCRecipientsEnabled = useFlag(FlagType.CCRecipients);
  const isExpandedInitial = phase.status !== Approved && !isLocked;
  const [isExpanded, setIsExpanded] = useState(isExpandedInitial);

  const previousPhaseStatus = previousPhase ? previousPhase.status : null;

  useEffect(() => {
    setIsExpanded(isExpandedInitial);
  }, [previousPhaseStatus, isExpandedInitial]);

  const handleOnPhaseClick = () => {
    if (!isLocked) setIsExpanded(!isExpanded);
  };

  const testShouldFilterCCSigner = (phaseApproval) =>
    phaseApproval &&
    (isCCRecipientsEnabled ||
      stage !== TicketStageType.Sign ||
      phaseApproval.status !== TicketStatusType.NotApplicable);

  const reviewPhaseStyle = isExpanded
    ? classes.reviewPhaseExpanded
    : classes.reviewPhaseCollapsed;

  const renderLockedHeader = () => {
    return (
      <FlexLayout
        className={classes.phasePending}
        alignItems="center"
        space={1}
      >
        <ActionsIcon size="16" color="rgba(0, 0, 0, 0.5)" />
        <Text color="black-alpha-50" variant="xs-dense-bold">
          Pending {previousPhaseName} Completion
        </Text>
      </FlexLayout>
    );
  };

  const renderApprovedHeader = () => {
    return (
      <FlexLayout
        className={classes.phaseApproved}
        alignItems="center"
        space={1}
      >
        <Icon icon="checkmark" size="xs" color="white" />
        <Text color="white" variant="xs-dense-bold">
          Completed
        </Text>
      </FlexLayout>
    );
  };

  const { judgmentIds, riskIds } = phase;

  const judgmentsApprovalIds = judgmentIds.reduce((arr, judgmentId) => {
    const judgment = judgments[judgmentId];
    return [...arr, ...judgment.approvalIds];
  }, []);

  const risksApprovalIds = riskIds.reduce((arr, riskId) => {
    const risk = risks[riskId];
    return [...arr, ...risk.approvalIds];
  }, []);

  const phaseApprovals = useMemo(
    () =>
      [...judgmentsApprovalIds, ...risksApprovalIds]
        .map((id) => approvals[id])
        .filter(testShouldFilterCCSigner),
    [judgmentsApprovalIds, risksApprovalIds],
  );

  const renderSingleStatus = (count, status) => {
    const hasUnassignedUsers = Object.values(phaseApprovals).some((approval) =>
      testIsUnassignedUser(approval, users),
    );
    const pendingDonutColor = hasUnassignedUsers ? 'red-400' : 'yellow-500';

    return status === Pending ? (
      <FlexLayout alignItems="center" space={2}>
        <Icon icon="donut" color={pendingDonutColor} size="s" />
        <Text
          color="gray-700"
          variant="xs-dense"
        >{`${count} pending ${pluralize(
          getPendingTaskName(stage),
          count,
        )}`}</Text>
      </FlexLayout>
    ) : (
      <FlexLayout alignItems="center" space={2}>
        <StatusIcon status={status} />
        <Text color="gray-700" variant="xs-dense">
          {`${count} ${status}`}
        </Text>
      </FlexLayout>
    );
  };

  const renderStatsHeader = () => {
    const { pending, approved, rejected } = calculatePhaseStatues(
      phaseApprovals,
      stage,
    );

    return (
      <FlexLayout alignItems="center" space={6}>
        {!!approved && renderSingleStatus(approved, Completed)}
        {!!rejected && renderSingleStatus(rejected, Rejected)}
        {!!pending && renderSingleStatus(pending, Pending)}
      </FlexLayout>
    );
  };

  const removeCCSigners = (judgment) => {
    const judgmentApproval =
      judgment.approvalIds && approvals[judgment.approvalIds[0]];
    return testShouldFilterCCSigner(judgmentApproval);
  };

  const items = useMemo(() => {
    return [
      ...judgmentIds.map((judgmentId) => ({
        ...judgments[judgmentId],
        entityType: EntityType.TicketJudgment,
      })),
      ...riskIds.map((riskId) => ({
        ...risks[riskId],
        entityType: EntityType.Risk,
      })),
    ].filter(removeCCSigners);
  }, [judgmentIds, riskIds]);

  return (
    <div className={reviewPhaseStyle}>
      <FlexLayout
        className={classes.reviewSummary}
        alignItems="center"
        onClick={handleOnPhaseClick}
      >
        {isExpanded ? (
          <ChevronDownIcon opacity=".25" />
        ) : (
          <ChevronRightIcon opacity=".25" />
        )}
        <div className={classes.phaseName}>{phase.name}</div>
        {isLocked
          ? renderLockedHeader()
          : phase.status === Approved
          ? renderApprovedHeader()
          : renderStatsHeader()}
      </FlexLayout>
      {isExpanded && (
        <div className={classes.reviewDetails}>
          {phase.description && (
            <div className={classes.criterionDescription}>
              {phase.description}
            </div>
          )}
          {items.map((reviewCriterion) => {
            const { id, approvalIds, entityType, name } = reviewCriterion;
            return (
              <div className={classes.phaseSubsection} key={id}>
                <div className={classes.subsectionTitle}>
                  {reviewCriterion.name.toUpperCase()}
                </div>
                {reviewCriterion.label && (
                  <div className={classes.criterionName}>
                    {reviewCriterion.label}
                  </div>
                )}
                {reviewCriterion.description && (
                  <div className={classes.criterionDescription}>
                    {reviewCriterion.description}
                  </div>
                )}
                <TicketApprovalList
                  issueId={id}
                  entityType={entityType}
                  approvalName={name}
                  approvalList={approvalIds}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

TicketReviewPhase.propTypes = {
  phaseId: PropTypes.string.isRequired,
  // First phase in the row doesn't have the previous one so the name is optional
  previousPhaseId: PropTypes.string,
};

function mapStateToProps({ ticket }) {
  const { phases, judgments, risks, approvals } = ticket;
  return { phases, judgments, risks, approvals };
}

export default injectSheet(styles)(
  connect(mapStateToProps)(withUsers(TicketReviewPhase)),
);
