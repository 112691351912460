import {
  black4,
  bold,
  evisortBlue,
} from '../../../assets/shared-styles/general';

const itemFieldContainer = {
  padding: '10px 0',
  display: 'flex',
  fontSize: '14px',
  borderBottom: '1px solid #ccc',
  '&:last-child': {
    borderBottom: 'none',
  },
};

const styles = {
  headerContainer: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: bold,
  },
  titleActionContainer: {
    display: 'flex',
    alignItems: 'center',
    color: evisortBlue,
    fontSize: '12px',
    fontWeight: bold,
    cursor: 'pointer',
  },
  listContainer: {
    maxHeight: '300px',
    overflowY: 'scroll',
  },
  itemRowActionButton: {
    marginRight: '7px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  itemFieldContainer: {
    ...itemFieldContainer,
  },
  itemFieldEditingContainer: {
    ...itemFieldContainer,
    justifyContent: 'space-between',
  },
  fieldName: {
    display: 'flex',
    alignItems: 'center',
  },
  dragHandleContainer: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    color: black4,
    cursor: 'row-resize',
  },
  sortableHelper: {
    zIndex: 10,
  },
};

export default styles;
