import { useSelector } from 'react-redux';

import { selectors } from '~/redux';
import { Client } from '~/types';

/**
 * TODO: this hook is very inefficient/slow.  Avoid using it if possible.
 * - Refactor to source data from a simple GET /client endpoint.
 * - Use this hook only if you need information such as client `folderTree` or `dataFields` (but these should exist in separate resource endpoints in the first place).
 * - Use `useCurrentUser().client` to obtain a client ID instead
 */
export const useClient = (): Client => {
  return useSelector(selectors.selectClient) as Client;
};
