import PT from 'prop-types';
import React, { useState } from 'react';

import { Button } from '~/eds';
import { Card } from '~/ui';

import IntegrationDetailCard from '../IntegrationDetailCard';
import ContactIntegrationMessage from './ContactIntegrationMessage';

function ContactIntegrationCard({
  description,
  logoSrc,
  rightActionText,
  title,
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  function handleClose() {
    setIsCollapsed(true);
  }

  const footer = (
    <Card.Footer
      actionButtons={[
        <Button text="Close" variant="tertiary" onClick={handleClose} />,
      ]}
    />
  );

  return (
    <IntegrationDetailCard
      isCollapsed={isCollapsed}
      footer={footer}
      headerRightContent={
        <Button disabled text={rightActionText} variant="action" />
      }
      logoSrc={logoSrc}
      title={title}
      onToggleCollapse={setIsCollapsed}
    >
      <ContactIntegrationMessage description={description} />
    </IntegrationDetailCard>
  );
}

ContactIntegrationCard.propTypes = {
  description: PT.string.isRequired,
  logoSrc: PT.string.isRequired,
  rightActionText: PT.string.isRequired,
  title: PT.string.isRequired,
};

export default ContactIntegrationCard;
