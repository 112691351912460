import React, { memo } from 'react';

import { useToggle } from '../../hooks';
import { Input } from '../../types';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { TextInput } from '../TextInput';

export const PasswordInput = memo((props: Input<string>) => {
  const [isVisible, toggleIsVisible] = useToggle(false);

  return (
    <Box styles={componentStyles.container}>
      <TextInput {...props} type={isVisible ? 'text' : 'password'} />
      <Box styles={componentStyles.icon}>
        <IconButton
          icon={isVisible ? 'not-visible' : 'view'}
          size="s"
          tooltip={isVisible ? 'Hide password' : 'Show password'}
          onClick={toggleIsVisible}
        />
      </Box>
    </Box>
  );
});

// TODO EKP-18796: formalize `leftIcon`, `rightIcon` in `BaseInput` component.
export const componentStyles = {
  container: {
    minWidth: 'max-content',
    position: 'relative',
    '> input': {
      paddingRight: 12, // hardcoded use of "!important" but still preferable to overloading TextInput
    },
  },
  icon: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    position: 'absolute',
    right: 2,
    top: 0,
  },
};
