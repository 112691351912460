import { createSlice } from '@reduxjs/toolkit';

import { AddToGroupStepType } from '~/enums';

export type DocumentsSlice = {
  columnOrder: string[];
  currentTableView?: number | string;
  currentAddToGroupStep: AddToGroupStepType;
  selectedDocumentId?: number;
  selectedGroupId?: number;
};

export const initialState: DocumentsSlice = {
  columnOrder: [],
  currentTableView: undefined,
  currentAddToGroupStep: AddToGroupStepType.GROUP,
  selectedDocumentId: undefined,
  selectedGroupId: undefined,
};

export const name = 'documents_v2';

export default createSlice({
  name,
  initialState,
  reducers: {
    setColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
    setCurrentTableView: (state, action) => {
      state.currentTableView = action.payload;
    },
    setCurrentAddToGroupStep: (
      state,
      action: { payload: AddToGroupStepType },
    ) => {
      state.currentAddToGroupStep = action.payload;
    },
    setSelectedDocumentId: (state, action) => {
      state.selectedDocumentId = action.payload;
    },
    setSelectedGroupId: (state, action) => {
      state.selectedGroupId = action.payload;
    },
  },
});
