import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import { getToken } from '~/api';
import { authenticate, reauthenticate, updateSyncPair } from '~/api/evisync';
import { showToast } from '~/components/Shared/EcToast';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import { Button } from '~/eds';
import { ManageSyncPairStageType, QueryParamType } from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { useAsync } from '~/hooks';
import { useRouting } from '~/routing';
import { ERROR } from '~/types/toast.types';
import { FlexLayout, LoadingSpinner } from '~/ui';
import { removeQueryParam } from '~/utils/browser';

import { resetSyncPairAuthCode } from '../../util';

function Authentication({
  // connected,
  activeSyncPair,
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const { location, navigate } = useRouting();

  const { id, state, code, name, provider, tokenId } = activeSyncPair;
  const hasTokenId = !!tokenId;
  const isPairCreated = !!id;
  const isLoadingToken = !!state;

  const authenticateErrorMessage = `Unable to contact ${name}, please try again.`;

  useEffect(() => {
    if (!hasTokenId && state && code) {
      const promise = getToken({ provider, state, code });
      integrationsSetActiveSyncPair(resetSyncPairAuthCode(activeSyncPair));

      promise
        .then((response) => {
          const updatedActiveSyncPair = {
            ...activeSyncPair,
            tokenId: response.id,
          };
          integrationsSetActiveSyncPair(
            resetSyncPairAuthCode(updatedActiveSyncPair),
          );

          if (isPairCreated) {
            executeUpdate();
            handleUpdateNext();
          } else {
            handleCreateNext();
          }

          removeQueryParam(location, navigate, QueryParamType.States);
        })
        .catch(() => {
          showToast(
            ERROR,
            `There is an error authenticating with ${name}, please try again.`,
          );
        });
    }
  }, [activeSyncPair]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    isLoading: isAuthenticating,
    executor: executeAuthenticate,
  } = useAsync(
    authenticate,
    { provider, returnTo: window.location.href },
    {
      errorToastMessage: authenticateErrorMessage,
      successHandler: (response) => (window.location.href = response.url),
    },
  );

  const {
    isLoading: isReAuthenticating,
    executor: executeReAuthenticate,
  } = useAsync(
    reauthenticate,
    { id, returnTo: window.location.href },
    {
      errorToastMessage: authenticateErrorMessage,
      successHandler: (response) => (window.location.href = response.url),
    },
  );

  const { executor: executeUpdate } = useAsync(updateSyncPair, activeSyncPair, {
    errorToastMessage: 'Unable to update sync pair, please try again.',
    successToastMessage: `${name} sync pair has been updated.`,
    successHandler: integrationsAddSyncPair,
  });

  const handleCreateNext = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.Folders);
  };

  const handleUpdateNext = () => {
    integrationsSetManageSyncPairStage(ManageSyncPairStageType.None);
  };

  if (isLoadingToken) {
    return (
      <FlexLayout alignItems="center" flexDirection="column" space={12}>
        <ProviderLogo provider={provider} />
        <LoadingSpinner />
      </FlexLayout>
    );
  }

  const nextStep = (
    <Button
      id={OnboardingIdType.LoginWithProviderButton}
      isLoading={isAuthenticating || isReAuthenticating}
      text={`Login with ${name}`}
      onClick={isPairCreated ? executeReAuthenticate : executeAuthenticate}
    />
  );

  return (
    <FlexLayout
      alignItems="center"
      flex="auto"
      flexDirection="column"
      space={12}
    >
      <ProviderLogo provider={provider} />
      {hasTokenId ? null : nextStep}
    </FlexLayout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
});

export default connect(mapStateToProps, {
  integrationsAddSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(Authentication);
