import React from 'react';
import { connect } from 'react-redux';

import {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
} from '~/actions/integrations';
import ProviderLogo from '~/components/Shared/ProviderLogo';
import {
  Button,
  Card,
  DescriptionText,
  Grid,
  HtmlEntityType,
  Layout,
  Markdown,
  Text,
} from '~/eds';
import { AribaIntegrationType, ManageSyncPairStageType } from '~/enums';
import { FlagType, useFlag } from '~/flags';

import AribaFooter from '../AribaFooter';

function FileConfigSetup({
  // connected,
  activeSyncPair,
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
}) {
  const { provider } = activeSyncPair;
  const usingOneTimeImportStage = useFlag(FlagType.FieldMappingOneTimeImport);
  const defaultStage = !usingOneTimeImportStage;

  const oneDescription = `Upload a .csv file with Ariba document IDs you would like to import.
  &nbsp;
  Ariba Files will be uploaded to Evisort, and field data extracted by Evisort will be viewable only in Evisort.`;

  const multiDescription = `Select your data fields and map to sync your data and documents.
  &nbsp;
  Ariba files will be uploaded to Evisort, and field data extracted by Evisort can be synced back to the Ariba Contract Workspace.`;

  const oneTimeImportDescription = `**Select files for a one-time import**
  - Upload CSV with Ariba documents
  - Select fields to transfer between the two systems
  - Evisort does not monitor for changes in Ariba

  **Best for:** historical contracts where the files or data are not changing.`;

  const continuousSyncDescription = `**Select files for continuous data sync**
  - Use filters to discover new Contract Workspaces as they're created
  - Select fields to transfer between the two systems
  - Evisort will monitor for changes in Ariba

  **Best for:** situations where you want to monitor and process new Contract Workspaces.`;

  const handleBack = () => {
    let stage;

    if (defaultStage) {
      integrationsSetActiveSyncPair(null);
      stage = ManageSyncPairStageType.None;
    }
    if (usingOneTimeImportStage) {
      stage = ManageSyncPairStageType.MultiAPIs;
    }

    integrationsSetManageSyncPairStage(stage);
  };

  return (
    <Layout align="center" direction="column" spacing={8}>
      <Layout align="center" py={2} spacing={6}>
        <ProviderLogo provider="evisort" />
        <Text color="text.quiet">{HtmlEntityType.DoubleDash}</Text>
        <ProviderLogo provider={provider} />
      </Layout>
      <Grid columnSpacing={8} columns={2}>
        <Card status="info" mode="bordered" px={8} py={4}>
          <Layout direction="column" py={2} spacing={6}>
            {usingOneTimeImportStage && (
              <Markdown text={oneTimeImportDescription} />
            )}
            {defaultStage && (
              <>
                <Text variant="body2">One-Time Import</Text>
                <DescriptionText variant="body1" text={oneDescription} />
              </>
            )}
            <Layout>
              <Button
                text="Upload"
                variant="secondary"
                onClick={() => {
                  integrationsSetActiveSyncPair({
                    ...activeSyncPair,
                    syncType: AribaIntegrationType.ONE_TIME,
                  });

                  let stage = ManageSyncPairStageType.MultiAPIs;

                  if (usingOneTimeImportStage) {
                    stage = ManageSyncPairStageType.FolderAriba;
                  }

                  integrationsSetManageSyncPairStage(stage);
                }}
              />
            </Layout>
          </Layout>
        </Card>
        <Card status="info" mode="bordered" px={8} py={4}>
          <Layout direction="column" py={2} spacing={6}>
            {usingOneTimeImportStage && (
              <Markdown text={continuousSyncDescription} />
            )}
            {defaultStage && (
              <>
                <Text variant="body2">Continuous Document and Data Sync</Text>
                <DescriptionText variant="body1" text={multiDescription} />
              </>
            )}
            <Layout>
              <Button
                text="Sync"
                variant="secondary"
                onClick={() => {
                  integrationsSetActiveSyncPair({
                    ...activeSyncPair,
                    syncType: AribaIntegrationType.CONTINUOUS,
                  });

                  let stage;

                  if (defaultStage) {
                    stage = ManageSyncPairStageType.MultiAPIs;
                  }
                  if (usingOneTimeImportStage) {
                    stage = ManageSyncPairStageType.FolderAriba;
                  }

                  integrationsSetManageSyncPairStage(stage);
                }}
              />
            </Layout>
          </Layout>
        </Card>
      </Grid>
      {usingOneTimeImportStage && (
        <AribaFooter
          secondaryAction={{
            text: 'Back',
            onClick: handleBack,
          }}
        />
      )}
      {defaultStage && (
        <AribaFooter
          secondaryAction={{
            text: 'Cancel',
            onClick: handleBack,
          }}
        />
      )}
    </Layout>
  );
}

const mapStateToProps = ({ integrations }) => ({
  activeSyncPair: integrations.activeSyncPair,
  manageSyncPairStage: integrations.manageSyncPairStage,
});

export default connect(mapStateToProps, {
  integrationsSetActiveSyncPair,
  integrationsSetManageSyncPairStage,
})(FileConfigSetup);
