export default `
<svg
  aria-hidden="true"
  aria-label="x"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M16.7656 15.7188C17.0469 16.0312 17.0469 16.5 16.7656 16.7812C16.4531 17.0938 15.9844 17.0938 15.7031 16.7812L12.0156 13.0625L8.29688 16.7812C7.98438 17.0938 7.51562 17.0938 7.23438 16.7812C6.92188 16.5 6.92188 16.0312 7.23438 15.7188L10.9531 12L7.23438 8.28125C6.92188 7.96875 6.92188 7.5 7.23438 7.21875C7.51562 6.90625 7.98438 6.90625 8.26562 7.21875L12.0156 10.9688L15.7344 7.25C16.0156 6.9375 16.4844 6.9375 16.7656 7.25C17.0781 7.53125 17.0781 8 16.7656 8.3125L13.0469 12L16.7656 15.7188Z"
    fill="currentcolor"
  />
</svg>
`;
