import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';

import { isDev } from '~/dev';
import { api, reducer } from '~/redux';

enableMapSet();

export const middleware = (gdm) =>
  gdm({
    serializableCheck: false,
    immutableCheck: false,
  }).concat(api.middleware);

const store = configureStore({
  reducer,
  middleware,
  devTools: isDev,
});

export default store;
