import React from 'react';

import { Button, Menu } from '../../../components';
import { Option } from '../../../types';

interface Props<V> {
  /** Name of the menu */
  name: string;
  /** List of options */
  options: Option<V>[];
  /** Text of the cell */
  text: string;
  /** Callback when option is selected */
  onClick: (option: Option) => void;
  /** Disables the menu trigger */
  disabled?: boolean;
}

export const MenuCell = <V extends unknown>({
  name,
  options,
  disabled,
  text,
  onClick,
}: Props<V>) => {
  return (
    <Menu
      enablePortal
      name={name}
      options={options}
      trigger={
        <Button
          disabled={disabled}
          icon="chevron-down"
          label={text}
          shouldTruncate
          text={text}
          variant="tertiary"
        />
      }
      onSelectOption={onClick}
    />
  );
};
