import PropTypes from 'prop-types';
import React from 'react';
import injectSheet from 'react-jss';

import styles from './LoginPageContainer.styles';

function LoginPageContainer({ classes, children, title }) {
  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <a href="/" title="Evisort login page">
          <img
            src="/evisort.png"
            alt="Evisort"
            title="Evisort"
            className={classes.logoImg}
          />
        </a>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.title} role="heading" aria-level="1">
          {title}
        </div>
        {children}
      </div>
    </div>
  );
}

LoginPageContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(LoginPageContainer);
