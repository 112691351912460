import {
  useBlockLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
} from 'react-table';

export default [
  useBlockLayout, // order matters
  useResizeColumns,
  useSortBy,
  usePagination,
  useRowSelect,
];
