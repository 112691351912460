import { DeletedDocumentResult } from '~/redux/api/methods';
import { formatUserNameAndEmail } from '~/utils/user';

export const displayReason = (reason: string): string => {
  switch (reason) {
    case 'duplicate':
      return 'Duplicate';
    case 'mistake':
      return 'Added by mistake';
    case 'other':
      return 'Other';
    default:
      return '';
  }
};

export const getExportDataFromColumns = (
  data: DeletedDocumentResult[],
  columns: string[],
): Partial<DeletedDocumentResult>[] => {
  return data.map((item) => {
    const result: Partial<DeletedDocumentResult> = {};
    columns.forEach((column) => {
      switch (column) {
        case 'name':
          result.name = `${item.name ?? 'Unknown'}${item.fileType}`;
          break;
        case 'original_folder':
          result.originalFolder = item.originalFolder;
          break;
        case 'deleted_date':
          result.deletedDate = item.deletedDate;
          break;
        case 'reason':
          result.reason = `${displayReason(item.reason)} / ${
            item.extraDetails
          }`;
          break;
        case 'deleted_by':
          result.deletedBy = formatUserNameAndEmail(
            item.firstName,
            item.lastName,
            item.deletedBy,
          );
          break;
        default:
          break;
      }
    });
    return result;
  });
};
