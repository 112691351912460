import { EsignatureType, FileExtensionType } from '~/enums';

export const supportedFileExtensions: Record<
  EsignatureType,
  FileExtensionType[]
> = {
  [EsignatureType.Adobesign]: [
    FileExtensionType.Bmp,
    FileExtensionType.Doc,
    FileExtensionType.Docx,
    FileExtensionType.Gif,
    FileExtensionType.Htm,
    FileExtensionType.Html,
    FileExtensionType.Jpeg,
    FileExtensionType.Jpg,
    FileExtensionType.Pdf,
    FileExtensionType.Png,
    FileExtensionType.Ppt,
    FileExtensionType.Pptx,
    FileExtensionType.Rtf,
    FileExtensionType.Tif,
    FileExtensionType.Tiff,
    FileExtensionType.Txt,
    FileExtensionType.Xls,
    FileExtensionType.Xlsx,
  ],
  [EsignatureType.Docusign]: [
    FileExtensionType.Bmp,
    FileExtensionType.Csv,
    FileExtensionType.Doc,
    FileExtensionType.Docm,
    FileExtensionType.Docx,
    FileExtensionType.Dot,
    FileExtensionType.Dotm,
    FileExtensionType.Dotx,
    FileExtensionType.Gif,
    FileExtensionType.Heic,
    FileExtensionType.Htm,
    FileExtensionType.Html,
    FileExtensionType.Jpeg,
    FileExtensionType.Jpg,
    FileExtensionType.Msg,
    FileExtensionType.Pdf,
    FileExtensionType.Png,
    FileExtensionType.Pot,
    FileExtensionType.Potx,
    FileExtensionType.Pps,
    FileExtensionType.Ppt,
    FileExtensionType.Pptm,
    FileExtensionType.Pptx,
    FileExtensionType.Rtf,
    FileExtensionType.Tif,
    FileExtensionType.Tiff,
    FileExtensionType.Txt,
    FileExtensionType.Wpd,
    FileExtensionType.Xhtml,
    FileExtensionType.Xls,
    FileExtensionType.Xlsm,
    FileExtensionType.Xlsx,
    FileExtensionType.Xps,
  ],
};
