import {
  black1,
  black2,
  bold,
  evisortBlue,
} from '../../../assets/shared-styles/general';

const itemHeaderStyles = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '15px',
  '& svg': {
    marginRight: '8px',
    cursor: 'pointer',
  },
};

const itemNameStyles = {
  marginRight: '20px',
  color: black1,
  cursor: 'pointer',
  '&:hover': {
    color: black2,
  },
};

const styles = {
  ecFolderTreeItem: {
    margin: '12px 0',
    paddingLeft: '16px',
  },
  ecFolderTreeItem2: {
    margin: '12px 0',
    paddingLeft: '16px',
    display: 'flex',
  },
  itemHeader: {
    ...itemHeaderStyles,
  },
  emptyItemHeader: {
    ...itemHeaderStyles,
    marginLeft: '28px',
  },
  itemName: {
    ...itemNameStyles,
  },
  selectedItemName: {
    ...itemNameStyles,
    color: black1,
    fontWeight: bold,
    '&:hover': {
      color: evisortBlue,
    },
  },
  multiSelectItemName: {
    ...itemNameStyles,
    color: evisortBlue,
    fontWeight: bold,
    '&:hover': {
      cursor: 'pointer',
      color: evisortBlue,
    },
  },
  checkboxWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  selectedFolder: {
    color: evisortBlue,
    fontWeight: bold,
    '&:hover': {
      color: evisortBlue,
    },
  },
  folderSelected: {
    color: '#999',
  },
  greyout: {
    color: '#777',
    cursor: 'default',
  },
};

export default styles;
