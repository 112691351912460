import { createReducer } from '@reduxjs/toolkit';

import { BuilderTabType } from '~/enums';
import { isLatestVersion } from '~/utils/workflow';

import * as actions from '../actions';

export const getInitialState = () => ({
  selectedTab: BuilderTabType.Source,
  shouldValidate: false,
  shouldEditSetup: false,
  shouldEditParties: false,
  shouldEnableAllComponents: true,
  initialWorkflow: {},
  fieldSections: {},
});

export default createReducer(getInitialState(), (builder) => {
  builder.addCase(actions.builderReset, getInitialState);
  builder.addCase(actions.builderSetShouldEditSetup, (state, action) => {
    state.shouldEditSetup = action.payload;
  });
  builder.addCase(actions.builderSetShouldEditParties, (state, action) => {
    state.shouldEditParties = action.payload;
  });
  builder.addCase(actions.builderSetShouldValidate, (state) => {
    state.shouldValidate = true;
  });
  builder.addCase(actions.builderSetTab, (state, action) => {
    state.selectedTab = action.payload;
  });
  builder.addCase(actions.builderSetInitialWorkflow, (state, action) => {
    state.initialWorkflow = action.payload;
  });
  builder.addCase(actions.builderSetFieldSections, (state, action) => {
    state.fieldSections = action.payload;
  });
  builder.addCase(
    actions.builderSetShouldEnableAllComponents,
    (state, action) => {
      state.shouldEnableAllComponents =
        isLatestVersion(action.payload) || action.payload.isDraft;
    },
  );
});
