import {
  black1,
  black2,
  bold,
  evisortBlue,
  heavy,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  booleanSyntaxPopup: {
    width: '360px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    overflowY: 'scroll',
    boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.25)',
    position: 'fixed',
    bottom: (props) => (props.relativeContained ? 'auto' : '78px'),
    top: (props) => (props.relativeContained ? 0 : 'auto'),
    right: (props) => (props.relativeContained ? '-460px' : 0),
    zIndex: 3,
  },
  booleanSyntaxHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  booleanSyntaxTitle: {
    display: 'flex',
    alignItems: 'center',
    color: black2,
    fontFamily: 'OatmealPro',
    fontSize: '18px',
    fontWeight: heavy,

    '& svg': {
      marginRight: '8px',
    },
  },
  booleanSyntaxActions: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    '& svg': {
      '&:not(:last-child)': {
        marginRight: '16px',
      },
    },
  },
  booleanSyntaxOptionsList: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px',
  },
  booleanSyntaxOption: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',

    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
  optionLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '77px',
    marginRight: '16px',
  },
  optionLabel: {
    padding: '4px 8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '2px',
    backgroundColor: 'rgba(0, 0, 0, 0.025)',
    color: evisortBlue,
    fontSize: '13px',
    fontWeight: bold,
  },
  optionDescription: {
    flexGrow: '1',
    color: black1,
    fontSize: '13px',
    fontWeight: semibold,
  },
  optionTooltipExample: {
    '&:not(:last-child)': {
      marginBottom: '16px',
    },
  },
  optionTooltipText: {
    marginBottom: '6px',
    fontSize: '12px',

    '& span': {
      fontWeight: bold,
    },
  },
  optionTooltipDescription: {
    fontSize: '12px',
  },
};

export default styles;
