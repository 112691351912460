import React from 'react';
import { connect } from 'react-redux';

import {
  workflowAddReviewPhase,
  workflowRemoveReviewPhase,
  workflowSetReviewCoordinator,
  workflowSetReviewPhaseName,
  workflowSetStageConditionRule,
  workflowSortReviewPhases,
  workflowToggleSkipStage,
} from '~/actions';
import { FormField } from '~/eds';
import { TicketStageType } from '~/enums';
import { getReviewErrors, getReviewPhases } from '~/reducers/workflow';
import { FlexLayout } from '~/ui';
import { coerceName } from '~/utils/workflow';

import SkipStageAccordion from '../../shared/SkipStageAccordion';
import StageItems from '../../shared/StageItems';
import WorkflowUserRoleSingleSelect from '../../shared/WorkflowUserRoleSingleSelect';
import Phase from './Phase';

function Review({
  // connected
  conditionRule,
  coordinator,
  disabled,
  errors,
  isSkipped,
  reviewPhases,
  workflowAddReviewPhase,
  workflowSetReviewCoordinator,
  workflowSortReviewPhases,
  workflowRemoveReviewPhase,
  workflowSetReviewPhaseName,
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
}) {
  const reviewItems = reviewPhases.map((phase, index) => ({
    ...phase,
    title: coerceName(phase.title, 'Review Phase', index),
    content: <Phase key={phase.id} phaseId={phase.id} position={index} />,
  }));

  const handleUpdateStageConditionRule = (updatedConditionRule) => {
    workflowSetStageConditionRule({
      conditionRule: updatedConditionRule,
      stage: TicketStageType.Review,
    });
  };

  const handleToggleSkipStage = (updatedIsSkipped) => {
    workflowToggleSkipStage({
      isSkipped: updatedIsSkipped,
      stage: TicketStageType.Review,
    });
  };

  return (
    <FlexLayout disabled={disabled} flexDirection="column" space={6}>
      <SkipStageAccordion
        conditionRule={conditionRule}
        isSkipped={isSkipped}
        onChange={handleUpdateStageConditionRule}
        onToggle={handleToggleSkipStage}
      />
      <FlexLayout flexDirection="column" mb={8} space={4}>
        <FormField
          description="A review coordinator will be in charge of making sure that all the approvals have been collected."
          id="workflow--review-coordinator"
          input={WorkflowUserRoleSingleSelect}
          label="REVIEW COORDINATOR"
          placeholder="Not specified"
          width="fullWidth"
          value={coordinator}
          onChange={workflowSetReviewCoordinator}
          error={
            errors.unspecifiedCoordinator
              ? 'You need to specify a review coordinator.'
              : undefined
          }
        />
      </FlexLayout>
      <StageItems
        disabled={disabled}
        items={reviewItems}
        invalidPhaseIds={errors.invalidPhaseIds}
        removeModalTitle="Remove Review Phase"
        sortModalTitle="Reorder Review Phases"
        onRemove={workflowRemoveReviewPhase}
        onSort={workflowSortReviewPhases}
        onEditTitle={workflowSetReviewPhaseName}
        newItemAction={{
          id: 'workflow--add-review-phase',
          text: 'Add review phase',
          handler: workflowAddReviewPhase,
        }}
      />
    </FlexLayout>
  );
}

function mapStateToProps({ builder, workflow }) {
  const stage = workflow.stages[TicketStageType.Review];
  return {
    conditionRule: stage.conditionRule,
    coordinator: stage.coordinators[0] || null,
    disabled: !builder.shouldEnableAllComponents,
    reviewPhases: getReviewPhases(workflow),
    errors: getReviewErrors(workflow),
    isSkipped: !stage.isEnabled,
  };
}

export default connect(mapStateToProps, {
  workflowAddReviewPhase,
  workflowSetReviewCoordinator,
  workflowSortReviewPhases,
  workflowRemoveReviewPhase,
  workflowSetReviewPhaseName,
  workflowSetStageConditionRule,
  workflowToggleSkipStage,
})(Review);
