import React, { useEffect } from 'react';

import { SearchInput, useDebouncedState } from '~/eds';
import { Nullable } from '~/types';

type Props = {
  onSearch: (value: Nullable<string>) => void;
};

export const UsersSearchBar = ({ onSearch }: Props) => {
  const [debouncedState, search, setSearch] = useDebouncedState<
    Nullable<string>
  >('');

  useEffect(() => {
    onSearch(debouncedState);
  }, [debouncedState]);

  return (
    <SearchInput
      width="100%"
      name="search"
      value={search}
      onChange={(value) => {
        setSearch(value);
      }}
      placeholder="Search users"
    />
  );
};
