import React from 'react';

import {
  CollapsibleText,
  Layout,
  Text,
  TruncateText,
} from '../../../components';
import { DEFAULT_PLACEHOLDER_LABEL } from '../../../constants';

interface Props {
  /** Required text (must be a simple string) */
  text: string;
  /** Optional description */
  description?: string;
  /** Collapses a text if it exceeds the specified limit/length */
  limit?: number;
  /** Will render text as numeric font style */
  isNumeric?: boolean;
  /** Will truncate the text with a tooltip */
  shouldTruncate?: boolean;
}

export const TextCell = ({
  description,
  isNumeric = false,
  limit,
  shouldTruncate = true,
  text: textParam,
}: Props) => {
  const TextComponent = shouldTruncate ? TruncateText : Text;
  const textStyles = isNumeric ? componentStyles.numeric : null;
  const text = textParam?.length ? textParam : DEFAULT_PLACEHOLDER_LABEL;
  // only the main `text` is supported i.e. `description` is not supported when indicating the main `text` is collapsible
  if (limit) {
    return <CollapsibleText limit={limit} text={text} />;
  }

  return (
    <Layout direction="column" w="100%" minW="0">
      <TextComponent styles={textStyles}>{text}</TextComponent>
      {description && (
        <TextComponent color="text.secondary" styles={textStyles}>
          {description}
        </TextComponent>
      )}
    </Layout>
  );
};

// TODO: consider formalizing in Text component or typography token
const componentStyles = {
  numeric: {
    fontVariantNumeric: 'tabular-nums',
  },
};
