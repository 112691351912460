import { everest } from '~/services';
import { Uuid } from '~/types';

interface EditorKeyResponse {
  key: string;
}

interface EditorKeyRequestParams {
  versionId: Uuid;
  documentId: Uuid;
}

interface DownloadTicketDocumentVersionEmailAttachmentRequestParams {
  versionId: Uuid;
  documentId: Uuid;
}

interface DownloadTicketDocumentVersionEmailAttachmentResponse {
  url: string;
}

export const getTicketDocumentVersionEditorKey = async ({
  documentId,
  versionId,
}: EditorKeyRequestParams): Promise<EditorKeyResponse> => {
  return (await everest.get(
    `documents/${documentId}/versions/${versionId}/editor-key/`,
  )) as EditorKeyResponse;
};

export const downloadTicketDocumentVersionEmailAttachment = ({
  documentId,
  versionId,
}: DownloadTicketDocumentVersionEmailAttachmentRequestParams): Promise<DownloadTicketDocumentVersionEmailAttachmentResponse> => {
  return everest.get(
    `documents/${documentId}/versions/${versionId}/download-email-attachment/`,
  );
};
