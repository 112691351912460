import pluralize from 'pluralize';
import React, { useState } from 'react';

import { BULK_DELETE_LIMIT } from '~/constants/max_lengths';
import { Layout, Modal, Text, TextInput } from '~/eds';
import { DeletedDocumentResult } from '~/redux/api/methods';
import { Nullable } from '~/types';

const DeleteDocumentPermanentlyModal = ({
  selectedDocuments,
  isVisible,
  totalCount = 0,
  onConfirm,
  onCancel,
}: {
  selectedDocuments: DeletedDocumentResult[];
  isVisible: boolean;
  totalCount?: number;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const [confirmDeleteText, setConfirmDeleteText] = useState<Nullable<string>>(
    '',
  );

  const count = totalCount || selectedDocuments.length;
  const multiple = count > 1;
  const name = multiple
    ? `${count} documents`
    : `the document "${selectedDocuments[0]?.name}"`;
  const disabled = confirmDeleteText !== 'DELETE';

  const onConfirmClicked = () => {
    setConfirmDeleteText('');
    onConfirm();
  };

  const onClose = () => {
    setConfirmDeleteText('');
    onCancel();
  };

  return (
    <Modal
      title={`Permanently delete ${count} ${pluralize('document', count)}?`}
      isVisible={isVisible}
      primaryAction={{
        text: `Permanently delete (${count})`,
        onClick: onConfirmClicked,
        variant: 'danger',
        disabled: disabled || count > BULK_DELETE_LIMIT,
      }}
      onHide={onClose}
      onCancel={onClose}
    >
      <Layout direction="column" spacing={6}>
        <Text>
          If you proceed, {name} will be deleted forever. Are you sure you would
          like to permanently delete
          {multiple ? ' them' : ' it'}?
        </Text>
        <Text>
          You won’t be able to revert this action. If you are sure, please type
          "DELETE" in the box below:
        </Text>
        <TextInput
          name="confirmPermanentDeleteInput"
          onChange={setConfirmDeleteText}
          value={confirmDeleteText}
        />
      </Layout>
    </Modal>
  );
};

export default DeleteDocumentPermanentlyModal;
