import { Uuid } from '~/types';

type IncludeData<R, I> = {
  [K in keyof R]: I;
};

type Relationships<R> = {
  [K in keyof R]: { data: { id: Uuid }[] };
};

export const getIncludedDataFromRelations = <
  R extends Relationships<R>,
  I extends { id: Uuid }
>(
  relationshipData: R,
  includedData: I[],
): IncludeData<R, I[]> => {
  return Object.keys(relationshipData).reduce((acc, curr) => {
    for (const item of relationshipData[curr as keyof R].data) {
      const included = includedData.find((data) => data.id === item.id);

      if (acc[curr as keyof R] && included) {
        acc[curr as keyof R].push(included);
      } else if (!acc[curr as keyof R] && included) {
        acc[curr as keyof R] = [included];
      }
    }

    return acc;
  }, {} as IncludeData<R, I[]>);
};
