import { toString } from 'lodash';

import { pilot } from '~/services';
import { PilotId } from '~/types';

import { SearchableFieldId } from './alert';

interface PinnedFiltersResponse {
  pinnedFilters?: {
    value?: Array<SearchableFieldId | PilotId>;
  };
}

interface PinnedFiltersReturnValue {
  data?: Array<SearchableFieldId>;
}

interface PinnedFiltersParams {
  context: string;
  pinnedFilters: Array<SearchableFieldId>;
}

export type ContextType = 'FOLDER' | 'SEARCH' | 'GROUP';

export const getPinnedFilters = async (
  context: ContextType,
): Promise<PinnedFiltersReturnValue> => {
  const response: PinnedFiltersResponse = await pilot.get(
    '/user/preferences/',
    { params: { context } },
  );
  return { data: response?.pinnedFilters?.value?.map(toString) };
};

export const updatePinnedFilters = ({
  context,
  pinnedFilters,
}: PinnedFiltersParams): Promise<{}> => {
  return pilot.put(
    '/user/preferences/',
    { value: pinnedFilters },
    { params: { context, key: 'pinnedFilters' } },
  );
};
