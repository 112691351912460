import { createSlice } from '@reduxjs/toolkit';

import { SliceType } from '~/enums';

import { defaultAdapter } from '../adapters';
import { DashboardsState as State } from '../types';

const name = SliceType.Dashboards;

export const initialState: State = {
  entities: {},
  ids: [],
};

export default createSlice({
  name,
  initialState,
  reducers: {
    setDashboard: (state, action) =>
      defaultAdapter.upsertOne(state, action.payload),
  },
});
