import {
  black1,
  black2,
  black3,
  bold,
  evisortBlue,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  fieldTable: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    color: black1,
    textAlign: 'left',
    '& td': {
      padding: '12px 0',
      color: black2,
      fontSize: '15px',
      wordBreak: 'break-all',
    },
    '& tr': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
    },
  },
  fieldName: {
    display: 'flex',
    lineHeight: '22px',
    '& svg': {
      margin: '3px 6px 0',
    },
  },
  fieldTypeRule: {
    display: 'flex',
    lineHeight: '22px',
    '& svg': {
      margin: '3px 6px 0',
    },
  },
  ruleTooltip: {
    position: 'relative',
    top: '4px',
    borderRadius: '2px',
    color: '#fff',
    fontSize: '11px',
    fontWeight: semibold,
    wordBreak: 'break-word',
    cursor: 'pointer',
  },
  dragHandleContainer: {
    paddingLeft: '20px',
    cursor: 'row-resize',
  },
  versionItemRowContainer: {
    display: 'block',
    borderTop: '1px solid #eee',
  },
  fieldRow: {
    padding: '15px 0',
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid #eee',
    color: black3,
    fontSize: '14px',
    lineHeight: '23px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  fieldVersionContainer: {
    paddingRight: '28px',
    width: '5%',
    display: 'inline',
    textAlign: 'right',
  },
  fieldFilenameContainer: {
    paddingRight: '20px',
    width: '23%',
    display: 'flex',
    wordBreak: 'break-word',
  },
  fieldLastModContainer: {
    paddingRight: '20px',
    width: '26%',
    display: 'flex',
    wordBreak: 'break-word',
  },
  fieldDescripContainer: {
    paddingRight: '20px',
    width: '29%',
    display: 'flex',
    wordBreak: 'break-word',
  },
  fieldViewContainer: {
    width: '8%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    color: evisortBlue,
    fontWeight: bold,
    cursor: 'pointer',
  },
  expandIcon: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  detailRowContainer: {
    padding: '20px',
    backgroundColor: '#f2f2f2',
    color: black3,
  },
  detailInfoHeader: {
    marginBottom: '20px',
    display: 'flex',
    color: black2,
    fontSize: '15px',
    fontWeight: semibold,
    '& svg': {
      marginRight: '5px',
    },
  },
  detailRow: {
    display: 'flex',
  },
  detailItem: {
    marginBottom: '20px',
    display: 'inline',
    width: '19%',
  },
  detailItemTitle: {
    marginBottom: '10px',
    display: 'block',
    color: black1,
    fontSize: '12px',
    fontWeight: semibold,
  },
  detailItemValue: {
    display: 'block',
    fontSize: '13px',
  },
  attachFileContainer: {
    position: 'relative',
    overflow: 'hidden',
    '& input[type=file]': {
      position: 'absolute',
      width: '140px',
      height: '45px',
      top: 0,
      left: 0,
      //opacity: 0,
      cursor: 'pointer',
    },
  },
};

export default styles;
