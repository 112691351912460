import React from 'react';

import { typedMemo } from '../../utils';
import { SortableGrid } from '../SortableGrid';
import type { SortableGridItem } from '../SortableGrid';

type SortableListItem<T> = Omit<SortableGridItem<T>, 'width'>;
interface Props<T> {
  items: SortableListItem<T>[];
  rows?: number;
  itemConfig?: {
    enableBorder?: boolean;
    padding: 's' | 'm' | 'l';
  };
  onChange: (updatedItems: T[]) => void;
  onRemoveItem?: (removedItem: T, removedItemIndex: number) => void;
  renderTitle: (item: T) => string;
  renderContents?: (item: T) => React.ReactNode;
  isRemovable?: boolean;
  rowSpacing?: string;
}

export const SortableList = typedMemo(
  <T extends unknown>({
    itemConfig,
    isRemovable,
    items,
    rowSpacing,
    onChange,
    onRemoveItem,
    renderContents,
    renderTitle,
  }: Props<T>) => {
    const handleRemoveItem = isRemovable
      ? (_item: T, itemIndex: number) => {
          const itemsContent: T[] = items.map((item) => item.item);
          if (onRemoveItem) {
            onRemoveItem(itemsContent[itemIndex], itemIndex);
          } else {
            onChange([
              ...itemsContent.slice(0, itemIndex),
              ...itemsContent.slice(itemIndex + 1),
            ]);
          }
        }
      : undefined;

    const formattedRowSpacing = rowSpacing
      ? rowSpacing === 's'
        ? 2
        : 4
      : undefined;

    return (
      <SortableGrid
        columns={1}
        items={items}
        renderContents={renderContents}
        renderTitle={renderTitle}
        onRemoveItem={handleRemoveItem}
        onSortEnd={onChange}
        itemSettings={{
          padding: itemConfig?.padding,
          enableBorder: itemConfig?.enableBorder,
        }}
        rowSpacing={formattedRowSpacing}
      />
    );
  },
);
