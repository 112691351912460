import { UserRoleType } from '~/enums';
import { Permission } from '~/types';
import { testIsSuperAdmin } from '~/utils/user';

import { or, testHasRole } from './utils';

export const checkIsAdmin: Permission = or([
  testIsSuperAdmin,
  testHasRole(UserRoleType.Admin),
]);
