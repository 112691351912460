import React, { memo, useEffect, useRef } from 'react';

import { useToggle } from '../../../../hooks';
import { SharedPanelProps } from '../../../../types';
import { scrollElementIntoView } from '../../../../utils';
import { Box } from '../../../Box';
import { Panel } from '../../Panel';
import { Steps } from './Steps';
import * as types from './types';

interface Props extends SharedPanelProps {
  /** Indicate which step is active */
  activeStepId: string;
  /** Declarative steps */
  steps: types.Step[];
  /** When step is selected */
  onSelectStep: (updatedStepId: string) => void;
  /** Indicate which instruction is active */
  activeInstructionId?: string;
  /** Indicate which is the last completed step */
  lastCompletedStepId?: string;
}

export const StepsPanel = memo(
  ({
    activeStepId,
    activeInstructionId,
    lastCompletedStepId,
    steps,
    onSelectStep,
    ...rest
  }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [isHidden, _toggleIsHidden, hide, show] = useToggle(false);

    const handleSelectStep = (updatedStepId: string) => {
      onSelectStep(updatedStepId);
      show();
    };

    // this effects finds and selects a new active step based on the provided `activeInstructionId`.
    useEffect(() => {
      if (activeInstructionId) {
        const currentActiveStepId = steps.find((step) =>
          step.instructions
            .map((instruction) => instruction.id)
            .includes(activeInstructionId),
        )?.id;

        if (currentActiveStepId) {
          if (activeStepId !== currentActiveStepId) {
            handleSelectStep(currentActiveStepId);
          }
        }
      }
    }, [activeInstructionId]);

    // this effects handles scrolling an element into view.  We always scroll the step element, and instructions element in this order.
    useEffect(() => {
      const scrollOptions: ScrollIntoViewOptions = { behavior: 'smooth' };

      scrollElementIntoView(activeStepId, scrollOptions, containerRef.current);

      if (activeInstructionId) {
        scrollElementIntoView(
          activeInstructionId,
          scrollOptions,
          containerRef.current,
        );
      }
    }, [activeInstructionId, activeStepId]);

    const hidden = {
      content: (
        <Box p={4}>
          <Steps
            activeStepId={activeStepId}
            lastCompletedStepId={lastCompletedStepId}
            mode="summary"
            steps={steps}
            onSelectStep={handleSelectStep}
          />
        </Box>
      ),
      placement: 'right' as const,
      isHidden,
      onHide: hide,
      onShow: show,
    };

    return (
      <Panel {...rest} hidden={hidden} width="m">
        <div ref={containerRef} style={componentStyles.container}>
          <Steps
            activeStepId={activeStepId}
            activeInstructionId={activeInstructionId}
            lastCompletedStepId={lastCompletedStepId}
            steps={steps}
            onSelectStep={handleSelectStep}
          />
        </div>
      </Panel>
    );
  },
);

const componentStyles = {
  container: {
    height: '100%',
    overflowY: 'auto' as const,
  },
};
