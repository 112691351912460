import { camelizeKeys } from 'humps';

import AuthenticationStore from '~/auth';
import { showToast } from '~/components/Shared/EcToast';
import { authExt, pilot } from '~/services/index';
import { ERROR } from '~/types/toast.types';

import { getClientFromUrl } from '../routing/utils';

export function redirectToDomainLogin() {
  window.location.href = '/';
}

export function redirectToLogin(client) {
  window.location.href = `/${client}/login`;
}

export function redirectToLoginEmbed() {
  const client = getClientFromUrl();
  window.location.href = `/${client}/login/embed`;
}

export function redirectToWorkflowSubmissionHome() {
  window.location.href = '/workflow/intake-forms/';
}

export function redirectToFolder(subdomain, folderId) {
  window.location.href = `${window.location.origin}/${subdomain}/documents/${folderId}`;
}

export function redirectToPage(subdomain, path) {
  window.location.href = `${window.location.origin}/${subdomain}/${path}`;
}

export async function redirectToZendesk(zendeskRedirectUrl) {
  try {
    const response = await authExt.get(
      `/zendesk/sso?return_to=${zendeskRedirectUrl}`,
    );
    if (response.error) throw response.error;

    // Feature flag for Zendesk SSO checked in aegis as FE is not able to check it when initialing the App.
    // Aegis will return deprecating GET method if feature flag is off, else return the POST method.
    if (response.method === 'post') {
      AuthenticationStore().removeZendeskFlag();
      // Required by Zendesk and will take effective on Starting May 1, 2024
      // https://support.zendesk.com/hc/en-us/articles/4408845838874

      // The payload must be base64-encoded and submitted via a form submission from a client.
      // Submitting the payload with a client-side AJAX, fetch, or axios request won't work
      // because the request will be blocked by the client's same-origin policy.

      const form = document.createElement('form');
      form.method = response.method;
      form.action = response.zendesk_url;

      const jwt = document.createElement('input');
      jwt.type = 'hidden';
      jwt.name = 'jwt';
      jwt.value = response.jwt_string;
      form.appendChild(jwt);

      const returnTo = document.createElement('input');
      returnTo.type = 'text';
      returnTo.name = 'return_to';
      returnTo.value = response.return_to;
      form.appendChild(returnTo);

      // Bind the form to the body for submitting
      document.body.appendChild(form);
      form.submit();
    } else {
      // Fall back to default GET method if the feature flag is off
      AuthenticationStore().removeZendeskFlag();
      window.location.href = response.redirect_url;
    }
  } catch (error) {
    showToast(ERROR, 'Error on fetching redirect URL');
  }
}

export async function redirectToOAuth(redirectUri, clientId, state) {
  try {
    const response = await authExt.get(
      `/oauth/redirect?redirect_uri=${redirectUri}&client_id=${clientId}&state=${state}`,
    );

    if (response.error) throw response.error;

    AuthenticationStore().removeOAuthFlag();
    window.location.href = response.redirect_url;
  } catch (error) {
    showToast(ERROR, 'Error on fetching redirect URL');
  }
}

export async function redirectToProviderAuthPage(state, code) {
  const resp = await pilot.get(`/sync-pair/state/${state}/`);

  try {
    const { returnTo, provider, syncPairId } = camelizeKeys(resp);

    const params = btoa(JSON.stringify({ provider, state, code, syncPairId }));

    const delimiter = returnTo.match(/\?./) ? '&' : '?';
    window.location.href = `${returnTo}${delimiter}states=${params}`;
  } catch (error) {
    showToast(ERROR, 'Error on fetching provider auth state data');
  }
}

export function getAbsoluteRedirectUrl(windowOrigin, clientName, redirectPath) {
  return `${windowOrigin}/${clientName}${redirectPath?.pathname}${redirectPath?.search}`;
}
