import React from 'react';

import { Icon, Layout, Text } from '~/eds';

export const NewConversation = () => {
  return (
    <Layout w="100%" h="100%" align="center" justify="center">
      <Layout direction="column" spacing={2} w="100%" maxW="680px" px={6}>
        <Layout align="center">
          <Icon name="evisort-ai" icon="evisort-ai-animated" size="l" />
          <Text variant="subtitle" fontWeight="400" ml={2}>
            Ask AI
          </Text>
        </Layout>
        <Text variant="banner" fontWeight="400">
          Start a new conversation
        </Text>
      </Layout>
    </Layout>
  );
};
