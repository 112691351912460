import { atlas } from '~/services';

import { QuestionResponsePayload } from './types';
import { mapQuestionResponse } from './utils';

type CreateQuestionParams = {
  text: string;
  scope: 'document_handler' | 'document_version' | 'all_documents';
};

export const createQuestion = async (question: CreateQuestionParams) => {
  const newQuestion = {
    data: {
      type: 'questions',
      attributes: {
        ...question,
      },
    },
  };
  const response: QuestionResponsePayload = await atlas.post(
    'questions',
    newQuestion,
  );
  return mapQuestionResponse(response.data);
};
