import React, { ReactNode } from 'react';
import { AutoSizer } from 'react-virtualized';

interface Config {
  defaultWidth: number;
}

type Props = {
  renderChart: ({ width }: { width: number }) => ReactNode;
  config?: Partial<Config>;
};

export const DEFAULT_CHART_WIDTH = 300;

export const ChartContainer = ({ renderChart, config = {} }: Props) => {
  const { defaultWidth = DEFAULT_CHART_WIDTH } = config;
  return (
    <AutoSizer disableHeight>
      {({ width }) => renderChart({ width: width || defaultWidth })}
    </AutoSizer>
  );
};
