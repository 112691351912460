import React, { memo, useEffect } from 'react';

import { ContentContainer, types } from '~/eds';
import { FileMimeType } from '~/enums';
import { api } from '~/redux';
import { Nullable, PilotId } from '~/types';

import { loadingMessage } from './constants';
import { PdfDocumentViewer, PdfHighlighterProvider } from './PdfDocumentViewer';
import { BaseDocumentViewerProps, ResolveDocumentCallback } from './types';
import { UnsupportedDocumentViewer } from './UnknownDocumentViewer';
import { WordDocumentViewer } from './WordDocumentViewer';

export type Props = Omit<BaseDocumentViewerProps, 'file'> & {
  /** Renders the document given its ID */
  documentId?: Nullable<PilotId>;
  /** Renders the document given its version ID (will resolve to a `documentId` internally) */
  documentVersionId?: Nullable<PilotId>;
  /** When specified, will use the provided file and skip resolving the document file */
  file?: File;
  /** Configurable options and behaviors */
  options?: {
    /* When true and provided a documentVersionId, will automatically fetch the latest version of the document (handler) */
    autoLatestVersion?: boolean;
  };
  /** When specifying documentId or documentVersionId as `null`, be sure to attach an appropriate `placeholderContent` */
  placeholderContent?: types.BasePlaceholderContentProps;
  /** Callback to capture the resolved document */
  onResolveDocument?: ResolveDocumentCallback;
};

export const DocumentViewer = memo((props: Props) => {
  const {
    documentId,
    documentVersionId,
    options,
    file: overrideFile,
    placeholderContent: providedPlaceholderContent,
    onResolveDocument,
  } = props;

  const {
    data: resolvedDocument,
    isFetching: isLoading,
    error,
  } = api.endpoints.resolveDocument.useQuery(
    {
      documentId,
      documentVersionId,
      options,
    },
    {
      skip: Boolean(overrideFile),
    },
  );

  useEffect(() => {
    if (onResolveDocument && resolvedDocument) {
      onResolveDocument(resolvedDocument);
    }
  }, [resolvedDocument]);

  const file = resolvedDocument?.file ?? overrideFile;
  const fileType = file?.type;

  const Viewer = fileType ? viewers[fileType] : UnsupportedDocumentViewer;

  const loadingContent = {
    isLoading,
    message: loadingMessage,
  };

  const placeholderContent = error ? providedPlaceholderContent : undefined;

  return (
    <ContentContainer
      loadingContent={loadingContent}
      placeholderContent={placeholderContent}
    >
      <PdfHighlighterProvider>
        {file ? <Viewer {...props} file={file} /> : null}
      </PdfHighlighterProvider>
    </ContentContainer>
  );
});

const viewers = {
  [FileMimeType.Pdf as string]: PdfDocumentViewer,
  [FileMimeType.Html as string]: WordDocumentViewer, // Once using DocumentEditor (EJ2) as the viewer, update the map to reference Word mimetypes
};
