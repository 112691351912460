import { OptimizeResults } from '~/features/x-ray';
import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import { OptimizeRun } from '../../types/sylvanus/model';
import { mapOptimizeResults } from './transformers';

interface Request {
  modelId: Uuid;
  version: number;
}

type Response = OptimizeRun;

export const optimizePromptModel = async ({
  modelId,
  version,
}: Request): Promise<OptimizeResults> => {
  const response: Response = await sylvanus.post(
    `/model/${modelId}/version/${version}/optimize`,
  );
  return mapOptimizeResults(response);
};
