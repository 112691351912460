import { CommentDraft, types, useDebouncedState } from '~/eds';
import { MentionEntityType } from '~/types';

import { useCommentMentions } from '../AppLayout/SidebarContent/Comments/useCommentMentions';

type Props = Omit<
  React.ComponentProps<typeof CommentDraft>,
  'mentions' | 'isMentionsLoading' | 'onMentionsSearch' | 'resolvedMentions'
>;

export const CommentDraftWithMentions = ({
  entity,
  onMentionSearch,
  onMention,
  ...rest
}: Props) => {
  const [debouncedSearch, _search, setSearch] = useDebouncedState('');

  const {
    searchedMentions,
    mentions,
    isFetching: isMentionsFetching,
    addMentionedEntity,
  } = useCommentMentions({
    entityPermission: entity as any,
    searchText: debouncedSearch,
    userIds: [],
  });

  const handleMentionSearch = (searchText: string) => {
    setSearch(searchText);
    onMentionSearch?.(searchText);
  };

  const handleOnMention = (
    mention: types.MentionedEntity<MentionEntityType>,
  ) => {
    onMention?.(mention);
    addMentionedEntity(mention);
  };

  return (
    <CommentDraft
      {...rest}
      entity={entity}
      isMentionsLoading={isMentionsFetching}
      onMentionSearch={handleMentionSearch}
      mentions={searchedMentions}
      resolvedMentions={mentions}
      onMention={handleOnMention}
    />
  );
};
