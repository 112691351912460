import { codeCash } from '~/services';

import {
  CreateCreditBalancesApiResponse,
  CreditAmount,
  EditCreditBalanceApiArg,
} from '../../types/codecash/external';

export const editOneTimeCreditBalance = async ({
  balanceId,
  clientId,
  body,
}: EditCreditBalanceApiArg): Promise<CreditAmount> => {
  const response: CreateCreditBalancesApiResponse = await codeCash.put(
    `clients/${clientId}/credits/balances/${balanceId}`,
    { data: body.data },
  );

  return response.meta.available;
};
