import React from 'react';
import { components, MenuProps } from 'react-select';

import { Option } from '../../../types';
import { Box } from '../../Box';
import { Footer } from '../../Footer';
import { Icon } from '../../Icon';
import { Layout } from '../../Layout';
import { MenuOption } from '../../Menu';
import { Text } from '../../Text';
import { getSelectAllLabel } from '../utils';
import { SELECT_ALL_OPTION } from './Option';

export const Menu = (props: MenuProps<Option, boolean>) => {
  const { children, selectProps } = props;
  const {
    asyncValue,
    enableSelectAll,
    onCreateOption,
    footerAction,
    menuHeader,
    isAllSelected: nonAsyncIsAllSelected,
    isIndeterminate,
    isEmbedded,
    isMulti,
    inputValue,
    isLoading,
    selectAllCount,
    options,
    onChange,
    onMenuClose,
  } = selectProps;

  const enableCreateOption = Boolean(onCreateOption);
  const isAllSelected = asyncValue?.isAllSelected ?? nonAsyncIsAllSelected;

  const handleSelectAll = () => {
    if (isAllSelected) {
      onChange?.([], {
        action: 'deselect-option',
        option: SELECT_ALL_OPTION,
      });
    } else {
      onChange?.(options as Option[], {
        action: 'select-option',
        option: SELECT_ALL_OPTION,
      });
    }
  };

  return (
    <components.Menu {...props}>
      <Box
        role="menu"
        styles={[
          componentStyles.base,
          isEmbedded ? null : componentStyles.default,
        ]}
      >
        {menuHeader && (
          <Box borderBottom="border" py={2}>
            {menuHeader}
          </Box>
        )}
        {isMulti && enableSelectAll && Boolean(options?.length) && (
          <MenuOption
            enableIndicator
            isIndeterminate={isIndeterminate}
            isMulti
            isSelected={isAllSelected}
            option={{
              label: getSelectAllLabel(selectAllCount, inputValue),
              value: SELECT_ALL_OPTION.value,
            }}
            onClick={handleSelectAll}
          />
        )}
        {isLoading ? (
          <Box px={4} py={2}>
            <Text preset="description">Loading...</Text>
          </Box>
        ) : (
          children
        )}
        {(footerAction || enableCreateOption) && (
          <Footer
            isEmbedded={!enableCreateOption}
            actions={[
              {
                ...footerAction,
                onClick: () => {
                  footerAction.onClick();
                  onMenuClose?.(); // always close the menu
                },
              },
            ]}
            justify="flex-start"
            size="s"
          >
            {enableCreateOption ? (
              <Layout align="center" spacing={1}>
                <Icon color="status.info" icon="help" />
                <Text preset="help">Type to search or create new option</Text>
              </Layout>
            ) : null}
          </Footer>
        )}
      </Box>
    </components.Menu>
  );
};

export const componentStyles = {
  base: {
    marginTop: 1,
  },
  default: {
    backgroundColor: 'background',
    zIndex: 'forward',
  },
};
