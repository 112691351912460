export default `
<svg
  aria-hidden="true"
  aria-label="checkbox-empty"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M17 5C18.0938 5 19 5.90625 19 7V17C19 18.125 18.0938 19 17 19H7C5.875 19 5 18.125 5 17V7C5 5.90625 5.875 5 7 5H17ZM17 6.5H7C6.71875 6.5 6.5 6.75 6.5 7V17C6.5 17.2812 6.71875 17.5 7 17.5H17C17.25 17.5 17.5 17.2812 17.5 17V7C17.5 6.75 17.25 6.5 17 6.5Z"
    fill="currentcolor"
  />
</svg>
`;
