import React from 'react';

import {
  BaseStatusTextProps,
  IconType,
  Input,
  InputValue,
  Option,
  StatusType,
  TextVariant,
} from '../../types';
import { typedMemo } from '../../utils';
import { Card } from '../Card';
import { Icon } from '../Icon';
import { Label } from '../Label';
import { Layout } from '../Layout';
import { StatusText } from '../StatusText';
import { Text } from '../Text';

/** Additional UI configuration for a card option */
type CardOption<V> = Option<V> & {
  /** Configures the card option's banner message */
  banner?: {
    label: string;
    status: StatusType;
    text: string;
  };
  /** Additional content to include with the card option */
  content?: React.ReactNode;
  /** Optional label text variant */
  labelVariant?: TextVariant;
  /** Configures the card option's status */
  status?: StatusType;
  /** Attach optional status text */
  statusText?: BaseStatusTextProps;
  /** Optional card width */
  width?: number | string;
  /** Optional direction for card content */
  contentDirection?: 'row' | 'column';
};

export interface Props<V, M extends boolean> extends Input<V, M> {
  /** Determines if single (radio) vs multi (checkbox) mode */
  isMulti: M;
  /** Select options */
  options: CardOption<V>[];
  /** Direction of card options */
  direction?: 'row' | 'column';
  /** Spacing of card options */
  spacing?: 's' | 'xl';
}

export const CardSelect = typedMemo(
  <V extends unknown, M extends boolean>({
    direction = 'column',
    disabled,
    isMulti,
    name,
    options,
    spacing = 's',
    value,
    onChange,
  }: Props<V, M>) => {
    type MultiValue = InputValue<V, true>;
    type SingleValue = InputValue<V, false>;

    const handleChange = (updatedValue: V) => {
      if (isMulti) {
        let updatedValues = (value as MultiValue) ?? [];
        if (updatedValues.includes(updatedValue)) {
          updatedValues = updatedValues.filter((v) => v !== updatedValue);
        } else {
          updatedValues = [...updatedValues, updatedValue];
        }
        onChange(updatedValues as InputValue<V, M>);
      } else {
        onChange(updatedValue as InputValue<V, M>);
      }
    };

    return (
      <Layout
        aria-label={name}
        direction={direction}
        disabled={disabled}
        preset={spacing}
        role="listbox"
      >
        {options.map(
          ({
            ariaLabel,
            banner,
            content,
            description,
            disabled: optionDisabled,
            label,
            labelVariant = 'body-bold',
            status,
            statusText,
            value: optionValue,
            width,
            contentDirection = 'column',
          }) => {
            const checked = isMulti
              ? Boolean((value as MultiValue)?.includes(optionValue))
              : (value as SingleValue) === optionValue;
            return (
              <Card
                key={label}
                banner={banner}
                disabled={disabled || optionDisabled}
                domProps={{
                  'aria-selected': checked,
                }}
                flex="auto"
                mode="bordered"
                name={ariaLabel}
                role="option"
                size="s"
                status={status ?? (checked ? 'active' : undefined)}
                onClick={() => handleChange(optionValue)}
                width={width}
              >
                <Layout
                  direction="column"
                  py={direction === 'row' ? 2 : undefined}
                >
                  <Layout direction={contentDirection}>
                    <Layout direction="column" flex="auto" spacing={1}>
                      <Layout align="center" spacing={1}>
                        <Icon icon={getSelectIcon(isMulti, checked)} />
                        <Layout
                          align="center"
                          justify="space-between"
                          spacing={2}
                          w="100%"
                        >
                          <Label variant={labelVariant}>{label}</Label>
                          {statusText && <StatusText {...statusText} />}
                        </Layout>
                      </Layout>
                      <Text preset="description" textAlign="left" pl={6}>
                        {description}
                      </Text>
                    </Layout>
                    <Layout flex="none">{content}</Layout>
                  </Layout>
                </Layout>
              </Card>
            );
          },
        )}
      </Layout>
    );
  },
);

const getSelectIcon = (isMulti: boolean, checked: boolean): IconType => {
  if (isMulti) {
    return checked ? 'checkbox-checked' : 'checkbox-empty';
  }
  return checked ? 'radio-checked' : 'radio-empty';
};
