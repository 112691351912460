import React from 'react';

import { DocumentEditorProvider } from '~/components/Shared/DocumentEditor';
import { PdfHighlighterProvider } from '~/components/Shared/EcPdfViewerHighlighter';
import { TicketReviewerPage } from '~/components/Workflow/TicketReviewerPage';

export default function Page(props) {
  return (
    <PdfHighlighterProvider>
      <DocumentEditorProvider>
        <TicketReviewerPage {...props} />
      </DocumentEditorProvider>
    </PdfHighlighterProvider>
  );
}
