import { noop } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ActionType } from 'react-table';

import { Table } from '~/eds';
import { useTableSettings } from '~/hooks';
import { ColumnSortOrder, ColumnWidths } from '~/types';
import { DEFAULT_TABLE_SETTINGS } from '~/utils/table';

interface SetColumnOrder {
  (updatedColumnOrder: string[], actionMeta?: any): unknown;
}
interface TableUpdate {
  (state: TableState, action?: ActionType): unknown;
}
interface SetPageSize {
  (pageSize: number): unknown;
}

type OnPaginate = (page: { pageIndex: number }) => unknown;

type TableState = any;
type Column = { key: string; rawWidth?: number };

interface Props {
  columns?: Column[];
  context: string;
  onPageSizeChange?: SetPageSize;
  onSetColumnOrder?: SetColumnOrder;
  onUpdate?: TableUpdate;
  state?: TableState;
  onPaginate?: OnPaginate;
  [x: string]: any;
}

export const PersistedTable = ({
  columns = [],
  context,
  onPageSizeChange = noop,
  onSetColumnOrder = noop,
  onUpdate = noop,
  state = {},
  ...rest
}: Props) => {
  const defaultColumnOrder = columns.map((column) => column.key);

  const [columnOrder, setColumnOrder] = useState(
    state.columnOrder ?? defaultColumnOrder,
  );
  const [columnWidths, setColumnWidths] = useState<ColumnWidths>(
    state.columnWidths ?? DEFAULT_TABLE_SETTINGS.columnWidths,
  );
  const [pageSize, setPageSize] = useState<number>(
    state.pageSize ?? DEFAULT_TABLE_SETTINGS.pageSize,
  );
  const [sortBy, setSortBy] = useState<ColumnSortOrder>(state.sortBy?.[0]);

  const localTableSettings = {
    columnOrder,
    columnWidths,
    pageSize,
    sortBy,
  };

  const {
    tableSettings: _tableSettings,
    updateTableSettings,
  } = useTableSettings(context);
  const tableSettings = _tableSettings ?? localTableSettings;

  useEffect(() => {
    if (tableSettings) {
      setColumnOrder(tableSettings.columnOrder);
      setColumnWidths(tableSettings.columnWidths);
      setPageSize(tableSettings.pageSize);
      setSortBy(tableSettings.sortBy);
    }
  }, [tableSettings]);

  useEffect(() => {
    if (state?.columnOrder) {
      setColumnOrder(state.columnOrder);
    }
  }, [state?.columnOrder]);

  const handleColumnOrderChange: SetColumnOrder = (
    updatedColumnOrder,
    actionMeta,
  ) => {
    setColumnOrder(updatedColumnOrder);

    const newSettings = {
      ...tableSettings,
      columnOrder: updatedColumnOrder,
    };

    updateTableSettings(newSettings);

    onSetColumnOrder(updatedColumnOrder, actionMeta);
  };

  const handlePageSizeChange: SetPageSize = (pageSize: number) => {
    setPageSize(pageSize);

    const newSettings = {
      ...tableSettings,
      pageSize,
    };

    updateTableSettings(newSettings);

    onPageSizeChange(pageSize);
  };

  const handleUpdate: TableUpdate = (state, action) => {
    if (action?.type === 'columnDoneResizing') {
      const columnWidths = {
        ...tableSettings.columnWidths,
        ...state.columnResizing.columnWidths,
      };

      setColumnWidths(columnWidths);

      const newSettings = {
        ...tableSettings,
        columnWidths,
      };

      updateTableSettings(newSettings);
    }

    if (action?.type === 'toggleSortBy') {
      const sortBy = state.sortBy[0] as ColumnSortOrder;

      setSortBy(sortBy);

      const newSettings = {
        ...tableSettings,
        sortBy,
      };

      updateTableSettings(newSettings);
    }

    onUpdate(state, action);
  };

  const columnsWithWidths = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      rawWidth: columnWidths[column.key],
    }));
  }, [columns, columnWidths]);

  return (
    <Table
      {...rest}
      columns={columnsWithWidths}
      onPageSizeChange={handlePageSizeChange}
      onSetColumnOrder={handleColumnOrderChange}
      onUpdate={handleUpdate}
      state={{
        ...state,
        columnOrder,
        pageSize,
        sortBy: sortBy ? [sortBy] : [],
      }}
    />
  );
};
