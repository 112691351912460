import PT from 'prop-types';
import React from 'react';

import { withUsers } from '~/hocs';
import { UserAvatar as UIUserAvatar } from '~/ui';

function UserAvatar({
  shouldRenderName = false,
  size = 'xl',
  userId,
  // connected
  users,
}) {
  const user = users[userId];
  return (
    <UIUserAvatar shouldRenderName={shouldRenderName} size={size} user={user} />
  );
}

UserAvatar.propTypes = {
  shouldRenderName: PT.bool,
  size: PT.oneOf(['xs', 's', 'm', 'l', 'xl']),
  userId: PT.number.isRequired,
};

export default withUsers(UserAvatar);
