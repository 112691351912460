import { alphas } from '../../../tokens/colors/system/alphas';

export const interactionsInverse = {
  ':hover': {
    boxShadow: `inset 0 0 0 1000px ${alphas['lighten-20']}`,
  },
  ':focus': {
    boxShadow: `inset 0 0 0 1000px ${alphas['lighten-20']}`,
  },
  ':active': {
    boxShadow: `inset 0 0 0 1000px ${alphas['lighten-30']}`,
  },
  ':hover:focus': {
    boxShadow: `inset 0 0 0 1000px ${alphas['lighten-30']}`,
  },
  ':hover:focus:active': {
    boxShadow: `inset 0 0 0 1000px ${alphas['lighten-30']}`,
  },
};
