import {
  black1,
  black2,
  black4,
  semibold,
} from '../../../assets/shared-styles/general';

const styles = {
  viewerAlgoWarningWrapper: {
    padding: '10px',
    backgroundColor: 'rgba(255, 196, 73, .5)',
    fontSize: '12px',
  },
  viewerWarningWrapper: {
    margin: '64px 0',
    textAlign: 'center',
  },
  viewerWarningHeader: {
    maxWidth: '360px',
    margin: '16px auto 8px auto',
    color: black2,
    fontSize: '17px',
    fontWeight: semibold,
  },
  viewerWarningBody: {
    maxWidth: '560px',
    margin: '0 auto',
    color: black4,
    fontSize: '15px',
  },
  viewerTitleRightControls: {
    display: 'flex',
  },
  documentViewerInModalHeader: {
    minHeight: '66px',
  },
  viewTypeSwitch: {
    display: 'flex',
    border: 'solid 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    color: black2,
    fontSize: '12px',
    fontWeight: semibold,
    cursor: 'pointer',
    '& button': {
      padding: '12px 16px',
    },
    '& button:first-child': {
      borderRight: 'solid 1px rgba(0, 0, 0, 0.25)',
    },
  },
  viewSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  documentViewerWrapper: {
    '&:focus': {
      outline: 'none',
    },
  },
  documentContentWrapper: {
    wordBreak: 'break-word',
    padding: '16px', // These two lines are one way to fix unconvenience of having to start selecting right on top of text.
    margin: '-16px',

    '& ::selection': {
      backgroundColor: 'rgba(255, 196, 73, 0.25)',
      mixBlendMode: 'multiply',
    },
    '& img': {
      maxWidth: '100%',
    },
    '& table': {
      wordBreak: 'break-word',
    },
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '64px 0',
    textAlign: 'center',
  },
  warningBanner: {
    display: 'flex',
    alignItems: 'center',
    margin: '-16px',
    padding: '16px',
    backgroundColor: 'rgba(255, 196, 73, 0.25)',
    color: black1,
    fontSize: '14px',
    '& svg': {
      marginRight: '8px',
    },
  },
  documentViewerInModal: {
    padding: '15px',
  },
  closeIcon: {
    marginLeft: '7px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

export default styles;
