import escapeRegExp from 'lodash/escapeRegExp';

/**
 * Returns substrings for a text matching on a pattern.
 *
 * Every odd entry represents the match.
 *
 * @example
 * ```js
 * textMatch('hello Hello HELLO world', 'lo h');
 * // ['hel', 'lo H', 'el', 'lo H', 'ELLO world']
 * ```
 */
export const textMatch = (text: string, pattern = ''): string[] => {
  if (!pattern) {
    return [text];
  }
  const re = new RegExp(`(${escapeRegExp(pattern)})`, 'gi');
  return text.split(re);
};
