import { get, noop } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  EmailInput,
  FormField,
  Layout,
  Panel_DEPRECATED,
  Select,
  testIsEmailValid,
  Text,
  TextInput,
} from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { CreateUserResponse } from '~/redux/api/methods';
import { Nullable } from '~/types';
import { ERROR, SUCCESS } from '~/types/toast.types';

import { default as DepartmentSelect } from '../Shared/DepartmentSelect';
import { showToast } from '../Shared/EcToast';

type Props = {
  visible: boolean;
  onHide: () => void;
};

export const UserFormPanel = ({ visible, onHide }: Props) => {
  const currentUser = useCurrentUser();
  const isRolodexDataSourceEnabled = useFlag(FlagType.RolodexAsUserDataSource);

  const [
    createUser,
    createUserResult,
  ] = api.endpoints.createSuperAdmin.useMutation();
  const {
    isLoading: isLoadingCreate,
    isError: isErrorCreate,
    isSuccess: isSuccessCreate,
    data: dataCreate,
    error: errorCreate,
  } = createUserResult;
  const [sendInvitationEmail] = api.endpoints.sendInvitationEmail.useMutation();

  const [firstName, setFirstName] = useState<Nullable<string>>('');
  const [lastName, setLastName] = useState<Nullable<string>>('');
  const [email, setEmail] = useState<Nullable<string>>('');
  const [jobTitle, setJobTitle] = useState<Nullable<string>>('');
  const [departments, setDepartments] = useState<Nullable<number[]>>([]);
  const [emailInstructions, setEmailInstructions] = useState<Nullable<boolean>>(
    true,
  );

  const reset = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setJobTitle('');
    setDepartments([]);
    setEmailInstructions(true);
  };

  const handleHide = () => {
    reset();
    onHide();
  };
  const handleSubmit = () => {
    createUser({
      first_name: firstName!,
      last_name: lastName!,
      email: email!,
      job_title: jobTitle,
      departments: departments ?? [],
      client: currentUser?.client,
    });
  };

  const handleSuccess = (
    successData?: CreateUserResponse,
    successMessage?: string,
  ) => {
    showToast(SUCCESS, successMessage);
    sendInvitationEmail({
      userId: get(successData, 'data.id', ''),
      useRolodex: isRolodexDataSourceEnabled,
    });
    handleHide();
  };

  const successMessage = `User ${firstName} created successfully!`;
  useEffect(() => {
    if (isSuccessCreate) {
      handleSuccess(dataCreate, successMessage);
    }
  }, [isSuccessCreate, dataCreate]);

  const showErrorMessage = (
    error: unknown,
    message = 'Something went wrong. Please try again later.',
  ) => {
    const errorMessage = get(
      error,
      'response.data.non_field_errors[0]',
      get(error, 'response.data.email[0]', message),
    );
    showToast(ERROR, errorMessage);
  };

  useEffect(() => {
    if (isErrorCreate) {
      showErrorMessage(errorCreate);
    }
  }, [isErrorCreate, errorCreate]);

  const isSubmitDisabled = !firstName || !lastName || !testIsEmailValid(email);
  const title = 'New User';
  const subtitle = 'Create a new user in your organization.';

  return (
    // eslint-disable-next-line react/jsx-pascal-case -- deprecating
    <Panel_DEPRECATED
      width="m"
      isVisible={visible}
      title={title}
      onHide={handleHide}
      isPadded={true}
      mode="blocking"
      footer={
        <Layout spacing="2" justify="flex-end" borderTop="border" p="3">
          <Button variant="tertiary" text="Cancel" onClick={handleHide} />
          <Button
            variant="primary"
            text="Save"
            onClick={handleSubmit}
            isLoading={isLoadingCreate}
            disabled={isSubmitDisabled}
          />
        </Layout>
      }
    >
      <Layout direction="column" spacing="2">
        <Text>{subtitle}</Text>
        <Text variant="section">General Information</Text>
        <FormField<string, false>
          placeholder="First name"
          name="firstName"
          value={firstName}
          onChange={setFirstName}
          input={TextInput}
          label="First name"
        />

        <FormField<string, false>
          placeholder="Last name"
          name="lastName"
          value={lastName}
          onChange={setLastName}
          input={TextInput}
          label="Last name"
        />

        <Layout direction="column" spacing="1">
          <FormField<string, false>
            placeholder="Email"
            name="email"
            value={email}
            onChange={setEmail}
            input={EmailInput}
            label="Email"
          />
          <FormField<boolean, false>
            name="sign-in-instructions'"
            value={emailInstructions}
            onChange={setEmailInstructions}
            inputProps={{
              option: {
                label: 'Email sign-in instructions',
                value: 'sign-in-instructions',
              },
            }}
            input={Checkbox}
            label={''}
          />
        </Layout>

        <Layout direction="column" spacing="1">
          <Text variant="body-bold">
            Job title{' '}
            <Text variant="tiny" color="text.secondary">
              (Optional)
            </Text>
          </Text>
          <FormField<string, false>
            placeholder="Job title"
            name="jobTitle"
            value={jobTitle}
            onChange={setJobTitle}
            input={TextInput}
            label=""
          />
        </Layout>

        <Layout direction="column" spacing="1">
          <Text variant="body-bold">
            Department{' '}
            <Text variant="tiny" color="text.secondary">
              (Optional)
            </Text>
          </Text>
          <Text color="text.secondary">
            Select a department for organizing purposes only. Use groups to
            grant access to documents and workflows.
          </Text>

          <FormField<number[], false>
            name="departments"
            value={departments}
            onChange={setDepartments}
            input={DepartmentSelect}
            label=""
            inputProps={{
              width: 'fullWidth',
              isMulti: true,
            }}
          />
        </Layout>

        <Layout direction="column" spacing="2" mt="6">
          <Text variant="section">Permissions</Text>

          <Layout direction="column" spacing="1">
            <Text variant="body-bold">Role</Text>
            <Text color="text.secondary">
              Assign a role to the user to grant permissions in Evisort.
            </Text>
            <Select
              disabled={true}
              placeholder="Select a role"
              options={[{ label: 'Super Admin', value: '1' }]}
              name="role"
              value={'1'}
              onChange={noop}
              isMulti={false}
            />
          </Layout>
        </Layout>
      </Layout>
    </Panel_DEPRECATED>
  );
};
