import { Party } from '~/features/manage-parties';
import { pilot } from '~/services';
import { PilotId } from '~/types';

interface Request {
  clientId: PilotId;
  isInternal: boolean;
  party: Party;
}

export const deleteParty = async ({
  clientId,
  isInternal,
  party,
}: Request): Promise<boolean> => {
  const params = {
    internal: isInternal,
    partyName: party.name,
  };

  await pilot.remove(`client/${clientId}/party/`, { params });

  return true;
};
