import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { connect } from 'react-redux';

import ShareDocumentButton from '~/components/Workflow/shared/ShareDocumentButton';
import { Button, Layout } from '~/eds';
import { TicketPermissionType, TicketStageType } from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { withUsers } from '~/hocs';
import { getIsNextStageDisabled } from '~/reducers/ticket';
import {
  getNextStageType,
  getSimplifiedStageNames,
  latestVersionHasWorkingDraft,
  shouldFillFormQuestionsInSignStage,
} from '~/utils/ticket';

import UploadSignedButton from '../UploadSignedButton';
import BackToReviewAction from './BackToReviewAction';
import CheckSignatureStatus from './CheckSignatureStatus';
import CollectSignatureAction from './CollectSignaturesAction';
import MarkCompleteAction from './MarkCompleteAction';
import UploadVersionAction from './UploadVersionAction';

const rankedPermissions = [
  TicketPermissionType.BackToReview,
  TicketPermissionType.UploadVersion,
  TicketPermissionType.Download,
  TicketPermissionType.Share,
  TicketPermissionType.SendForReview,
  TicketPermissionType.SendForSignatures,
  TicketPermissionType.UploadSigned,
  TicketPermissionType.CollectSignatures,
  TicketPermissionType.EditForm,
  TicketPermissionType.MarkForSignatures,
  TicketPermissionType.MarkComplete,
  TicketPermissionType.SendForFinalization,
];

export const getNextStageButtonText = (stage, stages) => {
  switch (getNextStageType(stage, stages)) {
    case TicketStageType.Review:
      return 'Send for Review';
    case TicketStageType.Sign:
      return 'Send for Signing';
    case TicketStageType.Finalize:
      return 'Send for Finalization';
    default:
      return null;
  }
};

const lastLeftSideActionIndex = rankedPermissions.indexOf(
  TicketPermissionType.Share,
);

function uploadVersionButton(stage) {
  if ([TicketStageType.Edit, TicketStageType.Review].includes(stage)) {
    return <UploadVersionAction />;
  }
}

function TicketDocumentActions({
  handlers,
  loaders,
  // connected,
  ticket,
  users,
}) {
  const { permissions, stage, enabledStageNames } = ticket;
  const nonDuplicatePermissions = permissions.filter(
    (permission, index) => permissions.indexOf(permission) === index,
  );
  const isHideShareButtonEnabled = useFlag(FlagType.HideShareButton);
  const enableCheckSignatureStatusButton = useFlag(
    FlagType.CheckSignatureStatus,
  );

  const isNextStageDisabled = getIsNextStageDisabled(ticket, users);

  const stages = getSimplifiedStageNames(ticket);

  const isReviewStageEnabled = enabledStageNames.includes(
    TicketStageType.Review,
  );

  const getShareButtonTooltip = () => {
    if (latestVersionHasWorkingDraft(ticket.documentVersions)) {
      return 'There are edits to this version in progress. Please save up as a new version or delete your edits to share version.';
    }
    return 'Share this document version.';
  };

  const nextStageButton = () => {
    const nextStageType = getNextStageType(stage, stages);
    const nextStageText = getNextStageButtonText(stage, stages);
    const onClick =
      nextStageType === TicketStageType.Sign
        ? handlers.handleSendForSigning
        : handlers.handleNextStageClick;

    const isLoading =
      nextStageType === TicketStageType.Sign
        ? loaders.isLoadingSendForSigning
        : loaders.isLoadingNextStage;

    // If there is no next stage text, there's nothing to progress to, otherwise check on additional (existing) logic that will further disable the next stage.
    if (!nextStageText || isNextStageDisabled) return null;

    return (
      <Button
        isLoading={isLoading}
        text={nextStageText}
        onClick={onClick}
        variant="primary"
      />
    );
  };

  const documentActions = {
    [TicketPermissionType.BackToReview]: isReviewStageEnabled && (
      <BackToReviewAction />
    ),
    [TicketPermissionType.UploadVersion]: uploadVersionButton(ticket.stage),
    [TicketPermissionType.Download]: (
      <Button
        tooltip={
          loaders.isPreparingDownload
            ? 'Your document is getting ready to be downloaded.'
            : ''
        }
        isLoading={loaders.isPreparingDownload || loaders.isLoadingDownload}
        text="Download"
        onClick={handlers.handleOnDownloadClick}
        variant="secondary"
      />
    ),
    [TicketPermissionType.Share]: !isHideShareButtonEnabled && (
      <ShareDocumentButton
        isDisabled={latestVersionHasWorkingDraft(ticket.documentVersions)}
        tooltip={getShareButtonTooltip()}
        onShare={handlers.handleOnShareSuccess}
      />
    ),
    [TicketPermissionType.SendForReview]: nextStageButton(),
    [TicketPermissionType.SendForSignatures]: nextStageButton(),
    [TicketPermissionType.SendForFinalization]: nextStageButton(),
    [TicketPermissionType.EditForm]: shouldFillFormQuestionsInSignStage(
      ticket,
      users,
    ) && (
      <Button
        text="Add Signers"
        onClick={handlers.handleOnAddSignersClick}
        tooltip="Add missing signers to collect signatures"
      />
    ),
    [TicketPermissionType.UploadSigned]: <UploadSignedButton />,
    [TicketPermissionType.CollectSignatures]: <CollectSignatureAction />,
    [TicketPermissionType.MarkForSignatures]: (
      <Button
        text="Mark Signatures"
        onClick={() => console.log('Mark Signatures clicked;')}
      />
    ),
    [TicketPermissionType.MarkComplete]:
      getNextStageType(stage, stages) === null && !isNextStageDisabled ? (
        <MarkCompleteAction />
      ) : null,
  };

  let leftsidePermissions = [];
  let rightsidePermissions = [];

  nonDuplicatePermissions.forEach((permission) => {
    const actionPermission = documentActions[permission];
    if (actionPermission) {
      if (rankedPermissions.indexOf(permission) > lastLeftSideActionIndex) {
        rightsidePermissions.push(permission);
      } else {
        leftsidePermissions.push(permission);
      }
    }
  });

  leftsidePermissions.sort(
    (p1, p2) => rankedPermissions.indexOf(p1) - rankedPermissions.indexOf(p2),
  );
  rightsidePermissions.sort(
    (p1, p2) => rankedPermissions.indexOf(p1) - rankedPermissions.indexOf(p2),
  );

  return (
    <Layout wrap="wrap" align="center" justify="space-between" spacing={2}>
      <Layout align="center" spacing={4}>
        {leftsidePermissions.map((permission, index) => (
          <Fragment key={`leftside-action-${index}`}>
            {documentActions[permission]}
          </Fragment>
        ))}
      </Layout>
      <Layout align="center" spacing={4}>
        {rightsidePermissions.map((permission, index) => (
          <Fragment key={`leftside-action-${index}`}>
            {documentActions[permission]}
          </Fragment>
        ))}
        {enableCheckSignatureStatusButton &&
          ticket.hasEnvelope &&
          stage === TicketStageType.Sign && <CheckSignatureStatus />}
      </Layout>
    </Layout>
  );
}

TicketDocumentActions.propTypes = {
  handlers: PropTypes.object.isRequired,
  loaders: PropTypes.object.isRequired,
};

const mapStateToProps = ({ ticket }) => ({
  ticket,
});

export default withUsers(connect(mapStateToProps)(TicketDocumentActions));
