import { black2 } from '~/assets/shared-styles/general';

const validationEntity = {
  marginBottom: '4px',
  display: 'flex',
  alignItems: 'center',
  color: black2,
  fontSize: '12px',
  '& svg': {
    marginRight: '5px',
  },
};

const styles = {
  formRow: {
    marginTop: '30px',
  },
  formRowDisabled: {
    marginTop: '30px',
    pointerEvents: 'none',
    opacity: 0.5,
  },
  passwordValidation: {
    textAlign: 'left',
  },
  validationEntity: {
    ...validationEntity,
  },
  validationEntityDisabled: {
    ...validationEntity,
    opacity: 0.5,
  },
};

export default styles;
