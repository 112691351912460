import React from 'react';
import { toast } from 'react-toastify';

import EcToast from './EcToast';

export const showToast = (
  type,
  text,
  href = null,
  autoCloseTime = undefined,
  isExternal = false,
) => {
  toast(
    <EcToast type={type} text={text} href={href} isExternal={isExternal} />,
    {
      autoClose: autoCloseTime,
    },
  );
};

export default EcToast;
