import { evisortBlue } from '../../../assets/shared-styles/general';

const backgroundWash = '#F0F0F0';
const border = 'solid 1px rgba(0, 0, 0, 0.1)';
const fontSize = '14px';
export default {
  mentionsInput: {
    '&__input': {
      border: '1px solid rgba(0, 0, 0, 0.2)',
      fontSize,
      outline: 0,
      borderRadius: 4,
      '&:hover': {
        borderColor: 'rgb(12, 120, 184)',
      },
    },
    '&__control': {
      minHeight: 100,
      fontSize: 15,
    },
    '&__suggestions': {
      '&__list': {
        backgroundColor: 'white',
        border,
        maxHeight: '240px',
        overflowY: 'auto',
      },
      '&__item': {
        borderBottom: border,
        fontSize,
        padding: '8px 16px',
        '&--focused': {
          background: backgroundWash,
          color: evisortBlue,
        },
      },
    },
  },
  mention: {
    background: backgroundWash,
  },
};
