import PT from 'prop-types';
import React from 'react';

import { FlexLayout } from '~/ui';

function Footer({ actionButtons }) {
  return (
    <FlexLayout
      alignItems="center"
      flexGrow="1"
      justifyContent="flex-end"
      px={6}
      py={3}
      space={3}
      sx={{
        borderTop: 'border',
        minHeight: 'card-footer-height',
      }}
    >
      {actionButtons}
    </FlexLayout>
  );
}

Footer.propTypes = {
  /** Array of Button components */
  actionButtons: PT.arrayOf(PT.node.isRequired).isRequired,
  /** Hides Cancel button */
  disableCancelButton: PT.bool,
};

export default Footer;
