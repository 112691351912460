import { get, identity } from 'lodash';

import { PositionResponse } from '~/redux/api/methods/searchV3/getSearchHandlersByPosition';
import { ApiResponse } from '~/redux/slices/documentsNavigation';

interface NavigationItem {
  id: number;
  name: string;
  file_type: string;
  position: number;
}

export const apiModelToViewModel = ({
  id,
  name,
  file_type,
  position,
}: NavigationItem) => ({
  id,
  name,
  fileType: file_type,
  position,
});

export const parseNavigationResponse = (response: ApiResponse) => {
  const positions = ['previous', 'current', 'next'];
  const parsedPositions = positions.map((position) => {
    const navigationItem = response[
      position as keyof ApiResponse
    ] as NavigationItem;
    if (navigationItem.position >= 0) {
      return apiModelToViewModel(navigationItem);
    } else {
      return null;
    }
  });
  return parsedPositions.filter(identity);
};

export const parseNavigationResponseV3 = (response: PositionResponse) => {
  const positions = ['previous', 'current', 'next'];
  const { attributes } = response?.data;
  const parsedPositions = positions.map((position) => {
    const navigationItem = get(attributes, position, null);
    if (!navigationItem) return null;
    return apiModelToViewModel({
      ...navigationItem.attributes,
      id: navigationItem.id as number,
    });
  });
  return parsedPositions.filter(identity);
};
