import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { workflowSetConditionalTextField } from '~/actions/workflow';
import ConditionRuleInput from '~/components/Workflow/shared/ConditionRuleInput';
import Field from '~/components/Workflow/shared/Field';
import FieldPlaceholder from '~/components/Workflow/shared/FieldPlaceholder';
import { getConditionalTextFields } from '~/reducers/workflow';
import { FlexLayout, PageLayout, Text, TextInput, useModalSimple } from '~/ui';
import { testInvalidConditionRule } from '~/utils/workflow';

const { SidebarSection, SidebarSectionItem } = PageLayout;

function LabelContentPair({ children, label, rightHeaderContent }) {
  return (
    <FlexLayout flexDirection="column" space={3}>
      <FlexLayout alignItems="center" justifyContent="space-between">
        <Text color="gray-700" variant="s-dense-bold">
          {label}
        </Text>
        {rightHeaderContent}
      </FlexLayout>
      {children}
    </FlexLayout>
  );
}

function ConditionalTextFieldSection({
  // connected
  conditions,
  disabled,
  fields,
  workflowSetConditionalTextField,
}) {
  const [selectedField, setSelectedField] = useState();
  const [name, setName] = useState('');
  const [conditionRule, setConditionRule] = useState();

  useEffect(() => {
    if (selectedField) {
      setName(selectedField.name);
      setConditionRule(selectedField.conditionRule);
    } else {
      setName('');
      setConditionRule();
    }
  }, [selectedField]);

  function confirm() {
    if (selectedField) {
      workflowSetConditionalTextField({
        ...selectedField,
        name,
        conditionRule,
      });
    }
  }

  function cancel() {
    setSelectedField();
  }

  function handleEdit(field) {
    setSelectedField(field);
    showEditModal();
  }

  const [editModal, showEditModal] = useModalSimple({
    confirm,
    disableAutoHide: true,
    text: 'Save',
    title: 'Edit Conditional Text',
    content: selectedField && (
      <FlexLayout flexDirection="column" space={6}>
        <LabelContentPair
          label="Name"
          rightHeaderContent={
            <FieldPlaceholder
              value={selectedField.conditionalText.placeholder}
            />
          }
        >
          <TextInput value={name} onChange={setName} width="fullWidth" />
        </LabelContentPair>
        <LabelContentPair label="Visibility">
          <ConditionRuleInput
            conditionRule={conditionRule}
            onChange={setConditionRule}
          />
        </LabelContentPair>
        {selectedField.conditionalText.texts.map((text, i) => (
          <LabelContentPair key={i} label="Content">
            <Text color="gray-700" variant="s-spaced">
              {text}
            </Text>
          </LabelContentPair>
        ))}
      </FlexLayout>
    ),
    width: 'l',
    onHide: cancel,
  });

  if (fields.length === 0) {
    return null;
  }

  return (
    <SidebarSection title="Conditional Text">
      {fields.map((field) => {
        let warningTooltip;
        if (!field.conditionRule) {
          warningTooltip = 'A condition must be assigned';
        } else if (testInvalidConditionRule(field.conditionRule, conditions)) {
          warningTooltip = 'Invalid condition assigned';
        }
        return (
          <SidebarSectionItem
            key={field.id}
            onClick={() => (disabled ? null : handleEdit(field))}
            role="listitem"
          >
            <Field
              assignedLabel={
                conditions[field?.conditionRule?.id]?.name || 'Unassigned'
              }
              disabled={disabled}
              field={field}
              isAssigned={!!field.conditionRule}
              warningTooltip={warningTooltip}
            />
          </SidebarSectionItem>
        );
      })}
      {editModal}
    </SidebarSection>
  );
}

function mapStateToProps({ builder, workflow }) {
  return {
    disabled: !builder.shouldEnableAllComponents,
    fields: getConditionalTextFields(workflow),
    conditions: workflow.conditions,
  };
}

export default connect(mapStateToProps, { workflowSetConditionalTextField })(
  ConditionalTextFieldSection,
);
