import { createConfig } from 'uinix-ui';

import { elementShorthandPropsMapping } from './elementShorthandPropsMapping';
import { elementStyles } from './elementStyles';
import { responsiveCssProperties } from './responsiveCssProperties';

export const config = createConfig({
  elementShorthandPropsMapping,
  elementStyles,
  responsiveCssProperties,
});
