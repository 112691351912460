import pluralize from 'pluralize';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { analyzerBulkDelete } from '~/components/AnalyzerResultsPage/AnalyzerResultsPage.data';
import DeleteDocumentWithReasonModal from '~/components/Modals/DeleteDocumentWithReasonModal';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { BULK_DELETE_LIMIT } from '~/constants/max_lengths';
import { withCurrentUser } from '~/hocs';
import { selectors } from '~/redux';
import search from '~/redux/slices/search';
import { MODAL_DELETE } from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';

const DeleteSearchResults = ({
  onActionComplete,
  resultsCache = {},
  currentUser,
}) => {
  const modal = useSelector(selectors.selectSearchResultModal);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(search.actions.setModal());
  const pilotSearchQuery = useSelector(selectors.selectPilotSearchQuery);
  const { modalType, context } = modal || {};
  const documentDeleteReasonRequired = !!currentUser?.client_config
    ?.document_delete_reason_required;

  if (modalType !== MODAL_DELETE) return null;

  const { isAllRowsSelected, selectedRowIds, totalCount } = context;

  const count = isAllRowsSelected ? totalCount : selectedRowIds.length;

  const renderBulkDeleteModalText = () => {
    let target;
    if (count === 1) {
      const doc = resultsCache[selectedRowIds[0]];
      target = `"${doc.document_handlers[0].document_name}"`;
    } else {
      target = `${count} ${pluralize('document', count)} you have selected`;
    }
    return `Are you sure you want to delete ${target}? You won’t be able to undo this action.`;
  };

  const onBulkDelete = (reason, extraDetails) => {
    const selectedItems = selectedRowIds.map((id) => resultsCache[id]);

    const handlersIds = isAllRowsSelected
      ? []
      : selectedItems.flatMap((document) =>
          document.document_handlers.map((handler) => handler.id),
        );

    analyzerBulkDelete(
      handlersIds,
      pilotSearchQuery,
      isAllRowsSelected,
      reason,
      extraDetails,
    )
      .then((res) => showToast(SUCCESS, res.detail))
      .catch((err) =>
        showToast(
          ERROR,
          err?.response?.data?.detail ||
            'An error occurred while deleting the documents.',
        ),
      )
      .finally(onActionComplete);
  };

  if (documentDeleteReasonRequired) {
    return (
      <DeleteDocumentWithReasonModal
        type="document"
        isVisible={modalType === MODAL_DELETE}
        count={count}
        hideModal={hideModal}
        onConfirmDelete={onBulkDelete}
      />
    );
  }
  return (
    <EcModal
      modalType={modalType}
      width="560px"
      title={`Delete ${pluralize('Document', count)}?`}
      text={renderBulkDeleteModalText()}
      confirmButtonText="Delete"
      deleteItem={onBulkDelete}
      hideModal={hideModal}
      overLimit={count > BULK_DELETE_LIMIT}
    />
  );
};

export default withCurrentUser(DeleteSearchResults);
