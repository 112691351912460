import React from 'react';

const GroupEmptyIcon = () => {
  return (
    <svg width="344" height="136" viewBox="0 0 344 136">
      <defs>
        <linearGradient id="a" x1="0%" x2="14.619%" y1="55.556%" y2="40.646%">
          <stop offset="0%" stopColor="#EAEAEA" />
          <stop offset="100%" stopColor="#F0F0F0" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="132" height="44" x="106" fill="url(#a)" rx="4" />
        <g fill="url(#a)" transform="translate(0 92)">
          <rect width="132" height="44" x="212" rx="4" />
          <rect width="132" height="44" rx="4" />
        </g>
        <path fill="#F4F4F4" d="M279 92h-2V69H67v23h-2V67h105V44h2v23h107z" />
      </g>
    </svg>
  );
};

export default GroupEmptyIcon;
