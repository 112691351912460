export const MIN_PAGE_INDEX = 1;

export const bounded = (n: number) => Math.max(0, n || 0);

export const testHasValidProps = ({
  pageIndex,
  pageSize,
  totalCount,
}: {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
}) => pageIndex >= MIN_PAGE_INDEX && pageSize > 0 && totalCount > 0;

export const renderPageDisplay = ({
  pageIndex,
  pageSize,
  totalCount,
}: {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
}) => {
  let lower = 0;
  let upper = 0;

  if (testHasValidProps({ pageIndex, pageSize, totalCount })) {
    lower = pageSize * (pageIndex - 1) + 1;
    upper = Math.min(totalCount, pageSize * pageIndex);
  }

  return `${lower} - ${upper}`;
};
