import { Uuid } from '~/types';

import { HtmlTokenHighlight } from '../HtmlDocumentViewer';
import { Highlight } from '../types';

export const mapActiveElement = (
  highlights: Highlight[] = [],
  activeHighlightId?: Uuid,
) => {
  const activeHighlight = highlights.find(
    (highlight) => highlight.id === activeHighlightId,
  );

  if (activeHighlight) {
    const firstHtmlToken = activeHighlight.htmlTokens?.[0];
    return (firstHtmlToken ?? '').toString();
  }
};

export const mapHtmlTokenHighlights = (
  highlights: Highlight[],
): HtmlTokenHighlight[] => {
  return highlights.flatMap((highlight) =>
    (highlight.htmlTokens ?? []).map((htmlToken) => ({
      id: htmlToken.toString(),
      key: highlight.id,
      className: `highlight-${highlight.highlighter ?? 'default'}`,
    })),
  );
};
