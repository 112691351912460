import React from 'react';

const ProjectIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          fill="#404040"
          fillRule="nonzero"
          d="M16.667 5h-3.334V3.333a1.66 1.66 0 0 0-1.666-1.666H8.333a1.66 1.66 0 0 0-1.666 1.666V5H3.333c-.925 0-1.658.742-1.658 1.667l-.008 9.166A1.66 1.66 0 0 0 3.333 17.5h13.334a1.66 1.66 0 0 0 1.666-1.667V6.667A1.66 1.66 0 0 0 16.667 5zm-5 0H8.333V3.333h3.334V5z"
        />
      </g>
    </svg>
  );
};

export default ProjectIcon;
