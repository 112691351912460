import { sylvanus } from '~/services';
import { Uuid } from '~/types';

import {
  PromptModelPublishMode,
  PromptModelWorkflow,
} from '../../types/sylvanus/model';

interface Request {
  modelId: Uuid;
  version: number;
  idempotencyKey: Uuid;
  publishMode?: PromptModelPublishMode;
}

type Response = boolean;

export const publishPromptModel = async ({
  modelId,
  version,
  idempotencyKey,
  publishMode = 'all_docs_in_scope',
}: Request): Promise<Response> => {
  const response = (await sylvanus.post(`/model/${modelId}/publish`, {
    published_version_number: version,
    idempotency_key: `reserve_${idempotencyKey}`,
    publish_mode: publishMode,
  })) as PromptModelWorkflow;

  return Boolean(response);
};
