import { useMemo, useState } from 'react';

import { Box, formatDate, Layout, Modal, Select, StatusMessage } from '~/eds';
import { DocumentVersion } from '~/features/document';
import { DocumentViewer } from '~/features/document-viewer';
import { Nullable, PilotId } from '~/types';

interface Props {
  onHide: () => void;
  versions: DocumentVersion[];
  selectedVersion: DocumentVersion;
}
export const DocumentVersionViewer = ({
  selectedVersion: initialSelectedVersion,
  versions,
  onHide,
}: Props) => {
  const [selectedVersion, setSelectedVersion] = useState(
    initialSelectedVersion,
  );

  const versionOptions = useMemo(() => {
    return versions.map((version) => ({
      value: version.id,
      label: `Version ${version.version_tag}`,
    }));
  }, [versions]);

  const handleVersionChange = (versionId: Nullable<PilotId>) => {
    if (versionId) {
      const version = versions.find((version) => version.id === versionId);
      if (version) {
        setSelectedVersion(version);
      }
    }
  };

  return (
    <Modal isVisible title={selectedVersion.file_name} onHide={onHide}>
      <Layout overflowY="hidden" direction="column" id="test">
        <Layout direction="column" spacing={2}>
          {!selectedVersion.is_latest && (
            <StatusMessage
              status="info"
              message={`You are viewing an older version of this document. This is version ${
                selectedVersion.version_tag
              }, last modified on ${formatDate(
                new Date(selectedVersion.date_modified),
                'full',
              )} by ${selectedVersion.last_modified_by}.`}
            />
          )}
          <Layout alignSelf="flex-end">
            <Select<number, false>
              name="version-select"
              isMulti={false}
              isSearchable={false}
              options={versionOptions}
              value={selectedVersion.id}
              onChange={handleVersionChange}
            />
          </Layout>
        </Layout>
        <Box overflowY="auto" minH={600}>
          <DocumentViewer
            documentVersionId={selectedVersion.id}
            options={{
              autoLatestVersion: false,
            }}
          />
        </Box>
      </Layout>
    </Modal>
  );
};
