import { add, set, sub } from 'date-fns';
import React from 'react';
import {
  Matcher,
  useDayPicker,
  useFocus,
  useNavigation,
} from 'react-day-picker';
import { useStyles } from 'uinix-ui';

import { Box } from '../../Box';
import { Button } from '../../Button';
import { IconButton } from '../../IconButton';
import { Layout } from '../../Layout';
import { Months } from './Months';
import {
  getNavigateUnit,
  getSelectedDate,
  getYearRange,
  testDisablePreviousYear,
  YEAR_PAGE_SIZE,
} from './utils';
import { useView } from './ViewContext';
import { Years } from './Years';

type MatcherPredicate = (date: Date) => boolean;

interface Props {
  displayMonth: Date;
}

export const Caption = ({ displayMonth: displayDate }: Props) => {
  const dayPickerProps = useDayPicker();
  const { focus } = useFocus();
  const { goToMonth } = useNavigation();
  const styles = useStyles();
  const { view, setView } = useView();

  const { formatters, locale, mode, selected, today } = dayPickerProps;

  const disabledMatchers = dayPickerProps.disabled as Matcher[]; // it is always sent as an array in our code path and the last element is always a matcher predicate.
  const disabledMatcher = disabledMatchers[
    disabledMatchers.length - 1
  ] as MatcherPredicate;
  const disabled = disabledMatcher(today);
  // @ts-ignore the vendor does expose this
  const onSelect = dayPickerProps.onSelect;

  const isDayView = view === 'day';
  const isRange = mode === 'range';
  const isMonthView = view === 'month';
  const isYearView = view === 'year';

  const formatDisplayDate = (date: Date) =>
    formatters.formatCaption(date, { locale });
  const formatMonth = (date: Date) =>
    formatters.formatMonthCaption(date, { locale });

  const selectedDate = getSelectedDate(selected, isRange);

  const formattedDisplayDate = formatDisplayDate(displayDate) as string;
  const displayDateText = isYearView
    ? getYearRange(displayDate).join(' - ')
    : formattedDisplayDate;

  const goToDate = (date: Date) => {
    goToMonth(date);
    focus(date);
  };

  const handleBack = () => {
    switch (view) {
      case 'year':
        goToMonth(sub(displayDate, { years: YEAR_PAGE_SIZE }));
        break;
      case 'month':
        goToMonth(sub(displayDate, { years: 1 }));
        break;
      case 'day':
      default: {
        goToMonth(sub(displayDate, { months: 1 }));
      }
    }
  };

  const handleNext = () => {
    switch (view) {
      case 'year':
        goToMonth(add(displayDate, { years: YEAR_PAGE_SIZE }));
        break;
      case 'month':
        goToMonth(add(displayDate, { years: 1 }));
        break;
      case 'day':
      default: {
        goToMonth(add(displayDate, { months: 1 }));
      }
    }
  };

  const handleSelectToday = () => {
    if (isRange) {
      onSelect({
        from: today,
        to: today,
      });
    } else {
      onSelect(today);
    }

    goToDate(today);
  };

  const handleSelectMonth = (month: number) => {
    goToDate(
      set(displayDate, {
        date: selectedDate,
        month,
      }),
    );
    setView('day');
  };

  const handleSelectYear = (year: number) => {
    goToDate(
      set(displayDate, {
        date: selectedDate,
        year,
      }),
    );
    setView('month');
  };

  const toggleView = () => setView(isDayView ? 'year' : 'day');

  const navigateUnit = getNavigateUnit(view);

  return (
    <Box styles={componentStyles.container}>
      <Layout align="center" justify="space-between">
        <Button
          icon={isDayView ? 'chevron-down' : 'chevron-up'}
          iconPosition="right"
          text={displayDateText}
          variant="tertiary"
          onClick={toggleView}
        />
        <Layout align="center" spacing={2}>
          {isDayView && (
            <Button
              disabled={disabled}
              text="Today"
              variant="action"
              onClick={handleSelectToday}
            />
          )}
          <IconButton
            disabled={testDisablePreviousYear(displayDate, view)}
            icon="chevron-left"
            tooltip={`Previous ${navigateUnit}`}
            onClick={handleBack}
          />
          <IconButton
            icon="chevron-right"
            tooltip={`Next ${navigateUnit}`}
            onClick={handleNext}
          />
        </Layout>
      </Layout>
      {!isDayView && (
        <Box styles={componentStyles.overlay}>
          <Box
            styleProps={{
              columns: 4,
              justify: 'center',
            }}
            styles={[styles.gridColumns]}
          >
            {isMonthView && (
              <Months
                displayDate={displayDate}
                formatMonth={formatMonth}
                onSelectMonth={handleSelectMonth}
              />
            )}
            {isYearView && (
              <Years
                displayDate={displayDate}
                onSelectYear={handleSelectYear}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const componentStyles = {
  container: {
    position: 'relative',
  },
  overlay: {
    backgroundColor: 'background',
    height: 'max-content',
    inset: '100% 0 0',
    paddingBottom: 4,
    paddingTop: 4,
    position: 'absolute',
    zIndex: 'forward',
  },
};
