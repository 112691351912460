import { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { Button, formatDate, parseDate } from '~/eds';
import { DataFieldType } from '~/enums';
import { withFlags } from '~/flags';
import { testIsSuperAdmin } from '~/utils/user';

import {
  black2,
  black5,
  evisortBlue,
} from '../../../assets/shared-styles/general';
import { getContractTypesByClientId } from '../../Admin/DataFieldsPage/DataFieldsPage.data';
import EcCheckbox from '../../Shared/EcCheckbox';
import EcDatepicker from '../../Shared/EcDatepicker';
import EcInput from '../../Shared/EcInput';
import EcModalCard from '../../Shared/EcModalCard';
import EcMultipleSelectValue from '../../Shared/EcMultipleSelectValue';
import EcMultiSelect from '../../Shared/EcMultiSelect';
import EcRadioButton from '../../Shared/EcRadioButton';
import EcRadioContainer from '../../Shared/EcRadioContainer';
import EcSelect from '../../Shared/EcSelect';
import EcTooltip from '../../Shared/EcTooltip';
import ChevronDownIcon from '../../Shared/Icons/ChevronDownIcon';
import ChevronRightIcon from '../../Shared/Icons/ChevronRightIcon';
import HelpIcon from '../../Shared/Icons/HelpIcon';
import SubArrowIcon from '../../Shared/Icons/SubArrowIcon';
import styles from './CustomFieldModal.styles';

const mappedPlaceholders = {
  options: 'option',
  doc_types: 'document types',
};

const defaultFieldType = { label: 'Text', value: 'Text' };

const field_types_available = [
  'Attachment',
  'Date',
  'Dropdown',
  'Number',
  'Text',
  'Text Area',
];

const fieldTypesDisplay = {
  ARRAY_SINGLE: 'Dropdown',
  ARRAY_MULTIPLE: 'Dropdown',
  ATTACHMENT: 'Attachment',
  BOOLEAN: 'True/False',
  DATE: 'Date',
  NUMBER: 'Number',
  STRING: 'Text',
  TEXT_AREA: 'Text Area',
};

class CustomFieldModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: this.props.selectedItem,
      editData: {
        name: this.props.selectedItem ? this.props.selectedItem.label : '',
        field_type: this.props.selectedItem
          ? {
              label: fieldTypesDisplay[this.props.selectedItem.field_type],
              value: fieldTypesDisplay[this.props.selectedItem.field_type],
            }
          : defaultFieldType,
        rawOptions: this.props.selectedItem
          ? this.convertTypeToLabel(this.props.selectedItem.options)
          : [],
        options: this.props.selectedItem
          ? this.convertTypeToLabel(this.props.selectedItem.options)
          : [],
        doc_restriction: this.props.selectedItem
          ? this.props.selectedItem.doc_restriction
          : 'EXCLUDE',
        doc_types: this.props.selectedItem
          ? this.convertTypeToLabel(this.props.selectedItem.doc_types)
          : [],
        defaultValue: this.props.selectedItem
          ? this.props.selectedItem.default
          : null,
        allow_multiple: this.props.selectedItem
          ? this.props.selectedItem.allow_multiple
          : false,
        allow_new_options: this.props.selectedItem
          ? this.props.selectedItem.allow_new_options
          : false,
        sort_options: this.props.selectedItem
          ? this.props.selectedItem.sort_options
          : false,
        help_text: this.props.selectedItem
          ? this.props.selectedItem.help_text
          : '',
        show_on_upload_form: this.props.selectedItem
          ? this.props.selectedItem.show_on_upload_form
          : false,
        is_beta: this.props.selectedItem
          ? this.props.selectedItem.is_beta
          : false,
        is_smart: this.props.selectedItem
          ? this.props.selectedItem.is_smart
          : false,
        is_visible: this.props.selectedItem
          ? this.props.selectedItem.is_visible
          : true,
      },
    };

    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleFlagForUpload = this.handleFlagForUpload.bind(this);
    this.handleAllowNewOptionsCheckbox = this.handleAllowNewOptionsCheckbox.bind(
      this,
    );
  }

  componentDidMount() {
    const { clientId } = this.props;

    getContractTypesByClientId(clientId)
      .then((data) => {
        this.setState({
          docTypes: this.convertTypeToLabel(data.suggestions),
          errorLoadingDocTypes: false,
        });
      })
      .catch(() => this.setState({ errorLoadingDocTypes: true }));
  }

  convertTypeToLabel(types) {
    return types && types.length
      ? types.map((type) => {
          return {
            label: type,
            value: type,
            key: type,
          };
        })
      : [];
  }

  handleActionClick() {
    const { handleDataFieldChange, hideModal } = this.props;
    const { editData } = this.state;

    handleDataFieldChange(editData);
    hideModal();
  }

  handleFormChange(name, value) {
    const { editData } = this.state;
    const newEditData = { ...editData, [name]: value };
    this.setState({ editData: newEditData });
  }

  renderMultiSelect(item, name) {
    const multiSelectMenu = this.renderItem(item, name);
    return <div>{multiSelectMenu}</div>;
  }

  renderItem(item, name) {
    const { selectedItem } = this.props;
    const {
      editData: { rawOptions },
    } = this.state;

    const presentValue = this.state.editData[name] || [];
    const options = selectedItem && name === 'options' ? rawOptions : item;
    const notCreatable = name === 'doc_types';
    const placeholderName = mappedPlaceholders[name];

    return (
      <div>
        <EcMultiSelect
          key={`select-menu-${name}`}
          value={presentValue}
          options={options}
          onChange={(newItems) => {
            const newState = {};
            newState[name] = newItems;
            this.setState({
              editData: { ...this.state.editData, ...newState },
            });
          }}
          placeholder={`Enter ${placeholderName}...`}
          noOptionsMessage={`No ${placeholderName} found`}
          isCreatable={!notCreatable}
        />
        {this.renderItemValues(name)}
      </div>
    );
  }

  renderItemValues(name) {
    const { classes } = this.props;
    const presentValues = this.state.editData[name];

    return (
      <div className={classes.multipleValuesContainer}>
        <SubArrowIcon color={black5} />
        <div className={classes.multipleValues}>
          {presentValues.length ? (
            presentValues.map((value) => (
              <EcMultipleSelectValue
                label={value.label}
                key={value.value}
                closeHandler={() => {
                  const newState = {};
                  const newMultiState = [...this.state.editData[name]];
                  newState[name] = newMultiState.filter(
                    (multiValue) => multiValue.value !== value.value,
                  );
                  this.setState({
                    editData: { ...this.state.editData, ...newState },
                  });
                }}
              />
            ))
          ) : (
            <span className={classes.multipleValuesEmpty}>
              No values selected...
            </span>
          )}
        </div>
      </div>
    );
  }

  renderInputFieldForType(field_type) {
    let returnValue;

    if (field_type) {
      switch (field_type.label) {
        case 'Date':
          returnValue = this.renderDateItem(field_type);
          break;
        case 'Number':
          returnValue = this.renderNumberItem(field_type);
          break;
        case 'Text Area':
          returnValue = this.renderTextAreaItem(field_type);
          break;
        default:
          returnValue = this.renderTextItem(field_type);
          break;
      }
      return returnValue;
    }
  }

  renderDateItem() {
    const {
      editData: { defaultValue },
    } = this.state;

    return (
      <EcDatepicker
        date={defaultValue ? parseDate(defaultValue) : null}
        onDateChange={(date) => {
          const newDate = date ? formatDate(date, 'iso_date') : null;
          const newEditData = { ...this.state.editData, defaultValue: newDate };
          this.setState({ editData: newEditData });
        }}
        id="CustomFieldModal/DefaultValue"
        isClearable={true}
        placeholder="Select date"
      />
    );
  }

  renderNumberItem() {
    const {
      editData: { defaultValue },
    } = this.state;

    return (
      <EcInput
        id="CustomFieldModal/DefaultValue"
        placeholder="Enter the default value for this field (if applicable)"
        type="number"
        input={{
          value: defaultValue || '',

          onChange: (event) => {
            this.handleFormChange('defaultValue', event.target.value);
          },
        }}
      />
    );
  }

  renderTextAreaItem() {
    const { classes } = this.props;
    return (
      <textarea
        id="CustomFieldModal/DefaultValue"
        rows="5"
        cols="66"
        className={classes.textArea}
        onChange={(e) => this.handleFormChange('defaultValue', e.target.value)}
      />
    );
  }

  renderTextItem(field_type) {
    const {
      editData: { options, defaultValue },
      selectedItem,
    } = this.state;
    const disableDefaultValue =
      (field_type && field_type.label === 'Dropdown' && !options.length) ||
      field_type.label === 'Attachment' ||
      (selectedItem &&
        !options.length &&
        [DataFieldType.ARRAY_MULTIPLE, DataFieldType.ARRAY_SINGLE].includes(
          selectedItem.field_type,
        )) ||
      (selectedItem && field_type === DataFieldType.ATTACHMENT);

    return (
      <EcInput
        id="CustomFieldModal/DefaultValue"
        isDisabled={disableDefaultValue}
        placeholder="Enter the default value for this field (if applicable)"
        input={{
          value: defaultValue || '',
          onChange: (event) => {
            this.handleFormChange('defaultValue', event.target.value);
          },
        }}
      />
    );
  }

  renderFieldType() {
    const { classes } = this.props;
    const { selectedItem } = this.state;
    const field_types_menu = field_types_available.map((item) => ({
      label: item,
      value: item,
    }));

    if (selectedItem && !!selectedItem.is_smart) {
      return null;
    }
    return (
      <Fragment>
        <div className={classes.divider} />
        <label
          className={classes.labelText}
          htmlFor="CustomFieldModal/FieldType"
        >
          FIELD TYPE
        </label>
        <EcSelect
          id="CustomFieldModal/FieldType"
          width="100%"
          {...(selectedItem && {
            value: {
              label: fieldTypesDisplay[selectedItem.field_type],
              value: fieldTypesDisplay[selectedItem.field_type],
            },
          })}
          defaultValue={defaultFieldType}
          options={field_types_menu}
          placeholder="Select Field Type"
          onChange={(value) => this.handleFormChange('field_type', value)}
          isDisabled={!!selectedItem}
        />
      </Fragment>
    );
  }

  renderDefaultValueHelpText() {
    const { classes } = this.props;
    const {
      advancedOptionsExpanded,
      editData: { help_text, field_type },
      selectedItem,
    } = this.state;

    if (!advancedOptionsExpanded) {
      return null;
    }

    return (
      <div className={classes.advancedOptionsWrapper}>
        <label
          className={classes.labelText}
          htmlFor="CustomFieldModal/DefaultValue"
        >
          DEFAULT VALUE
        </label>
        {this.renderInputFieldForType(field_type)}
        {(field_type && field_type.label === 'Dropdown') ||
        (selectedItem &&
          [DataFieldType.ARRAY_SINGLE, DataFieldType.ARRAY_MULTIPLE].includes(
            selectedItem.field_type,
          ))
          ? this.renderMultiSelectSelection()
          : null}

        <label
          className={classes.labelText}
          htmlFor="CustomFieldModal/HelpText"
        >
          HELP TEXT
        </label>
        <EcInput
          id="CustomFieldModal/HelpText"
          placeholder="If necessary, explain in more detail the data for this field..."
          input={{
            value: help_text || '',
            onChange: (event) => {
              this.handleFormChange('help_text', event.target.value);
            },
          }}
        />
      </div>
    );
  }

  handleOptionSelection(selectedDocTypeOption) {
    this.setState({
      editData: {
        ...this.state.editData,
        doc_types: [],
        doc_restriction: selectedDocTypeOption,
      },
    });
  }

  handleAllowNewOptionsCheckbox() {
    const { editData } = this.state;

    const checked = !editData.allow_new_options;
    const newEditData = { ...this.state.editData, allow_new_options: checked };
    this.setState({ editData: newEditData });
  }

  renderDocumentTypes() {
    const { classes } = this.props;
    const { selectedItem, errorLoadingDocTypes } = this.state;

    if (selectedItem && !!selectedItem.is_smart) {
      return null;
    } else if (errorLoadingDocTypes) {
      return <div>An error occurred while loading document types</div>;
    }

    return (
      <div>
        <div className={classes.divider} />
        <div className={classes.labelText}>DOCUMENT TYPES</div>
        <p className={classes.descriptionText}>
          You can choose whether this data field will be shown on any documents
          or documents of a specific type.
        </p>
        {this.renderDocumentTypesOptions()}
      </div>
    );
  }

  renderDocumentTypesOptions() {
    const { classes } = this.props;
    const {
      editData: { doc_restriction },
    } = this.state;

    return (
      <EcRadioContainer
        groupName="docTypesOptions"
        onChange={({ selectedValue }) =>
          this.handleOptionSelection(selectedValue)
        }
        defaultValue={doc_restriction}
      >
        {(groupName, selectedValue, onOptionChange) => (
          <div className={classes.radioContainerContent}>
            <EcRadioButton
              id="allDocTypesWithExceptions"
              groupName="docTypesOptions"
              value="EXCLUDE"
              label={<span>All document types</span>}
              onChange={onOptionChange}
              selectedValue={doc_restriction}
              fontSize="13px"
              labelColor={black2}
            />

            {doc_restriction === 'EXCLUDE'
              ? this.renderDocTypesExceptions()
              : null}

            <EcRadioButton
              id="specificDocTypes"
              groupName="docTypesOptions"
              value="INCLUDE"
              label={
                <span>
                  Only <b>specific</b> document types
                </span>
              }
              onChange={onOptionChange}
              selectedValue={doc_restriction}
              fontSize="13px"
              labelColor={black2}
            />

            {doc_restriction === 'INCLUDE'
              ? this.renderDocTypesSpecific()
              : null}
          </div>
        )}
      </EcRadioContainer>
    );
  }

  renderMultiSelectSelection() {
    const { classes } = this.props;
    const {
      editData: { allow_multiple, allow_new_options, sort_options },
    } = this.state;

    return (
      <div>
        <Fragment>
          <div className={classes.labelText}>SELECTION</div>
          <EcRadioContainer
            groupName="allowMultiple"
            onChange={({ selectedValue }) =>
              this.handleFormChange('allow_multiple', selectedValue)
            }
            defaultValue={allow_multiple}
          >
            {(groupName, selectedValue, onOptionChange) => (
              <div className={classes.radioContainerContent}>
                <EcRadioButton
                  id="allowOneOption"
                  groupName="allowMultiple"
                  value={false}
                  label="Allow selection of one option"
                  onChange={onOptionChange}
                  selectedValue={allow_multiple}
                  fontSize="13px"
                  labelColor={black2}
                />

                <EcRadioButton
                  id="allowMultiOptions"
                  groupName="allowMultiple"
                  value={true}
                  label="Allow selection of multiple options"
                  onChange={onOptionChange}
                  selectedValue={allow_multiple}
                  fontSize="13px"
                  labelColor={black2}
                />
              </div>
            )}
          </EcRadioContainer>
        </Fragment>

        <div className={classes.labelText}>USER-PROVIDED OPTIONS</div>
        <span className={classes.checkboxContainer}>
          <EcCheckbox
            checked={allow_new_options}
            id="CustomFieldModal/AllowUserToAddOptionsCheckbox"
            onClick={this.handleAllowNewOptionsCheckbox}
          />
          <label
            className={classes.checkboxLabel}
            htmlFor="CustomFieldModal/AllowUserToAddOptionsCheckbox"
          >
            Allow user to add their own options
          </label>
        </span>

        <div className={classes.labelText}>OPTIONS SORTING</div>
        <EcRadioContainer
          groupName="allowSort"
          onChange={({ selectedValue }) =>
            this.handleFormChange('sort_options', selectedValue)
          }
          defaultValue={sort_options}
        >
          {(groupName, selectedValue, onOptionChange) => (
            <div className={classes.radioContainerContent}>
              <EcRadioButton
                id="sortOptionsFalse"
                groupName="allowSort"
                value={false}
                label="Do not sort options"
                onChange={onOptionChange}
                selectedValue={sort_options}
                fontSize="13px"
                labelColor={black2}
              />

              <EcRadioButton
                id="sortOptionsTrue"
                groupName="allowSort"
                value={true}
                label="Sort options alphabetically"
                onChange={onOptionChange}
                selectedValue={sort_options}
                fontSize="13px"
                labelColor={black2}
              />
            </div>
          )}
        </EcRadioContainer>
      </div>
    );
  }

  renderAvailableOption() {
    const { classes, selectedItem } = this.props;
    const {
      editData: { field_type, options },
    } = this.state;

    if (
      (field_type && field_type.label === 'Dropdown') ||
      (selectedItem &&
        !selectedItem.is_smart &&
        [DataFieldType.ARRAY_MULTIPLE, DataFieldType.ARRAY_SINGLE].includes(
          field_type,
        ))
    ) {
      return (
        <Fragment>
          <div className={classes.labelText}>AVAILABLE OPTIONS</div>
          {this.renderItem(options, 'options')}
        </Fragment>
      );
    }
    return null;
  }

  renderSuperAdminOptions() {
    const { classes, currentUser } = this.props;
    const {
      advancedOptionsExpanded,
      editData: { is_beta, is_smart, is_visible },
    } = this.state;

    const isSuperAdmin = testIsSuperAdmin(currentUser);

    if (!isSuperAdmin || !advancedOptionsExpanded) {
      return null;
    }

    const handleIsBeta = () => this.handleFormChange('is_beta', !is_beta);
    const handleIsSmart = () => this.handleFormChange('is_smart', !is_smart);
    const handleIsVisible = () =>
      this.handleFormChange('is_visible', !is_visible);

    return (
      <div className={classes.flagContainer}>
        <span className={classes.checkboxContainer}>
          <EcCheckbox
            checked={is_visible}
            id="CustomFieldModal/DisplayFieldInSectionCheckbox"
            onClick={handleIsVisible}
          />
          <label
            className={classes.checkboxLabel}
            htmlFor="CustomFieldModal/DisplayFieldInSectionCheckbox"
          >
            Display this field in a client section
          </label>
        </span>
        <span className={classes.checkboxContainer}>
          <EcCheckbox
            checked={is_smart}
            id="CustomFieldModal/RelatedToAlgorithmCheckbox"
            onClick={handleIsSmart}
          />
          <label
            className={classes.checkboxLabel}
            htmlFor="CustomFieldModal/RelatedToAlgorithmCheckbox"
          >
            Is this related to an algorithm?
          </label>
          <div
            className={classes.tooltip}
            data-tip
            data-for="isSmartFieldTooltip"
          >
            <HelpIcon size="20" color={evisortBlue} opacity="1" />
            <div className={classes.tooltipContent}>
              <EcTooltip id="isSmartFieldTooltip" width="260px" place="right">
                Updating this will mark this field as SMART for ALL clients.
              </EcTooltip>
            </div>
          </div>
        </span>
        {is_smart && (
          <Fragment>
            <span className={classes.checkboxContainer}>
              <EcCheckbox
                checked={is_beta}
                id="CustomFieldModal/RelatedToAlgorithmBetaTestingCheckbox"
                onClick={handleIsBeta}
              />
              <label
                className={classes.checkboxLabel}
                htmlFor="CustomFieldModal/RelatedToAlgorithmBetaTestingCheckbox"
              >
                Is this related to an algorithm that we're beta testing?
              </label>
              <div
                className={classes.tooltip}
                data-tip
                data-for="isBetaFieldTooltip"
              >
                <HelpIcon size="20" color={evisortBlue} opacity="1" />
                <div className={classes.tooltipContent}>
                  <EcTooltip
                    id="isBetaFieldTooltip"
                    width="260px"
                    place="right"
                  >
                    Updating this will mark this field as BETA for ALL clients.
                  </EcTooltip>
                </div>
              </div>
            </span>
          </Fragment>
        )}
      </div>
    );
  }

  renderAdvancedOptions() {
    const { classes, currentUser } = this.props;
    const { advancedOptionsExpanded, selectedItem } = this.state;
    const isSuperAdmin = testIsSuperAdmin(currentUser);

    if (!isSuperAdmin && selectedItem && !!selectedItem.is_smart) {
      return null;
    }

    return (
      <div>
        <button
          className={classes.blueButton}
          onClick={() =>
            this.setState({ advancedOptionsExpanded: !advancedOptionsExpanded })
          }
        >
          {advancedOptionsExpanded ? (
            <ChevronDownIcon color={evisortBlue} size="16" />
          ) : (
            <ChevronRightIcon color={evisortBlue} size="16" />
          )}
          <span>Advanced Options</span>
        </button>
        {this.renderDefaultValueHelpText()}

        {this.renderUploadFlag()}
        {this.renderSuperAdminOptions()}
      </div>
    );
  }

  handleFlagForUpload() {
    const { editData } = this.state;

    const checked = !editData.show_on_upload_form;
    const newEditData = {
      ...this.state.editData,
      show_on_upload_form: checked,
    };
    this.setState({ editData: newEditData });
  }

  renderUploadFlag() {
    const { classes } = this.props;
    const {
      advancedOptionsExpanded,
      editData: { show_on_upload_form, field_type },
    } = this.state;
    // TODO: Remove field_type check when ATTACHMENT on upload fields are supported
    if (
      !advancedOptionsExpanded ||
      field_type === DataFieldType.ATTACHMENT ||
      field_type?.label === 'Attachment'
    ) {
      return null;
    }
    return (
      <div className={classes.flagContainer}>
        <span className={classes.checkboxContainer}>
          <EcCheckbox
            checked={show_on_upload_form}
            id="CustomFieldModal/DisplayFieldOnUploadCheckbox"
            onClick={this.handleFlagForUpload}
          />
          <label
            className={classes.checkboxLabel}
            htmlFor="CustomFieldModal/DisplayFieldOnUploadCheckbox"
          >
            Display this field on upload
          </label>
          <div
            className={classes.tooltip}
            data-tip
            data-for="uploadFieldTooltip"
          >
            <HelpIcon size="20" color={evisortBlue} opacity="1" />
            <div className={classes.tooltipContent}>
              <EcTooltip id="uploadFieldTooltip" width="260px" place="right">
                Checking this box will cause this field to appear every time a
                user uploads documents. It’s important to keep this limited to
                your most important 2-3 fields.
              </EcTooltip>
            </div>
          </div>
        </span>
      </div>
    );
  }

  renderDocTypesExceptions() {
    const { classes } = this.props;
    const { selectedItem, docTypes } = this.state;

    if (selectedItem && !!selectedItem.is_smart) {
      return null;
    }
    return (
      <div className={classes.optionWrapper}>
        <span className={classes.optionLabelText}>except</span>
        <div className={classes.optionSelectionContainer}>
          {this.renderMultiSelect(docTypes, 'doc_types')}
        </div>
      </div>
    );
  }

  renderDocTypesSpecific() {
    const { classes } = this.props;
    const { selectedItem, docTypes } = this.state;

    if (selectedItem && !!selectedItem.is_smart) {
      return null;
    }
    return (
      <div className={classes.optionWrapper}>
        <span className={classes.optionLabelText}>specific</span>
        <div className={classes.optionSelectionContainer}>
          {this.renderMultiSelect(docTypes, 'doc_types')}
        </div>
      </div>
    );
  }

  render() {
    const {
      classes,
      title,
      selectedSection,
      confirmButtonText,
      hideModal,
    } = this.props;
    const {
      editData: {
        name,
        defaultValue,
        field_type,
        allow_new_options,
        options,
        doc_restriction,
        doc_types,
      },
    } = this.state;

    const multiValuesConditionNotOk =
      !!field_type &&
      field_type.value === 'Dropdown' &&
      !allow_new_options &&
      !options.length;

    const multiValuesHasNoMatchingDefault =
      !!field_type &&
      field_type.value === 'Dropdown' &&
      defaultValue &&
      defaultValue.length &&
      options &&
      options.filter((option) => option.value === defaultValue).length === 0;

    const disabledSaveBtn =
      !name ||
      multiValuesConditionNotOk ||
      multiValuesHasNoMatchingDefault ||
      (doc_restriction === 'INCLUDE' && !doc_types.length);

    return (
      <EcModalCard
        title={`${title} in ${selectedSection}`}
        content={
          <div>
            <label
              className={classes.labelText}
              htmlFor="CustomFieldModal/FieldName"
            >
              FIELD NAME
            </label>
            <EcInput
              id="CustomFieldModal/FieldName"
              input={{
                'data-testid': 'data-field-name-input',
                value: name,
                onChange: (event) => {
                  this.handleFormChange('name', event.target.value);
                },
              }}
            />

            {this.renderFieldType()}

            <div className={classes.spacer} />

            {this.renderAvailableOption()}

            {this.renderAdvancedOptions()}

            {this.renderDocumentTypes()}
          </div>
        }
        footer={
          <>
            <Button variant="tertiary" text="Cancel" onClick={hideModal} />
            <Button
              variant="primary"
              text={confirmButtonText}
              disabled={disabledSaveBtn}
              onClick={this.handleActionClick}
            />
          </>
        }
        hideModal={hideModal}
      />
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

CustomFieldModal = withFlags(connect(mapStateToProps)(CustomFieldModal));

export default injectSheet(styles)(CustomFieldModal);
