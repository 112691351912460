import { Filter } from '~/evifields';
import { pilot } from '~/services';

import { buildFilterQuery } from '../searchV3';

interface Request {
  filters: Filter[];
  clauseName: string;
}

export const updateClause = async ({ filters, clauseName }: Request) => {
  const query = filters
    ? buildFilterQuery({
        booleanQuery: '',
        filters,
      })
    : [];

  return await pilot.patch(`/ai-command-center/update/?clause=${clauseName}`, {
    search_query: query,
  });
};
