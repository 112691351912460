import {
  black2,
  black3,
  black4,
} from '../../../../assets/shared-styles/general';

const styles = {
  headerUsers: {
    width: '150px',
    height: '16px',
    fontSize: '12px',
    fontWeight: '600',
    color: black2,
  },
  headerEmails: {
    marginTop: '20px',
    marginBottom: '8px',
    width: '150px',
    height: '16px',
    fontSize: '12px',
    fontWeight: '600',
    color: black2,
  },
  domainsContainer: {
    marginBottom: '20px',
  },
  allEmailDocChoice: {
    marginTop: '10px',
    height: '21px',
    fontSize: '15px',
    lineHeight: '1.4',
    color: black2,
  },
  selectEmailDocChoice: {
    marginTop: '10px',
    marginBottom: '15px',
    height: '21px',
    fontSize: '15px',
    lineHeight: '1.4',
    color: black2,
  },
  multipleValuesEmpty: {
    color: black4,
    fontSize: '15px',
  },
  multipleValuesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
  },
  multipleValues: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '8px',
  },
  domainInfo: {
    width: '500px',
    height: '17px',
    fontSize: '13px',
    color: black3,
    marginBottom: '10px',
  },
  hideUserMultiSelect: { display: 'none' },
  showUserMultiSelect: { display: 'inline-block' },
};
export default styles;
