import { useEffect } from 'react';

import { Nullable } from '../types';

export const useClickOutside = <T extends Nullable<HTMLElement>>(
  ref: React.MutableRefObject<T>,
  callback: () => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const selection = window.getSelection();
      const isSelection =
        selection && selection.rangeCount > 0 && !selection.isCollapsed;
      const isContained =
        !ref.current || ref.current.contains(event.target as T);

      // Do nothing if clicking is contained within ref or text selection is performed
      if (isContained || isSelection) {
        return;
      }

      callback();
    };

    document.addEventListener('click', listener, true);

    return () => {
      document.removeEventListener('click', listener, true);
    };
  }, [ref, callback]);
};
