import React from 'react';

import { GetFieldValuesParam } from '~/api';
import { mapValueObjectsToOptions } from '~/components/Shared/EcSelect/EcSelect.utils';
import { api } from '~/redux';
import { DropdownFieldValue, Option } from '~/types';

interface CanBeLoading {
  isLoading: boolean;
}
interface ListenOnInputChange {
  onInputChange?: (arg?: any) => any;
  debounceTimeout?: number;
}

interface ListenOnFocus {
  onFocus?: (arg?: any) => any;
}

interface HasDropdownOptions {
  options: Option<DropdownFieldValue>[];
}

interface LoadOptionsOnFocus
  extends HasDropdownOptions,
    CanBeLoading,
    ListenOnInputChange,
    ListenOnFocus {}

export function withGetFieldValuesOnFocus(
  WrappedComponent: React.ComponentType<LoadOptionsOnFocus>,
) {
  function DataFieldValuesContainer({
    fieldId,
    searchSuggestions,
    onFocus: _onFocus,
    ...props
  }: GetFieldValuesParam & ListenOnInputChange & ListenOnFocus) {
    const [getFieldValues, result] = api.useLazyGetFieldValuesQuery();

    const { data, isFetching } = result;
    const values = data?.values;

    const onInputChangeGetFieldValues = (value: any) => {
      if (fieldId && value) {
        getFieldValues({ fieldId, searchSuggestions, substringQuery: value });
      }
    };

    const onFocusWithGetFieldValues = (args: any) => {
      if (fieldId && !values) {
        getFieldValues({ fieldId, searchSuggestions });
      }
      _onFocus?.(args);
    };

    return (
      <WrappedComponent
        {...props}
        isLoading={isFetching}
        options={values ? mapValueObjectsToOptions(values) : []}
        debounceTimeout={300}
        onInputChange={onInputChangeGetFieldValues}
        onFocus={onFocusWithGetFieldValues}
      />
    );
  }
  return DataFieldValuesContainer;
}
