import { bold, evisortBlue, evisortRed } from '~/assets/shared-styles/general';

const searchInputWrapperStyles = {
  height: '48px',
  padding: '0 16px',
  border: '1px solid transparent',
  borderRadius: '4px',
  backgroundColor: 'rgba(0, 0, 0, 0.025)',
};

const styles = {
  searchInputWrapper: searchInputWrapperStyles,
  errorSearchInputWrapper: {
    ...searchInputWrapperStyles,
    border: `solid 1px ${evisortRed}`,
  },
  searchInput: {
    flexGrow: 1,
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    '&::placeholder': {
      color: '#626868',
    },
  },
  searchInputAppendix: {
    height: '14px',
  },
  booleanSearchPlaceholder: {
    marginBottom: '32px',
  },
  addSectionButton: {
    padding: 0,
    backgroundColor: 'transparent',
    border: 'none',
    color: evisortBlue,
    fontWeight: bold,
    cursor: 'pointer',
  },
};

export default styles;
