export const {
  VITE_REACT_APP_ALLOWED_REDIRECT_TO_DOMAIN,
  VITE_REACT_APP_AUTH_ENDPOINT,
  VITE_REACT_APP_EVEREST_ENDPOINT,
  VITE_REACT_APP_BACKEND_ENDPOINT,
  VITE_REACT_APP_PUBLIC_API_ENDPOINT,
  VITE_REACT_APP_DOC_PROXY_ENDPOINT,
  VITE_REACT_APP_WEAVER_ENDPOINT,
  VITE_REACT_APP_CORS_REQUESTS,
  VITE_REACT_APP_BASE_URL,
  VITE_REACT_APP_CLOUSEAU_ENDPOINT,
  VITE_REACT_APP_GEN_AI_ENDPOINT,
  VITE_REACT_APP_MUSE_ENDPOINT,
  VITE_REACT_APP_SYLVANUS_ENDPOINT,
  VITE_REACT_APP_EVISEARCH_ENDPOINT,
  VITE_REACT_APP_CODECASH_ENDPOINT,
  VITE_REACT_APP_ATLAS_ENDPOINT,
  VITE_REACT_APP_ROLODEX_ENDPOINT,
  VITE_REACT_APP_COURIER_ENDPOINT,
  VITE_REACT_APP_MANAGED_ENTITIES_ENDPOINT,
  VITE_REACT_APP_WOPI_ENDPOINT,
  VITE_PUBLIC_URL,
  VITE_NODE_OPTIONS,
  VITE_CI_ENVIRONMENT_NAME,
  VITE_SENTRY_ENABLED,
  VITE_SENTRY_ENVIRONMENT,
  VITE_SENTRY_DSN,
  VITE_SENTRY_RELEASE,
  VITE_SENTRY_PROFILES_SAMPLE_RATE,
  VITE_SENTRY_TRACES_SAMPLE_RATE,
  VITE_SENTRY_REPLAYS_ONERROR_SAMPLE_RATE,
  VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  VITE_MAINTENANCE_ENDS_AT,
  VITE_PUSHER_APP_KEY,
  VITE_PUSHER_CLUSTER,
  VITE_ZENDESK_AUTH_ENTRY_URL,
  VITE_LAUNCHDARKLY_CLIENT_SIDE_ID,
  VITE_OTEL_EXPORTER_OTLP_ENDPOINT,
  VITE_REACT_APP_EJ2_LICENSE_KEY,
  VITE_REACT_APP_AMPLITUDE_API_KEY,
  VITE_REACT_APP_BENTO_APP_ID,
  VITE_REACT_APP_EARLY_ACCESS_ENDPOINT,
  VITE_REACT_APP_STYTCH_PUBLIC_TOKEN,
  VITE_SKIP_PREFLIGHT_CHECK,
  // @ts-ignore
} = import.meta.env || {};
