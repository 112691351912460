import { BookmarkElementBox } from '@syncfusion/ej2-react-documenteditor';

export const bookmarkTypes = ['clause', 'risk', 'util'] as const;

export type Bookmark = typeof bookmarkTypes[number];

export type BookmarkRecord = Map<string, BookmarkElementBox>;

export type Bookmarks = Record<Bookmark, BookmarkRecord>;

export type RevisionType = 'All' | 'Insertion' | 'Deletion' | 'None';

export type Variant = 'clause' | 'comment' | 'revision' | 'risk' | 'source';

export interface DocumentEditorHighlight {
  id: number | string;
  type: Variant;
  isVisible?: boolean;
  tokens?: number[];
}
