import PT from 'prop-types';
import React from 'react';

import { PageLayout, Text } from '~/ui';

const { SidebarSectionItem } = PageLayout;

function EmptySectionItem({ indent = 0, message = 'No fields yet', py = 0 }) {
  return (
    <SidebarSectionItem
      indent={indent}
      py={py}
      sx={{ height: 'sidebar-section-item-height' }}
    >
      <Text
        color="gray-600"
        pl={`${indent * 10}px`}
        sx={{ alignSelf: 'center' }}
        variant="xs-dense-medium"
      >
        {message}
      </Text>
    </SidebarSectionItem>
  );
}

EmptySectionItem.propTypes = {
  indent: PT.number,
  message: PT.string,
};

export default EmptySectionItem;
