export default `
<svg
  aria-hidden="true"
  aria-label="send"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M19.3817 11.0781L5.38171 5.07812C5.25671 5.01562 5.13171 4.98438 5.00671 4.98438C4.72546 4.98438 4.44421 5.07812 4.25671 5.29688C3.97546 5.60938 3.91296 6.07812 4.10046 6.45312L7.00671 11.9844L4.10046 17.5469C3.91296 17.9219 3.97546 18.3906 4.25671 18.6719C4.44421 18.8906 4.72546 19.0156 5.00671 19.0156C5.13171 19.0156 5.25671 18.9844 5.38171 18.9219L19.3817 12.9219C19.7567 12.7656 20.0067 12.3906 20.0067 11.9844C19.9755 11.6094 19.7567 11.2344 19.3817 11.0781ZM6.06921 7.01562L15.9755 11.2344H8.28796L6.06921 7.01562ZM8.28796 12.7344H15.9755L6.06921 16.9844L8.28796 12.7344Z"
    fill="currentcolor"
  />
</svg>
`;
