import pluralize from 'pluralize';
import React from 'react';

import Links from '~/components/Workflow/shared/Links';
import { FlexLayout, Text } from '~/ui';

function ConfirmDelete({ condition }) {
  if (!condition) {
    return null;
  }

  const { length } = condition.links;

  return (
    <FlexLayout flexDirection="column" space={6}>
      {length > 0 && (
        <FlexLayout
          bg="red-600"
          color="white"
          flexDirection="column"
          p={6}
          space={2}
        >
          <Text variant="m-dense-bold">
            This condition is linked to {pluralize('object', length, true)}
          </Text>
          <Text variant="s-spaced">
            Deleting the condition will have consequences for the objects listed
            below.
          </Text>
        </FlexLayout>
      )}
      <Links links={condition.links} />
      <Text variant="s-spaced">
        Are you sure you want to delete this condition?
      </Text>
    </FlexLayout>
  );
}

export default ConfirmDelete;
