import React from 'react';

import boxLogoSrc from '~/assets/images/logo-box.svg';
import dropboxLogoSrc from '~/assets/images/logo-dropbox.svg';
import googleDriveLogoSrc from '~/assets/images/logo-google-drive.svg';
import oneDriveLogoSrc from '~/assets/images/logo-one-drive.svg';
import sharepointLogoSrc from '~/assets/images/logo-sharepoint.svg';
import { CardList } from '~/ui';

import ContactIntegrationCard from './ContactIntegrationCard';

const integrations = [
  {
    description:
      'Integrate Evisort with your Box account to sync documents across both systems and automatically bring files added to specified Box folders into Evisort',
    logoSrc: boxLogoSrc,
    title: 'Box',
  },
  {
    description:
      'Integrate Evisort with your Dropbox account to sync documents across both systems and automatically bring files added to specified Dropbox folders into Evisort',
    logoSrc: dropboxLogoSrc,
    title: 'Dropbox',
  },
  {
    description:
      'Integrate Evisort with your Google Drive account to sync documents across both systems and automatically bring files added to specified Google Drive folders into Evisort',
    logoSrc: googleDriveLogoSrc,
    title: 'Google Drive',
  },
  {
    description:
      'Integrate Evisort with your OneDrive for Business account to sync documents across both systems and automatically bring files added to specified OneDrive for Business folders into Evisort',
    logoSrc: oneDriveLogoSrc,
    title: 'One Drive',
  },
  {
    description:
      'Integrate Evisort with your Sharepoint account to sync documents across both systems and automatically bring files added to specified Sharepoint sites or folders into Evisort',
    logoSrc: sharepointLogoSrc,
    title: 'Sharepoint',
  },
];

function StorageIntegrations() {
  return (
    <CardList title="Storage">
      {integrations.map(({ description, logoSrc, title }) => (
        <ContactIntegrationCard
          key={title}
          description={description}
          logoSrc={logoSrc}
          rightActionText="Set up Sync Pair"
          title={title}
        />
      ))}
    </CardList>
  );
}

export default StorageIntegrations;
